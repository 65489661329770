// Constant imports
import {
  INVOICE_DOCUMENT_DETAILS_REQUEST,
  INVOICE_DOCUMENT_DETAILS_SUCCESS,
  INVOICE_DOCUMENT_DETAILS_FAIL,
  INVOICE_DOCUMENT_DETAILS_RESET,
  INVOICE_DOCUMENT_UPDATE_REQUEST,
  INVOICE_DOCUMENT_UPDATE_SUCCESS,
  INVOICE_DOCUMENT_UPDATE_FAIL,
  INVOICE_DOCUMENT_UPDATE_RESET,
} from "../constants/invoiceDocumentConstants";

// List details
export const invoiceDocumentDetailsReducer = (
  state = { loading: false, invoice_document: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_DOCUMENT_DETAILS_REQUEST:
      return {
        loading: true,
        invoice_document: state.invoice_document,
        success: false,
        error: "",
      };

    case INVOICE_DOCUMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice_document: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_DOCUMENT_DETAILS_FAIL:
      return {
        loading: false,
        invoice_document: state.invoice_document,
        success: false,
        error: action.payload,
      };

    case INVOICE_DOCUMENT_DETAILS_RESET:
      return {
        loading: false,
        invoice_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const invoiceDocumentUpdateReducer = (
  state = { loading: false, invoice_document: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_DOCUMENT_UPDATE_REQUEST:
      return {
        loading: true,
        invoice_document: state.invoice_document,
        success: false,
        error: "",
      };

    case INVOICE_DOCUMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice_document: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_DOCUMENT_UPDATE_FAIL:
      return {
        loading: false,
        invoice_document: state.invoice_document,
        success: false,
        error: action.payload,
      };

    case INVOICE_DOCUMENT_UPDATE_RESET:
      return {
        loading: false,
        invoice_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
