// Library imports
import {
  Button,
  Callout,
  Classes,
  Dialog,
  ProgressBar,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function ActionDialog({
  buttons,
  children,
  height,
  icon,
  isOpen,
  message,
  messageIntent,
  onClose,
  progress,
  resetDrawerVariables,
  setIsOpen,
  title,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div>
      <Dialog
        className={!lightMode ? Classes.DARK : undefined}
        icon={icon}
        isCloseButtonShown
        isOpen={isOpen}
        lazy
        onClose={() => {
          setIsOpen(false);
        }}
        style={{
          height: height,
          width: width,
        }}
        title={title}
      >
        <div className={Classes.DIALOG_BODY}>
          {/* Message */}
          {message ? (
            <Callout intent={messageIntent} style={{ margin: "0 0 20px 0" }}>
              {message}
            </Callout>
          ) : undefined}

          {/* Content */}
          {children}

          {/* Progress bar */}
          {progress ? (
            <div
              style={{
                margin: "15px 0 15px 0",
              }}
            >
              <ProgressBar
                intent="primary"
                stripes={progress === 1 ? false : true}
                value={progress}
              />
            </div>
          ) : undefined}
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {buttons}

            <Button
              className={Classes.DIALOG_CLOSE_BUTTON}
              onClick={() => {
                resetDrawerVariables && resetDrawerVariables();

                onClose();

                setIsOpen(false);
              }}
              text="Close"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ActionDialog;
