export const REIMBURSEMENT_LIST_REQUEST = "REIMBURSEMENT_LIST_REQUEST";
export const REIMBURSEMENT_LIST_SUCCESS = "REIMBURSEMENT_LIST_SUCCESS";
export const REIMBURSEMENT_LIST_FAIL = "REIMBURSEMENT_LIST_FAIL";
export const REIMBURSEMENT_LIST_RESET = "REIMBURSEMENT_LIST_RESET";

export const REIMBURSEMENT_DETAILS_REQUEST = "REIMBURSEMENT_DETAILS_REQUEST";
export const REIMBURSEMENT_DETAILS_SUCCESS = "REIMBURSEMENT_DETAILS_SUCCESS";
export const REIMBURSEMENT_DETAILS_FAIL = "REIMBURSEMENT_DETAILS_FAIL";
export const REIMBURSEMENT_DETAILS_RESET = "REIMBURSEMENT_DETAILS_RESET";

export const REIMBURSEMENT_UPDATE_REQUEST = "REIMBURSEMENT_UPDATE_REQUEST";
export const REIMBURSEMENT_UPDATE_SUCCESS = "REIMBURSEMENT_UPDATE_SUCCESS";
export const REIMBURSEMENT_UPDATE_FAIL = "REIMBURSEMENT_UPDATE_FAIL";
export const REIMBURSEMENT_UPDATE_RESET = "REIMBURSEMENT_UPDATE_RESET";

export const REIMBURSEMENT_DOWNLOAD_REQUEST = "REIMBURSEMENT_DOWNLOAD_REQUEST";
export const REIMBURSEMENT_DOWNLOAD_SUCCESS = "REIMBURSEMENT_DOWNLOAD_SUCCESS";
export const REIMBURSEMENT_DOWNLOAD_FAIL = "REIMBURSEMENT_DOWNLOAD_FAIL";
export const REIMBURSEMENT_DOWNLOAD_RESET = "REIMBURSEMENT_DOWNLOAD_RESET";
