export const ENGAGEMENT_DEPENDENCY_LIST_REQUEST =
  "ENGAGEMENT_DEPENDENCY_LIST_REQUEST";
export const ENGAGEMENT_DEPENDENCY_LIST_SUCCESS =
  "ENGAGEMENT_DEPENDENCY_LIST_SUCCESS";
export const ENGAGEMENT_DEPENDENCY_LIST_FAIL =
  "ENGAGEMENT_DEPENDENCY_LIST_FAIL";
export const ENGAGEMENT_DEPENDENCY_LIST_RESET =
  "ENGAGEMENT_DEPENDENCY_LIST_RESET";

export const ENGAGEMENT_DEPENDENCY_CREATE_REQUEST =
  "ENGAGEMENT_DEPENDENCY_CREATE_REQUEST";
export const ENGAGEMENT_DEPENDENCY_CREATE_SUCCESS =
  "ENGAGEMENT_DEPENDENCY_CREATE_SUCCESS";
export const ENGAGEMENT_DEPENDENCY_CREATE_FAIL =
  "ENGAGEMENT_DEPENDENCY_CREATE_FAIL";
export const ENGAGEMENT_DEPENDENCY_CREATE_RESET =
  "ENGAGEMENT_DEPENDENCY_CREATE_RESET";

export const ENGAGEMENT_DEPENDENCY_DETAILS_REQUEST =
  "ENGAGEMENT_DEPENDENCY_DETAILS_REQUEST";
export const ENGAGEMENT_DEPENDENCY_DETAILS_SUCCESS =
  "ENGAGEMENT_DEPENDENCY_DETAILS_SUCCESS";
export const ENGAGEMENT_DEPENDENCY_DETAILS_FAIL =
  "ENGAGEMENT_DEPENDENCY_DETAILS_FAIL";
export const ENGAGEMENT_DEPENDENCY_DETAILS_RESET =
  "ENGAGEMENT_DEPENDENCY_DETAILS_RESET";

export const ENGAGEMENT_DEPENDENCY_UPDATE_REQUEST =
  "ENGAGEMENT_DEPENDENCY_UPDATE_REQUEST";
export const ENGAGEMENT_DEPENDENCY_UPDATE_SUCCESS =
  "ENGAGEMENT_DEPENDENCY_UPDATE_SUCCESS";
export const ENGAGEMENT_DEPENDENCY_UPDATE_FAIL =
  "ENGAGEMENT_DEPENDENCY_UPDATE_FAIL";
export const ENGAGEMENT_DEPENDENCY_UPDATE_RESET =
  "ENGAGEMENT_DEPENDENCY_UPDATE_RESET";

export const ENGAGEMENT_DEPENDENCY_DELETE_REQUEST =
  "ENGAGEMENT_DEPENDENCY_DELETE_REQUEST";
export const ENGAGEMENT_DEPENDENCY_DELETE_SUCCESS =
  "ENGAGEMENT_DEPENDENCY_DELETE_SUCCESS";
export const ENGAGEMENT_DEPENDENCY_DELETE_FAIL =
  "ENGAGEMENT_DEPENDENCY_DELETE_FAIL";
export const ENGAGEMENT_DEPENDENCY_DELETE_RESET =
  "ENGAGEMENT_DEPENDENCY_DELETE_RESET";
