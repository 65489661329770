// Library imports
import { Button, Callout, Card, Classes, H1, H6 } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import LinkTag from "../components/LinkTag";

// Redux action imports
// User
import {
  resetPasswordAction,
  resetResetPasswordAction,
} from "../actions/userActions";

// Main function
function PasswordResetScreen() {
  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userPasswordReset = useSelector((state) => state.userPasswordReset);

  // Form variables
  // Password reset credentials
  const [passwordResetCredentials, setPasswordResetCredentials] = useState({
    email: "",
  });

  // Reset password
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const onVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const resetPassword = () => {
    // Dispatch action
    dispatch(
      resetPasswordAction({
        email: passwordResetCredentials.email,
        recaptcha_token: reCaptchaToken,
      })
    );

    // Refresh reCAPTCHA
    setRefreshReCaptcha((r) => !r);
  };

  // JSX UI code
  return (
    <div
      className={Classes.DARK}
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(24, 145, 172, 0.8), rgba(24, 145, 172, 1))",
        display: "flex",
        justifyContent: "center",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      <Card
        elevation={4}
        style={{
          border: "1px solid #D2ECF9",
          borderRadius: "5px",
          margin: "20px 5px 20px 5px",
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              objectFit: "contain",
              width: "100px",
            }}
          />
        </div>

        <div className="row" style={{ textAlign: "center" }}>
          {/* Heading */}
          <H1>Reset your password</H1>
        </div>

        <div className="row">
          {/* Form */}
          <div className="col-s-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Error message */}
              {userPasswordReset.error && (
                <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                  We couldn't reset your password. Check your email and try
                  again.
                </Callout>
              )}

              {/* Email */}
              <FormDrawerTextField2
                disabled={userPasswordReset.success}
                error={userPasswordReset.error}
                fieldName="email"
                large
                placeholder="Enter your email"
                setValueObject={setPasswordResetCredentials}
                setValueVariable="email"
                value={passwordResetCredentials.email}
              />

              {/* reCAPTCHA */}
              <GoogleReCaptcha
                action="resetpassword"
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />

              {/* Buttons */}
              {userPasswordReset.success ? (
                <Button
                  fill
                  icon="log-in"
                  intent="success"
                  large
                  onClick={() => {
                    dispatch(resetResetPasswordAction());

                    navigate("/");
                  }}
                  style={{ margin: "20px 0 0 0" }}
                  text="Go back"
                />
              ) : (
                <Button
                  disabled={!reCaptchaToken}
                  fill
                  icon="key"
                  intent="danger"
                  large
                  loading={userPasswordReset.loading}
                  onClick={resetPassword}
                  style={{ margin: "20px 0 0 0" }}
                  text="Reset"
                  type="submit"
                />
              )}

              {/* Footer */}
              <div
                style={{
                  margin: "20px 0 0 0",
                }}
              >
                {userPasswordReset.success ? (
                  <H6 style={{ color: "#D2ECF9", margin: "20px 0 0 0" }}>
                    Your will receive an email to reset your password if you
                    have an active account. If you don't see it, please check
                    your spam folder.
                  </H6>
                ) : (
                  <div>
                    Don't need to reset your password?{" "}
                    <LinkTag
                      intent="primary"
                      label="Go back"
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default PasswordResetScreen;
