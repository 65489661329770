// Library imports
import {
  Button,
  ButtonGroup,
  Card,
  Callout,
  Colors,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import PDF2 from "../components/PDF2";
import ProofReader from "../components/ProofReader";

// Main function
function TabSectionDocumentEditor3({
  document,
  error,
  errorMessage,
  form,
  height,
  loading,
  proofReaderTitle,
  proofReaderValueDisplayVariable,
  proofReaderValueObject,
  setSectionPages,
  setShowProofReader,
  showPage,
  showProofReader,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Show buttons
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (loading) {
      setShowButtons(false);
    }
  }, [loading]);

  // Zoom
  const documentContainerRef = useRef(null);
  const [documentContainerHeight, setDocumentContainerHeight] = useState(null);
  const [documentContainerWidth, setDocumentContainerWidth] = useState(null);
  const [width, setWidth] = useState(600);

  useEffect(() => {
    if (documentContainerRef.current) {
      setDocumentContainerHeight(documentContainerRef.current.clientHeight);

      setDocumentContainerWidth(documentContainerRef.current.clientWidth);
    }
    // eslint-disable-next-line
  }, [documentContainerRef.current]);

  // Update proof reader
  const [text, setText] = useState("");

  useEffect(() => {
    if (
      proofReaderValueObject &&
      proofReaderValueObject[proofReaderValueDisplayVariable]
    ) {
      setText(proofReaderValueObject[proofReaderValueDisplayVariable]);
    }
  }, [proofReaderValueDisplayVariable, proofReaderValueObject]);

  // JSX UI code
  return (
    <div
      style={{
        display: "flex",
        height: height ? height : "75vh",
        width: "100%",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          overflowY: "auto",
          padding: "10px",
          width: "400px",
        }}
      >
        {error && (
          <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
            {errorMessage ? errorMessage : "Unable to generate PDF."}
          </Callout>
        )}

        {form}
      </div>

      <Card
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          margin: "10px",
          overflowY: "hidden",
          padding: "10px",
          width: "100%",
        }}
      >
        {showProofReader ? (
          <ProofReader
            setShow={setShowProofReader}
            text={text}
            title={proofReaderTitle}
          />
        ) : (
          <div style={{ height: "100%" }}>
            {showButtons && (
              <ButtonGroup
                style={{ margin: "10px 0 0 10px", position: "absolute" }}
                vertical
              >
                <Button
                  icon="zoom-in"
                  onClick={() => {
                    if (width <= 1280) {
                      setWidth(width + 100);
                    }
                  }}
                />

                <Button
                  icon="zoom-out"
                  onClick={() => {
                    if (width >= 600) {
                      setWidth(width - 100);
                    }
                  }}
                />
              </ButtonGroup>
            )}

            <div
              ref={documentContainerRef}
              style={{
                height: "100%",
                width: "100%",
              }}
            >
              {loading ? (
                <Spinner
                  size={SpinnerSize.LARGE}
                  style={{ margin: "30vh 0 0 0" }}
                />
              ) : (
                <PDF2
                  document={document}
                  documentContainerHeight={documentContainerHeight}
                  documentContainerWidth={documentContainerWidth}
                  setSectionPages={setSectionPages}
                  setShowButtons={setShowButtons}
                  showPage={showPage}
                  width={width}
                />
              )}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default TabSectionDocumentEditor3;
