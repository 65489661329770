// Library imports
import {
  Card,
  Classes,
  Colors,
  Icon,
  Spinner,
  SpinnerSize,
  Text,
} from "@blueprintjs/core";
import React from "react";

// Main function
function DocumentCard({ interactive, loading, onClick, title, type }) {
  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        alignItems: "center",
        borderTop: `5px solid ${
          type === "pdf"
            ? Colors.RED3
            : type === "word"
            ? Colors.BLUE3
            : type === "powerpoint"
            ? Colors.ORANGE3
            : type === "excel"
            ? Colors.GREEN3
            : Colors.GRAY3
        }`,
        display: "flex",
        height: "100px",
        margin: "5px",
        minWidth: "fit-content",
        padding: "10px",
        textAlign: "center",
      }}
    >
      <div style={{ textAlign: "center", width: "100px" }}>
        {loading ? (
          <Spinner size={SpinnerSize.SMALL} style={{ padding: "0 0 10px 0" }} />
        ) : (
          <Icon
            color={!type ? Colors.GRAY3 : undefined}
            icon={
              type === "excel"
                ? "th"
                : type === "pdf"
                ? "manual"
                : type === "powerpoint"
                ? "presentation"
                : type === "word"
                ? "document"
                : "database"
            }
            intent={
              type === "excel"
                ? "success"
                : type === "pdf"
                ? "danger"
                : type === "powerpoint"
                ? "warning"
                : type === "word"
                ? "primary"
                : "undefined"
            }
            size={20}
            style={{ padding: "0 0 10px 0" }}
          />
        )}

        <div
          style={{
            maxHeight: "50px",
            overflowY: "hidden",
          }}
        >
          <Text className={Classes.TEXT_SMALL}>{title}</Text>
        </div>
      </div>
    </Card>
  );
}

export default DocumentCard;
