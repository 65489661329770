export const MARKET_LIST_REQUEST = "MARKET_LIST_REQUEST";
export const MARKET_LIST_SUCCESS = "MARKET_LIST_SUCCESS";
export const MARKET_LIST_FAIL = "MARKET_LIST_FAIL";
export const MARKET_LIST_RESET = "MARKET_LIST_RESET";

export const MARKET_CREATE_REQUEST = "MARKET_CREATE_REQUEST";
export const MARKET_CREATE_SUCCESS = "MARKET_CREATE_SUCCESS";
export const MARKET_CREATE_FAIL = "MARKET_CREATE_FAIL";
export const MARKET_CREATE_RESET = "MARKET_CREATE_RESET";

export const MARKET_DETAILS_REQUEST = "MARKET_DETAILS_REQUEST";
export const MARKET_DETAILS_SUCCESS = "MARKET_DETAILS_SUCCESS";
export const MARKET_DETAILS_FAIL = "MARKET_DETAILS_FAIL";
export const MARKET_DETAILS_RESET = "MARKET_DETAILS_RESET";

export const MARKET_UPDATE_REQUEST = "MARKET_UPDATE_REQUEST";
export const MARKET_UPDATE_SUCCESS = "MARKET_UPDATE_SUCCESS";
export const MARKET_UPDATE_FAIL = "MARKET_UPDATE_FAIL";
export const MARKET_UPDATE_RESET = "MARKET_UPDATE_RESET";

export const MARKET_DELETE_REQUEST = "MARKET_DELETE_REQUEST";
export const MARKET_DELETE_SUCCESS = "MARKET_DELETE_SUCCESS";
export const MARKET_DELETE_FAIL = "MARKET_DELETE_FAIL";
export const MARKET_DELETE_RESET = "MARKET_DELETE_RESET";
