export const ENGAGEMENT_SERVICE_ACTIVITY_LIST_REQUEST =
  "ENGAGEMENT_SERVICE_ACTIVITY_LIST_REQUEST";
export const ENGAGEMENT_SERVICE_ACTIVITY_LIST_SUCCESS =
  "ENGAGEMENT_SERVICE_ACTIVITY_LIST_SUCCESS";
export const ENGAGEMENT_SERVICE_ACTIVITY_LIST_FAIL =
  "ENGAGEMENT_SERVICE_ACTIVITY_LIST_FAIL";
export const ENGAGEMENT_SERVICE_ACTIVITY_LIST_RESET =
  "ENGAGEMENT_SERVICE_ACTIVITY_LIST_RESET";

export const ENGAGEMENT_SERVICE_ACTIVITY_CREATE_REQUEST =
  "ENGAGEMENT_SERVICE_ACTIVITY_CREATE_REQUEST";
export const ENGAGEMENT_SERVICE_ACTIVITY_CREATE_SUCCESS =
  "ENGAGEMENT_SERVICE_ACTIVITY_CREATE_SUCCESS";
export const ENGAGEMENT_SERVICE_ACTIVITY_CREATE_FAIL =
  "ENGAGEMENT_SERVICE_ACTIVITY_CREATE_FAIL";
export const ENGAGEMENT_SERVICE_ACTIVITY_CREATE_RESET =
  "ENGAGEMENT_SERVICE_ACTIVITY_CREATE_RESET";

export const ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_REQUEST =
  "ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_REQUEST";
export const ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_SUCCESS =
  "ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_SUCCESS";
export const ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_FAIL =
  "ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_FAIL";
export const ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_RESET =
  "ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_RESET";

export const ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_REQUEST =
  "ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_REQUEST";
export const ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_SUCCESS =
  "ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_SUCCESS";
export const ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_FAIL =
  "ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_FAIL";
export const ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_RESET =
  "ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_RESET";

export const ENGAGEMENT_SERVICE_ACTIVITY_DELETE_REQUEST =
  "ENGAGEMENT_SERVICE_ACTIVITY_DELETE_REQUEST";
export const ENGAGEMENT_SERVICE_ACTIVITY_DELETE_SUCCESS =
  "ENGAGEMENT_SERVICE_ACTIVITY_DELETE_SUCCESS";
export const ENGAGEMENT_SERVICE_ACTIVITY_DELETE_FAIL =
  "ENGAGEMENT_SERVICE_ACTIVITY_DELETE_FAIL";
export const ENGAGEMENT_SERVICE_ACTIVITY_DELETE_RESET =
  "ENGAGEMENT_SERVICE_ACTIVITY_DELETE_RESET";
