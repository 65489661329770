// Library imports
import { Classes, Colors, Divider, H5, HTMLTable } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DateValue from "../components/DateValue";
import NameValue from "../components/NameValue";
import TableNumber from "../components/TableNumber";

// Main function
function LabourExpenseInfo({ additionalInfo, labourExpense }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <H5 style={{ margin: "0 10px 0 0" }}>
        {labourExpense.role_data ? labourExpense.role_data.name : undefined}
      </H5>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Description</strong>
      </p>

      <p>
        {labourExpense.role_data ? (
          labourExpense.role_data.description ? (
            labourExpense.role_data.description
          ) : (
            <span className={Classes.TEXT_MUTED}>No description provided.</span>
          )
        ) : undefined}
      </p>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Start</strong>
      </p>

      <div style={{ marginBottom: "10px" }}>
        <DateValue date={labourExpense.start} />
      </div>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Finish</strong>
      </p>

      <div style={{ marginBottom: "10px" }}>
        <DateValue date={labourExpense.finish} />
      </div>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Rates</strong>
      </p>

      <HTMLTable bordered striped style={{ width: "100%" }}>
        <tbody className={[Classes.TEXT_SMALL]}>
          <tr>
            <td
              style={{
                color: lightMode ? Colors.WHITE : Colors.BLACK,
                verticalAlign: "middle",
              }}
            >
              <TableNumber invertColor number={labourExpense.cost_rate} />
            </td>

            <td
              style={{
                color: lightMode ? Colors.WHITE : Colors.BLACK,
                verticalAlign: "middle",
              }}
            >
              <TableNumber
                fillTag
                invertColor
                number={labourExpense.sell_rate - labourExpense.cost_rate}
                tagNumber={labourExpense.margin_percentage}
                tagPercentage
              />
            </td>

            <td
              style={{
                color: lightMode ? Colors.WHITE : Colors.BLACK,
                verticalAlign: "middle",
              }}
            >
              <TableNumber invertColor number={labourExpense.sell_rate} />
            </td>
          </tr>
        </tbody>

        <thead className={Classes.TEXT_SMALL}>
          <tr>
            <th
              style={{
                color: lightMode ? Colors.WHITE : Colors.BLACK,
              }}
            >
              Cost rate
            </th>

            <th
              style={{
                color: lightMode ? Colors.WHITE : Colors.BLACK,
              }}
            >
              Margin
            </th>

            <th
              style={{
                color: lightMode ? Colors.WHITE : Colors.BLACK,
              }}
            >
              Sell rate
            </th>
          </tr>
        </thead>
      </HTMLTable>

      <p
        className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
        style={{ marginTop: "5px" }}
      >
        Figures in {activeOrganisation.currency} per hour
      </p>

      {labourExpense.staff && (
        <>
          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />
          <p>
            <strong>Staff</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NameValue
              email={labourExpense.staff_name}
              image={labourExpense.staff_profile_photo}
              invertColor
            />
          </div>
        </>
      )}

      {additionalInfo}
    </div>
  );
}

export default LabourExpenseInfo;
