export const ROLE_LIST_REQUEST = "ROLE_LIST_REQUEST";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAIL = "ROLE_LIST_FAIL";
export const ROLE_LIST_RESET = "ROLE_LIST_RESET";

export const ROLE_CREATE_REQUEST = "ROLE_CREATE_REQUEST";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAIL = "ROLE_CREATE_FAIL";
export const ROLE_CREATE_RESET = "ROLE_CREATE_RESET";

export const ROLE_DETAILS_REQUEST = "ROLE_DETAILS_REQUEST";
export const ROLE_DETAILS_SUCCESS = "ROLE_DETAILS_SUCCESS";
export const ROLE_DETAILS_FAIL = "ROLE_DETAILS_FAIL";
export const ROLE_DETAILS_RESET = "ROLE_DETAILS_RESET";

export const ROLE_UPDATE_REQUEST = "ROLE_UPDATE_REQUEST";
export const ROLE_UPDATE_SUCCESS = "ROLE_UPDATE_SUCCESS";
export const ROLE_UPDATE_FAIL = "ROLE_UPDATE_FAIL";
export const ROLE_UPDATE_RESET = "ROLE_UPDATE_RESET";

export const ROLE_DELETE_REQUEST = "ROLE_DELETE_REQUEST";
export const ROLE_DELETE_SUCCESS = "ROLE_DELETE_SUCCESS";
export const ROLE_DELETE_FAIL = "ROLE_DELETE_FAIL";
export const ROLE_DELETE_RESET = "ROLE_DELETE_RESET";
