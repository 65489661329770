export const RANK_LIST_REQUEST = "RANK_LIST_REQUEST";
export const RANK_LIST_SUCCESS = "RANK_LIST_SUCCESS";
export const RANK_LIST_FAIL = "RANK_LIST_FAIL";
export const RANK_LIST_RESET = "RANK_LIST_RESET";

export const RANK_CREATE_REQUEST = "RANK_CREATE_REQUEST";
export const RANK_CREATE_SUCCESS = "RANK_CREATE_SUCCESS";
export const RANK_CREATE_FAIL = "RANK_CREATE_FAIL";
export const RANK_CREATE_RESET = "RANK_CREATE_RESET";

export const RANK_DETAILS_REQUEST = "RANK_DETAILS_REQUEST";
export const RANK_DETAILS_SUCCESS = "RANK_DETAILS_SUCCESS";
export const RANK_DETAILS_FAIL = "RANK_DETAILS_FAIL";
export const RANK_DETAILS_RESET = "RANK_DETAILS_RESET";

export const RANK_UPDATE_REQUEST = "RANK_UPDATE_REQUEST";
export const RANK_UPDATE_SUCCESS = "RANK_UPDATE_SUCCESS";
export const RANK_UPDATE_FAIL = "RANK_UPDATE_FAIL";
export const RANK_UPDATE_RESET = "RANK_UPDATE_RESET";

export const RANK_DELETE_REQUEST = "RANK_DELETE_REQUEST";
export const RANK_DELETE_SUCCESS = "RANK_DELETE_SUCCESS";
export const RANK_DELETE_FAIL = "RANK_DELETE_FAIL";
export const RANK_DELETE_RESET = "RANK_DELETE_RESET";
