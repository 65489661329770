// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  REIMBURSEMENT_LIST_REQUEST,
  REIMBURSEMENT_LIST_SUCCESS,
  REIMBURSEMENT_LIST_FAIL,
  REIMBURSEMENT_LIST_RESET,
  REIMBURSEMENT_DETAILS_REQUEST,
  REIMBURSEMENT_DETAILS_SUCCESS,
  REIMBURSEMENT_DETAILS_FAIL,
  REIMBURSEMENT_DETAILS_RESET,
  REIMBURSEMENT_UPDATE_REQUEST,
  REIMBURSEMENT_UPDATE_SUCCESS,
  REIMBURSEMENT_UPDATE_FAIL,
  REIMBURSEMENT_UPDATE_RESET,
  REIMBURSEMENT_DOWNLOAD_REQUEST,
  REIMBURSEMENT_DOWNLOAD_SUCCESS,
  REIMBURSEMENT_DOWNLOAD_FAIL,
  REIMBURSEMENT_DOWNLOAD_RESET,
} from "../constants/reimbursementConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listReimbursementsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: REIMBURSEMENT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getReimbursements(params, token);

      dispatch({
        type: REIMBURSEMENT_LIST_SUCCESS,

        payload: {
          totalValue: data.total_value.result,
          count: data.count,
          reimbursements: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: REIMBURSEMENT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListReimbursementsAction = () => async (dispatch) => {
  dispatch({ type: REIMBURSEMENT_LIST_RESET });
};

// List details
export const listReimbursementDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: REIMBURSEMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getReimbursement(id, token);

      dispatch({
        type: REIMBURSEMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REIMBURSEMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListReimbursementDetailsAction = () => async (dispatch) => {
  dispatch({ type: REIMBURSEMENT_DETAILS_RESET });
};

// Update
export const updateReimbursementAction =
  (id, reimbursement) => async (dispatch, getState) => {
    try {
      dispatch({ type: REIMBURSEMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateReimbursement(
        id,
        token,
        reimbursement
      );

      dispatch({
        type: REIMBURSEMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REIMBURSEMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateReimbursementAction = () => async (dispatch) => {
  dispatch({ type: REIMBURSEMENT_UPDATE_RESET });
};

// Download
export const downloadReimbursementsAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: REIMBURSEMENT_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var reimbursements = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getReimbursements(
          { organisation: organisation, page: page },
          token
        );

        reimbursements = reimbursements.concat(data.results);
      }

      dispatch({
        type: REIMBURSEMENT_DOWNLOAD_SUCCESS,

        payload: {
          reimbursements: reimbursements,
        },
      });
    } catch (error) {
      dispatch({
        type: REIMBURSEMENT_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadReimbursementsAction = () => async (dispatch) => {
  dispatch({ type: REIMBURSEMENT_DOWNLOAD_RESET });
};
