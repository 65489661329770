// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Colors,
  Divider,
  H5,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog2 from "../components/HelpDialog2";
import LinkTag from "../components/LinkTag";
import NavbarContainer from "../components/NavbarContainer";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldImage from "../components/TabSectionFieldImage";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";

// Redux action imports
// User
import {
  confirmEmailResendAction,
  resetConfirmEmailResendAction,
} from "../actions/userActions";

// User profile
import {
  listUserProfileDetailsAction,
  resetUpdateUserProfileAction,
  updateUserProfileAction,
} from "../actions/userActions";

// Main function
function UserProfileScreen() {
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userEmailConfirmResend = useSelector(
    (state) => state.userEmailConfirmResend
  );

  // User profile
  const userProfileDetails = useSelector((state) => state.userProfileDetails);

  const userProfileUpdate = useSelector((state) => state.userProfileUpdate);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Preferences
  const [updatePreferencesDrawerIsOpen, setUpdatePreferencesDrawerIsOpen] =
    useState(false);

  // Form variables
  // User profile
  const userProfileDefault = {
    about: "",
    contactEmail: "",
    linkedinPage: "",
    name: "",
    notifyOfUpdates: true,
    phone: "",
    profilePhoto: "",
    profilePhotoName: "",
    provideFeedback: true,
    sendEvents: true,
    sendOffers: true,
    signature: "",
    signatureName: "",
    title: "",
  };
  const [userProfile, setUserProfile] = useState({
    ...userProfileDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // User profile
    setUserProfile({
      ...userProfileDefault,
    });
  };

  // Initialise drawer variables for user profile
  useEffect(() => {
    setUserProfile({
      about: userProfileDetails.user_profile.about
        ? userProfileDetails.user_profile.about
        : userProfileDefault.about,
      contactEmail: userProfileDetails.user_profile.contact_email
        ? userProfileDetails.user_profile.contact_email
        : userProfileDefault.contactEmail,
      linkedinPage: userProfileDetails.user_profile.linkedin_page
        ? userProfileDetails.user_profile.linkedin_page
        : userProfileDefault.linkedinPage,
      name: userProfileDetails.user_profile.name
        ? userProfileDetails.user_profile.name
        : userProfileDefault.name,
      notifyOfUpdates:
        userProfileDetails.user_profile.notify_of_updates !== null
          ? userProfileDetails.user_profile.notify_of_updates
          : userProfileDefault.notifyOfUpdates,
      phone: userProfileDetails.user_profile.phone
        ? userProfileDetails.user_profile.phone
        : userProfileDefault.phone,
      profilePhoto: userProfileDetails.user_profile.profile_photo
        ? userProfileDetails.user_profile.profile_photo
        : userProfileDefault.profilePhoto,
      profilePhotoName: userProfileDetails.user_profile.profile_photo
        ? userProfileDetails.user_profile.profile_photo.split("/").pop()
        : userProfileDefault.profilePhotoName,
      provideFeedback:
        userProfileDetails.user_profile.provide_feedback !== null
          ? userProfileDetails.user_profile.provide_feedback
          : userProfileDefault.provideFeedback,
      sendEvents:
        userProfileDetails.user_profile.send_events !== null
          ? userProfileDetails.user_profile.send_events
          : userProfileDefault.sendEvents,
      sendOffers:
        userProfileDetails.user_profile.send_offers !== null
          ? userProfileDetails.user_profile.send_offers
          : userProfileDefault.sendOffers,
      signature: userProfileDetails.user_profile.signature
        ? userProfileDetails.user_profile.signature
        : userProfileDefault.signature,
      signatureName: userProfileDetails.user_profile.signature
        ? userProfileDetails.user_profile.signature.split("/").pop()
        : userProfileDefault.signatureName,
      title: userProfileDetails.user_profile.title
        ? userProfileDetails.user_profile.title
        : userProfileDefault.title,
    });
    // eslint-disable-next-line
  }, [userProfileDetails.user_profile]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // Form submission
  // User profile
  // Update preferences
  const updatePreferences = () => {
    // Prepare data
    const user_profile = new FormData();

    userProfile.notifyOfUpdates !== null
      ? user_profile.append("notify_of_updates", userProfile.notifyOfUpdates)
      : user_profile.append(
          "notify_of_updates",
          userProfileDefault.notifyOfUpdates
        );

    userProfile.provideFeedback !== null
      ? user_profile.append("provide_feedback", userProfile.provideFeedback)
      : user_profile.append(
          "provide_feedback",
          userProfileDefault.provideFeedback
        );

    userProfile.sendEvents !== null
      ? user_profile.append("send_events", userProfile.sendEvents)
      : user_profile.append("send_events", userProfileDefault.sendEvents);

    userProfile.sendOffers !== null
      ? user_profile.append("send_offers", userProfile.sendOffers)
      : user_profile.append("send_offers", userProfileDefault.sendOffers);

    // Dispatch action
    dispatch(
      updateUserProfileAction(userProfileDetails.user_profile.id, user_profile)
    );
  };

  const updateSummary = () => {
    // Prepare data
    const user_profile = new FormData();

    userProfile.about
      ? user_profile.append("about", userProfile.about)
      : user_profile.append("about", userProfileDefault.about);

    userProfile.contactEmail
      ? user_profile.append("contact_email", userProfile.contactEmail)
      : user_profile.append("contact_email", userProfileDefault.contactEmail);

    userProfile.linkedinPage
      ? user_profile.append("linkedin_page", userProfile.linkedinPage)
      : user_profile.append("linkedin_page", userProfileDefault.linkedinPage);

    userProfile.name
      ? user_profile.append("name", userProfile.name)
      : user_profile.append("name", userProfileDefault.name);

    userProfile.phone
      ? user_profile.append("phone", userProfile.phone)
      : user_profile.append("phone", userProfileDefault.phone);

    if (
      userProfile.profilePhoto &&
      userProfile.profilePhoto !== userProfileDetails.user_profile.profile_photo
    ) {
      user_profile.append("profile_photo", userProfile.profilePhoto);
    } else if (userProfile.profilePhoto === "") {
      user_profile.append("profile_photo", userProfileDefault.profilePhoto);
    }

    if (
      userProfile.signature &&
      userProfile.signature !== userProfileDetails.user_profile.signature
    ) {
      user_profile.append("signature", userProfile.signature);
    } else if (userProfile.signature === "") {
      user_profile.append("signature", userProfileDefault.signature);
    }

    userProfile.title
      ? user_profile.append("title", userProfile.title)
      : user_profile.append("title", userProfileDefault.title);

    // Dispatch action
    dispatch(
      updateUserProfileAction(userProfileDetails.user_profile.id, user_profile)
    );
  };

  // Resend email confirmation
  const resendEmailConfirmation = () => {
    dispatch(
      confirmEmailResendAction({ email: userProfileDetails.user_profile.email })
    );
  };

  useEffect(() => {
    if (userEmailConfirmResend.success) {
      AppToaster.show({
        icon: "envelope",
        intent: "success",
        message: "Email sent.",
      });

      dispatch(resetConfirmEmailResendAction());
    } else if (userEmailConfirmResend.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not send the email. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [userEmailConfirmResend.loading]);

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ width: "100%" }}>
              <H5>Enter your profile information</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px", width: "35%" }}>
                  <img
                    alt="profile"
                    src={
                      userProfile.profilePhoto
                        ? userProfile.profilePhoto !==
                          userProfileDetails.user_profile.profile_photo
                          ? URL.createObjectURL(
                              new Blob([userProfile.profilePhoto])
                            )
                          : userProfileDetails.user_profile.profile_photo
                        : "/images/no_photo.png"
                    }
                    style={{
                      aspectRatio: "1/1",
                      background: "white",
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: "50%",
                      margin: "10px 0 10px 0",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>

                <div style={{ marginLeft: "15px", width: "65%" }}>
                  {/* Name */}
                  <FormDrawerTextField2
                    error={userProfileUpdate.error}
                    fieldName="name"
                    icon="person"
                    label="Name"
                    placeholder="Enter your full name"
                    setValueObject={setUserProfile}
                    setValueVariable="name"
                    value={userProfile.name}
                  />

                  {/* Title */}
                  <FormDrawerTextField2
                    error={userProfileUpdate.error}
                    fieldName="title"
                    icon="layout-hierarchy"
                    label="Title"
                    placeholder="Enter your job title"
                    setValueObject={setUserProfile}
                    setValueVariable="title"
                    value={userProfile.title}
                  />

                  {/* Phone */}
                  <FormDrawerTextField2
                    error={userProfileUpdate.error}
                    fieldName="phone"
                    helperText="Include the country code"
                    icon="phone"
                    label="Phone"
                    placeholder="Enter your phone number"
                    setValueObject={setUserProfile}
                    setValueVariable="phone"
                    value={userProfile.phone}
                  />

                  {/* Contact email */}
                  <FormDrawerTextField2
                    error={userProfileUpdate.error}
                    fieldName="contact_email"
                    helperText="Your contact email may be different to your login email"
                    icon="envelope"
                    label="Contact email"
                    placeholder="Enter your contact email"
                    setValueObject={setUserProfile}
                    setValueVariable="contactEmail"
                    value={userProfile.contactEmail}
                  />

                  {/* LinkedIn page */}
                  <FormDrawerTextField2
                    error={userProfileUpdate.error}
                    fieldName="linkedin_page"
                    helperText="You can copy and paste the full url from your browser"
                    icon="social-media"
                    label="LinkedIn page"
                    placeholder="Enter the full url of your LinkedIn page"
                    setValueObject={setUserProfile}
                    setValueVariable="linkedinPage"
                    value={userProfile.linkedinPage}
                  />

                  {/* Signature */}
                  <FormDrawerFileField2
                    error={userProfileUpdate.error}
                    fileName="signature"
                    label="Signature"
                    placeholder="Upload your signature"
                    setValueObject={setUserProfile}
                    setValueFileVariable="signature"
                    setValueNameVariable="signatureName"
                    valueFile={userProfile.signature}
                    valueName={userProfile.signatureName}
                  />

                  {/* Signature preview */}
                  <div style={{ margin: "0 0 20px 0" }}>
                    {userProfile.signature ? (
                      userProfile.signature !==
                      userProfileDetails.user_profile.signature ? (
                        <TabSectionFieldImage
                          image={URL.createObjectURL(
                            new Blob([userProfile.signature])
                          )}
                          height="100px"
                        />
                      ) : (
                        <TabSectionFieldImage
                          image={userProfileDetails.user_profile.signature}
                          height="100px"
                        />
                      )
                    ) : undefined}
                  </div>

                  {/* Profile photo */}
                  <FormDrawerFileField2
                    error={userProfileUpdate.error}
                    fileName="profile_photo"
                    label="Profile photo"
                    placeholder="Upload your profile photo"
                    setValueObject={setUserProfile}
                    setValueFileVariable="profilePhoto"
                    setValueNameVariable="profilePhotoName"
                    valueFile={userProfile.profilePhoto}
                    valueName={userProfile.profilePhotoName}
                  />
                </div>
              </div>

              {/* About */}
              <FormDrawerTextAreaField3
                error={userProfileUpdate.error}
                fieldName="about"
                label="About"
                placeholder="Enter a brief introduction"
                setValueObject={setUserProfile}
                setValueVariable="about"
                value={userProfile.about}
              />
            </Card>
          </div>
        }
        error={userProfileUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Profile"
            view="Detail"
          />
        }
        helpTitle="Filling your profile"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={userProfileDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listUserProfileDetailsAction());
        }}
        resetAction={() => {
          dispatch(resetUpdateUserProfileAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={userProfileUpdate.success}
        saveSuccessMessage="User profile updated."
        saving={userProfileUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* Preferences form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Set your preferences</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Send event notifications */}
            <FormDrawerSwitchField
              error={userProfileUpdate.error}
              fieldName="send_events"
              helperText="Select to receive notifications about your work on advaise.app"
              label="Send event notifications"
              setValueObject={setUserProfile}
              setValueVariable="sendEvents"
              value={userProfile.sendEvents}
            />

            {/* Provide feedback */}
            <FormDrawerSwitchField
              error={userProfileUpdate.error}
              fieldName="provide_feedback"
              helperText="This option allows us to ask about your experience with advaise.app"
              label="Provide feedback"
              setValueObject={setUserProfile}
              setValueVariable="provideFeedback"
              value={userProfile.provideFeedback}
            />

            {/* Notify of updates */}
            <FormDrawerSwitchField
              error={userProfileUpdate.error}
              fieldName="notify_of_updates"
              helperText="This option allows us to email you about updates to advaise.app"
              label="Notify of updates"
              setValueObject={setUserProfile}
              setValueVariable="notifyOfUpdates"
              value={userProfile.notifyOfUpdates}
            />

            {/* Send offers */}
            <FormDrawerSwitchField
              error={userProfileUpdate.error}
              fieldName="send_offers"
              helperText="This option allows us to email you advaise.app offers"
              label="Send offers"
              setValueObject={setUserProfile}
              setValueVariable="sendOffers"
              value={userProfile.sendOffers}
            />
          </Card>
        }
        error={userProfileUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update preferences"
            module="Profile"
            view="Detail"
          />
        }
        helpTitle="Updating your preferences"
        icon="edit"
        isOpen={updatePreferencesDrawerIsOpen}
        loading={userProfileDetails.loading}
        onSave={updatePreferences}
        refreshList={() => {
          dispatch(listUserProfileDetailsAction());
        }}
        resetAction={() => {
          dispatch(resetUpdateUserProfileAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={userProfileUpdate.success}
        saveSuccessMessage="User profile updated."
        saving={userProfileUpdate.loading}
        setIsOpen={setUpdatePreferencesDrawerIsOpen}
        size="small"
        title="Update preferences"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        title="Profile"
      />

      {/* Error alerts */}
      {/* User profile loading error */}
      {userProfileDetails.error && (
        <ErrorAlert message="We couldn't load your user profile. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="User profile"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <div>
            {!userProfileDetails.user_profile.email_verified && (
              <Callout intent="warning" style={{ margin: "0 0 15px 0" }}>
                Please confirm your email. You should have received a message
                with instructions. If you cannot find it, the system can{" "}
                <LinkTag
                  intent="warning"
                  onClick={resendEmailConfirmation}
                  label="resend"
                />{" "}
                the confirmation email.
              </Callout>
            )}

            {/* Summary */}
            <TabSection2
              buttons={
                <Button
                  icon="edit"
                  intent="primary"
                  onClick={() => {
                    dispatch(listUserProfileDetailsAction());

                    setUpdateSummaryDrawerIsOpen(true);
                  }}
                  text="Edit"
                />
              }
              content={
                <TabSectionField
                  bottom
                  label={
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <img
                        alt="profile"
                        src={
                          userProfileDetails.user_profile.profile_photo
                            ? userProfileDetails.user_profile.profile_photo
                            : "/images/no_photo.png"
                        }
                        style={{
                          background: "white",
                          border: `1px solid ${
                            lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                          }`,
                          borderRadius: "50%",
                          height: "150px",
                          margin: "10px 0 10px 0",
                          objectFit: "contain",
                          width: "150px",
                        }}
                      />

                      <Text ellipsize>
                        {userProfileDetails.user_profile.email}
                      </Text>
                    </div>
                  }
                  value={
                    <TabSectionFieldMultifieldCard
                      children={
                        <>
                          {/* User Id */}
                          <TabSectionField
                            label="User Id"
                            loading={userProfileDetails.loading}
                            value={userProfileDetails.user_profile.user_id}
                          />

                          {/* Name */}
                          <TabSectionField
                            label="Name"
                            loading={userProfileDetails.loading}
                            value={userProfileDetails.user_profile.name}
                          />

                          {/* Title */}
                          <TabSectionField
                            label="Title"
                            loading={userProfileDetails.loading}
                            value={userProfileDetails.user_profile.title}
                          />

                          {/* About */}
                          <TabSectionField
                            label="About"
                            loading={userProfileDetails.loading}
                            value={userProfileDetails.user_profile.about}
                          />

                          {/* Phone */}
                          <TabSectionField
                            label="Phone"
                            loading={userProfileDetails.loading}
                            value={
                              userProfileDetails.user_profile.display_phone
                            }
                          />

                          {/* Contact email */}
                          <TabSectionField
                            label="Contact email"
                            loading={userProfileDetails.loading}
                            value={
                              <TabSectionEmailLink
                                email={
                                  userProfileDetails.user_profile.contact_email
                                }
                              />
                            }
                          />

                          {/* LinkedIn page */}
                          <TabSectionField
                            label="LinkedIn page"
                            loading={userProfileDetails.loading}
                            value={
                              userProfileDetails.user_profile.linkedin_page ? (
                                <a
                                  href={
                                    userProfileDetails.user_profile
                                      .linkedin_page
                                  }
                                  rel="noopener noreferrer"
                                  target="_self"
                                >
                                  {
                                    userProfileDetails.user_profile
                                      .linkedin_page
                                  }
                                </a>
                              ) : (
                                "-"
                              )
                            }
                          />

                          {/* Signature */}
                          <TabSectionField
                            bottom
                            label="Signature"
                            loading={userProfileDetails.loading}
                            value={
                              <TabSectionFieldImage
                                image={
                                  userProfileDetails.user_profile.signature
                                }
                                height="150px"
                              />
                            }
                          />
                        </>
                      }
                    />
                  }
                />
              }
              help="Review and update basic information about your profile"
              icon="id-number"
              title="Summary"
            />

            {/* Contact preferences */}
            <TabSection2
              buttons={
                <Button
                  icon="edit"
                  intent="primary"
                  onClick={() => {
                    dispatch(listUserProfileDetailsAction());

                    setUpdatePreferencesDrawerIsOpen(true);
                  }}
                  text="Edit"
                />
              }
              content={
                <>
                  {/* Send event notifications */}
                  <TabSectionField
                    helperText={
                      userProfileDetails.user_profile.send_events
                        ? "We may email you about events related to your work"
                        : "We will not email you about events related to your work"
                    }
                    label="Send event notifications"
                    loading={userProfileDetails.loading}
                    value={
                      <Tag
                        intent={
                          userProfileDetails.user_profile.send_events
                            ? "success"
                            : "danger"
                        }
                        round
                        style={{
                          textAlign: "center",
                          width: "75px",
                        }}
                      >
                        {userProfileDetails.user_profile.send_events
                          ? "Yes"
                          : "No"}
                      </Tag>
                    }
                  />

                  {/* Provide feedback */}
                  <TabSectionField
                    helperText={
                      userProfileDetails.user_profile.provide_feedback
                        ? "We may ask about your experience with advaise.app"
                        : "We will not ask about your experience with advaise.app"
                    }
                    label="Provide feedback"
                    loading={userProfileDetails.loading}
                    value={
                      <Tag
                        intent={
                          userProfileDetails.user_profile.provide_feedback
                            ? "success"
                            : "danger"
                        }
                        round
                        style={{
                          textAlign: "center",
                          width: "75px",
                        }}
                      >
                        {userProfileDetails.user_profile.provide_feedback
                          ? "Yes"
                          : "No"}
                      </Tag>
                    }
                  />

                  {/* Notify of updates */}
                  <TabSectionField
                    helperText={
                      userProfileDetails.user_profile.notify_of_updates
                        ? "We may email you about updates to advaise.app"
                        : "We will not email you about updates to advaise.app"
                    }
                    label="Notify of updates"
                    loading={userProfileDetails.loading}
                    value={
                      <Tag
                        intent={
                          userProfileDetails.user_profile.notify_of_updates
                            ? "success"
                            : "danger"
                        }
                        round
                        style={{
                          textAlign: "center",
                          width: "75px",
                        }}
                      >
                        {userProfileDetails.user_profile.notify_of_updates
                          ? "Yes"
                          : "No"}
                      </Tag>
                    }
                  />

                  {/* Send offers */}
                  <TabSectionField
                    bottom
                    helperText={
                      userProfileDetails.user_profile.send_offers
                        ? "We may email you advaise.app offers"
                        : "We will not email you advaise.app offers"
                    }
                    label="Send offers"
                    loading={userProfileDetails.loading}
                    value={
                      <Tag
                        intent={
                          userProfileDetails.user_profile.send_offers
                            ? "success"
                            : "danger"
                        }
                        round
                        style={{
                          textAlign: "center",
                          width: "75px",
                        }}
                      >
                        {userProfileDetails.user_profile.send_offers
                          ? "Yes"
                          : "No"}
                      </Tag>
                    }
                  />
                </>
              }
              help="Review your contact preferences"
              icon="settings"
              title="Contact preferences"
            />
          </div>
        }
      />
    </div>
  );
}

export default UserProfileScreen;
