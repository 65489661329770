export const EXPENSE_LIST_REQUEST = "EXPENSE_LIST_REQUEST";
export const EXPENSE_LIST_SUCCESS = "EXPENSE_LIST_SUCCESS";
export const EXPENSE_LIST_FAIL = "EXPENSE_LIST_FAIL";
export const EXPENSE_LIST_RESET = "EXPENSE_LIST_RESET";

export const EXPENSE_CREATE_REQUEST = "EXPENSE_CREATE_REQUEST";
export const EXPENSE_CREATE_SUCCESS = "EXPENSE_CREATE_SUCCESS";
export const EXPENSE_CREATE_FAIL = "EXPENSE_CREATE_FAIL";
export const EXPENSE_CREATE_RESET = "EXPENSE_CREATE_RESET";

export const EXPENSE_DETAILS_REQUEST = "EXPENSE_DETAILS_REQUEST";
export const EXPENSE_DETAILS_SUCCESS = "EXPENSE_DETAILS_SUCCESS";
export const EXPENSE_DETAILS_FAIL = "EXPENSE_DETAILS_FAIL";
export const EXPENSE_DETAILS_RESET = "EXPENSE_DETAILS_RESET";

export const EXPENSE_UPDATE_REQUEST = "EXPENSE_UPDATE_REQUEST";
export const EXPENSE_UPDATE_SUCCESS = "EXPENSE_UPDATE_SUCCESS";
export const EXPENSE_UPDATE_FAIL = "EXPENSE_UPDATE_FAIL";
export const EXPENSE_UPDATE_RESET = "EXPENSE_UPDATE_RESET";

export const EXPENSE_DELETE_REQUEST = "EXPENSE_DELETE_REQUEST";
export const EXPENSE_DELETE_SUCCESS = "EXPENSE_DELETE_SUCCESS";
export const EXPENSE_DELETE_FAIL = "EXPENSE_DELETE_FAIL";
export const EXPENSE_DELETE_RESET = "EXPENSE_DELETE_RESET";
