// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Invoice milestone
import { listInvoiceMilestonesAction } from "../actions/invoiceMilestoneActions";

// Main function
function InvoiceMilestoneInfo({
  additionalInfo,
  milestoneData,
  milestoneId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Invoice milestone
  const invoiceMilestoneList = useSelector(
    (state) => state.invoiceMilestoneList
  );

  // Refresh invoice milestone data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [milestone, setMilestone] = useState({});

  useEffect(() => {
    if (milestoneData) {
      setMilestone(milestoneData);

      setLoading(false);
    }
  }, [milestoneData]);

  useEffect(() => {
    if (!milestoneData && !invoiceMilestoneList.loading) {
      if (invoiceMilestoneList.success) {
        const thisMilestone = invoiceMilestoneList.invoice_milestones.filter(
          (invoice_milestone) => invoice_milestone.id === milestoneId
        );

        if (thisMilestone.length > 0) {
          setMilestone(thisMilestone[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listInvoiceMilestonesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (invoiceMilestoneList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [invoiceMilestoneList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{milestone.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {milestone.description ? (
              milestone.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default InvoiceMilestoneInfo;
