// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ApprovalDetailDrawer from "../components/ApprovalDetailDrawer";
import ClientFinancials from "../components/ClientFinancials";
import ContactCard from "../components/ContactCard";
import ContactInfo from "../components/ContactInfo";
import DataCardContainer from "../components/DataCardContainer";
import DataCardSimple from "../components/DataCardSimple";
import EngagementActualsCard from "../components/EngagementActualsCard";
import EngagementPlanAtCompletionCard from "../components/EngagementPlanAtCompletionCard";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import InfoPopup from "../components/InfoPopup";
import ItemCardList from "../components/ItemCardList";
import Kanban from "../components/Kanban";
import KPICard from "../components/KPICard";
import MarketInfo from "../components/MarketInfo";
import Matrix from "../components/Matrix";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NeedInfo from "../components/NeedInfo";
import NewsCard from "../components/NewsCard";
import PermissionCard from "../components/PermissionCard";
import ProposalAverageValueCard from "../components/ProposalAverageValueCard";
import ProposalPipelineCard from "../components/ProposalPipelineCard";
import ProposalTotalValueCard from "../components/ProposalTotalValueCard";
import ProposalWinLossCard from "../components/ProposalWinLossCard";
import SearchField from "../components/SearchField";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";

// Utility imports
import generateKanbanDataFromClientActions from "../utilities/generateKanbanDataFromClientActions";
import generateYearList from "../utilities/generateYearList";
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Approval request
import {
  createApprovalRequestAction,
  deleteApprovalRequestAction,
  listApprovalRequestsAction,
  listApprovalRequestDetailsAction,
  resetCreateApprovalRequestAction,
  resetDeleteApprovalRequestAction,
  resetUpdateApprovalRequestAction,
  updateApprovalRequestAction,
} from "../actions/approvalRequestActions";

// Client
import {
  deleteClientAction,
  getIntelClientAction,
  listClientDetailsAction,
  resetDeleteClientAction,
  resetGetIntelClientAction,
  resetListClientsAction,
  resetUpdateClientAction,
  updateClientAction,
} from "../actions/clientActions";

// Client action
import {
  createClientProgramAction,
  deleteClientProgramAction,
  listClientProgramsAction,
  listClientProgramDetailsAction,
  resetCreateClientProgramAction,
  resetDeleteClientProgramAction,
  resetUpdateClientProgramAction,
  updateClientProgramAction,
} from "../actions/clientProgramActions";

// Client KPI
import {
  createClientKPIAction,
  deleteClientKPIAction,
  listClientKPIsAction,
  listClientKPIDetailsAction,
  resetCreateClientKPIAction,
  resetDeleteClientKPIAction,
  resetUpdateClientKPIAction,
  updateClientKPIAction,
} from "../actions/clientKPIActions";

// Client need
import {
  createClientNeedAction,
  deleteClientNeedAction,
  listClientNeedsAction,
  listClientNeedDetailsAction,
  resetCreateClientNeedAction,
  resetDeleteClientNeedAction,
  resetUpdateClientNeedAction,
  updateClientNeedAction,
} from "../actions/clientNeedActions";

// Client permission
import {
  createClientPermissionAction,
  deleteClientPermissionAction,
  listClientPermissionsAction,
  listClientPermissionDetailsAction,
  resetCreateClientPermissionAction,
  resetDeleteClientPermissionAction,
  resetUpdateClientPermissionAction,
  updateClientPermissionAction,
} from "../actions/clientPermissionActions";

// Client program
import {
  createClientActionAction,
  deleteClientActionAction,
  listClientActionsAction,
  listClientActionDetailsAction,
  resetCreateClientActionAction,
  resetDeleteClientActionAction,
  resetUpdateClientActionAction,
  updateClientActionAction,
} from "../actions/clientActionActions";

// Client question
import {
  createClientQuestionAction,
  deleteClientQuestionAction,
  listClientQuestionsAction,
  listClientQuestionDetailsAction,
  resetCreateClientQuestionAction,
  resetDeleteClientQuestionAction,
  resetUpdateClientQuestionAction,
  updateClientQuestionAction,
} from "../actions/clientQuestionActions";

// Client stakeholder
import {
  createClientStakeholderAction,
  deleteClientStakeholderAction,
  listClientStakeholdersAction,
  listClientStakeholderDetailsAction,
  resetCreateClientStakeholderAction,
  resetDeleteClientStakeholderAction,
  resetUpdateClientStakeholderAction,
  updateClientStakeholderAction,
} from "../actions/clientStakeholderActions";

// Contact
import { listContactsAction } from "../actions/contactActions";

// Engagement
import {
  listEngagementsAction,
  resetListEngagementDetailsAction,
} from "../actions/engagementActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Market
import { listMarketsAction } from "../actions/marketActions";

// Need
import { listNeedsAction } from "../actions/needActions";

// Proposal
import {
  listProposalsAction,
  resetListProposalDetailsAction,
} from "../actions/proposalActions";

// Main function
function ClientDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Client id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Approval request
  const approvalRequestCreate = useSelector(
    (state) => state.approvalRequestCreate
  );

  const approvalRequestDelete = useSelector(
    (state) => state.approvalRequestDelete
  );

  const approvalRequestDetails = useSelector(
    (state) => state.approvalRequestDetails
  );

  const approvalRequestList = useSelector((state) => state.approvalRequestList);

  const approvalRequestUpdate = useSelector(
    (state) => state.approvalRequestUpdate
  );

  // Client
  const clientDelete = useSelector((state) => state.clientDelete);

  const clientDetails = useSelector((state) => state.clientDetails);

  const clientIntel = useSelector((state) => state.clientIntel);

  const clientUpdate = useSelector((state) => state.clientUpdate);

  // Client action
  const clientActionCreate = useSelector((state) => state.clientActionCreate);

  const clientActionDelete = useSelector((state) => state.clientActionDelete);

  const clientActionDetails = useSelector((state) => state.clientActionDetails);

  const clientActionList = useSelector((state) => state.clientActionList);

  const clientActionUpdate = useSelector((state) => state.clientActionUpdate);

  // Client KPI
  const clientKPICreate = useSelector((state) => state.clientKPICreate);

  const clientKPIDelete = useSelector((state) => state.clientKPIDelete);

  const clientKPIDetails = useSelector((state) => state.clientKPIDetails);

  const clientKPIList = useSelector((state) => state.clientKPIList);

  const clientKPIUpdate = useSelector((state) => state.clientKPIUpdate);

  // Client need
  const clientNeedCreate = useSelector((state) => state.clientNeedCreate);

  const clientNeedDelete = useSelector((state) => state.clientNeedDelete);

  const clientNeedDetails = useSelector((state) => state.clientNeedDetails);

  const clientNeedList = useSelector((state) => state.clientNeedList);

  const clientNeedUpdate = useSelector((state) => state.clientNeedUpdate);

  // Client permission
  const clientPermissionCreate = useSelector(
    (state) => state.clientPermissionCreate
  );

  const clientPermissionDelete = useSelector(
    (state) => state.clientPermissionDelete
  );

  const clientPermissionDetails = useSelector(
    (state) => state.clientPermissionDetails
  );

  const clientPermissionList = useSelector(
    (state) => state.clientPermissionList
  );

  const clientPermissionUpdate = useSelector(
    (state) => state.clientPermissionUpdate
  );

  // Client program
  const clientProgramCreate = useSelector((state) => state.clientProgramCreate);

  const clientProgramDelete = useSelector((state) => state.clientProgramDelete);

  const clientProgramDetails = useSelector(
    (state) => state.clientProgramDetails
  );

  const clientProgramList = useSelector((state) => state.clientProgramList);

  const clientProgramUpdate = useSelector((state) => state.clientProgramUpdate);

  // Client question
  const clientQuestionCreate = useSelector(
    (state) => state.clientQuestionCreate
  );

  const clientQuestionDelete = useSelector(
    (state) => state.clientQuestionDelete
  );

  const clientQuestionDetails = useSelector(
    (state) => state.clientQuestionDetails
  );

  const clientQuestionList = useSelector((state) => state.clientQuestionList);

  const clientQuestionUpdate = useSelector(
    (state) => state.clientQuestionUpdate
  );

  // Client stakeholder
  const clientStakeholderCreate = useSelector(
    (state) => state.clientStakeholderCreate
  );

  const clientStakeholderDelete = useSelector(
    (state) => state.clientStakeholderDelete
  );

  const clientStakeholderDetails = useSelector(
    (state) => state.clientStakeholderDetails
  );

  const clientStakeholderList = useSelector(
    (state) => state.clientStakeholderList
  );

  const clientStakeholderUpdate = useSelector(
    (state) => state.clientStakeholderUpdate
  );

  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Engagement
  const engagementList = useSelector((state) => state.engagementList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Market
  const marketList = useSelector((state) => state.marketList);

  // Need
  const needList = useSelector((state) => state.needList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Proposal
  const proposalList = useSelector((state) => state.proposalList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("client-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Client questions
  const [addQuestionDrawerIsOpen, setAddQuestionDrawerIsOpen] = useState(false);
  const [updateQuestionDrawerIsOpen, setUpdateQuestionDrawerIsOpen] =
    useState(false);

  // Client performance analysis
  const [addClientKPIDrawerIsOpen, setAddClientKPIDrawerIsOpen] =
    useState(false);
  const [updateClientKPIDrawerIsOpen, setUpdateClientKPIDrawerIsOpen] =
    useState(false);

  // Needs
  const [addNeedDrawerIsOpen, setAddNeedDrawerIsOpen] = useState(false);
  const [updateNeedDrawerIsOpen, setUpdateNeedDrawerIsOpen] = useState(false);

  // Actions
  const [addActionDrawerIsOpen, setAddActionDrawerIsOpen] = useState(false);
  const [updateActionDrawerIsOpen, setUpdateActionDrawerIsOpen] =
    useState(false);

  // Investment programs
  const [addClientProgramDrawerIsOpen, setAddClientProgramDrawerIsOpen] =
    useState(false);
  const [updateClientProgramDrawerIsOpen, setUpdateClientProgramDrawerIsOpen] =
    useState(false);

  // Key stakeholders
  const [
    addClientStakeholderDrawerIsOpen,
    setAddClientStakeholderDrawerIsOpen,
  ] = useState(false);
  const [
    updateClientStakeholderDrawerIsOpen,
    setUpdateClientStakeholderDrawerIsOpen,
  ] = useState(false);

  // Approval requests
  const [addApprovalRequestDrawerIsOpen, setAddApprovalRequestDrawerIsOpen] =
    useState(false);
  const [
    updateApprovalRequestDrawerIsOpen,
    setUpdateApprovalRequestDrawerIsOpen,
  ] = useState(false);
  const [viewApprovalDrawerIsOpen, setViewApprovalDrawerIsOpen] =
    useState(false);

  // Other
  const [initialiseActionKanban, setInitialiseActionKanban] = useState(true);
  const [showActionKanban, setShowActionKanban] = useState(false);

  // Search and filter variables
  // Approval request
  const approvalRequestQueryDefault = {
    approver: "",
    decisionRight: "",
    itemId: id,
    itemType: "client",
    organisation: activeOrganisation.id,
    outcome: "",
    page: 1,
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalRequestQuery, setApprovalRequestQuery] = useState({
    ...approvalRequestQueryDefault,
  });

  // Client action
  const clientActionQueryDefault = {
    category: "",
    client: id,
    dueEnd: "",
    dueStart: "",
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [clientActionQuery, setClientActionQuery] = useState({
    ...clientActionQueryDefault,
  });

  // Client KPI
  const clientKPIQueryDefault = {
    client: id,
    page: 1,
    rating: "",
    searchString: "",
    sortField: "",
    units: "",
  };
  const [clientKPIQuery, setClientKPIQuery] = useState({
    ...clientKPIQueryDefault,
  });

  // Client need
  const clientNeedQueryDefault = {
    client: id,
    page: 1,
    searchString: "",
  };
  const [clientNeedQuery, setClientNeedQuery] = useState({
    ...clientNeedQueryDefault,
  });

  // Client permission
  const clientPermissionQueryDefault = {
    page: 1,
    client: id,
    searchString: "",
  };
  const [clientPermissionQuery, setClientPermissionQuery] = useState({
    ...clientPermissionQueryDefault,
  });

  // Client program
  const clientProgramQueryDefault = {
    client: id,
    page: 1,
    searchString: "",
    sortField: "",
    sponsor: "",
    year: "",
  };
  const [clientProgramQuery, setClientProgramQuery] = useState({
    ...clientProgramQueryDefault,
  });

  // Client question
  const clientQuestionQueryDefault = {
    category: "",
    client: id,
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [clientQuestionQuery, setClientQuestionQuery] = useState({
    ...clientQuestionQueryDefault,
  });

  // Client stakeholder
  const clientStakeholderQueryDefault = {
    client: id,
    page: 1,
    searchString: "",
  };
  const [clientStakeholderQuery, setClientStakeholderQuery] = useState({
    ...clientStakeholderQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Dates
  const today = new Date();

  // Engagement
  const engagementQueryDefault = {
    client: id,
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    sponsor: "",
    status: "",
    user: "",
  };
  const [engagementQuery, setEngagementQuery] = useState({
    ...engagementQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Market
  const marketQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [marketQuery, setMarketQuery] = useState({
    ...marketQueryDefault,
  });

  // Need
  const needQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [needQuery, setNeedQuery] = useState({
    ...needQueryDefault,
  });

  // Proposal
  const proposalQueryDefault = {
    client: id,
    contact: "",
    dueEnd: "",
    dueStart: "",
    exclude: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
    unlinked: "",
    user: "",
  };
  const [proposalQuery, setProposalQuery] = useState({
    ...proposalQueryDefault,
  });

  // Form variables
  // Approval request
  const approvalRequestDefault = {
    approver: "",
    approverName: "",
    comments: "",
    decisionRight: "Client account plan",
    status: "Draft",
  };
  const [approvalRequest, setApprovalRequest] = useState({
    ...approvalRequestDefault,
  });

  // Client
  const clientDefault = {
    about: "",
    address: "",
    logo: "",
    logoName: "",
    manager: "",
    managerName: "",
    market: "",
    marketName: "",
    name: "",
    restricted: "",
    ticker: "",
    website: "",
  };
  const [client, setClient] = useState({
    ...clientDefault,
  });

  // Client action
  const clientActionDefault = {
    action: "",
    category: "Other",
    comments: "",
    due: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "In-progress",
  };
  const [clientAction, setClientAction] = useState({
    ...clientActionDefault,
  });

  // Client KPI
  const clientKPIDefault = {
    achieved: "",
    comments: "",
    kpi: "",
    rating: "Met",
    target: "",
    units: "",
    year: "",
  };
  const [clientKPI, setClientKPI] = useState({ ...clientKPIDefault });

  // Client need
  const clientNeedDefault = {
    need: "",
    needDescription: "",
    needName: "",
    notes: "",
  };
  const [clientNeed, setClientNeed] = useState({
    ...clientNeedDefault,
  });

  // Client permission
  const clientPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [clientPermission, setClientPermission] = useState({
    ...clientPermissionDefault,
  });

  // Client program
  const clientProgramDefault = {
    expectedRevenue: "",
    name: "",
    notes: "",
    sponsor: "",
    sponsorName: "",
    year: "",
  };
  const [clientProgram, setClientProgram] = useState({
    ...clientProgramDefault,
  });

  // Client question
  const clientQuestionDefault = {
    answer: "",
    category: "Other",
    name: "",
    owner: "",
    ownerName: "",
    question: "",
    status: "Researching",
  };
  const [clientQuestion, setClientQuestion] = useState({
    ...clientQuestionDefault,
  });

  // Client stakeholder
  const clientStakeholderDefault = {
    comments: "",
    levelOfInfluence: 3,
    relationshipOwner: "",
    relationshipOwnerName: "",
    stakeholder: "",
    stakeholderName: "",
  };
  const [clientStakeholder, setClientStakeholder] = useState({
    ...clientStakeholderDefault,
  });

  // Other
  const [clientDeleteKey, setClientDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval request
    setApprovalRequest({
      ...approvalRequestDefault,
    });

    // Client
    setClient({
      ...clientDefault,
    });

    // Client action
    setClientAction({
      ...clientActionDefault,
    });

    // Client KPI
    setClientKPI({
      ...clientKPIDefault,
    });

    // Client need
    setClientNeed({
      ...clientNeedDefault,
    });

    // Client permission
    setClientPermission({
      ...clientPermissionDefault,
    });

    // Client program
    setClientProgram({
      ...clientProgramDefault,
    });

    // Client question
    setClientQuestion({
      ...clientQuestionDefault,
    });

    // Client stakeholder
    setClientStakeholder({
      ...clientStakeholderDefault,
    });

    // Query variables
    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Market
    setMarketQuery({
      ...marketQueryDefault,
    });

    // Need
    setNeedQuery({
      ...needQueryDefault,
    });
  };

  // Streaming data websocket connection
  const { lastJsonMessage } = useWebSocket(
    `${BACKEND_WS_URL}/api/v1/data/client/${id}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  // Initialise drawer variables for approval request
  useEffect(() => {
    setApprovalRequest({
      approver: approvalRequestDetails.approval_request.approver
        ? approvalRequestDetails.approval_request.approver
        : approvalRequestDefault.approver,
      approverName: approvalRequestDetails.approval_request.approver_name
        ? approvalRequestDetails.approval_request.approver_name
        : approvalRequestDefault.approverName,
      comments: approvalRequestDetails.approval_request.comments
        ? approvalRequestDetails.approval_request.comments
        : approvalRequestDefault.comments,
      decisionRight: approvalRequestDetails.approval_request.decision_right
        ? approvalRequestDetails.approval_request.decision_right
        : approvalRequestDefault.decisionRight,
      status: approvalRequestDetails.approval_request.status
        ? approvalRequestDetails.approval_request.status
        : approvalRequestDefault.status,
    });
    // eslint-disable-next-line
  }, [approvalRequestDetails.approval_request]);

  // Initialise drawer variables for client
  useEffect(() => {
    setClient({
      about: clientDetails.client.about
        ? clientDetails.client.about
        : clientDefault.about,
      address: clientDetails.client.address
        ? clientDetails.client.address
        : clientDefault.address,
      logo: clientDetails.client.logo
        ? clientDetails.client.logo
        : clientDefault.logo,
      logoName: clientDetails.client.logo
        ? clientDetails.client.logo.split("/").pop()
        : clientDefault.logoName,
      manager: clientDetails.client.manager
        ? clientDetails.client.manager
        : clientDefault.manager,
      managerName: clientDetails.client.manager_name
        ? clientDetails.client.manager_name
        : clientDefault.managerName,
      market: clientDetails.client.market
        ? clientDetails.client.market
        : clientDefault.market,
      marketName: clientDetails.client.market_name
        ? clientDetails.client.market_name
        : clientDefault.marketName,
      name: clientDetails.client.name
        ? clientDetails.client.name
        : clientDefault.name,
      restricted:
        clientDetails.client.restricted != null
          ? clientDetails.client.restricted
          : clientDefault.restricted,
      ticker: clientDetails.client.ticker
        ? clientDetails.client.ticker
        : clientDefault.ticker,
      website: clientDetails.client.website
        ? clientDetails.client.website
        : clientDefault.website,
    });
    // eslint-disable-next-line
  }, [clientDetails.client]);

  // Initialise drawer variables for client action
  useEffect(() => {
    setClientAction({
      action: clientActionDetails.client_action.action
        ? clientActionDetails.client_action.action
        : clientActionDefault.action,
      category: clientActionDetails.client_action.category
        ? clientActionDetails.client_action.category
        : clientActionDefault.category,
      comments: clientActionDetails.client_action.comments
        ? clientActionDetails.client_action.comments
        : clientActionDefault.comments,
      due: clientActionDetails.client_action.due
        ? new Date(clientActionDetails.client_action.due)
        : clientActionDefault.due,
      name: clientActionDetails.client_action.name
        ? clientActionDetails.client_action.name
        : clientActionDefault.name,
      owner: clientActionDetails.client_action.owner
        ? clientActionDetails.client_action.owner
        : clientActionDefault.owner,
      ownerName: clientActionDetails.client_action.owner_name
        ? clientActionDetails.client_action.owner_name
        : clientActionDefault.ownerName,
      status: clientActionDetails.client_action.status
        ? clientActionDetails.client_action.status
        : clientActionDefault.status,
    });
    // eslint-disable-next-line
  }, [clientActionDetails.client_action]);

  // Initialise drawer variables for client KPI
  useEffect(() => {
    setClientKPI({
      achieved: clientKPIDetails.client_kpi.achieved
        ? clientKPIDetails.client_kpi.achieved
        : clientKPIDefault.achieved,
      comments: clientKPIDetails.client_kpi.comments
        ? clientKPIDetails.client_kpi.comments
        : clientKPIDefault.comments,
      kpi: clientKPIDetails.client_kpi.kpi
        ? clientKPIDetails.client_kpi.kpi
        : clientKPIDefault.kpi,
      rating: clientKPIDetails.client_kpi.rating
        ? clientKPIDetails.client_kpi.rating
        : clientKPIDefault.rating,
      target: clientKPIDetails.client_kpi.target
        ? clientKPIDetails.client_kpi.target
        : clientKPIDefault.target,
      units: clientKPIDetails.client_kpi.units
        ? clientKPIDetails.client_kpi.units
        : clientKPIDefault.units,
      year: clientKPIDetails.client_kpi.year
        ? clientKPIDetails.client_kpi.year
        : clientKPIDefault.year,
    });
    // eslint-disable-next-line
  }, [clientKPIDetails.client_kpi]);

  // Initialise drawer variables for client need
  useEffect(() => {
    setClientNeed({
      need: clientNeedDetails.client_need.need
        ? clientNeedDetails.client_need.need
        : clientNeedDefault.need,
      needName: clientNeedDetails.client_need.need_data
        ? clientNeedDetails.client_need.need_data.name
        : clientNeedDefault.needName,
      notes: clientNeedDetails.client_need.notes
        ? clientNeedDetails.client_need.notes
        : clientNeedDefault.notes,
    });
    // eslint-disable-next-line
  }, [clientNeedDetails.client_need]);

  // Initialise drawer variables for client permission
  useEffect(() => {
    setClientPermission({
      level: clientPermissionDetails.client_permission.level
        ? clientPermissionDetails.client_permission.level
        : clientPermissionDefault.level,
      manager: clientPermissionDetails.client_permission.manager
        ? clientPermissionDetails.client_permission.manager
        : clientPermissionDefault.manager,
      managerName: clientPermissionDetails.client_permission.manager_name
        ? clientPermissionDetails.client_permission.manager_name
        : clientPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [clientPermissionDetails.client_permission]);

  // Initialise drawer variables for client program
  useEffect(() => {
    setClientProgram({
      expectedRevenue: clientProgramDetails.client_program.expected_revenue
        ? clientProgramDetails.client_program.expected_revenue
        : clientProgramDefault.expectedRevenue,
      name: clientProgramDetails.client_program.name
        ? clientProgramDetails.client_program.name
        : clientProgramDefault.name,
      notes: clientProgramDetails.client_program.notes
        ? clientProgramDetails.client_program.notes
        : clientProgramDefault.notes,
      sponsor: clientProgramDetails.client_program.sponsor
        ? clientProgramDetails.client_program.sponsor
        : clientProgramDefault.sponsor,
      sponsorName: clientProgramDetails.client_program.sponsor_name
        ? clientProgramDetails.client_program.sponsor_name
        : clientProgramDefault.sponsorName,
      year: clientProgramDetails.client_program.year
        ? clientProgramDetails.client_program.year
        : clientProgramDefault.year,
    });
    // eslint-disable-next-line
  }, [clientProgramDetails.client_program]);

  // Initialise drawer variables for client question
  useEffect(() => {
    setClientQuestion({
      answer: clientQuestionDetails.client_question.answer
        ? clientQuestionDetails.client_question.answer
        : clientQuestionDefault.answer,
      category: clientQuestionDetails.client_question.category
        ? clientQuestionDetails.client_question.category
        : clientQuestionDefault.category,
      name: clientQuestionDetails.client_question.name
        ? clientQuestionDetails.client_question.name
        : clientQuestionDefault.name,
      owner: clientQuestionDetails.client_question.owner
        ? clientQuestionDetails.client_question.owner
        : clientQuestionDefault.owner,
      ownerName: clientQuestionDetails.client_question.owner_name
        ? clientQuestionDetails.client_question.owner_name
        : clientQuestionDefault.ownerName,
      question: clientQuestionDetails.client_question.question
        ? clientQuestionDetails.client_question.question
        : clientQuestionDefault.question,
      status: clientQuestionDetails.client_question.status
        ? clientQuestionDetails.client_question.status
        : clientQuestionDefault.status,
    });
    // eslint-disable-next-line
  }, [clientQuestionDetails.client_question]);

  // Initialise drawer variables for client stakeholder
  useEffect(() => {
    setClientStakeholder({
      comments: clientStakeholderDetails.client_stakeholder.comments
        ? clientStakeholderDetails.client_stakeholder.comments
        : clientStakeholderDefault.comments,
      levelOfInfluence: clientStakeholderDetails.client_stakeholder
        .level_of_influence
        ? clientStakeholderDetails.client_stakeholder.level_of_influence
        : clientStakeholderDefault.levelOfInfluence,
      relationshipOwner: clientStakeholderDetails.client_stakeholder
        .relationship_owner
        ? clientStakeholderDetails.client_stakeholder.relationship_owner
        : clientStakeholderDefault.relationshipOwner,
      relationshipOwnerName: clientStakeholderDetails.client_stakeholder
        .relationship_owner_name
        ? clientStakeholderDetails.client_stakeholder.relationship_owner_name
        : clientStakeholderDefault.relationshipOwnerName,
      stakeholder: clientStakeholderDetails.client_stakeholder.stakeholder
        ? clientStakeholderDetails.client_stakeholder.stakeholder
        : clientStakeholderDefault.stakeholder,
      stakeholderName: clientStakeholderDetails.client_stakeholder
        .stakeholder_data
        ? clientStakeholderDetails.client_stakeholder.stakeholder_data.name
        : clientStakeholderDefault.stakeholderName,
    });
    // eslint-disable-next-line
  }, [clientStakeholderDetails.client_stakeholder]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If client deleted redirect to clients
  useEffect(() => {
    if (clientDelete.success) {
      dispatch(resetDeleteClientAction());

      dispatch(resetListClientsAction());

      navigate("/clients");
    }
    // eslint-disable-next-line
  }, [clientDelete.success]);

  // Load client details
  useEffect(() => {
    dispatch(listClientDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter approval requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalRequestsAction({
            ...approvalRequestQuery,
            reviewedEnd: approvalRequestQuery.reviewedEnd
              ? getDjangoDate(approvalRequestQuery.reviewedEnd)
              : getDjangoDate(approvalRequestQueryDefault.reviewedEnd),
            reviewedStart: approvalRequestQuery.reviewedStart
              ? getDjangoDate(approvalRequestQuery.reviewedStart)
              : getDjangoDate(approvalRequestQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRequestQuery]);

  // Filter client actions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientActionsAction({
            ...clientActionQuery,
            dueEnd: clientActionQuery.dueEnd
              ? getDjangoDate(clientActionQuery.dueEnd)
              : "",
            dueStart: clientActionQuery.dueStart
              ? getDjangoDate(clientActionQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientActionQuery]);

  // Filter client KPIs
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientKPIsAction({
            ...clientKPIQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientKPIQuery]);

  // Filter client needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listClientNeedsAction({ ...clientNeedQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientNeedQuery]);

  // Filter client permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listClientPermissionsAction({ ...clientPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientPermissionQuery]);

  // Filter client programs
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listClientProgramsAction({ ...clientProgramQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientProgramQuery]);

  // Filter client questions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listClientQuestionsAction({ ...clientQuestionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuestionQuery]);

  // Filter client stakeholders
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientStakeholdersAction({
            ...clientStakeholderQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientStakeholderQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter engagements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementsAction({
            ...engagementQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter manager list
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    managers = managerList.managers.filter((manager) =>
      manager.approval_role_data !== null
        ? manager.approval_role_data.can_approve_client_account_plan &&
          manager.approval_role_data
            .can_approve_client_account_plan_threshold >=
            clientDetails.client.expected_revenue
        : false
    );

    setFilteredManagerList(managers);
  }, [
    addApprovalRequestDrawerIsOpen,
    clientDetails.client.expected_revenue,
    managerList.managers,
    updateApprovalRequestDrawerIsOpen,
  ]);

  // Filter markets
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listMarketsAction({
            ...marketQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketQuery]);

  // Filter needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNeedsAction({
            ...needQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuery]);

  // Filter proposals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalsAction({
            ...proposalQuery,
            dueEnd: proposalQuery.dueEnd
              ? getDjangoDate(proposalQuery.dueEnd)
              : getDjangoDate(proposalQueryDefault.dueEnd),
            dueStart: proposalQuery.dueStart
              ? getDjangoDate(proposalQuery.dueStart)
              : getDjangoDate(proposalQueryDefault.dueStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuery]);

  // Form submission
  // Approval request
  // Add approval request
  const addApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approval_request_data.append("item_id", id);

    approval_request_data.append("organisation", activeOrganisation.id);

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(createApprovalRequestAction(approval_request_data));
  };

  // Delete approval request
  const deleteApprovalRequest = () => {
    // Dispatch action
    dispatch(
      deleteApprovalRequestAction(approvalRequestDetails.approval_request.id)
    );
  };

  // Update approval request
  const updateApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(
      updateApprovalRequestAction(
        approvalRequestDetails.approval_request.id,
        approval_request_data
      )
    );
  };

  // Client
  // Delete client
  const deleteClient = () => {
    // Dispatch action
    clientDeleteKey === clientDetails.client.name
      ? dispatch(deleteClientAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the client's name",
        });
  };

  // Update client summary
  const updateSummary = () => {
    // Prepare data
    const client_data = new FormData();

    client.about
      ? client_data.append("about", client.about)
      : client_data.append("about", clientDefault.about);

    client.address
      ? client_data.append("address", client.address)
      : client_data.append("address", clientDefault.address);

    if (client.logo && client.logo !== clientDetails.client.logo) {
      client_data.append("logo", client.logo);
    } else if (client.logo === "") {
      client_data.append("logo", clientDefault.logo);
    }

    client.manager
      ? client_data.append("manager", client.manager)
      : client_data.append("manager", clientDefault.manager);

    client.market
      ? client_data.append("market", client.market)
      : client_data.append("market", clientDefault.market);

    client.name
      ? client_data.append("name", client.name)
      : client_data.append("name", clientDefault.name);

    client.restricted != null
      ? client_data.append("restricted", client.restricted)
      : client_data.append("restricted", clientDefault.restricted);

    client.ticker
      ? client_data.append("ticker", client.ticker)
      : client_data.append("ticker", clientDefault.ticker);

    client.website
      ? client_data.append("website", client.website)
      : client_data.append("website", clientDefault.website);

    // Dispatch action
    dispatch(updateClientAction(clientDetails.client.id, client_data));
  };

  // Client action
  const [waitingToUpdateKanban, setWaitingToUpdateKanban] = useState(false);

  // Add action
  const addAction = () => {
    // Prepare data
    const client_action = new FormData();

    clientAction.action
      ? client_action.append("action", clientAction.action)
      : client_action.append("action", clientActionDefault.action);

    clientAction.category
      ? client_action.append("category", clientAction.category)
      : client_action.append("category", clientActionDefault.category);

    client_action.append("client", id);

    clientAction.due
      ? client_action.append("due", getDjangoDate(clientAction.due))
      : client_action.append("due", getDjangoDate(clientActionDefault.due));

    clientAction.name
      ? client_action.append("name", clientAction.name)
      : client_action.append("name", clientActionDefault.name);

    clientAction.owner
      ? client_action.append("owner", clientAction.owner)
      : client_action.append("owner", clientActionDefault.owner);

    // Dispatch action
    dispatch(createClientActionAction(client_action));
  };

  // Delete action
  const deleteAction = () => {
    // Dispatch action
    dispatch(deleteClientActionAction(clientActionDetails.client_action.id));
  };

  // Update action
  const updateAction = () => {
    // Prepare data
    const client_action = new FormData();

    clientAction.action
      ? client_action.append("action", clientAction.action)
      : client_action.append("action", clientActionDefault.action);

    clientAction.category
      ? client_action.append("category", clientAction.category)
      : client_action.append("category", clientActionDefault.category);

    clientAction.comments
      ? client_action.append("comments", clientAction.comments)
      : client_action.append("comments", clientActionDefault.comments);

    clientAction.due
      ? client_action.append("due", getDjangoDate(clientAction.due))
      : client_action.append("due", getDjangoDate(clientActionDefault.due));

    clientAction.name
      ? client_action.append("name", clientAction.name)
      : client_action.append("name", clientActionDefault.name);

    clientAction.owner
      ? client_action.append("owner", clientAction.owner)
      : client_action.append("owner", clientActionDefault.owner);

    clientAction.status
      ? client_action.append("status", clientAction.status)
      : client_action.append("status", clientActionDefault.status);

    // Dispatch action
    dispatch(
      updateClientActionAction(
        clientActionDetails.client_action.id,
        client_action
      )
    );
  };

  // Update action status
  const updateActionStatus = (actionId, actionStatus) => {
    // Prepare data
    const client_action = new FormData();

    client_action.append("status", actionStatus);

    // Dispatch action
    dispatch(updateClientActionAction(actionId, client_action));
  };

  useEffect(() => {
    if (
      clientActionCreate.success ||
      clientActionDelete.success ||
      clientActionUpdate.success
    ) {
      setWaitingToUpdateKanban(true);

      dispatch(listClientDetailsAction(id));

      setClientActionQuery({
        ...clientActionQueryDefault,
      });
    }
    // eslint-disable-next-line
  }, [
    clientActionCreate.success,
    clientActionDelete.success,
    clientActionUpdate.success,
  ]);

  useEffect(() => {
    if (clientDetails.success && waitingToUpdateKanban) {
      setWaitingToUpdateKanban(false);

      setInitialiseActionKanban(true);
    }
    // eslint-disable-next-line
  }, [clientDetails.success]);

  // Client KPI
  // Add client KPI
  const addClientKPI = () => {
    // Prepare data
    const client_kpi = new FormData();

    clientKPI.achieved
      ? client_kpi.append("achieved", clientKPI.achieved)
      : client_kpi.append("achieved", clientKPIDefault.achieved);

    client_kpi.append("client", id);

    clientKPI.comments
      ? client_kpi.append("comments", clientKPI.comments)
      : client_kpi.append("comments", clientKPIDefault.comments);

    clientKPI.kpi
      ? client_kpi.append("kpi", clientKPI.kpi)
      : client_kpi.append("kpi", clientKPIDefault.kpi);

    clientKPI.rating
      ? client_kpi.append("rating", clientKPI.rating)
      : client_kpi.append("rating", clientKPIDefault.rating);

    clientKPI.target
      ? client_kpi.append("target", clientKPI.target)
      : client_kpi.append("target", clientKPIDefault.target);

    clientKPI.units
      ? client_kpi.append("units", clientKPI.units)
      : client_kpi.append("units", clientKPIDefault.units);

    clientKPI.year
      ? client_kpi.append("year", clientKPI.year)
      : client_kpi.append("year", clientKPIDefault.year);

    // Dispatch action
    dispatch(createClientKPIAction(client_kpi));
  };

  // Delete client KPI
  const deleteClientKPI = () => {
    // Dispatch action
    dispatch(deleteClientKPIAction(clientKPIDetails.client_kpi.id));
  };

  // Update client KPI
  const updateClientKPI = () => {
    // Prepare data
    const client_kpi = new FormData();

    clientKPI.achieved
      ? client_kpi.append("achieved", clientKPI.achieved)
      : client_kpi.append("achieved", clientKPIDefault.achieved);

    clientKPI.comments
      ? client_kpi.append("comments", clientKPI.comments)
      : client_kpi.append("comments", clientKPIDefault.comments);

    clientKPI.kpi
      ? client_kpi.append("kpi", clientKPI.kpi)
      : client_kpi.append("kpi", clientKPIDefault.kpi);

    clientKPI.rating
      ? client_kpi.append("rating", clientKPI.rating)
      : client_kpi.append("rating", clientKPIDefault.rating);

    clientKPI.target
      ? client_kpi.append("target", clientKPI.target)
      : client_kpi.append("target", clientKPIDefault.target);

    clientKPI.units
      ? client_kpi.append("units", clientKPI.units)
      : client_kpi.append("units", clientKPIDefault.units);

    clientKPI.year
      ? client_kpi.append("year", clientKPI.year)
      : client_kpi.append("year", clientKPIDefault.year);

    // Dispatch action
    dispatch(updateClientKPIAction(clientKPIDetails.client_kpi.id, client_kpi));
  };

  // Client need
  // Add need
  const addNeed = () => {
    // Prepare data
    const client_need = new FormData();

    client_need.append("client", id);

    clientNeed.need
      ? client_need.append("need", clientNeed.need)
      : client_need.append("need", clientNeedDefault.need);

    clientNeed.notes
      ? client_need.append("notes", clientNeed.notes)
      : client_need.append("notes", clientNeedDefault.notes);

    // Dispatch action
    dispatch(createClientNeedAction(client_need));
  };

  // Delete need
  const deleteNeed = () => {
    // Dispatch action
    dispatch(deleteClientNeedAction(clientNeedDetails.client_need.id));
  };

  // Update need
  const updateNeed = () => {
    // Prepare data
    const client_need = new FormData();

    clientNeed.need
      ? client_need.append("need", clientNeed.need)
      : client_need.append("need", clientNeedDefault.need);

    clientNeed.notes
      ? client_need.append("notes", clientNeed.notes)
      : client_need.append("notes", clientNeedDefault.notes);

    // Dispatch action
    dispatch(
      updateClientNeedAction(clientNeedDetails.client_need.id, client_need)
    );
  };

  // Client permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const client_permission = new FormData();

    clientPermission.level
      ? client_permission.append("level", clientPermission.level)
      : client_permission.append("level", clientPermissionDefault.level);

    clientPermission.manager
      ? client_permission.append("manager", clientPermission.manager)
      : client_permission.append("manager", clientPermissionDefault.manager);

    client_permission.append("client", id);

    // Dispatch action
    dispatch(createClientPermissionAction(client_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteClientPermissionAction(clientPermissionDetails.client_permission.id)
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const client_permission = new FormData();

    clientPermission.level
      ? client_permission.append("level", clientPermission.level)
      : client_permission.append("level", clientPermissionDefault.level);

    clientPermission.manager
      ? client_permission.append("manager", clientPermission.manager)
      : client_permission.append("manager", clientPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateClientPermissionAction(
        clientPermissionDetails.client_permission.id,
        client_permission
      )
    );
  };

  // Client program
  // Add program
  const addProgram = () => {
    // Prepare data
    const client_program = new FormData();

    client_program.append("client", id);

    clientProgram.expectedRevenue
      ? client_program.append("expected_revenue", clientProgram.expectedRevenue)
      : client_program.append(
          "expected_revenue",
          clientProgramDefault.expectedRevenue
        );

    clientProgram.name
      ? client_program.append("name", clientProgram.name)
      : client_program.append("name", clientProgramDefault.name);

    clientProgram.notes
      ? client_program.append("notes", clientProgram.notes)
      : client_program.append("notes", clientProgramDefault.notes);

    const sponsor = new FormData();
    if (clientProgram.sponsor) {
      client_program.append("sponsor", clientProgram.sponsor);
    } else {
      client_program.append("sponsor", clientProgramDefault.sponsor);

      if (clientProgram.sponsorName) {
        sponsor.append("name", clientProgram.sponsorName);

        sponsor.append("organisation", activeOrganisation.id);
      }
    }

    clientProgram.year
      ? client_program.append("year", clientProgram.year)
      : client_program.append("year", clientProgramDefault.year);

    // Dispatch action
    dispatch(createClientProgramAction(sponsor, client_program));
  };

  // Delete program
  const deleteProgram = () => {
    // Dispatch action
    dispatch(deleteClientProgramAction(clientProgramDetails.client_program.id));
  };

  // Update program
  const updateProgram = () => {
    // Prepare data
    const client_program = new FormData();

    clientProgram.expectedRevenue
      ? client_program.append("expected_revenue", clientProgram.expectedRevenue)
      : client_program.append(
          "expected_revenue",
          clientProgramDefault.expectedRevenue
        );

    clientProgram.name
      ? client_program.append("name", clientProgram.name)
      : client_program.append("name", clientProgramDefault.name);

    clientProgram.notes
      ? client_program.append("notes", clientProgram.notes)
      : client_program.append("notes", clientProgramDefault.notes);

    const sponsor = new FormData();
    if (clientProgram.sponsor) {
      client_program.append("sponsor", clientProgram.sponsor);
    } else {
      client_program.append("sponsor", clientProgramDefault.sponsor);

      if (clientProgram.sponsorName) {
        sponsor.append("name", clientProgram.sponsorName);

        sponsor.append("organisation", activeOrganisation.id);
      }
    }

    clientProgram.year
      ? client_program.append("year", clientProgram.year)
      : client_program.append("year", clientProgramDefault.year);

    // Dispatch action
    dispatch(
      updateClientProgramAction(
        clientProgramDetails.client_program.id,
        sponsor,
        client_program
      )
    );
  };

  // Client question
  // Add question
  const addQuestion = () => {
    // Prepare data
    const client_question = new FormData();

    clientQuestion.category
      ? client_question.append("category", clientQuestion.category)
      : client_question.append("category", clientQuestionDefault.category);

    client_question.append("client", id);

    clientQuestion.name
      ? client_question.append("name", clientQuestion.name)
      : client_question.append("name", clientQuestionDefault.name);

    clientQuestion.owner
      ? client_question.append("owner", clientQuestion.owner)
      : client_question.append("owner", clientQuestionDefault.owner);

    clientQuestion.question
      ? client_question.append("question", clientQuestion.question)
      : client_question.append("question", clientQuestionDefault.question);

    // Dispatch action
    dispatch(createClientQuestionAction(client_question));
  };

  // Delete question
  const deleteQuestion = () => {
    // Dispatch action
    dispatch(
      deleteClientQuestionAction(clientQuestionDetails.client_question.id)
    );
  };

  // Update question
  const updateQuestion = () => {
    // Prepare data
    const client_question = new FormData();

    clientQuestion.answer
      ? client_question.append("answer", clientQuestion.answer)
      : client_question.append("answer", clientQuestionDefault.answer);

    clientQuestion.category
      ? client_question.append("category", clientQuestion.category)
      : client_question.append("category", clientQuestionDefault.category);

    clientQuestion.name
      ? client_question.append("name", clientQuestion.name)
      : client_question.append("name", clientQuestionDefault.name);

    clientQuestion.owner
      ? client_question.append("owner", clientQuestion.owner)
      : client_question.append("owner", clientQuestionDefault.owner);

    clientQuestion.question
      ? client_question.append("question", clientQuestion.question)
      : client_question.append("question", clientQuestionDefault.question);

    clientQuestion.status
      ? client_question.append("status", clientQuestion.status)
      : client_question.append("status", clientQuestionDefault.status);

    // Dispatch action
    dispatch(
      updateClientQuestionAction(
        clientQuestionDetails.client_question.id,
        client_question
      )
    );
  };

  // Client stakeholder
  // Add client stakeholder
  const addClientStakeholder = () => {
    // Prepare data
    const client_stakeholder = new FormData();

    client_stakeholder.append("client", id);

    clientStakeholder.comments
      ? client_stakeholder.append("comments", clientStakeholder.comments)
      : client_stakeholder.append(
          "comments",
          clientStakeholderDefault.comments
        );

    clientStakeholder.levelOfInfluence
      ? client_stakeholder.append(
          "level_of_influence",
          clientStakeholder.levelOfInfluence
        )
      : client_stakeholder.append(
          "level_of_influence",
          clientStakeholderDefault.levelOfInfluence
        );

    clientStakeholder.relationshipOwner
      ? client_stakeholder.append(
          "relationship_owner",
          clientStakeholder.relationshipOwner
        )
      : client_stakeholder.append(
          "relationship_owner",
          clientStakeholderDefault.relationshipOwner
        );

    const stakeholder = new FormData();
    if (clientStakeholder.stakeholder) {
      client_stakeholder.append("stakeholder", clientStakeholder.stakeholder);
    } else {
      client_stakeholder.append(
        "stakeholder",
        clientStakeholderDefault.stakeholder
      );

      if (clientStakeholder.stakeholderName) {
        stakeholder.append("name", clientStakeholder.stakeholderName);

        stakeholder.append("organisation", activeOrganisation.id);
      }
    }

    // Dispatch action
    dispatch(createClientStakeholderAction(stakeholder, client_stakeholder));
  };

  // Delete client stakeholder
  const deleteClientStakeholder = () => {
    // Dispatch action
    dispatch(
      deleteClientStakeholderAction(
        clientStakeholderDetails.client_stakeholder.id
      )
    );
  };

  // Update client stakeholder
  const updateClientStakeholder = () => {
    // Prepare data
    const client_stakeholder = new FormData();

    client_stakeholder.append("client", id);

    clientStakeholder.comments
      ? client_stakeholder.append("comments", clientStakeholder.comments)
      : client_stakeholder.append(
          "comments",
          clientStakeholderDefault.comments
        );

    clientStakeholder.levelOfInfluence
      ? client_stakeholder.append(
          "level_of_influence",
          clientStakeholder.levelOfInfluence
        )
      : client_stakeholder.append(
          "level_of_influence",
          clientStakeholderDefault.levelOfInfluence
        );

    clientStakeholder.relationshipOwner
      ? client_stakeholder.append(
          "relationship_owner",
          clientStakeholder.relationshipOwner
        )
      : client_stakeholder.append(
          "relationship_owner",
          clientStakeholderDefault.relationshipOwner
        );

    const stakeholder = new FormData();
    if (clientStakeholder.stakeholder) {
      client_stakeholder.append("stakeholder", clientStakeholder.stakeholder);
    } else {
      client_stakeholder.append(
        "stakeholder",
        clientStakeholderDefault.stakeholder
      );

      if (clientStakeholder.stakeholderName) {
        stakeholder.append("name", clientStakeholder.stakeholderName);

        stakeholder.append("organisation", activeOrganisation.id);
      }
    }

    // Dispatch action
    dispatch(
      updateClientStakeholderAction(
        clientStakeholderDetails.client_stakeholder.id,
        stakeholder,
        client_stakeholder
      )
    );
  };

  // Load client intel
  const [downloadedClientIntel, setDownloadedClientIntel] = useState("");

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.client_intel) {
        setDownloadedClientIntel(
          JSON.parse(lastJsonMessage.message.client_intel)
        );
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (clientDetails.client.ticker) {
      dispatch(getIntelClientAction(id));
    } else {
      dispatch(resetGetIntelClientAction());
    }
    // eslint-disable-next-line
  }, [clientDetails.client.ticker]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the client</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px", width: "35%" }}>
                  <img
                    alt="logo"
                    src={
                      client.logo
                        ? client.logo !== clientDetails.client.logo
                          ? URL.createObjectURL(new Blob([client.logo]))
                          : clientDetails.client.logo
                        : "/images/no_logo.png"
                    }
                    style={{
                      aspectRatio: "1/1",
                      background: "white",
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: "10%",
                      margin: "10px 0 10px 0",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>

                <div style={{ marginLeft: "15px", width: "65%" }}>
                  {/* Client name */}
                  <FormDrawerTextField2
                    error={clientUpdate.error}
                    fieldName="name"
                    icon="office"
                    label="Client name"
                    labelInfo="(required)"
                    placeholder="Enter the client's name"
                    setValueObject={setClient}
                    setValueVariable="name"
                    value={client.name}
                  />

                  {/* Ticker symbol */}
                  <FormDrawerTextField2
                    error={clientUpdate.error}
                    fieldName="ticker"
                    helperText="If you enter a ticker, advaise.app will try to populate the client details"
                    icon="label"
                    label="Ticker symbol"
                    placeholder="Enter the client's ticker symbol"
                    setValueObject={setClient}
                    setValueVariable="ticker"
                    value={client.ticker}
                  />

                  {/* Website */}
                  <FormDrawerTextField2
                    error={clientUpdate.error}
                    fieldName="website"
                    helperText="Enter the full url"
                    icon="globe-network"
                    label="Website"
                    placeholder="Enter the client's website"
                    setValueObject={setClient}
                    setValueVariable="website"
                    value={client.website}
                  />

                  {/* Market */}
                  <FormDrawerSelectField2
                    disabled={
                      clientDetails.client.user_access_status === "edit" ||
                      clientDetails.client.user_access_status === "view"
                    }
                    error={clientUpdate.error}
                    fieldName="market"
                    helperText="You can select an existing market"
                    icon="heatmap"
                    info={
                      <MarketInfo
                        marketId={client.market}
                        query={marketQueryDefault}
                      />
                    }
                    items={marketList.markets}
                    label="Market"
                    loading={marketList.loading}
                    noOptionOnClick
                    placeholder="Select a market"
                    query={marketQuery.searchString}
                    setQueryObject={setMarketQuery}
                    setQueryVariable="searchString"
                    setValueObject={setClient}
                    setValueIdVariable="market"
                    setValueNameVariable="marketName"
                    showInfo={client.market}
                    value={client.marketName}
                  />

                  {/* Logo */}
                  <FormDrawerFileField2
                    error={clientUpdate.error}
                    fileName="logo"
                    label="Logo"
                    placeholder="Upload the client's logo"
                    setValueObject={setClient}
                    setValueFileVariable="logo"
                    setValueNameVariable="logoName"
                    valueFile={client.logo}
                    valueName={client.logoName}
                  />
                </div>
              </div>

              {/* Address */}
              <FormDrawerTextAreaField3
                error={clientUpdate.error}
                fieldName="address"
                height="100px"
                label="Address"
                noRewrite
                placeholder="Enter the client's address"
                setValueObject={setClient}
                setValueVariable="address"
                value={client.address}
              />

              {/* About */}
              <FormDrawerTextAreaField3
                error={clientUpdate.error}
                fieldName="about"
                label="About"
                placeholder="Summarise the client's mission"
                setValueObject={setClient}
                setValueVariable="about"
                value={client.about}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      clientDetails.client.user_access_status === "edit" ||
                      clientDetails.client.user_access_status === "view"
                    }
                    error={clientUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted client can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setClient}
                    setValueVariable="restricted"
                    value={client.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      clientDetails.client.user_access_status === "edit" ||
                      clientDetails.client.user_access_status === "view"
                    }
                    error={clientUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the client - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setClient}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={client.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the client manager and the client
                    is restricted, you will not be able to access it again,
                    unless you are organisation administrator/owner or have
                    edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={clientUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Updating a client"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={clientDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listClientDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateClientAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientUpdate.success}
        saveSuccessMessage="Client updated."
        saving={clientUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={clientPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setClientPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={clientPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={clientPermissionCreate.error}
              fieldName="level"
              intent={
                clientPermission.level === "Edit"
                  ? "success"
                  : clientPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setClientPermission}
              setValueVariable="level"
              value={clientPermission.level}
            />
          </Card>
        }
        error={clientPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listClientPermissionsAction({ client: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateClientPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={clientPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={clientPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setClientPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={clientPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={clientPermissionUpdate.error}
              fieldName="level"
              intent={
                clientPermission.level === "Edit"
                  ? "success"
                  : clientPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setClientPermission}
              setValueVariable="level"
              value={clientPermission.level}
            />
          </Card>
        }
        deleteSuccess={clientPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={clientPermissionDelete.loading}
        error={clientPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={clientPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listClientPermissionsAction({ ...clientPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClientPermissionAction());

          dispatch(resetUpdateClientPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={clientPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New question form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the question</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={clientQuestionCreate.error}
                  fieldName="name"
                  icon="search-template"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the question a title"
                  setValueObject={setClientQuestion}
                  setValueVariable="name"
                  value={clientQuestion.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={clientQuestionCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    { id: 1, icon: "dashboard", value: "Performance" },
                    { id: 2, icon: "locate", value: "Strategy" },
                    { id: 3, icon: "trending-up", value: "Trends" },
                    { id: 4, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setClientQuestion}
                  setValueVariable="category"
                  value={clientQuestion.category}
                />
              </div>
            </div>

            {/* Question */}
            <FormDrawerTextAreaField3
              error={clientQuestionCreate.error}
              fieldName="question"
              height="100px"
              label="Question"
              labelInfo="(required)"
              placeholder="Enter the question"
              setValueObject={setClientQuestion}
              setValueVariable="question"
              value={clientQuestion.question}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={clientQuestionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for answering the question"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientQuestion}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={clientQuestion.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>
          </Card>
        }
        error={clientQuestionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create question"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing client research"
        icon="edit"
        isOpen={addQuestionDrawerIsOpen}
        onSave={addQuestion}
        refreshList={() => {
          dispatch(listClientQuestionsAction({ client: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateClientQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientQuestionCreate.success}
        saveSuccessMessage="Question added."
        saving={clientQuestionCreate.loading}
        setIsOpen={setAddQuestionDrawerIsOpen}
        title="Create question"
      />

      {/* Existing question form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the question</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={clientQuestionUpdate.error}
                    fieldName="name"
                    icon="search-template"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the question a title"
                    setValueObject={setClientQuestion}
                    setValueVariable="name"
                    value={clientQuestion.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={clientQuestionUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "dashboard", value: "Performance" },
                      { id: 2, icon: "locate", value: "Strategy" },
                      { id: 3, icon: "trending-up", value: "Trends" },
                      { id: 4, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setClientQuestion}
                    setValueVariable="category"
                    value={clientQuestion.category}
                  />
                </div>
              </div>

              {/* Question */}
              <FormDrawerTextAreaField3
                error={clientQuestionUpdate.error}
                fieldName="question"
                height="100px"
                label="Question"
                labelInfo="(required)"
                placeholder="Enter the question"
                setValueObject={setClientQuestion}
                setValueVariable="question"
                value={clientQuestion.question}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={clientQuestionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible for answering the question"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setClientQuestion}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={clientQuestion.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the answer</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={clientQuestionUpdate.error}
                    fieldName="status"
                    intent={
                      clientQuestion.status === "Researching"
                        ? "warning"
                        : clientQuestion.status === "Resolved"
                        ? "success"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue",
                        value: "Researching",
                      },
                      {
                        id: 2,
                        icon: "issue-closed",
                        value: "Resolved",
                      },
                      {
                        id: 3,
                        icon: "disable",
                        value: "Not applicable",
                      },
                    ]}
                    setValueObject={setClientQuestion}
                    setValueVariable="status"
                    value={clientQuestion.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Answer */}
              <FormDrawerTextAreaField3
                error={clientQuestionUpdate.error}
                fieldName="answer"
                label="Answer"
                placeholder="Enter the answer"
                setValueObject={setClientQuestion}
                setValueVariable="answer"
                value={clientQuestion.answer}
              />
            </Card>
          </div>
        }
        deleteSuccess={clientQuestionDelete.success}
        deleteSuccessMessage="Question removed."
        deleting={clientQuestionDelete.loading}
        error={clientQuestionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update question"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing client research"
        icon="edit"
        isOpen={updateQuestionDrawerIsOpen}
        loading={clientQuestionDetails.loading}
        onDelete={deleteQuestion}
        onSave={updateQuestion}
        refreshList={() => {
          dispatch(listClientQuestionsAction({ ...clientQuestionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClientQuestionAction());

          dispatch(resetUpdateClientQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientQuestionUpdate.success}
        saveSuccessMessage="Question updated."
        saving={clientQuestionUpdate.loading}
        setIsOpen={setUpdateQuestionDrawerIsOpen}
        title="Update question"
      />

      {/* New client KPI form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the key performance indicator</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* KPI */}
                  <FormDrawerTextField2
                    error={clientKPICreate.error}
                    fieldName="kpi"
                    icon="dashboard"
                    label="KPI"
                    labelInfo="(required)"
                    placeholder="Name the client KPI"
                    setValueObject={setClientKPI}
                    setValueVariable="kpi"
                    value={clientKPI.kpi}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Units */}
                  <FormDrawerTextField2
                    error={clientKPICreate.error}
                    fieldName="units"
                    icon="variable"
                    label="Units"
                    labelInfo="(required)"
                    placeholder="Enter the units of measurement"
                    setValueObject={setClientKPI}
                    setValueVariable="units"
                    value={clientKPI.units}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Year */}
                  <FormDrawerNumericField2
                    error={clientKPICreate.error}
                    fieldName="year"
                    icon="calendar"
                    label="Year"
                    labelInfo="(required)"
                    majorStepSize={5}
                    max={today.getFullYear()}
                    min={2020}
                    minorStepSize={1}
                    placeholder="Enter the year"
                    setValueObject={setClientKPI}
                    setValueVariable="year"
                    stepSize={1}
                    value={clientKPI.year}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Target */}
                  <FormDrawerNumericField2
                    error={clientKPICreate.error}
                    fieldName="target"
                    icon="target"
                    label="Target"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the target value"
                    setValueObject={setClientKPI}
                    setValueVariable="target"
                    stepSize={10}
                    value={clientKPI.target}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "35%" }}>
                  {/* Actual */}
                  <FormDrawerNumericField2
                    error={clientKPICreate.error}
                    fieldName="achieved"
                    icon="trending-up"
                    label="Actual"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the achieved value"
                    setValueObject={setClientKPI}
                    setValueVariable="achieved"
                    stepSize={10}
                    value={clientKPI.achieved}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Evaluate the client's performance</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Rating */}
                  <FormDrawerSelectSimpleField3
                    error={clientKPICreate.error}
                    fieldName="rating"
                    intent={
                      clientKPI.rating === "Exceeded"
                        ? "success"
                        : clientKPI.rating === "Met"
                        ? "warning"
                        : "danger"
                    }
                    label="Rating"
                    options={[
                      { id: 1, icon: "arrow-up", value: "Exceeded" },
                      { id: 2, icon: "arrow-right", value: "Met" },
                      { id: 3, icon: "arrow-down", value: "Not met" },
                    ]}
                    setValueObject={setClientKPI}
                    setValueVariable="rating"
                    value={clientKPI.rating}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={clientKPICreate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter your comments"
                setValueObject={setClientKPI}
                setValueVariable="comments"
                value={clientKPI.comments}
              />
            </Card>
          </div>
        }
        error={clientKPICreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add client KPI"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Evaluating performance"
        icon="edit"
        isOpen={addClientKPIDrawerIsOpen}
        onSave={addClientKPI}
        refreshList={() => {
          dispatch(listClientKPIsAction({ client: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateClientKPIAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientKPICreate.success}
        saveSuccessMessage="Client KPI added."
        saving={clientKPICreate.loading}
        setIsOpen={setAddClientKPIDrawerIsOpen}
        title="Add client KPI"
      />

      {/* Existing client KPI form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the key performance indicator</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* KPI */}
                  <FormDrawerTextField2
                    error={clientKPIUpdate.error}
                    fieldName="kpi"
                    icon="dashboard"
                    label="KPI"
                    labelInfo="(required)"
                    placeholder="Name the client KPI"
                    setValueObject={setClientKPI}
                    setValueVariable="kpi"
                    value={clientKPI.kpi}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Units */}
                  <FormDrawerTextField2
                    error={clientKPIUpdate.error}
                    fieldName="units"
                    icon="variable"
                    label="Units"
                    labelInfo="(required)"
                    placeholder="Enter the units of measurement"
                    setValueObject={setClientKPI}
                    setValueVariable="units"
                    value={clientKPI.units}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Year */}
                  <FormDrawerNumericField2
                    error={clientKPIUpdate.error}
                    fieldName="year"
                    icon="calendar"
                    label="Year"
                    labelInfo="(required)"
                    majorStepSize={5}
                    max={today.getFullYear()}
                    min={2020}
                    minorStepSize={1}
                    placeholder="Enter the year"
                    setValueObject={setClientKPI}
                    setValueVariable="year"
                    stepSize={1}
                    value={clientKPI.year}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Target */}
                  <FormDrawerNumericField2
                    error={clientKPIUpdate.error}
                    fieldName="target"
                    icon="target"
                    label="Target"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the target value"
                    setValueObject={setClientKPI}
                    setValueVariable="target"
                    stepSize={10}
                    value={clientKPI.target}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "35%" }}>
                  {/* Actual */}
                  <FormDrawerNumericField2
                    error={clientKPIUpdate.error}
                    fieldName="achieved"
                    icon="trending-up"
                    label="Actual"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the achieved value"
                    setValueObject={setClientKPI}
                    setValueVariable="achieved"
                    stepSize={10}
                    value={clientKPI.achieved}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Evaluate the client's performance</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Rating */}
                  <FormDrawerSelectSimpleField3
                    error={clientKPIUpdate.error}
                    fieldName="rating"
                    intent={
                      clientKPI.rating === "Exceeded"
                        ? "success"
                        : clientKPI.rating === "Met"
                        ? "warning"
                        : "danger"
                    }
                    label="Rating"
                    options={[
                      { id: 1, icon: "arrow-up", value: "Exceeded" },
                      { id: 2, icon: "arrow-right", value: "Met" },
                      { id: 3, icon: "arrow-down", value: "Not met" },
                    ]}
                    setValueObject={setClientKPI}
                    setValueVariable="rating"
                    value={clientKPI.rating}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={clientKPIUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter your comments"
                setValueObject={setClientKPI}
                setValueVariable="comments"
                value={clientKPI.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={clientKPIDelete.success}
        deleteSuccessMessage="Client KPI deleted."
        deleting={clientKPIDelete.loading}
        error={clientKPIUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update client KPI"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Evaluating performance"
        icon="edit"
        isOpen={updateClientKPIDrawerIsOpen}
        loading={clientKPIDetails.loading}
        onDelete={deleteClientKPI}
        onSave={updateClientKPI}
        refreshList={() => {
          dispatch(
            listClientKPIsAction({
              ...clientKPIQuery,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteClientKPIAction());

          dispatch(resetUpdateClientKPIAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientKPIUpdate.success}
        saveSuccessMessage="Client KPI added."
        saving={clientKPIUpdate.loading}
        setIsOpen={setUpdateClientKPIDrawerIsOpen}
        title="Update client KPI"
      />

      {/* New need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need */}
                <FormDrawerSelectField2
                  error={clientNeedCreate.error}
                  fieldName="need"
                  helperText="You can select an existing need"
                  icon="user"
                  info={
                    <NeedInfo
                      needId={clientNeed.need}
                      query={needQueryDefault}
                    />
                  }
                  items={needList.needs}
                  label="Need"
                  labelInfo="(required)"
                  loading={needList.loading}
                  placeholder="Select a need"
                  query={needQuery.searchString}
                  setQueryObject={setNeedQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientNeed}
                  setValueIdVariable="need"
                  setValueNameVariable="needName"
                  showInfo={clientNeed.need}
                  value={clientNeed.needName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={clientNeedCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the client need"
              setValueObject={setClientNeed}
              setValueVariable="notes"
              value={clientNeed.notes}
            />
          </Card>
        }
        error={clientNeedCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add need"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing needs"
        icon="edit"
        isOpen={addNeedDrawerIsOpen}
        onSave={addNeed}
        refreshList={() => {
          dispatch(listClientNeedsAction({ client: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateClientNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientNeedCreate.success}
        saveSuccessMessage="Need added."
        saving={clientNeedCreate.loading}
        setIsOpen={setAddNeedDrawerIsOpen}
        title="Add need"
      />

      {/* Existing need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need */}
                <FormDrawerSelectField2
                  error={clientNeedUpdate.error}
                  fieldName="need"
                  helperText="You can select an existing need"
                  icon="user"
                  info={
                    <NeedInfo
                      needId={clientNeed.need}
                      query={needQueryDefault}
                    />
                  }
                  items={needList.needs}
                  label="Need"
                  labelInfo="(required)"
                  loading={needList.loading}
                  placeholder="Select a need"
                  query={needQuery.searchString}
                  setQueryObject={setNeedQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientNeed}
                  setValueIdVariable="need"
                  setValueNameVariable="needName"
                  showInfo={clientNeed.need}
                  value={clientNeed.needName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={clientNeedUpdate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the client need"
              setValueObject={setClientNeed}
              setValueVariable="notes"
              value={clientNeed.notes}
            />
          </Card>
        }
        deleteSuccess={clientNeedDelete.success}
        deleteSuccessMessage="Need removed."
        deleting={clientNeedDelete.loading}
        error={clientNeedUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update need"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing needs"
        icon="edit"
        isOpen={updateNeedDrawerIsOpen}
        loading={clientNeedDetails.loading}
        onDelete={deleteNeed}
        onSave={updateNeed}
        refreshList={() => {
          dispatch(listClientNeedsAction({ ...clientNeedQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClientNeedAction());

          dispatch(resetUpdateClientNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientNeedUpdate.success}
        saveSuccessMessage="Need updated."
        saving={clientNeedUpdate.loading}
        setIsOpen={setUpdateNeedDrawerIsOpen}
        title="Update need"
      />

      {/* New action form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the action</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={clientActionCreate.error}
                  fieldName="name"
                  icon="take-action"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the action a title"
                  setValueObject={setClientAction}
                  setValueVariable="name"
                  value={clientAction.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={clientActionCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "gantt-chart",
                      value: "Delivery",
                    },
                    { id: 2, icon: "locate", value: "Opportunity" },
                    { id: 3, icon: "path-search", value: "Planning" },
                    { id: 4, icon: "one-to-one", value: "Relationship" },
                    {
                      id: 5,
                      icon: "people",
                      value: "Team",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setClientAction}
                  setValueVariable="category"
                  value={clientAction.category}
                />
              </div>
            </div>

            {/* Action */}
            <FormDrawerTextAreaField3
              error={clientActionCreate.error}
              fieldName="action"
              height="100px"
              label="Action"
              labelInfo="(required)"
              placeholder="Enter the action"
              setValueObject={setClientAction}
              setValueVariable="action"
              value={clientAction.action}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={clientActionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for completing the action"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientAction}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={clientAction.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={clientActionCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the action due date"
                  setValueObject={setClientAction}
                  setValueVariable="due"
                  value={clientAction.due}
                />
              </div>
            </div>
          </Card>
        }
        error={clientActionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create action"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={addActionDrawerIsOpen}
        onSave={addAction}
        refreshList={() => {
          dispatch(listClientActionsAction({ client: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateClientActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientActionCreate.success}
        saveSuccessMessage="Action created."
        saving={clientActionCreate.loading}
        setIsOpen={setAddActionDrawerIsOpen}
        title="Create action"
      />

      {/* Existing action form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the action</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={clientActionUpdate.error}
                    fieldName="name"
                    icon="take-action"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the action a title"
                    setValueObject={setClientAction}
                    setValueVariable="name"
                    value={clientAction.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={clientActionUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      {
                        id: 1,
                        icon: "gantt-chart",
                        value: "Delivery",
                      },
                      { id: 2, icon: "locate", value: "Opportunity" },
                      { id: 3, icon: "path-search", value: "Planning" },
                      { id: 4, icon: "one-to-one", value: "Relationship" },
                      {
                        id: 5,
                        icon: "people",
                        value: "Team",
                      },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setClientAction}
                    setValueVariable="category"
                    value={clientAction.category}
                  />
                </div>
              </div>

              {/* Action */}
              <FormDrawerTextAreaField3
                error={clientActionUpdate.error}
                fieldName="action"
                height="100px"
                label="Action"
                labelInfo="(required)"
                placeholder="Enter the action"
                setValueObject={setClientAction}
                setValueVariable="action"
                value={clientAction.action}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={clientActionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setClientAction}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={clientAction.ownerName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "40%",
                  }}
                >
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={clientActionUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the action due date"
                    setValueObject={setClientAction}
                    setValueVariable="due"
                    value={clientAction.due}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  tag={
                    clientActionDetails.client_action.owner_done
                      ? "Ready to close"
                      : "Working on it"
                  }
                  tagIntent={
                    clientActionDetails.client_action.owner_done
                      ? "success"
                      : "primary"
                  }
                  text={clientActionDetails.client_action.owner_comments}
                  title="Owner update"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={clientActionUpdate.error}
                    fieldName="status"
                    intent={
                      clientAction.status === "Completed"
                        ? "success"
                        : clientAction.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Completed",
                      },
                      {
                        id: 2,
                        icon: "refresh",
                        value: "In-progress",
                      },
                      {
                        id: 3,
                        icon: "remove",
                        value: "On-hold",
                      },
                    ]}
                    setValueObject={setClientAction}
                    setValueVariable="status"
                    value={clientAction.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={clientActionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setClientAction}
                setValueVariable="comments"
                value={clientAction.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={clientActionDelete.success}
        deleteSuccessMessage="Action deleted."
        deleting={clientActionDelete.loading}
        error={clientActionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update action"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={updateActionDrawerIsOpen}
        loading={clientActionDetails.loading}
        onDelete={deleteAction}
        onSave={updateAction}
        refreshList={() => {
          dispatch(listClientActionsAction({ ...clientActionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClientActionAction());

          dispatch(resetUpdateClientActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientActionUpdate.success}
        saveSuccessMessage="Action updated."
        saving={clientActionUpdate.loading}
        setIsOpen={setUpdateActionDrawerIsOpen}
        title="Update action"
      />

      {/* New program form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the program details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Program */}
                <FormDrawerTextField2
                  error={clientProgramCreate.error}
                  fieldName="name"
                  icon="projects"
                  label="Program"
                  labelInfo="(required)"
                  placeholder="Enter the program"
                  setValueObject={setClientProgram}
                  setValueVariable="name"
                  value={clientProgram.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Year */}
                <FormDrawerNumericField2
                  error={clientProgramCreate.error}
                  fieldName="year"
                  icon="calendar"
                  label="Year"
                  majorStepSize={1}
                  max={new Date().getFullYear() + 3}
                  min={new Date().getFullYear() - 3}
                  minorStepSize={1}
                  placeholder="Enter the financial year"
                  setValueObject={setClientProgram}
                  setValueVariable="year"
                  stepSize={1}
                  value={clientProgram.year}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Expected revenue */}
                <FormDrawerNumericField2
                  error={clientProgramCreate.error}
                  fieldName="expected_revenue"
                  label="Expected revenue"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the estimated revenue"
                  setValueObject={setClientProgram}
                  setValueVariable="expectedRevenue"
                  stepSize={10000}
                  value={clientProgram.expectedRevenue}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Sponsor */}
                <FormDrawerSelectField2
                  allowCreate
                  error={clientProgramCreate.error}
                  fieldName="sponsor"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={clientProgram.sponsor}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Sponsor"
                  loading={contactList.loading}
                  noOptionOnClick
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientProgram}
                  setValueIdVariable="sponsor"
                  setValueNameVariable="sponsorName"
                  showInfo={clientProgram.sponsor}
                  value={clientProgram.sponsorName}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={clientProgramCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter any notes you want to capture"
              setValueObject={setClientProgram}
              setValueVariable="notes"
              value={clientProgram.notes}
            />
          </Card>
        }
        error={clientProgramCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add program"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Identify sources of revenue"
        icon="edit"
        isOpen={addClientProgramDrawerIsOpen}
        onSave={addProgram}
        refreshList={() => {
          dispatch(listClientProgramsAction({ client: id }));

          dispatch(listClientDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateClientProgramAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientProgramCreate.success}
        saveSuccessMessage="Program created."
        saving={clientProgramCreate.loading}
        setIsOpen={setAddClientProgramDrawerIsOpen}
        title="Add program"
      />

      {/* Existing program form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the program details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Program */}
                <FormDrawerTextField2
                  error={clientProgramUpdate.error}
                  fieldName="name"
                  icon="projects"
                  label="Program"
                  labelInfo="(required)"
                  placeholder="Enter the program"
                  setValueObject={setClientProgram}
                  setValueVariable="name"
                  value={clientProgram.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Year */}
                <FormDrawerNumericField2
                  error={clientProgramUpdate.error}
                  fieldName="year"
                  icon="calendar"
                  label="Year"
                  majorStepSize={1}
                  max={new Date().getFullYear() + 3}
                  min={new Date().getFullYear() - 3}
                  minorStepSize={1}
                  placeholder="Enter the financial year"
                  setValueObject={setClientProgram}
                  setValueVariable="year"
                  stepSize={1}
                  value={clientProgram.year}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Expected revenue */}
                <FormDrawerNumericField2
                  error={clientProgramUpdate.error}
                  fieldName="expected_revenue"
                  label="Expected revenue"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the estimated revenue"
                  setValueObject={setClientProgram}
                  setValueVariable="expectedRevenue"
                  stepSize={10000}
                  value={clientProgram.expectedRevenue}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Sponsor */}
                <FormDrawerSelectField2
                  allowCreate
                  error={clientProgramUpdate.error}
                  fieldName="sponsor"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={clientProgram.sponsor}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Sponsor"
                  loading={contactList.loading}
                  noOptionOnClick
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientProgram}
                  setValueIdVariable="sponsor"
                  setValueNameVariable="sponsorName"
                  showInfo={clientProgram.sponsor}
                  value={clientProgram.sponsorName}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={clientProgramUpdate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter any notes you want to capture"
              setValueObject={setClientProgram}
              setValueVariable="notes"
              value={clientProgram.notes}
            />
          </Card>
        }
        deleteSuccess={clientProgramDelete.success}
        deleteSuccessMessage="Program deleted."
        deleting={clientProgramDelete.loading}
        error={clientProgramUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update program"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Identifying sources of revenue"
        icon="edit"
        isOpen={updateClientProgramDrawerIsOpen}
        loading={clientProgramDetails.loading}
        onDelete={deleteProgram}
        onSave={updateProgram}
        refreshList={() => {
          dispatch(listClientProgramsAction({ ...clientProgramQuery }));

          dispatch(listClientDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteClientProgramAction());

          dispatch(resetUpdateClientProgramAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientProgramUpdate.success}
        saveSuccessMessage="Program updated."
        saving={clientProgramUpdate.loading}
        setIsOpen={setUpdateClientProgramDrawerIsOpen}
        title="Update program"
      />

      {/* New client stakeholder form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the stakeholder management strategy</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Stakeholder */}
                <FormDrawerSelectField2
                  allowCreate
                  error={clientStakeholderCreate.error}
                  fieldName="stakeholder"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={clientStakeholder.stakeholder}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Stakeholder"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientStakeholder}
                  setValueIdVariable="stakeholder"
                  setValueNameVariable="stakeholderName"
                  showInfo={clientStakeholder.stakeholder}
                  value={clientStakeholder.stakeholderName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level of influence */}
                <FormDrawerSliderField
                  error={clientStakeholderCreate.error}
                  fieldName="level_of_influence"
                  intent={
                    clientStakeholder.levelOfInfluence > 4
                      ? "success"
                      : clientStakeholder.levelOfInfluence > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of influence"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setClientStakeholder}
                  setValueVariable="levelOfInfluence"
                  value={clientStakeholder.levelOfInfluence}
                />
              </div>
            </div>

            {/* Relationship management strategy */}
            <FormDrawerTextAreaField3
              error={clientStakeholderCreate.error}
              fieldName="comments"
              label="Relationship management strategy"
              placeholder="Describe how you will manage this relationship"
              setValueObject={setClientStakeholder}
              setValueVariable="comments"
              value={clientStakeholder.comments}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Relationship owner */}
                <FormDrawerSelectField2
                  error={clientStakeholderCreate.error}
                  fieldName="relationship_owner"
                  helperText="Person responsible for managing the relationship"
                  icon="user"
                  items={managerList.managers}
                  label="Relationship owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select a relationship owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientStakeholder}
                  setValueIdVariable="relationshipOwner"
                  setValueNameVariable="relationshipOwnerName"
                  value={clientStakeholder.relationshipOwnerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>
          </Card>
        }
        error={clientStakeholderCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add stakeholder"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing stakeholders"
        icon="edit"
        isOpen={addClientStakeholderDrawerIsOpen}
        onSave={addClientStakeholder}
        refreshList={() => {
          dispatch(listClientStakeholdersAction({ client: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateClientStakeholderAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientStakeholderCreate.success}
        saveSuccessMessage="Stakeholder added."
        saving={clientStakeholderCreate.loading}
        setIsOpen={setAddClientStakeholderDrawerIsOpen}
        title="Add stakeholder"
      />

      {/* Existing client stakeholder form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the stakeholder management strategy</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Stakeholder */}
                <FormDrawerSelectField2
                  allowCreate
                  error={clientStakeholderUpdate.error}
                  fieldName="stakeholder"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={clientStakeholder.stakeholder}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Stakeholder"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientStakeholder}
                  setValueIdVariable="stakeholder"
                  setValueNameVariable="stakeholderName"
                  showInfo={clientStakeholder.stakeholder}
                  value={clientStakeholder.stakeholderName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level of influence */}
                <FormDrawerSliderField
                  error={clientStakeholderUpdate.error}
                  fieldName="level_of_influence"
                  intent={
                    clientStakeholder.levelOfInfluence > 4
                      ? "success"
                      : clientStakeholder.levelOfInfluence > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of influence"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setClientStakeholder}
                  setValueVariable="levelOfInfluence"
                  value={clientStakeholder.levelOfInfluence}
                />
              </div>
            </div>

            {/* Relationship management strategy */}
            <FormDrawerTextAreaField3
              error={clientStakeholderUpdate.error}
              fieldName="comments"
              label="Relationship management strategy"
              placeholder="Describe how you will manage this relationship"
              setValueObject={setClientStakeholder}
              setValueVariable="comments"
              value={clientStakeholder.comments}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Relationship owner */}
                <FormDrawerSelectField2
                  error={clientStakeholderUpdate.error}
                  fieldName="relationship_owner"
                  helperText="Person responsible for managing the relationship"
                  icon="user"
                  items={managerList.managers}
                  label="Relationship owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select a relationship owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClientStakeholder}
                  setValueIdVariable="relationshipOwner"
                  setValueNameVariable="relationshipOwnerName"
                  value={clientStakeholder.relationshipOwnerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>
          </Card>
        }
        deleteSuccess={clientStakeholderDelete.success}
        deleteSuccessMessage="Client stakeholder deleted."
        deleting={clientStakeholderDelete.loading}
        error={clientStakeholderUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update stakeholder"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Managing stakeholders"
        icon="edit"
        isOpen={updateClientStakeholderDrawerIsOpen}
        loading={clientStakeholderDetails.loading}
        onDelete={deleteClientStakeholder}
        onSave={updateClientStakeholder}
        refreshList={() => {
          dispatch(listClientStakeholdersAction({ ...clientStakeholderQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClientStakeholderAction());

          dispatch(resetUpdateClientStakeholderAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientStakeholderUpdate.success}
        saveSuccessMessage="Client stakeholder updated."
        saving={clientStakeholderUpdate.loading}
        setIsOpen={setUpdateClientStakeholderDrawerIsOpen}
        title="Update stakeholder"
      />

      {/* New approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request for the client account plan</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginRight: "10px",
                    width: "60%",
                  }}
                >
                  {/* Approver */}
                  <FormDrawerSelectField2
                    error={approvalRequestCreate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={approvalRequestCreate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    error={approvalRequestCreate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={approvalRequestCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create approval request"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={addApprovalRequestDrawerIsOpen}
        onSave={addApprovalRequest}
        refreshList={() => {
          dispatch(
            listApprovalRequestsAction({
              itemId: id,
              itemType: "client",
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestCreate.success}
        saveSuccessMessage="Approval request created."
        saving={approvalRequestCreate.loading}
        setIsOpen={setAddApprovalRequestDrawerIsOpen}
        title="Create approval request"
      />

      {/* Existing approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request to hire the selected candidate</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginRight: "10px",
                    width: "60%",
                  }}
                >
                  {/* Approver */}
                  <FormDrawerSelectField2
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                disabled={
                  approvalRequestDetails.approval_request.status === "Submitted"
                }
                error={approvalRequestUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={approvalRequestDelete.success}
        deleteSuccessMessage="Approval request deleted."
        deleting={approvalRequestDelete.loading}
        error={approvalRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approval request"
            module="Clients"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={updateApprovalRequestDrawerIsOpen}
        loading={approvalRequestDetails.loading}
        onDelete={deleteApprovalRequest}
        onSave={updateApprovalRequest}
        refreshList={() => {
          dispatch(listApprovalRequestsAction({ ...approvalRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteApprovalRequestAction());

          dispatch(resetUpdateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestUpdate.success}
        saveSuccessMessage="Approval request updated."
        saving={approvalRequestUpdate.loading}
        setIsOpen={setUpdateApprovalRequestDrawerIsOpen}
        title="Update approval request"
      />

      {/* Detail drawers */}
      {/* Approval request */}
      <ApprovalDetailDrawer
        approvalRequestDetails={approvalRequestDetails}
        setIsOpen={setViewApprovalDrawerIsOpen}
        isOpen={viewApprovalDrawerIsOpen}
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Clients"
              onClick={() => {
                dispatch(resetListClientsAction());

                navigate("/clients");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          clientDetails.client.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={clientDetails.loading}
        title={`${clientDetails.client.name}`}
      />

      {/* Error alerts */}
      {/* Approval requests loading error */}
      {approvalRequestList.error && (
        <ErrorAlert message="We couldn't load the approval request list. Try refreshing the page." />
      )}
      {/* Client delete error */}
      {clientDelete.error && (
        <ErrorAlert message="We couldn't delete the client. Try refreshing the page." />
      )}
      {/* Client loading error */}
      {clientDetails.error && (
        <ErrorAlert message="We couldn't load the client. Try refreshing the page." />
      )}
      {/* Client actions loading error */}
      {clientActionList.error && (
        <ErrorAlert message="We couldn't load the client actions. Try refreshing the page." />
      )}
      {/* Client intel loading error */}
      {clientIntel.error && (
        <ErrorAlert message="We couldn't load the client intelligence from external sources. Try refreshing the page." />
      )}
      {/* Client KPIs loading error */}
      {clientKPIList.error && (
        <ErrorAlert message="We couldn't load the client KPIs. Try refreshing the page." />
      )}
      {/* Client needs loading error */}
      {clientNeedList.error && (
        <ErrorAlert message="We couldn't load the client needs. Try refreshing the page." />
      )}
      {/* Client permissions loading error */}
      {clientPermissionList.error && (
        <ErrorAlert message="We couldn't load the client permissions. Try refreshing the page." />
      )}
      {/* Client programs loading error */}
      {clientProgramList.error && (
        <ErrorAlert message="We couldn't load the client programs. Try refreshing the page." />
      )}
      {/* Client questions loading error */}
      {clientQuestionList.error && (
        <ErrorAlert message="We couldn't load the client questions. Try refreshing the page." />
      )}
      {/* Client stakeholders loading error */}
      {clientStakeholderList.error && (
        <ErrorAlert message="We couldn't load the client stakeholders. Try refreshing the page." />
      )}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Engagements loading error */}
      {engagementList.error && (
        <ErrorAlert message="We couldn't load the engagement list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Markets loading error */}
      {marketList.error && (
        <ErrorAlert message="We couldn't load the market list. Try refreshing the page." />
      )}
      {/* Needs loading error */}
      {needList.error && (
        <ErrorAlert message="We couldn't load the need list. Try refreshing the page." />
      )}
      {/* Proposals loading error */}
      {proposalList.error && (
        <ErrorAlert message="We couldn't load the proposal list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Client"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="client-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="client-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listClientDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <TabSectionField
                        bottom
                        label={
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                clientDetails.client.logo
                                  ? clientDetails.client.logo
                                  : "/images/no_logo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "10%",
                                height: "150px",
                                margin: "10px 0 10px 0",
                                objectFit: "contain",
                                width: "150px",
                              }}
                            />

                            <Text ellipsize>{clientDetails.client.name}</Text>
                          </div>
                        }
                        value={
                          <TabSectionFieldMultifieldCard
                            children={
                              <>
                                {/* Id */}
                                <TabSectionField
                                  label="Id"
                                  loading={clientDetails.loading}
                                  value={clientDetails.client.id}
                                />

                                {/* Ticker symbol */}
                                <TabSectionField
                                  label="Ticker symbol"
                                  loading={clientDetails.loading}
                                  value={
                                    clientDetails.client.ticker ? (
                                      <Tag large minimal>
                                        {clientDetails.client.ticker}
                                      </Tag>
                                    ) : undefined
                                  }
                                />

                                {/* Website */}
                                <TabSectionField
                                  label="Website"
                                  loading={clientDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        url={clientDetails.client.website}
                                      />
                                    </div>
                                  }
                                />

                                {/* Address */}
                                <TabSectionField
                                  label="Address"
                                  loading={clientDetails.loading}
                                  value={clientDetails.client.address}
                                />

                                {/* About */}
                                <TabSectionField
                                  label="About"
                                  loading={clientDetails.loading}
                                  value={clientDetails.client.about}
                                />

                                {/* Market */}
                                <TabSectionField
                                  label="Market"
                                  loading={clientDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        name={
                                          clientDetails.client.market
                                            ? clientDetails.client.market_name
                                            : undefined
                                        }
                                        sameTab
                                        url={
                                          clientDetails.client.market
                                            ? `/markets/${clientDetails.client.market}`
                                            : undefined
                                        }
                                      />
                                    </div>
                                  }
                                />

                                {/* Access */}
                                <TabSectionField
                                  helperText={
                                    clientDetails.client.restricted
                                      ? "Users must have permission to access this client"
                                      : "Everyone in the organisation can edit this client"
                                  }
                                  label="Access"
                                  loading={clientDetails.loading}
                                  value={
                                    <Tag
                                      intent={
                                        clientDetails.client.restricted
                                          ? "danger"
                                          : "success"
                                      }
                                      large
                                      minimal
                                      style={{
                                        minWidth: "100px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {clientDetails.client.restricted
                                        ? "Restricted"
                                        : "Unrestricted"}
                                    </Tag>
                                  }
                                />

                                {/* Manager */}
                                <TabSectionField
                                  bottom
                                  helperText={
                                    clientDetails.client.user_access_status ===
                                    "view"
                                      ? "Contact to request edit access"
                                      : undefined
                                  }
                                  label="Manager"
                                  loading={clientDetails.loading}
                                  value={
                                    <NameValue
                                      email={clientDetails.client.manager_name}
                                      image={
                                        clientDetails.client
                                          .manager_profile_photo
                                      }
                                    />
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    }
                    help="Review and update client information"
                    icon="office"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(clientDetails.client.user_access_status === "owner" ||
                    clientDetails.client.user_access_status === "admin" ||
                    clientDetails.client.user_access_status === "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={clientPermissionList.loading}
                              setValueObject={setClientPermissionQuery}
                              setValueVariable="searchString"
                              value={clientPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={clientPermissionList.client_permissions.map(
                              (client_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={client_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listClientPermissionDetailsAction(
                                          client_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={client_permission}
                                  />
                                );
                              }
                            )}
                            count={clientPermissionList.count}
                            loading={clientPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={clientPermissionQuery.page}
                            setPageObject={setClientPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, clients are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete contact */}
                  {(clientDetails.client.user_access_status === "owner" ||
                    clientDetails.client.user_access_status === "admin" ||
                    clientDetails.client.user_access_status === "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={clientDetails.client.cannot_delete}
                              id="confirm-client-name"
                              onChange={(e) => {
                                setClientDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the client's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={clientDeleteKey}
                            />

                            <Button
                              disabled={clientDetails.client.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={clientDelete.loading}
                              onClick={deleteClient}
                              text="Delete this client"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{clientDetails.client.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the client"
                      icon="delete"
                      message={
                        clientDetails.client.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this client, you will lose all information associated with it."
                      }
                      messageIntent={
                        clientDetails.client.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete client"
                    />
                  )}
                </div>
              }
            />

            {/* Research */}
            <Tab
              id="client-research-tab"
              title="Research"
              panel={
                <div>
                  {/* Client questions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddQuestionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Resolved */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Resolved"
                                loading={clientQuestionList.loading}
                                onClick={() => {
                                  setClientQuestionQuery({
                                    ...clientQuestionQuery,
                                    page: 1,
                                    status: "Resolved",
                                  });
                                }}
                                value={
                                  clientQuestionList.numberOfQuestionsResolved
                                }
                              />

                              {/* Researching */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="Researching"
                                loading={clientQuestionList.loading}
                                onClick={() => {
                                  setClientQuestionQuery({
                                    ...clientQuestionQuery,
                                    page: 1,
                                    status: "Researching",
                                  });
                                }}
                                value={
                                  clientQuestionList.numberOfQuestionsResearching
                                }
                              />

                              {/* Not applicable */}
                              <DataCardSimple
                                intent="primary"
                                interactive
                                label="Not applicable"
                                loading={clientQuestionList.loading}
                                onClick={() => {
                                  setClientQuestionQuery({
                                    ...clientQuestionQuery,
                                    page: 1,
                                    status: "Not applicable",
                                  });
                                }}
                                value={
                                  clientQuestionList.numberOfQuestionsNotApplicable
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={clientQuestionList.client_questions.map(
                            (client_question) => {
                              return (
                                <tr
                                  key={client_question.id}
                                  onClick={() => {
                                    if (
                                      clientDetails.client
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listClientQuestionDetailsAction(
                                          client_question.id
                                        )
                                      );

                                      setUpdateQuestionDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Question */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{client_question.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Question</strong>
                                          </p>

                                          <p>{client_question.question}</p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Answer</strong>
                                          </p>

                                          <p>
                                            {client_question.answer ? (
                                              client_question.answer
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={client_question.name}
                                    />
                                  </td>

                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      value={client_question.category}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={client_question.owner_name}
                                      image={
                                        client_question.owner_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        client_question.status === "Resolved"
                                          ? "success"
                                          : client_question.status ===
                                            "Researching"
                                          ? "warning"
                                          : "primary"
                                      }
                                      value={client_question.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !clientQuestionQuery.category &&
                            !clientQuestionQuery.owner &&
                            !clientQuestionQuery.searchString &&
                            !clientQuestionQuery.status
                          }
                          count={clientQuestionList.count}
                          head={
                            <tr>
                              {/* Question */}
                              <TableColumnHeadSearch
                                queryObject={clientQuestionQuery}
                                queryVariable="searchString"
                                setQueryObject={setClientQuestionQuery}
                                setQueryVariable="searchString"
                                title="Question"
                                width="200px"
                              />

                              {/* Category */}
                              <TableColumnHeadSelectSimple
                                filterObject={clientQuestionQuery}
                                filterVariable="category"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Performance",
                                    icon: "dashboard",
                                    value: "Performance",
                                  },
                                  {
                                    display: "Strategy",
                                    icon: "locate",
                                    value: "Strategy",
                                  },
                                  {
                                    display: "Trends",
                                    icon: "trending-up",
                                    value: "Trends",
                                  },
                                ]}
                                setFilterObject={setClientQuestionQuery}
                                title="Category"
                                width="125px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={clientQuestionQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={setClientQuestionQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={clientQuestionQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Resolved",
                                    icon: "issue-closed",
                                    value: "Resolved",
                                  },
                                  {
                                    display: "Researching",
                                    icon: "issue",
                                    value: "Researching",
                                  },
                                  {
                                    display: "Not applicable",
                                    icon: "disable",
                                    value: "Not applicable",
                                  },
                                ]}
                                setFilterObject={setClientQuestionQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            clientDetails.client.user_access_status !== "view"
                          }
                          loading={clientQuestionList.loading}
                          noMargin
                          onClearFilters={() => {
                            setClientQuestionQuery({
                              ...clientQuestionQueryDefault,
                            });
                          }}
                          page={clientQuestionQuery.page}
                          setPageObject={setClientQuestionQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage and capture the outcome of client research"
                    icon="search-template"
                    message={
                      <>
                        advaise.app has suggested a set of questions to ask.
                        Assign them to managers or delete them as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Client questions"
                  />

                  {/* Key performance indicators */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddClientKPIDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search KPI"
                            searching={clientKPIList.loading}
                            setValueObject={setClientKPIQuery}
                            setValueVariable="searchString"
                            value={clientKPIQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={clientKPIList.client_kpis.map(
                            (client_kpi) => {
                              return (
                                <KPICard
                                  interactive
                                  key={client_kpi.id}
                                  onClick={() => {
                                    dispatch(
                                      listClientKPIDetailsAction(client_kpi.id)
                                    );

                                    setUpdateClientKPIDrawerIsOpen(true);
                                  }}
                                  kpi={client_kpi}
                                />
                              );
                            }
                          )}
                          count={clientKPIList.count}
                          loading={clientKPIList.loading}
                          noMargin
                          noResultsMessage="Add KPIs."
                          noResultsTitle="No KPIs found"
                          page={clientKPIQuery.page}
                          setPageObject={setClientKPIQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Identify and analyse the client's key performance indicators"
                    icon="dashboard"
                    title="Key performance indicators"
                  />

                  {/* Financials */}
                  {downloadedClientIntel && (
                    <TabSection2
                      content={
                        <>
                          <ClientFinancials
                            data={downloadedClientIntel}
                            loading={clientIntel.loading}
                            noMargin
                          />
                        </>
                      }
                      help="Review the client's financial position"
                      icon="chart"
                      message="advaise.app has sourced this information from third-party data sources."
                      messageIntent="primary"
                      title="Financials"
                    />
                  )}

                  {/* News */}
                  {downloadedClientIntel["news_items"] && (
                    <TabSection2
                      content={downloadedClientIntel["news_items"].map(
                        (news_item, i, { length }) => {
                          return (
                            <div key={news_item.uuid}>
                              <NewsCard
                                date={new Date(
                                  news_item.providerPublishTime * 1000
                                ).toLocaleDateString()}
                                interactive
                                onClick={() =>
                                  window.open(
                                    news_item.link,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                                }
                                text={undefined}
                                thumbnail={
                                  news_item.thumbnail
                                    ? news_item.thumbnail.resolutions[1].url
                                    : undefined
                                }
                                title={news_item.title}
                              />

                              {length - 1 === i ? undefined : (
                                <Divider style={{ margin: "10px 0 10px 0" }} />
                              )}
                            </div>
                          );
                        }
                      )}
                      help="Stay abreast of news that may impact the client"
                      icon="inbox-update"
                      message="advaise.app uses AI to recommend news articles."
                      messageIntent="primary"
                      title="News"
                    />
                  )}

                  {/* Needs */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddNeedDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search need"
                            searching={clientNeedList.loading}
                            setValueObject={setClientNeedQuery}
                            setValueVariable="searchString"
                            value={clientNeedQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={clientNeedList.client_needs.map(
                            (need, i, { length }) => {
                              return (
                                <div key={need.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>{need.need_data.name}</div>

                                        <div style={{ marginLeft: "5px" }}>
                                          <InfoPopup
                                            content={
                                              <NeedInfo
                                                needData={need.need_data}
                                              />
                                            }
                                          />
                                        </div>
                                      </div>
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive={
                                          clientDetails.client
                                            .user_access_status !== "view"
                                        }
                                        onClick={() => {
                                          if (
                                            clientDetails.client
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listClientNeedDetailsAction(
                                                need.id
                                              )
                                            );

                                            setUpdateNeedDrawerIsOpen(true);
                                          }
                                        }}
                                        text={
                                          need.notes ? (
                                            need.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={clientNeedList.count}
                          loading={clientNeedList.loading}
                          noResultsMessage="Add needs."
                          noResultsTitle="No needs found"
                          page={clientNeedQuery.page}
                          setPageObject={setClientNeedQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Add needs to the client"
                    icon="form"
                    title="Needs"
                  />
                </div>
              }
            />

            {/* Development */}
            <Tab
              id="client-development-tab"
              title="Development"
              panel={
                <div>
                  {/* Account development actions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddActionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive={!showActionKanban}
                                label="Completed"
                                loading={clientActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setClientActionQuery({
                                      ...clientActionQuery,
                                      page: 1,
                                      status: "Completed",
                                    });
                                }}
                                value={
                                  clientActionList.numberOfActionsCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive={!showActionKanban}
                                label="In-progress"
                                loading={clientActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setClientActionQuery({
                                      ...clientActionQuery,
                                      page: 1,
                                      status: "In-progress",
                                    });
                                }}
                                value={
                                  clientActionList.numberOfActionsInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive={!showActionKanban}
                                label="On-hold"
                                loading={clientActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setClientActionQuery({
                                      ...clientActionQuery,
                                      page: 1,
                                      status: "On-hold",
                                    });
                                }}
                                value={clientActionList.numberOfActionsOnHold}
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Buttons */}
                        <ButtonGroup
                          style={{
                            background: lightMode
                              ? Colors.LIGHT_GRAY3
                              : Colors.DARK_GRAY5,
                            maxWidth: "100%",
                            minWidth: "100%",
                            padding: "10px 10px 0 10px",
                          }}
                        >
                          <Button
                            icon="th"
                            onClick={() => setShowActionKanban(false)}
                          />

                          <Button
                            icon="list-columns"
                            onClick={() => {
                              setClientActionQuery({
                                ...clientActionQueryDefault,
                              });

                              setInitialiseActionKanban(true);

                              setShowActionKanban(true);
                            }}
                          />
                        </ButtonGroup>

                        {/* Data table */}
                        {!showActionKanban && (
                          <TableContainer2
                            body={clientActionList.client_actions.map(
                              (client_action) => {
                                return (
                                  <tr
                                    key={client_action.id}
                                    onClick={() => {
                                      if (
                                        clientDetails.client
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listClientActionDetailsAction(
                                            client_action.id
                                          )
                                        );

                                        setUpdateActionDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Action */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5>{client_action.name}</H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Action</strong>
                                            </p>

                                            <p>{client_action.action}</p>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Comments</strong>
                                            </p>

                                            <p>
                                              {client_action.comments ? (
                                                client_action.comments
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No comments.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={client_action.name}
                                      />
                                    </td>

                                    {/* Category */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableTag
                                        color={
                                          client_action.category === "Delivery"
                                            ? Colors.LIME3
                                            : client_action.category ===
                                              "Opportunity"
                                            ? Colors.TURQUOISE3
                                            : client_action.category ===
                                              "Planning"
                                            ? Colors.INDIGO3
                                            : (client_action.category ===
                                                "Delivery") ===
                                              "Relationship"
                                            ? Colors.ROSE3
                                            : client_action.category === "Team"
                                            ? Colors.CERULEAN3
                                            : Colors.BLUE3
                                        }
                                        value={client_action.category}
                                      />
                                    </td>

                                    {/* Owner */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={client_action.owner_name}
                                        image={
                                          client_action.owner_profile_photo
                                        }
                                        showImage
                                      />
                                    </td>

                                    {/* Due */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={client_action.due} />
                                    </td>

                                    {/* Status */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          client_action.status === "Completed"
                                            ? "success"
                                            : client_action.status ===
                                              "In-progress"
                                            ? "warning"
                                            : "primary"
                                        }
                                        value={client_action.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !clientActionQuery.category &&
                              !clientActionQuery.dueEnd &&
                              !clientActionQuery.dueStart &&
                              !clientActionQuery.owner &&
                              !clientActionQuery.searchString &&
                              !clientActionQuery.status
                            }
                            count={clientActionList.count}
                            head={
                              <tr>
                                {/* Action */}
                                <TableColumnHeadSearch
                                  queryObject={clientActionQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setClientActionQuery}
                                  setQueryVariable="searchString"
                                  title="Action"
                                  width="200px"
                                />

                                {/* Category */}
                                <TableColumnHeadSelectSimple
                                  filterObject={clientActionQuery}
                                  filterVariable="category"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Delivery",
                                      icon: "gantt-chart",
                                      value: "Delivery",
                                    },
                                    {
                                      display: "Opportunity",
                                      icon: "locate",
                                      value: "Opportunity",
                                    },
                                    {
                                      display: "Planning",
                                      icon: "path-search",
                                      value: "Planning",
                                    },
                                    {
                                      display: "Relationship",
                                      icon: "one-to-one",
                                      value: "Relationship",
                                    },
                                    {
                                      display: "Team",
                                      icon: "people",
                                      value: "Team",
                                    },
                                    {
                                      display: "Other",
                                      icon: "more",
                                      value: "Other",
                                    },
                                  ]}
                                  setFilterObject={setClientActionQuery}
                                  title="Category"
                                  width="125px"
                                />

                                {/* Owner */}
                                <TableColumnHeadSelect2
                                  filterObject={clientActionQuery}
                                  filterVariable="owner"
                                  items={managerList.managers}
                                  setFilterObject={setClientActionQuery}
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Owner"
                                  width="200px"
                                />

                                {/* Due */}
                                <TableColumnHeadDate
                                  endDateVariable="dueEnd"
                                  queryObject={clientActionQuery}
                                  setQueryObject={setClientActionQuery}
                                  shortRange
                                  startDateVariable="dueStart"
                                  title="Due"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={clientActionQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Completed",
                                      icon: "confirm",
                                      value: "Completed",
                                    },
                                    {
                                      display: "In-progress",
                                      icon: "refresh",
                                      value: "In-progress",
                                    },
                                    {
                                      display: "On-hold",
                                      icon: "remove",
                                      value: "On-hold",
                                    },
                                  ]}
                                  setFilterObject={setClientActionQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              clientDetails.client.user_access_status !== "view"
                            }
                            loading={clientActionList.loading}
                            noMargin
                            onClearFilters={() => {
                              setClientActionQuery({
                                ...clientActionQueryDefault,
                              });
                            }}
                            page={clientActionQuery.page}
                            setPageObject={setClientActionQuery}
                            setPageVariable="page"
                          />
                        )}

                        {/* Kanban */}
                        {showActionKanban && (
                          <Kanban
                            centerColumns
                            data={generateKanbanDataFromClientActions(
                              clientDetails.client.actions
                            )}
                            initialise={initialiseActionKanban}
                            noMargin
                            onClick={(id) => {
                              if (
                                clientDetails.client.user_access_status !==
                                "view"
                              ) {
                                dispatch(listClientActionDetailsAction(id));

                                setUpdateActionDrawerIsOpen(true);
                              }
                            }}
                            onDragEnd={(result) => {
                              const actionId = result.draggableId;
                              const actionStatus =
                                result.destination.droppableId;

                              updateActionStatus(actionId, actionStatus);
                            }}
                            searchBarMargin="-45px 0 20px 70px"
                            searchBarPlaceholder="Search actions"
                            setInitialise={setInitialiseActionKanban}
                            statusCategories={[
                              {
                                intent: "primary",
                                value: "On-hold",
                              },
                              {
                                intent: "warning",
                                value: "In-progress",
                              },
                              {
                                intent: "success",
                                value: "Completed",
                              },
                            ]}
                          />
                        )}
                      </>
                    }
                    help="Assign and review actions to develop the account"
                    icon="take-action"
                    message={
                      <>
                        advaise.app has prefilled this section with a standard
                        set of actions. Assign them to managers or delete them
                        as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Account development actions"
                  />

                  {/* Strategic relationships */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddClientStakeholderDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        {/* Stakeholder matrix */}
                        {clientStakeholderList.client_stakeholders.length >
                        0 ? (
                          <Matrix
                            colors={[
                              [
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                            ]}
                            data={clientStakeholderList.client_stakeholders}
                            intents={[
                              [
                                "danger",
                                "danger",
                                "danger",
                                "warning",
                                "success",
                              ],
                              [
                                "danger",
                                "danger",
                                "warning",
                                "warning",
                                "success",
                              ],
                              [
                                "danger",
                                "warning",
                                "warning",
                                "success",
                                "success",
                              ],
                              [
                                "warning",
                                "warning",
                                "success",
                                "success",
                                "success",
                              ],
                              [
                                "warning",
                                "success",
                                "success",
                                "success",
                                "success",
                              ],
                            ]}
                            interactive
                            itemNameVariable="stakeholder_name"
                            leftColumnWidth="150px"
                            onClickAccessTest={() =>
                              clientDetails.client.user_access_status !== "view"
                            }
                            onClickListDetailsAction={
                              listClientStakeholderDetailsAction
                            }
                            onClickSetDrawerIsOpen={
                              setUpdateClientStakeholderDrawerIsOpen
                            }
                            xAxisCategories={[1, 2, 3, 4, 5]}
                            xAxisDisplayCategories={[
                              "Very Negative",
                              "Negative",
                              "Neutral",
                              "Positive",
                              "Very Positive",
                            ]}
                            xAxisFilterVariable="attitude_toward_us"
                            xAxisLabel="Attitude toward us"
                            yAxisCategories={[5, 4, 3, 2, 1]}
                            yAxisDisplayCategories={[
                              "Very High",
                              "High",
                              "Medium",
                              "Low",
                              "Very Low",
                            ]}
                            yAxisFilterVariable="level_of_influence"
                            yAxisLabel="Influence"
                          />
                        ) : undefined}

                        {/* Search field */}
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search contacts"
                            searching={clientStakeholderList.loading}
                            setValueObject={setClientStakeholderQuery}
                            setValueVariable="searchString"
                            value={clientStakeholderQuery.member}
                          />
                        </div>

                        {/* Stakeholders */}
                        <ItemCardList
                          content={clientStakeholderList.client_stakeholders.map(
                            (client_stakeholder) => {
                              return (
                                <ContactCard
                                  additionalInfo={
                                    <>
                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Level of influence</strong>
                                      </p>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <TabSectionFieldProgressBar
                                            rating={
                                              client_stakeholder.level_of_influence /
                                              5
                                            }
                                          />
                                        </div>

                                        <div
                                          className={[
                                            Classes.TEXT_MUTED,
                                            Classes.TEXT_SMALL,
                                          ].join(" ")}
                                        >
                                          {client_stakeholder.level_of_influence ===
                                          5
                                            ? "Very high"
                                            : client_stakeholder.level_of_influence ===
                                              4
                                            ? "High"
                                            : client_stakeholder.level_of_influence ===
                                              3
                                            ? "Medium"
                                            : client_stakeholder.level_of_influence ===
                                              2
                                            ? "Low"
                                            : "Very low"}
                                        </div>
                                      </div>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>
                                          Relationship management strategy
                                        </strong>
                                      </p>

                                      <p>
                                        {client_stakeholder.comments ? (
                                          client_stakeholder.comments
                                        ) : (
                                          <span className={Classes.TEXT_MUTED}>
                                            No comments.
                                          </span>
                                        )}
                                      </p>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Relationship owner</strong>
                                      </p>

                                      {client_stakeholder.relationship_owner ? (
                                        <div
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <img
                                            alt="profile"
                                            src={
                                              client_stakeholder.relationship_owner_profile_photo
                                                ? client_stakeholder.relationship_owner_profile_photo
                                                : "/images/no_photo.png"
                                            }
                                            style={{
                                              background: "white",
                                              border: `1px solid ${
                                                lightMode
                                                  ? Colors.LIGHT_GRAY5
                                                  : Colors.DARK_GRAY5
                                              }`,
                                              borderRadius: "50%",
                                              height: "40px",
                                              margin: "0 10px 0 0",
                                              objectFit: "contain",
                                              width: "40px",
                                            }}
                                          />

                                          <TabSectionEmailLink
                                            email={
                                              client_stakeholder.relationship_owner_name
                                            }
                                            invertColor
                                          />
                                        </div>
                                      ) : (
                                        <p>-</p>
                                      )}
                                    </>
                                  }
                                  borderColor={Colors.ROSE3}
                                  data={client_stakeholder.stakeholder_data}
                                  interactive
                                  key={client_stakeholder.id}
                                  onClick={() => {
                                    dispatch(
                                      listClientStakeholderDetailsAction(
                                        client_stakeholder.id
                                      )
                                    );

                                    setUpdateClientStakeholderDrawerIsOpen(
                                      true
                                    );
                                  }}
                                />
                              );
                            }
                          )}
                          count={clientStakeholderList.count}
                          loading={clientStakeholderList.loading}
                          noMargin
                          noResultsMessage="Add contacts."
                          noResultsTitle="No contacts found"
                          page={clientStakeholderQuery.page}
                          setPageObject={setClientStakeholderQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Analyse key relationships in the account"
                    icon="following"
                    title="Strategic relationships"
                  />

                  {/* Investment programs */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddClientProgramDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={clientProgramList.client_programs.map(
                            (client_program) => {
                              return (
                                <tr
                                  key={client_program.id}
                                  onClick={() => {
                                    if (
                                      clientDetails.client
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listClientProgramDetailsAction(
                                          client_program.id
                                        )
                                      );

                                      setUpdateClientProgramDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Program */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{client_program.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Notes</strong>
                                          </p>

                                          <p>
                                            {client_program.notes ? (
                                              client_program.notes
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={client_program.name}
                                    />
                                  </td>

                                  {/* Year */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag value={client_program.year} />
                                  </td>

                                  {/* Sponsor */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      image={
                                        client_program.sponsor_profile_photo
                                      }
                                      showImage
                                      text={client_program.sponsor_name}
                                      url={
                                        client_program.sponsor
                                          ? `/contacts/${client_program.sponsor}`
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Expected revenue */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={client_program.expected_revenue}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !clientProgramQuery.searchString &&
                            !clientProgramQuery.sponsor &&
                            !clientProgramQuery.year
                          }
                          count={clientProgramList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="3"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={clientProgramList.expected_revenue}
                                />
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Program */}
                              <TableColumnHeadSearch
                                queryObject={clientProgramQuery}
                                queryVariable="searchString"
                                setQueryObject={setClientProgramQuery}
                                setQueryVariable="searchString"
                                title="Program"
                                width="200px"
                              />

                              {/* Year */}
                              <TableColumnHeadSelectSimple
                                filterObject={clientProgramQuery}
                                filterVariable="year"
                                options={generateYearList}
                                setFilterObject={setClientProgramQuery}
                                title="Year"
                                width="125px"
                              />

                              {/* Sponsor */}
                              <TableColumnHeadSelect2
                                filterObject={clientProgramQuery}
                                filterVariable="sponsor"
                                items={contactList.contacts}
                                setFilterObject={setClientProgramQuery}
                                setQueryObject={setContactQuery}
                                setQueryVariable="searchString"
                                title="Sponsor"
                                width="200px"
                              />

                              {/* Expected revenue */}
                              <TableColumnHeadSelectSimple
                                filterObject={clientProgramQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "expected_revenue",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-expected_revenue",
                                  },
                                ]}
                                setFilterObject={setClientProgramQuery}
                                title="Expected revenue"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            clientDetails.client.user_access_status !== "view"
                          }
                          loading={clientProgramList.loading}
                          noMargin
                          onClearFilters={() => {
                            setClientProgramQuery({
                              ...clientProgramQueryDefault,
                            });
                          }}
                          page={clientProgramQuery.page}
                          setPageObject={setClientProgramQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Identify sources of revenue this financial year"
                    icon="projects"
                    title="Investment programs"
                  />

                  {/* Proposals */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Pipeline */}
                              <ProposalPipelineCard
                                fill
                                proposalList={proposalList}
                              />

                              {/* Won-lost */}
                              <ProposalWinLossCard
                                proposalList={proposalList}
                                proposalQuery={proposalQuery}
                              />

                              {/* Average value */}
                              <ProposalAverageValueCard
                                fill
                                proposalList={proposalList}
                              />

                              {/* Total value */}
                              <ProposalTotalValueCard
                                proposalList={proposalList}
                                proposalQuery={proposalQuery}
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={proposalList.proposals.map((proposal) => {
                            return (
                              <tr
                                key={proposal.id}
                                onClick={() => {
                                  if (proposal.user_has_permission) {
                                    dispatch(resetListProposalDetailsAction());

                                    navigate(`/proposals/${proposal.id}`);
                                  } else {
                                    AppToaster.show({
                                      icon: "warning-sign",
                                      intent: "danger",
                                      message:
                                        "You do not have access to this proposal.",
                                    });
                                  }
                                }}
                              >
                                {/* Proposal */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    tagIntent={
                                      !proposal.user_has_permission
                                        ? "danger"
                                        : proposal.user_access_status === "view"
                                        ? "primary"
                                        : undefined
                                    }
                                    tagText={
                                      !proposal.user_has_permission
                                        ? "No access"
                                        : proposal.user_access_status === "view"
                                        ? "View only"
                                        : undefined
                                    }
                                    text={proposal.name}
                                  />
                                </td>

                                {/* Value */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={proposal.value} />
                                </td>

                                {/* Manager */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    email={proposal.manager_name}
                                    image={proposal.manager_profile_photo}
                                    showImage
                                  />
                                </td>

                                {/* Due */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={proposal.due} />
                                </td>

                                {/* Status */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableTag
                                    intent={
                                      proposal.status === "Won"
                                        ? "success"
                                        : proposal.status === "Lost"
                                        ? "danger"
                                        : proposal.status === "Cancelled"
                                        ? "warning"
                                        : "primary"
                                    }
                                    value={proposal.status}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !proposalQuery.client &&
                            !proposalQuery.dueEnd &&
                            !proposalQuery.dueStart &&
                            !proposalQuery.manager &&
                            !proposalQuery.searchString &&
                            !proposalQuery.status
                          }
                          count={proposalList.count}
                          footnote={
                            <div>Figures in {activeOrganisation.currency}</div>
                          }
                          head={
                            <tr>
                              {/* Proposal */}
                              <TableColumnHeadSearch
                                queryObject={proposalQuery}
                                queryVariable="searchString"
                                setQueryObject={setProposalQuery}
                                setQueryVariable="searchString"
                                title="Proposal"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setProposalQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Manager */}
                              <TableColumnHeadSelect2
                                filterObject={proposalQuery}
                                filterVariable="manager"
                                items={managerList.managers}
                                setFilterObject={setProposalQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Manager"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={proposalQuery}
                                setQueryObject={setProposalQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Qualifying",
                                    icon: "search-text",
                                    value: "Qualifying",
                                  },
                                  {
                                    display: "In-progress",
                                    icon: "manually-entered-data",
                                    value: "In-progress",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "envelope",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Won",
                                    icon: "tick-circle",
                                    value: "Won",
                                  },
                                  {
                                    display: "Lost",
                                    icon: "error",
                                    value: "Lost",
                                  },
                                  {
                                    display: "Cancelled",
                                    icon: "undo",
                                    value: "Cancelled",
                                  },
                                ]}
                                setFilterObject={setProposalQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={proposalList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalQuery({
                              ...proposalQueryDefault,
                            });
                          }}
                          page={proposalQuery.page}
                          setPageObject={setProposalQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review proposals with this client"
                    icon="manual"
                    title="Proposals"
                  />

                  {/* Engagements */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Plan at completion */}
                              <EngagementPlanAtCompletionCard
                                fill
                                engagementList={engagementList}
                                showInsights
                              />

                              {/* Actuals */}
                              <EngagementActualsCard
                                fill
                                engagementList={engagementList}
                                showInsights
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={engagementList.engagements.map((engagement) => {
                            return (
                              <tr
                                key={engagement.id}
                                onClick={() => {
                                  if (engagement.user_has_permission) {
                                    dispatch(
                                      resetListEngagementDetailsAction()
                                    );

                                    navigate(`/engagements/${engagement.id}`);
                                  } else {
                                    AppToaster.show({
                                      icon: "warning-sign",
                                      intent: "danger",
                                      message:
                                        "You do not have access to this engagement.",
                                    });
                                  }
                                }}
                              >
                                {/* Engagement */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    tagIntent={
                                      !engagement.user_has_permission
                                        ? "danger"
                                        : engagement.user_access_status ===
                                          "view"
                                        ? "primary"
                                        : undefined
                                    }
                                    tagText={
                                      !engagement.user_has_permission
                                        ? "No access"
                                        : engagement.user_access_status ===
                                          "view"
                                        ? "View only"
                                        : undefined
                                    }
                                    text={engagement.name}
                                  />
                                </td>

                                {/* Value */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={engagement.value} />
                                </td>

                                {/* Sponsor */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    image={engagement.sponsor_profile_photo}
                                    showImage
                                    text={engagement.sponsor_name}
                                    url={
                                      engagement.sponsor
                                        ? `/clients/${engagement.sponsor}`
                                        : undefined
                                    }
                                  />
                                </td>

                                {/* Manager */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    email={engagement.manager_name}
                                    image={engagement.manager_profile_photo}
                                    showImage
                                  />
                                </td>

                                {/* Status */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableTag
                                    intent={
                                      engagement.status === "Amber"
                                        ? "warning"
                                        : engagement.status === "Green"
                                        ? "success"
                                        : engagement.status === "Red"
                                        ? "danger"
                                        : "primary"
                                    }
                                    value={engagement.status}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !engagementQuery.client &&
                            !engagementQuery.manager &&
                            !engagementQuery.searchString &&
                            !engagementQuery.sponsor
                          }
                          count={engagementList.count}
                          footnote={
                            <div>Figures in {activeOrganisation.currency}</div>
                          }
                          head={
                            <tr>
                              {/* Engagement */}
                              <TableColumnHeadSearch
                                queryObject={engagementQuery}
                                queryVariable="searchString"
                                setQueryObject={setEngagementQuery}
                                setQueryVariable="searchString"
                                title="Engagement"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setEngagementQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Sponsor */}
                              <TableColumnHeadSelect2
                                filterObject={engagementQuery}
                                filterVariable="sponsor"
                                items={contactList.contacts}
                                setFilterObject={setEngagementQuery}
                                setQueryObject={setContactQuery}
                                setQueryVariable="searchString"
                                title="Sponsor"
                                width="200px"
                              />

                              {/* Manager */}
                              <TableColumnHeadSelect2
                                filterObject={engagementQuery}
                                filterVariable="manager"
                                items={managerList.managers}
                                setFilterObject={setEngagementQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Manager"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Planning",
                                    icon: "playbook",
                                    value: "Planning",
                                  },
                                  {
                                    display: "Green",
                                    icon: "repeat",
                                    value: "Green",
                                  },
                                  {
                                    display: "Amber",
                                    icon: "issue-new",
                                    value: "Amber",
                                  },
                                  {
                                    display: "Red",
                                    icon: "issue",
                                    value: "Red",
                                  },
                                  {
                                    display: "Closed",
                                    icon: "confirm",
                                    value: "Closed",
                                  },
                                ]}
                                setFilterObject={setEngagementQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={engagementList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementQuery({
                              ...engagementQueryDefault,
                            });
                          }}
                          page={engagementQuery.page}
                          setPageObject={setEngagementQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review engagements with this client"
                    icon="gantt-chart"
                    title="Engagements"
                  />

                  {/* Approvals */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          clientDetails.client.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddApprovalRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalRequestList.approval_requests.map(
                            (approval_request) => {
                              return (
                                <tr
                                  key={approval_request.id}
                                  onClick={() => {
                                    if (
                                      clientDetails.client
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listApprovalRequestDetailsAction(
                                          approval_request.id
                                        )
                                      );

                                      if (
                                        approval_request.status === "Completed"
                                      ) {
                                        setViewApprovalDrawerIsOpen(true);
                                      } else {
                                        setUpdateApprovalRequestDrawerIsOpen(
                                          true
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={approval_request.decision_right}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={approval_request.value}
                                      tagText={activeOrganisation.currency}
                                    />
                                  </td>

                                  {/* Approver */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={approval_request.approver_name}
                                      image={
                                        approval_request.approver_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .reviewed
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome === "Approved"
                                            ? "success"
                                            : approval_request.response_data
                                                .outcome === "Denied"
                                            ? "danger"
                                            : "primary"
                                          : undefined
                                      }
                                      value={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalRequestQuery.approver &&
                            !approvalRequestQuery.decisionRight &&
                            !approvalRequestQuery.outcome &&
                            !approvalRequestQuery.reviewedEnd &&
                            !approvalRequestQuery.reviewedStart &&
                            !approvalRequestQuery.value
                          }
                          count={approvalRequestList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Client account plan",
                                    value: "Client account plan",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Approver */}
                              <TableColumnHeadSelect2
                                filterObject={approvalRequestQuery}
                                filterVariable="approver"
                                items={managerList.managers}
                                setFilterObject={setApprovalRequestQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Approver"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalRequestQuery}
                                setQueryObject={setApprovalRequestQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalRequestQuery({
                              ...approvalRequestQueryDefault,
                            });
                          }}
                          page={approvalRequestQuery.page}
                          setPageObject={setApprovalRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage approval requests"
                    icon="book"
                    message="When you create an approval request, advaise.app generates a fact set to inform the approver's decision. This fact set is based on the information at the time. If there is a change, consider submitting a new approval request."
                    messageIntent="warning"
                    title="Approvals"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default ClientDetailScreen;
