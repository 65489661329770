// Constants import
import { ENABLE_SIGNUP } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  Divider,
  H1,
  H3,
  H6,
} from "@blueprintjs/core";
import React, { useCallback, useState, useEffect } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import LinkTag from "../components/LinkTag";

// Redux action imports
// User
import {
  loginAction,
  resetSignupAction,
  signupAction,
} from "../actions/userActions";

// Main function
function SignupScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userSignup = useSelector((state) => state.userSignup);

  // Form variables
  // Signup credentials
  const [signupCredentials, setSignupCredentials] = useState({
    email: "",
    password1: "",
    password2: "",
  });

  // If successful, login
  useEffect(() => {
    if (userSignup.success) {
      dispatch(resetSignupAction());

      dispatch(
        loginAction({
          email: signupCredentials.email,
          password: signupCredentials.password1,
          recaptcha_token: reCaptchaToken,
        })
      );
    }
    // eslint-disable-next-line
  }, [userSignup.success]);

  // If logged in navigate to homepage
  useEffect(() => {
    userLogin.token && navigate("/");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // Signup
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const onVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const signup = () => {
    // Dispatch action
    dispatch(
      signupAction({
        email: signupCredentials.email,
        password1: signupCredentials.password1,
        password2: signupCredentials.password2,
        recaptcha_token: reCaptchaToken,
      })
    );

    // Refresh reCAPTCHA
    setRefreshReCaptcha((r) => !r);
  };

  // JSX UI code
  return (
    <div
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(24, 145, 172, 0.8), rgba(24, 145, 172, 1))",
        display: "flex",
        justifyContent: "center",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      {/* Card */}
      <Card
        className={Classes.DARK}
        elevation={4}
        style={{
          border: "1px solid #D2ECF9",
          borderRadius: "5px",
          margin: "20px 5px 20px 5px",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              objectFit: "contain",
              width: "100px",
            }}
          />
        </div>

        <div className="row" style={{ textAlign: "center" }}>
          {/* Heading */}
          <H1>Try advaise.app for FREE</H1>

          <H3 style={{ color: "#D2ECF9", margin: "0 0 20px 0" }}>
            Manage your professional services business in one easy system
          </H3>
        </div>

        <div className="row">
          {/* Form */}
          <div className="col-s-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Error message */}
              {userSignup.error && (
                <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                  {userSignup.error.response.data["non_field_errors"]
                    ? userSignup.error.response.data["non_field_errors"]
                    : "We couldn't sign you up. Check your credentials and try again."}
                </Callout>
              )}

              {/* Email */}
              <FormDrawerTextField2
                error={userSignup.error}
                fieldName="email"
                label="Email"
                labelInfo="(required)"
                large
                placeholder="Enter your email address"
                setValueObject={setSignupCredentials}
                setValueVariable="email"
                value={signupCredentials.email}
              />

              {/* Password */}
              <FormDrawerTextField2
                error={userSignup.error}
                fieldName="password1"
                label="Password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter your password"
                setValueObject={setSignupCredentials}
                setValueVariable="password1"
                value={signupCredentials.password1}
              />

              {/* Confirm password */}
              <FormDrawerTextField2
                error={userSignup.error}
                fieldName="password2"
                label="Confirm password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter your password again"
                setValueObject={setSignupCredentials}
                setValueVariable="password2"
                value={signupCredentials.password2}
              />

              {/* reCAPTCHA */}
              <div
                style={{
                  margin: !reCaptchaToken ? "20px 0 20px 0" : undefined,
                }}
              >
                <GoogleReCaptcha
                  action="signup"
                  onVerify={onVerify}
                  refreshReCaptcha={refreshReCaptcha}
                />
              </div>

              {/* Signup */}
              <Button
                disabled={ENABLE_SIGNUP === "false" || !reCaptchaToken}
                fill
                intent="success"
                large
                loading={userSignup.loading}
                onClick={signup}
                style={{ margin: "20px 0 0 0" }}
                text="Try it for FREE"
                type="submit"
              />

              <H6
                style={{
                  color: ENABLE_SIGNUP === "false" ? Colors.RED5 : "#D2ECF9",
                  margin: "10px 0 0 0",
                  textAlign: "center",
                }}
              >
                {ENABLE_SIGNUP === "false"
                  ? "We're sorry, we're not accepting signups currently"
                  : "No credit card needed"}
              </H6>
            </form>

            <Divider style={{ margin: "15px 0 15px 0" }} />

            {/* Learn more */}
            <Button
              fill
              intent="primary"
              large
              onClick={() =>
                window.open(
                  `https://blog.advaise.app`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              text="Learn more"
            />
          </div>

          {/* Image */}
          <div className="col-s-6 hide-xs">
            <img
              alt="screenshots"
              src="/images/sign_up.png"
              style={{
                maxHeight: "350px",
                objectFit: "contain",
                width: "100%",
              }}
            />
          </div>
        </div>

        {/* Footer */}
        <div
          style={{
            margin: "20px 20px 0 20px",
          }}
        >
          <p>
            Already have an account?{" "}
            <LinkTag
              intent="primary"
              label="Login"
              onClick={() => {
                navigate("/login");
              }}
            />
          </p>

          <p>
            We respect your privacy. For more information, read our{" "}
            <LinkTag
              label="Privacy Policy"
              onClick={() =>
                window.open(
                  `https://blog.advaise.app/privacy`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            />
            .
          </p>
        </div>
      </Card>
    </div>
  );
}

export default SignupScreen;
