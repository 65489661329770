// Constant imports
import {
  TIMESHEET_ENTRY_LIST_REQUEST,
  TIMESHEET_ENTRY_LIST_SUCCESS,
  TIMESHEET_ENTRY_LIST_FAIL,
  TIMESHEET_ENTRY_LIST_RESET,
  TIMESHEET_ENTRY_CREATE_REQUEST,
  TIMESHEET_ENTRY_CREATE_SUCCESS,
  TIMESHEET_ENTRY_CREATE_FAIL,
  TIMESHEET_ENTRY_CREATE_RESET,
  TIMESHEET_ENTRY_DETAILS_REQUEST,
  TIMESHEET_ENTRY_DETAILS_SUCCESS,
  TIMESHEET_ENTRY_DETAILS_FAIL,
  TIMESHEET_ENTRY_DETAILS_RESET,
  TIMESHEET_ENTRY_UPDATE_REQUEST,
  TIMESHEET_ENTRY_UPDATE_SUCCESS,
  TIMESHEET_ENTRY_UPDATE_FAIL,
  TIMESHEET_ENTRY_UPDATE_RESET,
  TIMESHEET_ENTRY_DELETE_REQUEST,
  TIMESHEET_ENTRY_DELETE_SUCCESS,
  TIMESHEET_ENTRY_DELETE_FAIL,
  TIMESHEET_ENTRY_DELETE_RESET,
} from "../constants/timesheetEntryConstants";

// List all
export const timesheetEntryListReducer = (
  state = {
    loading: false,
    count: 0,
    timesheet_entries: [],
    totalMinutes: 0,
    totalMinutesFri: 0,
    totalMinutesMon: 0,
    totalMinutesSat: 0,
    totalMinutesSun: 0,
    totalMinutesThu: 0,
    totalMinutesTue: 0,
    totalMinutesWed: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_ENTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TIMESHEET_ENTRY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        timesheet_entries: action.payload.timesheet_entries,
        totalMinutes: action.payload.totalMinutes,
        totalMinutesFri: action.payload.totalMinutesFri,
        totalMinutesMon: action.payload.totalMinutesMon,
        totalMinutesSat: action.payload.totalMinutesSat,
        totalMinutesSun: action.payload.totalMinutesSun,
        totalMinutesThu: action.payload.totalMinutesThu,
        totalMinutesTue: action.payload.totalMinutesTue,
        totalMinutesWed: action.payload.totalMinutesWed,
        success: true,
        error: "",
      };

    case TIMESHEET_ENTRY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        timesheet_entries: [],
        totalMinutes: 0,
        totalMinutesFri: 0,
        totalMinutesMon: 0,
        totalMinutesSat: 0,
        totalMinutesSun: 0,
        totalMinutesThu: 0,
        totalMinutesTue: 0,
        totalMinutesWed: 0,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_ENTRY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        timesheet_entries: [],
        totalMinutes: 0,
        totalMinutesFri: 0,
        totalMinutesMon: 0,
        totalMinutesSat: 0,
        totalMinutesSun: 0,
        totalMinutesThu: 0,
        totalMinutesTue: 0,
        totalMinutesWed: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const timesheetEntryCreateReducer = (
  state = {
    loading: false,
    timesheet_entry: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_ENTRY_CREATE_REQUEST:
      return {
        loading: true,
        timesheet_entry: state.timesheet_entry,
        success: false,
        error: "",
      };

    case TIMESHEET_ENTRY_CREATE_SUCCESS:
      return {
        loading: false,
        timesheet_entry: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_ENTRY_CREATE_FAIL:
      return {
        loading: false,
        timesheet_entry: state.timesheet_entry,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_ENTRY_CREATE_RESET:
      return {
        loading: false,
        timesheet_entry: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const timesheetEntryDetailsReducer = (
  state = {
    loading: false,
    timesheet_entry: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_ENTRY_DETAILS_REQUEST:
      return {
        loading: true,
        timesheet_entry: state.timesheet_entry,
        success: false,
        error: "",
      };

    case TIMESHEET_ENTRY_DETAILS_SUCCESS:
      return {
        loading: false,
        timesheet_entry: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_ENTRY_DETAILS_FAIL:
      return {
        loading: false,
        timesheet_entry: state.timesheet_entry,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_ENTRY_DETAILS_RESET:
      return {
        loading: false,
        timesheet_entry: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const timesheetEntryUpdateReducer = (
  state = {
    loading: false,
    timesheet_entry: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_ENTRY_UPDATE_REQUEST:
      return {
        loading: true,
        timesheet_entry: state.timesheet_entry,
        success: false,
        error: "",
      };

    case TIMESHEET_ENTRY_UPDATE_SUCCESS:
      return {
        loading: false,
        timesheet_entry: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_ENTRY_UPDATE_FAIL:
      return {
        loading: false,
        timesheet_entry: state.timesheet_entry,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_ENTRY_UPDATE_RESET:
      return {
        loading: false,
        timesheet_entry: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const timesheetEntryDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TIMESHEET_ENTRY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case TIMESHEET_ENTRY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case TIMESHEET_ENTRY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_ENTRY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
