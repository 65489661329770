// Library imports
import { Card, Classes, Colors, H3 } from "@blueprintjs/core";
import React from "react";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Main function
function DataCardSimple({
  intent,
  interactive,
  label,
  loading,
  onClick,
  value,
}) {
  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        alignItems: "center",
        borderLeft: `5px solid ${
          intent === "success"
            ? Colors.GREEN3
            : intent === "warning"
            ? Colors.ORANGE3
            : intent === "danger"
            ? Colors.RED3
            : intent === "primary"
            ? Colors.BLUE3
            : Colors.GRAY3
        }`,
        display: "flex",
        height: "60px",
        margin: "5px",
        minWidth: "fit-content",
        padding: "0 10px 0 10px",
        textAlign: "start",
        width: "125px",
      }}
    >
      <div>
        <H3
          className={loading ? Classes.SKELETON : undefined}
          style={{ margin: "5px 0 0 0" }}
        >
          {loading ? "XXXXX" : value ? numberFormatter(value) : "-"}
        </H3>

        <div className={Classes.TEXT_SMALL} style={{ margin: "3px 0 5px 0" }}>
          {label}
        </div>
      </div>
    </Card>
  );
}

export default DataCardSimple;
