export const INVOICE_LIST_REQUEST = "INVOICE_LIST_REQUEST";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";
export const INVOICE_LIST_FAIL = "INVOICE_LIST_FAIL";
export const INVOICE_LIST_RESET = "INVOICE_LIST_RESET";

export const INVOICE_CREATE_REQUEST = "INVOICE_CREATE_REQUEST";
export const INVOICE_CREATE_SUCCESS = "INVOICE_CREATE_SUCCESS";
export const INVOICE_CREATE_FAIL = "INVOICE_CREATE_FAIL";
export const INVOICE_CREATE_RESET = "INVOICE_CREATE_RESET";

export const INVOICE_DETAILS_REQUEST = "INVOICE_DETAILS_REQUEST";
export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";
export const INVOICE_DETAILS_FAIL = "INVOICE_DETAILS_FAIL";
export const INVOICE_DETAILS_RESET = "INVOICE_DETAILS_RESET";

export const INVOICE_UPDATE_REQUEST = "INVOICE_UPDATE_REQUEST";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
export const INVOICE_UPDATE_FAIL = "INVOICE_UPDATE_FAIL";
export const INVOICE_UPDATE_RESET = "INVOICE_UPDATE_RESET";

export const INVOICE_DELETE_REQUEST = "INVOICE_DELETE_REQUEST";
export const INVOICE_DELETE_SUCCESS = "INVOICE_DELETE_SUCCESS";
export const INVOICE_DELETE_FAIL = "INVOICE_DELETE_FAIL";
export const INVOICE_DELETE_RESET = "INVOICE_DELETE_RESET";

export const INVOICE_DOWNLOAD_REQUEST = "INVOICE_DOWNLOAD_REQUEST";
export const INVOICE_DOWNLOAD_SUCCESS = "INVOICE_DOWNLOAD_SUCCESS";
export const INVOICE_DOWNLOAD_FAIL = "INVOICE_DOWNLOAD_FAIL";
export const INVOICE_DOWNLOAD_RESET = "INVOICE_DOWNLOAD_RESET";
