// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_LIST_RESET,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAIL,
  CONTACT_CREATE_RESET,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_RESET,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  CONTACT_UPDATE_RESET,
  CONTACT_DELETE_REQUEST,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_FAIL,
  CONTACT_DELETE_RESET,
  CONTACT_REFERRER_LIST_REQUEST,
  CONTACT_REFERRER_LIST_SUCCESS,
  CONTACT_REFERRER_LIST_FAIL,
  CONTACT_REFERRER_LIST_RESET,
  CONTACT_DOWNLOAD_REQUEST,
  CONTACT_DOWNLOAD_SUCCESS,
  CONTACT_DOWNLOAD_FAIL,
  CONTACT_DOWNLOAD_RESET,
  CONTACT_UPLOAD_BEGIN,
  CONTACT_UPLOAD_REQUEST,
  CONTACT_UPLOAD_SUCCESS,
  CONTACT_UPLOAD_FAIL,
  CONTACT_UPLOAD_END,
  CONTACT_UPLOAD_RESET,
  CONTACT_CREATE_COMMUNICATION_ACTION_REQUEST,
  CONTACT_CREATE_COMMUNICATION_ACTION_SUCCESS,
  CONTACT_CREATE_COMMUNICATION_ACTION_FAIL,
  CONTACT_CREATE_COMMUNICATION_ACTION_RESET,
} from "../constants/contactConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listContactsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getContacts(params, token);

    dispatch({
      type: CONTACT_LIST_SUCCESS,

      payload: {
        numberFinance: data.number_finance,
        numberGeneralManagement: data.number_general_management,
        numberHumanResources: data.number_human_resources,
        numberInformationTechnology: data.number_information_technology,
        numberMarketingSales: data.number_marketing_sales,
        numberMiddleManagement: data.number_middle_management,
        numberLowerManagement: data.number_lower_management,
        numberOperations: data.number_operations,
        numberOther: data.number_other,
        numberUpperManagement: data.number_upper_management,
        totalValueOfProposalsCancelled:
          data.total_value_of_proposals_cancelled.result,
        totalValueOfProposalsInProgress:
          data.total_value_of_proposals_in_progress.result,
        totalValueOfProposalsLost: data.total_value_of_proposals_lost.result,
        totalValueOfProposalsQualifying:
          data.total_value_of_proposals_qualifying.result,
        totalValueOfProposalsSubmitted:
          data.total_value_of_proposals_submitted.result,
        totalValueOfProposalsWon: data.total_value_of_proposals_won.result,
        count: data.count,
        contacts: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: CONTACT_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListContactsAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_LIST_RESET });
};

// Create
export const createContactAction = (contact) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createContact(token, contact);

    dispatch({
      type: CONTACT_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateContactAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_CREATE_RESET });
};

// List details
export const listContactDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getContact(id, token);

    dispatch({
      type: CONTACT_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListContactDetailsAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_DETAILS_RESET });
};

// Update
export const updateContactAction =
  (id, client, contact) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          contact.append("client", data.id);
        }
      }

      const { data } = await cortixDataService.updateContact(
        id,
        token,
        contact
      );

      dispatch({
        type: CONTACT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateContactAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_UPDATE_RESET });
};

// Delete
export const deleteContactAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteContact(id, token);

    dispatch({
      type: CONTACT_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteContactAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_DELETE_RESET });
};

// List referrers
export const listContactReferrersAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_REFERRER_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getContacts(params, token);

      dispatch({
        type: CONTACT_REFERRER_LIST_SUCCESS,

        payload: { count: data.count, contacts: data.results },
      });
    } catch (error) {
      dispatch({
        type: CONTACT_REFERRER_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list referrers
export const resetListContactReferrersAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_REFERRER_LIST_RESET });
};

// Download
export const downloadContactsAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var contacts = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getContacts(
          { page: page, organisation: organisation },
          token
        );

        contacts = contacts.concat(data.results);
      }

      dispatch({
        type: CONTACT_DOWNLOAD_SUCCESS,

        payload: {
          contacts: contacts,
        },
      });
    } catch (error) {
      dispatch({
        type: CONTACT_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadContactsAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_DOWNLOAD_RESET });
};

// Upload
export const uploadContactsAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: CONTACT_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: CONTACT_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteContact(records[recordNum].id, token);
        } else if (records[recordNum].id) {
          await cortixDataService.updateContact(records[recordNum].id, token, {
            email: records[recordNum].email,
            linkedin_page: records[recordNum].linkedin_page,
            name: records[recordNum].name,
            notes: records[recordNum].notes,
            organisation: id,
            phone: records[recordNum].phone,
            role: records[recordNum].role,
          });
        } else {
          await cortixDataService.createContact(token, {
            email: records[recordNum].email,
            linkedin_page: records[recordNum].linkedin_page,
            name: records[recordNum].name,
            notes: records[recordNum].notes,
            organisation: id,
            phone: records[recordNum].phone,
            role: records[recordNum].role,
          });
        }

        dispatch({
          type: CONTACT_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: CONTACT_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: CONTACT_UPLOAD_END });
  };

// Reset upload
export const resetUploadContactsAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_UPLOAD_RESET });
};

// Create communication action
export const createCommunicationAction =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_CREATE_COMMUNICATION_ACTION_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createCommunicationAction(
        id,
        token,
        params
      );

      dispatch({
        type: CONTACT_CREATE_COMMUNICATION_ACTION_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_CREATE_COMMUNICATION_ACTION_FAIL,

        payload: error,
      });
    }
  };

// Reset create communication action
export const resetCreateCommunicationAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_CREATE_COMMUNICATION_ACTION_RESET });
};
