export const INVOICE_OTHER_LIST_REQUEST = "INVOICE_OTHER_LIST_REQUEST";
export const INVOICE_OTHER_LIST_SUCCESS = "INVOICE_OTHER_LIST_SUCCESS";
export const INVOICE_OTHER_LIST_FAIL = "INVOICE_OTHER_LIST_FAIL";
export const INVOICE_OTHER_LIST_RESET = "INVOICE_OTHER_LIST_RESET";

export const INVOICE_OTHER_CREATE_REQUEST = "INVOICE_OTHER_CREATE_REQUEST";
export const INVOICE_OTHER_CREATE_SUCCESS = "INVOICE_OTHER_CREATE_SUCCESS";
export const INVOICE_OTHER_CREATE_FAIL = "INVOICE_OTHER_CREATE_FAIL";
export const INVOICE_OTHER_CREATE_RESET = "INVOICE_OTHER_CREATE_RESET";

export const INVOICE_OTHER_DETAILS_REQUEST = "INVOICE_OTHER_DETAILS_REQUEST";
export const INVOICE_OTHER_DETAILS_SUCCESS = "INVOICE_OTHER_DETAILS_SUCCESS";
export const INVOICE_OTHER_DETAILS_FAIL = "INVOICE_OTHER_DETAILS_FAIL";
export const INVOICE_OTHER_DETAILS_RESET = "INVOICE_OTHER_DETAILS_RESET";

export const INVOICE_OTHER_UPDATE_REQUEST = "INVOICE_OTHER_UPDATE_REQUEST";
export const INVOICE_OTHER_UPDATE_SUCCESS = "INVOICE_OTHER_UPDATE_SUCCESS";
export const INVOICE_OTHER_UPDATE_FAIL = "INVOICE_OTHER_UPDATE_FAIL";
export const INVOICE_OTHER_UPDATE_RESET = "INVOICE_OTHER_UPDATE_RESET";

export const INVOICE_OTHER_DELETE_REQUEST = "INVOICE_OTHER_DELETE_REQUEST";
export const INVOICE_OTHER_DELETE_SUCCESS = "INVOICE_OTHER_DELETE_SUCCESS";
export const INVOICE_OTHER_DELETE_FAIL = "INVOICE_OTHER_DELETE_FAIL";
export const INVOICE_OTHER_DELETE_RESET = "INVOICE_OTHER_DELETE_RESET";
