// Constant imports
import {
  INTERVIEW_LIST_REQUEST,
  INTERVIEW_LIST_SUCCESS,
  INTERVIEW_LIST_FAIL,
  INTERVIEW_LIST_RESET,
  INTERVIEW_CREATE_REQUEST,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_CREATE_FAIL,
  INTERVIEW_CREATE_RESET,
  INTERVIEW_DETAILS_REQUEST,
  INTERVIEW_DETAILS_SUCCESS,
  INTERVIEW_DETAILS_FAIL,
  INTERVIEW_DETAILS_RESET,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_FAIL,
  INTERVIEW_UPDATE_RESET,
  INTERVIEW_DELETE_REQUEST,
  INTERVIEW_DELETE_SUCCESS,
  INTERVIEW_DELETE_FAIL,
  INTERVIEW_DELETE_RESET,
} from "../constants/interviewConstants";

// List all
export const interviewListReducer = (
  state = {
    loading: false,
    count: 0,
    interviews: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INTERVIEW_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INTERVIEW_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        interviews: action.payload.interviews,
        success: true,
        error: "",
      };

    case INTERVIEW_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        interviews: [],
        success: false,
        error: action.payload,
      };

    case INTERVIEW_LIST_RESET:
      return {
        loading: false,
        count: 0,
        interviews: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const interviewCreateReducer = (
  state = { loading: false, interview: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INTERVIEW_CREATE_REQUEST:
      return {
        loading: true,
        interview: state.interview,
        success: false,
        error: "",
      };

    case INTERVIEW_CREATE_SUCCESS:
      return {
        loading: false,
        interview: action.payload,
        success: true,
        error: "",
      };

    case INTERVIEW_CREATE_FAIL:
      return {
        loading: false,
        interview: state.interview,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_CREATE_RESET:
      return { loading: false, interview: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const interviewDetailsReducer = (
  state = { loading: false, interview: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INTERVIEW_DETAILS_REQUEST:
      return {
        loading: true,
        interview: state.interview,
        success: false,
        error: "",
      };

    case INTERVIEW_DETAILS_SUCCESS:
      return {
        loading: false,
        interview: action.payload,
        success: true,
        error: "",
      };

    case INTERVIEW_DETAILS_FAIL:
      return {
        loading: false,
        interview: state.interview,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_DETAILS_RESET:
      return { loading: false, interview: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const interviewUpdateReducer = (
  state = { loading: false, interview: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INTERVIEW_UPDATE_REQUEST:
      return {
        loading: true,
        interview: state.interview,
        success: false,
        error: "",
      };

    case INTERVIEW_UPDATE_SUCCESS:
      return {
        loading: false,
        interview: action.payload,
        success: true,
        error: "",
      };

    case INTERVIEW_UPDATE_FAIL:
      return {
        loading: false,
        interview: state.interview,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_UPDATE_RESET:
      return { loading: false, interview: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const interviewDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INTERVIEW_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INTERVIEW_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INTERVIEW_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
