export const INVOICE_BOOKING_LIST_REQUEST = "INVOICE_BOOKING_LIST_REQUEST";
export const INVOICE_BOOKING_LIST_SUCCESS = "INVOICE_BOOKING_LIST_SUCCESS";
export const INVOICE_BOOKING_LIST_FAIL = "INVOICE_BOOKING_LIST_FAIL";
export const INVOICE_BOOKING_LIST_RESET = "INVOICE_BOOKING_LIST_RESET";

export const INVOICE_BOOKING_DETAILS_REQUEST =
  "INVOICE_BOOKING_DETAILS_REQUEST";
export const INVOICE_BOOKING_DETAILS_SUCCESS =
  "INVOICE_BOOKING_DETAILS_SUCCESS";
export const INVOICE_BOOKING_DETAILS_FAIL = "INVOICE_BOOKING_DETAILS_FAIL";
export const INVOICE_BOOKING_DETAILS_RESET = "INVOICE_BOOKING_DETAILS_RESET";

export const INVOICE_BOOKING_UPDATE_REQUEST = "INVOICE_BOOKING_UPDATE_REQUEST";
export const INVOICE_BOOKING_UPDATE_SUCCESS = "INVOICE_BOOKING_UPDATE_SUCCESS";
export const INVOICE_BOOKING_UPDATE_FAIL = "INVOICE_BOOKING_UPDATE_FAIL";
export const INVOICE_BOOKING_UPDATE_RESET = "INVOICE_BOOKING_UPDATE_RESET";

export const INVOICE_BOOKING_DELETE_REQUEST = "INVOICE_BOOKING_DELETE_REQUEST";
export const INVOICE_BOOKING_DELETE_SUCCESS = "INVOICE_BOOKING_DELETE_SUCCESS";
export const INVOICE_BOOKING_DELETE_FAIL = "INVOICE_BOOKING_DELETE_FAIL";
export const INVOICE_BOOKING_DELETE_RESET = "INVOICE_BOOKING_DELETE_RESET";
