// Generate image html
const generateImageHtml = (image) => {
  const { data, left, name, ratio, width } = image;

  if (data && left !== null && name && ratio !== null && width !== null) {
    const aspectRatio = (16 + ratio) / 9;
    const widthMm = ((210 - 40) * width) / 100;
    const heightMm = widthMm / aspectRatio;

    return `
        <div style="margin: 30px 0 30px 0; overflow: hidden; text-align: center;">
            <img
                alt="${name}"
                src="${data}"
                style="height: ${heightMm}mm; margin: 0 0 0 ${left}%; object-fit: cover; width: ${width}%;"
            />
        </div>
      `;
  } else {
    return "";
  }
};

// Main function
const generateHtmlDocument = (document) => {
  const {
    about,
    columns,
    conclusion,
    coverImage,
    font,
    fontSize,
    footer,
    introduction,
    primaryColour,
    references,
    secondaryColour,
    sections,
    title,
  } = document;

  // About
  const aboutHtml = about
    ? `
    <div style="page-break-inside: avoid;">
      <p><strong>About the author(s)</strong></p>

      <p>${about}</p>
    </div>
  `
    : "";

  // Cover
  const coverImageHtml = coverImage
    ? `<div style="margin: -30px 0 0 0;">
      ${generateImageHtml({
        data: coverImage,
        left: 0,
        name: title,
        ratio: 0,
        width: 100,
      })}
    </div>`
    : "";

  // About
  const referencesHtml = references
    ? `
  <div style="page-break-inside: avoid;">
    <p><strong>References</strong></p>

    <p>${references}</p>
  </div>
`
    : "";

  // Sections
  const sectionsHtml = sections
    .map((section, i) => {
      const imagePosition = section.image
        ? section.image.position
          ? section.image.position
          : undefined
        : undefined;

      const imageHtml = imagePosition ? generateImageHtml(section.image) : "";

      const imageHtmlLabel =
        imageHtml && section.image.label
          ? `<div style="color: grey; font-size: small; margin: -20px 0 30px 0; text-align:center;">${section.image.label}</div>`
          : "";

      return `
      <div>
        <div style="page-break-inside: avoid;">
          <h2>${section.title}</h2>

          ${imagePosition === "top" ? imageHtml : ""}

          ${imageHtmlLabel && imagePosition === "top" ? imageHtmlLabel : ""}
        </div>

        <div class="section-content">${
          section.content ? section.content : ""
        }</div>

        ${imagePosition === "bottom" ? imageHtml : ""}

        ${imageHtmlLabel && imagePosition === "bottom" ? imageHtmlLabel : ""}
      </div>
    `;
    })
    .join("");

  // HTML
  const html = `
      <html>
        <head>
          <meta charset="utf-8" />
          <meta content="${title}" name="description" />

          <title>${title}</title>

          <link href="https://fonts.googleapis.com" rel="preconnect">
          <link crossorigin href="https://fonts.gstatic.com" rel="preconnect" >
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">

          <style>
            @page {
              margin: 20mm;
              size: A4 portrait;

              @bottom-left {
                color: grey;
                content: "${footer}";
                font-family: ${font};
                font-size: 10px;
              }

              @bottom-right {
                color: grey;
                content: counter(page);
                font-family: ${font};
                font-size: 10px;
              }
            }
              
            body {
              background: white;
              font-family: ${font};
              font-size: ${fontSize.toLowerCase()};
              margin: 0 auto 0 auto;
            }
            
            h1 {
              color: ${primaryColour};
            }

            h2 {
              color: ${secondaryColour};
            }

            hr {
              color: #C5CBD3;
              margin: 30px 0 30px 0;
            }

            p {
              white-space: pre-wrap;
            }

            .conclusion {
              white-space: pre-wrap;
            }

            .introduction {
              font-weight: bold;
              white-space: pre-wrap;
            }

            .section-content {
              column-count: ${columns ? "2" : "1"};
              column-gap: 10mm;
              white-space: pre-wrap;
            }
          </style>
        </head>

        <body>
          ${coverImageHtml}

          <h1>${title}</h1>

          <div class="introduction">${introduction}</div>

          ${introduction && "<hr />"}

          ${sectionsHtml}

          ${conclusion && "<hr />"}

          <div class="conclusion">${conclusion}</div>

          ${(aboutHtml || referencesHtml) && "<hr />"}

          ${aboutHtml}

          ${referencesHtml}
        </body>
      </html>
    `;

  return html;
};

export default generateHtmlDocument;
