export const PROPOSAL_REQUIREMENT_LIST_REQUEST =
  "PROPOSAL_REQUIREMENT_LIST_REQUEST";
export const PROPOSAL_REQUIREMENT_LIST_SUCCESS =
  "PROPOSAL_REQUIREMENT_LIST_SUCCESS";
export const PROPOSAL_REQUIREMENT_LIST_FAIL = "PROPOSAL_REQUIREMENT_LIST_FAIL";
export const PROPOSAL_REQUIREMENT_LIST_RESET =
  "PROPOSAL_REQUIREMENT_LIST_RESET";

export const PROPOSAL_REQUIREMENT_CREATE_REQUEST =
  "PROPOSAL_REQUIREMENT_CREATE_REQUEST";
export const PROPOSAL_REQUIREMENT_CREATE_SUCCESS =
  "PROPOSAL_REQUIREMENT_CREATE_SUCCESS";
export const PROPOSAL_REQUIREMENT_CREATE_FAIL =
  "PROPOSAL_REQUIREMENT_CREATE_FAIL";
export const PROPOSAL_REQUIREMENT_CREATE_RESET =
  "PROPOSAL_REQUIREMENT_CREATE_RESET";

export const PROPOSAL_REQUIREMENT_DETAILS_REQUEST =
  "PROPOSAL_REQUIREMENT_DETAILS_REQUEST";
export const PROPOSAL_REQUIREMENT_DETAILS_SUCCESS =
  "PROPOSAL_REQUIREMENT_DETAILS_SUCCESS";
export const PROPOSAL_REQUIREMENT_DETAILS_FAIL =
  "PROPOSAL_REQUIREMENT_DETAILS_FAIL";
export const PROPOSAL_REQUIREMENT_DETAILS_RESET =
  "PROPOSAL_REQUIREMENT_DETAILS_RESET";

export const PROPOSAL_REQUIREMENT_UPDATE_REQUEST =
  "PROPOSAL_REQUIREMENT_UPDATE_REQUEST";
export const PROPOSAL_REQUIREMENT_UPDATE_SUCCESS =
  "PROPOSAL_REQUIREMENT_UPDATE_SUCCESS";
export const PROPOSAL_REQUIREMENT_UPDATE_FAIL =
  "PROPOSAL_REQUIREMENT_UPDATE_FAIL";
export const PROPOSAL_REQUIREMENT_UPDATE_RESET =
  "PROPOSAL_REQUIREMENT_UPDATE_RESET";

export const PROPOSAL_REQUIREMENT_DELETE_REQUEST =
  "PROPOSAL_REQUIREMENT_DELETE_REQUEST";
export const PROPOSAL_REQUIREMENT_DELETE_SUCCESS =
  "PROPOSAL_REQUIREMENT_DELETE_SUCCESS";
export const PROPOSAL_REQUIREMENT_DELETE_FAIL =
  "PROPOSAL_REQUIREMENT_DELETE_FAIL";
export const PROPOSAL_REQUIREMENT_DELETE_RESET =
  "PROPOSAL_REQUIREMENT_DELETE_RESET";

export const PROPOSAL_REQUIREMENT_DOWNLOAD_REQUEST =
  "PROPOSAL_REQUIREMENT_DOWNLOAD_REQUEST";
export const PROPOSAL_REQUIREMENT_DOWNLOAD_SUCCESS =
  "PROPOSAL_REQUIREMENT_DOWNLOAD_SUCCESS";
export const PROPOSAL_REQUIREMENT_DOWNLOAD_FAIL =
  "PROPOSAL_REQUIREMENT_DOWNLOAD_FAIL";
export const PROPOSAL_REQUIREMENT_DOWNLOAD_RESET =
  "PROPOSAL_REQUIREMENT_DOWNLOAD_RESET";

export const PROPOSAL_REQUIREMENT_UPLOAD_BEGIN =
  "PROPOSAL_REQUIREMENT_UPLOAD_BEGIN";
export const PROPOSAL_REQUIREMENT_UPLOAD_REQUEST =
  "PROPOSAL_REQUIREMENT_UPLOAD_REQUEST";
export const PROPOSAL_REQUIREMENT_UPLOAD_SUCCESS =
  "PROPOSAL_REQUIREMENT_UPLOAD_SUCCESS";
export const PROPOSAL_REQUIREMENT_UPLOAD_FAIL =
  "PROPOSAL_REQUIREMENT_UPLOAD_FAIL";
export const PROPOSAL_REQUIREMENT_UPLOAD_END =
  "PROPOSAL_REQUIREMENT_UPLOAD_END";
export const PROPOSAL_REQUIREMENT_UPLOAD_RESET =
  "PROPOSAL_REQUIREMENT_UPLOAD_RESET";
