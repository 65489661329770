// Constant imports
import {
  INVOICE_PERMISSION_LIST_REQUEST,
  INVOICE_PERMISSION_LIST_SUCCESS,
  INVOICE_PERMISSION_LIST_FAIL,
  INVOICE_PERMISSION_LIST_RESET,
  INVOICE_PERMISSION_CREATE_REQUEST,
  INVOICE_PERMISSION_CREATE_SUCCESS,
  INVOICE_PERMISSION_CREATE_FAIL,
  INVOICE_PERMISSION_CREATE_RESET,
  INVOICE_PERMISSION_DETAILS_REQUEST,
  INVOICE_PERMISSION_DETAILS_SUCCESS,
  INVOICE_PERMISSION_DETAILS_FAIL,
  INVOICE_PERMISSION_DETAILS_RESET,
  INVOICE_PERMISSION_UPDATE_REQUEST,
  INVOICE_PERMISSION_UPDATE_SUCCESS,
  INVOICE_PERMISSION_UPDATE_FAIL,
  INVOICE_PERMISSION_UPDATE_RESET,
  INVOICE_PERMISSION_DELETE_REQUEST,
  INVOICE_PERMISSION_DELETE_SUCCESS,
  INVOICE_PERMISSION_DELETE_FAIL,
  INVOICE_PERMISSION_DELETE_RESET,
} from "../constants/invoicePermissionConstants";

// List all
export const invoicePermissionListReducer = (
  state = {
    loading: false,
    count: 0,
    invoice_permissions: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_PERMISSION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoice_permissions: action.payload.invoice_permissions,
        success: true,
        error: "",
      };

    case INVOICE_PERMISSION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoice_permissions: [],
        success: false,
        error: action.payload,
      };

    case INVOICE_PERMISSION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoice_permissions: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const invoicePermissionCreateReducer = (
  state = {
    loading: false,
    invoice_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_PERMISSION_CREATE_REQUEST:
      return {
        loading: true,
        invoice_permission: state.invoice_permission,
        success: false,
        error: "",
      };

    case INVOICE_PERMISSION_CREATE_SUCCESS:
      return {
        loading: false,
        invoice_permission: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_PERMISSION_CREATE_FAIL:
      return {
        loading: false,
        invoice_permission: state.invoice_permission,
        success: false,
        error: action.payload,
      };

    case INVOICE_PERMISSION_CREATE_RESET:
      return {
        loading: false,
        invoice_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const invoicePermissionDetailsReducer = (
  state = {
    loading: false,
    invoice_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_PERMISSION_DETAILS_REQUEST:
      return {
        loading: true,
        invoice_permission: state.invoice_permission,
        success: false,
        error: "",
      };

    case INVOICE_PERMISSION_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice_permission: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_PERMISSION_DETAILS_FAIL:
      return {
        loading: false,
        invoice_permission: state.invoice_permission,
        success: false,
        error: action.payload,
      };

    case INVOICE_PERMISSION_DETAILS_RESET:
      return {
        loading: false,
        invoice_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const invoicePermissionUpdateReducer = (
  state = {
    loading: false,
    invoice_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_PERMISSION_UPDATE_REQUEST:
      return {
        loading: true,
        invoice_permission: state.invoice_permission,
        success: false,
        error: "",
      };

    case INVOICE_PERMISSION_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice_permission: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_PERMISSION_UPDATE_FAIL:
      return {
        loading: false,
        invoice_permission: state.invoice_permission,
        success: false,
        error: action.payload,
      };

    case INVOICE_PERMISSION_UPDATE_RESET:
      return {
        loading: false,
        invoice_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const invoicePermissionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_PERMISSION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INVOICE_PERMISSION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INVOICE_PERMISSION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INVOICE_PERMISSION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
