// Constant imports
import {
  STAFF_GOAL_LIST_REQUEST,
  STAFF_GOAL_LIST_SUCCESS,
  STAFF_GOAL_LIST_FAIL,
  STAFF_GOAL_LIST_RESET,
  STAFF_GOAL_CREATE_REQUEST,
  STAFF_GOAL_CREATE_SUCCESS,
  STAFF_GOAL_CREATE_FAIL,
  STAFF_GOAL_CREATE_RESET,
  STAFF_GOAL_DETAILS_REQUEST,
  STAFF_GOAL_DETAILS_SUCCESS,
  STAFF_GOAL_DETAILS_FAIL,
  STAFF_GOAL_DETAILS_RESET,
  STAFF_GOAL_UPDATE_REQUEST,
  STAFF_GOAL_UPDATE_SUCCESS,
  STAFF_GOAL_UPDATE_FAIL,
  STAFF_GOAL_UPDATE_RESET,
  STAFF_GOAL_DELETE_REQUEST,
  STAFF_GOAL_DELETE_SUCCESS,
  STAFF_GOAL_DELETE_FAIL,
  STAFF_GOAL_DELETE_RESET,
} from "../constants/staffGoalConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffGoalsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_GOAL_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getStaffGoals(params, token);

    dispatch({
      type: STAFF_GOAL_LIST_SUCCESS,

      payload: {
        count: data.count,
        numberOfGoalsExceeded: data.number_of_goals_exceeded,
        numberOfGoalsMet: data.number_of_goals_met,
        numberOfGoalsNotMet: data.number_of_goals_not_met,
        numberOfGoalsNotRated: data.number_of_goals_not_rated,
        staff_goals: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: STAFF_GOAL_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListStaffGoalsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_GOAL_LIST_RESET });
};

// Create
export const createStaffGoalAction =
  (staff_goal) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_GOAL_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createStaffGoal(
        token,
        staff_goal
      );

      dispatch({
        type: STAFF_GOAL_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_GOAL_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateStaffGoalAction = () => async (dispatch) => {
  dispatch({ type: STAFF_GOAL_CREATE_RESET });
};

// List details
export const listStaffGoalDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_GOAL_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffGoal(id, token);

      dispatch({
        type: STAFF_GOAL_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_GOAL_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListStaffGoalDetailsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_GOAL_DETAILS_RESET });
};

// Update
export const updateStaffGoalAction =
  (id, staff_goal) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_GOAL_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateStaffGoal(
        id,
        token,
        staff_goal
      );

      dispatch({
        type: STAFF_GOAL_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_GOAL_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateStaffGoalAction = () => async (dispatch) => {
  dispatch({ type: STAFF_GOAL_UPDATE_RESET });
};

// Delete
export const deleteStaffGoalAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_GOAL_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteStaffGoal(id, token);

    dispatch({
      type: STAFF_GOAL_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STAFF_GOAL_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteStaffGoalAction = () => async (dispatch) => {
  dispatch({ type: STAFF_GOAL_DELETE_RESET });
};
