// Constant imports
import {
  ENGAGEMENT_RATE_LIST_REQUEST,
  ENGAGEMENT_RATE_LIST_SUCCESS,
  ENGAGEMENT_RATE_LIST_FAIL,
  ENGAGEMENT_RATE_LIST_RESET,
  ENGAGEMENT_RATE_CREATE_REQUEST,
  ENGAGEMENT_RATE_CREATE_SUCCESS,
  ENGAGEMENT_RATE_CREATE_FAIL,
  ENGAGEMENT_RATE_CREATE_RESET,
  ENGAGEMENT_RATE_DETAILS_REQUEST,
  ENGAGEMENT_RATE_DETAILS_SUCCESS,
  ENGAGEMENT_RATE_DETAILS_FAIL,
  ENGAGEMENT_RATE_DETAILS_RESET,
  ENGAGEMENT_RATE_UPDATE_REQUEST,
  ENGAGEMENT_RATE_UPDATE_SUCCESS,
  ENGAGEMENT_RATE_UPDATE_FAIL,
  ENGAGEMENT_RATE_UPDATE_RESET,
  ENGAGEMENT_RATE_DELETE_REQUEST,
  ENGAGEMENT_RATE_DELETE_SUCCESS,
  ENGAGEMENT_RATE_DELETE_FAIL,
  ENGAGEMENT_RATE_DELETE_RESET,
} from "../constants/engagementRateConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementRatesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RATE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementRates(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_RATE_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_rates: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RATE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementRatesAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RATE_LIST_RESET });
};

// Create
export const createEngagementRateAction =
  (engagement_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RATE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementRate(
        token,
        engagement_rate
      );

      dispatch({
        type: ENGAGEMENT_RATE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RATE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementRateAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RATE_CREATE_RESET });
};

// List details
export const listEngagementRateDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RATE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementRate(id, token);

      dispatch({
        type: ENGAGEMENT_RATE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RATE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementRateDetailsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RATE_DETAILS_RESET });
};

// Update
export const updateEngagementRateAction =
  (id, engagement_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RATE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementRate(
        id,
        token,
        engagement_rate
      );

      dispatch({
        type: ENGAGEMENT_RATE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RATE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementRateAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RATE_UPDATE_RESET });
};

// Delete
export const deleteEngagementRateAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RATE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementRate(id, token);

      dispatch({
        type: ENGAGEMENT_RATE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RATE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementRateAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RATE_DELETE_RESET });
};
