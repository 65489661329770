// Library imports
import { Button, Classes, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";

// Main function
function TableColumnHeadSelect2({
  filterObject,
  filterVariable,
  items,
  itemsNameLabel,
  setFilterObject,
  setQueryObject,
  setQueryVariable,
  showLabel,
  title,
  width,
}) {
  // Add all option to items
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const allOption = {
      id: "",
    };

    setMenuItems([allOption, ...items]);
  }, [items]);

  // JSX UI code
  return (
    <th style={{ padding: "5px", width: width }}>
      <Select
        fill
        itemRenderer={(item, { handleClick, handleFocus, modifiers }) => {
          return (
            <MenuItem
              key={item.id}
              label={item.id && showLabel ? `[${item.id}]` : undefined}
              onClick={handleClick}
              onFocus={handleFocus}
              selected={modifiers.active}
              text={
                itemsNameLabel
                  ? item[itemsNameLabel]
                    ? item[itemsNameLabel]
                    : "-- All --"
                  : item.name
                  ? item.name
                  : "-- All --"
              }
            />
          );
        }}
        items={menuItems}
        onItemSelect={(item) => {
          setFilterObject((prevState) => {
            return { ...prevState, page: 1, [filterVariable]: item.id };
          });
        }}
        onQueryChange={(query) => {
          setQueryObject((prevState) => {
            return {
              ...prevState,
              page: 1,
              [setQueryVariable]: query,
            };
          });
        }}
      >
        <Button
          active={filterObject[filterVariable] ? true : false}
          alignText="left"
          fill
          intent={filterObject[filterVariable] ? "primary" : undefined}
          minimal
          text={
            <span className={Classes.TEXT_SMALL}>
              <strong>{title}</strong>
            </span>
          }
          rightIcon="caret-down"
        />
      </Select>
    </th>
  );
}

export default TableColumnHeadSelect2;
