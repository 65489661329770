// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  EXPENSE_CATEGORY_LIST_REQUEST,
  EXPENSE_CATEGORY_LIST_SUCCESS,
  EXPENSE_CATEGORY_LIST_FAIL,
  EXPENSE_CATEGORY_LIST_RESET,
  EXPENSE_CATEGORY_CREATE_REQUEST,
  EXPENSE_CATEGORY_CREATE_SUCCESS,
  EXPENSE_CATEGORY_CREATE_FAIL,
  EXPENSE_CATEGORY_CREATE_RESET,
  EXPENSE_CATEGORY_DETAILS_REQUEST,
  EXPENSE_CATEGORY_DETAILS_SUCCESS,
  EXPENSE_CATEGORY_DETAILS_FAIL,
  EXPENSE_CATEGORY_DETAILS_RESET,
  EXPENSE_CATEGORY_UPDATE_REQUEST,
  EXPENSE_CATEGORY_UPDATE_SUCCESS,
  EXPENSE_CATEGORY_UPDATE_FAIL,
  EXPENSE_CATEGORY_UPDATE_RESET,
  EXPENSE_CATEGORY_DELETE_REQUEST,
  EXPENSE_CATEGORY_DELETE_SUCCESS,
  EXPENSE_CATEGORY_DELETE_FAIL,
  EXPENSE_CATEGORY_DELETE_RESET,
  EXPENSE_CATEGORY_DOWNLOAD_REQUEST,
  EXPENSE_CATEGORY_DOWNLOAD_SUCCESS,
  EXPENSE_CATEGORY_DOWNLOAD_FAIL,
  EXPENSE_CATEGORY_DOWNLOAD_RESET,
  EXPENSE_CATEGORY_UPLOAD_BEGIN,
  EXPENSE_CATEGORY_UPLOAD_REQUEST,
  EXPENSE_CATEGORY_UPLOAD_SUCCESS,
  EXPENSE_CATEGORY_UPLOAD_FAIL,
  EXPENSE_CATEGORY_UPLOAD_END,
  EXPENSE_CATEGORY_UPLOAD_RESET,
} from "../constants/expenseCategoryConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listExpenseCategoriesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: EXPENSE_CATEGORY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getExpenseCategories(
        params,
        token
      );

      dispatch({
        type: EXPENSE_CATEGORY_LIST_SUCCESS,

        payload: {
          count: data.count,
          expense_categories: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: EXPENSE_CATEGORY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListExpenseCategoriesAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_LIST_RESET });
};

// Create
export const createExpenseCategoryAction =
  (expenseCategory) => async (dispatch, getState) => {
    try {
      dispatch({ type: EXPENSE_CATEGORY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createExpenseCategory(
        token,
        expenseCategory
      );

      dispatch({
        type: EXPENSE_CATEGORY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPENSE_CATEGORY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateExpenseCategoryAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_CREATE_RESET });
};

// List details
export const listExpenseCategoryDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: EXPENSE_CATEGORY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getExpenseCategory(id, token);

      dispatch({
        type: EXPENSE_CATEGORY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPENSE_CATEGORY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListExpenseCategoryDetailsAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_DETAILS_RESET });
};

// Update
export const updateExpenseCategoryAction =
  (id, expenseCategory) => async (dispatch, getState) => {
    try {
      dispatch({ type: EXPENSE_CATEGORY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateExpenseCategory(
        id,
        token,
        expenseCategory
      );

      dispatch({
        type: EXPENSE_CATEGORY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPENSE_CATEGORY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateExpenseCategoryAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_UPDATE_RESET });
};

// Delete
export const deleteExpenseCategoryAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: EXPENSE_CATEGORY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteExpenseCategory(id, token);

      dispatch({
        type: EXPENSE_CATEGORY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: EXPENSE_CATEGORY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteExpenseCategoryAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_DELETE_RESET });
};

// Download
export const downloadExpenseCategoriesAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: EXPENSE_CATEGORY_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var expenseCategories = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getExpenseCategories(
          { page: page, organisation: organisation },
          token
        );

        expenseCategories = expenseCategories.concat(data.results);
      }

      dispatch({
        type: EXPENSE_CATEGORY_DOWNLOAD_SUCCESS,

        payload: {
          expense_categories: expenseCategories.filter(
            (category) => !category.default
          ),
        },
      });
    } catch (error) {
      dispatch({
        type: EXPENSE_CATEGORY_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadExpenseCategoriesAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_DOWNLOAD_RESET });
};

// Upload
export const uploadExpenseCategoriesAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: EXPENSE_CATEGORY_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: EXPENSE_CATEGORY_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteExpenseCategory(
            records[recordNum].id,
            token
          );
        } else if (records[recordNum].id) {
          await cortixDataService.updateExpenseCategory(
            records[recordNum].id,
            token,
            {
              code: records[recordNum].code,
              description: records[recordNum].description,
              name: records[recordNum].name,
              organisation: id,
            }
          );
        } else {
          await cortixDataService.createExpenseCategory(token, {
            code: records[recordNum].code,
            description: records[recordNum].description,
            name: records[recordNum].name,
            organisation: id,
          });
        }

        dispatch({
          type: EXPENSE_CATEGORY_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: EXPENSE_CATEGORY_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: EXPENSE_CATEGORY_UPLOAD_END });
  };

// Reset upload
export const resetUploadExpenseCategoriesAction = () => async (dispatch) => {
  dispatch({ type: EXPENSE_CATEGORY_UPLOAD_RESET });
};
