// Constant imports
import {
  STAFF_PERMISSION_LIST_REQUEST,
  STAFF_PERMISSION_LIST_SUCCESS,
  STAFF_PERMISSION_LIST_FAIL,
  STAFF_PERMISSION_LIST_RESET,
  STAFF_PERMISSION_CREATE_REQUEST,
  STAFF_PERMISSION_CREATE_SUCCESS,
  STAFF_PERMISSION_CREATE_FAIL,
  STAFF_PERMISSION_CREATE_RESET,
  STAFF_PERMISSION_DETAILS_REQUEST,
  STAFF_PERMISSION_DETAILS_SUCCESS,
  STAFF_PERMISSION_DETAILS_FAIL,
  STAFF_PERMISSION_DETAILS_RESET,
  STAFF_PERMISSION_UPDATE_REQUEST,
  STAFF_PERMISSION_UPDATE_SUCCESS,
  STAFF_PERMISSION_UPDATE_FAIL,
  STAFF_PERMISSION_UPDATE_RESET,
  STAFF_PERMISSION_DELETE_REQUEST,
  STAFF_PERMISSION_DELETE_SUCCESS,
  STAFF_PERMISSION_DELETE_FAIL,
  STAFF_PERMISSION_DELETE_RESET,
} from "../constants/staffPermissionConstants";

// List all
export const staffPermissionListReducer = (
  state = {
    loading: false,
    count: 0,
    staff_permissions: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STAFF_PERMISSION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        staff_permissions: action.payload.staff_permissions,
        success: true,
        error: "",
      };

    case STAFF_PERMISSION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        staff_permissions: [],
        success: false,
        error: action.payload,
      };

    case STAFF_PERMISSION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        staff_permissions: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const staffPermissionCreateReducer = (
  state = {
    loading: false,
    staff_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_PERMISSION_CREATE_REQUEST:
      return {
        loading: true,
        staff_permission: state.staff_permission,
        success: false,
        error: "",
      };

    case STAFF_PERMISSION_CREATE_SUCCESS:
      return {
        loading: false,
        staff_permission: action.payload,
        success: true,
        error: "",
      };

    case STAFF_PERMISSION_CREATE_FAIL:
      return {
        loading: false,
        staff_permission: state.staff_permission,
        success: false,
        error: action.payload,
      };

    case STAFF_PERMISSION_CREATE_RESET:
      return {
        loading: false,
        staff_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const staffPermissionDetailsReducer = (
  state = {
    loading: false,
    staff_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_PERMISSION_DETAILS_REQUEST:
      return {
        loading: true,
        staff_permission: state.staff_permission,
        success: false,
        error: "",
      };

    case STAFF_PERMISSION_DETAILS_SUCCESS:
      return {
        loading: false,
        staff_permission: action.payload,
        success: true,
        error: "",
      };

    case STAFF_PERMISSION_DETAILS_FAIL:
      return {
        loading: false,
        staff_permission: state.staff_permission,
        success: false,
        error: action.payload,
      };

    case STAFF_PERMISSION_DETAILS_RESET:
      return {
        loading: false,
        staff_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const staffPermissionUpdateReducer = (
  state = {
    loading: false,
    staff_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_PERMISSION_UPDATE_REQUEST:
      return {
        loading: true,
        staff_permission: state.staff_permission,
        success: false,
        error: "",
      };

    case STAFF_PERMISSION_UPDATE_SUCCESS:
      return {
        loading: false,
        staff_permission: action.payload,
        success: true,
        error: "",
      };

    case STAFF_PERMISSION_UPDATE_FAIL:
      return {
        loading: false,
        staff_permission: state.staff_permission,
        success: false,
        error: action.payload,
      };

    case STAFF_PERMISSION_UPDATE_RESET:
      return {
        loading: false,
        staff_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const staffPermissionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_PERMISSION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case STAFF_PERMISSION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case STAFF_PERMISSION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case STAFF_PERMISSION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
