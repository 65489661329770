// Constant imports
import {
  ROLE_COMPETENCY_LIST_REQUEST,
  ROLE_COMPETENCY_LIST_SUCCESS,
  ROLE_COMPETENCY_LIST_FAIL,
  ROLE_COMPETENCY_LIST_RESET,
  ROLE_COMPETENCY_CREATE_REQUEST,
  ROLE_COMPETENCY_CREATE_SUCCESS,
  ROLE_COMPETENCY_CREATE_FAIL,
  ROLE_COMPETENCY_CREATE_RESET,
  ROLE_COMPETENCY_DETAILS_REQUEST,
  ROLE_COMPETENCY_DETAILS_SUCCESS,
  ROLE_COMPETENCY_DETAILS_FAIL,
  ROLE_COMPETENCY_DETAILS_RESET,
  ROLE_COMPETENCY_UPDATE_REQUEST,
  ROLE_COMPETENCY_UPDATE_SUCCESS,
  ROLE_COMPETENCY_UPDATE_FAIL,
  ROLE_COMPETENCY_UPDATE_RESET,
  ROLE_COMPETENCY_DELETE_REQUEST,
  ROLE_COMPETENCY_DELETE_SUCCESS,
  ROLE_COMPETENCY_DELETE_FAIL,
  ROLE_COMPETENCY_DELETE_RESET,
} from "../constants/roleCompetencyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listRoleCompetenciesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_COMPETENCY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getRoleCompetencies(
        params,
        token
      );

      dispatch({
        type: ROLE_COMPETENCY_LIST_SUCCESS,

        payload: { count: data.count, role_competencies: data.results },
      });
    } catch (error) {
      dispatch({
        type: ROLE_COMPETENCY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListRoleCompetenciesAction = () => async (dispatch) => {
  dispatch({ type: ROLE_COMPETENCY_LIST_RESET });
};

// Create
export const createRoleCompetencyAction =
  (role_competency) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_COMPETENCY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createRoleCompetency(
        token,
        role_competency
      );

      dispatch({
        type: ROLE_COMPETENCY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_COMPETENCY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateRoleCompetencyAction = () => async (dispatch) => {
  dispatch({ type: ROLE_COMPETENCY_CREATE_RESET });
};

// List details
export const listRoleCompetencyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_COMPETENCY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getRoleCompetency(id, token);

      dispatch({
        type: ROLE_COMPETENCY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_COMPETENCY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListRoleCompetencyDetailsAction = () => async (dispatch) => {
  dispatch({ type: ROLE_COMPETENCY_DETAILS_RESET });
};

// Update
export const updateRoleCompetencyAction =
  (id, role_competency) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_COMPETENCY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateRoleCompetency(
        id,
        token,
        role_competency
      );

      dispatch({
        type: ROLE_COMPETENCY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_COMPETENCY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateRoleCompetencyAction = () => async (dispatch) => {
  dispatch({ type: ROLE_COMPETENCY_UPDATE_RESET });
};

// Delete
export const deleteRoleCompetencyAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_COMPETENCY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteRoleCompetency(id, token);

      dispatch({
        type: ROLE_COMPETENCY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_COMPETENCY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteRoleCompetencyAction = () => async (dispatch) => {
  dispatch({ type: ROLE_COMPETENCY_DELETE_RESET });
};
