// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ErrorAlert from "../components/ErrorAlert";
import ClientInfo from "../components/ClientInfo";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import GenerateButton from "../components/GenerateButton";
import HelpDialog from "../components/HelpDialog";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import PermissionCard from "../components/PermissionCard";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldImage from "../components/TabSectionFieldImage";

// Redux action imports
// Case study
import {
  deleteCaseStudyAction,
  listCaseStudyDetailsAction,
  resetDeleteCaseStudyAction,
  resetListCaseStudiesAction,
  resetUpdateCaseStudyAction,
  updateCaseStudyAction,
} from "../actions/caseStudyActions";

// Case study permission
import {
  createCaseStudyPermissionAction,
  deleteCaseStudyPermissionAction,
  listCaseStudyPermissionsAction,
  listCaseStudyPermissionDetailsAction,
  resetCreateCaseStudyPermissionAction,
  resetDeleteCaseStudyPermissionAction,
  resetUpdateCaseStudyPermissionAction,
  updateCaseStudyPermissionAction,
} from "../actions/caseStudyPermissionActions";

// Client
import { listClientsAction } from "../actions/clientActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Main function
function CaseStudyDetailScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Case study id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Case study
  const caseStudyDelete = useSelector((state) => state.caseStudyDelete);

  const caseStudyDetails = useSelector((state) => state.caseStudyDetails);

  const caseStudyUpdate = useSelector((state) => state.caseStudyUpdate);

  // Case study permission
  const caseStudyPermissionCreate = useSelector(
    (state) => state.caseStudyPermissionCreate
  );

  const caseStudyPermissionDelete = useSelector(
    (state) => state.caseStudyPermissionDelete
  );

  const caseStudyPermissionDetails = useSelector(
    (state) => state.caseStudyPermissionDetails
  );

  const caseStudyPermissionList = useSelector(
    (state) => state.caseStudyPermissionList
  );

  const caseStudyPermissionUpdate = useSelector(
    (state) => state.caseStudyPermissionUpdate
  );

  // Client
  const clientList = useSelector((state) => state.clientList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("case-study-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Detail
  const [updateDetailDrawerIsOpen, setUpdateDetailDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Case study permission
  const caseStudyPermissionQueryDefault = {
    caseStudy: id,
    page: 1,
    searchString: "",
  };
  const [caseStudyPermissionQuery, setCaseStudyPermissionQuery] = useState({
    ...caseStudyPermissionQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Form variables
  // Case study
  const caseStudyDefault = {
    client: "",
    clientName: "",
    complication: "",
    description: "",
    exhibit: "",
    exhibitName: "",
    exhibitTitle: "",
    manager: "",
    managerName: "",
    name: "",
    restricted: true,
    situation: "",
    solution: "",
    status: "Draft",
  };
  const [caseStudy, setCaseStudy] = useState({
    ...caseStudyDefault,
  });

  // Case study permission
  const caseStudyPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [caseStudyPermission, setCaseStudyPermission] = useState({
    ...caseStudyPermissionDefault,
  });

  // Other
  const [caseStudyDeleteKey, setCaseStudyDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Case study
    setCaseStudy({
      ...caseStudyDefault,
    });

    // Case study permission
    setCaseStudyPermission({
      ...caseStudyPermissionDefault,
    });

    // Query variables
    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // Initialise drawer variables for case study
  useEffect(() => {
    setCaseStudy({
      client: caseStudyDetails.case_study.client
        ? caseStudyDetails.case_study.client
        : caseStudyDefault.client,
      clientName: caseStudyDetails.case_study.client_name
        ? caseStudyDetails.case_study.client_name
        : caseStudyDefault.clientName,
      complication: caseStudyDetails.case_study.complication
        ? caseStudyDetails.case_study.complication
        : caseStudyDefault.complication,
      description: caseStudyDetails.case_study.description
        ? caseStudyDetails.case_study.description
        : caseStudyDefault.description,
      exhibit: caseStudyDetails.case_study.exhibit
        ? caseStudyDetails.case_study.exhibit
        : caseStudyDefault.exhibit,
      exhibitName: caseStudyDetails.case_study.exhibit
        ? caseStudyDetails.case_study.exhibit.split("/").pop()
        : caseStudyDefault.exhibitName,
      exhibitTitle: caseStudyDetails.case_study.exhibit_title
        ? caseStudyDetails.case_study.exhibit_title
        : caseStudyDefault.exhibitTitle,
      manager: caseStudyDetails.case_study.manager
        ? caseStudyDetails.case_study.manager
        : caseStudyDefault.manager,
      managerName: caseStudyDetails.case_study.manager_name
        ? caseStudyDetails.case_study.manager_name
        : caseStudyDefault.managerName,
      name: caseStudyDetails.case_study.name
        ? caseStudyDetails.case_study.name
        : caseStudyDefault.name,
      restricted:
        caseStudyDetails.case_study.restricted != null
          ? caseStudyDetails.case_study.restricted
          : caseStudyDefault.restricted,
      situation: caseStudyDetails.case_study.situation
        ? caseStudyDetails.case_study.situation
        : caseStudyDefault.situation,
      solution: caseStudyDetails.case_study.solution
        ? caseStudyDetails.case_study.solution
        : caseStudyDefault.solution,
      status: caseStudyDetails.case_study.status
        ? caseStudyDetails.case_study.status
        : caseStudyDefault.status,
    });
    // eslint-disable-next-line
  }, [caseStudyDetails.case_study]);

  // Initialise drawer variables for case study permission
  useEffect(() => {
    setCaseStudyPermission({
      level: caseStudyPermissionDetails.case_study_permission.level
        ? caseStudyPermissionDetails.case_study_permission.level
        : caseStudyPermissionDefault.level,
      manager: caseStudyPermissionDetails.case_study_permission.manager
        ? caseStudyPermissionDetails.case_study_permission.manager
        : caseStudyPermissionDefault.manager,
      managerName: caseStudyPermissionDetails.case_study_permission.manager_name
        ? caseStudyPermissionDetails.case_study_permission.manager_name
        : caseStudyPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [caseStudyPermissionDetails.case_study_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If case study deleted redirect to case studies
  useEffect(() => {
    if (caseStudyDelete.success) {
      dispatch(resetDeleteCaseStudyAction());

      dispatch(resetListCaseStudiesAction());

      navigate("/caseStudies");
    }
    // eslint-disable-next-line
  }, [caseStudyDelete.success]);

  // Load case study details
  useEffect(() => {
    dispatch(listCaseStudyDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter case study permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCaseStudyPermissionsAction({ ...caseStudyPermissionQuery })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [caseStudyPermissionQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Form submission
  // Case study
  // Delete case study
  const deleteCaseStudy = () => {
    // Dispatch action
    caseStudyDeleteKey === caseStudyDetails.case_study.name
      ? dispatch(deleteCaseStudyAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the case study's name",
        });
  };

  // Update case study detail
  const updateDetail = () => {
    // Prepare data
    const case_study_data = new FormData();

    caseStudy.complication
      ? case_study_data.append("complication", caseStudy.complication)
      : case_study_data.append("complication", caseStudyDefault.complication);

    if (
      caseStudy.exhibit &&
      caseStudy.exhibit !== caseStudyDetails.case_study.exhibit
    ) {
      case_study_data.append("exhibit", caseStudy.exhibit);
    } else if (caseStudy.exhibit === "") {
      case_study_data.append("exhibit", caseStudyDefault.exhibit);
    }

    caseStudy.exhibitTitle
      ? case_study_data.append("exhibit_title", caseStudy.exhibitTitle)
      : case_study_data.append("exhibit_title", caseStudyDefault.exhibitTitle);

    caseStudy.situation
      ? case_study_data.append("situation", caseStudy.situation)
      : case_study_data.append("situation", caseStudyDefault.situation);

    caseStudy.solution
      ? case_study_data.append("solution", caseStudy.solution)
      : case_study_data.append("solution", caseStudyDefault.solution);

    // Dispatch action
    dispatch(
      updateCaseStudyAction(caseStudyDetails.case_study.id, "", case_study_data)
    );
  };

  // Update case study summary
  const updateSummary = () => {
    // Prepare data
    const case_study_data = new FormData();

    const client = new FormData();
    if (caseStudy.client) {
      case_study_data.append("client", caseStudy.client);
    } else {
      case_study_data.append("client", caseStudyDefault.client);

      if (caseStudy.clientName) {
        client.append("name", caseStudy.clientName);

        client.append("organisation", activeOrganisation.id);
      }
    }

    caseStudy.description
      ? case_study_data.append("description", caseStudy.description)
      : case_study_data.append("description", caseStudyDefault.description);

    caseStudy.manager
      ? case_study_data.append("manager", caseStudy.manager)
      : case_study_data.append("manager", caseStudyDefault.manager);

    caseStudy.name
      ? case_study_data.append("name", caseStudy.name)
      : case_study_data.append("name", caseStudyDefault.name);

    caseStudy.restricted != null
      ? case_study_data.append("restricted", caseStudy.restricted)
      : case_study_data.append("restricted", caseStudyDefault.restricted);

    caseStudy.status
      ? case_study_data.append("status", caseStudy.status)
      : case_study_data.append("status", caseStudyDefault.status);

    // Dispatch action
    dispatch(
      updateCaseStudyAction(
        caseStudyDetails.case_study.id,
        client,
        case_study_data
      )
    );
  };

  useEffect(() => {
    if (caseStudyUpdate.success) {
      dispatch(listCaseStudyDetailsAction(id));

      dispatch(resetUpdateCaseStudyAction());
    }
    // eslint-disable-next-line
  }, [caseStudyUpdate.success]);

  // Case study permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const case_study_permission = new FormData();

    caseStudyPermission.level
      ? case_study_permission.append("level", caseStudyPermission.level)
      : case_study_permission.append("level", caseStudyPermissionDefault.level);

    caseStudyPermission.manager
      ? case_study_permission.append("manager", caseStudyPermission.manager)
      : case_study_permission.append(
          "manager",
          caseStudyPermissionDefault.manager
        );

    case_study_permission.append("case_study", id);

    // Dispatch action
    dispatch(createCaseStudyPermissionAction(case_study_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteCaseStudyPermissionAction(
        caseStudyPermissionDetails.case_study_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const case_study_permission = new FormData();

    caseStudyPermission.level
      ? case_study_permission.append("level", caseStudyPermission.level)
      : case_study_permission.append("level", caseStudyPermissionDefault.level);

    caseStudyPermission.manager
      ? case_study_permission.append("manager", caseStudyPermission.manager)
      : case_study_permission.append(
          "manager",
          caseStudyPermissionDefault.manager
        );

    // Dispatch action
    dispatch(
      updateCaseStudyPermissionAction(
        caseStudyPermissionDetails.case_study_permission.id,
        case_study_permission
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the case study</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Case study name */}
                  <FormDrawerTextField2
                    error={caseStudyUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Case study name"
                    labelInfo="(required)"
                    placeholder="Enter the case study's name"
                    setValueObject={setCaseStudy}
                    setValueVariable="name"
                    value={caseStudy.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Client */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={caseStudyUpdate.error}
                    fieldName="client"
                    helperText="You can create or select an existing client"
                    icon="office"
                    info={
                      <ClientInfo
                        clientId={caseStudy.client}
                        query={clientQueryDefault}
                      />
                    }
                    items={clientList.clients}
                    label="Client"
                    labelInfo="(required)"
                    loading={clientList.loading}
                    placeholder="Create or select a client"
                    query={clientQuery.searchString}
                    setQueryObject={setClientQuery}
                    setQueryVariable="searchString"
                    setValueObject={setCaseStudy}
                    setValueIdVariable="client"
                    setValueNameVariable="clientName"
                    showInfo={caseStudy.client}
                    value={caseStudy.clientName}
                  />
                </div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={caseStudyUpdate.error}
                fieldName="description"
                helperText="advaise.app can use this description to generate content from the organisation's knowledge base"
                label="Description"
                placeholder="Describe the case study"
                setValueObject={setCaseStudy}
                setValueVariable="description"
                value={caseStudy.description}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Status */}
                  <FormDrawerButtonField
                    disabled={
                      caseStudyDetails.case_study.user_access_status ===
                        "edit" ||
                      caseStudyDetails.case_study.user_access_status === "view"
                    }
                    error={caseStudyUpdate.error}
                    fieldName="status"
                    helperText="Case studies in draft status cannot be added to proposals"
                    label="Status"
                    options={[
                      {
                        icon: "tick-circle",
                        id: 1,
                        intent: "success",
                        label: "Approved",
                        value: "Approved",
                      },
                      {
                        icon: "new-drawing",
                        id: 2,
                        intent: "primary",
                        label: "Draft",
                        value: "Draft",
                      },
                    ]}
                    setValueObject={setCaseStudy}
                    setValueVariable="status"
                    value={caseStudy.status}
                  />

                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      caseStudyDetails.case_study.user_access_status ===
                        "edit" ||
                      caseStudyDetails.case_study.user_access_status === "view"
                    }
                    error={caseStudyUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted case study can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setCaseStudy}
                    setValueVariable="restricted"
                    value={caseStudy.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      caseStudyDetails.case_study.user_access_status ===
                        "edit" ||
                      caseStudyDetails.case_study.user_access_status === "view"
                    }
                    error={caseStudyUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the case study - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setCaseStudy}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={caseStudy.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the case study manager and the
                    case study is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={caseStudyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Case studies"
            view="Detail"
          />
        }
        helpTitle="Updating a case study"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={caseStudyDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listCaseStudyDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={caseStudyUpdate.success}
        saveSuccessMessage="Case study updated."
        saving={caseStudyUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={caseStudyPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setCaseStudyPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={caseStudyPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={caseStudyPermissionCreate.error}
              fieldName="level"
              intent={
                caseStudyPermission.level === "Edit"
                  ? "success"
                  : caseStudyPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setCaseStudyPermission}
              setValueVariable="level"
              value={caseStudyPermission.level}
            />
          </Card>
        }
        error={caseStudyPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Case studies"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listCaseStudyPermissionsAction({ caseStudy: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateCaseStudyPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={caseStudyPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={caseStudyPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={caseStudyPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setCaseStudyPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={caseStudyPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={caseStudyPermissionUpdate.error}
              fieldName="level"
              intent={
                caseStudyPermission.level === "Edit"
                  ? "success"
                  : caseStudyPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setCaseStudyPermission}
              setValueVariable="level"
              value={caseStudyPermission.level}
            />
          </Card>
        }
        deleteSuccess={caseStudyPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={caseStudyPermissionDelete.loading}
        error={caseStudyPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Case studies"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={caseStudyPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(
            listCaseStudyPermissionsAction({ ...caseStudyPermissionQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteCaseStudyPermissionAction());

          dispatch(resetUpdateCaseStudyPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={caseStudyPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={caseStudyPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* Detail form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Summarise the situation</H5>

              <Divider />

              {/* Situation */}
              <FormDrawerTextAreaField3
                error={caseStudyUpdate.error}
                fieldName="situation"
                label="What did the client want to achieve?"
                placeholder="Give a summary of the client and their goal"
                setValueObject={setCaseStudy}
                setValueVariable="situation"
                value={caseStudy.situation}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the issue</H5>

              <Divider />

              {/* Complication */}
              <FormDrawerTextAreaField3
                error={caseStudyUpdate.error}
                fieldName="complication"
                label="Why did the client need help?"
                placeholder="Describe the barrier to achieving the goal"
                setValueObject={setCaseStudy}
                setValueVariable="complication"
                value={caseStudy.complication}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Summarise our approach</H5>

              <Divider />

              {/* Solution */}
              <FormDrawerTextAreaField3
                error={caseStudyUpdate.error}
                fieldName="solution"
                label="What was our solution and the result?"
                placeholder="Describe how we helped the client"
                setValueObject={setCaseStudy}
                setValueVariable="solution"
                value={caseStudy.solution}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Add an exhibit</H5>

              <Divider />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/*Exhibit */}
                  <FormDrawerFileField2
                    error={caseStudyUpdate.error}
                    fileName="exhibit"
                    label="Exhibit"
                    placeholder="Upload the exhibit"
                    setValueObject={setCaseStudy}
                    setValueFileVariable="exhibit"
                    setValueNameVariable="exhibitName"
                    valueFile={caseStudy.exhibit}
                    valueName={caseStudy.exhibitName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Exhibit title */}
                  <FormDrawerTextField2
                    error={caseStudyUpdate.error}
                    fieldName="exhibit_title"
                    icon="label"
                    label="Exhibit title"
                    placeholder="Give the exhibit a title"
                    setValueObject={setCaseStudy}
                    setValueVariable="exhibitTitle"
                    value={caseStudy.exhibitTitle}
                  />
                </div>
              </div>

              {/*Exhibit preview */}
              {caseStudy.exhibit ? (
                caseStudy.exhibit !== caseStudyDetails.case_study.exhibit ? (
                  <TabSectionFieldImage
                    image={URL.createObjectURL(new Blob([caseStudy.exhibit]))}
                  />
                ) : (
                  <TabSectionFieldImage
                    image={caseStudyDetails.case_study.exhibit}
                  />
                )
              ) : undefined}
            </Card>
          </div>
        }
        error={caseStudyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update detail"
            module="Case studies"
            view="Detail"
          />
        }
        helpTitle="Describing the case study"
        icon="edit"
        isOpen={updateDetailDrawerIsOpen}
        loading={caseStudyDetails.loading}
        onSave={updateDetail}
        refreshList={() => {
          dispatch(listCaseStudyDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={caseStudyUpdate.success}
        saveSuccessMessage="Detail updated."
        saving={caseStudyUpdate.loading}
        setIsOpen={setUpdateDetailDrawerIsOpen}
        title="Update detail"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Case studies"
              onClick={() => {
                dispatch(resetListCaseStudiesAction());

                navigate("/caseStudies");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          caseStudyDetails.case_study.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={caseStudyDetails.loading}
        title={`${caseStudyDetails.case_study.name}`}
      />

      {/* Error alerts */}
      {/* Case study delete error */}
      {caseStudyDelete.error && (
        <ErrorAlert message="We couldn't delete the case study. Try refreshing the page." />
      )}
      {/* Case study loading error */}
      {caseStudyDetails.error && (
        <ErrorAlert message="We couldn't load the case study. Try refreshing the page." />
      )}
      {/* Case study permissions loading error */}
      {caseStudyPermissionList.error && (
        <ErrorAlert message="We couldn't load the case study permissions. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Case study"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="case_study-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="case-study-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          caseStudyDetails.case_study.user_access_status ===
                          "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listCaseStudyDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={caseStudyDetails.loading}
                          value={caseStudyDetails.case_study.id}
                        />

                        {/* Case study name */}
                        <TabSectionField
                          label="Case study name"
                          loading={caseStudyDetails.loading}
                          value={caseStudyDetails.case_study.name}
                        />

                        {/* Client */}
                        <TabSectionField
                          label="Client"
                          loading={caseStudyDetails.loading}
                          value={
                            <NameValue
                              image={caseStudyDetails.case_study.client_logo}
                              logo
                              text={caseStudyDetails.case_study.client_name}
                              url={`/clients/${caseStudyDetails.case_study.client}`}
                            />
                          }
                        />

                        {/* Description */}
                        <TabSectionField
                          label="Description"
                          loading={caseStudyDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={caseStudyDetails.case_study.description}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={caseStudyDetails.loading}
                          value={
                            <Tag
                              intent={
                                caseStudyDetails.case_study.status ===
                                "Approved"
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {caseStudyDetails.case_study.status}
                            </Tag>
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            caseStudyDetails.case_study.restricted
                              ? "Users must have permission to access this case study"
                              : "Everyone in the organisation can edit this case study"
                          }
                          label="Access"
                          loading={caseStudyDetails.loading}
                          value={
                            <Tag
                              intent={
                                caseStudyDetails.case_study.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {caseStudyDetails.case_study.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            caseStudyDetails.case_study.user_access_status ===
                            "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={caseStudyDetails.loading}
                          value={
                            <NameValue
                              email={caseStudyDetails.case_study.manager_name}
                              image={
                                caseStudyDetails.case_study
                                  .manager_profile_photo
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update case study information"
                    icon="book"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(caseStudyDetails.case_study.user_access_status ===
                    "owner" ||
                    caseStudyDetails.case_study.user_access_status ===
                      "admin" ||
                    caseStudyDetails.case_study.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={caseStudyPermissionList.loading}
                              setValueObject={setCaseStudyPermissionQuery}
                              setValueVariable="searchString"
                              value={caseStudyPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={caseStudyPermissionList.case_study_permissions.map(
                              (case_study_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={case_study_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listCaseStudyPermissionDetailsAction(
                                          case_study_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={case_study_permission}
                                  />
                                );
                              }
                            )}
                            count={caseStudyPermissionList.count}
                            loading={caseStudyPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={caseStudyPermissionQuery.page}
                            setPageObject={setCaseStudyPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, case studies are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete case study */}
                  {(caseStudyDetails.case_study.user_access_status ===
                    "owner" ||
                    caseStudyDetails.case_study.user_access_status ===
                      "admin" ||
                    caseStudyDetails.case_study.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={
                                caseStudyDetails.case_study.cannot_delete
                              }
                              id="confirm-case-study-name"
                              onChange={(e) => {
                                setCaseStudyDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the case study's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={caseStudyDeleteKey}
                            />

                            <Button
                              disabled={
                                caseStudyDetails.case_study.cannot_delete
                              }
                              icon="trash"
                              intent="danger"
                              loading={caseStudyDelete.loading}
                              onClick={deleteCaseStudy}
                              text="Delete this case study"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{caseStudyDetails.case_study.name}" to
                            delete.
                          </div>
                        </div>
                      }
                      help="Delete the case study"
                      icon="delete"
                      message={
                        caseStudyDetails.case_study.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this case study, you will lose all information associated with it."
                      }
                      messageIntent={
                        caseStudyDetails.case_study.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete case study"
                    />
                  )}
                </div>
              }
            />

            {/* Detail */}
            <Tab
              id="case-study-detail-tab"
              title="Detail"
              panel={
                <div>
                  {/* Detail */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            caseStudyDetails.case_study.user_access_status ===
                            "view"
                          }
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(listCaseStudyDetailsAction(id));

                            setUpdateDetailDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Edit"
                        />

                        <GenerateButton
                          disabled={
                            !caseStudyDetails.case_study.description ||
                            (caseStudyDetails.case_study.complication &&
                              caseStudyDetails.case_study.situation &&
                              caseStudyDetails.case_study.solution) ||
                            caseStudyDetails.case_study.user_access_status ===
                              "view"
                          }
                          objectDescription={`${caseStudyDetails.case_study.name} for ${caseStudyDetails.case_study.client_name}: ${caseStudyDetails.case_study.description}`}
                          objectType="case study"
                          onSuccess={(result) => {
                            if (
                              (!("complication" in result) ||
                                result.complication === "") &&
                              (!("situation" in result) ||
                                result.situation === "") &&
                              (!("solution" in result) || result.solution) ===
                                ""
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            const caseStudyData = {
                              complication:
                                caseStudy.complication || result.complication,
                              situation:
                                caseStudy.situation || result.situation,
                              solution: caseStudy.solution || result.solution,
                            };

                            dispatch(
                              updateCaseStudyAction(id, "", caseStudyData)
                            );
                          }}
                        />
                      </>
                    }
                    content={
                      <>
                        {/* Situation */}
                        <TabSectionField
                          label="Situation"
                          value={
                            <TabSectionFieldCard
                              loading={caseStudyDetails.loading}
                              text={
                                caseStudyDetails.loading ? undefined : caseStudyDetails
                                    .case_study.situation ? (
                                  caseStudyDetails.case_study.situation
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                            />
                          }
                        />

                        {/* Complication */}
                        <TabSectionField
                          label="Complication"
                          value={
                            <TabSectionFieldCard
                              loading={caseStudyDetails.loading}
                              text={
                                caseStudyDetails.loading ? undefined : caseStudyDetails
                                    .case_study.complication ? (
                                  caseStudyDetails.case_study.complication
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                            />
                          }
                        />

                        {/* Solution */}
                        <TabSectionField
                          label="Solution"
                          value={
                            <TabSectionFieldCard
                              loading={caseStudyDetails.loading}
                              text={
                                caseStudyDetails.loading ? undefined : caseStudyDetails
                                    .case_study.solution ? (
                                  caseStudyDetails.case_study.solution
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                            />
                          }
                        />

                        {/* Exhibit */}
                        <TabSectionField
                          bottom
                          label="Exhibit"
                          loading={caseStudyDetails.loading}
                          value={
                            <TabSectionFieldImage
                              fill
                              image={caseStudyDetails.case_study.exhibit}
                              interactive
                              title={
                                caseStudyDetails.case_study.exhibit_title
                                  ? caseStudyDetails.case_study.exhibit_title
                                  : undefined
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Describe the case study"
                    icon="widget-button"
                    title="Detail"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default CaseStudyDetailScreen;
