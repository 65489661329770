// Constant imports
import {
  GENERATE_DEMAND_PROFILE_REQUEST,
  GENERATE_DEMAND_PROFILE_SUCCESS,
  GENERATE_DEMAND_PROFILE_FAIL,
  GENERATE_DEMAND_PROFILE_RESET,
} from "../constants/demandProfileConstants";

// Data services import
import cortixDataService from "../services/cortix";

// Generate demand profile
export const generateDemandProfileAction =
  (id, parameters) => async (dispatch, getState) => {
    try {
      dispatch({ type: GENERATE_DEMAND_PROFILE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.generateDemandProfile(
        id,
        token,
        parameters
      );

      dispatch({
        type: GENERATE_DEMAND_PROFILE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GENERATE_DEMAND_PROFILE_FAIL,

        payload: error,
      });
    }
  };

// Reset rewrite field
export const resetGenerateDemandProfileAction = () => async (dispatch) => {
  dispatch({ type: GENERATE_DEMAND_PROFILE_RESET });
};
