// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function OpeningFillRateCard({ fill, openingList, openingQuery }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Fill rate</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Percentage of the selected openings that have been filled. Excludes
            records that are on-hold.
          </p>
        </>
      }
      data={[
        {
          name: "Filled",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: openingList.numberOfOpeningsFilled,
        },
        {
          name: "Not filled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: openingList.numberOfOpeningsInterviewing,
        },
      ]}
      fill={fill}
      filter={openingQuery ? openingQuery.status : undefined}
      noData={
        !openingList.numberOfOpeningsFilled &&
        !openingList.numberOfOpeningsInterviewing
      }
      showPercent
      title="Fill rate"
      units="Percentage"
    />
  );
}

export default OpeningFillRateCard;
