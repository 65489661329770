// Library imports
import { Callout } from "@blueprintjs/core";
import React from "react";

// Main function
function ErrorAlert({ message }) {
  // JSX UI code
  return (
    <div style={{ padding: "10px" }}>
      <Callout icon="error" intent="danger">
        {message}
      </Callout>
    </div>
  );
}

export default ErrorAlert;
