export const INSIGHT_LIST_REQUEST = "INSIGHT_LIST_REQUEST";
export const INSIGHT_LIST_SUCCESS = "INSIGHT_LIST_SUCCESS";
export const INSIGHT_LIST_FAIL = "INSIGHT_LIST_FAIL";
export const INSIGHT_LIST_RESET = "INSIGHT_LIST_RESET";

export const INSIGHT_CREATE_REQUEST = "INSIGHT_CREATE_REQUEST";
export const INSIGHT_CREATE_SUCCESS = "INSIGHT_CREATE_SUCCESS";
export const INSIGHT_CREATE_FAIL = "INSIGHT_CREATE_FAIL";
export const INSIGHT_CREATE_RESET = "INSIGHT_CREATE_RESET";

export const INSIGHT_DETAILS_REQUEST = "INSIGHT_DETAILS_REQUEST";
export const INSIGHT_DETAILS_SUCCESS = "INSIGHT_DETAILS_SUCCESS";
export const INSIGHT_DETAILS_FAIL = "INSIGHT_DETAILS_FAIL";
export const INSIGHT_DETAILS_RESET = "INSIGHT_DETAILS_RESET";

export const INSIGHT_UPDATE_REQUEST = "INSIGHT_UPDATE_REQUEST";
export const INSIGHT_UPDATE_SUCCESS = "INSIGHT_UPDATE_SUCCESS";
export const INSIGHT_UPDATE_FAIL = "INSIGHT_UPDATE_FAIL";
export const INSIGHT_UPDATE_RESET = "INSIGHT_UPDATE_RESET";

export const INSIGHT_DELETE_REQUEST = "INSIGHT_DELETE_REQUEST";
export const INSIGHT_DELETE_SUCCESS = "INSIGHT_DELETE_SUCCESS";
export const INSIGHT_DELETE_FAIL = "INSIGHT_DELETE_FAIL";
export const INSIGHT_DELETE_RESET = "INSIGHT_DELETE_RESET";
