// Constant imports
import {
  SERVICE_ACTIVITY_LIST_REQUEST,
  SERVICE_ACTIVITY_LIST_SUCCESS,
  SERVICE_ACTIVITY_LIST_FAIL,
  SERVICE_ACTIVITY_LIST_RESET,
  SERVICE_ACTIVITY_CREATE_REQUEST,
  SERVICE_ACTIVITY_CREATE_SUCCESS,
  SERVICE_ACTIVITY_CREATE_FAIL,
  SERVICE_ACTIVITY_CREATE_RESET,
  SERVICE_ACTIVITY_DETAILS_REQUEST,
  SERVICE_ACTIVITY_DETAILS_SUCCESS,
  SERVICE_ACTIVITY_DETAILS_FAIL,
  SERVICE_ACTIVITY_DETAILS_RESET,
  SERVICE_ACTIVITY_UPDATE_REQUEST,
  SERVICE_ACTIVITY_UPDATE_SUCCESS,
  SERVICE_ACTIVITY_UPDATE_FAIL,
  SERVICE_ACTIVITY_UPDATE_RESET,
  SERVICE_ACTIVITY_DELETE_REQUEST,
  SERVICE_ACTIVITY_DELETE_SUCCESS,
  SERVICE_ACTIVITY_DELETE_FAIL,
  SERVICE_ACTIVITY_DELETE_RESET,
  SERVICE_ACTIVITY_UPLOAD_BEGIN,
  SERVICE_ACTIVITY_UPLOAD_REQUEST,
  SERVICE_ACTIVITY_UPLOAD_SUCCESS,
  SERVICE_ACTIVITY_UPLOAD_FAIL,
  SERVICE_ACTIVITY_UPLOAD_END,
  SERVICE_ACTIVITY_UPLOAD_RESET,
} from "../constants/serviceActivityConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listServiceActivitiesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_ACTIVITY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getServiceActivities(
        params,
        token
      );

      dispatch({
        type: SERVICE_ACTIVITY_LIST_SUCCESS,

        payload: {
          count: data.count,
          service_activities: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: SERVICE_ACTIVITY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListServiceActivitiesAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_ACTIVITY_LIST_RESET });
};

// Create
export const createServiceActivityAction =
  (service_activity) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_ACTIVITY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createServiceActivity(
        token,
        service_activity
      );

      dispatch({
        type: SERVICE_ACTIVITY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_ACTIVITY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateServiceActivityAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_ACTIVITY_CREATE_RESET });
};

// List details
export const listServiceActivityDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_ACTIVITY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getServiceActivity(id, token);

      dispatch({
        type: SERVICE_ACTIVITY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_ACTIVITY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListServiceActivityDetailsAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_ACTIVITY_DETAILS_RESET });
};

// Update
export const updateServiceActivityAction =
  (id, service_activity) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_ACTIVITY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateServiceActivity(
        id,
        token,
        service_activity
      );

      dispatch({
        type: SERVICE_ACTIVITY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_ACTIVITY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateServiceActivityAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_ACTIVITY_UPDATE_RESET });
};

// Delete
export const deleteServiceActivityAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_ACTIVITY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteServiceActivity(id, token);

      dispatch({
        type: SERVICE_ACTIVITY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_ACTIVITY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteServiceActivityAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_ACTIVITY_DELETE_RESET });
};

// Upload
export const uploadServiceActivitiesAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: SERVICE_ACTIVITY_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      try {
        dispatch({ type: SERVICE_ACTIVITY_UPLOAD_REQUEST });

        await cortixDataService.createServiceActivity(token, {
          description: records[recordNum].description,
          name: records[recordNum].name,
          service: id,
        });

        dispatch({
          type: SERVICE_ACTIVITY_UPLOAD_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: SERVICE_ACTIVITY_UPLOAD_FAIL,

          payload: {
            error: error,
          },
        });
      }
    }

    dispatch({ type: SERVICE_ACTIVITY_UPLOAD_END });
  };

// Reset upload
export const resetUploadServiceActivitiesAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_ACTIVITY_UPLOAD_RESET });
};
