// Library imports
import { Card, Colors } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TabSectionFieldMultifieldCard({ children, interactive, onClick }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ width: "100%" }}>
      <Card
        interactive={interactive}
        onClick={onClick}
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
        }}
      >
        {children}
      </Card>
    </div>
  );
}

export default TabSectionFieldMultifieldCard;
