// Library imports
import { Card, Classes, Colors, H1, H5, Icon, UL } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function DemoCoCTACard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // JSX UI code
  return (
    <div
      style={{
        margin: "20px auto 20px auto",
        maxWidth: "1600px",
        padding: "0 20px 0 20px",
      }}
    >
      <Card
        style={{
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <div style={{ marginRight: "10px", width: "75%", zIndex: 1 }}>
          <div style={{ margin: "0 auto 0 auto", width: "800px" }}>
            <H1
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
              }}
            >
              Explore advaise.app
            </H1>

            <div style={{ margin: "20px 0 10px 0" }}>
              Thanks for confirming your email. You can now explore advaise.app.
              You may wish to start checking out DemoCo, a fictional
              organization set up to showcase some of advaise.app's
              capabilities.
            </div>

            <div
              className={Classes.BLOCKQUOTE}
              style={{ margin: "20px 0 10px 0" }}
            >
              <H5 style={{ color: lightMode ? Colors.BLUE3 : Colors.BLUE5 }}>
                You'll have access to DemoCo for 14 days from your signup date.
              </H5>

              <p>
                DemoCo is a fictional digital marketing agency. DemoCo is
                working with StellarTech, a leading provider of high-quality
                manufacturing solutions for businesses in various industries.
                StellarTech is embarking on a transformative journey to bolster
                their sales and drive accelerated growth.
              </p>
            </div>

            <div style={{ margin: "20px 0 10px 0" }}>
              To explore advaise.app, click on the{" "}
              <Icon disabled icon="menu" style={{ margin: "0 5px 0 5px" }} />{" "}
              menu button located on the top left corner of the screen. You may
              be interested in different modules depending on your role:
            </div>

            <UL>
              <li>
                If you're responsible for sales, check out the Sales modules
                (Contacts, Clients and Proposals).
              </li>

              <li>
                If you're involved in people and operations management, take a
                look at the People modules (Roles, Openings and Staff).
              </li>

              <li>
                If you manage client engagements, explore the Delivery modules
                (Engagements, Invoices).
              </li>
            </UL>

            <div style={{ margin: "20px 0 10px 0" }}>
              When you are have finished your tour of DemoCo, click{" "}
              <Icon disabled icon="add" style={{ margin: "0 0 0 5px" }} />{" "}
              <strong>Add</strong> on the navigation bar to create your own
              organization.
            </div>
          </div>
        </div>

        <div
          style={{
            alignItems: "end",
            display: "flex",
            justifyContent: "end",
            marginLeft: "10px",
            padding: "10px",
            width: "25%",
          }}
        >
          <Icon
            color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
            icon="office"
            size={300}
          />
        </div>
      </Card>
    </div>
  );
}

export default DemoCoCTACard;
