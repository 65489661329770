// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  Divider,
  H5,
  Tab,
  Tabs,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";
import ItemCardList from "../components/ItemCardList";
import LinkTag from "../components/LinkTag";
import MarketInfo from "../components/MarketInfo";
import MarketEngagementActualsCard from "../components/MarketEngagementActualsCard";
import MarketProposalAverageMarginCard from "../components/MarketProposalAverageMarginCard";
import MarketProposalPipelineCard from "../components/MarketProposalPipelineCard";
import MarketProposalTotalValueCard from "../components/MarketProposalTotalValueCard";
import NeedInfo from "../components/NeedInfo";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";

// Redux action imports
// Market
import {
  createMarketAction,
  listMarketsAction,
  resetCreateMarketAction,
  resetListMarketDetailsAction,
} from "../actions/marketActions";

// Need
import {
  createNeedAction,
  listNeedsAction,
  resetCreateNeedAction,
  resetListNeedDetailsAction,
} from "../actions/needActions";

// Main function
function MarketListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Market
  const marketCreate = useSelector((state) => state.marketCreate);

  const marketList = useSelector((state) => state.marketList);

  // Need
  const needCreate = useSelector((state) => state.needCreate);

  const needList = useSelector((state) => state.needList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("markets-tab");

  useEffect(() => {
    const href = window.location.href;

    if (href.includes("#")) {
      setSelectedTab("needs-tab");
    }
  }, []);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Markets
  const [addMarketDrawerIsOpen, setAddMarketDrawerIsOpen] = useState(false);

  // Needs
  const [addNeedDrawerIsOpen, setAddNeedDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Market
  const marketQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [marketQuery, setMarketQuery] = useState({
    ...marketQueryDefault,
  });

  // Need
  const needQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [needQuery, setNeedQuery] = useState({
    ...needQueryDefault,
  });

  // Form variables
  // Market
  const marketDefault = {
    description: "",
    name: "",
  };
  const [market, setMarket] = useState({
    ...marketDefault,
  });

  // Need
  const needDefault = {
    description: "",
    name: "",
  };
  const [need, setNeed] = useState({
    ...needDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Market
    setMarket({
      ...marketDefault,
    });

    // Need
    setNeed({
      ...needDefault,
    });
  };

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter markets
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listMarketsAction({ ...marketQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketQuery]);

  // Filter needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listNeedsAction({ ...needQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuery]);

  // Form submission
  // Add market
  const addMarket = () => {
    // Prepare data
    const market_data = new FormData();

    market.description
      ? market_data.append("description", market.description)
      : market_data.append("description", marketDefault.description);

    market.name
      ? market_data.append("name", market.name)
      : market_data.append("name", marketDefault.name);

    market_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createMarketAction(market_data));
  };

  // Add need
  const addNeed = () => {
    // Prepare data
    const need_data = new FormData();

    need.description
      ? need_data.append("description", need.description)
      : need_data.append("description", needDefault.description);

    need.name
      ? need_data.append("name", need.name)
      : need_data.append("name", needDefault.name);

    need_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createNeedAction(need_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* New market form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Describe the market</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Market name */}
                <FormDrawerTextField2
                  error={marketCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Market name"
                  labelInfo="(required)"
                  placeholder="Enter the market's name"
                  setValueObject={setMarket}
                  setValueVariable="name"
                  value={market.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={marketCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the market"
              setValueObject={setMarket}
              setValueVariable="description"
              value={market.description}
            />
          </Card>
        }
        error={marketCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add market"
            module="Markets"
            view="List"
          />
        }
        helpTitle="Creating a market"
        icon="edit"
        isOpen={addMarketDrawerIsOpen}
        onSave={addMarket}
        refreshList={() => {
          dispatch(
            listMarketsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateMarketAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketCreate.success}
        saveSuccessMessage="Market created."
        saving={marketCreate.loading}
        setIsOpen={setAddMarketDrawerIsOpen}
        title="Add market"
      />

      {/* New need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Describe the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need name */}
                <FormDrawerTextField2
                  error={needCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Need name"
                  labelInfo="(required)"
                  placeholder="Enter the need's name"
                  setValueObject={setNeed}
                  setValueVariable="name"
                  value={need.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={needCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the need"
              setValueObject={setNeed}
              setValueVariable="description"
              value={need.description}
            />
          </Card>
        }
        error={needCreate.error}
        helpContent={
          <FormDrawerHelpContainer form="Add need" module="Needs" view="List" />
        }
        helpTitle="Creating a need"
        icon="edit"
        isOpen={addNeedDrawerIsOpen}
        onSave={addNeed}
        refreshList={() => {
          dispatch(
            listNeedsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={needCreate.success}
        saveSuccessMessage="Need created."
        saving={needCreate.loading}
        setIsOpen={setAddNeedDrawerIsOpen}
        title="Add need"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                selectedTab === "markets-tab"
                  ? setAddMarketDrawerIsOpen(true)
                  : setAddNeedDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Markets"
      />

      {/* Error alerts */}
      {/* Markets loading error */}
      {marketList.error && (
        <ErrorAlert message="We couldn't load the market list. Try refreshing the page." />
      )}
      {/* Needs loading error */}
      {needList.error && (
        <ErrorAlert message="We couldn't load the need list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Markets"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="market-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Markets */}
            <Tab
              id="markets-tab"
              title="Markets"
              panel={
                <div>
                  <div style={{ padding: "0 10px 0 10px" }}>
                    <div
                      style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}
                    >
                      {/* Data cards */}
                      <div style={{ margin: "-15px -20px 0 -20px" }}>
                        <DataCardContainer
                          children={
                            <>
                              {/* Pipeline */}
                              <MarketProposalPipelineCard
                                fill
                                marketList={marketList}
                                showInsights
                              />

                              {/* Total value of proposals */}
                              <MarketProposalTotalValueCard
                                marketList={marketList}
                                marketQuery={marketQuery}
                                showInsights
                              />

                              {/* Average margin */}
                              <MarketProposalAverageMarginCard
                                fill
                                marketList={marketList}
                                showInsights
                              />

                              {/* Engagement actuals */}
                              <MarketEngagementActualsCard
                                fill
                                marketList={marketList}
                              />
                            </>
                          }
                          fill
                        />
                      </div>

                      {/* Item list */}
                      <div
                        style={{ margin: "20px 0 20px 10px", width: "300px" }}
                      >
                        <SearchField
                          placeholder="Search market"
                          searching={marketList.loading}
                          setValueObject={setMarketQuery}
                          setValueVariable="searchString"
                          value={marketQuery.searchString}
                        />
                      </div>

                      <ItemCardList
                        content={marketList.markets.map((market) => {
                          return (
                            <ItemCard
                              content={
                                <div>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Tag
                                        intent={
                                          market.status === "Approved"
                                            ? "success"
                                            : "primary"
                                        }
                                        minimal
                                        style={{
                                          marginRight: "5px",
                                        }}
                                      >
                                        {market.status}
                                      </Tag>

                                      {!market.user_has_permission ? (
                                        <Tag
                                          intent="danger"
                                          minimal
                                          style={{ margin: "0 0 0 5px" }}
                                        >
                                          No access
                                        </Tag>
                                      ) : market.user_access_status ===
                                        "view" ? (
                                        <Tag
                                          intent="primary"
                                          minimal
                                          style={{ margin: "0 0 0 5px" }}
                                        >
                                          View only
                                        </Tag>
                                      ) : undefined}
                                    </div>

                                    <InfoPopup
                                      content={
                                        <MarketInfo
                                          hideLink
                                          marketData={market}
                                        />
                                      }
                                    />
                                  </div>

                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <img
                                      alt="profile"
                                      src={
                                        market.manager_profile_photo
                                          ? market.manager_profile_photo
                                          : "/images/no_photo.png"
                                      }
                                      style={{
                                        background: "white",
                                        border: `1px solid ${
                                          lightMode
                                            ? Colors.LIGHT_GRAY5
                                            : Colors.DARK_GRAY5
                                        }`,
                                        borderRadius: "50%",
                                        height: "40px",
                                        margin: "0 10px 0 0",
                                        objectFit: "contain",
                                        width: "40px",
                                      }}
                                    />

                                    <Text ellipsize>{market.manager_name}</Text>
                                  </div>
                                </div>
                              }
                              darkColor={Colors.TURQUOISE3}
                              height={market.manager ? 155 : 95}
                              icon="heatmap"
                              interactive
                              key={market.id}
                              lightColor={Colors.TURQUOISE3}
                              onClick={() => {
                                if (market.user_has_permission) {
                                  dispatch(resetListMarketDetailsAction());

                                  navigate(`/markets/${market.id}`);
                                } else {
                                  AppToaster.show({
                                    icon: "warning-sign",
                                    intent: "danger",
                                    message:
                                      "You do not have access to this market.",
                                  });
                                }
                              }}
                              title={market.name}
                            />
                          );
                        })}
                        count={marketList.count}
                        loading={marketList.loading}
                        noMargin
                        page={marketQuery.page}
                        setPageObject={setMarketQuery}
                        setPageVariable="page"
                      />
                    </div>
                  </div>
                </div>
              }
            />

            {/* Needs */}
            <Tab
              id="needs-tab"
              title="Needs"
              panel={
                <div style={{ padding: "0 10px 0 10px" }}>
                  <Callout intent="primary">
                    You can define and maintain a generic set of client needs
                    here. After you create a market in the{" "}
                    <LinkTag
                      onClick={() => {
                        setSelectedTab("markets-tab");
                      }}
                      label="markets"
                    />{" "}
                    tab, you can assign one or more needs to it.
                  </Callout>

                  <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
                    <div style={{ margin: "20px 0 20px 10px", width: "300px" }}>
                      <SearchField
                        placeholder="Search need"
                        searching={needList.loading}
                        setValueObject={setNeedQuery}
                        setValueVariable="searchString"
                        value={needQuery.searchString}
                      />
                    </div>

                    <ItemCardList
                      content={needList.needs.map((need) => {
                        return (
                          <ItemCard
                            content={
                              <div>
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        need.status === "Approved"
                                          ? "success"
                                          : "primary"
                                      }
                                      minimal
                                      style={{
                                        marginRight: "5px",
                                      }}
                                    >
                                      {need.status}
                                    </Tag>

                                    {!need.user_has_permission ? (
                                      <Tag
                                        intent="danger"
                                        minimal
                                        style={{ margin: "0 0 0 5px" }}
                                      >
                                        No access
                                      </Tag>
                                    ) : need.user_access_status === "view" ? (
                                      <Tag
                                        intent="primary"
                                        minimal
                                        style={{ margin: "0 0 0 5px" }}
                                      >
                                        View only
                                      </Tag>
                                    ) : undefined}
                                  </div>

                                  {false && (
                                    <InfoPopup
                                      content={
                                        <>
                                          <H5>{need.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Description</strong>
                                          </p>

                                          <p>
                                            {need.description ? (
                                              need.description
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                    />
                                  )}
                                  <InfoPopup
                                    content={
                                      <NeedInfo
                                        hideLink
                                        needData={need}
                                      />
                                    }
                                  />
                                </div>

                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    marginTop: "20px",
                                  }}
                                >
                                  <img
                                    alt="profile"
                                    src={
                                      need.manager_profile_photo
                                        ? need.manager_profile_photo
                                        : "/images/no_photo.png"
                                    }
                                    style={{
                                      background: "white",
                                      border: `1px solid ${
                                        lightMode
                                          ? Colors.LIGHT_GRAY5
                                          : Colors.DARK_GRAY5
                                      }`,
                                      borderRadius: "50%",
                                      height: "40px",
                                      margin: "0 10px 0 0",
                                      objectFit: "contain",
                                      width: "40px",
                                    }}
                                  />

                                  <Text ellipsize>{need.manager_name}</Text>
                                </div>
                              </div>
                            }
                            darkColor={Colors.TURQUOISE3}
                            height={need.manager ? 155 : 95}
                            icon="clean"
                            interactive
                            key={need.id}
                            lightColor={Colors.TURQUOISE3}
                            onClick={() => {
                              if (need.user_has_permission) {
                                dispatch(resetListNeedDetailsAction());

                                navigate(`/needs/${need.id}`);
                              } else {
                                AppToaster.show({
                                  icon: "warning-sign",
                                  intent: "danger",
                                  message:
                                    "You do not have access to this need.",
                                });
                              }
                            }}
                            title={need.name}
                          />
                        );
                      })}
                      count={needList.count}
                      loading={needList.loading}
                      noMargin
                      page={needQuery.page}
                      setPageObject={setNeedQuery}
                      setPageVariable="page"
                    />
                  </div>
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default MarketListScreen;
