// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
  Text,
  UL,
} from "@blueprintjs/core";
import ExcelJS from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Component imports
import ActionDialog from "../components/ActionDialog";
import AICreditBalanceCard from "../components/AICreditBalanceCard";
import AICreditTransactionPercentageCard from "../components/AICreditTransactionPercentageCard";
import AICreditTransactionTotalCard from "../components/AICreditTransactionTotalCard";
import { AppToaster } from "../components/AppToaster";
import ApprovalDetailDrawer from "../components/ApprovalDetailDrawer";
import BookingCodeBudgetCard from "../components/BookingCodeBudgetCard";
import BookingCodeInfo from "../components/BookingCodeInfo";
import BookingCodeMarginCard from "../components/BookingCodeMarginCard";
import BookingCodeWIPCard from "../components/BookingCodeWIPCard";
import BookingCodeProfitLossCard from "../components/BookingCodeProfitLossCard";
import BuyButton from "../components/BuyButton";
import CompetencyInfo from "../components/CompetencyInfo";
import DataCardContainer from "../components/DataCardContainer";
import EditableList from "../components/EditableList";
import EditableTableContainer from "../components/EditableTableContainer";
import EngagementInfo from "../components/EngagementInfo";
import ErrorAlert from "../components/ErrorAlert";
import ErrorList from "../components/ErrorList";
import ExpenseCategoryInfo from "../components/ExpenseCategoryInfo";
import FormDrawerApprovalRoleCard from "../components/FormDrawerApprovalRoleCard";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerDurationField from "../components/FormDrawerDurationField";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import FormDrawerTimeField from "../components/FormDrawerTimeField";
import GenerateButton from "../components/GenerateButton";
import HelpDialog from "../components/HelpDialog";
import IconSquare from "../components/IconSquare";
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";
import ItemCardList from "../components/ItemCardList";
import KnowledgeBaseDocumentInfo from "../components/KnowledgeBaseDocumentInfo";
import LeaveCategoryInfo from "../components/LeaveCategoryInfo";
import LinkTag from "../components/LinkTag";
import LocationInfo from "../components/LocationInfo";
import ManageAccountButton from "../components/ManageAccountButton";
import MemberCard from "../components/MemberCard";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NumericValue from "../components/NumericValue";
import PercentageValue from "../components/PercentageValue";
import ProposalInfo from "../components/ProposalInfo";
import RankInfo from "../components/RankInfo";
import SearchField from "../components/SearchField";
import SubscribeCTAOrganisationCard from "../components/SubscribeCTAOrganisationCard";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TabsContainer from "../components/TabsContainer";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabSection2 from "../components/TabSection2";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCollapse from "../components/TabSectionFieldCollapse";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import generateYearList from "../utilities/generateYearList";
import getDjangoDate from "../utilities/getDjangoDate";
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// AI credit transaction
import { listAICreditTransactionsAction } from "../actions/aiCreditTransactionActions";

// Approval request
import {
  downloadApprovalRequestsAction,
  listApprovalRequestsAction,
  listApprovalRequestDetailsAction,
  resetDownloadApprovalRequestsAction,
} from "../actions/approvalRequestActions";

// Approval role
import {
  createApprovalRoleAction,
  deleteApprovalRoleAction,
  listApprovalRolesAction,
  listApprovalRoleDetailsAction,
  resetCreateApprovalRoleAction,
  resetDeleteApprovalRoleAction,
  resetUpdateApprovalRoleAction,
  updateApprovalRoleAction,
} from "../actions/approvalRoleActions";

// Booking code
import {
  createBookingCodeAction,
  deleteBookingCodeAction,
  listBookingCodesAction,
  listBookingCodeDetailsAction,
  resetCreateBookingCodeAction,
  resetDeleteBookingCodeAction,
  resetUpdateBookingCodeAction,
  updateBookingCodeAction,
} from "../actions/bookingCodeActions";

// Client
import {
  downloadClientsAction,
  listClientsAction,
  resetDownloadClientsAction,
  resetUploadClientsAction,
  uploadClientsAction,
} from "../actions/clientActions";

// Competency
import {
  createCompetencyAction,
  deleteCompetencyAction,
  downloadCompetenciesAction,
  listCompetenciesAction,
  listCompetencyDetailsAction,
  resetCreateCompetencyAction,
  resetDeleteCompetencyAction,
  resetDownloadCompetenciesAction,
  resetUpdateCompetencyAction,
  resetUploadCompetenciesAction,
  updateCompetencyAction,
  uploadCompetenciesAction,
} from "../actions/competencyActions";

// Contact
import {
  downloadContactsAction,
  listContactsAction,
  resetDownloadContactsAction,
  resetUploadContactsAction,
  uploadContactsAction,
} from "../actions/contactActions";

// Currency
import {
  createCurrencyAction,
  deleteCurrencyAction,
  getCurrencyChoicesCurrencyAction,
  getRateCurrencyAction,
  listCurrenciesAction,
  listCurrencyDetailsAction,
  resetCreateCurrencyAction,
  resetDeleteCurrencyAction,
  resetGetRateCurrencyAction,
} from "../actions/currencyActions";

// Currency exchange rate
import {
  createCurrencyExchangeRateAction,
  deleteCurrencyExchangeRateAction,
  listCurrencyExchangeRatesAction,
  listCurrencyExchangeRateDetailsAction,
  resetCreateCurrencyExchangeRateAction,
  resetDeleteCurrencyExchangeRateAction,
  resetUpdateCurrencyExchangeRateAction,
  updateCurrencyExchangeRateAction,
} from "../actions/currencyExchangeRateActions";

// Engagement
import { listEngagementsAction } from "../actions/engagementActions";

// Expense category
import {
  createExpenseCategoryAction,
  deleteExpenseCategoryAction,
  downloadExpenseCategoriesAction,
  listExpenseCategoriesAction,
  listExpenseCategoryDetailsAction,
  resetCreateExpenseCategoryAction,
  resetDeleteExpenseCategoryAction,
  resetDownloadExpenseCategoriesAction,
  resetUpdateExpenseCategoryAction,
  resetUploadExpenseCategoriesAction,
  updateExpenseCategoryAction,
  uploadExpenseCategoriesAction,
} from "../actions/expenseCategoryActions";

// HubSpot
import {
  hubspotAuthoriseAction,
  hubspotDeauthoriseAction,
  hubspotSyncContactsAction,
} from "../actions/hubspotActions";

// Invoice
import {
  downloadInvoicesAction,
  listInvoicesAction,
  resetDownloadInvoicesAction,
} from "../actions/invoiceActions";

// Leave category
import {
  createLeaveCategoryAction,
  deleteLeaveCategoryAction,
  downloadLeaveCategoriesAction,
  listLeaveCategoriesAction,
  listLeaveCategoryDetailsAction,
  resetCreateLeaveCategoryAction,
  resetDeleteLeaveCategoryAction,
  resetDownloadLeaveCategoriesAction,
  resetUpdateLeaveCategoryAction,
  resetUploadLeaveCategoriesAction,
  updateLeaveCategoryAction,
  uploadLeaveCategoriesAction,
} from "../actions/leaveCategoryActions";

// Location
import {
  createLocationAction,
  deleteLocationAction,
  listLocationsAction,
  listLocationDetailsAction,
  resetCreateLocationAction,
  resetDeleteLocationAction,
  resetUpdateLocationAction,
  updateLocationAction,
} from "../actions/locationActions";

// Knowledge base document
import {
  createKnowledgeBaseDocumentAction,
  deleteKnowledgeBaseDocumentAction,
  listKnowledgeBaseDocumentsAction,
  listKnowledgeBaseDocumentDetailsAction,
  resetCreateKnowledgeBaseDocumentAction,
  resetDeleteKnowledgeBaseDocumentAction,
  resetUpdateKnowledgeBaseDocumentAction,
  updateKnowledgeBaseDocumentAction,
} from "../actions/knowledgeBaseDocumentActions";

// Manager
import {
  listManagersAction,
  listManagerDetailsAction,
  resetUpdateManagerAction,
  updateManagerAction,
} from "../actions/managerActions";

// Membership
import {
  createMembershipAction,
  deleteMembershipAction,
  listMembershipsAction,
  listMembershipDetailsAction,
  resetCreateMembershipAction,
  resetDeleteMembershipAction,
  resetUpdateMembershipAction,
  updateMembershipAction,
} from "../actions/membershipActions";

// Organisation
import {
  checkSubscriptionAction,
  deleteOrganisationAction,
  listOrganisationDetailsAction,
  resetDeleteOrganisationAction,
  resetListOrganisationsAction,
  resetUpdateOrganisationAction,
  updateOrganisationAction,
} from "../actions/organisationActions";

// Proposal
import { listProposalsAction } from "../actions/proposalActions";

// Rank
import {
  createRankAction,
  deleteRankAction,
  listRanksAction,
  listRankDetailsAction,
  resetCreateRankAction,
  resetDeleteRankAction,
  resetUpdateRankAction,
  updateRankAction,
} from "../actions/rankActions";

// Rank cost rate
import {
  createRankCostRateAction,
  deleteRankCostRateAction,
  listRankCostRatesAction,
  listRankCostRateDetailsAction,
  resetCreateRankCostRateAction,
  resetDeleteRankCostRateAction,
  resetUpdateRankCostRateAction,
  updateRankCostRateAction,
} from "../actions/rankCostRateActions";

// Reimbursement
import {
  downloadReimbursementsAction,
  listReimbursementsAction,
  listReimbursementDetailsAction,
  resetDownloadReimbursementsAction,
  resetUpdateReimbursementAction,
  updateReimbursementAction,
} from "../actions/reimbursementActions";

// Staff
import { listStaffAction } from "../actions/staffActions";

// Seat
import {
  listSeatsAction,
  listSeatDetailsAction,
  resetUpdateSeatAction,
  updateSeatAction,
} from "../actions/seatActions";

// Main function
function OrganisationDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Organisation id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // AI credit transaction
  const aiCreditTransactionList = useSelector(
    (state) => state.aiCreditTransactionList
  );

  // Approval request
  const approvalRequestDetails = useSelector(
    (state) => state.approvalRequestDetails
  );

  const approvalRequestDownload = useSelector(
    (state) => state.approvalRequestDownload
  );

  const approvalRequestList = useSelector((state) => state.approvalRequestList);

  // Approval role
  const approvalRoleCreate = useSelector((state) => state.approvalRoleCreate);

  const approvalRoleDelete = useSelector((state) => state.approvalRoleDelete);

  const approvalRoleDetails = useSelector((state) => state.approvalRoleDetails);

  const approvalRoleList = useSelector((state) => state.approvalRoleList);

  const approvalRoleUpdate = useSelector((state) => state.approvalRoleUpdate);

  // Booking code
  const bookingCodeCreate = useSelector((state) => state.bookingCodeCreate);

  const bookingCodeDelete = useSelector((state) => state.bookingCodeDelete);

  const bookingCodeDetails = useSelector((state) => state.bookingCodeDetails);

  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  const bookingCodeUpdate = useSelector((state) => state.bookingCodeUpdate);

  // Client
  const clientDownload = useSelector((state) => state.clientDownload);

  const clientList = useSelector((state) => state.clientList);

  const clientUpload = useSelector((state) => state.clientUpload);

  // Competency
  const competencyCreate = useSelector((state) => state.competencyCreate);

  const competencyDelete = useSelector((state) => state.competencyDelete);

  const competencyDetails = useSelector((state) => state.competencyDetails);

  const competencyDownload = useSelector((state) => state.competencyDownload);

  const competencyList = useSelector((state) => state.competencyList);

  const competencyUpdate = useSelector((state) => state.competencyUpdate);

  const competencyUpload = useSelector((state) => state.competencyUpload);

  // Contact
  const contactDownload = useSelector((state) => state.contactDownload);

  const contactList = useSelector((state) => state.contactList);

  const contactUpload = useSelector((state) => state.contactUpload);

  // Currency
  const currencyCreate = useSelector((state) => state.currencyCreate);

  const currencyDelete = useSelector((state) => state.currencyDelete);

  const currencyDetails = useSelector((state) => state.currencyDetails);

  const currencyGetCurrencyChoices = useSelector(
    (state) => state.currencyGetCurrencyChoices
  );

  const currencyGetRate = useSelector((state) => state.currencyGetRate);

  const currencyList = useSelector((state) => state.currencyList);

  // Currency exchange rate
  const currencyExchangeRateCreate = useSelector(
    (state) => state.currencyExchangeRateCreate
  );

  const currencyExchangeRateDelete = useSelector(
    (state) => state.currencyExchangeRateDelete
  );

  const currencyExchangeRateDetails = useSelector(
    (state) => state.currencyExchangeRateDetails
  );

  const currencyExchangeRateList = useSelector(
    (state) => state.currencyExchangeRateList
  );

  const currencyExchangeRateUpdate = useSelector(
    (state) => state.currencyExchangeRateUpdate
  );

  // Engagement
  const engagementList = useSelector((state) => state.engagementList);

  // Expense category
  const expenseCategoryCreate = useSelector(
    (state) => state.expenseCategoryCreate
  );

  const expenseCategoryDelete = useSelector(
    (state) => state.expenseCategoryDelete
  );

  const expenseCategoryDetails = useSelector(
    (state) => state.expenseCategoryDetails
  );

  const expenseCategoryDownload = useSelector(
    (state) => state.expenseCategoryDownload
  );

  const expenseCategoryList = useSelector((state) => state.expenseCategoryList);

  const expenseCategoryUpdate = useSelector(
    (state) => state.expenseCategoryUpdate
  );

  const expenseCategoryUpload = useSelector(
    (state) => state.expenseCategoryUpload
  );

  // HubSpot
  const hubspotAuthorise = useSelector((state) => state.hubspotAuthorise);

  const hubspotDeauthorise = useSelector((state) => state.hubspotDeauthorise);

  const hubspotContactSync = useSelector((state) => state.hubspotContactSync);

  // Invoice
  const invoiceDownload = useSelector((state) => state.invoiceDownload);

  const invoiceList = useSelector((state) => state.invoiceList);

  // Leave category
  const leaveCategoryCreate = useSelector((state) => state.leaveCategoryCreate);

  const leaveCategoryDelete = useSelector((state) => state.leaveCategoryDelete);

  const leaveCategoryDetails = useSelector(
    (state) => state.leaveCategoryDetails
  );

  const leaveCategoryDownload = useSelector(
    (state) => state.leaveCategoryDownload
  );

  const leaveCategoryList = useSelector((state) => state.leaveCategoryList);

  const leaveCategoryUpdate = useSelector((state) => state.leaveCategoryUpdate);

  const leaveCategoryUpload = useSelector((state) => state.leaveCategoryUpload);

  // Location
  const locationCreate = useSelector((state) => state.locationCreate);

  const locationDelete = useSelector((state) => state.locationDelete);

  const locationDetails = useSelector((state) => state.locationDetails);

  const locationList = useSelector((state) => state.locationList);

  const locationUpdate = useSelector((state) => state.locationUpdate);

  // Knowledge base document
  const knowledgeBaseDocumentCreate = useSelector(
    (state) => state.knowledgeBaseDocumentCreate
  );

  const knowledgeBaseDocumentDelete = useSelector(
    (state) => state.knowledgeBaseDocumentDelete
  );

  const knowledgeBaseDocumentDetails = useSelector(
    (state) => state.knowledgeBaseDocumentDetails
  );

  const knowledgeBaseDocumentList = useSelector(
    (state) => state.knowledgeBaseDocumentList
  );

  const knowledgeBaseDocumentUpdate = useSelector(
    (state) => state.knowledgeBaseDocumentUpdate
  );

  // Manager
  const managerDetails = useSelector((state) => state.managerDetails);

  const managerList = useSelector((state) => state.managerList);

  const managerUpdate = useSelector((state) => state.managerUpdate);

  // Membership
  const membershipCreate = useSelector((state) => state.membershipCreate);

  const membershipDelete = useSelector((state) => state.membershipDelete);

  const membershipDetails = useSelector((state) => state.membershipDetails);

  const membershipList = useSelector((state) => state.membershipList);

  const membershipUpdate = useSelector((state) => state.membershipUpdate);

  // Organisation
  const checkSubscription = useSelector((state) => state.checkSubscription);

  const organisationDelete = useSelector((state) => state.organisationDelete);

  const organisationDetails = useSelector((state) => state.organisationDetails);

  const organisationUpdate = useSelector((state) => state.organisationUpdate);

  // Proposal
  const proposalList = useSelector((state) => state.proposalList);

  // Rank
  const rankCreate = useSelector((state) => state.rankCreate);

  const rankDelete = useSelector((state) => state.rankDelete);

  const rankDetails = useSelector((state) => state.rankDetails);

  const rankList = useSelector((state) => state.rankList);

  const rankUpdate = useSelector((state) => state.rankUpdate);

  // Rank cost rate
  const rankCostRateCreate = useSelector((state) => state.rankCostRateCreate);

  const rankCostRateDelete = useSelector((state) => state.rankCostRateDelete);

  const rankCostRateDetails = useSelector((state) => state.rankCostRateDetails);

  const rankCostRateList = useSelector((state) => state.rankCostRateList);

  const rankCostRateUpdate = useSelector((state) => state.rankCostRateUpdate);

  // Reimbursement
  const reimbursementDetails = useSelector(
    (state) => state.reimbursementDetails
  );

  const reimbursementDownload = useSelector(
    (state) => state.reimbursementDownload
  );

  const reimbursementList = useSelector((state) => state.reimbursementList);

  const reimbursementUpdate = useSelector((state) => state.reimbursementUpdate);

  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Seat
  const seatDetails = useSelector((state) => state.seatDetails);

  const seatList = useSelector((state) => state.seatList);

  const seatUpdate = useSelector((state) => state.seatUpdate);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("organisation-admin-tab");

  useEffect(() => {
    const href = window.location.href;

    if (href.includes("#")) {
      setSelectedTab("organisation-subscription-tab");
    }
  }, []);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Memberships
  const [addMembershipDrawerIsOpen, setAddMembershipDrawerIsOpen] =
    useState(false);
  const [updateMembershipDrawerIsOpen, setUpdateMembershipDrawerIsOpen] =
    useState(false);

  // CRM integration
  const [
    updateCrmIntegrationDrawerIsOpen,
    setUpdateCrmIntegrationDrawerIsOpen,
  ] = useState(false);

  // Upload clients
  const [uploadClientsDialogIsOpen, setUploadClientsDialogIsOpen] =
    useState(false);

  // Upload contacts
  const [uploadContactsDialogIsOpen, setUploadContactsDialogIsOpen] =
    useState(false);

  // Seats
  const [updateSeatDrawerIsOpen, setUpdateSeatDrawerIsOpen] = useState(false);

  // Financials
  const [updateFinancialsDrawerIsOpen, setUpdateFinancialsDrawerIsOpen] =
    useState(false);

  // Currencies
  const [addCurrencyDrawerIsOpen, setAddCurrencyDrawerIsOpen] = useState(false);
  const [updateCurrencyDrawerIsOpen, setUpdateCurrencyDrawerIsOpen] =
    useState(false);

  // Expense categories
  const [addExpenseCategoryDrawerIsOpen, setAddExpenseCategoryDrawerIsOpen] =
    useState(false);
  const [
    updateExpenseCategoryDrawerIsOpen,
    setUpdateExpenseCategoryDrawerIsOpen,
  ] = useState(false);

  // Upload expense categories
  const [
    uploadExpenseCategoriesDialogIsOpen,
    setUploadExpenseCategoriesDialogIsOpen,
  ] = useState(false);

  // Leave categories
  const [addLeaveCategoryDrawerIsOpen, setAddLeaveCategoryDrawerIsOpen] =
    useState(false);
  const [updateLeaveCategoryDrawerIsOpen, setUpdateLeaveCategoryDrawerIsOpen] =
    useState(false);

  // Upload leave categories
  const [
    uploadLeaveCategoriesDialogIsOpen,
    setUploadLeaveCategoriesDialogIsOpen,
  ] = useState(false);

  // Ranks
  const [addRankDrawerIsOpen, setAddRankDrawerIsOpen] = useState(false);
  const [updateRankDrawerIsOpen, setUpdateRankDrawerIsOpen] = useState(false);

  // Booking codes
  const [addBookingCodeDrawerIsOpen, setAddBookingCodeDrawerIsOpen] =
    useState(false);
  const [updateBookingCodeDrawerIsOpen, setUpdateBookingCodeDrawerIsOpen] =
    useState(false);

  // Reimbursements
  const [updateReimbursementDrawerIsOpen, setUpdateReimbursementDrawerIsOpen] =
    useState(false);

  // Approval roles
  const [addApprovalRoleDrawerIsOpen, setAddApprovalRoleDrawerIsOpen] =
    useState(false);
  const [updateApprovalRoleDrawerIsOpen, setUpdateApprovalRoleDrawerIsOpen] =
    useState(false);

  // Managers
  const [updateManagerDrawerIsOpen, setUpdateManagerDrawerIsOpen] =
    useState(false);

  // Approval requests
  const [
    viewProposalQualificationApprovalDrawerIsOpen,
    setViewProposalQualificationApprovalDrawerIsOpen,
  ] = useState(false);

  // Knowledge base documents
  const [
    addKnowledgeBaseDocumentDrawerIsOpen,
    setAddKnowledgeBaseDocumentDrawerIsOpen,
  ] = useState(false);
  const [
    updateKnowledgeBaseDocumentDrawerIsOpen,
    setUpdateKnowledgeBaseDocumentDrawerIsOpen,
  ] = useState(false);

  // Competencies
  const [addCompetencyDrawerIsOpen, setAddCompetencyDrawerIsOpen] =
    useState(false);
  const [updateCompetencyDrawerIsOpen, setUpdateCompetencyDrawerIsOpen] =
    useState(false);

  // Upload competencies
  const [uploadCompetenciesDialogIsOpen, setUploadCompetenciesDialogIsOpen] =
    useState(false);

  // Locations
  const [addLocationDrawerIsOpen, setAddLocationDrawerIsOpen] = useState(false);
  const [updateLocationDrawerIsOpen, setUpdateLocationDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // AI credit transaction
  const aiCreditTransactionQueryDefault = {
    organisation: id,
    page: 1,
    sortField: "",
    staff: "",
    timestampEnd: "",
    timestampStart: "",
  };
  const [aiCreditTransactionQuery, setAICreditTransactionQuery] = useState({
    ...aiCreditTransactionQueryDefault,
  });

  // Approval request
  const approvalRequestQueryDefault = {
    approver: "",
    decisionRight: "",
    itemId: "",
    itemType: "",
    organisation: id,
    outcome: "",
    page: 1,
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalRequestQuery, setApprovalRequestQuery] = useState({
    ...approvalRequestQueryDefault,
  });

  // Approval role
  const approvalRoleQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [approvalRoleQuery, setApprovalRoleQuery] = useState({
    ...approvalRoleQueryDefault,
  });

  // Booking code
  const bookingCodeQueryDefault = {
    billable: "",
    engagement: "",
    holding: false,
    open: "",
    organisation: id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Competency
  const competencyQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [competencyQuery, setCompetencyQuery] = useState({
    ...competencyQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery] = useState({
    ...contactQueryDefault,
  });

  // Currency
  const currencyQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [currencyQuery, setCurrencyQuery] = useState({
    ...currencyQueryDefault,
  });

  // Currency exchange rate
  const currencyExchangeRateQueryDefault = {
    currency: "",
    page: 1,
  };
  const [currencyExchangeRateQuery, setCurrencyExchangeRateQuery] = useState({
    ...currencyExchangeRateQueryDefault,
  });

  // Engagement
  const engagementQueryDefault = {
    client: "",
    manager: "",
    organisation: id,
    page: 1,
    searchString: "",
    sortField: "",
    sponsor: "",
    status: "",
    user: "",
  };
  const [engagementQuery, setEngagementQuery] = useState({
    ...engagementQueryDefault,
  });

  // Expense category
  const expenseCategoryQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [expenseCategoryQuery, setExpenseCategoryQuery] = useState({
    ...expenseCategoryQueryDefault,
  });

  // Invoice
  const invoiceQueryDefault = {
    bookingCode: "",
    client: "",
    dueEnd: "",
    dueStart: "",
    issuedEnd: "",
    issuedStart: "",
    manager: "",
    organisation: id,
    page: 1,
    paidEnd: "",
    paidStart: "",
    searchString: "",
    sortField: "",
    status: "",
  };
  const [invoiceQuery, setInvoiceQuery] = useState({
    ...invoiceQueryDefault,
  });

  // Leave category
  const leaveCategoryQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [leaveCategoryQuery, setLeaveCategoryQuery] = useState({
    ...leaveCategoryQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Knowledge base document
  const knowledgeBaseDocumentQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [knowledgeBaseDocumentQuery, setKnowledgeBaseDocumentQuery] = useState({
    ...knowledgeBaseDocumentQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Membership
  const membershipQueryDefault = {
    member: "",
    organisation: id,
    page: 1,
  };
  const [membershipQuery, setMembershipQuery] = useState({
    ...membershipQueryDefault,
  });

  // Proposal
  const proposalQueryDefault = {
    client: "",
    contact: "",
    dueEnd: "",
    dueStart: "",
    exclude: "",
    manager: "",
    organisation: id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
    unlinked: "",
    user: "",
  };
  const [proposalQuery, setProposalQuery] = useState({
    ...proposalQueryDefault,
  });

  // Rank
  const rankQueryDefault = {
    organisation: id,
    page: 1,
    searchString: "",
  };
  const [rankQuery, setRankQuery] = useState({
    ...rankQueryDefault,
  });

  // Rank cost rate
  const rankCostRateQueryDefault = {
    page: 1,
    rank: "",
  };
  const [rankCostRateQuery, setRankCostRateQuery] = useState({
    ...rankCostRateQueryDefault,
  });

  // Reimbursement
  const reimbursementQueryDefault = {
    organisation: id,
    page: 1,
    paidEnd: "",
    paidStart: "",
    searchString: "",
    sortField: "",
    staff: "",
    status: "",
  };
  const [reimbursementQuery, setReimbursementQuery] = useState({
    ...reimbursementQueryDefault,
  });

  // Seat
  const seatQueryDefault = {
    expiresAtEnd: "",
    expiresAtStart: "",
    member: "",
    organisation: id,
    page: 1,
    sortField: "",
    status: "",
  };
  const [seatQuery, setSeatQuery] = useState({
    ...seatQueryDefault,
  });

  // Staff
  const staffQueryDefault = {
    bookingProfile: "",
    competencies: "",
    employment: "",
    freeTime: "",
    level: "",
    location: "",
    manager: "",
    organisation: id,
    page: 1,
    role: "",
    searchString: "",
    staff: "",
    utilisationScore: "",
  };
  const [staffQuery, setStaffQuery] = useState({
    ...staffQueryDefault,
  });

  // Form variables
  // Approval role
  const approvalRoleDefault = {
    canApproveClientAccountPlan: false,
    canApproveClientAccountPlanThreshold: 0,
    canApproveClientSurvey: false,
    canApproveClientSurveyThreshold: 0,
    canApproveEngagementBaseline: false,
    canApproveEngagementBaselineThreshold: 0,
    canApproveEngagementClose: false,
    canApproveEngagementCloseThreshold: 0,
    canApproveEngagementDeliverable: false,
    canApproveEngagementDeliverableThreshold: 0,
    canApproveInvoice: false,
    canApproveInvoiceThreshold: 0,
    canApproveProposalCommercials: false,
    canApproveProposalCommercialsThreshold: 0,
    canApproveProposalQualification: false,
    canApproveProposalQualificationThreshold: 0,
    canApproveProposalSolution: false,
    canApproveProposalSolutionThreshold: 0,
    canApproveProposalStrategy: false,
    canApproveProposalStrategyThreshold: 0,
    canApproveProposalSubmission: false,
    canApproveProposalSubmissionThreshold: 0,
    canApproveStaffExpense: false,
    canApproveStaffExpenseThreshold: 0,
    canApproveStaffLeave: false,
    canApproveStaffLeaveThreshold: 0,
    canApproveStaffRecruitment: false,
    canApproveStaffRecruitmentThreshold: 0,
    canApproveStaffTimesheet: false,
    canApproveStaffTimesheetThreshold: 0,
    name: "",
  };
  const [approvalRole, setApprovalRole] = useState({
    ...approvalRoleDefault,
  });

  // Booking code
  const bookingCodeDefault = {
    billable: false,
    budget: 0,
    comments: "",
    engagement: "",
    engagementName: "",
    fixedPrice: false,
    leaveCategory: "",
    leaveCategoryName: "",
    name: "",
    open: true,
    proposal: "",
    proposalName: "",
    tasks: [],
    year: new Date().getFullYear(),
  };
  const [bookingCode, setBookingCode] = useState({
    ...bookingCodeDefault,
  });

  // Client
  const clientDefault = {
    about: "",
    address: "",
    name: "",
    website: "",
  };

  const clientUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [clientUploadFile, setClientUploadFile] = useState({
    ...clientUploadFileDefault,
  });

  // Competency
  const competencyDefault = {
    description: "",
    name: "",
  };
  const [competency, setCompetency] = useState({
    ...competencyDefault,
  });

  const competencyUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [competencyUploadFile, setCompetencyUploadFile] = useState({
    ...competencyUploadFileDefault,
  });

  // Contact
  const contactDefault = {
    email: "",
    linkedinPage: "",
    name: "",
    notes: "",
    phone: "",
    role: "",
  };

  const contactUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [contactUploadFile, setContactUploadFile] = useState({
    ...contactUploadFileDefault,
  });

  // Currency
  const currencyDefault = {
    name: "British Pound",
  };
  const [currency, setCurrency] = useState({
    ...currencyDefault,
  });

  // Currency exchange rate
  const currencyExchangeRateDefault = {
    currency: "",
    exchangeRate: "",
    periodStart: "",
  };
  const [currencyExchangeRate, setCurrencyExchangeRate] = useState({
    ...currencyExchangeRateDefault,
  });

  // Expense category
  const expenseCategoryDefault = {
    code: "",
    description: "",
    name: "",
  };
  const [expenseCategory, setExpenseCategory] = useState({
    ...expenseCategoryDefault,
  });

  const expenseCategoryUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [expenseCategoryUploadFile, setExpenseCategoryUploadFile] = useState({
    ...expenseCategoryUploadFileDefault,
  });

  // Leave category
  const leaveCategoryDefault = {
    code: "",
    description: "",
    name: "",
  };
  const [leaveCategory, setLeaveCategory] = useState({
    ...leaveCategoryDefault,
  });

  const leaveCategoryUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [leaveCategoryUploadFile, setLeaveCategoryUploadFile] = useState({
    ...leaveCategoryUploadFileDefault,
  });

  // Location
  const locationDefault = {
    address: "",
    name: "",
  };
  const [location, setLocation] = useState({
    ...locationDefault,
  });

  // Knowledge base document
  const knowledgeBaseDocumentDefault = {
    category: "Other",
    file: "",
    fileName: "",
    name: "",
    summary: "",
  };
  const [knowledgeBaseDocument, setKnowledgeBaseDocument] = useState({
    ...knowledgeBaseDocumentDefault,
  });

  // Manager
  const managerDefault = {
    approvalRole: "",
    approvalRoleName: "",
  };
  const [manager, setManager] = useState({
    ...managerDefault,
  });

  // Membership
  const membershipDefault = {
    accessDelivery: false,
    accessMarketing: false,
    accessPartners: false,
    accessPeople: false,
    accessSales: false,
    accessStrategy: false,
    admin: false,
    emailConfirmation: "",
    member: "",
  };
  const [membership, setMembership] = useState({
    ...membershipDefault,
  });

  // Organisation
  const organisationDefault = {
    about: "",
    address: "",
    crm: "None",
    currency: "US Dollar",
    email: "",
    grossMargin: 50,
    logo: "",
    logoName: "",
    minutesPerDay: 480,
    name: "",
    owner: "",
    ownerEmail: "",
    phone: "",
    salesTaxRate: 0,
    syncContactsExternalToInternal: true,
    syncContactsInternalToExternal: true,
    tagline: "",
    website: "",
  };
  const [organisation, setOrganisation] = useState({
    ...organisationDefault,
  });

  // Rank
  const rankDefault = {
    description: "",
    name: "",
  };
  const [rank, setRank] = useState({
    ...rankDefault,
  });

  // Rank cost rate
  const rankCostRateDefault = {
    averageSalary: "",
    billableMinutes: "",
    overheadAllocation: "",
    periodStart: "",
    rank: "",
  };
  const [rankCostRate, setRankCostRate] = useState({
    ...rankCostRateDefault,
  });

  // Reimbursement
  const reimbursementDefault = {
    comments: "",
    paid: "",
    status: "Not paid",
    value: 0,
  };
  const [reimbursement, setReimbursement] = useState({
    ...reimbursementDefault,
  });

  // Seat
  const seatDefault = {
    memberEmail: "",
    membership: "",
  };
  const [seat, setSeat] = useState({
    ...seatDefault,
  });

  // Other
  const [organisationDeleteKey, setOrganisationDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval role
    setApprovalRole({
      ...approvalRoleDefault,
    });

    // Booking code
    setBookingCode({
      ...bookingCodeDefault,
    });

    // Competency
    setCompetency({
      ...competencyDefault,
    });

    // Currency
    setCurrency({
      ...currencyDefault,
    });

    // Currency exchange rate
    setCurrencyExchangeRate({
      ...currencyExchangeRateDefault,
    });

    setEditCurrencyExchangeRateId(null);
    setShowNewCurrencyExchangeRateForm(false);

    // Expense category
    setExpenseCategory({
      ...expenseCategoryDefault,
    });

    // Leave category
    setLeaveCategory({
      ...leaveCategoryDefault,
    });

    // Location
    setLocation({
      ...locationDefault,
    });

    // Knowledge base document
    setKnowledgeBaseDocument({
      ...knowledgeBaseDocumentDefault,
    });

    // Manager
    setManager({
      ...managerDefault,
    });

    // Membership
    setMembership({
      ...membershipDefault,
    });

    // Organisation
    setOrganisation({
      ...organisationDefault,
    });

    // Rank
    setRank({
      ...rankDefault,
    });

    // Rank cost rate
    setRankCostRate({
      ...rankCostRateDefault,
    });

    setEditRankCostRateId(null);
    setShowNewRankCostRateForm(false);

    // Reimbursement
    setReimbursement({
      ...reimbursementDefault,
    });

    // Seat
    setSeat({
      ...seatDefault,
    });

    // Query variables
    // Engagement
    setEngagementQuery({
      ...engagementQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Proposal
    setProposalQuery({
      ...proposalQueryDefault,
    });

    // Staff
    setStaffQuery({
      ...staffQueryDefault,
    });
  };

  // Streaming data websocket connection
  const { lastJsonMessage } = useWebSocket(
    `${BACKEND_WS_URL}/api/v1/data/organisation/${id}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  // Initialise drawer variables for approval role
  useEffect(() => {
    setApprovalRole({
      canApproveClientAccountPlan:
        approvalRoleDetails.approval_role.can_approve_client_account_plan !=
        null
          ? approvalRoleDetails.approval_role.can_approve_client_account_plan
          : approvalRoleDefault.canApproveClientAccountPlan,
      canApproveClientAccountPlanThreshold: approvalRoleDetails.approval_role
        .can_approve_client_account_plan_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_client_account_plan_threshold
        : approvalRoleDefault.canApproveClientAccountPlanThreshold,
      canApproveClientSurvey:
        approvalRoleDetails.approval_role.can_approve_client_survey != null
          ? approvalRoleDetails.approval_role.can_approve_client_survey
          : approvalRoleDefault.canApproveClientSurvey,
      canApproveClientSurveyThreshold: approvalRoleDetails.approval_role
        .can_approve_client_survey_threshold
        ? approvalRoleDetails.approval_role.can_approve_client_survey_threshold
        : approvalRoleDefault.canApproveClientSurveyThreshold,
      canApproveEngagementBaseline:
        approvalRoleDetails.approval_role.can_approve_engagement_baseline !=
        null
          ? approvalRoleDetails.approval_role.can_approve_engagement_baseline
          : approvalRoleDefault.canApproveEngagementBaseline,
      canApproveEngagementBaselineThreshold: approvalRoleDetails.approval_role
        .can_approve_engagement_baseline_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_engagement_baseline_threshold
        : approvalRoleDefault.canApproveEngagementBaselineThreshold,
      canApproveEngagementClose:
        approvalRoleDetails.approval_role.can_approve_engagement_close != null
          ? approvalRoleDetails.approval_role.can_approve_engagement_close
          : approvalRoleDefault.canApproveEngagementClose,
      canApproveEngagementCloseThreshold: approvalRoleDetails.approval_role
        .can_approve_engagement_close_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_engagement_close_threshold
        : approvalRoleDefault.canApproveEngagementCloseThreshold,
      canApproveEngagementDeliverable:
        approvalRoleDetails.approval_role.can_approve_engagement_deliverable !=
        null
          ? approvalRoleDetails.approval_role.can_approve_engagement_deliverable
          : approvalRoleDefault.canApproveEngagementDeliverable,
      canApproveEngagementDeliverableThreshold: approvalRoleDetails
        .approval_role.can_approve_engagement_deliverable_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_engagement_deliverable_threshold
        : approvalRoleDefault.canApproveEngagementDeliverableThreshold,
      canApproveInvoice:
        approvalRoleDetails.approval_role.can_approve_invoice != null
          ? approvalRoleDetails.approval_role.can_approve_invoice
          : approvalRoleDefault.canApproveInvoice,
      canApproveInvoiceThreshold: approvalRoleDetails.approval_role
        .can_approve_invoice_threshold
        ? approvalRoleDetails.approval_role.can_approve_invoice_threshold
        : approvalRoleDefault.canApproveInvoiceThreshold,
      canApproveProposalCommercials:
        approvalRoleDetails.approval_role.can_approve_proposal_commercials !=
        null
          ? approvalRoleDetails.approval_role.can_approve_proposal_commercials
          : approvalRoleDefault.canApproveProposalCommercials,
      canApproveProposalCommercialsThreshold: approvalRoleDetails.approval_role
        .can_approve_proposal_commercials_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_proposal_commercials_threshold
        : approvalRoleDefault.canApproveProposalCommercialsThreshold,
      canApproveProposalQualification:
        approvalRoleDetails.approval_role.can_approve_proposal_qualification !=
        null
          ? approvalRoleDetails.approval_role.can_approve_proposal_qualification
          : approvalRoleDefault.canApproveProposalQualification,
      canApproveProposalQualificationThreshold: approvalRoleDetails
        .approval_role.can_approve_proposal_qualification_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_proposal_qualification_threshold
        : approvalRoleDefault.canApproveProposalQualificationThreshold,
      canApproveProposalSolution:
        approvalRoleDetails.approval_role.can_approve_proposal_solution != null
          ? approvalRoleDetails.approval_role.can_approve_proposal_solution
          : approvalRoleDefault.canApproveProposalSolution,
      canApproveProposalSolutionThreshold: approvalRoleDetails.approval_role
        .can_approve_proposal_solution_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_proposal_solution_threshold
        : approvalRoleDefault.canApproveProposalSolutionThreshold,
      canApproveProposalStrategy:
        approvalRoleDetails.approval_role.can_approve_proposal_strategy != null
          ? approvalRoleDetails.approval_role.can_approve_proposal_strategy
          : approvalRoleDefault.canApproveProposalStrategy,
      canApproveProposalStrategyThreshold: approvalRoleDetails.approval_role
        .can_approve_proposal_strategy_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_proposal_strategy_threshold
        : approvalRoleDefault.canApproveProposalStrategyThreshold,
      canApproveProposalSubmission:
        approvalRoleDetails.approval_role.can_approve_proposal_submission !=
        null
          ? approvalRoleDetails.approval_role.can_approve_proposal_submission
          : approvalRoleDefault.canApproveProposalSubmission,
      canApproveProposalSubmissionThreshold: approvalRoleDetails.approval_role
        .can_approve_proposal_submission_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_proposal_submission_threshold
        : approvalRoleDefault.canApproveProposalSubmissionThreshold,
      canApproveStaffExpense:
        approvalRoleDetails.approval_role.can_approve_staff_expense != null
          ? approvalRoleDetails.approval_role.can_approve_staff_expense
          : approvalRoleDefault.canApproveStaffExpense,
      canApproveStaffExpenseThreshold: approvalRoleDetails.approval_role
        .can_approve_staff_expense_threshold
        ? approvalRoleDetails.approval_role.can_approve_staff_expense_threshold
        : approvalRoleDefault.canApproveStaffExpenseThreshold,
      canApproveStaffLeave:
        approvalRoleDetails.approval_role.can_approve_staff_leave != null
          ? approvalRoleDetails.approval_role.can_approve_staff_leave
          : approvalRoleDefault.canApproveStaffLeave,
      canApproveStaffLeaveThreshold: approvalRoleDetails.approval_role
        .can_approve_staff_leave_threshold
        ? approvalRoleDetails.approval_role.can_approve_staff_leave_threshold
        : approvalRoleDefault.canApproveStaffLeaveThreshold,
      canApproveStaffRecruitment:
        approvalRoleDetails.approval_role.can_approve_staff_recruitment != null
          ? approvalRoleDetails.approval_role.can_approve_staff_recruitment
          : approvalRoleDefault.canApproveStaffRecruitment,
      canApproveStaffRecruitmentThreshold: approvalRoleDetails.approval_role
        .can_approve_staff_recruitment_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_staff_recruitment_threshold
        : approvalRoleDefault.canApproveStaffRecruitmentThreshold,
      canApproveStaffTimesheet:
        approvalRoleDetails.approval_role.can_approve_staff_timesheet != null
          ? approvalRoleDetails.approval_role.can_approve_staff_timesheet
          : approvalRoleDefault.canApproveStaffTimesheet,
      canApproveStaffTimesheetThreshold: approvalRoleDetails.approval_role
        .can_approve_staff_timesheet_threshold
        ? approvalRoleDetails.approval_role
            .can_approve_staff_timesheet_threshold
        : approvalRoleDefault.canApproveStaffTimesheetThreshold,
      name: approvalRoleDetails.approval_role.name
        ? approvalRoleDetails.approval_role.name
        : approvalRoleDefault.name,
    });
    // eslint-disable-next-line
  }, [approvalRoleDetails.approval_role]);

  // Initialise drawer variables for booking code
  useEffect(() => {
    setBookingCode({
      billable:
        bookingCodeDetails.booking_code.billable != null
          ? bookingCodeDetails.booking_code.billable
          : bookingCodeDefault.billable,
      budget: bookingCodeDetails.booking_code.budget
        ? bookingCodeDetails.booking_code.budget
        : bookingCodeDefault.budget,
      comments: bookingCodeDetails.booking_code.comments
        ? bookingCodeDetails.booking_code.comments
        : bookingCodeDefault.comments,
      engagement: bookingCodeDetails.booking_code.engagement
        ? bookingCodeDetails.booking_code.engagement
        : bookingCodeDefault.engagement,
      engagementName: bookingCodeDetails.booking_code.engagement_data
        ? bookingCodeDetails.booking_code.engagement_data.name
        : bookingCodeDefault.engagementName,
      fixedPrice: bookingCodeDetails.booking_code.fixed_price
        ? bookingCodeDetails.booking_code.fixed_price
        : bookingCodeDefault.fixedPrice,
      leaveCategory: bookingCodeDetails.booking_code.leave_category
        ? bookingCodeDetails.booking_code.leave_category
        : bookingCodeDefault.leaveCategory,
      leaveCategoryName: bookingCodeDetails.booking_code.leave_category_name
        ? bookingCodeDetails.booking_code.leave_category_name
        : bookingCodeDefault.leaveCategoryName,
      name: bookingCodeDetails.booking_code.name
        ? bookingCodeDetails.booking_code.name
        : bookingCodeDefault.name,
      open:
        bookingCodeDetails.booking_code.open != null
          ? bookingCodeDetails.booking_code.open
          : bookingCodeDefault.open,
      proposal: bookingCodeDetails.booking_code.proposal
        ? bookingCodeDetails.booking_code.proposal
        : bookingCodeDefault.proposal,
      proposalName: bookingCodeDetails.booking_code.proposal_data
        ? bookingCodeDetails.booking_code.proposal_data.name
        : bookingCodeDefault.proposalName,
      tasks: bookingCodeDetails.booking_code.tasks
        ? bookingCodeDetails.booking_code.tasks
        : bookingCodeDefault.tasks,
      year: bookingCodeDetails.booking_code.year
        ? bookingCodeDetails.booking_code.year
        : bookingCodeDefault.year,
    });
    // eslint-disable-next-line
  }, [bookingCodeDetails.booking_code]);

  // Initialise drawer variables for competency
  useEffect(() => {
    setCompetency({
      description: competencyDetails.competency.description
        ? competencyDetails.competency.description
        : competencyDefault.description,
      name: competencyDetails.competency.name
        ? competencyDetails.competency.name
        : competencyDefault.name,
    });
    // eslint-disable-next-line
  }, [competencyDetails.competency]);

  // Initialise drawer variables for currency
  useEffect(() => {
    setCurrency({
      name: currencyDetails.currency.name
        ? currencyDetails.currency.name
        : currencyDefault.name,
    });
    // eslint-disable-next-line
  }, [currencyDetails.currency]);

  // Initialise drawer variables for currency exchange rate
  useEffect(() => {
    setCurrencyExchangeRate({
      currency: currencyExchangeRateDetails.currency_exchange_rate.currency
        ? currencyExchangeRateDetails.currency_exchange_rate.currency
        : currencyExchangeRateDefault.currency,
      exchangeRate: currencyExchangeRateDetails.currency_exchange_rate
        .exchange_rate
        ? currencyExchangeRateDetails.currency_exchange_rate.exchange_rate
        : currencyExchangeRateDefault.exchangeRate,
      periodStart: currencyExchangeRateDetails.currency_exchange_rate
        .period_start
        ? new Date(
            currencyExchangeRateDetails.currency_exchange_rate.period_start
          )
        : currencyExchangeRateDefault.periodStart,
    });
    // eslint-disable-next-line
  }, [currencyExchangeRateDetails.currency_exchange_rate]);

  // Initialise drawer variables for expense category
  useEffect(() => {
    setExpenseCategory({
      code: expenseCategoryDetails.expense_category.code
        ? expenseCategoryDetails.expense_category.code
        : expenseCategoryDefault.code,
      description: expenseCategoryDetails.expense_category.description
        ? expenseCategoryDetails.expense_category.description
        : expenseCategoryDefault.description,
      name: expenseCategoryDetails.expense_category.name
        ? expenseCategoryDetails.expense_category.name
        : expenseCategoryDefault.name,
    });
    // eslint-disable-next-line
  }, [expenseCategoryDetails.expense_category]);

  // Initialise drawer variables for leave category
  useEffect(() => {
    setLeaveCategory({
      code: leaveCategoryDetails.leave_category.code
        ? leaveCategoryDetails.leave_category.code
        : leaveCategoryDefault.code,
      description: leaveCategoryDetails.leave_category.description
        ? leaveCategoryDetails.leave_category.description
        : leaveCategoryDefault.description,
      name: leaveCategoryDetails.leave_category.name
        ? leaveCategoryDetails.leave_category.name
        : leaveCategoryDefault.name,
    });
    // eslint-disable-next-line
  }, [leaveCategoryDetails.leave_category]);

  // Initialise drawer variables for location
  useEffect(() => {
    setLocation({
      address: locationDetails.location.address
        ? locationDetails.location.address
        : locationDefault.address,
      name: locationDetails.location.name
        ? locationDetails.location.name
        : locationDefault.name,
    });
    // eslint-disable-next-line
  }, [locationDetails.location]);

  // Initialise drawer variables for knowledge base document
  useEffect(() => {
    setKnowledgeBaseDocument({
      category: knowledgeBaseDocumentDetails.knowledge_base_document.category
        ? knowledgeBaseDocumentDetails.knowledge_base_document.category
        : knowledgeBaseDocumentDefault.category,
      file: knowledgeBaseDocumentDetails.knowledge_base_document.file
        ? knowledgeBaseDocumentDetails.knowledge_base_document.file
        : knowledgeBaseDocumentDefault.file,
      fileName: knowledgeBaseDocumentDetails.knowledge_base_document.file
        ? knowledgeBaseDocumentDetails.knowledge_base_document.file
            .split("/")
            .pop()
        : knowledgeBaseDocumentDefault.fileName,
      name: knowledgeBaseDocumentDetails.knowledge_base_document.name
        ? knowledgeBaseDocumentDetails.knowledge_base_document.name
        : knowledgeBaseDocumentDefault.name,
      summary: knowledgeBaseDocumentDetails.knowledge_base_document.summary
        ? knowledgeBaseDocumentDetails.knowledge_base_document.summary
        : knowledgeBaseDocumentDefault.summary,
    });
    // eslint-disable-next-line
  }, [knowledgeBaseDocumentDetails.knowledge_base_document]);

  // Initialise drawer variables for manager
  useEffect(() => {
    setManager({
      approvalRole: managerDetails.manager.approval_role
        ? managerDetails.manager.approval_role
        : managerDefault.approvalRole,
      approvalRoleName: managerDetails.manager.approval_role_data
        ? managerDetails.manager.approval_role_data.name
        : managerDefault.approvalRoleName,
    });
    // eslint-disable-next-line
  }, [managerDetails.manager]);

  // Initialise drawer variables for membership
  useEffect(() => {
    setMembership({
      accessDelivery:
        membershipDetails.membership.access_delivery != null
          ? membershipDetails.membership.access_delivery
          : membershipDefault.accessDelivery,
      accessMarketing:
        membershipDetails.membership.access_marketing != null
          ? membershipDetails.membership.access_marketing
          : membershipDefault.accessMarketing,
      accessPartners:
        membershipDetails.membership.access_partners != null
          ? membershipDetails.membership.access_partners
          : membershipDefault.accessPartners,
      accessPeople:
        membershipDetails.membership.access_people != null
          ? membershipDetails.membership.access_people
          : membershipDefault.accessPeople,
      accessSales:
        membershipDetails.membership.access_sales != null
          ? membershipDetails.membership.access_sales
          : membershipDefault.accessSales,
      accessStrategy:
        membershipDetails.membership.access_strategy != null
          ? membershipDetails.membership.access_strategy
          : membershipDefault.accessStrategy,
      admin:
        membershipDetails.membership.admin != null
          ? membershipDetails.membership.admin
          : membershipDefault.admin,
      emailConfirmation: membershipDetails.membership.member_email
        ? membershipDetails.membership.member_email
        : membershipDefault.emailConfirmation,
      member: membershipDetails.membership.member
        ? membershipDetails.membership.member
        : membershipDefault.member,
    });
    // eslint-disable-next-line
  }, [membershipDetails.membership]);

  // Initialise drawer variables for organisation
  useEffect(() => {
    setOrganisation({
      about: organisationDetails.organisation.about
        ? organisationDetails.organisation.about
        : organisationDefault.about,
      address: organisationDetails.organisation.address
        ? organisationDetails.organisation.address
        : organisationDefault.address,
      crm: organisationDetails.organisation.crm
        ? organisationDetails.organisation.crm
        : organisationDefault.crm,
      currency: organisationDetails.organisation.currency
        ? organisationDetails.organisation.currency
        : organisationDefault.currency,
      email: organisationDetails.organisation.email
        ? organisationDetails.organisation.email
        : organisationDefault.email,
      grossMargin: organisationDetails.organisation.gross_margin
        ? organisationDetails.organisation.gross_margin
        : organisationDefault.grossMargin,
      logo: organisationDetails.organisation.logo
        ? organisationDetails.organisation.logo
        : organisationDefault.logo,
      logoName: organisationDetails.organisation.logo
        ? organisationDetails.organisation.logo.split("/").pop()
        : organisationDefault.logoName,
      minutesPerDay: organisationDetails.organisation.minutes_per_day
        ? organisationDetails.organisation.minutes_per_day
        : organisationDefault.minutesPerDay,
      name: organisationDetails.organisation.name
        ? organisationDetails.organisation.name
        : organisationDefault.name,
      owner: organisationDetails.organisation.owner
        ? organisationDetails.organisation.owner
        : organisationDefault.owner,
      ownerEmail: organisationDetails.organisation.owner_email
        ? organisationDetails.organisation.owner_email
        : organisationDefault.ownerEmail,
      phone: organisationDetails.organisation.phone
        ? organisationDetails.organisation.phone
        : organisationDefault.phone,
      salesTaxRate: organisationDetails.organisation.sales_tax_rate
        ? organisationDetails.organisation.sales_tax_rate
        : organisationDefault.salesTaxRate,
      syncContactsExternalToInternal:
        organisationDetails.organisation.sync_contacts_external_to_internal !=
        null
          ? organisationDetails.organisation.sync_contacts_external_to_internal
          : organisationDefault.syncContactsExternalToInternal,
      syncContactsInternalToExternal:
        organisationDetails.organisation.sync_contacts_internal_to_external !=
        null
          ? organisationDetails.organisation.sync_contacts_internal_to_external
          : organisationDefault.syncContactsInternalToExternal,
      tagline: organisationDetails.organisation.tagline
        ? organisationDetails.organisation.tagline
        : organisationDefault.tagline,
      website: organisationDetails.organisation.website
        ? organisationDetails.organisation.website
        : organisationDefault.website,
    });
    // eslint-disable-next-line
  }, [organisationDetails.organisation]);

  // Initialise drawer variables for rank
  useEffect(() => {
    setRank({
      description: rankDetails.rank.description
        ? rankDetails.rank.description
        : rankDefault.description,
      name: rankDetails.rank.name ? rankDetails.rank.name : rankDefault.name,
    });
    // eslint-disable-next-line
  }, [rankDetails.rank]);

  // Initialise drawer variables for rank cost rate
  useEffect(() => {
    setRankCostRate({
      averageSalary: rankCostRateDetails.rank_cost_rate.average_salary
        ? rankCostRateDetails.rank_cost_rate.average_salary
        : rankCostRateDefault.averageSalary,
      billableMinutes: rankCostRateDetails.rank_cost_rate.billable_minutes
        ? rankCostRateDetails.rank_cost_rate.billable_minutes
        : rankCostRateDefault.billableMinutes,
      overheadAllocation: rankCostRateDetails.rank_cost_rate.overhead_allocation
        ? rankCostRateDetails.rank_cost_rate.overhead_allocation
        : rankCostRateDefault.overheadAllocation,
      periodStart: rankCostRateDetails.rank_cost_rate.period_start
        ? new Date(rankCostRateDetails.rank_cost_rate.period_start)
        : rankCostRateDefault.periodStart,
      rank: rankCostRateDetails.rank_cost_rate.rank
        ? rankCostRateDetails.rank_cost_rate.rank
        : rankCostRateDefault.rank,
    });
    // eslint-disable-next-line
  }, [rankCostRateDetails.rank_cost_rate]);

  // Initialise drawer variables for reimbursement
  useEffect(() => {
    setReimbursement({
      comments: reimbursementDetails.reimbursement.comments
        ? reimbursementDetails.reimbursement.comments
        : reimbursementDefault.comments,
      paid: reimbursementDetails.reimbursement.paid
        ? new Date(reimbursementDetails.reimbursement.paid)
        : reimbursementDefault.paid,
      status: reimbursementDetails.reimbursement.status
        ? reimbursementDetails.reimbursement.status
        : reimbursementDefault.status,
      value: reimbursementDetails.reimbursement.value
        ? reimbursementDetails.reimbursement.value
        : reimbursementDefault.value,
    });
    // eslint-disable-next-line
  }, [reimbursementDetails.reimbursement]);

  // Initialise drawer variables for seat
  useEffect(() => {
    setSeat({
      memberEmail: seatDetails.seat.member_email
        ? seatDetails.seat.member_email
        : seatDefault.memberEmail,
      membership: seatDetails.seat.membership
        ? seatDetails.seat.membership
        : seatDefault.membership,
    });
    // eslint-disable-next-line
  }, [seatDetails.seat]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If organisation deleted redirect to organisations
  useEffect(() => {
    if (organisationDelete.success) {
      dispatch(resetDeleteOrganisationAction());

      dispatch(resetListOrganisationsAction());

      navigate("/organisations");
    }
    // eslint-disable-next-line
  }, [organisationDelete.success]);

  // Load currency data
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    dispatch(getCurrencyChoicesCurrencyAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      currencies.length === 0 &&
      currencyGetCurrencyChoices.currencies &&
      organisationDetails.organisation.currency_short
    ) {
      currencyGetCurrencyChoices.currencies
        .filter(
          (currency) =>
            currency[0] !== organisationDetails.organisation.currency_short
        )
        .map((currency) =>
          setCurrencies((prevState) => [
            ...prevState,
            {
              id: currency[0],
              icon: (
                <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                  {currency[0]}
                </Tag>
              ),
              value: currency[1],
            },
          ])
        );
    }
    // eslint-disable-next-line
  }, [
    currencyGetCurrencyChoices.success,
    organisationDetails.organisation.currency_short,
  ]);

  // Load organisation details
  useEffect(() => {
    dispatch(listOrganisationDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter AI credit transactions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listAICreditTransactionsAction({
            ...aiCreditTransactionQuery,
            timestampEnd: aiCreditTransactionQuery.timestampEnd
              ? getDjangoDate(aiCreditTransactionQuery.timestampEnd)
              : "",
            timestampStart: aiCreditTransactionQuery.timestampStart
              ? getDjangoDate(aiCreditTransactionQuery.timestampStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [aiCreditTransactionQuery]);

  // Filter approval requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalRequestsAction({
            ...approvalRequestQuery,
            reviewedEnd: approvalRequestQuery.reviewedEnd
              ? getDjangoDate(approvalRequestQuery.reviewedEnd)
              : getDjangoDate(approvalRequestQueryDefault.reviewedEnd),
            reviewedStart: approvalRequestQuery.reviewedStart
              ? getDjangoDate(approvalRequestQuery.reviewedStart)
              : getDjangoDate(approvalRequestQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRequestQuery]);

  // Filter approval roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listApprovalRolesAction({ ...approvalRoleQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRoleQuery]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listBookingCodesAction({ ...bookingCodeQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCompetenciesAction({
            ...competencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [competencyQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter engagements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementsAction({
            ...engagementQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementQuery]);

  // Filter expense categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listExpenseCategoriesAction({ ...expenseCategoryQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseCategoryQuery]);

  // Filter currencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCurrenciesAction({
            ...currencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [currencyQuery]);

  // Filter currency exchange rates
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCurrencyExchangeRatesAction({
            ...currencyExchangeRateQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [currencyExchangeRateQuery]);

  // Filter invoices
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoicesAction({
            ...invoiceQuery,
            dueEnd: invoiceQuery.dueEnd
              ? getDjangoDate(invoiceQuery.dueEnd)
              : getDjangoDate(invoiceQueryDefault.dueEnd),
            dueStart: invoiceQuery.dueStart
              ? getDjangoDate(invoiceQuery.dueStart)
              : getDjangoDate(invoiceQueryDefault.dueStart),
            issuedEnd: invoiceQuery.issuedEnd
              ? getDjangoDate(invoiceQuery.issuedEnd)
              : getDjangoDate(invoiceQueryDefault.issuedEnd),
            issuedStart: invoiceQuery.issuedStart
              ? getDjangoDate(invoiceQuery.issuedStart)
              : getDjangoDate(invoiceQueryDefault.issuedStart),
            paidEnd: invoiceQuery.paidEnd
              ? getDjangoDate(invoiceQuery.paidEnd)
              : getDjangoDate(invoiceQueryDefault.paidEnd),
            paidStart: invoiceQuery.paidStart
              ? getDjangoDate(invoiceQuery.paidStart)
              : getDjangoDate(invoiceQueryDefault.paidStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceQuery]);

  // Filter leave categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listLeaveCategoriesAction({ ...leaveCategoryQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [leaveCategoryQuery]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listLocationsAction({ ...locationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter knowledge base documents
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listKnowledgeBaseDocumentsAction({ ...knowledgeBaseDocumentQuery })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [knowledgeBaseDocumentQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter memberships
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listMembershipsAction({ ...membershipQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [membershipQuery]);

  // Filter proposals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalsAction({
            ...proposalQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuery]);

  // Filter ranks
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRanksAction({
            ...rankQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [rankQuery]);

  // Filter rank cost rates
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRankCostRatesAction({
            ...rankCostRateQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [rankCostRateQuery]);

  // Filter reimbursements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listReimbursementsAction({
            ...reimbursementQuery,
            paidEnd: reimbursementQuery.paidEnd
              ? getDjangoDate(reimbursementQuery.paidEnd)
              : "",
            paidStart: reimbursementQuery.paidStart
              ? getDjangoDate(reimbursementQuery.paidStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [reimbursementQuery]);

  // Filter seats
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listSeatsAction({
            ...seatQuery,
            expiresAtEnd: seatQuery.expiresAtEnd
              ? getDjangoDate(seatQuery.expiresAtEnd)
              : "",
            expiresAtStart: seatQuery.expiresAtStart
              ? getDjangoDate(seatQuery.expiresAtStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [seatQuery]);

  // Filter staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffAction({
            ...staffQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffQuery]);

  // Form submission
  // Approval role
  // Add approval role
  const addApprovalRole = () => {
    // Prepare data
    const approval_role_data = new FormData();

    approvalRole.canApproveClientAccountPlan != null
      ? approval_role_data.append(
          "can_approve_client_account_plan",
          approvalRole.canApproveClientAccountPlan
        )
      : approval_role_data.append(
          "can_approve_client_account_plan",
          approvalRoleDefault.canApproveClientAccountPlan
        );

    approvalRole.canApproveClientAccountPlanThreshold
      ? approval_role_data.append(
          "can_approve_client_account_plan_threshold",
          approvalRole.canApproveClientAccountPlanThreshold
        )
      : approval_role_data.append(
          "can_approve_client_account_plan_threshold",
          approvalRoleDefault.canApproveClientAccountPlanThreshold
        );

    approvalRole.canApproveClientSurvey != null
      ? approval_role_data.append(
          "can_approve_client_survey",
          approvalRole.canApproveClientSurvey
        )
      : approval_role_data.append(
          "can_approve_client_survey",
          approvalRoleDefault.canApproveClientSurvey
        );

    approvalRole.canApproveClientSurveyThreshold
      ? approval_role_data.append(
          "can_approve_client_survey_threshold",
          approvalRole.canApproveClientSurveyThreshold
        )
      : approval_role_data.append(
          "can_approve_client_survey_threshold",
          approvalRoleDefault.canApproveClientSurveyThreshold
        );

    approvalRole.canApproveEngagementBaseline != null
      ? approval_role_data.append(
          "can_approve_engagement_baseline",
          approvalRole.canApproveEngagementBaseline
        )
      : approval_role_data.append(
          "can_approve_engagement_baseline",
          approvalRoleDefault.canApproveEngagementBaseline
        );

    approvalRole.canApproveEngagementBaselineThreshold
      ? approval_role_data.append(
          "can_approve_engagement_baseline_threshold",
          approvalRole.canApproveEngagementBaselineThreshold
        )
      : approval_role_data.append(
          "can_approve_engagement_baseline_threshold",
          approvalRoleDefault.canApproveEngagementBaselineThreshold
        );

    approvalRole.canApproveEngagementClose != null
      ? approval_role_data.append(
          "can_approve_engagement_close",
          approvalRole.canApproveEngagementClose
        )
      : approval_role_data.append(
          "can_approve_engagement_close",
          approvalRoleDefault.canApproveEngagementClose
        );

    approvalRole.canApproveEngagementCloseThreshold
      ? approval_role_data.append(
          "can_approve_engagement_close_threshold",
          approvalRole.canApproveEngagementCloseThreshold
        )
      : approval_role_data.append(
          "can_approve_engagement_close_threshold",
          approvalRoleDefault.canApproveEngagementCloseThreshold
        );

    approvalRole.canApproveEngagementDeliverable != null
      ? approval_role_data.append(
          "can_approve_engagement_deliverable",
          approvalRole.canApproveEngagementDeliverable
        )
      : approval_role_data.append(
          "can_approve_engagement_deliverable",
          approvalRoleDefault.canApproveEngagementDeliverable
        );

    approvalRole.canApproveEngagementDeliverableThreshold
      ? approval_role_data.append(
          "can_approve_engagement_deliverable_threshold",
          approvalRole.canApproveEngagementDeliverableThreshold
        )
      : approval_role_data.append(
          "can_approve_engagement_deliverable_threshold",
          approvalRoleDefault.canApproveEngagementDeliverableThreshold
        );

    approvalRole.canApproveInvoice != null
      ? approval_role_data.append(
          "can_approve_invoice",
          approvalRole.canApproveInvoice
        )
      : approval_role_data.append(
          "can_approve_invoice",
          approvalRoleDefault.canApproveInvoice
        );

    approvalRole.canApproveInvoiceThreshold
      ? approval_role_data.append(
          "can_approve_invoice_threshold",
          approvalRole.canApproveInvoiceThreshold
        )
      : approval_role_data.append(
          "can_approve_invoice_threshold",
          approvalRoleDefault.canApproveInvoiceThreshold
        );

    approvalRole.canApproveProposalCommercials != null
      ? approval_role_data.append(
          "can_approve_proposal_commercials",
          approvalRole.canApproveProposalCommercials
        )
      : approval_role_data.append(
          "can_approve_proposal_commercials",
          approvalRoleDefault.canApproveProposalCommercials
        );

    approvalRole.canApproveProposalCommercialsThreshold
      ? approval_role_data.append(
          "can_approve_proposal_commercials_threshold",
          approvalRole.canApproveProposalCommercialsThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_commercials_threshold",
          approvalRoleDefault.canApproveProposalCommercialsThreshold
        );

    approvalRole.canApproveProposalQualification != null
      ? approval_role_data.append(
          "can_approve_proposal_qualification",
          approvalRole.canApproveProposalQualification
        )
      : approval_role_data.append(
          "can_approve_proposal_qualification",
          approvalRoleDefault.canApproveProposalQualification
        );

    approvalRole.canApproveProposalQualificationThreshold
      ? approval_role_data.append(
          "can_approve_proposal_qualification_threshold",
          approvalRole.canApproveProposalQualificationThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_qualification_threshold",
          approvalRoleDefault.canApproveProposalQualificationThreshold
        );

    approvalRole.canApproveProposalSolution != null
      ? approval_role_data.append(
          "can_approve_proposal_solution",
          approvalRole.canApproveProposalSolution
        )
      : approval_role_data.append(
          "can_approve_proposal_solution",
          approvalRoleDefault.canApproveProposalSolution
        );

    approvalRole.canApproveProposalSolutionThreshold
      ? approval_role_data.append(
          "can_approve_proposal_solution_threshold",
          approvalRole.canApproveProposalSolutionThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_solution_threshold",
          approvalRoleDefault.canApproveProposalSolutionThreshold
        );

    approvalRole.canApproveProposalStrategy != null
      ? approval_role_data.append(
          "can_approve_proposal_strategy",
          approvalRole.canApproveProposalStrategy
        )
      : approval_role_data.append(
          "can_approve_proposal_strategy",
          approvalRoleDefault.canApproveProposalStrategy
        );

    approvalRole.canApproveProposalStrategyThreshold
      ? approval_role_data.append(
          "can_approve_proposal_strategy_threshold",
          approvalRole.canApproveProposalStrategyThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_strategy_threshold",
          approvalRoleDefault.canApproveProposalStrategyThreshold
        );

    approvalRole.canApproveProposalSubmission != null
      ? approval_role_data.append(
          "can_approve_proposal_submission",
          approvalRole.canApproveProposalSubmission
        )
      : approval_role_data.append(
          "can_approve_proposal_submission",
          approvalRoleDefault.canApproveProposalSubmission
        );

    approvalRole.canApproveProposalSubmissionThreshold
      ? approval_role_data.append(
          "can_approve_proposal_submission_threshold",
          approvalRole.canApproveProposalSubmissionThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_submission_threshold",
          approvalRoleDefault.canApproveProposalSubmissionThreshold
        );

    approvalRole.canApproveStaffExpense != null
      ? approval_role_data.append(
          "can_approve_staff_expense",
          approvalRole.canApproveStaffExpense
        )
      : approval_role_data.append(
          "can_approve_staff_expense",
          approvalRoleDefault.canApproveStaffExpense
        );

    approvalRole.canApproveStaffExpenseThreshold
      ? approval_role_data.append(
          "can_approve_staff_expense_threshold",
          approvalRole.canApproveStaffExpenseThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_expense_threshold",
          approvalRoleDefault.canApproveStaffExpenseThreshold
        );

    approvalRole.canApproveStaffLeave != null
      ? approval_role_data.append(
          "can_approve_staff_leave",
          approvalRole.canApproveStaffLeave
        )
      : approval_role_data.append(
          "can_approve_staff_leave",
          approvalRoleDefault.canApproveStaffLeave
        );

    approvalRole.canApproveStaffLeaveThreshold
      ? approval_role_data.append(
          "can_approve_staff_leave_threshold",
          approvalRole.canApproveStaffLeaveThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_leave_threshold",
          approvalRoleDefault.canApproveStaffLeaveThreshold
        );

    approvalRole.canApproveStaffRecruitment != null
      ? approval_role_data.append(
          "can_approve_staff_recruitment",
          approvalRole.canApproveStaffRecruitment
        )
      : approval_role_data.append(
          "can_approve_staff_recruitment",
          approvalRoleDefault.canApproveStaffRecruitment
        );

    approvalRole.canApproveStaffRecruitmentThreshold
      ? approval_role_data.append(
          "can_approve_staff_recruitment_threshold",
          approvalRole.canApproveStaffRecruitmentThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_recruitment_threshold",
          approvalRoleDefault.canApproveStaffRecruitmentThreshold
        );

    approvalRole.canApproveStaffTimesheet != null
      ? approval_role_data.append(
          "can_approve_staff_timesheet",
          approvalRole.canApproveStaffTimesheet
        )
      : approval_role_data.append(
          "can_approve_staff_timesheet",
          approvalRoleDefault.canApproveStaffTimesheet
        );

    approvalRole.canApproveStaffTimesheetThreshold
      ? approval_role_data.append(
          "can_approve_staff_timesheet_threshold",
          approvalRole.canApproveStaffTimesheetThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_timesheet_threshold",
          approvalRoleDefault.canApproveStaffTimesheetThreshold
        );

    approvalRole.name
      ? approval_role_data.append("name", approvalRole.name)
      : approval_role_data.append("name", approvalRoleDefault.name);

    approval_role_data.append("organisation", id);

    // Dispatch action
    dispatch(createApprovalRoleAction(approval_role_data));
  };

  // Delete approval role
  const deleteApprovalRole = () => {
    // Dispatch action
    dispatch(deleteApprovalRoleAction(approvalRoleDetails.approval_role.id));
  };

  // Update approval role
  const updateApprovalRole = () => {
    // Prepare data
    const approval_role_data = new FormData();

    approvalRole.canApproveClientAccountPlan != null
      ? approval_role_data.append(
          "can_approve_client_account_plan",
          approvalRole.canApproveClientAccountPlan
        )
      : approval_role_data.append(
          "can_approve_client_account_plan",
          approvalRoleDefault.canApproveClientAccountPlan
        );

    approvalRole.canApproveClientAccountPlanThreshold
      ? approval_role_data.append(
          "can_approve_client_account_plan_threshold",
          approvalRole.canApproveClientAccountPlanThreshold
        )
      : approval_role_data.append(
          "can_approve_client_account_plan_threshold",
          approvalRoleDefault.canApproveClientAccountPlanThreshold
        );

    approvalRole.canApproveClientSurvey != null
      ? approval_role_data.append(
          "can_approve_client_survey",
          approvalRole.canApproveClientSurvey
        )
      : approval_role_data.append(
          "can_approve_client_survey",
          approvalRoleDefault.canApproveClientSurvey
        );

    approvalRole.canApproveClientSurveyThreshold
      ? approval_role_data.append(
          "can_approve_client_survey_threshold",
          approvalRole.canApproveClientSurveyThreshold
        )
      : approval_role_data.append(
          "can_approve_client_survey_threshold",
          approvalRoleDefault.canApproveClientSurveyThreshold
        );

    approvalRole.canApproveEngagementBaseline != null
      ? approval_role_data.append(
          "can_approve_engagement_baseline",
          approvalRole.canApproveEngagementBaseline
        )
      : approval_role_data.append(
          "can_approve_engagement_baseline",
          approvalRoleDefault.canApproveEngagementBaseline
        );

    approvalRole.canApproveEngagementBaselineThreshold
      ? approval_role_data.append(
          "can_approve_engagement_baseline_threshold",
          approvalRole.canApproveEngagementBaselineThreshold
        )
      : approval_role_data.append(
          "can_approve_engagement_baseline_threshold",
          approvalRoleDefault.canApproveEngagementBaselineThreshold
        );

    approvalRole.canApproveEngagementClose != null
      ? approval_role_data.append(
          "can_approve_engagement_close",
          approvalRole.canApproveEngagementClose
        )
      : approval_role_data.append(
          "can_approve_engagement_close",
          approvalRoleDefault.canApproveEngagementClose
        );

    approvalRole.canApproveEngagementCloseThreshold
      ? approval_role_data.append(
          "can_approve_engagement_close_threshold",
          approvalRole.canApproveEngagementCloseThreshold
        )
      : approval_role_data.append(
          "can_approve_engagement_close_threshold",
          approvalRoleDefault.canApproveEngagementCloseThreshold
        );

    approvalRole.canApproveEngagementDeliverable != null
      ? approval_role_data.append(
          "can_approve_engagement_deliverable",
          approvalRole.canApproveEngagementDeliverable
        )
      : approval_role_data.append(
          "can_approve_engagement_deliverable",
          approvalRoleDefault.canApproveEngagementDeliverable
        );

    approvalRole.canApproveEngagementDeliverableThreshold
      ? approval_role_data.append(
          "can_approve_engagement_deliverable_threshold",
          approvalRole.canApproveEngagementDeliverableThreshold
        )
      : approval_role_data.append(
          "can_approve_engagement_deliverable_threshold",
          approvalRoleDefault.canApproveEngagementDeliverableThreshold
        );

    approvalRole.canApproveInvoice != null
      ? approval_role_data.append(
          "can_approve_invoice",
          approvalRole.canApproveInvoice
        )
      : approval_role_data.append(
          "can_approve_invoice",
          approvalRoleDefault.canApproveInvoice
        );

    approvalRole.canApproveInvoiceThreshold
      ? approval_role_data.append(
          "can_approve_invoice_threshold",
          approvalRole.canApproveInvoiceThreshold
        )
      : approval_role_data.append(
          "can_approve_invoice_threshold",
          approvalRoleDefault.canApproveInvoiceThreshold
        );

    approvalRole.canApproveProposalCommercials != null
      ? approval_role_data.append(
          "can_approve_proposal_commercials",
          approvalRole.canApproveProposalCommercials
        )
      : approval_role_data.append(
          "can_approve_proposal_commercials",
          approvalRoleDefault.canApproveProposalCommercials
        );

    approvalRole.canApproveProposalCommercialsThreshold
      ? approval_role_data.append(
          "can_approve_proposal_commercials_threshold",
          approvalRole.canApproveProposalCommercialsThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_commercials_threshold",
          approvalRoleDefault.canApproveProposalCommercialsThreshold
        );

    approvalRole.canApproveProposalQualification != null
      ? approval_role_data.append(
          "can_approve_proposal_qualification",
          approvalRole.canApproveProposalQualification
        )
      : approval_role_data.append(
          "can_approve_proposal_qualification",
          approvalRoleDefault.canApproveProposalQualification
        );

    approvalRole.canApproveProposalQualificationThreshold
      ? approval_role_data.append(
          "can_approve_proposal_qualification_threshold",
          approvalRole.canApproveProposalQualificationThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_qualification_threshold",
          approvalRoleDefault.canApproveProposalQualificationThreshold
        );

    approvalRole.canApproveProposalSolution != null
      ? approval_role_data.append(
          "can_approve_proposal_solution",
          approvalRole.canApproveProposalSolution
        )
      : approval_role_data.append(
          "can_approve_proposal_solution",
          approvalRoleDefault.canApproveProposalSolution
        );

    approvalRole.canApproveProposalSolutionThreshold
      ? approval_role_data.append(
          "can_approve_proposal_solution_threshold",
          approvalRole.canApproveProposalSolutionThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_solution_threshold",
          approvalRoleDefault.canApproveProposalSolutionThreshold
        );

    approvalRole.canApproveProposalStrategy != null
      ? approval_role_data.append(
          "can_approve_proposal_strategy",
          approvalRole.canApproveProposalStrategy
        )
      : approval_role_data.append(
          "can_approve_proposal_strategy",
          approvalRoleDefault.canApproveProposalStrategy
        );

    approvalRole.canApproveProposalStrategyThreshold
      ? approval_role_data.append(
          "can_approve_proposal_strategy_threshold",
          approvalRole.canApproveProposalStrategyThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_strategy_threshold",
          approvalRoleDefault.canApproveProposalStrategyThreshold
        );

    approvalRole.canApproveProposalSubmission != null
      ? approval_role_data.append(
          "can_approve_proposal_submission",
          approvalRole.canApproveProposalSubmission
        )
      : approval_role_data.append(
          "can_approve_proposal_submission",
          approvalRoleDefault.canApproveProposalSubmission
        );

    approvalRole.canApproveProposalSubmissionThreshold
      ? approval_role_data.append(
          "can_approve_proposal_submission_threshold",
          approvalRole.canApproveProposalSubmissionThreshold
        )
      : approval_role_data.append(
          "can_approve_proposal_submission_threshold",
          approvalRoleDefault.canApproveProposalSubmissionThreshold
        );

    approvalRole.canApproveStaffExpense != null
      ? approval_role_data.append(
          "can_approve_staff_expense",
          approvalRole.canApproveStaffExpense
        )
      : approval_role_data.append(
          "can_approve_staff_expense",
          approvalRoleDefault.canApproveStaffExpense
        );

    approvalRole.canApproveStaffExpenseThreshold
      ? approval_role_data.append(
          "can_approve_staff_expense_threshold",
          approvalRole.canApproveStaffExpenseThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_expense_threshold",
          approvalRoleDefault.canApproveStaffExpenseThreshold
        );

    approvalRole.canApproveStaffLeave != null
      ? approval_role_data.append(
          "can_approve_staff_leave",
          approvalRole.canApproveStaffLeave
        )
      : approval_role_data.append(
          "can_approve_staff_leave",
          approvalRoleDefault.canApproveStaffLeave
        );

    approvalRole.canApproveStaffLeaveThreshold
      ? approval_role_data.append(
          "can_approve_staff_leave_threshold",
          approvalRole.canApproveStaffLeaveThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_leave_threshold",
          approvalRoleDefault.canApproveStaffLeaveThreshold
        );

    approvalRole.canApproveStaffRecruitment != null
      ? approval_role_data.append(
          "can_approve_staff_recruitment",
          approvalRole.canApproveStaffRecruitment
        )
      : approval_role_data.append(
          "can_approve_staff_recruitment",
          approvalRoleDefault.canApproveStaffRecruitment
        );

    approvalRole.canApproveStaffRecruitmentThreshold
      ? approval_role_data.append(
          "can_approve_staff_recruitment_threshold",
          approvalRole.canApproveStaffRecruitmentThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_recruitment_threshold",
          approvalRoleDefault.canApproveStaffRecruitmentThreshold
        );

    approvalRole.canApproveStaffTimesheet != null
      ? approval_role_data.append(
          "can_approve_staff_timesheet",
          approvalRole.canApproveStaffTimesheet
        )
      : approval_role_data.append(
          "can_approve_staff_timesheet",
          approvalRoleDefault.canApproveStaffTimesheet
        );

    approvalRole.canApproveStaffTimesheetThreshold
      ? approval_role_data.append(
          "can_approve_staff_timesheet_threshold",
          approvalRole.canApproveStaffTimesheetThreshold
        )
      : approval_role_data.append(
          "can_approve_staff_timesheet_threshold",
          approvalRoleDefault.canApproveStaffTimesheetThreshold
        );

    approvalRole.name
      ? approval_role_data.append("name", approvalRole.name)
      : approval_role_data.append("name", approvalRoleDefault.name);

    // Dispatch action
    dispatch(
      updateApprovalRoleAction(
        approvalRoleDetails.approval_role.id,
        approval_role_data
      )
    );
  };

  // Booking code
  // Add booking code
  const addBookingCode = () => {
    // Prepare data
    const booking_code_data = new FormData();

    bookingCode.billable != null
      ? booking_code_data.append("billable", bookingCode.billable)
      : booking_code_data.append("billable", bookingCodeDefault.billable);

    bookingCode.budget
      ? booking_code_data.append("budget", bookingCode.budget)
      : booking_code_data.append("budget", bookingCodeDefault.budget);

    bookingCode.comments
      ? booking_code_data.append("comments", bookingCode.comments)
      : booking_code_data.append("comments", bookingCodeDefault.comments);

    bookingCode.engagement
      ? booking_code_data.append("engagement", bookingCode.engagement)
      : booking_code_data.append("engagement", bookingCodeDefault.engagement);

    bookingCode.fixedPrice != null
      ? booking_code_data.append("fixed_price", bookingCode.fixedPrice)
      : booking_code_data.append("fixed_price", bookingCodeDefault.fixedPrice);

    bookingCode.leaveCategory
      ? booking_code_data.append("leave_category", bookingCode.leaveCategory)
      : booking_code_data.append(
          "leave_category",
          bookingCodeDefault.leaveCategory
        );

    bookingCode.name
      ? booking_code_data.append("name", bookingCode.name)
      : booking_code_data.append("name", bookingCodeDefault.name);

    bookingCode.open != null
      ? booking_code_data.append("open", bookingCode.open)
      : booking_code_data.append("open", bookingCodeDefault.open);

    booking_code_data.append("organisation", id);

    bookingCode.proposal
      ? booking_code_data.append("proposal", bookingCode.proposal)
      : booking_code_data.append("proposal", bookingCodeDefault.proposal);

    bookingCode.tasks
      ? booking_code_data.append("tasks", JSON.stringify(bookingCode.tasks))
      : booking_code_data.append(
          "tasks",
          JSON.stringify(bookingCodeDefault.tasks)
        );

    bookingCode.year
      ? booking_code_data.append("year", bookingCode.year)
      : booking_code_data.append("year", bookingCodeDefault.year);

    // Dispatch action
    dispatch(createBookingCodeAction(booking_code_data));
  };

  // Delete booking code
  const deleteBookingCode = () => {
    // Dispatch action
    dispatch(deleteBookingCodeAction(bookingCodeDetails.booking_code.id));
  };

  // Update booking code
  const updateBookingCode = () => {
    // Prepare data
    const booking_code_data = new FormData();

    bookingCode.billable != null
      ? booking_code_data.append("billable", bookingCode.billable)
      : booking_code_data.append("billable", bookingCodeDefault.billable);

    bookingCode.budget
      ? booking_code_data.append("budget", bookingCode.budget)
      : booking_code_data.append("budget", bookingCodeDefault.budget);

    bookingCode.comments
      ? booking_code_data.append("comments", bookingCode.comments)
      : booking_code_data.append("comments", bookingCodeDefault.comments);

    bookingCode.engagement
      ? booking_code_data.append("engagement", bookingCode.engagement)
      : booking_code_data.append("engagement", bookingCodeDefault.engagement);

    bookingCode.fixedPrice != null
      ? booking_code_data.append("fixed_price", bookingCode.fixedPrice)
      : booking_code_data.append("fixed_price", bookingCodeDefault.fixedPrice);

    bookingCode.leaveCategory
      ? booking_code_data.append("leave_category", bookingCode.leaveCategory)
      : booking_code_data.append(
          "leave_category",
          bookingCodeDefault.leaveCategory
        );

    bookingCode.name
      ? booking_code_data.append("name", bookingCode.name)
      : booking_code_data.append("name", bookingCodeDefault.name);

    bookingCode.open != null
      ? booking_code_data.append("open", bookingCode.open)
      : booking_code_data.append("open", bookingCodeDefault.open);

    bookingCode.proposal
      ? booking_code_data.append("proposal", bookingCode.proposal)
      : booking_code_data.append("proposal", bookingCodeDefault.proposal);

    bookingCode.tasks
      ? booking_code_data.append("tasks", JSON.stringify(bookingCode.tasks))
      : booking_code_data.append(
          "tasks",
          JSON.stringify(bookingCodeDefault.tasks)
        );

    bookingCode.year
      ? booking_code_data.append("year", bookingCode.year)
      : booking_code_data.append("year", bookingCodeDefault.year);

    // Dispatch action
    dispatch(
      updateBookingCodeAction(
        bookingCodeDetails.booking_code.id,
        booking_code_data
      )
    );
  };

  // Client
  // Download clients
  const downloadClients = useCallback(async () => {
    // Create workbook
    const clientWorkbook = new ExcelJS.Workbook();

    clientWorkbook.created = new Date();

    clientWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = clientWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload clients",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      ["1. You can use this spreadsheet to upload clients into advaise.app."],
      [
        "2. The tab 'Clients' contains client records that exist in your advaise.app organisation (if any). You can add to these by copying and pasting from other sources.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Name: The name of the client organization. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(c) Address: The client's address."],
      [
        "(d) Website: The client's website. You must provide the full url in the following format for the upload to succeed: 'https://www...'.",
      ],
      ["(e) About: Additional information about the client."],
      [
        "(f) Delete: A flag to delete the record. Note that you cannot delete clients by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused clients - YOU CANNOT DELETE clients that have other records, such as proposals, engagements or invoices linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const clientSheet = clientWorkbook.addWorksheet("Clients", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Clients`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style data sheet columns
    clientSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Address",
        key: "address",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Website",
        key: "website",
        style: columnStyle,
        width: 30,
      },
      {
        header: "About",
        key: "about",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    clientSheet.autoFilter = "A1:F1";

    const topRow = clientSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    clientSheet.addConditionalFormatting({
      ref: "F2:F20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    clientSheet.pageSetup.printTitlesRow = "1:6";

    // Populate data sheet data
    for (var i = 0; i < clientDownload.clients.length; i++) {
      clientSheet.addRow({
        id: clientDownload.clients[i].id,
        about: clientDownload.clients[i].about
          ? clientDownload.clients[i].about
          : "",
        address: clientDownload.clients[i].address
          ? clientDownload.clients[i].address
          : "",
        name: clientDownload.clients[i].name,
        website: clientDownload.clients[i].website
          ? clientDownload.clients[i].website
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    clientSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      clientSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await clientWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, `${organisationDetails.organisation.name} - Clients.xlsx`);
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Clients downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the clients file. Please refresh the page and try again.",
      });
    }
  }, [organisationDetails.organisation.name, clientDownload.clients]);

  useEffect(() => {
    if (clientDownload.success) {
      downloadClients();

      dispatch(resetDownloadClientsAction());
    }
    // eslint-disable-next-line
  }, [clientDownload.success]);

  // Upload clients
  const uploadClients = async () => {
    // Create workbook
    const clientWorkbook = new ExcelJS.Workbook();

    // Read file and extract data
    var records = [];
    try {
      await clientWorkbook.xlsx.load(clientUploadFile.file).then(() => {
        // Get data sheet
        const clientSheet = clientWorkbook.getWorksheet("Clients");

        // Get data
        clientSheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            records.push({
              id: row.getCell(1).value,
              name: row.getCell(2).value
                ? row.getCell(2).value
                : clientDefault.name,
              address: row.getCell(3).value
                ? row.getCell(3).value
                : clientDefault.address,
              website: row.getCell(4).value
                ? row.getCell(4).value
                : clientDefault.website,
              about: row.getCell(5).value
                ? row.getCell(5).value
                : clientDefault.about,
              delete: row.getCell(6).value,
            });
          }
        });

        // Upload data
        dispatch(uploadClientsAction(id, records));
      });
    } catch (error) {
      setClientUploadFile({
        ...clientUploadFile,
        error: true,
      });
    }
  };

  // Download clients that failed to upload
  const downloadClientsFailedUpload = useCallback(async () => {
    // Create workbook
    const clientWorkbook = new ExcelJS.Workbook();

    clientWorkbook.created = new Date();

    clientWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = clientWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to address client exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to fix client records that had exceptions and upload them into advaise.app.",
      ],
      [
        "2. The tab 'Clients' contains the client records that had exceptions. The most common issue is missing data for a field that is required. Update the client records based on the field definitions below and upload this file to address the exceptions.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Name: The name of the client organization. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(c) Address: The client's address."],
      [
        "(d) Website: The client's website. You must provide the full url in the following format for the upload to succeed: 'https://www...'.",
      ],
      ["(e) About: Additional information about the client."],
      [
        "(f) Delete: A flag to delete the record. Note that you cannot delete clients by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused clients - YOU CANNOT DELETE clients that have other records, such as proposals, engagements or invoices linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const clientSheet = clientWorkbook.addWorksheet("Clients", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Client Exceptions`,
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style data sheet columns
    clientSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Address",
        key: "address",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Website",
        key: "website",
        style: columnStyle,
        width: 30,
      },
      {
        header: "About",
        key: "about",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    clientSheet.autoFilter = "A1:F1";

    const topRow = clientSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    clientSheet.addConditionalFormatting({
      ref: "F2:F20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    clientSheet.pageSetup.printTitlesRow = "1:6";

    // Populate data sheet data
    for (var i = 0; i < clientUpload.records_failed.length; i++) {
      clientSheet.addRow({
        id: clientUpload.records_failed[i].id,
        about: clientUpload.records_failed[i].about
          ? clientUpload.records_failed[i].about
          : "",
        address: clientUpload.records_failed[i].address
          ? clientUpload.records_failed[i].address
          : "",
        name: clientUpload.records_failed[i].name,
        website: clientUpload.records_failed[i].website
          ? clientUpload.records_failed[i].website
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    clientSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      clientSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      clientSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await clientWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Client Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the client exceptions file. Please refresh the page and try again.",
      });
    }
  }, [organisationDetails.organisation.name, clientUpload.records_failed]);

  useEffect(() => {
    if (clientUpload.end) {
      clientUpload.records_failed.length > 0 && downloadClientsFailedUpload();
    }
    // eslint-disable-next-line
  }, [clientUpload.end]);

  // Competency
  // Add competency
  const addCompetency = () => {
    // Prepare data
    const competency_data = new FormData();

    competency.description
      ? competency_data.append("description", competency.description)
      : competency_data.append("description", competencyDefault.description);

    competency.name
      ? competency_data.append("name", competency.name)
      : competency_data.append("name", competencyDefault.name);

    competency_data.append("organisation", id);

    // Dispatch action
    dispatch(createCompetencyAction(competency_data));
  };

  // Delete competency
  const deleteCompetency = () => {
    // Dispatch action
    dispatch(deleteCompetencyAction(competencyDetails.competency.id));
  };

  // Download competencies
  const downloadCompetencies = useCallback(async () => {
    // Create workbook
    const competencyWorkbook = new ExcelJS.Workbook();

    competencyWorkbook.created = new Date();

    competencyWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = competencyWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload competencies",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to upload competencies into advaise.app.",
      ],
      [
        "2. The tab 'Competencies' contains competencies that exist in your advaise.app organisation (if any). You can add to these by copying and pasting from other documents or entering data manually.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Name: The name of the competency. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Description: A description of the competency."],
      [
        "(e) Delete: A flag to delete the record. Note that you cannot delete competencies by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused competencies - YOU CANNOT DELETE competencies that have roles or staff linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const competencySheet = competencyWorkbook.addWorksheet("Competencies", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Competencies`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style data sheet columns
    competencySheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Description",
        key: "description",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    competencySheet.autoFilter = "A1:D1";

    const topRow = competencySheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    competencySheet.addConditionalFormatting({
      ref: "D2:D20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    competencySheet.pageSetup.printTitlesRow = "1:5";

    // Populate data sheet data
    for (var i = 0; i < competencyDownload.competencies.length; i++) {
      competencySheet.addRow({
        id: competencyDownload.competencies[i].id,
        name: competencyDownload.competencies[i].name,
        description: competencyDownload.competencies[i].description
          ? competencyDownload.competencies[i].description
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    competencySheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      competencySheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      competencySheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      competencySheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await competencyWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Competencies.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Competencies downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the competencies file. Please refresh the page and try again.",
      });
    }
  }, [competencyDownload.competencies, organisationDetails.organisation.name]);

  useEffect(() => {
    if (competencyDownload.success) {
      downloadCompetencies();

      dispatch(resetDownloadCompetenciesAction());
    }
    // eslint-disable-next-line
  }, [competencyDownload.success]);

  // Update competency
  const updateCompetency = () => {
    // Prepare data
    const competency_data = new FormData();

    competency.description
      ? competency_data.append("description", competency.description)
      : competency_data.append("description", competencyDefault.description);

    competency.name
      ? competency_data.append("name", competency.name)
      : competency_data.append("name", competencyDefault.name);

    // Dispatch action
    dispatch(
      updateCompetencyAction(competencyDetails.competency.id, competency_data)
    );
  };

  // Upload competencies
  const uploadCompetencies = async () => {
    // Create workbook
    const competencyWorkbook = new ExcelJS.Workbook();

    // Read file and extract data
    var records = [];
    try {
      await competencyWorkbook.xlsx.load(competencyUploadFile.file).then(() => {
        // Get data sheet
        const competencySheet = competencyWorkbook.getWorksheet("Competencies");

        // Get data
        competencySheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            records.push({
              id: row.getCell(1).value,
              name: row.getCell(2).value
                ? row.getCell(2).value
                : competencyDefault.name,
              description: row.getCell(3).value
                ? row.getCell(3).value
                : competencyDefault.description,
              delete: row.getCell(4).value,
            });
          }
        });

        // Upload data
        dispatch(uploadCompetenciesAction(id, records));
      });
    } catch (error) {
      setCompetencyUploadFile({
        ...competencyUploadFile,
        error: true,
      });
    }
  };

  // Download competencies that failed to upload
  const downloadCompetenciesFailedUpload = useCallback(async () => {
    // Create workbook
    const competencyWorkbook = new ExcelJS.Workbook();

    competencyWorkbook.created = new Date();

    competencyWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = competencyWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to address competency exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to upload competencies into advaise.app.",
      ],
      [
        "2. The tab 'Competencies' contains competencies that exist in your advaise.app organisation (if any). You can add to these by copying and pasting from other documents or entering data manually.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Name: The name of the competency. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Description: A description of the competency."],
      [
        "(e) Delete: A flag to delete the record. Note that you cannot delete competencies by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused competencies - YOU CANNOT DELETE competencies that have roles or staff linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const competencySheet = competencyWorkbook.addWorksheet("Categories", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Competency Exceptions`,
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style data sheet columns
    competencySheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Description",
        key: "description",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    competencySheet.autoFilter = "A1:E1";

    const topRow = competencySheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    competencySheet.addConditionalFormatting({
      ref: "D2:D20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    competencySheet.pageSetup.printTitlesRow = "1:5";

    // Populate data sheet data
    for (var i = 0; i < competencyUpload.records_failed.length; i++) {
      competencySheet.addRow({
        id: competencyUpload.records_failed[i].id,
        name: competencyUpload.records_failed[i].name,
        description: competencyUpload.records_failed[i].description
          ? competencyUpload.records_failed[i].description
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    competencySheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      competencySheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      competencySheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      competencySheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await competencyWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Competency Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the competency exceptions file. Please refresh the page and try again.",
      });
    }
  }, [organisationDetails.organisation.name, competencyUpload.records_failed]);

  useEffect(() => {
    if (competencyUpload.end) {
      competencyUpload.records_failed.length > 0 &&
        downloadCompetenciesFailedUpload();
    }
    // eslint-disable-next-line
  }, [competencyUpload.end]);

  // Contact
  // Download contacts
  const downloadContacts = useCallback(async () => {
    // Create workbook
    const contactWorkbook = new ExcelJS.Workbook();

    contactWorkbook.created = new Date();

    contactWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = contactWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload contacts",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      ["1. You can use this spreadsheet to upload contacts into advaise.app."],
      [
        "2. The tab 'Contacts' contains contact records that exist in your advaise.app organisation (if any). You can add to these by copying and pasting from other sources.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Name: The full name of the contact (name and surname). THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(c) Phone: The phone number of the contact. You must provide a valid phone number, including the country code, for the upload to succeed.",
      ],
      [
        "(d) Email: The contact's email address. You must provide a valid email for the upload to succeed.",
      ],
      [
        "(e) LinkedIn Page: The contact's LinkedIn profile. You must provide the full url in the following format for the upload to succeed: 'https://www.linkedin.com/...'.",
      ],
      ["(f) Role: The contact's job title."],
      ["(g) Notes: Additional information about the contact."],
      [
        "(h) Delete: A flag to delete the record. Note that you cannot delete contacts by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused contacts - YOU CANNOT DELETE contacts that have other records, such as proposals, engagements, interviews or openings linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const contactSheet = contactWorkbook.addWorksheet("Contacts", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Contacts`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style data sheet columns
    contactSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Phone",
        key: "phone",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Email",
        key: "email",
        style: columnStyle,
        width: 30,
      },
      {
        header: "LinkedIn Page",
        key: "linkedinPage",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Role",
        key: "role",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Notes",
        key: "notes",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    contactSheet.autoFilter = "A1:H1";

    const topRow = contactSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    contactSheet.addConditionalFormatting({
      ref: "H2:H20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    contactSheet.pageSetup.printTitlesRow = "1:8";

    // Populate data sheet data
    for (var i = 0; i < contactDownload.contacts.length; i++) {
      contactSheet.addRow({
        id: contactDownload.contacts[i].id,
        email: contactDownload.contacts[i].email
          ? contactDownload.contacts[i].email
          : "",
        linkedinPage: contactDownload.contacts[i].linkedin_page
          ? contactDownload.contacts[i].linkedin_page
          : "",
        name: contactDownload.contacts[i].name,
        notes: contactDownload.contacts[i].notes
          ? contactDownload.contacts[i].notes
          : "",
        phone: contactDownload.contacts[i].phone
          ? contactDownload.contacts[i].phone
          : "",
        role: contactDownload.contacts[i].role
          ? contactDownload.contacts[i].role
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    contactSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      contactSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`G${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`H${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await contactWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Contacts.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Contacts downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the contacts file. Please refresh the page and try again.",
      });
    }
  }, [organisationDetails.organisation.name, contactDownload.contacts]);

  useEffect(() => {
    if (contactDownload.success) {
      downloadContacts();

      dispatch(resetDownloadContactsAction());
    }
    // eslint-disable-next-line
  }, [contactDownload.success]);

  // Upload contacts
  const uploadContacts = async () => {
    // Create workbook
    const contactWorkbook = new ExcelJS.Workbook();

    // Read file and extract data
    var records = [];
    try {
      await contactWorkbook.xlsx.load(contactUploadFile.file).then(() => {
        // Get data sheet
        const contactSheet = contactWorkbook.getWorksheet("Contacts");

        // Get data
        contactSheet.eachRow((row, rowNumber) => {
          if (rowNumber > 1) {
            records.push({
              id: row.getCell(1).value,
              name: row.getCell(2).value
                ? row.getCell(2).value
                : contactDefault.name,
              phone: row.getCell(3).value
                ? row.getCell(3).value
                : contactDefault.phone,
              email: row.getCell(4).value
                ? row.getCell(4).value
                : contactDefault.email,
              linkedin_page: row.getCell(5).value
                ? row.getCell(5).value
                : contactDefault.linkedinPage,
              role: row.getCell(6).value
                ? row.getCell(6).value
                : contactDefault.role,
              notes: row.getCell(7).value
                ? row.getCell(7).value
                : contactDefault.notes,
              delete: row.getCell(8).value,
            });
          }
        });

        // Upload data
        dispatch(uploadContactsAction(id, records));
      });
    } catch (error) {
      setContactUploadFile({
        ...contactUploadFile,
        error: true,
      });
    }
  };

  // Download contacts that failed to upload
  const downloadContactsFailedUpload = useCallback(async () => {
    // Create workbook
    const contactWorkbook = new ExcelJS.Workbook();

    contactWorkbook.created = new Date();

    contactWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = contactWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to address contact exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to fix contact records that had exceptions and upload them into advaise.app.",
      ],
      [
        "2. The tab 'Contacts' contains the contact records that had exceptions. The most common issue is missing data for a field that is required. Update the contact records based on the field definitions below and upload this file to address the exceptions.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Name: The full name of the contact (name and surname). THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(c) Phone: The phone number of the contact. You must provide a valid phone number, including the country code, for the upload to succeed.",
      ],
      [
        "(d) Email: The contact's email address. You must provide a valid email for the upload to succeed.",
      ],
      [
        "(e) LinkedIn Page: The contact's LinkedIn profile. You must provide the full url in the following format for the upload to succeed: 'https://www.linkedin.com/...'.",
      ],
      ["(f) Role: The contact's job title."],
      ["(g) Notes: Additional information about the contact."],
      [
        "(h) Delete: A flag to delete the record. Note that you cannot delete contacts by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused contacts - YOU CANNOT DELETE contacts that have other records, such as proposals, engagements, interviews or openings linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const contactSheet = contactWorkbook.addWorksheet("Contacts", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Contact Exceptions`,
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style data sheet columns
    contactSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Phone",
        key: "phone",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Email",
        key: "email",
        style: columnStyle,
        width: 30,
      },
      {
        header: "LinkedIn Page",
        key: "linkedinPage",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Role",
        key: "role",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Notes",
        key: "notes",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    contactSheet.autoFilter = "A1:H1";

    const topRow = contactSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    contactSheet.addConditionalFormatting({
      ref: "H2:H20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    contactSheet.pageSetup.printTitlesRow = "1:8";

    // Populate data sheet data
    for (var i = 0; i < contactUpload.records_failed.length; i++) {
      contactSheet.addRow({
        id: contactUpload.records_failed[i].id,
        email: contactUpload.records_failed[i].email
          ? contactUpload.records_failed[i].email
          : "",
        linkedinPage: contactUpload.records_failed[i].linkedin_page
          ? contactUpload.records_failed[i].linkedin_page
          : "",
        name: contactUpload.records_failed[i].name,
        notes: contactUpload.records_failed[i].notes
          ? contactUpload.records_failed[i].notes
          : "",
        phone: contactUpload.records_failed[i].phone
          ? contactUpload.records_failed[i].phone
          : "",
        role: contactUpload.records_failed[i].role
          ? contactUpload.records_failed[i].role
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    contactSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      contactSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`G${k + 2}`).protection = {
        locked: false,
      };

      contactSheet.getCell(`H${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await contactWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Contact Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the contact exceptions file. Please refresh the page and try again.",
      });
    }
  }, [organisationDetails.organisation.name, contactUpload.records_failed]);

  useEffect(() => {
    if (contactUpload.end) {
      contactUpload.records_failed.length > 0 && downloadContactsFailedUpload();
    }
    // eslint-disable-next-line
  }, [contactUpload.end]);

  // Currency
  const addCurrency = () => {
    // Prepare data
    const currency_data = new FormData();

    currency_data.append("organisation", id);

    currency.name
      ? currency_data.append("name", currency.name)
      : currency_data.append("name", currencyDefault.name);

    // Dispatch action
    dispatch(createCurrencyAction(currency_data));
  };

  // Delete currency
  const deleteCurrency = () => {
    // Dispatch action
    dispatch(deleteCurrencyAction(currencyDetails.currency.id));
  };

  // Update currency
  useEffect(() => {
    if (currencyCreate.success) {
      dispatch(listCurrencyDetailsAction(currencyCreate.currency.id));

      setCurrencyExchangeRateQuery({
        currency: currencyCreate.currency.id,
      });

      dispatch(resetCreateCurrencyAction());

      setUpdateCurrencyDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [currencyCreate.success]);

  // Currency exchange rate
  const [editCurrencyExchangeRateId, setEditCurrencyExchangeRateId] =
    useState(null);
  const [showNewCurrencyExchangeRateForm, setShowNewCurrencyExchangeRateForm] =
    useState(false);

  // Add currency exchange rate
  const addCurrencyExchangeRate = () => {
    // Prepare data
    const currency_exchange_rate = new FormData();

    currency_exchange_rate.append("currency", currencyDetails.currency.id);

    currencyExchangeRate.exchangeRate
      ? currency_exchange_rate.append(
          "exchange_rate",
          currencyExchangeRate.exchangeRate
        )
      : currency_exchange_rate.append(
          "exchange_rate",
          currencyExchangeRateDefault.exchangeRate
        );

    currencyExchangeRate.periodStart
      ? currency_exchange_rate.append(
          "period_start",
          getDjangoDate(currencyExchangeRate.periodStart)
        )
      : currency_exchange_rate.append(
          "period_start",
          getDjangoDate(currencyExchangeRateDefault.periodStart)
        );

    // Dispatch action
    dispatch(createCurrencyExchangeRateAction(currency_exchange_rate));
  };

  // Delete currency exchange rate
  const deleteCurrencyExchangeRate = () => {
    // Dispatch action
    dispatch(
      deleteCurrencyExchangeRateAction(
        currencyExchangeRateDetails.currency_exchange_rate.id
      )
    );
  };

  // Update currency exchange rate
  const updateCurrencyExchangeRate = () => {
    // Prepare data
    const currency_exchange_rate = new FormData();

    currencyExchangeRate.exchangeRate
      ? currency_exchange_rate.append(
          "exchange_rate",
          currencyExchangeRate.exchangeRate
        )
      : currency_exchange_rate.append(
          "exchange_rate",
          currencyExchangeRateDefault.exchangeRate
        );

    currencyExchangeRate.periodStart
      ? currency_exchange_rate.append(
          "period_start",
          getDjangoDate(currencyExchangeRate.periodStart)
        )
      : currency_exchange_rate.append(
          "period_start",
          getDjangoDate(currencyExchangeRateDefault.periodStart)
        );

    // Dispatch action
    dispatch(
      updateCurrencyExchangeRateAction(
        currencyExchangeRateDetails.currency_exchange_rate.id,
        currency_exchange_rate
      )
    );
  };

  useEffect(() => {
    if (
      currencyExchangeRateCreate.success ||
      currencyExchangeRateDelete.success ||
      currencyExchangeRateUpdate.success
    ) {
      dispatch(listCurrenciesAction({ ...currencyQuery }));
    }
    // eslint-disable-next-line
  }, [
    currencyExchangeRateCreate.success,
    currencyExchangeRateDelete.success,
    currencyExchangeRateUpdate.success,
  ]);

  // Expense category
  // Add expense category
  const addExpenseCategory = () => {
    // Prepare data
    const expense_category_data = new FormData();

    expenseCategory.code
      ? expense_category_data.append("code", expenseCategory.code)
      : expense_category_data.append("code", expenseCategoryDefault.code);

    expenseCategory.description
      ? expense_category_data.append("description", expenseCategory.description)
      : expense_category_data.append(
          "description",
          expenseCategoryDefault.description
        );

    expenseCategory.name
      ? expense_category_data.append("name", expenseCategory.name)
      : expense_category_data.append("name", expenseCategoryDefault.name);

    expense_category_data.append("organisation", id);

    // Dispatch action
    dispatch(createExpenseCategoryAction(expense_category_data));
  };

  // Delete expense category
  const deleteExpenseCategory = () => {
    // Dispatch action
    dispatch(
      deleteExpenseCategoryAction(expenseCategoryDetails.expense_category.id)
    );
  };

  // Download expense categories
  const downloadExpenseCategories = useCallback(async () => {
    // Create workbook
    const expenseCategoryWorkbook = new ExcelJS.Workbook();

    expenseCategoryWorkbook.created = new Date();

    expenseCategoryWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = expenseCategoryWorkbook.addWorksheet(
      "Instructions",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: "Instructions",
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload expense categories",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to upload expense categories from the chart of accounts into advaise.app.",
      ],
      [
        "2. The tab 'Categories' contains expense categories that exist in your advaise.app organisation (if any). You can add to these by copying and pasting from your accounting system.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      ["(b) Code: The chart of accounts expense category code."],
      [
        "(c) Name: The name of the expense category. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Description: A description of the expense category."],
      [
        "(e) Delete: A flag to delete the record. Note that you cannot delete expense categories by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused categories - YOU CANNOT DELETE categories that have expense records linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const expenseCategorySheet = expenseCategoryWorkbook.addWorksheet(
      "Categories",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: `${organisationDetails.organisation.name} - Expense Categories`,
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style data sheet columns
    expenseCategorySheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Code",
        key: "code",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Description",
        key: "description",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    expenseCategorySheet.autoFilter = "A1:E1";

    const topRow = expenseCategorySheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    expenseCategorySheet.addConditionalFormatting({
      ref: "E2:E20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    expenseCategorySheet.pageSetup.printTitlesRow = "1:5";

    // Populate data sheet data
    for (
      var i = 0;
      i < expenseCategoryDownload.expense_categories.length;
      i++
    ) {
      expenseCategorySheet.addRow({
        id: expenseCategoryDownload.expense_categories[i].id,
        code: expenseCategoryDownload.expense_categories[i].code
          ? expenseCategoryDownload.expense_categories[i].code
          : "",
        name: expenseCategoryDownload.expense_categories[i].name,
        description: expenseCategoryDownload.expense_categories[i].description
          ? expenseCategoryDownload.expense_categories[i].description
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    expenseCategorySheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      expenseCategorySheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      expenseCategorySheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      expenseCategorySheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      expenseCategorySheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await expenseCategoryWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Expense Categories.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Expense categories downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the expense categories file. Please refresh the page and try again.",
      });
    }
  }, [
    organisationDetails.organisation.name,
    expenseCategoryDownload.expense_categories,
  ]);

  useEffect(() => {
    if (expenseCategoryDownload.success) {
      downloadExpenseCategories();

      dispatch(resetDownloadExpenseCategoriesAction());
    }
    // eslint-disable-next-line
  }, [expenseCategoryDownload.success]);

  // Update expense category
  const updateExpenseCategory = () => {
    // Prepare data
    const expense_category_data = new FormData();

    expenseCategory.code
      ? expense_category_data.append("code", expenseCategory.code)
      : expense_category_data.append("code", expenseCategoryDefault.code);

    expenseCategory.description
      ? expense_category_data.append("description", expenseCategory.description)
      : expense_category_data.append(
          "description",
          expenseCategoryDefault.description
        );

    expenseCategory.name
      ? expense_category_data.append("name", expenseCategory.name)
      : expense_category_data.append("name", expenseCategoryDefault.name);

    // Dispatch action
    dispatch(
      updateExpenseCategoryAction(
        expenseCategoryDetails.expense_category.id,
        expense_category_data
      )
    );
  };

  // Upload expense categories
  const uploadExpenseCategories = async () => {
    // Create workbook
    const expenseCategoryWorkbook = new ExcelJS.Workbook();

    // Read file and extract data
    var records = [];
    try {
      await expenseCategoryWorkbook.xlsx
        .load(expenseCategoryUploadFile.file)
        .then(() => {
          // Get data sheet
          const expenseCategorySheet =
            expenseCategoryWorkbook.getWorksheet("Categories");

          // Get data
          expenseCategorySheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              records.push({
                id: row.getCell(1).value,
                code: row.getCell(2).value
                  ? row.getCell(2).value
                  : expenseCategoryDefault.code,
                name: row.getCell(3).value
                  ? row.getCell(3).value
                  : expenseCategoryDefault.name,
                description: row.getCell(4).value
                  ? row.getCell(4).value
                  : expenseCategoryDefault.description,
                delete: row.getCell(5).value,
              });
            }
          });

          // Upload data
          dispatch(uploadExpenseCategoriesAction(id, records));
        });
    } catch (error) {
      setExpenseCategoryUploadFile({
        ...expenseCategoryUploadFile,
        error: true,
      });
    }
  };

  // Download expense categories that failed to upload
  const downloadExpenseCategoriesFailedUpload = useCallback(async () => {
    // Create workbook
    const expenseCategoryWorkbook = new ExcelJS.Workbook();

    expenseCategoryWorkbook.created = new Date();

    expenseCategoryWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = expenseCategoryWorkbook.addWorksheet(
      "Instructions",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: "Instructions",
        },
        properties: { tabColor: { argb: "CD4246" } },
        views: views,
      }
    );

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header:
          "advaise.app - Instructions to address expense category exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to fix expense categories that had exceptions and upload them into advaise.app.",
      ],
      [
        "2. The tab 'Categories' contains the expense categories that had exceptions. The most common issue is missing data for a field that is required. Update the expense categories based on the field definitions below and upload this file to address the exceptions.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      ["(b) Code: The chart of accounts expense category code."],
      [
        "(c) Name: The name of the expense category. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Description: A description of the expense category."],
      [
        "(e) Delete: A flag to delete the record. Note that you cannot delete expense categories by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused categories - YOU CANNOT DELETE categories that have expense records linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const expenseCategorySheet = expenseCategoryWorkbook.addWorksheet(
      "Categories",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: `${organisationDetails.organisation.name} - Expense Category Exceptions`,
        },
        properties: { tabColor: { argb: "CD4246" } },
        views: views,
      }
    );

    // Define and style data sheet columns
    expenseCategorySheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Code",
        key: "code",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Description",
        key: "description",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    expenseCategorySheet.autoFilter = "A1:E1";

    const topRow = expenseCategorySheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    expenseCategorySheet.addConditionalFormatting({
      ref: "E2:E20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    expenseCategorySheet.pageSetup.printTitlesRow = "1:5";

    // Populate data sheet data
    for (var i = 0; i < expenseCategoryUpload.records_failed.length; i++) {
      expenseCategorySheet.addRow({
        id: expenseCategoryUpload.records_failed[i].id,
        code: expenseCategoryUpload.records_failed[i].code
          ? expenseCategoryUpload.records_failed[i].code
          : "",
        name: expenseCategoryUpload.records_failed[i].name,
        description: expenseCategoryUpload.records_failed[i].description
          ? expenseCategoryUpload.records_failed[i].description
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    expenseCategorySheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      expenseCategorySheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      expenseCategorySheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      expenseCategorySheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      expenseCategorySheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await expenseCategoryWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Expense Category Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the expense category exceptions file. Please refresh the page and try again.",
      });
    }
  }, [
    organisationDetails.organisation.name,
    expenseCategoryUpload.records_failed,
  ]);

  useEffect(() => {
    if (expenseCategoryUpload.end) {
      expenseCategoryUpload.records_failed.length > 0 &&
        downloadExpenseCategoriesFailedUpload();
    }
    // eslint-disable-next-line
  }, [expenseCategoryUpload.end]);

  // Invoice
  // Download invoices
  const downloadInvoices = useCallback(async () => {
    // Create workbook
    const invoiceWorkbook = new ExcelJS.Workbook();

    invoiceWorkbook.created = new Date();

    invoiceWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = invoiceWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Invoices",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      ["1. This file is an export of invoice data managed in advaise.app."],
      ["2. The tab 'Invoices' lists invoices."],
      ["3. Field definitions for invoices:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Invoice: The title of the invoice."],
      ["(c) Booking Code: The code from which the invoice was generated."],
      ["(d) Booking Code Name: A short description of the booking code."],
      ["(e) Client: The client that was or will be invoiced."],
      [
        "(f) Value: The invoice value in the organization's base currency, excluding sales tax.",
      ],
      ["(g) Tax: The sales tax amount in the organization's base currency."],
      [
        "(h) Local Value: The invoice value in the client's currency, excluding sales tax.",
      ],
      ["(i) Issued: The date when the invoice was issued."],
      ["(j) Due: The date when the invoice is or was due."],
      ["(k) Paid: The date when the invoice was paid."],
      [
        "(l) Status: The status of the invoice, either 'Draft', 'Invoiced', 'Paid', 'Disputed' or 'Credited'.",
      ],
      ["(m) Comments: Additional information on the invoice, if available."],
      ["4. The tab 'Time' contains time-based fees."],
      ["5. Field definitions for time:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Staff: The team member who booked time."],
      ["(c) Role: The team member's role."],
      ["(d) Time: The time booked in minutes."],
      ["(e) Start: The date when the booking started."],
      ["(f) End: The date when the booking ended."],
      ["(g) Rate: The day rate applied to the booking."],
      [
        "(h) Value: The payment value in the organization's base currency, excluding sales tax.",
      ],
      ["(i) Tax: The sales tax amount in the organization's base currency."],
      [
        "(j) Invoice: A unique identifier, which links the time entry to an invoice in the Invoices tab.",
      ],
      ["6. The tab 'Expenses' contains expense-related fees."],
      ["7. Field definitions for expenses:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Expense: The expense item."],
      [
        "(c) Value: The payment value in the organization's base currency, excluding sales tax.",
      ],
      ["(d) Tax: The sales tax amount in the organization's base currency."],
      [
        "(e) Invoice: A unique identifier, which links the expense to an invoice in the Invoices tab.",
      ],
      ["8. The tab 'Milestones' contains milestone-based fees."],
      ["9. Field definitions for milestones:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Milestone: The name of the milestone."],
      ["(c) Completed: The date when the milestone was completed."],
      [
        "(d) Value: The payment value in the organization's base currency, excluding sales tax.",
      ],
      ["(e) Tax: The sales tax amount in the organization's base currency."],
      [
        "(f) Invoice: A unique identifier, which links the milestone to an invoice in the Invoices tab.",
      ],
      ["10. The tab 'Other' contains miscellaneous items."],
      ["11. Field definitions for other items:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Item: The item billed."],
      [
        "(c) Value: The payment value in the organization's base currency, excluding sales tax.",
      ],
      ["(d) Tax: The sales tax amount in the organization's base currency."],
      [
        "(e) Invoice: A unique identifier, which links the item to an invoice in the Invoices tab.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create invoice data sheet
    const invoiceSheet = invoiceWorkbook.addWorksheet("Invoices", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Invoices`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style invoice data sheet columns
    invoiceSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Invoice",
        key: "invoice",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Booking Code",
        key: "bookingCode",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Booking Code Name",
        key: "bookingCodeName",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Client",
        key: "client",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Value",
        key: "value",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tax",
        key: "tax",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Local Value",
        key: "localValue",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Issued",
        key: "issued",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Due",
        key: "due",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Paid",
        key: "paid",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
    ];

    invoiceSheet.autoFilter = "A1:M1";

    const invoicesTopRow = invoiceSheet.getRow(1);
    invoicesTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    invoiceSheet.addConditionalFormatting({
      ref: "L2:L20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Credited"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "C5CBD3" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Disputed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Draft"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "8ABBFF" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Invoiced"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Paid"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    invoiceSheet.pageSetup.printTitlesRow = "1:13";

    // Populate invoice data sheet data
    for (var i = 0; i < invoiceDownload.invoices.length; i++) {
      invoiceSheet.addRow({
        id: invoiceDownload.invoices[i].id,
        invoice: invoiceDownload.invoices[i].name,
        bookingCode: invoiceDownload.invoices[i].booking_code,
        bookingCodeName: invoiceDownload.invoices[i].booking_code_data
          ? invoiceDownload.invoices[i].booking_code_data.name
          : "",
        client: invoiceDownload.invoices[i].client_name,
        value: invoiceDownload.invoices[i].value,
        tax: invoiceDownload.invoices[i].sales_tax,
        localValue: invoiceDownload.invoices[i].value_quote,
        issued: invoiceDownload.invoices[i].issued
          ? invoiceDownload.invoices[i].issued
          : "",
        due: invoiceDownload.invoices[i].due
          ? invoiceDownload.invoices[i].due
          : "",
        paid: invoiceDownload.invoices[i].paid
          ? invoiceDownload.invoices[i].paid
          : "",
        status: invoiceDownload.invoices[i].status,
        comments: invoiceDownload.invoices[i].comments
          ? invoiceDownload.invoices[i].comments
          : "",
      });
    }

    // Protect invoice data sheet
    invoiceSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create booking data sheet
    const bookingSheet = invoiceWorkbook.addWorksheet("Time", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Time`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style booking data sheet columns
    bookingSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Staff",
        key: "staff",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Role",
        key: "role",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Time",
        key: "time",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Start",
        key: "start",
        style: columnStyle,
        width: 20,
      },
      {
        header: "End",
        key: "end",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Rate",
        key: "rate",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Value",
        key: "value",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tax",
        key: "tax",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Invoice",
        key: "invoice",
        style: columnStyle,
        width: 20,
      },
    ];

    bookingSheet.autoFilter = "A1:J1";

    const bookingsTopRow = bookingSheet.getRow(1);
    bookingsTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    bookingSheet.pageSetup.printTitlesRow = "1:10";

    // Populate booking data sheet data
    for (var j = 0; j < invoiceDownload.invoices.length; j++) {
      let bookings = invoiceDownload.invoices[j].bookings;

      for (var k = 0; k < bookings.length; k++) {
        bookingSheet.addRow({
          id: bookings[k].id,
          staff: bookings[k].staff_name,
          role: bookings[k].role,
          time: bookings[k].minutes,
          start: bookings[k].start,
          end: bookings[k].end,
          rate: (60 * bookings[k].value) / bookings[k].minutes,
          value: bookings[k].value,
          tax: bookings[k].sales_tax,
          invoice: bookings[k].invoice,
        });
      }

      let booking_credits = invoiceDownload.invoices[j].booking_credits;

      for (var l = 0; l < booking_credits.length; l++) {
        bookingSheet.addRow({
          id: booking_credits[l].id,
          staff: booking_credits[l].staff_name,
          role: booking_credits[l].role,
          time: booking_credits[l].minutes,
          start: booking_credits[l].start,
          end: booking_credits[l].end,
          rate: (60 * booking_credits[k].value) / booking_credits[k].minutes,
          value: booking_credits[l].value,
          tax: booking_credits[l].sales_tax,
          invoice: booking_credits[l].invoice,
        });
      }
    }

    // Protect booking data sheet
    bookingSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create expense data sheet
    const expenseSheet = invoiceWorkbook.addWorksheet("Expenses", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Expenses`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style expense data sheet columns
    expenseSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Expense",
        key: "expense",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Value",
        key: "value",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tax",
        key: "tax",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Invoice",
        key: "invoice",
        style: columnStyle,
        width: 20,
      },
    ];

    expenseSheet.autoFilter = "A1:E1";

    const expensesTopRow = expenseSheet.getRow(1);
    expensesTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    expenseSheet.pageSetup.printTitlesRow = "1:5";

    // Populate expense data sheet data
    for (var x = 0; x < invoiceDownload.invoices.length; x++) {
      let expenses = invoiceDownload.invoices[x].expenses;

      for (var m = 0; m < expenses.length; m++) {
        expenseSheet.addRow({
          id: expenses[m].id,
          expense: expenses[m].name,
          value: expenses[m].value,
          tax: expenses[m].sales_tax,
          invoice: expenses[m].invoice,
        });
      }

      let expense_credits = invoiceDownload.invoices[x].expense_credits;

      for (var n = 0; n < expense_credits.length; n++) {
        expenseSheet.addRow({
          id: expense_credits[n].id,
          expense: expense_credits[n].name,
          value: expense_credits[n].value,
          tax: expense_credits[n].sales_tax,
          invoice: expense_credits[n].invoice,
        });
      }
    }

    // Protect expense data sheet
    expenseSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create milestone data sheet
    const milestoneSheet = invoiceWorkbook.addWorksheet("Milestones", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Milestones`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style milestone data sheet columns
    milestoneSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Milestone",
        key: "milestone",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Completed",
        key: "completed",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Value",
        key: "value",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tax",
        key: "tax",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Invoice",
        key: "invoice",
        style: columnStyle,
        width: 20,
      },
    ];

    milestoneSheet.autoFilter = "A1:F1";

    const milestonesTopRow = milestoneSheet.getRow(1);
    milestonesTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    milestoneSheet.pageSetup.printTitlesRow = "1:6";

    // Populate milestone data sheet data
    for (var o = 0; o < invoiceDownload.invoices.length; o++) {
      let milestones = invoiceDownload.invoices[o].milestones;

      for (var p = 0; p < milestones.length; p++) {
        milestoneSheet.addRow({
          id: milestones[p].id,
          milestone: milestones[p].name,
          completed: milestones[p].completed,
          value: milestones[p].value,
          tax: milestones[p].sales_tax,
          invoice: milestones[p].invoice,
        });
      }

      let milestone_credits = invoiceDownload.invoices[o].milestone_credits;

      for (var q = 0; q < milestone_credits.length; q++) {
        milestoneSheet.addRow({
          id: milestone_credits[q].id,
          milestone: milestone_credits[q].name,
          completed: milestone_credits[q].completed,
          value: milestone_credits[q].value,
          tax: milestone_credits[q].sales_tax,
          invoice: milestone_credits[q].invoice,
        });
      }
    }

    // Protect milestone data sheet
    milestoneSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create other data sheet
    const otherSheet = invoiceWorkbook.addWorksheet("Other", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Other`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style other data sheet columns
    otherSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Item",
        key: "item",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Value",
        key: "value",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Tax",
        key: "tax",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Invoice",
        key: "invoice",
        style: columnStyle,
        width: 20,
      },
    ];

    otherSheet.autoFilter = "A1:E1";

    const othersTopRow = otherSheet.getRow(1);
    othersTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    otherSheet.pageSetup.printTitlesRow = "1:5";

    // Populate other data sheet data
    for (var r = 0; r < invoiceDownload.invoices.length; r++) {
      let others = invoiceDownload.invoices[r].others;

      for (var s = 0; s < others.length; s++) {
        otherSheet.addRow({
          id: others[s].id,
          item: others[s].name,
          value: others[s].value,
          tax: others[s].sales_tax,
          invoice: others[s].invoice,
        });
      }

      let other_credits = invoiceDownload.invoices[r].other_credits;

      for (var t = 0; t < other_credits.length; t++) {
        otherSheet.addRow({
          id: other_credits[t].id,
          item: other_credits[t].name,
          value: other_credits[t].value,
          tax: other_credits[t].sales_tax,
          invoice: other_credits[t].invoice,
        });
      }
    }

    // Protect other data sheet
    otherSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Save file
    try {
      await invoiceWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Invoices.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Invoices downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the invoices file. Please refresh the page and try again.",
      });
    }
  }, [invoiceDownload.invoices, organisationDetails.organisation.name]);

  useEffect(() => {
    if (invoiceDownload.success) {
      downloadInvoices();

      dispatch(resetDownloadInvoicesAction());
    }
    // eslint-disable-next-line
  }, [invoiceDownload.success]);

  // Leave category
  // Add leave category
  const addLeaveCategory = () => {
    // Prepare data
    const leave_category_data = new FormData();

    leaveCategory.code
      ? leave_category_data.append("code", leaveCategory.code)
      : leave_category_data.append("code", leaveCategoryDefault.code);

    leaveCategory.description
      ? leave_category_data.append("description", leaveCategory.description)
      : leave_category_data.append(
          "description",
          leaveCategoryDefault.description
        );

    leaveCategory.name
      ? leave_category_data.append("name", leaveCategory.name)
      : leave_category_data.append("name", leaveCategoryDefault.name);

    leave_category_data.append("organisation", id);

    // Dispatch action
    dispatch(createLeaveCategoryAction(leave_category_data));
  };

  // Delete leave category
  const deleteLeaveCategory = () => {
    // Dispatch action
    dispatch(deleteLeaveCategoryAction(leaveCategoryDetails.leave_category.id));
  };

  // Download leave categories
  const downloadLeaveCategories = useCallback(async () => {
    // Create workbook
    const leaveCategoryWorkbook = new ExcelJS.Workbook();

    leaveCategoryWorkbook.created = new Date();

    leaveCategoryWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = leaveCategoryWorkbook.addWorksheet(
      "Instructions",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: "Instructions",
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload leave categories",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to upload leave categories from your payroll software into advaise.app.",
      ],
      [
        "2. The tab 'Categories' contains leave categories that exist in your advaise.app organisation (if any). You can add to these by copying and pasting from your accounting system.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      ["(b) Code: The payroll software leave category code."],
      [
        "(c) Name: The name of the leave category. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Description: A description of the leave category."],
      [
        "(e) Delete: A flag to delete the record. Note that you cannot delete leave categories by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused categories - YOU CANNOT DELETE categories that have leave records linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const leaveCategorySheet = leaveCategoryWorkbook.addWorksheet(
      "Categories",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: `${organisationDetails.organisation.name} - Leave Categories`,
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style data sheet columns
    leaveCategorySheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Code",
        key: "code",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Description",
        key: "description",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    leaveCategorySheet.autoFilter = "A1:E1";

    const topRow = leaveCategorySheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    leaveCategorySheet.addConditionalFormatting({
      ref: "E2:E20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    leaveCategorySheet.pageSetup.printTitlesRow = "1:5";

    // Populate data sheet data
    for (var i = 0; i < leaveCategoryDownload.leave_categories.length; i++) {
      leaveCategorySheet.addRow({
        id: leaveCategoryDownload.leave_categories[i].id,
        code: leaveCategoryDownload.leave_categories[i].code
          ? leaveCategoryDownload.leave_categories[i].code
          : "",
        name: leaveCategoryDownload.leave_categories[i].name,
        description: leaveCategoryDownload.leave_categories[i].description
          ? leaveCategoryDownload.leave_categories[i].description
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    leaveCategorySheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      leaveCategorySheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      leaveCategorySheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      leaveCategorySheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      leaveCategorySheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await leaveCategoryWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Leave Categories.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Leave categories downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the leave categories file. Please refresh the page and try again.",
      });
    }
  }, [
    organisationDetails.organisation.name,
    leaveCategoryDownload.leave_categories,
  ]);

  useEffect(() => {
    if (leaveCategoryDownload.success) {
      downloadLeaveCategories();

      dispatch(resetDownloadLeaveCategoriesAction());
    }
    // eslint-disable-next-line
  }, [leaveCategoryDownload.success]);

  // Update leave category
  const updateLeaveCategory = () => {
    // Prepare data
    const leave_category_data = new FormData();

    leaveCategory.code
      ? leave_category_data.append("code", leaveCategory.code)
      : leave_category_data.append("code", leaveCategoryDefault.code);

    leaveCategory.description
      ? leave_category_data.append("description", leaveCategory.description)
      : leave_category_data.append(
          "description",
          leaveCategoryDefault.description
        );

    leaveCategory.name
      ? leave_category_data.append("name", leaveCategory.name)
      : leave_category_data.append("name", leaveCategoryDefault.name);

    // Dispatch action
    dispatch(
      updateLeaveCategoryAction(
        leaveCategoryDetails.leave_category.id,
        leave_category_data
      )
    );
  };

  // Upload leave categories
  const uploadLeaveCategories = async () => {
    // Create workbook
    const leaveCategoryWorkbook = new ExcelJS.Workbook();

    // Read file and leave data
    var records = [];
    try {
      await leaveCategoryWorkbook.xlsx
        .load(leaveCategoryUploadFile.file)
        .then(() => {
          // Get data sheet
          const leaveCategorySheet =
            leaveCategoryWorkbook.getWorksheet("Categories");

          // Get data
          leaveCategorySheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              records.push({
                id: row.getCell(1).value,
                code: row.getCell(2).value
                  ? row.getCell(2).value
                  : leaveCategoryDefault.code,
                name: row.getCell(3).value
                  ? row.getCell(3).value
                  : leaveCategoryDefault.name,
                description: row.getCell(4).value
                  ? row.getCell(4).value
                  : leaveCategoryDefault.description,
                delete: row.getCell(5).value,
              });
            }
          });

          // Upload data
          dispatch(uploadLeaveCategoriesAction(id, records));
        });
    } catch (error) {
      setLeaveCategoryUploadFile({
        ...leaveCategoryUploadFile,
        error: true,
      });
    }
  };

  // Download leave categories that failed to upload
  const downloadLeaveCategoriesFailedUpload = useCallback(async () => {
    // Create workbook
    const leaveCategoryWorkbook = new ExcelJS.Workbook();

    leaveCategoryWorkbook.created = new Date();

    leaveCategoryWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = leaveCategoryWorkbook.addWorksheet(
      "Instructions",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: "Instructions",
        },
        properties: { tabColor: { argb: "CD4246" } },
        views: views,
      }
    );

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header:
          "advaise.app - Instructions to address leave category exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to fix leave categories that had exceptions and upload them into advaise.app.",
      ],
      [
        "2. The tab 'Categories' contains the leave categories that had exceptions. The most common issue is missing data for a field that is required. Update the leave categories based on the field definitions below and upload this file to address the exceptions.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      ["(b) Code: The chart of accounts leave category code."],
      [
        "(c) Name: The name of the leave category. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Description: A description of the leave category."],
      [
        "(e) Delete: A flag to delete the record. Note that you cannot delete leave categories by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'. You can only delete unused categories - YOU CANNOT DELETE categories that have leave records linked to them.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const leaveCategorySheet = leaveCategoryWorkbook.addWorksheet(
      "Categories",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: `${organisationDetails.organisation.name} - Leave Category Exceptions`,
        },
        properties: { tabColor: { argb: "CD4246" } },
        views: views,
      }
    );

    // Define and style data sheet columns
    leaveCategorySheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Code",
        key: "code",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Name",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Description",
        key: "description",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    leaveCategorySheet.autoFilter = "A1:E1";

    const topRow = leaveCategorySheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    leaveCategorySheet.addConditionalFormatting({
      ref: "E2:E20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    leaveCategorySheet.pageSetup.printTitlesRow = "1:5";

    // Populate data sheet data
    for (var i = 0; i < leaveCategoryUpload.records_failed.length; i++) {
      leaveCategorySheet.addRow({
        id: leaveCategoryUpload.records_failed[i].id,
        code: leaveCategoryUpload.records_failed[i].code
          ? leaveCategoryUpload.records_failed[i].code
          : "",
        name: leaveCategoryUpload.records_failed[i].name,
        description: leaveCategoryUpload.records_failed[i].description
          ? leaveCategoryUpload.records_failed[i].description
          : "",
        delete: "",
      });
    }

    // Protect data sheet
    leaveCategorySheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      leaveCategorySheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      leaveCategorySheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      leaveCategorySheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      leaveCategorySheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await leaveCategoryWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Leave Category Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the leave category exceptions file. Please refresh the page and try again.",
      });
    }
  }, [
    organisationDetails.organisation.name,
    leaveCategoryUpload.records_failed,
  ]);

  useEffect(() => {
    if (leaveCategoryUpload.end) {
      leaveCategoryUpload.records_failed.length > 0 &&
        downloadLeaveCategoriesFailedUpload();
    }
    // eslint-disable-next-line
  }, [leaveCategoryUpload.end]);

  // Location
  // Add location
  const addLocation = () => {
    // Prepare data
    const location_data = new FormData();

    location.address
      ? location_data.append("address", location.address)
      : location_data.append("address", locationDefault.address);

    location.name
      ? location_data.append("name", location.name)
      : location_data.append("name", locationDefault.name);

    location_data.append("organisation", id);

    // Dispatch action
    dispatch(createLocationAction(location_data));
  };

  // Delete location
  const deleteLocation = () => {
    // Dispatch action
    dispatch(deleteLocationAction(locationDetails.location.id));
  };

  // Update location
  const updateLocation = () => {
    // Prepare data
    const location_data = new FormData();

    location.address
      ? location_data.append("address", location.address)
      : location_data.append("address", locationDefault.address);

    location.name
      ? location_data.append("name", location.name)
      : location_data.append("name", locationDefault.name);

    // Dispatch action
    dispatch(updateLocationAction(locationDetails.location.id, location_data));
  };

  // Knowledge base document
  // Add knowledge base document
  const addKnowledgeBaseDocument = () => {
    // Prepare data
    const knowledge_base_document = new FormData();

    knowledgeBaseDocument.category
      ? knowledge_base_document.append(
          "category",
          knowledgeBaseDocument.category
        )
      : knowledge_base_document.append(
          "category",
          knowledgeBaseDocumentDefault.category
        );

    if (
      knowledgeBaseDocument.file &&
      knowledgeBaseDocument.file !==
        knowledgeBaseDocumentDetails.knowledge_base_document.file
    ) {
      knowledge_base_document.append("file", knowledgeBaseDocument.file);
    } else if (knowledgeBaseDocument.file === "") {
      knowledge_base_document.append("file", knowledgeBaseDocumentDefault.file);
    }

    knowledgeBaseDocument.name
      ? knowledge_base_document.append("name", knowledgeBaseDocument.name)
      : knowledge_base_document.append(
          "name",
          knowledgeBaseDocumentDefault.name
        );

    knowledge_base_document.append("organisation", id);

    // Dispatch action
    dispatch(createKnowledgeBaseDocumentAction(knowledge_base_document));
  };

  // Delete knowledge base document
  const deleteKnowledgeBaseDocument = () => {
    // Dispatch action
    dispatch(
      deleteKnowledgeBaseDocumentAction(
        knowledgeBaseDocumentDetails.knowledge_base_document.id
      )
    );
  };

  // Update knowledge base document
  const updateKnowledgeBaseDocument = () => {
    // Prepare data
    const knowledge_base_document = new FormData();

    knowledgeBaseDocument.category
      ? knowledge_base_document.append(
          "category",
          knowledgeBaseDocument.category
        )
      : knowledge_base_document.append(
          "category",
          knowledgeBaseDocumentDefault.category
        );

    if (
      knowledgeBaseDocument.file &&
      knowledgeBaseDocument.file !==
        knowledgeBaseDocumentDetails.knowledge_base_document.file
    ) {
      knowledge_base_document.append("file", knowledgeBaseDocument.file);
    } else if (knowledgeBaseDocument.file === "") {
      knowledge_base_document.append("file", knowledgeBaseDocumentDefault.file);
    }

    knowledgeBaseDocument.name
      ? knowledge_base_document.append("name", knowledgeBaseDocument.name)
      : knowledge_base_document.append(
          "name",
          knowledgeBaseDocumentDefault.name
        );

    knowledgeBaseDocument.summary
      ? knowledge_base_document.append("summary", knowledgeBaseDocument.summary)
      : knowledge_base_document.append(
          "summary",
          knowledgeBaseDocumentDefault.summary
        );

    // Dispatch action
    dispatch(
      updateKnowledgeBaseDocumentAction(
        knowledgeBaseDocumentDetails.knowledge_base_document.id,
        knowledge_base_document
      )
    );
  };

  // Manager
  // Update manager
  const updateManager = () => {
    // Prepare data
    const manager_data = new FormData();

    manager.approvalRole
      ? manager_data.append("approval_role", manager.approvalRole)
      : manager_data.append("approval_role", managerDefault.approvalRole);

    // Dispatch action
    dispatch(updateManagerAction(managerDetails.manager.id, manager_data));
  };

  // Membership
  // Add membership
  const addMembership = () => {
    // Prepare data
    const membership_data = new FormData();

    membership.accessDelivery != null
      ? membership_data.append("access_delivery", membership.accessDelivery)
      : membership_data.append(
          "access_delivery",
          membershipDefault.accessDelivery
        );

    membership.accessMarketing != null
      ? membership_data.append("access_marketing", membership.accessMarketing)
      : membership_data.append(
          "access_marketing",
          membershipDefault.accessMarketing
        );

    membership.accessPartners != null
      ? membership_data.append("access_partners", membership.accessPartners)
      : membership_data.append(
          "access_partners",
          membershipDefault.accessPartners
        );

    membership.accessPeople != null
      ? membership_data.append("access_people", membership.accessPeople)
      : membership_data.append("access_people", membershipDefault.accessPeople);

    membership.accessSales != null
      ? membership_data.append("access_sales", membership.accessSales)
      : membership_data.append("access_sales", membershipDefault.accessSales);

    membership.accessStrategy != null
      ? membership_data.append("access_strategy", membership.accessStrategy)
      : membership_data.append(
          "access_strategy",
          membershipDefault.accessStrategy
        );

    membership.admin != null
      ? membership_data.append("admin", membership.admin)
      : membership_data.append("admin", membershipDefault.admin);

    membership.emailConfirmation
      ? membership_data.append(
          "email_confirmation",
          membership.emailConfirmation
        )
      : membership_data.append(
          "email_confirmation",
          membershipDefault.emailConfirmation
        );

    membership.member
      ? membership_data.append("member", membership.member)
      : membership_data.append("member", membershipDefault.member);

    membership_data.append("organisation", id);

    // Dispatch action
    dispatch(createMembershipAction(membership_data));
  };

  // Delete membership
  const deleteMembership = () => {
    // Dispatch action
    dispatch(deleteMembershipAction(membershipDetails.membership.id));
  };

  // Update membership
  const updateMembership = () => {
    // Prepare data
    const membership_data = new FormData();

    membership.accessDelivery
      ? membership_data.append("access_delivery", membership.accessDelivery)
      : membership_data.append(
          "access_delivery",
          membershipDefault.accessDelivery
        );

    membership.accessMarketing
      ? membership_data.append("access_marketing", membership.accessMarketing)
      : membership_data.append(
          "access_marketing",
          membershipDefault.accessMarketing
        );

    membership.accessPartners
      ? membership_data.append("access_partners", membership.accessPartners)
      : membership_data.append(
          "access_partners",
          membershipDefault.accessPartners
        );

    membership.accessPeople
      ? membership_data.append("access_people", membership.accessPeople)
      : membership_data.append("access_people", membershipDefault.accessPeople);

    membership.accessSales
      ? membership_data.append("access_sales", membership.accessSales)
      : membership_data.append("access_sales", membershipDefault.accessSales);

    membership.accessStrategy
      ? membership_data.append("access_strategy", membership.accessStrategy)
      : membership_data.append(
          "access_strategy",
          membershipDefault.accessStrategy
        );

    membership.admin
      ? membership_data.append("admin", membership.admin)
      : membership_data.append("admin", membershipDefault.admin);

    membership.emailConfirmation
      ? membership_data.append(
          "email_confirmation",
          membership.emailConfirmation
        )
      : membership_data.append(
          "email_confirmation",
          membershipDefault.emailConfirmation
        );

    membership.member
      ? membership_data.append("member", membership.member)
      : membership_data.append("member", membershipDefault.member);

    // Dispatch action
    dispatch(
      updateMembershipAction(membershipDetails.membership.id, membership_data)
    );
  };

  // Organisation
  // Delete organisation
  const deleteOrganisation = () => {
    // Dispatch action
    organisationDeleteKey === organisationDetails.organisation.name
      ? dispatch(deleteOrganisationAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the organisation's name",
        });
  };

  // Update organisation summary
  const updateSummary = () => {
    // Prepare data
    const organisation_data = new FormData();

    organisation.about
      ? organisation_data.append("about", organisation.about)
      : organisation_data.append("about", organisationDefault.about);

    organisation.address
      ? organisation_data.append("address", organisation.address)
      : organisation_data.append("address", organisationDefault.address);

    organisation.email
      ? organisation_data.append("email", organisation.email)
      : organisation_data.append("email", organisationDefault.email);

    if (
      organisation.logo &&
      organisation.logo !== organisationDetails.organisation.logo
    ) {
      organisation_data.append("logo", organisation.logo);
    } else if (organisation.logo === "") {
      organisation_data.append("logo", organisationDefault.logo);
    }

    organisation.name
      ? organisation_data.append("name", organisation.name)
      : organisation_data.append("name", organisationDefault.name);

    organisation.owner
      ? organisation_data.append("owner", organisation.owner)
      : organisation_data.append("owner", organisationDefault.owner);

    organisation.phone
      ? organisation_data.append("phone", organisation.phone)
      : organisation_data.append("phone", organisationDefault.phone);

    organisation.tagline
      ? organisation_data.append("tagline", organisation.tagline)
      : organisation_data.append("tagline", organisationDefault.tagline);

    organisation.website
      ? organisation_data.append("website", organisation.website)
      : organisation_data.append("website", organisationDefault.website);

    // Dispatch action
    dispatch(
      updateOrganisationAction(
        organisationDetails.organisation.id,
        organisation_data
      )
    );
  };

  // Update CRM integration
  const updateCrmIntegration = () => {
    // Prepare data
    const organisation_data = new FormData();

    organisation.crm
      ? organisation_data.append("crm", organisation.crm)
      : organisation_data.append("crm", organisationDefault.crm);

    organisation.syncContactsExternalToInternal != null
      ? organisation_data.append(
          "sync_contacts_external_to_internal",
          organisation.syncContactsExternalToInternal
        )
      : organisation_data.append(
          "sync_contacts_external_to_internal",
          organisationDefault.syncContactsExternalToInternal
        );

    organisation.syncContactsInternalToExternal != null
      ? organisation_data.append(
          "sync_contacts_internal_to_external",
          organisation.syncContactsInternalToExternal
        )
      : organisation_data.append(
          "sync_contacts_internal_to_external",
          organisationDefault.syncContactsInternalToExternal
        );

    // Dispatch action
    dispatch(
      updateOrganisationAction(
        organisationDetails.organisation.id,
        organisation_data
      )
    );
  };

  // Update financials
  const updateFinancials = () => {
    // Prepare data
    const organisation_data = new FormData();

    organisation.grossMargin
      ? organisation_data.append("gross_margin", organisation.grossMargin)
      : organisation_data.append(
          "gross_margin",
          organisationDefault.grossMargin
        );

    organisation.minutesPerDay
      ? organisation_data.append("minutes_per_day", organisation.minutesPerDay)
      : organisation_data.append(
          "minutes_per_day",
          organisationDefault.minutesPerDay
        );

    organisation.salesTaxRate
      ? organisation_data.append("sales_tax_rate", organisation.salesTaxRate)
      : organisation_data.append(
          "sales_tax_rate",
          organisationDefault.salesTaxRate
        );

    // Dispatch action
    dispatch(
      updateOrganisationAction(
        organisationDetails.organisation.id,
        organisation_data
      )
    );
  };

  // Rank
  const addRank = () => {
    // Prepare data
    const rank_data = new FormData();

    rank_data.append("organisation", id);

    rank.name
      ? rank_data.append("name", rank.name)
      : rank_data.append("name", rankDefault.name);

    // Dispatch action
    dispatch(createRankAction(rank_data));
  };

  // Delete rank
  const deleteRank = () => {
    // Dispatch action
    dispatch(deleteRankAction(rankDetails.rank.id));
  };

  // Update rank
  useEffect(() => {
    if (rankCreate.success) {
      dispatch(listRankDetailsAction(rankCreate.rank.id));

      setRankCostRateQuery({
        rank: rankCreate.rank.id,
      });

      dispatch(resetCreateRankAction());

      setUpdateRankDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [rankCreate.success]);

  const updateRank = () => {
    // Prepare data
    const rank_data = new FormData();

    rank.description
      ? rank_data.append("description", rank.description)
      : rank_data.append("description", rankDefault.description);

    rank.name
      ? rank_data.append("name", rank.name)
      : rank_data.append("name", rankDefault.name);

    // Dispatch action
    dispatch(updateRankAction(rankDetails.rank.id, rank_data));
  };

  // Rank cost rate
  const [editRankCostRateId, setEditRankCostRateId] = useState(null);
  const [showNewRankCostRateForm, setShowNewRankCostRateForm] = useState(false);

  // Add rank cost rate
  const addRankCostRate = () => {
    // Prepare data
    const rank_cost_rate = new FormData();

    rankCostRate.averageSalary
      ? rank_cost_rate.append("average_salary", rankCostRate.averageSalary)
      : rank_cost_rate.append(
          "average_salary",
          rankCostRateDefault.averageSalary
        );

    rankCostRate.billableMinutes
      ? rank_cost_rate.append("billable_minutes", rankCostRate.billableMinutes)
      : rank_cost_rate.append(
          "billable_minutes",
          rankCostRateDefault.billableMinutes
        );

    rankCostRate.overheadAllocation
      ? rank_cost_rate.append(
          "overhead_allocation",
          rankCostRate.overheadAllocation
        )
      : rank_cost_rate.append(
          "overhead_allocation",
          rankCostRateDefault.overheadAllocation
        );

    rankCostRate.periodStart
      ? rank_cost_rate.append(
          "period_start",
          getDjangoDate(rankCostRate.periodStart)
        )
      : rank_cost_rate.append(
          "period_start",
          getDjangoDate(rankCostRateDefault.periodStart)
        );

    rank_cost_rate.append("rank", rankDetails.rank.id);

    // Dispatch action
    dispatch(createRankCostRateAction(rank_cost_rate));
  };

  // Delete rank cost rate
  const deleteRankCostRate = () => {
    // Dispatch action
    dispatch(deleteRankCostRateAction(rankCostRateDetails.rank_cost_rate.id));
  };

  // Update rank cost rate
  const updateRankCostRate = () => {
    // Prepare data
    const rank_cost_rate = new FormData();

    rankCostRate.averageSalary
      ? rank_cost_rate.append("average_salary", rankCostRate.averageSalary)
      : rank_cost_rate.append(
          "average_salary",
          rankCostRateDefault.averageSalary
        );

    rankCostRate.billableMinutes
      ? rank_cost_rate.append("billable_minutes", rankCostRate.billableMinutes)
      : rank_cost_rate.append(
          "billable_minutes",
          rankCostRateDefault.billableMinutes
        );

    rankCostRate.overheadAllocation
      ? rank_cost_rate.append(
          "overhead_allocation",
          rankCostRate.overheadAllocation
        )
      : rank_cost_rate.append(
          "overhead_allocation",
          rankCostRateDefault.overheadAllocation
        );

    rankCostRate.periodStart
      ? rank_cost_rate.append(
          "period_start",
          getDjangoDate(rankCostRate.periodStart)
        )
      : rank_cost_rate.append(
          "period_start",
          getDjangoDate(rankCostRateDefault.periodStart)
        );

    // Dispatch action
    dispatch(
      updateRankCostRateAction(
        rankCostRateDetails.rank_cost_rate.id,
        rank_cost_rate
      )
    );
  };

  useEffect(() => {
    if (
      rankCostRateCreate.success ||
      rankCostRateDelete.success ||
      rankCostRateUpdate.success
    ) {
      dispatch(listRanksAction({ ...rankQuery }));
    }
    // eslint-disable-next-line
  }, [
    rankCostRateCreate.success,
    rankCostRateDelete.success,
    rankCostRateUpdate.success,
  ]);

  // Reimbursement
  // Update reimbursement
  const updateReimbursement = () => {
    // Prepare data
    const reimbursement_data = new FormData();

    reimbursement.comments
      ? reimbursement_data.append("comments", reimbursement.comments)
      : reimbursement_data.append("comments", reimbursementDefault.comments);

    reimbursement.paid
      ? reimbursement_data.append("paid", getDjangoDate(reimbursement.paid))
      : reimbursement_data.append(
          "paid",
          getDjangoDate(reimbursementDefault.paid)
        );

    reimbursement.status
      ? reimbursement_data.append("status", reimbursement.status)
      : reimbursement_data.append("status", reimbursementDefault.status);

    reimbursement.value
      ? reimbursement_data.append("value", reimbursement.value)
      : reimbursement_data.append("value", reimbursementDefault.value);

    // Dispatch action
    dispatch(
      updateReimbursementAction(
        reimbursementDetails.reimbursement.id,
        reimbursement_data
      )
    );
  };

  // Seat
  // Update seat
  const updateSeat = () => {
    // Prepare data
    const seat_data = new FormData();

    seat.membership
      ? seat_data.append("membership", seat.membership)
      : seat_data.append("membership", seatDefault.membership);

    // Dispatch action
    dispatch(updateSeatAction(seatDetails.seat.id, seat_data));
  };

  // HubSpot integration messages
  // Authorise CRM
  useEffect(() => {
    if (hubspotAuthorise.success) {
      window.open(hubspotAuthorise.result, "_self");
    } else if (hubspotAuthorise.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message: "We couldn't authorise the CRM integration. Please try again.",
      });
    }
    // eslint-disable-next-line
  }, [hubspotAuthorise.loading]);

  // Deauthorise CRM
  useEffect(() => {
    if (hubspotDeauthorise.success) {
      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "CRM integration deauthorised.",
      });

      dispatch(listOrganisationDetailsAction(id));
    } else if (hubspotDeauthorise.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We couldn't deauthorise the CRM integration. Please try again.",
      });
    }
    // eslint-disable-next-line
  }, [hubspotDeauthorise.loading]);

  // Sync contacts
  useEffect(() => {
    if (hubspotContactSync.loading) {
      AppToaster.show({
        icon: "info-sign",
        intent: "primary",
        message: "Syncing contacts. This may take a few minutes.",
      });
    } else if (hubspotContactSync.success) {
      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Contact sync successful.",
      });
    } else if (hubspotContactSync.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message: "Contact sync unsuccessful. Please try again.",
      });
    }
    // eslint-disable-next-line
  }, [hubspotContactSync.loading]);

  // Download approval requests
  const downloadApprovalRequests = useCallback(async () => {
    // Create workbook
    const approvalRequestWorkbook = new ExcelJS.Workbook();

    approvalRequestWorkbook.created = new Date();

    approvalRequestWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = approvalRequestWorkbook.addWorksheet(
      "Instructions",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: "Instructions",
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Decision log",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. This file provides a record of approval requests and responses in advaise.app.",
      ],
      ["2. The tab 'Log' contains the approval request and response data."],
      ["3. Field definitions:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Decision: The type of decision requested."],
      [
        "(c) Value: Value of the item that the decision relates to. Units vary according to the type of item, usually currency units or hours.",
      ],
      ["(d) Facts: The fact set submitted with the request."],
      ["(e) Approver: Email of the decision-maker."],
      ["(f) Reviewed: The time when the approver submitted their decision."],
      [
        "(g) Outcome: The outcome of the decision, one of 'Approved', 'Denied', 'Pending' or 'Reversed'.",
      ],
      [
        "(h) Reversed By: Email of the person who reversed the approval, if relevant.",
      ],
      [
        "(i) Reversed Comments: Comments from the person who reversed the approval, if relevant.",
      ],
      ["(j) Reversed On: Date when the approval was reversed, if relevant."],
      ["(k) Approver Comments: Comments from the decision-maker."],
      ["(l) Requestor: Email of the person requesting the decision."],
      ["(m) Submitted: The time when the requestor asked for approval."],
      [
        "(n) Status: Status of the request, one of 'Completed', 'Submitted', or 'Draft'.",
      ],
      [
        "(o) Requestor Comments: Comments from the person requesting the decision.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const approvalRequestSheet = approvalRequestWorkbook.addWorksheet("Log", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Decision Log`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style data sheet columns
    approvalRequestSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Decision",
        key: "decision",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Value",
        key: "value",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Facts",
        key: "facts",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Approver",
        key: "approver",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Reviewed",
        key: "reviewed",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Outcome",
        key: "outcome",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Reversed By",
        key: "reversedBy",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Reversed Comments",
        key: "reversedComments",
        style: columnStyle,
        width: 30,
      },
      {
        header: "ReversedOn",
        key: "reversedOn",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Approver Comments",
        key: "approverComments",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Requestor",
        key: "requestor",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Submitted",
        key: "submitted",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Requestor Comments",
        key: "requestorComments",
        style: columnStyle,
        width: 30,
      },
    ];

    approvalRequestSheet.autoFilter = "A1:O1";

    const topRow = approvalRequestSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    approvalRequestSheet.addConditionalFormatting({
      ref: "G2:G20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Approved"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Denied"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Pending"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "8ABBFF" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Reversed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    approvalRequestSheet.addConditionalFormatting({
      ref: "N2:N20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Completed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Draft"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "8ABBFF" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Submitted"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    approvalRequestSheet.pageSetup.printTitlesRow = "1:13";

    // Populate data sheet data
    for (var i = 0; i < approvalRequestDownload.approval_requests.length; i++) {
      approvalRequestSheet.addRow({
        id: approvalRequestDownload.approval_requests[i].id,
        decision: approvalRequestDownload.approval_requests[i].decision_right,
        value: approvalRequestDownload.approval_requests[i].value,
        facts: JSON.stringify(
          approvalRequestDownload.approval_requests[i].facts
        ),
        approver: approvalRequestDownload.approval_requests[i].approver_name,
        reviewed: approvalRequestDownload.approval_requests[i].response_data
          .reviewed
          ? approvalRequestDownload.approval_requests[i].response_data.reviewed
          : "",
        outcome:
          approvalRequestDownload.approval_requests[i].response_data.outcome,
        reversedBy: approvalRequestDownload.approval_requests[i].response_data
          .reversed_by
          ? approvalRequestDownload.approval_requests[i].response_data
              .reversed_by
          : "",
        reversedComments: approvalRequestDownload.approval_requests[i]
          .response_data.reversed_comments
          ? approvalRequestDownload.approval_requests[i].response_data
              .reversed_comments
          : "",
        reversedOn: approvalRequestDownload.approval_requests[i].response_data
          .reversed_on
          ? approvalRequestDownload.approval_requests[i].response_data
              .reversed_on
          : "",
        approverComments: approvalRequestDownload.approval_requests[i]
          .response_data.comments
          ? approvalRequestDownload.approval_requests[i].response_data.comments
          : "",
        requestor: approvalRequestDownload.approval_requests[i].requestor_name,
        submitted: approvalRequestDownload.approval_requests[i].submitted
          ? approvalRequestDownload.approval_requests[i].submitted
          : "",
        status: approvalRequestDownload.approval_requests[i].status,
        requestorComments: approvalRequestDownload.approval_requests[i].comments
          ? approvalRequestDownload.approval_requests[i].comments
          : "",
      });
    }

    // Protect data sheet
    approvalRequestSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Save file
    try {
      await approvalRequestWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Decision Log.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Decision log downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the decision log file. Please refresh the page and try again.",
      });
    }
  }, [
    approvalRequestDownload.approval_requests,
    organisationDetails.organisation.name,
  ]);

  useEffect(() => {
    if (approvalRequestDownload.success) {
      downloadApprovalRequests();

      dispatch(resetDownloadApprovalRequestsAction());
    }
    // eslint-disable-next-line
  }, [approvalRequestDownload.success]);

  // Download reimbursements
  const downloadReimbursements = useCallback(async () => {
    // Create workbook
    const reimbursementWorkbook = new ExcelJS.Workbook();

    reimbursementWorkbook.created = new Date();

    reimbursementWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = reimbursementWorkbook.addWorksheet(
      "Instructions",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: "Instructions",
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Reimbursements",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. This file provides a list of reimbursements linked to expense claims managed in advaise.app.",
      ],
      ["2. The tab 'Reimbursements' contains the reimbursement data."],
      ["3. Field definitions for reimbursements:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Claim: The title of the expense claim."],
      ["(c) Comments: Additional information on the claim, if available."],
      ["(d) Staff: The team member reimbursed."],
      [
        "(e) Status: Whether the expenses have been reimbursed, either 'Paid' or 'Not paid'.",
      ],
      ["(f) Paid: The date of reimbursement."],
      ["(g) Amount: The amount reimbursed."],
      ["4. The tab 'Expenses' contains itemised expenses."],
      ["5. Field definitions for expenses:"],
      ["(a) System Id: A unique identifier for the record."],
      ["(b) Category: The expense category name."],
      ["(c) Code: The expense category code from the chart of accounts."],
      ["(d) Comments: A description of the expense."],
      ["(e) Amount: The value of the expense."],
      [
        "(f) Claim: A unique identifier, which links the expense to a claim in the Reimbursements tab.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create reimbursements data sheet
    const reimbursementSheet = reimbursementWorkbook.addWorksheet(
      "Reimbursements",
      {
        headerFooter: {
          oddFooter: "Page &P of &N",
          oddHeader: `${organisationDetails.organisation.name} - Reimbursements`,
        },
        properties: { tabColor: { argb: "1891AC" } },
        views: views,
      }
    );

    // Define and style reimbursements data sheet columns
    reimbursementSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Claim",
        key: "claim",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Staff",
        key: "staff",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Paid",
        key: "paid",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Amount",
        key: "amount",
        style: columnStyle,
        width: 20,
      },
    ];

    reimbursementSheet.autoFilter = "A1:G1";

    const reimbursementsTopRow = reimbursementSheet.getRow(1);
    reimbursementsTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    reimbursementSheet.addConditionalFormatting({
      ref: "E2:E20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Not paid"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Paid"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    reimbursementSheet.pageSetup.printTitlesRow = "1:7";

    // Populate reimbursements data sheet data
    for (var i = 0; i < reimbursementDownload.reimbursements.length; i++) {
      reimbursementSheet.addRow({
        id: reimbursementDownload.reimbursements[i].id,
        claim: reimbursementDownload.reimbursements[i].name,
        comments: reimbursementDownload.reimbursements[i].comments
          ? reimbursementDownload.reimbursements[i].comments
          : "",
        staff: reimbursementDownload.reimbursements[i].staff_name,
        status: reimbursementDownload.reimbursements[i].status,
        paid: reimbursementDownload.reimbursements[i].paid
          ? reimbursementDownload.reimbursements[i].paid
          : "",
        amount: reimbursementDownload.reimbursements[i].value,
      });
    }

    // Protect reimbursements data sheet
    reimbursementSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create expenses data sheet
    const expenseSheet = reimbursementWorkbook.addWorksheet("Expenses", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${organisationDetails.organisation.name} - Expenses`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style expenses data sheet columns
    expenseSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Category",
        key: "category",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Code",
        key: "code",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Amount",
        key: "amount",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Claim",
        key: "claim",
        style: columnStyle,
        width: 20,
      },
    ];

    expenseSheet.autoFilter = "A1:F1";

    const expensesTopRow = expenseSheet.getRow(1);
    expensesTopRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    expenseSheet.pageSetup.printTitlesRow = "1:6";

    // Populate expenses data sheet data
    for (var j = 0; j < reimbursementDownload.reimbursements.length; j++) {
      let expenses =
        reimbursementDownload.reimbursements[j].claim_data.expenses;

      for (var k = 0; k < expenses.length; k++) {
        expenseSheet.addRow({
          id: expenses[k].id,
          category: expenses[k].category_name,
          code: expenses[k].category_code ? expenses[k].category_code : "",
          comments: expenses[k].comments ? expenses[k].comments : "",
          amount: expenses[k].value,
          claim: expenses[k].claim,
        });
      }
    }

    // Protect expenses data sheet
    expenseSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Save file
    try {
      await reimbursementWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${organisationDetails.organisation.name} - Reimbursements.xlsx`
        );
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Reimbursements downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the reimbursements file. Please refresh the page and try again.",
      });
    }
  }, [
    reimbursementDownload.reimbursements,
    organisationDetails.organisation.name,
  ]);

  useEffect(() => {
    if (reimbursementDownload.success) {
      downloadReimbursements();

      dispatch(resetDownloadReimbursementsAction());
    }
    // eslint-disable-next-line
  }, [reimbursementDownload.success]);

  // Download currency exchange rate
  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.exchange_rate !== undefined) {
        setCurrencyExchangeRate((prevState) => {
          return {
            ...prevState,
            exchangeRate: lastJsonMessage.message.exchange_rate,
          };
        });
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (currencyGetRate.success) {
      AppToaster.show({
        icon: "updated",
        intent: "primary",
        message: "Downloading the exchange rate. This will take a few seconds.",
      });

      dispatch(resetGetRateCurrencyAction());
    } else if (currencyGetRate.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the exchange rate. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [currencyGetRate.loading]);

  // DemoCo expired
  const DemoCoExpired = () => {
    return organisationDetails.organisation.demo &&
      seatList.count > 0 &&
      seatList.seats.filter((seat) => seat.status === "Active").length === 0 ? (
      <Callout intent="danger">
        Your trial of DemoCo has expired. Please{" "}
        <LinkTag
          intent="danger"
          onClick={() => {
            navigate("/organisations");
          }}
          label="go back"
        />{" "}
        and create or activate your own organization.
      </Callout>
    ) : null;
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the organisation</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "15px", width: "35%" }}>
                <img
                  alt="logo"
                  src={
                    organisation.logo
                      ? organisation.logo !==
                        organisationDetails.organisation.logo
                        ? URL.createObjectURL(new Blob([organisation.logo]))
                        : organisationDetails.organisation.logo
                      : "/images/no_logo.png"
                  }
                  style={{
                    aspectRatio: "1/1",
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: "50%",
                    margin: "10px 0 10px 0",
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </div>

              <div style={{ marginLeft: "15px", width: "65%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={organisationUpdate.error}
                  fieldName="name"
                  icon="office"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Enter the organisation's name"
                  setValueObject={setOrganisation}
                  setValueVariable="name"
                  value={organisation.name}
                />

                {/* Tagline */}
                <FormDrawerTextField2
                  error={organisationUpdate.error}
                  fieldName="tagline"
                  icon="app-header"
                  label="Tagline"
                  placeholder="Enter the organisation's tagline"
                  setValueObject={setOrganisation}
                  setValueVariable="tagline"
                  value={organisation.tagline}
                />

                {/* Phone */}
                <FormDrawerTextField2
                  error={organisationUpdate.error}
                  fieldName="phone"
                  helperText="Include the country code"
                  icon="phone"
                  label="Phone"
                  placeholder="Enter the organisation's phone number"
                  setValueObject={setOrganisation}
                  setValueVariable="phone"
                  value={organisation.phone}
                />

                {/* Email */}
                <FormDrawerTextField2
                  error={organisationUpdate.error}
                  fieldName="email"
                  icon="envelope"
                  label="Email"
                  placeholder="Enter the organisation's contact email"
                  setValueObject={setOrganisation}
                  setValueVariable="email"
                  value={organisation.email}
                />

                {/* Website */}
                <FormDrawerTextField2
                  error={organisationUpdate.error}
                  fieldName="website"
                  helperText="You can copy and paste the full url from your browser"
                  icon="globe-network"
                  label="Website"
                  placeholder="Enter the full url of your organisation's website"
                  setValueObject={setOrganisation}
                  setValueVariable="website"
                  value={organisation.website}
                />

                {/* Logo */}
                <FormDrawerFileField2
                  error={organisationUpdate.error}
                  fileName="logo"
                  label="Logo"
                  placeholder="Upload the organisation's logo"
                  setValueObject={setOrganisation}
                  setValueFileVariable="logo"
                  setValueNameVariable="logoName"
                  valueFile={organisation.logo}
                  valueName={organisation.logoName}
                />
              </div>
            </div>

            {/* Address */}
            <FormDrawerTextAreaField3
              error={organisationUpdate.error}
              fieldName="address"
              height="100px"
              label="Address"
              noRewrite
              placeholder="Enter the organisation's address"
              setValueObject={setOrganisation}
              setValueVariable="address"
              value={organisation.address}
            />

            {/* About */}
            <FormDrawerTextAreaField3
              error={organisationUpdate.error}
              fieldName="about"
              label="About"
              placeholder="Enter a summary of the organisation"
              setValueObject={setOrganisation}
              setValueVariable="about"
              value={organisation.about}
            />
          </Card>
        }
        error={organisationUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update organisation"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Updating an organisation"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={organisationDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateOrganisationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={organisationUpdate.success}
        saveSuccessMessage="Organisation updated."
        saving={organisationUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update organisation"
      />

      {/* New membership form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the member</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* User Id */}
                  <FormDrawerTextField2
                    error={membershipCreate.error}
                    errorMessage={
                      membershipCreate.error
                        ? membershipCreate.error.response.data["member"]
                          ? String(
                              membershipCreate.error.response.data["member"]
                            ) === "This field may not be null."
                            ? "This field may not be blank."
                            : String(
                                membershipCreate.error.response.data["member"]
                              ).includes("object does not exist")
                            ? "User does not exist."
                            : undefined
                          : undefined
                        : undefined
                    }
                    fieldName="member"
                    helperText="Ask for the User Id found in the user profile"
                    icon="user"
                    label="User Id"
                    labelInfo="(required)"
                    placeholder="Enter the user's system id"
                    setValueObject={setMembership}
                    setValueVariable="member"
                    value={membership.member}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}>
                  {/* User email */}
                  <FormDrawerTextField2
                    error={membershipCreate.error}
                    fieldName="email_confirmation"
                    helperText="User's login email address"
                    icon="envelope"
                    label="User email"
                    labelInfo="(required)"
                    placeholder="Enter the user's login email"
                    setValueObject={setMembership}
                    setValueVariable="emailConfirmation"
                    value={membership.emailConfirmation}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Give administrative rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Administrator */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="admin"
                    helperText="Give this user administrative rights"
                    label="Administrator"
                    setValueObject={setMembership}
                    setValueVariable="admin"
                    value={membership.admin}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you give this member administrative rights, they will be
                    able to edit all of the organisation's information.
                    Administrators can do all that the organization owner can,
                    except deleting the organisation.
                  </Callout>
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access to advaise.app modules</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Strategy */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="access_strategy"
                    helperText="Allow this user to access Markets and Services"
                    label="Strategy"
                    setValueObject={setMembership}
                    setValueVariable="accessStrategy"
                    value={membership.accessStrategy}
                  />

                  {/* People */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="access_people"
                    helperText="Allow this user to access Roles, Openings and Staff"
                    label="People"
                    setValueObject={setMembership}
                    setValueVariable="accessPeople"
                    value={membership.accessPeople}
                  />

                  {/* Partners */}
                  {/*<FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="access_partners"
                    helperText="Allow this user to access the partners module"
                    label="Partners"
                    setValueObject={setMembership}
                    setValueVariable="accessPartners"
                    value={membership.accessPartners}
                  />*/}

                  {/* Content */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="access_marketing"
                    helperText="Allow this user to access Case Studies and Insights"
                    label="Content"
                    setValueObject={setMembership}
                    setValueVariable="accessMarketing"
                    value={membership.accessMarketing}
                  />

                  {/* Sales */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="access_sales"
                    helperText="Allow this user to access Contacts, Clients and Proposals"
                    label="Sales"
                    setValueObject={setMembership}
                    setValueVariable="accessSales"
                    value={membership.accessSales}
                  />

                  {/* Delivery */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="access_delivery"
                    helperText="Allow this user to access Engagements and Invoices"
                    label="Delivery"
                    setValueObject={setMembership}
                    setValueVariable="accessDelivery"
                    value={membership.accessDelivery}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="primary">
                    These options determine the modules that this user can
                    access. In addition, some modules provide fine-grained
                    controls to manage access to specific records.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={membershipCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add membership"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing members"
        icon="edit"
        isOpen={addMembershipDrawerIsOpen}
        onSave={addMembership}
        refreshList={() => {
          dispatch(listMembershipsAction({ organisation: id }));

          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateMembershipAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={membershipCreate.success}
        saveSuccessMessage="Membership added."
        saving={membershipCreate.loading}
        setIsOpen={setAddMembershipDrawerIsOpen}
        title="Add membership"
      />

      {/* Existing membership form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the member</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* User Id */}
                  <FormDrawerTextField2
                    disabled
                    error={membershipUpdate.error}
                    fieldName="member"
                    helperText="Ask for the User Id found in the user profile"
                    icon="user"
                    label="User Id"
                    labelInfo="(required)"
                    placeholder="Enter the user's system id"
                    setValueObject={setMembership}
                    setValueVariable="member"
                    value={membership.member}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}>
                  {/* User email */}
                  <FormDrawerTextField2
                    disabled
                    error={membershipUpdate.error}
                    fieldName="email_confirmation"
                    helperText="User's login email address"
                    icon="envelope"
                    label="User email"
                    labelInfo="(required)"
                    placeholder="Enter the user's login email"
                    setValueObject={setMembership}
                    setValueVariable="emailConfirmation"
                    value={membership.emailConfirmation}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Give admin rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Administrator */}
                  <FormDrawerSwitchField
                    error={membershipCreate.error}
                    fieldName="admin"
                    helperText="Give this user admin rights"
                    label="Administrator"
                    setValueObject={setMembership}
                    setValueVariable="admin"
                    value={membership.admin}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning" style={{ margin: "0 0 15px 0" }}>
                    If you give this member admin rights, they will be able to
                    edit all of your organisation's information. Administrators
                    can do all that you can do, except delete the organisation.
                  </Callout>
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access to advaise.app modules</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Strategy */}
                  <FormDrawerSwitchField
                    error={membershipUpdate.error}
                    fieldName="access_strategy"
                    helperText="Allow this user to access the strategy module"
                    label="Strategy"
                    setValueObject={setMembership}
                    setValueVariable="accessStrategy"
                    value={membership.accessStrategy}
                  />

                  {/* People */}
                  <FormDrawerSwitchField
                    error={membershipUpdate.error}
                    fieldName="access_people"
                    helperText="Allow this user to access the people module"
                    label="People"
                    setValueObject={setMembership}
                    setValueVariable="accessPeople"
                    value={membership.accessPeople}
                  />

                  {/* Partners */}
                  {/*<FormDrawerSwitchField
                    
                    error={membershipUpdate.error}
                    fieldName="access_partners"
                    helperText="Allow this user to access the partners module"
                    label="Partners"
                    setValueObject={setMembership}
                    setValueVariable="accessPartners"
                    value={membership.accessPartners}
                  />*/}

                  {/* Content */}
                  <FormDrawerSwitchField
                    error={membershipUpdate.error}
                    fieldName="access_marketing"
                    helperText="Allow this user to access the content module"
                    label="Content"
                    setValueObject={setMembership}
                    setValueVariable="accessMarketing"
                    value={membership.accessMarketing}
                  />

                  {/* Sales */}
                  <FormDrawerSwitchField
                    error={membershipUpdate.error}
                    fieldName="access_sales"
                    helperText="Allow this user to access the sales module"
                    label="Sales"
                    setValueObject={setMembership}
                    setValueVariable="accessSales"
                    value={membership.accessSales}
                  />

                  {/* Delivery */}
                  <FormDrawerSwitchField
                    error={membershipUpdate.error}
                    fieldName="access_delivery"
                    helperText="Allow this user to access the delivery module"
                    label="Delivery"
                    setValueObject={setMembership}
                    setValueVariable="accessDelivery"
                    value={membership.accessDelivery}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="primary">
                    These options determine the modules that this user can
                    access. In addition, the system provides fine-grained
                    controls to manage access to specific records.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={membershipDelete.success}
        deleteSuccessMessage="Membership removed."
        deleting={membershipDelete.loading}
        error={membershipUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update membership"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing members"
        icon="edit"
        isOpen={updateMembershipDrawerIsOpen}
        loading={membershipDetails.loading}
        onDelete={deleteMembership}
        onSave={updateMembership}
        refreshList={() => {
          dispatch(listMembershipsAction({ ...membershipQuery }));

          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteMembershipAction());

          dispatch(resetUpdateMembershipAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={membershipUpdate.success}
        saveSuccessMessage="Member updated."
        saving={membershipUpdate.loading}
        setIsOpen={setUpdateMembershipDrawerIsOpen}
        title="Update membership"
      />

      {/* CRM integration form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Configure the CRM data sync</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* CRM */}
                <FormDrawerSelectSimpleField3
                  error={organisationUpdate.error}
                  fieldName="crm"
                  label="CRM"
                  labelInfo="(required)"
                  options={[
                    { id: 1, value: "HubSpot" },
                    { id: 2, value: "None" },
                  ]}
                  setValueObject={setOrganisation}
                  setValueVariable="crm"
                  value={organisation.crm}
                />

                {/* Enable CRM contact replication */}
                <FormDrawerSwitchField
                  error={organisationUpdate.error}
                  fieldName="sync_contacts_external_to_internal"
                  helperText="Turn on this option if you want to copy contacts from the CRM to advaise.app"
                  label="Enable CRM contact replication"
                  setValueObject={setOrganisation}
                  setValueVariable="syncContactsExternalToInternal"
                  value={organisation.syncContactsExternalToInternal}
                />

                {/* Enable advaise.app contact replication */}
                <FormDrawerSwitchField
                  error={organisationUpdate.error}
                  fieldName="sync_contacts_internal_to_external"
                  helperText="Turn on this option if you want to copy contacts from advaise.app to the CRM"
                  label="Enable advaise.app contact replication"
                  setValueObject={setOrganisation}
                  setValueVariable="syncContactsInternalToExternal"
                  value={organisation.syncContactsInternalToExternal}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                <Callout intent="primary">
                  The replication options allow you to control the data that is
                  copied between advaise.app and your CRM. Only clients that
                  have a website and contacts that have an email address can be
                  copied.
                </Callout>
              </div>
            </div>
          </Card>
        }
        error={organisationUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Manage CRM integration"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing the CRM integration"
        icon="edit"
        isOpen={updateCrmIntegrationDrawerIsOpen}
        loading={organisationDetails.loading}
        onSave={updateCrmIntegration}
        refreshList={() => {
          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateOrganisationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={organisationUpdate.success}
        saveSuccessMessage="Integration updated."
        saving={organisationUpdate.loading}
        setIsOpen={setUpdateCrmIntegrationDrawerIsOpen}
        title="Manage CRM integration"
      />

      {/* Existing seat form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Assign the seat to a member</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <Callout style={{ display: "flex", margin: "0 0 15px 0" }}>
              <div style={{ marginRight: "10px", width: "25%" }}>
                Expires/Renews:
              </div>

              <div style={{ marginLeft: "10px", width: "75%" }}>
                {seatDetails.seat.expires_at
                  ? new Date(seatDetails.seat.expires_at).toLocaleString(
                      "en-US",
                      {
                        dateStyle: "medium",
                        timeStyle: "medium",
                      }
                    )
                  : undefined}
              </div>
            </Callout>

            {/* Member */}
            <FormDrawerSelectField2
              error={seatUpdate.error}
              fieldName="membership"
              icon="user"
              items={membershipList.memberships}
              itemsNameLabel="member_email"
              label="Member"
              loading={membershipList.loading}
              noOptionOnClick
              placeholder="Select a member"
              query={membershipQuery.member}
              setQueryObject={setMembershipQuery}
              setQueryVariable="member"
              setValueObject={setSeat}
              setValueIdVariable="membership"
              setValueNameVariable="memberEmail"
              value={seat.memberEmail}
            />
          </Card>
        }
        error={seatUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update seat"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing seats"
        icon="edit"
        isOpen={updateSeatDrawerIsOpen}
        loading={seatDetails.loading}
        onSave={updateSeat}
        refreshList={() => {
          dispatch(listSeatsAction({ ...seatQuery }));

          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateSeatAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={seatUpdate.success}
        saveSuccessMessage="Seat updated."
        saving={seatUpdate.loading}
        setIsOpen={setUpdateSeatDrawerIsOpen}
        size="small"
        title="Update seat"
      />

      {/* Financial settings form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Configure financials</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Currency */}
            <FormDrawerSelectSimpleField3
              disabled
              error={organisationUpdate.error}
              fieldName="currency"
              helperText="The organisation's base currency"
              label="Currency"
              loading={currencyGetCurrencyChoices.loading}
              options={currencies}
              setValueObject={setOrganisation}
              setValueVariable="currency"
              value={organisation.currency}
            />

            {/* Gross margin */}
            <FormDrawerNumericField2
              error={organisationUpdate.error}
              fieldName="gross_margin"
              helperText="Default gross margin percentage"
              icon="percentage"
              label="Gross margin"
              majorStepSize={20}
              min={0}
              minorStepSize={1}
              placeholder="Enter the gross margin percentage"
              setValueObject={setOrganisation}
              setValueVariable="grossMargin"
              stepSize={10}
              value={organisation.grossMargin}
            />

            {/* Sales tax rate */}
            <FormDrawerNumericField2
              error={organisationUpdate.error}
              fieldName="sales_tax_rate"
              helperText="Default tax rate applied to fees"
              icon="percentage"
              label="Sales tax rate"
              majorStepSize={20}
              min={0}
              minorStepSize={1}
              placeholder="Enter the sales tax rate"
              setValueObject={setOrganisation}
              setValueVariable="salesTaxRate"
              stepSize={10}
              value={organisation.salesTaxRate}
            />

            {/* Work time per day */}
            <FormDrawerTimeField
              error={organisationUpdate.error}
              fieldName="minutes_per_day"
              helperText="Average working time per day"
              label="Work time per day"
              maxTime={new Date(0, 0, 0, 24, 0)}
              minTime={new Date(0, 0, 0, 0, 1)}
              setValueObject={setOrganisation}
              setValueVariable="minutesPerDay"
              showArrowButtons
              value={organisation.minutesPerDay}
            />
          </Card>
        }
        error={organisationUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Configure financials"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing financial settings"
        icon="edit"
        isOpen={updateFinancialsDrawerIsOpen}
        loading={organisationDetails.loading}
        onSave={updateFinancials}
        refreshList={() => {
          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateOrganisationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={organisationUpdate.success}
        saveSuccessMessage="Financial settings updated."
        saving={organisationUpdate.loading}
        setIsOpen={setUpdateFinancialsDrawerIsOpen}
        size="small"
        title="Configure financials"
      />

      {/* New currency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Add currency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Currency */}
            <FormDrawerSelectSimpleField3
              error={currencyCreate.error}
              fieldName="name"
              label="Currency"
              labelInfo="(required)"
              loading={currencyGetCurrencyChoices.loading}
              options={currencies}
              setValueObject={setCurrency}
              setValueVariable="name"
              value={currency.name}
            />
          </Card>
        }
        error={currencyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add currency"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing currencies"
        icon="edit"
        isOpen={addCurrencyDrawerIsOpen}
        onSave={addCurrency}
        refreshList={() => {
          dispatch(
            listCurrenciesAction({
              organisation: id,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={currencyCreate.success}
        saveSuccessMessage="Currency added."
        saving={currencyCreate.loading}
        setIsOpen={setAddCurrencyDrawerIsOpen}
        size="small"
        title="Add currency"
      />

      {/* Existing currency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Edit the currency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Currency */}
                <FormDrawerSelectSimpleField3
                  disabled
                  fieldName="name"
                  label="Currency"
                  labelInfo="(required)"
                  loading={currencyGetCurrencyChoices.loading}
                  options={currencies}
                  setValueObject={setCurrency}
                  setValueVariable="name"
                  value={currency.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <Callout intent="primary" style={{ margin: "0 0 20px 0" }}>
              <p>
                Define currency exchange rates by entering the following
                information:
              </p>

              <UL>
                <li>
                  Period start: Date from which the exchange rate applies.
                </li>

                <li>Exchange rate: The currency exchange rate value.</li>
              </UL>

              <p>
                You can enter the exchange rate manually or ask the system to
                provide it based on hourly data.
              </p>
            </Callout>

            {/* Currency exchange rates */}
            <div style={{ margin: "20px 0 20px 0" }}>
              {/* Error alerts */}
              {currencyExchangeRateCreate.error && (
                <ErrorList
                  error={currencyExchangeRateUpdate.error}
                  message="Unable to create the exchange rate."
                />
              )}

              {currencyExchangeRateDelete.error && (
                <ErrorList
                  error={currencyExchangeRateUpdate.error}
                  message="Unable to delete the exchange rate."
                />
              )}

              {currencyExchangeRateUpdate.error && (
                <ErrorList
                  error={currencyExchangeRateUpdate.error}
                  message="Unable to update the exchange rate."
                />
              )}

              {/* Cannot delete warning */}
              {editCurrencyExchangeRateId &&
                currencyExchangeRateDetails.currency_exchange_rate.default && (
                  <Callout intent="primary" style={{ margin: "0 0 10px 0" }}>
                    The default exchange rate for this currency cannot be
                    removed.
                  </Callout>
                )}

              {/* Data table */}
              <EditableTableContainer
                activeRecord={editCurrencyExchangeRateId}
                body={
                  <>
                    {currencyExchangeRateList.currency_exchange_rates.map(
                      (exchange_rate) => {
                        return (
                          <tr
                            key={exchange_rate.id}
                            onClick={
                              editCurrencyExchangeRateId ||
                              showNewCurrencyExchangeRateForm
                                ? undefined
                                : () => {
                                    dispatch(
                                      listCurrencyExchangeRateDetailsAction(
                                        exchange_rate.id
                                      )
                                    );

                                    setEditCurrencyExchangeRateId(
                                      exchange_rate.id
                                    );
                                  }
                            }
                          >
                            {/* Period start */}
                            <td style={{ verticalAlign: "middle" }}>
                              {editCurrencyExchangeRateId ===
                              exchange_rate.id ? (
                                <div style={{ margin: "0 0 -15px 0" }}>
                                  <FormDrawerDateField2
                                    error={currencyExchangeRateUpdate.error}
                                    fieldName="period_start"
                                    icon="calendar"
                                    placeholder="Enter date"
                                    setValueObject={setCurrencyExchangeRate}
                                    setValueVariable="periodStart"
                                    value={currencyExchangeRate.periodStart}
                                  />
                                </div>
                              ) : (
                                <TableDate
                                  date={exchange_rate.period_start}
                                  small
                                />
                              )}
                            </td>

                            {/* Exchange rate */}
                            <td style={{ verticalAlign: "middle" }}>
                              {editCurrencyExchangeRateId ===
                              exchange_rate.id ? (
                                <div style={{ margin: "0 0 -15px 0" }}>
                                  <FormDrawerNumericField2
                                    error={currencyExchangeRateUpdate.error}
                                    fieldName="exchange_rate"
                                    leftElement={
                                      <Button
                                        icon="cloud-download"
                                        loading={currencyGetRate.loading}
                                        onClick={() => {
                                          dispatch(
                                            getRateCurrencyAction(
                                              currencyDetails.currency.id,
                                              { organisation_id: id }
                                            )
                                          );
                                        }}
                                        style={{ marginBottom: "15px" }}
                                        text="Download"
                                      />
                                    }
                                    majorStepSize={1}
                                    min={0}
                                    minorStepSize={0.0001}
                                    placeholder="Enter exchange rate"
                                    setValueObject={setCurrencyExchangeRate}
                                    setValueVariable="exchangeRate"
                                    stepSize={0.1}
                                    value={currencyExchangeRate.exchangeRate}
                                  />
                                </div>
                              ) : (
                                <div style={{ textAlign: "end" }}>
                                  {exchange_rate.exchange_rate}
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </>
                }
                //bordered={false}
                count={currencyExchangeRateList.count}
                createSuccess={currencyExchangeRateCreate.success}
                createSuccessMessage="Exchange rate created"
                deleteSuccess={currencyExchangeRateDelete.success}
                deleteSuccessMessage="Exchange rate deleted."
                head={
                  <tr>
                    {/* Period start */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Period start
                    </th>

                    {/* Exchange rate */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Exchange rate
                    </th>
                  </tr>
                }
                interactive={
                  !editCurrencyExchangeRateId &&
                  !showNewCurrencyExchangeRateForm
                }
                loading={currencyExchangeRateList.loading}
                newForm={
                  <tr>
                    {/* Period start */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerDateField2
                          error={currencyExchangeRateCreate.error}
                          fieldName="period_start"
                          icon="calendar"
                          placeholder="Enter date"
                          setValueObject={setCurrencyExchangeRate}
                          setValueVariable="periodStart"
                          value={currencyExchangeRate.periodStart}
                        />
                      </div>
                    </td>

                    {/* Exchange rate */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerNumericField2
                          error={currencyExchangeRateCreate.error}
                          fieldName="exchange_rate"
                          leftElement={
                            <Button
                              icon="cloud-download"
                              loading={currencyGetRate.loading}
                              onClick={() => {
                                dispatch(
                                  getRateCurrencyAction(
                                    currencyDetails.currency.id,
                                    { organisation_id: id }
                                  )
                                );
                              }}
                              style={{ marginBottom: "15px" }}
                              text="Download"
                            />
                          }
                          majorStepSize={1}
                          min={0}
                          minorStepSize={0.0001}
                          placeholder="Enter exchange rate"
                          setValueObject={setCurrencyExchangeRate}
                          setValueVariable="exchangeRate"
                          stepSize={0.1}
                          value={currencyExchangeRate.exchangeRate}
                        />
                      </div>
                    </td>
                  </tr>
                }
                noMargin
                noResultsMessage="Add exchange rates."
                noResultsTitle="No exchange rates found"
                page={currencyExchangeRateQuery.page}
                refreshList={() => {
                  dispatch(
                    listCurrencyExchangeRatesAction({
                      currency: currencyDetails.currency.id,
                    })
                  );
                }}
                resetAction={() => {
                  dispatch(resetCreateCurrencyExchangeRateAction());

                  dispatch(resetDeleteCurrencyExchangeRateAction());

                  dispatch(resetUpdateCurrencyExchangeRateAction());
                }}
                resetFormVariables={() => {
                  setCurrencyExchangeRate({
                    ...currencyExchangeRateDefault,
                  });
                }}
                setActiveRecord={setEditCurrencyExchangeRateId}
                setPageObject={setCurrencyExchangeRateQuery}
                setPageVariable="page"
                setShowNewForm={setShowNewCurrencyExchangeRateForm}
                showNewForm={showNewCurrencyExchangeRateForm}
                striped={false}
                updateSuccess={currencyExchangeRateUpdate.success}
                updateSuccessMessage="Exchange rate updated."
              />

              {/* Buttons */}
              <div style={{ margin: "15px 0 0 0" }}>
                {/* Neither adding nor editing record */}
                {!editCurrencyExchangeRateId &&
                  !showNewCurrencyExchangeRateForm && (
                    <Button
                      icon="add"
                      intent="primary"
                      onClick={() => {
                        setShowNewCurrencyExchangeRateForm(true);
                      }}
                      text="Add"
                    />
                  )}

                {/* Adding record */}
                {showNewCurrencyExchangeRateForm && (
                  <>
                    <Button
                      icon="add"
                      intent="success"
                      onClick={() => {
                        dispatch(resetCreateCurrencyExchangeRateAction());

                        addCurrencyExchangeRate();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Add"
                    />

                    <Button
                      icon="delete"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetCreateCurrencyExchangeRateAction());

                        setShowNewCurrencyExchangeRateForm(false);

                        resetDrawerVariables();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />
                  </>
                )}

                {/* Editing record */}
                {editCurrencyExchangeRateId && (
                  <>
                    <Button
                      icon="updated"
                      intent="success"
                      onClick={() => {
                        dispatch(resetUpdateCurrencyExchangeRateAction());

                        updateCurrencyExchangeRate();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Update"
                    />

                    <Button
                      icon="disable"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetUpdateCurrencyExchangeRateAction());

                        setEditCurrencyExchangeRateId(null);

                        resetDrawerVariables();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />

                    <Button
                      disabled={
                        currencyExchangeRateDetails.currency_exchange_rate
                          .cannot_delete
                      }
                      icon="delete"
                      intent="danger"
                      onClick={() => {
                        dispatch(resetUpdateCurrencyExchangeRateAction());

                        deleteCurrencyExchangeRate();
                      }}
                      text="Remove"
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
        }
        cannotDelete={currencyDetails.currency.cannot_delete}
        deleteSuccess={currencyDelete.success}
        deleteSuccessMessage="Currency deleted."
        deleting={currencyDelete.loading}
        disabled={editCurrencyExchangeRateId || showNewCurrencyExchangeRateForm}
        helpContent={
          <FormDrawerHelpContainer
            form="Update currency"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing currencies"
        icon="edit"
        isOpen={updateCurrencyDrawerIsOpen}
        loading={currencyDetails.loading}
        onDelete={deleteCurrency}
        refreshList={() => {
          dispatch(listCurrenciesAction({ ...currencyQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteCurrencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUpdateCurrencyDrawerIsOpen}
        title="Update currency"
      />

      {/* New expense category form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the expense category</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={expenseCategoryCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Enter a name for the expense category"
                  setValueObject={setExpenseCategory}
                  setValueVariable="name"
                  value={expenseCategory.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Code */}
                <FormDrawerTextField2
                  error={expenseCategoryCreate.error}
                  fieldName="code"
                  helperText="Unique identifier from the chart of accounts"
                  icon="barcode"
                  label="Code"
                  placeholder="Enter the category code"
                  setValueObject={setExpenseCategory}
                  setValueVariable="code"
                  value={expenseCategory.code}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={expenseCategoryCreate.error}
              fieldName="description"
              height="100px"
              label="Description"
              placeholder="Enter additional information"
              setValueObject={setExpenseCategory}
              setValueVariable="description"
              value={expenseCategory.description}
            />
          </Card>
        }
        error={expenseCategoryCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add expense category"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing expense categories"
        icon="edit"
        isOpen={addExpenseCategoryDrawerIsOpen}
        onSave={addExpenseCategory}
        refreshList={() => {
          dispatch(listExpenseCategoriesAction({ organisation: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateExpenseCategoryAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={expenseCategoryCreate.success}
        saveSuccessMessage="Expense category added."
        saving={expenseCategoryCreate.loading}
        setIsOpen={setAddExpenseCategoryDrawerIsOpen}
        title="Add expense category"
      />

      {/* Existing expense category form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the expense category</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={expenseCategoryUpdate.error}
                  fieldName="name"
                  icon="label"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Enter a name for the expense category"
                  setValueObject={setExpenseCategory}
                  setValueVariable="name"
                  value={expenseCategory.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Code */}
                <FormDrawerTextField2
                  error={expenseCategoryUpdate.error}
                  fieldName="code"
                  helperText="Unique identifier from the chart of accounts"
                  icon="barcode"
                  label="Code"
                  placeholder="Enter the category code"
                  setValueObject={setExpenseCategory}
                  setValueVariable="code"
                  value={expenseCategory.code}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={expenseCategoryUpdate.error}
              fieldName="description"
              height="100px"
              label="Description"
              placeholder="Enter additional information"
              setValueObject={setExpenseCategory}
              setValueVariable="description"
              value={expenseCategory.description}
            />
          </Card>
        }
        cannotDelete={expenseCategoryDetails.expense_category.cannot_delete}
        deleteSuccess={expenseCategoryDelete.success}
        deleteSuccessMessage="Expense category removed."
        deleting={expenseCategoryDelete.loading}
        error={expenseCategoryUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update expense category"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing expense categories"
        icon="edit"
        isOpen={updateExpenseCategoryDrawerIsOpen}
        loading={expenseCategoryDetails.loading}
        onDelete={
          !expenseCategoryDetails.expense_category.default &&
          deleteExpenseCategory
        }
        onSave={
          !expenseCategoryDetails.expense_category.default &&
          updateExpenseCategory
        }
        refreshList={() => {
          dispatch(listExpenseCategoriesAction({ ...expenseCategoryQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteExpenseCategoryAction());

          dispatch(resetUpdateExpenseCategoryAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={expenseCategoryUpdate.success}
        saveSuccessMessage="Expense category updated."
        saving={expenseCategoryUpdate.loading}
        setIsOpen={setUpdateExpenseCategoryDrawerIsOpen}
        title="Update expense category"
      />

      {/* New leave category form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the leave category</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={leaveCategoryCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Enter a name for the leave category"
                  setValueObject={setLeaveCategory}
                  setValueVariable="name"
                  value={leaveCategory.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Code */}
                <FormDrawerTextField2
                  error={leaveCategoryCreate.error}
                  fieldName="code"
                  helperText="Unique identifier from the payroll system"
                  icon="barcode"
                  label="Code"
                  placeholder="Enter the category code"
                  setValueObject={setLeaveCategory}
                  setValueVariable="code"
                  value={leaveCategory.code}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={leaveCategoryCreate.error}
              fieldName="description"
              height="100px"
              label="Description"
              placeholder="Enter additional information"
              setValueObject={setLeaveCategory}
              setValueVariable="description"
              value={leaveCategory.description}
            />
          </Card>
        }
        error={leaveCategoryCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add leave category"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing leave categories"
        icon="edit"
        isOpen={addLeaveCategoryDrawerIsOpen}
        onSave={addLeaveCategory}
        refreshList={() => {
          dispatch(listLeaveCategoriesAction({ organisation: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateLeaveCategoryAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={leaveCategoryCreate.success}
        saveSuccessMessage="Leave category added."
        saving={leaveCategoryCreate.loading}
        setIsOpen={setAddLeaveCategoryDrawerIsOpen}
        title="Add leave category"
      />

      {/* Existing leave category form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the leave category</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={leaveCategoryUpdate.error}
                  fieldName="name"
                  icon="label"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Enter a name for the leave category"
                  setValueObject={setLeaveCategory}
                  setValueVariable="name"
                  value={leaveCategory.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Code */}
                <FormDrawerTextField2
                  error={leaveCategoryUpdate.error}
                  fieldName="code"
                  helperText="Unique identifier from the payroll system"
                  icon="barcode"
                  label="Code"
                  placeholder="Enter the category code"
                  setValueObject={setLeaveCategory}
                  setValueVariable="code"
                  value={leaveCategory.code}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={leaveCategoryUpdate.error}
              fieldName="description"
              height="100px"
              label="Description"
              placeholder="Enter additional information"
              setValueObject={setLeaveCategory}
              setValueVariable="description"
              value={leaveCategory.description}
            />
          </Card>
        }
        cannotDelete={leaveCategoryDetails.leave_category.cannot_delete}
        deleteSuccess={leaveCategoryDelete.success}
        deleteSuccessMessage="Leave category removed."
        deleting={leaveCategoryDelete.loading}
        error={leaveCategoryUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update leave category"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing leave categories"
        icon="edit"
        isOpen={updateLeaveCategoryDrawerIsOpen}
        loading={leaveCategoryDetails.loading}
        onDelete={
          !leaveCategoryDetails.leave_category.default && deleteLeaveCategory
        }
        onSave={
          !leaveCategoryDetails.leave_category.default && updateLeaveCategory
        }
        refreshList={() => {
          dispatch(listLeaveCategoriesAction({ ...leaveCategoryQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteLeaveCategoryAction());

          dispatch(resetUpdateLeaveCategoryAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={leaveCategoryUpdate.success}
        saveSuccessMessage="Leave category updated."
        saving={leaveCategoryUpdate.loading}
        setIsOpen={setUpdateLeaveCategoryDrawerIsOpen}
        title="Update leave category"
      />

      {/* New rank form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Configure the rank</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Name */}
            <FormDrawerTextField2
              error={rankCreate.error}
              fieldName="name"
              icon="label"
              label="Name"
              labelInfo="(required)"
              placeholder="Give the rank a name"
              setValueObject={setRank}
              setValueVariable="name"
              value={rank.name}
            />
          </Card>
        }
        error={rankCreate.error}
        errorMessage="We couldn't add the rank. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Create rank"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Defining ranks"
        icon="edit"
        isOpen={addRankDrawerIsOpen}
        onSave={addRank}
        refreshList={() => {
          dispatch(
            listRanksAction({
              organisation: id,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={rankCreate.success}
        saveSuccessMessage="Rank created."
        saving={rankCreate.loading}
        setIsOpen={setAddRankDrawerIsOpen}
        size="small"
        title="Create rank"
      />

      {/* Existing rank form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Configure the rank</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Name */}
                    <FormDrawerTextField2
                      error={rankUpdate.error}
                      fieldName="name"
                      icon="label"
                      label="Name"
                      labelInfo="(required)"
                      placeholder="Give the rank a name"
                      setValueObject={setRank}
                      setValueVariable="name"
                      value={rank.name}
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: "10px",
                      paddingTop: "24px",
                      width: "40%",
                    }}
                  >
                    {rankDetails.rank.default && (
                      <Tag intent="primary" large>
                        Default
                      </Tag>
                    )}
                  </div>
                </div>

                {/* Description */}
                <FormDrawerTextAreaField3
                  error={rankUpdate.error}
                  fieldName="description"
                  height="100px"
                  label="Description"
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setRank}
                  setValueVariable="description"
                  value={rank.description}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                <Callout intent="primary" style={{ margin: "0 0 20px 0" }}>
                  <p>
                    Define hourly cost rates by entering the following
                    information:
                  </p>

                  <UL>
                    <li>
                      Period start: Date from which the cost rate applies.
                    </li>

                    <li>
                      Average salary: Estimated annual salary for the role.
                    </li>

                    <li>
                      Overhead allocation: Percentage salary adjustment to
                      calculate the full cost to the business.
                    </li>

                    <li>Billable hours: Estimated billable hours per annum.</li>
                  </UL>
                </Callout>
              </div>
            </div>

            {/* Rank cost rates */}
            <div style={{ margin: "20px 0 20px 0" }}>
              {/* Error alerts */}
              {rankCostRateCreate.error && (
                <ErrorList
                  error={rankCostRateUpdate.error}
                  message="Unable to create the cost rate."
                />
              )}

              {rankCostRateDelete.error && (
                <ErrorList
                  error={rankCostRateUpdate.error}
                  message="Unable to delete the cost rate."
                />
              )}

              {rankCostRateUpdate.error && (
                <ErrorList
                  error={rankCostRateUpdate.error}
                  message="Unable to update the cost rate."
                />
              )}

              {/* Cannot delete warning */}
              {editRankCostRateId &&
                rankCostRateDetails.rank_cost_rate.default && (
                  <Callout intent="primary" style={{ margin: "0 0 10px 0" }}>
                    The default cost rate for this rank cannot be removed.
                  </Callout>
                )}

              {/* Data table */}
              <EditableTableContainer
                activeRecord={editRankCostRateId}
                body={
                  <>
                    {rankCostRateList.rank_cost_rates.map((cost_rate) => {
                      return (
                        <tr
                          key={cost_rate.id}
                          onClick={() => {
                            if (
                              !editRankCostRateId &&
                              !showNewRankCostRateForm
                            ) {
                              if (cost_rate.default) {
                                AppToaster.show({
                                  icon: "warning-sign",
                                  intent: "warning",
                                  message:
                                    "You cannot edit the default cost rate.",
                                });
                              } else {
                                dispatch(
                                  listRankCostRateDetailsAction(cost_rate.id)
                                );

                                setEditRankCostRateId(cost_rate.id);
                              }
                            }
                          }}
                        >
                          {/* Period start */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editRankCostRateId === cost_rate.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerDateField2
                                  error={rankCostRateUpdate.error}
                                  fieldName="period_start"
                                  icon="calendar"
                                  placeholder="Enter date"
                                  setValueObject={setRankCostRate}
                                  setValueVariable="periodStart"
                                  value={rankCostRate.periodStart}
                                />
                              </div>
                            ) : (
                              <TableDate date={cost_rate.period_start} small />
                            )}
                          </td>

                          {/* Average salary */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editRankCostRateId === cost_rate.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerNumericField2
                                  error={rankCostRateUpdate.error}
                                  fieldName="average_salary"
                                  leftElement={
                                    <Tag
                                      minimal
                                      style={{
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {
                                        organisationDetails.organisation
                                          .currency_short
                                      }
                                    </Tag>
                                  }
                                  majorStepSize={100000}
                                  min={0}
                                  minorStepSize={1000}
                                  placeholder="Enter salary"
                                  setValueObject={setRankCostRate}
                                  setValueVariable="averageSalary"
                                  stepSize={10000}
                                  value={rankCostRate.averageSalary}
                                />
                              </div>
                            ) : (
                              <TableNumber
                                number={cost_rate.average_salary}
                                tagText={
                                  organisationDetails.organisation
                                    .currency_short
                                }
                              />
                            )}
                          </td>

                          {/* Overhead allocation */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editRankCostRateId === cost_rate.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerNumericField2
                                  error={rankCostRateUpdate.error}
                                  fieldName="overhead_allocation"
                                  icon="percentage"
                                  majorStepSize={100}
                                  min={0}
                                  minorStepSize={1}
                                  placeholder="Enter allocation"
                                  setValueObject={setRankCostRate}
                                  setValueVariable="overheadAllocation"
                                  stepSize={10}
                                  value={rankCostRate.overheadAllocation}
                                />
                              </div>
                            ) : (
                              <TableNumber
                                number={cost_rate.overhead_allocation}
                                tagText="%"
                              />
                            )}
                          </td>

                          {/* Billable hours */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editRankCostRateId === cost_rate.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerDurationField
                                  error={rankCostRateUpdate.error}
                                  fieldName="billable_minutes"
                                  icon="stopwatch"
                                  max={8760}
                                  placeholder="Enter hours"
                                  setValueObject={setRankCostRate}
                                  setValueVariable="billableMinutes"
                                  value={rankCostRate.billableMinutes}
                                />
                              </div>
                            ) : (
                              <TableNumber
                                number={Number(
                                  convertMinutesToHoursAndMinutes(
                                    cost_rate.billable_minutes
                                  ).split(":")[0]
                                )}
                                tagText="Hours"
                              />
                            )}
                          </td>

                          {/* Cost rate */}
                          <td style={{ verticalAlign: "middle" }}>
                            <TableNumber
                              number={cost_rate.cost_rate}
                              tagText={
                                organisationDetails.organisation.currency_short
                              }
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </>
                }
                //bordered={false}
                count={rankCostRateList.count}
                createSuccess={rankCostRateCreate.success}
                createSuccessMessage="Cost rate created"
                deleteSuccess={rankCostRateDelete.success}
                deleteSuccessMessage="Cost rate deleted."
                head={
                  <tr>
                    {/* Period start */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Period start
                    </th>

                    {/* Average salary */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Average salary
                    </th>

                    {/* Overhead allocation */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Overhead allocation
                    </th>

                    {/* Billable hours */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Billable hours
                    </th>

                    {/* Cost rate */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "125px",
                      }}
                    >
                      Cost rate
                    </th>
                  </tr>
                }
                interactive={!editRankCostRateId && !showNewRankCostRateForm}
                loading={rankCostRateList.loading}
                newForm={
                  <tr>
                    {/* Period start */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerDateField2
                          error={rankCostRateCreate.error}
                          fieldName="period_start"
                          icon="calendar"
                          placeholder="Enter date"
                          setValueObject={setRankCostRate}
                          setValueVariable="periodStart"
                          value={rankCostRate.periodStart}
                        />
                      </div>
                    </td>

                    {/* Average salary */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerNumericField2
                          error={rankCostRateCreate.error}
                          fieldName="average_salary"
                          leftElement={
                            <Tag
                              minimal
                              style={{ textAlign: "center", width: "50px" }}
                            >
                              {organisationDetails.organisation.currency_short}
                            </Tag>
                          }
                          majorStepSize={100000}
                          min={0}
                          minorStepSize={1000}
                          placeholder="Enter salary"
                          setValueObject={setRankCostRate}
                          setValueVariable="averageSalary"
                          stepSize={10000}
                          value={rankCostRate.averageSalary}
                        />
                      </div>
                    </td>

                    {/* Overhead allocation */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerNumericField2
                          error={rankCostRateCreate.error}
                          fieldName="overhead_allocation"
                          icon="percentage"
                          majorStepSize={100}
                          min={0}
                          minorStepSize={1}
                          placeholder="Enter allocation"
                          setValueObject={setRankCostRate}
                          setValueVariable="overheadAllocation"
                          stepSize={10}
                          value={rankCostRate.overheadAllocation}
                        />
                      </div>
                    </td>

                    {/* Billable hours */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerDurationField
                          error={rankCostRateCreate.error}
                          fieldName="billable_minutes"
                          icon="stopwatch"
                          max={8760}
                          placeholder="Enter hours"
                          setValueObject={setRankCostRate}
                          setValueVariable="billableMinutes"
                          value={rankCostRate.billableMinutes}
                        />
                      </div>
                    </td>

                    {/*  Cost rate */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={
                          (rankCostRate.averageSalary *
                            (1 + rankCostRate.overheadAllocation / 100)) /
                          (rankCostRate.billableMinutes / 60)
                        }
                        tagText={
                          organisationDetails.organisation.currency_short
                        }
                      />
                    </td>
                  </tr>
                }
                noMargin
                noResultsMessage="Add cost rates."
                noResultsTitle="No cost rates found"
                page={rankCostRateQuery.page}
                refreshList={() => {
                  dispatch(
                    listRankCostRatesAction({
                      rank: rankDetails.rank.id,
                    })
                  );
                }}
                resetAction={() => {
                  dispatch(resetCreateRankCostRateAction());

                  dispatch(resetDeleteRankCostRateAction());

                  dispatch(resetUpdateRankCostRateAction());
                }}
                resetFormVariables={() => {
                  setRankCostRate({
                    ...rankCostRateDefault,
                  });
                }}
                setActiveRecord={setEditRankCostRateId}
                setPageObject={setRankCostRateQuery}
                setPageVariable="page"
                setShowNewForm={setShowNewRankCostRateForm}
                showNewForm={showNewRankCostRateForm}
                striped={false}
                updateSuccess={rankCostRateUpdate.success}
                updateSuccessMessage="Cost rate updated."
              />

              {/* Buttons */}
              <div style={{ margin: "15px 0 0 0" }}>
                {/* Neither adding nor editing record */}
                {!editRankCostRateId && !showNewRankCostRateForm && (
                  <Button
                    icon="add"
                    intent="primary"
                    onClick={() => {
                      setShowNewRankCostRateForm(true);
                    }}
                    text="Add"
                  />
                )}

                {/* Adding record */}
                {showNewRankCostRateForm && (
                  <>
                    <Button
                      icon="add"
                      intent="success"
                      onClick={() => {
                        dispatch(resetCreateRankCostRateAction());

                        addRankCostRate();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Add"
                    />

                    <Button
                      icon="delete"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetCreateRankCostRateAction());

                        setShowNewRankCostRateForm(false);

                        resetDrawerVariables();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />
                  </>
                )}

                {/* Editing record */}
                {editRankCostRateId && (
                  <>
                    <Button
                      icon="updated"
                      intent="success"
                      onClick={() => {
                        dispatch(resetUpdateRankCostRateAction());

                        updateRankCostRate();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Update"
                    />

                    <Button
                      icon="disable"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetUpdateRankCostRateAction());

                        setEditRankCostRateId(null);

                        resetDrawerVariables();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />

                    <Button
                      disabled={
                        rankCostRateDetails.rank_cost_rate.cannot_delete
                      }
                      icon="delete"
                      intent="danger"
                      onClick={() => {
                        dispatch(resetUpdateRankCostRateAction());

                        deleteRankCostRate();
                      }}
                      text="Remove"
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
        }
        cannotDelete={rankDetails.rank.cannot_delete}
        deleteSuccess={rankDelete.success}
        deleteSuccessMessage="Rank deleted."
        deleting={rankDelete.loading}
        disabled={editRankCostRateId || showNewRankCostRateForm}
        error={rankUpdate.error}
        errorMessage="We couldn't update the rank. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Update rank"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Defining ranks"
        icon="edit"
        isOpen={updateRankDrawerIsOpen}
        loading={rankDetails.loading}
        onDelete={!rankDetails.rank.default && deleteRank}
        onSave={!rankDetails.rank.default && updateRank}
        refreshList={() => {
          dispatch(listRanksAction({ ...rankQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteRankAction());

          dispatch(resetUpdateRankAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={rankUpdate.success}
        saveSuccessMessage="Rank updated."
        saving={rankUpdate.loading}
        setIsOpen={setUpdateRankDrawerIsOpen}
        size="large"
        title="Update rank"
      />

      {/* New booking code form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Set up the booking code</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Name */}
                  <FormDrawerTextField2
                    error={bookingCodeCreate.error}
                    fieldName="name"
                    icon="label"
                    label="Name"
                    labelInfo="(required)"
                    placeholder="Enter a name for the booking code"
                    setValueObject={setBookingCode}
                    setValueVariable="name"
                    value={bookingCode.name}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "20%",
                  }}
                >
                  {/* Year */}
                  <FormDrawerNumericField2
                    error={bookingCodeCreate.error}
                    fieldName="year"
                    icon="calendar"
                    label="Year"
                    majorStepSize={1}
                    max={new Date().getFullYear() + 3}
                    min={new Date().getFullYear() - 3}
                    minorStepSize={1}
                    placeholder="Enter the financial year"
                    setValueObject={setBookingCode}
                    setValueVariable="year"
                    stepSize={1}
                    value={bookingCode.year}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    paddingTop: "20px",
                    width: "30%",
                  }}
                >
                  {/* Open */}
                  <FormDrawerSwitchField
                    error={bookingCodeCreate.error}
                    fieldName="open"
                    label="Open"
                    setValueObject={setBookingCode}
                    setValueVariable="open"
                    value={bookingCode.open}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Leave category */}
                  <FormDrawerSelectField2
                    error={bookingCodeCreate.error}
                    fieldName="leave_category"
                    icon="key-escape"
                    info={
                      <LeaveCategoryInfo
                        categoryId={bookingCode.leaveCategory}
                        query={leaveCategoryQueryDefault}
                      />
                    }
                    items={leaveCategoryList.leave_categories}
                    label="Leave category"
                    loading={leaveCategoryList.loading}
                    noOptionOnClick
                    placeholder="Select a leave category"
                    query={leaveCategoryQuery.searchString}
                    setQueryObject={setLeaveCategoryQuery}
                    setQueryVariable="searchString"
                    setValueObject={setBookingCode}
                    setValueIdVariable="leaveCategory"
                    setValueNameVariable="leaveCategoryName"
                    showInfo={bookingCode.leaveCategory}
                    value={bookingCode.leaveCategoryName}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "60%" }}>
                      {/* Budget */}
                      <FormDrawerNumericField2
                        disabled={bookingCode.leaveCategory}
                        error={bookingCodeCreate.error}
                        fieldName="budget"
                        label="Budget"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the budget"
                        setValueObject={setBookingCode}
                        setValueVariable="budget"
                        stepSize={10000}
                        value={bookingCode.budget}
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: "10px",
                        paddingTop: "20px",
                        width: "40%",
                      }}
                    >
                      {/* Billable */}
                      <FormDrawerSwitchField
                        disabled={bookingCode.leaveCategory}
                        error={bookingCodeCreate.error}
                        fieldName="billable"
                        label="Billable"
                        setValueObject={setBookingCode}
                        setValueVariable="billable"
                        value={bookingCode.billable}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  <Callout intent="primary">
                    Select a leave category if you would like to use this code
                    to manage leave bookings. Bookings to this code will not
                    accrue revenue or cost.
                  </Callout>
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={bookingCodeCreate.error}
                fieldName="comments"
                height="100px"
                label="Comments"
                placeholder="Enter additional information"
                setValueObject={setBookingCode}
                setValueVariable="comments"
                value={bookingCode.comments}
              />
            </Card>

            {!bookingCode.leaveCategory && (
              <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
                <H5>Add tasks</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    <EditableList
                      icon="gantt-chart"
                      items={bookingCode.tasks}
                      placeholder="Add task"
                      setValueObject={setBookingCode}
                      setValueVariable="tasks"
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}>
                    <Callout intent="primary">
                      Staff can select the tasks that you define when they book
                      time to this booking code.
                    </Callout>
                  </div>
                </div>
              </Card>
            )}

            {!bookingCode.leaveCategory && (
              <Card style={{ width: "100%" }}>
                <H5>Link to an engagement or proposal</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Engagement */}
                    <FormDrawerSelectField2
                      disabled={
                        bookingCode.leaveCategory || bookingCode.proposal
                      }
                      error={bookingCodeCreate.error}
                      fieldName="engagement"
                      icon="gantt-chart"
                      info={
                        <EngagementInfo
                          engagementId={bookingCode.engagement}
                          query={engagementQueryDefault}
                        />
                      }
                      items={engagementList.engagements}
                      label="Engagement"
                      loading={engagementList.loading}
                      noOptionOnClick
                      placeholder="Select an engagement"
                      query={engagementQuery.searchString}
                      setQueryObject={setEngagementQuery}
                      setQueryVariable="searchString"
                      setValueObject={setBookingCode}
                      setValueIdVariable="engagement"
                      setValueNameVariable="engagementName"
                      showInfo={bookingCode.engagement}
                      value={bookingCode.engagementName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Proposal */}
                    <FormDrawerSelectField2
                      disabled={
                        bookingCode.engagement || bookingCode.leaveCategory
                      }
                      error={bookingCodeCreate.error}
                      fieldName="proposal"
                      icon="manual"
                      info={
                        <ProposalInfo
                          proposalId={bookingCode.proposal}
                          query={proposalQueryDefault}
                        />
                      }
                      items={proposalList.proposals}
                      label="Proposal"
                      loading={proposalList.loading}
                      noOptionOnClick
                      placeholder="Select a proposal"
                      query={proposalQuery.searchString}
                      setQueryObject={setProposalQuery}
                      setQueryVariable="searchString"
                      setValueObject={setBookingCode}
                      setValueIdVariable="proposal"
                      setValueNameVariable="proposalName"
                      showInfo={bookingCode.proposal}
                      value={bookingCode.proposalName}
                    />
                  </div>
                </div>

                {bookingCode.engagement && (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "40%" }}>
                      {/* Fixed-price */}
                      <FormDrawerSwitchField
                        disabled={bookingCode.leaveCategory}
                        error={bookingCodeCreate.error}
                        fieldName="fixed_price"
                        label="Fixed-price"
                        setValueObject={setBookingCode}
                        setValueVariable="fixedPrice"
                        value={bookingCode.fixedPrice}
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: "10px",
                        paddingTop: "20px",
                        width: "60%",
                      }}
                    >
                      <Callout intent="primary">
                        Setting a booking code as fixed-price disables invoicing
                        of time and expenses. The system will invoice milestone
                        payments only.
                      </Callout>
                    </div>
                  </div>
                )}
              </Card>
            )}
          </div>
        }
        error={bookingCodeCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add booking code"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing booking codes"
        icon="edit"
        isOpen={addBookingCodeDrawerIsOpen}
        onSave={addBookingCode}
        refreshList={() => {
          dispatch(listBookingCodesAction({ organisation: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateBookingCodeAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={bookingCodeCreate.success}
        saveSuccessMessage="Booking code added."
        saving={bookingCodeCreate.loading}
        setIsOpen={setAddBookingCodeDrawerIsOpen}
        title="Add booking code"
      />

      {/* Existing booking code form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Set up the booking code</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <Callout
                children={
                  <div>Code: {bookingCodeDetails.booking_code.code}</div>
                }
                icon="barcode"
                style={{ margin: "20px 0 20px 0" }}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Name */}
                  <FormDrawerTextField2
                    error={bookingCodeUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Name"
                    labelInfo="(required)"
                    placeholder="Enter a name for the booking code"
                    setValueObject={setBookingCode}
                    setValueVariable="name"
                    value={bookingCode.name}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "20%",
                  }}
                >
                  {/* Year */}
                  <FormDrawerNumericField2
                    error={bookingCodeUpdate.error}
                    fieldName="year"
                    icon="calendar"
                    label="Year"
                    majorStepSize={1}
                    max={new Date().getFullYear() + 3}
                    min={new Date().getFullYear() - 3}
                    minorStepSize={1}
                    placeholder="Enter the financial year"
                    setValueObject={setBookingCode}
                    setValueVariable="year"
                    stepSize={1}
                    value={bookingCode.year}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    paddingTop: "20px",
                    width: "30%",
                  }}
                >
                  {/* Open */}
                  <FormDrawerSwitchField
                    error={bookingCodeUpdate.error}
                    fieldName="open"
                    label="Open"
                    setValueObject={setBookingCode}
                    setValueVariable="open"
                    value={bookingCode.open}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Leave category */}
                  <FormDrawerSelectField2
                    disabled
                    error={bookingCodeUpdate.error}
                    fieldName="leave_category"
                    icon="key-escape"
                    info={
                      <LeaveCategoryInfo
                        categoryId={bookingCode.leaveCategory}
                        query={leaveCategoryQueryDefault}
                      />
                    }
                    items={leaveCategoryList.leave_categories}
                    label="Leave category"
                    loading={leaveCategoryList.loading}
                    noOptionOnClick
                    placeholder="Select a leave category"
                    query={leaveCategoryQuery.searchString}
                    setQueryObject={setLeaveCategoryQuery}
                    setQueryVariable="searchString"
                    setValueObject={setBookingCode}
                    setValueIdVariable="leaveCategory"
                    setValueNameVariable="leaveCategoryName"
                    showInfo={bookingCode.leaveCategory}
                    value={bookingCode.leaveCategoryName}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "60%" }}>
                      {/* Budget */}
                      <FormDrawerNumericField2
                        disabled={bookingCode.leaveCategory}
                        error={bookingCodeUpdate.error}
                        fieldName="budget"
                        label="Budget"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the budget"
                        setValueObject={setBookingCode}
                        setValueVariable="budget"
                        stepSize={10000}
                        value={bookingCode.budget}
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: "10px",
                        paddingTop: "20px",
                        width: "40%",
                      }}
                    >
                      {/* Billable */}
                      <FormDrawerSwitchField
                        disabled={bookingCode.leaveCategory}
                        error={bookingCodeUpdate.error}
                        fieldName="billable"
                        label="Billable"
                        setValueObject={setBookingCode}
                        setValueVariable="billable"
                        value={bookingCode.billable}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  <Callout intent="primary">
                    Select a leave category if you would like to use this code
                    to manage leave bookings. Bookings to this code will not
                    accrue revenue or cost.
                  </Callout>
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={bookingCodeUpdate.error}
                fieldName="comments"
                height="100px"
                label="Comments"
                placeholder="Enter additional information"
                setValueObject={setBookingCode}
                setValueVariable="comments"
                value={bookingCode.comments}
              />
            </Card>

            {!bookingCode.leaveCategory && (
              <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
                <H5>Add tasks</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    <EditableList
                      icon="gantt-chart"
                      items={bookingCode.tasks}
                      placeholder="Add task"
                      setValueObject={setBookingCode}
                      setValueVariable="tasks"
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}>
                    <Callout intent="primary">
                      Staff can select the tasks that you define when they book
                      time to this booking code.
                    </Callout>
                  </div>
                </div>
              </Card>
            )}

            {!bookingCode.leaveCategory && (
              <Card style={{ width: "100%" }}>
                <H5>Link to an engagement or proposal</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Engagement */}
                    <FormDrawerSelectField2
                      disabled={bookingCode.proposal}
                      error={bookingCodeUpdate.error}
                      fieldName="engagement"
                      icon="gantt-chart"
                      info={
                        <EngagementInfo
                          engagementId={bookingCode.engagement}
                          query={engagementQueryDefault}
                        />
                      }
                      items={engagementList.engagements}
                      label="Engagement"
                      loading={engagementList.loading}
                      noOptionOnClick
                      placeholder="Select an engagement"
                      query={engagementQuery.searchString}
                      setQueryObject={setEngagementQuery}
                      setQueryVariable="searchString"
                      setValueObject={setBookingCode}
                      setValueIdVariable="engagement"
                      setValueNameVariable="engagementName"
                      showInfo={bookingCode.engagement}
                      value={bookingCode.engagementName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Proposal */}
                    <FormDrawerSelectField2
                      disabled={bookingCode.engagement}
                      error={bookingCodeUpdate.error}
                      fieldName="proposal"
                      icon="manual"
                      info={
                        <ProposalInfo
                          proposalId={bookingCode.proposal}
                          query={proposalQueryDefault}
                        />
                      }
                      items={proposalList.proposals}
                      label="Proposal"
                      loading={proposalList.loading}
                      noOptionOnClick
                      placeholder="Select a proposal"
                      query={proposalQuery.searchString}
                      setQueryObject={setProposalQuery}
                      setQueryVariable="searchString"
                      setValueObject={setBookingCode}
                      setValueIdVariable="proposal"
                      setValueNameVariable="proposalName"
                      showInfo={bookingCode.proposal}
                      value={bookingCode.proposalName}
                    />
                  </div>
                </div>

                {bookingCode.engagement && (
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "40%" }}>
                      {/* Fixed-price */}
                      <FormDrawerSwitchField
                        error={bookingCodeUpdate.error}
                        fieldName="fixed_price"
                        label="Fixed-price"
                        setValueObject={setBookingCode}
                        setValueVariable="fixedPrice"
                        value={bookingCode.fixedPrice}
                      />
                    </div>

                    <div
                      style={{
                        marginLeft: "10px",
                        paddingTop: "20px",
                        width: "60%",
                      }}
                    >
                      <Callout intent="primary">
                        Setting a booking code as fixed-price disables invoicing
                        of time and expenses. The system will invoice milestone
                        payments only.
                      </Callout>
                    </div>
                  </div>
                )}
              </Card>
            )}
          </div>
        }
        cannotDelete={bookingCodeDetails.booking_code.cannot_delete}
        deleteSuccess={bookingCodeDelete.success}
        deleteSuccessMessage="Booking code removed."
        deleting={bookingCodeDelete.loading}
        error={bookingCodeUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update booking code"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing booking codes"
        icon="edit"
        isOpen={updateBookingCodeDrawerIsOpen}
        loading={bookingCodeDetails.loading}
        onDelete={deleteBookingCode}
        onSave={updateBookingCode}
        refreshList={() => {
          dispatch(listBookingCodesAction({ ...bookingCodeQuery }));

          dispatch(listOrganisationDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteBookingCodeAction());

          dispatch(resetUpdateBookingCodeAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={bookingCodeUpdate.success}
        saveSuccessMessage="Booking code updated."
        saving={bookingCodeUpdate.loading}
        setIsOpen={setUpdateBookingCodeDrawerIsOpen}
        title="Update booking code"
      />

      {/* Existing reimbursement form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the reimbursement status</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <Callout
              children={
                <div>Claim: {reimbursementDetails.reimbursement.name}</div>
              }
              icon="bank-account"
              style={{ margin: "20px 0 20px 0" }}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Payment status */}
                <FormDrawerSelectSimpleField3
                  error={reimbursementUpdate.error}
                  fieldName="status"
                  intent={
                    reimbursement.status === "Not paid" ? "danger" : "success"
                  }
                  label="Status"
                  options={[
                    { id: 1, icon: "small-cross", value: "Not paid" },
                    {
                      id: 2,
                      icon: "small-tick",
                      value: "Paid",
                    },
                  ]}
                  setValueObject={setReimbursement}
                  setValueVariable="status"
                  value={reimbursement.status}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "30%",
                }}
              >
                {/* Amount */}
                <FormDrawerNumericField2
                  error={reimbursementUpdate.error}
                  fieldName="value"
                  label="Amount"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {organisationDetails.organisation.currency_short}
                    </Tag>
                  }
                  majorStepSize={1000}
                  min={0}
                  minorStepSize={10}
                  placeholder="Enter the amount reimbursed"
                  setValueObject={setReimbursement}
                  setValueVariable="value"
                  stepSize={100}
                  value={reimbursement.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "30%" }}>
                {/* Payment date */}
                <FormDrawerDateField2
                  error={reimbursementUpdate.error}
                  fieldName="paid"
                  icon="calendar"
                  label="Payment date"
                  placeholder="Enter the paid date"
                  setValueObject={setReimbursement}
                  setValueVariable="paid"
                  value={reimbursement.paid}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={reimbursementUpdate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Enter your comments on the reimbursement"
              setValueObject={setReimbursement}
              setValueVariable="comments"
              value={reimbursement.comments}
            />
          </Card>
        }
        error={reimbursementUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update reimbursement"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing reimbursements"
        icon="edit"
        isOpen={updateReimbursementDrawerIsOpen}
        loading={reimbursementDetails.loading}
        onSave={updateReimbursement}
        refreshList={() => {
          dispatch(listReimbursementsAction({ ...reimbursementQuery }));
        }}
        resetAction={() => {
          dispatch(resetUpdateReimbursementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={reimbursementUpdate.success}
        saveSuccessMessage="Reimbursement updated."
        saving={reimbursementUpdate.loading}
        setIsOpen={setUpdateReimbursementDrawerIsOpen}
        title="Update reimbursement"
      />

      {/* New approval role form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Name the approval role</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Name */}
                  <FormDrawerTextField2
                    error={approvalRoleCreate.error}
                    fieldName="name"
                    icon="hat"
                    label="Name"
                    labelInfo="(required)"
                    placeholder="Enter a name for the role"
                    setValueObject={setApprovalRole}
                    setValueVariable="name"
                    value={approvalRole.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define client decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Client account plan */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_client_account_plan"
                helperText="Approve account plans, including account development strategies and targets"
                label="Client account plan"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveClientAccountPlan"
                value={approvalRole.canApproveClientAccountPlan}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Client account plan threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_client_account_plan_threshold"
                    helperText="Planned annual revenue from the client"
                    label="Client account plan threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveClientAccountPlanThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveClientAccountPlanThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {false && (
                <>
                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Client survey */}
                  <FormDrawerSwitchField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_client_survey"
                    helperText="Authorise surveys used to collect feedback from client stakeholders"
                    label="Client survey"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveClientSurvey"
                    value={approvalRole.canApproveClientSurvey}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Client survey threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleCreate.error}
                        fieldName="can_approve_client_survey_threshold"
                        helperText="Planned annual revenue from the client"
                        label="Client survey threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveClientSurveyThreshold"
                        stepSize={10000}
                        value={approvalRole.canApproveClientSurveyThreshold}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>
                </>
              )}
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define engagement decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Engagement baseline */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_engagement_baseline"
                helperText="Approve engagement baselines"
                label="Engagement baseline"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveEngagementBaseline"
                value={approvalRole.canApproveEngagementBaseline}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Engagement baseline threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_engagement_baseline_threshold"
                    helperText="Value of engagement"
                    label="Engagement baseline threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveEngagementBaselineThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveEngagementBaselineThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {false && (
                <>
                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Engagement deliverable */}
                  <FormDrawerSwitchField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_engagement_deliverable"
                    helperText="Authorise the team to submit engagement deliverables"
                    label="Engagement deliverable"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveEngagementDeliverable"
                    value={approvalRole.canApproveEngagementDeliverable}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Engagement deliverable threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleCreate.error}
                        fieldName="can_approve_engagement_deliverable_threshold"
                        helperText="Value of engagement"
                        label="Engagement deliverable threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveEngagementDeliverableThreshold"
                        stepSize={10000}
                        value={
                          approvalRole.canApproveEngagementDeliverableThreshold
                        }
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>
                </>
              )}

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Engagement close */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_engagement_close"
                helperText="Authorise the team to close engagements"
                label="Engagement close"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveEngagementClose"
                value={approvalRole.canApproveEngagementClose}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Engagement close threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_engagement_close_threshold"
                    helperText="Value of engagement"
                    label="Engagement close threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveEngagementCloseThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveEngagementCloseThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define invoice decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Invoice */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_invoice"
                helperText="Approve invoices"
                label="Invoice"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveInvoice"
                value={approvalRole.canApproveInvoice}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Invoice threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_invoice_threshold"
                    helperText="Value of invoice"
                    label="Invoice threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveInvoiceThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveInvoiceThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define proposal decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Proposal qualification */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_proposal_qualification"
                helperText="Decide whether to commit resources to proposals"
                label="Proposal qualification"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveProposalQualification"
                value={approvalRole.canApproveProposalQualification}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal qualification threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_proposal_qualification_threshold"
                    helperText="Estimated value of proposal"
                    label="Proposal qualification threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalQualificationThreshold"
                    stepSize={10000}
                    value={
                      approvalRole.canApproveProposalQualificationThreshold
                    }
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {false && (
                <>
                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Proposal strategy */}
                  <FormDrawerSwitchField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_proposal_strategy"
                    helperText="Approve proposal win themes"
                    label="Proposal strategy"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalStrategy"
                    value={approvalRole.canApproveProposalStrategy}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Proposal strategy threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleCreate.error}
                        fieldName="can_approve_proposal_strategy_threshold"
                        helperText="Estimated value of proposal"
                        label="Proposal strategy threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveProposalStrategyThreshold"
                        stepSize={10000}
                        value={approvalRole.canApproveProposalStrategyThreshold}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>

                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Proposal solution */}
                  <FormDrawerSwitchField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_proposal_solution"
                    helperText="Approve proposal services and team members"
                    label="Proposal solution"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalSolution"
                    value={approvalRole.canApproveProposalSolution}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Proposal solution threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleCreate.error}
                        fieldName="can_approve_proposal_solution_threshold"
                        helperText="Estimated value of proposal"
                        label="Proposal solution threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveProposalSolutionThreshold"
                        stepSize={10000}
                        value={approvalRole.canApproveProposalSolutionThreshold}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>

                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Proposal commercials */}
                  <FormDrawerSwitchField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_proposal_commercials"
                    helperText="Approve proposal legal terms and pricing"
                    label="Proposal commercials"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalCommercials"
                    value={approvalRole.canApproveProposalCommercials}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Proposal commercials threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleCreate.error}
                        fieldName="can_approve_proposal_commercials_threshold"
                        helperText="Estimated value of proposal"
                        label="Proposal commercials threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveProposalCommercialsThreshold"
                        stepSize={10000}
                        value={
                          approvalRole.canApproveProposalCommercialsThreshold
                        }
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>
                </>
              )}

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Proposal submission */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_proposal_submission"
                helperText="Authorise the team to submit proposals"
                label="Proposal submission"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveProposalSubmission"
                value={approvalRole.canApproveProposalSubmission}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal submission threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_proposal_submission_threshold"
                    helperText="Estimated value of proposal"
                    label="Proposal submission threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalSubmissionThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveProposalSubmissionThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Define staff decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Staff recruitment */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_staff_recruitment"
                helperText="Approve the hiring of staff"
                label="Staff recruitment"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffRecruitment"
                value={approvalRole.canApproveStaffRecruitment}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff recruitment threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_staff_recruitment_threshold"
                    helperText="Staff salary"
                    label="Staff recruitment threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffRecruitmentThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveStaffRecruitmentThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Staff expense */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_staff_expense"
                helperText="Approve staff expenses"
                label="Staff expense"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffExpense"
                value={approvalRole.canApproveStaffExpense}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff expense threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_staff_expense_threshold"
                    helperText="Value of expense"
                    label="Staff expense threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffExpenseThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveStaffExpenseThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Staff timesheet */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_staff_timesheet"
                helperText="Approve staff timesheets"
                label="Staff timesheet"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffTimesheet"
                value={approvalRole.canApproveStaffTimesheet}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff timesheet threshold */}
                  <FormDrawerDurationField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_staff_timesheet_threshold"
                    helperText="Number of hours booked"
                    icon="stopwatch"
                    label="Staff timesheet threshold"
                    max={168}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffTimesheetThreshold"
                    value={approvalRole.canApproveStaffTimesheetThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {/* Staff leave */}
              <FormDrawerSwitchField
                error={approvalRoleCreate.error}
                fieldName="can_approve_staff_leave"
                helperText="Approve staff leave"
                label="Staff leave"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffLeave"
                value={approvalRole.canApproveStaffLeave}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff leave threshold */}
                  <FormDrawerDurationField
                    error={approvalRoleCreate.error}
                    fieldName="can_approve_staff_leave_threshold"
                    helperText="Number of hours requested"
                    icon="stopwatch"
                    label="Staff leave threshold"
                    max={168}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffLeaveThreshold"
                    value={approvalRole.canApproveStaffLeaveThreshold}
                  />
                </div>
              </div>
            </Card>
          </div>
        }
        error={approvalRoleCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add approval role"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Defining approval roles"
        icon="edit"
        isOpen={addApprovalRoleDrawerIsOpen}
        onSave={addApprovalRole}
        refreshList={() => {
          dispatch(listApprovalRolesAction({ organisation: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateApprovalRoleAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRoleCreate.success}
        saveSuccessMessage="Approval role added."
        saving={approvalRoleCreate.loading}
        setIsOpen={setAddApprovalRoleDrawerIsOpen}
        title="Add approval role"
      />

      {/* Existing approval role form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Name the approval role</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Name */}
                  <FormDrawerTextField2
                    error={approvalRoleUpdate.error}
                    fieldName="name"
                    icon="hat"
                    label="Name"
                    labelInfo="(required)"
                    placeholder="Enter a name for the role"
                    setValueObject={setApprovalRole}
                    setValueVariable="name"
                    value={approvalRole.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define client decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Client account plan */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_client_account_plan"
                helperText="Approve account plans, including account development strategies and targets"
                label="Client account plan"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveClientAccountPlan"
                value={approvalRole.canApproveClientAccountPlan}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Client account plan threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_client_account_plan_threshold"
                    helperText="Planned annual revenue from the client"
                    label="Client account plan threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveClientAccountPlanThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveClientAccountPlanThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {false && (
                <>
                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Client surveys */}
                  <FormDrawerSwitchField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_client_survey"
                    helperText="Authorise surveys used to collect feedback from client stakeholders"
                    label="Client surveys"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveClientSurvey"
                    value={approvalRole.canApproveClientSurvey}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Client survey threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleUpdate.error}
                        fieldName="can_approve_client_survey_threshold"
                        helperText="Planned annual revenue from the client"
                        label="Client survey threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveClientSurveyThreshold"
                        stepSize={10000}
                        value={approvalRole.canApproveClientSurveyThreshold}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>
                </>
              )}
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define engagement decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Engagement baseline */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_engagement_baseline"
                helperText="Approve engagement baselines"
                label="Engagement baseline"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveEngagementBaseline"
                value={approvalRole.canApproveEngagementBaseline}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Engagement baseline threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_engagement_baseline_threshold"
                    helperText="Value of engagement"
                    label="Engagement baseline threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveEngagementBaselineThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveEngagementBaselineThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {false && (
                <>
                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Engagement deliverable */}
                  <FormDrawerSwitchField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_engagement_deliverable"
                    helperText="Authorise the team to submit engagement deliverables"
                    label="Engagement deliverable"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveEngagementDeliverable"
                    value={approvalRole.canApproveEngagementDeliverable}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Engagement deliverable threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleUpdate.error}
                        fieldName="can_approve_engagement_deliverable_threshold"
                        helperText="Value of engagement"
                        label="Engagement deliverable threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveEngagementDeliverableThreshold"
                        stepSize={10000}
                        value={
                          approvalRole.canApproveEngagementDeliverableThreshold
                        }
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>
                </>
              )}

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Engagement close */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_engagement_close"
                helperText="Authorise the team to close engagements"
                label="Engagement close"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveEngagementClose"
                value={approvalRole.canApproveEngagementClose}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Engagement close threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_engagement_close_threshold"
                    helperText="Value of engagement"
                    label="Engagement close threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveEngagementCloseThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveEngagementCloseThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define invoice decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Invoice */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_invoice"
                helperText="Approve invoices"
                label="Invoice"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveInvoice"
                value={approvalRole.canApproveInvoice}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Invoice threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_invoice_threshold"
                    helperText="Value of invoice"
                    label="Invoice threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveInvoiceThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveInvoiceThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define proposal decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Proposal qualification */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_proposal_qualification"
                helperText="Decide whether to commit resources to proposals"
                label="Proposal qualification"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveProposalQualification"
                value={approvalRole.canApproveProposalQualification}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal qualification threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_proposal_qualification_threshold"
                    helperText="Estimated value of proposal"
                    label="Proposal qualification threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalQualificationThreshold"
                    stepSize={10000}
                    value={
                      approvalRole.canApproveProposalQualificationThreshold
                    }
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {false && (
                <>
                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Proposal strategy */}
                  <FormDrawerSwitchField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_proposal_strategy"
                    helperText="Approve proposal win themes"
                    label="Proposal strategy"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalStrategy"
                    value={approvalRole.canApproveProposalStrategy}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Proposal strategy threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleUpdate.error}
                        fieldName="can_approve_proposal_strategy_threshold"
                        helperText="Estimated value of proposal"
                        label="Proposal strategy threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveProposalStrategyThreshold"
                        stepSize={10000}
                        value={approvalRole.canApproveProposalStrategyThreshold}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>

                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Proposal solution */}
                  <FormDrawerSwitchField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_proposal_solution"
                    helperText="Approve proposal services and team members"
                    label="Proposal solution"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalSolution"
                    value={approvalRole.canApproveProposalSolution}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Proposal solution threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleUpdate.error}
                        fieldName="can_approve_proposal_solution_threshold"
                        helperText="Estimated value of proposal"
                        label="Proposal solution threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveProposalSolutionThreshold"
                        stepSize={10000}
                        value={approvalRole.canApproveProposalSolutionThreshold}
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>

                  <Divider style={{ margin: "5px 0 15px 0" }} />

                  {/* Proposal commercials */}
                  <FormDrawerSwitchField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_proposal_commercials"
                    helperText="Approve proposal legal terms and pricing"
                    label="Proposal commercials"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalCommercials"
                    value={approvalRole.canApproveProposalCommercials}
                  />

                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px", width: "50%" }}>
                      {/* Proposal commercials threshold */}
                      <FormDrawerNumericField2
                        error={approvalRoleUpdate.error}
                        fieldName="can_approve_proposal_commercials_threshold"
                        helperText="Estimated value of proposal"
                        label="Proposal commercials threshold"
                        leftElement={
                          <Tag
                            minimal
                            style={{ textAlign: "center", width: "50px" }}
                          >
                            {organisationDetails.organisation.currency_short}
                          </Tag>
                        }
                        majorStepSize={100000}
                        min={0}
                        minorStepSize={1000}
                        placeholder="Enter the approval threshold"
                        setValueObject={setApprovalRole}
                        setValueVariable="canApproveProposalCommercialsThreshold"
                        stepSize={10000}
                        value={
                          approvalRole.canApproveProposalCommercialsThreshold
                        }
                      />
                    </div>

                    <div style={{ marginLeft: "10px", width: "50%" }}></div>
                  </div>
                </>
              )}

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Proposal submission */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_proposal_submission"
                helperText="Authorise the team to submit proposals"
                label="Proposal submission"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveProposalSubmission"
                value={approvalRole.canApproveProposalSubmission}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal submission threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_proposal_submission_threshold"
                    helperText="Estimated value of proposal"
                    label="Proposal submission threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveProposalSubmissionThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveProposalSubmissionThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Define staff decision rights</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Staff recruitment */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_staff_recruitment"
                helperText="Approve the hiring of staff"
                label="Staff recruitment"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffRecruitment"
                value={approvalRole.canApproveStaffRecruitment}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff recruitment threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_staff_recruitment_threshold"
                    helperText="Staff salary"
                    label="Staff recruitment threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffRecruitmentThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveStaffRecruitmentThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Staff expense */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_staff_expense"
                helperText="Approve staff expenses"
                label="Staff expense"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffExpense"
                value={approvalRole.canApproveStaffExpense}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff expense threshold */}
                  <FormDrawerNumericField2
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_staff_expense_threshold"
                    helperText="Value of expense"
                    label="Staff expense threshold"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {organisationDetails.organisation.currency_short}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffExpenseThreshold"
                    stepSize={10000}
                    value={approvalRole.canApproveStaffExpenseThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              <Divider style={{ margin: "5px 0 15px 0" }} />

              {/* Staff timesheet */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_staff_timesheet"
                helperText="Approve staff timesheets"
                label="Staff timesheet"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffTimesheet"
                value={approvalRole.canApproveStaffTimesheet}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff timesheet threshold */}
                  <FormDrawerDurationField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_staff_timesheet_threshold"
                    helperText="Number of hours booked"
                    icon="stopwatch"
                    label="Staff timesheet threshold"
                    max={168}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffTimesheetThreshold"
                    value={approvalRole.canApproveStaffTimesheetThreshold}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>

              {/* Staff leave */}
              <FormDrawerSwitchField
                error={approvalRoleUpdate.error}
                fieldName="can_approve_staff_leave"
                helperText="Approve staff leave"
                label="Staff leave"
                setValueObject={setApprovalRole}
                setValueVariable="canApproveStaffLeave"
                value={approvalRole.canApproveStaffLeave}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Staff leave threshold */}
                  <FormDrawerDurationField
                    error={approvalRoleUpdate.error}
                    fieldName="can_approve_staff_leave_threshold"
                    helperText="Number of hours requested"
                    icon="stopwatch"
                    label="Staff leave threshold"
                    max={168}
                    placeholder="Enter the approval threshold"
                    setValueObject={setApprovalRole}
                    setValueVariable="canApproveStaffLeaveThreshold"
                    value={approvalRole.canApproveStaffLeaveThreshold}
                  />
                </div>
              </div>
            </Card>
          </div>
        }
        cannotDelete={approvalRoleDetails.approval_role.cannot_delete}
        deleteSuccess={approvalRoleDelete.success}
        deleteSuccessMessage="Approval role removed."
        deleting={approvalRoleDelete.loading}
        error={approvalRoleUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approval role"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Defining approval roles"
        icon="edit"
        isOpen={updateApprovalRoleDrawerIsOpen}
        loading={approvalRoleDetails.loading}
        onDelete={deleteApprovalRole}
        onSave={updateApprovalRole}
        refreshList={() => {
          dispatch(listApprovalRolesAction({ ...approvalRoleQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteApprovalRoleAction());

          dispatch(resetUpdateApprovalRoleAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRoleUpdate.success}
        saveSuccessMessage="Approval role updated."
        saving={approvalRoleUpdate.loading}
        setIsOpen={setUpdateApprovalRoleDrawerIsOpen}
        title="Update approval role"
      />

      {/* Existing manager form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Assign approval role</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <Callout
              children={
                <div>
                  You are assigning an approval role to{" "}
                  {managerDetails.manager.name}
                </div>
              }
              intent="primary"
              style={{ margin: "20px 0 20px 0" }}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Approval role */}
                <FormDrawerSelectField2
                  error={managerUpdate.error}
                  fieldName="approval_role"
                  helperText="Select an approval role"
                  icon="hat"
                  items={approvalRoleList.approval_roles}
                  label="Approval role"
                  loading={approvalRoleList.loading}
                  noOptionOnClick
                  placeholder="Select an approval role"
                  query={approvalRoleQuery.searchString}
                  setQueryObject={setApprovalRoleQuery}
                  setQueryVariable="searchString"
                  setValueObject={setManager}
                  setValueIdVariable="approvalRole"
                  setValueNameVariable="approvalRoleName"
                  value={manager.approvalRoleName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Approval role description */}
            <FormDrawerApprovalRoleCard
              approvalRoleId={manager.approvalRole}
              approvalRoleList={approvalRoleList}
            />
          </Card>
        }
        error={managerUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update manager"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing decision rights"
        icon="edit"
        isOpen={updateManagerDrawerIsOpen}
        loading={managerDetails.loading}
        onSave={updateManager}
        refreshList={() => {
          dispatch(listManagersAction({ ...managerQuery }));
        }}
        resetAction={() => {
          dispatch(resetUpdateManagerAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={managerUpdate.success}
        saveSuccessMessage="Manager updated."
        saving={managerUpdate.loading}
        setIsOpen={setUpdateManagerDrawerIsOpen}
        title="Update manager"
      />

      {/* New competency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the competency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Competency name */}
                <FormDrawerTextField2
                  error={competencyCreate.error}
                  fieldName="name"
                  icon="build"
                  label="Competency name"
                  labelInfo="(required)"
                  placeholder="Enter the competency's name"
                  setValueObject={setCompetency}
                  setValueVariable="name"
                  value={competency.name}
                />
              </div>

              <div
                style={{ marginLeft: "10px", paddingTop: "22px", width: "50%" }}
              >
                <GenerateButton
                  disabled={competency.description || !competency.name}
                  objectDescription={competency.name}
                  objectType="competency"
                  onSuccess={(result) => {
                    if (
                      !("description" in result) ||
                      result.description === ""
                    ) {
                      AppToaster.show({
                        icon: "warning-sign",
                        intent: "warning",
                        message: "Unable to generate suitable content.",
                      });
                    } else {
                      AppToaster.show({
                        icon: "tick-circle",
                        intent: "success",
                        message: "Generation complete.",
                      });
                    }

                    setCompetency((prevState) => {
                      return {
                        ...prevState,
                        description: result.description,
                      };
                    });
                  }}
                  text="Describe"
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={competencyCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the competency"
              setValueObject={setCompetency}
              setValueVariable="description"
              value={competency.description}
            />
          </Card>
        }
        error={competencyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add competency"
            module="Organisations"
            view="List"
          />
        }
        helpTitle="Defining competencies"
        icon="edit"
        isOpen={addCompetencyDrawerIsOpen}
        onSave={addCompetency}
        refreshList={() => {
          dispatch(
            listCompetenciesAction({
              organisation: id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateCompetencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={competencyCreate.success}
        saveSuccessMessage="Competency created."
        saving={competencyCreate.loading}
        setIsOpen={setAddCompetencyDrawerIsOpen}
        title="Add competency"
      />

      {/* Existing competency form drawer */}
      <FormDrawerContainer2
        body={
          <Card>
            <H5>Enter the competency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Competency name */}
                <FormDrawerTextField2
                  error={competencyUpdate.error}
                  fieldName="name"
                  icon="build"
                  label="Competency name"
                  labelInfo="(required)"
                  placeholder="Enter the competency's name"
                  setValueObject={setCompetency}
                  setValueVariable="name"
                  value={competency.name}
                />
              </div>

              <div
                style={{ marginLeft: "10px", paddingTop: "22px", width: "50%" }}
              >
                <GenerateButton
                  disabled={competency.description || !competency.name}
                  objectDescription={competency.name}
                  objectType="competency"
                  onSuccess={(result) => {
                    if (
                      !("description" in result) ||
                      result.description === ""
                    ) {
                      AppToaster.show({
                        icon: "warning-sign",
                        intent: "warning",
                        message: "Unable to generate suitable content.",
                      });
                    } else {
                      AppToaster.show({
                        icon: "tick-circle",
                        intent: "success",
                        message: "Generation complete.",
                      });
                    }

                    setCompetency((prevState) => {
                      return {
                        ...prevState,
                        description: result.description,
                      };
                    });
                  }}
                  text="Describe"
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={competencyUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the competency"
              setValueObject={setCompetency}
              setValueVariable="description"
              value={competency.description}
            />
          </Card>
        }
        cannotDelete={competencyDetails.competency.cannot_delete}
        deleteSuccess={competencyDelete.success}
        deleteSuccessMessage="Competency deleted."
        deleting={competencyDelete.loading}
        helpContent={
          <FormDrawerHelpContainer
            form="Update competency"
            module="Organisations"
            view="List"
          />
        }
        helpTitle="Defining competencies"
        error={competencyUpdate.error}
        icon="edit"
        isOpen={updateCompetencyDrawerIsOpen}
        loading={competencyDetails.loading}
        onDelete={deleteCompetency}
        onSave={updateCompetency}
        refreshList={() => {
          dispatch(listCompetenciesAction({ ...competencyQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteCompetencyAction());

          dispatch(resetUpdateCompetencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={competencyUpdate.success}
        saveSuccessMessage="Competency updated."
        saving={competencyUpdate.loading}
        setIsOpen={setUpdateCompetencyDrawerIsOpen}
        title="Update competency"
      />

      {/* New location form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the location</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Name */}
            <FormDrawerTextField2
              error={locationCreate.error}
              fieldName="name"
              icon="label"
              label="Name"
              labelInfo="(required)"
              placeholder="Enter a name for the location"
              setValueObject={setLocation}
              setValueVariable="name"
              value={location.name}
            />

            {/* Address */}
            <FormDrawerTextAreaField3
              error={locationCreate.error}
              fieldName="address"
              height="100px"
              label="Address"
              noRewrite
              placeholder="Enter the address"
              setValueObject={setLocation}
              setValueVariable="address"
              value={location.address}
            />
          </Card>
        }
        error={locationCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add location"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing locations"
        icon="edit"
        isOpen={addLocationDrawerIsOpen}
        onSave={addLocation}
        refreshList={() => {
          dispatch(listLocationsAction({ organisation: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateLocationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={locationCreate.success}
        saveSuccessMessage="Location added."
        saving={locationCreate.loading}
        setIsOpen={setAddLocationDrawerIsOpen}
        size="small"
        title="Add location"
      />

      {/* Existing location form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the location</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Name */}
            <FormDrawerTextField2
              error={locationUpdate.error}
              fieldName="name"
              icon="label"
              label="Name"
              labelInfo="(required)"
              placeholder="Enter a name for the location"
              setValueObject={setLocation}
              setValueVariable="name"
              value={location.name}
            />

            {/* Address */}
            <FormDrawerTextAreaField3
              error={locationUpdate.error}
              fieldName="address"
              height="100px"
              label="Address"
              noRewrite
              placeholder="Enter the address"
              setValueObject={setLocation}
              setValueVariable="address"
              value={location.address}
            />
          </Card>
        }
        cannotDelete={locationDetails.location.cannot_delete}
        deleteSuccess={locationDelete.success}
        deleteSuccessMessage="Location removed."
        deleting={locationDelete.loading}
        error={locationUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update location"
            module="Organisations"
            view="Detail"
          />
        }
        helpTitle="Managing locations"
        icon="edit"
        isOpen={updateLocationDrawerIsOpen}
        loading={locationDetails.loading}
        onDelete={deleteLocation}
        onSave={updateLocation}
        refreshList={() => {
          dispatch(listLocationsAction({ ...locationQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteLocationAction());

          dispatch(resetUpdateLocationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={locationUpdate.success}
        saveSuccessMessage="Location updated."
        saving={locationUpdate.loading}
        setIsOpen={setUpdateLocationDrawerIsOpen}
        size="small"
        title="Update location"
      />

      {/* New knowledge base document form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the file details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {!checkSubscription.subscription.has_ai_credits && (
              <Callout
                children={
                  <div>
                    You don't have access to AI services. You may need to
                    purchase additional seats or AI credits.
                  </div>
                }
                intent="danger"
                style={{ margin: "20px 0 20px 0" }}
              />
            )}

            {/* File name */}
            <FormDrawerTextField2
              disabled={!checkSubscription.subscription.has_ai_credits}
              error={knowledgeBaseDocumentCreate.error}
              fieldName="name"
              icon="document-open"
              label="File name"
              labelInfo="(required)"
              placeholder="Give the file a name"
              setValueObject={setKnowledgeBaseDocument}
              setValueVariable="name"
              value={knowledgeBaseDocument.name}
            />

            {/* File */}
            <FormDrawerFileField2
              disabled={!checkSubscription.subscription.has_ai_credits}
              error={knowledgeBaseDocumentCreate.error}
              fileName="file"
              label="File"
              labelInfo="(required)"
              placeholder="Select the file"
              setValueObject={setKnowledgeBaseDocument}
              setValueFileVariable="file"
              setValueNameVariable="fileName"
              valueFile={knowledgeBaseDocument.file}
              valueName={knowledgeBaseDocument.fileName}
            />

            {/* Category */}
            <FormDrawerSelectSimpleField3
              disabled={!checkSubscription.subscription.has_ai_credits}
              error={knowledgeBaseDocumentCreate.error}
              fieldName="category"
              label="Category"
              options={[
                {
                  id: 1,
                  icon: "heat-grid",
                  value: "Service Definition",
                },
                { id: 2, icon: "manual", value: "Proposal" },
                { id: 3, icon: "gantt-chart", value: "Engagement Charter" },
                { id: 4, icon: "book", value: "Case Study" },
                {
                  id: 5,
                  icon: "manually-entered-data",
                  value: "Publication",
                },
                { id: 6, icon: "more", value: "Other" },
              ]}
              setValueObject={setKnowledgeBaseDocument}
              setValueVariable="category"
              value={knowledgeBaseDocument.category}
            />
          </Card>
        }
        error={knowledgeBaseDocumentCreate.error}
        icon="edit"
        isOpen={addKnowledgeBaseDocumentDrawerIsOpen}
        onSave={addKnowledgeBaseDocument}
        refreshList={() => {
          dispatch(listKnowledgeBaseDocumentsAction({ organisation: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateKnowledgeBaseDocumentAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={knowledgeBaseDocumentCreate.success}
        saveSuccessMessage="Document added to knowledge base. The system may take a few minutes to index the content."
        saving={knowledgeBaseDocumentCreate.loading}
        setIsOpen={setAddKnowledgeBaseDocumentDrawerIsOpen}
        size="small"
        title="Upload file"
      />

      {/* Existing knowledge base document form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the file details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {!checkSubscription.subscription.has_ai_credits && (
              <Callout
                children={
                  <div>
                    You don't have access to AI services. You may need to
                    purchase additional seats or AI credits.
                  </div>
                }
                intent="danger"
                style={{ margin: "20px 0 20px 0" }}
              />
            )}

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  error={knowledgeBaseDocumentUpdate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setKnowledgeBaseDocument}
                  setValueVariable="name"
                  value={knowledgeBaseDocument.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  disabled={!checkSubscription.subscription.has_ai_credits}
                  error={knowledgeBaseDocumentUpdate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setKnowledgeBaseDocument}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={knowledgeBaseDocument.file}
                  valueName={knowledgeBaseDocument.fileName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={knowledgeBaseDocumentUpdate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "heat-grid",
                      value: "Service Definition",
                    },
                    { id: 2, icon: "manual", value: "Proposal" },
                    { id: 3, icon: "gantt-chart", value: "Engagement Charter" },
                    { id: 4, icon: "book", value: "Case Study" },
                    {
                      id: 5,
                      icon: "manually-entered-data",
                      value: "Publication",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setKnowledgeBaseDocument}
                  setValueVariable="category"
                  value={knowledgeBaseDocument.category}
                />
              </div>

              <div
                style={{ marginLeft: "10px", paddingTop: "22px", width: "50%" }}
              >
                <Button
                  disabled={!knowledgeBaseDocument.file}
                  icon="download"
                  intent="primary"
                  onClick={() => {
                    saveAs(
                      knowledgeBaseDocument.file,
                      knowledgeBaseDocument.fileName
                    );
                  }}
                  text="Download"
                />
              </div>
            </div>

            {/* Summary */}
            <FormDrawerTextAreaField3
              error={knowledgeBaseDocumentUpdate.error}
              fieldName="summary"
              //height="100px"
              label="Summary"
              placeholder="Summarise the document"
              setValueObject={setKnowledgeBaseDocument}
              setValueVariable="summary"
              value={knowledgeBaseDocument.summary}
            />
          </Card>
        }
        deleteSuccess={knowledgeBaseDocumentDelete.success}
        deleteSuccessMessage="File deleted."
        deleting={knowledgeBaseDocumentDelete.loading}
        error={knowledgeBaseDocumentUpdate.error}
        icon="edit"
        isOpen={updateKnowledgeBaseDocumentDrawerIsOpen}
        loading={knowledgeBaseDocumentDetails.loading}
        onDelete={deleteKnowledgeBaseDocument}
        onSave={updateKnowledgeBaseDocument}
        refreshList={() => {
          dispatch(
            listKnowledgeBaseDocumentsAction({ ...knowledgeBaseDocumentQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteKnowledgeBaseDocumentAction());

          dispatch(resetUpdateKnowledgeBaseDocumentAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={knowledgeBaseDocumentUpdate.success}
        saveSuccessMessage="Document updated in knowledge base. The system may take a few minutes to index the content."
        saving={knowledgeBaseDocumentUpdate.loading}
        setIsOpen={setUpdateKnowledgeBaseDocumentDrawerIsOpen}
        title="Update file"
      />

      {/* Action dialogs */}
      {/* Upload clients action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={clientUpload.begin}
            icon="upload"
            intent="primary"
            loading={clientUpload.begin}
            onClick={() => {
              setClientUploadFile({
                ...clientUploadFile,
                error: clientUploadFileDefault.error,
              });

              dispatch(resetUploadClientsAction());

              uploadClients();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the clients file"
            setValueObject={setClientUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={clientUploadFile.file}
            valueName={clientUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadClientsDialogIsOpen}
        message={
          clientUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : clientUpload.records_failed.length > 0
            ? "We couldn't upload all the clients. Please wait until we have processed all the records and then open the Client Exceptions file downloaded."
            : clientUpload.end
            ? "Clients uploaded."
            : "You must use the template downloaded to upload clients."
        }
        messageIntent={
          clientUploadFile.error
            ? "danger"
            : clientUpload.records_failed.length > 0
            ? "warning"
            : clientUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listClientsAction({ organisation: id }));

          dispatch(resetUploadClientsAction());
        }}
        progress={clientUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadClientsDialogIsOpen}
        title="Upload clients"
      />

      {/* Upload contacts action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={contactUpload.begin}
            icon="upload"
            intent="primary"
            loading={contactUpload.begin}
            onClick={() => {
              setContactUploadFile({
                ...contactUploadFile,
                error: contactUploadFileDefault.error,
              });

              dispatch(resetUploadContactsAction());

              uploadContacts();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the contacts file"
            setValueObject={setContactUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={contactUploadFile.file}
            valueName={contactUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadContactsDialogIsOpen}
        message={
          contactUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : contactUpload.records_failed.length > 0
            ? "We couldn't upload all the contacts. Please wait until we have processed all the records and then open the Contact Exceptions file downloaded."
            : contactUpload.end
            ? "Contacts uploaded."
            : "You must use the template downloaded to upload contacts."
        }
        messageIntent={
          contactUploadFile.error
            ? "danger"
            : contactUpload.records_failed.length > 0
            ? "warning"
            : contactUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listContactsAction({ organisation: id }));

          dispatch(resetUploadContactsAction());
        }}
        progress={contactUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadContactsDialogIsOpen}
        title="Upload contacts"
      />

      {/* Upload competencies action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={competencyUpload.begin}
            icon="upload"
            intent="primary"
            loading={competencyUpload.begin}
            onClick={() => {
              setCompetencyUploadFile({
                ...competencyUploadFile,
                error: competencyUploadFileDefault.error,
              });

              dispatch(resetUploadCompetenciesAction());

              uploadCompetencies();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the competencies file"
            setValueObject={setCompetencyUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={competencyUploadFile.file}
            valueName={competencyUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadCompetenciesDialogIsOpen}
        message={
          competencyUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : competencyUpload.records_failed.length > 0
            ? "We couldn't upload all the competencies. Please wait until we have processed all the records and then open the Competency Exceptions file downloaded."
            : competencyUpload.end
            ? "Expense categories uploaded."
            : "You must use the template downloaded to upload competencies."
        }
        messageIntent={
          competencyUploadFile.error
            ? "danger"
            : competencyUpload.records_failed.length > 0
            ? "warning"
            : competencyUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listCompetenciesAction({ organisation: id }));

          dispatch(resetUploadCompetenciesAction());
        }}
        progress={competencyUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadCompetenciesDialogIsOpen}
        title="Upload competencies"
      />

      {/* Upload expense categories action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={expenseCategoryUpload.begin}
            icon="upload"
            intent="primary"
            loading={expenseCategoryUpload.begin}
            onClick={() => {
              setExpenseCategoryUploadFile({
                ...expenseCategoryUploadFile,
                error: expenseCategoryUploadFileDefault.error,
              });

              dispatch(resetUploadExpenseCategoriesAction());

              uploadExpenseCategories();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the expense categories file"
            setValueObject={setExpenseCategoryUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={expenseCategoryUploadFile.file}
            valueName={expenseCategoryUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadExpenseCategoriesDialogIsOpen}
        message={
          expenseCategoryUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : expenseCategoryUpload.records_failed.length > 0
            ? "We couldn't upload all the expense categories. Please wait until we have processed all the records and then open the Expense Category Exceptions file downloaded."
            : expenseCategoryUpload.end
            ? "Expense categories uploaded."
            : "You must use the template downloaded to upload expense categories."
        }
        messageIntent={
          expenseCategoryUploadFile.error
            ? "danger"
            : expenseCategoryUpload.records_failed.length > 0
            ? "warning"
            : expenseCategoryUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listExpenseCategoriesAction({ organisation: id }));

          dispatch(resetUploadExpenseCategoriesAction());
        }}
        progress={expenseCategoryUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadExpenseCategoriesDialogIsOpen}
        title="Upload expense categories"
      />

      {/* Upload leave categories action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={leaveCategoryUpload.begin}
            icon="upload"
            intent="primary"
            loading={leaveCategoryUpload.begin}
            onClick={() => {
              setLeaveCategoryUploadFile({
                ...leaveCategoryUploadFile,
                error: leaveCategoryUploadFileDefault.error,
              });

              dispatch(resetUploadLeaveCategoriesAction());

              uploadLeaveCategories();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the leave categories file"
            setValueObject={setLeaveCategoryUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={leaveCategoryUploadFile.file}
            valueName={leaveCategoryUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadLeaveCategoriesDialogIsOpen}
        message={
          leaveCategoryUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : leaveCategoryUpload.records_failed.length > 0
            ? "We couldn't upload all the leave categories. Please wait until we have processed all the records and then open the Leave Category Exceptions file downloaded."
            : leaveCategoryUpload.end
            ? "Leave categories uploaded."
            : "You must use the template downloaded to upload leave categories."
        }
        messageIntent={
          leaveCategoryUploadFile.error
            ? "danger"
            : leaveCategoryUpload.records_failed.length > 0
            ? "warning"
            : leaveCategoryUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listLeaveCategoriesAction({ organisation: id }));

          dispatch(resetUploadLeaveCategoriesAction());
        }}
        progress={leaveCategoryUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadLeaveCategoriesDialogIsOpen}
        title="Upload leave categories"
      />

      {/* Detail drawers */}
      {/* Approval request */}
      <ApprovalDetailDrawer
        approvalRequestDetails={approvalRequestDetails}
        organisationDetails={organisationDetails}
        setIsOpen={setViewProposalQualificationApprovalDrawerIsOpen}
        isOpen={viewProposalQualificationApprovalDrawerIsOpen}
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Organisations"
              onClick={() => {
                dispatch(resetListOrganisationsAction());

                navigate("/organisations");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        loading={organisationDetails.loading}
        title={`${organisationDetails.organisation.name}`}
      />

      {/* Error alerts */}
      {/* Approval requests loading error */}
      {approvalRequestList.error && (
        <ErrorAlert message="We couldn't load the approval request list. Try refreshing the page." />
      )}
      {/* Approval roles loading error */}
      {approvalRoleList.error && (
        <ErrorAlert message="We couldn't load the approval role list. Try refreshing the page." />
      )}
      {/* Booking codes loading error */}
      {bookingCodeList.error && (
        <ErrorAlert message="We couldn't load the booking code list. Try refreshing the page." />
      )}
      {/* Competencies loading error */}
      {competencyList.error && (
        <ErrorAlert message="We couldn't load the competency list. Try refreshing the page." />
      )}
      {/* Currencies loading error */}
      {currencyList.error && (
        <ErrorAlert message="We couldn't load the currency list. Try refreshing the page." />
      )}
      {/* Currency exchange rates loading error */}
      {currencyExchangeRateList.error && (
        <ErrorAlert message="We couldn't load the currency exchange rate list. Try refreshing the page." />
      )}
      {/* Expense categories loading error */}
      {expenseCategoryList.error && (
        <ErrorAlert message="We couldn't load the expense category list. Try refreshing the page." />
      )}
      {/* Leave categories loading error */}
      {leaveCategoryList.error && (
        <ErrorAlert message="We couldn't load the leave category list. Try refreshing the page." />
      )}
      {/* Locations loading error */}
      {locationList.error && (
        <ErrorAlert message="We couldn't load the location list. Try refreshing the page." />
      )}
      {/* Knowledge base documents loading error */}
      {knowledgeBaseDocumentList.error && (
        <ErrorAlert message="We couldn't load the knowledge base document list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Memberships loading error */}
      {membershipList.error && (
        <ErrorAlert message="We couldn't load the organisation's members. Try refreshing the page." />
      )}
      {/* Organisation delete error */}
      {organisationDelete.error && (
        <ErrorAlert message="We couldn't delete the organisation. Try refreshing the page." />
      )}
      {/* Organisation loading error */}
      {organisationDetails.error && (
        <ErrorAlert message="We couldn't load the organisation. Try refreshing the page." />
      )}
      {/* Ranks loading error */}
      {rankList.error && (
        <ErrorAlert message="We couldn't load the rank list. Try refreshing the page." />
      )}
      {/* Rank cost rates loading error */}
      {rankCostRateList.error && (
        <ErrorAlert message="We couldn't load the rank cost rate list. Try refreshing the page." />
      )}
      {/* Reimbursements loading error */}
      {reimbursementList.error && (
        <ErrorAlert message="We couldn't load the reimbursement list. Try refreshing the page." />
      )}
      {/* Seats loading error */}
      {seatList.error && (
        <ErrorAlert message="We couldn't load the seat list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Organisation"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="organisation-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="organisation-admin-tab"
              title="Administration"
              panel={
                <div>
                  <DemoCoExpired />

                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listOrganisationDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <TabSectionField
                        bottom
                        label={
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt="logo"
                              src={
                                organisationDetails.organisation.logo
                                  ? organisationDetails.organisation.logo
                                  : "/images/no_logo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "10%",
                                height: "150px",
                                margin: "10px 0 10px 0",
                                objectFit: "contain",
                                width: "150px",
                              }}
                            />

                            <Text ellipsize>
                              {organisationDetails.organisation.name}
                            </Text>
                          </div>
                        }
                        value={
                          <TabSectionFieldMultifieldCard
                            children={
                              <>
                                {/* Id */}
                                <TabSectionField
                                  label="Id"
                                  loading={organisationDetails.loading}
                                  value={organisationDetails.organisation.id}
                                />

                                {/* Tagline */}
                                <TabSectionField
                                  label="Tagline"
                                  loading={organisationDetails.loading}
                                  value={
                                    organisationDetails.organisation.tagline
                                  }
                                />

                                {/* About */}
                                <TabSectionField
                                  label="About"
                                  loading={organisationDetails.loading}
                                  value={organisationDetails.organisation.about}
                                />

                                {/* Address */}
                                <TabSectionField
                                  label="Address"
                                  loading={organisationDetails.loading}
                                  value={
                                    organisationDetails.organisation.address
                                  }
                                />

                                {/* Phone */}
                                <TabSectionField
                                  label="Phone"
                                  loading={organisationDetails.loading}
                                  value={
                                    organisationDetails.organisation
                                      .display_phone
                                  }
                                />

                                {/* Email */}
                                <TabSectionField
                                  label="Email"
                                  loading={organisationDetails.loading}
                                  value={
                                    <TabSectionEmailLink
                                      email={
                                        organisationDetails.organisation.email
                                      }
                                    />
                                  }
                                />

                                {/* Website */}
                                <TabSectionField
                                  label="Website"
                                  loading={organisationDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        url={
                                          organisationDetails.organisation
                                            .website
                                        }
                                      />
                                    </div>
                                  }
                                />

                                {/* Owner */}
                                <TabSectionField
                                  bottom
                                  helperText={
                                    organisationDetails.organisation
                                      .user_is_admin
                                      ? "You are an administrator of this organisation"
                                      : organisationDetails.organisation
                                          .user_is_owner
                                      ? "You own this organisation"
                                      : undefined
                                  }
                                  label="Owner"
                                  loading={organisationDetails.loading}
                                  subLabel="Contact support to change ownership"
                                  value={
                                    <NameValue
                                      email={
                                        organisationDetails.organisation
                                          .owner_email
                                      }
                                      image={
                                        organisationDetails.organisation
                                          .owner_profile_photo
                                      }
                                      showImage
                                      text={
                                        organisationDetails.organisation
                                          .owner_email
                                      }
                                    />
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    }
                    help="Review and update basic information about the organisation"
                    icon="briefcase"
                    title="Summary"
                  />

                  {/* Memberships */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddMembershipDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search member"
                            searching={membershipList.loading}
                            setValueObject={setMembershipQuery}
                            setValueVariable="member"
                            value={membershipQuery.member}
                          />
                        </div>

                        <ItemCardList
                          content={membershipList.memberships.map(
                            (membership) => {
                              return (
                                <MemberCard
                                  interactive={
                                    membership.own_membership ||
                                    membership.owner
                                      ? false
                                      : true
                                  }
                                  key={membership.id}
                                  membership={membership}
                                  onClick={() => {
                                    if (
                                      !membership.own_membership &&
                                      !membership.owner
                                    ) {
                                      dispatch(
                                        listMembershipDetailsAction(
                                          membership.id
                                        )
                                      );

                                      setUpdateMembershipDrawerIsOpen(true);
                                    } else {
                                      AppToaster.show({
                                        icon: "info-sign",
                                        intent: "warning",
                                        message:
                                          "You cannot change this membership.",
                                      });
                                    }
                                  }}
                                />
                              );
                            }
                          )}
                          count={membershipList.count}
                          loading={membershipList.loading}
                          noMargin
                          noResultsMessage="Add memberships."
                          noResultsTitle="No members found"
                          page={membershipQuery.page}
                          setPageObject={setMembershipQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Add or remove users who can work at this organisation"
                    icon="inherited-group"
                    title="Memberships"
                  />

                  {/* Integrations */}
                  {false && (
                    <TabSection2
                      content={
                        <div>
                          <TabSectionField
                            bottom
                            label="CRM System"
                            value={
                              <TabSectionFieldMultifieldCard
                                children={
                                  <div>
                                    {/* Application */}
                                    <TabSectionField
                                      helperText={
                                        organisationDetails.organisation.crm !==
                                        "None"
                                          ? "advaise.app will sync clients and contacts with this application"
                                          : undefined
                                      }
                                      label="Application"
                                      value={
                                        organisationDetails.organisation.crm !==
                                        "None" ? (
                                          <div style={{ display: "flex" }}>
                                            <div
                                              style={{
                                                textAlign: "center",
                                              }}
                                            >
                                              <img
                                                alt="logo"
                                                src={
                                                  organisationDetails
                                                    .organisation.crm ===
                                                  "HubSpot"
                                                    ? "/images/hubspot.png"
                                                    : "/images/no_logo.png"
                                                }
                                                style={{
                                                  backgroundColor: "#F9F9F9",
                                                  border: "3px solid #D2ECF9",
                                                  borderRadius: "5%",
                                                  height: "100px",
                                                  objectFit: "contain",
                                                  padding: "5px",
                                                  width: "100px",
                                                }}
                                              />

                                              <Text
                                                ellipsize
                                                style={{ margin: "5px 0 0 0" }}
                                              >
                                                {
                                                  organisationDetails
                                                    .organisation.crm
                                                }
                                              </Text>
                                            </div>
                                          </div>
                                        ) : undefined
                                      }
                                    />

                                    {/* Status */}
                                    <TabSectionField
                                      helperText={
                                        organisationDetails.organisation.crm !==
                                        "None"
                                          ? organisationDetails.organisation
                                              .crm_authorised
                                            ? "Ready to connect to the CRM"
                                            : "You need to authorise the connection"
                                          : undefined
                                      }
                                      label="Status"
                                      value={
                                        organisationDetails.organisation.crm !==
                                        "None" ? (
                                          organisationDetails.organisation
                                            .crm_authorised ? (
                                            <Tag
                                              intent="success"
                                              large
                                              minimal
                                              style={{
                                                textAlign: "center",
                                                width: "125px",
                                              }}
                                            >
                                              Authorised
                                            </Tag>
                                          ) : (
                                            <Tag
                                              intent="danger"
                                              large
                                              minimal
                                              style={{
                                                textAlign: "center",
                                                width: "125px",
                                              }}
                                            >
                                              Not authorised
                                            </Tag>
                                          )
                                        ) : undefined
                                      }
                                    />

                                    <div
                                      onClick={(e) => e.stopPropagation()}
                                      style={{
                                        display: "flex",
                                        margin: "20px 0 0 0",
                                      }}
                                    >
                                      {/* Authorise */}
                                      <Button
                                        disabled={
                                          organisationDetails.organisation
                                            .crm === "None" ||
                                          organisationDetails.organisation
                                            .crm_authorised === true
                                        }
                                        icon="unlock"
                                        intent="success"
                                        loading={
                                          organisationDetails.organisation
                                            .crm === "HubSpot"
                                            ? hubspotAuthorise.loading
                                            : undefined
                                        }
                                        onClick={() => {
                                          if (
                                            organisationDetails.organisation
                                              .crm === "HubSpot"
                                          ) {
                                            dispatch(
                                              hubspotAuthoriseAction(id)
                                            );
                                          }
                                        }}
                                        style={{ margin: "0 10px 0 0" }}
                                        text="Authorise"
                                      />

                                      {/* Deauthorise */}
                                      <Button
                                        disabled={
                                          organisationDetails.organisation
                                            .crm_authorised === false
                                        }
                                        icon="key"
                                        intent="danger"
                                        loading={
                                          organisationDetails.organisation
                                            .crm === "HubSpot"
                                            ? hubspotDeauthorise.loading
                                            : undefined
                                        }
                                        onClick={() => {
                                          if (
                                            organisationDetails.organisation
                                              .crm === "HubSpot"
                                          ) {
                                            dispatch(
                                              hubspotDeauthoriseAction(id)
                                            );
                                          }
                                        }}
                                        style={{ margin: "0 10px 0 0" }}
                                        text="Deauthorise"
                                      />

                                      {/* Sync */}
                                      <Button
                                        disabled={
                                          organisationDetails.organisation
                                            .crm === "None" ||
                                          organisationDetails.organisation
                                            .crm_authorised === false
                                        }
                                        icon="id-number"
                                        intent="primary"
                                        loading={
                                          organisationDetails.organisation
                                            .crm === "HubSpot"
                                            ? hubspotContactSync.loading
                                            : undefined
                                        }
                                        onClick={() => {
                                          if (
                                            organisationDetails.organisation
                                              .crm === "HubSpot"
                                          ) {
                                            dispatch(
                                              hubspotSyncContactsAction(id)
                                            );
                                          }
                                        }}
                                        //style={{ margin: "0 10px 0 0" }}
                                        text="Sync contacts"
                                      />
                                    </div>
                                  </div>
                                }
                                interactive
                                onClick={() => {
                                  dispatch(listOrganisationDetailsAction(id));

                                  setUpdateCrmIntegrationDrawerIsOpen(true);
                                }}
                              />
                            }
                          />
                        </div>
                      }
                      help="Manage integrations with other apps"
                      icon="data-lineage"
                      title="Integrations"
                    />
                  )}

                  {/* Import/Export */}
                  <TabSection2
                    content={
                      <div>
                        {/* Clients */}
                        <TabSectionField
                          helperText={`${clientList.count} records`}
                          label={
                            <div
                              style={{ alignItems: "center", display: "flex" }}
                            >
                              <IconSquare
                                darkBackground={Colors.DARK_GRAY4}
                                darkColor={Colors.BLUE5}
                                icon="office"
                                lightBackground={Colors.LIGHT_GRAY4}
                                lightColor={Colors.BLUE2}
                              />

                              <div style={{ marginLeft: "10px" }}>Clients</div>
                            </div>
                          }
                          value={
                            <>
                              <Button
                                icon="download"
                                intent="primary"
                                loading={clientDownload.loading}
                                onClick={() => {
                                  dispatch(
                                    downloadClientsAction(id, clientList.count)
                                  );
                                }}
                                style={{ margin: "0 10px 0 0" }}
                                text="Download"
                              />

                              <Button
                                icon="upload"
                                intent="primary"
                                onClick={() => {
                                  setUploadClientsDialogIsOpen(true);
                                }}
                                text="Upload"
                              />
                            </>
                          }
                        />

                        {/* Contacts */}
                        <TabSectionField
                          bottom
                          helperText={`${contactList.count} records`}
                          label={
                            <div
                              style={{ alignItems: "center", display: "flex" }}
                            >
                              <IconSquare
                                darkBackground={Colors.DARK_GRAY4}
                                darkColor={Colors.BLUE5}
                                icon="id-number"
                                lightBackground={Colors.LIGHT_GRAY4}
                                lightColor={Colors.BLUE2}
                              />

                              <div style={{ marginLeft: "10px" }}>Contacts</div>
                            </div>
                          }
                          value={
                            <>
                              <Button
                                icon="download"
                                intent="primary"
                                loading={contactDownload.loading}
                                onClick={() => {
                                  dispatch(
                                    downloadContactsAction(
                                      id,
                                      contactList.count
                                    )
                                  );
                                }}
                                style={{ margin: "0 10px 0 0" }}
                                text="Download"
                              />

                              <Button
                                icon="upload"
                                intent="primary"
                                onClick={() => {
                                  setUploadContactsDialogIsOpen(true);
                                }}
                                text="Upload"
                              />
                            </>
                          }
                        />
                      </div>
                    }
                    help="Bulk upload or download data"
                    icon="import"
                    title="Import/Export"
                  />

                  {/* Delete organisation */}
                  {!organisationDetails.organisation.demo &&
                    organisationDetails.organisation.user_is_owner && (
                      <TabSection2
                        content={
                          <div>
                            <ControlGroup
                              style={{
                                margin: "20px 0 0 0",
                                textAlign: "start",
                              }}
                            >
                              <InputGroup
                                id="confirm-organisation-name"
                                onChange={(e) => {
                                  setOrganisationDeleteKey(e.target.value);
                                }}
                                placeholder="Enter the organisation's name"
                                style={{ margin: "0 5px 0 0", width: "400px" }}
                                value={organisationDeleteKey}
                              />

                              <Button
                                icon="trash"
                                intent="danger"
                                loading={organisationDelete.loading}
                                onClick={deleteOrganisation}
                                text="Delete this organisation"
                              />
                            </ControlGroup>

                            <div
                              className={[
                                Classes.TEXT_MUTED,
                                Classes.TEXT_SMALL,
                              ].join(" ")}
                              style={{ margin: "5px 0 0 10px" }}
                            >
                              Enter "{organisationDetails.organisation.name}" to
                              delete.
                            </div>
                          </div>
                        }
                        help="Delete the organisation"
                        icon="delete"
                        message="If you delete this organisation, you will lose all information associated with it. If you delete the organization by mistake, contact us immediately, as we may be able to restore your data from a backup. After the backup retention period, your organization's data will be permanently removed from advaise.app."
                        messageIntent="danger"
                        title="Delete organisation"
                      />
                    )}
                </div>
              }
            />

            {/* Subscription */}
            <Tab
              id="organisation-subscription-tab"
              title="Subscription"
              panel={
                <div>
                  <DemoCoExpired />

                  {/* Manage */}
                  <TabSection2
                    content={
                      organisationDetails.organisation.stripe_customer_id ? (
                        <>
                          {/* Subscription */}
                          <TabSectionField
                            helperText={
                              <ManageAccountButton
                                disabled={organisationDetails.organisation.demo}
                                intent="primary"
                                style={{ width: "150px" }}
                                text="Manage"
                                organisationId={id}
                              />
                            }
                            label="Subscription"
                            loading={organisationDetails.organisation.loading}
                            subLabel="The organization's subscription tier"
                            value={
                              <Tag
                                large
                                minimal
                                style={{
                                  minWidth: "100px",
                                  textAlign: "center",
                                }}
                              >
                                {organisationDetails.organisation.demo
                                  ? "Demo"
                                  : organisationDetails.organisation
                                      .subscription}
                              </Tag>
                            }
                          />

                          {/* Status */}
                          <TabSectionField
                            helperText={
                              organisationDetails.organisation
                                .invoice_payment_message
                                ? organisationDetails.organisation
                                    .invoice_payment_message
                                : "No action required"
                            }
                            label="Status"
                            loading={organisationDetails.loading}
                            subLabel="The status of the subscription"
                            value={
                              <Tag
                                intent={
                                  organisationDetails.organisation
                                    .invoice_payment_message
                                    ? "danger"
                                    : "success"
                                }
                                large
                                minimal
                                style={{
                                  minWidth: "100px",
                                  textAlign: "center",
                                }}
                              >
                                {organisationDetails.organisation
                                  .invoice_payment_message
                                  ? "Needs attention"
                                  : "Healthy"}
                              </Tag>
                            }
                          />

                          {/* Assigned seats */}
                          <TabSectionField
                            helperText={
                              <ManageAccountButton
                                disabled={organisationDetails.organisation.demo}
                                intent="success"
                                style={{ width: "150px" }}
                                text="Add seats"
                                organisationId={id}
                              />
                            }
                            label="Assigned seats"
                            loading={organisationDetails.organisation.loading}
                            subLabel=""
                            value={`${organisationDetails.organisation.assigned_seats.toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              }
                            )} out of ${organisationDetails.organisation.available_seats.toLocaleString(
                              "en-US",
                              {
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              }
                            )} available seats`}
                          />

                          {/* AI credit balance */}
                          <TabSectionField
                            bottom
                            helperText={
                              <BuyButton
                                action="add-credits"
                                disabled={
                                  organisationDetails.organisation.demo ||
                                  !organisationDetails.organisation
                                    .stripe_customer_id
                                }
                                intent="success"
                                style={{ width: "150px" }}
                                text="Top up"
                                organisationId={id}
                              />
                            }
                            label="AI credit balance"
                            loading={organisationDetails.organisation.loading}
                            subLabel="Purchased separately to user seats"
                            value={
                              <NumericValue
                                noDecimals
                                number={
                                  organisationDetails.organisation.ai_credits
                                }
                              />
                            }
                          />
                        </>
                      ) : (
                        <SubscribeCTAOrganisationCard organisationId={id} />
                      )
                    }
                    help="Manage your organisation's subscription"
                    icon="credit-card"
                    title="Manage"
                  />

                  {/* Seats */}
                  <TabSection2
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={seatList.seats.map((seat) => {
                            return (
                              <tr
                                key={seat.id}
                                onClick={() => {
                                  if (!organisationDetails.organisation.demo) {
                                    dispatch(listSeatDetailsAction(seat.id));

                                    setUpdateSeatDrawerIsOpen(true);
                                  }
                                }}
                              >
                                {/* Status */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Tag
                                    icon={
                                      seat.status === "Active"
                                        ? "tick"
                                        : seat.status === "Cancelled"
                                        ? "high-priority"
                                        : "cross"
                                    }
                                    intent={
                                      seat.status === "Active"
                                        ? "success"
                                        : seat.status === "Cancelled"
                                        ? "warning"
                                        : "danger"
                                    }
                                    large
                                    minimal
                                    style={{
                                      minWidth: "125px",
                                      textAlign: "center",
                                    }}
                                  >
                                    {seat.status}
                                  </Tag>
                                </td>

                                {/* Expires/Renews */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  {seat.expires_at
                                    ? new Date(seat.expires_at).toLocaleString(
                                        "en-US",
                                        {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        }
                                      )
                                    : undefined}
                                </td>

                                {/* AI credits */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    noDecimals
                                    number={seat.ai_credits}
                                  />
                                </td>

                                {/* Assigned to */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    email={seat.member_email}
                                    image={seat.member_profile_photo}
                                    showImage
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !seatQuery.expiresAtEnd &&
                            !seatQuery.expiresAtStart &&
                            !seatQuery.member &&
                            !seatQuery.status
                          }
                          count={seatList.count}
                          head={
                            <tr>
                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={seatQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Active",
                                    icon: "tick",
                                    value: "Active",
                                  },
                                  {
                                    display: "Cancelled",
                                    icon: "high-priority",
                                    value: "Cancelled",
                                  },
                                  {
                                    display: "Expired",
                                    icon: "cross",
                                    value: "Expired",
                                  },
                                ]}
                                setFilterObject={setSeatQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Expires/Renews */}
                              <TableColumnHeadDate
                                endDateVariable="expiresAtEnd"
                                noShortcuts
                                queryObject={seatQuery}
                                setQueryObject={setSeatQuery}
                                startDateVariable="expiresAtStart"
                                title="Expires/Renews"
                                width="125px"
                              />

                              {/* AI credits */}
                              <TableColumnHeadSelectSimple
                                filterObject={seatQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "ai_credits",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-ai_credits",
                                  },
                                ]}
                                setFilterObject={setSeatQuery}
                                title="AI credits"
                                width="125px"
                              />

                              {/* Assigned to */}
                              <TableColumnHeadSelect2
                                filterObject={seatQuery}
                                filterVariable="member"
                                items={membershipList.memberships}
                                itemsNameLabel="member_email"
                                setFilterObject={setSeatQuery}
                                setQueryObject={setMembershipQuery}
                                setQueryVariable="member"
                                title="Assigned to"
                                width="200px"
                              />
                            </tr>
                          }
                          interactive={!organisationDetails.organisation.demo}
                          loading={membershipList.loading}
                          noMargin
                          onClearFilters={() => {
                            setSeatQuery({
                              ...seatQueryDefault,
                            });
                          }}
                          page={seatQuery.page}
                          setPageObject={setSeatQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Assign seats to team members"
                    icon="people"
                    title="Seats"
                  />

                  {/* AI credits */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Credit balance */}
                              <AICreditBalanceCard
                                aiCreditBalance={
                                  organisationDetails.organisation.ai_credits
                                }
                              />

                              {/* Credits used by period */}
                              <AICreditTransactionTotalCard
                                fill
                                aiCreditTransactionList={
                                  aiCreditTransactionList
                                }
                              />

                              {/* Credits used by activity */}
                              <AICreditTransactionPercentageCard
                                aiCreditTransactionList={
                                  aiCreditTransactionList
                                }
                                aiCreditTransactionQuery={
                                  aiCreditTransactionQuery
                                }
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={aiCreditTransactionList.ai_credit_transactions.map(
                            (transaction) => {
                              return (
                                <tr key={transaction.id}>
                                  {/* Staff */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={transaction.staff_name}
                                      image={transaction.staff_profile_photo}
                                      showImage
                                    />
                                  </td>

                                  {/* Action */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      color={
                                        transaction.action === "Answer"
                                          ? lightMode
                                            ? Colors.VIOLET3
                                            : Colors.VIOLET5
                                          : transaction.action === "Extract"
                                          ? lightMode
                                            ? Colors.INDIGO3
                                            : Colors.INDIGO5
                                          : transaction.action === "Index"
                                          ? lightMode
                                            ? Colors.CERULEAN3
                                            : Colors.CERULEAN5
                                          : lightMode
                                          ? Colors.TURQUOISE3
                                          : Colors.TURQUOISE5
                                      }
                                      value={transaction.action}
                                    />
                                  </td>

                                  {/* Timestamp */}
                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {transaction.timestamp
                                      ? new Date(
                                          transaction.timestamp
                                        ).toLocaleString("en-US", {
                                          dateStyle: "medium",
                                          timeStyle: "medium",
                                        })
                                      : undefined}
                                  </td>

                                  {/* Credits */}
                                  <td
                                    style={{
                                      textAlign: "end",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      noDecimals
                                      number={transaction.credits}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !aiCreditTransactionQuery.action &&
                            !aiCreditTransactionQuery.staff &&
                            !aiCreditTransactionQuery.timestampEnd &&
                            !aiCreditTransactionQuery.timestampStart
                          }
                          count={aiCreditTransactionList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="3"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    Credits
                                  </Tag>
                                </div>
                              </td>

                              {/* Total */}
                              <td
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                {aiCreditTransactionList.totalCredits
                                  ? aiCreditTransactionList.totalCredits.toLocaleString(
                                      "en-US",
                                      {
                                        maximumFractionDigits: 0,
                                        minimumFractionDigits: 0,
                                      }
                                    )
                                  : "-"}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Staff */}
                              <TableColumnHeadSelect2
                                filterObject={aiCreditTransactionQuery}
                                filterVariable="staff"
                                items={staffList.staff}
                                setFilterObject={setAICreditTransactionQuery}
                                setQueryObject={setStaffQuery}
                                setQueryVariable="searchString"
                                title="Staff"
                                width="200px"
                              />

                              {/* Action */}
                              <TableColumnHeadSelectSimple
                                filterObject={aiCreditTransactionQuery}
                                filterVariable="action"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Answer",
                                    icon: "chat",
                                    value: "Answer",
                                  },
                                  {
                                    display: "Extract",
                                    icon: "bring-data",
                                    value: "Extract",
                                  },
                                  {
                                    display: "Index",
                                    icon: "document-share",
                                    value: "Index",
                                  },
                                  {
                                    display: "Rewrite",
                                    icon: "manually-entered-data",
                                    value: "Rewrite",
                                  },
                                ]}
                                setFilterObject={setAICreditTransactionQuery}
                                title="Action"
                                width="125px"
                              />

                              {/* Timestamp */}
                              <TableColumnHeadDate
                                endDateVariable="timestampEnd"
                                noShortcuts
                                queryObject={aiCreditTransactionQuery}
                                setQueryObject={setAICreditTransactionQuery}
                                startDateVariable="timestampStart"
                                title="Timestamp"
                                width="125px"
                              />

                              {/* Credits */}
                              <TableColumnHeadSelectSimple
                                filterObject={aiCreditTransactionQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "credits",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-credits",
                                  },
                                ]}
                                setFilterObject={setAICreditTransactionQuery}
                                title="Credits"
                                width="125px"
                              />
                            </tr>
                          }
                          loading={reimbursementList.loading}
                          noMargin
                          onClearFilters={() => {
                            setAICreditTransactionQuery({
                              ...aiCreditTransactionQueryDefault,
                            });
                          }}
                          page={aiCreditTransactionQuery.page}
                          setPageObject={setAICreditTransactionQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Monitor AI credit usage"
                    icon="predictive-analysis"
                    title="AI credits"
                  />
                </div>
              }
            />

            {/* Financials */}
            <Tab
              id="organisation-financials-tab"
              title="Financials"
              panel={
                <div>
                  <DemoCoExpired />

                  {/* Settings */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listOrganisationDetailsAction(id));

                          setUpdateFinancialsDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Currency */}
                        <TabSectionField
                          helperText="The organisation's base currency, used across modules"
                          label="Currency"
                          loading={organisationDetails.loading}
                          value={
                            <div>
                              <Tag
                                minimal
                                style={{
                                  margin: "0 10px 0 0",
                                  textAlign: "center",
                                  width: "50px",
                                }}
                              >
                                {currencyGetCurrencyChoices.currencies
                                  .filter(
                                    (currency) =>
                                      currency[1] ===
                                      organisationDetails.organisation.currency
                                  )
                                  .map((currency) => {
                                    return currency[0];
                                  })}
                              </Tag>

                              {organisationDetails.organisation.currency}
                            </div>
                          }
                        />

                        {/* Gross margin */}
                        <TabSectionField
                          helperText="Default gross margin applied to pricing calculations"
                          label="Gross margin"
                          loading={organisationDetails.loading}
                          value={
                            <PercentageValue
                              percentage={
                                organisationDetails.organisation.gross_margin
                              }
                            />
                          }
                        />

                        {/* Sales tax rate */}
                        <TabSectionField
                          helperText="Default tax rate levied on fees"
                          label="Sales tax rate"
                          loading={organisationDetails.loading}
                          value={
                            <PercentageValue
                              percentage={
                                organisationDetails.organisation.sales_tax_rate
                              }
                            />
                          }
                        />

                        {/* Work time per day */}
                        <TabSectionField
                          bottom
                          helperText="Average working time per day, used for cost and utilisation calculations"
                          label="Work time per day"
                          loading={organisationDetails.loading}
                          value={
                            organisationDetails.organisation.minutes_per_day
                              ? convertMinutesToHoursAndMinutes(
                                  organisationDetails.organisation
                                    .minutes_per_day
                                )
                              : undefined
                          }
                        />
                      </>
                    }
                    help="Configure the organisation's financial settings"
                    icon="dollar"
                    title="Settings"
                  />

                  {/* Currencies */}
                  {checkSubscription.subscription.has_subscription && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddCurrencyDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search currency"
                              searching={rankList.loading}
                              setValueObject={setCurrencyQuery}
                              setValueVariable="searchString"
                              value={currencyQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={currencyList.currencies.map((currency) => {
                              return (
                                <ItemCard
                                  content=" "
                                  darkColor={Colors.GOLD3}
                                  height={65}
                                  icon="euro"
                                  interactive
                                  key={currency.id}
                                  lightColor={Colors.GOLD3}
                                  onClick={() => {
                                    dispatch(
                                      listCurrencyDetailsAction(currency.id)
                                    );

                                    setCurrencyExchangeRateQuery({
                                      currency: currency.id,
                                      page: 1,
                                    });

                                    setUpdateCurrencyDrawerIsOpen(true);
                                  }}
                                  title={
                                    <div
                                      style={{
                                        marginTop: "3px",
                                      }}
                                    >
                                      <Text ellipsize>{currency.name}</Text>
                                    </div>
                                  }
                                />
                              );
                            })}
                            count={currencyList.count}
                            loading={currencyList.loading}
                            noMargin
                            page={currencyQuery.page}
                            setPageObject={setCurrencyQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage currency exchange rates"
                      icon="euro"
                      title="Currencies"
                    />
                  )}

                  {/* Expense categories */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddExpenseCategoryDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <Button
                          icon="download"
                          intent="primary"
                          loading={expenseCategoryDownload.loading}
                          onClick={() => {
                            dispatch(
                              downloadExpenseCategoriesAction(
                                id,
                                expenseCategoryList.count
                              )
                            );
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Download"
                        />

                        <Button
                          icon="upload"
                          intent="primary"
                          onClick={() => {
                            setUploadExpenseCategoriesDialogIsOpen(true);
                          }}
                          text="Upload"
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search category"
                            searching={expenseCategoryList.loading}
                            setValueObject={setExpenseCategoryQuery}
                            setValueVariable="searchString"
                            value={expenseCategoryQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={expenseCategoryList.expense_categories.map(
                            (category) => {
                              return (
                                <ItemCard
                                  content=" "
                                  darkColor={Colors.BLUE3}
                                  height={65}
                                  icon="bank-account"
                                  interactive
                                  key={category.id}
                                  lightColor={Colors.BLUE3}
                                  onClick={() => {
                                    dispatch(
                                      listExpenseCategoryDetailsAction(
                                        category.id
                                      )
                                    );

                                    setUpdateExpenseCategoryDrawerIsOpen(true);
                                  }}
                                  title={
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Text ellipsize>{category.name}</Text>

                                      <InfoPopup
                                        content={
                                          <ExpenseCategoryInfo
                                            categoryData={category}
                                          />
                                        }
                                      />
                                    </div>
                                  }
                                />
                              );
                            }
                          )}
                          count={expenseCategoryList.count}
                          loading={expenseCategoryList.loading}
                          noMargin
                          page={expenseCategoryQuery.page}
                          setPageObject={setExpenseCategoryQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Manage expense categories"
                    icon="bank-account"
                    title="Expense categories"
                  />

                  {/* Leave categories */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddLeaveCategoryDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <Button
                          icon="download"
                          intent="primary"
                          loading={leaveCategoryDownload.loading}
                          onClick={() => {
                            dispatch(
                              downloadLeaveCategoriesAction(
                                id,
                                leaveCategoryList.count
                              )
                            );
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Download"
                        />

                        <Button
                          icon="upload"
                          intent="primary"
                          onClick={() => {
                            setUploadLeaveCategoriesDialogIsOpen(true);
                          }}
                          text="Upload"
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search category"
                            searching={leaveCategoryList.loading}
                            setValueObject={setLeaveCategoryQuery}
                            setValueVariable="searchString"
                            value={leaveCategoryQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={leaveCategoryList.leave_categories.map(
                            (category) => {
                              return (
                                <ItemCard
                                  content=" "
                                  darkColor={Colors.BLUE3}
                                  height={65}
                                  icon="bank-account"
                                  interactive
                                  key={category.id}
                                  lightColor={Colors.BLUE3}
                                  onClick={() => {
                                    dispatch(
                                      listLeaveCategoryDetailsAction(
                                        category.id
                                      )
                                    );

                                    setUpdateLeaveCategoryDrawerIsOpen(true);
                                  }}
                                  title={
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Text ellipsize>{category.name}</Text>

                                      <InfoPopup
                                        content={
                                          <LeaveCategoryInfo
                                            categoryData={category}
                                          />
                                        }
                                      />
                                    </div>
                                  }
                                />
                              );
                            }
                          )}
                          count={leaveCategoryList.count}
                          loading={leaveCategoryList.loading}
                          noMargin
                          page={leaveCategoryQuery.page}
                          setPageObject={setLeaveCategoryQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Manage leave categories"
                    icon="key-escape"
                    title="Leave categories"
                  />

                  {/* Ranks */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddRankDrawerIsOpen(true);
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search rank"
                            searching={rankList.loading}
                            setValueObject={setRankQuery}
                            setValueVariable="searchString"
                            value={rankQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={rankList.ranks.map((rank) => {
                            return (
                              <ItemCard
                                content=" "
                                darkColor={Colors.INDIGO3}
                                height={65}
                                icon="layout-hierarchy"
                                interactive
                                key={rank.id}
                                lightColor={Colors.INDIGO3}
                                onClick={() => {
                                  dispatch(listRankDetailsAction(rank.id));

                                  setRankCostRateQuery({
                                    page: 1,
                                    rank: rank.id,
                                  });

                                  setUpdateRankDrawerIsOpen(true);
                                }}
                                title={
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text ellipsize>{rank.name}</Text>

                                    <InfoPopup
                                      content={<RankInfo rankData={rank} />}
                                    />
                                  </div>
                                }
                              />
                            );
                          })}
                          count={rankList.count}
                          loading={rankList.loading}
                          noMargin
                          page={rankQuery.page}
                          setPageObject={setRankQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Define ranks and associated cost rates"
                    icon="layout-hierarchy"
                    title="Rank hierarchy"
                  />

                  {/* Booking codes */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddBookingCodeDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Budget position */}
                              <BookingCodeBudgetCard
                                fill
                                bookingCodeList={bookingCodeList}
                              />

                              {/* Profit & loss */}
                              <BookingCodeProfitLossCard
                                fill
                                bookingCodeList={bookingCodeList}
                              />

                              {/* Margin */}
                              {bookingCodeList.marginTotal >= 0 && (
                                <BookingCodeMarginCard
                                  bookingCodeList={bookingCodeList}
                                  bookingCodeQuery={bookingCodeQuery}
                                />
                              )}

                              {/* Billing */}
                              <BookingCodeWIPCard
                                fill
                                bookingCodeList={bookingCodeList}
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={bookingCodeList.booking_codes.map(
                            (booking_code) => {
                              return (
                                <tr
                                  key={booking_code.id}
                                  onClick={() => {
                                    dispatch(
                                      listBookingCodeDetailsAction(
                                        booking_code.id
                                      )
                                    );

                                    setUpdateBookingCodeDrawerIsOpen(true);
                                  }}
                                >
                                  {/* Name */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <BookingCodeInfo
                                          bookingCodeData={booking_code}
                                        />
                                      }
                                      tagIntent={
                                        booking_code.open ? "success" : "none"
                                      }
                                      tagText={
                                        booking_code.open ? "Open" : "Closed"
                                      }
                                      text={booking_code.name}
                                    />
                                  </td>

                                  {/* Year */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag value={booking_code.year} />
                                  </td>

                                  {/* Billable */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      intent={
                                        booking_code.billable
                                          ? "success"
                                          : "primary"
                                      }
                                      value={
                                        booking_code.billable
                                          ? "Billable"
                                          : "Not billable"
                                      }
                                    />
                                  </td>

                                  {/* Budget */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber number={booking_code.budget} />
                                  </td>

                                  {/* Spent */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={booking_code.total_expense}
                                    />
                                  </td>

                                  {/* Revenue */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={booking_code.total_revenue}
                                    />
                                  </td>

                                  {/* Invoiced */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={booking_code.total_invoiced}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !bookingCodeQuery.billable &&
                            !bookingCodeQuery.searchString &&
                            !bookingCodeQuery.year
                          }
                          count={bookingCodeList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="3"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {
                                      organisationDetails.organisation
                                        .currency_short
                                    }
                                  </Tag>
                                </div>
                              </td>

                              {/* Budget */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={bookingCodeList.budgetTotal}
                                />
                              </td>

                              {/* Spent */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={bookingCodeList.totalExpenseTotal}
                                />
                              </td>

                              {/* Revenue */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={bookingCodeList.revenueTotal}
                                />
                              </td>

                              {/* Invoiced */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={bookingCodeList.invoicedTotal}
                                />
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Name */}
                              <TableColumnHeadSearch
                                queryObject={bookingCodeQuery}
                                queryVariable="searchString"
                                setQueryObject={setBookingCodeQuery}
                                setQueryVariable="searchString"
                                title="Name"
                                width="300px"
                              />

                              {/* Year */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingCodeQuery}
                                filterVariable="year"
                                options={generateYearList}
                                setFilterObject={setBookingCodeQuery}
                                title="Year"
                                width="125px"
                              />

                              {/* Billable */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingCodeQuery}
                                filterVariable="billable"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Billable",
                                    icon: "dollar",
                                    value: "true",
                                  },
                                  {
                                    display: "Not billable",
                                    icon: "cross",
                                    value: "false",
                                  },
                                ]}
                                setFilterObject={setBookingCodeQuery}
                                title="Billable"
                                width="125px"
                              />

                              {/* Budget */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingCodeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "budget",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-budget",
                                  },
                                ]}
                                setFilterObject={setBookingCodeQuery}
                                title="Budget"
                                width="125px"
                              />

                              {/* Spent */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingCodeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "total_expense",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-total_expense",
                                  },
                                ]}
                                setFilterObject={setBookingCodeQuery}
                                title="Spent"
                                width="125px"
                              />

                              {/* Revenue */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingCodeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "total_revenue",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-total_revenue",
                                  },
                                ]}
                                setFilterObject={setBookingCodeQuery}
                                title="Revenue"
                                width="125px"
                              />

                              {/* Invoiced */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingCodeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "total_invoiced",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-total_invoiced",
                                  },
                                ]}
                                setFilterObject={setBookingCodeQuery}
                                title="Invoiced"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={bookingCodeList.loading}
                          noMargin
                          onClearFilters={() => {
                            setBookingCodeQuery({
                              ...bookingCodeQueryDefault,
                            });
                          }}
                          page={bookingCodeQuery.page}
                          setPageObject={setBookingCodeQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage the organisation's booking codes"
                    icon="barcode"
                    title="Booking codes"
                  />

                  {/* Reimbursements */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="download"
                        intent="primary"
                        loading={reimbursementDownload.loading}
                        onClick={() => {
                          dispatch(
                            downloadReimbursementsAction(
                              id,
                              reimbursementList.count
                            )
                          );
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Download"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={reimbursementList.reimbursements.map(
                            (reimbursement) => {
                              return (
                                <tr
                                  key={reimbursement.id}
                                  onClick={() => {
                                    dispatch(
                                      listReimbursementDetailsAction(
                                        reimbursement.id
                                      )
                                    );

                                    setUpdateReimbursementDrawerIsOpen(true);
                                  }}
                                >
                                  {/* Claim */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{reimbursement.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Comments</strong>
                                          </p>

                                          <p>{reimbursement.comments}</p>
                                        </>
                                      }
                                      text={reimbursement.name}
                                    />
                                  </td>

                                  {/* Staff */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={reimbursement.staff_name}
                                      image={reimbursement.staff_profile_photo}
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        reimbursement.status === "Not paid"
                                          ? "danger"
                                          : "success"
                                      }
                                      value={reimbursement.status}
                                    />
                                  </td>

                                  {/* Paid */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={reimbursement.paid} />
                                  </td>

                                  {/* Amount */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber number={reimbursement.value} />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !reimbursementQuery.paidEnd &&
                            !reimbursementQuery.paidStart &&
                            !reimbursementQuery.searchString &&
                            !reimbursementQuery.staff &&
                            !reimbursementQuery.status
                          }
                          count={reimbursementList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="4"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {
                                      organisationDetails.organisation
                                        .currency_short
                                    }
                                  </Tag>
                                </div>
                              </td>

                              {/* Amount */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={reimbursementList.totalValue}
                                />
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Claim */}
                              <TableColumnHeadSearch
                                queryObject={reimbursementQuery}
                                queryVariable="searchString"
                                setQueryObject={setReimbursementQuery}
                                setQueryVariable="searchString"
                                title="Claim"
                                width="200px"
                              />

                              {/* Staff */}
                              <TableColumnHeadSelect2
                                filterObject={reimbursementQuery}
                                filterVariable="staff"
                                items={staffList.staff}
                                setFilterObject={setReimbursementQuery}
                                setQueryObject={setStaffQuery}
                                setQueryVariable="searchString"
                                title="Staff"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={reimbursementQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Paid",
                                    icon: "small-tick",
                                    value: "Paid",
                                  },
                                  {
                                    display: "Not paid",
                                    icon: "small-cross",
                                    value: "Not paid",
                                  },
                                ]}
                                setFilterObject={setReimbursementQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Paid */}
                              <TableColumnHeadDate
                                endDateVariable="paidEnd"
                                noShortcuts
                                queryObject={reimbursementQuery}
                                setQueryObject={setReimbursementQuery}
                                shortRange
                                startDateVariable="paidStart"
                                title="Paid"
                                width="125px"
                              />

                              {/* Amount */}
                              <TableColumnHeadSelectSimple
                                filterObject={reimbursementQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setReimbursementQuery}
                                title="Amount"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={reimbursementList.loading}
                          noMargin
                          onClearFilters={() => {
                            setReimbursementQuery({
                              ...reimbursementQueryDefault,
                            });
                          }}
                          page={reimbursementQuery.page}
                          setPageObject={setReimbursementQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Process expense reimbursements"
                    icon="credit-card"
                    title="Reimbursements"
                  />

                  {/* Invoices */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="download"
                        intent="primary"
                        loading={invoiceDownload.loading}
                        onClick={() => {
                          dispatch(
                            downloadInvoicesAction(id, invoiceList.count)
                          );
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Download"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={invoiceList.invoices.map((invoice) => {
                            return (
                              <tr key={invoice.id}>
                                {/* Invoice */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText text={invoice.name} />
                                </td>

                                {/* Client */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    image={invoice.client_logo}
                                    logo
                                    showImage
                                    text={invoice.client_name}
                                    url={`/clients/${invoice.client}`}
                                  />
                                </td>

                                {/* Value */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={invoice.value} />
                                </td>

                                {/* Manager */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    email={invoice.manager_name}
                                    image={invoice.manager_profile_photo}
                                    showImage
                                  />
                                </td>

                                {/* Issued */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={invoice.issued} />
                                </td>

                                {/* Due */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={invoice.due} />
                                </td>

                                {/* Paid */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={invoice.paid} />
                                </td>

                                {/* Status */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableTag
                                    intent={
                                      invoice.status === "Disputed"
                                        ? "danger"
                                        : invoice.status === "Draft"
                                        ? "primary"
                                        : invoice.status === "Invoiced"
                                        ? "warning"
                                        : invoice.status === "Paid"
                                        ? "success"
                                        : undefined
                                    }
                                    value={invoice.status}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !invoiceQuery.client &&
                            !invoiceQuery.dueEnd &&
                            !invoiceQuery.dueStart &&
                            !invoiceQuery.issuedEnd &&
                            !invoiceQuery.issuedStart &&
                            !invoiceQuery.manager &&
                            !invoiceQuery.paidEnd &&
                            !invoiceQuery.paidStart &&
                            !invoiceQuery.searchString &&
                            !invoiceQuery.status
                          }
                          count={invoiceList.count}
                          footnote={
                            <div>
                              Figures in{" "}
                              {organisationDetails.organisation.currency}{" "}
                              excluding sales tax
                            </div>
                          }
                          head={
                            <tr>
                              {/* Invoice */}
                              <TableColumnHeadSearch
                                queryObject={invoiceQuery}
                                queryVariable="searchString"
                                setQueryObject={setInvoiceQuery}
                                setQueryVariable="searchString"
                                title="Invoice"
                                width="200px"
                              />

                              {/* Client */}
                              <TableColumnHeadSelect2
                                filterObject={invoiceQuery}
                                filterVariable="client"
                                items={clientList.clients}
                                setFilterObject={setInvoiceQuery}
                                setQueryObject={setClientQuery}
                                setQueryVariable="searchString"
                                title="Client"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={invoiceQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setInvoiceQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Manager */}
                              <TableColumnHeadSelect2
                                filterObject={invoiceQuery}
                                filterVariable="manager"
                                items={managerList.managers}
                                setFilterObject={setInvoiceQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Manager"
                                width="200px"
                              />

                              {/* Issued */}
                              <TableColumnHeadDate
                                endDateVariable="issuedEnd"
                                noShortcuts
                                queryObject={invoiceQuery}
                                setQueryObject={setInvoiceQuery}
                                shortRange
                                startDateVariable="issuedStart"
                                title="Issued"
                                width="125px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                noShortcuts
                                queryObject={invoiceQuery}
                                setQueryObject={setInvoiceQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Paid */}
                              <TableColumnHeadDate
                                endDateVariable="paidEnd"
                                noShortcuts
                                queryObject={invoiceQuery}
                                setQueryObject={setInvoiceQuery}
                                shortRange
                                startDateVariable="paidStart"
                                title="Paid"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={invoiceQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Invoiced",
                                    icon: "document-share",
                                    value: "Invoiced",
                                  },
                                  {
                                    display: "Paid",
                                    icon: "dollar",
                                    value: "Paid",
                                  },
                                  {
                                    display: "Disputed",
                                    icon: "issue",
                                    value: "Disputed",
                                  },
                                  {
                                    display: "Credited",
                                    icon: "undo",
                                    value: "Credited",
                                  },
                                ]}
                                setFilterObject={setInvoiceQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          loading={invoiceList.loading}
                          noMargin
                          onClearFilters={() => {
                            setInvoiceQuery({
                              ...invoiceQueryDefault,
                            });
                          }}
                          page={invoiceQuery.page}
                          setPageObject={setInvoiceQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Export invoice data"
                    icon="dollar"
                    message={
                      <>
                        You can access detailed invoice information in the{" "}
                        <LinkTag
                          label="Invoices"
                          onClick={() => {
                            navigate(`/invoices`);
                          }}
                        />{" "}
                        module.
                      </>
                    }
                    messageIntent="primary"
                    title="Invoices"
                  />
                </div>
              }
            />

            {/* Governance */}
            <Tab
              id="organisation-governance-tab"
              title="Governance"
              panel={
                <div>
                  <DemoCoExpired />

                  {/* Approval roles */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddApprovalRoleDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search approval role"
                            searching={approvalRoleList.loading}
                            setValueObject={setApprovalRoleQuery}
                            setValueVariable="searchString"
                            value={approvalRoleQuery.member}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={approvalRoleList.approval_roles.map(
                            (approval_role, i, { length }) => {
                              return (
                                <TabSectionField
                                  bottom={length - 1 === i}
                                  key={approval_role.id}
                                  label={
                                    <Text ellipsize>{approval_role.name}</Text>
                                  }
                                  value={
                                    <TabSectionFieldMultifieldCard
                                      children={
                                        <>
                                          <TabSectionFieldCollapse
                                            content={
                                              <>
                                                {/* Client account plan */}
                                                <TabSectionField
                                                  bottom
                                                  helperText={
                                                    approval_role.can_approve_client_account_plan
                                                      ? `Approve plans for clients with annual revenue to ${
                                                          organisationDetails
                                                            .organisation
                                                            .currency_short
                                                        } ${approval_role.can_approve_client_account_plan_threshold.toLocaleString(
                                                          undefined,
                                                          {
                                                            maximumFractionDigits: 0,
                                                          }
                                                        )}`
                                                      : "Unable to approve account plans"
                                                  }
                                                  label="Client account plan"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_client_account_plan
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_client_account_plan
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />

                                                {/* Client surveys */}
                                                {false && (
                                                  <TabSectionField
                                                    bottom
                                                    helperText={
                                                      approval_role.can_approve_client_survey
                                                        ? `Authorise surveys for clients with annual revenue to ${
                                                            organisationDetails
                                                              .organisation
                                                              .currency_short
                                                          } ${approval_role.can_approve_client_survey_threshold.toLocaleString(
                                                            undefined,
                                                            {
                                                              maximumFractionDigits: 0,
                                                            }
                                                          )}`
                                                        : "Unable to authorise client surveys"
                                                    }
                                                    label="Client surveys"
                                                    value={
                                                      <Tag
                                                        intent={
                                                          approval_role.can_approve_client_survey
                                                            ? "success"
                                                            : "danger"
                                                        }
                                                        minimal
                                                        style={{
                                                          minWidth: "100px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {approval_role.can_approve_client_survey
                                                          ? "Yes"
                                                          : "No"}
                                                      </Tag>
                                                    }
                                                  />
                                                )}
                                              </>
                                            }
                                            icon="office"
                                            title="Clients"
                                          />

                                          <TabSectionFieldCollapse
                                            content={
                                              <>
                                                {/* Engagement baseline */}
                                                <TabSectionField
                                                  helperText={
                                                    approval_role.can_approve_engagement_baseline ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Approve baselines for
                                                        engagements to a value
                                                        of
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_engagement_baseline_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to approve engagement baselines"
                                                    )
                                                  }
                                                  label="Engagement baseline"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_engagement_baseline
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_engagement_baseline
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />

                                                {/* Engagement deliverable */}
                                                {false && (
                                                  <TabSectionField
                                                    helperText={
                                                      approval_role.can_approve_engagement_deliverable ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          Authorise deliverables
                                                          to a value of
                                                          <div
                                                            style={{
                                                              margin:
                                                                "0 5px 0 5px",
                                                            }}
                                                          >
                                                            <NumericValue
                                                              currency={
                                                                organisationDetails
                                                                  .organisation
                                                                  .currency_short
                                                              }
                                                              noTag
                                                              number={
                                                                approval_role.can_approve_engagement_deliverable_threshold
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        "Unable to approve engagement deliverables"
                                                      )
                                                    }
                                                    label="Engagement deliverables"
                                                    value={
                                                      <Tag
                                                        intent={
                                                          approval_role.can_approve_engagement_deliverable
                                                            ? "success"
                                                            : "danger"
                                                        }
                                                        minimal
                                                        style={{
                                                          minWidth: "100px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {approval_role.can_approve_engagement_deliverable
                                                          ? "Yes"
                                                          : "No"}
                                                      </Tag>
                                                    }
                                                  />
                                                )}

                                                {/* Engagement close */}
                                                <TabSectionField
                                                  bottom
                                                  helperText={
                                                    approval_role.can_approve_engagement_close ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Authorise close of
                                                        engagements to a value
                                                        of
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_engagement_close_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to approve engagement close"
                                                    )
                                                  }
                                                  label="Engagement close"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_engagement_close
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_engagement_close
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />
                                              </>
                                            }
                                            icon="gantt-chart"
                                            title="Engagements"
                                          />

                                          <TabSectionFieldCollapse
                                            content={
                                              <>
                                                {/* Invoice */}
                                                <TabSectionField
                                                  bottom
                                                  helperText={
                                                    approval_role.can_approve_invoice ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Approve invoices to a
                                                        value of
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_invoice_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to approve invoices"
                                                    )
                                                  }
                                                  label="Invoice"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_invoice
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_invoice
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />
                                              </>
                                            }
                                            icon="dollar"
                                            title="Invoices"
                                          />

                                          <TabSectionFieldCollapse
                                            content={
                                              <>
                                                {/* Proposal qualification */}
                                                <TabSectionField
                                                  helperText={
                                                    approval_role.can_approve_proposal_qualification ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Qualify proposals to a
                                                        value of
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_proposal_qualification_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to qualify proposals"
                                                    )
                                                  }
                                                  label="Proposal qualification"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_proposal_qualification
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_proposal_qualification
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />

                                                {/* Proposal strategy */}
                                                {false && (
                                                  <TabSectionField
                                                    helperText={
                                                      approval_role.can_approve_proposal_strategy ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          Approve strategy for
                                                          proposals to a value
                                                          of
                                                          <div
                                                            style={{
                                                              margin:
                                                                "0 5px 0 5px",
                                                            }}
                                                          >
                                                            <NumericValue
                                                              currency={
                                                                organisationDetails
                                                                  .organisation
                                                                  .currency_short
                                                              }
                                                              noTag
                                                              number={
                                                                approval_role.can_approve_proposal_strategy_threshold
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        "Unable to approve proposal strategy"
                                                      )
                                                    }
                                                    label="Proposal strategy"
                                                    value={
                                                      <Tag
                                                        intent={
                                                          approval_role.can_approve_proposal_strategy
                                                            ? "success"
                                                            : "danger"
                                                        }
                                                        minimal
                                                        style={{
                                                          minWidth: "100px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {approval_role.can_approve_proposal_strategy
                                                          ? "Yes"
                                                          : "No"}
                                                      </Tag>
                                                    }
                                                  />
                                                )}

                                                {/* Proposal solution */}
                                                {false && (
                                                  <TabSectionField
                                                    helperText={
                                                      approval_role.can_approve_proposal_solution ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          Approve solution for
                                                          proposals to a value
                                                          of
                                                          <div
                                                            style={{
                                                              margin:
                                                                "0 5px 0 5px",
                                                            }}
                                                          >
                                                            <NumericValue
                                                              currency={
                                                                organisationDetails
                                                                  .organisation
                                                                  .currency_short
                                                              }
                                                              noTag
                                                              number={
                                                                approval_role.can_approve_proposal_solution_threshold
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        "Unable to approve proposal solution"
                                                      )
                                                    }
                                                    label="Proposal solution"
                                                    value={
                                                      <Tag
                                                        intent={
                                                          approval_role.can_approve_proposal_solution
                                                            ? "success"
                                                            : "danger"
                                                        }
                                                        minimal
                                                        style={{
                                                          minWidth: "100px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {approval_role.can_approve_proposal_solution
                                                          ? "Yes"
                                                          : "No"}
                                                      </Tag>
                                                    }
                                                  />
                                                )}

                                                {/* Proposal commercials */}
                                                {false && (
                                                  <TabSectionField
                                                    helperText={
                                                      approval_role.can_approve_proposal_commercials ? (
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                          }}
                                                        >
                                                          Approve commercials
                                                          for proposals to a
                                                          value of
                                                          <div
                                                            style={{
                                                              margin:
                                                                "0 5px 0 5px",
                                                            }}
                                                          >
                                                            <NumericValue
                                                              currency={
                                                                organisationDetails
                                                                  .organisation
                                                                  .currency_short
                                                              }
                                                              noTag
                                                              number={
                                                                approval_role.can_approve_proposal_commercials_threshold
                                                              }
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        "Unable to approve proposal commercials"
                                                      )
                                                    }
                                                    label="Proposal commercials"
                                                    value={
                                                      <Tag
                                                        intent={
                                                          approval_role.can_approve_proposal_commercials
                                                            ? "success"
                                                            : "danger"
                                                        }
                                                        minimal
                                                        style={{
                                                          minWidth: "100px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {approval_role.can_approve_proposal_commercials
                                                          ? "Yes"
                                                          : "No"}
                                                      </Tag>
                                                    }
                                                  />
                                                )}

                                                {/* Proposal submission */}
                                                <TabSectionField
                                                  bottom
                                                  helperText={
                                                    approval_role.can_approve_proposal_submission ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Authorise submission of
                                                        proposals to a value of
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_proposal_submission_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to authorise proposal submission"
                                                    )
                                                  }
                                                  label="Proposal submission"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_proposal_submission
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_proposal_submission
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />
                                              </>
                                            }
                                            icon="manual"
                                            title="Proposals"
                                          />

                                          <TabSectionFieldCollapse
                                            bottom
                                            content={
                                              <>
                                                {/* Staff recruitment */}
                                                <TabSectionField
                                                  helperText={
                                                    approval_role.can_approve_staff_recruitment ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Approve hiring of staff
                                                        with salary to
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_staff_recruitment_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to approve the recruitment of staff"
                                                    )
                                                  }
                                                  label="Staff recruitment"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_staff_recruitment
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_staff_recruitment
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />

                                                {/* Staff expense */}
                                                <TabSectionField
                                                  helperText={
                                                    approval_role.can_approve_staff_expense ? (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        Approve expenses to a
                                                        value of
                                                        <div
                                                          style={{
                                                            margin:
                                                              "0 5px 0 5px",
                                                          }}
                                                        >
                                                          <NumericValue
                                                            currency={
                                                              organisationDetails
                                                                .organisation
                                                                .currency_short
                                                            }
                                                            noTag
                                                            number={
                                                              approval_role.can_approve_staff_expense_threshold
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      "Unable to approve expenses"
                                                    )
                                                  }
                                                  label="Staff expense"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_staff_expense
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_staff_expense
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />

                                                {/* Staff timesheet */}
                                                <TabSectionField
                                                  helperText={
                                                    approval_role.can_approve_staff_timesheet
                                                      ? `Approve timesheets to a time of ${
                                                          convertMinutesToHoursAndMinutes(
                                                            approval_role.can_approve_staff_timesheet_threshold
                                                          ).split(":")[0]
                                                        }h ${
                                                          convertMinutesToHoursAndMinutes(
                                                            approval_role.can_approve_staff_timesheet_threshold
                                                          ).split(":")[1]
                                                        }m`
                                                      : "Unable to approve timesheets"
                                                  }
                                                  label="Staff timesheet"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_staff_timesheet
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_staff_timesheet
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />

                                                {/* Staff leave */}
                                                <TabSectionField
                                                  bottom
                                                  helperText={
                                                    approval_role.can_approve_staff_leave
                                                      ? `Approve leave to a time of ${
                                                          convertMinutesToHoursAndMinutes(
                                                            approval_role.can_approve_staff_leave_threshold
                                                          ).split(":")[0]
                                                        }h ${
                                                          convertMinutesToHoursAndMinutes(
                                                            approval_role.can_approve_staff_leave_threshold
                                                          ).split(":")[1]
                                                        }m`
                                                      : "Unable to approve leave"
                                                  }
                                                  label="Staff leave"
                                                  value={
                                                    <Tag
                                                      intent={
                                                        approval_role.can_approve_staff_leave
                                                          ? "success"
                                                          : "danger"
                                                      }
                                                      minimal
                                                      style={{
                                                        minWidth: "100px",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {approval_role.can_approve_staff_leave
                                                        ? "Yes"
                                                        : "No"}
                                                    </Tag>
                                                  }
                                                />
                                              </>
                                            }
                                            icon="people"
                                            title="Staff"
                                          />

                                          <Button
                                            icon="edit"
                                            intent="primary"
                                            onClick={() => {
                                              dispatch(
                                                listApprovalRoleDetailsAction(
                                                  approval_role.id
                                                )
                                              );

                                              setUpdateApprovalRoleDrawerIsOpen(
                                                true
                                              );
                                            }}
                                            style={{ marginTop: "20px" }}
                                            text="Edit"
                                          />
                                        </>
                                      }
                                    />
                                  }
                                />
                              );
                            }
                          )}
                          count={approvalRoleList.count}
                          loading={approvalRoleList.loading}
                          noResultsMessage="Add approval roles."
                          noResultsTitle="No approval roles found"
                          page={approvalRoleQuery.page}
                          setPageObject={setApprovalRoleQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Manage the organisation's governance structure"
                    icon="hat"
                    message="An approval role defines a set of decision rights. Create the organisation's approval roles and then decide what is the appropriate role to assign to each team member."
                    messageIntent="primary"
                    title="Approval roles"
                  />

                  {/* Managers */}
                  <TabSection2
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={managerList.managers.map((manager) => {
                            return (
                              <tr
                                key={manager.id}
                                onClick={() => {
                                  if (
                                    !manager.organisation_owner &&
                                    manager.own_record
                                  ) {
                                    AppToaster.show({
                                      icon: "warning-sign",
                                      intent: "warning",
                                      message:
                                        "You cannot assign an approval role to yourself.",
                                    });
                                  } else {
                                    dispatch(
                                      listManagerDetailsAction(manager.id)
                                    );

                                    setUpdateManagerDrawerIsOpen(true);
                                  }
                                }}
                              >
                                {/* Manager */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    image={manager.profile_photo}
                                    showImage
                                    tagIntent="primary"
                                    tagText={
                                      manager.own_record
                                        ? "Own record"
                                        : undefined
                                    }
                                    text={manager.name}
                                  />
                                </td>

                                {/* Approval role */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <Text ellipsize>
                                    {manager.approval_role_data
                                      ? manager.approval_role_data.name
                                      : "-"}
                                  </Text>
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !managerQuery.approvalRole &&
                            !managerQuery.searchString
                          }
                          count={approvalRoleList.count}
                          head={
                            <tr>
                              {/* Manager */}
                              <TableColumnHeadSearch
                                queryObject={managerQuery}
                                queryVariable="searchString"
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Manager"
                                width="200px"
                              />

                              {/* Approval role */}
                              <TableColumnHeadSelect2
                                filterObject={managerQuery}
                                filterVariable="approvalRole"
                                items={approvalRoleList.approval_roles}
                                setFilterObject={setManagerQuery}
                                setQueryObject={setApprovalRoleQuery}
                                setQueryVariable="searchString"
                                title="Approval role"
                                width="200px"
                              />
                            </tr>
                          }
                          interactive
                          loading={managerList.loading}
                          noMargin
                          onClearFilters={() => {
                            setManagerQuery({
                              ...managerQueryDefault,
                            });
                          }}
                          page={managerQuery.page}
                          setPageObject={setManagerQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Assign approval roles to managers"
                    icon="layout-hierarchy"
                    title="Managers"
                  />

                  {/* Decision log */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="download"
                        intent="primary"
                        loading={approvalRequestDownload.loading}
                        onClick={() => {
                          dispatch(
                            downloadApprovalRequestsAction(
                              id,
                              approvalRequestList.count
                            )
                          );
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Download"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalRequestList.approval_requests.map(
                            (approval_request) => {
                              return (
                                <tr
                                  key={approval_request.id}
                                  onClick={() => {
                                    dispatch(
                                      listApprovalRequestDetailsAction(
                                        approval_request.id
                                      )
                                    );

                                    // Open a different drawer depending on the decision
                                    switch (approval_request.decision_right) {
                                      default:
                                        setViewProposalQualificationApprovalDrawerIsOpen(
                                          true
                                        );
                                    }
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={approval_request.decision_right}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={
                                        approval_request.decision_right ===
                                          "Staff leave" ||
                                        approval_request.decision_right ===
                                          "Staff timesheet"
                                          ? approval_request.value / 60
                                          : approval_request.value
                                      }
                                      tagText={
                                        approval_request.decision_right ===
                                          "Staff leave" ||
                                        approval_request.decision_right ===
                                          "Staff timesheet"
                                          ? "Hours"
                                          : organisationDetails.organisation
                                              .currency_short
                                      }
                                    />
                                  </td>

                                  {/* Approver */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={approval_request.approver_name}
                                      image={
                                        approval_request.approver_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={
                                        approval_request.response_data.reviewed
                                      }
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome === "Approved"
                                            ? "success"
                                            : approval_request.response_data
                                                .outcome === "Denied"
                                            ? "danger"
                                            : approval_request.response_data
                                                .outcome === "Reversed"
                                            ? "warning"
                                            : "primary"
                                          : undefined
                                      }
                                      value={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalRequestQuery.approver &&
                            !approvalRequestQuery.decisionRight &&
                            !approvalRequestQuery.outcome &&
                            !approvalRequestQuery.reviewedEnd &&
                            !approvalRequestQuery.reviewedStart &&
                            !approvalRequestQuery.value
                          }
                          count={approvalRequestList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Client account plan",
                                    value: "Client account plan",
                                  },
                                  /*{
                                    display: "Client survey",
                                    value: "Client survey",
                                  },*/
                                  {
                                    display: "Engagement baseline",
                                    value: "Engagement baseline",
                                  },
                                  /*{
                                    display: "Engagement deliverable",
                                    value: "Engagement deliverable",
                                  },*/
                                  {
                                    display: "Engagement close",
                                    value: "Engagement close",
                                  },
                                  {
                                    display: "Invoice",
                                    value: "Invoice",
                                  },
                                  {
                                    display: "Proposal qualification",
                                    value: "Proposal qualification",
                                  },
                                  /*{
                                    display: "Proposal strategy",
                                    value: "Proposal strategy",
                                  },*/
                                  /*{
                                    display: "Proposal solution",
                                    value: "Proposal solution",
                                  },*/
                                  /*{
                                    display: "Proposal commercials",
                                    value: "Proposal commercials",
                                  },*/
                                  {
                                    display: "Proposal submission",
                                    value: "Proposal submission",
                                  },
                                  {
                                    display: "Staff expense",
                                    value: "Staff expense",
                                  },
                                  {
                                    display: "Staff leave",
                                    value: "Staff leave",
                                  },
                                  {
                                    display: "Staff recruitment",
                                    value: "Staff recruitment",
                                  },
                                  {
                                    display: "Staff timesheet",
                                    value: "Staff timesheet",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Approver */}
                              <TableColumnHeadSelect2
                                filterObject={approvalRequestQuery}
                                filterVariable="approver"
                                items={managerList.managers}
                                setFilterObject={setApprovalRequestQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Approver"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalRequestQuery}
                                setQueryObject={setApprovalRequestQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                  {
                                    display: "Reversed",
                                    icon: "undo",
                                    value: "Reversed",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalRequestQuery({
                              ...approvalRequestQueryDefault,
                            });
                          }}
                          page={approvalRequestQuery.page}
                          setPageObject={setApprovalRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Access the organisation's decision history"
                    icon="book"
                    title="Decision log"
                  />
                </div>
              }
            />

            {/* Metadata */}
            <Tab
              id="organisation-metadata-tab"
              title="Metadata"
              panel={
                <div>
                  <DemoCoExpired />

                  {/* Knowledge base documents */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          dispatch(checkSubscriptionAction(id));

                          setAddKnowledgeBaseDocumentDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search file"
                            searching={knowledgeBaseDocumentList.loading}
                            setValueObject={setKnowledgeBaseDocumentQuery}
                            setValueVariable="searchString"
                            value={knowledgeBaseDocumentQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={knowledgeBaseDocumentList.knowledge_base_documents.map(
                            (document) => {
                              return (
                                <ItemCard
                                  content={
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Tag
                                        minimal
                                        style={{
                                          background:
                                            document.category ===
                                            "Service Definition"
                                              ? Colors.TURQUOISE3
                                              : document.category === "Proposal"
                                              ? Colors.FOREST3
                                              : document.category ===
                                                "Engagement Charter"
                                              ? Colors.CERULEAN3
                                              : document.category ===
                                                "Case Study"
                                              ? Colors.VERMILION3
                                              : document.category ===
                                                "Publication"
                                              ? Colors.ROSE3
                                              : Colors.BLUE3,
                                          color: setTextColour(
                                            document.category ===
                                              "Service Definition"
                                              ? Colors.TURQUOISE3
                                              : document.category === "Proposal"
                                              ? Colors.FOREST3
                                              : document.category ===
                                                "Engagement Charter"
                                              ? Colors.CERULEAN3
                                              : document.category ===
                                                "Case Study"
                                              ? Colors.VERMILION3
                                              : document.category ===
                                                "Publication"
                                              ? Colors.ROSE3
                                              : Colors.BLUE3
                                          ),
                                          marginRight: "5px",
                                        }}
                                      >
                                        {document.category}
                                      </Tag>

                                      <InfoPopup
                                        content={
                                          <KnowledgeBaseDocumentInfo
                                            documentData={document}
                                          />
                                        }
                                      />
                                    </div>
                                  }
                                  darkColor={
                                    document.category === "Service Definition"
                                      ? Colors.TURQUOISE3
                                      : document.category === "Proposal"
                                      ? Colors.FOREST3
                                      : document.category ===
                                        "Engagement Charter"
                                      ? Colors.CERULEAN3
                                      : document.category === "Case Study"
                                      ? Colors.VERMILION3
                                      : document.category === "Publication"
                                      ? Colors.ROSE3
                                      : Colors.BLUE3
                                  }
                                  height={100}
                                  icon="predictive-analysis"
                                  interactive
                                  key={document.id}
                                  lightColor={
                                    document.category === "Service Definition"
                                      ? Colors.TURQUOISE3
                                      : document.category === "Proposal"
                                      ? Colors.FOREST3
                                      : document.category ===
                                        "Engagement Charter"
                                      ? Colors.CERULEAN3
                                      : document.category === "Case Study"
                                      ? Colors.VERMILION3
                                      : document.category === "Publication"
                                      ? Colors.ROSE3
                                      : Colors.BLUE3
                                  }
                                  onClick={() => {
                                    dispatch(checkSubscriptionAction(id));

                                    dispatch(
                                      listKnowledgeBaseDocumentDetailsAction(
                                        document.id
                                      )
                                    );

                                    setUpdateKnowledgeBaseDocumentDrawerIsOpen(
                                      true
                                    );
                                  }}
                                  title={document.name}
                                />
                              );
                            }
                          )}
                          count={knowledgeBaseDocumentList.count}
                          loading={knowledgeBaseDocumentList.loading}
                          noMargin
                          page={knowledgeBaseDocumentQuery.page}
                          setPageObject={setKnowledgeBaseDocumentQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Manage the organisation's knowledge base"
                    icon="predictive-analysis"
                    message="advaise.app can use your documents to answer questions and generate content. While the system is capable of processing csv, pdf and pptx files, you will achieve the highest performance with unformatted documents in docx or txt format."
                    messageIntent="primary"
                    title="Knowledge base documents"
                  />

                  {/* Competencies */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddCompetencyDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <Button
                          icon="download"
                          intent="primary"
                          loading={competencyDownload.loading}
                          onClick={() => {
                            dispatch(
                              downloadCompetenciesAction(
                                id,
                                competencyList.count
                              )
                            );
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Download"
                        />

                        <Button
                          icon="upload"
                          intent="primary"
                          onClick={() => {
                            setUploadCompetenciesDialogIsOpen(true);
                          }}
                          text="Upload"
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search competency"
                            searching={competencyList.loading}
                            setValueObject={setCompetencyQuery}
                            setValueVariable="searchString"
                            value={competencyQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={competencyList.competencies.map(
                            (competency) => {
                              return (
                                <ItemCard
                                  content=" "
                                  darkColor={Colors.INDIGO3}
                                  height={65}
                                  icon="build"
                                  interactive
                                  key={competency.id}
                                  lightColor={Colors.INDIGO3}
                                  onClick={() => {
                                    dispatch(
                                      listCompetencyDetailsAction(competency.id)
                                    );

                                    setUpdateCompetencyDrawerIsOpen(true);
                                  }}
                                  title={
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Text ellipsize>{competency.name}</Text>

                                      <InfoPopup
                                        content={
                                          <CompetencyInfo
                                            competencyData={competency}
                                          />
                                        }
                                      />
                                    </div>
                                  }
                                />
                              );
                            }
                          )}
                          count={competencyList.count}
                          loading={competencyList.loading}
                          noMargin
                          page={competencyQuery.page}
                          setPageObject={setCompetencyQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Define the organisation's core competencies"
                    icon="build"
                    title="Competencies"
                  />

                  {/* Locations */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddLocationDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search location"
                            searching={locationList.loading}
                            setValueObject={setLocationQuery}
                            setValueVariable="searchString"
                            value={locationQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={locationList.locations.map((location) => {
                            return (
                              <ItemCard
                                content=" "
                                darkColor={Colors.BLUE3}
                                height={65}
                                icon="area-of-interest"
                                interactive
                                key={location.id}
                                lightColor={Colors.BLUE3}
                                onClick={() => {
                                  dispatch(
                                    listLocationDetailsAction(location.id)
                                  );

                                  setUpdateLocationDrawerIsOpen(true);
                                }}
                                title={
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text ellipsize>{location.name}</Text>

                                    <InfoPopup
                                      content={
                                        <LocationInfo locationData={location} />
                                      }
                                    />
                                  </div>
                                }
                              />
                            );
                          })}
                          count={locationList.count}
                          loading={locationList.loading}
                          noMargin
                          page={locationQuery.page}
                          setPageObject={setLocationQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Manage locations"
                    icon="area-of-interest"
                    title="Locations"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default OrganisationDetailScreen;
