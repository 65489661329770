// Constant imports
import {
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
  ORGANISATION_LIST_FAIL,
  ORGANISATION_LIST_RESET,
  ORGANISATION_CREATE_REQUEST,
  ORGANISATION_CREATE_SUCCESS,
  ORGANISATION_CREATE_FAIL,
  ORGANISATION_CREATE_RESET,
  ORGANISATION_DETAILS_REQUEST,
  ORGANISATION_DETAILS_SUCCESS,
  ORGANISATION_DETAILS_FAIL,
  ORGANISATION_DETAILS_RESET,
  ORGANISATION_UPDATE_REQUEST,
  ORGANISATION_UPDATE_SUCCESS,
  ORGANISATION_UPDATE_FAIL,
  ORGANISATION_UPDATE_RESET,
  ORGANISATION_DELETE_REQUEST,
  ORGANISATION_DELETE_SUCCESS,
  ORGANISATION_DELETE_FAIL,
  ORGANISATION_DELETE_RESET,
  ORGANISATION_SET_ACTIVE,
  ORGANISATION_CHECK_PERMISSIONS_REQUEST,
  ORGANISATION_CHECK_PERMISSIONS_SUCCESS,
  ORGANISATION_CHECK_PERMISSIONS_FAIL,
  ORGANISATION_CHECK_PERMISSIONS_RESET,
  ORGANISATION_CHECK_SUBSCRIPTION_REQUEST,
  ORGANISATION_CHECK_SUBSCRIPTION_SUCCESS,
  ORGANISATION_CHECK_SUBSCRIPTION_FAIL,
  ORGANISATION_CHECK_SUBSCRIPTION_RESET,
} from "../constants/organisationConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listOrganisationsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOrganisations(params, token);

      dispatch({
        type: ORGANISATION_LIST_SUCCESS,

        payload: { count: data.count, organisations: data.results },
      });
    } catch (error) {
      dispatch({
        type: ORGANISATION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListOrganisationsAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_LIST_RESET });
};

// Create
export const createOrganisationAction =
  (organisation) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createOrganisation(
        token,
        organisation
      );

      dispatch({
        type: ORGANISATION_CREATE_SUCCESS,

        payload: data,
      });

      dispatch(setActiveOrganisationAction(data));
    } catch (error) {
      dispatch({
        type: ORGANISATION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateOrganisationAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_CREATE_RESET });
};

// List details
export const listOrganisationDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOrganisation(id, token);

      dispatch({
        type: ORGANISATION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORGANISATION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListOrganisationDetailsAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_DETAILS_RESET });
};

// Update
export const updateOrganisationAction =
  (id, organisation) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORGANISATION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOrganisation(
        id,
        token,
        organisation
      );

      dispatch({
        type: ORGANISATION_UPDATE_SUCCESS,

        payload: data,
      });

      dispatch(setActiveOrganisationAction(data));
    } catch (error) {
      dispatch({
        type: ORGANISATION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOrganisationAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_UPDATE_RESET });
};

// Delete
export const deleteOrganisationAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteOrganisation(id, token);

    dispatch({
      type: ORGANISATION_DELETE_SUCCESS,

      payload: data,
    });

    dispatch(setActiveOrganisationAction({}));
  } catch (error) {
    dispatch({
      type: ORGANISATION_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteOrganisationAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_DELETE_RESET });
};

// Set active
export const setActiveOrganisationAction = (organisation) => (dispatch) => {
  dispatch({
    type: ORGANISATION_SET_ACTIVE,

    payload: organisation,
  });

  localStorage.setItem(
    "activeOrganisationCurrency",
    organisation.currency_short
  );

  localStorage.setItem("activeOrganisationId", organisation.id);

  localStorage.setItem("activeOrganisationName", organisation.name);

  localStorage.setItem("activeOrganisationOwner", organisation.owner);
};

// Check permissions
export const checkPermissionsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_CHECK_PERMISSIONS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.checkPermissions(id, token);

    dispatch({
      type: ORGANISATION_CHECK_PERMISSIONS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORGANISATION_CHECK_PERMISSIONS_FAIL,

      payload: error,
    });
  }
};

// Reset check permissions
export const resetCheckPermissionsAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_CHECK_PERMISSIONS_RESET });
};

// Check subscription
export const checkSubscriptionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORGANISATION_CHECK_SUBSCRIPTION_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.checkSubscription(id, token);

    dispatch({
      type: ORGANISATION_CHECK_SUBSCRIPTION_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORGANISATION_CHECK_SUBSCRIPTION_FAIL,

      payload: error,
    });
  }
};

// Reset check subscription
export const resetCheckSubscriptionAction = () => async (dispatch) => {
  dispatch({ type: ORGANISATION_CHECK_SUBSCRIPTION_RESET });
};
