export const STAFF_COMPETENCY_LIST_REQUEST = "STAFF_COMPETENCY_LIST_REQUEST";
export const STAFF_COMPETENCY_LIST_SUCCESS = "STAFF_COMPETENCY_LIST_SUCCESS";
export const STAFF_COMPETENCY_LIST_FAIL = "STAFF_COMPETENCY_LIST_FAIL";
export const STAFF_COMPETENCY_LIST_RESET = "STAFF_COMPETENCY_LIST_RESET";

export const STAFF_COMPETENCY_CREATE_REQUEST =
  "STAFF_COMPETENCY_CREATE_REQUEST";
export const STAFF_COMPETENCY_CREATE_SUCCESS =
  "STAFF_COMPETENCY_CREATE_SUCCESS";
export const STAFF_COMPETENCY_CREATE_FAIL = "STAFF_COMPETENCY_CREATE_FAIL";
export const STAFF_COMPETENCY_CREATE_RESET = "STAFF_COMPETENCY_CREATE_RESET";

export const STAFF_COMPETENCY_DETAILS_REQUEST =
  "STAFF_COMPETENCY_DETAILS_REQUEST";
export const STAFF_COMPETENCY_DETAILS_SUCCESS =
  "STAFF_COMPETENCY_DETAILS_SUCCESS";
export const STAFF_COMPETENCY_DETAILS_FAIL = "STAFF_COMPETENCY_DETAILS_FAIL";
export const STAFF_COMPETENCY_DETAILS_RESET = "STAFF_COMPETENCY_DETAILS_RESET";

export const STAFF_COMPETENCY_UPDATE_REQUEST =
  "STAFF_COMPETENCY_UPDATE_REQUEST";
export const STAFF_COMPETENCY_UPDATE_SUCCESS =
  "STAFF_COMPETENCY_UPDATE_SUCCESS";
export const STAFF_COMPETENCY_UPDATE_FAIL = "STAFF_COMPETENCY_UPDATE_FAIL";
export const STAFF_COMPETENCY_UPDATE_RESET = "STAFF_COMPETENCY_UPDATE_RESET";

export const STAFF_COMPETENCY_DELETE_REQUEST =
  "STAFF_COMPETENCY_DELETE_REQUEST";
export const STAFF_COMPETENCY_DELETE_SUCCESS =
  "STAFF_COMPETENCY_DELETE_SUCCESS";
export const STAFF_COMPETENCY_DELETE_FAIL = "STAFF_COMPETENCY_DELETE_FAIL";
export const STAFF_COMPETENCY_DELETE_RESET = "STAFF_COMPETENCY_DELETE_RESET";

export const STAFF_COMPETENCY_UPLOAD_BEGIN = "STAFF_COMPETENCY_UPLOAD_BEGIN";
export const STAFF_COMPETENCY_UPLOAD_REQUEST =
  "STAFF_COMPETENCY_UPLOAD_REQUEST";
export const STAFF_COMPETENCY_UPLOAD_SUCCESS =
  "STAFF_COMPETENCY_UPLOAD_SUCCESS";
export const STAFF_COMPETENCY_UPLOAD_FAIL = "STAFF_COMPETENCY_UPLOAD_FAIL";
export const STAFF_COMPETENCY_UPLOAD_END = "STAFF_COMPETENCY_UPLOAD_END";
export const STAFF_COMPETENCY_UPLOAD_RESET = "STAFF_COMPETENCY_UPLOAD_RESET";
