// Library imports
import { Card, H5 } from "@blueprintjs/core";
import React from "react";

// Main function
function TabSectionChartContainer({ chart, title }) {
  // JSX UI code
  return (
    <Card
      style={{
        borderRadius: "5px",
        margin: "10px auto 0 auto",
        //maxWidth: "1280px",
        maxWidth: "1366px",
        padding: "0",
      }}
    >
      <div style={{ padding: "20px 0 0 0" }}>
        <H5 style={{ margin: "0", textAlign: "center" }}>{title}</H5>
      </div>

      <div
        style={{ padding: "20px" }}
      >
        {chart}
      </div>
    </Card>
  );
}

export default TabSectionChartContainer;
