// Library imports
import {
  Classes,
  Colors,
  Drawer,
  Position,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function DetailDrawerContainer({
  body,
  icon,
  isOpen,
  loading,
  resetDrawerVariables,
  setIsOpen,
  size,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ overflowY: "auto" }}>
      <Drawer
        className={!lightMode ? Classes.DARK : undefined}
        icon={icon}
        isOpen={isOpen}
        lazy
        onClose={() => {
          resetDrawerVariables && resetDrawerVariables();

          setIsOpen(false);
        }}
        position={Position.BOTTOM}
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          borderRadius: "5px",
          height: "fit-content",
          margin: "0 auto 0 auto",
          maxHeight: "90vh",
          width:
            size === "large"
              ? "1280px"
              : size === "medium"
              ? "990px"
              : size === "small"
              ? "480px"
              : "800px",
        }}
        title={title}
      >
        {loading ? (
          <div
            className={Classes.DRAWER_BODY}
            style={{ margin: "auto 0 auto 0" }}
          >
            <div
              className={Classes.DIALOG_BODY}
              style={{ margin: "30px 0 30px 0" }}
            >
              <Spinner size={SpinnerSize.LARGE} />
            </div>
          </div>
        ) : (
          <>
            <div className={Classes.DRAWER_BODY}>
              <div className={Classes.DIALOG_BODY}>{body}</div>
            </div>
          </>
        )}
      </Drawer>
    </div>
  );
}

export default DetailDrawerContainer;
