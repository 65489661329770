// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function ClientProposalTotalValueCard({
  fill,
  clientList,
  clientQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Proposal value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total value of proposals closed with the selected client(s). These
            are proposals that have been won, lost, or cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {Math.max(
                  clientList.totalValueOfProposalsCancelled,
                  clientList.totalValueOfProposalsLost,
                  clientList.totalValueOfProposalsWon
                ) === clientList.totalValueOfProposalsWon ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm has a proper focus on opportunities with the
                      selected client(s). Continue to invest resources into
                      developing these accounts.
                    </div>
                  </div>
                ) : Math.max(
                    clientList.totalValueOfProposalsCancelled,
                    clientList.totalValueOfProposalsLost,
                    clientList.totalValueOfProposalsWon
                  ) === clientList.totalValueOfProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is investing resources into proposals that
                      ultimately do not materialize with the selected client(s).
                      Implementing a more rigorous qualification process may
                      prove beneficial in identifying and pursuing opportunities
                      with high-value clients.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is expending considerable resources on proposals
                      that are ultimately unsuccessful with the selected
                      client(s). Conducting a review of lessons learned may
                      reveal patterns that can be used to identify potential
                      disqualifiers early in the process and avoid investing in
                      accounts that are unlikely to pay off.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: clientList.totalValueOfProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: clientList.totalValueOfProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: clientList.totalValueOfProposalsCancelled,
        },
      ]}
      fill={fill}
      filter={clientQuery ? clientQuery.status : undefined}
      noData={
        !clientList.totalValueOfProposalsCancelled &&
        !clientList.totalValueOfProposalsLost &&
        !clientList.totalValueOfProposalsWon
      }
      title="Proposal value"
      units={activeOrganisation.currency}
    />
  );
}

export default ClientProposalTotalValueCard;
