export const NEED_QUESTION_LIST_REQUEST = "NEED_QUESTION_LIST_REQUEST";
export const NEED_QUESTION_LIST_SUCCESS = "NEED_QUESTION_LIST_SUCCESS";
export const NEED_QUESTION_LIST_FAIL = "NEED_QUESTION_LIST_FAIL";
export const NEED_QUESTION_LIST_RESET = "NEED_QUESTION_LIST_RESET";

export const NEED_QUESTION_CREATE_REQUEST = "NEED_QUESTION_CREATE_REQUEST";
export const NEED_QUESTION_CREATE_SUCCESS = "NEED_QUESTION_CREATE_SUCCESS";
export const NEED_QUESTION_CREATE_FAIL = "NEED_QUESTION_CREATE_FAIL";
export const NEED_QUESTION_CREATE_RESET = "NEED_QUESTION_CREATE_RESET";

export const NEED_QUESTION_DETAILS_REQUEST = "NEED_QUESTION_DETAILS_REQUEST";
export const NEED_QUESTION_DETAILS_SUCCESS = "NEED_QUESTION_DETAILS_SUCCESS";
export const NEED_QUESTION_DETAILS_FAIL = "NEED_QUESTION_DETAILS_FAIL";
export const NEED_QUESTION_DETAILS_RESET = "NEED_QUESTION_DETAILS_RESET";

export const NEED_QUESTION_UPDATE_REQUEST = "NEED_QUESTION_UPDATE_REQUEST";
export const NEED_QUESTION_UPDATE_SUCCESS = "NEED_QUESTION_UPDATE_SUCCESS";
export const NEED_QUESTION_UPDATE_FAIL = "NEED_QUESTION_UPDATE_FAIL";
export const NEED_QUESTION_UPDATE_RESET = "NEED_QUESTION_UPDATE_RESET";

export const NEED_QUESTION_DELETE_REQUEST = "NEED_QUESTION_DELETE_REQUEST";
export const NEED_QUESTION_DELETE_SUCCESS = "NEED_QUESTION_DELETE_SUCCESS";
export const NEED_QUESTION_DELETE_FAIL = "NEED_QUESTION_DELETE_FAIL";
export const NEED_QUESTION_DELETE_RESET = "NEED_QUESTION_DELETE_RESET";

export const NEED_QUESTION_UPLOAD_BEGIN = "NEED_QUESTION_UPLOAD_BEGIN";
export const NEED_QUESTION_UPLOAD_REQUEST = "NEED_QUESTION_UPLOAD_REQUEST";
export const NEED_QUESTION_UPLOAD_SUCCESS = "NEED_QUESTION_UPLOAD_SUCCESS";
export const NEED_QUESTION_UPLOAD_FAIL = "NEED_QUESTION_UPLOAD_FAIL";
export const NEED_QUESTION_UPLOAD_END = "NEED_QUESTION_UPLOAD_END";
export const NEED_QUESTION_UPLOAD_RESET = "NEED_QUESTION_UPLOAD_RESET";
