// Constant imports
import {
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_LIST_RESET,
  LOCATION_CREATE_REQUEST,
  LOCATION_CREATE_SUCCESS,
  LOCATION_CREATE_FAIL,
  LOCATION_CREATE_RESET,
  LOCATION_DETAILS_REQUEST,
  LOCATION_DETAILS_SUCCESS,
  LOCATION_DETAILS_FAIL,
  LOCATION_DETAILS_RESET,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_FAIL,
  LOCATION_UPDATE_RESET,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DELETE_FAIL,
  LOCATION_DELETE_RESET,
} from "../constants/locationConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listLocationsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getLocations(params, token);

    dispatch({
      type: LOCATION_LIST_SUCCESS,

      payload: {
        count: data.count,
        locations: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: LOCATION_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListLocationsAction = () => async (dispatch) => {
  dispatch({ type: LOCATION_LIST_RESET });
};

// Create
export const createLocationAction =
  (location) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOCATION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createLocation(token, location);

      dispatch({
        type: LOCATION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LOCATION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateLocationAction = () => async (dispatch) => {
  dispatch({ type: LOCATION_CREATE_RESET });
};

// List details
export const listLocationDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getLocation(id, token);

    dispatch({
      type: LOCATION_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListLocationDetailsAction = () => async (dispatch) => {
  dispatch({ type: LOCATION_DETAILS_RESET });
};

// Update
export const updateLocationAction =
  (id, location) => async (dispatch, getState) => {
    try {
      dispatch({ type: LOCATION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateLocation(
        id,
        token,
        location
      );

      dispatch({
        type: LOCATION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LOCATION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateLocationAction = () => async (dispatch) => {
  dispatch({ type: LOCATION_UPDATE_RESET });
};

// Delete
export const deleteLocationAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOCATION_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteLocation(id, token);

    dispatch({
      type: LOCATION_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LOCATION_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteLocationAction = () => async (dispatch) => {
  dispatch({ type: LOCATION_DELETE_RESET });
};
