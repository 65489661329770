// Constant imports
import {
  SEAT_LIST_REQUEST,
  SEAT_LIST_SUCCESS,
  SEAT_LIST_FAIL,
  SEAT_LIST_RESET,
  SEAT_DETAILS_REQUEST,
  SEAT_DETAILS_SUCCESS,
  SEAT_DETAILS_FAIL,
  SEAT_DETAILS_RESET,
  SEAT_UPDATE_REQUEST,
  SEAT_UPDATE_SUCCESS,
  SEAT_UPDATE_FAIL,
  SEAT_UPDATE_RESET,
} from "../constants/seatConstants";

// List all
export const seatListReducer = (
  state = {
    loading: false,
    count: 0,
    seats: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SEAT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEAT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        seats: action.payload.seats,
        success: true,
        error: "",
      };

    case SEAT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        seats: [],
        success: false,
        error: action.payload,
      };

    case SEAT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        seats: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const seatDetailsReducer = (
  state = { loading: false, seat: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SEAT_DETAILS_REQUEST:
      return {
        loading: true,
        seat: state.seat,
        success: false,
        error: "",
      };

    case SEAT_DETAILS_SUCCESS:
      return {
        loading: false,
        seat: action.payload,
        success: true,
        error: "",
      };

    case SEAT_DETAILS_FAIL:
      return {
        loading: false,
        seat: state.seat,
        success: false,
        error: action.payload,
      };

    case SEAT_DETAILS_RESET:
      return { loading: false, seat: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const seatUpdateReducer = (
  state = { loading: false, seat: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SEAT_UPDATE_REQUEST:
      return {
        loading: true,
        seat: state.seat,
        success: false,
        error: "",
      };

    case SEAT_UPDATE_SUCCESS:
      return {
        loading: false,
        seat: action.payload,
        success: true,
        error: "",
      };

    case SEAT_UPDATE_FAIL:
      return {
        loading: false,
        seat: state.seat,
        success: false,
        error: action.payload,
      };

    case SEAT_UPDATE_RESET:
      return { loading: false, seat: {}, success: false, error: "" };

    default:
      return state;
  }
};
