// Library imports
import { NonIdealState, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";

// Component imports
import InfoPopup from "./InfoPopup";
import ItemCard from "./ItemCard";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Main function
function DataCardDonutChart({
  comments,
  darkColor,
  data,
  fill,
  filter,
  interactive,
  lightColor,
  noData,
  onClick,
  showPercent,
  title,
  units,
  width,
}) {
  // Render active shape
  const [activeIndex, setActiveIndex] = useState(0);

  const renderActiveShape = (props) => {
    const {
      cx,
      cy,
      endAngle,
      fill,
      innerRadius,
      outerRadius,
      payload,
      percent,
      startAngle,
      value,
    } = props;

    return (
      <g>
        <text
          dy={4}
          fill={payload.payload.fill}
          fontSize={24}
          fontWeight="bold"
          textAnchor="middle"
          x={cx}
          y={cy}
        >
          {showPercent
            ? `${(percent * 100).toFixed(0)}%`
            : value
            ? numberFormatter(value)
            : "-"}
        </text>

        <text
          dy={24}
          fill={payload.payload.fill}
          textAnchor="middle"
          x={cx}
          y={cy}
        >
          {payload.name}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          endAngle={endAngle}
          fill={fill}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
        />

        <Sector
          cx={cx}
          cy={cy}
          endAngle={endAngle}
          fill={fill}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          startAngle={startAngle}
        />
      </g>
    );
  };

  // Set active category to filter value
  useEffect(() => {
    if (filter) {
      for (var i = 0; i < data.length; i++) {
        data[i].name === filter && setActiveIndex(i);
      }
    } else {
      for (var j = 0; j < data.length; j++) {
        if (data[j].value) {
          setActiveIndex(j);

          break;
        }
      }
    }
  }, [data, filter]);

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div style={{ overflowY: "hidden" }}>
              <ResponsiveContainer height={200} width="100%">
                <PieChart margin={{ top: 10 }}>
                  <defs>
                    {data.map((item, i) => {
                      return (
                        <linearGradient
                          id={`color${item.fill}`}
                          key={i}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={item.fill}
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor={item.fill}
                            stopOpacity={0.7}
                          />
                        </linearGradient>
                      );
                    })}
                  </defs>

                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    dataKey="value"
                    innerRadius={65}
                    onMouseEnter={(_, index) => {
                      setActiveIndex(index);
                    }}
                    outerRadius={85}
                  >
                    {data.map((entry, index) => (
                      <Cell
                        fill={`url(#color${entry.fill})`}
                        key={`cell-${index}`}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState icon={"search"} title={"No data"} />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={title}
      width={width}
    />
  );
}

export default DataCardDonutChart;
