// Library imports
import { Colors } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function PercentageValue({ invertColor, percentage }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        color:
          percentage < 0
            ? lightMode
              ? invertColor
                ? Colors.RED5
                : Colors.RED1
              : invertColor
              ? Colors.RED1
              : Colors.RED5
            : undefined,
      }}
    >
      {percentage
        ? percentage < 0
          ? "(" +
            Math.abs(percentage).toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) +
            ")%"
          : percentage.toLocaleString("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) + "%"
        : "-"}
    </div>
  );
}

export default PercentageValue;
