// Constant imports
import {
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_RESET,
} from "../constants/engagementServiceDeliverableConstants";

// List all
export const engagementServiceDeliverableListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_service_deliverables: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_DELIVERABLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_service_deliverables:
          action.payload.engagement_service_deliverables,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_service_deliverables: [],
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_service_deliverables: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementServiceDeliverableCreateReducer = (
  state = {
    loading: false,
    engagement_service_deliverable: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_REQUEST:
      return {
        loading: true,
        engagement_service_deliverable: state.engagement_service_deliverable,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_service_deliverable: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_FAIL:
      return {
        loading: false,
        engagement_service_deliverable: state.engagement_service_deliverable,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_RESET:
      return {
        loading: false,
        engagement_service_deliverable: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementServiceDeliverableDetailsReducer = (
  state = {
    loading: false,
    engagement_service_deliverable: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_service_deliverable: state.engagement_service_deliverable,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_service_deliverable: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_FAIL:
      return {
        loading: false,
        engagement_service_deliverable: state.engagement_service_deliverable,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_RESET:
      return {
        loading: false,
        engagement_service_deliverable: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementServiceDeliverableUpdateReducer = (
  state = {
    loading: false,
    engagement_service_deliverable: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_service_deliverable: state.engagement_service_deliverable,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_service_deliverable: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_FAIL:
      return {
        loading: false,
        engagement_service_deliverable: state.engagement_service_deliverable,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_RESET:
      return {
        loading: false,
        engagement_service_deliverable: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementServiceDeliverableDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
