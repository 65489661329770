// Constant imports
import {
  CLAIM_LIST_REQUEST,
  CLAIM_LIST_SUCCESS,
  CLAIM_LIST_FAIL,
  CLAIM_LIST_RESET,
  CLAIM_CREATE_REQUEST,
  CLAIM_CREATE_SUCCESS,
  CLAIM_CREATE_FAIL,
  CLAIM_CREATE_RESET,
  CLAIM_DETAILS_REQUEST,
  CLAIM_DETAILS_SUCCESS,
  CLAIM_DETAILS_FAIL,
  CLAIM_DETAILS_RESET,
  CLAIM_UPDATE_REQUEST,
  CLAIM_UPDATE_SUCCESS,
  CLAIM_UPDATE_FAIL,
  CLAIM_UPDATE_RESET,
  CLAIM_DELETE_REQUEST,
  CLAIM_DELETE_SUCCESS,
  CLAIM_DELETE_FAIL,
  CLAIM_DELETE_RESET,
  CLAIM_DUPLICATE_REQUEST,
  CLAIM_DUPLICATE_SUCCESS,
  CLAIM_DUPLICATE_FAIL,
  CLAIM_DUPLICATE_RESET,
  CLAIM_REVERSE_REQUEST,
  CLAIM_REVERSE_SUCCESS,
  CLAIM_REVERSE_FAIL,
  CLAIM_REVERSE_RESET,
} from "../constants/claimConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClaimsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLAIM_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClaims(params, token);

    dispatch({
      type: CLAIM_LIST_SUCCESS,

      payload: {
        valueOfClaimsApproved: data.value_of_claims_approved.result,
        valueOfClaimsDraft: data.value_of_claims_draft.result,
        valueOfClaimsPaid: data.value_of_claims_paid.result,
        valueOfClaimsRejected: data.value_of_claims_rejected.result,
        valueOfClaimsSubmitted: data.value_of_claims_submitted.result,
        count: data.count,
        claims: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: CLAIM_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListClaimsAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_LIST_RESET });
};

// Create
export const createClaimAction = (claim) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLAIM_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createClaim(token, claim);

    dispatch({
      type: CLAIM_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLAIM_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateClaimAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_CREATE_RESET });
};

// List details
export const listClaimDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLAIM_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClaim(id, token);

    dispatch({
      type: CLAIM_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLAIM_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListClaimDetailsAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_DETAILS_RESET });
};

// Update
export const updateClaimAction =
  (id, approvalRequest, claim) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLAIM_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateClaim(id, token, claim);

      if (approvalRequest) {
        if (approvalRequest.get("approver")) {
          await cortixDataService.createApprovalRequest(token, approvalRequest);
        }
      }

      dispatch({
        type: CLAIM_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLAIM_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClaimAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_UPDATE_RESET });
};

// Delete
export const deleteClaimAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLAIM_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteClaim(id, token);

    dispatch({
      type: CLAIM_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLAIM_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteClaimAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_DELETE_RESET });
};

// Duplicate
export const duplicateClaimAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLAIM_DUPLICATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.duplicateClaim(id, token);

    dispatch({
      type: CLAIM_DUPLICATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLAIM_DUPLICATE_FAIL,

      payload: error,
    });
  }
};

// Reset duplicate
export const resetDuplicateClaimAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_DUPLICATE_RESET });
};

// Reverse
export const reverseClaimAction =
  (id, reversal) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLAIM_REVERSE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.reverseClaim(
        id,
        token,
        reversal
      );

      dispatch({
        type: CLAIM_REVERSE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLAIM_REVERSE_FAIL,

        payload: error,
      });
    }
  };

// Reset reverse
export const resetReverseClaimAction = () => async (dispatch) => {
  dispatch({ type: CLAIM_REVERSE_RESET });
};
