// Constants import
import { PAGE_SIZE } from "../constants/applicationConstants";

// Library imports
import { Button, ButtonGroup, Classes, NonIdealState } from "@blueprintjs/core";
import React from "react";

// Main function
function TabSectionFieldMultifieldCardList({
  content,
  count,
  loading,
  noResultsIcon,
  noResultsMessage,
  noResultsTitle,
  page,
  setPageObject,
  setPageVariable,
}) {
  // JSX UI code
  return (
    <div>
      {/* Content */}
      {count !== 0 && content}

      {/* Buttons */}
      {count !== 0 && content && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: "10px",
          }}
        >
          <ButtonGroup minimal>
            {/* Last page */}
            <Button
              disabled={page === 1 ? true : false}
              onClick={() => {
                setPageObject((prevState) => {
                  return { ...prevState, [setPageVariable]: page - 1 };
                });
              }}
            >
              {<small>Last page</small>}
            </Button>

            {/* Next page */}
            <Button
              disabled={page * PAGE_SIZE < Number(count) ? false : true}
              onClick={() => {
                setPageObject((prevState) => {
                  return { ...prevState, [setPageVariable]: page + 1 };
                });
              }}
            >
              {<small>Next page</small>}
            </Button>
          </ButtonGroup>
        </div>
      )}

      {/* Loading */}
      {count === 0 && loading && (
        <div className={Classes.SKELETON} style={{ width: "100%" }}>
          XXXXXXXXXX
        </div>
      )}

      {/* No results */}
      {count === 0 && !loading && (
        <div style={{ margin: "60px 0 60px 0" }}>
          <NonIdealState
            description={noResultsMessage ? noResultsMessage : "Add records."}
            icon={noResultsIcon ? noResultsIcon : "search"}
            title={noResultsTitle ? noResultsTitle : "No records found"}
          />
        </div>
      )}
    </div>
  );
}

export default TabSectionFieldMultifieldCardList;
