export const ENGAGEMENT_LIST_REQUEST = "ENGAGEMENT_LIST_REQUEST";
export const ENGAGEMENT_LIST_SUCCESS = "ENGAGEMENT_LIST_SUCCESS";
export const ENGAGEMENT_LIST_FAIL = "ENGAGEMENT_LIST_FAIL";
export const ENGAGEMENT_LIST_RESET = "ENGAGEMENT_LIST_RESET";

export const ENGAGEMENT_CREATE_REQUEST = "ENGAGEMENT_CREATE_REQUEST";
export const ENGAGEMENT_CREATE_SUCCESS = "ENGAGEMENT_CREATE_SUCCESS";
export const ENGAGEMENT_CREATE_FAIL = "ENGAGEMENT_CREATE_FAIL";
export const ENGAGEMENT_CREATE_RESET = "ENGAGEMENT_CREATE_RESET";

export const ENGAGEMENT_DETAILS_REQUEST = "ENGAGEMENT_DETAILS_REQUEST";
export const ENGAGEMENT_DETAILS_SUCCESS = "ENGAGEMENT_DETAILS_SUCCESS";
export const ENGAGEMENT_DETAILS_FAIL = "ENGAGEMENT_DETAILS_FAIL";
export const ENGAGEMENT_DETAILS_RESET = "ENGAGEMENT_DETAILS_RESET";

export const ENGAGEMENT_UPDATE_REQUEST = "ENGAGEMENT_UPDATE_REQUEST";
export const ENGAGEMENT_UPDATE_SUCCESS = "ENGAGEMENT_UPDATE_SUCCESS";
export const ENGAGEMENT_UPDATE_FAIL = "ENGAGEMENT_UPDATE_FAIL";
export const ENGAGEMENT_UPDATE_RESET = "ENGAGEMENT_UPDATE_RESET";

export const ENGAGEMENT_DELETE_REQUEST = "ENGAGEMENT_DELETE_REQUEST";
export const ENGAGEMENT_DELETE_SUCCESS = "ENGAGEMENT_DELETE_SUCCESS";
export const ENGAGEMENT_DELETE_FAIL = "ENGAGEMENT_DELETE_FAIL";
export const ENGAGEMENT_DELETE_RESET = "ENGAGEMENT_DELETE_RESET";

export const ENGAGEMENT_GENERATE_REPORT_DATA_REQUEST =
  "ENGAGEMENT_GENERATE_REPORT_DATA_REQUEST";
export const ENGAGEMENT_GENERATE_REPORT_DATA_SUCCESS =
  "ENGAGEMENT_GENERATE_REPORT_DATA_SUCCESS";
export const ENGAGEMENT_GENERATE_REPORT_DATA_FAIL =
  "ENGAGEMENT_GENERATE_REPORT_DATA_FAIL";
export const ENGAGEMENT_GENERATE_REPORT_DATA_RESET =
  "ENGAGEMENT_GENERATE_REPORT_DATA_RESET";

export const ENGAGEMENT_DOWNLOAD_REQUEST = "ENGAGEMENT_DOWNLOAD_REQUEST";
export const ENGAGEMENT_DOWNLOAD_SUCCESS = "ENGAGEMENT_DOWNLOAD_SUCCESS";
export const ENGAGEMENT_DOWNLOAD_FAIL = "ENGAGEMENT_DOWNLOAD_FAIL";
export const ENGAGEMENT_DOWNLOAD_RESET = "ENGAGEMENT_DOWNLOAD_RESET";
