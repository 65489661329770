// Constant imports
// import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  COMPETENCY_LIST_REQUEST,
  COMPETENCY_LIST_SUCCESS,
  COMPETENCY_LIST_FAIL,
  COMPETENCY_LIST_RESET,
  COMPETENCY_CREATE_REQUEST,
  COMPETENCY_CREATE_SUCCESS,
  COMPETENCY_CREATE_FAIL,
  COMPETENCY_CREATE_RESET,
  COMPETENCY_DETAILS_REQUEST,
  COMPETENCY_DETAILS_SUCCESS,
  COMPETENCY_DETAILS_FAIL,
  COMPETENCY_DETAILS_RESET,
  COMPETENCY_UPDATE_REQUEST,
  COMPETENCY_UPDATE_SUCCESS,
  COMPETENCY_UPDATE_FAIL,
  COMPETENCY_UPDATE_RESET,
  COMPETENCY_DELETE_REQUEST,
  COMPETENCY_DELETE_SUCCESS,
  COMPETENCY_DELETE_FAIL,
  COMPETENCY_DELETE_RESET,
  COMPETENCY_DOWNLOAD_REQUEST,
  COMPETENCY_DOWNLOAD_SUCCESS,
  COMPETENCY_DOWNLOAD_FAIL,
  COMPETENCY_DOWNLOAD_RESET,
  COMPETENCY_UPLOAD_BEGIN,
  COMPETENCY_UPLOAD_REQUEST,
  COMPETENCY_UPLOAD_SUCCESS,
  COMPETENCY_UPLOAD_FAIL,
  COMPETENCY_UPLOAD_END,
  COMPETENCY_UPLOAD_RESET,
} from "../constants/competencyConstants";

// Data services import
import cortixDataService from "../services/cortix";

const PAGE_SIZE = 50; // Competencies has a custom page size of 50

// List all
export const listCompetenciesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPETENCY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCompetencies(params, token);

      dispatch({
        type: COMPETENCY_LIST_SUCCESS,

        payload: { count: data.count, competencies: data.results },
      });
    } catch (error) {
      dispatch({
        type: COMPETENCY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListCompetenciesAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_LIST_RESET });
};

// Create
export const createCompetencyAction =
  (competency) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPETENCY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createCompetency(
        token,
        competency
      );

      dispatch({
        type: COMPETENCY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPETENCY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateCompetencyAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_CREATE_RESET });
};

// List details
export const listCompetencyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPETENCY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCompetency(id, token);

      dispatch({
        type: COMPETENCY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPETENCY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListCompetencyDetailsAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_DETAILS_RESET });
};

// Update
export const updateCompetencyAction =
  (id, competency) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPETENCY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateCompetency(
        id,
        token,
        competency
      );

      dispatch({
        type: COMPETENCY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPETENCY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateCompetencyAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_UPDATE_RESET });
};

// Delete
export const deleteCompetencyAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: COMPETENCY_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteCompetency(id, token);

    dispatch({
      type: COMPETENCY_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COMPETENCY_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteCompetencyAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_DELETE_RESET });
};

// Download
export const downloadCompetenciesAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPETENCY_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var competencies = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getCompetencies(
          { organisation: organisation, page: page },
          token
        );

        competencies = competencies.concat(data.results);
      }

      dispatch({
        type: COMPETENCY_DOWNLOAD_SUCCESS,

        payload: {
          competencies: competencies,
        },
      });
    } catch (error) {
      dispatch({
        type: COMPETENCY_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadCompetenciesAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_DOWNLOAD_RESET });
};

// Upload
export const uploadCompetenciesAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: COMPETENCY_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: COMPETENCY_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteCompetency(
            records[recordNum].id,
            token
          );
        } else if (records[recordNum].id) {
          await cortixDataService.updateCompetency(
            records[recordNum].id,
            token,
            {
              description: records[recordNum].description,
              name: records[recordNum].name,
              organisation: id,
            }
          );
        } else {
          await cortixDataService.createCompetency(token, {
            description: records[recordNum].description,
            name: records[recordNum].name,
            organisation: id,
          });
        }

        dispatch({
          type: COMPETENCY_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: COMPETENCY_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: COMPETENCY_UPLOAD_END });
  };

// Reset upload
export const resetUploadCompetenciesAction = () => async (dispatch) => {
  dispatch({ type: COMPETENCY_UPLOAD_RESET });
};
