// Constant imports
import {
  CLIENT_PROGRAM_LIST_REQUEST,
  CLIENT_PROGRAM_LIST_SUCCESS,
  CLIENT_PROGRAM_LIST_FAIL,
  CLIENT_PROGRAM_LIST_RESET,
  CLIENT_PROGRAM_CREATE_REQUEST,
  CLIENT_PROGRAM_CREATE_SUCCESS,
  CLIENT_PROGRAM_CREATE_FAIL,
  CLIENT_PROGRAM_CREATE_RESET,
  CLIENT_PROGRAM_DETAILS_REQUEST,
  CLIENT_PROGRAM_DETAILS_SUCCESS,
  CLIENT_PROGRAM_DETAILS_FAIL,
  CLIENT_PROGRAM_DETAILS_RESET,
  CLIENT_PROGRAM_UPDATE_REQUEST,
  CLIENT_PROGRAM_UPDATE_SUCCESS,
  CLIENT_PROGRAM_UPDATE_FAIL,
  CLIENT_PROGRAM_UPDATE_RESET,
  CLIENT_PROGRAM_DELETE_REQUEST,
  CLIENT_PROGRAM_DELETE_SUCCESS,
  CLIENT_PROGRAM_DELETE_FAIL,
  CLIENT_PROGRAM_DELETE_RESET,
} from "../constants/clientProgramConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientProgramsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PROGRAM_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientPrograms(params, token);

      dispatch({
        type: CLIENT_PROGRAM_LIST_SUCCESS,

        payload: {
          count: data.count,
          expected_revenue: data.expected_revenue.result,
          client_programs: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PROGRAM_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListClientProgramsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PROGRAM_LIST_RESET });
};

// Create
export const createClientProgramAction =
  (sponsor, client_program) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PROGRAM_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (sponsor) {
        if (sponsor.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            sponsor
          );

          client_program.append("sponsor", data.id);
        }
      }

      const { data } = await cortixDataService.createClientProgram(
        token,
        client_program
      );

      dispatch({
        type: CLIENT_PROGRAM_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PROGRAM_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateClientProgramAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PROGRAM_CREATE_RESET });
};

// List details
export const listClientProgramDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PROGRAM_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientProgram(id, token);

      dispatch({
        type: CLIENT_PROGRAM_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PROGRAM_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListClientProgramDetailsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PROGRAM_DETAILS_RESET });
};

// Update
export const updateClientProgramAction =
  (id, sponsor, client_program) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PROGRAM_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (sponsor) {
        if (sponsor.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            sponsor
          );

          client_program.append("sponsor", data.id);
        }
      }

      const { data } = await cortixDataService.updateClientProgram(
        id,
        token,
        client_program
      );

      dispatch({
        type: CLIENT_PROGRAM_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PROGRAM_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientProgramAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PROGRAM_UPDATE_RESET });
};

// Delete
export const deleteClientProgramAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_PROGRAM_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteClientProgram(id, token);

    dispatch({
      type: CLIENT_PROGRAM_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_PROGRAM_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteClientProgramAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PROGRAM_DELETE_RESET });
};
