// Constant imports
import {
  MARKET_INSIGHT_LIST_REQUEST,
  MARKET_INSIGHT_LIST_SUCCESS,
  MARKET_INSIGHT_LIST_FAIL,
  MARKET_INSIGHT_LIST_RESET,
  MARKET_INSIGHT_CREATE_REQUEST,
  MARKET_INSIGHT_CREATE_SUCCESS,
  MARKET_INSIGHT_CREATE_FAIL,
  MARKET_INSIGHT_CREATE_RESET,
  MARKET_INSIGHT_DETAILS_REQUEST,
  MARKET_INSIGHT_DETAILS_SUCCESS,
  MARKET_INSIGHT_DETAILS_FAIL,
  MARKET_INSIGHT_DETAILS_RESET,
  MARKET_INSIGHT_UPDATE_REQUEST,
  MARKET_INSIGHT_UPDATE_SUCCESS,
  MARKET_INSIGHT_UPDATE_FAIL,
  MARKET_INSIGHT_UPDATE_RESET,
  MARKET_INSIGHT_DELETE_REQUEST,
  MARKET_INSIGHT_DELETE_SUCCESS,
  MARKET_INSIGHT_DELETE_FAIL,
  MARKET_INSIGHT_DELETE_RESET,
} from "../constants/marketInsightConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listMarketInsightsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_INSIGHT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketInsights(params, token);

      dispatch({
        type: MARKET_INSIGHT_LIST_SUCCESS,

        payload: {
          count: data.count,
          market_insights: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: MARKET_INSIGHT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListMarketInsightsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_INSIGHT_LIST_RESET });
};

// Create
export const createMarketInsightAction =
  (market_insight) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_INSIGHT_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createMarketInsight(
        token,
        market_insight
      );

      dispatch({
        type: MARKET_INSIGHT_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_INSIGHT_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateMarketInsightAction = () => async (dispatch) => {
  dispatch({ type: MARKET_INSIGHT_CREATE_RESET });
};

// List details
export const listMarketInsightDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_INSIGHT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketInsight(id, token);

      dispatch({
        type: MARKET_INSIGHT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_INSIGHT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListMarketInsightDetailsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_INSIGHT_DETAILS_RESET });
};

// Update
export const updateMarketInsightAction =
  (id, market_insight) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_INSIGHT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateMarketInsight(
        id,
        token,
        market_insight
      );

      dispatch({
        type: MARKET_INSIGHT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_INSIGHT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateMarketInsightAction = () => async (dispatch) => {
  dispatch({ type: MARKET_INSIGHT_UPDATE_RESET });
};

// Delete
export const deleteMarketInsightAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_INSIGHT_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteMarketInsight(id, token);

    dispatch({
      type: MARKET_INSIGHT_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKET_INSIGHT_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteMarketInsightAction = () => async (dispatch) => {
  dispatch({ type: MARKET_INSIGHT_DELETE_RESET });
};
