// Constant imports
import {
  ENGAGEMENT_FEE_LIST_REQUEST,
  ENGAGEMENT_FEE_LIST_SUCCESS,
  ENGAGEMENT_FEE_LIST_FAIL,
  ENGAGEMENT_FEE_LIST_RESET,
  ENGAGEMENT_FEE_DETAILS_REQUEST,
  ENGAGEMENT_FEE_DETAILS_SUCCESS,
  ENGAGEMENT_FEE_DETAILS_FAIL,
  ENGAGEMENT_FEE_DETAILS_RESET,
  ENGAGEMENT_FEE_UPDATE_REQUEST,
  ENGAGEMENT_FEE_UPDATE_SUCCESS,
  ENGAGEMENT_FEE_UPDATE_FAIL,
  ENGAGEMENT_FEE_UPDATE_RESET,
} from "../constants/engagementFeeConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementFeesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_FEE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementFees(params, token);

      dispatch({
        type: ENGAGEMENT_FEE_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_fees: data.results,
          averageMarginPercentage: data.average_margin_percentage.result,
          totalExpenses: data.total_expenses.result,
          totalFees: data.total_fees.result,
          totalFeesQuote: data.total_fees_quote.result,
          totalMargin: data.total_margin.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_FEE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementFeesAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_FEE_LIST_RESET });
};

// List details
export const listEngagementFeeDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_FEE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementFee(id, token);

      dispatch({
        type: ENGAGEMENT_FEE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_FEE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementFeeDetailsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_FEE_DETAILS_RESET });
};

// Update
export const updateEngagementFeeAction =
  (id, engagement_fee) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_FEE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementFee(
        id,
        token,
        engagement_fee
      );

      dispatch({
        type: ENGAGEMENT_FEE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_FEE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementFeeAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_FEE_UPDATE_RESET });
};
