// Constant imports
import {
  HUBSPOT_AUTHORISE_REQUEST,
  HUBSPOT_AUTHORISE_SUCCESS,
  HUBSPOT_AUTHORISE_FAIL,
  HUBSPOT_AUTHORISE_RESET,
  HUBSPOT_DEAUTHORISE_SUCCESS,
  HUBSPOT_DEAUTHORISE_REQUEST,
  HUBSPOT_DEAUTHORISE_FAIL,
  HUBSPOT_DEAUTHORISE_RESET,
  HUBSPOT_GET_CONTACT_REQUEST,
  HUBSPOT_GET_CONTACT_SUCCESS,
  HUBSPOT_GET_CONTACT_FAIL,
  HUBSPOT_GET_CONTACT_RESET,
  HUBSPOT_SYNC_CONTACTS_REQUEST,
  HUBSPOT_SYNC_CONTACTS_SUCCESS,
  HUBSPOT_SYNC_CONTACTS_FAIL,
  HUBSPOT_SYNC_CONTACTS_RESET,
} from "../constants/hubspotConstants";

// Data services import
import cortixDataService from "../services/cortix";

// Authorise
export const hubspotAuthoriseAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: HUBSPOT_AUTHORISE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.hubspotAuthorise(id, token);

    dispatch({
      type: HUBSPOT_AUTHORISE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HUBSPOT_AUTHORISE_FAIL,

      payload: error,
    });
  }
};

// Reset authorise
export const hubspotResetAuthoriseAction = () => async (dispatch) => {
  dispatch({ type: HUBSPOT_AUTHORISE_RESET });
};

// Deauthorise
export const hubspotDeauthoriseAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: HUBSPOT_DEAUTHORISE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.hubspotDeauthorise(id, token);

    dispatch({
      type: HUBSPOT_DEAUTHORISE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HUBSPOT_DEAUTHORISE_FAIL,

      payload: error,
    });
  }
};

// Reset authorise
export const hubspotResetDeauthoriseAction = () => async (dispatch) => {
  dispatch({ type: HUBSPOT_DEAUTHORISE_RESET });
};

// Get contact
export const hubspotGetContactAction =
  (id, email) => async (dispatch, getState) => {
    try {
      dispatch({ type: HUBSPOT_GET_CONTACT_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.hubspotGetContact(
        id,
        token,
        email
      );

      dispatch({
        type: HUBSPOT_GET_CONTACT_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: HUBSPOT_GET_CONTACT_FAIL,

        payload: error,
      });
    }
  };

// Reset get contact
export const hubspotResetGetContactAction = () => async (dispatch) => {
  dispatch({ type: HUBSPOT_GET_CONTACT_RESET });
};

// Sync contacts
export const hubspotSyncContactsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: HUBSPOT_SYNC_CONTACTS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.hubspotSyncContacts(id, token);

    dispatch({
      type: HUBSPOT_SYNC_CONTACTS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HUBSPOT_SYNC_CONTACTS_FAIL,

      payload: error,
    });
  }
};

// Reset sync contacts
export const hubspotResetSyncContactsAction = () => async (dispatch) => {
  dispatch({ type: HUBSPOT_SYNC_CONTACTS_RESET });
};
