// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import CompetencyInfo from "../components/CompetencyInfo";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import GenerateButton from "../components/GenerateButton";
import HelpDialog from "../components/HelpDialog";
import InfoPopup from "../components/InfoPopup";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import PermissionCard from "../components/PermissionCard";
import RankInfo from "../components/RankInfo";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";

// Redux action imports
// Competency
import { listCompetenciesAction } from "../actions/competencyActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Rank
import { listRanksAction } from "../actions/rankActions";

// Role
import {
  deleteRoleAction,
  listRoleDetailsAction,
  resetDeleteRoleAction,
  resetListRolesAction,
  resetUpdateRoleAction,
  updateRoleAction,
} from "../actions/roleActions";

// Role competency
import {
  createRoleCompetencyAction,
  deleteRoleCompetencyAction,
  listRoleCompetenciesAction,
  listRoleCompetencyDetailsAction,
  resetCreateRoleCompetencyAction,
  resetDeleteRoleCompetencyAction,
  resetUpdateRoleCompetencyAction,
  updateRoleCompetencyAction,
} from "../actions/roleCompetencyActions";

// Role permission
import {
  createRolePermissionAction,
  deleteRolePermissionAction,
  listRolePermissionsAction,
  listRolePermissionDetailsAction,
  resetCreateRolePermissionAction,
  resetDeleteRolePermissionAction,
  resetUpdateRolePermissionAction,
  updateRolePermissionAction,
} from "../actions/rolePermissionActions";

// Main function
function RoleDetailScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Role id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Competency
  const competencyList = useSelector((state) => state.competencyList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Rank
  const rankList = useSelector((state) => state.rankList);

  // Role
  const roleDelete = useSelector((state) => state.roleDelete);

  const roleDetails = useSelector((state) => state.roleDetails);

  const roleUpdate = useSelector((state) => state.roleUpdate);

  // Role competency
  const roleCompetencyCreate = useSelector(
    (state) => state.roleCompetencyCreate
  );

  const roleCompetencyDelete = useSelector(
    (state) => state.roleCompetencyDelete
  );

  const roleCompetencyDetails = useSelector(
    (state) => state.roleCompetencyDetails
  );

  const roleCompetencyList = useSelector((state) => state.roleCompetencyList);

  const roleCompetencyUpdate = useSelector(
    (state) => state.roleCompetencyUpdate
  );

  // Role permission
  const rolePermissionCreate = useSelector(
    (state) => state.rolePermissionCreate
  );

  const rolePermissionDelete = useSelector(
    (state) => state.rolePermissionDelete
  );

  const rolePermissionDetails = useSelector(
    (state) => state.rolePermissionDetails
  );

  const rolePermissionList = useSelector((state) => state.rolePermissionList);

  const rolePermissionUpdate = useSelector(
    (state) => state.rolePermissionUpdate
  );

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("role-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Job specification
  const [updateSpecificationDrawerIsOpen, setUpdateSpecificationDrawerIsOpen] =
    useState(false);

  // Competencies
  const [addCompetencyDrawerIsOpen, setAddCompetencyDrawerIsOpen] =
    useState(false);
  const [updateCompetencyDrawerIsOpen, setUpdateCompetencyDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Competency
  const competencyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [competencyQuery, setCompetencyQuery] = useState({
    ...competencyQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Rank
  const rankQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [rankQuery, setRankQuery] = useState({
    ...rankQueryDefault,
  });

  // Role competency
  const roleCompetencyQueryDefault = {
    page: 1,
    role: id,
    searchString: "",
  };
  const [roleCompetencyQuery, setRoleCompetencyQuery] = useState({
    ...roleCompetencyQueryDefault,
  });

  // Role permission
  const rolePermissionQueryDefault = {
    page: 1,
    role: id,
    searchString: "",
  };
  const [rolePermissionQuery, setRolePermissionQuery] = useState({
    ...rolePermissionQueryDefault,
  });

  // Form variables
  // Role
  const roleDefault = {
    description: "",
    manager: "",
    managerName: "",
    name: "",
    purpose: "",
    qualifications: "",
    rank: "",
    rankName: "",
    responsibilities: "",
    restricted: true,
    status: "Draft",
  };
  const [role, setRole] = useState({
    ...roleDefault,
  });

  // Role competency
  const roleCompetencyDefault = {
    comments: "",
    competency: "",
    competencyName: "",
    level: 3,
  };
  const [roleCompetency, setRoleCompetency] = useState({
    ...roleCompetencyDefault,
  });

  // Role permission
  const rolePermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [rolePermission, setRolePermission] = useState({
    ...rolePermissionDefault,
  });

  // Other
  const [roleDeleteKey, setRoleDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Role
    setRole({
      ...roleDefault,
    });

    // Role competency
    setRoleCompetency({
      ...roleCompetencyDefault,
    });

    // Role permission
    setRolePermission({
      ...rolePermissionDefault,
    });

    // Query variables
    // Competency
    setCompetencyQuery({
      ...competencyQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Rank
    setRankQuery({
      ...rankQueryDefault,
    });
  };

  // Initialise drawer variables for role
  useEffect(() => {
    setRole({
      description: roleDetails.role.description
        ? roleDetails.role.description
        : roleDefault.description,
      manager: roleDetails.role.manager
        ? roleDetails.role.manager
        : roleDefault.manager,
      managerName: roleDetails.role.manager_name
        ? roleDetails.role.manager_name
        : roleDefault.managerName,
      name: roleDetails.role.name ? roleDetails.role.name : roleDefault.name,
      purpose: roleDetails.role.purpose
        ? roleDetails.role.purpose
        : roleDefault.purpose,
      qualifications: roleDetails.role.qualifications
        ? roleDetails.role.qualifications
        : roleDefault.qualifications,
      rank: roleDetails.role.rank ? roleDetails.role.rank : roleDefault.rank,
      rankName: roleDetails.role.rank_name
        ? roleDetails.role.rank_name
        : roleDefault.rankName,
      responsibilities: roleDetails.role.responsibilities
        ? roleDetails.role.responsibilities
        : roleDefault.responsibilities,
      restricted:
        roleDetails.role.restricted != null
          ? roleDetails.role.restricted
          : roleDefault.restricted,
      status: roleDetails.role.status
        ? roleDetails.role.status
        : roleDefault.status,
    });
    // eslint-disable-next-line
  }, [roleDetails.role]);

  // Initialise drawer variables for role competency
  useEffect(() => {
    setRoleCompetency({
      comments: roleCompetencyDetails.role_competency.comments
        ? roleCompetencyDetails.role_competency.comments
        : roleCompetencyDefault.comments,
      competency: roleCompetencyDetails.role_competency.competency
        ? roleCompetencyDetails.role_competency.competency
        : roleCompetencyDefault.competency,
      competencyName: roleCompetencyDetails.role_competency.competency_data
        ? roleCompetencyDetails.role_competency.competency_data.name
        : roleCompetencyDefault.competencyName,
      level: roleCompetencyDetails.role_competency.level
        ? roleCompetencyDetails.role_competency.level
        : roleCompetencyDefault.level,
    });
    // eslint-disable-next-line
  }, [roleCompetencyDetails.role_competency]);

  // Initialise drawer variables for role permission
  useEffect(() => {
    setRolePermission({
      level: rolePermissionDetails.role_permission.level
        ? rolePermissionDetails.role_permission.level
        : rolePermissionDefault.level,
      manager: rolePermissionDetails.role_permission.manager
        ? rolePermissionDetails.role_permission.manager
        : rolePermissionDefault.manager,
      managerName: rolePermissionDetails.role_permission.manager_name
        ? rolePermissionDetails.role_permission.manager_name
        : rolePermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [rolePermissionDetails.role_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If role deleted redirect to roles
  useEffect(() => {
    if (roleDelete.success) {
      dispatch(resetDeleteRoleAction());

      dispatch(resetListRolesAction());

      navigate("/roles");
    }
    // eslint-disable-next-line
  }, [roleDelete.success]);

  // Load role details
  useEffect(() => {
    dispatch(listRoleDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCompetenciesAction({
            ...competencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [competencyQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter ranks
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRanksAction({
            ...rankQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [rankQuery]);

  // Filter role competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRoleCompetenciesAction({
            ...roleCompetencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleCompetencyQuery]);

  // Filter role permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listRolePermissionsAction({ ...rolePermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [rolePermissionQuery]);

  // Form submission
  // Role
  // Delete role
  const deleteRole = () => {
    // Dispatch action
    roleDeleteKey === roleDetails.role.name
      ? dispatch(deleteRoleAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the role's name",
        });
  };

  // Update role specification
  const updateSpecification = () => {
    // Prepare data
    const role_data = new FormData();

    role.purpose
      ? role_data.append("purpose", role.purpose)
      : role_data.append("purpose", roleDefault.purpose);

    role.qualifications
      ? role_data.append("qualifications", role.qualifications)
      : role_data.append("qualifications", roleDefault.qualifications);

    role.responsibilities
      ? role_data.append("responsibilities", role.responsibilities)
      : role_data.append("responsibilities", roleDefault.responsibilities);

    // Dispatch action
    dispatch(updateRoleAction(roleDetails.role.id, role_data));
  };

  // Update role summary
  const updateSummary = () => {
    // Prepare data
    const role_data = new FormData();

    role.description
      ? role_data.append("description", role.description)
      : role_data.append("description", roleDefault.description);

    role.manager
      ? role_data.append("manager", role.manager)
      : role_data.append("manager", roleDefault.manager);

    role.name
      ? role_data.append("name", role.name)
      : role_data.append("name", roleDefault.name);

    role.rank
      ? role_data.append("rank", role.rank)
      : role_data.append("rank", roleDefault.rank);

    role.restricted != null
      ? role_data.append("restricted", role.restricted)
      : role_data.append("restricted", roleDefault.restricted);

    role.status
      ? role_data.append("status", role.status)
      : role_data.append("status", roleDefault.status);

    // Dispatch action
    dispatch(updateRoleAction(roleDetails.role.id, role_data));
  };

  useEffect(() => {
    if (roleUpdate.success) {
      dispatch(listRoleDetailsAction(id));

      dispatch(resetUpdateRoleAction());
    }
    // eslint-disable-next-line
  }, [roleUpdate.success]);

  // Role competency
  // Add competency
  const addCompetency = () => {
    // Prepare data
    const role_competency_data = new FormData();

    roleCompetency.comments
      ? role_competency_data.append("comments", roleCompetency.comments)
      : role_competency_data.append("comments", roleCompetencyDefault.comments);

    roleCompetency.competency
      ? role_competency_data.append("competency", roleCompetency.competency)
      : role_competency_data.append(
          "competency",
          roleCompetencyDefault.competency
        );

    roleCompetency.level
      ? role_competency_data.append("level", roleCompetency.level)
      : role_competency_data.append("level", roleCompetencyDefault.level);

    role_competency_data.append("role", id);

    // Dispatch action
    dispatch(createRoleCompetencyAction(role_competency_data));
  };

  // Delete competency
  const deleteCompetency = () => {
    // Dispatch action
    dispatch(
      deleteRoleCompetencyAction(roleCompetencyDetails.role_competency.id)
    );
  };

  // Update competency
  const updateCompetency = () => {
    // Prepare data
    const role_competency_data = new FormData();

    roleCompetency.comments
      ? role_competency_data.append("comments", roleCompetency.comments)
      : role_competency_data.append("comments", roleCompetencyDefault.comments);

    roleCompetency.competency
      ? role_competency_data.append("competency", roleCompetency.competency)
      : role_competency_data.append(
          "competency",
          roleCompetencyDefault.competency
        );

    roleCompetency.level
      ? role_competency_data.append("level", roleCompetency.level)
      : role_competency_data.append("level", roleCompetencyDefault.level);

    // Dispatch action
    dispatch(
      updateRoleCompetencyAction(
        roleCompetencyDetails.role_competency.id,
        role_competency_data
      )
    );
  };

  // Role permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const role_permission = new FormData();

    rolePermission.level
      ? role_permission.append("level", rolePermission.level)
      : role_permission.append("level", rolePermissionDefault.level);

    rolePermission.manager
      ? role_permission.append("manager", rolePermission.manager)
      : role_permission.append("manager", rolePermissionDefault.manager);

    role_permission.append("role", id);

    // Dispatch action
    dispatch(createRolePermissionAction(role_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteRolePermissionAction(rolePermissionDetails.role_permission.id)
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const role_permission = new FormData();

    rolePermission.level
      ? role_permission.append("level", rolePermission.level)
      : role_permission.append("level", rolePermissionDefault.level);

    rolePermission.manager
      ? role_permission.append("manager", rolePermission.manager)
      : role_permission.append("manager", rolePermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateRolePermissionAction(
        rolePermissionDetails.role_permission.id,
        role_permission
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe the role</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Role name */}
                  <FormDrawerTextField2
                    error={roleUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Role name"
                    labelInfo="(required)"
                    placeholder="Enter the role's name"
                    setValueObject={setRole}
                    setValueVariable="name"
                    value={role.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Rank */}
                  <FormDrawerSelectField2
                    error={roleUpdate.error}
                    fieldName="rank"
                    helperText="You can select an existing rank in the organisation's hierarchy"
                    icon="layout-hierarchy"
                    info={
                      <RankInfo rankId={role.rank} query={rankQueryDefault} />
                    }
                    items={rankList.ranks}
                    label="Rank"
                    labelInfo="(required)"
                    loading={rankList.loading}
                    placeholder="Select a rank"
                    query={rankQuery.searchString}
                    setQueryObject={setRankQuery}
                    setQueryVariable="searchString"
                    setValueObject={setRole}
                    setValueIdVariable="rank"
                    setValueNameVariable="rankName"
                    showInfo={role.rank}
                    value={role.rankName}
                  />
                </div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={roleUpdate.error}
                fieldName="description"
                helperText="Enter a detailed description suitable to include in proposals"
                label="Description"
                placeholder="Describe the role"
                setValueObject={setRole}
                setValueVariable="description"
                value={role.description}
              />
            </Card>

            <Card>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Status */}
                  <FormDrawerButtonField
                    disabled={
                      roleDetails.role.user_access_status === "edit" ||
                      roleDetails.role.user_access_status === "view"
                    }
                    error={roleUpdate.error}
                    fieldName="status"
                    helperText="Roles in draft status are not available to include in engagements, proposals or vacancies"
                    label="Status"
                    options={[
                      {
                        icon: "tick-circle",
                        id: 1,
                        intent: "success",
                        label: "Approved",
                        value: "Approved",
                      },
                      {
                        icon: "new-drawing",
                        id: 2,
                        intent: "primary",
                        label: "Draft",
                        value: "Draft",
                      },
                    ]}
                    setValueObject={setRole}
                    setValueVariable="status"
                    value={role.status}
                  />

                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      roleDetails.role.user_access_status === "edit" ||
                      roleDetails.role.user_access_status === "view"
                    }
                    error={roleUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted role can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setRole}
                    setValueVariable="restricted"
                    value={role.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      roleDetails.role.user_access_status === "edit" ||
                      roleDetails.role.user_access_status === "view"
                    }
                    error={roleUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the role - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setRole}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={role.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the role manager and the role is
                    restricted, you will not be able to access it again, unless
                    you are organisation administrator/owner or have edit/view
                    permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={roleUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Roles"
            view="Detail"
          />
        }
        helpTitle="Updating a role"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={roleDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listRoleDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateRoleAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={roleUpdate.success}
        saveSuccessMessage="Role updated."
        saving={roleUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={rolePermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setRolePermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={rolePermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={rolePermissionCreate.error}
              fieldName="level"
              intent={
                rolePermission.level === "Edit"
                  ? "success"
                  : rolePermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setRolePermission}
              setValueVariable="level"
              value={rolePermission.level}
            />
          </Card>
        }
        error={rolePermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Roles"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listRolePermissionsAction({ role: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateRolePermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={rolePermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={rolePermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={rolePermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setRolePermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={rolePermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={rolePermissionUpdate.error}
              fieldName="level"
              intent={
                rolePermission.level === "Edit"
                  ? "success"
                  : rolePermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setRolePermission}
              setValueVariable="level"
              value={rolePermission.level}
            />
          </Card>
        }
        deleteSuccess={rolePermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={rolePermissionDelete.loading}
        error={rolePermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Roles"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={rolePermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listRolePermissionsAction({ ...rolePermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteRolePermissionAction());

          dispatch(resetUpdateRolePermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={rolePermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={rolePermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="medium"
        title="Update permission"
      />

      {/* Specification form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the role specification</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Purpose */}
            <FormDrawerTextAreaField3
              error={roleUpdate.error}
              fieldName="purpose"
              label="Purpose"
              placeholder="Summarise the purpose of the role"
              setValueObject={setRole}
              setValueVariable="purpose"
              value={role.purpose}
            />

            {/* Qualifications */}
            <FormDrawerTextAreaField3
              error={roleUpdate.error}
              fieldName="qualifications"
              label="Qualifications"
              placeholder="Describe the required experience for the role"
              setValueObject={setRole}
              setValueVariable="qualifications"
              value={role.qualifications}
            />

            {/* Responsibilities */}
            <FormDrawerTextAreaField3
              error={roleUpdate.error}
              fieldName="responsibilities"
              label="Responsibilities"
              placeholder="Describe the day-to-day responsibilities of the role"
              setValueObject={setRole}
              setValueVariable="responsibilities"
              value={role.responsibilities}
            />
          </Card>
        }
        error={roleUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update specification"
            module="Roles"
            view="Detail"
          />
        }
        helpTitle="Defining a role"
        icon="edit"
        isOpen={updateSpecificationDrawerIsOpen}
        loading={roleDetails.loading}
        onSave={updateSpecification}
        refreshList={() => {
          dispatch(listRoleDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateRoleAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={roleUpdate.success}
        saveSuccessMessage="Role updated."
        saving={roleUpdate.loading}
        setIsOpen={setUpdateSpecificationDrawerIsOpen}
        title="Update specification"
      />

      {/* New competency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the competency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Competency */}
                <FormDrawerSelectField2
                  error={roleCompetencyCreate.error}
                  fieldName="competency"
                  helperText="You can select from your organisation's competencies"
                  icon="build"
                  info={
                    <CompetencyInfo
                      competencyId={roleCompetency.competency}
                      query={competencyQueryDefault}
                    />
                  }
                  items={competencyList.competencies}
                  label="Competency"
                  labelInfo="(required)"
                  loading={competencyList.loading}
                  placeholder="Select a competency"
                  query={competencyQuery.searchString}
                  setQueryObject={setCompetencyQuery}
                  setQueryVariable="searchString"
                  setValueObject={setRoleCompetency}
                  setValueIdVariable="competency"
                  setValueNameVariable="competencyName"
                  showInfo={roleCompetency.competency}
                  value={roleCompetency.competencyName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level */}
                <FormDrawerSliderField
                  error={roleCompetencyCreate.error}
                  fieldName="level"
                  intent="success"
                  label="Level"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Beginner";
                      case 5:
                        return "Expert";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setRoleCompetency}
                  setValueVariable="level"
                  value={roleCompetency.level}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={roleCompetencyCreate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Explain the need for this competency"
              setValueObject={setRoleCompetency}
              setValueVariable="comments"
              value={roleCompetency.comments}
            />
          </Card>
        }
        error={roleCompetencyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add competency"
            module="Roles"
            view="Detail"
          />
        }
        helpTitle="Defining competencies"
        icon="edit"
        isOpen={addCompetencyDrawerIsOpen}
        onSave={addCompetency}
        refreshList={() => {
          dispatch(listRoleCompetenciesAction({ role: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateRoleCompetencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={roleCompetencyCreate.success}
        saveSuccessMessage="Competency created."
        saving={roleCompetencyCreate.loading}
        setIsOpen={setAddCompetencyDrawerIsOpen}
        title="Add competency"
      />

      {/* Existing competency form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the competency</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Competency */}
                <FormDrawerSelectField2
                  error={roleCompetencyUpdate.error}
                  fieldName="competency"
                  helperText="You can select from your organisation's competencies"
                  icon="build"
                  info={
                    <CompetencyInfo
                      competencyId={roleCompetency.competency}
                      query={competencyQueryDefault}
                    />
                  }
                  items={competencyList.competencies}
                  label="Competency"
                  labelInfo="(required)"
                  loading={competencyList.loading}
                  placeholder="Select a competency"
                  query={competencyQuery.searchString}
                  setQueryObject={setCompetencyQuery}
                  setQueryVariable="searchString"
                  setValueObject={setRoleCompetency}
                  setValueIdVariable="competency"
                  setValueNameVariable="competencyName"
                  showInfo={roleCompetency.competency}
                  value={roleCompetency.competencyName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level */}
                <FormDrawerSliderField
                  error={roleCompetencyUpdate.error}
                  fieldName="level"
                  intent="success"
                  label="Level"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Beginner";
                      case 5:
                        return "Expert";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setRoleCompetency}
                  setValueVariable="level"
                  value={roleCompetency.level}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={roleCompetencyUpdate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Explain the need for this competency"
              setValueObject={setRoleCompetency}
              setValueVariable="comments"
              value={roleCompetency.comments}
            />
          </Card>
        }
        cannotDelete={roleCompetencyDetails.role_competency.cannot_delete}
        deleteSuccess={roleCompetencyDelete.success}
        deleteSuccessMessage="Competency deleted."
        deleting={roleCompetencyDelete.loading}
        error={roleCompetencyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update competency"
            module="Roles"
            view="Detail"
          />
        }
        helpTitle="Defining competencies"
        icon="edit"
        isOpen={updateCompetencyDrawerIsOpen}
        loading={roleCompetencyDetails.loading}
        onDelete={deleteCompetency}
        onSave={updateCompetency}
        refreshList={() => {
          dispatch(listRoleCompetenciesAction({ ...roleCompetencyQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteRoleCompetencyAction());

          dispatch(resetUpdateRoleCompetencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={roleCompetencyUpdate.success}
        saveSuccessMessage="Competency updated."
        saving={roleCompetencyUpdate.loading}
        setIsOpen={setUpdateCompetencyDrawerIsOpen}
        title="Update competency"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Roles"
              onClick={() => {
                dispatch(resetListRolesAction());

                navigate("/roles");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          roleDetails.role.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={roleDetails.loading}
        title={`${roleDetails.role.name}`}
      />

      {/* Error alerts */}
      {/* Competencies loading error */}
      {competencyList.error && (
        <ErrorAlert message="We couldn't load the competency list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Role delete error */}
      {roleDelete.error && (
        <ErrorAlert message="We couldn't delete the role. Try refreshing the page." />
      )}
      {/* Role loading error */}
      {roleDetails.error && (
        <ErrorAlert message="We couldn't load the role. Try refreshing the page." />
      )}
      {/* Role competency loading error */}
      {roleCompetencyList.error && (
        <ErrorAlert message="We couldn't load the competency list. Try refreshing the page." />
      )}
      {/* Role permissions loading error */}
      {rolePermissionList.error && (
        <ErrorAlert message="We couldn't load the role permissions. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/6GLCg0Kb-SY?si=3LwMX13cgKRiJfs"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Role"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="role-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="role-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          roleDetails.role.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listRoleDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={roleDetails.loading}
                          value={roleDetails.role.id}
                        />

                        {/* Role name */}
                        <TabSectionField
                          label="Role name"
                          loading={roleDetails.loading}
                          value={roleDetails.role.name}
                        />

                        {/* Rank */}
                        <TabSectionField
                          label="Rank"
                          loading={roleDetails.loading}
                          value={roleDetails.role.rank_name}
                        />

                        {/* Description */}
                        <TabSectionField
                          label="Description"
                          loading={roleDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={roleDetails.role.description}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={roleDetails.loading}
                          value={
                            <Tag
                              intent={
                                roleDetails.role.status === "Approved"
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {roleDetails.role.status}
                            </Tag>
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            roleDetails.role.restricted
                              ? "Users must have permission to access this role"
                              : "Everyone in the organisation can edit this role"
                          }
                          label="Access"
                          loading={roleDetails.loading}
                          value={
                            <Tag
                              intent={
                                roleDetails.role.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {roleDetails.role.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            roleDetails.role.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={roleDetails.loading}
                          value={
                            <NameValue
                              email={roleDetails.role.manager_name}
                              image={roleDetails.role.manager_profile_photo}
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update role information"
                    icon="hat"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(roleDetails.role.user_access_status === "owner" ||
                    roleDetails.role.user_access_status === "admin" ||
                    roleDetails.role.user_access_status === "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={rolePermissionList.loading}
                              setValueObject={setRolePermissionQuery}
                              setValueVariable="searchString"
                              value={rolePermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={rolePermissionList.role_permissions.map(
                              (role_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={role_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listRolePermissionDetailsAction(
                                          role_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={role_permission}
                                  />
                                );
                              }
                            )}
                            count={rolePermissionList.count}
                            loading={rolePermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={rolePermissionQuery.page}
                            setPageObject={setRolePermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, roles are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete role */}
                  {(roleDetails.role.user_access_status === "owner" ||
                    roleDetails.role.user_access_status === "admin" ||
                    roleDetails.role.user_access_status === "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={roleDetails.role.cannot_delete}
                              id="confirm-role-name"
                              onChange={(e) => {
                                setRoleDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the role's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={roleDeleteKey}
                            />

                            <Button
                              disabled={roleDetails.role.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={roleDelete.loading}
                              onClick={deleteRole}
                              text="Delete this role"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{roleDetails.role.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the role"
                      icon="delete"
                      message={
                        roleDetails.role.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this role, you will lose all information associated with it."
                      }
                      messageIntent={
                        roleDetails.role.cannot_delete ? "primary" : "danger"
                      }
                      title="Delete role"
                    />
                  )}
                </div>
              }
            />

            {/* Detail */}
            <Tab
              id="role-detail-tab"
              title="Detail"
              panel={
                <div>
                  {/* Specification */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            roleDetails.role.user_access_status === "view"
                          }
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(listRoleDetailsAction(id));

                            setUpdateSpecificationDrawerIsOpen(true);
                          }}
                          style={{
                            margin: "0 10px 0 0",
                          }}
                          text="Edit"
                        />

                        <GenerateButton
                          disabled={
                            !roleDetails.role.description ||
                            (roleDetails.role.purpose &&
                              roleDetails.role.qualifications &&
                              roleDetails.role.responsibilities) ||
                            roleDetails.role.user_access_status === "view"
                          }
                          objectDescription={`${roleDetails.role.name}: ${roleDetails.role.description}`}
                          objectType="role"
                          onSuccess={(result) => {
                            if (
                              (!("purpose" in result) ||
                                result.purpose === "") &&
                              (!("qualifications" in result) ||
                                result.qualifications === "") &&
                              (!("responsibilities" in result) ||
                                result.responsibilities === "")
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            const roleData = {
                              purpose: role.purpose || result.purpose,
                              qualifications:
                                role.qualifications || result.qualifications,
                              responsibilities:
                                role.responsibilities ||
                                result.responsibilities,
                            };

                            dispatch(updateRoleAction(id, roleData));
                          }}
                        />
                      </>
                    }
                    content={
                      <div>
                        {/* Purpose */}
                        <TabSectionField
                          label="Purpose"
                          loading={roleDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={roleDetails.role.purpose}
                            />
                          }
                        />

                        {/* Qualifications */}
                        <TabSectionField
                          label="Qualifications"
                          loading={roleDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={roleDetails.role.qualifications}
                            />
                          }
                        />

                        {/* Responsibilities */}
                        <TabSectionField
                          bottom
                          label="Responsibilities"
                          loading={roleDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={roleDetails.role.responsibilities}
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update the role specification"
                    icon="form"
                    title="Specification"
                  />

                  {/* Competencies */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          roleDetails.role.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddCompetencyDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search competency"
                            searching={roleCompetencyList.loading}
                            setValueObject={setRoleCompetencyQuery}
                            setValueVariable="searchString"
                            value={roleCompetencyQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={roleCompetencyList.role_competencies.map(
                            (role_competency, i, { length }) => {
                              return (
                                <TabSectionField
                                  bottom={length - 1 === i}
                                  key={role_competency.id}
                                  label={
                                    <div
                                      style={{
                                        alignItems: "center",
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        {role_competency.competency_data.name}
                                      </div>

                                      <div style={{ marginLeft: "5px" }}>
                                        <InfoPopup
                                          content={
                                            <CompetencyInfo
                                              competencyData={
                                                role_competency.competency_data
                                              }
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  }
                                  value={
                                    <TabSectionFieldMultifieldCard
                                      children={
                                        <>
                                          {/* Level */}
                                          <TabSectionField
                                            helperText={
                                              role_competency.level === 5
                                                ? "Expert"
                                                : role_competency.level >= 3
                                                ? "Intermediate"
                                                : "Beginner"
                                            }
                                            label="Level"
                                            value={
                                              <TabSectionFieldProgressBar
                                                intent="success"
                                                rating={
                                                  role_competency.level / 5
                                                }
                                              />
                                            }
                                          />

                                          {/* Comments */}
                                          <TabSectionField
                                            bottom
                                            label="Comments"
                                            value={role_competency.comments}
                                          />
                                        </>
                                      }
                                      interactive={
                                        roleDetails.role.user_access_status !==
                                        "view"
                                      }
                                      onClick={() => {
                                        if (
                                          roleDetails.role
                                            .user_access_status !== "view"
                                        ) {
                                          dispatch(
                                            listRoleCompetencyDetailsAction(
                                              role_competency.id
                                            )
                                          );

                                          setUpdateCompetencyDrawerIsOpen(true);
                                        }
                                      }}
                                    />
                                  }
                                />
                              );
                            }
                          )}
                          count={roleCompetencyList.count}
                          loading={roleCompetencyList.loading}
                          noResultsMessage="Add competencies."
                          noResultsTitle="No competencies found"
                          page={roleCompetencyQuery.page}
                          setPageObject={setRoleCompetencyQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Identify the role's competency requirements"
                    icon="build"
                    title="Competencies"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default RoleDetailScreen;
