// Library imports
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import { AppToaster } from "../components/AppToaster";

// Redux action imports
// Payment
import {
  createCustomerPortalAction,
  resetCreateCustomerPortalAction,
} from "../actions/paymentActions";

// Main function
function ManageAccountButton({
  disabled,
  icon,
  intent,
  text,
  organisationId,
  style,
}) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Payment
  const customerPortalCreate = useSelector(
    (state) => state.customerPortalCreate
  );

  // Create customer portal session
  const [loading, setLoading] = useState(false);

  const createCustomerPortalSession = () => {
    if (organisationId) {
      setLoading(true);

      // Prepare data
      const data = new FormData();

      data.append("organisation", organisationId);

      // Dispatch action
      dispatch(createCustomerPortalAction(data));
    }
  };

  // Redirect to customer portal
  useEffect(() => {
    if (loading) {
      if (customerPortalCreate.success) {
        window.open(customerPortalCreate.customer_portal, "_self");

        dispatch(resetCreateCustomerPortalAction());

        setLoading(false);
      } else if (customerPortalCreate.error) {
        AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message:
            "We couldn't redirect you to the customer portal. Please try again.",
        });

        dispatch(resetCreateCustomerPortalAction());

        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [customerPortalCreate.loading]);

  return (
    <Button
      disabled={disabled || loading}
      icon={icon}
      intent={intent}
      loading={loading}
      onClick={createCustomerPortalSession}
      style={style}
      text={text}
    />
  );
}

export default ManageAccountButton;
