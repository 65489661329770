// Library imports
import {
  Card,
  Callout,
  Colors,
  NonIdealState,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import HtmlDisplay from "../components/HtmlDisplay";

// Utility imports
import generateHtmlDocument from "../utilities/generateHtmlDocument";

// Main function
function TabSectionHtmlEditor({
  document,
  error,
  errorMessage,
  form,
  height,
  loading,
  setHtml,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Generate HTML
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (document) {
      setContent(generateHtmlDocument(document));
    }
  }, [document]);

  useEffect(() => {
    if (content) {
      setHtml(parse(content));
    }
  }, [content, setHtml]);

  // JSX UI code
  return (
    <div
      style={{
        display: "flex",
        height: height ? height : "75vh",
        width: "100%",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          overflowY: "auto",
          padding: "10px",
          width: "400px",
        }}
      >
        {error && (
          <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
            {errorMessage ? errorMessage : "Unable to display content."}
          </Callout>
        )}

        {form}
      </div>

      <Card
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          margin: "10px",
          overflowY: "hidden",
          padding: "10px",
          width: "100%",
        }}
      >
        <div style={{ height: "100%" }}>
          <div
            style={{
              height: "100%",
              width: "100%",
            }}
          >
            {loading ? (
              <Spinner
                size={SpinnerSize.LARGE}
                style={{ margin: "30vh 0 0 0" }}
              />
            ) : !document.conclusion &&
              !document.introduction &&
              document.sections.length === 0 &&
              !document.title ? (
              <NonIdealState
                icon="manually-entered-data"
                description="Start by generating an outline"
                title="No content"
              />
            ) : (
              <HtmlDisplay html={content} />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default TabSectionHtmlEditor;
