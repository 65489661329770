// Library imports
import { Colors, FormGroup, NumericInput } from "@blueprintjs/core";
import React from "react";

// Main function
function FormDrawerNumericField2({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  icon,
  label,
  labelInfo,
  max,
  min,
  placeholder,
  setValueObject,
  setValueVariable,
  value,
}) {
  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <NumericInput
        disabled={disabled}
        fill
        id={fieldName}
        intent={
          error
            ? error.response.data[fieldName]
              ? "danger"
              : undefined
            : undefined
        }
        leftIcon={icon}
        majorStepSize={10}
        max={max}
        min={min}
        minorStepSize={0.1}
        onValueChange={(_valueAsNumber) => {
          setValueObject((prevState) => {
            return {
              ...prevState,
              [setValueVariable]: _valueAsNumber * 60,
            };
          });
        }}
        placeholder={placeholder}
        stepSize={1}
        value={value ? value / 60 : ""}
      />
    </FormGroup>
  );
}

export default FormDrawerNumericField2;
