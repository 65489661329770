// Library imports
import {
  ButtonGroup,
  Button,
  Classes,
  Collapse,
  Colors,
  NonIdealState,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";

// Utility imports
import setTextColour from "../utilities/setTextColour";

// Main function
function Timeline4({
  categoryOneColor,
  categoryOneValue,
  categoryThreeColor,
  categoryThreeValue,
  categoryTwoColor,
  categoryTwoValue,
  childColor,
  data,
  height,
  hideExpandButton,
  highlightColor,
  highlightItems,
  itemColor,
  itemListHeading,
  itemListWidth,
  loading,
  milestoneColor,
  noResultsIcon,
  noResultsMessage,
  noResultsTitle,
  onClick,
  progressColor,
  resourceColor,
  resourceData,
  scheduleRef,
  showDates,
  tableTop,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Show left pane
  const defaultItemListWidth = "375px";
  const [showLeftPane, setShowLeftPane] = useState(true);

  const showOrHideLeftPane = () => {
    setShowLeftPane(!showLeftPane);
  };

  // Set zoom
  const textBreakoutWidth = 200;
  const [zoom, setZoom] = useState("week");

  const zoomIn = () => {
    switch (zoom) {
      case "month":
        setZoom("week");
        break;

      case "year":
        setZoom("month");
        break;

      default:
        setZoom("day");
    }
  };

  const zoomOut = () => {
    switch (zoom) {
      case "day":
        setZoom("week");
        break;

      case "week":
        setZoom("month");
        break;

      default:
        setZoom("year");
    }
  };

  // Get view parameters
  const getHeadingLevel1Params = (date) => {
    let cellWidth;

    switch (zoom) {
      case "year":
        cellWidth = 12 * 40;

        break;

      default:
        cellWidth = 7 * 40;

        break;
    }

    return {
      cellWidth: cellWidth,
    };
  };

  const getHeadingLevel2Params = (date) => {
    let cellWidth, height, solidLeftBorder, showTopBorder;

    switch (zoom) {
      case "month":
        cellWidth = 140;

        height = 70;

        solidLeftBorder = false;

        showTopBorder = true;

        break;

      case "week":
        cellWidth = 140;

        height = 70;

        solidLeftBorder = false;

        showTopBorder = true;

        break;

      case "year":
        cellWidth = 40;

        height = 35;

        solidLeftBorder = date.getDate() === 1 && date.getMonth() === 0;

        break;

      default:
        cellWidth = 40;

        height = 35;

        solidLeftBorder = date.getDay() === 1;

        break;
    }

    return {
      cellWidth: cellWidth,
      height: height,
      solidLeftBorder: solidLeftBorder,
      showTopBorder: showTopBorder,
    };
  };

  const getTimelineParams = (date, item) => {
    let background,
      cellWidth,
      daysInMonth,
      marginLeft,
      solidLeftBorder,
      startPeriod,
      width;

    // If item is a milestone, make end/start equal to due date
    if (item.due) {
      item.end = item.due;

      item.start = item.due;
    }

    switch (zoom) {
      case "month":
        background = lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2;

        cellWidth = 140;

        daysInMonth = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).getDate();

        marginLeft =
          (cellWidth * (new Date(item.start).getTime() - date.getTime())) /
          (daysInMonth * 24 * 60 * 60 * 1000);

        solidLeftBorder = false;

        startPeriod =
          new Date(item.start).getTime() >= date.getTime() &&
          new Date(item.start).getTime() <
            date.getTime() + daysInMonth * 24 * 60 * 60 * 1000;

        width =
          (cellWidth *
            (24 * 60 * 60 * 1000 +
              new Date(item.end).getTime() -
              new Date(item.start).getTime())) /
          (daysInMonth * 24 * 60 * 60 * 1000);

        break;

      case "week":
        background = lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2;

        cellWidth = 140;

        marginLeft =
          (cellWidth * (new Date(item.start).getTime() - date.getTime())) /
          (7 * 24 * 60 * 60 * 1000);

        solidLeftBorder = false;

        startPeriod =
          new Date(item.start).getTime() >= date.getTime() &&
          new Date(item.start).getTime() <
            date.getTime() + 7 * 24 * 60 * 60 * 1000;

        width =
          (cellWidth *
            (24 * 60 * 60 * 1000 +
              new Date(item.end).getTime() -
              new Date(item.start).getTime())) /
          (7 * 24 * 60 * 60 * 1000);

        break;

      case "year":
        background = lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2;

        cellWidth = 40;

        daysInMonth = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).getDate();

        marginLeft =
          (cellWidth * (new Date(item.start).getTime() - date.getTime())) /
          (daysInMonth * 24 * 60 * 60 * 1000);

        solidLeftBorder = date.getMonth() === 0;

        startPeriod =
          new Date(item.start).getTime() >= date.getTime() &&
          new Date(item.start).getTime() <
            date.getTime() + daysInMonth * 24 * 60 * 60 * 1000;

        width =
          (cellWidth *
            (24 * 60 * 60 * 1000 +
              new Date(item.end).getTime() -
              new Date(item.start).getTime())) /
          (daysInMonth * 24 * 60 * 60 * 1000);

        break;

      default:
        background =
          date.getDay() === 6 || date.getDay() === 0
            ? lightMode
              ? Colors.LIGHT_GRAY4
              : Colors.DARK_GRAY4
            : lightMode
            ? Colors.LIGHT_GRAY5
            : Colors.DARK_GRAY2;

        cellWidth = 40;

        solidLeftBorder = date.getDay() === 1;

        startPeriod =
          new Date(item.start).getTime() >= date.getTime() &&
          new Date(item.start).getTime() <
            date.getTime() + 1 * 24 * 60 * 60 * 1000;

        width =
          (cellWidth *
            (24 * 60 * 60 * 1000 +
              new Date(item.end).getTime() -
              new Date(item.start).getTime())) /
          (24 * 60 * 60 * 1000);

        break;
    }

    return {
      background: background,
      cellWidth: cellWidth,
      daysInMonth: daysInMonth,
      marginLeft: marginLeft,
      solidLeftBorder: solidLeftBorder,
      startPeriod: startPeriod,
      width: width,
    };
  };

  // Calculate date range
  const [dateRange, setDateRange] = useState([]);
  const [filteredDateRange, setFilteredDateRange] = useState([]);

  useEffect(() => {
    if (data.length > 0) {
      const dates = [];

      // Get start and end dates in dataset
      data.forEach((item) => {
        dates.push(item.end);
        dates.push(item.start);

        item.children.forEach((child) => {
          dates.push(child.end);
          dates.push(child.start);
        });
      });

      // Remove duplicate dates
      const dateSet = [...new Set(dates.map((date) => date.toString()))];

      // Sort start and end dates in dataset
      const orderedDates = dateSet.sort((a, b) => {
        return Date.parse(a) - Date.parse(b);
      });

      // Generate dates between earliest start and latest end
      const startDate = orderedDates[0];
      const endDate = orderedDates[orderedDates.length - 1];

      const arr = [];

      for (
        var date = new Date(startDate);
        date <= new Date(endDate);
        date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
      ) {
        arr.push(new Date(date));
      }

      //If zoomed to day or week level, fill dates to first day of week of earliest date
      //If zoomed to month level, fill dates to first day of month of earliest date
      //If zoomed to year level, fill dates to first day of year of earliest date
      if (zoom === "day" || zoom === "week") {
        while (arr[0].getDay() !== 1) {
          const addDate = new Date();

          addDate.setTime(arr[0].getTime() - 1 * 24 * 60 * 60 * 1000);

          arr.unshift(addDate);
        }
      } else if (zoom === "month") {
        while (arr[0].getDate() > 1) {
          const addDate = new Date();

          addDate.setTime(arr[0].getTime() - 1 * 24 * 60 * 60 * 1000);

          arr.unshift(addDate);
        }
      } else {
        const startOfLastYear = new Date(arr[0].getFullYear(), 0, 2).getTime();

        while (arr[0].getTime() > startOfLastYear) {
          const addDate = new Date();

          addDate.setTime(arr[0].getTime() - 1 * 24 * 60 * 60 * 1000);

          arr.unshift(addDate);
        }
      }

      //If zoomed to day or week level, fill dates to last day of year plus 1 of latest date
      //If zoomed to month level, fill dates to last day of year plus 1 of latest date
      //If zoomed to year level, fill dates to last day of year plus 5 of latest date
      if (zoom === "day" || zoom === "week") {
        const endOfYearPlusOne = new Date(
          arr[arr.length - 1].getFullYear() + 2,
          0,
          0
        ).getTime();

        const endOfYearPlusOneBuffer =
          (7 - new Date(endOfYearPlusOne).getDay()) * 24 * 60 * 60 * 1000;

        while (
          arr[arr.length - 1].getTime() <
          endOfYearPlusOne + endOfYearPlusOneBuffer
        ) {
          const addDate = new Date();

          addDate.setTime(
            arr[arr.length - 1].getTime() + 1 * 24 * 60 * 60 * 1000
          );

          arr.push(addDate);
        }
      } else if (zoom === "month") {
        const endOfYearPlusOne = new Date(
          arr[arr.length - 1].getFullYear() + 2,
          0,
          0
        ).getTime();

        while (arr[arr.length - 1].getTime() < endOfYearPlusOne) {
          const addDate = new Date();

          addDate.setTime(
            arr[arr.length - 1].getTime() + 1 * 24 * 60 * 60 * 1000
          );

          arr.push(addDate);
        }
      } else {
        const endOfYearPlusFive = new Date(
          arr[arr.length - 1].getFullYear() + 6,
          0,
          0
        ).getTime();

        while (arr[arr.length - 1].getTime() <= endOfYearPlusFive) {
          const addDate = new Date();

          addDate.setTime(
            arr[arr.length - 1].getTime() + 1 * 24 * 60 * 60 * 1000
          );

          arr.push(addDate);
        }
      }

      setDateRange(arr);
    }
  }, [data, zoom]);

  useEffect(() => {
    if (dateRange.length > 0) {
      switch (zoom) {
        case "month":
          setFilteredDateRange(
            dateRange.filter((date) => date.getDate() === 1)
          );
          break;

        case "week":
          setFilteredDateRange(dateRange.filter((date) => date.getDay() === 1));
          break;

        case "year":
          setFilteredDateRange(
            dateRange.filter((date) => date.getDate() === 1)
          );
          break;

        default:
          setFilteredDateRange(dateRange);
      }
    }
    // eslint-disable-next-line
  }, [dateRange]);

  // Collapse/expand items
  const [expanded, setExpanded] = useState([]);

  const collapseOrExpand = (itemId) => {
    setExpanded((prevState) => {
      return {
        ...prevState,
        [itemId]: !expanded[itemId],
      };
    });
  };

  const expandAll = () => {
    data.forEach((item) =>
      setExpanded((prevState) => {
        return {
          ...prevState,
          [item.id]: true,
        };
      })
    );
  };

  // Show/hide resources
  const [showResources, setShowResources] = useState(false);

  // Format items/children
  const defaultChildColor = Colors.LIME5;
  const defaultHighlightColor = Colors.ORANGE5;
  const defaultItemColor = Colors.INDIGO3;
  const defaultMilestoneColor = Colors.GOLD5;
  const defaultProgressColor = Colors.GREEN5;
  const defaultResourceColor = Colors.CERULEAN5;

  const setBorderHighlight = (item) => {
    return highlightItems
      ? highlightItems.length > 0
        ? highlightItems.includes(item.id) &&
          `3px solid ${highlightColor ? highlightColor : defaultHighlightColor}`
        : undefined
      : undefined;
  };

  const setChildBackground = () => {
    return childColor
      ? `linear-gradient(to bottom, ${childColor}CC 95%, ${childColor}33 5%)`
      : `linear-gradient(to bottom, ${defaultChildColor}CC 95%, ${defaultChildColor}33 5%)`;
  };

  const setChildTextColor = () => {
    return setTextColour(childColor ? childColor : defaultChildColor);
  };

  const setItemBackground = (item) => {
    return item.category
      ? item.category === categoryOneValue
        ? `linear-gradient(to bottom, ${categoryOneColor}CC 95%, ${categoryOneColor}33 5%)`
        : item.category === categoryTwoValue
        ? `linear-gradient(to bottom, ${categoryTwoColor}CC 95%, ${categoryTwoColor}33 5%)`
        : item.category === categoryThreeValue
        ? `linear-gradient(to bottom, ${categoryThreeColor}CC 95%, ${categoryThreeColor}33 5%)`
        : itemColor
        ? `linear-gradient(to bottom, ${itemColor}CC 95%, ${itemColor}33 5%)`
        : `linear-gradient(to bottom, ${defaultItemColor}CC 95%, ${defaultItemColor}33 5%)`
      : itemColor
      ? `linear-gradient(to bottom, ${itemColor}CC 95%, ${itemColor}33 5%)`
      : `linear-gradient(to bottom, ${defaultItemColor}CC 95%, ${defaultItemColor}33 5%)`;
  };

  const setItemTextColor = (item) => {
    return setTextColour(
      item.category
        ? item.category === categoryOneValue
          ? categoryOneColor
          : item.category === categoryTwoValue
          ? categoryTwoColor
          : item.category === categoryThreeValue
          ? categoryThreeColor
          : defaultItemColor
        : itemColor
        ? itemColor
        : defaultItemColor
    );
  };

  const setMilestoneBackground = () => {
    return milestoneColor
      ? `linear-gradient(to bottom, ${milestoneColor}CC 95%, ${milestoneColor}33 5%)`
      : `linear-gradient(to bottom, ${defaultMilestoneColor}CC 95%, ${defaultMilestoneColor}33 5%)`;
  };

  const setResourceBackground = () => {
    return resourceColor
      ? `linear-gradient(to bottom, ${resourceColor}CC 95%, ${resourceColor}33 5%)`
      : `linear-gradient(to bottom, ${defaultResourceColor}CC 95%, ${defaultResourceColor}33 5%)`;
  };

  const setResourceTextColor = () => {
    return setTextColour(resourceColor ? resourceColor : defaultResourceColor);
  };

  // Highlight row
  const [highlightChildRow, setHighlightChildRow] = useState(null);
  const [highlightItemRow, setHighlightItemRow] = useState(null);
  const [highlightMilestoneRow, setHighlightMilestoneRow] = useState(null);

  // JSX UI code
  return (
    <div>
      {/* Content */}
      {data.length > 0 && (
        <div
          style={{
            background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
            margin: tableTop ? "0" : "0 0 20px 0",
            padding: tableTop ? "10px 10px 1px 10px" : "10px",
          }}
        >
          <div
            style={{
              background: tableTop
                ? lightMode
                  ? Colors.LIGHT_GRAY5
                  : Colors.DARK_GRAY2
                : undefined,
              padding: tableTop ? "10px" : "0px",
            }}
          >
            {/* Buttons */}
            <ButtonGroup style={{ margin: "0 0 10px 0" }}>
              <Button
                icon={showLeftPane ? "menu-closed" : "menu-open"}
                onClick={showOrHideLeftPane}
                style={{ margin: hideExpandButton ? "0 10px 0 0" : undefined }}
              />

              {!hideExpandButton && (
                <Button
                  icon="expand-all"
                  onClick={expandAll}
                  style={{ margin: "0 10px 0 0" }}
                />
              )}

              {resourceData && (
                <Button
                  disabled={resourceData ? resourceData.length === 0 : true}
                  icon="people"
                  onClick={() => setShowResources(!showResources)}
                  style={{ margin: "0 10px 0 0" }}
                />
              )}

              <Button
                disabled={zoom === "day"}
                icon="zoom-in"
                onClick={zoomIn}
              />

              <Button
                disabled={zoom === "year"}
                icon="zoom-out"
                onClick={zoomOut}
              />
            </ButtonGroup>

            {/* Timeline */}
            <ScrollSync>
              <div
                className={Classes.TEXT_SMALL}
                ref={scheduleRef}
                style={{ display: "flex" }}
              >
                {/* Left pane */}
                {showLeftPane && (
                  <div
                    style={{
                      maxWidth: itemListWidth
                        ? itemListWidth
                        : defaultItemListWidth,
                      minWidth: itemListWidth
                        ? itemListWidth
                        : defaultItemListWidth,
                    }}
                  >
                    {/* Heading */}
                    <div
                      style={{
                        alignItems: "center",
                        background: lightMode
                          ? Colors.WHITE
                          : Colors.DARK_GRAY4,
                        border: `1px solid ${
                          lightMode ? Colors.GRAY5 : Colors.GRAY1
                        }`,
                        display: "flex",
                        maxHeight: "70px",
                        minHeight: "70px",
                        padding: "10px",
                      }}
                    >
                      {itemListHeading ? (
                        <Text ellipsize>{itemListHeading}</Text>
                      ) : (
                        "Items"
                      )}
                    </div>

                    {/* Items */}
                    <ScrollSyncPane group="vertical">
                      <div
                        className="no-scrollbar"
                        style={{
                          maxHeight: height,
                          overflowY: "auto",
                        }}
                      >
                        {data.map((item) => {
                          return (
                            <div
                              key={item.id}
                              style={{
                                background: lightMode
                                  ? Colors.WHITE
                                  : Colors.DARK_GRAY4,
                                borderLeft: `1px solid ${
                                  lightMode ? Colors.GRAY5 : Colors.GRAY1
                                }`,
                                borderRight: `1px solid ${
                                  lightMode ? Colors.GRAY5 : Colors.GRAY1
                                }`,
                              }}
                            >
                              {/* Item */}
                              <div
                                onMouseEnter={() =>
                                  setHighlightItemRow(item.id)
                                }
                                onMouseLeave={() => setHighlightItemRow(null)}
                                style={{
                                  alignItems: "center",
                                  background:
                                    highlightItemRow === item.id
                                      ? lightMode
                                        ? Colors.LIGHT_GRAY3
                                        : Colors.DARK_GRAY5
                                      : undefined,
                                  borderBottom: `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                  display: "flex",
                                  maxHeight: "45px",
                                  minHeight: "45px",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    maxWidth: showDates ? "70%" : undefined,
                                    minWidth: showDates ? "70%" : undefined,
                                  }}
                                >
                                  {(item.children.length > 0 ||
                                    item.milestones.length > 0) && (
                                    <Button
                                      icon={
                                        expanded[item.id]
                                          ? "caret-down"
                                          : "caret-right"
                                      }
                                      minimal
                                      onClick={() => collapseOrExpand(item.id)}
                                    />
                                  )}

                                  <Text
                                    ellipsize
                                    onClick={() =>
                                      onClick && onClick(item.id, "item")
                                    }
                                    style={{
                                      cursor: onClick ? "pointer" : undefined,
                                      margin:
                                        item.children.length === 0 &&
                                        item.milestones.length === 0
                                          ? "0 5px 0 10px"
                                          : "0 5px 0 0",
                                    }}
                                  >
                                    <strong>{item.name}</strong>
                                  </Text>
                                </div>

                                {showDates && (
                                  <div
                                    style={{
                                      padding: "0 5px 0 5px",
                                      width: "30%",
                                    }}
                                  >
                                    <Tag
                                      fill
                                      minimal
                                      style={{ textAlign: "center" }}
                                    >
                                      <strong>
                                        {new Date(
                                          item.start
                                        ).toLocaleDateString("default", {
                                          day: "numeric",
                                          month: "short",
                                        })}{" "}
                                        -
                                        {new Date(item.end).toLocaleDateString(
                                          "default",
                                          {
                                            day: "numeric",
                                            month: "short",
                                          }
                                        )}
                                      </strong>
                                    </Tag>
                                  </div>
                                )}
                              </div>

                              {/* Children and milestones */}
                              <Collapse isOpen={expanded[item.id]}>
                                {item.children.map((child) => {
                                  return (
                                    <div
                                      key={child.id}
                                      onMouseEnter={() =>
                                        setHighlightChildRow(child.id)
                                      }
                                      onMouseLeave={() =>
                                        setHighlightChildRow(null)
                                      }
                                      style={{
                                        alignItems: "center",
                                        background:
                                          highlightChildRow === child.id
                                            ? lightMode
                                              ? Colors.LIGHT_GRAY3
                                              : Colors.DARK_GRAY5
                                            : undefined,
                                        borderBottom: `1px solid ${
                                          lightMode
                                            ? Colors.GRAY5
                                            : Colors.GRAY1
                                        }`,
                                        display: "flex",
                                        maxHeight: "45px",
                                        minHeight: "45px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          cursor: onClick
                                            ? "pointer"
                                            : undefined,

                                          maxWidth: showDates
                                            ? "70%"
                                            : undefined,
                                          minWidth: showDates
                                            ? "70%"
                                            : undefined,
                                          padding: "10px",
                                        }}
                                      >
                                        <Text
                                          ellipsize
                                          onClick={() =>
                                            onClick &&
                                            onClick(child.id, "child")
                                          }
                                        >
                                          {child.name}
                                        </Text>
                                      </div>

                                      {showDates && (
                                        <div
                                          style={{
                                            padding: "0 5px 0 5px",
                                            width: "30%",
                                          }}
                                        >
                                          <Tag
                                            fill
                                            minimal
                                            style={{ textAlign: "center" }}
                                          >
                                            {new Date(
                                              child.start
                                            ).toLocaleDateString("default", {
                                              day: "numeric",
                                              month: "short",
                                            })}{" "}
                                            -
                                            {new Date(
                                              child.end
                                            ).toLocaleDateString("default", {
                                              day: "numeric",
                                              month: "short",
                                            })}
                                          </Tag>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}

                                {item.milestones.map((milestone) => {
                                  return (
                                    <div
                                      key={milestone.id}
                                      onMouseEnter={() =>
                                        setHighlightMilestoneRow(milestone.id)
                                      }
                                      onMouseLeave={() =>
                                        setHighlightMilestoneRow(null)
                                      }
                                      style={{
                                        alignItems: "center",
                                        background:
                                          highlightMilestoneRow === milestone.id
                                            ? lightMode
                                              ? Colors.LIGHT_GRAY3
                                              : Colors.DARK_GRAY5
                                            : undefined,
                                        borderBottom: `1px solid ${
                                          lightMode
                                            ? Colors.GRAY5
                                            : Colors.GRAY1
                                        }`,
                                        display: "flex",
                                        maxHeight: "45px",
                                        minHeight: "45px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          cursor: onClick
                                            ? "pointer"
                                            : undefined,

                                          maxWidth: showDates
                                            ? "70%"
                                            : undefined,
                                          minWidth: showDates
                                            ? "70%"
                                            : undefined,
                                          padding: "10px",
                                        }}
                                      >
                                        <Text
                                          ellipsize
                                          onClick={() =>
                                            onClick &&
                                            onClick(milestone.id, "milestone")
                                          }
                                        >
                                          {milestone.name}
                                        </Text>
                                      </div>

                                      {showDates && (
                                        <div
                                          style={{
                                            padding: "0 5px 0 5px",
                                            width: "30%",
                                          }}
                                        >
                                          <Tag
                                            fill
                                            minimal
                                            style={{ textAlign: "center" }}
                                          >
                                            {new Date(
                                              milestone.due
                                            ).toLocaleDateString("default", {
                                              day: "numeric",
                                              month: "short",
                                            })}
                                          </Tag>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                              </Collapse>
                            </div>
                          );
                        })}

                        {resourceData && (
                          <Collapse isOpen={showResources}>
                            <div
                              style={{
                                background: lightMode
                                  ? Colors.WHITE
                                  : Colors.DARK_GRAY4,
                                borderLeft: `1px solid ${
                                  lightMode ? Colors.GRAY5 : Colors.GRAY1
                                }`,
                                borderRight: `1px solid ${
                                  lightMode ? Colors.GRAY5 : Colors.GRAY1
                                }`,
                                borderTop: `3px solid ${
                                  lightMode ? Colors.GRAY5 : Colors.GRAY1
                                }`,
                              }}
                            >
                              {resourceData.map((resource) => {
                                return (
                                  <div
                                    key={resource.id}
                                    onMouseEnter={() =>
                                      setHighlightChildRow(resource.id)
                                    }
                                    onMouseLeave={() =>
                                      setHighlightChildRow(null)
                                    }
                                    style={{
                                      alignItems: "center",
                                      background:
                                        highlightChildRow === resource.id
                                          ? lightMode
                                            ? Colors.LIGHT_GRAY3
                                            : Colors.DARK_GRAY5
                                          : undefined,
                                      borderBottom: `1px solid ${
                                        lightMode ? Colors.GRAY5 : Colors.GRAY1
                                      }`,
                                      display: "flex",
                                      maxHeight: "45px",
                                      minHeight: "45px",
                                    }}
                                  >
                                    <div
                                      onClick={() =>
                                        onClick &&
                                        onClick(resource.id, "resource")
                                      }
                                      style={{
                                        alignItems: "center",
                                        cursor: onClick ? "pointer" : undefined,
                                        display: "flex",
                                        maxWidth: showDates ? "70%" : undefined,
                                        minWidth: showDates ? "70%" : undefined,
                                        padding: "10px",
                                      }}
                                    >
                                      <img
                                        alt="profile"
                                        src={
                                          resource.photo
                                            ? resource.photo
                                            : "/images/no_photo.png"
                                        }
                                        style={{
                                          background: "white",
                                          border: `1px solid ${
                                            lightMode
                                              ? Colors.LIGHT_GRAY5
                                              : Colors.DARK_GRAY5
                                          }`,
                                          borderRadius: "50%",
                                          height: "30px",
                                          margin: "0 10px 0 0",
                                          objectFit: "contain",
                                          width: "30px",
                                        }}
                                      />

                                      <Text ellipsize>
                                        {resource.name
                                          ? resource.name
                                          : resource.role}
                                      </Text>
                                    </div>

                                    <div
                                      style={{
                                        padding: "0 5px 0 5px",
                                        width: "30%",
                                      }}
                                    >
                                      {resource.utilisation ? (
                                        <Tag
                                          fill
                                          intent={
                                            resource.utilisation > 110
                                              ? "danger"
                                              : resource.utilisation > 100
                                              ? "warning"
                                              : "success"
                                          }
                                          minimal
                                          style={{ textAlign: "center" }}
                                        >
                                          {resource.utilisation.toLocaleString(
                                            "en-US",
                                            {
                                              maximumFractionDigits: 2,
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                          %
                                        </Tag>
                                      ) : undefined}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </Collapse>
                        )}
                      </div>
                    </ScrollSyncPane>
                  </div>
                )}

                {/* Right pane */}
                <div
                  style={{
                    borderLeft:
                      !showLeftPane &&
                      `1px solid ${lightMode ? Colors.GRAY5 : Colors.GRAY1}`,
                    flex: 1,
                    overflow: "hidden",
                  }}
                >
                  <ScrollSyncPane group="horizontal">
                    <div
                      className="no-scrollbar"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      {/* Heading level 1*/}
                      {(zoom === "day" || zoom === "year") && (
                        <div style={{ display: "flex" }}>
                          {dateRange
                            .filter((date) =>
                              zoom === "day"
                                ? date.getDay() === 1
                                : date.getDate() === 1 && date.getMonth() === 0
                            )
                            .map((date, i, { length }) => {
                              const params = getHeadingLevel1Params(date);

                              return (
                                <div
                                  key={date}
                                  style={{
                                    background: lightMode
                                      ? Colors.WHITE
                                      : Colors.DARK_GRAY4,
                                    borderLeft:
                                      i !== 0 &&
                                      `1px solid ${
                                        lightMode ? Colors.GRAY5 : Colors.GRAY1
                                      }`,
                                    borderRight:
                                      length - 1 === i &&
                                      `1px solid ${
                                        lightMode ? Colors.GRAY5 : Colors.GRAY1
                                      }`,
                                    borderTop: `1px solid ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                    maxHeight: "35px",
                                    minHeight: "35px",
                                    maxWidth:
                                      i === 0
                                        ? params.cellWidth + 5
                                        : params.cellWidth,
                                    minWidth:
                                      i === 0
                                        ? params.cellWidth + 5
                                        : params.cellWidth,
                                    padding:
                                      i === 0 ? "10px 10px 10px 15px" : "10px",
                                    textAlign: "center",
                                  }}
                                >
                                  {zoom === "day" ? (
                                    <div className={Classes.TEXT_MUTED}>
                                      {date.getMonth() !==
                                      new Date(
                                        date.getTime() + 6 * 24 * 60 * 60 * 1000
                                      ).getMonth()
                                        ? `${date.toLocaleDateString(
                                            "default",
                                            {
                                              month: "short",
                                              year: "numeric",
                                            }
                                          )} - ${new Date(
                                            date.getTime() +
                                              7 * 24 * 60 * 60 * 1000
                                          ).toLocaleDateString("default", {
                                            month: "short",
                                            year: "numeric",
                                          })}`
                                        : date.toLocaleDateString("default", {
                                            month: "short",
                                            year: "numeric",
                                          })}
                                    </div>
                                  ) : (
                                    <div className={Classes.TEXT_MUTED}>
                                      {date.getFullYear()}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      )}

                      {/* Heading level 2 */}
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {filteredDateRange.map((date, i, { length }) => {
                          const params = getHeadingLevel2Params(date);

                          return (
                            <div
                              key={date}
                              style={{
                                alignItems: "center",
                                background: lightMode
                                  ? Colors.WHITE
                                  : Colors.DARK_GRAY4,
                                borderBottom: `1px solid ${
                                  lightMode ? Colors.GRAY5 : Colors.GRAY1
                                }`,
                                borderLeft:
                                  i !== 0 &&
                                  `1px ${
                                    params.solidLeftBorder ? "solid" : "dotted"
                                  } ${lightMode ? Colors.GRAY5 : Colors.GRAY1}`,
                                borderRight:
                                  length - 1 === i &&
                                  `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                borderTop:
                                  params.showTopBorder &&
                                  `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                display: "flex",
                                justifyContent: "center",
                                maxHeight: params.height,
                                minHeight: params.height,
                                maxWidth:
                                  i === 0
                                    ? params.cellWidth + 5
                                    : params.cellWidth,
                                minWidth:
                                  i === 0
                                    ? params.cellWidth + 5
                                    : params.cellWidth,
                                padding:
                                  i === 0 ? "10px 10px 10px 15px" : "10px",
                              }}
                            >
                              {zoom === "day" ? (
                                <Text ellipsize>{date.getDate()}</Text>
                              ) : zoom === "month" ? (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: params.height,
                                    justifyContent: "space-between",
                                    padding: "15px",
                                  }}
                                >
                                  <div className={Classes.TEXT_MUTED}>
                                    {date.getFullYear()}
                                  </div>

                                  <div>
                                    <Text ellipsize>
                                      {date.toLocaleString("default", {
                                        month: "short",
                                      })}
                                    </Text>
                                  </div>
                                </div>
                              ) : zoom === "week" ? (
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: params.height,
                                    justifyContent: "space-between",
                                    padding: "15px",
                                  }}
                                >
                                  <div className={Classes.TEXT_MUTED}>
                                    {date.getFullYear()}
                                  </div>

                                  <div>
                                    <Text ellipsize>
                                      {date.getDate()}{" "}
                                      {date.toLocaleString("default", {
                                        month: "short",
                                      })}{" "}
                                      -{" "}
                                      {new Date(
                                        date.getTime() + 6 * 24 * 60 * 60 * 1000
                                      ).getDate()}{" "}
                                      {new Date(
                                        date.getTime() + 6 * 24 * 60 * 60 * 1000
                                      ).toLocaleString("default", {
                                        month: "short",
                                      })}
                                    </Text>
                                  </div>
                                </div>
                              ) : (
                                <Text ellipsize>
                                  {date.toLocaleString("default", {
                                    month: "narrow",
                                  })}
                                </Text>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </ScrollSyncPane>

                  {/* Items */}
                  <ScrollSyncPane group={["horizontal", "vertical"]}>
                    <div
                      style={{
                        maxHeight: height,
                        overflowY: "auto",
                      }}
                    >
                      {data.map((item) => {
                        return (
                          <div key={item.id}>
                            {/* Item */}
                            <div
                              onMouseEnter={() => setHighlightItemRow(item.id)}
                              onMouseLeave={() => setHighlightItemRow(null)}
                              style={{
                                display: "flex",
                              }}
                            >
                              {filteredDateRange.map((date, i, { length }) => {
                                const params = getTimelineParams(date, item);

                                return (
                                  <div
                                    key={date}
                                    style={{
                                      alignItems: "center",
                                      background:
                                        highlightItemRow === item.id
                                          ? lightMode
                                            ? Colors.LIGHT_GRAY3
                                            : Colors.DARK_GRAY5
                                          : params.background,
                                      borderBottom: `1px solid ${
                                        lightMode ? Colors.GRAY5 : Colors.GRAY1
                                      }`,
                                      borderLeft:
                                        i !== 0 &&
                                        `1px ${
                                          params.solidLeftBorder
                                            ? "solid"
                                            : "dotted"
                                        } ${
                                          lightMode
                                            ? Colors.GRAY5
                                            : Colors.GRAY1
                                        }`,
                                      borderRight:
                                        length - 1 === i &&
                                        `1px solid ${
                                          lightMode
                                            ? Colors.GRAY5
                                            : Colors.GRAY1
                                        }`,
                                      display: "flex",
                                      maxHeight: "45px",
                                      minHeight: "45px",
                                      maxWidth:
                                        i === 0
                                          ? params.cellWidth + 5
                                          : params.cellWidth,
                                      minWidth:
                                        i === 0
                                          ? params.cellWidth + 5
                                          : params.cellWidth,
                                      paddingLeft: i === 0 ? "5px" : undefined,
                                    }}
                                  >
                                    {params.startPeriod && (
                                      <div>
                                        <div
                                          onClick={() =>
                                            onClick && onClick(item.id, "item")
                                          }
                                          style={{
                                            alignItems: "center",
                                            background: setItemBackground(item),
                                            border: setBorderHighlight(item),
                                            color: setItemTextColor(item),
                                            cursor: onClick
                                              ? "pointer"
                                              : undefined,
                                            display: "flex",
                                            justifyContent:
                                              params.width >= textBreakoutWidth
                                                ? "center"
                                                : "start",
                                            marginLeft: params.marginLeft,
                                            maxHeight: "25px",
                                            minHeight: "25px",
                                            maxWidth: params.width,
                                            minWidth: params.width,
                                            position: "relative",
                                          }}
                                        >
                                          {!showLeftPane && (
                                            <Text
                                              ellipsize={
                                                params.width >=
                                                textBreakoutWidth
                                              }
                                              style={{
                                                color:
                                                  params.width <
                                                  textBreakoutWidth
                                                    ? lightMode
                                                      ? Colors.BLACK
                                                      : Colors.WHITE
                                                    : undefined,
                                                marginLeft:
                                                  params.width <
                                                  textBreakoutWidth
                                                    ? params.width + 10
                                                    : undefined,
                                                padding: "0 5px 0 5px",
                                                whiteSpace:
                                                  params.width <
                                                  textBreakoutWidth
                                                    ? "nowrap"
                                                    : undefined,
                                              }}
                                            >
                                              <strong>{item.name}</strong>
                                            </Text>
                                          )}
                                        </div>

                                        {item.progress > 0 && (
                                          <div
                                            style={{
                                              background: progressColor
                                                ? progressColor
                                                : defaultProgressColor,
                                              borderRadius: "10px",
                                              marginLeft: params.marginLeft,
                                              maxHeight: "5px",
                                              minHeight: "5px",
                                              position: "relative",
                                              width:
                                                (params.width * item.progress) /
                                                100,
                                            }}
                                          ></div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                            </div>

                            {/* Children and milestones */}
                            <Collapse isOpen={expanded[item.id]}>
                              {item.children.map((child) => {
                                return (
                                  <div
                                    key={child.id}
                                    onMouseEnter={() =>
                                      setHighlightChildRow(child.id)
                                    }
                                    onMouseLeave={() =>
                                      setHighlightChildRow(null)
                                    }
                                    style={{ display: "flex" }}
                                  >
                                    {filteredDateRange.map(
                                      (date, i, { length }) => {
                                        const params = getTimelineParams(
                                          date,
                                          child
                                        );

                                        return (
                                          <div
                                            key={date}
                                            style={{
                                              alignItems: "center",
                                              background:
                                                highlightChildRow === child.id
                                                  ? lightMode
                                                    ? Colors.LIGHT_GRAY3
                                                    : Colors.DARK_GRAY5
                                                  : params.background,
                                              borderBottom: `1px solid ${
                                                lightMode
                                                  ? Colors.GRAY5
                                                  : Colors.GRAY1
                                              }`,
                                              borderLeft:
                                                i !== 0 &&
                                                `1px ${
                                                  params.solidLeftBorder
                                                    ? "solid"
                                                    : "dotted"
                                                } ${
                                                  lightMode
                                                    ? Colors.GRAY5
                                                    : Colors.GRAY1
                                                }`,
                                              borderRight:
                                                length - 1 === i &&
                                                `1px solid ${
                                                  lightMode
                                                    ? Colors.GRAY5
                                                    : Colors.GRAY1
                                                }`,
                                              display: "flex",
                                              maxHeight: "45px",
                                              minHeight: "45px",
                                              maxWidth:
                                                i === 0
                                                  ? params.cellWidth + 5
                                                  : params.cellWidth,
                                              minWidth:
                                                i === 0
                                                  ? params.cellWidth + 5
                                                  : params.cellWidth,
                                              paddingLeft:
                                                i === 0 ? "5px" : undefined,
                                            }}
                                          >
                                            {params.startPeriod && (
                                              <div>
                                                <div
                                                  onClick={() =>
                                                    onClick &&
                                                    onClick(child.id, "child")
                                                  }
                                                  style={{
                                                    alignItems: "center",
                                                    background:
                                                      setChildBackground(),
                                                    border:
                                                      setBorderHighlight(child),
                                                    borderRadius: "10px",
                                                    color: setChildTextColor(),
                                                    cursor: onClick
                                                      ? "pointer"
                                                      : undefined,
                                                    display: "flex",
                                                    justifyContent:
                                                      params.width >=
                                                      textBreakoutWidth
                                                        ? "center"
                                                        : "start",
                                                    marginLeft:
                                                      params.marginLeft,
                                                    maxHeight: "20px",
                                                    minHeight: "20px",
                                                    maxWidth: params.width,
                                                    minWidth: params.width,
                                                    position: "relative",
                                                  }}
                                                >
                                                  {!showLeftPane && (
                                                    <Text
                                                      ellipsize={
                                                        params.width >=
                                                        textBreakoutWidth
                                                      }
                                                      style={{
                                                        color:
                                                          params.width <
                                                          textBreakoutWidth
                                                            ? lightMode
                                                              ? Colors.BLACK
                                                              : Colors.WHITE
                                                            : undefined,
                                                        marginLeft:
                                                          params.width <
                                                          textBreakoutWidth
                                                            ? params.width + 10
                                                            : undefined,
                                                        padding: "0 5px 0 5px",
                                                        whiteSpace:
                                                          params.width <
                                                          textBreakoutWidth
                                                            ? "nowrap"
                                                            : undefined,
                                                      }}
                                                    >
                                                      {child.name}
                                                    </Text>
                                                  )}
                                                </div>

                                                {child.progress > 0 && (
                                                  <div
                                                    style={{
                                                      background: progressColor
                                                        ? progressColor
                                                        : defaultProgressColor,
                                                      borderRadius: "10px",
                                                      marginLeft:
                                                        params.marginLeft,
                                                      maxHeight: "5px",
                                                      minHeight: "5px",
                                                      position: "relative",
                                                      width:
                                                        (params.width *
                                                          child.progress) /
                                                        100,
                                                    }}
                                                  ></div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              })}

                              {item.milestones.map((milestone) => {
                                return (
                                  <div
                                    key={milestone.id}
                                    onMouseEnter={() =>
                                      setHighlightMilestoneRow(milestone.id)
                                    }
                                    onMouseLeave={() =>
                                      setHighlightMilestoneRow(null)
                                    }
                                    style={{ display: "flex" }}
                                  >
                                    {filteredDateRange.map(
                                      (date, i, { length }) => {
                                        const params = getTimelineParams(
                                          date,
                                          milestone
                                        );

                                        return (
                                          <div
                                            key={date}
                                            style={{
                                              alignItems: "center",
                                              background:
                                                highlightMilestoneRow ===
                                                milestone.id
                                                  ? lightMode
                                                    ? Colors.LIGHT_GRAY3
                                                    : Colors.DARK_GRAY5
                                                  : params.background,
                                              borderBottom: `1px solid ${
                                                lightMode
                                                  ? Colors.GRAY5
                                                  : Colors.GRAY1
                                              }`,
                                              borderLeft:
                                                i !== 0 &&
                                                `1px ${
                                                  params.solidLeftBorder
                                                    ? "solid"
                                                    : "dotted"
                                                } ${
                                                  lightMode
                                                    ? Colors.GRAY5
                                                    : Colors.GRAY1
                                                }`,
                                              borderRight:
                                                length - 1 === i &&
                                                `1px solid ${
                                                  lightMode
                                                    ? Colors.GRAY5
                                                    : Colors.GRAY1
                                                }`,
                                              display: "flex",
                                              maxHeight: "45px",
                                              minHeight: "45px",
                                              maxWidth:
                                                i === 0
                                                  ? params.cellWidth + 5
                                                  : params.cellWidth,
                                              minWidth:
                                                i === 0
                                                  ? params.cellWidth + 5
                                                  : params.cellWidth,
                                              paddingLeft:
                                                i === 0 ? "5px" : undefined,
                                            }}
                                          >
                                            {params.startPeriod && (
                                              <div>
                                                <div
                                                  onClick={() =>
                                                    onClick &&
                                                    onClick(
                                                      milestone.id,
                                                      "milestone"
                                                    )
                                                  }
                                                  style={{
                                                    alignItems: "center",
                                                    display: "flex",
                                                    justifyContent: "start",
                                                    marginLeft:
                                                      params.marginLeft,
                                                    position: "relative",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      background:
                                                        setMilestoneBackground(),
                                                      clipPath:
                                                        "polygon(0 50%, 50% 0, 100% 50%, 50% 100%, 0 50%",
                                                      cursor: onClick
                                                        ? "pointer"
                                                        : undefined,
                                                      marginLeft:
                                                        params.width - 10 > 0
                                                          ? params.width - 10
                                                          : 0,
                                                      maxHeight: "20px",
                                                      minHeight: "20px",
                                                      maxWidth: "20px",
                                                      minWidth: "20px",
                                                    }}
                                                  ></div>

                                                  {!showLeftPane && (
                                                    <Text
                                                      style={{
                                                        color: lightMode
                                                          ? Colors.BLACK
                                                          : Colors.WHITE,
                                                        marginLeft: "10px",
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    >
                                                      {milestone.name}
                                                    </Text>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                );
                              })}
                            </Collapse>
                          </div>
                        );
                      })}

                      {resourceData && (
                        <Collapse isOpen={showResources}>
                          {resourceData.map((resource, resourceIndex) => {
                            return (
                              <div
                                key={resource.id}
                                onMouseEnter={() =>
                                  setHighlightChildRow(resource.id)
                                }
                                onMouseLeave={() => setHighlightChildRow(null)}
                                style={{
                                  display: "flex",
                                  maxHeight:
                                    resourceIndex === 0 ? "48px" : "45px",
                                  minHeight:
                                    resourceIndex === 0 ? "48px" : "45px",
                                }}
                              >
                                {filteredDateRange.map(
                                  (date, dateIndex, { length }) => {
                                    const params = getTimelineParams(
                                      date,
                                      resource
                                    );

                                    return (
                                      <div
                                        key={date}
                                        style={{
                                          alignItems: "center",
                                          background:
                                            highlightChildRow === resource.id
                                              ? lightMode
                                                ? Colors.LIGHT_GRAY3
                                                : Colors.DARK_GRAY5
                                              : params.background,
                                          borderBottom: `1px solid ${
                                            lightMode
                                              ? Colors.GRAY5
                                              : Colors.GRAY1
                                          }`,
                                          borderLeft:
                                            dateIndex !== 0 &&
                                            `1px ${
                                              params.solidLeftBorder
                                                ? "solid"
                                                : "dotted"
                                            } ${
                                              lightMode
                                                ? Colors.GRAY5
                                                : Colors.GRAY1
                                            }`,
                                          borderRight:
                                            length - 1 === dateIndex &&
                                            `1px solid ${
                                              lightMode
                                                ? Colors.GRAY5
                                                : Colors.GRAY1
                                            }`,
                                          borderTop:
                                            resourceIndex === 0 &&
                                            `3px solid ${
                                              lightMode
                                                ? Colors.GRAY5
                                                : Colors.GRAY1
                                            }`,
                                          display: "flex",
                                          maxWidth:
                                            dateIndex === 0
                                              ? params.cellWidth + 5
                                              : params.cellWidth,
                                          minWidth:
                                            dateIndex === 0
                                              ? params.cellWidth + 5
                                              : params.cellWidth,
                                          paddingLeft:
                                            dateIndex === 0 ? "5px" : undefined,
                                        }}
                                      >
                                        {params.startPeriod && (
                                          <div
                                            onClick={() =>
                                              onClick &&
                                              onClick(resource.id, "resource")
                                            }
                                            style={{
                                              alignItems: "center",
                                              background:
                                                setResourceBackground(),
                                              border:
                                                setBorderHighlight(resource),
                                              borderRadius: "10px",
                                              color: setResourceTextColor(),
                                              cursor: onClick
                                                ? "pointer"
                                                : undefined,
                                              display: "flex",
                                              justifyContent:
                                                params.width >=
                                                textBreakoutWidth
                                                  ? "center"
                                                  : "start",
                                              marginLeft: params.marginLeft,
                                              maxHeight: "20px",
                                              minHeight: "20px",
                                              maxWidth: params.width,
                                              minWidth: params.width,
                                              position: "relative",
                                            }}
                                          >
                                            {!showLeftPane && (
                                              <div
                                                style={{
                                                  alignItems: "center",
                                                  display: "flex",
                                                }}
                                              >
                                                <img
                                                  alt="profile"
                                                  src={
                                                    resource.photo
                                                      ? resource.photo
                                                      : "/images/no_photo.png"
                                                  }
                                                  style={{
                                                    background: "white",
                                                    border: `1px solid ${
                                                      lightMode
                                                        ? Colors.LIGHT_GRAY5
                                                        : Colors.DARK_GRAY5
                                                    }`,
                                                    borderRadius: "50%",
                                                    height: "30px",
                                                    marginLeft:
                                                      params.width <
                                                      textBreakoutWidth
                                                        ? params.width + 10
                                                        : undefined,
                                                    objectFit: "contain",
                                                    width: "30px",
                                                  }}
                                                />

                                                <Text
                                                  ellipsize={
                                                    params.width >=
                                                    textBreakoutWidth
                                                  }
                                                  style={{
                                                    color:
                                                      params.width <
                                                      textBreakoutWidth
                                                        ? lightMode
                                                          ? Colors.BLACK
                                                          : Colors.WHITE
                                                        : undefined,
                                                    padding: "0 5px 0 5px",
                                                    whiteSpace:
                                                      params.width <
                                                      textBreakoutWidth
                                                        ? "nowrap"
                                                        : undefined,
                                                  }}
                                                >
                                                  {resource.name
                                                    ? resource.name
                                                    : resource.role}
                                                </Text>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            );
                          })}
                        </Collapse>
                      )}
                    </div>
                  </ScrollSyncPane>
                </div>
              </div>
            </ScrollSync>
          </div>
        </div>
      )}

      {/* Loading */}
      {data.length === 0 && loading && (
        <div className={Classes.SKELETON} style={{ width: "100%" }}>
          XXXXXXXXXX
        </div>
      )}

      {/* No results */}
      {data.length === 0 && !loading && (
        <div style={{ padding: "10px 0 10px 0" }}>
          <NonIdealState
            description={noResultsMessage ? noResultsMessage : "Add items."}
            icon={noResultsIcon ? noResultsIcon : "search"}
            title={noResultsTitle ? noResultsTitle : "No items found"}
          />
        </div>
      )}
    </div>
  );
}

export default Timeline4;
