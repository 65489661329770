// Library imports
import { Button, Colors, H2 } from "@blueprintjs/core";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Redux action imports
import { logoutAction } from "../actions/userActions";

// Main function
function SmallWindowWarning() {
  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // JSX UI code
  return (
    <div
      style={{
        alignItems: "center",
        background: Colors.DARK_GRAY3,
        display: "flex",
        height: "100vh",
        padding: "0 20px 0 20px",
        textAlign: "center",
        width: "100vw",
      }}
    >
      <div style={{ width: "100%" }}>
        <img
          alt="logo"
          src="/images/logo.svg"
          style={{
            margin: "0 0 20px 0",
            maxHeight: "50vh",
            maxWidth: "90vw",
          }}
        />

        <H2 style={{ color: Colors.WHITE, margin: "0 0 20px 0" }}>
          advaise.app is a data-rich application designed for laptop or tablet
          screens.
        </H2>

        <Button
          icon="log-out"
          intent="primary"
          large
          onClick={() => {
            dispatch(logoutAction());

            navigate("/login");
          }}
          text="Logout"
        />
      </div>
    </div>
  );
}

export default SmallWindowWarning;
