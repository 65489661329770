// Library imports
import { InputGroup, Spinner } from "@blueprintjs/core";
import React from "react";

// Main function
function SearchField({
  disabled,
  placeholder,
  searching,
  setValueObject,
  setValueVariable,
  value,
}) {
  return (
    <InputGroup
      disabled={disabled}
      leftIcon="search"
      onChange={(e) => {
        setValueObject((prevState) => {
          return {
            ...prevState,
            page: 1,
            [setValueVariable]: e.target.value,
          };
        });
      }}
      placeholder={placeholder}
      rightElement={searching ? <Spinner size={15} /> : undefined}
      type="search"
      value={value}
    />
  );
}

export default SearchField;
