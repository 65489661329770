// Library imports
import { Colors } from "@blueprintjs/core";

// Set color
const setColor = (category) => {
  switch (category) {
    case "Delivery":
      return Colors.LIME3;

    case "Opportunity":
      return Colors.TURQUOISE3;

    case "Planning":
      return Colors.INDIGO3;

    case "Relationship":
      return Colors.ROSE3;

    case "Team":
      return Colors.CERULEAN3;

    default:
      return Colors.BLUE3;
  }
};

// Main function
const generateKanbanDataFromClientActions = (actions) => {
  if (actions) {
    let arr = [];

    actions.forEach((action) => {
      arr.push({
        action: action.action,
        category: action.category,
        comments: action.comments,
        color: setColor(action.category),
        due: action.due,
        id: `${action.id}`,
        name: action.name,
        owner: action.owner_name,
        ownerPhoto: action.owner_profile_photo,
        status: action.status,
      });
    });

    return arr;
  }

  return [];
};

export default generateKanbanDataFromClientActions;
