// Constant imports
import {
  STAFF_GOAL_LIST_REQUEST,
  STAFF_GOAL_LIST_SUCCESS,
  STAFF_GOAL_LIST_FAIL,
  STAFF_GOAL_LIST_RESET,
  STAFF_GOAL_CREATE_REQUEST,
  STAFF_GOAL_CREATE_SUCCESS,
  STAFF_GOAL_CREATE_FAIL,
  STAFF_GOAL_CREATE_RESET,
  STAFF_GOAL_DETAILS_REQUEST,
  STAFF_GOAL_DETAILS_SUCCESS,
  STAFF_GOAL_DETAILS_FAIL,
  STAFF_GOAL_DETAILS_RESET,
  STAFF_GOAL_UPDATE_REQUEST,
  STAFF_GOAL_UPDATE_SUCCESS,
  STAFF_GOAL_UPDATE_FAIL,
  STAFF_GOAL_UPDATE_RESET,
  STAFF_GOAL_DELETE_REQUEST,
  STAFF_GOAL_DELETE_SUCCESS,
  STAFF_GOAL_DELETE_FAIL,
  STAFF_GOAL_DELETE_RESET,
} from "../constants/staffGoalConstants";

// List all
export const staffGoalListReducer = (
  state = {
    loading: false,
    count: 0,
    staff_goals: [],
    numberOfGoalsExceeded: 0,
    numberOfGoalsMet: 0,
    numberOfGoalsNotMet: 0,
    numberOfGoalsNotRated: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_GOAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STAFF_GOAL_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        staff_goals: action.payload.staff_goals,
        numberOfGoalsExceeded: action.payload.numberOfGoalsExceeded,
        numberOfGoalsMet: action.payload.numberOfGoalsMet,
        numberOfGoalsNotMet: action.payload.numberOfGoalsNotMet,
        numberOfGoalsNotRated: action.payload.numberOfGoalsNotRated,
        success: true,
        error: "",
      };

    case STAFF_GOAL_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        staff_goals: [],
        numberOfGoalsExceeded: 0,
        numberOfGoalsMet: 0,
        numberOfGoalsNotMet: 0,
        numberOfGoalsNotRated: 0,
        success: false,
        error: action.payload,
      };

    case STAFF_GOAL_LIST_RESET:
      return {
        loading: false,
        count: 0,
        staff_goals: [],
        numberOfGoalsExceeded: 0,
        numberOfGoalsMet: 0,
        numberOfGoalsNotMet: 0,
        numberOfGoalsNotRated: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const staffGoalCreateReducer = (
  state = {
    loading: false,
    staff_goal: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_GOAL_CREATE_REQUEST:
      return {
        loading: true,
        staff_goal: state.staff_goal,
        success: false,
        error: "",
      };

    case STAFF_GOAL_CREATE_SUCCESS:
      return {
        loading: false,
        staff_goal: action.payload,
        success: true,
        error: "",
      };

    case STAFF_GOAL_CREATE_FAIL:
      return {
        loading: false,
        staff_goal: state.staff_goal,
        success: false,
        error: action.payload,
      };

    case STAFF_GOAL_CREATE_RESET:
      return {
        loading: false,
        staff_goal: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const staffGoalDetailsReducer = (
  state = {
    loading: false,
    staff_goal: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_GOAL_DETAILS_REQUEST:
      return {
        loading: true,
        staff_goal: state.staff_goal,
        success: false,
        error: "",
      };

    case STAFF_GOAL_DETAILS_SUCCESS:
      return {
        loading: false,
        staff_goal: action.payload,
        success: true,
        error: "",
      };

    case STAFF_GOAL_DETAILS_FAIL:
      return {
        loading: false,
        staff_goal: state.staff_goal,
        success: false,
        error: action.payload,
      };

    case STAFF_GOAL_DETAILS_RESET:
      return {
        loading: false,
        staff_goal: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const staffGoalUpdateReducer = (
  state = {
    loading: false,
    staff_goal: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_GOAL_UPDATE_REQUEST:
      return {
        loading: true,
        staff_goal: state.staff_goal,
        success: false,
        error: "",
      };

    case STAFF_GOAL_UPDATE_SUCCESS:
      return {
        loading: false,
        staff_goal: action.payload,
        success: true,
        error: "",
      };

    case STAFF_GOAL_UPDATE_FAIL:
      return {
        loading: false,
        staff_goal: state.staff_goal,
        success: false,
        error: action.payload,
      };

    case STAFF_GOAL_UPDATE_RESET:
      return {
        loading: false,
        staff_goal: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const staffGoalDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_GOAL_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case STAFF_GOAL_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case STAFF_GOAL_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case STAFF_GOAL_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
