// Constant imports
import {
  ORGANISATION_LIST_REQUEST,
  ORGANISATION_LIST_SUCCESS,
  ORGANISATION_LIST_FAIL,
  ORGANISATION_LIST_RESET,
  ORGANISATION_CREATE_REQUEST,
  ORGANISATION_CREATE_SUCCESS,
  ORGANISATION_CREATE_FAIL,
  ORGANISATION_CREATE_RESET,
  ORGANISATION_DETAILS_REQUEST,
  ORGANISATION_DETAILS_SUCCESS,
  ORGANISATION_DETAILS_FAIL,
  ORGANISATION_DETAILS_RESET,
  ORGANISATION_UPDATE_REQUEST,
  ORGANISATION_UPDATE_SUCCESS,
  ORGANISATION_UPDATE_FAIL,
  ORGANISATION_UPDATE_RESET,
  ORGANISATION_DELETE_REQUEST,
  ORGANISATION_DELETE_SUCCESS,
  ORGANISATION_DELETE_FAIL,
  ORGANISATION_DELETE_RESET,
  ORGANISATION_SET_ACTIVE,
  ORGANISATION_SET_NONE,
  ORGANISATION_CHECK_PERMISSIONS_REQUEST,
  ORGANISATION_CHECK_PERMISSIONS_SUCCESS,
  ORGANISATION_CHECK_PERMISSIONS_FAIL,
  ORGANISATION_CHECK_PERMISSIONS_RESET,
  ORGANISATION_CHECK_SUBSCRIPTION_REQUEST,
  ORGANISATION_CHECK_SUBSCRIPTION_SUCCESS,
  ORGANISATION_CHECK_SUBSCRIPTION_FAIL,
  ORGANISATION_CHECK_SUBSCRIPTION_RESET,
} from "../constants/organisationConstants";

// List all
export const organisationListReducer = (
  state = {
    loading: false,
    count: 0,
    organisations: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ORGANISATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORGANISATION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        organisations: action.payload.organisations,
        success: true,
        error: "",
      };

    case ORGANISATION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        organisations: [],
        success: false,
        error: action.payload,
      };

    case ORGANISATION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        organisations: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const organisationCreateReducer = (
  state = { loading: false, organisation: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_CREATE_REQUEST:
      return {
        loading: true,
        organisation: state.organisation,
        success: false,
        error: "",
      };

    case ORGANISATION_CREATE_SUCCESS:
      return {
        loading: false,
        organisation: action.payload,
        success: true,
        error: "",
      };

    case ORGANISATION_CREATE_FAIL:
      return {
        loading: false,
        organisation: state.organisation,
        success: false,
        error: action.payload,
      };

    case ORGANISATION_CREATE_RESET:
      return { loading: false, organisation: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const organisationDetailsReducer = (
  state = { loading: false, organisation: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_DETAILS_REQUEST:
      return {
        loading: true,
        organisation: state.organisation,
        success: false,
        error: "",
      };

    case ORGANISATION_DETAILS_SUCCESS:
      return {
        loading: false,
        organisation: action.payload,
        success: true,
        error: "",
      };

    case ORGANISATION_DETAILS_FAIL:
      return {
        loading: false,
        organisation: state.organisation,
        success: false,
        error: action.payload,
      };

    case ORGANISATION_DETAILS_RESET:
      return { loading: false, organisation: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const organisationUpdateReducer = (
  state = { loading: false, organisation: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_UPDATE_REQUEST:
      return {
        loading: true,
        organisation: state.organisation,
        success: false,
        error: "",
      };

    case ORGANISATION_UPDATE_SUCCESS:
      return {
        loading: false,
        organisation: action.payload,
        success: true,
        error: "",
      };

    case ORGANISATION_UPDATE_FAIL:
      return {
        loading: false,
        organisation: state.organisation,
        success: false,
        error: action.payload,
      };

    case ORGANISATION_UPDATE_RESET:
      return { loading: false, organisation: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const organisationDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ORGANISATION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ORGANISATION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ORGANISATION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Set active
export const activeOrganisationReducer = (
  state = { currency: "", id: "", name: "", owner: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_SET_ACTIVE:
      return {
        currency: action.payload.currency_short,
        id: action.payload.id,
        name: action.payload.name,
        owner: action.payload.owner,
      };

    case ORGANISATION_SET_NONE:
      return {
        currency: "",
        id: "",
        name: "",
        owner: "",
      };

    default:
      return state;
  }
};

// Check permissions
export const checkPermissionsReducer = (
  state = { loading: false, permissions: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_CHECK_PERMISSIONS_REQUEST:
      return {
        loading: true,
        permissions: state.permissions,
        success: false,
        error: "",
      };

    case ORGANISATION_CHECK_PERMISSIONS_SUCCESS:
      return {
        loading: false,
        permissions: action.payload,
        success: true,
        error: "",
      };

    case ORGANISATION_CHECK_PERMISSIONS_FAIL:
      return {
        loading: false,
        permissions: state.permissions,
        success: false,
        error: action.payload,
      };

    case ORGANISATION_CHECK_PERMISSIONS_RESET:
      return { loading: false, permissions: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Check subscription
export const checkSubscriptionReducer = (
  state = { loading: false, subscription: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ORGANISATION_CHECK_SUBSCRIPTION_REQUEST:
      return {
        loading: true,
        subscription: state.subscription,
        success: false,
        error: "",
      };

    case ORGANISATION_CHECK_SUBSCRIPTION_SUCCESS:
      return {
        loading: false,
        subscription: action.payload,
        success: true,
        error: "",
      };

    case ORGANISATION_CHECK_SUBSCRIPTION_FAIL:
      return {
        loading: false,
        subscription: state.subscription,
        success: false,
        error: action.payload,
      };

    case ORGANISATION_CHECK_SUBSCRIPTION_RESET:
      return { loading: false, subscription: {}, success: false, error: "" };

    default:
      return state;
  }
};
