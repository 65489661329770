// Library imports
import { Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TableEmail({ email }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ maxWidth: "calc(100% - 50px)" }}>
      {email ? (
        <a
          href={`mailto:${email}`}
          style={{
            color: lightMode ? "#1891AC" : "#D2ECF9",
          }}
        >
          <Text ellipsize>{email}</Text>
        </a>
      ) : (
        "-"
      )}
    </div>
  );
}

export default TableEmail;
