export const MANAGER_LIST_REQUEST = "MANAGER_LIST_REQUEST";
export const MANAGER_LIST_SUCCESS = "MANAGER_LIST_SUCCESS";
export const MANAGER_LIST_FAIL = "MANAGER_LIST_FAIL";
export const MANAGER_LIST_RESET = "MANAGER_LIST_RESET";

export const MANAGER_DETAILS_REQUEST = "MANAGER_DETAILS_REQUEST";
export const MANAGER_DETAILS_SUCCESS = "MANAGER_DETAILS_SUCCESS";
export const MANAGER_DETAILS_FAIL = "MANAGER_DETAILS_FAIL";
export const MANAGER_DETAILS_RESET = "MANAGER_DETAILS_RESET";

export const MANAGER_UPDATE_REQUEST = "MANAGER_UPDATE_REQUEST";
export const MANAGER_UPDATE_SUCCESS = "MANAGER_UPDATE_SUCCESS";
export const MANAGER_UPDATE_FAIL = "MANAGER_UPDATE_FAIL";
export const MANAGER_UPDATE_RESET = "MANAGER_UPDATE_RESET";

export const MANAGER_DOWNLOAD_REQUEST = "MANAGER_DOWNLOAD_REQUEST";
export const MANAGER_DOWNLOAD_SUCCESS = "MANAGER_DOWNLOAD_SUCCESS";
export const MANAGER_DOWNLOAD_FAIL = "MANAGER_DOWNLOAD_FAIL";
export const MANAGER_DOWNLOAD_RESET = "MANAGER_DOWNLOAD_RESET";
