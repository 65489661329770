// Constant imports
import {
  CONTACT_ACTION_LIST_REQUEST,
  CONTACT_ACTION_LIST_SUCCESS,
  CONTACT_ACTION_LIST_FAIL,
  CONTACT_ACTION_LIST_RESET,
  CONTACT_ACTION_CREATE_REQUEST,
  CONTACT_ACTION_CREATE_SUCCESS,
  CONTACT_ACTION_CREATE_FAIL,
  CONTACT_ACTION_CREATE_RESET,
  CONTACT_ACTION_DETAILS_REQUEST,
  CONTACT_ACTION_DETAILS_SUCCESS,
  CONTACT_ACTION_DETAILS_FAIL,
  CONTACT_ACTION_DETAILS_RESET,
  CONTACT_ACTION_UPDATE_REQUEST,
  CONTACT_ACTION_UPDATE_SUCCESS,
  CONTACT_ACTION_UPDATE_FAIL,
  CONTACT_ACTION_UPDATE_RESET,
  CONTACT_ACTION_DELETE_REQUEST,
  CONTACT_ACTION_DELETE_SUCCESS,
  CONTACT_ACTION_DELETE_FAIL,
  CONTACT_ACTION_DELETE_RESET,
} from "../constants/contactActionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listContactActionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_ACTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getContactActions(params, token);

      dispatch({
        type: CONTACT_ACTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfActionsCompleted: data.number_of_actions_completed,
          numberOfActionsInProgress: data.number_of_actions_in_progress,
          numberOfActionsOnHold: data.number_of_actions_on_hold,
          contact_actions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CONTACT_ACTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListContactActionsAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_ACTION_LIST_RESET });
};

// Create
export const createContactActionAction =
  (contact_action) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_ACTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createContactAction(
        token,
        contact_action
      );

      dispatch({
        type: CONTACT_ACTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_ACTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateContactActionAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_ACTION_CREATE_RESET });
};

// List details
export const listContactActionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_ACTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getContactAction(id, token);

      dispatch({
        type: CONTACT_ACTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_ACTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListContactActionDetailsAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_ACTION_DETAILS_RESET });
};

// Update
export const updateContactActionAction =
  (id, contact_action) => async (dispatch, getState) => {
    try {
      dispatch({ type: CONTACT_ACTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateContactAction(
        id,
        token,
        contact_action
      );

      dispatch({
        type: CONTACT_ACTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CONTACT_ACTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateContactActionAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_ACTION_UPDATE_RESET });
};

// Delete
export const deleteContactActionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTACT_ACTION_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteContactAction(id, token);

    dispatch({
      type: CONTACT_ACTION_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTACT_ACTION_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteContactActionAction = () => async (dispatch) => {
  dispatch({ type: CONTACT_ACTION_DELETE_RESET });
};
