// Library imports
import { Card, Classes, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import NumericValue from "../components/NumericValue";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldLink from "../components/TabSectionFieldLink";

// Main function
function StaffExpenseFacts({ facts }) {
  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <Card>
      <TabSectionField label="Claim id" value={facts.claim_id} vertical />

      <TabSectionField label="Claim name" value={facts.claim_name} vertical />

      <TabSectionField
        helperText={
          facts.receipts ? (
            <TabSectionFieldLink name="Download" url={facts.receipts} />
          ) : undefined
        }
        label="Receipts provided"
        value={
          <Tag
            intent={facts.receipts_provided === "No" ? "danger" : "success"}
            round
            style={{ textAlign: "center", width: "75px" }}
          >
            {facts.receipts_provided}
          </Tag>
        }
        vertical
      />

      <TabSectionField
        bottom
        label="Expenses"
        value={
          facts.expenses.length > 0 ? (
            facts.expenses.map((expense) => {
              return (
                <TabSectionField
                  bottom
                  key={expense.id}
                  value={
                    <TabSectionFieldCard
                      footer={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>{expense.booking_code_name}</div>

                          <div>{expense.booking_code_code}</div>
                        </div>
                      }
                      tag={
                        <NumericValue
                          currency={activeOrganisation.currency}
                          noTag
                          number={expense.value}
                        />
                      }
                      tagIntent="success"
                      text={
                        expense.comments ? (
                          expense.comments
                        ) : (
                          <span className={Classes.TEXT_MUTED}>
                            No comments.
                          </span>
                        )
                      }
                      title={expense.category}
                    />
                  }
                  vertical
                />
              );
            })
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />
    </Card>
  );
}

export default StaffExpenseFacts;
