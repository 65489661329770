// Constant imports
import {
  PROPOSAL_PERMISSION_LIST_REQUEST,
  PROPOSAL_PERMISSION_LIST_SUCCESS,
  PROPOSAL_PERMISSION_LIST_FAIL,
  PROPOSAL_PERMISSION_LIST_RESET,
  PROPOSAL_PERMISSION_CREATE_REQUEST,
  PROPOSAL_PERMISSION_CREATE_SUCCESS,
  PROPOSAL_PERMISSION_CREATE_FAIL,
  PROPOSAL_PERMISSION_CREATE_RESET,
  PROPOSAL_PERMISSION_DETAILS_REQUEST,
  PROPOSAL_PERMISSION_DETAILS_SUCCESS,
  PROPOSAL_PERMISSION_DETAILS_FAIL,
  PROPOSAL_PERMISSION_DETAILS_RESET,
  PROPOSAL_PERMISSION_UPDATE_REQUEST,
  PROPOSAL_PERMISSION_UPDATE_SUCCESS,
  PROPOSAL_PERMISSION_UPDATE_FAIL,
  PROPOSAL_PERMISSION_UPDATE_RESET,
  PROPOSAL_PERMISSION_DELETE_REQUEST,
  PROPOSAL_PERMISSION_DELETE_SUCCESS,
  PROPOSAL_PERMISSION_DELETE_FAIL,
  PROPOSAL_PERMISSION_DELETE_RESET,
} from "../constants/proposalPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalPermissions(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalPermissionsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_PERMISSION_LIST_RESET });
};

// Create
export const createProposalPermissionAction =
  (proposal_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalPermission(
        token,
        proposal_permission
      );

      dispatch({
        type: PROPOSAL_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalPermissionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_PERMISSION_CREATE_RESET });
};

// List details
export const listProposalPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalPermission(id, token);

      dispatch({
        type: PROPOSAL_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateProposalPermissionAction =
  (id, proposal_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalPermission(
        id,
        token,
        proposal_permission
      );

      dispatch({
        type: PROPOSAL_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalPermissionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteProposalPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalPermission(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalPermissionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_PERMISSION_DELETE_RESET });
};
