export const PROPOSAL_LIST_REQUEST = "PROPOSAL_LIST_REQUEST";
export const PROPOSAL_LIST_SUCCESS = "PROPOSAL_LIST_SUCCESS";
export const PROPOSAL_LIST_FAIL = "PROPOSAL_LIST_FAIL";
export const PROPOSAL_LIST_RESET = "PROPOSAL_LIST_RESET";

export const PROPOSAL_CREATE_REQUEST = "PROPOSAL_CREATE_REQUEST";
export const PROPOSAL_CREATE_SUCCESS = "PROPOSAL_CREATE_SUCCESS";
export const PROPOSAL_CREATE_FAIL = "PROPOSAL_CREATE_FAIL";
export const PROPOSAL_CREATE_RESET = "PROPOSAL_CREATE_RESET";

export const PROPOSAL_DETAILS_REQUEST = "PROPOSAL_DETAILS_REQUEST";
export const PROPOSAL_DETAILS_SUCCESS = "PROPOSAL_DETAILS_SUCCESS";
export const PROPOSAL_DETAILS_FAIL = "PROPOSAL_DETAILS_FAIL";
export const PROPOSAL_DETAILS_RESET = "PROPOSAL_DETAILS_RESET";

export const PROPOSAL_UPDATE_REQUEST = "PROPOSAL_UPDATE_REQUEST";
export const PROPOSAL_UPDATE_SUCCESS = "PROPOSAL_UPDATE_SUCCESS";
export const PROPOSAL_UPDATE_FAIL = "PROPOSAL_UPDATE_FAIL";
export const PROPOSAL_UPDATE_RESET = "PROPOSAL_UPDATE_RESET";

export const PROPOSAL_DELETE_REQUEST = "PROPOSAL_DELETE_REQUEST";
export const PROPOSAL_DELETE_SUCCESS = "PROPOSAL_DELETE_SUCCESS";
export const PROPOSAL_DELETE_FAIL = "PROPOSAL_DELETE_FAIL";
export const PROPOSAL_DELETE_RESET = "PROPOSAL_DELETE_RESET";

export const PROPOSAL_PREDICT_WIN_REQUEST = "PROPOSAL_PREDICT_WIN_REQUEST";
export const PROPOSAL_PREDICT_WIN_SUCCESS = "PROPOSAL_PREDICT_WIN_SUCCESS";
export const PROPOSAL_PREDICT_WIN_FAIL = "PROPOSAL_PREDICT_WIN_FAIL";
export const PROPOSAL_PREDICT_WIN_RESET = "PROPOSAL_PREDICT_WIN_RESET";

export const PROPOSAL_DOWNLOAD_REQUEST = "PROPOSAL_DOWNLOAD_REQUEST";
export const PROPOSAL_DOWNLOAD_SUCCESS = "PROPOSAL_DOWNLOAD_SUCCESS";
export const PROPOSAL_DOWNLOAD_FAIL = "PROPOSAL_DOWNLOAD_FAIL";
export const PROPOSAL_DOWNLOAD_RESET = "PROPOSAL_DOWNLOAD_RESET";
