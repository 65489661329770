// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardHorizontalBarChart from "../components/DataCardHorizontalBarChart";

// Main function
function ClientEngagementActualsCard({ fill, clientList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardHorizontalBarChart
      comments={
        <>
          <H5>Engagement actuals</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Realised revenue and profit margin from engagements with the
            selected client(s).
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Revenue",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: clientList.totalEngagementRevenue,
        },
        {
          name: "Margin",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: clientList.totalEngagementMargin,
        },
      ]}
      fill={fill}
      noData={
        !clientList.totalEngagementMargin && !clientList.totalEngagementRevenue
      }
      title="Engagement actuals"
      units={activeOrganisation.currency}
    />
  );
}

export default ClientEngagementActualsCard;
