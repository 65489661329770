// Library imports
import { Card, Classes, Colors, Tag, Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function MemberCard({ interactive, membership, onClick }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        alignItems: "center",
        borderTop: `5px solid ${
          membership.owner
            ? Colors.GREEN3
            : membership.admin
            ? Colors.BLUE3
            : Colors.GRAY1
        }`,
        display: "flex",
        height: "200px",
        margin: "5px",
        padding: "10px",
        textAlign: "center",
        width: "200px",
      }}
    >
      <div style={{ textAlign: "center", width: "100%" }}>
        <img
          alt="profile"
          src={
            membership.member_profile_photo
              ? membership.member_profile_photo
              : "/images/no_photo.png"
          }
          style={{
            background: "white",
            border: `1px solid ${
              lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
            }`,
            borderRadius: "50%",
            height: "110px",
            margin: "10px 0 10px 0",
            objectFit: "contain",
            width: "110px",
          }}
        />

        <div>
          <Text className={Classes.TEXT_SMALL} ellipsize>
            {membership.member_email}
          </Text>
        </div>

        <div style={{ margin: "10px 0 10px 0" }}>
          {membership.owner ? (
            <Tag
              intent="success"
              minimal
              round
              style={{
                textAlign: "center",
                width: "125px",
              }}
            >
              <span className={Classes.TEXT_SMALL}>Owner</span>
            </Tag>
          ) : membership.admin ? (
            <Tag
              intent="primary"
              minimal
              round
              style={{
                textAlign: "center",
                width: "125px",
              }}
            >
              <span className={Classes.TEXT_SMALL}>Admin</span>
            </Tag>
          ) : (
            <Tag
              minimal
              round
              style={{
                textAlign: "center",
                width: "125px",
              }}
            >
              <span className={Classes.TEXT_SMALL}>Member</span>
            </Tag>
          )}
        </div>
      </div>
    </Card>
  );
}

export default MemberCard;
