// Main function
const numberFormatter = (number) => {
  if (Math.abs(number) >= 1000 && Math.abs(number) < 1000000) {
    return (
      (number / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 }) +
      "K"
    );
  } else if (Math.abs(number) >= 1000000) {
    return (
      (number / 1000000).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }) + "M"
    );
  } else if (Math.abs(number) < 1000) {
    return number.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }
};

export default numberFormatter;
