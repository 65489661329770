// Constant imports
import {
  ENGAGEMENT_UPLOAD_LIST_REQUEST,
  ENGAGEMENT_UPLOAD_LIST_SUCCESS,
  ENGAGEMENT_UPLOAD_LIST_FAIL,
  ENGAGEMENT_UPLOAD_LIST_RESET,
  ENGAGEMENT_UPLOAD_CREATE_REQUEST,
  ENGAGEMENT_UPLOAD_CREATE_SUCCESS,
  ENGAGEMENT_UPLOAD_CREATE_FAIL,
  ENGAGEMENT_UPLOAD_CREATE_RESET,
  ENGAGEMENT_UPLOAD_DETAILS_REQUEST,
  ENGAGEMENT_UPLOAD_DETAILS_SUCCESS,
  ENGAGEMENT_UPLOAD_DETAILS_FAIL,
  ENGAGEMENT_UPLOAD_DETAILS_RESET,
  ENGAGEMENT_UPLOAD_UPDATE_REQUEST,
  ENGAGEMENT_UPLOAD_UPDATE_SUCCESS,
  ENGAGEMENT_UPLOAD_UPDATE_FAIL,
  ENGAGEMENT_UPLOAD_UPDATE_RESET,
  ENGAGEMENT_UPLOAD_DELETE_REQUEST,
  ENGAGEMENT_UPLOAD_DELETE_SUCCESS,
  ENGAGEMENT_UPLOAD_DELETE_FAIL,
  ENGAGEMENT_UPLOAD_DELETE_RESET,
} from "../constants/engagementUploadConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementUploadsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_UPLOAD_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementUploads(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_UPLOAD_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_uploads: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_UPLOAD_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementUploadsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_UPLOAD_LIST_RESET });
};

// Create
export const createEngagementUploadAction =
  (engagement_upload) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_UPLOAD_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementUpload(
        token,
        engagement_upload
      );

      dispatch({
        type: ENGAGEMENT_UPLOAD_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_UPLOAD_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementUploadAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_UPLOAD_CREATE_RESET });
};

// List details
export const listEngagementUploadDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_UPLOAD_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementUpload(id, token);

      dispatch({
        type: ENGAGEMENT_UPLOAD_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_UPLOAD_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementUploadDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_UPLOAD_DETAILS_RESET });
  };

// Update
export const updateEngagementUploadAction =
  (id, engagement_upload) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_UPLOAD_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementUpload(
        id,
        token,
        engagement_upload
      );

      dispatch({
        type: ENGAGEMENT_UPLOAD_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_UPLOAD_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementUploadAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_UPLOAD_UPDATE_RESET });
};

// Delete
export const deleteEngagementUploadAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_UPLOAD_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementUpload(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_UPLOAD_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_UPLOAD_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementUploadAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_UPLOAD_DELETE_RESET });
};
