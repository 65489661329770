export const PROPOSAL_STAFF_LIST_REQUEST = "PROPOSAL_STAFF_LIST_REQUEST";
export const PROPOSAL_STAFF_LIST_SUCCESS = "PROPOSAL_STAFF_LIST_SUCCESS";
export const PROPOSAL_STAFF_LIST_FAIL = "PROPOSAL_STAFF_LIST_FAIL";
export const PROPOSAL_STAFF_LIST_RESET = "PROPOSAL_STAFF_LIST_RESET";

export const PROPOSAL_STAFF_CREATE_REQUEST = "PROPOSAL_STAFF_CREATE_REQUEST";
export const PROPOSAL_STAFF_CREATE_SUCCESS = "PROPOSAL_STAFF_CREATE_SUCCESS";
export const PROPOSAL_STAFF_CREATE_FAIL = "PROPOSAL_STAFF_CREATE_FAIL";
export const PROPOSAL_STAFF_CREATE_RESET = "PROPOSAL_STAFF_CREATE_RESET";

export const PROPOSAL_STAFF_DETAILS_REQUEST = "PROPOSAL_STAFF_DETAILS_REQUEST";
export const PROPOSAL_STAFF_DETAILS_SUCCESS = "PROPOSAL_STAFF_DETAILS_SUCCESS";
export const PROPOSAL_STAFF_DETAILS_FAIL = "PROPOSAL_STAFF_DETAILS_FAIL";
export const PROPOSAL_STAFF_DETAILS_RESET = "PROPOSAL_STAFF_DETAILS_RESET";

export const PROPOSAL_STAFF_UPDATE_REQUEST = "PROPOSAL_STAFF_UPDATE_REQUEST";
export const PROPOSAL_STAFF_UPDATE_SUCCESS = "PROPOSAL_STAFF_UPDATE_SUCCESS";
export const PROPOSAL_STAFF_UPDATE_FAIL = "PROPOSAL_STAFF_UPDATE_FAIL";
export const PROPOSAL_STAFF_UPDATE_RESET = "PROPOSAL_STAFF_UPDATE_RESET";

export const PROPOSAL_STAFF_DELETE_REQUEST = "PROPOSAL_STAFF_DELETE_REQUEST";
export const PROPOSAL_STAFF_DELETE_SUCCESS = "PROPOSAL_STAFF_DELETE_SUCCESS";
export const PROPOSAL_STAFF_DELETE_FAIL = "PROPOSAL_STAFF_DELETE_FAIL";
export const PROPOSAL_STAFF_DELETE_RESET = "PROPOSAL_STAFF_DELETE_RESET";
