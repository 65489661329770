// Main function
function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

const getDjangoDate = (date) => {
  if (date) {
    return `${date.getFullYear()}-${padTo2Digits(
      date.getMonth() + 1
    )}-${padTo2Digits(date.getDate())}`;
  }

  return "";
};

export default getDjangoDate;
