// Library imports
import { DateRangePicker } from "@blueprintjs/datetime";
import React from "react";

// Component imports
import TableColumnHead from "../components/TableColumnHead";

// Main function
function TableColumnHeadDate({
  endDateVariable,
  maxDate,
  minDate,
  noShortcuts,
  queryObject,
  setQueryObject,
  shortRange,
  startDateVariable,
  title,
  width,
}) {
  // Set short range dates
  const past_week = new Date();
  past_week.setDate(past_week.getDate() - 7);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const following_week = new Date();
  following_week.setDate(following_week.getDate() + 7);

  const shortcuts = [
    {
      dateRange: [past_week, today],
      label: "Past week",
    },
    {
      dateRange: [yesterday, yesterday],
      label: "Yesterday",
    },
    {
      dateRange: [today, today],
      label: "Today",
    },
    {
      dateRange: [tomorrow, tomorrow],
      label: "Tomorrow",
    },
    {
      dateRange: [today, following_week],
      label: "Following week",
    },
  ];

  // Calculate default max and min dates
  const defaultMaxDate = new Date(today.getFullYear() + 3, 12, 31);
  const defaultMinDate = new Date(today.getFullYear() - 3, 1, 1);

  // JSX UI code
  return (
    <TableColumnHead
      active={
        queryObject[endDateVariable] || queryObject[startDateVariable]
          ? true
          : false
      }
      children={
        <DateRangePicker
          highlightCurrentDay
          maxDate={maxDate ? maxDate : defaultMaxDate}
          minDate={minDate ? minDate : defaultMinDate}
          onChange={(selectedDates) => {
            setQueryObject((prevState) => {
              return {
                ...prevState,
                page: 1,
                [endDateVariable]: selectedDates[1],
                [startDateVariable]: selectedDates[0],
              };
            });
          }}
          shortcuts={noShortcuts ? false : shortRange ? shortcuts : undefined}
          value={[
            queryObject[startDateVariable]
              ? queryObject[startDateVariable]
              : undefined,
            queryObject[endDateVariable]
              ? queryObject[endDateVariable]
              : undefined,
          ]}
        />
      }
      title={title}
      width={width}
    />
  );
}

export default TableColumnHeadDate;
