// Library imports
import { Classes, Colors, Divider, H5, HTMLTable } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import TableNumber from "../components/TableNumber";

// Redux action imports
// Expense
import { listExpensesAction } from "../actions/expenseActions";

// Main function
function ExpenseInfo({ additionalInfo, expenseData, expenseId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Expense
  const expenseList = useSelector((state) => state.expenseList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh expense data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [expense, setExpense] = useState({});

  useEffect(() => {
    if (expenseData) {
      setExpense(expenseData);

      setLoading(false);
    }
  }, [expenseData]);

  useEffect(() => {
    if (!expenseData && !expenseList.loading) {
      if (expenseList.success) {
        const thisExpense = expenseList.expenses.filter(
          (expense_item) => expense_item.id === expenseId
        );

        if (thisExpense.length > 0) {
          setExpense(thisExpense[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listExpensesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (expenseList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [expenseList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{expense.category_name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Code</strong>
          </p>

          <p>
            {expense.booking_code_data
              ? expense.booking_code_data.name
              : undefined}
          </p>

          <p className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}>
            {expense.booking_code_data
              ? expense.booking_code_data.code
              : undefined}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Comments</strong>
          </p>

          <p>
            {expense.comments ? (
              expense.comments
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Financials</strong>
          </p>

          <HTMLTable bordered striped style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={expense.value} />
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber
                    fillTag
                    invertColor
                    number={expense.margin}
                    tagNumber={expense.margin_percentage}
                    tagPercentage
                  />
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={expense.revenue} />
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Cost
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Margin
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Revenue
                </th>
              </tr>
            </thead>
          </HTMLTable>

          <p
            className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
            style={{ marginTop: "5px" }}
          >
            Figures in {activeOrganisation.currency}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default ExpenseInfo;
