// Constant imports
import {
  INTERVIEW_LIST_REQUEST,
  INTERVIEW_LIST_SUCCESS,
  INTERVIEW_LIST_FAIL,
  INTERVIEW_LIST_RESET,
  INTERVIEW_CREATE_REQUEST,
  INTERVIEW_CREATE_SUCCESS,
  INTERVIEW_CREATE_FAIL,
  INTERVIEW_CREATE_RESET,
  INTERVIEW_DETAILS_REQUEST,
  INTERVIEW_DETAILS_SUCCESS,
  INTERVIEW_DETAILS_FAIL,
  INTERVIEW_DETAILS_RESET,
  INTERVIEW_UPDATE_REQUEST,
  INTERVIEW_UPDATE_SUCCESS,
  INTERVIEW_UPDATE_FAIL,
  INTERVIEW_UPDATE_RESET,
  INTERVIEW_DELETE_REQUEST,
  INTERVIEW_DELETE_SUCCESS,
  INTERVIEW_DELETE_FAIL,
  INTERVIEW_DELETE_RESET,
} from "../constants/interviewConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInterviewsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: INTERVIEW_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getInterviews(params, token);

    dispatch({
      type: INTERVIEW_LIST_SUCCESS,

      payload: {
        count: data.count,
        interviews: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: INTERVIEW_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListInterviewsAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_LIST_RESET });
};

// Create
export const createInterviewAction =
  (contact, interview) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );

          interview.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.createInterview(
        token,
        interview
      );

      dispatch({
        type: INTERVIEW_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateInterviewAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_CREATE_RESET });
};

// List details
export const listInterviewDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInterview(id, token);

      dispatch({
        type: INTERVIEW_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInterviewDetailsAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_DETAILS_RESET });
};

// Update
export const updateInterviewAction =
  (id, contact, interview) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );
          interview.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.updateInterview(
        id,
        token,
        interview
      );

      dispatch({
        type: INTERVIEW_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInterviewAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_UPDATE_RESET });
};

// Delete
export const deleteInterviewAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INTERVIEW_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteInterview(id, token);

    dispatch({
      type: INTERVIEW_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INTERVIEW_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteInterviewAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_DELETE_RESET });
};
