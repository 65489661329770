export const USER_EMAIL_CONFIRM_REQUEST = "USER_EMAIL_CONFIRM_REQUEST";
export const USER_EMAIL_CONFIRM_SUCCESS = "USER_EMAIL_CONFIRM_SUCCESS";
export const USER_EMAIL_CONFIRM_FAIL = "USER_EMAIL_CONFIRM_FAIL";
export const USER_EMAIL_CONFIRM_RESET = "USER_EMAIL_CONFIRM_RESET";

export const USER_EMAIL_CONFIRM_RESEND_REQUEST =
  "USER_EMAIL_CONFIRM_RESEND_REQUEST";
export const USER_EMAIL_CONFIRM_RESEND_SUCCESS =
  "USER_EMAIL_CONFIRM_RESEND_SUCCESS";
export const USER_EMAIL_CONFIRM_RESEND_FAIL = "USER_EMAIL_CONFIRM_RESEND_FAIL";
export const USER_EMAIL_CONFIRM_RESEND_RESET =
  "USER_EMAIL_CONFIRM_RESEND_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_PASSWORD_CHANGE_REQUEST = "USER_PASSWORD_CHANGE_REQUEST";
export const USER_PASSWORD_CHANGE_SUCCESS = "USER_PASSWORD_CHANGE_SUCCESS";
export const USER_PASSWORD_CHANGE_FAIL = "USER_PASSWORD_CHANGE_FAIL";
export const USER_PASSWORD_CHANGE_RESET = "USER_PASSWORD_CHANGE_RESET";

export const USER_PASSWORD_RESET_REQUEST = "USER_PASSWORD_RESET_REQUEST";
export const USER_PASSWORD_RESET_SUCCESS = "USER_PASSWORD_RESET_SUCCESS";
export const USER_PASSWORD_RESET_FAIL = "USER_PASSWORD_RESET_FAIL";
export const USER_PASSWORD_RESET_RESET = "USER_PASSWORD_RESET_RESET";

export const USER_PASSWORD_RESET_CONFIRM_REQUEST =
  "USER_PASSWORD_RESET_CONFIRM_REQUEST";
export const USER_PASSWORD_RESET_CONFIRM_SUCCESS =
  "USER_PASSWORD_RESET_CONFIRM_SUCCESS";
export const USER_PASSWORD_RESET_CONFIRM_FAIL =
  "USER_PASSWORD_RESET_CONFIRM_FAIL";
export const USER_PASSWORD_RESET_CONFIRM_RESET =
  "USER_PASSWORD_RESET_CONFIRM_RESET";

export const USER_PROFILE_DETAILS_REQUEST = "USER_PROFILE_DETAILS_REQUEST";
export const USER_PROFILE_DETAILS_SUCCESS = "USER_PROFILE_DETAILS_SUCCESS";
export const USER_PROFILE_DETAILS_FAIL = "USER_PROFILE_DETAILS_FAIL";
export const USER_PROFILE_DETAILS_RESET = "USER_PROFILE_DETAILS_RESET";

export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_FAIL = "USER_PROFILE_UPDATE_FAIL";
export const USER_PROFILE_UPDATE_RESET = "USER_PROFILE_UPDATE_RESET";

export const USER_SIGNUP_REQUEST = "USER_SIGNUP_REQUEST";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const USER_SIGNUP_RESET = "USER_SIGNUP_RESET";
