// Constant imports
import {
  SHOW_ACTION_SIDEBAR,
  SHOW_SIDEBAR,
  UI_MODE_SWITCH,
} from "../constants/UIConstants";

// Switch action sidebar mode
export const ShowActionSidebarReducer = (
  state = { showActionSidebar: false },
  action
) => {
  switch (action.type) {
    case SHOW_ACTION_SIDEBAR:
      return {
        showActionSidebar: action.payload,
      };

    default:
      return state;
  }
};

// Switch sidebar mode
export const ShowSidebarReducer = (state = { showSidebar: false }, action) => {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return {
        showSidebar: action.payload,
      };

    default:
      return state;
  }
};

// Switch UI mode
export const UIModeReducer = (state = { lightMode: true }, action) => {
  switch (action.type) {
    case UI_MODE_SWITCH:
      return {
        lightMode: action.payload,
      };

    default:
      return state;
  }
};
