// Constant imports
import {
  ENGAGEMENT_LABOUR_EXPENSE_LIST_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_LIST_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_LIST_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_LIST_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_RESET,
} from "../constants/engagementLabourExpenseConstants";

// List all
export const engagementLabourExpenseListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_labour_expenses: [],
    total_cost: 0,
    total_fee: 0,
    total_fee_quote: 0,
    total_margin: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_LABOUR_EXPENSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_LABOUR_EXPENSE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_labour_expenses: action.payload.engagement_labour_expenses,
        total_cost: action.payload.totalCost,
        total_fee: action.payload.totalFee,
        total_fee_quote: action.payload.totalFeeQuote,
        total_margin: action.payload.totalMargin,
        success: true,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_labour_expenses: [],
        total_cost: 0,
        total_fee: 0,
        total_fee_quote: 0,
        total_margin: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_LABOUR_EXPENSE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_labour_expenses: [],
        total_cost: 0,
        total_fee: 0,
        total_fee_quote: 0,
        total_margin: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementLabourExpenseCreateReducer = (
  state = {
    loading: false,
    engagement_labour_expense: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_LABOUR_EXPENSE_CREATE_REQUEST:
      return {
        loading: true,
        engagement_labour_expense: state.engagement_labour_expense,
        success: false,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_labour_expense: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_CREATE_FAIL:
      return {
        loading: false,
        engagement_labour_expense: state.engagement_labour_expense,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_LABOUR_EXPENSE_CREATE_RESET:
      return {
        loading: false,
        engagement_labour_expense: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementLabourExpenseDetailsReducer = (
  state = {
    loading: false,
    engagement_labour_expense: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_LABOUR_EXPENSE_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_labour_expense: state.engagement_labour_expense,
        success: false,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_labour_expense: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_DETAILS_FAIL:
      return {
        loading: false,
        engagement_labour_expense: state.engagement_labour_expense,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_LABOUR_EXPENSE_DETAILS_RESET:
      return {
        loading: false,
        engagement_labour_expense: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementLabourExpenseUpdateReducer = (
  state = {
    loading: false,
    engagement_labour_expense: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_LABOUR_EXPENSE_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_labour_expense: state.engagement_labour_expense,
        success: false,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_labour_expense: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_UPDATE_FAIL:
      return {
        loading: false,
        engagement_labour_expense: state.engagement_labour_expense,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_LABOUR_EXPENSE_UPDATE_RESET:
      return {
        loading: false,
        engagement_labour_expense: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementLabourExpenseDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_LABOUR_EXPENSE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_LABOUR_EXPENSE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_LABOUR_EXPENSE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
