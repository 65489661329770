// Library imports
import {
  Button,
  ButtonGroup,
  Card,
  Classes,
  Colors,
  Divider,
  H5,
  NonIdealState,
} from "@blueprintjs/core";
import parse from "html-react-parser";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import CopyButton from "../components/CopyButton";
import HtmlDisplay from "../components/HtmlDisplay";

// Utility imports
import generateHtmlDocument from "../utilities/generateHtmlDocument";

// Redux action imports
// Document
import {
  generatePdfFromHtmlDocumentAction,
  resetGeneratePdfFromHtmlDocumentAction,
} from "../actions/documentActions";

// Insight
import { listInsightsAction } from "../actions/insightActions";

// Main function
function InsightInfo({
  additionalInfo,
  hideLink,
  insightData,
  insightId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Document
  const documentGeneratePdfFromHtml = useSelector(
    (state) => state.documentGeneratePdfFromHtml
  );

  // Insight
  const insightList = useSelector((state) => state.insightList);

  // Refresh insight data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [insight, setInsight] = useState({});

  useEffect(() => {
    if (insightData) {
      setInsight(insightData);

      setLoading(false);
    }
  }, [insightData]);

  useEffect(() => {
    if (!insightData && !insightList.loading) {
      if (insightList.success) {
        const thisInsight = insightList.insights.filter(
          (insight) => insight.id === insightId
        );

        if (thisInsight.length > 0) {
          setInsight(thisInsight[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listInsightsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (insightList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [insightList]);

  // Insight content
  const insightContentDefault = {
    about: "",
    columns: false,
    conclusion: "",
    coverImage: "",
    font: "Sintony",
    fontSize: "Small",
    footer: "",
    introduction: "",
    primaryColour: "#1C6E42",
    references: "",
    secondaryColour: "#32A467",
    sections: [],
    title: "",
  };
  const [insightContent, setInsightContent] = useState({
    ...insightContentDefault,
  });

  // Initialise drawer variables for insight content
  useEffect(() => {
    if (insight) {
      setInsightContent({
        about: insight.about ? insight.about : insightContentDefault.about,
        columns:
          insight.columns != null
            ? insight.columns
            : insightContentDefault.columns,
        conclusion: insight.conclusion
          ? insight.conclusion
          : insightContentDefault.conclusion,
        coverImage: insight.cover_image
          ? insight.cover_image
          : insightContentDefault.coverImage,
        font: insight.font ? insight.font : insightContentDefault.font,
        fontSize: insight.font_size
          ? insight.font_size
          : insightContentDefault.fontSize,
        footer: insight.footer ? insight.footer : insightContentDefault.footer,
        introduction: insight.introduction
          ? insight.introduction
          : insightContentDefault.introduction,
        primaryColour: insight.primary_colour
          ? insight.primary_colour
          : insightContentDefault.primaryColour,
        references: insight.references
          ? insight.references
          : insightContentDefault.references,
        secondaryColour: insight.secondary_colour
          ? insight.secondary_colour
          : insightContentDefault.secondaryColour,
        sections:
          insight.sections && insight.sections.length > 0
            ? insight.sections
            : insightContentDefault.sections,
        title: insight.title ? insight.title : insightContentDefault.title,
      });
    }
    // eslint-disable-next-line
  }, [insight]);

  // HTML
  const [htmlString, setHtmlString] = useState("");

  useEffect(() => {
    if (insightContent) {
      setHtmlString(generateHtmlDocument(insightContent));
    }
  }, [insightContent]);

  const Html = useCallback(() => {
    return (
      <div style={{ height: "55vh" }}>
        <HtmlDisplay html={htmlString} />
      </div>
    );
  }, [htmlString]);

  // Download pdf
  const downloadPdf = () => {
    if (htmlString) {
      //const htmlString = renderToStaticMarkup(html);

      dispatch(
        generatePdfFromHtmlDocumentAction({
          data: htmlString,
        })
      );
    }
  };

  useEffect(() => {
    if (documentGeneratePdfFromHtml.success) {
      saveAs(documentGeneratePdfFromHtml.pdf, `${insight.name}`);

      dispatch(resetGeneratePdfFromHtmlDocumentAction());
    }
    // eslint-disable-next-line
  }, [documentGeneratePdfFromHtml.success]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5 style={{ margin: "0 10px 0 0" }}>{insight.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/insights/${insight.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {insight.description ? (
              insight.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Content</strong>
          </p>

          {insight.status === "Approved" ? (
            <>
              <Card elevation={2} style={{ padding: 0 }}>
                <Html />
              </Card>

              <div
                style={{
                  alignContent: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "-60px 10px 0 0",
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    background: lightMode
                      ? Colors.LIGHT_GRAY5
                      : Colors.DARK_GRAY1,
                    opacity: 0.75,
                  }}
                >
                  <Button
                    disabled={
                      documentGeneratePdfFromHtml.loading || !htmlString
                    }
                    icon="download"
                    loading={documentGeneratePdfFromHtml.loading}
                    minimal
                    onClick={downloadPdf}
                    text="Download"
                  />
                </div>

                <ButtonGroup
                  style={{
                    background: lightMode
                      ? Colors.LIGHT_GRAY5
                      : Colors.DARK_GRAY1,
                    opacity: 0.75,
                  }}
                >
                  <CopyButton
                    content={parse(htmlString)}
                    keepHtml
                    text="HTML"
                  />

                  <CopyButton content={parse(htmlString)} text="Text" />
                </ButtonGroup>
              </div>
            </>
          ) : (
            <div style={{ margin: "20px 0 20px 0" }}>
              <NonIdealState
                icon="disable"
                title="This insight is in draft status"
              />
            </div>
          )}

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default InsightInfo;
