// Library imports
import { Card, Classes, HTMLTable, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";
import TableNumber from "../components/TableNumber";
import TableText from "../components/TableText";
import TabSectionField from "../components/TabSectionField";

// Main function
function ClientAccountPlanFacts({ facts }) {
  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <Card>
      <TabSectionField label="Client id" value={facts.client_id} vertical />

      <TabSectionField
        label="Client"
        value={
          <NameValue image={facts.client_logo} logo text={facts.client_name} />
        }
        vertical
      />

      <TabSectionField label="Year" value={facts.year} vertical />

      <TabSectionField
        bottom
        label="Programs"
        value={
          facts.programs.length > 0 ? (
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.programs.map((program, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText info={program.notes} text={program.name} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableText text={program.year} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            image={program.sponsor_profile_photo}
                            showImage
                            text={program.sponsor_name}
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={program.expected_revenue} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Expected revenue */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_expected_revenue} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Program</th>

                    <th>Year</th>

                    <th>Sponsor</th>

                    <th>Expected revenue</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />
    </Card>
  );
}

export default ClientAccountPlanFacts;
