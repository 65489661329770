// Constant imports
import {
  NEED_LIST_REQUEST,
  NEED_LIST_SUCCESS,
  NEED_LIST_FAIL,
  NEED_LIST_RESET,
  NEED_CREATE_REQUEST,
  NEED_CREATE_SUCCESS,
  NEED_CREATE_FAIL,
  NEED_CREATE_RESET,
  NEED_DETAILS_REQUEST,
  NEED_DETAILS_SUCCESS,
  NEED_DETAILS_FAIL,
  NEED_DETAILS_RESET,
  NEED_UPDATE_REQUEST,
  NEED_UPDATE_SUCCESS,
  NEED_UPDATE_FAIL,
  NEED_UPDATE_RESET,
  NEED_DELETE_REQUEST,
  NEED_DELETE_SUCCESS,
  NEED_DELETE_FAIL,
  NEED_DELETE_RESET,
} from "../constants/needConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listNeedsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEED_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getNeeds(params, token);

    dispatch({
      type: NEED_LIST_SUCCESS,

      payload: { count: data.count, needs: data.results },
    });
  } catch (error) {
    dispatch({
      type: NEED_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListNeedsAction = () => async (dispatch) => {
  dispatch({ type: NEED_LIST_RESET });
};

// Create
export const createNeedAction = (need) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEED_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createNeed(token, need);

    dispatch({
      type: NEED_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEED_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateNeedAction = () => async (dispatch) => {
  dispatch({ type: NEED_CREATE_RESET });
};

// List details
export const listNeedDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEED_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getNeed(id, token);

    dispatch({
      type: NEED_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEED_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListNeedDetailsAction = () => async (dispatch) => {
  dispatch({ type: NEED_DETAILS_RESET });
};

// Update
export const updateNeedAction = (id, need) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEED_UPDATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.updateNeed(id, token, need);

    dispatch({
      type: NEED_UPDATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEED_UPDATE_FAIL,

      payload: error,
    });
  }
};

// Reset update
export const resetUpdateNeedAction = () => async (dispatch) => {
  dispatch({ type: NEED_UPDATE_RESET });
};

// Delete
export const deleteNeedAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEED_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteNeed(id, token);

    dispatch({
      type: NEED_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEED_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteNeedAction = () => async (dispatch) => {
  dispatch({ type: NEED_DELETE_RESET });
};
