// Library imports
import { Button, Callout, Card, Classes, H1, H6 } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import LinkTag from "../components/LinkTag";

// Redux action imports
// User
import {
  resetPasswordConfirmAction,
  resetResetPasswordConfirmAction,
} from "../actions/userActions";

// Main function
function PasswordResetConfirmScreen() {
  // Token and uid
  const token = useParams().token;
  const uid = useParams().uid;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userPasswordResetConfirm = useSelector(
    (state) => state.userPasswordResetConfirm
  );

  // Form variables
  // Password reset credentials
  const [passwordResetConfirmCredentials, setPasswordResetConfirmCredentials] =
    useState({
      newPassword1: "",
      newPassword2: "",
      token: token,
      uid: uid,
    });

  // Reset password
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const onVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const resetPassword = () => {
    // Dispatch action
    dispatch(
      resetPasswordConfirmAction({
        new_password1: passwordResetConfirmCredentials.newPassword1,
        new_password2: passwordResetConfirmCredentials.newPassword2,
        recaptcha_token: reCaptchaToken,
        token: passwordResetConfirmCredentials.token,
        uid: passwordResetConfirmCredentials.uid,
      })
    );

    // Refresh reCAPTCHA
    setRefreshReCaptcha((r) => !r);
  };

  // JSX UI code
  return (
    <div
      className={Classes.DARK}
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(24, 145, 172, 0.8), rgba(24, 145, 172, 1))",
        display: "flex",
        justifyContent: "center",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      <Card
        elevation={4}
        style={{
          border: "1px solid #D2ECF9",
          borderRadius: "5px",
          margin: "20px 5px 20px 5px",
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              objectFit: "contain",
              width: "100px",
            }}
          />
        </div>

        <div className="row" style={{ textAlign: "center" }}>
          {/* Heading */}
          <H1>Confirm new password</H1>
        </div>

        <div className="row">
          {/* Form */}
          <div className="col-s-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Error message */}
              {userPasswordResetConfirm.error && (
                <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                  We couldn't confirm your new password. Please try again.
                </Callout>
              )}

              {/* New password */}
              <FormDrawerTextField2
                disabled={userPasswordResetConfirm.success}
                error={userPasswordResetConfirm.error}
                fieldName="new_password1"
                label="New password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter your new password"
                setValueObject={setPasswordResetConfirmCredentials}
                setValueVariable="newPassword1"
                value={passwordResetConfirmCredentials.newPassword1}
              />

              {/* Enter new password again */}
              <FormDrawerTextField2
                disabled={userPasswordResetConfirm.success}
                error={userPasswordResetConfirm.error}
                fieldName="new_password2"
                label="Enter new password again"
                labelInfo="(required)"
                large
                password
                placeholder="Enter your new password"
                setValueObject={setPasswordResetConfirmCredentials}
                setValueVariable="newPassword2"
                value={passwordResetConfirmCredentials.newPassword2}
              />

              {/* reCAPTCHA */}
              <GoogleReCaptcha
                action="resetpasswordconfirm"
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />

              {/* Buttons */}
              {userPasswordResetConfirm.success ? (
                <Button
                  fill
                  icon="control"
                  intent="success"
                  large
                  onClick={() => {
                    dispatch(resetResetPasswordConfirmAction());

                    navigate("/");
                  }}
                  style={{ margin: "20px 0 0 0" }}
                  text="Return to dashboard"
                />
              ) : (
                <Button
                  disabled={!reCaptchaToken}
                  fill
                  icon="key"
                  intent="primary"
                  large
                  loading={userPasswordResetConfirm.loading}
                  onClick={resetPassword}
                  style={{ margin: "20px 0 0 0" }}
                  text="Change"
                  type="submit"
                />
              )}

              {/* Footer */}
              <div
                className={Classes.TEXT_SMALL}
                style={{
                  margin: "20px 0 0 0",
                }}
              >
                {userPasswordResetConfirm.success ? (
                  <H6 style={{ color: "#D2ECF9", margin: "20px 0 0 0" }}>
                    Your password has been changed.
                  </H6>
                ) : (
                  <div>
                    Don't need to reset your password?{" "}
                    <LinkTag
                      intent="primary"
                      label="Go back"
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default PasswordResetConfirmScreen;
