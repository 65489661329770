// Constant imports
import {
  ENGAGEMENT_DEPENDENCY_LIST_REQUEST,
  ENGAGEMENT_DEPENDENCY_LIST_SUCCESS,
  ENGAGEMENT_DEPENDENCY_LIST_FAIL,
  ENGAGEMENT_DEPENDENCY_LIST_RESET,
  ENGAGEMENT_DEPENDENCY_CREATE_REQUEST,
  ENGAGEMENT_DEPENDENCY_CREATE_SUCCESS,
  ENGAGEMENT_DEPENDENCY_CREATE_FAIL,
  ENGAGEMENT_DEPENDENCY_CREATE_RESET,
  ENGAGEMENT_DEPENDENCY_DETAILS_REQUEST,
  ENGAGEMENT_DEPENDENCY_DETAILS_SUCCESS,
  ENGAGEMENT_DEPENDENCY_DETAILS_FAIL,
  ENGAGEMENT_DEPENDENCY_DETAILS_RESET,
  ENGAGEMENT_DEPENDENCY_UPDATE_REQUEST,
  ENGAGEMENT_DEPENDENCY_UPDATE_SUCCESS,
  ENGAGEMENT_DEPENDENCY_UPDATE_FAIL,
  ENGAGEMENT_DEPENDENCY_UPDATE_RESET,
  ENGAGEMENT_DEPENDENCY_DELETE_REQUEST,
  ENGAGEMENT_DEPENDENCY_DELETE_SUCCESS,
  ENGAGEMENT_DEPENDENCY_DELETE_FAIL,
  ENGAGEMENT_DEPENDENCY_DELETE_RESET,
} from "../constants/engagementDependencyConstants";

// List all
export const engagementDependencyListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_dependencies: [],
    numberOfDependenciesClosed: 0,
    numberOfDependenciesOpen: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DEPENDENCY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_DEPENDENCY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_dependencies: action.payload.engagement_dependencies,
        numberOfDependenciesClosed: action.payload.numberOfDependenciesClosed,
        numberOfDependenciesOpen: action.payload.numberOfDependenciesOpen,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_dependencies: [],
        numberOfDependenciesClosed: 0,
        numberOfDependenciesOpen: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DEPENDENCY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_dependencies: [],
        numberOfDependenciesClosed: 0,
        numberOfDependenciesOpen: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementDependencyCreateReducer = (
  state = {
    loading: false,
    engagement_dependency: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DEPENDENCY_CREATE_REQUEST:
      return {
        loading: true,
        engagement_dependency: state.engagement_dependency,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_dependency: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_CREATE_FAIL:
      return {
        loading: false,
        engagement_dependency: state.engagement_dependency,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DEPENDENCY_CREATE_RESET:
      return {
        loading: false,
        engagement_dependency: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementDependencyDetailsReducer = (
  state = {
    loading: false,
    engagement_dependency: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DEPENDENCY_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_dependency: state.engagement_dependency,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_dependency: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_DETAILS_FAIL:
      return {
        loading: false,
        engagement_dependency: state.engagement_dependency,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DEPENDENCY_DETAILS_RESET:
      return {
        loading: false,
        engagement_dependency: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementDependencyUpdateReducer = (
  state = {
    loading: false,
    engagement_dependency: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DEPENDENCY_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_dependency: state.engagement_dependency,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_dependency: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_UPDATE_FAIL:
      return {
        loading: false,
        engagement_dependency: state.engagement_dependency,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DEPENDENCY_UPDATE_RESET:
      return {
        loading: false,
        engagement_dependency: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementDependencyDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DEPENDENCY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DEPENDENCY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DEPENDENCY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
