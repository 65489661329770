// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Card,
  Colors,
  Divider,
  H5,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ContactFunctionCard from "../components/ContactFunctionCard";
import ContactInfo from "../components/ContactInfo";
import ContactProposalPipelineCard from "../components/ContactProposalPipelineCard";
import ContactProposalTotalValueCard from "../components/ContactProposalTotalValueCard";
import ContactSeniorityCard from "../components/ContactSeniorityCard";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Client
import { listClientsAction } from "../actions/clientActions";

// Contact
import {
  createContactAction,
  listContactsAction,
  resetCreateContactAction,
  resetListContactDetailsAction,
} from "../actions/contactActions";

// Interview
import {
  createInterviewAction,
  listInterviewsAction,
  resetCreateInterviewAction,
  resetListInterviewDetailsAction,
} from "../actions/interviewActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Main function
function ContactListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Client
  const clientList = useSelector((state) => state.clientList);

  // Contact
  const contactCreate = useSelector((state) => state.contactCreate);

  const contactList = useSelector((state) => state.contactList);

  // Interview
  const interviewCreate = useSelector((state) => state.interviewCreate);

  const interviewList = useSelector((state) => state.interviewList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("contacts-tab");

  useEffect(() => {
    const href = window.location.href;

    if (href.includes("#")) {
      setSelectedTab("interviews-tab");
    }
  }, []);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Contact
  const [addContactDrawerIsOpen, setAddContactDrawerIsOpen] = useState(false);

  // Interview
  const [addInterviewDrawerIsOpen, setAddInterviewDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Interview
  const interviewQueryDefault = {
    contact: "",
    dateEnd: "",
    dateStart: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [interviewQuery, setInterviewQuery] = useState({
    ...interviewQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Form variables
  // Contact
  const contactDefault = {
    email: "",
    linkedinPage: "",
    name: "",
    notes: "",
    phone: "",
    profilePhoto: "",
    profilePhotoName: "",
  };
  const [contact, setContact] = useState({
    ...contactDefault,
  });

  // Interview
  const interviewDefault = {
    contact: "",
    contactName: "",
    date: "",
    manager: "",
    managerName: "",
    name: "",
    notes: "",
  };
  const [interview, setInterview] = useState({ ...interviewDefault });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Contact
    setContact({
      ...contactDefault,
    });

    // Interview
    setInterview({ ...interviewDefault });

    // Query variables
    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listContactsAction({ ...contactQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter interviews
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInterviewsAction({
            ...interviewQuery,
            dateEnd: interviewQuery.dateEnd
              ? getDjangoDate(interviewQuery.dateEnd)
              : getDjangoDate(interviewQueryDefault.dateEnd),
            dateStart: interviewQuery.dateStart
              ? getDjangoDate(interviewQuery.dateStart)
              : getDjangoDate(interviewQueryDefault.dateStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [interviewQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Form submission
  // Add contact
  const addContact = () => {
    // Prepare data
    const contact_data = new FormData();

    contact.email
      ? contact_data.append("email", contact.email)
      : contact_data.append("email", contactDefault.email);

    contact.linkedinPage
      ? contact_data.append("linkedin_page", contact.linkedinPage)
      : contact_data.append("linkedin_page", contactDefault.linkedinPage);

    contact.name
      ? contact_data.append("name", contact.name)
      : contact_data.append("name", contactDefault.name);

    contact.notes
      ? contact_data.append("notes", contact.notes)
      : contact_data.append("notes", contactDefault.notes);

    contact_data.append("organisation", activeOrganisation.id);

    contact.phone
      ? contact_data.append("phone", contact.phone)
      : contact_data.append("phone", contactDefault.phone);

    contact.profilePhoto
      ? contact_data.append("profile_photo", contact.profilePhoto)
      : contact_data.append("profile_photo", contactDefault.profilePhoto);

    // Dispatch action
    dispatch(createContactAction(contact_data));
  };

  // Add interview
  const addInterview = () => {
    // Prepare data
    const interview_data = new FormData();

    const contact = new FormData();
    if (interview.contact) {
      interview_data.append("contact", interview.contact);
    } else {
      interview_data.append("contact", interviewDefault.contact);

      if (interview.contactName) {
        contact.append("name", interview.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    interview.date
      ? interview_data.append("date", getDjangoDate(interview.date))
      : interview_data.append("date", getDjangoDate(interviewDefault.date));

    interview.name
      ? interview_data.append("name", interview.name)
      : interview_data.append("name", interviewDefault.name);

    interview.notes
      ? interview_data.append("notes", interview.notes)
      : interview_data.append("notes", interviewDefault.notes);

    interview_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createInterviewAction(contact, interview_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* New contact form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter contact details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "15px", width: "35%" }}>
                <img
                  alt="profile"
                  src={
                    contact.profilePhoto
                      ? URL.createObjectURL(new Blob([contact.profilePhoto]))
                      : "/images/no_photo.png"
                  }
                  style={{
                    aspectRatio: "1/1",
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: "50%",
                    margin: "10px 0 10px 0",
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </div>

              <div style={{ marginLeft: "15px", width: "65%" }}>
                {/* Contact name */}
                <FormDrawerTextField2
                  error={contactCreate.error}
                  fieldName="name"
                  icon="person"
                  label="Contact name"
                  labelInfo="(required)"
                  placeholder="Enter the contact's name"
                  setValueObject={setContact}
                  setValueVariable="name"
                  value={contact.name}
                />

                {/* Phone */}
                <FormDrawerTextField2
                  error={contactCreate.error}
                  fieldName="phone"
                  helperText="Include the country code"
                  icon="phone"
                  label="Phone"
                  placeholder="Enter the contact's phone number"
                  setValueObject={setContact}
                  setValueVariable="phone"
                  value={contact.phone}
                />

                {/* Email */}
                <FormDrawerTextField2
                  error={contactCreate.error}
                  fieldName="email"
                  icon="envelope"
                  label="Email"
                  placeholder="Enter the contact's email"
                  setValueObject={setContact}
                  setValueVariable="email"
                  value={contact.email}
                />

                {/* LinkedIn page */}
                <FormDrawerTextField2
                  error={contactCreate.error}
                  fieldName="linkedin_page"
                  helperText="Enter the full url"
                  icon="social-media"
                  label="LinkedIn page"
                  placeholder="Enter the contact's LinkedIn page"
                  setValueObject={setContact}
                  setValueVariable="linkedinPage"
                  value={contact.linkedinPage}
                />

                {/* Profile photo */}
                <FormDrawerFileField2
                  error={contactCreate.error}
                  fileName="profile_photo"
                  label="Profile photo"
                  placeholder="Upload the contact's profile photo"
                  setValueObject={setContact}
                  setValueFileVariable="profilePhoto"
                  setValueNameVariable="profilePhotoName"
                  valueFile={contact.profilePhoto}
                  valueName={contact.profilePhotoName}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={contactCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the contact"
              setValueObject={setContact}
              setValueVariable="notes"
              value={contact.notes}
            />
          </Card>
        }
        error={contactCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add contact"
            module="Contacts"
            view="List"
          />
        }
        helpTitle="Creating a contact"
        icon="edit"
        isOpen={addContactDrawerIsOpen}
        onSave={addContact}
        refreshList={() => {
          dispatch(
            listContactsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactCreate.success}
        saveSuccessMessage="Contact created."
        saving={contactCreate.loading}
        setIsOpen={setAddContactDrawerIsOpen}
        title="Add contact"
      />

      {/* New interview form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the interview</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Interview purpose */}
                <FormDrawerTextField2
                  error={interviewCreate.error}
                  fieldName="name"
                  icon="social-media"
                  label="Interview purpose"
                  labelInfo="(required)"
                  placeholder="State the purpose of the interview"
                  setValueObject={setInterview}
                  setValueVariable="name"
                  value={interview.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Contact */}
                <FormDrawerSelectField2
                  allowCreate
                  error={interviewCreate.error}
                  fieldName="contact"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={interview.contact}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Contact"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  noOptionOnClick
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setInterview}
                  setValueIdVariable="contact"
                  setValueNameVariable="contactName"
                  showInfo={interview.contact}
                  value={interview.contactName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Date */}
                <FormDrawerDateField2
                  error={interviewCreate.error}
                  fieldName="date"
                  icon="calendar"
                  label="Date"
                  placeholder="Enter the date of the interview"
                  setValueObject={setInterview}
                  setValueVariable="date"
                  value={interview.date}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={interviewCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the interview"
              setValueObject={setInterview}
              setValueVariable="notes"
              value={interview.notes}
            />
          </Card>
        }
        error={interviewCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add interview"
            module="Interviews"
            view="List"
          />
        }
        helpTitle="Creating an interview"
        icon="add"
        isOpen={addInterviewDrawerIsOpen}
        onSave={addInterview}
        refreshList={() => {
          dispatch(
            listInterviewsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateInterviewAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewCreate.success}
        saveSuccessMessage="Interview created."
        saving={interviewCreate.loading}
        setIsOpen={setAddInterviewDrawerIsOpen}
        title="Add interview"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                selectedTab === "contacts-tab"
                  ? setAddContactDrawerIsOpen(true)
                  : setAddInterviewDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Contacts"
      />

      {/* Error alerts */}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Interviews loading error */}
      {interviewList.error && (
        <ErrorAlert message="We couldn't load the interview list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/contacts overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Contacts"
        videoUrl={`${VIDEOS_URL}/contacts overview.mp4`}
        width="800"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="contact-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Contacts */}
            <Tab
              id="contacts-tab"
              title="Contacts"
              panel={
                <div style={{ padding: "0 10px 0 10px" }}>
                  {/* Data cards */}
                  <div
                    style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}
                  >
                    <DataCardContainer
                      children={
                        <>
                          {/* Pipeline */}
                          <ContactProposalPipelineCard
                            fill
                            contactList={contactList}
                            showInsights
                          />

                          {/* Total value of proposals */}
                          <ContactProposalTotalValueCard
                            contactList={contactList}
                            contactQuery={contactQuery}
                            showInsights
                          />

                          {/* Business function */}
                          <ContactFunctionCard fill contactList={contactList} />

                          {/* Level of seniority */}
                          <ContactSeniorityCard
                            contactList={contactList}
                            contactQuery={contactQuery}
                          />
                        </>
                      }
                      darkColor={Colors.DARK_GRAY4}
                      fill
                      lightColor={Colors.LIGHT_GRAY4}
                    />
                  </div>

                  {/* Data table */}
                  <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
                    <TableContainer2
                      body={contactList.contacts.map((contact) => {
                        return (
                          <tr
                            key={contact.id}
                            onClick={() => {
                              if (contact.user_has_permission) {
                                dispatch(resetListContactDetailsAction());

                                navigate(`/contacts/${contact.id}`);
                              } else {
                                AppToaster.show({
                                  icon: "warning-sign",
                                  intent: "danger",
                                  message:
                                    "You do not have access to this contact.",
                                });
                              }
                            }}
                          >
                            {/* Contact */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText
                                image={contact.profile_photo}
                                showImage
                                tagIntent={
                                  !contact.user_has_permission
                                    ? "danger"
                                    : contact.user_access_status === "view"
                                    ? "primary"
                                    : undefined
                                }
                                tagText={
                                  !contact.user_has_permission
                                    ? "No access"
                                    : contact.user_access_status === "view"
                                    ? "View only"
                                    : undefined
                                }
                                text={contact.name}
                              />
                            </td>

                            {/* Client */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText
                                image={contact.client_logo}
                                logo
                                showImage
                                text={contact.client_name}
                                url={
                                  contact.client
                                    ? `/clients/${contact.client}`
                                    : undefined
                                }
                              />
                            </td>

                            {/* Role */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText text={contact.role} />
                            </td>

                            {/* Manager */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText
                                email={contact.manager_name}
                                image={contact.manager_profile_photo}
                                showImage
                              />
                            </td>
                          </tr>
                        );
                      })}
                      clearFiltersDisabled={
                        !contactQuery.client &&
                        !contactQuery.manager &&
                        !contactQuery.role &&
                        !contactQuery.searchString
                      }
                      count={contactList.count}
                      darkColor={Colors.DARK_GRAY4}
                      head={
                        <tr>
                          {/* Contact */}
                          <TableColumnHeadSearch
                            queryObject={contactQuery}
                            queryVariable="searchString"
                            setQueryObject={setContactQuery}
                            setQueryVariable="searchString"
                            title="Contact"
                            width="200px"
                          />

                          {/* Client */}
                          <TableColumnHeadSelect2
                            filterObject={contactQuery}
                            filterVariable="client"
                            items={clientList.clients}
                            setFilterObject={setContactQuery}
                            setQueryObject={setClientQuery}
                            setQueryVariable="searchString"
                            title="Client"
                            width="200px"
                          />

                          {/* Role */}
                          <TableColumnHeadSearch
                            queryObject={contactQuery}
                            queryVariable="role"
                            setQueryObject={setContactQuery}
                            setQueryVariable="role"
                            title="Role"
                            width="200px"
                          />

                          {/* Manager */}
                          <TableColumnHeadSelect2
                            filterObject={contactQuery}
                            filterVariable="manager"
                            items={managerList.managers}
                            setFilterObject={setContactQuery}
                            setQueryObject={setManagerQuery}
                            setQueryVariable="searchString"
                            title="Manager"
                            width="200px"
                          />
                        </tr>
                      }
                      interactive
                      lightColor={Colors.LIGHT_GRAY4}
                      loading={contactList.loading}
                      onClearFilters={() => {
                        setContactQuery({
                          ...contactQueryDefault,
                        });
                      }}
                      page={contactQuery.page}
                      setPageObject={setContactQuery}
                      setPageVariable="page"
                    />
                  </div>
                </div>
              }
            />

            {/* Interviews */}
            <Tab
              id="interviews-tab"
              title="Interviews"
              panel={
                <div style={{ padding: "0 10px 0 10px" }}>
                  {/* Data table */}
                  <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
                    <TableContainer2
                      body={interviewList.interviews.map((interview) => {
                        return (
                          <tr
                            key={interview.id}
                            onClick={() => {
                              if (interview.user_has_permission) {
                                dispatch(resetListInterviewDetailsAction());

                                navigate(`/interviews/${interview.id}`);
                              } else {
                                AppToaster.show({
                                  icon: "warning-sign",
                                  intent: "danger",
                                  message:
                                    "You do not have access to this interview.",
                                });
                              }
                            }}
                          >
                            {/* Purpose */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText
                                tagIntent={
                                  !interview.user_has_permission
                                    ? "danger"
                                    : interview.user_access_status === "view"
                                    ? "primary"
                                    : undefined
                                }
                                tagText={
                                  !interview.user_has_permission
                                    ? "No access"
                                    : interview.user_access_status === "view"
                                    ? "View only"
                                    : undefined
                                }
                                text={interview.name}
                              />
                            </td>

                            {/* Contact */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText
                                image={interview.contact_data.profile_photo}
                                showImage
                                text={interview.contact_data.name}
                                url={`/contacts/${interview.contact}`}
                              />
                            </td>

                            {/* Date */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableDate date={interview.date} />
                            </td>

                            {/* Manager */}
                            <td style={{ verticalAlign: "middle" }}>
                              <TableText
                                email={interview.manager_name}
                                image={interview.manager_profile_photo}
                                showImage
                              />
                            </td>

                            {/* Status */}
                            <td
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              <TableTag
                                intent={
                                  interview.status === "Completed"
                                    ? "success"
                                    : interview.status === "Planned"
                                    ? "warning"
                                    : "primary"
                                }
                                value={interview.status}
                              />
                            </td>
                          </tr>
                        );
                      })}
                      clearFiltersDisabled={
                        !interviewQuery.contact &&
                        !interviewQuery.dateEnd &&
                        !interviewQuery.dateStart &&
                        !interviewQuery.manager &&
                        !interviewQuery.searchString &&
                        !interviewQuery.status
                      }
                      count={interviewList.count}
                      darkColor={Colors.DARK_GRAY4}
                      head={
                        <tr>
                          {/* Purpose */}
                          <TableColumnHeadSearch
                            queryObject={interviewQuery}
                            queryVariable="searchString"
                            setQueryObject={setInterviewQuery}
                            setQueryVariable="searchString"
                            title="Purpose"
                            width="200px"
                          />

                          {/* Contact */}
                          <TableColumnHeadSelect2
                            filterObject={interviewQuery}
                            filterVariable="contact"
                            items={contactList.contacts}
                            setFilterObject={setInterviewQuery}
                            setQueryObject={setContactQuery}
                            setQueryVariable="searchString"
                            title="Contact"
                            width="200px"
                          />

                          {/* Date */}
                          <TableColumnHeadDate
                            endDateVariable="dateEnd"
                            queryObject={interviewQuery}
                            setQueryObject={setInterviewQuery}
                            shortRange
                            startDateVariable="dateStart"
                            title="Date"
                            width="125px"
                          />

                          {/* Manager */}
                          <TableColumnHeadSelect2
                            filterObject={interviewQuery}
                            filterVariable="manager"
                            items={managerList.managers}
                            setFilterObject={setInterviewQuery}
                            setQueryObject={setManagerQuery}
                            setQueryVariable="searchString"
                            title="Manager"
                            width="200px"
                          />

                          {/* Status */}
                          <TableColumnHeadSelectSimple
                            filterObject={interviewQuery}
                            filterVariable="status"
                            options={[
                              {
                                display: "All",
                                icon: "filter-remove",
                                value: "",
                              },
                              {
                                display: "Planned",
                                icon: "playbook",
                                value: "Planned",
                              },
                              {
                                display: "Completed",
                                icon: "confirm",
                                value: "Completed",
                              },
                            ]}
                            setFilterObject={setInterviewQuery}
                            title="Status"
                            width="125px"
                          />
                        </tr>
                      }
                      interactive
                      lightColor={Colors.LIGHT_GRAY4}
                      loading={interviewList.loading}
                      onClearFilters={() => {
                        setInterviewQuery({
                          ...interviewQueryDefault,
                        });
                      }}
                      page={interviewQuery.page}
                      setPageObject={setInterviewQuery}
                      setPageVariable="page"
                    />
                  </div>
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default ContactListScreen;
