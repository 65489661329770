// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  AnchorButton,
  Button,
  Classes,
  Colors,
  Divider,
  FormGroup,
  H5,
  Switch,
  TextArea,
} from "@blueprintjs/core";
import {
  Classes as Popover2Classes,
  Popover2,
  Popover2InteractionKind,
} from "@blueprintjs/popover2";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";

// Component imports
import { AppToaster } from "../components/AppToaster";

// Utility imports
import generateUUID from "../utilities/generateUUID";

// Redux action imports
// Organisation
import { checkSubscriptionAction } from "../actions/organisationActions";

// Text
import {
  resetRewriteFieldTextAction,
  rewriteFieldTextAction,
} from "../actions/textActions";

// Main function
function FormDrawerTextAreaField3({
  context,
  disabled,
  enableAutofill,
  error,
  errorMessage,
  fieldName,
  height,
  helperText,
  label,
  labelInfo,
  minLength,
  noRewrite,
  placeholder,
  setValueObject,
  setValueVariable,
  value,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Field id
  const [id, setId] = useState(null);

  useEffect(() => {
    setId(generateUUID());
  }, []);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  const checkSubscription = useSelector((state) => state.checkSubscription);

  // Check AI credits
  useEffect(() => {
    if (activeOrganisation.id) {
      dispatch(checkSubscriptionAction(activeOrganisation.id));
    }
  }, [activeOrganisation.id, dispatch]);

  // Websocket
  const [result, setResult] = useState("");

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (id) {
        resolve(`${BACKEND_WS_URL}/api/v1/data/field/${id}/`);
      }
    });
  }, [id]);

  const { lastJsonMessage, readyState } = useWebSocket(getSocketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.field_id === id) {
        setResult(lastJsonMessage.message.result);

        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  // AI writer
  const [writerIsOpen, setWriterIsOpen] = useState(false);

  // Rewrite
  const [loading, setLoading] = useState(false);

  const rewrite = () => {
    // Prepare data
    const field_data = new FormData();

    context
      ? field_data.append("context", context)
      : field_data.append("context", "general");

    field_data.append("field_id", id);

    field_data.append("text", value);

    // Dispatch action
    dispatch(rewriteFieldTextAction(activeOrganisation.id, field_data));
  };

  // JSX UI code
  return (
    <div>
      <FormGroup
        helperText={helperText}
        label={label}
        labelFor={fieldName}
        labelInfo={labelInfo}
        subLabel={
          error ? (
            error.response.data[fieldName] ? (
              <span style={{ color: Colors.RED3 }}>
                {errorMessage ? errorMessage : error.response.data[fieldName]}
              </span>
            ) : undefined
          ) : undefined
        }
      >
        <TextArea
          disabled={disabled}
          fill
          growVertically
          id={fieldName}
          intent={
            error
              ? error.response.data[fieldName]
                ? "danger"
                : undefined
              : undefined
          }
          onChange={(e) => {
            setValueObject((prevState) => {
              return { ...prevState, [setValueVariable]: e.target.value };
            });
          }}
          placeholder={placeholder}
          style={{ height: height ? height : "200px", resize: "vertical" }}
          value={value}
        />
      </FormGroup>

      <div
        style={{
          alignItems: "center",
          display: "flex",
          margin: "-10px 0 15px 0",
          justifyContent: "space-between",
        }}
      >
        {enableAutofill ? (
          <div style={{ marginBottom: "-10px" }}>
            <Switch
              checked={value === "..."}
              disabled={value && value !== "..."}
              innerLabel="autofill"
              onChange={() => {
                let newValue;

                value === "..." ? (newValue = "") : (newValue = "...");

                setValueObject((prevState) => {
                  return { ...prevState, [setValueVariable]: newValue };
                });
              }}
            />
          </div>
        ) : (
          <div></div>
        )}

        {!noRewrite ? (
          <Popover2
            content={
              <div
                style={{
                  marginRight: "-10px",
                  maxHeight: "75vh",
                  maxWidth: "600px",
                  minWidth: "600px",
                  overflowY: "auto",
                  padding: "10px 20px 10px 10px",
                  whiteSpace: "pre-wrap",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <H5 style={{ margin: "0 10px 0 0" }}>
                    {label ? label : "Rewrite"}
                  </H5>

                  <Button
                    icon="cross"
                    minimal
                    onClick={() => {
                      setWriterIsOpen(false);
                    }}
                  />
                </div>

                <Divider
                  style={{
                    background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                    margin: "10px 0 20px 0",
                  }}
                />

                <div className={loading ? Classes.SKELETON : undefined}>
                  {loading ? "XXXXXXXXXX" : result}
                </div>

                <Divider
                  style={{
                    background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                    margin: "20px 0 10px 0",
                  }}
                />

                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    icon="changes"
                    intent="success"
                    onClick={() => {
                      setValueObject((prevState) => {
                        return {
                          ...prevState,
                          [setValueVariable]: result,
                        };
                      });

                      setWriterIsOpen(false);
                    }}
                    text="Replace"
                  />

                  <div>
                    <Button
                      intent="primary"
                      onClick={() => {
                        setWriterIsOpen(false);
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Close"
                    />

                    <Button
                      icon="edit"
                      intent="primary"
                      onClick={() => {
                        const threshold = minLength ? minLength : 10;

                        if (value.split(" ").length > threshold) {
                          setLoading(true);

                          rewrite();
                        } else {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "warning",
                            message: "Type in a longer sentence.",
                          });
                        }
                      }}
                      text="Rewrite"
                    />
                  </div>
                </div>
              </div>
            }
            interactionKind={Popover2InteractionKind.CLICK}
            isOpen={writerIsOpen}
            onInteraction={(nextOpenState) => {
              if (nextOpenState) {
                const threshold = minLength ? minLength : 10;

                if (value.split(" ").length > threshold) {
                  setLoading(true);

                  rewrite();

                  setWriterIsOpen(true);
                } else {
                  AppToaster.show({
                    icon: "warning-sign",
                    intent: "warning",
                    message: "Type in a longer sentence.",
                  });
                }
              } else {
                dispatch(resetRewriteFieldTextAction());

                setWriterIsOpen(false);
              }
            }}
            placement="bottom"
            popoverClassName={Popover2Classes.TOOLTIP2}
            renderTarget={({ isOpen, ref, ...targetProps }) => (
              <AnchorButton
                disabled={
                  connectionStatus !== "Open" ||
                  !checkSubscription.subscription.has_ai_credits
                }
                elementRef={ref}
                intent="primary"
                minimal
                small
                text="Rewrite"
                {...targetProps}
              />
            )}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}

export default FormDrawerTextAreaField3;
