// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function EngagementMilestoneProgressCard({
  fill,
  engagementMilestoneList,
  engagementMilestoneQuery,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Progress</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Number of milestones by status.</p>
        </>
      }
      data={[
        {
          name: "Paid",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: engagementMilestoneList.numberOfMilestonesPaid,
        },
        {
          name: "Invoiced",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: engagementMilestoneList.numberOfMilestonesInvoiced,
        },
        {
          name: "Completed",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: engagementMilestoneList.numberOfMilestonesCompleted,
        },
        {
          name: "Not completed",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: engagementMilestoneList.numberOfMilestonesNotCompleted,
        },
      ]}
      fill={fill}
      filter={
        engagementMilestoneQuery ? engagementMilestoneQuery.status : undefined
      }
      noData={
        !engagementMilestoneList.numberOfMilestonesCompleted &&
        !engagementMilestoneList.numberOfMilestonesInvoiced &&
        !engagementMilestoneList.numberOfMilestonesNotCompleted &&
        !engagementMilestoneList.numberOfMilestonesPaid
      }
      title="Progress"
      units="Numeric"
    />
  );
}

export default EngagementMilestoneProgressCard;
