// Constant imports
import {
  APPROVAL_RESPONSE_LIST_REQUEST,
  APPROVAL_RESPONSE_LIST_SUCCESS,
  APPROVAL_RESPONSE_LIST_FAIL,
  APPROVAL_RESPONSE_LIST_RESET,
  APPROVAL_RESPONSE_CREATE_REQUEST,
  APPROVAL_RESPONSE_CREATE_SUCCESS,
  APPROVAL_RESPONSE_CREATE_FAIL,
  APPROVAL_RESPONSE_CREATE_RESET,
  APPROVAL_RESPONSE_DETAILS_REQUEST,
  APPROVAL_RESPONSE_DETAILS_SUCCESS,
  APPROVAL_RESPONSE_DETAILS_FAIL,
  APPROVAL_RESPONSE_DETAILS_RESET,
  APPROVAL_RESPONSE_UPDATE_REQUEST,
  APPROVAL_RESPONSE_UPDATE_SUCCESS,
  APPROVAL_RESPONSE_UPDATE_FAIL,
  APPROVAL_RESPONSE_UPDATE_RESET,
  APPROVAL_RESPONSE_DELETE_REQUEST,
  APPROVAL_RESPONSE_DELETE_SUCCESS,
  APPROVAL_RESPONSE_DELETE_FAIL,
  APPROVAL_RESPONSE_DELETE_RESET,
} from "../constants/approvalResponseConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listApprovalResponsesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_RESPONSE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getApprovalResponses(
        params,
        token
      );

      dispatch({
        type: APPROVAL_RESPONSE_LIST_SUCCESS,

        payload: {
          count: data.count,
          approval_responses: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_RESPONSE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListApprovalResponsesAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_RESPONSE_LIST_RESET });
};

// Create
export const createApprovalResponseAction =
  (approvalResponse) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_RESPONSE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createApprovalResponse(
        token,
        approvalResponse
      );

      dispatch({
        type: APPROVAL_RESPONSE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_RESPONSE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateApprovalResponseAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_RESPONSE_CREATE_RESET });
};

// List details
export const listApprovalResponseDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_RESPONSE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getApprovalResponse(id, token);

      dispatch({
        type: APPROVAL_RESPONSE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_RESPONSE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListApprovalResponseDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: APPROVAL_RESPONSE_DETAILS_RESET });
  };

// Update
export const updateApprovalResponseAction =
  (id, approvalResponse) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_RESPONSE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateApprovalResponse(
        id,
        token,
        approvalResponse
      );

      dispatch({
        type: APPROVAL_RESPONSE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_RESPONSE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateApprovalResponseAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_RESPONSE_UPDATE_RESET });
};

// Delete
export const deleteApprovalResponseAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_RESPONSE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteApprovalResponse(
        id,
        token
      );

      dispatch({
        type: APPROVAL_RESPONSE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_RESPONSE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteApprovalResponseAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_RESPONSE_DELETE_RESET });
};
