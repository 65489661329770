// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function RoleProposalTotalFeesCard({
  fill,
  roleList,
  roleQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Fees</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total fees quoted on proposals closed for the selected role(s).
            These are proposals that have been won, lost, or cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {Math.max(
                  roleList.totalFeesOnProposalsCancelled,
                  roleList.totalFeesOnProposalsLost,
                  roleList.totalFeesOnProposalsWon
                ) === roleList.totalFeesOnProposalsWon ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm focuses on roles that are in-demand. Continue to
                      drive hiring and training activities for these roles.
                    </div>
                  </div>
                ) : Math.max(
                    roleList.totalFeesOnProposalsCancelled,
                    roleList.totalFeesOnProposalsLost,
                    roleList.totalFeesOnProposalsWon
                  ) === roleList.totalFeesOnProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is investing resources into proposals that
                      ultimately do not materialize when they include the
                      selected role(s). You may need to review the skills that
                      you bring to clients and adjust hiring and training
                      activity accordingly.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is expending considerable resources on proposals
                      that are ultimately unsuccessful when they include the
                      selected role(s). Collecting feedback from clients may
                      help to uncover specific objections, such as rates or
                      staff experience, which you can use to optimize capability
                      and pricing.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: roleList.totalFeesOnProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: roleList.totalFeesOnProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: roleList.totalFeesOnProposalsCancelled,
        },
      ]}
      fill={fill}
      filter={roleQuery ? roleQuery.status : undefined}
      noData={
        !roleList.totalFeesOnProposalsCancelled &&
        !roleList.totalFeesOnProposalsLost &&
        !roleList.totalFeesOnProposalsWon
      }
      title="Fees"
      units={activeOrganisation.currency}
    />
  );
}

export default RoleProposalTotalFeesCard;
