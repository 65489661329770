// Constant imports
import {
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAIL,
  NOTIFICATION_LIST_RESET,
  NOTIFICATION_DELETE_REQUEST,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAIL,
  NOTIFICATION_DELETE_RESET,
} from "../constants/notificationConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listNotificationsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: NOTIFICATION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getNotifications(params, token);

      dispatch({
        type: NOTIFICATION_LIST_SUCCESS,

        payload: {
          count: data.count,
          notifications: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: NOTIFICATION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListNotificationsAction = () => async (dispatch) => {
  dispatch({ type: NOTIFICATION_LIST_RESET });
};

// Delete
export const deleteNotificationAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NOTIFICATION_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteNotification(id, token);

    dispatch({
      type: NOTIFICATION_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteNotificationAction = () => async (dispatch) => {
  dispatch({ type: NOTIFICATION_DELETE_RESET });
};
