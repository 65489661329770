// Constant imports
import {
  DOCUMENT_GENERATE_PDF_REQUEST,
  DOCUMENT_GENERATE_PDF_SUCCESS,
  DOCUMENT_GENERATE_PDF_FAIL,
  DOCUMENT_GENERATE_PDF_RESET,
  DOCUMENT_GENERATE_PDF_FROM_HTML_REQUEST,
  DOCUMENT_GENERATE_PDF_FROM_HTML_SUCCESS,
  DOCUMENT_GENERATE_PDF_FROM_HTML_FAIL,
  DOCUMENT_GENERATE_PDF_FROM_HTML_RESET,
} from "../constants/documentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// Generate pdf
export const generatePdfDocumentAction =
  (document) => async (dispatch, getState) => {
    try {
      dispatch({ type: DOCUMENT_GENERATE_PDF_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.generatePdf(token, document);

      const fileURL = URL.createObjectURL(data);

      dispatch({
        type: DOCUMENT_GENERATE_PDF_SUCCESS,

        payload: {
          fileURL: fileURL,
          type: document.type,
        },
      });
    } catch (error) {
      dispatch({
        type: DOCUMENT_GENERATE_PDF_FAIL,

        payload: error,
      });
    }
  };

// Reset generate pdf
export const resetGeneratePdfDocumentAction = () => async (dispatch) => {
  dispatch({ type: DOCUMENT_GENERATE_PDF_RESET });
};

// Generate pdf from html
export const generatePdfFromHtmlDocumentAction =
  (document) => async (dispatch, getState) => {
    try {
      dispatch({ type: DOCUMENT_GENERATE_PDF_FROM_HTML_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.generatePdfFromHtml(
        token,
        document
      );

      const fileURL = URL.createObjectURL(data);

      dispatch({
        type: DOCUMENT_GENERATE_PDF_FROM_HTML_SUCCESS,

        payload: {
          fileURL: fileURL,
        },
      });
    } catch (error) {
      dispatch({
        type: DOCUMENT_GENERATE_PDF_FROM_HTML_FAIL,

        payload: error,
      });
    }
  };

// Reset generate pdf from html
export const resetGeneratePdfFromHtmlDocumentAction =
  () => async (dispatch) => {
    dispatch({ type: DOCUMENT_GENERATE_PDF_FROM_HTML_RESET });
  };
