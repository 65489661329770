export const INSIGHT_PERMISSION_LIST_REQUEST =
  "INSIGHT_PERMISSION_LIST_REQUEST";
export const INSIGHT_PERMISSION_LIST_SUCCESS =
  "INSIGHT_PERMISSION_LIST_SUCCESS";
export const INSIGHT_PERMISSION_LIST_FAIL = "INSIGHT_PERMISSION_LIST_FAIL";
export const INSIGHT_PERMISSION_LIST_RESET = "INSIGHT_PERMISSION_LIST_RESET";

export const INSIGHT_PERMISSION_CREATE_REQUEST =
  "INSIGHT_PERMISSION_CREATE_REQUEST";
export const INSIGHT_PERMISSION_CREATE_SUCCESS =
  "INSIGHT_PERMISSION_CREATE_SUCCESS";
export const INSIGHT_PERMISSION_CREATE_FAIL = "INSIGHT_PERMISSION_CREATE_FAIL";
export const INSIGHT_PERMISSION_CREATE_RESET =
  "INSIGHT_PERMISSION_CREATE_RESET";

export const INSIGHT_PERMISSION_DETAILS_REQUEST =
  "INSIGHT_PERMISSION_DETAILS_REQUEST";
export const INSIGHT_PERMISSION_DETAILS_SUCCESS =
  "INSIGHT_PERMISSION_DETAILS_SUCCESS";
export const INSIGHT_PERMISSION_DETAILS_FAIL =
  "INSIGHT_PERMISSION_DETAILS_FAIL";
export const INSIGHT_PERMISSION_DETAILS_RESET =
  "INSIGHT_PERMISSION_DETAILS_RESET";

export const INSIGHT_PERMISSION_UPDATE_REQUEST =
  "INSIGHT_PERMISSION_UPDATE_REQUEST";
export const INSIGHT_PERMISSION_UPDATE_SUCCESS =
  "INSIGHT_PERMISSION_UPDATE_SUCCESS";
export const INSIGHT_PERMISSION_UPDATE_FAIL = "INSIGHT_PERMISSION_UPDATE_FAIL";
export const INSIGHT_PERMISSION_UPDATE_RESET =
  "INSIGHT_PERMISSION_UPDATE_RESET";

export const INSIGHT_PERMISSION_DELETE_REQUEST =
  "INSIGHT_PERMISSION_DELETE_REQUEST";
export const INSIGHT_PERMISSION_DELETE_SUCCESS =
  "INSIGHT_PERMISSION_DELETE_SUCCESS";
export const INSIGHT_PERMISSION_DELETE_FAIL = "INSIGHT_PERMISSION_DELETE_FAIL";
export const INSIGHT_PERMISSION_DELETE_RESET =
  "INSIGHT_PERMISSION_DELETE_RESET";
