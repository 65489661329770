// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Competency
import { listCompetenciesAction } from "../actions/competencyActions";

// Main function
function CompetencyInfo({
  additionalInfo,
  competencyData,
  competencyId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Competency
  const competencyList = useSelector((state) => state.competencyList);

  // Refresh competency data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [competency, setCompetency] = useState({});

  useEffect(() => {
    if (competencyData) {
      setCompetency(competencyData);

      setLoading(false);
    }
  }, [competencyData]);

  useEffect(() => {
    if (!competencyData && !competencyList.loading) {
      if (competencyList.success) {
        const thisCompetency = competencyList.competencies.filter(
          (competency) => competency.id === competencyId
        );

        if (thisCompetency.length > 0) {
          setCompetency(thisCompetency[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listCompetenciesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (competencyList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [competencyList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{competency.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {competency.description ? (
              competency.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default CompetencyInfo;
