// Constant imports
import {
  PROPOSAL_RATE_LIST_REQUEST,
  PROPOSAL_RATE_LIST_SUCCESS,
  PROPOSAL_RATE_LIST_FAIL,
  PROPOSAL_RATE_LIST_RESET,
  PROPOSAL_RATE_CREATE_REQUEST,
  PROPOSAL_RATE_CREATE_SUCCESS,
  PROPOSAL_RATE_CREATE_FAIL,
  PROPOSAL_RATE_CREATE_RESET,
  PROPOSAL_RATE_DETAILS_REQUEST,
  PROPOSAL_RATE_DETAILS_SUCCESS,
  PROPOSAL_RATE_DETAILS_FAIL,
  PROPOSAL_RATE_DETAILS_RESET,
  PROPOSAL_RATE_UPDATE_REQUEST,
  PROPOSAL_RATE_UPDATE_SUCCESS,
  PROPOSAL_RATE_UPDATE_FAIL,
  PROPOSAL_RATE_UPDATE_RESET,
  PROPOSAL_RATE_DELETE_REQUEST,
  PROPOSAL_RATE_DELETE_SUCCESS,
  PROPOSAL_RATE_DELETE_FAIL,
  PROPOSAL_RATE_DELETE_RESET,
} from "../constants/proposalRateConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalRatesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RATE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalRates(params, token);

      dispatch({
        type: PROPOSAL_RATE_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_rates: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RATE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalRatesAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RATE_LIST_RESET });
};

// Create
export const createProposalRateAction =
  (proposal_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RATE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalRate(
        token,
        proposal_rate
      );

      dispatch({
        type: PROPOSAL_RATE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RATE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalRateAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RATE_CREATE_RESET });
};

// List details
export const listProposalRateDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RATE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalRate(id, token);

      dispatch({
        type: PROPOSAL_RATE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RATE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalRateDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RATE_DETAILS_RESET });
};

// Update
export const updateProposalRateAction =
  (id, proposal_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RATE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalRate(
        id,
        token,
        proposal_rate
      );

      dispatch({
        type: PROPOSAL_RATE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RATE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalRateAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RATE_UPDATE_RESET });
};

// Delete
export const deleteProposalRateAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_RATE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteProposalRate(id, token);

    dispatch({
      type: PROPOSAL_RATE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_RATE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteProposalRateAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RATE_DELETE_RESET });
};
