// Library imports
import { Colors, Divider, H5, Tag, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardTwoBarChart from "../components/DataCardTwoBarChart";

// Main function
function RoleResourcingCard({ fill, roleList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardTwoBarChart
      comments={
        <>
          <H5>Resource coverage</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total effort for the selected role(s) that has been factored into
            proposals and engagements. Includes proposals that are in-progress
            or have been submitted and engagements that are open. The effort is
            split into two categories:
          </p>

          <UL>
            <li>Resourced: A team member has been assigned to the role.</li>

            <li>Not resourced: The role is vacant.</li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {roleList.totalEffortOnEngagementsNotResourced === null &&
                roleList.totalEffortOnProposalsNotResourced === null ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      There are no vacancies for the selected role(s) in active
                      proposals or engagements.
                    </div>
                  </div>
                ) : roleList.totalEffortOnEngagementsResourced +
                    roleList.totalEffortOnProposalsResourced >
                  2 *
                    (roleList.totalEffortOnEngagementsNotResourced +
                      roleList.totalEffortOnProposalsNotResourced) ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The tentative staff bookings address most but not all of
                      the resourcing requirement for the selected role(s). You
                      may need to reassign team members or hire additional
                      staff.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      There is a significant amount of effort for the selected
                      role(s) that needs resourcing. You may need to hire
                      temporary staff or subcontract some of the work to deliver
                      on contractual commitments.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Resourced",
          fill1: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          fill2: lightMode ? Colors.INDIGO1 : Colors.INDIGO3,
          value1: roleList.totalEffortOnProposalsResourced / 60,
          value2: roleList.totalEffortOnEngagementsResourced / 60,
        },
        {
          name: "Not Resourced",
          fill1: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          fill2: lightMode ? Colors.INDIGO1 : Colors.INDIGO3,
          value1: roleList.totalEffortOnProposalsNotResourced / 60,
          value2: roleList.totalEffortOnEngagementsNotResourced / 60,
        },
      ]}
      fill={fill}
      legendData={[
        {
          color: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          type: "square",
          value: "Proposals",
        },
        {
          color: lightMode ? Colors.INDIGO1 : Colors.INDIGO3,
          type: "square",
          value: "Engagements",
        },
      ]}
      noData={
        !roleList.totalEffortOnEngagementsNotResourced &&
        !roleList.totalEffortOnEngagementsResourced &&
        !roleList.totalEffortOnProposalsNotResourced &&
        !roleList.totalEffortOnProposalsResourced
      }
      title="Resource coverage"
      units="Hours"
    />
  );
}

export default RoleResourcingCard;
