// Library imports
import { Button, Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";

// Redux action imports
// Case study
import { listCaseStudiesAction } from "../actions/caseStudyActions";

// Main function
function CaseStudyInfo({
  additionalInfo,
  caseStudyData,
  caseStudyId,
  hideLink,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Case study
  const caseStudyList = useSelector((state) => state.caseStudyList);

  // Refresh case study data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [caseStudy, setCaseStudy] = useState({});

  useEffect(() => {
    if (caseStudyData) {
      setCaseStudy(caseStudyData);

      setLoading(false);
    }
  }, [caseStudyData]);

  useEffect(() => {
    if (!caseStudyData && !caseStudyList.loading) {
      if (caseStudyList.success) {
        const thisCaseStudy = caseStudyList.case_studies.filter(
          (case_study) => case_study.id === caseStudyId
        );

        if (thisCaseStudy.length > 0) {
          setCaseStudy(thisCaseStudy[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listCaseStudiesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (caseStudyList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [caseStudyList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5 style={{ margin: "0 10px 0 0" }}>{caseStudy.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/caseStudies/${caseStudy.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Client</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NameValue
              image={caseStudy.client_logo}
              invertColor
              logo
              text={caseStudy.client_name}
              url={`/clients/${caseStudy.client}`}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {caseStudy.description ? (
              caseStudy.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Situation</strong>
          </p>

          <p>
            {caseStudy.situation ? (
              caseStudy.situation
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Complication</strong>
          </p>

          <p>
            {caseStudy.complication ? (
              caseStudy.complication
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Solution</strong>
          </p>

          <p>
            {caseStudy.solution ? (
              caseStudy.solution
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          {caseStudy.exhibit && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Exhibit</strong>
              </p>

              <p>
                <img
                  alt="exhibit"
                  src={caseStudy.exhibit}
                  style={{
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </p>

              {caseStudy.exhibit_title && (
                <div style={{ fontSize: "12px", textAlign: "center" }}>
                  {caseStudy.exhibit_title}
                </div>
              )}
            </>
          )}

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default CaseStudyInfo;
