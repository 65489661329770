// Constant imports
import {
  PROPOSAL_COMPETITOR_LIST_REQUEST,
  PROPOSAL_COMPETITOR_LIST_SUCCESS,
  PROPOSAL_COMPETITOR_LIST_FAIL,
  PROPOSAL_COMPETITOR_LIST_RESET, //new
  PROPOSAL_COMPETITOR_CREATE_REQUEST,
  PROPOSAL_COMPETITOR_CREATE_SUCCESS,
  PROPOSAL_COMPETITOR_CREATE_FAIL,
  PROPOSAL_COMPETITOR_CREATE_RESET,
  PROPOSAL_COMPETITOR_DETAILS_REQUEST,
  PROPOSAL_COMPETITOR_DETAILS_SUCCESS,
  PROPOSAL_COMPETITOR_DETAILS_FAIL,
  PROPOSAL_COMPETITOR_DETAILS_RESET, //new
  PROPOSAL_COMPETITOR_UPDATE_REQUEST,
  PROPOSAL_COMPETITOR_UPDATE_SUCCESS,
  PROPOSAL_COMPETITOR_UPDATE_FAIL,
  PROPOSAL_COMPETITOR_UPDATE_RESET,
  PROPOSAL_COMPETITOR_DELETE_REQUEST,
  PROPOSAL_COMPETITOR_DELETE_SUCCESS,
  PROPOSAL_COMPETITOR_DELETE_FAIL,
  PROPOSAL_COMPETITOR_DELETE_RESET,
} from "../constants/proposalCompetitorConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalCompetitorsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_COMPETITOR_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalCompetitors(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_COMPETITOR_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_competitors: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_COMPETITOR_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalCompetitorsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_COMPETITOR_LIST_RESET });
};

// Create
export const createProposalCompetitorAction =
  (proposal_competitor) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_COMPETITOR_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalCompetitor(
        token,
        proposal_competitor
      );

      dispatch({
        type: PROPOSAL_COMPETITOR_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_COMPETITOR_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalCompetitorAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_COMPETITOR_CREATE_RESET });
};

// List details
export const listProposalCompetitorDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_COMPETITOR_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalCompetitor(id, token);

      dispatch({
        type: PROPOSAL_COMPETITOR_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_COMPETITOR_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalCompetitorDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_COMPETITOR_DETAILS_RESET });
  };

// Update
export const updateProposalCompetitorAction =
  (id, proposal_competitor) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_COMPETITOR_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalCompetitor(
        id,
        token,
        proposal_competitor
      );

      dispatch({
        type: PROPOSAL_COMPETITOR_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_COMPETITOR_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalCompetitorAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_COMPETITOR_UPDATE_RESET });
};

// Delete
export const deleteProposalCompetitorAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_COMPETITOR_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalCompetitor(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_COMPETITOR_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_COMPETITOR_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalCompetitorAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_COMPETITOR_DELETE_RESET });
};
