// Constant imports
import {
  ROLE_COMPETENCY_LIST_REQUEST,
  ROLE_COMPETENCY_LIST_SUCCESS,
  ROLE_COMPETENCY_LIST_FAIL,
  ROLE_COMPETENCY_LIST_RESET,
  ROLE_COMPETENCY_CREATE_REQUEST,
  ROLE_COMPETENCY_CREATE_SUCCESS,
  ROLE_COMPETENCY_CREATE_FAIL,
  ROLE_COMPETENCY_CREATE_RESET,
  ROLE_COMPETENCY_DETAILS_REQUEST,
  ROLE_COMPETENCY_DETAILS_SUCCESS,
  ROLE_COMPETENCY_DETAILS_FAIL,
  ROLE_COMPETENCY_DETAILS_RESET,
  ROLE_COMPETENCY_UPDATE_REQUEST,
  ROLE_COMPETENCY_UPDATE_SUCCESS,
  ROLE_COMPETENCY_UPDATE_FAIL,
  ROLE_COMPETENCY_UPDATE_RESET,
  ROLE_COMPETENCY_DELETE_REQUEST,
  ROLE_COMPETENCY_DELETE_SUCCESS,
  ROLE_COMPETENCY_DELETE_FAIL,
  ROLE_COMPETENCY_DELETE_RESET,
} from "../constants/roleCompetencyConstants";

// List all
export const roleCompetencyListReducer = (
  state = {
    loading: false,
    count: 0,
    role_competencies: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ROLE_COMPETENCY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ROLE_COMPETENCY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        role_competencies: action.payload.role_competencies,
        success: true,
        error: "",
      };

    case ROLE_COMPETENCY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        role_competencies: [],
        success: false,
        error: action.payload,
      };

    case ROLE_COMPETENCY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        role_competencies: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const roleCompetencyCreateReducer = (
  state = { loading: false, role_competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_COMPETENCY_CREATE_REQUEST:
      return {
        loading: true,
        role_competency: state.role_competency,
        success: false,
        error: "",
      };

    case ROLE_COMPETENCY_CREATE_SUCCESS:
      return {
        loading: false,
        role_competency: action.payload,
        success: true,
        error: "",
      };

    case ROLE_COMPETENCY_CREATE_FAIL:
      return {
        loading: false,
        role_competency: state.role_competency,
        success: false,
        error: action.payload,
      };

    case ROLE_COMPETENCY_CREATE_RESET:
      return { loading: false, role_competency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const roleCompetencyDetailsReducer = (
  state = { loading: false, role_competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_COMPETENCY_DETAILS_REQUEST:
      return {
        loading: true,
        role_competency: state.role_competency,
        success: false,
        error: "",
      };

    case ROLE_COMPETENCY_DETAILS_SUCCESS:
      return {
        loading: false,
        role_competency: action.payload,
        success: true,
        error: "",
      };

    case ROLE_COMPETENCY_DETAILS_FAIL:
      return {
        loading: false,
        role_competency: state.role_competency,
        success: false,
        error: action.payload,
      };

    case ROLE_COMPETENCY_DETAILS_RESET:
      return { loading: false, role_competency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const roleCompetencyUpdateReducer = (
  state = { loading: false, role_competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_COMPETENCY_UPDATE_REQUEST:
      return {
        loading: true,
        role_competency: state.role_competency,
        success: false,
        error: "",
      };

    case ROLE_COMPETENCY_UPDATE_SUCCESS:
      return {
        loading: false,
        role_competency: action.payload,
        success: true,
        error: "",
      };

    case ROLE_COMPETENCY_UPDATE_FAIL:
      return {
        loading: false,
        role_competency: state.role_competency,
        success: false,
        error: action.payload,
      };

    case ROLE_COMPETENCY_UPDATE_RESET:
      return { loading: false, role_competency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const roleCompetencyDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_COMPETENCY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ROLE_COMPETENCY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ROLE_COMPETENCY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ROLE_COMPETENCY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
