// Constant imports
import {
  DOCUMENT_GENERATE_PDF_REQUEST,
  DOCUMENT_GENERATE_PDF_SUCCESS,
  DOCUMENT_GENERATE_PDF_FAIL,
  DOCUMENT_GENERATE_PDF_RESET,
  DOCUMENT_GENERATE_PDF_FROM_HTML_REQUEST,
  DOCUMENT_GENERATE_PDF_FROM_HTML_SUCCESS,
  DOCUMENT_GENERATE_PDF_FROM_HTML_FAIL,
  DOCUMENT_GENERATE_PDF_FROM_HTML_RESET,
} from "../constants/documentConstants";

// Generate pdf
export const documentGeneratePdfReducer = (
  state = {
    loading: false,
    commercials_review_pdf: null,
    engagement_charter_pdf: null,
    engagement_report_pdf: null,
    interview_questionnaire_pdf: null,
    invoice_pdf: null,
    opening_pdf: null,
    opportunity_brief_pdf: null,
    proposal_pdf: null,
    solution_review_pdf: null,
    strategy_review_pdf: null,
    win_loss_review_pdf: null,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case DOCUMENT_GENERATE_PDF_REQUEST:
      return {
        loading: true,
        commercials_review_pdf: state.commercials_review_pdf,
        engagement_charter_pdf: state.engagement_charter_pdf,
        engagement_report_pdf: state.engagement_report_pdf,
        interview_questionnaire_pdf: state.interview_questionnaire_pdf,
        invoice_pdf: state.invoice_pdf,
        opening_pdf: state.opening_pdf,
        opportunity_brief_pdf: state.opportunity_brief_pdf,
        proposal_pdf: state.proposal_pdf,
        solution_review_pdf: state.solution_review_pdf,
        strategy_review_pdf: state.strategy_review_pdf,
        win_loss_review_pdf: state.win_loss_review_pdf,
        success: false,
        error: "",
      };

    case DOCUMENT_GENERATE_PDF_SUCCESS:
      return {
        loading: false,
        commercials_review_pdf:
          action.payload.type === "commercials_review"
            ? action.payload.fileURL
            : state.commercials_review_pdf,
        engagement_charter_pdf:
          action.payload.type === "engagement_charter"
            ? action.payload.fileURL
            : state.engagement_charter_pdf,
        engagement_report_pdf:
          action.payload.type === "engagement_report"
            ? action.payload.fileURL
            : state.engagement_report_pdf,
        interview_questionnaire_pdf:
          action.payload.type === "interview_questionnaire"
            ? action.payload.fileURL
            : state.interview_questionnaire_pdf,
        invoice_pdf:
          action.payload.type === "invoice"
            ? action.payload.fileURL
            : state.invoice_pdf,
        opening_pdf:
          action.payload.type === "opening"
            ? action.payload.fileURL
            : state.opening_pdf,
        opportunity_brief_pdf:
          action.payload.type === "opportunity_brief"
            ? action.payload.fileURL
            : state.opportunity_brief_pdf,
        proposal_pdf:
          action.payload.type === "proposal"
            ? action.payload.fileURL
            : state.proposal_pdf,
        solution_review_pdf:
          action.payload.type === "solution_review"
            ? action.payload.fileURL
            : state.solution_review_pdf,
        strategy_review_pdf:
          action.payload.type === "strategy_review"
            ? action.payload.fileURL
            : state.strategy_review_pdf,
        win_loss_review_pdf:
          action.payload.type === "win_loss_review"
            ? action.payload.fileURL
            : state.win_loss_review_pdf,
        success: true,
        error: "",
      };

    case DOCUMENT_GENERATE_PDF_FAIL:
      return {
        loading: false,
        commercials_review_pdf: state.commercials_review_pdf,
        engagement_charter_pdf: state.engagement_charter_pdf,
        engagement_report_pdf: state.engagement_report_pdf,
        interview_questionnaire_pdf: state.interview_questionnaire_pdf,
        invoice_pdf: state.invoice_pdf,
        opening_pdf: state.opening_pdf,
        opportunity_brief_pdf: state.opportunity_brief_pdf,
        proposal_pdf: state.proposal_pdf,
        solution_review_pdf: state.solution_review_pdf,
        strategy_review_pdf: state.strategy_review_pdf,
        win_loss_review_pdf: state.win_loss_review_pdf,
        success: false,
        error: action.payload,
      };

    case DOCUMENT_GENERATE_PDF_RESET:
      return {
        loading: false,
        commercials_review_pdf: null,
        engagement_charter_pdf: null,
        engagement_report_pdf: null,
        interview_questionnaire_pdf: null,
        invoice_pdf: null,
        opening_pdf: null,
        opportunity_brief_pdf: null,
        proposal_pdf: null,
        solution_review_pdf: null,
        strategy_review_pdf: null,
        win_loss_review_pdf: null,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Generate pdf from html
export const documentGeneratePdfFromHtmlReducer = (
  state = {
    loading: false,
    pdf: null,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case DOCUMENT_GENERATE_PDF_FROM_HTML_REQUEST:
      return {
        loading: true,
        pdf: state.pdf,
        success: false,
        error: "",
      };

    case DOCUMENT_GENERATE_PDF_FROM_HTML_SUCCESS:
      return {
        loading: false,
        pdf: action.payload.fileURL,
        success: true,
        error: "",
      };

    case DOCUMENT_GENERATE_PDF_FROM_HTML_FAIL:
      return {
        loading: false,
        pdf: state.pdf,
        success: false,
        error: action.payload,
      };

    case DOCUMENT_GENERATE_PDF_FROM_HTML_RESET:
      return {
        loading: false,
        pdf: null,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
