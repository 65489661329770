export const ENGAGEMENT_FEE_LIST_REQUEST = "ENGAGEMENT_FEE_LIST_REQUEST";
export const ENGAGEMENT_FEE_LIST_SUCCESS = "ENGAGEMENT_FEE_LIST_SUCCESS";
export const ENGAGEMENT_FEE_LIST_FAIL = "ENGAGEMENT_FEE_LIST_FAIL";
export const ENGAGEMENT_FEE_LIST_RESET = "ENGAGEMENT_FEE_LIST_RESET";

export const ENGAGEMENT_FEE_DETAILS_REQUEST = "ENGAGEMENT_FEE_DETAILS_REQUEST";
export const ENGAGEMENT_FEE_DETAILS_SUCCESS = "ENGAGEMENT_FEE_DETAILS_SUCCESS";
export const ENGAGEMENT_FEE_DETAILS_FAIL = "ENGAGEMENT_FEE_DETAILS_FAIL";
export const ENGAGEMENT_FEE_DETAILS_RESET = "ENGAGEMENT_FEE_DETAILS_RESET";

export const ENGAGEMENT_FEE_UPDATE_REQUEST = "ENGAGEMENT_FEE_UPDATE_REQUEST";
export const ENGAGEMENT_FEE_UPDATE_SUCCESS = "ENGAGEMENT_FEE_UPDATE_SUCCESS";
export const ENGAGEMENT_FEE_UPDATE_FAIL = "ENGAGEMENT_FEE_UPDATE_FAIL";
export const ENGAGEMENT_FEE_UPDATE_RESET = "ENGAGEMENT_FEE_UPDATE_RESET";
