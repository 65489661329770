// Constant imports
import {
  NOTIFICATION_LIST_REQUEST,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAIL,
  NOTIFICATION_LIST_RESET,
  NOTIFICATION_DELETE_REQUEST,
  NOTIFICATION_DELETE_SUCCESS,
  NOTIFICATION_DELETE_FAIL,
  NOTIFICATION_DELETE_RESET,
} from "../constants/notificationConstants";

// List all
export const notificationListReducer = (
  state = {
    loading: false,
    count: 0,
    notifications: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        notifications: action.payload.notifications,
        success: true,
        error: "",
      };

    case NOTIFICATION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        notifications: [],
        success: false,
        error: action.payload,
      };

    case NOTIFICATION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        notifications: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const notificationDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case NOTIFICATION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case NOTIFICATION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case NOTIFICATION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
