// Library imports
import { Colors } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TabsContainer({ children }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
        margin: "10px",
        minHeight: "calc(100vh - 70px)",
        padding: "10px 10px 20px 10px",
      }}
    >
      {children}
    </div>
  );
}

export default TabsContainer;
