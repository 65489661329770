export const CASE_STUDY_PERMISSION_LIST_REQUEST =
  "CASE_STUDY_PERMISSION_LIST_REQUEST";
export const CASE_STUDY_PERMISSION_LIST_SUCCESS =
  "CASE_STUDY_PERMISSION_LIST_SUCCESS";
export const CASE_STUDY_PERMISSION_LIST_FAIL =
  "CASE_STUDY_PERMISSION_LIST_FAIL";
export const CASE_STUDY_PERMISSION_LIST_RESET =
  "CASE_STUDY_PERMISSION_LIST_RESET";

export const CASE_STUDY_PERMISSION_CREATE_REQUEST =
  "CASE_STUDY_PERMISSION_CREATE_REQUEST";
export const CASE_STUDY_PERMISSION_CREATE_SUCCESS =
  "CASE_STUDY_PERMISSION_CREATE_SUCCESS";
export const CASE_STUDY_PERMISSION_CREATE_FAIL =
  "CASE_STUDY_PERMISSION_CREATE_FAIL";
export const CASE_STUDY_PERMISSION_CREATE_RESET =
  "CASE_STUDY_PERMISSION_CREATE_RESET";

export const CASE_STUDY_PERMISSION_DETAILS_REQUEST =
  "CASE_STUDY_PERMISSION_DETAILS_REQUEST";
export const CASE_STUDY_PERMISSION_DETAILS_SUCCESS =
  "CASE_STUDY_PERMISSION_DETAILS_SUCCESS";
export const CASE_STUDY_PERMISSION_DETAILS_FAIL =
  "CASE_STUDY_PERMISSION_DETAILS_FAIL";
export const CASE_STUDY_PERMISSION_DETAILS_RESET =
  "CASE_STUDY_PERMISSION_DETAILS_RESET";

export const CASE_STUDY_PERMISSION_UPDATE_REQUEST =
  "CASE_STUDY_PERMISSION_UPDATE_REQUEST";
export const CASE_STUDY_PERMISSION_UPDATE_SUCCESS =
  "CASE_STUDY_PERMISSION_UPDATE_SUCCESS";
export const CASE_STUDY_PERMISSION_UPDATE_FAIL =
  "CASE_STUDY_PERMISSION_UPDATE_FAIL";
export const CASE_STUDY_PERMISSION_UPDATE_RESET =
  "CASE_STUDY_PERMISSION_UPDATE_RESET";

export const CASE_STUDY_PERMISSION_DELETE_REQUEST =
  "CASE_STUDY_PERMISSION_DELETE_REQUEST";
export const CASE_STUDY_PERMISSION_DELETE_SUCCESS =
  "CASE_STUDY_PERMISSION_DELETE_SUCCESS";
export const CASE_STUDY_PERMISSION_DELETE_FAIL =
  "CASE_STUDY_PERMISSION_DELETE_FAIL";
export const CASE_STUDY_PERMISSION_DELETE_RESET =
  "CASE_STUDY_PERMISSION_DELETE_RESET";
