// Library imports
import { Button, Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Role
import { listRolesAction } from "../actions/roleActions";

// Main function
function RoleInfo({ additionalInfo, hideLink, query, roleData, roleId }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Role
  const roleList = useSelector((state) => state.roleList);

  // Refresh role data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [role, setRole] = useState({});

  useEffect(() => {
    if (roleData) {
      setRole(roleData);

      setLoading(false);
    }
  }, [roleData]);

  useEffect(() => {
    if (!roleData && !roleList.loading) {
      if (roleList.success) {
        const thisRole = roleList.roles.filter(
          (role_item) => role_item.id === roleId
        );

        if (thisRole.length > 0) {
          setRole(thisRole[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listRolesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (roleList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [roleList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5 style={{ margin: "0 10px 0 0" }}>{role.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/roles/${role.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {role.description ? (
              role.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Rank</strong>
          </p>

          <p>{role.rank_name}</p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default RoleInfo;
