// Library imports
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TabSectionEmailLink({ email, invertColor }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <>
      {email ? (
        <a
          href={`mailto:${email}`}
          style={{
            color: invertColor
              ? lightMode
                ? "#D2ECF9"
                : "#1891AC"
              : lightMode
              ? "#1891AC"
              : "#D2ECF9",
          }}
        >
          {email}
        </a>
      ) : (
        "-"
      )}
    </>
  );
}

export default TabSectionEmailLink;
