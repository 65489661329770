// Library imports
import { Button } from "@blueprintjs/core";
import {
  Classes,
  Popover2,
  Popover2InteractionKind,
} from "@blueprintjs/popover2";
import React from "react";

// Main function
function InfoPopup({ content, flexWidth, noPreWrap, width }) {
  // JSX UI code
  return (
    <Popover2
      content={
        <div
          style={{
            marginRight: "-10px",
            maxHeight: "75vh",
            maxWidth: flexWidth ? undefined : width ? width : "600px",
            minWidth: flexWidth ? undefined : width ? width : "600px",
            overflowY: "auto",
            padding: "10px 20px 10px 10px",
            whiteSpace: noPreWrap ? undefined : "pre-wrap",
          }}
        >
          {content}
        </div>
      }
      interactionKind={Popover2InteractionKind.HOVER}
      popoverClassName={Classes.TOOLTIP2}
      renderTarget={({ isOpen, ref, ...targetProps }) => (
        <Button
          elementRef={ref}
          icon="info-sign"
          minimal
          small
          {...targetProps}
        />
      )}
    />
  );
}

export default InfoPopup;
