// Constant imports
import {
  CLIENT_QUESTION_LIST_REQUEST,
  CLIENT_QUESTION_LIST_SUCCESS,
  CLIENT_QUESTION_LIST_FAIL,
  CLIENT_QUESTION_LIST_RESET,
  CLIENT_QUESTION_CREATE_REQUEST,
  CLIENT_QUESTION_CREATE_SUCCESS,
  CLIENT_QUESTION_CREATE_FAIL,
  CLIENT_QUESTION_CREATE_RESET,
  CLIENT_QUESTION_DETAILS_REQUEST,
  CLIENT_QUESTION_DETAILS_SUCCESS,
  CLIENT_QUESTION_DETAILS_FAIL,
  CLIENT_QUESTION_DETAILS_RESET,
  CLIENT_QUESTION_UPDATE_REQUEST,
  CLIENT_QUESTION_UPDATE_SUCCESS,
  CLIENT_QUESTION_UPDATE_FAIL,
  CLIENT_QUESTION_UPDATE_RESET,
  CLIENT_QUESTION_DELETE_REQUEST,
  CLIENT_QUESTION_DELETE_SUCCESS,
  CLIENT_QUESTION_DELETE_FAIL,
  CLIENT_QUESTION_DELETE_RESET,
} from "../constants/clientQuestionConstants";

// List all
export const clientQuestionListReducer = (
  state = {
    loading: false,
    count: 0,
    client_questions: [],
    numberOfQuestionsNotApplicable: 0,
    numberOfQuestionsResearching: 0,
    numberOfQuestionsResolved: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_QUESTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        client_questions: action.payload.client_questions,
        numberOfQuestionsNotApplicable:
          action.payload.numberOfQuestionsNotApplicable,
        numberOfQuestionsResearching:
          action.payload.numberOfQuestionsResearching,
        numberOfQuestionsResolved: action.payload.numberOfQuestionsResolved,
        success: true,
        error: "",
      };

    case CLIENT_QUESTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        client_questions: [],
        numberOfQuestionsNotApplicable: 0,
        numberOfQuestionsResearching: 0,
        numberOfQuestionsResolved: 0,
        success: false,
        error: action.payload,
      };

    case CLIENT_QUESTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        client_questions: [],
        numberOfQuestionsNotApplicable: 0,
        numberOfQuestionsResearching: 0,
        numberOfQuestionsResolved: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientQuestionCreateReducer = (
  state = { loading: false, client_question: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_QUESTION_CREATE_REQUEST:
      return {
        loading: true,
        client_question: state.client_question,
        success: false,
        error: "",
      };

    case CLIENT_QUESTION_CREATE_SUCCESS:
      return {
        loading: false,
        client_question: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_QUESTION_CREATE_FAIL:
      return {
        loading: false,
        client_question: state.client_question,
        success: false,
        error: action.payload,
      };

    case CLIENT_QUESTION_CREATE_RESET:
      return {
        loading: false,
        client_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const clientQuestionDetailsReducer = (
  state = { loading: false, client_question: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_QUESTION_DETAILS_REQUEST:
      return {
        loading: true,
        client_question: state.client_question,
        success: false,
        error: "",
      };

    case CLIENT_QUESTION_DETAILS_SUCCESS:
      return {
        loading: false,
        client_question: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_QUESTION_DETAILS_FAIL:
      return {
        loading: false,
        client_question: state.client_question,
        success: false,
        error: action.payload,
      };

    case CLIENT_QUESTION_DETAILS_RESET:
      return {
        loading: false,
        client_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const clientQuestionUpdateReducer = (
  state = { loading: false, client_question: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_QUESTION_UPDATE_REQUEST:
      return {
        loading: true,
        client_question: state.client_question,
        success: false,
        error: "",
      };

    case CLIENT_QUESTION_UPDATE_SUCCESS:
      return {
        loading: false,
        client_question: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_QUESTION_UPDATE_FAIL:
      return {
        loading: false,
        client_question: state.client_question,
        success: false,
        error: action.payload,
      };

    case CLIENT_QUESTION_UPDATE_RESET:
      return {
        loading: false,
        client_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const clientQuestionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_QUESTION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_QUESTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_QUESTION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_QUESTION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
