// Constant imports
import {
  BOOKING_LIST_REQUEST,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_FAIL,
  BOOKING_LIST_RESET,
  BOOKING_CREATE_REQUEST,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAIL,
  BOOKING_CREATE_RESET,
  BOOKING_DETAILS_REQUEST,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_DETAILS_FAIL,
  BOOKING_DETAILS_RESET,
  BOOKING_UPDATE_REQUEST,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_UPDATE_RESET,
  BOOKING_DELETE_REQUEST,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_DELETE_RESET,
} from "../constants/bookingConstants";

// List all
export const bookingListReducer = (
  state = {
    loading: false,
    count: 0,
    bookings: [],
    averageUtilisationBillableConfirmed: 0,
    averageUtilisationBillableRealised: 0,
    averageUtilisationBillableTentative: 0,
    averageUtilisationNotBillableConfirmed: 0,
    averageUtilisationNotBillableRealised: 0,
    averageUtilisationNotBillableTentative: 0,
    bookingProfile: [],
    totalMinutesBillableConfirmed: 0,
    totalMinutesBillableRealised: 0,
    totalMinutesBillableTentative: 0,
    totalMinutesNotBillableConfirmed: 0,
    totalMinutesNotBillableRealised: 0,
    totalMinutesNotBillableTentative: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case BOOKING_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        bookings: action.payload.bookings,
        averageUtilisationBillableConfirmed:
          action.payload.averageUtilisationBillableConfirmed,
        averageUtilisationBillableRealised:
          action.payload.averageUtilisationBillableRealised,
        averageUtilisationBillableTentative:
          action.payload.averageUtilisationBillableTentative,
        averageUtilisationNotBillableConfirmed:
          action.payload.averageUtilisationNotBillableConfirmed,
        averageUtilisationNotBillableRealised:
          action.payload.averageUtilisationNotBillableRealised,
        averageUtilisationNotBillableTentative:
          action.payload.averageUtilisationNotBillableTentative,
        bookingProfile: action.payload.bookingProfile,
        totalMinutesBillableConfirmed:
          action.payload.totalMinutesBillableConfirmed,
        totalMinutesBillableRealised:
          action.payload.totalMinutesBillableRealised,
        totalMinutesBillableTentative:
          action.payload.totalMinutesBillableTentative,
        totalMinutesNotBillableConfirmed:
          action.payload.totalMinutesNotBillableConfirmed,
        totalMinutesNotBillableRealised:
          action.payload.totalMinutesNotBillableRealised,
        totalMinutesNotBillableTentative:
          action.payload.totalMinutesNotBillableTentative,
        success: true,
        error: "",
      };

    case BOOKING_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        bookings: [],
        averageUtilisationBillableConfirmed: 0,
        averageUtilisationBillableRealised: 0,
        averageUtilisationBillableTentative: 0,
        averageUtilisationNotBillableConfirmed: 0,
        averageUtilisationNotBillableRealised: 0,
        averageUtilisationNotBillableTentative: 0,
        bookingProfile: [],
        totalMinutesBillableConfirmed: 0,
        totalMinutesBillableRealised: 0,
        totalMinutesBillableTentative: 0,
        totalMinutesNotBillableConfirmed: 0,
        totalMinutesNotBillableRealised: 0,
        totalMinutesNotBillableTentative: 0,
        success: false,
        error: action.payload,
      };

    case BOOKING_LIST_RESET:
      return {
        loading: false,
        count: 0,
        bookings: [],
        averageUtilisationBillableConfirmed: 0,
        averageUtilisationBillableRealised: 0,
        averageUtilisationBillableTentative: 0,
        averageUtilisationNotBillableConfirmed: 0,
        averageUtilisationNotBillableRealised: 0,
        averageUtilisationNotBillableTentative: 0,
        bookingProfile: [],
        totalMinutesBillableConfirmed: 0,
        totalMinutesBillableRealised: 0,
        totalMinutesBillableTentative: 0,
        totalMinutesNotBillableConfirmed: 0,
        totalMinutesNotBillableRealised: 0,
        totalMinutesNotBillableTentative: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const bookingCreateReducer = (
  state = { loading: false, booking: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_CREATE_REQUEST:
      return {
        loading: true,
        booking: state.booking,
        success: false,
        error: "",
      };

    case BOOKING_CREATE_SUCCESS:
      return {
        loading: false,
        booking: action.payload,
        success: true,
        error: "",
      };

    case BOOKING_CREATE_FAIL:
      return {
        loading: false,
        booking: state.booking,
        success: false,
        error: action.payload,
      };

    case BOOKING_CREATE_RESET:
      return { loading: false, booking: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const bookingDetailsReducer = (
  state = { loading: false, booking: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_DETAILS_REQUEST:
      return {
        loading: true,
        booking: state.booking,
        success: false,
        error: "",
      };

    case BOOKING_DETAILS_SUCCESS:
      return {
        loading: false,
        booking: action.payload,
        success: true,
        error: "",
      };

    case BOOKING_DETAILS_FAIL:
      return {
        loading: false,
        booking: state.booking,
        success: false,
        error: action.payload,
      };

    case BOOKING_DETAILS_RESET:
      return { loading: false, booking: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const bookingUpdateReducer = (
  state = { loading: false, booking: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_UPDATE_REQUEST:
      return {
        loading: true,
        booking: state.booking,
        success: false,
        error: "",
      };

    case BOOKING_UPDATE_SUCCESS:
      return {
        loading: false,
        booking: action.payload,
        success: true,
        error: "",
      };

    case BOOKING_UPDATE_FAIL:
      return {
        loading: false,
        booking: state.booking,
        success: false,
        error: action.payload,
      };

    case BOOKING_UPDATE_RESET:
      return { loading: false, booking: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const bookingDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case BOOKING_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case BOOKING_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case BOOKING_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
