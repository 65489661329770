// Library imports
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import CopyButton from "../components/CopyButton";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerArrayFileField from "../components/FormDrawerArrayFileField";
import FormDrawerArrayTextAreaField from "../components/FormDrawerArrayTextAreaField";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import GenerateButton from "../components/GenerateButton";
import HelpDialog from "../components/HelpDialog";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import PermissionCard from "../components/PermissionCard";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionHtmlEditor from "../components/TabSectionHtmlEditor";
import TabSectionDocumentEditorFormContainer2 from "../components/TabSectionDocumentEditorFormContainer2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldImage from "../components/TabSectionFieldImage";

// Utility imports
import printHtml from "../utilities/printHtml";
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// Document
import {
  generatePdfFromHtmlDocumentAction,
  resetGeneratePdfFromHtmlDocumentAction,
} from "../actions/documentActions";

// Insight
import {
  deleteInsightAction,
  listInsightDetailsAction,
  resetDeleteInsightAction,
  resetListInsightsAction,
  resetUpdateInsightAction,
  updateInsightAction,
} from "../actions/insightActions";

// Insight permission
import {
  createInsightPermissionAction,
  deleteInsightPermissionAction,
  listInsightPermissionsAction,
  listInsightPermissionDetailsAction,
  resetCreateInsightPermissionAction,
  resetDeleteInsightPermissionAction,
  resetUpdateInsightPermissionAction,
  updateInsightPermissionAction,
} from "../actions/insightPermissionActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Main function
function InsightDetailScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Insight id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Document
  const documentGeneratePdfFromHtml = useSelector(
    (state) => state.documentGeneratePdfFromHtml
  );

  // Insight
  const insightDelete = useSelector((state) => state.insightDelete);

  const insightDetails = useSelector((state) => state.insightDetails);

  const insightUpdate = useSelector((state) => state.insightUpdate);

  // Insight permission
  const insightPermissionCreate = useSelector(
    (state) => state.insightPermissionCreate
  );

  const insightPermissionDelete = useSelector(
    (state) => state.insightPermissionDelete
  );

  const insightPermissionDetails = useSelector(
    (state) => state.insightPermissionDetails
  );

  const insightPermissionList = useSelector(
    (state) => state.insightPermissionList
  );

  const insightPermissionUpdate = useSelector(
    (state) => state.insightPermissionUpdate
  );

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("insight-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Configuration
  const [updateConfigurationDrawerIsOpen, setUpdateConfigurationDrawerIsOpen] =
    useState(false);

  // Outline
  const [updateOutlineDrawerIsOpen, setUpdateOutlineDrawerIsOpen] =
    useState(false);

  // Style
  const [updateStyleDrawerIsOpen, setUpdateStyleDrawerIsOpen] = useState(false);

  // Content
  const [html, setHtml] = useState("");

  // Search and filter variables
  // Insight permission
  const insightPermissionQueryDefault = {
    insight: id,
    page: 1,
    searchString: "",
  };
  const [insightPermissionQuery, setInsightPermissionQuery] = useState({
    ...insightPermissionQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Form variables
  // Insight
  const insightDefault = {
    about: "",
    audience: "",
    columns: false,
    conclusion: "",
    coverImage: "",
    coverImageName: "",
    description: "",
    detail: "",
    font: "Sintony",
    fontSize: "Small",
    footer: "",
    goal: "",
    introduction: "",
    keywords: "",
    manager: "",
    managerName: "",
    name: "",
    primaryColour: "#1C6E42",
    references: "",
    restricted: true,
    secondaryColour: "#32A467",
    sectionLength: 200,
    sectionNumber: 7,
    sections: [],
    status: "Draft",
    title: "",
  };
  const [insight, setInsight] = useState({
    ...insightDefault,
  });

  // Insight content
  const insightContentDefault = {
    about: "",
    columns: false,
    conclusion: "",
    coverImage: "",
    font: "Sintony",
    fontSize: "Small",
    footer: "",
    introduction: "",
    primaryColour: "#1C6E42",
    references: "",
    secondaryColour: "#32A467",
    sections: [],
    title: "",
  };
  const [insightContent, setInsightContent] = useState({
    ...insightContentDefault,
  });

  // Insight permission
  const insightPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [insightPermission, setInsightPermission] = useState({
    ...insightPermissionDefault,
  });

  // Other
  const [insightDeleteKey, setInsightDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Insight
    setInsight({
      ...insightDefault,
    });

    // Insight permission
    setInsightPermission({
      ...insightPermissionDefault,
    });

    // Query variables
    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // Initialise drawer variables for insight
  useEffect(() => {
    setInsight({
      about: insightDetails.insight.about
        ? insightDetails.insight.about
        : insightDefault.about,
      audience: insightDetails.insight.audience
        ? insightDetails.insight.audience
        : insightDefault.audience,
      columns:
        insightDetails.insight.columns != null
          ? insightDetails.insight.columns
          : insightDefault.columns,
      conclusion: insightDetails.insight.conclusion
        ? insightDetails.insight.conclusion
        : insightDefault.conclusion,
      coverImage: insightDetails.insight.cover_image
        ? insightDetails.insight.cover_image
        : insightDefault.coverImage,
      coverImageName: insightDetails.insight.cover_image
        ? "Cover Image"
        : insightDefault.coverImageName,
      description: insightDetails.insight.description
        ? insightDetails.insight.description
        : insightDefault.description,
      detail: insightDetails.insight.detail
        ? insightDetails.insight.detail
        : insightDefault.detail,
      font: insightDetails.insight.font
        ? insightDetails.insight.font
        : insightDefault.font,
      fontSize: insightDetails.insight.font_size
        ? insightDetails.insight.font_size
        : insightDefault.fontSize,
      footer: insightDetails.insight.footer
        ? insightDetails.insight.footer
        : insightDefault.footer,
      goal: insightDetails.insight.goal
        ? insightDetails.insight.goal
        : insightDefault.goal,
      introduction: insightDetails.insight.introduction
        ? insightDetails.insight.introduction
        : insightDefault.introduction,
      keywords: insightDetails.insight.keywords
        ? insightDetails.insight.keywords
        : insightDefault.keywords,
      manager: insightDetails.insight.manager
        ? insightDetails.insight.manager
        : insightDefault.manager,
      managerName: insightDetails.insight.manager_name
        ? insightDetails.insight.manager_name
        : insightDefault.managerName,
      name: insightDetails.insight.name
        ? insightDetails.insight.name
        : insightDefault.name,
      primaryColour: insightDetails.insight.primary_colour
        ? insightDetails.insight.primary_colour
        : insightDefault.primaryColour,
      references: insightDetails.insight.references
        ? insightDetails.insight.references
        : insightDefault.references,
      restricted:
        insightDetails.insight.restricted != null
          ? insightDetails.insight.restricted
          : insightDefault.restricted,
      secondaryColour: insightDetails.insight.secondary_colour
        ? insightDetails.insight.secondary_colour
        : insightDefault.secondaryColour,
      sectionLength: insightDetails.insight.section_length
        ? insightDetails.insight.section_length
        : insightDefault.sectionLength,
      sectionNumber: insightDetails.insight.section_number
        ? insightDetails.insight.section_number
        : insightDefault.sectionNumber,
      sections:
        insightDetails.insight.sections &&
        insightDetails.insight.sections.length > 0
          ? insightDetails.insight.sections
          : insightDefault.sections,
      status: insightDetails.insight.status
        ? insightDetails.insight.status
        : insightDefault.status,
      title: insightDetails.insight.title
        ? insightDetails.insight.title
        : insightDefault.title,
    });
    // eslint-disable-next-line
  }, [insightDetails.insight]);

  // Initialise drawer variables for insight content
  useEffect(() => {
    if (insightDetails.insight) {
      setInsightContent({
        about: insightDetails.insight.about
          ? insightDetails.insight.about
          : insightContentDefault.about,
        columns:
          insightDetails.insight.columns != null
            ? insightDetails.insight.columns
            : insightContentDefault.columns,
        conclusion: insightDetails.insight.conclusion
          ? insightDetails.insight.conclusion
          : insightContentDefault.conclusion,
        coverImage: insightDetails.insight.cover_image
          ? insightDetails.insight.cover_image
          : insightContentDefault.coverImage,
        font: insightDetails.insight.font
          ? insightDetails.insight.font
          : insightContentDefault.font,
        fontSize: insightDetails.insight.font_size
          ? insightDetails.insight.font_size
          : insightContentDefault.fontSize,
        footer: insightDetails.insight.footer
          ? insightDetails.insight.footer
          : insightContentDefault.footer,
        introduction: insightDetails.insight.introduction
          ? insightDetails.insight.introduction
          : insightContentDefault.introduction,
        primaryColour: insightDetails.insight.primary_colour
          ? insightDetails.insight.primary_colour
          : insightContentDefault.primaryColour,
        references: insightDetails.insight.references
          ? insightDetails.insight.references
          : insightContentDefault.references,
        secondaryColour: insightDetails.insight.secondary_colour
          ? insightDetails.insight.secondary_colour
          : insightContentDefault.secondaryColour,
        sections:
          insightDetails.insight.sections &&
          insightDetails.insight.sections.length > 0
            ? insightDetails.insight.sections
            : insightContentDefault.sections,
        title: insightDetails.insight.title
          ? insightDetails.insight.title
          : insightContentDefault.title,
      });
    }
    // eslint-disable-next-line
  }, [insightDetails.insight]);

  // Initialise drawer variables for insight permission
  useEffect(() => {
    setInsightPermission({
      level: insightPermissionDetails.insight_permission.level
        ? insightPermissionDetails.insight_permission.level
        : insightPermissionDefault.level,
      manager: insightPermissionDetails.insight_permission.manager
        ? insightPermissionDetails.insight_permission.manager
        : insightPermissionDefault.manager,
      managerName: insightPermissionDetails.insight_permission.manager_name
        ? insightPermissionDetails.insight_permission.manager_name
        : insightPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [insightPermissionDetails.insight_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If insight deleted redirect to insights
  useEffect(() => {
    if (insightDelete.success) {
      dispatch(resetDeleteInsightAction());

      dispatch(resetListInsightsAction());

      navigate("/insights");
    }
    // eslint-disable-next-line
  }, [insightDelete.success]);

  // Load insight details
  useEffect(() => {
    dispatch(listInsightDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter insight permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listInsightPermissionsAction({ ...insightPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [insightPermissionQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Form submission
  // Insight
  // Delete insight
  const deleteInsight = () => {
    // Dispatch action
    insightDeleteKey === insightDetails.insight.name
      ? dispatch(deleteInsightAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the insight's name",
        });
  };

  // Update insight configuration
  const updateConfiguration = () => {
    // Prepare data
    const insight_data = new FormData();

    insight.audience
      ? insight_data.append("audience", insight.audience)
      : insight_data.append("audience", insightDefault.audience);

    insight.goal
      ? insight_data.append("goal", insight.goal)
      : insight_data.append("goal", insightDefault.goal);

    insight.keywords
      ? insight_data.append("keywords", insight.keywords)
      : insight_data.append("keywords", insightDefault.keywords);

    insight.sectionLength
      ? insight_data.append("section_length", insight.sectionLength)
      : insight_data.append("section_length", insightDefault.sectionLength);

    insight.sectionNumber
      ? insight_data.append("section_number", insight.sectionNumber)
      : insight_data.append("section_number", insightDefault.sectionNumber);

    // Dispatch action
    dispatch(updateInsightAction(insightDetails.insight.id, insight_data));
  };

  // Update insight content
  const [updatingContent, setUpdatingContent] = useState(false);

  const updateContent = () => {
    // Prepare data
    const insight_data = new FormData();

    insightContent.about
      ? insight_data.append("about", insightContent.about)
      : insight_data.append("about", insightContentDefault.about);

    insightContent.conclusion
      ? insight_data.append("conclusion", insightContent.conclusion)
      : insight_data.append("conclusion", insightContentDefault.conclusion);

    insightContent.footer
      ? insight_data.append("footer", insightContent.footer)
      : insight_data.append("footer", insightContentDefault.footer);

    insightContent.introduction
      ? insight_data.append("introduction", insightContent.introduction)
      : insight_data.append("introduction", insightContentDefault.introduction);

    insightContent.references
      ? insight_data.append("references", insightContent.references)
      : insight_data.append("references", insightContentDefault.references);

    insightContent.sections
      ? insight_data.append("sections", JSON.stringify(insightContent.sections))
      : insight_data.append(
          "sections",
          JSON.stringify(insightContentDefault.sections)
        );

    // Dispatch action
    setUpdatingContent(true);

    dispatch(updateInsightAction(insightDetails.insight.id, insight_data));
  };

  useEffect(() => {
    if (insightUpdate.success && updatingContent) {
      dispatch(listInsightDetailsAction(id));

      setUpdatingContent(false);
    }
    // eslint-disable-next-line
  }, [insightUpdate.success]);

  // Update insight outline
  const updateOutline = () => {
    // Prepare data
    const insight_data = new FormData();

    insight.conclusion
      ? insight_data.append("conclusion", insight.conclusion)
      : insight_data.append("conclusion", insightDefault.conclusion);

    insight.introduction
      ? insight_data.append("introduction", insight.introduction)
      : insight_data.append("introduction", insightDefault.introduction);

    insight.sections
      ? insight_data.append("sections", JSON.stringify(insight.sections))
      : insight_data.append(
          "sections",
          JSON.stringify(insightDefault.sections)
        );

    insight.title
      ? insight_data.append("title", insight.title)
      : insight_data.append("title", insightDefault.title);

    // Dispatch action
    dispatch(updateInsightAction(insightDetails.insight.id, insight_data));
  };

  // Update insight style
  const updateStyle = () => {
    // Submit
    const submit = (coverImage) => {
      // Prepare data
      const insight_data = new FormData();

      insight.columns != null
        ? insight_data.append("columns", insight.columns)
        : insight_data.append("columns", insightDefault.columns);

      if (coverImage && coverImage !== insightDetails.insight.cover_image) {
        insight_data.append("cover_image", coverImage);
      } else if (coverImage === "") {
        insight_data.append("cover_image", insightDefault.coverImage);
      }

      insight.font
        ? insight_data.append("font", insight.font)
        : insight_data.append("font", insightDefault.font);

      insight.fontSize
        ? insight_data.append("font_size", insight.fontSize)
        : insight_data.append("font_size", insightDefault.fontSize);

      insight.primaryColour
        ? insight_data.append("primary_colour", insight.primaryColour)
        : insight_data.append("primary_colour", insightDefault.primaryColour);

      insight.secondaryColour
        ? insight_data.append("secondary_colour", insight.secondaryColour)
        : insight_data.append(
            "secondary_colour",
            insightDefault.secondaryColour
          );

      // Dispatch action
      dispatch(updateInsightAction(insightDetails.insight.id, insight_data));
    };

    // Process cover image
    if (insight.coverImage instanceof Blob) {
      const reader = new FileReader();

      reader.onloadend = () => {
        submit(reader.result);
      };

      reader.readAsDataURL(insight.coverImage);
    } else {
      submit(insight.coverImage);
    }
  };

  // Update insight summary
  const updateSummary = () => {
    // Prepare data
    const insight_data = new FormData();

    insight.description
      ? insight_data.append("description", insight.description)
      : insight_data.append("description", insightDefault.description);

    insight.manager
      ? insight_data.append("manager", insight.manager)
      : insight_data.append("manager", insightDefault.manager);

    insight.name
      ? insight_data.append("name", insight.name)
      : insight_data.append("name", insightDefault.name);

    insight.restricted != null
      ? insight_data.append("restricted", insight.restricted)
      : insight_data.append("restricted", insightDefault.restricted);

    insight.status
      ? insight_data.append("status", insight.status)
      : insight_data.append("status", insightDefault.status);

    // Dispatch action
    dispatch(updateInsightAction(insightDetails.insight.id, insight_data));
  };

  useEffect(() => {
    if (insightUpdate.success) {
      dispatch(listInsightDetailsAction(id));

      dispatch(resetUpdateInsightAction());
    }
    // eslint-disable-next-line
  }, [insightUpdate.success]);

  // Insight permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const insight_permission = new FormData();

    insightPermission.level
      ? insight_permission.append("level", insightPermission.level)
      : insight_permission.append("level", insightPermissionDefault.level);

    insightPermission.manager
      ? insight_permission.append("manager", insightPermission.manager)
      : insight_permission.append("manager", insightPermissionDefault.manager);

    insight_permission.append("insight", id);

    // Dispatch action
    dispatch(createInsightPermissionAction(insight_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteInsightPermissionAction(
        insightPermissionDetails.insight_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const insight_permission = new FormData();

    insightPermission.level
      ? insight_permission.append("level", insightPermission.level)
      : insight_permission.append("level", insightPermissionDefault.level);

    insightPermission.manager
      ? insight_permission.append("manager", insightPermission.manager)
      : insight_permission.append("manager", insightPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateInsightPermissionAction(
        insightPermissionDetails.insight_permission.id,
        insight_permission
      )
    );
  };

  // Download pdf
  const downloadPdf = () => {
    if (html) {
      const htmlString = renderToStaticMarkup(html);

      dispatch(
        generatePdfFromHtmlDocumentAction({
          data: htmlString,
        })
      );
    }
  };

  useEffect(() => {
    if (documentGeneratePdfFromHtml.success) {
      saveAs(documentGeneratePdfFromHtml.pdf, `${insightDetails.insight.name}`);

      dispatch(resetGeneratePdfFromHtmlDocumentAction());
    }
    // eslint-disable-next-line
  }, [documentGeneratePdfFromHtml.success]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Give the insight a title</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Insight name */}
                  <FormDrawerTextField2
                    error={insightUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Insight name"
                    labelInfo="(required)"
                    placeholder="Enter the insight's name"
                    setValueObject={setInsight}
                    setValueVariable="name"
                    value={insight.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Issue */}
              <FormDrawerTextAreaField3
                error={insightUpdate.error}
                fieldName="description"
                helperText="advaise.app can use this description to generate content from the organisation's knowledge base"
                label="Issue"
                placeholder="Describe the client opportunity or problem"
                setValueObject={setInsight}
                setValueVariable="description"
                value={insight.description}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Status */}
                  <FormDrawerButtonField
                    disabled={
                      insightDetails.insight.user_access_status === "edit" ||
                      insightDetails.insight.user_access_status === "view"
                    }
                    error={insightUpdate.error}
                    fieldName="status"
                    helperText="Insights in draft status cannot be linked to markets"
                    label="Status"
                    options={[
                      {
                        icon: "tick-circle",
                        id: 1,
                        intent: "success",
                        label: "Approved",
                        value: "Approved",
                      },
                      {
                        icon: "new-drawing",
                        id: 2,
                        intent: "primary",
                        label: "Draft",
                        value: "Draft",
                      },
                    ]}
                    setValueObject={setInsight}
                    setValueVariable="status"
                    value={insight.status}
                  />

                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      insightDetails.insight.user_access_status === "edit" ||
                      insightDetails.insight.user_access_status === "view"
                    }
                    error={insightUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted insight can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setInsight}
                    setValueVariable="restricted"
                    value={insight.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      insightDetails.insight.user_access_status === "edit" ||
                      insightDetails.insight.user_access_status === "view"
                    }
                    error={insightUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the insight - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInsight}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={insight.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the insight manager and the
                    insight is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={insightUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update insight"
            module="Insights"
            view="Detail"
          />
        }
        helpTitle="Updating an insight"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={insightDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listInsightDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateInsightAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightUpdate.success}
        saveSuccessMessage="Insight updated."
        saving={insightUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update insight"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={insightPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setInsightPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={insightPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={insightPermissionCreate.error}
              fieldName="level"
              intent={
                insightPermission.level === "Edit"
                  ? "success"
                  : insightPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setInsightPermission}
              setValueVariable="level"
              value={insightPermission.level}
            />
          </Card>
        }
        error={insightPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Insights"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listInsightPermissionsAction({ insight: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateInsightPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={insightPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={insightPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setInsightPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={insightPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={insightPermissionUpdate.error}
              fieldName="level"
              intent={
                insightPermission.level === "Edit"
                  ? "success"
                  : insightPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setInsightPermission}
              setValueVariable="level"
              value={insightPermission.level}
            />
          </Card>
        }
        deleteSuccess={insightPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={insightPermissionDelete.loading}
        error={insightPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Insights"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={insightPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listInsightPermissionsAction({ ...insightPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteInsightPermissionAction());

          dispatch(resetUpdateInsightPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={insightPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* Configuration form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ width: "100%" }}>
              <H5>Define parameters</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Audience */}
              <FormDrawerTextAreaField3
                error={insightUpdate.error}
                fieldName="audience"
                height={100}
                label="Audience"
                placeholder="Describe the audience"
                setValueObject={setInsight}
                setValueVariable="audience"
                value={insight.audience}
              />

              {/* Goal */}
              <FormDrawerTextAreaField3
                error={insightUpdate.error}
                fieldName="goal"
                height={100}
                label="Goal"
                placeholder="Describe the action you would like the audience to take"
                setValueObject={setInsight}
                setValueVariable="goal"
                value={insight.goal}
              />

              <Divider style={{ margin: "15px 0 15px 0" }} />

              {/* Number of sections */}
              <FormDrawerSliderField
                error={insightUpdate.error}
                fieldName="section_number"
                helperText="The actual number of sections may vary"
                intent="primary"
                label="Number of sections"
                labelRenderer={(value) => {
                  switch (value) {
                    case 1:
                      return <span style={{ whiteSpace: "nowrap" }}>1</span>;
                    case 2:
                      return <span style={{ whiteSpace: "nowrap" }}>2</span>;
                    case 3:
                      return <span style={{ whiteSpace: "nowrap" }}>3</span>;
                    case 4:
                      return <span style={{ whiteSpace: "nowrap" }}>4</span>;
                    case 5:
                      return <span style={{ whiteSpace: "nowrap" }}>5</span>;
                    case 6:
                      return <span style={{ whiteSpace: "nowrap" }}>6</span>;
                    case 7:
                      return <span style={{ whiteSpace: "nowrap" }}>7</span>;
                    case 8:
                      return <span style={{ whiteSpace: "nowrap" }}>8</span>;
                    case 9:
                      return <span style={{ whiteSpace: "nowrap" }}>9</span>;
                    case 10:
                      return <span style={{ whiteSpace: "nowrap" }}>10</span>;
                    case 11:
                      return <span style={{ whiteSpace: "nowrap" }}>11</span>;
                    case 12:
                      return <span style={{ whiteSpace: "nowrap" }}>12</span>;
                    case 13:
                      return <span style={{ whiteSpace: "nowrap" }}>13</span>;
                    case 14:
                      return <span style={{ whiteSpace: "nowrap" }}>14</span>;
                    case 15:
                      return <span style={{ whiteSpace: "nowrap" }}>15</span>;
                    default:
                      return null;
                  }
                }}
                labelStepSize={1}
                margin="50px"
                max={15}
                min={1}
                setValueObject={setInsight}
                setValueVariable="sectionNumber"
                value={insight.sectionNumber}
              />

              <Divider style={{ margin: "15px 0 15px 0" }} />

              {/* Section length */}
              <FormDrawerSliderField
                error={insightUpdate.error}
                fieldName="section_length"
                helperText="The actual length of individual sections may vary"
                intent="primary"
                label="Section length"
                labelInfo="(words)"
                labelRenderer={(value) => {
                  switch (value) {
                    case 100:
                      return <span style={{ whiteSpace: "nowrap" }}>100</span>;
                    case 200:
                      return <span style={{ whiteSpace: "nowrap" }}>200</span>;
                    case 300:
                      return <span style={{ whiteSpace: "nowrap" }}>300</span>;
                    case 400:
                      return <span style={{ whiteSpace: "nowrap" }}>400</span>;
                    case 500:
                      return <span style={{ whiteSpace: "nowrap" }}>500</span>;
                    default:
                      return null;
                  }
                }}
                labelStepSize={1}
                margin="50px"
                max={500}
                min={100}
                setValueObject={setInsight}
                setValueVariable="sectionLength"
                value={insight.sectionLength}
              />

              <Divider style={{ margin: "15px 0 15px 0" }} />

              {/* Keywords */}
              <FormDrawerTextAreaField3
                error={insightUpdate.error}
                fieldName="keywords"
                height={100}
                helperText="Comma-separated list of keywords"
                label="Keywords"
                noRewrite
                placeholder="Enter the keywords"
                setValueObject={setInsight}
                setValueVariable="keywords"
                value={insight.keywords}
              />
            </Card>
          </div>
        }
        error={insightUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Configure AI engine"
            module="Insights"
            view="Detail"
          />
        }
        helpTitle="Configuring the AI engine"
        icon="edit"
        isOpen={updateConfigurationDrawerIsOpen}
        loading={insightDetails.loading}
        onSave={updateConfiguration}
        refreshList={() => {
          dispatch(listInsightDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateInsightAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightUpdate.success}
        saveSuccessMessage="AI configuration updated."
        saving={insightUpdate.loading}
        setIsOpen={setUpdateConfigurationDrawerIsOpen}
        title="Configure AI engine"
      />

      {/* Outline form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Callout intent="warning" style={{ margin: "0 0 15px 0" }}>
              Changing the outline may delete the content you have written.
            </Callout>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Title</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Title */}
              <FormDrawerTextAreaField3
                context="You are an AI that writes catchy headlines for an article or social media post"
                error={insightUpdate.error}
                fieldName="title"
                height={40}
                minLength={5}
                placeholder="Enter the title"
                setValueObject={setInsight}
                setValueVariable="title"
                value={insight.title}
              />
            </Card>

            {insight.sections.map((section, i, { length }) => {
              return (
                <Card
                  key={i}
                  style={{
                    margin: length - 1 === i ? undefined : "0 0 10px 0",
                    width: "100%",
                  }}
                >
                  <H5>Section {i + 1}</H5>

                  <Divider style={{ margin: "0 0 15px 0" }} />

                  {/* Header */}
                  <FormDrawerArrayTextAreaField
                    array={insight.sections}
                    arrayField="title"
                    arrayPosition={i}
                    context="You are an AI that writes catchy headlines for an article or social media post"
                    error={insightUpdate.error}
                    fieldName="sections"
                    height={40}
                    label="Header"
                    minLength={5}
                    placeholder="Enter the section header"
                    setValueObject={setInsight}
                    setValueVariable="sections"
                  />

                  {/* Summary */}
                  <FormDrawerArrayTextAreaField
                    array={insight.sections}
                    arrayField="summary"
                    arrayPosition={i}
                    context="You are an AI that summarises sections of an article or social media post"
                    error={insightUpdate.error}
                    fieldName="sections"
                    height={100}
                    label="Summary"
                    minLength={5}
                    placeholder="Enter the main idea or topic of this section"
                    setValueObject={setInsight}
                    setValueVariable="sections"
                  />

                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      margin: "0 0 5px 0",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      icon="trash"
                      minimal
                      onClick={() => {
                        const newSections = Object.assign([], insight.sections);

                        newSections.splice(i, 1);

                        setInsight((prevState) => {
                          return {
                            ...prevState,
                            sections: newSections,
                          };
                        });
                      }}
                    />

                    <ButtonGroup minimal>
                      <Button
                        disabled={i === 0}
                        icon="arrow-up"
                        onClick={() => {
                          if (i !== 0) {
                            const newSections = Object.assign(
                              [],
                              insight.sections
                            );

                            const thisSection = newSections.splice(i, 1)[0];

                            newSections.splice(i - 1, 0, thisSection);

                            setInsight((prevState) => {
                              return {
                                ...prevState,
                                sections: newSections,
                              };
                            });
                          }
                        }}
                      />

                      <Button
                        disabled={length - 1 === i}
                        icon="arrow-down"
                        onClick={() => {
                          if (length - 1 !== i) {
                            const newSections = Object.assign(
                              [],
                              insight.sections
                            );

                            const thisSection = newSections.splice(i, 1)[0];

                            newSections.splice(i + 1, 0, thisSection);

                            setInsight((prevState) => {
                              return {
                                ...prevState,
                                sections: newSections,
                              };
                            });
                          }
                        }}
                      />
                    </ButtonGroup>
                  </div>
                </Card>
              );
            })}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "15px 0 0 0",
              }}
            >
              <GenerateButton
                disabled={
                  !insightDetails.insight.description ||
                  insightDetails.insight.user_access_status === "view"
                }
                objectDescription={`
                      --------------------
                      Audience: ${insightDetails.insight.audience}
                      Description: ${insightDetails.insight.description}
                      Goal: ${insightDetails.insight.goal}
                      Keywords: ${insightDetails.insight.keywords}
                      Number of sections: ${insightDetails.insight.section_number}
                      --------------------
                    `}
                objectType="article outline"
                onSuccess={(result) => {
                  if (
                    !("conclusion" in result) ||
                    result.conclusion === "" ||
                    !("introduction" in result) ||
                    result.introduction === "" ||
                    !("sections" in result) ||
                    result.sections.length === 0 ||
                    !("title" in result) ||
                    result.title === ""
                  ) {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "warning",
                      message: "Unable to generate the outline.",
                    });
                  } else {
                    AppToaster.show({
                      icon: "tick-circle",
                      intent: "success",
                      message: "Outline generation complete.",
                    });

                    setInsight((prevState) => {
                      return {
                        ...prevState,
                        conclusion: result.conclusion,
                        introduction: result.introduction,
                        sections: result.sections,
                        title: result.title,
                      };
                    });
                  }
                }}
              />

              <Button
                icon="add"
                intent="primary"
                onClick={() => {
                  const newSections = Object.assign([], insight.sections);

                  newSections.push({
                    summary: "",
                    title: "",
                  });

                  setInsight((prevState) => {
                    return {
                      ...prevState,
                      sections: newSections,
                    };
                  });
                }}
                text="Add"
              />
            </div>
          </div>
        }
        error={insightUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Plan the content"
            module="Insights"
            view="Detail"
          />
        }
        helpTitle="Planning the content"
        icon="edit"
        isOpen={updateOutlineDrawerIsOpen}
        loading={insightDetails.loading}
        onSave={updateOutline}
        refreshList={() => {
          dispatch(listInsightDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateInsightAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightUpdate.success}
        saveSuccessMessage="Content outline updated."
        saving={insightUpdate.loading}
        setIsOpen={setUpdateOutlineDrawerIsOpen}
        size="medium"
        title="Plan the content"
      />

      {/* Style form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Style the text</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Font */}
                <FormDrawerSelectSimpleField3
                  error={insightUpdate.error}
                  fieldName="font"
                  label="Font"
                  options={[
                    { id: 1, icon: "font", value: "Courier" },
                    { id: 2, icon: "font", value: "Helvetica" },
                    { id: 3, icon: "font", value: "Montserrat" },
                    { id: 4, icon: "font", value: "Roboto" },
                    { id: 5, icon: "font", value: "Sintony" },
                    { id: 6, icon: "font", value: "Times-Roman" },
                  ]}
                  setValueObject={setInsight}
                  setValueVariable="font"
                  value={insight.font}
                />

                {/* Font size */}
                <FormDrawerSelectSimpleField3
                  error={insightUpdate.error}
                  fieldName="font_size"
                  label="Font size"
                  options={[
                    { id: 1, icon: "search-text", value: "Large" },
                    { id: 2, icon: "search-text", value: "Medium" },
                    { id: 3, icon: "search-text", value: "Small" },
                  ]}
                  setValueObject={setInsight}
                  setValueVariable="fontSize"
                  value={insight.fontSize}
                />

                {/* Title colour */}
                <FormDrawerTextField2
                  error={insightUpdate.error}
                  fieldName="primary_colour"
                  helperText="Hex code starting with #"
                  icon="color-fill"
                  label="Title colour"
                  placeholder="Enter a hex code"
                  rightElement={
                    <Tag
                      round
                      style={{
                        backgroundColor: insight.primaryColour,
                        width: "50px",
                      }}
                    ></Tag>
                  }
                  setValueObject={setInsight}
                  setValueVariable="primaryColour"
                  value={insight.primaryColour}
                />

                {/* Header colour */}
                <FormDrawerTextField2
                  error={insightUpdate.error}
                  fieldName="secondary_colour"
                  helperText="Hex code starting with #"
                  icon="color-fill"
                  label="Header colour"
                  placeholder="Enter a hex code"
                  rightElement={
                    <Tag
                      round
                      style={{
                        backgroundColor: insight.secondaryColour,
                        width: "50px",
                      }}
                    ></Tag>
                  }
                  setValueObject={setInsight}
                  setValueVariable="secondaryColour"
                  value={insight.secondaryColour}
                />

                {/* Layout */}
                <FormDrawerButtonField
                  error={insightUpdate.error}
                  fieldName="columns"
                  label="Layout"
                  options={[
                    {
                      icon: "align-justify",
                      id: 1,
                      intent: "primary",
                      label: "Single Column",
                      value: false,
                    },
                    {
                      icon: "list-columns",
                      id: 2,
                      intent: "primary",
                      label: "Multi Column",
                      value: true,
                    },
                  ]}
                  setValueObject={setInsight}
                  setValueVariable="columns"
                  value={insight.columns}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Cover image */}
                <FormDrawerFileField2
                  error={insightUpdate.error}
                  fileName="cover_image"
                  label="Cover image"
                  placeholder="Upload the image"
                  setValueObject={setInsight}
                  setValueFileVariable="coverImage"
                  setValueNameVariable="coverImageName"
                  valueFile={insight.coverImage}
                  valueName={insight.coverImageName}
                />

                {/* Cover image preview */}
                <div style={{ margin: "0 0 20px 0" }}>
                  {insight.coverImage ? (
                    insight.coverImage !==
                    insightDetails.insight.cover_image ? (
                      <TabSectionFieldImage
                        image={URL.createObjectURL(
                          new Blob([insight.coverImage])
                        )}
                      />
                    ) : (
                      <TabSectionFieldImage
                        image={insightDetails.insight.cover_image}
                      />
                    )
                  ) : undefined}
                </div>
              </div>
            </div>
          </Card>
        }
        error={insightUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update style"
            module="Insights"
            view="Detail"
          />
        }
        helpTitle="Styling text"
        icon="edit"
        isOpen={updateStyleDrawerIsOpen}
        loading={insightDetails.loading}
        onSave={updateStyle}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightUpdate.success}
        saving={insightUpdate.loading}
        setIsOpen={setUpdateStyleDrawerIsOpen}
        title="Update style"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Insights"
              onClick={() => {
                dispatch(resetListInsightsAction());

                navigate("/insights");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          insightDetails.insight.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={insightDetails.loading}
        title={`${insightDetails.insight.name}`}
      />

      {/* Error alerts */}
      {/* Insight delete error */}
      {insightDelete.error && (
        <ErrorAlert message="We couldn't delete the insight. Try refreshing the page." />
      )}
      {/* Insight loading error */}
      {insightDetails.error && (
        <ErrorAlert message="We couldn't load the insight. Try refreshing the page." />
      )}
      {/* Insight permissions loading error */}
      {insightPermissionList.error && (
        <ErrorAlert message="We couldn't load the insight permissions. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Insight"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="insight-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="insight-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          insightDetails.insight.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listInsightDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={insightDetails.loading}
                          value={insightDetails.insight.id}
                        />

                        {/* Insight name */}
                        <TabSectionField
                          label="Insight name"
                          loading={insightDetails.loading}
                          value={insightDetails.insight.name}
                        />

                        {/* Description */}
                        <TabSectionField
                          label="Description"
                          loading={insightDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={insightDetails.insight.description}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={insightDetails.loading}
                          value={
                            <Tag
                              intent={
                                insightDetails.insight.status === "Approved"
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {insightDetails.insight.status}
                            </Tag>
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            insightDetails.insight.restricted
                              ? "Users must have permission to access this insight"
                              : "Everyone in the organisation can edit this insight"
                          }
                          label="Access"
                          loading={insightDetails.loading}
                          value={
                            <Tag
                              intent={
                                insightDetails.insight.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {insightDetails.insight.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            insightDetails.insight.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={insightDetails.loading}
                          value={
                            <NameValue
                              email={insightDetails.insight.manager_name}
                              image={
                                insightDetails.insight.manager_profile_photo
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update insight information"
                    icon="lightbulb"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(insightDetails.insight.user_access_status === "owner" ||
                    insightDetails.insight.user_access_status === "admin" ||
                    insightDetails.insight.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={insightPermissionList.loading}
                              setValueObject={setInsightPermissionQuery}
                              setValueVariable="searchString"
                              value={insightPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={insightPermissionList.insight_permissions.map(
                              (insight_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={insight_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listInsightPermissionDetailsAction(
                                          insight_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={insight_permission}
                                  />
                                );
                              }
                            )}
                            count={insightPermissionList.count}
                            loading={insightPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={insightPermissionQuery.page}
                            setPageObject={setInsightPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, insights are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete insight */}
                  {(insightDetails.insight.user_access_status === "owner" ||
                    insightDetails.insight.user_access_status === "admin" ||
                    insightDetails.insight.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              id="confirm-Insight-name"
                              onChange={(e) => {
                                setInsightDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the insight's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={insightDeleteKey}
                            />

                            <Button
                              icon="trash"
                              intent="danger"
                              loading={insightDelete.loading}
                              onClick={deleteInsight}
                              text="Delete this insight"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{insightDetails.insight.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the insight"
                      icon="delete"
                      message="If you delete this insight, you will lose all information associated with it."
                      messageIntent="danger"
                      title="Delete insight"
                    />
                  )}
                </div>
              }
            />

            {/* Planning */}
            <Tab
              id="insight-planning-tab"
              title="Planning"
              panel={
                <div>
                  {/* Configuration */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            insightDetails.insight.user_access_status === "view"
                          }
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(listInsightDetailsAction(id));

                            setUpdateConfigurationDrawerIsOpen(true);
                          }}
                          text="Edit"
                        />
                      </>
                    }
                    content={
                      <>
                        {/* Audience */}
                        <TabSectionField
                          label="Audience"
                          loading={insightDetails.loading}
                          value={insightDetails.insight.audience}
                        />

                        {/* Goal */}
                        <TabSectionField
                          label="Goal"
                          loading={insightDetails.loading}
                          value={insightDetails.insight.goal}
                        />

                        {/* Number of sections */}
                        <TabSectionField
                          helperText="The actual number of sections may vary"
                          label="Number of sections"
                          loading={insightDetails.loading}
                          value={insightDetails.insight.section_number}
                        />

                        {/* Section length */}
                        <TabSectionField
                          helperText="The actual length of individual sections may vary"
                          label="Section length"
                          loading={insightDetails.loading}
                          value={`${insightDetails.insight.section_length} words`}
                        />

                        {/* Keywords */}
                        <TabSectionField
                          bottom
                          label="Keywords"
                          loading={insightDetails.loading}
                          value={insightDetails.insight.keywords}
                        />
                      </>
                    }
                    help="Configure the AI engine"
                    icon="settings"
                    title="Configuration"
                  />

                  {/* Outline */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          insightDetails.insight.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listInsightDetailsAction(id));

                          setUpdateOutlineDrawerIsOpen(true);
                        }}
                        style={{ margin: "0 10px 0 0" }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Title */}
                        <TabSectionField
                          label="Title"
                          loading={insightDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={
                                <strong>
                                  {insightDetails.insight.title
                                    ? insightDetails.insight.title
                                    : "-"}
                                </strong>
                              }
                            />
                          }
                        />

                        {/* Sections */}
                        <TabSectionField
                          bottom
                          label="Sections"
                          loading={insightDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={
                                insightDetails.insight.sections &&
                                insightDetails.insight.sections.length > 0
                                  ? insightDetails.insight.sections.map(
                                      (section, i, { length }) => {
                                        return (
                                          <TabSectionField
                                            bottom={length - 1 === i}
                                            key={i}
                                            label={`${i + 1}. ${
                                              section.title
                                                ? section.title
                                                : "[No title]"
                                            }`}
                                            value={section.summary}
                                            vertical
                                          />
                                        );
                                      }
                                    )
                                  : "-"
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Plan the content"
                    icon="numbered-list"
                    title="Outline"
                  />
                </div>
              }
            />

            {/* Detail */}
            <Tab
              id="insight-detail-tab"
              title="Detail"
              panel={
                <div>
                  {/* Style */}
                  {insightDetails.insight.user_access_status !== "view" && (
                    <TabSection2
                      buttons={
                        <Button
                          disabled={
                            insightDetails.insight.user_access_status === "view"
                          }
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(listInsightDetailsAction(id));

                            setUpdateStyleDrawerIsOpen(true);
                          }}
                          text="Edit"
                        />
                      }
                      content={
                        <>
                          {/* Font */}
                          <TabSectionField
                            label="Font"
                            loading={insightDetails.loading}
                            value={insightDetails.insight.font}
                          />

                          {/* Font size */}
                          <TabSectionField
                            label="Font size"
                            loading={insightDetails.loading}
                            value={insightDetails.insight.font_size}
                          />

                          {/* Title colour */}
                          <TabSectionField
                            label="Title colour"
                            loading={insightDetails.loading}
                            value={
                              <Tag
                                large
                                round
                                style={{
                                  background:
                                    insightDetails.insight.primary_colour,
                                  color: insightDetails.insight.primary_colour
                                    ? setTextColour(
                                        insightDetails.insight.primary_colour
                                      )
                                    : undefined,
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                {insightDetails.insight.primary_colour}
                              </Tag>
                            }
                          />

                          {/* Header colour */}
                          <TabSectionField
                            label="Header colour"
                            loading={insightDetails.loading}
                            value={
                              <Tag
                                large
                                round
                                style={{
                                  background:
                                    insightDetails.insight.secondary_colour,
                                  color: insightDetails.insight.secondary_colour
                                    ? setTextColour(
                                        insightDetails.insight.secondary_colour
                                      )
                                    : undefined,
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                {insightDetails.insight.secondary_colour}
                              </Tag>
                            }
                          />

                          {/* Layout */}
                          <TabSectionField
                            label="Layout"
                            loading={insightDetails.loading}
                            value={
                              insightDetails.insight.columns
                                ? "Multi Column"
                                : "Single Column"
                            }
                          />

                          {/* Cover image */}
                          <TabSectionField
                            bottom
                            label="Cover image"
                            loading={insightDetails.loading}
                            value={
                              <TabSectionFieldImage
                                image={insightDetails.insight.cover_image}
                                height="200px"
                                interactive
                              />
                            }
                          />
                        </>
                      }
                      help="Set the content style"
                      icon="style"
                      title="Style"
                    />
                  )}

                  {/* Content */}
                  {insightDetails.insight.user_access_status !== "view" && (
                    <TabSection2
                      buttons={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Button
                              disabled={
                                insightDetails.loading ||
                                insightDetails.insight.user_access_status ===
                                  "view" ||
                                insightUpdate.loading
                              }
                              icon="floppy-disk"
                              intent="primary"
                              onClick={() => {
                                updateContent();
                              }}
                              style={{ margin: "0 10px 0 0" }}
                              text="Save"
                            />

                            {false && (
                              <Button
                                disabled={
                                  !html ||
                                  insightDetails.loading ||
                                  insightUpdate.loading
                                }
                                icon="print"
                                intent="primary"
                                onClick={() => {
                                  printHtml(html);
                                }}
                                text="Print"
                              />
                            )}

                            <Button
                              disabled={
                                documentGeneratePdfFromHtml.loading ||
                                !html ||
                                insightDetails.loading ||
                                insightUpdate.loading
                              }
                              icon="download"
                              intent="primary"
                              loading={documentGeneratePdfFromHtml.loading}
                              onClick={downloadPdf}
                              text="Download"
                            />
                          </div>

                          <ButtonGroup>
                            <CopyButton content={html} keepHtml text="HTML" />

                            <CopyButton content={html} text="Text" />
                          </ButtonGroup>
                        </div>
                      }
                      content={
                        <TabSectionHtmlEditor
                          document={insightContent}
                          error={insightUpdate.error}
                          form={
                            <>
                              {/* Introduction */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <FormDrawerTextAreaField3
                                    error={insightUpdate.error}
                                    fieldName="introduction"
                                    placeholder="Enter the introduction"
                                    setValueObject={setInsightContent}
                                    setValueVariable="introduction"
                                    value={insightContent.introduction}
                                  />
                                }
                                title="Introduction"
                              />

                              {/* Sections */}
                              {insightContent.sections.map((section, i) => {
                                return (
                                  <TabSectionDocumentEditorFormContainer2
                                    fields={
                                      <>
                                        <Callout
                                          style={{ margin: "0 0 15px 0" }}
                                        >
                                          <div>{section.summary}</div>

                                          <div
                                            style={{
                                              margin: "10px 0 0 0",
                                              textAlign: "end",
                                            }}
                                          >
                                            <GenerateButton
                                              disabled={
                                                insightDetails.insight
                                                  .user_access_status ===
                                                  "view" ||
                                                !section.summary ||
                                                !section.title
                                              }
                                              noIcon
                                              objectDescription={`
                      --------------------
                      Audience: ${insightDetails.insight.audience}
                      Description: ${section.summary}
                      Goal: ${insightDetails.insight.goal}
                      Keywords: ${insightDetails.insight.keywords}
                      Number of words: ${insightDetails.insight.section_length}
                      Title: ${section.title}
                      --------------------
                    `}
                                              objectType="article section"
                                              onSuccess={(result) => {
                                                if (
                                                  !("content" in result) ||
                                                  result.content === ""
                                                ) {
                                                  AppToaster.show({
                                                    icon: "warning-sign",
                                                    intent: "warning",
                                                    message:
                                                      "Unable to generate the content.",
                                                  });
                                                } else {
                                                  AppToaster.show({
                                                    icon: "tick-circle",
                                                    intent: "success",
                                                    message:
                                                      "Content generation complete.",
                                                  });

                                                  const newSections =
                                                    Object.assign(
                                                      [],
                                                      insightContent.sections
                                                    );

                                                  newSections[i] = {
                                                    content: result.content,
                                                    image: newSections[i].image,
                                                    summary:
                                                      newSections[i].summary,
                                                    title: newSections[i].title,
                                                  };

                                                  setInsightContent(
                                                    (prevState) => {
                                                      return {
                                                        ...prevState,
                                                        sections: newSections,
                                                      };
                                                    }
                                                  );
                                                }
                                              }}
                                              outlined
                                              small
                                            />
                                          </div>
                                        </Callout>

                                        {/* Content */}
                                        <FormDrawerArrayTextAreaField
                                          array={insightContent.sections}
                                          arrayField="content"
                                          arrayPosition={i}
                                          error={insightUpdate.error}
                                          fieldName="sections"
                                          placeholder="Enter the content of this section"
                                          setValueObject={setInsightContent}
                                          setValueVariable="sections"
                                        />

                                        {/* Image */}
                                        <FormDrawerArrayFileField
                                          array={insightContent.sections}
                                          arrayField="image"
                                          arrayPosition={i}
                                          error={insightUpdate.error}
                                          placeholder="Upload the image"
                                          setValueObject={setInsightContent}
                                          setValueVariable="sections"
                                          showControls
                                        />
                                      </>
                                    }
                                    key={i}
                                    title={`${i + 1}. ${
                                      section.title
                                        ? section.title
                                        : "[No title]"
                                    }`}
                                  />
                                );
                              })}

                              {/* Conclusion */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <FormDrawerTextAreaField3
                                    error={insightUpdate.error}
                                    fieldName="conclusion"
                                    placeholder="Enter the conclusion"
                                    setValueObject={setInsightContent}
                                    setValueVariable="conclusion"
                                    value={insightContent.conclusion}
                                  />
                                }
                                title="Conclusion"
                              />

                              {/* Additional information */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* About the author(s) */}
                                    <FormDrawerTextAreaField3
                                      error={insightUpdate.error}
                                      fieldName="about"
                                      label="About the author(s)"
                                      placeholder="Enter information about the author(s)"
                                      setValueObject={setInsightContent}
                                      setValueVariable="about"
                                      value={insightContent.about}
                                    />

                                    {/* References */}
                                    <FormDrawerTextAreaField3
                                      error={insightUpdate.error}
                                      fieldName="references"
                                      label="References"
                                      placeholder="Enter the references"
                                      setValueObject={setInsightContent}
                                      setValueVariable="references"
                                      value={insightContent.references}
                                    />

                                    {/* Footer */}
                                    <FormDrawerTextField2
                                      error={insightUpdate.error}
                                      fieldName="footer"
                                      helperText="Appears on printed media"
                                      icon="widget-footer"
                                      label="Footer"
                                      placeholder="Enter the footer"
                                      setValueObject={setInsightContent}
                                      setValueVariable="footer"
                                      value={insightContent.footer}
                                    />
                                  </>
                                }
                                title="Additional information"
                              />
                            </>
                          }
                          loading={
                            insightDetails.loading || insightUpdate.loading
                          }
                          setHtml={setHtml}
                        />
                      }
                      help="Edit the content"
                      icon="book"
                      title="Content"
                    />
                  )}
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default InsightDetailScreen;
