// Constant imports
import {
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_LIST_RESET,
  CURRENCY_CREATE_REQUEST,
  CURRENCY_CREATE_SUCCESS,
  CURRENCY_CREATE_FAIL,
  CURRENCY_CREATE_RESET,
  CURRENCY_DETAILS_REQUEST,
  CURRENCY_DETAILS_SUCCESS,
  CURRENCY_DETAILS_FAIL,
  CURRENCY_DETAILS_RESET,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_DELETE_FAIL,
  CURRENCY_DELETE_RESET,
  CURRENCY_GET_CURRENCY_CHOICES_REQUEST,
  CURRENCY_GET_CURRENCY_CHOICES_SUCCESS,
  CURRENCY_GET_CURRENCY_CHOICES_FAIL,
  CURRENCY_GET_CURRENCY_CHOICES_RESET,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_REQUEST,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_SUCCESS,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_FAIL,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_RESET,
  CURRENCY_GET_RATE_REQUEST,
  CURRENCY_GET_RATE_SUCCESS,
  CURRENCY_GET_RATE_FAIL,
  CURRENCY_GET_RATE_RESET,
} from "../constants/currencyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listCurrenciesAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getCurrencies(params, token);

    dispatch({
      type: CURRENCY_LIST_SUCCESS,

      payload: {
        count: data.count,
        currencies: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListCurrenciesAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_LIST_RESET });
};

// Create
export const createCurrencyAction =
  (currency) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createCurrency(token, currency);

      dispatch({
        type: CURRENCY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateCurrencyAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_CREATE_RESET });
};

// List details
export const listCurrencyDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getCurrency(id, token);

    dispatch({
      type: CURRENCY_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListCurrencyDetailsAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_DETAILS_RESET });
};

// Delete
export const deleteCurrencyAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENCY_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteCurrency(id, token);

    dispatch({
      type: CURRENCY_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CURRENCY_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteCurrencyAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_DELETE_RESET });
};

// Get currency choices
export const getCurrencyChoicesCurrencyAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_GET_CURRENCY_CHOICES_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCurrencyChoices(token);

      dispatch({
        type: CURRENCY_GET_CURRENCY_CHOICES_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_GET_CURRENCY_CHOICES_FAIL,

        payload: error,
      });
    }
  };

// Reset get rate
export const resetGetCurrencyChoicesCurrencyAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_GET_CURRENCY_CHOICES_RESET });
};

// Get organisation currency choices
export const getOrganisationCurrencyChoicesCurrencyAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOrganisationCurrencyChoices(
        params,
        token
      );

      dispatch({
        type: CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_FAIL,

        payload: error,
      });
    }
  };

// Reset get rate
export const resetGetOrganisationCurrencyChoicesCurrencyAction =
  () => async (dispatch) => {
    dispatch({ type: CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_RESET });
  };

// Get rate
export const getRateCurrencyAction =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_GET_RATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCurrencyRate(
        id,
        token,
        params
      );

      dispatch({
        type: CURRENCY_GET_RATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_GET_RATE_FAIL,

        payload: error,
      });
    }
  };

// Reset get rate
export const resetGetRateCurrencyAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_GET_RATE_RESET });
};
