// Library imports
import { Colors, Divider, H5, Tag, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function EngagementActualsCard({ fill, engagementList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Actuals</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Engagement financials to-date for the selected engagement(s),
            covering:
          </p>

          <UL>
            <li>Invoiced: Total value of invoices issued.</li>

            <li>
              Revenue: Revenue recognised to-date, based on actual cost and
              planned margin.
            </li>

            <li>Expenses: Actual cost to-date.</li>

            <li>Margin: Revenue less expenses to-date.</li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {engagementList.totalExpensesActual >
                engagementList.totalRevenue ? (
                  <>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Actual expenses exceed revenue for the selected
                      engagement(s). You may need to adjust the planned margin
                      on one or more resources.
                    </div>
                  </>
                ) : 2 *
                    (engagementList.totalRevenue -
                      engagementList.totalInvoiced) >
                  engagementList.totalRevenue ? (
                  <>
                    <Tag intent="danger">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The total WIP is significantly higher than revenue for the
                      selected engagement(s). Consider invoicing all or part of
                      the fee outstanding.
                    </div>
                  </>
                ) : (
                  <>
                    <Tag intent="success">On track</Tag>

                    <div style={{ marginTop: "10px" }}>No action needed.</div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Invoiced",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: engagementList.totalInvoiced,
        },
        {
          name: "Revenue",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: engagementList.totalRevenue,
        },
        {
          name: "Expenses",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: engagementList.totalExpensesActual,
        },
        {
          name: "Margin",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: engagementList.totalMarginActual,
        },
      ]}
      fill={fill}
      noData={
        !engagementList.totalExpensesActual &&
        !engagementList.totalInvoiced &&
        !engagementList.totalMarginActual &&
        !engagementList.totalRevenue
      }
      title="Actuals"
      units={activeOrganisation.currency}
    />
  );
}

export default EngagementActualsCard;
