// Constant imports
import {
  PROPOSAL_UPLOAD_LIST_REQUEST,
  PROPOSAL_UPLOAD_LIST_SUCCESS,
  PROPOSAL_UPLOAD_LIST_FAIL,
  PROPOSAL_UPLOAD_LIST_RESET,
  PROPOSAL_UPLOAD_CREATE_REQUEST,
  PROPOSAL_UPLOAD_CREATE_SUCCESS,
  PROPOSAL_UPLOAD_CREATE_FAIL,
  PROPOSAL_UPLOAD_CREATE_RESET,
  PROPOSAL_UPLOAD_DETAILS_REQUEST,
  PROPOSAL_UPLOAD_DETAILS_SUCCESS,
  PROPOSAL_UPLOAD_DETAILS_FAIL,
  PROPOSAL_UPLOAD_DETAILS_RESET,
  PROPOSAL_UPLOAD_UPDATE_REQUEST,
  PROPOSAL_UPLOAD_UPDATE_SUCCESS,
  PROPOSAL_UPLOAD_UPDATE_FAIL,
  PROPOSAL_UPLOAD_UPDATE_RESET,
  PROPOSAL_UPLOAD_DELETE_REQUEST,
  PROPOSAL_UPLOAD_DELETE_SUCCESS,
  PROPOSAL_UPLOAD_DELETE_FAIL,
  PROPOSAL_UPLOAD_DELETE_RESET,
} from "../constants/proposalUploadConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalUploadsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_UPLOAD_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalUploads(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_UPLOAD_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_uploads: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_UPLOAD_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalUploadsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_UPLOAD_LIST_RESET });
};

// Create
export const createProposalUploadAction =
  (proposal_upload) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_UPLOAD_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalUpload(
        token,
        proposal_upload
      );

      dispatch({
        type: PROPOSAL_UPLOAD_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_UPLOAD_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalUploadAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_UPLOAD_CREATE_RESET });
};

// List details
export const listProposalUploadDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_UPLOAD_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalUpload(id, token);

      dispatch({
        type: PROPOSAL_UPLOAD_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_UPLOAD_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalUploadDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_UPLOAD_DETAILS_RESET });
};

// Update
export const updateProposalUploadAction =
  (id, proposal_upload) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_UPLOAD_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalUpload(
        id,
        token,
        proposal_upload
      );

      dispatch({
        type: PROPOSAL_UPLOAD_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_UPLOAD_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalUploadAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_UPLOAD_UPDATE_RESET });
};

// Delete
export const deleteProposalUploadAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_UPLOAD_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalUpload(id, token);

      dispatch({
        type: PROPOSAL_UPLOAD_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_UPLOAD_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalUploadAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_UPLOAD_DELETE_RESET });
};
