export const TEXT_EXTRACT_DATA_REQUEST = "TEXT_EXTRACT_DATA_REQUEST";
export const TEXT_EXTRACT_DATA_SUCCESS = "TEXT_EXTRACT_DATA_SUCCESS";
export const TEXT_EXTRACT_DATA_FAIL = "TEXT_EXTRACT_DATA_FAIL";
export const TEXT_EXTRACT_DATA_RESET = "TEXT_EXTRACT_DATA_RESET";

export const TEXT_EXTRACT_DATA_FROM_FILE_REQUEST =
  "TEXT_EXTRACT_DATA_FROM_FILE_REQUEST";
export const TEXT_EXTRACT_DATA_FROM_FILE_SUCCESS =
  "TEXT_EXTRACT_DATA_FROM_FILE_SUCCESS";
export const TEXT_EXTRACT_DATA_FROM_FILE_FAIL =
  "TEXT_EXTRACT_DATA_FROM_FILE_FAIL";
export const TEXT_EXTRACT_DATA_FROM_FILE_RESET =
  "TEXT_EXTRACT_DATA_FROM_FILE_RESET";

export const TEXT_REWRITE_FIELD_REQUEST = "TEXT_REWRITE_FIELD_REQUEST";
export const TEXT_REWRITE_FIELD_SUCCESS = "TEXT_REWRITE_FIELD_SUCCESS";
export const TEXT_REWRITE_FIELD_FAIL = "TEXT_REWRITE_FIELD_FAIL";
export const TEXT_REWRITE_FIELD_RESET = "TEXT_REWRITE_FIELD_RESET";
