export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const BACKEND_WS_URL = process.env.REACT_APP_BACKEND_WS_URL;

export const ENABLE_SIGNUP = process.env.REACT_APP_ENABLE_SIGNUP;

export const NODE_SERVICES_URL = process.env.REACT_APP_NODE_SERVICES_URL;

export const PAGE_SIZE = 10; // Default number of objects per page

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export const VIDEOS_URL = process.env.REACT_APP_VIDEOS_URL;
