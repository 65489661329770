// Library imports
import { Button, Classes, Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import NumericValue from "../components/NumericValue";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionFieldLink from "../components/TabSectionFieldLink";

// Redux action imports
// Proposal
import { listProposalsAction } from "../actions/proposalActions";

// Main function
function ProposalInfo({
  additionalInfo,
  hideLink,
  proposalData,
  proposalId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Proposal
  const proposalList = useSelector((state) => state.proposalList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh proposal data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [proposal, setProposal] = useState({});

  useEffect(() => {
    if (proposalData) {
      setProposal(proposalData);

      setLoading(false);
    }
  }, [proposalData]);

  useEffect(() => {
    if (!proposalData && !proposalList.loading) {
      if (proposalList.success) {
        const thisProposal = proposalList.proposals.filter(
          (proposal) => proposal.id === proposalId
        );

        if (thisProposal.length > 0) {
          setProposal(thisProposal[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listProposalsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (proposalList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [proposalList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5 style={{ margin: "0 10px 0 0" }}>{proposal.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/proposals/${proposal.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Client</strong>
          </p>

          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginBottom: "10px",
            }}
          >
            <img
              alt="logo"
              src={
                proposal.client_logo
                  ? proposal.client_logo
                  : "/images/no_logo.png"
              }
              style={{
                background: "white",
                border: `1px solid ${
                  lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                }`,
                borderRadius: "10%",
                height: "40px",
                margin: "0 10px 0 0",
                objectFit: "contain",
                width: "40px",
              }}
            />

            <TabSectionFieldLink
              invertColor
              name={proposal.client_name}
              url={`/clients/${proposal.client}`}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Value</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NumericValue
              currency={activeOrganisation.currency}
              fillTag
              number={proposal.value}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Status</strong>
          </p>

          <p>
            {
              <Tag
                intent={
                  proposal.status === "Cancelled"
                    ? "warning"
                    : proposal.status === "Lost"
                    ? "danger"
                    : proposal.status === "Won"
                    ? "success"
                    : "primary"
                }
                large
              >
                {proposal.status}
              </Tag>
            }
          </p>

          {proposal.contact && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Contact</strong>
              </p>

              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <img
                  alt="profile"
                  src={
                    proposal.contact_profile_photo
                      ? proposal.contact_profile_photo
                      : "/images/no_photo.png"
                  }
                  style={{
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: "50%",
                    height: "40px",
                    margin: "0 10px 0 0",
                    objectFit: "contain",
                    width: "40px",
                  }}
                />

                <TabSectionFieldLink
                  invertColor
                  name={proposal.contact_name}
                  url={`/contacts/${proposal.contact}`}
                />
              </div>
            </>
          )}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Comments</strong>
          </p>

          <p>
            {proposal.notes ? (
              proposal.notes
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          {additionalInfo}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Manager</strong>
          </p>

          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginBottom: "10px",
            }}
          >
            <img
              alt="profile"
              src={
                proposal.manager_profile_photo
                  ? proposal.manager_profile_photo
                  : "/images/no_photo.png"
              }
              style={{
                background: "white",
                border: `1px solid ${
                  lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                }`,
                borderRadius: "50%",
                height: "40px",
                margin: "0 10px 0 0",
                objectFit: "contain",
                width: "40px",
              }}
            />

            <TabSectionEmailLink email={proposal.manager_name} invertColor />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProposalInfo;
