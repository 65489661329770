// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function ContactProposalTotalValueCard({
  fill,
  contactList,
  contactQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Proposal value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total value of proposals closed where the selected contact(s) are
            the sponsor. These are proposals that have been won, lost, or
            cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {Math.max(
                  contactList.totalValueOfProposalsCancelled,
                  contactList.totalValueOfProposalsLost,
                  contactList.totalValueOfProposalsWon
                ) === contactList.totalValueOfProposalsWon ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm tends to win most of the work where the selected
                      contact(s) are the sponsor. Continue to invest in these
                      relationships.
                    </div>
                  </div>
                ) : Math.max(
                    contactList.totalValueOfProposalsCancelled,
                    contactList.totalValueOfProposalsLost,
                    contactList.totalValueOfProposalsWon
                  ) === contactList.totalValueOfProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is investing resources into proposals that
                      ultimately do not materialize when the selected contact(s)
                      are the sponsor. Implementing a more rigorous
                      qualification process may prove beneficial in identifying
                      and pursuing only those opportunities were you have strong
                      client relationships.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is expending considerable resources on proposals
                      that are ultimately unsuccessful when the selected
                      contact(s) are the sponsor. Conducting a relationship
                      audit may reveal patterns that can be used to focus on
                      high-value contacts.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: contactList.totalValueOfProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: contactList.totalValueOfProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: contactList.totalValueOfProposalsCancelled,
        },
      ]}
      fill={fill}
      filter={contactQuery ? contactQuery.status : undefined}
      noData={
        !contactList.totalValueOfProposalsCancelled &&
        !contactList.totalValueOfProposalsLost &&
        !contactList.totalValueOfProposalsWon
      }
      title="Proposal value"
      units={activeOrganisation.currency}
    />
  );
}

export default ContactProposalTotalValueCard;
