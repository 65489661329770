// Library imports
import {
  Classes,
  Colors,
  Drawer,
  DrawerSize,
  H2,
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Text,
} from "@blueprintjs/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

// Component imports
import IconSquare from "../components/IconSquare";

// Redux action imports
// UI
import {
  switchUIModeAction,
  switchSidebarModeAction,
} from "../actions/UIActions";

// User
import { logoutAction } from "../actions/userActions";

// Main function
function Sidebar() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Sidebar configuration state variables
  const sidebarMode = useSelector((state) => state.sidebarMode);
  const { showSidebar } = sidebarMode;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const checkPermissions = useSelector((state) => state.checkPermissions);

  // Switch sidebar mode
  const switchSidebarMode = () => {
    dispatch(switchSidebarModeAction(!showSidebar));
  };

  // Switch UI mode
  const switchUIMode = () => {
    dispatch(switchUIModeAction(!lightMode));
  };

  // Logout
  const logout = () => {
    dispatch(logoutAction());
  };

  // JSX UI code
  return (
    <Drawer
      className={!lightMode ? Classes.DARK : undefined}
      isOpen={showSidebar}
      onClose={switchSidebarMode}
      position={Position.LEFT}
      size={DrawerSize.SMALL}
      title={
        <div
          style={{
            alignContent: "center",
            display: "flex",
          }}
        >
          <img
            alt="logo"
            src="/images/logo_modified.svg"
            style={{
              height: "40px",
              margin: "10px 10px 10px 0",
            }}
          />

          <Link to="/" style={{ textDecoration: "none" }}>
            <H2 style={{ margin: "10px 0 10px 0" }}>advaise.app</H2>
          </Link>
        </div>
      }
    >
      <div className={Classes.DRAWER_BODY}>
        <Menu>
          {lightMode ? (
            <MenuItem
              icon={
                <IconSquare
                  icon="moon"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.BLACK}
                />
              }
              onClick={switchUIMode}
              text="Dark Mode"
            />
          ) : (
            <MenuItem
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.WHITE}
                  icon="flash"
                />
              }
              onClick={switchUIMode}
              text="Light Mode"
            />
          )}

          <MenuDivider title="Strategy" />

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.strategy}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.TURQUOISE5}
                  icon="compass"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.TURQUOISE2}
                />
              }
              text="Brand"
            />
          )}

          <MenuItem
            disabled={!checkPermissions.permissions.strategy}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.TURQUOISE5}
                icon="heatmap"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.TURQUOISE2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/markets");
            }}
            text="Markets"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.strategy}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.TURQUOISE5}
                icon="heat-grid"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.TURQUOISE2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/services");
            }}
            text="Services"
          />

          <MenuDivider title="People" />

          <MenuItem
            disabled={!checkPermissions.permissions.people}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.INDIGO5}
                icon="hat"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.INDIGO2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/roles");
            }}
            text="Roles"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.people}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.INDIGO5}
                icon="shared-filter"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.INDIGO2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/openings");
            }}
            text="Openings"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.people}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.INDIGO5}
                icon="people"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.INDIGO2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/staff");
            }}
            text="Staff"
          />

          {false && <MenuDivider title="Partners" />}

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.partners}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.CERULEAN5}
                  icon="path"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.CERULEAN2}
                />
              }
              text="Channels"
            />
          )}

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.partners}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.CERULEAN5}
                  icon="new-link"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.CERULEAN2}
                />
              }
              text="Referrals"
            />
          )}

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.partners}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.CERULEAN5}
                  icon="data-lineage"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.CERULEAN2}
                />
              }
              text="Suppliers"
            />
          )}

          <MenuDivider title="Content" />

          <MenuItem
            disabled={!checkPermissions.permissions.marketing}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.VERMILION5}
                icon="book"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.VERMILION2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/caseStudies");
            }}
            text="Case Studies"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.marketing}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.VERMILION5}
                icon="lightbulb"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.VERMILION2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/insights");
            }}
            text="Insights"
          />

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.marketing}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.VERMILION5}
                  icon="send-to-graph"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.VERMILION2}
                />
              }
              text="Campaigns"
            />
          )}

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.marketing}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.VERMILION5}
                  icon="new-person"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.VERMILION2}
                />
              }
              text="Leads"
            />
          )}

          <MenuDivider title="Sales" />

          <MenuItem
            disabled={!checkPermissions.permissions.sales}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.FOREST5}
                icon="id-number"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.FOREST2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/contacts");
            }}
            text="Contacts"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.sales}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.FOREST5}
                icon="office"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.FOREST2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/clients");
            }}
            text="Clients"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.sales}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.FOREST5}
                icon="manual"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.FOREST2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/proposals");
            }}
            text="Proposals"
          />

          <MenuDivider title="Delivery" />

          {false && (
            <MenuItem
              disabled={!checkPermissions.permissions.delivery}
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.LIME5}
                  icon="manually-entered-data"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.LIME2}
                />
              }
              text="Contracts"
            />
          )}

          <MenuItem
            disabled={!checkPermissions.permissions.delivery}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.LIME5}
                icon="gantt-chart"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.LIME2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/engagements");
            }}
            text="Engagements"
          />

          <MenuItem
            disabled={!checkPermissions.permissions.delivery}
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.LIME5}
                icon="dollar"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.LIME2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/invoices");
            }}
            text="Invoices"
          />

          <MenuDivider title="Account Administration" />

          <MenuItem
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.BLUE5}
                icon="user"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.BLUE2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/userProfile");
            }}
            text="Profile"
          />

          <MenuItem
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.BLUE5}
                icon="briefcase"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.BLUE2}
              />
            }
            onClick={() => {
              dispatch(switchSidebarModeAction(false));

              navigate("/organisations");
            }}
            text="Organisations"
          />

          <MenuItem
            icon={
              <IconSquare
                darkBackground={Colors.DARK_GRAY4}
                darkColor={Colors.BLUE5}
                icon="cog"
                lightBackground={Colors.LIGHT_GRAY4}
                lightColor={Colors.BLUE2}
              />
            }
            text="Account"
          >
            <MenuItem
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.BLUE5}
                  icon="log-out"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.BLUE2}
                />
              }
              onClick={logout}
              text="Logout"
            />

            <MenuItem
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.BLUE5}
                  icon="key"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.BLUE2}
                />
              }
              onClick={() => {
                dispatch(switchSidebarModeAction(false));

                navigate("/changePassword");
              }}
              text="Change Password"
            />

            <MenuDivider />

            <MenuItem
              icon={
                <IconSquare
                  darkBackground={Colors.DARK_GRAY4}
                  darkColor={Colors.BLUE5}
                  icon="chat"
                  lightBackground={Colors.LIGHT_GRAY4}
                  lightColor={Colors.BLUE2}
                />
              }
              onClick={() => (window.location = "mailto:support@advaise.app")}
              text="Contact Support"
            />
          </MenuItem>
        </Menu>
      </div>

      <div className={Classes.DRAWER_FOOTER}>
        <Text className={Classes.TEXT_SMALL} style={{ textAlign: "center" }}>
          &copy; 2024 Advaise Pty Ltd
        </Text>
      </div>
    </Drawer>
  );
}

export default Sidebar;
