// Constant imports
import {
  KNOWLEDGE_BASE_DOCUMENT_LIST_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_LIST_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_LIST_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_LIST_RESET,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_RESET,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_RESET,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_RESET,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_RESET,
} from "../constants/knowledgeBaseDocumentConstants";

// List all
export const knowledgeBaseDocumentListReducer = (
  state = {
    loading: false,
    count: 0,
    knowledge_base_documents: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case KNOWLEDGE_BASE_DOCUMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case KNOWLEDGE_BASE_DOCUMENT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        knowledge_base_documents: action.payload.knowledge_base_documents,
        success: true,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        knowledge_base_documents: [],
        success: false,
        error: action.payload,
      };

    case KNOWLEDGE_BASE_DOCUMENT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        knowledge_base_documents: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const knowledgeBaseDocumentCreateReducer = (
  state = {
    loading: false,
    knowledge_base_document: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case KNOWLEDGE_BASE_DOCUMENT_CREATE_REQUEST:
      return {
        loading: true,
        knowledge_base_document: state.knowledge_base_document,
        success: false,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_CREATE_SUCCESS:
      return {
        loading: false,
        knowledge_base_document: action.payload,
        success: true,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_CREATE_FAIL:
      return {
        loading: false,
        knowledge_base_document: state.knowledge_base_document,
        success: false,
        error: action.payload,
      };

    case KNOWLEDGE_BASE_DOCUMENT_CREATE_RESET:
      return {
        loading: false,
        knowledge_base_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const knowledgeBaseDocumentDetailsReducer = (
  state = {
    loading: false,
    knowledge_base_document: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case KNOWLEDGE_BASE_DOCUMENT_DETAILS_REQUEST:
      return {
        loading: true,
        knowledge_base_document: state.knowledge_base_document,
        success: false,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        knowledge_base_document: action.payload,
        success: true,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_DETAILS_FAIL:
      return {
        loading: false,
        knowledge_base_document: state.knowledge_base_document,
        success: false,
        error: action.payload,
      };

    case KNOWLEDGE_BASE_DOCUMENT_DETAILS_RESET:
      return {
        loading: false,
        knowledge_base_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const knowledgeBaseDocumentUpdateReducer = (
  state = {
    loading: false,
    knowledge_base_document: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case KNOWLEDGE_BASE_DOCUMENT_UPDATE_REQUEST:
      return {
        loading: true,
        knowledge_base_document: state.knowledge_base_document,
        success: false,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        knowledge_base_document: action.payload,
        success: true,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_UPDATE_FAIL:
      return {
        loading: false,
        knowledge_base_document: state.knowledge_base_document,
        success: false,
        error: action.payload,
      };

    case KNOWLEDGE_BASE_DOCUMENT_UPDATE_RESET:
      return {
        loading: false,
        knowledge_base_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const knowledgeBaseDocumentDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case KNOWLEDGE_BASE_DOCUMENT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case KNOWLEDGE_BASE_DOCUMENT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case KNOWLEDGE_BASE_DOCUMENT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
