// Constant imports
import {
  HELP_CONTENT_REQUEST,
  HELP_CONTENT_SUCCESS,
  HELP_CONTENT_FAIL,
  HELP_CONTENT_RESET,
} from "../constants/helpContentConstants";

// List all
export const helpContentListReducer = (
  state = {
    loading: false,
    help_content: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case HELP_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HELP_CONTENT_SUCCESS:
      return {
        loading: false,
        help_content: action.payload,
        success: true,
        error: "",
      };

    case HELP_CONTENT_FAIL:
      return {
        loading: false,
        help_content: [],
        success: false,
        error: action.payload,
      };

    case HELP_CONTENT_RESET:
      return {
        loading: false,
        help_content: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
