// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardTwoBarChart from "./DataCardTwoBarChart";

// Main function
function EngagementBookingUtilisationCard({
  fill,
  engagementBookingList,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardTwoBarChart
      comments={
        <>
          <H5>Average utilisation</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Average utilisation by type of booking.</p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {(engagementBookingList.averageUtilisationBillableConfirmed >
                  0 &&
                  engagementBookingList.averageUtilisationBillableConfirmed <
                    25) ||
                (engagementBookingList.averageUtilisationBillableRealised > 0 &&
                  engagementBookingList.averageUtilisationBillableRealised <
                    25) ||
                (engagementBookingList.averageUtilisationBillableTentative >
                  0 &&
                  engagementBookingList.averageUtilisationBillableTentative <
                    25) ? (
                  <>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      This engagement has very low actual or planned
                      utilisation. Typically, this prevents team members from
                      being assigned to other, full-time, projects which may be
                      more profitable. In addition, staff that are thinly spread
                      may find it difficult to give clients the attention they
                      require.
                    </div>
                  </>
                ) : (engagementBookingList.averageUtilisationBillableConfirmed >
                    0 &&
                    engagementBookingList.averageUtilisationBillableConfirmed <
                      50) ||
                  (engagementBookingList.averageUtilisationBillableRealised >
                    0 &&
                    engagementBookingList.averageUtilisationBillableRealised <
                      50) ||
                  (engagementBookingList.averageUtilisationBillableTentative >
                    0 &&
                    engagementBookingList.averageUtilisationBillableTentative <
                      50) ? (
                  <>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      This engagement may benefit from increasing the
                      utilisation of team members. Staff who are fully dedicated
                      to a project tend to generate bigger fees and achieve
                      higher client satisfaction.
                    </div>
                  </>
                ) : (
                  <>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>No action required.</div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Realised",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: engagementBookingList.averageUtilisationBillableRealised,
          value2: engagementBookingList.averageUtilisationNotBillableRealised,
        },
        {
          name: "Confirmed",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: engagementBookingList.averageUtilisationBillableConfirmed,
          value2: engagementBookingList.averageUtilisationNotBillableConfirmed,
        },
        {
          name: "Tentative",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: engagementBookingList.averageUtilisationBillableTentative,
          value2: engagementBookingList.averageUtilisationNotBillableTentative,
        },
      ]}
      fill={fill}
      legendData={[
        {
          color: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          type: "square",
          value: "Billable",
        },
        {
          color: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          type: "square",
          value: "Not billable",
        },
      ]}
      noData={
        !engagementBookingList.averageUtilisationBillableConfirmed &&
        !engagementBookingList.averageUtilisationBillableRealised &&
        !engagementBookingList.averageUtilisationBillableTentative &&
        !engagementBookingList.averageUtilisationNotBillableConfirmed &&
        !engagementBookingList.averageUtilisationNotBillableRealised &&
        !engagementBookingList.averageUtilisationNotBillableTentative
      }
      title="Average utilisation"
      units="Percentage"
    />
  );
}

export default EngagementBookingUtilisationCard;
