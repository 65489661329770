// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Divider,
  H1,
  H3,
  H6,
} from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import LinkTag from "../components/LinkTag";

// Redux action imports
// User
import { loginAction } from "../actions/userActions";

// Main function
function LoginScreen2() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Form variables
  // Login credentials
  const [loginCredentials, setLoginCredentials] = useState({
    email: "",
    password: "",
  });

  // Login
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const onVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const login = () => {
    // Dispatch action
    dispatch(
      loginAction({
        email: loginCredentials.email,
        password: loginCredentials.password,
        recaptcha_token: reCaptchaToken,
      })
    );

    // Refresh reCAPTCHA
    setRefreshReCaptcha((r) => !r);
  };

  // If logged in navigate to homepage
  useEffect(() => {
    userLogin.token && navigate("/");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // JSX UI code
  return (
    <div
      className={Classes.DARK}
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(165, 217, 243, 0.8), rgba(165, 217, 243, 1))",
        display: "flex",
        justifyContent: "center",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      <Card
        elevation={4}
        style={{
          border: "1px solid #1891AC",
          borderRadius: "5px",
          margin: "20px 5px 20px 5px",
          maxWidth: "800px",
          width: "100%",
        }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              objectFit: "contain",
              width: "100px",
            }}
          />
        </div>

        <div className="row" style={{ textAlign: "center" }}>
          {/* Heading */}
          <H1>Log into advaise.app</H1>

          <H3 style={{ color: "#D2ECF9", margin: "0 0 20px 0" }}>
            Manage your professional services business in one easy system
          </H3>
        </div>

        <div className="row">
          {/* Image */}
          <div className="col-s-6 hide-xs">
            <img
              alt="screenshots"
              src="/images/login.png"
              style={{
                maxHeight: "300px",
                objectFit: "contain",
                width: "100%",
              }}
            />
          </div>

          {/* Form */}
          <div className="col-s-6">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Error message */}
              {userLogin.error && (
                <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                  {userLogin.error.response.data["non_field_errors"]
                    ? userLogin.error.response.data["non_field_errors"]
                    : "We couldn't log you in. Check your credentials and try again."}
                </Callout>
              )}

              {/* Email */}
              <FormDrawerTextField2
                error={userLogin.error}
                fieldName="email"
                label="Email"
                labelInfo="(required)"
                large
                placeholder="Enter your email address"
                setValueObject={setLoginCredentials}
                setValueVariable="email"
                value={loginCredentials.email}
              />

              {/* Password */}
              <FormDrawerTextField2
                error={userLogin.error}
                fieldName="password"
                label="Password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter your password"
                setValueObject={setLoginCredentials}
                setValueVariable="password"
                value={loginCredentials.password}
              />

              {/* reCAPTCHA */}
              <GoogleReCaptcha
                action="login"
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />

              {/* Login */}
              <Button
                disabled={!reCaptchaToken}
                fill
                icon="log-in"
                intent="primary"
                large
                loading={userLogin.loading}
                onClick={login}
                style={{ margin: "20px 0 0 0" }}
                text="Login"
                type="submit"
              />
            </form>

            <Divider style={{ margin: "15px 0 15px 0" }} />

            <p style={{ textAlign: "center" }}>Don't have an account?</p>

            {/* Signup */}
            <Button
              fill
              intent="success"
              large
              onClick={() => {
                navigate("/");
              }}
              style={{ margin: "10px 0 0 0" }}
              text="Try it for FREE"
            />

            <H6
              style={{
                color: "#D2ECF9",
                margin: "10px 0 0 0",
                textAlign: "center",
              }}
            >
              No credit card needed
            </H6>
          </div>
        </div>

        {/* Footer */}
        <div
          style={{
            margin: "20px 0 0 0",
          }}
        >
          <p>
            Forgotten your login?{" "}
            <LinkTag
              label="Reset your password"
              onClick={() => {
                navigate("/resetPassword");
              }}
            />
          </p>
        </div>
      </Card>
    </div>
  );
}

export default LoginScreen2;
