// Library imports
import { Card, Classes, Colors, H1, H3, H5, Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import TabSectionEmailLink from "../components/TabSectionEmailLink";

// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function DemoCoExpiredCTACard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // JSX UI code
  return (
    <div
      style={{
        margin: "20px auto 20px auto",
        maxWidth: "1600px",
        padding: "0 20px 0 20px",
      }}
    >
      <Card
        style={{
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <div style={{ marginRight: "10px", width: "75%", zIndex: 1 }}>
          <div style={{ margin: "0 auto 0 auto", width: "800px" }}>
            <H1
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
              }}
            >
              Create your organization
            </H1>

            <div
              className={Classes.BLOCKQUOTE}
              style={{ margin: "20px 0 10px 0" }}
            >
              <H5 style={{ color: lightMode ? Colors.RED3 : Colors.RED5 }}>
                Your trial of DemoCo has expired.
              </H5>

              <p>
                We hope you enjoyed exploring the system. Create your own
                organization to continue using advaise.app.
              </p>
            </div>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              How to set up your organization
            </H3>

            <div style={{ margin: "20px 0 10px 0" }}>
              Click <Icon disabled icon="add" style={{ margin: "0 0 0 5px" }} />{" "}
              <strong>Add</strong> on the navigation bar to create a new
              organization. Fill in the information required, taking care to
              select the correct base currency for your company. After creating
              your organization, you are ready to start setting up your business
              on advaise.app.
            </div>

            <H5 style={{ margin: "20px 0 0 0" }}>Let us know if we can help</H5>

            <div style={{ margin: "10px 0 20px 0" }}>
              If you are unable to move forward for any reason, we are happy to
              help. You can contact us at{" "}
              <TabSectionEmailLink email="support@advaise.app" />.
            </div>
          </div>
        </div>

        <div
          style={{
            alignItems: "end",
            display: "flex",
            justifyContent: "end",
            marginLeft: "10px",
            padding: "10px",
            width: "25%",
          }}
        >
          <Icon
            color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
            icon="office"
            size={300}
          />
        </div>
      </Card>
    </div>
  );
}

export default DemoCoExpiredCTACard;
