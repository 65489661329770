// Constant imports
import {
  INVOICE_OTHER_LIST_REQUEST,
  INVOICE_OTHER_LIST_SUCCESS,
  INVOICE_OTHER_LIST_FAIL,
  INVOICE_OTHER_LIST_RESET,
  INVOICE_OTHER_CREATE_REQUEST,
  INVOICE_OTHER_CREATE_SUCCESS,
  INVOICE_OTHER_CREATE_FAIL,
  INVOICE_OTHER_CREATE_RESET,
  INVOICE_OTHER_DETAILS_REQUEST,
  INVOICE_OTHER_DETAILS_SUCCESS,
  INVOICE_OTHER_DETAILS_FAIL,
  INVOICE_OTHER_DETAILS_RESET,
  INVOICE_OTHER_UPDATE_REQUEST,
  INVOICE_OTHER_UPDATE_SUCCESS,
  INVOICE_OTHER_UPDATE_FAIL,
  INVOICE_OTHER_UPDATE_RESET,
  INVOICE_OTHER_DELETE_REQUEST,
  INVOICE_OTHER_DELETE_SUCCESS,
  INVOICE_OTHER_DELETE_FAIL,
  INVOICE_OTHER_DELETE_RESET,
} from "../constants/invoiceOtherConstants";

// List all
export const invoiceOtherListReducer = (
  state = {
    loading: false,
    count: 0,
    invoice_others: [],
    totalNet: 0,
    totalNetQuote: 0,
    totalTax: 0,
    totalValue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_OTHER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_OTHER_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoice_others: action.payload.invoice_others,
        totalNet: action.payload.totalNet,
        totalNetQuote: action.payload.totalNetQuote,
        totalTax: action.payload.totalTax,
        totalValue: action.payload.totalValue,
        success: true,
        error: "",
      };

    case INVOICE_OTHER_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoice_others: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: action.payload,
      };

    case INVOICE_OTHER_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoice_others: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const invoiceOtherCreateReducer = (
  state = {
    loading: false,
    invoice_other: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_OTHER_CREATE_REQUEST:
      return {
        loading: true,
        invoice_other: state.invoice_other,
        success: false,
        error: "",
      };

    case INVOICE_OTHER_CREATE_SUCCESS:
      return {
        loading: false,
        invoice_other: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_OTHER_CREATE_FAIL:
      return {
        loading: false,
        invoice_other: state.invoice_other,
        success: false,
        error: action.payload,
      };

    case INVOICE_OTHER_CREATE_RESET:
      return {
        loading: false,
        invoice_other: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const invoiceOtherDetailsReducer = (
  state = { loading: false, invoice_other: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_OTHER_DETAILS_REQUEST:
      return {
        loading: true,
        invoice_other: state.invoice_other,
        success: false,
        error: "",
      };

    case INVOICE_OTHER_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice_other: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_OTHER_DETAILS_FAIL:
      return {
        loading: false,
        invoice_other: state.invoice_other,
        success: false,
        error: action.payload,
      };

    case INVOICE_OTHER_DETAILS_RESET:
      return {
        loading: false,
        invoice_other: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const invoiceOtherUpdateReducer = (
  state = { loading: false, invoice_other: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_OTHER_UPDATE_REQUEST:
      return {
        loading: true,
        invoice_other: state.invoice_other,
        success: false,
        error: "",
      };

    case INVOICE_OTHER_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice_other: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_OTHER_UPDATE_FAIL:
      return {
        loading: false,
        invoice_other: state.invoice_other,
        success: false,
        error: action.payload,
      };

    case INVOICE_OTHER_UPDATE_RESET:
      return {
        loading: false,
        invoice_other: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const invoiceOtherDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_OTHER_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INVOICE_OTHER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INVOICE_OTHER_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INVOICE_OTHER_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
