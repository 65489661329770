// Constant imports
import {
  OPENING_APPLICATION_CONFIGURATION_DETAILS_REQUEST,
  OPENING_APPLICATION_CONFIGURATION_DETAILS_SUCCESS,
  OPENING_APPLICATION_CONFIGURATION_DETAILS_FAIL,
  OPENING_APPLICATION_CONFIGURATION_DETAILS_RESET,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_REQUEST,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_SUCCESS,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_FAIL,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_RESET,
  OPENING_APPLICATION_CONFIGURATION_APPLY_REQUEST,
  OPENING_APPLICATION_CONFIGURATION_APPLY_SUCCESS,
  OPENING_APPLICATION_CONFIGURATION_APPLY_FAIL,
  OPENING_APPLICATION_CONFIGURATION_APPLY_RESET,
} from "../constants/openingApplicationConfigurationConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List details
export const listOpeningApplicationConfigurationDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_APPLICATION_CONFIGURATION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } =
        await cortixDataService.getOpeningApplicationConfiguration(id, token);

      dispatch({
        type: OPENING_APPLICATION_CONFIGURATION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_APPLICATION_CONFIGURATION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListOpeningApplicationConfigurationDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_APPLICATION_CONFIGURATION_DETAILS_RESET });
  };

// Update
export const updateOpeningApplicationConfigurationAction =
  (id, opening_application_configuration) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_APPLICATION_CONFIGURATION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } =
        await cortixDataService.updateOpeningApplicationConfiguration(
          id,
          token,
          opening_application_configuration
        );

      dispatch({
        type: OPENING_APPLICATION_CONFIGURATION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_APPLICATION_CONFIGURATION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningApplicationConfigurationAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_APPLICATION_CONFIGURATION_UPDATE_RESET });
  };

// Apply
export const applyOpeningApplicationConfigurationAction =
  (id) => async (dispatch) => {
    try {
      dispatch({ type: OPENING_APPLICATION_CONFIGURATION_APPLY_REQUEST });

      const { data } =
        await cortixDataService.applyOpeningApplicationConfiguration(id);

      dispatch({
        type: OPENING_APPLICATION_CONFIGURATION_APPLY_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_APPLICATION_CONFIGURATION_APPLY_FAIL,

        payload: error,
      });
    }
  };

// Reset apply
export const resetApplyOpeningApplicationConfigurationAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_APPLICATION_CONFIGURATION_APPLY_RESET });
  };
