export const ROLE_COMPETENCY_LIST_REQUEST = "ROLE_COMPETENCY_LIST_REQUEST";
export const ROLE_COMPETENCY_LIST_SUCCESS = "ROLE_COMPETENCY_LIST_SUCCESS";
export const ROLE_COMPETENCY_LIST_FAIL = "ROLE_COMPETENCY_LIST_FAIL";
export const ROLE_COMPETENCY_LIST_RESET = "ROLE_COMPETENCY_LIST_RESET";

export const ROLE_COMPETENCY_CREATE_REQUEST = "ROLE_COMPETENCY_CREATE_REQUEST";
export const ROLE_COMPETENCY_CREATE_SUCCESS = "ROLE_COMPETENCY_CREATE_SUCCESS";
export const ROLE_COMPETENCY_CREATE_FAIL = "ROLE_COMPETENCY_CREATE_FAIL";
export const ROLE_COMPETENCY_CREATE_RESET = "ROLE_COMPETENCY_CREATE_RESET";

export const ROLE_COMPETENCY_DETAILS_REQUEST =
  "ROLE_COMPETENCY_DETAILS_REQUEST";
export const ROLE_COMPETENCY_DETAILS_SUCCESS =
  "ROLE_COMPETENCY_DETAILS_SUCCESS";
export const ROLE_COMPETENCY_DETAILS_FAIL = "ROLE_COMPETENCY_DETAILS_FAIL";
export const ROLE_COMPETENCY_DETAILS_RESET = "ROLE_COMPETENCY_DETAILS_RESET";

export const ROLE_COMPETENCY_UPDATE_REQUEST = "ROLE_COMPETENCY_UPDATE_REQUEST";
export const ROLE_COMPETENCY_UPDATE_SUCCESS = "ROLE_COMPETENCY_UPDATE_SUCCESS";
export const ROLE_COMPETENCY_UPDATE_FAIL = "ROLE_COMPETENCY_UPDATE_FAIL";
export const ROLE_COMPETENCY_UPDATE_RESET = "ROLE_COMPETENCY_UPDATE_RESET";

export const ROLE_COMPETENCY_DELETE_REQUEST = "ROLE_COMPETENCY_DELETE_REQUEST";
export const ROLE_COMPETENCY_DELETE_SUCCESS = "ROLE_COMPETENCY_DELETE_SUCCESS";
export const ROLE_COMPETENCY_DELETE_FAIL = "ROLE_COMPETENCY_DELETE_FAIL";
export const ROLE_COMPETENCY_DELETE_RESET = "ROLE_COMPETENCY_DELETE_RESET";
