export const INTERVIEW_LIST_REQUEST = "INTERVIEW_LIST_REQUEST";
export const INTERVIEW_LIST_SUCCESS = "INTERVIEW_LIST_SUCCESS";
export const INTERVIEW_LIST_FAIL = "INTERVIEW_LIST_FAIL";
export const INTERVIEW_LIST_RESET = "INTERVIEW_LIST_RESET";

export const INTERVIEW_CREATE_REQUEST = "INTERVIEW_CREATE_REQUEST";
export const INTERVIEW_CREATE_SUCCESS = "INTERVIEW_CREATE_SUCCESS";
export const INTERVIEW_CREATE_FAIL = "INTERVIEW_CREATE_FAIL";
export const INTERVIEW_CREATE_RESET = "INTERVIEW_CREATE_RESET";

export const INTERVIEW_DETAILS_REQUEST = "INTERVIEW_DETAILS_REQUEST";
export const INTERVIEW_DETAILS_SUCCESS = "INTERVIEW_DETAILS_SUCCESS";
export const INTERVIEW_DETAILS_FAIL = "INTERVIEW_DETAILS_FAIL";
export const INTERVIEW_DETAILS_RESET = "INTERVIEW_DETAILS_RESET";

export const INTERVIEW_UPDATE_REQUEST = "INTERVIEW_UPDATE_REQUEST";
export const INTERVIEW_UPDATE_SUCCESS = "INTERVIEW_UPDATE_SUCCESS";
export const INTERVIEW_UPDATE_FAIL = "INTERVIEW_UPDATE_FAIL";
export const INTERVIEW_UPDATE_RESET = "INTERVIEW_UPDATE_RESET";

export const INTERVIEW_DELETE_REQUEST = "INTERVIEW_DELETE_REQUEST";
export const INTERVIEW_DELETE_SUCCESS = "INTERVIEW_DELETE_SUCCESS";
export const INTERVIEW_DELETE_FAIL = "INTERVIEW_DELETE_FAIL";
export const INTERVIEW_DELETE_RESET = "INTERVIEW_DELETE_RESET";
