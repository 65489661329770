// Library imports
import { Button, Classes, Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";
import NumericValue from "../components/NumericValue";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";

// Utility imports
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// Contact
import { listContactsAction } from "../actions/contactActions";

// Main function
function ContactInfo({ additionalInfo, contactData, contactId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh contact data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [contact, setContact] = useState({});

  useEffect(() => {
    if (contactData) {
      setContact(contactData);

      setLoading(false);
    }
  }, [contactData]);

  useEffect(() => {
    if (!contactData && !contactList.loading) {
      if (contactList.success) {
        const thisContact = contactList.contacts.filter(
          (contact) => contact.id === contactId
        );

        if (thisContact.length > 0) {
          setContact(thisContact[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listContactsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (contactList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [contactList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                alt="profile"
                src={
                  contact.profile_photo
                    ? contact.profile_photo
                    : "/images/no_photo.png"
                }
                style={{
                  background: "white",
                  border: `1px solid ${
                    lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                  }`,
                  borderRadius: "50%",
                  height: "60px",
                  margin: "0 10px 0 0",
                  objectFit: "contain",
                  width: "60px",
                }}
              />

              <H5 style={{ margin: "0 10px 0 0" }}>{contact.name}</H5>
            </div>

            <Button
              icon="open-application"
              intent="primary"
              minimal
              onClick={() =>
                window.open(
                  `/contacts/${contact.id}`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              style={{
                color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
              }}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Phone</strong>
          </p>

          <p>{contact.display_phone ? contact.display_phone : "-"}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Email</strong>
          </p>

          <div style={{ marginBottom: "10px" }}>
            <TabSectionEmailLink email={contact.email} invertColor />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>LinkedIn page</strong>
          </p>

          <div style={{ marginBottom: "10px" }}>
            <TabSectionFieldLink invertColor url={contact.linkedin_page} />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Seniority</strong>
          </p>

          <p>{contact.seniority}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Function</strong>
          </p>

          <p>{contact.function}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Contact</strong>
          </p>

          <p>{contact.role}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Budget</strong>
          </p>

          {contact.budget ? (
            <div
              style={{
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NumericValue
                  currency={activeOrganisation.currency}
                  fillTag
                  number={contact.budget}
                />
              </div>

              <div
                className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}
              >
                Estimated annual budget available to spend in our category
              </div>
            </div>
          ) : (
            <p>-</p>
          )}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Primary driver</strong>
          </p>

          <p>
            <Tag
              icon={
                contact.primary_driver === "Commercials"
                  ? "manually-entered-data"
                  : contact.primary_driver === "Partnerships"
                  ? "new-link"
                  : contact.primary_driver === "References"
                  ? "chat"
                  : contact.primary_driver === "Relationships"
                  ? "new-person"
                  : contact.primary_driver === "Solution"
                  ? "box"
                  : contact.primary_driver === "Team"
                  ? "people"
                  : "more"
              }
              large
              round
              style={{
                background:
                  contact.primary_driver === "Commercials"
                    ? Colors.GOLD3
                    : contact.primary_driver === "Partnerships"
                    ? Colors.INDIGO3
                    : contact.primary_driver === "References"
                    ? Colors.TURQUOISE3
                    : contact.primary_driver === "Relationships"
                    ? Colors.ROSE3
                    : contact.primary_driver === "Solution"
                    ? Colors.LIME3
                    : contact.primary_driver === "Team"
                    ? Colors.CERULEAN3
                    : Colors.BLUE3,
                color: setTextColour(
                  contact.primary_driver === "Commercials"
                    ? Colors.GOLD3
                    : contact.primary_driver === "Partnerships"
                    ? Colors.INDIGO3
                    : contact.primary_driver === "References"
                    ? Colors.TURQUOISE3
                    : contact.primary_driver === "Relationships"
                    ? Colors.ROSE3
                    : contact.primary_driver === "Solution"
                    ? Colors.LIME3
                    : contact.primary_driver === "Team"
                    ? Colors.CERULEAN3
                    : Colors.BLUE3
                ),
                textAlign: "center",
                minWidth: "100px",
              }}
            >
              {contact.primary_driver}
            </Tag>
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Secondary driver</strong>
          </p>

          <p>
            <Tag
              icon={
                contact.secondary_driver === "Commercials"
                  ? "manually-entered-data"
                  : contact.secondary_driver === "Partnerships"
                  ? "new-link"
                  : contact.secondary_driver === "References"
                  ? "chat"
                  : contact.secondary_driver === "Relationships"
                  ? "new-person"
                  : contact.secondary_driver === "Solution"
                  ? "box"
                  : contact.secondary_driver === "Team"
                  ? "people"
                  : "more"
              }
              large
              round
              style={{
                background:
                  contact.secondary_driver === "Commercials"
                    ? Colors.GOLD3
                    : contact.secondary_driver === "Partnerships"
                    ? Colors.INDIGO3
                    : contact.secondary_driver === "References"
                    ? Colors.TURQUOISE3
                    : contact.secondary_driver === "Relationships"
                    ? Colors.ROSE3
                    : contact.secondary_driver === "Solution"
                    ? Colors.LIME3
                    : contact.secondary_driver === "Team"
                    ? Colors.CERULEAN3
                    : Colors.BLUE3,
                color: setTextColour(
                  contact.secondary_driver === "Commercials"
                    ? Colors.GOLD3
                    : contact.secondary_driver === "Partnerships"
                    ? Colors.INDIGO3
                    : contact.secondary_driver === "References"
                    ? Colors.TURQUOISE3
                    : contact.secondary_driver === "Relationships"
                    ? Colors.ROSE3
                    : contact.secondary_driver === "Solution"
                    ? Colors.LIME3
                    : contact.secondary_driver === "Team"
                    ? Colors.CERULEAN3
                    : Colors.BLUE3
                ),
                textAlign: "center",
                minWidth: "100px",
              }}
            >
              {contact.secondary_driver}
            </Tag>
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Attitude toward us</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
                marginBottom: "5px",
              }}
            >
              <TabSectionFieldProgressBar
                rating={contact.attitude_toward_us / 5}
              />
            </div>

            <div className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}>
              {contact.attitude_toward_us === 5
                ? "Very positive"
                : contact.attitude_toward_us === 4
                ? "Positive"
                : contact.attitude_toward_us === 3
                ? "Neutral"
                : contact.attitude_toward_us === 2
                ? "Negative"
                : "Very negative"}
            </div>
          </div>

          {additionalInfo}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Manager</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NameValue
              email={contact.manager_name}
              image={contact.manager_profile_photo}
              invertColor
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactInfo;
