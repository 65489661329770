// Constant imports
import {
  PROPOSAL_FEE_LIST_REQUEST,
  PROPOSAL_FEE_LIST_SUCCESS,
  PROPOSAL_FEE_LIST_FAIL,
  PROPOSAL_FEE_LIST_RESET,
  PROPOSAL_FEE_DETAILS_REQUEST,
  PROPOSAL_FEE_DETAILS_SUCCESS,
  PROPOSAL_FEE_DETAILS_FAIL,
  PROPOSAL_FEE_DETAILS_RESET,
  PROPOSAL_FEE_UPDATE_REQUEST,
  PROPOSAL_FEE_UPDATE_SUCCESS,
  PROPOSAL_FEE_UPDATE_FAIL,
  PROPOSAL_FEE_UPDATE_RESET,
} from "../constants/proposalFeeConstants";

// List all
export const proposalFeeListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_fees: [],
    average_margin_percentage: 0,
    total_adjustments: 0,
    total_expenses: 0,
    total_fees: 0,
    total_fees_quote: 0,
    total_margin: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_FEE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_FEE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_fees: action.payload.proposal_fees,
        average_margin_percentage: action.payload.averageMarginPercentage,
        total_adjustments: action.payload.totalAdjustments,
        total_expenses: action.payload.totalExpenses,
        total_fees: action.payload.totalFees,
        total_fees_quote: action.payload.totalFeesQuote,
        total_margin: action.payload.totalMargin,
        success: true,
        error: "",
      };

    case PROPOSAL_FEE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_fees: [],
        average_margin_percentage: 0,
        total_adjustments: 0,
        total_expenses: 0,
        total_fees: 0,
        total_fees_quote: 0,
        total_margin: 0,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_FEE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_fees: [],
        average_margin_percentage: 0,
        total_adjustments: 0,
        total_expenses: 0,
        total_fees: 0,
        total_fees_quote: 0,
        total_margin: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalFeeDetailsReducer = (
  state = {
    loading: false,
    proposal_fee: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_FEE_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_fee: state.proposal_fee,
        success: false,
        error: "",
      };

    case PROPOSAL_FEE_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_fee: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_FEE_DETAILS_FAIL:
      return {
        loading: false,
        proposal_fee: state.proposal_fee,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_FEE_DETAILS_RESET:
      return {
        loading: false,
        proposal_fee: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalFeeUpdateReducer = (
  state = {
    loading: false,
    proposal_fee: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_FEE_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_fee: state.proposal_fee,
        success: false,
        error: "",
      };

    case PROPOSAL_FEE_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_fee: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_FEE_UPDATE_FAIL:
      return {
        loading: false,
        proposal_fee: state.proposal_fee,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_FEE_UPDATE_RESET:
      return {
        loading: false,
        proposal_fee: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
