// Library imports
import {
  Classes,
  Colors,
  Divider,
  H5,
  HTMLTable,
  Icon,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";
import NumericValue from "../components/NumericValue";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TabSectionFieldLink from "../components/TabSectionFieldLink";

// Redux action imports
// Booking code
import { listBookingCodesAction } from "../actions/bookingCodeActions";

// Main function
function BookingCodeInfo({
  additionalInfo,
  bookingCodeData,
  bookingCodeId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Booking code
  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh booking code data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [bookingCode, setBookingCode] = useState({});

  useEffect(() => {
    if (bookingCodeData) {
      setBookingCode(bookingCodeData);

      setLoading(false);
    }
  }, [bookingCodeData]);

  useEffect(() => {
    if (!bookingCodeData && !bookingCodeList.loading) {
      if (bookingCodeList.success) {
        const thisBookingCode = bookingCodeList.booking_codes.filter(
          (booking_code) => booking_code.id === bookingCodeId
        );

        if (thisBookingCode.length > 0) {
          setBookingCode(thisBookingCode[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listBookingCodesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (bookingCodeList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [bookingCodeList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{bookingCode.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Code</strong>
          </p>

          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{bookingCode.code}</div>

            {bookingCode.fixed_price && <Tag>Fixed-price</Tag>}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Year</strong>
          </p>

          <p>{bookingCode.year}</p>

          {bookingCode.engagement_data && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Engagement</strong>
              </p>

              <p>
                <TabSectionFieldLink
                  invertColor
                  name={bookingCode.engagement_data.name}
                  url={`/engagements/${bookingCode.engagement_data.id}`}
                />
              </p>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Client</strong>
              </p>

              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NameValue
                  image={bookingCode.engagement_data.client_logo}
                  invertColor
                  logo
                  text={bookingCode.engagement_data.client_name}
                  url={`/clients/${bookingCode.engagement_data.client}`}
                />
              </div>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Status</strong>
              </p>

              <p>
                {
                  <Tag
                    intent={
                      bookingCode.engagement_data.status === "Amber"
                        ? "warning"
                        : bookingCode.engagement_data.status === "Green"
                        ? "success"
                        : bookingCode.engagement_data.status === "Red"
                        ? "danger"
                        : "primary"
                    }
                    large
                  >
                    {bookingCode.engagement_data.status}
                  </Tag>
                }
              </p>
            </>
          )}

          {bookingCode.proposal_data && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Proposal</strong>
              </p>

              <p>
                <TabSectionFieldLink
                  invertColor
                  name={bookingCode.proposal_data.name}
                  url={`/proposals/${bookingCode.proposal_data.id}`}
                />
              </p>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Client</strong>
              </p>

              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NameValue
                  image={bookingCode.proposal_data.client_logo}
                  invertColor
                  logo
                  text={bookingCode.proposal_data.client_name}
                  url={`/clients/${bookingCode.proposal_data.client}`}
                />
              </div>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Value</strong>
              </p>

              <div>
                <NumericValue
                  currency={activeOrganisation.currency}
                  fillTag
                  number={bookingCode.proposal_data.value}
                />
              </div>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Status</strong>
              </p>

              <p>
                {
                  <Tag
                    intent={
                      bookingCode.proposal_data.status === "Cancelled"
                        ? "warning"
                        : bookingCode.proposal_data.status === "Lost"
                        ? "danger"
                        : bookingCode.proposal_data.status === "Won"
                        ? "success"
                        : "primary"
                    }
                    large
                  >
                    {bookingCode.proposal_data.status}
                  </Tag>
                }
              </p>
            </>
          )}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Comments</strong>
          </p>

          <p>
            {bookingCode.comments ? (
              bookingCode.comments
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Budget adherence</strong>
          </p>

          <HTMLTable bordered striped style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={bookingCode.budget} />
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={bookingCode.total_expense} />
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={bookingCode.remaining} />
                </td>

                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <TableTag
                    fillTag
                    intent={
                      bookingCode.budget - bookingCode.total_expense < 0
                        ? "danger"
                        : "success"
                    }
                    small
                    value={
                      bookingCode.budget - bookingCode.total_expense < 0
                        ? "Exceeded"
                        : "On track"
                    }
                  />
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Budget
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Spent
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Remaining
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Status
                </th>
              </tr>
            </thead>
          </HTMLTable>

          <p
            className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
            style={{ marginTop: "5px" }}
          >
            Figures in {activeOrganisation.currency}
          </p>

          {bookingCode.billable && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Profit & loss</strong>
              </p>

              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody className={Classes.TEXT_SMALL}>
                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                        width: "250px",
                      }}
                    >
                      <strong>A. Revenue</strong>
                    </td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.total_revenue}
                      />
                    </td>

                    <td></td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                        width: "200px",
                      }}
                    >
                      <strong>B. Labour expenses</strong>
                    </td>

                    <td></td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.labour_expense}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                        width: "200px",
                      }}
                    >
                      <strong>C. Non-labour expenses</strong>
                    </td>

                    <td></td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.non_labour_expense}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                        width: "200px",
                      }}
                    >
                      <strong>D. Total expenses [B + C]</strong>
                    </td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.total_expense}
                      />
                    </td>

                    <td></td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                        width: "200px",
                      }}
                    >
                      <strong>E. Gross margin [A - D]</strong>
                    </td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber invertColor number={bookingCode.margin} />
                    </td>

                    <td></td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                        width: "200px",
                      }}
                    >
                      <strong>F. Gross margin percentage [E / A]</strong>
                    </td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.margin_percentage}
                        percentage
                      />
                    </td>

                    <td></td>
                  </tr>
                </tbody>
              </HTMLTable>

              <p
                className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
                style={{ marginTop: "5px" }}
              >
                Figures in {activeOrganisation.currency}
              </p>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>WIP</strong>
              </p>

              {bookingCode.total_invoiced > bookingCode.total_revenue && (
                <div
                  style={{
                    background: `${Colors.ORANGE3}33`,
                    color: lightMode ? Colors.ORANGE5 : Colors.ORANGE2,
                    display: "flex",
                    margin: "20px 0 10px 0",
                    padding: "15px",
                  }}
                >
                  <Icon icon="warning-sign" />

                  <div style={{ marginLeft: "5px" }}>
                    The amount invoiced exceeds the revenue recognised on this
                    code. This may be expected if the margin is better than
                    planned , or if the client was billed in advance.
                  </div>
                </div>
              )}

              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody className={Classes.TEXT_SMALL}>
                  <tr>
                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.total_revenue}
                      />
                    </td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber
                        invertColor
                        number={bookingCode.total_invoiced}
                      />
                    </td>

                    <td
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        verticalAlign: "middle",
                      }}
                    >
                      <TableNumber invertColor number={bookingCode.wip} />
                    </td>

                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <TableTag
                        fillTag
                        intent={
                          bookingCode.wip / bookingCode.total_revenue > 0.5
                            ? "warning"
                            : "success"
                        }
                        small
                        value={
                          bookingCode.wip / bookingCode.total_revenue > 0.5
                            ? "Review"
                            : "On track"
                        }
                      />
                    </td>
                  </tr>
                </tbody>

                <thead className={Classes.TEXT_SMALL}>
                  <tr>
                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                      }}
                    >
                      Revenue
                    </th>

                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                      }}
                    >
                      Invoiced
                    </th>

                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                      }}
                    >
                      Not invoiced
                    </th>

                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
              </HTMLTable>

              <p
                className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
                style={{ marginTop: "5px" }}
              >
                Figures in {activeOrganisation.currency}
              </p>
            </>
          )}

          {additionalInfo}

          {bookingCode.engagement_data && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Manager</strong>
              </p>

              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NameValue
                  email={bookingCode.engagement_data.manager_name}
                  image={bookingCode.engagement_data.manager_profile_photo}
                  invertColor
                />
              </div>
            </>
          )}

          {bookingCode.proposal_data && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Manager</strong>
              </p>

              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NameValue
                  email={bookingCode.proposal_data.manager_name}
                  image={bookingCode.proposal_data.manager_profile_photo}
                  invertColor
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default BookingCodeInfo;
