// Library imports
import {
  Card,
  Classes,
  Colors,
  H6,
  HTMLTable,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Component imports
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";

// Main function
function DataCardCompetenciesChart({
  comments,
  darkColor,
  data,
  fill,
  interactive,
  lightColor,
  noData,
  onClick,
  title,
  units,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Custom tooltip
  const customToolTip = ({ payload, label, active }) => {
    if (active) {
      return (
        <Card
          style={{
            width: "300px",
          }}
        >
          <H6>{payload[0].payload.competency}</H6>

          <Tag
            intent={
              payload[0].payload.staff_level > payload[0].payload.role_level
                ? "success"
                : payload[0].payload.staff_level ===
                  payload[0].payload.role_level
                ? "primary"
                : "danger"
            }
            style={{
              textAlign: "center",
              width: "fit-content",
            }}
          >
            {payload[0].payload.staff_level > payload[0].payload.role_level
              ? "Above expected"
              : payload[0].payload.staff_level === payload[0].payload.role_level
              ? "As expected"
              : "Below expected"}
          </Tag>

          <HTMLTable style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td>
                  {payload[0].payload.staff_level === 5 ? (
                    <Tag intent="primary" minimal>
                      Expert
                    </Tag>
                  ) : payload[0].payload.staff_level >= 3 ? (
                    <Tag intent="primary" minimal>
                      Intermediate
                    </Tag>
                  ) : (
                    <Tag intent="primary" minimal>
                      Beginner
                    </Tag>
                  )}
                </td>

                <td>
                  {payload[0].payload.role_level >= 4 ? (
                    <Tag intent="primary" minimal>
                      Expert
                    </Tag>
                  ) : payload[0].payload.role_level >= 2 ? (
                    <Tag intent="primary" minimal>
                      Intermediate
                    </Tag>
                  ) : (
                    <Tag intent="primary" minimal>
                      Beginner
                    </Tag>
                  )}
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th>Current</th>

                <th>Required</th>
              </tr>
            </thead>
          </HTMLTable>
        </Card>
      );
    }

    return null;
  };

  // Legend formatter
  const legendFormatter = (value, entry, index) => {
    return (
      <span style={{ fontSize: 12 }}>
        {value === "role_level" ? "Required" : "Current"}
      </span>
    );
  };

  // Tick formatter
  const tickFormatter = (value, index) => {
    const char_limit = 20;

    if (value.length < char_limit) return value;

    return `${value.substring(0, char_limit)}...`;
  };

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div style={{ overflowY: "hidden" }}>
              <ResponsiveContainer height={215} width="100%">
                <ComposedChart
                  data={data}
                  margin={{
                    bottom: 15,
                    top: 5,
                  }}
                >
                  <defs>
                    <linearGradient id="colorRole" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="95%"
                        stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                        stopOpacity={0.7}
                      />
                    </linearGradient>

                    <linearGradient id="colorStaff" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="95%"
                        stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                        stopOpacity={0.7}
                      />
                    </linearGradient>
                  </defs>

                  <Area
                    dataKey="staff_level"
                    dot
                    fill="url(#colorStaff)"
                    stroke={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                  />

                  <Bar
                    barSize={65}
                    dataKey="role_level"
                    fill="url(#colorRole)"
                    radius={[5, 5, 0, 0]}
                  />

                  <Legend
                    formatter={legendFormatter}
                    wrapperStyle={{
                      backgroundColor: lightMode
                        ? Colors.WHITE
                        : Colors.DARK_GRAY3,
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY1 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: 3,
                      lineHeight: "30px",
                      position: "sticky",
                      width: "175px",
                    }}
                  />

                  <Tooltip content={customToolTip} />

                  <XAxis
                    dataKey="competency"
                    stroke={lightMode ? Colors.BLACK : Colors.WHITE}
                    style={{ fontSize: 12 }}
                    tickFormatter={tickFormatter}
                    type="category"
                  />

                  <YAxis
                    domain={[0, 5]}
                    hide
                    stroke={lightMode ? Colors.BLACK : Colors.WHITE}
                    type="number"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState icon={"search"} title={"No data"} />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={title}
      width={width}
    />
  );
}

export default DataCardCompetenciesChart;
