// Constant imports
import {
  RANK_LIST_REQUEST,
  RANK_LIST_SUCCESS,
  RANK_LIST_FAIL,
  RANK_LIST_RESET,
  RANK_CREATE_REQUEST,
  RANK_CREATE_SUCCESS,
  RANK_CREATE_FAIL,
  RANK_CREATE_RESET,
  RANK_DETAILS_REQUEST,
  RANK_DETAILS_SUCCESS,
  RANK_DETAILS_FAIL,
  RANK_DETAILS_RESET,
  RANK_UPDATE_REQUEST,
  RANK_UPDATE_SUCCESS,
  RANK_UPDATE_FAIL,
  RANK_UPDATE_RESET,
  RANK_DELETE_REQUEST,
  RANK_DELETE_SUCCESS,
  RANK_DELETE_FAIL,
  RANK_DELETE_RESET,
} from "../constants/rankConstants";

// List all
export const rankListReducer = (
  state = {
    loading: false,
    count: 0,
    ranks: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case RANK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RANK_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        ranks: action.payload.ranks,
        success: true,
        error: "",
      };

    case RANK_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        ranks: [],
        success: false,
        error: action.payload,
      };

    case RANK_LIST_RESET:
      return {
        loading: false,
        count: 0,
        ranks: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const rankCreateReducer = (
  state = { loading: false, rank: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case RANK_CREATE_REQUEST:
      return {
        loading: true,
        rank: state.rank,
        success: false,
        error: "",
      };

    case RANK_CREATE_SUCCESS:
      return {
        loading: false,
        rank: action.payload,
        success: true,
        error: "",
      };

    case RANK_CREATE_FAIL:
      return {
        loading: false,
        rank: state.rank,
        success: false,
        error: action.payload,
      };

    case RANK_CREATE_RESET:
      return { loading: false, rank: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const rankDetailsReducer = (
  state = { loading: false, rank: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case RANK_DETAILS_REQUEST:
      return {
        loading: true,
        rank: state.rank,
        success: false,
        error: "",
      };

    case RANK_DETAILS_SUCCESS:
      return {
        loading: false,
        rank: action.payload,
        success: true,
        error: "",
      };

    case RANK_DETAILS_FAIL:
      return {
        loading: false,
        rank: state.rank,
        success: false,
        error: action.payload,
      };

    case RANK_DETAILS_RESET:
      return { loading: false, rank: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const rankUpdateReducer = (
  state = { loading: false, rank: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case RANK_UPDATE_REQUEST:
      return {
        loading: true,
        rank: state.rank,
        success: false,
        error: "",
      };

    case RANK_UPDATE_SUCCESS:
      return {
        loading: false,
        rank: action.payload,
        success: true,
        error: "",
      };

    case RANK_UPDATE_FAIL:
      return {
        loading: false,
        rank: state.rank,
        success: false,
        error: action.payload,
      };

    case RANK_UPDATE_RESET:
      return { loading: false, rank: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const rankDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case RANK_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case RANK_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case RANK_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case RANK_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
