// Main function
const generateTimelineDataFromEngagementServices = (engagementServices) => {
  if (engagementServices) {
    let arr = [];

    engagementServices.forEach((service) => {
      let children = [];
      service.activities.forEach((activity) => {
        children.push({
          end: activity.end
            ? new Date(activity.end)
            : new Date(service.completion),
          id: `${service.id}.${activity.id}`,
          name: activity.name,
          progress: activity.progress,
          start: activity.start
            ? new Date(activity.start)
            : new Date(service.commencement),
        });
      });

      let milestones = [];
      service.deliverables.forEach((deliverable) => {
        milestones.push({
          due: deliverable.due,
          id: `${service.id}.${deliverable.id}`,
          name: deliverable.name,
        });
      });

      arr.push({
        children: children,
        end: new Date(service.end),
        id: `${service.id}`,
        milestones: milestones,
        name: service.name,
        progress: service.progress,
        start: new Date(service.start),
      });
    });

    return arr;
  }

  return [];
};

export default generateTimelineDataFromEngagementServices;
