// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import GenerateButton from "../components/GenerateButton";
import HelpDialog from "../components/HelpDialog";
import InfoPopup from "../components/InfoPopup";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NeedInfo from "../components/NeedInfo";
import PermissionCard from "../components/PermissionCard";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";

// Redux action imports
// Manager
import { listManagersAction } from "../actions/managerActions";

// Need
import { listNeedsAction } from "../actions/needActions";

// Service
import {
  deleteServiceAction,
  listServiceDetailsAction,
  resetDeleteServiceAction,
  resetListServicesAction,
  resetUpdateServiceAction,
  updateServiceAction,
} from "../actions/serviceActions";

// Service activity
import {
  createServiceActivityAction,
  deleteServiceActivityAction,
  listServiceActivitiesAction,
  listServiceActivityDetailsAction,
  resetCreateServiceActivityAction,
  resetDeleteServiceActivityAction,
  resetUpdateServiceActivityAction,
  resetUploadServiceActivitiesAction,
  updateServiceActivityAction,
  uploadServiceActivitiesAction,
} from "../actions/serviceActivityActions";

// Service deliverable
import {
  createServiceDeliverableAction,
  deleteServiceDeliverableAction,
  listServiceDeliverablesAction,
  listServiceDeliverableDetailsAction,
  resetCreateServiceDeliverableAction,
  resetDeleteServiceDeliverableAction,
  resetUpdateServiceDeliverableAction,
  resetUploadServiceDeliverablesAction,
  updateServiceDeliverableAction,
  uploadServiceDeliverablesAction,
} from "../actions/serviceDeliverableActions";

// Service need
import {
  createServiceNeedAction,
  deleteServiceNeedAction,
  listServiceNeedsAction,
  listServiceNeedDetailsAction,
  resetCreateServiceNeedAction,
  resetDeleteServiceNeedAction,
  resetUpdateServiceNeedAction,
  updateServiceNeedAction,
} from "../actions/serviceNeedActions";

// Service permission
import {
  createServicePermissionAction,
  deleteServicePermissionAction,
  listServicePermissionsAction,
  listServicePermissionDetailsAction,
  resetCreateServicePermissionAction,
  resetDeleteServicePermissionAction,
  resetUpdateServicePermissionAction,
  updateServicePermissionAction,
} from "../actions/servicePermissionActions";

// Main function
function ServiceDetailScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Service id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Need
  const needList = useSelector((state) => state.needList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Service
  const serviceDelete = useSelector((state) => state.serviceDelete);

  const serviceDetails = useSelector((state) => state.serviceDetails);

  const serviceUpdate = useSelector((state) => state.serviceUpdate);

  // Service activity
  const serviceActivityCreate = useSelector(
    (state) => state.serviceActivityCreate
  );

  const serviceActivityDelete = useSelector(
    (state) => state.serviceActivityDelete
  );

  const serviceActivityDetails = useSelector(
    (state) => state.serviceActivityDetails
  );

  const serviceActivityList = useSelector((state) => state.serviceActivityList);

  const serviceActivityUpdate = useSelector(
    (state) => state.serviceActivityUpdate
  );

  const serviceActivityUpload = useSelector(
    (state) => state.serviceActivityUpload
  );

  // Service deliverable
  const serviceDeliverableCreate = useSelector(
    (state) => state.serviceDeliverableCreate
  );

  const serviceDeliverableDelete = useSelector(
    (state) => state.serviceDeliverableDelete
  );

  const serviceDeliverableDetails = useSelector(
    (state) => state.serviceDeliverableDetails
  );

  const serviceDeliverableList = useSelector(
    (state) => state.serviceDeliverableList
  );

  const serviceDeliverableUpdate = useSelector(
    (state) => state.serviceDeliverableUpdate
  );

  const serviceDeliverableUpload = useSelector(
    (state) => state.serviceDeliverableUpload
  );

  // Service need
  const serviceNeedCreate = useSelector((state) => state.serviceNeedCreate);

  const serviceNeedDelete = useSelector((state) => state.serviceNeedDelete);

  const serviceNeedDetails = useSelector((state) => state.serviceNeedDetails);

  const serviceNeedList = useSelector((state) => state.serviceNeedList);

  const serviceNeedUpdate = useSelector((state) => state.serviceNeedUpdate);

  // Service permission
  const servicePermissionCreate = useSelector(
    (state) => state.servicePermissionCreate
  );

  const servicePermissionDelete = useSelector(
    (state) => state.servicePermissionDelete
  );

  const servicePermissionDetails = useSelector(
    (state) => state.servicePermissionDetails
  );

  const servicePermissionList = useSelector(
    (state) => state.servicePermissionList
  );

  const servicePermissionUpdate = useSelector(
    (state) => state.servicePermissionUpdate
  );

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("service-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Context
  const [updateContextDrawerIsOpen, setUpdateContextDrawerIsOpen] =
    useState(false);

  // Needs
  const [addNeedDrawerIsOpen, setAddNeedDrawerIsOpen] = useState(false);
  const [updateNeedDrawerIsOpen, setUpdateNeedDrawerIsOpen] = useState(false);

  // Activities
  const [addActivityDrawerIsOpen, setAddActivityDrawerIsOpen] = useState(false);
  const [updateActivityDrawerIsOpen, setUpdateActivityDrawerIsOpen] =
    useState(false);

  // Deliverables
  const [addDeliverableDrawerIsOpen, setAddDeliverableDrawerIsOpen] =
    useState(false);
  const [updateDeliverableDrawerIsOpen, setUpdateDeliverableDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Need
  const needQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [needQuery, setNeedQuery] = useState({
    ...needQueryDefault,
  });

  // Service activity
  const serviceActivityQueryDefault = {
    page: 1,
    searchString: "",
    service: id,
  };
  const [serviceActivityQuery, setServiceActivityQuery] = useState({
    ...serviceActivityQueryDefault,
  });

  // Service deliverable
  const serviceDeliverableQueryDefault = {
    page: 1,
    searchString: "",
    service: id,
  };
  const [serviceDeliverableQuery, setServiceDeliverableQuery] = useState({
    ...serviceDeliverableQueryDefault,
  });

  // Service need
  const serviceNeedQueryDefault = {
    page: 1,
    searchString: "",
    service: id,
  };
  const [serviceNeedQuery, setServiceNeedQuery] = useState({
    ...serviceNeedQueryDefault,
  });

  // Service permission
  const servicePermissionQueryDefault = {
    page: 1,
    searchString: "",
    service: id,
  };
  const [servicePermissionQuery, setServicePermissionQuery] = useState({
    ...servicePermissionQueryDefault,
  });

  // Form variables
  // Service
  const serviceDefault = {
    benefits: "",
    buyers: "",
    description: "",
    manager: "",
    managerName: "",
    name: "",
    restricted: true,
    situation: "",
    status: "Draft",
  };
  const [service, setService] = useState({
    ...serviceDefault,
  });

  // Service activity
  const serviceActivityDefault = {
    activityNumber: "",
    description: "",
    name: "",
  };
  const [serviceActivity, setServiceActivity] = useState({
    ...serviceActivityDefault,
  });

  // Service deliverable
  const serviceDeliverableDefault = {
    deliverableNumber: "",
    description: "",
    name: "",
    template: "",
    templateName: "",
    type: "",
  };
  const [serviceDeliverable, setServiceDeliverable] = useState({
    ...serviceDeliverableDefault,
  });

  // Service need
  const serviceNeedDefault = {
    need: "",
    needDescription: "",
    needName: "",
    notes: "",
  };
  const [serviceNeed, setServiceNeed] = useState({
    ...serviceNeedDefault,
  });

  // Service permission
  const servicePermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [servicePermission, setServicePermission] = useState({
    ...servicePermissionDefault,
  });

  // Other
  const [serviceDeleteKey, setServiceDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Service
    setService({
      ...serviceDefault,
    });

    // Service activity
    setServiceActivity({
      ...serviceActivityDefault,
    });

    // Service deliverable
    setServiceDeliverable({
      ...serviceDeliverableDefault,
    });

    // Service need
    setServiceNeed({
      ...serviceNeedDefault,
    });

    // Service permission
    setServicePermission({
      ...servicePermissionDefault,
    });

    // Query variables
    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Need
    setNeedQuery({
      ...needQueryDefault,
    });
  };

  // Initialise drawer variables for service
  useEffect(() => {
    setService({
      benefits: serviceDetails.service.benefits
        ? serviceDetails.service.benefits
        : serviceDefault.benefits,
      buyers: serviceDetails.service.buyers
        ? serviceDetails.service.buyers
        : serviceDefault.buyers,
      description: serviceDetails.service.description
        ? serviceDetails.service.description
        : serviceDefault.description,
      manager: serviceDetails.service.manager
        ? serviceDetails.service.manager
        : serviceDefault.manager,
      managerName: serviceDetails.service.manager_name
        ? serviceDetails.service.manager_name
        : serviceDefault.managerName,
      name: serviceDetails.service.name
        ? serviceDetails.service.name
        : serviceDefault.name,
      restricted:
        serviceDetails.service.restricted != null
          ? serviceDetails.service.restricted
          : serviceDefault.restricted,
      situation: serviceDetails.service.situation
        ? serviceDetails.service.situation
        : serviceDefault.situation,
      status: serviceDetails.service.status
        ? serviceDetails.service.status
        : serviceDefault.status,
    });
    // eslint-disable-next-line
  }, [serviceDetails.service]);

  // Initialise drawer variables for service activity
  useEffect(() => {
    setServiceActivity({
      activityNumber: serviceActivityDetails.service_activity.activity_number
        ? serviceActivityDetails.service_activity.activity_number
        : serviceActivityDefault.activityNumber,
      description: serviceActivityDetails.service_activity.description
        ? serviceActivityDetails.service_activity.description
        : serviceActivityDefault.description,
      name: serviceActivityDetails.service_activity.name
        ? serviceActivityDetails.service_activity.name
        : serviceActivityDefault.name,
    });
    // eslint-disable-next-line
  }, [serviceActivityDetails.service_activity]);

  // Initialise drawer variables for service deliverable
  useEffect(() => {
    setServiceDeliverable({
      deliverableNumber: serviceDeliverableDetails.service_deliverable
        .deliverable_number
        ? serviceDeliverableDetails.service_deliverable.deliverable_number
        : serviceDeliverableDefault.deliverableNumber,
      description: serviceDeliverableDetails.service_deliverable.description
        ? serviceDeliverableDetails.service_deliverable.description
        : serviceDeliverableDefault.description,
      name: serviceDeliverableDetails.service_deliverable.name
        ? serviceDeliverableDetails.service_deliverable.name
        : serviceDeliverableDefault.name,
      template: serviceDeliverableDetails.service_deliverable.template
        ? serviceDeliverableDetails.service_deliverable.template
        : serviceDeliverableDefault.template,
      templateName: serviceDeliverableDetails.service_deliverable.template
        ? serviceDeliverableDetails.service_deliverable.template
            .split("/")
            .pop()
        : serviceDeliverableDefault.templateName,
      type: serviceDeliverableDetails.service_deliverable.type
        ? serviceDeliverableDetails.service_deliverable.type
        : serviceDeliverableDefault.type,
    });
    // eslint-disable-next-line
  }, [serviceDeliverableDetails.service_deliverable]);

  // Initialise drawer variables for service need
  useEffect(() => {
    setServiceNeed({
      need: serviceNeedDetails.service_need.need
        ? serviceNeedDetails.service_need.need
        : serviceNeedDefault.need,
      needName: serviceNeedDetails.service_need.need_data
        ? serviceNeedDetails.service_need.need_data.name
        : serviceNeedDefault.needName,
      notes: serviceNeedDetails.service_need.notes
        ? serviceNeedDetails.service_need.notes
        : serviceNeedDefault.notes,
    });
    // eslint-disable-next-line
  }, [serviceNeedDetails.service_need]);

  // Initialise drawer variables for service permission
  useEffect(() => {
    setServicePermission({
      level: servicePermissionDetails.service_permission.level
        ? servicePermissionDetails.service_permission.level
        : servicePermissionDefault.level,
      manager: servicePermissionDetails.service_permission.manager
        ? servicePermissionDetails.service_permission.manager
        : servicePermissionDefault.manager,
      managerName: servicePermissionDetails.service_permission.manager_name
        ? servicePermissionDetails.service_permission.manager_name
        : servicePermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [servicePermissionDetails.service_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If service deleted redirect to services
  useEffect(() => {
    if (serviceDelete.success) {
      dispatch(resetDeleteServiceAction());

      dispatch(resetListServicesAction());

      navigate("/services");
    }
    // eslint-disable-next-line
  }, [serviceDelete.success]);

  // Load service details
  useEffect(() => {
    dispatch(listServiceDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNeedsAction({
            ...needQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuery]);

  // Filter service activities
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listServiceActivitiesAction({
            ...serviceActivityQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [serviceActivityQuery]);

  // Filter service deliverables
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listServiceDeliverablesAction({
            ...serviceDeliverableQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [serviceDeliverableQuery]);

  // Filter service needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listServiceNeedsAction({
            ...serviceNeedQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [serviceNeedQuery]);

  // Filter service permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listServicePermissionsAction({ ...servicePermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [servicePermissionQuery]);

  // Form submission
  // Service
  // Delete service
  const deleteService = () => {
    // Dispatch action
    serviceDeleteKey === serviceDetails.service.name
      ? dispatch(deleteServiceAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the service's name",
        });
  };

  // Update service context
  const updateContext = () => {
    // Prepare data
    const service_data = new FormData();

    service.benefits
      ? service_data.append("benefits", service.benefits)
      : service_data.append("benefits", serviceDefault.benefits);

    service.buyers
      ? service_data.append("buyers", service.buyers)
      : service_data.append("buyers", serviceDefault.buyers);

    service.situation
      ? service_data.append("situation", service.situation)
      : service_data.append("situation", serviceDefault.situation);

    // Dispatch action
    dispatch(updateServiceAction(serviceDetails.service.id, service_data));
  };

  // Update service summary
  const updateSummary = () => {
    // Prepare data
    const service_data = new FormData();

    service.description
      ? service_data.append("description", service.description)
      : service_data.append("description", serviceDefault.description);

    service.manager
      ? service_data.append("manager", service.manager)
      : service_data.append("manager", serviceDefault.manager);

    service.name
      ? service_data.append("name", service.name)
      : service_data.append("name", serviceDefault.name);

    service.restricted != null
      ? service_data.append("restricted", service.restricted)
      : service_data.append("restricted", serviceDefault.restricted);

    service.status
      ? service_data.append("status", service.status)
      : service_data.append("status", serviceDefault.status);

    // Dispatch action
    dispatch(updateServiceAction(serviceDetails.service.id, service_data));
  };

  useEffect(() => {
    if (serviceUpdate.success) {
      dispatch(listServiceDetailsAction(id));

      dispatch(resetUpdateServiceAction());
    }
    // eslint-disable-next-line
  }, [serviceUpdate.success]);

  // Service activity
  // Add activity
  const addActivity = () => {
    // Prepare data
    const service_activity_data = new FormData();

    serviceActivity.activityNumber
      ? service_activity_data.append(
          "activity_number",
          serviceActivity.activityNumber
        )
      : service_activity_data.append(
          "activity_number",
          serviceActivityDefault.activityNumber
        );

    serviceActivity.description
      ? service_activity_data.append("description", serviceActivity.description)
      : service_activity_data.append(
          "description",
          serviceActivityDefault.description
        );

    serviceActivity.name
      ? service_activity_data.append("name", serviceActivity.name)
      : service_activity_data.append("name", serviceActivityDefault.name);

    service_activity_data.append("service", id);

    // Dispatch action
    dispatch(createServiceActivityAction(service_activity_data));
  };

  useEffect(() => {
    if (serviceActivityUpload.success) {
      dispatch(listServiceActivitiesAction({ service: id }));

      dispatch(resetUploadServiceActivitiesAction());
    }
    // eslint-disable-next-line
  }, [serviceActivityUpload.success]);

  // Delete activity
  const deleteActivity = () => {
    // Dispatch action
    dispatch(
      deleteServiceActivityAction(serviceActivityDetails.service_activity.id)
    );
  };

  // Update activity
  const updateActivity = () => {
    // Prepare data
    const service_activity_data = new FormData();

    serviceActivity.activityNumber
      ? service_activity_data.append(
          "activity_number",
          serviceActivity.activityNumber
        )
      : service_activity_data.append(
          "activity_number",
          serviceActivityDefault.activityNumber
        );

    serviceActivity.description
      ? service_activity_data.append("description", serviceActivity.description)
      : service_activity_data.append(
          "description",
          serviceActivityDefault.description
        );

    serviceActivity.name
      ? service_activity_data.append("name", serviceActivity.name)
      : service_activity_data.append("name", serviceActivityDefault.name);

    // Dispatch action
    dispatch(
      updateServiceActivityAction(
        serviceActivityDetails.service_activity.id,
        service_activity_data
      )
    );
  };

  // Service deliverable
  // Add deliverable
  const addDeliverable = () => {
    // Prepare data
    const service_deliverable_data = new FormData();

    serviceDeliverable.deliverableNumber
      ? service_deliverable_data.append(
          "deliverable_number",
          serviceDeliverable.deliverableNumber
        )
      : service_deliverable_data.append(
          "deliverable_number",
          serviceDeliverableDefault.deliverableNumber
        );

    serviceDeliverable.description
      ? service_deliverable_data.append(
          "description",
          serviceDeliverable.description
        )
      : service_deliverable_data.append(
          "description",
          serviceDeliverableDefault.description
        );

    serviceDeliverable.name
      ? service_deliverable_data.append("name", serviceDeliverable.name)
      : service_deliverable_data.append("name", serviceDeliverableDefault.name);

    service_deliverable_data.append("service", id);

    serviceDeliverable.template
      ? service_deliverable_data.append("template", serviceDeliverable.template)
      : service_deliverable_data.append(
          "template",
          serviceDeliverableDefault.template
        );

    serviceDeliverable.type
      ? service_deliverable_data.append("type", serviceDeliverable.type)
      : service_deliverable_data.append("type", serviceDeliverableDefault.type);

    // Dispatch action
    dispatch(createServiceDeliverableAction(service_deliverable_data));
  };

  useEffect(() => {
    if (serviceDeliverableUpload.success) {
      dispatch(listServiceDeliverablesAction({ service: id }));

      dispatch(resetUploadServiceDeliverablesAction());
    }
    // eslint-disable-next-line
  }, [serviceDeliverableUpload.success]);

  // Delete deliverable
  const deleteDeliverable = () => {
    // Dispatch deliverable
    dispatch(
      deleteServiceDeliverableAction(
        serviceDeliverableDetails.service_deliverable.id
      )
    );
  };

  // Update deliverable
  const updateDeliverable = () => {
    // Prepare data
    const service_deliverable_data = new FormData();

    serviceDeliverable.deliverableNumber
      ? service_deliverable_data.append(
          "deliverable_number",
          serviceDeliverable.deliverableNumber
        )
      : service_deliverable_data.append(
          "deliverable_number",
          serviceDeliverableDefault.deliverableNumber
        );

    serviceDeliverable.description
      ? service_deliverable_data.append(
          "description",
          serviceDeliverable.description
        )
      : service_deliverable_data.append(
          "description",
          serviceDeliverableDefault.description
        );

    serviceDeliverable.name
      ? service_deliverable_data.append("name", serviceDeliverable.name)
      : service_deliverable_data.append("name", serviceDeliverableDefault.name);

    serviceDeliverable.template
      ? service_deliverable_data.append("template", serviceDeliverable.template)
      : service_deliverable_data.append(
          "template",
          serviceDeliverableDefault.template
        );

    serviceDeliverable.type
      ? service_deliverable_data.append("type", serviceDeliverable.type)
      : service_deliverable_data.append("type", serviceDeliverableDefault.type);

    // Dispatch action
    dispatch(
      updateServiceDeliverableAction(
        serviceDeliverableDetails.service_deliverable.id,
        service_deliverable_data
      )
    );
  };

  // Service need
  // Add need
  const addNeed = () => {
    // Prepare data
    const service_need = new FormData();

    serviceNeed.need
      ? service_need.append("need", serviceNeed.need)
      : service_need.append("need", serviceNeedDefault.need);

    serviceNeed.notes
      ? service_need.append("notes", serviceNeed.notes)
      : service_need.append("notes", serviceNeedDefault.notes);

    service_need.append("service", id);

    // Dispatch action
    dispatch(createServiceNeedAction(service_need));
  };

  // Delete need
  const deleteNeed = () => {
    // Dispatch action
    dispatch(deleteServiceNeedAction(serviceNeedDetails.service_need.id));
  };

  // Update need
  const updateNeed = () => {
    // Prepare data
    const service_need = new FormData();

    serviceNeed.need
      ? service_need.append("need", serviceNeed.need)
      : service_need.append("need", serviceNeedDefault.need);

    serviceNeed.notes
      ? service_need.append("notes", serviceNeed.notes)
      : service_need.append("notes", serviceNeedDefault.notes);

    // Dispatch action
    dispatch(
      updateServiceNeedAction(serviceNeedDetails.service_need.id, service_need)
    );
  };

  // Service permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const service_permission = new FormData();

    servicePermission.level
      ? service_permission.append("level", servicePermission.level)
      : service_permission.append("level", servicePermissionDefault.level);

    servicePermission.manager
      ? service_permission.append("manager", servicePermission.manager)
      : service_permission.append("manager", servicePermissionDefault.manager);

    service_permission.append("service", id);

    // Dispatch action
    dispatch(createServicePermissionAction(service_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteServicePermissionAction(
        servicePermissionDetails.service_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const service_permission = new FormData();

    servicePermission.level
      ? service_permission.append("level", servicePermission.level)
      : service_permission.append("level", servicePermissionDefault.level);

    servicePermission.manager
      ? service_permission.append("manager", servicePermission.manager)
      : service_permission.append("manager", servicePermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateServicePermissionAction(
        servicePermissionDetails.service_permission.id,
        service_permission
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe the service</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Service name */}
                  <FormDrawerTextField2
                    error={serviceUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Service name"
                    labelInfo="(required)"
                    placeholder="Enter the service's name"
                    setValueObject={setService}
                    setValueVariable="name"
                    value={service.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={serviceUpdate.error}
                fieldName="description"
                helperText="advaise.app can use this description to generate content from the organisation's knowledge base"
                label="Description"
                placeholder="Describe the service"
                setValueObject={setService}
                setValueVariable="description"
                value={service.description}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Status */}
                  <FormDrawerButtonField
                    disabled={
                      serviceDetails.service.user_access_status === "edit" ||
                      serviceDetails.service.user_access_status === "view"
                    }
                    error={serviceUpdate.error}
                    fieldName="status"
                    helperText="Services in draft status are not available to include in engagements or proposals"
                    label="Status"
                    options={[
                      {
                        icon: "tick-circle",
                        id: 1,
                        intent: "success",
                        label: "Approved",
                        value: "Approved",
                      },
                      {
                        icon: "new-drawing",
                        id: 2,
                        intent: "primary",
                        label: "Draft",
                        value: "Draft",
                      },
                    ]}
                    setValueObject={setService}
                    setValueVariable="status"
                    value={service.status}
                  />

                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      serviceDetails.service.user_access_status === "edit" ||
                      serviceDetails.service.user_access_status === "view"
                    }
                    error={serviceUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted service can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setService}
                    setValueVariable="restricted"
                    value={service.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      serviceDetails.service.user_access_status === "edit" ||
                      serviceDetails.service.user_access_status === "view"
                    }
                    error={serviceUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the service - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setService}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={service.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the service manager and the
                    service is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={serviceUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Updating a service"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={serviceDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listServiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceUpdate.success}
        saveSuccessMessage="Service updated."
        saving={serviceUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={servicePermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setServicePermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={servicePermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={servicePermissionCreate.error}
              fieldName="level"
              intent={
                servicePermission.level === "Edit"
                  ? "success"
                  : servicePermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setServicePermission}
              setValueVariable="level"
              value={servicePermission.level}
            />
          </Card>
        }
        error={servicePermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listServicePermissionsAction({ service: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateServicePermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={servicePermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={servicePermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={servicePermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setServicePermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={servicePermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={servicePermissionUpdate.error}
              fieldName="level"
              intent={
                servicePermission.level === "Edit"
                  ? "success"
                  : servicePermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setServicePermission}
              setValueVariable="level"
              value={servicePermission.level}
            />
          </Card>
        }
        deleteSuccess={servicePermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={servicePermissionDelete.loading}
        error={servicePermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={servicePermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listServicePermissionsAction({ ...servicePermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteServicePermissionAction());

          dispatch(resetUpdateServicePermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={servicePermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={servicePermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* Context form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define buying triggers</H5>

              <Divider />

              {/* Situation */}
              <FormDrawerTextAreaField3
                error={serviceUpdate.error}
                fieldName="situation"
                label="Why would the client need this service?"
                placeholder="Describe external/internal changes that may compel the client to take action"
                setValueObject={setService}
                setValueVariable="situation"
                value={service.situation}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe benefits</H5>

              <Divider />

              {/* Benefits */}
              <FormDrawerTextAreaField3
                error={serviceUpdate.error}
                fieldName="benefits"
                helperText="advaise.app can use the content of this field in client documents"
                label="What results can the client expect?"
                placeholder="Identify measurable performance improvements resulting from this service"
                setValueObject={setService}
                setValueVariable="benefits"
                value={service.benefits}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Identify buyers</H5>

              <Divider />

              {/* Situation */}

              {/* Buyers */}
              <FormDrawerTextAreaField3
                error={serviceUpdate.error}
                fieldName="buyers"
                label="Who will buy this type of service in the client organisation?"
                placeholder="List examples of roles who will make or influence the decision to buy this service"
                setValueObject={setService}
                setValueVariable="buyers"
                value={service.buyers}
              />
            </Card>
          </div>
        }
        error={serviceUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update context"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Defining the service context"
        icon="edit"
        isOpen={updateContextDrawerIsOpen}
        loading={serviceDetails.loading}
        onSave={updateContext}
        refreshList={() => {
          dispatch(listServiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceUpdate.success}
        saveSuccessMessage="Context updated."
        saving={serviceUpdate.loading}
        setIsOpen={setUpdateContextDrawerIsOpen}
        title="Update context"
      />

      {/* New need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need */}
                <FormDrawerSelectField2
                  error={serviceNeedCreate.error}
                  fieldName="need"
                  helperText="You can select an existing need"
                  icon="user"
                  info={
                    <NeedInfo
                      needId={serviceNeed.need}
                      query={needQueryDefault}
                    />
                  }
                  items={needList.needs}
                  label="Need"
                  labelInfo="(required)"
                  loading={needList.loading}
                  placeholder="Select a need"
                  query={needQuery.searchString}
                  setQueryObject={setNeedQuery}
                  setQueryVariable="searchString"
                  setValueObject={setServiceNeed}
                  setValueIdVariable="need"
                  setValueNameVariable="needName"
                  showInfo={serviceNeed.need}
                  value={serviceNeed.needName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={serviceNeedCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Explain how the service addresses this need"
              setValueObject={setServiceNeed}
              setValueVariable="notes"
              value={serviceNeed.notes}
            />
          </Card>
        }
        error={serviceNeedCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add need"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Managing needs"
        icon="edit"
        isOpen={addNeedDrawerIsOpen}
        onSave={addNeed}
        refreshList={() => {
          dispatch(listServiceNeedsAction({ service: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateServiceNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceNeedCreate.success}
        saveSuccessMessage="Need added."
        saving={serviceNeedCreate.loading}
        setIsOpen={setAddNeedDrawerIsOpen}
        title="Add need"
      />

      {/* Existing need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need */}
                <FormDrawerSelectField2
                  error={serviceNeedUpdate.error}
                  fieldName="need"
                  helperText="You can select an existing need"
                  icon="user"
                  info={
                    <NeedInfo
                      needId={serviceNeed.need}
                      query={needQueryDefault}
                    />
                  }
                  items={needList.needs}
                  label="Need"
                  labelInfo="(required)"
                  loading={needList.loading}
                  placeholder="Select a need"
                  query={needQuery.searchString}
                  setQueryObject={setNeedQuery}
                  setQueryVariable="searchString"
                  setValueObject={setServiceNeed}
                  setValueIdVariable="need"
                  setValueNameVariable="needName"
                  showInfo={serviceNeed.need}
                  value={serviceNeed.needName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={serviceNeedUpdate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Explain how the service addresses this need"
              setValueObject={setServiceNeed}
              setValueVariable="notes"
              value={serviceNeed.notes}
            />
          </Card>
        }
        deleteSuccess={serviceNeedDelete.success}
        deleteSuccessMessage="Need removed."
        deleting={serviceNeedDelete.loading}
        error={serviceNeedUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update need"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Managing needs"
        icon="edit"
        isOpen={updateNeedDrawerIsOpen}
        loading={serviceNeedDetails.loading}
        onDelete={deleteNeed}
        onSave={updateNeed}
        refreshList={() => {
          dispatch(listServiceNeedsAction({ ...serviceNeedQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteServiceNeedAction());

          dispatch(resetUpdateServiceNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceNeedUpdate.success}
        saveSuccessMessage="Need updated."
        saving={serviceNeedUpdate.loading}
        setIsOpen={setUpdateNeedDrawerIsOpen}
        title="Update need"
      />

      {/* New activity form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the activity</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Activity name */}
                <FormDrawerTextField2
                  error={serviceActivityCreate.error}
                  fieldName="name"
                  icon="form"
                  label="Activity name"
                  labelInfo="(required)"
                  placeholder="Enter the activity's name"
                  setValueObject={setServiceActivity}
                  setValueVariable="name"
                  value={serviceActivity.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Sequence number */}
                <FormDrawerTextField2
                  error={serviceActivityCreate.error}
                  fieldName="activity_number"
                  helperText="advaise.app will order activities based on this number"
                  icon="numbered-list"
                  label="Sequence number"
                  placeholder="Enter a sequence number"
                  setValueObject={setServiceActivity}
                  setValueVariable="activityNumber"
                  value={serviceActivity.activityNumber}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={serviceActivityCreate.error}
              fieldName="description"
              helperText="Enter a detailed description suitable to include in client materials"
              label="Description"
              placeholder="Describe the activity"
              setValueObject={setServiceActivity}
              setValueVariable="description"
              value={serviceActivity.description}
            />
          </Card>
        }
        error={serviceActivityCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add activity"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Defining activities"
        icon="edit"
        isOpen={addActivityDrawerIsOpen}
        onSave={addActivity}
        refreshList={() => {
          dispatch(listServiceActivitiesAction({ service: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateServiceActivityAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceActivityCreate.success}
        saveSuccessMessage="Activity created."
        saving={serviceActivityCreate.loading}
        setIsOpen={setAddActivityDrawerIsOpen}
        title="Add activity"
      />

      {/* Existing activity form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the activity</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Activity name */}
                <FormDrawerTextField2
                  error={serviceActivityUpdate.error}
                  fieldName="name"
                  icon="form"
                  label="Activity name"
                  labelInfo="(required)"
                  placeholder="Enter the activity's name"
                  setValueObject={setServiceActivity}
                  setValueVariable="name"
                  value={serviceActivity.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Sequence number */}
                <FormDrawerTextField2
                  error={serviceActivityUpdate.error}
                  fieldName="activity_number"
                  helperText="advaise.app will order activities based on this number"
                  icon="numbered-list"
                  label="Sequence number"
                  placeholder="Enter a sequence number"
                  setValueObject={setServiceActivity}
                  setValueVariable="activityNumber"
                  value={serviceActivity.activityNumber}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={serviceActivityUpdate.error}
              fieldName="description"
              helperText="Enter a detailed description suitable to include in client materials"
              label="Description"
              placeholder="Describe the activity"
              setValueObject={setServiceActivity}
              setValueVariable="description"
              value={serviceActivity.description}
            />
          </Card>
        }
        deleteSuccess={serviceActivityDelete.success}
        deleteSuccessMessage="Activity deleted."
        deleting={serviceActivityDelete.loading}
        error={serviceActivityUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update activity"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Defining activities"
        icon="edit"
        isOpen={updateActivityDrawerIsOpen}
        loading={serviceActivityDetails.loading}
        onDelete={deleteActivity}
        onSave={updateActivity}
        refreshList={() => {
          dispatch(listServiceActivitiesAction({ ...serviceActivityQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteServiceActivityAction());

          dispatch(resetUpdateServiceActivityAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceActivityUpdate.success}
        saveSuccessMessage="Activity updated."
        saving={serviceActivityUpdate.loading}
        setIsOpen={setUpdateActivityDrawerIsOpen}
        title="Update activity"
      />

      {/* New deliverable form drawer */}
      <FormDrawerContainer2
        body={
          <Card>
            <H5>Enter the deliverable</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Deliverable name */}
                <FormDrawerTextField2
                  error={serviceDeliverableCreate.error}
                  fieldName="name"
                  icon="form"
                  label="Deliverable name"
                  labelInfo="(required)"
                  placeholder="Enter the deliverable's name"
                  setValueObject={setServiceDeliverable}
                  setValueVariable="name"
                  value={serviceDeliverable.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Sequence number */}
                <FormDrawerTextField2
                  error={serviceDeliverableCreate.error}
                  fieldName="deliverable_number"
                  helperText="advaise.app will order deliverables based on this number"
                  icon="numbered-list"
                  label="Sequence number"
                  placeholder="Enter a sequence number"
                  setValueObject={setServiceDeliverable}
                  setValueVariable="deliverableNumber"
                  value={serviceDeliverable.deliverableNumber}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Deliverable type */}
                <FormDrawerTextField2
                  error={serviceDeliverableCreate.error}
                  fieldName="type"
                  helperText="For example: document, software, workshop"
                  icon="tag"
                  label="Deliverable type"
                  placeholder="Enter the deliverable's type"
                  setValueObject={setServiceDeliverable}
                  setValueVariable="type"
                  value={serviceDeliverable.type}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Template */}
                <FormDrawerFileField2
                  error={serviceDeliverableCreate.error}
                  fileName="template"
                  label="Template"
                  placeholder="Upload the template"
                  setValueObject={setServiceDeliverable}
                  setValueFileVariable="template"
                  setValueNameVariable="templateName"
                  valueFile={serviceDeliverable.template}
                  valueName={serviceDeliverable.templateName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={serviceDeliverableCreate.error}
              fieldName="description"
              helperText="Enter a detailed description suitable to include in client materials"
              label="Description"
              placeholder="Describe the deliverable"
              setValueObject={setServiceDeliverable}
              setValueVariable="description"
              value={serviceDeliverable.description}
            />
          </Card>
        }
        error={serviceDeliverableCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add deliverable"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Scoping deliverables"
        icon="edit"
        isOpen={addDeliverableDrawerIsOpen}
        onSave={addDeliverable}
        refreshList={() => {
          dispatch(listServiceDeliverablesAction({ service: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateServiceDeliverableAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceDeliverableCreate.success}
        saveSuccessMessage="Deliverable created."
        saving={serviceDeliverableCreate.loading}
        setIsOpen={setAddDeliverableDrawerIsOpen}
        title="Add deliverable"
      />

      {/* Existing deliverable form drawer */}
      <FormDrawerContainer2
        body={
          <Card>
            <H5>Enter the deliverable</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Deliverable name */}
                <FormDrawerTextField2
                  error={serviceDeliverableUpdate.error}
                  fieldName="name"
                  icon="form"
                  label="Deliverable name"
                  labelInfo="(required)"
                  placeholder="Enter the deliverable's name"
                  setValueObject={setServiceDeliverable}
                  setValueVariable="name"
                  value={serviceDeliverable.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Sequence number */}
                <FormDrawerTextField2
                  error={serviceDeliverableUpdate.error}
                  fieldName="deliverable_number"
                  helperText="advaise.app will order deliverables based on this number"
                  icon="numbered-list"
                  label="Sequence number"
                  placeholder="Enter a sequence number"
                  setValueObject={setServiceDeliverable}
                  setValueVariable="deliverableNumber"
                  value={serviceDeliverable.deliverableNumber}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Deliverable type */}
                <FormDrawerTextField2
                  error={serviceDeliverableUpdate.error}
                  fieldName="type"
                  helperText="For example: document, software, workshop"
                  icon="tag"
                  label="Deliverable type"
                  placeholder="Enter the deliverable's type"
                  setValueObject={setServiceDeliverable}
                  setValueVariable="type"
                  value={serviceDeliverable.type}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Template */}
                <FormDrawerFileField2
                  error={serviceDeliverableUpdate.error}
                  fileName="template"
                  label="Template"
                  placeholder="Upload the template"
                  setValueObject={setServiceDeliverable}
                  setValueFileVariable="template"
                  setValueNameVariable="templateName"
                  valueFile={serviceDeliverable.template}
                  valueName={serviceDeliverable.templateName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={serviceDeliverableUpdate.error}
              fieldName="description"
              helperText="Enter a detailed description suitable to include in client materials"
              label="Description"
              placeholder="Describe the deliverable"
              setValueObject={setServiceDeliverable}
              setValueVariable="description"
              value={serviceDeliverable.description}
            />
          </Card>
        }
        deleteSuccess={serviceDeliverableDelete.success}
        deleteSuccessMessage="Deliverable deleted."
        deleting={serviceDeliverableDelete.loading}
        error={serviceDeliverableUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update deliverable"
            module="Services"
            view="Detail"
          />
        }
        helpTitle="Scoping deliverables"
        icon="edit"
        isOpen={updateDeliverableDrawerIsOpen}
        loading={serviceDeliverableDetails.loading}
        onDelete={deleteDeliverable}
        onSave={updateDeliverable}
        refreshList={() => {
          dispatch(
            listServiceDeliverablesAction({ ...serviceDeliverableQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteServiceDeliverableAction());

          dispatch(resetUpdateServiceDeliverableAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceDeliverableUpdate.success}
        saveSuccessMessage="Deliverable updated."
        saving={serviceDeliverableUpdate.loading}
        setIsOpen={setUpdateDeliverableDrawerIsOpen}
        title="Update deliverable"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Services"
              onClick={() => {
                dispatch(resetListServicesAction());

                navigate("/services");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          serviceDetails.service.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={serviceDetails.loading}
        title={`${serviceDetails.service.name}`}
      />

      {/* Error alerts */}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Service delete error */}
      {serviceDelete.error && (
        <ErrorAlert message="We couldn't delete the service. Try refreshing the page." />
      )}
      {/* Service loading error */}
      {serviceDetails.error && (
        <ErrorAlert message="We couldn't load the service. Try refreshing the page." />
      )}
      {/* Service activities loading error */}
      {serviceActivityList.error && (
        <ErrorAlert message="We couldn't load the activity list. Try refreshing the page." />
      )}
      {/* Service deliverables loading error */}
      {serviceDeliverableList.error && (
        <ErrorAlert message="We couldn't load the deliverable list. Try refreshing the page." />
      )}
      {/* Service needs loading error */}
      {serviceNeedList.error && (
        <ErrorAlert message="We couldn't load the service needs. Try refreshing the page." />
      )}
      {/* Service permissions loading error */}
      {servicePermissionList.error && (
        <ErrorAlert message="We couldn't load the service permissions. Try refreshing the page." />
      )}
      {/* Needs loading error */}
      {needList.error && (
        <ErrorAlert message="We couldn't load the need list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/Q-dk7ucPvIc?si=JqEAFzKLwlKCGaCm"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Service"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="service-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="service-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          serviceDetails.service.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listServiceDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={serviceDetails.loading}
                          value={serviceDetails.service.id}
                        />

                        {/* Service name */}
                        <TabSectionField
                          label="Service name"
                          loading={serviceDetails.loading}
                          value={serviceDetails.service.name}
                        />

                        {/* Description */}
                        <TabSectionField
                          label="Description"
                          loading={serviceDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={serviceDetails.service.description}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={serviceDetails.loading}
                          value={
                            <Tag
                              intent={
                                serviceDetails.service.status === "Approved"
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {serviceDetails.service.status}
                            </Tag>
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            serviceDetails.service.restricted
                              ? "Users must have permission to access this service"
                              : "Everyone in the organisation can edit this service"
                          }
                          label="Access"
                          loading={serviceDetails.loading}
                          value={
                            <Tag
                              intent={
                                serviceDetails.service.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {serviceDetails.service.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            serviceDetails.service.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={serviceDetails.loading}
                          value={
                            <NameValue
                              email={serviceDetails.service.manager_name}
                              image={
                                serviceDetails.service.manager_profile_photo
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update service information"
                    icon="heat-grid"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(serviceDetails.service.user_access_status === "owner" ||
                    serviceDetails.service.user_access_status === "admin" ||
                    serviceDetails.service.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={servicePermissionList.loading}
                              setValueObject={setServicePermissionQuery}
                              setValueVariable="searchString"
                              value={servicePermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={servicePermissionList.service_permissions.map(
                              (service_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={service_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listServicePermissionDetailsAction(
                                          service_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={service_permission}
                                  />
                                );
                              }
                            )}
                            count={servicePermissionList.count}
                            loading={servicePermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={servicePermissionQuery.page}
                            setPageObject={setServicePermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, services are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete service */}
                  {(serviceDetails.service.user_access_status === "owner" ||
                    serviceDetails.service.user_access_status === "admin" ||
                    serviceDetails.service.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={serviceDetails.service.cannot_delete}
                              id="confirm-service-name"
                              onChange={(e) => {
                                setServiceDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the service's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={serviceDeleteKey}
                            />

                            <Button
                              disabled={serviceDetails.service.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={serviceDelete.loading}
                              onClick={deleteService}
                              text="Delete this service"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{serviceDetails.service.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the service"
                      icon="delete"
                      message={
                        serviceDetails.service.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this service, you will lose all information associated with it."
                      }
                      messageIntent={
                        serviceDetails.service.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete service"
                    />
                  )}
                </div>
              }
            />

            {/* Definition */}
            <Tab
              id="service-definition-tab"
              title="Definition"
              panel={
                <div>
                  {/* Needs */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          serviceDetails.service.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddNeedDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search need"
                            searching={serviceNeedList.loading}
                            setValueObject={setServiceNeedQuery}
                            setValueVariable="searchString"
                            value={serviceNeedQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={serviceNeedList.service_needs.map(
                            (need, i, { length }) => {
                              return (
                                <div key={need.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>{need.need_data.name}</div>

                                        <div style={{ marginLeft: "5px" }}>
                                          <InfoPopup
                                            content={
                                              <NeedInfo
                                                needData={need.need_data}
                                              />
                                            }
                                          />
                                        </div>
                                      </div>
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive={
                                          serviceDetails.service
                                            .user_access_status !== "view"
                                        }
                                        onClick={() => {
                                          if (
                                            serviceDetails.service
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listServiceNeedDetailsAction(
                                                need.id
                                              )
                                            );

                                            setUpdateNeedDrawerIsOpen(true);
                                          }
                                        }}
                                        text={
                                          need.notes ? (
                                            need.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={serviceNeedList.count}
                          loading={serviceNeedList.loading}
                          noResultsMessage="Add needs."
                          noResultsTitle="No needs found"
                          page={serviceNeedQuery.page}
                          setPageObject={setServiceNeedQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Identify needs that the service addresses"
                    icon="clean"
                    title="Needs"
                  />

                  {/* Context */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            serviceDetails.service.user_access_status === "view"
                          }
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(listServiceDetailsAction(id));

                            setUpdateContextDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Edit"
                        />

                        <GenerateButton
                          disabled={
                            !serviceDetails.service.description ||
                            (serviceDetails.service.benefits &&
                              serviceDetails.service.buyers &&
                              serviceDetails.service.situation) ||
                            serviceDetails.service.user_access_status === "view"
                          }
                          objectDescription={`${serviceDetails.service.name}: ${serviceDetails.service.description}`}
                          objectType="service"
                          onSuccess={(result) => {
                            if (
                              (!("benefits" in result) ||
                                result.benefits === "") &&
                              (!("buyers" in result) || result.buyers === "") &&
                              (!("situation" in result) ||
                                result.situation === "")
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            const serviceData = {
                              benefits: service.benefits || result.benefits,
                              buyers: service.buyers || result.buyers,
                              situation: service.situation || result.situation,
                            };

                            dispatch(updateServiceAction(id, serviceData));
                          }}
                        />
                      </>
                    }
                    content={
                      <>
                        {/* Situation */}
                        <TabSectionField
                          label="Situation"
                          value={
                            <TabSectionFieldCard
                              loading={serviceDetails.loading}
                              text={
                                serviceDetails.loading ? undefined : serviceDetails
                                    .service.situation ? (
                                  serviceDetails.service.situation
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Who needs this service?"
                            />
                          }
                        />

                        {/* Benefits */}
                        <TabSectionField
                          label="Benefits"
                          value={
                            <TabSectionFieldCard
                              loading={serviceDetails.loading}
                              text={
                                serviceDetails.loading ? undefined : serviceDetails
                                    .service.benefits ? (
                                  serviceDetails.service.benefits
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What results can the client expect?"
                            />
                          }
                        />

                        {/* Buyers */}
                        <TabSectionField
                          bottom
                          label="Buyers"
                          value={
                            <TabSectionFieldCard
                              loading={serviceDetails.loading}
                              text={
                                serviceDetails.loading ? undefined : serviceDetails
                                    .service.buyers ? (
                                  serviceDetails.service.buyers
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Who will buy this type of service in the client organisation?"
                            />
                          }
                        />
                      </>
                    }
                    help="Describe the ideal client for this service"
                    icon="locate"
                    title="Context"
                  />

                  {/* Activities */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            serviceDetails.service.user_access_status === "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddActivityDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <GenerateButton
                          disabled={
                            !serviceDetails.service.description ||
                            serviceActivityList.count > 0 ||
                            serviceDetails.service.user_access_status === "view"
                          }
                          objectDescription={`${serviceDetails.service.name}: ${serviceDetails.service.description}`}
                          objectType="service"
                          onSuccess={(result) => {
                            if (
                              !("activities" in result) ||
                              result.activities.length === 0
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            dispatch(
                              uploadServiceActivitiesAction(
                                id,
                                result.activities
                              )
                            );
                          }}
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search activity"
                            searching={serviceActivityList.loading}
                            setValueObject={setServiceActivityQuery}
                            setValueVariable="searchString"
                            value={serviceActivityQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={serviceActivityList.service_activities.map(
                            (activity, i, { length }) => {
                              return (
                                <div key={activity.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      activity.activity_number
                                        ? `${activity.activity_number}. ${activity.name}`
                                        : activity.name
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive
                                        onClick={() => {
                                          if (
                                            serviceDetails.service
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listServiceActivityDetailsAction(
                                                activity.id
                                              )
                                            );

                                            setUpdateActivityDrawerIsOpen(true);
                                          }
                                        }}
                                        text={
                                          activity.description ? (
                                            activity.description
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No description
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={serviceActivityList.count}
                          loading={serviceActivityList.loading}
                          noResultsMessage="Add activities."
                          noResultsTitle="No activities found"
                          page={serviceActivityQuery.page}
                          setPageObject={setServiceActivityQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Add activities to the service"
                    icon="form"
                    title="Activities"
                  />

                  {/* Deliverables */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            serviceDetails.service.user_access_status === "view"
                          }
                          icon="add"
                          intent="primary"
                          style={{ margin: "0 10px 0 0" }}
                          onClick={() => {
                            setAddDeliverableDrawerIsOpen(true);
                          }}
                          text="Add"
                        />

                        <GenerateButton
                          disabled={
                            !serviceDetails.service.description ||
                            serviceDeliverableList.count > 0 ||
                            serviceDetails.service.user_access_status === "view"
                          }
                          objectDescription={`${serviceDetails.service.name}: ${serviceDetails.service.description}`}
                          objectType="service"
                          onSuccess={(result) => {
                            if (
                              !("deliverables" in result) ||
                              result.deliverables.length === 0
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            dispatch(
                              uploadServiceDeliverablesAction(
                                id,
                                result.deliverables
                              )
                            );
                          }}
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search deliverable"
                            searching={serviceDeliverableList.loading}
                            setValueObject={setServiceDeliverableQuery}
                            setValueVariable="searchString"
                            value={serviceDeliverableQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={serviceDeliverableList.service_deliverables.map(
                            (deliverable, i, { length }) => {
                              return (
                                <div key={deliverable.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      deliverable.deliverable_number
                                        ? `${deliverable.deliverable_number}. ${deliverable.name}`
                                        : deliverable.name
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        footer={
                                          deliverable.template && (
                                            <div
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <Button
                                                icon="document"
                                                intent="primary"
                                                minimal
                                                onClick={() =>
                                                  window.open(
                                                    deliverable.template,
                                                    "_blank",
                                                    "noopener,noreferrer"
                                                  )
                                                }
                                                text="Template"
                                              />
                                            </div>
                                          )
                                        }
                                        interactive
                                        onClick={() => {
                                          if (
                                            serviceDetails.service
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listServiceDeliverableDetailsAction(
                                                deliverable.id
                                              )
                                            );

                                            setUpdateDeliverableDrawerIsOpen(
                                              true
                                            );
                                          }
                                        }}
                                        tag={deliverable.type}
                                        text={
                                          deliverable.description ? (
                                            deliverable.description
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No description
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={serviceDeliverableList.count}
                          loading={serviceDeliverableList.loading}
                          noResultsMessage="Add deliverables."
                          noResultsTitle="No deliverables found"
                          page={serviceDeliverableQuery.page}
                          setPageObject={setServiceDeliverableQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Scope the service deliverables"
                    icon="briefcase"
                    title="Deliverables"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default ServiceDetailScreen;
