// Constant imports
import {
  ENGAGEMENT_DOCUMENT_DETAILS_REQUEST,
  ENGAGEMENT_DOCUMENT_DETAILS_SUCCESS,
  ENGAGEMENT_DOCUMENT_DETAILS_FAIL,
  ENGAGEMENT_DOCUMENT_DETAILS_RESET,
  ENGAGEMENT_DOCUMENT_UPDATE_REQUEST,
  ENGAGEMENT_DOCUMENT_UPDATE_SUCCESS,
  ENGAGEMENT_DOCUMENT_UPDATE_FAIL,
  ENGAGEMENT_DOCUMENT_UPDATE_RESET,
} from "../constants/engagementDocumentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List details
export const listEngagementDocumentDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DOCUMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementDocument(id, token);

      dispatch({
        type: ENGAGEMENT_DOCUMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DOCUMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementDocumentDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DOCUMENT_DETAILS_RESET });
  };

// Update
export const updateEngagementDocumentAction =
  (id, engagement_document) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DOCUMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementDocument(
        id,
        token,
        engagement_document
      );

      dispatch({
        type: ENGAGEMENT_DOCUMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DOCUMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementDocumentAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DOCUMENT_UPDATE_RESET });
};
