// Library imports
import { Button, Classes, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";

// Main function
function TableColumnHeadSelectSimple({
  filterObject,
  filterVariable,
  noActive,
  options,
  setFilterObject,
  title,
  width,
}) {
  // Create static copy of options
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    setMenuItems(options);
  }, [options]);

  // JSX UI code
  return (
    <th style={{ padding: "5px", width: width }}>
      <Select
        fill
        filterable={false}
        itemRenderer={(item, { handleClick, handleFocus, modifiers }) => {
          return (
            <MenuItem
              icon={item.icon}
              key={item.display}
              onClick={handleClick}
              onFocus={handleFocus}
              selected={item.value === filterObject[filterVariable]}
              text={item.display}
            />
          );
        }}
        items={menuItems}
        onItemSelect={(item) => {
          setFilterObject((prevState) => {
            return { ...prevState, page: 1, [filterVariable]: item.value };
          });
        }}
      >
        <Button
          active={
            noActive ? undefined : filterObject[filterVariable] ? true : false
          }
          alignText="left"
          fill
          intent={
            noActive
              ? undefined
              : filterObject[filterVariable]
              ? "primary"
              : undefined
          }
          minimal
          text={
            <span className={Classes.TEXT_SMALL}>
              <strong>{title}</strong>
            </span>
          }
          rightIcon="caret-down"
        />
      </Select>
    </th>
  );
}

export default TableColumnHeadSelectSimple;
