export const PROPOSAL_LEGAL_SECTION_LIST_REQUEST =
  "PROPOSAL_LEGAL_SECTION_LIST_REQUEST";
export const PROPOSAL_LEGAL_SECTION_LIST_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_LIST_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_LIST_FAIL =
  "PROPOSAL_LEGAL_SECTION_LIST_FAIL";
export const PROPOSAL_LEGAL_SECTION_LIST_RESET =
  "PROPOSAL_LEGAL_SECTION_LIST_RESET";

export const PROPOSAL_LEGAL_SECTION_CREATE_REQUEST =
  "PROPOSAL_LEGAL_SECTION_CREATE_REQUEST";
export const PROPOSAL_LEGAL_SECTION_CREATE_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_CREATE_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_CREATE_FAIL =
  "PROPOSAL_LEGAL_SECTION_CREATE_FAIL";
export const PROPOSAL_LEGAL_SECTION_CREATE_RESET =
  "PROPOSAL_LEGAL_SECTION_CREATE_RESET";

export const PROPOSAL_LEGAL_SECTION_DETAILS_REQUEST =
  "PROPOSAL_LEGAL_SECTION_DETAILS_REQUEST";
export const PROPOSAL_LEGAL_SECTION_DETAILS_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_DETAILS_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_DETAILS_FAIL =
  "PROPOSAL_LEGAL_SECTION_DETAILS_FAIL";
export const PROPOSAL_LEGAL_SECTION_DETAILS_RESET =
  "PROPOSAL_LEGAL_SECTION_DETAILS_RESET";

export const PROPOSAL_LEGAL_SECTION_UPDATE_REQUEST =
  "PROPOSAL_LEGAL_SECTION_UPDATE_REQUEST";
export const PROPOSAL_LEGAL_SECTION_UPDATE_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_UPDATE_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_UPDATE_FAIL =
  "PROPOSAL_LEGAL_SECTION_UPDATE_FAIL";
export const PROPOSAL_LEGAL_SECTION_UPDATE_RESET =
  "PROPOSAL_LEGAL_SECTION_UPDATE_RESET";

export const PROPOSAL_LEGAL_SECTION_DELETE_REQUEST =
  "PROPOSAL_LEGAL_SECTION_DELETE_REQUEST";
export const PROPOSAL_LEGAL_SECTION_DELETE_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_DELETE_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_DELETE_FAIL =
  "PROPOSAL_LEGAL_SECTION_DELETE_FAIL";
export const PROPOSAL_LEGAL_SECTION_DELETE_RESET =
  "PROPOSAL_LEGAL_SECTION_DELETE_RESET";

export const PROPOSAL_LEGAL_SECTION_DOWNLOAD_REQUEST =
  "PROPOSAL_LEGAL_SECTION_DOWNLOAD_REQUEST";
export const PROPOSAL_LEGAL_SECTION_DOWNLOAD_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_DOWNLOAD_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_DOWNLOAD_FAIL =
  "PROPOSAL_LEGAL_SECTION_DOWNLOAD_FAIL";
export const PROPOSAL_LEGAL_SECTION_DOWNLOAD_RESET =
  "PROPOSAL_LEGAL_SECTION_DOWNLOAD_RESET";

export const PROPOSAL_LEGAL_SECTION_UPLOAD_BEGIN =
  "PROPOSAL_LEGAL_SECTION_UPLOAD_BEGIN";
export const PROPOSAL_LEGAL_SECTION_UPLOAD_REQUEST =
  "PROPOSAL_LEGAL_SECTION_UPLOAD_REQUEST";
export const PROPOSAL_LEGAL_SECTION_UPLOAD_SUCCESS =
  "PROPOSAL_LEGAL_SECTION_UPLOAD_SUCCESS";
export const PROPOSAL_LEGAL_SECTION_UPLOAD_FAIL =
  "PROPOSAL_LEGAL_SECTION_UPLOAD_FAIL";
export const PROPOSAL_LEGAL_SECTION_UPLOAD_END =
  "PROPOSAL_LEGAL_SECTION_UPLOAD_END";
export const PROPOSAL_LEGAL_SECTION_UPLOAD_RESET =
  "PROPOSAL_LEGAL_SECTION_UPLOAD_RESET";
