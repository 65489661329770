// Constant imports
import {
  ENGAGEMENT_LIST_REQUEST,
  ENGAGEMENT_LIST_SUCCESS,
  ENGAGEMENT_LIST_FAIL,
  ENGAGEMENT_LIST_RESET,
  ENGAGEMENT_CREATE_REQUEST,
  ENGAGEMENT_CREATE_SUCCESS,
  ENGAGEMENT_CREATE_FAIL,
  ENGAGEMENT_CREATE_RESET,
  ENGAGEMENT_DETAILS_REQUEST,
  ENGAGEMENT_DETAILS_SUCCESS,
  ENGAGEMENT_DETAILS_FAIL,
  ENGAGEMENT_DETAILS_RESET,
  ENGAGEMENT_UPDATE_REQUEST,
  ENGAGEMENT_UPDATE_SUCCESS,
  ENGAGEMENT_UPDATE_FAIL,
  ENGAGEMENT_UPDATE_RESET,
  ENGAGEMENT_DELETE_REQUEST,
  ENGAGEMENT_DELETE_SUCCESS,
  ENGAGEMENT_DELETE_FAIL,
  ENGAGEMENT_DELETE_RESET,
  ENGAGEMENT_GENERATE_REPORT_DATA_REQUEST,
  ENGAGEMENT_GENERATE_REPORT_DATA_SUCCESS,
  ENGAGEMENT_GENERATE_REPORT_DATA_FAIL,
  ENGAGEMENT_GENERATE_REPORT_DATA_RESET,
  ENGAGEMENT_DOWNLOAD_REQUEST,
  ENGAGEMENT_DOWNLOAD_SUCCESS,
  ENGAGEMENT_DOWNLOAD_FAIL,
  ENGAGEMENT_DOWNLOAD_RESET,
} from "../constants/engagementConstants";

// List all
export const engagementListReducer = (
  state = {
    loading: false,
    count: 0,
    engagements: [],
    totalExpensesActual: 0,
    totalExpensesBaseline: 0,
    totalExpensesCurrent: 0,
    totalFeeActual: 0,
    totalFeeBaseline: 0,
    totalInvoiced: 0,
    totalMarginActual: 0,
    totalMarginBaseline: 0,
    totalMarginCurrent: 0,
    totalRevenue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagements: action.payload.engagements,
        totalExpensesActual: action.payload.totalExpensesActual,
        totalExpensesBaseline: action.payload.totalExpensesBaseline,
        totalExpensesCurrent: action.payload.totalExpensesCurrent,
        totalFeeBaseline: action.payload.totalFeeBaseline,
        totalFeeCurrent: action.payload.totalFeeCurrent,
        totalInvoiced: action.payload.totalInvoiced,
        totalMarginActual: action.payload.totalMarginActual,
        totalMarginBaseline: action.payload.totalMarginBaseline,
        totalMarginCurrent: action.payload.totalMarginCurrent,
        totalRevenue: action.payload.totalRevenue,
        success: true,
        error: "",
      };

    case ENGAGEMENT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagements: [],
        totalExpensesActual: 0,
        totalExpensesBaseline: 0,
        totalExpensesCurrent: 0,
        totalFeeActual: 0,
        totalFeeBaseline: 0,
        totalInvoiced: 0,
        totalMarginActual: 0,
        totalMarginBaseline: 0,
        totalMarginCurrent: 0,
        totalRevenue: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagements: [],
        totalExpensesActual: 0,
        totalExpensesBaseline: 0,
        totalExpensesCurrent: 0,
        totalFeeActual: 0,
        totalFeeBaseline: 0,
        totalInvoiced: 0,
        totalMarginActual: 0,
        totalMarginBaseline: 0,
        totalMarginCurrent: 0,
        totalRevenue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementCreateReducer = (
  state = { loading: false, engagement: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_CREATE_REQUEST:
      return {
        loading: true,
        engagement: state.engagement,
        success: false,
        error: "",
      };

    case ENGAGEMENT_CREATE_SUCCESS:
      return {
        loading: false,
        engagement: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_CREATE_FAIL:
      return {
        loading: false,
        engagement: state.engagement,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_CREATE_RESET:
      return { loading: false, engagement: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const engagementDetailsReducer = (
  state = { loading: false, engagement: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DETAILS_REQUEST:
      return {
        loading: true,
        engagement: state.engagement,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DETAILS_FAIL:
      return {
        loading: false,
        engagement: state.engagement,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DETAILS_RESET:
      return { loading: false, engagement: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const engagementUpdateReducer = (
  state = { loading: false, engagement: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_UPDATE_REQUEST:
      return {
        loading: true,
        engagement: state.engagement,
        success: false,
        error: "",
      };

    case ENGAGEMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_UPDATE_FAIL:
      return {
        loading: false,
        engagement: state.engagement,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_UPDATE_RESET:
      return { loading: false, engagement: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const engagementDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Generate report data
export const engagementGenerateReportDataReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_GENERATE_REPORT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_GENERATE_REPORT_DATA_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_GENERATE_REPORT_DATA_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_GENERATE_REPORT_DATA_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const engagementDownloadReducer = (
  state = { loading: false, result: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DOWNLOAD_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DOWNLOAD_RESET:
      return { loading: false, result: [], success: false, error: "" };

    default:
      return state;
  }
};
