export const BOOKING_CODE_LIST_REQUEST = "BOOKING_CODE_LIST_REQUEST";
export const BOOKING_CODE_LIST_SUCCESS = "BOOKING_CODE_LIST_SUCCESS";
export const BOOKING_CODE_LIST_FAIL = "BOOKING_CODE_LIST_FAIL";
export const BOOKING_CODE_LIST_RESET = "BOOKING_CODE_LIST_RESET";

export const BOOKING_CODE_CREATE_REQUEST = "BOOKING_CODE_CREATE_REQUEST";
export const BOOKING_CODE_CREATE_SUCCESS = "BOOKING_CODE_CREATE_SUCCESS";
export const BOOKING_CODE_CREATE_FAIL = "BOOKING_CODE_CREATE_FAIL";
export const BOOKING_CODE_CREATE_RESET = "BOOKING_CODE_CREATE_RESET";

export const BOOKING_CODE_DETAILS_REQUEST = "BOOKING_CODE_DETAILS_REQUEST";
export const BOOKING_CODE_DETAILS_SUCCESS = "BOOKING_CODE_DETAILS_SUCCESS";
export const BOOKING_CODE_DETAILS_FAIL = "BOOKING_CODE_DETAILS_FAIL";
export const BOOKING_CODE_DETAILS_RESET = "BOOKING_CODE_DETAILS_RESET";

export const BOOKING_CODE_UPDATE_REQUEST = "BOOKING_CODE_UPDATE_REQUEST";
export const BOOKING_CODE_UPDATE_SUCCESS = "BOOKING_CODE_UPDATE_SUCCESS";
export const BOOKING_CODE_UPDATE_FAIL = "BOOKING_CODE_UPDATE_FAIL";
export const BOOKING_CODE_UPDATE_RESET = "BOOKING_CODE_UPDATE_RESET";

export const BOOKING_CODE_DELETE_REQUEST = "BOOKING_CODE_DELETE_REQUEST";
export const BOOKING_CODE_DELETE_SUCCESS = "BOOKING_CODE_DELETE_SUCCESS";
export const BOOKING_CODE_DELETE_FAIL = "BOOKING_CODE_DELETE_FAIL";
export const BOOKING_CODE_DELETE_RESET = "BOOKING_CODE_DELETE_RESET";
