// Library imports
import {
  Card,
  Classes,
  Checkbox,
  Colors,
  Divider,
  Label,
  Radio,
  RadioGroup,
  Spinner,
  SpinnerSize,
  Tag,
  Text,
} from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Demand profile
import {
  resetGenerateDemandProfileAction,
  generateDemandProfileAction,
} from "../actions/demandProfileActions";

// Main function
function FormDrawerLabourExpenseDaysField2({
  bookingProfile,
  customiseDays,
  setValueObject,
  valueObject,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Demand profile
  const demandProfileGenerate = useSelector(
    (state) => state.demandProfileGenerate
  );

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Filter booking profile
  const [filteredBookingProfile, setFilteredBookingProfile] = useState([]);

  useEffect(() => {
    if (
      bookingProfile &&
      Object.keys(bookingProfile).length > 0 &&
      valueObject.finish &&
      valueObject.start
    ) {
      const newBookingProfile = [];

      Object.keys(bookingProfile)
        .filter(
          (key) =>
            new Date(key) >= new Date(getDjangoDate(valueObject.start)) &&
            new Date(key) <= new Date(getDjangoDate(valueObject.finish))
        )
        .forEach((key) => {
          newBookingProfile[key] = bookingProfile[key]["free"];
        });

      setFilteredBookingProfile(newBookingProfile);
    }
  }, [bookingProfile, valueObject.finish, valueObject.start]);

  // Generate demand profile
  const [demandProfileInitialised, setDemandProfileInitialised] =
    useState(false);
  const [freeTimeOffset, setFreeTimeOffset] = useState([]);
  const [initialDemandProfile, setInitialDemandProfile] = useState([]);
  const [initialStaff, setInitialStaff] = useState(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (valueObject.finish && valueObject.staff && valueObject.start) {
        const parameters = {
          end: getDjangoDate(valueObject.finish),
          freeTimeOffset: JSON.stringify(freeTimeOffset),
          method: valueObject.method,
          minutes: valueObject.minutes,
          staff: valueObject.staff,
          start: getDjangoDate(valueObject.start),
          weekdays: JSON.stringify(valueObject.weekdays),
        };

        if (!demandProfileInitialised) {
          // Demand profile not initialised
          if (
            !valueObject.detail ||
            Object.keys(valueObject.detail).length === 0
          ) {
            dispatch(
              generateDemandProfileAction(activeOrganisation.id, parameters)
            );
          } else {
            setInitialDemandProfile(valueObject.detail);

            setFreeTimeOffset(valueObject.detail);

            setInitialStaff(valueObject.staff);
          }

          setDemandProfileInitialised(true);
        } else {
          // Demand profile initialised (and finish/minutes/staff/start changed)
          dispatch(
            generateDemandProfileAction(activeOrganisation.id, parameters)
          );

          // Remove free time offset if staff changed
          if (valueObject.staff !== initialStaff) {
            setFreeTimeOffset([]);
          } else {
            setFreeTimeOffset(initialDemandProfile);
          }
        }
      }
    }, 1000);

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [
    valueObject.finish,
    valueObject.method,
    valueObject.minutes,
    valueObject.staff,
    valueObject.start,
    valueObject.weekdays,
  ]);

  useEffect(() => {
    if (demandProfileGenerate.success) {
      setValueObject((prevState) => {
        return {
          ...prevState,
          detail: demandProfileGenerate.result,
        };
      });

      dispatch(resetGenerateDemandProfileAction());
    }
    // eslint-disable-next-line
  }, [demandProfileGenerate.success]);

  // JSX UI code
  return (
    <div>
      {customiseDays && (
        <>
          {/* Scheduling options */}
          <div
            style={{
              background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
              padding: "10px",
            }}
          >
            <Card
              style={{
                background: lightMode ? Colors.WHITE : Colors.DARK_GRAY4,
                display: "flex",
              }}
            >
              {/* Weekdays included */}
              <div>
                <Label>Weekdays included</Label>

                <Checkbox
                  checked={valueObject.weekdays.mon}
                  inline
                  label="Mon"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: !valueObject.weekdays.mon,
                          tue: valueObject.weekdays.tue,
                          wed: valueObject.weekdays.wed,
                          thu: valueObject.weekdays.thu,
                          fri: valueObject.weekdays.fri,
                          sat: valueObject.weekdays.sat,
                          sun: valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />

                <Checkbox
                  checked={valueObject.weekdays.tue}
                  inline
                  label="Tue"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: valueObject.weekdays.mon,
                          tue: !valueObject.weekdays.tue,
                          wed: valueObject.weekdays.wed,
                          thu: valueObject.weekdays.thu,
                          fri: valueObject.weekdays.fri,
                          sat: valueObject.weekdays.sat,
                          sun: valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />

                <Checkbox
                  checked={valueObject.weekdays.wed}
                  inline
                  label="Wed"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: valueObject.weekdays.mon,
                          tue: valueObject.weekdays.tue,
                          wed: !valueObject.weekdays.wed,
                          thu: valueObject.weekdays.thu,
                          fri: valueObject.weekdays.fri,
                          sat: valueObject.weekdays.sat,
                          sun: valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />

                <Checkbox
                  checked={valueObject.weekdays.thu}
                  inline
                  label="Thu"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: valueObject.weekdays.mon,
                          tue: valueObject.weekdays.tue,
                          wed: valueObject.weekdays.wed,
                          thu: !valueObject.weekdays.thu,
                          fri: valueObject.weekdays.fri,
                          sat: valueObject.weekdays.sat,
                          sun: valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />

                <Checkbox
                  checked={valueObject.weekdays.fri}
                  inline
                  label="Fri"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: valueObject.weekdays.mon,
                          tue: valueObject.weekdays.tue,
                          wed: valueObject.weekdays.wed,
                          thu: valueObject.weekdays.thu,
                          fri: !valueObject.weekdays.fri,
                          sat: valueObject.weekdays.sat,
                          sun: valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />

                <Checkbox
                  checked={valueObject.weekdays.sat}
                  inline
                  label="Sat"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: valueObject.weekdays.mon,
                          tue: valueObject.weekdays.tue,
                          wed: valueObject.weekdays.wed,
                          thu: valueObject.weekdays.thu,
                          fri: valueObject.weekdays.fri,
                          sat: !valueObject.weekdays.sat,
                          sun: valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />

                <Checkbox
                  checked={valueObject.weekdays.sun}
                  inline
                  label="Sun"
                  onChange={() =>
                    setValueObject((prevState) => {
                      return {
                        ...prevState,
                        weekdays: {
                          mon: valueObject.weekdays.mon,
                          tue: valueObject.weekdays.tue,
                          wed: valueObject.weekdays.wed,
                          thu: valueObject.weekdays.thu,
                          fri: valueObject.weekdays.fri,
                          sat: valueObject.weekdays.sat,
                          sun: !valueObject.weekdays.sun,
                        },
                      };
                    })
                  }
                />
              </div>

              <Divider style={{ margin: "0 15px 0 15px" }} />

              {/* Scheduling method */}
              <RadioGroup
                id="method"
                inline
                label="Scheduling method"
                onChange={(event) =>
                  setValueObject((prevState) => {
                    return {
                      ...prevState,
                      method: event.currentTarget.value,
                    };
                  })
                }
                selectedValue={valueObject.method}
              >
                <Radio label="Distribute" value="Distribute" />

                <Radio label="Level" value="Level" />
              </RadioGroup>
            </Card>
          </div>

          {/* Schedule */}
          {Object.keys(filteredBookingProfile).length > 0 &&
            valueObject.detail &&
            Object.keys(valueObject.detail).length > 0 && (
              <div
                style={{
                  background: lightMode
                    ? Colors.LIGHT_GRAY3
                    : Colors.DARK_GRAY5,
                  padding: "0 10px 10px 10px",
                }}
              >
                {demandProfileGenerate.loading ? (
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: "200px",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner size={SpinnerSize.LARGE} />
                  </div>
                ) : (
                  <div
                    className={Classes.TEXT_SMALL}
                    style={{ display: "flex" }}
                  >
                    {/* Left pane */}
                    <div
                      style={{
                        maxWidth: "90px",
                        minWidth: "90px",
                      }}
                    >
                      {/* Heading */}
                      <div
                        style={{
                          background: lightMode
                            ? Colors.WHITE
                            : Colors.DARK_GRAY4,
                          border: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          maxHeight: "70px",
                          minHeight: "70px",
                        }}
                      ></div>

                      {/* Free */}
                      <div
                        style={{
                          alignItems: "center",
                          background: lightMode
                            ? Colors.WHITE
                            : Colors.DARK_GRAY4,
                          borderBottom: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          borderLeft: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          borderRight: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          display: "flex",
                          maxHeight: "45px",
                          minHeight: "45px",
                          padding: "10px",
                        }}
                      >
                        Free
                      </div>

                      {/* Requested */}
                      <div
                        style={{
                          alignItems: "center",
                          background: lightMode
                            ? Colors.WHITE
                            : Colors.DARK_GRAY4,
                          borderBottom: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          borderLeft: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          borderRight: `1px solid ${
                            lightMode ? Colors.GRAY5 : Colors.GRAY1
                          }`,
                          display: "flex",
                          maxHeight: "80px",
                          minHeight: "80px",
                          padding: "10px",
                        }}
                      >
                        Requested
                      </div>
                    </div>

                    {/* Right pane */}
                    <div
                      //className="no-scrollbar"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      {/* Heading */}
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {Object.keys(filteredBookingProfile).map(
                          (key, i, { length }) => {
                            const date = new Date(key);

                            return (
                              <div
                                key={key}
                                style={{
                                  alignItems: "center",
                                  background: lightMode
                                    ? Colors.WHITE
                                    : Colors.DARK_GRAY4,
                                  borderBottom: `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                  borderLeft:
                                    i !== 0 &&
                                    `1px ${
                                      date.getDay() === 1 ? "solid" : "dotted"
                                    } ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                  borderRight:
                                    length - 1 === i &&
                                    `1px solid ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                  borderTop: `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                  display: "flex",
                                  justifyContent: "center",
                                  maxHeight: "70px",
                                  minHeight: "70px",
                                  maxWidth: i === 0 ? 95 : 90,
                                  minWidth: i === 0 ? 95 : 90,
                                  padding:
                                    i === 0 ? "10px 10px 10px 15px" : "10px",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "70px",
                                    justifyContent: "space-between",
                                    padding: "15px",
                                  }}
                                >
                                  <div className={Classes.TEXT_MUTED}>
                                    {date.getFullYear()}
                                  </div>

                                  <div>
                                    <Text ellipsize>
                                      {date.toLocaleString("default", {
                                        day: "numeric",
                                        month: "short",
                                        weekday: "short",
                                      })}
                                    </Text>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>

                      {/* Items */}
                      {/* Free */}
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {Object.entries(filteredBookingProfile).map(
                          ([key, value], i, { length }) => {
                            const date = new Date(key);

                            return (
                              <div
                                key={key}
                                style={{
                                  alignItems: "center",
                                  background:
                                    date.getDay() === 6 || date.getDay() === 0
                                      ? lightMode
                                        ? Colors.LIGHT_GRAY4
                                        : Colors.DARK_GRAY4
                                      : lightMode
                                      ? Colors.LIGHT_GRAY5
                                      : Colors.DARK_GRAY2,
                                  borderBottom: `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                  borderLeft:
                                    i !== 0 &&
                                    `1px ${
                                      date.getDay() === 1 ? "solid" : "dotted"
                                    } ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                  borderRight:
                                    length - 1 === i &&
                                    `1px solid ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                  display: "flex",
                                  justifyContent: "center",
                                  maxHeight: "45px",
                                  minHeight: "45px",
                                  maxWidth: i === 0 ? 95 : 90,
                                  minWidth: i === 0 ? 95 : 90,
                                  paddingLeft: i === 0 ? "5px" : undefined,
                                }}
                              >
                                {freeTimeOffset[key]
                                  ? freeTimeOffset[key] + value <= 0
                                    ? "-"
                                    : convertMinutesToHoursAndMinutes(
                                        freeTimeOffset[key] + value
                                      )
                                  : value <= 0
                                  ? "-"
                                  : convertMinutesToHoursAndMinutes(value)}
                              </div>
                            );
                          }
                        )}
                      </div>

                      {/* Requested */}
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {Object.entries(valueObject.detail).map(
                          ([key, value], i, { length }) => {
                            const date = new Date(key);

                            return (
                              <div
                                key={key}
                                style={{
                                  alignItems: "center",
                                  background:
                                    date.getDay() === 6 || date.getDay() === 0
                                      ? lightMode
                                        ? Colors.LIGHT_GRAY4
                                        : Colors.DARK_GRAY4
                                      : lightMode
                                      ? Colors.LIGHT_GRAY5
                                      : Colors.DARK_GRAY2,
                                  borderBottom: `1px solid ${
                                    lightMode ? Colors.GRAY5 : Colors.GRAY1
                                  }`,
                                  borderLeft:
                                    i !== 0 &&
                                    `1px ${
                                      date.getDay() === 1 ? "solid" : "dotted"
                                    } ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                  borderRight:
                                    length - 1 === i &&
                                    `1px solid ${
                                      lightMode ? Colors.GRAY5 : Colors.GRAY1
                                    }`,
                                  display: "flex",
                                  justifyContent: "center",
                                  maxHeight: "80px",
                                  minHeight: "80px",
                                  maxWidth: i === 0 ? 95 : 90,
                                  minWidth: i === 0 ? 95 : 90,
                                  paddingLeft: i === 0 ? "5px" : undefined,
                                }}
                              >
                                <div style={{ textAlign: "center" }}>
                                  <TimePicker
                                    /*defaultValue={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      value
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      value
                                    ).split(":")[1]
                                  )
                                }*/
                                    onChange={(newTime) => {
                                      const minutes =
                                        newTime.getHours() * 60 +
                                        newTime.getMinutes();

                                      const newDetail = Object.assign(
                                        {},
                                        valueObject.detail
                                      );

                                      newDetail[key] = minutes;

                                      setValueObject((prevState) => {
                                        return {
                                          ...prevState,
                                          detail: newDetail,
                                        };
                                      });
                                    }}
                                    value={
                                      new Date(
                                        0,
                                        0,
                                        0,
                                        convertMinutesToHoursAndMinutes(
                                          value
                                        ).split(":")[0],
                                        convertMinutesToHoursAndMinutes(
                                          value
                                        ).split(":")[1]
                                      )
                                    }
                                  />

                                  <Tag
                                    intent={
                                      freeTimeOffset[key]
                                        ? value <=
                                          filteredBookingProfile[key] +
                                            freeTimeOffset[key]
                                          ? "success"
                                          : "danger"
                                        : value <= filteredBookingProfile[key]
                                        ? "success"
                                        : "danger"
                                    }
                                    style={{
                                      borderRadius: "50%",
                                      height: "10px",
                                      marginTop: "10px",
                                      width: "10px",
                                    }}
                                  ></Tag>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
        </>
      )}
    </div>
  );
}

export default FormDrawerLabourExpenseDaysField2;
