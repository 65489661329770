// Library imports
import { Colors, Divider, H5, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function BookingCodeProfitLossCard({ fill, bookingCodeList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Profit & loss</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Profit & loss for the select booking code(s), covering:</p>

          <UL>
            <li>
              Revenue: Revenue recognised to-date, based on actual cost and
              planned margin.
            </li>

            <li>Expenses: Actual cost to-date.</li>

            <li>Margin: Revenue less expenses to-date.</li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Revenue",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: bookingCodeList.revenueTotal,
        },
        {
          name: "Expenses",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: bookingCodeList.totalExpenseTotal,
        },
        {
          name: "Margin",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: bookingCodeList.marginTotal,
        },
      ]}
      fill={fill}
      noData={
        !bookingCodeList.marginTotal &&
        !bookingCodeList.revenueTotal &&
        !bookingCodeList.totalExpenseTotal
      }
      title="Profit & loss"
      units={activeOrganisation.currency}
    />
  );
}

export default BookingCodeProfitLossCard;
