// Library imports
import { Colors, FormGroup, Slider } from "@blueprintjs/core";
import React from "react";

// Main function
function FormDrawerSliderField({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  intent,
  label,
  labelInfo,
  labelRenderer,
  labelStepSize,
  margin,
  max,
  min,
  setValueObject,
  setValueVariable,
  value,
}) {
  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <div style={{ padding: `5px ${margin} 0 ${margin}` }}>
        <Slider
          disabled={disabled}
          id={fieldName}
          intent={intent}
          labelRenderer={labelRenderer}
          labelStepSize={labelStepSize}
          max={max}
          min={min}
          onChange={(value) => {
            setValueObject((prevState) => {
              return {
                ...prevState,
                [setValueVariable]: value,
              };
            });
          }}
          value={value ? value : min}
        />
      </div>
    </FormGroup>
  );
}

export default FormDrawerSliderField;
