// Constant imports
import {
  SERVICE_ACTIVITY_LIST_REQUEST,
  SERVICE_ACTIVITY_LIST_SUCCESS,
  SERVICE_ACTIVITY_LIST_FAIL,
  SERVICE_ACTIVITY_LIST_RESET,
  SERVICE_ACTIVITY_CREATE_REQUEST,
  SERVICE_ACTIVITY_CREATE_SUCCESS,
  SERVICE_ACTIVITY_CREATE_FAIL,
  SERVICE_ACTIVITY_CREATE_RESET,
  SERVICE_ACTIVITY_DETAILS_REQUEST,
  SERVICE_ACTIVITY_DETAILS_SUCCESS,
  SERVICE_ACTIVITY_DETAILS_FAIL,
  SERVICE_ACTIVITY_DETAILS_RESET,
  SERVICE_ACTIVITY_UPDATE_REQUEST,
  SERVICE_ACTIVITY_UPDATE_SUCCESS,
  SERVICE_ACTIVITY_UPDATE_FAIL,
  SERVICE_ACTIVITY_UPDATE_RESET,
  SERVICE_ACTIVITY_DELETE_REQUEST,
  SERVICE_ACTIVITY_DELETE_SUCCESS,
  SERVICE_ACTIVITY_DELETE_FAIL,
  SERVICE_ACTIVITY_DELETE_RESET,
  SERVICE_ACTIVITY_UPLOAD_BEGIN,
  SERVICE_ACTIVITY_UPLOAD_REQUEST,
  SERVICE_ACTIVITY_UPLOAD_SUCCESS,
  SERVICE_ACTIVITY_UPLOAD_FAIL,
  SERVICE_ACTIVITY_UPLOAD_END,
  SERVICE_ACTIVITY_UPLOAD_RESET,
} from "../constants/serviceActivityConstants";

// List all
export const serviceActivityListReducer = (
  state = {
    loading: false,
    count: 0,
    service_activities: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        service_activities: action.payload.service_activities,
        success: true,
        error: "",
      };

    case SERVICE_ACTIVITY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        service_activities: [],
        success: false,
        error: action.payload,
      };

    case SERVICE_ACTIVITY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        service_activities: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const serviceActivityCreateReducer = (
  state = { loading: false, service_activity: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_ACTIVITY_CREATE_REQUEST:
      return {
        loading: true,
        service_activity: state.service_activity,
        success: false,
        error: "",
      };

    case SERVICE_ACTIVITY_CREATE_SUCCESS:
      return {
        loading: false,
        service_activity: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_ACTIVITY_CREATE_FAIL:
      return {
        loading: false,
        service_activity: state.service_activity,
        success: false,
        error: action.payload,
      };

    case SERVICE_ACTIVITY_CREATE_RESET:
      return {
        loading: false,
        service_activity: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const serviceActivityDetailsReducer = (
  state = { loading: false, service_activity: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_ACTIVITY_DETAILS_REQUEST:
      return {
        loading: true,
        service_activity: state.service_activity,
        success: false,
        error: "",
      };

    case SERVICE_ACTIVITY_DETAILS_SUCCESS:
      return {
        loading: false,
        service_activity: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_ACTIVITY_DETAILS_FAIL:
      return {
        loading: false,
        service_activity: state.service_activity,
        success: false,
        error: action.payload,
      };

    case SERVICE_ACTIVITY_DETAILS_RESET:
      return {
        loading: false,
        service_activity: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const serviceActivityUpdateReducer = (
  state = { loading: false, service_activity: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_ACTIVITY_UPDATE_REQUEST:
      return {
        loading: true,
        service_activity: state.service_activity,
        success: false,
        error: "",
      };

    case SERVICE_ACTIVITY_UPDATE_SUCCESS:
      return {
        loading: false,
        service_activity: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_ACTIVITY_UPDATE_FAIL:
      return {
        loading: false,
        service_activity: state.service_activity,
        success: false,
        error: action.payload,
      };

    case SERVICE_ACTIVITY_UPDATE_RESET:
      return {
        loading: false,
        service_activity: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const serviceActivityDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_ACTIVITY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case SERVICE_ACTIVITY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case SERVICE_ACTIVITY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case SERVICE_ACTIVITY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Upload
export const serviceActivityUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_ACTIVITY_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case SERVICE_ACTIVITY_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_ACTIVITY_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
      };

    case SERVICE_ACTIVITY_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case SERVICE_ACTIVITY_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case SERVICE_ACTIVITY_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
