// Library imports
import { Colors, Divider, H5, Tag, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardTwoBarChart from "../components/DataCardTwoBarChart";

// Main function
function EngagementPlanAtCompletionCard({
  fill,
  engagementList,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardTwoBarChart
      comments={
        <>
          <H5>Plan at completion</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Summary of financial estimates for the selected engagement(s),
            including:
          </p>

          <UL>
            <li>Fee: Total engagement revenue.</li>

            <li>
              Expenses: Total cost, including labour and non-labour expenses.
            </li>

            <li>Margin: Total engagement profit margin.</li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Fee</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {engagementList.totalFeeBaseline <=
                engagementList.totalFeeCurrent ? (
                  <div>
                    <Tag intent="success">On track</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The expected revenue is as or greater than planned for the
                      selected engagement(s).
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The estimated fee is lower than the baseline for the
                      selected engagement(s). You should consider options to
                      bill the full fee without reducing profitability.
                    </div>
                  </div>
                )}
              </div>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Expenses</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {engagementList.totalExpensesBaseline >=
                engagementList.totalExpensesCurrent ? (
                  <div>
                    <Tag intent="success">On track</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The expected cost at completion is as or better than
                      planned for the selected engagement(s).
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The estimated cost at completion exceeds the agreed
                      baseline for the selected engagement(s). You need to
                      reduce expenses or review the budget.
                    </div>
                  </div>
                )}
              </div>

              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Margin</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {engagementList.totalMarginBaseline <=
                engagementList.totalMarginCurrent ? (
                  <div>
                    <Tag intent="success">On track</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The expected profit margin is as or better than planned
                      for the selected engagement(s).
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The profit forecast falls short of the plan for the
                      selected engagement(s). You should try to negotiate a
                      higher fee or reduce expenses.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Fee",
          fill1: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          value1: engagementList.totalFeeBaseline,
          value2: engagementList.totalFeeCurrent,
        },
        {
          name: "Expenses",
          fill1: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          value1: engagementList.totalExpensesBaseline,
          value2: engagementList.totalExpensesCurrent,
        },
        {
          name: "Margin",
          fill1: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          value1: engagementList.totalMarginBaseline,
          value2: engagementList.totalMarginCurrent,
        },
      ]}
      fill={fill}
      legendData={[
        {
          color: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          type: "square",
          value: "Baseline",
        },
        {
          color: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          type: "square",
          value: "Current",
        },
      ]}
      noData={
        !engagementList.totalExpensesBaseline &&
        !engagementList.totalExpensesCurrent &&
        !engagementList.totalFeeBaseline &&
        !engagementList.totalExpensesCurrent &&
        !engagementList.totalMarginBaseline &&
        !engagementList.totalMarginCurrent
      }
      title="Plan at completion"
      units={activeOrganisation.currency}
    />
  );
}

export default EngagementPlanAtCompletionCard;
