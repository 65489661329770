// Constant imports
import {
  ENGAGEMENT_DOCUMENT_DETAILS_REQUEST,
  ENGAGEMENT_DOCUMENT_DETAILS_SUCCESS,
  ENGAGEMENT_DOCUMENT_DETAILS_FAIL,
  ENGAGEMENT_DOCUMENT_DETAILS_RESET,
  ENGAGEMENT_DOCUMENT_UPDATE_REQUEST,
  ENGAGEMENT_DOCUMENT_UPDATE_SUCCESS,
  ENGAGEMENT_DOCUMENT_UPDATE_FAIL,
  ENGAGEMENT_DOCUMENT_UPDATE_RESET,
} from "../constants/engagementDocumentConstants";

// List details
export const engagementDocumentDetailsReducer = (
  state = {
    loading: false,
    engagement_document: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DOCUMENT_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_document: state.engagement_document,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DOCUMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_document: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DOCUMENT_DETAILS_FAIL:
      return {
        loading: false,
        engagement_document: state.engagement_document,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DOCUMENT_DETAILS_RESET:
      return {
        loading: false,
        engagement_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementDocumentUpdateReducer = (
  state = {
    loading: false,
    engagement_document: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DOCUMENT_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_document: state.engagement_document,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DOCUMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_document: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DOCUMENT_UPDATE_FAIL:
      return {
        loading: false,
        engagement_document: state.engagement_document,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DOCUMENT_UPDATE_RESET:
      return {
        loading: false,
        engagement_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
