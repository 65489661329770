// Constant imports
import {
  CHECKOUT_CREATE_REQUEST,
  CHECKOUT_CREATE_SUCCESS,
  CHECKOUT_CREATE_FAIL,
  CHECKOUT_CREATE_RESET,
  CUSTOMER_PORTAL_CREATE_REQUEST,
  CUSTOMER_PORTAL_CREATE_SUCCESS,
  CUSTOMER_PORTAL_CREATE_FAIL,
  CUSTOMER_PORTAL_CREATE_RESET,
} from "../constants/paymentConstants";

// Create checkout
export const checkoutCreateReducer = (
  state = { loading: false, checkout: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CHECKOUT_CREATE_REQUEST:
      return {
        loading: true,
        checkout: state.checkout,
        success: false,
        error: "",
      };

    case CHECKOUT_CREATE_SUCCESS:
      return {
        loading: false,
        checkout: action.payload,
        success: true,
        error: "",
      };

    case CHECKOUT_CREATE_FAIL:
      return {
        loading: false,
        checkout: state.checkout,
        success: false,
        error: action.payload,
      };

    case CHECKOUT_CREATE_RESET:
      return { loading: false, checkout: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Create customer portal
export const customerPortalCreateReducer = (
  state = { loading: false, customer_portal: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CUSTOMER_PORTAL_CREATE_REQUEST:
      return {
        loading: true,
        customer_portal: state.customer_portal,
        success: false,
        error: "",
      };

    case CUSTOMER_PORTAL_CREATE_SUCCESS:
      return {
        loading: false,
        customer_portal: action.payload,
        success: true,
        error: "",
      };

    case CUSTOMER_PORTAL_CREATE_FAIL:
      return {
        loading: false,
        customer_portal: state.customer_portal,
        success: false,
        error: action.payload,
      };

    case CUSTOMER_PORTAL_CREATE_RESET:
      return { loading: false, customer_portal: {}, success: false, error: "" };

    default:
      return state;
  }
};
