export const CLIENT_KPI_LIST_REQUEST = "CLIENT_KPI_LIST_REQUEST";
export const CLIENT_KPI_LIST_SUCCESS = "CLIENT_KPI_LIST_SUCCESS";
export const CLIENT_KPI_LIST_FAIL = "CLIENT_KPI_LIST_FAIL";
export const CLIENT_KPI_LIST_RESET = "CLIENT_KPI_LIST_RESET";

export const CLIENT_KPI_CREATE_REQUEST = "CLIENT_KPI_CREATE_REQUEST";
export const CLIENT_KPI_CREATE_SUCCESS = "CLIENT_KPI_CREATE_SUCCESS";
export const CLIENT_KPI_CREATE_FAIL = "CLIENT_KPI_CREATE_FAIL";
export const CLIENT_KPI_CREATE_RESET = "CLIENT_KPI_CREATE_RESET";

export const CLIENT_KPI_DETAILS_REQUEST = "CLIENT_KPI_DETAILS_REQUEST";
export const CLIENT_KPI_DETAILS_SUCCESS = "CLIENT_KPI_DETAILS_SUCCESS";
export const CLIENT_KPI_DETAILS_FAIL = "CLIENT_KPI_DETAILS_FAIL";
export const CLIENT_KPI_DETAILS_RESET = "CLIENT_KPI_DETAILS_RESET";

export const CLIENT_KPI_UPDATE_REQUEST = "CLIENT_KPI_UPDATE_REQUEST";
export const CLIENT_KPI_UPDATE_SUCCESS = "CLIENT_KPI_UPDATE_SUCCESS";
export const CLIENT_KPI_UPDATE_FAIL = "CLIENT_KPI_UPDATE_FAIL";
export const CLIENT_KPI_UPDATE_RESET = "CLIENT_KPI_UPDATE_RESET";

export const CLIENT_KPI_DELETE_REQUEST = "CLIENT_KPI_DELETE_REQUEST";
export const CLIENT_KPI_DELETE_SUCCESS = "CLIENT_KPI_DELETE_SUCCESS";
export const CLIENT_KPI_DELETE_FAIL = "CLIENT_KPI_DELETE_FAIL";
export const CLIENT_KPI_DELETE_RESET = "CLIENT_KPI_DELETE_RESET";