// Constant imports
import {
  CLIENT_STAKEHOLDER_LIST_REQUEST,
  CLIENT_STAKEHOLDER_LIST_SUCCESS,
  CLIENT_STAKEHOLDER_LIST_FAIL,
  CLIENT_STAKEHOLDER_LIST_RESET,
  CLIENT_STAKEHOLDER_CREATE_REQUEST,
  CLIENT_STAKEHOLDER_CREATE_SUCCESS,
  CLIENT_STAKEHOLDER_CREATE_FAIL,
  CLIENT_STAKEHOLDER_CREATE_RESET,
  CLIENT_STAKEHOLDER_DETAILS_REQUEST,
  CLIENT_STAKEHOLDER_DETAILS_SUCCESS,
  CLIENT_STAKEHOLDER_DETAILS_FAIL,
  CLIENT_STAKEHOLDER_DETAILS_RESET,
  CLIENT_STAKEHOLDER_UPDATE_REQUEST,
  CLIENT_STAKEHOLDER_UPDATE_SUCCESS,
  CLIENT_STAKEHOLDER_UPDATE_FAIL,
  CLIENT_STAKEHOLDER_UPDATE_RESET,
  CLIENT_STAKEHOLDER_DELETE_REQUEST,
  CLIENT_STAKEHOLDER_DELETE_SUCCESS,
  CLIENT_STAKEHOLDER_DELETE_FAIL,
  CLIENT_STAKEHOLDER_DELETE_RESET,
} from "../constants/clientStakeholderConstants";

// List all
export const clientStakeholderListReducer = (
  state = {
    loading: false,
    count: 0,
    client_stakeholders: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_STAKEHOLDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_STAKEHOLDER_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        client_stakeholders: action.payload.client_stakeholders,
        success: true,
        error: "",
      };

    case CLIENT_STAKEHOLDER_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        client_stakeholders: [],
        success: false,
        error: action.payload,
      };

    case CLIENT_STAKEHOLDER_LIST_RESET:
      return {
        loading: false,
        count: 0,
        client_stakeholders: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientStakeholderCreateReducer = (
  state = { loading: false, client_stakeholder: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_STAKEHOLDER_CREATE_REQUEST:
      return {
        loading: true,
        client_stakeholder: state.client_stakeholder,
        success: false,
        error: "",
      };

    case CLIENT_STAKEHOLDER_CREATE_SUCCESS:
      return {
        loading: false,
        client_stakeholder: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_STAKEHOLDER_CREATE_FAIL:
      return {
        loading: false,
        client_stakeholder: state.client_stakeholder,
        success: false,
        error: action.payload,
      };

    case CLIENT_STAKEHOLDER_CREATE_RESET:
      return {
        loading: false,
        client_stakeholder: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const clientStakeholderDetailsReducer = (
  state = { loading: false, client_stakeholder: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_STAKEHOLDER_DETAILS_REQUEST:
      return {
        loading: true,
        client_stakeholder: state.client_stakeholder,
        success: false,
        error: "",
      };

    case CLIENT_STAKEHOLDER_DETAILS_SUCCESS:
      return {
        loading: false,
        client_stakeholder: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_STAKEHOLDER_DETAILS_FAIL:
      return {
        loading: false,
        client_stakeholder: state.client_stakeholder,
        success: false,
        error: action.payload,
      };

    case CLIENT_STAKEHOLDER_DETAILS_RESET:
      return {
        loading: false,
        client_stakeholder: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const clientStakeholderUpdateReducer = (
  state = { loading: false, client_stakeholder: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_STAKEHOLDER_UPDATE_REQUEST:
      return {
        loading: true,
        client_stakeholder: state.client_stakeholder,
        success: false,
        error: "",
      };

    case CLIENT_STAKEHOLDER_UPDATE_SUCCESS:
      return {
        loading: false,
        client_stakeholder: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_STAKEHOLDER_UPDATE_FAIL:
      return {
        loading: false,
        client_stakeholder: state.client_stakeholder,
        success: false,
        error: action.payload,
      };

    case CLIENT_STAKEHOLDER_UPDATE_RESET:
      return {
        loading: false,
        client_stakeholder: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const clientStakeholderDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_STAKEHOLDER_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_STAKEHOLDER_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_STAKEHOLDER_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_STAKEHOLDER_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
