// Constant imports
import {
  PROPOSAL_STAFF_LIST_REQUEST,
  PROPOSAL_STAFF_LIST_SUCCESS,
  PROPOSAL_STAFF_LIST_FAIL,
  PROPOSAL_STAFF_LIST_RESET,
  PROPOSAL_STAFF_CREATE_REQUEST,
  PROPOSAL_STAFF_CREATE_SUCCESS,
  PROPOSAL_STAFF_CREATE_FAIL,
  PROPOSAL_STAFF_CREATE_RESET,
  PROPOSAL_STAFF_DETAILS_REQUEST,
  PROPOSAL_STAFF_DETAILS_SUCCESS,
  PROPOSAL_STAFF_DETAILS_FAIL,
  PROPOSAL_STAFF_DETAILS_RESET,
  PROPOSAL_STAFF_UPDATE_REQUEST,
  PROPOSAL_STAFF_UPDATE_SUCCESS,
  PROPOSAL_STAFF_UPDATE_FAIL,
  PROPOSAL_STAFF_UPDATE_RESET,
  PROPOSAL_STAFF_DELETE_REQUEST,
  PROPOSAL_STAFF_DELETE_SUCCESS,
  PROPOSAL_STAFF_DELETE_FAIL,
  PROPOSAL_STAFF_DELETE_RESET,
} from "../constants/proposalStaffConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalStaffAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_STAFF_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalStaffList(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_STAFF_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_staff: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_STAFF_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalStaffListAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_STAFF_LIST_RESET });
};

// Create
export const createProposalStaffAction =
  (proposal_staff) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_STAFF_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalStaff(
        token,
        proposal_staff
      );

      dispatch({
        type: PROPOSAL_STAFF_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_STAFF_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalStaffAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_STAFF_CREATE_RESET });
};

// List details
export const listProposalStaffDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_STAFF_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalStaff(id, token);

      dispatch({
        type: PROPOSAL_STAFF_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_STAFF_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalStaffDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_STAFF_DETAILS_RESET });
};

// Update
export const updateProposalStaffAction =
  (id, proposal_staff) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_STAFF_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalStaff(
        id,
        token,
        proposal_staff
      );

      dispatch({
        type: PROPOSAL_STAFF_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_STAFF_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalStaffAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_STAFF_UPDATE_RESET });
};

// Delete
export const deleteProposalStaffAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_STAFF_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteProposalStaff(id, token);

    dispatch({
      type: PROPOSAL_STAFF_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_STAFF_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteProposalStaffAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_STAFF_DELETE_RESET });
};
