// Constant imports
import {
  CLIENT_NEED_LIST_REQUEST,
  CLIENT_NEED_LIST_SUCCESS,
  CLIENT_NEED_LIST_FAIL,
  CLIENT_NEED_LIST_RESET,
  CLIENT_NEED_CREATE_REQUEST,
  CLIENT_NEED_CREATE_SUCCESS,
  CLIENT_NEED_CREATE_FAIL,
  CLIENT_NEED_CREATE_RESET,
  CLIENT_NEED_DETAILS_REQUEST,
  CLIENT_NEED_DETAILS_SUCCESS,
  CLIENT_NEED_DETAILS_FAIL,
  CLIENT_NEED_DETAILS_RESET,
  CLIENT_NEED_UPDATE_REQUEST,
  CLIENT_NEED_UPDATE_SUCCESS,
  CLIENT_NEED_UPDATE_FAIL,
  CLIENT_NEED_UPDATE_RESET,
  CLIENT_NEED_DELETE_REQUEST,
  CLIENT_NEED_DELETE_SUCCESS,
  CLIENT_NEED_DELETE_FAIL,
  CLIENT_NEED_DELETE_RESET,
} from "../constants/clientNeedConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientNeedsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_NEED_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClientNeeds(params, token);

    dispatch({
      type: CLIENT_NEED_LIST_SUCCESS,

      payload: {
        count: data.count,
        client_needs: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: CLIENT_NEED_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListClientNeedsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_NEED_LIST_RESET });
};

// Create
export const createClientNeedAction =
  (client_need) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_NEED_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createClientNeed(
        token,
        client_need
      );

      dispatch({
        type: CLIENT_NEED_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_NEED_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateClientNeedAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_NEED_CREATE_RESET });
};

// List details
export const listClientNeedDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_NEED_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientNeed(id, token);

      dispatch({
        type: CLIENT_NEED_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_NEED_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListClientNeedDetailsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_NEED_DETAILS_RESET });
};

// Update
export const updateClientNeedAction =
  (id, client_need) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_NEED_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateClientNeed(
        id,
        token,
        client_need
      );

      dispatch({
        type: CLIENT_NEED_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_NEED_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientNeedAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_NEED_UPDATE_RESET });
};

// Delete
export const deleteClientNeedAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_NEED_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteClientNeed(id, token);

    dispatch({
      type: CLIENT_NEED_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_NEED_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteClientNeedAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_NEED_DELETE_RESET });
};
