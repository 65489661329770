// Library imports
import { Colors, FormGroup } from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import React from "react";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function FormDrawerTimeField({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  inline,
  label,
  labelInfo,
  maxTime,
  minTime,
  setValueObject,
  setValueVariable,
  showArrowButtons,
  value,
}) {
  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      inline={inline}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <TimePicker
        disabled={disabled}
        id={fieldName}
        maxTime={maxTime}
        minTime={minTime}
        onChange={(newTime) => {
          const minutes = newTime.getHours() * 60 + newTime.getMinutes();

          setValueObject((prevState) => {
            return {
              ...prevState,
              [setValueVariable]: minutes,
            };
          });
        }}
        showArrowButtons={showArrowButtons}
        value={
          value
            ? new Date(
                0,
                0,
                0,
                convertMinutesToHoursAndMinutes(value).split(":")[0],
                convertMinutesToHoursAndMinutes(value).split(":")[1]
              )
            : null
        }
      />
    </FormGroup>
  );
}

export default FormDrawerTimeField;
