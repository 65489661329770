export const LOCATION_LIST_REQUEST = "LOCATION_LIST_REQUEST";
export const LOCATION_LIST_SUCCESS = "LOCATION_LIST_SUCCESS";
export const LOCATION_LIST_FAIL = "LOCATION_LIST_FAIL";
export const LOCATION_LIST_RESET = "LOCATION_LIST_RESET";

export const LOCATION_CREATE_REQUEST = "LOCATION_CREATE_REQUEST";
export const LOCATION_CREATE_SUCCESS = "LOCATION_CREATE_SUCCESS";
export const LOCATION_CREATE_FAIL = "LOCATION_CREATE_FAIL";
export const LOCATION_CREATE_RESET = "LOCATION_CREATE_RESET";

export const LOCATION_DETAILS_REQUEST = "LOCATION_DETAILS_REQUEST";
export const LOCATION_DETAILS_SUCCESS = "LOCATION_DETAILS_SUCCESS";
export const LOCATION_DETAILS_FAIL = "LOCATION_DETAILS_FAIL";
export const LOCATION_DETAILS_RESET = "LOCATION_DETAILS_RESET";

export const LOCATION_UPDATE_REQUEST = "LOCATION_UPDATE_REQUEST";
export const LOCATION_UPDATE_SUCCESS = "LOCATION_UPDATE_SUCCESS";
export const LOCATION_UPDATE_FAIL = "LOCATION_UPDATE_FAIL";
export const LOCATION_UPDATE_RESET = "LOCATION_UPDATE_RESET";

export const LOCATION_DELETE_REQUEST = "LOCATION_DELETE_REQUEST";
export const LOCATION_DELETE_SUCCESS = "LOCATION_DELETE_SUCCESS";
export const LOCATION_DELETE_FAIL = "LOCATION_DELETE_FAIL";
export const LOCATION_DELETE_RESET = "LOCATION_DELETE_RESET";
