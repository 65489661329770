// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function ProposalAverageValueCard({ fill, proposalList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Average value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Average value of proposals closed. These are proposals that have
            been won, lost, or cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {Math.max(
                  proposalList.averageValueOfProposalsCancelled,
                  proposalList.averageValueOfProposalsLost,
                  proposalList.averageValueOfProposalsWon
                ) === proposalList.averageValueOfProposalsWon ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm achieves greater success when pursuing larger
                      opportunities, indicating that you are capable of scaling
                      your operations and can confidently pursue bigger
                      opportunities.
                    </div>
                  </div>
                ) : Math.max(
                    proposalList.averageValueOfProposalsCancelled,
                    proposalList.averageValueOfProposalsLost,
                    proposalList.averageValueOfProposalsWon
                  ) === proposalList.averageValueOfProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      As the value of a proposal increases, the risk of
                      cancellation also tends to increase. To mitigate this
                      risk, it may be necessary to improve the qualification
                      process for larger opportunities, ensuring that clients
                      are willing and able to engage your services and that your
                      team possesses the necessary skills to deliver quality
                      work.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm has a tendency to lose proposals with higher
                      values. To address this issue, it may be necessary to
                      enhance the differentiation of your offer or re-evaluate
                      your pricing strategy.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: proposalList.averageValueOfProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: proposalList.averageValueOfProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: proposalList.averageValueOfProposalsCancelled,
        },
      ]}
      fill={fill}
      noData={
        !proposalList.averageValueOfProposalsCancelled &&
        !proposalList.averageValueOfProposalsLost &&
        !proposalList.averageValueOfProposalsWon
      }
      title="Average value"
      units={activeOrganisation.currency}
    />
  );
}

export default ProposalAverageValueCard;
