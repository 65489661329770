// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  MANAGER_LIST_REQUEST,
  MANAGER_LIST_SUCCESS,
  MANAGER_LIST_FAIL,
  MANAGER_LIST_RESET,
  MANAGER_DETAILS_REQUEST,
  MANAGER_DETAILS_SUCCESS,
  MANAGER_DETAILS_FAIL,
  MANAGER_DETAILS_RESET,
  MANAGER_UPDATE_REQUEST,
  MANAGER_UPDATE_SUCCESS,
  MANAGER_UPDATE_FAIL,
  MANAGER_UPDATE_RESET,
  MANAGER_DOWNLOAD_REQUEST,
  MANAGER_DOWNLOAD_SUCCESS,
  MANAGER_DOWNLOAD_FAIL,
  MANAGER_DOWNLOAD_RESET,
} from "../constants/managerConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listManagersAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: MANAGER_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getManagers(params, token);

    dispatch({
      type: MANAGER_LIST_SUCCESS,

      payload: { count: data.count, managers: data.results },
    });
  } catch (error) {
    dispatch({
      type: MANAGER_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListManagersAction = () => async (dispatch) => {
  dispatch({ type: MANAGER_LIST_RESET });
};

// List details
export const listManagerDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MANAGER_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getManager(id, token);

    dispatch({
      type: MANAGER_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MANAGER_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListManagerDetailsAction = () => async (dispatch) => {
  dispatch({ type: MANAGER_DETAILS_RESET });
};

// Update
export const updateManagerAction =
  (id, manager) => async (dispatch, getState) => {
    try {
      dispatch({ type: MANAGER_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateManager(
        id,
        token,
        manager
      );

      dispatch({
        type: MANAGER_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MANAGER_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateManagerAction = () => async (dispatch) => {
  dispatch({ type: MANAGER_UPDATE_RESET });
};

// Download
export const downloadManagersAction =
  (numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: MANAGER_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var managers = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getManagers(
          {
            organisation: localStorage.getItem("activeOrganisationId"),
            page: page,
          },
          token
        );

        for (var recordNum = 0; recordNum < data.results.length; recordNum++) {
          managers.push(data.results[recordNum]);
        }
      }

      dispatch({
        type: MANAGER_DOWNLOAD_SUCCESS,

        payload: {
          managers: managers,
        },
      });
    } catch (error) {
      dispatch({
        type: MANAGER_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadManagersAction = () => async (dispatch) => {
  dispatch({ type: MANAGER_DOWNLOAD_RESET });
};
