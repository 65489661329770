// Constant imports
import {
  STAFF_COMPETENCY_LIST_REQUEST,
  STAFF_COMPETENCY_LIST_SUCCESS,
  STAFF_COMPETENCY_LIST_FAIL,
  STAFF_COMPETENCY_LIST_RESET,
  STAFF_COMPETENCY_CREATE_REQUEST,
  STAFF_COMPETENCY_CREATE_SUCCESS,
  STAFF_COMPETENCY_CREATE_FAIL,
  STAFF_COMPETENCY_CREATE_RESET,
  STAFF_COMPETENCY_DETAILS_REQUEST,
  STAFF_COMPETENCY_DETAILS_SUCCESS,
  STAFF_COMPETENCY_DETAILS_FAIL,
  STAFF_COMPETENCY_DETAILS_RESET,
  STAFF_COMPETENCY_UPDATE_REQUEST,
  STAFF_COMPETENCY_UPDATE_SUCCESS,
  STAFF_COMPETENCY_UPDATE_FAIL,
  STAFF_COMPETENCY_UPDATE_RESET,
  STAFF_COMPETENCY_DELETE_REQUEST,
  STAFF_COMPETENCY_DELETE_SUCCESS,
  STAFF_COMPETENCY_DELETE_FAIL,
  STAFF_COMPETENCY_DELETE_RESET,
  STAFF_COMPETENCY_UPLOAD_BEGIN,
  STAFF_COMPETENCY_UPLOAD_REQUEST,
  STAFF_COMPETENCY_UPLOAD_SUCCESS,
  STAFF_COMPETENCY_UPLOAD_FAIL,
  STAFF_COMPETENCY_UPLOAD_END,
  STAFF_COMPETENCY_UPLOAD_RESET,
} from "../constants/staffCompetencyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffCompetenciesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_COMPETENCY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffCompetencies(
        params,
        token
      );

      dispatch({
        type: STAFF_COMPETENCY_LIST_SUCCESS,

        payload: { count: data.count, staff_competencies: data.results },
      });
    } catch (error) {
      dispatch({
        type: STAFF_COMPETENCY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListStaffCompetenciesAction = () => async (dispatch) => {
  dispatch({ type: STAFF_COMPETENCY_LIST_RESET });
};

// Create
export const createStaffCompetencyAction =
  (staff_competency) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_COMPETENCY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createStaffCompetency(
        token,
        staff_competency
      );

      dispatch({
        type: STAFF_COMPETENCY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_COMPETENCY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateStaffCompetencyAction = () => async (dispatch) => {
  dispatch({ type: STAFF_COMPETENCY_CREATE_RESET });
};

// List details
export const listStaffCompetencyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_COMPETENCY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffCompetency(id, token);

      dispatch({
        type: STAFF_COMPETENCY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_COMPETENCY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListStaffCompetencyDetailsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_COMPETENCY_DETAILS_RESET });
};

// Update
export const updateStaffCompetencyAction =
  (id, staff_competency) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_COMPETENCY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateStaffCompetency(
        id,
        token,
        staff_competency
      );

      dispatch({
        type: STAFF_COMPETENCY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_COMPETENCY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateStaffCompetencyAction = () => async (dispatch) => {
  dispatch({ type: STAFF_COMPETENCY_UPDATE_RESET });
};

// Delete
export const deleteStaffCompetencyAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_COMPETENCY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteStaffCompetency(id, token);

      dispatch({
        type: STAFF_COMPETENCY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_COMPETENCY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteStaffCompetencyAction = () => async (dispatch) => {
  dispatch({ type: STAFF_COMPETENCY_DELETE_RESET });
};

// Upload
export const uploadStaffCompetenciesAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: STAFF_COMPETENCY_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      try {
        dispatch({ type: STAFF_COMPETENCY_UPLOAD_REQUEST });

        await cortixDataService.createStaffCompetency(token, {
          comments: records[recordNum].comments,
          competency: records[recordNum].competency,
          level: records[recordNum].level,
          staff: id,
        });

        dispatch({
          type: STAFF_COMPETENCY_UPLOAD_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: STAFF_COMPETENCY_UPLOAD_FAIL,

          payload: {
            error: error,
          },
        });
      }
    }

    dispatch({ type: STAFF_COMPETENCY_UPLOAD_END });
  };

// Reset upload
export const resetUploadStaffCompetenciesAction = () => async (dispatch) => {
  dispatch({ type: STAFF_COMPETENCY_UPLOAD_RESET });
};
