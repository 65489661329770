// Library imports
import { Callout, Colors, Divider, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function FormDrawerApprovalRoleCard({ approvalRoleId, approvalRoleList }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  //JSX UI code
  return (
    <>
      {approvalRoleList.approval_roles
        .filter((approval_role) => approval_role.id === approvalRoleId)
        .map((approval_role) => {
          return (
            <Callout
              children={
                <div>
                  <p>
                    <strong>Clients</strong>
                  </p>

                  <UL>
                    <li>
                      {approval_role.can_approve_client_account_plan
                        ? `Approve plans for clients with annual revenue to ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_client_account_plan_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to approve account plans"}
                    </li>

                    {false && (
                      <li>
                        {approval_role.can_approve_client_survey
                          ? `Authorise surveys for clients with annual revenue to ${
                              activeOrganisation.currency
                            } ${approval_role.can_approve_client_survey_threshold.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 0,
                              }
                            )}`
                          : "Unable to authorise client surveys"}
                      </li>
                    )}
                  </UL>

                  <Divider
                    style={{
                      background: lightMode
                        ? Colors.LIGHT_GRAY5
                        : Colors.DARK_GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Engagements</strong>
                  </p>

                  <UL>
                    <li>
                      {approval_role.can_approve_engagement_baseline
                        ? `Approve baselines for engagements to a value of ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_engagement_baseline_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to approve engagement baselines"}
                    </li>

                    {false && (
                      <li>
                        {approval_role.can_approve_engagement_deliverable
                          ? `Authorise deliverables to a value of ${
                              activeOrganisation.currency
                            } ${approval_role.can_approve_engagement_deliverable_threshold.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 0,
                              }
                            )}`
                          : "Unable to approve engagement deliverables"}
                      </li>
                    )}

                    <li>
                      {approval_role.can_approve_engagement_close
                        ? `Authorise close of engagements to a value of ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_engagement_close_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to approve engagement close"}
                    </li>
                  </UL>

                  <Divider
                    style={{
                      background: lightMode
                        ? Colors.LIGHT_GRAY5
                        : Colors.DARK_GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Invoices</strong>
                  </p>

                  <UL>
                    <li>
                      {approval_role.can_approve_invoice
                        ? `Approve invoices to a value of ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_invoice_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to approve invoices"}
                    </li>
                  </UL>

                  <Divider
                    style={{
                      background: lightMode
                        ? Colors.LIGHT_GRAY5
                        : Colors.DARK_GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Proposals</strong>
                  </p>

                  <UL>
                    <li>
                      {approval_role.can_approve_proposal_qualification
                        ? `Qualify proposals to a value of ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_proposal_qualification_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to qualify proposals"}
                    </li>

                    {false && (
                      <li>
                        {approval_role.can_approve_proposal_strategy
                          ? `Approve strategy for proposals to a value of ${
                              activeOrganisation.currency
                            } ${approval_role.can_approve_proposal_strategy_threshold.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 0,
                              }
                            )}`
                          : "Unable to approve proposal strategy"}
                      </li>
                    )}

                    {false && (
                      <li>
                        {approval_role.can_approve_proposal_solution
                          ? `Approve solution for proposals to a value of ${
                              activeOrganisation.currency
                            } ${approval_role.can_approve_proposal_solution_threshold.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 0,
                              }
                            )}`
                          : "Unable to approve proposal solution"}
                      </li>
                    )}

                    {false && (
                      <li>
                        {approval_role.can_approve_proposal_commercials
                          ? `Approve commercials for proposals to a value of ${
                              activeOrganisation.currency
                            } ${approval_role.can_approve_proposal_commercials_threshold.toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 0,
                              }
                            )}`
                          : "Unable to approve proposal commercials"}
                      </li>
                    )}

                    <li>
                      {approval_role.can_approve_proposal_submission
                        ? `Authorise submission of proposals to a value of ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_proposal_submission_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to authorise proposal submission"}
                    </li>
                  </UL>

                  <Divider
                    style={{
                      background: lightMode
                        ? Colors.LIGHT_GRAY5
                        : Colors.DARK_GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Staff</strong>
                  </p>

                  <UL>
                    <li>
                      {approval_role.can_approve_staff_recruitment
                        ? `Approve hiring of staff with salary to ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_staff_recruitment_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to approve the recruitment of staff"}
                    </li>

                    <li>
                      {approval_role.can_approve_staff_expense
                        ? `Approve expenses to a value of ${
                            activeOrganisation.currency
                          } ${approval_role.can_approve_staff_expense_threshold.toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}`
                        : "Unable to approve expenses"}
                    </li>

                    <li>
                      {approval_role.can_approve_staff_timesheet
                        ? `Approve timesheets to a time of ${
                            convertMinutesToHoursAndMinutes(
                              approval_role.can_approve_staff_timesheet_threshold
                            ).split(":")[0]
                          }h ${
                            convertMinutesToHoursAndMinutes(
                              approval_role.can_approve_staff_timesheet_threshold
                            ).split(":")[1]
                          }m`
                        : "Unable to approve timesheets"}
                    </li>

                    <li>
                      {approval_role.can_approve_staff_leave
                        ? `Approve leave to a time of ${
                            convertMinutesToHoursAndMinutes(
                              approval_role.can_approve_staff_leave_threshold
                            ).split(":")[0]
                          }h ${
                            convertMinutesToHoursAndMinutes(
                              approval_role.can_approve_staff_leave_threshold
                            ).split(":")[1]
                          }m`
                        : "Unable to approve leave"}
                    </li>
                  </UL>
                </div>
              }
              icon="hat"
              key={approval_role.id}
              style={{ margin: "20px 0 20px 0" }}
              title={
                <>
                  <div>{approval_role.name}</div>

                  <Divider style={{ margin: "10px 0 10px 0" }} />
                </>
              }
            />
          );
        })}
    </>
  );
}

export default FormDrawerApprovalRoleCard;
