// Constant imports
import {
  MANAGER_LIST_REQUEST,
  MANAGER_LIST_SUCCESS,
  MANAGER_LIST_FAIL,
  MANAGER_LIST_RESET,
  MANAGER_DETAILS_REQUEST,
  MANAGER_DETAILS_SUCCESS,
  MANAGER_DETAILS_FAIL,
  MANAGER_DETAILS_RESET,
  MANAGER_UPDATE_REQUEST,
  MANAGER_UPDATE_SUCCESS,
  MANAGER_UPDATE_FAIL,
  MANAGER_UPDATE_RESET,
  MANAGER_DOWNLOAD_REQUEST,
  MANAGER_DOWNLOAD_SUCCESS,
  MANAGER_DOWNLOAD_FAIL,
  MANAGER_DOWNLOAD_RESET,
} from "../constants/managerConstants";

// List all
export const managerListReducer = (
  state = {
    loading: false,
    count: 0,
    managers: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MANAGER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        /*count: 0,
        managers: [],
        success: false,
        error: "",*/
      };

    case MANAGER_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        managers: action.payload.managers,
        success: true,
        error: "",
      };

    case MANAGER_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        managers: [],
        success: false,
        error: action.payload,
      };

    case MANAGER_LIST_RESET:
      return {
        loading: false,
        count: 0,
        managers: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const managerDetailsReducer = (
  state = { loading: false, manager: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MANAGER_DETAILS_REQUEST:
      return {
        loading: true,
        manager: state.manager,
        success: false,
        error: "",
      };

    case MANAGER_DETAILS_SUCCESS:
      return {
        loading: false,
        manager: action.payload,
        success: true,
        error: "",
      };

    case MANAGER_DETAILS_FAIL:
      return {
        loading: false,
        manager: state.manager,
        success: false,
        error: action.payload,
      };

    case MANAGER_DETAILS_RESET:
      return { loading: false, manager: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const managerUpdateReducer = (
  state = { loading: false, manager: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MANAGER_UPDATE_REQUEST:
      return {
        loading: true,
        manager: state.manager,
        success: false,
        error: "",
      };

    case MANAGER_UPDATE_SUCCESS:
      return {
        loading: false,
        manager: action.payload,
        success: true,
        error: "",
      };

    case MANAGER_UPDATE_FAIL:
      return {
        loading: false,
        manager: state.manager,
        success: false,
        error: action.payload,
      };

    case MANAGER_UPDATE_RESET:
      return { loading: false, manager: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const managerDownloadReducer = (
  state = {
    loading: false,
    managers: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MANAGER_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MANAGER_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        managers: action.payload.managers,
        success: true,
        error: "",
      };

    case MANAGER_DOWNLOAD_FAIL:
      return {
        loading: false,
        managers: [],
        success: false,
        error: action.payload,
      };

    case MANAGER_DOWNLOAD_RESET:
      return {
        loading: false,
        managers: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
