// Library imports
import { Tag } from "@blueprintjs/core";
import React from "react";

// Component imports
import DateValue from "../components/DateValue";

// Main function
function TableDate({ date, fillTag, intent, small }) {
  // JSX UI code
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Tag
        intent={intent}
        large={!small}
        minimal={!fillTag}
        style={{ textAlign: "center", minWidth: "100px" }}
      >
        <DateValue date={date} />
      </Tag>
    </div>
  );
}

export default TableDate;
