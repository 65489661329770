// Library imports
import { Card, Classes, Colors, H1, H3, H5 } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import LinkTag from "../components/LinkTag";
import TabSectionEmailLink from "../components/TabSectionEmailLink";

// Redux action imports
// User
import {
  confirmEmailResendAction,
  resetConfirmEmailResendAction,
} from "../actions/userActions";

// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function ConfirmEmailCTACard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userEmailConfirmResend = useSelector(
    (state) => state.userEmailConfirmResend
  );

  // User profile
  const userProfileDetails = useSelector((state) => state.userProfileDetails);

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // Resend email confirmation
  const resendEmailConfirmation = () => {
    dispatch(
      confirmEmailResendAction({ email: userProfileDetails.user_profile.email })
    );
  };

  useEffect(() => {
    if (userEmailConfirmResend.success) {
      AppToaster.show({
        icon: "envelope",
        intent: "success",
        message: "Email sent.",
      });

      dispatch(resetConfirmEmailResendAction());
    } else if (userEmailConfirmResend.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not send the email. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [userEmailConfirmResend.loading]);

  // JSX UI code
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: "20px 20px 0 20px",
      }}
    >
      <Card
        style={{
          borderRadius: "5px",
          display: "flex",
          maxWidth: "1280px",
        }}
      >
        <div
          style={{
            marginRight: "10px",
            maxWidth: "480px",
            textAlign: "center",
          }}
        >
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              border: `1px solid ${
                lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
              }`,
              borderRadius: "50%",
              objectFit: "contain",
              width: "80%",
            }}
          />
        </div>

        <div style={{ marginLeft: "10px", width: "800px" }}>
          <H1
            style={{
              color: lightMode ? "#1891AC" : "#D2ECF9",
            }}
          >
            Welcome to advaise.app!
          </H1>

          <div
            className={Classes.BLOCKQUOTE}
            style={{ margin: "20px 0 30px 0" }}
          >
            advaise.app is intelligent software for agencies, consultancies and
            other professional services businesses. Save time by managing
            people, proposals and projects in one AI-powered system designed for
            small-medium professional services firms.
          </div>

          <H3
            style={{
              color: lightMode ? "#1891AC" : "#D2ECF9",
            }}
          >
            Taking your first steps on advaise.app
          </H3>

          <H5 style={{ margin: "20px 0 10px 0" }}>
            Confirm your email address
          </H5>

          <div style={{ margin: "10px 0 0 0" }}>
            We will use your email to send updates about your account. You
            should have received a message with instructions to confirm your
            email address. If you cannot find it, we can{" "}
            <LinkTag onClick={resendEmailConfirmation} label="resend" /> the
            confirmation email.
          </div>

          <H5 style={{ margin: "20px 0 0 0" }}>Complete your user profile</H5>

          <div style={{ margin: "10px 0 20px 0" }}>
            When you are ready, complete your{" "}
            <LinkTag
              label="user profile"
              onClick={() => {
                navigate("/userProfile");
              }}
            />
            . This includes general information that advaise.app can use across
            modules (for example, contact details). You can also manage
            communication preferences in your profile.
          </div>

          <H5 style={{ margin: "20px 0 0 0" }}>Check out DemoCo</H5>

          <div style={{ margin: "10px 0 20px 0" }}>
            Once you have confirmed your email, you are free to explore
            advaise.app. You may wish to check out DemoCo first, a fictional
            organization set up to showcase some of advaise.app's capabilities.
            Confirm your email to access DemoCo.
          </div>

          <H5 style={{ margin: "20px 0 0 0" }}>Let us know if we can help</H5>

          <div style={{ margin: "10px 0 20px 0" }}>
            If you are unable to move forward for any reason, we are happy to
            help. You can contact us at{" "}
            <TabSectionEmailLink email="support@advaise.app" />.
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ConfirmEmailCTACard;
