// Library imports
import { Button, Callout, Card, Classes, H1, H6 } from "@blueprintjs/core";
import React, { useCallback, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import LinkTag from "../components/LinkTag";

// Redux action imports
// User
import {
  changePasswordAction,
  resetChangePasswordAction,
} from "../actions/userActions";

// Main function
function PasswordChangeScreen() {
  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userPasswordChange = useSelector((state) => state.userPasswordChange);

  // Form variables
  // Password change credentials
  const [passwordChangeCredentials, setPasswordChangeCredentials] = useState({
    newPassword1: "",
    newPassword2: "",
    oldPassword: "",
  });

  // Change password
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const onVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const changePassword = () => {
    // Dispatch action
    dispatch(
      changePasswordAction({
        new_password1: passwordChangeCredentials.newPassword1,
        new_password2: passwordChangeCredentials.newPassword2,
        old_password: passwordChangeCredentials.oldPassword,
        recaptcha_token: reCaptchaToken,
      })
    );

    // Refresh reCAPTCHA
    setRefreshReCaptcha((r) => !r);
  };

  // JSX UI code
  return (
    <div
      className={Classes.DARK}
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(165, 217, 243, 0.8), rgba(165, 217, 243, 1))",
        display: "flex",
        justifyContent: "center",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      <Card
        elevation={4}
        style={{
          border: "1px solid #1891AC",
          borderRadius: "5px",
          margin: "20px 5px 20px 5px",
          maxWidth: "450px",
          width: "100%",
        }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              objectFit: "contain",
              width: "100px",
            }}
          />
        </div>

        <div className="row" style={{ textAlign: "center" }}>
          {/* Heading */}
          <H1>Change your password</H1>
        </div>

        <div className="row">
          {/* Form */}
          <div className="col-s-12">
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Error message */}
              {userPasswordChange.error && (
                <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                  We couldn't change your password. Check your credentials and
                  try again.
                </Callout>
              )}

              {/* New password */}
              <FormDrawerTextField2
                disabled={userPasswordChange.success}
                error={userPasswordChange.error}
                fieldName="new_password1"
                label="New password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter the new password"
                setValueObject={setPasswordChangeCredentials}
                setValueVariable="newPassword1"
                value={passwordChangeCredentials.newPassword1}
              />

              {/* Confirm new password */}
              <FormDrawerTextField2
                disabled={userPasswordChange.success}
                error={userPasswordChange.error}
                fieldName="new_password2"
                label="Confirm new password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter the new password again"
                setValueObject={setPasswordChangeCredentials}
                setValueVariable="newPassword2"
                value={passwordChangeCredentials.newPassword2}
              />

              {/* Current password */}
              <FormDrawerTextField2
                disabled={userPasswordChange.success}
                error={userPasswordChange.error}
                fieldName="old_password"
                label="Current password"
                labelInfo="(required)"
                large
                password
                placeholder="Enter your current password"
                setValueObject={setPasswordChangeCredentials}
                setValueVariable="oldPassword"
                value={passwordChangeCredentials.oldPassword}
              />

              {/* reCAPTCHA */}
              <GoogleReCaptcha
                action="changepassword"
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />

              {/* Buttons */}
              {userPasswordChange.success ? (
                <Button
                  fill
                  icon="log-in"
                  intent="success"
                  large
                  onClick={() => {
                    dispatch(resetChangePasswordAction());

                    navigate("/");
                  }}
                  style={{ margin: "20px 0 0 0" }}
                  text="Go back"
                />
              ) : (
                <Button
                  disabled={!reCaptchaToken}
                  fill
                  icon="key"
                  intent="primary"
                  large
                  loading={userPasswordChange.loading}
                  onClick={changePassword}
                  style={{ margin: "20px 0 0 0" }}
                  text="Change"
                  type="submit"
                />
              )}

              {/* Footer */}
              <div
                style={{
                  margin: "20px 0 0 0",
                }}
              >
                {userPasswordChange.success ? (
                  <H6 style={{ color: "#D2ECF9", margin: "20px 0 0 0" }}>
                    Your password has been changed.
                  </H6>
                ) : (
                  <div>
                    <div style={{ marginBottom: "10px" }}>
                      Don't need to change your password?{" "}
                      <LinkTag
                        intent="primary"
                        label="Go back"
                        onClick={() => {
                          navigate("/");
                        }}
                      />
                    </div>

                    <div>
                      Forgotten your login?{" "}
                      <LinkTag
                        label="Reset your password"
                        onClick={() => {
                          navigate("/resetPassword");
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default PasswordChangeScreen;
