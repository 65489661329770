// Library imports
import { Card, Classes, Tag } from "@blueprintjs/core";
import React from "react";

// Component imports
import NameValue from "../components/NameValue";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";

// Main function
function ProposalQualificationFacts({ facts }) {
  // JSX UI code
  return (
    <Card>
      <TabSectionField label="Proposal id" value={facts.proposal_id} vertical />

      <TabSectionField
        label="Proposal name"
        value={facts.proposal_name}
        vertical
      />

      <TabSectionField
        label="Client"
        value={
          <NameValue image={facts.client_logo} logo text={facts.client_name} />
        }
        vertical
      />

      <TabSectionField
        label="Contact"
        value={
          <NameValue
            image={facts.contact_profile_photo}
            text={facts.contact_name}
          />
        }
        vertical
      />

      <TabSectionField
        label="Qualification questions"
        value={
          <>
            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_market_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_market_score === 2
                      ? "Disagree"
                      : facts.qualification_market_score === 3
                      ? "Uncertain"
                      : facts.qualification_market_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_market_score === 1
                      ? "danger"
                      : facts.qualification_market_score === 2
                      ? "danger"
                      : facts.qualification_market_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_market_comments ? (
                      facts.qualification_market_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Is the client in our target market?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_services_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_services_score === 2
                      ? "Disagree"
                      : facts.qualification_services_score === 3
                      ? "Uncertain"
                      : facts.qualification_services_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_services_score === 1
                      ? "danger"
                      : facts.qualification_services_score === 2
                      ? "danger"
                      : facts.qualification_services_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_services_comments ? (
                      facts.qualification_services_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Do we provide the services that the client needs?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_reputation_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_reputation_score === 2
                      ? "Disagree"
                      : facts.qualification_reputation_score === 3
                      ? "Uncertain"
                      : facts.qualification_reputation_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_reputation_score === 1
                      ? "danger"
                      : facts.qualification_reputation_score === 2
                      ? "danger"
                      : facts.qualification_reputation_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_reputation_comments ? (
                      facts.qualification_reputation_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Are we comfortable being associated with the client and this project?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_budget_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_budget_score === 2
                      ? "Disagree"
                      : facts.qualification_budget_score === 3
                      ? "Uncertain"
                      : facts.qualification_budget_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_budget_score === 1
                      ? "danger"
                      : facts.qualification_budget_score === 2
                      ? "danger"
                      : facts.qualification_budget_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_budget_comments ? (
                      facts.qualification_budget_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Has the client allocated a budget to this project that covers our
        expected fee?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_need_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_need_score === 2
                      ? "Disagree"
                      : facts.qualification_need_score === 3
                      ? "Uncertain"
                      : facts.qualification_need_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_need_score === 1
                      ? "danger"
                      : facts.qualification_need_score === 2
                      ? "danger"
                      : facts.qualification_need_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_need_comments ? (
                      facts.qualification_need_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Is this project aligned with the client's long-term interests?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_timing_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_timing_score === 2
                      ? "Disagree"
                      : facts.qualification_timing_score === 3
                      ? "Uncertain"
                      : facts.qualification_timing_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_timing_score === 1
                      ? "danger"
                      : facts.qualification_timing_score === 2
                      ? "danger"
                      : facts.qualification_timing_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_timing_comments ? (
                      facts.qualification_timing_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Does the client have an urgent need for the project?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_relationship_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_relationship_score === 2
                      ? "Disagree"
                      : facts.qualification_relationship_score === 3
                      ? "Uncertain"
                      : facts.qualification_relationship_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_relationship_score === 1
                      ? "danger"
                      : facts.qualification_relationship_score === 2
                      ? "danger"
                      : facts.qualification_relationship_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_relationship_comments ? (
                      facts.qualification_relationship_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="In general, do we have a positive relationship with the client and
        third-parties involved in the project?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_buyer_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_buyer_score === 2
                      ? "Disagree"
                      : facts.qualification_buyer_score === 3
                      ? "Uncertain"
                      : facts.qualification_buyer_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_buyer_score === 1
                      ? "danger"
                      : facts.qualification_buyer_score === 2
                      ? "danger"
                      : facts.qualification_buyer_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_buyer_comments ? (
                      facts.qualification_buyer_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Do we have a positive relationship with the person who makes buying
                decisions on this project?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_coach_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_coach_score === 2
                      ? "Disagree"
                      : facts.qualification_coach_score === 3
                      ? "Uncertain"
                      : facts.qualification_coach_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_coach_score === 1
                      ? "danger"
                      : facts.qualification_coach_score === 2
                      ? "danger"
                      : facts.qualification_coach_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_coach_comments ? (
                      facts.qualification_coach_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Do we have a client sponsor for this proposal?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_team_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_team_score === 2
                      ? "Disagree"
                      : facts.qualification_team_score === 3
                      ? "Uncertain"
                      : facts.qualification_team_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_team_score === 1
                      ? "danger"
                      : facts.qualification_team_score === 2
                      ? "danger"
                      : facts.qualification_team_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_team_comments ? (
                      facts.qualification_team_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Can we assign a suitably experienced team to this project?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_solution_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_solution_score === 2
                      ? "Disagree"
                      : facts.qualification_solution_score === 3
                      ? "Uncertain"
                      : facts.qualification_solution_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_solution_score === 1
                      ? "danger"
                      : facts.qualification_solution_score === 2
                      ? "danger"
                      : facts.qualification_solution_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_solution_comments ? (
                      facts.qualification_solution_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Do we have a differentiated and proven solution to deliver this type of
                project?"
                />
              }
              vertical
            />

            <TabSectionField
              bottom
              value={
                <TabSectionFieldCard
                  tag={
                    facts.qualification_dependencies_score === 1
                      ? "Strongly disagree"
                      : facts.qualification_dependencies_score === 2
                      ? "Disagree"
                      : facts.qualification_dependencies_score === 3
                      ? "Uncertain"
                      : facts.qualification_dependencies_score === 4
                      ? "Agree"
                      : "Strongly agree"
                  }
                  tagIntent={
                    facts.qualification_dependencies_score === 1
                      ? "danger"
                      : facts.qualification_dependencies_score === 2
                      ? "danger"
                      : facts.qualification_dependencies_score === 3
                      ? "warning"
                      : "success"
                  }
                  text={
                    facts.qualification_dependencies_comments ? (
                      facts.qualification_dependencies_comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )
                  }
                  title="Can the client give us the support we need to deliver the project?"
                />
              }
              vertical
            />
          </>
        }
        vertical
      />

      <TabSectionField
        bottom
        label="Probability of win"
        value={
          <>
            <Tag
              large
              intent={
                facts.probability_win >= 0.8
                  ? "success"
                  : facts.probability_win >= 0.6
                  ? "warning"
                  : "danger"
              }
              style={{ marginBottom: "10px" }}
            >
              {facts.probability_win * 100}%
            </Tag>

            <p className={Classes.TEXT_MUTED}>Estimated by advaise.app</p>
          </>
        }
        vertical
      />
    </Card>
  );
}

export default ProposalQualificationFacts;
