// Library imports
import {
  Button,
  Colors,
  ControlGroup,
  FormGroup,
  MenuItem,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";

// Component imports
import InfoPopup from "./InfoPopup";

// Main function
function FormDrawerSelectField2({
  allowCreate,
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  icon,
  info,
  infoWidth,
  items,
  itemsNameLabel,
  label,
  labelInfo,
  loading,
  noOptionOnClick,
  placeholder,
  query,
  setQueryObject,
  setQueryVariable,
  setValueObject,
  setValueIdVariable,
  setValueNameVariable,
  showInfo,
  showLabel,
  value,
}) {
  // Add no option to items
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (noOptionOnClick) {
      const noOption = itemsNameLabel
        ? { id: "", [itemsNameLabel]: "" }
        : {
            id: "",
            name: "",
          };

      setMenuItems([noOption, ...items]);
    } else {
      setMenuItems([...items]);
    }
    // eslint-disable-next-line
  }, [items]);

  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage
                ? errorMessage
                : String(error.response.data[fieldName]) ===
                  "This field may not be null."
                ? "A valid selection is required."
                : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <ControlGroup>
        <Select
          createNewItemFromQuery={(query) => {
            return itemsNameLabel
              ? { [itemsNameLabel]: query }
              : {
                  name: query,
                };
          }}
          createNewItemRenderer={(query, active, handleClick) => {
            return (
              <>
                {allowCreate ? (
                  <MenuItem
                    icon="add"
                    onClick={handleClick}
                    selected={active}
                    shouldDismissPopover={false}
                    text={`Create "${query}"`}
                  />
                ) : undefined}
              </>
            );
          }}
          disabled={disabled}
          fill
          id={fieldName}
          itemRenderer={(item, { handleClick, handleFocus, modifiers }) => {
            return (
              <MenuItem
                key={item.id ? item.id : "#"}
                label={item.id && showLabel ? `[${item.id}]` : undefined}
                onClick={handleClick}
                onFocus={handleFocus}
                selected={modifiers.active}
                text={
                  itemsNameLabel
                    ? item[itemsNameLabel]
                      ? item[itemsNameLabel]
                      : "-- None --"
                    : item.name
                    ? item.name
                    : "-- None --"
                }
              />
            );
          }}
          items={menuItems}
          onItemSelect={(item) => {
            setValueObject((prevState) => {
              return {
                ...prevState,
                [setValueIdVariable]: item.id,
                [setValueNameVariable]: itemsNameLabel
                  ? item[itemsNameLabel]
                  : item.name,
              };
            });
          }}
          onQueryChange={(query) => {
            setQueryObject((prevState) => {
              return {
                ...prevState,
                [setQueryVariable]: query,
              };
            });
          }}
          query={query}
        >
          <Button
            alignText="left"
            disabled={disabled}
            fill
            icon={icon}
            intent={
              error
                ? error.response.data[fieldName]
                  ? "danger"
                  : undefined
                : undefined
            }
            loading={loading}
            outlined={
              error ? (error.response.data[fieldName] ? false : true) : true
            }
            text={value ? value : placeholder}
            rightIcon="double-caret-vertical"
          />
        </Select>

        {info && showInfo && (
          <InfoPopup content={<>{info}</>} width={infoWidth} />
        )}
      </ControlGroup>
    </FormGroup>
  );
}

export default FormDrawerSelectField2;
