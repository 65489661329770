// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function MilestoneInfo({ additionalInfo, milestone }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <H5 style={{ margin: "0 10px 0 0" }}>{milestone.name}</H5>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Description</strong>
      </p>

      <p>
        {milestone.description ? (
          milestone.description
        ) : (
          <span className={Classes.TEXT_MUTED}>No description provided.</span>
        )}
      </p>

      {additionalInfo}
    </div>
  );
}

export default MilestoneInfo;
