// Library imports
import { Tag, Text } from "@blueprintjs/core";
import {
  Classes,
  Popover2,
  Popover2InteractionKind,
} from "@blueprintjs/popover2";
import React from "react";

// Utility imports
import setTextColour from "../utilities/setTextColour";

// Main function
function TagPopup({
  backgroundColor,
  content,
  contentWidth,
  flexWidth,
  icon,
  intent,
  noPreWrap,
  onClick,
  text,
}) {
  // JSX UI code
  return (
    <Popover2
      content={
        <div
          style={{
            marginRight: "-10px",
            maxHeight: "75vh",
            maxWidth: contentWidth
              ? contentWidth
              : flexWidth
              ? undefined
              : "600px",
            minWidth: contentWidth
              ? contentWidth
              : flexWidth
              ? undefined
              : "600px",
            overflowY: "auto",
            padding: "10px 20px 10px 10px",
            whiteSpace: noPreWrap ? undefined : "pre-wrap",
          }}
        >
          {content}
        </div>
      }
      interactionKind={Popover2InteractionKind.HOVER}
      popoverClassName={Classes.TOOLTIP2}
      renderTarget={({ isOpen, ref, ...targetProps }) => (
        <Tag
          elementRef={ref}
          icon={icon}
          intent={intent}
          interactive
          large
          onClick={onClick}
          round
          style={{
            backgroundColor: backgroundColor,
            color: backgroundColor ? setTextColour(backgroundColor) : undefined,
            display: "flex",
            maxWidth: "300px",
          }}
          {...targetProps}
        >
          <Text ellipsize>{text}</Text>
        </Tag>
      )}
    />
  );
}

export default TagPopup;
