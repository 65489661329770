// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardHorizontalBarChart from "../components/DataCardHorizontalBarChart";

// Main function
function ContactFunctionCard({ fill, contactList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardHorizontalBarChart
      comments={
        <>
          <H5>Business functions</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Breakdown of the selected contact(s) by business function.</p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Finance",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberFinance,
        },
        {
          name: "Management",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberGeneralManagement,
        },
        {
          name: "HR",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberHumanResources,
        },
        {
          name: "IT",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberInformationTechnology,
        },
        {
          name: "Marketing/Sales",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberMarketingSales,
        },
        {
          name: "Operations",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberOperations,
        },
        {
          name: "Other",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: contactList.numberOther,
        },
      ]}
      fill={fill}
      labelMargin={50}
      noData={
        !contactList.numberFinance &&
        !contactList.numberGeneralManagement &&
        !contactList.numberHumanResources &&
        !contactList.numberInformationTechnology &&
        !contactList.numberMarketingSales &&
        !contactList.numberOperations &&
        !contactList.numberOther
      }
      title="Business functions"
      units="Number"
    />
  );
}

export default ContactFunctionCard;
