// Constant imports
import {
  OPENING_APPLICATION_CREATE_REQUEST,
  OPENING_APPLICATION_CREATE_SUCCESS,
  OPENING_APPLICATION_CREATE_FAIL,
  OPENING_APPLICATION_CREATE_RESET,
} from "../constants/openingApplicationConstants";

// Data services import
import cortixDataService from "../services/cortix";

// Create
export const createOpeningApplicationAction =
  (opening_application) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_APPLICATION_CREATE_REQUEST });

      const { data } = await cortixDataService.createOpeningApplication(
        opening_application
      );

      dispatch({
        type: OPENING_APPLICATION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_APPLICATION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateOpeningApplicationAction = () => async (dispatch) => {
  dispatch({ type: OPENING_APPLICATION_CREATE_RESET });
};
