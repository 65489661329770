// Library imports
import {
  Alignment,
  Button,
  Callout,
  Card,
  Classes,
  Collapse,
  Colors,
  H5,
  Text,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import IconSquare from "../components/IconSquare";

// Main function
function TabSection2({
  buttons,
  content,
  disabled,
  help,
  icon,
  message,
  messageIntent,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Collapse variables
  const [collapse, setCollapse] = useState(true);

  // JSX UI code
  return (
    <Card
      style={{
        borderRadius: "5px",
        margin: "10px auto 0 auto",
        //maxWidth: "1280px",
        maxWidth: "1366px",
        padding: "0",
      }}
    >
      <div
        style={{
          borderBottom: collapse
            ? undefined
            : lightMode
            ? "1px solid " + Colors.GRAY5
            : "1px solid" + Colors.GRAY1,
          width: "100%",
        }}
      >
        <Button
          alignText={Alignment.LEFT}
          children={
            <div style={{ padding: "10px 5px 10px 5px" }}>
              <H5 style={{ margin: "0" }}>{title}</H5>

              {help ? (
                <Text
                  className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}
                >
                  {help}
                </Text>
              ) : undefined}
            </div>
          }
          disabled={disabled}
          fill
          icon={
            <IconSquare
              darkBackground={Colors.DARK_GRAY4}
              darkColor={Colors.BLUE5}
              icon={icon}
              lightBackground={Colors.LIGHT_GRAY4}
              lightColor={Colors.BLUE2}
            />
          }
          large
          minimal
          onClick={() => {
            setCollapse(!collapse);
          }}
          rightIcon={collapse ? "chevron-down" : "chevron-up"}
        />
      </div>

      <Collapse isOpen={!collapse}>
        <div style={{ padding: "20px" }}>
          {/* Message */}
          {message ? (
            <Callout intent={messageIntent} style={{ margin: "0 0 20px 0" }}>
              {message}
            </Callout>
          ) : undefined}

          {/* Content */}
          {content}

          {/* Buttons */}
          {buttons ? (
            <div
              style={{
                margin: "20px 0 0 0",
                textAlign: "start",
              }}
            >
              {buttons}
            </div>
          ) : undefined}
        </div>
      </Collapse>
    </Card>
  );
}

export default TabSection2;
