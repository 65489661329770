// Library imports
import { Classes, Colors, H1, H3, H5, Icon, UL } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import BuyButton from "../components/BuyButton";

// Redux action imports
// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function SubscribeCTAOrganisationCard({ organisationId }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // JSX UI code
  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
      }}
    >
      <div
        style={{
          alignItems: "start",
          display: "flex",
          justifyContent: "start",
          marginRight: "10px",
          padding: "10px",
          width: "25%",
        }}
      >
        <Icon
          color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
          icon="feed-subscribed"
          size={300}
        />
      </div>

      <div style={{ marginLeft: "10px", width: "75%", zIndex: 1 }}>
        <div style={{ margin: "0 auto 0 auto", width: "800px" }}>
          <H1
            style={{
              color: lightMode ? "#1891AC" : "#D2ECF9",
            }}
          >
            Subscribe to advaise.app
          </H1>

          <div
            className={Classes.BLOCKQUOTE}
            style={{ margin: "20px 0 20px 0" }}
          >
            Now that you have created your organization, you need a subscription
            to use advaise.app.
          </div>

          <H3
            style={{
              color: lightMode ? "#1891AC" : "#D2ECF9",
              marginTop: "20px",
            }}
          >
            Benefits of advaise.app
          </H3>

          <H5 style={{ marginTop: "20px" }}>Win more work, with less effort</H5>

          <UL>
            <li>Client and contact management.</li>

            <li>Proposal qualification and planning.</li>

            <li>Content and document generation</li>
          </UL>

          <H5 style={{ marginTop: "20px" }}>Build a great team, faster</H5>

          <UL>
            <li>Workforce planning and analytics.</li>

            <li>Candidate sourcing and evaluation.</li>

            <li>Performance management.</li>
          </UL>

          <H5 style={{ marginTop: "20px" }}>
            Deliver successful, profitable projects
          </H5>

          <UL>
            <li>Project planning, scheduling and monitoring.</li>

            <li>Timesheet and expense management.</li>

            <li>Project financial management.</li>
          </UL>

          <div style={{ margin: "50px 0 0 0", textAlign: "end" }}>
            <BuyButton
              action="subscribe-standard"
              icon="office"
              intent="success"
              large
              text="Subscribe"
              organisationId={organisationId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeCTAOrganisationCard;
