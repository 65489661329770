// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Card,
  Colors,
  Divider,
  H5,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import InfoPopup from "../components/InfoPopup";
import InsightInfo from "../components/InsightInfo";
import ItemCard from "../components/ItemCard";
import ItemCardList from "../components/ItemCardList";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";

// Redux action imports
// Insight
import {
  createInsightAction,
  listInsightsAction,
  resetCreateInsightAction,
  resetListInsightDetailsAction,
} from "../actions/insightActions";

// Main function
function InsightListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Insight
  const insightCreate = useSelector((state) => state.insightCreate);

  const insightList = useSelector((state) => state.insightList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Insights
  const [addInsightDrawerIsOpen, setAddInsightDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Insight
  const insightQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [insightQuery, setInsightQuery] = useState({
    ...insightQueryDefault,
  });

  // Form variables
  // Insight
  const insightDefault = {
    description: "",
    name: "",
  };
  const [insight, setInsight] = useState({
    ...insightDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Insight
    setInsight({
      ...insightDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter insights
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listInsightsAction({ ...insightQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [insightQuery]);

  // Form submission
  // Add insight
  const addInsight = () => {
    // Prepare data
    const insight_data = new FormData();

    insight.description
      ? insight_data.append("description", insight.description)
      : insight_data.append("description", insightDefault.description);

    insight.name
      ? insight_data.append("name", insight.name)
      : insight_data.append("name", insightDefault.name);

    insight_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createInsightAction(insight_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Give the insight a title</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Insight name */}
                <FormDrawerTextField2
                  error={insightCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Insight name"
                  labelInfo="(required)"
                  placeholder="Enter the insight's name"
                  setValueObject={setInsight}
                  setValueVariable="name"
                  value={insight.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Issue */}
            <FormDrawerTextAreaField3
              error={insightCreate.error}
              fieldName="description"
              helperText="advaise.app can use this description to generate content from the organisation's knowledge base"
              label="Issue"
              placeholder="Describe the client opportunity or problem"
              setValueObject={setInsight}
              setValueVariable="description"
              value={insight.description}
            />
          </Card>
        }
        error={insightCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add insight"
            module="Insights"
            view="List"
          />
        }
        helpTitle="Creating an insight"
        icon="edit"
        isOpen={addInsightDrawerIsOpen}
        onSave={addInsight}
        refreshList={() => {
          dispatch(
            listInsightsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateInsightAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={insightCreate.success}
        saveSuccessMessage="Insight created."
        saving={insightCreate.loading}
        setIsOpen={setAddInsightDrawerIsOpen}
        title="Add insight"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddInsightDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Insights"
      />

      {/* Error alerts */}
      {/* Insights loading error */}
      {insightList.error && (
        <ErrorAlert message="We couldn't load the insight list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Insights"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      <TabsContainer
        children={
          <div style={{ padding: "0 10px 0 10px" }}>
            <div
              style={{
                margin: "0 auto 0 auto",
                maxWidth: "1600px",
              }}
            >
              <div style={{ margin: "20px 0 20px 10px", width: "300px" }}>
                <SearchField
                  placeholder="Search insight"
                  searching={insightList.loading}
                  setValueObject={setInsightQuery}
                  setValueVariable="searchString"
                  value={insightQuery.searchString}
                />
              </div>

              <ItemCardList
                content={insightList.insights.map((insight) => {
                  return (
                    <ItemCard
                      content={
                        <div>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tag
                                intent={
                                  insight.status === "Approved"
                                    ? "success"
                                    : "primary"
                                }
                                minimal
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                {insight.status}
                              </Tag>

                              {!insight.user_has_permission ? (
                                <Tag
                                  intent="danger"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  No access
                                </Tag>
                              ) : insight.user_access_status === "view" ? (
                                <Tag
                                  intent="primary"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  View only
                                </Tag>
                              ) : undefined}
                            </div>

                            <InfoPopup
                              content={
                                <InsightInfo hideLink insightData={insight} />
                              }
                              width="800px"
                            />
                          </div>

                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                insight.manager_profile_photo
                                  ? insight.manager_profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "40px",
                                margin: "0 10px 0 0",
                                objectFit: "contain",
                                width: "40px",
                              }}
                            />

                            <Text ellipsize>{insight.manager_name}</Text>
                          </div>
                        </div>
                      }
                      darkColor={Colors.VERMILION3}
                      height={insight.manager ? 155 : 95}
                      icon="lightbulb"
                      interactive
                      key={insight.id}
                      lightColor={Colors.VERMILION3}
                      onClick={() => {
                        if (insight.user_has_permission) {
                          dispatch(resetListInsightDetailsAction());

                          navigate(`/insights/${insight.id}`);
                        } else {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "danger",
                            message: "You do not have access to this insight.",
                          });
                        }
                      }}
                      title={insight.name}
                    />
                  );
                })}
                count={insightList.count}
                loading={insightList.loading}
                noMargin
                page={insightQuery.page}
                setPageObject={setInsightQuery}
                setPageVariable="page"
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default InsightListScreen;
