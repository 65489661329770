// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function OpeningStatusCard({ fill, openingList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Status</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Number of openings by status.</p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />
              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Filled",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: openingList.numberOfOpeningsFilled,
        },
        {
          name: "Interviewing",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: openingList.numberOfOpeningsInterviewing,
        },
        {
          name: "On-hold",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: openingList.numberOfOpeningsOnHold,
        },
      ]}
      fill={fill}
      noData={
        !openingList.numberOfOpeningsFilled &&
        !openingList.numberOfOpeningsInterviewing &&
        !openingList.numberOfOpeningsOnHold
      }
      title="Status"
      units="Number"
    />
  );
}

export default OpeningStatusCard;
