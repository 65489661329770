// Constant imports
import {
  ENGAGEMENT_RISK_LIST_REQUEST,
  ENGAGEMENT_RISK_LIST_SUCCESS,
  ENGAGEMENT_RISK_LIST_FAIL,
  ENGAGEMENT_RISK_LIST_RESET,
  ENGAGEMENT_RISK_CREATE_REQUEST,
  ENGAGEMENT_RISK_CREATE_SUCCESS,
  ENGAGEMENT_RISK_CREATE_FAIL,
  ENGAGEMENT_RISK_CREATE_RESET,
  ENGAGEMENT_RISK_DETAILS_REQUEST,
  ENGAGEMENT_RISK_DETAILS_SUCCESS,
  ENGAGEMENT_RISK_DETAILS_FAIL,
  ENGAGEMENT_RISK_DETAILS_RESET,
  ENGAGEMENT_RISK_UPDATE_REQUEST,
  ENGAGEMENT_RISK_UPDATE_SUCCESS,
  ENGAGEMENT_RISK_UPDATE_FAIL,
  ENGAGEMENT_RISK_UPDATE_RESET,
  ENGAGEMENT_RISK_DELETE_REQUEST,
  ENGAGEMENT_RISK_DELETE_SUCCESS,
  ENGAGEMENT_RISK_DELETE_FAIL,
  ENGAGEMENT_RISK_DELETE_RESET,
} from "../constants/engagementRiskConstants";

// List all
export const engagementRiskListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_risks: [],
    numberOfRisksClosed: 0,
    numberOfRisksOpen: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_RISK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_RISK_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_risks: action.payload.engagement_risks,
        numberOfRisksClosed: action.payload.numberOfRisksClosed,
        numberOfRisksOpen: action.payload.numberOfRisksOpen,
        success: true,
        error: "",
      };

    case ENGAGEMENT_RISK_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_risks: [],
        numberOfRisksClosed: 0,
        numberOfRisksOpen: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_RISK_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_risks: [],
        numberOfRisksClosed: 0,
        numberOfRisksOpen: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementRiskCreateReducer = (
  state = { loading: false, engagement_risk: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_RISK_CREATE_REQUEST:
      return {
        loading: true,
        engagement_risk: state.engagement_risk,
        success: false,
        error: "",
      };

    case ENGAGEMENT_RISK_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_risk: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_RISK_CREATE_FAIL:
      return {
        loading: false,
        engagement_risk: state.engagement_risk,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_RISK_CREATE_RESET:
      return {
        loading: false,
        engagement_risk: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementRiskDetailsReducer = (
  state = { loading: false, engagement_risk: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_RISK_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_risk: state.engagement_risk,
        success: false,
        error: "",
      };

    case ENGAGEMENT_RISK_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_risk: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_RISK_DETAILS_FAIL:
      return {
        loading: false,
        engagement_risk: state.engagement_risk,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_RISK_DETAILS_RESET:
      return {
        loading: false,
        engagement_risk: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementRiskUpdateReducer = (
  state = { loading: false, engagement_risk: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_RISK_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_risk: state.engagement_risk,
        success: false,
        error: "",
      };

    case ENGAGEMENT_RISK_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_risk: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_RISK_UPDATE_FAIL:
      return {
        loading: false,
        engagement_risk: state.engagement_risk,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_RISK_UPDATE_RESET:
      return {
        loading: false,
        engagement_risk: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementRiskDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_RISK_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_RISK_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_RISK_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_RISK_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
