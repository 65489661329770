export const COMPETENCY_LIST_REQUEST = "COMPETENCY_LIST_REQUEST";
export const COMPETENCY_LIST_SUCCESS = "COMPETENCY_LIST_SUCCESS";
export const COMPETENCY_LIST_FAIL = "COMPETENCY_LIST_FAIL";
export const COMPETENCY_LIST_RESET = "COMPETENCY_LIST_RESET";

export const COMPETENCY_CREATE_REQUEST = "COMPETENCY_CREATE_REQUEST";
export const COMPETENCY_CREATE_SUCCESS = "COMPETENCY_CREATE_SUCCESS";
export const COMPETENCY_CREATE_FAIL = "COMPETENCY_CREATE_FAIL";
export const COMPETENCY_CREATE_RESET = "COMPETENCY_CREATE_RESET";

export const COMPETENCY_DETAILS_REQUEST = "COMPETENCY_DETAILS_REQUEST";
export const COMPETENCY_DETAILS_SUCCESS = "COMPETENCY_DETAILS_SUCCESS";
export const COMPETENCY_DETAILS_FAIL = "COMPETENCY_DETAILS_FAIL";
export const COMPETENCY_DETAILS_RESET = "COMPETENCY_DETAILS_RESET";

export const COMPETENCY_UPDATE_REQUEST = "COMPETENCY_UPDATE_REQUEST";
export const COMPETENCY_UPDATE_SUCCESS = "COMPETENCY_UPDATE_SUCCESS";
export const COMPETENCY_UPDATE_FAIL = "COMPETENCY_UPDATE_FAIL";
export const COMPETENCY_UPDATE_RESET = "COMPETENCY_UPDATE_RESET";

export const COMPETENCY_DELETE_REQUEST = "COMPETENCY_DELETE_REQUEST";
export const COMPETENCY_DELETE_SUCCESS = "COMPETENCY_DELETE_SUCCESS";
export const COMPETENCY_DELETE_FAIL = "COMPETENCY_DELETE_FAIL";
export const COMPETENCY_DELETE_RESET = "COMPETENCY_DELETE_RESET";

export const COMPETENCY_DOWNLOAD_REQUEST = "COMPETENCY_DOWNLOAD_REQUEST";
export const COMPETENCY_DOWNLOAD_SUCCESS = "COMPETENCY_DOWNLOAD_SUCCESS";
export const COMPETENCY_DOWNLOAD_FAIL = "COMPETENCY_DOWNLOAD_FAIL";
export const COMPETENCY_DOWNLOAD_RESET = "COMPETENCY_DOWNLOAD_RESET";

export const COMPETENCY_UPLOAD_BEGIN = "COMPETENCY_UPLOAD_BEGIN";
export const COMPETENCY_UPLOAD_REQUEST = "COMPETENCY_UPLOAD_REQUEST";
export const COMPETENCY_UPLOAD_SUCCESS = "COMPETENCY_UPLOAD_SUCCESS";
export const COMPETENCY_UPLOAD_FAIL = "COMPETENCY_UPLOAD_FAIL";
export const COMPETENCY_UPLOAD_END = "COMPETENCY_UPLOAD_END";
export const COMPETENCY_UPLOAD_RESET = "COMPETENCY_UPLOAD_RESET";
