export const CURRENCY_LIST_REQUEST = "CURRENCY_LIST_REQUEST";
export const CURRENCY_LIST_SUCCESS = "CURRENCY_LIST_SUCCESS";
export const CURRENCY_LIST_FAIL = "CURRENCY_LIST_FAIL";
export const CURRENCY_LIST_RESET = "CURRENCY_LIST_RESET";

export const CURRENCY_CREATE_REQUEST = "CURRENCY_CREATE_REQUEST";
export const CURRENCY_CREATE_SUCCESS = "CURRENCY_CREATE_SUCCESS";
export const CURRENCY_CREATE_FAIL = "CURRENCY_CREATE_FAIL";
export const CURRENCY_CREATE_RESET = "CURRENCY_CREATE_RESET";

export const CURRENCY_DETAILS_REQUEST = "CURRENCY_DETAILS_REQUEST";
export const CURRENCY_DETAILS_SUCCESS = "CURRENCY_DETAILS_SUCCESS";
export const CURRENCY_DETAILS_FAIL = "CURRENCY_DETAILS_FAIL";
export const CURRENCY_DETAILS_RESET = "CURRENCY_DETAILS_RESET";

export const CURRENCY_DELETE_REQUEST = "CURRENCY_DELETE_REQUEST";
export const CURRENCY_DELETE_SUCCESS = "CURRENCY_DELETE_SUCCESS";
export const CURRENCY_DELETE_FAIL = "CURRENCY_DELETE_FAIL";
export const CURRENCY_DELETE_RESET = "CURRENCY_DELETE_RESET";

export const CURRENCY_GET_CURRENCY_CHOICES_REQUEST =
  "CURRENCY_GET_CURRENCY_CHOICES_REQUEST";
export const CURRENCY_GET_CURRENCY_CHOICES_SUCCESS =
  "CURRENCY_GET_CURRENCY_CHOICES_SUCCESS";
export const CURRENCY_GET_CURRENCY_CHOICES_FAIL =
  "CURRENCY_GET_CURRENCY_CHOICES_FAIL";
export const CURRENCY_GET_CURRENCY_CHOICES_RESET =
  "CURRENCY_GET_CURRENCY_CHOICES_RESET";

export const CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_REQUEST =
  "CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_REQUEST";
export const CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_SUCCESS =
  "CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_SUCCESS";
export const CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_FAIL =
  "CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_FAIL";
export const CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_RESET =
  "CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_RESET";

export const CURRENCY_GET_RATE_REQUEST = "CURRENCY_GET_RATE_REQUEST";
export const CURRENCY_GET_RATE_SUCCESS = "CURRENCY_GET_RATE_SUCCESS";
export const CURRENCY_GET_RATE_FAIL = "CURRENCY_GET_RATE_FAIL";
export const CURRENCY_GET_RATE_RESET = "CURRENCY_GET_RATE_RESET";
