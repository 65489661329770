// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import { Button, Card, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import BookingCodeInfo from "../components/BookingCodeInfo";
import ClientInfo from "../components/ClientInfo";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import InvoiceAgeCard from "../components/InvoiceAgeCard";
import InvoiceTotalValueCard from "../components/InvoiceTotalValueCard";
import InvoiceTotalValuePercentageCard from "../components/InvoiceTotalValuePercentageCard";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Booking code
import { listBookingCodesAction } from "../actions/bookingCodeActions";

// Client
import { listClientsAction } from "../actions/clientActions";

// Currency
import { getOrganisationCurrencyChoicesCurrencyAction } from "../actions/currencyActions";

// Invoice
import {
  createInvoiceAction,
  listInvoicesAction,
  resetCreateInvoiceAction,
  resetListInvoiceDetailsAction,
} from "../actions/invoiceActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Main function
function InvoiceListScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Booking code
  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Client
  const clientList = useSelector((state) => state.clientList);

  // Currency
  const currencyGetOrganisationCurrencyChoices = useSelector(
    (state) => state.currencyGetOrganisationCurrencyChoices
  );

  // Invoice
  const invoiceCreate = useSelector((state) => state.invoiceCreate);

  const invoiceList = useSelector((state) => state.invoiceList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Invoice
  const [addInvoiceDrawerIsOpen, setAddInvoiceDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Booking code
  const bookingCodeQueryDefault = {
    billable: true,
    engagement: "",
    holding: false,
    open: "",
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Invoice
  const invoiceQueryDefault = {
    bookingCode: "",
    client: "",
    dueEnd: "",
    dueStart: "",
    issuedEnd: "",
    issuedStart: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    paidEnd: "",
    paidStart: "",
    searchString: "",
    sortField: "",
    status: "",
  };
  const [invoiceQuery, setInvoiceQuery] = useState({
    ...invoiceQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Form variables
  // Invoice
  const newInvoiceDefault = {
    bookingCode: "",
    bookingCodeName: "",
    client: "",
    clientName: "",
    comments: "",
    due: "",
    name: "",
  };
  const [newInvoice, setNewInvoice] = useState({ ...newInvoiceDefault });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Invoice
    setNewInvoice({ ...newInvoiceDefault });

    // Query variables
    // Booking code
    setBookingCodeQuery({
      ...bookingCodeQueryDefault,
    });

    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
  }, [navigate, userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Load currency data
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    dispatch(
      getOrganisationCurrencyChoicesCurrencyAction({
        organisation: activeOrganisation.id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      currencies.length === 0 &&
      currencyGetOrganisationCurrencyChoices.currencies
    ) {
      currencyGetOrganisationCurrencyChoices.currencies.map((currency) =>
        setCurrencies((prevState) => [
          ...prevState,
          {
            id: currency[1],
            icon: (
              <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                {currency[0]}
              </Tag>
            ),
            value: currency[1],
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [currencyGetOrganisationCurrencyChoices.success]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listBookingCodesAction({ ...bookingCodeQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter invoices
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoicesAction({
            ...invoiceQuery,
            dueEnd: invoiceQuery.dueEnd
              ? getDjangoDate(invoiceQuery.dueEnd)
              : getDjangoDate(invoiceQueryDefault.dueEnd),
            dueStart: invoiceQuery.dueStart
              ? getDjangoDate(invoiceQuery.dueStart)
              : getDjangoDate(invoiceQueryDefault.dueStart),
            issuedEnd: invoiceQuery.issuedEnd
              ? getDjangoDate(invoiceQuery.issuedEnd)
              : getDjangoDate(invoiceQueryDefault.issuedEnd),
            issuedStart: invoiceQuery.issuedStart
              ? getDjangoDate(invoiceQuery.issuedStart)
              : getDjangoDate(invoiceQueryDefault.issuedStart),
            paidEnd: invoiceQuery.paidEnd
              ? getDjangoDate(invoiceQuery.paidEnd)
              : getDjangoDate(invoiceQueryDefault.paidEnd),
            paidStart: invoiceQuery.paidStart
              ? getDjangoDate(invoiceQuery.paidStart)
              : getDjangoDate(invoiceQueryDefault.paidStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Form submission
  // Add invoice
  const addInvoice = () => {
    // Prepare data
    const invoice = new FormData();

    newInvoice.bookingCode
      ? invoice.append("booking_code", newInvoice.bookingCode)
      : invoice.append("booking_code", newInvoiceDefault.bookingCode);

    newInvoice.client
      ? invoice.append("client", newInvoice.client)
      : invoice.append("client", newInvoiceDefault.client);

    newInvoice.comments
      ? invoice.append("comments", newInvoice.comments)
      : invoice.append("comments", newInvoiceDefault.comments);

    newInvoice.due
      ? invoice.append("due", getDjangoDate(newInvoice.due))
      : invoice.append("due", getDjangoDate(newInvoiceDefault.due));

    newInvoice.name
      ? invoice.append("name", newInvoice.name)
      : invoice.append("name", newInvoiceDefault.name);

    invoice.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createInvoiceAction(invoice));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the invoice details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Invoice name */}
                <FormDrawerTextField2
                  error={invoiceCreate.error}
                  fieldName="name"
                  helperText="You can search invoices by name"
                  icon="dollar"
                  label="Invoice name"
                  labelInfo="(required)"
                  placeholder="Give the invoice a title"
                  setValueObject={setNewInvoice}
                  setValueVariable="name"
                  value={newInvoice.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Booking code */}
                <FormDrawerSelectField2
                  error={invoiceCreate.error}
                  fieldName="booking_code"
                  helperText="You can select an existing booking code"
                  icon="barcode"
                  info={
                    <BookingCodeInfo
                      bookingCodeId={newInvoice.bookingCode}
                      query={bookingCodeQueryDefault}
                    />
                  }
                  items={bookingCodeList.booking_codes}
                  label="Booking code"
                  labelInfo="(required)"
                  loading={bookingCodeList.loading}
                  placeholder="Select a booking code"
                  query={bookingCodeQuery.searchString}
                  setQueryObject={setBookingCodeQuery}
                  setQueryVariable="searchString"
                  setValueObject={setNewInvoice}
                  setValueIdVariable="bookingCode"
                  setValueNameVariable="bookingCodeName"
                  showInfo={newInvoice.bookingCode}
                  value={newInvoice.bookingCodeName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Client */}
                <FormDrawerSelectField2
                  disabled={
                    bookingCodeList.booking_codes.filter(
                      (booking_code) =>
                        booking_code.id === newInvoice.bookingCode
                    )[0]
                      ? bookingCodeList.booking_codes.filter(
                          (booking_code) =>
                            booking_code.id === newInvoice.bookingCode
                        )[0].engagement
                      : undefined
                  }
                  error={invoiceCreate.error}
                  fieldName="client"
                  helperText="You can select an existing client"
                  icon="office"
                  info={
                    <ClientInfo
                      clientId={newInvoice.client}
                      query={clientQueryDefault}
                    />
                  }
                  items={clientList.clients}
                  label="Client"
                  labelInfo="(required)"
                  loading={clientList.loading}
                  placeholder="Select a client"
                  query={clientQuery.searchString}
                  setQueryObject={setClientQuery}
                  setQueryVariable="searchString"
                  setValueObject={setNewInvoice}
                  setValueIdVariable="client"
                  setValueNameVariable="clientName"
                  showInfo={newInvoice.client}
                  value={newInvoice.clientName}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  width: "40%",
                }}
              >
                {/* Due date */}
                <FormDrawerDateField2
                  error={invoiceCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the date"
                  setValueObject={setNewInvoice}
                  setValueVariable="due"
                  value={newInvoice.due}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={invoiceCreate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Enter your comments on the invoice"
              setValueObject={setNewInvoice}
              setValueVariable="comments"
              value={newInvoice.comments}
            />
          </Card>
        }
        error={invoiceCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add invoice"
            module="Invoices"
            view="List"
          />
        }
        helpTitle="Creating an invoice"
        icon="add"
        isOpen={addInvoiceDrawerIsOpen}
        onSave={addInvoice}
        refreshList={() => {
          dispatch(
            listInvoicesAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateInvoiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceCreate.success}
        saveSuccessMessage="Invoice created."
        saving={invoiceCreate.loading}
        setIsOpen={setAddInvoiceDrawerIsOpen}
        title="Add invoice"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddInvoiceDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Invoices"
      />

      {/* Error alerts */}
      {/* Booking codes loading error */}
      {bookingCodeList.error && (
        <ErrorAlert message="We couldn't load the booking code list. Try refreshing the page." />
      )}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Invoices loading error */}
      {invoiceList.error && (
        <ErrorAlert message="We couldn't load the invoice list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Invoices"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Data cards */}
      <div style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}>
        <DataCardContainer
          children={
            <>
              <InvoiceAgeCard invoiceList={invoiceList} />

              <InvoiceTotalValueCard
                fill
                invoiceList={invoiceList}
                showInsights
              />

              <InvoiceTotalValuePercentageCard
                invoiceList={invoiceList}
                invoiceQuery={invoiceQuery}
              />
            </>
          }
          fill
        />
      </div>

      {/* Data table */}
      <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
        <TableContainer2
          body={invoiceList.invoices.map((invoice) => {
            return (
              <tr
                key={invoice.id}
                onClick={() => {
                  if (invoice.user_has_permission) {
                    dispatch(resetListInvoiceDetailsAction());

                    navigate(`/invoices/${invoice.id}`);
                  } else {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "danger",
                      message: "You do not have access to this invoice.",
                    });
                  }
                }}
              >
                {/* Invoice */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    tagIntent={
                      !invoice.user_has_permission
                        ? "danger"
                        : invoice.user_access_status === "view"
                        ? "primary"
                        : undefined
                    }
                    tagText={
                      !invoice.user_has_permission
                        ? "No access"
                        : invoice.user_access_status === "view"
                        ? "View only"
                        : undefined
                    }
                    text={invoice.name}
                  />
                </td>

                {/* Client */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    image={invoice.client_logo}
                    logo
                    showImage
                    text={invoice.client_name}
                    url={`/clients/${invoice.client}`}
                  />
                </td>

                {/* Value */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableNumber number={invoice.value} />
                </td>

                {/* Manager */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    email={invoice.manager_name}
                    image={invoice.manager_profile_photo}
                    showImage
                  />
                </td>

                {/* Issued */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableDate date={invoice.issued} />
                </td>

                {/* Due */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableDate date={invoice.due} />
                </td>

                {/* Paid */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableDate date={invoice.paid} />
                </td>

                {/* Status */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableTag
                    intent={
                      invoice.status === "Disputed"
                        ? "danger"
                        : invoice.status === "Draft"
                        ? "primary"
                        : invoice.status === "Invoiced"
                        ? "warning"
                        : invoice.status === "Paid"
                        ? "success"
                        : undefined
                    }
                    value={invoice.status}
                  />
                </td>
              </tr>
            );
          })}
          clearFiltersDisabled={
            !invoiceQuery.client &&
            !invoiceQuery.dueEnd &&
            !invoiceQuery.dueStart &&
            !invoiceQuery.issuedEnd &&
            !invoiceQuery.issuedStart &&
            !invoiceQuery.manager &&
            !invoiceQuery.paidEnd &&
            !invoiceQuery.paidStart &&
            !invoiceQuery.searchString &&
            !invoiceQuery.status
          }
          count={invoiceList.count}
          footnote={
            <div>
              Figures in {activeOrganisation.currency} excluding sales tax
            </div>
          }
          head={
            <tr>
              {/* Invoice */}
              <TableColumnHeadSearch
                queryObject={invoiceQuery}
                queryVariable="searchString"
                setQueryObject={setInvoiceQuery}
                setQueryVariable="searchString"
                title="Invoice"
                width="200px"
              />

              {/* Client */}
              <TableColumnHeadSelect2
                filterObject={invoiceQuery}
                filterVariable="client"
                items={clientList.clients}
                setFilterObject={setInvoiceQuery}
                setQueryObject={setClientQuery}
                setQueryVariable="searchString"
                title="Client"
                width="200px"
              />

              {/* Value */}
              <TableColumnHeadSelectSimple
                filterObject={invoiceQuery}
                filterVariable="sortField"
                noActive
                options={[
                  {
                    display: "Sort Ascending",
                    icon: "sort-asc",
                    value: "value",
                  },
                  {
                    display: "Sort Descending",
                    icon: "sort-desc",
                    value: "-value",
                  },
                ]}
                setFilterObject={setInvoiceQuery}
                title="Value"
                width="125px"
              />

              {/* Manager */}
              <TableColumnHeadSelect2
                filterObject={invoiceQuery}
                filterVariable="manager"
                items={managerList.managers}
                setFilterObject={setInvoiceQuery}
                setQueryObject={setManagerQuery}
                setQueryVariable="searchString"
                title="Manager"
                width="200px"
              />

              {/* Issued */}
              <TableColumnHeadDate
                endDateVariable="issuedEnd"
                noShortcuts
                queryObject={invoiceQuery}
                setQueryObject={setInvoiceQuery}
                shortRange
                startDateVariable="issuedStart"
                title="Issued"
                width="125px"
              />

              {/* Due */}
              <TableColumnHeadDate
                endDateVariable="dueEnd"
                noShortcuts
                queryObject={invoiceQuery}
                setQueryObject={setInvoiceQuery}
                shortRange
                startDateVariable="dueStart"
                title="Due"
                width="125px"
              />

              {/* Paid */}
              <TableColumnHeadDate
                endDateVariable="paidEnd"
                noShortcuts
                queryObject={invoiceQuery}
                setQueryObject={setInvoiceQuery}
                shortRange
                startDateVariable="paidStart"
                title="Paid"
                width="125px"
              />

              {/* Status */}
              <TableColumnHeadSelectSimple
                filterObject={invoiceQuery}
                filterVariable="status"
                options={[
                  { display: "All", icon: "filter-remove", value: "" },
                  {
                    display: "Draft",
                    icon: "manually-entered-data",
                    value: "Draft",
                  },
                  {
                    display: "Invoiced",
                    icon: "document-share",
                    value: "Invoiced",
                  },
                  {
                    display: "Paid",
                    icon: "dollar",
                    value: "Paid",
                  },
                  { display: "Disputed", icon: "issue", value: "Disputed" },
                  { display: "Credited", icon: "undo", value: "Credited" },
                ]}
                setFilterObject={setInvoiceQuery}
                title="Status"
                width="125px"
              />
            </tr>
          }
          interactive
          loading={invoiceList.loading}
          onClearFilters={() => {
            setInvoiceQuery({
              ...invoiceQueryDefault,
            });
          }}
          page={invoiceQuery.page}
          setPageObject={setInvoiceQuery}
          setPageVariable="page"
        />
      </div>
    </div>
  );
}

export default InvoiceListScreen;
