// Library imports
import { Card, Classes, HTMLTable, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DateValue from "../components/DateValue";
import NameValue from "../components/NameValue";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableText from "../components/TableText";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";

// Utility functions
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function InvoiceFacts({ facts }) {
  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <Card>
      <TabSectionField label="Invoice id" value={facts.invoice_id} vertical />

      <TabSectionField
        label="Invoice title"
        value={facts.invoice_name}
        vertical
      />

      <TabSectionField
        label="Client"
        value={
          <NameValue image={facts.client_logo} logo text={facts.client_name} />
        }
        vertical
      />

      <TabSectionField
        label="Due"
        value={<DateValue date={facts.due} />}
        vertical
      />

      {facts.invoice_bookings.length > 0 && (
        <TabSectionField
          label="Bookings"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_bookings.map((booking, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={booking.comments}
                            text={booking.name}
                          />

                          <span
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                          >
                            {booking.role}
                          </span>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          {
                            convertMinutesToHoursAndMinutes(
                              booking.minutes
                            ).split(":")[0]
                          }
                          h{" "}
                          {
                            convertMinutesToHoursAndMinutes(
                              booking.minutes
                            ).split(":")[1]
                          }
                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ display: "flex" }}
                          >
                            <DateValue date={booking.start} /> -{" "}
                            <DateValue date={booking.end} />
                          </div>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={booking.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={booking.sales_tax}
                            tagNumber={booking.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={booking.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_bookings_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_bookings_sales_tax} />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_bookings_net_value} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Staff</th>

                    <th>Effort</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_booking_credits.length > 0 && (
        <TabSectionField
          label="Booking credits"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_booking_credits.map((booking_credit, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={booking_credit.comments}
                            text={booking_credit.name}
                          />

                          <span
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                          >
                            {booking_credit.role}
                          </span>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          {
                            convertMinutesToHoursAndMinutes(
                              booking_credit.minutes
                            ).split(":")[0]
                          }
                          h{" "}
                          {
                            convertMinutesToHoursAndMinutes(
                              booking_credit.minutes
                            ).split(":")[1]
                          }
                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ display: "flex" }}
                          >
                            <DateValue date={booking_credit.start} /> -{" "}
                            <DateValue date={booking_credit.end} />
                          </div>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={booking_credit.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={booking_credit.sales_tax}
                            tagNumber={booking_credit.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={booking_credit.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_booking_credits_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_booking_credits_sales_tax}
                      />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_booking_credits_net_value}
                      />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Staff</th>

                    <th>Effort</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_expenses.length > 0 && (
        <TabSectionField
          label="Expenses"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_expenses.map((expense, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={expense.comments}
                            text={expense.name}
                          />

                          <span
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                          >
                            {expense.staff_name}
                          </span>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={expense.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={expense.sales_tax}
                            tagNumber={expense.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={expense.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_expenses_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_expenses_sales_tax} />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_expenses_net_value} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Expense</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_expense_credits.length > 0 && (
        <TabSectionField
          label="Expense credits"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_expense_credits.map((expense_credit, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={expense_credit.comments}
                            text={expense_credit.name}
                          />

                          <span
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                          >
                            {expense_credit.staff_name}
                          </span>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={expense_credit.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={expense_credit.sales_tax}
                            tagNumber={expense_credit.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={expense_credit.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_expense_credits_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_expense_credits_sales_tax}
                      />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_expense_credits_net_value}
                      />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Expense</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_milestones.length > 0 && (
        <TabSectionField
          label="Milestones"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_milestones.map((milestone, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={milestone.description}
                            text={milestone.name}
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableDate date={milestone.completed} small />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={milestone.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={milestone.sales_tax}
                            tagNumber={milestone.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={milestone.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_milestones_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_milestones_sales_tax} />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_milestones_net_value} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Milestone</th>

                    <th>Completed</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_milestone_credits.length > 0 && (
        <TabSectionField
          label="Milestone credits"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_milestone_credits.map(
                    (milestone_credit, i) => {
                      return (
                        <tr key={i}>
                          <td style={{ verticalAlign: "middle" }}>
                            <TableText
                              info={milestone_credit.description}
                              text={milestone_credit.name}
                            />
                          </td>

                          <td style={{ verticalAlign: "middle" }}>
                            <TableDate
                              date={milestone_credit.completed}
                              small
                            />
                          </td>

                          <td style={{ verticalAlign: "middle" }}>
                            <TableNumber number={milestone_credit.value} />
                          </td>

                          <td style={{ verticalAlign: "middle" }}>
                            <TableNumber
                              number={milestone_credit.sales_tax}
                              tagNumber={milestone_credit.sales_tax_rate}
                              tagPercentage
                            />
                          </td>

                          <td style={{ verticalAlign: "middle" }}>
                            <TableNumber number={milestone_credit.net_value} />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_milestone_credits_value}
                      />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_milestone_credits_sales_tax}
                      />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_milestone_credits_net_value}
                      />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Milestone</th>

                    <th>Completed</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_others.length > 0 && (
        <TabSectionField
          label="Additional items"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_others.map((other, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={other.description}
                            text={other.name}
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={other.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={other.sales_tax}
                            tagNumber={other.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={other.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_others_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_others_sales_tax} />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_others_net_value} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Item</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      {facts.invoice_other_credits.length > 0 && (
        <TabSectionField
          label="Additional item credits"
          value={
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.invoice_other_credits.map((other_credit, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText
                            info={other_credit.description}
                            text={other_credit.name}
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={other_credit.value} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber
                            number={other_credit.sales_tax}
                            tagNumber={other_credit.sales_tax_rate}
                            tagPercentage
                          />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableNumber number={other_credit.net_value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Amount */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_other_credits_value} />
                    </td>

                    {/* Tax */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_other_credits_sales_tax}
                      />
                    </td>

                    {/* Net */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_other_credits_net_value}
                      />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Item</th>

                    <th>Amount</th>

                    <th>Tax</th>

                    <th>Net</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          }
          vertical
        />
      )}

      <TabSectionField
        bottom
        label="Comments"
        value={<TabSectionFieldCard text={facts.comments} />}
        vertical
      />
    </Card>
  );
}

export default InvoiceFacts;
