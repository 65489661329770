// Library imports
import { Button, Classes, Colors, Divider, H5, UL } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Service
import { listServicesAction } from "../actions/serviceActions";

// Main function
function ServiceInfo({
  additionalInfo,
  hideLink,
  serviceData,
  serviceId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Service
  const serviceList = useSelector((state) => state.serviceList);

  // Refresh service data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [service, setService] = useState({});

  useEffect(() => {
    if (serviceData) {
      setService(serviceData);

      setLoading(false);
    }
  }, [serviceData]);

  useEffect(() => {
    if (!serviceData && !serviceList.loading) {
      if (serviceList.success) {
        const thisService = serviceList.services.filter(
          (service_item) => service_item.id === serviceId
        );

        if (thisService.length > 0) {
          setService(thisService[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listServicesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (serviceList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [serviceList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5 style={{ margin: "0 10px 0 0" }}>{service.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/services/${service.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {service.description ? (
              service.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          {service.activities.length > 0 && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Activities</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                <UL>
                  {service.activities.map((activity, i) => {
                    return <li key={`activity_${i}`}>{activity.name}</li>;
                  })}
                </UL>
              </div>
            </>
          )}

          {service.deliverables.length > 0 && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Deliverables</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                <UL>
                  {service.deliverables.map((deliverable, i) => {
                    return <li key={`deliverable_${i}`}>{deliverable.name}</li>;
                  })}
                </UL>
              </div>
            </>
          )}

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default ServiceInfo;
