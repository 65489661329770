// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Location
import { listLocationsAction } from "../actions/locationActions";

// Main function
function LocationInfo({ additionalInfo, locationData, locationId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Location
  const locationList = useSelector((state) => state.locationList);

  // Refresh invoice booking data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [location, setLocation] = useState({});

  useEffect(() => {
    if (locationData) {
      setLocation(locationData);

      setLoading(false);
    }
  }, [locationData]);

  useEffect(() => {
    if (!locationData && !locationList.loading) {
      if (locationList.success) {
        const thisLocation = locationList.locations.filter(
          (location_item) => location_item.id === locationId
        );

        if (thisLocation.length > 0) {
          setLocation(thisLocation[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listLocationsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (locationList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [locationList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{location.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Address</strong>
          </p>

          <p>
            {location.address ? (
              location.address
            ) : (
              <span className={Classes.TEXT_MUTED}>No address provided.</span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default LocationInfo;
