// Library imports
import { Classes, Colors, FocusStyleManager } from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import WebFont from "webfontloader";

// Component imports
import ActionSidebar from "./components/ActionSidebar";
import CaseStudyListScreen from "./screens/CaseStudyListScreen";
import CaseStudyDetailScreen from "./screens/CaseStudyDetailScreen";
import ClientListScreen from "./screens/ClientListScreen";
import ClientDetailScreen from "./screens/ClientDetailScreen";
import ContactDetailScreen from "./screens/ContactDetailScreen";
import ContactListScreen from "./screens/ContactListScreen";
import EmailConfirmScreen from "./screens/EmailConfirmScreen";
import EngagementDetailScreen from "./screens/EngagementDetailScreen";
import EngagementListScreen from "./screens/EngagementListScreen";
import HomeScreen from "./screens/HomeScreen";
import InsightDetailScreen from "./screens/InsightDetailScreen";
import InsightListScreen from "./screens/InsightListScreen";
import InterviewDetailScreen from "./screens/InterviewDetailScreen";
import InvoiceDetailScreen from "./screens/InvoiceDetailScreen";
import InvoiceListScreen from "./screens/InvoiceListScreen";
import LoginScreen from "./screens/LoginScreen";
import MarketDetailScreen from "./screens/MarketDetailScreen";
import MarketListScreen from "./screens/MarketListScreen";
import NeedDetailScreen from "./screens/NeedDetailScreen";
import OpeningApplicationScreen from "./screens/OpeningApplicationScreen";
import OpeningDetailScreen from "./screens/OpeningDetailScreen";
import OpeningListScreen from "./screens/OpeningListScreen";
import OrganisationDetailScreen from "./screens/OrganisationDetailScreen";
import OrganisationListScreen from "./screens/OrganisationListScreen";
import PasswordChangeScreen from "./screens/PasswordChangeScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import PasswordResetConfirmScreen from "./screens/PasswordResetConfirmScreen";
import ProposalDetailScreen from "./screens/ProposalDetailScreen";
import ProposalListScreen from "./screens/ProposalListScreen";
import RoleDetailScreen from "./screens/RoleDetailScreen";
import RoleListScreen from "./screens/RoleListScreen";
import SalaryBenchmarkWidget from "./components/SalaryBenchmarkWidget";
import ServiceDetailScreen from "./screens/ServiceDetailScreen";
import ServiceListScreen from "./screens/ServiceListScreen";
import Sidebar from "./components/Sidebar";
import SignupScreen from "./screens/SignupScreen";
import SmallWindowWarning from "./screens/SmallWindowWarning";
import StaffDetailScreen from "./screens/StaffDetailScreen";
import StaffListScreen from "./screens/StaffListScreen";
import UserProfileScreen from "./screens/UserProfileScreen";

// Redux action imports
// Help content
import { listHelpContentAction } from "../src/actions/helpContentActions";

// Organisation
import {
  checkPermissionsAction,
  checkSubscriptionAction,
} from "../src/actions/organisationActions";

// Main function
function App() {
  // Load fonts
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Montserrat", "Roboto", "Sintony"],
      },
    });
  }, []);

  // BlueprintJS configuration
  FocusStyleManager.onlyShowFocusOnTabs();

  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);
  const { token } = userLogin;

  // Redux dispatch function
  const dispatch = useDispatch();

  // React Router location function
  const location = useLocation();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Load help content
  useEffect(() => {
    if (token) {
      dispatch(listHelpContentAction());
    }
    // eslint-disable-next-line
  }, [token]);

  // Set scale
  const [showSmallWindowWarning, setShowSmallWindowWarning] = useState(false);

  const calculateScale = useCallback(() => {
    const path = location.pathname.split("/").pop();
    const urlExclusions = ["", "apply", "login", "signup"];

    let scale = 1;

    if (urlExclusions.includes(path)) {
      scale = 1;

      setShowSmallWindowWarning(false);
    } else if (window.screen.width / 1280 < 1) {
      if (window.screen.width / 1280 < 0.5) {
        scale = 1;

        setShowSmallWindowWarning(true);
      } else {
        scale = window.screen.width / 1280;

        setShowSmallWindowWarning(false);
      }
    }

    const vpElement = document.getElementById("vp");

    if (vpElement) {
      vpElement.setAttribute(
        "content",
        "width=device-width, initial-scale=" + scale
      );
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      calculateScale();
    }, 1000);
  }, [calculateScale]);

  useEffect(() => {
    window.addEventListener("resize", calculateScale);

    return () => {
      window.removeEventListener("resize", calculateScale);
    };
  }, [calculateScale]);

  // Show reCAPTCHA
  const showReCaptcha = useCallback(() => {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];

    if (badge) {
      const path = location.pathname.split("/").pop();

      const urls = [
        "",
        "apply",
        "changePassword",
        "login",
        "resetPassword",
        "resetPasswordConfirm",
        "signup",
      ];

      if (urls.includes(path)) {
        if (path === "" && token) {
          // User is in Dashboard
          badge.style.visibility = "hidden";
        } else {
          badge.style.visibility = "visible";
        }
      } else {
        badge.style.visibility = "hidden";
      }
    }
  }, [location, token]);

  useEffect(() => {
    setTimeout(() => {
      showReCaptcha();
    }, 1000);
  }, [showReCaptcha]);

  // Check permissions and subscription whenever the active organisation or location change
  useEffect(() => {
    if (activeOrganisation.id && location) {
      dispatch(checkPermissionsAction(activeOrganisation.id));

      dispatch(checkSubscriptionAction(activeOrganisation.id));
    }
  }, [activeOrganisation.id, dispatch, location]);

  // JSX UI code
  if (location.pathname.startsWith("/tools")) {
    const toolId = location.pathname.split("/").pop();

    switch (toolId) {
      case "98ac805b-c1db-4eb0-8d4b-cba9a76d93a7":
        return <SalaryBenchmarkWidget />;

      default:
        return <></>;
    }
  } else if (showSmallWindowWarning) {
    return <SmallWindowWarning />;
  } else {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        {/* Sidebar */}
        {token && <Sidebar />}

        {/* Main container */}
        <div
          className={!lightMode ? Classes.DARK : undefined}
          style={{
            background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY4,
            height: "100vh",
            overflowY: "auto",
            padding: "50px 0 0 0",
            width: "100%",
          }}
        >
          <Routes>
            <Route path="/" element={<HomeScreen />} />

            {/* Account management */}
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/changePassword" element={<PasswordChangeScreen />} />
            <Route path="/confirmEmail/:key" element={<EmailConfirmScreen />} />
            <Route path="/resetPassword" element={<PasswordResetScreen />} />
            <Route
              path="/resetPasswordConfirm/:uid/:token"
              element={<PasswordResetConfirmScreen />}
            />
            <Route path="/signup" element={<SignupScreen />} />

            {/* Case studies */}
            <Route path="/caseStudies" element={<CaseStudyListScreen />} />
            <Route
              path="/caseStudies/:id"
              element={<CaseStudyDetailScreen />}
            />

            {/* Clients */}
            <Route path="/clients" element={<ClientListScreen />} />
            <Route path="/clients/:id" element={<ClientDetailScreen />} />

            {/* Contacts */}
            <Route path="/contacts" element={<ContactListScreen />} />
            <Route path="/contacts/:id" element={<ContactDetailScreen />} />

            {/* Engagements */}
            <Route path="/engagements" element={<EngagementListScreen />} />
            <Route
              path="/engagements/:id"
              element={<EngagementDetailScreen />}
            />

            {/* Insights */}
            <Route path="/insights" element={<InsightListScreen />} />
            <Route path="/insights/:id" element={<InsightDetailScreen />} />

            {/* Interviews */}
            <Route path="/interviews/:id" element={<InterviewDetailScreen />} />

            {/* Invoices */}
            <Route path="/invoices" element={<InvoiceListScreen />} />
            <Route path="/invoices/:id" element={<InvoiceDetailScreen />} />

            {/* Markets */}
            <Route path="/markets" element={<MarketListScreen />} />
            <Route path="/markets/:id" element={<MarketDetailScreen />} />

            {/* Needs */}
            <Route path="/needs/:id" element={<NeedDetailScreen />} />

            {/* Openings */}
            <Route path="/openings" element={<OpeningListScreen />} />
            <Route path="/openings/:id" element={<OpeningDetailScreen />} />
            <Route
              path="/openings/application/:id/apply"
              element={<OpeningApplicationScreen />}
            />

            {/* Organisations */}
            <Route path="/organisations" element={<OrganisationListScreen />} />
            <Route
              path="/organisations/:id"
              element={<OrganisationDetailScreen />}
            />

            {/* Proposals */}
            <Route path="/proposals" element={<ProposalListScreen />} />
            <Route path="/proposals/:id" element={<ProposalDetailScreen />} />

            {/* Roles */}
            <Route path="/roles" element={<RoleListScreen />} />
            <Route path="/roles/:id" element={<RoleDetailScreen />} />

            {/* Services */}
            <Route path="/services" element={<ServiceListScreen />} />
            <Route path="/services/:id" element={<ServiceDetailScreen />} />

            {/* Staff */}
            <Route path="/staff" element={<StaffListScreen />} />
            <Route path="/staff/:id" element={<StaffDetailScreen />} />

            {/* User profile */}
            <Route path="/userProfile" element={<UserProfileScreen />} />
          </Routes>
        </div>

        {/* Action sidebar */}
        {token && <ActionSidebar />}
      </div>
    );
  }
}

export default App;
