// Library imports
import { Button, Classes } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React from "react";

// Main function
function TableColumnHeadSearch({
  queryObject,
  queryVariable,
  setQueryObject,
  setQueryVariable,
  title,
  width,
}) {
  // JSX UI code
  return (
    <th style={{ padding: "5px", width: width }}>
      <Select
        fill
        items={[{}]}
        itemRenderer={() => {
          return null;
        }}
        onQueryChange={(query) => {
          setQueryObject((prevState) => {
            return {
              ...prevState,
              page: 1,
              [setQueryVariable]: query,
            };
          });
        }}
      >
        <Button
          active={queryObject[queryVariable] ? true : false}
          alignText="left"
          fill
          intent={queryObject[queryVariable] ? "primary" : undefined}
          minimal
          text={
            <span className={Classes.TEXT_SMALL}>
              <strong>{title}</strong>
            </span>
          }
          rightIcon="caret-down"
        />
      </Select>
    </th>
  );
}

export default TableColumnHeadSearch;
