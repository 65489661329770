// Constant imports
import {
  COMPETENCY_LIST_REQUEST,
  COMPETENCY_LIST_SUCCESS,
  COMPETENCY_LIST_FAIL,
  COMPETENCY_LIST_RESET,
  COMPETENCY_CREATE_REQUEST,
  COMPETENCY_CREATE_SUCCESS,
  COMPETENCY_CREATE_FAIL,
  COMPETENCY_CREATE_RESET,
  COMPETENCY_DETAILS_REQUEST,
  COMPETENCY_DETAILS_SUCCESS,
  COMPETENCY_DETAILS_FAIL,
  COMPETENCY_DETAILS_RESET,
  COMPETENCY_UPDATE_REQUEST,
  COMPETENCY_UPDATE_SUCCESS,
  COMPETENCY_UPDATE_FAIL,
  COMPETENCY_UPDATE_RESET,
  COMPETENCY_DELETE_REQUEST,
  COMPETENCY_DELETE_SUCCESS,
  COMPETENCY_DELETE_FAIL,
  COMPETENCY_DELETE_RESET,
  COMPETENCY_DOWNLOAD_REQUEST,
  COMPETENCY_DOWNLOAD_SUCCESS,
  COMPETENCY_DOWNLOAD_FAIL,
  COMPETENCY_DOWNLOAD_RESET,
  COMPETENCY_UPLOAD_BEGIN,
  COMPETENCY_UPLOAD_REQUEST,
  COMPETENCY_UPLOAD_SUCCESS,
  COMPETENCY_UPLOAD_FAIL,
  COMPETENCY_UPLOAD_END,
  COMPETENCY_UPLOAD_RESET,
} from "../constants/competencyConstants";

// List all
export const competencyListReducer = (
  state = {
    loading: false,
    count: 0,
    competencies: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case COMPETENCY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPETENCY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        competencies: action.payload.competencies,
        success: true,
        error: "",
      };

    case COMPETENCY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        competencies: [],
        success: false,
        error: action.payload,
      };

    case COMPETENCY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        competencies: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const competencyCreateReducer = (
  state = { loading: false, competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case COMPETENCY_CREATE_REQUEST:
      return {
        loading: true,
        competency: state.competency,
        success: false,
        error: "",
      };

    case COMPETENCY_CREATE_SUCCESS:
      return {
        loading: false,
        competency: action.payload,
        success: true,
        error: "",
      };

    case COMPETENCY_CREATE_FAIL:
      return {
        loading: false,
        competency: state.competency,
        success: false,
        error: action.payload,
      };

    case COMPETENCY_CREATE_RESET:
      return { loading: false, competency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const competencyDetailsReducer = (
  state = { loading: false, competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case COMPETENCY_DETAILS_REQUEST:
      return {
        loading: true,
        competency: state.competency,
        success: false,
        error: "",
      };

    case COMPETENCY_DETAILS_SUCCESS:
      return {
        loading: false,
        competency: action.payload,
        success: true,
        error: "",
      };

    case COMPETENCY_DETAILS_FAIL:
      return {
        loading: false,
        competency: state.competency,
        success: false,
        error: action.payload,
      };

    case COMPETENCY_DETAILS_RESET:
      return { loading: false, competency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const competencyUpdateReducer = (
  state = { loading: false, competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case COMPETENCY_UPDATE_REQUEST:
      return {
        loading: true,
        competency: state.competency,
        success: false,
        error: "",
      };

    case COMPETENCY_UPDATE_SUCCESS:
      return {
        loading: false,
        competency: action.payload,
        success: true,
        error: "",
      };

    case COMPETENCY_UPDATE_FAIL:
      return {
        loading: false,
        competency: state.competency,
        success: false,
        error: action.payload,
      };

    case COMPETENCY_UPDATE_RESET:
      return { loading: false, competency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const competencyDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case COMPETENCY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case COMPETENCY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case COMPETENCY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case COMPETENCY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const competencyDownloadReducer = (
  state = {
    loading: false,
    competencies: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case COMPETENCY_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPETENCY_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        competencies: action.payload.competencies,
        success: true,
        error: "",
      };

    case COMPETENCY_DOWNLOAD_FAIL:
      return {
        loading: false,
        competencies: [],
        success: false,
        error: action.payload,
      };

    case COMPETENCY_DOWNLOAD_RESET:
      return {
        loading: false,
        competencies: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const competencyUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    progress: 0,
    records_failed: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case COMPETENCY_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case COMPETENCY_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPETENCY_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
        success: true,
        error: "",
      };

    case COMPETENCY_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        records_failed: action.payload.records_failed,
        success: false,
        error: action.payload.error,
      };

    case COMPETENCY_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case COMPETENCY_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        progress: 0,
        records_failed: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
