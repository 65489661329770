// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function ServiceProposalPipelineCard({ fill, serviceList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Pipeline</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total fees estimated for the selected service(s) on active
            proposals. These are proposals that are being qualified or worked
            on, or that have been submitted but are awaiting an outcome.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {serviceList.totalFeesOnProposalsQualifying >
                2 *
                  (serviceList.totalFeesOnProposalsInProgress +
                    serviceList.totalFeesOnProposalsSubmitted) ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The shape of the pipeline looks good. Continue to promote
                      your services, while working on existing opportunities.
                    </div>
                  </div>
                ) : serviceList.totalFeesOnProposalsQualifying >
                  serviceList.totalFeesOnProposalsInProgress +
                    serviceList.totalFeesOnProposalsSubmitted ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The pipeline is too flat. It may be necessary to strike a
                      balance between delivery and marketing efforts to ensure a
                      consistent flow of opportunities.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is at risk of running out of work for the
                      selected service(s). To address this issue, it may be
                      necessary to allocate more resources to lead generation.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Qualifying",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: serviceList.totalFeesOnProposalsQualifying,
        },
        {
          name: "In-progress",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: serviceList.totalFeesOnProposalsInProgress,
        },
        {
          name: "Submitted",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: serviceList.totalFeesOnProposalsSubmitted,
        },
      ]}
      fill={fill}
      noData={
        !serviceList.totalFeesOnProposalsInProgress &&
        !serviceList.totalFeesOnProposalsQualifying &&
        !serviceList.totalFeesOnProposalsSubmitted
      }
      title="Pipeline"
      units={activeOrganisation.currency}
    />
  );
}

export default ServiceProposalPipelineCard;
