// Library imports
import { Card, Classes, HTMLTable, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DateValue from "../components/DateValue";
import NameValue from "../components/NameValue";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableText from "../components/TableText";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";

// Main function
function EngagementBaselineFacts({ facts }) {
  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <Card>
      <TabSectionField
        label="Engagement id"
        value={facts.engagement_id}
        vertical
      />

      <TabSectionField
        label="Engagement name"
        value={facts.engagement_name}
        vertical
      />

      <TabSectionField
        label="Client"
        value={
          <NameValue image={facts.client_logo} logo text={facts.client_name} />
        }
        vertical
      />

      <TabSectionField
        label="Sponsor"
        value={
          <NameValue
            image={facts.sponsor_profile_photo}
            text={facts.sponsor_name}
          />
        }
        vertical
      />

      <TabSectionField
        label="Fees"
        value={
          facts.fees.length > 0 ? (
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.fees.map((fee, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <TableText text={fee.engagement_service_name} />
                        </td>

                        <td>
                          <TableNumber number={fee.expenses} />
                        </td>

                        <td>
                          <TableNumber
                            number={fee.margin}
                            tagNumber={fee.margin_percentage}
                            tagPercentage
                          />
                        </td>

                        <td>
                          <TableNumber number={fee.fees} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Expenses */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_expenses} />
                    </td>

                    {/* Margin */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber
                        number={facts.total_margin}
                        tagNumber={facts.average_margin_percentage}
                        tagPercentage
                      />
                    </td>

                    {/* Fees */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_fees} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Service</th>

                    <th>Expenses</th>

                    <th>Margin</th>

                    <th>Fees</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />

      <TabSectionField
        label="Milestones"
        value={
          facts.milestones.length > 0 ? (
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.milestones.map((milestone, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <TableText text={milestone.name} />
                        </td>

                        <td>
                          <TableDate date={milestone.due} small />
                        </td>

                        <td>
                          <TableNumber number={milestone.value} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <tfoot>
                  <tr>
                    <td
                      colSpan="2"
                      style={{
                        textAlign: "end",
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <div>Total</div>

                        <Tag
                          minimal
                          style={{
                            margin: "0 0 0 10px",
                            textAlign: "center",
                            width: "50px",
                          }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      </div>
                    </td>

                    {/* Total */}
                    <td style={{ verticalAlign: "middle" }}>
                      <TableNumber number={facts.total_milestone_value} />
                    </td>
                  </tr>
                </tfoot>

                <thead>
                  <tr>
                    <th>Milestone</th>

                    <th>Due</th>

                    <th>Value</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />

      <TabSectionField
        bottom
        label="Deliverables"
        value={
          facts.deliverables.length > 0 ? (
            facts.deliverables.map((deliverable) => {
              return (
                <TabSectionField
                  bottom
                  key={deliverable.id}
                  value={
                    <TabSectionFieldCard
                      tag={<DateValue date={deliverable.due} />}
                      text={
                        deliverable.description ? (
                          deliverable.description
                        ) : (
                          <span className={Classes.TEXT_MUTED}>
                            No comments.
                          </span>
                        )
                      }
                      title={deliverable.name}
                    />
                  }
                  vertical
                />
              );
            })
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />
    </Card>
  );
}

export default EngagementBaselineFacts;
