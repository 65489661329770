// Library imports
import { Card, Classes, Colors, Tag, Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import ApplicationInfo from "./ApplicationInfo";
import InfoPopup from "./InfoPopup";

// Main function
function CandidateCard({ additionalInfo, candidate, interactive, onClick }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        alignItems: "center",
        borderTop: `5px solid ${
          candidate.status === "Pending"
            ? Colors.BLUE3
            : candidate.status === "Rejected"
            ? Colors.RED3
            : Colors.GREEN3
        }`,
        display: "flex",
        height: "200px",
        margin: " 40px 5px 5px 5px",
        padding: "10px",
        textAlign: "center",
        width: "200px",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img
          alt="profile"
          src={
            candidate.application_data
              ? candidate.application_data.profile_photo
                ? candidate.application_data.profile_photo
                : "/images/no_photo.png"
              : undefined
          }
          style={{
            background: "white",
            border: `1px solid ${
              lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
            }`,
            borderRadius: "50%",
            height: "110px",
            margin: "-50px 0 10px 0",
            objectFit: "contain",
            width: "110px",
          }}
        />

        <div style={{ width: "100%" }}>
          <Text ellipsize>
            {candidate.application_data
              ? candidate.application_data.name
              : undefined}
          </Text>

          <Text
            className={Classes.TEXT_SMALL}
            ellipsize
            style={{ fontWeight: "bold", marginTop: "5px" }}
          >
            {candidate.application_data
              ? candidate.application_data.email
              : undefined}
          </Text>

          <Tag
            intent={
              candidate.status === "Pending"
                ? "primary"
                : candidate.status === "Rejected"
                ? "danger"
                : "success"
            }
            minimal
            round
            style={{
              marginTop: "10px",
              textAlign: "center",
              width: "125px",
            }}
          >
            <span className={Classes.TEXT_SMALL}>{candidate.status}</span>
          </Tag>
        </div>

        <InfoPopup
          content={
            <ApplicationInfo
              additionalInfo={additionalInfo}
              applicationData={
                candidate.application_data
                  ? candidate.application_data
                  : undefined
              }
            />
          }
        />
      </div>
    </Card>
  );
}

export default CandidateCard;
