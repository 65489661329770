// Constant imports
import {
  PROPOSAL_DOCUMENT_DETAILS_REQUEST,
  PROPOSAL_DOCUMENT_DETAILS_SUCCESS,
  PROPOSAL_DOCUMENT_DETAILS_FAIL,
  PROPOSAL_DOCUMENT_DETAILS_RESET,
  PROPOSAL_DOCUMENT_UPDATE_REQUEST,
  PROPOSAL_DOCUMENT_UPDATE_SUCCESS,
  PROPOSAL_DOCUMENT_UPDATE_FAIL,
  PROPOSAL_DOCUMENT_UPDATE_RESET,
} from "../constants/proposalDocumentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List details
export const listProposalDocumentDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_DOCUMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalDocument(id, token);

      dispatch({
        type: PROPOSAL_DOCUMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_DOCUMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalDocumentDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_DOCUMENT_DETAILS_RESET });
  };

// Update
export const updateProposalDocumentAction =
  (id, proposal_document) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_DOCUMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalDocument(
        id,
        token,
        proposal_document
      );

      dispatch({
        type: PROPOSAL_DOCUMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_DOCUMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalDocumentAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_DOCUMENT_UPDATE_RESET });
};
