// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function StaffAvailabilityCard({ fill, staff }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Capacity</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Maximum number of work hours available by day of the week.</p>
        </>
      }
      data={[
        {
          name: "Monday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_mon / 60,
        },
        {
          name: "Tuesday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_tue / 60,
        },
        {
          name: "Wednesday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_wed / 60,
        },
        {
          name: "Thursday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_thu / 60,
        },
        {
          name: "Friday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_fri / 60,
        },
        {
          name: "Saturday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_sat / 60,
        },
        {
          name: "Sunday",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: staff.minutes_sun / 60,
        },
      ]}
      fill={fill}
      units="Hours"
    />
  );
}

export default StaffAvailabilityCard;
