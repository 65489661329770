// Main function
const getCurrentWeekOfYear = () => {
  const now = new Date();

  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0
  );

  const firstJan = new Date(today.getFullYear(), 0, 1);

  const weekOfYear = Math.ceil(
    ((today.getTime() - firstJan.getTime()) / (24 * 60 * 60 * 1000) +
      firstJan.getDay() +
      1) /
      7
  );

  return weekOfYear;
};

export default getCurrentWeekOfYear;
