// Constant imports
import {
  MARKET_INSIGHT_LIST_REQUEST,
  MARKET_INSIGHT_LIST_SUCCESS,
  MARKET_INSIGHT_LIST_FAIL,
  MARKET_INSIGHT_LIST_RESET,
  MARKET_INSIGHT_CREATE_REQUEST,
  MARKET_INSIGHT_CREATE_SUCCESS,
  MARKET_INSIGHT_CREATE_FAIL,
  MARKET_INSIGHT_CREATE_RESET,
  MARKET_INSIGHT_DETAILS_REQUEST,
  MARKET_INSIGHT_DETAILS_SUCCESS,
  MARKET_INSIGHT_DETAILS_FAIL,
  MARKET_INSIGHT_DETAILS_RESET,
  MARKET_INSIGHT_UPDATE_REQUEST,
  MARKET_INSIGHT_UPDATE_SUCCESS,
  MARKET_INSIGHT_UPDATE_FAIL,
  MARKET_INSIGHT_UPDATE_RESET,
  MARKET_INSIGHT_DELETE_REQUEST,
  MARKET_INSIGHT_DELETE_SUCCESS,
  MARKET_INSIGHT_DELETE_FAIL,
  MARKET_INSIGHT_DELETE_RESET,
} from "../constants/marketInsightConstants";

// List all
export const marketInsightListReducer = (
  state = {
    loading: false,
    count: 0,
    market_insights: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MARKET_INSIGHT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MARKET_INSIGHT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        market_insights: action.payload.market_insights,
        success: true,
        error: "",
      };

    case MARKET_INSIGHT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        market_insights: [],
        success: false,
        error: action.payload,
      };

    case MARKET_INSIGHT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        market_insights: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const marketInsightCreateReducer = (
  state = {
    loading: false,
    market_insight: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MARKET_INSIGHT_CREATE_REQUEST:
      return {
        loading: true,
        market_insight: state.market_insight,
        success: false,
        error: "",
      };

    case MARKET_INSIGHT_CREATE_SUCCESS:
      return {
        loading: false,
        market_insight: action.payload,
        success: true,
        error: "",
      };

    case MARKET_INSIGHT_CREATE_FAIL:
      return {
        loading: false,
        market_insight: state.market_insight,
        success: false,
        error: action.payload,
      };

    case MARKET_INSIGHT_CREATE_RESET:
      return {
        loading: false,
        market_insight: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const marketInsightDetailsReducer = (
  state = {
    loading: false,
    market_insight: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MARKET_INSIGHT_DETAILS_REQUEST:
      return {
        loading: true,
        market_insight: state.market_insight,
        success: false,
        error: "",
      };

    case MARKET_INSIGHT_DETAILS_SUCCESS:
      return {
        loading: false,
        market_insight: action.payload,
        success: true,
        error: "",
      };

    case MARKET_INSIGHT_DETAILS_FAIL:
      return {
        loading: false,
        market_insight: state.market_insight,
        success: false,
        error: action.payload,
      };

    case MARKET_INSIGHT_DETAILS_RESET:
      return {
        loading: false,
        market_insight: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const marketInsightUpdateReducer = (
  state = {
    loading: false,
    market_insight: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MARKET_INSIGHT_UPDATE_REQUEST:
      return {
        loading: true,
        market_insight: state.market_insight,
        success: false,
        error: "",
      };

    case MARKET_INSIGHT_UPDATE_SUCCESS:
      return {
        loading: false,
        market_insight: action.payload,
        success: true,
        error: "",
      };

    case MARKET_INSIGHT_UPDATE_FAIL:
      return {
        loading: false,
        market_insight: state.market_insight,
        success: false,
        error: action.payload,
      };

    case MARKET_INSIGHT_UPDATE_RESET:
      return {
        loading: false,
        market_insight: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const marketInsightDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MARKET_INSIGHT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case MARKET_INSIGHT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case MARKET_INSIGHT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case MARKET_INSIGHT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
