// Library imports
import {
  Button,
  Classes,
  Colors,
  Divider,
  H5,
  HTMLTable,
  Icon,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Staff
import { listStaffAction } from "../actions/staffActions";

// Component imports
import NameValue from "../components/NameValue";
import TabSectionFieldLink from "./TabSectionFieldLink";
import TabSectionFieldProgressBar from "./TabSectionFieldProgressBar";

// Main function
function StaffInfo({
  additionalInfo,
  customCompetencyProfile,
  query,
  staffData,
  staffId,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Refresh staff data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [staff, setStaff] = useState({});

  useEffect(() => {
    if (staffData) {
      setStaff(staffData);

      setLoading(false);
    }
  }, [staffData]);

  useEffect(() => {
    if (!staffData && !staffList.loading) {
      if (staffList.success) {
        const thisStaff = staffList.staff.filter(
          (staff_item) => staff_item.id === staffId
        );

        if (thisStaff.length > 0) {
          setStaff(thisStaff[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listStaffAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (staffList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [staffList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                alt="profile"
                src={
                  staff.profile_photo
                    ? staff.profile_photo
                    : "/images/no_photo.png"
                }
                style={{
                  background: "white",
                  border: `1px solid ${
                    lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                  }`,
                  borderRadius: "50%",
                  height: "60px",
                  margin: "0 10px 0 0",
                  objectFit: "contain",
                  width: "60px",
                }}
              />

              <H5 style={{ margin: "0 10px 0 0" }}>{staff.name}</H5>
            </div>

            <div onClick={(e) => e.stopPropagation()}>
              <Button
                intent="primary"
                minimal
                onClick={() =>
                  (window.location = `mailto:${
                    staff.contact_email ? staff.contact_email : staff.name
                  }`)
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
                text="Email team member"
              />
            </div>
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Name</strong>
          </p>

          <p>{staff.name_alias ? staff.name_alias : "-"}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Title</strong>
          </p>

          <p>{staff.title ? staff.title : "-"}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Role</strong>
          </p>

          <p>{staff.role_staff ? staff.role_staff.name : "-"}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Location</strong>
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{staff.location ? staff.location_name : "-"}</div>

            {staff.location && (
              <span
                className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}
              >
                {staff.location_address}
              </span>
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>About</strong>
          </p>

          <p>
            {staff.about ? (
              staff.about
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Phone</strong>
          </p>

          <p>{staff.phone ? staff.phone : "-"}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>LinkedIn page</strong>
          </p>

          <div style={{ marginBottom: "10px" }}>
            <TabSectionFieldLink invertColor url={staff.linkedin_page} />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Competencies</strong>
          </p>

          {customCompetencyProfile && customCompetencyProfile.length > 0 ? (
            <div style={{ marginBottom: "10px" }}>
              <div
                style={{
                  background: `${Colors.BLUE3}33`,
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE2,
                  display: "flex",
                  marginBottom: "10px",
                  padding: "15px",
                }}
              >
                <Icon icon="info-sign" />

                <div style={{ marginLeft: "5px" }}>
                  Competencies filtered based on custom competency profile.
                </div>
              </div>

              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody className={Classes.TEXT_SMALL}>
                  {customCompetencyProfile.map((customCompetency, i) => {
                    return (
                      <tr key={customCompetency.competency}>
                        <td
                          style={{
                            color: lightMode ? Colors.WHITE : Colors.BLACK,
                            verticalAlign: "middle",
                          }}
                        >
                          <Text ellipsize>{customCompetency.name}</Text>
                        </td>

                        <td
                          style={{
                            color: lightMode ? Colors.WHITE : Colors.BLACK,
                            verticalAlign: "middle",
                          }}
                        >
                          <TabSectionFieldProgressBar
                            intent="primary"
                            rating={customCompetency.level / 5}
                            width="100%"
                          />

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ marginTop: "5px" }}
                          >
                            {customCompetency.level > 4
                              ? "Expert"
                              : customCompetency.level > 2
                              ? "Intermediate"
                              : "Beginner"}
                          </div>
                        </td>

                        <td
                          style={{
                            color: lightMode ? Colors.WHITE : Colors.BLACK,
                            verticalAlign: "middle",
                          }}
                        >
                          {staff.competency_profile
                            .filter(
                              (competency) =>
                                competency.id === customCompetency.competency
                            )
                            .map((competency) => {
                              return (
                                <div key={competency.id}>
                                  <TabSectionFieldProgressBar
                                    intent="success"
                                    rating={competency.staff_level / 5}
                                    width="100%"
                                  />

                                  <div
                                    className={[
                                      Classes.TEXT_MUTED,
                                      Classes.TEXT_SMALL,
                                    ].join(" ")}
                                    style={{ marginTop: "5px" }}
                                  >
                                    {competency.staff_level > 4
                                      ? "Expert"
                                      : competency.staff_level > 2
                                      ? "Intermediate"
                                      : "Beginner"}
                                  </div>
                                </div>
                              );
                            })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <thead className={Classes.TEXT_SMALL}>
                  <tr>
                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                        width: "200px",
                      }}
                    >
                      Competency
                    </th>

                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                      }}
                    >
                      Required level
                    </th>

                    <th
                      style={{
                        color: lightMode ? Colors.WHITE : Colors.BLACK,
                      }}
                    >
                      Current level
                    </th>
                  </tr>
                </thead>
              </HTMLTable>
            </div>
          ) : staff.competency_profile.length > 0 ? (
            <HTMLTable bordered striped style={{ width: "100%" }}>
              <tbody className={Classes.TEXT_SMALL}>
                {staff.competency_profile.map((competency, i) => {
                  return (
                    <tr key={competency.id}>
                      <td
                        style={{
                          color: lightMode ? Colors.WHITE : Colors.BLACK,
                          verticalAlign: "middle",
                        }}
                      >
                        <Text ellipsize>{competency.competency}</Text>
                      </td>

                      <td
                        style={{
                          color: lightMode ? Colors.WHITE : Colors.BLACK,
                          verticalAlign: "middle",
                        }}
                      >
                        <TabSectionFieldProgressBar
                          intent="primary"
                          rating={competency.staff_level / 5}
                          width="100%"
                        />

                        <div
                          className={[
                            Classes.TEXT_MUTED,
                            Classes.TEXT_SMALL,
                          ].join(" ")}
                          style={{ marginTop: "5px" }}
                        >
                          {competency.staff_level > 4
                            ? "Expert"
                            : competency.staff_level > 2
                            ? "Intermediate"
                            : "Beginner"}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <thead className={Classes.TEXT_SMALL}>
                <tr>
                  <th
                    style={{
                      color: lightMode ? Colors.WHITE : Colors.BLACK,
                      width: "200px",
                    }}
                  >
                    Competency
                  </th>

                  <th
                    style={{
                      color: lightMode ? Colors.WHITE : Colors.BLACK,
                    }}
                  >
                    Current level
                  </th>
                </tr>
              </thead>
            </HTMLTable>
          ) : (
            <p className={Classes.TEXT_MUTED}>None identified.</p>
          )}

          {customCompetencyProfile
            ? customCompetencyProfile.length === 0 && (
                <div style={{ marginBottom: "10px" }}>
                  <HTMLTable bordered striped style={{ width: "100%" }}>
                    <tbody className={Classes.TEXT_SMALL}>
                      {staff.competency_profile
                        .filter((competency) => competency.staff_level !== 0)
                        .map((competency) => {
                          return (
                            <tr key={competency.id}>
                              <td
                                style={{
                                  color: lightMode
                                    ? Colors.WHITE
                                    : Colors.BLACK,
                                }}
                              >
                                <Text ellipsize>{competency.competency}</Text>
                              </td>

                              <td
                                style={{
                                  color: lightMode
                                    ? Colors.WHITE
                                    : Colors.BLACK,
                                }}
                              >
                                <TabSectionFieldProgressBar
                                  rating={competency.staff_level / 5}
                                  width="100%"
                                />
                              </td>

                              <td
                                style={{
                                  color: lightMode
                                    ? Colors.WHITE
                                    : Colors.BLACK,
                                }}
                              >
                                <div
                                  className={[
                                    Classes.TEXT_MUTED,
                                    Classes.TEXT_SMALL,
                                  ].join(" ")}
                                >
                                  {competency.staff_level > 4
                                    ? "Expert"
                                    : competency.staff_level > 2
                                    ? "Intermediate"
                                    : "Beginner"}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>

                    <thead className={Classes.TEXT_SMALL}>
                      <tr>
                        <th
                          style={{
                            color: lightMode ? Colors.WHITE : Colors.BLACK,
                            width: "200px",
                          }}
                        >
                          Competency
                        </th>

                        <th
                          style={{
                            color: lightMode ? Colors.WHITE : Colors.BLACK,
                          }}
                        >
                          Current level
                        </th>

                        <th></th>
                      </tr>
                    </thead>
                  </HTMLTable>
                </div>
              )
            : undefined}

          {additionalInfo}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Manager</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NameValue
              email={staff.manager_name}
              image={staff.manager_profile_photo}
              invertColor
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default StaffInfo;
