// Constant imports
import {
  OPENING_EVALUATION_RATING_LIST_REQUEST,
  OPENING_EVALUATION_RATING_LIST_SUCCESS,
  OPENING_EVALUATION_RATING_LIST_FAIL,
  OPENING_EVALUATION_RATING_LIST_RESET,
  OPENING_EVALUATION_RATING_UPDATE_REQUEST,
  OPENING_EVALUATION_RATING_UPDATE_SUCCESS,
  OPENING_EVALUATION_RATING_UPDATE_FAIL,
  OPENING_EVALUATION_RATING_UPDATE_RESET,
  OPENING_EVALUATION_RATINGS_UPLOAD_BEGIN,
  OPENING_EVALUATION_RATINGS_UPLOAD_REQUEST,
  OPENING_EVALUATION_RATINGS_UPLOAD_SUCCESS,
  OPENING_EVALUATION_RATINGS_UPLOAD_FAIL,
  OPENING_EVALUATION_RATINGS_UPLOAD_END,
  OPENING_EVALUATION_RATINGS_UPLOAD_RESET,
} from "../constants/openingEvaluationRatingConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listOpeningEvaluationRatingsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_RATING_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningEvaluationRatings(
        params,
        token
      );

      dispatch({
        type: OPENING_EVALUATION_RATING_LIST_SUCCESS,

        payload: {
          count: data.count,
          opening_evaluation_ratings: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_RATING_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListOpeningEvaluationRatingsAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_EVALUATION_RATING_LIST_RESET });
  };

// Update
export const updateOpeningEvaluationRatingAction =
  (id, opening_evaluation_rating) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_RATING_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOpeningEvaluationRating(
        id,
        token,
        opening_evaluation_rating
      );

      dispatch({
        type: OPENING_EVALUATION_RATING_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_RATING_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningEvaluationRatingAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_EVALUATION_RATING_UPDATE_RESET });
  };

// Upload
export const uploadOpeningEvaluationRatingsAction =
  (records) => async (dispatch, getState) => {
    dispatch({
      type: OPENING_EVALUATION_RATINGS_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      try {
        dispatch({ type: OPENING_EVALUATION_RATINGS_UPLOAD_REQUEST });

        await cortixDataService.updateOpeningEvaluationRating(
          records[recordNum].competency,
          token,
          {
            comments: records[recordNum].comments,
            level: records[recordNum].level,
          }
        );

        dispatch({
          type: OPENING_EVALUATION_RATINGS_UPLOAD_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: OPENING_EVALUATION_RATINGS_UPLOAD_FAIL,

          payload: {
            error: error,
          },
        });
      }
    }

    dispatch({ type: OPENING_EVALUATION_RATINGS_UPLOAD_END });
  };

// Reset upload
export const resetUploadOpeningEvaluationRatingsAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_EVALUATION_RATINGS_UPLOAD_RESET });
  };
