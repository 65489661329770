// Constant imports
import {
  ENGAGEMENT_DELIVERY_ISSUE_LIST_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_LIST_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_LIST_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_LIST_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_RESET,
} from "../constants/engagementDeliveryIssueConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementDeliveryIssuesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementDeliveryIssues(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfIssuesClosed: data.number_of_issues_closed,
          numberOfIssuesOpen: data.number_of_issues_open,
          engagement_issues: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementDeliveryIssuesAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_LIST_RESET });
  };

// Create
export const createEngagementDeliveryIssueAction =
  (engagement_issue) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementDeliveryIssue(
        token,
        engagement_issue
      );

      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementDeliveryIssueAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_CREATE_RESET });
  };

// List details
export const listEngagementDeliveryIssueDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementDeliveryIssue(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementDeliveryIssueDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_DETAILS_RESET });
  };

// Update
export const updateEngagementDeliveryIssueAction =
  (id, engagement_issue) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementDeliveryIssue(
        id,
        token,
        engagement_issue
      );

      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementDeliveryIssueAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_UPDATE_RESET });
  };

// Delete
export const deleteEngagementDeliveryIssueAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementDeliveryIssue(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DELIVERY_ISSUE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementDeliveryIssueAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DELIVERY_ISSUE_DELETE_RESET });
  };
