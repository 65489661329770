export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "CONTACT_LIST_FAIL";
export const CONTACT_LIST_RESET = "CONTACT_LIST_RESET";

export const CONTACT_CREATE_REQUEST = "CONTACT_CREATE_REQUEST";
export const CONTACT_CREATE_SUCCESS = "CONTACT_CREATE_SUCCESS";
export const CONTACT_CREATE_FAIL = "CONTACT_CREATE_FAIL";
export const CONTACT_CREATE_RESET = "CONTACT_CREATE_RESET";

export const CONTACT_DETAILS_REQUEST = "CONTACT_DETAILS_REQUEST";
export const CONTACT_DETAILS_SUCCESS = "CONTACT_DETAILS_SUCCESS";
export const CONTACT_DETAILS_FAIL = "CONTACT_DETAILS_FAIL";
export const CONTACT_DETAILS_RESET = "CONTACT_DETAILS_RESET";

export const CONTACT_UPDATE_REQUEST = "CONTACT_UPDATE_REQUEST";
export const CONTACT_UPDATE_SUCCESS = "CONTACT_UPDATE_SUCCESS";
export const CONTACT_UPDATE_FAIL = "CONTACT_UPDATE_FAIL";
export const CONTACT_UPDATE_RESET = "CONTACT_UPDATE_RESET";

export const CONTACT_DELETE_REQUEST = "CONTACT_DELETE_REQUEST";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAIL = "CONTACT_DELETE_FAIL";
export const CONTACT_DELETE_RESET = "CONTACT_DELETE_RESET";

export const CONTACT_REFERRER_LIST_REQUEST = "CONTACT_REFERRER_LIST_REQUEST";
export const CONTACT_REFERRER_LIST_SUCCESS = "CONTACT_REFERRER_LIST_SUCCESS";
export const CONTACT_REFERRER_LIST_FAIL = "CONTACT_REFERRER_LIST_FAIL";
export const CONTACT_REFERRER_LIST_RESET = "CONTACT_REFERRER_LIST_RESET";

export const CONTACT_DOWNLOAD_REQUEST = "CONTACT_DOWNLOAD_REQUEST";
export const CONTACT_DOWNLOAD_SUCCESS = "CONTACT_DOWNLOAD_SUCCESS";
export const CONTACT_DOWNLOAD_FAIL = "CONTACT_DOWNLOAD_FAIL";
export const CONTACT_DOWNLOAD_RESET = "CONTACT_DOWNLOAD_RESET";

export const CONTACT_UPLOAD_BEGIN = "CONTACT_UPLOAD_BEGIN";
export const CONTACT_UPLOAD_REQUEST = "CONTACT_UPLOAD_REQUEST";
export const CONTACT_UPLOAD_SUCCESS = "CONTACT_UPLOAD_SUCCESS";
export const CONTACT_UPLOAD_FAIL = "CONTACT_UPLOAD_FAIL";
export const CONTACT_UPLOAD_END = "CONTACT_UPLOAD_END";
export const CONTACT_UPLOAD_RESET = "CONTACT_UPLOAD_RESET";

export const CONTACT_CREATE_COMMUNICATION_ACTION_REQUEST =
  "CONTACT_CREATE_COMMUNICATION_ACTION_REQUEST";
export const CONTACT_CREATE_COMMUNICATION_ACTION_SUCCESS =
  "CONTACT_CREATE_COMMUNICATION_ACTION_SUCCESS";
export const CONTACT_CREATE_COMMUNICATION_ACTION_FAIL =
  "CONTACT_CREATE_COMMUNICATION_ACTION_FAIL";
export const CONTACT_CREATE_COMMUNICATION_ACTION_RESET =
  "CONTACT_CREATE_COMMUNICATION_ACTION_RESET";
