// Library imports
import { Button } from "@blueprintjs/core";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

// Main function
function CopyButton({ content, icon, intent, large, keepHtml, text }) {
  const copy = async () => {
    const htmlString = renderToStaticMarkup(content);

    if (keepHtml) {
      await navigator.clipboard.writeText(htmlString);
    } else {
      const tempDiv = document.createElement("div");

      const isHtml = htmlString.indexOf("<body>") !== -1;

      if (isHtml) {
        const bodyContentStart = htmlString.indexOf("<body>") + "<body>".length;
        const bodyContentEnd = htmlString.indexOf("</body>");
        const bodyContent = htmlString.substring(
          bodyContentStart,
          bodyContentEnd
        );

        tempDiv.innerHTML = bodyContent;
      } else {
        tempDiv.innerHTML = htmlString;
      }

      //const text = tempDiv.textContent.replace(/\n\s*\n/g, "\n").trim();
      const text = tempDiv.textContent.replace(/\n\s*/g, "\n").trim();

      await navigator.clipboard.writeText(text);
    }
  };

  // JSX UI code
  return (
    <Button
      disabled={!content}
      icon={icon ? icon : "clipboard"}
      intent={intent}
      minimal={large ? false : true}
      onClick={copy}
      small={large ? false : true}
      text={text}
    />
  );
}

export default CopyButton;
