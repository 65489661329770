// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import { Button, Card, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import LocationInfo from "../components/LocationInfo";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import OpeningAgeCard from "../components/OpeningAgeCard";
import OpeningFillRateCard from "../components/OpeningFillRateCard";
import OpeningStatusCard from "../components/OpeningStatusCard";
import RoleInfo from "../components/RoleInfo";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Location
import { listLocationsAction } from "../actions/locationActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Opening
import {
  createOpeningAction,
  listOpeningsAction,
  resetCreateOpeningAction,
  resetListOpeningDetailsAction,
} from "../actions/openingActions";

// Role
import { listRolesAction } from "../actions/roleActions";

// Main function
function OpeningListScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables

  // Location
  const locationList = useSelector((state) => state.locationList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Opening
  const openingCreate = useSelector((state) => state.openingCreate);

  const openingList = useSelector((state) => state.openingList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Role
  const roleList = useSelector((state) => state.roleList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Opening
  const [addOpeningDrawerIsOpen, setAddOpeningDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Opening
  const openingQueryDefault = {
    closedEnd: "",
    closedStart: "",
    location: "",
    manager: "",
    openedEnd: "",
    openedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
    status: "",
    value: "",
  };
  const [openingQuery, setOpeningQuery] = useState({
    ...openingQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Form variables

  // Opening
  const openingDefault = {
    employment: "Permanent",
    location: "",
    locationName: "",
    name: "",
    notes: "",
    role: "",
    roleName: "",
  };
  const [opening, setOpening] = useState({
    ...openingDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Opening
    setOpening({
      ...openingDefault,
    });

    // Query variables
    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Location
    setLocationQuery({
      ...locationQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Opening
    setOpeningQuery({
      ...openingQueryDefault,
    });

    // Role
    setRoleQuery({
      ...roleQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listLocationsAction({
            ...locationQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter openings
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listOpeningsAction({
            ...openingQuery,
            closedEnd: openingQuery.closedEnd
              ? getDjangoDate(openingQuery.closedEnd)
              : getDjangoDate(openingQueryDefault.closedEnd),
            closedStart: openingQuery.closedStart
              ? getDjangoDate(openingQuery.closedStart)
              : getDjangoDate(openingQueryDefault.closedStart),
            openedEnd: openingQuery.openedEnd
              ? getDjangoDate(openingQuery.openedEnd)
              : getDjangoDate(openingQueryDefault.openedEnd),
            openedStart: openingQuery.openedStart
              ? getDjangoDate(openingQuery.openedStart)
              : getDjangoDate(openingQueryDefault.openedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [openingQuery]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRolesAction({
            ...roleQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Form submission
  // Add opening
  const addOpening = () => {
    // Prepare data
    const opening_data = new FormData();

    opening.employment
      ? opening_data.append("employment", opening.employment)
      : opening_data.append("employment", openingDefault.employment);

    opening.location
      ? opening_data.append("location", opening.location)
      : opening_data.append("location", openingDefault.location);

    opening.name
      ? opening_data.append("name", opening.name)
      : opening_data.append("name", openingDefault.name);

    opening.notes
      ? opening_data.append("notes", opening.notes)
      : opening_data.append("notes", openingDefault.notes);

    opening_data.append("organisation", activeOrganisation.id);

    opening.role
      ? opening_data.append("role", opening.role)
      : opening_data.append("role", openingDefault.role);

    opening.value
      ? opening_data.append("value", opening.value)
      : opening_data.append("value", openingDefault.value);

    // Dispatch action
    dispatch(createOpeningAction(opening_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* New opening form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter opening details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Opening name */}
                <FormDrawerTextField2
                  error={openingCreate.error}
                  fieldName="name"
                  helperText="Leave blank to use the role name"
                  icon="label"
                  label="Opening name"
                  placeholder="Enter the opening's name"
                  setValueObject={setOpening}
                  setValueVariable="name"
                  value={opening.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Role */}
                <FormDrawerSelectField2
                  error={openingCreate.error}
                  fieldName="role"
                  helperText="You can choose from your organisation's standard roles"
                  icon="hat"
                  info={
                    <RoleInfo roleId={opening.role} query={roleQueryDefault} />
                  }
                  items={roleList.roles}
                  label="Role"
                  labelInfo="(required)"
                  loading={roleList.loading}
                  placeholder="Select a role"
                  query={roleQuery.searchString}
                  setQueryObject={setRoleQuery}
                  setQueryVariable="searchString"
                  setValueObject={setOpening}
                  setValueIdVariable="role"
                  setValueNameVariable="roleName"
                  showInfo={opening.role}
                  value={opening.roleName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Budget */}
                <FormDrawerNumericField2
                  error={openingCreate.error}
                  fieldName="value"
                  helperText="Total annual remuneration package"
                  label="Budget"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter total remuneration"
                  setValueObject={setOpening}
                  setValueVariable="value"
                  stepSize={10000}
                  value={opening.value}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "40%",
                }}
              >
                {/* Location */}
                <FormDrawerSelectField2
                  error={openingCreate.error}
                  fieldName="location"
                  helperText="You can choose from your organisation's locations"
                  icon="area-of-interest"
                  info={
                    <LocationInfo
                      locationId={opening.location}
                      query={locationQueryDefault}
                    />
                  }
                  items={locationList.locations}
                  label="Location"
                  loading={locationList.loading}
                  noOptionOnClick
                  placeholder="Select a location"
                  query={locationQuery.searchString}
                  setQueryObject={setLocationQuery}
                  setQueryVariable="searchString"
                  setValueObject={setOpening}
                  setValueIdVariable="location"
                  setValueNameVariable="locationName"
                  showInfo={opening.location}
                  value={opening.locationName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "20%" }}>
                {/* Employment type */}
                <FormDrawerSelectSimpleField3
                  error={openingCreate.error}
                  fieldName="employment"
                  label="Employment type"
                  options={[
                    { id: 1, icon: "full-circle", value: "Permanent" },
                    {
                      id: 2,
                      icon: "ring",
                      value: "Contract",
                    },
                    { id: 3, icon: "circle", value: "Casual" },
                  ]}
                  setValueObject={setOpening}
                  setValueVariable="employment"
                  value={opening.employment}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={openingCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the opening"
              setValueObject={setOpening}
              setValueVariable="notes"
              value={opening.notes}
            />
          </Card>
        }
        error={openingCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add opening"
            module="Openings"
            view="List"
          />
        }
        helpTitle="Creating an opening"
        icon="edit"
        isOpen={addOpeningDrawerIsOpen}
        onSave={addOpening}
        refreshList={() => {
          dispatch(
            listOpeningsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateOpeningAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingCreate.success}
        saveSuccessMessage="Opening created."
        saving={openingCreate.loading}
        setIsOpen={setAddOpeningDrawerIsOpen}
        title="Add opening"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddOpeningDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Openings"
      />

      {/* Error alerts */}
      {/* Locations loading error */}
      {locationList.error && (
        <ErrorAlert message="We couldn't load the location list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Openings loading error */}
      {openingList.error && (
        <ErrorAlert message="We couldn't load the opening list. Try refreshing the page." />
      )}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the role list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Openings"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Data cards */}
      <div style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}>
        <DataCardContainer
          children={
            <>
              {/* Age */}
              <OpeningAgeCard openingList={openingList} />

              {/* Status */}
              <OpeningStatusCard fill openingList={openingList} />

              {/* Fill rate */}
              <OpeningFillRateCard
                openingList={openingList}
                openingQuery={openingQuery}
              />
            </>
          }
          fill
        />
      </div>

      {/* Data table */}
      <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
        <TableContainer2
          body={openingList.openings.map((opening) => {
            return (
              <tr
                key={opening.id}
                onClick={() => {
                  if (opening.user_has_permission) {
                    dispatch(resetListOpeningDetailsAction());

                    navigate(`/openings/${opening.id}`);
                  } else {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "danger",
                      message: "You do not have access to this opening.",
                    });
                  }
                }}
              >
                {/* Opening */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    tagIntent={
                      !opening.user_has_permission
                        ? "danger"
                        : opening.user_access_status === "view"
                        ? "primary"
                        : undefined
                    }
                    tagText={
                      !opening.user_has_permission
                        ? "No access"
                        : opening.user_access_status === "view"
                        ? "View only"
                        : undefined
                    }
                    text={opening.name}
                  />
                </td>

                {/* Role */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    text={
                      opening.role_data ? opening.role_data.name : undefined
                    }
                    url={opening.role ? `/roles/${opening.role}` : undefined}
                  />
                </td>

                {/* Location */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText text={opening.location_name} />
                </td>

                {/* Opened */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableDate date={opening.opened} />
                </td>

                {/* Closed */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableDate date={opening.closed} />
                </td>

                {/* Manager */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    email={opening.manager_name}
                    image={opening.manager_profile_photo}
                    showImage
                  />
                </td>

                {/* Status */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableTag
                    intent={
                      opening.status === "Filled"
                        ? "success"
                        : opening.status === "Interviewing"
                        ? "warning"
                        : "primary"
                    }
                    value={opening.status}
                  />
                </td>
              </tr>
            );
          })}
          clearFiltersDisabled={
            !openingQuery.closedEnd &&
            !openingQuery.closedStart &&
            !openingQuery.location &&
            !openingQuery.manager &&
            !openingQuery.openedEnd &&
            !openingQuery.openedStart &&
            !openingQuery.role &&
            !openingQuery.searchString &&
            !openingQuery.status
          }
          count={openingList.count}
          head={
            <tr>
              {/* Opening */}
              <TableColumnHeadSearch
                queryObject={contactQuery}
                queryVariable="searchString"
                setQueryObject={setOpeningQuery}
                setQueryVariable="searchString"
                title="Opening"
                width="200px"
              />

              {/* Role */}
              <TableColumnHeadSelect2
                filterObject={openingQuery}
                filterVariable="role"
                items={roleList.roles}
                setFilterObject={setOpeningQuery}
                setQueryObject={setRoleQuery}
                setQueryVariable="searchString"
                title="Role"
                width="200px"
              />

              {/* Location */}
              <TableColumnHeadSelect2
                filterObject={openingQuery}
                filterVariable="location"
                items={locationList.locations}
                setFilterObject={setOpeningQuery}
                setQueryObject={setLocationQuery}
                setQueryVariable="searchString"
                title="Location"
                width="200px"
              />

              {/* Opened */}
              <TableColumnHeadDate
                endDateVariable="openedEnd"
                queryObject={openingQuery}
                setQueryObject={setOpeningQuery}
                shortRange
                startDateVariable="openedStart"
                title="Opened"
                width="125px"
              />

              {/* Closed */}
              <TableColumnHeadDate
                endDateVariable="closedEnd"
                queryObject={openingQuery}
                setQueryObject={setOpeningQuery}
                shortRange
                startDateVariable="closedStart"
                title="Closed"
                width="125px"
              />

              {/* Manager */}
              <TableColumnHeadSelect2
                filterObject={openingQuery}
                filterVariable="manager"
                items={managerList.managers}
                setFilterObject={setOpeningQuery}
                setQueryObject={setManagerQuery}
                setQueryVariable="searchString"
                title="Manager"
                width="200px"
              />

              {/* Status */}
              <TableColumnHeadSelectSimple
                filterObject={openingQuery}
                filterVariable="status"
                options={[
                  {
                    display: "All",
                    icon: "filter-remove",
                    value: "",
                  },
                  {
                    display: "Interviewing",
                    icon: "refresh",
                    value: "Interviewing",
                  },
                  {
                    display: "Filled",
                    icon: "confirm",
                    value: "Filled",
                  },
                  {
                    display: "On-hold",
                    icon: "remove",
                    value: "On-hold",
                  },
                ]}
                setFilterObject={setOpeningQuery}
                title="Status"
                width="125px"
              />
            </tr>
          }
          interactive
          loading={openingList.loading}
          onClearFilters={() => {
            setOpeningQuery({
              ...openingQueryDefault,
            });
          }}
          page={openingQuery.page}
          setPageObject={setOpeningQuery}
          setPageVariable="page"
        />
      </div>
    </div>
  );
}

export default OpeningListScreen;
