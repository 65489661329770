// Library imports
import { Classes, Tag, Text } from "@blueprintjs/core";
import React from "react";

// Component imports
import NumericValue from "../components/NumericValue";
import PercentageValue from "../components/PercentageValue";

// Main function
function TableNumber({
  fillTag,
  invertColor,
  minimal,
  noDecimals,
  number,
  percentage,
  tagIntent,
  tagNumber,
  tagPercentage,
  tagText,
}) {
  // JSX UI code
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: minimal ? "end" : "space-between",
        width: "100%",
      }}
    >
      {tagNumber ? (
        <Tag
          intent={tagIntent ? tagIntent : "primary"}
          minimal={fillTag ? false : true}
          style={{
            fontSize: minimal ? "10px" : undefined,
            marginRight: "5px",
          }}
        >
          {tagPercentage ? (
            <PercentageValue percentage={tagNumber} />
          ) : (
            <NumericValue noDecimals={noDecimals} number={tagNumber} />
          )}
        </Tag>
      ) : undefined}

      {tagText && (
        <Tag
          intent={tagIntent}
          minimal
          style={{ fontSize: minimal ? "10px" : undefined, marginRight: "5px" }}
        >
          {tagText}
        </Tag>
      )}

      <Text
        className={
          minimal
            ? [Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")
            : undefined
        }
        ellipsize
        style={{
          display: "flex",
          justifyContent: "end",
          width: tagNumber || tagText ? undefined : "100%",
        }}
      >
        {percentage ? (
          <PercentageValue invertColor={invertColor} percentage={number} />
        ) : (
          <NumericValue
            invertColor={invertColor}
            noDecimals={noDecimals}
            number={number}
          />
        )}
      </Text>
    </div>
  );
}

export default TableNumber;
