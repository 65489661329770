// Library imports
import { Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import ClientAccountPlanFacts from "../components/ClientAccountPlanFacts";
import DateValue from "../components/DateValue";
import DetailDrawerContainer from "../components/DetailDrawerContainer";
import EngagementBaselineFacts from "../components/EngagementBaselineFacts";
import EngagementCloseFacts from "../components/EngagementCloseFacts";
import InvoiceFacts from "../components/InvoiceFacts";
import NameValue from "../components/NameValue";
import NumericValue from "../components/NumericValue";
import ProposalQualificationFacts from "../components/ProposalQualificationFacts";
import ProposalSubmissionFacts from "../components/ProposalSubmissionFacts";
import StaffExpenseFacts from "../components/StaffExpenseFacts";
import StaffLeaveRequestFacts from "../components/StaffLeaveRequestFacts";
import StaffRecruitmentFacts from "../components/StaffRecruitmentFacts";
import StaffTimesheetFacts from "../components/StaffTimesheetFacts";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function ApprovalDetailDrawer({
  approvalRequestDetails,
  organisationDetails,
  setIsOpen,
  isOpen,
}) {
  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Generate approval request decision helper text
  const generateApprovalRequestDecisionHelperText = () => {
    switch (approvalRequestDetails.approval_request.decision_right) {
      case "Client account plan":
        return "Approve account plan";

      case "Client survey":
        return "Authorise survey used to collect feedback from client stakeholders";

      case "Engagement baseline":
        return "Approve engagement baseline";

      case "Engagement close":
        return "Authorise the team to close the engagement";

      case "Engagement deliverable":
        return "Authorise the team to submit engagement deliverable";

      case "Invoice":
        return "Approve invoice";

      case "Proposal commercials":
        return "Approve the proposal's legal terms and pricing";

      case "Proposal qualification":
        return "Decide whether to commit resources to the proposal";

      case "Proposal solution":
        return "Approve the proposal's services and team members";

      case "Proposal strategy":
        return "Approve the proposal's win themes";

      case "Proposal submission":
        return "Authorise the team to submit the proposal";

      case "Staff expense":
        return "Approve staff expense";

      case "Staff leave":
        return "Approve staff leave";

      case "Staff recruitment":
        return "Approve the hiring of staff";

      default:
        return "Approve staff timesheet";
    }
  };

  // Generate approval request facts
  const generateApprovalRequestFacts = () => {
    switch (approvalRequestDetails.approval_request.decision_right) {
      case "Client account plan":
        return (
          <ClientAccountPlanFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Client survey":
        return <div>TBD</div>;

      case "Engagement baseline":
        return (
          <EngagementBaselineFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Engagement close":
        return (
          <EngagementCloseFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Engagement deliverable":
        return <div>TBD</div>;

      case "Invoice":
        return (
          <InvoiceFacts facts={approvalRequestDetails.approval_request.facts} />
        );

      case "Proposal qualification":
        return (
          <ProposalQualificationFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Proposal commercials":
        return <div>TBD</div>;

      case "Proposal solution":
        return <div>TBD</div>;

      case "Proposal strategy":
        return <div>TBD</div>;

      case "Proposal submission":
        return (
          <ProposalSubmissionFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Staff expense":
        return (
          <StaffExpenseFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Staff leave":
        return (
          <StaffLeaveRequestFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      case "Staff recruitment":
        return (
          <StaffRecruitmentFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );

      default:
        return (
          <StaffTimesheetFacts
            facts={approvalRequestDetails.approval_request.facts}
          />
        );
    }
  };

  // Generate approval request value helper text
  const generateApprovalRequestValueHelperText = () => {
    switch (approvalRequestDetails.approval_request.decision_right) {
      case "Client account plan":
        return "Planned annual revenue from the client";

      case "Client survey":
        return "Planned annual revenue from the client";

      case "Engagement baseline":
        return "Value of engagement";

      case "Engagement close":
        return "Value of engagement";

      case "Engagement deliverable":
        return "Value of engagement";

      case "Invoice":
        return "Value of invoice";

      case "Proposal commercials":
        return "Estimated value of proposal";

      case "Proposal qualification":
        return "Estimated value of proposal";

      case "Proposal solution":
        return "Estimated value of proposal";

      case "Proposal strategy":
        return "Estimated value of proposal";

      case "Proposal submission":
        return "Estimated value of proposal";

      case "Staff expense":
        return "Value of expense";

      case "Staff leave":
        return "Time off requested";

      case "Staff recruitment":
        return "Total annual remuneration package";

      default:
        return "Time booked";
    }
  };

  // JSX UI code
  return (
    <DetailDrawerContainer
      body={
        <div style={{ margin: "20px 10px 30px 10px" }}>
          {/* Decision */}
          <TabSectionField
            helperText={generateApprovalRequestDecisionHelperText()}
            label="Decision"
            value={approvalRequestDetails.approval_request.decision_right}
          />

          {/* Value */}
          <TabSectionField
            helperText={generateApprovalRequestValueHelperText()}
            label="Value"
            value={
              approvalRequestDetails.approval_request.decision_right ===
                "Staff leave" ||
              approvalRequestDetails.approval_request.decision_right ===
                "Staff timesheet" ? (
                `${
                  convertMinutesToHoursAndMinutes(
                    approvalRequestDetails.approval_request.value
                  ).split(":")[0]
                }h ${
                  convertMinutesToHoursAndMinutes(
                    approvalRequestDetails.approval_request.value
                  ).split(":")[1]
                }m`
              ) : (
                <NumericValue
                  currency={
                    organisationDetails
                      ? organisationDetails.organisation.currency_short
                      : activeOrganisation.currency
                  }
                  number={approvalRequestDetails.approval_request.value}
                />
              )
            }
          />

          {/* Approver */}
          <TabSectionField
            label="Approver"
            value={
              <NameValue
                email={approvalRequestDetails.approval_request.approver_name}
                image={
                  approvalRequestDetails.approval_request.approver_profile_photo
                }
              />
            }
          />

          {/* Reviewed */}
          <TabSectionField
            label="Reviewed"
            value={
              <DateValue
                date={
                  approvalRequestDetails.approval_request.response_data
                    ? approvalRequestDetails.approval_request.response_data
                        .reviewed
                    : undefined
                }
              />
            }
          />

          {/* Outcome */}
          <TabSectionField
            helperText={
              approvalRequestDetails.approval_request.response_data
                ? approvalRequestDetails.approval_request.response_data
                    .outcome === "Reversed"
                  ? "This decision was approved and subsequently reversed"
                  : undefined
                : undefined
            }
            label="Outcome"
            value={
              <Tag
                intent={
                  approvalRequestDetails.approval_request.response_data
                    ? approvalRequestDetails.approval_request.response_data
                        .outcome === "Approved"
                      ? "success"
                      : approvalRequestDetails.approval_request.response_data
                          .outcome === "Denied"
                      ? "danger"
                      : approvalRequestDetails.approval_request.response_data
                          .outcome === "Reversed"
                      ? "warning"
                      : "none"
                    : undefined
                }
                large
                minimal
                style={{
                  minWidth: "100px",
                  textAlign: "center",
                }}
              >
                {approvalRequestDetails.approval_request.response_data
                  ? approvalRequestDetails.approval_request.response_data
                      .outcome
                  : undefined}
              </Tag>
            }
          />

          {/* Reversal reason */}
          {approvalRequestDetails.approval_request.response_data ? (
            approvalRequestDetails.approval_request.response_data.outcome ===
            "Reversed" ? (
              <TabSectionField
                label="Reversal reason"
                value={
                  <TabSectionFieldCard
                    noBackground
                    text={
                      <>
                        Reversed by{" "}
                        <strong>
                          {
                            approvalRequestDetails.approval_request
                              .response_data.reversed_by
                          }
                        </strong>{" "}
                        on{" "}
                        {new Date(
                          approvalRequestDetails.approval_request.response_data.reversed_on
                        ).toLocaleString("en-US", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        })}
                        {approvalRequestDetails.approval_request.response_data
                          .reversed_comments
                          ? ", with the following comments: " +
                            approvalRequestDetails.approval_request
                              .response_data.reversed_comments
                          : "."}
                      </>
                    }
                  />
                }
              />
            ) : undefined
          ) : undefined}

          {/* Approver comments */}
          <TabSectionField
            label="Approver comments"
            value={
              <TabSectionFieldCard
                noBackground
                text={
                  approvalRequestDetails.approval_request.response_data
                    ? approvalRequestDetails.approval_request.response_data
                        .comments
                    : undefined
                }
              />
            }
          />

          {/* Facts */}
          <TabSectionField
            label="Facts"
            value={generateApprovalRequestFacts()}
          />

          {/* Requestor */}
          <TabSectionField
            label="Requestor"
            value={
              <NameValue
                email={approvalRequestDetails.approval_request.requestor_name}
                image={
                  approvalRequestDetails.approval_request
                    .requestor_profile_photo
                }
              />
            }
          />

          {/* Submitted */}
          <TabSectionField
            label="Submitted"
            value={
              <DateValue
                date={approvalRequestDetails.approval_request.submitted}
              />
            }
          />

          {/* Status */}
          <TabSectionField
            label="Status"
            value={
              <Tag
                intent={
                  approvalRequestDetails.approval_request.status === "Completed"
                    ? "primary"
                    : approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    ? "warning"
                    : undefined
                }
                large
                minimal
                style={{
                  minWidth: "100px",
                  textAlign: "center",
                }}
              >
                {approvalRequestDetails.approval_request.status}
              </Tag>
            }
          />

          {/* Requestor comments */}
          <TabSectionField
            bottom
            label="Requestor comments"
            value={
              <TabSectionFieldCard
                noBackground
                text={approvalRequestDetails.approval_request.comments}
              />
            }
          />
        </div>
      }
      icon="book"
      isOpen={isOpen}
      loading={approvalRequestDetails.loading}
      setIsOpen={setIsOpen}
      size="large"
      title="Approval request details"
    />
  );
}

export default ApprovalDetailDrawer;
