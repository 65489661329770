export const WORKFLOW_LIST_REQUEST = "WORKFLOW_LIST_REQUEST";
export const WORKFLOW_LIST_SUCCESS = "WORKFLOW_LIST_SUCCESS";
export const WORKFLOW_LIST_FAIL = "WORKFLOW_LIST_FAIL";
export const WORKFLOW_LIST_RESET = "WORKFLOW_LIST_RESET";

export const WORKFLOW_CREATE_REQUEST = "WORKFLOW_CREATE_REQUEST";
export const WORKFLOW_CREATE_SUCCESS = "WORKFLOW_CREATE_SUCCESS";
export const WORKFLOW_CREATE_FAIL = "WORKFLOW_CREATE_FAIL";
export const WORKFLOW_CREATE_RESET = "WORKFLOW_CREATE_RESET";

export const WORKFLOW_DETAILS_REQUEST = "WORKFLOW_DETAILS_REQUEST";
export const WORKFLOW_DETAILS_SUCCESS = "WORKFLOW_DETAILS_SUCCESS";
export const WORKFLOW_DETAILS_FAIL = "WORKFLOW_DETAILS_FAIL";
export const WORKFLOW_DETAILS_RESET = "WORKFLOW_DETAILS_RESET";

export const WORKFLOW_UPDATE_REQUEST = "WORKFLOW_UPDATE_REQUEST";
export const WORKFLOW_UPDATE_SUCCESS = "WORKFLOW_UPDATE_SUCCESS";
export const WORKFLOW_UPDATE_FAIL = "WORKFLOW_UPDATE_FAIL";
export const WORKFLOW_UPDATE_RESET = "WORKFLOW_UPDATE_RESET";

export const WORKFLOW_DELETE_REQUEST = "WORKFLOW_DELETE_REQUEST";
export const WORKFLOW_DELETE_SUCCESS = "WORKFLOW_DELETE_SUCCESS";
export const WORKFLOW_DELETE_FAIL = "WORKFLOW_DELETE_FAIL";
export const WORKFLOW_DELETE_RESET = "WORKFLOW_DELETE_RESET";
