// Constant imports
import {
  MEMBERSHIP_LIST_REQUEST,
  MEMBERSHIP_LIST_SUCCESS,
  MEMBERSHIP_LIST_FAIL,
  MEMBERSHIP_LIST_RESET,
  MEMBERSHIP_CREATE_REQUEST,
  MEMBERSHIP_CREATE_SUCCESS,
  MEMBERSHIP_CREATE_FAIL,
  MEMBERSHIP_CREATE_RESET,
  MEMBERSHIP_DETAILS_REQUEST,
  MEMBERSHIP_DETAILS_SUCCESS,
  MEMBERSHIP_DETAILS_FAIL,
  MEMBERSHIP_DETAILS_RESET,
  MEMBERSHIP_UPDATE_REQUEST,
  MEMBERSHIP_UPDATE_SUCCESS,
  MEMBERSHIP_UPDATE_FAIL,
  MEMBERSHIP_UPDATE_RESET,
  MEMBERSHIP_DELETE_REQUEST,
  MEMBERSHIP_DELETE_SUCCESS,
  MEMBERSHIP_DELETE_FAIL,
  MEMBERSHIP_DELETE_RESET,
} from "../constants/membershipConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listMembershipsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBERSHIP_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getMemberships(params, token);

    dispatch({
      type: MEMBERSHIP_LIST_SUCCESS,

      payload: { count: data.count, memberships: data.results },
    });
  } catch (error) {
    dispatch({
      type: MEMBERSHIP_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListMembershipsAction = () => async (dispatch) => {
  dispatch({ type: MEMBERSHIP_LIST_RESET });
};

// Create
export const createMembershipAction =
  (membership) => async (dispatch, getState) => {
    try {
      dispatch({ type: MEMBERSHIP_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createMembership(
        token,
        membership
      );

      dispatch({
        type: MEMBERSHIP_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEMBERSHIP_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateMembershipAction = () => async (dispatch) => {
  dispatch({ type: MEMBERSHIP_CREATE_RESET });
};

// List details
export const listMembershipDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MEMBERSHIP_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMembership(id, token);

      dispatch({
        type: MEMBERSHIP_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEMBERSHIP_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListMembershipDetailsAction = () => async (dispatch) => {
  dispatch({ type: MEMBERSHIP_DETAILS_RESET });
};

// Update
export const updateMembershipAction =
  (id, membership) => async (dispatch, getState) => {
    try {
      dispatch({ type: MEMBERSHIP_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateMembership(
        id,
        token,
        membership
      );

      dispatch({
        type: MEMBERSHIP_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEMBERSHIP_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateMembershipAction = () => async (dispatch) => {
  dispatch({ type: MEMBERSHIP_UPDATE_RESET });
};

// Delete
export const deleteMembershipAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MEMBERSHIP_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteMembership(id, token);

    dispatch({
      type: MEMBERSHIP_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEMBERSHIP_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteMembershipAction = () => async (dispatch) => {
  dispatch({ type: MEMBERSHIP_DELETE_RESET });
};
