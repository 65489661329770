// Library imports
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import { AppToaster } from "../components/AppToaster";

// Redux action imports
// Payment
import {
  createCheckoutAction,
  resetCreateCheckoutAction,
} from "../actions/paymentActions";

// Main function
function BuyButton({
  action,
  disabled,
  icon,
  intent,
  large,
  text,
  organisationId,
  style,
}) {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Payment
  const checkoutCreate = useSelector((state) => state.checkoutCreate);

  // Create checkout session
  const [loading, setLoading] = useState(false);

  const createCheckoutSession = () => {
    if (organisationId) {
      setLoading(true);

      // Prepare data
      const data = new FormData();

      action && data.append("action", action);

      data.append("organisation", organisationId);

      // Dispatch action
      dispatch(createCheckoutAction(data));
    }
  };

  // Redirect to checkout page
  useEffect(() => {
    if (loading) {
      if (checkoutCreate.success) {
        window.open(checkoutCreate.checkout, "_self");

        dispatch(resetCreateCheckoutAction());

        setLoading(false);
      } else if (checkoutCreate.error) {
        AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message:
            "We couldn't redirect you to the checkout page. Please try again.",
        });

        dispatch(resetCreateCheckoutAction());

        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [checkoutCreate.loading]);

  return (
    <Button
      disabled={disabled || loading}
      icon={icon}
      intent={intent}
      large={large}
      loading={loading}
      onClick={createCheckoutSession}
      style={style}
      text={text}
    />
  );
}

export default BuyButton;
