// Constant imports
import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAIL,
  ROLE_LIST_RESET,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_CREATE_FAIL,
  ROLE_CREATE_RESET,
  ROLE_DETAILS_REQUEST,
  ROLE_DETAILS_SUCCESS,
  ROLE_DETAILS_FAIL,
  ROLE_DETAILS_RESET,
  ROLE_UPDATE_REQUEST,
  ROLE_UPDATE_SUCCESS,
  ROLE_UPDATE_FAIL,
  ROLE_UPDATE_RESET,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAIL,
  ROLE_DELETE_RESET,
} from "../constants/roleConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listRolesAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLE_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getRoles(params, token);

    dispatch({
      type: ROLE_LIST_SUCCESS,

      payload: {
        averageMarginPercentageOnProposalsCancelled:
          data.average_margin_percentage_on_proposals_cancelled.result,
        averageMarginPercentageOnProposalsLost:
          data.average_margin_percentage_on_proposals_lost.result,
        averageMarginPercentageOnProposalsWon:
          data.average_margin_percentage_on_proposals_won.result,
        totalEffortOnEngagementsResourced:
          data.total_effort_on_engagements_resourced.result,
        totalEffortOnEngagementsNotResourced:
          data.total_effort_on_engagements_not_resourced.result,
        totalEffortOnProposalsResourced:
          data.total_effort_on_proposals_resourced.result,
        totalEffortOnProposalsNotResourced:
          data.total_effort_on_proposals_not_resourced.result,
        totalFeesOnProposalsCancelled:
          data.total_fees_on_proposals_cancelled.result,
        totalFeesOnProposalsLost: data.total_fees_on_proposals_lost.result,
        totalFeesOnProposalsWon: data.total_fees_on_proposals_won.result,
        count: data.count,
        roles: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: ROLE_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListRolesAction = () => async (dispatch) => {
  dispatch({ type: ROLE_LIST_RESET });
};

// Create
export const createRoleAction = (role) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLE_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createRole(token, role);

    dispatch({
      type: ROLE_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLE_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateRoleAction = () => async (dispatch) => {
  dispatch({ type: ROLE_CREATE_RESET });
};

// List details
export const listRoleDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLE_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getRole(id, token);

    dispatch({
      type: ROLE_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLE_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListRoleDetailsAction = () => async (dispatch) => {
  dispatch({ type: ROLE_DETAILS_RESET });
};

// Update
export const updateRoleAction = (id, role) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLE_UPDATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.updateRole(id, token, role);

    dispatch({
      type: ROLE_UPDATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLE_UPDATE_FAIL,

      payload: error,
    });
  }
};

// Reset update
export const resetUpdateRoleAction = () => async (dispatch) => {
  dispatch({ type: ROLE_UPDATE_RESET });
};

// Delete
export const deleteRoleAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ROLE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteRole(id, token);

    dispatch({
      type: ROLE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROLE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteRoleAction = () => async (dispatch) => {
  dispatch({ type: ROLE_DELETE_RESET });
};
