// Constant imports
import {
  STAFF_PERMISSION_LIST_REQUEST,
  STAFF_PERMISSION_LIST_SUCCESS,
  STAFF_PERMISSION_LIST_FAIL,
  STAFF_PERMISSION_LIST_RESET, //new
  STAFF_PERMISSION_CREATE_REQUEST,
  STAFF_PERMISSION_CREATE_SUCCESS,
  STAFF_PERMISSION_CREATE_FAIL,
  STAFF_PERMISSION_CREATE_RESET,
  STAFF_PERMISSION_DETAILS_REQUEST,
  STAFF_PERMISSION_DETAILS_SUCCESS,
  STAFF_PERMISSION_DETAILS_FAIL,
  STAFF_PERMISSION_DETAILS_RESET, //new
  STAFF_PERMISSION_UPDATE_REQUEST,
  STAFF_PERMISSION_UPDATE_SUCCESS,
  STAFF_PERMISSION_UPDATE_FAIL,
  STAFF_PERMISSION_UPDATE_RESET,
  STAFF_PERMISSION_DELETE_REQUEST,
  STAFF_PERMISSION_DELETE_SUCCESS,
  STAFF_PERMISSION_DELETE_FAIL,
  STAFF_PERMISSION_DELETE_RESET,
} from "../constants/staffPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffPermissions(
        params,
        token
      );

      dispatch({
        type: STAFF_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          staff_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: STAFF_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListStaffPermissionsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_PERMISSION_LIST_RESET });
};

// Create
export const createStaffPermissionAction =
  (staff_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createStaffPermission(
        token,
        staff_permission
      );

      dispatch({
        type: STAFF_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateStaffPermissionAction = () => async (dispatch) => {
  dispatch({ type: STAFF_PERMISSION_CREATE_RESET });
};

// List details
export const listStaffPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffPermission(id, token);

      dispatch({
        type: STAFF_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListStaffPermissionDetailsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_PERMISSION_DETAILS_RESET });
};

// Update
export const updateStaffPermissionAction =
  (id, staff_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateStaffPermission(
        id,
        token,
        staff_permission
      );

      dispatch({
        type: STAFF_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateStaffPermissionAction = () => async (dispatch) => {
  dispatch({ type: STAFF_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteStaffPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteStaffPermission(id, token);

      dispatch({
        type: STAFF_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteStaffPermissionAction = () => async (dispatch) => {
  dispatch({ type: STAFF_PERMISSION_DELETE_RESET });
};
