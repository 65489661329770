// Library imports
import { Callout, Switch } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Main function
function FormDrawerStaffFreeTimeSearch2({
  customiseDays,
  detail,
  endDate,
  minutes,
  setStaffQueryObject,
  startDate,
}) {
  // Search staff
  const [filterStaff, setFilterStaff] = useState(false);

  useEffect(() => {
    if (endDate && filterStaff && minutes && startDate) {
      let query = {
        detail: customiseDays ? detail : [],
        end: getDjangoDate(endDate),
        minutes: minutes,
        start: getDjangoDate(startDate),
      };

      let jsonStr = JSON.stringify(query);

      let base64String = btoa(jsonStr);

      setStaffQueryObject((prevState) => {
        return {
          ...prevState,
          freeTime: base64String,
        };
      });
    } else {
      setStaffQueryObject((prevState) => {
        return {
          ...prevState,
          freeTime: "",
        };
      });
    }
  }, [
    customiseDays,
    detail,
    endDate,
    filterStaff,
    minutes,
    setStaffQueryObject,
    startDate,
  ]);

  // JSX UI code
  return (
    <div>
      <Callout style={{ marginBottom: "15px", paddingBottom: "5px" }}>
        <Switch
          checked={filterStaff}
          label="Filter staff based on these dates"
          onChange={() => setFilterStaff(!filterStaff)}
        />
      </Callout>
    </div>
  );
}

export default FormDrawerStaffFreeTimeSearch2;
