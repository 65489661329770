// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  ButtonGroup,
  Card,
  Callout,
  Classes,
  Colors,
  ControlGroup,
  Dialog,
  Divider,
  H5,
  Icon,
  InputGroup,
  Menu,
  MenuItem,
  NonIdealState,
  Switch,
  Tab,
  Tabs,
  Tag,
  Text,
} from "@blueprintjs/core";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import ExcelJS from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Typewriter from "typewriter-effect";
import useWebSocket from "react-use-websocket";

// Component imports
import ActionDialog from "../components/ActionDialog";
import { AppToaster } from "../components/AppToaster";
import ApprovalDetailDrawer from "../components/ApprovalDetailDrawer";
import BookingCodeInfo from "../components/BookingCodeInfo";
import CaseStudyInfo from "../components/CaseStudyInfo";
import ClientInfo from "../components/ClientInfo";
import ContactCard from "../components/ContactCard";
import ContactInfo from "../components/ContactInfo";
import DataCardContainer from "../components/DataCardContainer";
import DataCardSimple from "../components/DataCardSimple";
import DateValue from "../components/DateValue";
import DocumentCard from "../components/DocumentCard";
import DocumentDialog3 from "../components/DocumentDialog3";
import ErrorAlert from "../components/ErrorAlert";
import ExpenseCategoryInfo from "../components/ExpenseCategoryInfo";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerDurationField from "../components/FormDrawerDurationField";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerLabourExpenseDaysField2 from "../components/FormDrawerLabourExpenseDaysField2";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerStaffCompetencySearch from "../components/FormDrawerStaffCompetencySearch";
import FormDrawerStaffEmploymentSearch from "../components/FormDrawerStaffEmploymentSearch";
import FormDrawerStaffFreeTimeSearch2 from "../components/FormDrawerStaffFreeTimeSearch2";
import FormDrawerStaffLocationSearch from "../components/FormDrawerStaffLocationSearch";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import InfoPopup from "../components/InfoPopup";
import ItemCardList from "../components/ItemCardList";
import Kanban from "../components/Kanban";
import KPICard from "../components/KPICard";
import LabourExpenseInfo from "../components/LabourExpenseInfo";
import LocationInfo from "../components/LocationInfo";
import LinkTag from "../components/LinkTag";
import Matrix from "../components/Matrix";
import MilestoneInfo from "../components/MilestoneInfo";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NonLabourExpenseInfo from "../components/NonLabourExpenseInfo";
import NumericValue from "../components/NumericValue";
import PercentageValue from "../components/PercentageValue";
import PermissionCard from "../components/PermissionCard";
import ProposalAverageValueCard from "../components/ProposalAverageValueCard";
import ProposalCompetitorCard from "../components/ProposalCompetitorCard";
import ProposalServiceInfo from "../components/ProposalServiceInfo";
import ProposalTotalValueCard from "../components/ProposalTotalValueCard";
import ProposalWinLossCard from "../components/ProposalWinLossCard";
import RoleInfo from "../components/RoleInfo";
import SearchField from "../components/SearchField";
import ServiceInfo from "../components/ServiceInfo";
import StaffInfo from "../components/StaffInfo";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionDocumentEditor3 from "../components/TabSectionDocumentEditor3";
import TabSectionDocumentEditorFormContainer2 from "../components/TabSectionDocumentEditorFormContainer2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldImage from "../components/TabSectionFieldImage";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import Timeline4 from "../components/Timeline4";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import generateImageFromComponent from "../utilities/generateImageFromComponent";
import generateKanbanDataFromProposalActions from "../utilities/generateKanbanDataFromProposalActions";
import getDjangoDate from "../utilities/getDjangoDate";

// Utility imports
import generateTimelineResourceDataFromProposalServices from "../utilities/generateTimelineResourceDataFromProposalServices";
import generateTimelineDataFromProposalServices from "../utilities/generateTimelineDataFromProposalServices";
import generateYearList from "../utilities/generateYearList";
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// Approval request
import {
  createApprovalRequestAction,
  deleteApprovalRequestAction,
  listApprovalRequestsAction,
  listApprovalRequestDetailsAction,
  resetCreateApprovalRequestAction,
  resetDeleteApprovalRequestAction,
  resetUpdateApprovalRequestAction,
  updateApprovalRequestAction,
} from "../actions/approvalRequestActions";

// Booking code
import { listBookingCodesAction } from "../actions/bookingCodeActions";

// Case study
import { listCaseStudiesAction } from "../actions/caseStudyActions";

// Client
import { listClientsAction } from "../actions/clientActions";

// Client KPI
import {
  createClientKPIAction,
  deleteClientKPIAction,
  listClientKPIsAction,
  listClientKPIDetailsAction,
  resetCreateClientKPIAction,
  resetDeleteClientKPIAction,
  resetUpdateClientKPIAction,
  updateClientKPIAction,
} from "../actions/clientKPIActions";

// Competency
import { listCompetenciesAction } from "../actions/competencyActions";

// Contact
import { listContactsAction } from "../actions/contactActions";

// Currency
import { getOrganisationCurrencyChoicesCurrencyAction } from "../actions/currencyActions";

// Document
import { generatePdfDocumentAction } from "../actions/documentActions";

// Expense category
import { listExpenseCategoriesAction } from "../actions/expenseCategoryActions";

// Location
import { listLocationsAction } from "../actions/locationActions";

// Manager
import {
  downloadManagersAction,
  listManagersAction,
  resetDownloadManagersAction,
} from "../actions/managerActions";

// Proposal
import {
  deleteProposalAction,
  downloadProposalAction,
  listProposalDetailsAction,
  listProposalsAction,
  predictWinProposalAction,
  resetDeleteProposalAction,
  resetDownloadProposalAction,
  resetListProposalsAction,
  resetPredictWinProposalAction,
  resetUpdateProposalAction,
  updateProposalAction,
} from "../actions/proposalActions";

// Proposal action
import {
  createProposalActionAction,
  deleteProposalActionAction,
  listProposalActionsAction,
  listProposalActionDetailsAction,
  resetCreateProposalActionAction,
  resetDeleteProposalActionAction,
  resetUpdateProposalActionAction,
  updateProposalActionAction,
} from "../actions/proposalActionActions";

// Proposal case study
import {
  createProposalCaseStudyAction,
  deleteProposalCaseStudyAction,
  listProposalCaseStudiesAction,
  listProposalCaseStudyDetailsAction,
  resetCreateProposalCaseStudyAction,
  resetDeleteProposalCaseStudyAction,
  resetUpdateProposalCaseStudyAction,
  updateProposalCaseStudyAction,
} from "../actions/proposalCaseStudyActions";

// Proposal competitor
import {
  createProposalCompetitorAction,
  deleteProposalCompetitorAction,
  listProposalCompetitorsAction,
  listProposalCompetitorDetailsAction,
  resetCreateProposalCompetitorAction,
  resetDeleteProposalCompetitorAction,
  resetUpdateProposalCompetitorAction,
  updateProposalCompetitorAction,
} from "../actions/proposalCompetitorActions";

// Proposal contact
import {
  createProposalContactAction,
  deleteProposalContactAction,
  listProposalContactsAction,
  listProposalContactDetailsAction,
  resetCreateProposalContactAction,
  resetDeleteProposalContactAction,
  resetUpdateProposalContactAction,
  updateProposalContactAction,
} from "../actions/proposalContactActions";

// Proposal document
import {
  listProposalDocumentDetailsAction,
  resetUpdateProposalDocumentAction,
  updateProposalDocumentAction,
} from "../actions/proposalDocumentActions";

// Proposal fee
import {
  listProposalFeesAction,
  listProposalFeeDetailsAction,
  resetUpdateProposalFeeAction,
  updateProposalFeeAction,
} from "../actions/proposalFeeActions";

// Proposal labour expense
import {
  createProposalLabourExpenseAction,
  deleteProposalLabourExpenseAction,
  listProposalLabourExpensesAction,
  listProposalLabourExpenseDetailsAction,
  resetCreateProposalLabourExpenseAction,
  resetDeleteProposalLabourExpenseAction,
  resetUpdateProposalLabourExpenseAction,
  updateProposalLabourExpenseAction,
} from "../actions/proposalLabourExpenseActions";

// Proposal legal section
import {
  createProposalLegalSectionAction,
  deleteProposalLegalSectionAction,
  downloadProposalLegalSectionsAction,
  listProposalLegalSectionsAction,
  listProposalLegalSectionDetailsAction,
  resetCreateProposalLegalSectionAction,
  resetDeleteProposalLegalSectionAction,
  resetDownloadProposalLegalSectionsAction,
  resetUpdateProposalLegalSectionAction,
  resetUploadProposalLegalSectionsAction,
  updateProposalLegalSectionAction,
  uploadProposalLegalSectionsAction,
} from "../actions/proposalLegalSectionActions";

// Proposal lesson
import {
  createProposalLessonAction,
  deleteProposalLessonAction,
  listProposalLessonsAction,
  listProposalLessonDetailsAction,
  resetCreateProposalLessonAction,
  resetDeleteProposalLessonAction,
  resetUpdateProposalLessonAction,
  updateProposalLessonAction,
} from "../actions/proposalLessonActions";

// Proposal milestone
import {
  createProposalMilestoneAction,
  deleteProposalMilestoneAction,
  listProposalMilestonesAction,
  listProposalMilestoneDetailsAction,
  resetCreateProposalMilestoneAction,
  resetDeleteProposalMilestoneAction,
  resetUpdateProposalMilestoneAction,
  updateProposalMilestoneAction,
} from "../actions/proposalMilestoneActions";

// Proposal non-labour expense
import {
  createProposalNonLabourExpenseAction,
  deleteProposalNonLabourExpenseAction,
  listProposalNonLabourExpensesAction,
  listProposalNonLabourExpenseDetailsAction,
  resetCreateProposalNonLabourExpenseAction,
  resetDeleteProposalNonLabourExpenseAction,
  resetUpdateProposalNonLabourExpenseAction,
  updateProposalNonLabourExpenseAction,
} from "../actions/proposalNonLabourExpenseActions";

// Proposal permission
import {
  createProposalPermissionAction,
  deleteProposalPermissionAction,
  listProposalPermissionsAction,
  listProposalPermissionDetailsAction,
  resetCreateProposalPermissionAction,
  resetDeleteProposalPermissionAction,
  resetUpdateProposalPermissionAction,
  updateProposalPermissionAction,
} from "../actions/proposalPermissionActions";

// Proposal question
import {
  createProposalQuestionAction,
  deleteProposalQuestionAction,
  listProposalQuestionsAction,
  listProposalQuestionDetailsAction,
  resetCreateProposalQuestionAction,
  resetDeleteProposalQuestionAction,
  resetUpdateProposalQuestionAction,
  updateProposalQuestionAction,
} from "../actions/proposalQuestionActions";

// Proposal rate
import {
  createProposalRateAction,
  deleteProposalRateAction,
  listProposalRatesAction,
  listProposalRateDetailsAction,
  resetCreateProposalRateAction,
  resetDeleteProposalRateAction,
  resetUpdateProposalRateAction,
  updateProposalRateAction,
} from "../actions/proposalRateActions";

// Proposal requirement
import {
  createProposalRequirementAction,
  deleteProposalRequirementAction,
  downloadProposalRequirementsAction,
  listProposalRequirementsAction,
  listProposalRequirementDetailsAction,
  resetCreateProposalRequirementAction,
  resetDeleteProposalRequirementAction,
  resetDownloadProposalRequirementsAction,
  resetUpdateProposalRequirementAction,
  resetUploadProposalRequirementsAction,
  updateProposalRequirementAction,
  uploadProposalRequirementsAction,
} from "../actions/proposalRequirementActions";

// Proposal risk
import {
  createProposalRiskAction,
  deleteProposalRiskAction,
  listProposalRisksAction,
  listProposalRiskDetailsAction,
  resetCreateProposalRiskAction,
  resetDeleteProposalRiskAction,
  resetUpdateProposalRiskAction,
  updateProposalRiskAction,
} from "../actions/proposalRiskActions";

// Proposal service
import {
  createProposalServiceAction,
  deleteProposalServiceAction,
  listProposalServicesAction,
  listProposalServiceDetailsAction,
  resetCreateProposalServiceAction,
  resetDeleteProposalServiceAction,
  resetUpdateProposalServiceAction,
  updateProposalServiceAction,
} from "../actions/proposalServiceActions";

// Proposal service activity
import {
  createProposalServiceActivityAction,
  deleteProposalServiceActivityAction,
  listProposalServiceActivityDetailsAction,
  resetCreateProposalServiceActivityAction,
  resetDeleteProposalServiceActivityAction,
  resetUpdateProposalServiceActivityAction,
  updateProposalServiceActivityAction,
} from "../actions/proposalServiceActivityActions";

// Proposal service deliverable
import {
  createProposalServiceDeliverableAction,
  deleteProposalServiceDeliverableAction,
  listProposalServiceDeliverablesAction,
  listProposalServiceDeliverableDetailsAction,
  resetCreateProposalServiceDeliverableAction,
  resetDeleteProposalServiceDeliverableAction,
  resetUpdateProposalServiceDeliverableAction,
  updateProposalServiceDeliverableAction,
} from "../actions/proposalServiceDeliverableActions";

// Proposal staff
import {
  createProposalStaffAction,
  deleteProposalStaffAction,
  listProposalStaffAction,
  listProposalStaffDetailsAction,
  resetCreateProposalStaffAction,
  resetDeleteProposalStaffAction,
  resetUpdateProposalStaffAction,
  updateProposalStaffAction,
} from "../actions/proposalStaffActions";

// Proposal upload
import {
  createProposalUploadAction,
  deleteProposalUploadAction,
  listProposalUploadsAction,
  listProposalUploadDetailsAction,
  resetCreateProposalUploadAction,
  resetDeleteProposalUploadAction,
  resetUpdateProposalUploadAction,
  updateProposalUploadAction,
} from "../actions/proposalUploadActions";

// Role
import { listRolesAction } from "../actions/roleActions";

// Service
import { listServicesAction } from "../actions/serviceActions";

// Staff
import { listStaffAction } from "../actions/staffActions";

// Main function
function ProposalDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Proposal id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Approval request
  const approvalRequestCreate = useSelector(
    (state) => state.approvalRequestCreate
  );

  const approvalRequestDelete = useSelector(
    (state) => state.approvalRequestDelete
  );

  const approvalRequestDetails = useSelector(
    (state) => state.approvalRequestDetails
  );

  const approvalRequestList = useSelector((state) => state.approvalRequestList);

  const approvalRequestUpdate = useSelector(
    (state) => state.approvalRequestUpdate
  );

  // Booking code
  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Case study
  const caseStudyList = useSelector((state) => state.caseStudyList);

  // Client
  const clientList = useSelector((state) => state.clientList);

  // Client KPI
  const clientKPICreate = useSelector((state) => state.clientKPICreate);

  const clientKPIDelete = useSelector((state) => state.clientKPIDelete);

  const clientKPIDetails = useSelector((state) => state.clientKPIDetails);

  const clientKPIList = useSelector((state) => state.clientKPIList);

  const clientKPIUpdate = useSelector((state) => state.clientKPIUpdate);

  // Competency
  const competencyList = useSelector((state) => state.competencyList);

  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Currency
  const currencyGetOrganisationCurrencyChoices = useSelector(
    (state) => state.currencyGetOrganisationCurrencyChoices
  );

  // Document
  const documentGeneratePdf = useSelector((state) => state.documentGeneratePdf);

  // Expense category
  const expenseCategoryList = useSelector((state) => state.expenseCategoryList);

  // Location
  const locationList = useSelector((state) => state.locationList);

  // Manager
  const managerDownload = useSelector((state) => state.managerDownload);

  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Proposal
  const proposalDelete = useSelector((state) => state.proposalDelete);

  const proposalDetails = useSelector((state) => state.proposalDetails);

  const proposalDownload = useSelector((state) => state.proposalDownload);

  const proposalList = useSelector((state) => state.proposalList);

  const proposalPredictWin = useSelector((state) => state.proposalPredictWin);

  const proposalUpdate = useSelector((state) => state.proposalUpdate);

  // Proposal action
  const proposalActionCreate = useSelector(
    (state) => state.proposalActionCreate
  );

  const proposalActionDelete = useSelector(
    (state) => state.proposalActionDelete
  );

  const proposalActionDetails = useSelector(
    (state) => state.proposalActionDetails
  );

  const proposalActionList = useSelector((state) => state.proposalActionList);

  const proposalActionUpdate = useSelector(
    (state) => state.proposalActionUpdate
  );

  // Proposal case study
  const proposalCaseStudyCreate = useSelector(
    (state) => state.proposalCaseStudyCreate
  );

  const proposalCaseStudyDelete = useSelector(
    (state) => state.proposalCaseStudyDelete
  );

  const proposalCaseStudyDetails = useSelector(
    (state) => state.proposalCaseStudyDetails
  );

  const proposalCaseStudyList = useSelector(
    (state) => state.proposalCaseStudyList
  );

  const proposalCaseStudyUpdate = useSelector(
    (state) => state.proposalCaseStudyUpdate
  );

  // Proposal competitor
  const proposalCompetitorCreate = useSelector(
    (state) => state.proposalCompetitorCreate
  );

  const proposalCompetitorDelete = useSelector(
    (state) => state.proposalCompetitorDelete
  );

  const proposalCompetitorDetails = useSelector(
    (state) => state.proposalCompetitorDetails
  );

  const proposalCompetitorList = useSelector(
    (state) => state.proposalCompetitorList
  );

  const proposalCompetitorUpdate = useSelector(
    (state) => state.proposalCompetitorUpdate
  );

  // Proposal contact
  const proposalContactCreate = useSelector(
    (state) => state.proposalContactCreate
  );

  const proposalContactDelete = useSelector(
    (state) => state.proposalContactDelete
  );

  const proposalContactDetails = useSelector(
    (state) => state.proposalContactDetails
  );

  const proposalContactList = useSelector((state) => state.proposalContactList);

  const proposalContactUpdate = useSelector(
    (state) => state.proposalContactUpdate
  );

  // Proposal document
  const proposalDocumentDetails = useSelector(
    (state) => state.proposalDocumentDetails
  );

  const proposalDocumentUpdate = useSelector(
    (state) => state.proposalDocumentUpdate
  );

  // Proposal fee
  const proposalFeeDetails = useSelector((state) => state.proposalFeeDetails);

  const proposalFeeList = useSelector((state) => state.proposalFeeList);

  const proposalFeeUpdate = useSelector((state) => state.proposalFeeUpdate);

  // Proposal labour expense
  const proposalLabourExpenseCreate = useSelector(
    (state) => state.proposalLabourExpenseCreate
  );

  const proposalLabourExpenseDelete = useSelector(
    (state) => state.proposalLabourExpenseDelete
  );

  const proposalLabourExpenseDetails = useSelector(
    (state) => state.proposalLabourExpenseDetails
  );

  const proposalLabourExpenseList = useSelector(
    (state) => state.proposalLabourExpenseList
  );

  const proposalLabourExpenseUpdate = useSelector(
    (state) => state.proposalLabourExpenseUpdate
  );

  // Proposal legal section
  const proposalLegalSectionCreate = useSelector(
    (state) => state.proposalLegalSectionCreate
  );

  const proposalLegalSectionDelete = useSelector(
    (state) => state.proposalLegalSectionDelete
  );

  const proposalLegalSectionDetails = useSelector(
    (state) => state.proposalLegalSectionDetails
  );

  const proposalLegalSectionDownload = useSelector(
    (state) => state.proposalLegalSectionDownload
  );

  const proposalLegalSectionList = useSelector(
    (state) => state.proposalLegalSectionList
  );

  const proposalLegalSectionUpdate = useSelector(
    (state) => state.proposalLegalSectionUpdate
  );

  const proposalLegalSectionUpload = useSelector(
    (state) => state.proposalLegalSectionUpload
  );

  // Proposal lesson
  const proposalLessonCreate = useSelector(
    (state) => state.proposalLessonCreate
  );

  const proposalLessonDelete = useSelector(
    (state) => state.proposalLessonDelete
  );

  const proposalLessonDetails = useSelector(
    (state) => state.proposalLessonDetails
  );

  const proposalLessonList = useSelector((state) => state.proposalLessonList);

  const proposalLessonUpdate = useSelector(
    (state) => state.proposalLessonUpdate
  );

  // Proposal milestone
  const proposalMilestoneCreate = useSelector(
    (state) => state.proposalMilestoneCreate
  );

  const proposalMilestoneDelete = useSelector(
    (state) => state.proposalMilestoneDelete
  );

  const proposalMilestoneDetails = useSelector(
    (state) => state.proposalMilestoneDetails
  );

  const proposalMilestoneList = useSelector(
    (state) => state.proposalMilestoneList
  );

  const proposalMilestoneUpdate = useSelector(
    (state) => state.proposalMilestoneUpdate
  );

  // Proposal non-labour expense
  const proposalNonLabourExpenseCreate = useSelector(
    (state) => state.proposalNonLabourExpenseCreate
  );

  const proposalNonLabourExpenseDelete = useSelector(
    (state) => state.proposalNonLabourExpenseDelete
  );

  const proposalNonLabourExpenseDetails = useSelector(
    (state) => state.proposalNonLabourExpenseDetails
  );

  const proposalNonLabourExpenseList = useSelector(
    (state) => state.proposalNonLabourExpenseList
  );

  const proposalNonLabourExpenseUpdate = useSelector(
    (state) => state.proposalNonLabourExpenseUpdate
  );

  // Proposal permission
  const proposalPermissionCreate = useSelector(
    (state) => state.proposalPermissionCreate
  );

  const proposalPermissionDelete = useSelector(
    (state) => state.proposalPermissionDelete
  );

  const proposalPermissionDetails = useSelector(
    (state) => state.proposalPermissionDetails
  );

  const proposalPermissionList = useSelector(
    (state) => state.proposalPermissionList
  );

  const proposalPermissionUpdate = useSelector(
    (state) => state.proposalPermissionUpdate
  );

  // Proposal question
  const proposalQuestionCreate = useSelector(
    (state) => state.proposalQuestionCreate
  );

  const proposalQuestionDelete = useSelector(
    (state) => state.proposalQuestionDelete
  );

  const proposalQuestionDetails = useSelector(
    (state) => state.proposalQuestionDetails
  );

  const proposalQuestionList = useSelector(
    (state) => state.proposalQuestionList
  );

  const proposalQuestionUpdate = useSelector(
    (state) => state.proposalQuestionUpdate
  );

  // Proposal rate
  const proposalRateCreate = useSelector((state) => state.proposalRateCreate);

  const proposalRateDelete = useSelector((state) => state.proposalRateDelete);

  const proposalRateDetails = useSelector((state) => state.proposalRateDetails);

  const proposalRateList = useSelector((state) => state.proposalRateList);

  const proposalRateUpdate = useSelector((state) => state.proposalRateUpdate);

  // Proposal requirement
  const proposalRequirementCreate = useSelector(
    (state) => state.proposalRequirementCreate
  );

  const proposalRequirementDelete = useSelector(
    (state) => state.proposalRequirementDelete
  );

  const proposalRequirementDetails = useSelector(
    (state) => state.proposalRequirementDetails
  );

  const proposalRequirementDownload = useSelector(
    (state) => state.proposalRequirementDownload
  );

  const proposalRequirementList = useSelector(
    (state) => state.proposalRequirementList
  );

  const proposalRequirementUpdate = useSelector(
    (state) => state.proposalRequirementUpdate
  );

  const proposalRequirementUpload = useSelector(
    (state) => state.proposalRequirementUpload
  );

  // Proposal risk
  const proposalRiskCreate = useSelector((state) => state.proposalRiskCreate);

  const proposalRiskDelete = useSelector((state) => state.proposalRiskDelete);

  const proposalRiskDetails = useSelector((state) => state.proposalRiskDetails);

  const proposalRiskList = useSelector((state) => state.proposalRiskList);

  const proposalRiskUpdate = useSelector((state) => state.proposalRiskUpdate);

  // Proposal service
  const proposalServiceCreate = useSelector(
    (state) => state.proposalServiceCreate
  );

  const proposalServiceDelete = useSelector(
    (state) => state.proposalServiceDelete
  );

  const proposalServiceDetails = useSelector(
    (state) => state.proposalServiceDetails
  );

  const proposalServiceList = useSelector((state) => state.proposalServiceList);

  const proposalServiceUpdate = useSelector(
    (state) => state.proposalServiceUpdate
  );

  // Proposal service activity
  const proposalServiceActivityCreate = useSelector(
    (state) => state.proposalServiceActivityCreate
  );

  const proposalServiceActivityDelete = useSelector(
    (state) => state.proposalServiceActivityDelete
  );

  const proposalServiceActivityDetails = useSelector(
    (state) => state.proposalServiceActivityDetails
  );

  const proposalServiceActivityUpdate = useSelector(
    (state) => state.proposalServiceActivityUpdate
  );

  // Proposal service deliverable
  const proposalServiceDeliverableCreate = useSelector(
    (state) => state.proposalServiceDeliverableCreate
  );

  const proposalServiceDeliverableDelete = useSelector(
    (state) => state.proposalServiceDeliverableDelete
  );

  const proposalServiceDeliverableDetails = useSelector(
    (state) => state.proposalServiceDeliverableDetails
  );

  const proposalServiceDeliverableList = useSelector(
    (state) => state.proposalServiceDeliverableList
  );

  const proposalServiceDeliverableUpdate = useSelector(
    (state) => state.proposalServiceDeliverableUpdate
  );

  // Proposal staff
  const proposalStaffCreate = useSelector((state) => state.proposalStaffCreate);

  const proposalStaffDelete = useSelector((state) => state.proposalStaffDelete);

  const proposalStaffDetails = useSelector(
    (state) => state.proposalStaffDetails
  );

  const proposalStaffList = useSelector((state) => state.proposalStaffList);

  const proposalStaffUpdate = useSelector((state) => state.proposalStaffUpdate);

  // Proposal upload
  const proposalUploadCreate = useSelector(
    (state) => state.proposalUploadCreate
  );

  const proposalUploadDelete = useSelector(
    (state) => state.proposalUploadDelete
  );

  const proposalUploadDetails = useSelector(
    (state) => state.proposalUploadDetails
  );

  const proposalUploadList = useSelector((state) => state.proposalUploadList);

  const proposalUploadUpdate = useSelector(
    (state) => state.proposalUploadUpdate
  );

  // Role
  const roleList = useSelector((state) => state.roleList);

  // Service
  const serviceList = useSelector((state) => state.serviceList);

  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("proposal-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Schedule
  const [scheduleDialogIsOpen, setScheduleDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Actions
  const [addActionDrawerIsOpen, setAddActionDrawerIsOpen] = useState(false);
  const [updateActionDrawerIsOpen, setUpdateActionDrawerIsOpen] =
    useState(false);

  // Research
  const [addQuestionDrawerIsOpen, setAddQuestionDrawerIsOpen] = useState(false);
  const [updateQuestionDrawerIsOpen, setUpdateQuestionDrawerIsOpen] =
    useState(false);

  // Approval requests
  const [addApprovalRequestDrawerIsOpen, setAddApprovalRequestDrawerIsOpen] =
    useState(false);
  const [
    updateApprovalRequestDrawerIsOpen,
    setUpdateApprovalRequestDrawerIsOpen,
  ] = useState(false);
  const [
    viewProposalQualificationApprovalDrawerIsOpen,
    setViewProposalQualificationApprovalDrawerIsOpen,
  ] = useState(false);

  // Strategic alignment factors
  const [
    updateStrategicAlignmentFactorsDrawerIsOpen,
    setUpdateStrategicAlignmentFactorsDrawerIsOpen,
  ] = useState(false);

  // Opportunity factors
  const [
    updateOpportunityFactorsDrawerIsOpen,
    setUpdateOpportunityFactorsDrawerIsOpen,
  ] = useState(false);

  // Relationship factors
  const [
    updateRelationshipFactorsDrawerIsOpen,
    setUpdateRelationshipFactorsDrawerIsOpen,
  ] = useState(false);

  // Delivery factors
  const [
    updateDeliveryFactorsDrawerIsOpen,
    setUpdateDeliveryFactorsDrawerIsOpen,
  ] = useState(false);

  // Client performance analysis
  const [addClientKPIDrawerIsOpen, setAddClientKPIDrawerIsOpen] =
    useState(false);
  const [updateClientKPIDrawerIsOpen, setUpdateClientKPIDrawerIsOpen] =
    useState(false);

  // Needs analysis
  const [updateNeedsAnalysisDrawerIsOpen, setUpdateNeedsAnalysisDrawerIsOpen] =
    useState(false);

  // Key contacts
  const [addProposalContactDrawerIsOpen, setAddProposalContactDrawerIsOpen] =
    useState(false);
  const [
    updateProposalContactDrawerIsOpen,
    setUpdateProposalContactDrawerIsOpen,
  ] = useState(false);

  // Competitor analysis
  const [addCompetitorDrawerIsOpen, setAddCompetitorDrawerIsOpen] =
    useState(false);
  const [updateCompetitorDrawerIsOpen, setUpdateCompetitorDrawerIsOpen] =
    useState(false);

  // Win strategy
  const [updateWinStrategyDrawerIsOpen, setUpdateWinStrategyDrawerIsOpen] =
    useState(false);

  // Responses to questions/requirements
  const [addRequirementDrawerIsOpen, setAddRequirementDrawerIsOpen] =
    useState(false);
  const [updateRequirementDrawerIsOpen, setUpdateRequirementDrawerIsOpen] =
    useState(false);

  // Upload requirements
  const [
    uploadProposalRequirementsDialogIsOpen,
    setUploadProposalRequirementsDialogIsOpen,
  ] = useState(false);

  // Our approach
  const [updateApproachDrawerIsOpen, setUpdateApproachDrawerIsOpen] =
    useState(false);

  // Services
  const [addServiceDrawerIsOpen, setAddServiceDrawerIsOpen] = useState(false);
  const [updateServiceDrawerIsOpen, setUpdateServiceDrawerIsOpen] =
    useState(false);

  // Activities
  const [addActivityDrawerIsOpen, setAddActivityDrawerIsOpen] = useState(false);
  const [updateActivityDrawerIsOpen, setUpdateActivityDrawerIsOpen] =
    useState(false);

  // Deliverables
  const [addDeliverableDrawerIsOpen, setAddDeliverableDrawerIsOpen] =
    useState(false);
  const [updateDeliverableDrawerIsOpen, setUpdateDeliverableDrawerIsOpen] =
    useState(false);

  // Key personnel
  const [addStaffDrawerIsOpen, setAddStaffDrawerIsOpen] = useState(false);
  const [updateStaffDrawerIsOpen, setUpdateStaffDrawerIsOpen] = useState(false);

  // Labour expenses
  const [addLabourExpenseDrawerIsOpen, setAddLabourExpenseDrawerIsOpen] =
    useState(false);
  const [updateLabourExpenseDrawerIsOpen, setUpdateLabourExpenseDrawerIsOpen] =
    useState(false);

  // Non-labour expenses
  const [addNonLabourExpenseDrawerIsOpen, setAddNonLabourExpenseDrawerIsOpen] =
    useState(false);
  const [
    updateNonLabourExpenseDrawerIsOpen,
    setUpdateNonLabourExpenseDrawerIsOpen,
  ] = useState(false);

  // Risk management strategy
  const [addRiskDrawerIsOpen, setAddRiskDrawerIsOpen] = useState(false);
  const [updateRiskDrawerIsOpen, setUpdateRiskDrawerIsOpen] = useState(false);

  // Rates
  const [addRateDrawerIsOpen, setAddRateDrawerIsOpen] = useState(false);
  const [updateRateDrawerIsOpen, setUpdateRateDrawerIsOpen] = useState(false);

  // Fee notes
  const [updateFeeNotesDrawerIsOpen, setUpdateFeeNotesDrawerIsOpen] =
    useState(false);

  // Fees
  const [updateFeeDrawerIsOpen, setUpdateFeeDrawerIsOpen] = useState(false);

  // Milestones
  const [addMilestoneDrawerIsOpen, setAddMilestoneDrawerIsOpen] =
    useState(false);
  const [updateMilestoneDrawerIsOpen, setUpdateMilestoneDrawerIsOpen] =
    useState(false);

  // Legal terms
  const [addLegalSectionDrawerIsOpen, setAddLegalSectionDrawerIsOpen] =
    useState(false);
  const [updateLegalSectionDrawerIsOpen, setUpdateLegalSectionDrawerIsOpen] =
    useState(false);

  // Upload legal terms
  const [
    uploadProposalLegalSectionsDialogIsOpen,
    setUploadProposalLegalSectionsDialogIsOpen,
  ] = useState(false);

  // Style
  const [
    updateProposalDocumentStyleDrawerIsOpen,
    setUpdateProposalDocumentStyleDrawerIsOpen,
  ] = useState(false);

  // Case studies
  const [addCaseStudyDrawerIsOpen, setAddCaseStudyDrawerIsOpen] =
    useState(false);
  const [updateCaseStudyDrawerIsOpen, setUpdateCaseStudyDrawerIsOpen] =
    useState(false);

  // Lessons
  const [addProposalLessonDrawerIsOpen, setAddProposalLessonDrawerIsOpen] =
    useState(false);
  const [
    updateProposalLessonDrawerIsOpen,
    setUpdateProposalLessonDrawerIsOpen,
  ] = useState(false);

  // Opportunity brief
  const [opportunityBriefIsOpen, setOpportunityBriefIsOpen] = useState(false);

  // Strategy review
  const [strategyReviewIsOpen, setStrategyReviewIsOpen] = useState(false);

  // Solution review
  const [solutionReviewIsOpen, setSolutionReviewIsOpen] = useState(false);

  // Commercials review
  const [commercialsReviewIsOpen, setCommercialsReviewIsOpen] = useState(false);

  // Proposal document
  const [proposalDocumentIsOpen, setProposalDocumentIsOpen] = useState(false);

  // Download editable proposal document
  const [
    downloadEditableProposalDocumentIsOpen,
    setDownloadEditableProposalDocumentIsOpen,
  ] = useState(false);

  // Win/Loss review
  const [winLossReviewIsOpen, setWinLossReviewIsOpen] = useState(false);

  // User uploaded files
  const [addUploadDrawerIsOpen, setAddUploadDrawerIsOpen] = useState(false);
  const [updateUploadDrawerIsOpen, setUpdateUploadDrawerIsOpen] =
    useState(false);

  // Other
  const [initialiseActionKanban, setInitialiseActionKanban] = useState(true);
  const [showActionKanban, setShowActionKanban] = useState(false);

  // Search and filter variables
  // Approval request
  const approvalRequestQueryDefault = {
    approver: "",
    decisionRight: "",
    itemId: id,
    itemType: "proposal",
    organisation: activeOrganisation.id,
    outcome: "",
    page: 1,
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalRequestQuery, setApprovalRequestQuery] = useState({
    ...approvalRequestQueryDefault,
  });

  // Booking code
  const bookingCodeQueryDefault = {
    billable: "",
    engagement: "",
    holding: false,
    open: true,
    organisation: activeOrganisation.id,
    page: 1,
    proposal: id,
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Case study
  const caseStudyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [caseStudyQuery, setCaseStudyQuery] = useState({
    ...caseStudyQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Client KPI
  const clientKPIQueryDefault = {
    client: "",
    page: 1,
    rating: "",
    searchString: "",
    sortField: "",
    units: "",
  };
  const [clientKPIQuery, setClientKPIQuery] = useState({
    ...clientKPIQueryDefault,
  });

  // Competency
  const competencyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [competencyQuery, setCompetencyQuery] = useState({
    ...competencyQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Dates
  const past_week = new Date();
  past_week.setDate(past_week.getDate() - 7);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const following_week = new Date();
  following_week.setDate(following_week.getDate() + 7);

  // Expense category
  const expenseCategoryQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [expenseCategoryQuery, setExpenseCategoryQuery] = useState({
    ...expenseCategoryQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Proposal action
  const proposalActionQueryDefault = {
    category: "",
    dueEnd: "",
    dueStart: "",
    owner: "",
    page: 1,
    proposal: id,
    searchString: "",
    status: "",
  };
  const [proposalActionQuery, setProposalActionQuery] = useState({
    ...proposalActionQueryDefault,
  });

  // Proposal case study
  const proposalCaseStudyQueryDefault = {
    page: 1,
    proposal: id,
  };
  const [proposalCaseStudyQuery] = useState({
    ...proposalCaseStudyQueryDefault,
  });

  // Proposal competitor
  const proposalCompetitorQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
  };
  const [proposalCompetitorQuery, setProposalCompetitorQuery] = useState({
    ...proposalCompetitorQueryDefault,
  });

  // Proposal contact
  const proposalContactQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
  };
  const [proposalContactQuery, setProposalContactQuery] = useState({
    ...proposalContactQueryDefault,
  });

  // Proposal fee
  const proposalFeeQueryDefault = {
    page: 1,
    proposal: id,
    proposalService: "",
    sortField: "",
  };
  const [proposalFeeQuery, setProposalFeeQuery] = useState({
    ...proposalFeeQueryDefault,
  });

  // Proposal labour expense
  const proposalLabourExpenseQueryDefault = {
    page: 1,
    proposal: id,
    proposalService: "",
    role: "",
    sortField: "",
  };
  const [proposalLabourExpenseQuery, setProposalLabourExpenseQuery] = useState({
    ...proposalLabourExpenseQueryDefault,
  });

  // Proposal legal section
  const proposalLegalSectionQueryDefault = {
    amended: "",
    attachment: "",
    comments: "",
    number: "",
    originator: "",
    page: 1,
    proposal: id,
    proposed: "",
    title: "",
    status: "",
  };
  const [proposalLegalSectionQuery, setProposalLegalSectionQuery] = useState({
    ...proposalLegalSectionQueryDefault,
  });

  // Proposal lesson
  const proposalLessonQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
  };
  const [proposalLessonQuery, setProposalLessonQuery] = useState({
    ...proposalLessonQueryDefault,
  });

  // Proposal milestone
  const proposalMilestoneQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
    sortField: "",
  };
  const [proposalMilestoneQuery, setProposalMilestoneQuery] = useState({
    ...proposalMilestoneQueryDefault,
  });

  // Proposal non-labour expense
  const proposalNonLabourExpenseQueryDefault = {
    category: "",
    page: 1,
    proposal: id,
    proposalService: "",
    sortField: "",
  };
  const [proposalNonLabourExpenseQuery, setProposalNonLabourExpenseQuery] =
    useState({
      ...proposalNonLabourExpenseQueryDefault,
    });

  // Proposal permission
  const proposalPermissionQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
  };
  const [proposalPermissionQuery, setProposalPermissionQuery] = useState({
    ...proposalPermissionQueryDefault,
  });

  // Proposal question
  const proposalQuestionQueryDefault = {
    category: "",
    owner: "",
    page: 1,
    proposal: id,
    searchString: "",
    status: "",
  };
  const [proposalQuestionQuery, setProposalQuestionQuery] = useState({
    ...proposalQuestionQueryDefault,
  });

  // Proposal rate
  const proposalRateQueryDefault = {
    page: 1,
    proposal: id,
    role: "",
    sortField: "",
  };
  const [proposalRateQuery, setProposalRateQuery] = useState({
    ...proposalRateQueryDefault,
  });

  // Proposal requirement
  const proposalRequirementQueryDefault = {
    category: "",
    owner: "",
    page: 1,
    proposal: id,
    ref: "",
    searchString: "",
    status: "",
  };
  const [proposalRequirementQuery, setProposalRequirementQuery] = useState({
    ...proposalRequirementQueryDefault,
  });

  // Proposal risk
  const proposalRiskQueryDefault = {
    category: "",
    impact: "",
    likelihood: "",
    owner: "",
    page: 1,
    proposal: id,
    searchString: "",
    status: "",
  };
  const [proposalRiskQuery, setProposalRiskQuery] = useState({
    ...proposalRiskQueryDefault,
  });

  // Proposal service
  const proposalServiceQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
  };
  const [proposalServiceQuery, setProposalServiceQuery] = useState({
    ...proposalServiceQueryDefault,
  });

  // Proposal service deliverable
  const proposalServiceDeliverableQueryDefault = {
    dueEnd: "",
    dueStart: "",
    milestone: "",
    page: 1,
    proposal: id,
    searchString: "",
    service: "",
  };
  const [proposalServiceDeliverableQuery, setProposalServiceDeliverableQuery] =
    useState({
      ...proposalServiceDeliverableQueryDefault,
    });

  // Proposal staff
  const proposalStaffQueryDefault = {
    page: 1,
    proposal: id,
    searchString: "",
  };
  const [proposalStaffQuery, setProposalStaffQuery] = useState({
    ...proposalStaffQueryDefault,
  });

  // Proposal upload
  const proposalUploadQueryDefault = {
    page: 1,
    proposal: id,
  };
  const [proposalUploadQuery, setProposalUploadQuery] = useState({
    ...proposalUploadQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Service
  const serviceQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [serviceQuery, setServiceQuery] = useState({
    ...serviceQueryDefault,
  });

  // Staff
  const staffQueryDefault = {
    bookingProfile: "",
    competencies: "",
    employment: "",
    freeTime: "",
    level: "",
    location: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
    staff: "",
    utilisationScore: "",
  };
  const [staffQuery, setStaffQuery] = useState({
    ...staffQueryDefault,
  });

  // Form variables
  // Approval request
  const approvalRequestDefault = {
    approver: "",
    approverName: "",
    comments: "",
    decisionRight: "Proposal qualification",
    status: "Draft",
  };
  const [approvalRequest, setApprovalRequest] = useState({
    ...approvalRequestDefault,
  });

  // Client KPI
  const clientKPIDefault = {
    achieved: "",
    comments: "",
    kpi: "",
    rating: "Met",
    target: "",
    units: "",
    year: "",
  };
  const [clientKPI, setClientKPI] = useState({ ...clientKPIDefault });

  // Proposal
  const proposalDefault = {
    approachDiagram: "",
    approachDiagramName: "",
    approachSummary: "",
    changeDrivers: "",
    client: "",
    clientName: "",
    closed: "",
    contact: "",
    contactName: "",
    currency: activeOrganisation.currency,
    currencyExchangeRate: "",
    due: "",
    feeNotes: "",
    issues: "",
    manager: "",
    managerName: "",
    name: "",
    notes: "",
    ourSolution: "",
    probabilityWin: "",
    qualificationBudgetScore: 3,
    qualificationBudgetComments: "",
    qualificationBuyerScore: 3,
    qualificationBuyerComments: "",
    qualificationCoachScore: 3,
    qualificationCoachComments: "",
    qualificationDependenciesScore: 3,
    qualificationDependenciesComments: "",
    qualificationMarketScore: 3,
    qualificationMarketComments: "",
    qualificationNeedScore: 3,
    qualificationNeedComments: "",
    qualificationRelationshipScore: 3,
    qualificationRelationshipComments: "",
    qualificationReputationScore: 3,
    qualificationReputationComments: "",
    qualificationServicesScore: 3,
    qualificationServicesComments: "",
    qualificationSolutionScore: 3,
    qualificationSolutionComments: "",
    qualificationTeamScore: 3,
    qualificationTeamComments: "",
    qualificationTimingScore: 3,
    qualificationTimingComments: "",
    restricted: true,
    rfp: "",
    rfpName: "",
    status: "Qualifying",
    strategicOptions: "",
    strategyApprover: "",
    strategyApproverName: "",
    strategyDecision: "Pending",
    strategyDecisionComments: "",
    strategyDecisionDate: "",
    submitted: "",
    tax: "No",
    theoryOfChange: "",
    value: "",
    winStrategyCommercials: "",
    winStrategyOther: "",
    winStrategyPartnerships: "",
    winStrategyPrice: "",
    winStrategyReferences: "",
    winStrategyRelationships: "",
    winStrategySolution: "",
    winStrategyTeam: "",
  };
  const [updatedProposal, setUpdatedProposal] = useState({
    ...proposalDefault,
  });

  // Proposal action
  const proposalActionDefault = {
    action: "",
    category: "Other",
    comments: "",
    due: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "In-progress",
  };
  const [proposalAction, setProposalAction] = useState({
    ...proposalActionDefault,
  });

  // Proposal case study
  const proposalCaseStudyDefault = {
    caseStudy: "",
    caseStudyName: "",
  };
  const [proposalCaseStudy, setProposalCaseStudy] = useState({
    ...proposalCaseStudyDefault,
  });

  // Proposal competitor
  const proposalCompetitorDefault = {
    commercials: 3,
    competitor: "",
    notes: "",
    partnerships: 3,
    price: 3,
    references: 3,
    relationships: 3,
    solution: 3,
    team: 3,
  };
  const [proposalCompetitor, setProposalCompetitor] = useState({
    ...proposalCompetitorDefault,
  });

  // Proposal contact
  const proposalContactDefault = {
    buyerRole: "Advisor",
    comments: "",
    contact: "",
    contactName: "",
    levelOfInfluence: 3,
    relationshipOwner: "",
    relationshipOwnerName: "",
  };
  const [proposalContact, setProposalContact] = useState({
    ...proposalContactDefault,
  });

  // Proposal document
  const proposalDocumentDefault = {
    additionalContent: "",
    approachDiagram: "",
    approachDiagramName: "",
    approachHeading: "",
    approachSummary: "",
    backgroundImage: "",
    backgroundImageName: "",
    backPageText: "",
    caseStudiesHeading: "",
    changeDrivers: "",
    changeDriversHeading: "",
    client: "",
    clientAddress: "",
    clientAlias: "",
    clientDepartment: "",
    clientEntity: "",
    close: "",
    commitment: "",
    company: "",
    companyAddress: "",
    companyAlias: "",
    companyDepartment: "",
    companyEmail: "",
    companyEntity: "",
    companyPhone: "",
    companySummary: "",
    contact: "",
    contactRole: "",
    date: "",
    feesHeading: "",
    font: "Sintony",
    includeApproach: true,
    includeCaseStudies: true,
    includeCoverLetter: true,
    includeFeeBreakdown: true,
    includeFees: true,
    includeLegal: true,
    includeNeeds: true,
    includeResponses: true,
    includeServices: true,
    includeTeam: true,
    includeWhyUs: true,
    introCaseStudies: "",
    introFees: "",
    introRequirements: "",
    introServices: "",
    introTeam: "",
    issues: "",
    issuesHeading: "",
    legalHeading: "",
    logo: "",
    logoName: "",
    manager: "",
    managerEmail: "",
    managerPhone: "",
    managerRole: "",
    needsHeading: "",
    opening: "",
    ourSolution: "",
    ourSolutionHeading: "",
    primaryColour: "#1891AC",
    reference: "",
    responsesHeading: "",
    salutation: "",
    scheduleDiagram: "",
    scheduleDiagramName: "",
    secondaryColour: "#111111",
    servicesHeading: "",
    signature: "",
    signatureName: "",
    strategicOptions: "",
    strategicOptionsHeading: "",
    teamHeading: "",
    template: "London",
    theoryOfChange: "",
    theoryOfChangeHeading: "",
    title: "",
    whyUsHeading: "",
    winStrategyCommercials: "",
    winStrategyCommercialsHeading: "",
    winStrategyOther: "",
    winStrategyOtherHeading: "",
    winStrategyPartnerships: "",
    winStrategyPartnershipsHeading: "",
    winStrategyPrice: "",
    winStrategyPriceHeading: "",
    winStrategyReferences: "",
    winStrategyReferencesHeading: "",
    winStrategyRelationships: "",
    winStrategyRelationshipsHeading: "",
    winStrategySolution: "",
    winStrategySolutionHeading: "",
    winStrategyTeam: "",
    winStrategyTeamHeading: "",
  };
  const [proposalDocument, setProposalDocument] = useState({
    ...proposalDocumentDefault,
  });

  // Proposal fee
  const proposalFeeDefault = {
    adjustment: 0,
  };
  const [proposalFee, setProposalFee] = useState({
    ...proposalFeeDefault,
  });

  // Proposal labour expense
  const proposalLabourExpenseDefault = {
    competencies: [],
    customiseDays: false,
    detail: [],
    employment: "Permanent",
    finish: "",
    location: "",
    locationName: "",
    marginPercentage: "",
    method: "Distribute",
    minutes: 60,
    notes: "",
    proposalService: "",
    proposalServiceName: "",
    role: "",
    roleName: "",
    staff: "",
    staffName: "",
    start: "",
    weekdays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: false,
      sun: false,
    },
  };
  const [proposalLabourExpense, setProposalLabourExpense] = useState({
    ...proposalLabourExpenseDefault,
  });

  // Proposal legal section
  const proposalLegalSectionDefault = {
    amended: "",
    attachment: "",
    attachmentName: "",
    comments: "",
    number: "",
    originator: "",
    proposed: "",
    title: "",
    status: "Under review",
  };
  const [proposalLegalSection, setProposalLegalSection] = useState({
    ...proposalLegalSectionDefault,
  });

  const proposalLegalSectionUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [proposalLegalSectionUploadFile, setProposalLegalSectionUploadFile] =
    useState({
      ...proposalLegalSectionUploadFileDefault,
    });

  // Proposal lesson
  const proposalLessonDefault = {
    category: "Other",
    lesson: "",
    name: "",
  };
  const [proposalLesson, setProposalLesson] = useState({
    ...proposalLessonDefault,
  });

  // Proposal milestone
  const proposalMilestoneDefault = {
    description: "",
    due: "",
    name: "",
    value: "",
  };
  const [proposalMilestone, setProposalMilestone] = useState({
    ...proposalMilestoneDefault,
  });

  // Proposal non-labour expense
  const proposalNonLabourExpenseDefault = {
    category: "",
    categoryName: "",
    cost: "",
    marginPercentage: "",
    notes: "",
    proposalService: "",
    proposalServiceName: "",
  };
  const [proposalNonLabourExpense, setProposalNonLabourExpense] = useState({
    ...proposalNonLabourExpenseDefault,
  });

  // Proposal permission
  const proposalPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [proposalPermission, setProposalPermission] = useState({
    ...proposalPermissionDefault,
  });

  // Proposal question
  const proposalQuestionDefault = {
    answer: "",
    category: "Other",
    name: "",
    owner: "",
    ownerName: "",
    question: "",
    status: "Researching",
  };
  const [proposalQuestion, setProposalQuestion] = useState({
    ...proposalQuestionDefault,
  });

  // Proposal rate
  const proposalRateDefault = {
    marginPercentage: "",
    role: "",
    roleName: "",
  };
  const [proposalRate, setProposalRate] = useState({
    ...proposalRateDefault,
  });

  // Proposal requirement
  const proposalRequirementDefault = {
    attachment: "",
    attachmentName: "",
    category: "",
    name: "",
    owner: "",
    ownerName: "",
    ref: "",
    requirement: "",
    response: "",
    status: "Compliant",
  };
  const [proposalRequirement, setProposalRequirement] = useState({
    ...proposalRequirementDefault,
  });

  const proposalRequirementUploadFileDefault = {
    error: "",
    file: "",
    fileName: "",
  };
  const [proposalRequirementUploadFile, setProposalRequirementUploadFile] =
    useState({
      ...proposalRequirementUploadFileDefault,
    });

  // Proposal risk
  const proposalRiskDefault = {
    category: "Other",
    impact: "Medium",
    likelihood: "Medium",
    name: "",
    owner: "",
    ownerName: "",
    risk: "",
    status: "Open",
    strategy: "",
  };
  const [proposalRisk, setProposalRisk] = useState({
    ...proposalRiskDefault,
  });

  // Proposal service
  const proposalServiceDefault = {
    benefits: "",
    description: "",
    name: "",
    service: "",
    serviceName: "",
  };
  const [proposalService, setProposalService] = useState({
    ...proposalServiceDefault,
  });

  // Proposal service activity
  const proposalServiceActivityDefault = {
    description: "",
    end: new Date(),
    name: "",
    service: "",
    serviceName: "",
    start: new Date(),
  };
  const [proposalServiceActivity, setProposalServiceActivity] = useState({
    ...proposalServiceActivityDefault,
  });

  // Proposal service deliverable
  const proposalServiceDeliverableDefault = {
    description: "",
    due: new Date(),
    milestone: "",
    milestoneName: "",
    name: "",
    service: "",
    serviceName: "",
    template: "",
    templateName: "",
    type: "",
  };
  const [proposalServiceDeliverable, setProposalServiceDeliverable] = useState({
    ...proposalServiceDeliverableDefault,
  });

  // Proposal staff
  const proposalStaffDefault = {
    experience: "",
    expertise_1: "",
    expertise_2: "",
    expertise_3: "",
    responsibilities: "",
    role: "",
    staff: "",
    staffName: "",
  };
  const [proposalStaff, setProposalStaff] = useState({
    ...proposalStaffDefault,
  });

  // Proposal upload
  const proposalUploadDefault = {
    description: "",
    file: "",
    fileName: "",
    name: "",
  };
  const [proposalUpload, setProposalUpload] = useState({
    ...proposalUploadDefault,
  });

  // Other
  const [proposalDeleteKey, setProposalDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval request
    setApprovalRequest({
      ...approvalRequestDefault,
    });

    // Client KPI
    setClientKPI({
      ...clientKPIDefault,
    });

    // Proposal
    setUpdatedProposal({
      ...proposalDefault,
    });

    // Proposal action
    setProposalAction({
      ...proposalActionDefault,
    });

    // Proposal case study
    setProposalCaseStudy({
      ...proposalCaseStudyDefault,
    });

    // Proposal competitor
    setProposalCompetitor({
      ...proposalCompetitorDefault,
    });

    // Proposal contact
    setProposalContact({
      ...proposalContactDefault,
    });

    // Proposal fee
    setProposalFee({
      ...proposalFeeDefault,
    });

    // Proposal labour expense
    setProposalLabourExpense({
      ...proposalLabourExpenseDefault,
    });

    // Proposal legal section
    setProposalLegalSection({
      ...proposalLegalSectionDefault,
    });

    setProposalLegalSectionUploadFile({
      ...proposalLegalSectionUploadFileDefault,
    });

    // Proposal lesson
    setProposalLesson({
      ...proposalLessonDefault,
    });

    // Proposal milestone
    setProposalMilestone({
      ...proposalMilestoneDefault,
    });

    // Proposal non-labour expense
    setProposalNonLabourExpense({
      ...proposalNonLabourExpenseDefault,
    });

    // Proposal permission
    setProposalPermission({
      ...proposalPermissionDefault,
    });

    // Proposal question
    setProposalQuestion({
      ...proposalQuestionDefault,
    });

    // Proposal rate
    setProposalRate({
      ...proposalRateDefault,
    });

    // Proposal requirement
    setProposalRequirement({
      ...proposalRequirementDefault,
    });

    setProposalRequirementUploadFile({
      ...proposalRequirementUploadFileDefault,
    });

    // Proposal risk
    setProposalRisk({
      ...proposalRiskDefault,
    });

    // Proposal service
    setProposalService({
      ...proposalServiceDefault,
    });

    // Proposal service activity
    setProposalServiceActivity({
      ...proposalServiceActivityDefault,
    });

    // Proposal service deliverable
    setProposalServiceDeliverable({
      ...proposalServiceDeliverableDefault,
    });

    // Proposal staff
    setProposalStaff({
      ...proposalStaffDefault,
    });

    // Proposal upload
    setProposalUpload({
      ...proposalUploadDefault,
    });

    // Query variables
    // Case study
    setCaseStudyQuery({
      ...caseStudyQueryDefault,
    });

    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Competency
    setCompetencyQuery({
      ...competencyQueryDefault,
    });

    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Expense category
    setExpenseCategoryQuery({
      ...expenseCategoryQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Role
    setRoleQuery({
      ...roleQueryDefault,
    });

    // Service
    setServiceQuery({
      ...serviceQueryDefault,
    });

    // Staff
    setStaffQuery({
      ...staffQueryDefault,
    });
  };

  // Streaming data websocket connection
  const { lastJsonMessage } = useWebSocket(
    `${BACKEND_WS_URL}/api/v1/data/proposal/${id}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  // Initialise drawer variables for approval request
  useEffect(() => {
    setApprovalRequest({
      approver: approvalRequestDetails.approval_request.approver
        ? approvalRequestDetails.approval_request.approver
        : approvalRequestDefault.approver,
      approverName: approvalRequestDetails.approval_request.approver_name
        ? approvalRequestDetails.approval_request.approver_name
        : approvalRequestDefault.approverName,
      comments: approvalRequestDetails.approval_request.comments
        ? approvalRequestDetails.approval_request.comments
        : approvalRequestDefault.comments,
      decisionRight: approvalRequestDetails.approval_request.decision_right
        ? approvalRequestDetails.approval_request.decision_right
        : approvalRequestDefault.decisionRight,
      status: approvalRequestDetails.approval_request.status
        ? approvalRequestDetails.approval_request.status
        : approvalRequestDefault.status,
    });
    // eslint-disable-next-line
  }, [approvalRequestDetails.approval_request]);

  // Initialise drawer variables for client KPI
  useEffect(() => {
    setClientKPI({
      achieved: clientKPIDetails.client_kpi.achieved
        ? clientKPIDetails.client_kpi.achieved
        : clientKPIDefault.achieved,
      comments: clientKPIDetails.client_kpi.comments
        ? clientKPIDetails.client_kpi.comments
        : clientKPIDefault.comments,
      kpi: clientKPIDetails.client_kpi.kpi
        ? clientKPIDetails.client_kpi.kpi
        : clientKPIDefault.kpi,
      rating: clientKPIDetails.client_kpi.rating
        ? clientKPIDetails.client_kpi.rating
        : clientKPIDefault.rating,
      target: clientKPIDetails.client_kpi.target
        ? clientKPIDetails.client_kpi.target
        : clientKPIDefault.target,
      units: clientKPIDetails.client_kpi.units
        ? clientKPIDetails.client_kpi.units
        : clientKPIDefault.units,
      year: clientKPIDetails.client_kpi.year
        ? clientKPIDetails.client_kpi.year
        : clientKPIDefault.year,
    });
    // eslint-disable-next-line
  }, [clientKPIDetails.client_kpi]);

  // Initialise drawer variables for proposal
  useEffect(() => {
    setUpdatedProposal({
      approachDiagram: proposalDetails.proposal.approach_diagram
        ? proposalDetails.proposal.approach_diagram
        : proposalDefault.approachDiagram,
      approachDiagramName: proposalDetails.proposal.approach_diagram
        ? proposalDetails.proposal.approach_diagram.split("/").pop()
        : proposalDefault.approachDiagramName,
      approachSummary: proposalDetails.proposal.approach_summary
        ? proposalDetails.proposal.approach_summary
        : proposalDefault.approachSummary,
      changeDrivers: proposalDetails.proposal.change_drivers
        ? proposalDetails.proposal.change_drivers
        : proposalDefault.changeDrivers,
      client: proposalDetails.proposal.client
        ? proposalDetails.proposal.client
        : proposalDefault.client,
      clientName: proposalDetails.proposal.client_name
        ? proposalDetails.proposal.client_name
        : proposalDefault.clientName,
      closed: proposalDetails.proposal.closed
        ? new Date(proposalDetails.proposal.closed)
        : proposalDefault.closed,
      contact: proposalDetails.proposal.contact
        ? proposalDetails.proposal.contact
        : proposalDefault.contact,
      contactName: proposalDetails.proposal.contact_name
        ? proposalDetails.proposal.contact_name
        : proposalDefault.contactName,
      currency: proposalDetails.proposal.currency
        ? proposalDetails.proposal.currency
        : proposalDefault.currency,
      currencyExchangeRate: proposalDetails.proposal.currency_exchange_rate
        ? proposalDetails.proposal.currency_exchange_rate
        : proposalDefault.currencyExchangeRate,
      due: proposalDetails.proposal.due
        ? new Date(proposalDetails.proposal.due)
        : proposalDefault.due,
      feeNotes: proposalDetails.proposal.fee_notes
        ? proposalDetails.proposal.fee_notes
        : proposalDefault.feeNotes,
      issues: proposalDetails.proposal.issues
        ? proposalDetails.proposal.issues
        : proposalDefault.issues,
      manager: proposalDetails.proposal.manager
        ? proposalDetails.proposal.manager
        : proposalDefault.manager,
      managerName: proposalDetails.proposal.manager_name
        ? proposalDetails.proposal.manager_name
        : proposalDefault.managerName,
      name: proposalDetails.proposal.name
        ? proposalDetails.proposal.name
        : proposalDefault.name,
      notes: proposalDetails.proposal.notes
        ? proposalDetails.proposal.notes
        : proposalDefault.notes,
      ourSolution: proposalDetails.proposal.our_solution
        ? proposalDetails.proposal.our_solution
        : proposalDefault.ourSolution,
      qualificationBudgetComments: proposalDetails.proposal
        .qualification_budget_comments
        ? proposalDetails.proposal.qualification_budget_comments
        : proposalDefault.qualificationBudgetComments,
      qualificationBudgetScore: proposalDetails.proposal
        .qualification_budget_score
        ? proposalDetails.proposal.qualification_budget_score
        : proposalDefault.qualificationBudgetScore,
      qualificationBuyerComments: proposalDetails.proposal
        .qualification_buyer_comments
        ? proposalDetails.proposal.qualification_buyer_comments
        : proposalDefault.qualificationBuyerComments,
      qualificationBuyerScore: proposalDetails.proposal
        .qualification_buyer_score
        ? proposalDetails.proposal.qualification_buyer_score
        : proposalDefault.qualificationBuyerScore,
      qualificationCoachComments: proposalDetails.proposal
        .qualification_coach_comments
        ? proposalDetails.proposal.qualification_coach_comments
        : proposalDefault.qualificationCoachComments,
      qualificationCoachScore: proposalDetails.proposal
        .qualification_coach_score
        ? proposalDetails.proposal.qualification_coach_score
        : proposalDefault.qualificationCoachScore,
      qualificationDependenciesComments: proposalDetails.proposal
        .qualification_dependencies_comments
        ? proposalDetails.proposal.qualification_dependencies_comments
        : proposalDefault.qualificationDependenciesComments,
      qualificationDependenciesScore: proposalDetails.proposal
        .qualification_dependencies_score
        ? proposalDetails.proposal.qualification_dependencies_score
        : proposalDefault.qualificationDependenciesScore,
      qualificationMarketComments: proposalDetails.proposal
        .qualification_market_comments
        ? proposalDetails.proposal.qualification_market_comments
        : proposalDefault.qualificationMarketComments,
      qualificationMarketScore: proposalDetails.proposal
        .qualification_market_score
        ? proposalDetails.proposal.qualification_market_score
        : proposalDefault.qualificationMarketScore,
      qualificationNeedComments: proposalDetails.proposal
        .qualification_need_comments
        ? proposalDetails.proposal.qualification_need_comments
        : proposalDefault.qualificationNeedComments,
      qualificationNeedScore: proposalDetails.proposal.qualification_need_score
        ? proposalDetails.proposal.qualification_need_score
        : proposalDefault.qualificationNeedScore,
      qualificationRelationshipComments: proposalDetails.proposal
        .qualification_relationship_comments
        ? proposalDetails.proposal.qualification_relationship_comments
        : proposalDefault.qualificationRelationshipComments,
      qualificationRelationshipScore: proposalDetails.proposal
        .qualification_relationship_score
        ? proposalDetails.proposal.qualification_relationship_score
        : proposalDefault.qualificationRelationshipScore,
      qualificationReputationComments: proposalDetails.proposal
        .qualification_reputation_comments
        ? proposalDetails.proposal.qualification_reputation_comments
        : proposalDefault.qualificationReputationComments,
      qualificationReputationScore: proposalDetails.proposal
        .qualification_reputation_score
        ? proposalDetails.proposal.qualification_reputation_score
        : proposalDefault.qualificationReputationScore,
      qualificationServicesComments: proposalDetails.proposal
        .qualification_services_comments
        ? proposalDetails.proposal.qualification_services_comments
        : proposalDefault.qualificationServicesComments,
      qualificationServicesScore: proposalDetails.proposal
        .qualification_services_score
        ? proposalDetails.proposal.qualification_services_score
        : proposalDefault.qualificationServicesScore,
      qualificationSolutionComments: proposalDetails.proposal
        .qualification_solution_comments
        ? proposalDetails.proposal.qualification_solution_comments
        : proposalDefault.qualificationSolutionComments,
      qualificationSolutionScore: proposalDetails.proposal
        .qualification_solution_score
        ? proposalDetails.proposal.qualification_solution_score
        : proposalDefault.qualificationSolutionScore,
      qualificationTeamComments: proposalDetails.proposal
        .qualification_team_comments
        ? proposalDetails.proposal.qualification_team_comments
        : proposalDefault.qualificationTeamComments,
      qualificationTeamScore: proposalDetails.proposal.qualification_team_score
        ? proposalDetails.proposal.qualification_team_score
        : proposalDefault.qualificationTeamScore,
      qualificationTimingComments: proposalDetails.proposal
        .qualification_timing_comments
        ? proposalDetails.proposal.qualification_timing_comments
        : proposalDefault.qualificationTimingComments,
      qualificationTimingScore: proposalDetails.proposal
        .qualification_timing_score
        ? proposalDetails.proposal.qualification_timing_score
        : proposalDefault.qualificationTimingScore,
      restricted:
        proposalDetails.proposal.restricted != null
          ? proposalDetails.proposal.restricted
          : proposalDefault.restricted,
      rfp: proposalDetails.proposal.rfp
        ? proposalDetails.proposal.rfp
        : proposalDefault.rfp,
      rfpName: proposalDetails.proposal.rfp
        ? proposalDetails.proposal.rfp.split("/").pop()
        : proposalDefault.rfpName,
      status: proposalDetails.proposal.status
        ? proposalDetails.proposal.status
        : proposalDefault.status,
      strategicOptions: proposalDetails.proposal.strategic_options
        ? proposalDetails.proposal.strategic_options
        : proposalDefault.strategicOptions,
      submitted: proposalDetails.proposal.submitted
        ? new Date(proposalDetails.proposal.submitted)
        : proposalDefault.submitted,
      tax: proposalDetails.proposal.tax
        ? proposalDetails.proposal.tax
        : proposalDefault.tax,
      theoryOfChange: proposalDetails.proposal.theory_of_change
        ? proposalDetails.proposal.theory_of_change
        : proposalDefault.theoryOfChange,
      value: proposalDetails.proposal.value
        ? proposalDetails.proposal.value
        : proposalDefault.value,
      winStrategyCommercials: proposalDetails.proposal.win_strategy_commercials
        ? proposalDetails.proposal.win_strategy_commercials
        : proposalDefault.winStrategyCommercials,
      winStrategyOther: proposalDetails.proposal.win_strategy_other
        ? proposalDetails.proposal.win_strategy_other
        : proposalDefault.winStrategyOther,
      winStrategyPartnerships: proposalDetails.proposal
        .win_strategy_partnerships
        ? proposalDetails.proposal.win_strategy_partnerships
        : proposalDefault.winStrategyPartnerships,
      winStrategyPrice: proposalDetails.proposal.win_strategy_price
        ? proposalDetails.proposal.win_strategy_price
        : proposalDefault.winStrategyPrice,
      winStrategyReferences: proposalDetails.proposal.win_strategy_references
        ? proposalDetails.proposal.win_strategy_references
        : proposalDefault.winStrategyReferences,
      winStrategyRelationships: proposalDetails.proposal
        .win_strategy_relationships
        ? proposalDetails.proposal.win_strategy_relationships
        : proposalDefault.winStrategyRelationships,
      winStrategySolution: proposalDetails.proposal.win_strategy_solution
        ? proposalDetails.proposal.win_strategy_solution
        : proposalDefault.winStrategySolution,
      winStrategyTeam: proposalDetails.proposal.win_strategy_team
        ? proposalDetails.proposal.win_strategy_team
        : proposalDefault.winStrategyTeam,
    });
    // eslint-disable-next-line
  }, [proposalDetails.proposal]);

  // Initialise drawer variables for proposal action
  useEffect(() => {
    setProposalAction({
      action: proposalActionDetails.proposal_action.action
        ? proposalActionDetails.proposal_action.action
        : proposalActionDefault.action,
      category: proposalActionDetails.proposal_action.category
        ? proposalActionDetails.proposal_action.category
        : proposalActionDefault.category,
      comments: proposalActionDetails.proposal_action.comments
        ? proposalActionDetails.proposal_action.comments
        : proposalActionDefault.comments,
      due: proposalActionDetails.proposal_action.due
        ? new Date(proposalActionDetails.proposal_action.due)
        : proposalActionDefault.due,
      name: proposalActionDetails.proposal_action.name
        ? proposalActionDetails.proposal_action.name
        : proposalActionDefault.name,
      owner: proposalActionDetails.proposal_action.owner
        ? proposalActionDetails.proposal_action.owner
        : proposalActionDefault.owner,
      ownerName: proposalActionDetails.proposal_action.owner_name
        ? proposalActionDetails.proposal_action.owner_name
        : proposalActionDefault.ownerName,
      status: proposalActionDetails.proposal_action.status
        ? proposalActionDetails.proposal_action.status
        : proposalActionDefault.status,
    });
    // eslint-disable-next-line
  }, [proposalActionDetails.proposal_action]);

  // Initialise drawer variables for proposal case study
  useEffect(() => {
    setProposalCaseStudy({
      caseStudy: proposalCaseStudyDetails.proposal_case_study.case_study
        ? proposalCaseStudyDetails.proposal_case_study.case_study
        : proposalCaseStudyDefault.caseStudy,
      caseStudyName: proposalCaseStudyDetails.proposal_case_study
        .case_study_data
        ? proposalCaseStudyDetails.proposal_case_study.case_study_data.name
        : proposalCaseStudyDefault.caseStudyName,
    });
    // eslint-disable-next-line
  }, [proposalCaseStudyDetails.proposal_case_study]);

  // Initialise drawer variables for proposal competitor
  useEffect(() => {
    setProposalCompetitor({
      commercials: proposalCompetitorDetails.proposal_competitor.commercials
        ? proposalCompetitorDetails.proposal_competitor.commercials
        : proposalCompetitorDefault.commercials,
      competitor: proposalCompetitorDetails.proposal_competitor.competitor
        ? proposalCompetitorDetails.proposal_competitor.competitor
        : proposalCompetitorDefault.competitor,
      notes: proposalCompetitorDetails.proposal_competitor.notes
        ? proposalCompetitorDetails.proposal_competitor.notes
        : proposalCompetitorDefault.notes,
      partnerships: proposalCompetitorDetails.proposal_competitor.partnerships
        ? proposalCompetitorDetails.proposal_competitor.partnerships
        : proposalCompetitorDefault.partnerships,
      price: proposalCompetitorDetails.proposal_competitor.price
        ? proposalCompetitorDetails.proposal_competitor.price
        : proposalCompetitorDefault.price,
      references: proposalCompetitorDetails.proposal_competitor.references
        ? proposalCompetitorDetails.proposal_competitor.references
        : proposalCompetitorDefault.references,
      relationships: proposalCompetitorDetails.proposal_competitor.relationships
        ? proposalCompetitorDetails.proposal_competitor.relationships
        : proposalCompetitorDefault.relationships,
      solution: proposalCompetitorDetails.proposal_competitor.solution
        ? proposalCompetitorDetails.proposal_competitor.solution
        : proposalCompetitorDefault.solution,
      team: proposalCompetitorDetails.proposal_competitor.team
        ? proposalCompetitorDetails.proposal_competitor.team
        : proposalCompetitorDefault.team,
    });
    // eslint-disable-next-line
  }, [proposalCompetitorDetails.proposal_competitor]);

  // Initialise drawer variables for proposal contact
  useEffect(() => {
    setProposalContact({
      buyerRole: proposalContactDetails.proposal_contact.buyer_role
        ? proposalContactDetails.proposal_contact.buyer_role
        : proposalContactDefault.buyerRole,
      comments: proposalContactDetails.proposal_contact.comments
        ? proposalContactDetails.proposal_contact.comments
        : proposalContactDefault.comments,
      contact: proposalContactDetails.proposal_contact.contact
        ? proposalContactDetails.proposal_contact.contact
        : proposalContactDefault.contact,
      contactName: proposalContactDetails.proposal_contact.contact_data
        ? proposalContactDetails.proposal_contact.contact_data.name
        : proposalContactDefault.contactName,
      levelOfInfluence: proposalContactDetails.proposal_contact
        .level_of_influence
        ? proposalContactDetails.proposal_contact.level_of_influence
        : proposalContactDefault.levelOfInfluence,
      relationshipOwner: proposalContactDetails.proposal_contact
        .relationship_owner
        ? proposalContactDetails.proposal_contact.relationship_owner
        : proposalContactDefault.relationshipOwner,
      relationshipOwnerName: proposalContactDetails.proposal_contact
        .relationship_owner_name
        ? proposalContactDetails.proposal_contact.relationship_owner_name
        : proposalContactDefault.relationshipOwnerName,
    });
    // eslint-disable-next-line
  }, [proposalContactDetails.proposal_contact]);

  // Initialise drawer variables for proposal document
  useEffect(() => {
    setProposalDocument({
      additionalContent: proposalDocumentDetails.proposal_document
        .additional_content
        ? proposalDocumentDetails.proposal_document.additional_content
        : proposalDocumentDefault.additionalContent,
      approachDiagram: proposalDocumentDetails.proposal_document
        .approach_diagram
        ? proposalDocumentDetails.proposal_document.approach_diagram
        : proposalDocumentDefault.approachDiagram,
      approachDiagramName: proposalDocumentDetails.proposal_document
        .approach_diagram
        ? proposalDocumentDetails.proposal_document.approach_diagram
            .split("/")
            .pop()
        : proposalDocumentDefault.approachDiagramName,
      approachHeading: proposalDocumentDetails.proposal_document
        .approach_heading
        ? proposalDocumentDetails.proposal_document.approach_heading
        : proposalDocumentDefault.approachHeading,
      approachSummary: proposalDocumentDetails.proposal_document
        .approach_summary
        ? proposalDocumentDetails.proposal_document.approach_summary
        : proposalDocumentDefault.approachSummary,
      backPageText: proposalDocumentDetails.proposal_document.back_page_text
        ? proposalDocumentDetails.proposal_document.back_page_text
        : proposalDocumentDefault.backPageText,
      backgroundImage: proposalDocumentDetails.proposal_document
        .background_image
        ? proposalDocumentDetails.proposal_document.background_image
        : proposalDocumentDefault.backgroundImage,
      backgroundImageName: proposalDocumentDetails.proposal_document
        .background_image
        ? proposalDocumentDetails.proposal_document.background_image
            .split("/")
            .pop()
        : proposalDocumentDefault.backgroundImageName,
      caseStudiesHeading: proposalDocumentDetails.proposal_document
        .case_studies_heading
        ? proposalDocumentDetails.proposal_document.case_studies_heading
        : proposalDocumentDefault.caseStudiesHeading,
      changeDrivers: proposalDocumentDetails.proposal_document.change_drivers
        ? proposalDocumentDetails.proposal_document.change_drivers
        : proposalDocumentDefault.changeDrivers,
      changeDriversHeading: proposalDocumentDetails.proposal_document
        .change_drivers_heading
        ? proposalDocumentDetails.proposal_document.change_drivers_heading
        : proposalDocumentDefault.changeDriversHeading,
      client: proposalDocumentDetails.proposal_document.client
        ? proposalDocumentDetails.proposal_document.client
        : proposalDocumentDefault.client,
      clientAddress: proposalDocumentDetails.proposal_document.client_address
        ? proposalDocumentDetails.proposal_document.client_address
        : proposalDocumentDefault.clientAddress,
      clientAlias: proposalDocumentDetails.proposal_document.client_alias
        ? proposalDocumentDetails.proposal_document.client_alias
        : proposalDocumentDefault.clientAlias,
      clientDepartment: proposalDocumentDetails.proposal_document
        .client_department
        ? proposalDocumentDetails.proposal_document.client_department
        : proposalDocumentDefault.clientDepartment,
      clientEntity: proposalDocumentDetails.proposal_document.client_entity
        ? proposalDocumentDetails.proposal_document.client_entity
        : proposalDocumentDefault.clientEntity,
      close: proposalDocumentDetails.proposal_document.close
        ? proposalDocumentDetails.proposal_document.close
        : proposalDocumentDefault.close,
      commitment: proposalDocumentDetails.proposal_document.commitment
        ? proposalDocumentDetails.proposal_document.commitment
        : proposalDocumentDefault.commitment,
      company: proposalDocumentDetails.proposal_document.company
        ? proposalDocumentDetails.proposal_document.company
        : proposalDocumentDefault.company,
      companyAddress: proposalDocumentDetails.proposal_document.company_address
        ? proposalDocumentDetails.proposal_document.company_address
        : proposalDocumentDefault.companyAddress,
      companyAlias: proposalDocumentDetails.proposal_document.company_alias
        ? proposalDocumentDetails.proposal_document.company_alias
        : proposalDocumentDefault.companyAlias,
      companyDepartment: proposalDocumentDetails.proposal_document
        .company_department
        ? proposalDocumentDetails.proposal_document.company_department
        : proposalDocumentDefault.companyDepartment,
      companyEmail: proposalDocumentDetails.proposal_document.company_email
        ? proposalDocumentDetails.proposal_document.company_email
        : proposalDocumentDefault.companyEmail,
      companyEntity: proposalDocumentDetails.proposal_document.company_entity
        ? proposalDocumentDetails.proposal_document.company_entity
        : proposalDocumentDefault.companyEntity,
      companyPhone: proposalDocumentDetails.proposal_document.company_phone
        ? proposalDocumentDetails.proposal_document.company_phone
        : proposalDocumentDefault.companyPhone,
      companySummary: proposalDocumentDetails.proposal_document.company_summary
        ? proposalDocumentDetails.proposal_document.company_summary
        : proposalDocumentDefault.companySummary,
      contact: proposalDocumentDetails.proposal_document.contact
        ? proposalDocumentDetails.proposal_document.contact
        : proposalDocumentDefault.contact,
      contactRole: proposalDocumentDetails.proposal_document.contact_role
        ? proposalDocumentDetails.proposal_document.contact_role
        : proposalDocumentDefault.contactRole,
      date: proposalDocumentDetails.proposal_document.date
        ? proposalDocumentDetails.proposal_document.date
        : proposalDocumentDefault.date,
      feesHeading: proposalDocumentDetails.proposal_document.fees_heading
        ? proposalDocumentDetails.proposal_document.fees_heading
        : proposalDocumentDefault.feesHeading,
      font: proposalDocumentDetails.proposal_document.font
        ? proposalDocumentDetails.proposal_document.font
        : proposalDocumentDefault.font,
      includeApproach:
        proposalDocumentDetails.proposal_document.include_approach !== null
          ? proposalDocumentDetails.proposal_document.include_approach
          : proposalDocumentDefault.includeApproach,
      includeCaseStudies:
        proposalDocumentDetails.proposal_document.include_case_studies !== null
          ? proposalDocumentDetails.proposal_document.include_case_studies
          : proposalDocumentDefault.includeCaseStudies,
      includeCoverLetter:
        proposalDocumentDetails.proposal_document.include_cover_letter !== null
          ? proposalDocumentDetails.proposal_document.include_cover_letter
          : proposalDocumentDefault.includeCoverLetter,
      includeFeeBreakdown:
        proposalDocumentDetails.proposal_document.include_fee_breakdown !== null
          ? proposalDocumentDetails.proposal_document.include_fee_breakdown
          : proposalDocumentDefault.includeFeeBreakdown,
      includeFees:
        proposalDocumentDetails.proposal_document.include_fees !== null
          ? proposalDocumentDetails.proposal_document.include_fees
          : proposalDocumentDefault.includeFees,
      includeLegal:
        proposalDocumentDetails.proposal_document.include_legal !== null
          ? proposalDocumentDetails.proposal_document.include_legal
          : proposalDocumentDefault.includeLegal,
      includeNeeds:
        proposalDocumentDetails.proposal_document.include_needs !== null
          ? proposalDocumentDetails.proposal_document.include_needs
          : proposalDocumentDefault.includeNeeds,
      includeResponses:
        proposalDocumentDetails.proposal_document.include_responses !== null
          ? proposalDocumentDetails.proposal_document.include_responses
          : proposalDocumentDefault.includeResponses,
      includeServices:
        proposalDocumentDetails.proposal_document.include_services !== null
          ? proposalDocumentDetails.proposal_document.include_services
          : proposalDocumentDefault.includeServices,
      includeTeam:
        proposalDocumentDetails.proposal_document.include_team !== null
          ? proposalDocumentDetails.proposal_document.include_team
          : proposalDocumentDefault.includeTeam,
      includeWhyUs:
        proposalDocumentDetails.proposal_document.include_why_us !== null
          ? proposalDocumentDetails.proposal_document.include_why_us
          : proposalDocumentDefault.includeWhyUs,
      introCaseStudies: proposalDocumentDetails.proposal_document
        .intro_case_studies
        ? proposalDocumentDetails.proposal_document.intro_case_studies
        : proposalDocumentDefault.introCaseStudies,
      introFees: proposalDocumentDetails.proposal_document.intro_fees
        ? proposalDocumentDetails.proposal_document.intro_fees
        : proposalDocumentDefault.introFees,
      introRequirements: proposalDocumentDetails.proposal_document
        .intro_requirements
        ? proposalDocumentDetails.proposal_document.intro_requirements
        : proposalDocumentDefault.introRequirements,
      introServices: proposalDocumentDetails.proposal_document.intro_services
        ? proposalDocumentDetails.proposal_document.intro_services
        : proposalDocumentDefault.introServices,
      introTeam: proposalDocumentDetails.proposal_document.intro_team
        ? proposalDocumentDetails.proposal_document.intro_team
        : proposalDocumentDefault.introTeam,
      issues: proposalDocumentDetails.proposal_document.issues
        ? proposalDocumentDetails.proposal_document.issues
        : proposalDocumentDefault.issues,
      issuesHeading: proposalDocumentDetails.proposal_document.issues_heading
        ? proposalDocumentDetails.proposal_document.issues_heading
        : proposalDocumentDefault.issuesHeading,
      legalHeading: proposalDocumentDetails.proposal_document.legal_heading
        ? proposalDocumentDetails.proposal_document.legal_heading
        : proposalDocumentDefault.legalHeading,
      logo: proposalDocumentDetails.proposal_document.logo
        ? proposalDocumentDetails.proposal_document.logo
        : proposalDocumentDefault.logo,
      logoName: proposalDocumentDetails.proposal_document.logo
        ? proposalDocumentDetails.proposal_document.logo.split("/").pop()
        : proposalDocumentDefault.logoName,
      manager: proposalDocumentDetails.proposal_document.manager
        ? proposalDocumentDetails.proposal_document.manager
        : proposalDocumentDefault.manager,
      managerEmail: proposalDocumentDetails.proposal_document.manager_email
        ? proposalDocumentDetails.proposal_document.manager_email
        : proposalDocumentDefault.managerEmail,
      managerPhone: proposalDocumentDetails.proposal_document.manager_phone
        ? proposalDocumentDetails.proposal_document.manager_phone
        : proposalDocumentDefault.managerPhone,
      managerRole: proposalDocumentDetails.proposal_document.manager_role
        ? proposalDocumentDetails.proposal_document.manager_role
        : proposalDocumentDefault.managerRole,
      needsHeading: proposalDocumentDetails.proposal_document.needs_heading
        ? proposalDocumentDetails.proposal_document.needs_heading
        : proposalDocumentDefault.needsHeading,
      opening: proposalDocumentDetails.proposal_document.opening
        ? proposalDocumentDetails.proposal_document.opening
        : proposalDocumentDefault.opening,
      ourSolution: proposalDocumentDetails.proposal_document.our_solution
        ? proposalDocumentDetails.proposal_document.our_solution
        : proposalDocumentDefault.ourSolution,
      ourSolutionHeading: proposalDocumentDetails.proposal_document
        .our_solution_heading
        ? proposalDocumentDetails.proposal_document.our_solution_heading
        : proposalDocumentDefault.ourSolutionHeading,
      primaryColour: proposalDocumentDetails.proposal_document.primary_colour
        ? proposalDocumentDetails.proposal_document.primary_colour
        : proposalDocumentDefault.primaryColour,
      reference: proposalDocumentDetails.proposal_document.reference
        ? proposalDocumentDetails.proposal_document.reference
        : proposalDocumentDefault.reference,
      responsesHeading: proposalDocumentDetails.proposal_document
        .responses_heading
        ? proposalDocumentDetails.proposal_document.responses_heading
        : proposalDocumentDefault.responsesHeading,
      salutation: proposalDocumentDetails.proposal_document.salutation
        ? proposalDocumentDetails.proposal_document.salutation
        : proposalDocumentDefault.salutation,
      scheduleDiagram: proposalDocumentDetails.proposal_document
        .schedule_diagram
        ? proposalDocumentDetails.proposal_document.schedule_diagram
        : proposalDocumentDefault.scheduleDiagram,
      scheduleDiagramName: proposalDocumentDetails.proposal_document
        .schedule_diagram
        ? proposalDocumentDetails.proposal_document.schedule_diagram
            .split("/")
            .pop()
        : proposalDocumentDefault.scheduleDiagramName,
      secondaryColour: proposalDocumentDetails.proposal_document
        .secondary_colour
        ? proposalDocumentDetails.proposal_document.secondary_colour
        : proposalDocumentDefault.secondaryColour,
      servicesHeading: proposalDocumentDetails.proposal_document
        .services_heading
        ? proposalDocumentDetails.proposal_document.services_heading
        : proposalDocumentDefault.servicesHeading,
      signature: proposalDocumentDetails.proposal_document.signature
        ? proposalDocumentDetails.proposal_document.signature
        : proposalDocumentDefault.signature,
      signatureName: proposalDocumentDetails.proposal_document.signature
        ? proposalDocumentDetails.proposal_document.signature.split("/").pop()
        : proposalDocumentDefault.signatureName,
      strategicOptions: proposalDocumentDetails.proposal_document
        .strategic_options
        ? proposalDocumentDetails.proposal_document.strategic_options
        : proposalDocumentDefault.strategicOptions,
      strategicOptionsHeading: proposalDocumentDetails.proposal_document
        .strategic_options_heading
        ? proposalDocumentDetails.proposal_document.strategic_options_heading
        : proposalDocumentDefault.strategicOptionsHeading,
      teamHeading: proposalDocumentDetails.proposal_document.team_heading
        ? proposalDocumentDetails.proposal_document.team_heading
        : proposalDocumentDefault.teamHeading,
      template: proposalDocumentDetails.proposal_document.template
        ? proposalDocumentDetails.proposal_document.template
        : proposalDocumentDefault.template,
      theoryOfChange: proposalDocumentDetails.proposal_document.theory_of_change
        ? proposalDocumentDetails.proposal_document.theory_of_change
        : proposalDocumentDefault.theoryOfChange,
      theoryOfChangeHeading: proposalDocumentDetails.proposal_document
        .theory_of_change_heading
        ? proposalDocumentDetails.proposal_document.theory_of_change_heading
        : proposalDocumentDefault.theoryOfChangeHeading,
      title: proposalDocumentDetails.proposal_document.title
        ? proposalDocumentDetails.proposal_document.title
        : proposalDocumentDefault.title,
      whyUsHeading: proposalDocumentDetails.proposal_document.why_us_heading
        ? proposalDocumentDetails.proposal_document.why_us_heading
        : proposalDocumentDefault.whyUsHeading,
      winStrategyCommercials: proposalDocumentDetails.proposal_document
        .win_strategy_commercials
        ? proposalDocumentDetails.proposal_document.win_strategy_commercials
        : proposalDocumentDefault.winStrategyCommercials,
      winStrategyCommercialsHeading: proposalDocumentDetails.proposal_document
        .win_strategy_commercials_heading
        ? proposalDocumentDetails.proposal_document
            .win_strategy_commercials_heading
        : proposalDocumentDefault.winStrategyCommercialsHeading,
      winStrategyOther: proposalDocumentDetails.proposal_document
        .win_strategy_other
        ? proposalDocumentDetails.proposal_document.win_strategy_other
        : proposalDocumentDefault.winStrategyOther,
      winStrategyOtherHeading: proposalDocumentDetails.proposal_document
        .win_strategy_other_heading
        ? proposalDocumentDetails.proposal_document.win_strategy_other_heading
        : proposalDocumentDefault.winStrategyOtherHeading,
      winStrategyPartnerships: proposalDocumentDetails.proposal_document
        .win_strategy_partnerships
        ? proposalDocumentDetails.proposal_document.win_strategy_partnerships
        : proposalDocumentDefault.winStrategyPartnerships,
      winStrategyPartnershipsHeading: proposalDocumentDetails.proposal_document
        .win_strategy_partnerships_heading
        ? proposalDocumentDetails.proposal_document
            .win_strategy_partnerships_heading
        : proposalDocumentDefault.winStrategyPartnershipsHeading,
      winStrategyPrice: proposalDocumentDetails.proposal_document
        .win_strategy_price
        ? proposalDocumentDetails.proposal_document.win_strategy_price
        : proposalDocumentDefault.winStrategyPrice,
      winStrategyPriceHeading: proposalDocumentDetails.proposal_document
        .win_strategy_price_heading
        ? proposalDocumentDetails.proposal_document.win_strategy_price_heading
        : proposalDocumentDefault.winStrategyPriceHeading,
      winStrategyReferences: proposalDocumentDetails.proposal_document
        .win_strategy_references
        ? proposalDocumentDetails.proposal_document.win_strategy_references
        : proposalDocumentDefault.winStrategyReferences,
      winStrategyReferencesHeading: proposalDocumentDetails.proposal_document
        .win_strategy_references_heading
        ? proposalDocumentDetails.proposal_document
            .win_strategy_references_heading
        : proposalDocumentDefault.winStrategyReferencesHeading,
      winStrategyRelationships: proposalDocumentDetails.proposal_document
        .win_strategy_relationships
        ? proposalDocumentDetails.proposal_document.win_strategy_relationships
        : proposalDocumentDefault.winStrategyRelationships,
      winStrategyRelationshipsHeading: proposalDocumentDetails.proposal_document
        .win_strategy_relationships_heading
        ? proposalDocumentDetails.proposal_document
            .win_strategy_relationships_heading
        : proposalDocumentDefault.winStrategyRelationshipsHeading,
      winStrategySolution: proposalDocumentDetails.proposal_document
        .win_strategy_solution
        ? proposalDocumentDetails.proposal_document.win_strategy_solution
        : proposalDocumentDefault.winStrategySolution,
      winStrategySolutionHeading: proposalDocumentDetails.proposal_document
        .win_strategy_solution_heading
        ? proposalDocumentDetails.proposal_document
            .win_strategy_solution_heading
        : proposalDocumentDefault.winStrategySolutionHeading,
      winStrategyTeam: proposalDocumentDetails.proposal_document
        .win_strategy_team
        ? proposalDocumentDetails.proposal_document.win_strategy_team
        : proposalDocumentDefault.winStrategyTeam,
      winStrategyTeamHeading: proposalDocumentDetails.proposal_document
        .win_strategy_team_heading
        ? proposalDocumentDetails.proposal_document.win_strategy_team_heading
        : proposalDocumentDefault.winStrategyTeamHeading,
    });
    // eslint-disable-next-line
  }, [proposalDocumentDetails.proposal_document]);

  // Initialise drawer variables for proposal fee
  useEffect(() => {
    setProposalFee({
      adjustment: proposalFeeDetails.proposal_fee.adjustment
        ? proposalFeeDetails.proposal_fee.adjustment
        : proposalFeeDefault.adjustment,
    });
    // eslint-disable-next-line
  }, [proposalFeeDetails.proposal_fee]);

  // Initialise drawer variables for proposal labour expense
  useEffect(() => {
    setProposalLabourExpense({
      competencies:
        proposalLabourExpenseDetails.proposal_labour_expense.competencies &&
        proposalLabourExpenseDetails.proposal_labour_expense.competencies
          .length > 0
          ? proposalLabourExpenseDetails.proposal_labour_expense.competencies
          : proposalLabourExpenseDefault.competencies,
      customiseDays:
        proposalLabourExpenseDetails.proposal_labour_expense.detail &&
        Object.keys(proposalLabourExpenseDetails.proposal_labour_expense.detail)
          .length > 0
          ? true
          : false,
      detail:
        proposalLabourExpenseDetails.proposal_labour_expense.detail &&
        Object.keys(proposalLabourExpenseDetails.proposal_labour_expense.detail)
          .length > 0
          ? proposalLabourExpenseDetails.proposal_labour_expense.detail
          : proposalLabourExpenseDefault.detail,
      employment: proposalLabourExpenseDetails.proposal_labour_expense
        .employment
        ? proposalLabourExpenseDetails.proposal_labour_expense.employment
        : proposalLabourExpenseDefault.employment,
      finish: proposalLabourExpenseDetails.proposal_labour_expense.finish
        ? new Date(proposalLabourExpenseDetails.proposal_labour_expense.finish)
        : proposalLabourExpenseDefault.finish,
      location: proposalLabourExpenseDetails.proposal_labour_expense.location
        ? proposalLabourExpenseDetails.proposal_labour_expense.location
        : proposalLabourExpenseDefault.location,
      locationName: proposalLabourExpenseDetails.proposal_labour_expense
        .location_data
        ? proposalLabourExpenseDetails.proposal_labour_expense.location_data
            .name
        : proposalLabourExpenseDefault.locationName,
      marginPercentage: proposalLabourExpenseDetails.proposal_labour_expense
        .margin_percentage
        ? proposalLabourExpenseDetails.proposal_labour_expense.margin_percentage
        : proposalLabourExpenseDefault.marginPercentage,
      method: proposalLabourExpenseDetails.proposal_labour_expense.method
        ? proposalLabourExpenseDetails.proposal_labour_expense.method
        : proposalLabourExpenseDefault.method,
      minutes: proposalLabourExpenseDetails.proposal_labour_expense.minutes
        ? proposalLabourExpenseDetails.proposal_labour_expense.minutes
        : proposalLabourExpenseDefault.minutes,
      notes: proposalLabourExpenseDetails.proposal_labour_expense.notes
        ? proposalLabourExpenseDetails.proposal_labour_expense.notes
        : proposalLabourExpenseDefault.notes,
      proposalService: proposalLabourExpenseDetails.proposal_labour_expense
        .proposal_service
        ? proposalLabourExpenseDetails.proposal_labour_expense.proposal_service
        : proposalLabourExpenseDefault.proposalService,
      proposalServiceName: proposalLabourExpenseDetails.proposal_labour_expense
        .proposal_service_name
        ? proposalLabourExpenseDetails.proposal_labour_expense
            .proposal_service_name
        : proposalLabourExpenseDefault.proposalServiceName,
      role: proposalLabourExpenseDetails.proposal_labour_expense.role
        ? proposalLabourExpenseDetails.proposal_labour_expense.role
        : proposalLabourExpenseDefault.role,
      roleName: proposalLabourExpenseDetails.proposal_labour_expense.role_data
        ? proposalLabourExpenseDetails.proposal_labour_expense.role_data.name
        : proposalLabourExpenseDefault.roleName,
      staff: proposalLabourExpenseDetails.proposal_labour_expense.staff
        ? proposalLabourExpenseDetails.proposal_labour_expense.staff
        : proposalLabourExpenseDefault.staff,
      staffName: proposalLabourExpenseDetails.proposal_labour_expense.staff_name
        ? proposalLabourExpenseDetails.proposal_labour_expense.staff_name
        : proposalLabourExpenseDefault.staffName,
      start: proposalLabourExpenseDetails.proposal_labour_expense.start
        ? new Date(proposalLabourExpenseDetails.proposal_labour_expense.start)
        : proposalLabourExpenseDefault.start,
      weekdays:
        proposalLabourExpenseDetails.proposal_labour_expense.weekdays &&
        Object.keys(
          proposalLabourExpenseDetails.proposal_labour_expense.weekdays
        ).length > 0
          ? proposalLabourExpenseDetails.proposal_labour_expense.weekdays
          : proposalLabourExpenseDefault.weekdays,
    });
    // eslint-disable-next-line
  }, [proposalLabourExpenseDetails.proposal_labour_expense]);

  // Initialise drawer variables for proposal legal section
  useEffect(() => {
    setProposalLegalSection({
      amended: proposalLegalSectionDetails.proposal_legal_section.amended
        ? proposalLegalSectionDetails.proposal_legal_section.amended
        : proposalLegalSectionDefault.amended,
      attachment: proposalLegalSectionDetails.proposal_legal_section.attachment
        ? proposalLegalSectionDetails.proposal_legal_section.attachment
        : proposalLegalSectionDefault.attachment,
      attachmentName: proposalLegalSectionDetails.proposal_legal_section
        .attachment
        ? proposalLegalSectionDetails.proposal_legal_section.attachment
            .split("/")
            .pop()
        : proposalLegalSectionDefault.attachmentName,
      comments: proposalLegalSectionDetails.proposal_legal_section.comments
        ? proposalLegalSectionDetails.proposal_legal_section.comments
        : proposalLegalSectionDefault.comments,
      number: proposalLegalSectionDetails.proposal_legal_section.number
        ? proposalLegalSectionDetails.proposal_legal_section.number
        : proposalLegalSectionDefault.number,
      originator: proposalLegalSectionDetails.proposal_legal_section.originator
        ? proposalLegalSectionDetails.proposal_legal_section.originator
        : proposalLegalSectionDefault.originator,
      proposed: proposalLegalSectionDetails.proposal_legal_section.proposed
        ? proposalLegalSectionDetails.proposal_legal_section.proposed
        : proposalLegalSectionDefault.proposed,
      title: proposalLegalSectionDetails.proposal_legal_section.title
        ? proposalLegalSectionDetails.proposal_legal_section.title
        : proposalLegalSectionDefault.title,
      status: proposalLegalSectionDetails.proposal_legal_section.status
        ? proposalLegalSectionDetails.proposal_legal_section.status
        : proposalLegalSectionDefault.status,
    });
    // eslint-disable-next-line
  }, [proposalLegalSectionDetails.proposal_legal_section]);

  // Initialise drawer variables for proposal lesson
  useEffect(() => {
    setProposalLesson({
      category: proposalLessonDetails.proposal_lesson.category
        ? proposalLessonDetails.proposal_lesson.category
        : proposalLessonDefault.category,
      lesson: proposalLessonDetails.proposal_lesson.lesson
        ? proposalLessonDetails.proposal_lesson.lesson
        : proposalLessonDefault.lesson,
      name: proposalLessonDetails.proposal_lesson.name
        ? proposalLessonDetails.proposal_lesson.name
        : proposalLessonDefault.name,
    });
    // eslint-disable-next-line
  }, [proposalLessonDetails.proposal_lesson]);

  // Initialise drawer variables for proposal milestone
  useEffect(() => {
    setProposalMilestone({
      description: proposalMilestoneDetails.proposal_milestone.description
        ? proposalMilestoneDetails.proposal_milestone.description
        : proposalMilestoneDefault.description,
      due: proposalMilestoneDetails.proposal_milestone.due
        ? new Date(proposalMilestoneDetails.proposal_milestone.due)
        : proposalMilestoneDefault.due,
      name: proposalMilestoneDetails.proposal_milestone.name
        ? proposalMilestoneDetails.proposal_milestone.name
        : proposalMilestoneDefault.name,
      value: proposalMilestoneDetails.proposal_milestone.value
        ? proposalMilestoneDetails.proposal_milestone.value
        : proposalMilestoneDefault.value,
    });
    // eslint-disable-next-line
  }, [proposalMilestoneDetails.proposal_milestone]);

  // Initialise drawer variables for proposal non-labour expense
  useEffect(() => {
    setProposalNonLabourExpense({
      category: proposalNonLabourExpenseDetails.proposal_non_labour_expense
        .category
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense.category
        : proposalNonLabourExpenseDefault.category,
      categoryName: proposalNonLabourExpenseDetails.proposal_non_labour_expense
        .category_data
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense
            .category_data.name
        : proposalNonLabourExpenseDefault.categoryName,
      cost: proposalNonLabourExpenseDetails.proposal_non_labour_expense.cost
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense.cost
        : proposalNonLabourExpenseDefault.cost,
      marginPercentage: proposalNonLabourExpenseDetails
        .proposal_non_labour_expense.margin_percentage
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense
            .margin_percentage
        : proposalNonLabourExpenseDefault.marginPercentage,
      notes: proposalNonLabourExpenseDetails.proposal_non_labour_expense.notes
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense.notes
        : proposalNonLabourExpenseDefault.notes,
      proposalService: proposalNonLabourExpenseDetails
        .proposal_non_labour_expense.proposal_service
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense
            .proposal_service
        : proposalNonLabourExpenseDefault.proposalService,
      proposalServiceName: proposalNonLabourExpenseDetails
        .proposal_non_labour_expense.proposal_service_name
        ? proposalNonLabourExpenseDetails.proposal_non_labour_expense
            .proposal_service_name
        : proposalNonLabourExpenseDefault.proposalServiceName,
    });
    // eslint-disable-next-line
  }, [proposalNonLabourExpenseDetails.proposal_non_labour_expense]);

  // Initialise drawer variables for proposal permission
  useEffect(() => {
    setProposalPermission({
      level: proposalPermissionDetails.proposal_permission.level
        ? proposalPermissionDetails.proposal_permission.level
        : proposalPermissionDefault.level,
      manager: proposalPermissionDetails.proposal_permission.manager
        ? proposalPermissionDetails.proposal_permission.manager
        : proposalPermissionDefault.manager,
      managerName: proposalPermissionDetails.proposal_permission.manager_name
        ? proposalPermissionDetails.proposal_permission.manager_name
        : proposalPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [proposalPermissionDetails.proposal_permission]);

  // Initialise drawer variables for proposal question
  useEffect(() => {
    setProposalQuestion({
      answer: proposalQuestionDetails.proposal_question.answer
        ? proposalQuestionDetails.proposal_question.answer
        : proposalQuestionDefault.answer,
      category: proposalQuestionDetails.proposal_question.category
        ? proposalQuestionDetails.proposal_question.category
        : proposalQuestionDefault.category,
      name: proposalQuestionDetails.proposal_question.name
        ? proposalQuestionDetails.proposal_question.name
        : proposalQuestionDefault.name,
      owner: proposalQuestionDetails.proposal_question.owner
        ? proposalQuestionDetails.proposal_question.owner
        : proposalQuestionDefault.owner,
      ownerName: proposalQuestionDetails.proposal_question.owner_name
        ? proposalQuestionDetails.proposal_question.owner_name
        : proposalQuestionDefault.ownerName,
      question: proposalQuestionDetails.proposal_question.question
        ? proposalQuestionDetails.proposal_question.question
        : proposalQuestionDefault.question,
      status: proposalQuestionDetails.proposal_question.status
        ? proposalQuestionDetails.proposal_question.status
        : proposalQuestionDefault.status,
    });
    // eslint-disable-next-line
  }, [proposalQuestionDetails.proposal_question]);

  // Initialise drawer variables for proposal rate
  useEffect(() => {
    setProposalRate({
      marginPercentage: proposalRateDetails.proposal_rate.margin_percentage
        ? proposalRateDetails.proposal_rate.margin_percentage
        : proposalRateDefault.marginPercentage,
      role: proposalRateDetails.proposal_rate.role
        ? proposalRateDetails.proposal_rate.role
        : proposalRateDefault.role,
      roleName: proposalRateDetails.proposal_rate.role_name
        ? proposalRateDetails.proposal_rate.role_name
        : proposalRateDefault.roleName,
    });
    // eslint-disable-next-line
  }, [proposalRateDetails.proposal_rate]);

  // Initialise drawer variables for proposal requirement
  useEffect(() => {
    setProposalRequirement({
      attachment: proposalRequirementDetails.proposal_requirement.attachment
        ? proposalRequirementDetails.proposal_requirement.attachment
        : proposalRequirementDefault.attachment,
      attachmentName: proposalRequirementDetails.proposal_requirement.attachment
        ? proposalRequirementDetails.proposal_requirement.attachment
            .split("/")
            .pop()
        : proposalRequirementDefault.attachmentName,
      category: proposalRequirementDetails.proposal_requirement.category
        ? proposalRequirementDetails.proposal_requirement.category
        : proposalRequirementDefault.category,
      name: proposalRequirementDetails.proposal_requirement.name
        ? proposalRequirementDetails.proposal_requirement.name
        : proposalRequirementDefault.name,
      owner: proposalRequirementDetails.proposal_requirement.owner
        ? proposalRequirementDetails.proposal_requirement.owner
        : proposalRequirementDefault.owner,
      ownerName: proposalRequirementDetails.proposal_requirement.owner_name
        ? proposalRequirementDetails.proposal_requirement.owner_name
        : proposalRequirementDefault.ownerName,
      ref: proposalRequirementDetails.proposal_requirement.ref
        ? proposalRequirementDetails.proposal_requirement.ref
        : proposalRequirementDefault.ref,
      requirement: proposalRequirementDetails.proposal_requirement.requirement
        ? proposalRequirementDetails.proposal_requirement.requirement
        : proposalRequirementDefault.requirement,
      response: proposalRequirementDetails.proposal_requirement.response
        ? proposalRequirementDetails.proposal_requirement.response
        : proposalRequirementDefault.response,
      status: proposalRequirementDetails.proposal_requirement.status
        ? proposalRequirementDetails.proposal_requirement.status
        : proposalRequirementDefault.status,
    });
    // eslint-disable-next-line
  }, [proposalRequirementDetails.proposal_requirement]);

  // Initialise drawer variables for proposal risk
  useEffect(() => {
    setProposalRisk({
      category: proposalRiskDetails.proposal_risk.category
        ? proposalRiskDetails.proposal_risk.category
        : proposalRiskDefault.category,
      impact: proposalRiskDetails.proposal_risk.impact
        ? proposalRiskDetails.proposal_risk.impact
        : proposalRiskDefault.impact,
      likelihood: proposalRiskDetails.proposal_risk.likelihood
        ? proposalRiskDetails.proposal_risk.likelihood
        : proposalRiskDefault.likelihood,
      name: proposalRiskDetails.proposal_risk.name
        ? proposalRiskDetails.proposal_risk.name
        : proposalRiskDefault.name,
      owner: proposalRiskDetails.proposal_risk.owner
        ? proposalRiskDetails.proposal_risk.owner
        : proposalRiskDefault.owner,
      ownerName: proposalRiskDetails.proposal_risk.owner_name
        ? proposalRiskDetails.proposal_risk.owner_name
        : proposalRiskDefault.ownerName,
      risk: proposalRiskDetails.proposal_risk.risk
        ? proposalRiskDetails.proposal_risk.risk
        : proposalRiskDefault.risk,
      status: proposalRiskDetails.proposal_risk.status
        ? proposalRiskDetails.proposal_risk.status
        : proposalRiskDefault.status,
      strategy: proposalRiskDetails.proposal_risk.strategy
        ? proposalRiskDetails.proposal_risk.strategy
        : proposalRiskDefault.strategy,
    });
    // eslint-disable-next-line
  }, [proposalRiskDetails.proposal_risk]);

  // Initialise drawer variables for proposal service
  useEffect(() => {
    setProposalService({
      benefits: proposalServiceDetails.proposal_service.benefits
        ? proposalServiceDetails.proposal_service.benefits
        : proposalServiceDefault.benefits,
      description: proposalServiceDetails.proposal_service.description
        ? proposalServiceDetails.proposal_service.description
        : proposalServiceDefault.description,
      name: proposalServiceDetails.proposal_service.name
        ? proposalServiceDetails.proposal_service.name
        : proposalServiceDefault.name,
      service: proposalServiceDefault.service,
      serviceName: proposalServiceDefault.serviceName,
    });
    // eslint-disable-next-line
  }, [proposalServiceDetails.proposal_service]);

  // Initialise drawer variables for proposal service activity
  useEffect(() => {
    setProposalServiceActivity({
      description: proposalServiceActivityDetails.proposal_service_activity
        .description
        ? proposalServiceActivityDetails.proposal_service_activity.description
        : proposalServiceActivityDefault.description,
      end: proposalServiceActivityDetails.proposal_service_activity.end
        ? new Date(proposalServiceActivityDetails.proposal_service_activity.end)
        : proposalServiceActivityDefault.end,
      name: proposalServiceActivityDetails.proposal_service_activity.name
        ? proposalServiceActivityDetails.proposal_service_activity.name
        : proposalServiceActivityDefault.name,
      service: proposalServiceActivityDetails.proposal_service_activity.service
        ? proposalServiceActivityDetails.proposal_service_activity.service
        : proposalServiceActivityDefault.service,
      serviceName: proposalServiceActivityDetails.proposal_service_activity
        .service_name
        ? proposalServiceActivityDetails.proposal_service_activity.service_name
        : proposalServiceActivityDefault.serviceName,
      start: proposalServiceActivityDetails.proposal_service_activity.start
        ? new Date(
            proposalServiceActivityDetails.proposal_service_activity.start
          )
        : proposalServiceActivityDefault.start,
    });
    // eslint-disable-next-line
  }, [proposalServiceActivityDetails.proposal_service_activity]);

  // Initialise drawer variables for proposal service deliverable
  useEffect(() => {
    setProposalServiceDeliverable({
      description: proposalServiceDeliverableDetails
        .proposal_service_deliverable.description
        ? proposalServiceDeliverableDetails.proposal_service_deliverable
            .description
        : proposalServiceDeliverableDefault.description,
      due: proposalServiceDeliverableDetails.proposal_service_deliverable.due
        ? new Date(
            proposalServiceDeliverableDetails.proposal_service_deliverable.due
          )
        : proposalServiceDeliverableDefault.due,
      milestone: proposalServiceDeliverableDetails.proposal_service_deliverable
        .milestone
        ? proposalServiceDeliverableDetails.proposal_service_deliverable
            .milestone
        : proposalServiceDeliverableDefault.milestone,
      milestoneName: proposalServiceDeliverableDetails
        .proposal_service_deliverable.milestone_name
        ? proposalServiceDeliverableDetails.proposal_service_deliverable
            .milestone_name
        : proposalServiceDeliverableDefault.milestoneName,
      name: proposalServiceDeliverableDetails.proposal_service_deliverable.name
        ? proposalServiceDeliverableDetails.proposal_service_deliverable.name
        : proposalServiceDeliverableDefault.name,
      service: proposalServiceDeliverableDetails.proposal_service_deliverable
        .service
        ? proposalServiceDeliverableDetails.proposal_service_deliverable.service
        : proposalServiceDeliverableDefault.service,
      serviceName: proposalServiceDeliverableDetails
        .proposal_service_deliverable.service_name
        ? proposalServiceDeliverableDetails.proposal_service_deliverable
            .service_name
        : proposalServiceDeliverableDefault.serviceName,
      template: proposalServiceDeliverableDetails.proposal_service_deliverable
        .template
        ? proposalServiceDeliverableDetails.proposal_service_deliverable
            .template
        : proposalServiceDeliverableDefault.template,
      templateName: proposalServiceDeliverableDetails
        .proposal_service_deliverable.template
        ? proposalServiceDeliverableDetails.proposal_service_deliverable.template
            .split("/")
            .pop()
        : proposalServiceDeliverableDefault.templateName,
      type: proposalServiceDeliverableDetails.proposal_service_deliverable.type
        ? proposalServiceDeliverableDetails.proposal_service_deliverable.type
        : proposalServiceDeliverableDefault.type,
    });
    // eslint-disable-next-line
  }, [proposalServiceDeliverableDetails.proposal_service_deliverable]);

  // Initialise drawer variables for proposal staff
  useEffect(() => {
    setProposalStaff({
      experience: proposalStaffDetails.proposal_staff.experience
        ? proposalStaffDetails.proposal_staff.experience
        : proposalStaffDefault.experience,
      expertise_1: proposalStaffDetails.proposal_staff.expertise_1
        ? proposalStaffDetails.proposal_staff.expertise_1
        : proposalStaffDefault.expertise_1,
      expertise_2: proposalStaffDetails.proposal_staff.expertise_2
        ? proposalStaffDetails.proposal_staff.expertise_2
        : proposalStaffDefault.expertise_2,
      expertise_3: proposalStaffDetails.proposal_staff.expertise_3
        ? proposalStaffDetails.proposal_staff.expertise_3
        : proposalStaffDefault.expertise_3,
      responsibilities: proposalStaffDetails.proposal_staff.responsibilities
        ? proposalStaffDetails.proposal_staff.responsibilities
        : proposalStaffDefault.responsibilities,
      role: proposalStaffDetails.proposal_staff.role
        ? proposalStaffDetails.proposal_staff.role
        : proposalStaffDefault.role,
      staff: proposalStaffDetails.proposal_staff.staff
        ? proposalStaffDetails.proposal_staff.staff
        : proposalStaffDefault.staff,
      staffName: proposalStaffDetails.proposal_staff.staff_data
        ? proposalStaffDetails.proposal_staff.staff_data.name
        : proposalStaffDefault.staffName,
    });
    // eslint-disable-next-line
  }, [proposalStaffDetails.proposal_staff]);

  // Initialise drawer variables for proposal upload
  useEffect(() => {
    setProposalUpload({
      description: proposalUploadDetails.proposal_upload.description
        ? proposalUploadDetails.proposal_upload.description
        : proposalUploadDefault.description,
      file: proposalUploadDetails.proposal_upload.file
        ? proposalUploadDetails.proposal_upload.file
        : proposalUploadDefault.file,
      fileName: proposalUploadDetails.proposal_upload.file
        ? proposalUploadDetails.proposal_upload.file.split("/").pop()
        : proposalUploadDefault.fileName,
      name: proposalUploadDetails.proposal_upload.name
        ? proposalUploadDetails.proposal_upload.name
        : proposalUploadDefault.name,
    });
    // eslint-disable-next-line
  }, [proposalUploadDetails.proposal_upload]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If proposal deleted redirect to proposals
  useEffect(() => {
    if (proposalDelete.success) {
      dispatch(resetDeleteProposalAction());

      dispatch(resetListProposalsAction());

      navigate("/proposals");
    }
    // eslint-disable-next-line
  }, [proposalDelete.success]);

  // Load client data
  useEffect(() => {
    if (proposalDetails.proposal.client) {
      dispatch(
        listProposalsAction({
          client: proposalDetails.proposal.client,
          exclude: id,
          organisation: activeOrganisation.id,
        })
      );
    }
    // eslint-disable-next-line
  }, [proposalDetails.proposal.client]);

  // Load currency data
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    dispatch(
      getOrganisationCurrencyChoicesCurrencyAction({
        organisation: activeOrganisation.id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      currencies.length === 0 &&
      currencyGetOrganisationCurrencyChoices.currencies
    ) {
      currencyGetOrganisationCurrencyChoices.currencies.map((currency) =>
        setCurrencies((prevState) => [
          ...prevState,
          {
            id: currency[1],
            icon: (
              <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                {currency[0]}
              </Tag>
            ),
            value: currency[1],
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [currencyGetOrganisationCurrencyChoices.success]);

  // Load proposal details
  useEffect(() => {
    dispatch(listProposalDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Load proposal document data
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    if (proposalDetails.proposal.proposal_document && !initialLoadDone) {
      dispatch(
        listProposalDocumentDetailsAction(
          proposalDetails.proposal.proposal_document
        )
      );

      setInitialLoadDone(true);
    }
    // eslint-disable-next-line
  }, [proposalDetails.proposal.proposal_document]);

  useEffect(() => {
    if (
      initialLoadDone &&
      (proposalCaseStudyCreate.success ||
        proposalCaseStudyDelete.success ||
        proposalCaseStudyUpdate.success ||
        proposalFeeUpdate.success ||
        proposalLabourExpenseCreate.success ||
        proposalLabourExpenseDelete.success ||
        proposalLabourExpenseUpdate.success ||
        proposalLegalSectionCreate.success ||
        proposalLegalSectionDelete.success ||
        proposalLegalSectionUpdate.success ||
        proposalLegalSectionUpload.success ||
        proposalMilestoneCreate.success ||
        proposalMilestoneDelete.success ||
        proposalMilestoneUpdate.success ||
        proposalNonLabourExpenseCreate.success ||
        proposalNonLabourExpenseDelete.success ||
        proposalNonLabourExpenseUpdate.success ||
        proposalRateCreate.success ||
        proposalRateDelete.success ||
        proposalRateUpdate.success ||
        proposalRequirementCreate.success ||
        proposalRequirementDelete.success ||
        proposalRequirementUpdate.success ||
        proposalRequirementUpload.end ||
        proposalServiceCreate.success ||
        proposalServiceDelete.success ||
        proposalServiceUpdate.success ||
        proposalStaffCreate.success ||
        proposalStaffDelete.success ||
        proposalStaffUpdate.success ||
        proposalUpdate.success)
    ) {
      dispatch(
        listProposalDocumentDetailsAction(
          proposalDetails.proposal.proposal_document
        )
      );
    }
    // eslint-disable-next-line
  }, [
    proposalCaseStudyCreate.success,
    proposalCaseStudyDelete.success,
    proposalCaseStudyUpdate.success,
    proposalFeeUpdate.success,
    proposalLabourExpenseCreate.success,
    proposalLabourExpenseDelete.success,
    proposalLabourExpenseUpdate.success,
    proposalLegalSectionCreate.success,
    proposalLegalSectionDelete.success,
    proposalLegalSectionUpdate.success,
    proposalLegalSectionUpload.success,
    proposalMilestoneCreate.success,
    proposalMilestoneDelete.success,
    proposalMilestoneUpdate.success,
    proposalNonLabourExpenseCreate.success,
    proposalNonLabourExpenseDelete.success,
    proposalNonLabourExpenseUpdate.success,
    proposalRateCreate.success,
    proposalRateDelete.success,
    proposalRateUpdate.success,
    proposalRequirementCreate.success,
    proposalRequirementDelete.success,
    proposalRequirementUpdate.success,
    proposalRequirementUpload.end,
    proposalServiceCreate.success,
    proposalServiceDelete.success,
    proposalServiceUpdate.success,
    proposalStaffCreate.success,
    proposalStaffDelete.success,
    proposalStaffUpdate.success,
    proposalUpdate.success,
  ]);

  useEffect(() => {
    if (initialLoadDone && proposalDocumentUpdate.success) {
      dispatch(
        listProposalDocumentDetailsAction(
          proposalDetails.proposal.proposal_document
        )
      );

      dispatch(resetUpdateProposalDocumentAction());
    }
    // eslint-disable-next-line
  }, [proposalDocumentUpdate.success]);

  useEffect(() => {
    if (proposalDocumentDetails.success) {
      dispatch(
        generatePdfDocumentAction({
          data: proposalDocumentDetails.proposal_document,
          type: "proposal",
        })
      );
    }
    // eslint-disable-next-line
  }, [proposalDocumentDetails.success]);

  // Document paging variables
  const [sectionPages, setSectionPages] = useState([]);
  //const [showPage, setShowPage] = useState("");

  // Filter approval requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalRequestsAction({
            ...approvalRequestQuery,
            reviewedEnd: approvalRequestQuery.reviewedEnd
              ? getDjangoDate(approvalRequestQuery.reviewedEnd)
              : getDjangoDate(approvalRequestQueryDefault.reviewedEnd),
            reviewedStart: approvalRequestQuery.reviewedStart
              ? getDjangoDate(approvalRequestQuery.reviewedStart)
              : getDjangoDate(approvalRequestQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRequestQuery]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listBookingCodesAction({ ...bookingCodeQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter case studies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCaseStudiesAction({
            ...caseStudyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [caseStudyQuery]);

  // Filter client KPIs
  useEffect(() => {
    if (proposalDetails.proposal.client) {
      const timeOutId = setTimeout(
        () =>
          dispatch(
            listClientKPIsAction({
              ...clientKPIQuery,
              client: proposalDetails.proposal.client,
            })
          ),
        1000
      );

      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [clientKPIQuery, proposalDetails.proposal.client]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCompetenciesAction({
            ...competencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [competencyQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter expense categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpenseCategoriesAction({
            ...expenseCategoryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseCategoryQuery]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listLocationsAction({ ...locationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter manager list
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    if (approvalRequest.decisionRight === "Proposal commercials") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_proposal_commercials &&
            manager.approval_role_data
              .can_approve_proposal_commercials_threshold >=
              proposalDetails.proposal.value
          : false
      );
    } else if (approvalRequest.decisionRight === "Proposal qualification") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_proposal_qualification &&
            manager.approval_role_data
              .can_approve_proposal_qualification_threshold >=
              proposalDetails.proposal.value
          : false
      );
    } else if (approvalRequest.decisionRight === "Proposal solution") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_proposal_solution &&
            manager.approval_role_data
              .can_approve_proposal_solution_threshold >=
              proposalDetails.proposal.value
          : false
      );
    } else if (approvalRequest.decisionRight === "Proposal strategy") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_proposal_strategy &&
            manager.approval_role_data
              .can_approve_proposal_strategy_threshold >=
              proposalDetails.proposal.value
          : false
      );
    } else {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_proposal_submission &&
            manager.approval_role_data
              .can_approve_proposal_submission_threshold >=
              proposalDetails.proposal.value
          : false
      );
    }

    setFilteredManagerList(managers);
  }, [
    addApprovalRequestDrawerIsOpen,
    approvalRequest.decisionRight,
    managerList.managers,
    proposalDetails.proposal.value,
    updateApprovalRequestDrawerIsOpen,
  ]);

  // Filter proposal actions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalActionsAction({
            ...proposalActionQuery,
            dueEnd: proposalActionQuery.dueEnd
              ? getDjangoDate(proposalActionQuery.dueEnd)
              : "",
            dueStart: proposalActionQuery.dueStart
              ? getDjangoDate(proposalActionQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalActionQuery]);

  // Filter proposal case studies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalCaseStudiesAction({
            ...proposalCaseStudyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalCaseStudyQuery]);

  // Filter proposal competitors
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listProposalCompetitorsAction({ ...proposalCompetitorQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalCompetitorQuery]);

  // Filter proposal contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalContactsAction({
            ...proposalContactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalContactQuery]);

  // Filter proposal fees
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalFeesAction({
            ...proposalFeeQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalFeeQuery]);

  // Filter proposal labour expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalLabourExpensesAction({
            ...proposalLabourExpenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalLabourExpenseQuery]);

  // Filter proposal legal sections
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalLegalSectionsAction({
            ...proposalLegalSectionQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalLegalSectionQuery]);

  // Filter proposal lessons
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listProposalLessonsAction({ ...proposalLessonQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalLessonQuery]);

  // Filter proposal milestones
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listProposalMilestonesAction({ ...proposalMilestoneQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalMilestoneQuery]);

  // Filter proposal non-labour expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalNonLabourExpensesAction({
            ...proposalNonLabourExpenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalNonLabourExpenseQuery]);

  // Filter proposal permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listProposalPermissionsAction({ ...proposalPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalPermissionQuery]);

  // Filter proposal questions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listProposalQuestionsAction({ ...proposalQuestionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuestionQuery]);

  // Filter proposal rates
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalRatesAction({
            ...proposalRateQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalRateQuery]);

  // Filter proposal requirements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalRequirementsAction({
            ...proposalRequirementQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalRequirementQuery]);

  // Filter proposal risks
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listProposalRisksAction({ ...proposalRiskQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalRiskQuery]);

  // Filter proposal services
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listProposalServicesAction({ ...proposalServiceQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalServiceQuery]);

  // Filter proposal service deliverables
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalServiceDeliverablesAction({
            ...proposalServiceDeliverableQuery,
            dueEnd: proposalServiceDeliverableQuery.dueEnd
              ? getDjangoDate(proposalServiceDeliverableQuery.dueEnd)
              : "",
            dueStart: proposalServiceDeliverableQuery.dueStart
              ? getDjangoDate(proposalServiceDeliverableQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalServiceDeliverableQuery]);

  // Filter proposal staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalStaffAction({
            ...proposalStaffQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalStaffQuery]);

  // Filter proposal uploads
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalUploadsAction({
            ...proposalUploadQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalUploadQuery]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRolesAction({
            ...roleQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Filter services
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listServicesAction({
            ...serviceQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [serviceQuery]);

  // Filter staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffAction({
            ...staffQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffQuery]);

  // Form submission
  // Approval request
  // Add approval request
  const addApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approval_request_data.append("item_id", id);

    approval_request_data.append("organisation", activeOrganisation.id);

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(createApprovalRequestAction(approval_request_data));
  };

  // Delete approval request
  const deleteApprovalRequest = () => {
    // Dispatch action
    dispatch(
      deleteApprovalRequestAction(approvalRequestDetails.approval_request.id)
    );
  };

  // Update approval request
  const updateApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(
      updateApprovalRequestAction(
        approvalRequestDetails.approval_request.id,
        approval_request_data
      )
    );
  };

  // Client KPI
  // Add client KPI
  const addClientKPI = () => {
    // Prepare data
    const client_kpi = new FormData();

    clientKPI.achieved
      ? client_kpi.append("achieved", clientKPI.achieved)
      : client_kpi.append("achieved", clientKPIDefault.achieved);

    client_kpi.append("client", proposalDetails.proposal.client);

    clientKPI.comments
      ? client_kpi.append("comments", clientKPI.comments)
      : client_kpi.append("comments", clientKPIDefault.comments);

    clientKPI.kpi
      ? client_kpi.append("kpi", clientKPI.kpi)
      : client_kpi.append("kpi", clientKPIDefault.kpi);

    clientKPI.rating
      ? client_kpi.append("rating", clientKPI.rating)
      : client_kpi.append("rating", clientKPIDefault.rating);

    clientKPI.target
      ? client_kpi.append("target", clientKPI.target)
      : client_kpi.append("target", clientKPIDefault.target);

    clientKPI.units
      ? client_kpi.append("units", clientKPI.units)
      : client_kpi.append("units", clientKPIDefault.units);

    clientKPI.year
      ? client_kpi.append("year", clientKPI.year)
      : client_kpi.append("year", clientKPIDefault.year);

    // Dispatch action
    dispatch(createClientKPIAction(client_kpi));
  };

  // Delete client KPI
  const deleteClientKPI = () => {
    // Dispatch action
    dispatch(deleteClientKPIAction(clientKPIDetails.client_kpi.id));
  };

  // Update client KPI
  const updateClientKPI = () => {
    // Prepare data
    const client_kpi = new FormData();

    clientKPI.achieved
      ? client_kpi.append("achieved", clientKPI.achieved)
      : client_kpi.append("achieved", clientKPIDefault.achieved);

    clientKPI.comments
      ? client_kpi.append("comments", clientKPI.comments)
      : client_kpi.append("comments", clientKPIDefault.comments);

    clientKPI.kpi
      ? client_kpi.append("kpi", clientKPI.kpi)
      : client_kpi.append("kpi", clientKPIDefault.kpi);

    clientKPI.rating
      ? client_kpi.append("rating", clientKPI.rating)
      : client_kpi.append("rating", clientKPIDefault.rating);

    clientKPI.target
      ? client_kpi.append("target", clientKPI.target)
      : client_kpi.append("target", clientKPIDefault.target);

    clientKPI.units
      ? client_kpi.append("units", clientKPI.units)
      : client_kpi.append("units", clientKPIDefault.units);

    clientKPI.year
      ? client_kpi.append("year", clientKPI.year)
      : client_kpi.append("year", clientKPIDefault.year);

    // Dispatch action
    dispatch(updateClientKPIAction(clientKPIDetails.client_kpi.id, client_kpi));
  };

  // Proposal
  // Delete proposal
  const deleteProposal = () => {
    // Dispatch action
    proposalDeleteKey === proposalDetails.proposal.name
      ? dispatch(deleteProposalAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the proposal's name",
        });
  };

  // Update proposal approach
  const updateApproach = () => {
    // Prepare data
    const proposal = new FormData();

    if (
      updatedProposal.approachDiagram &&
      updatedProposal.approachDiagram !==
        proposalDetails.proposal.approach_diagram
    ) {
      proposal.append("approach_diagram", updatedProposal.approachDiagram);
    } else if (updatedProposal.approachDiagram === "") {
      proposal.append("approach_diagram", proposalDefault.approachDiagram);
    }

    updatedProposal.approachSummary
      ? proposal.append("approach_summary", updatedProposal.approachSummary)
      : proposal.append("approach_summary", proposalDefault.approachSummary);

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal delivery factors
  const updateDeliveryFactors = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.qualificationTeamScore
      ? proposal.append(
          "qualification_team_score",
          updatedProposal.qualificationTeamScore
        )
      : proposal.append(
          "qualification_team_score",
          proposalDefault.qualificationTeamScore
        );

    updatedProposal.qualificationTeamComments
      ? proposal.append(
          "qualification_team_comments",
          updatedProposal.qualificationTeamComments
        )
      : proposal.append(
          "qualification_team_comments",
          proposalDefault.qualificationTeamComments
        );

    updatedProposal.qualificationSolutionScore
      ? proposal.append(
          "qualification_solution_score",
          updatedProposal.qualificationSolutionScore
        )
      : proposal.append(
          "qualification_solution_score",
          proposalDefault.qualificationSolutionScore
        );

    updatedProposal.qualificationSolutionComments
      ? proposal.append(
          "qualification_solution_comments",
          updatedProposal.qualificationSolutionComments
        )
      : proposal.append(
          "qualification_solution_comments",
          proposalDefault.qualificationSolutionComments
        );

    updatedProposal.qualificationDependenciesScore
      ? proposal.append(
          "qualification_dependencies_score",
          updatedProposal.qualificationDependenciesScore
        )
      : proposal.append(
          "qualification_dependencies_score",
          proposalDefault.qualificationDependenciesScore
        );

    updatedProposal.qualificationDependenciesComments
      ? proposal.append(
          "qualification_dependencies_comments",
          updatedProposal.qualificationDependenciesComments
        )
      : proposal.append(
          "qualification_dependencies_comments",
          proposalDefault.qualificationDependenciesComments
        );

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal fee notes
  const updateFeeNotes = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.currency
      ? proposal.append("currency", updatedProposal.currency)
      : proposal.append("currency", proposalDefault.currency);

    updatedProposal.currencyExchangeRate
      ? proposal.append(
          "currency_exchange_rate",
          updatedProposal.currencyExchangeRate
        )
      : proposal.append(
          "currency_exchange_rate",
          proposalDefault.currencyExchangeRate
        );

    updatedProposal.tax
      ? proposal.append("tax", updatedProposal.tax)
      : proposal.append("tax", proposalDefault.tax);

    updatedProposal.feeNotes
      ? proposal.append("fee_notes", updatedProposal.feeNotes)
      : proposal.append("fee_notes", proposalDefault.feeNotes);

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal needs analysis
  const updateNeedsAnalysis = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.changeDrivers
      ? proposal.append("change_drivers", updatedProposal.changeDrivers)
      : proposal.append("change_drivers", proposalDefault.changeDrivers);

    updatedProposal.strategicOptions
      ? proposal.append("strategic_options", updatedProposal.strategicOptions)
      : proposal.append("strategic_options", proposalDefault.strategicOptions);

    updatedProposal.theoryOfChange
      ? proposal.append("theory_of_change", updatedProposal.theoryOfChange)
      : proposal.append("theory_of_change", proposalDefault.theoryOfChange);

    updatedProposal.issues
      ? proposal.append("issues", updatedProposal.issues)
      : proposal.append("issues", proposalDefault.issues);

    updatedProposal.ourSolution
      ? proposal.append("our_solution", updatedProposal.ourSolution)
      : proposal.append("our_solution", proposalDefault.ourSolution);

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal opportunity factors
  const updateOpportunityFactors = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.qualificationBudgetScore
      ? proposal.append(
          "qualification_budget_score",
          updatedProposal.qualificationBudgetScore
        )
      : proposal.append(
          "qualification_budget_score",
          proposalDefault.qualificationBudgetScore
        );

    updatedProposal.qualificationBudgetComments
      ? proposal.append(
          "qualification_budget_comments",
          updatedProposal.qualificationBudgetComments
        )
      : proposal.append(
          "qualification_budget_comments",
          proposalDefault.qualificationBudgetComments
        );

    updatedProposal.qualificationNeedScore
      ? proposal.append(
          "qualification_need_score",
          updatedProposal.qualificationNeedScore
        )
      : proposal.append(
          "qualification_need_score",
          proposalDefault.qualificationNeedScore
        );

    updatedProposal.qualificationNeedComments
      ? proposal.append(
          "qualification_need_comments",
          updatedProposal.qualificationNeedComments
        )
      : proposal.append(
          "qualification_need_comments",
          proposalDefault.qualificationNeedComments
        );

    updatedProposal.qualificationTimingScore
      ? proposal.append(
          "qualification_timing_score",
          updatedProposal.qualificationTimingScore
        )
      : proposal.append(
          "qualification_timing_score",
          proposalDefault.qualificationTimingScore
        );

    updatedProposal.qualificationTimingComments
      ? proposal.append(
          "qualification_timing_comments",
          updatedProposal.qualificationTimingComments
        )
      : proposal.append(
          "qualification_timing_comments",
          proposalDefault.qualificationTimingComments
        );

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal relationship factors
  const updateRelationshipFactors = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.qualificationRelationshipScore
      ? proposal.append(
          "qualification_relationship_score",
          updatedProposal.qualificationRelationshipScore
        )
      : proposal.append(
          "qualification_relationship_score",
          proposalDefault.qualificationRelationshipScore
        );

    updatedProposal.qualificationRelationshipComments
      ? proposal.append(
          "qualification_relationship_comments",
          updatedProposal.qualificationRelationshipComments
        )
      : proposal.append(
          "qualification_relationship_comments",
          proposalDefault.qualificationRelationshipComments
        );

    updatedProposal.qualificationBuyerScore
      ? proposal.append(
          "qualification_buyer_score",
          updatedProposal.qualificationBuyerScore
        )
      : proposal.append(
          "qualification_buyer_score",
          proposalDefault.qualificationBuyerScore
        );

    updatedProposal.qualificationBuyerComments
      ? proposal.append(
          "qualification_buyer_comments",
          updatedProposal.qualificationBuyerComments
        )
      : proposal.append(
          "qualification_buyer_comments",
          proposalDefault.qualificationBuyerComments
        );

    updatedProposal.qualificationCoachScore
      ? proposal.append(
          "qualification_coach_score",
          updatedProposal.qualificationCoachScore
        )
      : proposal.append(
          "qualification_coach_score",
          proposalDefault.qualificationCoachScore
        );

    updatedProposal.qualificationCoachComments
      ? proposal.append(
          "qualification_coach_comments",
          updatedProposal.qualificationCoachComments
        )
      : proposal.append(
          "qualification_coach_comments",
          proposalDefault.qualificationCoachComments
        );

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal strategic alignment factors
  const updateStrategicAlignmentFactors = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.qualificationMarketScore
      ? proposal.append(
          "qualification_market_score",
          updatedProposal.qualificationMarketScore
        )
      : proposal.append(
          "qualification_market_score",
          proposalDefault.qualificationMarketScore
        );

    updatedProposal.qualificationMarketComments
      ? proposal.append(
          "qualification_market_comments",
          updatedProposal.qualificationMarketComments
        )
      : proposal.append(
          "qualification_market_comments",
          proposalDefault.qualificationMarketComments
        );

    updatedProposal.qualificationServicesScore
      ? proposal.append(
          "qualification_services_score",
          updatedProposal.qualificationServicesScore
        )
      : proposal.append(
          "qualification_services_score",
          proposalDefault.qualificationServicesScore
        );

    updatedProposal.qualificationServicesComments
      ? proposal.append(
          "qualification_services_comments",
          updatedProposal.qualificationServicesComments
        )
      : proposal.append(
          "qualification_services_comments",
          proposalDefault.qualificationServicesComments
        );

    updatedProposal.qualificationReputationScore
      ? proposal.append(
          "qualification_reputation_score",
          updatedProposal.qualificationReputationScore
        )
      : proposal.append(
          "qualification_reputation_score",
          proposalDefault.qualificationReputationScore
        );

    updatedProposal.qualificationReputationComments
      ? proposal.append(
          "qualification_reputation_comments",
          updatedProposal.qualificationReputationComments
        )
      : proposal.append(
          "qualification_reputation_comments",
          proposalDefault.qualificationReputationComments
        );

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Update proposal summary
  const updateSummary = () => {
    // Prepare data
    const proposal = new FormData();

    const client = new FormData();
    if (updatedProposal.client) {
      proposal.append("client", updatedProposal.client);
    } else {
      proposal.append("client", proposalDefault.client);

      if (updatedProposal.clientName) {
        client.append("name", updatedProposal.clientName);

        client.append("organisation", activeOrganisation.id);
      }
    }

    updatedProposal.closed
      ? proposal.append("closed", getDjangoDate(updatedProposal.closed))
      : proposal.append("closed", getDjangoDate(proposalDefault.closed));

    const contact = new FormData();
    if (updatedProposal.contact) {
      proposal.append("contact", updatedProposal.contact);
    } else {
      proposal.append("contact", proposalDefault.contact);

      if (updatedProposal.contactName) {
        contact.append("name", updatedProposal.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    updatedProposal.due
      ? proposal.append("due", getDjangoDate(updatedProposal.due))
      : proposal.append("due", getDjangoDate(proposalDefault.due));

    updatedProposal.manager
      ? proposal.append("manager", updatedProposal.manager)
      : proposal.append("manager", proposalDefault.manager);

    updatedProposal.name
      ? proposal.append("name", updatedProposal.name)
      : proposal.append("name", proposalDefault.name);

    updatedProposal.notes
      ? proposal.append("notes", updatedProposal.notes)
      : proposal.append("notes", proposalDefault.notes);

    updatedProposal.restricted != null
      ? proposal.append("restricted", updatedProposal.restricted)
      : proposal.append("restricted", proposalDefault.restricted);

    if (
      updatedProposal.rfp &&
      updatedProposal.rfp !== proposalDetails.proposal.rfp
    ) {
      proposal.append("rfp", updatedProposal.rfp);
    } else if (updatedProposal.rfp === "") {
      proposal.append("rfp", proposalDefault.rfp);
    }

    updatedProposal.status
      ? proposal.append("status", updatedProposal.status)
      : proposal.append("status", proposalDefault.status);

    updatedProposal.submitted
      ? proposal.append("submitted", getDjangoDate(updatedProposal.submitted))
      : proposal.append("submitted", getDjangoDate(proposalDefault.submitted));

    updatedProposal.value
      ? proposal.append("value", updatedProposal.value)
      : proposal.append("value", proposalDefault.value);

    // Dispatch action
    dispatch(updateProposalAction(id, client, contact, proposal));
  };

  // Update proposal win strategy
  const updateWinStrategy = () => {
    // Prepare data
    const proposal = new FormData();

    updatedProposal.winStrategyCommercials
      ? proposal.append(
          "win_strategy_commercials",
          updatedProposal.winStrategyCommercials
        )
      : proposal.append(
          "win_strategy_commercials",
          proposalDefault.winStrategyCommercials
        );

    updatedProposal.winStrategyOther
      ? proposal.append("win_strategy_other", updatedProposal.winStrategyOther)
      : proposal.append("win_strategy_other", proposalDefault.winStrategyOther);

    updatedProposal.winStrategyPartnerships
      ? proposal.append(
          "win_strategy_partnerships",
          updatedProposal.winStrategyPartnerships
        )
      : proposal.append(
          "win_strategy_partnerships",
          proposalDefault.winStrategyPartnerships
        );

    updatedProposal.winStrategyPrice
      ? proposal.append("win_strategy_price", updatedProposal.winStrategyPrice)
      : proposal.append("win_strategy_price", proposalDefault.winStrategyPrice);

    updatedProposal.winStrategyReferences
      ? proposal.append(
          "win_strategy_references",
          updatedProposal.winStrategyReferences
        )
      : proposal.append(
          "win_strategy_references",
          proposalDefault.winStrategyReferences
        );

    updatedProposal.winStrategyRelationships
      ? proposal.append(
          "win_strategy_relationships",
          updatedProposal.winStrategyRelationships
        )
      : proposal.append(
          "win_strategy_relationships",
          proposalDefault.winStrategyRelationships
        );

    updatedProposal.winStrategySolution
      ? proposal.append(
          "win_strategy_solution",
          updatedProposal.winStrategySolution
        )
      : proposal.append(
          "win_strategy_solution",
          proposalDefault.winStrategySolution
        );

    updatedProposal.winStrategyTeam
      ? proposal.append("win_strategy_team", updatedProposal.winStrategyTeam)
      : proposal.append("win_strategy_team", proposalDefault.winStrategyTeam);

    // Dispatch action
    dispatch(updateProposalAction(id, "", "", proposal));
  };

  // Proposal action
  const [waitingToUpdateKanban, setWaitingToUpdateKanban] = useState(false);

  // Add action
  const addAction = () => {
    // Prepare data
    const proposal_action = new FormData();

    proposalAction.action
      ? proposal_action.append("action", proposalAction.action)
      : proposal_action.append("action", proposalActionDefault.action);

    proposalAction.category
      ? proposal_action.append("category", proposalAction.category)
      : proposal_action.append("category", proposalActionDefault.category);

    proposalAction.due
      ? proposal_action.append("due", getDjangoDate(proposalAction.due))
      : proposal_action.append("due", getDjangoDate(proposalActionDefault.due));

    proposalAction.name
      ? proposal_action.append("name", proposalAction.name)
      : proposal_action.append("name", proposalActionDefault.name);

    proposalAction.owner
      ? proposal_action.append("owner", proposalAction.owner)
      : proposal_action.append("owner", proposalActionDefault.owner);

    proposal_action.append("proposal", id);

    // Dispatch action
    dispatch(createProposalActionAction(proposal_action));
  };

  // Delete action
  const deleteAction = () => {
    // Dispatch action
    dispatch(
      deleteProposalActionAction(proposalActionDetails.proposal_action.id)
    );
  };

  // Update action
  const updateAction = () => {
    // Prepare data
    const proposal_action = new FormData();

    proposalAction.action
      ? proposal_action.append("action", proposalAction.action)
      : proposal_action.append("action", proposalActionDefault.action);

    proposalAction.category
      ? proposal_action.append("category", proposalAction.category)
      : proposal_action.append("category", proposalActionDefault.category);

    proposalAction.comments
      ? proposal_action.append("comments", proposalAction.comments)
      : proposal_action.append("comments", proposalActionDefault.comments);

    proposalAction.due
      ? proposal_action.append("due", getDjangoDate(proposalAction.due))
      : proposal_action.append("due", getDjangoDate(proposalActionDefault.due));

    proposalAction.name
      ? proposal_action.append("name", proposalAction.name)
      : proposal_action.append("name", proposalActionDefault.name);

    proposalAction.owner
      ? proposal_action.append("owner", proposalAction.owner)
      : proposal_action.append("owner", proposalActionDefault.owner);

    proposalAction.status
      ? proposal_action.append("status", proposalAction.status)
      : proposal_action.append("status", proposalActionDefault.status);

    // Dispatch action
    dispatch(
      updateProposalActionAction(
        proposalActionDetails.proposal_action.id,
        proposal_action
      )
    );
  };

  // Update action status
  const updateActionStatus = (actionId, actionStatus) => {
    // Prepare data
    const proposal_action = new FormData();

    proposal_action.append("status", actionStatus);

    // Dispatch action
    dispatch(updateProposalActionAction(actionId, proposal_action));
  };

  useEffect(() => {
    if (
      proposalActionCreate.success ||
      proposalActionDelete.success ||
      proposalActionUpdate.success
    ) {
      setWaitingToUpdateKanban(true);

      dispatch(listProposalDetailsAction(id));

      setProposalActionQuery({
        ...proposalActionQueryDefault,
      });
    }
    // eslint-disable-next-line
  }, [
    proposalActionCreate.success,
    proposalActionDelete.success,
    proposalActionUpdate.success,
  ]);

  useEffect(() => {
    if (proposalDetails.success && waitingToUpdateKanban) {
      setWaitingToUpdateKanban(false);

      setInitialiseActionKanban(true);
    }
    // eslint-disable-next-line
  }, [proposalDetails.success]);

  // Proposal case study
  // Add proposal case study
  const addCaseStudy = () => {
    // Prepare data
    const proposal_case_study = new FormData();

    proposalCaseStudy.caseStudy
      ? proposal_case_study.append("case_study", proposalCaseStudy.caseStudy)
      : proposal_case_study.append(
          "case_study",
          proposalCaseStudyDefault.caseStudy
        );

    proposal_case_study.append("proposal", id);

    // Dispatch action
    dispatch(createProposalCaseStudyAction(proposal_case_study));
  };

  // Delete proposal case study
  const deleteCaseStudy = () => {
    // Dispatch action
    dispatch(
      deleteProposalCaseStudyAction(
        proposalCaseStudyDetails.proposal_case_study.id
      )
    );
  };

  // Update proposal case study
  const updateCaseStudy = () => {
    // Prepare data
    const proposal_case_study = new FormData();

    proposalCaseStudy.caseStudy
      ? proposal_case_study.append("case_study", proposalCaseStudy.caseStudy)
      : proposal_case_study.append(
          "case_study",
          proposalCaseStudyDefault.caseStudy
        );

    // Dispatch action
    dispatch(
      updateProposalCaseStudyAction(
        proposalCaseStudyDetails.proposal_case_study.id,
        proposal_case_study
      )
    );
  };

  // Proposal competitor
  // Add competitor
  const addCompetitor = () => {
    // Prepare data
    const proposal_competitor = new FormData();

    proposalCompetitor.commercials
      ? proposal_competitor.append(
          "commercials",
          proposalCompetitor.commercials
        )
      : proposal_competitor.append(
          "commercials",
          proposalCompetitorDefault.commercials
        );

    proposalCompetitor.competitor
      ? proposal_competitor.append("competitor", proposalCompetitor.competitor)
      : proposal_competitor.append(
          "competitor",
          proposalCompetitorDefault.competitor
        );

    proposalCompetitor.notes
      ? proposal_competitor.append("notes", proposalCompetitor.notes)
      : proposal_competitor.append("notes", proposalCompetitorDefault.notes);

    proposalCompetitor.partnerships
      ? proposal_competitor.append(
          "partnerships",
          proposalCompetitor.partnerships
        )
      : proposal_competitor.append(
          "partnerships",
          proposalCompetitorDefault.partnerships
        );

    proposalCompetitor.price
      ? proposal_competitor.append("price", proposalCompetitor.price)
      : proposal_competitor.append("price", proposalCompetitorDefault.price);

    proposal_competitor.append("proposal", id);

    proposalCompetitor.references
      ? proposal_competitor.append("references", proposalCompetitor.references)
      : proposal_competitor.append(
          "references",
          proposalCompetitorDefault.references
        );

    proposalCompetitor.relationships
      ? proposal_competitor.append(
          "relationships",
          proposalCompetitor.relationships
        )
      : proposal_competitor.append(
          "relationships",
          proposalCompetitorDefault.relationships
        );

    proposalCompetitor.solution
      ? proposal_competitor.append("solution", proposalCompetitor.solution)
      : proposal_competitor.append(
          "solution",
          proposalCompetitorDefault.solution
        );

    proposalCompetitor.team
      ? proposal_competitor.append("team", proposalCompetitor.team)
      : proposal_competitor.append("team", proposalCompetitorDefault.team);

    // Dispatch action
    dispatch(createProposalCompetitorAction(proposal_competitor));
  };

  // Delete competitor
  const deleteCompetitor = () => {
    // Dispatch action
    dispatch(
      deleteProposalCompetitorAction(
        proposalCompetitorDetails.proposal_competitor.id
      )
    );
  };

  // Update competitor
  const updateCompetitor = () => {
    // Prepare data
    const proposal_competitor = new FormData();

    proposalCompetitor.commercials
      ? proposal_competitor.append(
          "commercials",
          proposalCompetitor.commercials
        )
      : proposal_competitor.append(
          "commercials",
          proposalCompetitorDefault.commercials
        );

    proposalCompetitor.competitor
      ? proposal_competitor.append("competitor", proposalCompetitor.competitor)
      : proposal_competitor.append(
          "competitor",
          proposalCompetitorDefault.competitor
        );

    proposalCompetitor.notes
      ? proposal_competitor.append("notes", proposalCompetitor.notes)
      : proposal_competitor.append("notes", proposalCompetitorDefault.notes);

    proposalCompetitor.partnerships
      ? proposal_competitor.append(
          "partnerships",
          proposalCompetitor.partnerships
        )
      : proposal_competitor.append(
          "partnerships",
          proposalCompetitorDefault.partnerships
        );

    proposalCompetitor.price
      ? proposal_competitor.append("price", proposalCompetitor.price)
      : proposal_competitor.append("price", proposalCompetitorDefault.price);

    proposalCompetitor.references
      ? proposal_competitor.append("references", proposalCompetitor.references)
      : proposal_competitor.append(
          "references",
          proposalCompetitorDefault.references
        );

    proposalCompetitor.relationships
      ? proposal_competitor.append(
          "relationships",
          proposalCompetitor.relationships
        )
      : proposal_competitor.append(
          "relationships",
          proposalCompetitorDefault.relationships
        );

    proposalCompetitor.solution
      ? proposal_competitor.append("solution", proposalCompetitor.solution)
      : proposal_competitor.append(
          "solution",
          proposalCompetitorDefault.solution
        );

    proposalCompetitor.team
      ? proposal_competitor.append("team", proposalCompetitor.team)
      : proposal_competitor.append("team", proposalCompetitorDefault.team);

    // Dispatch action
    dispatch(
      updateProposalCompetitorAction(
        proposalCompetitorDetails.proposal_competitor.id,
        proposal_competitor
      )
    );
  };

  // Proposal contact
  // Add proposal contact
  const addProposalContact = () => {
    // Prepare data
    const proposal_contact = new FormData();

    proposalContact.buyerRole
      ? proposal_contact.append("buyer_role", proposalContact.buyerRole)
      : proposal_contact.append("buyer_role", proposalContactDefault.buyerRole);

    proposalContact.comments
      ? proposal_contact.append("comments", proposalContact.comments)
      : proposal_contact.append("comments", proposalContactDefault.comments);

    const contact = new FormData();
    if (proposalContact.contact) {
      proposal_contact.append("contact", proposalContact.contact);
    } else {
      proposal_contact.append("contact", proposalContactDefault.contact);

      if (proposalContact.contactName) {
        contact.append("name", proposalContact.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    proposalContact.levelOfInfluence
      ? proposal_contact.append(
          "level_of_influence",
          proposalContact.levelOfInfluence
        )
      : proposal_contact.append(
          "level_of_influence",
          proposalContactDefault.levelOfInfluence
        );

    proposal_contact.append("proposal", id);

    proposalContact.relationshipOwner
      ? proposal_contact.append(
          "relationship_owner",
          proposalContact.relationshipOwner
        )
      : proposal_contact.append(
          "relationship_owner",
          proposalContactDefault.relationshipOwner
        );

    // Dispatch action
    dispatch(createProposalContactAction(contact, proposal_contact));
  };

  // Delete proposal contact
  const deleteProposalContact = () => {
    // Dispatch action
    dispatch(
      deleteProposalContactAction(proposalContactDetails.proposal_contact.id)
    );
  };

  // Update proposal contact
  const updateProposalContact = () => {
    // Prepare data
    const proposal_contact = new FormData();

    proposalContact.buyerRole
      ? proposal_contact.append("buyer_role", proposalContact.buyerRole)
      : proposal_contact.append("buyer_role", proposalContactDefault.buyerRole);

    proposalContact.comments
      ? proposal_contact.append("comments", proposalContact.comments)
      : proposal_contact.append("comments", proposalContactDefault.comments);

    const contact = new FormData();
    if (proposalContact.contact) {
      proposal_contact.append("contact", proposalContact.contact);
    } else {
      proposal_contact.append("contact", proposalContactDefault.contact);

      if (proposalContact.contactName) {
        contact.append("name", proposalContact.contactName);

        contact.append("organisation", proposalDetails.organisation);
      }
    }

    proposalContact.levelOfInfluence
      ? proposal_contact.append(
          "level_of_influence",
          proposalContact.levelOfInfluence
        )
      : proposal_contact.append(
          "level_of_influence",
          proposalContactDefault.levelOfInfluence
        );

    proposalContact.relationshipOwner
      ? proposal_contact.append(
          "relationship_owner",
          proposalContact.relationshipOwner
        )
      : proposal_contact.append(
          "relationship_owner",
          proposalContactDefault.relationshipOwner
        );

    // Dispatch action
    dispatch(
      updateProposalContactAction(
        proposalContactDetails.proposal_contact.id,
        contact,
        proposal_contact
      )
    );
  };

  // Proposal document
  // Update proposal document
  const scheduleRef = useRef(null);

  useEffect(() => {
    if (
      proposalDocument.scheduleDiagram &&
      proposalDocument.scheduleDiagram !==
        proposalDocumentDetails.proposal_document.schedule_diagram
    ) {
      updateDocument();

      AppToaster.show({
        icon: "updated",
        intent: "primary",
        message:
          "Updating the proposal document. This will take a few seconds.",
      });
    }
    // eslint-disable-next-line
  }, [proposalDocument.scheduleDiagram]);

  const updateDocument = () => {
    // Prepare data
    const proposal_document = new FormData();

    proposalDocument.additionalContent
      ? proposal_document.append(
          "additional_content",
          proposalDocument.additionalContent
        )
      : proposal_document.append(
          "additional_content",
          proposalDocumentDefault.additionalContent
        );

    if (
      proposalDocument.approachDiagram &&
      proposalDocument.approachDiagram !==
        proposalDocumentDetails.proposal_document.approach_diagram
    ) {
      proposal_document.append(
        "approach_diagram",
        proposalDocument.approachDiagram
      );
    } else if (proposalDocument.approachDiagram === "") {
      proposal_document.append(
        "approach_diagram",
        proposalDocument.approachDiagram
      );
    }

    proposalDocument.approachHeading
      ? proposal_document.append(
          "approach_heading",
          proposalDocument.approachHeading
        )
      : proposal_document.append(
          "approach_heading",
          proposalDocumentDefault.approachHeading
        );

    proposalDocument.approachSummary
      ? proposal_document.append(
          "approach_summary",
          proposalDocument.approachSummary
        )
      : proposal_document.append(
          "approach_summary",
          proposalDocumentDefault.approachSummary
        );

    if (
      proposalDocument.backgroundImage &&
      proposalDocument.backgroundImage !==
        proposalDocumentDetails.proposal_document.background_image
    ) {
      proposal_document.append(
        "background_image",
        proposalDocument.backgroundImage
      );
    } else if (proposalDocument.backgroundImage === "") {
      proposal_document.append(
        "background_image",
        proposalDocument.backgroundImage
      );
    }

    proposalDocument.backPageText
      ? proposal_document.append(
          "back_page_text",
          proposalDocument.backPageText
        )
      : proposal_document.append(
          "back_page_text",
          proposalDocumentDefault.backPageText
        );

    proposalDocument.caseStudiesHeading
      ? proposal_document.append(
          "case_studies_heading",
          proposalDocument.caseStudiesHeading
        )
      : proposal_document.append(
          "case_studies_heading",
          proposalDocumentDefault.caseStudiesHeading
        );

    proposalDocument.changeDrivers
      ? proposal_document.append(
          "change_drivers",
          proposalDocument.changeDrivers
        )
      : proposal_document.append(
          "change_drivers",
          proposalDocumentDefault.changeDrivers
        );

    proposalDocument.changeDriversHeading
      ? proposal_document.append(
          "change_drivers_heading",
          proposalDocument.changeDriversHeading
        )
      : proposal_document.append(
          "change_drivers_heading",
          proposalDocumentDefault.changeDriversHeading
        );

    proposalDocument.client
      ? proposal_document.append("client", proposalDocument.client)
      : proposal_document.append("client", proposalDocumentDefault.client);

    proposalDocument.clientAddress
      ? proposal_document.append(
          "client_address",
          proposalDocument.clientAddress
        )
      : proposal_document.append(
          "client_address",
          proposalDocumentDefault.clientAddress
        );

    proposalDocument.clientAlias
      ? proposal_document.append("client_alias", proposalDocument.clientAlias)
      : proposal_document.append(
          "client_alias",
          proposalDocumentDefault.clientAlias
        );

    proposalDocument.clientDepartment
      ? proposal_document.append(
          "client_department",
          proposalDocument.clientDepartment
        )
      : proposal_document.append(
          "client_department",
          proposalDocumentDefault.clientDepartment
        );

    proposalDocument.clientEntity
      ? proposal_document.append("client_entity", proposalDocument.clientEntity)
      : proposal_document.append(
          "client_entity",
          proposalDocumentDefault.clientEntity
        );

    proposalDocument.close
      ? proposal_document.append("close", proposalDocument.close)
      : proposal_document.append("close", proposalDocumentDefault.close);

    proposalDocument.commitment
      ? proposal_document.append("commitment", proposalDocument.commitment)
      : proposal_document.append(
          "commitment",
          proposalDocumentDefault.commitment
        );

    proposalDocument.company
      ? proposal_document.append("company", proposalDocument.company)
      : proposal_document.append("company", proposalDocumentDefault.company);

    proposalDocument.companyAddress
      ? proposal_document.append(
          "company_address",
          proposalDocument.companyAddress
        )
      : proposal_document.append(
          "company_address",
          proposalDocumentDefault.companyAddress
        );

    proposalDocument.companyAlias
      ? proposal_document.append("company_alias", proposalDocument.companyAlias)
      : proposal_document.append(
          "company_alias",
          proposalDocumentDefault.companyAlias
        );

    proposalDocument.companyDepartment
      ? proposal_document.append(
          "company_department",
          proposalDocument.companyDepartment
        )
      : proposal_document.append(
          "company_department",
          proposalDocumentDefault.companyDepartment
        );

    proposalDocument.companyEmail
      ? proposal_document.append("company_email", proposalDocument.companyEmail)
      : proposal_document.append(
          "company_email",
          proposalDocumentDefault.companyEmail
        );

    proposalDocument.companyEntity
      ? proposal_document.append(
          "company_entity",
          proposalDocument.companyEntity
        )
      : proposal_document.append(
          "company_entity",
          proposalDocumentDefault.companyEntity
        );

    proposalDocument.companyPhone
      ? proposal_document.append("company_phone", proposalDocument.companyPhone)
      : proposal_document.append(
          "company_phone",
          proposalDocumentDefault.companyPhone
        );

    proposalDocument.companySummary
      ? proposal_document.append(
          "company_summary",
          proposalDocument.companySummary
        )
      : proposal_document.append(
          "company_summary",
          proposalDocumentDefault.companySummary
        );

    proposalDocument.contact
      ? proposal_document.append("contact", proposalDocument.contact)
      : proposal_document.append("contact", proposalDocumentDefault.contact);

    proposalDocument.contactRole
      ? proposal_document.append("contact_role", proposalDocument.contactRole)
      : proposal_document.append(
          "contact_role",
          proposalDocumentDefault.contactRole
        );

    proposalDocument.date
      ? proposal_document.append("date", proposalDocument.date)
      : proposal_document.append("date", proposalDocumentDefault.date);

    proposalDocument.feesHeading
      ? proposal_document.append("fees_heading", proposalDocument.feesHeading)
      : proposal_document.append(
          "fees_heading",
          proposalDocumentDefault.feesHeading
        );
    proposalDocument.includeApproach !== null
      ? proposal_document.append(
          "include_approach",
          proposalDocument.includeApproach
        )
      : proposal_document.append(
          "include_approach",
          proposalDocumentDefault.includeApproach
        );
    proposalDocument.includeCaseStudies !== null
      ? proposal_document.append(
          "include_case_studies",
          proposalDocument.includeCaseStudies
        )
      : proposal_document.append(
          "include_case_studies",
          proposalDocumentDefault.includeCaseStudies
        );
    proposalDocument.includeCoverLetter !== null
      ? proposal_document.append(
          "include_cover_letter",
          proposalDocument.includeCoverLetter
        )
      : proposal_document.append(
          "include_cover_letter",
          proposalDocumentDefault.includeCoverLetter
        );
    proposalDocument.includeFeeBreakdown !== null
      ? proposal_document.append(
          "include_fee_breakdown",
          proposalDocument.includeFeeBreakdown
        )
      : proposal_document.append(
          "include_fee_breakdown",
          proposalDocumentDefault.includeFeeBreakdown
        );
    proposalDocument.includeFees !== null
      ? proposal_document.append("include_fees", proposalDocument.includeFees)
      : proposal_document.append(
          "include_fees",
          proposalDocumentDefault.includeFees
        );
    proposalDocument.includeLegal !== null
      ? proposal_document.append("include_legal", proposalDocument.includeLegal)
      : proposal_document.append(
          "include_legal",
          proposalDocumentDefault.includeLegal
        );
    proposalDocument.includeNeeds !== null
      ? proposal_document.append("include_needs", proposalDocument.includeNeeds)
      : proposal_document.append(
          "include_needs",
          proposalDocumentDefault.includeNeeds
        );
    proposalDocument.includeResponses !== null
      ? proposal_document.append(
          "include_responses",
          proposalDocument.includeResponses
        )
      : proposal_document.append(
          "include_responses",
          proposalDocumentDefault.includeResponses
        );
    proposalDocument.includeServices !== null
      ? proposal_document.append(
          "include_services",
          proposalDocument.includeServices
        )
      : proposal_document.append(
          "include_services",
          proposalDocumentDefault.includeServices
        );
    proposalDocument.includeTeam !== null
      ? proposal_document.append("include_team", proposalDocument.includeTeam)
      : proposal_document.append(
          "include_team",
          proposalDocumentDefault.includeTeam
        );
    proposalDocument.includeWhyUs !== null
      ? proposal_document.append(
          "include_why_us",
          proposalDocument.includeWhyUs
        )
      : proposal_document.append(
          "include_why_us",
          proposalDocumentDefault.includeWhyUs
        );
    proposalDocument.introCaseStudies
      ? proposal_document.append(
          "intro_case_studies",
          proposalDocument.introCaseStudies
        )
      : proposal_document.append(
          "intro_case_studies",
          proposalDocumentDefault.introCaseStudies
        );

    proposalDocument.introFees
      ? proposal_document.append("intro_fees", proposalDocument.introFees)
      : proposal_document.append(
          "intro_fees",
          proposalDocumentDefault.introFees
        );

    proposalDocument.introRequirements
      ? proposal_document.append(
          "intro_requirements",
          proposalDocument.introRequirements
        )
      : proposal_document.append(
          "intro_requirements",
          proposalDocumentDefault.introRequirements
        );

    proposalDocument.introServices
      ? proposal_document.append(
          "intro_services",
          proposalDocument.introServices
        )
      : proposal_document.append(
          "intro_services",
          proposalDocumentDefault.introServices
        );

    proposalDocument.introTeam
      ? proposal_document.append("intro_team", proposalDocument.introTeam)
      : proposal_document.append(
          "intro_team",
          proposalDocumentDefault.introTeam
        );

    proposalDocument.issues
      ? proposal_document.append("issues", proposalDocument.issues)
      : proposal_document.append("issues", proposalDocumentDefault.issues);

    proposalDocument.issuesHeading
      ? proposal_document.append(
          "issues_heading",
          proposalDocument.issuesHeading
        )
      : proposal_document.append(
          "issues_heading",
          proposalDocumentDefault.issuesHeading
        );

    proposalDocument.legalHeading
      ? proposal_document.append("legal_heading", proposalDocument.legalHeading)
      : proposal_document.append(
          "legal_heading",
          proposalDocumentDefault.legalHeading
        );

    proposalDocument.manager
      ? proposal_document.append("manager", proposalDocument.manager)
      : proposal_document.append("manager", proposalDocumentDefault.manager);

    proposalDocument.managerEmail
      ? proposal_document.append("manager_email", proposalDocument.managerEmail)
      : proposal_document.append(
          "manager_email",
          proposalDocumentDefault.managerEmail
        );

    proposalDocument.managerPhone
      ? proposal_document.append("manager_phone", proposalDocument.managerPhone)
      : proposal_document.append(
          "manager_phone",
          proposalDocumentDefault.managerPhone
        );

    proposalDocument.managerRole
      ? proposal_document.append("manager_role", proposalDocument.managerRole)
      : proposal_document.append(
          "manager_role",
          proposalDocumentDefault.managerRole
        );

    proposalDocument.needsHeading
      ? proposal_document.append("needs_heading", proposalDocument.needsHeading)
      : proposal_document.append(
          "needs_heading",
          proposalDocumentDefault.needsHeading
        );

    proposalDocument.opening
      ? proposal_document.append("opening", proposalDocument.opening)
      : proposal_document.append("opening", proposalDocumentDefault.opening);

    proposalDocument.ourSolution
      ? proposal_document.append("our_solution", proposalDocument.ourSolution)
      : proposal_document.append(
          "our_solution",
          proposalDocumentDefault.ourSolution
        );

    proposalDocument.ourSolutionHeading
      ? proposal_document.append(
          "our_solution_heading",
          proposalDocument.ourSolutionHeading
        )
      : proposal_document.append(
          "our_solution_heading",
          proposalDocumentDefault.ourSolutionHeading
        );

    proposalDocument.reference
      ? proposal_document.append("reference", proposalDocument.reference)
      : proposal_document.append(
          "reference",
          proposalDocumentDefault.reference
        );

    proposalDocument.responsesHeading
      ? proposal_document.append(
          "responses_heading",
          proposalDocument.responsesHeading
        )
      : proposal_document.append(
          "responses_heading",
          proposalDocumentDefault.responsesHeading
        );

    proposalDocument.salutation
      ? proposal_document.append("salutation", proposalDocument.salutation)
      : proposal_document.append(
          "salutation",
          proposalDocumentDefault.salutation
        );

    if (
      proposalDocument.scheduleDiagram &&
      proposalDocument.scheduleDiagram !==
        proposalDocumentDetails.proposal_document.schedule_diagram
    ) {
      proposal_document.append(
        "schedule_diagram",
        proposalDocument.scheduleDiagram
      );
    } else if (proposalDocument.scheduleDiagram === "") {
      proposal_document.append(
        "schedule_diagram",
        proposalDocument.scheduleDiagram
      );
    }

    proposalDocument.servicesHeading
      ? proposal_document.append(
          "services_heading",
          proposalDocument.servicesHeading
        )
      : proposal_document.append(
          "services_heading",
          proposalDocumentDefault.servicesHeading
        );

    if (
      proposalDocument.signature &&
      proposalDocument.signature !==
        proposalDocumentDetails.proposal_document.signature
    ) {
      proposal_document.append("signature", proposalDocument.signature);
    } else if (proposalDocument.signature === "") {
      proposal_document.append("signature", proposalDocument.signature);
    }

    proposalDocument.strategicOptions
      ? proposal_document.append(
          "strategic_options",
          proposalDocument.strategicOptions
        )
      : proposal_document.append(
          "strategic_options",
          proposalDocumentDefault.strategicOptions
        );

    proposalDocument.strategicOptionsHeading
      ? proposal_document.append(
          "strategic_options_heading",
          proposalDocument.strategicOptionsHeading
        )
      : proposal_document.append(
          "strategic_options_heading",
          proposalDocumentDefault.strategicOptionsHeading
        );

    proposalDocument.teamHeading
      ? proposal_document.append("team_heading", proposalDocument.teamHeading)
      : proposal_document.append(
          "team_heading",
          proposalDocumentDefault.teamHeading
        );

    proposalDocument.theoryOfChange
      ? proposal_document.append(
          "theory_of_change",
          proposalDocument.theoryOfChange
        )
      : proposal_document.append(
          "theory_of_change",
          proposalDocumentDefault.theoryOfChange
        );

    proposalDocument.theoryOfChangeHeading
      ? proposal_document.append(
          "theory_of_change_heading",
          proposalDocument.theoryOfChangeHeading
        )
      : proposal_document.append(
          "theory_of_change_heading",
          proposalDocumentDefault.theoryOfChangeHeading
        );

    proposalDocument.title
      ? proposal_document.append("title", proposalDocument.title)
      : proposal_document.append("title", proposalDocumentDefault.title);

    proposalDocument.whyUsHeading
      ? proposal_document.append(
          "why_us_heading",
          proposalDocument.whyUsHeading
        )
      : proposal_document.append(
          "why_us_heading",
          proposalDocumentDefault.whyUsHeading
        );

    proposalDocument.winStrategyCommercials
      ? proposal_document.append(
          "win_strategy_commercials",
          proposalDocument.winStrategyCommercials
        )
      : proposal_document.append(
          "win_strategy_commercials",
          proposalDocumentDefault.winStrategyCommercials
        );

    proposalDocument.winStrategyCommercialsHeading
      ? proposal_document.append(
          "win_strategy_commercials_heading",
          proposalDocument.winStrategyCommercialsHeading
        )
      : proposal_document.append(
          "win_strategy_commercials_heading",
          proposalDocumentDefault.winStrategyCommercialsHeading
        );

    proposalDocument.winStrategyOther
      ? proposal_document.append(
          "win_strategy_other",
          proposalDocument.winStrategyOther
        )
      : proposal_document.append(
          "win_strategy_other",
          proposalDocumentDefault.winStrategyOther
        );

    proposalDocument.winStrategyOtherHeading
      ? proposal_document.append(
          "win_strategy_other_heading",
          proposalDocument.winStrategyOtherHeading
        )
      : proposal_document.append(
          "win_strategy_other_heading",
          proposalDocumentDefault.winStrategyOtherHeading
        );

    proposalDocument.winStrategyPartnerships
      ? proposal_document.append(
          "win_strategy_partnerships",
          proposalDocument.winStrategyPartnerships
        )
      : proposal_document.append(
          "win_strategy_partnerships",
          proposalDocumentDefault.winStrategyPartnerships
        );

    proposalDocument.winStrategyPartnershipsHeading
      ? proposal_document.append(
          "win_strategy_partnerships_heading",
          proposalDocument.winStrategyPartnershipsHeading
        )
      : proposal_document.append(
          "win_strategy_partnerships_heading",
          proposalDocumentDefault.winStrategyPartnershipsHeading
        );

    proposalDocument.winStrategyPrice
      ? proposal_document.append(
          "win_strategy_price",
          proposalDocument.winStrategyPrice
        )
      : proposal_document.append(
          "win_strategy_price",
          proposalDocumentDefault.winStrategyPrice
        );

    proposalDocument.winStrategyPriceHeading
      ? proposal_document.append(
          "win_strategy_price_heading",
          proposalDocument.winStrategyPriceHeading
        )
      : proposal_document.append(
          "win_strategy_price_heading",
          proposalDocumentDefault.winStrategyPriceHeading
        );

    proposalDocument.winStrategyReferences
      ? proposal_document.append(
          "win_strategy_references",
          proposalDocument.winStrategyReferences
        )
      : proposal_document.append(
          "win_strategy_references",
          proposalDocumentDefault.winStrategyReferences
        );

    proposalDocument.winStrategyReferencesHeading
      ? proposal_document.append(
          "win_strategy_references_heading",
          proposalDocument.winStrategyReferencesHeading
        )
      : proposal_document.append(
          "win_strategy_references_heading",
          proposalDocumentDefault.winStrategyReferencesHeading
        );

    proposalDocument.winStrategyRelationships
      ? proposal_document.append(
          "win_strategy_relationships",
          proposalDocument.winStrategyRelationships
        )
      : proposal_document.append(
          "win_strategy_relationships",
          proposalDocumentDefault.winStrategyRelationships
        );

    proposalDocument.winStrategyRelationshipsHeading
      ? proposal_document.append(
          "win_strategy_relationships_heading",
          proposalDocument.winStrategyRelationshipsHeading
        )
      : proposal_document.append(
          "win_strategy_relationships_heading",
          proposalDocumentDefault.winStrategyRelationshipsHeading
        );

    proposalDocument.winStrategySolution
      ? proposal_document.append(
          "win_strategy_solution",
          proposalDocument.winStrategySolution
        )
      : proposal_document.append(
          "win_strategy_solution",
          proposalDocumentDefault.winStrategySolution
        );

    proposalDocument.winStrategySolutionHeading
      ? proposal_document.append(
          "win_strategy_solution_heading",
          proposalDocument.winStrategySolutionHeading
        )
      : proposal_document.append(
          "win_strategy_solution_heading",
          proposalDocumentDefault.winStrategySolutionHeading
        );

    proposalDocument.winStrategyTeam
      ? proposal_document.append(
          "win_strategy_team",
          proposalDocument.winStrategyTeam
        )
      : proposal_document.append(
          "win_strategy_team",
          proposalDocumentDefault.winStrategyTeam
        );

    proposalDocument.winStrategyTeamHeading
      ? proposal_document.append(
          "win_strategy_team_heading",
          proposalDocument.winStrategyTeamHeading
        )
      : proposal_document.append(
          "win_strategy_team_heading",
          proposalDocumentDefault.winStrategyTeamHeading
        );

    // Dispatch action
    dispatch(
      updateProposalDocumentAction(
        proposalDetails.proposal.proposal_document,
        proposal_document
      )
    );
  };

  // Update proposal document style
  const updateDocumentStyle = () => {
    // Prepare data
    const proposal_document = new FormData();

    proposalDocument.font
      ? proposal_document.append("font", proposalDocument.font)
      : proposal_document.append("font", proposalDocumentDefault.font);

    if (
      proposalDocument.logo &&
      proposalDocument.logo !== proposalDocumentDetails.proposal_document.logo
    ) {
      proposal_document.append("logo", proposalDocument.logo);
    } else if (proposalDocument.logo === "") {
      proposal_document.append("logo", proposalDocument.logo);
    }

    proposalDocument.primaryColour
      ? proposal_document.append(
          "primary_colour",
          proposalDocument.primaryColour
        )
      : proposal_document.append(
          "primary_colour",
          proposalDocumentDefault.primaryColour
        );

    proposalDocument.secondaryColour
      ? proposal_document.append(
          "secondary_colour",
          proposalDocument.secondaryColour
        )
      : proposal_document.append(
          "secondary_colour",
          proposalDocumentDefault.secondaryColour
        );

    proposalDocument.template
      ? proposal_document.append("template", proposalDocument.template)
      : proposal_document.append("template", proposalDocumentDefault.template);

    // Dispatch action
    dispatch(
      updateProposalDocumentAction(
        proposalDetails.proposal.proposal_document,
        proposal_document
      )
    );
  };

  // Proposal fee
  // Update proposal fee
  const updateProposalFee = () => {
    // Prepare data
    const proposal_fee = new FormData();

    proposalFee.adjustment
      ? proposal_fee.append("adjustment", proposalFee.adjustment)
      : proposal_fee.append("adjustment", proposalFeeDefault.adjustment);

    // Dispatch action
    dispatch(
      updateProposalFeeAction(proposalFeeDetails.proposal_fee.id, proposal_fee)
    );
  };

  // Proposal labour expense
  // Add labour expense
  const addLabourExpense = () => {
    // Prepare data
    const proposal_labour_expense = new FormData();

    proposalLabourExpense.competencies
      ? proposal_labour_expense.append(
          "competencies",
          JSON.stringify(proposalLabourExpense.competencies)
        )
      : proposal_labour_expense.append(
          "competencies",
          JSON.stringify(proposalLabourExpenseDefault.competencies)
        );

    proposalLabourExpense.customiseDays && proposalLabourExpense.detail
      ? proposal_labour_expense.append(
          "detail",
          JSON.stringify(proposalLabourExpense.detail)
        )
      : proposal_labour_expense.append(
          "detail",
          JSON.stringify(proposalLabourExpenseDefault.detail)
        );

    proposalLabourExpense.employment
      ? proposal_labour_expense.append(
          "employment",
          proposalLabourExpense.employment
        )
      : proposal_labour_expense.append(
          "employment",
          proposalLabourExpenseDefault.employment
        );

    proposalLabourExpense.finish
      ? proposal_labour_expense.append(
          "finish",
          getDjangoDate(proposalLabourExpense.finish)
        )
      : proposal_labour_expense.append(
          "finish",
          getDjangoDate(proposalLabourExpenseDefault.finish)
        );

    proposalLabourExpense.location
      ? proposal_labour_expense.append(
          "location",
          proposalLabourExpense.location
        )
      : proposal_labour_expense.append(
          "location",
          proposalLabourExpenseDefault.location
        );

    proposalLabourExpense.marginPercentage !== null
      ? proposal_labour_expense.append(
          "margin_percentage",
          proposalLabourExpense.marginPercentage
        )
      : proposal_labour_expense.append(
          "margin_percentage",
          proposalLabourExpenseDefault.marginPercentage
        );

    proposalLabourExpense.customiseDays && proposalLabourExpense.method
      ? proposal_labour_expense.append("method", proposalLabourExpense.method)
      : proposal_labour_expense.append(
          "method",
          proposalLabourExpenseDefault.method
        );

    proposalLabourExpense.minutes
      ? proposal_labour_expense.append("minutes", proposalLabourExpense.minutes)
      : proposal_labour_expense.append(
          "minutes",
          proposalLabourExpenseDefault.minutes
        );

    proposalLabourExpense.notes
      ? proposal_labour_expense.append("notes", proposalLabourExpense.notes)
      : proposal_labour_expense.append(
          "notes",
          proposalLabourExpenseDefault.notes
        );

    proposalLabourExpense.proposalService
      ? proposal_labour_expense.append(
          "proposal_service",
          proposalLabourExpense.proposalService
        )
      : proposal_labour_expense.append(
          "proposal_service",
          proposalLabourExpenseDefault.proposalService
        );

    proposalLabourExpense.role
      ? proposal_labour_expense.append("role", proposalLabourExpense.role)
      : proposal_labour_expense.append(
          "role",
          proposalLabourExpenseDefault.role
        );

    proposalLabourExpense.staff
      ? proposal_labour_expense.append("staff", proposalLabourExpense.staff)
      : proposal_labour_expense.append(
          "staff",
          proposalLabourExpenseDefault.staff
        );

    proposalLabourExpense.start
      ? proposal_labour_expense.append(
          "start",
          getDjangoDate(proposalLabourExpense.start)
        )
      : proposal_labour_expense.append(
          "start",
          getDjangoDate(proposalLabourExpenseDefault.start)
        );

    proposalLabourExpense.customiseDays && proposalLabourExpense.weekdays
      ? proposal_labour_expense.append(
          "weekdays",
          JSON.stringify(proposalLabourExpense.weekdays)
        )
      : proposal_labour_expense.append(
          "weekdays",
          JSON.stringify(proposalLabourExpenseDefault.weekdays)
        );

    // Dispatch action
    dispatch(createProposalLabourExpenseAction(proposal_labour_expense));
  };

  // Delete labour expense
  const deleteLabourExpense = () => {
    // Dispatch action
    dispatch(
      deleteProposalLabourExpenseAction(
        proposalLabourExpenseDetails.proposal_labour_expense.id
      )
    );
  };

  // Update labour expense
  const updateLabourExpense = () => {
    // Prepare data
    const proposal_labour_expense = new FormData();

    proposalLabourExpense.competencies
      ? proposal_labour_expense.append(
          "competencies",
          JSON.stringify(proposalLabourExpense.competencies)
        )
      : proposal_labour_expense.append(
          "competencies",
          JSON.stringify(proposalLabourExpenseDefault.competencies)
        );

    proposalLabourExpense.customiseDays && proposalLabourExpense.detail
      ? proposal_labour_expense.append(
          "detail",
          JSON.stringify(proposalLabourExpense.detail)
        )
      : proposal_labour_expense.append(
          "detail",
          JSON.stringify(proposalLabourExpenseDefault.detail)
        );

    proposalLabourExpense.employment
      ? proposal_labour_expense.append(
          "employment",
          proposalLabourExpense.employment
        )
      : proposal_labour_expense.append(
          "employment",
          proposalLabourExpenseDefault.employment
        );

    proposalLabourExpense.finish
      ? proposal_labour_expense.append(
          "finish",
          getDjangoDate(proposalLabourExpense.finish)
        )
      : proposal_labour_expense.append(
          "finish",
          getDjangoDate(proposalLabourExpenseDefault.finish)
        );

    proposalLabourExpense.location
      ? proposal_labour_expense.append(
          "location",
          proposalLabourExpense.location
        )
      : proposal_labour_expense.append(
          "location",
          proposalLabourExpenseDefault.location
        );

    proposalLabourExpense.marginPercentage !== null
      ? proposal_labour_expense.append(
          "margin_percentage",
          proposalLabourExpense.marginPercentage
        )
      : proposal_labour_expense.append(
          "margin_percentage",
          proposalLabourExpenseDefault.marginPercentage
        );

    proposalLabourExpense.customiseDays && proposalLabourExpense.method
      ? proposal_labour_expense.append("method", proposalLabourExpense.method)
      : proposal_labour_expense.append(
          "method",
          proposalLabourExpenseDefault.method
        );

    proposalLabourExpense.minutes
      ? proposal_labour_expense.append("minutes", proposalLabourExpense.minutes)
      : proposal_labour_expense.append(
          "minutes",
          proposalLabourExpenseDefault.minutes
        );

    proposalLabourExpense.notes
      ? proposal_labour_expense.append("notes", proposalLabourExpense.notes)
      : proposal_labour_expense.append(
          "notes",
          proposalLabourExpenseDefault.notes
        );

    proposalLabourExpense.proposalService
      ? proposal_labour_expense.append(
          "proposal_service",
          proposalLabourExpense.proposalService
        )
      : proposal_labour_expense.append(
          "proposal_service",
          proposalLabourExpenseDefault.proposalService
        );

    proposalLabourExpense.role
      ? proposal_labour_expense.append("role", proposalLabourExpense.role)
      : proposal_labour_expense.append(
          "role",
          proposalLabourExpenseDefault.role
        );

    proposalLabourExpense.staff
      ? proposal_labour_expense.append("staff", proposalLabourExpense.staff)
      : proposal_labour_expense.append(
          "staff",
          proposalLabourExpenseDefault.staff
        );

    proposalLabourExpense.start
      ? proposal_labour_expense.append(
          "start",
          getDjangoDate(proposalLabourExpense.start)
        )
      : proposal_labour_expense.append(
          "start",
          getDjangoDate(proposalLabourExpenseDefault.start)
        );

    proposalLabourExpense.customiseDays && proposalLabourExpense.weekdays
      ? proposal_labour_expense.append(
          "weekdays",
          JSON.stringify(proposalLabourExpense.weekdays)
        )
      : proposal_labour_expense.append(
          "weekdays",
          JSON.stringify(proposalLabourExpenseDefault.weekdays)
        );

    // Dispatch action
    dispatch(
      updateProposalLabourExpenseAction(
        proposalLabourExpenseDetails.proposal_labour_expense.id,
        proposal_labour_expense
      )
    );
  };

  // Proposal legal section
  // Add legal section
  const addLegalSection = () => {
    // Prepare data
    const proposal_legal_section = new FormData();

    proposalLegalSection.amended
      ? proposal_legal_section.append("amended", proposalLegalSection.amended)
      : proposal_legal_section.append(
          "amended",
          proposalLegalSectionDefault.amended
        );

    if (
      proposalLegalSection.attachment &&
      proposalLegalSection.attachment !==
        proposalLegalSectionDetails.proposal_legal_section.attachment
    ) {
      proposal_legal_section.append(
        "attachment",
        proposalLegalSection.attachment
      );
    } else if (proposalLegalSection.attachment === "") {
      proposal_legal_section.append(
        "attachment",
        proposalLegalSectionDefault.attachment
      );
    }

    proposalLegalSection.comments
      ? proposal_legal_section.append("comments", proposalLegalSection.comments)
      : proposal_legal_section.append(
          "comments",
          proposalLegalSectionDefault.comments
        );

    proposalLegalSection.number
      ? proposal_legal_section.append("number", proposalLegalSection.number)
      : proposal_legal_section.append(
          "number",
          proposalLegalSectionDefault.number
        );

    proposalLegalSection.originator
      ? proposal_legal_section.append(
          "originator",
          proposalLegalSection.originator
        )
      : proposal_legal_section.append(
          "originator",
          proposalLegalSectionDefault.originator
        );

    proposal_legal_section.append("proposal", id);

    proposalLegalSection.proposed
      ? proposal_legal_section.append("proposed", proposalLegalSection.proposed)
      : proposal_legal_section.append(
          "proposed",
          proposalLegalSectionDefault.proposed
        );

    proposalLegalSection.title
      ? proposal_legal_section.append("title", proposalLegalSection.title)
      : proposal_legal_section.append(
          "title",
          proposalLegalSectionDefault.title
        );

    proposalLegalSection.status
      ? proposal_legal_section.append("status", proposalLegalSection.status)
      : proposal_legal_section.append(
          "status",
          proposalLegalSectionDefault.status
        );

    // Dispatch action
    dispatch(createProposalLegalSectionAction(proposal_legal_section));
  };

  // Delete legal section
  const deleteLegalSection = () => {
    // Dispatch action
    dispatch(
      deleteProposalLegalSectionAction(
        proposalLegalSectionDetails.proposal_legal_section.id
      )
    );
  };

  // Download legal sections
  const downloadProposalLegalSections = useCallback(async () => {
    // Create workbook
    const legalSectionWorkbook = new ExcelJS.Workbook();

    legalSectionWorkbook.created = new Date();

    legalSectionWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = legalSectionWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload legal terms",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to upload legal terms into advaise.app.",
      ],
      [
        "2. The tab 'Terms' contains the legal terms already captured (if any). You can add to these by copying and pasting from other documents, such as the client's RFP.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Title: The title of the section, which advaise.app uses as headline in the proposal document. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(c) Number: A UNIQUE section number, which advaise.app uses to label and order the legal terms in the proposal document. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(d) Proposed: The original legal terms proposed. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(e) Originator: The party who proposed the original legal terms."],
      [
        "(f) Amended: The revised legal terms (if any), updated to address feedback to/from the client.",
      ],
      ["(g) Comments: Notes on the rationale or status of changes."],
      [
        "(h) Status: A tag to indicate whether you have agreed the legal terms with the client. THIS FIELD IS RESTRICTED to one of 'Agreed', 'Not agreed', or 'Under review', which you can select using the dropdown.",
      ],
      [
        "(i) Delete: A flag to delete the record. Note that you cannot delete legal terms by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const legalSectionSheet = legalSectionWorkbook.addWorksheet("Terms", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name} - Legal Terms`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style data sheet columns
    legalSectionSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Title",
        key: "title",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Number",
        key: "number",
        style: columnStyle,
        width: 10,
      },
      {
        header: "Proposed",
        key: "proposed",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Originator",
        key: "originator",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Amended",
        key: "amended",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    legalSectionSheet.autoFilter = "A1:I1";

    const topRow = legalSectionSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    legalSectionSheet.addConditionalFormatting({
      ref: "H2:H20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Agreed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Not agreed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Under review"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    legalSectionSheet.addConditionalFormatting({
      ref: "I2:I20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    legalSectionSheet.pageSetup.printTitlesRow = "1:9";

    // Populate data sheet data
    for (
      var i = 0;
      i < proposalLegalSectionDownload.proposal_legal_sections.length;
      i++
    ) {
      legalSectionSheet.addRow({
        id: proposalLegalSectionDownload.proposal_legal_sections[i].id,
        title: proposalLegalSectionDownload.proposal_legal_sections[i].title,
        number: proposalLegalSectionDownload.proposal_legal_sections[i].number,
        proposed:
          proposalLegalSectionDownload.proposal_legal_sections[i].proposed,
        originator: proposalLegalSectionDownload.proposal_legal_sections[i]
          .originator
          ? proposalLegalSectionDownload.proposal_legal_sections[i].originator
          : "",
        amended: proposalLegalSectionDownload.proposal_legal_sections[i].amended
          ? proposalLegalSectionDownload.proposal_legal_sections[i].amended
          : "",
        comments: proposalLegalSectionDownload.proposal_legal_sections[i]
          .comments
          ? proposalLegalSectionDownload.proposal_legal_sections[i].comments
          : "",
        status: proposalLegalSectionDownload.proposal_legal_sections[i].status,
        delete: "",
      });
    }

    // Add data validations
    legalSectionSheet.dataValidations.add("H2:H20000", {
      allowBlank: false,
      formulae: ['"Agreed,Not agreed,Under review"'],
      type: "list",
    });

    // Protect data sheet
    legalSectionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      legalSectionSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`G${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`H${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`I${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await legalSectionWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, `${proposalDetails.proposal.name} - Legal Terms.xlsx`);
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Legal terms downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the legal terms file. Please refresh the page and try again.",
      });
    }
  }, [
    proposalDetails.proposal.client_name,
    proposalDetails.proposal.name,
    proposalLegalSectionDownload.proposal_legal_sections,
  ]);

  useEffect(() => {
    if (proposalLegalSectionDownload.success) {
      downloadProposalLegalSections();

      dispatch(resetDownloadProposalLegalSectionsAction());
    }
    // eslint-disable-next-line
  }, [proposalLegalSectionDownload.success]);

  // Update legal section
  const updateLegalSection = () => {
    // Prepare data
    const proposal_legal_section = new FormData();

    proposalLegalSection.amended
      ? proposal_legal_section.append("amended", proposalLegalSection.amended)
      : proposal_legal_section.append(
          "amended",
          proposalLegalSectionDefault.amended
        );

    if (
      proposalLegalSection.attachment &&
      proposalLegalSection.attachment !==
        proposalLegalSectionDetails.proposal_legal_section.attachment
    ) {
      proposal_legal_section.append(
        "attachment",
        proposalLegalSection.attachment
      );
    } else if (proposalLegalSection.attachment === "") {
      proposal_legal_section.append(
        "attachment",
        proposalLegalSectionDefault.attachment
      );
    }

    proposalLegalSection.comments
      ? proposal_legal_section.append("comments", proposalLegalSection.comments)
      : proposal_legal_section.append(
          "comments",
          proposalLegalSectionDefault.comments
        );

    proposalLegalSection.number
      ? proposal_legal_section.append("number", proposalLegalSection.number)
      : proposal_legal_section.append(
          "number",
          proposalLegalSectionDefault.number
        );

    proposalLegalSection.originator
      ? proposal_legal_section.append(
          "originator",
          proposalLegalSection.originator
        )
      : proposal_legal_section.append(
          "originator",
          proposalLegalSectionDefault.originator
        );

    proposalLegalSection.proposed
      ? proposal_legal_section.append("proposed", proposalLegalSection.proposed)
      : proposal_legal_section.append(
          "proposed",
          proposalLegalSectionDefault.proposed
        );

    proposalLegalSection.title
      ? proposal_legal_section.append("title", proposalLegalSection.title)
      : proposal_legal_section.append(
          "title",
          proposalLegalSectionDefault.title
        );

    proposalLegalSection.status
      ? proposal_legal_section.append("status", proposalLegalSection.status)
      : proposal_legal_section.append(
          "status",
          proposalLegalSectionDefault.status
        );

    // Dispatch action
    dispatch(
      updateProposalLegalSectionAction(
        proposalLegalSectionDetails.proposal_legal_section.id,
        proposal_legal_section
      )
    );
  };

  // Upload legal sections
  const uploadProposalLegalSections = async () => {
    // Create workbook
    const legalSectionWorkbook = new ExcelJS.Workbook();

    // Read file and extract data
    var records = [];
    try {
      await legalSectionWorkbook.xlsx
        .load(proposalLegalSectionUploadFile.file)
        .then(() => {
          // Get data sheet
          const legalSectionSheet = legalSectionWorkbook.getWorksheet("Terms");

          // Get data
          legalSectionSheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              records.push({
                id: row.getCell(1).value,
                title: row.getCell(2).value
                  ? row.getCell(2).value
                  : proposalLegalSectionDefault.title,
                number: row.getCell(3).value
                  ? row.getCell(3).value
                  : proposalLegalSectionDefault.number,
                proposed: row.getCell(4).value
                  ? row.getCell(4).value
                  : proposalLegalSectionDefault.proposed,
                originator: row.getCell(5).value
                  ? row.getCell(5).value
                  : proposalLegalSectionDefault.originator,
                amended: row.getCell(6).value
                  ? row.getCell(6).value
                  : proposalLegalSectionDefault.amended,
                comments: row.getCell(7).value
                  ? row.getCell(7).value
                  : proposalLegalSectionDefault.comments,
                status: row.getCell(8).value
                  ? row.getCell(8).value
                  : proposalLegalSectionDefault.status,
                delete: row.getCell(9).value,
              });
            }
          });

          // Upload data
          dispatch(uploadProposalLegalSectionsAction(id, records));
        });
    } catch (error) {
      setProposalLegalSectionUploadFile({
        ...proposalLegalSectionUploadFile,
        error: true,
      });
    }
  };

  // Download proposal legal sections that failed to upload
  const downloadProposalLegalSectionsFailedUpload = useCallback(async () => {
    // Create workbook
    const legalSectionWorkbook = new ExcelJS.Workbook();

    legalSectionWorkbook.created = new Date();

    legalSectionWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = legalSectionWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to address legal term exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to fix legal terms that had exceptions and upload them into advaise.app.",
      ],
      [
        "2. The tab 'Terms' contains the sections that had exceptions. The most common issue is missing data for a field that is required. Update the legal terms based on the field definitions below and upload this file to address the exceptions.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Title: The title of the section, which advaise.app uses as headline in the proposal document. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(c) Number: A UNIQUE section number, which advaise.app uses to label and order the legal terms in the proposal document. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(d) Proposed: The original legal terms proposed. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(e) Originator: The party who proposed the original legal terms."],
      [
        "(f) Amended: The revised legal terms (if any), updated to address feedback to/from the client.",
      ],
      ["(g) Comments: Notes on the rationale or status of changes."],
      [
        "(h) Status: A tag to indicate whether you have agreed the legal terms with the client. THIS FIELD IS RESTRICTED to one of 'Agreed', 'Not agreed', or 'Under review', which you can select using the dropdown.",
      ],
      [
        "(i) Delete: A flag to delete the record. Note that you cannot delete legal terms by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const legalSectionSheet = legalSectionWorkbook.addWorksheet("Terms", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name} - Legal Term Exceptions`,
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style data sheet columns
    legalSectionSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Title",
        key: "title",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Number",
        key: "number",
        style: columnStyle,
        width: 10,
      },
      {
        header: "Proposed",
        key: "proposed",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Originator",
        key: "originator",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Amended",
        key: "amended",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Comments",
        key: "comments",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    legalSectionSheet.autoFilter = "A1:I1";

    const topRow = legalSectionSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    legalSectionSheet.addConditionalFormatting({
      ref: "H2:H20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Agreed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Not agreed"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Under review"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    legalSectionSheet.addConditionalFormatting({
      ref: "I2:I20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    legalSectionSheet.pageSetup.printTitlesRow = "1:9";

    // Populate data sheet data
    for (var i = 0; i < proposalLegalSectionUpload.records_failed.length; i++) {
      legalSectionSheet.addRow({
        id: proposalLegalSectionUpload.records_failed[i].id,
        title: proposalLegalSectionUpload.records_failed[i].title,
        number: proposalLegalSectionUpload.records_failed[i].number,
        proposed: proposalLegalSectionUpload.records_failed[i].proposed,
        originator: proposalLegalSectionUpload.records_failed[i].originator
          ? proposalLegalSectionUpload.records_failed[i].originator
          : "",
        amended: proposalLegalSectionUpload.records_failed[i].amended
          ? proposalLegalSectionUpload.records_failed[i].amended
          : "",
        comments: proposalLegalSectionUpload.records_failed[i].comments
          ? proposalLegalSectionUpload.records_failed[i].comments
          : "",
        status: proposalLegalSectionUpload.records_failed[i].status,
        delete: "",
      });
    }

    // Add data validations
    legalSectionSheet.dataValidations.add("H2:H20000", {
      allowBlank: false,
      formulae: ['"Agreed,Not agreed,Under review"'],
      type: "list",
    });

    // Protect data sheet
    legalSectionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      legalSectionSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`G${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`H${k + 2}`).protection = {
        locked: false,
      };

      legalSectionSheet.getCell(`I${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await legalSectionWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${proposalDetails.proposal.name} - Legal Term Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the legal term exceptions file. Please refresh the page and try again.",
      });
    }
  }, [
    proposalDetails.proposal.client_name,
    proposalDetails.proposal.name,
    proposalLegalSectionUpload.records_failed,
  ]);

  useEffect(() => {
    if (proposalLegalSectionUpload.end) {
      proposalLegalSectionUpload.records_failed.length > 0 &&
        downloadProposalLegalSectionsFailedUpload();
    }
    // eslint-disable-next-line
  }, [proposalLegalSectionUpload.end]);

  // Proposal lesson
  // Add proposal lesson
  const addProposalLesson = () => {
    // Prepare data
    const proposal_lesson = new FormData();

    proposalLesson.category
      ? proposal_lesson.append("category", proposalLesson.category)
      : proposal_lesson.append("category", proposalLessonDefault.category);

    proposal_lesson.append("proposal", id);

    proposalLesson.lesson
      ? proposal_lesson.append("lesson", proposalLesson.lesson)
      : proposal_lesson.append("lesson", proposalLessonDefault.lesson);

    proposalLesson.name
      ? proposal_lesson.append("name", proposalLesson.name)
      : proposal_lesson.append("name", proposalLessonDefault.name);

    // Dispatch action
    dispatch(createProposalLessonAction(proposal_lesson));
  };

  // Delete proposal lesson
  const deleteProposalLesson = () => {
    // Dispatch action
    dispatch(
      deleteProposalLessonAction(proposalLessonDetails.proposal_lesson.id)
    );
  };

  // Update proposal lesson
  const updateProposalLesson = () => {
    // Prepare data
    const proposal_lesson = new FormData();

    proposalLesson.category
      ? proposal_lesson.append("category", proposalLesson.category)
      : proposal_lesson.append("category", proposalLessonDefault.category);

    proposalLesson.lesson
      ? proposal_lesson.append("lesson", proposalLesson.lesson)
      : proposal_lesson.append("lesson", proposalLessonDefault.lesson);

    proposalLesson.name
      ? proposal_lesson.append("name", proposalLesson.name)
      : proposal_lesson.append("name", proposalLessonDefault.name);

    // Dispatch action
    dispatch(
      updateProposalLessonAction(
        proposalLessonDetails.proposal_lesson.id,
        proposal_lesson
      )
    );
  };

  // Proposal milestone
  // Add milestone
  const addMilestone = () => {
    // Prepare data
    const proposal_milestone = new FormData();

    proposalMilestone.description
      ? proposal_milestone.append("description", proposalMilestone.description)
      : proposal_milestone.append(
          "description",
          proposalMilestoneDefault.description
        );

    proposalMilestone.due
      ? proposal_milestone.append("due", getDjangoDate(proposalMilestone.due))
      : proposal_milestone.append(
          "due",
          getDjangoDate(proposalMilestoneDefault.due)
        );

    proposalMilestone.name
      ? proposal_milestone.append("name", proposalMilestone.name)
      : proposal_milestone.append("name", proposalMilestoneDefault.name);

    proposal_milestone.append("proposal", id);

    proposalMilestone.value
      ? proposal_milestone.append("value", proposalMilestone.value)
      : proposal_milestone.append("value", proposalMilestoneDefault.value);

    // Dispatch action
    dispatch(createProposalMilestoneAction(proposal_milestone));
  };

  // Delete milestone
  const deleteMilestone = () => {
    // Dispatch action
    dispatch(
      deleteProposalMilestoneAction(
        proposalMilestoneDetails.proposal_milestone.id
      )
    );
  };

  // Update milestone
  const updateMilestone = () => {
    // Prepare data
    const proposal_milestone = new FormData();

    proposalMilestone.description
      ? proposal_milestone.append("description", proposalMilestone.description)
      : proposal_milestone.append(
          "description",
          proposalMilestoneDefault.description
        );

    proposalMilestone.due
      ? proposal_milestone.append("due", getDjangoDate(proposalMilestone.due))
      : proposal_milestone.append(
          "due",
          getDjangoDate(proposalMilestoneDefault.due)
        );

    proposalMilestone.name
      ? proposal_milestone.append("name", proposalMilestone.name)
      : proposal_milestone.append("name", proposalMilestoneDefault.name);

    proposalMilestone.value
      ? proposal_milestone.append("value", proposalMilestone.value)
      : proposal_milestone.append("value", proposalMilestoneDefault.value);

    // Dispatch action
    dispatch(
      updateProposalMilestoneAction(
        proposalMilestoneDetails.proposal_milestone.id,
        proposal_milestone
      )
    );
  };

  // Proposal non-labour expense
  // Add non-labour expense
  const addNonLabourExpense = () => {
    // Prepare data
    const proposal_non_labour_expense = new FormData();

    proposalNonLabourExpense.category
      ? proposal_non_labour_expense.append(
          "category",
          proposalNonLabourExpense.category
        )
      : proposal_non_labour_expense.append(
          "category",
          proposalNonLabourExpenseDefault.category
        );

    proposalNonLabourExpense.cost
      ? proposal_non_labour_expense.append(
          "cost",
          proposalNonLabourExpense.cost
        )
      : proposal_non_labour_expense.append(
          "cost",
          proposalNonLabourExpenseDefault.cost
        );

    proposalNonLabourExpense.marginPercentage !== null
      ? proposal_non_labour_expense.append(
          "margin_percentage",
          proposalNonLabourExpense.marginPercentage
        )
      : proposal_non_labour_expense.append(
          "margin_percentage",
          proposalNonLabourExpenseDefault.marginPercentage
        );

    proposalNonLabourExpense.name
      ? proposal_non_labour_expense.append(
          "name",
          proposalNonLabourExpense.name
        )
      : proposal_non_labour_expense.append(
          "name",
          proposalNonLabourExpenseDefault.name
        );

    proposalNonLabourExpense.notes
      ? proposal_non_labour_expense.append(
          "notes",
          proposalNonLabourExpense.notes
        )
      : proposal_non_labour_expense.append(
          "notes",
          proposalNonLabourExpenseDefault.notes
        );

    proposalNonLabourExpense.proposalService
      ? proposal_non_labour_expense.append(
          "proposal_service",
          proposalNonLabourExpense.proposalService
        )
      : proposal_non_labour_expense.append(
          "proposal_service",
          proposalNonLabourExpenseDefault.proposalService
        );

    // Dispatch action
    dispatch(createProposalNonLabourExpenseAction(proposal_non_labour_expense));
  };

  // Delete non-labour expense
  const deleteNonLabourExpense = () => {
    // Dispatch action
    dispatch(
      deleteProposalNonLabourExpenseAction(
        proposalNonLabourExpenseDetails.proposal_non_labour_expense.id
      )
    );
  };

  // Update non-labour expense
  const updateNonLabourExpense = () => {
    // Prepare data
    const proposal_non_labour_expense = new FormData();

    proposalNonLabourExpense.category
      ? proposal_non_labour_expense.append(
          "category",
          proposalNonLabourExpense.category
        )
      : proposal_non_labour_expense.append(
          "category",
          proposalNonLabourExpenseDefault.category
        );

    proposalNonLabourExpense.cost
      ? proposal_non_labour_expense.append(
          "cost",
          proposalNonLabourExpense.cost
        )
      : proposal_non_labour_expense.append(
          "cost",
          proposalNonLabourExpenseDefault.cost
        );

    proposalNonLabourExpense.marginPercentage !== null
      ? proposal_non_labour_expense.append(
          "margin_percentage",
          proposalNonLabourExpense.marginPercentage
        )
      : proposal_non_labour_expense.append(
          "margin_percentage",
          proposalNonLabourExpenseDefault.marginPercentage
        );

    proposalNonLabourExpense.name
      ? proposal_non_labour_expense.append(
          "name",
          proposalNonLabourExpense.name
        )
      : proposal_non_labour_expense.append(
          "name",
          proposalNonLabourExpenseDefault.name
        );

    proposalNonLabourExpense.notes
      ? proposal_non_labour_expense.append(
          "notes",
          proposalNonLabourExpense.notes
        )
      : proposal_non_labour_expense.append(
          "notes",
          proposalNonLabourExpenseDefault.notes
        );

    proposalNonLabourExpense.proposalService
      ? proposal_non_labour_expense.append(
          "proposal_service",
          proposalNonLabourExpense.proposalService
        )
      : proposal_non_labour_expense.append(
          "proposal_service",
          proposalNonLabourExpenseDefault.proposalService
        );

    // Dispatch action
    dispatch(
      updateProposalNonLabourExpenseAction(
        proposalNonLabourExpenseDetails.proposal_non_labour_expense.id,
        proposal_non_labour_expense
      )
    );
  };

  // Proposal permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const proposal_permission = new FormData();

    proposalPermission.level
      ? proposal_permission.append("level", proposalPermission.level)
      : proposal_permission.append("level", proposalPermissionDefault.level);

    proposalPermission.manager
      ? proposal_permission.append("manager", proposalPermission.manager)
      : proposal_permission.append(
          "manager",
          proposalPermissionDefault.manager
        );

    proposal_permission.append("proposal", id);

    // Dispatch action
    dispatch(createProposalPermissionAction(proposal_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteProposalPermissionAction(
        proposalPermissionDetails.proposal_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const proposal_permission = new FormData();

    proposalPermission.level
      ? proposal_permission.append("level", proposalPermission.level)
      : proposal_permission.append("level", proposalPermissionDefault.level);

    proposalPermission.manager
      ? proposal_permission.append("manager", proposalPermission.manager)
      : proposal_permission.append(
          "manager",
          proposalPermissionDefault.manager
        );

    // Dispatch action
    dispatch(
      updateProposalPermissionAction(
        proposalPermissionDetails.proposal_permission.id,
        proposal_permission
      )
    );
  };

  // Proposal question
  // Add question
  const addQuestion = () => {
    // Prepare data
    const proposal_question = new FormData();

    proposalQuestion.category
      ? proposal_question.append("category", proposalQuestion.category)
      : proposal_question.append("category", proposalQuestionDefault.category);

    proposalQuestion.name
      ? proposal_question.append("name", proposalQuestion.name)
      : proposal_question.append("name", proposalQuestionDefault.name);

    proposalQuestion.owner
      ? proposal_question.append("owner", proposalQuestion.owner)
      : proposal_question.append("owner", proposalQuestionDefault.owner);

    proposal_question.append("proposal", id);

    proposalQuestion.question
      ? proposal_question.append("question", proposalQuestion.question)
      : proposal_question.append("question", proposalQuestionDefault.question);

    // Dispatch action
    dispatch(createProposalQuestionAction(proposal_question));
  };

  // Delete question
  const deleteQuestion = () => {
    // Dispatch action
    dispatch(
      deleteProposalQuestionAction(proposalQuestionDetails.proposal_question.id)
    );
  };

  // Update question
  const updateQuestion = () => {
    // Prepare data
    const proposal_question = new FormData();

    proposalQuestion.answer
      ? proposal_question.append("answer", proposalQuestion.answer)
      : proposal_question.append("answer", proposalQuestionDefault.answer);

    proposalQuestion.category
      ? proposal_question.append("category", proposalQuestion.category)
      : proposal_question.append("category", proposalQuestionDefault.category);

    proposalQuestion.name
      ? proposal_question.append("name", proposalQuestion.name)
      : proposal_question.append("name", proposalQuestionDefault.name);

    proposalQuestion.owner
      ? proposal_question.append("owner", proposalQuestion.owner)
      : proposal_question.append("owner", proposalQuestionDefault.owner);

    proposalQuestion.question
      ? proposal_question.append("question", proposalQuestion.question)
      : proposal_question.append("question", proposalQuestionDefault.question);

    proposalQuestion.status
      ? proposal_question.append("status", proposalQuestion.status)
      : proposal_question.append("status", proposalQuestionDefault.status);

    // Dispatch action
    dispatch(
      updateProposalQuestionAction(
        proposalQuestionDetails.proposal_question.id,
        proposal_question
      )
    );
  };

  // Proposal rate
  // Add rate
  const addRate = () => {
    // Prepare data
    const proposal_rate = new FormData();

    proposalRate.marginPercentage
      ? proposal_rate.append("margin_percentage", proposalRate.marginPercentage)
      : proposal_rate.append(
          "margin_percentage",
          proposalRateDefault.marginPercentage
        );

    proposal_rate.append("proposal", id);

    proposalRate.role
      ? proposal_rate.append("role", proposalRate.role)
      : proposal_rate.append("role", proposalRateDefault.role);

    // Dispatch action
    dispatch(createProposalRateAction(proposal_rate));
  };

  // Delete rate
  const deleteRate = () => {
    // Dispatch action
    dispatch(deleteProposalRateAction(proposalRateDetails.proposal_rate.id));
  };

  // Update rate
  const updateRate = () => {
    // Prepare data
    const proposal_rate = new FormData();

    proposalRate.marginPercentage
      ? proposal_rate.append("margin_percentage", proposalRate.marginPercentage)
      : proposal_rate.append(
          "margin_percentage",
          proposalRateDefault.marginPercentage
        );

    proposalRate.role
      ? proposal_rate.append("role", proposalRate.role)
      : proposal_rate.append("role", proposalRateDefault.role);

    // Dispatch action
    dispatch(
      updateProposalRateAction(
        proposalRateDetails.proposal_rate.id,
        proposal_rate
      )
    );
  };

  // Proposal requirement
  // Add requirement
  const addRequirement = () => {
    // Prepare data
    const proposal_requirement = new FormData();

    if (
      proposalRequirement.attachment &&
      proposalRequirement.attachment !==
        proposalRequirementDetails.proposal_requirement.attachment
    ) {
      proposal_requirement.append("attachment", proposalRequirement.attachment);
    } else if (proposalRequirement.attachment === "") {
      proposal_requirement.append(
        "attachment",
        proposalRequirementDefault.attachment
      );
    }

    proposalRequirement.category
      ? proposal_requirement.append("category", proposalRequirement.category)
      : proposal_requirement.append(
          "category",
          proposalRequirementDefault.category
        );

    proposalRequirement.name
      ? proposal_requirement.append("name", proposalRequirement.name)
      : proposal_requirement.append("name", proposalRequirementDefault.name);

    proposalRequirement.owner
      ? proposal_requirement.append("owner", proposalRequirement.owner)
      : proposal_requirement.append("owner", proposalRequirementDefault.owner);

    proposal_requirement.append("proposal", id);

    proposalRequirement.ref
      ? proposal_requirement.append("ref", proposalRequirement.ref)
      : proposal_requirement.append("ref", proposalRequirementDefault.ref);

    proposalRequirement.requirement
      ? proposal_requirement.append(
          "requirement",
          proposalRequirement.requirement
        )
      : proposal_requirement.append(
          "requirement",
          proposalRequirementDefault.requirement
        );

    proposalRequirement.response
      ? proposal_requirement.append("response", proposalRequirement.response)
      : proposal_requirement.append(
          "response",
          proposalRequirementDefault.response
        );

    proposalRequirement.status
      ? proposal_requirement.append("status", proposalRequirement.status)
      : proposal_requirement.append(
          "status",
          proposalRequirementDefault.status
        );

    // Dispatch action
    dispatch(createProposalRequirementAction(proposal_requirement));
  };

  // Delete requirement
  const deleteRequirement = () => {
    // Dispatch action
    dispatch(
      deleteProposalRequirementAction(
        proposalRequirementDetails.proposal_requirement.id
      )
    );
  };

  // Download requirements
  const downloadProposalRequirements = useCallback(async () => {
    // Create workbook
    const requirementWorkbook = new ExcelJS.Workbook();

    requirementWorkbook.created = new Date();

    requirementWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = requirementWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header: "advaise.app - Instructions to upload proposal requirements",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "111111" } },
        { position: 0.5, color: { argb: "111111" } },
        { position: 1, color: { argb: "111111" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to upload proposal requirements into advaise.app.",
      ],
      [
        "2. The tab 'Requirements' contains the requirements already captured (if any). You can add to these by copying and pasting from other documents, such as the client's RFP.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Title: A short description of the requirement. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(c) Requirement: The requirement that you have written or copied and pasted from a document. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Category: A label to categorise requirements."],
      [
        "(e) Client Ref: A client-defined identifier for the requirement. For example, the requirement number from the RFP.",
      ],
      [
        "(f) Owner: The person who this requirement is assigned to. THIS FIELD IS RESTRICTED to users registered in your company's advaise.app account, which you must select using the dropdown.",
      ],
      [
        "(g) Response: Your response to the requirement. You can leave this blank and ask advaise.app to recommend the content based on previous responses after uploading the file.",
      ],
      [
        "(h) Status: A tag to indicate whether you comply with the requirement. THIS FIELD IS RESTRICTED to one of 'Compliant', 'Part-compliant', or 'Non-compliant', which you can select using the dropdown.",
      ],
      [
        "(i) Delete: A flag to delete the record. Note that you cannot delete requirements by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const requirementSheet = requirementWorkbook.addWorksheet("Requirements", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name} - Requirements`,
      },
      properties: { tabColor: { argb: "1891AC" } },
      views: views,
    });

    // Define and style data sheet columns
    requirementSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Title",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Requirement",
        key: "requirement",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Category",
        key: "category",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Client Ref",
        key: "ref",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Owner",
        key: "owner",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Response",
        key: "response",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    requirementSheet.autoFilter = "A1:I1";

    const topRow = requirementSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "111111" } },
          { position: 0.5, color: { argb: "111111" } },
          { position: 1, color: { argb: "111111" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    requirementSheet.addConditionalFormatting({
      ref: "H2:H20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Compliant"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Part-compliant"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Non-compliant"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    requirementSheet.addConditionalFormatting({
      ref: "I2:I20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    requirementSheet.pageSetup.printTitlesRow = "1:9";

    // Populate data sheet data
    for (
      var i = 0;
      i < proposalRequirementDownload.proposal_requirements.length;
      i++
    ) {
      requirementSheet.addRow({
        id: proposalRequirementDownload.proposal_requirements[i].id,
        name: proposalRequirementDownload.proposal_requirements[i].name,
        requirement:
          proposalRequirementDownload.proposal_requirements[i].requirement,
        category: proposalRequirementDownload.proposal_requirements[i].category
          ? proposalRequirementDownload.proposal_requirements[i].category
          : "",
        ref: proposalRequirementDownload.proposal_requirements[i].ref
          ? proposalRequirementDownload.proposal_requirements[i].ref
          : "",
        owner: proposalRequirementDownload.proposal_requirements[i].owner_name
          ? `${proposalRequirementDownload.proposal_requirements[i].owner_name} [${proposalRequirementDownload.proposal_requirements[i].owner}]`
          : "",
        response: proposalRequirementDownload.proposal_requirements[i].response
          ? proposalRequirementDownload.proposal_requirements[i].response
          : "",
        status: proposalRequirementDownload.proposal_requirements[i].status,
        delete: "",
      });
    }

    // Add data validations
    const ownersUnique = Array.from(
      new Set(
        managerDownload.managers.map(
          (manager) => `${manager.name} [${manager.id}]`
        )
      )
    );
    var ownersList;
    for (var ownerIndex = 0; ownerIndex < ownersUnique.length; ownerIndex++) {
      if (ownerIndex === 0) {
        ownersList = '"' + ownersUnique[ownerIndex] + ",";
      } else if (ownerIndex === ownersUnique.length - 1) {
        ownersList += ownersUnique[ownerIndex] + '"';
      } else {
        ownersList += ownersUnique[ownerIndex] + ",";
      }
    }
    var ownersFormulae = [];
    ownersFormulae.push(ownersList);

    requirementSheet.dataValidations.add("F2:F20000", {
      allowBlank: true,
      formulae: ownersFormulae,
      type: "list",
    });

    requirementSheet.dataValidations.add("H2:H20000", {
      allowBlank: false,
      formulae: ['"Compliant,Part-compliant,Non-compliant"'],
      type: "list",
    });

    // Protect data sheet
    requirementSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      requirementSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`G${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`H${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`I${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await requirementWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, `${proposalDetails.proposal.name} - Requirements.xlsx`);
      });

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Requirements downloaded.",
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the requirements file. Please refresh the page and try again.",
      });
    }
  }, [
    managerDownload.managers,
    proposalDetails.proposal.client_name,
    proposalDetails.proposal.name,
    proposalRequirementDownload.proposal_requirements,
  ]);

  useEffect(() => {
    if (managerDownload.success && proposalRequirementDownload.success) {
      downloadProposalRequirements();

      dispatch(resetDownloadManagersAction());

      dispatch(resetDownloadProposalRequirementsAction());
    }
    // eslint-disable-next-line
  }, [managerDownload.success, proposalRequirementDownload.success]);

  // Update requirement
  const updateRequirement = () => {
    // Prepare data
    const proposal_requirement = new FormData();

    if (
      proposalRequirement.attachment &&
      proposalRequirement.attachment !==
        proposalRequirementDetails.proposal_requirement.attachment
    ) {
      proposal_requirement.append("attachment", proposalRequirement.attachment);
    } else if (proposalRequirement.attachment === "") {
      proposal_requirement.append(
        "attachment",
        proposalRequirementDefault.attachment
      );
    }

    proposalRequirement.category
      ? proposal_requirement.append("category", proposalRequirement.category)
      : proposal_requirement.append(
          "category",
          proposalRequirementDefault.category
        );

    proposalRequirement.name
      ? proposal_requirement.append("name", proposalRequirement.name)
      : proposal_requirement.append("name", proposalRequirementDefault.name);

    proposalRequirement.owner
      ? proposal_requirement.append("owner", proposalRequirement.owner)
      : proposal_requirement.append("owner", proposalRequirementDefault.owner);

    proposalRequirement.ref
      ? proposal_requirement.append("ref", proposalRequirement.ref)
      : proposal_requirement.append("ref", proposalRequirementDefault.ref);

    proposalRequirement.requirement
      ? proposal_requirement.append(
          "requirement",
          proposalRequirement.requirement
        )
      : proposal_requirement.append(
          "requirement",
          proposalRequirementDefault.requirement
        );

    proposalRequirement.response
      ? proposal_requirement.append("response", proposalRequirement.response)
      : proposal_requirement.append(
          "response",
          proposalRequirementDefault.response
        );

    proposalRequirement.status
      ? proposal_requirement.append("status", proposalRequirement.status)
      : proposal_requirement.append(
          "status",
          proposalRequirementDefault.status
        );

    // Dispatch action
    dispatch(
      updateProposalRequirementAction(
        proposalRequirementDetails.proposal_requirement.id,
        proposal_requirement
      )
    );
  };

  // Upload requirements
  const uploadProposalRequirements = async () => {
    // Create workbook
    const requirementWorkbook = new ExcelJS.Workbook();

    // Read file and extract data
    var records = [];
    try {
      await requirementWorkbook.xlsx
        .load(proposalRequirementUploadFile.file)
        .then(() => {
          // Get data sheet
          const requirementSheet =
            requirementWorkbook.getWorksheet("Requirements");

          // Get data
          requirementSheet.eachRow((row, rowNumber) => {
            if (rowNumber > 1) {
              records.push({
                id: row.getCell(1).value,
                name: row.getCell(2).value
                  ? row.getCell(2).value
                  : proposalRequirementDefault.name,
                requirement: row.getCell(3).value
                  ? row.getCell(3).value
                  : proposalRequirementDefault.requirement,
                category: row.getCell(4).value
                  ? row.getCell(4).value
                  : proposalRequirementDefault.category,
                ref: row.getCell(5).value
                  ? row.getCell(5).value
                  : proposalRequirementDefault.ref,
                owner: row.getCell(6).value
                  ? row.getCell(6).value.replace(/(^.*\[|\].*$)/g, "")
                  : proposalRequirementDefault.owner,
                ownerName: row.getCell(6).value
                  ? row.getCell(6).value
                  : proposalRequirementDefault.ownerName,
                response: row.getCell(7).value
                  ? row.getCell(7).value
                  : proposalRequirementDefault.response,
                status: row.getCell(8).value
                  ? row.getCell(8).value
                  : proposalRequirementDefault.status,

                delete: row.getCell(9).value,
              });
            }
          });

          // Upload data
          dispatch(downloadManagersAction(managerList.count));

          dispatch(uploadProposalRequirementsAction(id, records));
        });
    } catch (error) {
      setProposalRequirementUploadFile({
        ...proposalRequirementUploadFile,
        error: true,
      });
    }
  };

  // Download proposal requirements that failed to upload
  const downloadProposalRequirementsFailedUpload = useCallback(async () => {
    // Create workbook
    const requirementWorkbook = new ExcelJS.Workbook();

    requirementWorkbook.created = new Date();

    requirementWorkbook.creator = "advaise.app";

    // Configure sheet view options
    const views = [
      {
        showRowColHeaders: false,
        state: "frozen",
        ySplit: 1,
        zoomScale: 100,
      },
    ];

    // Configure column style options
    const columnStyle = {
      alignment: {
        horizontal: "left",
        indent: 1,
        vertical: "top",
        wrapText: true,
      },
    };

    // Create instructions sheet
    const instructionSheet = requirementWorkbook.addWorksheet("Instructions", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: "Instructions",
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style instruction sheet columns
    instructionSheet.columns = [
      {
        header:
          "advaise.app - Instructions to address proposal requirement exceptions",
        key: "instructions",
        style: columnStyle,
        width: 100,
      },
    ];

    instructionSheet.getCell("A1").font = {
      bold: true,
      color: { argb: "FFFFFF" },
      size: 12,
    };

    instructionSheet.getCell("A1").fill = {
      degree: 0,
      gradient: "angle",
      stops: [
        { position: 0, color: { argb: "AC2F33" } },
        { position: 0.5, color: { argb: "CD4246" } },
        { position: 1, color: { argb: "E76A6E" } },
      ],
      type: "gradient",
    };

    // Populate instruction sheet data
    const instructions = [
      [
        "1. You can use this spreadsheet to fix proposal requirements that had exceptions and upload them into advaise.app.",
      ],
      [
        "2. The tab 'Requirements' contains the requirements that had exceptions. The most common issue is missing data for a field that is required. Update the requirements based on the field definitions below and upload this file to address the exceptions.",
      ],
      ["3. Field definitions:"],
      [
        "(a) System ID: This is a system-defined identifier, which advaise.app uses to match the data in the spreadsheet to the relevant database record. You cannot edit this column.",
      ],
      [
        "(b) Title: A short description of the requirement. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      [
        "(c) Requirement: The requirement that you have written or copied and pasted from a document. THIS FIELD IS REQUIRED for the upload to succeed.",
      ],
      ["(d) Category: A label to categorise requirements."],
      [
        "(e) Client Ref: A client-defined identifier for the requirement. For example, the requirement number from the RFP.",
      ],
      [
        "(f) Owner: The person who this requirement is assigned to. THIS FIELD IS RESTRICTED to users registered in your company's advaise.app account, which you must select using the dropdown.",
      ],
      [
        "(g) Response: Your response to the requirement. You can leave this blank and ask advaise.app to recommend the content based on previous responses after uploading the file.",
      ],
      [
        "(h) Status: A tag to indicate whether you comply with the requirement. THIS FIELD IS RESTRICTED to one of 'Compliant', 'Part-compliant', or 'Non-compliant', which you can select using the dropdown.",
      ],
      [
        "(i) Delete: A flag to delete the record. Note that you cannot delete requirements by deleting them from the spreadsheet - you have to leave them in and set the Delete column value to 'yes'.",
      ],
    ];

    instructionSheet.addRows(instructions);

    // Protect instruction sheet
    instructionSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    // Create data sheet
    const requirementSheet = requirementWorkbook.addWorksheet("Requirements", {
      headerFooter: {
        oddFooter: "Page &P of &N",
        oddHeader: `${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name} - Requirement Exceptions`,
      },
      properties: { tabColor: { argb: "CD4246" } },
      views: views,
    });

    // Define and style data sheet columns
    requirementSheet.columns = [
      {
        header: "System Id",
        key: "id",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Title",
        key: "name",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Requirement",
        key: "requirement",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Category",
        key: "category",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Client Ref",
        key: "ref",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Owner",
        key: "owner",
        style: columnStyle,
        width: 30,
      },
      {
        header: "Response",
        key: "response",
        style: columnStyle,
        width: 80,
      },
      {
        header: "Status",
        key: "status",
        style: columnStyle,
        width: 20,
      },
      {
        header: "Delete",
        key: "delete",
        style: columnStyle,
        width: 20,
      },
    ];

    requirementSheet.autoFilter = "A1:H1";

    const topRow = requirementSheet.getRow(1);
    topRow.eachCell((cell, colNumber) => {
      cell.fill = {
        degree: 0,
        gradient: "angle",
        stops: [
          { position: 0, color: { argb: "AC2F33" } },
          { position: 0.5, color: { argb: "CD4246" } },
          { position: 1, color: { argb: "E76A6E" } },
        ],
        type: "gradient",
      };

      cell.font = {
        bold: true,
        color: { argb: "FFFFFF" },
        size: 12,
      };
    });

    requirementSheet.addConditionalFormatting({
      ref: "H2:H20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"Compliant"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "72CA9B" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Part-compliant"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FBB360" },
            },
          },
          type: "cellIs",
        },
        {
          operator: "equal",
          formulae: ['"Non-compliant"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    requirementSheet.addConditionalFormatting({
      ref: "I2:I20000",
      rules: [
        {
          operator: "equal",
          formulae: ['"yes"'],
          style: {
            fill: {
              type: "pattern",
              pattern: "solid",
              bgColor: { argb: "FA999C" },
            },
          },
          type: "cellIs",
        },
      ],
    });

    requirementSheet.pageSetup.printTitlesRow = "1:9";

    // Populate data sheet data
    for (var i = 0; i < proposalRequirementUpload.records_failed.length; i++) {
      requirementSheet.addRow({
        id: proposalRequirementUpload.records_failed[i].id,
        name: proposalRequirementUpload.records_failed[i].name,
        requirement: proposalRequirementUpload.records_failed[i].requirement,
        category: proposalRequirementUpload.records_failed[i].category
          ? proposalRequirementUpload.records_failed[i].category
          : "",
        ref: proposalRequirementUpload.records_failed[i].ref
          ? proposalRequirementUpload.records_failed[i].ref
          : "",
        owner: proposalRequirementUpload.records_failed[i].ownerName
          ? proposalRequirementUpload.records_failed[i].ownerName
          : "",
        response: proposalRequirementUpload.records_failed[i].response
          ? proposalRequirementUpload.records_failed[i].response
          : "",
        status: proposalRequirementUpload.records_failed[i].status,
        delete: "",
      });
    }

    // Add data validations
    const ownersUnique = Array.from(
      new Set(
        managerDownload.managers.map(
          (manager) => `${manager.name} [${manager.id}]`
        )
      )
    );
    var ownersList;
    for (var ownerIndex = 0; ownerIndex < ownersUnique.length; ownerIndex++) {
      if (ownerIndex === 0) {
        ownersList = '"' + ownersUnique[ownerIndex] + ",";
      } else if (ownerIndex === ownersUnique.length - 1) {
        ownersList += ownersUnique[ownerIndex] + '"';
      } else {
        ownersList += ownersUnique[ownerIndex] + ",";
      }
    }
    var ownersFormulae = [];
    ownersFormulae.push(ownersList);

    requirementSheet.dataValidations.add("F2:F20000", {
      allowBlank: true,
      formulae: ownersFormulae,
      type: "list",
    });

    requirementSheet.dataValidations.add("H2:H20000", {
      allowBlank: false,
      formulae: ['"Compliant,Part-compliant,Non-compliant"'],
      type: "list",
    });

    // Protect data sheet
    requirementSheet.protect("e7729d06-e138-4101-9dee-955fe452bc56", {
      autoFilter: true,
      sort: true,
    });

    for (var k = 0; k < 20000; k++) {
      requirementSheet.getCell(`B${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`C${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`D${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`E${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`F${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`G${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`H${k + 2}`).protection = {
        locked: false,
      };

      requirementSheet.getCell(`I${k + 2}`).protection = {
        locked: false,
      };
    }

    // Save file
    try {
      await requirementWorkbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(
          blob,
          `${proposalDetails.proposal.name} - Requirement Exceptions.xlsx`
        );
      });
    } catch (error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not download the requirement exceptions file. Please refresh the page and try again.",
      });
    }
  }, [
    managerDownload.managers,
    proposalDetails.proposal.client_name,
    proposalDetails.proposal.name,
    proposalRequirementUpload.records_failed,
  ]);

  useEffect(() => {
    if (managerDownload.success && proposalRequirementUpload.end) {
      proposalRequirementUpload.records_failed.length > 0 &&
        downloadProposalRequirementsFailedUpload();
    }
    // eslint-disable-next-line
  }, [managerDownload.success, proposalRequirementUpload.end]);

  // Proposal risk
  // Add risk
  const addRisk = () => {
    // Prepare data
    const proposal_risk = new FormData();

    proposalRisk.category
      ? proposal_risk.append("category", proposalRisk.category)
      : proposal_risk.append("category", proposalRiskDefault.category);

    proposalRisk.impact
      ? proposal_risk.append("impact", proposalRisk.impact)
      : proposal_risk.append("impact", proposalRiskDefault.impact);

    proposalRisk.likelihood
      ? proposal_risk.append("likelihood", proposalRisk.likelihood)
      : proposal_risk.append("likelihood", proposalRiskDefault.likelihood);

    proposalRisk.name
      ? proposal_risk.append("name", proposalRisk.name)
      : proposal_risk.append("name", proposalRiskDefault.name);

    proposalRisk.owner
      ? proposal_risk.append("owner", proposalRisk.owner)
      : proposal_risk.append("owner", proposalRiskDefault.owner);

    proposal_risk.append("proposal", id);

    proposalRisk.risk
      ? proposal_risk.append("risk", proposalRisk.risk)
      : proposal_risk.append("risk", proposalRiskDefault.risk);

    proposalRisk.status
      ? proposal_risk.append("status", proposalRisk.status)
      : proposal_risk.append("status", proposalRiskDefault.status);

    proposalRisk.strategy
      ? proposal_risk.append("strategy", proposalRisk.strategy)
      : proposal_risk.append("strategy", proposalRiskDefault.strategy);

    // Dispatch action
    dispatch(createProposalRiskAction(proposal_risk));
  };

  // Delete risk
  const deleteRisk = () => {
    // Dispatch action
    dispatch(deleteProposalRiskAction(proposalRiskDetails.proposal_risk.id));
  };

  // Update risk
  const updateRisk = () => {
    // Prepare data
    const proposal_risk = new FormData();

    proposalRisk.category
      ? proposal_risk.append("category", proposalRisk.category)
      : proposal_risk.append("category", proposalRiskDefault.category);

    proposalRisk.impact
      ? proposal_risk.append("impact", proposalRisk.impact)
      : proposal_risk.append("impact", proposalRiskDefault.impact);

    proposalRisk.likelihood
      ? proposal_risk.append("likelihood", proposalRisk.likelihood)
      : proposal_risk.append("likelihood", proposalRiskDefault.likelihood);

    proposalRisk.name
      ? proposal_risk.append("name", proposalRisk.name)
      : proposal_risk.append("name", proposalRiskDefault.name);

    proposalRisk.owner
      ? proposal_risk.append("owner", proposalRisk.owner)
      : proposal_risk.append("owner", proposalRiskDefault.owner);

    proposalRisk.risk
      ? proposal_risk.append("risk", proposalRisk.risk)
      : proposal_risk.append("risk", proposalRiskDefault.risk);

    proposalRisk.status
      ? proposal_risk.append("status", proposalRisk.status)
      : proposal_risk.append("status", proposalRiskDefault.status);

    proposalRisk.strategy
      ? proposal_risk.append("strategy", proposalRisk.strategy)
      : proposal_risk.append("strategy", proposalRiskDefault.strategy);

    // Dispatch action
    dispatch(
      updateProposalRiskAction(
        proposalRiskDetails.proposal_risk.id,
        proposal_risk
      )
    );
  };

  // Proposal service
  // Add service
  const addService = () => {
    // Prepare data
    const proposal_service = new FormData();

    proposalService.benefits
      ? proposal_service.append("benefits", proposalService.benefits)
      : proposal_service.append("benefits", proposalServiceDefault.benefits);

    proposalService.description
      ? proposal_service.append("description", proposalService.description)
      : proposal_service.append(
          "description",
          proposalServiceDefault.description
        );

    proposalService.name
      ? proposal_service.append("name", proposalService.name)
      : proposal_service.append("name", proposalServiceDefault.name);

    proposal_service.append("proposal", id);

    proposalService.service
      ? proposal_service.append("service", proposalService.service)
      : proposal_service.append("service", proposalServiceDefault.service);

    // Dispatch action
    dispatch(createProposalServiceAction(proposal_service));
  };

  // Delete service
  const deleteService = () => {
    // Dispatch action
    dispatch(
      deleteProposalServiceAction(proposalServiceDetails.proposal_service.id)
    );
  };

  // Update service
  const updateService = () => {
    // Prepare data
    const proposal_service = new FormData();

    proposalService.benefits
      ? proposal_service.append("benefits", proposalService.benefits)
      : proposal_service.append("benefits", proposalServiceDefault.benefits);

    proposalService.description
      ? proposal_service.append("description", proposalService.description)
      : proposal_service.append(
          "description",
          proposalServiceDefault.description
        );

    proposalService.name
      ? proposal_service.append("name", proposalService.name)
      : proposal_service.append("name", proposalServiceDefault.name);

    // Dispatch action
    dispatch(
      updateProposalServiceAction(
        proposalServiceDetails.proposal_service.id,
        proposal_service
      )
    );
  };

  // Proposal service activity
  // Add activity
  const addActivity = () => {
    // Prepare data
    const proposal_service_activity = new FormData();

    proposalServiceActivity.description
      ? proposal_service_activity.append(
          "description",
          proposalServiceActivity.description
        )
      : proposal_service_activity.append(
          "description",
          proposalServiceActivityDefault.description
        );

    proposalServiceActivity.end
      ? proposal_service_activity.append(
          "end",
          getDjangoDate(proposalServiceActivity.end)
        )
      : proposal_service_activity.append(
          "end",
          getDjangoDate(proposalServiceActivityDefault.end)
        );

    proposalServiceActivity.name
      ? proposal_service_activity.append("name", proposalServiceActivity.name)
      : proposal_service_activity.append(
          "name",
          proposalServiceActivityDefault.name
        );

    proposalServiceActivity.service
      ? proposal_service_activity.append(
          "service",
          proposalServiceActivity.service
        )
      : proposal_service_activity.append(
          "service",
          proposalServiceActivityDefault.service
        );

    proposalServiceActivity.start
      ? proposal_service_activity.append(
          "start",
          getDjangoDate(proposalServiceActivity.start)
        )
      : proposal_service_activity.append(
          "start",
          getDjangoDate(proposalServiceActivityDefault.start)
        );

    // Dispatch action
    dispatch(createProposalServiceActivityAction(proposal_service_activity));
  };

  // Delete activity
  const deleteActivity = () => {
    // Dispatch action
    dispatch(
      deleteProposalServiceActivityAction(
        proposalServiceActivityDetails.proposal_service_activity.id
      )
    );
  };

  // Update activity
  const updateActivity = () => {
    // Prepare data
    const proposal_service_activity = new FormData();

    proposalServiceActivity.description
      ? proposal_service_activity.append(
          "description",
          proposalServiceActivity.description
        )
      : proposal_service_activity.append(
          "description",
          proposalServiceActivityDefault.description
        );

    proposalServiceActivity.end
      ? proposal_service_activity.append(
          "end",
          getDjangoDate(proposalServiceActivity.end)
        )
      : proposal_service_activity.append(
          "end",
          getDjangoDate(proposalServiceActivityDefault.end)
        );

    proposalServiceActivity.name
      ? proposal_service_activity.append("name", proposalServiceActivity.name)
      : proposal_service_activity.append(
          "name",
          proposalServiceActivityDefault.name
        );

    proposalServiceActivity.service
      ? proposal_service_activity.append(
          "service",
          proposalServiceActivity.service
        )
      : proposal_service_activity.append(
          "service",
          proposalServiceActivityDefault.service
        );

    proposalServiceActivity.start
      ? proposal_service_activity.append(
          "start",
          getDjangoDate(proposalServiceActivity.start)
        )
      : proposal_service_activity.append(
          "start",
          getDjangoDate(proposalServiceActivityDefault.start)
        );

    // Dispatch action
    dispatch(
      updateProposalServiceActivityAction(
        proposalServiceActivityDetails.proposal_service_activity.id,
        proposal_service_activity
      )
    );
  };

  // Proposal service deliverable
  // Add deliverable
  const addDeliverable = () => {
    // Prepare data
    const proposal_service_deliverable = new FormData();

    proposalServiceDeliverable.description
      ? proposal_service_deliverable.append(
          "description",
          proposalServiceDeliverable.description
        )
      : proposal_service_deliverable.append(
          "description",
          proposalServiceDeliverableDefault.description
        );

    proposalServiceDeliverable.due
      ? proposal_service_deliverable.append(
          "due",
          getDjangoDate(proposalServiceDeliverable.due)
        )
      : proposal_service_deliverable.append(
          "due",
          getDjangoDate(proposalServiceDeliverableDefault.due)
        );

    proposalServiceDeliverable.milestone
      ? proposal_service_deliverable.append(
          "milestone",
          proposalServiceDeliverable.milestone
        )
      : proposal_service_deliverable.append(
          "milestone",
          proposalServiceDeliverableDefault.milestone
        );

    proposalServiceDeliverable.name
      ? proposal_service_deliverable.append(
          "name",
          proposalServiceDeliverable.name
        )
      : proposal_service_deliverable.append(
          "name",
          proposalServiceDeliverableDefault.name
        );

    proposalServiceDeliverable.service
      ? proposal_service_deliverable.append(
          "service",
          proposalServiceDeliverable.service
        )
      : proposal_service_deliverable.append(
          "service",
          proposalServiceDeliverableDefault.service
        );

    if (
      proposalServiceDeliverable.template &&
      proposalServiceDeliverable.template !==
        proposalServiceDeliverableDetails.proposal_service_deliverable.template
    ) {
      proposal_service_deliverable.append(
        "template",
        proposalServiceDeliverable.template
      );
    } else if (proposalServiceDeliverable.template === "") {
      proposal_service_deliverable.append(
        "template",
        proposalServiceDeliverableDefault.template
      );
    }

    proposalServiceDeliverable.type
      ? proposal_service_deliverable.append(
          "type",
          proposalServiceDeliverable.type
        )
      : proposal_service_deliverable.append(
          "type",
          proposalServiceDeliverableDefault.type
        );

    // Dispatch action
    dispatch(
      createProposalServiceDeliverableAction(proposal_service_deliverable)
    );
  };

  // Delete deliverable
  const deleteDeliverable = () => {
    // Dispatch action
    dispatch(
      deleteProposalServiceDeliverableAction(
        proposalServiceDeliverableDetails.proposal_service_deliverable.id
      )
    );
  };

  // Update deliverable
  const updateDeliverable = () => {
    // Prepare data
    const proposal_service_deliverable = new FormData();

    proposalServiceDeliverable.description
      ? proposal_service_deliverable.append(
          "description",
          proposalServiceDeliverable.description
        )
      : proposal_service_deliverable.append(
          "description",
          proposalServiceDeliverableDefault.description
        );

    proposalServiceDeliverable.due
      ? proposal_service_deliverable.append(
          "due",
          getDjangoDate(proposalServiceDeliverable.due)
        )
      : proposal_service_deliverable.append(
          "due",
          getDjangoDate(proposalServiceDeliverableDefault.due)
        );

    proposalServiceDeliverable.milestone
      ? proposal_service_deliverable.append(
          "milestone",
          proposalServiceDeliverable.milestone
        )
      : proposal_service_deliverable.append(
          "milestone",
          proposalServiceDeliverableDefault.milestone
        );

    proposalServiceDeliverable.name
      ? proposal_service_deliverable.append(
          "name",
          proposalServiceDeliverable.name
        )
      : proposal_service_deliverable.append(
          "name",
          proposalServiceDeliverableDefault.name
        );

    proposalServiceDeliverable.service
      ? proposal_service_deliverable.append(
          "service",
          proposalServiceDeliverable.service
        )
      : proposal_service_deliverable.append(
          "service",
          proposalServiceDeliverableDefault.service
        );

    if (
      proposalServiceDeliverable.template &&
      proposalServiceDeliverable.template !==
        proposalServiceDeliverableDetails.proposal_service_deliverable.template
    ) {
      proposal_service_deliverable.append(
        "template",
        proposalServiceDeliverable.template
      );
    } else if (proposalServiceDeliverable.template === "") {
      proposal_service_deliverable.append(
        "template",
        proposalServiceDeliverableDefault.template
      );
    }

    proposalServiceDeliverable.type
      ? proposal_service_deliverable.append(
          "type",
          proposalServiceDeliverable.type
        )
      : proposal_service_deliverable.append(
          "type",
          proposalServiceDeliverableDefault.type
        );

    // Dispatch action
    dispatch(
      updateProposalServiceDeliverableAction(
        proposalServiceDeliverableDetails.proposal_service_deliverable.id,
        proposal_service_deliverable
      )
    );
  };

  // Proposal staff
  // Add proposal staff
  const addStaff = () => {
    // Prepare data
    const proposal_staff = new FormData();

    proposalStaff.experience
      ? proposal_staff.append("experience", proposalStaff.experience)
      : proposal_staff.append("experience", proposalStaffDefault.experience);

    proposalStaff.expertise_1
      ? proposal_staff.append("expertise_1", proposalStaff.expertise_1)
      : proposal_staff.append("expertise_1", proposalStaffDefault.expertise_1);

    proposalStaff.expertise_2
      ? proposal_staff.append("expertise_2", proposalStaff.expertise_2)
      : proposal_staff.append("expertise_2", proposalStaffDefault.expertise_2);

    proposalStaff.expertise_3
      ? proposal_staff.append("expertise_3", proposalStaff.expertise_3)
      : proposal_staff.append("expertise_3", proposalStaffDefault.expertise_3);

    proposal_staff.append("proposal", id);

    proposalStaff.responsibilities
      ? proposal_staff.append(
          "responsibilities",
          proposalStaff.responsibilities
        )
      : proposal_staff.append(
          "responsibilities",
          proposalStaffDefault.responsibilities
        );

    proposalStaff.role
      ? proposal_staff.append("role", proposalStaff.role)
      : proposal_staff.append("role", proposalStaffDefault.role);

    proposalStaff.staff
      ? proposal_staff.append("staff", proposalStaff.staff)
      : proposal_staff.append("staff", proposalStaffDefault.staff);

    // Dispatch action
    dispatch(createProposalStaffAction(proposal_staff));
  };

  // Delete proposal staff
  const deleteStaff = () => {
    // Dispatch action
    dispatch(deleteProposalStaffAction(proposalStaffDetails.proposal_staff.id));
  };

  // Update proposal staff
  const updateStaff = () => {
    // Prepare data
    const proposal_staff = new FormData();

    proposalStaff.experience
      ? proposal_staff.append("experience", proposalStaff.experience)
      : proposal_staff.append("experience", proposalStaffDefault.experience);

    proposalStaff.expertise_1
      ? proposal_staff.append("expertise_1", proposalStaff.expertise_1)
      : proposal_staff.append("expertise_1", proposalStaffDefault.expertise_1);

    proposalStaff.expertise_2
      ? proposal_staff.append("expertise_2", proposalStaff.expertise_2)
      : proposal_staff.append("expertise_2", proposalStaffDefault.expertise_2);

    proposalStaff.expertise_3
      ? proposal_staff.append("expertise_3", proposalStaff.expertise_3)
      : proposal_staff.append("expertise_3", proposalStaffDefault.expertise_3);

    proposalStaff.responsibilities
      ? proposal_staff.append(
          "responsibilities",
          proposalStaff.responsibilities
        )
      : proposal_staff.append(
          "responsibilities",
          proposalStaffDefault.responsibilities
        );

    proposalStaff.role
      ? proposal_staff.append("role", proposalStaff.role)
      : proposal_staff.append("role", proposalStaffDefault.role);

    proposalStaff.staff
      ? proposal_staff.append("staff", proposalStaff.staff)
      : proposal_staff.append("staff", proposalStaffDefault.staff);

    // Dispatch action
    dispatch(
      updateProposalStaffAction(
        proposalStaffDetails.proposal_staff.id,
        proposal_staff
      )
    );
  };

  // Proposal upload
  // Add upload
  const addUpload = () => {
    // Prepare data
    const proposal_upload = new FormData();

    proposalUpload.description
      ? proposal_upload.append("description", proposalUpload.description)
      : proposal_upload.append(
          "description",
          proposalUploadDefault.description
        );

    if (
      proposalUpload.file &&
      proposalUpload.file !== proposalUploadDetails.proposal_upload.file
    ) {
      proposal_upload.append("file", proposalUpload.file);
    } else if (proposalUpload.file === "") {
      proposal_upload.append("file", proposalDefault.file);
    }

    proposalUpload.name
      ? proposal_upload.append("name", proposalUpload.name)
      : proposal_upload.append("name", proposalUploadDefault.name);

    proposal_upload.append("proposal", id);

    // Dispatch action
    dispatch(createProposalUploadAction(proposal_upload));
  };

  // Delete upload
  const deleteUpload = () => {
    // Dispatch action
    dispatch(
      deleteProposalUploadAction(proposalUploadDetails.proposal_upload.id)
    );
  };

  // Update upload
  const updateUpload = () => {
    // Prepare data
    const proposal_upload = new FormData();

    proposalUpload.description
      ? proposal_upload.append("description", proposalUpload.description)
      : proposal_upload.append(
          "description",
          proposalUploadDefault.description
        );

    if (
      proposalUpload.file &&
      proposalUpload.file !== proposalUploadDetails.proposal_upload.file
    ) {
      proposal_upload.append("file", proposalUpload.file);
    } else if (proposalUpload.file === "") {
      proposal_upload.append("file", proposalDefault.file);
    }

    proposalUpload.name
      ? proposal_upload.append("name", proposalUpload.name)
      : proposal_upload.append("name", proposalUploadDefault.name);

    // Dispatch action
    dispatch(
      updateProposalUploadAction(
        proposalUploadDetails.proposal_upload.id,
        proposal_upload
      )
    );
  };

  // Refresh probability of win
  const [probabilityWin, setProbabilityWin] = useState(null);

  useEffect(() => {
    if (probabilityWin === null && proposalDetails.success) {
      if (proposalDetails.proposal.probability_win !== null) {
        setProbabilityWin(proposalDetails.proposal.probability_win);
      } else {
        setProbabilityWin(0);
      }
    }
    // eslint-disable-next-line
  }, [proposalDetails.success]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.probability_win !== undefined) {
        setProbabilityWin(lastJsonMessage.message.probability_win);
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (proposalPredictWin.success) {
      AppToaster.show({
        icon: "updated",
        intent: "primary",
        message:
          "Predicting the probability of win. This will take a few seconds.",
      });

      dispatch(resetPredictWinProposalAction());
    } else if (proposalPredictWin.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not predict the probability of win. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [proposalPredictWin.loading]);

  // Render probability of win message
  const [probabilityWinMessage, setProbabilityWinMessage] = useState(null);

  useEffect(() => {
    setProbabilityWinMessage(null);

    setTimeout(() =>
      setProbabilityWinMessage(
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .changeDelay(20)
              .typeString(
                `Win probability of ${Math.round(probabilityWin * 100)} percent`
              )
              .start();
          }}
          options={{
            cursor: "",
          }}
        />
      )
    );
  }, [probabilityWin]);

  // Download editable proposal document
  const [editableProposalDocumentLoading, setEditableProposalDocumentLoading] =
    useState(false);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.proposal_file !== undefined) {
        fetch(lastJsonMessage.message.proposal_file)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(
              blob,
              `${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name}.docx`
            );
          });

        setEditableProposalDocumentLoading(false);

        dispatch(resetDownloadProposalAction());

        setDownloadEditableProposalDocumentIsOpen(false);
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (proposalDownload.error) {
      AppToaster.show({
        icon: "issue",
        intent: "danger",
        message: "We couldn't download the file. Please try again.",
      });
    } else if (proposalDownload.success) {
      AppToaster.show({
        icon: "info-sign",
        intent: "primary",
        message: "File downloading.",
      });
    }
  }, [proposalDownload.error, proposalDownload.success]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the proposal</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal name */}
                  <FormDrawerTextField2
                    error={proposalUpdate.error}
                    fieldName="name"
                    icon="manual"
                    label="Proposal name"
                    labelInfo="(required)"
                    placeholder="Give the proposal a title"
                    setValueObject={setUpdatedProposal}
                    setValueVariable="name"
                    value={updatedProposal.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Client */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={proposalUpdate.error}
                    fieldName="client"
                    helperText="You can create or select an existing client"
                    icon="office"
                    info={
                      <ClientInfo
                        clientId={updatedProposal.client}
                        query={clientQueryDefault}
                      />
                    }
                    items={clientList.clients}
                    label="Client"
                    labelInfo="(required)"
                    loading={clientList.loading}
                    placeholder="Create or select a client"
                    query={clientQuery.searchString}
                    setQueryObject={setClientQuery}
                    setQueryVariable="searchString"
                    setValueObject={setUpdatedProposal}
                    setValueIdVariable="client"
                    setValueNameVariable="clientName"
                    showInfo={updatedProposal.client}
                    value={updatedProposal.clientName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal value */}
                  <FormDrawerNumericField2
                    error={proposalUpdate.error}
                    fieldName="value"
                    label="Proposal value"
                    labelInfo="(required)"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {activeOrganisation.currency}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the estimated contract value"
                    setValueObject={setUpdatedProposal}
                    setValueVariable="value"
                    stepSize={10000}
                    value={updatedProposal.value}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={proposalUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the due date"
                    setValueObject={setUpdatedProposal}
                    setValueVariable="due"
                    value={updatedProposal.due}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Contact */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={proposalUpdate.error}
                    fieldName="contact"
                    helperText="You can create or select an existing contact"
                    icon="id-number"
                    info={
                      <ContactInfo
                        contactId={updatedProposal.contact}
                        query={contactQueryDefault}
                      />
                    }
                    items={contactList.contacts}
                    label="Contact"
                    loading={contactList.loading}
                    noOptionOnClick
                    placeholder="Create or select a contact"
                    query={contactQuery.searchString}
                    setQueryObject={setContactQuery}
                    setQueryVariable="searchString"
                    setValueObject={setUpdatedProposal}
                    setValueIdVariable="contact"
                    setValueNameVariable="contactName"
                    showInfo={updatedProposal.contact}
                    value={updatedProposal.contactName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Request for proposal */}
                  <FormDrawerFileField2
                    error={proposalUpdate.error}
                    fileName="rfp"
                    label="Request for proposal"
                    placeholder="Upload the RFP document"
                    setValueObject={setUpdatedProposal}
                    setValueFileVariable="rfp"
                    setValueNameVariable="rfpName"
                    valueFile={updatedProposal.rfp}
                    valueName={updatedProposal.rfpName}
                  />
                </div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the proposal"
                setValueObject={setUpdatedProposal}
                setValueVariable="notes"
                value={updatedProposal.notes}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Proposal status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalUpdate.error}
                    fieldName="status"
                    intent={
                      updatedProposal.status === "Cancelled"
                        ? "warning"
                        : updatedProposal.status === "Lost"
                        ? "danger"
                        : updatedProposal.status === "Won"
                        ? "success"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "search-text", value: "Qualifying" },
                      {
                        id: 2,
                        icon: "manually-entered-data",
                        value: "In-progress",
                      },
                      { id: 3, icon: "envelope", value: "Submitted" },
                      { id: 4, icon: "tick-circle", value: "Won" },
                      { id: 5, icon: "error", value: "Lost" },
                      { id: 6, icon: "undo", value: "Cancelled" },
                    ]}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="status"
                    value={updatedProposal.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Submitted date */}
                  <FormDrawerDateField2
                    error={proposalUpdate.error}
                    fieldName="submitted"
                    icon="calendar"
                    label="Submitted date"
                    placeholder="Enter the submission date"
                    setValueObject={setUpdatedProposal}
                    setValueVariable="submitted"
                    value={updatedProposal.submitted}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "35%" }}>
                  {/* Closed date */}
                  <FormDrawerDateField2
                    error={proposalUpdate.error}
                    fieldName="closed"
                    icon="calendar"
                    label="Closed date"
                    placeholder="Enter the closing date"
                    setValueObject={setUpdatedProposal}
                    setValueVariable="closed"
                    value={updatedProposal.closed}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      proposalDetails.proposal.user_access_status === "edit" ||
                      proposalDetails.proposal.user_access_status === "view"
                    }
                    error={proposalUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted proposal can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="restricted"
                    value={updatedProposal.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      proposalDetails.proposal.user_access_status === "edit" ||
                      proposalDetails.proposal.user_access_status === "view"
                    }
                    error={proposalUpdate.error}
                    fieldName="manager"
                    helperText="Person driving the proposal - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setUpdatedProposal}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={updatedProposal.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the proposal manager and the
                    proposal is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Updating a proposal"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={proposalPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setProposalPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={proposalPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={proposalPermissionCreate.error}
              fieldName="level"
              intent={
                proposalPermission.level === "Edit"
                  ? "success"
                  : proposalPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setProposalPermission}
              setValueVariable="level"
              value={proposalPermission.level}
            />
          </Card>
        }
        error={proposalPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listProposalPermissionsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={proposalPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={proposalPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setProposalPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={proposalPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={proposalPermissionUpdate.error}
              fieldName="level"
              intent={
                proposalPermission.level === "Edit"
                  ? "success"
                  : proposalPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setProposalPermission}
              setValueVariable="level"
              value={proposalPermission.level}
            />
          </Card>
        }
        deleteSuccess={proposalPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={proposalPermissionDelete.loading}
        error={proposalPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={proposalPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(
            listProposalPermissionsAction({ ...proposalPermissionQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalPermissionAction());

          dispatch(resetUpdateProposalPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={proposalPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New action form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the action</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={proposalActionCreate.error}
                  fieldName="name"
                  icon="take-action"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the action a title"
                  setValueObject={setProposalAction}
                  setValueVariable="name"
                  value={proposalAction.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={proposalActionCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "search-template",
                      value: "Qualification",
                    },
                    { id: 2, icon: "locate", value: "Strategy" },
                    { id: 3, icon: "gantt-chart", value: "Solution" },
                    { id: 4, icon: "dollar", value: "Commercials" },
                    {
                      id: 5,
                      icon: "manually-entered-data",
                      value: "Response",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setProposalAction}
                  setValueVariable="category"
                  value={proposalAction.category}
                />
              </div>
            </div>

            {/* Action */}
            <FormDrawerTextAreaField3
              error={proposalActionCreate.error}
              fieldName="action"
              height="100px"
              label="Action"
              labelInfo="(required)"
              placeholder="Enter the action"
              setValueObject={setProposalAction}
              setValueVariable="action"
              value={proposalAction.action}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={proposalActionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for completing the action"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalAction}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={proposalAction.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={proposalActionCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the action due date"
                  setValueObject={setProposalAction}
                  setValueVariable="due"
                  value={proposalAction.due}
                />
              </div>
            </div>
          </Card>
        }
        error={proposalActionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create action"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={addActionDrawerIsOpen}
        onSave={addAction}
        refreshList={() => {
          dispatch(listProposalActionsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalActionCreate.success}
        saveSuccessMessage="Action created."
        saving={proposalActionCreate.loading}
        setIsOpen={setAddActionDrawerIsOpen}
        title="Create action"
      />

      {/* Existing action form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the action</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={proposalActionUpdate.error}
                    fieldName="name"
                    icon="take-action"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the action a title"
                    setValueObject={setProposalAction}
                    setValueVariable="name"
                    value={proposalAction.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={proposalActionUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      {
                        id: 1,
                        icon: "search-template",
                        value: "Qualification",
                      },
                      { id: 2, icon: "locate", value: "Strategy" },
                      { id: 3, icon: "gantt-chart", value: "Solution" },
                      { id: 4, icon: "dollar", value: "Commercials" },
                      {
                        id: 5,
                        icon: "manually-entered-data",
                        value: "Response",
                      },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setProposalAction}
                    setValueVariable="category"
                    value={proposalAction.category}
                  />
                </div>
              </div>

              {/* Action */}
              <FormDrawerTextAreaField3
                error={proposalActionUpdate.error}
                fieldName="action"
                height="100px"
                label="Action"
                labelInfo="(required)"
                placeholder="Enter the action"
                setValueObject={setProposalAction}
                setValueVariable="action"
                value={proposalAction.action}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={proposalActionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalAction}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={proposalAction.ownerName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                >
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={proposalActionUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the action due date"
                    setValueObject={setProposalAction}
                    setValueVariable="due"
                    value={proposalAction.due}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  tag={
                    proposalActionDetails.proposal_action.owner_done
                      ? "Ready to close"
                      : "Working on it"
                  }
                  tagIntent={
                    proposalActionDetails.proposal_action.owner_done
                      ? "success"
                      : "primary"
                  }
                  text={proposalActionDetails.proposal_action.owner_comments}
                  title="Update from action owner"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalActionUpdate.error}
                    fieldName="status"
                    intent={
                      proposalAction.status === "Completed"
                        ? "success"
                        : proposalAction.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Completed",
                      },
                      {
                        id: 2,
                        icon: "refresh",
                        value: "In-progress",
                      },
                      {
                        id: 3,
                        icon: "remove",
                        value: "On-hold",
                      },
                    ]}
                    setValueObject={setProposalAction}
                    setValueVariable="status"
                    value={proposalAction.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                ></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={proposalActionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setProposalAction}
                setValueVariable="comments"
                value={proposalAction.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={proposalActionDelete.success}
        deleteSuccessMessage="Action deleted."
        deleting={proposalActionDelete.loading}
        error={proposalActionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update action"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={updateActionDrawerIsOpen}
        loading={proposalActionDetails.loading}
        onDelete={deleteAction}
        onSave={updateAction}
        refreshList={() => {
          dispatch(listProposalActionsAction({ ...proposalActionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalActionAction());

          dispatch(resetUpdateProposalActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalActionUpdate.success}
        saveSuccessMessage="Action updated."
        saving={proposalActionUpdate.loading}
        setIsOpen={setUpdateActionDrawerIsOpen}
        title="Update action"
      />

      {/* New question form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the question</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={proposalQuestionCreate.error}
                  fieldName="name"
                  icon="search-template"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the question a title"
                  setValueObject={setProposalQuestion}
                  setValueVariable="name"
                  value={proposalQuestion.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={proposalQuestionCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    { id: 1, icon: "layout-hierarchy", value: "Authority" },
                    { id: 2, icon: "dollar", value: "Budget" },
                    { id: 3, icon: "locate", value: "Need" },
                    { id: 4, icon: "calendar", value: "Timing" },
                    { id: 5, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setProposalQuestion}
                  setValueVariable="category"
                  value={proposalQuestion.category}
                />
              </div>
            </div>

            {/* Question */}
            <FormDrawerTextAreaField3
              error={proposalQuestionCreate.error}
              fieldName="question"
              height="100px"
              label="Question"
              labelInfo="(required)"
              placeholder="Enter the question"
              setValueObject={setProposalQuestion}
              setValueVariable="question"
              value={proposalQuestion.question}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={proposalQuestionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for answering the question"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalQuestion}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={proposalQuestion.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>
          </Card>
        }
        error={proposalQuestionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create question"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing proposal research"
        icon="edit"
        isOpen={addQuestionDrawerIsOpen}
        onSave={addQuestion}
        refreshList={() => {
          dispatch(listProposalQuestionsAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalQuestionCreate.success}
        saveSuccessMessage="Question added."
        saving={proposalQuestionCreate.loading}
        setIsOpen={setAddQuestionDrawerIsOpen}
        title="Create question"
      />

      {/* Existing question form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the question</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={proposalQuestionUpdate.error}
                    fieldName="name"
                    icon="search-template"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the question a title"
                    setValueObject={setProposalQuestion}
                    setValueVariable="name"
                    value={proposalQuestion.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={proposalQuestionUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "layout-hierarchy", value: "Authority" },
                      { id: 2, icon: "dollar", value: "Budget" },
                      { id: 3, icon: "locate", value: "Need" },
                      { id: 4, icon: "calendar", value: "Timing" },
                      { id: 5, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setProposalQuestion}
                    setValueVariable="category"
                    value={proposalQuestion.category}
                  />
                </div>
              </div>

              {/* Question */}
              <FormDrawerTextAreaField3
                error={proposalQuestionUpdate.error}
                fieldName="question"
                height="100px"
                label="Question"
                labelInfo="(required)"
                placeholder="Enter the question"
                setValueObject={setProposalQuestion}
                setValueVariable="question"
                value={proposalQuestion.question}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={proposalQuestionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible for answering the question"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalQuestion}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={proposalQuestion.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the answer</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalQuestionUpdate.error}
                    fieldName="status"
                    intent={
                      proposalQuestion.status === "Researching"
                        ? "warning"
                        : proposalQuestion.status === "Resolved"
                        ? "success"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue",
                        value: "Researching",
                      },
                      {
                        id: 2,
                        icon: "issue-closed",
                        value: "Resolved",
                      },
                      {
                        id: 3,
                        icon: "disable",
                        value: "Not applicable",
                      },
                    ]}
                    setValueObject={setProposalQuestion}
                    setValueVariable="status"
                    value={proposalQuestion.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Answer */}
              <FormDrawerTextAreaField3
                error={proposalQuestionUpdate.error}
                fieldName="answer"
                label="Answer"
                placeholder="Enter the answer"
                setValueObject={setProposalQuestion}
                setValueVariable="answer"
                value={proposalQuestion.answer}
              />
            </Card>
          </div>
        }
        deleteSuccess={proposalQuestionDelete.success}
        deleteSuccessMessage="Question removed."
        deleting={proposalQuestionDelete.loading}
        error={proposalQuestionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update question"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing proposal research"
        icon="edit"
        isOpen={updateQuestionDrawerIsOpen}
        loading={proposalQuestionDetails.loading}
        onDelete={deleteQuestion}
        onSave={updateQuestion}
        refreshList={() => {
          dispatch(listProposalQuestionsAction({ ...proposalQuestionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalQuestionAction());

          dispatch(resetUpdateProposalQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalQuestionUpdate.success}
        saveSuccessMessage="Question updated."
        saving={proposalQuestionUpdate.loading}
        setIsOpen={setUpdateQuestionDrawerIsOpen}
        title="Update question"
      />

      {/* New approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Decision */}
                  <FormDrawerSelectSimpleField3
                    error={approvalRequestCreate.error}
                    fieldName="decision_right"
                    helperText="The type of decision you are asking for"
                    label="Decision"
                    labelInfo="(required)"
                    options={[
                      {
                        id: 1,
                        icon: "search-template",
                        value: "Proposal qualification",
                      },
                      /*{ id: 2, icon: "locate", value: "Proposal strategy" },*/
                      /*{
                        id: 3,
                        icon: "gantt-chart",
                        value: "Proposal solution",
                      },*/
                      /*{ id: 4, icon: "dollar", value: "Proposal commercials" },*/
                      {
                        id: 5,
                        icon: "manually-entered-data",
                        value: "Proposal submission",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="decisionRight"
                    value={approvalRequest.decisionRight}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    error={approvalRequestCreate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={approvalRequestCreate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    error={approvalRequestCreate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={approvalRequestCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create approval request"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={addApprovalRequestDrawerIsOpen}
        onSave={addApprovalRequest}
        refreshList={() => {
          dispatch(
            listApprovalRequestsAction({
              itemId: id,
              itemType: "proposal",
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestCreate.success}
        saveSuccessMessage="Approval request created."
        saving={approvalRequestCreate.loading}
        setIsOpen={setAddApprovalRequestDrawerIsOpen}
        title="Create approval request"
      />

      {/* Existing approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Decision */}
                  <FormDrawerSelectSimpleField3
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="decision_right"
                    helperText="The type of decision you are asking for"
                    label="Decision"
                    labelInfo="(required)"
                    options={[
                      {
                        id: 1,
                        icon: "search-template",
                        value: "Proposal qualification",
                      },
                      /*{ id: 2, icon: "locate", value: "Proposal strategy" },*/
                      /*{
                        id: 3,
                        icon: "gantt-chart",
                        value: "Proposal solution",
                      },*/
                      /*{ id: 4, icon: "dollar", value: "Proposal commercials" },*/
                      {
                        id: 5,
                        icon: "manually-entered-data",
                        value: "Proposal submission",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="decisionRight"
                    value={approvalRequest.decisionRight}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                disabled={
                  approvalRequestDetails.approval_request.status === "Submitted"
                }
                error={approvalRequestUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={approvalRequestDelete.success}
        deleteSuccessMessage="Approval request deleted."
        deleting={approvalRequestDelete.loading}
        error={approvalRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approval request"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={updateApprovalRequestDrawerIsOpen}
        loading={approvalRequestDetails.loading}
        onDelete={deleteApprovalRequest}
        onSave={updateApprovalRequest}
        refreshList={() => {
          dispatch(listApprovalRequestsAction({ ...approvalRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteApprovalRequestAction());

          dispatch(resetUpdateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestUpdate.success}
        saveSuccessMessage="Approval request updated."
        saving={approvalRequestUpdate.loading}
        setIsOpen={setUpdateApprovalRequestDrawerIsOpen}
        title="Update approval request"
      />

      {/* Strategic alignment factors form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Evaluate market fit</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Market */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Market score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_market_score"
                    intent={
                      updatedProposal.qualificationMarketScore > 4
                        ? "success"
                        : updatedProposal.qualificationMarketScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Is the client in our target market?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationMarketScore"
                    value={updatedProposal.qualificationMarketScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Market comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_market_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationMarketComments"
                value={updatedProposal.qualificationMarketComments}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Analyse client needs</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Services */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Services score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_services_score"
                    intent={
                      updatedProposal.qualificationServicesScore > 4
                        ? "success"
                        : updatedProposal.qualificationServicesScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Do we provide the services that the client needs?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationServicesScore"
                    value={updatedProposal.qualificationServicesScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Services comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_services_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationServicesComments"
                value={updatedProposal.qualificationServicesComments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Assess client reputation</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Reputation */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Reputation score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_reputation_score"
                    intent={
                      updatedProposal.qualificationReputationScore > 4
                        ? "success"
                        : updatedProposal.qualificationReputationScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Are we comfortable being associated with the client and this
                    project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationReputationScore"
                    value={updatedProposal.qualificationReputationScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Reputation comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_reputation_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationReputationComments"
                value={updatedProposal.qualificationReputationComments}
              />
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Analyse strategic alignment"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Analysis strategic alignment"
        icon="edit"
        isOpen={updateStrategicAlignmentFactorsDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateStrategicAlignmentFactors}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateStrategicAlignmentFactorsDrawerIsOpen}
        title="Analyse strategic alignment"
      />

      {/* Opportunity factors form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Confirm the budget</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Budget */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Budget score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_budget_score"
                    intent={
                      updatedProposal.qualificationBudgetScore > 4
                        ? "success"
                        : updatedProposal.qualificationBudgetScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Has the client allocated a budget to this project that covers our
                  expected fee?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationBudgetScore"
                    value={updatedProposal.qualificationBudgetScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Budget comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_budget_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationBudgetComments"
                value={updatedProposal.qualificationBudgetComments}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Assess importance</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Need */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Need score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_need_score"
                    intent={
                      updatedProposal.qualificationNeedScore > 4
                        ? "success"
                        : updatedProposal.qualificationNeedScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Is this project aligned with the client's long-term interests?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationNeedScore"
                    value={updatedProposal.qualificationNeedScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Need comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_need_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationNeedComments"
                value={updatedProposal.qualificationNeedComments}
              />
            </Card>

            <Card>
              <H5>Determine urgency</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Timing */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Timing score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_timing_score"
                    intent={
                      updatedProposal.qualificationTimingScore > 4
                        ? "success"
                        : updatedProposal.qualificationTimingScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Does the client have an urgent need for the project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationTimingScore"
                    value={updatedProposal.qualificationTimingScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Timing comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_timing_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationTimingComments"
                value={updatedProposal.qualificationTimingComments}
              />
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Analyse opportunity"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Evaluating the opportunity"
        icon="edit"
        isOpen={updateOpportunityFactorsDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateOpportunityFactors}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateOpportunityFactorsDrawerIsOpen}
        title="Analyse opportunity"
      />

      {/* Relationship factors form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Assess your relationship overall</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Relationship */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Relationship score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_relationship_score"
                    intent={
                      updatedProposal.qualificationRelationshipScore > 4
                        ? "success"
                        : updatedProposal.qualificationRelationshipScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="In general, do we have a positive relationship with the client and
                  third-parties involved in the project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationRelationshipScore"
                    value={updatedProposal.qualificationRelationshipScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Relationship comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_relationship_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationRelationshipComments"
                value={updatedProposal.qualificationRelationshipComments}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Evaluate your economic buyer relationship</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Buyer */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Buyer score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_buyer_score"
                    intent={
                      updatedProposal.qualificationBuyerScore > 4
                        ? "success"
                        : updatedProposal.qualificationBuyerScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Do we have a positive relationship with the person who makes
                  buying decisions on this project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationBuyerScore"
                    value={updatedProposal.qualificationBuyerScore}
                  />
                </div>
                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Buyer comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_buyer_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationBuyerComments"
                value={updatedProposal.qualificationBuyerComments}
              />
            </Card>

            <Card>
              <H5>Understand your ability to find a sponsor</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Coach */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Coach score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_coach_score"
                    intent={
                      updatedProposal.qualificationCoachScore > 4
                        ? "success"
                        : updatedProposal.qualificationCoachScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Do we have a client sponsor for this proposal?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationCoachScore"
                    value={updatedProposal.qualificationCoachScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Coach comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_coach_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationCoachComments"
                value={updatedProposal.qualificationCoachComments}
              />
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Analyse relationship"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Assessing the relationship"
        icon="edit"
        isOpen={updateRelationshipFactorsDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateRelationshipFactors}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateRelationshipFactorsDrawerIsOpen}
        title="Analyse relationship"
      />

      {/* Delivery factors form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Evaluate the team</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Team */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Team score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_team_score"
                    intent={
                      updatedProposal.qualificationTeamScore > 4
                        ? "success"
                        : updatedProposal.qualificationTeamScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Can we assign a suitably experienced team to this project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationTeamScore"
                    value={updatedProposal.qualificationTeamScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Team comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_team_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationTeamComments"
                value={updatedProposal.qualificationTeamComments}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Assess the solution</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Solution */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Solution score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_solution_score"
                    intent={
                      updatedProposal.qualificationSolutionScore > 4
                        ? "success"
                        : updatedProposal.qualificationSolutionScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Do we have a differentiated and proven solution to deliver this
              type of project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationSolutionScore"
                    value={updatedProposal.qualificationSolutionScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Solution comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_solution_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationSolutionComments"
                value={updatedProposal.qualificationSolutionComments}
              />
            </Card>

            <Card>
              <H5>Establish the client's capability</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Dependencies */}
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Dependencies score */}
                  <FormDrawerSliderField
                    error={proposalUpdate.error}
                    fieldName="qualification_dependencies_score"
                    intent={
                      updatedProposal.qualificationDependenciesScore > 4
                        ? "success"
                        : updatedProposal.qualificationDependenciesScore > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Can the client give us the support we need to deliver the project?"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Disagree
                            </span>
                          );
                        case 2:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Disagree
                            </span>
                          );
                        case 3:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Uncertain
                            </span>
                          );
                        case 4:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>Agree</span>
                          );
                        case 5:
                          return (
                            <span style={{ whiteSpace: "nowrap" }}>
                              Strongly Agree
                            </span>
                          );
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="50px"
                    max={5}
                    min={1}
                    setValueObject={setUpdatedProposal}
                    setValueVariable="qualificationDependenciesScore"
                    value={updatedProposal.qualificationDependenciesScore}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>

              {/* Dependencies comments */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="qualification_dependencies_comments"
                height="100px"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="qualificationDependenciesComments"
                value={updatedProposal.qualificationDependenciesComments}
              />
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Analyse delivery"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Assessing the delivery risk"
        icon="edit"
        isOpen={updateDeliveryFactorsDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateDeliveryFactors}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateDeliveryFactorsDrawerIsOpen}
        title="Analyse delivery"
      />

      {/* New client KPI form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the key performance indicator</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* KPI */}
                  <FormDrawerTextField2
                    error={clientKPICreate.error}
                    fieldName="kpi"
                    icon="dashboard"
                    label="KPI"
                    labelInfo="(required)"
                    placeholder="Name the client KPI"
                    setValueObject={setClientKPI}
                    setValueVariable="kpi"
                    value={clientKPI.kpi}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Units */}
                  <FormDrawerTextField2
                    error={clientKPICreate.error}
                    fieldName="units"
                    icon="variable"
                    label="Units"
                    labelInfo="(required)"
                    placeholder="Enter the units of measurement"
                    setValueObject={setClientKPI}
                    setValueVariable="units"
                    value={clientKPI.units}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Year */}
                  <FormDrawerNumericField2
                    error={clientKPICreate.error}
                    fieldName="year"
                    icon="calendar"
                    label="Year"
                    labelInfo="(required)"
                    majorStepSize={5}
                    max={today.getFullYear()}
                    min={2020}
                    minorStepSize={1}
                    placeholder="Enter the year of measurement"
                    setValueObject={setClientKPI}
                    setValueVariable="year"
                    stepSize={1}
                    value={clientKPI.year}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Target */}
                  <FormDrawerNumericField2
                    error={clientKPICreate.error}
                    fieldName="target"
                    icon="target"
                    label="Target"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the target value"
                    setValueObject={setClientKPI}
                    setValueVariable="target"
                    stepSize={10}
                    value={clientKPI.target}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "35%" }}>
                  {/* Actual */}
                  <FormDrawerNumericField2
                    error={clientKPICreate.error}
                    fieldName="achieved"
                    icon="trending-up"
                    label="Actual"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the achieved value"
                    setValueObject={setClientKPI}
                    setValueVariable="achieved"
                    stepSize={10}
                    value={clientKPI.achieved}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Evaluate the client's performance</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Rating */}
                  <FormDrawerSelectSimpleField3
                    error={clientKPICreate.error}
                    fieldName="rating"
                    intent={
                      clientKPI.rating === "Exceeded"
                        ? "success"
                        : clientKPI.rating === "Met"
                        ? "warning"
                        : "danger"
                    }
                    label="Rating"
                    options={[
                      { id: 1, icon: "arrow-up", value: "Exceeded" },
                      { id: 2, icon: "arrow-right", value: "Met" },
                      { id: 3, icon: "arrow-down", value: "Not met" },
                    ]}
                    setValueObject={setClientKPI}
                    setValueVariable="rating"
                    value={clientKPI.rating}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={clientKPICreate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter your comments"
                setValueObject={setClientKPI}
                setValueVariable="comments"
                value={clientKPI.comments}
              />
            </Card>
          </div>
        }
        error={clientKPICreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add client KPI"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Evaluating performance"
        icon="edit"
        isOpen={addClientKPIDrawerIsOpen}
        onSave={addClientKPI}
        refreshList={() => {
          dispatch(
            listClientKPIsAction({ client: proposalDetails.proposal.client })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateClientKPIAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientKPICreate.success}
        saveSuccessMessage="Client KPI added."
        saving={clientKPICreate.loading}
        setIsOpen={setAddClientKPIDrawerIsOpen}
        title="Add client KPI"
      />

      {/* Existing client KPI form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the key performance indicator</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* KPI */}
                  <FormDrawerTextField2
                    error={clientKPIUpdate.error}
                    fieldName="kpi"
                    icon="dashboard"
                    label="KPI"
                    labelInfo="(required)"
                    placeholder="Name the client KPI"
                    setValueObject={setClientKPI}
                    setValueVariable="kpi"
                    value={clientKPI.kpi}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Units */}
                  <FormDrawerTextField2
                    error={clientKPIUpdate.error}
                    fieldName="units"
                    icon="variable"
                    label="Units"
                    labelInfo="(required)"
                    placeholder="Enter the units of measurement"
                    setValueObject={setClientKPI}
                    setValueVariable="units"
                    value={clientKPI.units}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Year */}
                  <FormDrawerNumericField2
                    error={clientKPIUpdate.error}
                    fieldName="year"
                    icon="calendar"
                    label="Year"
                    labelInfo="(required)"
                    majorStepSize={5}
                    max={today.getFullYear()}
                    min={2020}
                    minorStepSize={1}
                    placeholder="Enter the year of measurement"
                    setValueObject={setClientKPI}
                    setValueVariable="year"
                    stepSize={1}
                    value={clientKPI.year}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Target */}
                  <FormDrawerNumericField2
                    error={clientKPIUpdate.error}
                    fieldName="target"
                    icon="target"
                    label="Target"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the target value"
                    setValueObject={setClientKPI}
                    setValueVariable="target"
                    stepSize={10}
                    value={clientKPI.target}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "35%" }}>
                  {/* Actual */}
                  <FormDrawerNumericField2
                    error={clientKPIUpdate.error}
                    fieldName="achieved"
                    icon="trending-up"
                    label="Actual"
                    labelInfo="(required)"
                    majorStepSize={100}
                    minorStepSize={1}
                    placeholder="Enter the achieved value"
                    setValueObject={setClientKPI}
                    setValueVariable="achieved"
                    stepSize={10}
                    value={clientKPI.achieved}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Evaluate the client's performance</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Rating */}
                  <FormDrawerSelectSimpleField3
                    error={clientKPIUpdate.error}
                    fieldName="rating"
                    intent={
                      clientKPI.rating === "Exceeded"
                        ? "success"
                        : clientKPI.rating === "Met"
                        ? "warning"
                        : "danger"
                    }
                    label="Rating"
                    options={[
                      { id: 1, icon: "arrow-up", value: "Exceeded" },
                      { id: 2, icon: "arrow-right", value: "Met" },
                      { id: 3, icon: "arrow-down", value: "Not met" },
                    ]}
                    setValueObject={setClientKPI}
                    setValueVariable="rating"
                    value={clientKPI.rating}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={clientKPIUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter your comments"
                setValueObject={setClientKPI}
                setValueVariable="comments"
                value={clientKPI.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={clientKPIDelete.success}
        deleteSuccessMessage="Client KPI deleted."
        deleting={clientKPIDelete.loading}
        error={clientKPIUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update client KPI"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Evaluating performance"
        icon="edit"
        isOpen={updateClientKPIDrawerIsOpen}
        loading={clientKPIDetails.loading}
        onDelete={deleteClientKPI}
        onSave={updateClientKPI}
        refreshList={() => {
          dispatch(
            listClientKPIsAction({
              ...clientKPIQuery,
              client: proposalDetails.proposal.client,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteClientKPIAction());

          dispatch(resetUpdateClientKPIAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientKPIUpdate.success}
        saveSuccessMessage="Client KPI added."
        saving={clientKPIUpdate.loading}
        setIsOpen={setUpdateClientKPIDrawerIsOpen}
        title="Update client KPI"
      />

      {/* Needs analysis form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Callout
              intent="primary"
              style={{ margin: "0 0 15px 0", width: "100%" }}
            >
              advaise.app can import the content of this section into the
              proposal document. You can use the content as-is or ask the system
              to rewrite it.
            </Callout>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Understand the change drivers</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Change drivers */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="change_drivers"
                label="What is driving the client to change?"
                placeholder="Describe the impact of external (competitor, customer, regulator,
                    supplier) or internal (cultural, financial, operational,
                    technological) events on the client."
                setValueObject={setUpdatedProposal}
                setValueVariable="changeDrivers"
                value={updatedProposal.changeDrivers}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify strategic options</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Strategic options */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="strategic_options"
                label="What options does the client have?"
                placeholder="Identify strategic options, such as market development,
                  product development, process improvement, joint venture,
                  horizontal/vertical integration, divestment, or restructure."
                setValueObject={setUpdatedProposal}
                setValueVariable="strategicOptions"
                value={updatedProposal.strategicOptions}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define the leading hypothesis</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Theory of change */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="theory_of_change"
                label="What is the client's preferred strategy (or our leading hypothesis)?"
                placeholder="Identify and justify the most-likely response, listing assumptions that need validation."
                setValueObject={setUpdatedProposal}
                setValueVariable="theoryOfChange"
                value={updatedProposal.theoryOfChange}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify issues</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Issues */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="issues"
                label="What challenges face the client in moving forward?"
                placeholder="Describe key success factors to implement the client's strategy."
                setValueObject={setUpdatedProposal}
                setValueVariable="issues"
                value={updatedProposal.issues}
              />
            </Card>

            <Card>
              <H5>Outline the solution</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Our solution */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="our_solution"
                label="What solutions can we bring to support the change?"
                placeholder="Outline the knowledge and skills that we can bring to help the client on their journey."
                setValueObject={setUpdatedProposal}
                setValueVariable="ourSolution"
                value={updatedProposal.ourSolution}
              />
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Analyse needs"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Analysing needs"
        icon="edit"
        isOpen={updateNeedsAnalysisDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateNeedsAnalysis}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateNeedsAnalysisDrawerIsOpen}
        title="Analyse needs"
      />

      {/* New proposal contact form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the stakeholder management strategy</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Contact */}
                <FormDrawerSelectField2
                  allowCreate
                  error={proposalContactCreate.error}
                  fieldName="contact"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={proposalContact.contact}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Contact"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalContact}
                  setValueIdVariable="contact"
                  setValueNameVariable="contactName"
                  showInfo={proposalContact.contact}
                  value={proposalContact.contactName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level of influence */}
                <FormDrawerSliderField
                  error={proposalContactCreate.error}
                  fieldName="level_of_influence"
                  intent={
                    proposalContact.levelOfInfluence > 4
                      ? "success"
                      : proposalContact.levelOfInfluence > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of influence"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setProposalContact}
                  setValueVariable="levelOfInfluence"
                  value={proposalContact.levelOfInfluence}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Buyer role */}
                <FormDrawerSelectSimpleField3
                  error={proposalContactCreate.error}
                  fieldName="buyer_role"
                  label="Buyer role"
                  options={[
                    { id: 1, icon: "chat", value: "Advisor" },
                    { id: 2, icon: "bank-account", value: "Budget Holder" },
                    { id: 3, icon: "user", value: "End-User" },
                    {
                      id: 4,
                      icon: "layout-hierarchy",
                      value: "Executive Sponsor",
                    },
                    {
                      id: 5,
                      icon: "search-template",
                      value: "Legal and Compliance",
                    },
                  ]}
                  setValueObject={setProposalContact}
                  setValueVariable="buyerRole"
                  value={proposalContact.buyerRole}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Relationship owner */}
                <FormDrawerSelectField2
                  error={proposalContactCreate.error}
                  fieldName="relationship_owner"
                  helperText="Person responsible for managing the stakeholder"
                  icon="user"
                  items={managerList.managers}
                  label="Relationship owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select a relationship owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalContact}
                  setValueIdVariable="relationshipOwner"
                  setValueNameVariable="relationshipOwnerName"
                  value={proposalContact.relationshipOwnerName}
                />
              </div>
            </div>

            {/* Stakeholder management strategy */}
            <FormDrawerTextAreaField3
              error={proposalContactCreate.error}
              fieldName="comments"
              label="Stakeholder management strategy"
              placeholder="Describe how you will engage with this stakeholder"
              setValueObject={setProposalContact}
              setValueVariable="comments"
              value={proposalContact.comments}
            />
          </Card>
        }
        error={proposalContactCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add contact"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing stakeholders"
        icon="edit"
        isOpen={addProposalContactDrawerIsOpen}
        onSave={addProposalContact}
        refreshList={() => {
          dispatch(listProposalContactsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalContactCreate.success}
        saveSuccessMessage="Contact added."
        saving={proposalContactCreate.loading}
        setIsOpen={setAddProposalContactDrawerIsOpen}
        title="Add contact"
      />

      {/* Existing proposal contact form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the stakeholder management strategy</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Contact */}
                <FormDrawerSelectField2
                  allowCreate
                  disabled
                  error={proposalContactUpdate.error}
                  fieldName="contact"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={proposalContact.contact}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Contact"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalContact}
                  setValueIdVariable="contact"
                  setValueNameVariable="contactName"
                  showInfo={proposalContact.contact}
                  value={proposalContact.contactName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level of influence */}
                <FormDrawerSliderField
                  error={proposalContactUpdate.error}
                  fieldName="level_of_influence"
                  intent={
                    proposalContact.levelOfInfluence > 4
                      ? "success"
                      : proposalContact.levelOfInfluence > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of influence"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setProposalContact}
                  setValueVariable="levelOfInfluence"
                  value={proposalContact.levelOfInfluence}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Buyer role */}
                <FormDrawerSelectSimpleField3
                  error={proposalContactUpdate.error}
                  fieldName="buyer_role"
                  label="Buyer role"
                  options={[
                    { id: 1, icon: "chat", value: "Advisor" },
                    { id: 2, icon: "bank-account", value: "Budget Holder" },
                    { id: 3, icon: "user", value: "End-User" },
                    {
                      id: 4,
                      icon: "layout-hierarchy",
                      value: "Executive Sponsor",
                    },
                    {
                      id: 5,
                      icon: "search-template",
                      value: "Legal and Compliance",
                    },
                  ]}
                  setValueObject={setProposalContact}
                  setValueVariable="buyerRole"
                  value={proposalContact.buyerRole}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Relationship owner */}
                <FormDrawerSelectField2
                  error={proposalContactUpdate.error}
                  fieldName="relationship_owner"
                  helperText="Person responsible for managing the stakeholder"
                  icon="user"
                  items={managerList.managers}
                  label="Relationship owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select a relationship owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalContact}
                  setValueIdVariable="relationshipOwner"
                  setValueNameVariable="relationshipOwnerName"
                  value={proposalContact.relationshipOwnerName}
                />
              </div>
            </div>

            {/* Relationship management strategy */}
            <FormDrawerTextAreaField3
              error={proposalContactUpdate.error}
              fieldName="comments"
              label="Relationship management strategy"
              placeholder="Describe how you will engage with this stakeholder"
              setValueObject={setProposalContact}
              setValueVariable="comments"
              value={proposalContact.comments}
            />
          </Card>
        }
        deleteSuccess={proposalContactDelete.success}
        deleteSuccessMessage="Proposal contact deleted."
        deleting={proposalContactDelete.loading}
        error={proposalContactUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update contact"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Managing stakeholders"
        icon="edit"
        isOpen={updateProposalContactDrawerIsOpen}
        loading={proposalContactDetails.loading}
        onDelete={deleteProposalContact}
        onSave={updateProposalContact}
        refreshList={() => {
          dispatch(listProposalContactsAction({ ...proposalContactQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalContactAction());

          dispatch(resetUpdateProposalContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalContactUpdate.success}
        saveSuccessMessage="Proposal contact updated."
        saving={proposalContactUpdate.loading}
        setIsOpen={setUpdateProposalContactDrawerIsOpen}
        title="Update contact"
      />

      {/* New competitor form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the competitor</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Competitor */}
                  <FormDrawerTextField2
                    error={proposalCompetitorCreate.error}
                    fieldName="competitor"
                    icon="office"
                    label="Competitor"
                    labelInfo="(required)"
                    placeholder="Enter the competing organisation"
                    setValueObject={setProposalCompetitor}
                    setValueVariable="competitor"
                    value={proposalCompetitor.competitor}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={proposalCompetitorCreate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the competitor"
                setValueObject={setProposalCompetitor}
                setValueVariable="notes"
                value={proposalCompetitor.notes}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Benchmark capability</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Commercials */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="commercials"
                    helperText="Attractiveness of commercial terms"
                    label="Commercials"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="commercials"
                    value={proposalCompetitor.commercials}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Partnerships */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="partnerships"
                    helperText="Ability to bring a complete solution through partnerships"
                    label="Partnerships"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="partnerships"
                    value={proposalCompetitor.partnerships}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Price */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="price"
                    helperText="Value-for-money"
                    label="Price"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="price"
                    value={proposalCompetitor.price}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* References */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="references"
                    helperText="Credibility and relevance of references"
                    label="References"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="references"
                    value={proposalCompetitor.references}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Relationships */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="relationships"
                    helperText="Breadth and depth of client relationships"
                    label="Relationships"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="relationships"
                    value={proposalCompetitor.relationships}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Solution */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="solution"
                    helperText="Differentiation of solution"
                    label="Solution"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="solution"
                    value={proposalCompetitor.solution}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Team */}
                  <FormDrawerSliderField
                    error={proposalCompetitorCreate.error}
                    fieldName="team"
                    helperText="Experience of team"
                    label="Team"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="team"
                    value={proposalCompetitor.team}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>
          </div>
        }
        error={proposalCompetitorCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add competitor"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Analysing competitors"
        icon="edit"
        isOpen={addCompetitorDrawerIsOpen}
        onSave={addCompetitor}
        refreshList={() => {
          dispatch(listProposalCompetitorsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalCompetitorAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalCompetitorCreate.success}
        saveSuccessMessage="Competitor added."
        saving={proposalCompetitorCreate.loading}
        setIsOpen={setAddCompetitorDrawerIsOpen}
        title="Add competitor"
      />

      {/* Existing competitor form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the competitor</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Competitor */}
                  <FormDrawerTextField2
                    error={proposalCompetitorUpdate.error}
                    fieldName="competitor"
                    icon="office"
                    label="Competitor"
                    labelInfo="(required)"
                    placeholder="Enter the competing organisation"
                    setValueObject={setProposalCompetitor}
                    setValueVariable="competitor"
                    value={proposalCompetitor.competitor}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={proposalCompetitorUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the competitor"
                setValueObject={setProposalCompetitor}
                setValueVariable="notes"
                value={proposalCompetitor.notes}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Benchmark capability</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Commercials */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="commercials"
                    helperText="Attractiveness of commercial terms"
                    label="Commercials"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="commercials"
                    value={proposalCompetitor.commercials}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Partnerships */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="partnerships"
                    helperText="Ability to bring a complete solution through partnerships"
                    label="Partnerships"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="partnerships"
                    value={proposalCompetitor.partnerships}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Price */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="price"
                    helperText="Value-for-money"
                    label="Price"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="price"
                    value={proposalCompetitor.price}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* References */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="references"
                    helperText="Credibility and relevance of references"
                    label="References"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="references"
                    value={proposalCompetitor.references}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Relationships */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="relationships"
                    helperText="Breadth and depth of client relationships"
                    label="Relationships"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="relationships"
                    value={proposalCompetitor.relationships}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Solution */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="solution"
                    helperText="Differentiation of solution"
                    label="Solution"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="solution"
                    value={proposalCompetitor.solution}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Team */}
                  <FormDrawerSliderField
                    error={proposalCompetitorUpdate.error}
                    fieldName="team"
                    helperText="Experience of team"
                    label="Team"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Low";
                        case 5:
                          return "High";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setProposalCompetitor}
                    setValueVariable="team"
                    value={proposalCompetitor.team}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={proposalCompetitorDelete.success}
        deleteSuccessMessage="Competitor removed."
        deleting={proposalCompetitorDelete.loading}
        error={proposalCompetitorUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update competitor"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Analysing competitors"
        icon="edit"
        isOpen={updateCompetitorDrawerIsOpen}
        loading={proposalCompetitorDetails.loading}
        onDelete={deleteCompetitor}
        onSave={updateCompetitor}
        refreshList={() => {
          dispatch(
            listProposalCompetitorsAction({ ...proposalCompetitorQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalCompetitorAction());

          dispatch(resetUpdateProposalCompetitorAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalCompetitorUpdate.success}
        saveSuccessMessage="Competitor updated."
        saving={proposalCompetitorUpdate.loading}
        setIsOpen={setUpdateCompetitorDrawerIsOpen}
        title="Update competitor"
      />

      {/* Win strategy form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Callout intent="primary" style={{ margin: "0 0 15px 0" }}>
              advaise.app can import the content of this section into the
              proposal document. You can use the content as-is or ask the system
              to rewrite it.
            </Callout>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Summarize the commercial value proposition</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Commercials */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_commercials"
                label="How can we provide more compelling terms and conditions than our
              competitors?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyCommercials"
                value={updatedProposal.winStrategyCommercials}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe the partnering approach</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Partnerships */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_partnerships"
                label="How can we partner with other organisations to offer a complete
              solution?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyPartnerships"
                value={updatedProposal.winStrategyPartnerships}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define the pricing strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Price */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_price"
                label="How can we provide the best value-for-money?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyPrice"
                value={updatedProposal.winStrategyPrice}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify key references</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* References */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_references"
                label="How can we secure more credible and high-profile references than
              our competitors?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyReferences"
                value={updatedProposal.winStrategyReferences}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define strategic relationships</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Relationships */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_relationships"
                label="How can we better engage with the key stakeholders than our
              competitors?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyRelationships"
                value={updatedProposal.winStrategyRelationships}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Summarize the winning solution</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Solution */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_solution"
                label="How can we leverage our proprietary assets and differentiated
              skills to bring the best solution?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategySolution"
                value={updatedProposal.winStrategySolution}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify key staff</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Team */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_team"
                label="How can we bring a more experienced and high-profile team than our
              competitors?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyTeam"
                value={updatedProposal.winStrategyTeam}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Find other ways to stand out</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Other */}
              <FormDrawerTextAreaField3
                error={proposalUpdate.error}
                fieldName="win_strategy_other"
                label="How else can we provide more value than our competitors?"
                placeholder="Enter your comments"
                setValueObject={setUpdatedProposal}
                setValueVariable="winStrategyOther"
                value={updatedProposal.winStrategyOther}
              />
            </Card>
          </div>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Define win strategy"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Shaping the win strategy"
        icon="edit"
        isOpen={updateWinStrategyDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateWinStrategy}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateWinStrategyDrawerIsOpen}
        title="Define win strategy"
      />

      {/* New requirement form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the requirement</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={proposalRequirementCreate.error}
                    fieldName="name"
                    icon="form"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the requirement a title"
                    setValueObject={setProposalRequirement}
                    setValueVariable="name"
                    value={proposalRequirement.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Client reference */}
                  <FormDrawerTextField2
                    error={proposalRequirementCreate.error}
                    fieldName="ref"
                    icon="tag"
                    label="Client reference"
                    placeholder="Enter the client's requirement reference"
                    setValueObject={setProposalRequirement}
                    setValueVariable="ref"
                    value={proposalRequirement.ref}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Category */}
                  <FormDrawerTextField2
                    error={proposalRequirementCreate.error}
                    fieldName="category"
                    helperText="Label used to classify requirements"
                    icon="filter-list"
                    label="Category"
                    placeholder="Enter the requirement category"
                    setValueObject={setProposalRequirement}
                    setValueVariable="category"
                    value={proposalRequirement.category}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Attachment */}
                  <FormDrawerFileField2
                    error={proposalRequirementCreate.error}
                    fileName="attachment"
                    label="Attachment"
                    placeholder="Upload the attachment"
                    setValueObject={setProposalRequirement}
                    setValueFileVariable="attachment"
                    setValueNameVariable="attachmentName"
                    valueFile={proposalRequirement.attachment}
                    valueName={proposalRequirement.attachmentName}
                  />
                </div>
              </div>

              {/* Requirement */}
              <FormDrawerTextAreaField3
                error={proposalRequirementCreate.error}
                fieldName="requirement"
                label="Requirement"
                labelInfo="(required)"
                placeholder="Enter the requirement"
                setValueObject={setProposalRequirement}
                setValueVariable="requirement"
                value={proposalRequirement.requirement}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Define the response</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={proposalRequirementCreate.error}
                    fieldName="owner"
                    helperText="Person responsible for responding"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalRequirement}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={proposalRequirement.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRequirementCreate.error}
                    fieldName="status"
                    intent={
                      proposalRequirement.status === "Compliant"
                        ? "success"
                        : proposalRequirement.status === "Part-compliant"
                        ? "warning"
                        : "danger"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "full-circle", value: "Compliant" },
                      { id: 2, icon: "ring", value: "Part-compliant" },
                      { id: 3, icon: "circle", value: "Non-compliant" },
                    ]}
                    setValueObject={setProposalRequirement}
                    setValueVariable="status"
                    value={proposalRequirement.status}
                  />
                </div>
              </div>

              {/* Response */}
              <FormDrawerTextAreaField3
                error={proposalRequirementCreate.error}
                fieldName="response"
                label="Response"
                placeholder="Enter the response"
                setValueObject={setProposalRequirement}
                setValueVariable="response"
                value={proposalRequirement.response}
              />
            </Card>
          </div>
        }
        error={proposalRequirementCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add requirement"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Responding to questions/requirements"
        icon="edit"
        isOpen={addRequirementDrawerIsOpen}
        onSave={addRequirement}
        refreshList={() => {
          dispatch(listProposalRequirementsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalRequirementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalRequirementCreate.success}
        saveSuccessMessage="Requirement added."
        saving={proposalRequirementCreate.loading}
        setIsOpen={setAddRequirementDrawerIsOpen}
        title="Add requirement"
      />

      {/* Existing requirement form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the requirement</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={proposalRequirementUpdate.error}
                    fieldName="name"
                    icon="form"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the requirement a title"
                    setValueObject={setProposalRequirement}
                    setValueVariable="name"
                    value={proposalRequirement.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Client reference */}
                  <FormDrawerTextField2
                    error={proposalRequirementUpdate.error}
                    fieldName="ref"
                    icon="tag"
                    label="Client reference"
                    placeholder="Enter the client's requirement reference"
                    setValueObject={setProposalRequirement}
                    setValueVariable="ref"
                    value={proposalRequirement.ref}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Category */}
                  <FormDrawerTextField2
                    error={proposalRequirementUpdate.error}
                    fieldName="category"
                    helperText="Label used to classify requirements"
                    icon="filter-list"
                    label="Category"
                    placeholder="Enter the requirement category"
                    setValueObject={setProposalRequirement}
                    setValueVariable="category"
                    value={proposalRequirement.category}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Attachment */}
                  <FormDrawerFileField2
                    error={proposalRequirementUpdate.error}
                    fileName="attachment"
                    label="Attachment"
                    placeholder="Upload the attachment"
                    setValueObject={setProposalRequirement}
                    setValueFileVariable="attachment"
                    setValueNameVariable="attachmentName"
                    valueFile={proposalRequirement.attachment}
                    valueName={proposalRequirement.attachmentName}
                  />
                </div>
              </div>

              {/* Requirement */}
              <FormDrawerTextAreaField3
                error={proposalRequirementUpdate.error}
                fieldName="requirement"
                label="Requirement"
                labelInfo="(required)"
                placeholder="Enter the requirement"
                setValueObject={setProposalRequirement}
                setValueVariable="requirement"
                value={proposalRequirement.requirement}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define the response</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={proposalRequirementUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible for responding"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalRequirement}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={proposalRequirement.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRequirementUpdate.error}
                    fieldName="status"
                    intent={
                      proposalRequirement.status === "Compliant"
                        ? "success"
                        : proposalRequirement.status === "Part-compliant"
                        ? "warning"
                        : "danger"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "full-circle", value: "Compliant" },
                      { id: 2, icon: "ring", value: "Part-compliant" },
                      { id: 3, icon: "circle", value: "Non-compliant" },
                    ]}
                    setValueObject={setProposalRequirement}
                    setValueVariable="status"
                    value={proposalRequirement.status}
                  />
                </div>
              </div>

              {/* Response */}
              <FormDrawerTextAreaField3
                error={proposalRequirementUpdate.error}
                fieldName="response"
                label="Response"
                placeholder="Enter the response"
                setValueObject={setProposalRequirement}
                setValueVariable="response"
                value={proposalRequirement.response}
              />
            </Card>
          </div>
        }
        deleteSuccess={proposalRequirementDelete.success}
        deleteSuccessMessage="Requirement deleted."
        deleting={proposalRequirementDelete.loading}
        error={proposalRequirementUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update requirement"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Responding to questions/requirements"
        icon="edit"
        isOpen={updateRequirementDrawerIsOpen}
        loading={proposalRequirementDetails.loading}
        onDelete={deleteRequirement}
        onSave={updateRequirement}
        refreshList={() => {
          dispatch(
            listProposalRequirementsAction({ ...proposalRequirementQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalRequirementAction());

          dispatch(resetUpdateProposalRequirementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalRequirementUpdate.success}
        saveSuccessMessage="Requirement updated."
        saving={proposalRequirementUpdate.loading}
        setIsOpen={setUpdateRequirementDrawerIsOpen}
        title="Update requirement"
      />

      {/* Approach form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Summarize the engagement</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Approach summary */}
            <FormDrawerTextAreaField3
              error={proposalUpdate.error}
              fieldName="approach_summary"
              label="Approach summary"
              placeholder="Summarize the engagement approach"
              setValueObject={setUpdatedProposal}
              setValueVariable="approachSummary"
              value={updatedProposal.approachSummary}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Approach diagram */}
                <FormDrawerFileField2
                  error={proposalUpdate.error}
                  fileName="approach_diagram"
                  label="Approach diagram"
                  placeholder="Upload the approach diagram"
                  setValueObject={setUpdatedProposal}
                  setValueFileVariable="approachDiagram"
                  setValueNameVariable="approachDiagramName"
                  valueFile={updatedProposal.approachDiagram}
                  valueName={updatedProposal.approachDiagramName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Approach diagram preview */}
            {updatedProposal.approachDiagram ? (
              updatedProposal.approachDiagram !==
              proposalDetails.proposal.approach_diagram ? (
                <TabSectionFieldImage
                  image={URL.createObjectURL(
                    new Blob([updatedProposal.approachDiagram])
                  )}
                />
              ) : (
                <TabSectionFieldImage
                  image={proposalDetails.proposal.approach_diagram}
                />
              )
            ) : undefined}
          </Card>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approach"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Defining the engagement method"
        icon="edit"
        isOpen={updateApproachDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateApproach}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateApproachDrawerIsOpen}
        title="Update approach"
      />

      {/* New service form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                margin: !proposalService.service ? "0 0 10px 0" : undefined,
                width: "100%",
              }}
            >
              <H5>Import a standard service</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Service */}
                  <FormDrawerSelectField2
                    error={proposalServiceCreate.error}
                    fieldName="service"
                    icon="heat-grid"
                    info={
                      <ServiceInfo
                        serviceId={proposalService.service}
                        query={serviceQueryDefault}
                      />
                    }
                    items={serviceList.services}
                    label="Service"
                    loading={serviceList.loading}
                    noOptionOnClick
                    placeholder="Select a service"
                    query={serviceQuery.searchString}
                    setQueryObject={setServiceQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalService}
                    setValueIdVariable="service"
                    setValueNameVariable="serviceName"
                    showInfo={proposalService.service}
                    value={proposalService.serviceName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="primary">
                    You can import an approved service from your organisation's
                    service catalogue. This autocompletes service information,
                    including activities and deliverables.
                  </Callout>
                </div>
              </div>
            </Card>

            {!proposalService.service && (
              <Card style={{ width: "100%" }}>
                <H5>Define the service</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Service name */}
                    <FormDrawerTextField2
                      error={proposalServiceCreate.error}
                      fieldName="name"
                      icon="label"
                      label="Service name"
                      labelInfo="(required)"
                      placeholder="Enter the service's name"
                      setValueObject={setProposalService}
                      setValueVariable="name"
                      value={proposalService.name}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}></div>
                </div>

                {/* Description */}
                <FormDrawerTextAreaField3
                  error={proposalServiceCreate.error}
                  fieldName="benefits"
                  label="Description"
                  placeholder="Describe the service"
                  setValueObject={setProposalService}
                  setValueVariable="description"
                  value={proposalService.description}
                />

                {/* Benefits */}
                <FormDrawerTextAreaField3
                  error={proposalServiceCreate.error}
                  fieldName="benefits"
                  label="Benefits"
                  placeholder="Describe how the service will benefit the client"
                  setValueObject={setProposalService}
                  setValueVariable="benefits"
                  value={proposalService.benefits}
                />
              </Card>
            )}
          </div>
        }
        error={proposalServiceCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add service"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adding services"
        icon="edit"
        isOpen={addServiceDrawerIsOpen}
        onSave={addService}
        refreshList={() => {
          dispatch(listProposalServicesAction({ proposal: id }));

          dispatch(listProposalServiceDeliverablesAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalServiceCreate.success}
        saveSuccessMessage="Service added."
        saving={proposalServiceCreate.loading}
        setIsOpen={setAddServiceDrawerIsOpen}
        title="Add service"
      />

      {/* Existing service form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the service</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Service name */}
                <FormDrawerTextField2
                  error={proposalServiceUpdate.error}
                  fieldName="name"
                  icon="label"
                  label="Service name"
                  labelInfo="(required)"
                  placeholder="Enter the service's name"
                  setValueObject={setProposalService}
                  setValueVariable="name"
                  value={proposalService.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalServiceUpdate.error}
              fieldName="benefits"
              label="Description"
              placeholder="Describe the service"
              setValueObject={setProposalService}
              setValueVariable="description"
              value={proposalService.description}
            />

            {/* Benefits */}
            <FormDrawerTextAreaField3
              error={proposalServiceUpdate.error}
              fieldName="benefits"
              label="Benefits"
              placeholder="Describe how the service will benefit the client"
              setValueObject={setProposalService}
              setValueVariable="benefits"
              value={proposalService.benefits}
            />
          </Card>
        }
        deleteSuccess={proposalServiceDelete.success}
        deleteSuccessMessage="Service removed."
        deleting={proposalServiceDelete.loading}
        error={proposalServiceUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update service"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adding services"
        icon="edit"
        isOpen={updateServiceDrawerIsOpen}
        loading={proposalServiceDetails.loading}
        onDelete={deleteService}
        onSave={updateService}
        refreshList={() => {
          dispatch(listProposalServicesAction({ ...proposalServiceQuery }));

          dispatch(listProposalServiceDeliverablesAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalServiceAction());

          dispatch(resetUpdateProposalServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalServiceUpdate.success}
        saveSuccessMessage="Service updated."
        saving={proposalServiceUpdate.loading}
        setIsOpen={setUpdateServiceDrawerIsOpen}
        title="Update service"
      />

      {/* New deliverable form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Specify the deliverable</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={proposalServiceDeliverableCreate.error}
                  fieldName="service"
                  icon="heat-grid"
                  items={proposalServiceList.proposal_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={proposalServiceList.loading}
                  placeholder="Select a service"
                  query={proposalServiceQuery.searchString}
                  setQueryObject={setProposalServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalServiceDeliverable}
                  setValueIdVariable="service"
                  setValueNameVariable="serviceName"
                  value={proposalServiceDeliverable.serviceName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Deliverable name */}
                <FormDrawerTextField2
                  error={proposalServiceDeliverableCreate.error}
                  fieldName="name"
                  label="Deliverable name"
                  labelInfo="(required)"
                  placeholder="Enter the deliverable's name"
                  setValueObject={setProposalServiceDeliverable}
                  setValueVariable="name"
                  value={proposalServiceDeliverable.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Deliverable type */}
                <FormDrawerTextField2
                  error={proposalServiceDeliverableCreate.error}
                  fieldName="type"
                  helperText="For example: document, software, workshop"
                  icon="tag"
                  label="Deliverable type"
                  placeholder="Enter the deliverable's type"
                  setValueObject={setProposalServiceDeliverable}
                  setValueVariable="type"
                  value={proposalServiceDeliverable.type}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Template */}
                <FormDrawerFileField2
                  error={proposalServiceDeliverableCreate.error}
                  fileName="template"
                  label="Template"
                  placeholder="Upload the template"
                  setValueObject={setProposalServiceDeliverable}
                  setValueFileVariable="template"
                  setValueNameVariable="templateName"
                  valueFile={proposalServiceDeliverable.template}
                  valueName={proposalServiceDeliverable.templateName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalServiceDeliverableCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the deliverable"
              setValueObject={setProposalServiceDeliverable}
              setValueVariable="description"
              value={proposalServiceDeliverable.description}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Due */}
                <FormDrawerDateField2
                  error={proposalServiceDeliverableCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due"
                  placeholder="Enter the deliverable due date"
                  setValueObject={setProposalServiceDeliverable}
                  setValueVariable="due"
                  value={proposalServiceDeliverable.due}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Milestone */}
                <FormDrawerSelectField2
                  error={proposalServiceDeliverableCreate.error}
                  fieldName="milestone"
                  icon="star-empty"
                  items={proposalMilestoneList.proposal_milestones}
                  label="Milestone"
                  loading={proposalMilestoneList.loading}
                  placeholder="Select a milestone"
                  query={proposalMilestoneQuery.searchString}
                  setQueryObject={setProposalMilestoneQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalServiceDeliverable}
                  setValueIdVariable="milestone"
                  setValueNameVariable="milestoneName"
                  value={proposalServiceDeliverable.milestoneName}
                />
              </div>
            </div>
          </Card>
        }
        error={proposalServiceDeliverableCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create deliverable"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing deliverables"
        icon="edit"
        isOpen={addDeliverableDrawerIsOpen}
        onSave={addDeliverable}
        refreshList={() => {
          dispatch(listProposalServiceDeliverablesAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));

          dispatch(listProposalMilestonesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalServiceDeliverableAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalServiceDeliverableCreate.success}
        saveSuccessMessage="Deliverable created."
        saving={proposalServiceDeliverableCreate.loading}
        setIsOpen={setAddDeliverableDrawerIsOpen}
        title="Create deliverable"
      />

      {/* Existing deliverable form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Specify the deliverable</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={proposalServiceDeliverableUpdate.error}
                  fieldName="service"
                  icon="heat-grid"
                  items={proposalServiceList.proposal_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={proposalServiceList.loading}
                  placeholder="Select a service"
                  query={proposalServiceQuery.searchString}
                  setQueryObject={setProposalServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalServiceDeliverable}
                  setValueIdVariable="service"
                  setValueNameVariable="serviceName"
                  value={proposalServiceDeliverable.serviceName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Deliverable name */}
                <FormDrawerTextField2
                  error={proposalServiceDeliverableUpdate.error}
                  fieldName="name"
                  label="Deliverable name"
                  labelInfo="(required)"
                  placeholder="Enter the deliverable's name"
                  setValueObject={setProposalServiceDeliverable}
                  setValueVariable="name"
                  value={proposalServiceDeliverable.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Deliverable type */}
                <FormDrawerTextField2
                  error={proposalServiceDeliverableUpdate.error}
                  fieldName="type"
                  helperText="For example: document, software, workshop"
                  icon="tag"
                  label="Deliverable type"
                  placeholder="Enter the deliverable's type"
                  setValueObject={setProposalServiceDeliverable}
                  setValueVariable="type"
                  value={proposalServiceDeliverable.type}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Template */}
                <FormDrawerFileField2
                  error={proposalServiceDeliverableUpdate.error}
                  fileName="template"
                  label="Template"
                  placeholder="Upload the template"
                  setValueObject={setProposalServiceDeliverable}
                  setValueFileVariable="template"
                  setValueNameVariable="templateName"
                  valueFile={proposalServiceDeliverable.template}
                  valueName={proposalServiceDeliverable.templateName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalServiceDeliverableUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the deliverable"
              setValueObject={setProposalServiceDeliverable}
              setValueVariable="description"
              value={proposalServiceDeliverable.description}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Due */}
                <FormDrawerDateField2
                  error={proposalServiceDeliverableUpdate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due"
                  placeholder="Enter the deliverable due date"
                  setValueObject={setProposalServiceDeliverable}
                  setValueVariable="due"
                  value={proposalServiceDeliverable.due}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Milestone */}
                <FormDrawerSelectField2
                  error={proposalServiceDeliverableUpdate.error}
                  fieldName="milestone"
                  icon="star-empty"
                  items={proposalMilestoneList.proposal_milestones}
                  label="Milestone"
                  loading={proposalMilestoneList.loading}
                  placeholder="Select a milestone"
                  query={proposalMilestoneQuery.searchString}
                  setQueryObject={setProposalMilestoneQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalServiceDeliverable}
                  setValueIdVariable="milestone"
                  setValueNameVariable="milestoneName"
                  value={proposalServiceDeliverable.milestoneName}
                />
              </div>
            </div>
          </Card>
        }
        deleteSuccess={proposalServiceDeliverableDelete.success}
        deleteSuccessMessage="Deliverable deleted."
        deleting={proposalServiceDeliverableDelete.loading}
        error={proposalServiceDeliverableUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update deliverable"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing deliverables"
        icon="edit"
        isOpen={updateDeliverableDrawerIsOpen}
        loading={proposalServiceDeliverableDetails.loading}
        onDelete={deleteDeliverable}
        onSave={updateDeliverable}
        refreshList={() => {
          dispatch(listProposalServiceDeliverablesAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));

          dispatch(listProposalMilestonesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalServiceDeliverableAction());

          dispatch(resetUpdateProposalServiceDeliverableAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalServiceDeliverableUpdate.success}
        saveSuccessMessage="Deliverable updated."
        saving={proposalServiceDeliverableUpdate.loading}
        setIsOpen={setUpdateDeliverableDrawerIsOpen}
        title="Update deliverable"
      />

      {/* New activity form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the activity</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={proposalServiceActivityCreate.error}
                  fieldName="service"
                  icon="heat-grid"
                  items={proposalServiceList.proposal_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={proposalServiceList.loading}
                  placeholder="Select a service"
                  query={proposalServiceQuery.searchString}
                  setQueryObject={setProposalServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalServiceActivity}
                  setValueIdVariable="service"
                  setValueNameVariable="serviceName"
                  value={proposalServiceActivity.serviceName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Activity name */}
                <FormDrawerTextField2
                  error={proposalServiceActivityCreate.error}
                  fieldName="name"
                  label="Activity name"
                  labelInfo="(required)"
                  placeholder="Enter the activity's name"
                  setValueObject={setProposalServiceActivity}
                  setValueVariable="name"
                  value={proposalServiceActivity.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalServiceActivityCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the activity"
              setValueObject={setProposalServiceActivity}
              setValueVariable="description"
              value={proposalServiceActivity.description}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Start */}
                <FormDrawerDateField2
                  error={proposalServiceActivityCreate.error}
                  fieldName="start"
                  icon="calendar"
                  label="Start"
                  placeholder="Enter the activity start date"
                  setValueObject={setProposalServiceActivity}
                  setValueVariable="start"
                  value={proposalServiceActivity.start}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* End */}
                <FormDrawerDateField2
                  error={proposalServiceActivityCreate.error}
                  fieldName="end"
                  icon="calendar"
                  label="End"
                  placeholder="Enter the activity end date"
                  setValueObject={setProposalServiceActivity}
                  setValueVariable="end"
                  value={proposalServiceActivity.end}
                />
              </div>
            </div>
          </Card>
        }
        error={proposalServiceActivityCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create activity"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Scheduling activities"
        icon="edit"
        isOpen={addActivityDrawerIsOpen}
        onSave={addActivity}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalServiceActivityAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalServiceActivityCreate.success}
        saveSuccessMessage="Activity created."
        saving={proposalServiceActivityCreate.loading}
        setIsOpen={setAddActivityDrawerIsOpen}
        title="Create activity"
      />

      {/* Existing activity form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the activity</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={proposalServiceActivityUpdate.error}
                  fieldName="service"
                  icon="heat-grid"
                  items={proposalServiceList.proposal_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={proposalServiceList.loading}
                  placeholder="Select a service"
                  query={proposalServiceQuery.searchString}
                  setQueryObject={setProposalServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalServiceActivity}
                  setValueIdVariable="service"
                  setValueNameVariable="serviceName"
                  value={proposalServiceActivity.serviceName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Activity name */}
                <FormDrawerTextField2
                  error={proposalServiceActivityUpdate.error}
                  fieldName="name"
                  label="Activity name"
                  labelInfo="(required)"
                  placeholder="Enter the activity's name"
                  setValueObject={setProposalServiceActivity}
                  setValueVariable="name"
                  value={proposalServiceActivity.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalServiceActivityUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the activity"
              setValueObject={setProposalServiceActivity}
              setValueVariable="description"
              value={proposalServiceActivity.description}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Start */}
                <FormDrawerDateField2
                  error={proposalServiceActivityUpdate.error}
                  fieldName="start"
                  icon="calendar"
                  label="Start"
                  placeholder="Enter the activity start date"
                  setValueObject={setProposalServiceActivity}
                  setValueVariable="start"
                  value={proposalServiceActivity.start}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* End */}
                <FormDrawerDateField2
                  error={proposalServiceActivityUpdate.error}
                  fieldName="end"
                  icon="calendar"
                  label="End"
                  placeholder="Enter the activity end date"
                  setValueObject={setProposalServiceActivity}
                  setValueVariable="end"
                  value={proposalServiceActivity.end}
                />
              </div>
            </div>
          </Card>
        }
        deleteSuccess={proposalServiceActivityDelete.success}
        deleteSuccessMessage="Activity deleted."
        deleting={proposalServiceActivityDelete.loading}
        error={proposalServiceActivityUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update activity"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Scheduling activities"
        icon="edit"
        isOpen={updateActivityDrawerIsOpen}
        loading={proposalServiceActivityDetails.loading}
        onDelete={deleteActivity}
        onSave={updateActivity}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalServiceActivityAction());

          dispatch(resetUpdateProposalServiceActivityAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalServiceActivityUpdate.success}
        saveSuccessMessage="Activity updated."
        saving={proposalServiceActivityUpdate.loading}
        setIsOpen={setUpdateActivityDrawerIsOpen}
        title="Update activity"
      />

      {/* New staff form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ margin: "0 0 15px 0" }}>
            <H5>Enter the staff's role</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Staff */}
                <FormDrawerSelectField2
                  error={proposalStaffCreate.error}
                  fieldName="staff"
                  icon="user"
                  items={staffList.staff}
                  label="Staff"
                  labelInfo="(required)"
                  loading={staffList.loading}
                  placeholder="Select a staff member"
                  query={staffQuery.searchString}
                  setQueryObject={setStaffQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalStaff}
                  setValueIdVariable="staff"
                  setValueNameVariable="staffName"
                  value={proposalStaff.staffName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Role */}
                <FormDrawerTextField2
                  error={proposalStaffCreate.error}
                  fieldName="role"
                  icon="hat"
                  label="Role"
                  labelInfo="(required)"
                  placeholder="Enter the role"
                  setValueObject={setProposalStaff}
                  setValueVariable="role"
                  value={proposalStaff.role}
                />
              </div>
            </div>

            {/* Responsibilities */}
            <FormDrawerTextAreaField3
              error={proposalStaffCreate.error}
              fieldName="responsibilities"
              label="Responsibilities"
              placeholder="Summarize areas of responsibility"
              setValueObject={setProposalStaff}
              setValueVariable="responsibilities"
              value={proposalStaff.responsibilities}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "33%" }}>
                {/* Area of expertise #1 */}
                <FormDrawerTextField2
                  error={proposalStaffCreate.error}
                  fieldName="expertise_1"
                  icon="badge"
                  label="Area of expertise #1"
                  placeholder="Enter an area of expertise"
                  setValueObject={setProposalStaff}
                  setValueVariable="expertise_1"
                  value={proposalStaff.expertise_1}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "33%",
                }}
              >
                {/* Area of expertise #2 */}
                <FormDrawerTextField2
                  error={proposalStaffCreate.error}
                  fieldName="expertise_2"
                  label="Area of expertise #2"
                  icon="badge"
                  placeholder="Enter an area of expertise"
                  setValueObject={setProposalStaff}
                  setValueVariable="expertise_2"
                  value={proposalStaff.expertise_2}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "33%" }}>
                {/* Area of expertise #3 */}
                <FormDrawerTextField2
                  error={proposalStaffCreate.error}
                  fieldName="expertise_3"
                  label="Area of expertise #3"
                  icon="badge"
                  placeholder="Enter an area of expertise"
                  setValueObject={setProposalStaff}
                  setValueVariable="expertise_3"
                  value={proposalStaff.expertise_3}
                />
              </div>
            </div>

            {/* Relevant experience */}
            <FormDrawerTextAreaField3
              error={proposalStaffCreate.error}
              fieldName="experience"
              label="Relevant experience"
              placeholder="Summarize the staff's experience in similar engagements"
              setValueObject={setProposalStaff}
              setValueVariable="experience"
              value={proposalStaff.experience}
            />
          </Card>
        }
        error={proposalStaffCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add staff"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Selecting the core team"
        icon="edit"
        isOpen={addStaffDrawerIsOpen}
        onSave={addStaff}
        refreshList={() => {
          dispatch(listProposalStaffAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalStaffAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalStaffCreate.success}
        saveSuccessMessage="Staff added."
        saving={proposalStaffCreate.loading}
        setIsOpen={setAddStaffDrawerIsOpen}
        title="Add staff"
      />

      {/* Existing staff form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ margin: "0 0 15px 0" }}>
            <H5>Enter the staff's role</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Staff */}
                <FormDrawerSelectField2
                  error={proposalStaffUpdate.error}
                  fieldName="staff"
                  icon="user"
                  items={staffList.staff}
                  label="Staff"
                  labelInfo="(required)"
                  loading={staffList.loading}
                  placeholder="Select a staff member"
                  query={staffQuery.searchString}
                  setQueryObject={setStaffQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalStaff}
                  setValueIdVariable="staff"
                  setValueNameVariable="staffName"
                  value={proposalStaff.staffName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Role */}
                <FormDrawerTextField2
                  error={proposalStaffUpdate.error}
                  fieldName="role"
                  icon="hat"
                  label="Role"
                  labelInfo="(required)"
                  placeholder="Enter the role"
                  setValueObject={setProposalStaff}
                  setValueVariable="role"
                  value={proposalStaff.role}
                />
              </div>
            </div>

            {/* Responsibilities */}
            <FormDrawerTextAreaField3
              error={proposalStaffUpdate.error}
              fieldName="responsibilities"
              label="Responsibilities"
              placeholder="Summarize areas of responsibility"
              setValueObject={setProposalStaff}
              setValueVariable="responsibilities"
              value={proposalStaff.responsibilities}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "33%" }}>
                {/* Area of expertise #1 */}
                <FormDrawerTextField2
                  error={proposalStaffUpdate.error}
                  fieldName="expertise_1"
                  icon="badge"
                  label="Area of expertise #1"
                  placeholder="Enter an area of expertise"
                  setValueObject={setProposalStaff}
                  setValueVariable="expertise_1"
                  value={proposalStaff.expertise_1}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "33%",
                }}
              >
                {/* Area of expertise #2 */}
                <FormDrawerTextField2
                  error={proposalStaffUpdate.error}
                  fieldName="expertise_2"
                  label="Area of expertise #2"
                  icon="badge"
                  placeholder="Enter an area of expertise"
                  setValueObject={setProposalStaff}
                  setValueVariable="expertise_2"
                  value={proposalStaff.expertise_2}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "33%" }}>
                {/* Area of expertise #3 */}
                <FormDrawerTextField2
                  error={proposalStaffUpdate.error}
                  fieldName="expertise_3"
                  label="Area of expertise #3"
                  icon="badge"
                  placeholder="Enter an area of expertise"
                  setValueObject={setProposalStaff}
                  setValueVariable="expertise_3"
                  value={proposalStaff.expertise_3}
                />
              </div>
            </div>

            {/* Relevant experience */}
            <FormDrawerTextAreaField3
              error={proposalStaffUpdate.error}
              fieldName="experience"
              label="Relevant experience"
              placeholder="Summarize the staff's experience in similar engagements"
              setValueObject={setProposalStaff}
              setValueVariable="experience"
              value={proposalStaff.experience}
            />
          </Card>
        }
        deleteSuccess={proposalStaffDelete.success}
        deleteSuccessMessage="Staff removed."
        deleting={proposalStaffDelete.loading}
        error={proposalStaffUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update staff"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Selecting the core team"
        icon="edit"
        isOpen={updateStaffDrawerIsOpen}
        loading={proposalStaffDetails.loading}
        onDelete={deleteStaff}
        onSave={updateStaff}
        refreshList={() => {
          dispatch(listProposalStaffAction({ ...proposalStaffQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalStaffAction());

          dispatch(resetUpdateProposalStaffAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalStaffUpdate.success}
        saveSuccessMessage="Staff updated."
        saving={proposalStaffUpdate.loading}
        setIsOpen={setUpdateStaffDrawerIsOpen}
        title="Update staff"
      />

      {/* New labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
                width: "66%",
              }}
            >
              <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
                <H5>Enter the labour expense details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Role */}
                    <FormDrawerSelectField2
                      error={proposalLabourExpenseCreate.error}
                      fieldName="role"
                      helperText="You can choose from your organisation's standard roles"
                      icon="people"
                      info={
                        <RoleInfo
                          roleId={proposalLabourExpense.role}
                          query={roleQueryDefault}
                        />
                      }
                      items={roleList.roles}
                      label="Role"
                      labelInfo="(required)"
                      loading={roleList.loading}
                      placeholder="Select a role"
                      query={roleQuery.searchString}
                      setQueryObject={setRoleQuery}
                      setQueryVariable="searchString"
                      setValueObject={setProposalLabourExpense}
                      setValueIdVariable="role"
                      setValueNameVariable="roleName"
                      showInfo={proposalLabourExpense.role}
                      value={proposalLabourExpense.roleName}
                    />

                    {/* Service */}
                    <FormDrawerSelectField2
                      error={proposalLabourExpenseCreate.error}
                      fieldName="proposal_service"
                      icon="heat-grid"
                      info={
                        <ProposalServiceInfo
                          proposalServiceId={
                            proposalLabourExpense.proposalService
                          }
                          query={proposalServiceQueryDefault}
                        />
                      }
                      helperText="You can choose services that you have added to the proposal"
                      items={proposalServiceList.proposal_services}
                      label="Service"
                      labelInfo="(required)"
                      loading={proposalServiceList.loading}
                      placeholder="Select a service"
                      query={proposalServiceQuery.searchString}
                      setQueryObject={setProposalServiceQuery}
                      setQueryVariable="searchString"
                      setValueObject={setProposalLabourExpense}
                      setValueIdVariable="proposalService"
                      setValueNameVariable="proposalServiceName"
                      showInfo={proposalLabourExpense.proposalService}
                      value={
                        proposalLabourExpense.proposalServiceName.split(
                          " | "
                        )[0]
                      }
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Effort */}
                        <FormDrawerDurationField
                          error={proposalLabourExpenseCreate.error}
                          fieldName="minutes"
                          helperText="Expected effort in hours"
                          icon="stopwatch"
                          label="Effort"
                          min={0.1}
                          placeholder="Enter the number of working hours"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="minutes"
                          value={proposalLabourExpense.minutes}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {/* Margin */}
                        <FormDrawerNumericField2
                          error={proposalLabourExpenseCreate.error}
                          fieldName="margin_percentage"
                          helperText="Leave blank to use the organization's default margin"
                          icon="percentage"
                          label="Margin"
                          majorStepSize={20}
                          min={0}
                          minorStepSize={1}
                          placeholder="Enter the margin to apply"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="marginPercentage"
                          stepSize={10}
                          value={proposalLabourExpense.marginPercentage}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Notes */}
                    <FormDrawerTextAreaField3
                      error={proposalLabourExpenseCreate.error}
                      fieldName="notes"
                      label="Notes"
                      placeholder="Enter your notes on the estimate"
                      setValueObject={setProposalLabourExpense}
                      setValueVariable="notes"
                      value={proposalLabourExpense.notes}
                    />
                  </div>
                </div>
              </Card>

              <Card style={{ flex: 1, width: "100%" }}>
                <H5>Enter the resource booking details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <>
                    <FormDrawerStaffFreeTimeSearch2
                      customiseDays={proposalLabourExpense.customiseDays}
                      detail={proposalLabourExpense.detail}
                      endDate={proposalLabourExpense.finish}
                      minutes={proposalLabourExpense.minutes}
                      setStaffQueryObject={setStaffQuery}
                      startDate={proposalLabourExpense.start}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "33%" }}>
                        {/* Start */}
                        <FormDrawerDateField2
                          error={proposalLabourExpenseCreate.error}
                          fieldName="start"
                          icon="calendar"
                          label="Start"
                          placeholder="Enter the role starting date"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="start"
                          value={proposalLabourExpense.start}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "33%",
                        }}
                      >
                        {/* Finish */}
                        <FormDrawerDateField2
                          error={proposalLabourExpenseCreate.error}
                          fieldName="finish"
                          icon="calendar"
                          label="Finish"
                          placeholder="Enter the role finishing date"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="finish"
                          value={proposalLabourExpense.finish}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          paddingTop: "30px",
                          width: "34%",
                        }}
                      >
                        {/* Customise days */}
                        <Switch
                          checked={
                            proposalLabourExpense.customiseDays &&
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === proposalLabourExpense.staff
                            ).length > 0
                          }
                          disabled={
                            !proposalLabourExpense.finish ||
                            !proposalLabourExpense.minutes ||
                            !proposalLabourExpense.staff ||
                            !proposalLabourExpense.start ||
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === proposalLabourExpense.staff
                            ).length === 0
                          }
                          label="Customise days"
                          onChange={() => {
                            setProposalLabourExpense((prevState) => {
                              return {
                                ...prevState,
                                customiseDays:
                                  !proposalLabourExpense.customiseDays,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <FormDrawerLabourExpenseDaysField2
                      bookingProfile={
                        proposalLabourExpense.staff &&
                        staffList.staff.filter(
                          (staff) => staff.id === proposalLabourExpense.staff
                        ).length > 0
                          ? staffList.staff.filter(
                              (staff) =>
                                staff.id === proposalLabourExpense.staff
                            )[0].booking_profile
                          : undefined
                      }
                      customiseDays={proposalLabourExpense.customiseDays}
                      setValueObject={setProposalLabourExpense}
                      valueObject={proposalLabourExpense}
                    />

                    <Divider
                      style={{
                        margin: proposalLabourExpense.customiseDays
                          ? "15px 0 15px 0"
                          : "0 0 15px 0",
                      }}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Staff */}
                        <FormDrawerSelectField2
                          error={proposalLabourExpenseCreate.error}
                          fieldName="staff"
                          icon="user"
                          info={
                            <StaffInfo
                              customCompetencyProfile={
                                proposalLabourExpense.competencies
                              }
                              query={staffQueryDefault}
                              staffId={proposalLabourExpense.staff}
                            />
                          }
                          items={staffList.staff}
                          label="Staff"
                          loading={staffList.loading}
                          noOptionOnClick
                          placeholder="Select a staff member"
                          query={staffQuery.searchString}
                          setQueryObject={setStaffQuery}
                          setQueryVariable="searchString"
                          setValueObject={setProposalLabourExpense}
                          setValueIdVariable="staff"
                          setValueNameVariable="staffName"
                          showInfo={proposalLabourExpense.staff}
                          value={proposalLabourExpense.staffName}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {!staffList.loading && (
                          <Callout
                            intent={
                              proposalLabourExpense.staff
                                ? staffList.staff.filter(
                                    (staff) =>
                                      staff.id === proposalLabourExpense.staff
                                  ).length === 0
                                  ? "danger"
                                  : "success"
                                : staffList.count === 0
                                ? "danger"
                                : "success"
                            }
                            style={{ margin: "0 0 15px 0" }}
                          >
                            {proposalLabourExpense.staff
                              ? staffList.staff.filter(
                                  (staff) =>
                                    staff.id === proposalLabourExpense.staff
                                ).length === 0
                                ? "Your selected team member doesn't fit this role."
                                : "Your selected team member fits this role."
                              : staffList.count === 0
                              ? "The system found no suitable candidates."
                              : `The system found ${staffList.count} suitable candidate(s).`}
                          </Callout>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                width: "34%",
              }}
            >
              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the type of employment</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <>
                    {/* Employment */}
                    <FormDrawerStaffEmploymentSearch
                      employment={proposalLabourExpense.employment}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectSimpleField3
                      error={proposalLabourExpenseCreate.error}
                      fieldName="employment"
                      options={[
                        { id: 1, icon: "full-circle", value: "Permanent" },
                        {
                          id: 2,
                          icon: "ring",
                          value: "Contract",
                        },
                        { id: 3, icon: "circle", value: "Casual" },
                      ]}
                      setValueObject={setProposalLabourExpense}
                      setValueVariable="employment"
                      value={proposalLabourExpense.employment}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the location</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <>
                    {/* Location */}
                    <FormDrawerStaffLocationSearch
                      location={proposalLabourExpense.location}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectField2
                      error={proposalLabourExpenseCreate.error}
                      fieldName="location"
                      icon="area-of-interest"
                      info={
                        <LocationInfo
                          locationId={proposalLabourExpense.location}
                          query={locationQueryDefault}
                        />
                      }
                      items={locationList.locations}
                      loading={locationList.loading}
                      noOptionOnClick
                      placeholder="Select a location"
                      query={locationQuery.searchString}
                      setQueryObject={setLocationQuery}
                      setQueryVariable="searchString"
                      setValueObject={setProposalLabourExpense}
                      setValueIdVariable="location"
                      setValueNameVariable="locationName"
                      showInfo={proposalLabourExpense.location}
                      value={proposalLabourExpense.locationName}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  flex: 1,
                  width: "100%",
                }}
              >
                <H5>Review the skills needed</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <FormDrawerStaffCompetencySearch
                    competencies={proposalLabourExpense.competencies}
                    competencyList={competencyList}
                    competencyQuery={competencyQuery}
                    roleId={proposalLabourExpense.role}
                    setCompetenciesObject={setProposalLabourExpense}
                    setCompetenciesVariable="competencies"
                    setCompetencyQuery={setCompetencyQuery}
                    setStaffQueryObject={setStaffQuery}
                  />
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>
          </div>
        }
        error={proposalLabourExpenseCreate.error}
        height="90vh"
        helpContent={
          <FormDrawerHelpContainer
            form="Add labour expense"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Adding labour expenses"
        icon="edit"
        isOpen={addLabourExpenseDrawerIsOpen}
        onSave={addLabourExpense}
        refreshList={() => {
          dispatch(listProposalLabourExpensesAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));

          dispatch(listProposalFeesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalLabourExpenseCreate.success}
        saveSuccessMessage="Labour expense added."
        saving={proposalLabourExpenseCreate.loading}
        setIsOpen={setAddLabourExpenseDrawerIsOpen}
        size="large"
        title="Add labour expense"
      />

      {/* Existing labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
                width: "66%",
              }}
            >
              <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
                <H5>Enter the labour expense details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Role */}
                    <FormDrawerSelectField2
                      error={proposalLabourExpenseUpdate.error}
                      fieldName="role"
                      helperText="You can choose from your organisation's standard roles"
                      icon="people"
                      info={
                        <RoleInfo
                          roleId={proposalLabourExpense.role}
                          query={roleQueryDefault}
                        />
                      }
                      items={roleList.roles}
                      label="Role"
                      labelInfo="(required)"
                      loading={roleList.loading}
                      placeholder="Select a role"
                      query={roleQuery.searchString}
                      setQueryObject={setRoleQuery}
                      setQueryVariable="searchString"
                      setValueObject={setProposalLabourExpense}
                      setValueIdVariable="role"
                      setValueNameVariable="roleName"
                      showInfo={proposalLabourExpense.role}
                      value={proposalLabourExpense.roleName}
                    />

                    {/* Service */}
                    <FormDrawerSelectField2
                      error={proposalLabourExpenseUpdate.error}
                      fieldName="proposal_service"
                      icon="heat-grid"
                      info={
                        <ProposalServiceInfo
                          proposalServiceId={
                            proposalLabourExpense.proposalService
                          }
                          query={proposalServiceQueryDefault}
                        />
                      }
                      helperText="You can choose services that you have added to the proposal"
                      items={proposalServiceList.proposal_services}
                      label="Service"
                      labelInfo="(required)"
                      loading={proposalServiceList.loading}
                      placeholder="Select a service"
                      query={proposalServiceQuery.searchString}
                      setQueryObject={setProposalServiceQuery}
                      setQueryVariable="searchString"
                      setValueObject={setProposalLabourExpense}
                      setValueIdVariable="proposalService"
                      setValueNameVariable="proposalServiceName"
                      showInfo={proposalLabourExpense.proposalService}
                      value={
                        proposalLabourExpense.proposalServiceName.split(
                          " | "
                        )[0]
                      }
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Effort */}
                        <FormDrawerDurationField
                          error={proposalLabourExpenseUpdate.error}
                          fieldName="minutes"
                          helperText="Expected effort in hours"
                          icon="stopwatch"
                          label="Effort"
                          min={0.1}
                          placeholder="Enter the number of working hours"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="minutes"
                          value={proposalLabourExpense.minutes}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {/* Margin */}
                        <FormDrawerNumericField2
                          error={proposalLabourExpenseUpdate.error}
                          fieldName="margin_percentage"
                          helperText="Leave blank to use the organization's default margin"
                          icon="percentage"
                          label="Margin"
                          majorStepSize={20}
                          min={0}
                          minorStepSize={1}
                          placeholder="Enter the margin to apply"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="marginPercentage"
                          stepSize={10}
                          value={proposalLabourExpense.marginPercentage}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Notes */}
                    <FormDrawerTextAreaField3
                      error={proposalLabourExpenseUpdate.error}
                      fieldName="notes"
                      label="Notes"
                      placeholder="Enter your notes on the estimate"
                      setValueObject={setProposalLabourExpense}
                      setValueVariable="notes"
                      value={proposalLabourExpense.notes}
                    />
                  </div>
                </div>
              </Card>

              <Card style={{ flex: 1, width: "100%" }}>
                <H5>Enter the resource booking details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <>
                    <FormDrawerStaffFreeTimeSearch2
                      customiseDays={proposalLabourExpense.customiseDays}
                      detail={proposalLabourExpense.detail}
                      endDate={proposalLabourExpense.finish}
                      minutes={proposalLabourExpense.minutes}
                      setStaffQueryObject={setStaffQuery}
                      startDate={proposalLabourExpense.start}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "33%" }}>
                        {/* Start */}
                        <FormDrawerDateField2
                          error={proposalLabourExpenseUpdate.error}
                          fieldName="start"
                          icon="calendar"
                          label="Start"
                          placeholder="Enter the role starting date"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="start"
                          value={proposalLabourExpense.start}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "33%",
                        }}
                      >
                        {/* Finish */}
                        <FormDrawerDateField2
                          error={proposalLabourExpenseUpdate.error}
                          fieldName="finish"
                          icon="calendar"
                          label="Finish"
                          placeholder="Enter the role finishing date"
                          setValueObject={setProposalLabourExpense}
                          setValueVariable="finish"
                          value={proposalLabourExpense.finish}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          paddingTop: "30px",
                          width: "34%",
                        }}
                      >
                        {/* Customise days */}
                        <Switch
                          checked={
                            proposalLabourExpense.customiseDays &&
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === proposalLabourExpense.staff
                            ).length > 0
                          }
                          disabled={
                            !proposalLabourExpense.finish ||
                            !proposalLabourExpense.minutes ||
                            !proposalLabourExpense.staff ||
                            !proposalLabourExpense.start ||
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === proposalLabourExpense.staff
                            ).length === 0
                          }
                          label="Customise days"
                          onChange={() => {
                            setProposalLabourExpense((prevState) => {
                              return {
                                ...prevState,
                                customiseDays:
                                  !proposalLabourExpense.customiseDays,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <FormDrawerLabourExpenseDaysField2
                      bookingProfile={
                        proposalLabourExpense.staff &&
                        staffList.staff.filter(
                          (staff) => staff.id === proposalLabourExpense.staff
                        ).length > 0
                          ? staffList.staff.filter(
                              (staff) =>
                                staff.id === proposalLabourExpense.staff
                            )[0].booking_profile
                          : undefined
                      }
                      customiseDays={proposalLabourExpense.customiseDays}
                      setValueObject={setProposalLabourExpense}
                      valueObject={proposalLabourExpense}
                    />

                    <Divider
                      style={{
                        margin: proposalLabourExpense.customiseDays
                          ? "15px 0 15px 0"
                          : "0 0 15px 0",
                      }}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Staff */}
                        <FormDrawerSelectField2
                          error={proposalLabourExpenseCreate.error}
                          fieldName="staff"
                          icon="user"
                          info={
                            <StaffInfo
                              customCompetencyProfile={
                                proposalLabourExpense.competencies
                              }
                              query={staffQueryDefault}
                              staffId={proposalLabourExpense.staff}
                            />
                          }
                          items={staffList.staff}
                          label="Staff"
                          loading={staffList.loading}
                          noOptionOnClick
                          placeholder="Select a staff member"
                          query={staffQuery.searchString}
                          setQueryObject={setStaffQuery}
                          setQueryVariable="searchString"
                          setValueObject={setProposalLabourExpense}
                          setValueIdVariable="staff"
                          setValueNameVariable="staffName"
                          showInfo={proposalLabourExpense.staff}
                          value={proposalLabourExpense.staffName}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {!staffList.loading && (
                          <Callout
                            intent={
                              proposalLabourExpense.staff
                                ? staffList.staff.filter(
                                    (staff) =>
                                      staff.id === proposalLabourExpense.staff
                                  ).length === 0
                                  ? "danger"
                                  : "success"
                                : staffList.count === 0
                                ? "danger"
                                : "success"
                            }
                            style={{ margin: "0 0 15px 0" }}
                          >
                            {proposalLabourExpense.staff
                              ? staffList.staff.filter(
                                  (staff) =>
                                    staff.id === proposalLabourExpense.staff
                                ).length === 0
                                ? "Your selected team member doesn't fit this role."
                                : "Your selected team member fits this role."
                              : staffList.count === 0
                              ? "The system found no suitable candidates."
                              : `The system found ${staffList.count} suitable candidate(s).`}
                          </Callout>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                width: "34%",
              }}
            >
              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the type of employment</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <>
                    {/* Employment */}
                    <FormDrawerStaffEmploymentSearch
                      employment={proposalLabourExpense.employment}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectSimpleField3
                      error={proposalLabourExpenseUpdate.error}
                      fieldName="employment"
                      options={[
                        { id: 1, icon: "full-circle", value: "Permanent" },
                        {
                          id: 2,
                          icon: "ring",
                          value: "Contract",
                        },
                        { id: 3, icon: "circle", value: "Casual" },
                      ]}
                      setValueObject={setProposalLabourExpense}
                      setValueVariable="employment"
                      value={proposalLabourExpense.employment}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the location</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <>
                    {/* Location */}
                    <FormDrawerStaffLocationSearch
                      location={proposalLabourExpense.location}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectField2
                      error={proposalLabourExpenseUpdate.error}
                      fieldName="location"
                      icon="area-of-interest"
                      info={
                        <LocationInfo
                          locationId={proposalLabourExpense.location}
                          query={locationQueryDefault}
                        />
                      }
                      items={locationList.locations}
                      loading={locationList.loading}
                      noOptionOnClick
                      placeholder="Select a location"
                      query={locationQuery.searchString}
                      setQueryObject={setLocationQuery}
                      setQueryVariable="searchString"
                      setValueObject={setProposalLabourExpense}
                      setValueIdVariable="location"
                      setValueNameVariable="locationName"
                      showInfo={proposalLabourExpense.location}
                      value={proposalLabourExpense.locationName}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  flex: 1,
                  width: "100%",
                }}
              >
                <H5>Review the skills needed</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {proposalLabourExpense.role ? (
                  <FormDrawerStaffCompetencySearch
                    competencies={proposalLabourExpense.competencies}
                    competencyList={competencyList}
                    competencyQuery={competencyQuery}
                    roleId={proposalLabourExpense.role}
                    setCompetenciesObject={setProposalLabourExpense}
                    setCompetenciesVariable="competencies"
                    setCompetencyQuery={setCompetencyQuery}
                    setStaffQueryObject={setStaffQuery}
                  />
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>
          </div>
        }
        deleteSuccess={proposalLabourExpenseDelete.success}
        deleteSuccessMessage="Labour expense removed."
        deleting={proposalLabourExpenseDelete.loading}
        error={proposalLabourExpenseUpdate.error}
        height="90vh"
        helpContent={
          <FormDrawerHelpContainer
            form="Update labour expense"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Adding labour expenses"
        icon="edit"
        isOpen={updateLabourExpenseDrawerIsOpen}
        loading={proposalLabourExpenseDetails.loading}
        onDelete={deleteLabourExpense}
        onSave={updateLabourExpense}
        refreshList={() => {
          dispatch(
            listProposalLabourExpensesAction({ ...proposalLabourExpenseQuery })
          );

          dispatch(listProposalDetailsAction(id));

          dispatch(listProposalFeesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalLabourExpenseAction());

          dispatch(resetUpdateProposalLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalLabourExpenseUpdate.success}
        saveSuccessMessage="Labour expense updated."
        saving={proposalLabourExpenseUpdate.loading}
        setIsOpen={setUpdateLabourExpenseDrawerIsOpen}
        size="large"
        title="Update labour expense"
      />

      {/* New non-labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the non-labour expense details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectField2
                  error={proposalNonLabourExpenseCreate.error}
                  fieldName="category"
                  icon="bank-account"
                  info={
                    <ExpenseCategoryInfo
                      categoryId={proposalNonLabourExpense.category}
                      query={expenseCategoryQueryDefault}
                    />
                  }
                  items={expenseCategoryList.expense_categories}
                  label="Category"
                  labelInfo="(required)"
                  loading={expenseCategoryList.loading}
                  placeholder="Select a category"
                  query={expenseCategoryQuery.searchString}
                  setQueryObject={setExpenseCategoryQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalNonLabourExpense}
                  setValueIdVariable="category"
                  setValueNameVariable="categoryName"
                  showInfo={proposalNonLabourExpense.category}
                  value={proposalNonLabourExpense.categoryName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={proposalNonLabourExpenseCreate.error}
                  fieldName="proposal_service"
                  icon="heat-grid"
                  helperText="You can choose services that you have added to the proposal"
                  items={proposalServiceList.proposal_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={proposalServiceList.loading}
                  placeholder="Select a service"
                  query={proposalServiceQuery.searchString}
                  setQueryObject={setProposalServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalNonLabourExpense}
                  setValueIdVariable="proposalService"
                  setValueNameVariable="proposalServiceName"
                  value={
                    proposalNonLabourExpense.proposalServiceName.split(" | ")[0]
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Cost */}
                <FormDrawerNumericField2
                  error={proposalNonLabourExpenseCreate.error}
                  fieldName="cost"
                  label="Cost"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={10000}
                  min={0}
                  minorStepSize={100}
                  placeholder="Enter the amount"
                  setValueObject={setProposalNonLabourExpense}
                  setValueVariable="cost"
                  stepSize={1000}
                  value={proposalNonLabourExpense.cost}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Margin */}
                <FormDrawerNumericField2
                  error={proposalNonLabourExpenseCreate.error}
                  fieldName="margin_percentage"
                  helperText="Leave blank to use the organization's default margin"
                  icon="percentage"
                  label="Margin"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the margin to apply"
                  setValueObject={setProposalNonLabourExpense}
                  setValueVariable="marginPercentage"
                  stepSize={10}
                  value={proposalNonLabourExpense.marginPercentage}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={proposalNonLabourExpenseCreate.error}
              fieldName="notes"
              label="Assumptions"
              placeholder="Enter your notes on the estimate"
              setValueObject={setProposalNonLabourExpense}
              setValueVariable="notes"
              value={proposalNonLabourExpense.notes}
            />
          </Card>
        }
        error={proposalNonLabourExpenseCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add non-labour expense"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Adding non-labour expenses"
        icon="edit"
        isOpen={addNonLabourExpenseDrawerIsOpen}
        onSave={addNonLabourExpense}
        refreshList={() => {
          dispatch(listProposalNonLabourExpensesAction({ proposal: id }));

          dispatch(listProposalDetailsAction(id));

          dispatch(listProposalFeesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalNonLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalNonLabourExpenseCreate.success}
        saveSuccessMessage="Non-labour expense added."
        saving={proposalNonLabourExpenseCreate.loading}
        setIsOpen={setAddNonLabourExpenseDrawerIsOpen}
        title="Add non-labour expense"
      />

      {/* Existing non-labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the non-labour expense details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectField2
                  error={proposalNonLabourExpenseUpdate.error}
                  fieldName="category"
                  icon="bank-account"
                  info={
                    <ExpenseCategoryInfo
                      categoryId={proposalNonLabourExpense.category}
                      query={expenseCategoryQueryDefault}
                    />
                  }
                  items={expenseCategoryList.expense_categories}
                  label="Category"
                  labelInfo="(required)"
                  loading={expenseCategoryList.loading}
                  placeholder="Select a category"
                  query={expenseCategoryQuery.searchString}
                  setQueryObject={setExpenseCategoryQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalNonLabourExpense}
                  setValueIdVariable="category"
                  setValueNameVariable="categoryName"
                  showInfo={proposalNonLabourExpense.category}
                  value={proposalNonLabourExpense.categoryName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={proposalNonLabourExpenseUpdate.error}
                  fieldName="proposal_service"
                  icon="heat-grid"
                  helperText="You can choose services that you have added to the proposal"
                  items={proposalServiceList.proposal_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={proposalServiceList.loading}
                  placeholder="Select a service"
                  query={proposalServiceQuery.searchString}
                  setQueryObject={setProposalServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setProposalNonLabourExpense}
                  setValueIdVariable="proposalService"
                  setValueNameVariable="proposalServiceName"
                  value={
                    proposalNonLabourExpense.proposalServiceName.split(" | ")[0]
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Cost */}
                <FormDrawerNumericField2
                  error={proposalNonLabourExpenseUpdate.error}
                  fieldName="cost"
                  label="Cost"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={10000}
                  min={0}
                  minorStepSize={100}
                  placeholder="Enter the amount"
                  setValueObject={setProposalNonLabourExpense}
                  setValueVariable="cost"
                  stepSize={1000}
                  value={proposalNonLabourExpense.cost}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Margin */}
                <FormDrawerNumericField2
                  error={proposalNonLabourExpenseUpdate.error}
                  fieldName="margin_percentage"
                  helperText="Leave blank to use the organization's default margin"
                  icon="percentage"
                  label="Margin"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the margin to apply"
                  setValueObject={setProposalNonLabourExpense}
                  setValueVariable="marginPercentage"
                  stepSize={10}
                  value={proposalNonLabourExpense.marginPercentage}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={proposalNonLabourExpenseUpdate.error}
              fieldName="notes"
              label="Assumptions"
              placeholder="Enter your notes on the estimate"
              setValueObject={setProposalNonLabourExpense}
              setValueVariable="notes"
              value={proposalNonLabourExpense.notes}
            />
          </Card>
        }
        deleteSuccess={proposalNonLabourExpenseDelete.success}
        deleteSuccessMessage="Non-labour expense removed."
        deleting={proposalNonLabourExpenseDelete.loading}
        error={proposalNonLabourExpenseUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update non-labour expense"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Adding non-labour expenses"
        icon="edit"
        isOpen={updateNonLabourExpenseDrawerIsOpen}
        loading={proposalNonLabourExpenseDetails.loading}
        onDelete={deleteNonLabourExpense}
        onSave={updateNonLabourExpense}
        refreshList={() => {
          dispatch(
            listProposalNonLabourExpensesAction({
              ...proposalLabourExpenseQuery,
            })
          );

          dispatch(listProposalFeesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalNonLabourExpenseAction());

          dispatch(listProposalDetailsAction(id));

          dispatch(resetUpdateProposalNonLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalNonLabourExpenseUpdate.success}
        saveSuccessMessage="Non-labour expense updated."
        saving={proposalNonLabourExpenseUpdate.loading}
        setIsOpen={setUpdateNonLabourExpenseDrawerIsOpen}
        title="Update non-labour expense"
      />

      {/* New risk form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the risk</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={proposalRiskCreate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the risk a title"
                    setValueObject={setProposalRisk}
                    setValueVariable="name"
                    value={proposalRisk.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskCreate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "database", value: "Information" },
                      { id: 2, icon: "briefcase", value: "Partners" },
                      { id: 3, icon: "flow-end", value: "Process" },
                      { id: 4, icon: "people", value: "Team" },
                      { id: 5, icon: "cloud-download", value: "Technology" },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="category"
                    value={proposalRisk.category}
                  />
                </div>
              </div>

              {/* Risk */}
              <FormDrawerTextAreaField3
                error={proposalRiskCreate.error}
                fieldName="risk"
                height="100px"
                label="Risk"
                labelInfo="(required)"
                placeholder="Describe the risk"
                setValueObject={setProposalRisk}
                setValueVariable="risk"
                value={proposalRisk.risk}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Impact */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskCreate.error}
                    fieldName="impact"
                    helperText="Severity of risk (High: Unacceptable; Medium: Significant; Low: Immaterial)"
                    intent={
                      proposalRisk.impact === "High"
                        ? "danger"
                        : proposalRisk.impact === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Impact"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="impact"
                    value={proposalRisk.impact}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Likelihood */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskCreate.error}
                    fieldName="likelihood"
                    helperText="Probability of risk (High: > 75%; Medium: 25% - 75%; Low: < 25%)"
                    intent={
                      proposalRisk.likelihood === "High"
                        ? "danger"
                        : proposalRisk.likelihood === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Likelihood"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="likelihood"
                    value={proposalRisk.likelihood}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Define the risk management strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={proposalRiskUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalRisk}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={proposalRisk.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskCreate.error}
                    fieldName="status"
                    intent={
                      proposalRisk.status === "Closed" ? "success" : "danger"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue-closed",
                        value: "Closed",
                      },
                      {
                        id: 2,
                        icon: "issue",
                        value: "Open",
                      },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="status"
                    value={proposalRisk.status}
                  />
                </div>
              </div>

              {/* Strategy */}
              <FormDrawerTextAreaField3
                error={proposalRiskCreate.error}
                fieldName="strategy"
                label="Strategy"
                placeholder="Describe how you will avoid and/or mitigate the risk"
                setValueObject={setProposalRisk}
                setValueVariable="strategy"
                value={proposalRisk.strategy}
              />
            </Card>
          </div>
        }
        error={proposalRiskCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add risk"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Shaping your risk management strategy"
        icon="edit"
        isOpen={addRiskDrawerIsOpen}
        onSave={addRisk}
        refreshList={() => {
          dispatch(listProposalRisksAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalRiskAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalRiskCreate.success}
        saveSuccessMessage="Risk added."
        saving={proposalRiskCreate.loading}
        setIsOpen={setAddRiskDrawerIsOpen}
        title="Add risk"
      />

      {/* Existing risk form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the risk</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={proposalRiskUpdate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the risk a title"
                    setValueObject={setProposalRisk}
                    setValueVariable="name"
                    value={proposalRisk.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "database", value: "Information" },
                      { id: 2, icon: "briefcase", value: "Partners" },
                      { id: 3, icon: "flow-end", value: "Process" },
                      { id: 4, icon: "people", value: "Team" },
                      { id: 5, icon: "cloud-download", value: "Technology" },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="category"
                    value={proposalRisk.category}
                  />
                </div>
              </div>

              {/* Risk */}
              <FormDrawerTextAreaField3
                error={proposalRiskUpdate.error}
                fieldName="risk"
                height="100px"
                label="Risk"
                labelInfo="(required)"
                placeholder="Describe the risk"
                setValueObject={setProposalRisk}
                setValueVariable="risk"
                value={proposalRisk.risk}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Impact */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskUpdate.error}
                    fieldName="impact"
                    helperText="Severity of risk (High: Unacceptable; Medium: Significant; Low: Immaterial)"
                    intent={
                      proposalRisk.impact === "High"
                        ? "danger"
                        : proposalRisk.impact === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Impact"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="impact"
                    value={proposalRisk.impact}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Likelihood */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskUpdate.error}
                    fieldName="likelihood"
                    helperText="Probability of risk (High: > 75%; Medium: 25% - 75%; Low: < 25%)"
                    intent={
                      proposalRisk.likelihood === "High"
                        ? "danger"
                        : proposalRisk.likelihood === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Likelihood"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="likelihood"
                    value={proposalRisk.likelihood}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Define the risk management strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={proposalRiskUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setProposalRisk}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={proposalRisk.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalRiskUpdate.error}
                    fieldName="status"
                    intent={
                      proposalRisk.status === "Closed" ? "success" : "danger"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue-closed",
                        value: "Closed",
                      },
                      {
                        id: 2,
                        icon: "issue",
                        value: "Open",
                      },
                    ]}
                    setValueObject={setProposalRisk}
                    setValueVariable="status"
                    value={proposalRisk.status}
                  />
                </div>
              </div>

              {/* Strategy */}
              <FormDrawerTextAreaField3
                error={proposalRiskUpdate.error}
                fieldName="strategy"
                label="Strategy"
                placeholder="Describe how you will avoid and/or mitigate the risk"
                setValueObject={setProposalRisk}
                setValueVariable="strategy"
                value={proposalRisk.strategy}
              />
            </Card>
          </div>
        }
        deleteSuccess={proposalRiskDelete.success}
        deleteSuccessMessage="Risk removed."
        deleting={proposalRiskDelete.loading}
        error={proposalRiskUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update risk"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Shaping your risk management strategy"
        icon="edit"
        isOpen={updateRiskDrawerIsOpen}
        loading={proposalRiskDetails.loading}
        onDelete={deleteRisk}
        onSave={updateRisk}
        refreshList={() => {
          dispatch(listProposalRisksAction({ ...proposalRiskQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalRiskAction());

          dispatch(resetUpdateProposalRiskAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalRiskUpdate.success}
        saveSuccessMessage="Risk updated."
        saving={proposalRiskUpdate.loading}
        setIsOpen={setUpdateRiskDrawerIsOpen}
        title="Update risk"
      />

      {/* Existing proposal fee form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Adjust the fee</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {Math.abs(
              proposalFeeList.total_fees -
                proposalMilestoneList.totalValueOfMilestones
            ) >= 0.01 && (
              <Callout
                intent="primary"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "0 0 15px 0",
                }}
              >
                <div style={{ marginRight: "5px" }}>The total fee value is</div>

                <NumericValue
                  currency={activeOrganisation.currency}
                  noTag
                  number={Math.abs(
                    proposalFeeList.total_fees -
                      proposalMilestoneList.totalValueOfMilestones
                  )}
                />

                <div style={{ margin: "0 5px 0 5px" }}>
                  {proposalFeeList.total_fees -
                    proposalMilestoneList.totalValueOfMilestones >
                  0
                    ? "higher"
                    : "lower"}
                </div>

                <div>that the total milestone value.</div>
              </Callout>
            )}

            {/* Adjustment */}
            <FormDrawerNumericField2
              error={proposalFeeUpdate.error}
              fieldName="adjustment"
              label="Adjustment"
              leftElement={
                <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                  {activeOrganisation.currency}
                </Tag>
              }
              majorStepSize={100000}
              minorStepSize={1000}
              placeholder="Enter the adjustment value"
              setValueObject={setProposalFee}
              setValueVariable="adjustment"
              stepSize={10000}
              value={proposalFee.adjustment}
            />
          </Card>
        }
        error={proposalFeeUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update fee"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Adjusting fees"
        icon="edit"
        isOpen={updateFeeDrawerIsOpen}
        loading={proposalFeeDetails.loading}
        onSave={updateProposalFee}
        refreshList={() => {
          dispatch(listProposalFeesAction({ ...proposalFeeQuery }));

          dispatch(listProposalDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalFeeAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalFeeUpdate.success}
        saveSuccessMessage="Proposal fee updated."
        saving={proposalFeeUpdate.loading}
        setIsOpen={setUpdateFeeDrawerIsOpen}
        size="small"
        title="Update fee"
      />

      {/* New milestone form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the milestone</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={proposalMilestoneCreate.error}
                  fieldName="name"
                  icon="star-empty"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Give the milestone a name"
                  setValueObject={setProposalMilestone}
                  setValueVariable="name"
                  value={proposalMilestone.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={proposalMilestoneCreate.error}
                  fieldName="value"
                  label="Value"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the milestone value"
                  setValueObject={setProposalMilestone}
                  setValueVariable="value"
                  stepSize={10000}
                  value={proposalMilestone.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={proposalMilestoneCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the milestone due date"
                  setValueObject={setProposalMilestone}
                  setValueVariable="due"
                  value={proposalMilestone.due}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalMilestoneCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the milestone"
              setValueObject={setProposalMilestone}
              setValueVariable="description"
              value={proposalMilestone.description}
            />
          </Card>
        }
        error={proposalMilestoneCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add milestone"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Setting payment milestones"
        icon="edit"
        isOpen={addMilestoneDrawerIsOpen}
        onSave={addMilestone}
        refreshList={() => {
          dispatch(listProposalMilestonesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalMilestoneAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalMilestoneCreate.success}
        saveSuccessMessage="Milestone added."
        saving={proposalMilestoneCreate.loading}
        setIsOpen={setAddMilestoneDrawerIsOpen}
        title="Add milestone"
      />

      {/* Existing milestone form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the milestone</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={proposalMilestoneUpdate.error}
                  fieldName="name"
                  icon="star-empty"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Give the milestone a name"
                  setValueObject={setProposalMilestone}
                  setValueVariable="name"
                  value={proposalMilestone.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={proposalMilestoneUpdate.error}
                  fieldName="value"
                  label="Value"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the milestone value"
                  setValueObject={setProposalMilestone}
                  setValueVariable="value"
                  stepSize={10000}
                  value={proposalMilestone.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={proposalMilestoneUpdate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the milestone due date"
                  setValueObject={setProposalMilestone}
                  setValueVariable="due"
                  value={proposalMilestone.due}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalMilestoneUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the milestone"
              setValueObject={setProposalMilestone}
              setValueVariable="description"
              value={proposalMilestone.description}
            />
          </Card>
        }
        cannotDelete={proposalMilestoneDetails.proposal_milestone.cannot_delete}
        deleteSuccess={proposalMilestoneDelete.success}
        deleteSuccessMessage="Milestone removed."
        deleting={proposalMilestoneDelete.loading}
        error={proposalMilestoneUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update milestone"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Setting payment milestones"
        icon="edit"
        isOpen={updateMilestoneDrawerIsOpen}
        loading={proposalMilestoneDetails.loading}
        onDelete={deleteMilestone}
        onSave={updateMilestone}
        refreshList={() => {
          dispatch(listProposalMilestonesAction({ ...proposalMilestoneQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalMilestoneAction());

          dispatch(resetUpdateProposalMilestoneAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalMilestoneUpdate.success}
        saveSuccessMessage="Milestone updated."
        saving={proposalMilestoneUpdate.loading}
        setIsOpen={setUpdateMilestoneDrawerIsOpen}
        title="Update milestone"
      />

      {/* New rate form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the rate details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Role */}
            <FormDrawerSelectField2
              error={proposalRateCreate.error}
              fieldName="role"
              helperText="You can choose from your organisation's standard roles"
              icon="people"
              info={
                <RoleInfo roleId={proposalRate.role} query={roleQueryDefault} />
              }
              items={roleList.roles}
              label="Role"
              labelInfo="(required)"
              loading={roleList.loading}
              placeholder="Select a role"
              query={roleQuery.searchString}
              setQueryObject={setRoleQuery}
              setQueryVariable="searchString"
              setValueObject={setProposalRate}
              setValueIdVariable="role"
              setValueNameVariable="roleName"
              showInfo={proposalRate.role}
              value={proposalRate.roleName}
            />

            {/* Margin */}
            <FormDrawerNumericField2
              error={proposalRateCreate.error}
              fieldName="margin_percentage"
              helperText="Leave blank to use the organization's default margin"
              icon="percentage"
              label="Margin"
              majorStepSize={20}
              min={0}
              minorStepSize={1}
              placeholder="Enter the margin"
              setValueObject={setProposalRate}
              setValueVariable="marginPercentage"
              stepSize={10}
              value={proposalRate.marginPercentage}
            />
          </Card>
        }
        error={proposalRateCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add rate"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Defining the rate card"
        icon="edit"
        isOpen={addRateDrawerIsOpen}
        onSave={addRate}
        refreshList={() => {
          dispatch(listProposalRatesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalRateAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalRateCreate.success}
        saveSuccessMessage="Rate added."
        saving={proposalRateCreate.loading}
        setIsOpen={setAddRateDrawerIsOpen}
        size="small"
        title="Add rate"
      />

      {/* Existing rate form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the rate details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Role */}
            <FormDrawerSelectField2
              disabled
              error={proposalRateUpdate.error}
              fieldName="role"
              helperText="You can choose from your organisation's standard roles"
              icon="people"
              info={
                <RoleInfo roleId={proposalRate.role} query={roleQueryDefault} />
              }
              items={roleList.roles}
              label="Role"
              labelInfo="(required)"
              loading={roleList.loading}
              placeholder="Select a role"
              query={roleQuery.searchString}
              setQueryObject={setRoleQuery}
              setQueryVariable="searchString"
              setValueObject={setProposalRate}
              setValueIdVariable="role"
              setValueNameVariable="roleName"
              showInfo={proposalRate.role}
              value={proposalRate.roleName}
            />

            {/* Margin */}
            <FormDrawerNumericField2
              error={proposalRateUpdate.error}
              fieldName="margin_percentage"
              helperText="Leave blank to use the organization's default margin"
              icon="percentage"
              label="Margin"
              majorStepSize={20}
              min={0}
              minorStepSize={1}
              placeholder="Enter the margin"
              setValueObject={setProposalRate}
              setValueVariable="marginPercentage"
              stepSize={10}
              value={proposalRate.marginPercentage}
            />
          </Card>
        }
        deleteSuccess={proposalRateDelete.success}
        deleteSuccessMessage="Rate removed."
        deleting={proposalRateDelete.loading}
        error={proposalRateUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update rate"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Defining the rate card"
        icon="edit"
        isOpen={updateRateDrawerIsOpen}
        loading={proposalRateDetails.loading}
        onDelete={deleteRate}
        onSave={updateRate}
        refreshList={() => {
          dispatch(listProposalRatesAction({ ...proposalRateQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalRateAction());

          dispatch(resetUpdateProposalRateAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalRateUpdate.success}
        saveSuccessMessage="Rate updated."
        saving={proposalRateUpdate.loading}
        setIsOpen={setUpdateRateDrawerIsOpen}
        size="small"
        title="Update rate"
      />

      {/* Fee notes form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Add pricing assumptions</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Currency */}
                <FormDrawerSelectSimpleField3
                  error={proposalUpdate.error}
                  fieldName="currency"
                  helperText="Fees will be quoted in this currency"
                  label="Currency"
                  loading={currencyGetOrganisationCurrencyChoices.loading}
                  options={currencies}
                  setValueObject={setUpdatedProposal}
                  setValueVariable="currency"
                  value={updatedProposal.currency}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "25%",
                }}
              >
                {/* Exchange rate */}
                <FormDrawerNumericField2
                  error={proposalUpdate.error}
                  fieldName="currency_exchange_rate"
                  helperText="Leave blank to use the organisation's default"
                  label="Exchange rate"
                  majorStepSize={1}
                  min={0}
                  minorStepSize={0.0001}
                  placeholder="Enter rate"
                  setValueObject={setUpdatedProposal}
                  setValueVariable="currencyExchangeRate"
                  stepSize={0.1}
                  value={updatedProposal.currencyExchangeRate}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "25%" }}>
                {/* Includes tax */}
                <FormDrawerSelectSimpleField3
                  error={proposalUpdate.error}
                  fieldName="tax"
                  helperText="Whether the fee includes taxes applicable to your services"
                  label="Includes tax"
                  options={[
                    { id: 1, icon: "cross", value: "No" },
                    { id: 2, icon: "tick", value: "Yes" },
                  ]}
                  setValueObject={setUpdatedProposal}
                  setValueVariable="tax"
                  value={updatedProposal.tax}
                />
              </div>
            </div>

            {/* Additional information */}
            <FormDrawerTextAreaField3
              error={proposalUpdate.error}
              fieldName="fee_notes"
              helperText="Additional comments on the fee"
              label="Additional information"
              placeholder="Enter your comments"
              setValueObject={setUpdatedProposal}
              setValueVariable="feeNotes"
              value={updatedProposal.feeNotes}
            />
          </Card>
        }
        error={proposalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update fee notes"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Explaining the fee"
        icon="edit"
        isOpen={updateFeeNotesDrawerIsOpen}
        loading={proposalDetails.loading}
        onSave={updateFeeNotes}
        refreshList={() => {
          dispatch(listProposalDetailsAction(id));

          dispatch(listProposalLabourExpensesAction({ proposal: id }));

          dispatch(listProposalNonLabourExpensesAction({ proposal: id }));

          dispatch(listProposalFeesAction({ proposal: id }));

          dispatch(listProposalMilestonesAction({ proposal: id }));

          dispatch(listProposalRatesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetUpdateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUpdate.success}
        saveSuccessMessage="Proposal updated."
        saving={proposalUpdate.loading}
        setIsOpen={setUpdateFeeNotesDrawerIsOpen}
        title="Update fee notes"
      />

      {/* New legal section form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the proposed legal terms</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Section title */}
                <FormDrawerTextField2
                  error={proposalLegalSectionCreate.error}
                  fieldName="title"
                  icon="label"
                  label="Section title"
                  labelInfo="(required)"
                  placeholder="Enter the section title"
                  setValueObject={setProposalLegalSection}
                  setValueVariable="title"
                  value={proposalLegalSection.title}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Section number */}
                <FormDrawerTextField2
                  error={proposalLegalSectionCreate.error}
                  fieldName="number"
                  helperText="advaise.app will order sections based on this number"
                  icon="numbered-list"
                  label="Section number"
                  labelInfo="(required)"
                  placeholder="Enter a unique section number"
                  setValueObject={setProposalLegalSection}
                  setValueVariable="number"
                  value={proposalLegalSection.number}
                />
              </div>
            </div>

            {/* Proposed terms */}
            <FormDrawerTextAreaField3
              error={proposalLegalSectionCreate.error}
              fieldName="proposed"
              label="Proposed terms"
              labelInfo="(required)"
              placeholder="Enter the proposed terms"
              setValueObject={setProposalLegalSection}
              setValueVariable="proposed"
              value={proposalLegalSection.proposed}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Originator */}
                <FormDrawerTextField2
                  error={proposalLegalSectionCreate.error}
                  fieldName="originator"
                  helperText="Organisation that proposed the original terms"
                  icon="office"
                  label="Originator"
                  placeholder="Enter the originating organisation"
                  setValueObject={setProposalLegalSection}
                  setValueVariable="originator"
                  value={proposalLegalSection.originator}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>
          </Card>
        }
        error={proposalLegalSectionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add legal terms"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Agreeing legal terms"
        icon="edit"
        isOpen={addLegalSectionDrawerIsOpen}
        onSave={addLegalSection}
        refreshList={() => {
          dispatch(listProposalLegalSectionsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalLegalSectionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalLegalSectionCreate.success}
        saveSuccessMessage="Legal terms added."
        saving={proposalLegalSectionCreate.loading}
        setIsOpen={setAddLegalSectionDrawerIsOpen}
        title="Add legal terms"
      />

      {/* Existing legal section form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0" }}>
              <H5>Enter the proposed legal terms</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Section title */}
                  <FormDrawerTextField2
                    error={proposalLegalSectionUpdate.error}
                    fieldName="title"
                    icon="label"
                    label="Section title"
                    labelInfo="(required)"
                    placeholder="Enter the section title"
                    setValueObject={setProposalLegalSection}
                    setValueVariable="title"
                    value={proposalLegalSection.title}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Section number */}
                  <FormDrawerTextField2
                    error={proposalLegalSectionUpdate.error}
                    fieldName="number"
                    helperText="advaise.app will label and order sections based on this number"
                    icon="numbered-list"
                    label="Section number"
                    labelInfo="(required)"
                    placeholder="Enter a unique section number"
                    setValueObject={setProposalLegalSection}
                    setValueVariable="number"
                    value={proposalLegalSection.number}
                  />
                </div>
              </div>

              {/* Proposed terms */}
              <FormDrawerTextAreaField3
                error={proposalLegalSectionUpdate.error}
                fieldName="proposed"
                label="Proposed terms"
                labelInfo="(required)"
                placeholder="Enter the proposed terms"
                setValueObject={setProposalLegalSection}
                setValueVariable="proposed"
                value={proposalLegalSection.proposed}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Originator */}
                  <FormDrawerTextField2
                    error={proposalLegalSectionUpdate.error}
                    fieldName="originator"
                    helperText="Organisation that proposed the original terms"
                    icon="office"
                    label="Originator"
                    placeholder="Enter the originating organisation"
                    setValueObject={setProposalLegalSection}
                    setValueVariable="originator"
                    value={proposalLegalSection.originator}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card>
              <H5>Update the legal terms</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Amended terms */}
              <FormDrawerTextAreaField3
                error={proposalLegalSectionUpdate.error}
                fieldName="amended"
                helperText="If there are no amendments, the proposal document will show the original terms"
                label="Amended terms"
                placeholder="Enter the updated terms"
                setValueObject={setProposalLegalSection}
                setValueVariable="amended"
                value={proposalLegalSection.amended}
              />

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={proposalLegalSectionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Summarize your discussion with the client"
                setValueObject={setProposalLegalSection}
                setValueVariable="comments"
                value={proposalLegalSection.comments}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Attachment */}
                  <FormDrawerFileField2
                    error={proposalLegalSectionUpdate.error}
                    fileName="attachment"
                    label="Attachment"
                    placeholder="Upload the attachment"
                    setValueObject={setProposalLegalSection}
                    setValueFileVariable="attachment"
                    setValueNameVariable="attachmentName"
                    valueFile={proposalLegalSection.attachment}
                    valueName={proposalLegalSection.attachmentName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={proposalLegalSectionUpdate.error}
                    fieldName="status"
                    intent={
                      proposalLegalSection.status === "Agreed"
                        ? "success"
                        : proposalLegalSection.status === "Not agreed"
                        ? "danger"
                        : "warning"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "tick-circle", value: "Agreed" },
                      { id: 2, icon: "warning-sign", value: "Under review" },
                      { id: 3, icon: "error", value: "Not agreed" },
                    ]}
                    setValueObject={setProposalLegalSection}
                    setValueVariable="status"
                    value={proposalLegalSection.status}
                  />
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={proposalLegalSectionDelete.success}
        deleteSuccessMessage="Legal terms section deleted."
        deleting={proposalLegalSectionDelete.loading}
        error={proposalLegalSectionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update legal terms"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Agreeing legal terms"
        icon="edit"
        isOpen={updateLegalSectionDrawerIsOpen}
        loading={proposalLegalSectionDetails.loading}
        onDelete={deleteLegalSection}
        onSave={updateLegalSection}
        refreshList={() => {
          dispatch(
            listProposalLegalSectionsAction({ ...proposalLegalSectionQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalLegalSectionAction());

          dispatch(resetUpdateProposalLegalSectionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalLegalSectionUpdate.success}
        saveSuccessMessage="Legal terms updated."
        saving={proposalLegalSectionUpdate.loading}
        setIsOpen={setUpdateLegalSectionDrawerIsOpen}
        title="Update legal terms"
      />

      {/* Proposal document style form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Style the proposal document</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Template */}
                <FormDrawerSelectSimpleField3
                  error={proposalDocumentUpdate.error}
                  fieldName="template"
                  helperText="You can choose a template from the template library"
                  label="Template"
                  options={[
                    {
                      id: 1,
                      icon: "document",
                      value: "London",
                    },
                    {
                      id: 2,
                      icon: "document",
                      value: "Melbourne",
                    },
                  ]}
                  setValueObject={setProposalDocument}
                  setValueVariable="template"
                  value={proposalDocument.template}
                />

                {/* Font */}
                <FormDrawerSelectSimpleField3
                  error={proposalDocumentUpdate.error}
                  fieldName="font"
                  label="Font"
                  options={[
                    { id: 1, icon: "font", value: "Courier" },
                    { id: 2, icon: "font", value: "Helvetica" },
                    { id: 3, icon: "font", value: "Montserrat" },
                    { id: 4, icon: "font", value: "Roboto" },
                    { id: 5, icon: "font", value: "Sintony" },
                    { id: 6, icon: "font", value: "Times-Roman" },
                  ]}
                  setValueObject={setProposalDocument}
                  setValueVariable="font"
                  value={proposalDocument.font}
                />

                {/* Primary colour */}
                <FormDrawerTextField2
                  error={proposalDocumentUpdate.error}
                  fieldName="primary_colour"
                  helperText="Hex code starting with #"
                  icon="color-fill"
                  label="Primary colour"
                  placeholder="Enter a hex code"
                  rightElement={
                    <Tag
                      round
                      style={{
                        backgroundColor: proposalDocument.primaryColour,
                        width: "50px",
                      }}
                    ></Tag>
                  }
                  setValueObject={setProposalDocument}
                  setValueVariable="primaryColour"
                  value={proposalDocument.primaryColour}
                />

                {/* Secondary colour */}
                <FormDrawerTextField2
                  error={proposalDocumentUpdate.error}
                  fieldName="secondary_colour"
                  helperText="Hex code starting with #"
                  icon="color-fill"
                  label="Secondary colour"
                  placeholder="Enter a hex code"
                  rightElement={
                    <Tag
                      round
                      style={{
                        backgroundColor: proposalDocument.secondaryColour,
                        width: "50px",
                      }}
                    ></Tag>
                  }
                  setValueObject={setProposalDocument}
                  setValueVariable="secondaryColour"
                  value={proposalDocument.secondaryColour}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Logo */}
                <FormDrawerFileField2
                  error={proposalDocumentUpdate.error}
                  fileName="logo"
                  label="Logo"
                  placeholder="Upload the logo"
                  setValueObject={setProposalDocument}
                  setValueFileVariable="logo"
                  setValueNameVariable="logoName"
                  valueFile={proposalDocument.logo}
                  valueName={proposalDocument.logoName}
                />

                {/* Logo preview */}
                <div style={{ margin: "0 0 20px 0" }}>
                  {proposalDocument.logo ? (
                    proposalDocument.logo !==
                    proposalDocumentDetails.proposal_document.logo ? (
                      <TabSectionFieldImage
                        image={URL.createObjectURL(
                          new Blob([proposalDocument.logo])
                        )}
                      />
                    ) : (
                      <TabSectionFieldImage
                        image={proposalDocumentDetails.proposal_document.logo}
                      />
                    )
                  ) : undefined}
                </div>
              </div>
            </div>
          </Card>
        }
        error={proposalDocumentUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update style"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Styling the proposal"
        icon="edit"
        isOpen={updateProposalDocumentStyleDrawerIsOpen}
        loading={proposalDocumentDetails.loading}
        onSave={updateDocumentStyle}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalDocumentUpdate.success}
        saving={proposalDocumentUpdate.loading}
        setIsOpen={setUpdateProposalDocumentStyleDrawerIsOpen}
        title="Update style"
      />

      {/* New case study form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Select the case study</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Case study */}
            <FormDrawerSelectField2
              error={proposalCaseStudyCreate.error}
              fieldName="case_study"
              helperText="You can choose from your organisation's approved case studies"
              icon="book"
              info={
                <CaseStudyInfo
                  caseStudyId={proposalCaseStudy.caseStudy}
                  query={caseStudyQueryDefault}
                />
              }
              items={caseStudyList.case_studies}
              label="Case study"
              labelInfo="(required)"
              loading={caseStudyList.loading}
              placeholder="Select a case study"
              query={caseStudyQuery.searchString}
              setQueryObject={setCaseStudyQuery}
              setQueryVariable="searchString"
              setValueObject={setProposalCaseStudy}
              setValueIdVariable="caseStudy"
              setValueNameVariable="caseStudyName"
              showInfo={proposalCaseStudy.caseStudy}
              value={proposalCaseStudy.caseStudyName}
            />
          </Card>
        }
        error={proposalCaseStudyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add case study"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Selecting case studies"
        icon="edit"
        isOpen={addCaseStudyDrawerIsOpen}
        onSave={addCaseStudy}
        refreshList={() => {
          dispatch(listProposalCaseStudiesAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalCaseStudyCreate.success}
        saveSuccessMessage="Case study added."
        saving={proposalCaseStudyCreate.loading}
        setIsOpen={setAddCaseStudyDrawerIsOpen}
        size="small"
        title="Add case study"
      />

      {/* Existing case study form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Select the case study</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Case study */}
            <FormDrawerSelectField2
              error={proposalCaseStudyUpdate.error}
              fieldName="case_study"
              helperText="You can choose from your organisation's approved case studies"
              icon="book"
              info={
                <CaseStudyInfo
                  caseStudyId={proposalCaseStudy.caseStudy}
                  query={caseStudyQueryDefault}
                />
              }
              items={caseStudyList.case_studies}
              label="Case study"
              labelInfo="(required)"
              loading={caseStudyList.loading}
              placeholder="Select a case study"
              query={caseStudyQuery.searchString}
              setQueryObject={setCaseStudyQuery}
              setQueryVariable="searchString"
              setValueObject={setProposalCaseStudy}
              setValueIdVariable="caseStudy"
              setValueNameVariable="caseStudyName"
              showInfo={proposalCaseStudy.caseStudy}
              value={proposalCaseStudy.caseStudyName}
            />
          </Card>
        }
        deleteSuccess={proposalCaseStudyDelete.success}
        deleteSuccessMessage="Case study removed."
        deleting={proposalCaseStudyDelete.loading}
        error={proposalCaseStudyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update case study"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Selecting case studies"
        icon="edit"
        isOpen={updateCaseStudyDrawerIsOpen}
        loading={proposalCaseStudyDetails.loading}
        onDelete={deleteCaseStudy}
        onSave={updateCaseStudy}
        refreshList={() => {
          dispatch(
            listProposalCaseStudiesAction({ ...proposalCaseStudyQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalCaseStudyAction());

          dispatch(resetUpdateProposalCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalCaseStudyUpdate.success}
        saveSuccessMessage="Case study updated."
        saving={proposalCaseStudyUpdate.loading}
        setIsOpen={setUpdateCaseStudyDrawerIsOpen}
        size="small"
        title="Update case study"
      />

      {/* New proposal lesson form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the lesson learned</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={proposalLessonCreate.error}
                  fieldName="name"
                  icon="learning"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the lesson a title"
                  setValueObject={setProposalLesson}
                  setValueVariable="name"
                  value={proposalLesson.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={proposalLessonCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "search-template",
                      value: "Qualification",
                    },
                    { id: 2, icon: "playbook", value: "Strategy" },
                    { id: 3, icon: "gantt-chart", value: "Solution" },
                    { id: 4, icon: "dollar", value: "Commercials" },
                    {
                      id: 5,
                      icon: "manual",
                      value: "Response",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setProposalLesson}
                  setValueVariable="category"
                  value={proposalLesson.category}
                />
              </div>
            </div>

            {/* Lesson */}
            <FormDrawerTextAreaField3
              error={proposalLessonCreate.error}
              fieldName="lesson"
              label="Lesson"
              placeholder="Describe what happened and what we learned"
              setValueObject={setProposalLesson}
              setValueVariable="lesson"
              value={proposalLesson.lesson}
            />
          </Card>
        }
        error={proposalLessonCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add lesson"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Capturing lessons learned"
        icon="edit"
        isOpen={addProposalLessonDrawerIsOpen}
        onSave={addProposalLesson}
        refreshList={() => {
          dispatch(listProposalLessonsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalLessonAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalLessonCreate.success}
        saveSuccessMessage="Lesson added."
        saving={proposalLessonCreate.loading}
        setIsOpen={setAddProposalLessonDrawerIsOpen}
        title="Add lesson"
      />

      {/* Existing proposal lesson form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the lesson learned</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={proposalLessonUpdate.error}
                  fieldName="name"
                  icon="learning"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the lesson a title"
                  setValueObject={setProposalLesson}
                  setValueVariable="name"
                  value={proposalLesson.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={proposalLessonUpdate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "search-template",
                      value: "Qualification",
                    },
                    { id: 2, icon: "playbook", value: "Strategy" },
                    { id: 3, icon: "gantt-chart", value: "Solution" },
                    { id: 4, icon: "dollar", value: "Commercials" },
                    {
                      id: 5,
                      icon: "manual",
                      value: "Response",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setProposalLesson}
                  setValueVariable="category"
                  value={proposalLesson.category}
                />
              </div>
            </div>

            {/* Lesson */}
            <FormDrawerTextAreaField3
              error={proposalLessonUpdate.error}
              fieldName="lesson"
              label="Lesson"
              placeholder="Describe what happened and what we learned"
              setValueObject={setProposalLesson}
              setValueVariable="lesson"
              value={proposalLesson.lesson}
            />
          </Card>
        }
        deleteSuccess={proposalLessonDelete.success}
        deleteSuccessMessage="Proposal lesson deleted."
        deleting={proposalLessonDelete.loading}
        error={proposalLessonUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update lesson"
            module="Proposals"
            view="Detail"
          />
        }
        helpTitle="Capturing lessons learned"
        icon="edit"
        isOpen={updateProposalLessonDrawerIsOpen}
        loading={proposalLessonDetails.loading}
        onDelete={deleteProposalLesson}
        onSave={updateProposalLesson}
        refreshList={() => {
          dispatch(listProposalLessonsAction({ ...proposalLessonQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalLessonAction());

          dispatch(resetUpdateProposalLessonAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalLessonUpdate.success}
        saveSuccessMessage="Proposal lesson updated."
        saving={proposalLessonUpdate.loading}
        setIsOpen={setUpdateProposalLessonDrawerIsOpen}
        title="Update lesson"
      />

      {/* New upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the upload details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  error={proposalUploadCreate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setProposalUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={proposalUpload.file}
                  valueName={proposalUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  error={proposalUploadCreate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setProposalUpload}
                  setValueVariable="name"
                  value={proposalUpload.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={proposalUploadCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarize the file contents"
              setValueObject={setProposalUpload}
              setValueVariable="description"
              value={proposalUpload.description}
            />
          </Card>
        }
        error={proposalUploadCreate.error}
        icon="edit"
        isOpen={addUploadDrawerIsOpen}
        onSave={addUpload}
        refreshList={() => {
          dispatch(listProposalUploadsAction({ proposal: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateProposalUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUploadCreate.success}
        saveSuccessMessage="File uploaded."
        saving={proposalUploadCreate.loading}
        setIsOpen={setAddUploadDrawerIsOpen}
        title="Upload file"
      />

      {/* Existing upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the file details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  disabled={
                    proposalDetails.proposal.user_access_status === "view"
                  }
                  error={proposalUploadUpdate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setProposalUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={proposalUpload.file}
                  valueName={proposalUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  disabled={
                    proposalDetails.proposal.user_access_status === "view"
                  }
                  error={proposalUploadUpdate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setProposalUpload}
                  setValueVariable="name"
                  value={proposalUpload.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              disabled={proposalDetails.proposal.user_access_status === "view"}
              error={proposalUploadUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarize the file contents"
              setValueObject={setProposalUpload}
              setValueVariable="description"
              value={proposalUpload.description}
            />

            <Button
              disabled={!proposalUpload.file}
              icon="download"
              intent="primary"
              onClick={() => {
                saveAs(proposalUpload.file, proposalUpload.fileName);
              }}
              text="Download"
            />
          </Card>
        }
        deleteSuccess={proposalUploadDelete.success}
        deleteSuccessMessage="File deleted."
        deleting={proposalUploadDelete.loading}
        disabled={proposalDetails.proposal.user_access_status === "view"}
        error={proposalUploadUpdate.error}
        icon="edit"
        isOpen={updateUploadDrawerIsOpen}
        loading={proposalUploadDetails.loading}
        onDelete={deleteUpload}
        onSave={updateUpload}
        refreshList={() => {
          dispatch(listProposalUploadsAction({ ...proposalUploadQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteProposalUploadAction());

          dispatch(resetUpdateProposalUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalUploadUpdate.success}
        saveSuccessMessage="File updated."
        saving={proposalUploadUpdate.loading}
        setIsOpen={setUpdateUploadDrawerIsOpen}
        title="Update file"
      />

      {/* Action dialogs */}
      {/* Upload requirements action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={proposalRequirementUpload.begin}
            icon="upload"
            intent="primary"
            loading={proposalRequirementUpload.begin}
            onClick={() => {
              setProposalRequirementUploadFile({
                ...proposalRequirementUploadFile,
                error: proposalRequirementUploadFileDefault.error,
              });

              dispatch(resetUploadProposalRequirementsAction());

              uploadProposalRequirements();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the requirements file"
            setValueObject={setProposalRequirementUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={proposalRequirementUploadFile.file}
            valueName={proposalRequirementUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadProposalRequirementsDialogIsOpen}
        message={
          proposalRequirementUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : proposalRequirementUpload.records_failed.length > 0
            ? "We couldn't upload all the requirements. Please wait until we have processed all the records and then open the Requirement Exceptions file downloaded."
            : proposalRequirementUpload.end
            ? "Requirements uploaded."
            : "You must use the template downloaded to upload requirements."
        }
        messageIntent={
          proposalRequirementUploadFile.error
            ? "danger"
            : proposalRequirementUpload.records_failed.length > 0
            ? "warning"
            : proposalRequirementUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listProposalRequirementsAction({ proposal: id }));

          dispatch(resetDownloadManagersAction());

          dispatch(resetUploadProposalRequirementsAction());
        }}
        progress={proposalRequirementUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadProposalRequirementsDialogIsOpen}
        title="Upload requirements"
      />

      {/* Schedule dialog */}
      <Dialog
        className={!lightMode ? Classes.DARK : undefined}
        icon="gantt-chart"
        isCloseButtonShown
        isOpen={scheduleDialogIsOpen}
        lazy
        onClose={() => {
          setScheduleDialogIsOpen(false);
        }}
        style={{
          height: "90vh",
          width: "95vw",
        }}
        title="Schedule"
      >
        <div className={Classes.DIALOG_BODY} style={{ marginBottom: "-25px" }}>
          <Timeline4
            data={generateTimelineDataFromProposalServices(
              proposalDetails.proposal.services
            )}
            height="70vh"
            itemListHeading="Services/Activities"
            loading={proposalDetails.loading}
            onClick={(id, type) => {
              if (type === "child") {
                dispatch(
                  listProposalServiceActivityDetailsAction(id.split(".")[1])
                );

                setUpdateActivityDrawerIsOpen(true);
              } else if (type === "item") {
                dispatch(listProposalServiceDetailsAction(id));

                setUpdateServiceDrawerIsOpen(true);
              } else if (type === "milestone") {
                dispatch(
                  listProposalServiceDeliverableDetailsAction(id.split(".")[1])
                );

                setUpdateDeliverableDrawerIsOpen(true);
              } else {
                dispatch(
                  listProposalLabourExpenseDetailsAction(id.split(".")[1])
                );

                setUpdateLabourExpenseDrawerIsOpen(true);
              }
            }}
            resourceData={generateTimelineResourceDataFromProposalServices(
              proposalDetails.proposal.services
            )}
            showDates
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              disabled={proposalDetails.proposal.user_access_status === "view"}
              icon="add"
              intent="primary"
              onClick={() => {
                setAddActivityDrawerIsOpen(true);
              }}
              text="Add"
            />

            <Button
              className={Classes.DIALOG_CLOSE_BUTTON}
              onClick={() => {
                setScheduleDialogIsOpen(false);
              }}
              text="Close"
            />
          </div>
        </div>
      </Dialog>

      {/* Upload legal sections action dialog */}
      <ActionDialog
        buttons={
          <Button
            disabled={proposalLegalSectionUpload.begin}
            icon="upload"
            intent="primary"
            loading={proposalLegalSectionUpload.begin}
            onClick={() => {
              setProposalLegalSectionUploadFile({
                ...proposalLegalSectionUploadFile,
                error: proposalLegalSectionUploadFileDefault.error,
              });

              dispatch(resetUploadProposalLegalSectionsAction());

              uploadProposalLegalSections();
            }}
            text="Upload"
          />
        }
        children={
          <FormDrawerFileField2
            placeholder="Upload the legal terms file"
            setValueObject={setProposalLegalSectionUploadFile}
            setValueFileVariable="file"
            setValueNameVariable="fileName"
            valueFile={proposalLegalSectionUploadFile.file}
            valueName={proposalLegalSectionUploadFile.fileName}
          />
        }
        icon="upload"
        isOpen={uploadProposalLegalSectionsDialogIsOpen}
        message={
          proposalLegalSectionUploadFile.error
            ? "We couldn't read the file. Make sure you're using the correct template."
            : proposalLegalSectionUpload.records_failed.length > 0
            ? "We couldn't upload all the legal terms. Please wait until we have processed all the records and then open the Legal Term Exceptions file downloaded."
            : proposalLegalSectionUpload.end
            ? "Legal terms uploaded."
            : "You must use the template downloaded to upload legal terms."
        }
        messageIntent={
          proposalLegalSectionUploadFile.error
            ? "danger"
            : proposalLegalSectionUpload.records_failed.length > 0
            ? "warning"
            : proposalLegalSectionUpload.end
            ? "success"
            : "primary"
        }
        onClose={() => {
          dispatch(listProposalLegalSectionsAction({ proposal: id }));

          dispatch(resetUploadProposalLegalSectionsAction());
        }}
        progress={proposalLegalSectionUpload.progress}
        resetDrawerVariables={resetDrawerVariables}
        setIsOpen={setUploadProposalLegalSectionsDialogIsOpen}
        title="Upload legal terms"
      />

      {/* Download editable proposal document action dialog */}
      <ActionDialog
        buttons={
          <Button
            icon="download"
            intent="primary"
            loading={editableProposalDocumentLoading}
            onClick={() => {
              setEditableProposalDocumentLoading(true);

              dispatch(downloadProposalAction(id));
            }}
            text="Download"
          />
        }
        children={
          <p>
            This is an editable and unstyled version of the proposal document in
            Microsoft Word format. It includes the same information as the
            proposal document that advaise.app has generated as a PDF. You can
            use the Word file to completely customise the look of your proposal.
          </p>
        }
        icon="download"
        isOpen={downloadEditableProposalDocumentIsOpen}
        message={
          proposalDownload.error
            ? "We couldn't download the file. Please try again."
            : proposalDownload.success
            ? "File downloading."
            : undefined
        }
        messageIntent={
          proposalDownload.error
            ? "danger"
            : proposalDownload.success
            ? "primary"
            : undefined
        }
        onClose={() => {
          dispatch(resetDownloadProposalAction());
        }}
        setIsOpen={setDownloadEditableProposalDocumentIsOpen}
        title="Download editable proposal document"
      />

      {/* Detail drawers */}
      {/* Approval request */}
      <ApprovalDetailDrawer
        approvalRequestDetails={approvalRequestDetails}
        setIsOpen={setViewProposalQualificationApprovalDrawerIsOpen}
        isOpen={viewProposalQualificationApprovalDrawerIsOpen}
      />

      {/* Document dialogs */}
      {/* Opportunity brief dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.opportunity_brief_pdf}
        fileName="Opportunity Brief"
        isOpen={opportunityBriefIsOpen}
        loading={documentGeneratePdf.loading}
        setIsOpen={setOpportunityBriefIsOpen}
        title="Opportunity Brief"
      />

      {/* Strategy review dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.strategy_review_pdf}
        fileName="Strategy Review"
        isOpen={strategyReviewIsOpen}
        loading={documentGeneratePdf.loading}
        setIsOpen={setStrategyReviewIsOpen}
        title="Strategy Review"
      />

      {/* Commercials review dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.commercials_review_pdf}
        fileName="Commercials Review"
        isOpen={commercialsReviewIsOpen}
        loading={documentGeneratePdf.loading}
        setIsOpen={setCommercialsReviewIsOpen}
        title="Commercials Review"
      />

      {/* Solution review dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.solution_review_pdf}
        fileName="Solution Review"
        isOpen={solutionReviewIsOpen}
        loading={documentGeneratePdf.loading}
        setIsOpen={setSolutionReviewIsOpen}
        title="Solution Review"
      />

      {/* Proposal document dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.proposal_pdf}
        fileName={`${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name}`}
        isOpen={proposalDocumentIsOpen}
        loading={
          documentGeneratePdf.loading ||
          proposalDocumentDetails.loading ||
          proposalDocumentUpdate.loading
        }
        setIsOpen={setProposalDocumentIsOpen}
        title="Proposal Document"
      />

      {/* Win/Loss review dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.win_loss_review_pdf}
        fileName="Win/Loss Review"
        isOpen={winLossReviewIsOpen}
        loading={documentGeneratePdf.loading}
        setIsOpen={setWinLossReviewIsOpen}
        title="Win/Loss Review"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Proposals"
              onClick={() => {
                dispatch(resetListProposalsAction());

                navigate("/proposals");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          proposalDetails.proposal.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={proposalDetails.loading}
        title={`${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name}`}
      />

      {/* Error alerts */}
      {/* Approval requests loading error */}
      {approvalRequestList.error && (
        <ErrorAlert message="We couldn't load the approval request list. Try refreshing the page." />
      )}
      {/* Booking codes loading error */}
      {bookingCodeList.error && (
        <ErrorAlert message="We couldn't load the booking code list. Try refreshing the page." />
      )}
      {/* Case studies loading error */}
      {caseStudyList.error && (
        <ErrorAlert message="We couldn't load the case study list. Try refreshing the page." />
      )}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Client KPI loading error */}
      {clientKPIList.error && (
        <ErrorAlert message="We couldn't load the client KPIs. Try refreshing the page." />
      )}
      {/* Competencies loading error */}
      {competencyList.error && (
        <ErrorAlert message="We couldn't load the competency list. Try refreshing the page." />
      )}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Expense categories loading error */}
      {expenseCategoryList.error && (
        <ErrorAlert message="We couldn't load the expense category list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {(managerList.error || managerDownload.error) && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Proposal delete error */}
      {proposalDelete.error && (
        <ErrorAlert message="We couldn't delete the proposal. Try refreshing the page." />
      )}
      {/* Proposal loading error */}
      {proposalDetails.error && (
        <ErrorAlert message="We couldn't load the proposal. Try refreshing the page." />
      )}
      {/* Proposal actions loading error */}
      {proposalActionList.error && (
        <ErrorAlert message="We couldn't load the proposal actions. Try refreshing the page." />
      )}
      {/* Proposal case studies loading error */}
      {proposalCaseStudyList.error && (
        <ErrorAlert message="We couldn't load the proposal case studies. Try refreshing the page." />
      )}
      {/* Proposal competitors loading error */}
      {proposalCompetitorList.error && (
        <ErrorAlert message="We couldn't load the proposal competitors. Try refreshing the page." />
      )}
      {/* Proposal contacts loading error */}
      {proposalContactList.error && (
        <ErrorAlert message="We couldn't load the proposal contacts. Try refreshing the page." />
      )}
      {/* Proposal document loading error */}
      {proposalDocumentDetails.error && (
        <ErrorAlert message="We couldn't load the proposal document. Try refreshing the page." />
      )}
      {/* Proposal fees loading error */}
      {proposalFeeList.error && (
        <ErrorAlert message="We couldn't load the proposal fees. Try refreshing the page." />
      )}
      {/* Proposal labour expenses loading error */}
      {proposalLabourExpenseList.error && (
        <ErrorAlert message="We couldn't load the proposal labour expenses. Try refreshing the page." />
      )}
      {/* Proposal legal sections loading error */}
      {(proposalLegalSectionList.error ||
        proposalLegalSectionDownload.error) && (
        <ErrorAlert message="We couldn't load the proposal legal terms. Try refreshing the page." />
      )}
      {/* Proposal lessons loading error */}
      {proposalLessonList.error && (
        <ErrorAlert message="We couldn't load the proposal lessons. Try refreshing the page." />
      )}

      {/* Proposal milestones loading error */}
      {proposalMilestoneList.error && (
        <ErrorAlert message="We couldn't load the proposal milestones. Try refreshing the page." />
      )}
      {/* Proposal non-labour expenses loading error */}
      {proposalNonLabourExpenseList.error && (
        <ErrorAlert message="We couldn't load the proposal non-labour expenses. Try refreshing the page." />
      )}
      {/* Proposal permissions loading error */}
      {proposalPermissionList.error && (
        <ErrorAlert message="We couldn't load the proposal permissions. Try refreshing the page." />
      )}
      {/* Proposal questions loading error */}
      {proposalQuestionList.error && (
        <ErrorAlert message="We couldn't load the proposal questions. Try refreshing the page." />
      )}
      {/* Proposal rates loading error */}
      {proposalRateList.error && (
        <ErrorAlert message="We couldn't load the proposal rates. Try refreshing the page." />
      )}
      {/* Proposal requirements loading error */}
      {(proposalRequirementList.error || proposalRequirementDownload.error) && (
        <ErrorAlert message="We couldn't load the proposal requirements. Try refreshing the page." />
      )}
      {/* Proposal risks loading error */}
      {proposalRiskList.error && (
        <ErrorAlert message="We couldn't load the proposal risks. Try refreshing the page." />
      )}
      {/* Proposal services loading error */}
      {proposalServiceList.error && (
        <ErrorAlert message="We couldn't load the proposal services. Try refreshing the page." />
      )}
      {/* Proposal service deliverables loading error */}
      {proposalServiceDeliverableList.error && (
        <ErrorAlert message="We couldn't load the proposal deliverables. Try refreshing the page." />
      )}
      {/* Proposal staff loading error */}
      {proposalStaffList.error && (
        <ErrorAlert message="We couldn't load the proposal staff. Try refreshing the page." />
      )}
      {/* Proposal uploads loading error */}
      {proposalUploadList.error && (
        <ErrorAlert message="We couldn't load the proposal files. Try refreshing the page." />
      )}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the standard roles. Try refreshing the page." />
      )}
      {/* Services loading error */}
      {serviceList.error && (
        <ErrorAlert message="We couldn't load the service list. Try refreshing the page." />
      )}
      {/* Staff loading error */}
      {staffList.error && (
        <ErrorAlert message="We couldn't load the staff list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/DMmd2kzN1-Y"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Proposal"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="proposal-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="proposal-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={proposalDetails.loading}
                          value={proposalDetails.proposal.id}
                        />

                        {/* Proposal name */}
                        <TabSectionField
                          label="Proposal name"
                          loading={proposalDetails.loading}
                          value={proposalDetails.proposal.name}
                        />

                        {/* Client */}
                        <TabSectionField
                          label="Client"
                          loading={proposalDetails.loading}
                          value={
                            <NameValue
                              image={proposalDetails.proposal.client_logo}
                              logo
                              text={proposalDetails.proposal.client_name}
                              url={`/clients/${proposalDetails.proposal.client}`}
                            />
                          }
                        />

                        {/* Proposal value */}
                        <TabSectionField
                          helperText={
                            activeOrganisation.currency !==
                              proposalDetails.proposal.currency_short && (
                              <div style={{ display: "flex" }}>
                                Quote value of
                                <div style={{ margin: "0 0 0 5px" }}>
                                  <NumericValue
                                    currency={
                                      proposalDetails.proposal.currency_short
                                    }
                                    noTag
                                    number={
                                      proposalDetails.proposal.value_quote
                                    }
                                  />
                                </div>
                              </div>
                            )
                          }
                          label="Proposal value"
                          loading={proposalDetails.loading}
                          value={
                            <NumericValue
                              currency={activeOrganisation.currency}
                              number={proposalDetails.proposal.value}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={proposalDetails.loading}
                          value={
                            <Tag
                              intent={
                                proposalDetails.proposal.status === "Won"
                                  ? "success"
                                  : proposalDetails.proposal.status === "Lost"
                                  ? "danger"
                                  : proposalDetails.proposal.status ===
                                    "Cancelled"
                                  ? "warning"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {proposalDetails.proposal.status}
                            </Tag>
                          }
                        />

                        {/* Due date */}
                        <TabSectionField
                          label="Due date"
                          loading={proposalDetails.loading}
                          value={
                            <DateValue date={proposalDetails.proposal.due} />
                          }
                        />

                        {/* Submission date */}
                        <TabSectionField
                          label="Submission date"
                          loading={proposalDetails.loading}
                          value={
                            <DateValue
                              date={proposalDetails.proposal.submitted}
                            />
                          }
                        />

                        {/* Closed date */}
                        <TabSectionField
                          helperText={
                            proposalDetails.proposal.engagement_id
                              ? `Engagement id ${proposalDetails.proposal.engagement_id} has been created from this proposal.`
                              : undefined
                          }
                          label="Closed date"
                          loading={proposalDetails.loading}
                          value={
                            <DateValue date={proposalDetails.proposal.closed} />
                          }
                        />

                        {/* Contact */}
                        <TabSectionField
                          label="Contact"
                          loading={proposalDetails.loading}
                          value={
                            <NameValue
                              image={
                                proposalDetails.proposal.contact_profile_photo
                              }
                              text={proposalDetails.proposal.contact_name}
                              url={
                                proposalDetails.proposal.contact
                                  ? `/contacts/${proposalDetails.proposal.contact}`
                                  : undefined
                              }
                            />
                          }
                        />

                        {/* Request for proposal */}
                        <TabSectionField
                          label="Request for proposal"
                          loading={proposalDetails.loading}
                          value={
                            <TabSectionFieldLink
                              shorten
                              url={proposalDetails.proposal.rfp}
                            />
                          }
                        />

                        {/* Notes */}
                        <TabSectionField
                          label="Notes"
                          loading={proposalDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={proposalDetails.proposal.notes}
                            />
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            proposalDetails.proposal.restricted
                              ? "Users must have permission to access this proposal"
                              : "Everyone in the organisation can edit this proposal"
                          }
                          label="Access"
                          loading={proposalDetails.loading}
                          value={
                            <Tag
                              intent={
                                proposalDetails.proposal.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {proposalDetails.proposal.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={proposalDetails.loading}
                          value={
                            <NameValue
                              email={proposalDetails.proposal.manager_name}
                              image={
                                proposalDetails.proposal.manager_profile_photo
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Review and update basic information about the proposal"
                    icon="manual"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(proposalDetails.proposal.user_access_status === "owner" ||
                    proposalDetails.proposal.user_access_status === "admin" ||
                    proposalDetails.proposal.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={proposalPermissionList.loading}
                              setValueObject={setProposalPermissionQuery}
                              setValueVariable="searchString"
                              value={proposalPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={proposalPermissionList.proposal_permissions.map(
                              (proposal_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={proposal_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listProposalPermissionDetailsAction(
                                          proposal_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={proposal_permission}
                                  />
                                );
                              }
                            )}
                            count={proposalPermissionList.count}
                            loading={proposalPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={proposalPermissionQuery.page}
                            setPageObject={setProposalPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, proposals are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete proposal */}
                  {(proposalDetails.proposal.user_access_status === "owner" ||
                    proposalDetails.proposal.user_access_status === "admin" ||
                    proposalDetails.proposal.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={proposalDetails.proposal.cannot_delete}
                              id="confirm-proposal-name"
                              onChange={(e) => {
                                setProposalDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the proposal's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={proposalDeleteKey}
                            />

                            <Button
                              disabled={proposalDetails.proposal.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={proposalDelete.loading}
                              onClick={deleteProposal}
                              text="Delete this proposal"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{proposalDetails.proposal.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the proposal"
                      icon="delete"
                      message={
                        proposalDetails.proposal.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this proposal, you will lose all information associated with it."
                      }
                      messageIntent={
                        proposalDetails.proposal.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete proposal"
                    />
                  )}
                </div>
              }
            />

            {/* Management */}
            <Tab
              id="proposal-management-tab"
              title="Management"
              panel={
                <div>
                  {/* Financials */}
                  <TabSection2
                    content={
                      bookingCodeList.count > 0 && (
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={bookingCodeList.booking_codes.map(
                              (booking_code) => {
                                return (
                                  <tr key={booking_code.id}>
                                    {/* Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <BookingCodeInfo
                                            bookingCodeData={booking_code}
                                          />
                                        }
                                        tagIntent={
                                          booking_code.open ? "success" : "none"
                                        }
                                        tagText={
                                          booking_code.open ? "Open" : "Closed"
                                        }
                                        text={booking_code.name}
                                      />
                                    </td>

                                    {/* Year */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag value={booking_code.year} />
                                    </td>

                                    {/* Billable */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          booking_code.billable
                                            ? "success"
                                            : "primary"
                                        }
                                        value={
                                          booking_code.billable
                                            ? "Billable"
                                            : "Not billable"
                                        }
                                      />
                                    </td>

                                    {/* Budget */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.budget}
                                      />
                                    </td>

                                    {/* Spent */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.total_expense}
                                      />
                                    </td>

                                    {/* Remaining */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.remaining}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !bookingCodeQuery.billable &&
                              !bookingCodeQuery.searchString &&
                              !bookingCodeQuery.year
                            }
                            count={bookingCodeList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="3"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Budget */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.budgetTotal}
                                  />
                                </td>

                                {/* Spent */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.totalExpenseTotal}
                                  />
                                </td>

                                {/* Remaining */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.remainingTotal}
                                  />
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Booking code */}
                                <TableColumnHeadSearch
                                  queryObject={bookingCodeQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setBookingCodeQuery}
                                  setQueryVariable="searchString"
                                  title="Booking code"
                                  width="300px"
                                />

                                {/* Year */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="year"
                                  options={generateYearList}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Year"
                                  width="125px"
                                />

                                {/* Billable */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="billable"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Billable",
                                      icon: "dollar",
                                      value: "true",
                                    },
                                    {
                                      display: "Not billable",
                                      icon: "cross",
                                      value: "false",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Billable"
                                  width="125px"
                                />

                                {/* Budget */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "budget",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-budget",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Budget"
                                  width="125px"
                                />

                                {/* Spent */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "total_expense",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-total_expense",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Spent"
                                  width="125px"
                                />

                                {/* Remaining */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "remaining",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-remaining",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Remaining"
                                  width="125px"
                                />
                              </tr>
                            }
                            loading={bookingCodeList.loading}
                            noMargin
                            onClearFilters={() => {
                              setBookingCodeQuery({
                                ...bookingCodeQueryDefault,
                              });
                            }}
                            page={bookingCodeQuery.page}
                            setPageObject={setBookingCodeQuery}
                            setPageVariable="page"
                          />
                        </>
                      )
                    }
                    help="Monitor the proposal's financial position"
                    icon="dollar"
                    message={
                      bookingCodeList.count === 0
                        ? "Contact your administrator to set up a booking code."
                        : undefined
                    }
                    messageIntent={
                      bookingCodeList.count === 0 ? "primary" : undefined
                    }
                    title="Financials"
                  />

                  {/* Actions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddActionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive={!showActionKanban}
                                label="Completed"
                                loading={proposalActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setProposalActionQuery({
                                      ...proposalActionQuery,
                                      page: 1,
                                      status: "Completed",
                                    });
                                }}
                                value={
                                  proposalActionList.numberOfActionsCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive={!showActionKanban}
                                label="In-progress"
                                loading={proposalActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setProposalActionQuery({
                                      ...proposalActionQuery,
                                      page: 1,
                                      status: "In-progress",
                                    });
                                }}
                                value={
                                  proposalActionList.numberOfActionsInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive={!showActionKanban}
                                label="On-hold"
                                loading={proposalActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setProposalActionQuery({
                                      ...proposalActionQuery,
                                      page: 1,
                                      status: "On-hold",
                                    });
                                }}
                                value={proposalActionList.numberOfActionsOnHold}
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Buttons */}
                        <ButtonGroup
                          style={{
                            background: lightMode
                              ? Colors.LIGHT_GRAY3
                              : Colors.DARK_GRAY5,
                            maxWidth: "100%",
                            minWidth: "100%",
                            padding: "10px 10px 0 10px",
                          }}
                        >
                          <Button
                            icon="th"
                            onClick={() => setShowActionKanban(false)}
                          />

                          <Button
                            icon="list-columns"
                            onClick={() => {
                              setProposalActionQuery({
                                ...proposalActionQueryDefault,
                              });

                              setInitialiseActionKanban(true);

                              setShowActionKanban(true);
                            }}
                          />
                        </ButtonGroup>

                        {/* Data table */}
                        {!showActionKanban && (
                          <TableContainer2
                            body={proposalActionList.proposal_actions.map(
                              (proposal_action) => {
                                return (
                                  <tr
                                    key={proposal_action.id}
                                    onClick={() => {
                                      if (
                                        proposalDetails.proposal
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listProposalActionDetailsAction(
                                            proposal_action.id
                                          )
                                        );

                                        setUpdateActionDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Action */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5>{proposal_action.name}</H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Action</strong>
                                            </p>

                                            <p>{proposal_action.action}</p>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Comments</strong>
                                            </p>

                                            <p>
                                              {proposal_action.comments ? (
                                                proposal_action.comments
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No comments.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={proposal_action.name}
                                      />
                                    </td>

                                    {/* Category */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableTag
                                        color={
                                          proposal_action.category ===
                                          "Qualification"
                                            ? Colors.ROSE3
                                            : proposal_action.category ===
                                              "Strategy"
                                            ? Colors.INDIGO3
                                            : proposal_action.category ===
                                              "Solution"
                                            ? Colors.LIME3
                                            : proposal_action.category ===
                                              "Commercials"
                                            ? Colors.GOLD3
                                            : proposal_action.category ===
                                              "Response"
                                            ? Colors.CERULEAN3
                                            : Colors.BLUE3
                                        }
                                        value={proposal_action.category}
                                      />
                                    </td>

                                    {/* Owner */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={proposal_action.owner_name}
                                        image={
                                          proposal_action.owner_profile_photo
                                        }
                                        showImage
                                      />
                                    </td>

                                    {/* Due */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={proposal_action.due} />
                                    </td>

                                    {/* Status */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          proposal_action.status === "Completed"
                                            ? "success"
                                            : proposal_action.status ===
                                              "In-progress"
                                            ? "warning"
                                            : "primary"
                                        }
                                        value={proposal_action.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !proposalActionQuery.category &&
                              !proposalActionQuery.dueEnd &&
                              !proposalActionQuery.dueStart &&
                              !proposalActionQuery.owner &&
                              !proposalActionQuery.searchString &&
                              !proposalActionQuery.status
                            }
                            count={proposalActionList.count}
                            head={
                              <tr>
                                {/* Action */}
                                <TableColumnHeadSearch
                                  queryObject={proposalActionQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setProposalActionQuery}
                                  setQueryVariable="searchString"
                                  title="Action"
                                  width="200px"
                                />

                                {/* Category */}
                                <TableColumnHeadSelectSimple
                                  filterObject={proposalActionQuery}
                                  filterVariable="category"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Qualification",
                                      icon: "search-template",
                                      value: "Qualification",
                                    },
                                    {
                                      display: "Strategy",
                                      icon: "locate",
                                      value: "Strategy",
                                    },
                                    {
                                      display: "Solution",
                                      icon: "gantt-chart",
                                      value: "Solution",
                                    },
                                    {
                                      display: "Commercials",
                                      icon: "dollar",
                                      value: "Commercials",
                                    },
                                    {
                                      display: "Response",
                                      icon: "manually-entered-data",
                                      value: "Response",
                                    },
                                    {
                                      display: "Other",
                                      icon: "more",
                                      value: "Other",
                                    },
                                  ]}
                                  setFilterObject={setProposalActionQuery}
                                  title="Category"
                                  width="125px"
                                />

                                {/* Owner */}
                                <TableColumnHeadSelect2
                                  filterObject={proposalActionQuery}
                                  filterVariable="owner"
                                  items={managerList.managers}
                                  setFilterObject={setProposalActionQuery}
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Owner"
                                  width="200px"
                                />

                                {/* Due */}
                                <TableColumnHeadDate
                                  endDateVariable="dueEnd"
                                  queryObject={proposalActionQuery}
                                  setQueryObject={setProposalActionQuery}
                                  shortRange
                                  startDateVariable="dueStart"
                                  title="Due"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={proposalActionQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Completed",
                                      icon: "confirm",
                                      value: "Completed",
                                    },
                                    {
                                      display: "In-progress",
                                      icon: "refresh",
                                      value: "In-progress",
                                    },
                                    {
                                      display: "On-hold",
                                      icon: "remove",
                                      value: "On-hold",
                                    },
                                  ]}
                                  setFilterObject={setProposalActionQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              proposalDetails.proposal.user_access_status !==
                              "view"
                            }
                            loading={proposalActionList.loading}
                            noMargin
                            onClearFilters={() => {
                              setProposalActionQuery({
                                ...proposalActionQueryDefault,
                              });
                            }}
                            page={proposalActionQuery.page}
                            setPageObject={setProposalActionQuery}
                            setPageVariable="page"
                          />
                        )}

                        {/* Kanban */}
                        {showActionKanban && (
                          <Kanban
                            centerColumns
                            data={generateKanbanDataFromProposalActions(
                              proposalDetails.proposal.actions
                            )}
                            initialise={initialiseActionKanban}
                            noMargin
                            onClick={(id) => {
                              if (
                                proposalDetails.proposal.user_access_status !==
                                "view"
                              ) {
                                dispatch(listProposalActionDetailsAction(id));

                                setUpdateActionDrawerIsOpen(true);
                              }
                            }}
                            onDragEnd={(result) => {
                              const actionId = result.draggableId;
                              const actionStatus =
                                result.destination.droppableId;

                              updateActionStatus(actionId, actionStatus);
                            }}
                            searchBarMargin="-45px 0 20px 70px"
                            searchBarPlaceholder="Search actions"
                            setInitialise={setInitialiseActionKanban}
                            statusCategories={[
                              {
                                intent: "primary",
                                value: "On-hold",
                              },
                              {
                                intent: "warning",
                                value: "In-progress",
                              },
                              {
                                intent: "success",
                                value: "Completed",
                              },
                            ]}
                          />
                        )}
                      </>
                    }
                    help="Assign and review actions to complete the proposal"
                    icon="take-action"
                    message={
                      <>
                        advaise.app has prefilled this section with a standard
                        set of actions. Assign or delete them as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Actions"
                  />

                  {/* Research */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddQuestionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Resolved */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Resolved"
                                loading={proposalQuestionList.loading}
                                onClick={() => {
                                  setProposalQuestionQuery({
                                    ...proposalQuestionQuery,
                                    page: 1,
                                    status: "Resolved",
                                  });
                                }}
                                value={
                                  proposalQuestionList.numberOfQuestionsResolved
                                }
                              />

                              {/* Researching */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="Researching"
                                loading={proposalQuestionList.loading}
                                onClick={() => {
                                  setProposalQuestionQuery({
                                    ...proposalQuestionQuery,
                                    page: 1,
                                    status: "Researching",
                                  });
                                }}
                                value={
                                  proposalQuestionList.numberOfQuestionsResearching
                                }
                              />

                              {/* Not applicable */}
                              <DataCardSimple
                                intent="primary"
                                interactive
                                label="Not applicable"
                                loading={proposalQuestionList.loading}
                                onClick={() => {
                                  setProposalQuestionQuery({
                                    ...proposalQuestionQuery,
                                    page: 1,
                                    status: "Not applicable",
                                  });
                                }}
                                value={
                                  proposalQuestionList.numberOfQuestionsNotApplicable
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={proposalQuestionList.proposal_questions.map(
                            (proposal_question) => {
                              return (
                                <tr
                                  key={proposal_question.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalQuestionDetailsAction(
                                          proposal_question.id
                                        )
                                      );

                                      setUpdateQuestionDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Question */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{proposal_question.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Question</strong>
                                          </p>

                                          <p>{proposal_question.question}</p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Answer</strong>
                                          </p>

                                          <p>
                                            {proposal_question.answer ? (
                                              proposal_question.answer
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={proposal_question.name}
                                    />
                                  </td>

                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      value={proposal_question.category}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={proposal_question.owner_name}
                                      image={
                                        proposal_question.owner_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_question.status === "Resolved"
                                          ? "success"
                                          : proposal_question.status ===
                                            "Researching"
                                          ? "warning"
                                          : "primary"
                                      }
                                      value={proposal_question.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalQuestionQuery.category &&
                            !proposalQuestionQuery.owner &&
                            !proposalQuestionQuery.searchString &&
                            !proposalQuestionQuery.status
                          }
                          count={proposalQuestionList.count}
                          head={
                            <tr>
                              {/* Question */}
                              <TableColumnHeadSearch
                                queryObject={proposalQuestionQuery}
                                queryVariable="searchString"
                                setQueryObject={setProposalQuestionQuery}
                                setQueryVariable="searchString"
                                title="Question"
                                width="200px"
                              />

                              {/* Category */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalQuestionQuery}
                                filterVariable="category"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Authority",
                                    icon: "layout-hierarchy",
                                    value: "Authority",
                                  },
                                  {
                                    display: "Budget",
                                    icon: "dollar",
                                    value: "Budget",
                                  },
                                  {
                                    display: "Need",
                                    icon: "locate",
                                    value: "Need",
                                  },
                                  {
                                    display: "Timing",
                                    icon: "calendar",
                                    value: "Timing",
                                  },
                                ]}
                                setFilterObject={setProposalQuestionQuery}
                                title="Category"
                                width="125px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={proposalQuestionQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={setProposalQuestionQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalQuestionQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Resolved",
                                    icon: "issue-closed",
                                    value: "Resolved",
                                  },
                                  {
                                    display: "Researching",
                                    icon: "issue",
                                    value: "Researching",
                                  },
                                  {
                                    display: "Not applicable",
                                    icon: "disable",
                                    value: "Not applicable",
                                  },
                                ]}
                                setFilterObject={setProposalQuestionQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalQuestionList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalQuestionQuery({
                              ...proposalQuestionQueryDefault,
                            });
                          }}
                          page={proposalQuestionQuery.page}
                          setPageObject={setProposalQuestionQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage the research to evaluate and develop the proposal"
                    icon="search-template"
                    message={
                      <>
                        advaise.app has suggested a set of questions to ask.
                        Assign or delete them as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Research"
                  />

                  {/* Approvals */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddApprovalRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalRequestList.approval_requests.map(
                            (approval_request) => {
                              return (
                                <tr
                                  key={approval_request.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listApprovalRequestDetailsAction(
                                          approval_request.id
                                        )
                                      );

                                      if (
                                        approval_request.status === "Completed"
                                      ) {
                                        // Open a different drawer depending on the decision

                                        switch (
                                          approval_request.decision_right
                                        ) {
                                          default:
                                            setViewProposalQualificationApprovalDrawerIsOpen(
                                              true
                                            );
                                        }
                                      } else {
                                        setUpdateApprovalRequestDrawerIsOpen(
                                          true
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={approval_request.decision_right}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={approval_request.value}
                                      tagText={activeOrganisation.currency}
                                    />
                                  </td>

                                  {/* Approver */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={approval_request.approver_name}
                                      image={
                                        approval_request.approver_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .reviewed
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome === "Approved"
                                            ? "success"
                                            : approval_request.response_data
                                                .outcome === "Denied"
                                            ? "danger"
                                            : "primary"
                                          : undefined
                                      }
                                      value={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalRequestQuery.approver &&
                            !approvalRequestQuery.decisionRight &&
                            !approvalRequestQuery.outcome &&
                            !approvalRequestQuery.reviewedEnd &&
                            !approvalRequestQuery.reviewedStart &&
                            !approvalRequestQuery.value
                          }
                          count={approvalRequestList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Proposal qualification",
                                    value: "Proposal qualification",
                                  },
                                  /*{
                                    display: "Proposal strategy",
                                    value: "Proposal strategy",
                                  },*/
                                  /*{
                                    display: "Proposal solution",
                                    value: "Proposal solution",
                                  },*/
                                  /*{
                                    display: "Proposal commercials",
                                    value: "Proposal commercials",
                                  },*/
                                  {
                                    display: "Proposal submission",
                                    value: "Proposal submission",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Approver */}
                              <TableColumnHeadSelect2
                                filterObject={approvalRequestQuery}
                                filterVariable="approver"
                                items={managerList.managers}
                                setFilterObject={setApprovalRequestQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Approver"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalRequestQuery}
                                setQueryObject={setApprovalRequestQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalRequestQuery({
                              ...approvalRequestQueryDefault,
                            });
                          }}
                          page={approvalRequestQuery.page}
                          setPageObject={setApprovalRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage approval requests"
                    icon="book"
                    message="When you create an approval request, advaise.app generates a fact set to inform the approver's decision. This fact set is based on the information at the time. If there is a change, consider submitting a new approval request."
                    messageIntent="warning"
                    title="Approvals"
                  />
                </div>
              }
            />

            {/* Qualification */}
            <Tab
              id="proposal-qualification-tab"
              title="Qualification"
              panel={
                <div>
                  {/* Proposal statistics */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Won-lost */}
                              <ProposalWinLossCard
                                proposalList={proposalList}
                              />

                              {/* Average value */}
                              <ProposalAverageValueCard
                                fill
                                proposalList={proposalList}
                              />

                              {/* Total value */}
                              <ProposalTotalValueCard
                                proposalList={proposalList}
                              />
                            </>
                          }
                          fill
                          noMargin
                        />
                      </>
                    }
                    help="Review your proposal performance with this client"
                    icon="grouped-bar-chart"
                    title="Proposal statistics"
                  />

                  {/* Strategic alignment factors */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateStrategicAlignmentFactorsDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Market */}
                        <TabSectionField
                          label="Market"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_market_comments ? (
                                  proposalDetails.proposal
                                    .qualification_market_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Is the client in our target market?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_market_score
                              }
                            />
                          }
                        />

                        {/* Services */}
                        <TabSectionField
                          label="Services"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal
                                    .qualification_services_comments ? (
                                  proposalDetails.proposal
                                    .qualification_services_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Do we provide the services that the client needs?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_services_score
                              }
                            />
                          }
                        />

                        {/* Reputation */}
                        <TabSectionField
                          bottom
                          label="Reputation"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal
                                    .qualification_reputation_comments ? (
                                  proposalDetails.proposal
                                    .qualification_reputation_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Are we comfortable being associated with the client and this project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_reputation_score
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Investigate if the client is in your target market"
                    icon="locate"
                    title="Strategic alignment factors"
                  />

                  {/* Opportunity factors */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateOpportunityFactorsDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Budget */}
                        <TabSectionField
                          label="Budget"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_budget_comments ? (
                                  proposalDetails.proposal
                                    .qualification_budget_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Has the client allocated a budget to this project
                              that covers our expected fee?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_budget_score
                              }
                            />
                          }
                        />

                        {/* Need */}
                        <TabSectionField
                          label="Need"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_need_comments ? (
                                  proposalDetails.proposal
                                    .qualification_need_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Is this project aligned with the client&#39;s
                              long-term interests?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_need_score
                              }
                            />
                          }
                        />

                        {/* Timing */}
                        <TabSectionField
                          bottom
                          label="Timing"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_timing_comments ? (
                                  proposalDetails.proposal
                                    .qualification_timing_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Does the client have an urgent need for the
                              project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_timing_score
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Evaluate the opportunity"
                    icon="dollar"
                    title="Opportunity factors"
                  />

                  {/* Relationship factors */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateRelationshipFactorsDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Overall relationship */}
                        <TabSectionField
                          label="Overall relationship"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal
                                    .qualification_relationship_comments ? (
                                  proposalDetails.proposal
                                    .qualification_relationship_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="In general, do we have a positive relationship
                              with the client and third-parties involved in the
                              project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_relationship_score
                              }
                            />
                          }
                        />

                        {/* Economic buyer */}
                        <TabSectionField
                          label="Economic buyer"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_buyer_comments ? (
                                  proposalDetails.proposal
                                    .qualification_buyer_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Do we have a positive relationship with the person
                              who makes buying decisions on this project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_buyer_score
                              }
                            />
                          }
                        />

                        {/* Coach */}
                        <TabSectionField
                          bottom
                          label="Coach"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_coach_comments ? (
                                  proposalDetails.proposal
                                    .qualification_coach_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Do we have a client sponsor for this proposal?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_coach_score
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Reflect on your relationship"
                    icon="person"
                    title="Relationship factors"
                  />

                  {/* Delivery factors */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateDeliveryFactorsDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Team */}
                        <TabSectionField
                          label="Team"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.qualification_team_comments ? (
                                  proposalDetails.proposal
                                    .qualification_team_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Can we assign a suitably experienced team to this
                              project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_team_score
                              }
                            />
                          }
                        />

                        {/* Solution */}
                        <TabSectionField
                          label="Solution"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal
                                    .qualification_solution_comments ? (
                                  proposalDetails.proposal
                                    .qualification_solution_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Do we have a differentiated and proven solution to deliver this type of
                                project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_solution_score
                              }
                            />
                          }
                        />

                        {/* Dependencies */}
                        <TabSectionField
                          bottom
                          label="Dependencies"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal
                                    .qualification_dependencies_comments ? (
                                  proposalDetails.proposal
                                    .qualification_dependencies_comments
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="Can the client give us the support we need to
                              deliver the project?"
                              rating={
                                proposalDetails.proposal
                                  .qualification_dependencies_score
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Analyse your ability to deliver"
                    icon="build"
                    title="Delivery factors"
                  />

                  {/* Probability of win */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="scatter-plot"
                        intent="primary"
                        loading={proposalPredictWin.loading}
                        onClick={() => {
                          dispatch(predictWinProposalAction(id));
                        }}
                        text="Refresh"
                      />
                    }
                    content={
                      <>
                        {/* System prediction */}
                        <TabSectionField
                          bottom
                          helperText={probabilityWinMessage}
                          label="System prediction"
                          loading={
                            proposalPredictWin.loading ||
                            proposalDetails.loading
                          }
                          value={
                            <TabSectionFieldProgressBar
                              rating={probabilityWin}
                            />
                          }
                        />
                      </>
                    }
                    help="Review your likelihood of success"
                    icon="star"
                    message="advaise.app estimates the win probability based on historical data and your answers to the qualification questions."
                    messageIntent="primary"
                    title="Probability of win"
                  />
                </div>
              }
            />

            {/* Strategy */}
            <Tab
              id="proposal-strategy-tab"
              title="Strategy"
              panel={
                <div>
                  {/* Client performance analysis */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddClientKPIDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search KPI"
                            searching={clientKPIList.loading}
                            setValueObject={setClientKPIQuery}
                            setValueVariable="searchString"
                            value={clientKPIQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={clientKPIList.client_kpis.map(
                            (client_kpi) => {
                              return (
                                <KPICard
                                  interactive
                                  key={client_kpi.id}
                                  onClick={() => {
                                    dispatch(
                                      listClientKPIDetailsAction(client_kpi.id)
                                    );

                                    setUpdateClientKPIDrawerIsOpen(true);
                                  }}
                                  kpi={client_kpi}
                                />
                              );
                            }
                          )}
                          count={clientKPIList.count}
                          loading={clientKPIList.loading}
                          noMargin
                          noResultsMessage="Add KPIs."
                          noResultsTitle="No KPIs found"
                          page={clientKPIQuery.page}
                          setPageObject={setClientKPIQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Analyse the client's performance"
                    icon="dashboard"
                    message={
                      <>
                        The data in this section comes from the{" "}
                        <LinkTag
                          onClick={() => {
                            navigate(
                              `/clients/${proposalDetails.proposal.client}`
                            );
                          }}
                          label="client record"
                        />
                        . Add performance indicators as needed to diagnose the
                        client's challenges.
                      </>
                    }
                    messageIntent="primary"
                    title="Client performance analysis"
                  />

                  {/* Needs analysis */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateNeedsAnalysisDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Change drivers */}
                        <TabSectionField
                          label="Change drivers"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.change_drivers ? (
                                  proposalDetails.proposal.change_drivers
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What is driving the client to change?"
                            />
                          }
                        />

                        {/* Strategic options */}
                        <TabSectionField
                          label="Strategic options"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.strategic_options ? (
                                  proposalDetails.proposal.strategic_options
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What options does the client have?"
                            />
                          }
                        />

                        {/* Theory of change */}
                        <TabSectionField
                          label="Theory of change"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.theory_of_change ? (
                                  proposalDetails.proposal.theory_of_change
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What is the client's preferred strategy (or our leading hypothesis)?"
                            />
                          }
                        />

                        {/* Issues */}
                        <TabSectionField
                          label="Issues"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.issues ? (
                                  proposalDetails.proposal.issues
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What challenges face the client in moving forward?"
                            />
                          }
                        />

                        {/* Our solution */}
                        <TabSectionField
                          bottom
                          label="Our solution"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.our_solution ? (
                                  proposalDetails.proposal.our_solution
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What solutions can we bring to support the change?"
                            />
                          }
                        />
                      </>
                    }
                    help="Summarize the client's needs"
                    icon="compass"
                    title="Needs analysis"
                  />

                  {/* Key contacts */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddProposalContactDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        {/* Stakeholder matrix */}
                        {proposalContactList.proposal_contacts.length > 0 ? (
                          <Matrix
                            colors={[
                              [
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                            ]}
                            data={proposalContactList.proposal_contacts}
                            intents={[
                              [
                                "danger",
                                "danger",
                                "danger",
                                "warning",
                                "success",
                              ],
                              [
                                "danger",
                                "danger",
                                "warning",
                                "warning",
                                "success",
                              ],
                              [
                                "danger",
                                "warning",
                                "warning",
                                "success",
                                "success",
                              ],
                              [
                                "warning",
                                "warning",
                                "success",
                                "success",
                                "success",
                              ],
                              [
                                "warning",
                                "success",
                                "success",
                                "success",
                                "success",
                              ],
                            ]}
                            interactive
                            itemNameVariable="contact_name"
                            leftColumnWidth="150px"
                            onClickAccessTest={() =>
                              proposalDetails.proposal.user_access_status !==
                              "view"
                            }
                            onClickListDetailsAction={
                              listProposalContactDetailsAction
                            }
                            onClickSetDrawerIsOpen={
                              setUpdateProposalContactDrawerIsOpen
                            }
                            xAxisCategories={[1, 2, 3, 4, 5]}
                            xAxisDisplayCategories={[
                              "Very Negative",
                              "Negative",
                              "Neutral",
                              "Positive",
                              "Very Positive",
                            ]}
                            xAxisFilterVariable="attitude_toward_us"
                            xAxisLabel="Attitude toward us"
                            yAxisCategories={[5, 4, 3, 2, 1]}
                            yAxisDisplayCategories={[
                              "Very High",
                              "High",
                              "Medium",
                              "Low",
                              "Very Low",
                            ]}
                            yAxisFilterVariable="level_of_influence"
                            yAxisLabel="Influence"
                          />
                        ) : undefined}

                        {/* Search field */}
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search contact"
                            searching={proposalContactList.loading}
                            setValueObject={setProposalContactQuery}
                            setValueVariable="searchString"
                            value={proposalContactQuery.searchString}
                          />
                        </div>

                        {/* Stakeholders */}
                        <ItemCardList
                          content={proposalContactList.proposal_contacts.map(
                            (proposal_contact) => {
                              return (
                                <ContactCard
                                  additionalInfo={
                                    <>
                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Level of influence</strong>
                                      </p>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <TabSectionFieldProgressBar
                                            rating={
                                              proposal_contact.level_of_influence /
                                              5
                                            }
                                          />
                                        </div>

                                        <div
                                          className={[
                                            Classes.TEXT_MUTED,
                                            Classes.TEXT_SMALL,
                                          ].join(" ")}
                                        >
                                          {proposal_contact.level_of_influence ===
                                          5
                                            ? "Very high"
                                            : proposal_contact.level_of_influence ===
                                              4
                                            ? "High"
                                            : proposal_contact.level_of_influence ===
                                              3
                                            ? "Medium"
                                            : proposal_contact.level_of_influence ===
                                              2
                                            ? "Low"
                                            : "Very low"}
                                        </div>
                                      </div>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Buyer role</strong>
                                      </p>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {proposal_contact.buyer_role}
                                      </div>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>
                                          Stakeholder engagement strategy
                                        </strong>
                                      </p>

                                      <p>
                                        {proposal_contact.comments ? (
                                          proposal_contact.comments
                                        ) : (
                                          <span className={Classes.TEXT_MUTED}>
                                            No comments.
                                          </span>
                                        )}
                                      </p>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Relationship owner</strong>
                                      </p>

                                      <div style={{ marginBottom: "10px" }}>
                                        <NameValue
                                          email={
                                            proposal_contact.relationship_owner_name
                                          }
                                          image={
                                            proposal_contact.relationship_owner_profile_photo
                                          }
                                          invertColor
                                        />
                                      </div>
                                    </>
                                  }
                                  borderColor={Colors.ROSE3}
                                  data={proposal_contact.contact_data}
                                  interactive
                                  key={proposal_contact.id}
                                  onClick={() => {
                                    dispatch(
                                      listProposalContactDetailsAction(
                                        proposal_contact.id
                                      )
                                    );

                                    setUpdateProposalContactDrawerIsOpen(true);
                                  }}
                                />
                              );
                            }
                          )}
                          count={proposalContactList.count}
                          loading={proposalContactList.loading}
                          noMargin
                          noResultsMessage="Add contacts."
                          noResultsTitle="No contacts found"
                          page={proposalContactQuery.page}
                          setPageObject={setProposalContactQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Define your stakeholder management plan"
                    icon="following"
                    title="Key contacts"
                  />

                  {/* Competitor analysis */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddCompetitorDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Search field */}
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search competitor"
                            searching={proposalCompetitorList.loading}
                            setValueObject={setProposalCompetitorQuery}
                            setValueVariable="searchString"
                            value={proposalCompetitorQuery.searchString}
                          />
                        </div>

                        {/* Competitors */}
                        <ItemCardList
                          content={proposalCompetitorList.proposal_competitors.map(
                            (proposal_competitor) => {
                              return (
                                <ProposalCompetitorCard
                                  competitor={proposal_competitor}
                                  key={proposal_competitor.id}
                                  onClick={() => {
                                    dispatch(
                                      listProposalCompetitorDetailsAction(
                                        proposal_competitor.id
                                      )
                                    );

                                    setUpdateCompetitorDrawerIsOpen(true);
                                  }}
                                />
                              );
                            }
                          )}
                          count={proposalCompetitorList.count}
                          loading={proposalCompetitorList.loading}
                          noMargin
                          noResultsMessage="Add competitor."
                          noResultsTitle="No competitors found"
                          page={proposalCompetitorQuery.page}
                          setPageObject={setProposalCompetitorQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Analyse your competitors"
                    icon="comparison"
                    title="Competitor analysis"
                  />

                  {/* Win strategy */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateWinStrategyDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Commercials */}
                        <TabSectionField
                          label="Commercials"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_commercials ? (
                                  proposalDetails.proposal
                                    .win_strategy_commercials
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How can we provide more compelling terms and conditions than our competitors?"
                            />
                          }
                        />

                        {/* Partnerships */}
                        <TabSectionField
                          label="Partnerships"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_partnerships ? (
                                  proposalDetails.proposal
                                    .win_strategy_partnerships
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How can we partner with other organisations to offer a complete solution?"
                            />
                          }
                        />

                        {/* Price */}
                        <TabSectionField
                          label="Price"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_price ? (
                                  proposalDetails.proposal.win_strategy_price
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How can we provide the best value-for-money?"
                            />
                          }
                        />

                        {/* References */}
                        <TabSectionField
                          label="References"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_references ? (
                                  proposalDetails.proposal
                                    .win_strategy_references
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="
                              How can we secure more credible and high-profile references than our competitors?"
                            />
                          }
                        />

                        {/* Relationships */}
                        <TabSectionField
                          label="Relationships"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_relationships ? (
                                  proposalDetails.proposal
                                    .win_strategy_relationships
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How can we better engage with the key stakeholders than our competitors?"
                            />
                          }
                        />

                        {/* Solution */}
                        <TabSectionField
                          label="Solution"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_solution ? (
                                  proposalDetails.proposal.win_strategy_solution
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How can we leverage our proprietary assets and differentiated skills to bring the best solution?"
                            />
                          }
                        />

                        {/* Team */}
                        <TabSectionField
                          label="Team"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_team ? (
                                  proposalDetails.proposal.win_strategy_team
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How can we bring a more experienced and high-profile team than our competitors?"
                            />
                          }
                        />

                        {/* Other */}
                        <TabSectionField
                          bottom
                          label="Other"
                          value={
                            <TabSectionFieldCard
                              loading={proposalDetails.loading}
                              text={
                                proposalDetails.loading ? undefined : proposalDetails
                                    .proposal.win_strategy_other ? (
                                  proposalDetails.proposal.win_strategy_other
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How else can we provide more value than our competitors?"
                            />
                          }
                        />
                      </>
                    }
                    help="Identify your differentiators"
                    icon="playbook"
                    title="Win strategy"
                  />
                </div>
              }
            />

            {/* Solution */}
            <Tab
              id="proposal-solution-tab"
              title="Solution"
              panel={
                <div>
                  {/* Responses to questions/requirements */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddRequirementDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <Button
                          icon="download"
                          intent="primary"
                          loading={proposalRequirementDownload.loading}
                          onClick={() => {
                            dispatch(downloadManagersAction(managerList.count));

                            dispatch(
                              downloadProposalRequirementsAction(
                                id,
                                proposalRequirementList.count
                              )
                            );
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Download"
                        />

                        <Button
                          disabled={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                          }
                          icon="upload"
                          intent="primary"
                          onClick={() => {
                            setUploadProposalRequirementsDialogIsOpen(true);
                          }}
                          text="Upload"
                        />
                      </>
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Compliant */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Compliant"
                                loading={proposalRequirementList.loading}
                                onClick={() => {
                                  setProposalRequirementQuery({
                                    ...proposalRequirementQuery,
                                    page: 1,
                                    status: "Compliant",
                                  });
                                }}
                                value={
                                  proposalRequirementList.numberOfRequirementsCompliant
                                }
                              />

                              {/* Part-compliant */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="Part-compliant"
                                loading={proposalRequirementList.loading}
                                onClick={() => {
                                  setProposalRequirementQuery({
                                    ...proposalRequirementQuery,
                                    page: 1,
                                    status: "Part-compliant",
                                  });
                                }}
                                value={
                                  proposalRequirementList.numberOfRequirementsPartCompliant
                                }
                              />

                              {/* Non-compliant */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Non-compliant"
                                loading={proposalRequirementList.loading}
                                onClick={() => {
                                  setProposalRequirementQuery({
                                    ...proposalRequirementQuery,
                                    page: 1,
                                    status: "Non-compliant",
                                  });
                                }}
                                value={
                                  proposalRequirementList.numberOfRequirementsNonCompliant
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={proposalRequirementList.proposal_requirements.map(
                            (proposal_requirement) => {
                              return (
                                <tr
                                  key={proposal_requirement.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalRequirementDetailsAction(
                                          proposal_requirement.id
                                        )
                                      );

                                      setUpdateRequirementDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Requirement */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{proposal_requirement.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Requirement</strong>
                                          </p>

                                          <p>
                                            {proposal_requirement.requirement}
                                          </p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Response</strong>
                                          </p>

                                          <p>
                                            {proposal_requirement.response ? (
                                              proposal_requirement.response
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No content.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={proposal_requirement.name}
                                    />
                                  </td>

                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={proposal_requirement.category}
                                    />
                                  </td>

                                  {/* Client reference */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      value={proposal_requirement.ref}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={proposal_requirement.owner_name}
                                      image={
                                        proposal_requirement.owner_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_requirement.status ===
                                        "Compliant"
                                          ? "success"
                                          : proposal_requirement.status ===
                                            "Part-compliant"
                                          ? "warning"
                                          : "danger"
                                      }
                                      value={proposal_requirement.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalRequirementQuery.category &&
                            !proposalRequirementQuery.owner &&
                            !proposalRequirementQuery.ref &&
                            !proposalRequirementQuery.searchString &&
                            !proposalRequirementQuery.status
                          }
                          count={proposalRequirementList.count}
                          head={
                            <tr>
                              {/* Requirement */}
                              <TableColumnHeadSearch
                                queryObject={proposalRequirementQuery}
                                queryVariable="searchString"
                                setQueryObject={setProposalRequirementQuery}
                                setQueryVariable="searchString"
                                title="Requirement"
                                width="200px"
                              />

                              {/* Category */}
                              <TableColumnHeadSearch
                                queryObject={proposalRequirementQuery}
                                queryVariable="category"
                                setQueryObject={setProposalRequirementQuery}
                                setQueryVariable="category"
                                title="Category"
                                width="200px"
                              />

                              {/* Client Ref */}
                              <TableColumnHeadSearch
                                queryObject={proposalRequirementQuery}
                                queryVariable="ref"
                                setQueryObject={setProposalRequirementQuery}
                                setQueryVariable="ref"
                                title="Client Ref"
                                width="125px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={proposalRequirementQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={setProposalRequirementQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRequirementQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Compliant",
                                    icon: "full-circle",
                                    value: "Compliant",
                                  },
                                  {
                                    display: "Part-compliant",
                                    icon: "ring",
                                    value: "Part-compliant",
                                  },
                                  {
                                    display: "Non-compliant",
                                    icon: "circle",
                                    value: "Non-compliant",
                                  },
                                ]}
                                setFilterObject={setProposalRequirementQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalRequirementList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalRequirementQuery({
                              ...proposalRequirementQueryDefault,
                            });
                          }}
                          page={proposalRequirementQuery.page}
                          setPageObject={setProposalRequirementQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Respond to the client's questions/requirements"
                    icon="form"
                    message={
                      <>
                        You can download and upload requirements. This may be
                        useful when the client provides questions/requirements
                        that you can copy and paste. If you fill the responses
                        online, advaise.app can recommend the content based on
                        previous responses.
                      </>
                    }
                    messageIntent="primary"
                    title="Responses to questions/requirements"
                  />

                  {/* Our approach */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateApproachDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Approach summary */}
                        <TabSectionField
                          label="Approach summary"
                          loading={proposalDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={
                                proposalDetails.proposal.approach_summary ? (
                                  proposalDetails.proposal.approach_summary
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No description
                                  </span>
                                )
                              }
                            />
                          }
                        />

                        {/* Approach diagram */}
                        <TabSectionField
                          bottom
                          label="Approach diagram"
                          loading={proposalDetails.loading}
                          value={
                            <TabSectionFieldImage
                              fill
                              image={proposalDetails.proposal.approach_diagram}
                              interactive
                            />
                          }
                        />
                      </>
                    }
                    help="Summarize your approach to the engagement"
                    icon="gantt-chart"
                    title="Our approach"
                  />

                  {/* Services */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddServiceDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        {/* Search field */}
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search service"
                            searching={proposalServiceList.loading}
                            setValueObject={setProposalServiceQuery}
                            setValueVariable="searchString"
                            value={proposalServiceQuery.searchString}
                          />
                        </div>

                        {/* Services */}
                        <TabSectionFieldMultifieldCardList
                          content={proposalServiceList.proposal_services.map(
                            (proposal_service, i, { length }) => {
                              return (
                                <TabSectionField
                                  bottom={length - 1 === i}
                                  key={proposal_service.id}
                                  label={proposal_service.name}
                                  value={
                                    <TabSectionFieldMultifieldCard
                                      children={
                                        <>
                                          {/* Description */}
                                          <TabSectionField
                                            label="Description"
                                            value={proposal_service.description}
                                          />

                                          {/* Benefits */}
                                          <TabSectionField
                                            bottom
                                            label="Benefits"
                                            value={proposal_service.benefits}
                                          />
                                        </>
                                      }
                                      interactive={
                                        proposalDetails.proposal
                                          .user_access_status !== "view"
                                      }
                                      onClick={() => {
                                        if (
                                          proposalDetails.proposal
                                            .user_access_status !== "view"
                                        ) {
                                          dispatch(
                                            listProposalServiceDetailsAction(
                                              proposal_service.id
                                            )
                                          );

                                          setUpdateServiceDrawerIsOpen(true);
                                        }
                                      }}
                                    />
                                  }
                                />
                              );
                            }
                          )}
                          count={proposalServiceList.count}
                          loading={proposalServiceList.loading}
                          noResultsMessage="Add services."
                          noResultsTitle="No services found"
                          page={proposalServiceQuery.page}
                          setPageObject={setProposalServiceQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Scope the services that you will deliver"
                    icon="heat-grid"
                    title="Services"
                  />

                  {/* Deliverables */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddDeliverableDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={proposalServiceDeliverableList.proposal_service_deliverables.map(
                            (deliverable) => {
                              return (
                                <tr
                                  key={deliverable.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalServiceDeliverableDetailsAction(
                                          deliverable.id
                                        )
                                      );

                                      setUpdateDeliverableDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Deliverable */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{deliverable.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Description</strong>
                                          </p>

                                          <p>
                                            {deliverable.description ? (
                                              deliverable.description
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No description.
                                              </span>
                                            )}
                                          </p>

                                          {deliverable.template && (
                                            <div
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                              style={{ textAlign: "end" }}
                                            >
                                              <Button
                                                icon="document"
                                                intent="primary"
                                                minimal
                                                onClick={() =>
                                                  window.open(
                                                    deliverable.template,
                                                    "_blank",
                                                    "noopener,noreferrer"
                                                  )
                                                }
                                                style={{
                                                  color: lightMode
                                                    ? Colors.BLUE5
                                                    : Colors.BLUE3,
                                                }}
                                                text="Template"
                                              />
                                            </div>
                                          )}
                                        </>
                                      }
                                      tagText={deliverable.type}
                                      text={deliverable.name}
                                    />
                                  </td>

                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={deliverable.service_name}
                                    />
                                  </td>

                                  {/* Due */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={deliverable.due} />
                                  </td>

                                  {/* Milestone */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={deliverable.milestone_name}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalServiceDeliverableQuery.dueEnd &&
                            !proposalServiceDeliverableQuery.dueStart &&
                            !proposalServiceDeliverableQuery.milestone &&
                            !proposalServiceDeliverableQuery.searchString &&
                            !proposalServiceDeliverableQuery.service
                          }
                          count={proposalServiceDeliverableList.count}
                          head={
                            <tr>
                              {/* Deliverable */}
                              <TableColumnHeadSearch
                                queryObject={proposalServiceDeliverableQuery}
                                queryVariable="searchString"
                                setQueryObject={
                                  setProposalServiceDeliverableQuery
                                }
                                setQueryVariable="searchString"
                                title="Deliverable"
                                width="300px"
                              />

                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={proposalServiceDeliverableQuery}
                                filterVariable="service"
                                items={proposalServiceList.proposal_services}
                                setFilterObject={
                                  setProposalServiceDeliverableQuery
                                }
                                setQueryObject={setProposalServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={proposalServiceDeliverableQuery}
                                setQueryObject={
                                  setProposalServiceDeliverableQuery
                                }
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Milestone */}
                              <TableColumnHeadSearch
                                queryObject={proposalServiceDeliverableQuery}
                                queryVariable="milestone"
                                setQueryObject={
                                  setProposalServiceDeliverableQuery
                                }
                                setQueryVariable="milestone"
                                title="Milestone"
                                width="200px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalServiceDeliverableList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalServiceDeliverableQuery({
                              ...proposalServiceDeliverableQueryDefault,
                            });
                          }}
                          page={proposalServiceDeliverableQuery.page}
                          setPageObject={setProposalServiceDeliverableQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage the proposal's deliverables"
                    icon="briefcase"
                    title="Deliverables"
                  />

                  {/* Schedule */}
                  <TabSection2
                    buttons={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddActivityDrawerIsOpen(true);
                          }}
                          text="Add"
                        />

                        <ButtonGroup>
                          <Button
                            icon="media"
                            onClick={async () => {
                              const imageFile =
                                await generateImageFromComponent(scheduleRef);

                              setProposalDocument((prevState) => {
                                return {
                                  ...prevState,
                                  scheduleDiagram: imageFile,
                                };
                              });
                            }}
                            style={{ marginRight: "5px" }}
                          />

                          <Button
                            icon="maximize"
                            onClick={() => {
                              setScheduleDialogIsOpen(true);
                            }}
                          />
                        </ButtonGroup>
                      </div>
                    }
                    content={
                      <div>
                        <Timeline4
                          data={generateTimelineDataFromProposalServices(
                            proposalDetails.proposal.services
                          )}
                          height="70vh"
                          itemListHeading="Services/Activities"
                          loading={proposalDetails.loading}
                          onClick={(id, type) => {
                            if (type === "child") {
                              dispatch(
                                listProposalServiceActivityDetailsAction(
                                  id.split(".")[1]
                                )
                              );

                              setUpdateActivityDrawerIsOpen(true);
                            } else if (type === "item") {
                              dispatch(listProposalServiceDetailsAction(id));

                              setUpdateServiceDrawerIsOpen(true);
                            } else if (type === "milestone") {
                              dispatch(
                                listProposalServiceDeliverableDetailsAction(
                                  id.split(".")[1]
                                )
                              );

                              setUpdateDeliverableDrawerIsOpen(true);
                            } else {
                              dispatch(
                                listProposalLabourExpenseDetailsAction(
                                  id.split(".")[1]
                                )
                              );

                              setUpdateLabourExpenseDrawerIsOpen(true);
                            }
                          }}
                          resourceData={generateTimelineResourceDataFromProposalServices(
                            proposalDetails.proposal.services
                          )}
                          scheduleRef={scheduleRef}
                          showDates
                        />
                      </div>
                    }
                    help="Manage the proposal's schedule"
                    icon="gantt-chart"
                    title="Schedule"
                  />

                  {/* Key personnel */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddStaffDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search staff"
                            searching={proposalStaffList.loading}
                            setValueObject={setProposalStaffQuery}
                            setValueVariable="searchString"
                            value={proposalStaffQuery.member}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={proposalStaffList.proposal_staff.map(
                            (proposal_staff, i, { length }) => {
                              return (
                                <TabSectionField
                                  bottom={length - 1 === i}
                                  key={proposal_staff.id}
                                  label={
                                    <div
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        alt="profile"
                                        src={
                                          proposal_staff.staff_data
                                            .profile_photo
                                            ? proposal_staff.staff_data
                                                .profile_photo
                                            : "/images/no_photo.png"
                                        }
                                        style={{
                                          background: "white",
                                          border: `1px solid ${
                                            lightMode
                                              ? Colors.LIGHT_GRAY5
                                              : Colors.DARK_GRAY5
                                          }`,
                                          borderRadius: "50%",
                                          height: "150px",
                                          margin: "10px 0 10px 0",
                                          objectFit: "contain",
                                          width: "150px",
                                        }}
                                      />

                                      <Text ellipsize>
                                        {proposal_staff.staff_data.name}
                                      </Text>

                                      <div style={{ marginTop: "5px" }}>
                                        <InfoPopup
                                          content={
                                            <StaffInfo
                                              query={staffQueryDefault}
                                              staffId={proposal_staff.staff}
                                            />
                                          }
                                        />
                                      </div>
                                    </div>
                                  }
                                  value={
                                    <TabSectionFieldMultifieldCard
                                      children={
                                        <>
                                          {/* Role */}
                                          <TabSectionField
                                            label="Role"
                                            value={proposal_staff.role}
                                          />

                                          {/* Area of expertise #1 */}
                                          <TabSectionField
                                            label="Area of expertise #1"
                                            value={
                                              proposal_staff.expertise_1 ? (
                                                <Tag
                                                  large
                                                  round
                                                  style={{
                                                    background: Colors.GREEN3,
                                                    color: setTextColour(
                                                      Colors.GREEN3
                                                    ),
                                                    textAlign: "center",
                                                    maxWidth: "300px",
                                                    minWidth: "100px",
                                                  }}
                                                >
                                                  {proposal_staff.expertise_1}
                                                </Tag>
                                              ) : undefined
                                            }
                                          />

                                          {/* Area of expertise #2 */}
                                          <TabSectionField
                                            label="Area of expertise #2"
                                            value={
                                              proposal_staff.expertise_2 ? (
                                                <Tag
                                                  large
                                                  minimal
                                                  round
                                                  style={{
                                                    background: Colors.GREEN3,
                                                    color: setTextColour(
                                                      Colors.GREEN3
                                                    ),
                                                    textAlign: "center",
                                                    maxWidth: "300px",
                                                    minWidth: "100px",
                                                  }}
                                                >
                                                  {proposal_staff.expertise_2}
                                                </Tag>
                                              ) : undefined
                                            }
                                          />

                                          {/* Area of expertise #3 */}
                                          <TabSectionField
                                            label="Area of expertise #3"
                                            value={
                                              proposal_staff.expertise_3 ? (
                                                <Tag
                                                  large
                                                  minimal
                                                  round
                                                  style={{
                                                    background: Colors.GREEN3,
                                                    color: setTextColour(
                                                      Colors.GREEN3
                                                    ),
                                                    textAlign: "center",
                                                    maxWidth: "300px",
                                                    minWidth: "100px",
                                                  }}
                                                >
                                                  {proposal_staff.expertise_3}
                                                </Tag>
                                              ) : undefined
                                            }
                                          />

                                          {/* Responsibilities */}
                                          <TabSectionField
                                            label="Responsibilities"
                                            value={
                                              proposal_staff.responsibilities
                                            }
                                          />

                                          {/* Relevant experience */}
                                          <TabSectionField
                                            bottom
                                            label="Relevant experience"
                                            value={proposal_staff.experience}
                                          />
                                        </>
                                      }
                                      interactive={
                                        proposalDetails.proposal
                                          .user_access_status !== "view"
                                      }
                                      onClick={() => {
                                        if (
                                          proposalDetails.proposal
                                            .user_access_status !== "view"
                                        ) {
                                          dispatch(
                                            listProposalStaffDetailsAction(
                                              proposal_staff.id
                                            )
                                          );

                                          setUpdateStaffDrawerIsOpen(true);
                                        }
                                      }}
                                    />
                                  }
                                />
                              );
                            }
                          )}
                          count={proposalStaffList.count}
                          loading={proposalStaffList.loading}
                          noResultsMessage="Add personnel."
                          noResultsTitle="No personnel found"
                          page={proposalStaffQuery.page}
                          setPageObject={setProposalStaffQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Assign key staff to the engagement"
                    icon="people"
                    title="Key personnel"
                  />

                  {/* Labour expenses */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddLabourExpenseDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={proposalLabourExpenseList.proposal_labour_expenses.map(
                            (proposal_labour_expense) => {
                              return (
                                <tr
                                  key={proposal_labour_expense.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalLabourExpenseDetailsAction(
                                          proposal_labour_expense.id
                                        )
                                      );

                                      setUpdateLabourExpenseDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Role */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <LabourExpenseInfo
                                          labourExpense={
                                            proposal_labour_expense
                                          }
                                        />
                                      }
                                      text={
                                        proposal_labour_expense.role_data
                                          ? proposal_labour_expense.role_data
                                              .name
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={
                                        proposal_labour_expense.proposal_service_name
                                      }
                                    />
                                  </td>

                                  {/* Utilisation */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_labour_expense.utilisation >
                                        110
                                          ? "danger"
                                          : proposal_labour_expense.utilisation >
                                            100
                                          ? "warning"
                                          : "success"
                                      }
                                      value={
                                        <PercentageValue
                                          percentage={
                                            proposal_labour_expense.utilisation
                                          }
                                        />
                                      }
                                    />
                                  </td>

                                  {/* Effort */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      value={`${
                                        convertMinutesToHoursAndMinutes(
                                          proposal_labour_expense.minutes
                                        ).split(":")[0]
                                      }h ${
                                        convertMinutesToHoursAndMinutes(
                                          proposal_labour_expense.minutes
                                        ).split(":")[1]
                                      }m`}
                                    />
                                  </td>

                                  {/* Cost */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_labour_expense.cost}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_labour_expense.margin}
                                      tagNumber={
                                        proposal_labour_expense.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Fee */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_labour_expense.fee}
                                    />

                                    {activeOrganisation.currency !==
                                      proposalDetails.proposal
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            proposal_labour_expense.fee_quote
                                          }
                                          tagText={
                                            proposalDetails.proposal
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalLabourExpenseQuery.proposalService &&
                            !proposalLabourExpenseQuery.role
                          }
                          count={proposalLabourExpenseList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="4"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={proposalLabourExpenseList.total_cost}
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    proposalLabourExpenseList.total_margin
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={proposalLabourExpenseList.total_fee}
                                />

                                {activeOrganisation.currency !==
                                  proposalDetails.proposal.currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        proposalLabourExpenseList.total_fee_quote
                                      }
                                      tagText={
                                        proposalDetails.proposal.currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Role */}
                              <TableColumnHeadSelect2
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="role"
                                items={roleList.roles}
                                setFilterObject={setProposalLabourExpenseQuery}
                                setQueryObject={setRoleQuery}
                                setQueryVariable="searchString"
                                title="Role"
                                width="200px"
                              />

                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="proposalService"
                                items={proposalServiceList.proposal_services}
                                setFilterObject={setProposalLabourExpenseQuery}
                                setQueryObject={setProposalServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Utilisation */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "utilisation",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-utilisation",
                                  },
                                ]}
                                setFilterObject={setProposalLabourExpenseQuery}
                                title="Utilisation"
                                width="125px"
                              />

                              {/* Effort */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-minutes",
                                  },
                                ]}
                                setFilterObject={setProposalLabourExpenseQuery}
                                title="Effort"
                                width="125px"
                              />

                              {/* Cost */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "cost",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-cost",
                                  },
                                ]}
                                setFilterObject={setProposalLabourExpenseQuery}
                                title="Cost"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={setProposalLabourExpenseQuery}
                                title="Margin"
                                width="125px"
                              />

                              {/* Fee */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "fee",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-fee",
                                  },
                                ]}
                                setFilterObject={setProposalLabourExpenseQuery}
                                title="Fee"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalLabourExpenseList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalLabourExpenseQuery({
                              ...proposalLabourExpenseQueryDefault,
                            });
                          }}
                          page={proposalLabourExpenseQuery.page}
                          setPageObject={setProposalLabourExpenseQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Estimate the human resources cost"
                    icon="walk"
                    message={
                      proposalDetails.proposal.engagement_id ? (
                        <>
                          The system-generated tentative staff bookings have
                          been transferred to engagement id{" "}
                          {proposalDetails.proposal.engagement_id}. You can
                          update the labour expense profile of this proposal but
                          your changes will not impact the engagement's staff
                          bookings.
                        </>
                      ) : (
                        <>
                          This section captures internal labour costs.
                          advaise.app populates the hourly cost rate for each
                          role based on information from the{" "}
                          <LinkTag
                            label="Roles"
                            onClick={() => {
                              navigate(`/roles`);
                            }}
                          />{" "}
                          module. The system also generates tentative staff
                          bookings based on start and end dates.
                        </>
                      )
                    }
                    messageIntent={
                      proposalDetails.proposal.engagement_id
                        ? "warning"
                        : "primary"
                    }
                    title="Labour expenses"
                  />

                  {/* Non-labour expenses */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddNonLabourExpenseDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={proposalNonLabourExpenseList.proposal_non_labour_expenses.map(
                            (proposal_non_labour_expense) => {
                              return (
                                <tr
                                  key={proposal_non_labour_expense.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalNonLabourExpenseDetailsAction(
                                          proposal_non_labour_expense.id
                                        )
                                      );

                                      setUpdateNonLabourExpenseDrawerIsOpen(
                                        true
                                      );
                                    }
                                  }}
                                >
                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <NonLabourExpenseInfo
                                          nonLabourExpense={
                                            proposal_non_labour_expense
                                          }
                                        />
                                      }
                                      text={
                                        proposal_non_labour_expense.category_data
                                          ? proposal_non_labour_expense
                                              .category_data.name
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={
                                        proposal_non_labour_expense.proposal_service_name
                                      }
                                    />
                                  </td>

                                  {/* Cost */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_non_labour_expense.cost}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={
                                        proposal_non_labour_expense.margin
                                      }
                                      tagNumber={
                                        proposal_non_labour_expense.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Fee */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_non_labour_expense.fee}
                                    />

                                    {activeOrganisation.currency !==
                                      proposalDetails.proposal
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            proposal_non_labour_expense.fee_quote
                                          }
                                          tagText={
                                            proposalDetails.proposal
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalNonLabourExpenseQuery.category &&
                            !proposalNonLabourExpenseQuery.proposalService
                          }
                          count={proposalNonLabourExpenseList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    proposalNonLabourExpenseList.total_cost
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    proposalNonLabourExpenseList.total_margin
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    proposalNonLabourExpenseList.total_fee
                                  }
                                />

                                {activeOrganisation.currency !==
                                  proposalDetails.proposal.currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        proposalNonLabourExpenseList.total_fee_quote
                                      }
                                      tagText={
                                        proposalDetails.proposal.currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Category */}
                              <TableColumnHeadSelect2
                                filterObject={proposalNonLabourExpenseQuery}
                                filterVariable="category"
                                items={expenseCategoryList.expense_categories}
                                setFilterObject={
                                  setProposalNonLabourExpenseQuery
                                }
                                setQueryObject={setExpenseCategoryQuery}
                                setQueryVariable="searchString"
                                title="Category"
                                width="200px"
                              />

                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={proposalNonLabourExpenseQuery}
                                filterVariable="proposalService"
                                items={proposalServiceList.proposal_services}
                                setFilterObject={
                                  setProposalNonLabourExpenseQuery
                                }
                                setQueryObject={setProposalServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Cost */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalNonLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "cost",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-cost",
                                  },
                                ]}
                                setFilterObject={
                                  setProposalNonLabourExpenseQuery
                                }
                                title="Cost"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalNonLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={
                                  setProposalNonLabourExpenseQuery
                                }
                                title="Margin"
                                width="125px"
                              />

                              {/* Fee */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalNonLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "fee",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-fee",
                                  },
                                ]}
                                setFilterObject={
                                  setProposalNonLabourExpenseQuery
                                }
                                title="Fee"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalNonLabourExpenseList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalNonLabourExpenseQuery({
                              ...proposalNonLabourExpenseQueryDefault,
                            });
                          }}
                          page={proposalNonLabourExpenseQuery.page}
                          setPageObject={setProposalNonLabourExpenseQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Estimate the materials cost"
                    icon="credit-card"
                    title="Non-labour expenses"
                  />

                  {/* Risk management strategy */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddRiskDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Risk matrix */}
                        {proposalRiskList.proposal_risks.length > 0 ? (
                          <Matrix
                            colors={[
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                              ],
                              [
                                `${Colors.GREEN5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.RED5}33`,
                              ],
                              [
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.ORANGE5}33`,
                              ],
                            ]}
                            data={proposalRiskList.proposal_risks}
                            intents={[
                              ["warning", "danger", "danger"],
                              ["success", "warning", "danger"],
                              ["success", "success", "warning"],
                            ]}
                            interactive
                            leftColumnWidth="150px"
                            onClickAccessTest={() =>
                              proposalDetails.proposal.user_access_status !==
                              "view"
                            }
                            onClickListDetailsAction={
                              listProposalRiskDetailsAction
                            }
                            onClickSetDrawerIsOpen={setUpdateRiskDrawerIsOpen}
                            tableTop
                            xAxisCategories={["Low", "Medium", "High"]}
                            xAxisFilterVariable="likelihood"
                            xAxisLabel="Likelihood"
                            yAxisCategories={["High", "Medium", "Low"]}
                            yAxisFilterVariable="impact"
                            yAxisLabel="Impact"
                          />
                        ) : undefined}

                        {/* Data table */}
                        <TableContainer2
                          body={proposalRiskList.proposal_risks.map(
                            (proposal_risk) => {
                              return (
                                <tr
                                  key={proposal_risk.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalRiskDetailsAction(
                                          proposal_risk.id
                                        )
                                      );

                                      setUpdateRiskDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Risk */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{proposal_risk.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Risk</strong>
                                          </p>

                                          <p>{proposal_risk.risk}</p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Strategy</strong>
                                          </p>

                                          <p>
                                            {proposal_risk.strategy ? (
                                              proposal_risk.strategy
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={proposal_risk.name}
                                    />
                                  </td>

                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag value={proposal_risk.category} />
                                  </td>

                                  {/* Impact */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_risk.impact === "High"
                                          ? "danger"
                                          : proposal_risk.impact === "Low"
                                          ? "success"
                                          : "warning"
                                      }
                                      value={proposal_risk.impact}
                                    />
                                  </td>

                                  {/* Likelihood */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_risk.likelihood === "High"
                                          ? "danger"
                                          : proposal_risk.likelihood === "Low"
                                          ? "success"
                                          : "warning"
                                      }
                                      value={proposal_risk.likelihood}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={proposal_risk.owner_name}
                                      image={proposal_risk.owner_profile_photo}
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_risk.status === "Closed"
                                          ? "success"
                                          : "danger"
                                      }
                                      value={proposal_risk.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalRiskQuery.category &&
                            !proposalRiskQuery.impact &&
                            !proposalRiskQuery.likelihood &&
                            !proposalRiskQuery.owner &&
                            !proposalRiskQuery.risk &&
                            !proposalRiskQuery.searchString &&
                            !proposalRiskQuery.status
                          }
                          count={proposalRiskList.count}
                          head={
                            <tr>
                              {/* Risk */}
                              <TableColumnHeadSearch
                                queryObject={proposalRiskQuery}
                                queryVariable="searchString"
                                setQueryObject={setProposalRiskQuery}
                                setQueryVariable="searchString"
                                title="Risk"
                                width="200px"
                              />

                              {/* Category */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRiskQuery}
                                filterVariable="category"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Information",
                                    icon: "database",
                                    value: "Information",
                                  },
                                  {
                                    display: "Partners",
                                    icon: "briefcase",
                                    value: "Partners",
                                  },
                                  {
                                    display: "Process",
                                    icon: "flow-end",
                                    value: "Process",
                                  },
                                  {
                                    display: "Team",
                                    icon: "people",
                                    value: "Team",
                                  },
                                  {
                                    display: "Technology",
                                    icon: "cloud-download",
                                    value: "Technology",
                                  },
                                  {
                                    display: "Other",
                                    icon: "more",
                                    value: "Other",
                                  },
                                ]}
                                setFilterObject={setProposalRiskQuery}
                                title="Category"
                                width="125px"
                              />

                              {/* Impact */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRiskQuery}
                                filterVariable="impact"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "High",
                                    icon: "arrow-up",
                                    value: "High",
                                  },
                                  {
                                    display: "Medium",
                                    icon: "arrow-right",
                                    value: "Medium",
                                  },
                                  {
                                    display: "Low",
                                    icon: "arrow-down",
                                    value: "Low",
                                  },
                                ]}
                                setFilterObject={setProposalRiskQuery}
                                title="Impact"
                                width="125px"
                              />

                              {/* Likelihood */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRiskQuery}
                                filterVariable="likelihood"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "High",
                                    icon: "arrow-up",
                                    value: "High",
                                  },
                                  {
                                    display: "Medium",
                                    icon: "arrow-right",
                                    value: "Medium",
                                  },
                                  {
                                    display: "Low",
                                    icon: "arrow-down",
                                    value: "Low",
                                  },
                                ]}
                                setFilterObject={setProposalRiskQuery}
                                title="Likelihood"
                                width="125px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={proposalRiskQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={setProposalRiskQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRiskQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Closed",
                                    icon: "issue-closed",
                                    value: "Closed",
                                  },
                                  {
                                    display: "Open",
                                    icon: "issue",
                                    value: "Open",
                                  },
                                ]}
                                setFilterObject={setProposalRiskQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalRiskList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalRiskQuery({
                              ...proposalRiskQueryDefault,
                            });
                          }}
                          page={proposalRiskQuery.page}
                          setPageObject={setProposalRiskQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage key risks"
                    icon="shield"
                    title="Risk management strategy"
                  />
                </div>
              }
            />

            {/* Commercials */}
            <Tab
              id="proposal-commercials-tab"
              title="Commercials"
              panel={
                <div>
                  {/* Fees */}
                  <TabSection2
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={proposalFeeList.proposal_fees.map(
                            (proposal_fee) => {
                              return (
                                <tr
                                  key={proposal_fee.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalFeeDetailsAction(
                                          proposal_fee.id
                                        )
                                      );

                                      setUpdateFeeDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <ProposalServiceInfo
                                          proposalServiceId={
                                            proposal_fee.proposal_service
                                          }
                                          query={proposalServiceQueryDefault}
                                        />
                                      }
                                      text={proposal_fee.proposal_service_name}
                                    />
                                  </td>

                                  {/* Expenses */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_fee.expenses}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_fee.margin}
                                      tagNumber={proposal_fee.margin_percentage}
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Adjustment */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_fee.adjustment}
                                    />
                                  </td>

                                  {/* Fees */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber number={proposal_fee.fees} />

                                    {activeOrganisation.currency !==
                                      proposalDetails.proposal
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={proposal_fee.fees_quote}
                                          tagText={
                                            proposalDetails.proposal
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalFeeQuery.proposalService
                          }
                          count={proposalFeeList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="1"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={proposalFeeList.total_expenses}
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={proposalFeeList.total_margin}
                                  tagNumber={
                                    proposalFeeList.average_margin_percentage
                                  }
                                  tagPercentage
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={proposalFeeList.total_adjustments}
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={proposalFeeList.total_fees}
                                />

                                {activeOrganisation.currency !==
                                  proposalDetails.proposal.currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={proposalFeeList.total_fees_quote}
                                      tagText={
                                        proposalDetails.proposal.currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={proposalFeeQuery}
                                filterVariable="proposalService"
                                items={proposalServiceList.proposal_services}
                                setFilterObject={setProposalFeeQuery}
                                setQueryObject={setProposalServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Expenses */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "expenses",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-expenses",
                                  },
                                ]}
                                setFilterObject={setProposalFeeQuery}
                                title="Expenses"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={setProposalFeeQuery}
                                title="Margin"
                                width="125px"
                              />

                              {/* Adjustment */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "adjustment",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-adjustment",
                                  },
                                ]}
                                setFilterObject={setProposalFeeQuery}
                                title="Adjustment"
                                width="125px"
                              />

                              {/* Fees */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "fees",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-fees",
                                  },
                                ]}
                                setFilterObject={setProposalFeeQuery}
                                title="Fees"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalFeeList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalFeeQuery({
                              ...proposalFeeQueryDefault,
                            });
                          }}
                          page={proposalFeeQuery.page}
                          setPageObject={setProposalFeeQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review the pricing model"
                    icon="th"
                    message={
                      <>
                        This section uses expenses information entered for each
                        service in the{" "}
                        <LinkTag
                          onClick={() => {
                            setSelectedTab("proposal-solution-tab");
                          }}
                          label="solution"
                        />{" "}
                        tab.
                      </>
                    }
                    messageIntent="primary"
                    title="Fees"
                  />

                  {/* Milestones */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddMilestoneDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={proposalMilestoneList.proposal_milestones.map(
                            (proposal_milestone) => {
                              return (
                                <tr
                                  key={proposal_milestone.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalMilestoneDetailsAction(
                                          proposal_milestone.id
                                        )
                                      );

                                      setUpdateMilestoneDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Name */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <MilestoneInfo
                                          milestone={proposal_milestone}
                                        />
                                      }
                                      text={proposal_milestone.name}
                                    />
                                  </td>

                                  {/* Due */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={proposal_milestone.due} />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_milestone.value}
                                    />

                                    {activeOrganisation.currency !==
                                      proposalDetails.proposal
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            proposal_milestone.value_quote
                                          }
                                          tagText={
                                            proposalDetails.proposal
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalMilestoneQuery.searchString &&
                            !proposalMilestoneQuery.sortField &&
                            !proposalMilestoneQuery.status
                          }
                          count={proposalMilestoneList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              {/* Value */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    proposalMilestoneList.totalValueOfMilestones
                                  }
                                />

                                {activeOrganisation.currency !==
                                  proposalDetails.proposal.currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        proposalMilestoneList.totalValueOfMilestonesQuote
                                      }
                                      tagText={
                                        proposalDetails.proposal.currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Milestone */}
                              <TableColumnHeadSearch
                                queryObject={proposalMilestoneQuery}
                                queryVariable="searchString"
                                setQueryObject={setProposalMilestoneQuery}
                                setQueryVariable="searchString"
                                title="Milestone"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "due",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-due",
                                  },
                                ]}
                                setFilterObject={setProposalMilestoneQuery}
                                title="Due"
                                width="125px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setProposalMilestoneQuery}
                                title="Value"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={proposalMilestoneList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalMilestoneQuery({
                              ...proposalMilestoneQueryDefault,
                            });
                          }}
                          page={proposalMilestoneQuery.page}
                          setPageObject={setProposalMilestoneQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage payment milestones"
                    icon="star-empty"
                    title="Milestones"
                  />

                  {/* Rate card */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddRateDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={proposalRateList.proposal_rates.map(
                            (proposal_rate) => {
                              return (
                                <tr
                                  key={proposal_rate.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalRateDetailsAction(
                                          proposal_rate.id
                                        )
                                      );

                                      setUpdateRateDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Role */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <RoleInfo
                                          roleId={proposal_rate.role}
                                          query={roleQueryDefault}
                                        />
                                      }
                                      text={proposal_rate.role_name}
                                    />
                                  </td>

                                  {/* Cost rate */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_rate.cost_rate}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_rate.margin}
                                      tagNumber={
                                        proposal_rate.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Sell rate */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_rate.sell_rate}
                                    />

                                    {activeOrganisation.currency !==
                                      proposalDetails.proposal
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={proposal_rate.sell_rate_quote}
                                          tagText={
                                            proposalDetails.proposal
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>

                                  {/* Sell rate / day */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={proposal_rate.sell_rate_day}
                                    />

                                    {activeOrganisation.currency !==
                                      proposalDetails.proposal
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            proposal_rate.sell_rate_day_quote
                                          }
                                          tagText={
                                            proposalDetails.proposal
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={!proposalRateQuery.role}
                          count={proposalRateList.count}
                          footnote={
                            <div>Figures in {activeOrganisation.currency}</div>
                          }
                          head={
                            <tr>
                              {/* Role */}
                              <TableColumnHeadSelect2
                                filterObject={proposalRateQuery}
                                filterVariable="role"
                                items={roleList.roles}
                                setFilterObject={setProposalRateQuery}
                                setQueryObject={setRoleQuery}
                                setQueryVariable="searchString"
                                title="Role"
                                width="200px"
                              />

                              {/* Cost rate */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "cost_rate",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-cost_rate",
                                  },
                                ]}
                                setFilterObject={setProposalRateQuery}
                                title="Cost rate"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={setProposalRateQuery}
                                title="Margin"
                                width="125px"
                              />

                              {/* Sell rate */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "sell_rate",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-sell_rate",
                                  },
                                ]}
                                setFilterObject={setProposalRateQuery}
                                title="Sell rate"
                                width="125px"
                              />

                              {/* Sell rate / day */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "sell_rate_day",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-sell_rate_day",
                                  },
                                ]}
                                setFilterObject={setProposalRateQuery}
                                title="Sell rate / day"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalRateList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalRateQuery({
                              ...proposalRateQueryDefault,
                            });
                          }}
                          page={proposalRateQuery.page}
                          setPageObject={setProposalRateQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Develop the rate card"
                    icon="list-columns"
                    message={
                      <>
                        You can include a rate card in the proposal. advaise.app
                        will populate the hourly cost rate for each role based
                        on information from the{" "}
                        <LinkTag
                          label="Roles"
                          onClick={() => {
                            navigate(`/roles`);
                          }}
                        />{" "}
                        module.
                      </>
                    }
                    messageIntent="primary"
                    title="Rate card"
                  />

                  {/* Fee notes */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listProposalDetailsAction(id));

                          setUpdateFeeNotesDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Currency */}
                        <TabSectionField
                          label="Currency"
                          loading={proposalDetails.loading}
                          value={
                            <div>
                              <Tag
                                minimal
                                style={{
                                  margin: "0 10px 0 0",
                                  textAlign: "center",
                                  width: "50px",
                                }}
                              >
                                {proposalDetails.proposal.currency_short}
                              </Tag>

                              {proposalDetails.proposal.currency}
                            </div>
                          }
                        />

                        {/* Exchange rate */}
                        {activeOrganisation.currency !==
                          proposalDetails.proposal.currency_short && (
                          <TabSectionField
                            helperText="The exchange rate will not be shown in the proposal document"
                            label="Exchange rate"
                            loading={proposalDetails.loading}
                            value={`${proposalDetails.proposal.currency_exchange_rate} ${proposalDetails.proposal.currency_short}/${activeOrganisation.currency}`}
                          />
                        )}

                        {/* Includes tax */}
                        <TabSectionField
                          label="Includes tax"
                          loading={proposalDetails.loading}
                          value={proposalDetails.proposal.tax}
                        />

                        {/* Additional information */}
                        <TabSectionField
                          bottom
                          label="Additional information"
                          loading={proposalDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={proposalDetails.proposal.fee_notes}
                            />
                          }
                        />
                      </>
                    }
                    help="Summarize your pricing assumptions"
                    icon="annotation"
                    title="Fee notes"
                  />

                  {/* Legal terms */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddLegalSectionDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <Button
                          icon="download"
                          intent="primary"
                          loading={proposalLegalSectionDownload.loading}
                          onClick={() => {
                            dispatch(
                              downloadProposalLegalSectionsAction(
                                id,
                                proposalLegalSectionList.count
                              )
                            );
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Download"
                        />

                        <Button
                          disabled={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                          }
                          icon="upload"
                          intent="primary"
                          onClick={() => {
                            setUploadProposalLegalSectionsDialogIsOpen(true);
                          }}
                          text="Upload"
                        />
                      </>
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Agreed */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Agreed"
                                loading={proposalLegalSectionList.loading}
                                onClick={() => {
                                  setProposalLegalSectionQuery({
                                    ...proposalLegalSectionQuery,
                                    page: 1,
                                    status: "Agreed",
                                  });
                                }}
                                value={
                                  proposalLegalSectionList.numberOfSectionsAgreed
                                }
                              />

                              {/* Under review */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="Under review"
                                loading={proposalLegalSectionList.loading}
                                onClick={() => {
                                  setProposalLegalSectionQuery({
                                    ...proposalLegalSectionQuery,
                                    page: 1,
                                    status: "Under review",
                                  });
                                }}
                                value={
                                  proposalLegalSectionList.numberOfSectionsUnderReview
                                }
                              />

                              {/* Not agreed */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Not agreed"
                                loading={proposalLegalSectionList.loading}
                                onClick={() => {
                                  setProposalLegalSectionQuery({
                                    ...proposalLegalSectionQuery,
                                    page: 1,
                                    status: "Not agreed",
                                  });
                                }}
                                value={
                                  proposalLegalSectionList.numberOfSectionsNotAgreed
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={proposalLegalSectionList.proposal_legal_sections.map(
                            (proposal_legal_section) => {
                              return (
                                <tr
                                  key={proposal_legal_section.id}
                                  onClick={() => {
                                    if (
                                      proposalDetails.proposal
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listProposalLegalSectionDetailsAction(
                                          proposal_legal_section.id
                                        )
                                      );

                                      setUpdateLegalSectionDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Section */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>
                                            {proposal_legal_section.title}
                                          </H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Proposed terms</strong>
                                          </p>

                                          <p>
                                            {proposal_legal_section.proposed}
                                          </p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Amended terms</strong>
                                          </p>

                                          <p>
                                            {proposal_legal_section.amended ? (
                                              proposal_legal_section.amended
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No content.
                                              </span>
                                            )}
                                          </p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Comments</strong>
                                          </p>

                                          <p>
                                            {proposal_legal_section.comments ? (
                                              proposal_legal_section.comments
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>

                                          {proposal_legal_section.attachment && (
                                            <div
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                              style={{ textAlign: "end" }}
                                            >
                                              <Button
                                                icon="document"
                                                intent="primary"
                                                minimal
                                                onClick={() =>
                                                  window.open(
                                                    proposal_legal_section.attachment,
                                                    "_blank",
                                                    "noopener,noreferrer"
                                                  )
                                                }
                                                style={{
                                                  color: lightMode
                                                    ? Colors.BLUE5
                                                    : Colors.BLUE3,
                                                }}
                                                text="Attachment"
                                              />
                                            </div>
                                          )}
                                        </>
                                      }
                                      text={proposal_legal_section.title}
                                    />
                                  </td>

                                  {/* Number */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      value={proposal_legal_section.number}
                                    />
                                  </td>

                                  {/* Originator */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={proposal_legal_section.originator}
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        proposal_legal_section.status ===
                                        "Agreed"
                                          ? "success"
                                          : proposal_legal_section.status ===
                                            "Not agreed"
                                          ? "danger"
                                          : "warning"
                                      }
                                      value={proposal_legal_section.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !proposalLegalSectionQuery.number &&
                            !proposalLegalSectionQuery.originator &&
                            !proposalLegalSectionQuery.title &&
                            !proposalLegalSectionQuery.status
                          }
                          count={proposalLegalSectionList.count}
                          head={
                            <tr>
                              {/* Section */}
                              <TableColumnHeadSearch
                                queryObject={proposalLegalSectionQuery}
                                queryVariable="title"
                                setQueryObject={setProposalLegalSectionQuery}
                                setQueryVariable="title"
                                title="Section"
                                width="200px"
                              />

                              {/* Number */}
                              <TableColumnHeadSearch
                                queryObject={proposalLegalSectionQuery}
                                queryVariable="number"
                                setQueryObject={setProposalLegalSectionQuery}
                                setQueryVariable="number"
                                title="No."
                                width="125px"
                              />

                              {/* Originator */}
                              <TableColumnHeadSearch
                                queryObject={proposalLegalSectionQuery}
                                queryVariable="originator"
                                setQueryObject={setProposalLegalSectionQuery}
                                setQueryVariable="originator"
                                title="Originator"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalLegalSectionQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Agreed",
                                    icon: "tick-circle",
                                    value: "Agreed",
                                  },
                                  {
                                    display: "Under review",
                                    icon: "warning-sign",
                                    value: "Under review",
                                  },
                                  {
                                    display: "Not agreed",
                                    icon: "error",
                                    value: "Not agreed",
                                  },
                                ]}
                                setFilterObject={setProposalLegalSectionQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            proposalDetails.proposal.user_access_status !==
                            "view"
                          }
                          loading={proposalLegalSectionList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalLegalSectionQuery({
                              ...proposalLegalSectionQueryDefault,
                            });
                          }}
                          page={proposalLegalSectionQuery.page}
                          setPageObject={setProposalLegalSectionQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review the legal terms"
                    icon="manually-entered-data"
                    message={
                      <>
                        You can download a spreadsheet template to fill offline
                        and then upload it. This may be useful when the client
                        has provided a term sheet, which you can copy and paste.
                      </>
                    }
                    messageIntent="primary"
                    title="Legal terms"
                  />
                </div>
              }
            />

            {/* Response */}
            <Tab
              id="proposal-response-tab"
              title="Response"
              panel={
                <div>
                  {/* Style */}
                  {proposalDetails.proposal.user_access_status !== "view" && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(
                              listProposalDocumentDetailsAction(
                                proposalDetails.proposal.proposal_document
                              )
                            );

                            setUpdateProposalDocumentStyleDrawerIsOpen(true);
                          }}
                          text="Edit"
                        />
                      }
                      content={
                        <>
                          {/* Template */}
                          <TabSectionField
                            label="Template"
                            loading={proposalDocumentDetails.loading}
                            value={proposalDocument.template}
                          />

                          {/* Logo */}
                          <TabSectionField
                            label="Logo"
                            loading={proposalDocumentDetails.loading}
                            value={
                              <TabSectionFieldImage
                                image={
                                  proposalDocumentDetails.proposal_document.logo
                                }
                                height="200px"
                                interactive
                              />
                            }
                          />

                          {/* Font */}
                          <TabSectionField
                            label="Font"
                            loading={proposalDocumentDetails.loading}
                            value={proposalDocument.font}
                          />

                          {/* Primary colour */}
                          <TabSectionField
                            label="Primary colour"
                            loading={proposalDocumentDetails.loading}
                            value={
                              <Tag
                                large
                                round
                                style={{
                                  background: proposalDocument.primaryColour,
                                  color: setTextColour(
                                    proposalDocument.primaryColour
                                  ),
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                {proposalDocument.primaryColour}
                              </Tag>
                            }
                          />

                          {/* Secondary colour */}
                          <TabSectionField
                            bottom
                            label="Secondary colour"
                            loading={proposalDocumentDetails.loading}
                            value={
                              <Tag
                                large
                                round
                                style={{
                                  background: proposalDocument.secondaryColour,
                                  color: setTextColour(
                                    proposalDocument.secondaryColour
                                  ),
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                {proposalDocument.secondaryColour}
                              </Tag>
                            }
                          />
                        </>
                      }
                      help="Set the style for the proposal document"
                      icon="style"
                      title="Style"
                    />
                  )}

                  {/* Proposal document */}
                  {proposalDetails.proposal.user_access_status !== "view" && (
                    <TabSection2
                      buttons={
                        <>
                          <Button
                            disabled={
                              documentGeneratePdf.loading ||
                              proposalDocumentDetails.loading ||
                              proposalDocumentUpdate.loading
                            }
                            icon="refresh"
                            intent="primary"
                            onClick={() => {
                              updateDocument();
                            }}
                            text="Refresh"
                          />

                          <Popover2
                            content={
                              <Menu>
                                <MenuItem
                                  icon="manual"
                                  onClick={() => {
                                    saveAs(
                                      documentGeneratePdf.proposal_pdf,
                                      `${proposalDetails.proposal.name} for ${proposalDetails.proposal.client_name}.pdf`
                                    );
                                  }}
                                  text="PDF"
                                />

                                <MenuItem
                                  icon="document"
                                  onClick={() => {
                                    setEditableProposalDocumentLoading(true);

                                    dispatch(downloadProposalAction(id));
                                  }}
                                  text="DOCX"
                                />
                              </Menu>
                            }
                            interactionKind={Popover2InteractionKind.HOVER}
                          >
                            <Button
                              icon="download"
                              intent="primary"
                              loading={
                                documentGeneratePdf.loading ||
                                editableProposalDocumentLoading
                              }
                              style={{ margin: "0 0 0 10px" }}
                              text="Download"
                            />
                          </Popover2>
                        </>
                      }
                      content={
                        <TabSectionDocumentEditor3
                          document={documentGeneratePdf.proposal_pdf}
                          error={documentGeneratePdf.error}
                          form={
                            <>
                              {/* Front page */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Background image */}
                                    <FormDrawerFileField2
                                      error={proposalDocumentUpdate.error}
                                      fileName="background_image"
                                      helperText="Leave blank to use the default template background"
                                      label="Background image"
                                      placeholder="Upload a background image"
                                      setValueObject={setProposalDocument}
                                      setValueFileVariable="backgroundImage"
                                      setValueNameVariable="backgroundImageName"
                                      valueFile={
                                        proposalDocument.backgroundImage
                                      }
                                      valueName={
                                        proposalDocument.backgroundImageName
                                      }
                                    />

                                    {/* Background image preview */}
                                    <div style={{ margin: "0 0 20px 0" }}>
                                      {proposalDocument.backgroundImage ? (
                                        proposalDocument.backgroundImage !==
                                        proposalDocumentDetails
                                          .proposal_document
                                          .background_image ? (
                                          <TabSectionFieldImage
                                            image={URL.createObjectURL(
                                              new Blob([
                                                proposalDocument.backgroundImage,
                                              ])
                                            )}
                                          />
                                        ) : (
                                          <TabSectionFieldImage
                                            image={
                                              proposalDocumentDetails
                                                .proposal_document
                                                .background_image
                                            }
                                          />
                                        )
                                      ) : undefined}
                                    </div>

                                    {/* Title */}
                                    <FormDrawerTextField2
                                      error={proposalDocumentUpdate.error}
                                      fieldName="title"
                                      helperText="Leave blank to use the proposal's name"
                                      icon="manual"
                                      label="Title"
                                      labelInfo="(required)"
                                      placeholder="Give the proposal a title"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="title"
                                      value={proposalDocument.title}
                                    />
                                  </>
                                }
                                pageRef={sectionPages["Front page"]}
                                //setShowPage={setShowPage}
                                title="Front page"
                              />

                              {/* Cover letter */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_cover_letter"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeCoverLetter"
                                      value={
                                        proposalDocument.includeCoverLetter
                                      }
                                    />

                                    {proposalDocument.includeCoverLetter && (
                                      <>
                                        {/* Company */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company"
                                          helperText="Leave blank to use the default company name"
                                          icon="office"
                                          label="Company"
                                          labelInfo="(required)"
                                          placeholder="Enter your company's name"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="company"
                                          value={proposalDocument.company}
                                        />

                                        {/* Company department */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_department"
                                          icon="path"
                                          label="Company department"
                                          placeholder="Enter your business unit or department"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companyDepartment"
                                          value={
                                            proposalDocument.companyDepartment
                                          }
                                        />

                                        {/* Company address */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_address"
                                          height="100px"
                                          label="Company address"
                                          noRewrite
                                          placeholder="Enter your company's address"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companyAddress"
                                          value={
                                            proposalDocument.companyAddress
                                          }
                                        />

                                        {/* Company phone */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_phone"
                                          icon="phone"
                                          label="Company phone"
                                          placeholder="Enter your company's phone number"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companyPhone"
                                          value={proposalDocument.companyPhone}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.companyPhone ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.companyPhone
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.companyPhone !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    companyPhone: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Company email */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_email"
                                          icon="envelope"
                                          label="Company email"
                                          placeholder="Enter your company's email"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companyEmail"
                                          value={proposalDocument.companyEmail}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.companyEmail ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.companyEmail
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.companyEmail !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    companyEmail: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Contact */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="contact"
                                          icon="id-number"
                                          label="Contact"
                                          placeholder="Enter the contact's name"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="contact"
                                          value={proposalDocument.contact}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.contact === "..."
                                            }
                                            disabled={proposalDocument.contact}
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.contact !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    contact: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Contact role */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="contact_role"
                                          icon="hat"
                                          label="Contact role"
                                          placeholder="Enter the contact's role"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="contactRole"
                                          value={proposalDocument.contactRole}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.contactRole ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.contactRole
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.contactRole !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    contactRole: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Client */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="client"
                                          helperText="Leave blank to use the default client name"
                                          icon="office"
                                          label="Client"
                                          labelInfo="(required)"
                                          placeholder="Enter the client's name"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="client"
                                          value={proposalDocument.client}
                                        />

                                        {/* Client department */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="client_department"
                                          icon="path"
                                          label="Client department"
                                          placeholder="Enter the client's department"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="clientDepartment"
                                          value={
                                            proposalDocument.clientDepartment
                                          }
                                        />

                                        {/* Client address */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="client_address"
                                          height="100px"
                                          icon="area-of-interest"
                                          label="Client address"
                                          noRewrite
                                          placeholder="Enter the client's address"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="clientAddress"
                                          value={proposalDocument.clientAddress}
                                        />

                                        {/* Date */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="date"
                                          helperText="Leave blank to use the proposal's due date"
                                          icon="calendar"
                                          label="Date"
                                          labelInfo="(required)"
                                          placeholder="Enter the proposal's date"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="date"
                                          value={proposalDocument.date}
                                        />

                                        {/* Salutation */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="salutation"
                                          helperText="Leave blank to use system-generated content"
                                          icon="person"
                                          label="Salutation"
                                          labelInfo="(required)"
                                          placeholder="Enter a salutation"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="salutation"
                                          value={proposalDocument.salutation}
                                        />

                                        {/* Reference */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="reference"
                                          helperText="Leave blank to use the proposal's name"
                                          icon="search-text"
                                          label="Reference"
                                          labelInfo="(required)"
                                          placeholder="Enter the client reference"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="reference"
                                          value={proposalDocument.reference}
                                        />

                                        {/* Opening */}
                                        <FormDrawerTextAreaField3
                                          error={proposalDocumentUpdate.error}
                                          fieldName="opening"
                                          helperText="Leave blank to use system-generated content"
                                          label="Opening"
                                          labelInfo="(required)"
                                          placeholder="Enter the cover letter's opening statement"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="opening"
                                          value={proposalDocument.opening}
                                        />

                                        {/* Company summary */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_summary"
                                          label="Company summary"
                                          placeholder="Enter a summary of your company"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companySummary"
                                          value={
                                            proposalDocument.companySummary
                                          }
                                        />

                                        {/* Commitment */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="commitment"
                                          label="Commitment"
                                          placeholder="Enter your commitment statement"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="commitment"
                                          value={proposalDocument.commitment}
                                        />

                                        {/* Additional content */}
                                        <FormDrawerTextAreaField3
                                          error={proposalDocumentUpdate.error}
                                          fieldName="additional_content"
                                          label="Additional content"
                                          placeholder="Enter additional paragraphs"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="additionalContent"
                                          value={
                                            proposalDocument.additionalContent
                                          }
                                        />

                                        {/* Close */}
                                        <FormDrawerTextAreaField3
                                          error={proposalDocumentUpdate.error}
                                          fieldName="close"
                                          helperText="Leave blank to use system-generated content"
                                          label="Close"
                                          labelInfo="(required)"
                                          placeholder="Enter the cover letter's closing statement"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="close"
                                          value={proposalDocument.close}
                                        />

                                        {/* Signature */}
                                        <FormDrawerFileField2
                                          error={proposalDocumentUpdate.error}
                                          fileName="signature"
                                          icon="draw"
                                          label="Signature"
                                          placeholder="Upload the manager's signature"
                                          setValueObject={setProposalDocument}
                                          setValueFileVariable="signature"
                                          setValueNameVariable="signatureName"
                                          valueFile={proposalDocument.signature}
                                          valueName={
                                            proposalDocument.signatureName
                                          }
                                        />

                                        {/* Manager */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="manager"
                                          icon="layout-hierarchy"
                                          helperText="Leave blank to use the proposal's manager"
                                          label="Manager"
                                          labelInfo="(required)"
                                          placeholder="Enter the manager's name"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="manager"
                                          value={proposalDocument.manager}
                                        />

                                        {/* Manager role */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="manager_role"
                                          icon="hat"
                                          label="Manager role"
                                          placeholder="Enter the manager's role"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="managerRole"
                                          value={proposalDocument.managerRole}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.managerRole ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.managerRole
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.managerRole !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    managerRole: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Manager phone */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="manager_phone"
                                          icon="phone"
                                          label="Manager phone"
                                          placeholder="Enter the manager's phone number"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="managerPhone"
                                          value={proposalDocument.managerPhone}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.managerPhone ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.managerPhone
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.managerPhone !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    managerPhone: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Manager email */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="manager_email"
                                          icon="envelope"
                                          label="Manager email"
                                          placeholder="Enter the manager's email"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="managerEmail"
                                          value={proposalDocument.managerEmail}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.managerEmail ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.managerEmail
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.managerEmail !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    managerEmail: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                }
                                pageRef={sectionPages["Cover letter"]}
                                //setShowPage={setShowPage}
                                title="Cover letter"
                              />

                              {/* Our understanding of your needs */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_needs"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeNeeds"
                                      value={proposalDocument.includeNeeds}
                                    />

                                    {proposalDocument.includeNeeds && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="needs_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          placeholder="Enter the section title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="needsHeading"
                                          value={proposalDocument.needsHeading}
                                        />

                                        {/* Change drivers heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="change_drivers_heading"
                                          icon="header-two"
                                          label="Change drivers heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="changeDriversHeading"
                                          value={
                                            proposalDocument.changeDriversHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.changeDriversHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.changeDriversHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.changeDriversHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    changeDriversHeading: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Change drivers */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="change_drivers"
                                          label="Change drivers"
                                          placeholder="Summarize the events that are driving the client to change"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="changeDrivers"
                                          value={proposalDocument.changeDrivers}
                                        />

                                        {/* Strategic options heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="strategic_options_heading"
                                          icon="header-two"
                                          label="Strategic options heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="strategicOptionsHeading"
                                          value={
                                            proposalDocument.strategicOptionsHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.strategicOptionsHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.strategicOptionsHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.strategicOptionsHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    strategicOptionsHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Strategic options */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="strategic_options"
                                          label="Strategic options"
                                          placeholder="Identify the client's strategic options"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="strategicOptions"
                                          value={
                                            proposalDocument.strategicOptions
                                          }
                                        />

                                        {/* Theory of change heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="theory_of_change_heading"
                                          icon="header-two"
                                          label="Theory of change heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="theoryOfChangeHeading"
                                          value={
                                            proposalDocument.theoryOfChangeHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.theoryOfChangeHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.theoryOfChangeHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.theoryOfChangeHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    theoryOfChangeHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Theory of change */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="theory_of_change"
                                          label="Theory of change"
                                          placeholder="Describe the client's preferred strategy (or our leading hypothesis)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="theoryOfChange"
                                          value={
                                            proposalDocument.theoryOfChange
                                          }
                                        />

                                        {/* Issues heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="issues_heading"
                                          icon="header-two"
                                          label="Issues heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="issuesHeading"
                                          value={proposalDocument.issuesHeading}
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.issuesHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.issuesHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.issuesHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    issuesHeading: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Issues */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="issues"
                                          label="Issues"
                                          placeholder="Analyse the challenges that face the client in moving forward"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="issues"
                                          value={proposalDocument.issues}
                                        />

                                        {/* Our solution heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="our_solution_heading"
                                          icon="header-two"
                                          label="Our solution heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="ourSolutionHeading"
                                          value={
                                            proposalDocument.ourSolutionHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.ourSolutionHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.ourSolutionHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.ourSolutionHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    ourSolutionHeading: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Our solution */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="our_solution"
                                          label="Our solution"
                                          placeholder="Describe the solutions we will bring to support the change"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="ourSolution"
                                          value={proposalDocument.ourSolution}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    needs analysis in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("proposal-strategy-tab");
                                      }}
                                      label="strategy"
                                    />{" "}
                                    tab. You can provide alternative,
                                    client-ready, content using the fields
                                    below.
                                  </>
                                }
                                pageRef={sectionPages["Needs"]}
                                //setShowPage={setShowPage}
                                title="Our understanding of your needs"
                              />

                              {/* Why we are the right partner for you */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_why_us"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeWhyUs"
                                      value={proposalDocument.includeWhyUs}
                                    />

                                    {proposalDocument.includeWhyUs && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="why_us_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          placeholder="Enter the section title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="whyUsHeading"
                                          value={proposalDocument.whyUsHeading}
                                        />

                                        {/* Relationships heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Relationships heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyRelationshipsHeading"
                                          value={
                                            proposalDocument.winStrategyRelationshipsHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyRelationshipsHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyRelationshipsHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyRelationshipsHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyRelationshipsHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Relationships */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships"
                                          label="Relationships"
                                          placeholder="Describe how we can better engage with the key stakeholders than our competitors"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyRelationships"
                                          value={
                                            proposalDocument.winStrategyRelationships
                                          }
                                        />

                                        {/* Team heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Team heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyTeamHeading"
                                          value={
                                            proposalDocument.winStrategyTeamHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyTeamHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyTeamHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyTeamHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyTeamHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Team */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_team"
                                          label="Team"
                                          placeholder="Explain that we bring an experienced and high-profile team"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyTeam"
                                          value={
                                            proposalDocument.winStrategyTeam
                                          }
                                        />

                                        {/* Solution heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Solution heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategySolutionHeading"
                                          value={
                                            proposalDocument.winStrategySolutionHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategySolutionHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategySolutionHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategySolutionHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategySolutionHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Solution */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_solution"
                                          label="Solution"
                                          placeholder="Identify proprietary assets and differentiated skills that enable us to bring the best solution"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategySolution"
                                          value={
                                            proposalDocument.winStrategySolution
                                          }
                                        />

                                        {/* Partnerships heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Partnerships heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyPartnershipsHeading"
                                          value={
                                            proposalDocument.winStrategyPartnershipsHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyPartnershipsHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyPartnershipsHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyPartnershipsHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyPartnershipsHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Partnerships */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_partnerships"
                                          label="Partnerships"
                                          placeholder="Describe how we will partner with other organisations to offer a complete solution"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyPartnerships"
                                          value={
                                            proposalDocument.winStrategyPartnerships
                                          }
                                        />

                                        {/* References heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="References heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyReferencesHeading"
                                          value={
                                            proposalDocument.winStrategyReferencesHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyReferencesHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyReferencesHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyReferencesHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyReferencesHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* References */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_references"
                                          label="References"
                                          placeholder="Identify our credible and high-profile references"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyReferences"
                                          value={
                                            proposalDocument.winStrategyReferences
                                          }
                                        />

                                        {/* Commercials heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Commercials heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyCommercialsHeading"
                                          value={
                                            proposalDocument.winStrategyCommercialsHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyCommercialsHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyCommercialsHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyCommercialsHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyCommercialsHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Commercials */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_commercials"
                                          label="Commercials"
                                          placeholder="Explain how we provide compelling terms and conditions"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyCommercials"
                                          value={
                                            proposalDocument.winStrategyCommercials
                                          }
                                        />

                                        {/* Price heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Price heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyPriceHeading"
                                          value={
                                            proposalDocument.winStrategyPriceHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyPriceHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyPriceHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyPriceHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyPriceHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Price */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_price"
                                          label="Price"
                                          placeholder="Explain how we provide the best value-for-money"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyPrice"
                                          value={
                                            proposalDocument.winStrategyPrice
                                          }
                                        />

                                        {/* Other heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_relationships_heading"
                                          icon="header-two"
                                          label="Other heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyOtherHeading"
                                          value={
                                            proposalDocument.winStrategyOtherHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              proposalDocument.winStrategyOtherHeading ===
                                              "..."
                                            }
                                            disabled={
                                              proposalDocument.winStrategyOtherHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              proposalDocument.winStrategyOtherHeading !==
                                                "..." &&
                                              setProposalDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    winStrategyOtherHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Other */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="win_strategy_other"
                                          label="Other"
                                          placeholder="Describe how else we provide more value than our competitors"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="winStrategyOther"
                                          value={
                                            proposalDocument.winStrategyOther
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    win strategy in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("proposal-strategy-tab");
                                      }}
                                      label="strategy"
                                    />{" "}
                                    tab. You can provide alternative,
                                    client-ready, content using the fields
                                    below.
                                  </>
                                }
                                pageRef={sectionPages["Why us"]}
                                //setShowPage={setShowPage}
                                title="Why we are the right partner for you"
                              />

                              {/* Responses to your questions/requirements */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_responses"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeResponses"
                                      value={proposalDocument.includeResponses}
                                    />

                                    {proposalDocument.includeResponses && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="responses_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="responsesHeading"
                                          value={
                                            proposalDocument.responsesHeading
                                          }
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="intro_requirements"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="introRequirements"
                                          value={
                                            proposalDocument.introRequirements
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    responses to questions/requirements in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("proposal-solution-tab");
                                      }}
                                      label="solution"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Responses"]}
                                //setShowPage={setShowPage}
                                title="Responses to your questions/requirements"
                              />

                              {/* Proposed approach */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_approach"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeApproach"
                                      value={proposalDocument.includeApproach}
                                    />

                                    {proposalDocument.includeApproach && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="approach_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="approachHeading"
                                          value={
                                            proposalDocument.approachHeading
                                          }
                                        />

                                        {/* Approach summary */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="approach_summary"
                                          label="Approach summary"
                                          placeholder="Summarize the engagement method"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="approachSummary"
                                          value={
                                            proposalDocument.approachSummary
                                          }
                                        />

                                        {/* Approach diagram */}
                                        <FormDrawerFileField2
                                          error={proposalDocumentUpdate.error}
                                          fileName="approach_diagram"
                                          label="Approach diagram"
                                          placeholder="Upload the approach diagram"
                                          setValueObject={setProposalDocument}
                                          setValueFileVariable="approachDiagram"
                                          setValueNameVariable="approachDiagramName"
                                          valueFile={
                                            proposalDocument.approachDiagram
                                          }
                                          valueName={
                                            proposalDocument.approachDiagramName
                                          }
                                        />

                                        {/* Approach diagram preview */}
                                        {proposalDocument.approachDiagram ? (
                                          proposalDocument.approachDiagram !==
                                          proposalDocumentDetails
                                            .proposal_document
                                            .approach_diagram ? (
                                            <TabSectionFieldImage
                                              image={URL.createObjectURL(
                                                new Blob([
                                                  proposalDocument.approachDiagram,
                                                ])
                                              )}
                                            />
                                          ) : (
                                            <TabSectionFieldImage
                                              image={
                                                proposalDocumentDetails
                                                  .proposal_document
                                                  .approach_diagram
                                              }
                                            />
                                          )
                                        ) : undefined}
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    approach in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("proposal-solution-tab");
                                      }}
                                      label="solution"
                                    />{" "}
                                    tab. You can provide alternative,
                                    client-ready, content using the fields
                                    below.
                                  </>
                                }
                                pageRef={sectionPages["Approach"]}
                                //setShowPage={setShowPage}
                                title="Proposed approach"
                              />

                              {/* Services */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_services"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeServices"
                                      value={proposalDocument.includeServices}
                                    />

                                    {proposalDocument.includeServices && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="services_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="servicesHeading"
                                          value={
                                            proposalDocument.servicesHeading
                                          }
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="intro_services"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="introServices"
                                          value={proposalDocument.introServices}
                                        />

                                        {/* Schedule diagram */}
                                        <FormDrawerFileField2
                                          error={proposalDocumentUpdate.error}
                                          fileName="schedule_diagram"
                                          label="Schedule diagram"
                                          placeholder="Upload the schedule diagram"
                                          setValueObject={setProposalDocument}
                                          setValueFileVariable="scheduleDiagram"
                                          setValueNameVariable="scheduleDiagramName"
                                          valueFile={
                                            proposalDocument.scheduleDiagram
                                          }
                                          valueName={
                                            proposalDocument.scheduleDiagramName
                                          }
                                        />

                                        {/* Schedule diagram preview */}
                                        {proposalDocument.scheduleDiagram ? (
                                          proposalDocument.scheduleDiagram !==
                                          proposalDocumentDetails
                                            .proposal_document
                                            .schedule_diagram ? (
                                            <TabSectionFieldImage
                                              image={URL.createObjectURL(
                                                new Blob([
                                                  proposalDocument.scheduleDiagram,
                                                ])
                                              )}
                                            />
                                          ) : (
                                            <TabSectionFieldImage
                                              image={
                                                proposalDocumentDetails
                                                  .proposal_document
                                                  .schedule_diagram
                                              }
                                            />
                                          )
                                        ) : undefined}
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    services described in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("proposal-solution-tab");
                                      }}
                                      label="solution"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Services"]}
                                //setShowPage={setShowPage}
                                title="Services"
                              />

                              {/* Our team */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_team"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeTeam"
                                      value={proposalDocument.includeTeam}
                                    />

                                    {proposalDocument.includeTeam && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="team_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="teamHeading"
                                          value={proposalDocument.teamHeading}
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="intro_team"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="introTeam"
                                          value={proposalDocument.introTeam}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    key personnel identified in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("proposal-solution-tab");
                                      }}
                                      label="solution"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Team"]}
                                //setShowPage={setShowPage}
                                title="Our team"
                              />

                              {/* Case studies */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_case_studies"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeCaseStudies"
                                      value={
                                        proposalDocument.includeCaseStudies
                                      }
                                    />

                                    {proposalDocument.includeCaseStudies && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="case_studies_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="caseStudiesHeading"
                                          value={
                                            proposalDocument.caseStudiesHeading
                                          }
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="intro_case_studies"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="introCaseStudies"
                                          value={
                                            proposalDocument.introCaseStudies
                                          }
                                        />

                                        {/* Case studies */}
                                        <div style={{ margin: "0 0 15px 0" }}>
                                          <p>Case studies</p>

                                          {proposalCaseStudyList
                                            .proposal_case_studies.length ===
                                          0 ? (
                                            <div className={Classes.TEXT_MUTED}>
                                              No case studies.
                                            </div>
                                          ) : (
                                            proposalCaseStudyList.proposal_case_studies.map(
                                              (
                                                proposal_case_study,
                                                i,
                                                { length }
                                              ) => {
                                                return (
                                                  <Card
                                                    key={proposal_case_study.id}
                                                    interactive
                                                    onClick={() => {
                                                      dispatch(
                                                        listProposalCaseStudyDetailsAction(
                                                          proposal_case_study.id
                                                        )
                                                      );

                                                      setUpdateCaseStudyDrawerIsOpen(
                                                        true
                                                      );
                                                    }}
                                                    style={{
                                                      display: "flex",
                                                      justifyContent:
                                                        "space-between",
                                                      margin:
                                                        length - 1 === i
                                                          ? "0"
                                                          : "0 0 10px 0",
                                                      padding: "10px",
                                                    }}
                                                  >
                                                    <Text ellipsize>
                                                      {
                                                        proposal_case_study
                                                          .case_study_data.name
                                                      }
                                                    </Text>

                                                    <Icon
                                                      icon="edit"
                                                      intent="primary"
                                                    />
                                                  </Card>
                                                );
                                              }
                                            )
                                          )}

                                          <Button
                                            icon="plus"
                                            intent="primary"
                                            onClick={() => {
                                              setAddCaseStudyDrawerIsOpen(true);
                                            }}
                                            style={{
                                              margin: "10px 0 0 0",
                                            }}
                                            text="Add"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </>
                                }
                                pageRef={sectionPages["Case studies"]}
                                //setShowPage={setShowPage}
                                title="Case studies"
                              />

                              {/* Fees */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_fees"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeFees"
                                      value={proposalDocument.includeFees}
                                    />

                                    {proposalDocument.includeFees && (
                                      <>
                                        {/* Include fee breakdown */}
                                        <FormDrawerSwitchField
                                          error={proposalDocumentUpdate.error}
                                          fieldName="include_fee_breakdown"
                                          label="Include fee breakdown"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="includeFeeBreakdown"
                                          value={
                                            proposalDocument.includeFeeBreakdown
                                          }
                                        />

                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="fees_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="feesHeading"
                                          value={proposalDocument.feesHeading}
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={proposalDocumentUpdate.error}
                                          fieldName="intro_fees"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="introFees"
                                          value={proposalDocument.introFees}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    fees specified in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab(
                                          "proposal-commercials-tab"
                                        );
                                      }}
                                      label="commercials"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Fees"]}
                                //setShowPage={setShowPage}
                                title="Fees"
                              />

                              {/* Legal terms */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={proposalDocumentUpdate.error}
                                      fieldName="include_legal"
                                      label="Include section"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="includeLegal"
                                      value={proposalDocument.includeLegal}
                                    />

                                    {proposalDocument.includeLegal && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="legal_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="legalHeading"
                                          value={proposalDocument.legalHeading}
                                        />

                                        {/* Client entity */}
                                        <FormDrawerTextAreaField3
                                          error={proposalDocumentUpdate.error}
                                          fieldName="client_entity"
                                          height="100px"
                                          helperText="Enter the client entity details (e.g. name, registration number, registered address)"
                                          label="Client entity"
                                          labelInfo="(required)"
                                          noRewrite
                                          placeholder="Enter the client contracting entity details"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="clientEntity"
                                          value={proposalDocument.clientEntity}
                                        />

                                        {/* Client alias */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="client_alias"
                                          helperText="Leave blank to use the default client name"
                                          label="Client alias"
                                          labelInfo="(required)"
                                          placeholder="Give the client entity an alias"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="clientAlias"
                                          value={proposalDocument.clientAlias}
                                        />

                                        {/* Company entity */}
                                        <FormDrawerTextAreaField3
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_entity"
                                          height="100px"
                                          helperText="Enter the company contracting entity details (e.g. name, registration number, registered address)"
                                          label="Company entity"
                                          labelInfo="(required)"
                                          noRewrite
                                          placeholder="Enter the company"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companyEntity"
                                          value={proposalDocument.companyEntity}
                                        />

                                        {/* Company alias */}
                                        <FormDrawerTextField2
                                          error={proposalDocumentUpdate.error}
                                          fieldName="company_alias"
                                          helperText="Leave blank to use the default company name"
                                          label="Company alias"
                                          labelInfo="(required)"
                                          placeholder="Give the company an alias"
                                          setValueObject={setProposalDocument}
                                          setValueVariable="companyAlias"
                                          value={proposalDocument.companyAlias}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    legal terms captured in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab(
                                          "proposal-commercials-tab"
                                        );
                                      }}
                                      label="commercials"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Legal"]}
                                //setShowPage={setShowPage}
                                title="Legal terms"
                              />

                              {/* Back page */}
                              <TabSectionDocumentEditorFormContainer2
                                bottom
                                fields={
                                  <>
                                    {/* Back page text */}
                                    <FormDrawerTextAreaField3
                                      error={proposalDocumentUpdate.error}
                                      fieldName="back_page_text"
                                      label="Back page text"
                                      placeholder="Enter the back page text"
                                      setValueObject={setProposalDocument}
                                      setValueVariable="backPageText"
                                      value={proposalDocument.backPageText}
                                    />
                                  </>
                                }
                                pageRef={sectionPages["Back page"]}
                                //setShowPage={setShowPage}
                                title="Back page"
                              />
                            </>
                          }
                          loading={
                            documentGeneratePdf.loading ||
                            proposalDocumentDetails.loading ||
                            proposalDocumentUpdate.loading
                          }
                          setSectionPages={setSectionPages}
                          //showPage={showPage}
                        />
                      }
                      help="Edit the proposal document"
                      icon="book"
                      title="Proposal document"
                    />
                  )}
                </div>
              }
            />

            {/* Close */}
            <Tab
              id="proposal-close-tab"
              title="Close"
              panel={
                <div>
                  {/* Lessons learned */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          proposalDetails.proposal.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddProposalLessonDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search lessons"
                            searching={proposalLessonList.loading}
                            setValueObject={setProposalLessonQuery}
                            setValueVariable="searchString"
                            value={proposalLessonQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={proposalLessonList.proposal_lessons.map(
                            (lesson, i, { length }) => {
                              return (
                                <div key={lesson.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={lesson.name}
                                    value={
                                      <TabSectionFieldCard
                                        interactive
                                        onClick={() => {
                                          if (
                                            proposalDetails.proposal
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listProposalLessonDetailsAction(
                                                lesson.id
                                              )
                                            );

                                            setUpdateProposalLessonDrawerIsOpen(
                                              true
                                            );
                                          }
                                        }}
                                        text={lesson.lesson}
                                        title={
                                          <Tag
                                            icon={
                                              lesson.category === "Commercials"
                                                ? "dollar"
                                                : lesson.category ===
                                                  "Qualification"
                                                ? "search-template"
                                                : lesson.category === "Response"
                                                ? "manual"
                                                : lesson.category === "Solution"
                                                ? "gantt-chart"
                                                : lesson.category === "Strategy"
                                                ? "playbook"
                                                : "more"
                                            }
                                            large
                                            round
                                            style={{
                                              background:
                                                lesson.category ===
                                                "Commercials"
                                                  ? Colors.GOLD3
                                                  : lesson.category ===
                                                    "Qualification"
                                                  ? Colors.LIME3
                                                  : lesson.category ===
                                                    "Response"
                                                  ? Colors.ROSE3
                                                  : lesson.category ===
                                                    "Solution"
                                                  ? Colors.INDIGO3
                                                  : lesson.category ===
                                                    "Strategy"
                                                  ? Colors.CERULEAN3
                                                  : Colors.BLUE3,
                                              color: setTextColour(
                                                lesson.category ===
                                                  "Commercials"
                                                  ? Colors.GOLD3
                                                  : lesson.category ===
                                                    "Qualification"
                                                  ? Colors.LIME3
                                                  : lesson.category ===
                                                    "Response"
                                                  ? Colors.ROSE3
                                                  : lesson.category ===
                                                    "Solution"
                                                  ? Colors.INDIGO3
                                                  : lesson.category ===
                                                    "Strategy"
                                                  ? Colors.CERULEAN3
                                                  : Colors.BLUE3
                                              ),
                                            }}
                                          >
                                            {lesson.category}
                                          </Tag>
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={proposalLessonList.count}
                          loading={proposalLessonList.loading}
                          noResultsMessage="Add lessons."
                          noResultsTitle="No lessons found"
                          page={proposalLessonQuery.page}
                          setPageObject={setProposalLessonQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Capture lessons learned"
                    icon="learning"
                    title="Lessons learned"
                  />
                </div>
              }
            />

            {/* Files */}
            <Tab
              id="proposal-files-tab"
              title="Files"
              panel={
                <div>
                  <div>
                    {/* System generated files */}
                    <TabSection2
                      content={
                        <DataCardContainer
                          children={
                            <>
                              {/* Opportunity Brief */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  dispatch(
                                    generatePdfDocumentAction({
                                      data: {
                                        client_logo:
                                          proposalDetails.proposal.client_logo,
                                        client_name:
                                          proposalDetails.proposal.client_name,
                                        contact:
                                          proposalDetails.proposal.contact,
                                        contact_name:
                                          proposalDetails.proposal.contact_name,
                                        contact_profile_photo:
                                          proposalDetails.proposal
                                            .contact_profile_photo,
                                        currency:
                                          proposalDetails.proposal.currency,
                                        due: proposalDetails.proposal.due,
                                        id: id,
                                        manager_name:
                                          proposalDetails.proposal.manager_name,
                                        manager_profile_photo:
                                          proposalDetails.proposal
                                            .manager_profile_photo,
                                        name: proposalDetails.proposal.name,
                                        notes: proposalDetails.proposal.notes,
                                        qualification_budget_comments:
                                          proposalDetails.proposal
                                            .qualification_budget_comments,
                                        qualification_budget_score:
                                          proposalDetails.proposal
                                            .qualification_budget_score,
                                        qualification_buyer_comments:
                                          proposalDetails.proposal
                                            .qualification_buyer_comments,
                                        qualification_buyer_score:
                                          proposalDetails.proposal
                                            .qualification_buyer_score,
                                        qualification_coach_comments:
                                          proposalDetails.proposal
                                            .qualification_coach_comments,
                                        qualification_coach_score:
                                          proposalDetails.proposal
                                            .qualification_coach_score,
                                        qualification_dependencies_comments:
                                          proposalDetails.proposal
                                            .qualification_dependencies_comments,
                                        qualification_dependencies_score:
                                          proposalDetails.proposal
                                            .qualification_dependencies_score,
                                        qualification_market_comments:
                                          proposalDetails.proposal
                                            .qualification_market_comments,
                                        qualification_market_score:
                                          proposalDetails.proposal
                                            .qualification_market_score,
                                        qualification_need_comments:
                                          proposalDetails.proposal
                                            .qualification_need_comments,
                                        qualification_need_score:
                                          proposalDetails.proposal
                                            .qualification_need_score,
                                        qualification_relationship_comments:
                                          proposalDetails.proposal
                                            .qualification_relationship_comments,
                                        qualification_relationship_score:
                                          proposalDetails.proposal
                                            .qualification_relationship_score,
                                        qualification_reputation_comments:
                                          proposalDetails.proposal
                                            .qualification_reputation_comments,
                                        qualification_reputation_score:
                                          proposalDetails.proposal
                                            .qualification_reputation_score,
                                        qualification_services_comments:
                                          proposalDetails.proposal
                                            .qualification_services_comments,
                                        qualification_services_score:
                                          proposalDetails.proposal
                                            .qualification_services_score,
                                        qualification_solution_comments:
                                          proposalDetails.proposal
                                            .qualification_solution_comments,
                                        qualification_solution_score:
                                          proposalDetails.proposal
                                            .qualification_solution_score,
                                        qualification_team_comments:
                                          proposalDetails.proposal
                                            .qualification_team_comments,
                                        qualification_team_score:
                                          proposalDetails.proposal
                                            .qualification_team_score,
                                        qualification_timing_comments:
                                          proposalDetails.proposal
                                            .qualification_timing_comments,
                                        qualification_timing_score:
                                          proposalDetails.proposal
                                            .qualification_timing_score,
                                        probability_win: probabilityWin,
                                        status: proposalDetails.proposal.status,
                                        value: proposalDetails.proposal.value,
                                      },
                                      type: "opportunity_brief",
                                    })
                                  );

                                  setOpportunityBriefIsOpen(true);
                                }}
                                title="Opportunity Brief"
                                type="pdf"
                              />

                              {/* Strategy Review */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  dispatch(
                                    generatePdfDocumentAction({
                                      data: {
                                        change_drivers:
                                          proposalDetails.proposal
                                            .change_drivers,
                                        client_logo:
                                          proposalDetails.proposal.client_logo,
                                        client_name:
                                          proposalDetails.proposal.client_name,
                                        contact:
                                          proposalDetails.proposal.contact,
                                        contact_name:
                                          proposalDetails.proposal.contact_name,
                                        contact_profile_photo:
                                          proposalDetails.proposal
                                            .contact_profile_photo,
                                        currency:
                                          proposalDetails.proposal.currency,
                                        due: proposalDetails.proposal.due,
                                        id: id,
                                        issues: proposalDetails.proposal.issues,
                                        manager_name:
                                          proposalDetails.proposal.manager_name,
                                        manager_profile_photo:
                                          proposalDetails.proposal
                                            .manager_profile_photo,
                                        name: proposalDetails.proposal.name,
                                        notes: proposalDetails.proposal.notes,
                                        our_solution:
                                          proposalDetails.proposal.our_solution,
                                        status: proposalDetails.proposal.status,
                                        strategic_options:
                                          proposalDetails.proposal
                                            .strategic_options,
                                        theory_of_change:
                                          proposalDetails.proposal
                                            .theory_of_change,
                                        value: proposalDetails.proposal.value,
                                        win_strategy_commercials:
                                          proposalDetails.proposal
                                            .win_strategy_commercials,
                                        win_strategy_other:
                                          proposalDetails.proposal
                                            .win_strategy_other,
                                        win_strategy_partnerships:
                                          proposalDetails.proposal
                                            .win_strategy_partnerships,
                                        win_strategy_price:
                                          proposalDetails.proposal
                                            .win_strategy_price,
                                        win_strategy_references:
                                          proposalDetails.proposal
                                            .win_strategy_references,
                                        win_strategy_relationships:
                                          proposalDetails.proposal
                                            .win_strategy_relationships,
                                        win_strategy_solution:
                                          proposalDetails.proposal
                                            .win_strategy_solution,
                                        win_strategy_team:
                                          proposalDetails.proposal
                                            .win_strategy_team,
                                      },
                                      type: "strategy_review",
                                    })
                                  );

                                  setStrategyReviewIsOpen(true);
                                }}
                                title="Strategy Review"
                                type="pdf"
                              />

                              {/* Requirements */}
                              <DocumentCard
                                interactive
                                loading={proposalRequirementDownload.loading}
                                onClick={() => {
                                  dispatch(
                                    downloadManagersAction(managerList.count)
                                  );

                                  dispatch(
                                    downloadProposalRequirementsAction(
                                      id,
                                      proposalRequirementList.count
                                    )
                                  );
                                }}
                                title="Requirements"
                                type="excel"
                              />

                              {/* Solution Review */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  dispatch(
                                    generatePdfDocumentAction({
                                      data: {
                                        approach_diagram:
                                          proposalDetails.proposal
                                            .approach_diagram,
                                        approach_summary:
                                          proposalDetails.proposal
                                            .approach_summary,
                                        client_logo:
                                          proposalDetails.proposal.client_logo,
                                        client_name:
                                          proposalDetails.proposal.client_name,
                                        contact:
                                          proposalDetails.proposal.contact,
                                        contact_name:
                                          proposalDetails.proposal.contact_name,
                                        contact_profile_photo:
                                          proposalDetails.proposal
                                            .contact_profile_photo,
                                        currency:
                                          proposalDetails.proposal.currency,
                                        due: proposalDetails.proposal.due,
                                        id: id,
                                        labour_expenses:
                                          proposalLabourExpenseList.proposal_labour_expenses,
                                        labour_expenses_total_cost:
                                          proposalLabourExpenseList.total_cost,
                                        manager_name:
                                          proposalDetails.proposal.manager_name,
                                        manager_profile_photo:
                                          proposalDetails.proposal
                                            .manager_profile_photo,
                                        name: proposalDetails.proposal.name,
                                        non_labour_expenses:
                                          proposalNonLabourExpenseList.proposal_non_labour_expenses,
                                        non_labour_expenses_total_cost:
                                          proposalNonLabourExpenseList.total_cost,
                                        notes: proposalDetails.proposal.notes,
                                        number_of_requirements_compliant:
                                          proposalRequirementList.numberOfRequirementsCompliant,
                                        number_of_requirements_non_compliant:
                                          proposalRequirementList.numberOfRequirementsNonCompliant,
                                        number_of_requirements_part_compliant:
                                          proposalRequirementList.numberOfRequirementsPartCompliant,
                                        requirements:
                                          proposalRequirementList.proposal_requirements,
                                        risks: proposalRiskList.proposal_risks,
                                        schedule_diagram:
                                          proposalDocumentDetails
                                            .proposal_document.schedule_diagram,
                                        services:
                                          proposalDocumentDetails
                                            .proposal_document.proposal_data
                                            .services,
                                        staff: proposalStaffList.proposal_staff,
                                        status: proposalDetails.proposal.status,
                                        value: proposalDetails.proposal.value,
                                      },
                                      type: "solution_review",
                                    })
                                  );

                                  setSolutionReviewIsOpen(true);
                                }}
                                title="Solution Review"
                                type="pdf"
                              />

                              {/* Legal Terms */}
                              <DocumentCard
                                loading={proposalLegalSectionDownload.loading}
                                interactive
                                onClick={() => {
                                  dispatch(
                                    downloadProposalLegalSectionsAction(
                                      id,
                                      proposalLegalSectionList.count
                                    )
                                  );
                                }}
                                title="Legal Terms"
                                type="excel"
                              />

                              {/* Commercials Review */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  dispatch(
                                    generatePdfDocumentAction({
                                      data: {
                                        average_margin_percentage:
                                          proposalFeeList.average_margin_percentage,
                                        client_logo:
                                          proposalDetails.proposal.client_logo,
                                        client_name:
                                          proposalDetails.proposal.client_name,
                                        contact:
                                          proposalDetails.proposal.contact,
                                        contact_name:
                                          proposalDetails.proposal.contact_name,
                                        contact_profile_photo:
                                          proposalDetails.proposal
                                            .contact_profile_photo,
                                        currency:
                                          proposalDetails.proposal.currency,
                                        due: proposalDetails.proposal.due,
                                        fees: proposalFeeList.proposal_fees,
                                        id: id,
                                        legal_sections:
                                          proposalLegalSectionList.proposal_legal_sections,
                                        manager_name:
                                          proposalDetails.proposal.manager_name,
                                        manager_profile_photo:
                                          proposalDetails.proposal
                                            .manager_profile_photo,
                                        milestones:
                                          proposalMilestoneList.proposal_milestones,
                                        name: proposalDetails.proposal.name,
                                        notes: proposalDetails.proposal.notes,
                                        number_of_sections_agreed:
                                          proposalLegalSectionList.numberOfSectionsAgreed,
                                        number_of_sections_not_agreed:
                                          proposalLegalSectionList.numberOfSectionsNotAgreed,
                                        number_of_sections_under_review:
                                          proposalLegalSectionList.numberOfSectionsUnderReview,
                                        rates: proposalRateList.proposal_rates,
                                        status: proposalDetails.proposal.status,
                                        tax: proposalDetails.proposal.tax,
                                        total_expenses:
                                          proposalFeeList.total_expenses,
                                        total_fees: proposalFeeList.total_fees,
                                        total_margin:
                                          proposalFeeList.total_margin,
                                        value: proposalDetails.proposal.value,
                                      },
                                      type: "commercials_review",
                                    })
                                  );
                                  setCommercialsReviewIsOpen(true);
                                }}
                                title="Commercials Review"
                                type="pdf"
                              />

                              {/* Proposal Document */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  setProposalDocumentIsOpen(true);
                                }}
                                title="Proposal Document"
                                type="pdf"
                              />

                              {/* Editable Proposal Document */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  setDownloadEditableProposalDocumentIsOpen(
                                    true
                                  );
                                }}
                                title="Editable Proposal Document"
                                type="word"
                              />

                              {/* Editable Proposal Presentation */}
                              {false && (
                                <DocumentCard
                                  title="Editable Proposal Presentation"
                                  type="powerpoint"
                                />
                              )}

                              {/* Win/Loss Review */}
                              <DocumentCard
                                interactive
                                onClick={() => {
                                  dispatch(
                                    generatePdfDocumentAction({
                                      data: {
                                        client_logo:
                                          proposalDetails.proposal.client_logo,
                                        client_name:
                                          proposalDetails.proposal.client_name,
                                        contact:
                                          proposalDetails.proposal.contact,
                                        contact_name:
                                          proposalDetails.proposal.contact_name,
                                        contact_profile_photo:
                                          proposalDetails.proposal
                                            .contact_profile_photo,
                                        currency:
                                          proposalDetails.proposal.currency,
                                        due: proposalDetails.proposal.due,
                                        id: id,
                                        lessons:
                                          proposalLessonList.proposal_lessons,
                                        manager_name:
                                          proposalDetails.proposal.manager_name,
                                        manager_profile_photo:
                                          proposalDetails.proposal
                                            .manager_profile_photo,
                                        name: proposalDetails.proposal.name,
                                        notes: proposalDetails.proposal.notes,
                                        status: proposalDetails.proposal.status,
                                        value: proposalDetails.proposal.value,
                                      },
                                      type: "win_loss_review",
                                    })
                                  );

                                  setWinLossReviewIsOpen(true);
                                }}
                                title="Win/Loss Review"
                                type="pdf"
                              />
                            </>
                          }
                          noMargin
                        />
                      }
                      help="Access system-generated files"
                      icon="cloud-download"
                      title="System generated files"
                    />

                    {/* User uploaded files */}
                    <TabSection2
                      buttons={
                        <Button
                          disabled={
                            proposalDetails.proposal.user_access_status ===
                            "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddUploadDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <ItemCardList
                          content={proposalUploadList.proposal_uploads.map(
                            (proposal_upload) => {
                              return (
                                <DocumentCard
                                  interactive
                                  key={proposal_upload.id}
                                  onClick={() => {
                                    dispatch(
                                      listProposalUploadDetailsAction(
                                        proposal_upload.id
                                      )
                                    );

                                    setUpdateUploadDrawerIsOpen(true);
                                  }}
                                  title={proposal_upload.name}
                                  type={proposal_upload.type}
                                />
                              );
                            }
                          )}
                          count={proposalUploadList.count}
                          loading={proposalUploadList.loading}
                          noMargin
                          noResultsMessage="Add files"
                          noResultsTitle="No files found"
                          page={proposalUploadQuery.page}
                          setPageObject={setProposalUploadQuery}
                          setPageVariable="page"
                        />
                      }
                      help="Manage user-uploaded files"
                      icon="folder-open"
                      title="User uploaded files"
                    />
                  </div>
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default ProposalDetailScreen;
