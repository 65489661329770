// Library imports
import { Button, Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Market
import { listMarketsAction } from "../actions/marketActions";

// Main function
function MarketInfo({ additionalInfo, hideLink, marketData, marketId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Market
  const marketList = useSelector((state) => state.marketList);

  // Refresh market data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [market, setMarket] = useState({});

  useEffect(() => {
    if (marketData) {
      setMarket(marketData);

      setLoading(false);
    }
  }, [marketData]);

  useEffect(() => {
    if (!marketData && !marketList.loading) {
      if (marketList.success) {
        const thisMarket = marketList.markets.filter(
          (market) => market.id === marketId
        );

        if (thisMarket.length > 0) {
          setMarket(thisMarket[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listMarketsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (marketList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [marketList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5>{market.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/markets/${market.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {market.description ? (
              market.description
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default MarketInfo;
