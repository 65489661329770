// Constant imports
import {
  GENERATE_DEMAND_PROFILE_REQUEST,
  GENERATE_DEMAND_PROFILE_SUCCESS,
  GENERATE_DEMAND_PROFILE_FAIL,
  GENERATE_DEMAND_PROFILE_RESET,
} from "../constants/demandProfileConstants";

// Rewrite field
export const demandProfileGenerateReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case GENERATE_DEMAND_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GENERATE_DEMAND_PROFILE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case GENERATE_DEMAND_PROFILE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case GENERATE_DEMAND_PROFILE_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};
