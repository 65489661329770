// Constant imports
import {
  PROPOSAL_LIST_REQUEST,
  PROPOSAL_LIST_SUCCESS,
  PROPOSAL_LIST_FAIL,
  PROPOSAL_LIST_RESET,
  PROPOSAL_CREATE_REQUEST,
  PROPOSAL_CREATE_SUCCESS,
  PROPOSAL_CREATE_FAIL,
  PROPOSAL_CREATE_RESET,
  PROPOSAL_DETAILS_REQUEST,
  PROPOSAL_DETAILS_SUCCESS,
  PROPOSAL_DETAILS_FAIL,
  PROPOSAL_DETAILS_RESET,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_UPDATE_SUCCESS,
  PROPOSAL_UPDATE_FAIL,
  PROPOSAL_UPDATE_RESET,
  PROPOSAL_DELETE_REQUEST,
  PROPOSAL_DELETE_SUCCESS,
  PROPOSAL_DELETE_FAIL,
  PROPOSAL_DELETE_RESET,
  PROPOSAL_PREDICT_WIN_REQUEST,
  PROPOSAL_PREDICT_WIN_SUCCESS,
  PROPOSAL_PREDICT_WIN_FAIL,
  PROPOSAL_PREDICT_WIN_RESET,
  PROPOSAL_DOWNLOAD_REQUEST,
  PROPOSAL_DOWNLOAD_SUCCESS,
  PROPOSAL_DOWNLOAD_FAIL,
  PROPOSAL_DOWNLOAD_RESET,
} from "../constants/proposalConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getProposals(params, token);

    dispatch({
      type: PROPOSAL_LIST_SUCCESS,

      payload: {
        averageValueOfProposalsCancelled:
          data.average_value_of_proposals_cancelled.value__avg,
        averageValueOfProposalsInProgress:
          data.average_value_of_proposals_in_progress.value__avg,
        averageValueOfProposalsLost:
          data.average_value_of_proposals_lost.value__avg,
        averageValueOfProposalsQualifying:
          data.average_value_of_proposals_qualifying.value__avg,
        averageValueOfProposalsSubmitted:
          data.average_value_of_proposals_submitted.value__avg,
        averageValueOfProposalsWon:
          data.average_value_of_proposals_won.value__avg,
        numberOfProposalsCancelled: data.number_of_proposals_cancelled,
        numberOfProposalsInProgress: data.number_of_proposals_in_progress,
        numberOfProposalsLost: data.number_of_proposals_lost,
        numberOfProposalsQualifying: data.number_of_proposals_qualifying,
        numberOfProposalsSubmitted: data.number_of_proposals_submitted,
        numberOfProposalsWon: data.number_of_proposals_won,
        totalValueOfProposalsCancelled:
          data.total_value_of_proposals_cancelled.value__sum,
        totalValueOfProposalsInProgress:
          data.total_value_of_proposals_in_progress.value__sum,
        totalValueOfProposalsLost:
          data.total_value_of_proposals_lost.value__sum,
        totalValueOfProposalsQualifying:
          data.total_value_of_proposals_qualifying.value__sum,
        totalValueOfProposalsSubmitted:
          data.total_value_of_proposals_submitted.value__sum,
        totalValueOfProposalsWon: data.total_value_of_proposals_won.value__sum,
        count: data.count,
        proposals: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListProposalsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LIST_RESET });
};

// Create
export const createProposalAction =
  (client, contact, proposal) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          proposal.append("client", data.id);
        }
      }

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );

          proposal.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.createProposal(token, proposal);

      dispatch({
        type: PROPOSAL_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CREATE_RESET });
};

// List details
export const listProposalDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getProposal(id, token);

    dispatch({
      type: PROPOSAL_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListProposalDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_DETAILS_RESET });
};

// Update
export const updateProposalAction =
  (id, client, contact, proposal) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          proposal.append("client", data.id);
        }
      }

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );
          proposal.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.updateProposal(
        id,
        token,
        proposal
      );

      dispatch({
        type: PROPOSAL_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_UPDATE_RESET });
};

// Delete
export const deleteProposalAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteProposal(id, token);

    dispatch({
      type: PROPOSAL_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteProposalAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_DELETE_RESET });
};

// Predict win
export const predictWinProposalAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_PREDICT_WIN_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.predictWinProposal(id, token);

    dispatch({
      type: PROPOSAL_PREDICT_WIN_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_PREDICT_WIN_FAIL,

      payload: error,
    });
  }
};

// Reset predict win
export const resetPredictWinProposalAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_PREDICT_WIN_RESET });
};

// Download
export const downloadProposalAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_DOWNLOAD_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.downloadProposal(id, token);

    dispatch({
      type: PROPOSAL_DOWNLOAD_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_DOWNLOAD_FAIL,

      payload: error,
    });
  }
};

// Reset download
export const resetDownloadProposalAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_DOWNLOAD_RESET });
};
