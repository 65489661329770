// Constant imports
import {
  OPENING_LIST_REQUEST,
  OPENING_LIST_SUCCESS,
  OPENING_LIST_FAIL,
  OPENING_LIST_RESET,
  OPENING_CREATE_REQUEST,
  OPENING_CREATE_SUCCESS,
  OPENING_CREATE_FAIL,
  OPENING_CREATE_RESET,
  OPENING_DETAILS_REQUEST,
  OPENING_DETAILS_SUCCESS,
  OPENING_DETAILS_FAIL,
  OPENING_DETAILS_RESET,
  OPENING_UPDATE_REQUEST,
  OPENING_UPDATE_SUCCESS,
  OPENING_UPDATE_FAIL,
  OPENING_UPDATE_RESET,
  OPENING_DELETE_REQUEST,
  OPENING_DELETE_SUCCESS,
  OPENING_DELETE_FAIL,
  OPENING_DELETE_RESET,
  OPENING_REFERRER_LIST_REQUEST,
  OPENING_REFERRER_LIST_SUCCESS,
  OPENING_REFERRER_LIST_FAIL,
  OPENING_REFERRER_LIST_RESET,
  OPENING_DOWNLOAD_REQUEST,
  OPENING_DOWNLOAD_SUCCESS,
  OPENING_DOWNLOAD_FAIL,
  OPENING_DOWNLOAD_RESET,
} from "../constants/openingConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listOpeningsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPENING_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getOpenings(params, token);

    dispatch({
      type: OPENING_LIST_SUCCESS,

      payload: {
        averageAge: data.average_age.result,
        numberOfOpeningsFilled: data.number_of_openings_filled,
        numberOfOpeningsInterviewing: data.number_of_openings_interviewing,
        numberOfOpeningsOnHold: data.number_of_openings_on_hold,
        count: data.count,
        openings: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: OPENING_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListOpeningsAction = () => async (dispatch) => {
  dispatch({ type: OPENING_LIST_RESET });
};

// Create
export const createOpeningAction = (opening) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPENING_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createOpening(token, opening);

    dispatch({
      type: OPENING_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPENING_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateOpeningAction = () => async (dispatch) => {
  dispatch({ type: OPENING_CREATE_RESET });
};

// List details
export const listOpeningDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPENING_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getOpening(id, token);

    dispatch({
      type: OPENING_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPENING_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListOpeningDetailsAction = () => async (dispatch) => {
  dispatch({ type: OPENING_DETAILS_RESET });
};

// Update
export const updateOpeningAction =
  (id, opening) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOpening(
        id,
        token,
        opening
      );

      dispatch({
        type: OPENING_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningAction = () => async (dispatch) => {
  dispatch({ type: OPENING_UPDATE_RESET });
};

// Delete
export const deleteOpeningAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPENING_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteOpening(id, token);

    dispatch({
      type: OPENING_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPENING_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteOpeningAction = () => async (dispatch) => {
  dispatch({ type: OPENING_DELETE_RESET });
};

// List referrers
export const listOpeningReferrersAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_REFERRER_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpenings(params, token);

      dispatch({
        type: OPENING_REFERRER_LIST_SUCCESS,

        payload: { count: data.count, openings: data.results },
      });
    } catch (error) {
      dispatch({
        type: OPENING_REFERRER_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list referrers
export const resetListOpeningReferrersAction = () => async (dispatch) => {
  dispatch({ type: OPENING_REFERRER_LIST_RESET });
};

// Download
export const downloadOpeningAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: OPENING_DOWNLOAD_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.downloadOpening(id, token);

    dispatch({
      type: OPENING_DOWNLOAD_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: OPENING_DOWNLOAD_FAIL,

      payload: error,
    });
  }
};

// Reset download
export const resetDownloadOpeningAction = () => async (dispatch) => {
  dispatch({ type: OPENING_DOWNLOAD_RESET });
};
