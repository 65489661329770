// Constant imports
import {
  SALARY_BENCHMARK_LIST_REQUEST,
  SALARY_BENCHMARK_LIST_SUCCESS,
  SALARY_BENCHMARK_LIST_FAIL,
  SALARY_BENCHMARK_LIST_RESET,
} from "../constants/salaryBenchmarkConstants";

// List all
export const salaryBenchmarkListReducer = (
  state = {
    loading: false,
    salary_benchmarks: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SALARY_BENCHMARK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SALARY_BENCHMARK_LIST_SUCCESS:
      return {
        loading: false,
        salary_benchmarks: action.payload,
        success: true,
        error: "",
      };

    case SALARY_BENCHMARK_LIST_FAIL:
      return {
        loading: false,
        salary_benchmarks: [],
        success: false,
        error: action.payload,
      };

    case SALARY_BENCHMARK_LIST_RESET:
      return {
        loading: false,
        salary_benchmarks: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
