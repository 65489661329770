// Constant imports
import {
  TIMESHEET_LIST_REQUEST,
  TIMESHEET_LIST_SUCCESS,
  TIMESHEET_LIST_FAIL,
  TIMESHEET_LIST_RESET,
  TIMESHEET_CREATE_REQUEST,
  TIMESHEET_CREATE_SUCCESS,
  TIMESHEET_CREATE_FAIL,
  TIMESHEET_CREATE_RESET,
  TIMESHEET_DETAILS_REQUEST,
  TIMESHEET_DETAILS_SUCCESS,
  TIMESHEET_DETAILS_FAIL,
  TIMESHEET_DETAILS_RESET,
  TIMESHEET_UPDATE_REQUEST,
  TIMESHEET_UPDATE_SUCCESS,
  TIMESHEET_UPDATE_FAIL,
  TIMESHEET_UPDATE_RESET,
  TIMESHEET_DELETE_REQUEST,
  TIMESHEET_DELETE_SUCCESS,
  TIMESHEET_DELETE_FAIL,
  TIMESHEET_DELETE_RESET,
  TIMESHEET_DOWNLOAD_REQUEST,
  TIMESHEET_DOWNLOAD_SUCCESS,
  TIMESHEET_DOWNLOAD_FAIL,
  TIMESHEET_DOWNLOAD_RESET,
  TIMESHEET_DUPLICATE_REQUEST,
  TIMESHEET_DUPLICATE_SUCCESS,
  TIMESHEET_DUPLICATE_FAIL,
  TIMESHEET_DUPLICATE_RESET,
  TIMESHEET_REVERSE_REQUEST,
  TIMESHEET_REVERSE_SUCCESS,
  TIMESHEET_REVERSE_FAIL,
  TIMESHEET_REVERSE_RESET,
} from "../constants/timesheetConstants";

// List all
export const timesheetListReducer = (
  state = {
    loading: false,
    count: 0,
    timesheets: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TIMESHEET_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        timesheets: action.payload.timesheets,
        success: true,
        error: "",
      };

    case TIMESHEET_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        timesheets: [],
        success: false,
        error: action.payload,
      };

    case TIMESHEET_LIST_RESET:
      return {
        loading: false,
        count: 0,
        timesheets: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const timesheetCreateReducer = (
  state = {
    loading: false,
    timesheet: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_CREATE_REQUEST:
      return {
        loading: true,
        timesheet: state.timesheet,
        success: false,
        error: "",
      };

    case TIMESHEET_CREATE_SUCCESS:
      return {
        loading: false,
        timesheet: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_CREATE_FAIL:
      return {
        loading: false,
        timesheet: state.timesheet,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_CREATE_RESET:
      return {
        loading: false,
        timesheet: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const timesheetDetailsReducer = (
  state = {
    loading: false,
    timesheet: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_DETAILS_REQUEST:
      return {
        loading: true,
        timesheet: state.timesheet,
        success: false,
        error: "",
      };

    case TIMESHEET_DETAILS_SUCCESS:
      return {
        loading: false,
        timesheet: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_DETAILS_FAIL:
      return {
        loading: false,
        timesheet: state.timesheet,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_DETAILS_RESET:
      return {
        loading: false,
        timesheet: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const timesheetUpdateReducer = (
  state = {
    loading: false,
    timesheet: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_UPDATE_REQUEST:
      return {
        loading: true,
        timesheet: state.timesheet,
        success: false,
        error: "",
      };

    case TIMESHEET_UPDATE_SUCCESS:
      return {
        loading: false,
        timesheet: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_UPDATE_FAIL:
      return {
        loading: false,
        timesheet: state.timesheet,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_UPDATE_RESET:
      return {
        loading: false,
        timesheet: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const timesheetDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TIMESHEET_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case TIMESHEET_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case TIMESHEET_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const timesheetDownloadReducer = (
  state = {
    loading: false,
    timesheets: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TIMESHEET_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TIMESHEET_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        timesheets: action.payload.timesheets,
        success: true,
        error: "",
      };

    case TIMESHEET_DOWNLOAD_FAIL:
      return {
        loading: false,
        timesheets: [],
        success: false,
        error: action.payload,
      };

    case TIMESHEET_DOWNLOAD_RESET:
      return {
        loading: false,
        timesheets: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Duplicate
export const timesheetDuplicateReducer = (
  state = { loading: false, timesheet: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TIMESHEET_DUPLICATE_REQUEST:
      return {
        loading: true,
        timesheet: state.timesheet,
        success: false,
        error: "",
      };

    case TIMESHEET_DUPLICATE_SUCCESS:
      return {
        loading: false,
        timesheet: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_DUPLICATE_FAIL:
      return {
        loading: false,
        timesheet: state.timesheet,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_DUPLICATE_RESET:
      return { loading: false, timesheet: "", success: false, error: "" };

    default:
      return state;
  }
};

// Reverse
export const timesheetReverseReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TIMESHEET_REVERSE_REQUEST:
      return {
        loading: true,
        result: state.result,
        success: false,
        error: "",
      };

    case TIMESHEET_REVERSE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case TIMESHEET_REVERSE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case TIMESHEET_REVERSE_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};
