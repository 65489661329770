// Constant imports
import {
  CURRENCY_LIST_REQUEST,
  CURRENCY_LIST_SUCCESS,
  CURRENCY_LIST_FAIL,
  CURRENCY_LIST_RESET,
  CURRENCY_CREATE_REQUEST,
  CURRENCY_CREATE_SUCCESS,
  CURRENCY_CREATE_FAIL,
  CURRENCY_CREATE_RESET,
  CURRENCY_DETAILS_REQUEST,
  CURRENCY_DETAILS_SUCCESS,
  CURRENCY_DETAILS_FAIL,
  CURRENCY_DETAILS_RESET,
  CURRENCY_DELETE_REQUEST,
  CURRENCY_DELETE_SUCCESS,
  CURRENCY_DELETE_FAIL,
  CURRENCY_DELETE_RESET,
  CURRENCY_GET_CURRENCY_CHOICES_REQUEST,
  CURRENCY_GET_CURRENCY_CHOICES_SUCCESS,
  CURRENCY_GET_CURRENCY_CHOICES_FAIL,
  CURRENCY_GET_CURRENCY_CHOICES_RESET,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_REQUEST,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_SUCCESS,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_FAIL,
  CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_RESET,
  CURRENCY_GET_RATE_REQUEST,
  CURRENCY_GET_RATE_SUCCESS,
  CURRENCY_GET_RATE_FAIL,
  CURRENCY_GET_RATE_RESET,
} from "../constants/currencyConstants";

// List all
export const currencyListReducer = (
  state = {
    loading: false,
    count: 0,
    currencies: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CURRENCY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CURRENCY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        currencies: action.payload.currencies,
        success: true,
        error: "",
      };

    case CURRENCY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        currencies: [],
        success: false,
        error: action.payload,
      };

    case CURRENCY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        currencies: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const currencyCreateReducer = (
  state = { loading: false, currency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CURRENCY_CREATE_REQUEST:
      return {
        loading: true,
        currency: state.currency,
        success: false,
        error: "",
      };

    case CURRENCY_CREATE_SUCCESS:
      return {
        loading: false,
        currency: action.payload,
        success: true,
        error: "",
      };

    case CURRENCY_CREATE_FAIL:
      return {
        loading: false,
        currency: state.currency,
        success: false,
        error: action.payload,
      };

    case CURRENCY_CREATE_RESET:
      return { loading: false, currency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const currencyDetailsReducer = (
  state = { loading: false, currency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CURRENCY_DETAILS_REQUEST:
      return {
        loading: true,
        currency: state.currency,
        success: false,
        error: "",
      };

    case CURRENCY_DETAILS_SUCCESS:
      return {
        loading: false,
        currency: action.payload,
        success: true,
        error: "",
      };

    case CURRENCY_DETAILS_FAIL:
      return {
        loading: false,
        currency: state.currency,
        success: false,
        error: action.payload,
      };

    case CURRENCY_DETAILS_RESET:
      return { loading: false, currency: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const currencyDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CURRENCY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CURRENCY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CURRENCY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CURRENCY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Get currency choices
export const currencyGetCurrencyChoicesReducer = (
  state = { loading: false, currencies: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CURRENCY_GET_CURRENCY_CHOICES_REQUEST:
      return {
        loading: true,
        currencies: state.currencies,
        success: false,
        error: "",
      };

    case CURRENCY_GET_CURRENCY_CHOICES_SUCCESS:
      return {
        loading: false,
        currencies: action.payload,
        success: true,
        error: "",
      };

    case CURRENCY_GET_CURRENCY_CHOICES_FAIL:
      return {
        loading: false,
        currencies: state.currencies,
        success: false,
        error: action.payload,
      };

    case CURRENCY_GET_CURRENCY_CHOICES_RESET:
      return { loading: false, currencies: [], success: false, error: "" };

    default:
      return state;
  }
};

// Get organisation currency choices
export const currencyGetOrganisationCurrencyChoicesReducer = (
  state = { loading: false, currencies: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_REQUEST:
      return {
        loading: true,
        currencies: state.currencies,
        success: false,
        error: "",
      };

    case CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_SUCCESS:
      return {
        loading: false,
        currencies: action.payload,
        success: true,
        error: "",
      };

    case CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_FAIL:
      return {
        loading: false,
        currencies: state.currencies,
        success: false,
        error: action.payload,
      };

    case CURRENCY_GET_ORGANISATION_CURRENCY_CHOICES_RESET:
      return { loading: false, currencies: [], success: false, error: "" };

    default:
      return state;
  }
};

// Get rate
export const currencyGetRateReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CURRENCY_GET_RATE_REQUEST:
      return {
        loading: true,
        result: state.result,
        success: false,
        error: "",
      };

    case CURRENCY_GET_RATE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case CURRENCY_GET_RATE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case CURRENCY_GET_RATE_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};
