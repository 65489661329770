// Constant imports
import {
  INSIGHT_LIST_REQUEST,
  INSIGHT_LIST_SUCCESS,
  INSIGHT_LIST_FAIL,
  INSIGHT_LIST_RESET,
  INSIGHT_CREATE_REQUEST,
  INSIGHT_CREATE_SUCCESS,
  INSIGHT_CREATE_FAIL,
  INSIGHT_CREATE_RESET,
  INSIGHT_DETAILS_REQUEST,
  INSIGHT_DETAILS_SUCCESS,
  INSIGHT_DETAILS_FAIL,
  INSIGHT_DETAILS_RESET,
  INSIGHT_UPDATE_REQUEST,
  INSIGHT_UPDATE_SUCCESS,
  INSIGHT_UPDATE_FAIL,
  INSIGHT_UPDATE_RESET,
  INSIGHT_DELETE_REQUEST,
  INSIGHT_DELETE_SUCCESS,
  INSIGHT_DELETE_FAIL,
  INSIGHT_DELETE_RESET,
} from "../constants/insightConstants";

// List all
export const insightListReducer = (
  state = {
    loading: false,
    count: 0,
    insights: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INSIGHT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INSIGHT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        insights: action.payload.insights,
        success: true,
        error: "",
      };

    case INSIGHT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        insights: [],
        success: false,
        error: action.payload,
      };

    case INSIGHT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        insights: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const insightCreateReducer = (
  state = { loading: false, insight: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INSIGHT_CREATE_REQUEST:
      return {
        loading: true,
        insight: state.insight,
        success: false,
        error: "",
      };

    case INSIGHT_CREATE_SUCCESS:
      return {
        loading: false,
        insight: action.payload,
        success: true,
        error: "",
      };

    case INSIGHT_CREATE_FAIL:
      return {
        loading: false,
        insight: state.insight,
        success: false,
        error: action.payload,
      };

    case INSIGHT_CREATE_RESET:
      return { loading: false, insight: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const insightDetailsReducer = (
  state = { loading: false, insight: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INSIGHT_DETAILS_REQUEST:
      return {
        loading: true,
        insight: state.insight,
        success: false,
        error: "",
      };

    case INSIGHT_DETAILS_SUCCESS:
      return {
        loading: false,
        insight: action.payload,
        success: true,
        error: "",
      };

    case INSIGHT_DETAILS_FAIL:
      return {
        loading: false,
        insight: state.insight,
        success: false,
        error: action.payload,
      };

    case INSIGHT_DETAILS_RESET:
      return { loading: false, insight: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const insightUpdateReducer = (
  state = { loading: false, insight: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INSIGHT_UPDATE_REQUEST:
      return {
        loading: true,
        insight: state.insight,
        success: false,
        error: "",
      };

    case INSIGHT_UPDATE_SUCCESS:
      return {
        loading: false,
        insight: action.payload,
        success: true,
        error: "",
      };

    case INSIGHT_UPDATE_FAIL:
      return {
        loading: false,
        insight: state.insight,
        success: false,
        error: action.payload,
      };

    case INSIGHT_UPDATE_RESET:
      return { loading: false, insight: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const insightDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INSIGHT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INSIGHT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INSIGHT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INSIGHT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
