// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardCompetenciesChart from "../components/DataCardCompetenciesChart";

// Main function
function CandidateCompetenciesCard({ competencyProfile }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardCompetenciesChart
      comments={
        <>
          <H5>Bookings</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Required competencies for the role Vs average ratings for the
            candidates evaluated.
          </p>
        </>
      }
      data={competencyProfile}
      fill
      noData={competencyProfile.length === 0}
      title="Competencies"
      units="Rating"
    />
  );
}

export default CandidateCompetenciesCard;
