// Library imports
import { Colors, Divider, H5, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function AICreditTransactionPercentageCard({
  fill,
  aiCreditTransactionList,
  aiCreditTransactionQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Credits used by activity</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            AI Credits used by activity as a percentage of total credits used in
            the last 28 days. Activities include:
          </p>

          <UL>
            <li>
              Answer: Answering questions using the organisation's knowledge
              base.
            </li>

            <li>
              Extract: Extracting data from documents to generate content.
            </li>

            <li>
              Index: Indexing new knowledge base documents so the system can
              search them.
            </li>

            <li>Rewrite: Rewriting text in free-text fields.</li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Answer",
          fill: lightMode ? Colors.VIOLET3 : Colors.VIOLET5,
          value: aiCreditTransactionList.totalCreditsAnswer,
        },
        {
          name: "Extract",
          fill: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          value: aiCreditTransactionList.totalCreditsExtract,
        },
        {
          name: "Index",
          fill: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value: aiCreditTransactionList.totalCreditsIndex,
        },
        {
          name: "Rewrite",
          fill: lightMode ? Colors.TURQUOISE3 : Colors.TURQUOISE5,
          value: aiCreditTransactionList.totalCreditsRewrite,
        },
      ]}
      fill={fill}
      filter={
        aiCreditTransactionQuery ? aiCreditTransactionQuery.action : undefined
      }
      noData={
        !aiCreditTransactionList.totalCreditsAnswer &&
        !aiCreditTransactionList.totalCreditsExtract &&
        !aiCreditTransactionList.totalCreditsIndex &&
        !aiCreditTransactionList.totalCreditsRewrite
      }
      showPercent
      title="Credits used by activity"
      units="Percentage"
    />
  );
}

export default AICreditTransactionPercentageCard;
