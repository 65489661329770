// Constant imports
import {
  SERVICE_NEED_LIST_REQUEST,
  SERVICE_NEED_LIST_SUCCESS,
  SERVICE_NEED_LIST_FAIL,
  SERVICE_NEED_LIST_RESET,
  SERVICE_NEED_CREATE_REQUEST,
  SERVICE_NEED_CREATE_SUCCESS,
  SERVICE_NEED_CREATE_FAIL,
  SERVICE_NEED_CREATE_RESET,
  SERVICE_NEED_DETAILS_REQUEST,
  SERVICE_NEED_DETAILS_SUCCESS,
  SERVICE_NEED_DETAILS_FAIL,
  SERVICE_NEED_DETAILS_RESET,
  SERVICE_NEED_UPDATE_REQUEST,
  SERVICE_NEED_UPDATE_SUCCESS,
  SERVICE_NEED_UPDATE_FAIL,
  SERVICE_NEED_UPDATE_RESET,
  SERVICE_NEED_DELETE_REQUEST,
  SERVICE_NEED_DELETE_SUCCESS,
  SERVICE_NEED_DELETE_FAIL,
  SERVICE_NEED_DELETE_RESET,
} from "../constants/serviceNeedConstants";

// List all
export const serviceNeedListReducer = (
  state = {
    loading: false,
    count: 0,
    service_needs: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_NEED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_NEED_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        service_needs: action.payload.service_needs,
        success: true,
        error: "",
      };

    case SERVICE_NEED_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        service_needs: [],
        success: false,
        error: action.payload,
      };

    case SERVICE_NEED_LIST_RESET:
      return {
        loading: false,
        count: 0,
        service_needs: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const serviceNeedCreateReducer = (
  state = {
    loading: false,
    service_need: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_NEED_CREATE_REQUEST:
      return {
        loading: true,
        service_need: state.service_need,
        success: false,
        error: "",
      };

    case SERVICE_NEED_CREATE_SUCCESS:
      return {
        loading: false,
        service_need: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_NEED_CREATE_FAIL:
      return {
        loading: false,
        service_need: state.service_need,
        success: false,
        error: action.payload,
      };

    case SERVICE_NEED_CREATE_RESET:
      return {
        loading: false,
        service_need: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const serviceNeedDetailsReducer = (
  state = {
    loading: false,
    service_need: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_NEED_DETAILS_REQUEST:
      return {
        loading: true,
        service_need: state.service_need,
        success: false,
        error: "",
      };

    case SERVICE_NEED_DETAILS_SUCCESS:
      return {
        loading: false,
        service_need: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_NEED_DETAILS_FAIL:
      return {
        loading: false,
        service_need: state.service_need,
        success: false,
        error: action.payload,
      };

    case SERVICE_NEED_DETAILS_RESET:
      return {
        loading: false,
        service_need: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const serviceNeedUpdateReducer = (
  state = {
    loading: false,
    service_need: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_NEED_UPDATE_REQUEST:
      return {
        loading: true,
        service_need: state.service_need,
        success: false,
        error: "",
      };

    case SERVICE_NEED_UPDATE_SUCCESS:
      return {
        loading: false,
        service_need: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_NEED_UPDATE_FAIL:
      return {
        loading: false,
        service_need: state.service_need,
        success: false,
        error: action.payload,
      };

    case SERVICE_NEED_UPDATE_RESET:
      return {
        loading: false,
        service_need: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const serviceNeedDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_NEED_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case SERVICE_NEED_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case SERVICE_NEED_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case SERVICE_NEED_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
