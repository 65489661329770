export const BOOKING_LIST_REQUEST = "BOOKING_LIST_REQUEST";
export const BOOKING_LIST_SUCCESS = "BOOKING_LIST_SUCCESS";
export const BOOKING_LIST_FAIL = "BOOKING_LIST_FAIL";
export const BOOKING_LIST_RESET = "BOOKING_LIST_RESET";

export const BOOKING_CREATE_REQUEST = "BOOKING_CREATE_REQUEST";
export const BOOKING_CREATE_SUCCESS = "BOOKING_CREATE_SUCCESS";
export const BOOKING_CREATE_FAIL = "BOOKING_CREATE_FAIL";
export const BOOKING_CREATE_RESET = "BOOKING_CREATE_RESET";

export const BOOKING_DETAILS_REQUEST = "BOOKING_DETAILS_REQUEST";
export const BOOKING_DETAILS_SUCCESS = "BOOKING_DETAILS_SUCCESS";
export const BOOKING_DETAILS_FAIL = "BOOKING_DETAILS_FAIL";
export const BOOKING_DETAILS_RESET = "BOOKING_DETAILS_RESET";

export const BOOKING_UPDATE_REQUEST = "BOOKING_UPDATE_REQUEST";
export const BOOKING_UPDATE_SUCCESS = "BOOKING_UPDATE_SUCCESS";
export const BOOKING_UPDATE_FAIL = "BOOKING_UPDATE_FAIL";
export const BOOKING_UPDATE_RESET = "BOOKING_UPDATE_RESET";

export const BOOKING_DELETE_REQUEST = "BOOKING_DELETE_REQUEST";
export const BOOKING_DELETE_SUCCESS = "BOOKING_DELETE_SUCCESS";
export const BOOKING_DELETE_FAIL = "BOOKING_DELETE_FAIL";
export const BOOKING_DELETE_RESET = "BOOKING_DELETE_RESET";
