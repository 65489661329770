// Constant imports
import {
  SALARY_BENCHMARK_LIST_REQUEST,
  SALARY_BENCHMARK_LIST_SUCCESS,
  SALARY_BENCHMARK_LIST_FAIL,
  SALARY_BENCHMARK_LIST_RESET,
} from "../constants/salaryBenchmarkConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listSalaryBenchmarksAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: SALARY_BENCHMARK_LIST_REQUEST });

    const { data } = await cortixDataService.getSalaryBenchmarks(params);

    dispatch({
      type: SALARY_BENCHMARK_LIST_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SALARY_BENCHMARK_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListSalaryBenchmarksAction = () => async (dispatch) => {
  dispatch({ type: SALARY_BENCHMARK_LIST_RESET });
};
