// Constant imports
import {
  NEED_QUESTION_LIST_REQUEST,
  NEED_QUESTION_LIST_SUCCESS,
  NEED_QUESTION_LIST_FAIL,
  NEED_QUESTION_LIST_RESET,
  NEED_QUESTION_CREATE_REQUEST,
  NEED_QUESTION_CREATE_SUCCESS,
  NEED_QUESTION_CREATE_FAIL,
  NEED_QUESTION_CREATE_RESET,
  NEED_QUESTION_DETAILS_REQUEST,
  NEED_QUESTION_DETAILS_SUCCESS,
  NEED_QUESTION_DETAILS_FAIL,
  NEED_QUESTION_DETAILS_RESET,
  NEED_QUESTION_UPDATE_REQUEST,
  NEED_QUESTION_UPDATE_SUCCESS,
  NEED_QUESTION_UPDATE_FAIL,
  NEED_QUESTION_UPDATE_RESET,
  NEED_QUESTION_DELETE_REQUEST,
  NEED_QUESTION_DELETE_SUCCESS,
  NEED_QUESTION_DELETE_FAIL,
  NEED_QUESTION_DELETE_RESET,
  NEED_QUESTION_UPLOAD_BEGIN,
  NEED_QUESTION_UPLOAD_REQUEST,
  NEED_QUESTION_UPLOAD_SUCCESS,
  NEED_QUESTION_UPLOAD_FAIL,
  NEED_QUESTION_UPLOAD_END,
  NEED_QUESTION_UPLOAD_RESET,
} from "../constants/needQuestionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listNeedQuestionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEED_QUESTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getNeedQuestions(params, token);

      dispatch({
        type: NEED_QUESTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          need_questions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: NEED_QUESTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListNeedQuestionsAction = () => async (dispatch) => {
  dispatch({ type: NEED_QUESTION_LIST_RESET });
};

// Create
export const createNeedQuestionAction =
  (need_question) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEED_QUESTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createNeedQuestion(
        token,
        need_question
      );

      dispatch({
        type: NEED_QUESTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEED_QUESTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateNeedQuestionAction = () => async (dispatch) => {
  dispatch({ type: NEED_QUESTION_CREATE_RESET });
};

// List details
export const listNeedQuestionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEED_QUESTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getNeedQuestion(id, token);

      dispatch({
        type: NEED_QUESTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEED_QUESTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListNeedQuestionDetailsAction = () => async (dispatch) => {
  dispatch({ type: NEED_QUESTION_DETAILS_RESET });
};

// Update
export const updateNeedQuestionAction =
  (id, need_question) => async (dispatch, getState) => {
    try {
      dispatch({ type: NEED_QUESTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateNeedQuestion(
        id,
        token,
        need_question
      );

      dispatch({
        type: NEED_QUESTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEED_QUESTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateNeedQuestionAction = () => async (dispatch) => {
  dispatch({ type: NEED_QUESTION_UPDATE_RESET });
};

// Delete
export const deleteNeedQuestionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NEED_QUESTION_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteNeedQuestion(id, token);

    dispatch({
      type: NEED_QUESTION_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEED_QUESTION_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteNeedQuestionAction = () => async (dispatch) => {
  dispatch({ type: NEED_QUESTION_DELETE_RESET });
};

// Upload
export const uploadNeedQuestionsAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: NEED_QUESTION_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      try {
        dispatch({ type: NEED_QUESTION_UPLOAD_REQUEST });

        await cortixDataService.createNeedQuestion(token, {
          name: records[recordNum].name,
          need: id,
          notes: records[recordNum].notes,
          question: records[recordNum].question,
        });

        dispatch({
          type: NEED_QUESTION_UPLOAD_SUCCESS,
        });
      } catch (error) {
        dispatch({
          type: NEED_QUESTION_UPLOAD_FAIL,

          payload: {
            error: error,
          },
        });
      }
    }

    dispatch({ type: NEED_QUESTION_UPLOAD_END });
  };

// Reset upload
export const resetUploadNeedQuestionsAction = () => async (dispatch) => {
  dispatch({ type: NEED_QUESTION_UPLOAD_RESET });
};
