// Library imports
import { Tag } from "@blueprintjs/core";
import React from "react";

// Utility imports
import setTextColour from "../utilities/setTextColour";

// Main function
function TableTag({ color, fillTag, intent, small, value }) {
  // JSX UI code
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Tag
        intent={intent}
        large={!small}
        minimal={!fillTag}
        style={{
          color: color ? setTextColour(color) : undefined,
          backgroundColor: color,
          textAlign: "center",
          minWidth: small ? undefined : "100px",
        }}
      >
        {value ? value : "-"}
      </Tag>
    </div>
  );
}

export default TableTag;
