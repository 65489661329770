// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function ContactSeniorityCard({
  fill,
  contactList,
  contactQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Level of seniority</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Classification of the selected contact(s) by level of seniority.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Upper Mgt",
          fill: lightMode ? Colors.INDIGO2 : Colors.INDIGO3,
          value: contactList.numberUpperManagement,
        },
        {
          name: "Middle Mgt",
          fill: lightMode ? Colors.INDIGO3 : Colors.INDIGO4,
          value: contactList.numberMiddleManagement,
        },
        {
          name: "Lower Mgt",
          fill: lightMode ? Colors.INDIGO4 : Colors.INDIGO5,
          value: contactList.numberLowerManagement,
        },
      ]}
      fill={fill}
      filter={contactQuery ? contactQuery.status : undefined}
      noData={
        !contactList.numberLowerManagement &&
        !contactList.numberMiddleManagement &&
        !contactList.numberUpperManagement
      }
      title="Level of seniority"
      units="Category"
    />
  );
}

export default ContactSeniorityCard;
