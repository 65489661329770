// Constant imports
import {
  OPENING_DOCUMENT_DETAILS_REQUEST,
  OPENING_DOCUMENT_DETAILS_SUCCESS,
  OPENING_DOCUMENT_DETAILS_FAIL,
  OPENING_DOCUMENT_DETAILS_RESET,
  OPENING_DOCUMENT_UPDATE_REQUEST,
  OPENING_DOCUMENT_UPDATE_SUCCESS,
  OPENING_DOCUMENT_UPDATE_FAIL,
  OPENING_DOCUMENT_UPDATE_RESET,
} from "../constants/openingDocumentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List details
export const listOpeningDocumentDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_DOCUMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningDocument(id, token);

      dispatch({
        type: OPENING_DOCUMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_DOCUMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListOpeningDocumentDetailsAction = () => async (dispatch) => {
  dispatch({ type: OPENING_DOCUMENT_DETAILS_RESET });
};

// Update
export const updateOpeningDocumentAction =
  (id, opening_document) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_DOCUMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOpeningDocument(
        id,
        token,
        opening_document
      );

      dispatch({
        type: OPENING_DOCUMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_DOCUMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningDocumentAction = () => async (dispatch) => {
  dispatch({ type: OPENING_DOCUMENT_UPDATE_RESET });
};
