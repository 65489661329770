// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Dialog,
  Divider,
  H5,
  InputGroup,
  Menu,
  MenuItem,
  NonIdealState,
  Switch,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ApprovalDetailDrawer from "../components/ApprovalDetailDrawer";
import BookingCodeBudgetCard from "../components/BookingCodeBudgetCard";
import BookingCodeInfo from "../components/BookingCodeInfo";
import BookingCodeMarginCard from "../components/BookingCodeMarginCard";
import BookingCodeWIPCard from "../components/BookingCodeWIPCard";
import BookingCodeProfitLossCard from "../components/BookingCodeProfitLossCard";
import BookingInfo from "../components/BookingInfo";
import ClientInfo from "../components/ClientInfo";
import ContactCard from "../components/ContactCard";
import ContactInfo from "../components/ContactInfo";
import DataCardContainer from "../components/DataCardContainer";
import DataCardSimple from "../components/DataCardSimple";
import DocumentCard from "../components/DocumentCard";
import EngagementBookingTimeCard from "../components/EngagementBookingTimeCard";
import EngagementBookingUtilisationCard from "../components/EngagementBookingUtilisationCard";
import EngagementMilestonePaidCard from "../components/EngagementMilestonePaidCard";
import EngagementMilestoneProgressCard from "../components/EngagementMilestoneProgressCard";
import EngagementMilestoneValueCard from "../components/EngagementMilestoneValueCard";
import EngagementServiceInfo from "../components/EngagementServiceInfo";
import EngagementStatusSummary from "../components/EngagementStatusSummary";
import ErrorAlert from "../components/ErrorAlert";
import ExpenseCategoryInfo from "../components/ExpenseCategoryInfo";
import ExpenseInfo from "../components/ExpenseInfo";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerDurationField from "../components/FormDrawerDurationField";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerLabourExpenseDaysField2 from "../components/FormDrawerLabourExpenseDaysField2";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerStaffCompetencySearch from "../components/FormDrawerStaffCompetencySearch";
import FormDrawerStaffEmploymentSearch from "../components/FormDrawerStaffEmploymentSearch";
import FormDrawerStaffFreeTimeSearch2 from "../components/FormDrawerStaffFreeTimeSearch2";
import FormDrawerStaffLocationSearch from "../components/FormDrawerStaffLocationSearch";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import ItemCardList from "../components/ItemCardList";
import LabourExpenseInfo from "../components/LabourExpenseInfo";
import LocationInfo from "../components/LocationInfo";
import LinkTag from "../components/LinkTag";
import Kanban from "../components/Kanban";
import Matrix from "../components/Matrix";
import MilestoneInfo from "../components/MilestoneInfo";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NonLabourExpenseInfo from "../components/NonLabourExpenseInfo";
import NumericValue from "../components/NumericValue";
import PercentageValue from "../components/PercentageValue";
import PermissionCard from "../components/PermissionCard";
import RoleInfo from "../components/RoleInfo";
import SearchField from "../components/SearchField";
import ServiceInfo from "../components/ServiceInfo";
import StaffInfo from "../components/StaffInfo";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionDocumentEditor3 from "../components/TabSectionDocumentEditor3";
import TabSectionDocumentEditorFormContainer2 from "../components/TabSectionDocumentEditorFormContainer2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldImage from "../components/TabSectionFieldImage";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";
import Timeline4 from "../components/Timeline4";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import generateImageFromComponent from "../utilities/generateImageFromComponent";
import generateKanbanDataFromEngagementActions from "../utilities/generateKanbanDataFromEngagementActions";
import generateTimelineDataFromEngagementServices from "../utilities/generateTimelineDataFromEngagementServices";
import generateTimelineResourceDataFromEngagementServices from "../utilities/generateTimelineResourceDataFromEngagementServices";
import generateYearList from "../utilities/generateYearList";
import getDjangoDate from "../utilities/getDjangoDate";
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// Approval request
import {
  createApprovalRequestAction,
  deleteApprovalRequestAction,
  listApprovalRequestsAction,
  listApprovalRequestDetailsAction,
  resetCreateApprovalRequestAction,
  resetDeleteApprovalRequestAction,
  resetUpdateApprovalRequestAction,
  updateApprovalRequestAction,
} from "../actions/approvalRequestActions";

// Booking
import { listBookingsAction } from "../actions/bookingActions";

// Booking code
import { listBookingCodesAction } from "../actions/bookingCodeActions";

// Client
import { listClientsAction } from "../actions/clientActions";

// Competency
import { listCompetenciesAction } from "../actions/competencyActions";

// Contact
import { listContactsAction } from "../actions/contactActions";

// Currency
import { getOrganisationCurrencyChoicesCurrencyAction } from "../actions/currencyActions";

// Document
import { generatePdfDocumentAction } from "../actions/documentActions";

// Engagement
import {
  deleteEngagementAction,
  downloadEngagementAction,
  generateReportDataEngagementAction,
  listEngagementDetailsAction,
  resetDeleteEngagementAction,
  resetDownloadEngagementAction,
  resetGenerateReportDataEngagementAction,
  resetListEngagementsAction,
  resetUpdateEngagementAction,
  updateEngagementAction,
} from "../actions/engagementActions";

// Engagement action
import {
  createEngagementActionAction,
  deleteEngagementActionAction,
  listEngagementActionsAction,
  listEngagementActionDetailsAction,
  resetCreateEngagementActionAction,
  resetDeleteEngagementActionAction,
  resetUpdateEngagementActionAction,
  updateEngagementActionAction,
} from "../actions/engagementActionActions";

// Engagement contact
import {
  createEngagementContactAction,
  deleteEngagementContactAction,
  listEngagementContactsAction,
  listEngagementContactDetailsAction,
  resetCreateEngagementContactAction,
  resetDeleteEngagementContactAction,
  resetUpdateEngagementContactAction,
  updateEngagementContactAction,
} from "../actions/engagementContactActions";

// Engagement delivery issue
import {
  createEngagementDeliveryIssueAction,
  deleteEngagementDeliveryIssueAction,
  listEngagementDeliveryIssuesAction,
  listEngagementDeliveryIssueDetailsAction,
  resetCreateEngagementDeliveryIssueAction,
  resetDeleteEngagementDeliveryIssueAction,
  resetUpdateEngagementDeliveryIssueAction,
  updateEngagementDeliveryIssueAction,
} from "../actions/engagementDeliveryIssueActions";

// Engagement dependency
import {
  createEngagementDependencyAction,
  deleteEngagementDependencyAction,
  listEngagementDependenciesAction,
  listEngagementDependencyDetailsAction,
  resetCreateEngagementDependencyAction,
  resetDeleteEngagementDependencyAction,
  resetUpdateEngagementDependencyAction,
  updateEngagementDependencyAction,
} from "../actions/engagementDependencyActions";

// Engagement document
import {
  listEngagementDocumentDetailsAction,
  resetUpdateEngagementDocumentAction,
  updateEngagementDocumentAction,
} from "../actions/engagementDocumentActions";

// Engagement fee
import {
  listEngagementFeesAction,
  listEngagementFeeDetailsAction,
  resetUpdateEngagementFeeAction,
  updateEngagementFeeAction,
} from "../actions/engagementFeeActions";

// Engagement labour expense
import {
  createEngagementLabourExpenseAction,
  deleteEngagementLabourExpenseAction,
  listEngagementLabourExpensesAction,
  listEngagementLabourExpenseDetailsAction,
  resetCreateEngagementLabourExpenseAction,
  resetDeleteEngagementLabourExpenseAction,
  resetUpdateEngagementLabourExpenseAction,
  updateEngagementLabourExpenseAction,
} from "../actions/engagementLabourExpenseActions";

// Engagement lesson
import {
  createEngagementLessonAction,
  deleteEngagementLessonAction,
  listEngagementLessonsAction,
  listEngagementLessonDetailsAction,
  resetCreateEngagementLessonAction,
  resetDeleteEngagementLessonAction,
  resetUpdateEngagementLessonAction,
  updateEngagementLessonAction,
} from "../actions/engagementLessonActions";

// Engagement milestone
import {
  createEngagementMilestoneAction,
  deleteEngagementMilestoneAction,
  listEngagementMilestonesAction,
  listEngagementMilestoneDetailsAction,
  resetCreateEngagementMilestoneAction,
  resetDeleteEngagementMilestoneAction,
  resetUpdateEngagementMilestoneAction,
  updateEngagementMilestoneAction,
} from "../actions/engagementMilestoneActions";

// Engagement non-labour expense
import {
  createEngagementNonLabourExpenseAction,
  deleteEngagementNonLabourExpenseAction,
  listEngagementNonLabourExpensesAction,
  listEngagementNonLabourExpenseDetailsAction,
  resetCreateEngagementNonLabourExpenseAction,
  resetDeleteEngagementNonLabourExpenseAction,
  resetUpdateEngagementNonLabourExpenseAction,
  updateEngagementNonLabourExpenseAction,
} from "../actions/engagementNonLabourExpenseActions";

// Engagement permission
import {
  createEngagementPermissionAction,
  deleteEngagementPermissionAction,
  listEngagementPermissionsAction,
  listEngagementPermissionDetailsAction,
  resetCreateEngagementPermissionAction,
  resetDeleteEngagementPermissionAction,
  resetUpdateEngagementPermissionAction,
  updateEngagementPermissionAction,
} from "../actions/engagementPermissionActions";

// Engagement rate
import {
  createEngagementRateAction,
  deleteEngagementRateAction,
  listEngagementRatesAction,
  listEngagementRateDetailsAction,
  resetCreateEngagementRateAction,
  resetDeleteEngagementRateAction,
  resetUpdateEngagementRateAction,
  updateEngagementRateAction,
} from "../actions/engagementRateActions";

// Engagement report
import {
  createEngagementReportAction,
  deleteEngagementReportAction,
  downloadEngagementReportAction,
  listEngagementReportsAction,
  listEngagementReportDetailsAction,
  resetCreateEngagementReportAction,
  resetDeleteEngagementReportAction,
  resetDownloadEngagementReportAction,
  resetUpdateEngagementReportAction,
  updateEngagementReportAction,
} from "../actions/engagementReportActions";

// Engagement risk
import {
  createEngagementRiskAction,
  deleteEngagementRiskAction,
  listEngagementRisksAction,
  listEngagementRiskDetailsAction,
  resetCreateEngagementRiskAction,
  resetDeleteEngagementRiskAction,
  resetUpdateEngagementRiskAction,
  updateEngagementRiskAction,
} from "../actions/engagementRiskActions";

// Engagement service
import {
  createEngagementServiceAction,
  deleteEngagementServiceAction,
  listEngagementServicesAction,
  listEngagementServiceDetailsAction,
  resetCreateEngagementServiceAction,
  resetDeleteEngagementServiceAction,
  resetUpdateEngagementServiceAction,
  updateEngagementServiceAction,
} from "../actions/engagementServiceActions";

// Engagement service activity
import {
  createEngagementServiceActivityAction,
  deleteEngagementServiceActivityAction,
  listEngagementServiceActivityDetailsAction,
  resetCreateEngagementServiceActivityAction,
  resetDeleteEngagementServiceActivityAction,
  resetUpdateEngagementServiceActivityAction,
  updateEngagementServiceActivityAction,
} from "../actions/engagementServiceActivityActions";

// Engagement service deliverable
import {
  createEngagementServiceDeliverableAction,
  deleteEngagementServiceDeliverableAction,
  listEngagementServiceDeliverablesAction,
  listEngagementServiceDeliverableDetailsAction,
  resetCreateEngagementServiceDeliverableAction,
  resetDeleteEngagementServiceDeliverableAction,
  resetUpdateEngagementServiceDeliverableAction,
  updateEngagementServiceDeliverableAction,
} from "../actions/engagementServiceDeliverableActions";

// Engagement upload
import {
  createEngagementUploadAction,
  deleteEngagementUploadAction,
  listEngagementUploadsAction,
  listEngagementUploadDetailsAction,
  resetCreateEngagementUploadAction,
  resetDeleteEngagementUploadAction,
  resetUpdateEngagementUploadAction,
  updateEngagementUploadAction,
} from "../actions/engagementUploadActions";

// Expense
import { listExpensesAction } from "../actions/expenseActions";

// Expense category
import { listExpenseCategoriesAction } from "../actions/expenseCategoryActions";

// Location
import { listLocationsAction } from "../actions/locationActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Role
import { listRolesAction } from "../actions/roleActions";

// Service
import { listServicesAction } from "../actions/serviceActions";

// Staff
import { listStaffAction } from "../actions/staffActions";

// Main function
function EngagementDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Engagement id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Approval request
  const approvalRequestCreate = useSelector(
    (state) => state.approvalRequestCreate
  );

  const approvalRequestDelete = useSelector(
    (state) => state.approvalRequestDelete
  );

  const approvalRequestDetails = useSelector(
    (state) => state.approvalRequestDetails
  );

  const approvalRequestList = useSelector((state) => state.approvalRequestList);

  const approvalRequestUpdate = useSelector(
    (state) => state.approvalRequestUpdate
  );

  // Booking
  const bookingList = useSelector((state) => state.bookingList);

  // Booking code
  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Client
  const clientList = useSelector((state) => state.clientList);

  // Competency
  const competencyList = useSelector((state) => state.competencyList);

  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Currency
  const currencyGetOrganisationCurrencyChoices = useSelector(
    (state) => state.currencyGetOrganisationCurrencyChoices
  );

  // Document
  const documentGeneratePdf = useSelector((state) => state.documentGeneratePdf);

  // Engagement
  const engagementDelete = useSelector((state) => state.engagementDelete);

  const engagementDetails = useSelector((state) => state.engagementDetails);

  const engagementDownload = useSelector((state) => state.engagementDownload);

  const engagementGenerateReportData = useSelector(
    (state) => state.engagementGenerateReportData
  );

  const engagementUpdate = useSelector((state) => state.engagementUpdate);

  // Engagement action
  const engagementActionCreate = useSelector(
    (state) => state.engagementActionCreate
  );

  const engagementActionDelete = useSelector(
    (state) => state.engagementActionDelete
  );

  const engagementActionDetails = useSelector(
    (state) => state.engagementActionDetails
  );

  const engagementActionList = useSelector(
    (state) => state.engagementActionList
  );

  const engagementActionUpdate = useSelector(
    (state) => state.engagementActionUpdate
  );

  // Engagement contact
  const engagementContactCreate = useSelector(
    (state) => state.engagementContactCreate
  );

  const engagementContactDelete = useSelector(
    (state) => state.engagementContactDelete
  );

  const engagementContactDetails = useSelector(
    (state) => state.engagementContactDetails
  );

  const engagementContactList = useSelector(
    (state) => state.engagementContactList
  );

  const engagementContactUpdate = useSelector(
    (state) => state.engagementContactUpdate
  );

  // Engagement delivery issue
  const engagementDeliveryIssueCreate = useSelector(
    (state) => state.engagementDeliveryIssueCreate
  );

  const engagementDeliveryIssueDelete = useSelector(
    (state) => state.engagementDeliveryIssueDelete
  );

  const engagementDeliveryIssueDetails = useSelector(
    (state) => state.engagementDeliveryIssueDetails
  );

  const engagementDeliveryIssueList = useSelector(
    (state) => state.engagementDeliveryIssueList
  );

  const engagementDeliveryIssueUpdate = useSelector(
    (state) => state.engagementDeliveryIssueUpdate
  );

  // Engagement dependency
  const engagementDependencyCreate = useSelector(
    (state) => state.engagementDependencyCreate
  );

  const engagementDependencyDelete = useSelector(
    (state) => state.engagementDependencyDelete
  );

  const engagementDependencyDetails = useSelector(
    (state) => state.engagementDependencyDetails
  );

  const engagementDependencyList = useSelector(
    (state) => state.engagementDependencyList
  );

  const engagementDependencyUpdate = useSelector(
    (state) => state.engagementDependencyUpdate
  );

  // Engagement document
  const engagementDocumentDetails = useSelector(
    (state) => state.engagementDocumentDetails
  );

  const engagementDocumentUpdate = useSelector(
    (state) => state.engagementDocumentUpdate
  );

  // Engagement fee
  const engagementFeeDetails = useSelector(
    (state) => state.engagementFeeDetails
  );

  const engagementFeeList = useSelector((state) => state.engagementFeeList);

  const engagementFeeUpdate = useSelector((state) => state.engagementFeeUpdate);

  // Engagement labour expense
  const engagementLabourExpenseCreate = useSelector(
    (state) => state.engagementLabourExpenseCreate
  );

  const engagementLabourExpenseDelete = useSelector(
    (state) => state.engagementLabourExpenseDelete
  );

  const engagementLabourExpenseDetails = useSelector(
    (state) => state.engagementLabourExpenseDetails
  );

  const engagementLabourExpenseList = useSelector(
    (state) => state.engagementLabourExpenseList
  );

  const engagementLabourExpenseUpdate = useSelector(
    (state) => state.engagementLabourExpenseUpdate
  );

  // Engagement lesson
  const engagementLessonCreate = useSelector(
    (state) => state.engagementLessonCreate
  );

  const engagementLessonDelete = useSelector(
    (state) => state.engagementLessonDelete
  );

  const engagementLessonDetails = useSelector(
    (state) => state.engagementLessonDetails
  );

  const engagementLessonList = useSelector(
    (state) => state.engagementLessonList
  );

  const engagementLessonUpdate = useSelector(
    (state) => state.engagementLessonUpdate
  );

  // Engagement milestone
  const engagementMilestoneCreate = useSelector(
    (state) => state.engagementMilestoneCreate
  );

  const engagementMilestoneDelete = useSelector(
    (state) => state.engagementMilestoneDelete
  );

  const engagementMilestoneDetails = useSelector(
    (state) => state.engagementMilestoneDetails
  );

  const engagementMilestoneList = useSelector(
    (state) => state.engagementMilestoneList
  );

  const engagementMilestoneUpdate = useSelector(
    (state) => state.engagementMilestoneUpdate
  );

  // Engagement non-labour expense
  const engagementNonLabourExpenseCreate = useSelector(
    (state) => state.engagementNonLabourExpenseCreate
  );

  const engagementNonLabourExpenseDelete = useSelector(
    (state) => state.engagementNonLabourExpenseDelete
  );

  const engagementNonLabourExpenseDetails = useSelector(
    (state) => state.engagementNonLabourExpenseDetails
  );

  const engagementNonLabourExpenseList = useSelector(
    (state) => state.engagementNonLabourExpenseList
  );

  const engagementNonLabourExpenseUpdate = useSelector(
    (state) => state.engagementNonLabourExpenseUpdate
  );

  // Engagement permission
  const engagementPermissionCreate = useSelector(
    (state) => state.engagementPermissionCreate
  );

  const engagementPermissionDelete = useSelector(
    (state) => state.engagementPermissionDelete
  );

  const engagementPermissionDetails = useSelector(
    (state) => state.engagementPermissionDetails
  );

  const engagementPermissionList = useSelector(
    (state) => state.engagementPermissionList
  );

  const engagementPermissionUpdate = useSelector(
    (state) => state.engagementPermissionUpdate
  );

  // Engagement rate
  const engagementRateCreate = useSelector(
    (state) => state.engagementRateCreate
  );

  const engagementRateDelete = useSelector(
    (state) => state.engagementRateDelete
  );

  const engagementRateDetails = useSelector(
    (state) => state.engagementRateDetails
  );

  const engagementRateList = useSelector((state) => state.engagementRateList);

  const engagementRateUpdate = useSelector(
    (state) => state.engagementRateUpdate
  );

  // Engagement report
  const engagementReportCreate = useSelector(
    (state) => state.engagementReportCreate
  );

  const engagementReportDelete = useSelector(
    (state) => state.engagementReportDelete
  );

  const engagementReportDetails = useSelector(
    (state) => state.engagementReportDetails
  );

  const engagementReportDownload = useSelector(
    (state) => state.engagementReportDownload
  );

  const engagementReportList = useSelector(
    (state) => state.engagementReportList
  );

  const engagementReportUpdate = useSelector(
    (state) => state.engagementReportUpdate
  );

  // Engagement risk
  const engagementRiskCreate = useSelector(
    (state) => state.engagementRiskCreate
  );

  const engagementRiskDelete = useSelector(
    (state) => state.engagementRiskDelete
  );

  const engagementRiskDetails = useSelector(
    (state) => state.engagementRiskDetails
  );

  const engagementRiskList = useSelector((state) => state.engagementRiskList);

  const engagementRiskUpdate = useSelector(
    (state) => state.engagementRiskUpdate
  );

  // Engagement service
  const engagementServiceCreate = useSelector(
    (state) => state.engagementServiceCreate
  );

  const engagementServiceDelete = useSelector(
    (state) => state.engagementServiceDelete
  );

  const engagementServiceDetails = useSelector(
    (state) => state.engagementServiceDetails
  );

  const engagementServiceList = useSelector(
    (state) => state.engagementServiceList
  );

  const engagementServiceUpdate = useSelector(
    (state) => state.engagementServiceUpdate
  );

  // Engagement service activity
  const engagementServiceActivityCreate = useSelector(
    (state) => state.engagementServiceActivityCreate
  );

  const engagementServiceActivityDelete = useSelector(
    (state) => state.engagementServiceActivityDelete
  );

  const engagementServiceActivityDetails = useSelector(
    (state) => state.engagementServiceActivityDetails
  );

  const engagementServiceActivityUpdate = useSelector(
    (state) => state.engagementServiceActivityUpdate
  );

  // Engagement service deliverable
  const engagementServiceDeliverableCreate = useSelector(
    (state) => state.engagementServiceDeliverableCreate
  );

  const engagementServiceDeliverableDelete = useSelector(
    (state) => state.engagementServiceDeliverableDelete
  );

  const engagementServiceDeliverableDetails = useSelector(
    (state) => state.engagementServiceDeliverableDetails
  );

  const engagementServiceDeliverableList = useSelector(
    (state) => state.engagementServiceDeliverableList
  );

  const engagementServiceDeliverableUpdate = useSelector(
    (state) => state.engagementServiceDeliverableUpdate
  );

  // Engagement upload
  const engagementUploadCreate = useSelector(
    (state) => state.engagementUploadCreate
  );

  const engagementUploadDelete = useSelector(
    (state) => state.engagementUploadDelete
  );

  const engagementUploadDetails = useSelector(
    (state) => state.engagementUploadDetails
  );

  const engagementUploadList = useSelector(
    (state) => state.engagementUploadList
  );

  const engagementUploadUpdate = useSelector(
    (state) => state.engagementUploadUpdate
  );

  // Expense
  const expenseList = useSelector((state) => state.expenseList);

  // Expense category
  const expenseCategoryList = useSelector((state) => state.expenseCategoryList);

  // Location
  const locationList = useSelector((state) => state.locationList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Role
  const roleList = useSelector((state) => state.roleList);

  // Service
  const serviceList = useSelector((state) => state.serviceList);

  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("engagement-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Schedule
  const [scheduleDialogIsOpen, setScheduleDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Reports
  const [reportDialogIsOpen, setReportDialogIsOpen] = useState(false);

  // Approval requests
  const [addApprovalRequestDrawerIsOpen, setAddApprovalRequestDrawerIsOpen] =
    useState(false);
  const [
    updateApprovalRequestDrawerIsOpen,
    setUpdateApprovalRequestDrawerIsOpen,
  ] = useState(false);
  const [viewApprovalDrawerIsOpen, setViewApprovalDrawerIsOpen] =
    useState(false);

  // Risks
  const [addRiskDrawerIsOpen, setAddRiskDrawerIsOpen] = useState(false);
  const [updateRiskDrawerIsOpen, setUpdateRiskDrawerIsOpen] = useState(false);

  // Actions
  const [addActionDrawerIsOpen, setAddActionDrawerIsOpen] = useState(false);
  const [updateActionDrawerIsOpen, setUpdateActionDrawerIsOpen] =
    useState(false);

  // Issues
  const [addIssueDrawerIsOpen, setAddIssueDrawerIsOpen] = useState(false);
  const [updateIssueDrawerIsOpen, setUpdateIssueDrawerIsOpen] = useState(false);

  // Dependencies
  const [addDependencyDrawerIsOpen, setAddDependencyDrawerIsOpen] =
    useState(false);
  const [updateDependencyDrawerIsOpen, setUpdateDependencyDrawerIsOpen] =
    useState(false);

  // Needs analysis
  const [updateNeedsAnalysisDrawerIsOpen, setUpdateNeedsAnalysisDrawerIsOpen] =
    useState(false);

  // Key stakeholders
  const [
    addEngagementContactDrawerIsOpen,
    setAddEngagementContactDrawerIsOpen,
  ] = useState(false);
  const [
    updateEngagementContactDrawerIsOpen,
    setUpdateEngagementContactDrawerIsOpen,
  ] = useState(false);

  // Our approach
  const [updateApproachDrawerIsOpen, setUpdateApproachDrawerIsOpen] =
    useState(false);

  // Services
  const [addServiceDrawerIsOpen, setAddServiceDrawerIsOpen] = useState(false);
  const [updateServiceDrawerIsOpen, setUpdateServiceDrawerIsOpen] =
    useState(false);

  // Activities
  const [addActivityDrawerIsOpen, setAddActivityDrawerIsOpen] = useState(false);
  const [updateActivityDrawerIsOpen, setUpdateActivityDrawerIsOpen] =
    useState(false);

  // Deliverables
  const [addDeliverableDrawerIsOpen, setAddDeliverableDrawerIsOpen] =
    useState(false);
  const [updateDeliverableDrawerIsOpen, setUpdateDeliverableDrawerIsOpen] =
    useState(false);

  // Labour expenses
  const [addLabourExpenseDrawerIsOpen, setAddLabourExpenseDrawerIsOpen] =
    useState(false);
  const [updateLabourExpenseDrawerIsOpen, setUpdateLabourExpenseDrawerIsOpen] =
    useState(false);

  // Non-labour expenses
  const [addNonLabourExpenseDrawerIsOpen, setAddNonLabourExpenseDrawerIsOpen] =
    useState(false);
  const [
    updateNonLabourExpenseDrawerIsOpen,
    setUpdateNonLabourExpenseDrawerIsOpen,
  ] = useState(false);

  // Fees
  const [updateFeeDrawerIsOpen, setUpdateFeeDrawerIsOpen] = useState(false);

  // Milestones
  const [addMilestoneDrawerIsOpen, setAddMilestoneDrawerIsOpen] =
    useState(false);
  const [updateMilestoneDrawerIsOpen, setUpdateMilestoneDrawerIsOpen] =
    useState(false);

  // Rates
  const [addRateDrawerIsOpen, setAddRateDrawerIsOpen] = useState(false);
  const [updateRateDrawerIsOpen, setUpdateRateDrawerIsOpen] = useState(false);

  // Fee notes
  const [updateFeeNotesDrawerIsOpen, setUpdateFeeNotesDrawerIsOpen] =
    useState(false);

  // Style
  const [
    updateEngagementDocumentStyleDrawerIsOpen,
    setUpdateEngagementDocumentStyleDrawerIsOpen,
  ] = useState(false);

  // Lessons learned
  const [addEngagementLessonDrawerIsOpen, setAddEngagementLessonDrawerIsOpen] =
    useState(false);
  const [
    updateEngagementLessonDrawerIsOpen,
    setUpdateEngagementLessonDrawerIsOpen,
  ] = useState(false);

  // User uploaded files
  const [addUploadDrawerIsOpen, setAddUploadDrawerIsOpen] = useState(false);
  const [updateUploadDrawerIsOpen, setUpdateUploadDrawerIsOpen] =
    useState(false);

  // Other
  const [initialiseActionKanban, setInitialiseActionKanban] = useState(true);
  const [showActionKanban, setShowActionKanban] = useState(false);

  // Search and filter variables
  // Approval request
  const approvalRequestQueryDefault = {
    approver: "",
    decisionRight: "",
    itemId: id,
    itemType: "engagement",
    organisation: activeOrganisation.id,
    outcome: "",
    page: 1,
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalRequestQuery, setApprovalRequestQuery] = useState({
    ...approvalRequestQueryDefault,
  });

  // Booking
  const bookingQueryDefault = {
    billable: "",
    bookingCode: "",
    bookingProfile: "",
    endEnd: "",
    endStart: "",
    engagement: id,
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    staff: "",
    startEnd: "",
    startStart: "",
    type: "",
    user: "",
  };
  const [bookingQuery, setBookingQuery] = useState({
    ...bookingQueryDefault,
  });

  // Booking code
  const bookingCodeQueryDefault = {
    billable: "",
    engagement: id,
    holding: false,
    open: true,
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Competency
  const competencyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [competencyQuery, setCompetencyQuery] = useState({
    ...competencyQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Engagement action
  const engagementActionQueryDefault = {
    category: "",
    dueEnd: "",
    dueStart: "",
    engagement: id,
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [engagementActionQuery, setEngagementActionQuery] = useState({
    ...engagementActionQueryDefault,
  });

  // Engagement contact
  const engagementContactQueryDefault = {
    engagement: id,
    page: 1,
    searchString: "",
  };
  const [engagementContactQuery, setEngagementContactQuery] = useState({
    ...engagementContactQueryDefault,
  });

  // Engagement delivery issue
  const engagementDeliveryIssueQueryDefault = {
    category: "",
    engagement: id,
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [engagementDeliveryIssueQuery, setEngagementDeliveryIssueQuery] =
    useState({
      ...engagementDeliveryIssueQueryDefault,
    });

  // Engagement dependency
  const engagementDependencyQueryDefault = {
    engagement: id,
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [engagementDependencyQuery, setEngagementDependencyQuery] = useState({
    ...engagementDependencyQueryDefault,
  });

  // Engagement fee
  const engagementFeeQueryDefault = {
    engagement: id,
    engagementService: "",
    page: 1,
    sortField: "",
  };
  const [engagementFeeQuery, setEngagementFeeQuery] = useState({
    ...engagementFeeQueryDefault,
  });

  // Engagement labour expense
  const engagementLabourExpenseQueryDefault = {
    engagement: id,
    engagementService: "",
    page: 1,
    role: "",
    sortField: "",
  };
  const [engagementLabourExpenseQuery, setEngagementLabourExpenseQuery] =
    useState({
      ...engagementLabourExpenseQueryDefault,
    });

  // Engagement lesson
  const engagementLessonQueryDefault = {
    engagement: id,
    page: 1,
    searchString: "",
  };
  const [engagementLessonQuery, setEngagementLessonQuery] = useState({
    ...engagementLessonQueryDefault,
  });

  // Engagement milestone
  const engagementMilestoneQueryDefault = {
    engagement: id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
  };
  const [engagementMilestoneQuery, setEngagementMilestoneQuery] = useState({
    ...engagementMilestoneQueryDefault,
  });

  // Engagement non-labour expense
  const engagementNonLabourExpenseQueryDefault = {
    category: "",
    engagement: id,
    engagementService: "",
    page: 1,
    sortField: "",
  };
  const [engagementNonLabourExpenseQuery, setEngagementNonLabourExpenseQuery] =
    useState({
      ...engagementNonLabourExpenseQueryDefault,
    });

  // Engagement permission
  const engagementPermissionQueryDefault = {
    engagement: id,
    page: 1,
    searchString: "",
  };
  const [engagementPermissionQuery, setEngagementPermissionQuery] = useState({
    ...engagementPermissionQueryDefault,
  });

  // Engagement rate
  const engagementRateQueryDefault = {
    engagement: id,
    page: 1,
    role: "",
    sortField: "",
  };
  const [engagementRateQuery, setEngagementRateQuery] = useState({
    ...engagementRateQueryDefault,
  });

  // Engagement report
  const engagementReportQueryDefault = {
    dateEnd: "",
    dateStart: "",
    engagement: id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [engagementReportQuery, setEngagementReportQuery] = useState({
    ...engagementReportQueryDefault,
  });

  // Engagement risk
  const engagementRiskQueryDefault = {
    category: "",
    engagement: id,
    impact: "",
    likelihood: "",
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [engagementRiskQuery, setEngagementRiskQuery] = useState({
    ...engagementRiskQueryDefault,
  });

  // Engagement service
  const engagementServiceQueryDefault = {
    engagement: id,
    page: 1,
    searchString: "",
  };
  const [engagementServiceQuery, setEngagementServiceQuery] = useState({
    ...engagementServiceQueryDefault,
  });

  // Engagement service deliverable
  const engagementServiceDeliverableQueryDefault = {
    dueEnd: "",
    dueStart: "",
    engagement: id,
    milestone: "",
    page: 1,
    searchString: "",
    service: "",
    status: "",
  };
  const [
    engagementServiceDeliverableQuery,
    setEngagementServiceDeliverableQuery,
  ] = useState({
    ...engagementServiceDeliverableQueryDefault,
  });

  // Engagement upload
  const engagementUploadQueryDefault = {
    engagement: id,
    page: 1,
  };
  const [engagementUploadQuery, setEngagementUploadQuery] = useState({
    ...engagementUploadQueryDefault,
  });

  // Expense
  const expenseQueryDefault = {
    billable: "",
    bookingCode: "",
    category: "",
    claim: "",
    dateEnd: "",
    dateStart: "",
    engagement: id,
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    reversed: "false",
    sortField: "",
    staff: "",
  };
  const [expenseQuery, setExpenseQuery] = useState({
    ...expenseQueryDefault,
  });

  // Expense category
  const expenseCategoryQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [expenseCategoryQuery, setExpenseCategoryQuery] = useState({
    ...expenseCategoryQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Service
  const serviceQueryDefault = {
    description: "",
    manager: "",
    name: "",
    organisation: activeOrganisation.id,
    page: 1,
    sortField: "",
    status: "Approved",
  };
  const [serviceQuery, setServiceQuery] = useState({
    ...serviceQueryDefault,
  });

  // Staff
  const staffQueryDefault = {
    bookingProfile: "",
    competencies: "",
    employment: "",
    freeTime: "",
    level: "",
    location: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
    staff: "",
    utilisationScore: "",
  };
  const [staffQuery, setStaffQuery] = useState({
    ...staffQueryDefault,
  });

  // Form variables
  // Approval request
  const approvalRequestDefault = {
    approver: "",
    approverName: "",
    comments: "",
    decisionRight: "Engagement baseline",
    status: "Draft",
  };
  const [approvalRequest, setApprovalRequest] = useState({
    ...approvalRequestDefault,
  });

  // Engagement
  const engagementDefault = {
    approachDiagram: "",
    approachDiagramName: "",
    approachSummary: "",
    bookingCode: "",
    changeDrivers: "",
    client: "",
    clientName: "",
    currency: activeOrganisation.currency,
    currencyExchangeRate: "",
    feeNotes: "",
    issues: "",
    manager: "",
    managerName: "",
    name: "",
    notes: "",
    ourSolution: "",
    restricted: true,
    sponsor: "",
    sponsorName: "",
    sow: "",
    sowName: "",
    status: "Planning",
    strategicOptions: "",
    tax: "No",
    theoryOfChange: "",
    value: "",
  };
  const [engagement, setEngagement] = useState({
    ...engagementDefault,
  });

  // Engagement action
  const engagementActionDefault = {
    action: "",
    category: "Other",
    comments: "",
    due: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "In-progress",
  };
  const [engagementAction, setEngagementAction] = useState({
    ...engagementActionDefault,
  });

  // Engagement contact
  const engagementContactDefault = {
    comments: "",
    contact: "",
    contactName: "",
    expectations: "",
    levelOfInfluence: 3,
    levelOfSupport: 3,
    relationshipOwner: "",
    relationshipOwnerName: "",
  };
  const [engagementContact, setEngagementContact] = useState({
    ...engagementContactDefault,
  });

  // Engagement delivery issue
  const engagementDeliveryIssueDefault = {
    category: "Other",
    issue: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "Open",
    strategy: "",
  };
  const [engagementDeliveryIssue, setEngagementDeliveryIssue] = useState({
    ...engagementDeliveryIssueDefault,
  });

  // Engagement dependency
  const engagementDependencyDefault = {
    dependency: "",
    name: "",
    notes: "",
    owner: "",
    ownerName: "",
    status: "Open",
  };
  const [engagementDependency, setEngagementDependency] = useState({
    ...engagementDependencyDefault,
  });

  // Engagement document
  const engagementDocumentDefault = {
    approachDiagram: "",
    approachDiagramName: "",
    approachHeading: "",
    approachSummary: "",
    backgroundImage: "",
    backgroundImageName: "",
    backPageText: "",
    changeDrivers: "",
    changeDriversHeading: "",
    client: "",
    currency: "",
    date: "",
    dependenciesHeading: "",
    feesHeading: "",
    font: "Sintony",
    includeApproach: true,
    includeDependencies: true,
    includeFeeBreakdown: true,
    includeFees: true,
    includeLegal: true,
    includeNeeds: true,
    includeRisks: true,
    includeServices: true,
    introDependencies: "",
    introFees: "",
    introRisks: "",
    introServices: "",
    issues: "",
    issuesHeading: "",
    logo: "",
    logoName: "",
    manager: "",
    needsHeading: "",
    ourSolution: "",
    ourSolutionHeading: "",
    primaryColour: "#1891AC",
    risksHeading: "",
    scheduleDiagram: "",
    scheduleDiagramName: "",
    secondaryColour: "#111111",
    servicesHeading: "",
    strategicOptions: "",
    strategicOptionsHeading: "",
    template: "London",
    theoryOfChange: "",
    theoryOfChangeHeading: "",
    title: "",
  };
  const [engagementDocument, setEngagementDocument] = useState({
    ...engagementDocumentDefault,
  });

  // Engagement fee
  const engagementFeeDefault = {
    adjustment: 0,
  };
  const [engagementFee, setEngagementFee] = useState({
    ...engagementFeeDefault,
  });

  // Engagement labour expense
  const engagementLabourExpenseDefault = {
    competencies: [],
    customiseDays: false,
    detail: [],
    employment: "Permanent",
    engagementService: "",
    engagementServiceName: "",
    finish: "",
    location: "",
    locationName: "",
    marginPercentage: "",
    method: "Distribute",
    minutes: 60,
    notes: "",
    role: "",
    roleName: "",
    staff: "",
    staffName: "",
    start: "",
    weekdays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: false,
      sun: false,
    },
  };
  const [engagementLabourExpense, setEngagementLabourExpense] = useState({
    ...engagementLabourExpenseDefault,
  });

  // Engagement lesson
  const engagementLessonDefault = {
    category: "Other",
    lesson: "",
    name: "",
  };
  const [engagementLesson, setEngagementLesson] = useState({
    ...engagementLessonDefault,
  });

  // Engagement milestone
  const engagementMilestoneDefault = {
    completed: "",
    description: "",
    due: "",
    invoiced: "",
    name: "",
    paid: "",
    status: "Not completed",
    value: "",
  };
  const [engagementMilestone, setEngagementMilestone] = useState({
    ...engagementMilestoneDefault,
  });

  // Engagement non-labour expense
  const engagementNonLabourExpenseDefault = {
    engagementService: "",
    engagementServiceName: "",
    marginPercentage: "",
    notes: "",
    totalCost: "",
  };
  const [engagementNonLabourExpense, setEngagementNonLabourExpense] = useState({
    ...engagementNonLabourExpenseDefault,
  });

  // Engagement permission
  const engagementPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [engagementPermission, setEngagementPermission] = useState({
    ...engagementPermissionDefault,
  });

  // Engagement rate
  const engagementRateDefault = {
    marginPercentage: "",
    role: "",
    roleName: "",
  };
  const [engagementRate, setEngagementRate] = useState({
    ...engagementRateDefault,
  });

  // Engagement report
  const engagementReportDefault = {
    commentsDeliverables: "",
    commentsDependencies: "",
    commentsFinancials: "",
    commentsIssues: "",
    commentsMilestones: "",
    commentsResources: "",
    commentsRisks: "",
    commentsSummary: "",
    dependenciesStatusComments: "",
    dependenciesStatusRating: "On-track",
    external: false,
    financialsStatusComments: "",
    financialsStatusRating: "On-track",
    font: "Sintony",
    includeDeliverables: true,
    includeDependencies: true,
    includeFinancials: true,
    includeIssues: true,
    includeMilestones: true,
    includeResources: true,
    includeRisks: true,
    includeStatus: true,
    logo: "",
    logoName: "",
    primaryColour: "#1891AC",
    progressStatusComments: "",
    progressStatusRating: "On-track",
    resourcesStatusComments: "",
    resourcesStatusRating: "On-track",
    risksIssuesStatusComments: "",
    risksIssuesStatusRating: "On-track",
    secondaryColour: "#111111",
    stakeholdersStatusComments: "",
    stakeholdersStatusRating: "On-track",
    template: "London",
    title: "",
    status: "Draft",
  };
  const [engagementReport, setEngagementReport] = useState({
    ...engagementReportDefault,
  });

  // Engagement risk
  const engagementRiskDefault = {
    category: "Other",
    impact: "Medium",
    likelihood: "Medium",
    name: "",
    owner: "",
    ownerName: "",
    risk: "",
    status: "Open",
    strategy: "",
  };
  const [engagementRisk, setEngagementRisk] = useState({
    ...engagementRiskDefault,
  });

  // Engagement service
  const engagementServiceDefault = {
    benefits: "",
    description: "",
    name: "",
    service: "",
    serviceName: "",
  };
  const [engagementService, setEngagementService] = useState({
    ...engagementServiceDefault,
  });

  // Engagement service activity
  const engagementServiceActivityDefault = {
    description: "",
    end: new Date(),
    name: "",
    progress: 0,
    service: "",
    serviceName: "",
    start: new Date(),
  };
  const [engagementServiceActivity, setEngagementServiceActivity] = useState({
    ...engagementServiceActivityDefault,
  });

  // Engagement service deliverable
  const engagementServiceDeliverableDefault = {
    description: "",
    due: new Date(),
    milestone: "",
    milestoneName: "",
    name: "",
    service: "",
    serviceName: "",
    status: "Not delivered",
    template: "",
    templateName: "",
    type: "",
  };
  const [engagementServiceDeliverable, setEngagementServiceDeliverable] =
    useState({
      ...engagementServiceDeliverableDefault,
    });

  // Engagement upload
  const engagementUploadDefault = {
    description: "",
    file: "",
    fileName: "",
    name: "",
  };
  const [engagementUpload, setEngagementUpload] = useState({
    ...engagementUploadDefault,
  });

  // Other
  const [engagementDeleteKey, setServiceDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval request
    setApprovalRequest({
      ...approvalRequestDefault,
    });

    // Engagement
    setEngagement({
      ...engagementDefault,
    });

    // Engagement action
    setEngagementAction({
      ...engagementActionDefault,
    });

    // Engagement contact
    setEngagementContact({
      ...engagementContactDefault,
    });

    // Engagement delivery issue
    setEngagementDeliveryIssue({
      ...engagementDeliveryIssueDefault,
    });

    // Engagement dependency
    setEngagementDependency({
      ...engagementDependencyDefault,
    });

    // Engagement fee
    setEngagementFee({
      ...engagementFeeDefault,
    });

    // Engagement labour expense
    setEngagementLabourExpense({
      ...engagementLabourExpenseDefault,
    });

    // Engagement lesson
    setEngagementLesson({
      ...engagementLessonDefault,
    });

    // Engagement milestone
    setEngagementMilestone({
      ...engagementMilestoneDefault,
    });

    // Engagement non-labour expense
    setEngagementNonLabourExpense({
      ...engagementNonLabourExpenseDefault,
    });

    // Engagement permission
    setEngagementPermission({
      ...engagementPermissionDefault,
    });

    // Engagement rate
    setEngagementRate({
      ...engagementRateDefault,
    });

    // Engagement report
    setEngagementReport({
      ...engagementReportDefault,
    });

    // Engagement risk
    setEngagementRisk({
      ...engagementRiskDefault,
    });

    // Engagement service
    setEngagementService({
      ...engagementServiceDefault,
    });

    // Engagement service activity
    setEngagementServiceActivity({
      ...engagementServiceActivityDefault,
    });

    // Engagement service deliverable
    setEngagementServiceDeliverable({
      ...engagementServiceDeliverableDefault,
    });

    // Engagement upload
    setEngagementUpload({
      ...engagementUploadDefault,
    });

    // Query variables
    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Expense category
    setExpenseCategoryQuery({
      ...expenseCategoryQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Role
    setRoleQuery({
      ...roleQueryDefault,
    });

    // Service
    setServiceQuery({
      ...serviceQueryDefault,
    });

    // Staff
    setStaffQuery({
      ...staffQueryDefault,
    });
  };

  // Streaming data websocket connection
  const { lastJsonMessage } = useWebSocket(
    `${BACKEND_WS_URL}/api/v1/data/engagement/${id}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  // Initialise drawer variables for approval request
  useEffect(() => {
    setApprovalRequest({
      approver: approvalRequestDetails.approval_request.approver
        ? approvalRequestDetails.approval_request.approver
        : approvalRequestDefault.approver,
      approverName: approvalRequestDetails.approval_request.approver_name
        ? approvalRequestDetails.approval_request.approver_name
        : approvalRequestDefault.approverName,
      comments: approvalRequestDetails.approval_request.comments
        ? approvalRequestDetails.approval_request.comments
        : approvalRequestDefault.comments,
      decisionRight: approvalRequestDetails.approval_request.decision_right
        ? approvalRequestDetails.approval_request.decision_right
        : approvalRequestDefault.decisionRight,
      status: approvalRequestDetails.approval_request.status
        ? approvalRequestDetails.approval_request.status
        : approvalRequestDefault.status,
    });
    // eslint-disable-next-line
  }, [approvalRequestDetails.approval_request]);

  // Initialise drawer variables for engagement
  useEffect(() => {
    setEngagement({
      approachDiagram: engagementDetails.engagement.approach_diagram
        ? engagementDetails.engagement.approach_diagram
        : engagementDefault.approachDiagram,
      approachDiagramName: engagementDetails.engagement.approach_diagram
        ? engagementDetails.engagement.approach_diagram.split("/").pop()
        : engagementDefault.approachDiagramName,
      approachSummary: engagementDetails.engagement.approach_summary
        ? engagementDetails.engagement.approach_summary
        : engagementDefault.approachSummary,
      bookingCode: engagementDetails.engagement.booking_code
        ? engagementDetails.engagement.booking_code
        : engagementDefault.bookingCode,
      changeDrivers: engagementDetails.engagement.change_drivers
        ? engagementDetails.engagement.change_drivers
        : engagementDefault.changeDrivers,
      client: engagementDetails.engagement.client
        ? engagementDetails.engagement.client
        : engagementDefault.client,
      clientName: engagementDetails.engagement.client_name
        ? engagementDetails.engagement.client_name
        : engagementDefault.clientName,
      currency: engagementDetails.engagement.currency
        ? engagementDetails.engagement.currency
        : engagementDefault.currency,
      currencyExchangeRate: engagementDetails.engagement.currency_exchange_rate
        ? engagementDetails.engagement.currency_exchange_rate
        : engagementDefault.currencyExchangeRate,
      feeNotes: engagementDetails.engagement.fee_notes
        ? engagementDetails.engagement.fee_notes
        : engagementDefault.feeNotes,
      issues: engagementDetails.engagement.issues
        ? engagementDetails.engagement.issues
        : engagementDefault.issues,
      manager: engagementDetails.engagement.manager
        ? engagementDetails.engagement.manager
        : engagementDefault.manager,
      managerName: engagementDetails.engagement.manager_name
        ? engagementDetails.engagement.manager_name
        : engagementDefault.managerName,
      name: engagementDetails.engagement.name
        ? engagementDetails.engagement.name
        : engagementDefault.name,
      notes: engagementDetails.engagement.notes
        ? engagementDetails.engagement.notes
        : engagementDefault.notes,
      ourSolution: engagementDetails.engagement.our_solution
        ? engagementDetails.engagement.our_solution
        : engagementDefault.ourSolution,
      restricted:
        engagementDetails.engagement.restricted != null
          ? engagementDetails.engagement.restricted
          : engagementDefault.restricted,
      sponsor: engagementDetails.engagement.sponsor
        ? engagementDetails.engagement.sponsor
        : engagementDefault.sponsor,
      sponsorName: engagementDetails.engagement.sponsor_name
        ? engagementDetails.engagement.sponsor_name
        : engagementDefault.sponsorName,
      sow: engagementDetails.engagement.sow
        ? engagementDetails.engagement.sow
        : engagementDefault.sow,
      sowName: engagementDetails.engagement.sow
        ? engagementDetails.engagement.sow.split("/").pop()
        : engagementDefault.sowName,
      status: engagementDetails.engagement.status
        ? engagementDetails.engagement.status
        : engagementDefault.status,
      strategicOptions: engagementDetails.engagement.strategic_options
        ? engagementDetails.engagement.strategic_options
        : engagementDefault.strategicOptions,
      tax: engagementDetails.engagement.tax
        ? engagementDetails.engagement.tax
        : engagementDefault.tax,
      theoryOfChange: engagementDetails.engagement.theory_of_change
        ? engagementDetails.engagement.theory_of_change
        : engagementDefault.theoryOfChange,
      value: engagementDetails.engagement.value
        ? engagementDetails.engagement.value
        : engagementDefault.value,
    });
    // eslint-disable-next-line
  }, [engagementDetails.engagement]);

  // Initialise drawer variables for engagement action
  useEffect(() => {
    setEngagementAction({
      action: engagementActionDetails.engagement_action.action
        ? engagementActionDetails.engagement_action.action
        : engagementActionDefault.action,
      category: engagementActionDetails.engagement_action.category
        ? engagementActionDetails.engagement_action.category
        : engagementActionDefault.category,
      comments: engagementActionDetails.engagement_action.comments
        ? engagementActionDetails.engagement_action.comments
        : engagementActionDefault.comments,
      due: engagementActionDetails.engagement_action.due
        ? new Date(engagementActionDetails.engagement_action.due)
        : engagementActionDefault.due,
      name: engagementActionDetails.engagement_action.name
        ? engagementActionDetails.engagement_action.name
        : engagementActionDefault.name,
      owner: engagementActionDetails.engagement_action.owner
        ? engagementActionDetails.engagement_action.owner
        : engagementActionDefault.owner,
      ownerName: engagementActionDetails.engagement_action.owner_name
        ? engagementActionDetails.engagement_action.owner_name
        : engagementActionDefault.ownerName,
      status: engagementActionDetails.engagement_action.status
        ? engagementActionDetails.engagement_action.status
        : engagementActionDefault.status,
    });
    // eslint-disable-next-line
  }, [engagementActionDetails.engagement_action]);

  // Initialise drawer variables for engagement contact
  useEffect(() => {
    setEngagementContact({
      comments: engagementContactDetails.engagement_contact.comments
        ? engagementContactDetails.engagement_contact.comments
        : engagementContactDefault.comments,
      contact: engagementContactDetails.engagement_contact.contact
        ? engagementContactDetails.engagement_contact.contact
        : engagementContactDefault.contact,
      contactName: engagementContactDetails.engagement_contact.contact_data
        ? engagementContactDetails.engagement_contact.contact_data.name
        : engagementContactDefault.contactName,
      expectations: engagementContactDetails.engagement_contact.expectations
        ? engagementContactDetails.engagement_contact.expectations
        : engagementContactDefault.expectations,
      levelOfInfluence: engagementContactDetails.engagement_contact
        .level_of_influence
        ? engagementContactDetails.engagement_contact.level_of_influence
        : engagementContactDefault.levelOfInfluence,
      levelOfSupport: engagementContactDetails.engagement_contact
        .level_of_support
        ? engagementContactDetails.engagement_contact.level_of_support
        : engagementContactDefault.levelOfSupport,
      relationshipOwner: engagementContactDetails.engagement_contact
        .relationship_owner
        ? engagementContactDetails.engagement_contact.relationship_owner
        : engagementContactDefault.relationshipOwner,
      relationshipOwnerName: engagementContactDetails.engagement_contact
        .relationship_owner_name
        ? engagementContactDetails.engagement_contact.relationship_owner_name
        : engagementContactDefault.relationshipOwnerName,
    });
    // eslint-disable-next-line
  }, [engagementContactDetails.engagement_contact]);

  // Initialise drawer variables for engagement delivery issue
  useEffect(() => {
    setEngagementDeliveryIssue({
      category: engagementDeliveryIssueDetails.engagement_issue.category
        ? engagementDeliveryIssueDetails.engagement_issue.category
        : engagementDeliveryIssueDefault.category,
      issue: engagementDeliveryIssueDetails.engagement_issue.issue
        ? engagementDeliveryIssueDetails.engagement_issue.issue
        : engagementDeliveryIssueDefault.issue,
      name: engagementDeliveryIssueDetails.engagement_issue.name
        ? engagementDeliveryIssueDetails.engagement_issue.name
        : engagementDeliveryIssueDefault.name,
      owner: engagementDeliveryIssueDetails.engagement_issue.owner
        ? engagementDeliveryIssueDetails.engagement_issue.owner
        : engagementDeliveryIssueDefault.owner,
      ownerName: engagementDeliveryIssueDetails.engagement_issue.owner_name
        ? engagementDeliveryIssueDetails.engagement_issue.owner_name
        : engagementDeliveryIssueDefault.ownerName,
      status: engagementDeliveryIssueDetails.engagement_issue.status
        ? engagementDeliveryIssueDetails.engagement_issue.status
        : engagementDeliveryIssueDefault.status,
      strategy: engagementDeliveryIssueDetails.engagement_issue.strategy
        ? engagementDeliveryIssueDetails.engagement_issue.strategy
        : engagementDeliveryIssueDefault.strategy,
    });
    // eslint-disable-next-line
  }, [engagementDeliveryIssueDetails.engagement_issue]);

  // Initialise drawer variables for engagement dependency
  useEffect(() => {
    setEngagementDependency({
      dependency: engagementDependencyDetails.engagement_dependency.dependency
        ? engagementDependencyDetails.engagement_dependency.dependency
        : engagementDependencyDefault.dependency,
      name: engagementDependencyDetails.engagement_dependency.name
        ? engagementDependencyDetails.engagement_dependency.name
        : engagementDependencyDefault.name,
      notes: engagementDependencyDetails.engagement_dependency.notes
        ? engagementDependencyDetails.engagement_dependency.notes
        : engagementDependencyDefault.notes,
      owner: engagementDependencyDetails.engagement_dependency.owner
        ? engagementDependencyDetails.engagement_dependency.owner
        : engagementDependencyDefault.owner,
      ownerName: engagementDependencyDetails.engagement_dependency.owner_name
        ? engagementDependencyDetails.engagement_dependency.owner_name
        : engagementDependencyDefault.ownerName,
      status: engagementDependencyDetails.engagement_dependency.status
        ? engagementDependencyDetails.engagement_dependency.status
        : engagementDependencyDefault.status,
    });
    // eslint-disable-next-line
  }, [engagementDependencyDetails.engagement_dependency]);

  // Initialise drawer variables for engagement document
  useEffect(() => {
    setEngagementDocument({
      approachDiagram: engagementDocumentDetails.engagement_document
        .approach_diagram
        ? engagementDocumentDetails.engagement_document.approach_diagram
        : engagementDocumentDefault.approachDiagram,
      approachDiagramName: engagementDocumentDetails.engagement_document
        .approach_diagram
        ? engagementDocumentDetails.engagement_document.approach_diagram
            .split("/")
            .pop()
        : engagementDocumentDefault.approachDiagramName,
      approachHeading: engagementDocumentDetails.engagement_document
        .approach_heading
        ? engagementDocumentDetails.engagement_document.approach_heading
        : engagementDocumentDefault.approachHeading,
      approachSummary: engagementDocumentDetails.engagement_document
        .approach_summary
        ? engagementDocumentDetails.engagement_document.approach_summary
        : engagementDocumentDefault.approachSummary,
      backgroundImage: engagementDocumentDetails.engagement_document
        .background_image
        ? engagementDocumentDetails.engagement_document.background_image
        : engagementDocumentDefault.backgroundImage,
      backgroundImageName: engagementDocumentDetails.engagement_document
        .background_image
        ? engagementDocumentDetails.engagement_document.background_image
            .split("/")
            .pop()
        : engagementDocumentDefault.backgroundImageName,
      backPageText: engagementDocumentDetails.engagement_document.back_page_text
        ? engagementDocumentDetails.engagement_document.back_page_text
        : engagementDocumentDefault.backPageText,
      changeDrivers: engagementDocumentDetails.engagement_document
        .change_drivers
        ? engagementDocumentDetails.engagement_document.change_drivers
        : engagementDocumentDefault.changeDrivers,
      changeDriversHeading: engagementDocumentDetails.engagement_document
        .change_drivers_heading
        ? engagementDocumentDetails.engagement_document.change_drivers_heading
        : engagementDocumentDefault.changeDriversHeading,
      client: engagementDocumentDetails.engagement_document.client
        ? engagementDocumentDetails.engagement_document.client
        : engagementDocumentDefault.client,
      currency: engagementDocumentDetails.engagement_document.currency
        ? engagementDocumentDetails.engagement_document.currency
        : engagementDocumentDefault.currency,
      date: engagementDocumentDetails.engagement_document.date
        ? new Date(engagementDocumentDetails.engagement_document.date)
        : engagementDocumentDefault.date,
      dependenciesHeading: engagementDocumentDetails.engagement_document
        .dependencies_heading
        ? engagementDocumentDetails.engagement_document.dependencies_heading
        : engagementDocumentDefault.dependenciesHeading,
      feesHeading: engagementDocumentDetails.engagement_document.fees_heading
        ? engagementDocumentDetails.engagement_document.fees_heading
        : engagementDocumentDefault.feesHeading,
      font: engagementDocumentDetails.engagement_document.font
        ? engagementDocumentDetails.engagement_document.font
        : engagementDocumentDefault.font,
      includeApproach:
        engagementDocumentDetails.engagement_document.include_approach !== null
          ? engagementDocumentDetails.engagement_document.include_approach
          : engagementDocumentDefault.includeApproach,
      includeDependencies:
        engagementDocumentDetails.engagement_document.include_dependencies !==
        null
          ? engagementDocumentDetails.engagement_document.include_dependencies
          : engagementDocumentDefault.includeDependencies,
      includeFeeBreakdown:
        engagementDocumentDetails.engagement_document.include_fee_breakdown !==
        null
          ? engagementDocumentDetails.engagement_document.include_fee_breakdown
          : engagementDocumentDefault.includeFeeBreakdown,
      includeFees:
        engagementDocumentDetails.engagement_document.include_fees !== null
          ? engagementDocumentDetails.engagement_document.include_fees
          : engagementDocumentDefault.includeFees,
      includeNeeds:
        engagementDocumentDetails.engagement_document.include_needs !== null
          ? engagementDocumentDetails.engagement_document.include_needs
          : engagementDocumentDefault.includeNeeds,
      includeRisks:
        engagementDocumentDetails.engagement_document.include_risks !== null
          ? engagementDocumentDetails.engagement_document.include_risks
          : engagementDocumentDefault.includeRisks,
      includeServices:
        engagementDocumentDetails.engagement_document.include_services !== null
          ? engagementDocumentDetails.engagement_document.include_services
          : engagementDocumentDefault.includeServices,
      introDependencies: engagementDocumentDetails.engagement_document
        .intro_dependencies
        ? engagementDocumentDetails.engagement_document.intro_dependencies
        : engagementDocumentDefault.introDependencies,
      introFees: engagementDocumentDetails.engagement_document.intro_fees
        ? engagementDocumentDetails.engagement_document.intro_fees
        : engagementDocumentDefault.introFees,
      introRisks: engagementDocumentDetails.engagement_document.intro_risks
        ? engagementDocumentDetails.engagement_document.intro_risks
        : engagementDocumentDefault.introRisks,
      introServices: engagementDocumentDetails.engagement_document
        .intro_services
        ? engagementDocumentDetails.engagement_document.intro_services
        : engagementDocumentDefault.introServices,
      issues: engagementDocumentDetails.engagement_document.issues
        ? engagementDocumentDetails.engagement_document.issues
        : engagementDocumentDefault.issues,
      issuesHeading: engagementDocumentDetails.engagement_document
        .issues_heading
        ? engagementDocumentDetails.engagement_document.issues_heading
        : engagementDocumentDefault.issuesHeading,
      logo: engagementDocumentDetails.engagement_document.logo
        ? engagementDocumentDetails.engagement_document.logo
        : engagementDocumentDefault.logo,
      logoName: engagementDocumentDetails.engagement_document.logo
        ? engagementDocumentDetails.engagement_document.logo.split("/").pop()
        : engagementDocumentDefault.logoName,
      manager: engagementDocumentDetails.engagement_document.manager
        ? engagementDocumentDetails.engagement_document.manager
        : engagementDocumentDefault.manager,
      needsHeading: engagementDocumentDetails.engagement_document.needs_heading
        ? engagementDocumentDetails.engagement_document.needs_heading
        : engagementDocumentDefault.needsHeading,
      ourSolution: engagementDocumentDetails.engagement_document.our_solution
        ? engagementDocumentDetails.engagement_document.our_solution
        : engagementDocumentDefault.ourSolution,
      ourSolutionHeading: engagementDocumentDetails.engagement_document
        .our_solution_heading
        ? engagementDocumentDetails.engagement_document.our_solution_heading
        : engagementDocumentDefault.ourSolutionHeading,
      primaryColour: engagementDocumentDetails.engagement_document
        .primary_colour
        ? engagementDocumentDetails.engagement_document.primary_colour
        : engagementDocumentDefault.primaryColour,
      risksHeading: engagementDocumentDetails.engagement_document.risks_heading
        ? engagementDocumentDetails.engagement_document.risks_heading
        : engagementDocumentDefault.risksHeading,
      scheduleDiagram: engagementDocumentDetails.engagement_document
        .schedule_diagram
        ? engagementDocumentDetails.engagement_document.schedule_diagram
        : engagementDocumentDefault.scheduleDiagram,
      scheduleDiagramName: engagementDocumentDetails.engagement_document
        .schedule_diagram
        ? engagementDocumentDetails.engagement_document.schedule_diagram
            .split("/")
            .pop()
        : engagementDocumentDefault.scheduleDiagramName,
      secondaryColour: engagementDocumentDetails.engagement_document
        .secondary_colour
        ? engagementDocumentDetails.engagement_document.secondary_colour
        : engagementDocumentDefault.secondaryColour,
      servicesHeading: engagementDocumentDetails.engagement_document
        .services_heading
        ? engagementDocumentDetails.engagement_document.services_heading
        : engagementDocumentDefault.servicesHeading,
      strategicOptions: engagementDocumentDetails.engagement_document
        .strategic_options
        ? engagementDocumentDetails.engagement_document.strategic_options
        : engagementDocumentDefault.strategicOptions,
      strategicOptionsHeading: engagementDocumentDetails.engagement_document
        .strategic_options_heading
        ? engagementDocumentDetails.engagement_document
            .strategic_options_heading
        : engagementDocumentDefault.strategicOptionsHeading,
      template: engagementDocumentDetails.engagement_document.template
        ? engagementDocumentDetails.engagement_document.template
        : engagementDocumentDefault.template,
      theoryOfChange: engagementDocumentDetails.engagement_document
        .theory_of_change
        ? engagementDocumentDetails.engagement_document.theory_of_change
        : engagementDocumentDefault.theoryOfChange,
      theoryOfChangeHeading: engagementDocumentDetails.engagement_document
        .theory_of_change_heading
        ? engagementDocumentDetails.engagement_document.theory_of_change_heading
        : engagementDocumentDefault.theoryOfChangeHeading,
      title: engagementDocumentDetails.engagement_document.title
        ? engagementDocumentDetails.engagement_document.title
        : engagementDocumentDefault.title,
    });
    // eslint-disable-next-line
  }, [engagementDocumentDetails.engagement_document]);

  // Initialise drawer variables for engagement fee
  useEffect(() => {
    setEngagementFee({
      adjustment: engagementFeeDetails.engagement_fee.adjustment
        ? engagementFeeDetails.engagement_fee.adjustment
        : engagementFeeDefault.adjustment,
    });
    // eslint-disable-next-line
  }, [engagementFeeDetails.engagement_fee]);

  // Initialise drawer variables for engagement labour expense
  useEffect(() => {
    setEngagementLabourExpense({
      competencies:
        engagementLabourExpenseDetails.engagement_labour_expense.competencies &&
        engagementLabourExpenseDetails.engagement_labour_expense.competencies
          .length > 0
          ? engagementLabourExpenseDetails.engagement_labour_expense
              .competencies
          : engagementLabourExpenseDefault.competencies,
      customiseDays:
        engagementLabourExpenseDetails.engagement_labour_expense.detail &&
        Object.keys(
          engagementLabourExpenseDetails.engagement_labour_expense.detail
        ).length > 0
          ? true
          : false,
      detail:
        engagementLabourExpenseDetails.engagement_labour_expense.detail &&
        Object.keys(
          engagementLabourExpenseDetails.engagement_labour_expense.detail
        ).length > 0
          ? engagementLabourExpenseDetails.engagement_labour_expense.detail
          : engagementLabourExpenseDefault.detail,
      employment: engagementLabourExpenseDetails.engagement_labour_expense
        .employment
        ? engagementLabourExpenseDetails.engagement_labour_expense.employment
        : engagementLabourExpenseDefault.employment,
      finish: engagementLabourExpenseDetails.engagement_labour_expense.finish
        ? new Date(
            engagementLabourExpenseDetails.engagement_labour_expense.finish
          )
        : engagementLabourExpenseDefault.finish,
      location: engagementLabourExpenseDetails.engagement_labour_expense
        .location
        ? engagementLabourExpenseDetails.engagement_labour_expense.location
        : engagementLabourExpenseDefault.location,
      locationName: engagementLabourExpenseDetails.engagement_labour_expense
        .location_data
        ? engagementLabourExpenseDetails.engagement_labour_expense.location_data
            .name
        : engagementLabourExpenseDefault.locationName,
      marginPercentage: engagementLabourExpenseDetails.engagement_labour_expense
        .margin_percentage
        ? engagementLabourExpenseDetails.engagement_labour_expense
            .margin_percentage
        : engagementLabourExpenseDefault.marginPercentage,
      method: engagementLabourExpenseDetails.engagement_labour_expense.method
        ? engagementLabourExpenseDetails.engagement_labour_expense.method
        : engagementLabourExpenseDefault.method,
      minutes: engagementLabourExpenseDetails.engagement_labour_expense.minutes
        ? engagementLabourExpenseDetails.engagement_labour_expense.minutes
        : engagementLabourExpenseDefault.minutes,
      notes: engagementLabourExpenseDetails.engagement_labour_expense.notes
        ? engagementLabourExpenseDetails.engagement_labour_expense.notes
        : engagementLabourExpenseDefault.notes,
      engagementService: engagementLabourExpenseDetails
        .engagement_labour_expense.engagement_service
        ? engagementLabourExpenseDetails.engagement_labour_expense
            .engagement_service
        : engagementLabourExpenseDefault.engagementService,
      engagementServiceName: engagementLabourExpenseDetails
        .engagement_labour_expense.engagement_service_name
        ? engagementLabourExpenseDetails.engagement_labour_expense
            .engagement_service_name
        : engagementLabourExpenseDefault.engagementServiceName,
      role: engagementLabourExpenseDetails.engagement_labour_expense.role
        ? engagementLabourExpenseDetails.engagement_labour_expense.role
        : engagementLabourExpenseDefault.role,
      roleName: engagementLabourExpenseDetails.engagement_labour_expense
        .role_data
        ? engagementLabourExpenseDetails.engagement_labour_expense.role_data
            .name
        : engagementLabourExpenseDefault.roleName,
      staff: engagementLabourExpenseDetails.engagement_labour_expense.staff
        ? engagementLabourExpenseDetails.engagement_labour_expense.staff
        : engagementLabourExpenseDefault.staff,
      staffName: engagementLabourExpenseDetails.engagement_labour_expense
        .staff_name
        ? engagementLabourExpenseDetails.engagement_labour_expense.staff_name
        : engagementLabourExpenseDefault.staffName,
      start: engagementLabourExpenseDetails.engagement_labour_expense.start
        ? new Date(
            engagementLabourExpenseDetails.engagement_labour_expense.start
          )
        : engagementLabourExpenseDefault.start,
      weekdays:
        engagementLabourExpenseDetails.engagement_labour_expense.weekdays &&
        Object.keys(
          engagementLabourExpenseDetails.engagement_labour_expense.weekdays
        ).length > 0
          ? engagementLabourExpenseDetails.engagement_labour_expense.weekdays
          : engagementLabourExpenseDefault.weekdays,
    });
    // eslint-disable-next-line
  }, [engagementLabourExpenseDetails.engagement_labour_expense]);

  // Initialise drawer variables for engagement lesson
  useEffect(() => {
    setEngagementLesson({
      category: engagementLessonDetails.engagement_lesson.category
        ? engagementLessonDetails.engagement_lesson.category
        : engagementLessonDefault.category,
      lesson: engagementLessonDetails.engagement_lesson.lesson
        ? engagementLessonDetails.engagement_lesson.lesson
        : engagementLessonDefault.lesson,
      name: engagementLessonDetails.engagement_lesson.name
        ? engagementLessonDetails.engagement_lesson.name
        : engagementLessonDefault.name,
    });
    // eslint-disable-next-line
  }, [engagementLessonDetails.engagement_lesson]);

  // Initialise drawer variables for engagement milestone
  useEffect(() => {
    setEngagementMilestone({
      completed: engagementMilestoneDetails.engagement_milestone.completed
        ? new Date(engagementMilestoneDetails.engagement_milestone.completed)
        : engagementMilestoneDefault.completed,
      description: engagementMilestoneDetails.engagement_milestone.description
        ? engagementMilestoneDetails.engagement_milestone.description
        : engagementMilestoneDefault.description,
      due: engagementMilestoneDetails.engagement_milestone.due
        ? new Date(engagementMilestoneDetails.engagement_milestone.due)
        : engagementMilestoneDefault.due,
      invoiced: engagementMilestoneDetails.engagement_milestone.invoiced
        ? new Date(engagementMilestoneDetails.engagement_milestone.invoiced)
        : engagementMilestoneDefault.invoiced,
      name: engagementMilestoneDetails.engagement_milestone.name
        ? engagementMilestoneDetails.engagement_milestone.name
        : engagementMilestoneDefault.name,
      paid: engagementMilestoneDetails.engagement_milestone.paid
        ? new Date(engagementMilestoneDetails.engagement_milestone.paid)
        : engagementMilestoneDefault.paid,
      status: engagementMilestoneDetails.engagement_milestone.status
        ? engagementMilestoneDetails.engagement_milestone.status
        : engagementMilestoneDefault.status,
      value: engagementMilestoneDetails.engagement_milestone.value
        ? engagementMilestoneDetails.engagement_milestone.value
        : engagementMilestoneDefault.value,
    });
    // eslint-disable-next-line
  }, [engagementMilestoneDetails.engagement_milestone]);

  // Initialise drawer variables for engagement non-labour expense
  useEffect(() => {
    setEngagementNonLabourExpense({
      category: engagementNonLabourExpenseDetails.engagement_non_labour_expense
        .category
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense
            .category
        : engagementNonLabourExpenseDefault.category,
      categoryName: engagementNonLabourExpenseDetails
        .engagement_non_labour_expense.category_data
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense
            .category_data.name
        : engagementNonLabourExpenseDefault.categoryName,
      cost: engagementNonLabourExpenseDetails.engagement_non_labour_expense.cost
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense.cost
        : engagementNonLabourExpenseDefault.cost,
      marginPercentage: engagementNonLabourExpenseDetails
        .engagement_non_labour_expense.margin_percentage
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense
            .margin_percentage
        : engagementNonLabourExpenseDefault.marginPercentage,
      name: engagementNonLabourExpenseDetails.engagement_non_labour_expense.name
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense.name
        : engagementNonLabourExpenseDefault.name,
      notes: engagementNonLabourExpenseDetails.engagement_non_labour_expense
        .notes
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense.notes
        : engagementNonLabourExpenseDefault.notes,
      engagementService: engagementNonLabourExpenseDetails
        .engagement_non_labour_expense.engagement_service
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense
            .engagement_service
        : engagementNonLabourExpenseDefault.engagementService,
      engagementServiceName: engagementNonLabourExpenseDetails
        .engagement_non_labour_expense.engagement_service_name
        ? engagementNonLabourExpenseDetails.engagement_non_labour_expense
            .engagement_service_name
        : engagementNonLabourExpenseDefault.engagementServiceName,
    });
    // eslint-disable-next-line
  }, [engagementNonLabourExpenseDetails.engagement_non_labour_expense]);

  // Initialise drawer variables for engagement permission
  useEffect(() => {
    setEngagementPermission({
      level: engagementPermissionDetails.engagement_permission.level
        ? engagementPermissionDetails.engagement_permission.level
        : engagementPermissionDefault.level,
      manager: engagementPermissionDetails.engagement_permission.manager
        ? engagementPermissionDetails.engagement_permission.manager
        : engagementPermissionDefault.manager,
      managerName: engagementPermissionDetails.engagement_permission
        .manager_name
        ? engagementPermissionDetails.engagement_permission.manager_name
        : engagementPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [engagementPermissionDetails.engagement_permission]);

  // Initialise drawer variables for engagement rate
  useEffect(() => {
    setEngagementRate({
      marginPercentage:
        engagementRateDetails.engagement_rate.margin_percentage !== null
          ? engagementRateDetails.engagement_rate.margin_percentage
          : engagementRateDefault.marginPercentage,
      role: engagementRateDetails.engagement_rate.role
        ? engagementRateDetails.engagement_rate.role
        : engagementRateDefault.role,
      roleName: engagementRateDetails.engagement_rate.role_name
        ? engagementRateDetails.engagement_rate.role_name
        : engagementRateDefault.roleName,
    });
    // eslint-disable-next-line
  }, [engagementRateDetails.engagement_rate]);

  // Initialise drawer variables for engagement report
  useEffect(() => {
    setEngagementReport({
      commentsDeliverables: engagementReportDetails.engagement_report
        .comments_deliverables
        ? engagementReportDetails.engagement_report.comments_deliverables
        : engagementReportDefault.commentsDeliverables,
      commentsDependencies: engagementReportDetails.engagement_report
        .comments_dependencies
        ? engagementReportDetails.engagement_report.comments_dependencies
        : engagementReportDefault.commentsDependencies,
      commentsFinancials: engagementReportDetails.engagement_report
        .comments_financials
        ? engagementReportDetails.engagement_report.comments_financials
        : engagementReportDefault.commentsFinancials,
      commentsIssues: engagementReportDetails.engagement_report.comments_issues
        ? engagementReportDetails.engagement_report.comments_issues
        : engagementReportDefault.commentsIssues,
      commentsMilestones: engagementReportDetails.engagement_report
        .comments_milestones
        ? engagementReportDetails.engagement_report.comments_milestones
        : engagementReportDefault.commentsMilestones,
      commentsResources: engagementReportDetails.engagement_report
        .comments_resources
        ? engagementReportDetails.engagement_report.comments_resources
        : engagementReportDefault.commentsResources,
      commentsRisks: engagementReportDetails.engagement_report.comments_risks
        ? engagementReportDetails.engagement_report.comments_risks
        : engagementReportDefault.commentsRisks,
      commentsSummary: engagementReportDetails.engagement_report
        .comments_summary
        ? engagementReportDetails.engagement_report.comments_summary
        : engagementReportDefault.commentsSummary,
      dependenciesStatusComments: engagementReportDetails.engagement_report
        .dependencies_status_comments
        ? engagementReportDetails.engagement_report.dependencies_status_comments
        : engagementReportDefault.dependenciesStatusComments,
      dependenciesStatusRating: engagementReportDetails.engagement_report
        .dependencies_status_rating
        ? engagementReportDetails.engagement_report.dependencies_status_rating
        : engagementReportDefault.dependenciesStatusRating,
      external:
        engagementReportDetails.engagement_report.external !== null
          ? engagementReportDetails.engagement_report.external
          : engagementReportDefault.external,
      financialsStatusComments: engagementReportDetails.engagement_report
        .financials_status_comments
        ? engagementReportDetails.engagement_report.financials_status_comments
        : engagementReportDefault.financialsStatusComments,
      financialsStatusRating: engagementReportDetails.engagement_report
        .financials_status_rating
        ? engagementReportDetails.engagement_report.financials_status_rating
        : engagementReportDefault.financialsStatusRating,
      font: engagementReportDetails.engagement_report.font
        ? engagementReportDetails.engagement_report.font
        : engagementReportDefault.font,
      includeDeliverables:
        engagementReportDetails.engagement_report.include_deliverables !== null
          ? engagementReportDetails.engagement_report.include_deliverables
          : engagementReportDefault.includeDeliverables,
      includeDependencies:
        engagementReportDetails.engagement_report.include_dependencies !== null
          ? engagementReportDetails.engagement_report.include_dependencies
          : engagementReportDefault.includeDependencies,
      includeFinancials:
        engagementReportDetails.engagement_report.include_financials !== null
          ? engagementReportDetails.engagement_report.include_financials
          : engagementReportDefault.includeFinancials,
      includeIssues:
        engagementReportDetails.engagement_report.include_issues !== null
          ? engagementReportDetails.engagement_report.include_issues
          : engagementReportDefault.includeIssues,
      includeMilestones:
        engagementReportDetails.engagement_report.include_milestones !== null
          ? engagementReportDetails.engagement_report.include_milestones
          : engagementReportDefault.includeMilestones,
      includeResources:
        engagementReportDetails.engagement_report.include_resources !== null
          ? engagementReportDetails.engagement_report.include_resources
          : engagementReportDefault.includeResources,
      includeRisks:
        engagementReportDetails.engagement_report.include_risks !== null
          ? engagementReportDetails.engagement_report.include_risks
          : engagementReportDefault.includeRisks,
      includeStatus:
        engagementReportDetails.engagement_report.include_status !== null
          ? engagementReportDetails.engagement_report.include_status
          : engagementReportDefault.includeStatus,
      logo: engagementReportDetails.engagement_report.logo
        ? engagementReportDetails.engagement_report.logo
        : engagementReportDefault.logo,
      logoName: engagementReportDetails.engagement_report.logo
        ? engagementReportDetails.engagement_report.logo.split("/").pop()
        : engagementReportDefault.logoName,
      primaryColour: engagementReportDetails.engagement_report.primary_colour
        ? engagementReportDetails.engagement_report.primary_colour
        : engagementReportDefault.primaryColour,
      progressStatusComments: engagementReportDetails.engagement_report
        .progress_status_comments
        ? engagementReportDetails.engagement_report.progress_status_comments
        : engagementReportDefault.progressStatusComments,
      progressStatusRating: engagementReportDetails.engagement_report
        .progress_status_rating
        ? engagementReportDetails.engagement_report.progress_status_rating
        : engagementReportDefault.progressStatusRating,
      resourcesStatusComments: engagementReportDetails.engagement_report
        .resources_status_comments
        ? engagementReportDetails.engagement_report.resources_status_comments
        : engagementReportDefault.resourcesStatusComments,
      resourcesStatusRating: engagementReportDetails.engagement_report
        .resources_status_rating
        ? engagementReportDetails.engagement_report.resources_status_rating
        : engagementReportDefault.resourcesStatusRating,
      risksIssuesStatusComments: engagementReportDetails.engagement_report
        .risks_issues_status_comments
        ? engagementReportDetails.engagement_report.risks_issues_status_comments
        : engagementReportDefault.risksIssuesStatusComments,
      risksIssuesStatusRating: engagementReportDetails.engagement_report
        .risks_issues_status_rating
        ? engagementReportDetails.engagement_report.risks_issues_status_rating
        : engagementReportDefault.risksIssuesStatusRating,
      secondaryColour: engagementReportDetails.engagement_report
        .secondary_colour
        ? engagementReportDetails.engagement_report.secondary_colour
        : engagementReportDefault.secondaryColour,
      stakeholdersStatusComments: engagementReportDetails.engagement_report
        .stakeholders_status_comments
        ? engagementReportDetails.engagement_report.stakeholders_status_comments
        : engagementReportDefault.stakeholdersStatusComments,
      stakeholdersStatusRating: engagementReportDetails.engagement_report
        .stakeholders_status_rating
        ? engagementReportDetails.engagement_report.stakeholders_status_rating
        : engagementReportDefault.stakeholdersStatusRating,
      template: engagementReportDetails.engagement_report.template
        ? engagementReportDetails.engagement_report.template
        : engagementReportDefault.template,
      title: engagementReportDetails.engagement_report.title
        ? engagementReportDetails.engagement_report.title
        : engagementReportDefault.title,
      status: engagementReportDetails.engagement_report.status
        ? engagementReportDetails.engagement_report.status
        : engagementReportDefault.status,
    });
    // eslint-disable-next-line
  }, [engagementReportDetails.engagement_report]);

  // Initialise drawer variables for engagement risk
  useEffect(() => {
    setEngagementRisk({
      category: engagementRiskDetails.engagement_risk.category
        ? engagementRiskDetails.engagement_risk.category
        : engagementRiskDefault.category,
      impact: engagementRiskDetails.engagement_risk.impact
        ? engagementRiskDetails.engagement_risk.impact
        : engagementRiskDefault.impact,
      likelihood: engagementRiskDetails.engagement_risk.likelihood
        ? engagementRiskDetails.engagement_risk.likelihood
        : engagementRiskDefault.likelihood,
      name: engagementRiskDetails.engagement_risk.name
        ? engagementRiskDetails.engagement_risk.name
        : engagementRiskDefault.name,
      owner: engagementRiskDetails.engagement_risk.owner
        ? engagementRiskDetails.engagement_risk.owner
        : engagementRiskDefault.owner,
      ownerName: engagementRiskDetails.engagement_risk.owner_name
        ? engagementRiskDetails.engagement_risk.owner_name
        : engagementRiskDefault.ownerName,
      risk: engagementRiskDetails.engagement_risk.risk
        ? engagementRiskDetails.engagement_risk.risk
        : engagementRiskDefault.risk,
      status: engagementRiskDetails.engagement_risk.status
        ? engagementRiskDetails.engagement_risk.status
        : engagementRiskDefault.status,
      strategy: engagementRiskDetails.engagement_risk.strategy
        ? engagementRiskDetails.engagement_risk.strategy
        : engagementRiskDefault.strategy,
    });
    // eslint-disable-next-line
  }, [engagementRiskDetails.engagement_risk]);

  // Initialise drawer variables for engagement service
  useEffect(() => {
    setEngagementService({
      benefits: engagementServiceDetails.engagement_service.benefits
        ? engagementServiceDetails.engagement_service.benefits
        : engagementServiceDefault.benefits,
      description: engagementServiceDetails.engagement_service.description
        ? engagementServiceDetails.engagement_service.description
        : engagementServiceDefault.description,
      name: engagementServiceDetails.engagement_service.name
        ? engagementServiceDetails.engagement_service.name
        : engagementServiceDefault.name,
      service: engagementServiceDefault.service,
      serviceName: engagementServiceDefault.serviceName,
    });
    // eslint-disable-next-line
  }, [engagementServiceDetails.engagement_service]);

  // Initialise drawer variables for engagement service activity
  useEffect(() => {
    setEngagementServiceActivity({
      description: engagementServiceActivityDetails.engagement_service_activity
        .description
        ? engagementServiceActivityDetails.engagement_service_activity
            .description
        : engagementServiceActivityDefault.description,
      end: engagementServiceActivityDetails.engagement_service_activity.end
        ? new Date(
            engagementServiceActivityDetails.engagement_service_activity.end
          )
        : engagementServiceActivityDefault.end,
      name: engagementServiceActivityDetails.engagement_service_activity.name
        ? engagementServiceActivityDetails.engagement_service_activity.name
        : engagementServiceActivityDefault.name,
      progress: engagementServiceActivityDetails.engagement_service_activity
        .progress
        ? engagementServiceActivityDetails.engagement_service_activity.progress
        : engagementServiceActivityDefault.progress,
      service: engagementServiceActivityDetails.engagement_service_activity
        .service
        ? engagementServiceActivityDetails.engagement_service_activity.service
        : engagementServiceActivityDefault.service,
      serviceName: engagementServiceActivityDetails.engagement_service_activity
        .service_name
        ? engagementServiceActivityDetails.engagement_service_activity
            .service_name
        : engagementServiceActivityDefault.serviceName,
      start: engagementServiceActivityDetails.engagement_service_activity.start
        ? new Date(
            engagementServiceActivityDetails.engagement_service_activity.start
          )
        : engagementServiceActivityDefault.start,
    });
    // eslint-disable-next-line
  }, [engagementServiceActivityDetails.engagement_service_activity]);

  // Initialise drawer variables for engagement service deliverable
  useEffect(() => {
    setEngagementServiceDeliverable({
      description: engagementServiceDeliverableDetails
        .engagement_service_deliverable.description
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .description
        : engagementServiceDeliverableDefault.description,
      due: engagementServiceDeliverableDetails.engagement_service_deliverable
        .due
        ? new Date(
            engagementServiceDeliverableDetails.engagement_service_deliverable.due
          )
        : engagementServiceDeliverableDefault.due,
      milestone: engagementServiceDeliverableDetails
        .engagement_service_deliverable.milestone
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .milestone
        : engagementServiceDeliverableDefault.milestone,
      milestoneName: engagementServiceDeliverableDetails
        .engagement_service_deliverable.milestone_name
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .milestone_name
        : engagementServiceDeliverableDefault.milestoneName,
      name: engagementServiceDeliverableDetails.engagement_service_deliverable
        .name
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .name
        : engagementServiceDeliverableDefault.name,
      service: engagementServiceDeliverableDetails
        .engagement_service_deliverable.service
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .service
        : engagementServiceDeliverableDefault.service,
      serviceName: engagementServiceDeliverableDetails
        .engagement_service_deliverable.service_name
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .service_name
        : engagementServiceDeliverableDefault.serviceName,
      status: engagementServiceDeliverableDetails.engagement_service_deliverable
        .status
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .status
        : engagementServiceDeliverableDefault.status,
      template: engagementServiceDeliverableDetails
        .engagement_service_deliverable.template
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .template
        : engagementServiceDeliverableDefault.template,
      templateName: engagementServiceDeliverableDetails
        .engagement_service_deliverable.template
        ? engagementServiceDeliverableDetails.engagement_service_deliverable.template
            .split("/")
            .pop()
        : engagementServiceDeliverableDefault.templateName,
      type: engagementServiceDeliverableDetails.engagement_service_deliverable
        .type
        ? engagementServiceDeliverableDetails.engagement_service_deliverable
            .type
        : engagementServiceDeliverableDefault.type,
    });
    // eslint-disable-next-line
  }, [engagementServiceDeliverableDetails.engagement_service_deliverable]);

  // Initialise drawer variables for engagement upload
  useEffect(() => {
    setEngagementUpload({
      description: engagementUploadDetails.engagement_upload.description
        ? engagementUploadDetails.engagement_upload.description
        : engagementUploadDefault.description,
      file: engagementUploadDetails.engagement_upload.file
        ? engagementUploadDetails.engagement_upload.file
        : engagementUploadDefault.file,
      fileName: engagementUploadDetails.engagement_upload.file
        ? engagementUploadDetails.engagement_upload.file.split("/").pop()
        : engagementUploadDefault.fileName,
      name: engagementUploadDetails.engagement_upload.name
        ? engagementUploadDetails.engagement_upload.name
        : engagementUploadDefault.name,
    });
    // eslint-disable-next-line
  }, [engagementUploadDetails.engagement_upload]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If engagement deleted redirect to engagements
  useEffect(() => {
    if (engagementDelete.success) {
      dispatch(resetDeleteEngagementAction());

      dispatch(resetListEngagementsAction());

      navigate("/engagements");
    }
    // eslint-disable-next-line
  }, [engagementDelete.success]);

  // Load currency data
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    dispatch(
      getOrganisationCurrencyChoicesCurrencyAction({
        organisation: activeOrganisation.id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      currencies.length === 0 &&
      currencyGetOrganisationCurrencyChoices.currencies
    ) {
      currencyGetOrganisationCurrencyChoices.currencies.map((currency) =>
        setCurrencies((prevState) => [
          ...prevState,
          {
            id: currency[1],
            icon: (
              <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                {currency[0]}
              </Tag>
            ),
            value: currency[1],
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [currencyGetOrganisationCurrencyChoices.success]);

  // Load engagement details
  useEffect(() => {
    dispatch(listEngagementDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Load engagement document data
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    if (engagementDetails.engagement.engagement_document && !initialLoadDone) {
      dispatch(
        listEngagementDocumentDetailsAction(
          engagementDetails.engagement.engagement_document
        )
      );

      setInitialLoadDone(true);
    }
    // eslint-disable-next-line
  }, [engagementDetails.engagement.engagement_document]);

  useEffect(() => {
    if (
      initialLoadDone &&
      (engagementDependencyCreate.success ||
        engagementDependencyDelete.success ||
        engagementDependencyUpdate.success ||
        engagementFeeUpdate.success ||
        engagementLabourExpenseCreate.success ||
        engagementLabourExpenseDelete.success ||
        engagementLabourExpenseUpdate.success ||
        engagementMilestoneCreate.success ||
        engagementMilestoneDelete.success ||
        engagementMilestoneUpdate.success ||
        engagementNonLabourExpenseCreate.success ||
        engagementNonLabourExpenseDelete.success ||
        engagementNonLabourExpenseUpdate.success ||
        engagementRiskCreate.success ||
        engagementRiskDelete.success ||
        engagementRiskUpdate.success ||
        engagementServiceCreate.success ||
        engagementServiceDelete.success ||
        engagementServiceUpdate.success ||
        engagementUpdate.success)
    ) {
      dispatch(
        listEngagementDocumentDetailsAction(
          engagementDetails.engagement.engagement_document
        )
      );
    }
    // eslint-disable-next-line
  }, [
    engagementDependencyCreate.success,
    engagementDependencyDelete.success,
    engagementDependencyUpdate.success,
    engagementFeeUpdate.success,
    engagementLabourExpenseCreate.success,
    engagementLabourExpenseDelete.success,
    engagementLabourExpenseUpdate.success,
    engagementMilestoneCreate.success,
    engagementMilestoneDelete.success,
    engagementMilestoneUpdate.success,
    engagementNonLabourExpenseCreate.success,
    engagementNonLabourExpenseDelete.success,
    engagementNonLabourExpenseUpdate.success,
    engagementRiskCreate.success,
    engagementRiskDelete.success,
    engagementRiskUpdate.success,
    engagementServiceCreate.success,
    engagementServiceDelete.success,
    engagementServiceUpdate.success,
    engagementUpdate.success,
  ]);

  useEffect(() => {
    if (initialLoadDone && engagementDocumentUpdate.success) {
      dispatch(
        listEngagementDocumentDetailsAction(
          engagementDetails.engagement.engagement_document
        )
      );

      dispatch(resetUpdateEngagementDocumentAction());
    }
    // eslint-disable-next-line
  }, [engagementDocumentUpdate.success]);

  useEffect(() => {
    if (engagementDocumentDetails.success) {
      dispatch(
        generatePdfDocumentAction({
          data: engagementDocumentDetails.engagement_document,
          type: "engagement_charter",
        })
      );
    }
    // eslint-disable-next-line
  }, [engagementDocumentDetails.success]);

  // Document paging variables
  const [sectionPages, setSectionPages] = useState([]);
  //const [showPage, setShowPage] = useState("");

  // Load engagement report data
  useEffect(() => {
    if (engagementReportCreate.success) {
      dispatch(
        listEngagementReportDetailsAction(
          engagementReportCreate.engagement_report.id
        )
      );

      setReportDialogIsOpen(true);

      dispatch(resetCreateEngagementReportAction());
    }
    // eslint-disable-next-line
  }, [engagementReportCreate.success]);

  useEffect(() => {
    if (engagementReportDetails.success) {
      dispatch(
        generatePdfDocumentAction({
          data: engagementReportDetails.engagement_report,
          type: "engagement_report",
        })
      );
    }
    // eslint-disable-next-line
  }, [engagementReportDetails.success]);

  // Filter approval requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalRequestsAction({
            ...approvalRequestQuery,
            reviewedEnd: approvalRequestQuery.reviewedEnd
              ? getDjangoDate(approvalRequestQuery.reviewedEnd)
              : getDjangoDate(approvalRequestQueryDefault.reviewedEnd),
            reviewedStart: approvalRequestQuery.reviewedStart
              ? getDjangoDate(approvalRequestQuery.reviewedStart)
              : getDjangoDate(approvalRequestQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRequestQuery]);

  // Filter bookings
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listBookingsAction({
            ...bookingQuery,
            endEnd: bookingQuery.endEnd
              ? getDjangoDate(bookingQuery.endEnd)
              : "",
            endStart: bookingQuery.endStart
              ? getDjangoDate(bookingQuery.endStart)
              : "",
            startEnd: bookingQuery.startEnd
              ? getDjangoDate(bookingQuery.startEnd)
              : "",
            startStart: bookingQuery.startStart
              ? getDjangoDate(bookingQuery.startStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingQuery]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listBookingCodesAction({ ...bookingCodeQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter competencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCompetenciesAction({
            ...competencyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [competencyQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter engagement actions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementActionsAction({
            ...engagementActionQuery,
            dueEnd: engagementActionQuery.dueEnd
              ? getDjangoDate(engagementActionQuery.dueEnd)
              : "",
            dueStart: engagementActionQuery.dueStart
              ? getDjangoDate(engagementActionQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementActionQuery]);

  // Filter engagement contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementContactsAction({
            ...engagementContactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementContactQuery]);

  // Filter engagement delivery issues
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementDeliveryIssuesAction({
            ...engagementDeliveryIssueQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementDeliveryIssueQuery]);

  // Filter engagement dependencies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementDependenciesAction({ ...engagementDependencyQuery })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementDependencyQuery]);

  // Filter engagement fees
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementFeesAction({
            ...engagementFeeQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementFeeQuery]);

  // Filter engagement labour expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementLabourExpensesAction({
            ...engagementLabourExpenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementLabourExpenseQuery]);

  // Filter engagement lessons
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementLessonsAction({
            ...engagementLessonQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementLessonQuery]);

  // Filter engagement milestones
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementMilestonesAction({ ...engagementMilestoneQuery })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementMilestoneQuery]);

  // Filter engagement non-labour expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementNonLabourExpensesAction({
            ...engagementNonLabourExpenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementNonLabourExpenseQuery]);

  // Filter engagement permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementPermissionsAction({ ...engagementPermissionQuery })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementPermissionQuery]);

  // Filter engagement rates
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementRatesAction({
            ...engagementRateQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementRateQuery]);

  // Filter engagement reports
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementReportsAction({
            ...engagementReportQuery,
            dateEnd: engagementReportQuery.dateEnd
              ? getDjangoDate(engagementReportQuery.dateEnd)
              : "",
            dateStart: engagementReportQuery.dateStart
              ? getDjangoDate(engagementReportQuery.dateStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementReportQuery]);

  // Filter engagement risks
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listEngagementRisksAction({ ...engagementRiskQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementRiskQuery]);

  // Filter engagement services
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listEngagementServicesAction({ ...engagementServiceQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementServiceQuery]);

  // Filter engagement service deliverables
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementServiceDeliverablesAction({
            ...engagementServiceDeliverableQuery,
            dueEnd: engagementServiceDeliverableQuery.dueEnd
              ? getDjangoDate(engagementServiceDeliverableQuery.dueEnd)
              : "",
            dueStart: engagementServiceDeliverableQuery.dueStart
              ? getDjangoDate(engagementServiceDeliverableQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementServiceDeliverableQuery]);

  // Filter engagement uploads
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementUploadsAction({
            ...engagementUploadQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementUploadQuery]);

  // Filter expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpensesAction({
            ...expenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseQuery]);

  // Filter expense categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpenseCategoriesAction({
            ...expenseCategoryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseCategoryQuery]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listLocationsAction({ ...locationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter manager list
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    if (approvalRequest.decisionRight === "Engagement baseline") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_engagement_baseline &&
            manager.approval_role_data
              .can_approve_engagement_baseline_threshold >=
              engagementDetails.engagement.value
          : false
      );
    } else if (approvalRequest.decisionRight === "Engagement close") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_engagement_close &&
            manager.approval_role_data.can_approve_engagement_close_threshold >=
              engagementDetails.engagement.value
          : false
      );
    } else {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_engagement_deliverable &&
            manager.approval_role_data
              .can_approve_engagement_deliverable_threshold >=
              engagementDetails.engagement.value
          : false
      );
    }

    setFilteredManagerList(managers);
  }, [
    addApprovalRequestDrawerIsOpen,
    approvalRequest.decisionRight,
    managerList.managers,
    engagementDetails.engagement.value,
    updateApprovalRequestDrawerIsOpen,
  ]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRolesAction({
            ...roleQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Filter services
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listServicesAction({
            ...serviceQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [serviceQuery]);

  // Filter staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffAction({
            ...staffQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffQuery]);

  // Form submission
  // Approval request
  // Add approval request
  const addApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approval_request_data.append("item_id", id);

    approval_request_data.append("organisation", activeOrganisation.id);

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(createApprovalRequestAction(approval_request_data));
  };

  // Delete approval request
  const deleteApprovalRequest = () => {
    // Dispatch action
    dispatch(
      deleteApprovalRequestAction(approvalRequestDetails.approval_request.id)
    );
  };

  // Update approval request
  const updateApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(
      updateApprovalRequestAction(
        approvalRequestDetails.approval_request.id,
        approval_request_data
      )
    );
  };

  // Engagement
  // Delete engagement
  const deleteEngagement = () => {
    // Dispatch action
    engagementDeleteKey === engagementDetails.engagement.name
      ? dispatch(deleteEngagementAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the engagement's name",
        });
  };

  // Update engagement approach
  const updateApproach = () => {
    // Prepare data
    const engagement_data = new FormData();

    if (
      engagement.approachDiagram &&
      engagement.approachDiagram !==
        engagementDetails.engagement.approach_diagram
    ) {
      engagement_data.append("approach_diagram", engagement.approachDiagram);
    } else if (engagement.approachDiagram === "") {
      engagement_data.append(
        "approach_diagram",
        engagementDefault.approachDiagram
      );
    }

    engagement.approachSummary
      ? engagement_data.append("approach_summary", engagement.approachSummary)
      : engagement_data.append(
          "approach_summary",
          engagementDefault.approachSummary
        );

    // Dispatch action
    dispatch(updateEngagementAction(id, "", "", engagement_data));
  };

  // Update engagement fee notes
  const updateFeeNotes = () => {
    // Prepare data
    const engagement_data = new FormData();

    engagement.currency
      ? engagement_data.append("currency", engagement.currency)
      : engagement_data.append("currency", engagementDefault.currency);

    engagement.currencyExchangeRate
      ? engagement_data.append(
          "currency_exchange_rate",
          engagement.currencyExchangeRate
        )
      : engagement_data.append(
          "currency_exchange_rate",
          engagementDefault.currencyExchangeRate
        );

    engagement.tax
      ? engagement_data.append("tax", engagement.tax)
      : engagement_data.append("tax", engagementDefault.tax);

    engagement.feeNotes
      ? engagement_data.append("fee_notes", engagement.feeNotes)
      : engagement_data.append("fee_notes", engagementDefault.feeNotes);

    // Dispatch action
    dispatch(updateEngagementAction(id, "", "", engagement_data));
  };

  // Update engagement needs analysis
  const updateNeedsAnalysis = () => {
    // Prepare data
    const engagement_data = new FormData();

    engagement.changeDrivers
      ? engagement_data.append("change_drivers", engagement.changeDrivers)
      : engagement_data.append(
          "change_drivers",
          engagementDefault.changeDrivers
        );

    engagement.issues
      ? engagement_data.append("issues", engagement.issues)
      : engagement_data.append("issues", engagementDefault.issues);

    engagement.ourSolution
      ? engagement_data.append("our_solution", engagement.ourSolution)
      : engagement_data.append("our_solution", engagementDefault.ourSolution);

    engagement.strategicOptions
      ? engagement_data.append("strategic_options", engagement.strategicOptions)
      : engagement_data.append(
          "strategic_options",
          engagementDefault.strategicOptions
        );

    engagement.theoryOfChange
      ? engagement_data.append("theory_of_change", engagement.theoryOfChange)
      : engagement_data.append(
          "theory_of_change",
          engagementDefault.theoryOfChange
        );

    // Dispatch action
    dispatch(updateEngagementAction(id, "", "", engagement_data));
  };

  // Update engagement summary
  const updateSummary = () => {
    // Prepare data
    const engagement_data = new FormData();

    const client = new FormData();
    if (engagement.client) {
      engagement_data.append("client", engagement.client);
    } else {
      engagement_data.append("client", engagementDefault.client);

      if (engagement.clientName) {
        client.append("name", engagement.clientName);

        client.append("organisation", activeOrganisation.id);
      }
    }

    engagement.manager
      ? engagement_data.append("manager", engagement.manager)
      : engagement_data.append("manager", engagementDefault.manager);

    engagement.name
      ? engagement_data.append("name", engagement.name)
      : engagement_data.append("name", engagementDefault.name);

    engagement.notes
      ? engagement_data.append("notes", engagement.notes)
      : engagement_data.append("notes", engagementDefault.notes);

    engagement.restricted != null
      ? engagement_data.append("restricted", engagement.restricted)
      : engagement_data.append("restricted", engagementDefault.restricted);

    const sponsor = new FormData();
    if (engagement.sponsor) {
      engagement_data.append("sponsor", engagement.sponsor);
    } else {
      engagement_data.append("sponsor", engagementDefault.sponsor);

      if (engagement.sponsorName) {
        sponsor.append("name", engagement.sponsorName);

        sponsor.append("organisation", activeOrganisation.id);
      }
    }

    if (engagement.sow && engagement.sow !== engagementDetails.engagement.sow) {
      engagement_data.append("sow", engagement.sow);
    } else if (engagement.sow === "") {
      engagement_data.append("sow", engagementDefault.sow);
    }

    engagement.status
      ? engagement_data.append("status", engagement.status)
      : engagement_data.append("status", engagementDefault.status);

    engagement.value
      ? engagement_data.append("value", engagement.value)
      : engagement_data.append("value", engagementDefault.value);

    // Dispatch action
    dispatch(updateEngagementAction(id, client, sponsor, engagement_data));
  };

  // Engagement action
  const [waitingToUpdateKanban, setWaitingToUpdateKanban] = useState(false);

  // Add action
  const addAction = () => {
    // Prepare data
    const engagement_action = new FormData();

    engagementAction.action
      ? engagement_action.append("action", engagementAction.action)
      : engagement_action.append("action", engagementActionDefault.action);

    engagementAction.category
      ? engagement_action.append("category", engagementAction.category)
      : engagement_action.append("category", engagementActionDefault.category);

    engagementAction.due
      ? engagement_action.append("due", getDjangoDate(engagementAction.due))
      : engagement_action.append(
          "due",
          getDjangoDate(engagementActionDefault.due)
        );

    engagement_action.append("engagement", id);

    engagementAction.name
      ? engagement_action.append("name", engagementAction.name)
      : engagement_action.append("name", engagementActionDefault.name);

    engagementAction.owner
      ? engagement_action.append("owner", engagementAction.owner)
      : engagement_action.append("owner", engagementActionDefault.owner);

    // Dispatch action
    dispatch(createEngagementActionAction(engagement_action));
  };

  // Delete action
  const deleteAction = () => {
    // Dispatch action
    dispatch(
      deleteEngagementActionAction(engagementActionDetails.engagement_action.id)
    );
  };

  // Update action
  const updateAction = () => {
    // Prepare data
    const engagement_action = new FormData();

    engagementAction.action
      ? engagement_action.append("action", engagementAction.action)
      : engagement_action.append("action", engagementActionDefault.action);

    engagementAction.category
      ? engagement_action.append("category", engagementAction.category)
      : engagement_action.append("category", engagementActionDefault.category);

    engagementAction.comments
      ? engagement_action.append("comments", engagementAction.comments)
      : engagement_action.append("comments", engagementActionDefault.comments);

    engagementAction.due
      ? engagement_action.append("due", getDjangoDate(engagementAction.due))
      : engagement_action.append(
          "due",
          getDjangoDate(engagementActionDefault.due)
        );

    engagementAction.name
      ? engagement_action.append("name", engagementAction.name)
      : engagement_action.append("name", engagementActionDefault.name);

    engagementAction.owner
      ? engagement_action.append("owner", engagementAction.owner)
      : engagement_action.append("owner", engagementActionDefault.owner);

    engagementAction.status
      ? engagement_action.append("status", engagementAction.status)
      : engagement_action.append("status", engagementActionDefault.status);

    // Dispatch action
    dispatch(
      updateEngagementActionAction(
        engagementActionDetails.engagement_action.id,
        engagement_action
      )
    );
  };

  // Update action status
  const updateActionStatus = (actionId, actionStatus) => {
    // Prepare data
    const engagement_action = new FormData();

    engagement_action.append("status", actionStatus);

    // Dispatch action
    dispatch(updateEngagementActionAction(actionId, engagement_action));
  };

  useEffect(() => {
    if (
      engagementActionCreate.success ||
      engagementActionDelete.success ||
      engagementActionUpdate.success
    ) {
      setWaitingToUpdateKanban(true);

      dispatch(listEngagementDetailsAction(id));

      setEngagementActionQuery({
        ...engagementActionQueryDefault,
      });
    }
    // eslint-disable-next-line
  }, [
    engagementActionCreate.success,
    engagementActionDelete.success,
    engagementActionUpdate.success,
  ]);

  useEffect(() => {
    if (engagementDetails.success && waitingToUpdateKanban) {
      setWaitingToUpdateKanban(false);

      setInitialiseActionKanban(true);
    }
    // eslint-disable-next-line
  }, [engagementDetails.success]);

  // Engagement contact
  // Add engagement contact
  const addEngagementContact = () => {
    // Prepare data
    const engagement_contact = new FormData();

    engagementContact.comments
      ? engagement_contact.append("comments", engagementContact.comments)
      : engagement_contact.append(
          "comments",
          engagementContactDefault.comments
        );

    const contact = new FormData();
    if (engagementContact.contact) {
      engagement_contact.append("contact", engagementContact.contact);
    } else {
      engagement_contact.append("contact", engagementContactDefault.contact);

      if (engagementContact.contactName) {
        contact.append("name", engagementContact.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    engagement_contact.append("engagement", id);

    engagementContact.expectations
      ? engagement_contact.append(
          "expectations",
          engagementContact.expectations
        )
      : engagement_contact.append(
          "expectations",
          engagementContactDefault.expectations
        );

    engagementContact.levelOfInfluence
      ? engagement_contact.append(
          "level_of_influence",
          engagementContact.levelOfInfluence
        )
      : engagement_contact.append(
          "level_of_influence",
          engagementContactDefault.levelOfInfluence
        );

    engagementContact.levelOfSupport
      ? engagement_contact.append(
          "level_of_support",
          engagementContact.levelOfSupport
        )
      : engagement_contact.append(
          "level_of_support",
          engagementContactDefault.levelOfSupport
        );

    engagementContact.relationshipOwner
      ? engagement_contact.append(
          "relationship_owner",
          engagementContact.relationshipOwner
        )
      : engagement_contact.append(
          "relationship_owner",
          engagementContactDefault.relationshipOwner
        );

    // Dispatch action
    dispatch(createEngagementContactAction(contact, engagement_contact));
  };

  // Delete engagement contact
  const deleteEngagementContact = () => {
    // Dispatch action
    dispatch(
      deleteEngagementContactAction(
        engagementContactDetails.engagement_contact.id
      )
    );
  };

  // Update engagement contact
  const updateEngagementContact = () => {
    // Prepare data
    const engagement_contact = new FormData();

    engagementContact.comments
      ? engagement_contact.append("comments", engagementContact.comments)
      : engagement_contact.append(
          "comments",
          engagementContactDefault.comments
        );

    const contact = new FormData();
    if (engagementContact.contact) {
      engagement_contact.append("contact", engagementContact.contact);
    } else {
      engagement_contact.append("contact", engagementContactDefault.contact);

      if (engagementContact.contactName) {
        contact.append("name", engagementContact.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    engagementContact.expectations
      ? engagement_contact.append(
          "expectations",
          engagementContact.expectations
        )
      : engagement_contact.append(
          "expectations",
          engagementContactDefault.expectations
        );

    engagementContact.levelOfInfluence
      ? engagement_contact.append(
          "level_of_influence",
          engagementContact.levelOfInfluence
        )
      : engagement_contact.append(
          "level_of_influence",
          engagementContactDefault.levelOfInfluence
        );

    engagementContact.levelOfSupport
      ? engagement_contact.append(
          "level_of_support",
          engagementContact.levelOfSupport
        )
      : engagement_contact.append(
          "level_of_support",
          engagementContactDefault.levelOfSupport
        );

    engagementContact.relationshipOwner
      ? engagement_contact.append(
          "relationship_owner",
          engagementContact.relationshipOwner
        )
      : engagement_contact.append(
          "relationship_owner",
          engagementContactDefault.relationshipOwner
        );

    // Dispatch action
    dispatch(
      updateEngagementContactAction(
        engagementContactDetails.engagement_contact.id,
        contact,
        engagement_contact
      )
    );
  };

  // Engagement delivery issue
  // Add issue
  const addIssue = () => {
    // Prepare data
    const engagement_issue = new FormData();

    engagementDeliveryIssue.category
      ? engagement_issue.append("category", engagementDeliveryIssue.category)
      : engagement_issue.append(
          "category",
          engagementDeliveryIssueDefault.category
        );

    engagement_issue.append("engagement", id);

    engagementDeliveryIssue.issue
      ? engagement_issue.append("issue", engagementDeliveryIssue.issue)
      : engagement_issue.append("issue", engagementDeliveryIssueDefault.issue);

    engagementDeliveryIssue.name
      ? engagement_issue.append("name", engagementDeliveryIssue.name)
      : engagement_issue.append("name", engagementDeliveryIssueDefault.name);

    engagementDeliveryIssue.owner
      ? engagement_issue.append("owner", engagementDeliveryIssue.owner)
      : engagement_issue.append("owner", engagementDeliveryIssueDefault.owner);

    engagementDeliveryIssue.strategy
      ? engagement_issue.append("strategy", engagementDeliveryIssue.strategy)
      : engagement_issue.append(
          "strategy",
          engagementDeliveryIssueDefault.strategy
        );

    // Dispatch action
    dispatch(createEngagementDeliveryIssueAction(engagement_issue));
  };

  // Delete issue
  const deleteIssue = () => {
    // Dispatch action
    dispatch(
      deleteEngagementDeliveryIssueAction(
        engagementDeliveryIssueDetails.engagement_issue.id
      )
    );
  };

  // Update issue
  const updateIssue = () => {
    // Prepare data
    const engagement_issue = new FormData();

    engagementDeliveryIssue.category
      ? engagement_issue.append("category", engagementDeliveryIssue.category)
      : engagement_issue.append(
          "category",
          engagementDeliveryIssueDefault.category
        );

    engagementDeliveryIssue.issue
      ? engagement_issue.append("issue", engagementDeliveryIssue.issue)
      : engagement_issue.append("issue", engagementDeliveryIssueDefault.issue);

    engagementDeliveryIssue.name
      ? engagement_issue.append("name", engagementDeliveryIssue.name)
      : engagement_issue.append("name", engagementDeliveryIssueDefault.name);

    engagementDeliveryIssue.owner
      ? engagement_issue.append("owner", engagementDeliveryIssue.owner)
      : engagement_issue.append("owner", engagementDeliveryIssueDefault.owner);

    engagementDeliveryIssue.status
      ? engagement_issue.append("status", engagementDeliveryIssue.status)
      : engagement_issue.append(
          "status",
          engagementDeliveryIssueDefault.status
        );

    engagementDeliveryIssue.strategy
      ? engagement_issue.append("strategy", engagementDeliveryIssue.strategy)
      : engagement_issue.append(
          "strategy",
          engagementDeliveryIssueDefault.strategy
        );

    // Dispatch action
    dispatch(
      updateEngagementDeliveryIssueAction(
        engagementDeliveryIssueDetails.engagement_issue.id,
        engagement_issue
      )
    );
  };

  // Engagement dependency
  // Add dependency
  const addDependency = () => {
    // Prepare data
    const engagement_dependency = new FormData();

    engagementDependency.dependency
      ? engagement_dependency.append(
          "dependency",
          engagementDependency.dependency
        )
      : engagement_dependency.append(
          "dependency",
          engagementDependencyDefault.dependency
        );

    engagement_dependency.append("engagement", id);

    engagementDependency.name
      ? engagement_dependency.append("name", engagementDependency.name)
      : engagement_dependency.append("name", engagementDependencyDefault.name);

    engagementDependency.notes
      ? engagement_dependency.append("notes", engagementDependency.notes)
      : engagement_dependency.append(
          "notes",
          engagementDependencyDefault.notes
        );

    engagementDependency.owner
      ? engagement_dependency.append("owner", engagementDependency.owner)
      : engagement_dependency.append(
          "owner",
          engagementDependencyDefault.owner
        );

    // Dispatch action
    dispatch(createEngagementDependencyAction(engagement_dependency));
  };

  // Delete dependency
  const deleteDependency = () => {
    // Dispatch action
    dispatch(
      deleteEngagementDependencyAction(
        engagementDependencyDetails.engagement_dependency.id
      )
    );
  };

  // Update dependency
  const updateDependency = () => {
    // Prepare data
    const engagement_dependency = new FormData();

    engagementDependency.dependency
      ? engagement_dependency.append(
          "dependency",
          engagementDependency.dependency
        )
      : engagement_dependency.append(
          "dependency",
          engagementDependencyDefault.dependency
        );

    engagementDependency.name
      ? engagement_dependency.append("name", engagementDependency.name)
      : engagement_dependency.append("name", engagementDependencyDefault.name);

    engagementDependency.notes
      ? engagement_dependency.append("notes", engagementDependency.notes)
      : engagement_dependency.append(
          "notes",
          engagementDependencyDefault.notes
        );

    engagementDependency.owner
      ? engagement_dependency.append("owner", engagementDependency.owner)
      : engagement_dependency.append(
          "owner",
          engagementDependencyDefault.owner
        );

    engagementDependency.status
      ? engagement_dependency.append("status", engagementDependency.status)
      : engagement_dependency.append(
          "status",
          engagementDependencyDefault.status
        );

    // Dispatch action
    dispatch(
      updateEngagementDependencyAction(
        engagementDependencyDetails.engagement_dependency.id,
        engagement_dependency
      )
    );
  };

  // Engagement document
  // Update engagement document
  const scheduleRef = useRef(null);

  useEffect(() => {
    if (
      engagementDocument.scheduleDiagram &&
      engagementDocument.scheduleDiagram !==
        engagementDocumentDetails.engagement_document.schedule_diagram
    ) {
      updateDocument();

      AppToaster.show({
        icon: "updated",
        intent: "primary",
        message:
          "Updating the engagement document. This will take a few seconds.",
      });
    }
    // eslint-disable-next-line
  }, [engagementDocument.scheduleDiagram]);

  const updateDocument = () => {
    // Prepare data
    const engagement_document = new FormData();

    if (
      engagementDocument.approachDiagram &&
      engagementDocument.approachDiagram !==
        engagementDocumentDetails.engagement_document.approach_diagram
    ) {
      engagement_document.append(
        "approach_diagram",
        engagementDocument.approachDiagram
      );
    } else if (engagementDocument.approachDiagram === "") {
      engagement_document.append(
        "approach_diagram",
        engagementDocument.approachDiagram
      );
    }

    engagementDocument.approachHeading
      ? engagement_document.append(
          "approach_heading",
          engagementDocument.approachHeading
        )
      : engagement_document.append(
          "approach_heading",
          engagementDocumentDefault.approachHeading
        );

    engagementDocument.approachSummary
      ? engagement_document.append(
          "approach_summary",
          engagementDocument.approachSummary
        )
      : engagement_document.append(
          "approach_summary",
          engagementDocumentDefault.approachSummary
        );

    if (
      engagementDocument.backgroundImage &&
      engagementDocument.backgroundImage !==
        engagementDocumentDetails.engagement_document.background_image
    ) {
      engagement_document.append(
        "background_image",
        engagementDocument.backgroundImage
      );
    } else if (engagementDocument.backgroundImage === "") {
      engagement_document.append(
        "background_image",
        engagementDocument.backgroundImage
      );
    }

    engagementDocument.backPageText
      ? engagement_document.append(
          "back_page_text",
          engagementDocument.backPageText
        )
      : engagement_document.append(
          "back_page_text",
          engagementDocumentDefault.backPageText
        );

    engagementDocument.changeDrivers
      ? engagement_document.append(
          "change_drivers",
          engagementDocument.changeDrivers
        )
      : engagement_document.append(
          "change_drivers",
          engagementDocumentDefault.changeDrivers
        );

    engagementDocument.changeDriversHeading
      ? engagement_document.append(
          "change_drivers_heading",
          engagementDocument.changeDriversHeading
        )
      : engagement_document.append(
          "change_drivers_heading",
          engagementDocumentDefault.changeDriversHeading
        );

    engagementDocument.client
      ? engagement_document.append("client", engagementDocument.client)
      : engagement_document.append("client", engagementDocumentDefault.client);

    engagementDocument.currency
      ? engagement_document.append("currency", engagementDocument.currency)
      : engagement_document.append(
          "currency",
          engagementDocumentDefault.currency
        );

    engagementDocument.date
      ? engagement_document.append(
          "date",
          getDjangoDate(engagementDocument.date)
        )
      : engagement_document.append(
          "date",
          getDjangoDate(engagementDocumentDefault.date)
        );

    engagementDocument.dependenciesHeading
      ? engagement_document.append(
          "dependencies_heading",
          engagementDocument.dependenciesHeading
        )
      : engagement_document.append(
          "dependencies_heading",
          engagementDocumentDefault.dependenciesHeading
        );

    engagementDocument.feesHeading
      ? engagement_document.append(
          "fees_heading",
          engagementDocument.feesHeading
        )
      : engagement_document.append(
          "fees_heading",
          engagementDocumentDefault.feesHeading
        );

    engagementDocument.includeApproach !== null
      ? engagement_document.append(
          "include_approach",
          engagementDocument.includeApproach
        )
      : engagement_document.append(
          "include_approach",
          engagementDocumentDefault.includeApproach
        );

    engagementDocument.includeDependencies !== null
      ? engagement_document.append(
          "include_dependencies",
          engagementDocument.includeDependencies
        )
      : engagement_document.append(
          "include_dependencies",
          engagementDocumentDefault.includeDependencies
        );

    engagementDocument.includeFeeBreakdown !== null
      ? engagement_document.append(
          "include_fee_breakdown",
          engagementDocument.includeFeeBreakdown
        )
      : engagement_document.append(
          "include_fee_breakdown",
          engagementDocumentDefault.includeFeeBreakdown
        );

    engagementDocument.includeFees !== null
      ? engagement_document.append(
          "include_fees",
          engagementDocument.includeFees
        )
      : engagement_document.append(
          "include_fees",
          engagementDocumentDefault.includeFees
        );

    engagementDocument.includeRisks !== null
      ? engagement_document.append(
          "include_risks",
          engagementDocument.includeRisks
        )
      : engagement_document.append(
          "include_risks",
          engagementDocumentDefault.includeRisks
        );

    engagementDocument.includeNeeds !== null
      ? engagement_document.append(
          "include_needs",
          engagementDocument.includeNeeds
        )
      : engagement_document.append(
          "include_needs",
          engagementDocumentDefault.includeNeeds
        );

    engagementDocument.includeServices !== null
      ? engagement_document.append(
          "include_services",
          engagementDocument.includeServices
        )
      : engagement_document.append(
          "include_services",
          engagementDocumentDefault.includeServices
        );

    engagementDocument.introDependencies
      ? engagement_document.append(
          "intro_dependencies",
          engagementDocument.introDependencies
        )
      : engagement_document.append(
          "intro_dependencies",
          engagementDocumentDefault.introDependencies
        );

    engagementDocument.introFees
      ? engagement_document.append("intro_fees", engagementDocument.introFees)
      : engagement_document.append(
          "intro_fees",
          engagementDocumentDefault.introFees
        );

    engagementDocument.introRisks
      ? engagement_document.append("intro_risks", engagementDocument.introRisks)
      : engagement_document.append(
          "intro_risks",
          engagementDocumentDefault.introRisks
        );

    engagementDocument.introServices
      ? engagement_document.append(
          "intro_services",
          engagementDocument.introServices
        )
      : engagement_document.append(
          "intro_services",
          engagementDocumentDefault.introServices
        );

    engagementDocument.issues
      ? engagement_document.append("issues", engagementDocument.issues)
      : engagement_document.append("issues", engagementDocumentDefault.issues);

    engagementDocument.issuesHeading
      ? engagement_document.append(
          "issues_heading",
          engagementDocument.issuesHeading
        )
      : engagement_document.append(
          "issues_heading",
          engagementDocumentDefault.issuesHeading
        );

    engagementDocument.manager
      ? engagement_document.append("manager", engagementDocument.manager)
      : engagement_document.append(
          "manager",
          engagementDocumentDefault.manager
        );

    engagementDocument.needsHeading
      ? engagement_document.append(
          "needs_heading",
          engagementDocument.needsHeading
        )
      : engagement_document.append(
          "needs_heading",
          engagementDocumentDefault.needsHeading
        );

    engagementDocument.ourSolution
      ? engagement_document.append(
          "our_solution",
          engagementDocument.ourSolution
        )
      : engagement_document.append(
          "our_solution",
          engagementDocumentDefault.ourSolution
        );

    engagementDocument.ourSolutionHeading
      ? engagement_document.append(
          "our_solution_heading",
          engagementDocument.ourSolutionHeading
        )
      : engagement_document.append(
          "our_solution_heading",
          engagementDocumentDefault.ourSolutionHeading
        );

    if (
      engagementDocument.scheduleDiagram &&
      engagementDocument.scheduleDiagram !==
        engagementDocumentDetails.engagement_document.schedule_diagram
    ) {
      engagement_document.append(
        "schedule_diagram",
        engagementDocument.scheduleDiagram
      );
    } else if (engagementDocument.scheduleDiagram === "") {
      engagement_document.append(
        "schedule_diagram",
        engagementDocument.scheduleDiagram
      );
    }

    engagementDocument.risksHeading
      ? engagement_document.append(
          "risks_heading",
          engagementDocument.risksHeading
        )
      : engagement_document.append(
          "risks_heading",
          engagementDocumentDefault.risksHeading
        );

    engagementDocument.servicesHeading
      ? engagement_document.append(
          "services_heading",
          engagementDocument.servicesHeading
        )
      : engagement_document.append(
          "services_heading",
          engagementDocumentDefault.servicesHeading
        );

    engagementDocument.strategicOptions
      ? engagement_document.append(
          "strategic_options",
          engagementDocument.strategicOptions
        )
      : engagement_document.append(
          "strategic_options",
          engagementDocumentDefault.strategicOptions
        );

    engagementDocument.strategicOptionsHeading
      ? engagement_document.append(
          "strategic_options_heading",
          engagementDocument.strategicOptionsHeading
        )
      : engagement_document.append(
          "strategic_options_heading",
          engagementDocumentDefault.strategicOptionsHeading
        );

    engagementDocument.theoryOfChange
      ? engagement_document.append(
          "theory_of_change",
          engagementDocument.theoryOfChange
        )
      : engagement_document.append(
          "theory_of_change",
          engagementDocumentDefault.theoryOfChange
        );

    engagementDocument.theoryOfChangeHeading
      ? engagement_document.append(
          "theory_of_change_heading",
          engagementDocument.theoryOfChangeHeading
        )
      : engagement_document.append(
          "theory_of_change_heading",
          engagementDocumentDefault.theoryOfChangeHeading
        );

    engagementDocument.title
      ? engagement_document.append("title", engagementDocument.title)
      : engagement_document.append("title", engagementDocumentDefault.title);

    // Dispatch action
    dispatch(
      updateEngagementDocumentAction(
        engagementDetails.engagement.engagement_document,
        engagement_document
      )
    );
  };

  // Update engagement document style
  const updateDocumentStyle = () => {
    // Prepare data
    const engagement_document = new FormData();

    engagementDocument.font
      ? engagement_document.append("font", engagementDocument.font)
      : engagement_document.append("font", engagementDocumentDefault.font);

    if (
      engagementDocument.logo &&
      engagementDocument.logo !==
        engagementDocumentDetails.engagement_document.logo
    ) {
      engagement_document.append("logo", engagementDocument.logo);
    } else if (engagementDocument.logo === "") {
      engagement_document.append("logo", engagementDocument.logo);
    }

    engagementDocument.primaryColour
      ? engagement_document.append(
          "primary_colour",
          engagementDocument.primaryColour
        )
      : engagement_document.append(
          "primary_colour",
          engagementDocumentDefault.primaryColour
        );

    engagementDocument.secondaryColour
      ? engagement_document.append(
          "secondary_colour",
          engagementDocument.secondaryColour
        )
      : engagement_document.append(
          "secondary_colour",
          engagementDocumentDefault.secondaryColour
        );

    engagementDocument.template
      ? engagement_document.append("template", engagementDocument.template)
      : engagement_document.append(
          "template",
          engagementDocumentDefault.template
        );

    // Dispatch action
    dispatch(
      updateEngagementDocumentAction(
        engagementDetails.engagement.engagement_document,
        engagement_document
      )
    );
  };

  // Engagement fee
  // Update engagement fee
  const updateEngagementFee = () => {
    // Prepare data
    const engagement_fee = new FormData();

    engagementFee.adjustment
      ? engagement_fee.append("adjustment", engagementFee.adjustment)
      : engagement_fee.append("adjustment", engagementFeeDefault.adjustment);

    // Dispatch action
    dispatch(
      updateEngagementFeeAction(
        engagementFeeDetails.engagement_fee.id,
        engagement_fee
      )
    );
  };

  // Engagement labour expense
  // Add labour expense
  const addLabourExpense = () => {
    // Prepare data
    const engagement_labour_expense = new FormData();

    engagementLabourExpense.competencies
      ? engagement_labour_expense.append(
          "competencies",
          JSON.stringify(engagementLabourExpense.competencies)
        )
      : engagement_labour_expense.append(
          "competencies",
          JSON.stringify(engagementLabourExpenseDefault.competencies)
        );

    engagementLabourExpense.customiseDays && engagementLabourExpense.detail
      ? engagement_labour_expense.append(
          "detail",
          JSON.stringify(engagementLabourExpense.detail)
        )
      : engagement_labour_expense.append(
          "detail",
          JSON.stringify(engagementLabourExpenseDefault.detail)
        );

    engagementLabourExpense.employment
      ? engagement_labour_expense.append(
          "employment",
          engagementLabourExpense.employment
        )
      : engagement_labour_expense.append(
          "employment",
          engagementLabourExpenseDefault.employment
        );

    engagementLabourExpense.engagementService
      ? engagement_labour_expense.append(
          "engagement_service",
          engagementLabourExpense.engagementService
        )
      : engagement_labour_expense.append(
          "engagement_service",
          engagementLabourExpenseDefault.engagementService
        );

    engagementLabourExpense.finish
      ? engagement_labour_expense.append(
          "finish",
          getDjangoDate(engagementLabourExpense.finish)
        )
      : engagement_labour_expense.append(
          "finish",
          getDjangoDate(engagementLabourExpenseDefault.finish)
        );

    engagementLabourExpense.location
      ? engagement_labour_expense.append(
          "location",
          engagementLabourExpense.location
        )
      : engagement_labour_expense.append(
          "location",
          engagementLabourExpenseDefault.location
        );

    engagementLabourExpense.marginPercentage !== null
      ? engagement_labour_expense.append(
          "margin_percentage",
          engagementLabourExpense.marginPercentage
        )
      : engagement_labour_expense.append(
          "margin_percentage",
          engagementLabourExpenseDefault.marginPercentage
        );

    engagementLabourExpense.customiseDays && engagementLabourExpense.method
      ? engagement_labour_expense.append(
          "method",
          engagementLabourExpense.method
        )
      : engagement_labour_expense.append(
          "method",
          engagementLabourExpenseDefault.method
        );

    engagementLabourExpense.minutes
      ? engagement_labour_expense.append(
          "minutes",
          engagementLabourExpense.minutes
        )
      : engagement_labour_expense.append(
          "minutes",
          engagementLabourExpenseDefault.minutes
        );

    engagementLabourExpense.notes
      ? engagement_labour_expense.append("notes", engagementLabourExpense.notes)
      : engagement_labour_expense.append(
          "notes",
          engagementLabourExpenseDefault.notes
        );

    engagementLabourExpense.role
      ? engagement_labour_expense.append("role", engagementLabourExpense.role)
      : engagement_labour_expense.append(
          "role",
          engagementLabourExpenseDefault.role
        );

    engagementLabourExpense.staff
      ? engagement_labour_expense.append("staff", engagementLabourExpense.staff)
      : engagement_labour_expense.append(
          "staff",
          engagementLabourExpenseDefault.staff
        );

    engagementLabourExpense.start
      ? engagement_labour_expense.append(
          "start",
          getDjangoDate(engagementLabourExpense.start)
        )
      : engagement_labour_expense.append(
          "start",
          getDjangoDate(engagementLabourExpenseDefault.start)
        );

    engagementLabourExpense.customiseDays && engagementLabourExpense.weekdays
      ? engagement_labour_expense.append(
          "weekdays",
          JSON.stringify(engagementLabourExpense.weekdays)
        )
      : engagement_labour_expense.append(
          "weekdays",
          JSON.stringify(engagementLabourExpenseDefault.weekdays)
        );

    // Dispatch action
    dispatch(createEngagementLabourExpenseAction(engagement_labour_expense));
  };

  // Delete labour expense
  const deleteLabourExpense = () => {
    // Dispatch action
    dispatch(
      deleteEngagementLabourExpenseAction(
        engagementLabourExpenseDetails.engagement_labour_expense.id
      )
    );
  };

  // Update labour expense
  const updateLabourExpense = () => {
    // Prepare data
    const engagement_labour_expense = new FormData();

    engagementLabourExpense.competencies
      ? engagement_labour_expense.append(
          "competencies",
          JSON.stringify(engagementLabourExpense.competencies)
        )
      : engagement_labour_expense.append(
          "competencies",
          JSON.stringify(engagementLabourExpenseDefault.competencies)
        );

    engagementLabourExpense.customiseDays && engagementLabourExpense.detail
      ? engagement_labour_expense.append(
          "detail",
          JSON.stringify(engagementLabourExpense.detail)
        )
      : engagement_labour_expense.append(
          "detail",
          JSON.stringify(engagementLabourExpenseDefault.detail)
        );

    engagementLabourExpense.employment
      ? engagement_labour_expense.append(
          "employment",
          engagementLabourExpense.employment
        )
      : engagement_labour_expense.append(
          "employment",
          engagementLabourExpenseDefault.employment
        );

    engagementLabourExpense.engagementService
      ? engagement_labour_expense.append(
          "engagement_service",
          engagementLabourExpense.engagementService
        )
      : engagement_labour_expense.append(
          "engagement_service",
          engagementLabourExpenseDefault.engagementService
        );

    engagementLabourExpense.finish
      ? engagement_labour_expense.append(
          "finish",
          getDjangoDate(engagementLabourExpense.finish)
        )
      : engagement_labour_expense.append(
          "finish",
          getDjangoDate(engagementLabourExpenseDefault.finish)
        );

    engagementLabourExpense.location
      ? engagement_labour_expense.append(
          "location",
          engagementLabourExpense.location
        )
      : engagement_labour_expense.append(
          "location",
          engagementLabourExpenseDefault.location
        );

    engagementLabourExpense.marginPercentage !== null
      ? engagement_labour_expense.append(
          "margin_percentage",
          engagementLabourExpense.marginPercentage
        )
      : engagement_labour_expense.append(
          "margin_percentage",
          engagementLabourExpenseDefault.marginPercentage
        );

    engagementLabourExpense.customiseDays && engagementLabourExpense.method
      ? engagement_labour_expense.append(
          "method",
          engagementLabourExpense.method
        )
      : engagement_labour_expense.append(
          "method",
          engagementLabourExpenseDefault.method
        );

    engagementLabourExpense.minutes
      ? engagement_labour_expense.append(
          "minutes",
          engagementLabourExpense.minutes
        )
      : engagement_labour_expense.append(
          "minutes",
          engagementLabourExpenseDefault.minutes
        );

    engagementLabourExpense.notes
      ? engagement_labour_expense.append("notes", engagementLabourExpense.notes)
      : engagement_labour_expense.append(
          "notes",
          engagementLabourExpenseDefault.notes
        );

    engagementLabourExpense.role
      ? engagement_labour_expense.append("role", engagementLabourExpense.role)
      : engagement_labour_expense.append(
          "role",
          engagementLabourExpenseDefault.role
        );

    engagementLabourExpense.staff
      ? engagement_labour_expense.append("staff", engagementLabourExpense.staff)
      : engagement_labour_expense.append(
          "staff",
          engagementLabourExpenseDefault.staff
        );

    engagementLabourExpense.start
      ? engagement_labour_expense.append(
          "start",
          getDjangoDate(engagementLabourExpense.start)
        )
      : engagement_labour_expense.append(
          "start",
          getDjangoDate(engagementLabourExpenseDefault.start)
        );

    engagementLabourExpense.customiseDays && engagementLabourExpense.weekdays
      ? engagement_labour_expense.append(
          "weekdays",
          JSON.stringify(engagementLabourExpense.weekdays)
        )
      : engagement_labour_expense.append(
          "weekdays",
          JSON.stringify(engagementLabourExpenseDefault.weekdays)
        );

    // Dispatch action
    dispatch(
      updateEngagementLabourExpenseAction(
        engagementLabourExpenseDetails.engagement_labour_expense.id,
        engagement_labour_expense
      )
    );
  };

  // Engagement lesson
  // Add engagement lesson
  const addEngagementLesson = () => {
    // Prepare data
    const engagement_lesson = new FormData();

    engagementLesson.category
      ? engagement_lesson.append("category", engagementLesson.category)
      : engagement_lesson.append("category", engagementLessonDefault.category);

    engagement_lesson.append("engagement", id);

    engagementLesson.lesson
      ? engagement_lesson.append("lesson", engagementLesson.lesson)
      : engagement_lesson.append("lesson", engagementLessonDefault.lesson);

    engagementLesson.name
      ? engagement_lesson.append("name", engagementLesson.name)
      : engagement_lesson.append("name", engagementLessonDefault.name);

    // Dispatch action
    dispatch(createEngagementLessonAction(engagement_lesson));
  };

  // Delete engagement lesson
  const deleteEngagementLesson = () => {
    // Dispatch action
    dispatch(
      deleteEngagementLessonAction(engagementLessonDetails.engagement_lesson.id)
    );
  };

  // Update engagement lesson
  const updateEngagementLesson = () => {
    // Prepare data
    const engagement_lesson = new FormData();

    engagementLesson.category
      ? engagement_lesson.append("category", engagementLesson.category)
      : engagement_lesson.append("category", engagementLessonDefault.category);

    engagementLesson.lesson
      ? engagement_lesson.append("lesson", engagementLesson.lesson)
      : engagement_lesson.append("lesson", engagementLessonDefault.lesson);

    engagementLesson.name
      ? engagement_lesson.append("name", engagementLesson.name)
      : engagement_lesson.append("name", engagementLessonDefault.name);

    // Dispatch action
    dispatch(
      updateEngagementLessonAction(
        engagementLessonDetails.engagement_lesson.id,
        engagement_lesson
      )
    );
  };

  // Engagement milestone
  // Add milestone
  const addMilestone = () => {
    // Prepare data
    const engagement_milestone = new FormData();

    engagementMilestone.description
      ? engagement_milestone.append(
          "description",
          engagementMilestone.description
        )
      : engagement_milestone.append(
          "description",
          engagementMilestoneDefault.description
        );

    engagementMilestone.due
      ? engagement_milestone.append(
          "due",
          getDjangoDate(engagementMilestone.due)
        )
      : engagement_milestone.append(
          "due",
          getDjangoDate(engagementMilestoneDefault.due)
        );

    engagement_milestone.append("engagement", id);

    engagementMilestone.name
      ? engagement_milestone.append("name", engagementMilestone.name)
      : engagement_milestone.append("name", engagementMilestoneDefault.name);

    engagementMilestone.value
      ? engagement_milestone.append("value", engagementMilestone.value)
      : engagement_milestone.append("value", engagementMilestoneDefault.value);

    // Dispatch action
    dispatch(createEngagementMilestoneAction(engagement_milestone));
  };

  // Delete milestone
  const deleteMilestone = () => {
    // Dispatch action
    dispatch(
      deleteEngagementMilestoneAction(
        engagementMilestoneDetails.engagement_milestone.id
      )
    );
  };

  // Update milestone
  const updateMilestone = () => {
    // Prepare data
    const engagement_milestone = new FormData();

    engagementMilestone.completed
      ? engagement_milestone.append(
          "completed",
          getDjangoDate(engagementMilestone.completed)
        )
      : engagement_milestone.append(
          "completed",
          getDjangoDate(engagementMilestoneDefault.completed)
        );

    engagementMilestone.description
      ? engagement_milestone.append(
          "description",
          engagementMilestone.description
        )
      : engagement_milestone.append(
          "description",
          engagementMilestoneDefault.description
        );

    engagementMilestone.due
      ? engagement_milestone.append(
          "due",
          getDjangoDate(engagementMilestone.due)
        )
      : engagement_milestone.append(
          "due",
          getDjangoDate(engagementMilestoneDefault.due)
        );

    engagementMilestone.invoiced
      ? engagement_milestone.append(
          "invoiced",
          getDjangoDate(engagementMilestone.invoiced)
        )
      : engagement_milestone.append(
          "invoiced",
          getDjangoDate(engagementMilestoneDefault.invoiced)
        );

    engagementMilestone.name
      ? engagement_milestone.append("name", engagementMilestone.name)
      : engagement_milestone.append("name", engagementMilestoneDefault.name);

    engagementMilestone.paid
      ? engagement_milestone.append(
          "paid",
          getDjangoDate(engagementMilestone.paid)
        )
      : engagement_milestone.append(
          "paid",
          getDjangoDate(engagementMilestoneDefault.paid)
        );

    engagementMilestone.status
      ? engagement_milestone.append("status", engagementMilestone.status)
      : engagement_milestone.append(
          "status",
          engagementMilestoneDefault.status
        );

    engagementMilestone.value
      ? engagement_milestone.append("value", engagementMilestone.value)
      : engagement_milestone.append("value", engagementMilestoneDefault.value);

    // Dispatch action
    dispatch(
      updateEngagementMilestoneAction(
        engagementMilestoneDetails.engagement_milestone.id,
        engagement_milestone
      )
    );
  };

  // Engagement non-labour expense
  // Add non-labour expense
  const addNonLabourExpense = () => {
    // Prepare data
    const engagement_non_labour_expense = new FormData();

    engagementNonLabourExpense.category
      ? engagement_non_labour_expense.append(
          "category",
          engagementNonLabourExpense.category
        )
      : engagement_non_labour_expense.append(
          "category",
          engagementNonLabourExpenseDefault.category
        );

    engagementNonLabourExpense.cost
      ? engagement_non_labour_expense.append(
          "cost",
          engagementNonLabourExpense.cost
        )
      : engagement_non_labour_expense.append(
          "cost",
          engagementNonLabourExpenseDefault.cost
        );

    engagementNonLabourExpense.marginPercentage !== null
      ? engagement_non_labour_expense.append(
          "margin_percentage",
          engagementNonLabourExpense.marginPercentage
        )
      : engagement_non_labour_expense.append(
          "margin_percentage",
          engagementNonLabourExpenseDefault.marginPercentage
        );

    engagementNonLabourExpense.name
      ? engagement_non_labour_expense.append(
          "name",
          engagementNonLabourExpense.name
        )
      : engagement_non_labour_expense.append(
          "name",
          engagementNonLabourExpenseDefault.name
        );

    engagementNonLabourExpense.notes
      ? engagement_non_labour_expense.append(
          "notes",
          engagementNonLabourExpense.notes
        )
      : engagement_non_labour_expense.append(
          "notes",
          engagementNonLabourExpenseDefault.notes
        );

    engagementNonLabourExpense.engagementService
      ? engagement_non_labour_expense.append(
          "engagement_service",
          engagementNonLabourExpense.engagementService
        )
      : engagement_non_labour_expense.append(
          "engagement_service",
          engagementNonLabourExpenseDefault.engagementService
        );

    // Dispatch action
    dispatch(
      createEngagementNonLabourExpenseAction(engagement_non_labour_expense)
    );
  };

  // Delete non-labour expense
  const deleteNonLabourExpense = () => {
    // Dispatch action
    dispatch(
      deleteEngagementNonLabourExpenseAction(
        engagementNonLabourExpenseDetails.engagement_non_labour_expense.id
      )
    );
  };

  // Update non-labour expense
  const updateNonLabourExpense = () => {
    // Prepare data
    const engagement_non_labour_expense = new FormData();

    engagementNonLabourExpense.category
      ? engagement_non_labour_expense.append(
          "category",
          engagementNonLabourExpense.category
        )
      : engagement_non_labour_expense.append(
          "category",
          engagementNonLabourExpenseDefault.category
        );

    engagementNonLabourExpense.cost
      ? engagement_non_labour_expense.append(
          "cost",
          engagementNonLabourExpense.cost
        )
      : engagement_non_labour_expense.append(
          "cost",
          engagementNonLabourExpenseDefault.cost
        );

    engagementNonLabourExpense.marginPercentage !== null
      ? engagement_non_labour_expense.append(
          "margin_percentage",
          engagementNonLabourExpense.marginPercentage
        )
      : engagement_non_labour_expense.append(
          "margin_percentage",
          engagementNonLabourExpenseDefault.marginPercentage
        );

    engagementNonLabourExpense.name
      ? engagement_non_labour_expense.append(
          "name",
          engagementNonLabourExpense.name
        )
      : engagement_non_labour_expense.append(
          "name",
          engagementNonLabourExpenseDefault.name
        );

    engagementNonLabourExpense.notes
      ? engagement_non_labour_expense.append(
          "notes",
          engagementNonLabourExpense.notes
        )
      : engagement_non_labour_expense.append(
          "notes",
          engagementNonLabourExpenseDefault.notes
        );

    engagementNonLabourExpense.engagementService
      ? engagement_non_labour_expense.append(
          "engagement_service",
          engagementNonLabourExpense.engagementService
        )
      : engagement_non_labour_expense.append(
          "engagement_service",
          engagementNonLabourExpenseDefault.engagementService
        );

    // Dispatch action
    dispatch(
      updateEngagementNonLabourExpenseAction(
        engagementNonLabourExpenseDetails.engagement_non_labour_expense.id,
        engagement_non_labour_expense
      )
    );
  };

  // Engagement permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const engagement_permission = new FormData();

    engagementPermission.level
      ? engagement_permission.append("level", engagementPermission.level)
      : engagement_permission.append(
          "level",
          engagementPermissionDefault.level
        );

    engagementPermission.manager
      ? engagement_permission.append("manager", engagementPermission.manager)
      : engagement_permission.append(
          "manager",
          engagementPermissionDefault.manager
        );

    engagement_permission.append("engagement", id);

    // Dispatch action
    dispatch(createEngagementPermissionAction(engagement_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteEngagementPermissionAction(
        engagementPermissionDetails.engagement_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const engagement_permission = new FormData();

    engagementPermission.level
      ? engagement_permission.append("level", engagementPermission.level)
      : engagement_permission.append(
          "level",
          engagementPermissionDefault.level
        );

    engagementPermission.manager
      ? engagement_permission.append("manager", engagementPermission.manager)
      : engagement_permission.append(
          "manager",
          engagementPermissionDefault.manager
        );

    // Dispatch action
    dispatch(
      updateEngagementPermissionAction(
        engagementPermissionDetails.engagement_permission.id,
        engagement_permission
      )
    );
  };

  // Engagement rate
  // Add rate
  const addRate = () => {
    // Prepare data
    const engagement_rate = new FormData();

    engagement_rate.append("engagement", id);

    engagementRate.marginPercentage !== null
      ? engagement_rate.append(
          "margin_percentage",
          engagementRate.marginPercentage
        )
      : engagement_rate.append(
          "margin_percentage",
          engagementRateDefault.marginPercentage
        );

    engagementRate.role
      ? engagement_rate.append("role", engagementRate.role)
      : engagement_rate.append("role", engagementRateDefault.role);

    // Dispatch action
    dispatch(createEngagementRateAction(engagement_rate));
  };

  // Delete rate
  const deleteRate = () => {
    // Dispatch action
    dispatch(
      deleteEngagementRateAction(engagementRateDetails.engagement_rate.id)
    );
  };

  // Update rate
  const updateRate = () => {
    // Prepare data
    const engagement_rate = new FormData();

    engagementRate.marginPercentage !== null
      ? engagement_rate.append(
          "margin_percentage",
          engagementRate.marginPercentage
        )
      : engagement_rate.append(
          "margin_percentage",
          engagementRateDefault.marginPercentage
        );

    engagementRate.role
      ? engagement_rate.append("role", engagementRate.role)
      : engagement_rate.append("role", engagementRateDefault.role);

    // Dispatch action
    dispatch(
      updateEngagementRateAction(
        engagementRateDetails.engagement_rate.id,
        engagement_rate
      )
    );
  };

  // Engagement report
  // Add report
  const addReport = () => {
    // Prepare data
    const engagement_report = new FormData();

    engagement_report.append(
      "comments_deliverables",
      engagementReportDefault.commentsDeliverables
    );

    engagement_report.append(
      "comments_dependencies",
      engagementReportDefault.commentsDependencies
    );

    engagement_report.append(
      "comments_financials",
      engagementReportDefault.commentsFinancials
    );

    engagement_report.append(
      "comments_issues",
      engagementReportDefault.commentsIssues
    );

    engagement_report.append(
      "comments_milestones",
      engagementReportDefault.commentsMilestones
    );

    engagement_report.append(
      "comments_resources",
      engagementReportDefault.commentsResources
    );

    engagement_report.append(
      "comments_risks",
      engagementReportDefault.commentsRisks
    );

    engagement_report.append(
      "comments_summary",
      engagementReportDefault.commentsSummary
    );

    engagement_report.append(
      "dependencies_status_comments",
      engagementReportDefault.dependenciesStatusComments
    );

    engagement_report.append(
      "dependencies_status_rating",
      engagementReportDefault.dependenciesStatusRating
    );

    engagement_report.append("engagement", id);

    engagement_report.append("external", engagementReportDefault.external);

    engagement_report.append(
      "financials_status_comments",
      engagementReportDefault.financialsStatusComments
    );

    engagement_report.append(
      "financials_status_rating",
      engagementReportDefault.financialsStatusRating
    );

    engagement_report.append("font", engagementReportDefault.font);

    engagement_report.append(
      "include_deliverables",
      engagementReportDefault.includeDeliverables
    );

    engagement_report.append(
      "include_dependencies",
      engagementReportDefault.includeDependencies
    );

    engagement_report.append(
      "include_financials",
      engagementReportDefault.includeFinancials
    );

    engagement_report.append(
      "include_issues",
      engagementReportDefault.includeIssues
    );

    engagement_report.append(
      "include_milestones",
      engagementReportDefault.includeMilestones
    );

    engagement_report.append(
      "include_resources",
      engagementReportDefault.includeResources
    );

    engagement_report.append(
      "include_risks",
      engagementReportDefault.includeRisks
    );

    engagement_report.append(
      "include_status",
      engagementReportDefault.includeStatus
    );

    engagement_report.append(
      "primary_colour",
      engagementReportDefault.primaryColour
    );

    engagement_report.append(
      "progress_status_comments",
      engagementReportDefault.progressStatusComments
    );

    engagement_report.append(
      "progress_status_rating",
      engagementReportDefault.progressStatusRating
    );

    engagement_report.append(
      "resources_status_comments",
      engagementReportDefault.resourcesStatusComments
    );

    engagement_report.append(
      "resources_status_rating",
      engagementReportDefault.resourcesStatusRating
    );

    engagement_report.append(
      "risks_issues_status_comments",
      engagementReportDefault.risksIssuesStatusComments
    );

    engagement_report.append(
      "risks_issues_status_rating",
      engagementReportDefault.risksIssuesStatusRating
    );

    engagement_report.append(
      "secondary_colour",
      engagementReportDefault.secondaryColour
    );

    engagement_report.append(
      "stakeholders_status_comments",
      engagementReportDefault.stakeholdersStatusComments
    );

    engagement_report.append(
      "stakeholders_status_rating",
      engagementReportDefault.stakeholdersStatusRating
    );

    engagement_report.append("template", engagementReportDefault.template);

    engagement_report.append("title", engagementReportDefault.title);

    engagement_report.append("status", engagementReportDefault.status);

    // Dispatch action
    dispatch(createEngagementReportAction(engagement_report));
  };

  // Delete report
  const deleteReport = () => {
    // Dispatch action
    dispatch(
      deleteEngagementReportAction(engagementReportDetails.engagement_report.id)
    );
  };

  useEffect(() => {
    if (engagementReportDelete.success) {
      setReportDialogIsOpen(false);

      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Report removed.",
      });

      dispatch(resetDeleteEngagementReportAction());

      dispatch(listEngagementReportsAction({ ...engagementReportQuery }));

      resetDrawerVariables();
    }
    // eslint-disable-next-line
  }, [engagementReportDelete.success]);

  // Update report
  const updateReport = () => {
    // Prepare data
    const engagement_report = new FormData();

    engagementReport.commentsDeliverables
      ? engagement_report.append(
          "comments_deliverables",
          engagementReport.commentsDeliverables
        )
      : engagement_report.append(
          "comments_deliverables",
          engagementReportDefault.commentsDeliverables
        );

    engagementReport.commentsDependencies
      ? engagement_report.append(
          "comments_dependencies",
          engagementReport.commentsDependencies
        )
      : engagement_report.append(
          "comments_dependencies",
          engagementReportDefault.commentsDependencies
        );

    engagementReport.commentsFinancials
      ? engagement_report.append(
          "comments_financials",
          engagementReport.commentsFinancials
        )
      : engagement_report.append(
          "comments_financials",
          engagementReportDefault.commentsFinancials
        );

    engagementReport.commentsIssues
      ? engagement_report.append(
          "comments_issues",
          engagementReport.commentsIssues
        )
      : engagement_report.append(
          "comments_issues",
          engagementReportDefault.commentsIssues
        );

    engagementReport.commentsMilestones
      ? engagement_report.append(
          "comments_milestones",
          engagementReport.commentsMilestones
        )
      : engagement_report.append(
          "comments_milestones",
          engagementReportDefault.commentsMilestones
        );

    engagementReport.commentsResources
      ? engagement_report.append(
          "comments_resources",
          engagementReport.commentsResources
        )
      : engagement_report.append(
          "comments_resources",
          engagementReportDefault.commentsResources
        );

    engagementReport.commentsRisks
      ? engagement_report.append(
          "comments_risks",
          engagementReport.commentsRisks
        )
      : engagement_report.append(
          "comments_risks",
          engagementReportDefault.commentsRisks
        );

    engagementReport.commentsSummary
      ? engagement_report.append(
          "comments_summary",
          engagementReport.commentsSummary
        )
      : engagement_report.append(
          "comments_summary",
          engagementReportDefault.commentsSummary
        );

    engagementReport.dependenciesStatusComments
      ? engagement_report.append(
          "dependencies_status_comments",
          engagementReport.dependenciesStatusComments
        )
      : engagement_report.append(
          "dependencies_status_comments",
          engagementReportDefault.dependenciesStatusComments
        );

    engagementReport.dependenciesStatusRating
      ? engagement_report.append(
          "dependencies_status_rating",
          engagementReport.dependenciesStatusRating
        )
      : engagement_report.append(
          "dependencies_status_rating",
          engagementReportDefault.dependenciesStatusRating
        );

    engagementReport.external !== null
      ? engagement_report.append("external", engagementReport.external)
      : engagement_report.append("external", engagementReportDefault.external);

    engagementReport.financialsStatusComments
      ? engagement_report.append(
          "financials_status_comments",
          engagementReport.financialsStatusComments
        )
      : engagement_report.append(
          "financials_status_comments",
          engagementReportDefault.financialsStatusComments
        );

    engagementReport.financialsStatusRating
      ? engagement_report.append(
          "financials_status_rating",
          engagementReport.financialsStatusRating
        )
      : engagement_report.append(
          "financials_status_rating",
          engagementReportDefault.financialsStatusRating
        );

    engagementReport.font
      ? engagement_report.append("font", engagementReport.font)
      : engagement_report.append("font", engagementReportDefault.font);

    engagementReport.includeDeliverables !== null
      ? engagement_report.append(
          "include_deliverables",
          engagementReport.includeDeliverables
        )
      : engagement_report.append(
          "include_deliverables",
          engagementReportDefault.includeDeliverables
        );

    engagementReport.includeDependencies !== null
      ? engagement_report.append(
          "include_dependencies",
          engagementReport.includeDependencies
        )
      : engagement_report.append(
          "include_dependencies",
          engagementReportDefault.includeDependencies
        );

    engagementReport.includeFinancials !== null
      ? engagement_report.append(
          "include_financials",
          engagementReport.includeFinancials
        )
      : engagement_report.append(
          "include_financials",
          engagementReportDefault.includeFinancials
        );

    engagementReport.includeIssues !== null
      ? engagement_report.append(
          "include_issues",
          engagementReport.includeIssues
        )
      : engagement_report.append(
          "include_issues",
          engagementReportDefault.includeIssues
        );

    engagementReport.includeMilestones !== null
      ? engagement_report.append(
          "include_milestones",
          engagementReport.includeMilestones
        )
      : engagement_report.append(
          "include_milestones",
          engagementReportDefault.includeMilestones
        );

    engagementReport.includeResources !== null
      ? engagement_report.append(
          "include_resources",
          engagementReport.includeResources
        )
      : engagement_report.append(
          "include_resources",
          engagementReportDefault.includeResources
        );

    engagementReport.includeRisks !== null
      ? engagement_report.append("include_risks", engagementReport.includeRisks)
      : engagement_report.append(
          "include_risks",
          engagementReportDefault.includeRisks
        );

    engagementReport.includeStatus !== null
      ? engagement_report.append(
          "include_status",
          engagementReport.includeStatus
        )
      : engagement_report.append(
          "include_status",
          engagementReportDefault.includeStatus
        );

    if (
      engagementReport.logo &&
      engagementReport.logo !== engagementReportDetails.engagement_report.logo
    ) {
      engagement_report.append("logo", engagementReport.logo);
    } else if (engagementReport.logo === "") {
      engagement_report.append("logo", engagementReport.logo);
    }

    engagementReport.primaryColour
      ? engagement_report.append(
          "primary_colour",
          engagementReport.primaryColour
        )
      : engagement_report.append(
          "primary_colour",
          engagementReportDefault.primaryColour
        );

    engagementReport.progressStatusComments
      ? engagement_report.append(
          "progress_status_comments",
          engagementReport.progressStatusComments
        )
      : engagement_report.append(
          "progress_status_comments",
          engagementReportDefault.progressStatusComments
        );

    engagementReport.progressStatusRating
      ? engagement_report.append(
          "progress_status_rating",
          engagementReport.progressStatusRating
        )
      : engagement_report.append(
          "progress_status_rating",
          engagementReportDefault.progressStatusRating
        );

    engagementReport.resourcesStatusComments
      ? engagement_report.append(
          "resources_status_comments",
          engagementReport.resourcesStatusComments
        )
      : engagement_report.append(
          "resources_status_comments",
          engagementReportDefault.resourcesStatusComments
        );

    engagementReport.resourcesStatusRating
      ? engagement_report.append(
          "resources_status_rating",
          engagementReport.resourcesStatusRating
        )
      : engagement_report.append(
          "resources_status_rating",
          engagementReportDefault.resourcesStatusRating
        );

    engagementReport.risksIssuesStatusComments
      ? engagement_report.append(
          "risks_issues_status_comments",
          engagementReport.risksIssuesStatusComments
        )
      : engagement_report.append(
          "risks_issues_status_comments",
          engagementReportDefault.risksIssuesStatusComments
        );

    engagementReport.risksIssuesStatusRating
      ? engagement_report.append(
          "risks_issues_status_rating",
          engagementReport.risksIssuesStatusRating
        )
      : engagement_report.append(
          "risks_issues_status_rating",
          engagementReportDefault.risksIssuesStatusRating
        );

    engagementReport.secondaryColour
      ? engagement_report.append(
          "secondary_colour",
          engagementReport.secondaryColour
        )
      : engagement_report.append(
          "secondary_colour",
          engagementReportDefault.secondaryColour
        );

    engagementReport.stakeholdersStatusComments
      ? engagement_report.append(
          "stakeholders_status_comments",
          engagementReport.stakeholdersStatusComments
        )
      : engagement_report.append(
          "stakeholders_status_comments",
          engagementReportDefault.stakeholdersStatusComments
        );

    engagementReport.stakeholdersStatusRating
      ? engagement_report.append(
          "stakeholders_status_rating",
          engagementReport.stakeholdersStatusRating
        )
      : engagement_report.append(
          "stakeholders_status_rating",
          engagementReportDefault.stakeholdersStatusRating
        );

    engagementReport.template
      ? engagement_report.append("template", engagementReport.template)
      : engagement_report.append("template", engagementReportDefault.template);

    engagementReport.title
      ? engagement_report.append("title", engagementReport.title)
      : engagement_report.append("title", engagementReportDefault.title);

    engagementReport.status
      ? engagement_report.append("status", engagementReport.status)
      : engagement_report.append("status", engagementReportDefault.status);

    // Dispatch action
    dispatch(
      updateEngagementReportAction(
        engagementReportDetails.engagement_report.id,
        engagement_report
      )
    );
  };

  useEffect(() => {
    if (engagementReportUpdate.success) {
      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: "Report updated.",
      });

      dispatch(resetUpdateEngagementReportAction());

      dispatch(
        listEngagementReportDetailsAction(
          engagementReportDetails.engagement_report.id
        )
      );
    }
    // eslint-disable-next-line
  }, [engagementReportUpdate.success]);

  // Engagement risk
  // Add risk
  const addRisk = () => {
    // Prepare data
    const engagement_risk = new FormData();

    engagementRisk.category
      ? engagement_risk.append("category", engagementRisk.category)
      : engagement_risk.append("category", engagementRiskDefault.category);

    engagement_risk.append("engagement", id);

    engagementRisk.impact
      ? engagement_risk.append("impact", engagementRisk.impact)
      : engagement_risk.append("impact", engagementRiskDefault.impact);

    engagementRisk.likelihood
      ? engagement_risk.append("likelihood", engagementRisk.likelihood)
      : engagement_risk.append("likelihood", engagementRiskDefault.likelihood);

    engagementRisk.name
      ? engagement_risk.append("name", engagementRisk.name)
      : engagement_risk.append("name", engagementRiskDefault.name);

    engagementRisk.owner
      ? engagement_risk.append("owner", engagementRisk.owner)
      : engagement_risk.append("owner", engagementRiskDefault.owner);

    engagementRisk.risk
      ? engagement_risk.append("risk", engagementRisk.risk)
      : engagement_risk.append("risk", engagementRiskDefault.risk);

    engagementRisk.strategy
      ? engagement_risk.append("strategy", engagementRisk.strategy)
      : engagement_risk.append("strategy", engagementRiskDefault.strategy);

    // Dispatch action
    dispatch(createEngagementRiskAction(engagement_risk));
  };

  // Delete risk
  const deleteRisk = () => {
    // Dispatch action
    dispatch(
      deleteEngagementRiskAction(engagementRiskDetails.engagement_risk.id)
    );
  };

  // Update risk
  const updateRisk = () => {
    // Prepare data
    const engagement_risk = new FormData();

    engagementRisk.category
      ? engagement_risk.append("category", engagementRisk.category)
      : engagement_risk.append("category", engagementRiskDefault.category);

    engagementRisk.impact
      ? engagement_risk.append("impact", engagementRisk.impact)
      : engagement_risk.append("impact", engagementRiskDefault.impact);

    engagementRisk.likelihood
      ? engagement_risk.append("likelihood", engagementRisk.likelihood)
      : engagement_risk.append("likelihood", engagementRiskDefault.likelihood);

    engagementRisk.name
      ? engagement_risk.append("name", engagementRisk.name)
      : engagement_risk.append("name", engagementRiskDefault.name);

    engagementRisk.owner
      ? engagement_risk.append("owner", engagementRisk.owner)
      : engagement_risk.append("owner", engagementRiskDefault.owner);

    engagementRisk.risk
      ? engagement_risk.append("risk", engagementRisk.risk)
      : engagement_risk.append("risk", engagementRiskDefault.risk);

    engagementRisk.status
      ? engagement_risk.append("status", engagementRisk.status)
      : engagement_risk.append("status", engagementRiskDefault.status);

    engagementRisk.strategy
      ? engagement_risk.append("strategy", engagementRisk.strategy)
      : engagement_risk.append("strategy", engagementRiskDefault.strategy);

    // Dispatch action
    dispatch(
      updateEngagementRiskAction(
        engagementRiskDetails.engagement_risk.id,
        engagement_risk
      )
    );
  };

  // Engagement service
  // Add service
  const addService = () => {
    // Prepare data
    const engagement_service = new FormData();

    engagementService.benefits
      ? engagement_service.append("benefits", engagementService.benefits)
      : engagement_service.append(
          "benefits",
          engagementServiceDefault.benefits
        );

    engagementService.description
      ? engagement_service.append("description", engagementService.description)
      : engagement_service.append(
          "description",
          engagementServiceDefault.description
        );

    engagement_service.append("engagement", id);

    engagementService.name
      ? engagement_service.append("name", engagementService.name)
      : engagement_service.append("name", engagementServiceDefault.name);

    engagementService.service
      ? engagement_service.append("service", engagementService.service)
      : engagement_service.append("service", engagementServiceDefault.service);

    // Dispatch action
    dispatch(createEngagementServiceAction(engagement_service));
  };

  // Delete service
  const deleteService = () => {
    // Dispatch action
    dispatch(
      deleteEngagementServiceAction(
        engagementServiceDetails.engagement_service.id
      )
    );
  };

  // Update service
  const updateService = () => {
    // Prepare data
    const engagement_service = new FormData();

    engagementService.benefits
      ? engagement_service.append("benefits", engagementService.benefits)
      : engagement_service.append(
          "benefits",
          engagementServiceDefault.benefits
        );

    engagementService.description
      ? engagement_service.append("description", engagementService.description)
      : engagement_service.append(
          "description",
          engagementServiceDefault.description
        );

    engagementService.name
      ? engagement_service.append("name", engagementService.name)
      : engagement_service.append("name", engagementServiceDefault.name);

    // Dispatch action
    dispatch(
      updateEngagementServiceAction(
        engagementServiceDetails.engagement_service.id,
        engagement_service
      )
    );
  };

  // Engagement service activity
  // Add activity
  const addActivity = () => {
    // Prepare data
    const engagement_service_activity = new FormData();

    engagementServiceActivity.description
      ? engagement_service_activity.append(
          "description",
          engagementServiceActivity.description
        )
      : engagement_service_activity.append(
          "description",
          engagementServiceActivityDefault.description
        );

    engagementServiceActivity.end
      ? engagement_service_activity.append(
          "end",
          getDjangoDate(engagementServiceActivity.end)
        )
      : engagement_service_activity.append(
          "end",
          getDjangoDate(engagementServiceActivityDefault.end)
        );

    engagementServiceActivity.name
      ? engagement_service_activity.append(
          "name",
          engagementServiceActivity.name
        )
      : engagement_service_activity.append(
          "name",
          engagementServiceActivityDefault.name
        );

    engagementServiceActivity.service
      ? engagement_service_activity.append(
          "service",
          engagementServiceActivity.service
        )
      : engagement_service_activity.append(
          "service",
          engagementServiceActivityDefault.service
        );

    engagementServiceActivity.start
      ? engagement_service_activity.append(
          "start",
          getDjangoDate(engagementServiceActivity.start)
        )
      : engagement_service_activity.append(
          "start",
          getDjangoDate(engagementServiceActivityDefault.start)
        );

    // Dispatch action
    dispatch(
      createEngagementServiceActivityAction(engagement_service_activity)
    );
  };

  // Delete activity
  const deleteActivity = () => {
    // Dispatch action
    dispatch(
      deleteEngagementServiceActivityAction(
        engagementServiceActivityDetails.engagement_service_activity.id
      )
    );
  };

  // Update activity
  const updateActivity = () => {
    // Prepare data
    const engagement_service_activity = new FormData();

    engagementServiceActivity.description
      ? engagement_service_activity.append(
          "description",
          engagementServiceActivity.description
        )
      : engagement_service_activity.append(
          "description",
          engagementServiceActivityDefault.description
        );

    engagementServiceActivity.end
      ? engagement_service_activity.append(
          "end",
          getDjangoDate(engagementServiceActivity.end)
        )
      : engagement_service_activity.append(
          "end",
          getDjangoDate(engagementServiceActivityDefault.end)
        );

    engagementServiceActivity.name
      ? engagement_service_activity.append(
          "name",
          engagementServiceActivity.name
        )
      : engagement_service_activity.append(
          "name",
          engagementServiceActivityDefault.name
        );

    engagementServiceActivity.progress !== null
      ? engagement_service_activity.append(
          "progress",
          engagementServiceActivity.progress
        )
      : engagement_service_activity.append(
          "progress",
          engagementServiceActivityDefault.progress
        );

    engagementServiceActivity.service
      ? engagement_service_activity.append(
          "service",
          engagementServiceActivity.service
        )
      : engagement_service_activity.append(
          "service",
          engagementServiceActivityDefault.service
        );

    engagementServiceActivity.start
      ? engagement_service_activity.append(
          "start",
          getDjangoDate(engagementServiceActivity.start)
        )
      : engagement_service_activity.append(
          "start",
          getDjangoDate(engagementServiceActivityDefault.start)
        );

    // Dispatch action
    dispatch(
      updateEngagementServiceActivityAction(
        engagementServiceActivityDetails.engagement_service_activity.id,
        engagement_service_activity
      )
    );
  };

  // Engagement service deliverable
  // Add deliverable
  const addDeliverable = () => {
    // Prepare data
    const engagement_service_deliverable = new FormData();

    engagementServiceDeliverable.description
      ? engagement_service_deliverable.append(
          "description",
          engagementServiceDeliverable.description
        )
      : engagement_service_deliverable.append(
          "description",
          engagementServiceDeliverableDefault.description
        );

    engagementServiceDeliverable.due
      ? engagement_service_deliverable.append(
          "due",
          getDjangoDate(engagementServiceDeliverable.due)
        )
      : engagement_service_deliverable.append(
          "due",
          getDjangoDate(engagementServiceDeliverableDefault.due)
        );

    engagementServiceDeliverable.milestone
      ? engagement_service_deliverable.append(
          "milestone",
          engagementServiceDeliverable.milestone
        )
      : engagement_service_deliverable.append(
          "milestone",
          engagementServiceDeliverableDefault.milestone
        );

    engagementServiceDeliverable.name
      ? engagement_service_deliverable.append(
          "name",
          engagementServiceDeliverable.name
        )
      : engagement_service_deliverable.append(
          "name",
          engagementServiceDeliverableDefault.name
        );

    engagementServiceDeliverable.service
      ? engagement_service_deliverable.append(
          "service",
          engagementServiceDeliverable.service
        )
      : engagement_service_deliverable.append(
          "service",
          engagementServiceDeliverableDefault.service
        );

    if (
      engagementServiceDeliverable.template &&
      engagementServiceDeliverable.template !==
        engagementServiceDeliverableDetails.engagement_service_deliverable
          .template
    ) {
      engagement_service_deliverable.append(
        "template",
        engagementServiceDeliverable.template
      );
    } else if (engagementServiceDeliverable.template === "") {
      engagement_service_deliverable.append(
        "template",
        engagementServiceDeliverableDefault.template
      );
    }

    engagementServiceDeliverable.type
      ? engagement_service_deliverable.append(
          "type",
          engagementServiceDeliverable.type
        )
      : engagement_service_deliverable.append(
          "type",
          engagementServiceDeliverableDefault.type
        );

    // Dispatch action
    dispatch(
      createEngagementServiceDeliverableAction(engagement_service_deliverable)
    );
  };

  // Delete deliverable
  const deleteDeliverable = () => {
    // Dispatch action
    dispatch(
      deleteEngagementServiceDeliverableAction(
        engagementServiceDeliverableDetails.engagement_service_deliverable.id
      )
    );
  };

  // Update deliverable
  const updateDeliverable = () => {
    // Prepare data
    const engagement_service_deliverable = new FormData();

    engagementServiceDeliverable.description
      ? engagement_service_deliverable.append(
          "description",
          engagementServiceDeliverable.description
        )
      : engagement_service_deliverable.append(
          "description",
          engagementServiceDeliverableDefault.description
        );

    engagementServiceDeliverable.due
      ? engagement_service_deliverable.append(
          "due",
          getDjangoDate(engagementServiceDeliverable.due)
        )
      : engagement_service_deliverable.append(
          "due",
          getDjangoDate(engagementServiceDeliverableDefault.due)
        );

    engagementServiceDeliverable.milestone
      ? engagement_service_deliverable.append(
          "milestone",
          engagementServiceDeliverable.milestone
        )
      : engagement_service_deliverable.append(
          "milestone",
          engagementServiceDeliverableDefault.milestone
        );

    engagementServiceDeliverable.name
      ? engagement_service_deliverable.append(
          "name",
          engagementServiceDeliverable.name
        )
      : engagement_service_deliverable.append(
          "name",
          engagementServiceDeliverableDefault.name
        );

    engagementServiceDeliverable.service
      ? engagement_service_deliverable.append(
          "service",
          engagementServiceDeliverable.service
        )
      : engagement_service_deliverable.append(
          "service",
          engagementServiceDeliverableDefault.service
        );

    engagementServiceDeliverable.status
      ? engagement_service_deliverable.append(
          "status",
          engagementServiceDeliverable.status
        )
      : engagement_service_deliverable.append(
          "status",
          engagementServiceDeliverableDefault.status
        );

    if (
      engagementServiceDeliverable.template &&
      engagementServiceDeliverable.template !==
        engagementServiceDeliverableDetails.engagement_service_deliverable
          .template
    ) {
      engagement_service_deliverable.append(
        "template",
        engagementServiceDeliverable.template
      );
    } else if (engagementServiceDeliverable.template === "") {
      engagement_service_deliverable.append(
        "template",
        engagementServiceDeliverableDefault.template
      );
    }

    engagementServiceDeliverable.type
      ? engagement_service_deliverable.append(
          "type",
          engagementServiceDeliverable.type
        )
      : engagement_service_deliverable.append(
          "type",
          engagementServiceDeliverableDefault.type
        );

    // Dispatch action
    dispatch(
      updateEngagementServiceDeliverableAction(
        engagementServiceDeliverableDetails.engagement_service_deliverable.id,
        engagement_service_deliverable
      )
    );
  };

  // Engagement upload
  // Add upload
  const addUpload = () => {
    // Prepare data
    const engagement_upload = new FormData();

    engagementUpload.description
      ? engagement_upload.append("description", engagementUpload.description)
      : engagement_upload.append(
          "description",
          engagementUploadDefault.description
        );

    if (
      engagementUpload.file &&
      engagementUpload.file !== engagementUploadDetails.engagement_upload.file
    ) {
      engagement_upload.append("file", engagementUpload.file);
    } else if (engagementUpload.file === "") {
      engagement_upload.append("file", engagementDefault.file);
    }

    engagementUpload.name
      ? engagement_upload.append("name", engagementUpload.name)
      : engagement_upload.append("name", engagementUploadDefault.name);

    engagement_upload.append("engagement", id);

    // Dispatch action
    dispatch(createEngagementUploadAction(engagement_upload));
  };

  // Delete upload
  const deleteUpload = () => {
    // Dispatch action
    dispatch(
      deleteEngagementUploadAction(engagementUploadDetails.engagement_upload.id)
    );
  };

  // Update upload
  const updateUpload = () => {
    // Prepare data
    const engagement_upload = new FormData();

    engagementUpload.description
      ? engagement_upload.append("description", engagementUpload.description)
      : engagement_upload.append(
          "description",
          engagementUploadDefault.description
        );

    if (
      engagementUpload.file &&
      engagementUpload.file !== engagementUploadDetails.engagement_upload.file
    ) {
      engagement_upload.append("file", engagementUpload.file);
    } else if (engagementUpload.file === "") {
      engagement_upload.append("file", engagementDefault.file);
    }

    engagementUpload.name
      ? engagement_upload.append("name", engagementUpload.name)
      : engagement_upload.append("name", engagementUploadDefault.name);

    // Dispatch action
    dispatch(
      updateEngagementUploadAction(
        engagementUploadDetails.engagement_upload.id,
        engagement_upload
      )
    );
  };

  // Download editable engagement document
  const [
    editableEngagementDocumentLoading,
    setEditableEngagementDocumentLoading,
  ] = useState(false);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.engagement_file !== undefined) {
        fetch(lastJsonMessage.message.engagement_file)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(
              blob,
              `Engagement Charter for ${engagementDetails.engagement.name}.docx`
            );
          });

        setEditableEngagementDocumentLoading(false);

        dispatch(resetDownloadEngagementAction());
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (engagementDownload.error) {
      AppToaster.show({
        icon: "issue",
        intent: "danger",
        message: "We couldn't download the file. Please try again.",
      });
    } else if (engagementDownload.success) {
      AppToaster.show({
        icon: "info-sign",
        intent: "primary",
        message: "File downloading.",
      });
    }
  }, [engagementDownload.error, engagementDownload.success]);

  // Generate report data
  const [reportData, setReportData] = useState(null);

  useEffect(() => {
    if (reportData === null && engagementDetails.success) {
      setReportData(engagementDetails.engagement.report_data);
    }
    // eslint-disable-next-line
  }, [engagementDetails.success]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.report_data !== undefined) {
        setReportData(JSON.parse(lastJsonMessage.message.report_data));
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (engagementGenerateReportData.success) {
      dispatch(resetGenerateReportDataEngagementAction());
    } else if (engagementGenerateReportData.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "We could not refresh the engagement's status. Please refresh the page and try again.",
      });
    }
    // eslint-disable-next-line
  }, [engagementGenerateReportData.loading]);

  // Download editable report document
  const [editableReportDocumentLoading, setEditableReportDocumentLoading] =
    useState(false);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.engagement_report_file !== undefined) {
        fetch(lastJsonMessage.message.engagement_report_file)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(
              blob,
              `${engagementReportDetails.engagement_report.title}.docx`
            );
          });

        setEditableReportDocumentLoading(false);

        dispatch(resetDownloadEngagementReportAction());
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (engagementReportDownload.error) {
      AppToaster.show({
        icon: "issue",
        intent: "danger",
        message: "We couldn't download the file. Please try again.",
      });
    } else if (engagementReportDownload.success) {
      AppToaster.show({
        icon: "info-sign",
        intent: "primary",
        message: "File downloading.",
      });
    }
  }, [engagementReportDownload.error, engagementReportDownload.success]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify the engagement</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Engagement name */}
                  <FormDrawerTextField2
                    error={engagementUpdate.error}
                    fieldName="name"
                    icon="gantt-chart"
                    label="Engagement name"
                    labelInfo="(required)"
                    placeholder="Give the engagement a name"
                    setValueObject={setEngagement}
                    setValueVariable="name"
                    value={engagement.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Client */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={engagementUpdate.error}
                    fieldName="client"
                    helperText="You can create or select an existing client"
                    icon="office"
                    info={
                      <ClientInfo
                        clientId={engagement.client}
                        query={clientQueryDefault}
                      />
                    }
                    items={clientList.clients}
                    label="Client"
                    labelInfo="(required)"
                    loading={clientList.loading}
                    placeholder="Create or select a client"
                    query={clientQuery.searchString}
                    setQueryObject={setClientQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagement}
                    setValueIdVariable="client"
                    setValueNameVariable="clientName"
                    showInfo={engagement.client}
                    value={engagement.clientName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Engagement value */}
                  <FormDrawerNumericField2
                    error={engagementUpdate.error}
                    fieldName="value"
                    label="Engagement value"
                    labelInfo="(required)"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {activeOrganisation.currency}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the contract value"
                    setValueObject={setEngagement}
                    setValueVariable="value"
                    stepSize={10000}
                    value={engagement.value}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Sponsor */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={engagementUpdate.error}
                    fieldName="sponsor"
                    helperText="You can create or select an existing contact"
                    icon="id-number"
                    info={
                      <ContactInfo
                        contactId={engagement.sponsor}
                        query={contactQueryDefault}
                      />
                    }
                    items={contactList.contacts}
                    label="Sponsor"
                    loading={contactList.loading}
                    noOptionOnClick
                    placeholder="Create or select a contact"
                    query={contactQuery.searchString}
                    setQueryObject={setContactQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagement}
                    setValueIdVariable="sponsor"
                    setValueNameVariable="sponsorName"
                    showInfo={engagement.sponsor}
                    value={engagement.sponsorName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Statement of work */}
                  <FormDrawerFileField2
                    error={engagementUpdate.error}
                    fileName="sow"
                    label="Statement of work"
                    placeholder="Upload the statement of work"
                    setValueObject={setEngagement}
                    setValueFileVariable="sow"
                    setValueNameVariable="sowName"
                    valueFile={engagement.sow}
                    valueName={engagement.sowName}
                  />
                </div>

                <div style={{ marginRight: "10px", width: "40%" }}></div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={engagementUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the engagement"
                setValueObject={setEngagement}
                setValueVariable="notes"
                value={engagement.notes}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Engagement status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementUpdate.error}
                    fieldName="status"
                    intent={
                      engagement.status === "Amber"
                        ? "warning"
                        : engagement.status === "Green"
                        ? "success"
                        : engagement.status === "Red"
                        ? "danger"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "playbook", value: "Planning" },
                      { id: 2, icon: "repeat", value: "Green" },
                      { id: 3, icon: "issue-new", value: "Amber" },
                      { id: 4, icon: "issue", value: "Red" },
                      { id: 5, icon: "confirm", value: "Closed" },
                    ]}
                    setValueObject={setEngagement}
                    setValueVariable="status"
                    value={engagement.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      engagementDetails.engagement.user_access_status ===
                        "edit" ||
                      engagementDetails.engagement.user_access_status === "view"
                    }
                    error={engagementUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted engagement can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setEngagement}
                    setValueVariable="restricted"
                    value={engagement.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      engagementDetails.engagement.user_access_status ===
                        "edit" ||
                      engagementDetails.engagement.user_access_status === "view"
                    }
                    error={engagementUpdate.error}
                    fieldName="manager"
                    helperText="Person driving the engagement - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagement}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={engagement.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the engagement manager and the
                    engagement is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={engagementUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Updating an engagement"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={engagementDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listEngagementDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateEngagementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementUpdate.success}
        saveSuccessMessage="Engagement updated."
        saving={engagementUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={engagementPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setEngagementPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={engagementPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={engagementPermissionCreate.error}
              fieldName="level"
              intent={
                engagementPermission.level === "Edit"
                  ? "success"
                  : engagementPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setEngagementPermission}
              setValueVariable="level"
              value={engagementPermission.level}
            />
          </Card>
        }
        error={engagementPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listEngagementPermissionsAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={engagementPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={engagementPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setEngagementPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={engagementPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={engagementPermissionUpdate.error}
              fieldName="level"
              intent={
                engagementPermission.level === "Edit"
                  ? "success"
                  : engagementPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setEngagementPermission}
              setValueVariable="level"
              value={engagementPermission.level}
            />
          </Card>
        }
        deleteSuccess={engagementPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={engagementPermissionDelete.loading}
        error={engagementPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={engagementPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(
            listEngagementPermissionsAction({ ...engagementPermissionQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementPermissionAction());

          dispatch(resetUpdateEngagementPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={engagementPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Decision */}
                  <FormDrawerSelectSimpleField3
                    error={approvalRequestCreate.error}
                    fieldName="decision_right"
                    helperText="The type of decision you are asking for"
                    label="Decision"
                    labelInfo="(required)"
                    options={[
                      {
                        id: 1,
                        icon: "bring-data",
                        value: "Engagement baseline",
                      },
                      /*{
                        id: 2,
                        icon: "briefcase",
                        value: "Engagement deliverable",
                      },*/
                      { id: 3, icon: "endorsed", value: "Engagement close" },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="decisionRight"
                    value={approvalRequest.decisionRight}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    error={approvalRequestCreate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={approvalRequestCreate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    error={approvalRequestCreate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={approvalRequestCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create approval request"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={addApprovalRequestDrawerIsOpen}
        onSave={addApprovalRequest}
        refreshList={() => {
          dispatch(
            listApprovalRequestsAction({
              itemId: id,
              itemType: "engagement",
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestCreate.success}
        saveSuccessMessage="Approval request created."
        saving={approvalRequestCreate.loading}
        setIsOpen={setAddApprovalRequestDrawerIsOpen}
        title="Create approval request"
      />

      {/* Existing approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Decision */}
                  <FormDrawerSelectSimpleField3
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="decision_right"
                    helperText="The type of decision you are asking for"
                    label="Decision"
                    labelInfo="(required)"
                    options={[
                      {
                        id: 1,
                        icon: "bring-data",
                        value: "Engagement baseline",
                      },
                      /*{
                        id: 2,
                        icon: "briefcase",
                        value: "Engagement deliverable",
                      },*/
                      { id: 3, icon: "endorsed", value: "Engagement close" },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="decisionRight"
                    value={approvalRequest.decisionRight}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                disabled={
                  approvalRequestDetails.approval_request.status === "Submitted"
                }
                error={approvalRequestUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={approvalRequestDelete.success}
        deleteSuccessMessage="Approval request deleted."
        deleting={approvalRequestDelete.loading}
        error={approvalRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approval request"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={updateApprovalRequestDrawerIsOpen}
        loading={approvalRequestDetails.loading}
        onDelete={deleteApprovalRequest}
        onSave={updateApprovalRequest}
        refreshList={() => {
          dispatch(listApprovalRequestsAction({ ...approvalRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteApprovalRequestAction());

          dispatch(resetUpdateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestUpdate.success}
        saveSuccessMessage="Approval request updated."
        saving={approvalRequestUpdate.loading}
        setIsOpen={setUpdateApprovalRequestDrawerIsOpen}
        title="Update approval request"
      />

      {/* New risk form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the risk</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementRiskCreate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the risk a title"
                    setValueObject={setEngagementRisk}
                    setValueVariable="name"
                    value={engagementRisk.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskCreate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "database", value: "Information" },
                      { id: 2, icon: "briefcase", value: "Partners" },
                      { id: 3, icon: "flow-end", value: "Process" },
                      { id: 4, icon: "people", value: "Team" },
                      { id: 5, icon: "cloud-download", value: "Technology" },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="category"
                    value={engagementRisk.category}
                  />
                </div>
              </div>

              {/* Risk */}
              <FormDrawerTextAreaField3
                error={engagementRiskCreate.error}
                fieldName="risk"
                height="100px"
                label="Risk"
                labelInfo="(required)"
                placeholder="Describe the risk"
                setValueObject={setEngagementRisk}
                setValueVariable="risk"
                value={engagementRisk.risk}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Impact */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskCreate.error}
                    fieldName="impact"
                    helperText="Severity of risk (High: Unacceptable; Medium: Significant; Low: Immaterial)"
                    intent={
                      engagementRisk.impact === "High"
                        ? "danger"
                        : engagementRisk.impact === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Impact"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="impact"
                    value={engagementRisk.impact}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Likelihood */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskCreate.error}
                    fieldName="likelihood"
                    helperText="Probability of risk (High: > 75%; Medium: 25% - 75%; Low: < 25%)"
                    intent={
                      engagementRisk.likelihood === "High"
                        ? "danger"
                        : engagementRisk.likelihood === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Likelihood"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="likelihood"
                    value={engagementRisk.likelihood}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Enter the risk management strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementRiskCreate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementRisk}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementRisk.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Strategy */}
              <FormDrawerTextAreaField3
                error={engagementRiskCreate.error}
                fieldName="strategy"
                placeholder="Describe how you will avoid and/or mitigate the risk"
                setValueObject={setEngagementRisk}
                setValueVariable="strategy"
                value={engagementRisk.strategy}
              />
            </Card>
          </div>
        }
        error={engagementRiskCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add risk"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Shaping your risk management strategy"
        icon="edit"
        isOpen={addRiskDrawerIsOpen}
        onSave={addRisk}
        refreshList={() => {
          dispatch(listEngagementRisksAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementRiskAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementRiskCreate.success}
        saveSuccessMessage="Risk added."
        saving={engagementRiskCreate.loading}
        setIsOpen={setAddRiskDrawerIsOpen}
        title="Add risk"
      />

      {/* Existing risk form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the risk</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementRiskUpdate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the risk a title"
                    setValueObject={setEngagementRisk}
                    setValueVariable="name"
                    value={engagementRisk.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "database", value: "Information" },
                      { id: 2, icon: "briefcase", value: "Partners" },
                      { id: 3, icon: "flow-end", value: "Process" },
                      { id: 4, icon: "people", value: "Team" },
                      { id: 5, icon: "cloud-download", value: "Technology" },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="category"
                    value={engagementRisk.category}
                  />
                </div>
              </div>

              {/* Risk */}
              <FormDrawerTextAreaField3
                error={engagementRiskUpdate.error}
                fieldName="risk"
                height="100px"
                label="Risk"
                labelInfo="(required)"
                placeholder="Describe the risk"
                setValueObject={setEngagementRisk}
                setValueVariable="risk"
                value={engagementRisk.risk}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Impact */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskUpdate.error}
                    fieldName="impact"
                    helperText="Severity of risk (High: Unacceptable; Medium: Significant; Low: Immaterial)"
                    intent={
                      engagementRisk.impact === "High"
                        ? "danger"
                        : engagementRisk.impact === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Impact"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="impact"
                    value={engagementRisk.impact}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Likelihood */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskUpdate.error}
                    fieldName="likelihood"
                    helperText="Probability of risk (High: > 75%; Medium: 25% - 75%; Low: < 25%)"
                    intent={
                      engagementRisk.likelihood === "High"
                        ? "danger"
                        : engagementRisk.likelihood === "Low"
                        ? "success"
                        : "warning"
                    }
                    label="Likelihood"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "High",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Medium",
                      },
                      {
                        id: 3,
                        icon: "arrow-down",
                        value: "Low",
                      },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="likelihood"
                    value={engagementRisk.likelihood}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Enter the risk management strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementRiskUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementRisk}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementRisk.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementRiskUpdate.error}
                    fieldName="status"
                    intent={
                      engagementRisk.status === "Closed" ? "success" : "danger"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue-closed",
                        value: "Closed",
                      },
                      {
                        id: 2,
                        icon: "issue",
                        value: "Open",
                      },
                    ]}
                    setValueObject={setEngagementRisk}
                    setValueVariable="status"
                    value={engagementRisk.status}
                  />
                </div>
              </div>

              {/* Strategy */}
              <FormDrawerTextAreaField3
                error={engagementRiskUpdate.error}
                fieldName="strategy"
                placeholder="Describe how you will avoid and/or mitigate the risk"
                setValueObject={setEngagementRisk}
                setValueVariable="strategy"
                value={engagementRisk.strategy}
              />
            </Card>
          </div>
        }
        deleteSuccess={engagementRiskDelete.success}
        deleteSuccessMessage="Risk removed."
        deleting={engagementRiskDelete.loading}
        error={engagementRiskUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update risk"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Shaping your risk management strategy"
        icon="edit"
        isOpen={updateRiskDrawerIsOpen}
        loading={engagementRiskDetails.loading}
        onDelete={deleteRisk}
        onSave={updateRisk}
        refreshList={() => {
          dispatch(listEngagementRisksAction({ ...engagementRiskQuery }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementRiskAction());

          dispatch(resetUpdateEngagementRiskAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementRiskUpdate.success}
        saveSuccessMessage="Risk updated."
        saving={engagementRiskUpdate.loading}
        setIsOpen={setUpdateRiskDrawerIsOpen}
        title="Update risk"
      />

      {/* New action form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the action</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={engagementActionCreate.error}
                  fieldName="name"
                  icon="take-action"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the action a title"
                  setValueObject={setEngagementAction}
                  setValueVariable="name"
                  value={engagementAction.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={engagementActionCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "playbook",
                      value: "Planning",
                    },
                    { id: 2, icon: "new-person", value: "Stakeholders" },
                    { id: 3, icon: "people", value: "Resourcing" },
                    { id: 4, icon: "gantt-chart", value: "Delivery" },
                    {
                      id: 5,
                      icon: "dollar",
                      value: "Commercials",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setEngagementAction}
                  setValueVariable="category"
                  value={engagementAction.category}
                />
              </div>
            </div>

            {/* Action */}
            <FormDrawerTextAreaField3
              error={engagementActionCreate.error}
              fieldName="action"
              height="100px"
              label="Action"
              labelInfo="(required)"
              placeholder="Enter the action"
              setValueObject={setEngagementAction}
              setValueVariable="action"
              value={engagementAction.action}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={engagementActionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for completing the action"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementAction}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={engagementAction.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={engagementActionCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the action due date"
                  setValueObject={setEngagementAction}
                  setValueVariable="due"
                  value={engagementAction.due}
                />
              </div>
            </div>
          </Card>
        }
        error={engagementActionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create action"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={addActionDrawerIsOpen}
        onSave={addAction}
        refreshList={() => {
          dispatch(listEngagementActionsAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementActionCreate.success}
        saveSuccessMessage="Action created."
        saving={engagementActionCreate.loading}
        setIsOpen={setAddActionDrawerIsOpen}
        title="Create action"
      />

      {/* Existing action form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the action</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementActionUpdate.error}
                    fieldName="name"
                    icon="shield"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the action a title"
                    setValueObject={setEngagementAction}
                    setValueVariable="name"
                    value={engagementAction.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={engagementActionUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      {
                        id: 1,
                        icon: "playbook",
                        value: "Planning",
                      },
                      { id: 2, icon: "new-person", value: "Stakeholders" },
                      { id: 3, icon: "people", value: "Resourcing" },
                      { id: 4, icon: "gantt-chart", value: "Delivery" },
                      {
                        id: 5,
                        icon: "dollar",
                        value: "Commercials",
                      },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setEngagementAction}
                    setValueVariable="category"
                    value={engagementAction.category}
                  />
                </div>
              </div>

              {/* Action */}
              <FormDrawerTextAreaField3
                error={engagementActionUpdate.error}
                fieldName="action"
                height="100px"
                label="Action"
                labelInfo="(required)"
                placeholder="Enter the action"
                setValueObject={setEngagementAction}
                setValueVariable="action"
                value={engagementAction.action}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementActionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementAction}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementAction.ownerName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                >
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={engagementActionUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the action due date"
                    setValueObject={setEngagementAction}
                    setValueVariable="due"
                    value={engagementAction.due}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  tag={
                    engagementActionDetails.engagement_action.owner_done
                      ? "Ready to close"
                      : "Working on it"
                  }
                  tagIntent={
                    engagementActionDetails.engagement_action.owner_done
                      ? "success"
                      : "primary"
                  }
                  text={
                    engagementActionDetails.engagement_action.owner_comments
                  }
                  title="Update from action owner"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementActionUpdate.error}
                    fieldName="status"
                    intent={
                      engagementAction.status === "Completed"
                        ? "success"
                        : engagementAction.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Completed",
                      },
                      {
                        id: 2,
                        icon: "refresh",
                        value: "In-progress",
                      },
                      {
                        id: 3,
                        icon: "remove",
                        value: "On-hold",
                      },
                    ]}
                    setValueObject={setEngagementAction}
                    setValueVariable="status"
                    value={engagementAction.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                ></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={engagementActionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setEngagementAction}
                setValueVariable="comments"
                value={engagementAction.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={engagementActionDelete.success}
        deleteSuccessMessage="Action deleted."
        deleting={engagementActionDelete.loading}
        error={engagementActionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update action"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={updateActionDrawerIsOpen}
        loading={engagementActionDetails.loading}
        onDelete={deleteAction}
        onSave={updateAction}
        refreshList={() => {
          dispatch(listEngagementActionsAction({ ...engagementActionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementActionAction());

          dispatch(resetUpdateEngagementActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementActionUpdate.success}
        saveSuccessMessage="Action updated."
        saving={engagementActionUpdate.loading}
        setIsOpen={setUpdateActionDrawerIsOpen}
        title="Update action"
      />

      {/* New issue form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the issue</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementDeliveryIssueCreate.error}
                    fieldName="name"
                    icon="issue"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the issue a title"
                    setValueObject={setEngagementDeliveryIssue}
                    setValueVariable="name"
                    value={engagementDeliveryIssue.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={engagementDeliveryIssueCreate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "database", value: "Information" },
                      { id: 2, icon: "briefcase", value: "Partners" },
                      { id: 3, icon: "flow-end", value: "Process" },
                      { id: 4, icon: "people", value: "Team" },
                      { id: 5, icon: "cloud-download", value: "Technology" },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setEngagementDeliveryIssue}
                    setValueVariable="category"
                    value={engagementDeliveryIssue.category}
                  />
                </div>
              </div>

              {/* Issue */}
              <FormDrawerTextAreaField3
                error={engagementDeliveryIssueCreate.error}
                fieldName="issue"
                height="100px"
                label="Issue"
                labelInfo="(required)"
                placeholder="Describe the issue"
                setValueObject={setEngagementDeliveryIssue}
                setValueVariable="issue"
                value={engagementDeliveryIssue.issue}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Enter the issue management strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementDeliveryIssueCreate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementDeliveryIssue}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementDeliveryIssue.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Strategy */}
              <FormDrawerTextAreaField3
                error={engagementDeliveryIssueCreate.error}
                fieldName="strategy"
                placeholder="Describe how you will manage the issue"
                setValueObject={setEngagementDeliveryIssue}
                setValueVariable="strategy"
                value={engagementDeliveryIssue.strategy}
              />
            </Card>
          </div>
        }
        error={engagementDeliveryIssueCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add issue"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing issues"
        icon="edit"
        isOpen={addIssueDrawerIsOpen}
        onSave={addIssue}
        refreshList={() => {
          dispatch(listEngagementDeliveryIssuesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementDeliveryIssueAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementDeliveryIssueCreate.success}
        saveSuccessMessage="Issue added."
        saving={engagementDeliveryIssueCreate.loading}
        setIsOpen={setAddIssueDrawerIsOpen}
        title="Add issue"
      />

      {/* Existing issue form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the issue</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementDeliveryIssueUpdate.error}
                    fieldName="name"
                    icon="issue"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the issue a title"
                    setValueObject={setEngagementDeliveryIssue}
                    setValueVariable="name"
                    value={engagementDeliveryIssue.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={engagementDeliveryIssueUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      { id: 1, icon: "database", value: "Information" },
                      { id: 2, icon: "briefcase", value: "Partners" },
                      { id: 3, icon: "flow-end", value: "Process" },
                      { id: 4, icon: "people", value: "Team" },
                      { id: 5, icon: "cloud-download", value: "Technology" },
                      { id: 6, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setEngagementDeliveryIssue}
                    setValueVariable="category"
                    value={engagementDeliveryIssue.category}
                  />
                </div>
              </div>

              {/* Issue */}
              <FormDrawerTextAreaField3
                error={engagementDeliveryIssueUpdate.error}
                fieldName="issue"
                height="100px"
                label="Issue"
                labelInfo="(required)"
                placeholder="Describe the issue"
                setValueObject={setEngagementDeliveryIssue}
                setValueVariable="issue"
                value={engagementDeliveryIssue.issue}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Enter the issue management strategy</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementDeliveryIssueUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementDeliveryIssue}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementDeliveryIssue.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementDeliveryIssueUpdate.error}
                    fieldName="status"
                    intent={
                      engagementDeliveryIssue.status === "Closed"
                        ? "success"
                        : "danger"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue-closed",
                        value: "Closed",
                      },
                      {
                        id: 2,
                        icon: "issue",
                        value: "Open",
                      },
                    ]}
                    setValueObject={setEngagementDeliveryIssue}
                    setValueVariable="status"
                    value={engagementDeliveryIssue.status}
                  />
                </div>
              </div>

              {/* Strategy */}
              <FormDrawerTextAreaField3
                error={engagementDeliveryIssueUpdate.error}
                fieldName="strategy"
                placeholder="Describe how you will manager the issue"
                setValueObject={setEngagementDeliveryIssue}
                setValueVariable="strategy"
                value={engagementDeliveryIssue.strategy}
              />
            </Card>
          </div>
        }
        deleteSuccess={engagementDeliveryIssueDelete.success}
        deleteSuccessMessage="Issue removed."
        deleting={engagementDeliveryIssueDelete.loading}
        error={engagementDeliveryIssueUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update issue"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing issues"
        icon="edit"
        isOpen={updateIssueDrawerIsOpen}
        loading={engagementDeliveryIssueDetails.loading}
        onDelete={deleteIssue}
        onSave={updateIssue}
        refreshList={() => {
          dispatch(
            listEngagementDeliveryIssuesAction({
              ...engagementDeliveryIssueQuery,
            })
          );

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementDeliveryIssueAction());

          dispatch(resetUpdateEngagementDeliveryIssueAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementDeliveryIssueUpdate.success}
        saveSuccessMessage="Issue updated."
        saving={engagementDeliveryIssueUpdate.loading}
        setIsOpen={setUpdateIssueDrawerIsOpen}
        title="Update issue"
      />

      {/* New dependency form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the dependency</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementDependencyCreate.error}
                    fieldName="name"
                    icon="flows"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the dependency a title"
                    setValueObject={setEngagementDependency}
                    setValueVariable="name"
                    value={engagementDependency.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Dependency */}
              <FormDrawerTextAreaField3
                error={engagementDependencyCreate.error}
                fieldName="dependency"
                height="100px"
                label="Dependency"
                labelInfo="(required)"
                placeholder="Describe the dependency"
                setValueObject={setEngagementDependency}
                setValueVariable="dependency"
                value={engagementDependency.dependency}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Capture additional information</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementDependencyCreate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementDependency}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementDependency.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={engagementDependencyCreate.error}
                fieldName="notes"
                placeholder="Enter your notes"
                setValueObject={setEngagementDependency}
                setValueVariable="notes"
                value={engagementDependency.notes}
              />
            </Card>
          </div>
        }
        error={engagementDependencyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add dependency"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing dependencies"
        icon="edit"
        isOpen={addDependencyDrawerIsOpen}
        onSave={addDependency}
        refreshList={() => {
          dispatch(listEngagementDependenciesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementDependencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementDependencyCreate.success}
        saveSuccessMessage="Dependency added."
        saving={engagementDependencyCreate.loading}
        setIsOpen={setAddDependencyDrawerIsOpen}
        title="Add dependency"
      />

      {/* Existing dependency form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the dependency</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={engagementDependencyUpdate.error}
                    fieldName="name"
                    icon="flows"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the dependency a title"
                    setValueObject={setEngagementDependency}
                    setValueVariable="name"
                    value={engagementDependency.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Dependency */}
              <FormDrawerTextAreaField3
                error={engagementDependencyCreate.error}
                fieldName="dependency"
                height="100px"
                label="Dependency"
                labelInfo="(required)"
                placeholder="Describe the dependency"
                setValueObject={setEngagementDependency}
                setValueVariable="dependency"
                value={engagementDependency.dependency}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Capture additional information</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={engagementDependencyUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementDependency}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={engagementDependency.ownerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementDependencyUpdate.error}
                    fieldName="status"
                    intent={
                      engagementDependency.status === "Closed"
                        ? "success"
                        : "danger"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "issue-closed",
                        value: "Closed",
                      },
                      {
                        id: 2,
                        icon: "issue",
                        value: "Open",
                      },
                    ]}
                    setValueObject={setEngagementDependency}
                    setValueVariable="status"
                    value={engagementDependency.status}
                  />
                </div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={engagementDependencyUpdate.error}
                fieldName="notes"
                placeholder="Enter your notes"
                setValueObject={setEngagementDependency}
                setValueVariable="notes"
                value={engagementDependency.notes}
              />
            </Card>
          </div>
        }
        deleteSuccess={engagementDependencyDelete.success}
        deleteSuccessMessage="Dependency removed."
        deleting={engagementDependencyDelete.loading}
        error={engagementDependencyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update dependency"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing dependencies"
        icon="edit"
        isOpen={updateDependencyDrawerIsOpen}
        loading={engagementDependencyDetails.loading}
        onDelete={deleteDependency}
        onSave={updateDependency}
        refreshList={() => {
          dispatch(
            listEngagementDependenciesAction({
              ...engagementDependencyQuery,
            })
          );

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementDependencyAction());

          dispatch(resetUpdateEngagementDependencyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementDependencyUpdate.success}
        saveSuccessMessage="Dependency updated."
        saving={engagementDependencyUpdate.loading}
        setIsOpen={setUpdateDependencyDrawerIsOpen}
        title="Update dependency"
      />

      {/* Needs analysis form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Callout intent="primary" style={{ margin: "0 0 15px 0" }}>
              advaise.app can import the content of this section into the
              engagement charter. You can use the content as-is or ask the
              system to rewrite it.
            </Callout>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Understand the change drivers</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Change drivers */}
              <FormDrawerTextAreaField3
                error={engagementUpdate.error}
                fieldName="change_drivers"
                label="What is driving the client to change?"
                placeholder="Describe the impact of external (competitor, customer, regulator,
                    supplier) or internal (cultural, financial, operational,
                    technological) events on the client."
                setValueObject={setEngagement}
                setValueVariable="changeDrivers"
                value={engagement.changeDrivers}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify strategic options</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Strategic options */}
              <FormDrawerTextAreaField3
                error={engagementUpdate.error}
                fieldName="strategic_options"
                label="What options has the client explored?"
                placeholder="Identify strategic options that the client has evaluated, such as market development,
                  product development, process improvement, joint venture,
                  horizontal/vertical integration, divestment, or restructure."
                setValueObject={setEngagement}
                setValueVariable="strategicOptions"
                value={engagement.strategicOptions}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe the client's strategic choice</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Theory of change */}
              <FormDrawerTextAreaField3
                error={engagementUpdate.error}
                fieldName="theory_of_change"
                label="What has the client decided to do?"
                placeholder="Identify and justify the client's response."
                setValueObject={setEngagement}
                setValueVariable="theoryOfChange"
                value={engagement.theoryOfChange}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Identify issues</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Issues */}
              <FormDrawerTextAreaField3
                error={engagementUpdate.error}
                fieldName="issues"
                label="What challenges face the client in moving forward?"
                placeholder="Describe key success factors to implement the client's strategy."
                setValueObject={setEngagement}
                setValueVariable="issues"
                value={engagement.issues}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Outline the project's investment thesis</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* The project */}
              <FormDrawerTextAreaField3
                error={engagementUpdate.error}
                fieldName="our_solution"
                label="How will this project support the change?"
                placeholder="Describe the rationale for the project as a means to successfully executing the client's plans."
                setValueObject={setEngagement}
                setValueVariable="ourSolution"
                value={engagement.ourSolution}
              />
            </Card>
          </div>
        }
        error={engagementUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Analyse needs"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Analysing needs"
        icon="edit"
        isOpen={updateNeedsAnalysisDrawerIsOpen}
        loading={engagementDetails.loading}
        onSave={updateNeedsAnalysis}
        refreshList={() => {
          dispatch(listEngagementDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateEngagementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementUpdate.success}
        saveSuccessMessage="Engagement updated."
        saving={engagementUpdate.loading}
        setIsOpen={setUpdateNeedsAnalysisDrawerIsOpen}
        title="Analyse needs"
      />

      {/* New engagement contact form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the stakeholder management strategy</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Contact */}
                <FormDrawerSelectField2
                  allowCreate
                  error={engagementContactCreate.error}
                  fieldName="contact"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={engagementContact.contact}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Contact"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementContact}
                  setValueIdVariable="contact"
                  setValueNameVariable="contactName"
                  showInfo={engagementContact.contact}
                  value={engagementContact.contactName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Relationship owner */}
                <FormDrawerSelectField2
                  error={engagementContactCreate.error}
                  fieldName="relationship_owner"
                  icon="user"
                  items={managerList.managers}
                  label="Relationship owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select a relationship owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementContact}
                  setValueIdVariable="relationshipOwner"
                  setValueNameVariable="relationshipOwnerName"
                  value={engagementContact.relationshipOwnerName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Level of influence */}
                <FormDrawerSliderField
                  error={engagementContactCreate.error}
                  fieldName="level_of_influence"
                  intent={
                    engagementContact.levelOfInfluence > 4
                      ? "success"
                      : engagementContact.levelOfInfluence > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of influence"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setEngagementContact}
                  setValueVariable="levelOfInfluence"
                  value={engagementContact.levelOfInfluence}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level of support */}
                <FormDrawerSliderField
                  error={engagementContactCreate.error}
                  fieldName="level_of_support"
                  intent={
                    engagementContact.levelOfSupport > 4
                      ? "success"
                      : engagementContact.levelOfSupport > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of support"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setEngagementContact}
                  setValueVariable="levelOfSupport"
                  value={engagementContact.levelOfSupport}
                />
              </div>
            </div>

            {/* Expectations */}
            <FormDrawerTextAreaField3
              error={engagementContactCreate.error}
              fieldName="expectations"
              label="Expectations"
              placeholder="Describe their expectations of the engagement"
              setValueObject={setEngagementContact}
              setValueVariable="expectations"
              value={engagementContact.expectations}
            />

            {/* Stakeholder management strategy */}
            <FormDrawerTextAreaField3
              error={engagementContactCreate.error}
              fieldName="comments"
              label="Stakeholder management strategy"
              placeholder="Describe how you will engage with them to address their needs"
              setValueObject={setEngagementContact}
              setValueVariable="comments"
              value={engagementContact.comments}
            />
          </Card>
        }
        error={engagementContactCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add stakeholder"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing stakeholders"
        icon="edit"
        isOpen={addEngagementContactDrawerIsOpen}
        onSave={addEngagementContact}
        refreshList={() => {
          dispatch(listEngagementContactsAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementContactCreate.success}
        saveSuccessMessage="Stakeholder added."
        saving={engagementContactCreate.loading}
        setIsOpen={setAddEngagementContactDrawerIsOpen}
        title="Add stakeholder"
      />

      {/* Existing engagement contact form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the stakeholder management strategy</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Contact */}
                <FormDrawerSelectField2
                  allowCreate
                  disabled
                  error={engagementContactUpdate.error}
                  fieldName="contact"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={engagementContact.contact}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Contact"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Update or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementContact}
                  setValueIdVariable="contact"
                  setValueNameVariable="contactName"
                  showInfo={engagementContact.contact}
                  value={engagementContact.contactName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Relationship owner */}
                <FormDrawerSelectField2
                  error={engagementContactUpdate.error}
                  fieldName="relationship_owner"
                  icon="user"
                  items={managerList.managers}
                  label="Relationship owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select a relationship owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementContact}
                  setValueIdVariable="relationshipOwner"
                  setValueNameVariable="relationshipOwnerName"
                  value={engagementContact.relationshipOwnerName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Level of influence */}
                <FormDrawerSliderField
                  error={engagementContactUpdate.error}
                  fieldName="level_of_influence"
                  intent={
                    engagementContact.levelOfInfluence > 4
                      ? "success"
                      : engagementContact.levelOfInfluence > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of influence"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setEngagementContact}
                  setValueVariable="levelOfInfluence"
                  value={engagementContact.levelOfInfluence}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Level of support */}
                <FormDrawerSliderField
                  error={engagementContactUpdate.error}
                  fieldName="level_of_support"
                  intent={
                    engagementContact.levelOfSupport > 4
                      ? "success"
                      : engagementContact.levelOfSupport > 2
                      ? "warning"
                      : "danger"
                  }
                  label="Level of support"
                  labelRenderer={(value) => {
                    switch (value) {
                      case 1:
                        return "Low";
                      case 5:
                        return "High";
                      default:
                        return null;
                    }
                  }}
                  labelStepSize={1}
                  margin="35px"
                  max={5}
                  min={1}
                  setValueObject={setEngagementContact}
                  setValueVariable="levelOfSupport"
                  value={engagementContact.levelOfSupport}
                />
              </div>
            </div>

            {/* Expectations */}
            <FormDrawerTextAreaField3
              error={engagementContactUpdate.error}
              fieldName="expectations"
              label="Expectations"
              placeholder="Describe their expectations of the engagement"
              setValueObject={setEngagementContact}
              setValueVariable="expectations"
              value={engagementContact.expectations}
            />

            {/* Stakeholder management strategy */}
            <FormDrawerTextAreaField3
              error={engagementContactUpdate.error}
              fieldName="comments"
              label="Stakeholder management strategy"
              placeholder="Describe how you will engage with them to address their needs"
              setValueObject={setEngagementContact}
              setValueVariable="comments"
              value={engagementContact.comments}
            />
          </Card>
        }
        deleteSuccess={engagementContactDelete.success}
        deleteSuccessMessage="Engagement stakeholder deleted."
        deleting={engagementContactDelete.loading}
        error={engagementContactUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update stakeholder"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing stakeholders"
        icon="edit"
        isOpen={updateEngagementContactDrawerIsOpen}
        loading={engagementContactDetails.loading}
        onDelete={deleteEngagementContact}
        onSave={updateEngagementContact}
        refreshList={() => {
          dispatch(listEngagementContactsAction({ ...engagementContactQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementContactAction());

          dispatch(resetUpdateEngagementContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementContactUpdate.success}
        saveSuccessMessage="Engagement stakeholder updated."
        saving={engagementContactUpdate.loading}
        setIsOpen={setUpdateEngagementContactDrawerIsOpen}
        title="Update stakeholder"
      />

      {/* Approach form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Summarise the engagement</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Approach summary */}
            <FormDrawerTextAreaField3
              error={engagementUpdate.error}
              fieldName="approach_summary"
              label="Approach summary"
              placeholder="Summarise the engagement approach"
              setValueObject={setEngagement}
              setValueVariable="approachSummary"
              value={engagement.approachSummary}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Approach diagram */}
                <FormDrawerFileField2
                  error={engagementUpdate.error}
                  fileName="approach_diagram"
                  label="Approach diagram"
                  placeholder="Upload the approach diagram"
                  setValueObject={setEngagement}
                  setValueFileVariable="approachDiagram"
                  setValueNameVariable="approachDiagramName"
                  valueFile={engagement.approachDiagram}
                  valueName={engagement.approachDiagramName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Approach diagram preview */}
            {engagement.approachDiagram ? (
              engagement.approachDiagram !==
              engagementDetails.engagement.approach_diagram ? (
                <TabSectionFieldImage
                  image={URL.createObjectURL(
                    new Blob([engagement.approachDiagram])
                  )}
                />
              ) : (
                <TabSectionFieldImage
                  image={engagementDetails.engagement.approach_diagram}
                />
              )
            ) : undefined}
          </Card>
        }
        error={engagementUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approach"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Defining the engagement method"
        icon="edit"
        isOpen={updateApproachDrawerIsOpen}
        loading={engagementDetails.loading}
        onSave={updateApproach}
        refreshList={() => {
          dispatch(listEngagementDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateEngagementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementUpdate.success}
        saveSuccessMessage="Engagement updated."
        saving={engagementUpdate.loading}
        setIsOpen={setUpdateApproachDrawerIsOpen}
        title="Update approach"
      />

      {/* New service form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                margin: !engagementService.service ? "0 0 10px 0" : undefined,
                width: "100%",
              }}
            >
              <H5>Import a standard service</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Service */}
                  <FormDrawerSelectField2
                    error={engagementServiceCreate.error}
                    fieldName="service"
                    icon="heat-grid"
                    info={
                      <ServiceInfo
                        serviceId={engagementService.service}
                        query={serviceQueryDefault}
                      />
                    }
                    items={serviceList.services}
                    label="Service"
                    loading={serviceList.loading}
                    noOptionOnClick
                    placeholder="Select a service"
                    query={serviceQuery.searchString}
                    setQueryObject={setServiceQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementService}
                    setValueIdVariable="service"
                    setValueNameVariable="serviceName"
                    showInfo={engagementService.service}
                    value={engagementService.serviceName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="primary">
                    You can import an approved service from your organisation's
                    service catalogue. This autocompletes service information,
                    including activities and deliverables.
                  </Callout>
                </div>
              </div>
            </Card>

            {!engagementService.service && (
              <Card>
                <H5>Define the service</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Service name */}
                    <FormDrawerTextField2
                      error={engagementServiceCreate.error}
                      fieldName="name"
                      icon="label"
                      label="Service name"
                      labelInfo="(required)"
                      placeholder="Enter the service's name"
                      setValueObject={setEngagementService}
                      setValueVariable="name"
                      value={engagementService.name}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}></div>
                </div>

                {/* Description */}
                <FormDrawerTextAreaField3
                  error={engagementServiceCreate.error}
                  fieldName="benefits"
                  label="Description"
                  placeholder="Describe the service"
                  setValueObject={setEngagementService}
                  setValueVariable="description"
                  value={engagementService.description}
                />

                {/* Benefits */}
                <FormDrawerTextAreaField3
                  error={engagementServiceCreate.error}
                  fieldName="benefits"
                  label="Benefits"
                  placeholder="Describe how the service will benefit the client"
                  setValueObject={setEngagementService}
                  setValueVariable="benefits"
                  value={engagementService.benefits}
                />
              </Card>
            )}
          </div>
        }
        error={engagementServiceCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add service"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adding services"
        icon="edit"
        isOpen={addServiceDrawerIsOpen}
        onSave={addService}
        refreshList={() => {
          dispatch(listEngagementServicesAction({ engagement: id }));

          dispatch(listEngagementServiceDeliverablesAction({ engagement: id }));

          dispatch(listEngagementDetailsAction(id));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementServiceCreate.success}
        saveSuccessMessage="Service added."
        saving={engagementServiceCreate.loading}
        setIsOpen={setAddServiceDrawerIsOpen}
        title="Add service"
      />

      {/* Existing service form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the service</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Service name */}
                <FormDrawerTextField2
                  error={engagementServiceUpdate.error}
                  fieldName="name"
                  icon="label"
                  label="Service name"
                  labelInfo="(required)"
                  placeholder="Enter the service's name"
                  setValueObject={setEngagementService}
                  setValueVariable="name"
                  value={engagementService.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={engagementServiceUpdate.error}
              fieldName="benefits"
              label="Description"
              placeholder="Describe the service"
              setValueObject={setEngagementService}
              setValueVariable="description"
              value={engagementService.description}
            />

            {/* Benefits */}
            <FormDrawerTextAreaField3
              error={engagementServiceUpdate.error}
              fieldName="benefits"
              label="Benefits"
              placeholder="Describe how the service will benefit the client"
              setValueObject={setEngagementService}
              setValueVariable="benefits"
              value={engagementService.benefits}
            />
          </Card>
        }
        deleteSuccess={engagementServiceDelete.success}
        deleteSuccessMessage="Service removed."
        deleting={engagementServiceDelete.loading}
        error={engagementServiceUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update service"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adding services"
        icon="edit"
        isOpen={updateServiceDrawerIsOpen}
        loading={engagementServiceDetails.loading}
        onDelete={deleteService}
        onSave={updateService}
        refreshList={() => {
          dispatch(listEngagementServicesAction({ ...engagementServiceQuery }));

          dispatch(listEngagementServiceDeliverablesAction({ engagement: id }));

          dispatch(listEngagementDetailsAction(id));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementServiceAction());

          dispatch(resetUpdateEngagementServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementServiceUpdate.success}
        saveSuccessMessage="Service updated."
        saving={engagementServiceUpdate.loading}
        setIsOpen={setUpdateServiceDrawerIsOpen}
        title="Update service"
      />

      {/* New deliverable form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Specify the deliverable</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={engagementServiceDeliverableCreate.error}
                  fieldName="service"
                  icon="heat-grid"
                  items={engagementServiceList.engagement_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={engagementServiceList.loading}
                  placeholder="Select a service"
                  query={engagementServiceQuery.searchString}
                  setQueryObject={setEngagementServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementServiceDeliverable}
                  setValueIdVariable="service"
                  setValueNameVariable="serviceName"
                  value={engagementServiceDeliverable.serviceName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Deliverable name */}
                <FormDrawerTextField2
                  error={engagementServiceDeliverableCreate.error}
                  fieldName="name"
                  label="Deliverable name"
                  labelInfo="(required)"
                  placeholder="Enter the deliverable's name"
                  setValueObject={setEngagementServiceDeliverable}
                  setValueVariable="name"
                  value={engagementServiceDeliverable.name}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Deliverable type */}
                <FormDrawerTextField2
                  error={engagementServiceDeliverableCreate.error}
                  fieldName="type"
                  helperText="For example: document, software, workshop"
                  icon="tag"
                  label="Deliverable type"
                  placeholder="Enter the deliverable's type"
                  setValueObject={setEngagementServiceDeliverable}
                  setValueVariable="type"
                  value={engagementServiceDeliverable.type}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                {/* Template */}
                <FormDrawerFileField2
                  error={engagementServiceDeliverableCreate.error}
                  fileName="template"
                  label="Template"
                  placeholder="Upload the template"
                  setValueObject={setEngagementServiceDeliverable}
                  setValueFileVariable="template"
                  setValueNameVariable="templateName"
                  valueFile={engagementServiceDeliverable.template}
                  valueName={engagementServiceDeliverable.templateName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={engagementServiceDeliverableCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the deliverable"
              setValueObject={setEngagementServiceDeliverable}
              setValueVariable="description"
              value={engagementServiceDeliverable.description}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Due */}
                <FormDrawerDateField2
                  error={engagementServiceDeliverableCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due"
                  placeholder="Enter the deliverable due date"
                  setValueObject={setEngagementServiceDeliverable}
                  setValueVariable="due"
                  value={engagementServiceDeliverable.due}
                />

                {/* Milestone */}
                <FormDrawerSelectField2
                  error={engagementServiceDeliverableCreate.error}
                  fieldName="milestone"
                  icon="star-empty"
                  items={engagementMilestoneList.engagement_milestones}
                  label="Milestone"
                  loading={engagementMilestoneList.loading}
                  placeholder="Select a milestone"
                  query={engagementMilestoneQuery.searchString}
                  setQueryObject={setEngagementMilestoneQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementServiceDeliverable}
                  setValueIdVariable="milestone"
                  setValueNameVariable="milestoneName"
                  value={engagementServiceDeliverable.milestoneName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                <Callout intent="primary">
                  If you link the deliverable to a milestone, the system will
                  update the milestone status as you make progress.
                </Callout>
              </div>
            </div>
          </Card>
        }
        error={engagementServiceDeliverableCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create deliverable"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing deliverables"
        icon="edit"
        isOpen={addDeliverableDrawerIsOpen}
        onSave={addDeliverable}
        refreshList={() => {
          dispatch(listEngagementServiceDeliverablesAction({ engagement: id }));

          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementMilestonesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementServiceDeliverableAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementServiceDeliverableCreate.success}
        saveSuccessMessage="Deliverable created."
        saving={engagementServiceDeliverableCreate.loading}
        setIsOpen={setAddDeliverableDrawerIsOpen}
        title="Create deliverable"
      />

      {/* Existing deliverable form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Specify the deliverable</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Service */}
                  <FormDrawerSelectField2
                    error={engagementServiceDeliverableUpdate.error}
                    fieldName="service"
                    icon="heat-grid"
                    items={engagementServiceList.engagement_services}
                    label="Service"
                    labelInfo="(required)"
                    loading={engagementServiceList.loading}
                    placeholder="Select a service"
                    query={engagementServiceQuery.searchString}
                    setQueryObject={setEngagementServiceQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementServiceDeliverable}
                    setValueIdVariable="service"
                    setValueNameVariable="serviceName"
                    value={engagementServiceDeliverable.serviceName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Deliverable name */}
                  <FormDrawerTextField2
                    error={engagementServiceDeliverableUpdate.error}
                    fieldName="name"
                    label="Deliverable name"
                    labelInfo="(required)"
                    placeholder="Enter the deliverable's name"
                    setValueObject={setEngagementServiceDeliverable}
                    setValueVariable="name"
                    value={engagementServiceDeliverable.name}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Deliverable type */}
                  <FormDrawerTextField2
                    error={engagementServiceDeliverableCreate.error}
                    fieldName="type"
                    helperText="For example: document, software, workshop"
                    icon="tag"
                    label="Deliverable type"
                    placeholder="Enter the deliverable's type"
                    setValueObject={setEngagementServiceDeliverable}
                    setValueVariable="type"
                    value={engagementServiceDeliverable.type}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}>
                  {/* Template */}
                  <FormDrawerFileField2
                    error={engagementServiceDeliverableUpdate.error}
                    fileName="template"
                    label="Template"
                    placeholder="Upload the template"
                    setValueObject={setEngagementServiceDeliverable}
                    setValueFileVariable="template"
                    setValueNameVariable="templateName"
                    valueFile={engagementServiceDeliverable.template}
                    valueName={engagementServiceDeliverable.templateName}
                  />
                </div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={engagementServiceDeliverableUpdate.error}
                fieldName="description"
                label="Description"
                placeholder="Describe the deliverable"
                setValueObject={setEngagementServiceDeliverable}
                setValueVariable="description"
                value={engagementServiceDeliverable.description}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Due */}
                  <FormDrawerDateField2
                    error={engagementServiceDeliverableUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due"
                    placeholder="Enter the deliverable due date"
                    setValueObject={setEngagementServiceDeliverable}
                    setValueVariable="due"
                    value={engagementServiceDeliverable.due}
                  />

                  {/* Milestone */}
                  <FormDrawerSelectField2
                    error={engagementServiceDeliverableUpdate.error}
                    fieldName="milestone"
                    icon="star-empty"
                    items={engagementMilestoneList.engagement_milestones}
                    label="Milestone"
                    loading={engagementMilestoneList.loading}
                    placeholder="Select a milestone"
                    query={engagementMilestoneQuery.searchString}
                    setQueryObject={setEngagementMilestoneQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementServiceDeliverable}
                    setValueIdVariable="milestone"
                    setValueNameVariable="milestoneName"
                    value={engagementServiceDeliverable.milestoneName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}>
                  <Callout intent="primary">
                    If you link the deliverable to a milestone, the system will
                    update the milestone status as you make progress.
                  </Callout>
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementServiceDeliverableUpdate.error}
                    fieldName="status"
                    intent={
                      engagementServiceDeliverable.status === "Accepted"
                        ? "success"
                        : engagementServiceDeliverable.status === "Delivered"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Accepted",
                      },
                      {
                        id: 2,
                        icon: "circle",
                        value: "Delivered",
                      },
                      {
                        id: 3,
                        icon: "refresh",
                        value: "Not delivered",
                      },
                    ]}
                    setValueObject={setEngagementServiceDeliverable}
                    setValueVariable="status"
                    value={engagementServiceDeliverable.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}></div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={engagementServiceDeliverableDelete.success}
        deleteSuccessMessage="Deliverable deleted."
        deleting={engagementServiceDeliverableDelete.loading}
        error={engagementServiceDeliverableUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update deliverable"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing deliverables"
        icon="edit"
        isOpen={updateDeliverableDrawerIsOpen}
        loading={engagementServiceDeliverableDetails.loading}
        onDelete={deleteDeliverable}
        onSave={updateDeliverable}
        refreshList={() => {
          dispatch(listEngagementServiceDeliverablesAction({ engagement: id }));

          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementMilestonesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementServiceDeliverableAction());

          dispatch(resetUpdateEngagementServiceDeliverableAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementServiceDeliverableUpdate.success}
        saveSuccessMessage="Deliverable updated."
        saving={engagementServiceDeliverableUpdate.loading}
        setIsOpen={setUpdateDeliverableDrawerIsOpen}
        title="Update deliverable"
      />

      {/* New activity form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the activity</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={engagementServiceActivityCreate.error}
                  fieldName="service"
                  icon="heat-grid"
                  items={engagementServiceList.engagement_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={engagementServiceList.loading}
                  placeholder="Select a service"
                  query={engagementServiceQuery.searchString}
                  setQueryObject={setEngagementServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementServiceActivity}
                  setValueIdVariable="service"
                  setValueNameVariable="serviceName"
                  value={engagementServiceActivity.serviceName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Activity name */}
                <FormDrawerTextField2
                  error={engagementServiceActivityCreate.error}
                  fieldName="name"
                  label="Activity name"
                  labelInfo="(required)"
                  placeholder="Enter the activity's name"
                  setValueObject={setEngagementServiceActivity}
                  setValueVariable="name"
                  value={engagementServiceActivity.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={engagementServiceActivityCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the activity"
              setValueObject={setEngagementServiceActivity}
              setValueVariable="description"
              value={engagementServiceActivity.description}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Start */}
                <FormDrawerDateField2
                  error={engagementServiceActivityCreate.error}
                  fieldName="start"
                  icon="calendar"
                  label="Start"
                  placeholder="Enter the activity start date"
                  setValueObject={setEngagementServiceActivity}
                  setValueVariable="start"
                  value={engagementServiceActivity.start}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* End */}
                <FormDrawerDateField2
                  error={engagementServiceActivityCreate.error}
                  fieldName="end"
                  icon="calendar"
                  label="End"
                  placeholder="Enter the activity end date"
                  setValueObject={setEngagementServiceActivity}
                  setValueVariable="end"
                  value={engagementServiceActivity.end}
                />{" "}
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}></div>

              <div style={{ marginLeft: "10px", width: "50%" }}></div>
            </div>
          </Card>
        }
        error={engagementServiceActivityCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create activity"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Scheduling activities"
        icon="edit"
        isOpen={addActivityDrawerIsOpen}
        onSave={addActivity}
        refreshList={() => {
          dispatch(listEngagementDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementServiceActivityAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementServiceActivityCreate.success}
        saveSuccessMessage="Activity created."
        saving={engagementServiceActivityCreate.loading}
        setIsOpen={setAddActivityDrawerIsOpen}
        title="Create activity"
      />

      {/* Existing activity form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Define the activity</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Service */}
                  <FormDrawerSelectField2
                    error={engagementServiceActivityUpdate.error}
                    fieldName="service"
                    icon="heat-grid"
                    items={engagementServiceList.engagement_services}
                    label="Service"
                    labelInfo="(required)"
                    loading={engagementServiceList.loading}
                    placeholder="Select a service"
                    query={engagementServiceQuery.searchString}
                    setQueryObject={setEngagementServiceQuery}
                    setQueryVariable="searchString"
                    setValueObject={setEngagementServiceActivity}
                    setValueIdVariable="service"
                    setValueNameVariable="serviceName"
                    value={engagementServiceActivity.serviceName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Activity name */}
                  <FormDrawerTextField2
                    error={engagementServiceActivityUpdate.error}
                    fieldName="name"
                    label="Activity name"
                    labelInfo="(required)"
                    placeholder="Enter the activity's name"
                    setValueObject={setEngagementServiceActivity}
                    setValueVariable="name"
                    value={engagementServiceActivity.name}
                  />
                </div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={engagementServiceActivityUpdate.error}
                fieldName="description"
                label="Description"
                placeholder="Describe the activity"
                setValueObject={setEngagementServiceActivity}
                setValueVariable="description"
                value={engagementServiceActivity.description}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Start */}
                  <FormDrawerDateField2
                    error={engagementServiceActivityUpdate.error}
                    fieldName="start"
                    icon="calendar"
                    label="Start"
                    placeholder="Enter the activity start date"
                    setValueObject={setEngagementServiceActivity}
                    setValueVariable="start"
                    value={engagementServiceActivity.start}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* End */}
                  <FormDrawerDateField2
                    error={engagementServiceActivityUpdate.error}
                    fieldName="end"
                    icon="calendar"
                    label="End"
                    placeholder="Enter the activity end date"
                    setValueObject={setEngagementServiceActivity}
                    setValueVariable="end"
                    value={engagementServiceActivity.end}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "80%" }}>
                  {/* Progress */}
                  <FormDrawerSliderField
                    error={engagementServiceActivityUpdate.error}
                    fieldName="progress"
                    label="Progress"
                    labelStepSize={10}
                    margin="50px"
                    max={100}
                    min={0}
                    setValueObject={setEngagementServiceActivity}
                    setValueVariable="progress"
                    value={engagementServiceActivity.progress}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}></div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={engagementServiceActivityDelete.success}
        deleteSuccessMessage="Activity deleted."
        deleting={engagementServiceActivityDelete.loading}
        error={engagementServiceActivityUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update activity"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Scheduling activities"
        icon="edit"
        isOpen={updateActivityDrawerIsOpen}
        loading={engagementServiceActivityDetails.loading}
        onDelete={deleteActivity}
        onSave={updateActivity}
        refreshList={() => {
          dispatch(listEngagementDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementServiceActivityAction());

          dispatch(resetUpdateEngagementServiceActivityAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementServiceActivityUpdate.success}
        saveSuccessMessage="Activity updated."
        saving={engagementServiceActivityUpdate.loading}
        setIsOpen={setUpdateActivityDrawerIsOpen}
        title="Update activity"
      />

      {/* New labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
                width: "66%",
              }}
            >
              <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
                <H5>Enter the labour expense details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Role */}
                    <FormDrawerSelectField2
                      error={engagementLabourExpenseCreate.error}
                      fieldName="role"
                      helperText="You can choose from your organisation's standard roles"
                      icon="people"
                      info={
                        <RoleInfo
                          roleId={engagementLabourExpense.role}
                          query={roleQueryDefault}
                        />
                      }
                      items={roleList.roles}
                      label="Role"
                      labelInfo="(required)"
                      loading={roleList.loading}
                      placeholder="Select a role"
                      query={roleQuery.searchString}
                      setQueryObject={setRoleQuery}
                      setQueryVariable="searchString"
                      setValueObject={setEngagementLabourExpense}
                      setValueIdVariable="role"
                      setValueNameVariable="roleName"
                      showInfo={engagementLabourExpense.role}
                      value={engagementLabourExpense.roleName}
                    />

                    {/* Service */}
                    <FormDrawerSelectField2
                      error={engagementLabourExpenseCreate.error}
                      fieldName="engagement_service"
                      icon="heat-grid"
                      info={
                        <EngagementServiceInfo
                          engagementServiceId={
                            engagementLabourExpense.engagementService
                          }
                          query={engagementServiceQueryDefault}
                        />
                      }
                      helperText="You can choose services that you have added to the engagement"
                      items={engagementServiceList.engagement_services}
                      label="Service"
                      labelInfo="(required)"
                      loading={engagementServiceList.loading}
                      placeholder="Select a service"
                      query={engagementServiceQuery.searchString}
                      setQueryObject={setEngagementServiceQuery}
                      setQueryVariable="searchString"
                      setValueObject={setEngagementLabourExpense}
                      setValueIdVariable="engagementService"
                      setValueNameVariable="engagementServiceName"
                      showInfo={engagementLabourExpense.engagementService}
                      value={
                        engagementLabourExpense.engagementServiceName.split(
                          " | "
                        )[0]
                      }
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Effort */}
                        <FormDrawerDurationField
                          error={engagementLabourExpenseCreate.error}
                          fieldName="minutes"
                          helperText="Expected effort in hours"
                          icon="stopwatch"
                          label="Effort"
                          min={0.1}
                          placeholder="Enter the number of working hours"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="minutes"
                          value={engagementLabourExpense.minutes}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {/* Margin */}
                        <FormDrawerNumericField2
                          error={engagementLabourExpenseCreate.error}
                          fieldName="margin_percentage"
                          helperText="Leave blank to use the organization's default margin"
                          icon="percentage"
                          label="Margin"
                          majorStepSize={20}
                          min={0}
                          minorStepSize={1}
                          placeholder="Enter the margin to apply"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="marginPercentage"
                          stepSize={10}
                          value={engagementLabourExpense.marginPercentage}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Notes */}
                    <FormDrawerTextAreaField3
                      error={engagementLabourExpenseCreate.error}
                      fieldName="notes"
                      label="Notes"
                      placeholder="Enter your notes on the estimate"
                      setValueObject={setEngagementLabourExpense}
                      setValueVariable="notes"
                      value={engagementLabourExpense.notes}
                    />
                  </div>
                </div>
              </Card>

              <Card style={{ flex: 1, width: "100%" }}>
                <H5>Enter the resource booking details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <>
                    <FormDrawerStaffFreeTimeSearch2
                      customiseDays={engagementLabourExpense.customiseDays}
                      detail={engagementLabourExpense.detail}
                      endDate={engagementLabourExpense.finish}
                      minutes={engagementLabourExpense.minutes}
                      setStaffQueryObject={setStaffQuery}
                      startDate={engagementLabourExpense.start}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "33%" }}>
                        {/* Start */}
                        <FormDrawerDateField2
                          error={engagementLabourExpenseCreate.error}
                          fieldName="start"
                          icon="calendar"
                          label="Start"
                          placeholder="Enter the role starting date"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="start"
                          value={engagementLabourExpense.start}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "33%",
                        }}
                      >
                        {/* Finish */}
                        <FormDrawerDateField2
                          error={engagementLabourExpenseCreate.error}
                          fieldName="finish"
                          icon="calendar"
                          label="Finish"
                          placeholder="Enter the role finishing date"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="finish"
                          value={engagementLabourExpense.finish}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          paddingTop: "30px",
                          width: "34%",
                        }}
                      >
                        {/* Customise days */}
                        <Switch
                          checked={
                            engagementLabourExpense.customiseDays &&
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === engagementLabourExpense.staff
                            ).length > 0
                          }
                          disabled={
                            !engagementLabourExpense.finish ||
                            !engagementLabourExpense.minutes ||
                            !engagementLabourExpense.staff ||
                            !engagementLabourExpense.start ||
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === engagementLabourExpense.staff
                            ).length === 0
                          }
                          label="Customise days"
                          onChange={() => {
                            setEngagementLabourExpense((prevState) => {
                              return {
                                ...prevState,
                                customiseDays:
                                  !engagementLabourExpense.customiseDays,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <FormDrawerLabourExpenseDaysField2
                      bookingProfile={
                        engagementLabourExpense.staff &&
                        staffList.staff.filter(
                          (staff) => staff.id === engagementLabourExpense.staff
                        ).length > 0
                          ? staffList.staff.filter(
                              (staff) =>
                                staff.id === engagementLabourExpense.staff
                            )[0].booking_profile
                          : undefined
                      }
                      customiseDays={engagementLabourExpense.customiseDays}
                      setValueObject={setEngagementLabourExpense}
                      valueObject={engagementLabourExpense}
                    />

                    <Divider
                      style={{
                        margin: engagementLabourExpense.customiseDays
                          ? "15px 0 15px 0"
                          : "0 0 15px 0",
                      }}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Staff */}
                        <FormDrawerSelectField2
                          error={engagementLabourExpenseCreate.error}
                          fieldName="staff"
                          icon="user"
                          info={
                            <StaffInfo
                              customCompetencyProfile={
                                engagementLabourExpense.competencies
                              }
                              query={staffQueryDefault}
                              staffId={engagementLabourExpense.staff}
                            />
                          }
                          items={staffList.staff}
                          label="Staff"
                          loading={staffList.loading}
                          noOptionOnClick
                          placeholder="Select a staff member"
                          query={staffQuery.searchString}
                          setQueryObject={setStaffQuery}
                          setQueryVariable="searchString"
                          setValueObject={setEngagementLabourExpense}
                          setValueIdVariable="staff"
                          setValueNameVariable="staffName"
                          showInfo={engagementLabourExpense.staff}
                          value={engagementLabourExpense.staffName}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {!staffList.loading && (
                          <Callout
                            intent={
                              engagementLabourExpense.staff
                                ? staffList.staff.filter(
                                    (staff) =>
                                      staff.id === engagementLabourExpense.staff
                                  ).length === 0
                                  ? "danger"
                                  : "success"
                                : staffList.count === 0
                                ? "danger"
                                : "success"
                            }
                            style={{ margin: "0 0 15px 0" }}
                          >
                            {engagementLabourExpense.staff
                              ? staffList.staff.filter(
                                  (staff) =>
                                    staff.id === engagementLabourExpense.staff
                                ).length === 0
                                ? "Your selected team member doesn't fit this role."
                                : "Your selected team member fits this role."
                              : staffList.count === 0
                              ? "The system found no suitable candidates."
                              : `The system found ${staffList.count} suitable candidate(s).`}
                          </Callout>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                width: "34%",
              }}
            >
              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the type of employment</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <>
                    {/* Employment */}
                    <FormDrawerStaffEmploymentSearch
                      employment={engagementLabourExpense.employment}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectSimpleField3
                      error={engagementLabourExpenseCreate.error}
                      fieldName="employment"
                      options={[
                        { id: 1, icon: "full-circle", value: "Permanent" },
                        {
                          id: 2,
                          icon: "ring",
                          value: "Contract",
                        },
                        { id: 3, icon: "circle", value: "Casual" },
                      ]}
                      setValueObject={setEngagementLabourExpense}
                      setValueVariable="employment"
                      value={engagementLabourExpense.employment}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the location</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <>
                    {/* Location */}
                    <FormDrawerStaffLocationSearch
                      location={engagementLabourExpense.location}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectField2
                      error={engagementLabourExpenseCreate.error}
                      fieldName="location"
                      icon="area-of-interest"
                      info={
                        <LocationInfo
                          locationId={engagementLabourExpense.location}
                          query={locationQueryDefault}
                        />
                      }
                      items={locationList.locations}
                      loading={locationList.loading}
                      noOptionOnClick
                      placeholder="Select a location"
                      query={locationQuery.searchString}
                      setQueryObject={setLocationQuery}
                      setQueryVariable="searchString"
                      setValueObject={setEngagementLabourExpense}
                      setValueIdVariable="location"
                      setValueNameVariable="locationName"
                      showInfo={engagementLabourExpense.location}
                      value={engagementLabourExpense.locationName}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  flex: 1,
                  width: "100%",
                }}
              >
                <H5>Review the skills needed</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <FormDrawerStaffCompetencySearch
                    competencies={engagementLabourExpense.competencies}
                    competencyList={competencyList}
                    competencyQuery={competencyQuery}
                    roleId={engagementLabourExpense.role}
                    setCompetenciesObject={setEngagementLabourExpense}
                    setCompetenciesVariable="competencies"
                    setCompetencyQuery={setCompetencyQuery}
                    setStaffQueryObject={setStaffQuery}
                  />
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>
          </div>
        }
        error={engagementLabourExpenseCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add labour expense"
            module="Engagements"
            view="Detail"
          />
        }
        height="90vh"
        helpTitle="Estimating labour expenses"
        icon="edit"
        isOpen={addLabourExpenseDrawerIsOpen}
        onSave={addLabourExpense}
        refreshList={() => {
          dispatch(listEngagementLabourExpensesAction({ engagement: id }));

          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementFeesAction({ engagement: id }));

          dispatch(
            listBookingsAction({
              engagement: id,
              organisation: activeOrganisation.id,
            })
          );

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementLabourExpenseCreate.success}
        saveSuccessMessage="Labour expense added."
        saving={engagementLabourExpenseCreate.loading}
        setIsOpen={setAddLabourExpenseDrawerIsOpen}
        size="large"
        title="Add labour expense"
      />

      {/* Existing labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "5px",
                width: "66%",
              }}
            >
              <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
                <H5>Enter the labour expense details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Role */}
                    <FormDrawerSelectField2
                      error={engagementLabourExpenseUpdate.error}
                      fieldName="role"
                      helperText="You can choose from your organisation's standard roles"
                      icon="people"
                      info={
                        <RoleInfo
                          roleId={engagementLabourExpense.role}
                          query={roleQueryDefault}
                        />
                      }
                      items={roleList.roles}
                      label="Role"
                      labelInfo="(required)"
                      loading={roleList.loading}
                      placeholder="Select a role"
                      query={roleQuery.searchString}
                      setQueryObject={setRoleQuery}
                      setQueryVariable="searchString"
                      setValueObject={setEngagementLabourExpense}
                      setValueIdVariable="role"
                      setValueNameVariable="roleName"
                      showInfo={engagementLabourExpense.role}
                      value={engagementLabourExpense.roleName}
                    />

                    {/* Service */}
                    <FormDrawerSelectField2
                      error={engagementLabourExpenseUpdate.error}
                      fieldName="engagement_service"
                      icon="heat-grid"
                      info={
                        <EngagementServiceInfo
                          engagementServiceId={
                            engagementLabourExpense.engagementService
                          }
                          query={engagementServiceQueryDefault}
                        />
                      }
                      helperText="You can choose services that you have added to the engagement"
                      items={engagementServiceList.engagement_services}
                      label="Service"
                      labelInfo="(required)"
                      loading={engagementServiceList.loading}
                      placeholder="Select a service"
                      query={engagementServiceQuery.searchString}
                      setQueryObject={setEngagementServiceQuery}
                      setQueryVariable="searchString"
                      setValueObject={setEngagementLabourExpense}
                      setValueIdVariable="engagementService"
                      setValueNameVariable="engagementServiceName"
                      showInfo={engagementLabourExpense.engagementService}
                      value={
                        engagementLabourExpense.engagementServiceName.split(
                          " | "
                        )[0]
                      }
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Effort */}
                        <FormDrawerDurationField
                          error={engagementLabourExpenseUpdate.error}
                          fieldName="minutes"
                          helperText="Expected effort in hours"
                          icon="stopwatch"
                          label="Effort"
                          min={0.1}
                          placeholder="Enter the number of working hours"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="minutes"
                          value={engagementLabourExpense.minutes}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {/* Margin */}
                        <FormDrawerNumericField2
                          error={engagementLabourExpenseUpdate.error}
                          fieldName="margin_percentage"
                          helperText="Leave blank to use the organization's default margin"
                          icon="percentage"
                          label="Margin"
                          majorStepSize={20}
                          min={0}
                          minorStepSize={1}
                          placeholder="Enter the margin to apply"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="marginPercentage"
                          stepSize={10}
                          value={engagementLabourExpense.marginPercentage}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Notes */}
                    <FormDrawerTextAreaField3
                      error={engagementLabourExpenseUpdate.error}
                      fieldName="notes"
                      label="Notes"
                      placeholder="Enter your notes on the estimate"
                      setValueObject={setEngagementLabourExpense}
                      setValueVariable="notes"
                      value={engagementLabourExpense.notes}
                    />
                  </div>
                </div>
              </Card>

              <Card style={{ flex: 1, width: "100%" }}>
                <H5>Enter the resource booking details</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <>
                    <FormDrawerStaffFreeTimeSearch2
                      customiseDays={engagementLabourExpense.customiseDays}
                      detail={engagementLabourExpense.detail}
                      endDate={engagementLabourExpense.finish}
                      minutes={engagementLabourExpense.minutes}
                      setStaffQueryObject={setStaffQuery}
                      startDate={engagementLabourExpense.start}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "33%" }}>
                        {/* Start */}
                        <FormDrawerDateField2
                          error={engagementLabourExpenseUpdate.error}
                          fieldName="start"
                          icon="calendar"
                          label="Start"
                          placeholder="Enter the role starting date"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="start"
                          value={engagementLabourExpense.start}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          width: "33%",
                        }}
                      >
                        {/* Finish */}
                        <FormDrawerDateField2
                          error={engagementLabourExpenseUpdate.error}
                          fieldName="finish"
                          icon="calendar"
                          label="Finish"
                          placeholder="Enter the role finishing date"
                          setValueObject={setEngagementLabourExpense}
                          setValueVariable="finish"
                          value={engagementLabourExpense.finish}
                        />
                      </div>

                      <div
                        style={{
                          marginLeft: "10px",
                          paddingTop: "30px",
                          width: "34%",
                        }}
                      >
                        {/* Customise days */}
                        <Switch
                          checked={
                            engagementLabourExpense.customiseDays &&
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === engagementLabourExpense.staff
                            ).length > 0
                          }
                          disabled={
                            !engagementLabourExpense.finish ||
                            !engagementLabourExpense.minutes ||
                            !engagementLabourExpense.staff ||
                            !engagementLabourExpense.start ||
                            staffList.staff.filter(
                              (staff) =>
                                staff.id === engagementLabourExpense.staff
                            ).length === 0
                          }
                          label="Customise days"
                          onChange={() => {
                            setEngagementLabourExpense((prevState) => {
                              return {
                                ...prevState,
                                customiseDays:
                                  !engagementLabourExpense.customiseDays,
                              };
                            });
                          }}
                        />
                      </div>
                    </div>

                    <FormDrawerLabourExpenseDaysField2
                      bookingProfile={
                        engagementLabourExpense.staff &&
                        staffList.staff.filter(
                          (staff) => staff.id === engagementLabourExpense.staff
                        ).length > 0
                          ? staffList.staff.filter(
                              (staff) =>
                                staff.id === engagementLabourExpense.staff
                            )[0].booking_profile
                          : undefined
                      }
                      customiseDays={engagementLabourExpense.customiseDays}
                      setValueObject={setEngagementLabourExpense}
                      valueObject={engagementLabourExpense}
                    />

                    <Divider
                      style={{
                        margin: engagementLabourExpense.customiseDays
                          ? "15px 0 15px 0"
                          : "0 0 15px 0",
                      }}
                    />

                    <div style={{ display: "flex" }}>
                      <div style={{ marginRight: "10px", width: "50%" }}>
                        {/* Staff */}
                        <FormDrawerSelectField2
                          error={engagementLabourExpenseCreate.error}
                          fieldName="staff"
                          icon="user"
                          info={
                            <StaffInfo
                              customCompetencyProfile={
                                engagementLabourExpense.competencies
                              }
                              query={staffQueryDefault}
                              staffId={engagementLabourExpense.staff}
                            />
                          }
                          items={staffList.staff}
                          label="Staff"
                          loading={staffList.loading}
                          noOptionOnClick
                          placeholder="Select a staff member"
                          query={staffQuery.searchString}
                          setQueryObject={setStaffQuery}
                          setQueryVariable="searchString"
                          setValueObject={setEngagementLabourExpense}
                          setValueIdVariable="staff"
                          setValueNameVariable="staffName"
                          showInfo={engagementLabourExpense.staff}
                          value={engagementLabourExpense.staffName}
                        />
                      </div>

                      <div style={{ marginLeft: "10px", width: "50%" }}>
                        {!staffList.loading && (
                          <Callout
                            intent={
                              engagementLabourExpense.staff
                                ? staffList.staff.filter(
                                    (staff) =>
                                      staff.id === engagementLabourExpense.staff
                                  ).length === 0
                                  ? "danger"
                                  : "success"
                                : staffList.count === 0
                                ? "danger"
                                : "success"
                            }
                            style={{ margin: "0 0 15px 0" }}
                          >
                            {engagementLabourExpense.staff
                              ? staffList.staff.filter(
                                  (staff) =>
                                    staff.id === engagementLabourExpense.staff
                                ).length === 0
                                ? "Your selected team member doesn't fit this role."
                                : "Your selected team member fits this role."
                              : staffList.count === 0
                              ? "The system found no suitable candidates."
                              : `The system found ${staffList.count} suitable candidate(s).`}
                          </Callout>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
                width: "34%",
              }}
            >
              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the type of employment</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <>
                    {/* Employment */}
                    <FormDrawerStaffEmploymentSearch
                      employment={engagementLabourExpense.employment}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectSimpleField3
                      error={engagementLabourExpenseUpdate.error}
                      fieldName="employment"
                      options={[
                        { id: 1, icon: "full-circle", value: "Permanent" },
                        {
                          id: 2,
                          icon: "ring",
                          value: "Contract",
                        },
                        { id: 3, icon: "circle", value: "Casual" },
                      ]}
                      setValueObject={setEngagementLabourExpense}
                      setValueVariable="employment"
                      value={engagementLabourExpense.employment}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  margin: "0 0 10px 0",
                  width: "100%",
                }}
              >
                <H5>Select the location</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <>
                    {/* Location */}
                    <FormDrawerStaffLocationSearch
                      location={engagementLabourExpense.location}
                      setStaffQueryObject={setStaffQuery}
                    />

                    <FormDrawerSelectField2
                      error={engagementLabourExpenseUpdate.error}
                      fieldName="location"
                      icon="area-of-interest"
                      info={
                        <LocationInfo
                          locationId={engagementLabourExpense.location}
                          query={locationQueryDefault}
                        />
                      }
                      items={locationList.locations}
                      loading={locationList.loading}
                      noOptionOnClick
                      placeholder="Select a location"
                      query={locationQuery.searchString}
                      setQueryObject={setLocationQuery}
                      setQueryVariable="searchString"
                      setValueObject={setEngagementLabourExpense}
                      setValueIdVariable="location"
                      setValueNameVariable="locationName"
                      showInfo={engagementLabourExpense.location}
                      value={engagementLabourExpense.locationName}
                    />
                  </>
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>

              <Card
                style={{
                  flex: 1,
                  width: "100%",
                }}
              >
                <H5>Review the skills needed</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                {engagementLabourExpense.role ? (
                  <FormDrawerStaffCompetencySearch
                    competencies={engagementLabourExpense.competencies}
                    competencyList={competencyList}
                    competencyQuery={competencyQuery}
                    roleId={engagementLabourExpense.role}
                    setCompetenciesObject={setEngagementLabourExpense}
                    setCompetenciesVariable="competencies"
                    setCompetencyQuery={setCompetencyQuery}
                    setStaffQueryObject={setStaffQuery}
                  />
                ) : (
                  <div
                    style={{
                      margin: "20px 0 20px 0",
                    }}
                  >
                    <NonIdealState icon="people" title="Select a role" />
                  </div>
                )}
              </Card>
            </div>
          </div>
        }
        cannotDelete={
          engagementLabourExpenseDetails.engagement_labour_expense.cannot_delete
        }
        deleteSuccess={engagementLabourExpenseDelete.success}
        deleteSuccessMessage="Labour expense removed."
        deleting={engagementLabourExpenseDelete.loading}
        error={engagementLabourExpenseUpdate.error}
        height="90vh"
        helpContent={
          <FormDrawerHelpContainer
            form="Update labour expense"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Estimating labour expenses"
        icon="edit"
        isOpen={updateLabourExpenseDrawerIsOpen}
        loading={engagementLabourExpenseDetails.loading}
        onDelete={deleteLabourExpense}
        onSave={updateLabourExpense}
        refreshList={() => {
          dispatch(
            listEngagementLabourExpensesAction({
              ...engagementLabourExpenseQuery,
            })
          );

          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementFeesAction({ engagement: id }));

          dispatch(
            listBookingsAction({
              engagement: id,
              organisation: activeOrganisation.id,
            })
          );

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementLabourExpenseAction());

          dispatch(resetUpdateEngagementLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementLabourExpenseUpdate.success}
        saveSuccessMessage="Labour expense updated."
        saving={engagementLabourExpenseUpdate.loading}
        setIsOpen={setUpdateLabourExpenseDrawerIsOpen}
        size="large"
        title="Update labour expense"
      />

      {/* New non-labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the non-labour expense details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectField2
                  error={engagementNonLabourExpenseCreate.error}
                  fieldName="category"
                  icon="bank-account"
                  info={
                    <ExpenseCategoryInfo
                      categoryId={engagementNonLabourExpense.category}
                      query={expenseCategoryQueryDefault}
                    />
                  }
                  items={expenseCategoryList.expense_categories}
                  label="Category"
                  labelInfo="(required)"
                  loading={expenseCategoryList.loading}
                  placeholder="Select a category"
                  query={expenseCategoryQuery.searchString}
                  setQueryObject={setExpenseCategoryQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueIdVariable="category"
                  setValueNameVariable="categoryName"
                  showInfo={engagementNonLabourExpense.category}
                  value={engagementNonLabourExpense.categoryName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={engagementNonLabourExpenseCreate.error}
                  fieldName="engagement_service"
                  icon="heat-grid"
                  helperText="You can choose services that you have added to the engagement"
                  items={engagementServiceList.engagement_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={engagementServiceList.loading}
                  placeholder="Select a service"
                  query={engagementServiceQuery.searchString}
                  setQueryObject={setEngagementServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueIdVariable="engagementService"
                  setValueNameVariable="engagementServiceName"
                  value={
                    engagementNonLabourExpense.engagementServiceName.split(
                      " | "
                    )[0]
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Cost */}
                <FormDrawerNumericField2
                  error={engagementNonLabourExpenseCreate.error}
                  fieldName="cost"
                  label="Cost"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={10000}
                  min={0}
                  minorStepSize={100}
                  placeholder="Enter the amount"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueVariable="cost"
                  stepSize={1000}
                  value={engagementNonLabourExpense.cost}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Margin */}
                <FormDrawerNumericField2
                  error={engagementNonLabourExpenseCreate.error}
                  fieldName="margin_percentage"
                  helperText="Leave blank to use the organization's default margin"
                  icon="percentage"
                  label="Margin"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the margin to apply"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueVariable="marginPercentage"
                  stepSize={10}
                  value={engagementNonLabourExpense.marginPercentage}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={engagementNonLabourExpenseCreate.error}
              fieldName="notes"
              label="Assumptions"
              placeholder="Enter your notes on the estimate"
              setValueObject={setEngagementNonLabourExpense}
              setValueVariable="notes"
              value={engagementNonLabourExpense.notes}
            />
          </Card>
        }
        error={engagementNonLabourExpenseCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add non-labour expense"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adding non-labour expenses"
        icon="edit"
        isOpen={addNonLabourExpenseDrawerIsOpen}
        onSave={addNonLabourExpense}
        refreshList={() => {
          dispatch(listEngagementNonLabourExpensesAction({ engagement: id }));

          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementFeesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementNonLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementNonLabourExpenseCreate.success}
        saveSuccessMessage="Non-labour expense added."
        saving={engagementNonLabourExpenseCreate.loading}
        setIsOpen={setAddNonLabourExpenseDrawerIsOpen}
        title="Add non-labour expense"
      />

      {/* Existing non-labour expense form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the non-labour expense details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Category */}
                <FormDrawerSelectField2
                  error={engagementNonLabourExpenseUpdate.error}
                  fieldName="category"
                  icon="bank-account"
                  info={
                    <ExpenseCategoryInfo
                      categoryId={engagementNonLabourExpense.category}
                      query={expenseCategoryQueryDefault}
                    />
                  }
                  items={expenseCategoryList.expense_categories}
                  label="Category"
                  labelInfo="(required)"
                  loading={expenseCategoryList.loading}
                  placeholder="Select a category"
                  query={expenseCategoryQuery.searchString}
                  setQueryObject={setExpenseCategoryQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueIdVariable="category"
                  setValueNameVariable="categoryName"
                  showInfo={engagementNonLabourExpense.category}
                  value={engagementNonLabourExpense.categoryName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Service */}
                <FormDrawerSelectField2
                  error={engagementNonLabourExpenseUpdate.error}
                  fieldName="engagement_service"
                  icon="heat-grid"
                  helperText="You can choose services that you have added to the engagement"
                  items={engagementServiceList.engagement_services}
                  label="Service"
                  labelInfo="(required)"
                  loading={engagementServiceList.loading}
                  placeholder="Select a service"
                  query={engagementServiceQuery.searchString}
                  setQueryObject={setEngagementServiceQuery}
                  setQueryVariable="searchString"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueIdVariable="engagementService"
                  setValueNameVariable="engagementServiceName"
                  value={
                    engagementNonLabourExpense.engagementServiceName.split(
                      " | "
                    )[0]
                  }
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Cost */}
                <FormDrawerNumericField2
                  error={engagementNonLabourExpenseUpdate.error}
                  fieldName="cost"
                  label="Cost"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={10000}
                  min={0}
                  minorStepSize={100}
                  placeholder="Enter the amount"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueVariable="cost"
                  stepSize={1000}
                  value={engagementNonLabourExpense.cost}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Margin */}
                <FormDrawerNumericField2
                  error={engagementNonLabourExpenseUpdate.error}
                  fieldName="margin_percentage"
                  helperText="Leave blank to use the organization's default margin"
                  icon="percentage"
                  label="Margin"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the margin to apply"
                  setValueObject={setEngagementNonLabourExpense}
                  setValueVariable="marginPercentage"
                  stepSize={10}
                  value={engagementNonLabourExpense.marginPercentage}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={engagementNonLabourExpenseUpdate.error}
              fieldName="notes"
              label="Assumptions"
              placeholder="Enter your notes on the estimate"
              setValueObject={setEngagementNonLabourExpense}
              setValueVariable="notes"
              value={engagementNonLabourExpense.notes}
            />
          </Card>
        }
        deleteSuccess={engagementNonLabourExpenseDelete.success}
        deleteSuccessMessage="Non-labour expense removed."
        deleting={engagementNonLabourExpenseDelete.loading}
        error={engagementNonLabourExpenseUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update non-labour expense"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adding non-labour expenses"
        icon="edit"
        isOpen={updateNonLabourExpenseDrawerIsOpen}
        loading={engagementNonLabourExpenseDetails.loading}
        onDelete={deleteNonLabourExpense}
        onSave={updateNonLabourExpense}
        refreshList={() => {
          dispatch(
            listEngagementNonLabourExpensesAction({
              ...engagementLabourExpenseQuery,
            })
          );

          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementFeesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementNonLabourExpenseAction());

          dispatch(resetUpdateEngagementNonLabourExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementNonLabourExpenseUpdate.success}
        saveSuccessMessage="Non-labour expense updated."
        saving={engagementNonLabourExpenseUpdate.loading}
        setIsOpen={setUpdateNonLabourExpenseDrawerIsOpen}
        title="Update non-labour expense"
      />

      {/* Existing engagement fee form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Adjust the fee</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {Math.abs(
              engagementFeeList.total_fees -
                engagementMilestoneList.totalValueOfMilestones
            ) >= 0.01 && (
              <Callout
                intent="primary"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "0 0 15px 0",
                }}
              >
                <div style={{ marginRight: "5px" }}>The total fee value is</div>

                <NumericValue
                  currency={activeOrganisation.currency}
                  noTag
                  number={Math.abs(
                    engagementFeeList.total_fees -
                      engagementMilestoneList.totalValueOfMilestones
                  )}
                />

                <div style={{ margin: "0 5px 0 5px" }}>
                  {engagementFeeList.total_fees -
                    engagementMilestoneList.totalValueOfMilestones >
                  0
                    ? "higher"
                    : "lower"}
                </div>

                <div>that the total milestone value.</div>
              </Callout>
            )}

            {/* Adjustment */}
            <FormDrawerNumericField2
              error={engagementFeeUpdate.error}
              fieldName="adjustment"
              label="Adjustment"
              leftElement={
                <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                  {activeOrganisation.currency}
                </Tag>
              }
              majorStepSize={100000}
              minorStepSize={1000}
              placeholder="Enter the adjustment value"
              setValueObject={setEngagementFee}
              setValueVariable="adjustment"
              stepSize={10000}
              value={engagementFee.adjustment}
            />
          </Card>
        }
        error={engagementFeeUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update fee"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Adjusting fees"
        icon="edit"
        isOpen={updateFeeDrawerIsOpen}
        loading={engagementFeeDetails.loading}
        onSave={updateEngagementFee}
        refreshList={() => {
          dispatch(listEngagementFeesAction({ ...engagementFeeQuery }));

          dispatch(listEngagementDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateEngagementFeeAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementFeeUpdate.success}
        saveSuccessMessage="Engagement fee updated."
        saving={engagementFeeUpdate.loading}
        setIsOpen={setUpdateFeeDrawerIsOpen}
        size="small"
        title="Update fee"
      />

      {/* New milestone form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the milestone</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={engagementMilestoneCreate.error}
                  fieldName="name"
                  icon="star-empty"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Give the milestone a name"
                  setValueObject={setEngagementMilestone}
                  setValueVariable="name"
                  value={engagementMilestone.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={engagementMilestoneCreate.error}
                  fieldName="value"
                  label="Value"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the milestone value"
                  setValueObject={setEngagementMilestone}
                  setValueVariable="value"
                  stepSize={10000}
                  value={engagementMilestone.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={engagementMilestoneCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the milestone due date"
                  setValueObject={setEngagementMilestone}
                  setValueVariable="due"
                  value={engagementMilestone.due}
                />
              </div>
            </div>
            {/* Description */}
            <FormDrawerTextAreaField3
              error={engagementMilestoneCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Describe the milestone"
              setValueObject={setEngagementMilestone}
              setValueVariable="description"
              value={engagementMilestone.description}
            />
          </Card>
        }
        error={engagementMilestoneCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add milestone"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing payment milestones"
        icon="edit"
        isOpen={addMilestoneDrawerIsOpen}
        onSave={addMilestone}
        refreshList={() => {
          dispatch(listEngagementMilestonesAction({ engagement: id }));

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementMilestoneAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementMilestoneCreate.success}
        saveSuccessMessage="Milestone added."
        saving={engagementMilestoneCreate.loading}
        setIsOpen={setAddMilestoneDrawerIsOpen}
        title="Add milestone"
      />

      {/* Existing milestone form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the milestone</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Name */}
                  <FormDrawerTextField2
                    error={engagementMilestoneUpdate.error}
                    fieldName="name"
                    icon="star-empty"
                    label="Name"
                    labelInfo="(required)"
                    placeholder="Give the milestone a name"
                    setValueObject={setEngagementMilestone}
                    setValueVariable="name"
                    value={engagementMilestone.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Value */}
                  <FormDrawerNumericField2
                    error={engagementMilestoneUpdate.error}
                    fieldName="value"
                    label="Value"
                    labelInfo="(required)"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {activeOrganisation.currency}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the milestone value"
                    setValueObject={setEngagementMilestone}
                    setValueVariable="value"
                    stepSize={10000}
                    value={engagementMilestone.value}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={engagementMilestoneUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the milestone due date"
                    setValueObject={setEngagementMilestone}
                    setValueVariable="due"
                    value={engagementMilestone.due}
                  />
                </div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={engagementMilestoneUpdate.error}
                fieldName="description"
                label="Description"
                placeholder="Describe the milestone"
                setValueObject={setEngagementMilestone}
                setValueVariable="description"
                value={engagementMilestone.description}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "33%" }}>
                  {/* Completion date */}
                  <FormDrawerDateField2
                    error={engagementMilestoneUpdate.error}
                    fieldName="completed"
                    helperText="Date when the client has accepted all milestone deliverables"
                    icon="calendar"
                    label="Completion date"
                    placeholder="Enter the completion date"
                    setValueObject={setEngagementMilestone}
                    setValueVariable="completed"
                    value={engagementMilestone.completed}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "33%",
                  }}
                >
                  {/* Invoice date */}
                  <FormDrawerDateField2
                    error={engagementMilestoneUpdate.error}
                    fieldName="invoiced"
                    helperText="Date when you have issued the invoice"
                    icon="calendar"
                    label="Invoice date"
                    placeholder="Enter the invoiced date"
                    setValueObject={setEngagementMilestone}
                    setValueVariable="invoiced"
                    value={engagementMilestone.invoiced}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "33%" }}>
                  {/* Paid date */}
                  <FormDrawerDateField2
                    error={engagementMilestoneUpdate.error}
                    fieldName="paid"
                    helperText="Date when the client has paid the invoice"
                    icon="calendar"
                    label="Paid date"
                    placeholder="Enter the paid date"
                    setValueObject={setEngagementMilestone}
                    setValueVariable="paid"
                    value={engagementMilestone.paid}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={engagementMilestoneUpdate.error}
                    fieldName="status"
                    intent={
                      engagementMilestone.status === "Completed"
                        ? "primary"
                        : engagementMilestone.status === "Invoiced"
                        ? "warning"
                        : engagementMilestone.status === "Not completed"
                        ? "danger"
                        : "success"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "build",
                        value: "Not completed",
                      },
                      {
                        id: 2,
                        icon: "cargo-ship",
                        value: "Completed",
                      },
                      {
                        id: 3,
                        icon: "document-share",
                        value: "Invoiced",
                      },
                      {
                        id: 4,
                        icon: "dollar",
                        value: "Paid",
                      },
                    ]}
                    setValueObject={setEngagementMilestone}
                    setValueVariable="status"
                    value={engagementMilestone.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>
          </div>
        }
        cannotDelete={
          engagementMilestoneDetails.engagement_milestone.cannot_delete
        }
        deleteSuccess={engagementMilestoneDelete.success}
        deleteSuccessMessage="Milestone removed."
        deleting={engagementMilestoneDelete.loading}
        error={engagementMilestoneUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update milestone"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Managing payment milestones"
        icon="edit"
        isOpen={updateMilestoneDrawerIsOpen}
        loading={engagementMilestoneDetails.loading}
        onDelete={deleteMilestone}
        onSave={updateMilestone}
        refreshList={() => {
          dispatch(
            listEngagementMilestonesAction({ ...engagementMilestoneQuery })
          );

          dispatch(generateReportDataEngagementAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementMilestoneAction());

          dispatch(resetUpdateEngagementMilestoneAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementMilestoneUpdate.success}
        saveSuccessMessage="Milestone updated."
        saving={engagementMilestoneUpdate.loading}
        setIsOpen={setUpdateMilestoneDrawerIsOpen}
        title="Update milestone"
      />

      {/* New rate form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the rate details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Role */}
            <FormDrawerSelectField2
              error={engagementRateCreate.error}
              fieldName="role"
              helperText="You can choose from your organisation's standard roles"
              icon="people"
              info={
                <RoleInfo
                  roleId={engagementRate.role}
                  query={roleQueryDefault}
                />
              }
              items={roleList.roles}
              label="Role"
              labelInfo="(required)"
              loading={roleList.loading}
              placeholder="Select a role"
              query={roleQuery.searchString}
              setQueryObject={setRoleQuery}
              setQueryVariable="searchString"
              setValueObject={setEngagementRate}
              setValueIdVariable="role"
              setValueNameVariable="roleName"
              showInfo={engagementRate.role}
              value={engagementRate.roleName}
            />

            {/* Margin */}
            <FormDrawerNumericField2
              error={engagementRateCreate.error}
              fieldName="margin_percentage"
              helperText="Leave blank to use the organization's default margin"
              icon="percentage"
              label="Margin"
              majorStepSize={20}
              min={0}
              minorStepSize={1}
              placeholder="Enter the margin"
              setValueObject={setEngagementRate}
              setValueVariable="marginPercentage"
              stepSize={10}
              value={engagementRate.marginPercentage}
            />
          </Card>
        }
        error={engagementRateCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add rate"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Defining the rate card"
        icon="edit"
        isOpen={addRateDrawerIsOpen}
        onSave={addRate}
        refreshList={() => {
          dispatch(listEngagementRatesAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementRateAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementRateCreate.success}
        saveSuccessMessage="Rate added."
        saving={engagementRateCreate.loading}
        setIsOpen={setAddRateDrawerIsOpen}
        size="small"
        title="Add rate"
      />

      {/* Existing rate form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the rate details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Role */}
            <FormDrawerSelectField2
              disabled
              error={engagementRateUpdate.error}
              fieldName="role"
              helperText="You can choose from your organisation's standard roles"
              icon="people"
              info={
                <RoleInfo
                  roleId={engagementRate.role}
                  query={roleQueryDefault}
                />
              }
              items={roleList.roles}
              label="Role"
              labelInfo="(required)"
              loading={roleList.loading}
              placeholder="Select a role"
              query={roleQuery.searchString}
              setQueryObject={setRoleQuery}
              setQueryVariable="searchString"
              setValueObject={setEngagementRate}
              setValueIdVariable="role"
              setValueNameVariable="roleName"
              showInfo={engagementRate.role}
              value={engagementRate.roleName}
            />

            {/* Margin */}
            <FormDrawerNumericField2
              error={engagementRateUpdate.error}
              fieldName="margin_percentage"
              helperText="Leave blank to use the organization's default margin"
              icon="percentage"
              label="Margin"
              majorStepSize={20}
              min={0}
              minorStepSize={1}
              placeholder="Enter the margin"
              setValueObject={setEngagementRate}
              setValueVariable="marginPercentage"
              stepSize={10}
              value={engagementRate.marginPercentage}
            />
          </Card>
        }
        deleteSuccess={engagementRateDelete.success}
        deleteSuccessMessage="Rate removed."
        deleting={engagementRateDelete.loading}
        error={engagementRateUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update rate"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Defining the rate card"
        icon="edit"
        isOpen={updateRateDrawerIsOpen}
        loading={engagementRateDetails.loading}
        onDelete={deleteRate}
        onSave={updateRate}
        refreshList={() => {
          dispatch(listEngagementRatesAction({ ...engagementRateQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementRateAction());

          dispatch(resetUpdateEngagementRateAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementRateUpdate.success}
        saveSuccessMessage="Rate updated."
        saving={engagementRateUpdate.loading}
        setIsOpen={setUpdateRateDrawerIsOpen}
        size="small"
        title="Update rate"
      />

      {/* Fee notes form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Add pricing assumptions</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Currency */}
                <FormDrawerSelectSimpleField3
                  error={engagementUpdate.error}
                  fieldName="currency"
                  helperText="Fees will be quoted in this currency"
                  label="Currency"
                  loading={currencyGetOrganisationCurrencyChoices.loading}
                  options={currencies}
                  setValueObject={setEngagement}
                  setValueVariable="currency"
                  value={engagement.currency}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "25%",
                }}
              >
                {/* Exchange rate */}
                <FormDrawerNumericField2
                  error={engagementUpdate.error}
                  fieldName="currency_exchange_rate"
                  helperText="Leave blank to use the organisation's default"
                  label="Exchange rate"
                  majorStepSize={1}
                  min={0}
                  minorStepSize={0.0001}
                  placeholder="Enter rate"
                  setValueObject={setEngagement}
                  setValueVariable="currencyExchangeRate"
                  stepSize={0.1}
                  value={engagement.currencyExchangeRate}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "25%" }}>
                {/* Includes tax */}
                <FormDrawerSelectSimpleField3
                  error={engagementUpdate.error}
                  fieldName="tax"
                  helperText="Whether the fee includes taxes applicable to your services"
                  label="Includes tax"
                  options={[
                    { id: 1, icon: "cross", value: "No" },
                    { id: 2, icon: "tick", value: "Yes" },
                  ]}
                  setValueObject={setEngagement}
                  setValueVariable="tax"
                  value={engagement.tax}
                />
              </div>
            </div>

            {/* Additional information */}
            <FormDrawerTextAreaField3
              error={engagementUpdate.error}
              fieldName="fee_notes"
              helperText="Additional comments on the fee"
              label="Additional information"
              placeholder="Enter your comments"
              setValueObject={setEngagement}
              setValueVariable="feeNotes"
              value={engagement.feeNotes}
            />
          </Card>
        }
        error={engagementUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update fee notes"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Explaining the fee"
        icon="edit"
        isOpen={updateFeeNotesDrawerIsOpen}
        loading={engagementDetails.loading}
        onSave={updateFeeNotes}
        refreshList={() => {
          dispatch(listEngagementDetailsAction(id));

          dispatch(listEngagementLabourExpensesAction({ engagement: id }));

          dispatch(listEngagementNonLabourExpensesAction({ engagement: id }));

          dispatch(listEngagementFeesAction({ engagement: id }));

          dispatch(listEngagementMilestonesAction({ engagement: id }));

          dispatch(listEngagementRatesAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetUpdateEngagementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementUpdate.success}
        saveSuccessMessage="Engagement updated."
        saving={engagementUpdate.loading}
        setIsOpen={setUpdateFeeNotesDrawerIsOpen}
        title="Update fee notes"
      />

      {/* Engagement document style form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Style the engagement charter</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Template */}
                <FormDrawerSelectSimpleField3
                  error={engagementDocumentUpdate.error}
                  fieldName="template"
                  helperText="You can choose a template from the template library"
                  label="Template"
                  options={[
                    {
                      id: 1,
                      icon: "document",
                      value: "London",
                    },
                    {
                      id: 2,
                      icon: "document",
                      value: "Melbourne",
                    },
                  ]}
                  setValueObject={setEngagementDocument}
                  setValueVariable="template"
                  value={engagementDocument.template}
                />

                {/* Font */}
                <FormDrawerSelectSimpleField3
                  error={engagementDocumentUpdate.error}
                  fieldName="font"
                  label="Font"
                  options={[
                    { id: 1, icon: "font", value: "Courier" },
                    { id: 2, icon: "font", value: "Helvetica" },
                    { id: 3, icon: "font", value: "Montserrat" },
                    { id: 4, icon: "font", value: "Roboto" },
                    { id: 5, icon: "font", value: "Sintony" },
                    { id: 6, icon: "font", value: "Times-Roman" },
                  ]}
                  setValueObject={setEngagementDocument}
                  setValueVariable="font"
                  value={engagementDocument.font}
                />

                {/* Primary colour */}
                <FormDrawerTextField2
                  error={engagementDocumentUpdate.error}
                  fieldName="primary_colour"
                  helperText="Hex code starting with #"
                  icon="color-fill"
                  label="Primary colour"
                  placeholder="Enter a hex code"
                  rightElement={
                    <Tag
                      round
                      style={{
                        backgroundColor: engagementDocument.primaryColour,
                        width: "50px",
                      }}
                    ></Tag>
                  }
                  setValueObject={setEngagementDocument}
                  setValueVariable="primaryColour"
                  value={engagementDocument.primaryColour}
                />

                {/* Secondary colour */}
                <FormDrawerTextField2
                  error={engagementDocumentUpdate.error}
                  fieldName="secondary_colour"
                  helperText="Hex code starting with #"
                  icon="color-fill"
                  label="Secondary colour"
                  placeholder="Enter a hex code"
                  rightElement={
                    <Tag
                      round
                      style={{
                        backgroundColor: engagementDocument.secondaryColour,
                        width: "50px",
                      }}
                    ></Tag>
                  }
                  setValueObject={setEngagementDocument}
                  setValueVariable="secondaryColour"
                  value={engagementDocument.secondaryColour}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Logo */}
                <FormDrawerFileField2
                  error={engagementDocumentUpdate.error}
                  fileName="logo"
                  label="Logo"
                  placeholder="Upload the logo"
                  setValueObject={setEngagementDocument}
                  setValueFileVariable="logo"
                  setValueNameVariable="logoName"
                  valueFile={engagementDocument.logo}
                  valueName={engagementDocument.logoName}
                />

                {/* Logo preview */}
                <div style={{ margin: "0 0 20px 0" }}>
                  {engagementDocument.logo ? (
                    engagementDocument.logo !==
                    engagementDocumentDetails.engagement_document.logo ? (
                      <TabSectionFieldImage
                        image={URL.createObjectURL(
                          new Blob([engagementDocument.logo])
                        )}
                      />
                    ) : (
                      <TabSectionFieldImage
                        image={
                          engagementDocumentDetails.engagement_document.logo
                        }
                      />
                    )
                  ) : undefined}
                </div>
              </div>
            </div>
          </Card>
        }
        error={engagementDocumentUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update style"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Styling the engagement charter"
        icon="edit"
        isOpen={updateEngagementDocumentStyleDrawerIsOpen}
        loading={engagementDocumentDetails.loading}
        onSave={updateDocumentStyle}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementDocumentUpdate.success}
        saving={engagementDocumentUpdate.loading}
        setIsOpen={setUpdateEngagementDocumentStyleDrawerIsOpen}
        title="Update style"
      />

      {/* New engagement lesson form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the lesson learned</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={engagementLessonCreate.error}
                  fieldName="name"
                  icon="learning"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the lesson a title"
                  setValueObject={setEngagementLesson}
                  setValueVariable="name"
                  value={engagementLesson.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={engagementLessonCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "playbook",
                      value: "Planning",
                    },
                    { id: 2, icon: "new-person", value: "Stakeholders" },
                    { id: 3, icon: "people", value: "Resourcing" },
                    { id: 4, icon: "gantt-chart", value: "Delivery" },
                    {
                      id: 5,
                      icon: "dollar",
                      value: "Commercials",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setEngagementLesson}
                  setValueVariable="category"
                  value={engagementLesson.category}
                />
              </div>
            </div>

            {/* Lesson */}
            <FormDrawerTextAreaField3
              error={engagementLessonCreate.error}
              fieldName="lesson"
              label="Lesson"
              placeholder="Describe what happened and what we learned"
              setValueObject={setEngagementLesson}
              setValueVariable="lesson"
              value={engagementLesson.lesson}
            />
          </Card>
        }
        error={engagementLessonCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add lesson"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Capturing lessons learned"
        icon="edit"
        isOpen={addEngagementLessonDrawerIsOpen}
        onSave={addEngagementLesson}
        refreshList={() => {
          dispatch(listEngagementLessonsAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementLessonAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementLessonCreate.success}
        saveSuccessMessage="Lesson added."
        saving={engagementLessonCreate.loading}
        setIsOpen={setAddEngagementLessonDrawerIsOpen}
        title="Add lesson"
      />

      {/* Existing engagement lesson form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the lesson learned</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={engagementLessonUpdate.error}
                  fieldName="name"
                  icon="learning"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the lesson a title"
                  setValueObject={setEngagementLesson}
                  setValueVariable="name"
                  value={engagementLesson.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={engagementLessonUpdate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "playbook",
                      value: "Planning",
                    },
                    { id: 2, icon: "new-person", value: "Stakeholders" },
                    { id: 3, icon: "people", value: "Resourcing" },
                    { id: 4, icon: "gantt-chart", value: "Delivery" },
                    {
                      id: 5,
                      icon: "dollar",
                      value: "Commercials",
                    },
                    { id: 6, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setEngagementLesson}
                  setValueVariable="category"
                  value={engagementLesson.category}
                />
              </div>
            </div>

            {/* Lesson */}
            <FormDrawerTextAreaField3
              error={engagementLessonUpdate.error}
              fieldName="lesson"
              label="Lesson"
              placeholder="Describe what happened and what we learned"
              setValueObject={setEngagementLesson}
              setValueVariable="lesson"
              value={engagementLesson.lesson}
            />
          </Card>
        }
        deleteSuccess={engagementLessonDelete.success}
        deleteSuccessMessage="Engagement lesson deleted."
        deleting={engagementLessonDelete.loading}
        error={engagementLessonUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update lesson"
            module="Engagements"
            view="Detail"
          />
        }
        helpTitle="Capturing lessons learned"
        icon="edit"
        isOpen={updateEngagementLessonDrawerIsOpen}
        loading={engagementLessonDetails.loading}
        onDelete={deleteEngagementLesson}
        onSave={updateEngagementLesson}
        refreshList={() => {
          dispatch(listEngagementLessonsAction({ ...engagementLessonQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementLessonAction());

          dispatch(resetUpdateEngagementLessonAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementLessonUpdate.success}
        saveSuccessMessage="Engagement lesson updated."
        saving={engagementLessonUpdate.loading}
        setIsOpen={setUpdateEngagementLessonDrawerIsOpen}
        title="Update lesson"
      />

      {/* New upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the upload details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  error={engagementUploadCreate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setEngagementUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={engagementUpload.file}
                  valueName={engagementUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  error={engagementUploadCreate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setEngagementUpload}
                  setValueVariable="name"
                  value={engagementUpload.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={engagementUploadCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarise the file contents"
              setValueObject={setEngagementUpload}
              setValueVariable="description"
              value={engagementUpload.description}
            />
          </Card>
        }
        error={engagementUploadCreate.error}
        icon="edit"
        isOpen={addUploadDrawerIsOpen}
        onSave={addUpload}
        refreshList={() => {
          dispatch(listEngagementUploadsAction({ engagement: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementUploadCreate.success}
        saveSuccessMessage="File uploaded."
        saving={engagementUploadCreate.loading}
        setIsOpen={setAddUploadDrawerIsOpen}
        title="Upload file"
      />

      {/* Existing upload form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the file details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* File */}
                <FormDrawerFileField2
                  disabled={
                    engagementDetails.engagement.user_access_status === "view"
                  }
                  error={engagementUploadUpdate.error}
                  fileName="file"
                  label="File"
                  labelInfo="(required)"
                  placeholder="Select the file"
                  setValueObject={setEngagementUpload}
                  setValueFileVariable="file"
                  setValueNameVariable="fileName"
                  valueFile={engagementUpload.file}
                  valueName={engagementUpload.fileName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* File name */}
                <FormDrawerTextField2
                  disabled={
                    engagementDetails.engagement.user_access_status === "view"
                  }
                  error={engagementUploadUpdate.error}
                  fieldName="name"
                  icon="document-open"
                  label="File name"
                  labelInfo="(required)"
                  placeholder="Give the file a name"
                  setValueObject={setEngagementUpload}
                  setValueVariable="name"
                  value={engagementUpload.name}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              disabled={
                engagementDetails.engagement.user_access_status === "view"
              }
              error={engagementUploadUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Summarise the file contents"
              setValueObject={setEngagementUpload}
              setValueVariable="description"
              value={engagementUpload.description}
            />

            <Button
              disabled={!engagementUpload.file}
              icon="download"
              intent="primary"
              onClick={() => {
                saveAs(engagementUpload.file, engagementUpload.fileName);
              }}
              text="Download"
            />
          </Card>
        }
        deleteSuccess={engagementUploadDelete.success}
        deleteSuccessMessage="File deleted."
        deleting={engagementUploadDelete.loading}
        disabled={engagementDetails.engagement.user_access_status === "view"}
        error={engagementUploadUpdate.error}
        icon="edit"
        isOpen={updateUploadDrawerIsOpen}
        loading={engagementUploadDetails.loading}
        onDelete={deleteUpload}
        onSave={updateUpload}
        refreshList={() => {
          dispatch(listEngagementUploadsAction({ ...engagementUploadQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteEngagementUploadAction());

          dispatch(resetUpdateEngagementUploadAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementUploadUpdate.success}
        saveSuccessMessage="File updated."
        saving={engagementUploadUpdate.loading}
        setIsOpen={setUpdateUploadDrawerIsOpen}
        title="Update file"
      />

      {/* Detail drawers */}
      {/* Approval request */}
      <ApprovalDetailDrawer
        approvalRequestDetails={approvalRequestDetails}
        setIsOpen={setViewApprovalDrawerIsOpen}
        isOpen={viewApprovalDrawerIsOpen}
      />

      {/* Action dialogs */}
      {/* Report dialog */}
      <Dialog
        className={!lightMode ? Classes.DARK : undefined}
        icon="panel-stats"
        isCloseButtonShown
        isOpen={reportDialogIsOpen}
        lazy
        onClose={() => {
          setReportDialogIsOpen(false);
        }}
        style={{
          height: "90vh",
          width: "1280px",
        }}
        title="Status report"
      >
        <div className={Classes.DIALOG_BODY} style={{ marginBottom: "-25px" }}>
          <TabSectionDocumentEditor3
            document={documentGeneratePdf.engagement_report_pdf}
            error={documentGeneratePdf.error}
            form={
              <>
                {/* Report */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Title */}
                      <FormDrawerTextField2
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="title"
                        icon="panel-stats"
                        label="Title"
                        placeholder="Enter a title for the report"
                        setValueObject={setEngagementReport}
                        setValueVariable="title"
                        value={engagementReport.title}
                      />

                      {/* Status */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="status"
                        intent={
                          engagementReport.status === "Draft"
                            ? "primary"
                            : "success"
                        }
                        label="Status"
                        options={[
                          {
                            id: 1,
                            icon: "manually-entered-data",
                            value: "Draft",
                          },
                          {
                            id: 2,
                            icon: "document-share",
                            value: "Published",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="status"
                        value={engagementReport.status}
                      />

                      {/* External report */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="external"
                        helperText="Selecting this option will remove commercially sensitive information"
                        label="External report"
                        setValueObject={setEngagementReport}
                        setValueVariable="external"
                        value={engagementReport.external}
                      />
                    </>
                  }
                  message="A published report cannot be changed."
                  title="Report"
                />

                {/* Style */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Template */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="template"
                        helperText="You can choose a template from the template library"
                        label="Template"
                        options={[
                          {
                            id: 1,
                            icon: "document",
                            value: "London",
                          },
                          {
                            id: 2,
                            icon: "document",
                            value: "Melbourne",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="template"
                        value={engagementReport.template}
                      />

                      {/* Font */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="font"
                        label="Font"
                        options={[
                          { id: 1, icon: "font", value: "Courier" },
                          { id: 2, icon: "font", value: "Helvetica" },
                          { id: 3, icon: "font", value: "Montserrat" },
                          { id: 4, icon: "font", value: "Roboto" },
                          { id: 5, icon: "font", value: "Sintony" },
                          { id: 6, icon: "font", value: "Times-Roman" },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="font"
                        value={engagementReport.font}
                      />

                      {/* Primary colour */}
                      <FormDrawerTextField2
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="primary_colour"
                        helperText="Hex code starting with #"
                        icon="color-fill"
                        label="Primary colour"
                        placeholder="Enter a hex code"
                        rightElement={
                          <Tag
                            round
                            style={{
                              backgroundColor: engagementReport.primaryColour,
                              width: "50px",
                            }}
                          ></Tag>
                        }
                        setValueObject={setEngagementReport}
                        setValueVariable="primaryColour"
                        value={engagementReport.primaryColour}
                      />

                      {/* Secondary colour */}
                      <FormDrawerTextField2
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="secondary_colour"
                        helperText="Hex code starting with #"
                        icon="color-fill"
                        label="Secondary colour"
                        placeholder="Enter a hex code"
                        rightElement={
                          <Tag
                            round
                            style={{
                              backgroundColor: engagementReport.secondaryColour,
                              width: "50px",
                            }}
                          ></Tag>
                        }
                        setValueObject={setEngagementReport}
                        setValueVariable="secondaryColour"
                        value={engagementReport.secondaryColour}
                      />

                      {/* Logo */}
                      <FormDrawerFileField2
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fileName="logo"
                        label="Logo"
                        placeholder="Upload the logo"
                        setValueObject={setEngagementReport}
                        setValueFileVariable="logo"
                        setValueNameVariable="logoName"
                        valueFile={engagementReport.logo}
                        valueName={engagementReport.logoName}
                      />

                      {/* Logo preview */}
                      <div style={{ margin: "0 0 20px 0" }}>
                        {engagementReport.logo ? (
                          engagementReport.logo !==
                          engagementReportDetails.engagement_report.logo ? (
                            <TabSectionFieldImage
                              image={URL.createObjectURL(
                                new Blob([engagementReport.logo])
                              )}
                            />
                          ) : (
                            <TabSectionFieldImage
                              image={
                                engagementReportDetails.engagement_report.logo
                              }
                            />
                          )
                        ) : undefined}
                      </div>
                    </>
                  }
                  title="Style"
                />

                {/* Summary */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      <FormDrawerTextAreaField3
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_summary"
                        label="Comments"
                        placeholder="Summarise the engagement's status"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsSummary"
                        value={engagementReport.commentsSummary}
                      />
                    </>
                  }
                  title="Summary"
                />

                {/* Status */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_status"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeStatus"
                        value={engagementReport.includeStatus}
                      />

                      {/* Stakeholders */}
                      {!engagementReport.external && (
                        <>
                          <FormDrawerSelectSimpleField3
                            disabled={
                              !engagementReport.includeStatus ||
                              engagementReportDetails.engagement_report
                                .status === "Published"
                            }
                            error={engagementReportUpdate.error}
                            fieldName="stakeholders_status_rating"
                            intent={
                              engagementReport.stakeholdersStatusRating ===
                              "Needs attention"
                                ? "warning"
                                : engagementReport.stakeholdersStatusRating ===
                                  "Needs urgent attention"
                                ? "danger"
                                : "success"
                            }
                            label="Stakeholders"
                            options={[
                              { id: 1, icon: "confirm", value: "On-track" },
                              {
                                id: 2,
                                icon: "issue-new",
                                value: "Needs attention",
                              },
                              {
                                id: 3,
                                icon: "issue",
                                value: "Needs urgent attention",
                              },
                            ]}
                            setValueObject={setEngagementReport}
                            setValueVariable="stakeholdersStatusRating"
                            value={engagementReport.stakeholdersStatusRating}
                          />

                          <FormDrawerTextAreaField3
                            disabled={
                              !engagementReport.includeStatus ||
                              engagementReportDetails.engagement_report
                                .status === "Published"
                            }
                            error={engagementReportUpdate.error}
                            fieldName="stakeholders_status_comments"
                            height="100px"
                            placeholder="Enter your comments"
                            setValueObject={setEngagementReport}
                            setValueVariable="stakeholdersStatusComments"
                            value={engagementReport.stakeholdersStatusComments}
                          />
                        </>
                      )}

                      {/* Financials */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="financials_status_rating"
                        intent={
                          engagementReport.financialsStatusRating ===
                          "Needs attention"
                            ? "warning"
                            : engagementReport.financialsStatusRating ===
                              "Needs urgent attention"
                            ? "danger"
                            : "success"
                        }
                        label="Financials"
                        options={[
                          { id: 1, icon: "confirm", value: "On-track" },
                          {
                            id: 2,
                            icon: "issue-new",
                            value: "Needs attention",
                          },
                          {
                            id: 3,
                            icon: "issue",
                            value: "Needs urgent attention",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="financialsStatusRating"
                        value={engagementReport.financialsStatusRating}
                      />

                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="financials_status_comments"
                        height="100px"
                        placeholder="Enter your comments"
                        setValueObject={setEngagementReport}
                        setValueVariable="financialsStatusComments"
                        value={engagementReport.financialsStatusComments}
                      />

                      {/* Progress */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="progress_status_rating"
                        intent={
                          engagementReport.progressStatusRating ===
                          "Needs attention"
                            ? "warning"
                            : engagementReport.progressStatusRating ===
                              "Needs urgent attention"
                            ? "danger"
                            : "success"
                        }
                        label="Progress"
                        options={[
                          { id: 1, icon: "confirm", value: "On-track" },
                          {
                            id: 2,
                            icon: "issue-new",
                            value: "Needs attention",
                          },
                          {
                            id: 3,
                            icon: "issue",
                            value: "Needs urgent attention",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="progressStatusRating"
                        value={engagementReport.progressStatusRating}
                      />

                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="progress_status_comments"
                        height="100px"
                        placeholder="Enter your comments"
                        setValueObject={setEngagementReport}
                        setValueVariable="progressStatusComments"
                        value={engagementReport.progressStatusComments}
                      />

                      {/* Resources */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="resources_status_rating"
                        intent={
                          engagementReport.resourcesStatusRating ===
                          "Needs attention"
                            ? "warning"
                            : engagementReport.resourcesStatusRating ===
                              "Needs urgent attention"
                            ? "danger"
                            : "success"
                        }
                        label="Resources"
                        options={[
                          { id: 1, icon: "confirm", value: "On-track" },
                          {
                            id: 2,
                            icon: "issue-new",
                            value: "Needs attention",
                          },
                          {
                            id: 3,
                            icon: "issue",
                            value: "Needs urgent attention",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="resourcesStatusRating"
                        value={engagementReport.resourcesStatusRating}
                      />

                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="resources_status_comments"
                        height="100px"
                        placeholder="Enter your comments"
                        setValueObject={setEngagementReport}
                        setValueVariable="resourcesStatusComments"
                        value={engagementReport.resourcesStatusComments}
                      />

                      {/* Risks/Issues */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="risks_issues_status_rating"
                        intent={
                          engagementReport.risksIssuesStatusRating ===
                          "Needs attention"
                            ? "warning"
                            : engagementReport.risksIssuesStatusRating ===
                              "Needs urgent attention"
                            ? "danger"
                            : "success"
                        }
                        label="Risks/Issues"
                        options={[
                          { id: 1, icon: "confirm", value: "On-track" },
                          {
                            id: 2,
                            icon: "issue-new",
                            value: "Needs attention",
                          },
                          {
                            id: 3,
                            icon: "issue",
                            value: "Needs urgent attention",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="risksIssuesStatusRating"
                        value={engagementReport.risksIssuesStatusRating}
                      />

                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="risks_issues_status_comments"
                        height="100px"
                        placeholder="Enter your comments"
                        setValueObject={setEngagementReport}
                        setValueVariable="risksIssuesStatusComments"
                        value={engagementReport.risksIssuesStatusComments}
                      />

                      {/* Dependencies */}
                      <FormDrawerSelectSimpleField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="dependencies_status_rating"
                        intent={
                          engagementReport.dependenciesStatusRating ===
                          "Needs attention"
                            ? "warning"
                            : engagementReport.dependenciesStatusRating ===
                              "Needs urgent attention"
                            ? "danger"
                            : "success"
                        }
                        label="Dependencies"
                        options={[
                          { id: 1, icon: "confirm", value: "On-track" },
                          {
                            id: 2,
                            icon: "issue-new",
                            value: "Needs attention",
                          },
                          {
                            id: 3,
                            icon: "issue",
                            value: "Needs urgent attention",
                          },
                        ]}
                        setValueObject={setEngagementReport}
                        setValueVariable="dependenciesStatusRating"
                        value={engagementReport.dependenciesStatusRating}
                      />

                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeStatus ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="dependencies_status_comments"
                        height="100px"
                        placeholder="Enter your comments"
                        setValueObject={setEngagementReport}
                        setValueVariable="dependenciesStatusComments"
                        value={engagementReport.dependenciesStatusComments}
                      />
                    </>
                  }
                  title="Status"
                />

                {/* Financial overview */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_financials"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeFinancials"
                        value={engagementReport.includeFinancials}
                      />

                      {/* Comments */}
                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeFinancials ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_financials"
                        label="Comments"
                        placeholder="Analyse the engagement's financial position"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsFinancials"
                        value={engagementReport.commentsFinancials}
                      />
                    </>
                  }
                  title="Financial overview"
                />

                {/* Milestones */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_milestones"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeMilestones"
                        value={engagementReport.includeMilestones}
                      />

                      {/* Comments */}
                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeMilestones ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_milestones"
                        label="Comments"
                        placeholder="Comment on the milestone payments"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsMilestones"
                        value={engagementReport.commentsMilestones}
                      />
                    </>
                  }
                  message={
                    engagementReportDetails.engagement_report.report_data
                      ? engagementReportDetails.engagement_report.report_data
                          .milestones.length === 0 && "No milestones found."
                      : undefined
                  }
                  title="Milestones"
                />

                {/* Deliverables */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_deliverables"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeDeliverables"
                        value={engagementReport.includeDeliverables}
                      />

                      {/* Comments */}
                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeDeliverables ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_deliverables"
                        label="Comments"
                        placeholder="Summarise status of deliverables"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsDeliverables"
                        value={engagementReport.commentsDeliverables}
                      />
                    </>
                  }
                  message={
                    engagementReportDetails.engagement_report.report_data
                      ? engagementReportDetails.engagement_report.report_data
                          .deliverables.length === 0 && "No deliverables found."
                      : undefined
                  }
                  title="Deliverables"
                />

                {/* Resources */}
                {!engagementReport.external && (
                  <TabSectionDocumentEditorFormContainer2
                    fields={
                      <>
                        {/* Include section */}
                        <FormDrawerSwitchField
                          disabled={
                            engagementReportDetails.engagement_report.status ===
                            "Published"
                          }
                          error={engagementReportUpdate.error}
                          fieldName="include_resources"
                          label="Include section"
                          setValueObject={setEngagementReport}
                          setValueVariable="includeResources"
                          value={engagementReport.includeResources}
                        />

                        {/* Comments */}
                        <FormDrawerTextAreaField3
                          disabled={
                            !engagementReport.includeResources ||
                            engagementReportDetails.engagement_report.status ===
                              "Published"
                          }
                          error={engagementReportUpdate.error}
                          fieldName="comments_resources"
                          label="Comments"
                          placeholder="Comment on the use of resources"
                          setValueObject={setEngagementReport}
                          setValueVariable="commentsResources"
                          value={engagementReport.commentsResources}
                        />
                      </>
                    }
                    title="Resources"
                  />
                )}

                {/* Risks */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_risks"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeRisks"
                        value={engagementReport.includeRisks}
                      />

                      {/* Comments */}
                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeRisks ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_risks"
                        label="Comments"
                        placeholder="Summarise the risk management strategy"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsRisks"
                        value={engagementReport.commentsRisks}
                      />
                    </>
                  }
                  message={
                    engagementReportDetails.engagement_report.report_data
                      ? engagementReportDetails.engagement_report.report_data
                          .risks.length === 0 && "No open risks found."
                      : undefined
                  }
                  title="Risks"
                />

                {/* Issues */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_issues"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeIssues"
                        value={engagementReport.includeIssues}
                      />

                      {/* Comments */}
                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeIssues ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_issues"
                        label="Comments"
                        placeholder="Comment on the current issues"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsIssues"
                        value={engagementReport.commentsIssues}
                      />
                    </>
                  }
                  message={
                    engagementReportDetails.engagement_report.report_data
                      ? engagementReportDetails.engagement_report.report_data
                          .issues.length === 0 && "No open issues found."
                      : undefined
                  }
                  title="Issues"
                />

                {/* Dependencies */}
                <TabSectionDocumentEditorFormContainer2
                  fields={
                    <>
                      {/* Include section */}
                      <FormDrawerSwitchField
                        disabled={
                          engagementReportDetails.engagement_report.status ===
                          "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="include_dependencies"
                        label="Include section"
                        setValueObject={setEngagementReport}
                        setValueVariable="includeDependencies"
                        value={engagementReport.includeDependencies}
                      />

                      {/* Comments */}
                      <FormDrawerTextAreaField3
                        disabled={
                          !engagementReport.includeDependencies ||
                          engagementReportDetails.engagement_report.status ===
                            "Published"
                        }
                        error={engagementReportUpdate.error}
                        fieldName="comments_dependencies"
                        label="Comments"
                        placeholder="Summarise the key dependencies"
                        setValueObject={setEngagementReport}
                        setValueVariable="commentsDependencies"
                        value={engagementReport.commentsDependencies}
                      />
                    </>
                  }
                  message={
                    engagementReportDetails.engagement_report.report_data
                      ? engagementReportDetails.engagement_report.report_data
                          .dependencies.length === 0 &&
                        "No outstanding dependencies found."
                      : undefined
                  }
                  title="Dependencies"
                />
              </>
            }
            height="75vh"
            loading={
              documentGeneratePdf.loading || engagementReportUpdate.loading
            }
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div
            className={Classes.DIALOG_FOOTER_ACTIONS}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              disabled={
                engagementDetails.engagement.user_access_status === "view" ||
                engagementReportDetails.engagement_report.status === "Published"
              }
              icon="trash"
              intent="danger"
              loading={engagementReportDelete.loading}
              onClick={() => {
                deleteReport();
              }}
              text="Delete"
            />

            <div>
              <Button
                disabled={
                  engagementDetails.engagement.user_access_status === "view" ||
                  engagementReportDetails.engagement_report.status ===
                    "Published"
                }
                icon="refresh"
                intent="primary"
                onClick={() => {
                  updateReport();
                }}
                text="Refresh"
              />

              <Popover2
                content={
                  <Menu>
                    <MenuItem
                      icon="manual"
                      onClick={() => {
                        saveAs(
                          documentGeneratePdf.engagement_report_pdf,
                          `${engagementReportDetails.engagement_report.title}.pdf`
                        );
                      }}
                      text="PDF"
                    />

                    <MenuItem
                      icon="document"
                      onClick={() => {
                        setEditableReportDocumentLoading(true);

                        dispatch(
                          downloadEngagementReportAction(
                            engagementReportDetails.engagement_report.id
                          )
                        );
                      }}
                      text="DOCX"
                    />
                  </Menu>
                }
                interactionKind={Popover2InteractionKind.HOVER}
              >
                <Button
                  icon="download"
                  intent="primary"
                  loading={
                    documentGeneratePdf.loading || editableReportDocumentLoading
                  }
                  style={{ margin: "0 0 0 10px" }}
                  text="Download"
                />
              </Popover2>

              <Button
                className={Classes.DIALOG_CLOSE_BUTTON}
                onClick={() => {
                  setReportDialogIsOpen(false);

                  dispatch(
                    listEngagementReportsAction({ ...engagementReportQuery })
                  );

                  resetDrawerVariables();
                }}
                text="Close"
              />
            </div>
          </div>
        </div>
      </Dialog>

      {/* Schedule dialog */}
      <Dialog
        className={!lightMode ? Classes.DARK : undefined}
        icon="gantt-chart"
        isCloseButtonShown
        isOpen={scheduleDialogIsOpen}
        lazy
        onClose={() => {
          setScheduleDialogIsOpen(false);
        }}
        style={{
          height: "90vh",
          width: "95vw",
        }}
        title="Schedule"
      >
        <div className={Classes.DIALOG_BODY} style={{ marginBottom: "-25px" }}>
          <Timeline4
            data={generateTimelineDataFromEngagementServices(
              engagementDetails.engagement.services
            )}
            height="70vh"
            itemListHeading="Services/Activities"
            loading={engagementDetails.loading}
            onClick={(id, type) => {
              if (type === "child") {
                dispatch(
                  listEngagementServiceActivityDetailsAction(id.split(".")[1])
                );

                setUpdateActivityDrawerIsOpen(true);
              } else if (type === "item") {
                dispatch(listEngagementServiceDetailsAction(id));

                setUpdateServiceDrawerIsOpen(true);
              } else if (type === "milestone") {
                dispatch(
                  listEngagementServiceDeliverableDetailsAction(
                    id.split(".")[1]
                  )
                );

                setUpdateDeliverableDrawerIsOpen(true);
              } else {
                dispatch(
                  listEngagementLabourExpenseDetailsAction(id.split(".")[1])
                );

                setUpdateLabourExpenseDrawerIsOpen(true);
              }
            }}
            resourceData={generateTimelineResourceDataFromEngagementServices(
              engagementDetails.engagement.services
            )}
            scheduleRef={scheduleRef}
            showDates
          />
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              disabled={
                engagementDetails.engagement.user_access_status === "view"
              }
              icon="add"
              intent="primary"
              onClick={() => {
                setAddActivityDrawerIsOpen(true);
              }}
              text="Add"
            />

            <Button
              className={Classes.DIALOG_CLOSE_BUTTON}
              onClick={() => {
                setScheduleDialogIsOpen(false);
              }}
              text="Close"
            />
          </div>
        </div>
      </Dialog>

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Engagements"
              onClick={() => {
                dispatch(resetListEngagementsAction());

                navigate("/engagements");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          engagementDetails.engagement.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={engagementDetails.loading}
        title={`${engagementDetails.engagement.name}`}
      />

      {/* Error alerts */}
      {/* Approval requests loading error */}
      {approvalRequestList.error && (
        <ErrorAlert message="We couldn't load the approval request list. Try refreshing the page." />
      )}
      {/* Bookings loading error */}
      {bookingList.error && (
        <ErrorAlert message="We couldn't load the booking list. Try refreshing the page." />
      )}
      {/* Booking codes loading error */}
      {bookingCodeList.error && (
        <ErrorAlert message="We couldn't load the booking code list. Try refreshing the page." />
      )}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Competencies loading error */}
      {competencyList.error && (
        <ErrorAlert message="We couldn't load the competency list. Try refreshing the page." />
      )}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Engagement delete error */}
      {engagementDelete.error && (
        <ErrorAlert message="We couldn't delete the engagement. Try refreshing the page." />
      )}
      {/* Engagement loading error */}
      {engagementDetails.error && (
        <ErrorAlert message="We couldn't load the engagement. Try refreshing the page." />
      )}
      {/* Engagement actions loading error */}
      {engagementActionList.error && (
        <ErrorAlert message="We couldn't load the engagement actions. Try refreshing the page." />
      )}
      {/* Engagement contacts loading error */}
      {engagementContactList.error && (
        <ErrorAlert message="We couldn't load the engagement contacts. Try refreshing the page." />
      )}
      {/* Engagement delivery issues loading error */}
      {engagementDeliveryIssueList.error && (
        <ErrorAlert message="We couldn't load the engagement issues. Try refreshing the page." />
      )}
      {/* Engagement dependencies loading error */}
      {engagementDependencyList.error && (
        <ErrorAlert message="We couldn't load the engagement dependencies. Try refreshing the page." />
      )}
      {/* Engagement fees loading error */}
      {engagementFeeList.error && (
        <ErrorAlert message="We couldn't load the engagement fees. Try refreshing the page." />
      )}
      {/* Engagement labour expenses loading error */}
      {engagementLabourExpenseList.error && (
        <ErrorAlert message="We couldn't load the engagement labour expenses. Try refreshing the page." />
      )}
      {/* Engagement lessons loading error */}
      {engagementLessonList.error && (
        <ErrorAlert message="We couldn't load the engagement lessons. Try refreshing the page." />
      )}
      {/* Engagement milestones loading error */}
      {engagementMilestoneList.error && (
        <ErrorAlert message="We couldn't load the engagement milestones. Try refreshing the page." />
      )}
      {/* Engagement non-labour expenses loading error */}
      {engagementNonLabourExpenseList.error && (
        <ErrorAlert message="We couldn't load the engagement non-labour expenses. Try refreshing the page." />
      )}
      {/* Engagement permissions loading error */}
      {engagementPermissionList.error && (
        <ErrorAlert message="We couldn't load the engagement permissions. Try refreshing the page." />
      )}
      {/* Engagement rates loading error */}
      {engagementRateList.error && (
        <ErrorAlert message="We couldn't load the engagement rates. Try refreshing the page." />
      )}
      {/* Engagement reports loading error */}
      {engagementReportList.error && (
        <ErrorAlert message="We couldn't load the engagement reports. Try refreshing the page." />
      )}
      {/* Engagement risks loading error */}
      {engagementRiskList.error && (
        <ErrorAlert message="We couldn't load the engagement risks. Try refreshing the page." />
      )}
      {/* Engagement services loading error */}
      {engagementServiceList.error && (
        <ErrorAlert message="We couldn't load the engagement services. Try refreshing the page." />
      )}
      {/* Engagement service deliverables loading error */}
      {engagementServiceDeliverableList.error && (
        <ErrorAlert message="We couldn't load the engagement deliverables. Try refreshing the page." />
      )}
      {/* Engagement uploads loading error */}
      {engagementUploadList.error && (
        <ErrorAlert message="We couldn't load the engagement files. Try refreshing the page." />
      )}
      {/* Expenses loading error */}
      {expenseList.error && (
        <ErrorAlert message="We couldn't load the expense list. Try refreshing the page." />
      )}
      {/* Expense categories loading error */}
      {expenseCategoryList.error && (
        <ErrorAlert message="We couldn't load the expense category list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the standard roles. Try refreshing the page." />
      )}
      {/* Services loading error */}
      {serviceList.error && (
        <ErrorAlert message="We couldn't load the service list. Try refreshing the page." />
      )}
      {/* Staff loading error */}
      {staffList.error && (
        <ErrorAlert message="We couldn't load the staff list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Engagement"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="engagement-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="engagement-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listEngagementDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={engagementDetails.loading}
                          value={engagementDetails.engagement.id}
                        />

                        {/* Engagement name */}
                        <TabSectionField
                          label="Engagement name"
                          loading={engagementDetails.loading}
                          value={engagementDetails.engagement.name}
                        />

                        {/* Client */}
                        <TabSectionField
                          label="Client"
                          loading={engagementDetails.loading}
                          value={
                            <NameValue
                              image={engagementDetails.engagement.client_logo}
                              logo
                              text={engagementDetails.engagement.client_name}
                              url={`/clients/${engagementDetails.engagement.client}`}
                            />
                          }
                        />

                        {/* Engagement value */}
                        <TabSectionField
                          helperText={
                            activeOrganisation.currency !==
                              engagementDetails.engagement.currency_short && (
                              <div style={{ display: "flex" }}>
                                Quote value of
                                <div style={{ margin: "0 0 0 5px" }}>
                                  <NumericValue
                                    currency={
                                      engagementDetails.engagement
                                        .currency_short
                                    }
                                    noTag
                                    number={
                                      engagementDetails.engagement.value_quote
                                    }
                                  />
                                </div>
                              </div>
                            )
                          }
                          label="Engagement value"
                          loading={engagementDetails.loading}
                          value={
                            <NumericValue
                              currency={activeOrganisation.currency}
                              number={engagementDetails.engagement.value}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={engagementDetails.loading}
                          value={
                            <Tag
                              intent={
                                engagementDetails.engagement.status === "Amber"
                                  ? "warning"
                                  : engagementDetails.engagement.status ===
                                    "Green"
                                  ? "success"
                                  : engagementDetails.engagement.status ===
                                    "Red"
                                  ? "danger"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {engagementDetails.engagement.status}
                            </Tag>
                          }
                        />

                        {/* Sponsor */}
                        <TabSectionField
                          label="Sponsor"
                          loading={engagementDetails.loading}
                          value={
                            <NameValue
                              image={
                                engagementDetails.engagement
                                  .sponsor_profile_photo
                              }
                              text={engagementDetails.engagement.sponsor_name}
                              url={
                                engagementDetails.engagement.sponsor
                                  ? `/contacts/${engagementDetails.engagement.sponsor}`
                                  : undefined
                              }
                            />
                          }
                        />

                        {/* Statement of Work */}
                        <TabSectionField
                          label="Statement of Work"
                          loading={engagementDetails.loading}
                          value={
                            <TabSectionFieldLink
                              shorten
                              url={engagementDetails.engagement.sow}
                            />
                          }
                        />

                        {/* Notes */}
                        <TabSectionField
                          label="Notes"
                          loading={engagementDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={engagementDetails.engagement.notes}
                            />
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            engagementDetails.engagement.restricted
                              ? "Users must have permission to access this engagement"
                              : "Everyone in the organisation can edit this engagement"
                          }
                          label="Access"
                          loading={engagementDetails.loading}
                          value={
                            <Tag
                              intent={
                                engagementDetails.engagement.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {engagementDetails.engagement.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            engagementDetails.engagement.user_access_status ===
                            "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={engagementDetails.loading}
                          value={
                            <NameValue
                              email={engagementDetails.engagement.manager_name}
                              image={
                                engagementDetails.engagement
                                  .manager_profile_photo
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update engagement information"
                    icon="gantt-chart"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(engagementDetails.engagement.user_access_status ===
                    "owner" ||
                    engagementDetails.engagement.user_access_status ===
                      "admin" ||
                    engagementDetails.engagement.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={engagementPermissionList.loading}
                              setValueObject={setEngagementPermissionQuery}
                              setValueVariable="searchString"
                              value={engagementPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={engagementPermissionList.engagement_permissions.map(
                              (engagement_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={engagement_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listEngagementPermissionDetailsAction(
                                          engagement_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={engagement_permission}
                                  />
                                );
                              }
                            )}
                            count={engagementPermissionList.count}
                            loading={engagementPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={engagementPermissionQuery.page}
                            setPageObject={setEngagementPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, engagements are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete service */}
                  {(engagementDetails.engagement.user_access_status ===
                    "owner" ||
                    engagementDetails.engagement.user_access_status ===
                      "admin" ||
                    engagementDetails.engagement.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={
                                engagementDetails.engagement.cannot_delete
                              }
                              id="confirm-engagement-name"
                              onChange={(e) => {
                                setServiceDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the engagement's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={engagementDeleteKey}
                            />

                            <Button
                              disabled={
                                engagementDetails.engagement.cannot_delete
                              }
                              icon="trash"
                              intent="danger"
                              loading={engagementDelete.loading}
                              onClick={deleteEngagement}
                              text="Delete this engagement"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{engagementDetails.engagement.name}" to
                            delete.
                          </div>
                        </div>
                      }
                      help="Delete the engagement"
                      icon="delete"
                      message={
                        engagementDetails.engagement.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this engagement, you will lose all information associated with it."
                      }
                      messageIntent={
                        engagementDetails.engagement.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete engagement"
                    />
                  )}
                </div>
              }
            />

            {/* Status */}
            <Tab
              id="engagement-status-tab"
              title="Status"
              panel={
                <div>
                  {/* Status summary */}
                  <TabSection2
                    content={
                      reportData ? (
                        <EngagementStatusSummary
                          data={reportData}
                          loading={
                            engagementDetails.loading ||
                            engagementGenerateReportData.loading
                          }
                        />
                      ) : undefined
                    }
                    help="Evaluate the engagement against the approved baseline"
                    icon="comparison"
                    message={
                      !reportData
                        ? "Use the approvals section to baseline the engagement. You will then be able to generate status information."
                        : undefined
                    }
                    messageIntent={!reportData ? "primary" : undefined}
                    title="Status summary"
                  />

                  {/* Reports */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                            "view" || !reportData
                        }
                        icon="add"
                        intent="primary"
                        loading={engagementReportCreate.loading}
                        onClick={() => {
                          addReport();
                        }}
                        text="Generate"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        {reportData ? (
                          <TableContainer2
                            body={engagementReportList.engagement_reports.map(
                              (engagement_report) => {
                                return (
                                  <tr
                                    key={engagement_report.id}
                                    onClick={() => {
                                      if (
                                        engagementDetails.engagement
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listEngagementReportDetailsAction(
                                            engagement_report.id
                                          )
                                        );

                                        setReportDialogIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Title */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        text={engagement_report.title}
                                      />
                                    </td>

                                    {/* Date */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate
                                        date={engagement_report.date}
                                      />
                                    </td>

                                    {/* Status */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableTag
                                        intent={
                                          engagement_report.status === "Draft"
                                            ? "primary"
                                            : "success"
                                        }
                                        value={engagement_report.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !engagementReportQuery.dateEnd &&
                              !engagementReportQuery.dateStart &&
                              !engagementReportQuery.searchString &&
                              !engagementReportQuery.status
                            }
                            count={engagementReportList.count}
                            head={
                              <tr>
                                {/* Title */}
                                <TableColumnHeadSearch
                                  queryObject={engagementReportQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setEngagementReportQuery}
                                  setQueryVariable="searchString"
                                  title="Title"
                                  width="200px"
                                />

                                {/* Date */}
                                <TableColumnHeadDate
                                  endDateVariable="dateEnd"
                                  queryObject={engagementReportQuery}
                                  setQueryObject={setEngagementReportQuery}
                                  startDateVariable="dateStart"
                                  title="Date"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={engagementReportQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Draft",
                                      icon: "manually-entered-data",
                                      value: "Draft",
                                    },
                                    {
                                      display: "Published",
                                      icon: "document-share",
                                      value: "Published",
                                    },
                                  ]}
                                  setFilterObject={setEngagementReportQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              engagementDetails.engagement
                                .user_access_status !== "view"
                            }
                            loading={engagementReportList.loading}
                            noMargin
                            onClearFilters={() => {
                              setEngagementReportQuery({
                                ...engagementReportQueryDefault,
                              });
                            }}
                            page={engagementReportQuery.page}
                            setPageObject={setEngagementReportQuery}
                            setPageVariable="page"
                          />
                        ) : undefined}
                      </>
                    }
                    help="Generate status reports"
                    icon="panel-stats"
                    message={
                      !reportData
                        ? "Use the approvals section to baseline the engagement. You will then be able to generate status information."
                        : undefined
                    }
                    messageIntent={!reportData ? "primary" : undefined}
                    title="Reports"
                  />

                  {/* Approvals */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddApprovalRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalRequestList.approval_requests.map(
                            (approval_request) => {
                              return (
                                <tr
                                  key={approval_request.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listApprovalRequestDetailsAction(
                                          approval_request.id
                                        )
                                      );

                                      if (
                                        approval_request.status === "Completed"
                                      ) {
                                        setViewApprovalDrawerIsOpen(true);
                                      } else {
                                        setUpdateApprovalRequestDrawerIsOpen(
                                          true
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={approval_request.decision_right}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={approval_request.value}
                                      tagText={activeOrganisation.currency}
                                    />
                                  </td>

                                  {/* Approver */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={approval_request.approver_name}
                                      image={
                                        approval_request.approver_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .reviewed
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome === "Approved"
                                            ? "success"
                                            : approval_request.response_data
                                                .outcome === "Denied"
                                            ? "danger"
                                            : "primary"
                                          : undefined
                                      }
                                      value={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalRequestQuery.approver &&
                            !approvalRequestQuery.decisionRight &&
                            !approvalRequestQuery.outcome &&
                            !approvalRequestQuery.reviewedEnd &&
                            !approvalRequestQuery.reviewedStart &&
                            !approvalRequestQuery.value
                          }
                          count={approvalRequestList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Engagement baseline",
                                    value: "Engagement baseline",
                                  },
                                  /*{
                                    display: "Engagement deliverable",
                                    value: "Engagement deliverable",
                                  },*/
                                  {
                                    display: "Engagement close",
                                    value: "Engagement close",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Approver */}
                              <TableColumnHeadSelect2
                                filterObject={approvalRequestQuery}
                                filterVariable="approver"
                                items={managerList.managers}
                                setFilterObject={setApprovalRequestQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Approver"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalRequestQuery}
                                setQueryObject={setApprovalRequestQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalRequestQuery({
                              ...approvalRequestQueryDefault,
                            });
                          }}
                          page={approvalRequestQuery.page}
                          setPageObject={setApprovalRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage approval requests"
                    icon="book"
                    message="When you create an approval request, advaise.app generates a fact set to inform the approver's decision. This fact set is based on the information at the time. If there is a change, consider submitting a new approval request."
                    messageIntent="warning"
                    title="Approvals"
                  />
                </div>
              }
            />

            {/* RAID */}
            <Tab
              id="engagement-raid-tab"
              title="RAID"
              panel={
                <div>
                  {/* Risks */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddRiskDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Closed */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Closed"
                                loading={engagementRiskList.loading}
                                onClick={() => {
                                  setEngagementRiskQuery({
                                    ...engagementRiskQuery,
                                    page: 1,
                                    status: "Closed",
                                  });
                                }}
                                value={engagementRiskList.numberOfRisksClosed}
                              />

                              {/* Open */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Open"
                                loading={engagementRiskList.loading}
                                onClick={() => {
                                  setEngagementRiskQuery({
                                    ...engagementRiskQuery,
                                    page: 1,
                                    status: "Open",
                                  });
                                }}
                                value={engagementRiskList.numberOfRisksOpen}
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Risk matrix */}
                        {engagementRiskList.engagement_risks.length > 0 ? (
                          <Matrix
                            colors={[
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                              ],
                              [
                                `${Colors.GREEN5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.RED5}33`,
                              ],
                              [
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.ORANGE5}33`,
                              ],
                            ]}
                            data={engagementRiskList.engagement_risks}
                            intents={[
                              ["warning", "danger", "danger"],
                              ["success", "warning", "danger"],
                              ["success", "success", "warning"],
                            ]}
                            interactive
                            leftColumnWidth="150px"
                            onClickAccessTest={() =>
                              engagementDetails.engagement
                                .user_access_status !== "view"
                            }
                            onClickListDetailsAction={
                              listEngagementRiskDetailsAction
                            }
                            onClickSetDrawerIsOpen={setUpdateRiskDrawerIsOpen}
                            tableTop
                            xAxisCategories={["Low", "Medium", "High"]}
                            xAxisFilterVariable="likelihood"
                            xAxisLabel="Likelihood"
                            yAxisCategories={["High", "Medium", "Low"]}
                            yAxisFilterVariable="impact"
                            yAxisLabel="Impact"
                          />
                        ) : undefined}

                        {/* Data table */}
                        <TableContainer2
                          body={engagementRiskList.engagement_risks.map(
                            (engagement_risk) => {
                              return (
                                <tr
                                  key={engagement_risk.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementRiskDetailsAction(
                                          engagement_risk.id
                                        )
                                      );

                                      setUpdateRiskDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Risk */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{engagement_risk.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Risk</strong>
                                          </p>

                                          <p>{engagement_risk.risk}</p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Strategy</strong>
                                          </p>

                                          <p>
                                            {engagement_risk.strategy ? (
                                              engagement_risk.strategy
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={engagement_risk.name}
                                    />
                                  </td>

                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      value={engagement_risk.category}
                                    />
                                  </td>

                                  {/* Impact */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_risk.impact === "High"
                                          ? "danger"
                                          : engagement_risk.impact === "Low"
                                          ? "success"
                                          : "warning"
                                      }
                                      value={engagement_risk.impact}
                                    />
                                  </td>

                                  {/* Likelihood */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_risk.likelihood === "High"
                                          ? "danger"
                                          : engagement_risk.likelihood === "Low"
                                          ? "success"
                                          : "warning"
                                      }
                                      value={engagement_risk.likelihood}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={engagement_risk.owner_name}
                                      image={
                                        engagement_risk.owner_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_risk.status === "Closed"
                                          ? "success"
                                          : "danger"
                                      }
                                      value={engagement_risk.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementRiskQuery.category &&
                            !engagementRiskQuery.impact &&
                            !engagementRiskQuery.likelihood &&
                            !engagementRiskQuery.owner &&
                            !engagementRiskQuery.risk &&
                            !engagementRiskQuery.searchString &&
                            !engagementRiskQuery.status
                          }
                          count={engagementRiskList.count}
                          head={
                            <tr>
                              {/* Risk */}
                              <TableColumnHeadSearch
                                queryObject={engagementRiskQuery}
                                queryVariable="searchString"
                                setQueryObject={setEngagementRiskQuery}
                                setQueryVariable="searchString"
                                title="Risk"
                                width="200px"
                              />

                              {/* Category */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRiskQuery}
                                filterVariable="category"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Information",
                                    icon: "database",
                                    value: "Information",
                                  },
                                  {
                                    display: "Partners",
                                    icon: "briefcase",
                                    value: "Partners",
                                  },
                                  {
                                    display: "Process",
                                    icon: "flow-end",
                                    value: "Process",
                                  },
                                  {
                                    display: "Team",
                                    icon: "people",
                                    value: "Team",
                                  },
                                  {
                                    display: "Technology",
                                    icon: "cloud-download",
                                    value: "Technology",
                                  },
                                  {
                                    display: "Other",
                                    icon: "more",
                                    value: "Other",
                                  },
                                ]}
                                setFilterObject={setEngagementRiskQuery}
                                title="Category"
                                width="125px"
                              />

                              {/* Impact */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRiskQuery}
                                filterVariable="impact"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "High",
                                    icon: "arrow-up",
                                    value: "High",
                                  },
                                  {
                                    display: "Medium",
                                    icon: "arrow-right",
                                    value: "Medium",
                                  },
                                  {
                                    display: "Low",
                                    icon: "arrow-down",
                                    value: "Low",
                                  },
                                ]}
                                setFilterObject={setEngagementRiskQuery}
                                title="Impact"
                                width="125px"
                              />

                              {/* Likelihood */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRiskQuery}
                                filterVariable="likelihood"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "High",
                                    icon: "arrow-up",
                                    value: "High",
                                  },
                                  {
                                    display: "Medium",
                                    icon: "arrow-right",
                                    value: "Medium",
                                  },
                                  {
                                    display: "Low",
                                    icon: "arrow-down",
                                    value: "Low",
                                  },
                                ]}
                                setFilterObject={setEngagementRiskQuery}
                                title="Likelihood"
                                width="125px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={engagementRiskQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={setEngagementRiskQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRiskQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Closed",
                                    icon: "issue-closed",
                                    value: "Closed",
                                  },
                                  {
                                    display: "Open",
                                    icon: "issue",
                                    value: "Open",
                                  },
                                ]}
                                setFilterObject={setEngagementRiskQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementRiskList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementRiskQuery({
                              ...engagementRiskQueryDefault,
                            });
                          }}
                          page={engagementRiskQuery.page}
                          setPageObject={setEngagementRiskQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage key risks"
                    icon="shield"
                    title="Risks"
                  />

                  {/* Actions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddActionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive={!showActionKanban}
                                label="Completed"
                                loading={engagementActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setEngagementActionQuery({
                                      ...engagementActionQuery,
                                      page: 1,
                                      status: "Completed",
                                    });
                                }}
                                value={
                                  engagementActionList.numberOfActionsCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive={!showActionKanban}
                                label="In-progress"
                                loading={engagementActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setEngagementActionQuery({
                                      ...engagementActionQuery,
                                      page: 1,
                                      status: "In-progress",
                                    });
                                }}
                                value={
                                  engagementActionList.numberOfActionsInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive={!showActionKanban}
                                label="On-hold"
                                loading={engagementActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setEngagementActionQuery({
                                      ...engagementActionQuery,
                                      page: 1,
                                      status: "On-hold",
                                    });
                                }}
                                value={
                                  engagementActionList.numberOfActionsOnHold
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Buttons */}
                        <ButtonGroup
                          style={{
                            background: lightMode
                              ? Colors.LIGHT_GRAY3
                              : Colors.DARK_GRAY5,
                            maxWidth: "100%",
                            minWidth: "100%",
                            padding: "10px 10px 0 10px",
                          }}
                        >
                          <Button
                            icon="th"
                            onClick={() => setShowActionKanban(false)}
                          />

                          <Button
                            icon="list-columns"
                            onClick={() => {
                              setEngagementActionQuery({
                                ...engagementActionQueryDefault,
                              });

                              setInitialiseActionKanban(true);

                              setShowActionKanban(true);
                            }}
                          />
                        </ButtonGroup>

                        {/* Data table */}
                        {!showActionKanban && (
                          <TableContainer2
                            body={engagementActionList.engagement_actions.map(
                              (engagement_action) => {
                                return (
                                  <tr
                                    key={engagement_action.id}
                                    onClick={() => {
                                      if (
                                        engagementDetails.engagement
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listEngagementActionDetailsAction(
                                            engagement_action.id
                                          )
                                        );

                                        setUpdateActionDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Action */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5>{engagement_action.name}</H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Action</strong>
                                            </p>

                                            <p>{engagement_action.action}</p>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Comments</strong>
                                            </p>

                                            <p>
                                              {engagement_action.comments ? (
                                                engagement_action.comments
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No comments.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={engagement_action.name}
                                      />
                                    </td>

                                    {/* Category */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableTag
                                        color={
                                          engagement_action.category ===
                                          "Planning"
                                            ? Colors.INDIGO3
                                            : engagement_action.category ===
                                              "Stakeholders"
                                            ? Colors.ROSE3
                                            : engagement_action.category ===
                                              "Resourcing"
                                            ? Colors.CERULEAN3
                                            : engagement_action.category ===
                                              "Delivery"
                                            ? Colors.LIME3
                                            : engagement_action.category ===
                                              "Commercials"
                                            ? Colors.GOLD3
                                            : Colors.BLUE3
                                        }
                                        value={engagement_action.category}
                                      />
                                    </td>

                                    {/* Owner */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={engagement_action.owner_name}
                                        image={
                                          engagement_action.owner_profile_photo
                                        }
                                        showImage
                                      />
                                    </td>

                                    {/* Due */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={engagement_action.due} />
                                    </td>

                                    {/* Status */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          engagement_action.status ===
                                          "Completed"
                                            ? "success"
                                            : engagement_action.status ===
                                              "In-progress"
                                            ? "warning"
                                            : "primary"
                                        }
                                        value={engagement_action.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !engagementActionQuery.category &&
                              !engagementActionQuery.dueEnd &&
                              !engagementActionQuery.dueStart &&
                              !engagementActionQuery.owner &&
                              !engagementActionQuery.searchString &&
                              !engagementActionQuery.status
                            }
                            count={engagementActionList.count}
                            head={
                              <tr>
                                {/* Action */}
                                <TableColumnHeadSearch
                                  queryObject={engagementActionQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setEngagementActionQuery}
                                  setQueryVariable="searchString"
                                  title="Action"
                                  width="200px"
                                />

                                {/* Category */}
                                <TableColumnHeadSelectSimple
                                  filterObject={engagementActionQuery}
                                  filterVariable="category"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Planning",
                                      icon: "playbook",
                                      value: "Planning",
                                    },
                                    {
                                      display: "Stakeholders",
                                      icon: "new-person",
                                      value: "Stakeholders",
                                    },
                                    {
                                      display: "Resourcing",
                                      icon: "people",
                                      value: "Resourcing",
                                    },
                                    {
                                      display: "Delivery",
                                      icon: "gantt-chart",
                                      value: "Delivery",
                                    },
                                    {
                                      display: "Commercials",
                                      icon: "dollar",
                                      value: "Commercials",
                                    },
                                    {
                                      display: "Other",
                                      icon: "more",
                                      value: "Other",
                                    },
                                  ]}
                                  setFilterObject={setEngagementActionQuery}
                                  title="Category"
                                  width="125px"
                                />

                                {/* Owner */}
                                <TableColumnHeadSelect2
                                  filterObject={engagementActionQuery}
                                  filterVariable="owner"
                                  items={managerList.managers}
                                  setFilterObject={setEngagementActionQuery}
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Owner"
                                  width="200px"
                                />

                                {/* Due */}
                                <TableColumnHeadDate
                                  endDateVariable="dueEnd"
                                  queryObject={engagementActionQuery}
                                  setQueryObject={setEngagementActionQuery}
                                  shortRange
                                  startDateVariable="dueStart"
                                  title="Due"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={engagementActionQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Completed",
                                      icon: "confirm",
                                      value: "Completed",
                                    },
                                    {
                                      display: "In-progress",
                                      icon: "refresh",
                                      value: "In-progress",
                                    },
                                    {
                                      display: "On-hold",
                                      icon: "remove",
                                      value: "On-hold",
                                    },
                                  ]}
                                  setFilterObject={setEngagementActionQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              engagementDetails.engagement
                                .user_access_status !== "view"
                            }
                            loading={engagementActionList.loading}
                            noMargin
                            onClearFilters={() => {
                              setEngagementActionQuery({
                                ...engagementActionQueryDefault,
                              });
                            }}
                            page={engagementActionQuery.page}
                            setPageObject={setEngagementActionQuery}
                            setPageVariable="page"
                          />
                        )}

                        {/* Kanban */}
                        {showActionKanban && (
                          <Kanban
                            centerColumns
                            data={generateKanbanDataFromEngagementActions(
                              engagementDetails.engagement.actions
                            )}
                            initialise={initialiseActionKanban}
                            noMargin
                            onClick={(id) => {
                              if (
                                engagementDetails.engagement
                                  .user_access_status !== "view"
                              ) {
                                dispatch(listEngagementActionDetailsAction(id));

                                setUpdateActionDrawerIsOpen(true);
                              }
                            }}
                            onDragEnd={(result) => {
                              const actionId = result.draggableId;
                              const actionStatus =
                                result.destination.droppableId;

                              updateActionStatus(actionId, actionStatus);
                            }}
                            searchBarMargin="-45px 0 20px 70px"
                            searchBarPlaceholder="Search actions"
                            setInitialise={setInitialiseActionKanban}
                            statusCategories={[
                              {
                                intent: "primary",
                                value: "On-hold",
                              },
                              {
                                intent: "warning",
                                value: "In-progress",
                              },
                              {
                                intent: "success",
                                value: "Completed",
                              },
                            ]}
                          />
                        )}
                      </>
                    }
                    help="Assign and review actions to complete the engagement"
                    icon="take-action"
                    message={
                      <>
                        advaise.app has prefilled this section with a standard
                        set of actions. Assign or delete them as appropriate.
                      </>
                    }
                    messageIntent="primary"
                    title="Actions"
                  />

                  {/* Issues */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddIssueDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Closed */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Closed"
                                loading={engagementDeliveryIssueList.loading}
                                onClick={() => {
                                  setEngagementDeliveryIssueQuery({
                                    ...engagementDeliveryIssueQuery,
                                    page: 1,
                                    status: "Closed",
                                  });
                                }}
                                value={
                                  engagementDeliveryIssueList.numberOfIssuesClosed
                                }
                              />

                              {/* Open */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Open"
                                loading={engagementDeliveryIssueList.loading}
                                onClick={() => {
                                  setEngagementDeliveryIssueQuery({
                                    ...engagementDeliveryIssueQuery,
                                    page: 1,
                                    status: "Open",
                                  });
                                }}
                                value={
                                  engagementDeliveryIssueList.numberOfIssuesOpen
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={engagementDeliveryIssueList.engagement_issues.map(
                            (engagement_issue) => {
                              return (
                                <tr
                                  key={engagement_issue.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementDeliveryIssueDetailsAction(
                                          engagement_issue.id
                                        )
                                      );

                                      setUpdateIssueDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Issue */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{engagement_issue.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Issue</strong>
                                          </p>

                                          <p>{engagement_issue.issue}</p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Strategy</strong>
                                          </p>

                                          <p>
                                            {engagement_issue.strategy ? (
                                              engagement_issue.strategy
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={engagement_issue.name}
                                    />
                                  </td>

                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      value={engagement_issue.category}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={engagement_issue.owner_name}
                                      image={
                                        engagement_issue.owner_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_issue.status === "Closed"
                                          ? "success"
                                          : "danger"
                                      }
                                      value={engagement_issue.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementDeliveryIssueQuery.category &&
                            !engagementDeliveryIssueQuery.issue &&
                            !engagementDeliveryIssueQuery.owner &&
                            !engagementDeliveryIssueQuery.searchString &&
                            !engagementDeliveryIssueQuery.status
                          }
                          count={engagementDeliveryIssueList.count}
                          head={
                            <tr>
                              {/* Issue */}
                              <TableColumnHeadSearch
                                queryObject={engagementDeliveryIssueQuery}
                                queryVariable="searchString"
                                setQueryObject={setEngagementDeliveryIssueQuery}
                                setQueryVariable="searchString"
                                title="Issue"
                                width="200px"
                              />

                              {/* Category */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementDeliveryIssueQuery}
                                filterVariable="category"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Information",
                                    icon: "database",
                                    value: "Information",
                                  },
                                  {
                                    display: "Partners",
                                    icon: "briefcase",
                                    value: "Partners",
                                  },
                                  {
                                    display: "Process",
                                    icon: "flow-end",
                                    value: "Process",
                                  },
                                  {
                                    display: "Team",
                                    icon: "people",
                                    value: "Team",
                                  },
                                  {
                                    display: "Technology",
                                    icon: "cloud-download",
                                    value: "Technology",
                                  },
                                  {
                                    display: "Other",
                                    icon: "more",
                                    value: "Other",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementDeliveryIssueQuery
                                }
                                title="Category"
                                width="125px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={engagementDeliveryIssueQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={
                                  setEngagementDeliveryIssueQuery
                                }
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementDeliveryIssueQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Closed",
                                    icon: "issue-closed",
                                    value: "Closed",
                                  },
                                  {
                                    display: "Open",
                                    icon: "issue",
                                    value: "Open",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementDeliveryIssueQuery
                                }
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementDeliveryIssueList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementDeliveryIssueQuery({
                              ...engagementDeliveryIssueQueryDefault,
                            });
                          }}
                          page={engagementDeliveryIssueQuery.page}
                          setPageObject={setEngagementDeliveryIssueQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage issues"
                    icon="issue"
                    title="Issues"
                  />

                  {/* Dependencies */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddDependencyDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Closed */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Closed"
                                loading={engagementDependencyList.loading}
                                onClick={() => {
                                  setEngagementDependencyQuery({
                                    ...engagementDependencyQuery,
                                    page: 1,
                                    status: "Closed",
                                  });
                                }}
                                value={
                                  engagementDependencyList.numberOfDependenciesClosed
                                }
                              />

                              {/* Open */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Open"
                                loading={engagementDependencyList.loading}
                                onClick={() => {
                                  setEngagementDependencyQuery({
                                    ...engagementDependencyQuery,
                                    page: 1,
                                    status: "Open",
                                  });
                                }}
                                value={
                                  engagementDependencyList.numberOfDependenciesOpen
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={engagementDependencyList.engagement_dependencies.map(
                            (engagement_dependency) => {
                              return (
                                <tr
                                  key={engagement_dependency.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementDependencyDetailsAction(
                                          engagement_dependency.id
                                        )
                                      );

                                      setUpdateDependencyDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Dependency */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{engagement_dependency.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Dependency</strong>
                                          </p>

                                          <p>
                                            {engagement_dependency.dependency}
                                          </p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Notes</strong>
                                          </p>

                                          <p>
                                            {engagement_dependency.notes ? (
                                              engagement_dependency.notes
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={engagement_dependency.name}
                                    />
                                  </td>

                                  {/* Owner */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={engagement_dependency.owner_name}
                                      image={
                                        engagement_dependency.owner_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_dependency.status ===
                                        "Closed"
                                          ? "success"
                                          : "danger"
                                      }
                                      value={engagement_dependency.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementDependencyQuery.dependency &&
                            !engagementDependencyQuery.owner &&
                            !engagementDependencyQuery.searchString &&
                            !engagementDependencyQuery.status
                          }
                          count={engagementDependencyList.count}
                          head={
                            <tr>
                              {/* Dependency */}
                              <TableColumnHeadSearch
                                queryObject={engagementDependencyQuery}
                                queryVariable="searchString"
                                setQueryObject={setEngagementDependencyQuery}
                                setQueryVariable="searchString"
                                title="Dependency"
                                width="200px"
                              />

                              {/* Owner */}
                              <TableColumnHeadSelect2
                                filterObject={engagementDependencyQuery}
                                filterVariable="owner"
                                items={managerList.managers}
                                setFilterObject={setEngagementDependencyQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Owner"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementDependencyQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Closed",
                                    icon: "issue-closed",
                                    value: "Closed",
                                  },
                                  {
                                    display: "Open",
                                    icon: "issue",
                                    value: "Open",
                                  },
                                ]}
                                setFilterObject={setEngagementDependencyQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementDependencyList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementDependencyQuery({
                              ...engagementDependencyQueryDefault,
                            });
                          }}
                          page={engagementDependencyQuery.page}
                          setPageObject={setEngagementDependencyQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Track dependencies"
                    icon="flows"
                    title="Dependencies"
                  />
                </div>
              }
            />

            {/* Strategy */}
            <Tab
              id="engagement-strategy-tab"
              title="Strategy"
              panel={
                <div>
                  {/* Needs analysis */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            engagementDetails.engagement.user_access_status ===
                            "view"
                          }
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(listEngagementDetailsAction(id));

                            setUpdateNeedsAnalysisDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Edit"
                        />

                        <Button icon="import" intent="primary" text="Import" />
                      </>
                    }
                    content={
                      <>
                        {/* Change drivers */}
                        <TabSectionField
                          label="Change drivers"
                          value={
                            <TabSectionFieldCard
                              loading={engagementDetails.loading}
                              text={
                                engagementDetails.loading ? undefined : engagementDetails
                                    .engagement.change_drivers ? (
                                  engagementDetails.engagement.change_drivers
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What is driving the client to change?"
                            />
                          }
                        />

                        {/* Strategic options */}
                        <TabSectionField
                          label="Strategic options"
                          value={
                            <TabSectionFieldCard
                              loading={engagementDetails.loading}
                              text={
                                engagementDetails.loading ? undefined : engagementDetails
                                    .engagement.strategic_options ? (
                                  engagementDetails.engagement.strategic_options
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What options has the client explored?"
                            />
                          }
                        />

                        {/* Theory of change */}
                        <TabSectionField
                          label="Theory of change"
                          value={
                            <TabSectionFieldCard
                              loading={engagementDetails.loading}
                              text={
                                engagementDetails.loading ? undefined : engagementDetails
                                    .engagement.theory_of_change ? (
                                  engagementDetails.engagement.theory_of_change
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What has the client decided to do?"
                            />
                          }
                        />

                        {/* Issues */}
                        <TabSectionField
                          label="Issues"
                          value={
                            <TabSectionFieldCard
                              loading={engagementDetails.loading}
                              text={
                                engagementDetails.loading ? undefined : engagementDetails
                                    .engagement.issues ? (
                                  engagementDetails.engagement.issues
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="What challenges face the client in moving forward?"
                            />
                          }
                        />

                        {/* The project */}
                        <TabSectionField
                          bottom
                          label="The project"
                          value={
                            <TabSectionFieldCard
                              loading={engagementDetails.loading}
                              text={
                                engagementDetails.loading ? undefined : engagementDetails
                                    .engagement.our_solution ? (
                                  engagementDetails.engagement.our_solution
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No comments
                                  </span>
                                )
                              }
                              title="How will this project support the change?"
                            />
                          }
                        />
                      </>
                    }
                    help="Summarise the client's needs"
                    icon="compass"
                    message="If this project is the result of a successful proposal, you may have already undertaken a needs analysis. If so, you can import the needs analysis content from the proposal."
                    messageIntent="primary"
                    title="Needs analysis"
                  />

                  {/* Key stakeholders */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddEngagementContactDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        {/* Stakeholder matrix */}
                        {engagementContactList.engagement_contacts.length >
                        0 ? (
                          <Matrix
                            colors={[
                              [
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.RED5}33`,
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.RED5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                              [
                                `${Colors.ORANGE5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                                `${Colors.GREEN5}33`,
                              ],
                            ]}
                            data={engagementContactList.engagement_contacts}
                            intents={[
                              [
                                "danger",
                                "danger",
                                "danger",
                                "warning",
                                "success",
                              ],
                              [
                                "danger",
                                "danger",
                                "warning",
                                "warning",
                                "success",
                              ],
                              [
                                "danger",
                                "warning",
                                "warning",
                                "success",
                                "success",
                              ],
                              [
                                "warning",
                                "warning",
                                "success",
                                "success",
                                "success",
                              ],
                              [
                                "warning",
                                "success",
                                "success",
                                "success",
                                "success",
                              ],
                            ]}
                            interactive
                            itemNameVariable="contact_name"
                            leftColumnWidth="150px"
                            onClickAccessTest={() =>
                              engagementDetails.engagement
                                .user_access_status !== "view"
                            }
                            onClickListDetailsAction={
                              listEngagementContactDetailsAction
                            }
                            onClickSetDrawerIsOpen={
                              setUpdateEngagementContactDrawerIsOpen
                            }
                            xAxisCategories={[1, 2, 3, 4, 5]}
                            xAxisDisplayCategories={[
                              "Very Low",
                              "Low",
                              "Medium",
                              "High",
                              "Very High",
                            ]}
                            xAxisFilterVariable="level_of_support"
                            xAxisLabel="Support"
                            yAxisCategories={[5, 4, 3, 2, 1]}
                            yAxisDisplayCategories={[
                              "Very High",
                              "High",
                              "Medium",
                              "Low",
                              "Very Low",
                            ]}
                            yAxisFilterVariable="level_of_influence"
                            yAxisLabel="Influence"
                          />
                        ) : undefined}

                        {/* Search field */}
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search stakeholder"
                            searching={engagementContactList.loading}
                            setValueObject={setEngagementContactQuery}
                            setValueVariable="searchString"
                            value={engagementContactQuery.member}
                          />
                        </div>

                        {/* Stakeholders */}
                        <ItemCardList
                          content={engagementContactList.engagement_contacts.map(
                            (engagement_contact) => {
                              return (
                                <ContactCard
                                  additionalInfo={
                                    <>
                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Level of influence</strong>
                                      </p>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <TabSectionFieldProgressBar
                                            rating={
                                              engagement_contact.level_of_influence /
                                              5
                                            }
                                          />
                                        </div>

                                        <div
                                          className={[
                                            Classes.TEXT_MUTED,
                                            Classes.TEXT_SMALL,
                                          ].join(" ")}
                                        >
                                          {engagement_contact.level_of_influence ===
                                          5
                                            ? "Very high"
                                            : engagement_contact.level_of_influence ===
                                              4
                                            ? "High"
                                            : engagement_contact.level_of_influence ===
                                              3
                                            ? "Medium"
                                            : engagement_contact.level_of_influence ===
                                              2
                                            ? "Low"
                                            : "Very low"}
                                        </div>
                                      </div>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Level of support</strong>
                                      </p>

                                      <div
                                        style={{
                                          marginBottom: "10px",
                                        }}
                                      >
                                        <div
                                          style={{
                                            alignItems: "center",
                                            display: "flex",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          <TabSectionFieldProgressBar
                                            rating={
                                              engagement_contact.level_of_support /
                                              5
                                            }
                                          />
                                        </div>

                                        <div
                                          className={[
                                            Classes.TEXT_MUTED,
                                            Classes.TEXT_SMALL,
                                          ].join(" ")}
                                        >
                                          {engagement_contact.level_of_support ===
                                          5
                                            ? "Very high"
                                            : engagement_contact.level_of_support ===
                                              4
                                            ? "High"
                                            : engagement_contact.level_of_support ===
                                              3
                                            ? "Medium"
                                            : engagement_contact.level_of_support ===
                                              2
                                            ? "Low"
                                            : "Very low"}
                                        </div>
                                      </div>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Expectations</strong>
                                      </p>

                                      <p>
                                        {engagement_contact.expectations ? (
                                          engagement_contact.expectations
                                        ) : (
                                          <span className={Classes.TEXT_MUTED}>
                                            No comments.
                                          </span>
                                        )}
                                      </p>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>
                                          Stakeholder engagement strategy
                                        </strong>
                                      </p>

                                      <p>
                                        {engagement_contact.comments ? (
                                          engagement_contact.comments
                                        ) : (
                                          <span className={Classes.TEXT_MUTED}>
                                            No comments.
                                          </span>
                                        )}
                                      </p>

                                      <Divider
                                        style={{
                                          background: lightMode
                                            ? Colors.GRAY1
                                            : Colors.GRAY5,
                                          margin: "10px 0 10px 0",
                                        }}
                                      />

                                      <p>
                                        <strong>Relationship owner</strong>
                                      </p>

                                      <div style={{ marginBottom: "10px" }}>
                                        <NameValue
                                          email={
                                            engagement_contact.relationship_owner_name
                                          }
                                          image={
                                            engagement_contact.relationship_owner_profile_photo
                                          }
                                          invertColor
                                        />
                                      </div>
                                    </>
                                  }
                                  borderColor={Colors.ROSE3}
                                  data={engagement_contact.contact_data}
                                  interactive
                                  key={engagement_contact.id}
                                  onClick={() => {
                                    dispatch(
                                      listEngagementContactDetailsAction(
                                        engagement_contact.id
                                      )
                                    );

                                    setUpdateEngagementContactDrawerIsOpen(
                                      true
                                    );
                                  }}
                                />
                              );
                            }
                          )}
                          count={engagementContactList.count}
                          loading={engagementContactList.loading}
                          noMargin
                          noResultsMessage="Add contacts."
                          noResultsTitle="No contacts found"
                          page={engagementContactQuery.page}
                          setPageObject={setEngagementContactQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Define your stakeholder management plan"
                    icon="following"
                    title="Key stakeholders"
                  />

                  {/* Our approach */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listEngagementDetailsAction(id));

                          setUpdateApproachDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Approach summary */}
                        <TabSectionField
                          label="Approach summary"
                          loading={engagementDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={
                                engagementDetails.engagement
                                  .approach_summary ? (
                                  engagementDetails.engagement.approach_summary
                                ) : (
                                  <span className={Classes.TEXT_MUTED}>
                                    No description
                                  </span>
                                )
                              }
                            />
                          }
                        />

                        {/* Approach diagram */}
                        <TabSectionField
                          bottom
                          label="Approach diagram"
                          loading={engagementDetails.loading}
                          value={
                            <TabSectionFieldImage
                              fill
                              image={
                                engagementDetails.engagement.approach_diagram
                              }
                              interactive
                            />
                          }
                        />
                      </>
                    }
                    help="Summarise your approach to the engagement"
                    icon="gantt-chart"
                    title="Our approach"
                  />
                </div>
              }
            />

            {/* Plan */}
            <Tab
              id="engagement-plan-tab"
              title="Plan"
              panel={
                <div>
                  {/* Services */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddServiceDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        {/* Search field */}
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search service"
                            searching={engagementServiceList.loading}
                            setValueObject={setEngagementServiceQuery}
                            setValueVariable="searchString"
                            value={engagementServiceQuery.searchString}
                          />
                        </div>

                        {/* Services */}
                        <TabSectionFieldMultifieldCardList
                          content={engagementServiceList.engagement_services.map(
                            (engagement_service, i, { length }) => {
                              return (
                                <TabSectionField
                                  bottom={length - 1 === i}
                                  key={engagement_service.id}
                                  label={engagement_service.name}
                                  value={
                                    <TabSectionFieldMultifieldCard
                                      children={
                                        <>
                                          {/* Description */}
                                          <TabSectionField
                                            label="Description"
                                            value={
                                              engagement_service.description
                                            }
                                          />

                                          {/* Benefits */}
                                          <TabSectionField
                                            bottom
                                            label="Benefits"
                                            value={engagement_service.benefits}
                                          />
                                        </>
                                      }
                                      interactive={
                                        engagementDetails.engagement
                                          .user_access_status !== "view"
                                      }
                                      onClick={() => {
                                        if (
                                          engagementDetails.engagement
                                            .user_access_status !== "view"
                                        ) {
                                          dispatch(
                                            listEngagementServiceDetailsAction(
                                              engagement_service.id
                                            )
                                          );

                                          setUpdateServiceDrawerIsOpen(true);
                                        }
                                      }}
                                    />
                                  }
                                />
                              );
                            }
                          )}
                          count={engagementServiceList.count}
                          loading={engagementServiceList.loading}
                          noResultsMessage="Add services."
                          noResultsTitle="No services found"
                          page={engagementServiceQuery.page}
                          setPageObject={setEngagementServiceQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Scope the services that you will deliver"
                    icon="heat-grid"
                    title="Services"
                  />

                  {/* Deliverables */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddDeliverableDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={engagementServiceDeliverableList.engagement_service_deliverables.map(
                            (deliverable) => {
                              return (
                                <tr
                                  key={deliverable.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementServiceDeliverableDetailsAction(
                                          deliverable.id
                                        )
                                      );

                                      setUpdateDeliverableDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Deliverable */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{deliverable.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Description</strong>
                                          </p>

                                          <p>
                                            {deliverable.description ? (
                                              deliverable.description
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No description.
                                              </span>
                                            )}
                                          </p>

                                          {deliverable.template && (
                                            <div
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <Button
                                                icon="document"
                                                intent="primary"
                                                minimal
                                                onClick={() =>
                                                  window.open(
                                                    deliverable.template,
                                                    "_blank",
                                                    "noopener,noreferrer"
                                                  )
                                                }
                                                style={{
                                                  color: lightMode
                                                    ? Colors.BLUE5
                                                    : Colors.BLUE3,
                                                }}
                                                text="Template"
                                              />
                                            </div>
                                          )}
                                        </>
                                      }
                                      tagText={deliverable.type}
                                      text={deliverable.name}
                                    />
                                  </td>

                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={deliverable.service_name}
                                    />
                                  </td>

                                  {/* Due */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={deliverable.due} />
                                  </td>

                                  {/* Milestone */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={deliverable.milestone_name}
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        deliverable.status === "Accepted"
                                          ? "success"
                                          : deliverable.status === "Delivered"
                                          ? "warning"
                                          : "primary"
                                      }
                                      value={deliverable.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementServiceDeliverableQuery.dueEnd &&
                            !engagementServiceDeliverableQuery.dueStart &&
                            !engagementServiceDeliverableQuery.milestone &&
                            !engagementServiceDeliverableQuery.searchString &&
                            !engagementServiceDeliverableQuery.service &&
                            !engagementServiceDeliverableQuery.status
                          }
                          count={engagementServiceDeliverableList.count}
                          head={
                            <tr>
                              {/* Deliverable */}
                              <TableColumnHeadSearch
                                queryObject={engagementServiceDeliverableQuery}
                                queryVariable="searchString"
                                setQueryObject={
                                  setEngagementServiceDeliverableQuery
                                }
                                setQueryVariable="searchString"
                                title="Deliverable"
                                width="300px"
                              />

                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={engagementServiceDeliverableQuery}
                                filterVariable="service"
                                items={
                                  engagementServiceList.engagement_services
                                }
                                setFilterObject={
                                  setEngagementServiceDeliverableQuery
                                }
                                setQueryObject={setEngagementServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={engagementServiceDeliverableQuery}
                                setQueryObject={
                                  setEngagementServiceDeliverableQuery
                                }
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Milestone */}
                              <TableColumnHeadSearch
                                queryObject={engagementServiceDeliverableQuery}
                                queryVariable="milestone"
                                setQueryObject={
                                  setEngagementServiceDeliverableQuery
                                }
                                setQueryVariable="milestone"
                                title="Milestone"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementServiceDeliverableQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Accepted",
                                    icon: "confirm",
                                    value: "Accepted",
                                  },
                                  {
                                    display: "Delivered",
                                    icon: "circle",
                                    value: "Delivered",
                                  },
                                  {
                                    display: "Not delivered",
                                    icon: "refresh",
                                    value: "Not delivered",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementServiceDeliverableQuery
                                }
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementServiceDeliverableList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementServiceDeliverableQuery({
                              ...engagementServiceDeliverableQueryDefault,
                            });
                          }}
                          page={engagementServiceDeliverableQuery.page}
                          setPageObject={setEngagementServiceDeliverableQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage the engagement's deliverables"
                    icon="briefcase"
                    title="Deliverables"
                  />

                  {/* Schedule */}
                  <TabSection2
                    buttons={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={
                            engagementDetails.engagement.user_access_status ===
                            "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddActivityDrawerIsOpen(true);
                          }}
                          text="Add"
                        />

                        <ButtonGroup>
                          <Button
                            icon="media"
                            onClick={async () => {
                              const imageFile =
                                await generateImageFromComponent(scheduleRef);

                              setEngagementDocument((prevState) => {
                                return {
                                  ...prevState,
                                  scheduleDiagram: imageFile,
                                };
                              });
                            }}
                            style={{ marginRight: "5px" }}
                          />

                          <Button
                            icon="maximize"
                            onClick={() => {
                              setScheduleDialogIsOpen(true);
                            }}
                          />
                        </ButtonGroup>
                      </div>
                    }
                    content={
                      <div>
                        <Timeline4
                          data={generateTimelineDataFromEngagementServices(
                            engagementDetails.engagement.services
                          )}
                          height="70vh"
                          itemListHeading="Services/Activities"
                          loading={engagementDetails.loading}
                          onClick={(id, type) => {
                            if (type === "child") {
                              dispatch(
                                listEngagementServiceActivityDetailsAction(
                                  id.split(".")[1]
                                )
                              );

                              setUpdateActivityDrawerIsOpen(true);
                            } else if (type === "item") {
                              dispatch(listEngagementServiceDetailsAction(id));

                              setUpdateServiceDrawerIsOpen(true);
                            } else if (type === "milestone") {
                              dispatch(
                                listEngagementServiceDeliverableDetailsAction(
                                  id.split(".")[1]
                                )
                              );

                              setUpdateDeliverableDrawerIsOpen(true);
                            } else {
                              dispatch(
                                listEngagementLabourExpenseDetailsAction(
                                  id.split(".")[1]
                                )
                              );

                              setUpdateLabourExpenseDrawerIsOpen(true);
                            }
                          }}
                          resourceData={generateTimelineResourceDataFromEngagementServices(
                            engagementDetails.engagement.services
                          )}
                          scheduleRef={scheduleRef}
                          showDates
                        />
                      </div>
                    }
                    help="Manage the engagement's schedule"
                    icon="gantt-chart"
                    title="Schedule"
                  />
                </div>
              }
            />

            {/* Resourcing */}
            <Tab
              id="engagement-resourcing-tab"
              title="Resourcing"
              panel={
                <div>
                  {/* Labour expenses */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddLabourExpenseDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={engagementLabourExpenseList.engagement_labour_expenses.map(
                            (engagement_labour_expense) => {
                              return (
                                <tr
                                  key={engagement_labour_expense.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementLabourExpenseDetailsAction(
                                          engagement_labour_expense.id
                                        )
                                      );

                                      setUpdateLabourExpenseDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Role */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <LabourExpenseInfo
                                          labourExpense={
                                            engagement_labour_expense
                                          }
                                        />
                                      }
                                      text={
                                        engagement_labour_expense.role_data
                                          ? engagement_labour_expense.role_data
                                              .name
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={
                                        engagement_labour_expense.engagement_service_name
                                      }
                                    />
                                  </td>

                                  {/* Utilisation */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_labour_expense.utilisation >
                                        110
                                          ? "danger"
                                          : engagement_labour_expense.utilisation >
                                            100
                                          ? "warning"
                                          : "success"
                                      }
                                      value={
                                        <PercentageValue
                                          percentage={
                                            engagement_labour_expense.utilisation
                                          }
                                        />
                                      }
                                    />
                                  </td>

                                  {/* Effort */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      value={`${
                                        convertMinutesToHoursAndMinutes(
                                          engagement_labour_expense.minutes
                                        ).split(":")[0]
                                      }h ${
                                        convertMinutesToHoursAndMinutes(
                                          engagement_labour_expense.minutes
                                        ).split(":")[1]
                                      }m`}
                                    />
                                  </td>

                                  {/* Cost */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_labour_expense.cost}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_labour_expense.margin}
                                      tagNumber={
                                        engagement_labour_expense.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Fee */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_labour_expense.fee}
                                    />

                                    {activeOrganisation.currency !==
                                      engagementDetails.engagement
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            engagement_labour_expense.fee_quote
                                          }
                                          tagText={
                                            engagementDetails.engagement
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementLabourExpenseQuery.engagementService &&
                            !engagementLabourExpenseQuery.role
                          }
                          count={engagementLabourExpenseList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="4"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    engagementLabourExpenseList.total_cost
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    engagementLabourExpenseList.total_margin
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={engagementLabourExpenseList.total_fee}
                                />

                                {activeOrganisation.currency !==
                                  engagementDetails.engagement
                                    .currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        engagementLabourExpenseList.total_fee_quote
                                      }
                                      tagText={
                                        engagementDetails.engagement
                                          .currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Role */}
                              <TableColumnHeadSelect2
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="role"
                                items={roleList.roles}
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                setQueryObject={setRoleQuery}
                                setQueryVariable="searchString"
                                title="Role"
                                width="200px"
                              />

                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="engagementService"
                                items={
                                  engagementServiceList.engagement_services
                                }
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                setQueryObject={setEngagementServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Utilisation */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "utilisation",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-utilisation",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                title="Utilisation"
                                width="125px"
                              />

                              {/* Effort */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-minutes",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                title="Effort"
                                width="125px"
                              />

                              {/* Cost */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "cost",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-cost",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                title="Cost"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                title="Margin"
                                width="125px"
                              />

                              {/* Fee */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "fee",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-fee",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementLabourExpenseQuery
                                }
                                title="Fee"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementLabourExpenseList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementLabourExpenseQuery({
                              ...engagementLabourExpenseQueryDefault,
                            });
                          }}
                          page={engagementLabourExpenseQuery.page}
                          setPageObject={setEngagementLabourExpenseQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Estimate the human resources cost"
                    icon="walk"
                    message={
                      <>
                        This section captures internal labour costs. advaise.app
                        populates the hourly cost rate for each role based on
                        information from the{" "}
                        <LinkTag
                          label="Roles"
                          onClick={() => {
                            navigate(`/roles`);
                          }}
                        />{" "}
                        module. The system also generates tentative staff
                        bookings based on start and end dates.
                      </>
                    }
                    messageIntent="primary"
                    title="Labour expenses"
                  />

                  {/* Non-labour expenses */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddNonLabourExpenseDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={engagementNonLabourExpenseList.engagement_non_labour_expenses.map(
                            (engagement_non_labour_expense) => {
                              return (
                                <tr
                                  key={engagement_non_labour_expense.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementNonLabourExpenseDetailsAction(
                                          engagement_non_labour_expense.id
                                        )
                                      );

                                      setUpdateNonLabourExpenseDrawerIsOpen(
                                        true
                                      );
                                    }
                                  }}
                                >
                                  {/* Category */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <NonLabourExpenseInfo
                                          nonLabourExpense={
                                            engagement_non_labour_expense
                                          }
                                        />
                                      }
                                      text={
                                        engagement_non_labour_expense.category_data
                                          ? engagement_non_labour_expense
                                              .category_data.name
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={
                                        engagement_non_labour_expense.engagement_service_name
                                      }
                                    />
                                  </td>

                                  {/* Cost */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={
                                        engagement_non_labour_expense.cost
                                      }
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={
                                        engagement_non_labour_expense.margin
                                      }
                                      tagNumber={
                                        engagement_non_labour_expense.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Fee */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_non_labour_expense.fee}
                                    />

                                    {activeOrganisation.currency !==
                                      engagementDetails.engagement
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            engagement_non_labour_expense.fee_quote
                                          }
                                          tagText={
                                            engagementDetails.engagement
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementNonLabourExpenseQuery.category &&
                            !engagementNonLabourExpenseQuery.engagementService
                          }
                          count={engagementNonLabourExpenseList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    engagementNonLabourExpenseList.total_cost
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    engagementNonLabourExpenseList.total_margin
                                  }
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    engagementNonLabourExpenseList.total_fee
                                  }
                                />

                                {activeOrganisation.currency !==
                                  engagementDetails.engagement
                                    .currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        engagementNonLabourExpenseList.total_fee_quote
                                      }
                                      tagText={
                                        engagementDetails.engagement
                                          .currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Category */}
                              <TableColumnHeadSelect2
                                filterObject={engagementNonLabourExpenseQuery}
                                filterVariable="category"
                                items={expenseCategoryList.expense_categories}
                                setFilterObject={
                                  setEngagementNonLabourExpenseQuery
                                }
                                setQueryObject={setExpenseCategoryQuery}
                                setQueryVariable="searchString"
                                title="Category"
                                width="200px"
                              />

                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={engagementNonLabourExpenseQuery}
                                filterVariable="engagementService"
                                items={
                                  engagementServiceList.engagement_services
                                }
                                setFilterObject={
                                  setEngagementNonLabourExpenseQuery
                                }
                                setQueryObject={setEngagementServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Cost */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementNonLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "cost",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-cost",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementNonLabourExpenseQuery
                                }
                                title="Cost"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementNonLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementNonLabourExpenseQuery
                                }
                                title="Margin"
                                width="125px"
                              />

                              {/* Fee */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementNonLabourExpenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "fee",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-fee",
                                  },
                                ]}
                                setFilterObject={
                                  setEngagementNonLabourExpenseQuery
                                }
                                title="Fee"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementNonLabourExpenseList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementNonLabourExpenseQuery({
                              ...engagementNonLabourExpenseQueryDefault,
                            });
                          }}
                          page={engagementNonLabourExpenseQuery.page}
                          setPageObject={setEngagementNonLabourExpenseQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Estimate the materials cost"
                    icon="credit-card"
                    title="Non-labour expenses"
                  />

                  {/* Staff bookings */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Utilisation */}
                              <EngagementBookingUtilisationCard
                                engagementBookingList={bookingList}
                                fill
                                showInsights
                              />

                              {/* Time */}
                              <EngagementBookingTimeCard
                                engagementBookingList={bookingList}
                                fill
                                showInsights
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={bookingList.bookings.map((booking) => {
                            return (
                              <tr key={booking.id}>
                                {/* Staff */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    email={booking.staff_name}
                                    image={booking.staff_profile_photo}
                                    info={<BookingInfo bookingData={booking} />}
                                    showImage
                                  />
                                </td>

                                {/* Booking code */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    text={
                                      booking.booking_code_data
                                        ? booking.booking_code_data.name
                                        : undefined
                                    }
                                  />
                                </td>

                                {/* Billable */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      booking.booking_code_data.billable
                                        ? "success"
                                        : "primary"
                                    }
                                    value={
                                      booking.booking_code_data.billable
                                        ? "Billable"
                                        : "Not billable"
                                    }
                                  />
                                </td>

                                {/* Type */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      booking.type === "Confirmed"
                                        ? "primary"
                                        : booking.type === "Realised"
                                        ? "success"
                                        : "warning"
                                    }
                                    value={booking.type}
                                  />
                                </td>

                                {/* Effort */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    value={`${
                                      convertMinutesToHoursAndMinutes(
                                        booking.minutes
                                      ).split(":")[0]
                                    }h ${
                                      convertMinutesToHoursAndMinutes(
                                        booking.minutes
                                      ).split(":")[1]
                                    }m`}
                                  />
                                </td>

                                {/* Start */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={booking.start} />
                                </td>

                                {/* End */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={booking.end} />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !bookingQuery.billable &&
                            !bookingQuery.bookingCode &&
                            !bookingQuery.endEnd &&
                            !bookingQuery.endStart &&
                            !bookingQuery.searchString &&
                            !bookingQuery.staff &&
                            !bookingQuery.startEnd &&
                            !bookingQuery.startStart &&
                            !bookingQuery.type
                          }
                          count={bookingList.count}
                          head={
                            <tr>
                              {/* Staff */}
                              <TableColumnHeadSelect2
                                filterObject={bookingQuery}
                                filterVariable="staff"
                                items={staffList.staff}
                                setFilterObject={setBookingQuery}
                                setQueryObject={setStaffQuery}
                                setQueryVariable="searchString"
                                title="Staff"
                                width="200px"
                              />

                              {/* Booking code */}
                              <TableColumnHeadSelect2
                                filterObject={bookingQuery}
                                filterVariable="bookingCode"
                                items={bookingCodeList.booking_codes}
                                setFilterObject={setBookingQuery}
                                setQueryObject={setBookingCodeQuery}
                                setQueryVariable="searchString"
                                title="Booking code"
                                width="200px"
                              />

                              {/* Billable */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="billable"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Billable",
                                    icon: "dollar",
                                    value: "true",
                                  },
                                  {
                                    display: "Not billable",
                                    icon: "cross",
                                    value: "false",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Billable"
                                width="125px"
                              />

                              {/* Type */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="type"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Confirmed",
                                    icon: "circle",
                                    value: "Confirmed",
                                  },
                                  {
                                    display: "Realised",
                                    icon: "confirm",
                                    value: "Realised",
                                  },
                                  {
                                    display: "Tentative",
                                    icon: "remove",
                                    value: "Tentative",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Type"
                                width="125px"
                              />

                              {/* Effort */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-minutes",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Effort"
                                width="125px"
                              />

                              {/* Start */}
                              <TableColumnHeadDate
                                endDateVariable="startEnd"
                                queryObject={bookingQuery}
                                setQueryObject={setBookingQuery}
                                shortRange
                                startDateVariable="startStart"
                                title="Start"
                                width="125px"
                              />

                              {/* End */}
                              <TableColumnHeadDate
                                endDateVariable="endEnd"
                                queryObject={bookingQuery}
                                setQueryObject={setBookingQuery}
                                shortRange
                                startDateVariable="endStart"
                                title="End"
                                width="125px"
                              />
                            </tr>
                          }
                          loading={bookingList.loading}
                          noMargin
                          onClearFilters={() => {
                            setBookingQuery({
                              ...bookingQueryDefault,
                            });
                          }}
                          page={bookingQuery.page}
                          setPageObject={setBookingQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review staff bookings"
                    icon="timeline-events"
                    title="Staff bookings"
                  />

                  {/* Expenses */}
                  <TabSection2
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={expenseList.expenses.map((expense) => {
                            return (
                              <tr key={expense.id}>
                                {/* Category */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    info={<ExpenseInfo expenseData={expense} />}
                                    text={expense.category_name}
                                  />
                                </td>

                                {/* Staff */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    image={expense.staff_profile_photo}
                                    showImage
                                    text={expense.staff_name}
                                  />
                                </td>

                                {/* Booking code */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    text={
                                      expense.booking_code_data
                                        ? expense.booking_code_data.name
                                        : undefined
                                    }
                                  />
                                </td>

                                {/* Billable */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      expense.booking_code_data
                                        ? expense.booking_code_data.billable
                                          ? "success"
                                          : "primary"
                                        : undefined
                                    }
                                    value={
                                      expense.booking_code_data
                                        ? expense.booking_code_data.billable
                                          ? "Billable"
                                          : "Not billable"
                                        : undefined
                                    }
                                  />
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={expense.value} />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !expenseQuery.billable &&
                            !expenseQuery.bookingCode &&
                            !expenseQuery.category &&
                            !expenseQuery.searchString &&
                            !expenseQuery.staff
                          }
                          count={expenseList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="4"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              {/* Total */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber number={expenseList.totalValue} />
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Category */}
                              <TableColumnHeadSelect2
                                filterObject={expenseQuery}
                                filterVariable="category"
                                items={expenseCategoryList.expense_categories}
                                setFilterObject={setExpenseQuery}
                                setQueryObject={setExpenseCategoryQuery}
                                setQueryVariable="searchString"
                                title="Category"
                                width="200px"
                              />

                              {/* Staff */}
                              <TableColumnHeadSelect2
                                filterObject={expenseQuery}
                                filterVariable="staff"
                                items={staffList.staff}
                                setFilterObject={setExpenseQuery}
                                setQueryObject={setStaffQuery}
                                setQueryVariable="searchString"
                                title="Staff"
                                width="200px"
                              />

                              {/* Booking code */}
                              <TableColumnHeadSelect2
                                filterObject={expenseQuery}
                                filterVariable="bookingCode"
                                items={bookingCodeList.booking_codes}
                                setFilterObject={setExpenseQuery}
                                setQueryObject={setBookingCodeQuery}
                                setQueryVariable="searchString"
                                title="Booking code"
                                width="200px"
                              />

                              {/* Billable */}
                              <TableColumnHeadSelectSimple
                                filterObject={expenseQuery}
                                filterVariable="billable"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Billable",
                                    icon: "dollar",
                                    value: "true",
                                  },
                                  {
                                    display: "Not billable",
                                    icon: "cross",
                                    value: "false",
                                  },
                                ]}
                                setFilterObject={setExpenseQuery}
                                title="Billable"
                                width="125px"
                              />

                              {/* Amount */}
                              <TableColumnHeadSelectSimple
                                filterObject={expenseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setExpenseQuery}
                                title="Amount"
                                width="125px"
                              />
                            </tr>
                          }
                          loading={expenseList.loading}
                          noMargin
                          onClearFilters={() => {
                            setExpenseQuery({
                              ...expenseQueryDefault,
                            });
                          }}
                          page={expenseQuery.page}
                          setPageObject={setExpenseQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review expenses"
                    icon="bank-account"
                    title="Expenses"
                  />
                </div>
              }
            />

            {/* Financials */}
            <Tab
              id="engagement-financials-tab"
              title="Financials"
              panel={
                <div>
                  {/* Financials */}
                  <TabSection2
                    content={
                      bookingCodeList.count > 0 && (
                        <>
                          {/* Data cards */}
                          <DataCardContainer
                            children={
                              <>
                                {/* Budget adherence */}
                                <BookingCodeBudgetCard
                                  fill
                                  bookingCodeList={bookingCodeList}
                                />

                                {/* Profit & loss */}
                                <BookingCodeProfitLossCard
                                  fill
                                  bookingCodeList={bookingCodeList}
                                />

                                {/* Margin */}
                                {bookingCodeList.marginTotal >= 0 && (
                                  <BookingCodeMarginCard
                                    bookingCodeList={bookingCodeList}
                                    bookingCodeQuery={bookingCodeQuery}
                                  />
                                )}

                                {/* Billing */}
                                <BookingCodeWIPCard
                                  fill
                                  bookingCodeList={bookingCodeList}
                                />
                              </>
                            }
                            fill
                            tableTop
                          />

                          {/* Data table */}
                          <TableContainer2
                            body={bookingCodeList.booking_codes.map(
                              (booking_code) => {
                                return (
                                  <tr key={booking_code.id}>
                                    {/* Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <BookingCodeInfo
                                            bookingCodeData={booking_code}
                                          />
                                        }
                                        tagIntent={
                                          booking_code.open ? "success" : "none"
                                        }
                                        tagText={
                                          booking_code.open ? "Open" : "Closed"
                                        }
                                        text={booking_code.name}
                                      />
                                    </td>

                                    {/* Year */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag value={booking_code.year} />
                                    </td>

                                    {/* Billable */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          booking_code.billable
                                            ? "success"
                                            : "primary"
                                        }
                                        value={
                                          booking_code.billable
                                            ? "Billable"
                                            : "Not billable"
                                        }
                                      />
                                    </td>

                                    {/* Budget */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.budget}
                                      />
                                    </td>

                                    {/* Spent */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.total_expense}
                                      />
                                    </td>

                                    {/* Revenue */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.total_revenue}
                                      />
                                    </td>

                                    {/* Invoiced */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_code.total_invoiced}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !bookingCodeQuery.billable &&
                              !bookingCodeQuery.searchString &&
                              !bookingCodeQuery.year
                            }
                            count={bookingCodeList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="3"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Budget */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.budgetTotal}
                                  />
                                </td>

                                {/* Spent */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.totalExpenseTotal}
                                  />
                                </td>

                                {/* Revenue */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.revenueTotal}
                                  />
                                </td>

                                {/* Invoiced */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={bookingCodeList.invoicedTotal}
                                  />
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Booking code */}
                                <TableColumnHeadSearch
                                  queryObject={bookingCodeQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setBookingCodeQuery}
                                  setQueryVariable="searchString"
                                  title="Booking code"
                                  width="300px"
                                />

                                {/* Year */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="year"
                                  options={generateYearList}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Year"
                                  width="125px"
                                />

                                {/* Billable */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="billable"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Billable",
                                      icon: "dollar",
                                      value: "true",
                                    },
                                    {
                                      display: "Not billable",
                                      icon: "cross",
                                      value: "false",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Billable"
                                  width="125px"
                                />

                                {/* Budget */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "budget",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-budget",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Budget"
                                  width="125px"
                                />

                                {/* Spent */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "total_expense",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-total_expense",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Spent"
                                  width="125px"
                                />

                                {/* Revenue */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "total_revenue",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-total_revenue",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Revenue"
                                  width="125px"
                                />

                                {/* Invoiced */}
                                <TableColumnHeadSelectSimple
                                  filterObject={bookingCodeQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-numerical",
                                      value: "total_invoiced",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-numerical-desc",
                                      value: "-total_invoiced",
                                    },
                                  ]}
                                  setFilterObject={setBookingCodeQuery}
                                  title="Invoiced"
                                  width="125px"
                                />
                              </tr>
                            }
                            loading={bookingCodeList.loading}
                            noMargin
                            onClearFilters={() => {
                              setBookingCodeQuery({
                                ...bookingCodeQueryDefault,
                              });
                            }}
                            page={bookingCodeQuery.page}
                            setPageObject={setBookingCodeQuery}
                            setPageVariable="page"
                          />
                        </>
                      )
                    }
                    help="Monitor the engagement's financial position"
                    icon="dollar"
                    message={
                      bookingCodeList.count === 0
                        ? "Contact your administrator to set up a booking code."
                        : undefined
                    }
                    messageIntent={
                      bookingCodeList.count === 0 ? "primary" : undefined
                    }
                    title="Financials"
                  />

                  {/* Fees */}
                  <TabSection2
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={engagementFeeList.engagement_fees.map(
                            (engagement_fee) => {
                              return (
                                <tr
                                  key={engagement_fee.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementFeeDetailsAction(
                                          engagement_fee.id
                                        )
                                      );

                                      setUpdateFeeDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Service */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <EngagementServiceInfo
                                          engagementServiceId={
                                            engagement_fee.engagement_service
                                          }
                                          query={engagementServiceQueryDefault}
                                        />
                                      }
                                      text={
                                        engagement_fee.engagement_service_name
                                      }
                                    />
                                  </td>

                                  {/* Expenses */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_fee.expenses}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_fee.margin}
                                      tagNumber={
                                        engagement_fee.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Adjustment */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_fee.adjustment}
                                    />
                                  </td>

                                  {/* Fees */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber number={engagement_fee.fees} />

                                    {activeOrganisation.currency !==
                                      engagementDetails.engagement
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={engagement_fee.fees_quote}
                                          tagText={
                                            engagementDetails.engagement
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementFeeQuery.engagementService
                          }
                          count={engagementFeeList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="1"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={engagementFeeList.total_expenses}
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={engagementFeeList.total_margin}
                                  tagNumber={
                                    engagementFeeList.average_margin_percentage
                                  }
                                  tagPercentage
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={engagementFeeList.total_adjustments}
                                />
                              </td>

                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={engagementFeeList.total_fees}
                                />

                                {activeOrganisation.currency !==
                                  engagementDetails.engagement
                                    .currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        engagementFeeList.total_fees_quote
                                      }
                                      tagText={
                                        engagementDetails.engagement
                                          .currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Service */}
                              <TableColumnHeadSelect2
                                filterObject={engagementFeeQuery}
                                filterVariable="engagementService"
                                items={
                                  engagementServiceList.engagement_services
                                }
                                setFilterObject={setEngagementFeeQuery}
                                setQueryObject={setEngagementServiceQuery}
                                setQueryVariable="searchString"
                                title="Service"
                                width="200px"
                              />

                              {/* Expenses */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "expenses",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-expenses",
                                  },
                                ]}
                                setFilterObject={setEngagementFeeQuery}
                                title="Expenses"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={setEngagementFeeQuery}
                                title="Margin"
                                width="125px"
                              />

                              {/* Adjustment */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "adjustment",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-adjustment",
                                  },
                                ]}
                                setFilterObject={setEngagementFeeQuery}
                                title="Adjustment"
                                width="125px"
                              />

                              {/* Fees */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementFeeQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "fees",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-fees",
                                  },
                                ]}
                                setFilterObject={setEngagementFeeQuery}
                                title="Fees"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementFeeList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementFeeQuery({
                              ...engagementFeeQueryDefault,
                            });
                          }}
                          page={engagementFeeQuery.page}
                          setPageObject={setEngagementFeeQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review the pricing model"
                    icon="th"
                    message={
                      <>
                        This section uses expenses information entered for each
                        service in the{" "}
                        <LinkTag
                          onClick={() => {
                            setSelectedTab("engagement-resourcing-tab");
                          }}
                          label="resourcing"
                        />{" "}
                        tab.
                      </>
                    }
                    messageIntent="primary"
                    title="Fees"
                  />

                  {/* Milestones */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddMilestoneDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Progress */}
                              <EngagementMilestoneProgressCard
                                engagementMilestoneList={
                                  engagementMilestoneList
                                }
                                engagementMilestoneQuery={
                                  engagementMilestoneQuery
                                }
                              />

                              {/* Value */}
                              <EngagementMilestoneValueCard
                                fill
                                engagementMilestoneList={
                                  engagementMilestoneList
                                }
                                showInsights
                              />

                              {/* Invoiced */}
                              <EngagementMilestonePaidCard
                                engagementMilestoneList={
                                  engagementMilestoneList
                                }
                                engagementMilestoneQuery={
                                  engagementMilestoneQuery
                                }
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={engagementMilestoneList.engagement_milestones.map(
                            (engagement_milestone) => {
                              return (
                                <tr
                                  key={engagement_milestone.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementMilestoneDetailsAction(
                                          engagement_milestone.id
                                        )
                                      );

                                      setUpdateMilestoneDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Name */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <MilestoneInfo
                                          milestone={engagement_milestone}
                                        />
                                      }
                                      text={engagement_milestone.name}
                                    />
                                  </td>

                                  {/* Due */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={engagement_milestone.due}
                                    />
                                  </td>

                                  {/* Completed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={engagement_milestone.completed}
                                    />
                                  </td>

                                  {/* Invoiced */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={engagement_milestone.invoiced}
                                    />
                                  </td>

                                  {/* Paid */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={engagement_milestone.paid}
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        engagement_milestone.status ===
                                        "Completed"
                                          ? "primary"
                                          : engagement_milestone.status ===
                                            "Invoiced"
                                          ? "warning"
                                          : engagement_milestone.status ===
                                            "Not completed"
                                          ? "danger"
                                          : "success"
                                      }
                                      value={engagement_milestone.status}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_milestone.value}
                                    />

                                    {activeOrganisation.currency !==
                                      engagementDetails.engagement
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            engagement_milestone.value_quote
                                          }
                                          tagText={
                                            engagementDetails.engagement
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !engagementMilestoneQuery.searchString &&
                            !engagementMilestoneQuery.sortField &&
                            !engagementMilestoneQuery.status
                          }
                          count={engagementMilestoneList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                </div>
                              </td>

                              {/* Value */}
                              <td style={{ verticalAlign: "middle" }}>
                                <TableNumber
                                  number={
                                    engagementMilestoneList.totalValueOfMilestones
                                  }
                                />

                                {activeOrganisation.currency !==
                                  engagementDetails.engagement
                                    .currency_short && (
                                  <div style={{ marginTop: "5px" }}>
                                    <TableNumber
                                      minimal
                                      number={
                                        engagementMilestoneList.totalValueOfMilestonesQuote
                                      }
                                      tagText={
                                        engagementDetails.engagement
                                          .currency_short
                                      }
                                    />
                                  </div>
                                )}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Milestone */}
                              <TableColumnHeadSearch
                                queryObject={engagementMilestoneQuery}
                                queryVariable="searchString"
                                setQueryObject={setEngagementMilestoneQuery}
                                setQueryVariable="searchString"
                                title="Milestone"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "due",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-due",
                                  },
                                ]}
                                setFilterObject={setEngagementMilestoneQuery}
                                title="Due"
                                width="125px"
                              />

                              {/* Completed */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "completed",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-completed",
                                  },
                                ]}
                                setFilterObject={setEngagementMilestoneQuery}
                                title="Completed"
                                width="125px"
                              />

                              {/* Invoiced */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "invoiced",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-invoiced",
                                  },
                                ]}
                                setFilterObject={setEngagementMilestoneQuery}
                                title="Invoiced"
                                width="125px"
                              />

                              {/* Paid */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "paid",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-paid",
                                  },
                                ]}
                                setFilterObject={setEngagementMilestoneQuery}
                                title="Paid"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementMilestoneQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "Not completed",
                                    icon: "build",
                                    value: "Not completed",
                                  },
                                  {
                                    display: "Completed",
                                    icon: "cargo-ship",
                                    value: "Completed",
                                  },
                                  {
                                    display: "Invoiced",
                                    icon: "document-share",
                                    value: "Invoiced",
                                  },
                                  {
                                    display: "Paid",
                                    icon: "dollar",
                                    value: "Paid",
                                  },
                                ]}
                                setFilterObject={setEngagementMilestoneQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementMilestoneQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setEngagementMilestoneQuery}
                                title="Value"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={engagementMilestoneList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementMilestoneQuery({
                              ...engagementMilestoneQueryDefault,
                            });
                          }}
                          page={engagementMilestoneQuery.page}
                          setPageObject={setEngagementMilestoneQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage payment milestones"
                    icon="star-empty"
                    title="Milestones"
                  />

                  {/* Rate card */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddRateDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={engagementRateList.engagement_rates.map(
                            (engagement_rate) => {
                              return (
                                <tr
                                  key={engagement_rate.id}
                                  onClick={() => {
                                    if (
                                      engagementDetails.engagement
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listEngagementRateDetailsAction(
                                          engagement_rate.id
                                        )
                                      );

                                      setUpdateRateDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Role */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <RoleInfo
                                          roleId={engagement_rate.role}
                                          query={roleQueryDefault}
                                        />
                                      }
                                      text={engagement_rate.role_name}
                                    />
                                  </td>

                                  {/* Cost rate */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_rate.cost_rate}
                                    />
                                  </td>

                                  {/* Margin */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_rate.margin}
                                      tagNumber={
                                        engagement_rate.margin_percentage
                                      }
                                      tagPercentage
                                    />
                                  </td>

                                  {/* Sell rate */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_rate.sell_rate}
                                    />

                                    {activeOrganisation.currency !==
                                      engagementDetails.engagement
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            engagement_rate.sell_rate_quote
                                          }
                                          tagText={
                                            engagementDetails.engagement
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>

                                  {/* Sell rate / day */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={engagement_rate.sell_rate_day}
                                    />

                                    {activeOrganisation.currency !==
                                      engagementDetails.engagement
                                        .currency_short && (
                                      <div style={{ marginTop: "5px" }}>
                                        <TableNumber
                                          minimal
                                          number={
                                            engagement_rate.sell_rate_day_quote
                                          }
                                          tagText={
                                            engagementDetails.engagement
                                              .currency_short
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={!engagementRateQuery.role}
                          count={engagementRateList.count}
                          footnote={
                            <div>Figures in {activeOrganisation.currency}</div>
                          }
                          head={
                            <tr>
                              {/* Role */}
                              <TableColumnHeadSelect2
                                filterObject={engagementRateQuery}
                                filterVariable="role"
                                items={roleList.roles}
                                setFilterObject={setEngagementRateQuery}
                                setQueryObject={setRoleQuery}
                                setQueryVariable="searchString"
                                title="Role"
                                width="200px"
                              />

                              {/* Cost rate */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "cost_rate",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-cost_rate",
                                  },
                                ]}
                                setFilterObject={setEngagementRateQuery}
                                title="Cost rate"
                                width="125px"
                              />

                              {/* Margin */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "margin",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-margin",
                                  },
                                ]}
                                setFilterObject={setEngagementRateQuery}
                                title="Margin"
                                width="125px"
                              />

                              {/* Sell rate */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "sell_rate",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-sell_rate",
                                  },
                                ]}
                                setFilterObject={setEngagementRateQuery}
                                title="Sell rate"
                                width="125px"
                              />

                              {/* Sell rate / day */}
                              <TableColumnHeadSelectSimple
                                filterObject={engagementRateQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "sell_rate_day",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-sell_rate_day",
                                  },
                                ]}
                                setFilterObject={setEngagementRateQuery}
                                title="Sell rate / day"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive={
                            engagementDetails.engagement.user_access_status !==
                            "view"
                          }
                          loading={engagementRateList.loading}
                          noMargin
                          onClearFilters={() => {
                            setEngagementRateQuery({
                              ...engagementRateQueryDefault,
                            });
                          }}
                          page={engagementRateQuery.page}
                          setPageObject={setEngagementRateQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Develop the rate card"
                    icon="list-columns"
                    message={
                      <>
                        You can include a rate card in the engagement.
                        advaise.app will populate the hourly cost rate for each
                        role based on information from the{" "}
                        <LinkTag
                          label="Roles"
                          onClick={() => {
                            navigate(`/roles`);
                          }}
                        />{" "}
                        module.
                      </>
                    }
                    messageIntent="primary"
                    title="Rate card"
                  />

                  {/* Fee notes */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listEngagementDetailsAction(id));

                          setUpdateFeeNotesDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Currency */}
                        <TabSectionField
                          label="Currency"
                          loading={engagementDetails.loading}
                          value={
                            <div>
                              <Tag
                                minimal
                                style={{
                                  margin: "0 10px 0 0",
                                  textAlign: "center",
                                  width: "50px",
                                }}
                              >
                                {engagementDetails.engagement.currency_short}
                              </Tag>

                              {engagementDetails.engagement.currency}
                            </div>
                          }
                        />

                        {/* Exchange rate */}
                        {activeOrganisation.currency !==
                          engagementDetails.engagement.currency_short && (
                          <TabSectionField
                            helperText="The exchange rate will not be shown in the engagement charter"
                            label="Exchange rate"
                            loading={engagementDetails.loading}
                            value={`${engagementDetails.engagement.currency_exchange_rate} ${engagementDetails.engagement.currency_short}/${activeOrganisation.currency}`}
                          />
                        )}

                        {/* Includes tax */}
                        <TabSectionField
                          label="Includes tax"
                          loading={engagementDetails.loading}
                          value={engagementDetails.engagement.tax}
                        />

                        {/* Additional information */}
                        <TabSectionField
                          bottom
                          label="Additional information"
                          loading={engagementDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={engagementDetails.engagement.fee_notes}
                            />
                          }
                        />
                      </>
                    }
                    help="Summarize your pricing assumptions"
                    icon="annotation"
                    title="Fee notes"
                  />
                </div>
              }
            />

            {/* Charter */}
            <Tab
              id="engagement-charter-tab"
              title="Charter"
              panel={
                <div>
                  <Callout intent="primary">
                    Some of the charter information has been prefilled using
                    data from other sections.
                  </Callout>

                  {/* Style */}
                  {engagementDetails.engagement.user_access_status !==
                    "view" && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="edit"
                          intent="primary"
                          onClick={() => {
                            dispatch(
                              listEngagementDocumentDetailsAction(
                                engagementDetails.engagement.engagement_document
                              )
                            );

                            setUpdateEngagementDocumentStyleDrawerIsOpen(true);
                          }}
                          text="Edit"
                        />
                      }
                      content={
                        <>
                          {/* Template */}
                          <TabSectionField
                            label="Template"
                            loading={engagementDocumentDetails.loading}
                            value={engagementDocument.template}
                          />

                          {/* Logo */}
                          <TabSectionField
                            label="Logo"
                            loading={engagementDocumentDetails.loading}
                            value={
                              <TabSectionFieldImage
                                image={
                                  engagementDocumentDetails.engagement_document
                                    .logo
                                }
                                height="200px"
                                interactive
                              />
                            }
                          />

                          {/* Font */}
                          <TabSectionField
                            label="Font"
                            loading={engagementDocumentDetails.loading}
                            value={engagementDocument.font}
                          />

                          {/* Primary colour */}
                          <TabSectionField
                            label="Primary colour"
                            loading={engagementDocumentDetails.loading}
                            value={
                              <Tag
                                large
                                round
                                style={{
                                  background: engagementDocument.primaryColour,
                                  color: setTextColour(
                                    engagementDocument.primaryColour
                                  ),
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                {engagementDocument.primaryColour}
                              </Tag>
                            }
                          />

                          {/* Secondary colour */}
                          <TabSectionField
                            bottom
                            label="Secondary colour"
                            loading={engagementDocumentDetails.loading}
                            value={
                              <Tag
                                large
                                round
                                style={{
                                  background:
                                    engagementDocument.secondaryColour,
                                  color: setTextColour(
                                    engagementDocument.secondaryColour
                                  ),
                                  textAlign: "center",
                                  width: "100px",
                                }}
                              >
                                {engagementDocument.secondaryColour}
                              </Tag>
                            }
                          />
                        </>
                      }
                      help="Set the style for the engagement charter"
                      icon="style"
                      title="Style"
                    />
                  )}

                  {/* Engagement charter */}
                  {engagementDetails.engagement.user_access_status !==
                    "view" && (
                    <TabSection2
                      buttons={
                        <>
                          <Button
                            disabled={
                              documentGeneratePdf.loading ||
                              engagementDocumentDetails.loading ||
                              engagementDocumentUpdate.loading
                            }
                            icon="refresh"
                            intent="primary"
                            onClick={() => {
                              updateDocument();
                            }}
                            text="Refresh"
                          />

                          <Popover2
                            content={
                              <Menu>
                                <MenuItem
                                  icon="manual"
                                  onClick={() => {
                                    saveAs(
                                      documentGeneratePdf.engagement_charter_pdf,
                                      `Engagement Charter for ${engagementDetails.engagement.name}.pdf`
                                    );
                                  }}
                                  text="PDF"
                                />

                                <MenuItem
                                  icon="document"
                                  onClick={() => {
                                    setEditableEngagementDocumentLoading(true);

                                    dispatch(downloadEngagementAction(id));
                                  }}
                                  text="DOCX"
                                />
                              </Menu>
                            }
                            interactionKind={Popover2InteractionKind.HOVER}
                          >
                            <Button
                              icon="download"
                              intent="primary"
                              loading={
                                documentGeneratePdf.loading ||
                                editableEngagementDocumentLoading
                              }
                              style={{ margin: "0 0 0 10px" }}
                              text="Download"
                            />
                          </Popover2>
                        </>
                      }
                      content={
                        <TabSectionDocumentEditor3
                          document={documentGeneratePdf.engagement_charter_pdf}
                          error={documentGeneratePdf.error}
                          form={
                            <>
                              {/* Front page */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Background image */}
                                    <FormDrawerFileField2
                                      error={engagementDocumentUpdate.error}
                                      fileName="background_image"
                                      helperText="Leave blank to use the default template background"
                                      label="Background image"
                                      placeholder="Upload a background image"
                                      setValueObject={setEngagementDocument}
                                      setValueFileVariable="backgroundImage"
                                      setValueNameVariable="backgroundImageName"
                                      valueFile={
                                        engagementDocument.backgroundImage
                                      }
                                      valueName={
                                        engagementDocument.backgroundImageName
                                      }
                                    />

                                    {/* Background image preview */}
                                    <div style={{ margin: "0 0 20px 0" }}>
                                      {engagementDocument.backgroundImage ? (
                                        engagementDocument.backgroundImage !==
                                        engagementDocumentDetails
                                          .engagement_document
                                          .background_image ? (
                                          <TabSectionFieldImage
                                            image={URL.createObjectURL(
                                              new Blob([
                                                engagementDocument.backgroundImage,
                                              ])
                                            )}
                                          />
                                        ) : (
                                          <TabSectionFieldImage
                                            image={
                                              engagementDocumentDetails
                                                .engagement_document
                                                .background_image
                                            }
                                          />
                                        )
                                      ) : undefined}
                                    </div>

                                    {/* Title */}
                                    <FormDrawerTextField2
                                      error={engagementDocumentUpdate.error}
                                      fieldName="title"
                                      helperText="Leave blank to use the engagement's name"
                                      icon="manual"
                                      label="Title"
                                      labelInfo="(required)"
                                      placeholder="Give the engagement charter a title"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="title"
                                      value={engagementDocument.title}
                                    />
                                  </>
                                }
                                pageRef={sectionPages["Front page"]}
                                //setShowPage={setShowPage}
                                title="Front page"
                              />

                              {/* Needs analysis */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={engagementDocumentUpdate.error}
                                      fieldName="include_needs"
                                      label="Include section"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="includeNeeds"
                                      value={engagementDocument.includeNeeds}
                                    />

                                    {engagementDocument.includeNeeds && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="needs_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          placeholder="Enter the section title"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="needsHeading"
                                          value={
                                            engagementDocument.needsHeading
                                          }
                                        />

                                        {/* Change drivers heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="change_drivers_heading"
                                          icon="header-two"
                                          label="Change drivers heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="changeDriversHeading"
                                          value={
                                            engagementDocument.changeDriversHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              engagementDocument.changeDriversHeading ===
                                              "..."
                                            }
                                            disabled={
                                              engagementDocument.changeDriversHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              engagementDocument.changeDriversHeading !==
                                                "..." &&
                                              setEngagementDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    changeDriversHeading: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Change drivers */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="change_drivers"
                                          label="Change drivers"
                                          placeholder="Summarise the events that are driving the client to change"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="changeDrivers"
                                          value={
                                            engagementDocument.changeDrivers
                                          }
                                        />

                                        {/* Strategic options heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="strategic_options_heading"
                                          icon="header-two"
                                          label="Strategic options heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="strategicOptionsHeading"
                                          value={
                                            engagementDocument.strategicOptionsHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              engagementDocument.strategicOptionsHeading ===
                                              "..."
                                            }
                                            disabled={
                                              engagementDocument.strategicOptionsHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              engagementDocument.strategicOptionsHeading !==
                                                "..." &&
                                              setEngagementDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    strategicOptionsHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Strategic options */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="strategic_options"
                                          label="Strategic options"
                                          placeholder="Identify the client's strategic options"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="strategicOptions"
                                          value={
                                            engagementDocument.strategicOptions
                                          }
                                        />

                                        {/* Theory of change heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="theory_of_change_heading"
                                          icon="header-two"
                                          label="Theory of change heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="theoryOfChangeHeading"
                                          value={
                                            engagementDocument.theoryOfChangeHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              engagementDocument.theoryOfChangeHeading ===
                                              "..."
                                            }
                                            disabled={
                                              engagementDocument.theoryOfChangeHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              engagementDocument.theoryOfChangeHeading !==
                                                "..." &&
                                              setEngagementDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    theoryOfChangeHeading:
                                                      "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Theory of change */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="theory_of_change"
                                          label="Theory of change"
                                          placeholder="Describe the client's preferred strategy (or our leading hypothesis)"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="theoryOfChange"
                                          value={
                                            engagementDocument.theoryOfChange
                                          }
                                        />

                                        {/* Issues heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="issues_heading"
                                          icon="header-two"
                                          label="Issues heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="issuesHeading"
                                          value={
                                            engagementDocument.issuesHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              engagementDocument.issuesHeading ===
                                              "..."
                                            }
                                            disabled={
                                              engagementDocument.issuesHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              engagementDocument.issuesHeading !==
                                                "..." &&
                                              setEngagementDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    issuesHeading: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Issues */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="issues"
                                          label="Issues"
                                          placeholder="Analyse the challenges that face the client in moving forward"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="issues"
                                          value={engagementDocument.issues}
                                        />

                                        {/* Our solution heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="our_solution_heading"
                                          icon="header-two"
                                          label="Our solution heading"
                                          placeholder="Enter the subsection title"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="ourSolutionHeading"
                                          value={
                                            engagementDocument.ourSolutionHeading
                                          }
                                        />

                                        <div
                                          style={{
                                            margin: "-10px 0 15px 0",
                                          }}
                                        >
                                          <Switch
                                            checked={
                                              engagementDocument.ourSolutionHeading ===
                                              "..."
                                            }
                                            disabled={
                                              engagementDocument.ourSolutionHeading
                                            }
                                            innerLabel="autofill"
                                            onChange={() =>
                                              engagementDocument.ourSolutionHeading !==
                                                "..." &&
                                              setEngagementDocument(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    ourSolutionHeading: "...",
                                                  };
                                                }
                                              )
                                            }
                                          />
                                        </div>

                                        {/* Our solution */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="our_solution"
                                          label="Our solution"
                                          placeholder="Describe the solutions we will bring to support the change"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="ourSolution"
                                          value={engagementDocument.ourSolution}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    needs analysis in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab(
                                          "engagement-strategy-tab"
                                        );
                                      }}
                                      label="strategy"
                                    />{" "}
                                    tab. You can provide alternative,
                                    client-ready, content using the fields
                                    below.
                                  </>
                                }
                                pageRef={sectionPages["Needs"]}
                                //setShowPage={setShowPage}
                                title="Needs analysis"
                              />

                              {/* Engagement approach */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={engagementDocumentUpdate.error}
                                      fieldName="include_approach"
                                      label="Include section"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="includeApproach"
                                      value={engagementDocument.includeApproach}
                                    />

                                    {engagementDocument.includeApproach && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="approach_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="approachHeading"
                                          value={
                                            engagementDocument.approachHeading
                                          }
                                        />

                                        {/* Approach summary */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="approach_summary"
                                          label="Approach summary"
                                          placeholder="Summarize the engagement method"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="approachSummary"
                                          value={
                                            engagementDocument.approachSummary
                                          }
                                        />

                                        {/* Approach diagram */}
                                        <FormDrawerFileField2
                                          error={engagementDocumentUpdate.error}
                                          fileName="approach_diagram"
                                          label="Approach diagram"
                                          placeholder="Upload the approach diagram"
                                          setValueObject={setEngagementDocument}
                                          setValueFileVariable="approachDiagram"
                                          setValueNameVariable="approachDiagramName"
                                          valueFile={
                                            engagementDocument.approachDiagram
                                          }
                                          valueName={
                                            engagementDocument.approachDiagramName
                                          }
                                        />

                                        {/* Approach diagram preview */}
                                        {engagementDocument.approachDiagram ? (
                                          engagementDocument.approachDiagram !==
                                          engagementDocumentDetails
                                            .engagement_document
                                            .approach_diagram ? (
                                            <TabSectionFieldImage
                                              image={URL.createObjectURL(
                                                new Blob([
                                                  engagementDocument.approachDiagram,
                                                ])
                                              )}
                                            />
                                          ) : (
                                            <TabSectionFieldImage
                                              image={
                                                engagementDocumentDetails
                                                  .engagement_document
                                                  .approach_diagram
                                              }
                                            />
                                          )
                                        ) : undefined}
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    approach in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab(
                                          "engagement-strategy-tab"
                                        );
                                      }}
                                      label="strategy"
                                    />{" "}
                                    tab. You can provide alternative,
                                    client-ready, content using the fields
                                    below.
                                  </>
                                }
                                pageRef={sectionPages["Approach"]}
                                //setShowPage={setShowPage}
                                title="Engagement approach"
                              />

                              {/* Services */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={engagementDocumentUpdate.error}
                                      fieldName="include_services"
                                      label="Include section"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="includeServices"
                                      value={engagementDocument.includeServices}
                                    />

                                    {engagementDocument.includeServices && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="services_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="servicesHeading"
                                          value={
                                            engagementDocument.servicesHeading
                                          }
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="intro_services"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="introServices"
                                          value={
                                            engagementDocument.introServices
                                          }
                                        />

                                        {/* Schedule diagram */}
                                        <FormDrawerFileField2
                                          error={engagementDocumentUpdate.error}
                                          fileName="schedule_diagram"
                                          label="Schedule diagram"
                                          placeholder="Upload the schedule diagram"
                                          setValueObject={setEngagementDocument}
                                          setValueFileVariable="scheduleDiagram"
                                          setValueNameVariable="scheduleDiagramName"
                                          valueFile={
                                            engagementDocument.scheduleDiagram
                                          }
                                          valueName={
                                            engagementDocument.scheduleDiagramName
                                          }
                                        />

                                        {/* Schedule diagram preview */}
                                        {engagementDocument.scheduleDiagram ? (
                                          engagementDocument.scheduleDiagram !==
                                          engagementDocumentDetails
                                            .engagement_document
                                            .schedule_diagram ? (
                                            <TabSectionFieldImage
                                              image={URL.createObjectURL(
                                                new Blob([
                                                  engagementDocument.scheduleDiagram,
                                                ])
                                              )}
                                            />
                                          ) : (
                                            <TabSectionFieldImage
                                              image={
                                                engagementDocumentDetails
                                                  .engagement_document
                                                  .schedule_diagram
                                              }
                                            />
                                          )
                                        ) : undefined}
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    services described in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("engagement-plan-tab");
                                      }}
                                      label="plan"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Services"]}
                                //setShowPage={setShowPage}
                                title="Services"
                              />

                              {/* Fees and resources */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={engagementDocumentUpdate.error}
                                      fieldName="include_fees"
                                      label="Include section"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="includeFees"
                                      value={engagementDocument.includeFees}
                                    />

                                    {engagementDocument.includeFees && (
                                      <>
                                        {/* Include fee breakdown */}
                                        <FormDrawerSwitchField
                                          error={engagementDocumentUpdate.error}
                                          fieldName="include_fee_breakdown"
                                          label="Include fee breakdown"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="includeFeeBreakdown"
                                          value={
                                            engagementDocument.includeFeeBreakdown
                                          }
                                        />

                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="fees_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="feesHeading"
                                          value={engagementDocument.feesHeading}
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="intro_fees"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="introFees"
                                          value={engagementDocument.introFees}
                                        />

                                        {/* Currency */}
                                        <FormDrawerSelectSimpleField3
                                          error={engagementDocumentUpdate.error}
                                          fieldName="currency"
                                          label="Currency"
                                          loading={
                                            currencyGetOrganisationCurrencyChoices.loading
                                          }
                                          options={currencies}
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="currency"
                                          value={engagementDocument.currency}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    fees specified in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab(
                                          "engagement-financials-tab"
                                        );
                                      }}
                                      label="financials"
                                    />{" "}
                                    tab. Go to that section to make changes.
                                  </>
                                }
                                pageRef={sectionPages["Fees"]}
                                //setShowPage={setShowPage}
                                title="Fees and resources"
                              />

                              {/* Risk management */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={engagementDocumentUpdate.error}
                                      fieldName="include_risks"
                                      label="Include section"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="includeRisks"
                                      value={engagementDocument.includeRisks}
                                    />

                                    {engagementDocument.includeRisks && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="risks_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="risksHeading"
                                          value={
                                            engagementDocument.risksHeading
                                          }
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="intro_risks"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="introRisks"
                                          value={engagementDocument.introRisks}
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    risks in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("engagement-raid-tab");
                                      }}
                                      label="RAID"
                                    />{" "}
                                    tab that are in open status.
                                  </>
                                }
                                pageRef={sectionPages["Risks"]}
                                //setShowPage={setShowPage}
                                title="Risk management"
                              />

                              {/* Dependencies */}
                              <TabSectionDocumentEditorFormContainer2
                                fields={
                                  <>
                                    {/* Include section */}
                                    <FormDrawerSwitchField
                                      error={engagementDocumentUpdate.error}
                                      fieldName="include_dependencies"
                                      label="Include section"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="includeDependencies"
                                      value={
                                        engagementDocument.includeDependencies
                                      }
                                    />

                                    {engagementDocument.includeDependencies && (
                                      <>
                                        {/* Section heading */}
                                        <FormDrawerTextField2
                                          error={engagementDocumentUpdate.error}
                                          fieldName="dependencies_heading"
                                          helperText="Leave blank to use the default heading"
                                          icon="header-one"
                                          label="Section heading"
                                          labelInfo="(required)"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="dependenciesHeading"
                                          value={
                                            engagementDocument.dependenciesHeading
                                          }
                                        />

                                        {/* Introduction */}
                                        <FormDrawerTextAreaField3
                                          enableAutofill
                                          error={engagementDocumentUpdate.error}
                                          fieldName="intro_dependencies"
                                          label="Introduction"
                                          placeholder="Introduce this section"
                                          setValueObject={setEngagementDocument}
                                          setValueVariable="introDependencies"
                                          value={
                                            engagementDocument.introDependencies
                                          }
                                        />
                                      </>
                                    )}
                                  </>
                                }
                                message={
                                  <>
                                    The content in this section comes from the
                                    dependencies in the{" "}
                                    <LinkTag
                                      onClick={() => {
                                        setSelectedTab("engagement-raid-tab");
                                      }}
                                      label="RAID"
                                    />{" "}
                                    tab that are in open status.
                                  </>
                                }
                                pageRef={sectionPages["Dependencies"]}
                                //setShowPage={setShowPage}
                                title="Dependencies"
                              />

                              {/* Back page */}
                              <TabSectionDocumentEditorFormContainer2
                                bottom
                                fields={
                                  <>
                                    {/* Back page text */}
                                    <FormDrawerTextAreaField3
                                      error={engagementDocumentUpdate.error}
                                      fieldName="back_page_text"
                                      label="Back page text"
                                      placeholder="Enter the back page text"
                                      setValueObject={setEngagementDocument}
                                      setValueVariable="backPageText"
                                      value={engagementDocument.backPageText}
                                    />
                                  </>
                                }
                                pageRef={sectionPages["Back page"]}
                                //setShowPage={setShowPage}
                                title="Back page"
                              />
                            </>
                          }
                          loading={
                            documentGeneratePdf.loading ||
                            engagementDocumentDetails.loading ||
                            engagementDocumentUpdate.loading
                          }
                          setSectionPages={setSectionPages}
                          //showPage={showPage}
                        />
                      }
                      help="Edit the engagement charter"
                      icon="book"
                      title="Engagement charter"
                    />
                  )}
                </div>
              }
            />

            {/* Close */}
            <Tab
              id="engagement-close-tab"
              title="Close"
              panel={
                <div>
                  {/* Lessons learned */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          engagementDetails.engagement.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddEngagementLessonDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search lessons"
                            searching={engagementLessonList.loading}
                            setValueObject={setEngagementLessonQuery}
                            setValueVariable="searchString"
                            value={engagementLessonQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={engagementLessonList.engagement_lessons.map(
                            (lesson, i, { length }) => {
                              return (
                                <div key={lesson.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={lesson.name}
                                    value={
                                      <TabSectionFieldCard
                                        interactive
                                        onClick={() => {
                                          if (
                                            engagementDetails.engagement
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listEngagementLessonDetailsAction(
                                                lesson.id
                                              )
                                            );

                                            setUpdateEngagementLessonDrawerIsOpen(
                                              true
                                            );
                                          }
                                        }}
                                        text={lesson.lesson}
                                        title={
                                          <Tag
                                            icon={
                                              lesson.category === "Commercials"
                                                ? "dollar"
                                                : lesson.category === "Delivery"
                                                ? "gantt-chart"
                                                : lesson.category === "Planning"
                                                ? "playbook"
                                                : lesson.category ===
                                                  "Stakeholders"
                                                ? "new-person"
                                                : lesson.category ===
                                                  "Resourcing"
                                                ? "people"
                                                : "more"
                                            }
                                            large
                                            round
                                            style={{
                                              background:
                                                lesson.category ===
                                                "Commercials"
                                                  ? Colors.GOLD3
                                                  : lesson.category ===
                                                    "Delivery"
                                                  ? Colors.LIME3
                                                  : lesson.category ===
                                                    "Planning"
                                                  ? Colors.INDIGO3
                                                  : lesson.category ===
                                                    "Stakeholders"
                                                  ? Colors.ROSE3
                                                  : lesson.category ===
                                                    "Resourcing"
                                                  ? Colors.CERULEAN3
                                                  : Colors.BLUE3,
                                              color: setTextColour(
                                                lesson.category ===
                                                  "Commercials"
                                                  ? Colors.GOLD3
                                                  : lesson.category ===
                                                    "Delivery"
                                                  ? Colors.LIME3
                                                  : lesson.category ===
                                                    "Planning"
                                                  ? Colors.INDIGO3
                                                  : lesson.category ===
                                                    "Stakeholders"
                                                  ? Colors.ROSE3
                                                  : lesson.category ===
                                                    "Resourcing"
                                                  ? Colors.CERULEAN3
                                                  : Colors.BLUE3
                                              ),
                                            }}
                                          >
                                            {lesson.category}
                                          </Tag>
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={engagementLessonList.count}
                          loading={engagementLessonList.loading}
                          noResultsMessage="Add lessons."
                          noResultsTitle="No lessons found"
                          page={engagementLessonQuery.page}
                          setPageObject={setEngagementLessonQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Capture lessons learned"
                    icon="learning"
                    title="Lessons learned"
                  />
                </div>
              }
            />

            {/* Files */}
            <Tab
              id="engagement-files-tab"
              title="Files"
              panel={
                <div>
                  <div>
                    {/* User uploaded files */}
                    <TabSection2
                      buttons={
                        <Button
                          disabled={
                            engagementDetails.engagement.user_access_status ===
                            "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddUploadDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <ItemCardList
                          content={engagementUploadList.engagement_uploads.map(
                            (engagement_upload) => {
                              return (
                                <DocumentCard
                                  interactive
                                  key={engagement_upload.id}
                                  onClick={() => {
                                    dispatch(
                                      listEngagementUploadDetailsAction(
                                        engagement_upload.id
                                      )
                                    );

                                    setUpdateUploadDrawerIsOpen(true);
                                  }}
                                  title={engagement_upload.name}
                                  type={engagement_upload.type}
                                />
                              );
                            }
                          )}
                          count={engagementUploadList.count}
                          loading={engagementUploadList.loading}
                          noMargin
                          noResultsMessage="Add files"
                          noResultsTitle="No files found"
                          page={engagementUploadQuery.page}
                          setPageObject={setEngagementUploadQuery}
                          setPageVariable="page"
                        />
                      }
                      help="Manage user-uploaded files"
                      icon="folder-open"
                      title="User uploaded files"
                    />
                  </div>
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default EngagementDetailScreen;
