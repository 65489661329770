// Constant imports
import {
  PROPOSAL_QUESTION_LIST_REQUEST,
  PROPOSAL_QUESTION_LIST_SUCCESS,
  PROPOSAL_QUESTION_LIST_FAIL,
  PROPOSAL_QUESTION_LIST_RESET,
  PROPOSAL_QUESTION_CREATE_REQUEST,
  PROPOSAL_QUESTION_CREATE_SUCCESS,
  PROPOSAL_QUESTION_CREATE_FAIL,
  PROPOSAL_QUESTION_CREATE_RESET,
  PROPOSAL_QUESTION_DETAILS_REQUEST,
  PROPOSAL_QUESTION_DETAILS_SUCCESS,
  PROPOSAL_QUESTION_DETAILS_FAIL,
  PROPOSAL_QUESTION_DETAILS_RESET,
  PROPOSAL_QUESTION_UPDATE_REQUEST,
  PROPOSAL_QUESTION_UPDATE_SUCCESS,
  PROPOSAL_QUESTION_UPDATE_FAIL,
  PROPOSAL_QUESTION_UPDATE_RESET,
  PROPOSAL_QUESTION_DELETE_REQUEST,
  PROPOSAL_QUESTION_DELETE_SUCCESS,
  PROPOSAL_QUESTION_DELETE_FAIL,
  PROPOSAL_QUESTION_DELETE_RESET,
} from "../constants/proposalQuestionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalQuestionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_QUESTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalQuestions(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_QUESTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfQuestionsNotApplicable:
            data.number_of_questions_not_applicable,
          numberOfQuestionsResearching: data.number_of_questions_researching,
          numberOfQuestionsResolved: data.number_of_questions_resolved,
          proposal_questions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_QUESTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalQuestionsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_QUESTION_LIST_RESET });
};

// Create
export const createProposalQuestionAction =
  (proposal_question) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_QUESTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalQuestion(
        token,
        proposal_question
      );

      dispatch({
        type: PROPOSAL_QUESTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_QUESTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalQuestionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_QUESTION_CREATE_RESET });
};

// List details
export const listProposalQuestionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_QUESTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalQuestion(id, token);

      dispatch({
        type: PROPOSAL_QUESTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_QUESTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalQuestionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_QUESTION_DETAILS_RESET });
  };

// Update
export const updateProposalQuestionAction =
  (id, proposal_question) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_QUESTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalQuestion(
        id,
        token,
        proposal_question
      );

      dispatch({
        type: PROPOSAL_QUESTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_QUESTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalQuestionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_QUESTION_UPDATE_RESET });
};

// Delete
export const deleteProposalQuestionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_QUESTION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalQuestion(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_QUESTION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_QUESTION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalQuestionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_QUESTION_DELETE_RESET });
};
