// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function EngagementMilestonePaidCard({
  fill,
  engagementMilestoneList,
  engagementMilestoneQuery,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Invoiced</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Percentage of total milestone value paid.</p>
        </>
      }
      data={[
        {
          name: "Paid",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: engagementMilestoneList.valueOfMilestonesPaid,
        },
        {
          name: "Not paid",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value:
            engagementMilestoneList.valueOfMilestonesCompleted +
            engagementMilestoneList.valueOfMilestonesNotCompleted +
            engagementMilestoneList.valueOfMilestonesInvoiced,
        },
      ]}
      fill={fill}
      filter={
        engagementMilestoneQuery ? engagementMilestoneQuery.status : undefined
      }
      noData={
        !engagementMilestoneList.valueOfMilestonesCompleted &&
        !engagementMilestoneList.valueOfMilestonesInvoiced &&
        !engagementMilestoneList.valueOfMilestonesNotCompleted &&
        !engagementMilestoneList.valueOfMilestonesPaid
      }
      showPercent
      title="Paid"
      units="Percentage"
    />
  );
}

export default EngagementMilestonePaidCard;
