export const LEAVE_REQUEST_LIST_REQUEST = "LEAVE_REQUEST_LIST_REQUEST";
export const LEAVE_REQUEST_LIST_SUCCESS = "LEAVE_REQUEST_LIST_SUCCESS";
export const LEAVE_REQUEST_LIST_FAIL = "LEAVE_REQUEST_LIST_FAIL";
export const LEAVE_REQUEST_LIST_RESET = "LEAVE_REQUEST_LIST_RESET";

export const LEAVE_REQUEST_CREATE_REQUEST = "LEAVE_REQUEST_CREATE_REQUEST";
export const LEAVE_REQUEST_CREATE_SUCCESS = "LEAVE_REQUEST_CREATE_SUCCESS";
export const LEAVE_REQUEST_CREATE_FAIL = "LEAVE_REQUEST_CREATE_FAIL";
export const LEAVE_REQUEST_CREATE_RESET = "LEAVE_REQUEST_CREATE_RESET";

export const LEAVE_REQUEST_DETAILS_REQUEST = "LEAVE_REQUEST_DETAILS_REQUEST";
export const LEAVE_REQUEST_DETAILS_SUCCESS = "LEAVE_REQUEST_DETAILS_SUCCESS";
export const LEAVE_REQUEST_DETAILS_FAIL = "LEAVE_REQUEST_DETAILS_FAIL";
export const LEAVE_REQUEST_DETAILS_RESET = "LEAVE_REQUEST_DETAILS_RESET";

export const LEAVE_REQUEST_UPDATE_REQUEST = "LEAVE_REQUEST_UPDATE_REQUEST";
export const LEAVE_REQUEST_UPDATE_SUCCESS = "LEAVE_REQUEST_UPDATE_SUCCESS";
export const LEAVE_REQUEST_UPDATE_FAIL = "LEAVE_REQUEST_UPDATE_FAIL";
export const LEAVE_REQUEST_UPDATE_RESET = "LEAVE_REQUEST_UPDATE_RESET";

export const LEAVE_REQUEST_DELETE_REQUEST = "LEAVE_REQUEST_DELETE_REQUEST";
export const LEAVE_REQUEST_DELETE_SUCCESS = "LEAVE_REQUEST_DELETE_SUCCESS";
export const LEAVE_REQUEST_DELETE_FAIL = "LEAVE_REQUEST_DELETE_FAIL";
export const LEAVE_REQUEST_DELETE_RESET = "LEAVE_REQUEST_DELETE_RESET";

export const LEAVE_REQUEST_CANCEL_REQUEST = "LEAVE_REQUEST_CANCEL_REQUEST";
export const LEAVE_REQUEST_CANCEL_SUCCESS = "LEAVE_REQUEST_CANCEL_SUCCESS";
export const LEAVE_REQUEST_CANCEL_FAIL = "LEAVE_REQUEST_CANCEL_FAIL";
export const LEAVE_REQUEST_CANCEL_RESET = "LEAVE_REQUEST_CANCEL_RESET";
