// Constant imports
import {
  CLIENT_PERMISSION_LIST_REQUEST,
  CLIENT_PERMISSION_LIST_SUCCESS,
  CLIENT_PERMISSION_LIST_FAIL,
  CLIENT_PERMISSION_LIST_RESET,
  CLIENT_PERMISSION_CREATE_REQUEST,
  CLIENT_PERMISSION_CREATE_SUCCESS,
  CLIENT_PERMISSION_CREATE_FAIL,
  CLIENT_PERMISSION_CREATE_RESET,
  CLIENT_PERMISSION_DETAILS_REQUEST,
  CLIENT_PERMISSION_DETAILS_SUCCESS,
  CLIENT_PERMISSION_DETAILS_FAIL,
  CLIENT_PERMISSION_DETAILS_RESET,
  CLIENT_PERMISSION_UPDATE_REQUEST,
  CLIENT_PERMISSION_UPDATE_SUCCESS,
  CLIENT_PERMISSION_UPDATE_FAIL,
  CLIENT_PERMISSION_UPDATE_RESET,
  CLIENT_PERMISSION_DELETE_REQUEST,
  CLIENT_PERMISSION_DELETE_SUCCESS,
  CLIENT_PERMISSION_DELETE_FAIL,
  CLIENT_PERMISSION_DELETE_RESET,
} from "../constants/clientPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientPermissions(
        params,
        token
      );

      dispatch({
        type: CLIENT_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          client_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListClientPermissionsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PERMISSION_LIST_RESET });
};

// Create
export const createClientPermissionAction =
  (client_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createClientPermission(
        token,
        client_permission
      );

      dispatch({
        type: CLIENT_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateClientPermissionAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PERMISSION_CREATE_RESET });
};

// List details
export const listClientPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientPermission(id, token);

      dispatch({
        type: CLIENT_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListClientPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: CLIENT_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateClientPermissionAction =
  (id, client_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateClientPermission(
        id,
        token,
        client_permission
      );

      dispatch({
        type: CLIENT_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientPermissionAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteClientPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteClientPermission(
        id,
        token
      );

      dispatch({
        type: CLIENT_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteClientPermissionAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_PERMISSION_DELETE_RESET });
};
