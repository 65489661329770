// Constant imports
import {
  MARKET_NEED_LIST_REQUEST,
  MARKET_NEED_LIST_SUCCESS,
  MARKET_NEED_LIST_FAIL,
  MARKET_NEED_LIST_RESET,
  MARKET_NEED_CREATE_REQUEST,
  MARKET_NEED_CREATE_SUCCESS,
  MARKET_NEED_CREATE_FAIL,
  MARKET_NEED_CREATE_RESET,
  MARKET_NEED_DETAILS_REQUEST,
  MARKET_NEED_DETAILS_SUCCESS,
  MARKET_NEED_DETAILS_FAIL,
  MARKET_NEED_DETAILS_RESET,
  MARKET_NEED_UPDATE_REQUEST,
  MARKET_NEED_UPDATE_SUCCESS,
  MARKET_NEED_UPDATE_FAIL,
  MARKET_NEED_UPDATE_RESET,
  MARKET_NEED_DELETE_REQUEST,
  MARKET_NEED_DELETE_SUCCESS,
  MARKET_NEED_DELETE_FAIL,
  MARKET_NEED_DELETE_RESET,
} from "../constants/marketNeedConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listMarketNeedsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_NEED_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getMarketNeeds(params, token);

    dispatch({
      type: MARKET_NEED_LIST_SUCCESS,

      payload: {
        count: data.count,
        market_needs: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: MARKET_NEED_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListMarketNeedsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_NEED_LIST_RESET });
};

// Create
export const createMarketNeedAction =
  (market_need) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_NEED_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createMarketNeed(
        token,
        market_need
      );

      dispatch({
        type: MARKET_NEED_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_NEED_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateMarketNeedAction = () => async (dispatch) => {
  dispatch({ type: MARKET_NEED_CREATE_RESET });
};

// List details
export const listMarketNeedDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_NEED_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketNeed(id, token);

      dispatch({
        type: MARKET_NEED_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_NEED_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListMarketNeedDetailsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_NEED_DETAILS_RESET });
};

// Update
export const updateMarketNeedAction =
  (id, market_need) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_NEED_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateMarketNeed(
        id,
        token,
        market_need
      );

      dispatch({
        type: MARKET_NEED_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_NEED_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateMarketNeedAction = () => async (dispatch) => {
  dispatch({ type: MARKET_NEED_UPDATE_RESET });
};

// Delete
export const deleteMarketNeedAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_NEED_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteMarketNeed(id, token);

    dispatch({
      type: MARKET_NEED_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKET_NEED_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteMarketNeedAction = () => async (dispatch) => {
  dispatch({ type: MARKET_NEED_DELETE_RESET });
};
