// Constant imports
import {
  MEMBERSHIP_LIST_REQUEST,
  MEMBERSHIP_LIST_SUCCESS,
  MEMBERSHIP_LIST_FAIL,
  MEMBERSHIP_LIST_RESET,
  MEMBERSHIP_CREATE_REQUEST,
  MEMBERSHIP_CREATE_SUCCESS,
  MEMBERSHIP_CREATE_FAIL,
  MEMBERSHIP_CREATE_RESET,
  MEMBERSHIP_DETAILS_REQUEST,
  MEMBERSHIP_DETAILS_SUCCESS,
  MEMBERSHIP_DETAILS_FAIL,
  MEMBERSHIP_DETAILS_RESET,
  MEMBERSHIP_UPDATE_REQUEST,
  MEMBERSHIP_UPDATE_SUCCESS,
  MEMBERSHIP_UPDATE_FAIL,
  MEMBERSHIP_UPDATE_RESET,
  MEMBERSHIP_DELETE_REQUEST,
  MEMBERSHIP_DELETE_SUCCESS,
  MEMBERSHIP_DELETE_FAIL,
  MEMBERSHIP_DELETE_RESET,
} from "../constants/membershipConstants";

// List all
export const membershipListReducer = (
  state = {
    loading: false,
    count: 0,
    memberships: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MEMBERSHIP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MEMBERSHIP_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        memberships: action.payload.memberships,
        success: true,
        error: "",
      };

    case MEMBERSHIP_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        memberships: [],
        success: false,
        error: action.payload,
      };

    case MEMBERSHIP_LIST_RESET:
      return {
        loading: false,
        count: 0,
        memberships: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const membershipCreateReducer = (
  state = { loading: false, membership: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MEMBERSHIP_CREATE_REQUEST:
      return {
        loading: true,
        membership: state.membership,
        success: false,
        error: "",
      };

    case MEMBERSHIP_CREATE_SUCCESS:
      return {
        loading: false,
        membership: action.payload,
        success: true,
        error: "",
      };

    case MEMBERSHIP_CREATE_FAIL:
      return {
        loading: false,
        membership: state.membership,
        success: false,
        error: action.payload,
      };

    case MEMBERSHIP_CREATE_RESET:
      return { loading: false, membership: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const membershipDetailsReducer = (
  state = { loading: false, membership: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MEMBERSHIP_DETAILS_REQUEST:
      return {
        loading: true,
        membership: state.membership,
        success: false,
        error: "",
      };

    case MEMBERSHIP_DETAILS_SUCCESS:
      return {
        loading: false,
        membership: action.payload,
        success: true,
        error: "",
      };

    case MEMBERSHIP_DETAILS_FAIL:
      return {
        loading: false,
        membership: state.membership,
        success: false,
        error: action.payload,
      };

    case MEMBERSHIP_DETAILS_RESET:
      return { loading: false, membership: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const membershipUpdateReducer = (
  state = { loading: false, membership: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MEMBERSHIP_UPDATE_REQUEST:
      return {
        loading: true,
        membership: state.membership,
        success: false,
        error: "",
      };

    case MEMBERSHIP_UPDATE_SUCCESS:
      return {
        loading: false,
        membership: action.payload,
        success: true,
        error: "",
      };

    case MEMBERSHIP_UPDATE_FAIL:
      return {
        loading: false,
        membership: state.membership,
        success: false,
        error: action.payload,
      };

    case MEMBERSHIP_UPDATE_RESET:
      return { loading: false, membership: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const membershipDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MEMBERSHIP_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case MEMBERSHIP_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case MEMBERSHIP_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case MEMBERSHIP_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
