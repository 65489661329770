// Constant imports
import {
  BOOKING_CODE_LIST_REQUEST,
  BOOKING_CODE_LIST_SUCCESS,
  BOOKING_CODE_LIST_FAIL,
  BOOKING_CODE_LIST_RESET,
  BOOKING_CODE_CREATE_REQUEST,
  BOOKING_CODE_CREATE_SUCCESS,
  BOOKING_CODE_CREATE_FAIL,
  BOOKING_CODE_CREATE_RESET,
  BOOKING_CODE_DETAILS_REQUEST,
  BOOKING_CODE_DETAILS_SUCCESS,
  BOOKING_CODE_DETAILS_FAIL,
  BOOKING_CODE_DETAILS_RESET,
  BOOKING_CODE_UPDATE_REQUEST,
  BOOKING_CODE_UPDATE_SUCCESS,
  BOOKING_CODE_UPDATE_FAIL,
  BOOKING_CODE_UPDATE_RESET,
  BOOKING_CODE_DELETE_REQUEST,
  BOOKING_CODE_DELETE_SUCCESS,
  BOOKING_CODE_DELETE_FAIL,
  BOOKING_CODE_DELETE_RESET,
} from "../constants/bookingCodeConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listBookingCodesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: BOOKING_CODE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getBookingCodes(params, token);

      dispatch({
        type: BOOKING_CODE_LIST_SUCCESS,

        payload: {
          count: data.count,
          budget_total: data.budget_total.result,
          invoiced_total: data.invoiced_total.result,
          labour_expense_total: data.labour_expense_total.result,
          margin_percentage_average: data.margin_percentage_average.result,
          margin_total: data.margin_total.result,
          non_labour_expense_total: data.non_labour_expense_total.result,
          remaining_total: data.remaining_total.result,
          revenue_total: data.revenue_total.result,
          total_expense_total: data.total_expense_total.result,
          wip_total: data.wip_total.result,
          booking_codes: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: BOOKING_CODE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListBookingCodesAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_CODE_LIST_RESET });
};

// Create
export const createBookingCodeAction =
  (bookingCode) => async (dispatch, getState) => {
    try {
      dispatch({ type: BOOKING_CODE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createBookingCode(
        token,
        bookingCode
      );

      dispatch({
        type: BOOKING_CODE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOKING_CODE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateBookingCodeAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_CODE_CREATE_RESET });
};

// List details
export const listBookingCodeDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: BOOKING_CODE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getBookingCode(id, token);

      dispatch({
        type: BOOKING_CODE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOKING_CODE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListBookingCodeDetailsAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_CODE_DETAILS_RESET });
};

// Update
export const updateBookingCodeAction =
  (id, bookingCode) => async (dispatch, getState) => {
    try {
      dispatch({ type: BOOKING_CODE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateBookingCode(
        id,
        token,
        bookingCode
      );

      dispatch({
        type: BOOKING_CODE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOKING_CODE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateBookingCodeAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_CODE_UPDATE_RESET });
};

// Delete
export const deleteBookingCodeAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BOOKING_CODE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteBookingCode(id, token);

    dispatch({
      type: BOOKING_CODE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_CODE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteBookingCodeAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_CODE_DELETE_RESET });
};
