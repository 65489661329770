export const STAFF_DEVELOPMENT_PLAN_LIST_REQUEST =
  "STAFF_DEVELOPMENT_PLAN_LIST_REQUEST";
export const STAFF_DEVELOPMENT_PLAN_LIST_SUCCESS =
  "STAFF_DEVELOPMENT_PLAN_LIST_SUCCESS";
export const STAFF_DEVELOPMENT_PLAN_LIST_FAIL =
  "STAFF_DEVELOPMENT_PLAN_LIST_FAIL";
export const STAFF_DEVELOPMENT_PLAN_LIST_RESET =
  "STAFF_DEVELOPMENT_PLAN_LIST_RESET";

export const STAFF_DEVELOPMENT_PLAN_CREATE_REQUEST =
  "STAFF_DEVELOPMENT_PLAN_CREATE_REQUEST";
export const STAFF_DEVELOPMENT_PLAN_CREATE_SUCCESS =
  "STAFF_DEVELOPMENT_PLAN_CREATE_SUCCESS";
export const STAFF_DEVELOPMENT_PLAN_CREATE_FAIL =
  "STAFF_DEVELOPMENT_PLAN_CREATE_FAIL";
export const STAFF_DEVELOPMENT_PLAN_CREATE_RESET =
  "STAFF_DEVELOPMENT_PLAN_CREATE_RESET";

export const STAFF_DEVELOPMENT_PLAN_DETAILS_REQUEST =
  "STAFF_DEVELOPMENT_PLAN_DETAILS_REQUEST";
export const STAFF_DEVELOPMENT_PLAN_DETAILS_SUCCESS =
  "STAFF_DEVELOPMENT_PLAN_DETAILS_SUCCESS";
export const STAFF_DEVELOPMENT_PLAN_DETAILS_FAIL =
  "STAFF_DEVELOPMENT_PLAN_DETAILS_FAIL";
export const STAFF_DEVELOPMENT_PLAN_DETAILS_RESET =
  "STAFF_DEVELOPMENT_PLAN_DETAILS_RESET";

export const STAFF_DEVELOPMENT_PLAN_UPDATE_REQUEST =
  "STAFF_DEVELOPMENT_PLAN_UPDATE_REQUEST";
export const STAFF_DEVELOPMENT_PLAN_UPDATE_SUCCESS =
  "STAFF_DEVELOPMENT_PLAN_UPDATE_SUCCESS";
export const STAFF_DEVELOPMENT_PLAN_UPDATE_FAIL =
  "STAFF_DEVELOPMENT_PLAN_UPDATE_FAIL";
export const STAFF_DEVELOPMENT_PLAN_UPDATE_RESET =
  "STAFF_DEVELOPMENT_PLAN_UPDATE_RESET";

export const STAFF_DEVELOPMENT_PLAN_DELETE_REQUEST =
  "STAFF_DEVELOPMENT_PLAN_DELETE_REQUEST";
export const STAFF_DEVELOPMENT_PLAN_DELETE_SUCCESS =
  "STAFF_DEVELOPMENT_PLAN_DELETE_SUCCESS";
export const STAFF_DEVELOPMENT_PLAN_DELETE_FAIL =
  "STAFF_DEVELOPMENT_PLAN_DELETE_FAIL";
export const STAFF_DEVELOPMENT_PLAN_DELETE_RESET =
  "STAFF_DEVELOPMENT_PLAN_DELETE_RESET";
