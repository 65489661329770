// Library imports
import { Classes, Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Cell,
  Legend,
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from "recharts";

// Component imports
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Main function
function KPICard({ darkColor, interactive, lightColor, onClick, kpi }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Update data
  const [data, setData] = useState([]);

  useEffect(() => {
    if (kpi) {
      setData([
        {
          name: "Target",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: kpi.target,
        },
        {
          name: "Actual",
          fill:
            kpi.rating === "Exceeded"
              ? lightMode
                ? Colors.GREEN3
                : Colors.GREEN5
              : kpi.rating === "Met"
              ? lightMode
                ? Colors.ORANGE3
                : Colors.ORANGE5
              : lightMode
              ? Colors.RED3
              : Colors.RED5,
          value: kpi.achieved,
        },
      ]);
    }
  }, [kpi, lightMode]);

  // Legend formatter
  const legendFormatter = (value, entry, index) => {
    return (
      <span
        style={{ color: lightMode ? Colors.BLACK : Colors.WHITE, fontSize: 12 }}
      >
        {value}
      </span>
    );
  };

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{kpi.year}</Tag>

              <Tag
                intent={
                  kpi.rating === "Exceeded"
                    ? "success"
                    : kpi.rating === "Met"
                    ? "warning"
                    : "danger"
                }
                minimal
                style={{
                  marginLeft: "5px",
                }}
              >
                {kpi.rating}
              </Tag>
            </div>

            <InfoPopup
              content={
                <>
                  <H5>{kpi.kpi}</H5>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Units</strong>
                  </p>

                  <p>{kpi.units}</p>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Actual</strong>
                  </p>

                  <p>
                    <Tag
                      intent={
                        kpi.rating === "Exceeded"
                          ? "success"
                          : kpi.rating === "Met"
                          ? "warning"
                          : "danger"
                      }
                    >
                      {numberFormatter(kpi.achieved)}
                    </Tag>
                  </p>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Target</strong>
                  </p>

                  <p>
                    <Tag intent="primary">{numberFormatter(kpi.target)}</Tag>
                  </p>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Comments</strong>
                  </p>

                  <p>
                    {kpi.comments ? (
                      kpi.comments
                    ) : (
                      <span className={Classes.TEXT_MUTED}>No comments.</span>
                    )}
                  </p>
                </>
              }
            />
          </div>

          <div style={{ overflowY: "hidden" }}>
            <ResponsiveContainer height={300} width="100%">
              <RadialBarChart
                data={data}
                endAngle={0}
                innerRadius="70%"
                outerRadius="100%"
                startAngle={180}
                style={{ top: -20 }}
              >
                <defs>
                  {data.map((item, i) => {
                    return (
                      <linearGradient
                        id={`color${item.fill}`}
                        key={i}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor={item.fill}
                          stopOpacity={0.9}
                        />
                        <stop
                          offset="95%"
                          stopColor={item.fill}
                          stopOpacity={0.7}
                        />
                      </linearGradient>
                    );
                  })}
                </defs>

                <Legend
                  align="center"
                  formatter={legendFormatter}
                  wrapperStyle={{
                    background: lightMode
                      ? Colors.LIGHT_GRAY4
                      : Colors.DARK_GRAY2,
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY1 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: 3,
                    bottom: 75,
                    lineHeight: "30px",
                  }}
                />

                <PolarAngleAxis
                  domain={[0, Math.max(kpi.achieved, kpi.target)]}
                  tick={false}
                  type="number"
                />

                <RadialBar background cornerRadius={15} dataKey="value">
                  {data.map((entry, index) => (
                    <Cell
                      fill={`url(#color${entry.fill})`}
                      key={`cell-${index}`}
                    />
                  ))}
                </RadialBar>

                <text
                  style={{
                    fontSize: 32,
                    fontWeight: "bold",
                    fill:
                      kpi.rating === "Exceeded"
                        ? lightMode
                          ? Colors.GREEN3
                          : Colors.GREEN5
                        : kpi.rating === "Met"
                        ? lightMode
                          ? Colors.ORANGE3
                          : Colors.ORANGE5
                        : lightMode
                        ? Colors.RED3
                        : Colors.RED5,
                  }}
                  textAnchor="middle"
                  x="50%"
                  y="47%"
                >
                  {numberFormatter(kpi.achieved)}
                </text>

                <text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                    fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                  }}
                  textAnchor="middle"
                  x="50%"
                  y="57%"
                >
                  Target: {numberFormatter(kpi.target)}
                </text>
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      }
      darkColor={darkColor}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={kpi.kpi}
      width={300}
    />
  );
}

export default KPICard;
