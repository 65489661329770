// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  Divider,
  FormGroup,
  HTMLTable,
  H5,
  Tabs,
  Tab,
  Tag,
  Text,
} from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import BookingInfo from "../components/BookingInfo";
import ClientAccountPlanFacts from "../components/ClientAccountPlanFacts";
import DashboardSection from "../components/DashboardSection";
import DashboardWelcomeCard from "../components/DashboardWelcomeCard";
import DataCardContainer from "../components/DataCardContainer";
import DataCardSimple from "../components/DataCardSimple";
import DateValue from "../components/DateValue";
import EditableTableContainer from "./EditableTableContainer";
import EngagementActualsCard from "../components/EngagementActualsCard";
import EngagementBaselineFacts from "../components/EngagementBaselineFacts";
import EngagementBookingTimeCard from "../components/EngagementBookingTimeCard";
import EngagementBookingUtilisationCard from "../components/EngagementBookingUtilisationCard";
import EngagementCloseFacts from "../components/EngagementCloseFacts";
import EngagementPlanAtCompletionCard from "../components/EngagementPlanAtCompletionCard";
import ErrorAlert from "../components/ErrorAlert";
import ErrorList from "./ErrorList";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "./FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import FormDrawerTimeField from "../components/FormDrawerTimeField";
import HelpDialog2 from "../components/HelpDialog2";
import InvoiceFacts from "../components/InvoiceFacts";
import LeaveCategoryInfo from "../components/LeaveCategoryInfo";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NumericValue from "../components/NumericValue";
import ProposalAverageValueCard from "../components/ProposalAverageValueCard";
import ProposalPipelineCard from "../components/ProposalPipelineCard";
import ProposalQualificationFacts from "../components/ProposalQualificationFacts";
import ProposalSubmissionFacts from "../components/ProposalSubmissionFacts";
import ProposalTotalValueCard from "../components/ProposalTotalValueCard";
import SelectField from "../components/SelectField";
import StaffBookingsCard2 from "../components/StaffBookingsCard2";
import StaffExpenseFacts from "../components/StaffExpenseFacts";
import StaffLeaveRequestFacts from "../components/StaffLeaveRequestFacts";
import StaffRecruitmentFacts from "../components/StaffRecruitmentFacts";
import StaffTimesheetFacts from "../components/StaffTimesheetFacts";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TabsContainer from "../components/TabsContainer";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabSection2 from "../components/TabSection2";
import TabSectionField from "./TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TodoStatusCard from "../components/TodoStatusCard";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import generateYearList from "../utilities/generateYearList";
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Approval response
import {
  listApprovalResponsesAction,
  listApprovalResponseDetailsAction,
  resetUpdateApprovalResponseAction,
  updateApprovalResponseAction,
} from "../actions/approvalResponseActions";

// Booking
import { listBookingsAction } from "../actions/bookingActions";

// Booking code
import {
  listBookingCodeDetailsAction,
  listBookingCodesAction,
  resetListBookingCodeDetailsAction,
} from "../actions/bookingCodeActions";

// Claim
import {
  createClaimAction,
  deleteClaimAction,
  duplicateClaimAction,
  listClaimsAction,
  listClaimDetailsAction,
  resetCreateClaimAction,
  resetDeleteClaimAction,
  resetDuplicateClaimAction,
  resetUpdateClaimAction,
  updateClaimAction,
} from "../actions/claimActions";

// Engagement
import { listEngagementsAction } from "../actions/engagementActions";

// Expense category
import { listExpenseCategoriesAction } from "../actions/expenseCategoryActions";

// Expense
import {
  createExpenseAction,
  deleteExpenseAction,
  listExpensesAction,
  listExpenseDetailsAction,
  resetCreateExpenseAction,
  resetDeleteExpenseAction,
  resetUpdateExpenseAction,
  updateExpenseAction,
} from "../actions/expenseActions";

// Leave category
import { listLeaveCategoriesAction } from "../actions/leaveCategoryActions";

// Leave request
import {
  cancelLeaveRequestAction,
  createLeaveRequestAction,
  deleteLeaveRequestAction,
  listLeaveRequestsAction,
  listLeaveRequestDetailsAction,
  resetCreateLeaveRequestAction,
  resetDeleteLeaveRequestAction,
  resetCancelLeaveRequestAction,
  resetUpdateLeaveRequestAction,
  updateLeaveRequestAction,
} from "../actions/leaveRequestActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Proposal
import { listProposalsAction } from "../actions/proposalActions";

// Staff
import { listStaffAction } from "../actions/staffActions";

// Staff development plan
import {
  listStaffDevelopmentPlansAction,
  listStaffDevelopmentPlanDetailsAction,
  resetUpdateStaffDevelopmentPlanAction,
  updateStaffDevelopmentPlanAction,
} from "../actions/staffDevelopmentPlanActions";

// Staff goal
import {
  listStaffGoalsAction,
  listStaffGoalDetailsAction,
  resetUpdateStaffGoalAction,
  updateStaffGoalAction,
} from "../actions/staffGoalActions";

// Timesheet
import {
  createTimesheetAction,
  deleteTimesheetAction,
  duplicateTimesheetAction,
  listTimesheetsAction,
  listTimesheetDetailsAction,
  resetCreateTimesheetAction,
  resetDeleteTimesheetAction,
  resetDuplicateTimesheetAction,
  resetUpdateTimesheetAction,
  updateTimesheetAction,
} from "../actions/timesheetActions";

// Timesheet entry
import {
  createTimesheetEntryAction,
  deleteTimesheetEntryAction,
  listTimesheetEntriesAction,
  listTimesheetEntryDetailsAction,
  resetCreateTimesheetEntryAction,
  resetDeleteTimesheetEntryAction,
  resetUpdateTimesheetEntryAction,
  updateTimesheetEntryAction,
} from "../actions/timesheetEntryActions";

// To-do
import { listTodosAction } from "../actions/todoActions";

// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Week
import { listWeeksAction } from "../actions/weekActions";

// Main function
function Dashboard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Approval response
  const approvalResponseDetails = useSelector(
    (state) => state.approvalResponseDetails
  );

  const approvalResponseList = useSelector(
    (state) => state.approvalResponseList
  );

  const approvalResponseUpdate = useSelector(
    (state) => state.approvalResponseUpdate
  );

  // Booking
  const bookingList = useSelector((state) => state.bookingList);

  // Booking code
  const bookingCodeDetails = useSelector((state) => state.bookingCodeDetails);

  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Claim
  const claimCreate = useSelector((state) => state.claimCreate);

  const claimDelete = useSelector((state) => state.claimDelete);

  const claimDetails = useSelector((state) => state.claimDetails);

  const claimDuplicate = useSelector((state) => state.claimDuplicate);

  const claimList = useSelector((state) => state.claimList);

  const claimUpdate = useSelector((state) => state.claimUpdate);

  // Engagement
  const engagementList = useSelector((state) => state.engagementList);

  // Expense
  const expenseCreate = useSelector((state) => state.expenseCreate);

  const expenseDelete = useSelector((state) => state.expenseDelete);

  const expenseDetails = useSelector((state) => state.expenseDetails);

  const expenseList = useSelector((state) => state.expenseList);

  const expenseUpdate = useSelector((state) => state.expenseUpdate);

  // Expense category
  const expenseCategoryList = useSelector((state) => state.expenseCategoryList);

  // Leave category
  const leaveCategoryList = useSelector((state) => state.leaveCategoryList);

  // Leave request
  const leaveRequestCancel = useSelector((state) => state.leaveRequestCancel);

  const leaveRequestCreate = useSelector((state) => state.leaveRequestCreate);

  const leaveRequestDelete = useSelector((state) => state.leaveRequestDelete);

  const leaveRequestDetails = useSelector((state) => state.leaveRequestDetails);

  const leaveRequestList = useSelector((state) => state.leaveRequestList);

  const leaveRequestUpdate = useSelector((state) => state.leaveRequestUpdate);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  const checkSubscription = useSelector((state) => state.checkSubscription);

  // Proposal
  const proposalList = useSelector((state) => state.proposalList);

  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Staff development plan
  const staffDevelopmentPlanDetails = useSelector(
    (state) => state.staffDevelopmentPlanDetails
  );

  const staffDevelopmentPlanList = useSelector(
    (state) => state.staffDevelopmentPlanList
  );

  const staffDevelopmentPlanUpdate = useSelector(
    (state) => state.staffDevelopmentPlanUpdate
  );

  // Staff goal
  const staffGoalDetails = useSelector((state) => state.staffGoalDetails);

  const staffGoalList = useSelector((state) => state.staffGoalList);

  const staffGoalUpdate = useSelector((state) => state.staffGoalUpdate);

  // Timesheet
  const timesheetCreate = useSelector((state) => state.timesheetCreate);

  const timesheetDelete = useSelector((state) => state.timesheetDelete);

  const timesheetDetails = useSelector((state) => state.timesheetDetails);

  const timesheetDuplicate = useSelector((state) => state.timesheetDuplicate);

  const timesheetList = useSelector((state) => state.timesheetList);

  const timesheetUpdate = useSelector((state) => state.timesheetUpdate);

  // Timesheet entry
  const timesheetEntryCreate = useSelector(
    (state) => state.timesheetEntryCreate
  );

  const timesheetEntryDelete = useSelector(
    (state) => state.timesheetEntryDelete
  );

  const timesheetEntryDetails = useSelector(
    (state) => state.timesheetEntryDetails
  );

  const timesheetEntryList = useSelector((state) => state.timesheetEntryList);

  const timesheetEntryUpdate = useSelector(
    (state) => state.timesheetEntryUpdate
  );

  // To-do
  const todoList = useSelector((state) => state.todoList);

  // User profile
  const userProfileDetails = useSelector((state) => state.userProfileDetails);

  // Week
  const weekList = useSelector((state) => state.weekList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("dashboard-overview-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Goals
  const [updateGoalDrawerIsOpen, setUpdateGoalDrawerIsOpen] = useState(false);

  // Development plans
  const [
    updateDevelopmentPlanDrawerIsOpen,
    setUpdateDevelopmentPlanDrawerIsOpen,
  ] = useState(false);

  // Timesheets
  const [addTimesheetDrawerIsOpen, setAddTimesheetDrawerIsOpen] =
    useState(false);
  const [updateTimesheetDrawerIsOpen, setUpdateTimesheetDrawerIsOpen] =
    useState(false);

  // Expense claims
  const [addClaimDrawerIsOpen, setAddClaimDrawerIsOpen] = useState(false);
  const [updateClaimDrawerIsOpen, setUpdateClaimDrawerIsOpen] = useState(false);

  // Leave requests
  const [addLeaveRequestDrawerIsOpen, setAddLeaveRequestDrawerIsOpen] =
    useState(false);
  const [updateLeaveRequestDrawerIsOpen, setUpdateLeaveRequestDrawerIsOpen] =
    useState(false);

  // Approval response
  const [
    updateApprovalResponseDrawerIsOpen,
    setUpdateApprovalResponseDrawerIsOpen,
  ] = useState(false);

  // Search and filter variables
  // Approval response
  const approvalResponseQueryDefault = {
    approverName: userLogin.user,
    decisionRight: "",
    exclude: "Draft",
    organisation: activeOrganisation.id,
    outcome: "",
    page: 1,
    requestor: "",
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalResponseQuery, setApprovalResponseQuery] = useState({
    ...approvalResponseQueryDefault,
  });

  // Booking
  const bookingQueryDefault = {
    billable: "",
    bookingCode: "",
    bookingProfile: "month",
    endEnd: "",
    endStart: "",
    engagement: "",
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    staff: "",
    startEnd: "",
    startStart: "",
    type: "",
    user: userLogin.user,
  };
  const [bookingQuery, setBookingQuery] = useState({
    ...bookingQueryDefault,
  });

  // Booking code
  const bookingCodeQueryDefault = {
    billable: "",
    engagement: "",
    holding: "",
    open: true,
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Claim
  const claimQueryDefault = {
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    staff: "",
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: userLogin.user,
  };
  const [claimQuery, setClaimQuery] = useState({
    ...claimQueryDefault,
  });

  // Engagement
  const engagementQueryDefault = {
    client: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    sponsor: "",
    status: "",
    user: userLogin.user,
  };
  const [engagementQuery] = useState({
    ...engagementQueryDefault,
  });

  // Expense
  const expenseQueryDefault = {
    billable: "",
    bookingCode: "",
    category: "",
    claim: "",
    dateEnd: "",
    dateStart: "",
    engagement: "",
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    reversed: "",
    sortField: "",
    staff: "",
  };
  const [expenseQuery, setExpenseQuery] = useState({
    ...expenseQueryDefault,
  });

  // Expense category
  const expenseCategoryQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [expenseCategoryQuery, setExpenseCategoryQuery] = useState({
    ...expenseCategoryQueryDefault,
  });

  // Leave category
  const leaveCategoryQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [leaveCategoryQuery, setLeaveCategoryQuery] = useState({
    ...leaveCategoryQueryDefault,
  });

  // Leave request
  const leaveRequestQueryDefault = {
    category: "",
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    sortField: "",
    staff: "",
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: userLogin.user,
    weekLabel: "",
  };
  const [leaveRequestQuery, setLeaveRequestQuery] = useState({
    ...leaveRequestQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Proposal
  const proposalQueryDefault = {
    client: "",
    contact: "",
    dueEnd: "",
    dueStart: "",
    exclude: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
    unlinked: "",
    user: userLogin.user,
  };
  const [proposalQuery] = useState({
    ...proposalQueryDefault,
  });

  // Staff
  const staffQueryDefault = {
    bookingProfile: "week",
    competencies: "",
    employment: "",
    freeTime: "",
    level: "",
    location: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
    staff: "",
  };
  const [staffQuery, setStaffQuery] = useState({
    ...staffQueryDefault,
  });

  // Staff development plan
  const staffDevelopmentPlanQueryDefault = {
    dueEnd: "",
    dueStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    staff: "",
    status: "",
  };
  const [staffDevelopmentPlanQuery, setStaffDevelopmentPlanQuery] = useState({
    ...staffDevelopmentPlanQueryDefault,
  });

  // Staff goal
  const staffGoalQueryDefault = {
    dueEnd: "",
    dueStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    rating: "",
    searchString: "",
    sortField: "",
    staff: "",
    year: "",
  };
  const [staffGoalQuery, setStaffGoalQuery] = useState({
    ...staffGoalQueryDefault,
  });

  // Timesheet
  const timesheetQueryDefault = {
    completedEnd: "",
    completedStart: "",
    organisation: activeOrganisation.id,
    page: 1,
    range: "",
    staff: "",
    status: "",
    submittedEnd: "",
    submittedStart: "",
    user: userLogin.user,
    weekLabel: "",
  };
  const [timesheetQuery, setTimesheetQuery] = useState({
    ...timesheetQueryDefault,
  });

  // Timesheet entry
  const timesheetEntryQueryDefault = {
    page: 1,
    timesheet: "",
  };
  const [timesheetEntryQuery, setTimesheetEntryQuery] = useState({
    ...timesheetEntryQueryDefault,
  });

  // To-do
  const todoQueryDefault = {
    category: "",
    daysAhead: 84,
    done: "",
    organisation: activeOrganisation.id,
  };
  const [todoQuery] = useState({
    ...todoQueryDefault,
  });

  // Form variables
  // Approval response
  const approvalResponseDefault = {
    comments: "",
    outcome: "Pending",
    request: "",
    status: "Draft",
  };
  const [approvalResponse, setApprovalResponse] = useState({
    ...approvalResponseDefault,
  });

  // Claim
  const claimDefault = {
    comments: "",
    name: "",
    receipts: "",
    receiptsName: "",
    staff: "",
    value: "",
  };
  const [claim, setClaim] = useState({
    ...claimDefault,
  });

  const claimApprovalRequestDefault = {
    approver: "",
    approverName: "",
    submit: false,
  };
  const [claimApprovalRequest, setClaimApprovalRequest] = useState({
    ...claimApprovalRequestDefault,
  });

  // Expense
  const expenseDefault = {
    bookingCode: "",
    bookingCodeName: "",
    category: "",
    categoryName: "",
    claim: "",
    comments: "",
    date: "",
    value: "",
  };
  const [expense, setExpense] = useState({
    ...expenseDefault,
  });

  // Leave request
  const leaveRequestDefault = {
    category: "",
    categoryName: "",
    comments: "",
    minutesFri: 0,
    minutesMon: 0,
    minutesSat: 0,
    minutesSun: 0,
    minutesTue: 0,
    minutesThu: 0,
    minutesWed: 0,
    weekLabel: "",
  };
  const [leaveRequest, setLeaveRequest] = useState({
    ...leaveRequestDefault,
  });

  const leaveRequestApprovalRequestDefault = {
    approver: "",
    approverName: "",
    submit: false,
  };
  const [leaveRequestApprovalRequest, setLeaveRequestApprovalRequest] =
    useState({
      ...leaveRequestApprovalRequestDefault,
    });

  // Staff development plan
  const staffDevelopmentPlanDefault = {
    staffComments: "",
  };
  const [staffDevelopmentPlan, setStaffDevelopmentPlan] = useState({
    ...staffDevelopmentPlanDefault,
  });

  // Staff goal
  const staffGoalDefault = {
    staffComments: "",
  };
  const [staffGoal, setStaffGoal] = useState({
    ...staffGoalDefault,
  });

  // Timesheet
  const timesheetDefault = {
    comments: "",
    staff: "",
    weekLabel: "",
  };
  const [timesheet, setTimesheet] = useState({
    ...timesheetDefault,
  });

  const timesheetApprovalRequestDefault = {
    approver: "",
    approverName: "",
    submit: false,
  };
  const [timesheetApprovalRequest, setTimesheetApprovalRequest] = useState({
    ...timesheetApprovalRequestDefault,
  });

  // Timesheet entry
  const timesheetEntryDefault = {
    bookingCode: "",
    bookingCodeName: "",
    comments: "",
    engagementLabourExpense: "",
    minutesFri: 0,
    minutesMon: 0,
    minutesSat: 0,
    minutesSun: 0,
    minutesTue: 0,
    minutesThu: 0,
    minutesWed: 0,
    task: "",
    timesheet: "",
  };
  const [timesheetEntry, setTimesheetEntry] = useState({
    ...timesheetEntryDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval response
    setApprovalResponse({
      ...approvalResponseDefault,
    });

    // Claim
    setClaim({
      ...claimDefault,
    });

    setClaimApprovalRequest({
      ...claimApprovalRequestDefault,
    });

    // Expense
    setExpense({
      ...expenseDefault,
    });

    setEditExpenseId(null);
    setShowNewExpenseForm(false);

    // Leave request
    setLeaveRequest({
      ...leaveRequestDefault,
    });

    setLeaveRequestApprovalRequest({
      ...leaveRequestApprovalRequestDefault,
    });

    // Staff development plan
    setStaffDevelopmentPlan({
      ...staffDevelopmentPlanDefault,
    });

    // Staff goal
    setStaffGoal({
      ...staffGoalDefault,
    });

    // Timesheet
    setTimesheet({
      ...timesheetDefault,
    });

    setTimesheetApprovalRequest({
      ...timesheetApprovalRequestDefault,
    });

    // Timesheet entry
    setTimesheetEntry({
      ...timesheetEntryDefault,
    });

    setEditTimesheetEntryId(null);
    setShowNewTimesheetEntryForm(false);

    // Query variables
    // Booking code
    setBookingCodeQuery({
      ...bookingCodeQueryDefault,
    });

    // Expense category
    setExpenseCategoryQuery({
      ...expenseCategoryQueryDefault,
    });

    // Leave category
    setLeaveCategoryQuery({
      ...leaveCategoryQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Staff
    setStaffQuery({
      ...staffQueryDefault,
    });
  };

  // Initialise drawer variables for approval response
  useEffect(() => {
    setApprovalResponse({
      comments: approvalResponseDetails.approval_response.comments
        ? approvalResponseDetails.approval_response.comments
        : approvalResponseDefault.comments,
      outcome: approvalResponseDetails.approval_response.outcome
        ? approvalResponseDetails.approval_response.outcome
        : approvalResponseDefault.outcome,
      request: approvalResponseDetails.approval_response.request
        ? approvalResponseDetails.approval_response.request
        : approvalResponseDefault.request,
      status: approvalResponseDetails.approval_response.status
        ? approvalResponseDetails.approval_response.status
        : approvalResponseDefault.status,
    });
    // eslint-disable-next-line
  }, [approvalResponseDetails.approval_response]);

  // Initialise drawer variables for claim
  useEffect(() => {
    setClaim({
      comments: claimDetails.claim.comments
        ? claimDetails.claim.comments
        : claimDefault.comments,
      name: claimDetails.claim.name
        ? claimDetails.claim.name
        : claimDefault.name,
      receipts: claimDetails.claim.receipts
        ? claimDetails.claim.receipts
        : claimDefault.receipts,
      receiptsName: claimDetails.claim.receipts
        ? claimDetails.claim.receipts.split("/").pop()
        : claimDefault.receiptsName,
      staff: claimDetails.claim.staff
        ? claimDetails.claim.staff
        : claimDefault.staff,
      value: claimDetails.claim.value
        ? claimDetails.claim.value
        : claimDefault.value,
    });
    // eslint-disable-next-line
  }, [claimDetails.claim]);

  // Initialise drawer variables for expense
  useEffect(() => {
    setExpense({
      bookingCode: expenseDetails.expense.booking_code
        ? expenseDetails.expense.booking_code
        : expenseDefault.bookingCode,
      bookingCodeName: expenseDetails.expense.booking_code_data
        ? expenseDetails.expense.booking_code_data.name
        : expenseDefault.bookingCodeName,
      category: expenseDetails.expense.category
        ? expenseDetails.expense.category
        : expenseDefault.category,
      categoryName: expenseDetails.expense.category_name
        ? expenseDetails.expense.category_name
        : expenseDefault.categoryName,
      claim: expenseDetails.expense.claim
        ? expenseDetails.expense.claim
        : expenseDefault.claim,
      comments: expenseDetails.expense.comments
        ? expenseDetails.expense.comments
        : expenseDefault.comments,
      date: expenseDetails.expense.date
        ? new Date(expenseDetails.expense.date)
        : expenseDefault.date,
      value: expenseDetails.expense.value
        ? expenseDetails.expense.value
        : expenseDefault.value,
    });
    // eslint-disable-next-line
  }, [expenseDetails.expense]);

  // Initialise drawer variables for leave request
  useEffect(() => {
    setLeaveRequest({
      category: leaveRequestDetails.leave_request.category
        ? leaveRequestDetails.leave_request.category
        : leaveRequestDefault.category,
      categoryName: leaveRequestDetails.leave_request.category_name
        ? leaveRequestDetails.leave_request.category_name
        : leaveRequestDefault.categoryName,
      comments: leaveRequestDetails.leave_request.comments
        ? leaveRequestDetails.leave_request.comments
        : leaveRequestDefault.comments,
      minutesFri: leaveRequestDetails.leave_request.minutes_fri
        ? leaveRequestDetails.leave_request.minutes_fri
        : leaveRequestDefault.minutesFri,
      minutesMon: leaveRequestDetails.leave_request.minutes_mon
        ? leaveRequestDetails.leave_request.minutes_mon
        : leaveRequestDefault.minutesMon,
      minutesSat: leaveRequestDetails.leave_request.minutes_sat
        ? leaveRequestDetails.leave_request.minutes_sat
        : leaveRequestDefault.minutesSat,
      minutesSun: leaveRequestDetails.leave_request.minutes_sun
        ? leaveRequestDetails.leave_request.minutes_sun
        : leaveRequestDefault.minutesSun,
      minutesTue: leaveRequestDetails.leave_request.minutes_tue
        ? leaveRequestDetails.leave_request.minutes_tue
        : leaveRequestDefault.minutesTue,
      minutesThu: leaveRequestDetails.leave_request.minutes_thu
        ? leaveRequestDetails.leave_request.minutes_thu
        : leaveRequestDefault.minutesThu,
      minutesWed: leaveRequestDetails.leave_request.minutes_wed
        ? leaveRequestDetails.leave_request.minutes_wed
        : leaveRequestDefault.minutesWed,
      weekLabel: leaveRequestDetails.leave_request.week_label
        ? leaveRequestDetails.leave_request.week_label
        : leaveRequestDefault.weekLabel,
    });
    // eslint-disable-next-line
  }, [leaveRequestDetails.leave_request]);

  // Initialise drawer variables for staff development plan
  useEffect(() => {
    setStaffDevelopmentPlan({
      staffComments: staffDevelopmentPlanDetails.staff_development_plan
        .staff_comments
        ? staffDevelopmentPlanDetails.staff_development_plan.staff_comments
        : staffDevelopmentPlanDefault.staffComments,
    });
    // eslint-disable-next-line
  }, [staffDevelopmentPlanDetails.staff_development_plan]);

  // Initialise drawer variables for staff goal
  useEffect(() => {
    setStaffGoal({
      staffComments: staffGoalDetails.staff_goal.staff_comments
        ? staffGoalDetails.staff_goal.staff_comments
        : staffGoalDefault.staffComments,
    });
    // eslint-disable-next-line
  }, [staffGoalDetails.staff_goal]);

  // Initialise drawer variables for timesheet
  useEffect(() => {
    setTimesheet({
      comments: timesheetDetails.timesheet.comments
        ? timesheetDetails.timesheet.comments
        : timesheetDefault.comments,
      staff: timesheetDetails.timesheet.staff
        ? timesheetDetails.timesheet.staff
        : timesheetDefault.staff,
      weekLabel: timesheetDetails.timesheet.week_label
        ? timesheetDetails.timesheet.week_label
        : timesheetDefault.weekLabel,
    });
    // eslint-disable-next-line
  }, [timesheetDetails.timesheet]);

  // Initialise drawer variables for timesheet entry
  useEffect(() => {
    setTimesheetEntry({
      bookingCode: timesheetEntryDetails.timesheet_entry.booking_code
        ? timesheetEntryDetails.timesheet_entry.booking_code
        : timesheetEntryDefault.bookingCode,
      bookingCodeName: timesheetEntryDetails.timesheet_entry.booking_code_data
        ? timesheetEntryDetails.timesheet_entry.booking_code_data.name
        : timesheetEntryDefault.bookingCodeName,
      comments: timesheetEntryDetails.timesheet_entry.comments
        ? timesheetEntryDetails.timesheet_entry.comments
        : timesheetEntryDefault.comments,
      engagementLabourExpense: timesheetEntryDetails.timesheet_entry
        .engagement_labour_expense
        ? timesheetEntryDetails.timesheet_entry.engagement_labour_expense
        : timesheetEntryDefault.engagementLabourExpense,
      minutesFri: timesheetEntryDetails.timesheet_entry.minutes_fri
        ? timesheetEntryDetails.timesheet_entry.minutes_fri
        : timesheetEntryDefault.minutesFri,
      minutesMon: timesheetEntryDetails.timesheet_entry.minutes_mon
        ? timesheetEntryDetails.timesheet_entry.minutes_mon
        : timesheetEntryDefault.minutesMon,
      minutesSat: timesheetEntryDetails.timesheet_entry.minutes_sat
        ? timesheetEntryDetails.timesheet_entry.minutes_sat
        : timesheetEntryDefault.minutesSat,
      minutesSun: timesheetEntryDetails.timesheet_entry.minutes_sun
        ? timesheetEntryDetails.timesheet_entry.minutes_sun
        : timesheetEntryDefault.minutesSun,
      minutesTue: timesheetEntryDetails.timesheet_entry.minutes_tue
        ? timesheetEntryDetails.timesheet_entry.minutes_tue
        : timesheetEntryDefault.minutesTue,
      minutesThu: timesheetEntryDetails.timesheet_entry.minutes_thu
        ? timesheetEntryDetails.timesheet_entry.minutes_thu
        : timesheetEntryDefault.minutesThu,
      minutesWed: timesheetEntryDetails.timesheet_entry.minutes_wed
        ? timesheetEntryDetails.timesheet_entry.minutes_wed
        : timesheetEntryDefault.minutesWed,
      task: timesheetEntryDetails.timesheet_entry.task
        ? timesheetEntryDetails.timesheet_entry.task
        : timesheetEntryDefault.task,
      timesheet: timesheetEntryDetails.timesheet_entry.timesheet
        ? timesheetEntryDetails.timesheet_entry.timesheet
        : timesheetEntryDefault.timesheet,
    });
    // eslint-disable-next-line
  }, [timesheetEntryDetails.timesheet_entry]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If onboarding incomplete redirect to organisations page
  useEffect(() => {
    (!checkSubscription.subscription.has_active_seat ||
      !userProfileDetails.user_profile.email_verified) &&
      navigate("/organisations");
    // eslint-disable-next-line
  }, [
    checkSubscription.subscription.has_active_seat,
    userProfileDetails.user_profile.email_verified,
  ]);

  // Load engagement role and task data
  const [engagementRoleOptions, setEngagementRoleOptions] = useState([]);
  const [taskOptions, setTaskOptions] = useState([]);

  useEffect(() => {
    if (timesheetEntry.bookingCode) {
      dispatch(listBookingCodeDetailsAction(timesheetEntry.bookingCode));
    }
    // eslint-disable-next-line
  }, [timesheetEntry.bookingCode]);

  useEffect(() => {
    if (bookingCodeDetails.success) {
      const engagementRoleChoices = [];

      if (bookingCodeDetails.booking_code.engagement_roles_data) {
        bookingCodeDetails.booking_code.engagement_roles_data
          .filter((engagement_role) => engagement_role.user === userLogin.user)
          .map((role) =>
            engagementRoleChoices.push({
              id: role.engagement_labour_expense,
              label: role.role_name,
              value: role.engagement_labour_expense,
            })
          );

        engagementRoleChoices.length > 0 &&
          setTimesheetEntry((prevState) => {
            return {
              ...prevState,
              engagementLabourExpense: engagementRoleChoices[0].value,
            };
          });
      }

      setEngagementRoleOptions(engagementRoleChoices);

      const taskChoices = [
        {
          id: 0,
          label: "None",
          value: "",
        },
      ];

      if (bookingCodeDetails.booking_code.tasks) {
        bookingCodeDetails.booking_code.tasks.map((task, i) =>
          taskChoices.push({
            id: i + 1,
            value: task,
          })
        );

        taskChoices.length > 0 &&
          setTimesheetEntry((prevState) => {
            return {
              ...prevState,
              task: taskChoices.filter(
                (task) => task.value === timesheetEntry.task
              )[0]
                ? taskChoices.filter(
                    (task) => task.value === timesheetEntry.task
                  )[0].value
                : taskChoices[0].value,
            };
          });
      }

      setTaskOptions(taskChoices);

      resetListBookingCodeDetailsAction();
    }
    // eslint-disable-next-line
  }, [bookingCodeDetails.success]);

  // Load week data
  const [weekOptions, setWeekOptions] = useState([]);

  useEffect(() => {
    dispatch(listWeeksAction({}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (weekList.weeks.week_choices) {
      const weekChoices = [];

      weekList.weeks.week_choices.map((week) =>
        weekChoices.push({
          id: week,
          value: week,
        })
      );

      if (weekChoices.length > 0) {
        addLeaveRequestDrawerIsOpen &&
          setLeaveRequest((prevState) => {
            return {
              ...prevState,
              weekLabel: weekChoices[12].value,
            };
          });

        addTimesheetDrawerIsOpen &&
          setTimesheet((prevState) => {
            return {
              ...prevState,
              weekLabel: weekChoices[12].value,
            };
          });
      }

      setWeekOptions(weekChoices);
    }
    // eslint-disable-next-line
  }, [addLeaveRequestDrawerIsOpen, addTimesheetDrawerIsOpen, weekList.success]);

  // Filter approval responses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalResponsesAction({
            ...approvalResponseQuery,
            reviewedEnd: approvalResponseQuery.reviewedEnd
              ? getDjangoDate(approvalResponseQuery.reviewedEnd)
              : getDjangoDate(approvalResponseQueryDefault.reviewedEnd),
            reviewedStart: approvalResponseQuery.reviewedStart
              ? getDjangoDate(approvalResponseQuery.reviewedStart)
              : getDjangoDate(approvalResponseQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalResponseQuery]);

  // Filter bookings
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listBookingsAction({
            ...bookingQuery,
            endEnd: bookingQuery.endEnd
              ? getDjangoDate(bookingQuery.endEnd)
              : "",
            endStart: bookingQuery.endStart
              ? getDjangoDate(bookingQuery.endStart)
              : "",
            startEnd: bookingQuery.startEnd
              ? getDjangoDate(bookingQuery.startEnd)
              : "",
            startStart: bookingQuery.startStart
              ? getDjangoDate(bookingQuery.startStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingQuery]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listBookingCodesAction({
            ...bookingCodeQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter claims
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClaimsAction({
            ...claimQuery,
            completedEnd: claimQuery.completedEnd
              ? getDjangoDate(claimQuery.completedEnd)
              : getDjangoDate(claimQueryDefault.completedEnd),
            completedStart: claimQuery.completedStart
              ? getDjangoDate(claimQuery.completedStart)
              : getDjangoDate(claimQueryDefault.completedStart),
            submittedEnd: claimQuery.submittedEnd
              ? getDjangoDate(claimQuery.submittedEnd)
              : getDjangoDate(claimQueryDefault.submittedEnd),
            submittedStart: claimQuery.submittedStart
              ? getDjangoDate(claimQuery.submittedStart)
              : getDjangoDate(claimQueryDefault.submittedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [claimQuery]);

  // Filter engagements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementsAction({
            ...engagementQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementQuery]);

  // Filter expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpensesAction({
            ...expenseQuery,
            dateEnd: expenseQuery.dateEnd
              ? getDjangoDate(expenseQuery.dateEnd)
              : "",
            dateStart: expenseQuery.dateStart
              ? getDjangoDate(expenseQuery.dateStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseQuery]);

  // Filter expense categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listExpenseCategoriesAction({
            ...expenseCategoryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [expenseCategoryQuery]);

  // Filter leave categories
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listLeaveCategoriesAction({
            ...leaveCategoryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [leaveCategoryQuery]);

  // Filter leave requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listLeaveRequestsAction({
            ...leaveRequestQuery,
            completedEnd: leaveRequestQuery.completedEnd
              ? getDjangoDate(leaveRequestQuery.completedEnd)
              : getDjangoDate(leaveRequestQueryDefault.completedEnd),
            completedStart: leaveRequestQuery.completedStart
              ? getDjangoDate(leaveRequestQuery.completedStart)
              : getDjangoDate(leaveRequestQueryDefault.completedStart),
            submittedEnd: leaveRequestQuery.submittedEnd
              ? getDjangoDate(leaveRequestQuery.submittedEnd)
              : getDjangoDate(leaveRequestQueryDefault.submittedEnd),
            submittedStart: leaveRequestQuery.submittedStart
              ? getDjangoDate(leaveRequestQuery.submittedStart)
              : getDjangoDate(leaveRequestQueryDefault.submittedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [leaveRequestQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  const [decisionRight, setDecisionRight] = useState("");
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    if (decisionRight === "Staff expense") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_staff_expense &&
            manager.approval_role_data.can_approve_staff_expense_threshold >=
              claimDetails.claim.value
          : false
      );
    }

    if (decisionRight === "Staff leave") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_staff_leave &&
            manager.approval_role_data.can_approve_staff_leave_threshold >=
              leaveRequestDetails.leave_request.total_minutes
          : false
      );
    }

    if (decisionRight === "Staff timesheet") {
      managers = managerList.managers.filter((manager) =>
        manager.approval_role_data !== null
          ? manager.approval_role_data.can_approve_staff_timesheet &&
            manager.approval_role_data.can_approve_staff_timesheet_threshold >=
              timesheetDetails.timesheet.total_minutes
          : false
      );
    }

    setFilteredManagerList(managers);
  }, [
    claimDetails.claim.value,
    decisionRight,
    leaveRequestDetails.leave_request.total_minutes,
    managerList.managers,
    timesheetDetails.timesheet.total_minutes,
  ]);

  // Filter proposals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalsAction({
            ...proposalQuery,
            dueEnd: proposalQuery.dueEnd
              ? getDjangoDate(proposalQuery.dueEnd)
              : getDjangoDate(proposalQueryDefault.dueEnd),
            dueStart: proposalQuery.dueStart
              ? getDjangoDate(proposalQuery.dueStart)
              : getDjangoDate(proposalQueryDefault.dueStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuery]);

  // Filter staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffAction({
            ...staffQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffQuery]);

  // Filter staff development plans
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffDevelopmentPlansAction({
            ...staffDevelopmentPlanQuery,
            dueEnd: staffDevelopmentPlanQuery.dueEnd
              ? getDjangoDate(staffDevelopmentPlanQuery.dueEnd)
              : "",
            dueStart: staffDevelopmentPlanQuery.dueStart
              ? getDjangoDate(staffDevelopmentPlanQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffDevelopmentPlanQuery]);

  // Filter staff goals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffGoalsAction({
            ...staffGoalQuery,
            dueEnd: staffGoalQuery.dueEnd
              ? getDjangoDate(staffGoalQuery.dueEnd)
              : "",
            dueStart: staffGoalQuery.dueStart
              ? getDjangoDate(staffGoalQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffGoalQuery]);

  // Filter timesheets
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTimesheetsAction({
            ...timesheetQuery,
            completedEnd: timesheetQuery.completedEnd
              ? getDjangoDate(timesheetQuery.completedEnd)
              : getDjangoDate(timesheetQueryDefault.completedEnd),
            completedStart: timesheetQuery.completedStart
              ? getDjangoDate(timesheetQuery.completedStart)
              : getDjangoDate(timesheetQueryDefault.completedStart),
            submittedEnd: timesheetQuery.submittedEnd
              ? getDjangoDate(timesheetQuery.submittedEnd)
              : getDjangoDate(timesheetQueryDefault.submittedEnd),
            submittedStart: timesheetQuery.submittedStart
              ? getDjangoDate(timesheetQuery.submittedStart)
              : getDjangoDate(timesheetQueryDefault.submittedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [timesheetQuery]);

  // Filter timesheet entries
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTimesheetEntriesAction({
            ...timesheetEntryQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [timesheetEntryQuery]);

  // Filter to-dos
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTodosAction({
            ...todoQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [todoQuery]);

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // Form submission
  // Approval response
  // Update approval response
  const updateApprovalResponse = () => {
    // Prepare data
    const approval_response_data = new FormData();

    approvalResponse.comments
      ? approval_response_data.append("comments", approvalResponse.comments)
      : approval_response_data.append(
          "comments",
          approvalResponseDefault.comments
        );

    approvalResponse.outcome
      ? approval_response_data.append("outcome", approvalResponse.outcome)
      : approval_response_data.append(
          "outcome",
          approvalResponseDefault.outcome
        );

    approvalResponse.request
      ? approval_response_data.append("request", approvalResponse.request)
      : approval_response_data.append(
          "request",
          approvalResponseDefault.request
        );

    approvalResponse.status
      ? approval_response_data.append("status", approvalResponse.status)
      : approval_response_data.append("status", approvalResponseDefault.status);

    // Dispatch action
    dispatch(
      updateApprovalResponseAction(
        approvalResponseDetails.approval_response.id,
        approval_response_data
      )
    );
  };

  // Generate approval request decision helper text
  const generateApprovalRequestDecisionHelperText = () => {
    switch (
      approvalResponseDetails.approval_response.request_data.decision_right
    ) {
      case "Client account plan":
        return "Approve account plan";

      case "Client survey":
        return "Authorise survey used to collect feedback from client stakeholders";

      case "Engagement baseline":
        return "Approve engagement baseline";

      case "Engagement close":
        return "Authorise the team to close the engagement";

      case "Engagement deliverable":
        return "Authorise the team to submit engagement deliverable";

      case "Invoice":
        return "Approve invoice";

      case "Proposal commercials":
        return "Approve the proposal's legal terms and pricing";

      case "Proposal qualification":
        return "Decide whether to commit resources to the proposal";

      case "Proposal solution":
        return "Approve the proposal's services and team members";

      case "Proposal strategy":
        return "Approve the proposal's win themes";

      case "Proposal submission":
        return "Authorise the team to submit the proposal";

      case "Staff expense":
        return "Approve staff expense";

      case "Staff leave":
        return "Approve staff leave";

      case "Staff recruitment":
        return "Approve the hiring of staff";

      default:
        return "Approve staff timesheet";
    }
  };

  // Generate approval request facts
  const generateApprovalRequestFacts = () => {
    switch (
      approvalResponseDetails.approval_response.request_data.decision_right
    ) {
      case "Client account plan":
        return (
          <ClientAccountPlanFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Client survey":
        return <div>TBD</div>;

      case "Engagement baseline":
        return (
          <EngagementBaselineFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Engagement close":
        return (
          <EngagementCloseFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Engagement deliverable":
        return <div>TBD</div>;

      case "Invoice":
        return (
          <InvoiceFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Proposal commercials":
        return <div>TBD</div>;

      case "Proposal qualification":
        return (
          <ProposalQualificationFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Proposal solution":
        return <div>TBD</div>;

      case "Proposal strategy":
        return <div>TBD</div>;

      case "Proposal submission":
        return (
          <ProposalSubmissionFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Staff expense":
        return (
          <StaffExpenseFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Staff leave":
        return (
          <StaffLeaveRequestFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      case "Staff recruitment":
        return (
          <StaffRecruitmentFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );

      default:
        return (
          <StaffTimesheetFacts
            facts={approvalResponseDetails.approval_response.request_data.facts}
          />
        );
    }
  };

  // Generate approval request value helper text
  const generateApprovalRequestValueHelperText = () => {
    switch (
      approvalResponseDetails.approval_response.request_data.decision_right
    ) {
      case "Client account plan":
        return "Planned annual revenue from the client";

      case "Client survey":
        return "Planned annual revenue from the client";

      case "Engagement baseline":
        return "Value of engagement";

      case "Engagement close":
        return "Value of engagement";

      case "Engagement deliverable":
        return "Value of engagement";

      case "Invoice":
        return "Value of invoice";

      case "Proposal commercials":
        return "Estimated value of proposal";

      case "Proposal qualification":
        return "Estimated value of proposal";

      case "Proposal solution":
        return "Estimated value of proposal";

      case "Proposal strategy":
        return "Estimated value of proposal";

      case "Proposal submission":
        return "Estimated value of proposal";

      case "Staff expense":
        return "Value of expense";

      case "Staff leave":
        return "Time off requested";

      case "Staff recruitment":
        return "Total annual remuneration package";

      default:
        return "Time booked";
    }
  };

  // Claim
  // Add claim
  const addClaim = () => {
    // Prepare data
    const claim_data = new FormData();

    claim.name
      ? claim_data.append("name", claim.name)
      : claim_data.append("name", claimDefault.name);

    claim_data.append("organisation", activeOrganisation.id);

    claim_data.append("staff", claimDefault.staff);

    // Dispatch action
    dispatch(createClaimAction(claim_data));
  };

  // Delete claim
  const deleteClaim = () => {
    // Dispatch action
    dispatch(deleteClaimAction(claimDetails.claim.id));
  };

  // Duplicate claim
  useEffect(() => {
    if (claimDuplicate.success) {
      dispatch(listClaimsAction({ ...claimQuery }));

      dispatch(listClaimDetailsAction(claimDuplicate.claim));

      setExpenseQuery({
        claim: claimDuplicate.claim,
        organisation: activeOrganisation.id,
        page: 1,
      });

      dispatch(resetDuplicateClaimAction());

      setUpdateClaimDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [claimDuplicate.success]);

  // Update claim
  useEffect(() => {
    if (claimCreate.success) {
      dispatch(listClaimDetailsAction(claimCreate.claim.id));

      setExpenseQuery({
        claim: claimCreate.claim.id,
        organisation: activeOrganisation.id,
      });

      dispatch(resetCreateClaimAction());

      setUpdateClaimDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [claimCreate.success]);

  useEffect(() => {
    if (updateClaimDrawerIsOpen) {
      setDecisionRight("Staff expense");
    }
    // eslint-disable-next-line
  }, [updateClaimDrawerIsOpen]);

  const updateClaim = () => {
    // Prepare approval request data
    const approval_request_data = new FormData();

    if (claimApprovalRequest.submit) {
      approval_request_data.append("approver", claimApprovalRequest.approver);

      approval_request_data.append("comments", claim.comments);

      approval_request_data.append("decision_right", "Staff expense");

      approval_request_data.append("item_id", claimDetails.claim.id);

      approval_request_data.append("organisation", activeOrganisation.id);

      approval_request_data.append("status", "Submitted");
    }

    // Prepare claim data
    const claim_data = new FormData();

    claim.comments
      ? claim_data.append("comments", claim.comments)
      : claim_data.append("comments", claimDefault.comments);

    claim.name
      ? claim_data.append("name", claim.name)
      : claim_data.append("name", claimDefault.name);

    if (claim.receipts && claim.receipts !== claimDetails.claim.receipts) {
      claim_data.append("receipts", claim.receipts);
    } else if (claim.receipts === "") {
      claim_data.append("receipts", claimDefault.receipts);
    }

    claim.value
      ? claim_data.append("value", claim.value)
      : claim_data.append("value", claimDefault.value);

    // Dispatch action
    dispatch(
      updateClaimAction(
        claimDetails.claim.id,
        approval_request_data,
        claim_data
      )
    );
  };

  // Expense
  const [editExpenseId, setEditExpenseId] = useState(null);
  const [showNewExpenseForm, setShowNewExpenseForm] = useState(false);

  // Add expense
  const addExpense = () => {
    // Prepare data
    const expense_data = new FormData();

    expense.bookingCode
      ? expense_data.append("booking_code", expense.bookingCode)
      : expense_data.append("booking_code", expenseDefault.bookingCode);

    expense.category
      ? expense_data.append("category", expense.category)
      : expense_data.append("category", expenseDefault.category);

    expense_data.append("claim", claimDetails.claim.id);

    expense.comments
      ? expense_data.append("comments", expense.comments)
      : expense_data.append("comments", expenseDefault.comments);

    expense.date
      ? expense_data.append("date", getDjangoDate(expense.date))
      : expense_data.append("date", getDjangoDate(expenseDefault.date));

    expense.value
      ? expense_data.append("value", expense.value)
      : expense_data.append("value", expenseDefault.value);

    // Dispatch action
    dispatch(createExpenseAction(expense_data));
  };

  // Delete expense
  const deleteExpense = () => {
    // Dispatch action
    dispatch(deleteExpenseAction(expenseDetails.expense.id));
  };

  // Update expense
  const updateExpense = () => {
    // Prepare data
    const expense_data = new FormData();

    expense.bookingCode
      ? expense_data.append("booking_code", expense.bookingCode)
      : expense_data.append("booking_code", expenseDefault.bookingCode);

    expense.category
      ? expense_data.append("category", expense.category)
      : expense_data.append("category", expenseDefault.category);

    expense.comments
      ? expense_data.append("comments", expense.comments)
      : expense_data.append("comments", expenseDefault.comments);

    expense.date
      ? expense_data.append("date", getDjangoDate(expense.date))
      : expense_data.append("date", getDjangoDate(expenseDefault.date));

    expense.value
      ? expense_data.append("value", expense.value)
      : expense_data.append("value", expenseDefault.value);

    // Dispatch action
    dispatch(updateExpenseAction(expenseDetails.expense.id, expense_data));
  };

  useEffect(() => {
    if (
      expenseCreate.success ||
      expenseDelete.success ||
      expenseUpdate.success
    ) {
      dispatch(listClaimsAction({ ...claimQuery }));
    }
    // eslint-disable-next-line
  }, [expenseCreate.success, expenseDelete.success, expenseUpdate.success]);

  // Leave request
  // Add leave request
  const addLeaveRequest = () => {
    // Prepare data
    const leave_request_data = new FormData();

    leave_request_data.append("organisation", activeOrganisation.id);

    leave_request_data.append("staff", claimDefault.staff);

    leaveRequest.weekLabel
      ? leave_request_data.append("week_label", leaveRequest.weekLabel)
      : leave_request_data.append("week_label", leaveRequestDefault.weekLabel);

    // Dispatch action
    dispatch(createLeaveRequestAction(leave_request_data));
  };

  // Cancel leave request
  useEffect(() => {
    if (leaveRequestCancel.success) {
      dispatch(listLeaveRequestsAction({ ...leaveRequestQuery }));

      dispatch(resetCancelLeaveRequestAction());

      setUpdateLeaveRequestDrawerIsOpen(false);
    }
    // eslint-disable-next-line
  }, [leaveRequestCancel.success]);

  // Delete leave request
  const deleteLeaveRequest = () => {
    // Dispatch action
    dispatch(deleteLeaveRequestAction(leaveRequestDetails.leave_request.id));
  };

  // Update leave request
  useEffect(() => {
    if (leaveRequestCreate.success) {
      dispatch(
        listLeaveRequestDetailsAction(leaveRequestCreate.leave_request.id)
      );

      dispatch(resetCreateLeaveRequestAction());

      setUpdateLeaveRequestDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [leaveRequestCreate.success]);

  useEffect(() => {
    if (updateLeaveRequestDrawerIsOpen) {
      setDecisionRight("Staff leave");
    }
    // eslint-disable-next-line
  }, [updateLeaveRequestDrawerIsOpen]);

  const updateLeaveRequest = () => {
    // Prepare approval request data
    const approval_request_data = new FormData();

    if (leaveRequestApprovalRequest.submit) {
      approval_request_data.append(
        "approver",
        leaveRequestApprovalRequest.approver
      );

      approval_request_data.append("comments", leaveRequest.comments);

      approval_request_data.append("decision_right", "Staff leave");

      approval_request_data.append(
        "item_id",
        leaveRequestDetails.leave_request.id
      );

      approval_request_data.append("organisation", activeOrganisation.id);

      approval_request_data.append("status", "Submitted");
    }

    // Prepare leave request data
    const leave_request_data = new FormData();

    leaveRequest.category
      ? leave_request_data.append("category", leaveRequest.category)
      : leave_request_data.append("category", leaveRequestDefault.category);

    leaveRequest.comments
      ? leave_request_data.append("comments", leaveRequest.comments)
      : leave_request_data.append("comments", leaveRequestDefault.comments);

    leaveRequest.minutesFri
      ? leave_request_data.append("minutes_fri", leaveRequest.minutesFri)
      : leave_request_data.append(
          "minutes_fri",
          leaveRequestDefault.minutesFri
        );

    leaveRequest.minutesMon
      ? leave_request_data.append("minutes_mon", leaveRequest.minutesMon)
      : leave_request_data.append(
          "minutes_mon",
          leaveRequestDefault.minutesMon
        );

    leaveRequest.minutesSat
      ? leave_request_data.append("minutes_sat", leaveRequest.minutesSat)
      : leave_request_data.append(
          "minutes_sat",
          leaveRequestDefault.minutesSat
        );

    leaveRequest.minutesSun
      ? leave_request_data.append("minutes_sun", leaveRequest.minutesSun)
      : leave_request_data.append(
          "minutes_sun",
          leaveRequestDefault.minutesSun
        );

    leaveRequest.minutesTue
      ? leave_request_data.append("minutes_tue", leaveRequest.minutesTue)
      : leave_request_data.append(
          "minutes_tue",
          leaveRequestDefault.minutesTue
        );

    leaveRequest.minutesThu
      ? leave_request_data.append("minutes_thu", leaveRequest.minutesThu)
      : leave_request_data.append(
          "minutes_thu",
          leaveRequestDefault.minutesThu
        );

    leaveRequest.minutesWed
      ? leave_request_data.append("minutes_wed", leaveRequest.minutesWed)
      : leave_request_data.append(
          "minutes_wed",
          leaveRequestDefault.minutesWed
        );

    leave_request_data.append("organisation", activeOrganisation.id);

    leaveRequest.weekLabel
      ? leave_request_data.append("week_label", leaveRequest.weekLabel)
      : leave_request_data.append("week_label", leaveRequestDefault.weekLabel);

    // Dispatch action
    dispatch(
      updateLeaveRequestAction(
        leaveRequestDetails.leave_request.id,
        approval_request_data,
        leave_request_data
      )
    );
  };

  // Staff development plan
  // Update development plan
  const updateDevelopmentPlan = () => {
    // Prepare data
    const staff_development_plan = new FormData();

    staffDevelopmentPlan.staffComments
      ? staff_development_plan.append(
          "staff_comments",
          staffDevelopmentPlan.staffComments
        )
      : staff_development_plan.append(
          "staff_comments",
          staffDevelopmentPlanDefault.staffComments
        );

    // Dispatch action
    dispatch(
      updateStaffDevelopmentPlanAction(
        staffDevelopmentPlanDetails.staff_development_plan.id,
        staff_development_plan
      )
    );
  };

  // Staff goal
  // Update goal
  const updateGoal = () => {
    // Prepare data
    const staff_goal = new FormData();

    staffGoal.staffComments
      ? staff_goal.append("staff_comments", staffGoal.staffComments)
      : staff_goal.append("staff_comments", staffGoalDefault.comments);

    // Dispatch action
    dispatch(updateStaffGoalAction(staffGoalDetails.staff_goal.id, staff_goal));
  };

  // Timesheet
  // Add timesheet
  const addTimesheet = () => {
    // Prepare data
    const timesheet_data = new FormData();

    timesheet_data.append("organisation", activeOrganisation.id);

    timesheet_data.append("staff", timesheetDefault.staff);

    timesheet.weekLabel
      ? timesheet_data.append("week_label", timesheet.weekLabel)
      : timesheet_data.append("week_label", timesheetDefault.weekLabel);

    // Dispatch action
    dispatch(createTimesheetAction(timesheet_data));
  };

  // Delete timesheet
  const deleteTimesheet = () => {
    // Dispatch action
    dispatch(deleteTimesheetAction(timesheetDetails.timesheet.id));
  };

  // Duplicate timesheet
  useEffect(() => {
    if (timesheetDuplicate.success) {
      dispatch(listTimesheetsAction({ ...timesheetQuery }));

      dispatch(listTimesheetDetailsAction(timesheetDuplicate.timesheet));

      setTimesheetEntryQuery({
        page: 1,
        timesheet: timesheetDuplicate.timesheet,
      });

      dispatch(resetDuplicateTimesheetAction());

      setUpdateTimesheetDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [timesheetDuplicate.success]);

  // Update timesheet
  useEffect(() => {
    if (timesheetCreate.success) {
      dispatch(listTimesheetDetailsAction(timesheetCreate.timesheet.id));

      setTimesheetEntryQuery({
        timesheet: timesheetCreate.timesheet.id,
      });

      dispatch(resetCreateTimesheetAction());

      setUpdateTimesheetDrawerIsOpen(true);
    }
    // eslint-disable-next-line
  }, [timesheetCreate.success]);

  useEffect(() => {
    if (updateTimesheetDrawerIsOpen) {
      setDecisionRight("Staff timesheet");
    }
    // eslint-disable-next-line
  }, [updateTimesheetDrawerIsOpen]);

  const updateTimesheet = () => {
    // Prepare approval request data
    const approval_request_data = new FormData();

    if (timesheetApprovalRequest.submit) {
      approval_request_data.append(
        "approver",
        timesheetApprovalRequest.approver
      );

      approval_request_data.append("comments", timesheet.comments);

      approval_request_data.append("decision_right", "Staff timesheet");

      approval_request_data.append("item_id", timesheetDetails.timesheet.id);

      approval_request_data.append("organisation", activeOrganisation.id);

      approval_request_data.append("status", "Submitted");
    }

    // Prepare timesheet data
    const timesheet_data = new FormData();

    timesheet.comments
      ? timesheet_data.append("comments", timesheet.comments)
      : timesheet_data.append("comments", timesheetDefault.comments);

    timesheet.weekLabel
      ? timesheet_data.append("week_label", timesheet.weekLabel)
      : timesheet_data.append("week_label", timesheetDefault.weekLabel);

    // Dispatch action
    dispatch(
      updateTimesheetAction(
        timesheetDetails.timesheet.id,
        approval_request_data,
        timesheet_data
      )
    );
  };

  // Timesheet entry
  const [editTimesheetEntryId, setEditTimesheetEntryId] = useState(null);
  const [showNewTimesheetEntryForm, setShowNewTimesheetEntryForm] =
    useState(false);

  // Add timesheet entry
  const addTimesheetEntry = () => {
    // Prepare data
    const timesheet_entry = new FormData();

    timesheetEntry.bookingCode
      ? timesheet_entry.append("booking_code", timesheetEntry.bookingCode)
      : timesheet_entry.append(
          "booking_code",
          timesheetEntryDefault.bookingCode
        );

    timesheetEntry.comments
      ? timesheet_entry.append("comments", timesheetEntry.comments)
      : timesheet_entry.append("comments", timesheetEntryDefault.comments);

    timesheetEntry.engagementLabourExpense
      ? timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntry.engagementLabourExpense
        )
      : timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntryDefault.engagementLabourExpense
        );

    timesheetEntry.minutesFri
      ? timesheet_entry.append("minutes_fri", timesheetEntry.minutesFri)
      : timesheet_entry.append("minutes_fri", timesheetEntryDefault.minutesFri);

    timesheetEntry.minutesMon
      ? timesheet_entry.append("minutes_mon", timesheetEntry.minutesMon)
      : timesheet_entry.append("minutes_mon", timesheetEntryDefault.minutesMon);

    timesheetEntry.minutesSat
      ? timesheet_entry.append("minutes_sat", timesheetEntry.minutesSat)
      : timesheet_entry.append("minutes_sat", timesheetEntryDefault.minutesSat);

    timesheetEntry.minutesSun
      ? timesheet_entry.append("minutes_sun", timesheetEntry.minutesSun)
      : timesheet_entry.append("minutes_sun", timesheetEntryDefault.minutesSun);

    timesheetEntry.minutesTue
      ? timesheet_entry.append("minutes_tue", timesheetEntry.minutesTue)
      : timesheet_entry.append("minutes_tue", timesheetEntryDefault.minutesTue);

    timesheetEntry.minutesThu
      ? timesheet_entry.append("minutes_thu", timesheetEntry.minutesThu)
      : timesheet_entry.append("minutes_thu", timesheetEntryDefault.minutesThu);

    timesheetEntry.minutesWed
      ? timesheet_entry.append("minutes_wed", timesheetEntry.minutesWed)
      : timesheet_entry.append("minutes_wed", timesheetEntryDefault.minutesWed);

    timesheetEntry.task
      ? timesheet_entry.append("task", timesheetEntry.task)
      : timesheet_entry.append("task", timesheetEntryDefault.task);

    timesheet_entry.append("timesheet", timesheetDetails.timesheet.id);

    // Dispatch action
    dispatch(createTimesheetEntryAction(timesheet_entry));
  };

  // Delete timesheet entry
  const deleteTimesheetEntry = () => {
    // Dispatch action
    dispatch(
      deleteTimesheetEntryAction(timesheetEntryDetails.timesheet_entry.id)
    );
  };

  // Update timesheet entry
  const updateTimesheetEntry = () => {
    // Prepare data
    const timesheet_entry = new FormData();

    timesheetEntry.bookingCode
      ? timesheet_entry.append("booking_code", timesheetEntry.bookingCode)
      : timesheet_entry.append(
          "booking_code",
          timesheetEntryDefault.bookingCode
        );

    timesheetEntry.comments
      ? timesheet_entry.append("comments", timesheetEntry.comments)
      : timesheet_entry.append("comments", timesheetEntryDefault.comments);

    timesheetEntry.engagementLabourExpense
      ? timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntry.engagementLabourExpense
        )
      : timesheet_entry.append(
          "engagement_labour_expense",
          timesheetEntryDefault.engagementLabourExpense
        );

    timesheetEntry.minutesFri
      ? timesheet_entry.append("minutes_fri", timesheetEntry.minutesFri)
      : timesheet_entry.append("minutes_fri", timesheetEntryDefault.minutesFri);

    timesheetEntry.minutesMon
      ? timesheet_entry.append("minutes_mon", timesheetEntry.minutesMon)
      : timesheet_entry.append("minutes_mon", timesheetEntryDefault.minutesMon);

    timesheetEntry.minutesSat
      ? timesheet_entry.append("minutes_sat", timesheetEntry.minutesSat)
      : timesheet_entry.append("minutes_sat", timesheetEntryDefault.minutesSat);

    timesheetEntry.minutesSun
      ? timesheet_entry.append("minutes_sun", timesheetEntry.minutesSun)
      : timesheet_entry.append("minutes_sun", timesheetEntryDefault.minutesSun);

    timesheetEntry.minutesTue
      ? timesheet_entry.append("minutes_tue", timesheetEntry.minutesTue)
      : timesheet_entry.append("minutes_tue", timesheetEntryDefault.minutesTue);

    timesheetEntry.minutesThu
      ? timesheet_entry.append("minutes_thu", timesheetEntry.minutesThu)
      : timesheet_entry.append("minutes_thu", timesheetEntryDefault.minutesThu);

    timesheetEntry.minutesWed
      ? timesheet_entry.append("minutes_wed", timesheetEntry.minutesWed)
      : timesheet_entry.append("minutes_wed", timesheetEntryDefault.minutesWed);

    timesheetEntry.task
      ? timesheet_entry.append("task", timesheetEntry.task)
      : timesheet_entry.append("task", timesheetEntryDefault.task);

    // Dispatch action
    dispatch(
      updateTimesheetEntryAction(
        timesheetEntryDetails.timesheet_entry.id,
        timesheet_entry
      )
    );
  };

  useEffect(() => {
    if (
      timesheetEntryCreate.success ||
      timesheetEntryDelete.success ||
      timesheetEntryUpdate.success
    ) {
      dispatch(listTimesheetsAction({ ...timesheetQuery }));
    }
    // eslint-disable-next-line
  }, [
    timesheetEntryCreate.success,
    timesheetEntryDelete.success,
    timesheetEntryUpdate.success,
  ]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Existing goal form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <TabSectionField
              label="Title"
              value={staffGoalDetails.staff_goal.name}
              vertical
            />

            <TabSectionField
              label="Due date"
              value={<DateValue date={staffGoalDetails.staff_goal.due} />}
              vertical
            />

            <TabSectionField
              label="Goal"
              value={staffGoalDetails.staff_goal.goal}
              vertical
            />

            <TabSectionField
              label="Rating"
              value={
                <Tag
                  intent={
                    staffGoalDetails.staff_goal.rating === "Exceeded"
                      ? "success"
                      : staffGoalDetails.staff_goal.rating === "Met"
                      ? "warning"
                      : staffGoalDetails.staff_goal.rating === "Not met"
                      ? "danger"
                      : "primary"
                  }
                  large
                  minimal
                  style={{
                    minWidth: "100px",
                    textAlign: "center",
                  }}
                >
                  {staffGoalDetails.staff_goal.rating}
                </Tag>
              }
              vertical
            />

            <TabSectionField
              label="Comments"
              value={
                staffGoalDetails.staff_goal.comments
                  ? staffGoalDetails.staff_goal.comments
                  : "-"
              }
              vertical
            />

            <TabSectionField
              bottom
              label="Progress"
              value={
                <FormDrawerTextAreaField3
                  error={staffGoalUpdate.error}
                  fieldName="staff_comments"
                  placeholder="Describe your progress toward the goal"
                  setValueObject={setStaffGoal}
                  setValueVariable="staffComments"
                  value={staffGoal.staffComments}
                />
              }
              vertical
            />
          </Card>
        }
        error={staffGoalUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update goal progress"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Managing your goals"
        icon="edit"
        isOpen={updateGoalDrawerIsOpen}
        loading={staffGoalDetails.loading}
        onSave={updateGoal}
        refreshList={() => {
          dispatch(listStaffGoalsAction({ ...staffGoalQuery }));
        }}
        resetAction={() => {
          dispatch(resetUpdateStaffGoalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffGoalUpdate.success}
        saveSuccessMessage="Goal updated."
        saving={staffGoalUpdate.loading}
        setIsOpen={setUpdateGoalDrawerIsOpen}
        title="Update goal progress"
      />

      {/* Existing development plan form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ width: "100%" }}>
              <TabSectionField
                label="Title"
                value={staffDevelopmentPlanDetails.staff_development_plan.name}
                vertical
              />

              <TabSectionField
                label="Due date"
                value={
                  <DateValue
                    date={
                      staffDevelopmentPlanDetails.staff_development_plan.due
                    }
                  />
                }
                vertical
              />

              <TabSectionField
                label="Development plan"
                value={
                  staffDevelopmentPlanDetails.staff_development_plan
                    .development_plan
                }
                vertical
              />

              <TabSectionField
                label="Status"
                value={
                  <Tag
                    intent={
                      staffDevelopmentPlanDetails.staff_development_plan
                        .status === "Completed"
                        ? "success"
                        : staffDevelopmentPlanDetails.staff_development_plan
                            .status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    large
                    minimal
                    style={{
                      minWidth: "100px",
                      textAlign: "center",
                    }}
                  >
                    {staffDevelopmentPlanDetails.staff_development_plan.status}
                  </Tag>
                }
                vertical
              />

              <TabSectionField
                label="Comments"
                value={
                  staffDevelopmentPlanDetails.staff_development_plan.comments
                    ? staffDevelopmentPlanDetails.staff_development_plan
                        .comments
                    : "-"
                }
                vertical
              />

              <TabSectionField
                bottom
                label="Progress"
                value={
                  <FormDrawerTextAreaField3
                    error={staffGoalUpdate.error}
                    fieldName="staff_comments"
                    placeholder="Describe your progress toward the goal"
                    setValueObject={setStaffDevelopmentPlan}
                    setValueVariable="staffComments"
                    value={staffDevelopmentPlan.staffComments}
                  />
                }
                vertical
              />
            </Card>
          </div>
        }
        error={staffDevelopmentPlanUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update development plan status"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Developing your skills"
        icon="edit"
        isOpen={updateDevelopmentPlanDrawerIsOpen}
        loading={staffDevelopmentPlanDetails.loading}
        onSave={updateDevelopmentPlan}
        refreshList={() => {
          dispatch(
            listStaffDevelopmentPlansAction({ ...staffDevelopmentPlanQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetUpdateStaffDevelopmentPlanAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={staffDevelopmentPlanUpdate.success}
        saveSuccessMessage="Development plan updated."
        saving={staffDevelopmentPlanUpdate.loading}
        setIsOpen={setUpdateDevelopmentPlanDrawerIsOpen}
        title="Update development plan status"
      />

      {/* New timesheet form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Select the week</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Week */}
            <div style={{ margin: "0 0 15px 0" }}>
              <div style={{ marginBottom: "5px" }}>Week</div>

              <SelectField
                options={weekOptions}
                setValueObject={setTimesheet}
                setValueVariable="weekLabel"
                value={timesheet.weekLabel}
              />
            </div>
          </Card>
        }
        error={timesheetCreate.error}
        errorMessage="We couldn't add the timesheet. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Create timesheet"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a timesheet"
        icon="edit"
        isOpen={addTimesheetDrawerIsOpen}
        onSave={addTimesheet}
        refreshList={() => {
          dispatch(
            listTimesheetsAction({
              organisation: activeOrganisation.id,
              user: userLogin.user,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={timesheetCreate.success}
        saveSuccessMessage="Timesheet created."
        saving={timesheetCreate.loading}
        setIsOpen={setAddTimesheetDrawerIsOpen}
        size="small"
        title="Create timesheet"
      />

      {/* Existing timesheet form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the timesheet details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {timesheetDetails.timesheet.status === "Reversed" && (
              <Callout intent="warning" style={{ margin: "0 0 20px 0" }}>
                This timesheet was reversed by{" "}
                <strong>{timesheetDetails.timesheet.reversed_by}</strong> on{" "}
                {new Date(
                  timesheetDetails.timesheet.reversed_on
                ).toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
                {timesheetDetails.timesheet.reversed_comments
                  ? ", with the following comments: " +
                    timesheetDetails.timesheet.reversed_comments
                  : "."}
              </Callout>
            )}

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Week */}
                    <div style={{ margin: "0 0 15px 0" }}>
                      <div style={{ marginBottom: "5px" }}>Week</div>

                      <SelectField
                        disabled={timesheetDetails.timesheet.status !== "Draft"}
                        options={weekOptions}
                        setValueObject={setTimesheet}
                        setValueVariable="weekLabel"
                        value={timesheet.weekLabel}
                      />
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Status */}
                    <div style={{ marginBottom: "5px" }}>Status</div>

                    <Tag
                      intent={
                        timesheetDetails.timesheet.status === "Approved"
                          ? "success"
                          : timesheetDetails.timesheet.status === "Draft"
                          ? "primary"
                          : timesheetDetails.timesheet.status === "Rejected"
                          ? "danger"
                          : "warning"
                      }
                      large
                      style={{
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      {timesheetDetails.timesheet.status}
                    </Tag>
                  </div>
                </div>

                {/* Comments */}
                <FormDrawerTextAreaField3
                  disabled={timesheetDetails.timesheet.status !== "Draft"}
                  error={timesheetUpdate.error}
                  fieldName="comments"
                  height="100px"
                  label="Comments"
                  noRewrite={timesheetDetails.timesheet.status !== "Draft"}
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setTimesheet}
                  setValueVariable="comments"
                  value={timesheet.comments}
                />
              </div>

              {timesheetDetails.timesheet.status === "Draft" ? (
                <Card style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve timesheets"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setTimesheetApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={timesheetApprovalRequest.approverName}
                  />

                  {/* Submit */}
                  <FormDrawerButtonField
                    fieldName="status"
                    helperText="Do not submit to keep the timesheet in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: false,
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: true,
                      },
                    ]}
                    setValueObject={setTimesheetApprovalRequest}
                    setValueVariable="submit"
                    value={timesheetApprovalRequest.submit}
                  />
                </Card>
              ) : (
                <Card style={{ marginLeft: "10px", width: "40%" }}>
                  <p>
                    <strong>Reviewer</strong>
                  </p>

                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <NameValue
                      email={
                        timesheetDetails.timesheet.approval_data
                          ? timesheetDetails.timesheet.approval_data
                              .approver_name
                          : undefined
                      }
                      image={
                        timesheetDetails.timesheet.approval_data
                          ? timesheetDetails.timesheet.approval_data
                              .approver_profile_photo
                          : undefined
                      }
                    />
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Submitted</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue
                          date={timesheetDetails.timesheet.submitted}
                        />
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Reviewed</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue
                          date={timesheetDetails.timesheet.completed}
                        />
                      </div>
                    </div>
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Comments</strong>
                  </p>

                  <p>
                    {timesheetDetails.timesheet.approval_data ? (
                      timesheetDetails.timesheet.approval_data.comments ? (
                        timesheetDetails.timesheet.approval_data.comments
                      ) : (
                        <span className={Classes.TEXT_MUTED}>No comments.</span>
                      )
                    ) : undefined}
                  </p>
                </Card>
              )}
            </div>

            {/* Timesheet entries */}
            <div style={{ margin: "20px 0 20px 0" }}>
              {/* Error alerts */}
              {timesheetEntryCreate.error && (
                <ErrorList
                  error={timesheetEntryCreate.error}
                  message="You cannot book time to this code."
                />
              )}

              {timesheetEntryDelete.error && (
                <ErrorList
                  error={timesheetEntryDelete.error}
                  message="Unable to delete the timesheet entry."
                />
              )}

              {timesheetEntryUpdate.error && (
                <ErrorList
                  error={timesheetEntryUpdate.error}
                  message="You cannot book time to this code."
                />
              )}

              {/* Data table */}
              <EditableTableContainer
                activeRecord={editTimesheetEntryId}
                body={
                  <>
                    {timesheetEntryList.timesheet_entries.map((entry) => {
                      return (
                        <tr
                          key={entry.id}
                          onClick={
                            editTimesheetEntryId ||
                            showNewTimesheetEntryForm ||
                            timesheetDetails.timesheet.status !== "Draft"
                              ? undefined
                              : () => {
                                  dispatch(
                                    listTimesheetEntryDetailsAction(entry.id)
                                  );

                                  setEditTimesheetEntryId(entry.id);
                                }
                          }
                        >
                          {/* Booking code */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editTimesheetEntryId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerSelectField2
                                  error={timesheetEntryUpdate.error}
                                  fieldName="booking_code"
                                  items={bookingCodeList.booking_codes.filter(
                                    (booking_code) =>
                                      !booking_code.engagement ||
                                      booking_code.engagement_roles_data.filter(
                                        (engagement_role) =>
                                          engagement_role.user ===
                                          userLogin.user
                                      ).length > 0
                                  )}
                                  loading={bookingCodeList.loading}
                                  placeholder="Select a booking code"
                                  query={bookingCodeQuery.searchString}
                                  setQueryObject={setBookingCodeQuery}
                                  setQueryVariable="searchString"
                                  setValueObject={setTimesheetEntry}
                                  setValueIdVariable="bookingCode"
                                  setValueNameVariable="bookingCodeName"
                                  value={timesheetEntry.bookingCodeName}
                                />
                              </div>
                            ) : (
                              <Text ellipsize>
                                {entry.booking_code_data.name}
                              </Text>
                            )}
                          </td>

                          {/* Task */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editTimesheetEntryId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormGroup
                                  subLabel={
                                    timesheetEntryUpdate.error ? (
                                      timesheetEntryUpdate.error.response.data[
                                        "task"
                                      ] ? (
                                        <span style={{ color: Colors.RED3 }}>
                                          {
                                            timesheetEntryUpdate.error.response
                                              .data["task"]
                                          }
                                        </span>
                                      ) : undefined
                                    ) : undefined
                                  }
                                >
                                  <SelectField
                                    options={taskOptions}
                                    setValueObject={setTimesheetEntry}
                                    setValueVariable="task"
                                    value={timesheetEntry.task}
                                  />
                                </FormGroup>
                              </div>
                            ) : (
                              <Text ellipsize>
                                {entry.task ? entry.task : "-"}
                              </Text>
                            )}
                          </td>

                          {/* Engagement role */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editTimesheetEntryId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormGroup
                                  subLabel={
                                    timesheetEntryUpdate.error ? (
                                      timesheetEntryUpdate.error.response.data[
                                        "engagement_labour_expense"
                                      ] ? (
                                        <span style={{ color: Colors.RED3 }}>
                                          {
                                            timesheetEntryUpdate.error.response
                                              .data["engagement_labour_expense"]
                                          }
                                        </span>
                                      ) : undefined
                                    ) : undefined
                                  }
                                >
                                  <SelectField
                                    options={
                                      engagementRoleOptions.length > 0
                                        ? engagementRoleOptions
                                        : [
                                            {
                                              id: 0,
                                              label: "None",
                                              value: "",
                                            },
                                          ]
                                    }
                                    setValueObject={setTimesheetEntry}
                                    setValueVariable="engagementLabourExpense"
                                    value={
                                      timesheetEntry.engagementLabourExpense
                                    }
                                  />
                                </FormGroup>
                              </div>
                            ) : (
                              <Text ellipsize>
                                {entry.engagement_role_name
                                  ? entry.engagement_role_name
                                  : "-"}
                              </Text>
                            )}
                          </td>

                          {/* Mon */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesMon: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesMon
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesMon
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_mon
                                )}
                              </div>
                            )}
                          </td>

                          {/* Tue */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesTue: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesTue
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesTue
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_tue
                                )}
                              </div>
                            )}
                          </td>

                          {/* Wed */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesWed: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesWed
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesWed
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_wed
                                )}
                              </div>
                            )}
                          </td>

                          {/* Thu */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesThu: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesThu
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesThu
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_thu
                                )}
                              </div>
                            )}
                          </td>

                          {/* Fri */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesFri: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesFri
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesFri
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_fri
                                )}
                              </div>
                            )}
                          </td>

                          {/* Sat */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesSat: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesSat
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesSat
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_sat
                                )}
                              </div>
                            )}
                          </td>

                          {/* Sun */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            {editTimesheetEntryId === entry.id ? (
                              <TimePicker
                                onChange={(newTime) => {
                                  const minutes =
                                    newTime.getHours() * 60 +
                                    newTime.getMinutes();

                                  setTimesheetEntry((prevState) => {
                                    return {
                                      ...prevState,
                                      minutesSun: minutes,
                                    };
                                  });
                                }}
                                value={
                                  new Date(
                                    0,
                                    0,
                                    0,
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesSun
                                    ).split(":")[0],
                                    convertMinutesToHoursAndMinutes(
                                      timesheetEntry.minutesSun
                                    ).split(":")[1]
                                  )
                                }
                              />
                            ) : (
                              <div>
                                {convertMinutesToHoursAndMinutes(
                                  entry.minutes_sun
                                )}
                              </div>
                            )}
                          </td>

                          {/* Total */}
                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            <strong>
                              {editTimesheetEntryId === entry.id ? (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    timesheetEntry.minutesFri +
                                      timesheetEntry.minutesMon +
                                      timesheetEntry.minutesSat +
                                      timesheetEntry.minutesSun +
                                      timesheetEntry.minutesThu +
                                      timesheetEntry.minutesTue +
                                      timesheetEntry.minutesWed
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {convertMinutesToHoursAndMinutes(
                                    entry.total_minutes
                                  )}
                                </div>
                              )}
                            </strong>
                          </td>

                          {/* Comments */}
                          <td style={{ verticalAlign: "middle" }}>
                            {editTimesheetEntryId === entry.id ? (
                              <div style={{ margin: "0 0 -15px 0" }}>
                                <FormDrawerTextField2
                                  error={timesheetEntryUpdate.error}
                                  fieldName="comments"
                                  placeholder="Enter comments"
                                  setValueObject={setTimesheetEntry}
                                  setValueVariable="comments"
                                  value={timesheetEntry.comments}
                                />
                              </div>
                            ) : (
                              <TableText text={entry.comments} />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                }
                //bordered={false}
                count={timesheetEntryList.count}
                createSuccess={timesheetEntryCreate.success}
                createSuccessMessage="Timesheet entry created"
                deleteSuccess={timesheetEntryDelete.success}
                deleteSuccessMessage="Timesheet entry deleted."
                footer={
                  <tr>
                    <td colSpan="3" style={{ textAlign: "end" }}>
                      Total
                    </td>

                    {/* Mon */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesMon -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_mon +
                              timesheetEntry.minutesMon
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesMon +
                              timesheetEntry.minutesMon
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesMon
                          )}
                    </td>

                    {/* Tue */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesTue -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_tue +
                              timesheetEntry.minutesTue
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesTue +
                              timesheetEntry.minutesTue
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesTue
                          )}
                    </td>

                    {/* Wed */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesWed -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_wed +
                              timesheetEntry.minutesWed
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesWed +
                              timesheetEntry.minutesWed
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesWed
                          )}
                    </td>

                    {/* Thu */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesThu -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_thu +
                              timesheetEntry.minutesThu
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesThu +
                              timesheetEntry.minutesThu
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesThu
                          )}
                    </td>

                    {/* Fri */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesFri -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_fri +
                              timesheetEntry.minutesFri
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesFri +
                              timesheetEntry.minutesFri
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesFri
                          )}
                    </td>

                    {/* Sat */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesSat -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_sat +
                              timesheetEntry.minutesSat
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesSat +
                              timesheetEntry.minutesSat
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesSat
                          )}
                    </td>

                    {/* Sun */}
                    <td style={{ textAlign: "center" }}>
                      {editTimesheetEntryId
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesSun -
                              timesheetEntryDetails.timesheet_entry
                                .minutes_sun +
                              timesheetEntry.minutesSun
                          )
                        : showNewTimesheetEntryForm
                        ? convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesSun +
                              timesheetEntry.minutesSun
                          )
                        : convertMinutesToHoursAndMinutes(
                            timesheetEntryList.totalMinutesSun
                          )}
                    </td>

                    {/* Total */}
                    <td style={{ textAlign: "center" }}>
                      <strong>
                        {editTimesheetEntryId
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutes -
                                timesheetEntryDetails.timesheet_entry
                                  .total_minutes +
                                timesheetEntry.minutesFri +
                                timesheetEntry.minutesMon +
                                timesheetEntry.minutesSat +
                                timesheetEntry.minutesSun +
                                timesheetEntry.minutesThu +
                                timesheetEntry.minutesTue +
                                timesheetEntry.minutesWed
                            )
                          : showNewTimesheetEntryForm
                          ? convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutes +
                                timesheetEntry.minutesFri +
                                timesheetEntry.minutesMon +
                                timesheetEntry.minutesSat +
                                timesheetEntry.minutesSun +
                                timesheetEntry.minutesThu +
                                timesheetEntry.minutesTue +
                                timesheetEntry.minutesWed
                            )
                          : convertMinutesToHoursAndMinutes(
                              timesheetEntryList.totalMinutes
                            )}
                      </strong>
                    </td>

                    <td></td>
                  </tr>
                }
                head={
                  <tr>
                    {/* Booking code */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "250px",
                      }}
                    >
                      Booking code
                    </th>

                    {/* Task */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "250px",
                      }}
                    >
                      Task
                    </th>

                    {/* Engagement role */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "250px",
                      }}
                    >
                      Engagement role
                    </th>

                    {/* Mon */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Mon
                    </th>

                    {/* Tue */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Tue
                    </th>

                    {/* Wed */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Wed
                    </th>

                    {/* Thu */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Thu
                    </th>

                    {/* Fri */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Fri
                    </th>

                    {/* Sat */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Sat
                    </th>

                    {/* Sun */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "100px",
                      }}
                    >
                      Sun
                    </th>

                    {/* Total */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        textAlign: "center",
                        width: "90px",
                      }}
                    >
                      Total
                    </th>

                    {/* Comments */}
                    <th
                      className={Classes.TEXT_SMALL}
                      style={{
                        padding: "15px",
                        width: "300px",
                      }}
                    >
                      Comments
                    </th>
                  </tr>
                }
                interactive={
                  !editTimesheetEntryId &&
                  !showNewTimesheetEntryForm &&
                  timesheetDetails.timesheet.status === "Draft"
                }
                loading={timesheetEntryList.loading}
                newForm={
                  <tr>
                    {/* Booking code */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerSelectField2
                          error={timesheetEntryCreate.error}
                          fieldName="booking_code"
                          items={bookingCodeList.booking_codes.filter(
                            (booking_code) =>
                              !booking_code.engagement ||
                              booking_code.engagement_roles_data.filter(
                                (engagement_role) =>
                                  engagement_role.user === userLogin.user
                              ).length > 0
                          )}
                          loading={bookingCodeList.loading}
                          placeholder="Select a booking code"
                          query={bookingCodeQuery.searchString}
                          setQueryObject={setBookingCodeQuery}
                          setQueryVariable="searchString"
                          setValueObject={setTimesheetEntry}
                          setValueIdVariable="bookingCode"
                          setValueNameVariable="bookingCodeName"
                          value={timesheetEntry.bookingCodeName}
                        />
                      </div>
                    </td>

                    {/* Task */}
                    <td style={{ verticalAlign: "middle" }}>
                      {taskOptions.length > 1 ? (
                        <SelectField
                          options={taskOptions}
                          setValueObject={setTimesheetEntry}
                          setValueVariable="task"
                          value={timesheetEntry.task}
                        />
                      ) : (
                        <Text>-</Text>
                      )}
                    </td>

                    {/* Engagement role */}
                    <td style={{ verticalAlign: "middle" }}>
                      {engagementRoleOptions.length > 0 ? (
                        <SelectField
                          options={engagementRoleOptions}
                          setValueObject={setTimesheetEntry}
                          setValueVariable="engagementLabourExpense"
                          value={timesheetEntry.engagementLabourExpense}
                        />
                      ) : (
                        <Text>-</Text>
                      )}
                    </td>

                    {/* Mon */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesMon: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesMon
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesMon
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Tue */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesTue: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesTue
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesTue
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Wed */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesWed: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesWed
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesWed
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Thu */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesThu: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesThu
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesThu
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Fri */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesFri: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesFri
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesFri
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Sat */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesSat: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesSat
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesSat
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Sun */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <TimePicker
                        onChange={(newTime) => {
                          const minutes =
                            newTime.getHours() * 60 + newTime.getMinutes();

                          setTimesheetEntry((prevState) => {
                            return {
                              ...prevState,
                              minutesSun: minutes,
                            };
                          });
                        }}
                        value={
                          new Date(
                            0,
                            0,
                            0,
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesSun
                            ).split(":")[0],
                            convertMinutesToHoursAndMinutes(
                              timesheetEntry.minutesSun
                            ).split(":")[1]
                          )
                        }
                      />
                    </td>

                    {/* Total */}
                    <td
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <strong>
                        {convertMinutesToHoursAndMinutes(
                          timesheetEntry.minutesFri +
                            timesheetEntry.minutesMon +
                            timesheetEntry.minutesSat +
                            timesheetEntry.minutesSun +
                            timesheetEntry.minutesThu +
                            timesheetEntry.minutesTue +
                            timesheetEntry.minutesWed
                        )}
                      </strong>
                    </td>

                    {/* Comments */}
                    <td style={{ verticalAlign: "middle" }}>
                      <div style={{ margin: "0 0 -15px 0" }}>
                        <FormDrawerTextField2
                          error={timesheetEntryCreate.error}
                          fieldName="comments"
                          placeholder="Enter comments"
                          setValueObject={setTimesheetEntry}
                          setValueVariable="comments"
                          value={timesheetEntry.comments}
                        />
                      </div>
                    </td>
                  </tr>
                }
                noMargin
                noResultsMessage="Add entries."
                noResultsTitle="No timesheet entries found"
                page={timesheetEntryQuery.page}
                refreshList={() => {
                  dispatch(
                    listTimesheetEntriesAction({
                      timesheet: timesheetDetails.timesheet.id,
                    })
                  );
                }}
                resetAction={() => {
                  dispatch(resetCreateTimesheetEntryAction());

                  dispatch(resetDeleteTimesheetEntryAction());

                  dispatch(resetUpdateTimesheetEntryAction());
                }}
                resetFormVariables={() => {
                  setTimesheetEntry({
                    ...timesheetEntryDefault,
                  });
                }}
                setActiveRecord={setEditTimesheetEntryId}
                setPageObject={setTimesheetEntryQuery}
                setPageVariable="page"
                setShowNewForm={setShowNewTimesheetEntryForm}
                showNewForm={showNewTimesheetEntryForm}
                striped={false}
                updateSuccess={timesheetEntryUpdate.success}
                updateSuccessMessage="Timesheet entry updated."
              />

              {/* Buttons */}
              <div style={{ margin: "15px 0 0 0" }}>
                {/* Neither adding nor editing record */}
                {!editTimesheetEntryId && !showNewTimesheetEntryForm && (
                  <Button
                    disabled={
                      timesheetDetails.timesheet.status === "Approved" ||
                      timesheetDetails.timesheet.status === "Rejected" ||
                      timesheetDetails.timesheet.status === "Submitted"
                    }
                    icon="add"
                    intent="primary"
                    onClick={() => {
                      setShowNewTimesheetEntryForm(true);
                    }}
                    text="Add"
                  />
                )}

                {/* Adding record */}
                {showNewTimesheetEntryForm && (
                  <>
                    <Button
                      icon="add"
                      intent="success"
                      onClick={() => {
                        dispatch(resetCreateTimesheetEntryAction());

                        addTimesheetEntry();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Add"
                    />

                    <Button
                      icon="delete"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetCreateTimesheetEntryAction());

                        setShowNewTimesheetEntryForm(false);
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />
                  </>
                )}

                {/* Editing record */}
                {editTimesheetEntryId && (
                  <>
                    <Button
                      icon="updated"
                      intent="success"
                      onClick={() => {
                        dispatch(resetUpdateTimesheetEntryAction());

                        updateTimesheetEntry();
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Update"
                    />

                    <Button
                      icon="disable"
                      intent="primary"
                      onClick={() => {
                        dispatch(resetUpdateTimesheetEntryAction());

                        setEditTimesheetEntryId(null);
                      }}
                      style={{ margin: "0 10px 0 0" }}
                      text="Discard"
                    />

                    <Button
                      icon="delete"
                      intent="danger"
                      onClick={() => {
                        dispatch(resetUpdateTimesheetEntryAction());

                        deleteTimesheetEntry();
                      }}
                      text="Remove"
                    />
                  </>
                )}
              </div>
            </div>
          </Card>
        }
        deleteSuccess={timesheetDelete.success}
        deleteSuccessMessage="Timesheet deleted."
        deleting={timesheetDelete.loading}
        disabled={editTimesheetEntryId || showNewTimesheetEntryForm}
        error={timesheetUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update timesheet"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a timesheet"
        icon="edit"
        isOpen={updateTimesheetDrawerIsOpen}
        loading={timesheetDetails.loading}
        onDelete={
          timesheetDetails.timesheet.status === "Draft" && deleteTimesheet
        }
        onSave={
          timesheetDetails.timesheet.status === "Draft" && updateTimesheet
        }
        refreshList={() => {
          dispatch(listTimesheetsAction({ ...timesheetQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteTimesheetAction());

          dispatch(resetUpdateTimesheetAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={timesheetUpdate.success}
        saveSuccessMessage="Timesheet updated."
        saving={timesheetUpdate.loading}
        setIsOpen={setUpdateTimesheetDrawerIsOpen}
        size="large"
        title="Update timesheet"
      />

      {/* New expense claim form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter a title</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Claim title */}
            <FormDrawerTextField2
              error={claimCreate.error}
              errorMessage="Give the expense claim a title"
              fieldName="name"
              icon="bank-account"
              label="Claim title"
              labelInfo="(required)"
              placeholder="Give the expense claim a title"
              setValueObject={setClaim}
              setValueVariable="name"
              value={claim.name}
            />
          </Card>
        }
        error={claimCreate.error}
        errorMessage="We couldn't add the expense claim. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Create expense claim"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting an expense claim"
        icon="edit"
        isOpen={addClaimDrawerIsOpen}
        onSave={addClaim}
        refreshList={() => {
          dispatch(
            listClaimsAction({
              organisation: activeOrganisation.id,
              user: userLogin.user,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={claimCreate.success}
        saveSuccessMessage="Expense claim created."
        saving={claimCreate.loading}
        setIsOpen={setAddClaimDrawerIsOpen}
        size="small"
        title="Create expense claim"
      />

      {/* Existing expense claim form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the expense claim details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {claimDetails.claim.status === "Reversed" && (
              <Callout intent="warning" style={{ margin: "0 0 20px 0" }}>
                This claim was reversed by{" "}
                <strong>{claimDetails.claim.reversed_by}</strong> on{" "}
                {new Date(claimDetails.claim.reversed_on).toLocaleString(
                  "en-US",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  }
                )}
                {claimDetails.claim.reversed_comments
                  ? ", with the following comments: " +
                    claimDetails.claim.reversed_comments
                  : "."}
              </Callout>
            )}

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Claim title */}
                    <FormDrawerTextField2
                      disabled={claimDetails.claim.status !== "Draft"}
                      error={claimUpdate.error}
                      errorMessage="Give the expense claim a title"
                      fieldName="name"
                      icon="bank-account"
                      label="Claim title"
                      labelInfo="(required)"
                      placeholder="Give the expense claim a title"
                      setValueObject={setClaim}
                      setValueVariable="name"
                      value={claim.name}
                    />
                  </div>

                  <div
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      width: "35%",
                    }}
                  >
                    {/* Receipts */}
                    <FormDrawerFileField2
                      disabled={claimDetails.claim.status !== "Draft"}
                      error={claimUpdate.error}
                      fileName="receipts"
                      helperText="Combine your receipts into a single file"
                      label="Receipts"
                      placeholder="Upload receipts"
                      setValueObject={setClaim}
                      setValueFileVariable="receipts"
                      setValueNameVariable="receiptsName"
                      valueFile={claim.receipts}
                      valueName={claim.receiptsName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "15%" }}>
                    {/* Status */}
                    <div style={{ marginBottom: "5px" }}>Status</div>

                    <Tag
                      intent={
                        claimDetails.claim.status === "Approved"
                          ? "success"
                          : claimDetails.claim.status === "Draft"
                          ? "primary"
                          : claimDetails.claim.status === "Paid"
                          ? "success"
                          : claimDetails.claim.status === "Rejected"
                          ? "danger"
                          : "warning"
                      }
                      large
                      style={{
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      {claimDetails.claim.status}
                    </Tag>
                  </div>
                </div>

                {/* Comments */}
                <FormDrawerTextAreaField3
                  disabled={claimDetails.claim.status !== "Draft"}
                  error={claimUpdate.error}
                  fieldName="comments"
                  height="100px"
                  label="Comments"
                  noRewrite={claimDetails.claim.status !== "Draft"}
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setClaim}
                  setValueVariable="comments"
                  value={claim.comments}
                />
              </div>

              {claimDetails.claim.status === "Draft" ? (
                <Card style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve expenses"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setClaimApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={claimApprovalRequest.approverName}
                  />

                  {/* Submit */}
                  <FormDrawerButtonField
                    fieldName="status"
                    helperText="Do not submit to keep the expense claim in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: false,
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: true,
                      },
                    ]}
                    setValueObject={setClaimApprovalRequest}
                    setValueVariable="submit"
                    value={claimApprovalRequest.submit}
                  />
                </Card>
              ) : (
                <Card style={{ marginLeft: "10px", width: "40%" }}>
                  <p>
                    <strong>Reviewer</strong>
                  </p>

                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <NameValue
                      email={
                        claimDetails.claim.approval_data
                          ? claimDetails.claim.approval_data.approver_name
                          : undefined
                      }
                      image={
                        claimDetails.claim.approval_data
                          ? claimDetails.claim.approval_data
                              .approver_profile_photo
                          : undefined
                      }
                    />
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Submitted</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue date={claimDetails.claim.submitted} />
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Reviewed</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue date={claimDetails.claim.completed} />
                      </div>
                    </div>
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Comments</strong>
                  </p>

                  <p>
                    {claimDetails.claim.approval_data ? (
                      claimDetails.claim.approval_data.comments ? (
                        claimDetails.claim.approval_data.comments
                      ) : (
                        <span className={Classes.TEXT_MUTED}>No comments.</span>
                      )
                    ) : undefined}
                  </p>
                </Card>
              )}
            </div>

            {/* Expenses */}
            <div style={{ margin: "20px 0 20px 0" }}>
              {/* Error alerts */}
              {expenseCreate.error && (
                <ErrorList
                  error={expenseCreate.error}
                  message="Unable to create the expense."
                />
              )}

              {expenseDelete.error && (
                <ErrorList
                  error={expenseDelete.error}
                  message="Unable to delete the expense."
                />
              )}

              {expenseUpdate.error && (
                <ErrorList
                  error={expenseUpdate.error}
                  message="Unable to update the expense."
                />
              )}
            </div>

            {/* Data table */}
            <EditableTableContainer
              activeRecord={editExpenseId}
              body={
                <>
                  {expenseList.expenses.map((entry) => {
                    return (
                      <tr
                        key={entry.id}
                        onClick={
                          editExpenseId ||
                          showNewExpenseForm ||
                          claimDetails.claim.status !== "Draft"
                            ? undefined
                            : () => {
                                dispatch(listExpenseDetailsAction(entry.id));

                                setEditExpenseId(entry.id);
                              }
                        }
                      >
                        {/* Booking code */}
                        <td style={{ verticalAlign: "middle" }}>
                          {editExpenseId === entry.id ? (
                            <div style={{ margin: "0 0 -15px 0" }}>
                              <FormDrawerSelectField2
                                error={expenseUpdate.error}
                                fieldName="booking_code"
                                items={bookingCodeList.booking_codes}
                                loading={bookingCodeList.loading}
                                placeholder="Select a booking code"
                                query={bookingCodeQuery.searchString}
                                setQueryObject={setBookingCodeQuery}
                                setQueryVariable="searchString"
                                setValueObject={setExpense}
                                setValueIdVariable="bookingCode"
                                setValueNameVariable="bookingCodeName"
                                value={expense.bookingCodeName}
                              />
                            </div>
                          ) : (
                            <TableText text={entry.booking_code_data.name} />
                          )}
                        </td>

                        {/* Category */}
                        <td style={{ verticalAlign: "middle" }}>
                          {editExpenseId === entry.id ? (
                            <div style={{ margin: "0 0 -15px 0" }}>
                              <FormDrawerSelectField2
                                error={expenseUpdate.error}
                                fieldName="category"
                                items={expenseCategoryList.expense_categories}
                                loading={expenseCategoryList.loading}
                                placeholder="Select a category"
                                query={expenseCategoryQuery.searchString}
                                setQueryObject={setExpenseCategoryQuery}
                                setQueryVariable="searchString"
                                setValueObject={setExpense}
                                setValueIdVariable="category"
                                setValueNameVariable="categoryName"
                                value={expense.categoryName}
                              />
                            </div>
                          ) : (
                            <TableText text={entry.category_name} />
                          )}
                        </td>

                        {/* Date */}
                        <td style={{ verticalAlign: "middle" }}>
                          {editExpenseId === entry.id ? (
                            <div style={{ margin: "0 0 -15px 0" }}>
                              <FormDrawerDateField2
                                error={expenseUpdate.error}
                                fieldName="date"
                                icon="calendar"
                                placeholder="Enter date"
                                setValueObject={setExpense}
                                setValueVariable="date"
                                value={expense.date}
                              />
                            </div>
                          ) : (
                            new Date(entry.date).toLocaleString("en-US", {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })
                          )}
                        </td>

                        {/* Value */}
                        <td style={{ verticalAlign: "middle" }}>
                          {editExpenseId === entry.id ? (
                            <div style={{ margin: "0 0 -15px 0" }}>
                              <FormDrawerNumericField2
                                error={expenseUpdate.error}
                                fieldName="value"
                                leftElement={
                                  <Tag
                                    minimal
                                    style={{
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    {activeOrganisation.currency}
                                  </Tag>
                                }
                                majorStepSize={10000}
                                min={0}
                                minorStepSize={100}
                                placeholder="Enter value"
                                setValueObject={setExpense}
                                setValueVariable="value"
                                stepSize={1000}
                                value={expense.value}
                              />
                            </div>
                          ) : (
                            <TableNumber number={entry.value} />
                          )}
                        </td>

                        {/* Comments */}
                        <td style={{ verticalAlign: "middle" }}>
                          {editExpenseId === entry.id ? (
                            <div style={{ margin: "0 0 -15px 0" }}>
                              <FormDrawerTextField2
                                error={expenseUpdate.error}
                                fieldName="comments"
                                placeholder="Enter comments"
                                setValueObject={setExpense}
                                setValueVariable="comments"
                                value={expense.comments}
                              />
                            </div>
                          ) : (
                            <TableText text={entry.comments} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              }
              //bordered={false}
              count={expenseList.count}
              createSuccess={expenseCreate.success}
              createSuccessMessage="Expense created"
              deleteSuccess={expenseDelete.success}
              deleteSuccessMessage="Expense deleted."
              footer={
                <tr>
                  <td
                    colSpan="3"
                    style={{ textAlign: "end", verticalAlign: "middle" }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <div>Total</div>

                      <Tag
                        minimal
                        style={{
                          margin: "0 0 0 10px",
                          textAlign: "center",
                          width: "50px",
                        }}
                      >
                        {activeOrganisation.currency}
                      </Tag>
                    </div>
                  </td>

                  {/* Value */}
                  <td style={{ textAlign: "center" }}>
                    <TableNumber number={expenseList.totalValue} />
                  </td>

                  <td></td>
                </tr>
              }
              head={
                <tr>
                  {/* Booking code */}
                  <th
                    className={Classes.TEXT_SMALL}
                    style={{
                      padding: "15px",
                      width: "250px",
                    }}
                  >
                    Booking code
                  </th>

                  {/* Category */}
                  <th
                    className={Classes.TEXT_SMALL}
                    style={{
                      padding: "15px",
                      width: "250px",
                    }}
                  >
                    Category
                  </th>

                  {/* Date */}
                  <th
                    className={Classes.TEXT_SMALL}
                    style={{
                      padding: "15px",
                      width: "200px",
                    }}
                  >
                    Date
                  </th>

                  {/* Value */}
                  <th
                    className={Classes.TEXT_SMALL}
                    style={{
                      padding: "15px",
                      width: "200px",
                    }}
                  >
                    Value
                  </th>

                  {/* Comments */}
                  <th
                    className={Classes.TEXT_SMALL}
                    style={{
                      padding: "15px",
                      width: "300px",
                    }}
                  >
                    Comments
                  </th>
                </tr>
              }
              interactive={
                !editExpenseId &&
                !showNewExpenseForm &&
                claimDetails.claim.status === "Draft"
              }
              loading={expenseList.loading}
              newForm={
                <tr>
                  {/* Booking code */}
                  <td style={{ verticalAlign: "middle" }}>
                    <div style={{ margin: "0 0 -15px 0" }}>
                      <FormDrawerSelectField2
                        error={expenseCreate.error}
                        fieldName="booking_code"
                        items={bookingCodeList.booking_codes}
                        loading={bookingCodeList.loading}
                        placeholder="Select a booking code"
                        query={bookingCodeQuery.searchString}
                        setQueryObject={setBookingCodeQuery}
                        setQueryVariable="searchString"
                        setValueObject={setExpense}
                        setValueIdVariable="bookingCode"
                        setValueNameVariable="bookingCodeName"
                        value={expense.bookingCodeName}
                      />
                    </div>
                  </td>

                  {/* Category */}
                  <td style={{ verticalAlign: "middle" }}>
                    <div style={{ margin: "0 0 -15px 0" }}>
                      <FormDrawerSelectField2
                        error={expenseCreate.error}
                        fieldName="category"
                        items={expenseCategoryList.expense_categories}
                        loading={expenseCategoryList.loading}
                        placeholder="Select a category"
                        query={expenseCategoryQuery.searchString}
                        setQueryObject={setExpenseCategoryQuery}
                        setQueryVariable="searchString"
                        setValueObject={setExpense}
                        setValueIdVariable="category"
                        setValueNameVariable="categoryName"
                        value={expense.categoryName}
                      />
                    </div>
                  </td>

                  {/* Date */}
                  <td style={{ verticalAlign: "middle" }}>
                    <div style={{ margin: "0 0 -15px 0" }}>
                      <FormDrawerDateField2
                        error={expenseCreate.error}
                        fieldName="date"
                        icon="calendar"
                        placeholder="Enter date"
                        setValueObject={setExpense}
                        setValueVariable="date"
                        value={expense.date}
                      />
                    </div>
                  </td>

                  {/* Value */}
                  <td style={{ verticalAlign: "middle" }}>
                    <div style={{ margin: "0 0 -15px 0" }}>
                      <FormDrawerNumericField2
                        error={expenseCreate.error}
                        fieldName="value"
                        leftElement={
                          <Tag
                            minimal
                            style={{
                              textAlign: "center",
                              width: "50px",
                            }}
                          >
                            {activeOrganisation.currency}
                          </Tag>
                        }
                        majorStepSize={10000}
                        min={0}
                        minorStepSize={100}
                        placeholder="Enter value"
                        setValueObject={setExpense}
                        setValueVariable="value"
                        stepSize={1000}
                        value={expense.value}
                      />
                    </div>
                  </td>

                  {/* Comments */}
                  <td style={{ verticalAlign: "middle" }}>
                    <div style={{ margin: "0 0 -15px 0" }}>
                      <FormDrawerTextField2
                        error={expenseCreate.error}
                        fieldName="comments"
                        placeholder="Enter comments"
                        setValueObject={setExpense}
                        setValueVariable="comments"
                        value={expense.comments}
                      />
                    </div>
                  </td>
                </tr>
              }
              noMargin
              noResultsMessage="Add expenses."
              noResultsTitle="No expenses found"
              page={expenseQuery.page}
              refreshList={() => {
                dispatch(
                  listExpensesAction({
                    claim: claimDetails.claim.id,
                    organisation: activeOrganisation.id,
                  })
                );
              }}
              resetAction={() => {
                dispatch(resetCreateExpenseAction());

                dispatch(resetDeleteExpenseAction());

                dispatch(resetUpdateExpenseAction());
              }}
              resetFormVariables={() => {
                setExpense({
                  ...expenseDefault,
                });
              }}
              setActiveRecord={setEditExpenseId}
              setPageObject={setExpenseQuery}
              setPageVariable="page"
              setShowNewForm={setShowNewExpenseForm}
              showNewForm={showNewExpenseForm}
              striped={false}
              updateSuccess={expenseUpdate.success}
              updateSuccessMessage="Expense updated."
            />

            {/* Buttons */}
            <div style={{ margin: "15px 0 0 0" }}>
              {/* Neither adding nor editing record */}
              {!editExpenseId && !showNewExpenseForm && (
                <Button
                  disabled={
                    claimDetails.claim.status === "Approved" ||
                    claimDetails.claim.status === "Rejected" ||
                    claimDetails.claim.status === "Submitted"
                  }
                  icon="add"
                  intent="primary"
                  onClick={() => {
                    setShowNewExpenseForm(true);
                  }}
                  text="Add"
                />
              )}

              {/* Adding record */}
              {showNewExpenseForm && (
                <>
                  <Button
                    icon="add"
                    intent="success"
                    onClick={() => {
                      dispatch(resetCreateExpenseAction());

                      addExpense();
                    }}
                    style={{ margin: "0 10px 0 0" }}
                    text="Add"
                  />

                  <Button
                    icon="delete"
                    intent="primary"
                    onClick={() => {
                      dispatch(resetCreateExpenseAction());

                      setShowNewExpenseForm(false);
                    }}
                    style={{ margin: "0 10px 0 0" }}
                    text="Discard"
                  />
                </>
              )}

              {/* Editing record */}
              {editExpenseId && (
                <>
                  <Button
                    icon="updated"
                    intent="success"
                    onClick={() => {
                      dispatch(resetUpdateExpenseAction());

                      updateExpense();
                    }}
                    style={{ margin: "0 10px 0 0" }}
                    text="Update"
                  />

                  <Button
                    icon="disable"
                    intent="primary"
                    onClick={() => {
                      dispatch(resetUpdateExpenseAction());

                      setEditExpenseId(null);
                    }}
                    style={{ margin: "0 10px 0 0" }}
                    text="Discard"
                  />

                  <Button
                    icon="delete"
                    intent="danger"
                    onClick={() => {
                      dispatch(resetUpdateExpenseAction());

                      deleteExpense();
                    }}
                    text="Remove"
                  />
                </>
              )}
            </div>
          </Card>
        }
        deleteSuccess={claimDelete.success}
        deleteSuccessMessage="Expense claim deleted."
        deleting={claimDelete.loading}
        disabled={editExpenseId || showNewExpenseForm}
        error={claimUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update expense claim"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting an expense claim"
        icon="edit"
        isOpen={updateClaimDrawerIsOpen}
        loading={claimDetails.loading}
        onDelete={claimDetails.claim.status === "Draft" && deleteClaim}
        onSave={claimDetails.claim.status === "Draft" && updateClaim}
        refreshList={() => {
          dispatch(listClaimsAction({ ...claimQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteClaimAction());

          dispatch(resetUpdateClaimAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={claimUpdate.success}
        saveSuccessMessage="Claim updated."
        saving={claimUpdate.loading}
        setIsOpen={setUpdateClaimDrawerIsOpen}
        size="large"
        title="Update expense claim"
      />

      {/* New leave request form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Select the week</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Week */}
            <div style={{ margin: "0 0 15px 0" }}>
              <div style={{ marginBottom: "5px" }}>Week</div>

              <SelectField
                options={weekOptions}
                setValueObject={setLeaveRequest}
                setValueVariable="weekLabel"
                value={leaveRequest.weekLabel}
              />
            </div>
          </Card>
        }
        error={leaveRequestCreate.error}
        errorMessage="We couldn't add the leave request. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Create leave request"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a leave request"
        icon="edit"
        isOpen={addLeaveRequestDrawerIsOpen}
        onSave={addLeaveRequest}
        refreshList={() => {
          dispatch(
            listLeaveRequestsAction({
              organisation: activeOrganisation.id,
              user: userLogin.user,
            })
          );
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={leaveRequestCreate.success}
        saveSuccessMessage="Leave request created."
        saving={leaveRequestCreate.loading}
        setIsOpen={setAddLeaveRequestDrawerIsOpen}
        size="small"
        title="Create leave request"
      />

      {/* Existing leave request form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the leave request details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {leaveRequestDetails.leave_request.status === "Cancelled" && (
              <Callout intent="warning" style={{ margin: "0 0 20px 0" }}>
                This leave request was cancelled on{" "}
                {new Date(
                  leaveRequestDetails.leave_request.cancelled_on
                ).toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Callout>
            )}

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Week */}
                    <div style={{ margin: "0 0 15px 0" }}>
                      <div style={{ marginBottom: "5px" }}>Week</div>

                      <SelectField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        options={weekOptions}
                        setValueObject={setLeaveRequest}
                        setValueVariable="weekLabel"
                        value={leaveRequest.weekLabel}
                      />
                    </div>
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Status */}
                    <div style={{ marginBottom: "5px" }}>Status</div>

                    <Tag
                      intent={
                        leaveRequestDetails.leave_request.status === "Approved"
                          ? "success"
                          : leaveRequestDetails.leave_request.status === "Draft"
                          ? "primary"
                          : leaveRequestDetails.leave_request.status ===
                            "Rejected"
                          ? "danger"
                          : "warning"
                      }
                      large
                      style={{
                        minWidth: "100px",
                        textAlign: "center",
                      }}
                    >
                      {leaveRequestDetails.leave_request.status}
                    </Tag>
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "85%" }}>
                    {/* Type */}
                    <FormDrawerSelectField2
                      disabled={
                        leaveRequestDetails.leave_request.status !== "Draft"
                      }
                      error={leaveRequestUpdate.error}
                      fieldName="category"
                      helperText="You can select an existing category"
                      icon="key-escape"
                      info={
                        <LeaveCategoryInfo
                          categoryId={leaveRequest.category}
                          query={leaveCategoryQueryDefault}
                        />
                      }
                      items={leaveCategoryList.leave_categories}
                      label="Type"
                      loading={leaveCategoryList.loading}
                      placeholder="Select a category"
                      query={leaveCategoryQuery.searchString}
                      setQueryObject={setLeaveCategoryQuery}
                      setQueryVariable="searchString"
                      setValueObject={setLeaveRequest}
                      setValueIdVariable="category"
                      setValueNameVariable="categoryName"
                      showInfo={leaveRequest.category}
                      value={leaveRequest.categoryName}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "15%" }}></div>
                </div>

                {/* Comments */}
                <FormDrawerTextAreaField3
                  disabled={
                    leaveRequestDetails.leave_request.status !== "Draft"
                  }
                  error={leaveRequestUpdate.error}
                  fieldName="comments"
                  height="100px"
                  label="Comments"
                  noRewrite={
                    leaveRequestDetails.leave_request.status !== "Draft"
                  }
                  placeholder="Enter any notes you want to capture"
                  setValueObject={setLeaveRequest}
                  setValueVariable="comments"
                  value={leaveRequest.comments}
                />
              </div>

              {leaveRequestDetails.leave_request.status === "Draft" ? (
                <Card style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve leave requests"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setLeaveRequestApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={leaveRequestApprovalRequest.approverName}
                  />

                  {/* Submit */}
                  <FormDrawerButtonField
                    fieldName="status"
                    helperText="Do not submit to keep the leave request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: false,
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: true,
                      },
                    ]}
                    setValueObject={setLeaveRequestApprovalRequest}
                    setValueVariable="submit"
                    value={leaveRequestApprovalRequest.submit}
                  />
                </Card>
              ) : (
                <Card style={{ marginLeft: "10px", width: "50%" }}>
                  <p>
                    <strong>Reviewer</strong>
                  </p>

                  <div
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <NameValue
                      email={
                        leaveRequestDetails.leave_request.approval_data
                          ? leaveRequestDetails.leave_request.approval_data
                              .approver_name
                          : undefined
                      }
                      image={
                        leaveRequestDetails.leave_request.approval_data
                          ? leaveRequestDetails.leave_request.approval_data
                              .approver_profile_photo
                          : undefined
                      }
                    />
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Submitted</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue
                          date={leaveRequestDetails.leave_request.submitted}
                        />
                      </div>
                    </div>

                    <div style={{ width: "50%" }}>
                      <p>
                        <strong>Reviewed</strong>
                      </p>

                      <div
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        <DateValue
                          date={leaveRequestDetails.leave_request.completed}
                        />
                      </div>
                    </div>
                  </div>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY5 : Colors.GRAY1,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    <strong>Comments</strong>
                  </p>

                  <p>
                    {leaveRequestDetails.leave_request.approval_data ? (
                      leaveRequestDetails.leave_request.approval_data
                        .comments ? (
                        leaveRequestDetails.leave_request.approval_data.comments
                      ) : (
                        <span className={Classes.TEXT_MUTED}>No comments.</span>
                      )
                    ) : undefined}
                  </p>
                </Card>
              )}
            </div>

            {/* Leave entries */}
            <Card style={{ margin: "20px 0 20px 0" }}>
              <HTMLTable style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_mon"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesMon"
                        value={leaveRequest.minutesMon}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_tue"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesTue"
                        value={leaveRequest.minutesTue}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_wed"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesWed"
                        value={leaveRequest.minutesWed}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_thu"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesThu"
                        value={leaveRequest.minutesThu}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_fri"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesFri"
                        value={leaveRequest.minutesFri}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_sat"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesSat"
                        value={leaveRequest.minutesSat}
                      />
                    </td>

                    <td>
                      <FormDrawerTimeField
                        disabled={
                          leaveRequestDetails.leave_request.status !== "Draft"
                        }
                        error={leaveRequestUpdate.error}
                        fieldName="minutes_sun"
                        setValueObject={setLeaveRequest}
                        setValueVariable="minutesSun"
                        value={leaveRequest.minutesSun}
                      />
                    </td>

                    <td>
                      <div style={{ paddingTop: "5px" }}>
                        <strong>
                          {convertMinutesToHoursAndMinutes(
                            leaveRequest.minutesFri +
                              leaveRequest.minutesMon +
                              leaveRequest.minutesSat +
                              leaveRequest.minutesSun +
                              leaveRequest.minutesThu +
                              leaveRequest.minutesTue +
                              leaveRequest.minutesWed
                          )}
                        </strong>
                      </div>
                    </td>
                  </tr>
                </tbody>

                <thead>
                  <tr>
                    <td>Monday</td>

                    <td>Tuesday</td>

                    <td>Wednesday</td>

                    <td>Thursday</td>

                    <td>Friday</td>

                    <td>Saturday</td>

                    <td>Sunday</td>

                    <td>Total</td>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>

            {leaveRequestDetails.leave_request.status === "Approved" && (
              <Button
                icon="undo"
                intent="danger"
                loading={leaveRequestCancel.loading}
                onClick={() => {
                  dispatch(
                    cancelLeaveRequestAction(
                      leaveRequestDetails.leave_request.id
                    )
                  );
                }}
                text="Cancel this leave"
              />
            )}
          </Card>
        }
        deleteSuccess={leaveRequestDelete.success}
        deleteSuccessMessage="Leave request deleted."
        deleting={leaveRequestDelete.loading}
        error={leaveRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update leave request"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Submitting a leave request"
        icon="edit"
        isOpen={updateLeaveRequestDrawerIsOpen}
        loading={leaveRequestDetails.loading}
        onDelete={
          leaveRequestDetails.leave_request.status === "Draft" &&
          deleteLeaveRequest
        }
        onSave={
          leaveRequestDetails.leave_request.status === "Draft" &&
          updateLeaveRequest
        }
        refreshList={() => {
          dispatch(listLeaveRequestsAction({ ...leaveRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteLeaveRequestAction());

          dispatch(resetUpdateLeaveRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={leaveRequestUpdate.success}
        saveSuccessMessage="Leave request updated."
        saving={leaveRequestUpdate.loading}
        setIsOpen={setUpdateLeaveRequestDrawerIsOpen}
        size="medium"
        title="Update leave request"
      />

      {/* Approval response form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Review the request</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {approvalResponseDetails.approval_response.status ===
                "Submitted" && (
                <Callout intent="primary" style={{ margin: "0 0 15px 0" }}>
                  You have already submitted this decision. If you want to
                  change it, ask the originator to submit another approval
                  request.
                </Callout>
              )}

              {/* Approval request */}
              <Callout
                children={
                  <div>
                    {approvalResponseDetails.approval_response.request_data ? (
                      <div>
                        <TabSectionField
                          helperText={generateApprovalRequestDecisionHelperText()}
                          invertBorder
                          label="Decision"
                          value={
                            approvalResponseDetails.approval_response
                              .request_data.decision_right
                          }
                          vertical
                        />

                        <TabSectionField
                          helperText={generateApprovalRequestValueHelperText()}
                          invertBorder
                          label="Value"
                          value={
                            approvalResponseDetails.approval_response
                              .request_data.decision_right === "Staff leave" ||
                            approvalResponseDetails.approval_response
                              .request_data.decision_right ===
                              "Staff timesheet" ? (
                              `${
                                convertMinutesToHoursAndMinutes(
                                  approvalResponseDetails.approval_response
                                    .request_data.value
                                ).split(":")[0]
                              }h ${
                                convertMinutesToHoursAndMinutes(
                                  approvalResponseDetails.approval_response
                                    .request_data.value
                                ).split(":")[1]
                              }m`
                            ) : (
                              <NumericValue
                                currency={activeOrganisation.currency}
                                number={
                                  approvalResponseDetails.approval_response
                                    .request_data.value
                                }
                              />
                            )
                          }
                          vertical
                        />

                        <TabSectionField
                          invertBorder
                          label="Requestor"
                          value={
                            <NameValue
                              email={
                                approvalResponseDetails.approval_response
                                  .request_data.requestor_name
                              }
                              image={
                                approvalResponseDetails.approval_response
                                  .request_data.requestor_profile_photo
                              }
                            />
                          }
                          vertical
                        />

                        <TabSectionField
                          invertBorder
                          label="Date submitted"
                          value={
                            <DateValue
                              date={
                                approvalResponseDetails.approval_response
                                  .request_data.submitted
                              }
                            />
                          }
                          vertical
                        />

                        <TabSectionField
                          invertBorder
                          label="Requestor comments"
                          value={
                            <TabSectionFieldCard
                              text={
                                approvalResponseDetails.approval_response
                                  .request_data.comments
                              }
                            />
                          }
                          vertical
                        />

                        <TabSectionField
                          bottom
                          label="Facts"
                          value={generateApprovalRequestFacts()}
                          vertical
                        />
                      </div>
                    ) : undefined}
                  </div>
                }
                style={{ margin: "20px 0 20px 0" }}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit your decision</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {approvalResponseDetails.approval_response.status === "Draft" && (
                <Callout intent="warning" style={{ margin: "0 0 15px 0" }}>
                  Once you submit your decision, the approval request and
                  response will become immutable (to preserve a record of the
                  decision).
                </Callout>
              )}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "45%" }}>
                  {/* Outcome */}
                  <FormDrawerSelectSimpleField3
                    disabled={
                      approvalResponseDetails.approval_response.status ===
                      "Submitted"
                    }
                    error={approvalResponseUpdate.error}
                    errorMessage="Approve or deny the request"
                    fieldName="outcome"
                    helperText="Defaults to pending if you keep the response in draft"
                    intent={
                      approvalResponse.outcome === "Approved"
                        ? "success"
                        : approvalResponse.outcome === "Denied"
                        ? "danger"
                        : undefined
                    }
                    label="Outcome"
                    labelInfo="(required)"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Approved",
                      },
                      { id: 2, icon: "remove", value: "Denied" },
                      { id: 3, icon: "circle", value: "Pending" },
                    ]}
                    setValueObject={setApprovalResponse}
                    setValueVariable="outcome"
                    value={approvalResponse.outcome}
                  />
                </div>

                <div style={{ width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    disabled={
                      approvalResponse.outcome === "Pending" ||
                      approvalResponseDetails.approval_response.status ===
                        "Submitted"
                    }
                    error={approvalResponseUpdate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the response in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit decision",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalResponse}
                    setValueVariable="status"
                    value={approvalResponse.status}
                  />
                </div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                disabled={
                  approvalResponseDetails.approval_response.status ===
                  "Submitted"
                }
                error={approvalResponseUpdate.error}
                fieldName="comments"
                label="Comments"
                noRewrite={
                  approvalResponseDetails.approval_response.status ===
                  "Submitted"
                }
                placeholder="Enter your comments"
                setValueObject={setApprovalResponse}
                setValueVariable="comments"
                value={approvalResponse.comments}
              />
            </Card>
          </div>
        }
        error={approvalResponseUpdate.error}
        errorMessage="We couldn't update the approval response. Review any errors below and try
        saving again."
        helpContent={
          <FormDrawerHelpContainer
            form="Review requests"
            module="Dashboard"
            view="Detail"
          />
        }
        helpTitle="Reviewing a request"
        icon="edit"
        isOpen={updateApprovalResponseDrawerIsOpen}
        loading={approvalResponseDetails.loading}
        onSave={
          approvalResponseDetails.approval_response.status !== "Submitted" &&
          updateApprovalResponse
        }
        refreshList={() => {
          dispatch(listApprovalResponsesAction({ ...approvalResponseQuery }));
        }}
        resetAction={() => {
          dispatch(resetUpdateApprovalResponseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalResponseUpdate.success}
        saveSuccessMessage="Approval response updated."
        saving={approvalResponseUpdate.loading}
        setIsOpen={setUpdateApprovalResponseDrawerIsOpen}
        size="medium"
        title="Review request"
      />

      {/* Navigation bar */}
      <NavbarContainer
        /*children={
          <Button
            icon="help"
            minimal
            text="Help"
            onClick={() => {
              setHelpDialogIsOpen(true);
            }}
          />
        }*/
        title="Dashboard"
      />

      {/* Error alerts */}
      {/* Approval responses loading error */}
      {approvalResponseList.error && (
        <ErrorAlert message="We couldn't load the approval response list. Try refreshing the page." />
      )}
      {/* Booking codes loading error */}
      {bookingCodeList.error && (
        <ErrorAlert message="We couldn't load the booking code list. Try refreshing the page." />
      )}
      {/* Claims loading error */}
      {claimList.error && (
        <ErrorAlert message="We couldn't load the claim list. Try refreshing the page." />
      )}
      {/* Engagements loading error */}
      {engagementList.error && (
        <ErrorAlert message="We couldn't load the engagement list. Try refreshing the page." />
      )}
      {/* Expense categories loading error */}
      {expenseCategoryList.error && (
        <ErrorAlert message="We couldn't load the expense category list. Try refreshing the page." />
      )}
      {/* Expenses loading error */}
      {expenseList.error && (
        <ErrorAlert message="We couldn't load the expense list. Try refreshing the page." />
      )}
      {/* Leave request loading error */}
      {leaveRequestList.error && (
        <ErrorAlert message="We couldn't load the leave request list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Proposals loading error */}
      {proposalList.error && (
        <ErrorAlert message="We couldn't load the proposal list. Try refreshing the page." />
      )}
      {/* Staff loading error */}
      {staffList.error && (
        <ErrorAlert message="We couldn't load the staff list. Try refreshing the page." />
      )}
      {/* Staff development plans loading error */}
      {staffDevelopmentPlanList.error && (
        <ErrorAlert message="We couldn't load the staff development plans. Try refreshing the page." />
      )}
      {/* Staff goals loading error */}
      {staffGoalList.error && (
        <ErrorAlert message="We couldn't load the staff goals. Try refreshing the page." />
      )}
      {/* Timesheets loading error */}
      {timesheetList.error && (
        <ErrorAlert message="We couldn't load the timesheet list. Try refreshing the page." />
      )}
      {/* Timesheet entries loading error */}
      {timesheetEntryList.error && (
        <ErrorAlert message="We couldn't load the timesheet entry list. Try refreshing the page." />
      )}
      {/* Weeks loading error */}
      {weekList.error && (
        <ErrorAlert message="We couldn't load the week list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Introduction to advaise.app"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="dashboard-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Overview */}
            <Tab
              id="dashboard-overview-tab"
              title="Overview"
              panel={
                <div>
                  {/* Welcome */}
                  {bookingList.count === 0 &&
                    engagementList.count === 0 &&
                    proposalList.count === 0 &&
                    todoList.count === 0 && <DashboardWelcomeCard />}

                  {/* My bookings */}
                  {bookingList.count > 0 && (
                    <DashboardSection
                      content={
                        <>
                          <DataCardContainer
                            children={
                              <>
                                {/* Bookings */}
                                <StaffBookingsCard2
                                  bookingProfile={bookingList.bookingProfile}
                                  setStaffQuery={setBookingQuery}
                                />
                              </>
                            }
                            fill
                            noMargin
                          />
                        </>
                      }
                      icon="timeline-events"
                      title="My bookings"
                    />
                  )}

                  {/* My proposals */}
                  {proposalList.count > 0 && (
                    <DashboardSection
                      content={
                        <>
                          <DataCardContainer
                            children={
                              <>
                                {/* Pipeline */}
                                <ProposalPipelineCard
                                  fill
                                  proposalList={proposalList}
                                />

                                {/* Total value */}
                                <ProposalTotalValueCard
                                  proposalList={proposalList}
                                  proposalQuery={proposalQuery}
                                />

                                {/* Average value */}
                                <ProposalAverageValueCard
                                  fill
                                  proposalList={proposalList}
                                />
                              </>
                            }
                            fill
                            noMargin
                          />
                        </>
                      }
                      icon="manual"
                      title="My proposals"
                    />
                  )}

                  {/* My engagements */}
                  {engagementList.count > 0 && (
                    <DashboardSection
                      content={
                        <>
                          <DataCardContainer
                            children={
                              <>
                                {/* Plan at completion */}
                                <EngagementPlanAtCompletionCard
                                  fill
                                  engagementList={engagementList}
                                />

                                {/* Actuals */}
                                <EngagementActualsCard
                                  fill
                                  engagementList={engagementList}
                                />
                              </>
                            }
                            fill
                            noMargin
                          />
                        </>
                      }
                      icon="gantt-chart"
                      title="My engagements"
                    />
                  )}

                  {/* My actions */}
                  {todoList.count > 0 && (
                    <DashboardSection
                      content={
                        <>
                          <DataCardContainer
                            children={
                              <>
                                {/* Actions */}
                                <TodoStatusCard fill todoList={todoList} />
                              </>
                            }
                            fill
                            noMargin
                          />
                        </>
                      }
                      icon="build"
                      title="My actions"
                    />
                  )}
                </div>
              }
            />

            {/* Development */}
            <Tab
              id="dashboard-goals-tab"
              title="Goals"
              panel={
                <div>
                  {/* Goals */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Exceeded */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Exceeded"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Exceeded",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsExceeded}
                              />

                              {/* Met */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="Met"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Met",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsMet}
                              />

                              {/* Not met */}
                              <DataCardSimple
                                intent="danger"
                                interactive
                                label="Not met"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Not met",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsNotMet}
                              />

                              {/* Not rated */}
                              <DataCardSimple
                                intent="primary"
                                interactive
                                label="Not rated"
                                loading={staffGoalList.loading}
                                onClick={() => {
                                  setStaffGoalQuery({
                                    ...staffGoalQuery,
                                    page: 1,
                                    rating: "Not rated",
                                  });
                                }}
                                value={staffGoalList.numberOfGoalsNotRated}
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={staffGoalList.staff_goals.map((staff_goal) => {
                            return (
                              <tr
                                key={staff_goal.id}
                                onClick={() => {
                                  dispatch(
                                    listStaffGoalDetailsAction(staff_goal.id)
                                  );

                                  setUpdateGoalDrawerIsOpen(true);
                                }}
                              >
                                {/* Goal */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    info={
                                      <>
                                        <H5>{staff_goal.name}</H5>

                                        <Divider
                                          style={{
                                            background: lightMode
                                              ? Colors.GRAY1
                                              : Colors.GRAY5,
                                            margin: "10px 0 10px 0",
                                          }}
                                        />

                                        <p>
                                          <strong>Goal</strong>
                                        </p>

                                        <p>{staff_goal.goal}</p>

                                        <Divider
                                          style={{
                                            background: lightMode
                                              ? Colors.GRAY1
                                              : Colors.GRAY5,
                                            margin: "10px 0 10px 0",
                                          }}
                                        />

                                        <p>
                                          <strong>Comments</strong>
                                        </p>

                                        <p>
                                          {staff_goal.comments ? (
                                            staff_goal.comments
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )}
                                        </p>
                                      </>
                                    }
                                    text={staff_goal.name}
                                  />
                                </td>

                                {/* Year */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag value={staff_goal.year} />
                                </td>

                                {/* Due */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={staff_goal.due} />
                                </td>

                                {/* Rating */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      staff_goal.rating === "Exceeded"
                                        ? "success"
                                        : staff_goal.rating === "Met"
                                        ? "warning"
                                        : staff_goal.rating === "Not met"
                                        ? "danger"
                                        : "primary"
                                    }
                                    value={staff_goal.rating}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !staffGoalQuery.dueEnd &&
                            !staffGoalQuery.dueStart &&
                            !staffGoalQuery.rating &&
                            !staffGoalQuery.searchString &&
                            !staffGoalQuery.year
                          }
                          count={staffGoalList.count}
                          head={
                            <tr>
                              {/* Goal */}
                              <TableColumnHeadSearch
                                queryObject={staffGoalQuery}
                                queryVariable="searchString"
                                setQueryObject={setStaffGoalQuery}
                                setQueryVariable="searchString"
                                title="Goal"
                                width="200px"
                              />

                              {/* Year */}
                              <TableColumnHeadSelectSimple
                                filterObject={staffGoalQuery}
                                filterVariable="year"
                                options={generateYearList}
                                setFilterObject={setStaffGoalQuery}
                                title="Year"
                                width="125px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={staffGoalQuery}
                                setQueryObject={setStaffGoalQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Rating */}
                              <TableColumnHeadSelectSimple
                                filterObject={staffGoalQuery}
                                filterVariable="rating"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Exceeded",
                                    icon: "arrow-up",
                                    value: "Exceeded",
                                  },
                                  {
                                    display: "Met",
                                    icon: "arrow-right",
                                    value: "Met",
                                  },
                                  {
                                    display: "Not met",
                                    icon: "arrow-down",
                                    value: "Not met",
                                  },
                                  {
                                    display: "Not rated",
                                    icon: "remove",
                                    value: "Not rated",
                                  },
                                ]}
                                setFilterObject={setStaffGoalQuery}
                                title="Rating"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={staffGoalList.loading}
                          noMargin
                          onClearFilters={() => {
                            setStaffGoalQuery({
                              ...staffGoalQueryDefault,
                            });
                          }}
                          page={staffGoalQuery.page}
                          setPageObject={setStaffGoalQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Log progress toward goals"
                    icon="locate"
                    title="Goals"
                  />

                  {/* Development plan */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive
                                label="Completed"
                                loading={staffDevelopmentPlanList.loading}
                                onClick={() => {
                                  setStaffDevelopmentPlanQuery({
                                    ...staffDevelopmentPlanQuery,
                                    page: 1,
                                    status: "Completed",
                                  });
                                }}
                                value={
                                  staffDevelopmentPlanList.numberOfDevelopmentPlansCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive
                                label="In-progress"
                                loading={staffDevelopmentPlanList.loading}
                                onClick={() => {
                                  setStaffDevelopmentPlanQuery({
                                    ...staffDevelopmentPlanQuery,
                                    page: 1,
                                    status: "In-progress",
                                  });
                                }}
                                value={
                                  staffDevelopmentPlanList.numberOfDevelopmentPlansInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive
                                label="On-hold"
                                loading={staffDevelopmentPlanList.loading}
                                onClick={() => {
                                  setStaffDevelopmentPlanQuery({
                                    ...staffDevelopmentPlanQuery,
                                    page: 1,
                                    status: "On-hold",
                                  });
                                }}
                                value={
                                  staffDevelopmentPlanList.numberOfDevelopmentPlansOnHold
                                }
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={staffDevelopmentPlanList.staff_development_plans.map(
                            (staff_development_plan) => {
                              return (
                                <tr
                                  key={staff_development_plan.id}
                                  onClick={() => {
                                    dispatch(
                                      listStaffDevelopmentPlanDetailsAction(
                                        staff_development_plan.id
                                      )
                                    );

                                    setUpdateDevelopmentPlanDrawerIsOpen(true);
                                  }}
                                >
                                  {/* Development plan */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      info={
                                        <>
                                          <H5>{staff_development_plan.name}</H5>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Development plan</strong>
                                          </p>

                                          <p>
                                            {
                                              staff_development_plan.development_plan
                                            }
                                          </p>

                                          <Divider
                                            style={{
                                              background: lightMode
                                                ? Colors.GRAY1
                                                : Colors.GRAY5,
                                              margin: "10px 0 10px 0",
                                            }}
                                          />

                                          <p>
                                            <strong>Comments</strong>
                                          </p>

                                          <p>
                                            {staff_development_plan.comments ? (
                                              staff_development_plan.comments
                                            ) : (
                                              <span
                                                className={Classes.TEXT_MUTED}
                                              >
                                                No comments.
                                              </span>
                                            )}
                                          </p>
                                        </>
                                      }
                                      text={staff_development_plan.name}
                                    />
                                  </td>

                                  {/* Due */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableDate
                                      date={staff_development_plan.due}
                                    />
                                  </td>

                                  {/* Status */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      intent={
                                        staff_development_plan.status ===
                                        "Completed"
                                          ? "success"
                                          : staff_development_plan.status ===
                                            "In-progress"
                                          ? "warning"
                                          : "primary"
                                      }
                                      value={staff_development_plan.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !staffDevelopmentPlanQuery.dueEnd &&
                            !staffDevelopmentPlanQuery.dueStart &&
                            !staffDevelopmentPlanQuery.searchString &&
                            !staffDevelopmentPlanQuery.status
                          }
                          count={staffDevelopmentPlanList.count}
                          head={
                            <tr>
                              {/* Development plan */}
                              <TableColumnHeadSearch
                                queryObject={staffDevelopmentPlanQuery}
                                queryVariable="searchString"
                                setQueryObject={setStaffDevelopmentPlanQuery}
                                setQueryVariable="searchString"
                                title="Development plan"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={staffDevelopmentPlanQuery}
                                setQueryObject={setStaffDevelopmentPlanQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={staffDevelopmentPlanQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Completed",
                                    icon: "confirm",
                                    value: "Completed",
                                  },
                                  {
                                    display: "In-progress",
                                    icon: "refresh",
                                    value: "In-progress",
                                  },
                                  {
                                    display: "On-hold",
                                    icon: "remove",
                                    value: "On-hold",
                                  },
                                ]}
                                setFilterObject={setStaffDevelopmentPlanQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={staffDevelopmentPlanList.loading}
                          noMargin
                          onClearFilters={() => {
                            setStaffDevelopmentPlanQuery({
                              ...staffDevelopmentPlanQueryDefault,
                            });
                          }}
                          page={staffDevelopmentPlanQuery.page}
                          setPageObject={setStaffDevelopmentPlanQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage actions to develop your skills"
                    icon="learning"
                    title="Development plan"
                  />
                </div>
              }
            />

            {/* Assignments */}
            <Tab
              id="dashboard-assignments-tab"
              title="Assignments"
              panel={
                <div>
                  {/* Bookings */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Utilisation */}
                              <EngagementBookingUtilisationCard
                                engagementBookingList={bookingList}
                                fill
                              />

                              {/* Time */}
                              <EngagementBookingTimeCard
                                engagementBookingList={bookingList}
                                fill
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={bookingList.bookings.map((booking) => {
                            return (
                              <tr key={booking.id}>
                                {/* Booking code */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    info={<BookingInfo bookingData={booking} />}
                                    text={
                                      booking.booking_code_data
                                        ? booking.booking_code_data.name
                                        : undefined
                                    }
                                  />
                                </td>

                                {/* Billable */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      booking.booking_code_data.billable
                                        ? "success"
                                        : "primary"
                                    }
                                    value={
                                      booking.booking_code_data.billable
                                        ? "Billable"
                                        : "Not billable"
                                    }
                                  />
                                </td>

                                {/* Type */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      booking.type === "Confirmed"
                                        ? "primary"
                                        : booking.type === "Realised"
                                        ? "success"
                                        : "warning"
                                    }
                                    value={booking.type}
                                  />
                                </td>

                                {/* Effort */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    value={`${
                                      convertMinutesToHoursAndMinutes(
                                        booking.minutes
                                      ).split(":")[0]
                                    }h ${
                                      convertMinutesToHoursAndMinutes(
                                        booking.minutes
                                      ).split(":")[1]
                                    }m`}
                                  />
                                </td>

                                {/* Start */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={booking.start} />
                                </td>

                                {/* End */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={booking.end} />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !bookingQuery.billable &&
                            !bookingQuery.bookingCode &&
                            !bookingQuery.endEnd &&
                            !bookingQuery.endStart &&
                            !bookingQuery.searchString &&
                            !bookingQuery.startEnd &&
                            !bookingQuery.startStart &&
                            !bookingQuery.type
                          }
                          count={bookingList.count}
                          head={
                            <tr>
                              {/* Booking code */}
                              <TableColumnHeadSelect2
                                filterObject={bookingQuery}
                                filterVariable="bookingCode"
                                items={bookingCodeList.booking_codes}
                                setFilterObject={setBookingQuery}
                                setQueryObject={setBookingCodeQuery}
                                setQueryVariable="searchString"
                                title="Booking code"
                                width="200px"
                              />

                              {/* Billable */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="billable"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Billable",
                                    icon: "dollar",
                                    value: "true",
                                  },
                                  {
                                    display: "Not billable",
                                    icon: "cross",
                                    value: "false",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Billable"
                                width="125px"
                              />

                              {/* Type */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="type"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Confirmed",
                                    icon: "circle",
                                    value: "Confirmed",
                                  },
                                  {
                                    display: "Realised",
                                    icon: "confirm",
                                    value: "Realised",
                                  },
                                  {
                                    display: "Tentative",
                                    icon: "remove",
                                    value: "Tentative",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Type"
                                width="125px"
                              />

                              {/* Effort */}
                              <TableColumnHeadSelectSimple
                                filterObject={bookingQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-minutes",
                                  },
                                ]}
                                setFilterObject={setBookingQuery}
                                title="Effort"
                                width="125px"
                              />

                              {/* Start */}
                              <TableColumnHeadDate
                                endDateVariable="startEnd"
                                queryObject={bookingQuery}
                                setQueryObject={setBookingQuery}
                                shortRange
                                startDateVariable="startStart"
                                title="Start"
                                width="125px"
                              />

                              {/* End */}
                              <TableColumnHeadDate
                                endDateVariable="endEnd"
                                queryObject={bookingQuery}
                                setQueryObject={setBookingQuery}
                                shortRange
                                startDateVariable="endStart"
                                title="End"
                                width="125px"
                              />
                            </tr>
                          }
                          loading={bookingList.loading}
                          noMargin
                          onClearFilters={() => {
                            setBookingQuery({
                              ...bookingQueryDefault,
                            });
                          }}
                          page={bookingQuery.page}
                          setPageObject={setBookingQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review your bookings"
                    icon="timeline-events"
                    title="Bookings"
                  />

                  {/* Timesheets */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddTimesheetDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={timesheetList.timesheets.map((timesheet) => {
                            return (
                              <tr
                                key={timesheet.id}
                                onClick={() => {
                                  dispatch(
                                    listTimesheetDetailsAction(timesheet.id)
                                  );

                                  setTimesheetEntryQuery({
                                    page: 1,
                                    timesheet: timesheet.id,
                                  });

                                  setUpdateTimesheetDrawerIsOpen(true);
                                }}
                              >
                                {/* Week */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TableText text={timesheet.week_label} />

                                    <div style={{ marginLeft: "5px" }}>
                                      <Button
                                        icon="duplicate"
                                        minimal
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          dispatch(
                                            duplicateTimesheetAction(
                                              timesheet.id
                                            )
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>

                                {/* Submitted */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={timesheet.submitted} />
                                </td>

                                {/* Reviewed */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={timesheet.completed} />
                                </td>

                                {/* Status */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      timesheet.status === "Approved"
                                        ? "success"
                                        : timesheet.status === "Draft"
                                        ? "primary"
                                        : timesheet.status === "Rejected"
                                        ? "danger"
                                        : "warning"
                                    }
                                    value={timesheet.status}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !timesheetQuery.completedEnd &&
                            !timesheetQuery.completedStart &&
                            !timesheetQuery.status &&
                            !timesheetQuery.submittedEnd &&
                            !timesheetQuery.submittedStart &&
                            !timesheetQuery.weekLabel
                          }
                          count={timesheetList.count}
                          head={
                            <tr>
                              {/* Week */}
                              <TableColumnHeadSearch
                                queryObject={timesheetQuery}
                                queryVariable="weekLabel"
                                setQueryObject={setTimesheetQuery}
                                setQueryVariable="weekLabel"
                                title="Week"
                                width="200px"
                              />

                              {/* Submitted */}
                              <TableColumnHeadDate
                                endDateVariable="submittedEnd"
                                queryObject={timesheetQuery}
                                setQueryObject={setTimesheetQuery}
                                shortRange
                                startDateVariable="submittedStart"
                                title="Submitted"
                                width="125px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="completedEnd"
                                queryObject={timesheetQuery}
                                setQueryObject={setTimesheetQuery}
                                shortRange
                                startDateVariable="completedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={timesheetQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "document-share",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "tick",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Rejected",
                                    icon: "cross",
                                    value: "Rejected",
                                  },
                                  {
                                    display: "Reversed",
                                    icon: "undo",
                                    value: "Reversed",
                                  },
                                ]}
                                setFilterObject={setTimesheetQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={timesheetList.loading}
                          noMargin
                          onClearFilters={() => {
                            setTimesheetQuery({
                              ...timesheetQueryDefault,
                            });
                          }}
                          page={timesheetQuery.page}
                          setPageObject={setTimesheetQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Submit timesheets"
                    icon="stopwatch"
                    title="Timesheets"
                  />

                  {/* Expenses */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddClaimDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={claimList.claims.map((claim) => {
                            return (
                              <tr
                                key={claim.id}
                                onClick={() => {
                                  dispatch(listClaimDetailsAction(claim.id));

                                  setExpenseQuery({
                                    claim: claim.id,
                                    organisation: activeOrganisation.id,
                                    page: 1,
                                  });

                                  setUpdateClaimDrawerIsOpen(true);
                                }}
                              >
                                {/* Title */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TableText text={claim.name} />

                                    <div style={{ marginLeft: "5px" }}>
                                      <Button
                                        icon="duplicate"
                                        minimal
                                        onClick={(e) => {
                                          e.stopPropagation();

                                          dispatch(
                                            duplicateClaimAction(claim.id)
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </td>

                                {/* Submitted */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={claim.submitted} />
                                </td>

                                {/* Reviewed */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableDate date={claim.completed} />
                                </td>

                                {/* Status */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      claim.status === "Approved"
                                        ? "success"
                                        : claim.status === "Draft"
                                        ? "primary"
                                        : claim.status === "Paid"
                                        ? "success"
                                        : claim.status === "Rejected"
                                        ? "danger"
                                        : "warning"
                                    }
                                    value={claim.status}
                                  />
                                </td>

                                {/* Value */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={claim.value} />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !claimQuery.completedEnd &&
                            !claimQuery.completedStart &&
                            !claimQuery.searchString &&
                            !claimQuery.status &&
                            !claimQuery.submittedEnd &&
                            !claimQuery.submittedStart
                          }
                          count={claimList.count}
                          footnote={
                            <div>Figures in {activeOrganisation.currency}</div>
                          }
                          head={
                            <tr>
                              {/* Claim */}
                              <TableColumnHeadSearch
                                queryObject={claimQuery}
                                queryVariable="searchString"
                                setQueryObject={setClaimQuery}
                                setQueryVariable="searchString"
                                title="Claim"
                                width="200px"
                              />

                              {/* Submitted */}
                              <TableColumnHeadDate
                                endDateVariable="submittedEnd"
                                queryObject={claimQuery}
                                setQueryObject={setClaimQuery}
                                shortRange
                                startDateVariable="submittedStart"
                                title="Submitted"
                                width="125px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="completedEnd"
                                queryObject={claimQuery}
                                setQueryObject={setClaimQuery}
                                shortRange
                                startDateVariable="completedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={claimQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "document-share",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "tick",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Paid",
                                    icon: "bank-account",
                                    value: "Paid",
                                  },
                                  {
                                    display: "Rejected",
                                    icon: "cross",
                                    value: "Rejected",
                                  },
                                  {
                                    display: "Reversed",
                                    icon: "undo",
                                    value: "Reversed",
                                  },
                                ]}
                                setFilterObject={setClaimQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={claimQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "id",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-id",
                                  },
                                ]}
                                setFilterObject={setClaimQuery}
                                title="Value"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={claimList.loading}
                          noMargin
                          onClearFilters={() => {
                            setClaimQuery({
                              ...claimQueryDefault,
                            });
                          }}
                          page={claimQuery.page}
                          setPageObject={setClaimQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Submit expense claims"
                    icon="bank-account"
                    title="Expenses"
                  />

                  {/* Leave requests */}
                  <TabSection2
                    buttons={
                      <Button
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddLeaveRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={leaveRequestList.leave_requests.map(
                            (leave_request) => {
                              return (
                                <tr
                                  key={leave_request.id}
                                  onClick={() => {
                                    dispatch(
                                      listLeaveRequestDetailsAction(
                                        leave_request.id
                                      )
                                    );

                                    setUpdateLeaveRequestDrawerIsOpen(true);
                                  }}
                                >
                                  {/* Week */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={leave_request.week_label}
                                    />
                                  </td>

                                  {/* Type */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={leave_request.category_name}
                                    />
                                  </td>

                                  {/* Submitted */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={leave_request.submitted} />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate date={leave_request.completed} />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        leave_request.status === "Approved"
                                          ? "success"
                                          : leave_request.status === "Draft"
                                          ? "primary"
                                          : leave_request.status === "Rejected"
                                          ? "danger"
                                          : "warning"
                                      }
                                      value={leave_request.status}
                                    />
                                  </td>

                                  {/* Time */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableTag
                                      value={`${
                                        convertMinutesToHoursAndMinutes(
                                          leave_request.total_minutes
                                        ).split(":")[0]
                                      }h ${
                                        convertMinutesToHoursAndMinutes(
                                          leave_request.total_minutes
                                        ).split(":")[1]
                                      }m`}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !leaveRequestQuery.category &&
                            !leaveRequestQuery.completedEnd &&
                            !leaveRequestQuery.completedStart &&
                            !leaveRequestQuery.status &&
                            !leaveRequestQuery.submittedEnd &&
                            !leaveRequestQuery.submittedStart &&
                            !leaveRequestQuery.weekLabel
                          }
                          count={leaveRequestList.count}
                          footer={
                            <tr>
                              <td
                                colSpan="5"
                                style={{
                                  textAlign: "end",
                                  verticalAlign: "middle",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <div>Total</div>

                                  <Tag
                                    minimal
                                    style={{
                                      margin: "0 0 0 10px",
                                      textAlign: "center",
                                      width: "50px",
                                    }}
                                  >
                                    Hours
                                  </Tag>
                                </div>
                              </td>

                              {/* Total */}
                              <td
                                style={{
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                {leaveRequestList.totalMinutes
                                  ? `${
                                      convertMinutesToHoursAndMinutes(
                                        leaveRequestList.totalMinutes
                                      ).split(":")[0]
                                    }h ${
                                      convertMinutesToHoursAndMinutes(
                                        leaveRequestList.totalMinutes
                                      ).split(":")[1]
                                    }m`
                                  : "-"}
                              </td>
                            </tr>
                          }
                          head={
                            <tr>
                              {/* Week */}
                              <TableColumnHeadSearch
                                queryObject={leaveRequestQuery}
                                queryVariable="weekLabel"
                                setQueryObject={setLeaveRequestQuery}
                                setQueryVariable="weekLabel"
                                title="Week"
                                width="200px"
                              />

                              {/* Type */}
                              <TableColumnHeadSelect2
                                filterObject={leaveRequestQuery}
                                filterVariable="category"
                                items={leaveCategoryList.leave_categories}
                                setFilterObject={setLeaveRequestQuery}
                                setQueryObject={setLeaveCategoryQuery}
                                setQueryVariable="searchString"
                                title="Type"
                                width="200px"
                              />

                              {/* Submitted */}
                              <TableColumnHeadDate
                                endDateVariable="submittedEnd"
                                queryObject={leaveRequestQuery}
                                setQueryObject={setLeaveRequestQuery}
                                shortRange
                                startDateVariable="submittedStart"
                                title="Submitted"
                                width="125px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="completedEnd"
                                queryObject={leaveRequestQuery}
                                setQueryObject={setLeaveRequestQuery}
                                shortRange
                                startDateVariable="completedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={leaveRequestQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Draft",
                                    icon: "manually-entered-data",
                                    value: "Draft",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "document-share",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "tick",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Rejected",
                                    icon: "cross",
                                    value: "Rejected",
                                  },
                                  {
                                    display: "Cancelled",
                                    icon: "undo",
                                    value: "Cancelled",
                                  },
                                ]}
                                setFilterObject={setLeaveRequestQuery}
                                title="Status"
                                width="125px"
                              />

                              {/* Time */}
                              <TableColumnHeadSelectSimple
                                filterObject={leaveRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "total_minutes",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-total_minutes",
                                  },
                                ]}
                                setFilterObject={setLeaveRequestQuery}
                                title="Time"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={leaveRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setLeaveRequestQuery({
                              ...leaveRequestQueryDefault,
                            });
                          }}
                          page={leaveRequestQuery.page}
                          setPageObject={setLeaveRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Submit leave requests"
                    icon="key-escape"
                    title="Leave requests"
                  />

                  {/* Decisions */}
                  <TabSection2
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalResponseList.approval_responses.map(
                            (approval_response) => {
                              return (
                                <tr
                                  key={approval_response.id}
                                  onClick={() => {
                                    dispatch(
                                      listApprovalResponseDetailsAction(
                                        approval_response.id
                                      )
                                    );

                                    setUpdateApprovalResponseDrawerIsOpen(true);
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={
                                        approval_response.request_data
                                          .decision_right
                                          ? approval_response.request_data
                                              .decision_right
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={
                                        approval_response.request_data
                                          .decision_right
                                          ? approval_response.request_data
                                              .decision_right ===
                                              "Staff leave" ||
                                            approval_response.request_data
                                              .decision_right ===
                                              "Staff timesheet"
                                            ? approval_response.value / 60
                                            : approval_response.value
                                          : undefined
                                      }
                                      tagText={
                                        approval_response.request_data
                                          .decision_right
                                          ? approval_response.request_data
                                              .decision_right ===
                                              "Staff leave" ||
                                            approval_response.request_data
                                              .decision_right ===
                                              "Staff timesheet"
                                            ? "Hours"
                                            : activeOrganisation.currency
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Requestor */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={
                                        approval_response.request_data
                                          ? approval_response.request_data
                                              .requestor_name
                                          : undefined
                                      }
                                      image={
                                        approval_response.request_data
                                          ? approval_response.request_data
                                              .requestor_profile_photo
                                          : undefined
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={approval_response.reviewed}
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_response.outcome === "Approved"
                                          ? "success"
                                          : approval_response.outcome ===
                                            "Denied"
                                          ? "danger"
                                          : "primary"
                                      }
                                      value={approval_response.outcome}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalResponseQuery.decisionRight &&
                            !approvalResponseQuery.outcome &&
                            !approvalResponseQuery.requestor &&
                            !approvalResponseQuery.reviewedEnd &&
                            !approvalResponseQuery.reviewedStart
                          }
                          count={approvalResponseList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalResponseQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Client account plan",
                                    value: "Client account plan",
                                  },
                                  /*{
                                        display: "Client survey",
                                        value: "Client survey",
                                      },*/
                                  {
                                    display: "Engagement baseline",
                                    value: "Engagement baseline",
                                  },
                                  /*{
                                        display: "Engagement deliverable",
                                        value: "Engagement deliverable",
                                      },*/
                                  {
                                    display: "Engagement close",
                                    value: "Engagement close",
                                  },
                                  {
                                    display: "Invoice",
                                    value: "Invoice",
                                  },
                                  {
                                    display: "Proposal qualification",
                                    value: "Proposal qualification",
                                  },
                                  /*{
                                        display: "Proposal strategy",
                                        value: "Proposal strategy",
                                      },*/
                                  /*{
                                        display: "Proposal solution",
                                        value: "Proposal solution",
                                      },*/
                                  /*{
                                        display: "Proposal commercials",
                                        value: "Proposal commercials",
                                      },*/
                                  {
                                    display: "Proposal submission",
                                    value: "Proposal submission",
                                  },
                                  {
                                    display: "Staff expense",
                                    value: "Staff expense",
                                  },
                                  {
                                    display: "Staff leave",
                                    value: "Staff leave",
                                  },
                                  {
                                    display: "Staff recruitment",
                                    value: "Staff recruitment",
                                  },
                                  {
                                    display: "Staff timesheet",
                                    value: "Staff timesheet",
                                  },
                                ]}
                                setFilterObject={setApprovalResponseQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalResponseQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalResponseQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Requestor */}
                              <TableColumnHeadSelect2
                                filterObject={approvalResponseQuery}
                                filterVariable="requestor"
                                items={staffList.staff}
                                setFilterObject={setApprovalResponseQuery}
                                setQueryObject={setStaffQuery}
                                setQueryVariable="searchString"
                                title="Requestor"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalResponseQuery}
                                setQueryObject={setApprovalResponseQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalResponseQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                ]}
                                setFilterObject={setApprovalResponseQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalResponseList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalResponseQuery({
                              ...approvalResponseQueryDefault,
                            });
                          }}
                          page={approvalResponseQuery.page}
                          setPageObject={setApprovalResponseQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Respond to approval requests that you have received"
                    icon="book"
                    title="Decisions"
                  />
                </div>
              }
            />

            {/* Test */}
            {false && (
              <Tab
                id="dashboard-test-tab"
                title="Test"
                panel={
                  <div
                    style={{
                      height: "calc(100vh - 150px)",
                      padding: "0 15px 0 15px",
                      overflowY: "auto",
                    }}
                  ></div>
                }
              />
            )}
          </Tabs>
        }
      />
    </div>
  );
}

export default Dashboard;
