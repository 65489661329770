// Constant imports
import {
  NEED_LIST_REQUEST,
  NEED_LIST_SUCCESS,
  NEED_LIST_FAIL,
  NEED_LIST_RESET,
  NEED_CREATE_REQUEST,
  NEED_CREATE_SUCCESS,
  NEED_CREATE_FAIL,
  NEED_CREATE_RESET,
  NEED_DETAILS_REQUEST,
  NEED_DETAILS_SUCCESS,
  NEED_DETAILS_FAIL,
  NEED_DETAILS_RESET,
  NEED_UPDATE_REQUEST,
  NEED_UPDATE_SUCCESS,
  NEED_UPDATE_FAIL,
  NEED_UPDATE_RESET,
  NEED_DELETE_REQUEST,
  NEED_DELETE_SUCCESS,
  NEED_DELETE_FAIL,
  NEED_DELETE_RESET,
} from "../constants/needConstants";

// List all
export const needListReducer = (
  state = {
    loading: false,
    count: 0,
    needs: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NEED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NEED_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        needs: action.payload.needs,
        success: true,
        error: "",
      };

    case NEED_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        needs: [],
        success: false,
        error: action.payload,
      };

    case NEED_LIST_RESET:
      return {
        loading: false,
        count: 0,
        needs: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const needCreateReducer = (
  state = { loading: false, need: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case NEED_CREATE_REQUEST:
      return {
        loading: true,
        need: state.need,
        success: false,
        error: "",
      };

    case NEED_CREATE_SUCCESS:
      return {
        loading: false,
        need: action.payload,
        success: true,
        error: "",
      };

    case NEED_CREATE_FAIL:
      return {
        loading: false,
        need: state.need,
        success: false,
        error: action.payload,
      };

    case NEED_CREATE_RESET:
      return { loading: false, need: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const needDetailsReducer = (
  state = { loading: false, need: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case NEED_DETAILS_REQUEST:
      return {
        loading: true,
        need: state.need,
        success: false,
        error: "",
      };

    case NEED_DETAILS_SUCCESS:
      return {
        loading: false,
        need: action.payload,
        success: true,
        error: "",
      };

    case NEED_DETAILS_FAIL:
      return {
        loading: false,
        need: state.need,
        success: false,
        error: action.payload,
      };

    case NEED_DETAILS_RESET:
      return { loading: false, need: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const needUpdateReducer = (
  state = { loading: false, need: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case NEED_UPDATE_REQUEST:
      return {
        loading: true,
        need: state.need,
        success: false,
        error: "",
      };

    case NEED_UPDATE_SUCCESS:
      return {
        loading: false,
        need: action.payload,
        success: true,
        error: "",
      };

    case NEED_UPDATE_FAIL:
      return {
        loading: false,
        need: state.need,
        success: false,
        error: action.payload,
      };

    case NEED_UPDATE_RESET:
      return { loading: false, need: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const needDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case NEED_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case NEED_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case NEED_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case NEED_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
