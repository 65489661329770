export const OPENING_APPLICATION_CONFIGURATION_DETAILS_REQUEST =
  "OPENING_APPLICATION_CONFIGURATION_DETAILS_REQUEST";
export const OPENING_APPLICATION_CONFIGURATION_DETAILS_SUCCESS =
  "OPENING_APPLICATION_CONFIGURATION_DETAILS_SUCCESS";
export const OPENING_APPLICATION_CONFIGURATION_DETAILS_FAIL =
  "OPENING_APPLICATION_CONFIGURATION_DETAILS_FAIL";
export const OPENING_APPLICATION_CONFIGURATION_DETAILS_RESET =
  "OPENING_APPLICATION_CONFIGURATION_DETAILS_RESET";
export const OPENING_APPLICATION_CONFIGURATION_UPDATE_REQUEST =
  "OPENING_APPLICATION_CONFIGURATION_UPDATE_REQUEST";
export const OPENING_APPLICATION_CONFIGURATION_UPDATE_SUCCESS =
  "OPENING_APPLICATION_CONFIGURATION_UPDATE_SUCCESS";
export const OPENING_APPLICATION_CONFIGURATION_UPDATE_FAIL =
  "OPENING_APPLICATION_CONFIGURATION_UPDATE_FAIL";
export const OPENING_APPLICATION_CONFIGURATION_UPDATE_RESET =
  "OPENING_APPLICATION_CONFIGURATION_UPDATE_RESET";
export const OPENING_APPLICATION_CONFIGURATION_APPLY_REQUEST =
  "OPENING_APPLICATION_CONFIGURATION_APPLY_REQUEST";
export const OPENING_APPLICATION_CONFIGURATION_APPLY_SUCCESS =
  "OPENING_APPLICATION_CONFIGURATION_APPLY_SUCCESS";
export const OPENING_APPLICATION_CONFIGURATION_APPLY_FAIL =
  "OPENING_APPLICATION_CONFIGURATION_APPLY_FAIL";
export const OPENING_APPLICATION_CONFIGURATION_APPLY_RESET =
  "OPENING_APPLICATION_CONFIGURATION_APPLY_RESET";
