// Constant imports
import {
  ENGAGEMENT_MILESTONE_LIST_REQUEST,
  ENGAGEMENT_MILESTONE_LIST_SUCCESS,
  ENGAGEMENT_MILESTONE_LIST_FAIL,
  ENGAGEMENT_MILESTONE_LIST_RESET,
  ENGAGEMENT_MILESTONE_CREATE_REQUEST,
  ENGAGEMENT_MILESTONE_CREATE_SUCCESS,
  ENGAGEMENT_MILESTONE_CREATE_FAIL,
  ENGAGEMENT_MILESTONE_CREATE_RESET,
  ENGAGEMENT_MILESTONE_DETAILS_REQUEST,
  ENGAGEMENT_MILESTONE_DETAILS_SUCCESS,
  ENGAGEMENT_MILESTONE_DETAILS_FAIL,
  ENGAGEMENT_MILESTONE_DETAILS_RESET,
  ENGAGEMENT_MILESTONE_UPDATE_REQUEST,
  ENGAGEMENT_MILESTONE_UPDATE_SUCCESS,
  ENGAGEMENT_MILESTONE_UPDATE_FAIL,
  ENGAGEMENT_MILESTONE_UPDATE_RESET,
  ENGAGEMENT_MILESTONE_DELETE_REQUEST,
  ENGAGEMENT_MILESTONE_DELETE_SUCCESS,
  ENGAGEMENT_MILESTONE_DELETE_FAIL,
  ENGAGEMENT_MILESTONE_DELETE_RESET,
} from "../constants/engagementMilestoneConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementMilestonesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_MILESTONE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementMilestones(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_MILESTONE_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfMilestonesCompleted: data.number_of_milestones_completed,
          numberOfMilestonesInvoiced: data.number_of_milestones_invoiced,
          numberOfMilestonesNotCompleted:
            data.number_of_milestones_not_completed,
          numberOfMilestonesPaid: data.number_of_milestones_paid,
          totalValueOfMilestones: data.total_value_of_milestones.result,
          totalValueOfMilestonesQuote: data.total_value_of_milestones_quote.result,
          valueOfMilestonesCompleted: data.value_of_milestones_completed.result,
          valueOfMilestonesInvoiced: data.value_of_milestones_invoiced.result,
          valueOfMilestonesNotCompleted:
            data.value_of_milestones_not_completed.result,
          valueOfMilestonesPaid: data.value_of_milestones_paid.result,
          engagement_milestones: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_MILESTONE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementMilestonesAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_MILESTONE_LIST_RESET });
};

// Create
export const createEngagementMilestoneAction =
  (engagement_milestone) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_MILESTONE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementMilestone(
        token,
        engagement_milestone
      );

      dispatch({
        type: ENGAGEMENT_MILESTONE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_MILESTONE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementMilestoneAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_MILESTONE_CREATE_RESET });
};

// List details
export const listEngagementMilestoneDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_MILESTONE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementMilestone(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_MILESTONE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_MILESTONE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementMilestoneDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_MILESTONE_DETAILS_RESET });
  };

// Update
export const updateEngagementMilestoneAction =
  (id, engagement_milestone) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_MILESTONE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementMilestone(
        id,
        token,
        engagement_milestone
      );

      dispatch({
        type: ENGAGEMENT_MILESTONE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_MILESTONE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementMilestoneAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_MILESTONE_UPDATE_RESET });
};

// Delete
export const deleteEngagementMilestoneAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_MILESTONE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementMilestone(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_MILESTONE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_MILESTONE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementMilestoneAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_MILESTONE_DELETE_RESET });
};
