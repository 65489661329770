// Main function
const setTextColour = (hexcolour) => {
  hexcolour = hexcolour.replace("#", "");

  let r = parseInt(hexcolour.substr(0, 2), 16);
  let g = parseInt(hexcolour.substr(2, 2), 16);
  let b = parseInt(hexcolour.substr(4, 2), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? "black" : "white";
};

export default setTextColour;
