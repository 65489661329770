// Constant imports
import {
  CURRENCY_EXCHANGE_RATE_LIST_REQUEST,
  CURRENCY_EXCHANGE_RATE_LIST_SUCCESS,
  CURRENCY_EXCHANGE_RATE_LIST_FAIL,
  CURRENCY_EXCHANGE_RATE_LIST_RESET,
  CURRENCY_EXCHANGE_RATE_CREATE_REQUEST,
  CURRENCY_EXCHANGE_RATE_CREATE_SUCCESS,
  CURRENCY_EXCHANGE_RATE_CREATE_FAIL,
  CURRENCY_EXCHANGE_RATE_CREATE_RESET,
  CURRENCY_EXCHANGE_RATE_DETAILS_REQUEST,
  CURRENCY_EXCHANGE_RATE_DETAILS_SUCCESS,
  CURRENCY_EXCHANGE_RATE_DETAILS_FAIL,
  CURRENCY_EXCHANGE_RATE_DETAILS_RESET,
  CURRENCY_EXCHANGE_RATE_UPDATE_REQUEST,
  CURRENCY_EXCHANGE_RATE_UPDATE_SUCCESS,
  CURRENCY_EXCHANGE_RATE_UPDATE_FAIL,
  CURRENCY_EXCHANGE_RATE_UPDATE_RESET,
  CURRENCY_EXCHANGE_RATE_DELETE_REQUEST,
  CURRENCY_EXCHANGE_RATE_DELETE_SUCCESS,
  CURRENCY_EXCHANGE_RATE_DELETE_FAIL,
  CURRENCY_EXCHANGE_RATE_DELETE_RESET,
} from "../constants/currencyExchangeRateConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listCurrencyExchangeRatesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_EXCHANGE_RATE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCurrencyExchangeRates(
        params,
        token
      );

      dispatch({
        type: CURRENCY_EXCHANGE_RATE_LIST_SUCCESS,

        payload: {
          count: data.count,
          currency_exchange_rates: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_EXCHANGE_RATE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListCurrencyExchangeRatesAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_EXCHANGE_RATE_LIST_RESET });
};

// Create
export const createCurrencyExchangeRateAction =
  (currency_exchange_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_EXCHANGE_RATE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createCurrencyExchangeRate(
        token,
        currency_exchange_rate
      );

      dispatch({
        type: CURRENCY_EXCHANGE_RATE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_EXCHANGE_RATE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateCurrencyExchangeRateAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_EXCHANGE_RATE_CREATE_RESET });
};

// List details
export const listCurrencyExchangeRateDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_EXCHANGE_RATE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCurrencyExchangeRate(
        id,
        token
      );

      dispatch({
        type: CURRENCY_EXCHANGE_RATE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_EXCHANGE_RATE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListCurrencyExchangeRateDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: CURRENCY_EXCHANGE_RATE_DETAILS_RESET });
  };

// Update
export const updateCurrencyExchangeRateAction =
  (id, currency_exchange_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_EXCHANGE_RATE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateCurrencyExchangeRate(
        id,
        token,
        currency_exchange_rate
      );

      dispatch({
        type: CURRENCY_EXCHANGE_RATE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_EXCHANGE_RATE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateCurrencyExchangeRateAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_EXCHANGE_RATE_UPDATE_RESET });
};

// Delete
export const deleteCurrencyExchangeRateAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENCY_EXCHANGE_RATE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteCurrencyExchangeRate(
        id,
        token
      );

      dispatch({
        type: CURRENCY_EXCHANGE_RATE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CURRENCY_EXCHANGE_RATE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteCurrencyExchangeRateAction = () => async (dispatch) => {
  dispatch({ type: CURRENCY_EXCHANGE_RATE_DELETE_RESET });
};
