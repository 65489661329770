// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function ServiceProposalAverageMarginCard({ fill, serviceList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Margin</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Average service gross margin priced into proposals closed. These are
            proposals that have been won, lost, or cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {serviceList.averageMarginPercentageOnProposalsWon >=
                  serviceList.averageMarginPercentageOnProposalsLost &&
                serviceList.averageMarginPercentageOnProposalsWon >=
                  serviceList.averageMarginPercentageOnProposalsCancelled ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      On average, the service margin on successful proposals is
                      better than on unsuccessful ones for the selected
                      service(s). Clients may associate higher fees with
                      greater service quality. Consider raising your prices.
                    </div>
                  </div>
                ) : serviceList.averageMarginPercentageOnProposalsWon >=
                    serviceList.averageMarginPercentageOnProposalsLost &&
                  serviceList.averageMarginPercentageOnProposalsWon <
                    serviceList.averageMarginPercentageOnProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The service margin on successful proposals is lower than
                      on cancelled opportunities for the selected service(s). If
                      organisations are cancelling initiatives due to
                      affordability concerns, you may have an opportunity to
                      develop solutions targeted at price-sensitive clients.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      On average, the rates on proposals won is lower than
                      proposals lost for the selected service(s). You may need
                      to improve your value proposition, or adjust your service
                      fees to match market expectations.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: serviceList.averageMarginPercentageOnProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: serviceList.averageMarginPercentageOnProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: serviceList.averageMarginPercentageOnProposalsCancelled,
        },
      ]}
      fill={fill}
      noData={
        !serviceList.averageMarginPercentageOnProposalsCancelled &&
        !serviceList.averageMarginPercentageOnProposalsLost &&
        !serviceList.averageMarginPercentageOnProposalsWon
      }
      title="Margin"
      units="Percentage"
    />
  );
}

export default ServiceProposalAverageMarginCard;
