// Library imports
import {
  Card,
  Classes,
  Colors,
  Divider,
  H5,
  InputGroup,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { createRef, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { VariableSizeList as List, areEqual } from "react-window";

// Component imports
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";

// Utility imports
import setTextColour from "../utilities/setTextColour";

// Main function
function Kanban({
  centerColumns,
  columnWidth,
  data,
  darkColor,
  height,
  initialise,
  lightColor,
  noMargin,
  onClick,
  onDragEnd,
  searchBarMargin,
  searchBarPlaceholder,
  setInitialise,
  statusCategories,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // List navigation
  const [listRefs, setListRefs] = useState([]);

  useEffect(() => {
    setListRefs((oldRefs) =>
      Array(statusCategories.length)
        .fill()
        .map((_, i) => oldRefs[i] || createRef())
    ); // Reuses old refs if they exist, so it won’t cause unnecessary re-renders
  }, [statusCategories]);

  // Initialise items
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (data && initialise) {
      setItems(data);

      setInitialise(false);
    }
  }, [data, initialise, setInitialise]);

  useEffect(() => {
    if (items) {
      listRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.resetAfterIndex(0);
          ref.current.scrollToItem(0, "start");
        }
      });
    }
  }, [items, listRefs]);

  // Filter items
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (data) {
      const filteredItems = data.filter(
        (item) =>
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          (item.owner
            ? item.owner.toLowerCase().includes(search.toLowerCase())
            : false)
      );

      setItems(filteredItems);
    }
    // eslint-disable-next-line
  }, [search]);

  // Update item status
  const handleOnDragEnd = (result) => {
    const { destination, draggableId, source } = result;

    /*console.log(
      `item id: ${draggableId} | destination: ${destination.droppableId}`
    );*/

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    let newItems = [];

    items.forEach((item) => {
      item.id === draggableId
        ? newItems.push({
            ...item,
            status: destination.droppableId,
          })
        : newItems.push(item);
    });

    setItems(newItems);

    onDragEnd && onDragEnd(result);
  };

  // Item
  const Item = ({ item }) => {
    return (
      <ItemCard
        content={
          <div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Tag
                minimal
                style={{
                  background: item.color ? item.color : undefined,
                  color: item.color ? setTextColour(item.color) : undefined,
                  marginRight: "5px",
                }}
              >
                {item.category}
              </Tag>

              <InfoPopup
                content={
                  <>
                    <H5>{item.name}</H5>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <p>
                      <strong>Action</strong>
                    </p>

                    <p>{item.action}</p>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <p>
                      <strong>Due</strong>
                    </p>

                    <p>{item.due ? new Date(item.due).toDateString() : "-"}</p>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <p>
                      <strong>Comments</strong>
                    </p>

                    <p>
                      {item.comments ? (
                        item.comments
                      ) : (
                        <span className={Classes.TEXT_MUTED}>No comments.</span>
                      )}
                    </p>
                  </>
                }
              />
            </div>

            {item.owner && (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                <img
                  alt="profile"
                  src={
                    item.ownerPhoto ? item.ownerPhoto : "/images/no_photo.png"
                  }
                  style={{
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: "50%",
                    height: "40px",
                    margin: "0 10px 0 0",
                    objectFit: "contain",
                    width: "40px",
                  }}
                />

                <Text ellipsize>{item.owner}</Text>
              </div>
            )}
          </div>
        }
        darkColor={item.color ? item.color : undefined}
        height={item.owner ? 155 : 95}
        interactive
        lightColor={item.color ? item.color : undefined}
        noScroll
        onClick={onClick ? () => onClick(item.id) : undefined}
        title={item.name}
        width="95%"
      />
    );
  };

  // Row
  const Row = React.memo(({ data, index, style }) => {
    const item = data[index];

    // We are rendering an extra item for the placeholder
    if (!item) {
      return null;
    }

    return (
      <Draggable draggableId={item.id} index={index} key={item.id}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
            >
              <Item item={item} />
            </div>
          );
        }}
      </Draggable>
    );
  }, areEqual);

  // JSX UI code
  return (
    <div
      style={{
        background: lightMode
          ? lightColor
            ? lightColor
            : Colors.LIGHT_GRAY3
          : darkColor
          ? darkColor
          : Colors.DARK_GRAY5,
        margin: noMargin ? "0" : "20px",
        padding: "15px",
      }}
    >
      {/* Search */}
      <div style={{ margin: searchBarMargin, width: "300px" }}>
        <InputGroup
          leftIcon="search"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder={searchBarPlaceholder}
          type="search"
          value={search}
        />
      </div>

      {/* Kanban */}
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div
          style={{
            display: "flex",
            justifyContent: centerColumns ? "center" : undefined,
            overflowX: "auto",
          }}
        >
          {/* Columns */}
          {statusCategories.map(
            (statusCategory, statusCategoryIndex, { length }) => {
              return (
                <Card
                  className={statusCategory.value}
                  key={statusCategoryIndex}
                  style={{
                    background: lightMode
                      ? Colors.LIGHT_GRAY5
                      : Colors.DARK_GRAY4,
                    margin:
                      statusCategoryIndex === 0
                        ? "1px 10px 1px 1px"
                        : statusCategoryIndex === length - 1
                        ? "1px 1px 1px 10px"
                        : "1px 10px 1px 10px",
                    maxWidth: columnWidth ? columnWidth : undefined,
                    minWidth: columnWidth ? columnWidth : "330px",
                    padding: "20px 10px 20px 10px",
                    width: "100%",
                  }}
                >
                  {/* Category name */}
                  <div style={{ margin: "0 0 20px 0", textAlign: "center" }}>
                    <Tag
                      intent={statusCategory.intent}
                      minimal
                      style={{
                        background: statusCategory.color
                          ? statusCategory.color
                          : undefined,
                        color: statusCategory.color
                          ? setTextColour(statusCategory.color)
                          : undefined,
                      }}
                    >
                      {statusCategory.value}
                    </Tag>
                  </div>

                  {/* Items */}
                  <Droppable
                    droppableId={statusCategory.value}
                    mode="virtual"
                    renderClone={(provided, snapshot, rubric) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className={lightMode ? undefined : Classes.DARK}
                          style={{
                            transform: "rotate(3deg)",
                          }}
                        >
                          <Item
                            item={
                              items.filter(
                                (item) => item.id === rubric.draggableId
                              )[0]
                            }
                          />
                        </div>
                      </div>
                    )}
                  >
                    {(provided, snapshot) => {
                      // Add an extra item to our list to make space for a dragging item
                      // Usually the DroppableProvided.placeholder does this, but that won't
                      // work in a virtual list
                      const filteredItems = items.filter(
                        (item) => item.status === statusCategory.value
                      );

                      const itemCount = snapshot.isUsingPlaceholder
                        ? filteredItems.length + 1
                        : filteredItems.length;

                      return (
                        <List
                          estimatedItemSize={105}
                          height={height ? height : 500}
                          itemCount={itemCount}
                          itemData={filteredItems}
                          itemSize={(index) => {
                            const item = filteredItems[index];

                            if (!item) {
                              return 105;
                            }

                            if (item.owner) {
                              return 165;
                            }

                            return 105;
                          }}
                          outerRef={provided.innerRef}
                          ref={listRefs[statusCategoryIndex]}
                        >
                          {Row}
                        </List>
                      );
                    }}
                  </Droppable>
                </Card>
              );
            }
          )}
        </div>
      </DragDropContext>
    </div>
  );
}

export default Kanban;
