export const STAFF_GOAL_LIST_REQUEST = "STAFF_GOAL_LIST_REQUEST";
export const STAFF_GOAL_LIST_SUCCESS = "STAFF_GOAL_LIST_SUCCESS";
export const STAFF_GOAL_LIST_FAIL = "STAFF_GOAL_LIST_FAIL";
export const STAFF_GOAL_LIST_RESET = "STAFF_GOAL_LIST_RESET";

export const STAFF_GOAL_CREATE_REQUEST = "STAFF_GOAL_CREATE_REQUEST";
export const STAFF_GOAL_CREATE_SUCCESS = "STAFF_GOAL_CREATE_SUCCESS";
export const STAFF_GOAL_CREATE_FAIL = "STAFF_GOAL_CREATE_FAIL";
export const STAFF_GOAL_CREATE_RESET = "STAFF_GOAL_CREATE_RESET";

export const STAFF_GOAL_DETAILS_REQUEST = "STAFF_GOAL_DETAILS_REQUEST";
export const STAFF_GOAL_DETAILS_SUCCESS = "STAFF_GOAL_DETAILS_SUCCESS";
export const STAFF_GOAL_DETAILS_FAIL = "STAFF_GOAL_DETAILS_FAIL";
export const STAFF_GOAL_DETAILS_RESET = "STAFF_GOAL_DETAILS_RESET";

export const STAFF_GOAL_UPDATE_REQUEST = "STAFF_GOAL_UPDATE_REQUEST";
export const STAFF_GOAL_UPDATE_SUCCESS = "STAFF_GOAL_UPDATE_SUCCESS";
export const STAFF_GOAL_UPDATE_FAIL = "STAFF_GOAL_UPDATE_FAIL";
export const STAFF_GOAL_UPDATE_RESET = "STAFF_GOAL_UPDATE_RESET";

export const STAFF_GOAL_DELETE_REQUEST = "STAFF_GOAL_DELETE_REQUEST";
export const STAFF_GOAL_DELETE_SUCCESS = "STAFF_GOAL_DELETE_SUCCESS";
export const STAFF_GOAL_DELETE_FAIL = "STAFF_GOAL_DELETE_FAIL";
export const STAFF_GOAL_DELETE_RESET = "STAFF_GOAL_DELETE_RESET";
