// Library imports
import { Button, Colors, FormGroup, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";

// Main function
function FormDrawerSelectSimpleField3({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  intent,
  label,
  labelInfo,
  loading,
  options,
  setValueObject,
  setValueVariable,
  value,
}) {
  // Set active item icon
  const [activeItemIcon, setActiveItemIcon] = useState("");

  useEffect(() => {
    if (options && value) {
      const selectedOption = options.filter((option) => option.value === value);

      selectedOption[0] && setActiveItemIcon(selectedOption[0].icon);
    }
  }, [options, value]);

  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <Select
        disabled={disabled}
        fill
        filterable={false}
        id={fieldName}
        itemRenderer={(item, { handleClick, handleFocus, modifiers }) => {
          return (
            <MenuItem
              disabled={item.disabled}
              icon={item.icon}
              key={item.id}
              onClick={handleClick}
              onFocus={handleFocus}
              selected={modifiers.active}
              text={item.value}
            />
          );
        }}
        items={options}
        onItemSelect={(item) => {
          setValueObject((prevState) => {
            return {
              ...prevState,
              [setValueVariable]: item.value,
            };
          });
        }}
      >
        <Button
          alignText="left"
          disabled={disabled}
          fill
          icon={activeItemIcon}
          intent={
            error
              ? error.response.data[fieldName]
                ? "danger"
                : intent
              : intent
          }
          loading={loading}
          outlined={
            error ? (error.response.data[fieldName] ? false : true) : true
          }
          text={value}
          rightIcon="double-caret-vertical"
        />
      </Select>
    </FormGroup>
  );
}

export default FormDrawerSelectSimpleField3;
