// Constant imports
import {
  MARKET_PERMISSION_LIST_REQUEST,
  MARKET_PERMISSION_LIST_SUCCESS,
  MARKET_PERMISSION_LIST_FAIL,
  MARKET_PERMISSION_LIST_RESET,
  MARKET_PERMISSION_CREATE_REQUEST,
  MARKET_PERMISSION_CREATE_SUCCESS,
  MARKET_PERMISSION_CREATE_FAIL,
  MARKET_PERMISSION_CREATE_RESET,
  MARKET_PERMISSION_DETAILS_REQUEST,
  MARKET_PERMISSION_DETAILS_SUCCESS,
  MARKET_PERMISSION_DETAILS_FAIL,
  MARKET_PERMISSION_DETAILS_RESET,
  MARKET_PERMISSION_UPDATE_REQUEST,
  MARKET_PERMISSION_UPDATE_SUCCESS,
  MARKET_PERMISSION_UPDATE_FAIL,
  MARKET_PERMISSION_UPDATE_RESET,
  MARKET_PERMISSION_DELETE_REQUEST,
  MARKET_PERMISSION_DELETE_SUCCESS,
  MARKET_PERMISSION_DELETE_FAIL,
  MARKET_PERMISSION_DELETE_RESET,
} from "../constants/marketPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listMarketPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketPermissions(
        params,
        token
      );

      dispatch({
        type: MARKET_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          market_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: MARKET_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListMarketPermissionsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_PERMISSION_LIST_RESET });
};

// Create
export const createMarketPermissionAction =
  (market_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createMarketPermission(
        token,
        market_permission
      );

      dispatch({
        type: MARKET_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateMarketPermissionAction = () => async (dispatch) => {
  dispatch({ type: MARKET_PERMISSION_CREATE_RESET });
};

// List details
export const listMarketPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketPermission(id, token);

      dispatch({
        type: MARKET_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListMarketPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: MARKET_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateMarketPermissionAction =
  (id, market_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateMarketPermission(
        id,
        token,
        market_permission
      );

      dispatch({
        type: MARKET_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateMarketPermissionAction = () => async (dispatch) => {
  dispatch({ type: MARKET_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteMarketPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteMarketPermission(
        id,
        token
      );

      dispatch({
        type: MARKET_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteMarketPermissionAction = () => async (dispatch) => {
  dispatch({ type: MARKET_PERMISSION_DELETE_RESET });
};
