// Library imports
import { Button, Card, Colors, Divider, H5, Tag } from "@blueprintjs/core";
import parse, { domToReact } from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Utility imports
import proofRead from "../utilities/proofRead";

// Main function
function ProofReader({ setShow, text, title }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Update
  const [outputHtml, setOutputHtml] = useState("");

  useEffect(() => {
    if (text) {
      setOutputHtml(proofRead(text));
    }
  }, [text]);

  // Style
  const adverbColor = lightMode ? Colors.LIME5 : Colors.LIME2;
  const complexColor = lightMode ? Colors.INDIGO5 : Colors.INDIGO2;
  const hardSentenceColor = lightMode ? Colors.GOLD5 : Colors.ORANGE2;
  const passiveColor = lightMode ? Colors.CERULEAN5 : Colors.CERULEAN2;
  const qualifierColor = lightMode ? Colors.TURQUOISE5 : Colors.TURQUOISE2;
  const veryHardSentenceColor = lightMode
    ? Colors.VERMILION5
    : Colors.VERMILION2;

  const styleOptions = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }

      if (attribs.class === "adverb") {
        return (
          <span style={{ backgroundColor: adverbColor }}>
            {domToReact(children, styleOptions)}
          </span>
        );
      }

      if (attribs.class === "complex") {
        return (
          <span style={{ backgroundColor: complexColor }}>
            {domToReact(children, styleOptions)}
          </span>
        );
      }

      if (attribs.class === "hardSentence") {
        return (
          <span style={{ backgroundColor: hardSentenceColor }}>
            {domToReact(children, styleOptions)}
          </span>
        );
      }

      if (attribs.class === "passive") {
        return (
          <span style={{ backgroundColor: passiveColor }}>
            {domToReact(children, styleOptions)}
          </span>
        );
      }

      if (attribs.class === "qualifier") {
        return (
          <span style={{ backgroundColor: qualifierColor }}>
            {domToReact(children, styleOptions)}
          </span>
        );
      }

      if (attribs.class === "veryHardSentence") {
        return (
          <span style={{ backgroundColor: veryHardSentenceColor }}>
            {domToReact(children, styleOptions)}
          </span>
        );
      }
    },
  };

  // JSX UI code
  return (
    <Card
      style={{
        backgroundColor: lightMode ? Colors.WHITE : Colors.DARK_GRAY2,
        display: "flex",
        flexFlow: "column",
        height: "100%",
      }}
    >
      {/* Title */}
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <H5>{title}</H5>

        <Button
          icon="cross"
          minimal
          onClick={() => {
            setShow(false);
          }}
        />
      </div>

      <Divider />

      {/* Tags */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          margin: "10px 0 20px 0",
        }}
      >
        <Tag
          style={{
            color: lightMode ? Colors.BLACK : Colors.WHITE,
            backgroundColor: qualifierColor,
            margin: "0 5px 5px 0",
          }}
        >
          Remove qualifier
        </Tag>

        <Tag
          style={{
            color: lightMode ? Colors.BLACK : Colors.WHITE,
            backgroundColor: adverbColor,
            margin: "0 5px 5px 0",
          }}
        >
          Remove adverb
        </Tag>

        <Tag
          style={{
            color: lightMode ? Colors.BLACK : Colors.WHITE,
            backgroundColor: passiveColor,
            margin: "0 5px 5px 0",
          }}
        >
          Use active voice
        </Tag>

        <Tag
          style={{
            color: lightMode ? Colors.BLACK : Colors.WHITE,
            backgroundColor: complexColor,
            margin: "0 5px 5px 0",
          }}
        >
          Simplify language
        </Tag>

        <Tag
          style={{
            color: lightMode ? Colors.BLACK : Colors.WHITE,
            backgroundColor: hardSentenceColor,
            margin: "0 5px 5px 0",
          }}
        >
          Shorten sentence
        </Tag>

        <Tag
          style={{
            color: lightMode ? Colors.BLACK : Colors.WHITE,
            backgroundColor: veryHardSentenceColor,
            margin: "0 5px 5px 0",
          }}
        >
          Break up sentence
        </Tag>
      </div>

      {/* Content */}
      <div style={{ flex: 1, overflowY: "auto" }}>
        {parse(outputHtml, styleOptions)}
      </div>

      {/* Buttons */}
      <div
        style={{
          margin: "20px 0 0 0",
          textAlign: "right",
        }}
      >
        <Button
          onClick={() => {
            setShow(false);
          }}
          text="Close"
        />
      </div>
    </Card>
  );
}

export default ProofReader;
