// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import { Button, Card, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ClientEngagementActualsCard from "../components/ClientEngagementActualsCard";
import ClientProposalAverageMarginCard from "../components/ClientProposalAverageMarginCard";
import ClientProposalPipelineCard from "../components/ClientProposalPipelineCard";
import ClientProposalTotalValueCard from "../components/ClientProposalTotalValueCard";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import MarketInfo from "../components/MarketInfo";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableContainer2 from "../components/TableContainer2";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";

// Redux action imports
// Client
import {
  createClientAction,
  listClientsAction,
  resetCreateClientAction,
  resetGetIntelClientAction,
  resetListClientDetailsAction,
} from "../actions/clientActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Market
import { listMarketsAction } from "../actions/marketActions";

// Main function
function ClientListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Client
  const clientCreate = useSelector((state) => state.clientCreate);

  const clientList = useSelector((state) => state.clientList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Market
  const marketList = useSelector((state) => state.marketList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Client
  const [addClientDrawerIsOpen, setAddClientDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Market
  const marketQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [marketQuery, setMarketQuery] = useState({
    ...marketQueryDefault,
  });

  // Form variables
  // Client
  const clientDefault = {
    about: "",
    address: "",
    logo: "",
    logoName: "",
    market: "",
    marketName: "",
    name: "",
    ticker: "",
    website: "",
  };
  const [client, setClient] = useState({
    ...clientDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Client
    setClient({
      ...clientDefault,
    });

    // Query variables
    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Market
    setMarketQuery({
      ...marketQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter markets
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listMarketsAction({
            ...marketQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketQuery]);

  // Form submission
  // Add client
  const addClient = () => {
    // Prepare data
    const client_data = new FormData();

    client.about
      ? client_data.append("about", client.about)
      : client_data.append("about", clientDefault.about);

    client.address
      ? client_data.append("address", client.address)
      : client_data.append("address", clientDefault.address);

    client.logo
      ? client_data.append("logo", client.logo)
      : client_data.append("logo", clientDefault.logo);

    client.market
      ? client_data.append("market", client.market)
      : client_data.append("market", clientDefault.market);

    client.name
      ? client_data.append("name", client.name)
      : client_data.append("name", clientDefault.name);

    client_data.append("organisation", activeOrganisation.id);

    client.ticker
      ? client_data.append("ticker", client.ticker)
      : client_data.append("ticker", clientDefault.ticker);

    client.website
      ? client_data.append("website", client.website)
      : client_data.append("website", clientDefault.website);

    // Dispatch action
    dispatch(createClientAction(client_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Identify the client</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "15px", width: "35%" }}>
                <img
                  alt="profile"
                  src={
                    client.logo
                      ? URL.createObjectURL(new Blob([client.logo]))
                      : "/images/no_logo.png"
                  }
                  style={{
                    aspectRatio: "1/1",
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: "10%",
                    margin: "10px 0 10px 0",
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </div>

              <div style={{ marginLeft: "15px", width: "65%" }}>
                {/* Client name */}
                <FormDrawerTextField2
                  error={clientCreate.error}
                  fieldName="name"
                  icon="office"
                  label="Client name"
                  labelInfo="(required)"
                  placeholder="Enter the client's name"
                  setValueObject={setClient}
                  setValueVariable="name"
                  value={client.name}
                />

                {/* Ticker symbol */}
                <FormDrawerTextField2
                  error={clientCreate.error}
                  fieldName="ticker"
                  helperText="If you enter a ticker, advaise.app will try to populate the client details"
                  icon="label"
                  label="Ticker symbol"
                  placeholder="Enter the client's ticker symbol"
                  setValueObject={setClient}
                  setValueVariable="ticker"
                  value={client.ticker}
                />

                {/* Website */}
                <FormDrawerTextField2
                  error={clientCreate.error}
                  fieldName="website"
                  helperText="Enter the full url"
                  icon="globe-network"
                  label="Website"
                  placeholder="Enter the client's website"
                  setValueObject={setClient}
                  setValueVariable="website"
                  value={client.website}
                />

                {/* Market */}
                <FormDrawerSelectField2
                  error={clientCreate.error}
                  fieldName="market"
                  helperText="You can select an existing market"
                  icon="heatmap"
                  info={
                    <MarketInfo
                      marketId={client.market}
                      query={marketQueryDefault}
                    />
                  }
                  items={marketList.markets}
                  label="Market"
                  loading={marketList.loading}
                  noOptionOnClick
                  placeholder="Select a market"
                  query={marketQuery.searchString}
                  setQueryObject={setMarketQuery}
                  setQueryVariable="searchString"
                  setValueObject={setClient}
                  setValueIdVariable="market"
                  setValueNameVariable="marketName"
                  showInfo={client.market}
                  value={client.marketName}
                />

                {/* Logo */}
                <FormDrawerFileField2
                  error={clientCreate.error}
                  fileName="logo"
                  label="Logo"
                  placeholder="Upload the client's logo"
                  setValueObject={setClient}
                  setValueFileVariable="logo"
                  setValueNameVariable="logoName"
                  valueFile={client.logo}
                  valueName={client.logoName}
                />
              </div>
            </div>

            {/* Address */}
            <FormDrawerTextAreaField3
              error={clientCreate.error}
              fieldName="address"
              height="100px"
              label="Address"
              noRewrite
              placeholder="Enter the client's address"
              setValueObject={setClient}
              setValueVariable="address"
              value={client.address}
            />

            {/* About */}
            <FormDrawerTextAreaField3
              error={clientCreate.error}
              fieldName="about"
              label="About"
              placeholder="Summarise the client's mission"
              setValueObject={setClient}
              setValueVariable="about"
              value={client.about}
            />
          </Card>
        }
        error={clientCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add client"
            module="Clients"
            view="List"
          />
        }
        helpTitle="Creating a client"
        icon="edit"
        isOpen={addClientDrawerIsOpen}
        onSave={addClient}
        refreshList={() => {
          dispatch(
            listClientsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateClientAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={clientCreate.success}
        saveSuccessMessage="Client created."
        saving={clientCreate.loading}
        setIsOpen={setAddClientDrawerIsOpen}
        title="Add client"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddClientDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Clients"
      />

      {/* Error alerts */}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/clients overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Clients"
        videoUrl={`${VIDEOS_URL}/clients overview.mp4`}
        width="800"
      />

      {/* Data cards */}
      <div style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}>
        <DataCardContainer
          children={
            <>
              {/* Pipeline */}
              <ClientProposalPipelineCard
                fill
                clientList={clientList}
                showInsights
              />

              {/* Total value of proposals */}
              <ClientProposalTotalValueCard
                clientList={clientList}
                clientQuery={clientQuery}
                showInsights
              />

              {/* Average margin */}
              <ClientProposalAverageMarginCard
                fill
                clientList={clientList}
                showInsights
              />

              {/* Engagement actuals */}
              <ClientEngagementActualsCard fill clientList={clientList} />
            </>
          }
          fill
        />
      </div>

      {/* Data table */}
      <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
        <TableContainer2
          body={clientList.clients.map((client) => {
            return (
              <tr
                key={client.id}
                onClick={() => {
                  if (client.user_has_permission) {
                    dispatch(resetListClientDetailsAction());

                    dispatch(resetGetIntelClientAction());

                    navigate(`/clients/${client.id}`);
                  } else {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "danger",
                      message: "You do not have access to this client.",
                    });
                  }
                }}
              >
                {/* Client */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    image={client.logo}
                    logo
                    showImage
                    tagIntent={
                      !client.user_has_permission
                        ? "danger"
                        : client.user_access_status === "view"
                        ? "primary"
                        : undefined
                    }
                    tagText={
                      !client.user_has_permission
                        ? "No access"
                        : client.user_access_status === "view"
                        ? "View only"
                        : undefined
                    }
                    text={client.name}
                  />
                </td>

                {/* Ticker */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableTag value={client.ticker} />
                </td>

                {/* Market */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    text={client.market_name}
                    url={
                      client.market ? `/markets/${client.market}` : undefined
                    }
                  />
                </td>

                {/* Manager */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    email={client.manager_name}
                    image={client.manager_profile_photo}
                    showImage
                  />
                </td>
              </tr>
            );
          })}
          clearFiltersDisabled={
            !clientQuery.manager &&
            !clientQuery.market &&
            !clientQuery.searchString &&
            !clientQuery.ticker
          }
          count={clientList.count}
          head={
            <tr>
              {/* Client */}
              <TableColumnHeadSearch
                queryObject={clientQuery}
                queryVariable="searchString"
                setQueryObject={setClientQuery}
                setQueryVariable="searchString"
                title="Client"
                width="200px"
              />

              {/* Ticker */}
              <TableColumnHeadSearch
                queryObject={clientQuery}
                queryVariable="ticker"
                setQueryObject={setClientQuery}
                setQueryVariable="ticker"
                title="Ticker"
                width="125px"
              />

              {/* Market */}
              <TableColumnHeadSelect2
                filterObject={clientQuery}
                filterVariable="market"
                items={marketList.markets}
                setFilterObject={setClientQuery}
                setQueryObject={setMarketQuery}
                setQueryVariable="market"
                title="Market"
                width="200px"
              />

              {/* Manager */}
              <TableColumnHeadSelect2
                filterObject={clientQuery}
                filterVariable="manager"
                items={managerList.managers}
                setFilterObject={setClientQuery}
                setQueryObject={setManagerQuery}
                setQueryVariable="searchString"
                title="Manager"
                width="200px"
              />
            </tr>
          }
          interactive
          loading={clientList.loading}
          onClearFilters={() => {
            setClientQuery({
              ...clientQueryDefault,
            });
          }}
          page={clientQuery.page}
          setPageObject={setClientQuery}
          setPageVariable="page"
        />
      </div>
    </div>
  );
}

export default ClientListScreen;
