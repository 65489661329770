// Library imports
import { Colors, FormGroup, Switch } from "@blueprintjs/core";
import React from "react";

// Main function
function FormDrawerSwitchField({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  label,
  setValueObject,
  setValueVariable,
  value,
}) {
  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      labelFor={fieldName}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <Switch
        checked={value}
        disabled={disabled}
        id={fieldName}
        innerLabel="No"
        innerLabelChecked="Yes"
        label={label}
        large
        onChange={() => {
          setValueObject((prevState) => {
            return {
              ...prevState,
              [setValueVariable]: !value,
            };
          });
        }}
      />
    </FormGroup>
  );
}

export default FormDrawerSwitchField;
