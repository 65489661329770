// Constant imports
import {
  ENGAGEMENT_REPORT_LIST_REQUEST,
  ENGAGEMENT_REPORT_LIST_SUCCESS,
  ENGAGEMENT_REPORT_LIST_FAIL,
  ENGAGEMENT_REPORT_LIST_RESET,
  ENGAGEMENT_REPORT_CREATE_REQUEST,
  ENGAGEMENT_REPORT_CREATE_SUCCESS,
  ENGAGEMENT_REPORT_CREATE_FAIL,
  ENGAGEMENT_REPORT_CREATE_RESET,
  ENGAGEMENT_REPORT_DETAILS_REQUEST,
  ENGAGEMENT_REPORT_DETAILS_SUCCESS,
  ENGAGEMENT_REPORT_DETAILS_FAIL,
  ENGAGEMENT_REPORT_DETAILS_RESET,
  ENGAGEMENT_REPORT_UPDATE_REQUEST,
  ENGAGEMENT_REPORT_UPDATE_SUCCESS,
  ENGAGEMENT_REPORT_UPDATE_FAIL,
  ENGAGEMENT_REPORT_UPDATE_RESET,
  ENGAGEMENT_REPORT_DELETE_REQUEST,
  ENGAGEMENT_REPORT_DELETE_SUCCESS,
  ENGAGEMENT_REPORT_DELETE_FAIL,
  ENGAGEMENT_REPORT_DELETE_RESET,
  ENGAGEMENT_REPORT_DOWNLOAD_REQUEST,
  ENGAGEMENT_REPORT_DOWNLOAD_SUCCESS,
  ENGAGEMENT_REPORT_DOWNLOAD_FAIL,
  ENGAGEMENT_REPORT_DOWNLOAD_RESET,
} from "../constants/engagementReportConstants";

// List all
export const engagementReportListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_reports: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_REPORT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_REPORT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_reports: action.payload.engagement_reports,
        success: true,
        error: "",
      };

    case ENGAGEMENT_REPORT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_reports: [],
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_REPORT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_reports: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementReportCreateReducer = (
  state = {
    loading: false,
    engagement_report: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_REPORT_CREATE_REQUEST:
      return {
        loading: true,
        engagement_report: state.engagement_report,
        success: false,
        error: "",
      };

    case ENGAGEMENT_REPORT_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_report: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_REPORT_CREATE_FAIL:
      return {
        loading: false,
        engagement_report: state.engagement_report,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_REPORT_CREATE_RESET:
      return {
        loading: false,
        engagement_report: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementReportDetailsReducer = (
  state = {
    loading: false,
    engagement_report: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_REPORT_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_report: state.engagement_report,
        success: false,
        error: "",
      };

    case ENGAGEMENT_REPORT_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_report: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_REPORT_DETAILS_FAIL:
      return {
        loading: false,
        engagement_report: state.engagement_report,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_REPORT_DETAILS_RESET:
      return {
        loading: false,
        engagement_report: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementReportUpdateReducer = (
  state = {
    loading: false,
    engagement_report: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_REPORT_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_report: state.engagement_report,
        success: false,
        error: "",
      };

    case ENGAGEMENT_REPORT_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_report: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_REPORT_UPDATE_FAIL:
      return {
        loading: false,
        engagement_report: state.engagement_report,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_REPORT_UPDATE_RESET:
      return {
        loading: false,
        engagement_report: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementReportDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_REPORT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_REPORT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_REPORT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_REPORT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const engagementReportDownloadReducer = (
  state = { loading: false, result: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_REPORT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_REPORT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_REPORT_DOWNLOAD_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_REPORT_DOWNLOAD_RESET:
      return { loading: false, result: [], success: false, error: "" };

    default:
      return state;
  }
};
