export const TIMESHEET_ENTRY_LIST_REQUEST = "TIMESHEET_ENTRY_LIST_REQUEST";
export const TIMESHEET_ENTRY_LIST_SUCCESS = "TIMESHEET_ENTRY_LIST_SUCCESS";
export const TIMESHEET_ENTRY_LIST_FAIL = "TIMESHEET_ENTRY_LIST_FAIL";
export const TIMESHEET_ENTRY_LIST_RESET = "TIMESHEET_ENTRY_LIST_RESET";

export const TIMESHEET_ENTRY_CREATE_REQUEST = "TIMESHEET_ENTRY_CREATE_REQUEST";
export const TIMESHEET_ENTRY_CREATE_SUCCESS = "TIMESHEET_ENTRY_CREATE_SUCCESS";
export const TIMESHEET_ENTRY_CREATE_FAIL = "TIMESHEET_ENTRY_CREATE_FAIL";
export const TIMESHEET_ENTRY_CREATE_RESET = "TIMESHEET_ENTRY_CREATE_RESET";

export const TIMESHEET_ENTRY_DETAILS_REQUEST =
  "TIMESHEET_ENTRY_DETAILS_REQUEST";
export const TIMESHEET_ENTRY_DETAILS_SUCCESS =
  "TIMESHEET_ENTRY_DETAILS_SUCCESS";
export const TIMESHEET_ENTRY_DETAILS_FAIL = "TIMESHEET_ENTRY_DETAILS_FAIL";
export const TIMESHEET_ENTRY_DETAILS_RESET = "TIMESHEET_ENTRY_DETAILS_RESET";

export const TIMESHEET_ENTRY_UPDATE_REQUEST = "TIMESHEET_ENTRY_UPDATE_REQUEST";
export const TIMESHEET_ENTRY_UPDATE_SUCCESS = "TIMESHEET_ENTRY_UPDATE_SUCCESS";
export const TIMESHEET_ENTRY_UPDATE_FAIL = "TIMESHEET_ENTRY_UPDATE_FAIL";
export const TIMESHEET_ENTRY_UPDATE_RESET = "TIMESHEET_ENTRY_UPDATE_RESET";

export const TIMESHEET_ENTRY_DELETE_REQUEST = "TIMESHEET_ENTRY_DELETE_REQUEST";
export const TIMESHEET_ENTRY_DELETE_SUCCESS = "TIMESHEET_ENTRY_DELETE_SUCCESS";
export const TIMESHEET_ENTRY_DELETE_FAIL = "TIMESHEET_ENTRY_DELETE_FAIL";
export const TIMESHEET_ENTRY_DELETE_RESET = "TIMESHEET_ENTRY_DELETE_RESET";
