// Constant imports
import {
  AI_CREDIT_TRANSACTION_LIST_REQUEST,
  AI_CREDIT_TRANSACTION_LIST_SUCCESS,
  AI_CREDIT_TRANSACTION_LIST_FAIL,
  AI_CREDIT_TRANSACTION_LIST_RESET,
} from "../constants/aiCreditTransactionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listAICreditTransactionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: AI_CREDIT_TRANSACTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getAICreditTransactions(
        params,
        token
      );

      dispatch({
        type: AI_CREDIT_TRANSACTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          total_credits_last_7_days: data.total_credits_last_7_days.result,
          total_credits_last_14_days: data.total_credits_last_14_days.result,
          total_credits_last_21_days: data.total_credits_last_21_days.result,
          total_credits_last_28_days: data.total_credits_last_28_days.result,
          total_credits: data.total_credits.result,
          total_credits_answer: data.total_credits_answer.result,
          total_credits_extract: data.total_credits_extract.result,
          total_credits_index: data.total_credits_index.result,
          total_credits_rewrite: data.total_credits_rewrite.result,
          ai_credit_transactions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: AI_CREDIT_TRANSACTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListAICreditTransactionsAction = () => async (dispatch) => {
  dispatch({ type: AI_CREDIT_TRANSACTION_LIST_RESET });
};
