export const OPENING_CANDIDATE_LIST_REQUEST = "OPENING_CANDIDATE_LIST_REQUEST";
export const OPENING_CANDIDATE_LIST_SUCCESS = "OPENING_CANDIDATE_LIST_SUCCESS";
export const OPENING_CANDIDATE_LIST_FAIL = "OPENING_CANDIDATE_LIST_FAIL";
export const OPENING_CANDIDATE_LIST_RESET = "OPENING_CANDIDATE_LIST_RESET";

export const OPENING_CANDIDATE_DETAILS_REQUEST =
  "OPENING_CANDIDATE_DETAILS_REQUEST";
export const OPENING_CANDIDATE_DETAILS_SUCCESS =
  "OPENING_CANDIDATE_DETAILS_SUCCESS";
export const OPENING_CANDIDATE_DETAILS_FAIL = "OPENING_CANDIDATE_DETAILS_FAIL";
export const OPENING_CANDIDATE_DETAILS_RESET =
  "OPENING_CANDIDATE_DETAILS_RESET";

export const OPENING_CANDIDATE_UPDATE_REQUEST =
  "OPENING_CANDIDATE_UPDATE_REQUEST";
export const OPENING_CANDIDATE_UPDATE_SUCCESS =
  "OPENING_CANDIDATE_UPDATE_SUCCESS";
export const OPENING_CANDIDATE_UPDATE_FAIL = "OPENING_CANDIDATE_UPDATE_FAIL";
export const OPENING_CANDIDATE_UPDATE_RESET = "OPENING_CANDIDATE_UPDATE_RESET";
