// Constant imports
import {
  ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_REQUEST,
  ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_SUCCESS,
  ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_FAIL,
  ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_RESET,
  ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_REQUEST,
  ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_SUCCESS,
  ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_FAIL,
  ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_RESET,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_REQUEST,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_SUCCESS,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_FAIL,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_RESET,
  ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_REQUEST,
  ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_SUCCESS,
  ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_FAIL,
  ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_RESET,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_REQUEST,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_SUCCESS,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_FAIL,
  ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_RESET,
} from "../constants/engagementNonLabourExpenseConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementNonLabourExpensesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementNonLabourExpenses(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_non_labour_expenses: data.results,
          totalCost: data.total_cost.result,
          totalFee: data.total_fee.result,
          totalFeeQuote: data.total_fee_quote.result,
          totalMargin: data.total_margin.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementNonLabourExpensesAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_LIST_RESET });
  };

// Create
export const createEngagementNonLabourExpenseAction =
  (engagement_non_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementNonLabourExpense(
        token,
        engagement_non_labour_expense
      );

      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementNonLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_CREATE_RESET });
  };

// List details
export const listEngagementNonLabourExpenseDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementNonLabourExpense(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementNonLabourExpenseDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_DETAILS_RESET });
  };

// Update
export const updateEngagementNonLabourExpenseAction =
  (id, engagement_non_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementNonLabourExpense(
        id,
        token,
        engagement_non_labour_expense
      );

      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementNonLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_UPDATE_RESET });
  };

// Delete
export const deleteEngagementNonLabourExpenseAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementNonLabourExpense(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementNonLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_NON_LABOUR_EXPENSE_DELETE_RESET });
  };
