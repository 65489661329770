// Main function
const generateTimelineResourceDataFromEngagementServices = (
  engagementServices
) => {
  if (engagementServices) {
    let arr = [];

    engagementServices.forEach((service) => {
      service.labour_expenses.forEach((expense) => {
        arr.push({
          end: new Date(expense.finish),
          id: `resource.${expense.id}`,
          minutes: expense.minutes,
          name: expense.staff_name,
          photo: expense.staff_profile_photo,
          role: expense.role_name,
          start: new Date(expense.start),
          utilisation: expense.utilisation,
        });
      });
    });

    return arr;
  }

  return [];
};

export default generateTimelineResourceDataFromEngagementServices;
