// Library imports
import React, { useEffect, useRef } from "react";

// Main function
function HtmlDisplay({ html }) {
  // Populate iframe
  const iframeRef = useRef();

  useEffect(() => {
    if (html) {
      const paddedHtml = html.replace(
        "<body>",
        "<body style='padding: 20mm;'>"
      );

      const doc = iframeRef.current.contentWindow.document;

      doc.open();

      doc.write(paddedHtml);

      doc.close();
    }
  }, [html]);

  // JSX UI code
  return (
    <iframe
      height="100%"
      ref={iframeRef}
      style={{ border: "none" }}
      title="Document"
      width="100%"
    />
  );
}

export default HtmlDisplay;
