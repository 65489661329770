// Constant imports
import {
  PROPOSAL_CASE_STUDY_LIST_REQUEST,
  PROPOSAL_CASE_STUDY_LIST_SUCCESS,
  PROPOSAL_CASE_STUDY_LIST_FAIL,
  PROPOSAL_CASE_STUDY_LIST_RESET,
  PROPOSAL_CASE_STUDY_CREATE_REQUEST,
  PROPOSAL_CASE_STUDY_CREATE_SUCCESS,
  PROPOSAL_CASE_STUDY_CREATE_FAIL,
  PROPOSAL_CASE_STUDY_CREATE_RESET,
  PROPOSAL_CASE_STUDY_DETAILS_REQUEST,
  PROPOSAL_CASE_STUDY_DETAILS_SUCCESS,
  PROPOSAL_CASE_STUDY_DETAILS_FAIL,
  PROPOSAL_CASE_STUDY_DETAILS_RESET,
  PROPOSAL_CASE_STUDY_UPDATE_REQUEST,
  PROPOSAL_CASE_STUDY_UPDATE_SUCCESS,
  PROPOSAL_CASE_STUDY_UPDATE_FAIL,
  PROPOSAL_CASE_STUDY_UPDATE_RESET,
  PROPOSAL_CASE_STUDY_DELETE_REQUEST,
  PROPOSAL_CASE_STUDY_DELETE_SUCCESS,
  PROPOSAL_CASE_STUDY_DELETE_FAIL,
  PROPOSAL_CASE_STUDY_DELETE_RESET,
} from "../constants/proposalCaseStudyConstants";

// List all
export const proposalCaseStudyListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_case_studies: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CASE_STUDY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_CASE_STUDY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_case_studies: action.payload.proposal_case_studies,
        success: true,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_case_studies: [],
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CASE_STUDY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_case_studies: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalCaseStudyCreateReducer = (
  state = {
    loading: false,
    proposal_case_study: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CASE_STUDY_CREATE_REQUEST:
      return {
        loading: true,
        proposal_case_study: state.proposal_case_study,
        success: false,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_case_study: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_CREATE_FAIL:
      return {
        loading: false,
        proposal_case_study: state.proposal_case_study,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CASE_STUDY_CREATE_RESET:
      return {
        loading: false,
        proposal_case_study: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalCaseStudyDetailsReducer = (
  state = {
    loading: false,
    proposal_case_study: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CASE_STUDY_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_case_study: state.proposal_case_study,
        success: false,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_case_study: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_DETAILS_FAIL:
      return {
        loading: false,
        proposal_case_study: state.proposal_case_study,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CASE_STUDY_DETAILS_RESET:
      return {
        loading: false,
        proposal_case_study: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalCaseStudyUpdateReducer = (
  state = {
    loading: false,
    proposal_case_study: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CASE_STUDY_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_case_study: state.proposal_case_study,
        success: false,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_case_study: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_UPDATE_FAIL:
      return {
        loading: false,
        proposal_case_study: state.proposal_case_study,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CASE_STUDY_UPDATE_RESET:
      return {
        loading: false,
        proposal_case_study: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalCaseStudyDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CASE_STUDY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_CASE_STUDY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CASE_STUDY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
