export const INVOICE_MILESTONE_LIST_REQUEST = "INVOICE_MILESTONE_LIST_REQUEST";
export const INVOICE_MILESTONE_LIST_SUCCESS = "INVOICE_MILESTONE_LIST_SUCCESS";
export const INVOICE_MILESTONE_LIST_FAIL = "INVOICE_MILESTONE_LIST_FAIL";
export const INVOICE_MILESTONE_LIST_RESET = "INVOICE_MILESTONE_LIST_RESET";

export const INVOICE_MILESTONE_DETAILS_REQUEST =
  "INVOICE_MILESTONE_DETAILS_REQUEST";
export const INVOICE_MILESTONE_DETAILS_SUCCESS =
  "INVOICE_MILESTONE_DETAILS_SUCCESS";
export const INVOICE_MILESTONE_DETAILS_FAIL = "INVOICE_MILESTONE_DETAILS_FAIL";
export const INVOICE_MILESTONE_DETAILS_RESET =
  "INVOICE_MILESTONE_DETAILS_RESET";

export const INVOICE_MILESTONE_UPDATE_REQUEST =
  "INVOICE_MILESTONE_UPDATE_REQUEST";
export const INVOICE_MILESTONE_UPDATE_SUCCESS =
  "INVOICE_MILESTONE_UPDATE_SUCCESS";
export const INVOICE_MILESTONE_UPDATE_FAIL = "INVOICE_MILESTONE_UPDATE_FAIL";
export const INVOICE_MILESTONE_UPDATE_RESET = "INVOICE_MILESTONE_UPDATE_RESET";

export const INVOICE_MILESTONE_DELETE_REQUEST =
  "INVOICE_MILESTONE_DELETE_REQUEST";
export const INVOICE_MILESTONE_DELETE_SUCCESS =
  "INVOICE_MILESTONE_DELETE_SUCCESS";
export const INVOICE_MILESTONE_DELETE_FAIL = "INVOICE_MILESTONE_DELETE_FAIL";
export const INVOICE_MILESTONE_DELETE_RESET = "INVOICE_MILESTONE_DELETE_RESET";
