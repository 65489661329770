// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  PROPOSAL_LEGAL_SECTION_LIST_REQUEST,
  PROPOSAL_LEGAL_SECTION_LIST_SUCCESS,
  PROPOSAL_LEGAL_SECTION_LIST_FAIL,
  PROPOSAL_LEGAL_SECTION_LIST_RESET,
  PROPOSAL_LEGAL_SECTION_CREATE_REQUEST,
  PROPOSAL_LEGAL_SECTION_CREATE_SUCCESS,
  PROPOSAL_LEGAL_SECTION_CREATE_FAIL,
  PROPOSAL_LEGAL_SECTION_CREATE_RESET,
  PROPOSAL_LEGAL_SECTION_DETAILS_REQUEST,
  PROPOSAL_LEGAL_SECTION_DETAILS_SUCCESS,
  PROPOSAL_LEGAL_SECTION_DETAILS_FAIL,
  PROPOSAL_LEGAL_SECTION_DETAILS_RESET,
  PROPOSAL_LEGAL_SECTION_UPDATE_REQUEST,
  PROPOSAL_LEGAL_SECTION_UPDATE_SUCCESS,
  PROPOSAL_LEGAL_SECTION_UPDATE_FAIL,
  PROPOSAL_LEGAL_SECTION_UPDATE_RESET,
  PROPOSAL_LEGAL_SECTION_DELETE_REQUEST,
  PROPOSAL_LEGAL_SECTION_DELETE_SUCCESS,
  PROPOSAL_LEGAL_SECTION_DELETE_FAIL,
  PROPOSAL_LEGAL_SECTION_DELETE_RESET,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_REQUEST,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_SUCCESS,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_FAIL,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_RESET,
  PROPOSAL_LEGAL_SECTION_UPLOAD_BEGIN,
  PROPOSAL_LEGAL_SECTION_UPLOAD_REQUEST,
  PROPOSAL_LEGAL_SECTION_UPLOAD_SUCCESS,
  PROPOSAL_LEGAL_SECTION_UPLOAD_FAIL,
  PROPOSAL_LEGAL_SECTION_UPLOAD_END,
  PROPOSAL_LEGAL_SECTION_UPLOAD_RESET,
} from "../constants/proposalLegalSectionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalLegalSectionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LEGAL_SECTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalLegalSections(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_LEGAL_SECTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfSectionsAgreed: data.number_of_sections_agreed,
          numberOfSectionsNotAgreed: data.number_of_sections_not_agreed,
          numberOfSectionsUnderReview: data.number_of_sections_under_review,
          proposal_legal_sections: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LEGAL_SECTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalLegalSectionsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LEGAL_SECTION_LIST_RESET });
};

// Create
export const createProposalLegalSectionAction =
  (proposal_legal_section) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LEGAL_SECTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalLegalSection(
        token,
        proposal_legal_section
      );

      dispatch({
        type: PROPOSAL_LEGAL_SECTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LEGAL_SECTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalLegalSectionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LEGAL_SECTION_CREATE_RESET });
};

// List details
export const listProposalLegalSectionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LEGAL_SECTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalLegalSection(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_LEGAL_SECTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LEGAL_SECTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalLegalSectionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LEGAL_SECTION_DETAILS_RESET });
  };

// Update
export const updateProposalLegalSectionAction =
  (id, proposal_legal_section) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LEGAL_SECTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalLegalSection(
        id,
        token,
        proposal_legal_section
      );

      dispatch({
        type: PROPOSAL_LEGAL_SECTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LEGAL_SECTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalLegalSectionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LEGAL_SECTION_UPDATE_RESET });
};

// Delete
export const deleteProposalLegalSectionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LEGAL_SECTION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalLegalSection(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_LEGAL_SECTION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LEGAL_SECTION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalLegalSectionAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LEGAL_SECTION_DELETE_RESET });
};

// Download
export const downloadProposalLegalSectionsAction =
  (proposal, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LEGAL_SECTION_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var proposalLegalSections = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getProposalLegalSections(
          { page: page, proposal: proposal },
          token
        );

        proposalLegalSections = proposalLegalSections.concat(data.results);
      }

      dispatch({
        type: PROPOSAL_LEGAL_SECTION_DOWNLOAD_SUCCESS,

        payload: {
          proposal_legal_sections: proposalLegalSections,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LEGAL_SECTION_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadProposalLegalSectionsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LEGAL_SECTION_DOWNLOAD_RESET });
  };

// Upload
export const uploadProposalLegalSectionsAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: PROPOSAL_LEGAL_SECTION_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: PROPOSAL_LEGAL_SECTION_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteProposalLegalSection(
            records[recordNum].id,
            token
          );
        } else if (records[recordNum].id) {
          await cortixDataService.updateProposalLegalSection(
            records[recordNum].id,
            token,
            {
              amended: records[recordNum].amended,
              comments: records[recordNum].comments,
              number: records[recordNum].number,
              originator: records[recordNum].originator,
              proposal: id,
              proposed: records[recordNum].proposed,
              title: records[recordNum].title,
              status: records[recordNum].status,
            }
          );
        } else {
          await cortixDataService.createProposalLegalSection(token, {
            amended: records[recordNum].amended,
            comments: records[recordNum].comments,
            number: records[recordNum].number,
            originator: records[recordNum].originator,
            proposal: id,
            proposed: records[recordNum].proposed,
            title: records[recordNum].title,
            status: records[recordNum].status,
          });
        }

        dispatch({
          type: PROPOSAL_LEGAL_SECTION_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: PROPOSAL_LEGAL_SECTION_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: PROPOSAL_LEGAL_SECTION_UPLOAD_END });
  };

// Reset upload
export const resetUploadProposalLegalSectionsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LEGAL_SECTION_UPLOAD_RESET });
  };
