// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import CaseStudyInfo from "../components/CaseStudyInfo";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import InfoPopup from "../components/InfoPopup";
import InsightInfo from "../components/InsightInfo";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NeedInfo from "../components/NeedInfo";
import PermissionCard from "../components/PermissionCard";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";

// Redux action imports
// Case study
import { listCaseStudiesAction } from "../actions/caseStudyActions";

// Insight
import { listInsightsAction } from "../actions/insightActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Market
import {
  deleteMarketAction,
  listMarketDetailsAction,
  resetDeleteMarketAction,
  resetListMarketsAction,
  resetUpdateMarketAction,
  updateMarketAction,
} from "../actions/marketActions";

// Market case study
import {
  createMarketCaseStudyAction,
  deleteMarketCaseStudyAction,
  listMarketCaseStudiesAction,
  listMarketCaseStudyDetailsAction,
  resetCreateMarketCaseStudyAction,
  resetDeleteMarketCaseStudyAction,
  resetUpdateMarketCaseStudyAction,
  updateMarketCaseStudyAction,
} from "../actions/marketCaseStudyActions";

// Market insight
import {
  createMarketInsightAction,
  deleteMarketInsightAction,
  listMarketInsightsAction,
  listMarketInsightDetailsAction,
  resetCreateMarketInsightAction,
  resetDeleteMarketInsightAction,
  resetUpdateMarketInsightAction,
  updateMarketInsightAction,
} from "../actions/marketInsightActions";

// Market need
import {
  createMarketNeedAction,
  deleteMarketNeedAction,
  listMarketNeedsAction,
  listMarketNeedDetailsAction,
  resetCreateMarketNeedAction,
  resetDeleteMarketNeedAction,
  resetUpdateMarketNeedAction,
  updateMarketNeedAction,
} from "../actions/marketNeedActions";

// Market permission
import {
  createMarketPermissionAction,
  deleteMarketPermissionAction,
  listMarketPermissionsAction,
  listMarketPermissionDetailsAction,
  resetCreateMarketPermissionAction,
  resetDeleteMarketPermissionAction,
  resetUpdateMarketPermissionAction,
  updateMarketPermissionAction,
} from "../actions/marketPermissionActions";

// Need
import { listNeedsAction } from "../actions/needActions";

// Main function
function MarketDetailScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Market id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Case study
  const caseStudyList = useSelector((state) => state.caseStudyList);

  // Insight
  const insightList = useSelector((state) => state.insightList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Market
  const marketDelete = useSelector((state) => state.marketDelete);

  const marketDetails = useSelector((state) => state.marketDetails);

  const marketUpdate = useSelector((state) => state.marketUpdate);

  // Market case study
  const marketCaseStudyCreate = useSelector(
    (state) => state.marketCaseStudyCreate
  );

  const marketCaseStudyDelete = useSelector(
    (state) => state.marketCaseStudyDelete
  );

  const marketCaseStudyDetails = useSelector(
    (state) => state.marketCaseStudyDetails
  );

  const marketCaseStudyList = useSelector((state) => state.marketCaseStudyList);

  const marketCaseStudyUpdate = useSelector(
    (state) => state.marketCaseStudyUpdate
  );

  // Market insight
  const marketInsightCreate = useSelector((state) => state.marketInsightCreate);

  const marketInsightDelete = useSelector((state) => state.marketInsightDelete);

  const marketInsightDetails = useSelector(
    (state) => state.marketInsightDetails
  );

  const marketInsightList = useSelector((state) => state.marketInsightList);

  const marketInsightUpdate = useSelector((state) => state.marketInsightUpdate);

  // Market need
  const marketNeedCreate = useSelector((state) => state.marketNeedCreate);

  const marketNeedDelete = useSelector((state) => state.marketNeedDelete);

  const marketNeedDetails = useSelector((state) => state.marketNeedDetails);

  const marketNeedList = useSelector((state) => state.marketNeedList);

  const marketNeedUpdate = useSelector((state) => state.marketNeedUpdate);

  // Market permission
  const marketPermissionCreate = useSelector(
    (state) => state.marketPermissionCreate
  );

  const marketPermissionDelete = useSelector(
    (state) => state.marketPermissionDelete
  );

  const marketPermissionDetails = useSelector(
    (state) => state.marketPermissionDetails
  );

  const marketPermissionList = useSelector(
    (state) => state.marketPermissionList
  );

  const marketPermissionUpdate = useSelector(
    (state) => state.marketPermissionUpdate
  );

  // Need
  const needList = useSelector((state) => state.needList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("market-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Needs
  const [addNeedDrawerIsOpen, setAddNeedDrawerIsOpen] = useState(false);
  const [updateNeedDrawerIsOpen, setUpdateNeedDrawerIsOpen] = useState(false);

  // Case studies
  const [addCaseStudyDrawerIsOpen, setAddCaseStudyDrawerIsOpen] =
    useState(false);
  const [updateCaseStudyDrawerIsOpen, setUpdateCaseStudyDrawerIsOpen] =
    useState(false);

  // Insights
  const [addInsightDrawerIsOpen, setAddInsightDrawerIsOpen] = useState(false);
  const [updateInsightDrawerIsOpen, setUpdateInsightDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Case study
  const caseStudyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [caseStudyQuery, setCaseStudyQuery] = useState({
    ...caseStudyQueryDefault,
  });

  // Insight
  const insightQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [insightQuery, setInsightQuery] = useState({
    ...insightQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Market case study
  const marketCaseStudyQueryDefault = {
    market: id,
    page: 1,
    searchString: "",
  };
  const [marketCaseStudyQuery, setMarketCaseStudyQuery] = useState({
    ...marketCaseStudyQueryDefault,
  });

  // Market insight
  const marketInsightQueryDefault = {
    market: id,
    page: 1,
    searchString: "",
  };
  const [marketInsightQuery, setMarketInsightQuery] = useState({
    ...marketInsightQueryDefault,
  });

  // Market need
  const marketNeedQueryDefault = {
    market: id,
    page: 1,
    searchString: "",
  };
  const [marketNeedQuery, setMarketNeedQuery] = useState({
    ...marketNeedQueryDefault,
  });

  // Market permission
  const marketPermissionQueryDefault = {
    market: id,
    page: 1,
    searchString: "",
  };
  const [marketPermissionQuery, setMarketPermissionQuery] = useState({
    ...marketPermissionQueryDefault,
  });

  // Need
  const needQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [needQuery, setNeedQuery] = useState({
    ...needQueryDefault,
  });

  // Form variables
  // Market
  const marketDefault = {
    description: "",
    manager: "",
    managerName: "",
    name: "",
    restricted: true,
    status: "Draft",
  };
  const [market, setMarket] = useState({
    ...marketDefault,
  });

  // Market case study
  const marketCaseStudyDefault = {
    caseStudy: "",
    caseStudyName: "",
    notes: "",
  };
  const [marketCaseStudy, setMarketCaseStudy] = useState({
    ...marketCaseStudyDefault,
  });

  // Market insight
  const marketInsightDefault = {
    insight: "",
    insightDetail: "",
    insightName: "",
    notes: "",
  };
  const [marketInsight, setMarketInsight] = useState({
    ...marketInsightDefault,
  });

  // Market need
  const marketNeedDefault = {
    need: "",
    needDescription: "",
    needName: "",
    notes: "",
  };
  const [marketNeed, setMarketNeed] = useState({
    ...marketNeedDefault,
  });

  // Market permission
  const marketPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [marketPermission, setMarketPermission] = useState({
    ...marketPermissionDefault,
  });

  // Other
  const [marketDeleteKey, setMarketDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Market
    setMarket({
      ...marketDefault,
    });

    // Market case study
    setMarketCaseStudy({
      ...marketCaseStudyDefault,
    });

    // Market insight
    setMarketInsight({
      ...marketInsightDefault,
    });

    // Market need
    setMarketNeed({
      ...marketNeedDefault,
    });

    // Market permission
    setMarketPermission({
      ...marketPermissionDefault,
    });

    // Query variables
    // Case study
    setCaseStudyQuery({
      ...caseStudyQueryDefault,
    });

    // Insight
    setInsightQuery({
      ...insightQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Need
    setNeedQuery({
      ...needQueryDefault,
    });
  };

  // Initialise drawer variables for market
  useEffect(() => {
    setMarket({
      description: marketDetails.market.description
        ? marketDetails.market.description
        : marketDefault.description,
      manager: marketDetails.market.manager
        ? marketDetails.market.manager
        : marketDefault.manager,
      managerName: marketDetails.market.manager_name
        ? marketDetails.market.manager_name
        : marketDefault.managerName,
      name: marketDetails.market.name
        ? marketDetails.market.name
        : marketDefault.name,
      restricted:
        marketDetails.market.restricted != null
          ? marketDetails.market.restricted
          : marketDefault.restricted,
      status: marketDetails.market.status
        ? marketDetails.market.status
        : marketDefault.status,
    });
    // eslint-disable-next-line
  }, [marketDetails.market]);

  // Initialise drawer variables for market case study
  useEffect(() => {
    setMarketCaseStudy({
      caseStudy: marketCaseStudyDetails.market_case_study.case_study
        ? marketCaseStudyDetails.market_case_study.case_study
        : marketCaseStudyDefault.caseStudy,
      caseStudyName: marketCaseStudyDetails.market_case_study.case_study_data
        ? marketCaseStudyDetails.market_case_study.case_study_data.name
        : marketCaseStudyDefault.caseStudyName,
      notes: marketCaseStudyDetails.market_case_study.notes
        ? marketCaseStudyDetails.market_case_study.notes
        : marketCaseStudyDefault.notes,
    });
    // eslint-disable-next-line
  }, [marketCaseStudyDetails.market_case_study]);

  // Initialise drawer variables for market insight
  useEffect(() => {
    setMarketInsight({
      insight: marketInsightDetails.market_insight.insight
        ? marketInsightDetails.market_insight.insight
        : marketInsightDefault.insight,
      insightName: marketInsightDetails.market_insight.insight_data
        ? marketInsightDetails.market_insight.insight_data.name
        : marketInsightDefault.insightName,
      notes: marketInsightDetails.market_insight.notes
        ? marketInsightDetails.market_insight.notes
        : marketInsightDefault.notes,
    });
    // eslint-disable-next-line
  }, [marketInsightDetails.market_insight]);

  // Initialise drawer variables for market need
  useEffect(() => {
    setMarketNeed({
      need: marketNeedDetails.market_need.need
        ? marketNeedDetails.market_need.need
        : marketNeedDefault.need,
      needName: marketNeedDetails.market_need.need_data
        ? marketNeedDetails.market_need.need_data.name
        : marketNeedDefault.needName,
      notes: marketNeedDetails.market_need.notes
        ? marketNeedDetails.market_need.notes
        : marketNeedDefault.notes,
    });
    // eslint-disable-next-line
  }, [marketNeedDetails.market_need]);

  // Initialise drawer variables for market permission
  useEffect(() => {
    setMarketPermission({
      level: marketPermissionDetails.market_permission.level
        ? marketPermissionDetails.market_permission.level
        : marketPermissionDefault.level,
      manager: marketPermissionDetails.market_permission.manager
        ? marketPermissionDetails.market_permission.manager
        : marketPermissionDefault.manager,
      managerName: marketPermissionDetails.market_permission.manager_name
        ? marketPermissionDetails.market_permission.manager_name
        : marketPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [marketPermissionDetails.market_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If market deleted redirect to markets
  useEffect(() => {
    if (marketDelete.success) {
      dispatch(resetDeleteMarketAction());

      dispatch(resetListMarketsAction());

      navigate("/markets");
    }
    // eslint-disable-next-line
  }, [marketDelete.success]);

  // Load market details
  useEffect(() => {
    dispatch(listMarketDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter case studies
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listCaseStudiesAction({
            ...caseStudyQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [caseStudyQuery]);

  // Filter insights
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInsightsAction({
            ...insightQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [insightQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter market case studies
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listMarketCaseStudiesAction({ ...marketCaseStudyQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketCaseStudyQuery]);

  // Filter market insights
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listMarketInsightsAction({ ...marketInsightQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketInsightQuery]);

  // Filter market needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listMarketNeedsAction({ ...marketNeedQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketNeedQuery]);

  // Filter market permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listMarketPermissionsAction({ ...marketPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [marketPermissionQuery]);

  // Filter needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNeedsAction({
            ...needQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuery]);

  // Form submission
  // Market
  // Delete market
  const deleteMarket = () => {
    // Dispatch action
    marketDeleteKey === marketDetails.market.name
      ? dispatch(deleteMarketAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the market's name",
        });
  };

  // Update market summary
  const updateSummary = () => {
    // Prepare data
    const market_data = new FormData();

    market.description
      ? market_data.append("description", market.description)
      : market_data.append("description", marketDefault.description);

    market.manager
      ? market_data.append("manager", market.manager)
      : market_data.append("manager", marketDefault.manager);

    market.name
      ? market_data.append("name", market.name)
      : market_data.append("name", marketDefault.name);

    market.restricted != null
      ? market_data.append("restricted", market.restricted)
      : market_data.append("restricted", marketDefault.restricted);

    market.status
      ? market_data.append("status", market.status)
      : market_data.append("status", marketDefault.status);

    // Dispatch action
    dispatch(updateMarketAction(marketDetails.market.id, market_data));
  };

  // Market case study
  // Add case study
  const addCaseStudy = () => {
    // Prepare data
    const market_case_study = new FormData();

    marketCaseStudy.caseStudy
      ? market_case_study.append("case_study", marketCaseStudy.caseStudy)
      : market_case_study.append(
          "case_study",
          marketCaseStudyDefault.caseStudy
        );

    market_case_study.append("market", id);

    marketCaseStudy.notes
      ? market_case_study.append("notes", marketCaseStudy.notes)
      : market_case_study.append("notes", marketCaseStudyDefault.notes);

    // Dispatch action
    dispatch(createMarketCaseStudyAction(market_case_study));
  };

  // Delete case study
  const deleteCaseStudy = () => {
    // Dispatch action
    dispatch(
      deleteMarketCaseStudyAction(marketCaseStudyDetails.market_case_study.id)
    );
  };

  // Update case study
  const updateCaseStudy = () => {
    // Prepare data
    const market_case_study = new FormData();

    marketCaseStudy.caseStudy
      ? market_case_study.append("case_study", marketCaseStudy.caseStudy)
      : market_case_study.append(
          "case_study",
          marketCaseStudyDefault.caseStudy
        );

    market_case_study.append("market", id);

    marketCaseStudy.notes
      ? market_case_study.append("notes", marketCaseStudy.notes)
      : market_case_study.append("notes", marketCaseStudyDefault.notes);

    // Dispatch action
    dispatch(
      updateMarketCaseStudyAction(
        marketCaseStudyDetails.market_case_study.id,
        market_case_study
      )
    );
  };

  // Market insight
  // Add insight
  const addInsight = () => {
    // Prepare data
    const market_insight = new FormData();

    marketInsight.insight
      ? market_insight.append("insight", marketInsight.insight)
      : market_insight.append("insight", marketInsightDefault.insight);

    market_insight.append("market", id);

    marketInsight.notes
      ? market_insight.append("notes", marketInsight.notes)
      : market_insight.append("notes", marketInsightDefault.notes);

    // Dispatch action
    dispatch(createMarketInsightAction(market_insight));
  };

  // Delete insight
  const deleteInsight = () => {
    // Dispatch action
    dispatch(deleteMarketInsightAction(marketInsightDetails.market_insight.id));
  };

  // Update insight
  const updateInsight = () => {
    // Prepare data
    const market_insight = new FormData();

    marketInsight.insight
      ? market_insight.append("insight", marketInsight.insight)
      : market_insight.append("insight", marketInsightDefault.insight);

    marketInsight.notes
      ? market_insight.append("notes", marketInsight.notes)
      : market_insight.append("notes", marketInsightDefault.notes);

    // Dispatch action
    dispatch(
      updateMarketInsightAction(
        marketInsightDetails.market_insight.id,
        market_insight
      )
    );
  };

  // Market need
  // Add need
  const addNeed = () => {
    // Prepare data
    const market_need = new FormData();

    market_need.append("market", id);

    marketNeed.need
      ? market_need.append("need", marketNeed.need)
      : market_need.append("need", marketNeedDefault.need);

    marketNeed.notes
      ? market_need.append("notes", marketNeed.notes)
      : market_need.append("notes", marketNeedDefault.notes);

    // Dispatch action
    dispatch(createMarketNeedAction(market_need));
  };

  // Delete need
  const deleteNeed = () => {
    // Dispatch action
    dispatch(deleteMarketNeedAction(marketNeedDetails.market_need.id));
  };

  // Update need
  const updateNeed = () => {
    // Prepare data
    const market_need = new FormData();

    marketNeed.need
      ? market_need.append("need", marketNeed.need)
      : market_need.append("need", marketNeedDefault.need);

    marketNeed.notes
      ? market_need.append("notes", marketNeed.notes)
      : market_need.append("notes", marketNeedDefault.notes);

    // Dispatch action
    dispatch(
      updateMarketNeedAction(marketNeedDetails.market_need.id, market_need)
    );
  };

  // Market permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const market_permission = new FormData();

    marketPermission.level
      ? market_permission.append("level", marketPermission.level)
      : market_permission.append("level", marketPermissionDefault.level);

    marketPermission.manager
      ? market_permission.append("manager", marketPermission.manager)
      : market_permission.append("manager", marketPermissionDefault.manager);

    market_permission.append("market", id);

    // Dispatch action
    dispatch(createMarketPermissionAction(market_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteMarketPermissionAction(marketPermissionDetails.market_permission.id)
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const market_permission = new FormData();

    marketPermission.level
      ? market_permission.append("level", marketPermission.level)
      : market_permission.append("level", marketPermissionDefault.level);

    marketPermission.manager
      ? market_permission.append("manager", marketPermission.manager)
      : market_permission.append("manager", marketPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateMarketPermissionAction(
        marketPermissionDetails.market_permission.id,
        market_permission
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe the market</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Market name */}
                  <FormDrawerTextField2
                    error={marketUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Market name"
                    labelInfo="(required)"
                    placeholder="Enter the market's name"
                    setValueObject={setMarket}
                    setValueVariable="name"
                    value={market.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={marketUpdate.error}
                fieldName="description"
                label="Description"
                placeholder="Describe the market"
                setValueObject={setMarket}
                setValueVariable="description"
                value={market.description}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Status */}
                  <FormDrawerButtonField
                    disabled={
                      marketDetails.market.user_access_status === "edit" ||
                      marketDetails.market.user_access_status === "view"
                    }
                    error={marketUpdate.error}
                    fieldName="status"
                    helperText="Clients cannot be assigned to markets in draft status"
                    label="Status"
                    options={[
                      {
                        icon: "tick-circle",
                        id: 1,
                        intent: "success",
                        label: "Approved",
                        value: "Approved",
                      },
                      {
                        icon: "new-drawing",
                        id: 2,
                        intent: "primary",
                        label: "Draft",
                        value: "Draft",
                      },
                    ]}
                    setValueObject={setMarket}
                    setValueVariable="status"
                    value={market.status}
                  />

                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      marketDetails.market.user_access_status === "edit" ||
                      marketDetails.market.user_access_status === "view"
                    }
                    error={marketUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted market can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setMarket}
                    setValueVariable="restricted"
                    value={market.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      marketDetails.market.user_access_status === "edit" ||
                      marketDetails.market.user_access_status === "view"
                    }
                    error={marketUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the market - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setMarket}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={market.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the market manager and the market
                    is restricted, you will not be able to access it again,
                    unless you are organisation administrator/owner or have
                    edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={marketUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Updating a market"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={marketDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listMarketDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateMarketAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketUpdate.success}
        saveSuccessMessage="Market updated."
        saving={marketUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />
            {/* Manager */}
            <FormDrawerSelectField2
              error={marketPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setMarketPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={marketPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={marketPermissionCreate.error}
              fieldName="level"
              intent={
                marketPermission.level === "Edit"
                  ? "success"
                  : marketPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setMarketPermission}
              setValueVariable="level"
              value={marketPermission.level}
            />
          </Card>
        }
        error={marketPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listMarketPermissionsAction({ market: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateMarketPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={marketPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={marketPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setMarketPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={marketPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={marketPermissionUpdate.error}
              fieldName="level"
              intent={
                marketPermission.level === "Edit"
                  ? "success"
                  : marketPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setMarketPermission}
              setValueVariable="level"
              value={marketPermission.level}
            />
          </Card>
        }
        deleteSuccess={marketPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={marketPermissionDelete.loading}
        error={marketPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={marketPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listMarketPermissionsAction({ ...marketPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteMarketPermissionAction());

          dispatch(resetUpdateMarketPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={marketPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New case study form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Link the case study</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Case study */}
                <FormDrawerSelectField2
                  error={marketCaseStudyCreate.error}
                  fieldName="case_study"
                  helperText="You can select an existing case study"
                  icon="book"
                  info={
                    <CaseStudyInfo
                      caseStudyId={marketCaseStudy.caseStudy}
                      query={caseStudyQueryDefault}
                    />
                  }
                  items={caseStudyList.case_studies}
                  label="Case study"
                  labelInfo="(required)"
                  loading={caseStudyList.loading}
                  placeholder="Select a case study"
                  query={caseStudyQuery.searchString}
                  setQueryObject={setCaseStudyQuery}
                  setQueryVariable="searchString"
                  setValueObject={setMarketCaseStudy}
                  setValueIdVariable="caseStudy"
                  setValueNameVariable="caseStudyName"
                  showInfo={marketCaseStudy.caseStudy}
                  value={marketCaseStudy.caseStudyName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={marketCaseStudyCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the market case study"
              setValueObject={setMarketCaseStudy}
              setValueVariable="notes"
              value={marketCaseStudy.notes}
            />
          </Card>
        }
        error={marketCaseStudyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add case study"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Linking case studies"
        icon="edit"
        isOpen={addCaseStudyDrawerIsOpen}
        onSave={addCaseStudy}
        refreshList={() => {
          dispatch(listMarketCaseStudiesAction({ market: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateMarketCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketCaseStudyCreate.success}
        saveSuccessMessage="Case study added."
        saving={marketCaseStudyCreate.loading}
        setIsOpen={setAddCaseStudyDrawerIsOpen}
        title="Add case study"
      />

      {/* Existing case study form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Link the case study</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Case study */}
                <FormDrawerSelectField2
                  error={marketCaseStudyUpdate.error}
                  fieldName="case_study"
                  helperText="You can select an existing case study"
                  icon="book"
                  info={
                    <CaseStudyInfo
                      caseStudyId={marketCaseStudy.caseStudy}
                      query={caseStudyQueryDefault}
                    />
                  }
                  items={caseStudyList.case_studies}
                  label="Case study"
                  labelInfo="(required)"
                  loading={caseStudyList.loading}
                  placeholder="Select a case study"
                  query={caseStudyQuery.searchString}
                  setQueryObject={setCaseStudyQuery}
                  setQueryVariable="searchString"
                  setValueObject={setMarketCaseStudy}
                  setValueIdVariable="caseStudy"
                  setValueNameVariable="caseStudyName"
                  showInfo={marketCaseStudy.caseStudy}
                  value={marketCaseStudy.caseStudyName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={marketCaseStudyUpdate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the market case study"
              setValueObject={setMarketCaseStudy}
              setValueVariable="notes"
              value={marketCaseStudy.notes}
            />
          </Card>
        }
        deleteSuccess={marketCaseStudyDelete.success}
        deleteSuccessMessage="Case study removed."
        deleting={marketCaseStudyDelete.loading}
        error={marketCaseStudyUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update case study"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Linking case studies"
        icon="edit"
        isOpen={updateCaseStudyDrawerIsOpen}
        loading={marketCaseStudyDetails.loading}
        onDelete={deleteCaseStudy}
        onSave={updateCaseStudy}
        refreshList={() => {
          dispatch(listMarketCaseStudiesAction({ ...marketCaseStudyQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteMarketCaseStudyAction());

          dispatch(resetUpdateMarketCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketCaseStudyUpdate.success}
        saveSuccessMessage="Case study updated."
        saving={marketCaseStudyUpdate.loading}
        setIsOpen={setUpdateCaseStudyDrawerIsOpen}
        title="Update case study"
      />

      {/* New insight form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the insight</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Insight */}
                <FormDrawerSelectField2
                  error={marketInsightCreate.error}
                  fieldName="insight"
                  helperText="You can select an existing insight"
                  icon="lightbulb"
                  info={
                    <InsightInfo
                      insightId={marketInsight.insight}
                      query={insightQueryDefault}
                    />
                  }
                  infoWidth="800px"
                  items={insightList.insights}
                  label="Insight"
                  labelInfo="(required)"
                  loading={insightList.loading}
                  placeholder="Select an insight"
                  query={insightQuery.searchString}
                  setQueryObject={setInsightQuery}
                  setQueryVariable="searchString"
                  setValueObject={setMarketInsight}
                  setValueIdVariable="insight"
                  setValueNameVariable="insightName"
                  showInfo={marketInsight.insight}
                  value={marketInsight.insightName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={marketInsightCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the market insight"
              setValueObject={setMarketInsight}
              setValueVariable="notes"
              value={marketInsight.notes}
            />
          </Card>
        }
        error={marketInsightCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add insight"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Managing insights"
        icon="edit"
        isOpen={addInsightDrawerIsOpen}
        onSave={addInsight}
        refreshList={() => {
          dispatch(listMarketInsightsAction({ market: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateMarketInsightAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketInsightCreate.success}
        saveSuccessMessage="Insight added."
        saving={marketInsightCreate.loading}
        setIsOpen={setAddInsightDrawerIsOpen}
        title="Add insight"
      />

      {/* Existing insight form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the insight</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Insight */}
                <FormDrawerSelectField2
                  error={marketInsightUpdate.error}
                  fieldName="insight"
                  helperText="You can select an existing insight"
                  icon="lightbulb"
                  info={
                    <InsightInfo
                      insightId={marketInsight.insight}
                      query={insightQueryDefault}
                    />
                  }
                  infoWidth="800px"
                  items={insightList.insights}
                  label="Insight"
                  labelInfo="(required)"
                  loading={insightList.loading}
                  placeholder="Select an insight"
                  query={insightQuery.searchString}
                  setQueryObject={setInsightQuery}
                  setQueryVariable="searchString"
                  setValueObject={setMarketInsight}
                  setValueIdVariable="insight"
                  setValueNameVariable="insightName"
                  showInfo={marketInsight.insight}
                  value={marketInsight.insightName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={marketInsightUpdate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the market insight"
              setValueObject={setMarketInsight}
              setValueVariable="notes"
              value={marketInsight.notes}
            />
          </Card>
        }
        deleteSuccess={marketInsightDelete.success}
        deleteSuccessMessage="Insight removed."
        deleting={marketInsightDelete.loading}
        error={marketInsightUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update insight"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Managing insights"
        icon="edit"
        isOpen={updateInsightDrawerIsOpen}
        loading={marketInsightDetails.loading}
        onDelete={deleteInsight}
        onSave={updateInsight}
        refreshList={() => {
          dispatch(listMarketInsightsAction({ ...marketInsightQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteMarketInsightAction());

          dispatch(resetUpdateMarketInsightAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketInsightUpdate.success}
        saveSuccessMessage="Insight updated."
        saving={marketInsightUpdate.loading}
        setIsOpen={setUpdateInsightDrawerIsOpen}
        title="Update insight"
      />

      {/* New need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need */}
                <FormDrawerSelectField2
                  error={marketNeedCreate.error}
                  fieldName="need"
                  helperText="You can select an existing need"
                  icon="user"
                  info={
                    <NeedInfo
                      needId={marketNeed.need}
                      query={needQueryDefault}
                    />
                  }
                  items={needList.needs}
                  label="Need"
                  labelInfo="(required)"
                  loading={needList.loading}
                  placeholder="Select a need"
                  query={needQuery.searchString}
                  setQueryObject={setNeedQuery}
                  setQueryVariable="searchString"
                  setValueObject={setMarketNeed}
                  setValueIdVariable="need"
                  setValueNameVariable="needName"
                  showInfo={marketNeed.need}
                  value={marketNeed.needName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={marketNeedCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the market need"
              setValueObject={setMarketNeed}
              setValueVariable="notes"
              value={marketNeed.notes}
            />
          </Card>
        }
        error={marketNeedCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add need"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Managing needs"
        icon="edit"
        isOpen={addNeedDrawerIsOpen}
        onSave={addNeed}
        refreshList={() => {
          dispatch(listMarketNeedsAction({ market: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateMarketNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketNeedCreate.success}
        saveSuccessMessage="Need added."
        saving={marketNeedCreate.loading}
        setIsOpen={setAddNeedDrawerIsOpen}
        title="Add need"
      />

      {/* Existing need form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the need</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Need */}
                <FormDrawerSelectField2
                  error={marketNeedUpdate.error}
                  fieldName="need"
                  helperText="You can select an existing need"
                  icon="user"
                  info={
                    <NeedInfo
                      needId={marketNeed.need}
                      query={needQueryDefault}
                    />
                  }
                  items={needList.needs}
                  label="Need"
                  labelInfo="(required)"
                  loading={needList.loading}
                  placeholder="Select a need"
                  query={needQuery.searchString}
                  setQueryObject={setNeedQuery}
                  setQueryVariable="searchString"
                  setValueObject={setMarketNeed}
                  setValueIdVariable="need"
                  setValueNameVariable="needName"
                  showInfo={marketNeed.need}
                  value={marketNeed.needName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={marketNeedUpdate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the market need"
              setValueObject={setMarketNeed}
              setValueVariable="notes"
              value={marketNeed.notes}
            />
          </Card>
        }
        deleteSuccess={marketNeedDelete.success}
        deleteSuccessMessage="Need removed."
        deleting={marketNeedDelete.loading}
        error={marketNeedUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update need"
            module="Markets"
            view="Detail"
          />
        }
        helpTitle="Managing needs"
        icon="edit"
        isOpen={updateNeedDrawerIsOpen}
        loading={marketNeedDetails.loading}
        onDelete={deleteNeed}
        onSave={updateNeed}
        refreshList={() => {
          dispatch(listMarketNeedsAction({ ...marketNeedQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteMarketNeedAction());

          dispatch(resetUpdateMarketNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={marketNeedUpdate.success}
        saveSuccessMessage="Need updated."
        saving={marketNeedUpdate.loading}
        setIsOpen={setUpdateNeedDrawerIsOpen}
        title="Update need"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Markets"
              onClick={() => {
                dispatch(resetListMarketsAction());

                navigate("/markets");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          marketDetails.market.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={marketDetails.loading}
        title={`${marketDetails.market.name}`}
      />

      {/* Error alerts */}
      {/* Case studies loading error */}
      {caseStudyList.error && (
        <ErrorAlert message="We couldn't load the case study list. Try refreshing the page." />
      )}
      {/* Insights loading error */}
      {insightList.error && (
        <ErrorAlert message="We couldn't load the insight list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Market delete error */}
      {marketDelete.error && (
        <ErrorAlert message="We couldn't delete the market. Try refreshing the page." />
      )}
      {/* Market loading error */}
      {marketDetails.error && (
        <ErrorAlert message="We couldn't load the market. Try refreshing the page." />
      )}
      {/* Market case studies loading error */}
      {marketCaseStudyList.error && (
        <ErrorAlert message="We couldn't load the market case studies. Try refreshing the page." />
      )}
      {/* Market insights loading error */}
      {marketInsightList.error && (
        <ErrorAlert message="We couldn't load the market insights. Try refreshing the page." />
      )}
      {/* Market needs loading error */}
      {marketNeedList.error && (
        <ErrorAlert message="We couldn't load the market needs. Try refreshing the page." />
      )}
      {/* Market permissions loading error */}
      {marketPermissionList.error && (
        <ErrorAlert message="We couldn't load the market permissions. Try refreshing the page." />
      )}
      {/* Needs loading error */}
      {needList.error && (
        <ErrorAlert message="We couldn't load the need list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Market"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="market-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="market-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          marketDetails.market.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listMarketDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={marketDetails.loading}
                          value={marketDetails.market.id}
                        />

                        {/* Market name */}
                        <TabSectionField
                          label="Market name"
                          loading={marketDetails.loading}
                          value={marketDetails.market.name}
                        />

                        {/* Description */}
                        <TabSectionField
                          label="Description"
                          loading={marketDetails.loading}
                          value={
                            marketDetails.market.description ? (
                              <TabSectionFieldCard
                                text={marketDetails.market.description}
                              />
                            ) : undefined
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={marketDetails.loading}
                          value={
                            <Tag
                              intent={
                                marketDetails.market.status === "Approved"
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {marketDetails.market.status}
                            </Tag>
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            marketDetails.market.restricted
                              ? "Users must have permission to access this market"
                              : "Everyone in the organisation can edit this market"
                          }
                          label="Access"
                          loading={marketDetails.loading}
                          value={
                            <Tag
                              intent={
                                marketDetails.market.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {marketDetails.market.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            marketDetails.market.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={marketDetails.loading}
                          value={
                            <NameValue
                              email={marketDetails.market.manager_name}
                              image={marketDetails.market.manager_profile_photo}
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update market information"
                    icon="heatmap"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(marketDetails.market.user_access_status === "owner" ||
                    marketDetails.market.user_access_status === "admin" ||
                    marketDetails.market.user_access_status === "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={marketPermissionList.loading}
                              setValueObject={setMarketPermissionQuery}
                              setValueVariable="searchString"
                              value={marketPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={marketPermissionList.market_permissions.map(
                              (market_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={market_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listMarketPermissionDetailsAction(
                                          market_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={market_permission}
                                  />
                                );
                              }
                            )}
                            count={marketPermissionList.count}
                            loading={marketPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={marketPermissionQuery.page}
                            setPageObject={setMarketPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, markets are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete market */}
                  {(marketDetails.market.user_access_status === "owner" ||
                    marketDetails.market.user_access_status === "admin" ||
                    marketDetails.market.user_access_status === "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={marketDetails.market.cannot_delete}
                              id="confirm-market-name"
                              onChange={(e) => {
                                setMarketDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the market's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={marketDeleteKey}
                            />

                            <Button
                              disabled={marketDetails.market.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={marketDelete.loading}
                              onClick={deleteMarket}
                              text="Delete this market"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{marketDetails.market.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the market"
                      icon="delete"
                      message={
                        marketDetails.market.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this market, you will lose all information associated with it."
                      }
                      messageIntent={
                        marketDetails.market.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete market"
                    />
                  )}
                </div>
              }
            />

            {/* Links */}
            <Tab
              id="market-needs-tab"
              title="Links"
              panel={
                <div>
                  {/* Needs */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          marketDetails.market.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddNeedDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search need"
                            searching={marketNeedList.loading}
                            setValueObject={setMarketNeedQuery}
                            setValueVariable="searchString"
                            value={marketNeedQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={marketNeedList.market_needs.map(
                            (need, i, { length }) => {
                              return (
                                <div key={need.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>{need.need_data.name}</div>

                                        <div style={{ marginLeft: "5px" }}>
                                          <InfoPopup
                                            content={
                                              <NeedInfo
                                                needData={need.need_data}
                                              />
                                            }
                                          />
                                        </div>
                                      </div>
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive={
                                          marketDetails.market
                                            .user_access_status !== "view"
                                        }
                                        onClick={() => {
                                          if (
                                            marketDetails.market
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listMarketNeedDetailsAction(
                                                need.id
                                              )
                                            );

                                            setUpdateNeedDrawerIsOpen(true);
                                          }
                                        }}
                                        text={
                                          need.notes ? (
                                            need.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={marketNeedList.count}
                          loading={marketNeedList.loading}
                          noResultsMessage="Add needs."
                          noResultsTitle="No needs found"
                          page={marketNeedQuery.page}
                          setPageObject={setMarketNeedQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Add needs to the market"
                    icon="form"
                    title="Needs"
                  />

                  {/* Case studies */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          marketDetails.market.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddCaseStudyDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search case study"
                            searching={marketCaseStudyList.loading}
                            setValueObject={setMarketCaseStudyQuery}
                            setValueVariable="searchString"
                            value={marketCaseStudyQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={marketCaseStudyList.market_case_studies.map(
                            (case_study, i, { length }) => {
                              return (
                                <div key={case_study.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>
                                          {case_study.case_study_data.name}
                                        </div>

                                        <div style={{ marginLeft: "5px" }}>
                                          <InfoPopup
                                            content={
                                              <CaseStudyInfo
                                                caseStudyData={
                                                  case_study.case_study_data
                                                }
                                              />
                                            }
                                          />
                                        </div>
                                      </div>
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive={
                                          marketDetails.market
                                            .user_access_status !== "view"
                                        }
                                        onClick={() => {
                                          if (
                                            marketDetails.market
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listMarketCaseStudyDetailsAction(
                                                case_study.id
                                              )
                                            );

                                            setUpdateCaseStudyDrawerIsOpen(
                                              true
                                            );
                                          }
                                        }}
                                        text={
                                          case_study.notes ? (
                                            case_study.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={marketCaseStudyList.count}
                          loading={marketCaseStudyList.loading}
                          noResultsMessage="Add case studies."
                          noResultsTitle="No case studies found"
                          page={marketCaseStudyQuery.page}
                          setPageObject={setMarketCaseStudyQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Add case studies to the market"
                    icon="book"
                    title="Case studies"
                  />

                  {/* Insights */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          marketDetails.market.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddInsightDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search insight"
                            searching={marketInsightList.loading}
                            setValueObject={setMarketInsightQuery}
                            setValueVariable="searchString"
                            value={marketInsightQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={marketInsightList.market_insights.map(
                            (insight, i, { length }) => {
                              return (
                                <div key={insight.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      <div
                                        style={{
                                          alignItems: "center",
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div>{insight.insight_data.name}</div>

                                        <div style={{ marginLeft: "5px" }}>
                                          <InfoPopup
                                            content={
                                              <InsightInfo
                                                insightData={
                                                  insight.insight_data
                                                }
                                              />
                                            }
                                            width="800px"
                                          />
                                        </div>
                                      </div>
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive={
                                          marketDetails.market
                                            .user_access_status !== "view"
                                        }
                                        onClick={() => {
                                          if (
                                            marketDetails.market
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listMarketInsightDetailsAction(
                                                insight.id
                                              )
                                            );

                                            setUpdateInsightDrawerIsOpen(true);
                                          }
                                        }}
                                        text={
                                          insight.notes ? (
                                            insight.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={marketInsightList.count}
                          loading={marketInsightList.loading}
                          noResultsMessage="Add insights."
                          noResultsTitle="No insights found"
                          page={marketInsightQuery.page}
                          setPageObject={setMarketInsightQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Add insights to the market"
                    icon="lightbulb"
                    title="Insights"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default MarketDetailScreen;
