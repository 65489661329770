// Library imports
import {
  Alignment,
  Button,
  Card,
  Collapse,
  Colors,
  H6,
  Tag,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import IconSquare from "../components/IconSquare";

// Main function
function TabSectionFieldCollapse({
  bottom,
  content,
  icon,
  tag,
  tagIntent,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Collapse variables
  const [collapse, setCollapse] = useState(true);

  // JSX UI code
  return (
    <Card
      style={{
        borderRadius: "5px",
        margin: bottom ? "0" : "0 0 20px 0",
        padding: "0",
      }}
    >
      <div
        style={{
          borderBottom: collapse
            ? undefined
            : lightMode
            ? "1px solid " + Colors.GRAY5
            : "1px solid" + Colors.GRAY1,
          width: "100%",
        }}
      >
        <Button
          alignText={Alignment.LEFT}
          children={
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 5px 10px 5px",
              }}
            >
              <H6 style={{ margin: "0" }}>{title}</H6>

              {tag && (
                <Tag
                  intent={tagIntent ? tagIntent : undefined}
                  minimal
                  style={{ height: "10px", marginLeft: "10px" }}
                >
                  {tag}
                </Tag>
              )}
            </div>
          }
          fill
          icon={
            <IconSquare
              darkBackground={Colors.DARK_GRAY4}
              darkColor={Colors.BLUE5}
              icon={icon}
              lightBackground={Colors.LIGHT_GRAY4}
              lightColor={Colors.BLUE2}
            />
          }
          large
          minimal
          onClick={() => {
            setCollapse(!collapse);
          }}
          rightIcon={collapse ? "chevron-down" : "chevron-up"}
        />
      </div>

      <Collapse isOpen={!collapse}>
        <div style={{ padding: "20px" }}>{content}</div>
      </Collapse>
    </Card>
  );
}

export default TabSectionFieldCollapse;
