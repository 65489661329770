// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Card,
  Colors,
  Divider,
  H5,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import CaseStudyInfo from "../components/CaseStudyInfo";
import ClientInfo from "../components/ClientInfo";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";
import ItemCardList from "../components/ItemCardList";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";

// Redux action imports
// Case study
import {
  createCaseStudyAction,
  listCaseStudiesAction,
  resetCreateCaseStudyAction,
  resetListCaseStudyDetailsAction,
} from "../actions/caseStudyActions";

// Client
import { listClientsAction } from "../actions/clientActions";

// Main function
function CaseStudyListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Case study
  const caseStudyCreate = useSelector((state) => state.caseStudyCreate);

  const caseStudyList = useSelector((state) => state.caseStudyList);

  // Client
  const clientList = useSelector((state) => state.clientList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Dialog variables
  // Case studies
  const [addCaseStudyDrawerIsOpen, setAddCaseStudyDrawerIsOpen] =
    useState(false);

  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Search and filter variables
  // Case study
  const caseStudyQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [caseStudyQuery, setCaseStudyQuery] = useState({
    ...caseStudyQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Form variables
  // Case study
  const caseStudyDefault = {
    client: "",
    description: "",
    name: "",
  };
  const [caseStudy, setCaseStudy] = useState({
    ...caseStudyDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Case study
    setCaseStudy({
      ...caseStudyDefault,
    });

    // Client
    setClientQuery({
      ...clientQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter case studies
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listCaseStudiesAction({ ...caseStudyQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [caseStudyQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Form submission
  // Add case study
  const addCaseStudy = () => {
    // Prepare data
    const case_study_data = new FormData();

    const client = new FormData();
    if (caseStudy.client) {
      case_study_data.append("client", caseStudy.client);
    } else {
      case_study_data.append("client", caseStudyDefault.client);

      if (caseStudy.clientName) {
        client.append("name", caseStudy.clientName);

        client.append("organisation", activeOrganisation.id);
      }
    }

    caseStudy.description
      ? case_study_data.append("description", caseStudy.description)
      : case_study_data.append("description", caseStudyDefault.description);

    caseStudy.name
      ? case_study_data.append("name", caseStudy.name)
      : case_study_data.append("name", caseStudyDefault.name);

    case_study_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createCaseStudyAction(client, case_study_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the case study</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Case study name */}
                <FormDrawerTextField2
                  error={caseStudyCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Case study name"
                  labelInfo="(required)"
                  placeholder="Enter the case study's name"
                  setValueObject={setCaseStudy}
                  setValueVariable="name"
                  value={caseStudy.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Client */}
                <FormDrawerSelectField2
                  allowCreate
                  error={caseStudyCreate.error}
                  fieldName="client"
                  helperText="You can create or select an existing client"
                  icon="office"
                  info={
                    <ClientInfo
                      clientId={caseStudy.client}
                      query={clientQueryDefault}
                    />
                  }
                  items={clientList.clients}
                  label="Client"
                  labelInfo="(required)"
                  loading={clientList.loading}
                  placeholder="Create or select a client"
                  query={clientQuery.searchString}
                  setQueryObject={setClientQuery}
                  setQueryVariable="searchString"
                  setValueObject={setCaseStudy}
                  setValueIdVariable="client"
                  setValueNameVariable="clientName"
                  showInfo={caseStudy.client}
                  value={caseStudy.clientName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={caseStudyCreate.error}
              fieldName="description"
              helperText="advaise.app can use this description to generate content from the organisation's knowledge base"
              label="Description"
              placeholder="Describe the case study"
              setValueObject={setCaseStudy}
              setValueVariable="description"
              value={caseStudy.description}
            />
          </Card>
        }
        error={caseStudyCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add case study"
            module="Case studies"
            view="List"
          />
        }
        helpTitle="Creating a case study"
        icon="edit"
        isOpen={addCaseStudyDrawerIsOpen}
        onSave={addCaseStudy}
        refreshList={() => {
          dispatch(
            listCaseStudiesAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateCaseStudyAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={caseStudyCreate.success}
        saveSuccessMessage="Case study created."
        saving={caseStudyCreate.loading}
        setIsOpen={setAddCaseStudyDrawerIsOpen}
        title="Add case study"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddCaseStudyDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Case studies"
      />

      {/* Error alerts */}
      {/* Case studies loading error */}
      {caseStudyList.error && (
        <ErrorAlert message="We couldn't load the case study list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Case studies"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      <TabsContainer
        children={
          <div style={{ padding: "0 10px 0 10px" }}>
            <div
              style={{
                margin: "0 auto 0 auto",
                maxWidth: "1600px",
              }}
            >
              <div style={{ margin: "20px 0 20px 10px", width: "300px" }}>
                <SearchField
                  placeholder="Search case study"
                  searching={caseStudyList.loading}
                  setValueObject={setCaseStudyQuery}
                  setValueVariable="searchString"
                  value={caseStudyQuery.searchString}
                />
              </div>

              <ItemCardList
                content={caseStudyList.case_studies.map((case_study) => {
                  return (
                    <ItemCard
                      content={
                        <div>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tag
                                intent={
                                  case_study.status === "Approved"
                                    ? "success"
                                    : "primary"
                                }
                                minimal
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                {case_study.status}
                              </Tag>

                              {!case_study.user_has_permission ? (
                                <Tag
                                  intent="danger"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  No access
                                </Tag>
                              ) : case_study.user_access_status === "view" ? (
                                <Tag
                                  intent="primary"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  View only
                                </Tag>
                              ) : undefined}
                            </div>

                            <InfoPopup
                              content={
                                <CaseStudyInfo
                                  caseStudyData={case_study}
                                  hideLink
                                />
                              }
                            />
                          </div>

                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                case_study.manager_profile_photo
                                  ? case_study.manager_profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "40px",
                                margin: "0 10px 0 0",
                                objectFit: "contain",
                                width: "40px",
                              }}
                            />

                            <Text ellipsize>{case_study.manager_name}</Text>
                          </div>
                        </div>
                      }
                      darkColor={Colors.VERMILION3}
                      height={case_study.manager ? 155 : 95}
                      icon="book"
                      interactive
                      key={case_study.id}
                      lightColor={Colors.VERMILION3}
                      onClick={() => {
                        if (case_study.user_has_permission) {
                          dispatch(resetListCaseStudyDetailsAction());

                          navigate(`/caseStudies/${case_study.id}`);
                        } else {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "danger",
                            message:
                              "You do not have access to this case study.",
                          });
                        }
                      }}
                      title={case_study.name}
                    />
                  );
                })}
                count={caseStudyList.count}
                loading={caseStudyList.loading}
                noMargin
                page={caseStudyQuery.page}
                setPageObject={setCaseStudyQuery}
                setPageVariable="page"
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default CaseStudyListScreen;
