// Constant imports
import {
  PROPOSAL_MILESTONE_LIST_REQUEST,
  PROPOSAL_MILESTONE_LIST_SUCCESS,
  PROPOSAL_MILESTONE_LIST_FAIL,
  PROPOSAL_MILESTONE_LIST_RESET,
  PROPOSAL_MILESTONE_CREATE_REQUEST,
  PROPOSAL_MILESTONE_CREATE_SUCCESS,
  PROPOSAL_MILESTONE_CREATE_FAIL,
  PROPOSAL_MILESTONE_CREATE_RESET,
  PROPOSAL_MILESTONE_DETAILS_REQUEST,
  PROPOSAL_MILESTONE_DETAILS_SUCCESS,
  PROPOSAL_MILESTONE_DETAILS_FAIL,
  PROPOSAL_MILESTONE_DETAILS_RESET,
  PROPOSAL_MILESTONE_UPDATE_REQUEST,
  PROPOSAL_MILESTONE_UPDATE_SUCCESS,
  PROPOSAL_MILESTONE_UPDATE_FAIL,
  PROPOSAL_MILESTONE_UPDATE_RESET,
  PROPOSAL_MILESTONE_DELETE_REQUEST,
  PROPOSAL_MILESTONE_DELETE_SUCCESS,
  PROPOSAL_MILESTONE_DELETE_FAIL,
  PROPOSAL_MILESTONE_DELETE_RESET,
} from "../constants/proposalMilestoneConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalMilestonesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_MILESTONE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalMilestones(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_MILESTONE_LIST_SUCCESS,

        payload: {
          count: data.count,
          totalValueOfMilestones: data.total_value_of_milestones.result,
          totalValueOfMilestonesQuote:
            data.total_value_of_milestones_quote.result,
          proposal_milestones: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_MILESTONE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalMilestonesAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_MILESTONE_LIST_RESET });
};

// Create
export const createProposalMilestoneAction =
  (proposal_milestone) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_MILESTONE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalMilestone(
        token,
        proposal_milestone
      );

      dispatch({
        type: PROPOSAL_MILESTONE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_MILESTONE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalMilestoneAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_MILESTONE_CREATE_RESET });
};

// List details
export const listProposalMilestoneDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_MILESTONE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalMilestone(id, token);

      dispatch({
        type: PROPOSAL_MILESTONE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_MILESTONE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalMilestoneDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_MILESTONE_DETAILS_RESET });
  };

// Update
export const updateProposalMilestoneAction =
  (id, proposal_milestone) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_MILESTONE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalMilestone(
        id,
        token,
        proposal_milestone
      );

      dispatch({
        type: PROPOSAL_MILESTONE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_MILESTONE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalMilestoneAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_MILESTONE_UPDATE_RESET });
};

// Delete
export const deleteProposalMilestoneAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_MILESTONE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalMilestone(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_MILESTONE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_MILESTONE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalMilestoneAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_MILESTONE_DELETE_RESET });
};
