// Constant imports
import {
  OPENING_EVALUATION_LIST_REQUEST,
  OPENING_EVALUATION_LIST_SUCCESS,
  OPENING_EVALUATION_LIST_FAIL,
  OPENING_EVALUATION_LIST_RESET,
  OPENING_EVALUATION_CREATE_REQUEST,
  OPENING_EVALUATION_CREATE_SUCCESS,
  OPENING_EVALUATION_CREATE_FAIL,
  OPENING_EVALUATION_CREATE_RESET,
  OPENING_EVALUATION_DETAILS_REQUEST,
  OPENING_EVALUATION_DETAILS_SUCCESS,
  OPENING_EVALUATION_DETAILS_FAIL,
  OPENING_EVALUATION_DETAILS_RESET,
  OPENING_EVALUATION_UPDATE_REQUEST,
  OPENING_EVALUATION_UPDATE_SUCCESS,
  OPENING_EVALUATION_UPDATE_FAIL,
  OPENING_EVALUATION_UPDATE_RESET,
  OPENING_EVALUATION_DELETE_REQUEST,
  OPENING_EVALUATION_DELETE_SUCCESS,
  OPENING_EVALUATION_DELETE_FAIL,
  OPENING_EVALUATION_DELETE_RESET,
} from "../constants/openingEvaluationConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listOpeningEvaluationsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningEvaluations(
        params,
        token
      );

      dispatch({
        type: OPENING_EVALUATION_LIST_SUCCESS,

        payload: {
          competencyProfile: data.competency_profile,
          count: data.count,
          opening_evaluations: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListOpeningEvaluationsAction = () => async (dispatch) => {
  dispatch({ type: OPENING_EVALUATION_LIST_RESET });
};

// Create
export const createOpeningEvaluationAction =
  (candidate, opening_evaluation) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (candidate) {
        if (candidate.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            candidate
          );

          opening_evaluation.append("candidate", data.id);
        }
      }

      const { data } = await cortixDataService.createOpeningEvaluation(
        token,
        opening_evaluation
      );

      dispatch({
        type: OPENING_EVALUATION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateOpeningEvaluationAction = () => async (dispatch) => {
  dispatch({ type: OPENING_EVALUATION_CREATE_RESET });
};

// List details
export const listOpeningEvaluationDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningEvaluation(id, token);

      dispatch({
        type: OPENING_EVALUATION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListOpeningEvaluationDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_EVALUATION_DETAILS_RESET });
  };

// Update
export const updateOpeningEvaluationAction =
  (id, opening_evaluation) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOpeningEvaluation(
        id,
        token,
        opening_evaluation
      );

      dispatch({
        type: OPENING_EVALUATION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningEvaluationAction = () => async (dispatch) => {
  dispatch({ type: OPENING_EVALUATION_UPDATE_RESET });
};

// Delete
export const deleteOpeningEvaluationAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_EVALUATION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteOpeningEvaluation(
        id,
        token
      );

      dispatch({
        type: OPENING_EVALUATION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_EVALUATION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteOpeningEvaluationAction = () => async (dispatch) => {
  dispatch({ type: OPENING_EVALUATION_DELETE_RESET });
};
