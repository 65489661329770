// Constant imports
import {
  STAFF_DEVELOPMENT_PLAN_LIST_REQUEST,
  STAFF_DEVELOPMENT_PLAN_LIST_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_LIST_FAIL,
  STAFF_DEVELOPMENT_PLAN_LIST_RESET,
  STAFF_DEVELOPMENT_PLAN_CREATE_REQUEST,
  STAFF_DEVELOPMENT_PLAN_CREATE_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_CREATE_FAIL,
  STAFF_DEVELOPMENT_PLAN_CREATE_RESET,
  STAFF_DEVELOPMENT_PLAN_DETAILS_REQUEST,
  STAFF_DEVELOPMENT_PLAN_DETAILS_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_DETAILS_FAIL,
  STAFF_DEVELOPMENT_PLAN_DETAILS_RESET,
  STAFF_DEVELOPMENT_PLAN_UPDATE_REQUEST,
  STAFF_DEVELOPMENT_PLAN_UPDATE_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_UPDATE_FAIL,
  STAFF_DEVELOPMENT_PLAN_UPDATE_RESET,
  STAFF_DEVELOPMENT_PLAN_DELETE_REQUEST,
  STAFF_DEVELOPMENT_PLAN_DELETE_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_DELETE_FAIL,
  STAFF_DEVELOPMENT_PLAN_DELETE_RESET,
} from "../constants/staffDevelopmentPlanConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffDevelopmentPlansAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_DEVELOPMENT_PLAN_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffDevelopmentPlans(
        params,
        token
      );

      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfDevelopmentPlansCompleted:
            data.number_of_development_plans_completed,
          numberOfDevelopmentPlansInProgress:
            data.number_of_development_plans_in_progress,
          numberOfDevelopmentPlansOnHold:
            data.number_of_development_plans_on_hold,
          staff_development_plans: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListStaffDevelopmentPlansAction = () => async (dispatch) => {
  dispatch({ type: STAFF_DEVELOPMENT_PLAN_LIST_RESET });
};

// Create
export const createStaffDevelopmentPlanAction =
  (staff_development_plan) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_DEVELOPMENT_PLAN_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createStaffDevelopmentPlan(
        token,
        staff_development_plan
      );

      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateStaffDevelopmentPlanAction = () => async (dispatch) => {
  dispatch({ type: STAFF_DEVELOPMENT_PLAN_CREATE_RESET });
};

// List details
export const listStaffDevelopmentPlanDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_DEVELOPMENT_PLAN_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getStaffDevelopmentPlan(
        id,
        token
      );

      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListStaffDevelopmentPlanDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: STAFF_DEVELOPMENT_PLAN_DETAILS_RESET });
  };

// Update
export const updateStaffDevelopmentPlanAction =
  (id, staff_development_plan) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_DEVELOPMENT_PLAN_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateStaffDevelopmentPlan(
        id,
        token,
        staff_development_plan
      );

      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateStaffDevelopmentPlanAction = () => async (dispatch) => {
  dispatch({ type: STAFF_DEVELOPMENT_PLAN_UPDATE_RESET });
};

// Delete
export const deleteStaffDevelopmentPlanAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_DEVELOPMENT_PLAN_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteStaffDevelopmentPlan(
        id,
        token
      );

      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_DEVELOPMENT_PLAN_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteStaffDevelopmentPlanAction = () => async (dispatch) => {
  dispatch({ type: STAFF_DEVELOPMENT_PLAN_DELETE_RESET });
};
