// Constant imports
import {
  OPENING_APPLICATION_CREATE_REQUEST,
  OPENING_APPLICATION_CREATE_SUCCESS,
  OPENING_APPLICATION_CREATE_FAIL,
  OPENING_APPLICATION_CREATE_RESET,
} from "../constants/openingApplicationConstants";

// Create
export const openingApplicationCreateReducer = (
  state = {
    loading: false,
    opening_application: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_APPLICATION_CREATE_REQUEST:
      return {
        loading: true,
        opening_application: state.opening_application,
        success: false,
        error: "",
      };

    case OPENING_APPLICATION_CREATE_SUCCESS:
      return {
        loading: false,
        opening_application: action.payload,
        success: true,
        error: "",
      };

    case OPENING_APPLICATION_CREATE_FAIL:
      return {
        loading: false,
        opening_application: state.opening_application,
        success: false,
        error: action.payload,
      };

    case OPENING_APPLICATION_CREATE_RESET:
      return {
        loading: false,
        opening_application: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
