export const MEMBERSHIP_LIST_REQUEST = "MEMBERSHIP_LIST_REQUEST";
export const MEMBERSHIP_LIST_SUCCESS = "MEMBERSHIP_LIST_SUCCESS";
export const MEMBERSHIP_LIST_FAIL = "MEMBERSHIP_LIST_FAIL";
export const MEMBERSHIP_LIST_RESET = "MEMBERSHIP_LIST_RESET";

export const MEMBERSHIP_CREATE_REQUEST = "MEMBERSHIP_CREATE_REQUEST";
export const MEMBERSHIP_CREATE_SUCCESS = "MEMBERSHIP_CREATE_SUCCESS";
export const MEMBERSHIP_CREATE_FAIL = "MEMBERSHIP_CREATE_FAIL";
export const MEMBERSHIP_CREATE_RESET = "MEMBERSHIP_CREATE_RESET";

export const MEMBERSHIP_DETAILS_REQUEST = "MEMBERSHIP_DETAILS_REQUEST";
export const MEMBERSHIP_DETAILS_SUCCESS = "MEMBERSHIP_DETAILS_SUCCESS";
export const MEMBERSHIP_DETAILS_FAIL = "MEMBERSHIP_DETAILS_FAIL";
export const MEMBERSHIP_DETAILS_RESET = "MEMBERSHIP_DETAILS_RESET";

export const MEMBERSHIP_UPDATE_REQUEST = "MEMBERSHIP_UPDATE_REQUEST";
export const MEMBERSHIP_UPDATE_SUCCESS = "MEMBERSHIP_UPDATE_SUCCESS";
export const MEMBERSHIP_UPDATE_FAIL = "MEMBERSHIP_UPDATE_FAIL";
export const MEMBERSHIP_UPDATE_RESET = "MEMBERSHIP_UPDATE_RESET";

export const MEMBERSHIP_DELETE_REQUEST = "MEMBERSHIP_DELETE_REQUEST";
export const MEMBERSHIP_DELETE_SUCCESS = "MEMBERSHIP_DELETE_SUCCESS";
export const MEMBERSHIP_DELETE_FAIL = "MEMBERSHIP_DELETE_FAIL";
export const MEMBERSHIP_DELETE_RESET = "MEMBERSHIP_DELETE_RESET";
