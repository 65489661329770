// Constant imports
import {
  ENGAGEMENT_DEPENDENCY_LIST_REQUEST,
  ENGAGEMENT_DEPENDENCY_LIST_SUCCESS,
  ENGAGEMENT_DEPENDENCY_LIST_FAIL,
  ENGAGEMENT_DEPENDENCY_LIST_RESET,
  ENGAGEMENT_DEPENDENCY_CREATE_REQUEST,
  ENGAGEMENT_DEPENDENCY_CREATE_SUCCESS,
  ENGAGEMENT_DEPENDENCY_CREATE_FAIL,
  ENGAGEMENT_DEPENDENCY_CREATE_RESET,
  ENGAGEMENT_DEPENDENCY_DETAILS_REQUEST,
  ENGAGEMENT_DEPENDENCY_DETAILS_SUCCESS,
  ENGAGEMENT_DEPENDENCY_DETAILS_FAIL,
  ENGAGEMENT_DEPENDENCY_DETAILS_RESET,
  ENGAGEMENT_DEPENDENCY_UPDATE_REQUEST,
  ENGAGEMENT_DEPENDENCY_UPDATE_SUCCESS,
  ENGAGEMENT_DEPENDENCY_UPDATE_FAIL,
  ENGAGEMENT_DEPENDENCY_UPDATE_RESET,
  ENGAGEMENT_DEPENDENCY_DELETE_REQUEST,
  ENGAGEMENT_DEPENDENCY_DELETE_SUCCESS,
  ENGAGEMENT_DEPENDENCY_DELETE_FAIL,
  ENGAGEMENT_DEPENDENCY_DELETE_RESET,
} from "../constants/engagementDependencyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementDependenciesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DEPENDENCY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementDependencies(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_DEPENDENCY_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfDependenciesClosed: data.number_of_dependencies_closed,
          numberOfDependenciesOpen: data.number_of_dependencies_open,
          engagement_dependencies: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DEPENDENCY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementDependenciesAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DEPENDENCY_LIST_RESET });
};

// Create
export const createEngagementDependencyAction =
  (engagement_dependency) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DEPENDENCY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementDependency(
        token,
        engagement_dependency
      );

      dispatch({
        type: ENGAGEMENT_DEPENDENCY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DEPENDENCY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementDependencyAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DEPENDENCY_CREATE_RESET });
};

// List details
export const listEngagementDependencyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DEPENDENCY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementDependency(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_DEPENDENCY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DEPENDENCY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementDependencyDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_DEPENDENCY_DETAILS_RESET });
  };

// Update
export const updateEngagementDependencyAction =
  (id, engagement_dependency) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DEPENDENCY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementDependency(
        id,
        token,
        engagement_dependency
      );

      dispatch({
        type: ENGAGEMENT_DEPENDENCY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DEPENDENCY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementDependencyAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DEPENDENCY_UPDATE_RESET });
};

// Delete
export const deleteEngagementDependencyAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DEPENDENCY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementDependency(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_DEPENDENCY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DEPENDENCY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementDependencyAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DEPENDENCY_DELETE_RESET });
};
