// Library imports
import { Button, Classes } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import React from "react";

// Main function
function TableColumnHead({ active, children, disabled, title, width }) {
  // JSX UI code
  return (
    <th
      style={{
        padding: "5px",
        width: width,
      }}
    >
      <Popover2 content={children} disabled={disabled} fill>
        <Button
          active={active}
          alignText="left"
          intent={active ? "primary" : undefined}
          minimal
          rightIcon={disabled ? undefined : "caret-down"}
          text={
            <span className={Classes.TEXT_SMALL}>
              <strong>{title}</strong>
            </span>
          }
        />
      </Popover2>
    </th>
  );
}

export default TableColumnHead;
