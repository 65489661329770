// Library imports
import {
  Card,
  Classes,
  Colors,
  Divider,
  H6,
  HTMLTable,
  NonIdealState,
  Tag,
  Text,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Main function
function CompetencyChart({ data, roleFitScore, roleDescription, roleTitle }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Custom tooltip
  const customToolTip = ({ payload, label, active }) => {
    if (active) {
      return (
        <Card
          style={{
            width: "300px",
          }}
        >
          <H6>{payload[0].payload.competency}</H6>

          <Tag
            intent={
              payload[0].payload.staff_level > payload[0].payload.role_level
                ? "success"
                : payload[0].payload.staff_level ===
                  payload[0].payload.role_level
                ? "primary"
                : "danger"
            }
            style={{
              textAlign: "center",
              width: "fit-content",
            }}
          >
            {payload[0].payload.staff_level > payload[0].payload.role_level
              ? "Above expected"
              : payload[0].payload.staff_level === payload[0].payload.role_level
              ? "As expected"
              : "Below expected"}
          </Tag>

          <HTMLTable style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td>
                  {payload[0].payload.staff_level === 5 ? (
                    <Tag intent="primary" minimal>
                      Expert
                    </Tag>
                  ) : payload[0].payload.staff_level >= 3 ? (
                    <Tag intent="primary" minimal>
                      Intermediate
                    </Tag>
                  ) : (
                    <Tag intent="primary" minimal>
                      Beginner
                    </Tag>
                  )}
                </td>

                <td>
                  {payload[0].payload.role_level >= 4 ? (
                    <Tag intent="primary" minimal>
                      Expert
                    </Tag>
                  ) : payload[0].payload.role_level >= 2 ? (
                    <Tag intent="primary" minimal>
                      Intermediate
                    </Tag>
                  ) : (
                    <Tag intent="primary" minimal>
                      Beginner
                    </Tag>
                  )}
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th>Current</th>

                <th>Required</th>
              </tr>
            </thead>
          </HTMLTable>
        </Card>
      );
    }

    return null;
  };

  // Legend formatter
  const legendFormatter = (value, entry, index) => {
    return (
      <span style={{ fontSize: 12 }}>
        {value === "role_level" ? "Required" : "Current"}
      </span>
    );
  };

  // Tick formatter
  const tickFormatter = (value, index) => {
    const char_limit = 20;

    if (value.length < char_limit) return value;

    return `${value.substring(0, char_limit)}...`;
  };

  // JSX UI code
  return (
    <div style={{ display: "flex" }}>
      {/* Role */}
      <Card
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          width: "30%",
        }}
      >
        <H6>
          <Text ellipsize>{roleTitle}</Text>
        </H6>

        <Divider />

        {roleFitScore !== null ? (
          <div style={{ margin: "10px 0 0 0" }}>
            <Tag
              intent={
                roleFitScore >= 80
                  ? "success"
                  : roleFitScore >= 60
                  ? "warning"
                  : "danger"
              }
              large
              minimal
              style={{ textAlign: "center", width: "fit-content" }}
            >
              {roleFitScore >= 80
                ? "Strong fit"
                : roleFitScore >= 60
                ? "Moderate fit"
                : "Poor fit"}
            </Tag>
          </div>
        ) : undefined}

        <div
          style={{
            height: roleFitScore !== null ? "180px" : "210px",
            margin: "10px -20px 0 0",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          {roleDescription ? (
            <span style={{ whiteSpace: "pre-wrap" }}>{roleDescription}</span>
          ) : (
            <NonIdealState
              description="Assign a defined role to the team member."
              icon="hat"
              title="Role description unavailable"
            />
          )}
        </div>
      </Card>

      {/* Competency chart */}
      <Card
        style={{
          alignItems: "center",
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          display: "flex",
          margin: "0 0 0 10px",
          overflowX: "auto",
          overflowY: "hidden",
          width: "70%",
        }}
      >
        {data && data.length > 0 ? (
          <ComposedChart
            data={data}
            height={250}
            margin={{
              bottom: 5,
              left: 5,
              right: 5,
              top: 5,
            }}
            width={150 * data.length}
          >
            <defs>
              <linearGradient id="colorRole" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                  stopOpacity={0.9}
                />
                <stop
                  offset="95%"
                  stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                  stopOpacity={0.7}
                />
              </linearGradient>

              <linearGradient id="colorStaff" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                  stopOpacity={0.9}
                />
                <stop
                  offset="95%"
                  stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                  stopOpacity={0.7}
                />
              </linearGradient>
            </defs>

            <Area
              dataKey="staff_level"
              dot
              fill="url(#colorStaff)"
              stroke={lightMode ? Colors.GREEN3 : Colors.GREEN5}
            />

            <Bar
              barSize={65}
              dataKey="role_level"
              fill="url(#colorRole)"
              radius={[5, 5, 0, 0]}
            />

            <Legend
              formatter={legendFormatter}
              wrapperStyle={{
                backgroundColor: lightMode ? Colors.WHITE : Colors.DARK_GRAY3,
                border: `1px solid ${
                  lightMode ? Colors.LIGHT_GRAY1 : Colors.DARK_GRAY5
                }`,
                borderRadius: 3,
                lineHeight: "30px",
                position: "sticky",
                width: "175px",
              }}
            />

            <Tooltip content={customToolTip} />

            <XAxis
              dataKey="competency"
              stroke={lightMode ? Colors.BLACK : Colors.WHITE}
              style={{ fontSize: 12 }}
              tickFormatter={tickFormatter}
              type="category"
            />

            <YAxis
              domain={[0, 5]}
              hide
              stroke={lightMode ? Colors.BLACK : Colors.WHITE}
              type="number"
            />
          </ComposedChart>
        ) : (
          <div style={{ width: "100%" }}>
            <NonIdealState
              description="Assign a role with defined competencies to the team member."
              icon="search"
              title="No competencies found"
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default CompetencyChart;
