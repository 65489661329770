// Library imports
import { Button, ButtonGroup, Card } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

// Component imports
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";

// Utility imports
import checkObjectsEqual from "../utilities/checkObjectsEqual";
import convertBase64ToBlob from "../utilities/convertBase64ToBlob";

// Main function
function FormDrawerArrayFileField({
  array,
  arrayField,
  arrayPosition,
  error,
  label,
  placeholder,
  setValueObject,
  setValueVariable,
  showControls,
}) {
  // Initialise
  const [initialised, setInitialised] = useState(false);

  const objectDefault = {
    file: "",
    fileName: "",
    label: "",
    left: 0,
    position: "top",
    ratio: 0,
    width: 100,
  };
  const [object, setObject] = useState({ ...objectDefault });

  useEffect(() => {
    if (array && arrayField && arrayPosition !== null) {
      if (
        array[arrayPosition][arrayField] &&
        array[arrayPosition][arrayField].data
      ) {
        const blob = convertBase64ToBlob(
          array[arrayPosition][arrayField].data,
          "image/jpg"
        );

        const label = array[arrayPosition][arrayField].label
          ? array[arrayPosition][arrayField].label
          : objectDefault.label;

        const left =
          array[arrayPosition][arrayField].left !== null
            ? array[arrayPosition][arrayField].left
            : objectDefault.left;

        const name = array[arrayPosition][arrayField].name
          ? array[arrayPosition][arrayField].name
          : objectDefault.name;

        const position = array[arrayPosition][arrayField].position
          ? array[arrayPosition][arrayField].position
          : objectDefault.position;

        const ratio =
          array[arrayPosition][arrayField].ratio !== null
            ? array[arrayPosition][arrayField].ratio
            : objectDefault.ratio;

        const width =
          array[arrayPosition][arrayField].width !== null
            ? array[arrayPosition][arrayField].width
            : objectDefault.width;

        const newValue = {
          file: blob,
          fileName: name,
          label: label,
          left: left,
          position: position,
          ratio: ratio,
          width: width,
        };

        if (!checkObjectsEqual(newValue, object)) {
          setObject({
            ...newValue,
          });
        }
      }

      setInitialised(true);
    }
    // eslint-disable-next-line
  }, [array, arrayField, arrayPosition]);

  // Update
  const update = (data, label, left, name, position, ratio, width) => {
    let newArray = Object.assign([], array);

    const item = newArray[arrayPosition];

    newArray.splice(arrayPosition, 1, {
      ...item,
      [arrayField]: {
        data: data,
        label: label,
        left: left,
        name: name,
        position: position,
        ratio: ratio,
        width: width,
      },
    });

    setValueObject((prevState) => {
      return {
        ...prevState,
        [setValueVariable]: newArray,
      };
    });
  };

  useEffect(() => {
    if (initialised && object.file !== null) {
      const fileName = object.fileName
        ? object.fileName
        : objectDefault.fileName;

      const label = object.label ? object.label : objectDefault.label;

      const left = object.left !== null ? object.left : objectDefault.left;

      const position = object.position
        ? object.position
        : objectDefault.position;

      const ratio = object.ratio !== null ? object.ratio : objectDefault.ratio;

      const width = object.width !== null ? object.width : objectDefault.width;

      if (object.file === "") {
        update("", label, left, fileName, position, ratio, width);
      } else {
        const timeOutId = setTimeout(() => {
          const reader = new FileReader();

          reader.onloadend = () => {
            update(
              reader.result,
              object.label,
              object.left,
              object.fileName,
              object.position,
              object.ratio,
              object.width
            );
          };

          reader.readAsDataURL(object.file);
        }, 100);

        return () => clearTimeout(timeOutId);
      }
    }
    // eslint-disable-next-line
  }, [object]);

  // JSX UI code
  return (
    <Card style={{ padding: "15px" }}>
      {/* File */}
      <FormDrawerFileField2
        error={error}
        fileName={`file_${arrayField}_${arrayPosition}`}
        label={label}
        placeholder={placeholder}
        setValueObject={setObject}
        setValueFileVariable="file"
        setValueNameVariable="fileName"
        valueFile={object.file}
        valueName={object.fileName}
      />

      {showControls && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "15px 0 15px 0",
            }}
          >
            {/* Position */}
            <ButtonGroup>
              <Button
                icon="alignment-top"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      position: "top",
                    };
                  });
                }}
              />

              <Button
                icon="alignment-bottom"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      position: "bottom",
                    };
                  });
                }}
              />
            </ButtonGroup>

            {/* Width */}
            <ButtonGroup>
              <Button
                disabled={object.width === 200}
                icon="zoom-in"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      width: object.width + 10,
                    };
                  });
                }}
              />

              <Button
                disabled={object.width === 10}
                icon="zoom-out"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      width: object.width - 10,
                    };
                  });
                }}
              />
            </ButtonGroup>

            {/* Margin horizontal */}
            <ButtonGroup>
              <Button
                disabled={object.left === -100}
                icon="caret-left"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      left: object.left - 10,
                    };
                  });
                }}
              />

              <Button
                disabled={object.left === 100}
                icon="caret-right"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      left: object.left + 10,
                    };
                  });
                }}
              />
            </ButtonGroup>

            {/* Aspect ratio */}
            <ButtonGroup>
              <Button
                disabled={object.ratio === -9}
                icon="expand-all"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      ratio: object.ratio - 1,
                    };
                  });
                }}
              />

              <Button
                disabled={object.ratio === 9}
                icon="collapse-all"
                onClick={() => {
                  setObject((prevState) => {
                    return {
                      ...prevState,
                      ratio: object.ratio + 1,
                    };
                  });
                }}
              />
            </ButtonGroup>

            {/* Fit */}
            <Button
              icon="zoom-to-fit"
              onClick={() => {
                setObject((prevState) => {
                  return {
                    ...prevState,
                    left: 0,
                    ratio: 0,
                    width: 100,
                  };
                });
              }}
            />
          </div>

          {/* Label */}
          <FormDrawerTextField2
            error={error}
            fieldName={`label_${arrayField}_${arrayPosition}`}
            placeholder="Enter the image title"
            setValueObject={setObject}
            setValueVariable="label"
            value={object.label}
          />
        </>
      )}
    </Card>
  );
}

export default FormDrawerArrayFileField;
