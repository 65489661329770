// Constant imports
import {
  PROPOSAL_LIST_REQUEST,
  PROPOSAL_LIST_SUCCESS,
  PROPOSAL_LIST_FAIL,
  PROPOSAL_LIST_RESET,
  PROPOSAL_CREATE_REQUEST,
  PROPOSAL_CREATE_SUCCESS,
  PROPOSAL_CREATE_FAIL,
  PROPOSAL_CREATE_RESET,
  PROPOSAL_DETAILS_REQUEST,
  PROPOSAL_DETAILS_SUCCESS,
  PROPOSAL_DETAILS_FAIL,
  PROPOSAL_DETAILS_RESET,
  PROPOSAL_UPDATE_REQUEST,
  PROPOSAL_UPDATE_SUCCESS,
  PROPOSAL_UPDATE_FAIL,
  PROPOSAL_UPDATE_RESET,
  PROPOSAL_DELETE_REQUEST,
  PROPOSAL_DELETE_SUCCESS,
  PROPOSAL_DELETE_FAIL,
  PROPOSAL_DELETE_RESET,
  PROPOSAL_PREDICT_WIN_REQUEST,
  PROPOSAL_PREDICT_WIN_SUCCESS,
  PROPOSAL_PREDICT_WIN_FAIL,
  PROPOSAL_PREDICT_WIN_RESET,
  PROPOSAL_DOWNLOAD_REQUEST,
  PROPOSAL_DOWNLOAD_SUCCESS,
  PROPOSAL_DOWNLOAD_FAIL,
  PROPOSAL_DOWNLOAD_RESET,
} from "../constants/proposalConstants";

// List all
export const proposalListReducer = (
  state = {
    loading: false,
    count: 0,
    proposals: [],
    averageValueOfProposalsCancelled: 0,
    averageValueOfProposalsInProgress: 0,
    averageValueOfProposalsLost: 0,
    averageValueOfProposalsQualifying: 0,
    averageValueOfProposalsSubmitted: 0,
    averageValueOfProposalsWon: 0,
    numberOfProposalsCancelled: 0,
    numberOfProposalsInProgress: 0,
    numberOfProposalsLost: 0,
    numberOfProposalsQualifying: 0,
    numberOfProposalsSubmitted: 0,
    numberOfProposalsWon: 0,
    totalValueOfProposalsCancelled: 0,
    totalValueOfProposalsInProgress: 0,
    totalValueOfProposalsLost: 0,
    totalValueOfProposalsQualifying: 0,
    totalValueOfProposalsSubmitted: 0,
    totalValueOfProposalsWon: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposals: action.payload.proposals,
        averageValueOfProposalsCancelled:
          action.payload.averageValueOfProposalsCancelled,
        averageValueOfProposalsInProgress:
          action.payload.averageValueOfProposalsInProgress,
        averageValueOfProposalsLost: action.payload.averageValueOfProposalsLost,
        averageValueOfProposalsQualifying:
          action.payload.averageValueOfProposalsQualifying,
        averageValueOfProposalsSubmitted:
          action.payload.averageValueOfProposalsSubmitted,
        averageValueOfProposalsWon: action.payload.averageValueOfProposalsWon,
        numberOfProposalsCancelled: action.payload.numberOfProposalsCancelled,
        numberOfProposalsInProgress: action.payload.numberOfProposalsInProgress,
        numberOfProposalsLost: action.payload.numberOfProposalsLost,
        numberOfProposalsQualifying: action.payload.numberOfProposalsQualifying,
        numberOfProposalsSubmitted: action.payload.numberOfProposalsSubmitted,
        numberOfProposalsWon: action.payload.numberOfProposalsWon,
        totalValueOfProposalsCancelled:
          action.payload.totalValueOfProposalsCancelled,
        totalValueOfProposalsInProgress:
          action.payload.totalValueOfProposalsInProgress,
        totalValueOfProposalsLost: action.payload.totalValueOfProposalsLost,
        totalValueOfProposalsQualifying:
          action.payload.totalValueOfProposalsQualifying,
        totalValueOfProposalsSubmitted:
          action.payload.totalValueOfProposalsSubmitted,
        totalValueOfProposalsWon: action.payload.totalValueOfProposalsWon,
        success: true,
        error: "",
      };

    case PROPOSAL_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposals: [],
        averageValueOfProposalsCancelled: 0,
        averageValueOfProposalsInProgress: 0,
        averageValueOfProposalsLost: 0,
        averageValueOfProposalsQualifying: 0,
        averageValueOfProposalsSubmitted: 0,
        averageValueOfProposalsWon: 0,
        numberOfProposalsCancelled: 0,
        numberOfProposalsInProgress: 0,
        numberOfProposalsLost: 0,
        numberOfProposalsQualifying: 0,
        numberOfProposalsSubmitted: 0,
        numberOfProposalsWon: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposals: [],
        averageValueOfProposalsCancelled: 0,
        averageValueOfProposalsInProgress: 0,
        averageValueOfProposalsLost: 0,
        averageValueOfProposalsQualifying: 0,
        averageValueOfProposalsSubmitted: 0,
        averageValueOfProposalsWon: 0,
        numberOfProposalsCancelled: 0,
        numberOfProposalsInProgress: 0,
        numberOfProposalsLost: 0,
        numberOfProposalsQualifying: 0,
        numberOfProposalsSubmitted: 0,
        numberOfProposalsWon: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalCreateReducer = (
  state = { loading: false, proposal: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CREATE_REQUEST:
      return {
        loading: true,
        proposal: state.proposal,
        success: false,
        error: "",
      };

    case PROPOSAL_CREATE_SUCCESS:
      return {
        loading: false,
        proposal: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CREATE_FAIL:
      return {
        loading: false,
        proposal: state.proposal,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CREATE_RESET:
      return { loading: false, proposal: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const proposalDetailsReducer = (
  state = { loading: false, proposal: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_DETAILS_REQUEST:
      return {
        loading: true,
        proposal: state.proposal,
        success: false,
        error: "",
      };

    case PROPOSAL_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_DETAILS_FAIL:
      return {
        loading: false,
        proposal: state.proposal,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_DETAILS_RESET:
      return { loading: false, proposal: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const proposalUpdateReducer = (
  state = { loading: false, proposal: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_UPDATE_REQUEST:
      return {
        loading: true,
        proposal: state.proposal,
        success: false,
        error: "",
      };

    case PROPOSAL_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_UPDATE_FAIL:
      return {
        loading: false,
        proposal: state.proposal,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_UPDATE_RESET:
      return { loading: false, proposal: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const proposalDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Predict win
export const proposalPredictWinReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_PREDICT_WIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_PREDICT_WIN_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_PREDICT_WIN_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_PREDICT_WIN_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const proposalDownloadReducer = (
  state = { loading: false, result: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_DOWNLOAD_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_DOWNLOAD_RESET:
      return { loading: false, result: [], success: false, error: "" };

    default:
      return state;
  }
};
