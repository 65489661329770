// Main function
const generateYearList = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 3;
  const endYear = currentYear + 3;

  const yearList = [];

  for (var i = startYear; i <= endYear; i++) {
    yearList.push({
      display: i,
      icon: "",
      value: i,
    });
  }

  return yearList;
};

export default generateYearList;
