// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Card,
  Colors,
  Divider,
  H5,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";
import ItemCardList from "../components/ItemCardList";
import SearchField from "../components/SearchField";
import ServiceInfo from "../components/ServiceInfo";
import ServiceProposalAverageMarginCard from "../components/ServiceProposalAverageMarginCard";
import ServiceProposalPipelineCard from "../components/ServiceProposalPipelineCard";
import ServiceProposalTotalFeesCard from "../components/ServiceProposalTotalFeesCard";
import TabsContainer from "../components/TabsContainer";

// Redux action imports
// Service
import {
  createServiceAction,
  listServicesAction,
  resetCreateServiceAction,
  resetListServiceDetailsAction,
} from "../actions/serviceActions";

// Main function
function ServiceListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Service
  const serviceCreate = useSelector((state) => state.serviceCreate);

  const serviceList = useSelector((state) => state.serviceList);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Services
  const [addServiceDrawerIsOpen, setAddServiceDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Service
  const serviceQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [serviceQuery, setServiceQuery] = useState({
    ...serviceQueryDefault,
  });

  // Form variables
  // Service
  const serviceDefault = {
    description: "",
    name: "",
  };
  const [service, setService] = useState({
    ...serviceDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Service
    setService({
      ...serviceDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter services
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listServicesAction({ ...serviceQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [serviceQuery]);

  // Form submission
  // Add service
  const addService = () => {
    // Prepare data
    const service_data = new FormData();

    service.description
      ? service_data.append("description", service.description)
      : service_data.append("description", serviceDefault.description);

    service.name
      ? service_data.append("name", service.name)
      : service_data.append("name", serviceDefault.name);

    service_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createServiceAction(service_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Describe the service</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Service name */}
                <FormDrawerTextField2
                  error={serviceCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Service name"
                  labelInfo="(required)"
                  placeholder="Enter the service's name"
                  setValueObject={setService}
                  setValueVariable="name"
                  value={service.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={serviceCreate.error}
              fieldName="description"
              helperText="advaise.app can use this description to generate content from the organisation's knowledge base"
              label="Description"
              placeholder="Describe the service"
              setValueObject={setService}
              setValueVariable="description"
              value={service.description}
            />
          </Card>
        }
        error={serviceCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add service"
            module="Services"
            view="List"
          />
        }
        helpTitle="Creating a service"
        icon="edit"
        isOpen={addServiceDrawerIsOpen}
        onSave={addService}
        refreshList={() => {
          dispatch(
            listServicesAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateServiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={serviceCreate.success}
        saveSuccessMessage="Service created."
        saving={serviceCreate.loading}
        setIsOpen={setAddServiceDrawerIsOpen}
        title="Add service"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddServiceDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Services"
      />

      {/* Error alerts */}
      {/* Services loading error */}
      {serviceList.error && (
        <ErrorAlert message="We couldn't load the service list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Services"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      <TabsContainer
        children={
          <div style={{ padding: "0 10px 0 10px" }}>
            <div
              style={{
                margin: "0 auto 0 auto",
                maxWidth: "1600px",
              }}
            >
              {/* Data cards */}
              <div style={{ margin: "-20px -20px 0 -20px" }}>
                <DataCardContainer
                  children={
                    <>
                      {/* Pipeline */}
                      <ServiceProposalPipelineCard
                        fill
                        serviceList={serviceList}
                        showInsights
                      />

                      {/* Total fees */}
                      <ServiceProposalTotalFeesCard
                        serviceList={serviceList}
                        serviceQuery={serviceQuery}
                        showInsights
                      />

                      {/* Average margin */}
                      <ServiceProposalAverageMarginCard
                        fill
                        serviceList={serviceList}
                        showInsights
                      />
                    </>
                  }
                  fill
                />
              </div>

              {/* Item list */}
              <div style={{ margin: "20px 0 20px 10px", width: "300px" }}>
                <SearchField
                  placeholder="Search service"
                  searching={serviceList.loading}
                  setValueObject={setServiceQuery}
                  setValueVariable="searchString"
                  value={serviceQuery.searchString}
                />
              </div>

              <ItemCardList
                content={serviceList.services.map((service) => {
                  return (
                    <ItemCard
                      content={
                        <div>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tag
                                intent={
                                  service.status === "Approved"
                                    ? "success"
                                    : "primary"
                                }
                                minimal
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                {service.status}
                              </Tag>

                              {!service.user_has_permission ? (
                                <Tag
                                  intent="danger"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  No access
                                </Tag>
                              ) : service.user_access_status === "view" ? (
                                <Tag
                                  intent="primary"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  View only
                                </Tag>
                              ) : undefined}
                            </div>

                            <InfoPopup
                              content={
                                <ServiceInfo hideLink serviceData={service} />
                              }
                            />
                          </div>

                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                service.manager_profile_photo
                                  ? service.manager_profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "40px",
                                margin: "0 10px 0 0",
                                objectFit: "contain",
                                width: "40px",
                              }}
                            />

                            <Text ellipsize>{service.manager_name}</Text>
                          </div>
                        </div>
                      }
                      darkColor={Colors.TURQUOISE3}
                      height={service.manager ? 155 : 95}
                      icon="heat-grid"
                      interactive
                      key={service.id}
                      lightColor={Colors.TURQUOISE3}
                      onClick={() => {
                        if (service.user_has_permission) {
                          dispatch(resetListServiceDetailsAction());

                          navigate(`/services/${service.id}`);
                        } else {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "danger",
                            message: "You do not have access to this service.",
                          });
                        }
                      }}
                      title={service.name}
                    />
                  );
                })}
                count={serviceList.count}
                loading={serviceList.loading}
                noMargin
                page={serviceQuery.page}
                setPageObject={setServiceQuery}
                setPageVariable="page"
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default ServiceListScreen;
