// Constant imports
import {
  LEAVE_CATEGORY_LIST_REQUEST,
  LEAVE_CATEGORY_LIST_SUCCESS,
  LEAVE_CATEGORY_LIST_FAIL,
  LEAVE_CATEGORY_LIST_RESET,
  LEAVE_CATEGORY_CREATE_REQUEST,
  LEAVE_CATEGORY_CREATE_SUCCESS,
  LEAVE_CATEGORY_CREATE_FAIL,
  LEAVE_CATEGORY_CREATE_RESET,
  LEAVE_CATEGORY_DETAILS_REQUEST,
  LEAVE_CATEGORY_DETAILS_SUCCESS,
  LEAVE_CATEGORY_DETAILS_FAIL,
  LEAVE_CATEGORY_DETAILS_RESET,
  LEAVE_CATEGORY_UPDATE_REQUEST,
  LEAVE_CATEGORY_UPDATE_SUCCESS,
  LEAVE_CATEGORY_UPDATE_FAIL,
  LEAVE_CATEGORY_UPDATE_RESET,
  LEAVE_CATEGORY_DELETE_REQUEST,
  LEAVE_CATEGORY_DELETE_SUCCESS,
  LEAVE_CATEGORY_DELETE_FAIL,
  LEAVE_CATEGORY_DELETE_RESET,
  LEAVE_CATEGORY_DOWNLOAD_REQUEST,
  LEAVE_CATEGORY_DOWNLOAD_SUCCESS,
  LEAVE_CATEGORY_DOWNLOAD_FAIL,
  LEAVE_CATEGORY_DOWNLOAD_RESET,
  LEAVE_CATEGORY_UPLOAD_BEGIN,
  LEAVE_CATEGORY_UPLOAD_REQUEST,
  LEAVE_CATEGORY_UPLOAD_SUCCESS,
  LEAVE_CATEGORY_UPLOAD_FAIL,
  LEAVE_CATEGORY_UPLOAD_END,
  LEAVE_CATEGORY_UPLOAD_RESET,
} from "../constants/leaveCategoryConstants";

// List all
export const leaveCategoryListReducer = (
  state = {
    loading: false,
    count: 0,
    leave_categories: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LEAVE_CATEGORY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        leave_categories: action.payload.leave_categories,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        leave_categories: [],
        success: false,
        error: action.payload,
      };

    case LEAVE_CATEGORY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        leave_categories: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const leaveCategoryCreateReducer = (
  state = { loading: false, leave_category: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_CREATE_REQUEST:
      return {
        loading: true,
        leave_category: state.leave_category,
        success: false,
        error: "",
      };

    case LEAVE_CATEGORY_CREATE_SUCCESS:
      return {
        loading: false,
        leave_category: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_CREATE_FAIL:
      return {
        loading: false,
        leave_category: state.leave_category,
        success: false,
        error: action.payload,
      };

    case LEAVE_CATEGORY_CREATE_RESET:
      return {
        loading: false,
        leave_category: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const leaveCategoryDetailsReducer = (
  state = { loading: false, leave_category: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_DETAILS_REQUEST:
      return {
        loading: true,
        leave_category: state.leave_category,
        success: false,
        error: "",
      };

    case LEAVE_CATEGORY_DETAILS_SUCCESS:
      return {
        loading: false,
        leave_category: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_DETAILS_FAIL:
      return {
        loading: false,
        leave_category: state.leave_category,
        success: false,
        error: action.payload,
      };

    case LEAVE_CATEGORY_DETAILS_RESET:
      return {
        loading: false,
        leave_category: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const leaveCategoryUpdateReducer = (
  state = { loading: false, leave_category: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_UPDATE_REQUEST:
      return {
        loading: true,
        leave_category: state.leave_category,
        success: false,
        error: "",
      };

    case LEAVE_CATEGORY_UPDATE_SUCCESS:
      return {
        loading: false,
        leave_category: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_UPDATE_FAIL:
      return {
        loading: false,
        leave_category: state.leave_category,
        success: false,
        error: action.payload,
      };

    case LEAVE_CATEGORY_UPDATE_RESET:
      return {
        loading: false,
        leave_category: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const leaveCategoryDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case LEAVE_CATEGORY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case LEAVE_CATEGORY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const leaveCategoryDownloadReducer = (
  state = {
    loading: false,
    leave_categories: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LEAVE_CATEGORY_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        leave_categories: action.payload.leave_categories,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_DOWNLOAD_FAIL:
      return {
        loading: false,
        leave_categories: [],
        success: false,
        error: action.payload,
      };

    case LEAVE_CATEGORY_DOWNLOAD_RESET:
      return {
        loading: false,
        leave_categories: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const leaveCategoryUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    progress: 0,
    records_failed: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_CATEGORY_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case LEAVE_CATEGORY_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LEAVE_CATEGORY_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_CATEGORY_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        records_failed: action.payload.records_failed,
        success: false,
        error: action.payload.error,
      };

    case LEAVE_CATEGORY_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case LEAVE_CATEGORY_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        progress: 0,
        records_failed: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
