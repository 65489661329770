// Library imports
import { Colors } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function DataCardContainer({
  children,
  darkColor,
  fill,
  lightColor,
  noMargin,
  stacked,
  tableTop,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        background: lightMode
          ? lightColor
            ? lightColor
            : Colors.LIGHT_GRAY3
          : darkColor
          ? darkColor
          : Colors.DARK_GRAY5,
        margin: stacked
          ? "0 0 -20px 0"
          : tableTop
          ? "0 0 -10px 0"
          : noMargin
          ? "0"
          : "20px",
        maxWidth: fill ? "100%" : "fit-content",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          overflowX: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default DataCardContainer;
