import {
  COMPANY_DETAILS_REQUEST,
  COMPANY_DETAILS_SUCCESS,
  COMPANY_DETAILS_FAIL,
  COMPANY_DETAILS_RESET,
  COMPANY_UPDATE_REQUEST,
  COMPANY_UPDATE_SUCCESS,
  COMPANY_UPDATE_FAIL,
  COMPANY_UPDATE_RESET,
} from "../constants/companyConstants";

export const companyDetailsReducer = (
  state = { loading: false, company: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case COMPANY_DETAILS_REQUEST:
      return {
        loading: true,
        company: state.company,
        success: false,
        error: "",
      };

    case COMPANY_DETAILS_SUCCESS:
      return {
        loading: false,
        company: action.payload,
        success: true,
        error: "",
      };

    case COMPANY_DETAILS_FAIL:
      return {
        loading: false,
        company: state.company,
        success: false,
        error: action.payload,
      };

    case COMPANY_DETAILS_RESET:
      return { loading: false, company: {}, success: false, error: "" };

    default:
      return state;
  }
};

export const companyUpdateReducer = (
  state = { loading: false, company: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case COMPANY_UPDATE_REQUEST:
      return {
        loading: true,
        company: state.company,
        success: false,
        error: "",
      };

    case COMPANY_UPDATE_SUCCESS:
      return {
        loading: false,
        company: action.payload,
        success: true,
        error: "",
      };

    case COMPANY_UPDATE_FAIL:
      return {
        loading: false,
        company: state.company,
        success: false,
        error: action.payload,
      };

    case COMPANY_UPDATE_RESET:
      return { loading: false, company: {}, success: false, error: "" };

    default:
      return state;
  }
};
