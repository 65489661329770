// Library imports
import { Colors, FormGroup } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import React from "react";

// Main function
function FormDrawerDateField2({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  icon,
  label,
  labelInfo,
  maxDate,
  minDate,
  placeholder,
  setValueObject,
  setValueVariable,
  value,
}) {
  // Calculate default max and min dates
  const defaultMaxDate = new Date(2050, 11, 31);
  const defaultMinDate = new Date(2020, 0, 1);

  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : "This field may not be blank."}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <DateInput
        disabled={disabled}
        fill
        formatDate={(date) =>
          date.toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            //weekday: "short",
            year: "numeric",
          })
        }
        id={fieldName}
        inputProps={{
          intent: error
            ? error.response.data[fieldName]
              ? "danger"
              : undefined
            : undefined,
          leftIcon: icon,
        }}
        highlightCurrentDay
        maxDate={maxDate ? maxDate : defaultMaxDate}
        minDate={minDate ? minDate : defaultMinDate}
        parseDate={(str) => new Date(str)}
        onChange={(selectedDate, isUserChange) => {
          isUserChange &&
            setValueObject((prevState) => {
              return {
                ...prevState,
                [setValueVariable]: selectedDate,
              };
            });
        }}
        placeholder={placeholder}
        showActionsBar
        value={value ? value : null}
      />
    </FormGroup>
  );
}

export default FormDrawerDateField2;
