// Library imports
import { HTMLSelect } from "@blueprintjs/core";
import React from "react";

// Main function
function SelectField({
  disabled,
  options,
  setValueObject,
  setValueVariable,
  value,
}) {
  return (
    <HTMLSelect
      disabled={disabled}
      fill
      id="selectField"
      onChange={(e) => {
        setValueObject((prevState) => {
          return {
            ...prevState,
            page: 1,
            [setValueVariable]: e.target.value,
          };
        });
      }}
      options={options}
      value={value}
    />
  );
}

export default SelectField;
