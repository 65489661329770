// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Card,
  Colors,
  Divider,
  H5,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";
import ItemCardList from "../components/ItemCardList";
import RankInfo from "../components/RankInfo";
import RoleInfo from "../components/RoleInfo";
import RoleProposalAverageMarginCard from "../components/RoleProposalAverageMarginCard";
import RoleProposalTotalFeesCard from "../components/RoleProposalTotalFeesCard";
import RoleResourcingCard from "../components/RoleResourcingCard";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";

// Redux action imports
// Rank
import { listRanksAction } from "../actions/rankActions";

// Role
import {
  createRoleAction,
  listRolesAction,
  resetCreateRoleAction,
  resetListRoleDetailsAction,
} from "../actions/roleActions";

// Main function
function RoleListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Rank
  const rankList = useSelector((state) => state.rankList);

  // Role
  const roleCreate = useSelector((state) => state.roleCreate);

  const roleList = useSelector((state) => state.roleList);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Roles
  const [addRoleDrawerIsOpen, setAddRoleDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Rank
  const rankQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [rankQuery, setRankQuery] = useState({
    ...rankQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Form variables
  // Role
  const roleDefault = {
    description: "",
    name: "",
    rank: "",
    rankName: "",
  };
  const [role, setRole] = useState({
    ...roleDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Role
    setRole({
      ...roleDefault,
    });

    // Query variables
    // Rank
    setRankQuery({
      ...rankQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter ranks
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRanksAction({
            ...rankQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [rankQuery]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listRolesAction({ ...roleQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Form submission
  // Role
  // Add role
  const addRole = () => {
    // Prepare data
    const role_data = new FormData();

    role.description
      ? role_data.append("description", role.description)
      : role_data.append("description", roleDefault.description);

    role.name
      ? role_data.append("name", role.name)
      : role_data.append("name", roleDefault.name);

    role.rank
      ? role_data.append("rank", role.rank)
      : role_data.append("rank", roleDefault.rank);

    role_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createRoleAction(role_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      {/* New role form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Describe the role</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Role name */}
                <FormDrawerTextField2
                  error={roleCreate.error}
                  fieldName="name"
                  icon="label"
                  label="Role name"
                  labelInfo="(required)"
                  placeholder="Enter the role's name"
                  setValueObject={setRole}
                  setValueVariable="name"
                  value={role.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Rank */}
                <FormDrawerSelectField2
                  error={roleCreate.error}
                  fieldName="rank"
                  helperText="You can select an existing rank in the organisation's hierarchy"
                  icon="layout-hierarchy"
                  info={
                    <RankInfo rankId={role.rank} query={rankQueryDefault} />
                  }
                  items={rankList.ranks}
                  label="Rank"
                  labelInfo="(required)"
                  loading={rankList.loading}
                  placeholder="Select a rank"
                  query={rankQuery.searchString}
                  setQueryObject={setRankQuery}
                  setQueryVariable="searchString"
                  setValueObject={setRole}
                  setValueIdVariable="rank"
                  setValueNameVariable="rankName"
                  showInfo={role.rank}
                  value={role.rankName}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={roleCreate.error}
              fieldName="description"
              helperText="Enter a detailed description suitable to include in proposals"
              label="Description"
              placeholder="Describe the role"
              setValueObject={setRole}
              setValueVariable="description"
              value={role.description}
            />
          </Card>
        }
        error={roleCreate.error}
        helpContent={
          <FormDrawerHelpContainer form="Add role" module="Roles" view="List" />
        }
        helpTitle="Creating a role"
        icon="edit"
        isOpen={addRoleDrawerIsOpen}
        onSave={addRole}
        refreshList={() => {
          dispatch(
            listRolesAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateRoleAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={roleCreate.success}
        saveSuccessMessage="Role created."
        saving={roleCreate.loading}
        setIsOpen={setAddRoleDrawerIsOpen}
        title="Add role"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddRoleDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Roles"
      />

      {/* Error alerts */}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the role list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Roles"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      <TabsContainer
        children={
          <div style={{ padding: "0 10px 0 10px" }}>
            <div
              style={{
                margin: "0 auto 0 auto",
                maxWidth: "1600px",
              }}
            >
              {/* Data cards */}
              <div style={{ margin: "-20px -20px 0 -20px" }}>
                <DataCardContainer
                  children={
                    <>
                      {/* Average margin */}
                      <RoleProposalAverageMarginCard
                        fill
                        roleList={roleList}
                        showInsights
                      />

                      {/* Total fees */}
                      <RoleProposalTotalFeesCard
                        roleList={roleList}
                        roleQuery={roleQuery}
                        showInsights
                      />

                      {/* Resource coverage */}
                      <RoleResourcingCard
                        fill
                        roleList={roleList}
                        showInsights
                      />
                    </>
                  }
                  fill
                />
              </div>

              {/* Item list */}
              <div style={{ margin: "20px 0 20px 10px", width: "300px" }}>
                <SearchField
                  placeholder="Search role"
                  searching={roleList.loading}
                  setValueObject={setRoleQuery}
                  setValueVariable="searchString"
                  value={roleQuery.searchString}
                />
              </div>

              <ItemCardList
                content={roleList.roles.map((role) => {
                  return (
                    <ItemCard
                      content={
                        <div>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Tag
                                intent={
                                  role.status === "Approved"
                                    ? "success"
                                    : "primary"
                                }
                                minimal
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                {role.status}
                              </Tag>

                              {!role.user_has_permission ? (
                                <Tag
                                  intent="danger"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  No access
                                </Tag>
                              ) : role.user_access_status === "view" ? (
                                <Tag
                                  intent="primary"
                                  minimal
                                  style={{ margin: "0 0 0 5px" }}
                                >
                                  View only
                                </Tag>
                              ) : undefined}
                            </div>

                            <InfoPopup
                              content={
                                <RoleInfo
                                  hideLink
                                  roleId={role.id}
                                  query={roleQueryDefault}
                                />
                              }
                            />
                          </div>

                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              marginTop: "20px",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                role.manager_profile_photo
                                  ? role.manager_profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "40px",
                                margin: "0 10px 0 0",
                                objectFit: "contain",
                                width: "40px",
                              }}
                            />

                            <Text ellipsize>{role.manager_name}</Text>
                          </div>
                        </div>
                      }
                      darkColor={Colors.INDIGO3}
                      height={role.manager ? 155 : 95}
                      icon="hat"
                      interactive
                      key={role.id}
                      lightColor={Colors.INDIGO3}
                      onClick={() => {
                        if (role.user_has_permission) {
                          dispatch(resetListRoleDetailsAction());

                          navigate(`/roles/${role.id}`);
                        } else {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "danger",
                            message: "You do not have access to this role.",
                          });
                        }
                      }}
                      title={role.name}
                    />
                  );
                })}
                count={roleList.count}
                loading={roleList.loading}
                noMargin
                page={roleQuery.page}
                setPageObject={setRoleQuery}
                setPageVariable="page"
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default RoleListScreen;
