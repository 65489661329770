// Constant imports
import {
  PROPOSAL_NON_LABOUR_EXPENSE_LIST_REQUEST,
  PROPOSAL_NON_LABOUR_EXPENSE_LIST_SUCCESS,
  PROPOSAL_NON_LABOUR_EXPENSE_LIST_FAIL,
  PROPOSAL_NON_LABOUR_EXPENSE_LIST_RESET,
  PROPOSAL_NON_LABOUR_EXPENSE_CREATE_REQUEST,
  PROPOSAL_NON_LABOUR_EXPENSE_CREATE_SUCCESS,
  PROPOSAL_NON_LABOUR_EXPENSE_CREATE_FAIL,
  PROPOSAL_NON_LABOUR_EXPENSE_CREATE_RESET,
  PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_REQUEST,
  PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_SUCCESS,
  PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_FAIL,
  PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_RESET,
  PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_REQUEST,
  PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_SUCCESS,
  PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_FAIL,
  PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_RESET,
  PROPOSAL_NON_LABOUR_EXPENSE_DELETE_REQUEST,
  PROPOSAL_NON_LABOUR_EXPENSE_DELETE_SUCCESS,
  PROPOSAL_NON_LABOUR_EXPENSE_DELETE_FAIL,
  PROPOSAL_NON_LABOUR_EXPENSE_DELETE_RESET,
} from "../constants/proposalNonLabourExpenseConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalNonLabourExpensesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalNonLabourExpenses(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_non_labour_expenses: data.results,
          totalCost: data.total_cost.result,
          totalFee: data.total_fee.result,
          totalFeeQuote: data.total_fee_quote.result,
          totalMargin: data.total_margin.result,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalNonLabourExpensesAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_LIST_RESET });
  };

// Create
export const createProposalNonLabourExpenseAction =
  (proposal_non_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalNonLabourExpense(
        token,
        proposal_non_labour_expense
      );

      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalNonLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_CREATE_RESET });
  };

// List details
export const listProposalNonLabourExpenseDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalNonLabourExpense(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalNonLabourExpenseDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_DETAILS_RESET });
  };

// Update
export const updateProposalNonLabourExpenseAction =
  (id, proposal_non_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalNonLabourExpense(
        id,
        token,
        proposal_non_labour_expense
      );

      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalNonLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_UPDATE_RESET });
  };

// Delete
export const deleteProposalNonLabourExpenseAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalNonLabourExpense(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_NON_LABOUR_EXPENSE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalNonLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_NON_LABOUR_EXPENSE_DELETE_RESET });
  };
