// Main function
const convertBase64ToBlob = (base64, mimeType) => {
  const byteString = atob(base64.split(",")[1]);

  const arrayBuffer = new ArrayBuffer(byteString.length);

  const uint8Array = new Uint8Array(arrayBuffer);

  for (var i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  const blob = new Blob([uint8Array], { type: mimeType });

  return blob;
};

export default convertBase64ToBlob;
