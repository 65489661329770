// Constant imports
import {
  INTERVIEW_PERMISSION_LIST_REQUEST,
  INTERVIEW_PERMISSION_LIST_SUCCESS,
  INTERVIEW_PERMISSION_LIST_FAIL,
  INTERVIEW_PERMISSION_LIST_RESET,
  INTERVIEW_PERMISSION_CREATE_REQUEST,
  INTERVIEW_PERMISSION_CREATE_SUCCESS,
  INTERVIEW_PERMISSION_CREATE_FAIL,
  INTERVIEW_PERMISSION_CREATE_RESET,
  INTERVIEW_PERMISSION_DETAILS_REQUEST,
  INTERVIEW_PERMISSION_DETAILS_SUCCESS,
  INTERVIEW_PERMISSION_DETAILS_FAIL,
  INTERVIEW_PERMISSION_DETAILS_RESET,
  INTERVIEW_PERMISSION_UPDATE_REQUEST,
  INTERVIEW_PERMISSION_UPDATE_SUCCESS,
  INTERVIEW_PERMISSION_UPDATE_FAIL,
  INTERVIEW_PERMISSION_UPDATE_RESET,
  INTERVIEW_PERMISSION_DELETE_REQUEST,
  INTERVIEW_PERMISSION_DELETE_SUCCESS,
  INTERVIEW_PERMISSION_DELETE_FAIL,
  INTERVIEW_PERMISSION_DELETE_RESET,
} from "../constants/interviewPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInterviewPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInterviewPermissions(
        params,
        token
      );

      dispatch({
        type: INTERVIEW_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          interview_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInterviewPermissionsAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_PERMISSION_LIST_RESET });
};

// Create
export const createInterviewPermissionAction =
  (interview_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createInterviewPermission(
        token,
        interview_permission
      );

      dispatch({
        type: INTERVIEW_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateInterviewPermissionAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_PERMISSION_CREATE_RESET });
};

// List details
export const listInterviewPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInterviewPermission(
        id,
        token
      );

      dispatch({
        type: INTERVIEW_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInterviewPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: INTERVIEW_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateInterviewPermissionAction =
  (id, interview_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInterviewPermission(
        id,
        token,
        interview_permission
      );

      dispatch({
        type: INTERVIEW_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInterviewPermissionAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteInterviewPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteInterviewPermission(
        id,
        token
      );

      dispatch({
        type: INTERVIEW_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteInterviewPermissionAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_PERMISSION_DELETE_RESET });
};
