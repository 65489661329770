// Constants import
import { RECAPTCHA_SITE_KEY } from "./constants/applicationConstants";

// Library imports
import { HotkeysProvider } from "@blueprintjs/core";
import React from "react";
import ReactDOM from "react-dom";
//import { createRoot } from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Redux store import
import store from "./store";

// Main css file import
import "./index.css";

// Component imports
import App from "./App";

// Main function
ReactDOM.render(
  <Provider store={store}>
    <HotkeysProvider>
      <BrowserRouter>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
          <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </HotkeysProvider>
  </Provider>,
  document.getElementById("root")
);

/*createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <HotkeysProvider>
      <BrowserRouter>
        <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
          <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </HotkeysProvider>
  </Provider>
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
