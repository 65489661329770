// Constant imports
import {
  ENGAGEMENT_LABOUR_EXPENSE_LIST_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_LIST_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_LIST_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_LIST_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_CREATE_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_DETAILS_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_UPDATE_RESET,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_REQUEST,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_SUCCESS,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_FAIL,
  ENGAGEMENT_LABOUR_EXPENSE_DELETE_RESET,
} from "../constants/engagementLabourExpenseConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementLabourExpensesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementLabourExpenses(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_labour_expenses: data.results,
          totalCost: data.total_cost.result,
          totalFee: data.total_fee.result,
          totalFeeQuote: data.total_fee_quote.result,
          totalMargin: data.total_margin.result,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementLabourExpensesAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_LIST_RESET });
  };

// Create
export const createEngagementLabourExpenseAction =
  (engagement_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementLabourExpense(
        token,
        engagement_labour_expense
      );

      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_CREATE_RESET });
  };

// List details
export const listEngagementLabourExpenseDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementLabourExpense(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementLabourExpenseDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_DETAILS_RESET });
  };

// Update
export const updateEngagementLabourExpenseAction =
  (id, engagement_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementLabourExpense(
        id,
        token,
        engagement_labour_expense
      );

      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_UPDATE_RESET });
  };

// Delete
export const deleteEngagementLabourExpenseAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementLabourExpense(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LABOUR_EXPENSE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_LABOUR_EXPENSE_DELETE_RESET });
  };
