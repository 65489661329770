// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ApprovalDetailDrawer from "../components/ApprovalDetailDrawer";
import BookingCodeInfo from "../components/BookingCodeInfo";
import ClientInfo from "../components/ClientInfo";
import DateValue from "../components/DateValue";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import InvoiceBookingInfo from "../components/InvoiceBookingInfo";
import InvoiceExpenseInfo from "../components/InvoiceExpenseInfo";
import InvoiceMilestoneInfo from "../components/InvoiceMilestoneInfo";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import PermissionCard from "../components/PermissionCard";
import SearchField from "../components/SearchField";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionDocumentEditor3 from "../components/TabSectionDocumentEditor3";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldImage from "../components/TabSectionFieldImage";
import TabSectionFieldCard from "../components/TabSectionFieldCard";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Approval request
import {
  createApprovalRequestAction,
  deleteApprovalRequestAction,
  listApprovalRequestsAction,
  listApprovalRequestDetailsAction,
  resetCreateApprovalRequestAction,
  resetDeleteApprovalRequestAction,
  resetUpdateApprovalRequestAction,
  updateApprovalRequestAction,
} from "../actions/approvalRequestActions";

// Booking code
import { listBookingCodesAction } from "../actions/bookingCodeActions";

// Client
import { listClientsAction } from "../actions/clientActions";

// Currency
import { getOrganisationCurrencyChoicesCurrencyAction } from "../actions/currencyActions";

// Document
import { generatePdfDocumentAction } from "../actions/documentActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Invoice
import {
  deleteInvoiceAction,
  listInvoiceDetailsAction,
  resetDeleteInvoiceAction,
  resetListInvoicesAction,
  resetUpdateInvoiceAction,
  updateInvoiceAction,
} from "../actions/invoiceActions";

// Invoice booking
import {
  deleteInvoiceBookingAction,
  listInvoiceBookingsAction,
  listInvoiceBookingDetailsAction,
  resetDeleteInvoiceBookingAction,
  resetUpdateInvoiceBookingAction,
  updateInvoiceBookingAction,
} from "../actions/invoiceBookingActions";

// Invoice booking credit
import { listInvoiceBookingCreditsAction } from "../actions/invoiceBookingCreditActions";

// Invoice document
import {
  listInvoiceDocumentDetailsAction,
  resetUpdateInvoiceDocumentAction,
  updateInvoiceDocumentAction,
} from "../actions/invoiceDocumentActions";

// Invoice expense
import {
  deleteInvoiceExpenseAction,
  listInvoiceExpensesAction,
  listInvoiceExpenseDetailsAction,
  resetDeleteInvoiceExpenseAction,
  resetUpdateInvoiceExpenseAction,
  updateInvoiceExpenseAction,
} from "../actions/invoiceExpenseActions";

// Invoice expense credit
import { listInvoiceExpenseCreditsAction } from "../actions/invoiceExpenseCreditActions";

// Invoice milestone
import {
  deleteInvoiceMilestoneAction,
  listInvoiceMilestonesAction,
  listInvoiceMilestoneDetailsAction,
  resetDeleteInvoiceMilestoneAction,
  resetUpdateInvoiceMilestoneAction,
  updateInvoiceMilestoneAction,
} from "../actions/invoiceMilestoneActions";

// Invoice milestone credit
import { listInvoiceMilestoneCreditsAction } from "../actions/invoiceMilestoneCreditActions";

// Invoice other
import {
  createInvoiceOtherAction,
  deleteInvoiceOtherAction,
  listInvoiceOthersAction,
  listInvoiceOtherDetailsAction,
  resetCreateInvoiceOtherAction,
  resetDeleteInvoiceOtherAction,
  resetUpdateInvoiceOtherAction,
  updateInvoiceOtherAction,
} from "../actions/invoiceOtherActions";

// Invoice other credit
import { listInvoiceOtherCreditsAction } from "../actions/invoiceOtherCreditActions";

// Invoice permission
import {
  createInvoicePermissionAction,
  deleteInvoicePermissionAction,
  listInvoicePermissionsAction,
  listInvoicePermissionDetailsAction,
  resetCreateInvoicePermissionAction,
  resetDeleteInvoicePermissionAction,
  resetUpdateInvoicePermissionAction,
  updateInvoicePermissionAction,
} from "../actions/invoicePermissionActions";

// Staff
import { listStaffAction } from "../actions/staffActions";
import NumericValue from "../components/NumericValue";

// Main function
function InvoiceDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Invoice id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Approval request
  const approvalRequestCreate = useSelector(
    (state) => state.approvalRequestCreate
  );

  const approvalRequestDelete = useSelector(
    (state) => state.approvalRequestDelete
  );

  const approvalRequestDetails = useSelector(
    (state) => state.approvalRequestDetails
  );

  const approvalRequestList = useSelector((state) => state.approvalRequestList);

  const approvalRequestUpdate = useSelector(
    (state) => state.approvalRequestUpdate
  );

  // Booking code
  const bookingCodeList = useSelector((state) => state.bookingCodeList);

  // Client
  const clientList = useSelector((state) => state.clientList);

  // Currency
  const currencyGetOrganisationCurrencyChoices = useSelector(
    (state) => state.currencyGetOrganisationCurrencyChoices
  );

  // Document
  const documentGeneratePdf = useSelector((state) => state.documentGeneratePdf);

  // Invoice
  const invoiceDelete = useSelector((state) => state.invoiceDelete);

  const invoiceDetails = useSelector((state) => state.invoiceDetails);

  const invoiceUpdate = useSelector((state) => state.invoiceUpdate);

  // Invoice booking
  const invoiceBookingDelete = useSelector(
    (state) => state.invoiceBookingDelete
  );

  const invoiceBookingDetails = useSelector(
    (state) => state.invoiceBookingDetails
  );

  const invoiceBookingList = useSelector((state) => state.invoiceBookingList);

  const invoiceBookingUpdate = useSelector(
    (state) => state.invoiceBookingUpdate
  );

  // Invoice booking credit
  const invoiceBookingCreditList = useSelector(
    (state) => state.invoiceBookingCreditList
  );

  // Invoice document
  const invoiceDocumentDetails = useSelector(
    (state) => state.invoiceDocumentDetails
  );

  const invoiceDocumentUpdate = useSelector(
    (state) => state.invoiceDocumentUpdate
  );

  // Invoice expense
  const invoiceExpenseDelete = useSelector(
    (state) => state.invoiceExpenseDelete
  );

  const invoiceExpenseDetails = useSelector(
    (state) => state.invoiceExpenseDetails
  );

  const invoiceExpenseList = useSelector((state) => state.invoiceExpenseList);

  const invoiceExpenseUpdate = useSelector(
    (state) => state.invoiceExpenseUpdate
  );

  // Invoice expense credit
  const invoiceExpenseCreditList = useSelector(
    (state) => state.invoiceExpenseCreditList
  );

  // Invoice milestone
  const invoiceMilestoneDelete = useSelector(
    (state) => state.invoiceMilestoneDelete
  );

  const invoiceMilestoneDetails = useSelector(
    (state) => state.invoiceMilestoneDetails
  );

  const invoiceMilestoneList = useSelector(
    (state) => state.invoiceMilestoneList
  );

  const invoiceMilestoneUpdate = useSelector(
    (state) => state.invoiceMilestoneUpdate
  );

  // Invoice milestone credit
  const invoiceMilestoneCreditList = useSelector(
    (state) => state.invoiceMilestoneCreditList
  );

  // Invoice other
  const invoiceOtherCreate = useSelector((state) => state.invoiceOtherCreate);

  const invoiceOtherDelete = useSelector((state) => state.invoiceOtherDelete);

  const invoiceOtherDetails = useSelector((state) => state.invoiceOtherDetails);

  const invoiceOtherList = useSelector((state) => state.invoiceOtherList);

  const invoiceOtherUpdate = useSelector((state) => state.invoiceOtherUpdate);

  // Invoice other credit
  const invoiceOtherCreditList = useSelector(
    (state) => state.invoiceOtherCreditList
  );

  // Invoice permission
  const invoicePermissionCreate = useSelector(
    (state) => state.invoicePermissionCreate
  );

  const invoicePermissionDelete = useSelector(
    (state) => state.invoicePermissionDelete
  );

  const invoicePermissionDetails = useSelector(
    (state) => state.invoicePermissionDetails
  );

  const invoicePermissionList = useSelector(
    (state) => state.invoicePermissionList
  );

  const invoicePermissionUpdate = useSelector(
    (state) => state.invoicePermissionUpdate
  );

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Staff
  const staffList = useSelector((state) => state.staffList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("invoice-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Milestones
  const [updateMilestoneDrawerIsOpen, setUpdateMilestoneDrawerIsOpen] =
    useState(false);

  // Expenses
  const [updateExpenseDrawerIsOpen, setUpdateExpenseDrawerIsOpen] =
    useState(false);

  // Bookings
  const [updateBookingDrawerIsOpen, setUpdateBookingDrawerIsOpen] =
    useState(false);

  // Other items
  const [addOtherDrawerIsOpen, setAddOtherDrawerIsOpen] = useState(false);
  const [updateOtherDrawerIsOpen, setUpdateOtherDrawerIsOpen] = useState(false);

  // Approval requests
  const [addApprovalRequestDrawerIsOpen, setAddApprovalRequestDrawerIsOpen] =
    useState(false);
  const [
    updateApprovalRequestDrawerIsOpen,
    setUpdateApprovalRequestDrawerIsOpen,
  ] = useState(false);
  const [viewApprovalDrawerIsOpen, setViewApprovalDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Approval request
  const approvalRequestQueryDefault = {
    approver: "",
    decisionRight: "",
    itemId: id,
    itemType: "invoice",
    organisation: activeOrganisation.id,
    outcome: "",
    page: 1,
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalRequestQuery, setApprovalRequestQuery] = useState({
    ...approvalRequestQueryDefault,
  });

  // Booking code
  const bookingCodeQueryDefault = {
    billable: true,
    engagement: "",
    holding: "",
    open: "",
    organisation: activeOrganisation.id,
    page: 1,
    proposal: "",
    searchString: "",
    sortField: "",
    year: "",
  };
  const [bookingCodeQuery, setBookingCodeQuery] = useState({
    ...bookingCodeQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Invoice booking
  const invoiceBookingQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
    staff: "",
  };
  const [invoiceBookingQuery, setInvoiceBookingQuery] = useState({
    ...invoiceBookingQueryDefault,
  });

  // Invoice booking credit
  const invoiceBookingCreditQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
    staff: "",
  };
  const [invoiceBookingCreditQuery, setInvoiceBookingCreditQuery] = useState({
    ...invoiceBookingCreditQueryDefault,
  });

  // Invoice expense
  const invoiceExpenseQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
    staff: "",
  };
  const [invoiceExpenseQuery, setInvoiceExpenseQuery] = useState({
    ...invoiceExpenseQueryDefault,
  });

  // Invoice expense credit
  const invoiceExpenseCreditQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
    staff: "",
  };
  const [invoiceExpenseCreditQuery, setInvoiceExpenseCreditQuery] = useState({
    ...invoiceExpenseCreditQueryDefault,
  });

  // Invoice milestone
  const invoiceMilestoneQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
  };
  const [invoiceMilestoneQuery, setInvoiceMilestoneQuery] = useState({
    ...invoiceMilestoneQueryDefault,
  });

  // Invoice milestone credit
  const invoiceMilestoneCreditQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
  };
  const [invoiceMilestoneCreditQuery, setInvoiceMilestoneCreditQuery] =
    useState({
      ...invoiceMilestoneCreditQueryDefault,
    });

  // Invoice other
  const invoiceOtherQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
  };
  const [invoiceOtherQuery, setInvoiceOtherQuery] = useState({
    ...invoiceOtherQueryDefault,
  });

  // Invoice other credit
  const invoiceOtherCreditQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
    sortField: "",
  };
  const [invoiceOtherCreditQuery, setInvoiceOtherCreditQuery] = useState({
    ...invoiceOtherCreditQueryDefault,
  });

  // Invoice permission
  const invoicePermissionQueryDefault = {
    invoice: id,
    page: 1,
    searchString: "",
  };
  const [invoicePermissionQuery, setInvoicePermissionQuery] = useState({
    ...invoicePermissionQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Staff
  const staffQueryDefault = {
    bookingProfile: "",
    competencies: "",
    employment: "",
    freeTime: "",
    level: "",
    location: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
    staff: "",
    utilisationScore: "",
  };
  const [staffQuery, setStaffQuery] = useState({
    ...staffQueryDefault,
  });

  // Form variables
  // Approval request
  const approvalRequestDefault = {
    approver: "",
    approverName: "",
    comments: "",
    decisionRight: "Invoice",
    status: "Draft",
  };
  const [approvalRequest, setApprovalRequest] = useState({
    ...approvalRequestDefault,
  });

  // Invoice
  const invoiceDefault = {
    bookingCode: "",
    bookingCodeName: "",
    client: "",
    clientName: "",
    comments: "",
    currency: activeOrganisation.currency,
    currencyExchangeRate: "",
    due: "",
    issued: new Date(),
    manager: "",
    managerName: "",
    name: "",
    paid: "",
    status: "Draft",
  };
  const [invoice, setInvoice] = useState({ ...invoiceDefault });

  // Invoice booking
  const invoiceBookingDefault = {
    comments: "",
    effortHours: "",
    effortMinutes: "",
    name: "",
    role: "",
    salesTaxRate: "",
    value: "",
  };
  const [invoiceBooking, setInvoiceBooking] = useState({
    ...invoiceBookingDefault,
  });

  // Invoice document
  const invoiceDocumentDefault = {
    clientAddress: "",
    clientEntity: "",
    companyAddress: "",
    companyEmail: "",
    companyEntity: "",
    companyLogo: "",
    companyLogoName: "",
    companyPhone: "",
    font: "Sintony",
    message: "",
    po: "",
  };
  const [invoiceDocument, setInvoiceDocument] = useState({
    ...invoiceDocumentDefault,
  });

  // Invoice expense
  const invoiceExpenseDefault = {
    comments: "",
    name: "",
    salesTaxRate: "",
    value: "",
  };
  const [invoiceExpense, setInvoiceExpense] = useState({
    ...invoiceExpenseDefault,
  });

  // Invoice milestone
  const invoiceMilestoneDefault = {
    description: "",
    name: "",
    salesTaxRate: "",
    value: "",
  };
  const [invoiceMilestone, setInvoiceMilestone] = useState({
    ...invoiceMilestoneDefault,
  });

  // Invoice other
  const invoiceOtherDefault = {
    description: "",
    name: "",
    salesTaxRate: "",
    value: "",
  };
  const [invoiceOther, setInvoiceOther] = useState({
    ...invoiceOtherDefault,
  });

  // Invoice permission
  const invoicePermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [invoicePermission, setInvoicePermission] = useState({
    ...invoicePermissionDefault,
  });

  // Other
  const [invoiceDeleteKey, setInvoiceDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval request
    setApprovalRequest({
      ...approvalRequestDefault,
    });

    // Invoice
    setInvoice({
      ...invoiceDefault,
    });

    // Invoice booking
    setInvoiceBooking({
      ...invoiceBookingDefault,
    });

    // Invoice expense
    setInvoiceExpense({
      ...invoiceExpenseDefault,
    });

    // Invoice milestone
    setInvoiceMilestone({
      ...invoiceMilestoneDefault,
    });

    // Invoice other
    setInvoiceOther({
      ...invoiceOtherDefault,
    });

    // Invoice permission
    setInvoicePermission({
      ...invoicePermissionDefault,
    });

    // Query variables
    // Booking code
    setBookingCodeQuery({
      ...bookingCodeQueryDefault,
    });

    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Staff
    setStaffQuery({
      ...staffQueryDefault,
    });
  };

  // Initialise drawer variables for approval request
  useEffect(() => {
    setApprovalRequest({
      approver: approvalRequestDetails.approval_request.approver
        ? approvalRequestDetails.approval_request.approver
        : approvalRequestDefault.approver,
      approverName: approvalRequestDetails.approval_request.approver_name
        ? approvalRequestDetails.approval_request.approver_name
        : approvalRequestDefault.approverName,
      comments: approvalRequestDetails.approval_request.comments
        ? approvalRequestDetails.approval_request.comments
        : approvalRequestDefault.comments,
      decisionRight: approvalRequestDetails.approval_request.decision_right
        ? approvalRequestDetails.approval_request.decision_right
        : approvalRequestDefault.decisionRight,
      status: approvalRequestDetails.approval_request.status
        ? approvalRequestDetails.approval_request.status
        : approvalRequestDefault.status,
    });
    // eslint-disable-next-line
  }, [approvalRequestDetails.approval_request]);

  // Initialise drawer variables for invoice
  useEffect(() => {
    setInvoice({
      bookingCode: invoiceDetails.invoice.booking_code
        ? invoiceDetails.invoice.booking_code
        : invoiceDefault.bookingCode,
      bookingCodeName: invoiceDetails.invoice.booking_code_data
        ? invoiceDetails.invoice.booking_code_data.name
        : invoiceDefault.bookingCodeName,
      client: invoiceDetails.invoice.client
        ? invoiceDetails.invoice.client
        : invoiceDefault.client,
      clientName: invoiceDetails.invoice.client_name
        ? invoiceDetails.invoice.client_name
        : invoiceDefault.clientName,
      comments: invoiceDetails.invoice.comments
        ? invoiceDetails.invoice.comments
        : invoiceDefault.comments,
      currency: invoiceDetails.invoice.currency
        ? invoiceDetails.invoice.currency
        : invoiceDefault.currency,
      currencyExchangeRate: invoiceDetails.invoice.currency_exchange_rate
        ? invoiceDetails.invoice.currency_exchange_rate
        : invoiceDefault.currencyExchangeRate,
      due: invoiceDetails.invoice.due
        ? new Date(invoiceDetails.invoice.due)
        : invoiceDefault.due,
      issued: invoiceDetails.invoice.issued
        ? new Date(invoiceDetails.invoice.issued)
        : invoiceDefault.issued,
      manager: invoiceDetails.invoice.manager
        ? invoiceDetails.invoice.manager
        : invoiceDefault.manager,
      managerName: invoiceDetails.invoice.manager_name
        ? invoiceDetails.invoice.manager_name
        : invoiceDefault.managerName,
      name: invoiceDetails.invoice.name
        ? invoiceDetails.invoice.name
        : invoiceDefault.name,
      paid: invoiceDetails.invoice.paid
        ? new Date(invoiceDetails.invoice.paid)
        : invoiceDefault.paid,
      status: invoiceDetails.invoice.status
        ? invoiceDetails.invoice.status
        : invoiceDefault.status,
    });
    // eslint-disable-next-line
  }, [invoiceDetails.invoice]);

  // Initialise drawer variables for invoice booking
  useEffect(() => {
    setInvoiceBooking({
      comments: invoiceBookingDetails.invoice_booking.comments
        ? invoiceBookingDetails.invoice_booking.comments
        : invoiceBookingDefault.comments,
      effortHours:
        invoiceBookingDetails.invoice_booking.minutes !== null
          ? convertMinutesToHoursAndMinutes(
              invoiceBookingDetails.invoice_booking.minutes
            ).split(":")[0]
          : invoiceBookingDefault.effortHours,
      effortMinutes:
        invoiceBookingDetails.invoice_booking.minutes !== null
          ? convertMinutesToHoursAndMinutes(
              invoiceBookingDetails.invoice_booking.minutes
            ).split(":")[1]
          : invoiceBookingDefault.effortMinutes,
      name: invoiceBookingDetails.invoice_booking.name
        ? invoiceBookingDetails.invoice_booking.name
        : invoiceBookingDefault.name,
      role: invoiceBookingDetails.invoice_booking.role
        ? invoiceBookingDetails.invoice_booking.role
        : invoiceBookingDefault.role,
      salesTaxRate:
        invoiceBookingDetails.invoice_booking.sales_tax_rate !== null
          ? invoiceBookingDetails.invoice_booking.sales_tax_rate
          : invoiceBookingDefault.salesTaxRate,
      value:
        invoiceBookingDetails.invoice_booking.value !== null
          ? invoiceBookingDetails.invoice_booking.value
          : invoiceBookingDefault.value,
    });
    // eslint-disable-next-line
  }, [invoiceBookingDetails.invoice_booking]);

  // Initialise drawer variables for invoice document
  useEffect(() => {
    setInvoiceDocument({
      clientAddress: invoiceDocumentDetails.invoice_document.client_address
        ? invoiceDocumentDetails.invoice_document.client_address
        : invoiceDocumentDefault.clientAddress,
      clientEntity: invoiceDocumentDetails.invoice_document.client_entity
        ? invoiceDocumentDetails.invoice_document.client_entity
        : invoiceDocumentDefault.clientEntity,
      companyAddress: invoiceDocumentDetails.invoice_document.company_address
        ? invoiceDocumentDetails.invoice_document.company_address
        : invoiceDocumentDefault.companyAddress,
      companyEmail: invoiceDocumentDetails.invoice_document.company_email
        ? invoiceDocumentDetails.invoice_document.company_email
        : invoiceDocumentDefault.companyEmail,
      companyEntity: invoiceDocumentDetails.invoice_document.company_entity
        ? invoiceDocumentDetails.invoice_document.company_entity
        : invoiceDocumentDefault.companyEntity,
      companyLogo: invoiceDocumentDetails.invoice_document.company_logo
        ? invoiceDocumentDetails.invoice_document.company_logo
        : invoiceDocumentDefault.logo,
      companyLogoName: invoiceDocumentDetails.invoice_document.company_logo
        ? invoiceDocumentDetails.invoice_document.company_logo.split("/").pop()
        : invoiceDocumentDefault.companyLogoName,
      companyPhone: invoiceDocumentDetails.invoice_document.company_phone
        ? invoiceDocumentDetails.invoice_document.company_phone
        : invoiceDocumentDefault.companyPhone,
      font: invoiceDocumentDetails.invoice_document.font
        ? invoiceDocumentDetails.invoice_document.font
        : invoiceDocumentDefault.font,
      message: invoiceDocumentDetails.invoice_document.message
        ? invoiceDocumentDetails.invoice_document.message
        : invoiceDocumentDefault.message,
      po: invoiceDocumentDetails.invoice_document.po
        ? invoiceDocumentDetails.invoice_document.po
        : invoiceDocumentDefault.po,
    });
    // eslint-disable-next-line
  }, [invoiceDocumentDetails.invoice_document]);

  // Initialise drawer variables for invoice expense
  useEffect(() => {
    setInvoiceExpense({
      comments: invoiceExpenseDetails.invoice_expense.comments
        ? invoiceExpenseDetails.invoice_expense.comments
        : invoiceExpenseDefault.comments,
      name: invoiceExpenseDetails.invoice_expense.name
        ? invoiceExpenseDetails.invoice_expense.name
        : invoiceExpenseDefault.name,
      salesTaxRate:
        invoiceExpenseDetails.invoice_expense.sales_tax_rate !== null
          ? invoiceExpenseDetails.invoice_expense.sales_tax_rate
          : invoiceExpenseDefault.salesTaxRate,
      value:
        invoiceExpenseDetails.invoice_expense.value !== null
          ? invoiceExpenseDetails.invoice_expense.value
          : invoiceExpenseDefault.value,
    });
    // eslint-disable-next-line
  }, [invoiceExpenseDetails.invoice_expense]);

  // Initialise drawer variables for invoice milestone
  useEffect(() => {
    setInvoiceMilestone({
      description: invoiceMilestoneDetails.invoice_milestone.description
        ? invoiceMilestoneDetails.invoice_milestone.description
        : invoiceMilestoneDefault.description,
      name: invoiceMilestoneDetails.invoice_milestone.name
        ? invoiceMilestoneDetails.invoice_milestone.name
        : invoiceMilestoneDefault.name,
      salesTaxRate:
        invoiceMilestoneDetails.invoice_milestone.sales_tax_rate !== null
          ? invoiceMilestoneDetails.invoice_milestone.sales_tax_rate
          : invoiceMilestoneDefault.salesTaxRate,
      value:
        invoiceMilestoneDetails.invoice_milestone.value !== null
          ? invoiceMilestoneDetails.invoice_milestone.value
          : invoiceMilestoneDefault.value,
    });
    // eslint-disable-next-line
  }, [invoiceMilestoneDetails.invoice_milestone]);

  // Initialise drawer variables for invoice other
  useEffect(() => {
    setInvoiceOther({
      description: invoiceOtherDetails.invoice_other.description
        ? invoiceOtherDetails.invoice_other.description
        : invoiceOtherDefault.description,
      name: invoiceOtherDetails.invoice_other.name
        ? invoiceOtherDetails.invoice_other.name
        : invoiceOtherDefault.name,
      salesTaxRate:
        invoiceOtherDetails.invoice_other.sales_tax_rate !== null
          ? invoiceOtherDetails.invoice_other.sales_tax_rate
          : invoiceOtherDefault.salesTaxRate,
      value:
        invoiceOtherDetails.invoice_other.value !== null
          ? invoiceOtherDetails.invoice_other.value
          : invoiceOtherDefault.value,
    });
    // eslint-disable-next-line
  }, [invoiceOtherDetails.invoice_other]);

  // Initialise drawer variables for invoice permission
  useEffect(() => {
    setInvoicePermission({
      level: invoicePermissionDetails.invoice_permission.level
        ? invoicePermissionDetails.invoice_permission.level
        : invoicePermissionDefault.level,
      manager: invoicePermissionDetails.invoice_permission.manager
        ? invoicePermissionDetails.invoice_permission.manager
        : invoicePermissionDefault.manager,
      managerName: invoicePermissionDetails.invoice_permission.manager_name
        ? invoicePermissionDetails.invoice_permission.manager_name
        : invoicePermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [invoicePermissionDetails.invoice_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If invoice deleted redirect to invoices
  useEffect(() => {
    if (invoiceDelete.success) {
      dispatch(resetDeleteInvoiceAction());

      dispatch(resetListInvoicesAction());

      navigate("/invoices");
    }
    // eslint-disable-next-line
  }, [invoiceDelete.success]);

  // Load currency data
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    dispatch(
      getOrganisationCurrencyChoicesCurrencyAction({
        organisation: activeOrganisation.id,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      currencies.length === 0 &&
      currencyGetOrganisationCurrencyChoices.currencies
    ) {
      currencyGetOrganisationCurrencyChoices.currencies.map((currency) =>
        setCurrencies((prevState) => [
          ...prevState,
          {
            id: currency[1],
            icon: (
              <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                {currency[0]}
              </Tag>
            ),
            value: currency[1],
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [currencyGetOrganisationCurrencyChoices.success]);

  // Load invoice details
  useEffect(() => {
    dispatch(listInvoiceDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Load invoice document data
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    if (invoiceDetails.invoice.invoice_document && !initialLoadDone) {
      dispatch(
        listInvoiceDocumentDetailsAction(
          invoiceDetails.invoice.invoice_document
        )
      );

      setInitialLoadDone(true);
    }
    // eslint-disable-next-line
  }, [invoiceDetails.invoice.invoice_document]);

  useEffect(() => {
    if (
      initialLoadDone &&
      (invoiceBookingDelete.success ||
        invoiceBookingUpdate.success ||
        invoiceExpenseDelete.success ||
        invoiceExpenseUpdate.success ||
        invoiceMilestoneDelete.success ||
        invoiceMilestoneUpdate.success ||
        invoiceOtherCreate.success ||
        invoiceOtherDelete.success ||
        invoiceOtherUpdate.success ||
        invoiceUpdate.success)
    ) {
      dispatch(
        listInvoiceDocumentDetailsAction(
          invoiceDetails.invoice.invoice_document
        )
      );
    }
    // eslint-disable-next-line
  }, [
    invoiceBookingDelete.success,
    invoiceBookingUpdate.success,
    invoiceExpenseDelete.success,
    invoiceExpenseUpdate.success,
    invoiceMilestoneDelete.success,
    invoiceMilestoneUpdate.success,
    invoiceOtherCreate.success,
    invoiceOtherDelete.success,
    invoiceOtherUpdate.success,
    invoiceUpdate.success,
  ]);

  useEffect(() => {
    if (initialLoadDone && invoiceDocumentUpdate.success) {
      dispatch(
        listInvoiceDocumentDetailsAction(
          invoiceDetails.invoice.invoice_document
        )
      );

      dispatch(resetUpdateInvoiceDocumentAction());
    }
    // eslint-disable-next-line
  }, [invoiceDocumentUpdate.success]);

  useEffect(() => {
    if (invoiceDocumentDetails.success) {
      dispatch(
        generatePdfDocumentAction({
          data: invoiceDocumentDetails.invoice_document,
          type: "invoice",
        })
      );
    }
    // eslint-disable-next-line
  }, [invoiceDocumentDetails.success]);

  // Filter approval requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalRequestsAction({
            ...approvalRequestQuery,
            reviewedEnd: approvalRequestQuery.reviewedEnd
              ? getDjangoDate(approvalRequestQuery.reviewedEnd)
              : getDjangoDate(approvalRequestQueryDefault.reviewedEnd),
            reviewedStart: approvalRequestQuery.reviewedStart
              ? getDjangoDate(approvalRequestQuery.reviewedStart)
              : getDjangoDate(approvalRequestQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRequestQuery]);

  // Filter booking codes
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listBookingCodesAction({ ...bookingCodeQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [bookingCodeQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter invoice bookings
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceBookingsAction({
            ...invoiceBookingQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceBookingQuery]);

  // Filter invoice booking credits
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceBookingCreditsAction({
            ...invoiceBookingCreditQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceBookingCreditQuery]);

  // Filter invoice expenses
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceExpensesAction({
            ...invoiceExpenseQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceExpenseQuery]);

  // Filter invoice expense credits
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceExpenseCreditsAction({
            ...invoiceExpenseCreditQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceExpenseCreditQuery]);

  // Filter invoice milestones
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceMilestonesAction({
            ...invoiceMilestoneQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceMilestoneQuery]);

  // Filter invoice milestone credits
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceMilestoneCreditsAction({
            ...invoiceMilestoneCreditQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceMilestoneCreditQuery]);

  // Filter invoice others
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceOthersAction({
            ...invoiceOtherQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceOtherQuery]);

  // Filter invoice other credits
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInvoiceOtherCreditsAction({
            ...invoiceOtherCreditQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoiceOtherCreditQuery]);

  // Filter invoice permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listInvoicePermissionsAction({ ...invoicePermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [invoicePermissionQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter manager list
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    managers = managerList.managers.filter((manager) =>
      manager.approval_role_data !== null
        ? manager.approval_role_data.can_approve_invoice &&
          manager.approval_role_data.can_approve_invoice_threshold >=
            invoiceDetails.invoice.value
        : false
    );

    setFilteredManagerList(managers);
  }, [
    addApprovalRequestDrawerIsOpen,
    invoiceDetails.invoice.value,
    managerList.managers,
    updateApprovalRequestDrawerIsOpen,
  ]);

  // Filter staff
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listStaffAction({
            ...staffQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [staffQuery]);

  // Form submission
  // Approval request
  // Add approval request
  const addApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approval_request_data.append("item_id", id);

    approval_request_data.append("organisation", activeOrganisation.id);

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(createApprovalRequestAction(approval_request_data));
  };

  // Delete approval request
  const deleteApprovalRequest = () => {
    // Dispatch action
    dispatch(
      deleteApprovalRequestAction(approvalRequestDetails.approval_request.id)
    );
  };

  // Update approval request
  const updateApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(
      updateApprovalRequestAction(
        approvalRequestDetails.approval_request.id,
        approval_request_data
      )
    );
  };

  // Invoice
  // Delete invoice
  const deleteInvoice = () => {
    // Dispatch action
    invoiceDeleteKey === invoiceDetails.invoice.name
      ? dispatch(deleteInvoiceAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the invoice's name",
        });
  };

  // Update invoice summary
  const updateSummary = () => {
    // Prepare data
    const invoice_data = new FormData();

    invoice.bookingCode
      ? invoice_data.append("booking_code", invoice.bookingCode)
      : invoice_data.append("booking_code", invoiceDefault.bookingCode);

    invoice.client
      ? invoice_data.append("client", invoice.client)
      : invoice_data.append("client", invoiceDefault.client);

    invoice.comments
      ? invoice_data.append("comments", invoice.comments)
      : invoice_data.append("comments", invoiceDefault.comments);

    invoice.currency
      ? invoice_data.append("currency", invoice.currency)
      : invoice_data.append("currency", invoiceDefault.currency);

    invoice.currencyExchangeRate
      ? invoice_data.append(
          "currency_exchange_rate",
          invoice.currencyExchangeRate
        )
      : invoice_data.append(
          "currency_exchange_rate",
          invoiceDefault.currencyExchangeRate
        );

    invoice.due
      ? invoice_data.append("due", getDjangoDate(invoice.due))
      : invoice_data.append("due", getDjangoDate(invoiceDefault.due));

    invoice.issued
      ? invoice_data.append("issued", getDjangoDate(invoice.issued))
      : invoice_data.append("issued", getDjangoDate(invoiceDefault.issued));

    invoice.manager
      ? invoice_data.append("manager", invoice.manager)
      : invoice_data.append("manager", invoiceDefault.manager);

    invoice.name
      ? invoice_data.append("name", invoice.name)
      : invoice_data.append("name", invoiceDefault.name);

    invoice.paid
      ? invoice_data.append("paid", getDjangoDate(invoice.paid))
      : invoice_data.append("paid", getDjangoDate(invoiceDefault.paid));

    invoice.status
      ? invoice_data.append("status", invoice.status)
      : invoice_data.append("status", invoiceDefault.status);

    // Dispatch action
    dispatch(updateInvoiceAction(id, invoice_data));
  };

  // Invoice booking
  // Delete booking
  const deleteBooking = () => {
    // Dispatch action
    dispatch(
      deleteInvoiceBookingAction(invoiceBookingDetails.invoice_booking.id)
    );
  };

  // Update booking
  const updateBooking = () => {
    // Prepare data
    const invoice_booking_data = new FormData();

    invoiceBooking.comments
      ? invoice_booking_data.append("comments", invoiceBooking.comments)
      : invoice_booking_data.append("comments", invoiceBookingDefault.comments);

    invoiceBooking.effortHours !== null && invoiceBooking.effortMinutes !== null
      ? invoice_booking_data.append(
          "minutes",
          invoiceBooking.effortHours * 60 + Number(invoiceBooking.effortMinutes)
        )
      : invoice_booking_data.append(
          "minutes",
          invoiceBookingDefault.effortHours * 60 +
            Number(invoiceBookingDefault.effortMinutes)
        );

    invoiceBooking.name
      ? invoice_booking_data.append("name", invoiceBooking.name)
      : invoice_booking_data.append("name", invoiceBookingDefault.name);

    invoiceBooking.role
      ? invoice_booking_data.append("role", invoiceBooking.role)
      : invoice_booking_data.append("role", invoiceBookingDefault.role);

    invoiceBooking.salesTaxRate !== null
      ? invoice_booking_data.append(
          "sales_tax_rate",
          invoiceBooking.salesTaxRate
        )
      : invoice_booking_data.append(
          "sales_tax_rate",
          invoiceBookingDefault.salesTaxRate
        );

    invoiceBooking.value !== null
      ? invoice_booking_data.append("value", invoiceBooking.value)
      : invoice_booking_data.append("value", invoiceBookingDefault.value);

    // Dispatch action
    dispatch(
      updateInvoiceBookingAction(
        invoiceBookingDetails.invoice_booking.id,
        invoice_booking_data
      )
    );
  };

  // Invoice document
  // Update invoice document
  const updateDocument = () => {
    // Prepare data
    const invoice_document = new FormData();

    invoiceDocument.clientAddress
      ? invoice_document.append("client_address", invoiceDocument.clientAddress)
      : invoice_document.append(
          "client_address",
          invoiceDocumentDefault.clientAddress
        );

    invoiceDocument.clientEntity
      ? invoice_document.append("client_entity", invoiceDocument.clientEntity)
      : invoice_document.append(
          "client_entity",
          invoiceDocumentDefault.clientEntity
        );

    invoiceDocument.companyAddress
      ? invoice_document.append(
          "company_address",
          invoiceDocument.companyAddress
        )
      : invoice_document.append(
          "company_address",
          invoiceDocumentDefault.companyAddress
        );

    invoiceDocument.companyEmail
      ? invoice_document.append("company_email", invoiceDocument.companyEmail)
      : invoice_document.append(
          "company_email",
          invoiceDocumentDefault.companyEmail
        );

    invoiceDocument.companyEntity
      ? invoice_document.append("company_entity", invoiceDocument.companyEntity)
      : invoice_document.append(
          "company_entity",
          invoiceDocumentDefault.companyEntity
        );

    if (
      invoiceDocument.companyLogo &&
      invoiceDocument.companyLogo !==
        invoiceDocumentDetails.invoice_document.company_logo
    ) {
      invoice_document.append("company_logo", invoiceDocument.companyLogo);
    } else if (invoiceDocument.companyLogo === "") {
      invoice_document.append("company_logo", invoiceDocument.companyLogo);
    }

    invoiceDocument.companyPhone
      ? invoice_document.append("company_phone", invoiceDocument.companyPhone)
      : invoice_document.append(
          "company_phone",
          invoiceDocumentDefault.companyPhone
        );

    invoiceDocument.font
      ? invoice_document.append("font", invoiceDocument.font)
      : invoice_document.append("font", invoiceDocumentDefault.font);

    invoiceDocument.message
      ? invoice_document.append("message", invoiceDocument.message)
      : invoice_document.append("message", invoiceDocumentDefault.message);

    invoiceDocument.po
      ? invoice_document.append("po", invoiceDocument.po)
      : invoice_document.append("po", invoiceDocumentDefault.po);

    // Dispatch action
    dispatch(
      updateInvoiceDocumentAction(
        invoiceDetails.invoice.invoice_document,
        invoice_document
      )
    );

    // Refresh invoice data
    dispatch(listInvoiceDetailsAction(id));
  };

  // Invoice expense
  // Delete expense
  const deleteExpense = () => {
    // Dispatch action
    dispatch(
      deleteInvoiceExpenseAction(invoiceExpenseDetails.invoice_expense.id)
    );
  };

  // Update expense
  const updateExpense = () => {
    // Prepare data
    const invoice_expense_data = new FormData();

    invoiceExpense.comments
      ? invoice_expense_data.append("comments", invoiceExpense.comments)
      : invoice_expense_data.append("comments", invoiceExpenseDefault.comments);

    invoiceExpense.name
      ? invoice_expense_data.append("name", invoiceExpense.name)
      : invoice_expense_data.append("name", invoiceExpenseDefault.name);

    invoiceExpense.salesTaxRate !== null
      ? invoice_expense_data.append(
          "sales_tax_rate",
          invoiceExpense.salesTaxRate
        )
      : invoice_expense_data.append(
          "sales_tax_rate",
          invoiceExpenseDefault.salesTaxRate
        );

    invoiceExpense.value !== null
      ? invoice_expense_data.append("value", invoiceExpense.value)
      : invoice_expense_data.append("value", invoiceExpenseDefault.value);

    // Dispatch action
    dispatch(
      updateInvoiceExpenseAction(
        invoiceExpenseDetails.invoice_expense.id,
        invoice_expense_data
      )
    );
  };

  // Invoice milestone
  // Delete milestones
  const deleteMilestone = () => {
    // Dispatch milestones
    dispatch(
      deleteInvoiceMilestoneAction(invoiceMilestoneDetails.invoice_milestone.id)
    );
  };

  // Update milestones
  const updateMilestone = () => {
    // Prepare data
    const invoice_milestone_data = new FormData();

    invoiceMilestone.description
      ? invoice_milestone_data.append(
          "description",
          invoiceMilestone.description
        )
      : invoice_milestone_data.append(
          "description",
          invoiceMilestoneDefault.description
        );

    invoiceMilestone.name
      ? invoice_milestone_data.append("name", invoiceMilestone.name)
      : invoice_milestone_data.append("name", invoiceMilestoneDefault.name);

    invoiceMilestone.salesTaxRate !== null
      ? invoice_milestone_data.append(
          "sales_tax_rate",
          invoiceMilestone.salesTaxRate
        )
      : invoice_milestone_data.append(
          "sales_tax_rate",
          invoiceMilestoneDefault.salesTaxRate
        );

    invoiceMilestone.value !== null
      ? invoice_milestone_data.append("value", invoiceMilestone.value)
      : invoice_milestone_data.append("value", invoiceMilestoneDefault.value);

    // Dispatch action
    dispatch(
      updateInvoiceMilestoneAction(
        invoiceMilestoneDetails.invoice_milestone.id,
        invoice_milestone_data
      )
    );
  };

  // Invoice other
  // Add others
  const addOther = () => {
    // Prepare data
    const invoice_other_data = new FormData();

    invoiceOther.description
      ? invoice_other_data.append("description", invoiceOther.description)
      : invoice_other_data.append(
          "description",
          invoiceOtherDefault.description
        );

    invoice_other_data.append("invoice", id);

    invoiceOther.name
      ? invoice_other_data.append("name", invoiceOther.name)
      : invoice_other_data.append("name", invoiceOtherDefault.name);

    invoiceOther.salesTaxRate !== null
      ? invoice_other_data.append("sales_tax_rate", invoiceOther.salesTaxRate)
      : invoice_other_data.append(
          "sales_tax_rate",
          invoiceOtherDefault.salesTaxRate
        );

    invoiceOther.value !== null
      ? invoice_other_data.append("value", invoiceOther.value)
      : invoice_other_data.append("value", invoiceOtherDefault.value);

    // Dispatch action
    dispatch(createInvoiceOtherAction(invoice_other_data));
  };

  // Delete others
  const deleteOther = () => {
    // Dispatch others
    dispatch(deleteInvoiceOtherAction(invoiceOtherDetails.invoice_other.id));
  };

  // Update others
  const updateOther = () => {
    // Prepare data
    const invoice_other_data = new FormData();

    invoiceOther.description
      ? invoice_other_data.append("description", invoiceOther.description)
      : invoice_other_data.append(
          "description",
          invoiceOtherDefault.description
        );

    invoiceOther.name
      ? invoice_other_data.append("name", invoiceOther.name)
      : invoice_other_data.append("name", invoiceOtherDefault.name);

    invoiceOther.salesTaxRate !== null
      ? invoice_other_data.append("sales_tax_rate", invoiceOther.salesTaxRate)
      : invoice_other_data.append(
          "sales_tax_rate",
          invoiceOtherDefault.salesTaxRate
        );

    invoiceOther.value !== null
      ? invoice_other_data.append("value", invoiceOther.value)
      : invoice_other_data.append("value", invoiceOtherDefault.value);

    // Dispatch action
    dispatch(
      updateInvoiceOtherAction(
        invoiceOtherDetails.invoice_other.id,
        invoice_other_data
      )
    );
  };

  // Invoice permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const invoice_permission = new FormData();

    invoice_permission.append("invoice", id);

    invoicePermission.level
      ? invoice_permission.append("level", invoicePermission.level)
      : invoice_permission.append("level", invoicePermissionDefault.level);

    invoicePermission.manager
      ? invoice_permission.append("manager", invoicePermission.manager)
      : invoice_permission.append("manager", invoicePermissionDefault.manager);

    // Dispatch action
    dispatch(createInvoicePermissionAction(invoice_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteInvoicePermissionAction(
        invoicePermissionDetails.invoice_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const invoice_permission = new FormData();

    invoicePermission.level
      ? invoice_permission.append("level", invoicePermission.level)
      : invoice_permission.append("level", invoicePermissionDefault.level);

    invoicePermission.manager
      ? invoice_permission.append("manager", invoicePermission.manager)
      : invoice_permission.append("manager", invoicePermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateInvoicePermissionAction(
        invoicePermissionDetails.invoice_permission.id,
        invoice_permission
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            {invoiceDetails.invoice.status !== "Draft" && (
              <Callout
                intent="primary"
                style={{ margin: "0 0 15px 0", width: "100%" }}
              >
                This invoice has already been issued and its details cannot be
                changed.
              </Callout>
            )}

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the invoice details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Invoice name */}
                  <FormDrawerTextField2
                    error={invoiceUpdate.error}
                    fieldName="name"
                    helperText="You can search invoices by name"
                    icon="dollar"
                    label="Invoice name"
                    labelInfo="(required)"
                    placeholder="Give the invoice a title"
                    setValueObject={setInvoice}
                    setValueVariable="name"
                    value={invoice.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Booking code */}
                  <FormDrawerSelectField2
                    disabled={invoiceDetails.invoice.status !== "Draft"}
                    error={invoiceUpdate.error}
                    fieldName="booking_code"
                    helperText="You can select an existing booking code"
                    icon="barcode"
                    info={
                      <BookingCodeInfo
                        bookingCodeId={invoice.bookingCode}
                        query={bookingCodeQueryDefault}
                      />
                    }
                    items={bookingCodeList.booking_codes}
                    label="Booking code"
                    labelInfo="(required)"
                    loading={bookingCodeList.loading}
                    placeholder="Select a booking code"
                    query={bookingCodeQuery.searchString}
                    setQueryObject={setBookingCodeQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInvoice}
                    setValueIdVariable="bookingCode"
                    setValueNameVariable="bookingCodeName"
                    showInfo={invoice.bookingCode}
                    value={invoice.bookingCodeName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Client */}
                  <FormDrawerSelectField2
                    disabled={
                      invoiceDetails.invoice.status !== "Draft" ||
                      (bookingCodeList.booking_codes.filter(
                        (booking_code) =>
                          booking_code.id === invoice.bookingCode
                      )[0]
                        ? bookingCodeList.booking_codes.filter(
                            (booking_code) =>
                              booking_code.id === invoice.bookingCode
                          )[0].engagement
                        : undefined)
                    }
                    error={invoiceUpdate.error}
                    fieldName="client"
                    helperText="You can select an existing client"
                    icon="office"
                    info={
                      <ClientInfo
                        clientId={invoice.client}
                        query={clientQueryDefault}
                      />
                    }
                    items={clientList.clients}
                    label="Client"
                    labelInfo="(required)"
                    loading={clientList.loading}
                    placeholder="Select a client"
                    query={clientQuery.searchString}
                    setQueryObject={setClientQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInvoice}
                    setValueIdVariable="client"
                    setValueNameVariable="clientName"
                    showInfo={invoice.client}
                    value={invoice.clientName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Currency */}
                  <FormDrawerSelectSimpleField3
                    disabled={invoiceDetails.invoice.status !== "Draft"}
                    error={invoiceUpdate.error}
                    fieldName="currency"
                    label="Currency"
                    loading={currencyGetOrganisationCurrencyChoices.loading}
                    options={currencies}
                    setValueObject={setInvoice}
                    setValueVariable="currency"
                    value={invoice.currency}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "25%" }}>
                  {/* Due date */}
                  <FormDrawerDateField2
                    disabled={invoiceDetails.invoice.status !== "Draft"}
                    error={invoiceUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the date"
                    setValueObject={setInvoice}
                    setValueVariable="due"
                    value={invoice.due}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Exchange rate */}
                  <FormDrawerNumericField2
                    disabled={invoiceDetails.invoice.status !== "Draft"}
                    error={invoiceUpdate.error}
                    fieldName="currency_exchange_rate"
                    helperText="Leave blank to use the organisation's default"
                    label="Exchange rate"
                    majorStepSize={1}
                    min={0}
                    minorStepSize={0.0001}
                    placeholder="Enter rate"
                    setValueObject={setInvoice}
                    setValueVariable="currencyExchangeRate"
                    stepSize={0.1}
                    value={invoice.currencyExchangeRate}
                  />
                </div>

                <div style={{ marginRight: "10px", width: "60%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={invoiceUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter your comments on the invoice"
                setValueObject={setInvoice}
                setValueVariable="comments"
                value={invoice.comments}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Invoice status */}
                  <FormDrawerSelectSimpleField3
                    disabled={
                      invoiceDetails.invoice.status === "Credited" ||
                      invoiceDetails.invoice.status === "Disputed"
                    }
                    error={invoiceUpdate.error}
                    fieldName="status"
                    intent={
                      invoice.status === "Disputed"
                        ? "danger"
                        : invoice.status === "Draft"
                        ? "primary"
                        : invoice.status === "Invoiced"
                        ? "warning"
                        : invoice.status === "Paid"
                        ? "success"
                        : undefined
                    }
                    label="Status"
                    options={[
                      {
                        disabled: invoiceDetails.invoice.status !== "Draft",
                        id: 1,
                        icon: "manually-entered-data",
                        value: "Draft",
                      },
                      {
                        id: 2,
                        icon: "document-share",
                        value: "Invoiced",
                      },
                      { id: 3, icon: "dollar", value: "Paid" },
                      { id: 4, icon: "issue", value: "Disputed" },
                      {
                        disabled: true,
                        id: 5,
                        icon: "undo",
                        value: "Credited",
                      },
                    ]}
                    setValueObject={setInvoice}
                    setValueVariable="status"
                    value={invoice.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "30%",
                  }}
                >
                  {/* Issued date */}
                  <FormDrawerDateField2
                    disabled={
                      invoiceDetails.invoice.status === "Credited" ||
                      invoiceDetails.invoice.status === "Disputed"
                    }
                    error={invoiceUpdate.error}
                    fieldName="issued"
                    icon="calendar"
                    label="Issued date"
                    placeholder="Enter the issued date"
                    setValueObject={setInvoice}
                    setValueVariable="issued"
                    value={invoice.issued}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "30%" }}>
                  {/* Paid date */}
                  <FormDrawerDateField2
                    disabled={
                      invoiceDetails.invoice.status === "Credited" ||
                      invoiceDetails.invoice.status === "Disputed"
                    }
                    error={invoiceUpdate.error}
                    fieldName="paid"
                    icon="calendar"
                    label="Paid date"
                    placeholder="Enter the paid date"
                    setValueObject={setInvoice}
                    setValueVariable="paid"
                    value={invoice.paid}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      invoiceDetails.invoice.user_access_status === "edit" ||
                      invoiceDetails.invoice.user_access_status === "view"
                    }
                    error={invoiceUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the invoice - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInvoice}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={invoice.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the invoice manager you will not
                    be able to access it again, unless you are organisation
                    administrator/owner or have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={invoiceUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Updating an invoice"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={invoiceDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listInvoiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateInvoiceAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceUpdate.success}
        saveSuccessMessage="Invoice updated."
        saving={invoiceUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={invoicePermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setInvoicePermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={invoicePermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={invoicePermissionCreate.error}
              fieldName="level"
              intent={
                invoicePermission.level === "Edit"
                  ? "success"
                  : invoicePermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setInvoicePermission}
              setValueVariable="level"
              value={invoicePermission.level}
            />
          </Card>
        }
        error={invoicePermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listInvoicePermissionsAction({ invoice: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateInvoicePermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoicePermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={invoicePermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={invoicePermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setInvoicePermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={invoicePermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={invoicePermissionUpdate.error}
              fieldName="level"
              intent={
                invoicePermission.level === "Edit"
                  ? "success"
                  : invoicePermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setInvoicePermission}
              setValueVariable="level"
              value={invoicePermission.level}
            />
          </Card>
        }
        deleteSuccess={invoicePermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={invoicePermissionDelete.loading}
        error={invoicePermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={invoicePermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listInvoicePermissionsAction({ ...invoicePermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteInvoicePermissionAction());

          dispatch(resetUpdateInvoicePermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoicePermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={invoicePermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* Existing milestone form drawer */}
      <FormDrawerContainer2
        body={
          <Card>
            <H5>Enter the milestone details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Milestone name */}
                <FormDrawerTextField2
                  error={invoiceMilestoneUpdate.error}
                  fieldName="name"
                  icon="star-empty"
                  label="Milestone name"
                  placeholder="Enter the milestone's name"
                  setValueObject={setInvoiceMilestone}
                  setValueVariable="name"
                  value={invoiceMilestone.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={invoiceMilestoneUpdate.error}
                  fieldName="value"
                  label="Value"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {currencyGetOrganisationCurrencyChoices.currencies
                        .filter(
                          (currency) =>
                            currency[1] === invoiceDetails.invoice.currency
                        )
                        .map((currency) => currency[0])}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the value"
                  setValueObject={setInvoiceMilestone}
                  setValueVariable="value"
                  stepSize={10000}
                  value={invoiceMilestone.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Sales tax rate */}
                <FormDrawerNumericField2
                  error={invoiceMilestoneUpdate.error}
                  fieldName="sales_tax_rate"
                  icon="percentage"
                  label="Sales tax rate"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the sales tax rate"
                  setValueObject={setInvoiceMilestone}
                  setValueVariable="salesTaxRate"
                  stepSize={10}
                  value={invoiceMilestone.salesTaxRate}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={invoiceMilestoneUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Enter your description"
              setValueObject={setInvoiceMilestone}
              setValueVariable="description"
              value={invoiceMilestone.description}
            />
          </Card>
        }
        deleteSuccess={invoiceMilestoneDelete.success}
        deleteSuccessMessage="Milestone deleted."
        deleting={invoiceMilestoneDelete.loading}
        error={invoiceMilestoneUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update milestone"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Scoping milestones"
        icon="edit"
        isOpen={updateMilestoneDrawerIsOpen}
        loading={invoiceMilestoneDetails.loading}
        onDelete={deleteMilestone}
        onSave={updateMilestone}
        refreshList={() => {
          dispatch(listInvoiceMilestonesAction({ ...invoiceMilestoneQuery }));

          dispatch(listInvoiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteInvoiceMilestoneAction());

          dispatch(resetUpdateInvoiceMilestoneAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceMilestoneUpdate.success}
        saveSuccessMessage="Milestone updated."
        saving={invoiceMilestoneUpdate.loading}
        setIsOpen={setUpdateMilestoneDrawerIsOpen}
        title="Update milestone"
      />

      {/* Existing booking form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the booking details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Staff name */}
                <FormDrawerTextField2
                  error={invoiceBookingUpdate.error}
                  fieldName="name"
                  icon="people"
                  label="Staff name"
                  placeholder="Enter the team member's name"
                  setValueObject={setInvoiceBooking}
                  setValueVariable="name"
                  value={invoiceBooking.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Role */}
                <FormDrawerTextField2
                  error={invoiceBookingUpdate.error}
                  fieldName="role"
                  icon="hat"
                  label="Role"
                  placeholder="Enter the role"
                  setValueObject={setInvoiceBooking}
                  setValueVariable="role"
                  value={invoiceBooking.role}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "20%" }}>
                {/* Effort hours */}
                <FormDrawerNumericField2
                  error={invoiceBookingUpdate.error}
                  fieldName="effortHours"
                  label="Hours"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter hours"
                  setValueObject={setInvoiceBooking}
                  setValueVariable="effortHours"
                  stepSize={10}
                  value={invoiceBooking.effortHours}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "20%",
                }}
              >
                {/* Effort minutes */}
                <FormDrawerNumericField2
                  error={invoiceBookingUpdate.error}
                  fieldName="effortMinutes"
                  label="Minutes"
                  majorStepSize={15}
                  max={59}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter minutes"
                  setValueObject={setInvoiceBooking}
                  setValueVariable="effortMinutes"
                  stepSize={5}
                  value={invoiceBooking.effortMinutes}
                />
              </div>

              <div
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  width: "30%",
                }}
              >
                {/* Value */}
                <FormDrawerNumericField2
                  error={invoiceBookingUpdate.error}
                  fieldName="value"
                  label="Value"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {currencyGetOrganisationCurrencyChoices.currencies
                        .filter(
                          (currency) =>
                            currency[1] === invoiceDetails.invoice.currency
                        )
                        .map((currency) => currency[0])}
                    </Tag>
                  }
                  majorStepSize={10000}
                  min={0}
                  minorStepSize={100}
                  placeholder="Enter value"
                  setValueObject={setInvoiceBooking}
                  setValueVariable="value"
                  stepSize={1000}
                  value={invoiceBooking.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "30%" }}>
                {/* Sales tax rate */}
                <FormDrawerNumericField2
                  error={invoiceBookingUpdate.error}
                  fieldName="sales_tax_rate"
                  icon="percentage"
                  label="Sales tax rate"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter tax rate"
                  setValueObject={setInvoiceBooking}
                  setValueVariable="salesTaxRate"
                  stepSize={10}
                  value={invoiceBooking.salesTaxRate}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={invoiceBookingUpdate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Enter your comments"
              setValueObject={setInvoiceBooking}
              setValueVariable="comments"
              value={invoiceBooking.comments}
            />
          </Card>
        }
        deleteSuccess={invoiceBookingDelete.success}
        deleteSuccessMessage="Booking deleted."
        deleting={invoiceBookingDelete.loading}
        error={invoiceBookingUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update booking"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Updating bookings"
        icon="edit"
        isOpen={updateBookingDrawerIsOpen}
        loading={invoiceBookingDetails.loading}
        onDelete={deleteBooking}
        onSave={updateBooking}
        refreshList={() => {
          dispatch(listInvoiceBookingsAction({ ...invoiceBookingQuery }));

          dispatch(listInvoiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteInvoiceBookingAction());

          dispatch(resetUpdateInvoiceBookingAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceBookingUpdate.success}
        saveSuccessMessage="Booking updated."
        saving={invoiceBookingUpdate.loading}
        setIsOpen={setUpdateBookingDrawerIsOpen}
        title="Update booking"
      />

      {/* Existing expense form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the expense details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Expense name */}
                <FormDrawerTextField2
                  error={invoiceExpenseUpdate.error}
                  fieldName="name"
                  icon="bank-account"
                  label="Expense name"
                  placeholder="Enter a short description of the expense"
                  setValueObject={setInvoiceExpense}
                  setValueVariable="name"
                  value={invoiceExpense.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={invoiceExpenseUpdate.error}
                  fieldName="value"
                  label="Value"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {currencyGetOrganisationCurrencyChoices.currencies
                        .filter(
                          (currency) =>
                            currency[1] === invoiceDetails.invoice.currency
                        )
                        .map((currency) => currency[0])}
                    </Tag>
                  }
                  majorStepSize={10000}
                  min={0}
                  minorStepSize={100}
                  placeholder="Enter the value"
                  setValueObject={setInvoiceExpense}
                  setValueVariable="value"
                  stepSize={1000}
                  value={invoiceExpense.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Sales tax rate */}
                <FormDrawerNumericField2
                  error={invoiceExpenseUpdate.error}
                  fieldName="sales_tax_rate"
                  icon="percentage"
                  label="Sales tax rate"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the sales tax rate"
                  setValueObject={setInvoiceExpense}
                  setValueVariable="salesTaxRate"
                  stepSize={10}
                  value={invoiceExpense.salesTaxRate}
                />
              </div>
            </div>

            {/* Comments */}
            <FormDrawerTextAreaField3
              error={invoiceExpenseUpdate.error}
              fieldName="comments"
              label="Comments"
              placeholder="Enter your comments"
              setValueObject={setInvoiceExpense}
              setValueVariable="comments"
              value={invoiceExpense.comments}
            />
          </Card>
        }
        deleteSuccess={invoiceExpenseDelete.success}
        deleteSuccessMessage="Expense deleted."
        deleting={invoiceExpenseDelete.loading}
        error={invoiceExpenseUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update expense"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Updating expenses"
        icon="edit"
        isOpen={updateExpenseDrawerIsOpen}
        loading={invoiceExpenseDetails.loading}
        onDelete={deleteExpense}
        onSave={updateExpense}
        refreshList={() => {
          dispatch(listInvoiceExpensesAction({ ...invoiceExpenseQuery }));

          dispatch(listInvoiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteInvoiceExpenseAction());

          dispatch(resetUpdateInvoiceExpenseAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceExpenseUpdate.success}
        saveSuccessMessage="Expense updated."
        saving={invoiceExpenseUpdate.loading}
        setIsOpen={setUpdateExpenseDrawerIsOpen}
        title="Update expense"
      />

      {/* New other form drawer */}
      <FormDrawerContainer2
        body={
          <Card>
            <H5>Enter the item details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Item name */}
                <FormDrawerTextField2
                  error={invoiceOtherCreate.error}
                  fieldName="name"
                  icon="property"
                  label="Item name"
                  placeholder="Enter the item's name"
                  setValueObject={setInvoiceOther}
                  setValueVariable="name"
                  value={invoiceOther.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={invoiceOtherCreate.error}
                  fieldName="value"
                  label="Value"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {currencyGetOrganisationCurrencyChoices.currencies
                        .filter(
                          (currency) =>
                            currency[1] === invoiceDetails.invoice.currency
                        )
                        .map((currency) => currency[0])}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the value"
                  setValueObject={setInvoiceOther}
                  setValueVariable="value"
                  stepSize={10000}
                  value={invoiceOther.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Sales tax rate */}
                <FormDrawerNumericField2
                  error={invoiceOtherCreate.error}
                  fieldName="sales_tax_rate"
                  icon="percentage"
                  label="Sales tax rate"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the sales tax rate"
                  setValueObject={setInvoiceOther}
                  setValueVariable="salesTaxRate"
                  stepSize={10}
                  value={invoiceOther.salesTaxRate}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={invoiceOtherCreate.error}
              fieldName="description"
              label="Description"
              placeholder="Enter your description"
              setValueObject={setInvoiceOther}
              setValueVariable="description"
              value={invoiceOther.description}
            />
          </Card>
        }
        error={invoiceOtherCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add item"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Adding items to the invoice"
        icon="edit"
        isOpen={addOtherDrawerIsOpen}
        loading={invoiceOtherDetails.loading}
        onSave={addOther}
        refreshList={() => {
          dispatch(listInvoiceOthersAction({ ...invoiceOtherQuery }));

          dispatch(listInvoiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetCreateInvoiceOtherAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceOtherCreate.success}
        saveSuccessMessage="Item added."
        saving={invoiceOtherCreate.loading}
        setIsOpen={setAddOtherDrawerIsOpen}
        title="Add item"
      />

      {/* Existing other form drawer */}
      <FormDrawerContainer2
        body={
          <Card>
            <H5>Enter the item details</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Item name */}
                <FormDrawerTextField2
                  error={invoiceOtherUpdate.error}
                  fieldName="name"
                  icon="property"
                  label="Item name"
                  placeholder="Enter the item's name"
                  setValueObject={setInvoiceOther}
                  setValueVariable="name"
                  value={invoiceOther.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Value */}
                <FormDrawerNumericField2
                  error={invoiceOtherUpdate.error}
                  fieldName="value"
                  label="Value"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {currencyGetOrganisationCurrencyChoices.currencies
                        .filter(
                          (currency) =>
                            currency[1] === invoiceDetails.invoice.currency
                        )
                        .map((currency) => currency[0])}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the value"
                  setValueObject={setInvoiceOther}
                  setValueVariable="value"
                  stepSize={10000}
                  value={invoiceOther.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Sales tax rate */}
                <FormDrawerNumericField2
                  error={invoiceOtherUpdate.error}
                  fieldName="sales_tax_rate"
                  icon="percentage"
                  label="Sales tax rate"
                  majorStepSize={20}
                  min={0}
                  minorStepSize={1}
                  placeholder="Enter the sales tax rate"
                  setValueObject={setInvoiceOther}
                  setValueVariable="salesTaxRate"
                  stepSize={10}
                  value={invoiceOther.salesTaxRate}
                />
              </div>
            </div>

            {/* Description */}
            <FormDrawerTextAreaField3
              error={invoiceOtherUpdate.error}
              fieldName="description"
              label="Description"
              placeholder="Enter your description"
              setValueObject={setInvoiceOther}
              setValueVariable="description"
              value={invoiceOther.description}
            />
          </Card>
        }
        deleteSuccess={invoiceOtherDelete.success}
        deleteSuccessMessage="Other deleted."
        deleting={invoiceOtherDelete.loading}
        error={invoiceOtherUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update item"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Adding items to the invoice"
        icon="edit"
        isOpen={updateOtherDrawerIsOpen}
        loading={invoiceOtherDetails.loading}
        onDelete={deleteOther}
        onSave={updateOther}
        refreshList={() => {
          dispatch(listInvoiceOthersAction({ ...invoiceOtherQuery }));

          dispatch(listInvoiceDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteInvoiceOtherAction());

          dispatch(resetUpdateInvoiceOtherAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={invoiceOtherUpdate.success}
        saveSuccessMessage="Item updated."
        saving={invoiceOtherUpdate.loading}
        setIsOpen={setUpdateOtherDrawerIsOpen}
        title="Update item"
      />

      {/* New approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request to issue the invoice</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginRight: "10px",
                    width: "60%",
                  }}
                >
                  {/* Approver */}
                  <FormDrawerSelectField2
                    error={approvalRequestCreate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={approvalRequestCreate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    error={approvalRequestCreate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={approvalRequestCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create approval request"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={addApprovalRequestDrawerIsOpen}
        onSave={addApprovalRequest}
        refreshList={() => {
          dispatch(
            listApprovalRequestsAction({
              itemId: id,
              itemType: "invoice",
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestCreate.success}
        saveSuccessMessage="Approval request created."
        saving={approvalRequestCreate.loading}
        setIsOpen={setAddApprovalRequestDrawerIsOpen}
        title="Create approval request"
      />

      {/* Existing approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request to issue the invoice</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    marginRight: "10px",
                    width: "60%",
                  }}
                >
                  {/* Approver */}
                  <FormDrawerSelectField2
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />
                </div>

                <div style={{ marginLeft: "15px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                disabled={
                  approvalRequestDetails.approval_request.status === "Submitted"
                }
                error={approvalRequestUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter addition information you would like to submit with the request"
                setValueObject={setApprovalRequest}
                setValueVariable="comments"
                value={approvalRequest.comments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={approvalRequestDelete.success}
        deleteSuccessMessage="Approval request deleted."
        deleting={approvalRequestDelete.loading}
        error={approvalRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approval request"
            module="Invoices"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={updateApprovalRequestDrawerIsOpen}
        loading={approvalRequestDetails.loading}
        onDelete={deleteApprovalRequest}
        onSave={updateApprovalRequest}
        refreshList={() => {
          dispatch(listApprovalRequestsAction({ ...approvalRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteApprovalRequestAction());

          dispatch(resetUpdateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestUpdate.success}
        saveSuccessMessage="Approval request updated."
        saving={approvalRequestUpdate.loading}
        setIsOpen={setUpdateApprovalRequestDrawerIsOpen}
        title="Update approval request"
      />

      {/* Detail drawers */}
      {/* Approval request */}
      <ApprovalDetailDrawer
        approvalRequestDetails={approvalRequestDetails}
        setIsOpen={setViewApprovalDrawerIsOpen}
        isOpen={viewApprovalDrawerIsOpen}
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Invoices"
              onClick={() => {
                dispatch(resetListInvoicesAction());

                navigate("/invoices");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          invoiceDetails.invoice.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={invoiceDetails.loading}
        title={`${invoiceDetails.invoice.name}`}
      />

      {/* Error alerts */}
      {/* Approval requests loading error */}
      {approvalRequestList.error && (
        <ErrorAlert message="We couldn't load the approval request list. Try refreshing the page." />
      )}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Invoice delete error */}
      {invoiceDelete.error && (
        <ErrorAlert message="We couldn't delete the invoice. Try refreshing the page." />
      )}
      {/* Invoice loading error */}
      {invoiceDetails.error && (
        <ErrorAlert message="We couldn't load the invoice. Try refreshing the page." />
      )}
      {/* Invoice bookings loading error */}
      {invoiceBookingList.error && (
        <ErrorAlert message="We couldn't load the booking list. Try refreshing the page." />
      )}
      {/* Invoice booking credits loading error */}
      {invoiceBookingCreditList.error && (
        <ErrorAlert message="We couldn't load the booking credit list. Try refreshing the page." />
      )}
      {/* Invoice document loading error */}
      {invoiceDocumentDetails.error && (
        <ErrorAlert message="We couldn't load the invoice document. Try refreshing the page." />
      )}
      {/* Invoice expenses loading error */}
      {invoiceExpenseList.error && (
        <ErrorAlert message="We couldn't load the expense list. Try refreshing the page." />
      )}
      {/* Invoice expense credits loading error */}
      {invoiceExpenseCreditList.error && (
        <ErrorAlert message="We couldn't load the expense credit list. Try refreshing the page." />
      )}
      {/* Invoice milestones loading error */}
      {invoiceMilestoneList.error && (
        <ErrorAlert message="We couldn't load the milestone list. Try refreshing the page." />
      )}
      {/* Invoice milestone credits loading error */}
      {invoiceMilestoneCreditList.error && (
        <ErrorAlert message="We couldn't load the milestone credit list. Try refreshing the page." />
      )}
      {/* Invoice others loading error */}
      {invoiceOtherList.error && (
        <ErrorAlert message="We couldn't load the additional item list. Try refreshing the page." />
      )}
      {/* Invoice other credits loading error */}
      {invoiceOtherCreditList.error && (
        <ErrorAlert message="We couldn't load the booking credit list. Try refreshing the page." />
      )}
      {/* Invoice permissions loading error */}
      {invoicePermissionList.error && (
        <ErrorAlert message="We couldn't load the invoice permissions. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Invoice"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="invoice-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="invoice-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          invoiceDetails.invoice.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listInvoiceDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={invoiceDetails.loading}
                          value={invoiceDetails.invoice.id}
                        />

                        {/* Title */}
                        <TabSectionField
                          label="Title"
                          loading={invoiceDetails.loading}
                          value={invoiceDetails.invoice.name}
                        />

                        {/* Booking code */}
                        <TabSectionField
                          helperText={
                            invoiceDetails.invoice.booking_code_data
                              ? invoiceDetails.invoice.booking_code_data
                                  .comments
                              : undefined
                          }
                          label="Booking code"
                          loading={invoiceDetails.loading}
                          value={
                            invoiceDetails.invoice.booking_code_data
                              ? invoiceDetails.invoice.booking_code_data.name
                              : undefined
                          }
                        />

                        {/* Client */}
                        <TabSectionField
                          label="Client"
                          loading={invoiceDetails.loading}
                          value={
                            <NameValue
                              image={invoiceDetails.invoice.client_logo}
                              logo
                              text={invoiceDetails.invoice.client_name}
                              url={`/clients/${invoiceDetails.invoice.client}`}
                            />
                          }
                        />

                        {/* Invoice value */}
                        <TabSectionField
                          helperText={
                            activeOrganisation.currency !==
                              invoiceDetails.invoice.currency_short && (
                              <div style={{ display: "flex" }}>
                                Local currency value of
                                <div style={{ margin: "0 5px 0 5px" }}>
                                  <NumericValue
                                    currency={
                                      invoiceDetails.invoice.currency_short
                                    }
                                    noTag
                                    number={invoiceDetails.invoice.value_quote}
                                  />
                                </div>
                                excluding sales tax
                              </div>
                            )
                          }
                          label="Invoice value"
                          loading={invoiceDetails.loading}
                          value={
                            invoiceDetails.invoice.value === 0 ? (
                              "-"
                            ) : (
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div style={{ margin: "0 5px 0 0" }}>
                                  <NumericValue
                                    currency={activeOrganisation.currency}
                                    number={invoiceDetails.invoice.value}
                                  />
                                </div>
                                excluding sales tax of
                                <div style={{ margin: "0 0 0 5px" }}>
                                  <NumericValue
                                    number={invoiceDetails.invoice.sales_tax}
                                  />
                                </div>
                              </div>
                            )
                          }
                        />

                        {/* Exchange rate */}
                        {activeOrganisation.currency !==
                          invoiceDetails.invoice.currency_short && (
                          <TabSectionField
                            helperText="The exchange rate will not be shown in the invoice"
                            label="Exchange rate"
                            loading={invoiceDetails.loading}
                            value={`${invoiceDetails.invoice.currency_exchange_rate} ${invoiceDetails.invoice.currency_short}/${activeOrganisation.currency}`}
                          />
                        )}

                        {/* Issued date */}
                        <TabSectionField
                          label="Issued date"
                          loading={invoiceDetails.loading}
                          value={
                            <DateValue date={invoiceDetails.invoice.issued} />
                          }
                        />

                        {/* Due date */}
                        <TabSectionField
                          helperText={
                            invoiceDetails.invoice.overdue && (
                              <Tag
                                intent="danger"
                                large
                                minimal
                                style={{
                                  margin: "0 0 0 10px",
                                }}
                              >
                                Overdue
                              </Tag>
                            )
                          }
                          label="Due date"
                          loading={invoiceDetails.loading}
                          value={
                            <DateValue date={invoiceDetails.invoice.due} />
                          }
                        />

                        {/* Paid date */}
                        <TabSectionField
                          label="Paid date"
                          loading={invoiceDetails.loading}
                          value={
                            <DateValue date={invoiceDetails.invoice.paid} />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          helperText={
                            invoiceDetails.invoice.invoice_credit_note
                              ? `Credit note raised with invoice id ${invoiceDetails.invoice.invoice_credit_note}`
                              : invoiceDetails.invoice.original_invoice
                              ? `Credit note raised against invoice id ${invoiceDetails.invoice.original_invoice}`
                              : undefined
                          }
                          label="Status"
                          loading={invoiceDetails.loading}
                          value={
                            <Tag
                              intent={
                                invoiceDetails.invoice.status === "Disputed"
                                  ? "danger"
                                  : invoiceDetails.invoice.status === "Draft"
                                  ? "primary"
                                  : invoiceDetails.invoice.status === "Invoiced"
                                  ? "warning"
                                  : invoiceDetails.invoice.status === "Paid"
                                  ? "success"
                                  : undefined
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {invoiceDetails.invoice.status}
                            </Tag>
                          }
                        />

                        {/* Comments */}
                        <TabSectionField
                          label="Comments"
                          loading={invoiceDetails.loading}
                          value={
                            invoiceDetails.invoice.comments ? (
                              <TabSectionFieldCard
                                text={invoiceDetails.invoice.comments}
                              />
                            ) : undefined
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            invoiceDetails.invoice.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={invoiceDetails.loading}
                          value={
                            <NameValue
                              email={invoiceDetails.invoice.manager_name}
                              image={
                                invoiceDetails.invoice.manager_profile_photo
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update invoice information"
                    icon="dollar"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(invoiceDetails.invoice.user_access_status === "owner" ||
                    invoiceDetails.invoice.user_access_status === "admin" ||
                    invoiceDetails.invoice.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={invoicePermissionList.loading}
                              setValueObject={setInvoicePermissionQuery}
                              setValueVariable="searchString"
                              value={invoicePermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={invoicePermissionList.invoice_permissions.map(
                              (invoice_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={invoice_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listInvoicePermissionDetailsAction(
                                          invoice_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={invoice_permission}
                                  />
                                );
                              }
                            )}
                            count={invoicePermissionList.count}
                            loading={invoicePermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={invoicePermissionQuery.page}
                            setPageObject={setInvoicePermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="All invoices are restricted. You need to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete invoice */}
                  {(invoiceDetails.invoice.user_access_status === "owner" ||
                    invoiceDetails.invoice.user_access_status === "admin" ||
                    invoiceDetails.invoice.user_access_status === "manager") &&
                    invoiceDetails.invoice.status === "Draft" && (
                      <TabSection2
                        content={
                          <div>
                            <ControlGroup
                              style={{
                                margin: "20px 0 0 0",
                                textAlign: "start",
                              }}
                            >
                              <InputGroup
                                id="confirm-invoice-name"
                                onChange={(e) => {
                                  setInvoiceDeleteKey(e.target.value);
                                }}
                                placeholder="Enter the invoice's name"
                                style={{ margin: "0 5px 0 0", width: "400px" }}
                                value={invoiceDeleteKey}
                              />

                              <Button
                                icon="trash"
                                intent="danger"
                                loading={invoiceDelete.loading}
                                onClick={deleteInvoice}
                                text="Delete this invoice"
                              />
                            </ControlGroup>

                            <div
                              className={[
                                Classes.TEXT_MUTED,
                                Classes.TEXT_SMALL,
                              ].join(" ")}
                              style={{ margin: "5px 0 0 10px" }}
                            >
                              Enter "{invoiceDetails.invoice.name}" to delete.
                            </div>
                          </div>
                        }
                        help="Delete the invoice"
                        icon="delete"
                        message="If you delete this invoice, you will lose all information associated with it."
                        messageIntent="danger"
                        title="Delete invoice"
                      />
                    )}
                </div>
              }
            />

            {/* Details */}
            <Tab
              id="invoice-details-tab"
              title="Details"
              panel={
                <div>
                  {/* Milestones */}
                  {invoiceMilestoneList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceMilestoneList.invoice_milestones.map(
                              (milestone) => {
                                return (
                                  <tr
                                    key={milestone.id}
                                    onClick={() => {
                                      if (
                                        invoiceDetails.invoice
                                          .user_access_status !== "view" &&
                                        invoiceDetails.invoice.status ===
                                          "Draft"
                                      ) {
                                        dispatch(
                                          listInvoiceMilestoneDetailsAction(
                                            milestone.id
                                          )
                                        );

                                        setUpdateMilestoneDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <InvoiceMilestoneInfo
                                            milestoneData={milestone}
                                          />
                                        }
                                        text={milestone.name}
                                      />
                                    </td>

                                    {/* Completed */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={milestone.completed} />
                                    </td>

                                    {/* Amount */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={milestone.value} />
                                    </td>

                                    {/* Tax */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={milestone.sales_tax}
                                        tagNumber={milestone.sales_tax_rate}
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={milestone.net_value}
                                      />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={milestone.net_value_quote}
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceMilestoneQuery.searchString
                            }
                            count={invoiceMilestoneList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceMilestoneList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceMilestoneList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceMilestoneList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceMilestoneList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Milestone */}
                                <TableColumnHeadSearch
                                  queryObject={invoiceMilestoneQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setInvoiceMilestoneQuery}
                                  setQueryVariable="searchString"
                                  title="Milestone"
                                  width="200px"
                                />

                                {/* Completed */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "completed",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-completed",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceMilestoneQuery}
                                  title="Completed"
                                  width="125px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceMilestoneQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceMilestoneQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceMilestoneQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              invoiceDetails.invoice.user_access_status !==
                                "view" &&
                              invoiceDetails.invoice.status === "Draft"
                            }
                            loading={invoiceMilestoneList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceMilestoneQuery({
                                ...invoiceMilestoneQueryDefault,
                              });
                            }}
                            page={invoiceMilestoneQuery.page}
                            setPageObject={setInvoiceMilestoneQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Review the engagement milestones to be invoiced"
                      icon="star-empty"
                      title="Milestones"
                    />
                  )}

                  {/* Bookings */}
                  {invoiceBookingList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceBookingList.invoice_bookings.map(
                              (booking) => {
                                return (
                                  <tr
                                    key={booking.id}
                                    onClick={() => {
                                      if (
                                        invoiceDetails.invoice
                                          .user_access_status !== "view" &&
                                        invoiceDetails.invoice.status ===
                                          "Draft"
                                      ) {
                                        dispatch(
                                          listInvoiceBookingDetailsAction(
                                            booking.id
                                          )
                                        );

                                        setUpdateBookingDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Staff */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        image={booking.staff_profile_photo}
                                        info={
                                          <InvoiceBookingInfo
                                            bookingData={booking}
                                          />
                                        }
                                        showImage
                                        text={booking.staff_name}
                                      />
                                    </td>

                                    {/* Effort */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        value={`${
                                          convertMinutesToHoursAndMinutes(
                                            booking.minutes
                                          ).split(":")[0]
                                        }h ${
                                          convertMinutesToHoursAndMinutes(
                                            booking.minutes
                                          ).split(":")[1]
                                        }m`}
                                      />
                                    </td>

                                    {/* Start */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={booking.start} />
                                    </td>

                                    {/* End */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={booking.end} />
                                    </td>

                                    {/* Amount */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={booking.value} />
                                    </td>

                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking.sales_tax}
                                        tagNumber={booking.sales_tax_rate}
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={booking.net_value} />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={booking.net_value_quote}
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceBookingQuery.searchString &&
                              !invoiceBookingQuery.staff
                            }
                            count={invoiceBookingList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceBookingList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceBookingList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceBookingList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceBookingList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Staff */}
                                <TableColumnHeadSelect2
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="staff"
                                  items={staffList.staff}
                                  setFilterObject={setInvoiceBookingQuery}
                                  setQueryObject={setStaffQuery}
                                  setQueryVariable="searchString"
                                  title="Staff"
                                  width="200px"
                                />

                                {/* Effort */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "minutes",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-minutes",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingQuery}
                                  title="Effort"
                                  width="125px"
                                />

                                {/* Start */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "start",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-start",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingQuery}
                                  title="Start"
                                  width="125px"
                                />

                                {/* End */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "end",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-end",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingQuery}
                                  title="End"
                                  width="125px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              invoiceDetails.invoice.user_access_status !==
                                "view" &&
                              invoiceDetails.invoice.status === "Draft"
                            }
                            loading={invoiceBookingList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceBookingQuery({
                                ...invoiceBookingQueryDefault,
                              });
                            }}
                            page={invoiceBookingQuery.page}
                            setPageObject={setInvoiceBookingQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Review the time bookings to be invoiced"
                      icon="timeline-events"
                      title="Bookings"
                    />
                  )}

                  {/* Expenses */}
                  {invoiceExpenseList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceExpenseList.invoice_expenses.map(
                              (expense) => {
                                return (
                                  <tr
                                    key={expense.id}
                                    onClick={() => {
                                      if (
                                        invoiceDetails.invoice
                                          .user_access_status !== "view" &&
                                        invoiceDetails.invoice.status ===
                                          "Draft"
                                      ) {
                                        dispatch(
                                          listInvoiceExpenseDetailsAction(
                                            expense.id
                                          )
                                        );

                                        setUpdateExpenseDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Expense */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <InvoiceExpenseInfo
                                            expenseData={expense}
                                          />
                                        }
                                        text={expense.name}
                                      />
                                    </td>

                                    {/* Staff */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        image={expense.staff_profile_photo}
                                        showImage
                                        text={expense.staff_name}
                                      />
                                    </td>

                                    {/* Amount */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={expense.value} />
                                    </td>

                                    {/* Tax */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={expense.sales_tax}
                                        tagNumber={expense.sales_tax_rate}
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={expense.net_value} />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={expense.net_value_quote}
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceExpenseQuery.searchString &&
                              !invoiceExpenseQuery.staff
                            }
                            count={invoiceExpenseList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceExpenseList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceExpenseList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceExpenseList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceExpenseList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Expense */}
                                <TableColumnHeadSearch
                                  queryObject={invoiceExpenseQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setInvoiceExpenseQuery}
                                  setQueryVariable="searchString"
                                  title="Expense"
                                  width="200px"
                                />

                                {/* Staff */}
                                <TableColumnHeadSelect2
                                  filterObject={invoiceExpenseQuery}
                                  filterVariable="staff"
                                  items={staffList.staff}
                                  setFilterObject={setInvoiceExpenseQuery}
                                  setQueryObject={setStaffQuery}
                                  setQueryVariable="searchString"
                                  title="Staff"
                                  width="200px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceExpenseQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceExpenseQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceExpenseQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceExpenseQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceExpenseQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceExpenseQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              invoiceDetails.invoice.user_access_status !==
                                "view" &&
                              invoiceDetails.invoice.status === "Draft"
                            }
                            loading={invoiceExpenseList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceExpenseQuery({
                                ...invoiceExpenseQueryDefault,
                              });
                            }}
                            page={invoiceExpenseQuery.page}
                            setPageObject={setInvoiceExpenseQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Review the time bookings to be invoiced"
                      icon="bank-account"
                      title="Expenses"
                    />
                  )}

                  {/* Additional items */}
                  {(invoiceDetails.invoice.status === "Draft" ||
                    invoiceOtherList.count > 0) && (
                    <TabSection2
                      buttons={
                        <Button
                          disabled={
                            invoiceDetails.invoice.status !== "Draft" ||
                            invoiceDetails.invoice.user_access_status === "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddOtherDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceOtherList.invoice_others.map(
                              (other) => {
                                return (
                                  <tr
                                    key={other.id}
                                    onClick={() => {
                                      if (
                                        invoiceDetails.invoice
                                          .user_access_status !== "view" &&
                                        invoiceDetails.invoice.status ===
                                          "Draft"
                                      ) {
                                        dispatch(
                                          listInvoiceOtherDetailsAction(
                                            other.id
                                          )
                                        );

                                        setUpdateOtherDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5
                                              style={{ margin: "0 10px 0 0" }}
                                            >
                                              {other.name}
                                            </H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Description</strong>
                                            </p>

                                            <p>
                                              {other.description ? (
                                                other.description
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No description provided.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={other.name}
                                      />
                                    </td>

                                    {/* Value */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={other.value} />
                                    </td>

                                    {/* Tax */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={other.sales_tax}
                                        tagNumber={other.sales_tax_rate}
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={other.net_value} />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={other.net_value_quote}
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceOtherQuery.searchString
                            }
                            count={invoiceOtherList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="1"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceOtherList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceOtherList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceOtherList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={invoiceOtherList.totalNetQuote}
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Item */}
                                <TableColumnHeadSearch
                                  queryObject={invoiceOtherQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setInvoiceOtherQuery}
                                  setQueryVariable="searchString"
                                  title="Item"
                                  width="200px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceOtherQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceOtherQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceOtherQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceOtherQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceOtherQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceOtherQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              invoiceDetails.invoice.user_access_status !==
                                "view" &&
                              invoiceDetails.invoice.status === "Draft"
                            }
                            loading={invoiceOtherList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceOtherQuery({
                                ...invoiceOtherQueryDefault,
                              });
                            }}
                            page={invoiceOtherQuery.page}
                            setPageObject={setInvoiceOtherQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Add additional items to be invoiced"
                      icon="property"
                      title="Additional items"
                    />
                  )}

                  {/* Milestone credits */}
                  {invoiceMilestoneCreditList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceMilestoneCreditList.invoice_milestone_credits.map(
                              (milestone_credit) => {
                                return (
                                  <tr key={milestone_credit.id}>
                                    {/* Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <InvoiceMilestoneInfo
                                            milestoneData={milestone_credit}
                                          />
                                        }
                                        text={milestone_credit.name}
                                      />
                                    </td>

                                    {/* Completed */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate
                                        date={milestone_credit.completed}
                                      />
                                    </td>

                                    {/* Amount */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={milestone_credit.value}
                                      />
                                    </td>

                                    {/* Tax */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={milestone_credit.sales_tax}
                                        tagNumber={
                                          milestone_credit.sales_tax_rate
                                        }
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={milestone_credit.net_value}
                                      />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={
                                              milestone_credit.net_value_quote
                                            }
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceMilestoneCreditQuery.searchString
                            }
                            count={invoiceMilestoneCreditList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={
                                      invoiceMilestoneCreditList.totalValue
                                    }
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceMilestoneCreditList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceMilestoneCreditList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceMilestoneCreditList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Milestone */}
                                <TableColumnHeadSearch
                                  queryObject={invoiceMilestoneCreditQuery}
                                  queryVariable="searchString"
                                  setQueryObject={
                                    setInvoiceMilestoneCreditQuery
                                  }
                                  setQueryVariable="searchString"
                                  title="Milestone"
                                  width="200px"
                                />

                                {/* Completed */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "completed",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-completed",
                                    },
                                  ]}
                                  setFilterObject={
                                    setInvoiceMilestoneCreditQuery
                                  }
                                  title="Completed"
                                  width="125px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={
                                    setInvoiceMilestoneCreditQuery
                                  }
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={
                                    setInvoiceMilestoneCreditQuery
                                  }
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceMilestoneCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={
                                    setInvoiceMilestoneCreditQuery
                                  }
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            loading={invoiceMilestoneCreditList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceMilestoneCreditQuery({
                                ...invoiceMilestoneCreditQueryDefault,
                              });
                            }}
                            page={invoiceMilestoneCreditQuery.page}
                            setPageObject={setInvoiceMilestoneCreditQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Review the engagement milestones to be credited"
                      icon="star-empty"
                      title="Milestone credits"
                    />
                  )}

                  {/* Booking credits */}
                  {invoiceBookingCreditList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceBookingCreditList.invoice_booking_credits.map(
                              (booking_credit) => {
                                return (
                                  <tr key={booking_credit.id}>
                                    {/* Staff */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        image={
                                          booking_credit.staff_profile_photo
                                        }
                                        info={
                                          <InvoiceBookingInfo
                                            bookingData={booking_credit}
                                          />
                                        }
                                        showImage
                                        text={booking_credit.staff_name}
                                      />
                                    </td>

                                    {/* Effort */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        value={`${
                                          convertMinutesToHoursAndMinutes(
                                            booking_credit.minutes
                                          ).split(":")[0]
                                        }h ${
                                          convertMinutesToHoursAndMinutes(
                                            booking_credit.minutes
                                          ).split(":")[1]
                                        }m`}
                                      />
                                    </td>

                                    {/* Start */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={booking_credit.start} />
                                    </td>

                                    {/* End */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableDate date={booking_credit.end} />
                                    </td>

                                    {/* Amount */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_credit.value}
                                      />
                                    </td>

                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_credit.sales_tax}
                                        tagNumber={
                                          booking_credit.sales_tax_rate
                                        }
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={booking_credit.net_value}
                                      />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={
                                              booking_credit.net_value_quote
                                            }
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceBookingCreditQuery.searchString &&
                              !invoiceBookingCreditQuery.staff
                            }
                            count={invoiceBookingCreditList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="4"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceBookingCreditList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceBookingCreditList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceBookingCreditList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceBookingCreditList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Staff */}
                                <TableColumnHeadSelect2
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="staff"
                                  items={staffList.staff}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  setQueryObject={setStaffQuery}
                                  setQueryVariable="searchString"
                                  title="Staff"
                                  width="200px"
                                />

                                {/* Effort */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "minutes",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-minutes",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  title="Effort"
                                  width="125px"
                                />

                                {/* Start */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "start",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-start",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  title="Start"
                                  width="125px"
                                />

                                {/* End */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "end",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-end",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  title="End"
                                  width="125px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceBookingCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceBookingCreditQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            loading={invoiceBookingCreditList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceBookingCreditQuery({
                                ...invoiceBookingCreditQueryDefault,
                              });
                            }}
                            page={invoiceBookingCreditQuery.page}
                            setPageObject={setInvoiceBookingCreditQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Review the time bookings to be credited"
                      icon="timeline-events"
                      title="Booking credits"
                    />
                  )}

                  {/* Expense credits */}
                  {invoiceExpenseCreditList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceExpenseCreditList.invoice_expense_credits.map(
                              (expense_credit) => {
                                return (
                                  <tr key={expense_credit.id}>
                                    {/* Expense */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <InvoiceExpenseInfo
                                            expenseData={expense_credit}
                                          />
                                        }
                                        text={expense_credit.name}
                                      />
                                    </td>

                                    {/* Staff */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        image={
                                          expense_credit.staff_profile_photo
                                        }
                                        showImage
                                        text={expense_credit.staff_name}
                                      />
                                    </td>

                                    {/* Amount */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={expense_credit.value}
                                      />
                                    </td>

                                    {/* Tax */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={expense_credit.sales_tax}
                                        tagNumber={
                                          expense_credit.sales_tax_rate
                                        }
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={expense_credit.net_value}
                                      />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={
                                              expense_credit.net_value_quote
                                            }
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceExpenseCreditQuery.searchString &&
                              !invoiceExpenseCreditQuery.staff
                            }
                            count={invoiceExpenseCreditList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="2"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceExpenseCreditList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceExpenseCreditList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceExpenseCreditList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceExpenseCreditList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Expense */}
                                <TableColumnHeadSearch
                                  queryObject={invoiceExpenseCreditQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setInvoiceExpenseCreditQuery}
                                  setQueryVariable="searchString"
                                  title="Expense"
                                  width="200px"
                                />

                                {/* Staff */}
                                <TableColumnHeadSelect2
                                  filterObject={invoiceExpenseCreditQuery}
                                  filterVariable="staff"
                                  items={staffList.staff}
                                  setFilterObject={setInvoiceExpenseCreditQuery}
                                  setQueryObject={setStaffQuery}
                                  setQueryVariable="searchString"
                                  title="Staff"
                                  width="200px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceExpenseCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceExpenseCreditQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceExpenseCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceExpenseCreditQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceExpenseCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceExpenseCreditQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              invoiceDetails.invoice.user_access_status !==
                                "view" &&
                              invoiceDetails.invoice.status === "Draft"
                            }
                            loading={invoiceExpenseCreditList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceExpenseCreditQuery({
                                ...invoiceExpenseCreditQueryDefault,
                              });
                            }}
                            page={invoiceExpenseCreditQuery.page}
                            setPageObject={setInvoiceExpenseCreditQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Review the time bookings to be credited"
                      icon="bank-account"
                      title="Expense credits"
                    />
                  )}

                  {/* Additional item credits */}
                  {invoiceOtherCreditList.count > 0 && (
                    <TabSection2
                      content={
                        <>
                          {/* Data table */}
                          <TableContainer2
                            body={invoiceOtherCreditList.invoice_other_credits.map(
                              (other_credit) => {
                                return (
                                  <tr key={other_credit.id}>
                                    {/* Name */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5
                                              style={{ margin: "0 10px 0 0" }}
                                            >
                                              {other_credit.name}
                                            </H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Description</strong>
                                            </p>

                                            <p>
                                              {other_credit.description ? (
                                                other_credit.description
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No description provided.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={other_credit.name}
                                      />
                                    </td>

                                    {/* Value */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={other_credit.value}
                                      />
                                    </td>

                                    {/* Tax */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={other_credit.sales_tax}
                                        tagNumber={other_credit.sales_tax_rate}
                                        tagPercentage
                                      />
                                    </td>

                                    {/* Net */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber
                                        number={other_credit.net_value}
                                      />

                                      {activeOrganisation.currency !==
                                        invoiceDetails.invoice
                                          .currency_short && (
                                        <div style={{ marginTop: "5px" }}>
                                          <TableNumber
                                            minimal
                                            number={
                                              other_credit.net_value_quote
                                            }
                                            tagText={
                                              invoiceDetails.invoice
                                                .currency_short
                                            }
                                          />
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !invoiceOtherCreditQuery.searchString
                            }
                            count={invoiceOtherCreditList.count}
                            footer={
                              <tr>
                                <td
                                  colSpan="1"
                                  style={{
                                    textAlign: "end",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "end",
                                    }}
                                  >
                                    <div>Total</div>

                                    <Tag
                                      minimal
                                      style={{
                                        margin: "0 0 0 10px",
                                        textAlign: "center",
                                        width: "50px",
                                      }}
                                    >
                                      {activeOrganisation.currency}
                                    </Tag>
                                  </div>
                                </td>

                                {/* Amount */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceOtherCreditList.totalValue}
                                  />
                                </td>

                                {/* Tax */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceOtherCreditList.totalTax}
                                  />
                                </td>

                                {/* Net */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber
                                    number={invoiceOtherCreditList.totalNet}
                                  />

                                  {activeOrganisation.currency !==
                                    invoiceDetails.invoice.currency_short && (
                                    <div style={{ marginTop: "5px" }}>
                                      <TableNumber
                                        minimal
                                        number={
                                          invoiceOtherCreditList.totalNetQuote
                                        }
                                        tagText={
                                          invoiceDetails.invoice.currency_short
                                        }
                                      />
                                    </div>
                                  )}
                                </td>
                              </tr>
                            }
                            head={
                              <tr>
                                {/* Item */}
                                <TableColumnHeadSearch
                                  queryObject={invoiceOtherCreditQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setInvoiceOtherCreditQuery}
                                  setQueryVariable="searchString"
                                  title="Item"
                                  width="200px"
                                />

                                {/* Amount */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceOtherCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceOtherCreditQuery}
                                  title="Amount"
                                  width="125px"
                                />

                                {/* Tax */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceOtherCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "sales_tax",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-sales_tax",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceOtherCreditQuery}
                                  title="Tax"
                                  width="125px"
                                />

                                {/* Net */}
                                <TableColumnHeadSelectSimple
                                  filterObject={invoiceOtherCreditQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "net_value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-net_value",
                                    },
                                  ]}
                                  setFilterObject={setInvoiceOtherCreditQuery}
                                  title="Net"
                                  width="125px"
                                />
                              </tr>
                            }
                            loading={invoiceOtherCreditList.loading}
                            noMargin
                            onClearFilters={() => {
                              setInvoiceOtherCreditQuery({
                                ...invoiceOtherCreditQueryDefault,
                              });
                            }}
                            page={invoiceOtherCreditQuery.page}
                            setPageObject={setInvoiceOtherCreditQuery}
                            setPageVariable="page"
                          />
                        </>
                      }
                      help="Add additional items to be credited"
                      icon="property"
                      title="Additional item credits"
                    />
                  )}

                  {/* Invoice document */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            documentGeneratePdf.loading ||
                            invoiceDetails.loading ||
                            invoiceDocumentUpdate.loading
                          }
                          icon="refresh"
                          intent="primary"
                          onClick={() => {
                            updateDocument();
                          }}
                          text="Refresh"
                        />

                        <Button
                          icon="download"
                          intent="primary"
                          loading={documentGeneratePdf.loading}
                          onClick={() => {
                            saveAs(
                              documentGeneratePdf.invoice_pdf,
                              `Invoice for ${invoiceDetails.invoice.client_name} - ${invoiceDetails.invoice.name}`
                            );
                          }}
                          style={{ margin: "0 0 0 10px" }}
                          text="Download"
                        />
                      </>
                    }
                    content={
                      <TabSectionDocumentEditor3
                        document={documentGeneratePdf.invoice_pdf}
                        error={documentGeneratePdf.error}
                        form={
                          <div style={{ paddingRight: "10px" }}>
                            <Callout
                              intent="primary"
                              style={{ margin: "0 0 15px 0", width: "100%" }}
                            >
                              If you delete the content of the following fields,
                              advaise will use information available in the
                              client and organisation records.
                            </Callout>

                            {/* Font */}
                            <FormDrawerSelectSimpleField3
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="font"
                              label="Font"
                              options={[
                                { id: 1, icon: "font", value: "Courier" },
                                { id: 2, icon: "font", value: "Helvetica" },
                                { id: 3, icon: "font", value: "Montserrat" },
                                { id: 4, icon: "font", value: "Roboto" },
                                { id: 5, icon: "font", value: "Sintony" },
                                { id: 6, icon: "font", value: "Times-Roman" },
                              ]}
                              setValueObject={setInvoiceDocument}
                              setValueVariable="font"
                              value={invoiceDocument.font}
                            />

                            {/* Company logo */}
                            <FormDrawerFileField2
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fileName="company_logo"
                              label="Company logo"
                              placeholder="Upload a logo image"
                              setValueObject={setInvoiceDocument}
                              setValueFileVariable="companyLogo"
                              setValueNameVariable="companyLogoName"
                              valueFile={invoiceDocument.companyLogo}
                              valueName={invoiceDocument.companyLogoName}
                            />

                            {/* Company logo preview */}
                            <div style={{ margin: "0 0 20px 0" }}>
                              {invoiceDocument.companyLogo ? (
                                invoiceDocument.companyLogo !==
                                invoiceDocumentDetails.invoice_document
                                  .company_logo ? (
                                  <TabSectionFieldImage
                                    image={URL.createObjectURL(
                                      new Blob([invoiceDocument.companyLogo])
                                    )}
                                  />
                                ) : (
                                  <TabSectionFieldImage
                                    image={
                                      invoiceDocumentDetails.invoice_document
                                        .company_logo
                                    }
                                  />
                                )
                              ) : undefined}
                            </div>

                            {/* Company entity */}
                            <FormDrawerTextField2
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="company_entity"
                              icon="office"
                              label="Company entity"
                              placeholder="Enter your company's name"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="companyEntity"
                              value={invoiceDocument.companyEntity}
                            />

                            {/* Company address */}
                            <FormDrawerTextAreaField3
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="company_address"
                              height="100px"
                              label="Company address"
                              placeholder="Enter the company's address"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="companyAddress"
                              value={invoiceDocument.companyAddress}
                            />

                            {/* Company phone */}
                            <FormDrawerTextField2
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="company_phone"
                              icon="phone"
                              label="Company phone"
                              placeholder="Enter your company's phone number"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="companyPhone"
                              value={invoiceDocument.companyPhone}
                            />

                            {/* Company email */}
                            <FormDrawerTextField2
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="company_email"
                              icon="envelope"
                              label="Company email"
                              placeholder="Enter your company's email"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="companyEmail"
                              value={invoiceDocument.companyEmail}
                            />

                            {/* Client entity */}
                            <FormDrawerTextField2
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="client_entity"
                              icon="office"
                              label="Client entity"
                              placeholder="Enter the client's name"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="clientEntity"
                              value={invoiceDocument.clientEntity}
                            />

                            {/* Client address */}
                            <FormDrawerTextAreaField3
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="client_address"
                              height="100px"
                              label="Client address"
                              placeholder="Enter the client's address"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="clientAddress"
                              value={invoiceDocument.clientAddress}
                            />

                            {/* Purchase order */}
                            <FormDrawerTextField2
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="po"
                              icon="barcode"
                              label="Purchase order"
                              placeholder="Enter the purchase order reference"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="po"
                              value={invoiceDocument.po}
                            />

                            {/* Message */}
                            <FormDrawerTextAreaField3
                              disabled={
                                invoiceDetails.invoice.status !== "Draft"
                              }
                              error={invoiceDocumentUpdate.error}
                              fieldName="message"
                              height="100px"
                              label="Message"
                              placeholder="Enter a message for the client"
                              setValueObject={setInvoiceDocument}
                              setValueVariable="message"
                              value={invoiceDocument.message}
                            />
                          </div>
                        }
                        loading={
                          documentGeneratePdf.loading ||
                          invoiceDetails.loading ||
                          invoiceDocumentUpdate.loading
                        }
                      />
                    }
                    help="Edit the invoice document"
                    icon="book"
                    title="Invoice document"
                  />

                  {/* Approvals */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          invoiceDetails.invoice.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddApprovalRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalRequestList.approval_requests.map(
                            (approval_request) => {
                              return (
                                <tr
                                  key={approval_request.id}
                                  onClick={() => {
                                    if (
                                      invoiceDetails.invoice
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listApprovalRequestDetailsAction(
                                          approval_request.id
                                        )
                                      );

                                      if (
                                        approval_request.status === "Completed"
                                      ) {
                                        setViewApprovalDrawerIsOpen(true);
                                      } else {
                                        setUpdateApprovalRequestDrawerIsOpen(
                                          true
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={approval_request.decision_right}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={approval_request.value}
                                      tagText={activeOrganisation.currency}
                                    />
                                  </td>

                                  {/* Approver */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={approval_request.approver_name}
                                      image={
                                        approval_request.approver_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .reviewed
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome === "Approved"
                                            ? "success"
                                            : approval_request.response_data
                                                .outcome === "Denied"
                                            ? "danger"
                                            : "primary"
                                          : undefined
                                      }
                                      value={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalRequestQuery.approver &&
                            !approvalRequestQuery.decisionRight &&
                            !approvalRequestQuery.outcome &&
                            !approvalRequestQuery.reviewedEnd &&
                            !approvalRequestQuery.reviewedStart &&
                            !approvalRequestQuery.value
                          }
                          count={approvalRequestList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Invoice",
                                    value: "Invoice",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Approver */}
                              <TableColumnHeadSelect2
                                filterObject={approvalRequestQuery}
                                filterVariable="approver"
                                items={managerList.managers}
                                setFilterObject={setApprovalRequestQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Approver"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalRequestQuery}
                                setQueryObject={setApprovalRequestQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalRequestQuery({
                              ...approvalRequestQueryDefault,
                            });
                          }}
                          page={approvalRequestQuery.page}
                          setPageObject={setApprovalRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage approval requests"
                    icon="book"
                    message="When you create an approval request, advaise.app generates a fact set to inform the approver's decision. This fact set is based on the information at the time. If there is a change, consider submitting a new approval request."
                    messageIntent="warning"
                    title="Approvals"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default InvoiceDetailScreen;
