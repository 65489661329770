export const OPENING_LIST_REQUEST = "OPENING_LIST_REQUEST";
export const OPENING_LIST_SUCCESS = "OPENING_LIST_SUCCESS";
export const OPENING_LIST_FAIL = "OPENING_LIST_FAIL";
export const OPENING_LIST_RESET = "OPENING_LIST_RESET";

export const OPENING_CREATE_REQUEST = "OPENING_CREATE_REQUEST";
export const OPENING_CREATE_SUCCESS = "OPENING_CREATE_SUCCESS";
export const OPENING_CREATE_FAIL = "OPENING_CREATE_FAIL";
export const OPENING_CREATE_RESET = "OPENING_CREATE_RESET";

export const OPENING_DETAILS_REQUEST = "OPENING_DETAILS_REQUEST";
export const OPENING_DETAILS_SUCCESS = "OPENING_DETAILS_SUCCESS";
export const OPENING_DETAILS_FAIL = "OPENING_DETAILS_FAIL";
export const OPENING_DETAILS_RESET = "OPENING_DETAILS_RESET";

export const OPENING_UPDATE_REQUEST = "OPENING_UPDATE_REQUEST";
export const OPENING_UPDATE_SUCCESS = "OPENING_UPDATE_SUCCESS";
export const OPENING_UPDATE_FAIL = "OPENING_UPDATE_FAIL";
export const OPENING_UPDATE_RESET = "OPENING_UPDATE_RESET";

export const OPENING_DELETE_REQUEST = "OPENING_DELETE_REQUEST";
export const OPENING_DELETE_SUCCESS = "OPENING_DELETE_SUCCESS";
export const OPENING_DELETE_FAIL = "OPENING_DELETE_FAIL";
export const OPENING_DELETE_RESET = "OPENING_DELETE_RESET";

export const OPENING_REFERRER_LIST_REQUEST = "OPENING_REFERRER_LIST_REQUEST";
export const OPENING_REFERRER_LIST_SUCCESS = "OPENING_REFERRER_LIST_SUCCESS";
export const OPENING_REFERRER_LIST_FAIL = "OPENING_REFERRER_LIST_FAIL";
export const OPENING_REFERRER_LIST_RESET = "OPENING_REFERRER_LIST_RESET";

export const OPENING_DOWNLOAD_REQUEST = "OPENING_DOWNLOAD_REQUEST";
export const OPENING_DOWNLOAD_SUCCESS = "OPENING_DOWNLOAD_SUCCESS";
export const OPENING_DOWNLOAD_FAIL = "OPENING_DOWNLOAD_FAIL";
export const OPENING_DOWNLOAD_RESET = "OPENING_DOWNLOAD_RESET";
