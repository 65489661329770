// Library imports
import { Classes, Colors, HTMLTable, Tag, Text } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Bar, BarChart, ResponsiveContainer } from "recharts";

// Component imports
import InfoPopup from "../components/InfoPopup";
import TabSectionText from "../components/TabSectionText";

// Main function
function ClientFinancials({ data, interactive, loading, noMargin }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  /* Process data */
  const [cashFlowCoverageRatioValues, setCashFlowCoverageRatioValues] =
    useState([]);
  const [cashFlowMarginValues, setCashFlowMarginValues] = useState([]);
  const [
    currentLiabilityCoverageRatioValues,
    setCurrentLiabilityCoverageRatioValues,
  ] = useState([]);
  const [currentRatioValues, setCurrentRatioValues] = useState([]);
  const [debtToEquityRatioValues, setDebtToEquityRatioValues] = useState([]);
  const [grossProfitValues, setGrossProfitValues] = useState([]);
  const [headings, setHeadings] = useState([]);
  const [netProfitValues, setNetProfitValues] = useState([]);
  const [operatingProfitValues, setOperatingProfitValues] = useState([]);
  const [quickRatioValues, setQuickRatioValues] = useState([]);
  const [solvencyRatioValues, setSolvencyRatioValues] = useState([]);
  const [totalRevenueValues, setTotalRevenueValues] = useState([]);
  const [workingCapitalValues, setWorkingCapitalValues] = useState([]);

  useEffect(() => {
    if (data) {
      let arr = [];

      /* Headings */
      for (let key in data.cash_flow_statement_dates) {
        arr.push(
          new Date(data.cash_flow_statement_dates[key]).toLocaleDateString()
        );
      }
      setHeadings(arr);

      /* Cash flow coverage ratio */
      arr = [];
      for (let key in data.cash_flow_statement_dates) {
        arr.push({
          label: data.cash_flow_coverage_ratio[
            data.cash_flow_statement_dates[key]
          ]
            ? parseFloat(
                data.cash_flow_coverage_ratio[
                  data.cash_flow_statement_dates[key]
                ]
              ).toFixed(2)
            : "-",
          value: data.cash_flow_coverage_ratio[
            data.cash_flow_statement_dates[key]
          ]
            ? data.cash_flow_coverage_ratio[data.cash_flow_statement_dates[key]]
            : 0,
        });
      }
      setCashFlowCoverageRatioValues(arr);

      /* Cash flow margin */
      arr = [];
      for (let key in data.cash_flow_statement_dates) {
        arr.push({
          label: data.cash_flow_margin[data.cash_flow_statement_dates[key]]
            ? parseInt(
                data.cash_flow_margin[data.cash_flow_statement_dates[key]]
              )
            : "-",
          value: data.cash_flow_margin[data.cash_flow_statement_dates[key]]
            ? data.cash_flow_margin[data.cash_flow_statement_dates[key]]
            : 0,
        });
      }
      setCashFlowMarginValues(arr);

      /* Current liability coverage ratio */
      arr = [];
      for (let key in data.cash_flow_statement_dates) {
        arr.push({
          label: data.current_liability_coverage_ratio[
            data.cash_flow_statement_dates[key]
          ]
            ? parseFloat(
                data.current_liability_coverage_ratio[
                  data.cash_flow_statement_dates[key]
                ]
              ).toFixed(2)
            : "0",
          value: data.current_liability_coverage_ratio[
            data.cash_flow_statement_dates[key]
          ]
            ? data.current_liability_coverage_ratio[
                data.cash_flow_statement_dates[key]
              ]
            : 0,
        });
      }
      setCurrentLiabilityCoverageRatioValues(arr);

      /* Current ratio */
      arr = [];
      for (let key in data.balance_sheet_dates) {
        arr.push({
          label: data.current_ratio[data.balance_sheet_dates[key]]
            ? parseFloat(
                data.current_ratio[data.balance_sheet_dates[key]]
              ).toFixed(2)
            : "-",
          value: data.current_ratio[data.balance_sheet_dates[key]]
            ? data.current_ratio[data.balance_sheet_dates[key]]
            : 0,
        });
      }
      setCurrentRatioValues(arr);

      /* Debt-to-equity ratio */
      arr = [];
      for (let key in data.balance_sheet_dates) {
        arr.push({
          label: data.debt_to_equity_ratio[data.balance_sheet_dates[key]]
            ? parseFloat(
                data.debt_to_equity_ratio[data.balance_sheet_dates[key]]
              ).toFixed(2)
            : "-",
          value: data.debt_to_equity_ratio[data.balance_sheet_dates[key]]
            ? data.debt_to_equity_ratio[data.balance_sheet_dates[key]]
            : 0,
        });
      }
      setDebtToEquityRatioValues(arr);

      /* Gross profit */
      arr = [];
      for (let key in data.income_statement_dates) {
        arr.push({
          date: data.income_statement_dates[key],
          label: data.gross_profit[data.income_statement_dates[key]]
            ? parseInt(
                data.gross_profit[data.income_statement_dates[key]] / 1000
              ).toLocaleString()
            : "-",
          value: data.gross_profit[data.income_statement_dates[key]]
            ? data.gross_profit[data.income_statement_dates[key]]
            : 0,
        });
      }
      setGrossProfitValues(arr);

      /* Net profit */
      arr = [];
      for (let key in data.income_statement_dates) {
        arr.push({
          date: data.income_statement_dates[key],
          label: data.net_profit[data.income_statement_dates[key]]
            ? parseInt(
                data.net_profit[data.income_statement_dates[key]] / 1000
              ).toLocaleString()
            : "-",
          value: data.net_profit[data.income_statement_dates[key]]
            ? data.net_profit[data.income_statement_dates[key]]
            : 0,
        });
      }
      setNetProfitValues(arr);

      /* Operating profit */
      arr = [];
      for (let key in data.income_statement_dates) {
        arr.push({
          date: data.income_statement_dates[key],
          label: data.operating_profit[data.income_statement_dates[key]]
            ? parseInt(
                data.operating_profit[data.income_statement_dates[key]] / 1000
              ).toLocaleString()
            : "-",
          value: data.operating_profit[data.income_statement_dates[key]]
            ? data.operating_profit[data.income_statement_dates[key]]
            : 0,
        });
      }
      setOperatingProfitValues(arr);

      /* Quick ratio */
      arr = [];
      for (let key in data.balance_sheet_dates) {
        arr.push({
          label: data.quick_ratio[data.balance_sheet_dates[key]]
            ? parseFloat(
                data.quick_ratio[data.balance_sheet_dates[key]]
              ).toFixed(2)
            : "-",
          value: data.quick_ratio[data.balance_sheet_dates[key]]
            ? data.quick_ratio[data.balance_sheet_dates[key]]
            : 0,
        });
      }
      setQuickRatioValues(arr);

      /* Solvency ratio */
      arr = [];
      for (let key in data.balance_sheet_dates) {
        arr.push({
          label: data.solvency_ratio[data.balance_sheet_dates[key]]
            ? parseFloat(
                data.solvency_ratio[data.balance_sheet_dates[key]]
              ).toFixed(2)
            : "-",
          value: data.solvency_ratio[data.balance_sheet_dates[key]]
            ? data.solvency_ratio[data.balance_sheet_dates[key]]
            : 0,
        });
      }
      setSolvencyRatioValues(arr);

      /* Total revenue */
      arr = [];
      for (let key in data.income_statement_dates) {
        arr.push({
          label: data.total_revenue[data.income_statement_dates[key]]
            ? parseInt(
                data.total_revenue[data.income_statement_dates[key]] / 1000
              ).toLocaleString()
            : "-",
          value: data.total_revenue[data.income_statement_dates[key]]
            ? data.total_revenue[data.income_statement_dates[key]]
            : 0,
        });
      }
      setTotalRevenueValues(arr);

      /* Working capital */
      arr = [];
      for (let key in data.balance_sheet_dates) {
        arr.push({
          label: data.working_capital[data.balance_sheet_dates[key]]
            ? parseInt(
                data.working_capital[data.balance_sheet_dates[key]] / 1000
              ).toLocaleString()
            : "-",
          value: data.working_capital[data.balance_sheet_dates[key]]
            ? data.working_capital[data.balance_sheet_dates[key]]
            : 0,
        });
      }
      setWorkingCapitalValues(arr);
    }
  }, [data]);

  // JSX UI code
  return (
    <div>
      {data ? (
        <div
          style={{
            background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
            margin: noMargin ? "0" : "20px",
            maxWidth: "fit-content",
            padding: "10px",
          }}
        >
          <div
            style={{
              overflowX: "auto",
            }}
          >
            <HTMLTable
              bordered
              interactive={interactive}
              striped
              style={{
                margin: "5px 0 5px 0",
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              {/* Head */}
              <thead
                //className={Classes.TEXT_SMALL}
                style={{
                  background: lightMode
                    ? Colors.LIGHT_GRAY5
                    : Colors.DARK_GRAY4,
                }}
              >
                <tr>
                  <th style={{ width: "200px" }}>Category</th>

                  <th style={{ width: "100px" }}>Trend</th>

                  <th style={{ width: "100px" }}>Change</th>

                  {headings.map((heading) => {
                    return (
                      <th key={heading} style={{ width: "150px" }}>
                        {heading}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {/* Body */}
              <tbody
                //className={Classes.TEXT_SMALL}
                style={{
                  background: lightMode ? Colors.WHITE : Colors.DARK_GRAY3,
                }}
              >
                {/* Cash flow coverage ratio */}
                {data.cash_flow_coverage_ratio ? (
                  <tr>
                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Cash flow coverage ratio</Text>

                        <InfoPopup
                          content="Cash flow from operating activities divided by total debt"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={cashFlowCoverageRatioValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.cash_flow_coverage_ratio_change >= 0
                            ? "success"
                            : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.cash_flow_coverage_ratio_change
                          ? parseInt(data.cash_flow_coverage_ratio_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {cashFlowCoverageRatioValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Cash flow margin */}
                {data.cash_flow_margin ? (
                  <tr>
                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Cash flow margin</Text>

                        <InfoPopup
                          content="Cash flow from operating activities divided by total revenue"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={cashFlowMarginValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.cash_flow_margin_change >= 0
                            ? "success"
                            : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.cash_flow_margin_change
                          ? parseInt(data.cash_flow_margin_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {cashFlowMarginValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}%
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Current liability coverage ratio */}
                {data.current_liability_coverage_ratio ? (
                  <tr>
                    <td
                      style={{
                        verticalAlign: "middle",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Current liability coverage ratio</Text>

                        <InfoPopup
                          content="Cash flow from operating activities divided by current liabilities"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={currentLiabilityCoverageRatioValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.current_liability_coverage_ratio_change >= 0
                            ? "success"
                            : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.current_liability_coverage_ratio_change
                          ? parseInt(
                              data.current_liability_coverage_ratio_change
                            ) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {currentLiabilityCoverageRatioValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Current ratio */}
                {data.current_ratio ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Current ratio</Text>

                        <InfoPopup
                          content="Current assets divided by current liabilities"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={currentRatioValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.current_ratio_change >= 0 ? "success" : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.current_ratio_change
                          ? parseInt(data.current_ratio_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {currentRatioValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Debt-to-equity ratio */}
                {data.debt_to_equity_ratio ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Debt-to-equity ratio</Text>

                        <InfoPopup
                          content="Total liabilities divided by shareholder equity"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={debtToEquityRatioValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.debt_to_equity_ratio_change <= 0
                            ? "success"
                            : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.debt_to_equity_ratio_change
                          ? parseInt(data.debt_to_equity_ratio_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {debtToEquityRatioValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Gross profit */}
                {data.gross_profit ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Gross profit</Text>

                        <InfoPopup
                          content="Total revenue less direct costs in reference currency or as a percentage of total revenue"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={grossProfitValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.gross_profit_change >= 0 ? "success" : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.gross_profit_change
                          ? parseInt(data.gross_profit_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {grossProfitValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {item.label}

                            <Tag
                              intent="primary"
                              minimal
                              style={{ textAlign: "center", width: "50px" }}
                            >
                              {data.gross_profit_percent[item.date]
                                ? parseInt(
                                    data.gross_profit_percent[item.date]
                                  ) + "%"
                                : "-"}
                            </Tag>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Net profit */}
                {data.net_profit ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Net profit</Text>

                        <InfoPopup
                          content="Operating profit less interest and tax expenses in reference currency or as a percentage of total revenue"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={netProfitValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.net_profit_change >= 0 ? "success" : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.net_profit_change
                          ? parseInt(data.net_profit_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {netProfitValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {item.label}

                            <Tag
                              intent="primary"
                              minimal
                              style={{ textAlign: "center", width: "50px" }}
                            >
                              {data.net_profit_percent[item.date]
                                ? parseInt(data.net_profit_percent[item.date]) +
                                  "%"
                                : "-"}
                            </Tag>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Operating profit */}
                {data.operating_profit ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Operating profit</Text>

                        <InfoPopup
                          content="Gross profit less overheads in reference currency or as a percentage of total revenue"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={operatingProfitValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.operating_profit_change >= 0
                            ? "success"
                            : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.operating_profit_change
                          ? parseInt(data.operating_profit_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {operatingProfitValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          <div
                            style={{
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {item.label}

                            <Tag
                              intent="primary"
                              minimal
                              style={{ textAlign: "center", width: "50px" }}
                            >
                              {data.operating_profit_percent[item.date]
                                ? parseInt(
                                    data.operating_profit_percent[item.date]
                                  ) + "%"
                                : "-"}
                            </Tag>
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Quick ratio */}
                {data.quick_ratio ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Quick ratio</Text>

                        <InfoPopup
                          content="Current assets less inventory divided by current liabilities"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={quickRatioValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.quick_ratio_change >= 0 ? "success" : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.quick_ratio_change
                          ? parseInt(data.quick_ratio_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {quickRatioValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Solvency ratio */}
                {data.solvency_ratio ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Solvency ratio</Text>

                        <InfoPopup
                          content="Total revenue plus depreciation divided by total liabilities"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={solvencyRatioValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.solvency_ratio_change >= 0 ? "success" : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.solvency_ratio_change
                          ? parseInt(data.solvency_ratio_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {solvencyRatioValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Total revenue */}
                {data.total_revenue ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Total revenue</Text>

                        <InfoPopup
                          content="Aggregated revenue from operating activities"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={totalRevenueValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.total_revenue_change >= 0 ? "success" : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.total_revenue_change
                          ? parseInt(data.total_revenue_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {totalRevenueValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}

                {/* Working capital */}
                {data.working_capital ? (
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text ellipsize>Working capital</Text>

                        <InfoPopup
                          content="Current assets less current liabilities"
                          flexWidth
                        />
                      </div>
                    </td>

                    <td style={{ verticalAlign: "middle" }}>
                      <ResponsiveContainer height={30} width="100%">
                        <BarChart
                          data={workingCapitalValues}
                          margin={{
                            bottom: 5,
                            left: 5,
                            right: 5,
                            top: 5,
                          }}
                        >
                          <Bar
                            dataKey="value"
                            fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </td>

                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      <Tag
                        intent={
                          data.working_capital_change >= 0
                            ? "success"
                            : "danger"
                        }
                        large
                        minimal
                        style={{
                          textAlign: "center",
                          minWidth: "75px",
                        }}
                      >
                        {data.working_capital_change
                          ? parseInt(data.working_capital_change) + "%"
                          : "-"}
                      </Tag>
                    </td>

                    {workingCapitalValues.map((item, index) => {
                      return (
                        <td key={index} style={{ verticalAlign: "middle" }}>
                          {item.label}
                        </td>
                      );
                    })}
                  </tr>
                ) : undefined}
              </tbody>
            </HTMLTable>
          </div>

          {/* Footnote */}
          <div className={Classes.TEXT_MUTED}>
            <small>
              Figures in thousands, excluding percentages and ratios
            </small>
          </div>
        </div>
      ) : (
        <TabSectionText loading={loading} />
      )}
    </div>
  );
}

export default ClientFinancials;
