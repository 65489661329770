// Constant imports
import {
  HELP_CONTENT_REQUEST,
  HELP_CONTENT_SUCCESS,
  HELP_CONTENT_FAIL,
  HELP_CONTENT_RESET,
} from "../constants/helpContentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listHelpContentAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: HELP_CONTENT_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getHelpContent(token);

    dispatch({
      type: HELP_CONTENT_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: HELP_CONTENT_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListHelpContentAction = () => async (dispatch) => {
  dispatch({ type: HELP_CONTENT_RESET });
};
