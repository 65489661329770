export const OPENING_EVALUATION_RATING_LIST_REQUEST =
  "OPENING_EVALUATION_RATING_LIST_REQUEST";
export const OPENING_EVALUATION_RATING_LIST_SUCCESS =
  "OPENING_EVALUATION_RATING_LIST_SUCCESS";
export const OPENING_EVALUATION_RATING_LIST_FAIL =
  "OPENING_EVALUATION_RATING_LIST_FAIL";
export const OPENING_EVALUATION_RATING_LIST_RESET =
  "OPENING_EVALUATION_RATING_LIST_RESET";

export const OPENING_EVALUATION_RATING_UPDATE_REQUEST =
  "OPENING_EVALUATION_RATING_UPDATE_REQUEST";
export const OPENING_EVALUATION_RATING_UPDATE_SUCCESS =
  "OPENING_EVALUATION_RATING_UPDATE_SUCCESS";
export const OPENING_EVALUATION_RATING_UPDATE_FAIL =
  "OPENING_EVALUATION_RATING_UPDATE_FAIL";
export const OPENING_EVALUATION_RATING_UPDATE_RESET =
  "OPENING_EVALUATION_RATING_UPDATE_RESET";

export const OPENING_EVALUATION_RATINGS_UPLOAD_BEGIN =
  "OPENING_EVALUATION_RATINGS_UPLOAD_BEGIN";
export const OPENING_EVALUATION_RATINGS_UPLOAD_REQUEST =
  "OPENING_EVALUATION_RATINGS_UPLOAD_REQUEST";
export const OPENING_EVALUATION_RATINGS_UPLOAD_SUCCESS =
  "OPENING_EVALUATION_RATINGS_UPLOAD_SUCCESS";
export const OPENING_EVALUATION_RATINGS_UPLOAD_FAIL =
  "OPENING_EVALUATION_RATINGS_UPLOAD_FAIL";
export const OPENING_EVALUATION_RATINGS_UPLOAD_END =
  "OPENING_EVALUATION_RATINGS_UPLOAD_END";
export const OPENING_EVALUATION_RATINGS_UPLOAD_RESET =
  "OPENING_EVALUATION_RATINGS_UPLOAD_RESET";
