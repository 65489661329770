export const LEAVE_CATEGORY_LIST_REQUEST = "LEAVE_CATEGORY_LIST_REQUEST";
export const LEAVE_CATEGORY_LIST_SUCCESS = "LEAVE_CATEGORY_LIST_SUCCESS";
export const LEAVE_CATEGORY_LIST_FAIL = "LEAVE_CATEGORY_LIST_FAIL";
export const LEAVE_CATEGORY_LIST_RESET = "LEAVE_CATEGORY_LIST_RESET";

export const LEAVE_CATEGORY_CREATE_REQUEST = "LEAVE_CATEGORY_CREATE_REQUEST";
export const LEAVE_CATEGORY_CREATE_SUCCESS = "LEAVE_CATEGORY_CREATE_SUCCESS";
export const LEAVE_CATEGORY_CREATE_FAIL = "LEAVE_CATEGORY_CREATE_FAIL";
export const LEAVE_CATEGORY_CREATE_RESET = "LEAVE_CATEGORY_CREATE_RESET";

export const LEAVE_CATEGORY_DETAILS_REQUEST = "LEAVE_CATEGORY_DETAILS_REQUEST";
export const LEAVE_CATEGORY_DETAILS_SUCCESS = "LEAVE_CATEGORY_DETAILS_SUCCESS";
export const LEAVE_CATEGORY_DETAILS_FAIL = "LEAVE_CATEGORY_DETAILS_FAIL";
export const LEAVE_CATEGORY_DETAILS_RESET = "LEAVE_CATEGORY_DETAILS_RESET";

export const LEAVE_CATEGORY_UPDATE_REQUEST = "LEAVE_CATEGORY_UPDATE_REQUEST";
export const LEAVE_CATEGORY_UPDATE_SUCCESS = "LEAVE_CATEGORY_UPDATE_SUCCESS";
export const LEAVE_CATEGORY_UPDATE_FAIL = "LEAVE_CATEGORY_UPDATE_FAIL";
export const LEAVE_CATEGORY_UPDATE_RESET = "LEAVE_CATEGORY_UPDATE_RESET";

export const LEAVE_CATEGORY_DELETE_REQUEST = "LEAVE_CATEGORY_DELETE_REQUEST";
export const LEAVE_CATEGORY_DELETE_SUCCESS = "LEAVE_CATEGORY_DELETE_SUCCESS";
export const LEAVE_CATEGORY_DELETE_FAIL = "LEAVE_CATEGORY_DELETE_FAIL";
export const LEAVE_CATEGORY_DELETE_RESET = "LEAVE_CATEGORY_DELETE_RESET";

export const LEAVE_CATEGORY_DOWNLOAD_REQUEST =
  "LEAVE_CATEGORY_DOWNLOAD_REQUEST";
export const LEAVE_CATEGORY_DOWNLOAD_SUCCESS =
  "LEAVE_CATEGORY_DOWNLOAD_SUCCESS";
export const LEAVE_CATEGORY_DOWNLOAD_FAIL = "LEAVE_CATEGORY_DOWNLOAD_FAIL";
export const LEAVE_CATEGORY_DOWNLOAD_RESET = "LEAVE_CATEGORY_DOWNLOAD_RESET";

export const LEAVE_CATEGORY_UPLOAD_BEGIN = "LEAVE_CATEGORY_UPLOAD_BEGIN";
export const LEAVE_CATEGORY_UPLOAD_REQUEST = "LEAVE_CATEGORY_UPLOAD_REQUEST";
export const LEAVE_CATEGORY_UPLOAD_SUCCESS = "LEAVE_CATEGORY_UPLOAD_SUCCESS";
export const LEAVE_CATEGORY_UPLOAD_FAIL = "LEAVE_CATEGORY_UPLOAD_FAIL";
export const LEAVE_CATEGORY_UPLOAD_END = "LEAVE_CATEGORY_UPLOAD_END";
export const LEAVE_CATEGORY_UPLOAD_RESET = "LEAVE_CATEGORY_UPLOAD_RESET";
