export const APPROVAL_REQUEST_LIST_REQUEST = "APPROVAL_REQUEST_LIST_REQUEST";
export const APPROVAL_REQUEST_LIST_SUCCESS = "APPROVAL_REQUEST_LIST_SUCCESS";
export const APPROVAL_REQUEST_LIST_FAIL = "APPROVAL_REQUEST_LIST_FAIL";
export const APPROVAL_REQUEST_LIST_RESET = "APPROVAL_REQUEST_LIST_RESET";

export const APPROVAL_REQUEST_CREATE_REQUEST =
  "APPROVAL_REQUEST_CREATE_REQUEST";
export const APPROVAL_REQUEST_CREATE_SUCCESS =
  "APPROVAL_REQUEST_CREATE_SUCCESS";
export const APPROVAL_REQUEST_CREATE_FAIL = "APPROVAL_REQUEST_CREATE_FAIL";
export const APPROVAL_REQUEST_CREATE_RESET = "APPROVAL_REQUEST_CREATE_RESET";

export const APPROVAL_REQUEST_DETAILS_REQUEST =
  "APPROVAL_REQUEST_DETAILS_REQUEST";
export const APPROVAL_REQUEST_DETAILS_SUCCESS =
  "APPROVAL_REQUEST_DETAILS_SUCCESS";
export const APPROVAL_REQUEST_DETAILS_FAIL = "APPROVAL_REQUEST_DETAILS_FAIL";
export const APPROVAL_REQUEST_DETAILS_RESET = "APPROVAL_REQUEST_DETAILS_RESET";

export const APPROVAL_REQUEST_UPDATE_REQUEST =
  "APPROVAL_REQUEST_UPDATE_REQUEST";
export const APPROVAL_REQUEST_UPDATE_SUCCESS =
  "APPROVAL_REQUEST_UPDATE_SUCCESS";
export const APPROVAL_REQUEST_UPDATE_FAIL = "APPROVAL_REQUEST_UPDATE_FAIL";
export const APPROVAL_REQUEST_UPDATE_RESET = "APPROVAL_REQUEST_UPDATE_RESET";

export const APPROVAL_REQUEST_DELETE_REQUEST =
  "APPROVAL_REQUEST_DELETE_REQUEST";
export const APPROVAL_REQUEST_DELETE_SUCCESS =
  "APPROVAL_REQUEST_DELETE_SUCCESS";
export const APPROVAL_REQUEST_DELETE_FAIL = "APPROVAL_REQUEST_DELETE_FAIL";
export const APPROVAL_REQUEST_DELETE_RESET = "APPROVAL_REQUEST_DELETE_RESET";

export const APPROVAL_REQUEST_DOWNLOAD_REQUEST =
  "APPROVAL_REQUEST_DOWNLOAD_REQUEST";
export const APPROVAL_REQUEST_DOWNLOAD_SUCCESS =
  "APPROVAL_REQUEST_DOWNLOAD_SUCCESS";
export const APPROVAL_REQUEST_DOWNLOAD_FAIL = "APPROVAL_REQUEST_DOWNLOAD_FAIL";
export const APPROVAL_REQUEST_DOWNLOAD_RESET =
  "APPROVAL_REQUEST_DOWNLOAD_RESET";
