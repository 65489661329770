export const APPROVAL_ROLE_LIST_REQUEST = "APPROVAL_ROLE_LIST_REQUEST";
export const APPROVAL_ROLE_LIST_SUCCESS = "APPROVAL_ROLE_LIST_SUCCESS";
export const APPROVAL_ROLE_LIST_FAIL = "APPROVAL_ROLE_LIST_FAIL";
export const APPROVAL_ROLE_LIST_RESET = "APPROVAL_ROLE_LIST_RESET";

export const APPROVAL_ROLE_CREATE_REQUEST = "APPROVAL_ROLE_CREATE_REQUEST";
export const APPROVAL_ROLE_CREATE_SUCCESS = "APPROVAL_ROLE_CREATE_SUCCESS";
export const APPROVAL_ROLE_CREATE_FAIL = "APPROVAL_ROLE_CREATE_FAIL";
export const APPROVAL_ROLE_CREATE_RESET = "APPROVAL_ROLE_CREATE_RESET";

export const APPROVAL_ROLE_DETAILS_REQUEST = "APPROVAL_ROLE_DETAILS_REQUEST";
export const APPROVAL_ROLE_DETAILS_SUCCESS = "APPROVAL_ROLE_DETAILS_SUCCESS";
export const APPROVAL_ROLE_DETAILS_FAIL = "APPROVAL_ROLE_DETAILS_FAIL";
export const APPROVAL_ROLE_DETAILS_RESET = "APPROVAL_ROLE_DETAILS_RESET";

export const APPROVAL_ROLE_UPDATE_REQUEST = "APPROVAL_ROLE_UPDATE_REQUEST";
export const APPROVAL_ROLE_UPDATE_SUCCESS = "APPROVAL_ROLE_UPDATE_SUCCESS";
export const APPROVAL_ROLE_UPDATE_FAIL = "APPROVAL_ROLE_UPDATE_FAIL";
export const APPROVAL_ROLE_UPDATE_RESET = "APPROVAL_ROLE_UPDATE_RESET";

export const APPROVAL_ROLE_DELETE_REQUEST = "APPROVAL_ROLE_DELETE_REQUEST";
export const APPROVAL_ROLE_DELETE_SUCCESS = "APPROVAL_ROLE_DELETE_SUCCESS";
export const APPROVAL_ROLE_DELETE_FAIL = "APPROVAL_ROLE_DELETE_FAIL";
export const APPROVAL_ROLE_DELETE_RESET = "APPROVAL_ROLE_DELETE_RESET";
