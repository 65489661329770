export const SEAT_LIST_REQUEST = "SEAT_LIST_REQUEST";
export const SEAT_LIST_SUCCESS = "SEAT_LIST_SUCCESS";
export const SEAT_LIST_FAIL = "SEAT_LIST_FAIL";
export const SEAT_LIST_RESET = "SEAT_LIST_RESET";

export const SEAT_DETAILS_REQUEST = "SEAT_DETAILS_REQUEST";
export const SEAT_DETAILS_SUCCESS = "SEAT_DETAILS_SUCCESS";
export const SEAT_DETAILS_FAIL = "SEAT_DETAILS_FAIL";
export const SEAT_DETAILS_RESET = "SEAT_DETAILS_RESET";

export const SEAT_UPDATE_REQUEST = "SEAT_UPDATE_REQUEST";
export const SEAT_UPDATE_SUCCESS = "SEAT_UPDATE_SUCCESS";
export const SEAT_UPDATE_FAIL = "SEAT_UPDATE_FAIL";
export const SEAT_UPDATE_RESET = "SEAT_UPDATE_RESET";
