// Constant imports
import {
  CASE_STUDY_LIST_REQUEST,
  CASE_STUDY_LIST_SUCCESS,
  CASE_STUDY_LIST_FAIL,
  CASE_STUDY_LIST_RESET,
  CASE_STUDY_CREATE_REQUEST,
  CASE_STUDY_CREATE_SUCCESS,
  CASE_STUDY_CREATE_FAIL,
  CASE_STUDY_CREATE_RESET,
  CASE_STUDY_DETAILS_REQUEST,
  CASE_STUDY_DETAILS_SUCCESS,
  CASE_STUDY_DETAILS_FAIL,
  CASE_STUDY_DETAILS_RESET,
  CASE_STUDY_UPDATE_REQUEST,
  CASE_STUDY_UPDATE_SUCCESS,
  CASE_STUDY_UPDATE_FAIL,
  CASE_STUDY_UPDATE_RESET,
  CASE_STUDY_DELETE_REQUEST,
  CASE_STUDY_DELETE_SUCCESS,
  CASE_STUDY_DELETE_FAIL,
  CASE_STUDY_DELETE_RESET,
} from "../constants/caseStudyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listCaseStudiesAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CASE_STUDY_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getCaseStudies(params, token);

    dispatch({
      type: CASE_STUDY_LIST_SUCCESS,

      payload: { count: data.count, case_studies: data.results },
    });
  } catch (error) {
    dispatch({
      type: CASE_STUDY_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListCaseStudiesAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_LIST_RESET });
};

// Create
export const createCaseStudyAction =
  (client, case_study) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          case_study.append("client", data.id);
        }
      }

      const { data } = await cortixDataService.createCaseStudy(
        token,
        case_study
      );

      dispatch({
        type: CASE_STUDY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_CREATE_RESET });
};

// List details
export const listCaseStudyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCaseStudy(id, token);

      dispatch({
        type: CASE_STUDY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListCaseStudyDetailsAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_DETAILS_RESET });
};

// Update
export const updateCaseStudyAction =
  (id, client, case_study) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          case_study.append("client", data.id);
        }
      }

      const { data } = await cortixDataService.updateCaseStudy(
        id,
        token,
        case_study
      );

      dispatch({
        type: CASE_STUDY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_UPDATE_RESET });
};

// Delete
export const deleteCaseStudyAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CASE_STUDY_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteCaseStudy(id, token);

    dispatch({
      type: CASE_STUDY_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CASE_STUDY_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_DELETE_RESET });
};
