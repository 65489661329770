// Library imports
import { Button, Colors, FormGroup } from "@blueprintjs/core";
import React from "react";

// Main function
function FormDrawerButtonField({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  label,
  labelInfo,
  options,
  setValueObject,
  setValueVariable,
  value,
}) {
  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <div>
        {options.map((option) => {
          return (
            <Button
              disabled={disabled}
              icon={option.icon}
              intent={value === option.value ? option.intent : undefined}
              key={option.id}
              onClick={() => {
                setValueObject((prevState) => {
                  return {
                    ...prevState,
                    [setValueVariable]: option.value,
                  };
                });
              }}
              outlined={value !== option.value}
              style={{ margin: "0 5px 3px 0" }}
              text={option.label}
            />
          );
        })}
      </div>
    </FormGroup>
  );
}

export default FormDrawerButtonField;
