// Constant imports
import {
  LEAVE_REQUEST_LIST_REQUEST,
  LEAVE_REQUEST_LIST_SUCCESS,
  LEAVE_REQUEST_LIST_FAIL,
  LEAVE_REQUEST_LIST_RESET,
  LEAVE_REQUEST_CREATE_REQUEST,
  LEAVE_REQUEST_CREATE_SUCCESS,
  LEAVE_REQUEST_CREATE_FAIL,
  LEAVE_REQUEST_CREATE_RESET,
  LEAVE_REQUEST_DETAILS_REQUEST,
  LEAVE_REQUEST_DETAILS_SUCCESS,
  LEAVE_REQUEST_DETAILS_FAIL,
  LEAVE_REQUEST_DETAILS_RESET,
  LEAVE_REQUEST_UPDATE_REQUEST,
  LEAVE_REQUEST_UPDATE_SUCCESS,
  LEAVE_REQUEST_UPDATE_FAIL,
  LEAVE_REQUEST_UPDATE_RESET,
  LEAVE_REQUEST_DELETE_REQUEST,
  LEAVE_REQUEST_DELETE_SUCCESS,
  LEAVE_REQUEST_DELETE_FAIL,
  LEAVE_REQUEST_DELETE_RESET,
  LEAVE_REQUEST_CANCEL_REQUEST,
  LEAVE_REQUEST_CANCEL_SUCCESS,
  LEAVE_REQUEST_CANCEL_FAIL,
  LEAVE_REQUEST_CANCEL_RESET,
} from "../constants/leaveRequestConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listLeaveRequestsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_REQUEST_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getLeaveRequests(params, token);

      dispatch({
        type: LEAVE_REQUEST_LIST_SUCCESS,

        payload: {
          count: data.count,
          totalMinutes: data.total_minutes.result,
          leave_requests: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: LEAVE_REQUEST_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListLeaveRequestsAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_REQUEST_LIST_RESET });
};

// Create
export const createLeaveRequestAction =
  (leave_request) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_REQUEST_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createLeaveRequest(
        token,
        leave_request
      );

      dispatch({
        type: LEAVE_REQUEST_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LEAVE_REQUEST_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateLeaveRequestAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_REQUEST_CREATE_RESET });
};

// List details
export const listLeaveRequestDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_REQUEST_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getLeaveRequest(id, token);

      dispatch({
        type: LEAVE_REQUEST_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LEAVE_REQUEST_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListLeaveRequestDetailsAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_REQUEST_DETAILS_RESET });
};

// Update
export const updateLeaveRequestAction =
  (id, approvalRequest, leave_request) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_REQUEST_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateLeaveRequest(
        id,
        token,
        leave_request
      );

      if (approvalRequest) {
        if (approvalRequest.get("approver")) {
          await cortixDataService.createApprovalRequest(token, approvalRequest);
        }
      }

      dispatch({
        type: LEAVE_REQUEST_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LEAVE_REQUEST_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateLeaveRequestAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_REQUEST_UPDATE_RESET });
};

// Delete
export const deleteLeaveRequestAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAVE_REQUEST_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteLeaveRequest(id, token);

    dispatch({
      type: LEAVE_REQUEST_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LEAVE_REQUEST_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteLeaveRequestAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_REQUEST_DELETE_RESET });
};

// Cancel
export const cancelLeaveRequestAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAVE_REQUEST_CANCEL_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.cancelLeaveRequest(id, token);

    dispatch({
      type: LEAVE_REQUEST_CANCEL_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LEAVE_REQUEST_CANCEL_FAIL,

      payload: error,
    });
  }
};

// Reset cancel
export const resetCancelLeaveRequestAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_REQUEST_CANCEL_RESET });
};
