// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardTwoBarChart from "./DataCardTwoBarChart";

// Main function
function EngagementBookingTimeCard({
  fill,
  engagementBookingList,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardTwoBarChart
      comments={
        <>
          <H5>Total time</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Total time in hours by type of booking.</p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {engagementBookingList.totalMinutesBillableConfirmed +
                  engagementBookingList.totalMinutesNotBillableConfirmed <
                engagementBookingList.totalMinutesBillableTentative +
                  engagementBookingList.totalMinutesNotBillableTentative ? (
                  <>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Most of the planned effort is booked as tentative. Confirm
                      the engagement's bookings as soon as practicable to avoid
                      losing key staff.
                    </div>
                  </>
                ) : engagementBookingList.totalMinutesBillableRealised <
                  engagementBookingList.totalMinutesNotBillableRealised ? (
                  <>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The majority of the effort incurred on this engagement is
                      not billable. You may wish to explore options to monetise
                      a bigger proportion of the realised bookings.
                    </div>
                  </>
                ) : (
                  <>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>No action required.</div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Realised",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: engagementBookingList.totalMinutesBillableRealised / 60,
          value2: engagementBookingList.totalMinutesNotBillableRealised / 60,
        },
        {
          name: "Confirmed",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: engagementBookingList.totalMinutesBillableConfirmed / 60,
          value2: engagementBookingList.totalMinutesNotBillableConfirmed / 60,
        },
        {
          name: "Tentative",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: engagementBookingList.totalMinutesBillableTentative / 60,
          value2: engagementBookingList.totalMinutesNotBillableTentative / 60,
        },
      ]}
      fill={fill}
      legendData={[
        {
          color: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          type: "square",
          value: "Billable",
        },
        {
          color: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          type: "square",
          value: "Not billable",
        },
      ]}
      noData={
        !engagementBookingList.totalMinutesBillableConfirmed &&
        !engagementBookingList.totalMinutesBillableRealised &&
        !engagementBookingList.totalMinutesBillableTentative &&
        !engagementBookingList.totalMinutesNotBillableConfirmed &&
        !engagementBookingList.totalMinutesNotBillableRealised &&
        !engagementBookingList.totalMinutesNotBillableTentative
      }
      title="Total time"
      units="Hours"
    />
  );
}

export default EngagementBookingTimeCard;
