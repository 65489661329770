// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import { Button, Callout, Card, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ClientInfo from "../components/ClientInfo";
import ContactInfo from "../components/ContactInfo";
import DataCardContainer from "../components/DataCardContainer";
import EngagementActualsCard from "../components/EngagementActualsCard";
import EngagementPlanAtCompletionCard from "../components/EngagementPlanAtCompletionCard";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import ProposalInfo from "../components/ProposalInfo";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";

// Redux action imports
// Client
import { listClientsAction } from "../actions/clientActions";

// Contact
import { listContactsAction } from "../actions/contactActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Engagement
import {
  createEngagementAction,
  listEngagementsAction,
  resetCreateEngagementAction,
  resetListEngagementDetailsAction,
} from "../actions/engagementActions";

// Proposal
import { listProposalsAction } from "../actions/proposalActions";

// Main function
function EngagementListScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Client
  const clientList = useSelector((state) => state.clientList);

  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Engagement
  const engagementCreate = useSelector((state) => state.engagementCreate);

  const engagementList = useSelector((state) => state.engagementList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Proposal
  const proposalList = useSelector((state) => state.proposalList);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Engagement
  const [addEngagementDrawerIsOpen, setAddEngagementDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Engagement
  const engagementQueryDefault = {
    client: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    sponsor: "",
    status: "",
    user: "",
  };
  const [engagementQuery, setEngagementQuery] = useState({
    ...engagementQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Proposal
  const proposalQueryDefault = {
    client: "",
    contact: "",
    dueEnd: "",
    dueStart: "",
    exclude: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
    unlinked: "true",
    user: "",
  };
  const [proposalQuery, setProposalQuery] = useState({
    ...proposalQueryDefault,
  });

  // Form variables
  // Engagement
  const newEngagementDefault = {
    client: "",
    clientName: "",
    name: "",
    notes: "",
    proposal: "",
    proposalName: "",
    sponsor: "",
    sponsorName: "",
    sow: "",
    sowName: "",
    value: "",
  };
  const [newEngagement, setNewEngagement] = useState({
    ...newEngagementDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Engagement
    setNewEngagement({ ...newEngagementDefault });

    // Query variables
    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Proposal
    setProposalQuery({
      ...proposalQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
  }, [navigate, userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter engagements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementsAction({
            ...engagementQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter proposals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalsAction({
            ...proposalQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuery]);

  // Form submission
  // Add engagement
  const addEngagement = () => {
    // Prepare data
    const engagement = new FormData();

    const client = new FormData();
    if (newEngagement.client) {
      engagement.append("client", newEngagement.client);
    } else {
      engagement.append("client", newEngagementDefault.client);

      if (newEngagement.clientName) {
        client.append("name", newEngagement.clientName);

        client.append("organisation", activeOrganisation.id);
      }
    }

    newEngagement.name
      ? engagement.append("name", newEngagement.name)
      : engagement.append("name", newEngagementDefault.name);

    newEngagement.notes
      ? engagement.append("notes", newEngagement.notes)
      : engagement.append("notes", newEngagementDefault.notes);

    engagement.append("organisation", activeOrganisation.id);

    newEngagement.proposal
      ? engagement.append("proposal", newEngagement.proposal)
      : engagement.append("proposal", newEngagementDefault.proposal);

    const sponsor = new FormData();
    if (newEngagement.sponsor) {
      engagement.append("sponsor", newEngagement.sponsor);
    } else {
      engagement.append("sponsor", newEngagementDefault.sponsor);

      if (newEngagement.sponsorName) {
        sponsor.append("name", newEngagement.sponsorName);

        sponsor.append("organisation", activeOrganisation.id);
      }
    }

    newEngagement.sow
      ? engagement.append("sow", newEngagement.sow)
      : engagement.append("sow", newEngagementDefault.sow);

    newEngagement.value
      ? engagement.append("value", newEngagement.value)
      : engagement.append("value", newEngagementDefault.value);

    // Dispatch action
    dispatch(createEngagementAction(client, sponsor, engagement));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                margin: !newEngagement.proposal ? "0 0 10px 0" : undefined,
                width: "100%",
              }}
            >
              <H5>Create engagement from proposal</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Proposal */}
                  <FormDrawerSelectField2
                    error={engagementCreate.error}
                    fieldName="proposal"
                    icon="manual"
                    info={
                      <ProposalInfo
                        proposalId={newEngagement.proposal}
                        query={proposalQueryDefault}
                      />
                    }
                    items={proposalList.proposals.filter(
                      (proposal) => proposal.engagement_id === null
                    )}
                    label="Service"
                    loading={proposalList.loading}
                    noOptionOnClick
                    placeholder="Select a proposal"
                    query={proposalQuery.searchString}
                    setQueryObject={setProposalQuery}
                    setQueryVariable="searchString"
                    setValueObject={setNewEngagement}
                    setValueIdVariable="proposal"
                    setValueNameVariable="proposalName"
                    showInfo={newEngagement.proposal}
                    value={newEngagement.proposalName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="primary">
                    You can create an engagement from a proposal. This
                    autocompletes engagement information, including risks,
                    stakeholders, services, activities, deliverables and
                    resources.
                  </Callout>
                </div>
              </div>
            </Card>

            {!newEngagement.proposal && (
              <Card style={{ width: "100%" }}>
                <H5>Identify the engagement</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Engagement name */}
                    <FormDrawerTextField2
                      error={engagementCreate.error}
                      fieldName="name"
                      icon="gantt-chart"
                      label="Engagement name"
                      labelInfo="(required)"
                      placeholder="Give the engagement a name"
                      setValueObject={setNewEngagement}
                      setValueVariable="name"
                      value={newEngagement.name}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Client */}
                    <FormDrawerSelectField2
                      allowCreate
                      error={engagementCreate.error}
                      fieldName="client"
                      helperText="You can create or select an existing client"
                      icon="office"
                      info={
                        <ClientInfo
                          clientId={newEngagement.client}
                          query={clientQueryDefault}
                        />
                      }
                      items={clientList.clients}
                      label="Client"
                      labelInfo="(required)"
                      loading={clientList.loading}
                      placeholder="Create or select a client"
                      query={clientQuery.searchString}
                      setQueryObject={setClientQuery}
                      setQueryVariable="searchString"
                      setValueObject={setNewEngagement}
                      setValueIdVariable="client"
                      setValueNameVariable="clientName"
                      showInfo={newEngagement.client}
                      value={newEngagement.clientName}
                    />
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "50%" }}>
                    {/* Engagement value */}
                    <FormDrawerNumericField2
                      error={engagementCreate.error}
                      fieldName="value"
                      label="Engagement value"
                      labelInfo="(required)"
                      leftElement={
                        <Tag
                          minimal
                          style={{ textAlign: "center", width: "50px" }}
                        >
                          {activeOrganisation.currency}
                        </Tag>
                      }
                      majorStepSize={100000}
                      min={0}
                      minorStepSize={1000}
                      placeholder="Enter the contract value"
                      setValueObject={setNewEngagement}
                      setValueVariable="value"
                      stepSize={10000}
                      value={newEngagement.value}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "50%" }}>
                    {/* Sponsor */}
                    <FormDrawerSelectField2
                      allowCreate
                      error={engagementCreate.error}
                      fieldName="sponsor"
                      helperText="You can create or select an existing contact"
                      icon="id-number"
                      info={
                        <ContactInfo
                          contactId={newEngagement.sponsor}
                          query={contactQueryDefault}
                        />
                      }
                      items={contactList.contacts}
                      label="Sponsor"
                      loading={contactList.loading}
                      noOptionOnClick
                      placeholder="Create or select a contact"
                      query={contactQuery.searchString}
                      setQueryObject={setContactQuery}
                      setQueryVariable="searchString"
                      setValueObject={setNewEngagement}
                      setValueIdVariable="sponsor"
                      setValueNameVariable="sponsorName"
                      showInfo={newEngagement.sponsor}
                      value={newEngagement.sponsorName}
                    />
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Statement of work */}
                    <FormDrawerFileField2
                      error={engagementCreate.error}
                      fileName="sow"
                      label="Statement of work"
                      placeholder="Upload the statement of work"
                      setValueObject={setNewEngagement}
                      setValueFileVariable="sow"
                      setValueNameVariable="sowName"
                      valueFile={newEngagement.sow}
                      valueName={newEngagement.sowName}
                    />
                  </div>

                  <div style={{ marginRight: "10px", width: "40%" }}></div>
                </div>

                {/* Notes */}
                <FormDrawerTextAreaField3
                  error={engagementCreate.error}
                  fieldName="notes"
                  label="Notes"
                  placeholder="Enter your notes on the engagement"
                  setValueObject={setNewEngagement}
                  setValueVariable="notes"
                  value={newEngagement.notes}
                />
              </Card>
            )}
          </div>
        }
        error={engagementCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add engagement"
            module="Engagements"
            view="List"
          />
        }
        helpTitle="Creating an engagement"
        icon="add"
        isOpen={addEngagementDrawerIsOpen}
        onSave={addEngagement}
        refreshList={() => {
          dispatch(
            listEngagementsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateEngagementAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={engagementCreate.success}
        saveSuccessMessage="Engagement created."
        saving={engagementCreate.loading}
        setIsOpen={setAddEngagementDrawerIsOpen}
        title="Add engagement"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddEngagementDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Engagements"
      />

      {/* Error alerts */}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Engagements loading error */}
      {engagementList.error && (
        <ErrorAlert message="We couldn't load the engagement list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Engagements"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Data cards */}
      <div style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}>
        <DataCardContainer
          children={
            <>
              {/* Plan at completion */}
              <EngagementPlanAtCompletionCard
                fill
                engagementList={engagementList}
                showInsights
              />

              {/* Actuals */}
              <EngagementActualsCard
                fill
                engagementList={engagementList}
                showInsights
              />
            </>
          }
          fill
        />
      </div>

      {/* Data table */}
      <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
        <TableContainer2
          body={engagementList.engagements.map((engagement) => {
            return (
              <tr
                key={engagement.id}
                onClick={() => {
                  if (engagement.user_has_permission) {
                    dispatch(resetListEngagementDetailsAction());

                    navigate(`/engagements/${engagement.id}`);
                  } else {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "danger",
                      message: "You do not have access to this engagement.",
                    });
                  }
                }}
              >
                {/* Engagement */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    tagIntent={
                      !engagement.user_has_permission
                        ? "danger"
                        : engagement.user_access_status === "view"
                        ? "primary"
                        : undefined
                    }
                    tagText={
                      !engagement.user_has_permission
                        ? "No access"
                        : engagement.user_access_status === "view"
                        ? "View only"
                        : undefined
                    }
                    text={engagement.name}
                  />
                </td>

                {/* Client */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    image={engagement.client_logo}
                    logo
                    showImage
                    text={engagement.client_name}
                    url={`/clients/${engagement.client}`}
                  />
                </td>

                {/* Value */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableNumber number={engagement.value} />
                </td>

                {/* Sponsor */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    image={engagement.sponsor_profile_photo}
                    showImage
                    text={engagement.sponsor_name}
                    url={
                      engagement.sponsor
                        ? `/clients/${engagement.sponsor}`
                        : undefined
                    }
                  />
                </td>

                {/* Manager */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    email={engagement.manager_name}
                    image={engagement.manager_profile_photo}
                    showImage
                  />
                </td>

                {/* Status */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableTag
                    intent={
                      engagement.status === "Amber"
                        ? "warning"
                        : engagement.status === "Green"
                        ? "success"
                        : engagement.status === "Red"
                        ? "danger"
                        : "primary"
                    }
                    value={engagement.status}
                  />
                </td>
              </tr>
            );
          })}
          clearFiltersDisabled={
            !engagementQuery.client &&
            !engagementQuery.manager &&
            !engagementQuery.searchString &&
            !engagementQuery.sponsor
          }
          count={engagementList.count}
          footnote={<div>Figures in {activeOrganisation.currency}</div>}
          head={
            <tr>
              {/* Engagement */}
              <TableColumnHeadSearch
                queryObject={engagementQuery}
                queryVariable="searchString"
                setQueryObject={setEngagementQuery}
                setQueryVariable="searchString"
                title="Engagement"
                width="200px"
              />

              {/* Client */}
              <TableColumnHeadSelect2
                filterObject={engagementQuery}
                filterVariable="client"
                items={clientList.clients}
                setFilterObject={setEngagementQuery}
                setQueryObject={setClientQuery}
                setQueryVariable="searchString"
                title="Client"
                width="200px"
              />

              {/* Value */}
              <TableColumnHeadSelectSimple
                filterObject={engagementQuery}
                filterVariable="sortField"
                noActive
                options={[
                  {
                    display: "Sort Ascending",
                    icon: "sort-asc",
                    value: "value",
                  },
                  {
                    display: "Sort Descending",
                    icon: "sort-desc",
                    value: "-value",
                  },
                ]}
                setFilterObject={setEngagementQuery}
                title="Value"
                width="125px"
              />

              {/* Sponsor */}
              <TableColumnHeadSelect2
                filterObject={engagementQuery}
                filterVariable="sponsor"
                items={contactList.contacts}
                setFilterObject={setEngagementQuery}
                setQueryObject={setContactQuery}
                setQueryVariable="searchString"
                title="Sponsor"
                width="200px"
              />

              {/* Manager */}
              <TableColumnHeadSelect2
                filterObject={engagementQuery}
                filterVariable="manager"
                items={managerList.managers}
                setFilterObject={setEngagementQuery}
                setQueryObject={setManagerQuery}
                setQueryVariable="searchString"
                title="Manager"
                width="200px"
              />

              {/* Status */}
              <TableColumnHeadSelectSimple
                filterObject={engagementQuery}
                filterVariable="status"
                options={[
                  { display: "All", icon: "filter-remove", value: "" },
                  { display: "Planning", icon: "playbook", value: "Planning" },
                  {
                    display: "Green",
                    icon: "repeat",
                    value: "Green",
                  },
                  { display: "Amber", icon: "issue-new", value: "Amber" },
                  { display: "Red", icon: "issue", value: "Red" },
                  { display: "Closed", icon: "confirm", value: "Closed" },
                ]}
                setFilterObject={setEngagementQuery}
                title="Status"
                width="125px"
              />
            </tr>
          }
          interactive
          loading={engagementList.loading}
          onClearFilters={() => {
            setEngagementQuery({
              ...engagementQueryDefault,
            });
          }}
          page={engagementQuery.page}
          setPageObject={setEngagementQuery}
          setPageVariable="page"
        />
      </div>
    </div>
  );
}

export default EngagementListScreen;
