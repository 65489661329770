// Constant imports
import {
  MARKET_LIST_REQUEST,
  MARKET_LIST_SUCCESS,
  MARKET_LIST_FAIL,
  MARKET_LIST_RESET,
  MARKET_CREATE_REQUEST,
  MARKET_CREATE_SUCCESS,
  MARKET_CREATE_FAIL,
  MARKET_CREATE_RESET,
  MARKET_DETAILS_REQUEST,
  MARKET_DETAILS_SUCCESS,
  MARKET_DETAILS_FAIL,
  MARKET_DETAILS_RESET,
  MARKET_UPDATE_REQUEST,
  MARKET_UPDATE_SUCCESS,
  MARKET_UPDATE_FAIL,
  MARKET_UPDATE_RESET,
  MARKET_DELETE_REQUEST,
  MARKET_DELETE_SUCCESS,
  MARKET_DELETE_FAIL,
  MARKET_DELETE_RESET,
} from "../constants/marketConstants";

// Data services import
import cortixDataMarket from "../services/cortix";

// List all
export const listMarketsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataMarket.getMarkets(params, token);

    dispatch({
      type: MARKET_LIST_SUCCESS,

      payload: {
        averageMarginPercentageOfProposalsCancelled:
          data.average_margin_percentage_of_proposals_cancelled.result,
        averageMarginPercentageOfProposalsLost:
          data.average_margin_percentage_of_proposals_lost.result,
        averageMarginPercentageOfProposalsWon:
          data.average_margin_percentage_of_proposals_won.result,
        totalEngagementMargin: data.total_engagement_margin.result,
        totalEngagementRevenue: data.total_engagement_revenue.result,
        totalValueOfProposalsCancelled:
          data.total_value_of_proposals_cancelled.result,
        totalValueOfProposalsInProgress:
          data.total_value_of_proposals_in_progress.result,
        totalValueOfProposalsLost: data.total_value_of_proposals_lost.result,
        totalValueOfProposalsQualifying:
          data.total_value_of_proposals_qualifying.result,
        totalValueOfProposalsSubmitted:
          data.total_value_of_proposals_submitted.result,
        totalValueOfProposalsWon: data.total_value_of_proposals_won.result,
        count: data.count,
        markets: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: MARKET_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListMarketsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_LIST_RESET });
};

// Create
export const createMarketAction = (market) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataMarket.createMarket(token, market);

    dispatch({
      type: MARKET_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKET_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateMarketAction = () => async (dispatch) => {
  dispatch({ type: MARKET_CREATE_RESET });
};

// List details
export const listMarketDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataMarket.getMarket(id, token);

    dispatch({
      type: MARKET_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKET_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListMarketDetailsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_DETAILS_RESET });
};

// Update
export const updateMarketAction =
  (id, market) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataMarket.updateMarket(id, token, market);

      dispatch({
        type: MARKET_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateMarketAction = () => async (dispatch) => {
  dispatch({ type: MARKET_UPDATE_RESET });
};

// Delete
export const deleteMarketAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARKET_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataMarket.deleteMarket(id, token);

    dispatch({
      type: MARKET_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MARKET_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteMarketAction = () => async (dispatch) => {
  dispatch({ type: MARKET_DELETE_RESET });
};
