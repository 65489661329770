// Library imports
import { Card } from "@blueprintjs/core";
import React from "react";

// Component imports
import NameValue from "../components/NameValue";
import TabSectionField from "../components/TabSectionField";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function StaffLeaveRequestFacts({ facts }) {
  // JSX UI code
  return (
    <Card>
      <TabSectionField
        label="Leave request id"
        value={facts.leave_request_id}
        vertical
      />

      <TabSectionField
        label="Week"
        value={facts.leave_request_week_label}
        vertical
      />

      <TabSectionField
        label="Staff"
        value={
          <NameValue
            email={facts.staff_name}
            image={facts.staff_profile_photo}
          />
        }
        vertical
      />

      <TabSectionField label="Type" value={facts.category} vertical />

      <TabSectionField label="Comments" value={facts.comments} vertical />

      <TabSectionField
        label="Monday"
        value={
          facts.minutes_mon
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_mon).split(":")[0]
              }h ${
                convertMinutesToHoursAndMinutes(facts.minutes_mon).split(":")[1]
              }`
            : "-"
        }
        vertical
      />

      <TabSectionField
        label="Tuesday"
        value={
          facts.minutes_tue
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_tue).split(":")[0]
              } h ${
                convertMinutesToHoursAndMinutes(facts.minutes_tue).split(":")[1]
              }`
            : "-"
        }
        vertical
      />

      <TabSectionField
        label="Wednesday"
        value={
          facts.minutes_wed
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_wed).split(":")[0]
              } h ${
                convertMinutesToHoursAndMinutes(facts.minutes_wed).split(":")[1]
              }`
            : "-"
        }
        vertical
      />

      <TabSectionField
        label="Thursday"
        value={
          facts.minutes_thu
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_thu).split(":")[0]
              } h ${
                convertMinutesToHoursAndMinutes(facts.minutes_thu).split(":")[1]
              }`
            : "-"
        }
        vertical
      />

      <TabSectionField
        label="Friday"
        value={
          facts.minutes_fri
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_fri).split(":")[0]
              } h ${
                convertMinutesToHoursAndMinutes(facts.minutes_fri).split(":")[1]
              }`
            : "-"
        }
        vertical
      />

      <TabSectionField
        label="Saturday"
        value={
          facts.minutes_sat
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_sat).split(":")[0]
              } h ${
                convertMinutesToHoursAndMinutes(facts.minutes_sat).split(":")[1]
              }`
            : "-"
        }
        vertical
      />

      <TabSectionField
        bottom
        label="Sunday"
        value={
          facts.minutes_sun
            ? `${
                convertMinutesToHoursAndMinutes(facts.minutes_sun).split(":")[0]
              } h ${
                convertMinutesToHoursAndMinutes(facts.minutes_sun).split(":")[1]
              }`
            : "-"
        }
        vertical
      />
    </Card>
  );
}

export default StaffLeaveRequestFacts;
