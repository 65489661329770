export const CLAIM_LIST_REQUEST = "CLAIM_LIST_REQUEST";
export const CLAIM_LIST_SUCCESS = "CLAIM_LIST_SUCCESS";
export const CLAIM_LIST_FAIL = "CLAIM_LIST_FAIL";
export const CLAIM_LIST_RESET = "CLAIM_LIST_RESET";

export const CLAIM_CREATE_REQUEST = "CLAIM_CREATE_REQUEST";
export const CLAIM_CREATE_SUCCESS = "CLAIM_CREATE_SUCCESS";
export const CLAIM_CREATE_FAIL = "CLAIM_CREATE_FAIL";
export const CLAIM_CREATE_RESET = "CLAIM_CREATE_RESET";

export const CLAIM_DETAILS_REQUEST = "CLAIM_DETAILS_REQUEST";
export const CLAIM_DETAILS_SUCCESS = "CLAIM_DETAILS_SUCCESS";
export const CLAIM_DETAILS_FAIL = "CLAIM_DETAILS_FAIL";
export const CLAIM_DETAILS_RESET = "CLAIM_DETAILS_RESET";

export const CLAIM_UPDATE_REQUEST = "CLAIM_UPDATE_REQUEST";
export const CLAIM_UPDATE_SUCCESS = "CLAIM_UPDATE_SUCCESS";
export const CLAIM_UPDATE_FAIL = "CLAIM_UPDATE_FAIL";
export const CLAIM_UPDATE_RESET = "CLAIM_UPDATE_RESET";

export const CLAIM_DELETE_REQUEST = "CLAIM_DELETE_REQUEST";
export const CLAIM_DELETE_SUCCESS = "CLAIM_DELETE_SUCCESS";
export const CLAIM_DELETE_FAIL = "CLAIM_DELETE_FAIL";
export const CLAIM_DELETE_RESET = "CLAIM_DELETE_RESET";

export const CLAIM_DUPLICATE_REQUEST = "CLAIM_DUPLICATE_REQUEST";
export const CLAIM_DUPLICATE_SUCCESS = "CLAIM_DUPLICATE_SUCCESS";
export const CLAIM_DUPLICATE_FAIL = "CLAIM_DUPLICATE_FAIL";
export const CLAIM_DUPLICATE_RESET = "CLAIM_DUPLICATE_RESET";

export const CLAIM_REVERSE_REQUEST = "CLAIM_REVERSE_REQUEST";
export const CLAIM_REVERSE_SUCCESS = "CLAIM_REVERSE_SUCCESS";
export const CLAIM_REVERSE_FAIL = "CLAIM_REVERSE_FAIL";
export const CLAIM_REVERSE_RESET = "CLAIM_REVERSE_RESET";
