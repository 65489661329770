// Library imports
import { Colors, Divider, H5, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardHorizontalBarChart from "../components/DataCardHorizontalBarChart";

// Main function
function BookingCodeBudgetCard({ fill, bookingCodeList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardHorizontalBarChart
      comments={
        <>
          <H5>Budget position</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Budget position for the selecting booking code(s), including:</p>

          <UL>
            <li>Budget: Amount set as the budget on the code.</li>

            <li>Remaining: Budget less expenses to-date.</li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Budget",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: bookingCodeList.budgetTotal,
        },
        {
          name: "Remaining",
          fill: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          value: bookingCodeList.remainingTotal,
        },
      ]}
      fill={fill}
      labelMargin={25}
      noData={!bookingCodeList.budgetTotal && !bookingCodeList.remainingTotal}
      title="Budget position"
      units={activeOrganisation.currency}
    />
  );
}

export default BookingCodeBudgetCard;
