// Library imports
import { Card, Classes, HTMLTable } from "@blueprintjs/core";
import React from "react";

// Component imports
import NameValue from "../components/NameValue";
import TableText from "../components/TableText";
import TabSectionField from "../components/TabSectionField";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function StaffTimesheetFacts({ facts }) {
  // JSX UI code
  return (
    <Card>
      <TabSectionField
        label="Timesheet id"
        value={facts.timesheet_id}
        vertical
      />

      <TabSectionField
        label="Week"
        value={facts.timesheet_week_label}
        vertical
      />

      <TabSectionField
        label="Staff"
        value={
          <NameValue
            email={facts.staff_name}
            image={facts.staff_profile_photo}
          />
        }
        vertical
      />

      <TabSectionField label="Comments" value={facts.comments} vertical />

      <TabSectionField
        bottom
        label="Entries"
        value={
          facts.entries.length > 0 ? (
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.entries.map((entry, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle" }}>
                          <TableText text={entry.booking_code_name} />

                          <span
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                          >
                            {entry.booking_code_code}
                          </span>
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          <TableText text={entry.task} />
                        </td>

                        <td style={{ verticalAlign: "middle" }}>
                          {
                            convertMinutesToHoursAndMinutes(
                              entry.total_minutes
                            ).split(":")[0]
                          }
                          h{" "}
                          {
                            convertMinutesToHoursAndMinutes(
                              entry.total_minutes
                            ).split(":")[1]
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <thead>
                  <tr>
                    <th>Booking code</th>

                    <th>Task</th>

                    <th>Time</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />
    </Card>
  );
}

export default StaffTimesheetFacts;
