// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_RESET,
  INVOICE_CREATE_REQUEST,
  INVOICE_CREATE_SUCCESS,
  INVOICE_CREATE_FAIL,
  INVOICE_CREATE_RESET,
  INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_FAIL,
  INVOICE_DETAILS_RESET,
  INVOICE_UPDATE_REQUEST,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_UPDATE_FAIL,
  INVOICE_UPDATE_RESET,
  INVOICE_DELETE_REQUEST,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DELETE_FAIL,
  INVOICE_DELETE_RESET,
  INVOICE_DOWNLOAD_REQUEST,
  INVOICE_DOWNLOAD_SUCCESS,
  INVOICE_DOWNLOAD_FAIL,
  INVOICE_DOWNLOAD_RESET,
} from "../constants/invoiceConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInvoicesAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVOICE_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getInvoices(params, token);

    dispatch({
      type: INVOICE_LIST_SUCCESS,

      payload: {
        averageAge: data.average_age.result,
        totalValueDisputed: data.total_value_disputed.result,
        totalValueDraft: data.total_value_draft.result,
        totalValueInvoiced: data.total_value_invoiced.result,
        totalValueOverdue: data.total_value_overdue.result,
        totalValuePaid: data.total_value_paid.result,
        count: data.count,
        invoices: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: INVOICE_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListInvoicesAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_LIST_RESET });
};

// Create
export const createInvoiceAction = (invoice) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVOICE_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createInvoice(token, invoice);

    dispatch({
      type: INVOICE_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateInvoiceAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_CREATE_RESET });
};

// List details
export const listInvoiceDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVOICE_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getInvoice(id, token);

    dispatch({
      type: INVOICE_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListInvoiceDetailsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_DETAILS_RESET });
};

// Update
export const updateInvoiceAction =
  (id, invoice) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInvoice(
        id,
        token,
        invoice
      );

      dispatch({
        type: INVOICE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInvoiceAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_UPDATE_RESET });
};

// Delete
export const deleteInvoiceAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVOICE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteInvoice(id, token);

    dispatch({
      type: INVOICE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteInvoiceAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_DELETE_RESET });
};

// Download
export const downloadInvoicesAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var invoices = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getInvoices(
          { organisation: organisation, page: page },
          token
        );

        invoices = invoices.concat(data.results);
      }

      dispatch({
        type: INVOICE_DOWNLOAD_SUCCESS,

        payload: {
          invoices: invoices,
        },
      });
    } catch (error) {
      dispatch({
        type: INVOICE_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadInvoicesAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_DOWNLOAD_RESET });
};
