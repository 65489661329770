// Library imports
import { Card, Classes, Colors, H1, H3, Icon, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import LinkTag from "../components/LinkTag";

// Main function
function DashboardWelcomeCard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Navigation function
  const navigate = useNavigate();

  // JSX UI code
  return (
    <div
      style={{
        margin: "20px auto 20px auto",
        maxWidth: "1250px",
      }}
    >
      <Card
        style={{
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <div
          style={{
            alignItems: "start",
            display: "flex",
            justifyContent: "start",
            marginRight: "10px",
            padding: "10px",
            width: "25%",
          }}
        >
          <Icon
            color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
            icon="dashboard"
            size={300}
          />
        </div>

        <div style={{ marginLeft: "10px", width: "75%", zIndex: 1 }}>
          <div style={{ margin: "0 auto 0 auto", width: "800px" }}>
            <H1
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
              }}
            >
              Welcome to your dashboard
            </H1>

            <div
              className={Classes.BLOCKQUOTE}
              style={{ margin: "20px 0 30px 0" }}
            >
              The dashboard provides essential information related to your
              day-to-day work. It will fill with information as you start using
              advaise.app.
            </div>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
              }}
            >
              Your personalized dashboard
            </H3>

            <div style={{ margin: "10px 0 10px 0" }}>
              The dashboard provides the following information:
            </div>

            <UL>
              <li>
                <strong>Overview</strong>: Under this tab, you will find key
                performance data, covering your utilization, the performance of
                proposals and engagements under your management, and actions
                assigned to you.
              </li>

              <li>
                <strong>Goals</strong>: This tab shows your goals and personal
                development plan, allowing you to capture your progress toward
                achieving them.
              </li>

              <li>
                <strong>Assignments</strong>: This tab allows you to view
                information related to your work assignments. You can also
                submit timesheets and expense claims, as well as reviewing
                decision requests.
              </li>
            </UL>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              Leveraging the system's workflows
            </H3>

            <div style={{ margin: "10px 0 10px 0" }}>
              Once you have subscribed to advaise.app and assigned yourself a
              seat, you can access advise.app's modules. You may wish to follow
              the application-provided workflows to learn to use the system.
              Open the Action Sidebar by clicking on the{" "}
              <Icon
                disabled
                icon="menu-closed"
                style={{ margin: "0 5px 0 5px" }}
              />{" "}
              button located on the top right corner of the screen. Open the
              Workflows tab and start a new workflow.
            </div>

            <div style={{ margin: "10px 0 20px 0" }}>
              We recommend setting up your organization first. The corresponding
              workflow (the first option on the dropdown), will provide you with
              a step-by-step guide.
            </div>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              Ask advaise.app for help
            </H3>

            <div style={{ margin: "10px 0 10px 0" }}>
              Take advantage of the contextual help provided by the system. On
              many of the elements that you will interact with, you can access
              context-sensitive help by hovering over the{" "}
              <Icon
                disabled
                icon="info-sign"
                style={{ margin: "0 5px 0 5px" }}
              />{" "}
              icon. This will open a popup with detailed instructions to
              complete the task that you are working on.
            </div>

            <div style={{ margin: "10px 0 10px 0" }}>
              You can also ask advaise.app's AI for help. On the Action Sidebar,
              open the Ask me a question tab and type in your question. The
              system can provide general information or step-by-step
              instructions to complete specific tasks. Ask questions in natural
              language, as you would to a person.
            </div>

            <div style={{ margin: "10px 0 20px 0" }}>
              If you need more details, please check the{" "}
              <LinkTag
                onClick={() =>
                  window.open(
                    "https://help.advaise.app",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                label="help docs"
              />
              .
            </div>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              Complete your user profile
            </H3>

            <div style={{ margin: "10px 0 20px 0" }}>
              If you have not done so already, complete your{" "}
              <LinkTag
                label="user profile"
                onClick={() => {
                  navigate("/userProfile");
                }}
              />
              . This includes general information that the system can use across
              modules (for example, contact details). You can also manage
              communication preferences in your profile.
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DashboardWelcomeCard;
