// Library imports
import { Icon } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function IconSquare({
  darkBackground,
  darkColor,
  icon,
  lightBackground,
  lightColor,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <Icon
      color={lightMode ? lightColor : darkColor}
      icon={icon}
      style={{
        backgroundColor: lightMode ? lightBackground : darkBackground,
        borderRadius: "5px",
        padding: "5px",
      }}
    />
  );
}

export default IconSquare;
