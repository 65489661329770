// Constant imports
import {
  ROLE_LIST_REQUEST,
  ROLE_LIST_SUCCESS,
  ROLE_LIST_FAIL,
  ROLE_LIST_RESET,
  ROLE_CREATE_REQUEST,
  ROLE_CREATE_SUCCESS,
  ROLE_CREATE_FAIL,
  ROLE_CREATE_RESET,
  ROLE_DETAILS_REQUEST,
  ROLE_DETAILS_SUCCESS,
  ROLE_DETAILS_FAIL,
  ROLE_DETAILS_RESET,
  ROLE_UPDATE_REQUEST,
  ROLE_UPDATE_SUCCESS,
  ROLE_UPDATE_FAIL,
  ROLE_UPDATE_RESET,
  ROLE_DELETE_REQUEST,
  ROLE_DELETE_SUCCESS,
  ROLE_DELETE_FAIL,
  ROLE_DELETE_RESET,
} from "../constants/roleConstants";

// List all
export const roleListReducer = (
  state = {
    loading: false,
    count: 0,
    roles: [],
    averageMarginPercentageOnProposalsCancelled: 0,
    averageMarginPercentageOnProposalsLost: 0,
    averageMarginPercentageOnProposalsWon: 0,
    totalEffortOnEngagementsResourced: 0,
    totalEffortOnEngagementsNotResourced: 0,
    totalEffortOnProposalsResourced: 0,
    totalEffortOnProposalsNotResourced: 0,
    totalFeesOnProposalsCancelled: 0,
    totalFeesOnProposalsLost: 0,
    totalFeesOnProposalsWon: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ROLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ROLE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        roles: action.payload.roles,
        averageMarginPercentageOnProposalsCancelled:
          action.payload.averageMarginPercentageOnProposalsCancelled,
        averageMarginPercentageOnProposalsLost:
          action.payload.averageMarginPercentageOnProposalsLost,
        averageMarginPercentageOnProposalsWon:
          action.payload.averageMarginPercentageOnProposalsWon,
        totalEffortOnEngagementsResourced:
          action.payload.totalEffortOnEngagementsResourced,
        totalEffortOnEngagementsNotResourced:
          action.payload.totalEffortOnEngagementsNotResourced,
        totalEffortOnProposalsResourced:
          action.payload.totalEffortOnProposalsResourced,
        totalEffortOnProposalsNotResourced:
          action.payload.totalEffortOnProposalsNotResourced,
        totalFeesOnProposalsCancelled:
          action.payload.totalFeesOnProposalsCancelled,
        totalFeesOnProposalsLost: action.payload.totalFeesOnProposalsLost,
        totalFeesOnProposalsWon: action.payload.totalFeesOnProposalsWon,
        success: true,
        error: "",
      };

    case ROLE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        roles: [],
        averageMarginPercentageOnProposalsCancelled: 0,
        averageMarginPercentageOnProposalsLost: 0,
        averageMarginPercentageOnProposalsWon: 0,
        totalEffortOnEngagementsResourced: 0,
        totalEffortOnEngagementsNotResourced: 0,
        totalEffortOnProposalsResourced: 0,
        totalEffortOnProposalsNotResourced: 0,
        totalFeesOnProposalsCancelled: 0,
        totalFeesOnProposalsLost: 0,
        totalFeesOnProposalsWon: 0,

        success: false,
        error: action.payload,
      };

    case ROLE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        roles: [],
        averageMarginPercentageOnProposalsCancelled: 0,
        averageMarginPercentageOnProposalsLost: 0,
        averageMarginPercentageOnProposalsWon: 0,
        totalEffortOnEngagementsResourced: 0,
        totalEffortOnEngagementsNotResourced: 0,
        totalEffortOnProposalsResourced: 0,
        totalEffortOnProposalsNotResourced: 0,
        totalFeesOnProposalsCancelled: 0,
        totalFeesOnProposalsLost: 0,
        totalFeesOnProposalsWon: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const roleCreateReducer = (
  state = { loading: false, role: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_CREATE_REQUEST:
      return {
        loading: true,
        role: state.role,
        success: false,
        error: "",
      };

    case ROLE_CREATE_SUCCESS:
      return {
        loading: false,
        role: action.payload,
        success: true,
        error: "",
      };

    case ROLE_CREATE_FAIL:
      return {
        loading: false,
        role: state.role,
        success: false,
        error: action.payload,
      };

    case ROLE_CREATE_RESET:
      return { loading: false, role: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const roleDetailsReducer = (
  state = { loading: false, role: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_DETAILS_REQUEST:
      return {
        loading: true,
        role: state.role,
        success: false,
        error: "",
      };

    case ROLE_DETAILS_SUCCESS:
      return {
        loading: false,
        role: action.payload,
        success: true,
        error: "",
      };

    case ROLE_DETAILS_FAIL:
      return {
        loading: false,
        role: state.role,
        success: false,
        error: action.payload,
      };

    case ROLE_DETAILS_RESET:
      return { loading: false, role: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const roleUpdateReducer = (
  state = { loading: false, role: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_UPDATE_REQUEST:
      return {
        loading: true,
        role: state.role,
        success: false,
        error: "",
      };

    case ROLE_UPDATE_SUCCESS:
      return {
        loading: false,
        role: action.payload,
        success: true,
        error: "",
      };

    case ROLE_UPDATE_FAIL:
      return {
        loading: false,
        role: state.role,
        success: false,
        error: action.payload,
      };

    case ROLE_UPDATE_RESET:
      return { loading: false, role: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const roleDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ROLE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ROLE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ROLE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ROLE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
