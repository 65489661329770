// Constant imports
import {
  INVOICE_LIST_REQUEST,
  INVOICE_LIST_SUCCESS,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_RESET,
  INVOICE_CREATE_REQUEST,
  INVOICE_CREATE_SUCCESS,
  INVOICE_CREATE_FAIL,
  INVOICE_CREATE_RESET,
  INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_FAIL,
  INVOICE_DETAILS_RESET,
  INVOICE_UPDATE_REQUEST,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_UPDATE_FAIL,
  INVOICE_UPDATE_RESET,
  INVOICE_DELETE_REQUEST,
  INVOICE_DELETE_SUCCESS,
  INVOICE_DELETE_FAIL,
  INVOICE_DELETE_RESET,
  INVOICE_DOWNLOAD_REQUEST,
  INVOICE_DOWNLOAD_SUCCESS,
  INVOICE_DOWNLOAD_FAIL,
  INVOICE_DOWNLOAD_RESET,
} from "../constants/invoiceConstants";

// List all
export const invoiceListReducer = (
  state = {
    loading: false,
    count: 0,
    invoices: [],
    averageAge: 0,
    totalValueDisputed: 0,
    totalValueDraft: 0,
    totalValueInvoiced: 0,
    totalValueOverdue: 0,
    totalValuePaid: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoices: action.payload.invoices,
        averageAge: action.payload.averageAge,
        totalValueDisputed: action.payload.totalValueDisputed,
        totalValueDraft: action.payload.totalValueDraft,
        totalValueInvoiced: action.payload.totalValueInvoiced,
        totalValueOverdue: action.payload.totalValueOverdue,
        totalValuePaid: action.payload.totalValuePaid,
        success: true,
        error: "",
      };

    case INVOICE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoices: [],
        averageAge: 0,
        totalValueDisputed: 0,
        totalValueDraft: 0,
        totalValueInvoiced: 0,
        totalValueOverdue: 0,
        totalValuePaid: 0,
        success: false,
        error: action.payload,
      };

    case INVOICE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoices: [],
        averageAge: 0,
        totalValueDisputed: 0,
        totalValueDraft: 0,
        totalValueInvoiced: 0,
        totalValueOverdue: 0,
        totalValuePaid: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const invoiceCreateReducer = (
  state = { loading: false, invoice: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_CREATE_REQUEST:
      return {
        loading: true,
        invoice: state.invoice,
        success: false,
        error: "",
      };

    case INVOICE_CREATE_SUCCESS:
      return {
        loading: false,
        invoice: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_CREATE_FAIL:
      return {
        loading: false,
        invoice: state.invoice,
        success: false,
        error: action.payload,
      };

    case INVOICE_CREATE_RESET:
      return { loading: false, invoice: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const invoiceDetailsReducer = (
  state = { loading: false, invoice: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_DETAILS_REQUEST:
      return {
        loading: true,
        invoice: state.invoice,
        success: false,
        error: "",
      };

    case INVOICE_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_DETAILS_FAIL:
      return {
        loading: false,
        invoice: state.invoice,
        success: false,
        error: action.payload,
      };

    case INVOICE_DETAILS_RESET:
      return { loading: false, invoice: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const invoiceUpdateReducer = (
  state = { loading: false, invoice: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_UPDATE_REQUEST:
      return {
        loading: true,
        invoice: state.invoice,
        success: false,
        error: "",
      };

    case INVOICE_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_UPDATE_FAIL:
      return {
        loading: false,
        invoice: state.invoice,
        success: false,
        error: action.payload,
      };

    case INVOICE_UPDATE_RESET:
      return { loading: false, invoice: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const invoiceDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INVOICE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INVOICE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INVOICE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const invoiceDownloadReducer = (
  state = {
    loading: false,
    invoices: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        invoices: action.payload.invoices,
        success: true,
        error: "",
      };

    case INVOICE_DOWNLOAD_FAIL:
      return {
        loading: false,
        invoices: [],
        success: false,
        error: action.payload,
      };

    case INVOICE_DOWNLOAD_RESET:
      return {
        loading: false,
        invoices: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
