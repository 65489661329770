// Library imports
import { Button, Colors, FileInput, FormGroup } from "@blueprintjs/core";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";

// Main function
function FormDrawerFileField2({
  disabled,
  error,
  errorMessage,
  fileName,
  helperText,
  label,
  labelInfo,
  onSave,
  placeholder,
  postSave,
  saveSuccess,
  setValueObject,
  setValueFileVariable,
  setValueNameVariable,
  showDownload,
  valueFile,
  valueName,
}) {
  // Field active variable
  const [thisFieldActive, setThisFieldActive] = useState(false);

  // Execute post-save action on save success
  useEffect(() => {
    if (thisFieldActive && saveSuccess) {
      postSave && postSave();

      setThisFieldActive(false);
    }
    // eslint-disable-next-line
  }, [saveSuccess]);

  // JSX UI code
  return (
    <FormGroup
      helperText={valueName ? undefined : helperText}
      label={label}
      labelFor={fileName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fileName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage
                ? errorMessage
                : String(error.response.data[fileName]) ===
                  "The submitted data was not a file. Check the encoding type on the form."
                ? "A valid file is required."
                : error.response.data[fileName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <FileInput
        disabled={disabled}
        fill
        hasSelection={valueName ? true : false}
        id={fileName}
        onInputChange={(e) => {
          setValueObject((prevState) => {
            return {
              ...prevState,
              [setValueFileVariable]: e.target.files[0],
              [setValueNameVariable]: e.target.files[0]
                ? e.target.files[0].name
                : "",
            };
          });

          if (onSave) {
            setThisFieldActive(true);

            onSave(e.target.files[0]);
          }
        }}
        text={valueName ? valueName : placeholder}
      />

      {valueName ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            intent="warning"
            minimal
            onClick={() => {
              setValueObject((prevState) => {
                return {
                  ...prevState,
                  [setValueFileVariable]: "",
                  [setValueNameVariable]: "",
                };
              });
            }}
            small
            style={{ margin: "3px 0 0 0" }}
            text="Remove file"
          />

          {showDownload && valueFile && (
            <Button
              intent="primary"
              minimal
              onClick={() => {
                saveAs(valueFile, valueName);
              }}
              small
              style={{ margin: "3px 0 0 0" }}
              text="Download"
            />
          )}
        </div>
      ) : undefined}
    </FormGroup>
  );
}

export default FormDrawerFileField2;
