// Library imports
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import Dashboard from "../components/Dashboard";
import SignupScreen from "../screens/SignupScreen";

// Main function
function HomeScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);
  const { token } = userLogin;

  // JSX UI code
  return <>{token ? <Dashboard /> : <SignupScreen />}</>;
}

export default HomeScreen;
