// Constant imports
import {
  WEEK_LIST_REQUEST,
  WEEK_LIST_SUCCESS,
  WEEK_LIST_FAIL,
  WEEK_LIST_RESET,
} from "../constants/weekConstants";

// List all
export const weekListReducer = (
  state = {
    loading: false,
    weeks: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case WEEK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case WEEK_LIST_SUCCESS:
      return {
        loading: false,
        weeks: action.payload,
        success: true,
        error: "",
      };

    case WEEK_LIST_FAIL:
      return {
        loading: false,
        weeks: [],
        success: false,
        error: action.payload,
      };

    case WEEK_LIST_RESET:
      return {
        loading: false,
        weeks: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
