// Library imports
import { Colors, FormGroup, InputGroup } from "@blueprintjs/core";
import React from "react";

// Main function
function FormDrawerTextField2({
  disabled,
  error,
  errorMessage,
  fieldName,
  helperText,
  icon,
  label,
  labelInfo,
  large,
  password,
  placeholder,
  rightElement,
  setValueObject,
  setValueVariable,
  value,
}) {
  // JSX UI code
  return (
    <FormGroup
      helperText={helperText}
      label={label}
      labelFor={fieldName}
      labelInfo={labelInfo}
      subLabel={
        error ? (
          error.response.data[fieldName] ? (
            <span style={{ color: Colors.RED3 }}>
              {errorMessage ? errorMessage : error.response.data[fieldName]}
            </span>
          ) : undefined
        ) : undefined
      }
    >
      <InputGroup
        disabled={disabled}
        fill
        id={fieldName}
        large={large}
        leftIcon={icon}
        intent={
          error
            ? error.response.data[fieldName]
              ? "danger"
              : undefined
            : undefined
        }
        onChange={(e) => {
          setValueObject((prevState) => {
            return {
              ...prevState,
              [setValueVariable]: e.target.value,
            };
          });
        }}
        placeholder={placeholder}
        rightElement={rightElement}
        type={password ? "password" : "text"}
        value={value}
      />
    </FormGroup>
  );
}

export default FormDrawerTextField2;
