// Constant imports
import {
  ENGAGEMENT_LIST_REQUEST,
  ENGAGEMENT_LIST_SUCCESS,
  ENGAGEMENT_LIST_FAIL,
  ENGAGEMENT_LIST_RESET,
  ENGAGEMENT_CREATE_REQUEST,
  ENGAGEMENT_CREATE_SUCCESS,
  ENGAGEMENT_CREATE_FAIL,
  ENGAGEMENT_CREATE_RESET,
  ENGAGEMENT_DETAILS_REQUEST,
  ENGAGEMENT_DETAILS_SUCCESS,
  ENGAGEMENT_DETAILS_FAIL,
  ENGAGEMENT_DETAILS_RESET,
  ENGAGEMENT_UPDATE_REQUEST,
  ENGAGEMENT_UPDATE_SUCCESS,
  ENGAGEMENT_UPDATE_FAIL,
  ENGAGEMENT_UPDATE_RESET,
  ENGAGEMENT_DELETE_REQUEST,
  ENGAGEMENT_DELETE_SUCCESS,
  ENGAGEMENT_DELETE_FAIL,
  ENGAGEMENT_DELETE_RESET,
  ENGAGEMENT_GENERATE_REPORT_DATA_REQUEST,
  ENGAGEMENT_GENERATE_REPORT_DATA_SUCCESS,
  ENGAGEMENT_GENERATE_REPORT_DATA_FAIL,
  ENGAGEMENT_GENERATE_REPORT_DATA_RESET,
  ENGAGEMENT_DOWNLOAD_REQUEST,
  ENGAGEMENT_DOWNLOAD_SUCCESS,
  ENGAGEMENT_DOWNLOAD_FAIL,
  ENGAGEMENT_DOWNLOAD_RESET,
} from "../constants/engagementConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENGAGEMENT_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getEngagements(params, token);

    dispatch({
      type: ENGAGEMENT_LIST_SUCCESS,

      payload: {
        totalExpensesActual: data.total_expenses_actual.result,
        totalExpensesBaseline: data.total_expenses_baseline.result,
        totalExpensesCurrent: data.total_expenses_current.result,
        totalFeeBaseline: data.total_fee_baseline.result,
        totalFeeCurrent: data.total_fee_current.result,
        totalInvoiced: data.total_invoiced.result,
        totalMarginActual: data.total_margin_actual.result,
        totalMarginBaseline: data.total_margin_baseline.result,
        totalMarginCurrent: data.total_margin_current.result,
        totalRevenue: data.total_revenue.result,
        count: data.count,
        engagements: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: ENGAGEMENT_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListEngagementsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_LIST_RESET });
};

// Create
export const createEngagementAction =
  (client, sponsor, engagement) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          engagement.append("client", data.id);
        }
      }

      if (sponsor) {
        if (sponsor.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            sponsor
          );

          engagement.append("sponsor", data.id);
        }
      }

      const { data } = await cortixDataService.createEngagement(
        token,
        engagement
      );

      dispatch({
        type: ENGAGEMENT_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_CREATE_RESET });
};

// List details
export const listEngagementDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagement(id, token);

      dispatch({
        type: ENGAGEMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementDetailsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DETAILS_RESET });
};

// Update
export const updateEngagementAction =
  (id, client, sponsor, engagement) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (client) {
        if (client.get("name")) {
          const { data } = await cortixDataService.createClient(token, client);

          engagement.append("client", data.id);
        }
      }

      if (sponsor) {
        if (sponsor.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            sponsor
          );
          engagement.append("sponsor", data.id);
        }
      }

      const { data } = await cortixDataService.updateEngagement(
        id,
        token,
        engagement
      );

      dispatch({
        type: ENGAGEMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_UPDATE_RESET });
};

// Delete
export const deleteEngagementAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENGAGEMENT_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteEngagement(id, token);

    dispatch({
      type: ENGAGEMENT_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ENGAGEMENT_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteEngagementAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DELETE_RESET });
};

// Generate report data
export const generateReportDataEngagementAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_GENERATE_REPORT_DATA_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.generateReportDataEngagement(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_GENERATE_REPORT_DATA_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_GENERATE_REPORT_DATA_FAIL,

        payload: error,
      });
    }
  };

// Reset generate report data
export const resetGenerateReportDataEngagementAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_GENERATE_REPORT_DATA_RESET });
  };

// Download
export const downloadEngagementAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ENGAGEMENT_DOWNLOAD_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.downloadEngagement(id, token);

    dispatch({
      type: ENGAGEMENT_DOWNLOAD_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ENGAGEMENT_DOWNLOAD_FAIL,

      payload: error,
    });
  }
};

// Reset download
export const resetDownloadEngagementAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_DOWNLOAD_RESET });
};
