// Constant imports
import {
  OPENING_CANDIDATE_LIST_REQUEST,
  OPENING_CANDIDATE_LIST_SUCCESS,
  OPENING_CANDIDATE_LIST_FAIL,
  OPENING_CANDIDATE_LIST_RESET,
  OPENING_CANDIDATE_DETAILS_REQUEST,
  OPENING_CANDIDATE_DETAILS_SUCCESS,
  OPENING_CANDIDATE_DETAILS_FAIL,
  OPENING_CANDIDATE_DETAILS_RESET,
  OPENING_CANDIDATE_UPDATE_REQUEST,
  OPENING_CANDIDATE_UPDATE_SUCCESS,
  OPENING_CANDIDATE_UPDATE_FAIL,
  OPENING_CANDIDATE_UPDATE_RESET,
} from "../constants/openingCandidateConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listOpeningCandidatesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_CANDIDATE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningCandidates(
        params,
        token
      );

      dispatch({
        type: OPENING_CANDIDATE_LIST_SUCCESS,

        payload: {
          averageExperience: data.average_experience.result,
          averageNotice: data.average_notice.result,
          averageRemuneration: data.average_remuneration.result,
          count: data.count,
          opening_candidates: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: OPENING_CANDIDATE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListOpeningCandidatesAction = () => async (dispatch) => {
  dispatch({ type: OPENING_CANDIDATE_LIST_RESET });
};

// List details
export const listOpeningCandidateDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_CANDIDATE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningCandidate(id, token);

      dispatch({
        type: OPENING_CANDIDATE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_CANDIDATE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListOpeningCandidateDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_CANDIDATE_DETAILS_RESET });
  };

// Update
export const updateOpeningCandidateAction =
  (id, opening_candidate) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_CANDIDATE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOpeningCandidate(
        id,
        token,
        opening_candidate
      );

      dispatch({
        type: OPENING_CANDIDATE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_CANDIDATE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningCandidateAction = () => async (dispatch) => {
  dispatch({ type: OPENING_CANDIDATE_UPDATE_RESET });
};
