// Constant imports
import {
  SEAT_LIST_REQUEST,
  SEAT_LIST_SUCCESS,
  SEAT_LIST_FAIL,
  SEAT_LIST_RESET,
  SEAT_DETAILS_REQUEST,
  SEAT_DETAILS_SUCCESS,
  SEAT_DETAILS_FAIL,
  SEAT_DETAILS_RESET,
  SEAT_UPDATE_REQUEST,
  SEAT_UPDATE_SUCCESS,
  SEAT_UPDATE_FAIL,
  SEAT_UPDATE_RESET,
} from "../constants/seatConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listSeatsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SEAT_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getSeats(params, token);

    dispatch({
      type: SEAT_LIST_SUCCESS,

      payload: {
        count: data.count,
        seats: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: SEAT_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListSeatsAction = () => async (dispatch) => {
  dispatch({ type: SEAT_LIST_RESET });
};

// List details
export const listSeatDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SEAT_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getSeat(id, token);

    dispatch({
      type: SEAT_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEAT_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListSeatDetailsAction = () => async (dispatch) => {
  dispatch({ type: SEAT_DETAILS_RESET });
};

// Update
export const updateSeatAction = (id, seat) => async (dispatch, getState) => {
  try {
    dispatch({ type: SEAT_UPDATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.updateSeat(id, token, seat);

    dispatch({
      type: SEAT_UPDATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEAT_UPDATE_FAIL,

      payload: error,
    });
  }
};

// Reset update
export const resetUpdateSeatAction = () => async (dispatch) => {
  dispatch({ type: SEAT_UPDATE_RESET });
};
