// Constant imports
import {
  APPROVAL_ROLE_LIST_REQUEST,
  APPROVAL_ROLE_LIST_SUCCESS,
  APPROVAL_ROLE_LIST_FAIL,
  APPROVAL_ROLE_LIST_RESET,
  APPROVAL_ROLE_CREATE_REQUEST,
  APPROVAL_ROLE_CREATE_SUCCESS,
  APPROVAL_ROLE_CREATE_FAIL,
  APPROVAL_ROLE_CREATE_RESET,
  APPROVAL_ROLE_DETAILS_REQUEST,
  APPROVAL_ROLE_DETAILS_SUCCESS,
  APPROVAL_ROLE_DETAILS_FAIL,
  APPROVAL_ROLE_DETAILS_RESET,
  APPROVAL_ROLE_UPDATE_REQUEST,
  APPROVAL_ROLE_UPDATE_SUCCESS,
  APPROVAL_ROLE_UPDATE_FAIL,
  APPROVAL_ROLE_UPDATE_RESET,
  APPROVAL_ROLE_DELETE_REQUEST,
  APPROVAL_ROLE_DELETE_SUCCESS,
  APPROVAL_ROLE_DELETE_FAIL,
  APPROVAL_ROLE_DELETE_RESET,
} from "../constants/approvalRoleConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listApprovalRolesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_ROLE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getApprovalRoles(params, token);

      dispatch({
        type: APPROVAL_ROLE_LIST_SUCCESS,

        payload: {
          count: data.count,
          approval_roles: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_ROLE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListApprovalRolesAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_ROLE_LIST_RESET });
};

// Create
export const createApprovalRoleAction =
  (approvalRole) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_ROLE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createApprovalRole(
        token,
        approvalRole
      );

      dispatch({
        type: APPROVAL_ROLE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_ROLE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateApprovalRoleAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_ROLE_CREATE_RESET });
};

// List details
export const listApprovalRoleDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_ROLE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getApprovalRole(id, token);

      dispatch({
        type: APPROVAL_ROLE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_ROLE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListApprovalRoleDetailsAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_ROLE_DETAILS_RESET });
};

// Update
export const updateApprovalRoleAction =
  (id, approvalRole) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_ROLE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateApprovalRole(
        id,
        token,
        approvalRole
      );

      dispatch({
        type: APPROVAL_ROLE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_ROLE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateApprovalRoleAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_ROLE_UPDATE_RESET });
};

// Delete
export const deleteApprovalRoleAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: APPROVAL_ROLE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteApprovalRole(id, token);

    dispatch({
      type: APPROVAL_ROLE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVAL_ROLE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteApprovalRoleAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_ROLE_DELETE_RESET });
};
