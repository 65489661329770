// Constant imports
import {
  RANK_COST_RATE_LIST_REQUEST,
  RANK_COST_RATE_LIST_SUCCESS,
  RANK_COST_RATE_LIST_FAIL,
  RANK_COST_RATE_LIST_RESET,
  RANK_COST_RATE_CREATE_REQUEST,
  RANK_COST_RATE_CREATE_SUCCESS,
  RANK_COST_RATE_CREATE_FAIL,
  RANK_COST_RATE_CREATE_RESET,
  RANK_COST_RATE_DETAILS_REQUEST,
  RANK_COST_RATE_DETAILS_SUCCESS,
  RANK_COST_RATE_DETAILS_FAIL,
  RANK_COST_RATE_DETAILS_RESET,
  RANK_COST_RATE_UPDATE_REQUEST,
  RANK_COST_RATE_UPDATE_SUCCESS,
  RANK_COST_RATE_UPDATE_FAIL,
  RANK_COST_RATE_UPDATE_RESET,
  RANK_COST_RATE_DELETE_REQUEST,
  RANK_COST_RATE_DELETE_SUCCESS,
  RANK_COST_RATE_DELETE_FAIL,
  RANK_COST_RATE_DELETE_RESET,
} from "../constants/rankCostRateConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listRankCostRatesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: RANK_COST_RATE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getRankCostRates(params, token);

      dispatch({
        type: RANK_COST_RATE_LIST_SUCCESS,

        payload: {
          count: data.count,
          rank_cost_rates: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: RANK_COST_RATE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListRankCostRatesAction = () => async (dispatch) => {
  dispatch({ type: RANK_COST_RATE_LIST_RESET });
};

// Create
export const createRankCostRateAction =
  (rank_cost_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: RANK_COST_RATE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createRankCostRate(
        token,
        rank_cost_rate
      );

      dispatch({
        type: RANK_COST_RATE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RANK_COST_RATE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateRankCostRateAction = () => async (dispatch) => {
  dispatch({ type: RANK_COST_RATE_CREATE_RESET });
};

// List details
export const listRankCostRateDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: RANK_COST_RATE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getRankCostRate(id, token);

      dispatch({
        type: RANK_COST_RATE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RANK_COST_RATE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListRankCostRateDetailsAction = () => async (dispatch) => {
  dispatch({ type: RANK_COST_RATE_DETAILS_RESET });
};

// Update
export const updateRankCostRateAction =
  (id, rank_cost_rate) => async (dispatch, getState) => {
    try {
      dispatch({ type: RANK_COST_RATE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateRankCostRate(
        id,
        token,
        rank_cost_rate
      );

      dispatch({
        type: RANK_COST_RATE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RANK_COST_RATE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateRankCostRateAction = () => async (dispatch) => {
  dispatch({ type: RANK_COST_RATE_UPDATE_RESET });
};

// Delete
export const deleteRankCostRateAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RANK_COST_RATE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteRankCostRate(id, token);

    dispatch({
      type: RANK_COST_RATE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RANK_COST_RATE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteRankCostRateAction = () => async (dispatch) => {
  dispatch({ type: RANK_COST_RATE_DELETE_RESET });
};
