// Constant imports
import {
  PROPOSAL_DOCUMENT_DETAILS_REQUEST,
  PROPOSAL_DOCUMENT_DETAILS_SUCCESS,
  PROPOSAL_DOCUMENT_DETAILS_FAIL,
  PROPOSAL_DOCUMENT_DETAILS_RESET,
  PROPOSAL_DOCUMENT_UPDATE_REQUEST,
  PROPOSAL_DOCUMENT_UPDATE_SUCCESS,
  PROPOSAL_DOCUMENT_UPDATE_FAIL,
  PROPOSAL_DOCUMENT_UPDATE_RESET,
} from "../constants/proposalDocumentConstants";

// List details
export const proposalDocumentDetailsReducer = (
  state = { loading: false, proposal_document: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_DOCUMENT_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_document: state.proposal_document,
        success: false,
        error: "",
      };

    case PROPOSAL_DOCUMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_document: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_DOCUMENT_DETAILS_FAIL:
      return {
        loading: false,
        proposal_document: state.proposal_document,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_DOCUMENT_DETAILS_RESET:
      return {
        loading: false,
        proposal_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalDocumentUpdateReducer = (
  state = { loading: false, proposal_document: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_DOCUMENT_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_document: state.proposal_document,
        success: false,
        error: "",
      };

    case PROPOSAL_DOCUMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_document: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_DOCUMENT_UPDATE_FAIL:
      return {
        loading: false,
        proposal_document: state.proposal_document,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_DOCUMENT_UPDATE_RESET:
      return {
        loading: false,
        proposal_document: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
