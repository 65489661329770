// Library imports
import { Callout, Switch } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

// Main function
function FormDrawerStaffLocationSearch({ location, setStaffQueryObject }) {
  // Search staff
  const [filterStaff, setFilterStaff] = useState(false);

  useEffect(() => {
    if (filterStaff && location) {
      setStaffQueryObject((prevState) => {
        return {
          ...prevState,
          location: location,
        };
      });
    } else {
      setStaffQueryObject((prevState) => {
        return {
          ...prevState,
          location: "",
        };
      });
    }
    // eslint-disable-next-line
  }, [filterStaff, location]);

  // JSX UI code
  return (
    <div>
      <Callout style={{ marginBottom: "15px", paddingBottom: "5px" }}>
        <Switch
          checked={filterStaff}
          label="Filter staff based on this location"
          onChange={() => setFilterStaff(!filterStaff)}
        />
      </Callout>
    </div>
  );
}

export default FormDrawerStaffLocationSearch;
