// Constant imports
import {
  PROPOSAL_RISK_LIST_REQUEST,
  PROPOSAL_RISK_LIST_SUCCESS,
  PROPOSAL_RISK_LIST_FAIL,
  PROPOSAL_RISK_LIST_RESET,
  PROPOSAL_RISK_CREATE_REQUEST,
  PROPOSAL_RISK_CREATE_SUCCESS,
  PROPOSAL_RISK_CREATE_FAIL,
  PROPOSAL_RISK_CREATE_RESET,
  PROPOSAL_RISK_DETAILS_REQUEST,
  PROPOSAL_RISK_DETAILS_SUCCESS,
  PROPOSAL_RISK_DETAILS_FAIL,
  PROPOSAL_RISK_DETAILS_RESET,
  PROPOSAL_RISK_UPDATE_REQUEST,
  PROPOSAL_RISK_UPDATE_SUCCESS,
  PROPOSAL_RISK_UPDATE_FAIL,
  PROPOSAL_RISK_UPDATE_RESET,
  PROPOSAL_RISK_DELETE_REQUEST,
  PROPOSAL_RISK_DELETE_SUCCESS,
  PROPOSAL_RISK_DELETE_FAIL,
  PROPOSAL_RISK_DELETE_RESET,
} from "../constants/proposalRiskConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalRisksAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RISK_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalRisks(params, token);

      dispatch({
        type: PROPOSAL_RISK_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_risks: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RISK_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalRisksAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RISK_LIST_RESET });
};

// Create
export const createProposalRiskAction =
  (proposal_risk) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RISK_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalRisk(
        token,
        proposal_risk
      );

      dispatch({
        type: PROPOSAL_RISK_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RISK_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalRiskAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RISK_CREATE_RESET });
};

// List details
export const listProposalRiskDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RISK_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalRisk(id, token);

      dispatch({
        type: PROPOSAL_RISK_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RISK_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalRiskDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RISK_DETAILS_RESET });
};

// Update
export const updateProposalRiskAction =
  (id, proposal_risk) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_RISK_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalRisk(
        id,
        token,
        proposal_risk
      );

      dispatch({
        type: PROPOSAL_RISK_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_RISK_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalRiskAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RISK_UPDATE_RESET });
};

// Delete
export const deleteProposalRiskAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROPOSAL_RISK_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteProposalRisk(id, token);

    dispatch({
      type: PROPOSAL_RISK_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROPOSAL_RISK_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteProposalRiskAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_RISK_DELETE_RESET });
};
