export const ENGAGEMENT_REPORT_LIST_REQUEST = "ENGAGEMENT_REPORT_LIST_REQUEST";
export const ENGAGEMENT_REPORT_LIST_SUCCESS = "ENGAGEMENT_REPORT_LIST_SUCCESS";
export const ENGAGEMENT_REPORT_LIST_FAIL = "ENGAGEMENT_REPORT_LIST_FAIL";
export const ENGAGEMENT_REPORT_LIST_RESET = "ENGAGEMENT_REPORT_LIST_RESET";

export const ENGAGEMENT_REPORT_CREATE_REQUEST =
  "ENGAGEMENT_REPORT_CREATE_REQUEST";
export const ENGAGEMENT_REPORT_CREATE_SUCCESS =
  "ENGAGEMENT_REPORT_CREATE_SUCCESS";
export const ENGAGEMENT_REPORT_CREATE_FAIL = "ENGAGEMENT_REPORT_CREATE_FAIL";
export const ENGAGEMENT_REPORT_CREATE_RESET = "ENGAGEMENT_REPORT_CREATE_RESET";

export const ENGAGEMENT_REPORT_DETAILS_REQUEST =
  "ENGAGEMENT_REPORT_DETAILS_REQUEST";
export const ENGAGEMENT_REPORT_DETAILS_SUCCESS =
  "ENGAGEMENT_REPORT_DETAILS_SUCCESS";
export const ENGAGEMENT_REPORT_DETAILS_FAIL = "ENGAGEMENT_REPORT_DETAILS_FAIL";
export const ENGAGEMENT_REPORT_DETAILS_RESET =
  "ENGAGEMENT_REPORT_DETAILS_RESET";

export const ENGAGEMENT_REPORT_UPDATE_REQUEST =
  "ENGAGEMENT_REPORT_UPDATE_REQUEST";
export const ENGAGEMENT_REPORT_UPDATE_SUCCESS =
  "ENGAGEMENT_REPORT_UPDATE_SUCCESS";
export const ENGAGEMENT_REPORT_UPDATE_FAIL = "ENGAGEMENT_REPORT_UPDATE_FAIL";
export const ENGAGEMENT_REPORT_UPDATE_RESET = "ENGAGEMENT_REPORT_UPDATE_RESET";

export const ENGAGEMENT_REPORT_DELETE_REQUEST =
  "ENGAGEMENT_REPORT_DELETE_REQUEST";
export const ENGAGEMENT_REPORT_DELETE_SUCCESS =
  "ENGAGEMENT_REPORT_DELETE_SUCCESS";
export const ENGAGEMENT_REPORT_DELETE_FAIL = "ENGAGEMENT_REPORT_DELETE_FAIL";
export const ENGAGEMENT_REPORT_DELETE_RESET = "ENGAGEMENT_REPORT_DELETE_RESET";

export const ENGAGEMENT_REPORT_DOWNLOAD_REQUEST =
  "ENGAGEMENT_REPORT_DOWNLOAD_REQUEST";
export const ENGAGEMENT_REPORT_DOWNLOAD_SUCCESS =
  "ENGAGEMENT_REPORT_DOWNLOAD_SUCCESS";
export const ENGAGEMENT_REPORT_DOWNLOAD_FAIL =
  "ENGAGEMENT_REPORT_DOWNLOAD_FAIL";
export const ENGAGEMENT_REPORT_DOWNLOAD_RESET =
  "ENGAGEMENT_REPORT_DOWNLOAD_RESET";
