// Constant imports
import {
  PROPOSAL_LABOUR_EXPENSE_LIST_REQUEST,
  PROPOSAL_LABOUR_EXPENSE_LIST_SUCCESS,
  PROPOSAL_LABOUR_EXPENSE_LIST_FAIL,
  PROPOSAL_LABOUR_EXPENSE_LIST_RESET,
  PROPOSAL_LABOUR_EXPENSE_CREATE_REQUEST,
  PROPOSAL_LABOUR_EXPENSE_CREATE_SUCCESS,
  PROPOSAL_LABOUR_EXPENSE_CREATE_FAIL,
  PROPOSAL_LABOUR_EXPENSE_CREATE_RESET,
  PROPOSAL_LABOUR_EXPENSE_DETAILS_REQUEST,
  PROPOSAL_LABOUR_EXPENSE_DETAILS_SUCCESS,
  PROPOSAL_LABOUR_EXPENSE_DETAILS_FAIL,
  PROPOSAL_LABOUR_EXPENSE_DETAILS_RESET,
  PROPOSAL_LABOUR_EXPENSE_UPDATE_REQUEST,
  PROPOSAL_LABOUR_EXPENSE_UPDATE_SUCCESS,
  PROPOSAL_LABOUR_EXPENSE_UPDATE_FAIL,
  PROPOSAL_LABOUR_EXPENSE_UPDATE_RESET,
  PROPOSAL_LABOUR_EXPENSE_DELETE_REQUEST,
  PROPOSAL_LABOUR_EXPENSE_DELETE_SUCCESS,
  PROPOSAL_LABOUR_EXPENSE_DELETE_FAIL,
  PROPOSAL_LABOUR_EXPENSE_DELETE_RESET,
} from "../constants/proposalLabourExpenseConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalLabourExpensesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LABOUR_EXPENSE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalLabourExpenses(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_labour_expenses: data.results,
          totalCost: data.total_cost.result,
          totalFee: data.total_fee.result,
          totalFeeQuote: data.total_fee_quote.result,
          totalMargin: data.total_margin.result,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalLabourExpensesAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LABOUR_EXPENSE_LIST_RESET });
};

// Create
export const createProposalLabourExpenseAction =
  (proposal_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LABOUR_EXPENSE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalLabourExpense(
        token,
        proposal_labour_expense
      );

      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LABOUR_EXPENSE_CREATE_RESET });
  };

// List details
export const listProposalLabourExpenseDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LABOUR_EXPENSE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalLabourExpense(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalLabourExpenseDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LABOUR_EXPENSE_DETAILS_RESET });
  };

// Update
export const updateProposalLabourExpenseAction =
  (id, proposal_labour_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LABOUR_EXPENSE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalLabourExpense(
        id,
        token,
        proposal_labour_expense
      );

      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LABOUR_EXPENSE_UPDATE_RESET });
  };

// Delete
export const deleteProposalLabourExpenseAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LABOUR_EXPENSE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalLabourExpense(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LABOUR_EXPENSE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalLabourExpenseAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_LABOUR_EXPENSE_DELETE_RESET });
  };
