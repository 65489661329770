export const OPENING_DOCUMENT_DETAILS_REQUEST =
  "OPENING_DOCUMENT_DETAILS_REQUEST";
export const OPENING_DOCUMENT_DETAILS_SUCCESS =
  "OPENING_DOCUMENT_DETAILS_SUCCESS";
export const OPENING_DOCUMENT_DETAILS_FAIL = "OPENING_DOCUMENT_DETAILS_FAIL";
export const OPENING_DOCUMENT_DETAILS_RESET = "OPENING_DOCUMENT_DETAILS_RESET";
export const OPENING_DOCUMENT_UPDATE_REQUEST =
  "OPENING_DOCUMENT_UPDATE_REQUEST";
export const OPENING_DOCUMENT_UPDATE_SUCCESS =
  "OPENING_DOCUMENT_UPDATE_SUCCESS";
export const OPENING_DOCUMENT_UPDATE_FAIL = "OPENING_DOCUMENT_UPDATE_FAIL";
export const OPENING_DOCUMENT_UPDATE_RESET = "OPENING_DOCUMENT_UPDATE_RESET";
