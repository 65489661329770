// Library imports
import { Classes, Colors, H6 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TabSectionField({
  bottom,
  helperText,
  invertBorder,
  label,
  loading,
  subLabel,
  value,
  vertical,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        borderBottom: !bottom
          ? lightMode
            ? `1px solid ${
                invertBorder ? Colors.LIGHT_GRAY1 : Colors.LIGHT_GRAY3
              }`
            : `1px solid ${invertBorder ? Colors.GRAY1 : Colors.DARK_GRAY5}`
          : undefined,
        display: "flex",
        flexDirection: vertical ? "column" : undefined,
        margin: bottom ? "0" : vertical ? "0 0 15px 0" : "0 0 20px 0",
        padding: bottom ? "0" : vertical ? "0 0 15px 0" : "0 0 20px 0",
      }}
    >
      <div
        style={{
          flexShrink: 0,
          padding: vertical ? "0 0 10px 0" : "0 10px 0 0",
          width: vertical ? undefined : "300px",
        }}
      >
        <H6 style={{ margin: "0" }}>{label}</H6>

        {subLabel && (
          <div className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}>
            {subLabel}
          </div>
        )}
      </div>

      <div
        className={loading ? Classes.SKELETON : undefined}
        style={{
          display: helperText && "flex",
          justifyContent: helperText && "space-between",
          width: "100%",
        }}
      >
        {loading ? (
          "XXXXXXXXXX"
        ) : value ? (
          <div style={{ whiteSpace: "pre-wrap" }}>{value}</div>
        ) : (
          "-"
        )}

        {helperText && (
          <div className={Classes.TEXT_MUTED} style={{ margin: "0 0 0 20px" }}>
            {helperText}
          </div>
        )}
      </div>
    </div>
  );
}

export default TabSectionField;
