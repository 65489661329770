// Constant imports
import {
  SHOW_ACTION_SIDEBAR,
  SHOW_SIDEBAR,
  UI_MODE_SWITCH,
} from "../constants/UIConstants";

// Switch action sidebar mode
export const switchActionSidebarModeAction =
  (showActionSidebar) => (dispatch) => {
    dispatch({
      type: SHOW_ACTION_SIDEBAR,

      payload: showActionSidebar,
    });
  };

// Switch sidebar mode
export const switchSidebarModeAction = (showSidebar) => (dispatch) => {
  dispatch({
    type: SHOW_SIDEBAR,

    payload: showSidebar,
  });
};

// Switch UI mode
export const switchUIModeAction = (lightMode) => (dispatch) => {
  dispatch({
    type: UI_MODE_SWITCH,

    payload: lightMode,
  });

  localStorage.setItem("lightMode", lightMode);
};
