// Constant imports
import {
  PROPOSAL_CONTACT_LIST_REQUEST,
  PROPOSAL_CONTACT_LIST_SUCCESS,
  PROPOSAL_CONTACT_LIST_FAIL,
  PROPOSAL_CONTACT_LIST_RESET,
  PROPOSAL_CONTACT_CREATE_REQUEST,
  PROPOSAL_CONTACT_CREATE_SUCCESS,
  PROPOSAL_CONTACT_CREATE_FAIL,
  PROPOSAL_CONTACT_CREATE_RESET,
  PROPOSAL_CONTACT_DETAILS_REQUEST,
  PROPOSAL_CONTACT_DETAILS_SUCCESS,
  PROPOSAL_CONTACT_DETAILS_FAIL,
  PROPOSAL_CONTACT_DETAILS_RESET,
  PROPOSAL_CONTACT_UPDATE_REQUEST,
  PROPOSAL_CONTACT_UPDATE_SUCCESS,
  PROPOSAL_CONTACT_UPDATE_FAIL,
  PROPOSAL_CONTACT_UPDATE_RESET,
  PROPOSAL_CONTACT_DELETE_REQUEST,
  PROPOSAL_CONTACT_DELETE_SUCCESS,
  PROPOSAL_CONTACT_DELETE_FAIL,
  PROPOSAL_CONTACT_DELETE_RESET,
} from "../constants/proposalContactConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalContactsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CONTACT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalContacts(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_CONTACT_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_contacts: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CONTACT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalContactsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CONTACT_LIST_RESET });
};

// Create
export const createProposalContactAction =
  (contact, proposal_contact) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CONTACT_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );

          proposal_contact.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.createProposalContact(
        token,
        proposal_contact
      );

      dispatch({
        type: PROPOSAL_CONTACT_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CONTACT_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalContactAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CONTACT_CREATE_RESET });
};

// List details
export const listProposalContactDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CONTACT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalContact(id, token);

      dispatch({
        type: PROPOSAL_CONTACT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CONTACT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalContactDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CONTACT_DETAILS_RESET });
};

// Update
export const updateProposalContactAction =
  (id, contact, proposal_contact) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CONTACT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );
          proposal_contact.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.updateProposalContact(
        id,
        token,
        proposal_contact
      );

      dispatch({
        type: PROPOSAL_CONTACT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CONTACT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalContactAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CONTACT_UPDATE_RESET });
};

// Delete
export const deleteProposalContactAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CONTACT_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalContact(id, token);

      dispatch({
        type: PROPOSAL_CONTACT_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CONTACT_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalContactAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CONTACT_DELETE_RESET });
};
