// Library imports
import { Spinner, SpinnerSize } from "@blueprintjs/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Document as PDFDocument, Page as PDFPage, pdfjs } from "react-pdf";
import { VariableSizeList as List } from "react-window";
import { asyncMap } from "@wojtekmaj/async-array-utils";

// Main function
function PDF2({
  document,
  documentContainerHeight,
  documentContainerWidth,
  setSectionPages,
  setShowButtons,
  showPage,
  width,
}) {
  // Configure PDF.js worker
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  // Render document
  const [pageViewports, setPageViewports] = useState(null);
  const [pdf, setPdf] = useState(null);

  useEffect(() => {
    setPageViewports(null);

    if (!pdf) {
      return;
    }

    (async () => {
      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      const nextPageViewports = await asyncMap(pageNumbers, (pageNumber) =>
        pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: 1 }))
      );

      setPageViewports(nextPageViewports);
    })();
  }, [pdf, width]);

  const onDocumentLoadSuccess = (nextPdf) => {
    setSectionPages &&
      nextPdf.getOutline().then((outline) => {
        outline.forEach((item) => {
          nextPdf.getPageIndex(item.dest[0]).then((pageIndex) =>
            setSectionPages((prevState) => {
              return {
                ...prevState,
                [item.title]: pageIndex + 1,
              };
            })
          );
        });
      });

    setPdf(nextPdf);

    setShowButtons(true);
  };

  const getPageHeight = (pageIndex) => {
    if (!pageViewports) {
      throw new Error("getPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = width / pageViewport.width;
    const actualHeight = pageViewport.height * scale;

    return actualHeight + 5;
  };

  const options = useMemo(() => {
    return {
      standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
    };
  }, []);

  const Row = useCallback(
    ({ index, style }) => {
      /*const onPageRenderSuccess = (page) => {
        console.log(`Page ${page.pageNumber} rendered`);
      };*/

      return (
        <div
          style={{
            ...style,
            display: "flex",
            justifyContent: "center",
            paddingBottom: "5px",
          }}
        >
          <PDFPage
            loading={
              <Spinner
                size={SpinnerSize.LARGE}
                style={{ margin: "30vh 0 0 0" }}
              />
            }
            noData={
              <Spinner
                size={SpinnerSize.LARGE}
                style={{ margin: "30vh 0 0 0" }}
              />
            }
            //onRenderSuccess={onPageRenderSuccess}
            pageIndex={index}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={width}
          />
        </div>
      );
    },
    [width]
  );

  // List navigation
  const listRef = useRef(null);

  useEffect(() => {
    if (
      documentContainerHeight &&
      listRef.current &&
      pageViewports &&
      pdf &&
      showPage
    ) {
      listRef.current.scrollToItem(showPage - 1, "start");
    }
    // eslint-disable-next-line
  }, [documentContainerHeight, listRef.current, pageViewports, pdf, showPage]);

  // JSX UI code
  return (
    <PDFDocument
      file={document}
      loading={
        <Spinner size={SpinnerSize.LARGE} style={{ margin: "30vh 0 0 0" }} />
      }
      noData={
        <Spinner size={SpinnerSize.LARGE} style={{ margin: "30vh 0 0 0" }} />
      }
      onLoadSuccess={onDocumentLoadSuccess}
      options={options}
    >
      {documentContainerHeight &&
        documentContainerWidth &&
        pageViewports &&
        pdf && (
          <List
            estimatedItemSize={(width * 297) / 210}
            height={documentContainerHeight}
            itemCount={pdf.numPages}
            itemSize={getPageHeight}
            ref={listRef}
            width={documentContainerWidth}
          >
            {Row}
          </List>
        )}
    </PDFDocument>
  );
}

export default PDF2;
