// Library imports
import {
  Card,
  Classes,
  Colors,
  H6,
  HTMLSelect,
  HTMLTable,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Component imports
import InfoPopup from "../components/InfoPopup";
import ItemCard from "../components/ItemCard";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";

// Main function
function DataCardBookingsChart2({
  comments,
  darkColor,
  data,
  fill,
  interactive,
  lightColor,
  noData,
  onClick,
  setStaffQuery,
  title,
  units,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Custom tooltip
  const customToolTip = ({ payload, label, active }) => {
    if (active) {
      const date = new Date(label);

      return (
        <Card
          style={{
            paddingBottom: "10px",
            width: "400px",
          }}
        >
          <H6>
            {selectedLevel === "day"
              ? date.toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : selectedLevel === "month"
              ? date.toLocaleString("en-US", {
                  month: "short",
                  year: "numeric",
                })
              : `${date.toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })} - ${new Date(
                  date.setDate(date.getDate() + 6)
                ).toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}`}
          </H6>

          <Tag
            intent={
              payload[0].payload.free === 0
                ? "success"
                : payload[0].payload.free < 0
                ? "warning"
                : "danger"
            }
            style={{
              textAlign: "center",
              width: "fit-content",
            }}
          >
            {payload[0].payload.free === 0
              ? "Fully booked"
              : payload[0].payload.free < 0
              ? "Oversubscribed"
              : "Undersubscribed"}
          </Tag>

          <HTMLTable style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td>
                  <Tag intent="none" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.available
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.available
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>

                <td>
                  <Tag intent="primary" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.confirmed
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.confirmed
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <Tag intent="warning" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.tentative
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.tentative
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <Tag intent="success" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.realised
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.realised
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th>Available</th>

                <th>Confirmed</th>

                <th>Tentative</th>

                <th>Realised</th>
              </tr>
            </thead>
          </HTMLTable>
        </Card>
      );
    }

    return null;
  };

  // Filter and reshape data
  const currentYear = new Date().getFullYear().toString();

  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    if (data) {
      const newData = [];

      Object.keys(data)
        .filter(
          (key) => new Date(key).getFullYear().toString() === selectedYear
        )
        .forEach((key) => {
          newData.push({
            available: data[key]["available"],
            confirmed: data[key]["confirmed"],
            date: key,
            free: data[key]["free"],
            realised: data[key]["realised"],
            tentative: data[key]["tentative"],
          });
        });

      setFilteredData(newData);
    }
    // eslint-disable-next-line
  }, [data, selectedYear]);

  // Change level
  const [selectedLevel, setSelectedLevel] = useState("month");
  const levelOptions = [
    { label: "Daily", value: "day" },
    { label: "Weekly", value: "week" },
    { label: "Monthly", value: "month" },
  ];

  useEffect(() => {
    setStaffQuery((prevState) => {
      return {
        ...prevState,
        bookingProfile: selectedLevel,
      };
    });
    // eslint-disable-next-line
  }, [selectedLevel]);

  // Generate year options
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    if (data) {
      const yearsUnique = Array.from(
        new Set(
          Object.keys(data).map((key) => {
            return new Date(key).getFullYear().toString();
          })
        )
      );

      setYearOptions(yearsUnique);
    }
  }, [data]);

  // Legend formatter
  const legendFormatter = (value, entry, index) => {
    return (
      <span style={{ fontSize: 12 }}>
        {value === "available"
          ? "Available"
          : value === "confirmed"
          ? "Confirmed"
          : value === "realised"
          ? "Realised"
          : "Tentative"}
      </span>
    );
  };

  // Tick formatter
  const tickFormatter = (value, index) => {
    return new Date(value).toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div style={{ overflowY: "hidden" }}>
              <ResponsiveContainer height={215} width="100%">
                <ComposedChart
                  data={filteredData}
                  margin={{
                    bottom: 15,
                    top: 5,
                  }}
                >
                  <defs>
                    <linearGradient
                      id="colorConfirmed"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="95%"
                        stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                        stopOpacity={0.7}
                      />
                    </linearGradient>

                    <linearGradient
                      id="colorRealised"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="95%"
                        stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                        stopOpacity={0.7}
                      />
                    </linearGradient>

                    <linearGradient
                      id="colorTentative"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={lightMode ? Colors.ORANGE3 : Colors.ORANGE5}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="95%"
                        stopColor={lightMode ? Colors.ORANGE3 : Colors.ORANGE5}
                        stopOpacity={0.7}
                      />
                    </linearGradient>
                  </defs>

                  <Line
                    dataKey="available"
                    dot={false}
                    stroke={lightMode ? Colors.GRAY1 : Colors.GRAY5}
                    strokeDasharray="3"
                    type="step"
                  />

                  <Bar
                    dataKey="confirmed"
                    fill="url(#colorConfirmed)"
                    stackId="a"
                  />

                  <Bar
                    dataKey="tentative"
                    fill="url(#colorTentative)"
                    stackId="a"
                  />

                  <Area
                    dataKey="realised"
                    fill="url(#colorRealised)"
                    stroke={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                    type="monotone"
                  />

                  <Legend
                    formatter={legendFormatter}
                    wrapperStyle={{
                      backgroundColor: lightMode
                        ? Colors.WHITE
                        : Colors.DARK_GRAY3,
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY1 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: 3,
                      lineHeight: "30px",
                      position: "sticky",
                      width: "350px",
                    }}
                  />

                  <Tooltip content={customToolTip} />

                  <XAxis
                    dataKey="date"
                    stroke={lightMode ? Colors.BLACK : Colors.WHITE}
                    style={{ fontSize: 10 }}
                    tickFormatter={tickFormatter}
                    type="category"
                  />

                  <YAxis
                    domain={[0, "auto"]}
                    hide
                    stroke={lightMode ? Colors.BLACK : Colors.WHITE}
                    type="number"
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState icon={"search"} title={"No data"} />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>{title}</div>

          {!noData && (
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>
                <HTMLSelect
                  id="levelField"
                  onChange={(event) =>
                    setSelectedLevel(event.currentTarget.value)
                  }
                  options={levelOptions}
                  value={selectedLevel}
                />
              </div>

              <div>
                <HTMLSelect
                  id="yearField"
                  onChange={(event) =>
                    setSelectedYear(event.currentTarget.value)
                  }
                  options={yearOptions}
                  value={selectedYear}
                />
              </div>
            </div>
          )}
        </div>
      }
      width={width}
    />
  );
}

export default DataCardBookingsChart2;
