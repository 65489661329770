// Constant imports
import {
  TODO_LIST_REQUEST,
  TODO_LIST_SUCCESS,
  TODO_LIST_FAIL,
  TODO_LIST_RESET,
  TODO_UPDATE_REQUEST,
  TODO_UPDATE_SUCCESS,
  TODO_UPDATE_FAIL,
  TODO_UPDATE_RESET,
} from "../constants/todoConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listTodosAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: TODO_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getTodos(params, token);

    dispatch({
      type: TODO_LIST_SUCCESS,

      payload: {
        clientTodosClosed: data.client_todos_closed,
        clientTodosOpen: data.client_todos_open,
        contactTodosClosed: data.contact_todos_closed,
        contactTodosOpen: data.contact_todos_open,
        engagementTodosClosed: data.engagement_todos_closed,
        engagementTodosOpen: data.engagement_todos_open,
        proposalTodosClosed: data.proposal_todos_closed,
        proposalTodosOpen: data.proposal_todos_open,
        count: data.count,
        todos: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: TODO_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListTodosAction = () => async (dispatch) => {
  dispatch({ type: TODO_LIST_RESET });
};

// Update
export const updateTodoAction = (id, todo) => async (dispatch, getState) => {
  try {
    dispatch({ type: TODO_UPDATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.updateTodo(id, token, todo);

    dispatch({
      type: TODO_UPDATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TODO_UPDATE_FAIL,

      payload: error,
    });
  }
};

// Reset update
export const resetUpdateTodoAction = () => async (dispatch) => {
  dispatch({ type: TODO_UPDATE_RESET });
};
