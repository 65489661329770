// Constant imports
import {
  ENGAGEMENT_RISK_LIST_REQUEST,
  ENGAGEMENT_RISK_LIST_SUCCESS,
  ENGAGEMENT_RISK_LIST_FAIL,
  ENGAGEMENT_RISK_LIST_RESET,
  ENGAGEMENT_RISK_CREATE_REQUEST,
  ENGAGEMENT_RISK_CREATE_SUCCESS,
  ENGAGEMENT_RISK_CREATE_FAIL,
  ENGAGEMENT_RISK_CREATE_RESET,
  ENGAGEMENT_RISK_DETAILS_REQUEST,
  ENGAGEMENT_RISK_DETAILS_SUCCESS,
  ENGAGEMENT_RISK_DETAILS_FAIL,
  ENGAGEMENT_RISK_DETAILS_RESET,
  ENGAGEMENT_RISK_UPDATE_REQUEST,
  ENGAGEMENT_RISK_UPDATE_SUCCESS,
  ENGAGEMENT_RISK_UPDATE_FAIL,
  ENGAGEMENT_RISK_UPDATE_RESET,
  ENGAGEMENT_RISK_DELETE_REQUEST,
  ENGAGEMENT_RISK_DELETE_SUCCESS,
  ENGAGEMENT_RISK_DELETE_FAIL,
  ENGAGEMENT_RISK_DELETE_RESET,
} from "../constants/engagementRiskConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementRisksAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RISK_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementRisks(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_RISK_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfRisksClosed: data.number_of_risks_closed,
          numberOfRisksOpen: data.number_of_risks_open,
          engagement_risks: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RISK_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementRisksAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RISK_LIST_RESET });
};

// Create
export const createEngagementRiskAction =
  (engagement_risk) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RISK_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementRisk(
        token,
        engagement_risk
      );

      dispatch({
        type: ENGAGEMENT_RISK_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RISK_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementRiskAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RISK_CREATE_RESET });
};

// List details
export const listEngagementRiskDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RISK_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementRisk(id, token);

      dispatch({
        type: ENGAGEMENT_RISK_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RISK_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementRiskDetailsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RISK_DETAILS_RESET });
};

// Update
export const updateEngagementRiskAction =
  (id, engagement_risk) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RISK_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementRisk(
        id,
        token,
        engagement_risk
      );

      dispatch({
        type: ENGAGEMENT_RISK_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RISK_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementRiskAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RISK_UPDATE_RESET });
};

// Delete
export const deleteEngagementRiskAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_RISK_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementRisk(id, token);

      dispatch({
        type: ENGAGEMENT_RISK_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_RISK_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementRiskAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_RISK_DELETE_RESET });
};
