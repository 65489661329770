export const CLIENT_QUESTION_LIST_REQUEST = "CLIENT_QUESTION_LIST_REQUEST";
export const CLIENT_QUESTION_LIST_SUCCESS = "CLIENT_QUESTION_LIST_SUCCESS";
export const CLIENT_QUESTION_LIST_FAIL = "CLIENT_QUESTION_LIST_FAIL";
export const CLIENT_QUESTION_LIST_RESET = "CLIENT_QUESTION_LIST_RESET";

export const CLIENT_QUESTION_CREATE_REQUEST = "CLIENT_QUESTION_CREATE_REQUEST";
export const CLIENT_QUESTION_CREATE_SUCCESS = "CLIENT_QUESTION_CREATE_SUCCESS";
export const CLIENT_QUESTION_CREATE_FAIL = "CLIENT_QUESTION_CREATE_FAIL";
export const CLIENT_QUESTION_CREATE_RESET = "CLIENT_QUESTION_CREATE_RESET";

export const CLIENT_QUESTION_DETAILS_REQUEST =
  "CLIENT_QUESTION_DETAILS_REQUEST";
export const CLIENT_QUESTION_DETAILS_SUCCESS =
  "CLIENT_QUESTION_DETAILS_SUCCESS";
export const CLIENT_QUESTION_DETAILS_FAIL = "CLIENT_QUESTION_DETAILS_FAIL";
export const CLIENT_QUESTION_DETAILS_RESET = "CLIENT_QUESTION_DETAILS_RESET";

export const CLIENT_QUESTION_UPDATE_REQUEST = "CLIENT_QUESTION_UPDATE_REQUEST";
export const CLIENT_QUESTION_UPDATE_SUCCESS = "CLIENT_QUESTION_UPDATE_SUCCESS";
export const CLIENT_QUESTION_UPDATE_FAIL = "CLIENT_QUESTION_UPDATE_FAIL";
export const CLIENT_QUESTION_UPDATE_RESET = "CLIENT_QUESTION_UPDATE_RESET";

export const CLIENT_QUESTION_DELETE_REQUEST = "CLIENT_QUESTION_DELETE_REQUEST";
export const CLIENT_QUESTION_DELETE_SUCCESS = "CLIENT_QUESTION_DELETE_SUCCESS";
export const CLIENT_QUESTION_DELETE_FAIL = "CLIENT_QUESTION_DELETE_FAIL";
export const CLIENT_QUESTION_DELETE_RESET = "CLIENT_QUESTION_DELETE_RESET";
