// Constant imports
import {
  TEXT_EXTRACT_DATA_REQUEST,
  TEXT_EXTRACT_DATA_SUCCESS,
  TEXT_EXTRACT_DATA_FAIL,
  TEXT_EXTRACT_DATA_RESET,
  TEXT_EXTRACT_DATA_FROM_FILE_REQUEST,
  TEXT_EXTRACT_DATA_FROM_FILE_SUCCESS,
  TEXT_EXTRACT_DATA_FROM_FILE_FAIL,
  TEXT_EXTRACT_DATA_FROM_FILE_RESET,
  TEXT_REWRITE_FIELD_REQUEST,
  TEXT_REWRITE_FIELD_SUCCESS,
  TEXT_REWRITE_FIELD_FAIL,
  TEXT_REWRITE_FIELD_RESET,
} from "../constants/textConstants";

// Data services import
import cortixDataService from "../services/cortix";

// Extract data
export const extractDataTextAction =
  (id, context_data) => async (dispatch, getState) => {
    try {
      dispatch({ type: TEXT_EXTRACT_DATA_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.extractDataText(
        id,
        token,
        context_data
      );

      dispatch({
        type: TEXT_EXTRACT_DATA_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEXT_EXTRACT_DATA_FAIL,

        payload: error,
      });
    }
  };

// Reset extract data
export const resetExtractDataTextAction = () => async (dispatch) => {
  dispatch({ type: TEXT_EXTRACT_DATA_RESET });
};

// Extract data from file
export const extractDataFromFileTextAction =
  (id, context_data) => async (dispatch, getState) => {
    try {
      dispatch({ type: TEXT_EXTRACT_DATA_FROM_FILE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.extractDataFromFileText(
        id,
        token,
        context_data
      );

      dispatch({
        type: TEXT_EXTRACT_DATA_FROM_FILE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEXT_EXTRACT_DATA_FROM_FILE_FAIL,

        payload: error,
      });
    }
  };

// Reset extract data from file
export const resetExtractDataFromFileTextAction = () => async (dispatch) => {
  dispatch({ type: TEXT_EXTRACT_DATA_FROM_FILE_RESET });
};

// Rewrite field
export const rewriteFieldTextAction =
  (id, field_data) => async (dispatch, getState) => {
    try {
      dispatch({ type: TEXT_REWRITE_FIELD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.rewriteFieldText(
        id,
        token,
        field_data
      );

      dispatch({
        type: TEXT_REWRITE_FIELD_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TEXT_REWRITE_FIELD_FAIL,

        payload: error,
      });
    }
  };

// Reset rewrite field
export const resetRewriteFieldTextAction = () => async (dispatch) => {
  dispatch({ type: TEXT_REWRITE_FIELD_RESET });
};
