// Library imports
import { Card, Classes, Colors, H6, ProgressBar, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import CopyButton from "../components/CopyButton";

// Main function
function TabSectionFieldCard({
  copyButton,
  footer,
  loading,
  interactive,
  noBackground,
  onClick,
  rating,
  tag,
  tagIntent,
  text,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ width: "100%" }}>
      <Card
        interactive={interactive}
        onClick={onClick}
        style={{
          background: noBackground
            ? lightMode
              ? Colors.WHITE
              : Colors.DARK_GRAY3 // Colors.DARK_GRAY5
            : lightMode
            ? Colors.LIGHT_GRAY5
            : Colors.DARK_GRAY2,
        }}
      >
        {loading ? (
          <H6 className={Classes.SKELETON}>XXXXXXXXXX</H6>
        ) : (
          <>
            <div style={{ margin: "0 auto 0 auto", maxWidth: "800px" }}>
              {title ? <H6>{title}</H6> : undefined}

              {tag ? (
                <div
                  style={{
                    margin: title ? "15px 0 0 0" : undefined,
                  }}
                >
                  <Tag
                    intent={tagIntent}
                    large
                    minimal
                    style={{
                      maxWidth: "300px",
                      minWidth: "100px",
                      textAlign: "center",
                    }}
                  >
                    {tag}
                  </Tag>
                </div>
              ) : undefined}

              {rating ? (
                <div
                  style={{
                    margin: tag || title ? "15px 0 0 0" : undefined,
                    width: "250px",
                  }}
                >
                  <ProgressBar
                    animate={false}
                    intent={
                      rating > 4 ? "success" : rating > 2 ? "warning" : "danger"
                    }
                    stripes={false}
                    value={rating / 5}
                  />
                </div>
              ) : undefined}

              <div
                style={{
                  margin: rating || tag || title ? "15px 0 0 0" : undefined,
                  whiteSpace: "pre-wrap",
                }}
              >
                {text ? text : "-"}
              </div>

              {footer ? (
                <div
                  className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
                  style={{
                    display: "flex",
                    margin: "15px 0 0 0",
                    justifyContent: "end",
                  }}
                >
                  {footer}
                </div>
              ) : undefined}
            </div>

            {copyButton ? (
              <div style={{ textAlign: "end" }}>
                <CopyButton content={text} />
              </div>
            ) : undefined}
          </>
        )}
      </Card>
    </div>
  );
}

export default TabSectionFieldCard;
