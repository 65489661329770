// Constant imports
import {
  PROPOSAL_REQUIREMENT_LIST_REQUEST,
  PROPOSAL_REQUIREMENT_LIST_SUCCESS,
  PROPOSAL_REQUIREMENT_LIST_FAIL,
  PROPOSAL_REQUIREMENT_LIST_RESET,
  PROPOSAL_REQUIREMENT_CREATE_REQUEST,
  PROPOSAL_REQUIREMENT_CREATE_SUCCESS,
  PROPOSAL_REQUIREMENT_CREATE_FAIL,
  PROPOSAL_REQUIREMENT_CREATE_RESET,
  PROPOSAL_REQUIREMENT_DETAILS_REQUEST,
  PROPOSAL_REQUIREMENT_DETAILS_SUCCESS,
  PROPOSAL_REQUIREMENT_DETAILS_FAIL,
  PROPOSAL_REQUIREMENT_DETAILS_RESET,
  PROPOSAL_REQUIREMENT_UPDATE_REQUEST,
  PROPOSAL_REQUIREMENT_UPDATE_SUCCESS,
  PROPOSAL_REQUIREMENT_UPDATE_FAIL,
  PROPOSAL_REQUIREMENT_UPDATE_RESET,
  PROPOSAL_REQUIREMENT_DELETE_REQUEST,
  PROPOSAL_REQUIREMENT_DELETE_SUCCESS,
  PROPOSAL_REQUIREMENT_DELETE_FAIL,
  PROPOSAL_REQUIREMENT_DELETE_RESET,
  PROPOSAL_REQUIREMENT_DOWNLOAD_REQUEST,
  PROPOSAL_REQUIREMENT_DOWNLOAD_SUCCESS,
  PROPOSAL_REQUIREMENT_DOWNLOAD_FAIL,
  PROPOSAL_REQUIREMENT_DOWNLOAD_RESET,
  PROPOSAL_REQUIREMENT_UPLOAD_BEGIN,
  PROPOSAL_REQUIREMENT_UPLOAD_REQUEST,
  PROPOSAL_REQUIREMENT_UPLOAD_SUCCESS,
  PROPOSAL_REQUIREMENT_UPLOAD_FAIL,
  PROPOSAL_REQUIREMENT_UPLOAD_END,
  PROPOSAL_REQUIREMENT_UPLOAD_RESET,
} from "../constants/proposalRequirementConstants";

// List all
export const proposalRequirementListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_requirements: [],
    numberOfRequirementsCompliant: 0,
    numberOfRequirementsPartCompliant: 0,
    numberOfRequirementsNonCompliant: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_REQUIREMENT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_requirements: action.payload.proposal_requirements,
        numberOfRequirementsCompliant:
          action.payload.numberOfRequirementsCompliant,
        numberOfRequirementsPartCompliant:
          action.payload.numberOfRequirementsPartCompliant,
        numberOfRequirementsNonCompliant:
          action.payload.numberOfRequirementsNonCompliant,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_requirements: [],
        numberOfRequirementsCompliant: 0,
        numberOfRequirementsPartCompliant: 0,
        numberOfRequirementsNonCompliant: 0,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_REQUIREMENT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_requirements: [],
        numberOfRequirementsCompliant: 0,
        numberOfRequirementsPartCompliant: 0,
        numberOfRequirementsNonCompliant: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalRequirementCreateReducer = (
  state = {
    loading: false,
    proposal_requirement: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_CREATE_REQUEST:
      return {
        loading: true,
        proposal_requirement: state.proposal_requirement,
        success: false,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_requirement: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_CREATE_FAIL:
      return {
        loading: false,
        proposal_requirement: state.proposal_requirement,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_REQUIREMENT_CREATE_RESET:
      return {
        loading: false,
        proposal_requirement: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalRequirementDetailsReducer = (
  state = {
    loading: false,
    proposal_requirement: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_requirement: state.proposal_requirement,
        success: false,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_requirement: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_DETAILS_FAIL:
      return {
        loading: false,
        proposal_requirement: state.proposal_requirement,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_REQUIREMENT_DETAILS_RESET:
      return {
        loading: false,
        proposal_requirement: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalRequirementUpdateReducer = (
  state = {
    loading: false,
    proposal_requirement: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_requirement: state.proposal_requirement,
        success: false,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_requirement: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_UPDATE_FAIL:
      return {
        loading: false,
        proposal_requirement: state.proposal_requirement,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_REQUIREMENT_UPDATE_RESET:
      return {
        loading: false,
        proposal_requirement: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalRequirementDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_REQUIREMENT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const proposalRequirementDownloadReducer = (
  state = {
    loading: false,
    proposal_requirements: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_REQUIREMENT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        proposal_requirements: action.payload.proposal_requirements,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_DOWNLOAD_FAIL:
      return {
        loading: false,
        proposal_requirements: [],
        success: false,
        error: action.payload,
      };

    case PROPOSAL_REQUIREMENT_DOWNLOAD_RESET:
      return {
        loading: false,
        proposal_requirements: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const proposalRequirementUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    progress: 0,
    records_failed: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_REQUIREMENT_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case PROPOSAL_REQUIREMENT_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_REQUIREMENT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_REQUIREMENT_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        records_failed: action.payload.records_failed,
        success: false,
        error: action.payload.error,
      };

    case PROPOSAL_REQUIREMENT_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case PROPOSAL_REQUIREMENT_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        progress: 0,
        records_failed: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
