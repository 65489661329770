// Constant imports
import {
  CONTACT_ACTION_LIST_REQUEST,
  CONTACT_ACTION_LIST_SUCCESS,
  CONTACT_ACTION_LIST_FAIL,
  CONTACT_ACTION_LIST_RESET,
  CONTACT_ACTION_CREATE_REQUEST,
  CONTACT_ACTION_CREATE_SUCCESS,
  CONTACT_ACTION_CREATE_FAIL,
  CONTACT_ACTION_CREATE_RESET,
  CONTACT_ACTION_DETAILS_REQUEST,
  CONTACT_ACTION_DETAILS_SUCCESS,
  CONTACT_ACTION_DETAILS_FAIL,
  CONTACT_ACTION_DETAILS_RESET,
  CONTACT_ACTION_UPDATE_REQUEST,
  CONTACT_ACTION_UPDATE_SUCCESS,
  CONTACT_ACTION_UPDATE_FAIL,
  CONTACT_ACTION_UPDATE_RESET,
  CONTACT_ACTION_DELETE_REQUEST,
  CONTACT_ACTION_DELETE_SUCCESS,
  CONTACT_ACTION_DELETE_FAIL,
  CONTACT_ACTION_DELETE_RESET,
} from "../constants/contactActionConstants";

// List all
export const contactActionListReducer = (
  state = {
    loading: false,
    count: 0,
    contact_actions: [],
    numberOfActionsCompleted: 0,
    numberOfActionsInProgress: 0,
    numberOfActionsOnHold: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CONTACT_ACTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONTACT_ACTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        contact_actions: action.payload.contact_actions,
        numberOfActionsCompleted: action.payload.numberOfActionsCompleted,
        numberOfActionsInProgress: action.payload.numberOfActionsInProgress,
        numberOfActionsOnHold: action.payload.numberOfActionsOnHold,
        success: true,
        error: "",
      };

    case CONTACT_ACTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        contact_actions: [],
        numberOfActionsCompleted: 0,
        numberOfActionsInProgress: 0,
        numberOfActionsOnHold: 0,
        success: false,
        error: action.payload,
      };

    case CONTACT_ACTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        contact_actions: [],
        numberOfActionsCompleted: 0,
        numberOfActionsInProgress: 0,
        numberOfActionsOnHold: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const contactActionCreateReducer = (
  state = { loading: false, contact_action: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_ACTION_CREATE_REQUEST:
      return {
        loading: true,
        contact_action: state.contact_action,
        success: false,
        error: "",
      };

    case CONTACT_ACTION_CREATE_SUCCESS:
      return {
        loading: false,
        contact_action: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_ACTION_CREATE_FAIL:
      return {
        loading: false,
        contact_action: state.contact_action,
        success: false,
        error: action.payload,
      };

    case CONTACT_ACTION_CREATE_RESET:
      return { loading: false, contact_action: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const contactActionDetailsReducer = (
  state = { loading: false, contact_action: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_ACTION_DETAILS_REQUEST:
      return {
        loading: true,
        contact_action: state.contact_action,
        success: false,
        error: "",
      };

    case CONTACT_ACTION_DETAILS_SUCCESS:
      return {
        loading: false,
        contact_action: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_ACTION_DETAILS_FAIL:
      return {
        loading: false,
        contact_action: state.contact_action,
        success: false,
        error: action.payload,
      };

    case CONTACT_ACTION_DETAILS_RESET:
      return { loading: false, contact_action: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const contactActionUpdateReducer = (
  state = { loading: false, contact_action: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_ACTION_UPDATE_REQUEST:
      return {
        loading: true,
        contact_action: state.contact_action,
        success: false,
        error: "",
      };

    case CONTACT_ACTION_UPDATE_SUCCESS:
      return {
        loading: false,
        contact_action: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_ACTION_UPDATE_FAIL:
      return {
        loading: false,
        contact_action: state.contact_action,
        success: false,
        error: action.payload,
      };

    case CONTACT_ACTION_UPDATE_RESET:
      return { loading: false, contact_action: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const contactActionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_ACTION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CONTACT_ACTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CONTACT_ACTION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CONTACT_ACTION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
