// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Leave category
import { listLeaveCategoriesAction } from "../actions/leaveCategoryActions";

// Main function
function LeaveCategoryInfo({
  additionalInfo,
  categoryData,
  categoryId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Leave category
  const leaveCategoryList = useSelector((state) => state.leaveCategoryList);

  // Refresh leave category data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState({});

  useEffect(() => {
    if (categoryData) {
      setCategory(categoryData);

      setLoading(false);
    }
  }, [categoryData]);

  useEffect(() => {
    if (!categoryData && !leaveCategoryList.loading) {
      if (leaveCategoryList.success) {
        const thisCategory = leaveCategoryList.leave_categories.filter(
          (leave_category) => leave_category.id === categoryId
        );

        if (thisCategory.length > 0) {
          setCategory(thisCategory[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listLeaveCategoriesAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (leaveCategoryList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [leaveCategoryList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{category.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Code</strong>
          </p>

          <p>{category.code ? category.code : "-"}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Description</strong>
          </p>

          <p>
            {category.description ? (
              category.description
            ) : (
              <span className={Classes.TEXT_MUTED}>
                No description provided.
              </span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default LeaveCategoryInfo;
