export const ORGANISATION_LIST_REQUEST = "ORGANISATION_LIST_REQUEST";
export const ORGANISATION_LIST_SUCCESS = "ORGANISATION_LIST_SUCCESS";
export const ORGANISATION_LIST_FAIL = "ORGANISATION_LIST_FAIL";
export const ORGANISATION_LIST_RESET = "ORGANISATION_LIST_RESET";

export const ORGANISATION_CREATE_REQUEST = "ORGANISATION_CREATE_REQUEST";
export const ORGANISATION_CREATE_SUCCESS = "ORGANISATION_CREATE_SUCCESS";
export const ORGANISATION_CREATE_FAIL = "ORGANISATION_CREATE_FAIL";
export const ORGANISATION_CREATE_RESET = "ORGANISATION_CREATE_RESET";

export const ORGANISATION_DETAILS_REQUEST = "ORGANISATION_DETAILS_REQUEST";
export const ORGANISATION_DETAILS_SUCCESS = "ORGANISATION_DETAILS_SUCCESS";
export const ORGANISATION_DETAILS_FAIL = "ORGANISATION_DETAILS_FAIL";
export const ORGANISATION_DETAILS_RESET = "ORGANISATION_DETAILS_RESET";

export const ORGANISATION_UPDATE_REQUEST = "ORGANISATION_UPDATE_REQUEST";
export const ORGANISATION_UPDATE_SUCCESS = "ORGANISATION_UPDATE_SUCCESS";
export const ORGANISATION_UPDATE_FAIL = "ORGANISATION_UPDATE_FAIL";
export const ORGANISATION_UPDATE_RESET = "ORGANISATION_UPDATE_RESET";

export const ORGANISATION_DELETE_REQUEST = "ORGANISATION_DELETE_REQUEST";
export const ORGANISATION_DELETE_SUCCESS = "ORGANISATION_DELETE_SUCCESS";
export const ORGANISATION_DELETE_FAIL = "ORGANISATION_DELETE_FAIL";
export const ORGANISATION_DELETE_RESET = "ORGANISATION_DELETE_RESET";

export const ORGANISATION_SET_ACTIVE = "ORGANISATION_SET_ACTIVE";
export const ORGANISATION_SET_NONE = "ORGANISATION_SET_NONE";

export const ORGANISATION_CHECK_PERMISSIONS_REQUEST =
  "ORGANISATION_CHECK_PERMISSIONS_REQUEST";
export const ORGANISATION_CHECK_PERMISSIONS_SUCCESS =
  "ORGANISATION_CHECK_PERMISSIONS_SUCCESS";
export const ORGANISATION_CHECK_PERMISSIONS_FAIL =
  "ORGANISATION_CHECK_PERMISSIONS_FAIL";
export const ORGANISATION_CHECK_PERMISSIONS_RESET =
  "ORGANISATION_CHECK_PERMISSIONS_RESET";

export const ORGANISATION_CHECK_SUBSCRIPTION_REQUEST =
  "ORGANISATION_CHECK_SUBSCRIPTION_REQUEST";
export const ORGANISATION_CHECK_SUBSCRIPTION_SUCCESS =
  "ORGANISATION_CHECK_SUBSCRIPTION_SUCCESS";
export const ORGANISATION_CHECK_SUBSCRIPTION_FAIL =
  "ORGANISATION_CHECK_SUBSCRIPTION_FAIL";
export const ORGANISATION_CHECK_SUBSCRIPTION_RESET =
  "ORGANISATION_CHECK_SUBSCRIPTION_RESET";
