// Constant imports
import {
  WEEK_LIST_REQUEST,
  WEEK_LIST_SUCCESS,
  WEEK_LIST_FAIL,
  WEEK_LIST_RESET,
} from "../constants/weekConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listWeeksAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WEEK_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getWeeks(token);

    dispatch({
      type: WEEK_LIST_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WEEK_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListWeeksAction = () => async (dispatch) => {
  dispatch({ type: WEEK_LIST_RESET });
};
