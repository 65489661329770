export const EXPENSE_CATEGORY_LIST_REQUEST = "EXPENSE_CATEGORY_LIST_REQUEST";
export const EXPENSE_CATEGORY_LIST_SUCCESS = "EXPENSE_CATEGORY_LIST_SUCCESS";
export const EXPENSE_CATEGORY_LIST_FAIL = "EXPENSE_CATEGORY_LIST_FAIL";
export const EXPENSE_CATEGORY_LIST_RESET = "EXPENSE_CATEGORY_LIST_RESET";

export const EXPENSE_CATEGORY_CREATE_REQUEST =
  "EXPENSE_CATEGORY_CREATE_REQUEST";
export const EXPENSE_CATEGORY_CREATE_SUCCESS =
  "EXPENSE_CATEGORY_CREATE_SUCCESS";
export const EXPENSE_CATEGORY_CREATE_FAIL = "EXPENSE_CATEGORY_CREATE_FAIL";
export const EXPENSE_CATEGORY_CREATE_RESET = "EXPENSE_CATEGORY_CREATE_RESET";

export const EXPENSE_CATEGORY_DETAILS_REQUEST =
  "EXPENSE_CATEGORY_DETAILS_REQUEST";
export const EXPENSE_CATEGORY_DETAILS_SUCCESS =
  "EXPENSE_CATEGORY_DETAILS_SUCCESS";
export const EXPENSE_CATEGORY_DETAILS_FAIL = "EXPENSE_CATEGORY_DETAILS_FAIL";
export const EXPENSE_CATEGORY_DETAILS_RESET = "EXPENSE_CATEGORY_DETAILS_RESET";

export const EXPENSE_CATEGORY_UPDATE_REQUEST =
  "EXPENSE_CATEGORY_UPDATE_REQUEST";
export const EXPENSE_CATEGORY_UPDATE_SUCCESS =
  "EXPENSE_CATEGORY_UPDATE_SUCCESS";
export const EXPENSE_CATEGORY_UPDATE_FAIL = "EXPENSE_CATEGORY_UPDATE_FAIL";
export const EXPENSE_CATEGORY_UPDATE_RESET = "EXPENSE_CATEGORY_UPDATE_RESET";

export const EXPENSE_CATEGORY_DELETE_REQUEST =
  "EXPENSE_CATEGORY_DELETE_REQUEST";
export const EXPENSE_CATEGORY_DELETE_SUCCESS =
  "EXPENSE_CATEGORY_DELETE_SUCCESS";
export const EXPENSE_CATEGORY_DELETE_FAIL = "EXPENSE_CATEGORY_DELETE_FAIL";
export const EXPENSE_CATEGORY_DELETE_RESET = "EXPENSE_CATEGORY_DELETE_RESET";

export const EXPENSE_CATEGORY_DOWNLOAD_REQUEST =
  "EXPENSE_CATEGORY_DOWNLOAD_REQUEST";
export const EXPENSE_CATEGORY_DOWNLOAD_SUCCESS =
  "EXPENSE_CATEGORY_DOWNLOAD_SUCCESS";
export const EXPENSE_CATEGORY_DOWNLOAD_FAIL = "EXPENSE_CATEGORY_DOWNLOAD_FAIL";
export const EXPENSE_CATEGORY_DOWNLOAD_RESET =
  "EXPENSE_CATEGORY_DOWNLOAD_RESET";

export const EXPENSE_CATEGORY_UPLOAD_BEGIN = "EXPENSE_CATEGORY_UPLOAD_BEGIN";
export const EXPENSE_CATEGORY_UPLOAD_REQUEST =
  "EXPENSE_CATEGORY_UPLOAD_REQUEST";
export const EXPENSE_CATEGORY_UPLOAD_SUCCESS =
  "EXPENSE_CATEGORY_UPLOAD_SUCCESS";
export const EXPENSE_CATEGORY_UPLOAD_FAIL = "EXPENSE_CATEGORY_UPLOAD_FAIL";
export const EXPENSE_CATEGORY_UPLOAD_END = "EXPENSE_CATEGORY_UPLOAD_END";
export const EXPENSE_CATEGORY_UPLOAD_RESET = "EXPENSE_CATEGORY_UPLOAD_RESET";
