// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDataPoint from "../components/DataCardDataPoint";

// Main function
function OpeningAgeCard({ fill, openingList }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDataPoint
      background={
        openingList.averageAge > 90
          ? lightMode
            ? Colors.RED3
            : Colors.RED5
          : openingList.averageAge > 60
          ? lightMode
            ? Colors.ORANGE3
            : Colors.ORANGE5
          : lightMode
          ? Colors.GREEN3
          : Colors.GREEN5
      }
      comments={
        <>
          <H5>Age</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Average age of the selected opening(s). Considers openings that have
            not been filled.
          </p>
        </>
      }
      data={openingList.averageAge}
      fill={fill}
      noData={!openingList.averageAge}
      title="Age"
      units="Days"
    />
  );
}

export default OpeningAgeCard;
