// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  PROPOSAL_REQUIREMENT_LIST_REQUEST,
  PROPOSAL_REQUIREMENT_LIST_SUCCESS,
  PROPOSAL_REQUIREMENT_LIST_FAIL,
  PROPOSAL_REQUIREMENT_LIST_RESET,
  PROPOSAL_REQUIREMENT_CREATE_REQUEST,
  PROPOSAL_REQUIREMENT_CREATE_SUCCESS,
  PROPOSAL_REQUIREMENT_CREATE_FAIL,
  PROPOSAL_REQUIREMENT_CREATE_RESET,
  PROPOSAL_REQUIREMENT_DETAILS_REQUEST,
  PROPOSAL_REQUIREMENT_DETAILS_SUCCESS,
  PROPOSAL_REQUIREMENT_DETAILS_FAIL,
  PROPOSAL_REQUIREMENT_DETAILS_RESET,
  PROPOSAL_REQUIREMENT_UPDATE_REQUEST,
  PROPOSAL_REQUIREMENT_UPDATE_SUCCESS,
  PROPOSAL_REQUIREMENT_UPDATE_FAIL,
  PROPOSAL_REQUIREMENT_UPDATE_RESET,
  PROPOSAL_REQUIREMENT_DELETE_REQUEST,
  PROPOSAL_REQUIREMENT_DELETE_SUCCESS,
  PROPOSAL_REQUIREMENT_DELETE_FAIL,
  PROPOSAL_REQUIREMENT_DELETE_RESET,
  PROPOSAL_REQUIREMENT_DOWNLOAD_REQUEST,
  PROPOSAL_REQUIREMENT_DOWNLOAD_SUCCESS,
  PROPOSAL_REQUIREMENT_DOWNLOAD_FAIL,
  PROPOSAL_REQUIREMENT_DOWNLOAD_RESET,
  PROPOSAL_REQUIREMENT_UPLOAD_BEGIN,
  PROPOSAL_REQUIREMENT_UPLOAD_REQUEST,
  PROPOSAL_REQUIREMENT_UPLOAD_SUCCESS,
  PROPOSAL_REQUIREMENT_UPLOAD_FAIL,
  PROPOSAL_REQUIREMENT_UPLOAD_END,
  PROPOSAL_REQUIREMENT_UPLOAD_RESET,
} from "../constants/proposalRequirementConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalRequirementsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_REQUIREMENT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalRequirements(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_REQUIREMENT_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfRequirementsCompliant: data.number_of_requirements_compliant,
          numberOfRequirementsPartCompliant:
            data.number_of_requirements_part_compliant,
          numberOfRequirementsNonCompliant:
            data.number_of_requirements_non_compliant,
          proposal_requirements: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_REQUIREMENT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalRequirementsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_REQUIREMENT_LIST_RESET });
};

// Create
export const createProposalRequirementAction =
  (proposal_requirement) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_REQUIREMENT_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalRequirement(
        token,
        proposal_requirement
      );

      dispatch({
        type: PROPOSAL_REQUIREMENT_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_REQUIREMENT_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalRequirementAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_REQUIREMENT_CREATE_RESET });
};

// List details
export const listProposalRequirementDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_REQUIREMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalRequirement(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_REQUIREMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_REQUIREMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalRequirementDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_REQUIREMENT_DETAILS_RESET });
  };

// Update
export const updateProposalRequirementAction =
  (id, proposal_requirement) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_REQUIREMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalRequirement(
        id,
        token,
        proposal_requirement
      );

      dispatch({
        type: PROPOSAL_REQUIREMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_REQUIREMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalRequirementAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_REQUIREMENT_UPDATE_RESET });
};

// Delete
export const deleteProposalRequirementAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_REQUIREMENT_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalRequirement(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_REQUIREMENT_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_REQUIREMENT_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalRequirementAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_REQUIREMENT_DELETE_RESET });
};

// Download
export const downloadProposalRequirementsAction =
  (proposal, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_REQUIREMENT_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var proposalRequirements = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getProposalRequirements(
          { page: page, proposal: proposal },
          token
        );

        proposalRequirements = proposalRequirements.concat(data.results);
      }

      dispatch({
        type: PROPOSAL_REQUIREMENT_DOWNLOAD_SUCCESS,

        payload: {
          proposal_requirements: proposalRequirements,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_REQUIREMENT_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadProposalRequirementsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_REQUIREMENT_DOWNLOAD_RESET });
  };

// Upload
export const uploadProposalRequirementsAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: PROPOSAL_REQUIREMENT_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: PROPOSAL_REQUIREMENT_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteProposalRequirement(
            records[recordNum].id,
            token
          );
        } else if (records[recordNum].id) {
          await cortixDataService.updateProposalRequirement(
            records[recordNum].id,
            token,
            {
              category: records[recordNum].category,
              name: records[recordNum].name,
              owner: records[recordNum].owner,
              proposal: id,
              ref: records[recordNum].ref,
              requirement: records[recordNum].requirement,
              response: records[recordNum].response,
              status: records[recordNum].status,
            }
          );
        } else {
          await cortixDataService.createProposalRequirement(token, {
            category: records[recordNum].category,
            name: records[recordNum].name,
            owner: records[recordNum].owner,
            proposal: id,
            ref: records[recordNum].ref,
            requirement: records[recordNum].requirement,
            response: records[recordNum].response,
            status: records[recordNum].status,
          });
        }

        dispatch({
          type: PROPOSAL_REQUIREMENT_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: PROPOSAL_REQUIREMENT_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: PROPOSAL_REQUIREMENT_UPLOAD_END });
  };

// Reset upload
export const resetUploadProposalRequirementsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_REQUIREMENT_UPLOAD_RESET });
};
