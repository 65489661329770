// Constant imports
import {
  INVOICE_OTHER_LIST_REQUEST,
  INVOICE_OTHER_LIST_SUCCESS,
  INVOICE_OTHER_LIST_FAIL,
  INVOICE_OTHER_LIST_RESET,
  INVOICE_OTHER_CREATE_REQUEST,
  INVOICE_OTHER_CREATE_SUCCESS,
  INVOICE_OTHER_CREATE_FAIL,
  INVOICE_OTHER_CREATE_RESET,
  INVOICE_OTHER_DETAILS_REQUEST,
  INVOICE_OTHER_DETAILS_SUCCESS,
  INVOICE_OTHER_DETAILS_FAIL,
  INVOICE_OTHER_DETAILS_RESET,
  INVOICE_OTHER_UPDATE_REQUEST,
  INVOICE_OTHER_UPDATE_SUCCESS,
  INVOICE_OTHER_UPDATE_FAIL,
  INVOICE_OTHER_UPDATE_RESET,
  INVOICE_OTHER_DELETE_REQUEST,
  INVOICE_OTHER_DELETE_SUCCESS,
  INVOICE_OTHER_DELETE_FAIL,
  INVOICE_OTHER_DELETE_RESET,
} from "../constants/invoiceOtherConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInvoiceOthersAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_OTHER_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceOthers(params, token);

      dispatch({
        type: INVOICE_OTHER_LIST_SUCCESS,

        payload: {
          totalNet: data.total_net.result,
          totalNetQuote: data.total_net_quote.result,
          totalTax: data.total_tax.result,
          totalValue: data.total_value.result,
          count: data.count,
          invoice_others: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INVOICE_OTHER_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInvoiceOthersAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_OTHER_LIST_RESET });
};

// List details
export const listInvoiceOtherDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_OTHER_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceOther(id, token);

      dispatch({
        type: INVOICE_OTHER_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_OTHER_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInvoiceOtherDetailsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_OTHER_DETAILS_RESET });
};

// Create
export const createInvoiceOtherAction =
  (invoice_other) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_OTHER_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createInvoiceOther(
        token,
        invoice_other
      );

      dispatch({
        type: INVOICE_OTHER_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_OTHER_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateInvoiceOtherAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_OTHER_CREATE_RESET });
};

// Update
export const updateInvoiceOtherAction =
  (id, invoice_other) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_OTHER_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInvoiceOther(
        id,
        token,
        invoice_other
      );

      dispatch({
        type: INVOICE_OTHER_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_OTHER_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInvoiceOtherAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_OTHER_UPDATE_RESET });
};

// Delete
export const deleteInvoiceOtherAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INVOICE_OTHER_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteInvoiceOther(id, token);

    dispatch({
      type: INVOICE_OTHER_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_OTHER_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteInvoiceOtherAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_OTHER_DELETE_RESET });
};
