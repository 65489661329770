// Constant imports
import {
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_LIST_FAIL,
  SERVICE_LIST_RESET,
  SERVICE_CREATE_REQUEST,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_FAIL,
  SERVICE_CREATE_RESET,
  SERVICE_DETAILS_REQUEST,
  SERVICE_DETAILS_SUCCESS,
  SERVICE_DETAILS_FAIL,
  SERVICE_DETAILS_RESET,
  SERVICE_UPDATE_REQUEST,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
  SERVICE_UPDATE_RESET,
  SERVICE_DELETE_REQUEST,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,
  SERVICE_DELETE_RESET,
} from "../constants/serviceConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listServicesAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getServices(params, token);

    dispatch({
      type: SERVICE_LIST_SUCCESS,

      payload: {
        averageMarginPercentageOnProposalsCancelled:
          data.average_margin_percentage_on_proposals_cancelled.result,
        averageMarginPercentageOnProposalsLost:
          data.average_margin_percentage_on_proposals_lost.result,
        averageMarginPercentageOnProposalsWon:
          data.average_margin_percentage_on_proposals_won.result,
        totalFeesOnProposalsCancelled:
          data.total_fees_on_proposals_cancelled.result,
        totalFeesOnProposalsInProgress:
          data.total_fees_on_proposals_in_progress.result,
        totalFeesOnProposalsLost: data.total_fees_on_proposals_lost.result,
        totalFeesOnProposalsQualifying:
          data.total_fees_on_proposals_qualifying.result,
        totalFeesOnProposalsSubmitted:
          data.total_fees_on_proposals_submitted.result,
        totalFeesOnProposalsWon: data.total_fees_on_proposals_won.result,
        count: data.count,
        services: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: SERVICE_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListServicesAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_LIST_RESET });
};

// Create
export const createServiceAction = (service) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createService(token, service);

    dispatch({
      type: SERVICE_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateServiceAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_CREATE_RESET });
};

// List details
export const listServiceDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getService(id, token);

    dispatch({
      type: SERVICE_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListServiceDetailsAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_DETAILS_RESET });
};

// Update
export const updateServiceAction =
  (id, service) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateService(
        id,
        token,
        service
      );

      dispatch({
        type: SERVICE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateServiceAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_UPDATE_RESET });
};

// Delete
export const deleteServiceAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SERVICE_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteService(id, token);

    dispatch({
      type: SERVICE_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SERVICE_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteServiceAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_DELETE_RESET });
};
