// Constant imports
import {
  CLIENT_KPI_LIST_REQUEST,
  CLIENT_KPI_LIST_SUCCESS,
  CLIENT_KPI_LIST_FAIL,
  CLIENT_KPI_LIST_RESET,
  CLIENT_KPI_CREATE_REQUEST,
  CLIENT_KPI_CREATE_SUCCESS,
  CLIENT_KPI_CREATE_FAIL,
  CLIENT_KPI_CREATE_RESET,
  CLIENT_KPI_DETAILS_REQUEST,
  CLIENT_KPI_DETAILS_SUCCESS,
  CLIENT_KPI_DETAILS_FAIL,
  CLIENT_KPI_DETAILS_RESET,
  CLIENT_KPI_UPDATE_REQUEST,
  CLIENT_KPI_UPDATE_SUCCESS,
  CLIENT_KPI_UPDATE_FAIL,
  CLIENT_KPI_UPDATE_RESET,
  CLIENT_KPI_DELETE_REQUEST,
  CLIENT_KPI_DELETE_SUCCESS,
  CLIENT_KPI_DELETE_FAIL,
  CLIENT_KPI_DELETE_RESET,
} from "../constants/clientKPIConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientKPIsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_KPI_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClientKPIs(params, token);

    dispatch({
      type: CLIENT_KPI_LIST_SUCCESS,

      payload: { count: data.count, client_kpis: data.results },
    });
  } catch (error) {
    dispatch({
      type: CLIENT_KPI_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListClientKPIsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_KPI_LIST_RESET });
};

// Create
export const createClientKPIAction = (kpi) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_KPI_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createClientKPI(token, kpi);

    dispatch({
      type: CLIENT_KPI_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_KPI_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateClientKPIAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_KPI_CREATE_RESET });
};

// List details
export const listClientKPIDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_KPI_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientKPI(id, token);

      dispatch({
        type: CLIENT_KPI_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_KPI_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListClientKPIDetailsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_KPI_DETAILS_RESET });
};

// Update
export const updateClientKPIAction =
  (id, kpi) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_KPI_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateClientKPI(id, token, kpi);

      dispatch({
        type: CLIENT_KPI_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_KPI_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientKPIAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_KPI_UPDATE_RESET });
};

// Delete
export const deleteClientKPIAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_KPI_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteClientKPI(id, token);

    dispatch({
      type: CLIENT_KPI_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_KPI_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteClientKPIAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_KPI_DELETE_RESET });
};
