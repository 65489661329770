// Constant imports
import {
  MARKET_CASE_STUDY_LIST_REQUEST,
  MARKET_CASE_STUDY_LIST_SUCCESS,
  MARKET_CASE_STUDY_LIST_FAIL,
  MARKET_CASE_STUDY_LIST_RESET,
  MARKET_CASE_STUDY_CREATE_REQUEST,
  MARKET_CASE_STUDY_CREATE_SUCCESS,
  MARKET_CASE_STUDY_CREATE_FAIL,
  MARKET_CASE_STUDY_CREATE_RESET,
  MARKET_CASE_STUDY_DETAILS_REQUEST,
  MARKET_CASE_STUDY_DETAILS_SUCCESS,
  MARKET_CASE_STUDY_DETAILS_FAIL,
  MARKET_CASE_STUDY_DETAILS_RESET,
  MARKET_CASE_STUDY_UPDATE_REQUEST,
  MARKET_CASE_STUDY_UPDATE_SUCCESS,
  MARKET_CASE_STUDY_UPDATE_FAIL,
  MARKET_CASE_STUDY_UPDATE_RESET,
  MARKET_CASE_STUDY_DELETE_REQUEST,
  MARKET_CASE_STUDY_DELETE_SUCCESS,
  MARKET_CASE_STUDY_DELETE_FAIL,
  MARKET_CASE_STUDY_DELETE_RESET,
} from "../constants/marketCaseStudyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listMarketCaseStudiesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_CASE_STUDY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketCaseStudies(
        params,
        token
      );

      dispatch({
        type: MARKET_CASE_STUDY_LIST_SUCCESS,

        payload: {
          count: data.count,
          market_case_studies: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: MARKET_CASE_STUDY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListMarketCaseStudiesAction = () => async (dispatch) => {
  dispatch({ type: MARKET_CASE_STUDY_LIST_RESET });
};

// Create
export const createMarketCaseStudyAction =
  (market_case_study) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_CASE_STUDY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createMarketCaseStudy(
        token,
        market_case_study
      );

      dispatch({
        type: MARKET_CASE_STUDY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_CASE_STUDY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateMarketCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: MARKET_CASE_STUDY_CREATE_RESET });
};

// List details
export const listMarketCaseStudyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_CASE_STUDY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getMarketCaseStudy(id, token);

      dispatch({
        type: MARKET_CASE_STUDY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_CASE_STUDY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListMarketCaseStudyDetailsAction = () => async (dispatch) => {
  dispatch({ type: MARKET_CASE_STUDY_DETAILS_RESET });
};

// Update
export const updateMarketCaseStudyAction =
  (id, market_case_study) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_CASE_STUDY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateMarketCaseStudy(
        id,
        token,
        market_case_study
      );

      dispatch({
        type: MARKET_CASE_STUDY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_CASE_STUDY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateMarketCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: MARKET_CASE_STUDY_UPDATE_RESET });
};

// Delete
export const deleteMarketCaseStudyAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: MARKET_CASE_STUDY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteMarketCaseStudy(id, token);

      dispatch({
        type: MARKET_CASE_STUDY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MARKET_CASE_STUDY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteMarketCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: MARKET_CASE_STUDY_DELETE_RESET });
};
