export const PROPOSAL_SERVICE_DELIVERABLE_LIST_REQUEST =
  "PROPOSAL_SERVICE_DELIVERABLE_LIST_REQUEST";
export const PROPOSAL_SERVICE_DELIVERABLE_LIST_SUCCESS =
  "PROPOSAL_SERVICE_DELIVERABLE_LIST_SUCCESS";
export const PROPOSAL_SERVICE_DELIVERABLE_LIST_FAIL =
  "PROPOSAL_SERVICE_DELIVERABLE_LIST_FAIL";
export const PROPOSAL_SERVICE_DELIVERABLE_LIST_RESET =
  "PROPOSAL_SERVICE_DELIVERABLE_LIST_RESET";

export const PROPOSAL_SERVICE_DELIVERABLE_CREATE_REQUEST =
  "PROPOSAL_SERVICE_DELIVERABLE_CREATE_REQUEST";
export const PROPOSAL_SERVICE_DELIVERABLE_CREATE_SUCCESS =
  "PROPOSAL_SERVICE_DELIVERABLE_CREATE_SUCCESS";
export const PROPOSAL_SERVICE_DELIVERABLE_CREATE_FAIL =
  "PROPOSAL_SERVICE_DELIVERABLE_CREATE_FAIL";
export const PROPOSAL_SERVICE_DELIVERABLE_CREATE_RESET =
  "PROPOSAL_SERVICE_DELIVERABLE_CREATE_RESET";

export const PROPOSAL_SERVICE_DELIVERABLE_DETAILS_REQUEST =
  "PROPOSAL_SERVICE_DELIVERABLE_DETAILS_REQUEST";
export const PROPOSAL_SERVICE_DELIVERABLE_DETAILS_SUCCESS =
  "PROPOSAL_SERVICE_DELIVERABLE_DETAILS_SUCCESS";
export const PROPOSAL_SERVICE_DELIVERABLE_DETAILS_FAIL =
  "PROPOSAL_SERVICE_DELIVERABLE_DETAILS_FAIL";
export const PROPOSAL_SERVICE_DELIVERABLE_DETAILS_RESET =
  "PROPOSAL_SERVICE_DELIVERABLE_DETAILS_RESET";

export const PROPOSAL_SERVICE_DELIVERABLE_UPDATE_REQUEST =
  "PROPOSAL_SERVICE_DELIVERABLE_UPDATE_REQUEST";
export const PROPOSAL_SERVICE_DELIVERABLE_UPDATE_SUCCESS =
  "PROPOSAL_SERVICE_DELIVERABLE_UPDATE_SUCCESS";
export const PROPOSAL_SERVICE_DELIVERABLE_UPDATE_FAIL =
  "PROPOSAL_SERVICE_DELIVERABLE_UPDATE_FAIL";
export const PROPOSAL_SERVICE_DELIVERABLE_UPDATE_RESET =
  "PROPOSAL_SERVICE_DELIVERABLE_UPDATE_RESET";

export const PROPOSAL_SERVICE_DELIVERABLE_DELETE_REQUEST =
  "PROPOSAL_SERVICE_DELIVERABLE_DELETE_REQUEST";
export const PROPOSAL_SERVICE_DELIVERABLE_DELETE_SUCCESS =
  "PROPOSAL_SERVICE_DELIVERABLE_DELETE_SUCCESS";
export const PROPOSAL_SERVICE_DELIVERABLE_DELETE_FAIL =
  "PROPOSAL_SERVICE_DELIVERABLE_DELETE_FAIL";
export const PROPOSAL_SERVICE_DELIVERABLE_DELETE_RESET =
  "PROPOSAL_SERVICE_DELIVERABLE_DELETE_RESET";
