// Library imports
import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Main function
function FormDrawerHelpContainer({ form, module, view }) {
  // Redux state variables
  // Help content
  const helpContentList = useSelector((state) => state.helpContentList);

  // Load help content
  const [data, setData] = useState([]);

  useEffect(() => {
    if (helpContentList.success) {
      setData(helpContentList.help_content.results);
    }
    // eslint-disable-next-line
  }, [helpContentList.success]);

  // JSX UI code
  return (
    <div style={{ paddingBottom: "10px" }}>
      {data
        .filter(
          (result) =>
            result.module === module &&
            result.view === view &&
            result.form === form
        )
        .map((result, index) => {
          return <div key={index}>{parse(result.text)}</div>;
        })}
    </div>
  );
}

export default FormDrawerHelpContainer;
