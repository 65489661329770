export const TIMESHEET_LIST_REQUEST = "TIMESHEET_LIST_REQUEST";
export const TIMESHEET_LIST_SUCCESS = "TIMESHEET_LIST_SUCCESS";
export const TIMESHEET_LIST_FAIL = "TIMESHEET_LIST_FAIL";
export const TIMESHEET_LIST_RESET = "TIMESHEET_LIST_RESET";

export const TIMESHEET_CREATE_REQUEST = "TIMESHEET_CREATE_REQUEST";
export const TIMESHEET_CREATE_SUCCESS = "TIMESHEET_CREATE_SUCCESS";
export const TIMESHEET_CREATE_FAIL = "TIMESHEET_CREATE_FAIL";
export const TIMESHEET_CREATE_RESET = "TIMESHEET_CREATE_RESET";

export const TIMESHEET_DETAILS_REQUEST = "TIMESHEET_DETAILS_REQUEST";
export const TIMESHEET_DETAILS_SUCCESS = "TIMESHEET_DETAILS_SUCCESS";
export const TIMESHEET_DETAILS_FAIL = "TIMESHEET_DETAILS_FAIL";
export const TIMESHEET_DETAILS_RESET = "TIMESHEET_DETAILS_RESET";

export const TIMESHEET_UPDATE_REQUEST = "TIMESHEET_UPDATE_REQUEST";
export const TIMESHEET_UPDATE_SUCCESS = "TIMESHEET_UPDATE_SUCCESS";
export const TIMESHEET_UPDATE_FAIL = "TIMESHEET_UPDATE_FAIL";
export const TIMESHEET_UPDATE_RESET = "TIMESHEET_UPDATE_RESET";

export const TIMESHEET_DELETE_REQUEST = "TIMESHEET_DELETE_REQUEST";
export const TIMESHEET_DELETE_SUCCESS = "TIMESHEET_DELETE_SUCCESS";
export const TIMESHEET_DELETE_FAIL = "TIMESHEET_DELETE_FAIL";
export const TIMESHEET_DELETE_RESET = "TIMESHEET_DELETE_RESET";

export const TIMESHEET_DOWNLOAD_REQUEST = "TIMESHEET_DOWNLOAD_REQUEST";
export const TIMESHEET_DOWNLOAD_SUCCESS = "TIMESHEET_DOWNLOAD_SUCCESS";
export const TIMESHEET_DOWNLOAD_FAIL = "TIMESHEET_DOWNLOAD_FAIL";
export const TIMESHEET_DOWNLOAD_RESET = "TIMESHEET_DOWNLOAD_RESET";

export const TIMESHEET_DUPLICATE_REQUEST = "TIMESHEET_DUPLICATE_REQUEST";
export const TIMESHEET_DUPLICATE_SUCCESS = "TIMESHEET_DUPLICATE_SUCCESS";
export const TIMESHEET_DUPLICATE_FAIL = "TIMESHEET_DUPLICATE_FAIL";
export const TIMESHEET_DUPLICATE_RESET = "TIMESHEET_DUPLICATE_RESET";

export const TIMESHEET_REVERSE_REQUEST = "TIMESHEET_REVERSE_REQUEST";
export const TIMESHEET_REVERSE_SUCCESS = "TIMESHEET_REVERSE_SUCCESS";
export const TIMESHEET_REVERSE_FAIL = "TIMESHEET_REVERSE_FAIL";
export const TIMESHEET_REVERSE_RESET = "TIMESHEET_REVERSE_RESET";
