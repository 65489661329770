export const INVOICE_DOCUMENT_DETAILS_REQUEST =
  "INVOICE_DOCUMENT_DETAILS_REQUEST";
export const INVOICE_DOCUMENT_DETAILS_SUCCESS =
  "INVOICE_DOCUMENT_DETAILS_SUCCESS";
export const INVOICE_DOCUMENT_DETAILS_FAIL = "INVOICE_DOCUMENT_DETAILS_FAIL";
export const INVOICE_DOCUMENT_DETAILS_RESET = "INVOICE_DOCUMENT_DETAILS_RESET";

export const INVOICE_DOCUMENT_UPDATE_REQUEST =
  "INVOICE_DOCUMENT_UPDATE_REQUEST";
export const INVOICE_DOCUMENT_UPDATE_SUCCESS =
  "INVOICE_DOCUMENT_UPDATE_SUCCESS";
export const INVOICE_DOCUMENT_UPDATE_FAIL = "INVOICE_DOCUMENT_UPDATE_FAIL";
export const INVOICE_DOCUMENT_UPDATE_RESET = "INVOICE_DOCUMENT_UPDATE_RESET";
