// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function ClientProposalPipelineCard({ fill, clientList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Pipeline</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total value of active proposals for the selected client(s). These
            are proposals that are being qualified or worked on, or that have
            been submitted but are awaiting an outcome.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {clientList.totalValueOfProposalsQualifying >
                2 *
                  (clientList.totalValueOfProposalsInProgress +
                    clientList.totalValueOfProposalsSubmitted) ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The shape of the pipeline looks good. Continue to engage
                      with the selected client(s), while working on existing
                      opportunities.
                    </div>
                  </div>
                ) : clientList.totalValueOfProposalsQualifying >
                  clientList.totalValueOfProposalsInProgress +
                    clientList.totalValueOfProposalsSubmitted ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The pipeline is too flat. It may be necessary to strike a
                      balance between delivery and account management efforts to
                      ensure a consistent flow of opportunities.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is at risk of running out of work from the
                      selected client(s). To address this issue, it may be
                      necessary to allocate more resources to account management.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Qualifying",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: clientList.totalValueOfProposalsQualifying,
        },
        {
          name: "In-progress",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: clientList.totalValueOfProposalsInProgress,
        },
        {
          name: "Submitted",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: clientList.totalValueOfProposalsSubmitted,
        },
      ]}
      fill={fill}
      noData={
        !clientList.totalValueOfProposalsInProgress &&
        !clientList.totalValueOfProposalsQualifying &&
        !clientList.totalValueOfProposalsSubmitted
      }
      title="Pipeline"
      units={activeOrganisation.currency}
    />
  );
}

export default ClientProposalPipelineCard;
