// Library imports
import { Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TableLink({ name, sameTab, url }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ maxWidth: "calc(100% - 50px)" }}>
      {url ? (
        <a
          href={url}
          rel="noopener noreferrer"
          style={{
            color: lightMode ? "#1891AC" : "#D2ECF9",
          }}
          target={sameTab ? undefined : "_blank"}
        >
          <Text ellipsize>{name ? name : url}</Text>
        </a>
      ) : (
        "-"
      )}
    </div>
  );
}

export default TableLink;
