// Constant imports
import {
  PROPOSAL_CONTACT_LIST_REQUEST,
  PROPOSAL_CONTACT_LIST_SUCCESS,
  PROPOSAL_CONTACT_LIST_FAIL,
  PROPOSAL_CONTACT_LIST_RESET,
  PROPOSAL_CONTACT_CREATE_REQUEST,
  PROPOSAL_CONTACT_CREATE_SUCCESS,
  PROPOSAL_CONTACT_CREATE_FAIL,
  PROPOSAL_CONTACT_CREATE_RESET,
  PROPOSAL_CONTACT_DETAILS_REQUEST,
  PROPOSAL_CONTACT_DETAILS_SUCCESS,
  PROPOSAL_CONTACT_DETAILS_FAIL,
  PROPOSAL_CONTACT_DETAILS_RESET,
  PROPOSAL_CONTACT_UPDATE_REQUEST,
  PROPOSAL_CONTACT_UPDATE_SUCCESS,
  PROPOSAL_CONTACT_UPDATE_FAIL,
  PROPOSAL_CONTACT_UPDATE_RESET,
  PROPOSAL_CONTACT_DELETE_REQUEST,
  PROPOSAL_CONTACT_DELETE_SUCCESS,
  PROPOSAL_CONTACT_DELETE_FAIL,
  PROPOSAL_CONTACT_DELETE_RESET,
} from "../constants/proposalContactConstants";

// List all
export const proposalContactListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_contacts: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CONTACT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_contacts: action.payload.proposal_contacts,
        success: true,
        error: "",
      };

    case PROPOSAL_CONTACT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_contacts: [],
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CONTACT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_contacts: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalContactCreateReducer = (
  state = { loading: false, proposal_contact: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CONTACT_CREATE_REQUEST:
      return {
        loading: true,
        proposal_contact: state.proposal_contact,
        success: false,
        error: "",
      };

    case PROPOSAL_CONTACT_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_contact: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CONTACT_CREATE_FAIL:
      return {
        loading: false,
        proposal_contact: state.proposal_contact,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CONTACT_CREATE_RESET:
      return {
        loading: false,
        proposal_contact: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalContactDetailsReducer = (
  state = { loading: false, proposal_contact: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CONTACT_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_contact: state.proposal_contact,
        success: false,
        error: "",
      };

    case PROPOSAL_CONTACT_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_contact: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CONTACT_DETAILS_FAIL:
      return {
        loading: false,
        proposal_contact: state.proposal_contact,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CONTACT_DETAILS_RESET:
      return {
        loading: false,
        proposal_contact: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalContactUpdateReducer = (
  state = { loading: false, proposal_contact: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CONTACT_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_contact: state.proposal_contact,
        success: false,
        error: "",
      };

    case PROPOSAL_CONTACT_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_contact: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_CONTACT_UPDATE_FAIL:
      return {
        loading: false,
        proposal_contact: state.proposal_contact,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CONTACT_UPDATE_RESET:
      return {
        loading: false,
        proposal_contact: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalContactDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_CONTACT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_CONTACT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_CONTACT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_CONTACT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
