// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function ContactApplicationInfo({ additionalInfo, application }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <H5>{application.opening_name}</H5>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Status</strong>
      </p>

      <p>
        {
          <Tag
            intent={
              application.candidate_data.status === "Pending"
                ? "primary"
                : application.candidate_data.status === "Rejected"
                ? "danger"
                : "success"
            }
            large
          >
            {application.candidate_data.status}
          </Tag>
        }
      </p>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Notes</strong>
      </p>

      <p>
        {application.candidate_data.notes
          ? application.candidate_data.notes
          : "-"}
      </p>

      {additionalInfo}
    </div>
  );
}

export default ContactApplicationInfo;
