// Constants import
import { PAGE_SIZE } from "../constants/applicationConstants";

// Library imports
import {
  Classes,
  Colors,
  Button,
  ButtonGroup,
  NonIdealState,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function ItemCardList({
  content,
  count,
  darkColor,
  fill,
  lightColor,
  loading,
  noMargin,
  noResultsIcon,
  noResultsMessage,
  noResultsTitle,
  page,
  setPageObject,
  setPageVariable,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        maxWidth: fill ? "100%" : count === 0 ? undefined : "fit-content",
      }}
    >
      {count !== 0 && (
        <div
          style={{
            background: lightMode
              ? lightColor
                ? lightColor
                : Colors.LIGHT_GRAY3
              : darkColor
              ? darkColor
              : Colors.DARK_GRAY5,
            margin: noMargin ? "0" : "20px 20px 0 20px",
            padding: "10px 10px 0 10px",
          }}
        >
          {/* Content */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {content}
          </div>

          {/* Buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              padding: "0 0 10px 0",
            }}
          >
            <ButtonGroup minimal>
              {/* Previous page */}
              <Button
                disabled={page === 1 ? true : false}
                onClick={() => {
                  setPageObject((prevState) => {
                    return { ...prevState, [setPageVariable]: page - 1 };
                  });
                }}
              >
                {<small>Previous page</small>}
              </Button>

              {/* Next page */}
              <Button
                disabled={page * PAGE_SIZE < Number(count) ? false : true}
                onClick={() => {
                  setPageObject((prevState) => {
                    return { ...prevState, [setPageVariable]: page + 1 };
                  });
                }}
              >
                {<small>Next page</small>}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}

      {/* Loading */}
      {count === 0 && loading && (
        <div
          className={Classes.SKELETON}
          style={{
            margin: "30px 0 30px 0",
            width: "100%",
          }}
        >
          XXXXXXXXXX
        </div>
      )}

      {/* No results */}
      {count === 0 && !loading && (
        <div style={{ margin: "60px 0 60px 0" }}>
          <NonIdealState
            description={noResultsMessage ? noResultsMessage : "Add records."}
            icon={noResultsIcon ? noResultsIcon : "search"}
            title={noResultsTitle ? noResultsTitle : "No records found"}
          />
        </div>
      )}
    </div>
  );
}

export default ItemCardList;
