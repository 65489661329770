// Constant imports
import {
  CLIENT_PERMISSION_LIST_REQUEST,
  CLIENT_PERMISSION_LIST_SUCCESS,
  CLIENT_PERMISSION_LIST_FAIL,
  CLIENT_PERMISSION_LIST_RESET,
  CLIENT_PERMISSION_CREATE_REQUEST,
  CLIENT_PERMISSION_CREATE_SUCCESS,
  CLIENT_PERMISSION_CREATE_FAIL,
  CLIENT_PERMISSION_CREATE_RESET,
  CLIENT_PERMISSION_DETAILS_REQUEST,
  CLIENT_PERMISSION_DETAILS_SUCCESS,
  CLIENT_PERMISSION_DETAILS_FAIL,
  CLIENT_PERMISSION_DETAILS_RESET,
  CLIENT_PERMISSION_UPDATE_REQUEST,
  CLIENT_PERMISSION_UPDATE_SUCCESS,
  CLIENT_PERMISSION_UPDATE_FAIL,
  CLIENT_PERMISSION_UPDATE_RESET,
  CLIENT_PERMISSION_DELETE_REQUEST,
  CLIENT_PERMISSION_DELETE_SUCCESS,
  CLIENT_PERMISSION_DELETE_FAIL,
  CLIENT_PERMISSION_DELETE_RESET,
} from "../constants/clientPermissionConstants";

// List all
export const clientPermissionListReducer = (
  state = {
    loading: false,
    count: 0,
    client_permissions: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_PERMISSION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        client_permissions: action.payload.client_permissions,
        success: true,
        error: "",
      };

    case CLIENT_PERMISSION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        client_permissions: [],
        success: false,
        error: action.payload,
      };

    case CLIENT_PERMISSION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        client_permissions: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientPermissionCreateReducer = (
  state = {
    loading: false,
    client_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_PERMISSION_CREATE_REQUEST:
      return {
        loading: true,
        client_permission: state.client_permission,
        success: false,
        error: "",
      };

    case CLIENT_PERMISSION_CREATE_SUCCESS:
      return {
        loading: false,
        client_permission: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_PERMISSION_CREATE_FAIL:
      return {
        loading: false,
        client_permission: state.client_permission,
        success: false,
        error: action.payload,
      };

    case CLIENT_PERMISSION_CREATE_RESET:
      return {
        loading: false,
        client_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const clientPermissionDetailsReducer = (
  state = {
    loading: false,
    client_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_PERMISSION_DETAILS_REQUEST:
      return {
        loading: true,
        client_permission: state.client_permission,
        success: false,
        error: "",
      };

    case CLIENT_PERMISSION_DETAILS_SUCCESS:
      return {
        loading: false,
        client_permission: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_PERMISSION_DETAILS_FAIL:
      return {
        loading: false,
        client_permission: state.client_permission,
        success: false,
        error: action.payload,
      };

    case CLIENT_PERMISSION_DETAILS_RESET:
      return {
        loading: false,
        client_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const clientPermissionUpdateReducer = (
  state = {
    loading: false,
    client_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_PERMISSION_UPDATE_REQUEST:
      return {
        loading: true,
        client_permission: state.client_permission,
        success: false,
        error: "",
      };

    case CLIENT_PERMISSION_UPDATE_SUCCESS:
      return {
        loading: false,
        client_permission: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_PERMISSION_UPDATE_FAIL:
      return {
        loading: false,
        client_permission: state.client_permission,
        success: false,
        error: action.payload,
      };

    case CLIENT_PERMISSION_UPDATE_RESET:
      return {
        loading: false,
        client_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const clientPermissionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_PERMISSION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_PERMISSION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_PERMISSION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_PERMISSION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
