// Constant imports
import {
  PROPOSAL_LESSON_LIST_REQUEST,
  PROPOSAL_LESSON_LIST_SUCCESS,
  PROPOSAL_LESSON_LIST_FAIL,
  PROPOSAL_LESSON_LIST_RESET,
  PROPOSAL_LESSON_CREATE_REQUEST,
  PROPOSAL_LESSON_CREATE_SUCCESS,
  PROPOSAL_LESSON_CREATE_FAIL,
  PROPOSAL_LESSON_CREATE_RESET,
  PROPOSAL_LESSON_DETAILS_REQUEST,
  PROPOSAL_LESSON_DETAILS_SUCCESS,
  PROPOSAL_LESSON_DETAILS_FAIL,
  PROPOSAL_LESSON_DETAILS_RESET,
  PROPOSAL_LESSON_UPDATE_REQUEST,
  PROPOSAL_LESSON_UPDATE_SUCCESS,
  PROPOSAL_LESSON_UPDATE_FAIL,
  PROPOSAL_LESSON_UPDATE_RESET,
  PROPOSAL_LESSON_DELETE_REQUEST,
  PROPOSAL_LESSON_DELETE_SUCCESS,
  PROPOSAL_LESSON_DELETE_FAIL,
  PROPOSAL_LESSON_DELETE_RESET,
} from "../constants/proposalLessonConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalLessonsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LESSON_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalLessons(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_LESSON_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_lessons: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LESSON_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalLessonsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LESSON_LIST_RESET });
};

// Create
export const createProposalLessonAction =
  (proposal_lesson) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LESSON_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalLesson(
        token,
        proposal_lesson
      );

      dispatch({
        type: PROPOSAL_LESSON_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LESSON_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalLessonAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LESSON_CREATE_RESET });
};

// List details
export const listProposalLessonDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LESSON_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalLesson(id, token);

      dispatch({
        type: PROPOSAL_LESSON_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LESSON_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalLessonDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LESSON_DETAILS_RESET });
};

// Update
export const updateProposalLessonAction =
  (id, proposal_lesson) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LESSON_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalLesson(
        id,
        token,
        proposal_lesson
      );

      dispatch({
        type: PROPOSAL_LESSON_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LESSON_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalLessonAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LESSON_UPDATE_RESET });
};

// Delete
export const deleteProposalLessonAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_LESSON_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalLesson(id, token);

      dispatch({
        type: PROPOSAL_LESSON_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_LESSON_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalLessonAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_LESSON_DELETE_RESET });
};
