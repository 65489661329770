// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  APPROVAL_REQUEST_LIST_REQUEST,
  APPROVAL_REQUEST_LIST_SUCCESS,
  APPROVAL_REQUEST_LIST_FAIL,
  APPROVAL_REQUEST_LIST_RESET,
  APPROVAL_REQUEST_CREATE_REQUEST,
  APPROVAL_REQUEST_CREATE_SUCCESS,
  APPROVAL_REQUEST_CREATE_FAIL,
  APPROVAL_REQUEST_CREATE_RESET,
  APPROVAL_REQUEST_DETAILS_REQUEST,
  APPROVAL_REQUEST_DETAILS_SUCCESS,
  APPROVAL_REQUEST_DETAILS_FAIL,
  APPROVAL_REQUEST_DETAILS_RESET,
  APPROVAL_REQUEST_UPDATE_REQUEST,
  APPROVAL_REQUEST_UPDATE_SUCCESS,
  APPROVAL_REQUEST_UPDATE_FAIL,
  APPROVAL_REQUEST_UPDATE_RESET,
  APPROVAL_REQUEST_DELETE_REQUEST,
  APPROVAL_REQUEST_DELETE_SUCCESS,
  APPROVAL_REQUEST_DELETE_FAIL,
  APPROVAL_REQUEST_DELETE_RESET,
  APPROVAL_REQUEST_DOWNLOAD_REQUEST,
  APPROVAL_REQUEST_DOWNLOAD_SUCCESS,
  APPROVAL_REQUEST_DOWNLOAD_FAIL,
  APPROVAL_REQUEST_DOWNLOAD_RESET,
} from "../constants/approvalRequestConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listApprovalRequestsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_REQUEST_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getApprovalRequests(
        params,
        token
      );

      dispatch({
        type: APPROVAL_REQUEST_LIST_SUCCESS,

        payload: {
          count: data.count,
          approval_requests: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_REQUEST_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListApprovalRequestsAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_REQUEST_LIST_RESET });
};

// Create
export const createApprovalRequestAction =
  (approvalRequest) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_REQUEST_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createApprovalRequest(
        token,
        approvalRequest
      );

      dispatch({
        type: APPROVAL_REQUEST_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_REQUEST_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateApprovalRequestAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_REQUEST_CREATE_RESET });
};

// List details
export const listApprovalRequestDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_REQUEST_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getApprovalRequest(id, token);

      dispatch({
        type: APPROVAL_REQUEST_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_REQUEST_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListApprovalRequestDetailsAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_REQUEST_DETAILS_RESET });
};

// Update
export const updateApprovalRequestAction =
  (id, approvalRequest) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_REQUEST_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateApprovalRequest(
        id,
        token,
        approvalRequest
      );

      dispatch({
        type: APPROVAL_REQUEST_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_REQUEST_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateApprovalRequestAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_REQUEST_UPDATE_RESET });
};

// Delete
export const deleteApprovalRequestAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_REQUEST_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteApprovalRequest(id, token);

      dispatch({
        type: APPROVAL_REQUEST_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_REQUEST_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteApprovalRequestAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_REQUEST_DELETE_RESET });
};

// Download
export const downloadApprovalRequestsAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: APPROVAL_REQUEST_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var approvalRequests = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getApprovalRequests(
          { organisation: organisation, page: page },
          token
        );

        approvalRequests = approvalRequests.concat(data.results);
      }

      dispatch({
        type: APPROVAL_REQUEST_DOWNLOAD_SUCCESS,

        payload: {
          approval_requests: approvalRequests,
        },
      });
    } catch (error) {
      dispatch({
        type: APPROVAL_REQUEST_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadApprovalRequestsAction = () => async (dispatch) => {
  dispatch({ type: APPROVAL_REQUEST_DOWNLOAD_RESET });
};
