// Constant imports
import {
  ENGAGEMENT_LESSON_LIST_REQUEST,
  ENGAGEMENT_LESSON_LIST_SUCCESS,
  ENGAGEMENT_LESSON_LIST_FAIL,
  ENGAGEMENT_LESSON_LIST_RESET,
  ENGAGEMENT_LESSON_CREATE_REQUEST,
  ENGAGEMENT_LESSON_CREATE_SUCCESS,
  ENGAGEMENT_LESSON_CREATE_FAIL,
  ENGAGEMENT_LESSON_CREATE_RESET,
  ENGAGEMENT_LESSON_DETAILS_REQUEST,
  ENGAGEMENT_LESSON_DETAILS_SUCCESS,
  ENGAGEMENT_LESSON_DETAILS_FAIL,
  ENGAGEMENT_LESSON_DETAILS_RESET,
  ENGAGEMENT_LESSON_UPDATE_REQUEST,
  ENGAGEMENT_LESSON_UPDATE_SUCCESS,
  ENGAGEMENT_LESSON_UPDATE_FAIL,
  ENGAGEMENT_LESSON_UPDATE_RESET,
  ENGAGEMENT_LESSON_DELETE_REQUEST,
  ENGAGEMENT_LESSON_DELETE_SUCCESS,
  ENGAGEMENT_LESSON_DELETE_FAIL,
  ENGAGEMENT_LESSON_DELETE_RESET,
} from "../constants/engagementLessonConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementLessonsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LESSON_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementLessons(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_LESSON_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_lessons: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LESSON_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementLessonsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_LESSON_LIST_RESET });
};

// Create
export const createEngagementLessonAction =
  (engagement_lesson) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LESSON_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementLesson(
        token,
        engagement_lesson
      );

      dispatch({
        type: ENGAGEMENT_LESSON_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LESSON_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementLessonAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_LESSON_CREATE_RESET });
};

// List details
export const listEngagementLessonDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LESSON_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementLesson(id, token);

      dispatch({
        type: ENGAGEMENT_LESSON_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LESSON_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementLessonDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_LESSON_DETAILS_RESET });
  };

// Update
export const updateEngagementLessonAction =
  (id, engagement_lesson) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LESSON_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementLesson(
        id,
        token,
        engagement_lesson
      );

      dispatch({
        type: ENGAGEMENT_LESSON_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LESSON_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementLessonAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_LESSON_UPDATE_RESET });
};

// Delete
export const deleteEngagementLessonAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_LESSON_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementLesson(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_LESSON_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_LESSON_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementLessonAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_LESSON_DELETE_RESET });
};
