// Constant imports
import {
  INVOICE_BOOKING_LIST_REQUEST,
  INVOICE_BOOKING_LIST_SUCCESS,
  INVOICE_BOOKING_LIST_FAIL,
  INVOICE_BOOKING_LIST_RESET,
  INVOICE_BOOKING_DETAILS_REQUEST,
  INVOICE_BOOKING_DETAILS_SUCCESS,
  INVOICE_BOOKING_DETAILS_FAIL,
  INVOICE_BOOKING_DETAILS_RESET,
  INVOICE_BOOKING_UPDATE_REQUEST,
  INVOICE_BOOKING_UPDATE_SUCCESS,
  INVOICE_BOOKING_UPDATE_FAIL,
  INVOICE_BOOKING_UPDATE_RESET,
  INVOICE_BOOKING_DELETE_REQUEST,
  INVOICE_BOOKING_DELETE_SUCCESS,
  INVOICE_BOOKING_DELETE_FAIL,
  INVOICE_BOOKING_DELETE_RESET,
} from "../constants/invoiceBookingConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInvoiceBookingsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_BOOKING_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceBookings(
        params,
        token
      );

      dispatch({
        type: INVOICE_BOOKING_LIST_SUCCESS,

        payload: {
          totalNet: data.total_net.result,
          totalNetQuote: data.total_net_quote.result,
          totalTax: data.total_tax.result,
          totalValue: data.total_value.result,
          count: data.count,
          invoice_bookings: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INVOICE_BOOKING_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInvoiceBookingsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_BOOKING_LIST_RESET });
};

// List details
export const listInvoiceBookingDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_BOOKING_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceBooking(id, token);

      dispatch({
        type: INVOICE_BOOKING_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_BOOKING_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInvoiceBookingDetailsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_BOOKING_DETAILS_RESET });
};

// Update
export const updateInvoiceBookingAction =
  (id, invoice_booking) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_BOOKING_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInvoiceBooking(
        id,
        token,
        invoice_booking
      );

      dispatch({
        type: INVOICE_BOOKING_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_BOOKING_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInvoiceBookingAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_BOOKING_UPDATE_RESET });
};

// Delete
export const deleteInvoiceBookingAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_BOOKING_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteInvoiceBooking(id, token);

      dispatch({
        type: INVOICE_BOOKING_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_BOOKING_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteInvoiceBookingAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_BOOKING_DELETE_RESET });
};
