// Constant imports
import {
  CLIENT_KPI_LIST_REQUEST,
  CLIENT_KPI_LIST_SUCCESS,
  CLIENT_KPI_LIST_FAIL,
  CLIENT_KPI_LIST_RESET,
  CLIENT_KPI_CREATE_REQUEST,
  CLIENT_KPI_CREATE_SUCCESS,
  CLIENT_KPI_CREATE_FAIL,
  CLIENT_KPI_CREATE_RESET,
  CLIENT_KPI_DETAILS_REQUEST,
  CLIENT_KPI_DETAILS_SUCCESS,
  CLIENT_KPI_DETAILS_FAIL,
  CLIENT_KPI_DETAILS_RESET,
  CLIENT_KPI_UPDATE_REQUEST,
  CLIENT_KPI_UPDATE_SUCCESS,
  CLIENT_KPI_UPDATE_FAIL,
  CLIENT_KPI_UPDATE_RESET,
  CLIENT_KPI_DELETE_REQUEST,
  CLIENT_KPI_DELETE_SUCCESS,
  CLIENT_KPI_DELETE_FAIL,
  CLIENT_KPI_DELETE_RESET,
} from "../constants/clientKPIConstants";

// List all
export const clientKPIListReducer = (
  state = {
    loading: false,
    count: 0,
    client_kpis: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_KPI_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_KPI_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        client_kpis: action.payload.client_kpis,
        success: true,
        error: "",
      };

    case CLIENT_KPI_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        client_kpis: [],
        success: false,
        error: action.payload,
      };

    case CLIENT_KPI_LIST_RESET:
      return {
        loading: false,
        count: 0,
        client_kpis: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientKPICreateReducer = (
  state = { loading: false, client_kpi: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_KPI_CREATE_REQUEST:
      return {
        loading: true,
        client_kpi: state.client_kpi,
        success: false,
        error: "",
      };

    case CLIENT_KPI_CREATE_SUCCESS:
      return {
        loading: false,
        client_kpi: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_KPI_CREATE_FAIL:
      return {
        loading: false,
        client_kpi: state.client_kpi,
        success: false,
        error: action.payload,
      };

    case CLIENT_KPI_CREATE_RESET:
      return { loading: false, client_kpi: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const clientKPIDetailsReducer = (
  state = { loading: false, client_kpi: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_KPI_DETAILS_REQUEST:
      return {
        loading: true,
        client_kpi: state.client_kpi,
        success: false,
        error: "",
      };

    case CLIENT_KPI_DETAILS_SUCCESS:
      return {
        loading: false,
        client_kpi: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_KPI_DETAILS_FAIL:
      return {
        loading: false,
        client_kpi: state.client_kpi,
        success: false,
        error: action.payload,
      };

    case CLIENT_KPI_DETAILS_RESET:
      return { loading: false, client_kpi: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const clientKPIUpdateReducer = (
  state = { loading: false, client_kpi: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_KPI_UPDATE_REQUEST:
      return {
        loading: true,
        client_kpi: state.client_kpi,
        success: false,
        error: "",
      };

    case CLIENT_KPI_UPDATE_SUCCESS:
      return {
        loading: false,
        client_kpi: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_KPI_UPDATE_FAIL:
      return {
        loading: false,
        client_kpi: state.client_kpi,
        success: false,
        error: action.payload,
      };

    case CLIENT_KPI_UPDATE_RESET:
      return { loading: false, client_kpi: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const clientKPIDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_KPI_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_KPI_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_KPI_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_KPI_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
