export const ROLE_PERMISSION_LIST_REQUEST = "ROLE_PERMISSION_LIST_REQUEST";
export const ROLE_PERMISSION_LIST_SUCCESS = "ROLE_PERMISSION_LIST_SUCCESS";
export const ROLE_PERMISSION_LIST_FAIL = "ROLE_PERMISSION_LIST_FAIL";
export const ROLE_PERMISSION_LIST_RESET = "ROLE_PERMISSION_LIST_RESET";

export const ROLE_PERMISSION_CREATE_REQUEST = "ROLE_PERMISSION_CREATE_REQUEST";
export const ROLE_PERMISSION_CREATE_SUCCESS = "ROLE_PERMISSION_CREATE_SUCCESS";
export const ROLE_PERMISSION_CREATE_FAIL = "ROLE_PERMISSION_CREATE_FAIL";
export const ROLE_PERMISSION_CREATE_RESET = "ROLE_PERMISSION_CREATE_RESET";

export const ROLE_PERMISSION_DETAILS_REQUEST =
  "ROLE_PERMISSION_DETAILS_REQUEST";
export const ROLE_PERMISSION_DETAILS_SUCCESS =
  "ROLE_PERMISSION_DETAILS_SUCCESS";
export const ROLE_PERMISSION_DETAILS_FAIL = "ROLE_PERMISSION_DETAILS_FAIL";
export const ROLE_PERMISSION_DETAILS_RESET = "ROLE_PERMISSION_DETAILS_RESET";

export const ROLE_PERMISSION_UPDATE_REQUEST = "ROLE_PERMISSION_UPDATE_REQUEST";
export const ROLE_PERMISSION_UPDATE_SUCCESS = "ROLE_PERMISSION_UPDATE_SUCCESS";
export const ROLE_PERMISSION_UPDATE_FAIL = "ROLE_PERMISSION_UPDATE_FAIL";
export const ROLE_PERMISSION_UPDATE_RESET = "ROLE_PERMISSION_UPDATE_RESET";

export const ROLE_PERMISSION_DELETE_REQUEST = "ROLE_PERMISSION_DELETE_REQUEST";
export const ROLE_PERMISSION_DELETE_SUCCESS = "ROLE_PERMISSION_DELETE_SUCCESS";
export const ROLE_PERMISSION_DELETE_FAIL = "ROLE_PERMISSION_DELETE_FAIL";
export const ROLE_PERMISSION_DELETE_RESET = "ROLE_PERMISSION_DELETE_RESET";
