// Library imports
import { Classes, ProgressBar } from "@blueprintjs/core";
import React from "react";

// Main function
function TabSectionFieldProgressBar({ loading, intent, rating, width }) {
  // JSX UI code
  return (
    <div
      className={loading ? Classes.SKELETON : undefined}
      style={{
        margin: "5px 0 0 0",
        width: width ? width : "250px",
      }}
    >
      {loading ? (
        "XXXXXXXXXX"
      ) : (
        <ProgressBar
          animate={false}
          intent={
            intent
              ? intent
              : rating > 0.8
              ? "success"
              : rating > 0.4
              ? "warning"
              : "danger"
          }
          stripes={false}
          value={rating}
        />
      )}
    </div>
  );
}

export default TabSectionFieldProgressBar;
