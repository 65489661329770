export const CLIENT_LIST_REQUEST = "CLIENT_LIST_REQUEST";
export const CLIENT_LIST_SUCCESS = "CLIENT_LIST_SUCCESS";
export const CLIENT_LIST_FAIL = "CLIENT_LIST_FAIL";
export const CLIENT_LIST_RESET = "CLIENT_LIST_RESET";

export const CLIENT_CREATE_REQUEST = "CLIENT_CREATE_REQUEST";
export const CLIENT_CREATE_SUCCESS = "CLIENT_CREATE_SUCCESS";
export const CLIENT_CREATE_FAIL = "CLIENT_CREATE_FAIL";
export const CLIENT_CREATE_RESET = "CLIENT_CREATE_RESET";

export const CLIENT_DETAILS_REQUEST = "CLIENT_DETAILS_REQUEST";
export const CLIENT_DETAILS_SUCCESS = "CLIENT_DETAILS_SUCCESS";
export const CLIENT_DETAILS_FAIL = "CLIENT_DETAILS_FAIL";
export const CLIENT_DETAILS_RESET = "CLIENT_DETAILS_RESET";

export const CLIENT_UPDATE_REQUEST = "CLIENT_UPDATE_REQUEST";
export const CLIENT_UPDATE_SUCCESS = "CLIENT_UPDATE_SUCCESS";
export const CLIENT_UPDATE_FAIL = "CLIENT_UPDATE_FAIL";
export const CLIENT_UPDATE_RESET = "CLIENT_UPDATE_RESET";

export const CLIENT_DELETE_REQUEST = "CLIENT_DELETE_REQUEST";
export const CLIENT_DELETE_SUCCESS = "CLIENT_DELETE_SUCCESS";
export const CLIENT_DELETE_FAIL = "CLIENT_DELETE_FAIL";
export const CLIENT_DELETE_RESET = "CLIENT_DELETE_RESET";

export const CLIENT_GET_INTEL_REQUEST = "CLIENT_GET_INTEL_REQUEST";
export const CLIENT_GET_INTEL_SUCCESS = "CLIENT_GET_INTEL_SUCCESS";
export const CLIENT_GET_INTEL_FAIL = "CLIENT_GET_INTEL_FAIL";
export const CLIENT_GET_INTEL_RESET = "CLIENT_GET_INTEL_RESET";

export const CLIENT_DOWNLOAD_REQUEST = "CLIENT_DOWNLOAD_REQUEST";
export const CLIENT_DOWNLOAD_SUCCESS = "CLIENT_DOWNLOAD_SUCCESS";
export const CLIENT_DOWNLOAD_FAIL = "CLIENT_DOWNLOAD_FAIL";
export const CLIENT_DOWNLOAD_RESET = "CLIENT_DOWNLOAD_RESET";

export const CLIENT_UPLOAD_BEGIN = "CLIENT_UPLOAD_BEGIN";
export const CLIENT_UPLOAD_REQUEST = "CLIENT_UPLOAD_REQUEST";
export const CLIENT_UPLOAD_SUCCESS = "CLIENT_UPLOAD_SUCCESS";
export const CLIENT_UPLOAD_FAIL = "CLIENT_UPLOAD_FAIL";
export const CLIENT_UPLOAD_END = "CLIENT_UPLOAD_END";
export const CLIENT_UPLOAD_RESET = "CLIENT_UPLOAD_RESET";
