// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Menu,
  MenuItem,
  Slider,
  Switch,
  Tab,
  Tabs,
  Tag,
  Text,
  TextArea,
} from "@blueprintjs/core";
import { Popover2, Popover2InteractionKind } from "@blueprintjs/popover2";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Component imports
import ActionDialog from "../components/ActionDialog";
import { AppToaster } from "../components/AppToaster";
import ApprovalDetailDrawer from "../components/ApprovalDetailDrawer";
import CandidateCard from "../components/CandidateCard";
import CandidateCompetenciesCard from "../components/CandidateCompetenciesCard";
import ContactInfo from "../components/ContactInfo";
import DataCardContainer from "../components/DataCardContainer";
import DateValue from "../components/DateValue";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import GenerateFromFileButton from "../components/GenerateFromFileButton";
import HelpDialog from "../components/HelpDialog";
import ItemCardList from "../components/ItemCardList";
import LocationInfo from "../components/LocationInfo";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NumericValue from "../components/NumericValue";
import PermissionCard from "../components/PermissionCard";
import RoleInfo from "../components/RoleInfo";
import SearchField from "../components/SearchField";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionDocumentEditor3 from "../components/TabSectionDocumentEditor3";
import TabSectionDocumentEditorFormContainer2 from "../components/TabSectionDocumentEditorFormContainer2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldCollapse from "../components/TabSectionFieldCollapse";
import TabSectionFieldImage from "../components/TabSectionFieldImage";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Approval request
import {
  createApprovalRequestAction,
  deleteApprovalRequestAction,
  listApprovalRequestsAction,
  listApprovalRequestDetailsAction,
  resetCreateApprovalRequestAction,
  resetDeleteApprovalRequestAction,
  resetUpdateApprovalRequestAction,
  updateApprovalRequestAction,
} from "../actions/approvalRequestActions";

// Contact
import { listContactsAction } from "../actions/contactActions";

// Document
import { generatePdfDocumentAction } from "../actions/documentActions";

// Location
import { listLocationsAction } from "../actions/locationActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Opening
import {
  deleteOpeningAction,
  downloadOpeningAction,
  listOpeningDetailsAction,
  resetDeleteOpeningAction,
  resetDownloadOpeningAction,
  resetListOpeningsAction,
  resetUpdateOpeningAction,
  updateOpeningAction,
} from "../actions/openingActions";

// Opening application configuration
import {
  listOpeningApplicationConfigurationDetailsAction,
  resetUpdateOpeningApplicationConfigurationAction,
  updateOpeningApplicationConfigurationAction,
} from "../actions/openingApplicationConfigurationActions";

// Opening candidate
import {
  listOpeningCandidatesAction,
  listOpeningCandidateDetailsAction,
  resetUpdateOpeningCandidateAction,
  updateOpeningCandidateAction,
} from "../actions/openingCandidateActions";

// Opening document
import {
  listOpeningDocumentDetailsAction,
  resetUpdateOpeningDocumentAction,
  updateOpeningDocumentAction,
} from "../actions/openingDocumentActions";

// Opening evaluation
import {
  createOpeningEvaluationAction,
  deleteOpeningEvaluationAction,
  listOpeningEvaluationsAction,
  listOpeningEvaluationDetailsAction,
  resetCreateOpeningEvaluationAction,
  resetDeleteOpeningEvaluationAction,
  resetUpdateOpeningEvaluationAction,
  updateOpeningEvaluationAction,
} from "../actions/openingEvaluationActions";

// Opening evaluation rating
import {
  listOpeningEvaluationRatingsAction,
  resetUpdateOpeningEvaluationRatingAction,
  resetUploadOpeningEvaluationRatingsAction,
  updateOpeningEvaluationRatingAction,
  uploadOpeningEvaluationRatingsAction,
} from "../actions/openingEvaluationRatingActions";

// Opening permission
import {
  createOpeningPermissionAction,
  deleteOpeningPermissionAction,
  listOpeningPermissionsAction,
  listOpeningPermissionDetailsAction,
  resetCreateOpeningPermissionAction,
  resetDeleteOpeningPermissionAction,
  resetUpdateOpeningPermissionAction,
  updateOpeningPermissionAction,
} from "../actions/openingPermissionActions";

// Role
import { listRolesAction } from "../actions/roleActions";

// Main function
function OpeningDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Opening id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Approval request
  const approvalRequestCreate = useSelector(
    (state) => state.approvalRequestCreate
  );

  const approvalRequestDelete = useSelector(
    (state) => state.approvalRequestDelete
  );

  const approvalRequestDetails = useSelector(
    (state) => state.approvalRequestDetails
  );

  const approvalRequestList = useSelector((state) => state.approvalRequestList);

  const approvalRequestUpdate = useSelector(
    (state) => state.approvalRequestUpdate
  );

  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Document
  const documentGeneratePdf = useSelector((state) => state.documentGeneratePdf);

  // Location
  const locationList = useSelector((state) => state.locationList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Opening
  const openingDelete = useSelector((state) => state.openingDelete);

  const openingDetails = useSelector((state) => state.openingDetails);

  const openingDownload = useSelector((state) => state.openingDownload);

  const openingUpdate = useSelector((state) => state.openingUpdate);

  // Opening application configuration
  const openingApplicationConfigurationDetails = useSelector(
    (state) => state.openingApplicationConfigurationDetails
  );

  const openingApplicationConfigurationUpdate = useSelector(
    (state) => state.openingApplicationConfigurationUpdate
  );

  // Opening candidate
  const openingCandidateDetails = useSelector(
    (state) => state.openingCandidateDetails
  );

  const openingCandidateList = useSelector(
    (state) => state.openingCandidateList
  );

  const openingCandidateUpdate = useSelector(
    (state) => state.openingCandidateUpdate
  );

  // Opening document
  const openingDocumentDetails = useSelector(
    (state) => state.openingDocumentDetails
  );

  const openingDocumentUpdate = useSelector(
    (state) => state.openingDocumentUpdate
  );

  // Opening evaluation
  const openingEvaluationCreate = useSelector(
    (state) => state.openingEvaluationCreate
  );

  const openingEvaluationDelete = useSelector(
    (state) => state.openingEvaluationDelete
  );

  const openingEvaluationDetails = useSelector(
    (state) => state.openingEvaluationDetails
  );

  const openingEvaluationList = useSelector(
    (state) => state.openingEvaluationList
  );

  const openingEvaluationUpdate = useSelector(
    (state) => state.openingEvaluationUpdate
  );

  // Opening evaluation rating
  const openingEvaluationRatingList = useSelector(
    (state) => state.openingEvaluationRatingList
  );

  const openingEvaluationRatingUpdate = useSelector(
    (state) => state.openingEvaluationRatingUpdate
  );

  const openingEvaluationRatingsUpload = useSelector(
    (state) => state.openingEvaluationRatingsUpload
  );

  // Opening permission
  const openingPermissionCreate = useSelector(
    (state) => state.openingPermissionCreate
  );

  const openingPermissionDelete = useSelector(
    (state) => state.openingPermissionDelete
  );

  const openingPermissionDetails = useSelector(
    (state) => state.openingPermissionDetails
  );

  const openingPermissionList = useSelector(
    (state) => state.openingPermissionList
  );

  const openingPermissionUpdate = useSelector(
    (state) => state.openingPermissionUpdate
  );

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Role
  const roleList = useSelector((state) => state.roleList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("opening-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Download editable opening document
  const [
    downloadEditableOpeningDocumentIsOpen,
    setDownloadEditableOpeningDocumentIsOpen,
  ] = useState(false);

  // Application form
  const [
    updateApplicationConfigFormDrawerIsOpen,
    setUpdateApplicationConfigFormDrawerIsOpen,
  ] = useState(false);

  // Candidates
  const [updateCandidateDrawerIsOpen, setUpdateCandidateDrawerIsOpen] =
    useState(false);

  // Evaluations
  const [addEvaluationDrawerIsOpen, setAddEvaluationDrawerIsOpen] =
    useState(false);
  const [updateEvaluationDrawerIsOpen, setUpdateEvaluationDrawerIsOpen] =
    useState(false);

  // Approval requests
  const [addApprovalRequestDrawerIsOpen, setAddApprovalRequestDrawerIsOpen] =
    useState(false);
  const [
    updateApprovalRequestDrawerIsOpen,
    setUpdateApprovalRequestDrawerIsOpen,
  ] = useState(false);
  const [viewApprovalDrawerIsOpen, setViewApprovalDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Approval request
  const approvalRequestQueryDefault = {
    approver: "",
    decisionRight: "",
    itemId: id,
    itemType: "opening",
    organisation: activeOrganisation.id,
    outcome: "",
    page: 1,
    reviewedEnd: "",
    reviewedStart: "",
    sortField: "",
  };
  const [approvalRequestQuery, setApprovalRequestQuery] = useState({
    ...approvalRequestQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Location
  const locationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [locationQuery, setLocationQuery] = useState({
    ...locationQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Opening candidate
  const openingCandidateQueryDefault = {
    page: 1,
    opening: id,
    searchString: "",
    status: "",
  };
  const [openingCandidateQuery, setOpeningCandidateQuery] = useState({
    ...openingCandidateQueryDefault,
  });

  // Opening evaluation
  const openingEvaluationQueryDefault = {
    interviewer: "",
    page: 1,
    opening: id,
    searchString: "",
    status: "",
  };
  const [openingEvaluationQuery, setOpeningEvaluationQuery] = useState({
    ...openingEvaluationQueryDefault,
  });

  // Opening evaluation ratings
  const openingEvaluationRatingQueryDefault = {
    evaluation: "",
    page: 1,
  };
  const [openingEvaluationRatingQuery, setOpeningEvaluationRatingQuery] =
    useState({
      ...openingEvaluationRatingQueryDefault,
    });

  // Opening permission
  const openingPermissionQueryDefault = {
    page: 1,
    opening: id,
    searchString: "",
  };
  const [openingPermissionQuery, setOpeningPermissionQuery] = useState({
    ...openingPermissionQueryDefault,
  });

  // Role
  const roleQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [roleQuery, setRoleQuery] = useState({
    ...roleQueryDefault,
  });

  // Form variables
  // Approval request
  const approvalRequestDefault = {
    approver: "",
    approverName: "",
    comments: "",
    decisionRight: "Staff recruitment",
    status: "Draft",
  };
  const [approvalRequest, setApprovalRequest] = useState({
    ...approvalRequestDefault,
  });

  // Opening
  const openingDefault = {
    closed: "",
    employment: "Permanent",
    location: "",
    locationName: "",
    manager: "",
    managerName: "",
    name: "",
    notes: "",
    opened: "",
    role: "",
    roleName: "",
    restricted: true,
    status: "Interviewing",
    value: "",
  };
  const [opening, setOpening] = useState({
    ...openingDefault,
  });

  // Opening application configuration
  const openingApplicationConfigurationDefault = {
    close: "",
    employment: true,
    intro: "",
    notesGuidance: "",
    remuneration: true,
    policeCheck: true,
  };
  const [openingApplicationConfiguration, setOpeningApplicationConfiguration] =
    useState({
      ...openingApplicationConfigurationDefault,
    });

  // Opening candidate
  const openingCandidateDefault = {
    notes: "",
    status: "Pending",
  };
  const [openingCandidate, setOpeningCandidate] = useState({
    ...openingCandidateDefault,
  });

  // Opening document
  const openingDocumentDefault = {
    about: "",
    additionalContent: "",
    benefits: "",
    closingDate: "",
    companyAddress: "",
    companyEmail: "",
    companyEntity: "",
    companyLogo: "",
    companyLogoName: "",
    companyPhone: "",
    font: "Sintony",
    includeFormUrl: true,
    includeCompetencies: true,
    intro: "",
    location: "",
    name: "",
    purpose: "",
    qualifications: "",
    reference: "",
    responsibilities: "",
  };
  const [openingDocument, setOpeningDocument] = useState({
    ...openingDocumentDefault,
  });

  // Opening evaluation
  const openingEvaluationDefault = {
    candidate: "",
    candidateName: "",
    interviewer: "",
    interviewerName: "",
    notes: "",
    policeCheckComments: "",
    referencesComments: "",
    remunerationComments: "",
    resume: "",
    resumeName: "",
    status: "Not selected",
    valuesAlignmentComments: "",
  };
  const [openingEvaluation, setOpeningEvaluation] = useState({
    ...openingEvaluationDefault,
  });

  // Opening evaluation rating
  const openingEvaluationRatingDefault = {
    comments: "",
    id: "",
    level: "",
  };
  const [openingEvaluationRating, setOpeningEvaluationRating] = useState({
    ...openingEvaluationRatingDefault,
  });

  const [evaluationRatings, setEvaluationRatings] = useState({});
  const [evaluationRatingsInitialised, setEvaluationRatingsInitialised] =
    useState(false);

  // Opening permission
  const openingPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [openingPermission, setOpeningPermission] = useState({
    ...openingPermissionDefault,
  });

  // Other
  const [openingDeleteKey, setOpeningDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Approval request
    setApprovalRequest({
      ...approvalRequestDefault,
    });

    // Opening
    setOpening({
      ...openingDefault,
    });

    // Opening candidate
    setOpeningCandidate({
      ...openingCandidateDefault,
    });

    // Opening evaluation
    setOpeningEvaluation({
      ...openingEvaluationDefault,
    });

    // Opening evaluation ratings
    setOpeningEvaluationRating({
      ...openingEvaluationRatingDefault,
    });

    setEvaluationRatings({});
    setEvaluationRatingsInitialised(false);

    // Opening permission
    setOpeningPermission({
      ...openingPermissionDefault,
    });

    // Query variables
    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Location
    setLocationQuery({
      ...locationQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Role
    setRoleQuery({
      ...roleQueryDefault,
    });
  };

  // Streaming data websocket connection
  const { lastJsonMessage } = useWebSocket(
    `${BACKEND_WS_URL}/api/v1/data/opening/${id}/`,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  // Initialise drawer variables for approval request
  useEffect(() => {
    setApprovalRequest({
      approver: approvalRequestDetails.approval_request.approver
        ? approvalRequestDetails.approval_request.approver
        : approvalRequestDefault.approver,
      approverName: approvalRequestDetails.approval_request.approver_name
        ? approvalRequestDetails.approval_request.approver_name
        : approvalRequestDefault.approverName,
      comments: approvalRequestDetails.approval_request.comments
        ? approvalRequestDetails.approval_request.comments
        : approvalRequestDefault.comments,
      decisionRight: approvalRequestDetails.approval_request.decision_right
        ? approvalRequestDetails.approval_request.decision_right
        : approvalRequestDefault.decisionRight,
      status: approvalRequestDetails.approval_request.status
        ? approvalRequestDetails.approval_request.status
        : approvalRequestDefault.status,
    });
    // eslint-disable-next-line
  }, [approvalRequestDetails.approval_request]);

  // Initialise drawer variables for opening
  useEffect(() => {
    setOpening({
      closed: openingDetails.opening.closed
        ? new Date(openingDetails.opening.closed)
        : openingDefault.closed,
      employment: openingDetails.opening.employment
        ? openingDetails.opening.employment
        : openingDefault.employment,
      location: openingDetails.opening.location
        ? openingDetails.opening.location
        : openingDefault.location,
      locationName: openingDetails.opening.location_name
        ? openingDetails.opening.location_name
        : openingDefault.locationName,
      manager: openingDetails.opening.manager
        ? openingDetails.opening.manager
        : openingDefault.manager,
      managerName: openingDetails.opening.manager_name
        ? openingDetails.opening.manager_name
        : openingDefault.managerName,
      name: openingDetails.opening.name
        ? openingDetails.opening.name
        : openingDefault.name,
      notes: openingDetails.opening.notes
        ? openingDetails.opening.notes
        : openingDefault.notes,
      opened: openingDetails.opening.opened
        ? new Date(openingDetails.opening.opened)
        : openingDefault.opened,
      role: openingDetails.opening.role
        ? openingDetails.opening.role
        : openingDefault.role,
      roleName: openingDetails.opening.role_data
        ? openingDetails.opening.role_data.name
        : openingDefault.roleName,
      restricted:
        openingDetails.opening.restricted != null
          ? openingDetails.opening.restricted
          : openingDefault.restricted,
      status: openingDetails.opening.status
        ? openingDetails.opening.status
        : openingDefault.status,
      value: openingDetails.opening.value
        ? openingDetails.opening.value
        : openingDefault.value,
    });
    // eslint-disable-next-line
  }, [openingDetails.opening]);

  // Initialise drawer variables for opening application configuration
  useEffect(() => {
    setOpeningApplicationConfiguration({
      close: openingApplicationConfigurationDetails
        .opening_application_configuration.close
        ? new Date(
            openingApplicationConfigurationDetails.opening_application_configuration.close
          )
        : openingApplicationConfigurationDefault.close,
      employment:
        openingApplicationConfigurationDetails.opening_application_configuration
          .employment !== null
          ? openingApplicationConfigurationDetails
              .opening_application_configuration.employment
          : openingApplicationConfigurationDefault.employment,
      intro: openingApplicationConfigurationDetails
        .opening_application_configuration.intro
        ? openingApplicationConfigurationDetails
            .opening_application_configuration.intro
        : openingApplicationConfigurationDefault.intro,
      notesGuidance: openingApplicationConfigurationDetails
        .opening_application_configuration.notes_guidance
        ? openingApplicationConfigurationDetails
            .opening_application_configuration.notes_guidance
        : openingApplicationConfigurationDefault.notesGuidance,
      remuneration:
        openingApplicationConfigurationDetails.opening_application_configuration
          .remuneration !== null
          ? openingApplicationConfigurationDetails
              .opening_application_configuration.remuneration
          : openingApplicationConfigurationDefault.remuneration,
      policeCheck:
        openingApplicationConfigurationDetails.opening_application_configuration
          .police_check !== null
          ? openingApplicationConfigurationDetails
              .opening_application_configuration.police_check
          : openingApplicationConfigurationDefault.policeCheck,
    });
    // eslint-disable-next-line
  }, [
    openingApplicationConfigurationDetails.opening_application_configuration,
  ]);

  // Initialise drawer variables for opening candidate
  useEffect(() => {
    setOpeningCandidate({
      notes: openingCandidateDetails.opening_candidate.notes
        ? openingCandidateDetails.opening_candidate.notes
        : openingCandidateDefault.notes,
      status: openingCandidateDetails.opening_candidate.status
        ? openingCandidateDetails.opening_candidate.status
        : openingCandidateDefault.status,
    });
    // eslint-disable-next-line
  }, [openingCandidateDetails.opening_candidate]);

  // Initialise drawer variables for opening document
  useEffect(() => {
    setOpeningDocument({
      about: openingDocumentDetails.opening_document.about
        ? openingDocumentDetails.opening_document.about
        : openingDocumentDefault.about,
      additionalContent: openingDocumentDetails.opening_document
        .additional_content
        ? openingDocumentDetails.opening_document.additional_content
        : openingDocumentDefault.additionalContent,
      benefits: openingDocumentDetails.opening_document.benefits
        ? openingDocumentDetails.opening_document.benefits
        : openingDocumentDefault.benefits,
      closingDate: openingDocumentDetails.opening_document.closing_date
        ? new Date(openingDocumentDetails.opening_document.closing_date)
        : openingDocumentDefault.closingDate,
      companyAddress: openingDocumentDetails.opening_document.company_address
        ? openingDocumentDetails.opening_document.company_address
        : openingDocumentDefault.companyAddress,
      companyEmail: openingDocumentDetails.opening_document.company_email
        ? openingDocumentDetails.opening_document.company_email
        : openingDocumentDefault.companyEmail,
      companyEntity: openingDocumentDetails.opening_document.company_entity
        ? openingDocumentDetails.opening_document.company_entity
        : openingDocumentDefault.companyEntity,
      companyLogo: openingDocumentDetails.opening_document.company_logo
        ? openingDocumentDetails.opening_document.company_logo
        : openingDocumentDefault.logo,
      companyLogoName: openingDocumentDetails.opening_document.company_logo
        ? openingDocumentDetails.opening_document.company_logo.split("/").pop()
        : openingDocumentDefault.companyLogoName,
      companyPhone: openingDocumentDetails.opening_document.company_phone
        ? openingDocumentDetails.opening_document.company_phone
        : openingDocumentDefault.companyPhone,
      font: openingDocumentDetails.opening_document.font
        ? openingDocumentDetails.opening_document.font
        : openingDocumentDefault.font,
      includeFormUrl:
        openingDocumentDetails.opening_document.include_form_url !== null
          ? openingDocumentDetails.opening_document.include_form_url
          : openingDocumentDefault.includeFormUrl,
      includeCompetencies:
        openingDocumentDetails.opening_document.include_competencies !== null
          ? openingDocumentDetails.opening_document.include_competencies
          : openingDocumentDefault.includeCompetencies,
      intro: openingDocumentDetails.opening_document.intro
        ? openingDocumentDetails.opening_document.intro
        : openingDocumentDefault.intro,
      location: openingDocumentDetails.opening_document.location
        ? openingDocumentDetails.opening_document.location
        : openingDocumentDefault.location,
      name: openingDocumentDetails.opening_document.name
        ? openingDocumentDetails.opening_document.name
        : openingDocumentDefault.name,
      purpose: openingDocumentDetails.opening_document.purpose
        ? openingDocumentDetails.opening_document.purpose
        : openingDocumentDefault.purpose,
      qualifications: openingDocumentDetails.opening_document.qualifications
        ? openingDocumentDetails.opening_document.qualifications
        : openingDocumentDefault.qualifications,
      reference: openingDocumentDetails.opening_document.reference
        ? openingDocumentDetails.opening_document.reference
        : openingDocumentDefault.reference,
      responsibilities: openingDocumentDetails.opening_document.responsibilities
        ? openingDocumentDetails.opening_document.responsibilities
        : openingDocumentDefault.responsibilities,
    });
    // eslint-disable-next-line
  }, [openingDocumentDetails.opening_document]);

  // Initialise drawer variables for opening evaluation
  useEffect(() => {
    setOpeningEvaluation({
      candidate: openingEvaluationDetails.opening_evaluation.candidate
        ? openingEvaluationDetails.opening_evaluation.candidate
        : openingEvaluationDefault.candidate,
      candidateName: openingEvaluationDetails.opening_evaluation.candidate_name
        ? openingEvaluationDetails.opening_evaluation.candidate_name
        : openingEvaluationDefault.candidateName,
      interviewer: openingEvaluationDetails.opening_evaluation.interviewer
        ? openingEvaluationDetails.opening_evaluation.interviewer
        : openingEvaluationDefault.interviewer,
      interviewerName: openingEvaluationDetails.opening_evaluation
        .interviewer_name
        ? openingEvaluationDetails.opening_evaluation.interviewer_name
        : openingEvaluationDefault.interviewerName,
      notes: openingEvaluationDetails.opening_evaluation.notes
        ? openingEvaluationDetails.opening_evaluation.notes
        : openingEvaluationDefault.notes,
      policeCheckComments: openingEvaluationDetails.opening_evaluation
        .police_check_comments
        ? openingEvaluationDetails.opening_evaluation.police_check_comments
        : openingEvaluationDefault.policeCheckComments,
      referencesComments: openingEvaluationDetails.opening_evaluation
        .references_comments
        ? openingEvaluationDetails.opening_evaluation.references_comments
        : openingEvaluationDefault.referencesComments,
      remunerationComments: openingEvaluationDetails.opening_evaluation
        .remuneration_comments
        ? openingEvaluationDetails.opening_evaluation.remuneration_comments
        : openingEvaluationDefault.remunerationComments,
      resume: openingEvaluationDetails.opening_evaluation.resume
        ? openingEvaluationDetails.opening_evaluation.resume
        : openingEvaluationDefault.resume,
      resumeName: openingEvaluationDetails.opening_evaluation.resume
        ? openingEvaluationDetails.opening_evaluation.resume.split("/").pop()
        : openingEvaluationDefault.resumeName,
      status: openingEvaluationDetails.opening_evaluation.status
        ? openingEvaluationDetails.opening_evaluation.status
        : openingEvaluationDefault.status,
      valuesAlignmentComments: openingEvaluationDetails.opening_evaluation
        .values_alignment_comments
        ? openingEvaluationDetails.opening_evaluation.values_alignment_comments
        : openingEvaluationDefault.valuesAlignmentComments,
    });
    // eslint-disable-next-line
  }, [openingEvaluationDetails.opening_evaluation]);

  // Initialise drawer variables for opening permission
  useEffect(() => {
    setOpeningPermission({
      level: openingPermissionDetails.opening_permission.level
        ? openingPermissionDetails.opening_permission.level
        : openingPermissionDefault.level,
      manager: openingPermissionDetails.opening_permission.manager
        ? openingPermissionDetails.opening_permission.manager
        : openingPermissionDefault.manager,
      managerName: openingPermissionDetails.opening_permission.manager_name
        ? openingPermissionDetails.opening_permission.manager_name
        : openingPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [openingPermissionDetails.opening_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If opening deleted redirect to openings
  useEffect(() => {
    if (openingDelete.success) {
      dispatch(resetDeleteOpeningAction());

      dispatch(resetListOpeningsAction());

      navigate("/openings");
    }
    // eslint-disable-next-line
  }, [openingDelete.success]);

  // Load opening details
  useEffect(() => {
    dispatch(listOpeningDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Load opening application configuration
  const [initialConfigLoadDone, setInitialConfigLoadDone] = useState(false);

  useEffect(() => {
    if (openingDetails.opening.opening_document && !initialConfigLoadDone) {
      dispatch(
        listOpeningApplicationConfigurationDetailsAction(
          openingDetails.opening.opening_application_configuration
        )
      );

      setInitialConfigLoadDone(true);
    }
    // eslint-disable-next-line
  }, [openingDetails.opening.opening_document]);

  // Load opening document data
  const [initialDocLoadDone, setInitialDocLoadDone] = useState(false);

  useEffect(() => {
    if (openingDetails.opening.opening_document && !initialDocLoadDone) {
      dispatch(
        listOpeningDocumentDetailsAction(
          openingDetails.opening.opening_document
        )
      );

      setInitialDocLoadDone(true);
    }
    // eslint-disable-next-line
  }, [openingDetails.opening.opening_document]);

  useEffect(() => {
    if (initialDocLoadDone && openingUpdate.success) {
      dispatch(
        listOpeningDocumentDetailsAction(
          openingDetails.opening.opening_document
        )
      );
    }
    // eslint-disable-next-line
  }, [openingUpdate.success]);

  useEffect(() => {
    if (initialDocLoadDone && openingDocumentUpdate.success) {
      dispatch(
        listOpeningDocumentDetailsAction(
          openingDetails.opening.opening_document
        )
      );

      dispatch(resetUpdateOpeningDocumentAction());
    }
    // eslint-disable-next-line
  }, [openingDocumentUpdate.success]);

  useEffect(() => {
    if (openingDocumentDetails.success) {
      dispatch(
        generatePdfDocumentAction({
          data: openingDocumentDetails.opening_document,
          type: "opening",
        })
      );
    }
    // eslint-disable-next-line
  }, [openingDocumentDetails.success]);

  // Filter approval requests
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listApprovalRequestsAction({
            ...approvalRequestQuery,
            reviewedEnd: approvalRequestQuery.reviewedEnd
              ? getDjangoDate(approvalRequestQuery.reviewedEnd)
              : getDjangoDate(approvalRequestQueryDefault.reviewedEnd),
            reviewedStart: approvalRequestQuery.reviewedStart
              ? getDjangoDate(approvalRequestQuery.reviewedStart)
              : getDjangoDate(approvalRequestQueryDefault.reviewedStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [approvalRequestQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listContactsAction({ ...contactQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter locations
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listLocationsAction({
            ...locationQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [locationQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter manager list
  const [filteredManagerList, setFilteredManagerList] = useState([]);

  useEffect(() => {
    let managers = [];

    managers = managerList.managers.filter((manager) =>
      manager.approval_role_data !== null
        ? manager.approval_role_data.can_approve_staff_recruitment &&
          manager.approval_role_data.can_approve_staff_recruitment_threshold >=
            openingDetails.opening.value
        : false
    );

    setFilteredManagerList(managers);
  }, [
    addApprovalRequestDrawerIsOpen,
    managerList.managers,
    openingDetails.opening.value,
    updateApprovalRequestDrawerIsOpen,
  ]);

  // Filter opening candidates
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listOpeningCandidatesAction({ ...openingCandidateQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [openingCandidateQuery]);

  // Filter opening evaluations
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listOpeningEvaluationsAction({ ...openingEvaluationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [openingEvaluationQuery]);

  // Filter opening evaluation ratings
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listOpeningEvaluationRatingsAction({
            ...openingEvaluationRatingQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [openingEvaluationRatingQuery]);

  // Filter opening permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listOpeningPermissionsAction({ ...openingPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [openingPermissionQuery]);

  // Filter roles
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listRolesAction({
            ...roleQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [roleQuery]);

  // Form submission
  // Approval request
  // Add approval request
  const addApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approval_request_data.append("item_id", id);

    approval_request_data.append("organisation", activeOrganisation.id);

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(createApprovalRequestAction(approval_request_data));
  };

  // Delete approval request
  const deleteApprovalRequest = () => {
    // Dispatch action
    dispatch(
      deleteApprovalRequestAction(approvalRequestDetails.approval_request.id)
    );
  };

  // Update approval request
  const updateApprovalRequest = () => {
    // Prepare data
    const approval_request_data = new FormData();

    approvalRequest.approver
      ? approval_request_data.append("approver", approvalRequest.approver)
      : approval_request_data.append(
          "approver",
          approvalRequestDefault.approver
        );

    approvalRequest.comments
      ? approval_request_data.append("comments", approvalRequest.comments)
      : approval_request_data.append(
          "comments",
          approvalRequestDefault.comments
        );

    approvalRequest.decisionRight
      ? approval_request_data.append(
          "decision_right",
          approvalRequest.decisionRight
        )
      : approval_request_data.append(
          "decision_right",
          approvalRequestDefault.decisionRight
        );

    approvalRequest.status
      ? approval_request_data.append("status", approvalRequest.status)
      : approval_request_data.append("status", approvalRequestDefault.status);

    // Dispatch action
    dispatch(
      updateApprovalRequestAction(
        approvalRequestDetails.approval_request.id,
        approval_request_data
      )
    );
  };

  // Opening
  // Delete opening
  const deleteOpening = () => {
    // Dispatch action
    openingDeleteKey === openingDetails.opening.name
      ? dispatch(deleteOpeningAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the opening's name",
        });
  };

  // Update opening summary
  const updateSummary = () => {
    // Prepare data
    const opening_data = new FormData();

    opening.closed
      ? opening_data.append("closed", getDjangoDate(opening.closed))
      : opening_data.append("closed", getDjangoDate(openingDefault.closed));

    opening.employment
      ? opening_data.append("employment", opening.employment)
      : opening_data.append("employment", openingDefault.employment);

    opening.location
      ? opening_data.append("location", opening.location)
      : opening_data.append("location", openingDefault.location);

    opening.manager
      ? opening_data.append("manager", opening.manager)
      : opening_data.append("manager", openingDefault.manager);

    opening.name
      ? opening_data.append("name", opening.name)
      : opening_data.append("name", openingDefault.name);

    opening.notes
      ? opening_data.append("notes", opening.notes)
      : opening_data.append("notes", openingDefault.notes);

    opening.opened
      ? opening_data.append("opened", getDjangoDate(opening.opened))
      : opening_data.append("opened", getDjangoDate(openingDefault.opened));

    opening.role
      ? opening_data.append("role", opening.role)
      : opening_data.append("role", openingDefault.role);

    opening.restricted != null
      ? opening_data.append("restricted", opening.restricted)
      : opening_data.append("restricted", openingDefault.restricted);

    opening.status
      ? opening_data.append("status", opening.status)
      : opening_data.append("status", openingDefault.status);

    opening.value
      ? opening_data.append("value", opening.value)
      : opening_data.append("value", openingDefault.value);

    // Dispatch action
    dispatch(updateOpeningAction(openingDetails.opening.id, opening_data));
  };

  // Opening application configuration
  // Update opening application configuration
  const updateApplicationConfig = () => {
    // Prepare data
    const opening_application_configuration = new FormData();

    openingApplicationConfiguration.close
      ? opening_application_configuration.append(
          "close",
          getDjangoDate(openingApplicationConfiguration.close)
        )
      : opening_application_configuration.append(
          "close",
          getDjangoDate(openingApplicationConfigurationDefault.close)
        );

    openingApplicationConfiguration.employment !== null
      ? opening_application_configuration.append(
          "employment",
          openingApplicationConfiguration.employment
        )
      : opening_application_configuration.append(
          "employment",
          openingApplicationConfigurationDefault.employment
        );

    openingApplicationConfiguration.intro
      ? opening_application_configuration.append(
          "intro",
          openingApplicationConfiguration.intro
        )
      : opening_application_configuration.append(
          "intro",
          openingApplicationConfigurationDefault.intro
        );

    openingApplicationConfiguration.notesGuidance !== null
      ? opening_application_configuration.append(
          "notes_guidance",
          openingApplicationConfiguration.notesGuidance
        )
      : opening_application_configuration.append(
          "notes_guidance",
          openingApplicationConfigurationDefault.notesGuidance
        );

    openingApplicationConfiguration.policeCheck !== null
      ? opening_application_configuration.append(
          "police_check",
          openingApplicationConfiguration.policeCheck
        )
      : opening_application_configuration.append(
          "police_check",
          openingApplicationConfigurationDefault.policeCheck
        );

    openingApplicationConfiguration.remuneration !== null
      ? opening_application_configuration.append(
          "remuneration",
          openingApplicationConfiguration.remuneration
        )
      : opening_application_configuration.append(
          "remuneration",
          openingApplicationConfigurationDefault.remuneration
        );

    // Dispatch action
    dispatch(
      updateOpeningApplicationConfigurationAction(
        openingDetails.opening.opening_application_configuration,
        opening_application_configuration
      )
    );
  };

  // Opening candidate
  // Update candidate
  const updateCandidate = () => {
    // Prepare data
    const opening_candidate = new FormData();

    openingCandidate.notes
      ? opening_candidate.append("notes", openingCandidate.notes)
      : opening_candidate.append("notes", openingCandidateDefault.notes);

    openingCandidate.status
      ? opening_candidate.append("status", openingCandidate.status)
      : opening_candidate.append("status", openingCandidateDefault.status);

    // Dispatch action
    dispatch(
      updateOpeningCandidateAction(
        openingCandidateDetails.opening_candidate.id,
        opening_candidate
      )
    );
  };

  // Opening document
  // Update opening document
  const updateDocument = () => {
    // Prepare data
    const opening_document = new FormData();

    openingDocument.about
      ? opening_document.append("about", openingDocument.about)
      : opening_document.append("about", openingDocumentDefault.about);

    openingDocument.additionalContent
      ? opening_document.append(
          "additional_content",
          openingDocument.additionalContent
        )
      : opening_document.append(
          "additional_content",
          openingDocumentDefault.additionalContent
        );

    openingDocument.benefits
      ? opening_document.append("benefits", openingDocument.benefits)
      : opening_document.append("benefits", openingDocumentDefault.benefits);

    openingDocument.closingDate
      ? opening_document.append(
          "closing_date",
          getDjangoDate(openingDocument.closingDate)
        )
      : opening_document.append(
          "closing_date",
          getDjangoDate(openingDocumentDefault.closingDate)
        );

    openingDocument.companyAddress
      ? opening_document.append(
          "company_address",
          openingDocument.companyAddress
        )
      : opening_document.append(
          "company_address",
          openingDocumentDefault.companyAddress
        );

    openingDocument.companyEmail
      ? opening_document.append("company_email", openingDocument.companyEmail)
      : opening_document.append(
          "company_email",
          openingDocumentDefault.companyEmail
        );

    openingDocument.companyEntity
      ? opening_document.append("company_entity", openingDocument.companyEntity)
      : opening_document.append(
          "company_entity",
          openingDocumentDefault.companyEntity
        );

    if (
      openingDocument.companyLogo &&
      openingDocument.companyLogo !==
        openingDocumentDetails.opening_document.company_logo
    ) {
      opening_document.append("company_logo", openingDocument.companyLogo);
    } else if (openingDocument.companyLogo === "") {
      opening_document.append("company_logo", openingDocument.companyLogo);
    }

    openingDocument.companyPhone
      ? opening_document.append("company_phone", openingDocument.companyPhone)
      : opening_document.append(
          "company_phone",
          openingDocumentDefault.companyPhone
        );

    openingDocument.font
      ? opening_document.append("font", openingDocument.font)
      : opening_document.append("font", openingDocumentDefault.font);

    openingDocument.includeFormUrl !== null
      ? opening_document.append(
          "include_form_url",
          openingDocument.includeFormUrl
        )
      : opening_document.append(
          "include_form_url",
          openingDocumentDefault.includeFormUrl
        );

    openingDocument.includeCompetencies !== null
      ? opening_document.append(
          "include_competencies",
          openingDocument.includeCompetencies
        )
      : opening_document.append(
          "include_competencies",
          openingDocumentDefault.includeCompetencies
        );

    openingDocument.intro
      ? opening_document.append("intro", openingDocument.intro)
      : opening_document.append("intro", openingDocumentDefault.intro);

    openingDocument.location
      ? opening_document.append("location", openingDocument.location)
      : opening_document.append("location", openingDocumentDefault.location);

    openingDocument.name
      ? opening_document.append("name", openingDocument.name)
      : opening_document.append("name", openingDocumentDefault.name);

    openingDocument.purpose
      ? opening_document.append("purpose", openingDocument.purpose)
      : opening_document.append("purpose", openingDocumentDefault.purpose);

    openingDocument.qualifications
      ? opening_document.append(
          "qualifications",
          openingDocument.qualifications
        )
      : opening_document.append(
          "qualifications",
          openingDocumentDefault.qualifications
        );

    openingDocument.reference
      ? opening_document.append("reference", openingDocument.reference)
      : opening_document.append("reference", openingDocumentDefault.reference);

    openingDocument.responsibilities
      ? opening_document.append(
          "responsibilities",
          openingDocument.responsibilities
        )
      : opening_document.append(
          "responsibilities",
          openingDocumentDefault.responsibilities
        );

    // Dispatch action
    dispatch(
      updateOpeningDocumentAction(
        openingDetails.opening.opening_document,
        opening_document
      )
    );

    // Refresh opening data
    dispatch(listOpeningDetailsAction(id));
  };

  // Opening evaluation
  // Add evaluation
  const addEvaluation = () => {
    // Prepare data
    const opening_evaluation = new FormData();

    const candidate = new FormData();
    if (openingEvaluation.candidate) {
      opening_evaluation.append("candidate", openingEvaluation.candidate);
    } else {
      opening_evaluation.append(
        "candidate",
        openingEvaluationDefault.candidate
      );

      if (openingEvaluation.candidateName) {
        candidate.append("name", openingEvaluation.candidateName);

        candidate.append("organisation", activeOrganisation.id);
      }
    }

    openingEvaluation.interviewer
      ? opening_evaluation.append("interviewer", openingEvaluation.interviewer)
      : opening_evaluation.append(
          "interviewer",
          openingEvaluationDefault.interviewer
        );

    openingEvaluation.notes
      ? opening_evaluation.append("notes", openingEvaluation.notes)
      : opening_evaluation.append("notes", openingEvaluationDefault.notes);

    opening_evaluation.append("opening", id);

    if (
      openingEvaluation.resume &&
      openingEvaluation.resume !==
        openingEvaluationDetails.opening_evaluation.resume
    ) {
      opening_evaluation.append("resume", openingEvaluation.resume);
    } else if (openingEvaluation.resume === "") {
      opening_evaluation.append("resume", openingEvaluation.resume);
    }

    openingEvaluation.status
      ? opening_evaluation.append("status", openingEvaluation.status)
      : opening_evaluation.append("status", openingEvaluationDefault.status);

    // Dispatch action
    dispatch(createOpeningEvaluationAction(candidate, opening_evaluation));
  };

  // Delete evaluation
  const deleteEvaluation = () => {
    // Dispatch action
    dispatch(
      deleteOpeningEvaluationAction(
        openingEvaluationDetails.opening_evaluation.id
      )
    );
  };

  // Update evaluation
  const updateEvaluation = () => {
    // Prepare data
    const opening_evaluation = new FormData();

    openingEvaluation.interviewer
      ? opening_evaluation.append("interviewer", openingEvaluation.interviewer)
      : opening_evaluation.append(
          "interviewer",
          openingEvaluationDefault.interviewer
        );

    openingEvaluation.notes
      ? opening_evaluation.append("notes", openingEvaluation.notes)
      : opening_evaluation.append("notes", openingEvaluationDefault.notes);

    openingEvaluation.policeCheckComments
      ? opening_evaluation.append(
          "police_check_comments",
          openingEvaluation.policeCheckComments
        )
      : opening_evaluation.append(
          "police_check_comments",
          openingEvaluationDefault.policeCheckComments
        );

    openingEvaluation.referencesComments
      ? opening_evaluation.append(
          "references_comments",
          openingEvaluation.referencesComments
        )
      : opening_evaluation.append(
          "references_comments",
          openingEvaluationDefault.referencesComments
        );

    openingEvaluation.remunerationComments
      ? opening_evaluation.append(
          "remuneration_comments",
          openingEvaluation.remunerationComments
        )
      : opening_evaluation.append(
          "remuneration_comments",
          openingEvaluationDefault.remunerationComments
        );

    openingEvaluation.status
      ? opening_evaluation.append("status", openingEvaluation.status)
      : opening_evaluation.append("status", openingEvaluationDefault.status);

    openingEvaluation.valuesAlignmentComments
      ? opening_evaluation.append(
          "values_alignment_comments",
          openingEvaluation.valuesAlignmentComments
        )
      : opening_evaluation.append(
          "values_alignment_comments",
          openingEvaluationDefault.valuesAlignmentComments
        );

    // Dispatch action
    dispatch(
      updateOpeningEvaluationAction(
        openingEvaluationDetails.opening_evaluation.id,
        opening_evaluation
      )
    );
  };

  // Opening evaluation rating
  // Update evaluation rating
  const updateEvaluationRating = () => {
    // Prepare data
    const opening_evaluation_rating = new FormData();

    openingEvaluationRating.comments
      ? opening_evaluation_rating.append(
          "comments",
          openingEvaluationRating.comments
        )
      : opening_evaluation_rating.append(
          "comments",
          openingEvaluationRatingDefault.comments
        );

    openingEvaluationRating.level
      ? opening_evaluation_rating.append("level", openingEvaluationRating.level)
      : opening_evaluation_rating.append(
          "level",
          openingEvaluationRatingDefault.level
        );

    // Dispatch action
    dispatch(
      updateOpeningEvaluationRatingAction(
        openingEvaluationRating.id,
        opening_evaluation_rating
      )
    );
  };

  useEffect(() => {
    if (!evaluationRatingsInitialised) {
      setEvaluationRatings(
        Object.assign(
          {},
          ...openingEvaluationRatingList.opening_evaluation_ratings.map(
            (rating) => ({
              [rating.id]: {
                comments: rating.comments,
                description:
                  rating.role_competency_data.competency_data.description,
                id: rating.id,
                level: rating.level,
                name: rating.role_competency_data.competency_data.name,
                required: rating.role_competency_data.level,
              },
            })
          )
        )
      );

      setEvaluationRatingsInitialised(true);
    }
    // eslint-disable-next-line
  }, [openingEvaluationRatingList.opening_evaluation_ratings]);

  useEffect(() => {
    if (openingEvaluationRating.id) {
      const timeOutId = setTimeout(() => {
        updateEvaluationRating();
      }, 1000);

      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [openingEvaluationRating]);

  useEffect(() => {
    if (openingEvaluationRatingUpdate.success) {
      dispatch(resetUpdateOpeningEvaluationRatingAction());

      dispatch(listOpeningEvaluationsAction({ ...openingEvaluationQuery }));

      setOpeningEvaluationRating({
        ...openingEvaluationRatingDefault,
      });
    }
    // eslint-disable-next-line
  }, [openingEvaluationRatingUpdate.success]);

  // Upload evaluation ratings
  useEffect(() => {
    if (openingEvaluationRatingsUpload.success) {
      dispatch(resetUploadOpeningEvaluationRatingsAction());

      setEvaluationRatingsInitialised(false);

      dispatch(
        listOpeningEvaluationRatingsAction({ ...openingEvaluationRatingQuery })
      );

      dispatch(listOpeningEvaluationsAction({ ...openingEvaluationQuery }));
    }
    // eslint-disable-next-line
  }, [openingEvaluationRatingsUpload.success]);

  // Opening permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const opening_permission = new FormData();

    openingPermission.level
      ? opening_permission.append("level", openingPermission.level)
      : opening_permission.append("level", openingPermissionDefault.level);

    openingPermission.manager
      ? opening_permission.append("manager", openingPermission.manager)
      : opening_permission.append("manager", openingPermissionDefault.manager);

    opening_permission.append("opening", id);

    // Dispatch action
    dispatch(createOpeningPermissionAction(opening_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteOpeningPermissionAction(
        openingPermissionDetails.opening_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const opening_permission = new FormData();

    openingPermission.level
      ? opening_permission.append("level", openingPermission.level)
      : opening_permission.append("level", openingPermissionDefault.level);

    openingPermission.manager
      ? opening_permission.append("manager", openingPermission.manager)
      : opening_permission.append("manager", openingPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateOpeningPermissionAction(
        openingPermissionDetails.opening_permission.id,
        opening_permission
      )
    );
  };

  // Download editable opening document
  const [editableOpeningDocumentLoading, setEditableOpeningDocumentLoading] =
    useState(false);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.opening_file !== undefined) {
        fetch(lastJsonMessage.message.opening_file)
          .then((res) => res.blob())
          .then((blob) => {
            saveAs(
              blob,
              `Position specification for ${openingDetails.opening.name}.docx`
            );
          });

        setEditableOpeningDocumentLoading(false);

        dispatch(resetDownloadOpeningAction());

        setDownloadEditableOpeningDocumentIsOpen(false);
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (openingDownload.error) {
      AppToaster.show({
        icon: "issue",
        intent: "danger",
        message: "We couldn't download the file. Please try again.",
      });
    } else if (openingDownload.success) {
      AppToaster.show({
        icon: "info-sign",
        intent: "primary",
        message: "File downloading.",
      });
    }
  }, [openingDownload.error, openingDownload.success]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the opening details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Opening name */}
                  <FormDrawerTextField2
                    error={openingUpdate.error}
                    fieldName="name"
                    helperText="Leave blank to use the role name"
                    icon="label"
                    label="Opening name"
                    placeholder="Enter the opening's name"
                    setValueObject={setOpening}
                    setValueVariable="name"
                    value={opening.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Role */}
                  <FormDrawerSelectField2
                    error={openingUpdate.error}
                    fieldName="role"
                    helperText="You can choose from your organisation's standard roles"
                    icon="hat"
                    info={
                      <RoleInfo
                        roleId={opening.role}
                        query={roleQueryDefault}
                      />
                    }
                    items={roleList.roles}
                    label="Role"
                    labelInfo="(required)"
                    loading={roleList.loading}
                    placeholder="Select a role"
                    query={roleQuery.searchString}
                    setQueryObject={setRoleQuery}
                    setQueryVariable="searchString"
                    setValueObject={setOpening}
                    setValueIdVariable="role"
                    setValueNameVariable="roleName"
                    showInfo={opening.role}
                    value={opening.roleName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Budget */}
                  <FormDrawerNumericField2
                    error={openingUpdate.error}
                    fieldName="value"
                    helperText="Total annual remuneration package"
                    label="Budget"
                    labelInfo="(required)"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {activeOrganisation.currency}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter total remuneration"
                    setValueObject={setOpening}
                    setValueVariable="value"
                    stepSize={10000}
                    value={opening.value}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "40%",
                  }}
                >
                  {/* Location */}
                  <FormDrawerSelectField2
                    error={openingUpdate.error}
                    fieldName="location"
                    helperText="You can choose from your organisation's locations"
                    icon="area-of-interest"
                    info={
                      <LocationInfo
                        locationId={opening.location}
                        query={locationQueryDefault}
                      />
                    }
                    items={locationList.locations}
                    label="Location"
                    loading={locationList.loading}
                    noOptionOnClick
                    placeholder="Select a location"
                    query={locationQuery.searchString}
                    setQueryObject={setLocationQuery}
                    setQueryVariable="searchString"
                    setValueObject={setOpening}
                    setValueIdVariable="location"
                    setValueNameVariable="locationName"
                    showInfo={opening.location}
                    value={opening.locationName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "20%" }}>
                  {/* Employment type */}
                  <FormDrawerSelectSimpleField3
                    error={openingUpdate.error}
                    fieldName="employment"
                    label="Employment type"
                    options={[
                      { id: 1, icon: "full-circle", value: "Permanent" },
                      {
                        id: 2,
                        icon: "ring",
                        value: "Contract",
                      },
                      { id: 3, icon: "circle", value: "Casual" },
                    ]}
                    setValueObject={setOpening}
                    setValueVariable="employment"
                    value={opening.employment}
                  />
                </div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={openingUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the opening"
                setValueObject={setOpening}
                setValueVariable="notes"
                value={opening.notes}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "30%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={openingUpdate.error}
                    fieldName="status"
                    intent={
                      opening.status === "Filled"
                        ? "success"
                        : opening.status === "Interviewing"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "refresh", value: "Interviewing" },
                      {
                        id: 2,
                        icon: "confirm",
                        value: "Filled",
                      },
                      { id: 3, icon: "remove", value: "On-hold" },
                    ]}
                    setValueObject={setOpening}
                    setValueVariable="status"
                    value={opening.status}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "35%",
                  }}
                >
                  {/* Opened date */}
                  <FormDrawerDateField2
                    error={openingUpdate.error}
                    fieldName="opened"
                    icon="calendar"
                    label="Opened date"
                    placeholder="Enter the opened date"
                    setValueObject={setOpening}
                    setValueVariable="opened"
                    value={opening.opened}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "35%" }}>
                  {/* Close date */}
                  <FormDrawerDateField2
                    error={openingUpdate.error}
                    fieldName="closed"
                    icon="calendar"
                    label="Close date"
                    placeholder="Enter the closed date"
                    setValueObject={setOpening}
                    setValueVariable="closed"
                    value={opening.closed}
                  />
                </div>
              </div>
            </Card>

            <Card>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      openingDetails.opening.user_access_status === "edit" ||
                      openingDetails.opening.user_access_status === "view"
                    }
                    error={openingUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted opening can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setOpening}
                    setValueVariable="restricted"
                    value={opening.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      openingDetails.opening.user_access_status === "edit" ||
                      openingDetails.opening.user_access_status === "view"
                    }
                    error={openingUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the opening - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setOpening}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={opening.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the opening manager and the
                    opening is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={openingUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Updating an opening"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={openingDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listOpeningDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateOpeningAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingUpdate.success}
        saveSuccessMessage="Opening updated."
        saving={openingUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={openingPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setOpeningPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={openingPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={openingPermissionCreate.error}
              fieldName="level"
              intent={
                openingPermission.level === "Edit"
                  ? "success"
                  : openingPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setOpeningPermission}
              setValueVariable="level"
              value={openingPermission.level}
            />
          </Card>
        }
        error={openingPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listOpeningPermissionsAction({ opening: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateOpeningPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={openingPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={openingPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setOpeningPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={openingPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={openingPermissionUpdate.error}
              fieldName="level"
              intent={
                openingPermission.level === "Edit"
                  ? "success"
                  : openingPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setOpeningPermission}
              setValueVariable="level"
              value={openingPermission.level}
            />
          </Card>
        }
        deleteSuccess={openingPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={openingPermissionDelete.loading}
        error={openingPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={openingPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listOpeningPermissionsAction({ ...openingPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteOpeningPermissionAction());

          dispatch(resetUpdateOpeningPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={openingPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* Application form configuration form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ width: "100%" }}>
              <H5>Configure application form</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* Introduction */}
              <FormDrawerTextAreaField3
                error={openingApplicationConfigurationUpdate.error}
                fieldName="intro"
                helperText="This content will be shown at the top of the form"
                height="100px"
                label="Introduction"
                placeholder="Add an introduction to the application form"
                setValueObject={setOpeningApplicationConfiguration}
                setValueVariable="intro"
                value={openingApplicationConfiguration.intro}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Recent employment */}
                  <FormDrawerSwitchField
                    error={openingApplicationConfigurationUpdate.error}
                    fieldName="employment"
                    helperText="Ask for current or last job details"
                    label="Recent employment"
                    setValueObject={setOpeningApplicationConfiguration}
                    setValueVariable="employment"
                    value={openingApplicationConfiguration.employment}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Remuneration */}
                  <FormDrawerSwitchField
                    error={openingApplicationConfigurationUpdate.error}
                    fieldName="remuneration"
                    helperText="Ask for expected remuneration"
                    label="Remuneration"
                    setValueObject={setOpeningApplicationConfiguration}
                    setValueVariable="remuneration"
                    value={openingApplicationConfiguration.remuneration}
                  />
                </div>
              </div>

              <div style={{ display: "flex", marginBottom: "15px" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Police check */}
                  <FormDrawerSwitchField
                    error={openingApplicationConfigurationUpdate.error}
                    fieldName="police_check"
                    helperText="Confirm the candidate is willing to undergo police checks"
                    label="Police check"
                    setValueObject={setOpeningApplicationConfiguration}
                    setValueVariable="policeCheck"
                    value={openingApplicationConfiguration.policeCheck}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Additional information */}
              <FormDrawerTextAreaField3
                error={openingApplicationConfigurationUpdate.error}
                fieldName="notes_guidance"
                height="100px"
                helperText="Use this field to ask for information not typically included in the applicant's resume"
                label="Additional information"
                placeholder="Describe any additional information you would like applicants to provide"
                setValueObject={setOpeningApplicationConfiguration}
                setValueVariable="notesGuidance"
                value={openingApplicationConfiguration.notesGuidance}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Close date */}
                  <FormDrawerDateField2
                    error={openingApplicationConfigurationUpdate.error}
                    fieldName="close"
                    helperText="Stop accepting applications after this date"
                    icon="calendar"
                    label="Close date"
                    placeholder="Enter the close date"
                    setValueObject={setOpeningApplicationConfiguration}
                    setValueVariable="close"
                    value={openingApplicationConfiguration.close}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}></div>
              </div>
            </Card>
          </div>
        }
        error={openingApplicationConfiguration.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Configure application form"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Configuring the application form"
        icon="edit"
        isOpen={updateApplicationConfigFormDrawerIsOpen}
        loading={openingApplicationConfigurationDetails.loading}
        onSave={updateApplicationConfig}
        refreshList={() => {
          dispatch(
            listOpeningApplicationConfigurationDetailsAction(
              openingDetails.opening.opening_application_configuration
            )
          );
        }}
        resetAction={() => {
          dispatch(resetUpdateOpeningApplicationConfigurationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingApplicationConfigurationUpdate.success}
        saveSuccessMessage="Application form configuration updated."
        saving={openingApplicationConfigurationUpdate.loading}
        setIsOpen={setUpdateApplicationConfigFormDrawerIsOpen}
        title="Configure application form"
      />

      {/* Existing candidate form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Review the candidate</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {openingCandidateDetails.opening_candidate.application_data ? (
                <>
                  <TabSectionFieldCollapse
                    content={
                      <TabSectionField
                        bottom
                        label={
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                openingCandidateDetails.opening_candidate
                                  .application_data.profile_photo
                                  ? openingCandidateDetails.opening_candidate
                                      .application_data.profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "150px",
                                margin: "10px 0 10px 0",
                                objectFit: "contain",
                                width: "150px",
                              }}
                            />

                            <Text ellipsize>
                              {
                                openingCandidateDetails.opening_candidate
                                  .application_data.name
                              }
                            </Text>
                          </div>
                        }
                        value={
                          <>
                            <TabSectionFieldMultifieldCard
                              children={
                                <>
                                  {/* Email */}
                                  <TabSectionField
                                    label="Email"
                                    value={
                                      <TabSectionEmailLink
                                        email={
                                          openingCandidateDetails
                                            .opening_candidate.application_data
                                            .email
                                        }
                                      />
                                    }
                                    vertical
                                  />

                                  {/* Preferred name */}
                                  <TabSectionField
                                    label="Preferred name"
                                    value={
                                      openingCandidateDetails.opening_candidate
                                        .application_data.preferred_name
                                    }
                                    vertical
                                  />

                                  {/* Phone */}
                                  <TabSectionField
                                    label="Phone"
                                    value={
                                      openingCandidateDetails.opening_candidate
                                        .application_data.phone
                                    }
                                    vertical
                                  />

                                  {/* LinkedIn page */}
                                  <TabSectionField
                                    bottom
                                    label="LinkedIn page"
                                    value={
                                      <TabSectionFieldLink
                                        url={
                                          openingCandidateDetails
                                            .opening_candidate.application_data
                                            .linkedin_page
                                        }
                                      />
                                    }
                                    vertical
                                  />
                                </>
                              }
                            />

                            <div style={{ textAlign: "end" }}>
                              <Button
                                intent="primary"
                                minimal
                                onClick={() =>
                                  window.open(
                                    `/contacts/${openingCandidateDetails.opening_candidate.application_data.contact}`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                                }
                                small
                                style={{
                                  marginTop: "5px",
                                }}
                                text="Open contact record"
                              />
                            </div>
                          </>
                        }
                      />
                    }
                    icon="id-number"
                    title="Contact information"
                  />

                  <TabSectionFieldCollapse
                    content={
                      <>
                        {/* Currently employed */}
                        <TabSectionField
                          label="Currently employed"
                          value={
                            <Tag
                              intent={
                                openingCandidateDetails.opening_candidate
                                  .application_data.employed
                                  ? "success"
                                  : "danger"
                              }
                              round
                              style={{
                                textAlign: "center",
                                width: "75px",
                              }}
                            >
                              {openingCandidateDetails.opening_candidate
                                .application_data.employed
                                ? "Yes"
                                : "No"}
                            </Tag>
                          }
                        />

                        {/* Employer */}
                        <TabSectionField
                          label="Employer"
                          helperText="Current or most recent employer"
                          value={
                            openingCandidateDetails.opening_candidate
                              .application_data.employer
                          }
                        />

                        {/* Role */}
                        <TabSectionField
                          label="Role"
                          helperText="Current or most recent job title"
                          value={
                            openingCandidateDetails.opening_candidate
                              .application_data.role
                          }
                        />

                        {/* Employment type */}
                        <TabSectionField
                          label="Employment type"
                          value={
                            openingCandidateDetails.opening_candidate
                              .application_data.employment
                          }
                        />

                        {/* Start date */}
                        <TabSectionField
                          label="Start date"
                          value={
                            <DateValue
                              date={
                                openingCandidateDetails.opening_candidate
                                  .application_data.role_start
                              }
                            />
                          }
                        />

                        {/* End date */}
                        <TabSectionField
                          bottom
                          label="End date"
                          value={
                            <DateValue
                              date={
                                openingCandidateDetails.opening_candidate
                                  .application_data.role_end
                              }
                            />
                          }
                        />
                      </>
                    }
                    icon="office"
                    title="Recent employment"
                  />

                  <TabSectionFieldCollapse
                    content={
                      <>
                        {/* Resume */}
                        <TabSectionField
                          label="Resume"
                          value={
                            <TabSectionFieldLink
                              shorten
                              url={
                                openingCandidateDetails.opening_candidate
                                  .application_data.resume
                              }
                            />
                          }
                        />

                        {/* Experience */}
                        <TabSectionField
                          label="Experience"
                          helperText="Years of relevant experience"
                          value={
                            openingCandidateDetails.opening_candidate
                              .application_data.experience
                          }
                        />

                        {/* Notice period */}
                        <TabSectionField
                          label="Notice period"
                          helperText="Minimum notice period in weeks"
                          value={
                            openingCandidateDetails.opening_candidate
                              .application_data.notice
                          }
                        />

                        {/* Expected remuneration */}
                        <TabSectionField
                          label="Expected remuneration"
                          helperText="Total expected remuneration"
                          value={
                            <NumericValue
                              currency={activeOrganisation.currency}
                              number={
                                openingCandidateDetails.opening_candidate
                                  .application_data.remuneration
                              }
                            />
                          }
                        />

                        {/* References */}
                        <TabSectionField
                          helperText={
                            openingCandidateDetails.opening_candidate
                              .application_data.references
                              ? "Applicant can provide references"
                              : "Application cannot provide references"
                          }
                          label="References"
                          value={
                            <Tag
                              intent={
                                openingCandidateDetails.opening_candidate
                                  .application_data.references
                                  ? "success"
                                  : "danger"
                              }
                              round
                              style={{
                                textAlign: "center",
                                width: "75px",
                              }}
                            >
                              {openingCandidateDetails.opening_candidate
                                .application_data.references
                                ? "Yes"
                                : "No"}
                            </Tag>
                          }
                        />

                        {/* Police check */}
                        <TabSectionField
                          helperText={
                            openingCandidateDetails.opening_candidate
                              .application_data.police_check
                              ? "Applicant is willing to undergo police checks"
                              : "Applicant is not willing to undergo police checks"
                          }
                          label="Police check"
                          value={
                            <Tag
                              intent={
                                openingCandidateDetails.opening_candidate
                                  .application_data.police_check
                                  ? "success"
                                  : "danger"
                              }
                              round
                              style={{
                                textAlign: "center",
                                width: "75px",
                              }}
                            >
                              {openingCandidateDetails.opening_candidate
                                .application_data.police_check
                                ? "Yes"
                                : "No"}
                            </Tag>
                          }
                        />

                        {/* Additional information */}
                        <TabSectionField
                          bottom
                          label="Additional information"
                          value={
                            openingCandidateDetails.opening_candidate
                              .application_data.notes
                          }
                        />
                      </>
                    }
                    icon="following"
                    title="Application"
                  />
                </>
              ) : undefined}

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={openingCandidateUpdate.error}
                    fieldName="status"
                    intent={
                      openingCandidate.status === "Pending"
                        ? "primary"
                        : openingCandidate.status === "Rejected"
                        ? "danger"
                        : "success"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "circle", value: "Pending" },
                      {
                        id: 2,
                        icon: "remove",
                        value: "Rejected",
                      },
                      {
                        id: 3,
                        icon: "add",
                        value: "Shortlisted",
                      },
                    ]}
                    setValueObject={setOpeningCandidate}
                    setValueVariable="status"
                    value={openingCandidate.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}></div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={openingCandidateUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the candidate"
                setValueObject={setOpeningCandidate}
                setValueVariable="notes"
                value={openingCandidate.notes}
              />
            </Card>
          </div>
        }
        error={openingCandidateUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Review candidate"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Reviewing candidates"
        icon="edit"
        isOpen={updateCandidateDrawerIsOpen}
        loading={openingCandidateDetails.loading}
        onSave={updateCandidate}
        refreshList={() => {
          dispatch(listOpeningCandidatesAction({ ...openingCandidateQuery }));

          dispatch(listOpeningEvaluationsAction({ opening: id }));
        }}
        resetAction={() => {
          dispatch(resetUpdateOpeningCandidateAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingCandidateUpdate.success}
        saveSuccessMessage="Candidate updated."
        saving={openingCandidateUpdate.loading}
        setIsOpen={setUpdateCandidateDrawerIsOpen}
        size="medium"
        title="Review candidate"
      />

      {/* New evaluation form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the evaluation</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Candidate */}
                <FormDrawerSelectField2
                  allowCreate
                  error={openingEvaluationCreate.error}
                  fieldName="candidate"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={openingEvaluation.candidate}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Candidate"
                  labelInfo="(required)"
                  loading={contactList.loading}
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setOpeningEvaluation}
                  setValueIdVariable="candidate"
                  setValueNameVariable="candidateName"
                  showInfo={openingEvaluation.candidate}
                  value={openingEvaluation.candidateName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Interviewer */}
                <FormDrawerSelectField2
                  error={openingEvaluationCreate.error}
                  fieldName="manager"
                  icon="user"
                  items={managerList.managers}
                  label="Interviewer"
                  loading={managerList.loading}
                  placeholder="Select an interviewer"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setOpeningEvaluation}
                  setValueIdVariable="interviewer"
                  setValueNameVariable="interviewerName"
                  value={openingEvaluation.interviewerName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Resume */}
                <FormDrawerFileField2
                  error={openingEvaluationCreate.error}
                  fileName="resume"
                  label="Resume"
                  placeholder="Upload the candidate's resume"
                  setValueObject={setOpeningEvaluation}
                  setValueFileVariable="resume"
                  setValueNameVariable="resumeName"
                  valueFile={openingEvaluation.resume}
                  valueName={openingEvaluation.resumeName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}></div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={openingEvaluationCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the candidate"
              setValueObject={setOpeningEvaluation}
              setValueVariable="notes"
              value={openingEvaluation.notes}
            />
          </Card>
        }
        error={openingEvaluationCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add evaluation"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Evaluating candidates"
        icon="edit"
        isOpen={addEvaluationDrawerIsOpen}
        onSave={addEvaluation}
        refreshList={() => {
          dispatch(listOpeningEvaluationsAction({ opening: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateOpeningEvaluationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingEvaluationCreate.success}
        saveSuccessMessage="Evaluation added."
        saving={openingEvaluationCreate.loading}
        setIsOpen={setAddEvaluationDrawerIsOpen}
        size="medium"
        title="Add evaluation"
      />

      {/* Existing evaluation form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the evaluation</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Candidate */}
                  <FormDrawerSelectField2
                    allowCreate
                    disabled
                    error={openingEvaluationUpdate.error}
                    fieldName="candidate"
                    helperText="You can create or select an existing contact"
                    icon="id-number"
                    info={
                      <ContactInfo
                        contactId={openingEvaluation.candidate}
                        query={contactQueryDefault}
                      />
                    }
                    items={contactList.contacts}
                    label="Candidate"
                    labelInfo="(required)"
                    loading={contactList.loading}
                    placeholder="Create or select a contact"
                    query={contactQuery.searchString}
                    setQueryObject={setContactQuery}
                    setQueryVariable="searchString"
                    setValueObject={setOpeningEvaluation}
                    setValueIdVariable="candidate"
                    setValueNameVariable="candidateName"
                    showInfo={openingEvaluation.candidate}
                    value={openingEvaluation.candidateName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Interviewer */}
                  <FormDrawerSelectField2
                    error={openingEvaluationUpdate.error}
                    fieldName="manager"
                    icon="user"
                    items={managerList.managers}
                    label="Interviewer"
                    loading={managerList.loading}
                    placeholder="Select an interviewer"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setOpeningEvaluation}
                    setValueIdVariable="interviewer"
                    setValueNameVariable="interviewerName"
                    value={openingEvaluation.interviewerName}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Resume */}
                  <FormDrawerFileField2
                    error={openingEvaluationUpdate.error}
                    fileName="resume"
                    label="Resume"
                    onSave={(file) => {
                      const opening_evaluation = new FormData();

                      opening_evaluation.append("resume", file);

                      dispatch(
                        updateOpeningEvaluationAction(
                          openingEvaluationDetails.opening_evaluation.id,
                          opening_evaluation
                        )
                      );
                    }}
                    placeholder="Upload the candidate's resume"
                    postSave={() => {
                      setOpeningEvaluation((prevState) => {
                        return {
                          ...prevState,
                          resume:
                            openingEvaluationUpdate.opening_evaluation.resume,
                        };
                      });

                      dispatch(resetUpdateOpeningEvaluationAction());
                    }}
                    saveSuccess={openingEvaluationUpdate.success}
                    setValueObject={setOpeningEvaluation}
                    setValueFileVariable="resume"
                    setValueNameVariable="resumeName"
                    showDownload
                    valueFile={openingEvaluation.resume}
                    valueName={openingEvaluation.resumeName}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    paddingTop: "22px",
                    width: "50%",
                  }}
                >
                  <div style={{ marginRight: "20px" }}>
                    <GenerateFromFileButton
                      disabled={
                        openingDetails.opening.user_access_status === "view" ||
                        !openingEvaluation.candidateName ||
                        !openingEvaluation.resume ||
                        openingEvaluationRatingList.count === 0 ||
                        openingEvaluationUpdate.loading
                      }
                      filename={openingEvaluation.resume}
                      objectDescription={`${
                        openingEvaluation.candidateName
                      }, who may have the following competencies: ${openingEvaluationRatingList.opening_evaluation_ratings
                        .map(
                          (competency) =>
                            `${competency.role_competency_data.competency_data.name} (id: ${competency.id}, description: ${competency.role_competency_data.competency_data.description})`
                        )
                        .join(", ")}`}
                      objectType="staff"
                      onSuccess={(result) => {
                        if (
                          !("competencies" in result) ||
                          result.competencies.length === 0
                        ) {
                          AppToaster.show({
                            icon: "warning-sign",
                            intent: "warning",
                            message: "Unable to generate suitable content.",
                          });
                        } else {
                          AppToaster.show({
                            icon: "tick-circle",
                            intent: "success",
                            message: "Generation complete.",
                          });
                        }

                        setOpeningEvaluation((prevState) => {
                          return {
                            ...prevState,
                            notes: result.notes,
                          };
                        });

                        dispatch(
                          uploadOpeningEvaluationRatingsAction(
                            result.competencies
                          )
                        );
                      }}
                      text="Evaluate"
                    />
                  </div>

                  <Callout intent="warning">
                    Using AI-enabled evaluation will overwrite previous ratings.
                  </Callout>
                </div>
              </div>
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Rate the candidate</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <Callout
                intent="primary"
                style={{ marginBottom: "15px", width: "100%" }}
              >
                The following criteria are based on the requirements of the{" "}
                {openingDetails.opening.role_data
                  ? openingDetails.opening.role_data.name
                  : undefined}{" "}
                role .
              </Callout>

              {
                <TabSectionFieldMultifieldCardList
                  content={Object.keys(evaluationRatings).map(
                    (rating_key, i, { length }) => {
                      return (
                        <div key={rating_key} style={{ marginTop: "-10px" }}>
                          <TabSectionField
                            bottom={length - 1 === i}
                            value={
                              <TabSectionFieldMultifieldCard
                                children={
                                  <>
                                    {/* Competency */}
                                    <TabSectionField
                                      label="Competency"
                                      value={evaluationRatings[rating_key].name}
                                    />

                                    {/* Required level */}
                                    <TabSectionField
                                      helperText={
                                        evaluationRatings[rating_key]
                                          .required === 5
                                          ? "Expert"
                                          : evaluationRatings[rating_key]
                                              .required >= 3
                                          ? "Intermediate"
                                          : "Beginner"
                                      }
                                      label="Required level"
                                      value={
                                        <TabSectionFieldProgressBar
                                          intent="success"
                                          rating={
                                            evaluationRatings[rating_key]
                                              .required / 5
                                          }
                                        />
                                      }
                                    />

                                    {/* Description */}
                                    <TabSectionField
                                      label="Description"
                                      value={
                                        evaluationRatings[rating_key]
                                          .description
                                      }
                                    />

                                    {/* Rating */}
                                    <TabSectionField
                                      label="Rating"
                                      subLabel="Rate the candidate against this competency"
                                      value={
                                        <div
                                          style={{ padding: "5px 35px 0 35px" }}
                                        >
                                          <Slider
                                            intent="success"
                                            labelRenderer={(value) => {
                                              switch (value) {
                                                case 1:
                                                  return "Beginner";
                                                case 3:
                                                  return "Intermediate";
                                                case 5:
                                                  return "Expert";
                                                default:
                                                  return null;
                                              }
                                            }}
                                            labelStepSize={1}
                                            max={5}
                                            min={1}
                                            onChange={(value) => {
                                              const newRating = {
                                                ...evaluationRatings[
                                                  rating_key
                                                ],
                                                level: value,
                                              };

                                              setEvaluationRatings(
                                                (prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [rating_key]: newRating,
                                                  };
                                                }
                                              );

                                              setOpeningEvaluationRating(
                                                newRating
                                              );
                                            }}
                                            value={
                                              evaluationRatings[rating_key]
                                                .level
                                            }
                                          />
                                        </div>
                                      }
                                    />

                                    {/* Comments */}
                                    <TabSectionField
                                      bottom
                                      label="Comments"
                                      subLabel="Explain your rating"
                                      value={
                                        <TextArea
                                          fill
                                          growVertically
                                          onChange={(e) => {
                                            const newRating = {
                                              ...evaluationRatings[rating_key],
                                              comments: e.target.value,
                                            };

                                            setEvaluationRatings(
                                              (prevState) => {
                                                return {
                                                  ...prevState,
                                                  [rating_key]: newRating,
                                                };
                                              }
                                            );

                                            setOpeningEvaluationRating(
                                              newRating
                                            );
                                          }}
                                          placeholder="Click to add comment"
                                          style={{
                                            height: "200px",
                                            resize: "vertical",
                                          }}
                                          value={
                                            evaluationRatings[rating_key]
                                              .comments
                                          }
                                        />
                                      }
                                    />
                                  </>
                                }
                              />
                            }
                            vertical
                          />
                        </div>
                      );
                    }
                  )}
                  count={openingEvaluationRatingList.count}
                  loading={openingEvaluationRatingList.loading}
                  noResultsMessage="Assign competencies to the role."
                  noResultsTitle="No competencies found"
                  page={openingEvaluationRatingQuery.page}
                  setPageObject={setOpeningEvaluationRatingQuery}
                  setPageVariable="page"
                />
              }
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Capture additional information</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              {/* References summary */}
              <FormDrawerTextAreaField3
                error={openingEvaluationUpdate.error}
                fieldName="references_comments"
                height="100px"
                label="References summary"
                placeholder="Summarise the references taken"
                setValueObject={setOpeningEvaluation}
                setValueVariable="referencesComments"
                value={openingEvaluation.referencesComments}
              />

              {/* Police check outcomes */}
              <FormDrawerTextAreaField3
                error={openingEvaluationUpdate.error}
                fieldName="police_check_comments"
                height="100px"
                label="Police check outcomes"
                placeholder="Capture the outcome of any police checks undertaken"
                setValueObject={setOpeningEvaluation}
                setValueVariable="policeCheckComments"
                value={openingEvaluation.policeCheckComments}
              />

              {/* Remuneration expectations */}
              <FormDrawerTextAreaField3
                error={openingEvaluationUpdate.error}
                fieldName="remuneration_comments"
                height="100px"
                label="Remuneration expectations"
                placeholder="Summarise the candidate's expected remuneration"
                setValueObject={setOpeningEvaluation}
                setValueVariable="remunerationComments"
                value={openingEvaluation.remunerationComments}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={openingEvaluationUpdate.error}
                    fieldName="status"
                    intent={
                      openingEvaluation.status === "Not selected"
                        ? "primary"
                        : "success"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "circle", value: "Not selected" },
                      {
                        id: 2,
                        icon: "confirm",
                        value: "Selected",
                      },
                    ]}
                    setValueObject={setOpeningEvaluation}
                    setValueVariable="status"
                    value={openingEvaluation.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "60%" }}></div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={openingEvaluationUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the candidate"
                setValueObject={setOpeningEvaluation}
                setValueVariable="notes"
                value={openingEvaluation.notes}
              />
            </Card>
          </div>
        }
        deleteSuccess={openingEvaluationDelete.success}
        deleteSuccessMessage="Evaluation removed."
        deleting={openingEvaluationDelete.loading}
        error={openingEvaluationUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update evaluation"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Evaluating candidates"
        icon="edit"
        isOpen={updateEvaluationDrawerIsOpen}
        loading={openingEvaluationDetails.loading}
        onDelete={deleteEvaluation}
        onSave={updateEvaluation}
        refreshList={() => {
          dispatch(listOpeningEvaluationsAction({ ...openingEvaluationQuery }));

          dispatch(listOpeningDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetDeleteOpeningEvaluationAction());

          dispatch(resetUpdateOpeningEvaluationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={openingEvaluationUpdate.success}
        saveSuccessMessage="Evaluation updated."
        saving={openingEvaluationUpdate.loading}
        setIsOpen={setUpdateEvaluationDrawerIsOpen}
        size="medium"
        title="Update evaluation"
      />

      {/* New approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request to hire the selected candidate</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    marginRight: "15px",
                    textAlign: "center",
                    width: "35%",
                  }}
                >
                  <img
                    alt="profile"
                    src={
                      openingDetails.opening.selected_candidate_profile_photo
                        ? openingDetails.opening
                            .selected_candidate_profile_photo
                        : "/images/no_photo.png"
                    }
                    style={{
                      aspectRatio: "1/1",
                      background: "white",
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: "50%",
                      margin: "10px 0 10px 0",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />

                  <Text ellipsize>
                    {openingDetails.opening.selected_candidate_name}
                  </Text>
                </div>

                <div style={{ marginLeft: "15px", width: "65%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    error={approvalRequestCreate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />

                  {/* Comments */}
                  <FormDrawerTextAreaField3
                    error={approvalRequestCreate.error}
                    fieldName="comments"
                    label="Comments"
                    placeholder="Enter addition information you would like to submit with the request"
                    setValueObject={setApprovalRequest}
                    setValueVariable="comments"
                    value={approvalRequest.comments}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    error={approvalRequestCreate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={approvalRequestCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create approval request"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={addApprovalRequestDrawerIsOpen}
        onSave={addApprovalRequest}
        refreshList={() => {
          dispatch(
            listApprovalRequestsAction({
              itemId: id,
              itemType: "opening",
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestCreate.success}
        saveSuccessMessage="Approval request created."
        saving={approvalRequestCreate.loading}
        setIsOpen={setAddApprovalRequestDrawerIsOpen}
        title="Create approval request"
      />

      {/* Existing approval request form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the approval request to hire the selected candidate</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    marginRight: "15px",
                    textAlign: "center",
                    width: "35%",
                  }}
                >
                  <img
                    alt="profile"
                    src={
                      openingDetails.opening.selected_candidate_profile_photo
                        ? openingDetails.opening
                            .selected_candidate_profile_photo
                        : "/images/no_photo.png"
                    }
                    style={{
                      aspectRatio: "1/1",
                      background: "white",
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: "50%",
                      margin: "10px 0 10px 0",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />

                  <Text ellipsize>
                    {openingDetails.opening.selected_candidate_name}
                  </Text>
                </div>

                <div style={{ marginLeft: "15px", width: "65%" }}>
                  {/* Approver */}
                  <FormDrawerSelectField2
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="approver"
                    helperText="You can select people in your organisation who have authority to approve the decision"
                    icon="user"
                    items={filteredManagerList}
                    label="Approver"
                    labelInfo="(required)"
                    loading={managerList.loading}
                    placeholder="Select an approver"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setApprovalRequest}
                    setValueIdVariable="approver"
                    setValueNameVariable="approverName"
                    value={approvalRequest.approverName}
                  />

                  {/* Comments */}
                  <FormDrawerTextAreaField3
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="comments"
                    label="Comments"
                    placeholder="Enter addition information you would like to submit with the request"
                    setValueObject={setApprovalRequest}
                    setValueVariable="comments"
                    value={approvalRequest.comments}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Submit for approval</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Submit */}
                  <FormDrawerButtonField
                    disabled={
                      approvalRequestDetails.approval_request.status ===
                      "Submitted"
                    }
                    error={approvalRequestUpdate.error}
                    fieldName="status"
                    helperText="Do not submit to keep the request in draft"
                    label="Submit"
                    options={[
                      {
                        icon: "pause",
                        id: 1,
                        intent: "primary",
                        label: "Do not submit",
                        value: "Draft",
                      },
                      {
                        icon: "play",
                        id: 2,
                        intent: "success",
                        label: "Submit for review",
                        value: "Submitted",
                      },
                    ]}
                    setValueObject={setApprovalRequest}
                    setValueVariable="status"
                    value={approvalRequest.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    Once you submit the approval request, you will not be able
                    to change it. If you want to make changes at that point, you
                    can delete the request and create a new one, provided the
                    approver has not responded. If the approver has already
                    approved or declined the request, then the request becomes
                    immutable (to preserve a record of the decision). However,
                    you can create a new request for the same decision. You may
                    have to submit a new request when there has been a material
                    change in the facts that the decision was predicated upon.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        deleteSuccess={approvalRequestDelete.success}
        deleteSuccessMessage="Approval request deleted."
        deleting={approvalRequestDelete.loading}
        error={approvalRequestUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update approval request"
            module="Openings"
            view="Detail"
          />
        }
        helpTitle="Requesting approvals"
        icon="edit"
        isOpen={updateApprovalRequestDrawerIsOpen}
        loading={approvalRequestDetails.loading}
        onDelete={deleteApprovalRequest}
        onSave={updateApprovalRequest}
        refreshList={() => {
          dispatch(listApprovalRequestsAction({ ...approvalRequestQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteApprovalRequestAction());

          dispatch(resetUpdateApprovalRequestAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={approvalRequestUpdate.success}
        saveSuccessMessage="Approval request updated."
        saving={approvalRequestUpdate.loading}
        setIsOpen={setUpdateApprovalRequestDrawerIsOpen}
        title="Update approval request"
      />

      {/* Detail drawers */}
      {/* Approval request */}
      <ApprovalDetailDrawer
        approvalRequestDetails={approvalRequestDetails}
        setIsOpen={setViewApprovalDrawerIsOpen}
        isOpen={viewApprovalDrawerIsOpen}
      />

      {/* Action dialogs */}
      {/* Download editable opening document action dialog */}
      <ActionDialog
        buttons={
          <Button
            icon="download"
            intent="primary"
            loading={editableOpeningDocumentLoading}
            onClick={() => {
              setEditableOpeningDocumentLoading(true);

              dispatch(downloadOpeningAction(id));
            }}
            text="Download"
          />
        }
        children={
          <p>
            This is an editable and unstyled version of the position
            specification in Microsoft Word format. It includes the same
            information as the document that advaise.app has generated as a PDF.
            You can use the Word file to completely customise the look of your
            position specification.
          </p>
        }
        icon="download"
        isOpen={downloadEditableOpeningDocumentIsOpen}
        message={
          openingDownload.error
            ? "We couldn't download the file. Please try again."
            : openingDownload.success
            ? "File downloading."
            : undefined
        }
        messageIntent={
          openingDownload.error
            ? "danger"
            : openingDownload.success
            ? "primary"
            : undefined
        }
        onClose={() => {
          dispatch(resetDownloadOpeningAction());
        }}
        setIsOpen={setDownloadEditableOpeningDocumentIsOpen}
        title="Download editable opening document"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Openings"
              onClick={() => {
                dispatch(resetListOpeningsAction());

                navigate("/openings");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          openingDetails.opening.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={openingDetails.loading}
        title={`${openingDetails.opening.name}`}
      />

      {/* Error alerts */}
      {/* Approval requests loading error */}
      {approvalRequestList.error && (
        <ErrorAlert message="We couldn't load the approval request list. Try refreshing the page." />
      )}
      {/* Locations loading error */}
      {locationList.error && (
        <ErrorAlert message="We couldn't load the location list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Opening delete error */}
      {openingDelete.error && (
        <ErrorAlert message="We couldn't delete the opening. Try refreshing the page." />
      )}
      {/* Opening loading error */}
      {openingDetails.error && (
        <ErrorAlert message="We couldn't load the opening. Try refreshing the page." />
      )}
      {/* Opening application configuration loading error */}
      {openingApplicationConfigurationDetails.error && (
        <ErrorAlert message="We couldn't load the opening application configuration. Try refreshing the page." />
      )}
      {/* Opening candidates loading error */}
      {openingCandidateList.error && (
        <ErrorAlert message="We couldn't load the opening candidates. Try refreshing the page." />
      )}
      {/* Opening document loading error */}
      {openingDocumentDetails.error && (
        <ErrorAlert message="We couldn't load the opening document. Try refreshing the page." />
      )}
      {/* Opening evaluations loading error */}
      {openingEvaluationList.error && (
        <ErrorAlert message="We couldn't load the opening evaluations. Try refreshing the page." />
      )}
      {/* Opening evaluation ratings loading error */}
      {openingEvaluationRatingList.error && (
        <ErrorAlert message="We couldn't load the opening evaluation ratings. Try refreshing the page." />
      )}
      {/* Opening permissions loading error */}
      {openingPermissionList.error && (
        <ErrorAlert message="We couldn't load the opening permissions. Try refreshing the page." />
      )}
      {/* Roles loading error */}
      {roleList.error && (
        <ErrorAlert message="We couldn't load the role list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Opening"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="role-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="opening-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          openingDetails.opening.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listOpeningDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={openingDetails.loading}
                          value={openingDetails.opening.id}
                        />

                        {/* Opening name */}
                        <TabSectionField
                          label="Opening name"
                          loading={openingDetails.loading}
                          value={openingDetails.opening.name}
                        />

                        {/* Role */}
                        <TabSectionField
                          label="Role"
                          loading={openingDetails.loading}
                          value={
                            <div onClick={(e) => e.stopPropagation()}>
                              <TabSectionFieldLink
                                name={
                                  openingDetails.opening.role_data
                                    ? openingDetails.opening.role_data.name
                                    : undefined
                                }
                                sameTab
                                url={
                                  openingDetails.opening.role
                                    ? `/roles/${openingDetails.opening.role}`
                                    : undefined
                                }
                              />
                            </div>
                          }
                        />

                        {/* Budget */}
                        <TabSectionField
                          helperText="Total annual remuneration package"
                          label="Budget"
                          loading={openingDetails.loading}
                          value={
                            <NumericValue
                              currency={activeOrganisation.currency}
                              number={openingDetails.opening.value}
                            />
                          }
                        />

                        {/* Location */}
                        <TabSectionField
                          helperText={openingDetails.opening.location_address}
                          label="Location"
                          loading={openingDetails.loading}
                          value={openingDetails.opening.location_name}
                        />

                        {/* Employment */}
                        <TabSectionField
                          label="Employment"
                          loading={openingDetails.loading}
                          value={openingDetails.opening.employment}
                        />

                        {/* Notes */}
                        <TabSectionField
                          label="Notes"
                          loading={openingDetails.loading}
                          value={openingDetails.opening.notes}
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={openingDetails.loading}
                          value={
                            <Tag
                              intent={
                                openingDetails.opening.status === "Filled"
                                  ? "success"
                                  : openingDetails.opening.status ===
                                    "Interviewing"
                                  ? "warning"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {openingDetails.opening.status}
                            </Tag>
                          }
                        />

                        {/* Opened */}
                        <TabSectionField
                          label="Opened"
                          loading={openingDetails.loading}
                          value={
                            <DateValue date={openingDetails.opening.opened} />
                          }
                        />

                        {/* Closed */}
                        <TabSectionField
                          label="Closed"
                          loading={openingDetails.loading}
                          value={
                            <DateValue date={openingDetails.opening.closed} />
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            openingDetails.opening.restricted
                              ? "Users must have permission to access this opening"
                              : "Everyone in the organisation can edit this opening"
                          }
                          label="Access"
                          loading={openingDetails.loading}
                          value={
                            <Tag
                              intent={
                                openingDetails.opening.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {openingDetails.opening.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            openingDetails.opening.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={openingDetails.loading}
                          value={
                            <NameValue
                              email={openingDetails.opening.manager_name}
                              image={
                                openingDetails.opening.manager_profile_photo
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update opening information"
                    icon="shared-filter"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(openingDetails.opening.user_access_status === "owner" ||
                    openingDetails.opening.user_access_status === "admin" ||
                    openingDetails.opening.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={openingPermissionList.loading}
                              setValueObject={setOpeningPermissionQuery}
                              setValueVariable="searchString"
                              value={openingPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={openingPermissionList.opening_permissions.map(
                              (opening_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={opening_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listOpeningPermissionDetailsAction(
                                          opening_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={opening_permission}
                                  />
                                );
                              }
                            )}
                            count={openingPermissionList.count}
                            loading={openingPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={openingPermissionQuery.page}
                            setPageObject={setOpeningPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, roles are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete opening */}
                  {(openingDetails.opening.user_access_status === "owner" ||
                    openingDetails.opening.user_access_status === "admin" ||
                    openingDetails.opening.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              id="confirm-opening-name"
                              onChange={(e) => {
                                setOpeningDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the opening's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={openingDeleteKey}
                            />

                            <Button
                              icon="trash"
                              intent="danger"
                              loading={openingDelete.loading}
                              onClick={deleteOpening}
                              text="Delete this opening"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{openingDetails.opening.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the opening"
                      icon="delete"
                      message="If you delete this opening, you will lose all information associated with it."
                      messageIntent="danger"
                      title="Delete opening"
                    />
                  )}
                </div>
              }
            />

            {/* Detail */}
            <Tab
              id="opening-detail-tab"
              title="Detail"
              panel={
                <div>
                  {/* Specification */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            documentGeneratePdf.loading ||
                            openingDocumentDetails.loading ||
                            openingDocumentUpdate.loading
                          }
                          icon="refresh"
                          intent="primary"
                          onClick={() => {
                            updateDocument();
                          }}
                          text="Refresh"
                        />

                        <Popover2
                          content={
                            <Menu>
                              <MenuItem
                                icon="manual"
                                onClick={() => {
                                  saveAs(
                                    documentGeneratePdf.opening_pdf,
                                    `Position specification for ${openingDetails.opening.name}.pdf`
                                  );
                                }}
                                text="PDF"
                              />

                              <MenuItem
                                icon="document"
                                onClick={() => {
                                  setEditableOpeningDocumentLoading(true);

                                  dispatch(downloadOpeningAction(id));
                                }}
                                text="DOCX"
                              />
                            </Menu>
                          }
                          interactionKind={Popover2InteractionKind.HOVER}
                        >
                          <Button
                            icon="download"
                            intent="primary"
                            loading={
                              documentGeneratePdf.loading ||
                              editableOpeningDocumentLoading
                            }
                            style={{ margin: "0 0 0 10px" }}
                            text="Download"
                          />
                        </Popover2>
                      </>
                    }
                    content={
                      <TabSectionDocumentEditor3
                        document={documentGeneratePdf.opening_pdf}
                        error={documentGeneratePdf.error}
                        form={
                          <>
                            {/* Style */}
                            <TabSectionDocumentEditorFormContainer2
                              fields={
                                <>
                                  {/* Font */}
                                  <FormDrawerSelectSimpleField3
                                    error={openingDocumentUpdate.error}
                                    fieldName="font"
                                    label="Font"
                                    options={[
                                      { id: 1, icon: "font", value: "Courier" },
                                      {
                                        id: 2,
                                        icon: "font",
                                        value: "Helvetica",
                                      },
                                      {
                                        id: 3,
                                        icon: "font",
                                        value: "Montserrat",
                                      },
                                      { id: 4, icon: "font", value: "Roboto" },
                                      { id: 5, icon: "font", value: "Sintony" },
                                      {
                                        id: 6,
                                        icon: "font",
                                        value: "Times-Roman",
                                      },
                                    ]}
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="font"
                                    value={openingDocument.font}
                                  />

                                  {/* Company logo */}
                                  <FormDrawerFileField2
                                    error={openingDocumentUpdate.error}
                                    fileName="company_logo"
                                    label="Company logo"
                                    placeholder="Upload a logo image"
                                    setValueObject={setOpeningDocument}
                                    setValueFileVariable="companyLogo"
                                    setValueNameVariable="companyLogoName"
                                    valueFile={openingDocument.companyLogo}
                                    valueName={openingDocument.companyLogoName}
                                  />

                                  {/* Company logo preview */}
                                  <div style={{ margin: "0 0 20px 0" }}>
                                    {openingDocument.companyLogo ? (
                                      openingDocument.companyLogo !==
                                      openingDocumentDetails.opening_document
                                        .company_logo ? (
                                        <TabSectionFieldImage
                                          image={URL.createObjectURL(
                                            new Blob([
                                              openingDocument.companyLogo,
                                            ])
                                          )}
                                        />
                                      ) : (
                                        <TabSectionFieldImage
                                          image={
                                            openingDocumentDetails
                                              .opening_document.company_logo
                                          }
                                        />
                                      )
                                    ) : undefined}
                                  </div>
                                </>
                              }
                              title="Style"
                            />

                            {/* Company information */}
                            <TabSectionDocumentEditorFormContainer2
                              fields={
                                <>
                                  {/* Company entity */}
                                  <FormDrawerTextField2
                                    error={openingDocumentUpdate.error}
                                    fieldName="company_entity"
                                    helperText="Leave blank to use the default company name"
                                    icon="office"
                                    label="Company entity"
                                    labelInfo="(required)"
                                    placeholder="Enter your company's name"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="companyEntity"
                                    value={openingDocument.companyEntity}
                                  />

                                  {/* Company address */}
                                  <FormDrawerTextAreaField3
                                    enableAutofill
                                    error={openingDocumentUpdate.error}
                                    fieldName="company_address"
                                    height="100px"
                                    label="Company address"
                                    noRewrite
                                    placeholder="Enter the company's address"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="companyAddress"
                                    value={openingDocument.companyAddress}
                                  />

                                  {/* Company phone */}
                                  <FormDrawerTextField2
                                    error={openingDocumentUpdate.error}
                                    fieldName="company_phone"
                                    icon="phone"
                                    label="Company phone"
                                    placeholder="Enter your company's phone number"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="companyPhone"
                                    value={openingDocument.companyPhone}
                                  />

                                  <div
                                    style={{
                                      margin: "-10px 0 15px 0",
                                    }}
                                  >
                                    <Switch
                                      checked={
                                        openingDocument.companyPhone === "..."
                                      }
                                      disabled={openingDocument.companyPhone}
                                      innerLabel="autofill"
                                      onChange={() =>
                                        openingDocument.companyPhone !==
                                          "..." &&
                                        setOpeningDocument((prevState) => {
                                          return {
                                            ...prevState,
                                            companyPhone: "...",
                                          };
                                        })
                                      }
                                    />
                                  </div>

                                  {/* Company email */}
                                  <FormDrawerTextField2
                                    error={openingDocumentUpdate.error}
                                    fieldName="company_email"
                                    icon="envelope"
                                    label="Company email"
                                    placeholder="Enter your company's email"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="companyEmail"
                                    value={openingDocument.companyEmail}
                                  />

                                  <div
                                    style={{
                                      margin: "-10px 0 15px 0",
                                    }}
                                  >
                                    <Switch
                                      checked={
                                        openingDocument.companyEmail === "..."
                                      }
                                      disabled={openingDocument.companyEmail}
                                      innerLabel="autofill"
                                      onChange={() =>
                                        openingDocument.companyEmail !==
                                          "..." &&
                                        setOpeningDocument((prevState) => {
                                          return {
                                            ...prevState,
                                            companyEmail: "...",
                                          };
                                        })
                                      }
                                    />
                                  </div>

                                  {/* About */}
                                  <FormDrawerTextAreaField3
                                    enableAutofill
                                    error={openingDocumentUpdate.error}
                                    fieldName="about"
                                    label="About"
                                    placeholder="Introduce the company"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="about"
                                    value={openingDocument.about}
                                  />
                                </>
                              }
                              title="Company information"
                            />

                            {/* Position details */}
                            <TabSectionDocumentEditorFormContainer2
                              fields={
                                <>
                                  {/* Position title */}
                                  <FormDrawerTextField2
                                    error={openingDocumentUpdate.error}
                                    fieldName="name"
                                    helperText="Leave blank to use the role name"
                                    icon="label"
                                    label="Position title"
                                    labelInfo="(required)"
                                    placeholder="Enter the position title"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="name"
                                    value={openingDocument.name}
                                  />

                                  {/* Reference */}
                                  <FormDrawerTextField2
                                    error={openingDocumentUpdate.error}
                                    fieldName="reference"
                                    icon="barcode"
                                    label="Reference"
                                    placeholder="Enter the position reference"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="reference"
                                    value={openingDocument.reference}
                                  />

                                  {/* Closing date */}
                                  <FormDrawerDateField2
                                    error={openingDocumentUpdate.error}
                                    fieldName="closing_date"
                                    icon="calendar"
                                    label="Closing date"
                                    placeholder={
                                      openingDocument.closingDate
                                        ? openingDocument.closingDate.toDateString()
                                        : "Enter the closing date"
                                    }
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="closingDate"
                                    value={openingDocument.closingDate}
                                  />

                                  {/* Location */}
                                  <FormDrawerTextAreaField3
                                    enableAutofill
                                    error={openingDocumentUpdate.error}
                                    fieldName="location"
                                    height="100px"
                                    icon="area-of-interest"
                                    label="Location"
                                    noRewrite
                                    placeholder="Enter the position's location"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="location"
                                    value={openingDocument.location}
                                  />

                                  {/* Introduction */}
                                  <FormDrawerTextAreaField3
                                    error={openingDocumentUpdate.error}
                                    fieldName="intro"
                                    label="Introduction"
                                    placeholder="Explain why the company is hiring"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="intro"
                                    value={openingDocument.intro}
                                  />

                                  {/* Purpose */}
                                  <FormDrawerTextAreaField3
                                    enableAutofill
                                    error={openingDocumentUpdate.error}
                                    fieldName="purpose"
                                    label="Purpose"
                                    placeholder="Summarise the purpose of the role"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="purpose"
                                    value={openingDocument.purpose}
                                  />

                                  {/* Qualifications */}
                                  <FormDrawerTextAreaField3
                                    enableAutofill
                                    error={openingDocumentUpdate.error}
                                    fieldName="qualifications"
                                    label="Qualifications"
                                    placeholder="Describe the required experience for the role"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="qualifications"
                                    value={openingDocument.qualifications}
                                  />

                                  {/* Responsibilities */}
                                  <FormDrawerTextAreaField3
                                    enableAutofill
                                    error={openingDocumentUpdate.error}
                                    fieldName="responsibilities"
                                    label="Responsibilities"
                                    placeholder="Describe the day-to-day responsibilities of the role"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="responsibilities"
                                    value={openingDocument.responsibilities}
                                  />

                                  {/* Include competencies */}
                                  <FormDrawerSwitchField
                                    error={openingDocumentUpdate.error}
                                    fieldName="include_competencies"
                                    helperText="Includes competency requirements"
                                    label="Include competencies"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="includeCompetencies"
                                    value={openingDocument.includeCompetencies}
                                  />
                                </>
                              }
                              title="Position details"
                            />

                            {/* Additional information */}
                            <TabSectionDocumentEditorFormContainer2
                              fields={
                                <>
                                  {/* Benefits */}
                                  <FormDrawerTextAreaField3
                                    error={openingDocumentUpdate.error}
                                    fieldName="benefits"
                                    label="Benefits"
                                    placeholder="Summarise the benefits offered if appropriate"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="benefits"
                                    value={openingDocument.benefits}
                                  />

                                  {/* Additional information */}
                                  <FormDrawerTextAreaField3
                                    error={openingDocumentUpdate.error}
                                    fieldName="additional_content"
                                    label="Additional information"
                                    placeholder="Add additional information"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="additionalContent"
                                    value={openingDocument.additionalContent}
                                  />

                                  {/* Include link to form */}
                                  <FormDrawerSwitchField
                                    error={openingDocumentUpdate.error}
                                    fieldName="include_form_url"
                                    helperText="Includes a link to the advaise.app application form"
                                    label="Include link to form"
                                    setValueObject={setOpeningDocument}
                                    setValueVariable="includeFormUrl"
                                    value={openingDocument.includeFormUrl}
                                  />
                                </>
                              }
                              title="Additional information"
                            />
                          </>
                        }
                        loading={
                          documentGeneratePdf.loading ||
                          openingDetails.loading ||
                          openingDocumentUpdate.loading
                        }
                      />
                    }
                    help="Create the position specification"
                    icon="manually-entered-data"
                    title="Specification"
                  />

                  {/* Application form */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          !openingDetails.opening
                            .opening_application_configuration ||
                          openingDetails.opening.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(
                            listOpeningApplicationConfigurationDetailsAction(
                              openingDetails.opening
                                .opening_application_configuration
                            )
                          );

                          setUpdateApplicationConfigFormDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Introduction */}
                        <TabSectionField
                          label="Introduction"
                          loading={
                            openingApplicationConfigurationDetails.loading
                          }
                          value={
                            <TabSectionFieldCard
                              text={
                                openingApplicationConfigurationDetails
                                  .opening_application_configuration.intro
                              }
                            />
                          }
                        />

                        {/* Recent employment */}
                        <TabSectionField
                          helperText={
                            openingApplicationConfigurationDetails
                              .opening_application_configuration.employment &&
                            "Ask for current or last job details"
                          }
                          label="Recent employment"
                          loading={
                            openingApplicationConfigurationDetails.loading
                          }
                          value={
                            <Tag
                              intent={
                                openingApplicationConfigurationDetails
                                  .opening_application_configuration.employment
                                  ? "success"
                                  : "danger"
                              }
                              round
                              style={{
                                textAlign: "center",
                                width: "75px",
                              }}
                            >
                              {openingApplicationConfigurationDetails
                                .opening_application_configuration.employment
                                ? "Yes"
                                : "No"}
                            </Tag>
                          }
                        />

                        {/* Remuneration */}
                        <TabSectionField
                          helperText={
                            openingApplicationConfigurationDetails
                              .opening_application_configuration.remuneration &&
                            "Ask for expected remuneration"
                          }
                          label="Remuneration"
                          loading={
                            openingApplicationConfigurationDetails.loading
                          }
                          value={
                            <Tag
                              intent={
                                openingApplicationConfigurationDetails
                                  .opening_application_configuration
                                  .remuneration
                                  ? "success"
                                  : "danger"
                              }
                              round
                              style={{
                                textAlign: "center",
                                width: "75px",
                              }}
                            >
                              {openingApplicationConfigurationDetails
                                .opening_application_configuration.remuneration
                                ? "Yes"
                                : "No"}
                            </Tag>
                          }
                        />

                        {/* Police check */}
                        <TabSectionField
                          helperText={
                            openingApplicationConfigurationDetails
                              .opening_application_configuration.police_check &&
                            "Confirm the candidate is willing to undergo police checks"
                          }
                          label="Police check"
                          loading={
                            openingApplicationConfigurationDetails.loading
                          }
                          value={
                            <Tag
                              intent={
                                openingApplicationConfigurationDetails
                                  .opening_application_configuration
                                  .police_check
                                  ? "success"
                                  : "danger"
                              }
                              round
                              style={{
                                textAlign: "center",
                                width: "75px",
                              }}
                            >
                              {openingApplicationConfigurationDetails
                                .opening_application_configuration.police_check
                                ? "Yes"
                                : "No"}
                            </Tag>
                          }
                        />

                        {/* Close date */}
                        <TabSectionField
                          label="Close date"
                          loading={
                            openingApplicationConfigurationDetails.loading
                          }
                          value={
                            <DateValue
                              date={
                                openingApplicationConfigurationDetails
                                  .opening_application_configuration.close
                              }
                            />
                          }
                        />

                        {/* Additional information */}
                        <TabSectionField
                          bottom
                          label="Additional information"
                          loading={
                            openingApplicationConfigurationDetails.loading
                          }
                          value={
                            <TabSectionFieldCard
                              text={
                                openingApplicationConfigurationDetails
                                  .opening_application_configuration
                                  .notes_guidance
                              }
                            />
                          }
                        />
                      </div>
                    }
                    help="Configure the application form"
                    icon="form"
                    message={
                      <div>
                        Preview the application form at:{" "}
                        <TabSectionFieldLink
                          url={openingDetails.opening.form_url}
                        />
                        .
                      </div>
                    }
                    messageIntent="primary"
                    title="Application form"
                  />

                  {/* Applicants */}
                  <TabSection2
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search candidate"
                            searching={openingCandidateList.loading}
                            setValueObject={setOpeningCandidateQuery}
                            setValueVariable="searchString"
                            value={openingCandidateQuery.searchString}
                          />
                        </div>

                        <ItemCardList
                          content={openingCandidateList.opening_candidates.map(
                            (opening_candidate) => {
                              return (
                                <CandidateCard
                                  candidate={opening_candidate}
                                  interactive
                                  key={opening_candidate.id}
                                  onClick={() => {
                                    dispatch(
                                      listOpeningCandidateDetailsAction(
                                        opening_candidate.id
                                      )
                                    );

                                    setUpdateCandidateDrawerIsOpen(true);
                                  }}
                                  permission={opening_candidate}
                                />
                              );
                            }
                          )}
                          count={openingCandidateList.count}
                          loading={openingCandidateList.loading}
                          noMargin
                          noResultsMessage="Candidates appear when they fill the application form."
                          noResultsTitle="No candidates found"
                          page={openingCandidateQuery.page}
                          setPageObject={setOpeningCandidateQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Shortlist applicants for evaluation"
                    icon="inherited-group"
                    title="Applicants"
                  />

                  {/* Evaluations */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          openingDetails.opening.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddEvaluationDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Competencies */}
                              <CandidateCompetenciesCard
                                competencyProfile={
                                  openingEvaluationList.competencyProfile
                                }
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={openingEvaluationList.opening_evaluations.map(
                            (evaluation) => {
                              return (
                                <tr
                                  key={evaluation.id}
                                  onClick={() => {
                                    if (
                                      openingDetails.opening
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listOpeningEvaluationDetailsAction(
                                          evaluation.id
                                        )
                                      );

                                      setEvaluationRatingsInitialised(false);

                                      setOpeningEvaluationRatingQuery(
                                        (prevState) => {
                                          return {
                                            ...prevState,
                                            evaluation: evaluation.id,
                                          };
                                        }
                                      );

                                      setUpdateEvaluationDrawerIsOpen(true);
                                    }
                                  }}
                                >
                                  {/* Candidate */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={evaluation.candidate_name}
                                      image={evaluation.candidate_profile_photo}
                                      showImage
                                    />
                                  </td>

                                  {/* Interviewer */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={evaluation.interviewer_name}
                                      image={
                                        evaluation.interviewer_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Status */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        evaluation.status === "Not selected"
                                          ? "primary"
                                          : "success"
                                      }
                                      value={evaluation.status}
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !openingEvaluationQuery.interviewer &&
                            !openingEvaluationQuery.searchString &&
                            !openingEvaluationQuery.status
                          }
                          count={openingEvaluationList.count}
                          head={
                            <tr>
                              {/* Candidate */}
                              <TableColumnHeadSearch
                                queryObject={openingEvaluationQuery}
                                queryVariable="searchString"
                                setQueryObject={setOpeningEvaluationQuery}
                                setQueryVariable="searchString"
                                title="Candidate"
                                width="200px"
                              />

                              {/* Interviewer */}
                              <TableColumnHeadSelect2
                                filterObject={openingEvaluationQuery}
                                filterVariable="manager"
                                items={managerList.managers}
                                setFilterObject={setOpeningEvaluationQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Interviewer"
                                width="200px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={openingEvaluationQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "Not selected",
                                    icon: "circle",
                                    value: "Not selected",
                                  },
                                  {
                                    display: "Selected",
                                    icon: "confirm",
                                    value: "Selected",
                                  },
                                ]}
                                setFilterObject={setOpeningEvaluationQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={openingEvaluationList.loading}
                          noMargin
                          onClearFilters={() => {
                            setOpeningEvaluationQuery({
                              ...openingEvaluationQueryDefault,
                            });
                          }}
                          page={openingEvaluationQuery.page}
                          setPageObject={setOpeningEvaluationQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Evaluate shortlisted candidates"
                    icon="eye-open"
                    title="Evaluations"
                  />

                  {/* Approvals */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          openingDetails.opening.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddApprovalRequestDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data table */}
                        <TableContainer2
                          body={approvalRequestList.approval_requests.map(
                            (approval_request) => {
                              return (
                                <tr
                                  key={approval_request.id}
                                  onClick={() => {
                                    if (
                                      openingDetails.opening
                                        .user_access_status !== "view"
                                    ) {
                                      dispatch(
                                        listApprovalRequestDetailsAction(
                                          approval_request.id
                                        )
                                      );

                                      if (
                                        approval_request.status === "Completed"
                                      ) {
                                        setViewApprovalDrawerIsOpen(true);
                                      } else {
                                        setUpdateApprovalRequestDrawerIsOpen(
                                          true
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {/* Decision */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      text={approval_request.decision_right}
                                    />
                                  </td>

                                  {/* Value */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableNumber
                                      number={approval_request.value}
                                      tagText={activeOrganisation.currency}
                                    />
                                  </td>

                                  {/* Approver */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableText
                                      email={approval_request.approver_name}
                                      image={
                                        approval_request.approver_profile_photo
                                      }
                                      showImage
                                    />
                                  </td>

                                  {/* Reviewed */}
                                  <td style={{ verticalAlign: "middle" }}>
                                    <TableDate
                                      date={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .reviewed
                                          : undefined
                                      }
                                    />
                                  </td>

                                  {/* Outcome */}
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      intent={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome === "Approved"
                                            ? "success"
                                            : approval_request.response_data
                                                .outcome === "Denied"
                                            ? "danger"
                                            : "primary"
                                          : undefined
                                      }
                                      value={
                                        approval_request.response_data
                                          ? approval_request.response_data
                                              .outcome
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>
                              );
                            }
                          )}
                          clearFiltersDisabled={
                            !approvalRequestQuery.approver &&
                            !approvalRequestQuery.decisionRight &&
                            !approvalRequestQuery.outcome &&
                            !approvalRequestQuery.reviewedEnd &&
                            !approvalRequestQuery.reviewedStart &&
                            !approvalRequestQuery.value
                          }
                          count={approvalRequestList.count}
                          head={
                            <tr>
                              {/* Decision */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="decisionRight"
                                options={[
                                  {
                                    display: "All",
                                    value: "",
                                  },
                                  {
                                    display: "Staff recruitment",
                                    value: "Staff recruitment",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Decision"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-numerical",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-numerical-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Approver */}
                              <TableColumnHeadSelect2
                                filterObject={approvalRequestQuery}
                                filterVariable="approver"
                                items={managerList.managers}
                                setFilterObject={setApprovalRequestQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Approver"
                                width="200px"
                              />

                              {/* Reviewed */}
                              <TableColumnHeadDate
                                endDateVariable="reviewedEnd"
                                queryObject={approvalRequestQuery}
                                setQueryObject={setApprovalRequestQuery}
                                startDateVariable="reviewedStart"
                                title="Reviewed"
                                width="125px"
                              />

                              {/* Outcome */}
                              <TableColumnHeadSelectSimple
                                filterObject={approvalRequestQuery}
                                filterVariable="outcome"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Approved",
                                    icon: "confirm",
                                    value: "Approved",
                                  },
                                  {
                                    display: "Pending",
                                    icon: "circle",
                                    value: "Pending",
                                  },
                                  {
                                    display: "Denied",
                                    icon: "remove",
                                    value: "Denied",
                                  },
                                ]}
                                setFilterObject={setApprovalRequestQuery}
                                title="Outcome"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={approvalRequestList.loading}
                          noMargin
                          onClearFilters={() => {
                            setApprovalRequestQuery({
                              ...approvalRequestQueryDefault,
                            });
                          }}
                          page={approvalRequestQuery.page}
                          setPageObject={setApprovalRequestQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Manage approval requests"
                    icon="book"
                    message="When you create an approval request, advaise.app generates a fact set to inform the approver's decision. This fact set is based on the information at the time. If there is a change, consider submitting a new approval request."
                    messageIntent="warning"
                    title="Approvals"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default OpeningDetailScreen;
