// Constant imports
import {
  CLIENT_ACTION_LIST_REQUEST,
  CLIENT_ACTION_LIST_SUCCESS,
  CLIENT_ACTION_LIST_FAIL,
  CLIENT_ACTION_LIST_RESET,
  CLIENT_ACTION_CREATE_REQUEST,
  CLIENT_ACTION_CREATE_SUCCESS,
  CLIENT_ACTION_CREATE_FAIL,
  CLIENT_ACTION_CREATE_RESET,
  CLIENT_ACTION_DETAILS_REQUEST,
  CLIENT_ACTION_DETAILS_SUCCESS,
  CLIENT_ACTION_DETAILS_FAIL,
  CLIENT_ACTION_DETAILS_RESET,
  CLIENT_ACTION_UPDATE_REQUEST,
  CLIENT_ACTION_UPDATE_SUCCESS,
  CLIENT_ACTION_UPDATE_FAIL,
  CLIENT_ACTION_UPDATE_RESET,
  CLIENT_ACTION_DELETE_REQUEST,
  CLIENT_ACTION_DELETE_SUCCESS,
  CLIENT_ACTION_DELETE_FAIL,
  CLIENT_ACTION_DELETE_RESET,
} from "../constants/clientActionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientActionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_ACTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientActions(params, token);

      dispatch({
        type: CLIENT_ACTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          numberOfActionsCompleted: data.number_of_actions_completed,
          numberOfActionsInProgress: data.number_of_actions_in_progress,
          numberOfActionsOnHold: data.number_of_actions_on_hold,
          client_actions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CLIENT_ACTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListClientActionsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_ACTION_LIST_RESET });
};

// Create
export const createClientActionAction =
  (client_action) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_ACTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createClientAction(
        token,
        client_action
      );

      dispatch({
        type: CLIENT_ACTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_ACTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateClientActionAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_ACTION_CREATE_RESET });
};

// List details
export const listClientActionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_ACTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientAction(id, token);

      dispatch({
        type: CLIENT_ACTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_ACTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListClientActionDetailsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_ACTION_DETAILS_RESET });
};

// Update
export const updateClientActionAction =
  (id, client_action) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_ACTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateClientAction(
        id,
        token,
        client_action
      );

      dispatch({
        type: CLIENT_ACTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_ACTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientActionAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_ACTION_UPDATE_RESET });
};

// Delete
export const deleteClientActionAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_ACTION_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteClientAction(id, token);

    dispatch({
      type: CLIENT_ACTION_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_ACTION_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteClientActionAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_ACTION_DELETE_RESET });
};
