// Constant imports
import {
  CONTACT_LIST_REQUEST,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAIL,
  CONTACT_LIST_RESET,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_FAIL,
  CONTACT_CREATE_RESET,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_RESET,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  CONTACT_UPDATE_RESET,
  CONTACT_DELETE_REQUEST,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_FAIL,
  CONTACT_DELETE_RESET,
  CONTACT_REFERRER_LIST_REQUEST,
  CONTACT_REFERRER_LIST_SUCCESS,
  CONTACT_REFERRER_LIST_FAIL,
  CONTACT_REFERRER_LIST_RESET,
  CONTACT_DOWNLOAD_REQUEST,
  CONTACT_DOWNLOAD_SUCCESS,
  CONTACT_DOWNLOAD_FAIL,
  CONTACT_DOWNLOAD_RESET,
  CONTACT_UPLOAD_BEGIN,
  CONTACT_UPLOAD_REQUEST,
  CONTACT_UPLOAD_SUCCESS,
  CONTACT_UPLOAD_FAIL,
  CONTACT_UPLOAD_END,
  CONTACT_UPLOAD_RESET,
  CONTACT_CREATE_COMMUNICATION_ACTION_REQUEST,
  CONTACT_CREATE_COMMUNICATION_ACTION_SUCCESS,
  CONTACT_CREATE_COMMUNICATION_ACTION_FAIL,
  CONTACT_CREATE_COMMUNICATION_ACTION_RESET,
} from "../constants/contactConstants";

// List all
export const contactListReducer = (
  state = {
    loading: false,
    count: 0,
    contacts: [],
    numberFinance: 0,
    numberGeneralManagement: 0,
    numberHumanResources: 0,
    numberInformationTechnology: 0,
    numberMarketingSales: 0,
    numberMiddleManagement: 0,
    numberLowerManagement: 0,
    numberOperations: 0,
    numberOther: 0,
    numberUpperManagement: 0,
    totalValueOfProposalsCancelled: 0,
    totalValueOfProposalsInProgress: 0,
    totalValueOfProposalsLost: 0,
    totalValueOfProposalsQualifying: 0,
    totalValueOfProposalsSubmitted: 0,
    totalValueOfProposalsWon: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CONTACT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONTACT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        contacts: action.payload.contacts,
        numberFinance: action.payload.numberFinance,
        numberGeneralManagement: action.payload.numberGeneralManagement,
        numberHumanResources: action.payload.numberHumanResources,
        numberInformationTechnology: action.payload.numberInformationTechnology,
        numberMarketingSales: action.payload.numberMarketingSales,
        numberMiddleManagement: action.payload.numberMiddleManagement,
        numberLowerManagement: action.payload.numberLowerManagement,
        numberOperations: action.payload.numberOperations,
        numberOther: action.payload.numberOther,
        numberUpperManagement: action.payload.numberUpperManagement,
        totalValueOfProposalsCancelled:
          action.payload.totalValueOfProposalsCancelled,
        totalValueOfProposalsInProgress:
          action.payload.totalValueOfProposalsInProgress,
        totalValueOfProposalsLost: action.payload.totalValueOfProposalsLost,
        totalValueOfProposalsQualifying:
          action.payload.totalValueOfProposalsQualifying,
        totalValueOfProposalsSubmitted:
          action.payload.totalValueOfProposalsSubmitted,
        totalValueOfProposalsWon: action.payload.totalValueOfProposalsWon,
        success: true,
        error: "",
      };

    case CONTACT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        contacts: [],
        numberFinance: 0,
        numberGeneralManagement: 0,
        numberHumanResources: 0,
        numberInformationTechnology: 0,
        numberMarketingSales: 0,
        numberMiddleManagement: 0,
        numberLowerManagement: 0,
        numberOperations: 0,
        numberOther: 0,
        numberUpperManagement: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: action.payload,
      };

    case CONTACT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        contacts: [],
        numberFinance: 0,
        numberGeneralManagement: 0,
        numberHumanResources: 0,
        numberInformationTechnology: 0,
        numberMarketingSales: 0,
        numberMiddleManagement: 0,
        numberLowerManagement: 0,
        numberOperations: 0,
        numberOther: 0,
        numberUpperManagement: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const contactCreateReducer = (
  state = { loading: false, contact: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_CREATE_REQUEST:
      return {
        loading: true,
        contact: state.contact,
        success: false,
        error: "",
      };

    case CONTACT_CREATE_SUCCESS:
      return {
        loading: false,
        contact: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_CREATE_FAIL:
      return {
        loading: false,
        contact: state.contact,
        success: false,
        error: action.payload,
      };

    case CONTACT_CREATE_RESET:
      return { loading: false, contact: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const contactDetailsReducer = (
  state = { loading: false, contact: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_DETAILS_REQUEST:
      return {
        loading: true,
        contact: state.contact,
        success: false,
        error: "",
      };

    case CONTACT_DETAILS_SUCCESS:
      return {
        loading: false,
        contact: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_DETAILS_FAIL:
      return {
        loading: false,
        contact: state.contact,
        success: false,
        error: action.payload,
      };

    case CONTACT_DETAILS_RESET:
      return { loading: false, contact: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const contactUpdateReducer = (
  state = { loading: false, contact: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_UPDATE_REQUEST:
      return {
        loading: true,
        contact: state.contact,
        success: false,
        error: "",
      };

    case CONTACT_UPDATE_SUCCESS:
      return {
        loading: false,
        contact: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_UPDATE_FAIL:
      return {
        loading: false,
        contact: state.contact,
        success: false,
        error: action.payload,
      };

    case CONTACT_UPDATE_RESET:
      return { loading: false, contact: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const contactDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CONTACT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CONTACT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CONTACT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// List referrers
export const contactReferrerListReducer = (
  state = { loading: false, count: 0, contacts: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_REFERRER_LIST_REQUEST:
      return {
        loading: true,
        count: 0,
        contacts: [],
        success: false,
        error: "",
      };

    case CONTACT_REFERRER_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        contacts: action.payload.contacts,
        success: true,
        error: "",
      };

    case CONTACT_REFERRER_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        contacts: [],
        success: false,
        error: action.payload,
      };

    case CONTACT_REFERRER_LIST_RESET:
      return {
        loading: false,
        count: 0,
        contacts: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Download
export const contactDownloadReducer = (
  state = {
    loading: false,
    contacts: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CONTACT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONTACT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        contacts: action.payload.contacts,
        success: true,
        error: "",
      };

    case CONTACT_DOWNLOAD_FAIL:
      return {
        loading: false,
        contacts: [],
        success: false,
        error: action.payload,
      };

    case CONTACT_DOWNLOAD_RESET:
      return {
        loading: false,
        contacts: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const contactUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    progress: 0,
    records_failed: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CONTACT_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case CONTACT_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CONTACT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        records_failed: action.payload.records_failed,
        success: false,
        error: action.payload.error,
      };

    case CONTACT_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case CONTACT_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        progress: 0,
        records_failed: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create communication action
export const contactCreateCommunicationActionReducer = (
  state = { loading: false, action: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CONTACT_CREATE_COMMUNICATION_ACTION_REQUEST:
      return {
        loading: true,
        action: state.action,
        success: false,
        error: "",
      };

    case CONTACT_CREATE_COMMUNICATION_ACTION_SUCCESS:
      return {
        loading: false,
        action: action.payload,
        success: true,
        error: "",
      };

    case CONTACT_CREATE_COMMUNICATION_ACTION_FAIL:
      return {
        loading: false,
        action: state.action,
        success: false,
        error: action.payload,
      };

    case CONTACT_CREATE_COMMUNICATION_ACTION_RESET:
      return { loading: false, action: "", success: false, error: "" };

    default:
      return state;
  }
};
