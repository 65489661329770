// Constant imports
import {
  MARKET_LIST_REQUEST,
  MARKET_LIST_SUCCESS,
  MARKET_LIST_FAIL,
  MARKET_LIST_RESET,
  MARKET_CREATE_REQUEST,
  MARKET_CREATE_SUCCESS,
  MARKET_CREATE_FAIL,
  MARKET_CREATE_RESET,
  MARKET_DETAILS_REQUEST,
  MARKET_DETAILS_SUCCESS,
  MARKET_DETAILS_FAIL,
  MARKET_DETAILS_RESET,
  MARKET_UPDATE_REQUEST,
  MARKET_UPDATE_SUCCESS,
  MARKET_UPDATE_FAIL,
  MARKET_UPDATE_RESET,
  MARKET_DELETE_REQUEST,
  MARKET_DELETE_SUCCESS,
  MARKET_DELETE_FAIL,
  MARKET_DELETE_RESET,
} from "../constants/marketConstants";

// List all
export const marketListReducer = (
  state = {
    loading: false,
    count: 0,
    markets: [],
    averageMarginPercentageOfProposalsCancelled: 0,
    averageMarginPercentageOfProposalsLost: 0,
    averageMarginPercentageOfProposalsWon: 0,
    totalEngagementMargin: 0,
    totalEngagementRevenue: 0,
    totalValueOfProposalsCancelled: 0,
    totalValueOfProposalsInProgress: 0,
    totalValueOfProposalsLost: 0,
    totalValueOfProposalsQualifying: 0,
    totalValueOfProposalsSubmitted: 0,
    totalValueOfProposalsWon: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case MARKET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case MARKET_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        markets: action.payload.markets,
        averageMarginPercentageOfProposalsCancelled:
          action.payload.averageMarginPercentageOfProposalsCancelled,
        averageMarginPercentageOfProposalsLost:
          action.payload.averageMarginPercentageOfProposalsLost,
        averageMarginPercentageOfProposalsWon:
          action.payload.averageMarginPercentageOfProposalsWon,
        totalEngagementMargin: action.payload.totalEngagementMargin,
        totalEngagementRevenue: action.payload.totalEngagementRevenue,
        totalValueOfProposalsCancelled:
          action.payload.totalValueOfProposalsCancelled,
        totalValueOfProposalsInProgress:
          action.payload.totalValueOfProposalsInProgress,
        totalValueOfProposalsLost: action.payload.totalValueOfProposalsLost,
        totalValueOfProposalsQualifying:
          action.payload.totalValueOfProposalsQualifying,
        totalValueOfProposalsSubmitted:
          action.payload.totalValueOfProposalsSubmitted,
        totalValueOfProposalsWon: action.payload.totalValueOfProposalsWon,
        success: true,
        error: "",
      };

    case MARKET_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        markets: [],
        averageMarginPercentageOfProposalsCancelled: 0,
        averageMarginPercentageOfProposalsLost: 0,
        averageMarginPercentageOfProposalsWon: 0,
        totalEngagementMargin: 0,
        totalEngagementRevenue: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: action.payload,
      };

    case MARKET_LIST_RESET:
      return {
        loading: false,
        count: 0,
        markets: [],
        averageMarginPercentageOfProposalsCancelled: 0,
        averageMarginPercentageOfProposalsLost: 0,
        averageMarginPercentageOfProposalsWon: 0,
        totalEngagementMargin: 0,
        totalEngagementRevenue: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const marketCreateReducer = (
  state = { loading: false, market: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MARKET_CREATE_REQUEST:
      return {
        loading: true,
        market: state.market,
        success: false,
        error: "",
      };

    case MARKET_CREATE_SUCCESS:
      return {
        loading: false,
        market: action.payload,
        success: true,
        error: "",
      };

    case MARKET_CREATE_FAIL:
      return {
        loading: false,
        market: state.market,
        success: false,
        error: action.payload,
      };

    case MARKET_CREATE_RESET:
      return { loading: false, market: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const marketDetailsReducer = (
  state = { loading: false, market: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MARKET_DETAILS_REQUEST:
      return {
        loading: true,
        market: state.market,
        success: false,
        error: "",
      };

    case MARKET_DETAILS_SUCCESS:
      return {
        loading: false,
        market: action.payload,
        success: true,
        error: "",
      };

    case MARKET_DETAILS_FAIL:
      return {
        loading: false,
        market: state.market,
        success: false,
        error: action.payload,
      };

    case MARKET_DETAILS_RESET:
      return { loading: false, market: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const marketUpdateReducer = (
  state = { loading: false, market: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MARKET_UPDATE_REQUEST:
      return {
        loading: true,
        market: state.market,
        success: false,
        error: "",
      };

    case MARKET_UPDATE_SUCCESS:
      return {
        loading: false,
        market: action.payload,
        success: true,
        error: "",
      };

    case MARKET_UPDATE_FAIL:
      return {
        loading: false,
        market: state.market,
        success: false,
        error: action.payload,
      };

    case MARKET_UPDATE_RESET:
      return { loading: false, market: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const marketDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case MARKET_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case MARKET_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case MARKET_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case MARKET_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
