// Library imports
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

// AI credit transaction reducer imports
import { aiCreditTransactionListReducer } from "./reducers/aiCreditTransactionReducers";

// Approval request reducer imports
import {
  approvalRequestCreateReducer,
  approvalRequestDeleteReducer,
  approvalRequestDetailsReducer,
  approvalRequestDownloadReducer,
  approvalRequestListReducer,
  approvalRequestUpdateReducer,
} from "./reducers/approvalRequestReducers";

// Approval response reducer imports
import {
  approvalResponseCreateReducer,
  approvalResponseDeleteReducer,
  approvalResponseDetailsReducer,
  approvalResponseListReducer,
  approvalResponseUpdateReducer,
} from "./reducers/approvalResponseReducers";

// Approval role reducer imports
import {
  approvalRoleCreateReducer,
  approvalRoleDeleteReducer,
  approvalRoleDetailsReducer,
  approvalRoleListReducer,
  approvalRoleUpdateReducer,
} from "./reducers/approvalRoleReducers";

// Booking reducer imports
import {
  bookingCreateReducer,
  bookingDeleteReducer,
  bookingDetailsReducer,
  bookingListReducer,
  bookingUpdateReducer,
} from "./reducers/bookingReducers";

// Booking code reducer imports
import {
  bookingCodeCreateReducer,
  bookingCodeDeleteReducer,
  bookingCodeDetailsReducer,
  bookingCodeListReducer,
  bookingCodeUpdateReducer,
} from "./reducers/bookingCodeReducers";

// Case study reducer imports
import {
  caseStudyCreateReducer,
  caseStudyDeleteReducer,
  caseStudyDetailsReducer,
  caseStudyListReducer,
  caseStudyUpdateReducer,
} from "./reducers/caseStudyReducers";

// Case study permission reducer imports
import {
  caseStudyPermissionCreateReducer,
  caseStudyPermissionDeleteReducer,
  caseStudyPermissionDetailsReducer,
  caseStudyPermissionListReducer,
  caseStudyPermissionUpdateReducer,
} from "./reducers/caseStudyPermissionReducers";

// Claim reducer imports
import {
  claimCreateReducer,
  claimDeleteReducer,
  claimDetailsReducer,
  claimDuplicateReducer,
  claimListReducer,
  claimReverseReducer,
  claimUpdateReducer,
} from "./reducers/claimReducers";

// Client reducer imports
import {
  clientCreateReducer,
  clientDeleteReducer,
  clientDetailsReducer,
  clientDownloadReducer,
  clientIntelReducer,
  clientListReducer,
  clientUpdateReducer,
  clientUploadReducer,
} from "./reducers/clientReducers";

// Client action reducer imports
import {
  clientActionCreateReducer,
  clientActionDeleteReducer,
  clientActionDetailsReducer,
  clientActionListReducer,
  clientActionUpdateReducer,
} from "./reducers/clientActionReducers";

// Client KPI reducer imports
import {
  clientKPICreateReducer,
  clientKPIDeleteReducer,
  clientKPIDetailsReducer,
  clientKPIListReducer,
  clientKPIUpdateReducer,
} from "./reducers/clientKPIReducers";

// Client need reducer imports
import {
  clientNeedCreateReducer,
  clientNeedDeleteReducer,
  clientNeedDetailsReducer,
  clientNeedListReducer,
  clientNeedUpdateReducer,
} from "./reducers/clientNeedReducers";

// Client permission reducer imports
import {
  clientPermissionCreateReducer,
  clientPermissionDeleteReducer,
  clientPermissionDetailsReducer,
  clientPermissionListReducer,
  clientPermissionUpdateReducer,
} from "./reducers/clientPermissionReducers";

// Client program reducer imports
import {
  clientProgramCreateReducer,
  clientProgramDeleteReducer,
  clientProgramDetailsReducer,
  clientProgramListReducer,
  clientProgramUpdateReducer,
} from "./reducers/clientProgramReducers";

// Client question reducer imports
import {
  clientQuestionCreateReducer,
  clientQuestionDeleteReducer,
  clientQuestionDetailsReducer,
  clientQuestionListReducer,
  clientQuestionUpdateReducer,
} from "./reducers/clientQuestionReducers";

// Client stakeholder reducer imports
import {
  clientStakeholderCreateReducer,
  clientStakeholderDeleteReducer,
  clientStakeholderDetailsReducer,
  clientStakeholderListReducer,
  clientStakeholderUpdateReducer,
} from "./reducers/clientStakeholderReducers";

// Company reducer imports
import {
  companyDetailsReducer,
  companyUpdateReducer,
} from "./reducers/companyReducers";

// Competency reducer imports
import {
  competencyCreateReducer,
  competencyDeleteReducer,
  competencyDetailsReducer,
  competencyDownloadReducer,
  competencyListReducer,
  competencyUpdateReducer,
  competencyUploadReducer,
} from "./reducers/competencyReducers";

// Contact reducer imports
import {
  contactCreateReducer,
  contactCreateCommunicationActionReducer,
  contactDeleteReducer,
  contactDetailsReducer,
  contactDownloadReducer,
  contactListReducer,
  contactReferrerListReducer,
  contactUpdateReducer,
  contactUploadReducer,
} from "./reducers/contactReducers";

// Contact action reducer imports
import {
  contactActionCreateReducer,
  contactActionDeleteReducer,
  contactActionDetailsReducer,
  contactActionListReducer,
  contactActionUpdateReducer,
} from "./reducers/contactActionReducers";

// Contact permission reducer imports
import {
  contactPermissionCreateReducer,
  contactPermissionDeleteReducer,
  contactPermissionDetailsReducer,
  contactPermissionListReducer,
  contactPermissionUpdateReducer,
} from "./reducers/contactPermissionReducers";

// Currency reducer imports
import {
  currencyCreateReducer,
  currencyDeleteReducer,
  currencyDetailsReducer,
  currencyGetCurrencyChoicesReducer,
  currencyGetOrganisationCurrencyChoicesReducer,
  currencyGetRateReducer,
  currencyListReducer,
} from "./reducers/currencyReducers";

// Currency exchange rate reducer imports
import {
  currencyExchangeRateCreateReducer,
  currencyExchangeRateDeleteReducer,
  currencyExchangeRateDetailsReducer,
  currencyExchangeRateListReducer,
  currencyExchangeRateUpdateReducer,
} from "./reducers/currencyExchangeRateReducers";

// Demand profile reducer imports
import { demandProfileGenerateReducer } from "./reducers/demandProfileReducers";

// Document reducer imports
import {
  documentGeneratePdfReducer,
  documentGeneratePdfFromHtmlReducer,
} from "./reducers/documentReducers";

// Engagement reducer imports
import {
  engagementCreateReducer,
  engagementDeleteReducer,
  engagementDetailsReducer,
  engagementDownloadReducer,
  engagementGenerateReportDataReducer,
  engagementListReducer,
  engagementUpdateReducer,
} from "./reducers/engagementReducers";

// Engagement action reducer imports
import {
  engagementActionCreateReducer,
  engagementActionDeleteReducer,
  engagementActionDetailsReducer,
  engagementActionListReducer,
  engagementActionUpdateReducer,
} from "./reducers/engagementActionReducers";

// Engagement contact reducer imports
import {
  engagementContactCreateReducer,
  engagementContactDeleteReducer,
  engagementContactDetailsReducer,
  engagementContactListReducer,
  engagementContactUpdateReducer,
} from "./reducers/engagementContactReducers";

// Engagement delivery issue reducer imports
import {
  engagementDeliveryIssueCreateReducer,
  engagementDeliveryIssueDeleteReducer,
  engagementDeliveryIssueDetailsReducer,
  engagementDeliveryIssueListReducer,
  engagementDeliveryIssueUpdateReducer,
} from "./reducers/engagementDeliveryIssueReducers";

// Engagement dependency reducer imports
import {
  engagementDependencyCreateReducer,
  engagementDependencyDeleteReducer,
  engagementDependencyDetailsReducer,
  engagementDependencyListReducer,
  engagementDependencyUpdateReducer,
} from "./reducers/engagementDependencyReducers";

// Engagement document reducer imports
import {
  engagementDocumentDetailsReducer,
  engagementDocumentUpdateReducer,
} from "./reducers/engagementDocumentReducers";

// Engagement fee reducer imports
import {
  engagementFeeDetailsReducer,
  engagementFeeListReducer,
  engagementFeeUpdateReducer,
} from "./reducers/engagementFeeReducers";

// Engagement labour expense reducer imports
import {
  engagementLabourExpenseCreateReducer,
  engagementLabourExpenseDeleteReducer,
  engagementLabourExpenseDetailsReducer,
  engagementLabourExpenseListReducer,
  engagementLabourExpenseUpdateReducer,
} from "./reducers/engagementLabourExpenseReducers";

// Engagement lesson reducer imports
import {
  engagementLessonCreateReducer,
  engagementLessonDeleteReducer,
  engagementLessonDetailsReducer,
  engagementLessonListReducer,
  engagementLessonUpdateReducer,
} from "./reducers/engagementLessonReducers";

// Engagement milestone reducer imports
import {
  engagementMilestoneCreateReducer,
  engagementMilestoneDeleteReducer,
  engagementMilestoneDetailsReducer,
  engagementMilestoneListReducer,
  engagementMilestoneUpdateReducer,
} from "./reducers/engagementMilestoneReducers";

// Engagement non-labour expense reducer imports
import {
  engagementNonLabourExpenseCreateReducer,
  engagementNonLabourExpenseDeleteReducer,
  engagementNonLabourExpenseDetailsReducer,
  engagementNonLabourExpenseListReducer,
  engagementNonLabourExpenseUpdateReducer,
} from "./reducers/engagementNonLabourExpenseReducers";

// Engagement permission reducer imports
import {
  engagementPermissionCreateReducer,
  engagementPermissionDeleteReducer,
  engagementPermissionDetailsReducer,
  engagementPermissionListReducer,
  engagementPermissionUpdateReducer,
} from "./reducers/engagementPermissionReducers";

// Engagement rate reducer imports
import {
  engagementRateCreateReducer,
  engagementRateDeleteReducer,
  engagementRateDetailsReducer,
  engagementRateListReducer,
  engagementRateUpdateReducer,
} from "./reducers/engagementRateReducers";

// Engagement report reducer imports
import {
  engagementReportCreateReducer,
  engagementReportDeleteReducer,
  engagementReportDetailsReducer,
  engagementReportDownloadReducer,
  engagementReportListReducer,
  engagementReportUpdateReducer,
} from "./reducers/engagementReportReducers";

// Engagement risk reducer imports
import {
  engagementRiskCreateReducer,
  engagementRiskDeleteReducer,
  engagementRiskDetailsReducer,
  engagementRiskListReducer,
  engagementRiskUpdateReducer,
} from "./reducers/engagementRiskReducers";

// Engagement service reducer imports
import {
  engagementServiceCreateReducer,
  engagementServiceDeleteReducer,
  engagementServiceDetailsReducer,
  engagementServiceListReducer,
  engagementServiceUpdateReducer,
} from "./reducers/engagementServiceReducers";

// Engagement service activity reducer imports
import {
  engagementServiceActivityCreateReducer,
  engagementServiceActivityDeleteReducer,
  engagementServiceActivityDetailsReducer,
  engagementServiceActivityListReducer,
  engagementServiceActivityUpdateReducer,
} from "./reducers/engagementServiceActivityReducers";

// Engagement service deliverable reducer imports
import {
  engagementServiceDeliverableCreateReducer,
  engagementServiceDeliverableDeleteReducer,
  engagementServiceDeliverableDetailsReducer,
  engagementServiceDeliverableListReducer,
  engagementServiceDeliverableUpdateReducer,
} from "./reducers/engagementServiceDeliverableReducers";

// Engagement upload reducer imports
import {
  engagementUploadCreateReducer,
  engagementUploadDeleteReducer,
  engagementUploadDetailsReducer,
  engagementUploadListReducer,
  engagementUploadUpdateReducer,
} from "./reducers/engagementUploadReducers";

// Expense reducer imports
import {
  expenseCreateReducer,
  expenseDeleteReducer,
  expenseDetailsReducer,
  expenseListReducer,
  expenseUpdateReducer,
} from "./reducers/expenseReducers";

// Expense category reducer imports
import {
  expenseCategoryCreateReducer,
  expenseCategoryDeleteReducer,
  expenseCategoryDetailsReducer,
  expenseCategoryDownloadReducer,
  expenseCategoryListReducer,
  expenseCategoryUpdateReducer,
  expenseCategoryUploadReducer,
} from "./reducers/expenseCategoryReducers";

// Help content reducer imports
import { helpContentListReducer } from "./reducers/helpContentReducers";

// HubSpot reducer imports
import {
  hubspotAuthoriseReducer,
  hubspotDeauthoriseReducer,
  hubspotGetContactReducer,
  hubspotContactSyncReducer,
} from "./reducers/hubspotReducers";

// Insight reducer imports
import {
  insightCreateReducer,
  insightDeleteReducer,
  insightDetailsReducer,
  insightListReducer,
  insightUpdateReducer,
} from "./reducers/insightReducers";

// Insight permission reducer imports
import {
  insightPermissionCreateReducer,
  insightPermissionDeleteReducer,
  insightPermissionDetailsReducer,
  insightPermissionListReducer,
  insightPermissionUpdateReducer,
} from "./reducers/insightPermissionReducers";

// Interview reducer imports
import {
  interviewCreateReducer,
  interviewDeleteReducer,
  interviewDetailsReducer,
  interviewListReducer,
  interviewUpdateReducer,
} from "./reducers/interviewReducers";

// Interview permission reducer imports
import {
  interviewPermissionCreateReducer,
  interviewPermissionDeleteReducer,
  interviewPermissionDetailsReducer,
  interviewPermissionListReducer,
  interviewPermissionUpdateReducer,
} from "./reducers/interviewPermissionReducers";

// Interview question reducer imports
import {
  interviewQuestionCreateReducer,
  interviewQuestionDeleteReducer,
  interviewQuestionDetailsReducer,
  interviewQuestionListReducer,
  interviewQuestionUpdateReducer,
} from "./reducers/interviewQuestionReducers";

// Invoice reducer imports
import {
  invoiceCreateReducer,
  invoiceDeleteReducer,
  invoiceDetailsReducer,
  invoiceDownloadReducer,
  invoiceListReducer,
  invoiceUpdateReducer,
} from "./reducers/invoiceReducers";

// Invoice booking reducer imports
import {
  invoiceBookingDeleteReducer,
  invoiceBookingDetailsReducer,
  invoiceBookingListReducer,
  invoiceBookingUpdateReducer,
} from "./reducers/invoiceBookingReducers";

// Invoice booking credit reducer imports
import { invoiceBookingCreditListReducer } from "./reducers/invoiceBookingCreditReducers";

// Invoice document reducer imports
import {
  invoiceDocumentDetailsReducer,
  invoiceDocumentUpdateReducer,
} from "./reducers/invoiceDocumentReducers";

// Invoice expense reducer imports
import {
  invoiceExpenseDeleteReducer,
  invoiceExpenseDetailsReducer,
  invoiceExpenseListReducer,
  invoiceExpenseUpdateReducer,
} from "./reducers/invoiceExpenseReducers";

// Invoice expense credit reducer imports
import { invoiceExpenseCreditListReducer } from "./reducers/invoiceExpenseCreditReducers";

// Invoice milestone reducer imports
import {
  invoiceMilestoneDeleteReducer,
  invoiceMilestoneDetailsReducer,
  invoiceMilestoneListReducer,
  invoiceMilestoneUpdateReducer,
} from "./reducers/invoiceMilestoneReducers";

// Invoice milestone credit reducer imports
import { invoiceMilestoneCreditListReducer } from "./reducers/invoiceMilestoneCreditReducers";

// Invoice other reducer imports
import {
  invoiceOtherCreateReducer,
  invoiceOtherDeleteReducer,
  invoiceOtherDetailsReducer,
  invoiceOtherListReducer,
  invoiceOtherUpdateReducer,
} from "./reducers/invoiceOtherReducers";

// Invoice other credit reducer imports
import { invoiceOtherCreditListReducer } from "./reducers/invoiceOtherCreditReducers";

// Invoice permission reducer imports
import {
  invoicePermissionCreateReducer,
  invoicePermissionDeleteReducer,
  invoicePermissionDetailsReducer,
  invoicePermissionListReducer,
  invoicePermissionUpdateReducer,
} from "./reducers/invoicePermissionReducers";

// Knowledge base document reducer imports
import {
  knowledgeBaseDocumentCreateReducer,
  knowledgeBaseDocumentDeleteReducer,
  knowledgeBaseDocumentDetailsReducer,
  knowledgeBaseDocumentListReducer,
  knowledgeBaseDocumentUpdateReducer,
} from "./reducers/knowledgeBaseDocumentReducers";

// Leave category reducer imports
import {
  leaveCategoryCreateReducer,
  leaveCategoryDeleteReducer,
  leaveCategoryDetailsReducer,
  leaveCategoryDownloadReducer,
  leaveCategoryListReducer,
  leaveCategoryUpdateReducer,
  leaveCategoryUploadReducer,
} from "./reducers/leaveCategoryReducers";

// Leave request reducer imports
import {
  leaveRequestCancelReducer,
  leaveRequestCreateReducer,
  leaveRequestDeleteReducer,
  leaveRequestDetailsReducer,
  leaveRequestListReducer,
  leaveRequestUpdateReducer,
} from "./reducers/leaveRequestReducers";

// Location reducer imports
import {
  locationCreateReducer,
  locationDeleteReducer,
  locationDetailsReducer,
  locationListReducer,
  locationUpdateReducer,
} from "./reducers/locationReducers";

// Manager reducer imports
import {
  managerDetailsReducer,
  managerDownloadReducer,
  managerListReducer,
  managerUpdateReducer,
} from "./reducers/managerReducers";

// Market reducer imports
import {
  marketCreateReducer,
  marketDeleteReducer,
  marketDetailsReducer,
  marketListReducer,
  marketUpdateReducer,
} from "./reducers/marketReducers";

// Market case study reducer imports
import {
  marketCaseStudyCreateReducer,
  marketCaseStudyDeleteReducer,
  marketCaseStudyDetailsReducer,
  marketCaseStudyListReducer,
  marketCaseStudyUpdateReducer,
} from "./reducers/marketCaseStudyReducers";

// Market insight reducer imports
import {
  marketInsightCreateReducer,
  marketInsightDeleteReducer,
  marketInsightDetailsReducer,
  marketInsightListReducer,
  marketInsightUpdateReducer,
} from "./reducers/marketInsightReducers";

// Market need reducer imports
import {
  marketNeedCreateReducer,
  marketNeedDeleteReducer,
  marketNeedDetailsReducer,
  marketNeedListReducer,
  marketNeedUpdateReducer,
} from "./reducers/marketNeedReducers";

// Market permission reducer imports
import {
  marketPermissionCreateReducer,
  marketPermissionDeleteReducer,
  marketPermissionDetailsReducer,
  marketPermissionListReducer,
  marketPermissionUpdateReducer,
} from "./reducers/marketPermissionReducers";

// Membership reducer imports
import {
  membershipCreateReducer,
  membershipDeleteReducer,
  membershipDetailsReducer,
  membershipListReducer,
  membershipUpdateReducer,
} from "./reducers/membershipReducers";

// Need reducer imports
import {
  needCreateReducer,
  needDeleteReducer,
  needDetailsReducer,
  needListReducer,
  needUpdateReducer,
} from "./reducers/needReducers";

// Need permission reducer imports
import {
  needPermissionCreateReducer,
  needPermissionDeleteReducer,
  needPermissionDetailsReducer,
  needPermissionListReducer,
  needPermissionUpdateReducer,
} from "./reducers/needPermissionReducers";

// Need question reducer imports
import {
  needQuestionCreateReducer,
  needQuestionDeleteReducer,
  needQuestionDetailsReducer,
  needQuestionListReducer,
  needQuestionUpdateReducer,
  needQuestionUploadReducer,
} from "./reducers/needQuestionReducers";

// Notification reducer imports
import {
  notificationDeleteReducer,
  notificationListReducer,
} from "./reducers/notificationReducers";

// Opening reducer imports
import {
  openingCreateReducer,
  openingDeleteReducer,
  openingDetailsReducer,
  openingDownloadReducer,
  openingListReducer,
  openingReferrerListReducer,
  openingUpdateReducer,
} from "./reducers/openingReducers";

// Opening application reducer imports
import { openingApplicationCreateReducer } from "./reducers/openingApplicationReducers";

// Opening application configuration reducer imports
import {
  openingApplicationConfigurationApplyReducer,
  openingApplicationConfigurationDetailsReducer,
  openingApplicationConfigurationUpdateReducer,
} from "./reducers/openingApplicationConfigurationReducers";

// Opening candidate reducer imports
import {
  openingCandidateDetailsReducer,
  openingCandidateListReducer,
  openingCandidateUpdateReducer,
} from "./reducers/openingCandidateReducers";

// Opening document reducer imports
import {
  openingDocumentDetailsReducer,
  openingDocumentUpdateReducer,
} from "./reducers/openingDocumentReducers";

// Opening evaluation reducer imports
import {
  openingEvaluationCreateReducer,
  openingEvaluationDeleteReducer,
  openingEvaluationDetailsReducer,
  openingEvaluationListReducer,
  openingEvaluationUpdateReducer,
} from "./reducers/openingEvaluationReducers";

// Opening evaluation rating reducer imports
import {
  openingEvaluationRatingListReducer,
  openingEvaluationRatingUpdateReducer,
  openingEvaluationRatingsUploadReducer,
} from "./reducers/openingEvaluationRatingReducers";

// Opening permission reducer imports
import {
  openingPermissionCreateReducer,
  openingPermissionDeleteReducer,
  openingPermissionDetailsReducer,
  openingPermissionListReducer,
  openingPermissionUpdateReducer,
} from "./reducers/openingPermissionReducers";

// Organisation reducer imports
import {
  activeOrganisationReducer,
  checkPermissionsReducer,
  checkSubscriptionReducer,
  organisationCreateReducer,
  organisationDeleteReducer,
  organisationDetailsReducer,
  organisationListReducer,
  organisationUpdateReducer,
} from "./reducers/organisationReducers";

// Payment reducer imports
import {
  checkoutCreateReducer,
  customerPortalCreateReducer,
} from "./reducers/paymentReducers";

// Proposal reducer imports
import {
  proposalCreateReducer,
  proposalDeleteReducer,
  proposalDetailsReducer,
  proposalDownloadReducer,
  proposalListReducer,
  proposalPredictWinReducer,
  proposalUpdateReducer,
} from "./reducers/proposalReducers";

// Proposal action reducer imports
import {
  proposalActionCreateReducer,
  proposalActionDeleteReducer,
  proposalActionDetailsReducer,
  proposalActionListReducer,
  proposalActionUpdateReducer,
} from "./reducers/proposalActionReducers";

// Proposal case study reducer imports
import {
  proposalCaseStudyCreateReducer,
  proposalCaseStudyDeleteReducer,
  proposalCaseStudyDetailsReducer,
  proposalCaseStudyListReducer,
  proposalCaseStudyUpdateReducer,
} from "./reducers/proposalCaseStudyReducers";

// Proposal competitor reducer imports
import {
  proposalCompetitorCreateReducer,
  proposalCompetitorDeleteReducer,
  proposalCompetitorDetailsReducer,
  proposalCompetitorListReducer,
  proposalCompetitorUpdateReducer,
} from "./reducers/proposalCompetitorReducers";

// Proposal contact reducer imports
import {
  proposalContactCreateReducer,
  proposalContactDeleteReducer,
  proposalContactDetailsReducer,
  proposalContactListReducer,
  proposalContactUpdateReducer,
} from "./reducers/proposalContactReducers";

// Proposal document reducer imports
import {
  proposalDocumentDetailsReducer,
  proposalDocumentUpdateReducer,
} from "./reducers/proposalDocumentReducers";

// Proposal fee reducer imports
import {
  proposalFeeDetailsReducer,
  proposalFeeListReducer,
  proposalFeeUpdateReducer,
} from "./reducers/proposalFeeReducers";

// Proposal labour expense reducer imports
import {
  proposalLabourExpenseCreateReducer,
  proposalLabourExpenseDeleteReducer,
  proposalLabourExpenseDetailsReducer,
  proposalLabourExpenseListReducer,
  proposalLabourExpenseUpdateReducer,
} from "./reducers/proposalLabourExpenseReducers";

// Proposal legal section reducer imports
import {
  proposalLegalSectionCreateReducer,
  proposalLegalSectionDeleteReducer,
  proposalLegalSectionDetailsReducer,
  proposalLegalSectionDownloadReducer,
  proposalLegalSectionListReducer,
  proposalLegalSectionUpdateReducer,
  proposalLegalSectionUploadReducer,
} from "./reducers/proposalLegalSectionReducers";

// Proposal lesson reducer imports
import {
  proposalLessonCreateReducer,
  proposalLessonDeleteReducer,
  proposalLessonDetailsReducer,
  proposalLessonListReducer,
  proposalLessonUpdateReducer,
} from "./reducers/proposalLessonReducers";

// Proposal milestone reducer imports
import {
  proposalMilestoneCreateReducer,
  proposalMilestoneDeleteReducer,
  proposalMilestoneDetailsReducer,
  proposalMilestoneListReducer,
  proposalMilestoneUpdateReducer,
} from "./reducers/proposalMilestoneReducers";

// Proposal non-labour expense reducer imports
import {
  proposalNonLabourExpenseCreateReducer,
  proposalNonLabourExpenseDeleteReducer,
  proposalNonLabourExpenseDetailsReducer,
  proposalNonLabourExpenseListReducer,
  proposalNonLabourExpenseUpdateReducer,
} from "./reducers/proposalNonLabourExpenseReducers";

// Proposal permission reducer imports
import {
  proposalPermissionCreateReducer,
  proposalPermissionDeleteReducer,
  proposalPermissionDetailsReducer,
  proposalPermissionListReducer,
  proposalPermissionUpdateReducer,
} from "./reducers/proposalPermissionReducers";

// Proposal question reducer imports
import {
  proposalQuestionCreateReducer,
  proposalQuestionDeleteReducer,
  proposalQuestionDetailsReducer,
  proposalQuestionListReducer,
  proposalQuestionUpdateReducer,
} from "./reducers/proposalQuestionReducers";

// Proposal rate reducer imports
import {
  proposalRateCreateReducer,
  proposalRateDeleteReducer,
  proposalRateDetailsReducer,
  proposalRateListReducer,
  proposalRateUpdateReducer,
} from "./reducers/proposalRateReducers";

// Proposal requirement reducer imports
import {
  proposalRequirementCreateReducer,
  proposalRequirementDeleteReducer,
  proposalRequirementDetailsReducer,
  proposalRequirementDownloadReducer,
  proposalRequirementListReducer,
  proposalRequirementUpdateReducer,
  proposalRequirementUploadReducer,
} from "./reducers/proposalRequirementReducers";

// Proposal risk reducer imports
import {
  proposalRiskCreateReducer,
  proposalRiskDeleteReducer,
  proposalRiskDetailsReducer,
  proposalRiskListReducer,
  proposalRiskUpdateReducer,
} from "./reducers/proposalRiskReducers";

// Proposal service reducer imports
import {
  proposalServiceCreateReducer,
  proposalServiceDeleteReducer,
  proposalServiceDetailsReducer,
  proposalServiceListReducer,
  proposalServiceUpdateReducer,
} from "./reducers/proposalServiceReducers";

// Proposal service activity reducer imports
import {
  proposalServiceActivityCreateReducer,
  proposalServiceActivityDeleteReducer,
  proposalServiceActivityDetailsReducer,
  proposalServiceActivityListReducer,
  proposalServiceActivityUpdateReducer,
} from "./reducers/proposalServiceActivityReducers";

// Proposal service deliverable reducer imports
import {
  proposalServiceDeliverableCreateReducer,
  proposalServiceDeliverableDeleteReducer,
  proposalServiceDeliverableDetailsReducer,
  proposalServiceDeliverableListReducer,
  proposalServiceDeliverableUpdateReducer,
} from "./reducers/proposalServiceDeliverableReducers";

// Proposal staff reducer imports
import {
  proposalStaffCreateReducer,
  proposalStaffDeleteReducer,
  proposalStaffDetailsReducer,
  proposalStaffListReducer,
  proposalStaffUpdateReducer,
} from "./reducers/proposalStaffReducers";

// Proposal upload reducer imports
import {
  proposalUploadCreateReducer,
  proposalUploadDeleteReducer,
  proposalUploadDetailsReducer,
  proposalUploadListReducer,
  proposalUploadUpdateReducer,
} from "./reducers/proposalUploadReducers";

// Rank reducer imports
import {
  rankCreateReducer,
  rankDeleteReducer,
  rankDetailsReducer,
  rankListReducer,
  rankUpdateReducer,
} from "./reducers/rankReducers";

// Rank cost rate reducer imports
import {
  rankCostRateCreateReducer,
  rankCostRateDeleteReducer,
  rankCostRateDetailsReducer,
  rankCostRateListReducer,
  rankCostRateUpdateReducer,
} from "./reducers/rankCostRateReducers";

// Reimbursement reducer imports
import {
  reimbursementDetailsReducer,
  reimbursementDownloadReducer,
  reimbursementListReducer,
  reimbursementUpdateReducer,
} from "./reducers/reimbursementReducers";

// Role reducer imports
import {
  roleCreateReducer,
  roleDeleteReducer,
  roleDetailsReducer,
  roleListReducer,
  roleUpdateReducer,
} from "./reducers/roleReducers";

// Role competency reducer imports
import {
  roleCompetencyCreateReducer,
  roleCompetencyDeleteReducer,
  roleCompetencyDetailsReducer,
  roleCompetencyListReducer,
  roleCompetencyUpdateReducer,
} from "./reducers/roleCompetencyReducers";

// Role permission reducer imports
import {
  rolePermissionCreateReducer,
  rolePermissionDeleteReducer,
  rolePermissionDetailsReducer,
  rolePermissionListReducer,
  rolePermissionUpdateReducer,
} from "./reducers/rolePermissionReducers";

// Salary benchmark reducer imports
import { salaryBenchmarkListReducer } from "./reducers/salaryBenchmarkReducers";

// Seat reducer imports
import {
  seatDetailsReducer,
  seatListReducer,
  seatUpdateReducer,
} from "./reducers/seatReducers";

// Service reducer imports
import {
  serviceCreateReducer,
  serviceDeleteReducer,
  serviceDetailsReducer,
  serviceListReducer,
  serviceUpdateReducer,
} from "./reducers/serviceReducers";

// Service activity reducer imports
import {
  serviceActivityCreateReducer,
  serviceActivityDeleteReducer,
  serviceActivityDetailsReducer,
  serviceActivityListReducer,
  serviceActivityUpdateReducer,
  serviceActivityUploadReducer,
} from "./reducers/serviceActivityReducers";

// Service deliverable reducer imports
import {
  serviceDeliverableCreateReducer,
  serviceDeliverableDeleteReducer,
  serviceDeliverableDetailsReducer,
  serviceDeliverableListReducer,
  serviceDeliverableUpdateReducer,
  serviceDeliverableUploadReducer,
} from "./reducers/serviceDeliverableReducers";

// Service need reducer imports
import {
  serviceNeedCreateReducer,
  serviceNeedDeleteReducer,
  serviceNeedDetailsReducer,
  serviceNeedListReducer,
  serviceNeedUpdateReducer,
} from "./reducers/serviceNeedReducers";

// Service permission reducer imports
import {
  servicePermissionCreateReducer,
  servicePermissionDeleteReducer,
  servicePermissionDetailsReducer,
  servicePermissionListReducer,
  servicePermissionUpdateReducer,
} from "./reducers/servicePermissionReducers";

// Staff reducer imports
import {
  staffCalculateRoleFitScoreReducer,
  staffDetailsReducer,
  staffGenerateUtilisationAnalysisReducer,
  staffListReducer,
  staffUpdateReducer,
} from "./reducers/staffReducers";

// Staff competency reducer imports
import {
  staffCompetencyCreateReducer,
  staffCompetencyDeleteReducer,
  staffCompetencyDetailsReducer,
  staffCompetencyListReducer,
  staffCompetencyUpdateReducer,
  staffCompetencyUploadReducer,
} from "./reducers/staffCompetencyReducers";

// Staff development plan reducer imports
import {
  staffDevelopmentPlanCreateReducer,
  staffDevelopmentPlanDeleteReducer,
  staffDevelopmentPlanDetailsReducer,
  staffDevelopmentPlanListReducer,
  staffDevelopmentPlanUpdateReducer,
} from "./reducers/staffDevelopmentPlanReducers";

// Staff goal reducer imports
import {
  staffGoalCreateReducer,
  staffGoalDeleteReducer,
  staffGoalDetailsReducer,
  staffGoalListReducer,
  staffGoalUpdateReducer,
} from "./reducers/staffGoalReducers";

// Staff permission reducer imports
import {
  staffPermissionCreateReducer,
  staffPermissionDeleteReducer,
  staffPermissionDetailsReducer,
  staffPermissionListReducer,
  staffPermissionUpdateReducer,
} from "./reducers/staffPermissionReducers";

// Text reducer imports
import {
  textExtractDataReducer,
  textExtractDataFromFileReducer,
  textRewriteFieldReducer,
} from "./reducers/textReducers";

// Timesheet reducer imports
import {
  timesheetCreateReducer,
  timesheetDeleteReducer,
  timesheetDetailsReducer,
  timesheetDownloadReducer,
  timesheetDuplicateReducer,
  timesheetListReducer,
  timesheetReverseReducer,
  timesheetUpdateReducer,
} from "./reducers/timesheetReducers";

// Timesheet entry reducer imports
import {
  timesheetEntryCreateReducer,
  timesheetEntryDeleteReducer,
  timesheetEntryDetailsReducer,
  timesheetEntryListReducer,
  timesheetEntryUpdateReducer,
} from "./reducers/timesheetEntryReducers";

// To-do reducer imports
import { todoListReducer, todoUpdateReducer } from "./reducers/todoReducers";

// UI reducer imports
import {
  ShowActionSidebarReducer,
  ShowSidebarReducer,
  UIModeReducer,
} from "./reducers/UIReducers";

// User reducer imports
import {
  userEmailConfirmReducer,
  userEmailConfirmResendReducer,
  userLoginReducer,
  userPasswordChangeReducer,
  userPasswordResetReducer,
  userPasswordResetConfirmReducer,
  userProfileDetailsReducer,
  userProfileUpdateReducer,
  userSignupReducer,
} from "./reducers/userReducers";

// Week reducer imports
import { weekListReducer } from "./reducers/weekReducers";

// Workflow reducer imports
import {
  workflowCreateReducer,
  workflowDeleteReducer,
  workflowDetailsReducer,
  workflowListReducer,
  workflowUpdateReducer,
} from "./reducers/workflowReducers";

// Combine reducers
const appReducer = combineReducers({
  // AI credit transaction reducers
  aiCreditTransactionList: aiCreditTransactionListReducer,

  // Approval request reducers
  approvalRequestCreate: approvalRequestCreateReducer,
  approvalRequestDelete: approvalRequestDeleteReducer,
  approvalRequestDetails: approvalRequestDetailsReducer,
  approvalRequestDownload: approvalRequestDownloadReducer,
  approvalRequestList: approvalRequestListReducer,
  approvalRequestUpdate: approvalRequestUpdateReducer,

  // Approval response reducers
  approvalResponseCreate: approvalResponseCreateReducer,
  approvalResponseDelete: approvalResponseDeleteReducer,
  approvalResponseDetails: approvalResponseDetailsReducer,
  approvalResponseList: approvalResponseListReducer,
  approvalResponseUpdate: approvalResponseUpdateReducer,

  // Approval role reducers
  approvalRoleCreate: approvalRoleCreateReducer,
  approvalRoleDelete: approvalRoleDeleteReducer,
  approvalRoleDetails: approvalRoleDetailsReducer,
  approvalRoleList: approvalRoleListReducer,
  approvalRoleUpdate: approvalRoleUpdateReducer,

  // Booking reducers
  bookingCreate: bookingCreateReducer,
  bookingDelete: bookingDeleteReducer,
  bookingDetails: bookingDetailsReducer,
  bookingList: bookingListReducer,
  bookingUpdate: bookingUpdateReducer,

  // Booking code reducers
  bookingCodeCreate: bookingCodeCreateReducer,
  bookingCodeDelete: bookingCodeDeleteReducer,
  bookingCodeDetails: bookingCodeDetailsReducer,
  bookingCodeList: bookingCodeListReducer,
  bookingCodeUpdate: bookingCodeUpdateReducer,

  // Case study reducers
  caseStudyCreate: caseStudyCreateReducer,
  caseStudyDelete: caseStudyDeleteReducer,
  caseStudyDetails: caseStudyDetailsReducer,
  caseStudyList: caseStudyListReducer,
  caseStudyUpdate: caseStudyUpdateReducer,

  // Case study permission reducers
  caseStudyPermissionCreate: caseStudyPermissionCreateReducer,
  caseStudyPermissionDelete: caseStudyPermissionDeleteReducer,
  caseStudyPermissionDetails: caseStudyPermissionDetailsReducer,
  caseStudyPermissionList: caseStudyPermissionListReducer,
  caseStudyPermissionUpdate: caseStudyPermissionUpdateReducer,

  // Claim reducers
  claimCreate: claimCreateReducer,
  claimDelete: claimDeleteReducer,
  claimDetails: claimDetailsReducer,
  claimDuplicate: claimDuplicateReducer,
  claimList: claimListReducer,
  claimReverse: claimReverseReducer,
  claimUpdate: claimUpdateReducer,

  // Client reducers
  clientCreate: clientCreateReducer,
  clientDelete: clientDeleteReducer,
  clientDetails: clientDetailsReducer,
  clientDownload: clientDownloadReducer,
  clientIntel: clientIntelReducer,
  clientList: clientListReducer,
  clientUpdate: clientUpdateReducer,
  clientUpload: clientUploadReducer,

  // Client action reducers
  clientActionCreate: clientActionCreateReducer,
  clientActionDelete: clientActionDeleteReducer,
  clientActionDetails: clientActionDetailsReducer,
  clientActionList: clientActionListReducer,
  clientActionUpdate: clientActionUpdateReducer,

  // Client KPI reducers
  clientKPICreate: clientKPICreateReducer,
  clientKPIDelete: clientKPIDeleteReducer,
  clientKPIDetails: clientKPIDetailsReducer,
  clientKPIList: clientKPIListReducer,
  clientKPIUpdate: clientKPIUpdateReducer,

  // Client need reducers
  clientNeedCreate: clientNeedCreateReducer,
  clientNeedDelete: clientNeedDeleteReducer,
  clientNeedDetails: clientNeedDetailsReducer,
  clientNeedList: clientNeedListReducer,
  clientNeedUpdate: clientNeedUpdateReducer,

  // Client permission reducers
  clientPermissionCreate: clientPermissionCreateReducer,
  clientPermissionDelete: clientPermissionDeleteReducer,
  clientPermissionDetails: clientPermissionDetailsReducer,
  clientPermissionList: clientPermissionListReducer,
  clientPermissionUpdate: clientPermissionUpdateReducer,

  // Client program reducers
  clientProgramCreate: clientProgramCreateReducer,
  clientProgramDelete: clientProgramDeleteReducer,
  clientProgramDetails: clientProgramDetailsReducer,
  clientProgramList: clientProgramListReducer,
  clientProgramUpdate: clientProgramUpdateReducer,

  // Client question reducers
  clientQuestionCreate: clientQuestionCreateReducer,
  clientQuestionDelete: clientQuestionDeleteReducer,
  clientQuestionDetails: clientQuestionDetailsReducer,
  clientQuestionList: clientQuestionListReducer,
  clientQuestionUpdate: clientQuestionUpdateReducer,

  // Client stakeholder reducers
  clientStakeholderCreate: clientStakeholderCreateReducer,
  clientStakeholderDelete: clientStakeholderDeleteReducer,
  clientStakeholderDetails: clientStakeholderDetailsReducer,
  clientStakeholderList: clientStakeholderListReducer,
  clientStakeholderUpdate: clientStakeholderUpdateReducer,

  // Company reducers
  companyDetails: companyDetailsReducer,
  companyUpdate: companyUpdateReducer,

  // Competency reducers
  competencyCreate: competencyCreateReducer,
  competencyDelete: competencyDeleteReducer,
  competencyDetails: competencyDetailsReducer,
  competencyDownload: competencyDownloadReducer,
  competencyList: competencyListReducer,
  competencyUpdate: competencyUpdateReducer,
  competencyUpload: competencyUploadReducer,

  // Contact reducers
  contactCreate: contactCreateReducer,
  contactCreateCommunicationAction: contactCreateCommunicationActionReducer,
  contactDelete: contactDeleteReducer,
  contactDetails: contactDetailsReducer,
  contactDownload: contactDownloadReducer,
  contactList: contactListReducer,
  contactReferrerList: contactReferrerListReducer,
  contactUpdate: contactUpdateReducer,
  contactUpload: contactUploadReducer,

  // Contact action reducers
  contactActionCreate: contactActionCreateReducer,
  contactActionDelete: contactActionDeleteReducer,
  contactActionDetails: contactActionDetailsReducer,
  contactActionList: contactActionListReducer,
  contactActionUpdate: contactActionUpdateReducer,

  // Contact permission reducers
  contactPermissionCreate: contactPermissionCreateReducer,
  contactPermissionDelete: contactPermissionDeleteReducer,
  contactPermissionDetails: contactPermissionDetailsReducer,
  contactPermissionList: contactPermissionListReducer,
  contactPermissionUpdate: contactPermissionUpdateReducer,

  // Currency reducers
  currencyCreate: currencyCreateReducer,
  currencyDelete: currencyDeleteReducer,
  currencyDetails: currencyDetailsReducer,
  currencyGetCurrencyChoices: currencyGetCurrencyChoicesReducer,
  currencyGetOrganisationCurrencyChoices:
    currencyGetOrganisationCurrencyChoicesReducer,
  currencyGetRate: currencyGetRateReducer,
  currencyList: currencyListReducer,

  // Currency exchange rate reducers
  currencyExchangeRateCreate: currencyExchangeRateCreateReducer,
  currencyExchangeRateDelete: currencyExchangeRateDeleteReducer,
  currencyExchangeRateDetails: currencyExchangeRateDetailsReducer,
  currencyExchangeRateList: currencyExchangeRateListReducer,
  currencyExchangeRateUpdate: currencyExchangeRateUpdateReducer,

  // Demand profile reducers
  demandProfileGenerate: demandProfileGenerateReducer,

  // Document reducers
  documentGeneratePdf: documentGeneratePdfReducer,
  documentGeneratePdfFromHtml: documentGeneratePdfFromHtmlReducer,

  // Engagement reducers
  engagementCreate: engagementCreateReducer,
  engagementDelete: engagementDeleteReducer,
  engagementDetails: engagementDetailsReducer,
  engagementDownload: engagementDownloadReducer,
  engagementGenerateReportData: engagementGenerateReportDataReducer,
  engagementList: engagementListReducer,
  engagementUpdate: engagementUpdateReducer,

  // Engagement action reducers
  engagementActionCreate: engagementActionCreateReducer,
  engagementActionDelete: engagementActionDeleteReducer,
  engagementActionDetails: engagementActionDetailsReducer,
  engagementActionList: engagementActionListReducer,
  engagementActionUpdate: engagementActionUpdateReducer,

  // Engagement contact reducers
  engagementContactCreate: engagementContactCreateReducer,
  engagementContactDelete: engagementContactDeleteReducer,
  engagementContactDetails: engagementContactDetailsReducer,
  engagementContactList: engagementContactListReducer,
  engagementContactUpdate: engagementContactUpdateReducer,

  // Engagement delivery issue reducers
  engagementDeliveryIssueCreate: engagementDeliveryIssueCreateReducer,
  engagementDeliveryIssueDelete: engagementDeliveryIssueDeleteReducer,
  engagementDeliveryIssueDetails: engagementDeliveryIssueDetailsReducer,
  engagementDeliveryIssueList: engagementDeliveryIssueListReducer,
  engagementDeliveryIssueUpdate: engagementDeliveryIssueUpdateReducer,

  // Engagement dependency reducers
  engagementDependencyCreate: engagementDependencyCreateReducer,
  engagementDependencyDelete: engagementDependencyDeleteReducer,
  engagementDependencyDetails: engagementDependencyDetailsReducer,
  engagementDependencyList: engagementDependencyListReducer,
  engagementDependencyUpdate: engagementDependencyUpdateReducer,

  // Engagement document reducers
  engagementDocumentDetails: engagementDocumentDetailsReducer,
  engagementDocumentUpdate: engagementDocumentUpdateReducer,

  // Engagement fee reducers
  engagementFeeDetails: engagementFeeDetailsReducer,
  engagementFeeList: engagementFeeListReducer,
  engagementFeeUpdate: engagementFeeUpdateReducer,

  // Engagement labour expense reducers
  engagementLabourExpenseCreate: engagementLabourExpenseCreateReducer,
  engagementLabourExpenseDelete: engagementLabourExpenseDeleteReducer,
  engagementLabourExpenseDetails: engagementLabourExpenseDetailsReducer,
  engagementLabourExpenseList: engagementLabourExpenseListReducer,
  engagementLabourExpenseUpdate: engagementLabourExpenseUpdateReducer,

  // Engagement lesson reducers
  engagementLessonCreate: engagementLessonCreateReducer,
  engagementLessonDelete: engagementLessonDeleteReducer,
  engagementLessonDetails: engagementLessonDetailsReducer,
  engagementLessonList: engagementLessonListReducer,
  engagementLessonUpdate: engagementLessonUpdateReducer,

  // Engagement milestone reducers
  engagementMilestoneCreate: engagementMilestoneCreateReducer,
  engagementMilestoneDelete: engagementMilestoneDeleteReducer,
  engagementMilestoneDetails: engagementMilestoneDetailsReducer,
  engagementMilestoneList: engagementMilestoneListReducer,
  engagementMilestoneUpdate: engagementMilestoneUpdateReducer,

  // Engagement non-labour expense reducers
  engagementNonLabourExpenseCreate: engagementNonLabourExpenseCreateReducer,
  engagementNonLabourExpenseDelete: engagementNonLabourExpenseDeleteReducer,
  engagementNonLabourExpenseDetails: engagementNonLabourExpenseDetailsReducer,
  engagementNonLabourExpenseList: engagementNonLabourExpenseListReducer,
  engagementNonLabourExpenseUpdate: engagementNonLabourExpenseUpdateReducer,

  // Engagement permission reducers
  engagementPermissionCreate: engagementPermissionCreateReducer,
  engagementPermissionDelete: engagementPermissionDeleteReducer,
  engagementPermissionDetails: engagementPermissionDetailsReducer,
  engagementPermissionList: engagementPermissionListReducer,
  engagementPermissionUpdate: engagementPermissionUpdateReducer,

  // Engagement rate reducers
  engagementRateCreate: engagementRateCreateReducer,
  engagementRateDelete: engagementRateDeleteReducer,
  engagementRateDetails: engagementRateDetailsReducer,
  engagementRateList: engagementRateListReducer,
  engagementRateUpdate: engagementRateUpdateReducer,

  // Engagement report reducers
  engagementReportCreate: engagementReportCreateReducer,
  engagementReportDelete: engagementReportDeleteReducer,
  engagementReportDetails: engagementReportDetailsReducer,
  engagementReportDownload: engagementReportDownloadReducer,
  engagementReportList: engagementReportListReducer,
  engagementReportUpdate: engagementReportUpdateReducer,

  // Engagement risk reducers
  engagementRiskCreate: engagementRiskCreateReducer,
  engagementRiskDelete: engagementRiskDeleteReducer,
  engagementRiskDetails: engagementRiskDetailsReducer,
  engagementRiskList: engagementRiskListReducer,
  engagementRiskUpdate: engagementRiskUpdateReducer,

  // Engagement service reducers
  engagementServiceCreate: engagementServiceCreateReducer,
  engagementServiceDelete: engagementServiceDeleteReducer,
  engagementServiceDetails: engagementServiceDetailsReducer,
  engagementServiceList: engagementServiceListReducer,
  engagementServiceUpdate: engagementServiceUpdateReducer,

  // Engagement service activity reducers
  engagementServiceActivityCreate: engagementServiceActivityCreateReducer,
  engagementServiceActivityDelete: engagementServiceActivityDeleteReducer,
  engagementServiceActivityDetails: engagementServiceActivityDetailsReducer,
  engagementServiceActivityList: engagementServiceActivityListReducer,
  engagementServiceActivityUpdate: engagementServiceActivityUpdateReducer,

  // Engagement service deliverable reducers
  engagementServiceDeliverableCreate: engagementServiceDeliverableCreateReducer,
  engagementServiceDeliverableDelete: engagementServiceDeliverableDeleteReducer,
  engagementServiceDeliverableDetails:
    engagementServiceDeliverableDetailsReducer,
  engagementServiceDeliverableList: engagementServiceDeliverableListReducer,
  engagementServiceDeliverableUpdate: engagementServiceDeliverableUpdateReducer,

  // Engagement upload reducers
  engagementUploadCreate: engagementUploadCreateReducer,
  engagementUploadDelete: engagementUploadDeleteReducer,
  engagementUploadDetails: engagementUploadDetailsReducer,
  engagementUploadList: engagementUploadListReducer,
  engagementUploadUpdate: engagementUploadUpdateReducer,

  // Expense reducers
  expenseCreate: expenseCreateReducer,
  expenseDelete: expenseDeleteReducer,
  expenseDetails: expenseDetailsReducer,
  expenseList: expenseListReducer,
  expenseUpdate: expenseUpdateReducer,

  // Expense category reducers
  expenseCategoryCreate: expenseCategoryCreateReducer,
  expenseCategoryDelete: expenseCategoryDeleteReducer,
  expenseCategoryDetails: expenseCategoryDetailsReducer,
  expenseCategoryDownload: expenseCategoryDownloadReducer,
  expenseCategoryList: expenseCategoryListReducer,
  expenseCategoryUpdate: expenseCategoryUpdateReducer,
  expenseCategoryUpload: expenseCategoryUploadReducer,

  // Help content reducers
  helpContentList: helpContentListReducer,

  // HubSpot reducers
  hubspotAuthorise: hubspotAuthoriseReducer,
  hubspotDeauthorise: hubspotDeauthoriseReducer,
  hubspotGetContact: hubspotGetContactReducer,
  hubspotContactSync: hubspotContactSyncReducer,

  // Insight reducers
  insightCreate: insightCreateReducer,
  insightDelete: insightDeleteReducer,
  insightDetails: insightDetailsReducer,
  insightList: insightListReducer,
  insightUpdate: insightUpdateReducer,

  // Insight permission reducers
  insightPermissionCreate: insightPermissionCreateReducer,
  insightPermissionDelete: insightPermissionDeleteReducer,
  insightPermissionDetails: insightPermissionDetailsReducer,
  insightPermissionList: insightPermissionListReducer,
  insightPermissionUpdate: insightPermissionUpdateReducer,

  // Interview reducers
  interviewCreate: interviewCreateReducer,
  interviewDelete: interviewDeleteReducer,
  interviewDetails: interviewDetailsReducer,
  interviewList: interviewListReducer,
  interviewUpdate: interviewUpdateReducer,

  // Interview permission reducers
  interviewPermissionCreate: interviewPermissionCreateReducer,
  interviewPermissionDelete: interviewPermissionDeleteReducer,
  interviewPermissionDetails: interviewPermissionDetailsReducer,
  interviewPermissionList: interviewPermissionListReducer,
  interviewPermissionUpdate: interviewPermissionUpdateReducer,

  // Interview question reducers
  interviewQuestionCreate: interviewQuestionCreateReducer,
  interviewQuestionDelete: interviewQuestionDeleteReducer,
  interviewQuestionDetails: interviewQuestionDetailsReducer,
  interviewQuestionList: interviewQuestionListReducer,
  interviewQuestionUpdate: interviewQuestionUpdateReducer,

  // Invoice reducers
  invoiceCreate: invoiceCreateReducer,
  invoiceDelete: invoiceDeleteReducer,
  invoiceDetails: invoiceDetailsReducer,
  invoiceDownload: invoiceDownloadReducer,
  invoiceList: invoiceListReducer,
  invoiceUpdate: invoiceUpdateReducer,

  // Invoice booking reducers
  invoiceBookingDelete: invoiceBookingDeleteReducer,
  invoiceBookingDetails: invoiceBookingDetailsReducer,
  invoiceBookingList: invoiceBookingListReducer,
  invoiceBookingUpdate: invoiceBookingUpdateReducer,

  // Invoice booking credit reducers
  invoiceBookingCreditList: invoiceBookingCreditListReducer,

  // Invoice document reducers
  invoiceDocumentDetails: invoiceDocumentDetailsReducer,
  invoiceDocumentUpdate: invoiceDocumentUpdateReducer,

  // Invoice expense reducers
  invoiceExpenseDelete: invoiceExpenseDeleteReducer,
  invoiceExpenseDetails: invoiceExpenseDetailsReducer,
  invoiceExpenseList: invoiceExpenseListReducer,
  invoiceExpenseUpdate: invoiceExpenseUpdateReducer,

  // Invoice expense credit reducers
  invoiceExpenseCreditList: invoiceExpenseCreditListReducer,

  // Invoice milestone reducers
  invoiceMilestoneDelete: invoiceMilestoneDeleteReducer,
  invoiceMilestoneDetails: invoiceMilestoneDetailsReducer,
  invoiceMilestoneList: invoiceMilestoneListReducer,
  invoiceMilestoneUpdate: invoiceMilestoneUpdateReducer,

  // Invoice milestone credit reducers
  invoiceMilestoneCreditList: invoiceMilestoneCreditListReducer,

  // Invoice other reducers
  invoiceOtherCreate: invoiceOtherCreateReducer,
  invoiceOtherDelete: invoiceOtherDeleteReducer,
  invoiceOtherDetails: invoiceOtherDetailsReducer,
  invoiceOtherList: invoiceOtherListReducer,
  invoiceOtherUpdate: invoiceOtherUpdateReducer,

  // Invoice other credit reducers
  invoiceOtherCreditList: invoiceOtherCreditListReducer,

  // Invoice permission reducers
  invoicePermissionCreate: invoicePermissionCreateReducer,
  invoicePermissionDelete: invoicePermissionDeleteReducer,
  invoicePermissionDetails: invoicePermissionDetailsReducer,
  invoicePermissionList: invoicePermissionListReducer,
  invoicePermissionUpdate: invoicePermissionUpdateReducer,

  // Knowledge base document reducers
  knowledgeBaseDocumentCreate: knowledgeBaseDocumentCreateReducer,
  knowledgeBaseDocumentDelete: knowledgeBaseDocumentDeleteReducer,
  knowledgeBaseDocumentDetails: knowledgeBaseDocumentDetailsReducer,
  knowledgeBaseDocumentList: knowledgeBaseDocumentListReducer,
  knowledgeBaseDocumentUpdate: knowledgeBaseDocumentUpdateReducer,

  // Leave category reducers
  leaveCategoryCreate: leaveCategoryCreateReducer,
  leaveCategoryDelete: leaveCategoryDeleteReducer,
  leaveCategoryDetails: leaveCategoryDetailsReducer,
  leaveCategoryDownload: leaveCategoryDownloadReducer,
  leaveCategoryList: leaveCategoryListReducer,
  leaveCategoryUpdate: leaveCategoryUpdateReducer,
  leaveCategoryUpload: leaveCategoryUploadReducer,

  // Leave request reducers
  leaveRequestCancel: leaveRequestCancelReducer,
  leaveRequestCreate: leaveRequestCreateReducer,
  leaveRequestDelete: leaveRequestDeleteReducer,
  leaveRequestDetails: leaveRequestDetailsReducer,
  leaveRequestList: leaveRequestListReducer,
  leaveRequestUpdate: leaveRequestUpdateReducer,

  // Location reducers
  locationCreate: locationCreateReducer,
  locationDelete: locationDeleteReducer,
  locationDetails: locationDetailsReducer,
  locationList: locationListReducer,
  locationUpdate: locationUpdateReducer,

  // Manager reducers
  managerDetails: managerDetailsReducer,
  managerDownload: managerDownloadReducer,
  managerList: managerListReducer,
  managerUpdate: managerUpdateReducer,

  // Market reducers
  marketCreate: marketCreateReducer,
  marketDelete: marketDeleteReducer,
  marketDetails: marketDetailsReducer,
  marketList: marketListReducer,
  marketUpdate: marketUpdateReducer,

  // Market case study reducers
  marketCaseStudyCreate: marketCaseStudyCreateReducer,
  marketCaseStudyDelete: marketCaseStudyDeleteReducer,
  marketCaseStudyDetails: marketCaseStudyDetailsReducer,
  marketCaseStudyList: marketCaseStudyListReducer,
  marketCaseStudyUpdate: marketCaseStudyUpdateReducer,

  // Market insight reducers
  marketInsightCreate: marketInsightCreateReducer,
  marketInsightDelete: marketInsightDeleteReducer,
  marketInsightDetails: marketInsightDetailsReducer,
  marketInsightList: marketInsightListReducer,
  marketInsightUpdate: marketInsightUpdateReducer,

  // Market need reducers
  marketNeedCreate: marketNeedCreateReducer,
  marketNeedDelete: marketNeedDeleteReducer,
  marketNeedDetails: marketNeedDetailsReducer,
  marketNeedList: marketNeedListReducer,
  marketNeedUpdate: marketNeedUpdateReducer,

  // Market permission reducers
  marketPermissionCreate: marketPermissionCreateReducer,
  marketPermissionDelete: marketPermissionDeleteReducer,
  marketPermissionDetails: marketPermissionDetailsReducer,
  marketPermissionList: marketPermissionListReducer,
  marketPermissionUpdate: marketPermissionUpdateReducer,

  // Membership reducers
  membershipCreate: membershipCreateReducer,
  membershipDelete: membershipDeleteReducer,
  membershipDetails: membershipDetailsReducer,
  membershipList: membershipListReducer,
  membershipUpdate: membershipUpdateReducer,

  // Need reducers
  needCreate: needCreateReducer,
  needDelete: needDeleteReducer,
  needDetails: needDetailsReducer,
  needList: needListReducer,
  needUpdate: needUpdateReducer,

  // Need permission reducers
  needPermissionCreate: needPermissionCreateReducer,
  needPermissionDelete: needPermissionDeleteReducer,
  needPermissionDetails: needPermissionDetailsReducer,
  needPermissionList: needPermissionListReducer,
  needPermissionUpdate: needPermissionUpdateReducer,

  // Need question reducers
  needQuestionCreate: needQuestionCreateReducer,
  needQuestionDelete: needQuestionDeleteReducer,
  needQuestionDetails: needQuestionDetailsReducer,
  needQuestionList: needQuestionListReducer,
  needQuestionUpdate: needQuestionUpdateReducer,
  needQuestionUpload: needQuestionUploadReducer,

  // Notification reducers
  notificationDelete: notificationDeleteReducer,
  notificationList: notificationListReducer,

  // Opening reducers
  openingCreate: openingCreateReducer,
  openingDelete: openingDeleteReducer,
  openingDetails: openingDetailsReducer,
  openingDownload: openingDownloadReducer,
  openingList: openingListReducer,
  openingReferrerList: openingReferrerListReducer,
  openingUpdate: openingUpdateReducer,

  // Opening application reducers
  openingApplicationCreate: openingApplicationCreateReducer,

  // Opening application configuration reducers
  openingApplicationConfigurationApply:
    openingApplicationConfigurationApplyReducer,
  openingApplicationConfigurationDetails:
    openingApplicationConfigurationDetailsReducer,
  openingApplicationConfigurationUpdate:
    openingApplicationConfigurationUpdateReducer,

  // Opening candidate reducers
  openingCandidateDetails: openingCandidateDetailsReducer,
  openingCandidateList: openingCandidateListReducer,
  openingCandidateUpdate: openingCandidateUpdateReducer,

  // Opening document reducers
  openingDocumentDetails: openingDocumentDetailsReducer,
  openingDocumentUpdate: openingDocumentUpdateReducer,

  // Opening evaluation reducers
  openingEvaluationCreate: openingEvaluationCreateReducer,
  openingEvaluationDelete: openingEvaluationDeleteReducer,
  openingEvaluationDetails: openingEvaluationDetailsReducer,
  openingEvaluationList: openingEvaluationListReducer,
  openingEvaluationUpdate: openingEvaluationUpdateReducer,

  // Opening evaluation rating reducers
  openingEvaluationRatingList: openingEvaluationRatingListReducer,
  openingEvaluationRatingUpdate: openingEvaluationRatingUpdateReducer,
  openingEvaluationRatingsUpload: openingEvaluationRatingsUploadReducer,

  // Opening permission reducers
  openingPermissionCreate: openingPermissionCreateReducer,
  openingPermissionDelete: openingPermissionDeleteReducer,
  openingPermissionDetails: openingPermissionDetailsReducer,
  openingPermissionList: openingPermissionListReducer,
  openingPermissionUpdate: openingPermissionUpdateReducer,

  // Organisation reducers
  activeOrganisation: activeOrganisationReducer,
  checkPermissions: checkPermissionsReducer,
  checkSubscription: checkSubscriptionReducer,
  organisationCreate: organisationCreateReducer,
  organisationDelete: organisationDeleteReducer,
  organisationDetails: organisationDetailsReducer,
  organisationList: organisationListReducer,
  organisationUpdate: organisationUpdateReducer,

  // Payment reducers
  checkoutCreate: checkoutCreateReducer,
  customerPortalCreate: customerPortalCreateReducer,

  // Proposal reducers
  proposalCreate: proposalCreateReducer,
  proposalDelete: proposalDeleteReducer,
  proposalDetails: proposalDetailsReducer,
  proposalDownload: proposalDownloadReducer,
  proposalList: proposalListReducer,
  proposalPredictWin: proposalPredictWinReducer,
  proposalUpdate: proposalUpdateReducer,

  // Proposal action reducers
  proposalActionCreate: proposalActionCreateReducer,
  proposalActionDelete: proposalActionDeleteReducer,
  proposalActionDetails: proposalActionDetailsReducer,
  proposalActionList: proposalActionListReducer,
  proposalActionUpdate: proposalActionUpdateReducer,

  // Proposal case study reducers
  proposalCaseStudyCreate: proposalCaseStudyCreateReducer,
  proposalCaseStudyDelete: proposalCaseStudyDeleteReducer,
  proposalCaseStudyDetails: proposalCaseStudyDetailsReducer,
  proposalCaseStudyList: proposalCaseStudyListReducer,
  proposalCaseStudyUpdate: proposalCaseStudyUpdateReducer,

  // Proposal competitor reducers
  proposalCompetitorCreate: proposalCompetitorCreateReducer,
  proposalCompetitorDelete: proposalCompetitorDeleteReducer,
  proposalCompetitorDetails: proposalCompetitorDetailsReducer,
  proposalCompetitorList: proposalCompetitorListReducer,
  proposalCompetitorUpdate: proposalCompetitorUpdateReducer,

  // Proposal contact reducers
  proposalContactCreate: proposalContactCreateReducer,
  proposalContactDelete: proposalContactDeleteReducer,
  proposalContactDetails: proposalContactDetailsReducer,
  proposalContactList: proposalContactListReducer,
  proposalContactUpdate: proposalContactUpdateReducer,

  // Proposal document reducers
  proposalDocumentDetails: proposalDocumentDetailsReducer,
  proposalDocumentUpdate: proposalDocumentUpdateReducer,

  // Proposal fee reducers
  proposalFeeDetails: proposalFeeDetailsReducer,
  proposalFeeList: proposalFeeListReducer,
  proposalFeeUpdate: proposalFeeUpdateReducer,

  // Proposal labour expense reducers
  proposalLabourExpenseCreate: proposalLabourExpenseCreateReducer,
  proposalLabourExpenseDelete: proposalLabourExpenseDeleteReducer,
  proposalLabourExpenseDetails: proposalLabourExpenseDetailsReducer,
  proposalLabourExpenseList: proposalLabourExpenseListReducer,
  proposalLabourExpenseUpdate: proposalLabourExpenseUpdateReducer,

  // Proposal legal section reducers
  proposalLegalSectionCreate: proposalLegalSectionCreateReducer,
  proposalLegalSectionDelete: proposalLegalSectionDeleteReducer,
  proposalLegalSectionDetails: proposalLegalSectionDetailsReducer,
  proposalLegalSectionDownload: proposalLegalSectionDownloadReducer,
  proposalLegalSectionList: proposalLegalSectionListReducer,
  proposalLegalSectionUpdate: proposalLegalSectionUpdateReducer,
  proposalLegalSectionUpload: proposalLegalSectionUploadReducer,

  // Proposal lesson reducers
  proposalLessonCreate: proposalLessonCreateReducer,
  proposalLessonDelete: proposalLessonDeleteReducer,
  proposalLessonDetails: proposalLessonDetailsReducer,
  proposalLessonList: proposalLessonListReducer,
  proposalLessonUpdate: proposalLessonUpdateReducer,

  // Proposal milestone reducers
  proposalMilestoneCreate: proposalMilestoneCreateReducer,
  proposalMilestoneDelete: proposalMilestoneDeleteReducer,
  proposalMilestoneDetails: proposalMilestoneDetailsReducer,
  proposalMilestoneList: proposalMilestoneListReducer,
  proposalMilestoneUpdate: proposalMilestoneUpdateReducer,

  // Proposal non-labour expense reducers
  proposalNonLabourExpenseCreate: proposalNonLabourExpenseCreateReducer,
  proposalNonLabourExpenseDelete: proposalNonLabourExpenseDeleteReducer,
  proposalNonLabourExpenseDetails: proposalNonLabourExpenseDetailsReducer,
  proposalNonLabourExpenseList: proposalNonLabourExpenseListReducer,
  proposalNonLabourExpenseUpdate: proposalNonLabourExpenseUpdateReducer,

  // Proposal permission reducers
  proposalPermissionCreate: proposalPermissionCreateReducer,
  proposalPermissionDelete: proposalPermissionDeleteReducer,
  proposalPermissionDetails: proposalPermissionDetailsReducer,
  proposalPermissionList: proposalPermissionListReducer,
  proposalPermissionUpdate: proposalPermissionUpdateReducer,

  // Proposal question reducers
  proposalQuestionCreate: proposalQuestionCreateReducer,
  proposalQuestionDelete: proposalQuestionDeleteReducer,
  proposalQuestionDetails: proposalQuestionDetailsReducer,
  proposalQuestionList: proposalQuestionListReducer,
  proposalQuestionUpdate: proposalQuestionUpdateReducer,

  // Proposal rate reducers
  proposalRateCreate: proposalRateCreateReducer,
  proposalRateDelete: proposalRateDeleteReducer,
  proposalRateDetails: proposalRateDetailsReducer,
  proposalRateList: proposalRateListReducer,
  proposalRateUpdate: proposalRateUpdateReducer,

  // Proposal requirement reducers
  proposalRequirementCreate: proposalRequirementCreateReducer,
  proposalRequirementDelete: proposalRequirementDeleteReducer,
  proposalRequirementDetails: proposalRequirementDetailsReducer,
  proposalRequirementDownload: proposalRequirementDownloadReducer,
  proposalRequirementList: proposalRequirementListReducer,
  proposalRequirementUpdate: proposalRequirementUpdateReducer,
  proposalRequirementUpload: proposalRequirementUploadReducer,

  // Proposal risk reducers
  proposalRiskCreate: proposalRiskCreateReducer,
  proposalRiskDelete: proposalRiskDeleteReducer,
  proposalRiskDetails: proposalRiskDetailsReducer,
  proposalRiskList: proposalRiskListReducer,
  proposalRiskUpdate: proposalRiskUpdateReducer,

  // Proposal service reducers
  proposalServiceCreate: proposalServiceCreateReducer,
  proposalServiceDelete: proposalServiceDeleteReducer,
  proposalServiceDetails: proposalServiceDetailsReducer,
  proposalServiceList: proposalServiceListReducer,
  proposalServiceUpdate: proposalServiceUpdateReducer,

  // Proposal service activity reducers
  proposalServiceActivityCreate: proposalServiceActivityCreateReducer,
  proposalServiceActivityDelete: proposalServiceActivityDeleteReducer,
  proposalServiceActivityDetails: proposalServiceActivityDetailsReducer,
  proposalServiceActivityList: proposalServiceActivityListReducer,
  proposalServiceActivityUpdate: proposalServiceActivityUpdateReducer,

  // Proposal service deliverable reducers
  proposalServiceDeliverableCreate: proposalServiceDeliverableCreateReducer,
  proposalServiceDeliverableDelete: proposalServiceDeliverableDeleteReducer,
  proposalServiceDeliverableDetails: proposalServiceDeliverableDetailsReducer,
  proposalServiceDeliverableList: proposalServiceDeliverableListReducer,
  proposalServiceDeliverableUpdate: proposalServiceDeliverableUpdateReducer,

  // Proposal staff reducers
  proposalStaffCreate: proposalStaffCreateReducer,
  proposalStaffDelete: proposalStaffDeleteReducer,
  proposalStaffDetails: proposalStaffDetailsReducer,
  proposalStaffList: proposalStaffListReducer,
  proposalStaffUpdate: proposalStaffUpdateReducer,

  // Proposal upload reducers
  proposalUploadCreate: proposalUploadCreateReducer,
  proposalUploadDelete: proposalUploadDeleteReducer,
  proposalUploadDetails: proposalUploadDetailsReducer,
  proposalUploadList: proposalUploadListReducer,
  proposalUploadUpdate: proposalUploadUpdateReducer,

  // Rank reducers
  rankCreate: rankCreateReducer,
  rankDelete: rankDeleteReducer,
  rankDetails: rankDetailsReducer,
  rankList: rankListReducer,
  rankUpdate: rankUpdateReducer,

  // Rank cost rate reducers
  rankCostRateCreate: rankCostRateCreateReducer,
  rankCostRateDelete: rankCostRateDeleteReducer,
  rankCostRateDetails: rankCostRateDetailsReducer,
  rankCostRateList: rankCostRateListReducer,
  rankCostRateUpdate: rankCostRateUpdateReducer,

  // Reimbursement reducers
  reimbursementDetails: reimbursementDetailsReducer,
  reimbursementDownload: reimbursementDownloadReducer,
  reimbursementList: reimbursementListReducer,
  reimbursementUpdate: reimbursementUpdateReducer,

  // Role reducers
  roleCreate: roleCreateReducer,
  roleDelete: roleDeleteReducer,
  roleDetails: roleDetailsReducer,
  roleList: roleListReducer,
  roleUpdate: roleUpdateReducer,

  // Role competency reducers
  roleCompetencyCreate: roleCompetencyCreateReducer,
  roleCompetencyDelete: roleCompetencyDeleteReducer,
  roleCompetencyDetails: roleCompetencyDetailsReducer,
  roleCompetencyList: roleCompetencyListReducer,
  roleCompetencyUpdate: roleCompetencyUpdateReducer,

  // Role permission reducers
  rolePermissionCreate: rolePermissionCreateReducer,
  rolePermissionDelete: rolePermissionDeleteReducer,
  rolePermissionDetails: rolePermissionDetailsReducer,
  rolePermissionList: rolePermissionListReducer,
  rolePermissionUpdate: rolePermissionUpdateReducer,

  // Salary benchmark reducers
  salaryBenchmarkList: salaryBenchmarkListReducer,

  // Seat reducers
  seatDetails: seatDetailsReducer,
  seatList: seatListReducer,
  seatUpdate: seatUpdateReducer,

  // Service reducers
  serviceCreate: serviceCreateReducer,
  serviceDelete: serviceDeleteReducer,
  serviceDetails: serviceDetailsReducer,
  serviceList: serviceListReducer,
  serviceUpdate: serviceUpdateReducer,

  // Service activity reducers
  serviceActivityCreate: serviceActivityCreateReducer,
  serviceActivityDelete: serviceActivityDeleteReducer,
  serviceActivityDetails: serviceActivityDetailsReducer,
  serviceActivityList: serviceActivityListReducer,
  serviceActivityUpdate: serviceActivityUpdateReducer,
  serviceActivityUpload: serviceActivityUploadReducer,

  // Service deliverable reducers
  serviceDeliverableCreate: serviceDeliverableCreateReducer,
  serviceDeliverableDelete: serviceDeliverableDeleteReducer,
  serviceDeliverableDetails: serviceDeliverableDetailsReducer,
  serviceDeliverableList: serviceDeliverableListReducer,
  serviceDeliverableUpdate: serviceDeliverableUpdateReducer,
  serviceDeliverableUpload: serviceDeliverableUploadReducer,

  // Service need reducers
  serviceNeedCreate: serviceNeedCreateReducer,
  serviceNeedDelete: serviceNeedDeleteReducer,
  serviceNeedDetails: serviceNeedDetailsReducer,
  serviceNeedList: serviceNeedListReducer,
  serviceNeedUpdate: serviceNeedUpdateReducer,

  // Service permission reducers
  servicePermissionCreate: servicePermissionCreateReducer,
  servicePermissionDelete: servicePermissionDeleteReducer,
  servicePermissionDetails: servicePermissionDetailsReducer,
  servicePermissionList: servicePermissionListReducer,
  servicePermissionUpdate: servicePermissionUpdateReducer,

  // Staff reducers
  staffCalculateRoleFitScore: staffCalculateRoleFitScoreReducer,
  staffDetails: staffDetailsReducer,
  staffGenerateUtilisationAnalysis: staffGenerateUtilisationAnalysisReducer,
  staffList: staffListReducer,
  staffUpdate: staffUpdateReducer,

  // Staff competency reducers
  staffCompetencyCreate: staffCompetencyCreateReducer,
  staffCompetencyDelete: staffCompetencyDeleteReducer,
  staffCompetencyDetails: staffCompetencyDetailsReducer,
  staffCompetencyList: staffCompetencyListReducer,
  staffCompetencyUpdate: staffCompetencyUpdateReducer,
  staffCompetencyUpload: staffCompetencyUploadReducer,

  // Staff development plan reducers
  staffDevelopmentPlanCreate: staffDevelopmentPlanCreateReducer,
  staffDevelopmentPlanDelete: staffDevelopmentPlanDeleteReducer,
  staffDevelopmentPlanDetails: staffDevelopmentPlanDetailsReducer,
  staffDevelopmentPlanList: staffDevelopmentPlanListReducer,
  staffDevelopmentPlanUpdate: staffDevelopmentPlanUpdateReducer,

  // Staff goal reducers
  staffGoalCreate: staffGoalCreateReducer,
  staffGoalDelete: staffGoalDeleteReducer,
  staffGoalDetails: staffGoalDetailsReducer,
  staffGoalList: staffGoalListReducer,
  staffGoalUpdate: staffGoalUpdateReducer,

  // Staff permission reducers
  staffPermissionCreate: staffPermissionCreateReducer,
  staffPermissionDelete: staffPermissionDeleteReducer,
  staffPermissionDetails: staffPermissionDetailsReducer,
  staffPermissionList: staffPermissionListReducer,
  staffPermissionUpdate: staffPermissionUpdateReducer,

  // Text reducers
  textExtractData: textExtractDataReducer,
  textExtractDataFromFile: textExtractDataFromFileReducer,
  textRewriteField: textRewriteFieldReducer,

  // Timesheet reducers
  timesheetCreate: timesheetCreateReducer,
  timesheetDelete: timesheetDeleteReducer,
  timesheetDetails: timesheetDetailsReducer,
  timesheetDownload: timesheetDownloadReducer,
  timesheetDuplicate: timesheetDuplicateReducer,
  timesheetList: timesheetListReducer,
  timesheetReverse: timesheetReverseReducer,
  timesheetUpdate: timesheetUpdateReducer,

  // Timesheet entry reducers
  timesheetEntryCreate: timesheetEntryCreateReducer,
  timesheetEntryDelete: timesheetEntryDeleteReducer,
  timesheetEntryDetails: timesheetEntryDetailsReducer,
  timesheetEntryList: timesheetEntryListReducer,
  timesheetEntryUpdate: timesheetEntryUpdateReducer,

  // To-do reducers
  todoList: todoListReducer,
  todoUpdate: todoUpdateReducer,

  // UI reducers
  actionSidebarMode: ShowActionSidebarReducer,
  sidebarMode: ShowSidebarReducer,
  UIMode: UIModeReducer,

  // User reducers
  userEmailConfirm: userEmailConfirmReducer,
  userEmailConfirmResend: userEmailConfirmResendReducer,
  userLogin: userLoginReducer,
  userPasswordChange: userPasswordChangeReducer,
  userPasswordReset: userPasswordResetReducer,
  userPasswordResetConfirm: userPasswordResetConfirmReducer,
  userProfileDetails: userProfileDetailsReducer,
  userProfileUpdate: userProfileUpdateReducer,
  userSignup: userSignupReducer,

  // Week reducers
  weekList: weekListReducer,

  // Workflow reducers
  workflowCreate: workflowCreateReducer,
  workflowDelete: workflowDeleteReducer,
  workflowDetails: workflowDetailsReducer,
  workflowList: workflowListReducer,
  workflowUpdate: workflowUpdateReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

// Initial state
const initialState = {
  activeOrganisation: {
    currency: localStorage.getItem("activeOrganisationCurrency"),
    id: Number(localStorage.getItem("activeOrganisationId")),
    name: localStorage.getItem("activeOrganisationName"),
    owner: localStorage.getItem("activeOrganisationOwner"),
  },

  UIMode: {
    lightMode: localStorage.getItem("lightMode") === "true",
  },

  userLogin: {
    loading: false,
    token: localStorage.getItem("token"),
    user: localStorage.getItem("user"),
    success:
      localStorage.getItem("token") !== null &&
      localStorage.getItem("user") !== null,
    error: "",
  },
};

// Middleware
const middleware = [thunk];

// Store
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
