// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Colors,
  Divider,
  H5,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import AddSeatCTACard from "../components/AddSeatCTACard";
import { AppToaster } from "../components/AppToaster";
import ConfirmEmailCTACard from "../components/ConfirmEmailCTACard";
import DemoCoCTACard from "../components/DemoCoCTACard";
import DemoCoExpiredCTACard from "../components/DemoCoExpiredCTACard";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import SubscribeCTACard from "../components/SubscribeCTACard";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";

// Redux action imports
// Currency
import { getCurrencyChoicesCurrencyAction } from "../actions/currencyActions";

// Organisation
import {
  createOrganisationAction,
  listOrganisationsAction,
  resetCreateOrganisationAction,
  resetListOrganisationDetailsAction,
  setActiveOrganisationAction,
} from "../actions/organisationActions";

// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function OrganisationListScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Currency
  const currencyGetCurrencyChoices = useSelector(
    (state) => state.currencyGetCurrencyChoices
  );

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  const checkSubscription = useSelector((state) => state.checkSubscription);

  const organisationCreate = useSelector((state) => state.organisationCreate);

  const organisationDetails = useSelector((state) => state.organisationDetails);

  const organisationList = useSelector((state) => state.organisationList);

  // User profile
  const userProfileDetails = useSelector((state) => state.userProfileDetails);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Organisations
  const [addOrganisationDrawerIsOpen, setAddOrganisationDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Organisation
  const organisationQueryDefault = {
    about: "",
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [organisationQuery, setOrganisationQuery] = useState({
    ...organisationQueryDefault,
  });

  // Form variables
  // Organisation
  const organisationDefault = {
    about: "",
    address: "",
    currency: "US Dollar",
    email: "",
    logo: "",
    logoName: "",
    name: "",
    owner: "",
    ownerEmail: "",
    phone: "",
    tagline: "",
    website: "",
  };
  const [organisation, setOrganisation] = useState({
    ...organisationDefault,
  });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Organisation
    setOrganisation({
      ...organisationDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation activate DemoCo
  useEffect(() => {
    const filteredOrganisations = organisationList.organisations.filter(
      (organisation) => organisation.name === "DemoCo"
    );

    if (!activeOrganisation.id && filteredOrganisations.length > 0) {
      const democo = filteredOrganisations[0];

      dispatch(
        setActiveOrganisationAction({
          currency_short: democo.currency_short,
          id: democo.id,
          name: democo.name,
          owner: democo.owner_email,
        })
      );
    }
    // eslint-disable-next-line
  }, [activeOrganisation.id, organisationList.organisations]);

  // Load currency data
  const [currencies, setCurrencies] = useState([]);

  useEffect(() => {
    dispatch(getCurrencyChoicesCurrencyAction());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currencies.length === 0 && currencyGetCurrencyChoices.currencies) {
      currencyGetCurrencyChoices.currencies.map((currency) =>
        setCurrencies((prevState) => [
          ...prevState,
          {
            id: currency[0],
            icon: (
              <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                {currency[0]}
              </Tag>
            ),
            value: currency[1],
          },
        ])
      );
    }
    // eslint-disable-next-line
  }, [currencyGetCurrencyChoices.success]);

  // Filter organisations
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listOrganisationsAction({ ...organisationQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [organisationQuery]);

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // Form submission
  // Add organisation
  const addOrganisation = () => {
    // Prepare data
    const organisation_data = new FormData();

    organisation.about
      ? organisation_data.append("about", organisation.about)
      : organisation_data.append("about", organisationDefault.about);

    organisation.address
      ? organisation_data.append("address", organisation.address)
      : organisation_data.append("address", organisationDefault.address);

    organisation.currency
      ? organisation_data.append("currency", organisation.currency)
      : organisation_data.append("currency", organisationDefault.currency);

    organisation.email
      ? organisation_data.append("email", organisation.email)
      : organisation_data.append("email", organisationDefault.email);

    if (
      organisation.logo &&
      organisation.logo !== organisationDetails.organisation.logo
    ) {
      organisation_data.append("logo", organisation.logo);
    } else if (organisation.logo === "") {
      organisation_data.append("logo", organisationDefault.logo);
    }

    organisation.name
      ? organisation_data.append("name", organisation.name)
      : organisation_data.append("name", organisationDefault.name);

    organisation.owner
      ? organisation_data.append("owner", organisation.owner)
      : organisation_data.append("owner", organisationDefault.owner);

    organisation.phone
      ? organisation_data.append("phone", organisation.phone)
      : organisation_data.append("phone", organisationDefault.phone);

    organisation.tagline
      ? organisation_data.append("tagline", organisation.tagline)
      : organisation_data.append("tagline", organisationDefault.tagline);

    organisation.website
      ? organisation_data.append("website", organisation.website)
      : organisation_data.append("website", organisationDefault.website);

    // Dispatch action
    dispatch(createOrganisationAction(organisation_data));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the organisation</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "15px", width: "35%" }}>
                <img
                  alt="logo"
                  src={
                    organisation.logo
                      ? URL.createObjectURL(new Blob([organisation.logo]))
                      : "/images/no_logo.png"
                  }
                  style={{
                    aspectRatio: "1/1",
                    background: "white",
                    border: `1px solid ${
                      lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                    }`,
                    borderRadius: "10%",
                    margin: "10px 0 10px 0",
                    objectFit: "contain",
                    width: "100%",
                  }}
                />
              </div>

              <div style={{ marginLeft: "15px", width: "65%" }}>
                {/* Name */}
                <FormDrawerTextField2
                  error={organisationCreate.error}
                  fieldName="name"
                  icon="office"
                  label="Name"
                  labelInfo="(required)"
                  placeholder="Enter the organisation's name"
                  setValueObject={setOrganisation}
                  setValueVariable="name"
                  value={organisation.name}
                />

                {/* Tagline */}
                <FormDrawerTextField2
                  error={organisationCreate.error}
                  fieldName="tagline"
                  icon="app-header"
                  label="Tagline"
                  placeholder="Enter the organisation's tagline"
                  setValueObject={setOrganisation}
                  setValueVariable="tagline"
                  value={organisation.tagline}
                />

                {/* Phone */}
                <FormDrawerTextField2
                  error={organisationCreate.error}
                  fieldName="phone"
                  helperText="Include the country code"
                  icon="phone"
                  label="Phone"
                  placeholder="Enter the organisation's phone number"
                  setValueObject={setOrganisation}
                  setValueVariable="phone"
                  value={organisation.phone}
                />

                {/* Email */}
                <FormDrawerTextField2
                  error={organisationCreate.error}
                  fieldName="email"
                  icon="envelope"
                  label="Email"
                  placeholder="Enter the organisation's contact email"
                  setValueObject={setOrganisation}
                  setValueVariable="email"
                  value={organisation.email}
                />

                {/* Website */}
                <FormDrawerTextField2
                  error={organisationCreate.error}
                  fieldName="website"
                  helperText="You can copy and paste the full url from your browser"
                  icon="globe-network"
                  label="Website"
                  placeholder="Enter the full url of the organisation's website"
                  setValueObject={setOrganisation}
                  setValueVariable="website"
                  value={organisation.website}
                />

                {/* Logo */}
                <FormDrawerFileField2
                  error={organisationCreate.error}
                  fileName="logo"
                  label="Logo"
                  placeholder="Upload the organisation's logo"
                  setValueObject={setOrganisation}
                  setValueFileVariable="logo"
                  setValueNameVariable="logoName"
                  valueFile={organisation.logo}
                  valueName={organisation.logoName}
                />
              </div>
            </div>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "40%" }}>
                {/* Currency */}
                <FormDrawerSelectSimpleField3
                  error={organisationCreate.error}
                  fieldName="currency"
                  helperText="The organisation's base currency"
                  label="Currency"
                  loading={currencyGetCurrencyChoices.loading}
                  options={currencies}
                  setValueObject={setOrganisation}
                  setValueVariable="currency"
                  value={organisation.currency}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "60%" }}>
                <Callout intent="warning">
                  When you add an organisation in advaise.app, you select a base
                  currency. Your financial data will be displayed in this
                  currency. You can't change the base currency after creating
                  your organisation.
                </Callout>
              </div>
            </div>

            <Divider style={{ margin: "15px 0 15px 0" }} />

            {/* Address */}
            <FormDrawerTextAreaField3
              error={organisationCreate.error}
              fieldName="address"
              height="100px"
              label="Address"
              placeholder="Enter the organisation's address"
              setValueObject={setOrganisation}
              setValueVariable="address"
              value={organisation.address}
            />

            {/* About */}
            <FormDrawerTextAreaField3
              error={organisationCreate.error}
              fieldName="about"
              label="About"
              placeholder="Enter a summary of the organisation"
              setValueObject={setOrganisation}
              setValueVariable="about"
              value={organisation.about}
            />
          </Card>
        }
        error={organisationCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add organisation"
            module="Organisations"
            view="List"
          />
        }
        helpTitle="Creating an organisation"
        icon="edit"
        isOpen={addOrganisationDrawerIsOpen}
        onSave={addOrganisation}
        refreshList={() => {
          dispatch(listOrganisationsAction({}));
        }}
        resetAction={() => {
          dispatch(resetCreateOrganisationAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={organisationCreate.success}
        saveSuccessMessage="Organisation created."
        saving={organisationCreate.loading}
        setIsOpen={setAddOrganisationDrawerIsOpen}
        title="Add organisation"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              disabled={!activeOrganisation.id}
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddOrganisationDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Organisations"
      />

      {/* Error alerts */}
      {/* Organisations loading error */}
      {organisationList.error && (
        <ErrorAlert message="We couldn't load the organisation list. Try refreshing the page." />
      )}
      {/* User profile loading error */}
      {userProfileDetails.error && (
        <ErrorAlert message="We couldn't load your user profile. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Organisations"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Active organisation */}
      {organisationList.count >= 2 &&
        userProfileDetails.user_profile.email_verified && (
          <div style={{ margin: "20px" }}>
            <Callout intent={activeOrganisation.name ? "success" : "warning"}>
              {activeOrganisation.name
                ? `Your active organisation is ${activeOrganisation.name}.`
                : "You need to choose an organisation to work on in order to access other modules."}
            </Callout>
          </div>
        )}

      {/* Confirm email CTA card */}
      {!userProfileDetails.user_profile.email_verified && (
        <ConfirmEmailCTACard />
      )}

      {/* DemoCo CTA card */}
      {activeOrganisation.name === "DemoCo" &&
        checkSubscription.subscription.has_active_seat &&
        userProfileDetails.user_profile.email_verified && <DemoCoCTACard />}

      {/* DemoCo expired CTA card */}
      {activeOrganisation.name === "DemoCo" &&
        !checkSubscription.subscription.has_active_seat &&
        userProfileDetails.user_profile.email_verified && (
          <DemoCoExpiredCTACard />
        )}

      {/* Subscribe CTA card */}
      {!checkSubscription.subscription.has_subscription &&
        userProfileDetails.user_profile.email_verified && <SubscribeCTACard />}

      {/* Add seat CTA card */}
      {activeOrganisation.name !== "DemoCo" &&
        !checkSubscription.subscription.has_active_seat &&
        checkSubscription.subscription.has_subscription &&
        userProfileDetails.user_profile.email_verified && <AddSeatCTACard />}

      {/* Data table */}
      {userProfileDetails.user_profile.email_verified && (
        <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
          <TableContainer2
            body={organisationList.organisations.map((organisation) => {
              return (
                <tr
                  key={organisation.id}
                  onClick={() => {
                    if (
                      organisation.user_is_admin ||
                      organisation.user_is_owner
                    ) {
                      dispatch(resetListOrganisationDetailsAction());

                      navigate(`/organisations/${organisation.id}`);
                    } else {
                      AppToaster.show({
                        icon: "warning-sign",
                        intent: "danger",
                        message:
                          "You need to be an administrator or the owner to manage this organisation.",
                      });
                    }
                  }}
                >
                  {/* Organisation */}
                  <td style={{ verticalAlign: "middle" }}>
                    <TableText
                      image={organisation.logo}
                      logo
                      showImage
                      tagIntent={
                        organisation.id === activeOrganisation.id
                          ? "success"
                          : "primary"
                      }
                      tagOnClick={() => {
                        dispatch(
                          setActiveOrganisationAction({
                            currency_short: organisation.currency_short,
                            id: organisation.id,
                            name: organisation.name,
                            owner: organisation.owner_email,
                          })
                        );
                      }}
                      tagText={
                        organisation.id === activeOrganisation.id
                          ? "Active"
                          : "Set active"
                      }
                      text={organisation.name}
                    />
                  </td>

                  {/* About */}
                  <td style={{ verticalAlign: "middle" }}>
                    <TableText text={organisation.about} />
                  </td>

                  {/* Owner */}
                  <td style={{ verticalAlign: "middle" }}>
                    <TableText
                      email={organisation.owner_email}
                      image={organisation.owner_profile_photo}
                      showImage
                    />
                  </td>

                  {/* Status */}
                  <td style={{ verticalAlign: "middle" }}>
                    <TableTag
                      intent={
                        organisation.user_is_owner
                          ? "success"
                          : organisation.user_is_admin
                          ? "primary"
                          : undefined
                      }
                      value={
                        organisation.user_is_owner
                          ? "Owner"
                          : organisation.user_is_admin
                          ? "Admin"
                          : "Member"
                      }
                    />
                  </td>
                </tr>
              );
            })}
            clearFiltersDisabled={
              !organisationQuery.about &&
              !organisationQuery.status &&
              !organisationQuery.owner &&
              !organisationQuery.searchString
            }
            count={organisationList.count}
            head={
              <tr>
                {/* Organisation */}
                <TableColumnHeadSearch
                  queryObject={organisationQuery}
                  queryVariable="searchString"
                  setQueryObject={setOrganisationQuery}
                  setQueryVariable="searchString"
                  title="Organisation"
                  width="200px"
                />

                {/* About */}
                <TableColumnHeadSearch
                  queryObject={organisationQuery}
                  queryVariable="about"
                  setQueryObject={setOrganisationQuery}
                  setQueryVariable="about"
                  title="About"
                  width="200px"
                />

                {/* Owner */}
                <TableColumnHeadSearch
                  queryObject={organisationQuery}
                  queryVariable="owner"
                  setQueryObject={setOrganisationQuery}
                  setQueryVariable="owner"
                  title="Owner"
                  width="200px"
                />

                {/* Status */}
                <TableColumnHeadSelectSimple
                  filterObject={organisationQuery}
                  filterVariable="status"
                  options={[
                    { display: "All", icon: "filter-remove", value: "" },
                    {
                      display: "Member",
                      icon: "inherited-group",
                      value: "Member",
                    },
                    {
                      display: "Owner",
                      icon: "crown",
                      value: "Owner",
                    },
                  ]}
                  setFilterObject={setOrganisationQuery}
                  title="Status"
                  width="125px"
                />
              </tr>
            }
            interactive
            loading={organisationList.loading}
            onClearFilters={() => {
              setOrganisationQuery({
                ...organisationQueryDefault,
              });
            }}
            page={organisationQuery.page}
            setPageObject={setOrganisationQuery}
            setPageVariable="page"
          />
        </div>
      )}
    </div>
  );
}

export default OrganisationListScreen;
