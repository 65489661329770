// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDataPoint from "../components/DataCardDataPoint";

// Main function
function AICreditBalanceCard({ fill, aiCreditBalance, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDataPoint
      background={
        aiCreditBalance > 50000
          ? lightMode
            ? Colors.GREEN3
            : Colors.GREEN5
          : aiCreditBalance > 10000
          ? lightMode
            ? Colors.ORANGE3
            : Colors.ORANGE5
          : lightMode
          ? Colors.RED3
          : Colors.RED5
      }
      comments={
        <>
          <H5>Credit pack balance</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Purchased AI credits left to spend. These exclude the AI credits
            included in User seats.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={aiCreditBalance}
      fill={fill}
      labelMargin={25}
      noData={aiCreditBalance === null || aiCreditBalance === 0}
      noDataMessage="You do not have any purchased AI credits"
      title="Credit pack balance"
      units="Credits"
    />
  );
}

export default AICreditBalanceCard;
