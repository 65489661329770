// Constant imports
import {
  ENGAGEMENT_CONTACT_LIST_REQUEST,
  ENGAGEMENT_CONTACT_LIST_SUCCESS,
  ENGAGEMENT_CONTACT_LIST_FAIL,
  ENGAGEMENT_CONTACT_LIST_RESET,
  ENGAGEMENT_CONTACT_CREATE_REQUEST,
  ENGAGEMENT_CONTACT_CREATE_SUCCESS,
  ENGAGEMENT_CONTACT_CREATE_FAIL,
  ENGAGEMENT_CONTACT_CREATE_RESET,
  ENGAGEMENT_CONTACT_DETAILS_REQUEST,
  ENGAGEMENT_CONTACT_DETAILS_SUCCESS,
  ENGAGEMENT_CONTACT_DETAILS_FAIL,
  ENGAGEMENT_CONTACT_DETAILS_RESET,
  ENGAGEMENT_CONTACT_UPDATE_REQUEST,
  ENGAGEMENT_CONTACT_UPDATE_SUCCESS,
  ENGAGEMENT_CONTACT_UPDATE_FAIL,
  ENGAGEMENT_CONTACT_UPDATE_RESET,
  ENGAGEMENT_CONTACT_DELETE_REQUEST,
  ENGAGEMENT_CONTACT_DELETE_SUCCESS,
  ENGAGEMENT_CONTACT_DELETE_FAIL,
  ENGAGEMENT_CONTACT_DELETE_RESET,
} from "../constants/engagementContactConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementContactsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_CONTACT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementContacts(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_CONTACT_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_contacts: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_CONTACT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementContactsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_CONTACT_LIST_RESET });
};

// Create
export const createEngagementContactAction =
  (contact, engagement_contact) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_CONTACT_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );

          engagement_contact.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.createEngagementContact(
        token,
        engagement_contact
      );

      dispatch({
        type: ENGAGEMENT_CONTACT_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_CONTACT_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementContactAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_CONTACT_CREATE_RESET });
};

// List details
export const listEngagementContactDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_CONTACT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementContact(id, token);

      dispatch({
        type: ENGAGEMENT_CONTACT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_CONTACT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementContactDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_CONTACT_DETAILS_RESET });
  };

// Update
export const updateEngagementContactAction =
  (id, contact, engagement_contact) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_CONTACT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (contact) {
        if (contact.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            contact
          );
          engagement_contact.append("contact", data.id);
        }
      }

      const { data } = await cortixDataService.updateEngagementContact(
        id,
        token,
        engagement_contact
      );

      dispatch({
        type: ENGAGEMENT_CONTACT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_CONTACT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementContactAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_CONTACT_UPDATE_RESET });
};

// Delete
export const deleteEngagementContactAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_CONTACT_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementContact(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_CONTACT_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_CONTACT_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementContactAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_CONTACT_DELETE_RESET });
};
