// Constant imports
import {
  INVOICE_MILESTONE_LIST_REQUEST,
  INVOICE_MILESTONE_LIST_SUCCESS,
  INVOICE_MILESTONE_LIST_FAIL,
  INVOICE_MILESTONE_LIST_RESET,
  INVOICE_MILESTONE_DETAILS_REQUEST,
  INVOICE_MILESTONE_DETAILS_SUCCESS,
  INVOICE_MILESTONE_DETAILS_FAIL,
  INVOICE_MILESTONE_DETAILS_RESET,
  INVOICE_MILESTONE_UPDATE_REQUEST,
  INVOICE_MILESTONE_UPDATE_SUCCESS,
  INVOICE_MILESTONE_UPDATE_FAIL,
  INVOICE_MILESTONE_UPDATE_RESET,
  INVOICE_MILESTONE_DELETE_REQUEST,
  INVOICE_MILESTONE_DELETE_SUCCESS,
  INVOICE_MILESTONE_DELETE_FAIL,
  INVOICE_MILESTONE_DELETE_RESET,
} from "../constants/invoiceMilestoneConstants";

// List all
export const invoiceMilestoneListReducer = (
  state = {
    loading: false,
    count: 0,
    invoice_milestones: [],
    totalNet: 0,
    totalNetQuote: 0,
    totalTax: 0,
    totalValue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_MILESTONE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_MILESTONE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoice_milestones: action.payload.invoice_milestones,
        totalNet: action.payload.totalNet,
        totalNetQuote: action.payload.totalNetQuote,
        totalTax: action.payload.totalTax,
        totalValue: action.payload.totalValue,
        success: true,
        error: "",
      };

    case INVOICE_MILESTONE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoice_milestones: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: action.payload,
      };

    case INVOICE_MILESTONE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoice_milestones: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const invoiceMilestoneDetailsReducer = (
  state = { loading: false, invoice_milestone: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_MILESTONE_DETAILS_REQUEST:
      return {
        loading: true,
        invoice_milestone: state.invoice_milestone,
        success: false,
        error: "",
      };

    case INVOICE_MILESTONE_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice_milestone: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_MILESTONE_DETAILS_FAIL:
      return {
        loading: false,
        invoice_milestone: state.invoice_milestone,
        success: false,
        error: action.payload,
      };

    case INVOICE_MILESTONE_DETAILS_RESET:
      return {
        loading: false,
        invoice_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const invoiceMilestoneUpdateReducer = (
  state = { loading: false, invoice_milestone: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_MILESTONE_UPDATE_REQUEST:
      return {
        loading: true,
        invoice_milestone: state.invoice_milestone,
        success: false,
        error: "",
      };

    case INVOICE_MILESTONE_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice_milestone: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_MILESTONE_UPDATE_FAIL:
      return {
        loading: false,
        invoice_milestone: state.invoice_milestone,
        success: false,
        error: action.payload,
      };

    case INVOICE_MILESTONE_UPDATE_RESET:
      return {
        loading: false,
        invoice_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const invoiceMilestoneDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_MILESTONE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INVOICE_MILESTONE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INVOICE_MILESTONE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INVOICE_MILESTONE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
