// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Invoice booking
import { listInvoiceBookingsAction } from "../actions/invoiceBookingActions";

// Main function
function InvoiceBookingInfo({ additionalInfo, bookingData, bookingId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Invoice booking
  const invoiceBookingList = useSelector((state) => state.invoiceBookingList);

  // Refresh invoice booking data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [booking, setBooking] = useState({});

  useEffect(() => {
    if (bookingData) {
      setBooking(bookingData);

      setLoading(false);
    }
  }, [bookingData]);

  useEffect(() => {
    if (!bookingData && !invoiceBookingList.loading) {
      if (invoiceBookingList.success) {
        const thisBooking = invoiceBookingList.invoice_bookings.filter(
          (invoice_booking) => invoice_booking.id === bookingId
        );

        if (thisBooking.length > 0) {
          setBooking(thisBooking[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listInvoiceBookingsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (invoiceBookingList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [invoiceBookingList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{booking.staff_name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Code</strong>
          </p>

          <p>
            {booking.booking_data
              ? booking.booking_data.booking_code_data.name
              : undefined}
          </p>

          <p className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}>
            {booking.booking_data.booking_code_data
              ? booking.booking_data.booking_code_data.code
              : undefined}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Comments</strong>
          </p>

          <p>
            {booking.comments ? (
              booking.comments
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default InvoiceBookingInfo;
