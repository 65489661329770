// Library imports
import { Colors, NonIdealState, Tag } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  XAxis,
} from "recharts";

// Component imports
import InfoPopup from "./InfoPopup";
import ItemCard from "./ItemCard";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Main function
function DataCardThreeBarChart({
  comments,
  darkColor,
  data,
  fill,
  interactive,
  legendData,
  lightColor,
  noData,
  onClick,
  title,
  units,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Highlight item
  const [activeIndex, setActiveIndex] = useState(null);

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div style={{ overflowY: "hidden" }}>
              <ResponsiveContainer height={215} width="100%">
                <BarChart data={data} margin={{ right: 25, top: 25 }}>
                  <defs>
                    {data.map((item, i) => {
                      return (
                        <linearGradient
                          id={`color${item.fill1}`}
                          key={i}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={item.fill1}
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor={item.fill1}
                            stopOpacity={0.7}
                          />
                        </linearGradient>
                      );
                    })}
                  </defs>

                  <defs>
                    {data.map((item, i) => {
                      return (
                        <linearGradient
                          id={`color${item.fill2}`}
                          key={i}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={item.fill2}
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor={item.fill2}
                            stopOpacity={0.7}
                          />
                        </linearGradient>
                      );
                    })}
                  </defs>

                  <defs>
                    {data.map((item, i) => {
                      return (
                        <linearGradient
                          id={`color${item.fill3}`}
                          key={i}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={item.fill3}
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor={item.fill3}
                            stopOpacity={0.7}
                          />
                        </linearGradient>
                      );
                    })}
                  </defs>

                  <Bar
                    dataKey="value1"
                    maxBarSize={65}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                    radius={[5, 5, 0, 0]}
                  >
                    <LabelList
                      dataKey="value1"
                      formatter={(value) =>
                        value ? numberFormatter(value) : ""
                      }
                      position="top"
                      style={{ fontSize: "10px" }}
                    />

                    {data.map((entry, index) => (
                      <Cell
                        fill={`url(#color${entry.fill1})`}
                        key={`cell-${index}`}
                        stroke={index === activeIndex ? entry.fill1 : undefined}
                      />
                    ))}
                  </Bar>

                  <Bar
                    dataKey="value2"
                    maxBarSize={65}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                    radius={[5, 5, 0, 0]}
                  >
                    <LabelList
                      dataKey="value2"
                      formatter={(value) =>
                        value ? numberFormatter(value) : ""
                      }
                      position="top"
                      style={{ fontSize: "10px" }}
                    />

                    {data.map((entry, index) => (
                      <Cell
                        fill={`url(#color${entry.fill2})`}
                        key={`cell-${index}`}
                        stroke={index === activeIndex ? entry.fill2 : undefined}
                      />
                    ))}
                  </Bar>

                  <Bar
                    dataKey="value3"
                    maxBarSize={65}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                    radius={[5, 5, 0, 0]}
                  >
                    <LabelList
                      dataKey="value3"
                      formatter={(value) =>
                        value ? numberFormatter(value) : ""
                      }
                      position="top"
                      style={{ fontSize: "10px" }}
                    />

                    {data.map((entry, index) => (
                      <Cell
                        fill={`url(#color${entry.fill3})`}
                        key={`cell-${index}`}
                        stroke={index === activeIndex ? entry.fill3 : undefined}
                      />
                    ))}
                  </Bar>

                  <Legend
                    align="right"
                    layout="vertical"
                    payload={legendData.map((item) => ({
                      color: item.color,
                      type: item.type,
                      value: item.value,
                    }))}
                    verticalAlign="top"
                    wrapperStyle={{
                      backgroundColor: lightMode
                        ? Colors.LIGHT_GRAY5
                        : Colors.DARK_GRAY4,
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY1 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: 3,
                      lineHeight: "30px",
                      marginRight: "-20px",
                      padding: "0 10px 0 10px",
                    }}
                  />

                  <XAxis
                    dataKey="name"
                    tick={{ fill: lightMode ? Colors.BLACK : Colors.WHITE }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState icon={"search"} title={"No data"} />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={title}
      width={width}
    />
  );
}

export default DataCardThreeBarChart;
