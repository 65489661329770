// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  H1,
  H3,
  Spinner,
} from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Redux action imports
// User
import {
  confirmEmailAction,
  resetConfirmEmailAction,
} from "../actions/userActions";

// Main function
function EmailConfirmScreen() {
  // Key
  const key = useParams().key;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // User
  const userEmailConfirm = useSelector((state) => state.userEmailConfirm);

  // Confirm email
  useEffect(() => {
    dispatch(
      confirmEmailAction({
        key: key,
      })
    );
    // eslint-disable-next-line
  }, []);

  // JSX UI code
  return (
    <div
      className={Classes.DARK}
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(24, 145, 172, 0.8), rgba(24, 145, 172, 1))",
        display: "flex",
        justifyContent: "center",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      <Card
        elevation={4}
        style={{
          border: "1px solid #D2ECF9",
          borderRadius: "5px",
          margin: "20px 5px 20px 5px",
          maxWidth: "400px",
          width: "100%",
        }}
      >
        <div className="row" style={{ textAlign: "center" }}>
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              objectFit: "contain",
              width: "100px",
            }}
          />
        </div>

        <div className="row">
          <div className="col-s-12">
            {/* Error message */}
            {userEmailConfirm.error && (
              <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                We couldn't confirm your email. Please refresh the page.
              </Callout>
            )}

            {/* Heading */}
            <H1>
              {!userEmailConfirm ? "Confirming your email" : "Thank you!"}
            </H1>

            <div style={{ margin: "20px auto 20px 0" }}>
              {!userEmailConfirm ? (
                <Spinner />
              ) : (
                <H3 style={{ color: "#D2ECF9" }}>
                  Your email has been confirmed.
                </H3>
              )}
            </div>

            <Button
              disabled={!userEmailConfirm}
              fill
              icon="log-in"
              intent="primary"
              large
              onClick={() => {
                dispatch(resetConfirmEmailAction());

                navigate("/");
              }}
              text="Login"
              type="submit"
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default EmailConfirmScreen;
