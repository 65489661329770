// Constant imports
import {
  PROPOSAL_SERVICE_LIST_REQUEST,
  PROPOSAL_SERVICE_LIST_SUCCESS,
  PROPOSAL_SERVICE_LIST_FAIL,
  PROPOSAL_SERVICE_LIST_RESET,
  PROPOSAL_SERVICE_CREATE_REQUEST,
  PROPOSAL_SERVICE_CREATE_SUCCESS,
  PROPOSAL_SERVICE_CREATE_FAIL,
  PROPOSAL_SERVICE_CREATE_RESET,
  PROPOSAL_SERVICE_DETAILS_REQUEST,
  PROPOSAL_SERVICE_DETAILS_SUCCESS,
  PROPOSAL_SERVICE_DETAILS_FAIL,
  PROPOSAL_SERVICE_DETAILS_RESET,
  PROPOSAL_SERVICE_UPDATE_REQUEST,
  PROPOSAL_SERVICE_UPDATE_SUCCESS,
  PROPOSAL_SERVICE_UPDATE_FAIL,
  PROPOSAL_SERVICE_UPDATE_RESET,
  PROPOSAL_SERVICE_DELETE_REQUEST,
  PROPOSAL_SERVICE_DELETE_SUCCESS,
  PROPOSAL_SERVICE_DELETE_FAIL,
  PROPOSAL_SERVICE_DELETE_RESET,
} from "../constants/proposalServiceConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalServicesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_SERVICE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalServices(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_SERVICE_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_services: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_SERVICE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalServicesAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_SERVICE_LIST_RESET });
};

// Create
export const createProposalServiceAction =
  (proposal_service) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_SERVICE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalService(
        token,
        proposal_service
      );

      dispatch({
        type: PROPOSAL_SERVICE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_SERVICE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalServiceAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_SERVICE_CREATE_RESET });
};

// List details
export const listProposalServiceDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_SERVICE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalService(id, token);

      dispatch({
        type: PROPOSAL_SERVICE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_SERVICE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalServiceDetailsAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_SERVICE_DETAILS_RESET });
};

// Update
export const updateProposalServiceAction =
  (id, proposal_service) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_SERVICE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalService(
        id,
        token,
        proposal_service
      );

      dispatch({
        type: PROPOSAL_SERVICE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_SERVICE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalServiceAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_SERVICE_UPDATE_RESET });
};

// Delete
export const deleteProposalServiceAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_SERVICE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalService(id, token);

      dispatch({
        type: PROPOSAL_SERVICE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_SERVICE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalServiceAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_SERVICE_DELETE_RESET });
};
