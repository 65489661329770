// Library imports
import { Card, Colors } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TabSectionFieldImage({
  alt,
  fill,
  image,
  height,
  interactive,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <>
      {image ? (
        <Card
          interactive={interactive}
          onClick={interactive ? () => window.open(image, "_blank") : undefined}
          style={{
            background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
            maxWidth: "100%",
            padding: "10px",
            textAlign: "center",
            width: fill ? "100%" : "fit-content",
          }}
        >
          <img
            alt={alt}
            src={image}
            style={{
              height: height,
              maxHeight: "75vh",
              maxWidth: "800px",
              width: "100%",
            }}
          />

          {title && (
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              {title}
            </div>
          )}
        </Card>
      ) : (
        "-"
      )}
    </>
  );
}

export default TabSectionFieldImage;
