// Library imports
import { Alignment, Button, Collapse, Colors, H5 } from "@blueprintjs/core";
import React, { useState } from "react";

// Component imports
import IconSquare from "../components/IconSquare";

// Main function
function DashboardSection({ content, disabled, icon, title }) {
  // Collapse variables
  const [collapse, setCollapse] = useState(false);

  // JSX UI code
  return (
    <div
      style={{
        margin: "10px auto 0 auto",
        maxWidth: "1280px",
      }}
    >
      <Button
        alignText={Alignment.LEFT}
        children={<H5 style={{ margin: "0" }}>{title}</H5>}
        disabled={disabled}
        icon={
          <IconSquare
            darkBackground={Colors.DARK_GRAY3}
            darkColor={Colors.BLUE5}
            icon={icon}
            lightBackground={Colors.LIGHT_GRAY5}
            lightColor={Colors.BLUE2}
          />
        }
        large
        minimal
        onClick={() => {
          setCollapse(!collapse);
        }}
        rightIcon={collapse ? "chevron-down" : "chevron-up"}
      />

      <Collapse isOpen={!collapse}>{content}</Collapse>
    </div>
  );
}

export default DashboardSection;
