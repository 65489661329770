// Library imports
import { Card, Classes, Colors, Tag, Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function PermissionCard({ interactive, onClick, permission }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        alignItems: "center",
        borderTop: `5px solid ${
          permission.level === "Edit" ? Colors.GREEN3 : Colors.BLUE3
        }`,
        display: "flex",
        height: "200px",
        margin: "5px",
        padding: "10px",
        textAlign: "center",
        width: "200px",
      }}
    >
      <div style={{ textAlign: "center", width: "100%" }}>
        <img
          alt="profile"
          src={
            permission.profile_photo
              ? permission.profile_photo
              : "/images/no_photo.png"
          }
          style={{
            background: "white",
            border: `1px solid ${
              lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
            }`,
            borderRadius: "50%",
            height: "110px",
            margin: "10px 0 10px 0",
            objectFit: "contain",
            width: "110px",
          }}
        />

        <div
          style={{
            maxHeight: "50px",
            overflowY: "hidden",
          }}
        >
          <Text className={Classes.TEXT_SMALL}>{permission.manager_name}</Text>
        </div>

        <div style={{ margin: "10px 0 10px 0" }}>
          {permission.level === "Edit" ? (
            <Tag
              intent="success"
              minimal
              round
              style={{
                textAlign: "center",
                width: "125px",
              }}
            >
              <span className={Classes.TEXT_SMALL}>Edit</span>
            </Tag>
          ) : (
            <Tag
              intent="primary"
              minimal
              round
              style={{
                textAlign: "center",
                width: "125px",
              }}
            >
              <span className={Classes.TEXT_SMALL}>View</span>
            </Tag>
          )}
        </div>
      </div>
    </Card>
  );
}

export default PermissionCard;
