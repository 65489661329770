// Library imports
import html2canvas from "html2canvas";

// Main function
const generateImageFromComponent = async (ref) => {
  const component = ref.current;

  const canvas = await html2canvas(component, { useCORS: true });

  const dataUrl = canvas.toDataURL("image/jpg");

  const imageFile = fetch(dataUrl)
    .then((res) => res.blob())
    .then((blob) => {
      const file = new File([blob], "schedule.jpg", { type: "image/jpg" });

      return file;
    });

  return imageFile;
};

export default generateImageFromComponent;
