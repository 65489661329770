// Library imports
import { Card, Classes, Colors, H1, H3, H5, Icon, UL } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import TabSectionEmailLink from "../components/TabSectionEmailLink";

// Redux action imports
// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function SubscribeCTACard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // JSX UI code
  return (
    <div
      style={{
        margin: "20px auto 20px auto",
        maxWidth: "1600px",
        padding: "0 20px 0 20px",
      }}
    >
      <Card
        style={{
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <div
          style={{
            alignItems: "start",
            display: "flex",
            justifyContent: "start",
            marginRight: "10px",
            padding: "10px",
            width: "25%",
          }}
        >
          <Icon
            color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
            icon="feed-subscribed"
            size={300}
          />
        </div>

        <div style={{ marginLeft: "10px", width: "75%", zIndex: 1 }}>
          <div style={{ margin: "0 auto 0 auto", width: "800px" }}>
            <H1
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
              }}
            >
              Subscribe to advaise.app
            </H1>

            <div
              className={Classes.BLOCKQUOTE}
              style={{ margin: "20px 0 20px 0" }}
            >
              Now that you have created your organization, you need to subscribe
              to a plan and assign yourself a seat to continue using
              advaise.app.
            </div>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              Benefits of advaise.app
            </H3>

            <H5 style={{ marginTop: "20px" }}>
              Win more work, with less effort
            </H5>

            <UL>
              <li>Client and contact management.</li>

              <li>Proposal qualification and planning.</li>

              <li>Content and document generation.</li>
            </UL>

            <H5 style={{ marginTop: "20px" }}>Build a great team, faster</H5>

            <UL>
              <li>Workforce planning and analytics.</li>

              <li>Candidate sourcing and evaluation.</li>

              <li>Performance management.</li>
            </UL>

            <H5 style={{ marginTop: "20px" }}>
              Deliver successful, profitable projects
            </H5>

            <UL>
              <li>Project planning, scheduling and monitoring.</li>

              <li>Timesheet and expense management.</li>

              <li>Project financial management.</li>
            </UL>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              How to subscribe
            </H3>

            <div style={{ margin: "10px 0 10px 0" }}>
              Open your organization and select the Subscriptions tab. Work
              through the sections under this tab to create a subscription and
              add seats as required. Assign yourself a seat when you are
              finished.
            </div>

            <div style={{ margin: "10px 0 0 0" }}>
              If you need help subscribing or have questions, we are happy to
              help. You can contact us at{" "}
              <TabSectionEmailLink email="support@advaise.app" />.
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default SubscribeCTACard;
