// Constant imports
import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_RESET,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  CLIENT_CREATE_RESET,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_DETAILS_RESET,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_UPDATE_RESET,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  CLIENT_DELETE_RESET,
  CLIENT_GET_INTEL_REQUEST,
  CLIENT_GET_INTEL_SUCCESS,
  CLIENT_GET_INTEL_FAIL,
  CLIENT_GET_INTEL_RESET,
  CLIENT_DOWNLOAD_REQUEST,
  CLIENT_DOWNLOAD_SUCCESS,
  CLIENT_DOWNLOAD_FAIL,
  CLIENT_DOWNLOAD_RESET,
  CLIENT_UPLOAD_BEGIN,
  CLIENT_UPLOAD_REQUEST,
  CLIENT_UPLOAD_SUCCESS,
  CLIENT_UPLOAD_FAIL,
  CLIENT_UPLOAD_END,
  CLIENT_UPLOAD_RESET,
} from "../constants/clientConstants";

// List all
export const clientListReducer = (
  state = {
    loading: false,
    count: 0,
    clients: [],
    averageMarginPercentageOfProposalsCancelled: 0,
    averageMarginPercentageOfProposalsLost: 0,
    averageMarginPercentageOfProposalsWon: 0,
    totalEngagementMargin: 0,
    totalEngagementRevenue: 0,
    totalValueOfProposalsCancelled: 0,
    totalValueOfProposalsInProgress: 0,
    totalValueOfProposalsLost: 0,
    totalValueOfProposalsQualifying: 0,
    totalValueOfProposalsSubmitted: 0,
    totalValueOfProposalsWon: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        clients: action.payload.clients,
        averageMarginPercentageOfProposalsCancelled:
          action.payload.averageMarginPercentageOfProposalsCancelled,
        averageMarginPercentageOfProposalsLost:
          action.payload.averageMarginPercentageOfProposalsLost,
        averageMarginPercentageOfProposalsWon:
          action.payload.averageMarginPercentageOfProposalsWon,
        totalEngagementMargin: action.payload.totalEngagementMargin,
        totalEngagementRevenue: action.payload.totalEngagementRevenue,
        totalValueOfProposalsCancelled:
          action.payload.totalValueOfProposalsCancelled,
        totalValueOfProposalsInProgress:
          action.payload.totalValueOfProposalsInProgress,
        totalValueOfProposalsLost: action.payload.totalValueOfProposalsLost,
        totalValueOfProposalsQualifying:
          action.payload.totalValueOfProposalsQualifying,
        totalValueOfProposalsSubmitted:
          action.payload.totalValueOfProposalsSubmitted,
        totalValueOfProposalsWon: action.payload.totalValueOfProposalsWon,
        success: true,
        error: "",
      };

    case CLIENT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        clients: [],
        averageMarginPercentageOfProposalsCancelled: 0,
        averageMarginPercentageOfProposalsLost: 0,
        averageMarginPercentageOfProposalsWon: 0,
        totalEngagementMargin: 0,
        totalEngagementRevenue: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: action.payload,
      };

    case CLIENT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        clients: [],
        averageMarginPercentageOfProposalsCancelled: 0,
        averageMarginPercentageOfProposalsLost: 0,
        averageMarginPercentageOfProposalsWon: 0,
        totalEngagementMargin: 0,
        totalEngagementRevenue: 0,
        totalValueOfProposalsCancelled: 0,
        totalValueOfProposalsInProgress: 0,
        totalValueOfProposalsLost: 0,
        totalValueOfProposalsQualifying: 0,
        totalValueOfProposalsSubmitted: 0,
        totalValueOfProposalsWon: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientCreateReducer = (
  state = { loading: false, client: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_CREATE_REQUEST:
      return {
        loading: true,
        client: state.client,
        success: false,
        error: "",
      };

    case CLIENT_CREATE_SUCCESS:
      return {
        loading: false,
        client: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_CREATE_FAIL:
      return {
        loading: false,
        client: state.client,
        success: false,
        error: action.payload,
      };

    case CLIENT_CREATE_RESET:
      return { loading: false, client: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const clientDetailsReducer = (
  state = { loading: false, client: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_DETAILS_REQUEST:
      return {
        loading: true,
        client: state.client,
        success: false,
        error: "",
      };

    case CLIENT_DETAILS_SUCCESS:
      return {
        loading: false,
        client: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_DETAILS_FAIL:
      return {
        loading: false,
        client: state.client,
        success: false,
        error: action.payload,
      };

    case CLIENT_DETAILS_RESET:
      return { loading: false, client: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const clientUpdateReducer = (
  state = { loading: false, client: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_UPDATE_REQUEST:
      return {
        loading: true,
        client: state.client,
        success: false,
        error: "",
      };

    case CLIENT_UPDATE_SUCCESS:
      return {
        loading: false,
        client: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_UPDATE_FAIL:
      return {
        loading: false,
        client: state.client,
        success: false,
        error: action.payload,
      };

    case CLIENT_UPDATE_RESET:
      return { loading: false, client: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const clientDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Get intel
export const clientIntelReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_GET_INTEL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_GET_INTEL_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_GET_INTEL_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case CLIENT_GET_INTEL_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const clientDownloadReducer = (
  state = {
    loading: false,
    clients: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        clients: action.payload.clients,
        success: true,
        error: "",
      };

    case CLIENT_DOWNLOAD_FAIL:
      return {
        loading: false,
        clients: [],
        success: false,
        error: action.payload,
      };

    case CLIENT_DOWNLOAD_RESET:
      return {
        loading: false,
        clients: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const clientUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    progress: 0,
    records_failed: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case CLIENT_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        records_failed: action.payload.records_failed,
        success: false,
        error: action.payload.error,
      };

    case CLIENT_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case CLIENT_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        progress: 0,
        records_failed: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
