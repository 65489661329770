// Constant imports
import {
  ROLE_PERMISSION_LIST_REQUEST,
  ROLE_PERMISSION_LIST_SUCCESS,
  ROLE_PERMISSION_LIST_FAIL,
  ROLE_PERMISSION_LIST_RESET,
  ROLE_PERMISSION_CREATE_REQUEST,
  ROLE_PERMISSION_CREATE_SUCCESS,
  ROLE_PERMISSION_CREATE_FAIL,
  ROLE_PERMISSION_CREATE_RESET,
  ROLE_PERMISSION_DETAILS_REQUEST,
  ROLE_PERMISSION_DETAILS_SUCCESS,
  ROLE_PERMISSION_DETAILS_FAIL,
  ROLE_PERMISSION_DETAILS_RESET,
  ROLE_PERMISSION_UPDATE_REQUEST,
  ROLE_PERMISSION_UPDATE_SUCCESS,
  ROLE_PERMISSION_UPDATE_FAIL,
  ROLE_PERMISSION_UPDATE_RESET,
  ROLE_PERMISSION_DELETE_REQUEST,
  ROLE_PERMISSION_DELETE_SUCCESS,
  ROLE_PERMISSION_DELETE_FAIL,
  ROLE_PERMISSION_DELETE_RESET,
} from "../constants/rolePermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listRolePermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getRolePermissions(
        params,
        token
      );

      dispatch({
        type: ROLE_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          role_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ROLE_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListRolePermissionsAction = () => async (dispatch) => {
  dispatch({ type: ROLE_PERMISSION_LIST_RESET });
};

// Create
export const createRolePermissionAction =
  (role_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createRolePermission(
        token,
        role_permission
      );

      dispatch({
        type: ROLE_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateRolePermissionAction = () => async (dispatch) => {
  dispatch({ type: ROLE_PERMISSION_CREATE_RESET });
};

// List details
export const listRolePermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getRolePermission(id, token);

      dispatch({
        type: ROLE_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListRolePermissionDetailsAction = () => async (dispatch) => {
  dispatch({ type: ROLE_PERMISSION_DETAILS_RESET });
};

// Update
export const updateRolePermissionAction =
  (id, role_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateRolePermission(
        id,
        token,
        role_permission
      );

      dispatch({
        type: ROLE_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateRolePermissionAction = () => async (dispatch) => {
  dispatch({ type: ROLE_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteRolePermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ROLE_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteRolePermission(id, token);

      dispatch({
        type: ROLE_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ROLE_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteRolePermissionAction = () => async (dispatch) => {
  dispatch({ type: ROLE_PERMISSION_DELETE_RESET });
};
