export const PROPOSAL_DOCUMENT_DETAILS_REQUEST =
  "PROPOSAL_DOCUMENT_DETAILS_REQUEST";
export const PROPOSAL_DOCUMENT_DETAILS_SUCCESS =
  "PROPOSAL_DOCUMENT_DETAILS_SUCCESS";
export const PROPOSAL_DOCUMENT_DETAILS_FAIL = "PROPOSAL_DOCUMENT_DETAILS_FAIL";
export const PROPOSAL_DOCUMENT_DETAILS_RESET =
  "PROPOSAL_DOCUMENT_DETAILS_RESET";
export const PROPOSAL_DOCUMENT_UPDATE_REQUEST =
  "PROPOSAL_DOCUMENT_UPDATE_REQUEST";
export const PROPOSAL_DOCUMENT_UPDATE_SUCCESS =
  "PROPOSAL_DOCUMENT_UPDATE_SUCCESS";
export const PROPOSAL_DOCUMENT_UPDATE_FAIL = "PROPOSAL_DOCUMENT_UPDATE_FAIL";
export const PROPOSAL_DOCUMENT_UPDATE_RESET = "PROPOSAL_DOCUMENT_UPDATE_RESET";
