// Constant imports
import {
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_RESET,
} from "../constants/engagementServiceActivityConstants";

// List all
export const engagementServiceActivityListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_service_activities: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_service_activities:
          action.payload.engagement_service_activities,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_service_activities: [],
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_service_activities: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementServiceActivityCreateReducer = (
  state = {
    loading: false,
    engagement_service_activity: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_ACTIVITY_CREATE_REQUEST:
      return {
        loading: true,
        engagement_service_activity: state.engagement_service_activity,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_service_activity: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_CREATE_FAIL:
      return {
        loading: false,
        engagement_service_activity: state.engagement_service_activity,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_CREATE_RESET:
      return {
        loading: false,
        engagement_service_activity: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementServiceActivityDetailsReducer = (
  state = {
    loading: false,
    engagement_service_activity: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_service_activity: state.engagement_service_activity,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_service_activity: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_FAIL:
      return {
        loading: false,
        engagement_service_activity: state.engagement_service_activity,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_RESET:
      return {
        loading: false,
        engagement_service_activity: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementServiceActivityUpdateReducer = (
  state = {
    loading: false,
    engagement_service_activity: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_service_activity: state.engagement_service_activity,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_service_activity: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_FAIL:
      return {
        loading: false,
        engagement_service_activity: state.engagement_service_activity,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_RESET:
      return {
        loading: false,
        engagement_service_activity: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementServiceActivityDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_SERVICE_ACTIVITY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_SERVICE_ACTIVITY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
