// Library imports
import { Card, Classes, Colors, H6, Icon, Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function ItemCard({
  content,
  darkColor,
  fill,
  height,
  icon,
  interactive,
  flexHeight,
  lightColor,
  noScroll,
  onClick,
  title,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        borderLeft: `5px solid ${
          lightMode
            ? lightColor
              ? lightColor
              : undefined
            : darkColor
            ? darkColor
            : undefined
        }`,
        borderRadius: "5px",
        flex: fill ? 1 : undefined,
        margin: "5px",
        maxHeight: flexHeight ? undefined : height ? height : "150px",
        minHeight: flexHeight ? undefined : height ? height : "150px",
        maxWidth: fill ? undefined : width ? width : "295px",
        minWidth: width ? width : "295px",
        padding: "20px",
        position: "relative",
      }}
    >
      <div style={{ position: "relative", width: "100%", zIndex: 1 }}>
        <H6>
          <Text ellipsize>{title}</Text>
        </H6>

        <div
          style={{
            maxHeight: flexHeight ? undefined : height ? height - 70 : "80px",
            minHeight: flexHeight ? undefined : height ? height - 70 : "80px",
            overflowY: noScroll ? "hidden" : "auto",
          }}
        >
          {content ? (
            content
          ) : (
            <span className={Classes.TEXT_MUTED}>No content.</span>
          )}
        </div>
      </div>

      <div style={{ bottom: 0, opacity: 0.25, position: "absolute", right: 0 }}>
        {icon && (
          <Icon
            color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
            icon={icon}
            size={height}
          />
        )}
      </div>
    </Card>
  );
}

export default ItemCard;
