// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function ProposalPipelineCard({ fill, proposalList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Pipeline</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total value of active proposals. These are proposals that are being
            qualified or worked on, or that have been submitted but are awaiting
            an outcome.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {proposalList.totalValueOfProposalsQualifying >
                2 *
                  (proposalList.totalValueOfProposalsInProgress +
                    proposalList.totalValueOfProposalsSubmitted) ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The shape of the pipeline looks good. Continue to generate
                      leads, while working on existing opportunities.
                    </div>
                  </div>
                ) : proposalList.totalValueOfProposalsQualifying >
                  proposalList.totalValueOfProposalsInProgress +
                    proposalList.totalValueOfProposalsSubmitted ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The pipeline is too flat. It may be necessary to strike a
                      balance between delivery and marketing efforts to ensure a
                      consistent flow of opportunities.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is at risk of running out of work. To address
                      this issue, it may be necessary to allocate more resources
                      towards lead generation.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Qualifying",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: proposalList.totalValueOfProposalsQualifying,
        },
        {
          name: "In-progress",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: proposalList.totalValueOfProposalsInProgress,
        },
        {
          name: "Submitted",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: proposalList.totalValueOfProposalsSubmitted,
        },
      ]}
      fill={fill}
      noData={
        !proposalList.totalValueOfProposalsInProgress &&
        !proposalList.totalValueOfProposalsQualifying &&
        !proposalList.totalValueOfProposalsSubmitted
      }
      title="Pipeline"
      units={activeOrganisation.currency}
    />
  );
}

export default ProposalPipelineCard;
