export const NEED_LIST_REQUEST = "NEED_LIST_REQUEST";
export const NEED_LIST_SUCCESS = "NEED_LIST_SUCCESS";
export const NEED_LIST_FAIL = "NEED_LIST_FAIL";
export const NEED_LIST_RESET = "NEED_LIST_RESET";

export const NEED_CREATE_REQUEST = "NEED_CREATE_REQUEST";
export const NEED_CREATE_SUCCESS = "NEED_CREATE_SUCCESS";
export const NEED_CREATE_FAIL = "NEED_CREATE_FAIL";
export const NEED_CREATE_RESET = "NEED_CREATE_RESET";

export const NEED_DETAILS_REQUEST = "NEED_DETAILS_REQUEST";
export const NEED_DETAILS_SUCCESS = "NEED_DETAILS_SUCCESS";
export const NEED_DETAILS_FAIL = "NEED_DETAILS_FAIL";
export const NEED_DETAILS_RESET = "NEED_DETAILS_RESET";

export const NEED_UPDATE_REQUEST = "NEED_UPDATE_REQUEST";
export const NEED_UPDATE_SUCCESS = "NEED_UPDATE_SUCCESS";
export const NEED_UPDATE_FAIL = "NEED_UPDATE_FAIL";
export const NEED_UPDATE_RESET = "NEED_UPDATE_RESET";

export const NEED_DELETE_REQUEST = "NEED_DELETE_REQUEST";
export const NEED_DELETE_SUCCESS = "NEED_DELETE_SUCCESS";
export const NEED_DELETE_FAIL = "NEED_DELETE_FAIL";
export const NEED_DELETE_RESET = "NEED_DELETE_RESET";
