// Library imports
import React, { useEffect } from "react";

// Component imports
import { AppToaster } from "../components/AppToaster";
import TableContainer2 from "../components/TableContainer2";

// Main function
function EditableTableContainer({
  activeRecord,
  body,
  bordered,
  clearFiltersDisabled,
  compact,
  count,
  createSuccess,
  createSuccessMessage,
  darkColor,
  deleteSuccess,
  deleteSuccessMessage,
  footer,
  head,
  interactive,
  lightColor,
  loading,
  newForm,
  noMargin,
  noResultsIcon,
  noResultsMessage,
  noResultsTitle,
  onClearFilters,
  page,
  refreshList,
  resetAction,
  resetFormVariables,
  setActiveRecord,
  setPageObject,
  setPageVariable,
  setShowNewForm,
  showNewForm,
  striped,
  updateSuccess,
  updateSuccessMessage,
}) {
  // Close new record form and reset form and query variables on create success
  useEffect(() => {
    if (showNewForm && createSuccess) {
      AppToaster.show({
        icon: "tick-circle",
        intent: "success",
        message: createSuccessMessage,
      });

      resetAction && resetAction();

      refreshList && refreshList();

      resetFormVariables && resetFormVariables();

      setShowNewForm(false);
    }
    // eslint-disable-next-line
  }, [createSuccess]);

  // Close active record and reset form and query variables on delete or update success
  useEffect(() => {
    if (activeRecord && (deleteSuccess || updateSuccess)) {
      deleteSuccess &&
        AppToaster.show({
          icon: "tick-circle",
          intent: "success",
          message: deleteSuccessMessage,
        });

      updateSuccess &&
        AppToaster.show({
          icon: "tick-circle",
          intent: "success",
          message: updateSuccessMessage,
        });

      resetAction && resetAction();

      refreshList && refreshList();

      resetFormVariables && resetFormVariables();

      setActiveRecord(null);
    }
    // eslint-disable-next-line
  }, [deleteSuccess, updateSuccess]);

  // JSX UI code
  return (
    <>
      <TableContainer2
        body={
          showNewForm ? (
            <>
              {body}
              {newForm}
            </>
          ) : (
            body
          )
        }
        bordered={bordered}
        clearFiltersDisabled={clearFiltersDisabled}
        compact={compact}
        count={showNewForm ? count + 1 : count}
        darkColor={darkColor}
        footer={footer}
        head={head}
        interactive={interactive}
        lightColor={lightColor}
        loading={loading}
        noMargin={noMargin}
        noResultsIcon={noResultsIcon}
        noResultsMessage={noResultsMessage}
        noResultsTitle={noResultsTitle}
        onClearFilters={onClearFilters}
        page={page}
        setPageObject={setPageObject}
        setPageVariable={setPageVariable}
        striped={striped}
      />
    </>
  );
}

export default EditableTableContainer;
