// Library imports
import { Classes, Colors, Divider, H5, HTMLTable } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import PercentageValue from "../components/PercentageValue";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";

// Redux action imports
// Booking
import { listBookingsAction } from "../actions/bookingActions";

// Main function
function BookingInfo({ additionalInfo, bookingData, bookingId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Booking
  const bookingList = useSelector((state) => state.bookingList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh booking data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [booking, setBooking] = useState({});

  useEffect(() => {
    if (bookingData) {
      setBooking(bookingData);

      setLoading(false);
    }
  }, [bookingData]);

  useEffect(() => {
    if (!bookingData && !bookingList.loading) {
      if (bookingList.success) {
        const thisBooking = bookingList.bookings.filter(
          (booking) => booking.id === bookingId
        );

        if (thisBooking.length > 0) {
          setBooking(thisBooking[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listBookingsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (bookingList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [bookingList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{booking.staff_name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Code</strong>
          </p>

          <p>
            {booking.booking_code_data
              ? booking.booking_code_data.name
              : undefined}
          </p>

          <p className={[Classes.TEXT_MUTED, Classes.TEXT_SMALL].join(" ")}>
            {booking.booking_code_data
              ? booking.booking_code_data.code
              : undefined}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Task</strong>
          </p>

          <p>
            {booking.task ? (
              booking.task
            ) : (
              <span className={Classes.TEXT_MUTED}>No task identified.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Comments</strong>
          </p>

          <p>
            {booking.comments ? (
              booking.comments
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Financials</strong>
          </p>

          <HTMLTable bordered striped style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={booking.cost} />
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber
                    fillTag
                    invertColor
                    number={booking.margin}
                    tagNumber={booking.margin_percentage}
                    tagPercentage
                  />
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <TableNumber invertColor number={booking.revenue} />
                </td>

                <td
                  style={{
                    verticalAlign: "middle",
                  }}
                >
                  <TableTag
                    fillTag
                    intent={
                      booking.utilisation > 110
                        ? "danger"
                        : booking.utilisation > 100
                        ? "warning"
                        : "success"
                    }
                    small
                    value={<PercentageValue percentage={booking.utilisation} />}
                  />
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Cost
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Margin
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Revenue
                </th>

                <th
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                  }}
                >
                  Utilisation
                </th>
              </tr>
            </thead>
          </HTMLTable>

          <p
            className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
            style={{ marginTop: "5px" }}
          >
            Figures in {activeOrganisation.currency}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default BookingInfo;
