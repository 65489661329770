// Constant imports
import {
  CLIENT_STAKEHOLDER_LIST_REQUEST,
  CLIENT_STAKEHOLDER_LIST_SUCCESS,
  CLIENT_STAKEHOLDER_LIST_FAIL,
  CLIENT_STAKEHOLDER_LIST_RESET,
  CLIENT_STAKEHOLDER_CREATE_REQUEST,
  CLIENT_STAKEHOLDER_CREATE_SUCCESS,
  CLIENT_STAKEHOLDER_CREATE_FAIL,
  CLIENT_STAKEHOLDER_CREATE_RESET,
  CLIENT_STAKEHOLDER_DETAILS_REQUEST,
  CLIENT_STAKEHOLDER_DETAILS_SUCCESS,
  CLIENT_STAKEHOLDER_DETAILS_FAIL,
  CLIENT_STAKEHOLDER_DETAILS_RESET,
  CLIENT_STAKEHOLDER_UPDATE_REQUEST,
  CLIENT_STAKEHOLDER_UPDATE_SUCCESS,
  CLIENT_STAKEHOLDER_UPDATE_FAIL,
  CLIENT_STAKEHOLDER_UPDATE_RESET,
  CLIENT_STAKEHOLDER_DELETE_REQUEST,
  CLIENT_STAKEHOLDER_DELETE_SUCCESS,
  CLIENT_STAKEHOLDER_DELETE_FAIL,
  CLIENT_STAKEHOLDER_DELETE_RESET,
} from "../constants/clientStakeholderConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientStakeholdersAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_STAKEHOLDER_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientStakeholders(
        params,
        token
      );

      dispatch({
        type: CLIENT_STAKEHOLDER_LIST_SUCCESS,

        payload: {
          count: data.count,
          client_stakeholders: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CLIENT_STAKEHOLDER_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListClientStakeholdersAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_STAKEHOLDER_LIST_RESET });
};

// Create
export const createClientStakeholderAction =
  (stakeholder, client_stakeholder) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_STAKEHOLDER_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (stakeholder) {
        if (stakeholder.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            stakeholder
          );

          client_stakeholder.append("stakeholder", data.id);
        }
      }

      const { data } = await cortixDataService.createClientStakeholder(
        token,
        client_stakeholder
      );

      dispatch({
        type: CLIENT_STAKEHOLDER_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_STAKEHOLDER_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateClientStakeholderAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_STAKEHOLDER_CREATE_RESET });
};

// List details
export const listClientStakeholderDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_STAKEHOLDER_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getClientStakeholder(id, token);

      dispatch({
        type: CLIENT_STAKEHOLDER_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_STAKEHOLDER_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListClientStakeholderDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: CLIENT_STAKEHOLDER_DETAILS_RESET });
  };

// Update
export const updateClientStakeholderAction =
  (id, stakeholder, client_stakeholder) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_STAKEHOLDER_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      if (stakeholder) {
        if (stakeholder.get("name")) {
          const { data } = await cortixDataService.createContact(
            token,
            stakeholder
          );
          client_stakeholder.append("stakeholder", data.id);
        }
      }

      const { data } = await cortixDataService.updateClientStakeholder(
        id,
        token,
        client_stakeholder
      );

      dispatch({
        type: CLIENT_STAKEHOLDER_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_STAKEHOLDER_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientStakeholderAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_STAKEHOLDER_UPDATE_RESET });
};

// Delete
export const deleteClientStakeholderAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_STAKEHOLDER_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteClientStakeholder(
        id,
        token
      );

      dispatch({
        type: CLIENT_STAKEHOLDER_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_STAKEHOLDER_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteClientStakeholderAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_STAKEHOLDER_DELETE_RESET });
};
