// Library imports
import { Colors, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function NumericValue({
  currency,
  fillTag,
  invertColor,
  noDecimals,
  noTag,
  number,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ alignItems: "center", display: "flex" }}>
      {currency &&
        (noTag ? (
          <div style={{ marginRight: "5px" }}>{currency}</div>
        ) : (
          <Tag minimal={fillTag ? false : true} style={{ marginRight: "5px" }}>
            {currency}
          </Tag>
        ))}

      <div
        style={{
          color:
            number < 0
              ? lightMode
                ? invertColor
                  ? Colors.RED5
                  : Colors.RED1
                : invertColor
                ? Colors.RED1
                : Colors.RED5
              : undefined,
        }}
      >
        {number
          ? number < 0
            ? "(" +
              Math.abs(number).toLocaleString("en-US", {
                maximumFractionDigits: noDecimals ? 0 : 2,
                minimumFractionDigits: noDecimals ? 0 : 2,
              }) +
              ")"
            : number.toLocaleString("en-US", {
                maximumFractionDigits: noDecimals ? 0 : 2,
                minimumFractionDigits: noDecimals ? 0 : 2,
              })
          : "-"}
      </div>
    </div>
  );
}

export default NumericValue;
