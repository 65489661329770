// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  Colors,
  H1,
  H2,
  H3,
  Tag,
} from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Component imports
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSwitchField from "../components/FormDrawerSwitchField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import LinkTag from "../components/LinkTag";
import TabSectionFieldCollapse from "../components/TabSectionFieldCollapse";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Opening application
import {
  createOpeningApplicationAction,
  resetCreateOpeningApplicationAction,
} from "../actions/openingApplicationActions";

// Opening application configuration
import { applyOpeningApplicationConfigurationAction } from "../actions/openingApplicationConfigurationActions";

// Main function
function OpeningApplicationScreen() {
  // Application public id
  const publicId = useParams().id;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Opening application
  const openingApplicationCreate = useSelector(
    (state) => state.openingApplicationCreate
  );

  // Opening application configuration
  const openingApplicationConfigurationApply = useSelector(
    (state) => state.openingApplicationConfigurationApply
  );

  // Form variables
  // Application
  const applicationDefault = {
    email: "",
    employed: false,
    employer: "",
    employment: "Permanent",
    experience: "",
    linkedinPage: "",
    name: "",
    notes: "",
    notice: "",
    preferredName: "",
    profilePhoto: "",
    profilePhotoName: "",
    phone: "",
    policeCheck: false,
    references: false,
    remuneration: "",
    resume: "",
    resumeName: "",
    role: "",
    roleEnd: "",
    roleStart: "",
  };
  const [application, setApplication] = useState({
    ...applicationDefault,
  });

  // Load application configuration details
  const [applicationClosed, setApplicationClosed] = useState(false);
  const [confirmPoliceCheck, setConfirmPoliceCheck] = useState(true);
  const [confirmRemuneration, setConfirmRemuneration] = useState(true);
  const [organisationCurrency, setOrganisationCurrency] = useState("");
  const [organisationLogo, setOrganisationLogo] = useState("");
  const [organisationName, setOrganisationName] = useState("");
  const [roleName, setRoleName] = useState("");
  const [showEmploymentSection, setShowEmploymentSection] = useState(true);
  const [intro, setIntro] = useState("");
  const [notesGuidance, setNotesGuidance] = useState("");

  useEffect(() => {
    dispatch(applyOpeningApplicationConfigurationAction(publicId));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (openingApplicationConfigurationApply.success) {
      if (
        new Date(
          openingApplicationConfigurationApply.opening_application_configuration.close
        ).getTime() < new Date().getTime()
      ) {
        setApplicationClosed(true);
      }

      setConfirmPoliceCheck(
        openingApplicationConfigurationApply.opening_application_configuration
          .police_check
      );

      setConfirmRemuneration(
        openingApplicationConfigurationApply.opening_application_configuration
          .remuneration
      );

      setIntro(
        openingApplicationConfigurationApply.opening_application_configuration
          .intro
      );

      setNotesGuidance(
        openingApplicationConfigurationApply.opening_application_configuration
          .notes_guidance
      );

      setOrganisationCurrency(
        openingApplicationConfigurationApply.opening_application_configuration
          .organisation_currency
      );

      setOrganisationLogo(
        openingApplicationConfigurationApply.opening_application_configuration
          .organisation_logo
      );

      setOrganisationName(
        openingApplicationConfigurationApply.opening_application_configuration
          .organisation_name
      );

      setRoleName(
        openingApplicationConfigurationApply.opening_application_configuration
          .role_name
      );

      setShowEmploymentSection(
        openingApplicationConfigurationApply.opening_application_configuration
          .employment
      );
    }
    // eslint-disable-next-line
  }, [openingApplicationConfigurationApply.success]);

  // Apply
  const [applied, setApplied] = useState(false);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [reCaptchaToken, setReCaptchaToken] = useState("");

  const onVerify = useCallback((token) => {
    setReCaptchaToken(token);
  }, []);

  const apply = () => {
    // Prepare data
    const application_data = new FormData();

    application.email
      ? application_data.append("email", application.email)
      : application_data.append("email", applicationDefault.email);

    application.employed !== null
      ? application_data.append("employed", application.employed)
      : application_data.append("employed", applicationDefault.employed);

    application.employer
      ? application_data.append("employer", application.employer)
      : application_data.append("employer", applicationDefault.employer);

    application.employment
      ? application_data.append("employment", application.employment)
      : application_data.append("employment", applicationDefault.employment);

    application.experience
      ? application_data.append("experience", application.experience)
      : application_data.append("experience", applicationDefault.experience);

    application.linkedinPage
      ? application_data.append("linkedin_page", application.linkedinPage)
      : application_data.append(
          "linkedin_page",
          applicationDefault.linkedinPage
        );

    application.name
      ? application_data.append("name", application.name)
      : application_data.append("name", applicationDefault.name);

    application.notes
      ? application_data.append("notes", application.notes)
      : application_data.append("notes", applicationDefault.notes);

    application.notice
      ? application_data.append("notice", application.notice)
      : application_data.append("notice", applicationDefault.notice);

    application.preferredName
      ? application_data.append("preferred_name", application.preferredName)
      : application_data.append(
          "preferred_name",
          applicationDefault.preferredName
        );

    application.profilePhoto
      ? application_data.append("profile_photo", application.profilePhoto)
      : application_data.append(
          "profile_photo",
          applicationDefault.profilePhoto
        );

    application.phone
      ? application_data.append("phone", application.phone)
      : application_data.append("phone", applicationDefault.phone);

    application.policeCheck
      ? application_data.append("police_check", application.policeCheck)
      : application_data.append("police_check", applicationDefault.policeCheck);

    application_data.append("public_id", publicId);

    application_data.append("recaptcha_token", reCaptchaToken);

    application.references
      ? application_data.append("references", application.references)
      : application_data.append("references", applicationDefault.references);

    application.remuneration
      ? application_data.append("remuneration", application.remuneration)
      : application_data.append(
          "remuneration",
          applicationDefault.remuneration
        );

    application.resume
      ? application_data.append("resume", application.resume)
      : application_data.append("resume", applicationDefault.resume);

    application.role
      ? application_data.append("role", application.role)
      : application_data.append("role", applicationDefault.role);

    application.roleEnd
      ? application_data.append("role_end", getDjangoDate(application.roleEnd))
      : application_data.append(
          "role_end",
          getDjangoDate(applicationDefault.roleEnd)
        );

    application.roleStart
      ? application_data.append(
          "role_start",
          getDjangoDate(application.roleStart)
        )
      : application_data.append(
          "role_start",
          getDjangoDate(applicationDefault.roleStart)
        );

    // Dispatch action
    dispatch(createOpeningApplicationAction(application_data));

    // Refresh reCAPTCHA
    setRefreshReCaptcha((r) => !r);
  };

  useEffect(() => {
    if (openingApplicationCreate.success) {
      setApplied(true);

      dispatch(resetCreateOpeningApplicationAction());
    }
  }, [dispatch, openingApplicationCreate.success]);

  // JSX UI code
  return (
    <div
      className={Classes.DARK}
      style={{
        alignItems: "center",
        backgroundImage:
          "linear-gradient(rgba(24, 145, 172, 0.8), rgba(24, 145, 172, 1))",
        display: "flex",
        margin: "-50px 0 0 0",
        minHeight: "100vh",
      }}
    >
      {applicationClosed ? (
        <Card
          elevation={4}
          style={{
            border: "1px solid #D2ECF9",
            borderRadius: "5px",
            margin: "0 auto 0 auto",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          {organisationLogo && (
            <div
              className="row"
              style={{
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              <img
                alt="logo"
                src={organisationLogo}
                style={{
                  background: "white",
                  border: `1px solid ${Colors.DARK_GRAY5}`,
                  borderRadius: "10%",
                  maxHeight: "100px",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          )}

          {/* Heading */}
          <div className="row" style={{ textAlign: "center" }}>
            <H1>This application is closed</H1>

            <H2 style={{ color: "#D2ECF9" }}>
              {roleName} at {organisationName}
            </H2>
          </div>
        </Card>
      ) : applied ? (
        <Card
          elevation={4}
          style={{
            border: "1px solid #D2ECF9",
            borderRadius: "5px",
            margin: "0 auto 0 auto",
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <div className="row">
            {organisationLogo && (
              <div
                className="row"
                style={{
                  marginBottom: "30px",
                  textAlign: "center",
                }}
              >
                <img
                  alt="logo"
                  src={organisationLogo}
                  style={{
                    background: "white",
                    border: `1px solid ${Colors.DARK_GRAY5}`,
                    borderRadius: "10%",
                    maxHeight: "100px",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}

            {/* Heading */}
            <div className="row" style={{ textAlign: "center" }}>
              <H1>Thanks for applying</H1>

              <H3 style={{ color: "#D2ECF9" }}>
                The team at {organisationName} will be in touch if successful
              </H3>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          elevation={4}
          style={{
            border: "1px solid #D2ECF9",
            borderRadius: "5px",
            margin: "0 auto 0 auto",
            maxWidth: "800px",
            width: "100%",
          }}
        >
          {/* Logo */}
          {organisationLogo && (
            <div
              className="row"
              style={{
                marginBottom: "30px",
                textAlign: "center",
              }}
            >
              <img
                alt="logo"
                src={organisationLogo}
                style={{
                  background: "white",
                  border: `1px solid ${Colors.DARK_GRAY5}`,
                  borderRadius: "10%",
                  maxHeight: "100px",
                  maxWidth: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          )}

          {/* Heading */}
          <div className="row" style={{ textAlign: "center" }}>
            <H1>Job application</H1>

            <H2 style={{ color: "#D2ECF9" }}>
              {roleName} at {organisationName}
            </H2>
          </div>

          {/* Error message */}
          {openingApplicationCreate.error && (
            <Callout intent="danger" style={{ margin: "15px 15px 0 0" }}>
              {openingApplicationCreate.error.response.data["non_field_errors"]
                ? openingApplicationCreate.error.response.data[
                    "non_field_errors"
                  ]
                : "Review any errors below and try saving again."}
            </Callout>
          )}

          {/* Body */}
          <div
            style={{
              marginRight: "-20px",
              maxHeight: "60vh",
              overflowY: "auto",
              padding: "20px 20px 0 1px",
            }}
          >
            {/* Intro */}
            {intro && (
              <TabSectionFieldCollapse
                content={
                  <div className="row">
                    <div className="col-s-12" style={{ marginTop: "-15px" }}>
                      {intro}
                    </div>
                  </div>
                }
                icon="rocket"
                title="Introduction"
              />
            )}

            {/* Form */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              {/* Contact information */}
              <TabSectionFieldCollapse
                content={
                  <>
                    <div className="row">
                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* Name */}
                        <FormDrawerTextField2
                          error={openingApplicationCreate.error}
                          fieldName="name"
                          icon="id-number"
                          label="Name"
                          labelInfo="(required)"
                          placeholder="Enter your full name"
                          setValueObject={setApplication}
                          setValueVariable="name"
                          value={application.name}
                        />
                      </div>

                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* Email */}
                        <FormDrawerTextField2
                          error={openingApplicationCreate.error}
                          fieldName="email"
                          icon="envelope"
                          label="Email"
                          labelInfo="(required)"
                          placeholder="Enter your email address"
                          setValueObject={setApplication}
                          setValueVariable="email"
                          value={application.email}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* Preferred name */}
                        <FormDrawerTextField2
                          error={openingApplicationCreate.error}
                          fieldName="preferred_name"
                          helperText="Tell us how you would like us to address you"
                          icon="person"
                          label="Preferred name"
                          placeholder="Enter your preferred name"
                          setValueObject={setApplication}
                          setValueVariable="preferredName"
                          value={application.preferredName}
                        />
                      </div>

                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* Phone */}
                        <FormDrawerTextField2
                          error={openingApplicationCreate.error}
                          fieldName="phone"
                          helperText="Include the country code"
                          icon="phone"
                          label="Phone"
                          placeholder="Enter your phone number"
                          setValueObject={setApplication}
                          setValueVariable="phone"
                          value={application.phone}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* LinkedIn page */}
                        <FormDrawerTextField2
                          error={openingApplicationCreate.error}
                          fieldName="linkedin_page"
                          helperText="Enter the full url"
                          icon="social-media"
                          label="LinkedIn page"
                          placeholder="Enter your LinkedIn page"
                          setValueObject={setApplication}
                          setValueVariable="linkedinPage"
                          value={application.linkedinPage}
                        />
                      </div>

                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* Profile photo */}
                        <FormDrawerFileField2
                          error={openingApplicationCreate.error}
                          fileName="profile_photo"
                          label="Profile photo"
                          placeholder="Upload your profile photo"
                          setValueObject={setApplication}
                          setValueFileVariable="profilePhoto"
                          setValueNameVariable="profilePhotoName"
                          valueFile={application.profilePhoto}
                          valueName={application.profilePhotoName}
                        />
                      </div>
                    </div>
                  </>
                }
                icon="id-number"
                title="Contact information"
              />

              {/* Recent employment */}
              {showEmploymentSection && (
                <TabSectionFieldCollapse
                  content={
                    <>
                      <div style={{ marginLeft: "15px" }}>
                        {/* Employed */}
                        <FormDrawerSwitchField
                          error={openingApplicationCreate.error}
                          fieldName="employed"
                          label="I am currently employed"
                          setValueObject={setApplication}
                          setValueVariable="employed"
                          value={application.employed}
                        />
                      </div>

                      <div className="row">
                        <div className="col-s-6" style={{ marginTop: "-10px" }}>
                          {/* Employer */}
                          <FormDrawerTextField2
                            error={openingApplicationCreate.error}
                            fieldName="employer"
                            helperText="Current or most recent employer"
                            icon="office"
                            label="Employer"
                            placeholder="Enter the organisation's name"
                            setValueObject={setApplication}
                            setValueVariable="employer"
                            value={application.employer}
                          />
                        </div>

                        <div className="col-s-6" style={{ marginTop: "-10px" }}>
                          {/* Role */}
                          <FormDrawerTextField2
                            error={openingApplicationCreate.error}
                            fieldName="role"
                            helperText="Current or most recent job title"
                            icon="hat"
                            label="Role"
                            placeholder="Enter your job title"
                            setValueObject={setApplication}
                            setValueVariable="role"
                            value={application.role}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-s-4" style={{ marginTop: "-15px" }}>
                          {/* Employment type */}
                          <FormDrawerSelectSimpleField3
                            error={openingApplicationCreate.error}
                            fieldName="employment"
                            label="Employment type"
                            options={[
                              {
                                id: 1,
                                icon: "full-circle",
                                value: "Permanent",
                              },
                              {
                                id: 2,
                                icon: "ring",
                                value: "Contract",
                              },
                              { id: 3, icon: "circle", value: "Casual" },
                            ]}
                            setValueObject={setApplication}
                            setValueVariable="employment"
                            value={application.employment}
                          />
                        </div>

                        <div className="col-s-4" style={{ marginTop: "-15px" }}>
                          {/* Start date */}
                          <FormDrawerDateField2
                            error={openingApplicationCreate.error}
                            fieldName="role_start"
                            icon="calendar"
                            label="Start date"
                            placeholder="Enter the start date"
                            setValueObject={setApplication}
                            setValueVariable="roleStart"
                            value={application.roleStart}
                          />
                        </div>

                        <div className="col-s-4" style={{ marginTop: "-15px" }}>
                          {/* End date */}
                          <FormDrawerDateField2
                            disabled={application.employed}
                            error={openingApplicationCreate.error}
                            fieldName="role_end"
                            helperText="Leave blank if you are still employed"
                            icon="calendar"
                            label="End date"
                            placeholder="Enter the end date"
                            setValueObject={setApplication}
                            setValueVariable="roleEnd"
                            value={application.roleEnd}
                          />
                        </div>
                      </div>
                    </>
                  }
                  icon="office"
                  title="Recent employment"
                />
              )}

              {/* Application */}
              <TabSectionFieldCollapse
                content={
                  <>
                    <div className="row">
                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        {/* Resume */}
                        <FormDrawerFileField2
                          error={openingApplicationCreate.error}
                          fileName="resume"
                          label="Resume"
                          labelInfo="(required)"
                          placeholder="Upload your resume"
                          setValueObject={setApplication}
                          setValueFileVariable="resume"
                          setValueNameVariable="resumeName"
                          valueFile={application.resume}
                          valueName={application.resumeName}
                        />
                      </div>

                      <div className="col-s-6" style={{ marginTop: "-15px" }}>
                        <Callout
                          icon={null}
                          intent="success"
                          style={{ margin: "0 0 15px 0" }}
                        >
                          Please submit a detailed resume addressing the
                          position requirements, as we use AI to assist in
                          evaluating your experience.
                        </Callout>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-s-3" style={{ marginTop: "-15px" }}>
                        {/* Experience */}
                        <FormDrawerNumericField2
                          error={openingApplicationCreate.error}
                          fieldName="experience"
                          icon="time"
                          helperText="Years experience"
                          label="Experience"
                          majorStepSize={10}
                          min={0}
                          minorStepSize={1}
                          placeholder="Enter years"
                          setValueObject={setApplication}
                          setValueVariable="experience"
                          stepSize={10}
                          value={application.experience}
                        />
                      </div>

                      <div className="col-s-3" style={{ marginTop: "-15px" }}>
                        {/* Notice period */}
                        <FormDrawerNumericField2
                          error={openingApplicationCreate.error}
                          fieldName="notice"
                          icon="time"
                          helperText="Weeks notice"
                          label="Notice period"
                          min={0}
                          placeholder="Enter weeks"
                          setValueObject={setApplication}
                          setValueVariable="notice"
                          stepSize={1}
                          value={application.notice}
                        />
                      </div>

                      {confirmRemuneration && (
                        <div className="col-s-6" style={{ marginTop: "-15px" }}>
                          {/* Expected remuneration */}
                          <FormDrawerNumericField2
                            error={openingApplicationCreate.error}
                            fieldName="remuneration"
                            helperText="Total annual remuneration package"
                            label="Expected remuneration"
                            leftElement={
                              <Tag
                                minimal
                                style={{ textAlign: "center", width: "50px" }}
                              >
                                {organisationCurrency}
                              </Tag>
                            }
                            majorStepSize={100000}
                            min={0}
                            minorStepSize={1000}
                            placeholder="Enter total remuneration"
                            setValueObject={setApplication}
                            setValueVariable="remuneration"
                            stepSize={10000}
                            value={application.remuneration}
                          />
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-s-5" style={{ marginTop: "-15px" }}>
                        {/* References */}
                        <FormDrawerSwitchField
                          error={openingApplicationCreate.error}
                          fieldName="references"
                          label="I can provide references"
                          setValueObject={setApplication}
                          setValueVariable="references"
                          value={application.references}
                        />
                      </div>

                      {confirmPoliceCheck && (
                        <div className="col-s-7" style={{ marginTop: "-15px" }}>
                          {/* Police check */}
                          <FormDrawerSwitchField
                            error={openingApplicationCreate.error}
                            fieldName="police_check"
                            label="I am willing to undergo police checks"
                            setValueObject={setApplication}
                            setValueVariable="policeCheck"
                            value={application.policeCheck}
                          />
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-s-12">
                        {notesGuidance && (
                          <Card style={{ marginTop: "-15px" }}>
                            {notesGuidance}
                          </Card>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-s-12">
                        {/* Additional information */}
                        <FormDrawerTextAreaField3
                          error={openingApplicationCreate.error}
                          fieldName="notes"
                          height="100px"
                          helperText="Use this field to provide information not included above"
                          label="Additional information"
                          noRewrite
                          placeholder="Enter any additional information you would like us to consider"
                          setValueObject={setApplication}
                          setValueVariable="notes"
                          value={application.notes}
                        />
                      </div>
                    </div>
                  </>
                }
                icon="following"
                title="Application"
              />

              {/* reCAPTCHA */}
              <GoogleReCaptcha
                action="apply"
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
              />

              {/* Apply */}
              <Button
                disabled={!reCaptchaToken}
                icon="send-message"
                intent="success"
                loading={openingApplicationCreate.loading}
                onClick={apply}
                style={{ marginLeft: "20px", width: "100px" }}
                text="Apply"
                type="submit"
              />
            </form>

            {/* Footer */}
            <div
              style={{
                margin: "20px 20px 0 20px",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <img
                  alt="logo"
                  src="/images/logo.svg"
                  style={{
                    marginRight: "10px",
                    maxHeight: "60px",
                    objectFit: "contain",
                    width: "60px",
                  }}
                />

                <div>
                  Powered by{" "}
                  <LinkTag
                    intent="success"
                    label="advaise.app"
                    onClick={() =>
                      window.open(
                        `https://blog.advaise.app`,
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  />
                </div>
              </div>

              <p>
                We respect your privacy. For more information, read our{" "}
                <LinkTag
                  label="Privacy Policy"
                  onClick={() =>
                    window.open(
                      `https://blog.advaise.app/privacy`,
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                />
              </p>
            </div>
          </div>
        </Card>
      )}
    </div>
  );
}

export default OpeningApplicationScreen;
