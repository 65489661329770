// Constant imports
import {
  INVOICE_EXPENSE_LIST_REQUEST,
  INVOICE_EXPENSE_LIST_SUCCESS,
  INVOICE_EXPENSE_LIST_FAIL,
  INVOICE_EXPENSE_LIST_RESET,
  INVOICE_EXPENSE_DETAILS_REQUEST,
  INVOICE_EXPENSE_DETAILS_SUCCESS,
  INVOICE_EXPENSE_DETAILS_FAIL,
  INVOICE_EXPENSE_DETAILS_RESET,
  INVOICE_EXPENSE_UPDATE_REQUEST,
  INVOICE_EXPENSE_UPDATE_SUCCESS,
  INVOICE_EXPENSE_UPDATE_FAIL,
  INVOICE_EXPENSE_UPDATE_RESET,
  INVOICE_EXPENSE_DELETE_REQUEST,
  INVOICE_EXPENSE_DELETE_SUCCESS,
  INVOICE_EXPENSE_DELETE_FAIL,
  INVOICE_EXPENSE_DELETE_RESET,
} from "../constants/invoiceExpenseConstants";

// List all
export const invoiceExpenseListReducer = (
  state = {
    loading: false,
    count: 0,
    invoice_expenses: [],
    totalNet: 0,
    totalNetQuote: 0,
    totalTax: 0,
    totalValue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_EXPENSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_EXPENSE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoice_expenses: action.payload.invoice_expenses,
        totalNet: action.payload.totalNet,
        totalNetQuote: action.payload.totalNetQuote,
        totalTax: action.payload.totalTax,
        totalValue: action.payload.totalValue,
        success: true,
        error: "",
      };

    case INVOICE_EXPENSE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoice_expenses: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: action.payload,
      };

    case INVOICE_EXPENSE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoice_expenses: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const invoiceExpenseDetailsReducer = (
  state = { loading: false, invoice_expense: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_EXPENSE_DETAILS_REQUEST:
      return {
        loading: true,
        invoice_expense: state.invoice_expense,
        success: false,
        error: "",
      };

    case INVOICE_EXPENSE_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice_expense: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_EXPENSE_DETAILS_FAIL:
      return {
        loading: false,
        invoice_expense: state.expense,
        success: false,
        error: action.payload,
      };

    case INVOICE_EXPENSE_DETAILS_RESET:
      return { loading: false, invoice_expense: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const invoiceExpenseUpdateReducer = (
  state = { loading: false, invoice_expense: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_EXPENSE_UPDATE_REQUEST:
      return {
        loading: true,
        invoice_expense: state.expense,
        success: false,
        error: "",
      };

    case INVOICE_EXPENSE_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice_expense: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_EXPENSE_UPDATE_FAIL:
      return {
        loading: false,
        invoice_expense: state.expense,
        success: false,
        error: action.payload,
      };

    case INVOICE_EXPENSE_UPDATE_RESET:
      return { loading: false, invoice_expense: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const invoiceExpenseDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_EXPENSE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INVOICE_EXPENSE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INVOICE_EXPENSE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INVOICE_EXPENSE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
