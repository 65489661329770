// Library imports
import { Colors, Divider, H5, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardThreeBarChart from "../components/DataCardThreeBarChart";

// Main function
function CommunicationPlanStatsCard({ contactData, fill, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardThreeBarChart
      comments={
        <>
          <H5>Planned communications</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Number of planned communications that have marked as completed in
            the last month, last quarted and last year. These are categorised as
            follows:
          </p>

          <UL>
            <li>
              In-person: Connected in-person. For example, an informal catch-up
              or inviting them to a professional event.
            </li>

            <li>
              Phone: Connected by phone. For example, an unscheduled call to
              catch up or a formal meeting to talk about a topic of interest.
            </li>

            <li>
              Email: Connected via email. For example, sending a newsletter or
              sharing thought leadership content.
            </li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Last month",
          fill1: lightMode ? Colors.VIOLET3 : Colors.VIOLET5,
          fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          fill3: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value1: contactData.in_person_events_last_month,
          value2: contactData.phone_events_last_month,
          value3: contactData.email_events_last_month,
        },
        {
          name: "Last quarter",
          fill1: lightMode ? Colors.VIOLET3 : Colors.VIOLET5,
          fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          fill3: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value1: contactData.in_person_events_last_quarter,
          value2: contactData.phone_events_last_quarter,
          value3: contactData.email_events_last_quarter,
        },
        {
          name: "Last year",
          fill1: lightMode ? Colors.VIOLET3 : Colors.VIOLET5,
          fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          fill3: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          value1: contactData.in_person_events_last_year,
          value2: contactData.phone_events_last_year,
          value3: contactData.email_events_last_year,
        },
      ]}
      fill={fill}
      legendData={[
        {
          color: lightMode ? Colors.VIOLET3 : Colors.VIOLET5,
          type: "square",
          value: "In-person",
        },
        {
          color: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
          type: "square",
          value: "Phone",
        },
        {
          color: lightMode ? Colors.CERULEAN3 : Colors.CERULEAN5,
          type: "square",
          value: "Email",
        },
      ]}
      noData={
        !contactData.email_events_last_month &&
        !contactData.email_events_last_quarter &&
        !contactData.email_events_last_year &&
        !contactData.in_person_events_last_month &&
        !contactData.in_person_events_last_quarter &&
        !contactData.in_person_events_last_year &&
        !contactData.annual_events_last_month &&
        !contactData.annual_events_last_quarter &&
        !contactData.annual_events_last_year
      }
      title="Planned communications"
      units="Number"
    />
  );
}

export default CommunicationPlanStatsCard;
