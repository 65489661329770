// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function InvoiceTotalValueCard({ fill, invoiceList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Total value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Total value of the selected invoice(s) by status.</p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />
              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {invoiceList.totalValueDraft > 0 ? (
                  <>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      You have not issued some of the selected invoices.
                    </div>
                  </>
                ) : invoiceList.totalValueInvoiced > 0 ? (
                  <>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Some of the selected invoices are awaiting payment.
                    </div>
                  </>
                ) : (
                  <>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      You have not selected any invoices that need to be issued
                      or paid.
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Draft",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: invoiceList.totalValueDraft,
        },
        {
          name: "Invoiced",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: invoiceList.totalValueInvoiced,
        },
        {
          name: "Paid",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: invoiceList.totalValuePaid,
        },
      ]}
      fill={fill}
      noData={
        !invoiceList.totalValueDraft &&
        !invoiceList.totalValueInvoiced &&
        !invoiceList.totalValuePaid
      }
      title="Total value"
      units={activeOrganisation.currency}
    />
  );
}

export default InvoiceTotalValueCard;
