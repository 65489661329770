// Constant imports
import {
  OPENING_CANDIDATE_LIST_REQUEST,
  OPENING_CANDIDATE_LIST_SUCCESS,
  OPENING_CANDIDATE_LIST_FAIL,
  OPENING_CANDIDATE_LIST_RESET,
  OPENING_CANDIDATE_DETAILS_REQUEST,
  OPENING_CANDIDATE_DETAILS_SUCCESS,
  OPENING_CANDIDATE_DETAILS_FAIL,
  OPENING_CANDIDATE_DETAILS_RESET,
  OPENING_CANDIDATE_UPDATE_REQUEST,
  OPENING_CANDIDATE_UPDATE_SUCCESS,
  OPENING_CANDIDATE_UPDATE_FAIL,
  OPENING_CANDIDATE_UPDATE_RESET,
} from "../constants/openingCandidateConstants";

// List all
export const openingCandidateListReducer = (
  state = {
    loading: false,
    count: 0,
    opening_candidates: [],
    averageExperience: [],
    averageNotice: [],
    averageRemuneration: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_CANDIDATE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPENING_CANDIDATE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        opening_candidates: action.payload.opening_candidates,
        averageExperience: action.payload.averageExperience,
        averageNotice: action.payload.averageNotice,
        averageRemuneration: action.payload.averageRemuneration,
        success: true,
        error: "",
      };

    case OPENING_CANDIDATE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        opening_candidates: [],
        averageExperience: [],
        averageNotice: [],
        averageRemuneration: [],
        success: false,
        error: action.payload,
      };

    case OPENING_CANDIDATE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        opening_candidates: [],
        averageExperience: [],
        averageNotice: [],
        averageRemuneration: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const openingCandidateDetailsReducer = (
  state = {
    loading: false,
    opening_candidate: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_CANDIDATE_DETAILS_REQUEST:
      return {
        loading: true,
        opening_candidate: state.opening_candidate,
        success: false,
        error: "",
      };

    case OPENING_CANDIDATE_DETAILS_SUCCESS:
      return {
        loading: false,
        opening_candidate: action.payload,
        success: true,
        error: "",
      };

    case OPENING_CANDIDATE_DETAILS_FAIL:
      return {
        loading: false,
        opening_candidate: state.opening_candidate,
        success: false,
        error: action.payload,
      };

    case OPENING_CANDIDATE_DETAILS_RESET:
      return {
        loading: false,
        opening_candidate: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const openingCandidateUpdateReducer = (
  state = {
    loading: false,
    opening_candidate: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_CANDIDATE_UPDATE_REQUEST:
      return {
        loading: true,
        opening_candidate: state.opening_candidate,
        success: false,
        error: "",
      };

    case OPENING_CANDIDATE_UPDATE_SUCCESS:
      return {
        loading: false,
        opening_candidate: action.payload,
        success: true,
        error: "",
      };

    case OPENING_CANDIDATE_UPDATE_FAIL:
      return {
        loading: false,
        opening_candidate: state.opening_candidate,
        success: false,
        error: action.payload,
      };

    case OPENING_CANDIDATE_UPDATE_RESET:
      return {
        loading: false,
        opening_candidate: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
