// Constant imports
import {
  APPROVAL_REQUEST_LIST_REQUEST,
  APPROVAL_REQUEST_LIST_SUCCESS,
  APPROVAL_REQUEST_LIST_FAIL,
  APPROVAL_REQUEST_LIST_RESET,
  APPROVAL_REQUEST_CREATE_REQUEST,
  APPROVAL_REQUEST_CREATE_SUCCESS,
  APPROVAL_REQUEST_CREATE_FAIL,
  APPROVAL_REQUEST_CREATE_RESET,
  APPROVAL_REQUEST_DETAILS_REQUEST,
  APPROVAL_REQUEST_DETAILS_SUCCESS,
  APPROVAL_REQUEST_DETAILS_FAIL,
  APPROVAL_REQUEST_DETAILS_RESET,
  APPROVAL_REQUEST_UPDATE_REQUEST,
  APPROVAL_REQUEST_UPDATE_SUCCESS,
  APPROVAL_REQUEST_UPDATE_FAIL,
  APPROVAL_REQUEST_UPDATE_RESET,
  APPROVAL_REQUEST_DELETE_REQUEST,
  APPROVAL_REQUEST_DELETE_SUCCESS,
  APPROVAL_REQUEST_DELETE_FAIL,
  APPROVAL_REQUEST_DELETE_RESET,
  APPROVAL_REQUEST_DOWNLOAD_REQUEST,
  APPROVAL_REQUEST_DOWNLOAD_SUCCESS,
  APPROVAL_REQUEST_DOWNLOAD_FAIL,
  APPROVAL_REQUEST_DOWNLOAD_RESET,
} from "../constants/approvalRequestConstants";

// List all
export const approvalRequestListReducer = (
  state = {
    loading: false,
    count: 0,
    approval_requests: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case APPROVAL_REQUEST_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPROVAL_REQUEST_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        approval_requests: action.payload.approval_requests,
        success: true,
        error: "",
      };

    case APPROVAL_REQUEST_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        approval_requests: [],
        success: false,
        error: action.payload,
      };

    case APPROVAL_REQUEST_LIST_RESET:
      return {
        loading: false,
        count: 0,
        approval_requests: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const approvalRequestCreateReducer = (
  state = { loading: false, approval_request: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_REQUEST_CREATE_REQUEST:
      return {
        loading: true,
        approval_request: state.approval_request,
        success: false,
        error: "",
      };

    case APPROVAL_REQUEST_CREATE_SUCCESS:
      return {
        loading: false,
        approval_request: action.payload,
        success: true,
        error: "",
      };

    case APPROVAL_REQUEST_CREATE_FAIL:
      return {
        loading: false,
        approval_request: state.approval_request,
        success: false,
        error: action.payload,
      };

    case APPROVAL_REQUEST_CREATE_RESET:
      return {
        loading: false,
        approval_request: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const approvalRequestDetailsReducer = (
  state = { loading: false, approval_request: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_REQUEST_DETAILS_REQUEST:
      return {
        loading: true,
        approval_request: state.approval_request,
        success: false,
        error: "",
      };

    case APPROVAL_REQUEST_DETAILS_SUCCESS:
      return {
        loading: false,
        approval_request: action.payload,
        success: true,
        error: "",
      };

    case APPROVAL_REQUEST_DETAILS_FAIL:
      return {
        loading: false,
        approval_request: state.approval_request,
        success: false,
        error: action.payload,
      };

    case APPROVAL_REQUEST_DETAILS_RESET:
      return {
        loading: false,
        approval_request: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const approvalRequestUpdateReducer = (
  state = { loading: false, approval_request: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_REQUEST_UPDATE_REQUEST:
      return {
        loading: true,
        approval_request: state.approval_request,
        success: false,
        error: "",
      };

    case APPROVAL_REQUEST_UPDATE_SUCCESS:
      return {
        loading: false,
        approval_request: action.payload,
        success: true,
        error: "",
      };

    case APPROVAL_REQUEST_UPDATE_FAIL:
      return {
        loading: false,
        approval_request: state.approval_request,
        success: false,
        error: action.payload,
      };

    case APPROVAL_REQUEST_UPDATE_RESET:
      return {
        loading: false,
        approval_request: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const approvalRequestDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_REQUEST_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case APPROVAL_REQUEST_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case APPROVAL_REQUEST_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case APPROVAL_REQUEST_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const approvalRequestDownloadReducer = (
  state = {
    loading: false,
    approval_requests: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case APPROVAL_REQUEST_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPROVAL_REQUEST_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        approval_requests: action.payload.approval_requests,
        success: true,
        error: "",
      };

    case APPROVAL_REQUEST_DOWNLOAD_FAIL:
      return {
        loading: false,
        approval_requests: [],
        success: false,
        error: action.payload,
      };

    case APPROVAL_REQUEST_DOWNLOAD_RESET:
      return {
        loading: false,
        approval_requests: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
