export const DOCUMENT_GENERATE_PDF_REQUEST = "DOCUMENT_GENERATE_PDF_REQUEST";
export const DOCUMENT_GENERATE_PDF_SUCCESS = "DOCUMENT_GENERATE_PDF_SUCCESS";
export const DOCUMENT_GENERATE_PDF_FAIL = "DOCUMENT_GENERATE_PDF_FAIL";
export const DOCUMENT_GENERATE_PDF_RESET = "DOCUMENT_GENERATE_PDF_RESET";

export const DOCUMENT_GENERATE_PDF_FROM_HTML_REQUEST =
  "DOCUMENT_GENERATE_PDF_FROM_HTML_REQUEST";
export const DOCUMENT_GENERATE_PDF_FROM_HTML_SUCCESS =
  "DOCUMENT_GENERATE_PDF_FROM_HTML_SUCCESS";
export const DOCUMENT_GENERATE_PDF_FROM_HTML_FAIL =
  "DOCUMENT_GENERATE_PDF_FROM_HTML_FAIL";
export const DOCUMENT_GENERATE_PDF_FROM_HTML_RESET =
  "DOCUMENT_GENERATE_PDF_FROM_HTML_RESET";
