// Library imports
import { Colors, NonIdealState, Tag } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
} from "recharts";

// Component imports
import InfoPopup from "./InfoPopup";
import ItemCard from "./ItemCard";

// Main function
function DataCardRadarChart({
  comments,
  darkColor,
  data,
  fill,
  interactive,
  lightColor,
  noData,
  onClick,
  title,
  units,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Highlight item
  const [activeIndex, setActiveIndex] = useState(null);

  const customisedTick = (props) => {
    const { index, payload, textAnchor, x, y } = props;

    return (
      <g>
        <text
          fill={
            index === activeIndex
              ? lightMode
                ? Colors.BLUE3
                : Colors.BLUE5
              : lightMode
              ? Colors.BLACK
              : Colors.WHITE
          }
          fontSize={10}
          textAnchor={textAnchor}
          x={x}
          y={y}
        >
          {payload.value}
        </text>
      </g>
    );
  };

  // Tick formatter
  const tickFormatter = (value, index) => {
    const char_limit = 20;

    if (value.length < char_limit) return value;

    return `${value.substring(0, char_limit)}...`;
  };

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div style={{ overflowY: "hidden" }}>
              <ResponsiveContainer height={215} width="100%">
                <RadarChart data={data} outerRadius="85%">
                  <PolarAngleAxis
                    dataKey="name"
                    tick={customisedTick}
                    tickFormatter={tickFormatter}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                  />

                  <PolarGrid />

                  <PolarRadiusAxis
                    axisLine={false}
                    domain={[0, 5]}
                    tick={false}
                    ticks={[1, 2, 3, 4, 5]}
                    type="number"
                  />

                  <Radar
                    dataKey="value"
                    fill={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                    fillOpacity={0.6}
                    stroke={lightMode ? Colors.BLUE1 : Colors.BLUE3}
                  />
                </RadarChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState icon={"search"} title={"No data"} />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={title}
      width={width}
    />
  );
}

export default DataCardRadarChart;
