// Constant imports
import {
  STAFF_DEVELOPMENT_PLAN_LIST_REQUEST,
  STAFF_DEVELOPMENT_PLAN_LIST_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_LIST_FAIL,
  STAFF_DEVELOPMENT_PLAN_LIST_RESET,
  STAFF_DEVELOPMENT_PLAN_CREATE_REQUEST,
  STAFF_DEVELOPMENT_PLAN_CREATE_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_CREATE_FAIL,
  STAFF_DEVELOPMENT_PLAN_CREATE_RESET,
  STAFF_DEVELOPMENT_PLAN_DETAILS_REQUEST,
  STAFF_DEVELOPMENT_PLAN_DETAILS_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_DETAILS_FAIL,
  STAFF_DEVELOPMENT_PLAN_DETAILS_RESET,
  STAFF_DEVELOPMENT_PLAN_UPDATE_REQUEST,
  STAFF_DEVELOPMENT_PLAN_UPDATE_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_UPDATE_FAIL,
  STAFF_DEVELOPMENT_PLAN_UPDATE_RESET,
  STAFF_DEVELOPMENT_PLAN_DELETE_REQUEST,
  STAFF_DEVELOPMENT_PLAN_DELETE_SUCCESS,
  STAFF_DEVELOPMENT_PLAN_DELETE_FAIL,
  STAFF_DEVELOPMENT_PLAN_DELETE_RESET,
} from "../constants/staffDevelopmentPlanConstants";

// List all
export const staffDevelopmentPlanListReducer = (
  state = {
    loading: false,
    count: 0,
    numberOfDevelopmentPlansCompleted: 0,
    numberOfDevelopmentPlansInProgress: 0,
    numberOfDevelopmentPlansOnHold: 0,
    staff_development_plans: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_DEVELOPMENT_PLAN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STAFF_DEVELOPMENT_PLAN_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        numberOfDevelopmentPlansCompleted:
          action.payload.numberOfDevelopmentPlansCompleted,
        numberOfDevelopmentPlansInProgress:
          action.payload.numberOfDevelopmentPlansInProgress,
        numberOfDevelopmentPlansOnHold:
          action.payload.numberOfDevelopmentPlansOnHold,
        staff_development_plans: action.payload.staff_development_plans,
        success: true,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        numberOfDevelopmentPlansCompleted: 0,
        numberOfDevelopmentPlansInProgress: 0,
        numberOfDevelopmentPlansOnHold: 0,
        staff_development_plans: [],
        success: false,
        error: action.payload,
      };

    case STAFF_DEVELOPMENT_PLAN_LIST_RESET:
      return {
        loading: false,
        count: 0,
        numberOfDevelopmentPlansCompleted: 0,
        numberOfDevelopmentPlansInProgress: 0,
        numberOfDevelopmentPlansOnHold: 0,
        staff_development_plans: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const staffDevelopmentPlanCreateReducer = (
  state = {
    loading: false,
    staff_development_plan: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_DEVELOPMENT_PLAN_CREATE_REQUEST:
      return {
        loading: true,
        staff_development_plan: state.staff_development_plan,
        success: false,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_CREATE_SUCCESS:
      return {
        loading: false,
        staff_development_plan: action.payload,
        success: true,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_CREATE_FAIL:
      return {
        loading: false,
        staff_development_plan: state.staff_development_plan,
        success: false,
        error: action.payload,
      };

    case STAFF_DEVELOPMENT_PLAN_CREATE_RESET:
      return {
        loading: false,
        staff_development_plan: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const staffDevelopmentPlanDetailsReducer = (
  state = {
    loading: false,
    staff_development_plan: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_DEVELOPMENT_PLAN_DETAILS_REQUEST:
      return {
        loading: true,
        staff_development_plan: state.staff_development_plan,
        success: false,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_DETAILS_SUCCESS:
      return {
        loading: false,
        staff_development_plan: action.payload,
        success: true,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_DETAILS_FAIL:
      return {
        loading: false,
        staff_development_plan: state.staff_development_plan,
        success: false,
        error: action.payload,
      };

    case STAFF_DEVELOPMENT_PLAN_DETAILS_RESET:
      return {
        loading: false,
        staff_development_plan: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const staffDevelopmentPlanUpdateReducer = (
  state = {
    loading: false,
    staff_development_plan: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_DEVELOPMENT_PLAN_UPDATE_REQUEST:
      return {
        loading: true,
        staff_development_plan: state.staff_development_plan,
        success: false,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_UPDATE_SUCCESS:
      return {
        loading: false,
        staff_development_plan: action.payload,
        success: true,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_UPDATE_FAIL:
      return {
        loading: false,
        staff_development_plan: state.staff_development_plan,
        success: false,
        error: action.payload,
      };

    case STAFF_DEVELOPMENT_PLAN_UPDATE_RESET:
      return {
        loading: false,
        staff_development_plan: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const staffDevelopmentPlanDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_DEVELOPMENT_PLAN_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case STAFF_DEVELOPMENT_PLAN_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case STAFF_DEVELOPMENT_PLAN_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
