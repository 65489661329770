// Constant imports
import {
  ENGAGEMENT_PERMISSION_LIST_REQUEST,
  ENGAGEMENT_PERMISSION_LIST_SUCCESS,
  ENGAGEMENT_PERMISSION_LIST_FAIL,
  ENGAGEMENT_PERMISSION_LIST_RESET,
  ENGAGEMENT_PERMISSION_CREATE_REQUEST,
  ENGAGEMENT_PERMISSION_CREATE_SUCCESS,
  ENGAGEMENT_PERMISSION_CREATE_FAIL,
  ENGAGEMENT_PERMISSION_CREATE_RESET,
  ENGAGEMENT_PERMISSION_DETAILS_REQUEST,
  ENGAGEMENT_PERMISSION_DETAILS_SUCCESS,
  ENGAGEMENT_PERMISSION_DETAILS_FAIL,
  ENGAGEMENT_PERMISSION_DETAILS_RESET,
  ENGAGEMENT_PERMISSION_UPDATE_REQUEST,
  ENGAGEMENT_PERMISSION_UPDATE_SUCCESS,
  ENGAGEMENT_PERMISSION_UPDATE_FAIL,
  ENGAGEMENT_PERMISSION_UPDATE_RESET,
  ENGAGEMENT_PERMISSION_DELETE_REQUEST,
  ENGAGEMENT_PERMISSION_DELETE_SUCCESS,
  ENGAGEMENT_PERMISSION_DELETE_FAIL,
  ENGAGEMENT_PERMISSION_DELETE_RESET,
} from "../constants/engagementPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementPermissions(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementPermissionsAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_PERMISSION_LIST_RESET });
};

// Create
export const createEngagementPermissionAction =
  (engagement_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementPermission(
        token,
        engagement_permission
      );

      dispatch({
        type: ENGAGEMENT_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementPermissionAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_PERMISSION_CREATE_RESET });
};

// List details
export const listEngagementPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementPermission(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateEngagementPermissionAction =
  (id, engagement_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementPermission(
        id,
        token,
        engagement_permission
      );

      dispatch({
        type: ENGAGEMENT_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementPermissionAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteEngagementPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementPermission(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementPermissionAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_PERMISSION_DELETE_RESET });
};
