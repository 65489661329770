// Constant imports
import {
  ENGAGEMENT_MILESTONE_LIST_REQUEST,
  ENGAGEMENT_MILESTONE_LIST_SUCCESS,
  ENGAGEMENT_MILESTONE_LIST_FAIL,
  ENGAGEMENT_MILESTONE_LIST_RESET,
  ENGAGEMENT_MILESTONE_CREATE_REQUEST,
  ENGAGEMENT_MILESTONE_CREATE_SUCCESS,
  ENGAGEMENT_MILESTONE_CREATE_FAIL,
  ENGAGEMENT_MILESTONE_CREATE_RESET,
  ENGAGEMENT_MILESTONE_DETAILS_REQUEST,
  ENGAGEMENT_MILESTONE_DETAILS_SUCCESS,
  ENGAGEMENT_MILESTONE_DETAILS_FAIL,
  ENGAGEMENT_MILESTONE_DETAILS_RESET,
  ENGAGEMENT_MILESTONE_UPDATE_REQUEST,
  ENGAGEMENT_MILESTONE_UPDATE_SUCCESS,
  ENGAGEMENT_MILESTONE_UPDATE_FAIL,
  ENGAGEMENT_MILESTONE_UPDATE_RESET,
  ENGAGEMENT_MILESTONE_DELETE_REQUEST,
  ENGAGEMENT_MILESTONE_DELETE_SUCCESS,
  ENGAGEMENT_MILESTONE_DELETE_FAIL,
  ENGAGEMENT_MILESTONE_DELETE_RESET,
} from "../constants/engagementMilestoneConstants";

// List all
export const engagementMilestoneListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_milestones: [],
    numberOfMilestonesCompleted: 0,
    numberOfMilestonesInvoiced: 0,
    numberOfMilestonesNotCompleted: 0,
    numberOfMilestonesPaid: 0,
    totalValueOfMilestones: 0,
    totalValueOfMilestonesQuote: 0,
    valueOfMilestonesCompleted: 0,
    valueOfMilestonesInvoiced: 0,
    valueOfMilestonesNotCompleted: 0,
    valueOfMilestonesPaid: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_MILESTONE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_MILESTONE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_milestones: action.payload.engagement_milestones,
        numberOfMilestonesCompleted: action.payload.numberOfMilestonesCompleted,
        numberOfMilestonesInvoiced: action.payload.numberOfMilestonesInvoiced,
        numberOfMilestonesNotCompleted:
          action.payload.numberOfMilestonesNotCompleted,
        numberOfMilestonesPaid: action.payload.numberOfMilestonesPaid,
        totalValueOfMilestones: action.payload.totalValueOfMilestones,
        totalValueOfMilestonesQuote: action.payload.totalValueOfMilestonesQuote,
        valueOfMilestonesCompleted: action.payload.valueOfMilestonesCompleted,
        valueOfMilestonesInvoiced: action.payload.valueOfMilestonesInvoiced,
        valueOfMilestonesNotCompleted:
          action.payload.valueOfMilestonesNotCompleted,
        valueOfMilestonesPaid: action.payload.valueOfMilestonesPaid,
        success: true,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_milestones: [],
        numberOfMilestonesCompleted: 0,
        numberOfMilestonesInvoiced: 0,
        numberOfMilestonesNotCompleted: 0,
        numberOfMilestonesPaid: 0,
        totalValueOfMilestones: 0,
        totalValueOfMilestonesQuote: 0,
        valueOfMilestonesCompleted: 0,
        valueOfMilestonesInvoiced: 0,
        valueOfMilestonesNotCompleted: 0,
        valueOfMilestonesPaid: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_MILESTONE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_milestones: [],
        numberOfMilestonesCompleted: 0,
        numberOfMilestonesInvoiced: 0,
        numberOfMilestonesNotCompleted: 0,
        numberOfMilestonesPaid: 0,
        totalValueOfMilestones: 0,
        totalValueOfMilestonesQuote: 0,
        valueOfMilestonesCompleted: 0,
        valueOfMilestonesInvoiced: 0,
        valueOfMilestonesNotCompleted: 0,
        valueOfMilestonesPaid: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementMilestoneCreateReducer = (
  state = {
    loading: false,
    engagement_milestone: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_MILESTONE_CREATE_REQUEST:
      return {
        loading: true,
        engagement_milestone: state.engagement_milestone,
        success: false,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_milestone: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_CREATE_FAIL:
      return {
        loading: false,
        engagement_milestone: state.engagement_milestone,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_MILESTONE_CREATE_RESET:
      return {
        loading: false,
        engagement_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementMilestoneDetailsReducer = (
  state = {
    loading: false,
    engagement_milestone: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_MILESTONE_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_milestone: state.engagement_milestone,
        success: false,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_milestone: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_DETAILS_FAIL:
      return {
        loading: false,
        engagement_milestone: state.engagement_milestone,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_MILESTONE_DETAILS_RESET:
      return {
        loading: false,
        engagement_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementMilestoneUpdateReducer = (
  state = {
    loading: false,
    engagement_milestone: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_MILESTONE_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_milestone: state.engagement_milestone,
        success: false,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_milestone: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_UPDATE_FAIL:
      return {
        loading: false,
        engagement_milestone: state.engagement_milestone,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_MILESTONE_UPDATE_RESET:
      return {
        loading: false,
        engagement_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementMilestoneDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_MILESTONE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_MILESTONE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_MILESTONE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
