// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function ClientProposalAverageMarginCard({ fill, clientList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Proposal margin</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Average gross margin percentage of proposals closed with the
            selected client(s). These are proposals that have been won, lost, or
            cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {clientList.averageMarginPercentageOfProposalsWon >=
                  clientList.averageMarginPercentageOfProposalsLost &&
                clientList.averageMarginPercentageOfProposalsWon >=
                  clientList.averageMarginPercentageOfProposalsCancelled ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      On average, the profitability of successful proposals is
                      better than unsuccessful ones with the selected client(s).
                      Clients may associate a higher fee with greater service
                      quality. Consider raising your prices.
                    </div>
                  </div>
                ) : clientList.averageMarginPercentageOfProposalsWon >=
                    clientList.averageMarginPercentageOfProposalsLost &&
                  clientList.averageMarginPercentageOfProposalsWon <
                    clientList.averageMarginPercentageOfProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      The margin on successful proposals is lower than cancelled
                      opportunities with the selected client(s). If
                      organisations are cancelling initiatives due to
                      affordability concerns, you may have an opportunity to
                      develop solutions targeted at price-sensitive clients.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      On average, the profitability of proposals won is lower
                      than proposals lost with the selected client(s). You may
                      need to improve your value proposition, or adjust the
                      pricing to match client expectations.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: clientList.averageMarginPercentageOfProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: clientList.averageMarginPercentageOfProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: clientList.averageMarginPercentageOfProposalsCancelled,
        },
      ]}
      fill={fill}
      noData={
        !clientList.totalValueOfProposalsCancelled &&
        !clientList.totalValueOfProposalsLost &&
        !clientList.totalValueOfProposalsWon
      }
      title="Proposal margin"
      units="Percentage"
    />
  );
}

export default ClientProposalAverageMarginCard;
