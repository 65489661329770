// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import { Button } from "@blueprintjs/core";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";

// Component imports
import { AppToaster } from "../components/AppToaster";

// Utility imports
import generateUUID from "../utilities/generateUUID";

// Redux action imports
// Organisation
import { checkSubscriptionAction } from "../actions/organisationActions";

// Text
import {
  extractDataFromFileTextAction,
  resetExtractDataFromFileTextAction,
} from "../actions/textActions";

// Main function
function GenerateFromFileButton({
  disabled,
  filename,
  fill,
  icon,
  minimal,
  noIcon,
  objectDescription,
  objectType,
  onSuccess,
  outlined,
  small,
  text,
}) {
  // Extract id
  const [id, setId] = useState(null);

  useEffect(() => {
    setId(generateUUID());
  }, []);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  const checkSubscription = useSelector((state) => state.checkSubscription);

  // Text
  const textExtractDataFromFile = useSelector(
    (state) => state.textExtractDataFromFile
  );

  // Check AI credits
  useEffect(() => {
    if (activeOrganisation.id) {
      dispatch(checkSubscriptionAction(activeOrganisation.id));
    }
  }, [activeOrganisation.id, dispatch]);

  // Websocket
  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (id) {
        resolve(`${BACKEND_WS_URL}/api/v1/data/extract/${id}/`);
      }
    });
  }, [id]);

  const { lastJsonMessage, readyState } = useWebSocket(getSocketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (lastJsonMessage !== null) {
      onSuccess && onSuccess(JSON.parse(lastJsonMessage.message));

      setLoading(false);

      dispatch(resetExtractDataFromFileTextAction());
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  // Extract
  const [loading, setLoading] = useState(false);

  const extract = () => {
    // Prepare data
    const context_data = new FormData();

    context_data.append("extract_id", id);

    context_data.append("filename", filename);

    context_data.append("object_description", objectDescription);

    context_data.append("object_type", objectType);

    // Dispatch action
    dispatch(
      extractDataFromFileTextAction(activeOrganisation.id, context_data)
    );
  };

  // Show error message
  useEffect(() => {
    if (textExtractDataFromFile.error) {
      AppToaster.show({
        icon: "warning-sign",
        intent: "danger",
        message:
          "Error generating content. Please wait a moment and try again.",
      });
    }
  }, [textExtractDataFromFile]);

  // JSX UI code
  return (
    <Button
      disabled={
        connectionStatus !== "Open" ||
        disabled ||
        !checkSubscription.subscription.has_ai_credits
      }
      fill={fill}
      icon={noIcon ? undefined : icon ? icon : "predictive-analysis"}
      intent="primary"
      loading={loading}
      minimal={minimal}
      onClick={() => {
        setLoading(true);

        AppToaster.show({
          icon: "updated",
          intent: "primary",
          message: "Generating content. This will take a few seconds.",
        });

        extract();
      }}
      small={small}
      outlined={outlined}
      text={text ? text : "Generate"}
    />
  );
}

export default GenerateFromFileButton;
