// Library imports
import { Button, Classes, Dialog } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function HelpDialog({ children, height, isOpen, setIsOpen, title, width }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div>
      <Dialog
        className={!lightMode ? Classes.DARK : undefined}
        icon="help"
        isCloseButtonShown
        isOpen={isOpen}
        lazy
        onClose={() => {
          setIsOpen(false);
        }}
        style={{
          height: height,
          width: width,
        }}
        title={title}
      >
        <div className={Classes.DIALOG_BODY}>{children}</div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              className={Classes.DIALOG_CLOSE_BUTTON}
              onClick={() => {
                setIsOpen(false);
              }}
              text="Close"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default HelpDialog;
