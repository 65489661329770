// Constant imports
import {
  PROPOSAL_SERVICE_LIST_REQUEST,
  PROPOSAL_SERVICE_LIST_SUCCESS,
  PROPOSAL_SERVICE_LIST_FAIL,
  PROPOSAL_SERVICE_LIST_RESET,
  PROPOSAL_SERVICE_CREATE_REQUEST,
  PROPOSAL_SERVICE_CREATE_SUCCESS,
  PROPOSAL_SERVICE_CREATE_FAIL,
  PROPOSAL_SERVICE_CREATE_RESET,
  PROPOSAL_SERVICE_DETAILS_REQUEST,
  PROPOSAL_SERVICE_DETAILS_SUCCESS,
  PROPOSAL_SERVICE_DETAILS_FAIL,
  PROPOSAL_SERVICE_DETAILS_RESET,
  PROPOSAL_SERVICE_UPDATE_REQUEST,
  PROPOSAL_SERVICE_UPDATE_SUCCESS,
  PROPOSAL_SERVICE_UPDATE_FAIL,
  PROPOSAL_SERVICE_UPDATE_RESET,
  PROPOSAL_SERVICE_DELETE_REQUEST,
  PROPOSAL_SERVICE_DELETE_SUCCESS,
  PROPOSAL_SERVICE_DELETE_FAIL,
  PROPOSAL_SERVICE_DELETE_RESET,
} from "../constants/proposalServiceConstants";

// List all
export const proposalServiceListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_services: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_SERVICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_SERVICE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_services: action.payload.proposal_services,
        success: true,
        error: "",
      };

    case PROPOSAL_SERVICE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_services: [],
        success: false,
        error: action.payload,
      };

    case PROPOSAL_SERVICE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_services: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalServiceCreateReducer = (
  state = { loading: false, proposal_service: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_SERVICE_CREATE_REQUEST:
      return {
        loading: true,
        proposal_service: state.proposal_service,
        success: false,
        error: "",
      };

    case PROPOSAL_SERVICE_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_service: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_SERVICE_CREATE_FAIL:
      return {
        loading: false,
        proposal_service: state.proposal_service,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_SERVICE_CREATE_RESET:
      return {
        loading: false,
        proposal_service: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalServiceDetailsReducer = (
  state = { loading: false, proposal_service: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_SERVICE_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_service: state.proposal_service,
        success: false,
        error: "",
      };

    case PROPOSAL_SERVICE_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_service: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_SERVICE_DETAILS_FAIL:
      return {
        loading: false,
        proposal_service: state.proposal_service,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_SERVICE_DETAILS_RESET:
      return {
        loading: false,
        proposal_service: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalServiceUpdateReducer = (
  state = { loading: false, proposal_service: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_SERVICE_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_service: state.proposal_service,
        success: false,
        error: "",
      };

    case PROPOSAL_SERVICE_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_service: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_SERVICE_UPDATE_FAIL:
      return {
        loading: false,
        proposal_service: state.proposal_service,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_SERVICE_UPDATE_RESET:
      return {
        loading: false,
        proposal_service: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalServiceDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_SERVICE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_SERVICE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_SERVICE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_SERVICE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
