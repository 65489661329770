// Constant imports
import {
  INVOICE_PERMISSION_LIST_REQUEST,
  INVOICE_PERMISSION_LIST_SUCCESS,
  INVOICE_PERMISSION_LIST_FAIL,
  INVOICE_PERMISSION_LIST_RESET, //new
  INVOICE_PERMISSION_CREATE_REQUEST,
  INVOICE_PERMISSION_CREATE_SUCCESS,
  INVOICE_PERMISSION_CREATE_FAIL,
  INVOICE_PERMISSION_CREATE_RESET,
  INVOICE_PERMISSION_DETAILS_REQUEST,
  INVOICE_PERMISSION_DETAILS_SUCCESS,
  INVOICE_PERMISSION_DETAILS_FAIL,
  INVOICE_PERMISSION_DETAILS_RESET, //new
  INVOICE_PERMISSION_UPDATE_REQUEST,
  INVOICE_PERMISSION_UPDATE_SUCCESS,
  INVOICE_PERMISSION_UPDATE_FAIL,
  INVOICE_PERMISSION_UPDATE_RESET,
  INVOICE_PERMISSION_DELETE_REQUEST,
  INVOICE_PERMISSION_DELETE_SUCCESS,
  INVOICE_PERMISSION_DELETE_FAIL,
  INVOICE_PERMISSION_DELETE_RESET,
} from "../constants/invoicePermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInvoicePermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoicePermissions(
        params,
        token
      );

      dispatch({
        type: INVOICE_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          invoice_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INVOICE_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInvoicePermissionsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_PERMISSION_LIST_RESET });
};

// Create
export const createInvoicePermissionAction =
  (invoice_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createInvoicePermission(
        token,
        invoice_permission
      );

      dispatch({
        type: INVOICE_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateInvoicePermissionAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_PERMISSION_CREATE_RESET });
};

// List details
export const listInvoicePermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoicePermission(id, token);

      dispatch({
        type: INVOICE_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInvoicePermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: INVOICE_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateInvoicePermissionAction =
  (id, invoice_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInvoicePermission(
        id,
        token,
        invoice_permission
      );

      dispatch({
        type: INVOICE_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInvoicePermissionAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteInvoicePermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteInvoicePermission(
        id,
        token
      );

      dispatch({
        type: INVOICE_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteInvoicePermissionAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_PERMISSION_DELETE_RESET });
};
