// Library imports
import { Classes, NonIdealState } from "@blueprintjs/core";
import React from "react";

// Main function
function TabSectionText({ loading, value }) {
  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : value ? (
        <span style={{ whiteSpace: "pre-wrap" }}>{value}</span>
      ) : (
        <div
          style={{
            padding: "0 0 10px 0",
          }}
        >
          <NonIdealState icon="search" title="No information found" />
        </div>
      )}
    </div>
  );
}

export default TabSectionText;
