// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  CLIENT_LIST_REQUEST,
  CLIENT_LIST_SUCCESS,
  CLIENT_LIST_FAIL,
  CLIENT_LIST_RESET,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  CLIENT_CREATE_RESET,
  CLIENT_DETAILS_REQUEST,
  CLIENT_DETAILS_SUCCESS,
  CLIENT_DETAILS_FAIL,
  CLIENT_DETAILS_RESET,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_UPDATE_RESET,
  CLIENT_DELETE_REQUEST,
  CLIENT_DELETE_SUCCESS,
  CLIENT_DELETE_FAIL,
  CLIENT_DELETE_RESET,
  CLIENT_GET_INTEL_REQUEST,
  CLIENT_GET_INTEL_SUCCESS,
  CLIENT_GET_INTEL_FAIL,
  CLIENT_GET_INTEL_RESET,
  CLIENT_DOWNLOAD_REQUEST,
  CLIENT_DOWNLOAD_SUCCESS,
  CLIENT_DOWNLOAD_FAIL,
  CLIENT_DOWNLOAD_RESET,
  CLIENT_UPLOAD_BEGIN,
  CLIENT_UPLOAD_REQUEST,
  CLIENT_UPLOAD_SUCCESS,
  CLIENT_UPLOAD_FAIL,
  CLIENT_UPLOAD_END,
  CLIENT_UPLOAD_RESET,
} from "../constants/clientConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listClientsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClients(params, token);

    dispatch({
      type: CLIENT_LIST_SUCCESS,

      payload: {
        averageMarginPercentageOfProposalsCancelled:
          data.average_margin_percentage_of_proposals_cancelled.result,
        averageMarginPercentageOfProposalsLost:
          data.average_margin_percentage_of_proposals_lost.result,
        averageMarginPercentageOfProposalsWon:
          data.average_margin_percentage_of_proposals_won.result,
        totalEngagementMargin: data.total_engagement_margin.result,
        totalEngagementRevenue: data.total_engagement_revenue.result,
        totalValueOfProposalsCancelled:
          data.total_value_of_proposals_cancelled.result,
        totalValueOfProposalsInProgress:
          data.total_value_of_proposals_in_progress.result,
        totalValueOfProposalsLost: data.total_value_of_proposals_lost.result,
        totalValueOfProposalsQualifying:
          data.total_value_of_proposals_qualifying.result,
        totalValueOfProposalsSubmitted:
          data.total_value_of_proposals_submitted.result,
        totalValueOfProposalsWon: data.total_value_of_proposals_won.result,
        count: data.count,
        clients: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: CLIENT_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListClientsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_LIST_RESET });
};

// Create
export const createClientAction = (client) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createClient(token, client);

    dispatch({
      type: CLIENT_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateClientAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_CREATE_RESET });
};

// List details
export const listClientDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClient(id, token);

    dispatch({
      type: CLIENT_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListClientDetailsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_DETAILS_RESET });
};

// Update
export const updateClientAction =
  (id, client) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateClient(id, token, client);

      dispatch({
        type: CLIENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CLIENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateClientAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_UPDATE_RESET });
};

// Delete
export const deleteClientAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteClient(id, token);

    dispatch({
      type: CLIENT_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteClientAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_DELETE_RESET });
};

// Get intel
export const getIntelClientAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLIENT_GET_INTEL_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getClientIntel(id, token);

    dispatch({
      type: CLIENT_GET_INTEL_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CLIENT_GET_INTEL_FAIL,

      payload: error,
    });
  }
};

// Reset get intel
export const resetGetIntelClientAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_GET_INTEL_RESET });
};

// Download
export const downloadClientsAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: CLIENT_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var clients = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getClients(
          { page: page, organisation: organisation },
          token
        );

        clients = clients.concat(data.results);
      }

      dispatch({
        type: CLIENT_DOWNLOAD_SUCCESS,

        payload: {
          clients: clients,
        },
      });
    } catch (error) {
      dispatch({
        type: CLIENT_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadClientsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_DOWNLOAD_RESET });
};

// Upload
export const uploadClientsAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: CLIENT_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: CLIENT_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteClient(records[recordNum].id, token);
        } else if (records[recordNum].id) {
          await cortixDataService.updateClient(records[recordNum].id, token, {
            about: records[recordNum].about,
            address: records[recordNum].address,
            name: records[recordNum].name,
            organisation: id,
            website: records[recordNum].website,
          });
        } else {
          await cortixDataService.createClient(token, {
            about: records[recordNum].about,
            address: records[recordNum].address,
            name: records[recordNum].name,
            organisation: id,
            website: records[recordNum].website,
          });
        }

        dispatch({
          type: CLIENT_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: CLIENT_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: CLIENT_UPLOAD_END });
  };

// Reset upload
export const resetUploadClientsAction = () => async (dispatch) => {
  dispatch({ type: CLIENT_UPLOAD_RESET });
};
