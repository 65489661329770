// Constant imports
import {
  SERVICE_PERMISSION_LIST_REQUEST,
  SERVICE_PERMISSION_LIST_SUCCESS,
  SERVICE_PERMISSION_LIST_FAIL,
  SERVICE_PERMISSION_LIST_RESET,
  SERVICE_PERMISSION_CREATE_REQUEST,
  SERVICE_PERMISSION_CREATE_SUCCESS,
  SERVICE_PERMISSION_CREATE_FAIL,
  SERVICE_PERMISSION_CREATE_RESET,
  SERVICE_PERMISSION_DETAILS_REQUEST,
  SERVICE_PERMISSION_DETAILS_SUCCESS,
  SERVICE_PERMISSION_DETAILS_FAIL,
  SERVICE_PERMISSION_DETAILS_RESET,
  SERVICE_PERMISSION_UPDATE_REQUEST,
  SERVICE_PERMISSION_UPDATE_SUCCESS,
  SERVICE_PERMISSION_UPDATE_FAIL,
  SERVICE_PERMISSION_UPDATE_RESET,
  SERVICE_PERMISSION_DELETE_REQUEST,
  SERVICE_PERMISSION_DELETE_SUCCESS,
  SERVICE_PERMISSION_DELETE_FAIL,
  SERVICE_PERMISSION_DELETE_RESET,
} from "../constants/servicePermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listServicePermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getServicePermissions(
        params,
        token
      );

      dispatch({
        type: SERVICE_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          service_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: SERVICE_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListServicePermissionsAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_PERMISSION_LIST_RESET });
};

// Create
export const createServicePermissionAction =
  (service_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createServicePermission(
        token,
        service_permission
      );

      dispatch({
        type: SERVICE_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateServicePermissionAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_PERMISSION_CREATE_RESET });
};

// List details
export const listServicePermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getServicePermission(id, token);

      dispatch({
        type: SERVICE_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListServicePermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: SERVICE_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateServicePermissionAction =
  (id, service_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateServicePermission(
        id,
        token,
        service_permission
      );

      dispatch({
        type: SERVICE_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateServicePermissionAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteServicePermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SERVICE_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteServicePermission(
        id,
        token
      );

      dispatch({
        type: SERVICE_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SERVICE_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteServicePermissionAction = () => async (dispatch) => {
  dispatch({ type: SERVICE_PERMISSION_DELETE_RESET });
};
