// Constant imports
import {
  INVOICE_EXPENSE_LIST_REQUEST,
  INVOICE_EXPENSE_LIST_SUCCESS,
  INVOICE_EXPENSE_LIST_FAIL,
  INVOICE_EXPENSE_LIST_RESET,
  INVOICE_EXPENSE_DETAILS_REQUEST,
  INVOICE_EXPENSE_DETAILS_SUCCESS,
  INVOICE_EXPENSE_DETAILS_FAIL,
  INVOICE_EXPENSE_DETAILS_RESET,
  INVOICE_EXPENSE_UPDATE_REQUEST,
  INVOICE_EXPENSE_UPDATE_SUCCESS,
  INVOICE_EXPENSE_UPDATE_FAIL,
  INVOICE_EXPENSE_UPDATE_RESET,
  INVOICE_EXPENSE_DELETE_REQUEST,
  INVOICE_EXPENSE_DELETE_SUCCESS,
  INVOICE_EXPENSE_DELETE_FAIL,
  INVOICE_EXPENSE_DELETE_RESET,
} from "../constants/invoiceExpenseConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInvoiceExpensesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_EXPENSE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceExpenses(
        params,
        token
      );

      dispatch({
        type: INVOICE_EXPENSE_LIST_SUCCESS,

        payload: {
          totalNet: data.total_net.result,
          totalNetQuote: data.total_net_quote.result,
          totalTax: data.total_tax.result,
          totalValue: data.total_value.result,
          count: data.count,
          invoice_expenses: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INVOICE_EXPENSE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInvoiceExpensesAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_EXPENSE_LIST_RESET });
};

// List details
export const listInvoiceExpenseDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_EXPENSE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceExpense(id, token);

      dispatch({
        type: INVOICE_EXPENSE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_EXPENSE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInvoiceExpenseDetailsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_EXPENSE_DETAILS_RESET });
};

// Update
export const updateInvoiceExpenseAction =
  (id, invoice_expense) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_EXPENSE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInvoiceExpense(
        id,
        token,
        invoice_expense
      );

      dispatch({
        type: INVOICE_EXPENSE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_EXPENSE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInvoiceExpenseAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_EXPENSE_UPDATE_RESET });
};

// Delete
export const deleteInvoiceExpenseAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_EXPENSE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteInvoiceExpense(id, token);

      dispatch({
        type: INVOICE_EXPENSE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INVOICE_EXPENSE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteInvoiceExpenseAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_EXPENSE_DELETE_RESET });
};
