// Constant imports
import {
  ENGAGEMENT_SERVICE_LIST_REQUEST,
  ENGAGEMENT_SERVICE_LIST_SUCCESS,
  ENGAGEMENT_SERVICE_LIST_FAIL,
  ENGAGEMENT_SERVICE_LIST_RESET,
  ENGAGEMENT_SERVICE_CREATE_REQUEST,
  ENGAGEMENT_SERVICE_CREATE_SUCCESS,
  ENGAGEMENT_SERVICE_CREATE_FAIL,
  ENGAGEMENT_SERVICE_CREATE_RESET,
  ENGAGEMENT_SERVICE_DETAILS_REQUEST,
  ENGAGEMENT_SERVICE_DETAILS_SUCCESS,
  ENGAGEMENT_SERVICE_DETAILS_FAIL,
  ENGAGEMENT_SERVICE_DETAILS_RESET,
  ENGAGEMENT_SERVICE_UPDATE_REQUEST,
  ENGAGEMENT_SERVICE_UPDATE_SUCCESS,
  ENGAGEMENT_SERVICE_UPDATE_FAIL,
  ENGAGEMENT_SERVICE_UPDATE_RESET,
  ENGAGEMENT_SERVICE_DELETE_REQUEST,
  ENGAGEMENT_SERVICE_DELETE_SUCCESS,
  ENGAGEMENT_SERVICE_DELETE_FAIL,
  ENGAGEMENT_SERVICE_DELETE_RESET,
} from "../constants/engagementServiceConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementServicesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementServices(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_services: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementServicesAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_SERVICE_LIST_RESET });
};

// Create
export const createEngagementServiceAction =
  (engagement_service) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementService(
        token,
        engagement_service
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementServiceAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_SERVICE_CREATE_RESET });
};

// List details
export const listEngagementServiceDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementService(id, token);

      dispatch({
        type: ENGAGEMENT_SERVICE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementServiceDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_DETAILS_RESET });
  };

// Update
export const updateEngagementServiceAction =
  (id, engagement_service) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementService(
        id,
        token,
        engagement_service
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementServiceAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_SERVICE_UPDATE_RESET });
};

// Delete
export const deleteEngagementServiceAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementService(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementServiceAction = () => async (dispatch) => {
  dispatch({ type: ENGAGEMENT_SERVICE_DELETE_RESET });
};
