// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function AICreditTransactionTotalCard({
  fill,
  aiCreditTransactionList,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Credits used by period</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            AI credits used in the previous 28 days across all activities,
            divided into 7-day blocks.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "21 - 28 Days",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: aiCreditTransactionList.totalCreditsLast28Days,
        },
        {
          name: "14 - 21 Days",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: aiCreditTransactionList.totalCreditsLast21Days,
        },
        {
          name: "7 - 14 Days",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: aiCreditTransactionList.totalCreditsLast14Days,
        },
        {
          name: "Last 7 Days",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: aiCreditTransactionList.totalCreditsLast7Days,
        },
      ]}
      fill={fill}
      noData={
        !aiCreditTransactionList.totalCreditsLast28Days &&
        !aiCreditTransactionList.totalCreditsLast21Days &&
        !aiCreditTransactionList.totalCreditsLast14Days &&
        !aiCreditTransactionList.totalCreditsLast7Days
      }
      title="Credits used by period"
      units={activeOrganisation.currency}
    />
  );
}

export default AICreditTransactionTotalCard;
