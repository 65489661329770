// Constant imports
import {
  OPENING_PERMISSION_LIST_REQUEST,
  OPENING_PERMISSION_LIST_SUCCESS,
  OPENING_PERMISSION_LIST_FAIL,
  OPENING_PERMISSION_LIST_RESET, //new
  OPENING_PERMISSION_CREATE_REQUEST,
  OPENING_PERMISSION_CREATE_SUCCESS,
  OPENING_PERMISSION_CREATE_FAIL,
  OPENING_PERMISSION_CREATE_RESET,
  OPENING_PERMISSION_DETAILS_REQUEST,
  OPENING_PERMISSION_DETAILS_SUCCESS,
  OPENING_PERMISSION_DETAILS_FAIL,
  OPENING_PERMISSION_DETAILS_RESET, //new
  OPENING_PERMISSION_UPDATE_REQUEST,
  OPENING_PERMISSION_UPDATE_SUCCESS,
  OPENING_PERMISSION_UPDATE_FAIL,
  OPENING_PERMISSION_UPDATE_RESET,
  OPENING_PERMISSION_DELETE_REQUEST,
  OPENING_PERMISSION_DELETE_SUCCESS,
  OPENING_PERMISSION_DELETE_FAIL,
  OPENING_PERMISSION_DELETE_RESET,
} from "../constants/openingPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listOpeningPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningPermissions(
        params,
        token
      );

      dispatch({
        type: OPENING_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          opening_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: OPENING_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListOpeningPermissionsAction = () => async (dispatch) => {
  dispatch({ type: OPENING_PERMISSION_LIST_RESET });
};

// Create
export const createOpeningPermissionAction =
  (opening_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createOpeningPermission(
        token,
        opening_permission
      );

      dispatch({
        type: OPENING_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateOpeningPermissionAction = () => async (dispatch) => {
  dispatch({ type: OPENING_PERMISSION_CREATE_RESET });
};

// List details
export const listOpeningPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getOpeningPermission(id, token);

      dispatch({
        type: OPENING_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListOpeningPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: OPENING_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateOpeningPermissionAction =
  (id, opening_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateOpeningPermission(
        id,
        token,
        opening_permission
      );

      dispatch({
        type: OPENING_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateOpeningPermissionAction = () => async (dispatch) => {
  dispatch({ type: OPENING_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteOpeningPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: OPENING_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteOpeningPermission(
        id,
        token
      );

      dispatch({
        type: OPENING_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OPENING_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteOpeningPermissionAction = () => async (dispatch) => {
  dispatch({ type: OPENING_PERMISSION_DELETE_RESET });
};
