// Library imports
import { Colors, Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionFieldLink from "../components/TabSectionFieldLink";

// Main function
function NameValue({ email, image, invertColor, logo, text, url }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      {(email || text) && (
        <img
          alt={logo ? "logo" : "photo"}
          src={
            image
              ? image
              : logo
              ? "/images/no_logo.png"
              : "/images/no_photo.png"
          }
          style={{
            background: "white",
            border: `1px solid ${
              invertColor
                ? lightMode
                  ? Colors.DARK_GRAY5
                  : Colors.LIGHT_GRAY5
                : lightMode
                ? Colors.LIGHT_GRAY5
                : Colors.DARK_GRAY5
            }`,
            borderRadius: logo ? "10%" : "50%",
            height: "40px",
            margin: "0 10px 0 0",
            objectFit: "contain",
            width: "40px",
          }}
        />
      )}

      {email ? (
        <TabSectionEmailLink email={email} invertColor={invertColor} />
      ) : url ? (
        <TabSectionFieldLink
          invertColor={invertColor}
          name={text}
          sameTab
          url={url}
        />
      ) : (
        <Text ellipsize>{text ? text : "-"}</Text>
      )}
    </div>
  );
}

export default NameValue;
