// Constant imports
import {
  USER_EMAIL_CONFIRM_REQUEST,
  USER_EMAIL_CONFIRM_SUCCESS,
  USER_EMAIL_CONFIRM_FAIL,
  USER_EMAIL_CONFIRM_RESET,
  USER_EMAIL_CONFIRM_RESEND_REQUEST,
  USER_EMAIL_CONFIRM_RESEND_SUCCESS,
  USER_EMAIL_CONFIRM_RESEND_FAIL,
  USER_EMAIL_CONFIRM_RESEND_RESET,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_RESET,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_RESET,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_CONFIRM_REQUEST,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_FAIL,
  USER_PASSWORD_RESET_CONFIRM_RESET,
  USER_PROFILE_DETAILS_REQUEST,
  USER_PROFILE_DETAILS_SUCCESS,
  USER_PROFILE_DETAILS_FAIL,
  USER_PROFILE_DETAILS_RESET,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_RESET,
} from "../constants/userConstants";

// Email confirm
export const userEmailConfirmReducer = (
  state = { loading: false, user: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_EMAIL_CONFIRM_REQUEST:
      return {
        loading: true,
        user: state.user,
        success: false,
        error: "",
      };

    case USER_EMAIL_CONFIRM_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
        success: true,
        error: "",
      };

    case USER_EMAIL_CONFIRM_FAIL:
      return {
        loading: false,
        user: state.user,
        success: false,
        error: action.payload,
      };

    case USER_EMAIL_CONFIRM_RESET:
      return {
        loading: false,
        user: "",
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Email confirm resend
export const userEmailConfirmResendReducer = (
  state = { loading: false, user: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_EMAIL_CONFIRM_RESEND_REQUEST:
      return {
        loading: true,
        user: state.user,
        success: false,
        error: "",
      };

    case USER_EMAIL_CONFIRM_RESEND_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
        success: true,
        error: "",
      };

    case USER_EMAIL_CONFIRM_RESEND_FAIL:
      return {
        loading: false,
        user: state.user,
        success: false,
        error: action.payload,
      };

    case USER_EMAIL_CONFIRM_RESEND_RESET:
      return {
        loading: false,
        user: "",
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Login
export const userLoginReducer = (
  state = { loading: false, token: "", user: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        token: state.token,
        user: state.user,
        success: false,
        error: "",
      };

    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
        success: true,
        error: "",
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        token: state.token,
        user: state.user,
        success: false,
        error: action.payload,
      };

    case USER_LOGOUT:
      return { loading: false, token: "", user: "", success: false, error: "" };

    default:
      return state;
  }
};

// Password change
export const userPasswordChangeReducer = (
  state = { loading: false, data: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_PASSWORD_CHANGE_REQUEST:
      return {
        loading: true,
        data: state.data,
        success: false,
        error: "",
      };

    case USER_PASSWORD_CHANGE_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
        error: "",
      };

    case USER_PASSWORD_CHANGE_FAIL:
      return {
        loading: false,
        data: state.data,
        success: false,
        error: action.payload,
      };

    case USER_PASSWORD_CHANGE_RESET:
      return { loading: false, data: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Password reset
export const userPasswordResetReducer = (
  state = { loading: false, data: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_REQUEST:
      return {
        loading: true,
        data: state.data,
        success: false,
        error: "",
      };

    case USER_PASSWORD_RESET_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
        error: "",
      };

    case USER_PASSWORD_RESET_FAIL:
      return {
        loading: false,
        data: state.data,
        success: false,
        error: action.payload,
      };

    case USER_PASSWORD_RESET_RESET:
      return { loading: false, data: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Password reset confirm
export const userPasswordResetConfirmReducer = (
  state = { loading: false, data: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_PASSWORD_RESET_CONFIRM_REQUEST:
      return {
        loading: true,
        data: state.data,
        success: false,
        error: "",
      };

    case USER_PASSWORD_RESET_CONFIRM_SUCCESS:
      return {
        loading: false,
        data: action.payload,
        success: true,
        error: "",
      };

    case USER_PASSWORD_RESET_CONFIRM_FAIL:
      return {
        loading: false,
        data: state.data,
        success: false,
        error: action.payload,
      };

    case USER_PASSWORD_RESET_CONFIRM_RESET:
      return { loading: false, data: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Signup
export const userSignupReducer = (
  state = { loading: false, user: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_SIGNUP_REQUEST:
      return {
        loading: true,
        user: state.user,
        success: false,
        error: "",
      };

    case USER_SIGNUP_SUCCESS:
      return {
        loading: false,
        user: action.payload.user,
        success: true,
        error: "",
      };

    case USER_SIGNUP_FAIL:
      return {
        loading: false,
        user: state.user,
        success: false,
        error: action.payload,
      };

    case USER_SIGNUP_RESET:
      return {
        loading: false,
        user: "",
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// User profile list details
export const userProfileDetailsReducer = (
  state = { loading: false, user_profile: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_PROFILE_DETAILS_REQUEST:
      return {
        loading: true,
        user_profile: state.user_profile,
        success: false,
        error: "",
      };

    case USER_PROFILE_DETAILS_SUCCESS:
      return {
        loading: false,
        user_profile: action.payload,
        success: true,
        error: "",
      };

    case USER_PROFILE_DETAILS_FAIL:
      return {
        loading: false,
        user_profile: state.user_profile,
        success: false,
        error: action.payload,
      };

    case USER_PROFILE_DETAILS_RESET:
      return { loading: false, user_profile: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Use profile update
export const userProfileUpdateReducer = (
  state = { loading: false, user_profile: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case USER_PROFILE_UPDATE_REQUEST:
      return {
        loading: true,
        user_profile: state.user_profile,
        success: false,
        error: "",
      };

    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        loading: false,
        user_profile: action.payload,
        success: true,
        error: "",
      };

    case USER_PROFILE_UPDATE_FAIL:
      return {
        loading: false,
        user_profile: state.user_profile,
        success: false,
        error: action.payload,
      };

    case USER_PROFILE_UPDATE_RESET:
      return { loading: false, user_profile: {}, success: false, error: "" };

    default:
      return state;
  }
};
