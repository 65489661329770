// Constant imports
import {
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_FAIL,
  LOCATION_LIST_RESET,
  LOCATION_CREATE_REQUEST,
  LOCATION_CREATE_SUCCESS,
  LOCATION_CREATE_FAIL,
  LOCATION_CREATE_RESET,
  LOCATION_DETAILS_REQUEST,
  LOCATION_DETAILS_SUCCESS,
  LOCATION_DETAILS_FAIL,
  LOCATION_DETAILS_RESET,
  LOCATION_UPDATE_REQUEST,
  LOCATION_UPDATE_SUCCESS,
  LOCATION_UPDATE_FAIL,
  LOCATION_UPDATE_RESET,
  LOCATION_DELETE_REQUEST,
  LOCATION_DELETE_SUCCESS,
  LOCATION_DELETE_FAIL,
  LOCATION_DELETE_RESET,
} from "../constants/locationConstants";

// List all
export const locationListReducer = (
  state = {
    loading: false,
    count: 0,
    locations: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LOCATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOCATION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        locations: action.payload.locations,
        success: true,
        error: "",
      };

    case LOCATION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        locations: [],
        success: false,
        error: action.payload,
      };

    case LOCATION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        locations: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const locationCreateReducer = (
  state = { loading: false, location: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LOCATION_CREATE_REQUEST:
      return {
        loading: true,
        location: state.location,
        success: false,
        error: "",
      };

    case LOCATION_CREATE_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        success: true,
        error: "",
      };

    case LOCATION_CREATE_FAIL:
      return {
        loading: false,
        location: state.location,
        success: false,
        error: action.payload,
      };

    case LOCATION_CREATE_RESET:
      return {
        loading: false,
        location: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const locationDetailsReducer = (
  state = { loading: false, location: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LOCATION_DETAILS_REQUEST:
      return {
        loading: true,
        location: state.location,
        success: false,
        error: "",
      };

    case LOCATION_DETAILS_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        success: true,
        error: "",
      };

    case LOCATION_DETAILS_FAIL:
      return {
        loading: false,
        location: state.location,
        success: false,
        error: action.payload,
      };

    case LOCATION_DETAILS_RESET:
      return {
        loading: false,
        location: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const locationUpdateReducer = (
  state = { loading: false, location: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LOCATION_UPDATE_REQUEST:
      return {
        loading: true,
        location: state.location,
        success: false,
        error: "",
      };

    case LOCATION_UPDATE_SUCCESS:
      return {
        loading: false,
        location: action.payload,
        success: true,
        error: "",
      };

    case LOCATION_UPDATE_FAIL:
      return {
        loading: false,
        location: state.location,
        success: false,
        error: action.payload,
      };

    case LOCATION_UPDATE_RESET:
      return {
        loading: false,
        location: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const locationDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LOCATION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case LOCATION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case LOCATION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case LOCATION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
