// Constant imports
import {
  INVOICE_EXPENSE_CREDIT_LIST_REQUEST,
  INVOICE_EXPENSE_CREDIT_LIST_SUCCESS,
  INVOICE_EXPENSE_CREDIT_LIST_FAIL,
  INVOICE_EXPENSE_CREDIT_LIST_RESET,
} from "../constants/invoiceExpenseCreditConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInvoiceExpenseCreditsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INVOICE_EXPENSE_CREDIT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInvoiceExpenseCredits(
        params,
        token
      );

      dispatch({
        type: INVOICE_EXPENSE_CREDIT_LIST_SUCCESS,

        payload: {
          totalNet: data.total_net.result,
          totalNetQuote: data.total_net_quote.result,
          totalTax: data.total_tax.result,
          totalValue: data.total_value.result,
          count: data.count,
          invoice_expense_credits: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INVOICE_EXPENSE_CREDIT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInvoiceExpenseCreditsAction = () => async (dispatch) => {
  dispatch({ type: INVOICE_EXPENSE_CREDIT_LIST_RESET });
};
