// Constant imports
import {
  CLIENT_NEED_LIST_REQUEST,
  CLIENT_NEED_LIST_SUCCESS,
  CLIENT_NEED_LIST_FAIL,
  CLIENT_NEED_LIST_RESET,
  CLIENT_NEED_CREATE_REQUEST,
  CLIENT_NEED_CREATE_SUCCESS,
  CLIENT_NEED_CREATE_FAIL,
  CLIENT_NEED_CREATE_RESET,
  CLIENT_NEED_DETAILS_REQUEST,
  CLIENT_NEED_DETAILS_SUCCESS,
  CLIENT_NEED_DETAILS_FAIL,
  CLIENT_NEED_DETAILS_RESET,
  CLIENT_NEED_UPDATE_REQUEST,
  CLIENT_NEED_UPDATE_SUCCESS,
  CLIENT_NEED_UPDATE_FAIL,
  CLIENT_NEED_UPDATE_RESET,
  CLIENT_NEED_DELETE_REQUEST,
  CLIENT_NEED_DELETE_SUCCESS,
  CLIENT_NEED_DELETE_FAIL,
  CLIENT_NEED_DELETE_RESET,
} from "../constants/clientNeedConstants";

// List all
export const clientNeedListReducer = (
  state = {
    loading: false,
    count: 0,
    client_needs: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_NEED_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_NEED_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        client_needs: action.payload.client_needs,
        success: true,
        error: "",
      };

    case CLIENT_NEED_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        client_needs: [],
        success: false,
        error: action.payload,
      };

    case CLIENT_NEED_LIST_RESET:
      return {
        loading: false,
        count: 0,
        client_needs: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientNeedCreateReducer = (
  state = {
    loading: false,
    client_need: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_NEED_CREATE_REQUEST:
      return {
        loading: true,
        client_need: state.client_need,
        success: false,
        error: "",
      };

    case CLIENT_NEED_CREATE_SUCCESS:
      return {
        loading: false,
        client_need: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_NEED_CREATE_FAIL:
      return {
        loading: false,
        client_need: state.client_need,
        success: false,
        error: action.payload,
      };

    case CLIENT_NEED_CREATE_RESET:
      return {
        loading: false,
        client_need: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const clientNeedDetailsReducer = (
  state = {
    loading: false,
    client_need: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_NEED_DETAILS_REQUEST:
      return {
        loading: true,
        client_need: state.client_need,
        success: false,
        error: "",
      };

    case CLIENT_NEED_DETAILS_SUCCESS:
      return {
        loading: false,
        client_need: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_NEED_DETAILS_FAIL:
      return {
        loading: false,
        client_need: state.client_need,
        success: false,
        error: action.payload,
      };

    case CLIENT_NEED_DETAILS_RESET:
      return {
        loading: false,
        client_need: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const clientNeedUpdateReducer = (
  state = {
    loading: false,
    client_need: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_NEED_UPDATE_REQUEST:
      return {
        loading: true,
        client_need: state.client_need,
        success: false,
        error: "",
      };

    case CLIENT_NEED_UPDATE_SUCCESS:
      return {
        loading: false,
        client_need: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_NEED_UPDATE_FAIL:
      return {
        loading: false,
        client_need: state.client_need,
        success: false,
        error: action.payload,
      };

    case CLIENT_NEED_UPDATE_RESET:
      return {
        loading: false,
        client_need: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const clientNeedDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_NEED_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_NEED_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_NEED_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_NEED_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
