// Library imports
import {
  Button,
  Callout,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  FormGroup,
  H5,
  MenuItem,
  Slider,
  Switch,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import InfoPopup from "./InfoPopup";

// Redux action imports
// Role
import { listRoleDetailsAction } from "../actions/roleActions";

// Main function
function FormDrawerStaffCompetencySearch({
  competencies,
  competencyList,
  competencyQuery,
  roleId,
  setCompetenciesObject,
  setCompetenciesVariable,
  setCompetencyQuery,
  setStaffQueryObject,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Role
  const roleDetails = useSelector((state) => state.roleDetails);

  // Initialise competency list
  const [competenciesInitialised, setCompetenciesInitialised] = useState(
    competencies.length === 0
  );

  useEffect(() => {
    if (competenciesInitialised && roleId) {
      dispatch(listRoleDetailsAction(roleId));
    } else {
      setCompetenciesInitialised(true);
    }
    // eslint-disable-next-line
  }, [roleId]);

  useEffect(() => {
    if (roleDetails.role.competencies) {
      let arr = [];

      roleDetails.role.competencies.forEach((competency) => {
        arr.push({
          competency: competency.competency,
          description: competency.competency_data.description,
          level: competency.level,
          name: competency.competency_data.name,
        });
      });

      setCompetenciesObject((prevState) => {
        return {
          ...prevState,
          [setCompetenciesVariable]: arr,
        };
      });
    }
    // eslint-disable-next-line
  }, [roleDetails]);

  // Add competency
  const [newCompetency, setNewCompetency] = useState({});

  const addCompetency = () => {
    let arr = competencies;

    arr.push(newCompetency);

    setCompetenciesObject((prevState) => {
      return {
        ...prevState,
        [setCompetenciesVariable]: arr,
      };
    });
  };

  // Change competency level
  const changeCompetencyLevel = (competency, value) => {
    let arr = competencies.map((updatedCompetency) => {
      return {
        competency: updatedCompetency.competency,
        description: updatedCompetency.description,
        level:
          updatedCompetency.competency === competency.competency
            ? value
            : updatedCompetency.level,
        name: updatedCompetency.name,
      };
    });

    setCompetenciesObject((prevState) => {
      return {
        ...prevState,
        [setCompetenciesVariable]: arr,
      };
    });
  };

  // Search staff
  const [filterStaff, setFilterStaff] = useState(false);

  useEffect(() => {
    if (filterStaff) {
      let arr = [];

      competencies.forEach((competency) => {
        arr.push({
          id: competency.competency,
          level: competency.level,
        });
      });

      let jsonStr = JSON.stringify(arr);

      let competencySearch = btoa(jsonStr);

      setStaffQueryObject((prevState) => {
        return {
          ...prevState,
          competencies: competencySearch,
        };
      });
    } else {
      setStaffQueryObject((prevState) => {
        return {
          ...prevState,
          competencies: "",
        };
      });
    }
  }, [competencies, filterStaff, setStaffQueryObject]);

  // JSX UI code
  return (
    <div>
      {/* Filter staff */}
      <Callout style={{ marginBottom: "15px", paddingBottom: "5px" }}>
        <Switch
          checked={filterStaff}
          label="Filter staff based on these competencies"
          onChange={() => setFilterStaff(!filterStaff)}
        />
      </Callout>

      {/* Competency sliders */}
      {competencies.map((competency, i) => {
        return (
          <FormGroup
            key={`competency_${i}`}
            label={
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{competency.name}</div>

                <Button
                  icon="trash"
                  minimal
                  onClick={() => {
                    setCompetenciesObject((prevState) => {
                      return {
                        ...prevState,
                        [setCompetenciesVariable]: competencies
                          .filter(
                            (updatedCompetency) =>
                              updatedCompetency.competency !==
                              competency.competency
                          )
                          .map((updatedCompetency) => {
                            return {
                              competency: updatedCompetency.competency,
                              description: updatedCompetency.description,
                              level: updatedCompetency.level,
                              name: updatedCompetency.name,
                            };
                          }),
                      };
                    });
                  }}
                  small
                  style={{ marginLeft: "5px" }}
                />
              </div>
            }
            labelFor={`competency_${i}`}
          >
            <div
              style={{
                padding: `20px 35px 10px 35px`,
              }}
            >
              <Slider
                id={`competency_${i}`}
                intent="primary"
                labelRenderer={(value) => {
                  switch (value) {
                    case 1:
                      return "Beginner";
                    case 5:
                      return "Expert";
                    default:
                      return null;
                  }
                }}
                labelStepSize={1}
                max={5}
                min={1}
                onChange={(value) => changeCompetencyLevel(competency, value)}
                value={competency.level}
              />
            </div>
          </FormGroup>
        );
      })}

      {/* Competency selector */}
      <Divider style={{ margin: "20px 0 20px 0" }} />

      <FormGroup label="Add more competencies" labelFor="competencyField">
        <ControlGroup>
          <Select
            fill
            id="competencyField"
            itemRenderer={(item, { handleClick, handleFocus, modifiers }) => {
              return (
                <MenuItem
                  key={item.id}
                  onClick={handleClick}
                  onFocus={handleFocus}
                  selected={modifiers.active}
                  text={item.name}
                />
              );
            }}
            items={competencyList ? competencyList.competencies : []}
            onItemSelect={(item) => {
              setNewCompetency({
                competency: item.id,
                description: item.description,
                level: 3,
                name: item.name,
              });
            }}
            onQueryChange={(query) => {
              setCompetencyQuery((prevState) => {
                return {
                  ...prevState,
                  searchString: query,
                };
              });
            }}
            query={competencyQuery ? competencyQuery.searchString : undefined}
          >
            <Button
              alignText="left"
              fill
              icon="build"
              loading={competencyList ? competencyList.loading : undefined}
              outlined={true}
              text={
                newCompetency.name ? newCompetency.name : "Select a competency"
              }
              rightIcon="double-caret-vertical"
            />
          </Select>

          {newCompetency.name && (
            <InfoPopup
              content={
                <>
                  <H5>{newCompetency.name}</H5>

                  <Divider
                    style={{
                      background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                      margin: "10px 0 10px 0",
                    }}
                  />

                  <p>
                    {newCompetency.description ? (
                      newCompetency.description
                    ) : (
                      <span className={Classes.TEXT_MUTED}>
                        No description provided.
                      </span>
                    )}
                  </p>
                </>
              }
            />
          )}
        </ControlGroup>
      </FormGroup>

      <Button
        disabled={
          !newCompetency.name ||
          competencies.filter(
            (competency) => competency.name === newCompetency.name
          ).length > 0
        }
        icon="add"
        intent="primary"
        onClick={addCompetency}
        text="Add"
      />
    </div>
  );
}

export default FormDrawerStaffCompetencySearch;
