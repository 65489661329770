// Constant imports
import {
  LEAVE_REQUEST_LIST_REQUEST,
  LEAVE_REQUEST_LIST_SUCCESS,
  LEAVE_REQUEST_LIST_FAIL,
  LEAVE_REQUEST_LIST_RESET,
  LEAVE_REQUEST_CREATE_REQUEST,
  LEAVE_REQUEST_CREATE_SUCCESS,
  LEAVE_REQUEST_CREATE_FAIL,
  LEAVE_REQUEST_CREATE_RESET,
  LEAVE_REQUEST_DETAILS_REQUEST,
  LEAVE_REQUEST_DETAILS_SUCCESS,
  LEAVE_REQUEST_DETAILS_FAIL,
  LEAVE_REQUEST_DETAILS_RESET,
  LEAVE_REQUEST_UPDATE_REQUEST,
  LEAVE_REQUEST_UPDATE_SUCCESS,
  LEAVE_REQUEST_UPDATE_FAIL,
  LEAVE_REQUEST_UPDATE_RESET,
  LEAVE_REQUEST_DELETE_REQUEST,
  LEAVE_REQUEST_DELETE_SUCCESS,
  LEAVE_REQUEST_DELETE_FAIL,
  LEAVE_REQUEST_DELETE_RESET,
  LEAVE_REQUEST_CANCEL_REQUEST,
  LEAVE_REQUEST_CANCEL_SUCCESS,
  LEAVE_REQUEST_CANCEL_FAIL,
  LEAVE_REQUEST_CANCEL_RESET,
} from "../constants/leaveRequestConstants";

// List all
export const leaveRequestListReducer = (
  state = {
    loading: false,
    count: 0,
    leave_requests: [],
    totalMinutes: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_REQUEST_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LEAVE_REQUEST_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        leave_requests: action.payload.leave_requests,
        totalMinutes: action.payload.totalMinutes,
        success: true,
        error: "",
      };

    case LEAVE_REQUEST_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        leave_requests: [],
        totalMinutes: 0,
        success: false,
        error: action.payload,
      };

    case LEAVE_REQUEST_LIST_RESET:
      return {
        loading: false,
        count: 0,
        leave_requests: [],
        totalMinutes: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const leaveRequestCreateReducer = (
  state = {
    loading: false,
    leave_request: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_REQUEST_CREATE_REQUEST:
      return {
        loading: true,
        leave_request: state.leave_request,
        success: false,
        error: "",
      };

    case LEAVE_REQUEST_CREATE_SUCCESS:
      return {
        loading: false,
        leave_request: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_REQUEST_CREATE_FAIL:
      return {
        loading: false,
        leave_request: state.leave_request,
        success: false,
        error: action.payload,
      };

    case LEAVE_REQUEST_CREATE_RESET:
      return {
        loading: false,
        leave_request: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const leaveRequestDetailsReducer = (
  state = {
    loading: false,
    leave_request: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_REQUEST_DETAILS_REQUEST:
      return {
        loading: true,
        leave_request: state.leave_request,
        success: false,
        error: "",
      };

    case LEAVE_REQUEST_DETAILS_SUCCESS:
      return {
        loading: false,
        leave_request: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_REQUEST_DETAILS_FAIL:
      return {
        loading: false,
        leave_request: state.leave_request,
        success: false,
        error: action.payload,
      };

    case LEAVE_REQUEST_DETAILS_RESET:
      return {
        loading: false,
        leave_request: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const leaveRequestUpdateReducer = (
  state = {
    loading: false,
    leave_request: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case LEAVE_REQUEST_UPDATE_REQUEST:
      return {
        loading: true,
        leave_request: state.leave_request,
        success: false,
        error: "",
      };

    case LEAVE_REQUEST_UPDATE_SUCCESS:
      return {
        loading: false,
        leave_request: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_REQUEST_UPDATE_FAIL:
      return {
        loading: false,
        leave_request: state.leave_request,
        success: false,
        error: action.payload,
      };

    case LEAVE_REQUEST_UPDATE_RESET:
      return {
        loading: false,
        leave_request: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const leaveRequestDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LEAVE_REQUEST_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case LEAVE_REQUEST_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case LEAVE_REQUEST_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case LEAVE_REQUEST_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Cancel
export const leaveRequestCancelReducer = (
  state = { loading: false, leave_request: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case LEAVE_REQUEST_CANCEL_REQUEST:
      return {
        loading: true,
        leave_request: state.leave_request,
        success: false,
        error: "",
      };

    case LEAVE_REQUEST_CANCEL_SUCCESS:
      return {
        loading: false,
        leave_request: action.payload,
        success: true,
        error: "",
      };

    case LEAVE_REQUEST_CANCEL_FAIL:
      return {
        loading: false,
        leave_request: state.leave_request,
        success: false,
        error: action.payload,
      };

    case LEAVE_REQUEST_CANCEL_RESET:
      return { loading: false, leave_request: "", success: false, error: "" };

    default:
      return state;
  }
};
