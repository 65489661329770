// Constant imports
import {
  PROPOSAL_QUESTION_LIST_REQUEST,
  PROPOSAL_QUESTION_LIST_SUCCESS,
  PROPOSAL_QUESTION_LIST_FAIL,
  PROPOSAL_QUESTION_LIST_RESET,
  PROPOSAL_QUESTION_CREATE_REQUEST,
  PROPOSAL_QUESTION_CREATE_SUCCESS,
  PROPOSAL_QUESTION_CREATE_FAIL,
  PROPOSAL_QUESTION_CREATE_RESET,
  PROPOSAL_QUESTION_DETAILS_REQUEST,
  PROPOSAL_QUESTION_DETAILS_SUCCESS,
  PROPOSAL_QUESTION_DETAILS_FAIL,
  PROPOSAL_QUESTION_DETAILS_RESET,
  PROPOSAL_QUESTION_UPDATE_REQUEST,
  PROPOSAL_QUESTION_UPDATE_SUCCESS,
  PROPOSAL_QUESTION_UPDATE_FAIL,
  PROPOSAL_QUESTION_UPDATE_RESET,
  PROPOSAL_QUESTION_DELETE_REQUEST,
  PROPOSAL_QUESTION_DELETE_SUCCESS,
  PROPOSAL_QUESTION_DELETE_FAIL,
  PROPOSAL_QUESTION_DELETE_RESET,
} from "../constants/proposalQuestionConstants";

// List all
export const proposalQuestionListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_questions: [],
    numberOfQuestionsNotApplicable: 0,
    numberOfQuestionsResearching: 0,
    numberOfQuestionsResolved: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_QUESTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_questions: action.payload.proposal_questions,
        numberOfQuestionsNotApplicable:
          action.payload.numberOfQuestionsNotApplicable,
        numberOfQuestionsResearching:
          action.payload.numberOfQuestionsResearching,
        numberOfQuestionsResolved: action.payload.numberOfQuestionsResolved,
        success: true,
        error: "",
      };

    case PROPOSAL_QUESTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_questions: [],
        numberOfQuestionsNotApplicable: 0,
        numberOfQuestionsResearching: 0,
        numberOfQuestionsResolved: 0,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_QUESTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_questions: [],
        numberOfQuestionsNotApplicable: 0,
        numberOfQuestionsResearching: 0,
        numberOfQuestionsResolved: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalQuestionCreateReducer = (
  state = { loading: false, proposal_question: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_QUESTION_CREATE_REQUEST:
      return {
        loading: true,
        proposal_question: state.proposal_question,
        success: false,
        error: "",
      };

    case PROPOSAL_QUESTION_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_question: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_QUESTION_CREATE_FAIL:
      return {
        loading: false,
        proposal_question: state.proposal_question,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_QUESTION_CREATE_RESET:
      return {
        loading: false,
        proposal_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalQuestionDetailsReducer = (
  state = { loading: false, proposal_question: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_QUESTION_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_question: state.proposal_question,
        success: false,
        error: "",
      };

    case PROPOSAL_QUESTION_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_question: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_QUESTION_DETAILS_FAIL:
      return {
        loading: false,
        proposal_question: state.proposal_question,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_QUESTION_DETAILS_RESET:
      return {
        loading: false,
        proposal_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalQuestionUpdateReducer = (
  state = { loading: false, proposal_question: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_QUESTION_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_question: state.proposal_question,
        success: false,
        error: "",
      };

    case PROPOSAL_QUESTION_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_question: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_QUESTION_UPDATE_FAIL:
      return {
        loading: false,
        proposal_question: state.proposal_question,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_QUESTION_UPDATE_RESET:
      return {
        loading: false,
        proposal_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalQuestionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_QUESTION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_QUESTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_QUESTION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_QUESTION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
