export const MARKET_INSIGHT_LIST_REQUEST = "MARKET_INSIGHT_LIST_REQUEST";
export const MARKET_INSIGHT_LIST_SUCCESS = "MARKET_INSIGHT_LIST_SUCCESS";
export const MARKET_INSIGHT_LIST_FAIL = "MARKET_INSIGHT_LIST_FAIL";
export const MARKET_INSIGHT_LIST_RESET = "MARKET_INSIGHT_LIST_RESET";

export const MARKET_INSIGHT_CREATE_REQUEST = "MARKET_INSIGHT_CREATE_REQUEST";
export const MARKET_INSIGHT_CREATE_SUCCESS = "MARKET_INSIGHT_CREATE_SUCCESS";
export const MARKET_INSIGHT_CREATE_FAIL = "MARKET_INSIGHT_CREATE_FAIL";
export const MARKET_INSIGHT_CREATE_RESET = "MARKET_INSIGHT_CREATE_RESET";

export const MARKET_INSIGHT_DETAILS_REQUEST = "MARKET_INSIGHT_DETAILS_REQUEST";
export const MARKET_INSIGHT_DETAILS_SUCCESS = "MARKET_INSIGHT_DETAILS_SUCCESS";
export const MARKET_INSIGHT_DETAILS_FAIL = "MARKET_INSIGHT_DETAILS_FAIL";
export const MARKET_INSIGHT_DETAILS_RESET = "MARKET_INSIGHT_DETAILS_RESET";

export const MARKET_INSIGHT_UPDATE_REQUEST = "MARKET_INSIGHT_UPDATE_REQUEST";
export const MARKET_INSIGHT_UPDATE_SUCCESS = "MARKET_INSIGHT_UPDATE_SUCCESS";
export const MARKET_INSIGHT_UPDATE_FAIL = "MARKET_INSIGHT_UPDATE_FAIL";
export const MARKET_INSIGHT_UPDATE_RESET = "MARKET_INSIGHT_UPDATE_RESET";

export const MARKET_INSIGHT_DELETE_REQUEST = "MARKET_INSIGHT_DELETE_REQUEST";
export const MARKET_INSIGHT_DELETE_SUCCESS = "MARKET_INSIGHT_DELETE_SUCCESS";
export const MARKET_INSIGHT_DELETE_FAIL = "MARKET_INSIGHT_DELETE_FAIL";
export const MARKET_INSIGHT_DELETE_RESET = "MARKET_INSIGHT_DELETE_RESET";
