// Constant imports
import {
  AI_CREDIT_TRANSACTION_LIST_REQUEST,
  AI_CREDIT_TRANSACTION_LIST_SUCCESS,
  AI_CREDIT_TRANSACTION_LIST_FAIL,
  AI_CREDIT_TRANSACTION_LIST_RESET,
} from "../constants/aiCreditTransactionConstants";

// List all
export const aiCreditTransactionListReducer = (
  state = {
    loading: false,
    count: 0,
    totalCreditsLast7Days: 0,
    totalCreditsLast14Days: 0,
    totalCreditsLast21Days: 0,
    totalCreditsLast28Days: 0,
    totalCredits: 0,
    totalCreditsAnswer: 0,
    totalCreditsExtract: 0,
    totalCreditsIndex: 0,
    totalCreditsRewrite: 0,
    ai_credit_transactions: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case AI_CREDIT_TRANSACTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AI_CREDIT_TRANSACTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        totalCreditsLast7Days: action.payload.total_credits_last_7_days,
        totalCreditsLast14Days: action.payload.total_credits_last_14_days,
        totalCreditsLast21Days: action.payload.total_credits_last_21_days,
        totalCreditsLast28Days: action.payload.total_credits_last_28_days,
        totalCredits: action.payload.total_credits,
        totalCreditsAnswer: action.payload.total_credits_answer,
        totalCreditsExtract: action.payload.total_credits_extract,
        totalCreditsIndex: action.payload.total_credits_index,
        totalCreditsRewrite: action.payload.total_credits_rewrite,
        ai_credit_transactions: action.payload.ai_credit_transactions,
        success: true,
        error: "",
      };

    case AI_CREDIT_TRANSACTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        totalCreditsLast7Days: 0,
        totalCreditsLast14Days: 0,
        totalCreditsLast21Days: 0,
        totalCreditsLast28Days: 0,
        totalCredits: 0,
        totalCreditsAnswer: 0,
        totalCreditsExtract: 0,
        totalCreditsIndex: 0,
        totalCreditsRewrite: 0,
        ai_credit_transactions: [],
        success: false,
        error: action.payload,
      };

    case AI_CREDIT_TRANSACTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        totalCreditsLast7Days: 0,
        totalCreditsLast14Days: 0,
        totalCreditsLast21Days: 0,
        totalCreditsLast28Days: 0,
        totalCredits: 0,
        totalCreditsAnswer: 0,
        totalCreditsExtract: 0,
        totalCreditsIndex: 0,
        totalCreditsRewrite: 0,
        ai_credit_transactions: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
