// Constant imports
import {
  APPROVAL_RESPONSE_LIST_REQUEST,
  APPROVAL_RESPONSE_LIST_SUCCESS,
  APPROVAL_RESPONSE_LIST_FAIL,
  APPROVAL_RESPONSE_LIST_RESET,
  APPROVAL_RESPONSE_CREATE_REQUEST,
  APPROVAL_RESPONSE_CREATE_SUCCESS,
  APPROVAL_RESPONSE_CREATE_FAIL,
  APPROVAL_RESPONSE_CREATE_RESET,
  APPROVAL_RESPONSE_DETAILS_REQUEST,
  APPROVAL_RESPONSE_DETAILS_SUCCESS,
  APPROVAL_RESPONSE_DETAILS_FAIL,
  APPROVAL_RESPONSE_DETAILS_RESET,
  APPROVAL_RESPONSE_UPDATE_REQUEST,
  APPROVAL_RESPONSE_UPDATE_SUCCESS,
  APPROVAL_RESPONSE_UPDATE_FAIL,
  APPROVAL_RESPONSE_UPDATE_RESET,
  APPROVAL_RESPONSE_DELETE_REQUEST,
  APPROVAL_RESPONSE_DELETE_SUCCESS,
  APPROVAL_RESPONSE_DELETE_FAIL,
  APPROVAL_RESPONSE_DELETE_RESET,
} from "../constants/approvalResponseConstants";

// List all
export const approvalResponseListReducer = (
  state = {
    loading: false,
    count: 0,
    approval_responses: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case APPROVAL_RESPONSE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case APPROVAL_RESPONSE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        approval_responses: action.payload.approval_responses,
        success: true,
        error: "",
      };

    case APPROVAL_RESPONSE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        approval_responses: [],
        success: false,
        error: action.payload,
      };

    case APPROVAL_RESPONSE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        approval_responses: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const approvalResponseCreateReducer = (
  state = { loading: false, approval_response: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_RESPONSE_CREATE_REQUEST:
      return {
        loading: true,
        approval_response: state.approval_response,
        success: false,
        error: "",
      };

    case APPROVAL_RESPONSE_CREATE_SUCCESS:
      return {
        loading: false,
        approval_response: action.payload,
        success: true,
        error: "",
      };

    case APPROVAL_RESPONSE_CREATE_FAIL:
      return {
        loading: false,
        approval_response: state.approval_response,
        success: false,
        error: action.payload,
      };

    case APPROVAL_RESPONSE_CREATE_RESET:
      return {
        loading: false,
        approval_response: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const approvalResponseDetailsReducer = (
  state = { loading: false, approval_response: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_RESPONSE_DETAILS_REQUEST:
      return {
        loading: true,
        approval_response: state.approval_response,
        success: false,
        error: "",
      };

    case APPROVAL_RESPONSE_DETAILS_SUCCESS:
      return {
        loading: false,
        approval_response: action.payload,
        success: true,
        error: "",
      };

    case APPROVAL_RESPONSE_DETAILS_FAIL:
      return {
        loading: false,
        approval_response: state.approval_response,
        success: false,
        error: action.payload,
      };

    case APPROVAL_RESPONSE_DETAILS_RESET:
      return {
        loading: false,
        approval_response: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const approvalResponseUpdateReducer = (
  state = { loading: false, approval_response: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_RESPONSE_UPDATE_REQUEST:
      return {
        loading: true,
        approval_response: state.approval_response,
        success: false,
        error: "",
      };

    case APPROVAL_RESPONSE_UPDATE_SUCCESS:
      return {
        loading: false,
        approval_response: action.payload,
        success: true,
        error: "",
      };

    case APPROVAL_RESPONSE_UPDATE_FAIL:
      return {
        loading: false,
        approval_response: state.approval_response,
        success: false,
        error: action.payload,
      };

    case APPROVAL_RESPONSE_UPDATE_RESET:
      return {
        loading: false,
        approval_response: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const approvalResponseDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case APPROVAL_RESPONSE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case APPROVAL_RESPONSE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case APPROVAL_RESPONSE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case APPROVAL_RESPONSE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
