export const INTERVIEW_QUESTION_LIST_REQUEST =
  "INTERVIEW_QUESTION_LIST_REQUEST";
export const INTERVIEW_QUESTION_LIST_SUCCESS =
  "INTERVIEW_QUESTION_LIST_SUCCESS";
export const INTERVIEW_QUESTION_LIST_FAIL = "INTERVIEW_QUESTION_LIST_FAIL";
export const INTERVIEW_QUESTION_LIST_RESET = "INTERVIEW_QUESTION_LIST_RESET";

export const INTERVIEW_QUESTION_CREATE_REQUEST =
  "INTERVIEW_QUESTION_CREATE_REQUEST";
export const INTERVIEW_QUESTION_CREATE_SUCCESS =
  "INTERVIEW_QUESTION_CREATE_SUCCESS";
export const INTERVIEW_QUESTION_CREATE_FAIL = "INTERVIEW_QUESTION_CREATE_FAIL";
export const INTERVIEW_QUESTION_CREATE_RESET =
  "INTERVIEW_QUESTION_CREATE_RESET";

export const INTERVIEW_QUESTION_DETAILS_REQUEST =
  "INTERVIEW_QUESTION_DETAILS_REQUEST";
export const INTERVIEW_QUESTION_DETAILS_SUCCESS =
  "INTERVIEW_QUESTION_DETAILS_SUCCESS";
export const INTERVIEW_QUESTION_DETAILS_FAIL =
  "INTERVIEW_QUESTION_DETAILS_FAIL";
export const INTERVIEW_QUESTION_DETAILS_RESET =
  "INTERVIEW_QUESTION_DETAILS_RESET";

export const INTERVIEW_QUESTION_UPDATE_REQUEST =
  "INTERVIEW_QUESTION_UPDATE_REQUEST";
export const INTERVIEW_QUESTION_UPDATE_SUCCESS =
  "INTERVIEW_QUESTION_UPDATE_SUCCESS";
export const INTERVIEW_QUESTION_UPDATE_FAIL = "INTERVIEW_QUESTION_UPDATE_FAIL";
export const INTERVIEW_QUESTION_UPDATE_RESET =
  "INTERVIEW_QUESTION_UPDATE_RESET";

export const INTERVIEW_QUESTION_DELETE_REQUEST =
  "INTERVIEW_QUESTION_DELETE_REQUEST";
export const INTERVIEW_QUESTION_DELETE_SUCCESS =
  "INTERVIEW_QUESTION_DELETE_SUCCESS";
export const INTERVIEW_QUESTION_DELETE_FAIL = "INTERVIEW_QUESTION_DELETE_FAIL";
export const INTERVIEW_QUESTION_DELETE_RESET =
  "INTERVIEW_QUESTION_DELETE_RESET";
