// Library imports
import { Colors, Divider, H5, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardTwoBarChart from "../components/DataCardTwoBarChart";

// Main function
function TodoStatusCard({ fill, todoList, showInsights }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardTwoBarChart
      comments={
        <>
          <H5>Actions</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Assigned actions across clients, contacts, proposals and engagements
            that have not yet been marked as completed. These are categorised as
            follows:
          </p>

          <UL>
            <li>Account development: Analyze and grow client accounts.</li>

            <li>
              Relationship management: Identify and nurture relationships with
              key contacts.
            </li>

            <li>
              Proposal management: Qualify, develop and close proposals for new
              business.
            </li>

            <li>
              Engagement delivery: Plan, execute and collect fees for client
              engagements.
            </li>
          </UL>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>TBD</div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Account Development",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: todoList.clientTodosClosed,
          value2: todoList.clientTodosOpen,
        },
        {
          name: "Relationship Management",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: todoList.contactTodosClosed,
          value2: todoList.contactTodosOpen,
        },
        {
          name: "Proposal Management",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: todoList.proposalTodosClosed,
          value2: todoList.proposalTodosOpen,
        },
        {
          name: "Engagement Delivery",
          fill1: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          fill2: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value1: todoList.engagementTodosClosed,
          value2: todoList.engagementTodosOpen,
        },
      ]}
      fill={fill}
      legendData={[
        {
          color: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          type: "square",
          value: "Ready to close",
        },
        {
          color: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          type: "square",
          value: "Working on it",
        },
      ]}
      noData={
        !todoList.clientTodosClosed &&
        !todoList.clientTodosOpen &&
        !todoList.contactTodosClosed &&
        !todoList.contactTodosOpen &&
        !todoList.engagementTodosClosed &&
        !todoList.engagementTodosOpen &&
        !todoList.proposalTodosClosed &&
        !todoList.proposalTodosOpen
      }
      title="Actions"
      units="Number"
    />
  );
}

export default TodoStatusCard;
