// Constant imports
import {
  INVOICE_BOOKING_LIST_REQUEST,
  INVOICE_BOOKING_LIST_SUCCESS,
  INVOICE_BOOKING_LIST_FAIL,
  INVOICE_BOOKING_LIST_RESET,
  INVOICE_BOOKING_DETAILS_REQUEST,
  INVOICE_BOOKING_DETAILS_SUCCESS,
  INVOICE_BOOKING_DETAILS_FAIL,
  INVOICE_BOOKING_DETAILS_RESET,
  INVOICE_BOOKING_UPDATE_REQUEST,
  INVOICE_BOOKING_UPDATE_SUCCESS,
  INVOICE_BOOKING_UPDATE_FAIL,
  INVOICE_BOOKING_UPDATE_RESET,
  INVOICE_BOOKING_DELETE_REQUEST,
  INVOICE_BOOKING_DELETE_SUCCESS,
  INVOICE_BOOKING_DELETE_FAIL,
  INVOICE_BOOKING_DELETE_RESET,
} from "../constants/invoiceBookingConstants";

// List all
export const invoiceBookingListReducer = (
  state = {
    loading: false,
    count: 0,
    invoice_bookings: [],
    totalNet: 0,
    totalNetQuote: 0,
    totalTax: 0,
    totalValue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_BOOKING_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_BOOKING_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoice_bookings: action.payload.invoice_bookings,
        totalNet: action.payload.totalNet,
        totalNetQuote: action.payload.totalNetQuote,
        totalTax: action.payload.totalTax,
        totalValue: action.payload.totalValue,
        success: true,
        error: "",
      };

    case INVOICE_BOOKING_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoice_bookings: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: action.payload,
      };

    case INVOICE_BOOKING_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoice_bookings: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const invoiceBookingDetailsReducer = (
  state = { loading: false, invoice_booking: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_BOOKING_DETAILS_REQUEST:
      return {
        loading: true,
        invoice_booking: state.invoice_booking,
        success: false,
        error: "",
      };

    case INVOICE_BOOKING_DETAILS_SUCCESS:
      return {
        loading: false,
        invoice_booking: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_BOOKING_DETAILS_FAIL:
      return {
        loading: false,
        invoice_booking: state.invoice_booking,
        success: false,
        error: action.payload,
      };

    case INVOICE_BOOKING_DETAILS_RESET:
      return { loading: false, invoice_booking: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const invoiceBookingUpdateReducer = (
  state = { loading: false, invoice_booking: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_BOOKING_UPDATE_REQUEST:
      return {
        loading: true,
        invoice_booking: state.invoice_booking,
        success: false,
        error: "",
      };

    case INVOICE_BOOKING_UPDATE_SUCCESS:
      return {
        loading: false,
        invoice_booking: action.payload,
        success: true,
        error: "",
      };

    case INVOICE_BOOKING_UPDATE_FAIL:
      return {
        loading: false,
        invoice_booking: state.invoice_booking,
        success: false,
        error: action.payload,
      };

    case INVOICE_BOOKING_UPDATE_RESET:
      return { loading: false, invoice_booking: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const invoiceBookingDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INVOICE_BOOKING_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INVOICE_BOOKING_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INVOICE_BOOKING_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INVOICE_BOOKING_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
