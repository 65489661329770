export const NEED_PERMISSION_LIST_REQUEST = "NEED_PERMISSION_LIST_REQUEST";
export const NEED_PERMISSION_LIST_SUCCESS = "NEED_PERMISSION_LIST_SUCCESS";
export const NEED_PERMISSION_LIST_FAIL = "NEED_PERMISSION_LIST_FAIL";
export const NEED_PERMISSION_LIST_RESET = "NEED_PERMISSION_LIST_RESET";

export const NEED_PERMISSION_CREATE_REQUEST = "NEED_PERMISSION_CREATE_REQUEST";
export const NEED_PERMISSION_CREATE_SUCCESS = "NEED_PERMISSION_CREATE_SUCCESS";
export const NEED_PERMISSION_CREATE_FAIL = "NEED_PERMISSION_CREATE_FAIL";
export const NEED_PERMISSION_CREATE_RESET = "NEED_PERMISSION_CREATE_RESET";

export const NEED_PERMISSION_DETAILS_REQUEST =
  "NEED_PERMISSION_DETAILS_REQUEST";
export const NEED_PERMISSION_DETAILS_SUCCESS =
  "NEED_PERMISSION_DETAILS_SUCCESS";
export const NEED_PERMISSION_DETAILS_FAIL = "NEED_PERMISSION_DETAILS_FAIL";
export const NEED_PERMISSION_DETAILS_RESET = "NEED_PERMISSION_DETAILS_RESET";

export const NEED_PERMISSION_UPDATE_REQUEST = "NEED_PERMISSION_UPDATE_REQUEST";
export const NEED_PERMISSION_UPDATE_SUCCESS = "NEED_PERMISSION_UPDATE_SUCCESS";
export const NEED_PERMISSION_UPDATE_FAIL = "NEED_PERMISSION_UPDATE_FAIL";
export const NEED_PERMISSION_UPDATE_RESET = "NEED_PERMISSION_UPDATE_RESET";

export const NEED_PERMISSION_DELETE_REQUEST = "NEED_PERMISSION_DELETE_REQUEST";
export const NEED_PERMISSION_DELETE_SUCCESS = "NEED_PERMISSION_DELETE_SUCCESS";
export const NEED_PERMISSION_DELETE_FAIL = "NEED_PERMISSION_DELETE_FAIL";
export const NEED_PERMISSION_DELETE_RESET = "NEED_PERMISSION_DELETE_RESET";
