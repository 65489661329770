// Constant imports
import {
  PROPOSAL_UPLOAD_LIST_REQUEST,
  PROPOSAL_UPLOAD_LIST_SUCCESS,
  PROPOSAL_UPLOAD_LIST_FAIL,
  PROPOSAL_UPLOAD_LIST_RESET,
  PROPOSAL_UPLOAD_CREATE_REQUEST,
  PROPOSAL_UPLOAD_CREATE_SUCCESS,
  PROPOSAL_UPLOAD_CREATE_FAIL,
  PROPOSAL_UPLOAD_CREATE_RESET,
  PROPOSAL_UPLOAD_DETAILS_REQUEST,
  PROPOSAL_UPLOAD_DETAILS_SUCCESS,
  PROPOSAL_UPLOAD_DETAILS_FAIL,
  PROPOSAL_UPLOAD_DETAILS_RESET,
  PROPOSAL_UPLOAD_UPDATE_REQUEST,
  PROPOSAL_UPLOAD_UPDATE_SUCCESS,
  PROPOSAL_UPLOAD_UPDATE_FAIL,
  PROPOSAL_UPLOAD_UPDATE_RESET,
  PROPOSAL_UPLOAD_DELETE_REQUEST,
  PROPOSAL_UPLOAD_DELETE_SUCCESS,
  PROPOSAL_UPLOAD_DELETE_FAIL,
  PROPOSAL_UPLOAD_DELETE_RESET,
} from "../constants/proposalUploadConstants";

// List all
export const proposalUploadListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_uploads: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_UPLOAD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_UPLOAD_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_uploads: action.payload.proposal_uploads,
        success: true,
        error: "",
      };

    case PROPOSAL_UPLOAD_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_uploads: [],
        success: false,
        error: action.payload,
      };

    case PROPOSAL_UPLOAD_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_uploads: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalUploadCreateReducer = (
  state = { loading: false, proposal_upload: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_UPLOAD_CREATE_REQUEST:
      return {
        loading: true,
        proposal_upload: state.proposal_upload,
        success: false,
        error: "",
      };

    case PROPOSAL_UPLOAD_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_upload: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_UPLOAD_CREATE_FAIL:
      return {
        loading: false,
        proposal_upload: state.proposal_upload,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_UPLOAD_CREATE_RESET:
      return {
        loading: false,
        proposal_upload: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalUploadDetailsReducer = (
  state = { loading: false, proposal_upload: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_UPLOAD_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_upload: state.proposal_upload,
        success: false,
        error: "",
      };

    case PROPOSAL_UPLOAD_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_upload: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_UPLOAD_DETAILS_FAIL:
      return {
        loading: false,
        proposal_upload: state.proposal_upload,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_UPLOAD_DETAILS_RESET:
      return {
        loading: false,
        proposal_upload: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalUploadUpdateReducer = (
  state = { loading: false, proposal_upload: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_UPLOAD_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_upload: state.proposal_upload,
        success: false,
        error: "",
      };

    case PROPOSAL_UPLOAD_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_upload: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_UPLOAD_UPDATE_FAIL:
      return {
        loading: false,
        proposal_upload: state.proposal_upload,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_UPLOAD_UPDATE_RESET:
      return {
        loading: false,
        proposal_upload: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalUploadDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_UPLOAD_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_UPLOAD_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_UPLOAD_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_UPLOAD_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
