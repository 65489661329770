// Library imports
import { Card, Classes, Colors, Text } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import ContactInfo from "./ContactInfo";
import InfoPopup from "./InfoPopup";

// Main function
function ContactCard({
  additionalInfo,
  borderColor,
  data,
  interactive,
  onClick,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <Card
      interactive={interactive}
      onClick={onClick}
      style={{
        alignItems: "center",
        borderTop: `5px solid ${borderColor ? borderColor : Colors.GRAY1}`,
        display: "flex",
        height: "200px",
        margin: " 40px 5px 5px 5px",
        padding: "10px",
        textAlign: "center",
        width: "200px",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img
          alt="profile"
          src={data.profile_photo ? data.profile_photo : "/images/no_photo.png"}
          style={{
            background: "white",
            border: `1px solid ${
              lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
            }`,
            borderRadius: "50%",
            height: "110px",
            margin: "-50px 0 10px 0",
            objectFit: "contain",
            width: "110px",
          }}
        />

        <div style={{ width: "100%" }}>
          <Text ellipsize>{data.name}</Text>

          {data.role && (
            <Text
              className={Classes.TEXT_SMALL}
              ellipsize
              style={{ fontWeight: "bold", marginTop: "5px" }}
            >
              {data.role}
            </Text>
          )}

          {data.function && (
            <Text
              className={Classes.TEXT_SMALL}
              ellipsize
              style={{ marginTop: "5px" }}
            >
              {data.function}
            </Text>
          )}
        </div>

        <div
          style={{
            margin: "10px 0 0 0",
          }}
        >
          <InfoPopup
            content={
              <ContactInfo additionalInfo={additionalInfo} contactData={data} />
            }
          />
        </div>
      </div>
    </Card>
  );
}

export default ContactCard;
