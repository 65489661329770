// Constant imports
import {
  ENGAGEMENT_FEE_LIST_REQUEST,
  ENGAGEMENT_FEE_LIST_SUCCESS,
  ENGAGEMENT_FEE_LIST_FAIL,
  ENGAGEMENT_FEE_LIST_RESET,
  ENGAGEMENT_FEE_DETAILS_REQUEST,
  ENGAGEMENT_FEE_DETAILS_SUCCESS,
  ENGAGEMENT_FEE_DETAILS_FAIL,
  ENGAGEMENT_FEE_DETAILS_RESET,
  ENGAGEMENT_FEE_UPDATE_REQUEST,
  ENGAGEMENT_FEE_UPDATE_SUCCESS,
  ENGAGEMENT_FEE_UPDATE_FAIL,
  ENGAGEMENT_FEE_UPDATE_RESET,
} from "../constants/engagementFeeConstants";

// List all
export const engagementFeeListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_fees: [],
    average_margin_percentage: 0,
    total_expenses: 0,
    total_fees: 0,
    total_fees_quote: 0,
    total_margin: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_FEE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_FEE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_fees: action.payload.engagement_fees,
        average_margin_percentage: action.payload.averageMarginPercentage,
        total_expenses: action.payload.totalExpenses,
        total_fees: action.payload.totalFees,
        total_fees_quote: action.payload.totalFeesQuote,
        total_margin: action.payload.totalMargin,
        success: true,
        error: "",
      };

    case ENGAGEMENT_FEE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_fees: [],
        average_margin_percentage: 0,
        total_expenses: 0,
        total_fees: 0,
        total_fees_quote: 0,
        total_margin: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_FEE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_fees: [],
        average_margin_percentage: 0,
        total_expenses: 0,
        total_fees: 0,
        total_fees_quote: 0,
        total_margin: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementFeeDetailsReducer = (
  state = {
    loading: false,
    engagement_fee: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_FEE_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_fee: state.engagement_fee,
        success: false,
        error: "",
      };

    case ENGAGEMENT_FEE_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_fee: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_FEE_DETAILS_FAIL:
      return {
        loading: false,
        engagement_fee: state.engagement_fee,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_FEE_DETAILS_RESET:
      return {
        loading: false,
        engagement_fee: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementFeeUpdateReducer = (
  state = {
    loading: false,
    engagement_fee: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_FEE_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_fee: state.engagement_fee,
        success: false,
        error: "",
      };

    case ENGAGEMENT_FEE_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_fee: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_FEE_UPDATE_FAIL:
      return {
        loading: false,
        engagement_fee: state.engagement_fee,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_FEE_UPDATE_RESET:
      return {
        loading: false,
        engagement_fee: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
