// Constant imports
import {
  STAFF_LIST_REQUEST,
  STAFF_LIST_SUCCESS,
  STAFF_LIST_FAIL,
  STAFF_LIST_RESET,
  STAFF_DETAILS_REQUEST,
  STAFF_DETAILS_SUCCESS,
  STAFF_DETAILS_FAIL,
  STAFF_DETAILS_RESET,
  STAFF_UPDATE_REQUEST,
  STAFF_UPDATE_SUCCESS,
  STAFF_UPDATE_FAIL,
  STAFF_UPDATE_RESET,
  STAFF_CALCULATE_ROLE_FIT_SCORE_REQUEST,
  STAFF_CALCULATE_ROLE_FIT_SCORE_SUCCESS,
  STAFF_CALCULATE_ROLE_FIT_SCORE_FAIL,
  STAFF_CALCULATE_ROLE_FIT_SCORE_RESET,
  STAFF_GENERATE_UTILISATION_ANALYSIS_REQUEST,
  STAFF_GENERATE_UTILISATION_ANALYSIS_SUCCESS,
  STAFF_GENERATE_UTILISATION_ANALYSIS_FAIL,
  STAFF_GENERATE_UTILISATION_ANALYSIS_RESET,
} from "../constants/staffConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listStaffAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getStaffList(params, token);

    dispatch({
      type: STAFF_LIST_SUCCESS,

      payload: {
        bookingProfile: data.booking_profile,
        count: data.count,
        staff: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: STAFF_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListStaffAction = () => async (dispatch) => {
  dispatch({ type: STAFF_LIST_RESET });
};

// List details
export const listStaffDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getStaff(id, token);

    dispatch({
      type: STAFF_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STAFF_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListStaffDetailsAction = () => async (dispatch) => {
  dispatch({ type: STAFF_DETAILS_RESET });
};

// Update
export const updateStaffAction = (id, staff) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_UPDATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.updateStaff(id, token, staff);

    dispatch({
      type: STAFF_UPDATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STAFF_UPDATE_FAIL,

      payload: error,
    });
  }
};

// Reset update
export const resetUpdateStaffAction = () => async (dispatch) => {
  dispatch({ type: STAFF_UPDATE_RESET });
};

// Calculate role fit score
export const calculateRoleFitScoreAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_CALCULATE_ROLE_FIT_SCORE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.calculateRoleFitScoreStaff(
        id,
        token
      );

      dispatch({
        type: STAFF_CALCULATE_ROLE_FIT_SCORE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_CALCULATE_ROLE_FIT_SCORE_FAIL,

        payload: error,
      });
    }
  };

// Reset calculate role fit score
export const resetCalculateRoleFitScoreAction = () => async (dispatch) => {
  dispatch({ type: STAFF_CALCULATE_ROLE_FIT_SCORE_RESET });
};

// Generate utilisation analysis
export const generateUtilisationAnalysisAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: STAFF_GENERATE_UTILISATION_ANALYSIS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.generateUtilisationAnalysisStaff(
        id,
        token
      );

      dispatch({
        type: STAFF_GENERATE_UTILISATION_ANALYSIS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: STAFF_GENERATE_UTILISATION_ANALYSIS_FAIL,

        payload: error,
      });
    }
  };

// Reset calculate role fit score
export const resetGenerateUtilisationAnalysisAction =
  () => async (dispatch) => {
    dispatch({ type: STAFF_GENERATE_UTILISATION_ANALYSIS_RESET });
  };
