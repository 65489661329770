// Constants import
import { VIDEOS_URL } from "../constants/applicationConstants";

// Library imports
import { Button, Card, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ClientInfo from "../components/ClientInfo";
import ContactInfo from "../components/ContactInfo";
import DataCardContainer from "../components/DataCardContainer";
import ErrorAlert from "../components/ErrorAlert";
import HelpDialog2 from "../components/HelpDialog2";
import NavbarContainer from "../components/NavbarContainer";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import ProposalAverageValueCard from "../components/ProposalAverageValueCard";
import ProposalPipelineCard from "../components/ProposalPipelineCard";
import ProposalTotalValueCard from "../components/ProposalTotalValueCard";
import ProposalWinLossCard from "../components/ProposalWinLossCard";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";

// Redux action imports
// Client
import { listClientsAction } from "../actions/clientActions";

// Contact
import { listContactsAction } from "../actions/contactActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Proposal
import {
  createProposalAction,
  listProposalsAction,
  resetCreateProposalAction,
  resetListProposalDetailsAction,
} from "../actions/proposalActions";

// Main function
function ProposalListScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Client
  const clientList = useSelector((state) => state.clientList);

  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Proposal
  const proposalCreate = useSelector((state) => state.proposalCreate);

  const proposalList = useSelector((state) => state.proposalList);

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Proposal
  const [addProposalDrawerIsOpen, setAddProposalDrawerIsOpen] = useState(false);

  // Search and filter variables
  // Proposal
  const proposalQueryDefault = {
    client: "",
    contact: "",
    dueEnd: "",
    dueStart: "",
    exclude: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
    unlinked: "",
    user: "",
  };
  const [proposalQuery, setProposalQuery] = useState({
    ...proposalQueryDefault,
  });

  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Form variables
  // Proposal
  const newProposalDefault = {
    client: "",
    clientName: "",
    contact: "",
    contactName: "",
    due: "",
    name: "",
    notes: "",
    rfp: "",
    rfpName: "",
    value: "",
  };
  const [newProposal, setNewProposal] = useState({ ...newProposalDefault });

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Proposal
    setNewProposal({ ...newProposalDefault });

    // Query variables
    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
  }, [navigate, userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // Filter proposals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalsAction({
            ...proposalQuery,
            dueEnd: proposalQuery.dueEnd
              ? getDjangoDate(proposalQuery.dueEnd)
              : getDjangoDate(proposalQueryDefault.dueEnd),
            dueStart: proposalQuery.dueStart
              ? getDjangoDate(proposalQuery.dueStart)
              : getDjangoDate(proposalQueryDefault.dueStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuery]);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Form submission
  // Add proposal
  const addProposal = () => {
    // Prepare data
    const proposal = new FormData();

    const client = new FormData();
    if (newProposal.client) {
      proposal.append("client", newProposal.client);
    } else {
      proposal.append("client", newProposalDefault.client);

      if (newProposal.clientName) {
        client.append("name", newProposal.clientName);

        client.append("organisation", activeOrganisation.id);
      }
    }

    const contact = new FormData();
    if (newProposal.contact) {
      proposal.append("contact", newProposal.contact);
    } else {
      proposal.append("contact", newProposalDefault.contact);

      if (newProposal.contactName) {
        contact.append("name", newProposal.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    newProposal.due
      ? proposal.append("due", getDjangoDate(newProposal.due))
      : proposal.append("due", getDjangoDate(newProposalDefault.due));

    newProposal.name
      ? proposal.append("name", newProposal.name)
      : proposal.append("name", newProposalDefault.name);

    newProposal.notes
      ? proposal.append("notes", newProposal.notes)
      : proposal.append("notes", newProposalDefault.notes);

    proposal.append("organisation", activeOrganisation.id);

    newProposal.rfp
      ? proposal.append("rfp", newProposal.rfp)
      : proposal.append("rfp", newProposalDefault.rfp);

    newProposal.value
      ? proposal.append("value", newProposal.value)
      : proposal.append("value", newProposalDefault.value);

    // Dispatch action
    dispatch(createProposalAction(client, contact, proposal));
  };

  // JSX UI code
  return (
    <div>
      {/* Form drawers */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Identify the proposal</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Proposal name */}
                <FormDrawerTextField2
                  error={proposalCreate.error}
                  fieldName="name"
                  icon="manual"
                  label="Proposal name"
                  labelInfo="(required)"
                  placeholder="Give the proposal a title"
                  setValueObject={setNewProposal}
                  setValueVariable="name"
                  value={newProposal.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Client */}
                <FormDrawerSelectField2
                  allowCreate
                  error={proposalCreate.error}
                  fieldName="client"
                  helperText="You can create or select an existing client"
                  icon="office"
                  info={
                    <ClientInfo
                      clientId={newProposal.client}
                      query={clientQueryDefault}
                    />
                  }
                  items={clientList.clients}
                  label="Client"
                  labelInfo="(required)"
                  loading={clientList.loading}
                  placeholder="Create or select a client"
                  query={clientQuery.searchString}
                  setQueryObject={setClientQuery}
                  setQueryVariable="searchString"
                  setValueObject={setNewProposal}
                  setValueIdVariable="client"
                  setValueNameVariable="clientName"
                  showInfo={newProposal.client}
                  value={newProposal.clientName}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Proposal value */}
                <FormDrawerNumericField2
                  error={proposalCreate.error}
                  fieldName="value"
                  label="Proposal value"
                  labelInfo="(required)"
                  leftElement={
                    <Tag minimal style={{ textAlign: "center", width: "50px" }}>
                      {activeOrganisation.currency}
                    </Tag>
                  }
                  majorStepSize={100000}
                  min={0}
                  minorStepSize={1000}
                  placeholder="Enter the estimated contract value"
                  setValueObject={setNewProposal}
                  setValueVariable="value"
                  stepSize={10000}
                  value={newProposal.value}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={proposalCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the due date"
                  setValueObject={setNewProposal}
                  setValueVariable="due"
                  value={newProposal.due}
                />
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "50%" }}>
                {/* Contact */}
                <FormDrawerSelectField2
                  allowCreate
                  error={proposalCreate.error}
                  fieldName="contact"
                  helperText="You can create or select an existing contact"
                  icon="id-number"
                  info={
                    <ContactInfo
                      contactId={newProposal.contact}
                      query={contactQueryDefault}
                    />
                  }
                  items={contactList.contacts}
                  label="Contact"
                  loading={contactList.loading}
                  noOptionOnClick
                  placeholder="Create or select a contact"
                  query={contactQuery.searchString}
                  setQueryObject={setContactQuery}
                  setQueryVariable="searchString"
                  setValueObject={setNewProposal}
                  setValueIdVariable="contact"
                  setValueNameVariable="contactName"
                  showInfo={newProposal.contact}
                  value={newProposal.contactName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "50%" }}>
                {/* Request for proposal */}
                <FormDrawerFileField2
                  error={proposalCreate.error}
                  fileName="rfp"
                  label="Request for proposal"
                  placeholder="Upload the RFP document"
                  setValueObject={setNewProposal}
                  setValueFileVariable="rfp"
                  setValueNameVariable="rfpName"
                  valueFile={newProposal.rfp}
                  valueName={newProposal.rfpName}
                />
              </div>
            </div>

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={proposalCreate.error}
              fieldName="notes"
              label="Notes"
              placeholder="Enter your notes on the proposal"
              setValueObject={setNewProposal}
              setValueVariable="notes"
              value={newProposal.notes}
            />
          </Card>
        }
        error={proposalCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add proposal"
            module="Proposals"
            view="List"
          />
        }
        helpTitle="Starting a proposal"
        icon="add"
        isOpen={addProposalDrawerIsOpen}
        onSave={addProposal}
        refreshList={() => {
          dispatch(
            listProposalsAction({
              organisation: activeOrganisation.id,
            })
          );
        }}
        resetAction={() => {
          dispatch(resetCreateProposalAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={proposalCreate.success}
        saveSuccessMessage="Proposal created."
        saving={proposalCreate.loading}
        setIsOpen={setAddProposalDrawerIsOpen}
        title="Add proposal"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="home"
              minimal
              text="Home"
              onClick={() => {
                navigate("/");
              }}
            />

            <Button
              icon="add"
              minimal
              onClick={() => {
                setAddProposalDrawerIsOpen(true);
              }}
              text="Add"
            />

            {false && (
              <Button
                icon="help"
                minimal
                text="Help"
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
              />
            )}
          </>
        }
        title="Proposals"
      />

      {/* Error alerts */}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Proposals loading error */}
      {proposalList.error && (
        <ErrorAlert message="We couldn't load the proposal list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog2
        captionsUrl={`${VIDEOS_URL}/advaise.app overview.vtt`}
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Proposals"
        videoUrl={`${VIDEOS_URL}/advaise.app overview.mp4`}
        width="800"
      />

      {/* Data cards */}
      <div style={{ margin: "0 auto -30px auto", maxWidth: "1600px" }}>
        <DataCardContainer
          children={
            <>
              {/* Pipeline */}
              <ProposalPipelineCard
                fill
                proposalList={proposalList}
                showInsights={
                  !proposalQuery.client &&
                  !proposalQuery.dueEnd &&
                  !proposalQuery.dueStart &&
                  !proposalQuery.manager &&
                  !proposalQuery.searchString &&
                  !proposalQuery.status
                }
              />

              {/* Won-lost */}
              <ProposalWinLossCard
                fill
                proposalList={proposalList}
                proposalQuery={proposalQuery}
                showInsights={
                  !proposalQuery.client &&
                  !proposalQuery.dueEnd &&
                  !proposalQuery.dueStart &&
                  !proposalQuery.manager &&
                  !proposalQuery.searchString &&
                  !proposalQuery.status
                }
              />

              {/* Average value */}
              <ProposalAverageValueCard
                fill
                proposalList={proposalList}
                showInsights={
                  !proposalQuery.client &&
                  !proposalQuery.dueEnd &&
                  !proposalQuery.dueStart &&
                  !proposalQuery.manager &&
                  !proposalQuery.searchString &&
                  !proposalQuery.status
                }
              />

              {/* Total value */}
              <ProposalTotalValueCard
                fill
                proposalList={proposalList}
                proposalQuery={proposalQuery}
                showInsights={
                  !proposalQuery.client &&
                  !proposalQuery.dueEnd &&
                  !proposalQuery.dueStart &&
                  !proposalQuery.manager &&
                  !proposalQuery.searchString &&
                  !proposalQuery.status
                }
              />
            </>
          }
          fill
        />
      </div>

      {/* Data table */}
      <div style={{ margin: "0 auto 0 auto", maxWidth: "1600px" }}>
        <TableContainer2
          body={proposalList.proposals.map((proposal) => {
            return (
              <tr
                key={proposal.id}
                onClick={() => {
                  if (proposal.user_has_permission) {
                    dispatch(resetListProposalDetailsAction());

                    navigate(`/proposals/${proposal.id}`);
                  } else {
                    AppToaster.show({
                      icon: "warning-sign",
                      intent: "danger",
                      message: "You do not have access to this proposal.",
                    });
                  }
                }}
              >
                {/* Proposal */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    tagIntent={
                      !proposal.user_has_permission
                        ? "danger"
                        : proposal.user_access_status === "view"
                        ? "primary"
                        : undefined
                    }
                    tagText={
                      !proposal.user_has_permission
                        ? "No access"
                        : proposal.user_access_status === "view"
                        ? "View only"
                        : undefined
                    }
                    text={proposal.name}
                  />
                </td>

                {/* Client */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    image={proposal.client_logo}
                    logo
                    showImage
                    text={proposal.client_name}
                    url={`/clients/${proposal.client}`}
                  />
                </td>

                {/* Value */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableNumber number={proposal.value} />
                </td>

                {/* Manager */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableText
                    email={proposal.manager_name}
                    image={proposal.manager_profile_photo}
                    showImage
                  />
                </td>

                {/* Due */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableDate date={proposal.due} />
                </td>

                {/* Status */}
                <td style={{ verticalAlign: "middle" }}>
                  <TableTag
                    intent={
                      proposal.status === "Won"
                        ? "success"
                        : proposal.status === "Lost"
                        ? "danger"
                        : proposal.status === "Cancelled"
                        ? "warning"
                        : "primary"
                    }
                    value={proposal.status}
                  />
                </td>
              </tr>
            );
          })}
          clearFiltersDisabled={
            !proposalQuery.client &&
            !proposalQuery.dueEnd &&
            !proposalQuery.dueStart &&
            !proposalQuery.manager &&
            !proposalQuery.searchString &&
            !proposalQuery.status
          }
          count={proposalList.count}
          footnote={<div>Figures in {activeOrganisation.currency}</div>}
          head={
            <tr>
              {/* Proposal */}
              <TableColumnHeadSearch
                queryObject={proposalQuery}
                queryVariable="searchString"
                setQueryObject={setProposalQuery}
                setQueryVariable="searchString"
                title="Proposal"
                width="200px"
              />

              {/* Client */}
              <TableColumnHeadSelect2
                filterObject={proposalQuery}
                filterVariable="client"
                items={clientList.clients}
                setFilterObject={setProposalQuery}
                setQueryObject={setClientQuery}
                setQueryVariable="searchString"
                title="Client"
                width="200px"
              />

              {/* Value */}
              <TableColumnHeadSelectSimple
                filterObject={proposalQuery}
                filterVariable="sortField"
                noActive
                options={[
                  {
                    display: "Sort Ascending",
                    icon: "sort-asc",
                    value: "value",
                  },
                  {
                    display: "Sort Descending",
                    icon: "sort-desc",
                    value: "-value",
                  },
                ]}
                setFilterObject={setProposalQuery}
                title="Value"
                width="125px"
              />

              {/* Manager */}
              <TableColumnHeadSelect2
                filterObject={proposalQuery}
                filterVariable="manager"
                items={managerList.managers}
                setFilterObject={setProposalQuery}
                setQueryObject={setManagerQuery}
                setQueryVariable="searchString"
                title="Manager"
                width="200px"
              />

              {/* Due */}
              <TableColumnHeadDate
                endDateVariable="dueEnd"
                queryObject={proposalQuery}
                setQueryObject={setProposalQuery}
                shortRange
                startDateVariable="dueStart"
                title="Due"
                width="125px"
              />

              {/* Status */}
              <TableColumnHeadSelectSimple
                filterObject={proposalQuery}
                filterVariable="status"
                options={[
                  { display: "All", icon: "filter-remove", value: "" },
                  {
                    display: "Qualifying",
                    icon: "search-text",
                    value: "Qualifying",
                  },
                  {
                    display: "In-progress",
                    icon: "manually-entered-data",
                    value: "In-progress",
                  },
                  {
                    display: "Submitted",
                    icon: "envelope",
                    value: "Submitted",
                  },
                  { display: "Won", icon: "tick-circle", value: "Won" },
                  { display: "Lost", icon: "error", value: "Lost" },
                  { display: "Cancelled", icon: "undo", value: "Cancelled" },
                ]}
                setFilterObject={setProposalQuery}
                title="Status"
                width="125px"
              />
            </tr>
          }
          interactive
          loading={proposalList.loading}
          onClearFilters={() => {
            setProposalQuery({
              ...proposalQueryDefault,
            });
          }}
          page={proposalQuery.page}
          setPageObject={setProposalQuery}
          setPageVariable="page"
        />
      </div>
    </div>
  );
}

export default ProposalListScreen;
