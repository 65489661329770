// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDataPoint from "../components/DataCardDataPoint";

// Main function
function InvoiceAgeCard({ fill, invoiceList }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDataPoint
      background={
        invoiceList.averageAge > 60
          ? lightMode
            ? Colors.RED3
            : Colors.RED5
          : invoiceList.averageAge > 30
          ? lightMode
            ? Colors.ORANGE3
            : Colors.ORANGE5
          : lightMode
          ? Colors.GREEN3
          : Colors.GREEN5
      }
      comments={
        <>
          <H5>Age</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Average age of the selected invoice(s). Considers invoices that have
            been issued but have not yet been paid or disputed.
          </p>
        </>
      }
      data={invoiceList.averageAge}
      fill={fill}
      noData={!invoiceList.averageAge || invoiceList.averageAge === 0}
      noDataMessage="You do not have any ageing invoices"
      title="Age"
      units="Days"
    />
  );
}

export default InvoiceAgeCard;
