// Constant imports
import {
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_LIST_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_CREATE_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_RESET,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_REQUEST,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_SUCCESS,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_FAIL,
  ENGAGEMENT_SERVICE_ACTIVITY_DELETE_RESET,
} from "../constants/engagementServiceActivityConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementServiceActivitiesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementServiceActivities(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_service_activities: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementServiceActivitiesAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_LIST_RESET });
  };

// Create
export const createEngagementServiceActivityAction =
  (engagement_service_activity) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createEngagementServiceActivity(
        token,
        engagement_service_activity
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementServiceActivityAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_CREATE_RESET });
  };

// List details
export const listEngagementServiceActivityDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementServiceActivity(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementServiceActivityDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_DETAILS_RESET });
  };

// Update
export const updateEngagementServiceActivityAction =
  (id, engagement_service_activity) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateEngagementServiceActivity(
        id,
        token,
        engagement_service_activity
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementServiceActivityAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_UPDATE_RESET });
  };

// Delete
export const deleteEngagementServiceActivityAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteEngagementServiceActivity(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_ACTIVITY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementServiceActivityAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_ACTIVITY_DELETE_RESET });
  };
