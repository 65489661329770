// Library imports
import React, { useEffect, useState } from "react";

// Component imports
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";

// Main function
function FormDrawerArrayTextAreaField({
  array,
  arrayField,
  arrayPosition,
  context,
  error,
  fieldName,
  height,
  label,
  minLength,
  placeholder,
  setValueObject,
  setValueVariable,
}) {
  // Initialise
  const [initialised, setInitialised] = useState(false);

  const [object, setObject] = useState({
    value: "",
  });

  useEffect(() => {
    if (array && arrayField && arrayPosition !== null) {
      if (array[arrayPosition][arrayField]) {
        const newValue = array[arrayPosition][arrayField];

        if (newValue !== object.value) {
          setObject({
            value: newValue,
          });
        }
      }

      setInitialised(true);
    }
    // eslint-disable-next-line
  }, [array, arrayField, arrayPosition]);

  // Update
  useEffect(() => {
    if (initialised && object.value !== null) {
      let newArray = Object.assign([], array);

      const item = newArray[arrayPosition];

      newArray.splice(arrayPosition, 1, {
        ...item,
        [arrayField]: object.value,
      });

      setValueObject((prevState) => {
        return {
          ...prevState,
          [setValueVariable]: newArray,
        };
      });
    }
    // eslint-disable-next-line
  }, [object.value]);

  // JSX UI code
  return (
    <FormDrawerTextAreaField3
      context={context}
      error={error}
      fieldName={fieldName}
      height={height}
      label={label}
      minLength={minLength}
      placeholder={placeholder}
      setValueObject={setObject}
      setValueVariable="value"
      value={object.value}
    />
  );
}

export default FormDrawerArrayTextAreaField;
