export const ENGAGEMENT_RISK_LIST_REQUEST = "ENGAGEMENT_RISK_LIST_REQUEST";
export const ENGAGEMENT_RISK_LIST_SUCCESS = "ENGAGEMENT_RISK_LIST_SUCCESS";
export const ENGAGEMENT_RISK_LIST_FAIL = "ENGAGEMENT_RISK_LIST_FAIL";
export const ENGAGEMENT_RISK_LIST_RESET = "ENGAGEMENT_RISK_LIST_RESET";

export const ENGAGEMENT_RISK_CREATE_REQUEST = "ENGAGEMENT_RISK_CREATE_REQUEST";
export const ENGAGEMENT_RISK_CREATE_SUCCESS = "ENGAGEMENT_RISK_CREATE_SUCCESS";
export const ENGAGEMENT_RISK_CREATE_FAIL = "ENGAGEMENT_RISK_CREATE_FAIL";
export const ENGAGEMENT_RISK_CREATE_RESET = "ENGAGEMENT_RISK_CREATE_RESET";

export const ENGAGEMENT_RISK_DETAILS_REQUEST =
  "ENGAGEMENT_RISK_DETAILS_REQUEST";
export const ENGAGEMENT_RISK_DETAILS_SUCCESS =
  "ENGAGEMENT_RISK_DETAILS_SUCCESS";
export const ENGAGEMENT_RISK_DETAILS_FAIL = "ENGAGEMENT_RISK_DETAILS_FAIL";
export const ENGAGEMENT_RISK_DETAILS_RESET = "ENGAGEMENT_RISK_DETAILS_RESET";

export const ENGAGEMENT_RISK_UPDATE_REQUEST = "ENGAGEMENT_RISK_UPDATE_REQUEST";
export const ENGAGEMENT_RISK_UPDATE_SUCCESS = "ENGAGEMENT_RISK_UPDATE_SUCCESS";
export const ENGAGEMENT_RISK_UPDATE_FAIL = "ENGAGEMENT_RISK_UPDATE_FAIL";
export const ENGAGEMENT_RISK_UPDATE_RESET = "ENGAGEMENT_RISK_UPDATE_RESET";

export const ENGAGEMENT_RISK_DELETE_REQUEST = "ENGAGEMENT_RISK_DELETE_REQUEST";
export const ENGAGEMENT_RISK_DELETE_SUCCESS = "ENGAGEMENT_RISK_DELETE_SUCCESS";
export const ENGAGEMENT_RISK_DELETE_FAIL = "ENGAGEMENT_RISK_DELETE_FAIL";
export const ENGAGEMENT_RISK_DELETE_RESET = "ENGAGEMENT_RISK_DELETE_RESET";
