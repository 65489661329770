// Constant imports
import {
  BOOKING_LIST_REQUEST,
  BOOKING_LIST_SUCCESS,
  BOOKING_LIST_FAIL,
  BOOKING_LIST_RESET,
  BOOKING_CREATE_REQUEST,
  BOOKING_CREATE_SUCCESS,
  BOOKING_CREATE_FAIL,
  BOOKING_CREATE_RESET,
  BOOKING_DETAILS_REQUEST,
  BOOKING_DETAILS_SUCCESS,
  BOOKING_DETAILS_FAIL,
  BOOKING_DETAILS_RESET,
  BOOKING_UPDATE_REQUEST,
  BOOKING_UPDATE_SUCCESS,
  BOOKING_UPDATE_FAIL,
  BOOKING_UPDATE_RESET,
  BOOKING_DELETE_REQUEST,
  BOOKING_DELETE_SUCCESS,
  BOOKING_DELETE_FAIL,
  BOOKING_DELETE_RESET,
} from "../constants/bookingConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listBookingsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: BOOKING_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getBookings(params, token);

    dispatch({
      type: BOOKING_LIST_SUCCESS,

      payload: {
        averageUtilisationBillableConfirmed:
          data.average_utilisation_billable_confirmed.result,
        averageUtilisationBillableRealised:
          data.average_utilisation_billable_realised.result,
        averageUtilisationBillableTentative:
          data.average_utilisation_billable_tentative.result,
        averageUtilisationNotBillableConfirmed:
          data.average_utilisation_not_billable_confirmed.result,
        averageUtilisationNotBillableRealised:
          data.average_utilisation_not_billable_realised.result,
        averageUtilisationNotBillableTentative:
          data.average_utilisation_not_billable_tentative.result,
        bookingProfile: data.booking_profile,
        totalMinutesBillableConfirmed:
          data.total_minutes_billable_confirmed.result,
        totalMinutesBillableRealised:
          data.total_minutes_billable_realised.result,
        totalMinutesBillableTentative:
          data.total_minutes_billable_tentative.result,
        totalMinutesNotBillableConfirmed:
          data.total_minutes_not_billable_confirmed.result,
        totalMinutesNotBillableRealised:
          data.total_minutes_not_billable_realised.result,
        totalMinutesNotBillableTentative:
          data.total_minutes_not_billable_tentative.result,
        count: data.count,
        bookings: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: BOOKING_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListBookingsAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_LIST_RESET });
};

// Create
export const createBookingAction = (booking) => async (dispatch, getState) => {
  try {
    dispatch({ type: BOOKING_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createBooking(token, booking);

    dispatch({
      type: BOOKING_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateBookingAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_CREATE_RESET });
};

// List details
export const listBookingDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BOOKING_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getBooking(id, token);

    dispatch({
      type: BOOKING_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListBookingDetailsAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_DETAILS_RESET });
};

// Update
export const updateBookingAction =
  (id, booking) => async (dispatch, getState) => {
    try {
      dispatch({ type: BOOKING_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateBooking(
        id,
        token,
        booking
      );

      dispatch({
        type: BOOKING_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BOOKING_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateBookingAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_UPDATE_RESET });
};

// Delete
export const deleteBookingAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BOOKING_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteBooking(id, token);

    dispatch({
      type: BOOKING_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteBookingAction = () => async (dispatch) => {
  dispatch({ type: BOOKING_DELETE_RESET });
};
