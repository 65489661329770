// Constant imports
import {
  CLAIM_LIST_REQUEST,
  CLAIM_LIST_SUCCESS,
  CLAIM_LIST_FAIL,
  CLAIM_LIST_RESET,
  CLAIM_CREATE_REQUEST,
  CLAIM_CREATE_SUCCESS,
  CLAIM_CREATE_FAIL,
  CLAIM_CREATE_RESET,
  CLAIM_DETAILS_REQUEST,
  CLAIM_DETAILS_SUCCESS,
  CLAIM_DETAILS_FAIL,
  CLAIM_DETAILS_RESET,
  CLAIM_UPDATE_REQUEST,
  CLAIM_UPDATE_SUCCESS,
  CLAIM_UPDATE_FAIL,
  CLAIM_UPDATE_RESET,
  CLAIM_DELETE_REQUEST,
  CLAIM_DELETE_SUCCESS,
  CLAIM_DELETE_FAIL,
  CLAIM_DELETE_RESET,
  CLAIM_DUPLICATE_REQUEST,
  CLAIM_DUPLICATE_SUCCESS,
  CLAIM_DUPLICATE_FAIL,
  CLAIM_DUPLICATE_RESET,
  CLAIM_REVERSE_REQUEST,
  CLAIM_REVERSE_SUCCESS,
  CLAIM_REVERSE_FAIL,
  CLAIM_REVERSE_RESET,
} from "../constants/claimConstants";

// List all
export const claimListReducer = (
  state = {
    loading: false,
    count: 0,
    claims: [],
    valueOfClaimsApproved: 0,
    valueOfClaimsDraft: 0,
    valueOfClaimsPaid: 0,
    valueOfClaimsRejected: 0,
    valueOfClaimsSubmitted: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLAIM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLAIM_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        claims: action.payload.claims,
        valueOfClaimsApproved: action.payload.valueOfClaimsApproved,
        valueOfClaimsDraft: action.payload.valueOfClaimsDraft,
        valueOfClaimsPaid: action.payload.valueOfClaimsPaid,
        valueOfClaimsRejected: action.payload.valueOfClaimsRejected,
        valueOfClaimsSubmitted: action.payload.valueOfClaimsSubmitted,
        success: true,
        error: "",
      };

    case CLAIM_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        claims: [],
        valueOfClaimsApproved: 0,
        valueOfClaimsDraft: 0,
        valueOfClaimsPaid: 0,
        valueOfClaimsRejected: 0,
        valueOfClaimsSubmitted: 0,
        success: false,
        error: action.payload,
      };

    case CLAIM_LIST_RESET:
      return {
        loading: false,
        count: 0,
        claims: [],
        valueOfClaimsApproved: 0,
        valueOfClaimsDraft: 0,
        valueOfClaimsPaid: 0,
        valueOfClaimsRejected: 0,
        valueOfClaimsSubmitted: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const claimCreateReducer = (
  state = { loading: false, claim: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLAIM_CREATE_REQUEST:
      return {
        loading: true,
        claim: state.claim,
        success: false,
        error: "",
      };

    case CLAIM_CREATE_SUCCESS:
      return {
        loading: false,
        claim: action.payload,
        success: true,
        error: "",
      };

    case CLAIM_CREATE_FAIL:
      return {
        loading: false,
        claim: state.claim,
        success: false,
        error: action.payload,
      };

    case CLAIM_CREATE_RESET:
      return { loading: false, claim: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const claimDetailsReducer = (
  state = { loading: false, claim: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLAIM_DETAILS_REQUEST:
      return {
        loading: true,
        claim: state.claim,
        success: false,
        error: "",
      };

    case CLAIM_DETAILS_SUCCESS:
      return {
        loading: false,
        claim: action.payload,
        success: true,
        error: "",
      };

    case CLAIM_DETAILS_FAIL:
      return {
        loading: false,
        claim: state.claim,
        success: false,
        error: action.payload,
      };

    case CLAIM_DETAILS_RESET:
      return { loading: false, claim: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const claimUpdateReducer = (
  state = { loading: false, claim: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLAIM_UPDATE_REQUEST:
      return {
        loading: true,
        claim: state.claim,
        success: false,
        error: "",
      };

    case CLAIM_UPDATE_SUCCESS:
      return {
        loading: false,
        claim: action.payload,
        success: true,
        error: "",
      };

    case CLAIM_UPDATE_FAIL:
      return {
        loading: false,
        claim: state.claim,
        success: false,
        error: action.payload,
      };

    case CLAIM_UPDATE_RESET:
      return { loading: false, claim: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const claimDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLAIM_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLAIM_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLAIM_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLAIM_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Duplicate
export const claimDuplicateReducer = (
  state = { loading: false, claim: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLAIM_DUPLICATE_REQUEST:
      return {
        loading: true,
        claim: state.claim,
        success: false,
        error: "",
      };

    case CLAIM_DUPLICATE_SUCCESS:
      return {
        loading: false,
        claim: action.payload,
        success: true,
        error: "",
      };

    case CLAIM_DUPLICATE_FAIL:
      return {
        loading: false,
        claim: state.claim,
        success: false,
        error: action.payload,
      };

    case CLAIM_DUPLICATE_RESET:
      return { loading: false, claim: "", success: false, error: "" };

    default:
      return state;
  }
};

// Reverse
export const claimReverseReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLAIM_REVERSE_REQUEST:
      return {
        loading: true,
        result: state.result,
        success: false,
        error: "",
      };

    case CLAIM_REVERSE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case CLAIM_REVERSE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case CLAIM_REVERSE_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};
