// Library imports
import {
  Card,
  Classes,
  Colors,
  Divider,
  H6,
  HTMLSelect,
  HTMLTable,
  NonIdealState,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  Area,
  Bar,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// Utility imports
import convertMinutesToHoursAndMinutes from "../utilities/convertMinutesToHoursAndMinutes";
import getCurrentWeekOfYear from "../utilities/getCurrentWeekOfYear";

// Main function
function BookingChart2({
  data,
  setStaffQuery,
  utilisationAnalysis,
  utilisationScore,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Custom tooltip
  const customToolTip = ({ payload, label, active }) => {
    if (active) {
      const date = new Date(label);

      return (
        <Card
          style={{
            paddingBottom: "10px",
            width: "400px",
          }}
        >
          <H6>
            {selectedLevel === "day"
              ? date.toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : selectedLevel === "month"
              ? date.toLocaleString("en-US", {
                  month: "short",
                  year: "numeric",
                })
              : `${date.toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })} - ${new Date(
                  date.setDate(date.getDate() + 6)
                ).toLocaleString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}`}
          </H6>

          <Tag
            intent={
              payload[0].payload.free === 0
                ? "success"
                : payload[0].payload.free < 0
                ? "warning"
                : "danger"
            }
            style={{
              textAlign: "center",
              width: "fit-content",
            }}
          >
            {payload[0].payload.free === 0
              ? "Fully booked"
              : payload[0].payload.free < 0
              ? "Oversubscribed"
              : "Undersubscribed"}
          </Tag>

          <HTMLTable style={{ width: "100%" }}>
            <tbody className={Classes.TEXT_SMALL}>
              <tr>
                <td>
                  <Tag intent="none" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.available
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.available
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>

                <td>
                  <Tag intent="primary" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.confirmed
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.confirmed
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <Tag intent="warning" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.tentative
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.tentative
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>

                <td
                  style={{
                    color: lightMode ? Colors.WHITE : Colors.BLACK,
                    verticalAlign: "middle",
                  }}
                >
                  <Tag intent="success" minimal>
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.realised
                      ).split(":")[0]
                    }
                    h{" "}
                    {
                      convertMinutesToHoursAndMinutes(
                        payload[0].payload.realised
                      ).split(":")[1]
                    }
                    m
                  </Tag>
                </td>
              </tr>
            </tbody>

            <thead className={Classes.TEXT_SMALL}>
              <tr>
                <th>Available</th>

                <th>Confirmed</th>

                <th>Tentative</th>

                <th>Realised</th>
              </tr>
            </thead>
          </HTMLTable>
        </Card>
      );
    }

    return null;
  };

  // Filter and reshape data
  const currentYear = new Date().getFullYear().toString();

  const [filteredData, setFilteredData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    if (data) {
      const newData = [];

      Object.keys(data)
        .filter(
          (key) => new Date(key).getFullYear().toString() === selectedYear
        )
        .forEach((key) => {
          newData.push({
            available: data[key]["available"],
            confirmed: data[key]["confirmed"],
            date: key,
            free: data[key]["free"],
            realised: data[key]["realised"],
            tentative: data[key]["tentative"],
          });
        });

      setFilteredData(newData);
    }
    // eslint-disable-next-line
  }, [data, selectedYear]);

  // Change level
  const [selectedLevel, setSelectedLevel] = useState("month");
  const levelOptions = [
    { label: "Daily", value: "day" },
    { label: "Weekly", value: "week" },
    { label: "Monthly", value: "month" },
  ];

  useEffect(() => {
    setStaffQuery((prevState) => {
      return {
        ...prevState,
        bookingProfile: selectedLevel,
      };
    });
    // eslint-disable-next-line
  }, [selectedLevel]);

  // Generate year options
  const [yearOptions, setYearOptions] = useState([]);

  useEffect(() => {
    if (data) {
      const yearsUnique = Array.from(
        new Set(
          Object.keys(data).map((key) => {
            return new Date(key).getFullYear().toString();
          })
        )
      );

      setYearOptions(yearsUnique);
    }
  }, [data]);

  // Legend formatter
  const legendFormatter = (value, entry, index) => {
    return (
      <span style={{ fontSize: 12 }}>
        {value === "available"
          ? "Available"
          : value === "confirmed"
          ? "Confirmed"
          : value === "realised"
          ? "Realised"
          : "Tentative"}
      </span>
    );
  };

  // Tick formatter
  const tickFormatter = (value, index) => {
    return new Date(value).toLocaleString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  // Scroll to current week
  const [chartRendered, setChartRendered] = useState(false);
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartRendered) {
      const weekOfYear = getCurrentWeekOfYear();

      if (selectedLevel === "day") {
        chartContainer.current.scrollLeft = 100 * 7 * weekOfYear;
      } else if (selectedLevel === "month") {
        chartContainer.current.scrollLeft = (100 / 7.4286) * weekOfYear;
      } else {
        chartContainer.current.scrollLeft = 100 * weekOfYear;
      }

      setChartRendered(false);
    }
  }, [chartRendered, selectedLevel]);

  // JSX UI code
  return (
    <div style={{ display: "flex" }}>
      {/* Analysis */}
      <Card
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          width: "30%",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            margin: "0 0 10px 0",
          }}
        >
          <H6 style={{ margin: "0" }}>
            <Text ellipsize>Analysis</Text>
          </H6>

          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
              <HTMLSelect
                id="levelField"
                onChange={(event) =>
                  setSelectedLevel(event.currentTarget.value)
                }
                options={levelOptions}
                value={selectedLevel}
              />
            </div>

            <div>
              <HTMLSelect
                id="yearField"
                onChange={(event) => setSelectedYear(event.currentTarget.value)}
                options={yearOptions}
                value={selectedYear}
              />
            </div>
          </div>
        </div>

        <Divider />

        {utilisationScore !== null ? (
          <div style={{ margin: "10px 0 0 0" }}>
            <Tag
              intent={
                utilisationScore >= 80
                  ? "success"
                  : utilisationScore >= 60
                  ? "warning"
                  : "danger"
              }
              large
              minimal
              style={{ textAlign: "center", width: "fit-content" }}
            >
              {utilisationScore >= 80
                ? "Healthy"
                : utilisationScore >= 60
                ? "Needs attention"
                : "Needs urgent attention"}
            </Tag>
          </div>
        ) : undefined}

        <div
          style={{
            height: utilisationScore !== null ? "180px" : "210px",
            margin: "10px -20px 0 0",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          {utilisationAnalysis ? (
            <span style={{ whiteSpace: "pre-wrap" }}>
              {utilisationAnalysis}
            </span>
          ) : (
            <NonIdealState
              description="The system will evaluate the team member's utilisation when more information becomes available."
              icon="predictive-analysis"
              title="Utilisation analysis unavailable"
            />
          )}
        </div>
      </Card>

      {/* Booking chart */}
      <Card
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          margin: "0 0 0 10px",
          overflow: "hidden",
          width: "70%",
        }}
      >
        <div
          ref={chartContainer}
          style={{
            height: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            scrollBehavior: "smooth",
            width: "100%",
          }}
        >
          {filteredData.length > 0 ? (
            <ComposedChart
              data={filteredData}
              height={250}
              margin={{
                bottom: 5,
                left: 5,
                right: 5,
                top: 5,
              }}
              width={100 * filteredData.length}
            >
              <defs>
                <linearGradient id="colorConfirmed" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                    stopOpacity={0.9}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightMode ? Colors.BLUE3 : Colors.BLUE5}
                    stopOpacity={0.7}
                  />
                </linearGradient>

                <linearGradient id="colorRealised" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                    stopOpacity={0.9}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                    stopOpacity={0.7}
                  />
                </linearGradient>

                <linearGradient id="colorTentative" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={lightMode ? Colors.ORANGE3 : Colors.ORANGE5}
                    stopOpacity={0.9}
                  />
                  <stop
                    offset="95%"
                    stopColor={lightMode ? Colors.ORANGE3 : Colors.ORANGE5}
                    stopOpacity={0.7}
                  />
                </linearGradient>
              </defs>

              <Line
                dataKey="available"
                dot={false}
                onAnimationEnd={() => setChartRendered(true)}
                stroke={lightMode ? Colors.GRAY1 : Colors.GRAY5}
                strokeDasharray="3"
                type="step"
              />

              <Bar
                barSize={65}
                dataKey="confirmed"
                fill="url(#colorConfirmed)"
                stackId="a"
              />

              <Bar
                barSize={65}
                dataKey="tentative"
                fill="url(#colorTentative)"
                stackId="a"
              />

              <Area
                dataKey="realised"
                fill="url(#colorRealised)"
                stroke={lightMode ? Colors.GREEN3 : Colors.GREEN5}
                type="monotone"
              />

              <Legend
                formatter={legendFormatter}
                wrapperStyle={{
                  backgroundColor: lightMode ? Colors.WHITE : Colors.DARK_GRAY3,
                  border: `1px solid ${
                    lightMode ? Colors.LIGHT_GRAY1 : Colors.DARK_GRAY5
                  }`,
                  borderRadius: 3,
                  lineHeight: "30px",
                  position: "sticky",
                  width: "350px",
                }}
              />

              <Tooltip content={customToolTip} />

              <XAxis
                dataKey="date"
                stroke={lightMode ? Colors.BLACK : Colors.WHITE}
                style={{ fontSize: 10 }}
                tickFormatter={tickFormatter}
                type="category"
              />

              <YAxis
                domain={[0, "auto"]}
                hide
                stroke={lightMode ? Colors.BLACK : Colors.WHITE}
                type="number"
              />
            </ComposedChart>
          ) : (
            <NonIdealState
              description="Assign bookings to the team member."
              icon="search"
              title="No bookings found"
            />
          )}
        </div>
      </Card>
    </div>
  );
}

export default BookingChart2;
