// Main function
const checkObjectsEqual = (obj1, obj2) => {
  // Both are identical
  if (obj1 === obj2) {
    return true;
  }

  // At least one is not an object or is null
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  // The number of keys is not the same
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  // The key values are not the same
  for (const key of keys1) {
    if (!keys2.includes(key) || !checkObjectsEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  // The objects are deep equal
  return true;
};

export default checkObjectsEqual;
