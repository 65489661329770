// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBarChart from "../components/DataCardBarChart";

// Main function
function EngagementMilestoneValueCard({
  fill,
  engagementMilestoneList,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardBarChart
      comments={
        <>
          <H5>Value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Total value of milestones by status.</p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {engagementMilestoneList.valueOfMilestonesCompleted > 0 ? (
                  <>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      You have milestones that need invoicing.
                    </div>
                  </>
                ) : engagementMilestoneList.valueOfMilestonesInvoiced > 0 ? (
                  <>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      You have pending payments for invoiced milestones.
                    </div>
                  </>
                ) : (
                  <>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      You have no payments pending or completed milestones that
                      need invoicing.
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Not completed",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: engagementMilestoneList.valueOfMilestonesNotCompleted,
        },
        {
          name: "Completed",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: engagementMilestoneList.valueOfMilestonesCompleted,
        },
        {
          name: "Invoiced",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: engagementMilestoneList.valueOfMilestonesInvoiced,
        },
        {
          name: "Paid",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: engagementMilestoneList.valueOfMilestonesPaid,
        },
      ]}
      fill={fill}
      noData={
        !engagementMilestoneList.valueOfMilestonesCompleted &&
        !engagementMilestoneList.valueOfMilestonesInvoiced &&
        !engagementMilestoneList.valueOfMilestonesNotCompleted &&
        !engagementMilestoneList.valueOfMilestonesPaid
      }
      title="Value"
      units={activeOrganisation.currency}
    />
  );
}

export default EngagementMilestoneValueCard;
