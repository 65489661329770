// Constant imports
import {
  ENGAGEMENT_DELIVERY_ISSUE_LIST_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_LIST_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_LIST_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_LIST_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_CREATE_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_DETAILS_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_UPDATE_RESET,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_REQUEST,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_SUCCESS,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_FAIL,
  ENGAGEMENT_DELIVERY_ISSUE_DELETE_RESET,
} from "../constants/engagementDeliveryIssueConstants";

// List all
export const engagementDeliveryIssueListReducer = (
  state = {
    loading: false,
    count: 0,
    engagement_issues: [],
    numberOfIssuesClosed: 0,
    numberOfIssuesOpen: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DELIVERY_ISSUE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ENGAGEMENT_DELIVERY_ISSUE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        engagement_issues: action.payload.engagement_issues,
        numberOfIssuesClosed: action.payload.numberOfIssuesClosed,
        numberOfIssuesOpen: action.payload.numberOfIssuesOpen,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        engagement_issues: [],
        numberOfIssuesClosed: 0,
        numberOfIssuesOpen: 0,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DELIVERY_ISSUE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        engagement_issues: [],
        numberOfIssuesClosed: 0,
        numberOfIssuesOpen: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const engagementDeliveryIssueCreateReducer = (
  state = {
    loading: false,
    engagement_issue: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DELIVERY_ISSUE_CREATE_REQUEST:
      return {
        loading: true,
        engagement_issue: state.engagement_issue,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_CREATE_SUCCESS:
      return {
        loading: false,
        engagement_issue: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_CREATE_FAIL:
      return {
        loading: false,
        engagement_issue: state.engagement_issue,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DELIVERY_ISSUE_CREATE_RESET:
      return {
        loading: false,
        engagement_issue: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const engagementDeliveryIssueDetailsReducer = (
  state = {
    loading: false,
    engagement_issue: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DELIVERY_ISSUE_DETAILS_REQUEST:
      return {
        loading: true,
        engagement_issue: state.engagement_issue,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_DETAILS_SUCCESS:
      return {
        loading: false,
        engagement_issue: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_DETAILS_FAIL:
      return {
        loading: false,
        engagement_issue: state.engagement_issue,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DELIVERY_ISSUE_DETAILS_RESET:
      return {
        loading: false,
        engagement_issue: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const engagementDeliveryIssueUpdateReducer = (
  state = {
    loading: false,
    engagement_issue: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DELIVERY_ISSUE_UPDATE_REQUEST:
      return {
        loading: true,
        engagement_issue: state.engagement_issue,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_UPDATE_SUCCESS:
      return {
        loading: false,
        engagement_issue: action.payload,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_UPDATE_FAIL:
      return {
        loading: false,
        engagement_issue: state.engagement_issue,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DELIVERY_ISSUE_UPDATE_RESET:
      return {
        loading: false,
        engagement_issue: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const engagementDeliveryIssueDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case ENGAGEMENT_DELIVERY_ISSUE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case ENGAGEMENT_DELIVERY_ISSUE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case ENGAGEMENT_DELIVERY_ISSUE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
