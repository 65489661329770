// Library imports
import { Button, Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionFieldLink from "../components/TabSectionFieldLink";

// Main function
function ApplicationInfo({ additionalInfo, applicationData }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            alt="profile"
            src={
              applicationData.profile_photo
                ? applicationData.profile_photo
                : "/images/no_photo.png"
            }
            style={{
              background: "white",
              border: `1px solid ${
                lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
              }`,
              borderRadius: "50%",
              height: "60px",
              margin: "0 10px 0 0",
              objectFit: "contain",
              width: "60px",
            }}
          />

          <H5 style={{ margin: "0 10px 0 0" }}>
            {applicationData ? applicationData.name : undefined}
          </H5>
        </div>

        <Button
          icon="open-application"
          intent="primary"
          minimal
          onClick={() =>
            window.open(
              `/contacts/${applicationData.contact}`,
              "_blank",
              "noopener,noreferrer"
            )
          }
          style={{
            color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
          }}
        />
      </div>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Email</strong>
      </p>

      <div style={{ marginBottom: "10px" }}>
        <TabSectionEmailLink email={applicationData.email} invertColor />
      </div>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Preferred name</strong>
      </p>

      <p>
        {applicationData.preferred_name ? applicationData.preferred_name : "-"}
      </p>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>Phone</strong>
      </p>

      <p>{applicationData.phone ? applicationData.phone : "-"}</p>

      <Divider
        style={{
          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
          margin: "10px 0 10px 0",
        }}
      />

      <p>
        <strong>LinkedIn page</strong>
      </p>

      <div style={{ marginBottom: "10px" }}>
        <TabSectionFieldLink
          invertColor
          url={
            applicationData.linkedin_page
              ? applicationData.linkedin_page
              : undefined
          }
        />
      </div>

      {additionalInfo}
    </div>
  );
}

export default ApplicationInfo;
