// Library imports
import { Card, Classes, Colors, H1, H3, H5, Icon } from "@blueprintjs/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import LinkTag from "../components/LinkTag";
import TabSectionEmailLink from "../components/TabSectionEmailLink";

// User profile
import { listUserProfileDetailsAction } from "../actions/userActions";

// Main function
function AddSeatCTACard() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Load user profile details
  useEffect(() => {
    dispatch(listUserProfileDetailsAction());
    // eslint-disable-next-line
  }, []);

  // JSX UI code
  return (
    <div
      style={{
        margin: "20px auto 20px auto",
        maxWidth: "1600px",
        padding: "0 20px 0 20px",
      }}
    >
      <Card
        style={{
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <div
          style={{
            alignItems: "start",
            display: "flex",
            justifyContent: "start",
            marginRight: "10px",
            padding: "10px",
            width: "25%",
          }}
        >
          <Icon
            color={lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5}
            icon="feed-subscribed"
            size={300}
          />
        </div>

        <div style={{ marginRight: "10px", width: "75%", zIndex: 1 }}>
          <div style={{ margin: "0 auto 0 auto", width: "800px" }}>
            <H1
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
              }}
            >
              Add or request a seat
            </H1>

            <div
              className={Classes.BLOCKQUOTE}
              style={{ margin: "20px 0 10px 0" }}
            >
              <H5 style={{ color: lightMode ? Colors.RED3 : Colors.RED5 }}>
                Your do not have an active seat in this organization.
              </H5>

              <p>
                You need an active seat to use advaise.app. If you had an active
                seat previously, it may have expired. Otherwise, the seat may
                have been assigned to someone else.
              </p>
            </div>

            <H3
              style={{
                color: lightMode ? "#1891AC" : "#D2ECF9",
                marginTop: "20px",
              }}
            >
              How to secure a seat
            </H3>

            <div style={{ margin: "20px 0 10px 0" }}>
              If you are the owner or an administrator of the organization, you
              can add a seat in the organization's page. Go to the Seats section
              under the Subscription tab and use the{" "}
              <Icon
                disabled
                icon="new-person"
                style={{ margin: "0 0 0 5px" }}
              />{" "}
              <strong>Add seats</strong> button. Remember to assign yourself the
              seat. Alternatively, ask someone with access to the organization
              to assign you a seat.
            </div>

            <div style={{ margin: "20px 0 10px 0" }}>
              For more details, please check the{" "}
              <LinkTag
                onClick={() =>
                  window.open(
                    "https://help.advaise.app",
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                label="help docs"
              />
              .
            </div>

            <H5 style={{ margin: "20px 0 0 0" }}>Let us know if we can help</H5>

            <div style={{ margin: "10px 0 20px 0" }}>
              If you are unable to move forward for any reason, we are happy to
              help. You can contact us at{" "}
              <TabSectionEmailLink email="support@advaise.app" />.
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default AddSeatCTACard;
