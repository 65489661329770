// Constant imports
import {
  RANK_LIST_REQUEST,
  RANK_LIST_SUCCESS,
  RANK_LIST_FAIL,
  RANK_LIST_RESET,
  RANK_CREATE_REQUEST,
  RANK_CREATE_SUCCESS,
  RANK_CREATE_FAIL,
  RANK_CREATE_RESET,
  RANK_DETAILS_REQUEST,
  RANK_DETAILS_SUCCESS,
  RANK_DETAILS_FAIL,
  RANK_DETAILS_RESET,
  RANK_UPDATE_REQUEST,
  RANK_UPDATE_SUCCESS,
  RANK_UPDATE_FAIL,
  RANK_UPDATE_RESET,
  RANK_DELETE_REQUEST,
  RANK_DELETE_SUCCESS,
  RANK_DELETE_FAIL,
  RANK_DELETE_RESET,
} from "../constants/rankConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listRanksAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: RANK_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getRanks(params, token);

    dispatch({
      type: RANK_LIST_SUCCESS,

      payload: {
        count: data.count,
        ranks: data.results,
      },
    });
  } catch (error) {
    dispatch({
      type: RANK_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListRanksAction = () => async (dispatch) => {
  dispatch({ type: RANK_LIST_RESET });
};

// Create
export const createRankAction = (rank) => async (dispatch, getState) => {
  try {
    dispatch({ type: RANK_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createRank(token, rank);

    dispatch({
      type: RANK_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RANK_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateRankAction = () => async (dispatch) => {
  dispatch({ type: RANK_CREATE_RESET });
};

// List details
export const listRankDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RANK_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getRank(id, token);

    dispatch({
      type: RANK_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RANK_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListRankDetailsAction = () => async (dispatch) => {
  dispatch({ type: RANK_DETAILS_RESET });
};

// Update
export const updateRankAction = (id, rank) => async (dispatch, getState) => {
  try {
    dispatch({ type: RANK_UPDATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.updateRank(id, token, rank);

    dispatch({
      type: RANK_UPDATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RANK_UPDATE_FAIL,

      payload: error,
    });
  }
};

// Reset update
export const resetUpdateRankAction = () => async (dispatch) => {
  dispatch({ type: RANK_UPDATE_RESET });
};

// Delete
export const deleteRankAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: RANK_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteRank(id, token);

    dispatch({
      type: RANK_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: RANK_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteRankAction = () => async (dispatch) => {
  dispatch({ type: RANK_DELETE_RESET });
};
