// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function ServiceProposalTotalFeesCard({
  fill,
  serviceList,
  serviceQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Fees</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total fees quoted on proposals closed for the selected service(s).
            These are proposals that have been won, lost, or cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {Math.max(
                  serviceList.totalFeesOnProposalsCancelled,
                  serviceList.totalFeesOnProposalsLost,
                  serviceList.totalFeesOnProposalsWon
                ) === serviceList.totalFeesOnProposalsWon ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm focuses on services that are in-demand. Continue
                      to promote your core services.
                    </div>
                  </div>
                ) : Math.max(
                    serviceList.totalFeesOnProposalsCancelled,
                    serviceList.totalFeesOnProposalsLost,
                    serviceList.totalFeesOnProposalsWon
                  ) === serviceList.totalFeesOnProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is investing resources into proposals that
                      ultimately do not materialize when they include the
                      selected service(s). You may need to review your core
                      offering and the value that you bring to clients.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is expending considerable resources on proposals
                      that are ultimately unsuccessful when they include the
                      selected service(s). Collecting feedback from clients may
                      help to uncover specific objections, such as service
                      differentiation or pricing, which you can use to improve
                      your value proposition.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: serviceList.totalFeesOnProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: serviceList.totalFeesOnProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: serviceList.totalFeesOnProposalsCancelled,
        },
      ]}
      fill={fill}
      filter={serviceQuery ? serviceQuery.status : undefined}
      noData={
        !serviceList.totalFeesOnProposalsCancelled &&
        !serviceList.totalFeesOnProposalsLost &&
        !serviceList.totalFeesOnProposalsWon
      }
      title="Fees"
      units={activeOrganisation.currency}
    />
  );
}

export default ServiceProposalTotalFeesCard;
