// Library imports
import { Card, Classes, HTMLTable } from "@blueprintjs/core";
import React from "react";

// Component imports
import NameValue from "../components/NameValue";
import TableText from "../components/TableText";
import TabSectionField from "./TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldLink from "./TabSectionFieldLink";
import TabSectionFieldProgressBar from "./TabSectionFieldProgressBar";

// Main function
function StaffRecruitmentFacts({ facts }) {
  // JSX UI code
  return (
    <Card>
      <TabSectionField label="Opening id" value={facts.opening_id} vertical />

      <TabSectionField
        label="Opening name"
        value={facts.opening_name}
        vertical
      />

      <TabSectionField label="Role" value={facts.role_name} vertical />

      <TabSectionField label="Location" value={facts.location_name} vertical />

      <TabSectionField label="Employment" value={facts.employment} vertical />

      <TabSectionField
        label="Notes"
        value={<TabSectionFieldCard text={facts.opening_notes} />}
        vertical
      />

      <TabSectionField
        label="Candidate"
        value={
          <NameValue
            image={facts.candidate_profile_photo}
            text={facts.candidate_name}
          />
        }
        vertical
      />

      <TabSectionField
        label="Resume"
        value={<TabSectionFieldLink shorten url={facts.resume} />}
        vertical
      />

      <TabSectionField
        label="Interviewer"
        value={
          <NameValue
            image={facts.interviewer_profile_photo}
            text={facts.interviewer_name}
          />
        }
        vertical
      />

      <TabSectionField
        label="Competencies"
        value={
          facts.evaluation_ratings.length > 0 ? (
            <Card style={{ padding: "0" }}>
              <HTMLTable bordered striped style={{ width: "100%" }}>
                <tbody>
                  {facts.evaluation_ratings.map((rating, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ verticalAlign: "middle", width: "40%" }}>
                          <TableText
                            info={rating.competency_description}
                            text={rating.competency_name}
                          />
                        </td>

                        <td style={{ verticalAlign: "middle", width: "30%" }}>
                          <TabSectionFieldProgressBar
                            intent="primary"
                            rating={rating.required_level / 5}
                            width="100%"
                          />

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ marginTop: "5px" }}
                          >
                            {rating.rating > 4
                              ? "Expert"
                              : rating.rating > 2
                              ? "Intermediate"
                              : "Beginner"}
                          </div>
                        </td>

                        <td style={{ verticalAlign: "middle", width: "30%" }}>
                          <TabSectionFieldProgressBar
                            intent="success"
                            rating={rating.rating / 5}
                            width="100%"
                          />

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ marginTop: "5px" }}
                          >
                            {rating.rating > 4
                              ? "Expert"
                              : rating.rating > 2
                              ? "Intermediate"
                              : "Beginner"}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>

                <thead>
                  <tr>
                    <th>Competency</th>

                    <th>Required level</th>

                    <th>Rating</th>
                  </tr>
                </thead>
              </HTMLTable>
            </Card>
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />

      <TabSectionField
        label="Evaluation summary"
        value={<TabSectionFieldCard text={facts.evaluation_notes} />}
        vertical
      />

      <TabSectionField
        label="References"
        value={<TabSectionFieldCard text={facts.references_comments} />}
        vertical
      />

      <TabSectionField
        label="Police check"
        value={<TabSectionFieldCard text={facts.police_check_comments} />}
        vertical
      />

      <TabSectionField
        label="Remuneration"
        value={<TabSectionFieldCard text={facts.remuneration_comments} />}
        vertical
      />

      <TabSectionField
        bottom
        label="Values alignment"
        value={<TabSectionFieldCard text={facts.values_alignment_comments} />}
        vertical
      />
    </Card>
  );
}

export default StaffRecruitmentFacts;
