// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Need question
import { listNeedQuestionsAction } from "../actions/needQuestionActions";

// Main function
function NeedQuestionInfo({
  additionalInfo,
  needQuestionData,
  needQuestionId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Need question
  const needQuestionList = useSelector((state) => state.needQuestionList);

  // Refresh need question data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [needQuestion, setNeedQuestion] = useState({});

  useEffect(() => {
    if (needQuestionData) {
      setNeedQuestion(needQuestionData);

      setLoading(false);
    }
  }, [needQuestionData]);

  useEffect(() => {
    if (!needQuestionData && !needQuestionList.loading) {
      if (needQuestionList.success) {
        const thisQuestion = needQuestionList.need_questions.filter(
          (question) => question.id === needQuestionId
        );

        if (thisQuestion.length > 0) {
          setNeedQuestion(thisQuestion[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listNeedQuestionsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (needQuestionList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [needQuestionList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{needQuestion.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Question</strong>
          </p>

          <p>{needQuestion.question}</p>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Notes</strong>
          </p>

          <p>{needQuestion.notes}</p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default NeedQuestionInfo;
