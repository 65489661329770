// Constant imports
import {
  CHECKOUT_CREATE_REQUEST,
  CHECKOUT_CREATE_SUCCESS,
  CHECKOUT_CREATE_FAIL,
  CHECKOUT_CREATE_RESET,
  CUSTOMER_PORTAL_CREATE_REQUEST,
  CUSTOMER_PORTAL_CREATE_SUCCESS,
  CUSTOMER_PORTAL_CREATE_FAIL,
  CUSTOMER_PORTAL_CREATE_RESET,
} from "../constants/paymentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// Create checkout
export const createCheckoutAction = (booking) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECKOUT_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createCheckout(token, booking);

    dispatch({
      type: CHECKOUT_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHECKOUT_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create checkout
export const resetCreateCheckoutAction = () => async (dispatch) => {
  dispatch({ type: CHECKOUT_CREATE_RESET });
};

// Create customer portal
export const createCustomerPortalAction =
  (booking) => async (dispatch, getState) => {
    try {
      dispatch({ type: CUSTOMER_PORTAL_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createCustomerPortal(
        token,
        booking
      );

      dispatch({
        type: CUSTOMER_PORTAL_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CUSTOMER_PORTAL_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create customer portal
export const resetCreateCustomerPortalAction = () => async (dispatch) => {
  dispatch({ type: CUSTOMER_PORTAL_CREATE_RESET });
};
