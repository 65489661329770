export const STAFF_LIST_REQUEST = "STAFF_LIST_REQUEST";
export const STAFF_LIST_SUCCESS = "STAFF_LIST_SUCCESS";
export const STAFF_LIST_FAIL = "STAFF_LIST_FAIL";
export const STAFF_LIST_RESET = "STAFF_LIST_RESET";

export const STAFF_DETAILS_REQUEST = "STAFF_DETAILS_REQUEST";
export const STAFF_DETAILS_SUCCESS = "STAFF_DETAILS_SUCCESS";
export const STAFF_DETAILS_FAIL = "STAFF_DETAILS_FAIL";
export const STAFF_DETAILS_RESET = "STAFF_DETAILS_RESET";

export const STAFF_UPDATE_REQUEST = "STAFF_UPDATE_REQUEST";
export const STAFF_UPDATE_SUCCESS = "STAFF_UPDATE_SUCCESS";
export const STAFF_UPDATE_FAIL = "STAFF_UPDATE_FAIL";
export const STAFF_UPDATE_RESET = "STAFF_UPDATE_RESET";

export const STAFF_CALCULATE_ROLE_FIT_SCORE_REQUEST =
  "STAFF_CALCULATE_ROLE_FIT_SCORE_REQUEST";
export const STAFF_CALCULATE_ROLE_FIT_SCORE_SUCCESS =
  "STAFF_CALCULATE_ROLE_FIT_SCORE_SUCCESS";
export const STAFF_CALCULATE_ROLE_FIT_SCORE_FAIL =
  "STAFF_CALCULATE_ROLE_FIT_SCORE_FAIL";
export const STAFF_CALCULATE_ROLE_FIT_SCORE_RESET =
  "STAFF_CALCULATE_ROLE_FIT_SCORE_RESET";

export const STAFF_GENERATE_UTILISATION_ANALYSIS_REQUEST =
  "STAFF_GENERATE_UTILISATION_ANALYSIS_REQUEST";
export const STAFF_GENERATE_UTILISATION_ANALYSIS_SUCCESS =
  "STAFF_GENERATE_UTILISATION_ANALYSIS_SUCCESS";
export const STAFF_GENERATE_UTILISATION_ANALYSIS_FAIL =
  "STAFF_GENERATE_UTILISATION_ANALYSIS_FAIL";
export const STAFF_GENERATE_UTILISATION_ANALYSIS_RESET =
  "STAFF_GENERATE_UTILISATION_ANALYSIS_RESET";
