// Constant imports
import {
  TEXT_EXTRACT_DATA_REQUEST,
  TEXT_EXTRACT_DATA_SUCCESS,
  TEXT_EXTRACT_DATA_FAIL,
  TEXT_EXTRACT_DATA_RESET,
  TEXT_EXTRACT_DATA_FROM_FILE_REQUEST,
  TEXT_EXTRACT_DATA_FROM_FILE_SUCCESS,
  TEXT_EXTRACT_DATA_FROM_FILE_FAIL,
  TEXT_EXTRACT_DATA_FROM_FILE_RESET,
  TEXT_REWRITE_FIELD_REQUEST,
  TEXT_REWRITE_FIELD_SUCCESS,
  TEXT_REWRITE_FIELD_FAIL,
  TEXT_REWRITE_FIELD_RESET,
} from "../constants/textConstants";

// Extract data
export const textExtractDataReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TEXT_EXTRACT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TEXT_EXTRACT_DATA_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case TEXT_EXTRACT_DATA_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case TEXT_EXTRACT_DATA_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};

// Extract data from file
export const textExtractDataFromFileReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TEXT_EXTRACT_DATA_FROM_FILE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TEXT_EXTRACT_DATA_FROM_FILE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case TEXT_EXTRACT_DATA_FROM_FILE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case TEXT_EXTRACT_DATA_FROM_FILE_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};

// Rewrite field
export const textRewriteFieldReducer = (
  state = { loading: false, result: "", success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TEXT_REWRITE_FIELD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TEXT_REWRITE_FIELD_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case TEXT_REWRITE_FIELD_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case TEXT_REWRITE_FIELD_RESET:
      return { loading: false, result: "", success: false, error: "" };

    default:
      return state;
  }
};
