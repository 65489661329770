// Constant imports
import {
  CASE_STUDY_PERMISSION_LIST_REQUEST,
  CASE_STUDY_PERMISSION_LIST_SUCCESS,
  CASE_STUDY_PERMISSION_LIST_FAIL,
  CASE_STUDY_PERMISSION_LIST_RESET,
  CASE_STUDY_PERMISSION_CREATE_REQUEST,
  CASE_STUDY_PERMISSION_CREATE_SUCCESS,
  CASE_STUDY_PERMISSION_CREATE_FAIL,
  CASE_STUDY_PERMISSION_CREATE_RESET,
  CASE_STUDY_PERMISSION_DETAILS_REQUEST,
  CASE_STUDY_PERMISSION_DETAILS_SUCCESS,
  CASE_STUDY_PERMISSION_DETAILS_FAIL,
  CASE_STUDY_PERMISSION_DETAILS_RESET,
  CASE_STUDY_PERMISSION_UPDATE_REQUEST,
  CASE_STUDY_PERMISSION_UPDATE_SUCCESS,
  CASE_STUDY_PERMISSION_UPDATE_FAIL,
  CASE_STUDY_PERMISSION_UPDATE_RESET,
  CASE_STUDY_PERMISSION_DELETE_REQUEST,
  CASE_STUDY_PERMISSION_DELETE_SUCCESS,
  CASE_STUDY_PERMISSION_DELETE_FAIL,
  CASE_STUDY_PERMISSION_DELETE_RESET,
} from "../constants/caseStudyPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listCaseStudyPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCaseStudyPermissions(
        params,
        token
      );

      dispatch({
        type: CASE_STUDY_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          case_study_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListCaseStudyPermissionsAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_PERMISSION_LIST_RESET });
};

// Create
export const createCaseStudyPermissionAction =
  (case_study_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createCaseStudyPermission(
        token,
        case_study_permission
      );

      dispatch({
        type: CASE_STUDY_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateCaseStudyPermissionAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_PERMISSION_CREATE_RESET });
};

// List details
export const listCaseStudyPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getCaseStudyPermission(
        id,
        token
      );

      dispatch({
        type: CASE_STUDY_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListCaseStudyPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: CASE_STUDY_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateCaseStudyPermissionAction =
  (id, case_study_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateCaseStudyPermission(
        id,
        token,
        case_study_permission
      );

      dispatch({
        type: CASE_STUDY_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateCaseStudyPermissionAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteCaseStudyPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CASE_STUDY_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteCaseStudyPermission(
        id,
        token
      );

      dispatch({
        type: CASE_STUDY_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CASE_STUDY_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteCaseStudyPermissionAction = () => async (dispatch) => {
  dispatch({ type: CASE_STUDY_PERMISSION_DELETE_RESET });
};
