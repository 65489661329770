// Constant imports
import {
  OPENING_LIST_REQUEST,
  OPENING_LIST_SUCCESS,
  OPENING_LIST_FAIL,
  OPENING_LIST_RESET,
  OPENING_CREATE_REQUEST,
  OPENING_CREATE_SUCCESS,
  OPENING_CREATE_FAIL,
  OPENING_CREATE_RESET,
  OPENING_DETAILS_REQUEST,
  OPENING_DETAILS_SUCCESS,
  OPENING_DETAILS_FAIL,
  OPENING_DETAILS_RESET,
  OPENING_UPDATE_REQUEST,
  OPENING_UPDATE_SUCCESS,
  OPENING_UPDATE_FAIL,
  OPENING_UPDATE_RESET,
  OPENING_DELETE_REQUEST,
  OPENING_DELETE_SUCCESS,
  OPENING_DELETE_FAIL,
  OPENING_DELETE_RESET,
  OPENING_REFERRER_LIST_REQUEST,
  OPENING_REFERRER_LIST_SUCCESS,
  OPENING_REFERRER_LIST_FAIL,
  OPENING_REFERRER_LIST_RESET,
  OPENING_DOWNLOAD_REQUEST,
  OPENING_DOWNLOAD_SUCCESS,
  OPENING_DOWNLOAD_FAIL,
  OPENING_DOWNLOAD_RESET,
} from "../constants/openingConstants";

// List all
export const openingListReducer = (
  state = {
    loading: false,
    count: 0,
    openings: [],
    averageAge: 0,
    numberOfOpeningsFilled: 0,
    numberOfOpeningsInterviewing: 0,
    numberOfOpeningsOnHold: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPENING_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        openings: action.payload.openings,
        averageAge: action.payload.averageAge,
        numberOfOpeningsFilled: action.payload.numberOfOpeningsFilled,
        numberOfOpeningsInterviewing:
          action.payload.numberOfOpeningsInterviewing,
        numberOfOpeningsOnHold: action.payload.numberOfOpeningsOnHold,
        success: true,
        error: "",
      };

    case OPENING_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        openings: [],
        averageAge: 0,
        numberOfOpeningsFilled: 0,
        numberOfOpeningsInterviewing: 0,
        numberOfOpeningsOnHold: 0,
        success: false,
        error: action.payload,
      };

    case OPENING_LIST_RESET:
      return {
        loading: false,
        count: 0,
        openings: [],
        averageAge: 0,
        numberOfOpeningsFilled: 0,
        numberOfOpeningsInterviewing: 0,
        numberOfOpeningsOnHold: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const openingCreateReducer = (
  state = { loading: false, opening: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case OPENING_CREATE_REQUEST:
      return {
        loading: true,
        opening: state.opening,
        success: false,
        error: "",
      };

    case OPENING_CREATE_SUCCESS:
      return {
        loading: false,
        opening: action.payload,
        success: true,
        error: "",
      };

    case OPENING_CREATE_FAIL:
      return {
        loading: false,
        opening: state.opening,
        success: false,
        error: action.payload,
      };

    case OPENING_CREATE_RESET:
      return { loading: false, opening: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const openingDetailsReducer = (
  state = { loading: false, opening: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case OPENING_DETAILS_REQUEST:
      return {
        loading: true,
        opening: state.opening,
        success: false,
        error: "",
      };

    case OPENING_DETAILS_SUCCESS:
      return {
        loading: false,
        opening: action.payload,
        success: true,
        error: "",
      };

    case OPENING_DETAILS_FAIL:
      return {
        loading: false,
        opening: state.opening,
        success: false,
        error: action.payload,
      };

    case OPENING_DETAILS_RESET:
      return { loading: false, opening: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const openingUpdateReducer = (
  state = { loading: false, opening: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case OPENING_UPDATE_REQUEST:
      return {
        loading: true,
        opening: state.opening,
        success: false,
        error: "",
      };

    case OPENING_UPDATE_SUCCESS:
      return {
        loading: false,
        opening: action.payload,
        success: true,
        error: "",
      };

    case OPENING_UPDATE_FAIL:
      return {
        loading: false,
        opening: state.opening,
        success: false,
        error: action.payload,
      };

    case OPENING_UPDATE_RESET:
      return { loading: false, opening: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const openingDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case OPENING_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case OPENING_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case OPENING_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case OPENING_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// List referrers
export const openingReferrerListReducer = (
  state = { loading: false, count: 0, openings: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case OPENING_REFERRER_LIST_REQUEST:
      return {
        loading: true,
        count: 0,
        openings: [],
        success: false,
        error: "",
      };

    case OPENING_REFERRER_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        openings: action.payload.openings,
        success: true,
        error: "",
      };

    case OPENING_REFERRER_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        openings: [],
        success: false,
        error: action.payload,
      };

    case OPENING_REFERRER_LIST_RESET:
      return {
        loading: false,
        count: 0,
        openings: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Download
export const openingDownloadReducer = (
  state = { loading: false, result: [], success: false, error: "" },
  action
) => {
  switch (action.type) {
    case OPENING_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPENING_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case OPENING_DOWNLOAD_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case OPENING_DOWNLOAD_RESET:
      return { loading: false, result: [], success: false, error: "" };

    default:
      return state;
  }
};
