// Main function
function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

const convertMinutesToHoursAndMinutes = (totalMinutes) => {
  const minutes = Math.round(totalMinutes) % 60;

  const hours = Math.floor(Math.round(totalMinutes) / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

export default convertMinutesToHoursAndMinutes;
