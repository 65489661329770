// Constant imports
import {
  SERVICE_LIST_REQUEST,
  SERVICE_LIST_SUCCESS,
  SERVICE_LIST_FAIL,
  SERVICE_LIST_RESET,
  SERVICE_CREATE_REQUEST,
  SERVICE_CREATE_SUCCESS,
  SERVICE_CREATE_FAIL,
  SERVICE_CREATE_RESET,
  SERVICE_DETAILS_REQUEST,
  SERVICE_DETAILS_SUCCESS,
  SERVICE_DETAILS_FAIL,
  SERVICE_DETAILS_RESET,
  SERVICE_UPDATE_REQUEST,
  SERVICE_UPDATE_SUCCESS,
  SERVICE_UPDATE_FAIL,
  SERVICE_UPDATE_RESET,
  SERVICE_DELETE_REQUEST,
  SERVICE_DELETE_SUCCESS,
  SERVICE_DELETE_FAIL,
  SERVICE_DELETE_RESET,
} from "../constants/serviceConstants";

// List all
export const serviceListReducer = (
  state = {
    loading: false,
    count: 0,
    services: [],
    averageMarginPercentageOnProposalsCancelled: 0,
    averageMarginPercentageOnProposalsLost: 0,
    averageMarginPercentageOnProposalsWon: 0,
    totalFeesOnProposalsCancelled: 0,
    totalFeesOnProposalsInProgress: 0,
    totalFeesOnProposalsLost: 0,
    totalFeesOnProposalsQualifying: 0,
    totalFeesOnProposalsSubmitted: 0,
    totalFeesOnProposalsWon: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        services: action.payload.services,
        averageMarginPercentageOnProposalsCancelled:
          action.payload.averageMarginPercentageOnProposalsCancelled,
        averageMarginPercentageOnProposalsLost:
          action.payload.averageMarginPercentageOnProposalsLost,
        averageMarginPercentageOnProposalsWon:
          action.payload.averageMarginPercentageOnProposalsWon,
        totalFeesOnProposalsCancelled:
          action.payload.totalFeesOnProposalsCancelled,
        totalFeesOnProposalsInProgress:
          action.payload.totalFeesOnProposalsInProgress,
        totalFeesOnProposalsLost: action.payload.totalFeesOnProposalsLost,
        totalFeesOnProposalsQualifying:
          action.payload.totalFeesOnProposalsQualifying,
        totalFeesOnProposalsSubmitted:
          action.payload.totalFeesOnProposalsSubmitted,
        totalFeesOnProposalsWon: action.payload.totalFeesOnProposalsWon,
        success: true,
        error: "",
      };

    case SERVICE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        services: [],
        averageMarginPercentageOnProposalsCancelled: 0,
        averageMarginPercentageOnProposalsLost: 0,
        averageMarginPercentageOnProposalsWon: 0,
        totalFeesOnProposalsCancelled: 0,
        totalFeesOnProposalsInProgress: 0,
        totalFeesOnProposalsLost: 0,
        totalFeesOnProposalsQualifying: 0,
        totalFeesOnProposalsSubmitted: 0,
        totalFeesOnProposalsWon: 0,
        success: false,
        error: action.payload,
      };

    case SERVICE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        services: [],
        averageMarginPercentageOnProposalsCancelled: 0,
        averageMarginPercentageOnProposalsLost: 0,
        averageMarginPercentageOnProposalsWon: 0,
        totalFeesOnProposalsCancelled: 0,
        totalFeesOnProposalsInProgress: 0,
        totalFeesOnProposalsLost: 0,
        totalFeesOnProposalsQualifying: 0,
        totalFeesOnProposalsSubmitted: 0,
        totalFeesOnProposalsWon: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const serviceCreateReducer = (
  state = { loading: false, service: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_CREATE_REQUEST:
      return {
        loading: true,
        service: state.service,
        success: false,
        error: "",
      };

    case SERVICE_CREATE_SUCCESS:
      return {
        loading: false,
        service: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_CREATE_FAIL:
      return {
        loading: false,
        service: state.service,
        success: false,
        error: action.payload,
      };

    case SERVICE_CREATE_RESET:
      return { loading: false, service: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const serviceDetailsReducer = (
  state = { loading: false, service: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_DETAILS_REQUEST:
      return {
        loading: true,
        service: state.service,
        success: false,
        error: "",
      };

    case SERVICE_DETAILS_SUCCESS:
      return {
        loading: false,
        service: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_DETAILS_FAIL:
      return {
        loading: false,
        service: state.service,
        success: false,
        error: action.payload,
      };

    case SERVICE_DETAILS_RESET:
      return { loading: false, service: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const serviceUpdateReducer = (
  state = { loading: false, service: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_UPDATE_REQUEST:
      return {
        loading: true,
        service: state.service,
        success: false,
        error: "",
      };

    case SERVICE_UPDATE_SUCCESS:
      return {
        loading: false,
        service: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_UPDATE_FAIL:
      return {
        loading: false,
        service: state.service,
        success: false,
        error: action.payload,
      };

    case SERVICE_UPDATE_RESET:
      return { loading: false, service: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const serviceDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case SERVICE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case SERVICE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case SERVICE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
