// Library imports
import {
  Button,
  Card,
  ControlGroup,
  Divider,
  InputGroup,
  NonIdealState,
  NonIdealStateIconSize,
  Text,
} from "@blueprintjs/core";
import React, { useState } from "react";

// Main function
function EditableList({
  disabled,
  icon,
  items,
  placeholder,
  setValueObject,
  setValueVariable,
}) {
  const [isEmpty, setIsEmpty] = useState(true);
  const [newItem, setNewItem] = useState("");

  // JSX UI code
  return (
    <div>
      {/* No items */}
      {items.length === 0 && (
        <div style={{ marginBottom: "20px" }}>
          <NonIdealState
            description="Add tasks to the booking code."
            icon="gantt-chart"
            iconSize={NonIdealStateIconSize.SMALL}
            title="No tasks defined"
          />
        </div>
      )}

      {/* Items */}
      {items.map((item, i) => {
        return (
          <Card
            key={i}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
              padding: "5px 10px 5px 10px",
            }}
          >
            <Text ellipsize>{item}</Text>

            <Button
              icon="trash"
              minimal
              onClick={() => {
                let newItems = Object.assign([], items);

                newItems.splice(i, 1);

                setValueObject((prevState) => {
                  return {
                    ...prevState,
                    [setValueVariable]: newItems,
                  };
                });
              }}
              style={{ marginLeft: "10px" }}
            />
          </Card>
        );
      })}

      <Divider style={{ margin: "15px 0 15px 0" }} />

      <ControlGroup>
        <InputGroup
          disabled={disabled}
          fill
          leftIcon={icon}
          onChange={(e) => {
            !e.target.value ? setIsEmpty(true) : setIsEmpty(false);

            setNewItem(e.target.value);
          }}
          placeholder={placeholder}
          value={newItem}
        />

        <Button
          disabled={disabled || isEmpty}
          icon="add"
          intent="primary"
          onClick={() => {
            let newItems = Object.assign([], items);

            newItems.push(newItem);

            setValueObject((prevState) => {
              return {
                ...prevState,
                [setValueVariable]: newItems,
              };
            });

            setIsEmpty(true);
            setNewItem("");
          }}
          text="Add"
        />
      </ControlGroup>
    </div>
  );
}

export default EditableList;
