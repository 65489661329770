// Constant imports
import {
  USER_EMAIL_CONFIRM_REQUEST,
  USER_EMAIL_CONFIRM_SUCCESS,
  USER_EMAIL_CONFIRM_FAIL,
  USER_EMAIL_CONFIRM_RESET,
  USER_EMAIL_CONFIRM_RESEND_REQUEST,
  USER_EMAIL_CONFIRM_RESEND_SUCCESS,
  USER_EMAIL_CONFIRM_RESEND_FAIL,
  USER_EMAIL_CONFIRM_RESEND_RESET,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_RESET,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_PASSWORD_CHANGE_REQUEST,
  USER_PASSWORD_CHANGE_SUCCESS,
  USER_PASSWORD_CHANGE_FAIL,
  USER_PASSWORD_CHANGE_RESET,
  USER_PASSWORD_RESET_REQUEST,
  USER_PASSWORD_RESET_SUCCESS,
  USER_PASSWORD_RESET_FAIL,
  USER_PASSWORD_RESET_RESET,
  USER_PASSWORD_RESET_CONFIRM_REQUEST,
  USER_PASSWORD_RESET_CONFIRM_SUCCESS,
  USER_PASSWORD_RESET_CONFIRM_FAIL,
  USER_PASSWORD_RESET_CONFIRM_RESET,
  USER_PROFILE_DETAILS_REQUEST,
  USER_PROFILE_DETAILS_SUCCESS,
  USER_PROFILE_DETAILS_FAIL,
  USER_PROFILE_DETAILS_RESET,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_FAIL,
  USER_PROFILE_UPDATE_RESET,
} from "../constants/userConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List user profile details
export const listUserProfileDetailsAction =
  () => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_PROFILE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getUserProfile(token);

      dispatch({
        type: USER_PROFILE_DETAILS_SUCCESS,

        payload: data.results[0],
      });
    } catch (error) {
      dispatch({
        type: USER_PROFILE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list user profile details
export const resetListUserProfileDetailsAction = () => async (dispatch) => {
  dispatch({ type: USER_PROFILE_DETAILS_RESET });
};

// Login
export const loginAction = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const { data } = await cortixDataService.login(credentials);

    dispatch({
      type: USER_LOGIN_SUCCESS,

      payload: { token: data.key, user: credentials.email },
    });

    localStorage.setItem("token", data.key);

    localStorage.setItem("user", credentials.email);
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,

      payload: error,
    });
  }
};

// Logout
export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("activeOrganisationCurrency");
  localStorage.removeItem("activeOrganisationId");
  localStorage.removeItem("activeOrganisationName");
  localStorage.removeItem("activeOrganisationOwner");
  localStorage.removeItem("lightMode");
  localStorage.removeItem("token");
  localStorage.removeItem("user");

  dispatch({ type: USER_LOGOUT });
};

// Change password
export const changePasswordAction =
  (credentials) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_PASSWORD_CHANGE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.changePassword(
        token,
        credentials
      );

      dispatch({
        type: USER_PASSWORD_CHANGE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PASSWORD_CHANGE_FAIL,

        payload: error,
      });
    }
  };

// Reset change password
export const resetChangePasswordAction = () => async (dispatch) => {
  dispatch({ type: USER_PASSWORD_CHANGE_RESET });
};

// Confirm email
export const confirmEmailAction = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: USER_EMAIL_CONFIRM_REQUEST });

    await cortixDataService.confirmEmail(credentials);

    dispatch({
      type: USER_EMAIL_CONFIRM_SUCCESS,

      payload: { user: credentials.email },
    });
  } catch (error) {
    dispatch({
      type: USER_EMAIL_CONFIRM_FAIL,

      payload: error,
    });
  }
};

// Reset confirm email
export const resetConfirmEmailAction = () => async (dispatch) => {
  dispatch({ type: USER_EMAIL_CONFIRM_RESET });
};

// Confirm email resend
export const confirmEmailResendAction = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: USER_EMAIL_CONFIRM_RESEND_REQUEST });

    const { data } = await cortixDataService.confirmEmailResend(credentials);

    dispatch({
      type: USER_EMAIL_CONFIRM_RESEND_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_EMAIL_CONFIRM_RESEND_FAIL,

      payload: error,
    });
  }
};

// Reset confirm email resend
export const resetConfirmEmailResendAction = () => async (dispatch) => {
  dispatch({ type: USER_EMAIL_CONFIRM_RESEND_RESET });
};

// Reset password
export const resetPasswordAction = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_RESET_REQUEST });

    const { data } = await cortixDataService.resetPassword(credentials);

    dispatch({
      type: USER_PASSWORD_RESET_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RESET_FAIL,

      payload: error,
    });
  }
};

// Reset reset password
export const resetResetPasswordAction = () => async (dispatch) => {
  dispatch({ type: USER_PASSWORD_RESET_RESET });
};

// Reset password confirm
export const resetPasswordConfirmAction = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: USER_PASSWORD_RESET_CONFIRM_REQUEST });

    const { data } = await cortixDataService.resetPasswordConfirm(credentials);

    dispatch({
      type: USER_PASSWORD_RESET_CONFIRM_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_PASSWORD_RESET_CONFIRM_FAIL,

      payload: error,
    });
  }
};

// Reset reset password confirm
export const resetResetPasswordConfirmAction = () => async (dispatch) => {
  dispatch({ type: USER_PASSWORD_RESET_CONFIRM_RESET });
};

// Signup
export const signupAction = (credentials) => async (dispatch) => {
  try {
    dispatch({ type: USER_SIGNUP_REQUEST });

    await cortixDataService.signup(credentials);

    dispatch({
      type: USER_SIGNUP_SUCCESS,

      payload: { user: credentials.email },
    });
  } catch (error) {
    dispatch({
      type: USER_SIGNUP_FAIL,

      payload: error,
    });
  }
};

// Reset signup
export const resetSignupAction = () => async (dispatch) => {
  dispatch({ type: USER_SIGNUP_RESET });
};

// Update user profile
export const updateUserProfileAction =
  (id, user_profile) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_PROFILE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateUserProfile(
        id,
        token,
        user_profile
      );

      dispatch({
        type: USER_PROFILE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_PROFILE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update user profile
export const resetUpdateUserProfileAction = () => async (dispatch) => {
  dispatch({ type: USER_PROFILE_UPDATE_RESET });
};
