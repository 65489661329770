// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardRadarChart from "../components/DataCardRadarChart";

// Main function
function ProposalCompetitorCard({ competitor, onClick }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardRadarChart
      comments={
        <>
          <H5>{competitor.competitor}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Notes</strong>
          </p>

          <p>
            {competitor.notes ? (
              competitor.notes
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>
        </>
      }
      data={[
        {
          name: "Commercials",
          value: competitor.commercials,
        },
        {
          name: "Partnerships",
          value: competitor.partnerships,
        },
        {
          name: "Price",
          value: competitor.price,
        },
        {
          name: "References",
          value: competitor.references,
        },
        {
          name: "Relationships",
          value: competitor.relationships,
        },
        {
          name: "Solution",
          value: competitor.solution,
        },
        {
          name: "Team",
          value: competitor.team,
        },
      ]}
      interactive
      noData={!competitor}
      onClick={onClick}
      title={competitor.competitor}
      units="Rating"
      width={350}
    />
  );
}

export default ProposalCompetitorCard;
