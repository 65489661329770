// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function ProposalTotalValueCard({
  fill,
  proposalList,
  proposalQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Total value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Total value of proposals closed. These are proposals that have been
            won, lost, or cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {Math.max(
                  proposalList.totalValueOfProposalsCancelled,
                  proposalList.totalValueOfProposalsLost,
                  proposalList.totalValueOfProposalsWon
                ) === proposalList.totalValueOfProposalsWon ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm has a proper focus on opportunities. Continue to
                      invest resources into proposals with high value and
                      probability of success.
                    </div>
                  </div>
                ) : Math.max(
                    proposalList.totalValueOfProposalsCancelled,
                    proposalList.totalValueOfProposalsLost,
                    proposalList.totalValueOfProposalsWon
                  ) === proposalList.totalValueOfProposalsCancelled ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is investing resources into proposals that
                      ultimately do not materialize. Implementing a more
                      rigorous qualification process may prove beneficial in
                      identifying and pursuing only those opportunities that are
                      genuine and worth pursuing.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm is expending considerable resources on proposals
                      that are ultimately unsuccessful. Conducting a review of
                      lessons learned may reveal patterns that can be used to
                      identify potential disqualifiers early in the process and
                      avoid investing in proposals that are unlikely to succeed.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: proposalList.totalValueOfProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: proposalList.totalValueOfProposalsLost,
        },
        {
          name: "Cancelled",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: proposalList.totalValueOfProposalsCancelled,
        },
      ]}
      fill={fill}
      filter={proposalQuery ? proposalQuery.status : undefined}
      noData={
        !proposalList.totalValueOfProposalsCancelled &&
        !proposalList.totalValueOfProposalsLost &&
        !proposalList.totalValueOfProposalsWon
      }
      title="Total value"
      units={activeOrganisation.currency}
    />
  );
}

export default ProposalTotalValueCard;
