// Constant imports
import {
  KNOWLEDGE_BASE_DOCUMENT_LIST_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_LIST_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_LIST_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_LIST_RESET,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_CREATE_RESET,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_DETAILS_RESET,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_UPDATE_RESET,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_REQUEST,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_SUCCESS,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_FAIL,
  KNOWLEDGE_BASE_DOCUMENT_DELETE_RESET,
} from "../constants/knowledgeBaseDocumentConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listKnowledgeBaseDocumentsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getKnowledgeBaseDocuments(
        params,
        token
      );

      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_LIST_SUCCESS,

        payload: { count: data.count, knowledge_base_documents: data.results },
      });
    } catch (error) {
      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListKnowledgeBaseDocumentsAction = () => async (dispatch) => {
  dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_LIST_RESET });
};

// Create
export const createKnowledgeBaseDocumentAction =
  (knowledgeBaseDocument) => async (dispatch, getState) => {
    try {
      dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createKnowledgeBaseDocument(
        token,
        knowledgeBaseDocument
      );

      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateKnowledgeBaseDocumentAction =
  () => async (dispatch) => {
    dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_CREATE_RESET });
  };

// List details
export const listKnowledgeBaseDocumentDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getKnowledgeBaseDocument(
        id,
        token
      );

      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListKnowledgeBaseDocumentDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_DETAILS_RESET });
  };

// Update
export const updateKnowledgeBaseDocumentAction =
  (id, knowledgeBaseDocument) => async (dispatch, getState) => {
    try {
      dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateKnowledgeBaseDocument(
        id,
        token,
        knowledgeBaseDocument
      );

      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateKnowledgeBaseDocumentAction =
  () => async (dispatch) => {
    dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_UPDATE_RESET });
  };

// Delete
export const deleteKnowledgeBaseDocumentAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteKnowledgeBaseDocument(
        id,
        token
      );

      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: KNOWLEDGE_BASE_DOCUMENT_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteKnowledgeBaseDocumentAction =
  () => async (dispatch) => {
    dispatch({ type: KNOWLEDGE_BASE_DOCUMENT_DELETE_RESET });
  };
