// Constant imports
import {
  INSIGHT_LIST_REQUEST,
  INSIGHT_LIST_SUCCESS,
  INSIGHT_LIST_FAIL,
  INSIGHT_LIST_RESET,
  INSIGHT_CREATE_REQUEST,
  INSIGHT_CREATE_SUCCESS,
  INSIGHT_CREATE_FAIL,
  INSIGHT_CREATE_RESET,
  INSIGHT_DETAILS_REQUEST,
  INSIGHT_DETAILS_SUCCESS,
  INSIGHT_DETAILS_FAIL,
  INSIGHT_DETAILS_RESET,
  INSIGHT_UPDATE_REQUEST,
  INSIGHT_UPDATE_SUCCESS,
  INSIGHT_UPDATE_FAIL,
  INSIGHT_UPDATE_RESET,
  INSIGHT_DELETE_REQUEST,
  INSIGHT_DELETE_SUCCESS,
  INSIGHT_DELETE_FAIL,
  INSIGHT_DELETE_RESET,
} from "../constants/insightConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInsightsAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: INSIGHT_LIST_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getInsights(params, token);

    dispatch({
      type: INSIGHT_LIST_SUCCESS,

      payload: { count: data.count, insights: data.results },
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_LIST_FAIL,

      payload: error,
    });
  }
};

// Reset list all
export const resetListInsightsAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_LIST_RESET });
};

// Create
export const createInsightAction = (insight) => async (dispatch, getState) => {
  try {
    dispatch({ type: INSIGHT_CREATE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.createInsight(token, insight);

    dispatch({
      type: INSIGHT_CREATE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_CREATE_FAIL,

      payload: error,
    });
  }
};

// Reset create
export const resetCreateInsightAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_CREATE_RESET });
};

// List details
export const listInsightDetailsAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INSIGHT_DETAILS_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.getInsight(id, token);

    dispatch({
      type: INSIGHT_DETAILS_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_DETAILS_FAIL,

      payload: error,
    });
  }
};

// Reset list details
export const resetListInsightDetailsAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_DETAILS_RESET });
};

// Update
export const updateInsightAction =
  (id, insight) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSIGHT_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInsight(
        id,
        token,
        insight
      );

      dispatch({
        type: INSIGHT_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INSIGHT_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInsightAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_UPDATE_RESET });
};

// Delete
export const deleteInsightAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: INSIGHT_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteInsight(id, token);

    dispatch({
      type: INSIGHT_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INSIGHT_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteInsightAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_DELETE_RESET });
};
