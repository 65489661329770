export const SERVICE_DELIVERABLE_LIST_REQUEST =
  "SERVICE_DELIVERABLE_LIST_REQUEST";
export const SERVICE_DELIVERABLE_LIST_SUCCESS =
  "SERVICE_DELIVERABLE_LIST_SUCCESS";
export const SERVICE_DELIVERABLE_LIST_FAIL = "SERVICE_DELIVERABLE_LIST_FAIL";
export const SERVICE_DELIVERABLE_LIST_RESET = "SERVICE_DELIVERABLE_LIST_RESET";

export const SERVICE_DELIVERABLE_CREATE_REQUEST =
  "SERVICE_DELIVERABLE_CREATE_REQUEST";
export const SERVICE_DELIVERABLE_CREATE_SUCCESS =
  "SERVICE_DELIVERABLE_CREATE_SUCCESS";
export const SERVICE_DELIVERABLE_CREATE_FAIL =
  "SERVICE_DELIVERABLE_CREATE_FAIL";
export const SERVICE_DELIVERABLE_CREATE_RESET =
  "SERVICE_DELIVERABLE_CREATE_RESET";

export const SERVICE_DELIVERABLE_DETAILS_REQUEST =
  "SERVICE_DELIVERABLE_DETAILS_REQUEST";
export const SERVICE_DELIVERABLE_DETAILS_SUCCESS =
  "SERVICE_DELIVERABLE_DETAILS_SUCCESS";
export const SERVICE_DELIVERABLE_DETAILS_FAIL =
  "SERVICE_DELIVERABLE_DETAILS_FAIL";
export const SERVICE_DELIVERABLE_DETAILS_RESET =
  "SERVICE_DELIVERABLE_DETAILS_RESET";

export const SERVICE_DELIVERABLE_UPDATE_REQUEST =
  "SERVICE_DELIVERABLE_UPDATE_REQUEST";
export const SERVICE_DELIVERABLE_UPDATE_SUCCESS =
  "SERVICE_DELIVERABLE_UPDATE_SUCCESS";
export const SERVICE_DELIVERABLE_UPDATE_FAIL =
  "SERVICE_DELIVERABLE_UPDATE_FAIL";
export const SERVICE_DELIVERABLE_UPDATE_RESET =
  "SERVICE_DELIVERABLE_UPDATE_RESET";

export const SERVICE_DELIVERABLE_DELETE_REQUEST =
  "SERVICE_DELIVERABLE_DELETE_REQUEST";
export const SERVICE_DELIVERABLE_DELETE_SUCCESS =
  "SERVICE_DELIVERABLE_DELETE_SUCCESS";
export const SERVICE_DELIVERABLE_DELETE_FAIL =
  "SERVICE_DELIVERABLE_DELETE_FAIL";
export const SERVICE_DELIVERABLE_DELETE_RESET =
  "SERVICE_DELIVERABLE_DELETE_RESET";

export const SERVICE_DELIVERABLE_UPLOAD_BEGIN =
  "SERVICE_DELIVERABLE_UPLOAD_BEGIN";
export const SERVICE_DELIVERABLE_UPLOAD_REQUEST =
  "SERVICE_DELIVERABLE_UPLOAD_REQUEST";
export const SERVICE_DELIVERABLE_UPLOAD_SUCCESS =
  "SERVICE_DELIVERABLE_UPLOAD_SUCCESS";
export const SERVICE_DELIVERABLE_UPLOAD_FAIL =
  "SERVICE_DELIVERABLE_UPLOAD_FAIL";
export const SERVICE_DELIVERABLE_UPLOAD_END = "SERVICE_DELIVERABLE_UPLOAD_END";
export const SERVICE_DELIVERABLE_UPLOAD_RESET =
  "SERVICE_DELIVERABLE_UPLOAD_RESET";
