// Constant imports
import {
  PROPOSAL_MILESTONE_LIST_REQUEST,
  PROPOSAL_MILESTONE_LIST_SUCCESS,
  PROPOSAL_MILESTONE_LIST_FAIL,
  PROPOSAL_MILESTONE_LIST_RESET,
  PROPOSAL_MILESTONE_CREATE_REQUEST,
  PROPOSAL_MILESTONE_CREATE_SUCCESS,
  PROPOSAL_MILESTONE_CREATE_FAIL,
  PROPOSAL_MILESTONE_CREATE_RESET,
  PROPOSAL_MILESTONE_DETAILS_REQUEST,
  PROPOSAL_MILESTONE_DETAILS_SUCCESS,
  PROPOSAL_MILESTONE_DETAILS_FAIL,
  PROPOSAL_MILESTONE_DETAILS_RESET,
  PROPOSAL_MILESTONE_UPDATE_REQUEST,
  PROPOSAL_MILESTONE_UPDATE_SUCCESS,
  PROPOSAL_MILESTONE_UPDATE_FAIL,
  PROPOSAL_MILESTONE_UPDATE_RESET,
  PROPOSAL_MILESTONE_DELETE_REQUEST,
  PROPOSAL_MILESTONE_DELETE_SUCCESS,
  PROPOSAL_MILESTONE_DELETE_FAIL,
  PROPOSAL_MILESTONE_DELETE_RESET,
} from "../constants/proposalMilestoneConstants";

// List all
export const proposalMilestoneListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_milestones: [],
    totalValueOfMilestones: 0,
    totalValueOfMilestonesQuote: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_MILESTONE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_MILESTONE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_milestones: action.payload.proposal_milestones,
        totalValueOfMilestones: action.payload.totalValueOfMilestones,
        totalValueOfMilestonesQuote: action.payload.totalValueOfMilestonesQuote,
        success: true,
        error: "",
      };

    case PROPOSAL_MILESTONE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_milestones: [],
        totalValueOfMilestones: 0,
        totalValueOfMilestonesQuote: 0,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_MILESTONE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_milestones: [],
        totalValueOfMilestones: 0,
        totalValueOfMilestonesQuote: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalMilestoneCreateReducer = (
  state = {
    loading: false,
    proposal_milestone: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_MILESTONE_CREATE_REQUEST:
      return {
        loading: true,
        proposal_milestone: state.proposal_milestone,
        success: false,
        error: "",
      };

    case PROPOSAL_MILESTONE_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_milestone: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_MILESTONE_CREATE_FAIL:
      return {
        loading: false,
        proposal_milestone: state.proposal_milestone,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_MILESTONE_CREATE_RESET:
      return {
        loading: false,
        proposal_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalMilestoneDetailsReducer = (
  state = {
    loading: false,
    proposal_milestone: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_MILESTONE_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_milestone: state.proposal_milestone,
        success: false,
        error: "",
      };

    case PROPOSAL_MILESTONE_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_milestone: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_MILESTONE_DETAILS_FAIL:
      return {
        loading: false,
        proposal_milestone: state.proposal_milestone,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_MILESTONE_DETAILS_RESET:
      return {
        loading: false,
        proposal_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalMilestoneUpdateReducer = (
  state = {
    loading: false,
    proposal_milestone: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_MILESTONE_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_milestone: state.proposal_milestone,
        success: false,
        error: "",
      };

    case PROPOSAL_MILESTONE_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_milestone: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_MILESTONE_UPDATE_FAIL:
      return {
        loading: false,
        proposal_milestone: state.proposal_milestone,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_MILESTONE_UPDATE_RESET:
      return {
        loading: false,
        proposal_milestone: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalMilestoneDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_MILESTONE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_MILESTONE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_MILESTONE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_MILESTONE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
