// Library imports
import { Card, Classes, HTMLTable, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";

// Main function
function EngagementCloseFacts({ facts }) {
  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <Card>
      <TabSectionField
        label="Engagement id"
        value={facts.report_data.engagement_id}
        vertical
      />

      <TabSectionField
        label="Engagement name"
        value={facts.report_data.engagement_name}
        vertical
      />

      <TabSectionField
        label="Client"
        value={
          <NameValue
            image={facts.report_data.client_logo}
            logo
            text={facts.report_data.client_name}
          />
        }
        vertical
      />

      <TabSectionField
        label="Sponsor"
        value={
          <NameValue
            image={facts.report_data.sponsor_profile_photo}
            text={facts.report_data.sponsor_name}
          />
        }
        vertical
      />

      <TabSectionField
        label="Fees"
        value={
          facts.report_data.fees.length > 0 ? (
            facts.report_data.fees.map((fee, i) => {
              return (
                <TabSectionField
                  bottom
                  key={i}
                  value={
                    <TabSectionFieldCard
                      text={
                        <>
                          <Card style={{ padding: "0" }}>
                            <HTMLTable
                              bordered
                              striped
                              style={{ width: "100%" }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableText text="Fee" />
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        fee.fees.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber number={fee.fees.baseline} />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber number={fee.fees.current} />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber number={fee.fees.variance} />
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableText text="Expenses" />
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        fee.expenses.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={fee.expenses.baseline}
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={fee.expenses.current}
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={fee.expenses.variance}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableText text="Margin" />
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        fee.margin.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={fee.margin.baseline}
                                      tagNumber={fee.margin_percentage.baseline}
                                      tagPercentage
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={fee.margin.current}
                                      tagNumber={fee.margin_percentage.current}
                                      tagPercentage
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber number={fee.margin.variance} />
                                  </td>
                                </tr>
                              </tbody>

                              <thead>
                                <tr>
                                  <th></th>

                                  <th></th>

                                  <th>Baseline</th>

                                  <th>Current</th>

                                  <th>Variance</th>
                                </tr>
                              </thead>
                            </HTMLTable>
                          </Card>

                          <p
                            className={[
                              Classes.TEXT_SMALL,
                              Classes.TEXT_MUTED,
                            ].join(" ")}
                            style={{ marginTop: "5px" }}
                          >
                            Figures in {activeOrganisation.currency}
                          </p>
                        </>
                      }
                      title={fee.engagement_service_name}
                    />
                  }
                  vertical
                />
              );
            })
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />

      <TabSectionField
        label="Milestones"
        value={
          facts.report_data.milestones.length > 0 ? (
            facts.report_data.milestones.map((milestone, i) => {
              return (
                <TabSectionField
                  bottom
                  key={i}
                  value={
                    <TabSectionFieldCard
                      text={
                        <>
                          <Card style={{ padding: "0" }}>
                            <HTMLTable
                              bordered
                              striped
                              style={{ width: "100%" }}
                            >
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableText text="Due" />
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        milestone.due.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableDate
                                      date={milestone.due.baseline}
                                      small
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableDate
                                      date={milestone.due.current}
                                      small
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      small
                                      value={`${milestone.due.variance} days`}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableText text="Value" />
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        milestone.value.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={milestone.value.baseline}
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={milestone.value.current}
                                    />
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableNumber
                                      number={milestone.value.variance}
                                    />
                                  </td>
                                </tr>
                              </tbody>

                              <thead>
                                <tr>
                                  <th></th>

                                  <th></th>

                                  <th>Baseline</th>

                                  <th>Current</th>

                                  <th>Variance</th>
                                </tr>
                              </thead>
                            </HTMLTable>
                          </Card>

                          <p
                            className={[
                              Classes.TEXT_SMALL,
                              Classes.TEXT_MUTED,
                            ].join(" ")}
                            style={{ marginTop: "5px" }}
                          >
                            Figures in {activeOrganisation.currency}
                          </p>
                        </>
                      }
                      title={milestone.name}
                    />
                  }
                  vertical
                />
              );
            })
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />

      <TabSectionField
        bottom
        label="Deliverables"
        value={
          facts.report_data.deliverables.length > 0 ? (
            facts.report_data.deliverables.map((deliverable, i) => {
              return (
                <TabSectionField
                  bottom
                  key={i}
                  value={
                    <TabSectionFieldCard
                      tag={deliverable.status}
                      tagIntent={
                        deliverable.status === "Accepted"
                          ? "success"
                          : deliverable.status === "Delivered"
                          ? "warning"
                          : "primary"
                      }
                      text={
                        <Card style={{ padding: "0" }}>
                          <HTMLTable bordered striped style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableText text="Due" />
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <Tag
                                    intent={
                                      deliverable.due.rating === "green"
                                        ? "success"
                                        : "danger"
                                    }
                                  ></Tag>
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableDate
                                    date={deliverable.due.baseline}
                                    small
                                  />
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableDate
                                    date={deliverable.due.current}
                                    small
                                  />
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    small
                                    value={`${deliverable.due.variance} days`}
                                  />
                                </td>
                              </tr>
                            </tbody>

                            <thead>
                              <tr>
                                <th></th>

                                <th></th>

                                <th>Baseline</th>

                                <th>Current</th>

                                <th>Variance</th>
                              </tr>
                            </thead>
                          </HTMLTable>
                        </Card>
                      }
                      title={deliverable.name}
                    />
                  }
                  vertical
                />
              );
            })
          ) : (
            <p className={Classes.TEXT_MUTED}>No information provided.</p>
          )
        }
        vertical
      />
    </Card>
  );
}

export default EngagementCloseFacts;
