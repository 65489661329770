export const APPROVAL_RESPONSE_LIST_REQUEST = "APPROVAL_RESPONSE_LIST_REQUEST";
export const APPROVAL_RESPONSE_LIST_SUCCESS = "APPROVAL_RESPONSE_LIST_SUCCESS";
export const APPROVAL_RESPONSE_LIST_FAIL = "APPROVAL_RESPONSE_LIST_FAIL";
export const APPROVAL_RESPONSE_LIST_RESET = "APPROVAL_RESPONSE_LIST_RESET";

export const APPROVAL_RESPONSE_CREATE_REQUEST =
  "APPROVAL_RESPONSE_CREATE_REQUEST";
export const APPROVAL_RESPONSE_CREATE_SUCCESS =
  "APPROVAL_RESPONSE_CREATE_SUCCESS";
export const APPROVAL_RESPONSE_CREATE_FAIL = "APPROVAL_RESPONSE_CREATE_FAIL";
export const APPROVAL_RESPONSE_CREATE_RESET = "APPROVAL_RESPONSE_CREATE_RESET";

export const APPROVAL_RESPONSE_DETAILS_REQUEST =
  "APPROVAL_RESPONSE_DETAILS_REQUEST";
export const APPROVAL_RESPONSE_DETAILS_SUCCESS =
  "APPROVAL_RESPONSE_DETAILS_SUCCESS";
export const APPROVAL_RESPONSE_DETAILS_FAIL = "APPROVAL_RESPONSE_DETAILS_FAIL";
export const APPROVAL_RESPONSE_DETAILS_RESET =
  "APPROVAL_RESPONSE_DETAILS_RESET";

export const APPROVAL_RESPONSE_UPDATE_REQUEST =
  "APPROVAL_RESPONSE_UPDATE_REQUEST";
export const APPROVAL_RESPONSE_UPDATE_SUCCESS =
  "APPROVAL_RESPONSE_UPDATE_SUCCESS";
export const APPROVAL_RESPONSE_UPDATE_FAIL = "APPROVAL_RESPONSE_UPDATE_FAIL";
export const APPROVAL_RESPONSE_UPDATE_RESET = "APPROVAL_RESPONSE_UPDATE_RESET";

export const APPROVAL_RESPONSE_DELETE_REQUEST =
  "APPROVAL_RESPONSE_DELETE_REQUEST";
export const APPROVAL_RESPONSE_DELETE_SUCCESS =
  "APPROVAL_RESPONSE_DELETE_SUCCESS";
export const APPROVAL_RESPONSE_DELETE_FAIL = "APPROVAL_RESPONSE_DELETE_FAIL";
export const APPROVAL_RESPONSE_DELETE_RESET = "APPROVAL_RESPONSE_DELETE_RESET";
