// Library imports
import { Callout, UL } from "@blueprintjs/core";
import React from "react";

// Main function
function ErrorList({ error, message }) {
  // JSX UI code
  return (
    <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
      {message ? message : "Please address the errors below and try again."}
      {error.response ? (
        "non_field_errors" in error.response.data ? (
          <UL>
            {error.response.data["non_field_errors"].map((error, i) => {
              return <li key={`non_field_error_${i}`}>{error}</li>;
            })}
          </UL>
        ) : undefined
      ) : undefined}
    </Callout>
  );
}

export default ErrorList;
