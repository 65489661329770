// Library imports
import {
  Button,
  Card,
  Callout,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  H5,
  H6,
  Icon,
  InputGroup,
  Tab,
  Tabs,
  Tag,
  Text,
} from "@blueprintjs/core";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import CaseStudyInfo from "../components/CaseStudyInfo";
import ClientProgramInfo from "../components/ClientProgramInfo";
import ContactInfo from "../components/ContactInfo";
import DateValue from "../components/DateValue";
import DocumentDialog3 from "../components/DocumentDialog3";
import EngagementInfo from "../components/EngagementInfo";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import InsightInfo from "../components/InsightInfo";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NeedInfo from "../components/NeedInfo";
import NeedQuestionInfo from "../components/NeedQuestionInfo";
import NumericValue from "../components/NumericValue";
import PermissionCard from "../components/PermissionCard";
import ProposalInfo from "../components/ProposalInfo";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";
import TagPopup from "../components/TagPopup";

// Utility imports
import getDjangoDate from "../utilities/getDjangoDate";
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// Contact
import { listContactsAction } from "../actions/contactActions";

// Document
import { generatePdfDocumentAction } from "../actions/documentActions";

// Interview
import {
  deleteInterviewAction,
  listInterviewDetailsAction,
  resetDeleteInterviewAction,
  resetListInterviewsAction,
  resetUpdateInterviewAction,
  updateInterviewAction,
} from "../actions/interviewActions";

// Interview permission
import {
  createInterviewPermissionAction,
  deleteInterviewPermissionAction,
  listInterviewPermissionsAction,
  listInterviewPermissionDetailsAction,
  resetCreateInterviewPermissionAction,
  resetDeleteInterviewPermissionAction,
  resetUpdateInterviewPermissionAction,
  updateInterviewPermissionAction,
} from "../actions/interviewPermissionActions";

// Interview question
import {
  createInterviewQuestionAction,
  deleteInterviewQuestionAction,
  listInterviewQuestionsAction,
  listInterviewQuestionDetailsAction,
  resetCreateInterviewQuestionAction,
  resetDeleteInterviewQuestionAction,
  resetUpdateInterviewQuestionAction,
  updateInterviewQuestionAction,
} from "../actions/interviewQuestionActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Need
import { listNeedsAction } from "../actions/needActions";

// NeedQuestion
import { listNeedQuestionsAction } from "../actions/needQuestionActions";

// Main function
function InterviewDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Interview id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Contact
  const contactList = useSelector((state) => state.contactList);

  // Document
  const documentGeneratePdf = useSelector((state) => state.documentGeneratePdf);

  // Interview
  const interviewDelete = useSelector((state) => state.interviewDelete);

  const interviewDetails = useSelector((state) => state.interviewDetails);

  const interviewUpdate = useSelector((state) => state.interviewUpdate);

  // Interview permission
  const interviewPermissionCreate = useSelector(
    (state) => state.interviewPermissionCreate
  );

  const interviewPermissionDelete = useSelector(
    (state) => state.interviewPermissionDelete
  );

  const interviewPermissionDetails = useSelector(
    (state) => state.interviewPermissionDetails
  );

  const interviewPermissionList = useSelector(
    (state) => state.interviewPermissionList
  );

  const interviewPermissionUpdate = useSelector(
    (state) => state.interviewPermissionUpdate
  );

  // Interview question
  const interviewQuestionCreate = useSelector(
    (state) => state.interviewQuestionCreate
  );

  const interviewQuestionDelete = useSelector(
    (state) => state.interviewQuestionDelete
  );

  const interviewQuestionDetails = useSelector(
    (state) => state.interviewQuestionDetails
  );

  const interviewQuestionList = useSelector(
    (state) => state.interviewQuestionList
  );

  const interviewQuestionUpdate = useSelector(
    (state) => state.interviewQuestionUpdate
  );

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Need
  const needList = useSelector((state) => state.needList);

  // Need question
  const needQuestionList = useSelector((state) => state.needQuestionList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("interview-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Questions
  const [addQuestionDrawerIsOpen, setAddQuestionDrawerIsOpen] = useState(false);
  const [updateQuestionDrawerIsOpen, setUpdateQuestionDrawerIsOpen] =
    useState(false);

  // Answers
  const [updateAnswerDrawerIsOpen, setUpdateAnswerDrawerIsOpen] =
    useState(false);

  // Interview questionnaire
  const [interviewQuestionnaireIsOpen, setInterviewQuestionnaireIsOpen] =
    useState(false);

  // Analysis
  const [updateAnalysisDrawerIsOpen, setUpdateAnalysisDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Contact
  const contactQueryDefault = {
    client: "",
    manager: "",
    opening: "",
    organisation: activeOrganisation.id,
    page: 1,
    role: "",
    searchString: "",
  };
  const [contactQuery, setContactQuery] = useState({
    ...contactQueryDefault,
  });

  // Interview permission
  const interviewPermissionQueryDefault = {
    interview: id,
    page: 1,
    searchString: "",
  };
  const [interviewPermissionQuery, setInterviewPermissionQuery] = useState({
    ...interviewPermissionQueryDefault,
  });

  // Interview question
  const interviewQuestionQueryDefault = {
    interview: id,
    page: 1,
    searchString: "",
  };
  const [interviewQuestionQuery, setInterviewQuestionQuery] = useState({
    ...interviewQuestionQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Need
  const needQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    status: "Approved",
  };
  const [needQuery, setNeedQuery] = useState({
    ...needQueryDefault,
  });

  // Need question
  const needFilterDefault = {
    need: "",
    needName: "",
  };
  const [needFilter, setNeedFilter] = useState({
    ...needFilterDefault,
  });

  useEffect(() => {
    setNeedQuestionQuery((prevState) => {
      return {
        ...prevState,
        need: needFilter.need,
      };
    });
  }, [needFilter]);

  const needQuestionQueryDefault = {
    need: "",
    page: 1,
    searchString: "",
  };
  const [needQuestionQuery, setNeedQuestionQuery] = useState({
    ...needQuestionQueryDefault,
  });

  // Form variables
  // Interview
  const interviewDefault = {
    contact: "",
    contactName: "",
    date: "",
    findings: "",
    manager: "",
    managerName: "",
    name: "",
    notes: "",
    restricted: true,
    status: "Draft",
  };
  const [interview, setInterview] = useState({
    ...interviewDefault,
  });

  // Interview permission
  const interviewPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [interviewPermission, setInterviewPermission] = useState({
    ...interviewPermissionDefault,
  });

  // Interview question
  const interviewQuestionDefault = {
    answer: "",
    name: "",
    needQuestion: "",
    needQuestionName: "",
    notes: "",
    question: "",
    questionNumber: "",
    theme: "No specific theme",
  };
  const [interviewQuestion, setInterviewQuestion] = useState({
    ...interviewQuestionDefault,
  });

  // Other
  const [interviewDeleteKey, setInterviewDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Interview
    setInterview({
      ...interviewDefault,
    });

    // Interview permission
    setInterviewPermission({
      ...interviewPermissionDefault,
    });

    // Interview question
    setInterviewQuestion({
      ...interviewQuestionDefault,
    });

    // Query variables
    // Contact
    setContactQuery({
      ...contactQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });

    // Need
    setNeedQuery({
      ...needQueryDefault,
    });

    // Need filter
    setNeedFilter({
      ...needFilterDefault,
    });

    // Need question
    setNeedQuestionQuery({
      ...needQuestionQueryDefault,
    });
  };

  // Initialise drawer variables for interview
  useEffect(() => {
    setInterview({
      contact: interviewDetails.interview.contact
        ? interviewDetails.interview.contact
        : interviewDefault.contact,
      contactName: interviewDetails.interview.contact_data
        ? interviewDetails.interview.contact_data.name
        : interviewDefault.contactName,
      date: interviewDetails.interview.date
        ? new Date(interviewDetails.interview.date)
        : interviewDefault.date,
      findings: interviewDetails.interview.findings
        ? interviewDetails.interview.findings
        : interviewDefault.findings,
      manager: interviewDetails.interview.manager
        ? interviewDetails.interview.manager
        : interviewDefault.manager,
      managerName: interviewDetails.interview.manager_name
        ? interviewDetails.interview.manager_name
        : interviewDefault.managerName,
      name: interviewDetails.interview.name
        ? interviewDetails.interview.name
        : interviewDefault.name,
      notes: interviewDetails.interview.notes
        ? interviewDetails.interview.notes
        : interviewDefault.notes,
      restricted:
        interviewDetails.interview.restricted != null
          ? interviewDetails.interview.restricted
          : interviewDefault.restricted,
      status: interviewDetails.interview.status
        ? interviewDetails.interview.status
        : interviewDefault.status,
    });
    // eslint-disable-next-line
  }, [interviewDetails.interview]);

  // Initialise drawer variables for interview permission
  useEffect(() => {
    setInterviewPermission({
      level: interviewPermissionDetails.interview_permission.level
        ? interviewPermissionDetails.interview_permission.level
        : interviewPermissionDefault.level,
      manager: interviewPermissionDetails.interview_permission.manager
        ? interviewPermissionDetails.interview_permission.manager
        : interviewPermissionDefault.manager,
      managerName: interviewPermissionDetails.interview_permission.manager_name
        ? interviewPermissionDetails.interview_permission.manager_name
        : interviewPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [interviewPermissionDetails.interview_permission]);

  // Initialise drawer variables for interview question
  useEffect(() => {
    setInterviewQuestion({
      answer: interviewQuestionDetails.interview_question.answer
        ? interviewQuestionDetails.interview_question.answer
        : interviewQuestionDefault.answer,
      name: interviewQuestionDetails.interview_question.name
        ? interviewQuestionDetails.interview_question.name
        : interviewQuestionDefault.name,
      needQuestion: interviewQuestionDefault.needQuestion,
      needQuestionName: interviewQuestionDefault.needQuestionName,
      notes: interviewQuestionDetails.interview_question.notes
        ? interviewQuestionDetails.interview_question.notes
        : interviewQuestionDefault.notes,
      question: interviewQuestionDetails.interview_question.question
        ? interviewQuestionDetails.interview_question.question
        : interviewQuestionDefault.question,
      questionNumber: interviewQuestionDetails.interview_question
        .question_number
        ? interviewQuestionDetails.interview_question.question_number
        : interviewQuestionDefault.questionNumber,
      theme: interviewQuestionDetails.interview_question.theme
        ? interviewQuestionDetails.interview_question.theme
        : interviewQuestionDefault.theme,
    });
    // eslint-disable-next-line
  }, [interviewQuestionDetails.interview_question]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If interview deleted redirect to interviews
  useEffect(() => {
    if (interviewDelete.success) {
      dispatch(resetDeleteInterviewAction());

      dispatch(resetListInterviewsAction());

      navigate("/contacts/#interviews");
    }
    // eslint-disable-next-line
  }, [interviewDelete.success]);

  // Load interview details
  useEffect(() => {
    dispatch(listInterviewDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter contacts
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactsAction({
            ...contactQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactQuery]);

  // Filter interview permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInterviewPermissionsAction({ ...interviewPermissionQuery })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [interviewPermissionQuery]);

  // Filter interview questions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listInterviewQuestionsAction({
            ...interviewQuestionQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [interviewQuestionQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter needs
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNeedsAction({
            ...needQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuery]);

  // Filter need questions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNeedQuestionsAction({
            ...needQuestionQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuestionQuery]);

  // Form submission
  // Interview
  // Delete interview
  const deleteInterview = () => {
    // Dispatch action
    interviewDeleteKey === interviewDetails.interview.name
      ? dispatch(deleteInterviewAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the interview's purpose",
        });
  };

  // Update analysis
  const updateAnalysis = () => {
    // Prepare data
    const interview_data = new FormData();

    interview.findings
      ? interview_data.append("findings", interview.findings)
      : interview_data.append("findings", interviewDefault.findings);

    // Dispatch action
    dispatch(updateInterviewAction(id, "", interview_data));
  };

  // Update interview summary
  const updateSummary = () => {
    // Prepare data
    const interview_data = new FormData();

    const contact = new FormData();
    if (interview.contact) {
      interview_data.append("contact", interview.contact);
    } else {
      interview_data.append("contact", interviewDefault.contact);

      if (interview.contactName) {
        contact.append("name", interview.contactName);

        contact.append("organisation", activeOrganisation.id);
      }
    }

    interview.date
      ? interview_data.append("date", getDjangoDate(interview.date))
      : interview_data.append("date", getDjangoDate(interviewDefault.date));

    interview.manager
      ? interview_data.append("manager", interview.manager)
      : interview_data.append("manager", interviewDefault.manager);

    interview.name
      ? interview_data.append("name", interview.name)
      : interview_data.append("name", interviewDefault.name);

    interview.notes
      ? interview_data.append("notes", interview.notes)
      : interview_data.append("notes", interviewDefault.notes);

    interview.restricted != null
      ? interview_data.append("restricted", interview.restricted)
      : interview_data.append("restricted", interviewDefault.restricted);

    interview.status
      ? interview_data.append("status", interview.status)
      : interview_data.append("status", interviewDefault.status);

    // Dispatch action
    dispatch(updateInterviewAction(id, contact, interview_data));
  };

  // Interview permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const interview_permission = new FormData();

    interview_permission.append("interview", id);

    interviewPermission.level
      ? interview_permission.append("level", interviewPermission.level)
      : interview_permission.append("level", interviewPermissionDefault.level);

    interviewPermission.manager
      ? interview_permission.append("manager", interviewPermission.manager)
      : interview_permission.append(
          "manager",
          interviewPermissionDefault.manager
        );

    // Dispatch action
    dispatch(createInterviewPermissionAction(interview_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteInterviewPermissionAction(
        interviewPermissionDetails.interview_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const interview_permission = new FormData();

    interviewPermission.level
      ? interview_permission.append("level", interviewPermission.level)
      : interview_permission.append("level", interviewPermissionDefault.level);

    interviewPermission.manager
      ? interview_permission.append("manager", interviewPermission.manager)
      : interview_permission.append(
          "manager",
          interviewPermissionDefault.manager
        );

    // Dispatch action
    dispatch(
      updateInterviewPermissionAction(
        interviewPermissionDetails.interview_permission.id,
        interview_permission
      )
    );
  };

  // Interview question
  // Add question
  const addQuestion = () => {
    // Prepare data
    const interview_question_data = new FormData();

    interview_question_data.append("interview", id);

    interviewQuestion.name
      ? interview_question_data.append("name", interviewQuestion.name)
      : interview_question_data.append("name", interviewQuestionDefault.name);

    interviewQuestion.needQuestion
      ? interview_question_data.append(
          "need_question",
          interviewQuestion.needQuestion
        )
      : interview_question_data.append(
          "need_question",
          interviewQuestionDefault.needQuestion
        );

    interviewQuestion.notes
      ? interview_question_data.append("notes", interviewQuestion.notes)
      : interview_question_data.append("notes", interviewQuestionDefault.notes);

    interviewQuestion.question
      ? interview_question_data.append("question", interviewQuestion.question)
      : interview_question_data.append(
          "question",
          interviewQuestionDefault.question
        );

    interviewQuestion.questionNumber
      ? interview_question_data.append(
          "question_number",
          interviewQuestion.questionNumber
        )
      : interview_question_data.append(
          "question_number",
          interviewQuestionDefault.questionNumber
        );

    interviewQuestion.theme
      ? interview_question_data.append("theme", interviewQuestion.theme)
      : interview_question_data.append("theme", interviewQuestionDefault.theme);

    // Dispatch action
    dispatch(createInterviewQuestionAction(interview_question_data));
  };

  // Delete question
  const deleteQuestion = () => {
    // Dispatch deliverable
    dispatch(
      deleteInterviewQuestionAction(
        interviewQuestionDetails.interview_question.id
      )
    );
  };

  // Update answer
  const updateAnswer = () => {
    // Prepare data
    const interview_question_data = new FormData();

    interviewQuestion.answer
      ? interview_question_data.append("answer", interviewQuestion.answer)
      : interview_question_data.append(
          "answer",
          interviewQuestionDefault.answer
        );

    // Dispatch action
    dispatch(
      updateInterviewQuestionAction(
        interviewQuestionDetails.interview_question.id,
        interview_question_data
      )
    );
  };

  // Update question
  const updateQuestion = () => {
    // Prepare data
    const interview_question_data = new FormData();

    interviewQuestion.name
      ? interview_question_data.append("name", interviewQuestion.name)
      : interview_question_data.append("name", interviewQuestionDefault.name);

    interviewQuestion.notes
      ? interview_question_data.append("notes", interviewQuestion.notes)
      : interview_question_data.append("notes", interviewQuestionDefault.notes);

    interviewQuestion.question
      ? interview_question_data.append("question", interviewQuestion.question)
      : interview_question_data.append(
          "question",
          interviewQuestionDefault.question
        );

    interviewQuestion.questionNumber
      ? interview_question_data.append(
          "question_number",
          interviewQuestion.questionNumber
        )
      : interview_question_data.append(
          "question_number",
          interviewQuestionDefault.questionNumber
        );

    interviewQuestion.theme
      ? interview_question_data.append("theme", interviewQuestion.theme)
      : interview_question_data.append("theme", interviewQuestionDefault.theme);

    // Dispatch action
    dispatch(
      updateInterviewQuestionAction(
        interviewQuestionDetails.interview_question.id,
        interview_question_data
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the interview</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Interview purpose */}
                  <FormDrawerTextField2
                    error={interviewUpdate.error}
                    fieldName="name"
                    icon="social-media"
                    label="Interview purpose"
                    labelInfo="(required)"
                    placeholder="State the purpose of the interview"
                    setValueObject={setInterview}
                    setValueVariable="name"
                    value={interview.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Contact */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={interviewUpdate.error}
                    fieldName="contact"
                    helperText="You can create or select an existing contact"
                    icon="id-number"
                    info={
                      <ContactInfo
                        contactId={interview.contact}
                        query={contactQueryDefault}
                      />
                    }
                    items={contactList.contacts}
                    label="Contact"
                    labelInfo="(required)"
                    loading={contactList.loading}
                    noOptionOnClick
                    placeholder="Create or select a contact"
                    query={contactQuery.searchString}
                    setQueryObject={setContactQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInterview}
                    setValueIdVariable="contact"
                    setValueNameVariable="contactName"
                    showInfo={interview.contact}
                    value={interview.contactName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Date */}
                  <FormDrawerDateField2
                    error={interviewUpdate.error}
                    fieldName="date"
                    icon="calendar"
                    label="Date"
                    placeholder="Enter the date of the interview"
                    setValueObject={setInterview}
                    setValueVariable="date"
                    value={interview.date}
                  />
                </div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={interviewUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the interview"
                setValueObject={setInterview}
                setValueVariable="notes"
                value={interview.notes}
              />
            </Card>

            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />
              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Interview status */}
                  <FormDrawerSelectSimpleField3
                    error={interviewUpdate.error}
                    fieldName="status"
                    intent={
                      interview.status === "Completed"
                        ? "success"
                        : interview.status === "Planned"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      { id: 1, icon: "manually-entered-data", value: "Draft" },
                      { id: 2, icon: "playbook", value: "Planned" },
                      {
                        id: 3,
                        icon: "confirm",
                        value: "Completed",
                      },
                    ]}
                    setValueObject={setInterview}
                    setValueVariable="status"
                    value={interview.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      interviewDetails.interview.user_access_status ===
                        "edit" ||
                      interviewDetails.interview.user_access_status === "view"
                    }
                    error={interviewUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted interview can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setInterview}
                    setValueVariable="restricted"
                    value={interview.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      interviewDetails.interview.user_access_status ===
                        "edit" ||
                      interviewDetails.interview.user_access_status === "view"
                    }
                    error={interviewUpdate.error}
                    fieldName="manager"
                    helperText="Person driving the interview - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInterview}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={interview.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the interview manager and the
                    interview is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={interviewUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update interview"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Updating an interview"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={interviewDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listInterviewDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateInterviewAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewUpdate.success}
        saveSuccessMessage="Interview updated."
        saving={interviewUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update interview"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={interviewPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setInterviewPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={interviewPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={interviewPermissionCreate.error}
              fieldName="level"
              intent={
                interviewPermission.level === "Edit"
                  ? "success"
                  : interviewPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setInterviewPermission}
              setValueVariable="level"
              value={interviewPermission.level}
            />
          </Card>
        }
        error={interviewPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listInterviewPermissionsAction({ interview: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateInterviewPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={interviewPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={interviewPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setInterviewPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={interviewPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={interviewPermissionUpdate.error}
              fieldName="level"
              intent={
                interviewPermission.level === "Edit"
                  ? "success"
                  : interviewPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setInterviewPermission}
              setValueVariable="level"
              value={interviewPermission.level}
            />
          </Card>
        }
        deleteSuccess={interviewPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={interviewPermissionDelete.loading}
        error={interviewPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={interviewPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(
            listInterviewPermissionsAction({ ...interviewPermissionQuery })
          );
        }}
        resetAction={() => {
          dispatch(resetDeleteInterviewPermissionAction());

          dispatch(resetUpdateInterviewPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={interviewPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New question form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                margin: !interviewQuestion.needQuestion
                  ? "0 0 10px 0"
                  : undefined,
                width: "100%",
              }}
            >
              <H5>Import a question to evaluate a specific need</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Need */}
                  <FormDrawerSelectField2
                    fieldName="need"
                    icon="clean"
                    info={
                      <NeedInfo
                        needId={needFilter.need}
                        query={needQueryDefault}
                      />
                    }
                    items={needList.needs}
                    label="Need"
                    loading={needList.loading}
                    noOptionOnClick
                    placeholder="Select a need"
                    query={needQuery.searchString}
                    setQueryObject={setNeedQuery}
                    setQueryVariable="searchString"
                    setValueObject={setNeedFilter}
                    setValueIdVariable="need"
                    setValueNameVariable="needName"
                    showInfo={needFilter.need}
                    value={needFilter.needName}
                  />

                  {/* Need question */}
                  <FormDrawerSelectField2
                    error={interviewQuestionCreate.error}
                    fieldName="need_question"
                    icon="form"
                    info={
                      <NeedQuestionInfo
                        needQuestionId={interviewQuestion.needQuestion}
                        query={{
                          need: needFilter.need,
                        }}
                      />
                    }
                    items={needQuestionList.need_questions}
                    label="Question"
                    loading={needQuestionList.loading}
                    noOptionOnClick
                    placeholder="Select a question"
                    query={needQuestionQuery.searchString}
                    setQueryObject={setNeedQuestionQuery}
                    setQueryVariable="searchString"
                    setValueObject={setInterviewQuestion}
                    setValueIdVariable="needQuestion"
                    setValueNameVariable="needQuestionName"
                    showInfo={interviewQuestion.needQuestion}
                    value={interviewQuestion.needQuestionName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="primary">
                    You can import questions designed to evaluate specific
                    needs. Select a need and a question. The system will then
                    autopopulate the question's details.
                  </Callout>
                </div>
              </div>
            </Card>

            {!interviewQuestion.needQuestion && (
              <Card style={{ width: "100%" }}>
                <H5>Define the question</H5>

                <Divider style={{ margin: "0 0 15px 0" }} />

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Theme */}
                    <FormDrawerTextField2
                      error={interviewQuestionCreate.error}
                      fieldName="theme"
                      helperText="You may create multiple questions addressing a common theme"
                      icon="tag"
                      label="Theme"
                      placeholder="Give the question a theme"
                      setValueObject={setInterviewQuestion}
                      setValueVariable="theme"
                      value={interviewQuestion.theme}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}></div>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px", width: "60%" }}>
                    {/* Question name */}
                    <FormDrawerTextField2
                      error={interviewQuestionCreate.error}
                      fieldName="name"
                      icon="form"
                      label="Question name"
                      labelInfo="(required)"
                      placeholder="Enter the question's name"
                      setValueObject={setInterviewQuestion}
                      setValueVariable="name"
                      value={interviewQuestion.name}
                    />
                  </div>

                  <div style={{ marginLeft: "10px", width: "40%" }}>
                    {/* Question number */}
                    <FormDrawerTextField2
                      error={interviewQuestionCreate.error}
                      fieldName="question_number"
                      helperText="advaise.app will order questions based on this number"
                      icon="numbered-list"
                      label="Question number"
                      placeholder="Enter a question number"
                      setValueObject={setInterviewQuestion}
                      setValueVariable="questionNumber"
                      value={interviewQuestion.questionNumber}
                    />
                  </div>
                </div>

                {/* Question */}
                <FormDrawerTextAreaField3
                  error={interviewQuestionCreate.error}
                  fieldName="question"
                  height="100px"
                  label="Question"
                  labelInfo="(required)"
                  placeholder="Enter the question"
                  setValueObject={setInterviewQuestion}
                  setValueVariable="question"
                  value={interviewQuestion.question}
                />

                {/* Notes */}
                <FormDrawerTextAreaField3
                  error={interviewQuestionCreate.error}
                  fieldName="notes"
                  height="100px"
                  helperText="Provide guidance on how to interpret answers"
                  label="Notes"
                  placeholder="Explain the question"
                  setValueObject={setInterviewQuestion}
                  setValueVariable="notes"
                  value={interviewQuestion.notes}
                />
              </Card>
            )}
          </div>
        }
        error={interviewQuestionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add question"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Defining questions"
        icon="edit"
        isOpen={addQuestionDrawerIsOpen}
        onSave={addQuestion}
        refreshList={() => {
          dispatch(listInterviewQuestionsAction({ interview: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateInterviewQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewQuestionCreate.success}
        saveSuccessMessage="Question created."
        saving={interviewQuestionCreate.loading}
        setIsOpen={setAddQuestionDrawerIsOpen}
        title="Add question"
      />

      {/* Existing question form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the question</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Theme */}
                <FormDrawerTextField2
                  error={interviewQuestionUpdate.error}
                  fieldName="theme"
                  helperText="You may create multiple questions addressing a common theme"
                  icon="tag"
                  label="Theme"
                  placeholder="Give the question a theme"
                  setValueObject={setInterviewQuestion}
                  setValueVariable="theme"
                  value={interviewQuestion.theme}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}></div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Question name */}
                <FormDrawerTextField2
                  error={interviewQuestionUpdate.error}
                  fieldName="name"
                  icon="form"
                  label="Question name"
                  labelInfo="(required)"
                  placeholder="Enter the question's name"
                  setValueObject={setInterviewQuestion}
                  setValueVariable="name"
                  value={interviewQuestion.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Question number */}
                <FormDrawerTextField2
                  error={interviewQuestionUpdate.error}
                  fieldName="question_number"
                  helperText="advaise.app will order questions based on this number"
                  icon="numbered-list"
                  label="Question number"
                  placeholder="Enter a question number"
                  setValueObject={setInterviewQuestion}
                  setValueVariable="questionNumber"
                  value={interviewQuestion.questionNumber}
                />
              </div>
            </div>

            {/* Question */}
            <FormDrawerTextAreaField3
              error={interviewQuestionUpdate.error}
              fieldName="question"
              height="100px"
              label="Question"
              labelInfo="(required)"
              placeholder="Enter the question"
              setValueObject={setInterviewQuestion}
              setValueVariable="question"
              value={interviewQuestion.question}
            />

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={interviewQuestionUpdate.error}
              fieldName="notes"
              height="100px"
              helperText="Provide guidance on how to interpret answers"
              label="Notes"
              placeholder="Explain the question"
              setValueObject={setInterviewQuestion}
              setValueVariable="notes"
              value={interviewQuestion.notes}
            />
          </Card>
        }
        deleteSuccess={interviewQuestionDelete.success}
        deleteSuccessMessage="Question deleted."
        deleting={interviewQuestionDelete.loading}
        error={interviewQuestionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update question"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Defining questions"
        icon="edit"
        isOpen={updateQuestionDrawerIsOpen}
        loading={interviewQuestionDetails.loading}
        onDelete={deleteQuestion}
        onSave={updateQuestion}
        refreshList={() => {
          dispatch(listInterviewQuestionsAction({ ...interviewQuestionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteInterviewQuestionAction());

          dispatch(resetUpdateInterviewQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewQuestionUpdate.success}
        saveSuccessMessage="Question updated."
        saving={interviewQuestionUpdate.loading}
        setIsOpen={setUpdateQuestionDrawerIsOpen}
        title="Update question"
      />

      {/* Answer form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the answer</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <H6>{interviewQuestion.question}</H6>

            <Tag
              intent="success"
              large
              minimal
              style={{ marginBottom: "15px" }}
            >
              {interviewQuestion.theme}
            </Tag>

            {interviewQuestion.notes && <p>{interviewQuestion.notes}</p>}

            {/* Answer */}
            <FormDrawerTextAreaField3
              error={interviewQuestionUpdate.error}
              fieldName="answer"
              label="Answer"
              placeholder="Enter the answer"
              setValueObject={setInterviewQuestion}
              setValueVariable="answer"
              value={interviewQuestion.answer}
            />
          </Card>
        }
        error={interviewQuestionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update answer"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Entering answers"
        icon="edit"
        isOpen={updateAnswerDrawerIsOpen}
        loading={interviewQuestionDetails.loading}
        onSave={updateAnswer}
        refreshList={() => {
          dispatch(listInterviewQuestionsAction({ ...interviewQuestionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteInterviewQuestionAction());

          dispatch(resetUpdateInterviewQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewQuestionUpdate.success}
        saveSuccessMessage="Answer updated."
        saving={interviewQuestionUpdate.loading}
        setIsOpen={setUpdateAnswerDrawerIsOpen}
        title="Update answer"
      />

      {/* Analysis form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Analyse the answers</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Key findings */}
            <FormDrawerTextAreaField3
              error={interviewUpdate.error}
              fieldName="notes"
              label="Key findings"
              placeholder="Enter the key insights gained from the interview"
              setValueObject={setInterview}
              setValueVariable="findings"
              value={interview.findings}
            />
          </Card>
        }
        error={interviewUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update analysis"
            module="Interviews"
            view="Detail"
          />
        }
        helpTitle="Analysing answers"
        icon="edit"
        isOpen={updateAnalysisDrawerIsOpen}
        loading={interviewDetails.loading}
        onSave={updateAnalysis}
        refreshList={() => {
          dispatch(listInterviewDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateInterviewAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={interviewUpdate.success}
        saveSuccessMessage="Interview updated."
        saving={interviewUpdate.loading}
        setIsOpen={setUpdateAnalysisDrawerIsOpen}
        title="Update analysis"
      />

      {/* Document dialogs */}
      {/* Interview questionnaire dialog */}
      <DocumentDialog3
        document={documentGeneratePdf.interview_questionnaire_pdf}
        fileName="Interview Questionnaire"
        isOpen={interviewQuestionnaireIsOpen}
        loading={documentGeneratePdf.loading}
        setIsOpen={setInterviewQuestionnaireIsOpen}
        title="Interview Questionnaire"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Interviews"
              onClick={() => {
                dispatch(resetListInterviewsAction());

                navigate("/contacts/#interviews");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          interviewDetails.interview.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={interviewDetails.loading}
        title={`${interviewDetails.interview.name} with ${
          interviewDetails.interview.contact_data
            ? interviewDetails.interview.contact_data.name
            : undefined
        }`}
      />

      {/* Error alerts */}
      {/* Contacts loading error */}
      {contactList.error && (
        <ErrorAlert message="We couldn't load the contact list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Interview delete error */}
      {interviewDelete.error && (
        <ErrorAlert message="We couldn't delete the interview. Try refreshing the page." />
      )}
      {/* Interview loading error */}
      {interviewDetails.error && (
        <ErrorAlert message="We couldn't load the interview. Try refreshing the page." />
      )}
      {/* Interview permissions loading error */}
      {interviewPermissionList.error && (
        <ErrorAlert message="We couldn't load the interview permissions. Try refreshing the page." />
      )}
      {/* Interview questions loading error */}
      {interviewQuestionList.error && (
        <ErrorAlert message="We couldn't load the interview questions. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/DMmd2kzN1-Y"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Interview"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="interview-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="interview-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          interviewDetails.interview.user_access_status ===
                          "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listInterviewDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={interviewDetails.loading}
                          value={interviewDetails.interview.id}
                        />

                        {/* Interview purpose */}
                        <TabSectionField
                          label="Interview purpose"
                          loading={interviewDetails.loading}
                          value={interviewDetails.interview.name}
                        />

                        {/* Contact */}
                        <TabSectionField
                          label="Contact"
                          loading={interviewDetails.loading}
                          value={
                            <NameValue
                              image={
                                interviewDetails.interview.contact_data
                                  ? interviewDetails.interview.contact_data
                                      .profile_photo
                                  : undefined
                              }
                              text={
                                interviewDetails.interview.contact_data
                                  ? interviewDetails.interview.contact_data.name
                                  : undefined
                              }
                              url={
                                interviewDetails.interview.contact
                                  ? `/contacts/${interviewDetails.interview.contact}`
                                  : undefined
                              }
                            />
                          }
                        />

                        {/* Date */}
                        <TabSectionField
                          label="Date"
                          loading={interviewDetails.loading}
                          value={
                            <DateValue date={interviewDetails.interview.date} />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={interviewDetails.loading}
                          value={
                            <Tag
                              intent={
                                interviewDetails.interview.status ===
                                "Completed"
                                  ? "success"
                                  : interviewDetails.interview.status ===
                                    "Planned"
                                  ? "warning"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {interviewDetails.interview.status}
                            </Tag>
                          }
                        />

                        {/* Notes */}
                        <TabSectionField
                          label="Notes"
                          loading={interviewDetails.loading}
                          value={
                            interviewDetails.interview.notes ? (
                              <TabSectionFieldCard
                                text={interviewDetails.interview.notes}
                              />
                            ) : undefined
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            interviewDetails.interview.restricted
                              ? "Users must have permission to access this interview"
                              : "Everyone in the organisation can edit this interview"
                          }
                          label="Access"
                          loading={interviewDetails.loading}
                          value={
                            <Tag
                              intent={
                                interviewDetails.interview.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {interviewDetails.interview.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            interviewDetails.interview.user_access_status ===
                            "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={interviewDetails.loading}
                          value={
                            <NameValue
                              email={interviewDetails.interview.manager_name}
                              image={
                                interviewDetails.interview.manager_profile_photo
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Review and update basic information about the interview"
                    icon="manual"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(interviewDetails.interview.user_access_status === "owner" ||
                    interviewDetails.interview.user_access_status === "admin" ||
                    interviewDetails.interview.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={interviewPermissionList.loading}
                              setValueObject={setInterviewPermissionQuery}
                              setValueVariable="searchString"
                              value={interviewPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={interviewPermissionList.interview_permissions.map(
                              (interview_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={interview_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listInterviewPermissionDetailsAction(
                                          interview_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={interview_permission}
                                  />
                                );
                              }
                            )}
                            count={interviewPermissionList.count}
                            loading={interviewPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={interviewPermissionQuery.page}
                            setPageObject={setInterviewPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, interviews are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete interview */}
                  {(interviewDetails.interview.user_access_status === "owner" ||
                    interviewDetails.interview.user_access_status === "admin" ||
                    interviewDetails.interview.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              id="confirm-interview-name"
                              onChange={(e) => {
                                setInterviewDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the interview's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={interviewDeleteKey}
                            />

                            <Button
                              icon="trash"
                              intent="danger"
                              loading={interviewDelete.loading}
                              onClick={deleteInterview}
                              text="Delete this interview"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{interviewDetails.interview.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the interview"
                      icon="delete"
                      message="If you delete this interview, you will lose all
                    information associated with it."
                      messageIntent="danger"
                      title="Delete interview"
                    />
                  )}
                </div>
              }
            />

            {/* Planning */}
            <Tab
              id="interview-planning-tab"
              title="Planning"
              panel={
                <div>
                  {/* Contact summary */}
                  <TabSection2
                    content={
                      <TabSectionField
                        bottom
                        label={
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                interviewDetails.interview.contact_data
                                  ? interviewDetails.interview.contact_data
                                      .profile_photo
                                    ? interviewDetails.interview.contact_data
                                        .profile_photo
                                    : "/images/no_photo.png"
                                  : undefined
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "150px",
                                margin: "10px 0 10px 0",
                                objectFit: "contain",
                                width: "150px",
                              }}
                            />

                            <Text ellipsize>
                              {interviewDetails.interview.contact_data
                                ? interviewDetails.interview.contact_data.name
                                : undefined}
                            </Text>
                          </div>
                        }
                        value={
                          <TabSectionFieldMultifieldCard
                            children={
                              <>
                                {/* Contact details */}
                                <Callout
                                  style={{
                                    margin: "0 0 20px 0",
                                    padding: "10px 15px 10px 15px",
                                  }}
                                >
                                  <Icon
                                    color={
                                      lightMode ? Colors.GRAY1 : Colors.GRAY5
                                    }
                                    icon="following"
                                  />

                                  <span style={{ marginLeft: "10px" }}>
                                    <strong>Contact details</strong>
                                  </span>
                                </Callout>

                                {/* Phone */}
                                <TabSectionField
                                  label="Phone"
                                  loading={interviewDetails.loading}
                                  value={
                                    interviewDetails.interview.contact_data
                                      ? interviewDetails.interview.contact_data
                                          .display_phone
                                      : undefined
                                  }
                                />

                                {/* Email */}
                                <TabSectionField
                                  label="Email"
                                  loading={interviewDetails.loading}
                                  value={
                                    <TabSectionEmailLink
                                      email={
                                        interviewDetails.interview.contact_data
                                          ? interviewDetails.interview
                                              .contact_data.email
                                          : undefined
                                      }
                                    />
                                  }
                                />

                                {/* LinkedIn page */}
                                <TabSectionField
                                  label="LinkedIn page"
                                  loading={interviewDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        url={
                                          interviewDetails.interview
                                            .contact_data
                                            ? interviewDetails.interview
                                                .contact_data.linkedin_page
                                            : undefined
                                        }
                                      />
                                    </div>
                                  }
                                />

                                {/* Notes */}
                                <TabSectionField
                                  bottom
                                  label="Notes"
                                  loading={interviewDetails.loading}
                                  value={
                                    interviewDetails.interview.contact_data
                                      ? interviewDetails.interview.contact_data
                                          .notes
                                      : undefined
                                  }
                                />

                                {/* Buyer profile */}
                                <Callout
                                  style={{
                                    margin: "20px 0 20px 0",
                                    padding: "10px 15px 10px 15px",
                                  }}
                                >
                                  <Icon
                                    color={
                                      lightMode ? Colors.GRAY1 : Colors.GRAY5
                                    }
                                    icon="user"
                                  />

                                  <span style={{ marginLeft: "10px" }}>
                                    <strong>Buyer profile</strong>
                                  </span>
                                </Callout>

                                {/* Seniority */}
                                <TabSectionField
                                  label="Seniority"
                                  loading={interviewDetails.loading}
                                  value={
                                    interviewDetails.interview.contact_data
                                      ? interviewDetails.interview.contact_data
                                          .seniority
                                      : undefined
                                  }
                                />

                                {/* Function */}
                                <TabSectionField
                                  label="Function"
                                  loading={interviewDetails.loading}
                                  value={
                                    interviewDetails.interview.contact_data
                                      ? interviewDetails.interview.contact_data
                                          .function
                                      : undefined
                                  }
                                />

                                {/* Role */}
                                <TabSectionField
                                  label="Role"
                                  loading={interviewDetails.loading}
                                  value={
                                    interviewDetails.interview.contact_data
                                      ? interviewDetails.interview.contact_data
                                          .role
                                      : undefined
                                  }
                                />

                                {/* Budget */}
                                <TabSectionField
                                  helperText="Estimated annual budget available to spend in our category"
                                  label="Budget"
                                  loading={interviewDetails.loading}
                                  value={
                                    <NumericValue
                                      currency={activeOrganisation.currency}
                                      number={
                                        interviewDetails.interview.contact_data
                                          ? interviewDetails.interview
                                              .contact_data.budget
                                          : undefined
                                      }
                                    />
                                  }
                                />

                                {/* Primary driver */}
                                <TabSectionField
                                  label="Primary driver"
                                  loading={interviewDetails.loading}
                                  value={
                                    <Tag
                                      icon={
                                        interviewDetails.interview.contact_data
                                          ? interviewDetails.interview
                                              .contact_data.primary_driver ===
                                            "Commercials"
                                            ? "manually-entered-data"
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Partnerships"
                                            ? "new-link"
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "References"
                                            ? "chat"
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Relationships"
                                            ? "new-person"
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Solution"
                                            ? "box"
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Team"
                                            ? "people"
                                            : "more"
                                          : undefined
                                      }
                                      large
                                      round
                                      style={{
                                        background: interviewDetails.interview
                                          .contact_data
                                          ? interviewDetails.interview
                                              .contact_data.primary_driver ===
                                            "Commercials"
                                            ? Colors.GOLD3
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Partnerships"
                                            ? Colors.INDIGO3
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "References"
                                            ? Colors.TURQUOISE3
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Relationships"
                                            ? Colors.ROSE3
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Solution"
                                            ? Colors.LIME3
                                            : interviewDetails.interview
                                                .contact_data.primary_driver ===
                                              "Team"
                                            ? Colors.CERULEAN3
                                            : Colors.BLUE3
                                          : undefined,
                                        color: interviewDetails.interview
                                          .contact_data
                                          ? setTextColour(
                                              interviewDetails.interview
                                                .contact_data.primary_driver ===
                                                "Commercials"
                                                ? Colors.GOLD3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .primary_driver ===
                                                  "Partnerships"
                                                ? Colors.INDIGO3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .primary_driver ===
                                                  "References"
                                                ? Colors.TURQUOISE3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .primary_driver ===
                                                  "Relationships"
                                                ? Colors.ROSE3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .primary_driver ===
                                                  "Solution"
                                                ? Colors.LIME3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .primary_driver === "Team"
                                                ? Colors.CERULEAN3
                                                : Colors.BLUE3
                                            )
                                          : undefined,
                                        textAlign: "center",
                                        minWidth: "100px",
                                      }}
                                    >
                                      {interviewDetails.interview.contact_data
                                        ? interviewDetails.interview
                                            .contact_data.primary_driver
                                        : undefined}
                                    </Tag>
                                  }
                                />

                                {/* Secondary driver */}
                                <TabSectionField
                                  label="Secondary driver"
                                  loading={interviewDetails.loading}
                                  value={
                                    <Tag
                                      icon={
                                        interviewDetails.interview.contact_data
                                          ? interviewDetails.interview
                                              .contact_data.secondary_driver ===
                                            "Commercials"
                                            ? "manually-entered-data"
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                              "Partnerships"
                                            ? "new-link"
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                              "References"
                                            ? "chat"
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                              "Relationships"
                                            ? "new-person"
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver === "Solution"
                                            ? "box"
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver === "Team"
                                            ? "people"
                                            : "more"
                                          : undefined
                                      }
                                      large
                                      round
                                      style={{
                                        background: interviewDetails.interview
                                          .contact_data
                                          ? interviewDetails.interview
                                              .contact_data.secondary_driver ===
                                            "Commercials"
                                            ? Colors.GOLD3
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                              "Partnerships"
                                            ? Colors.INDIGO3
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                              "References"
                                            ? Colors.TURQUOISE3
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                              "Relationships"
                                            ? Colors.ROSE3
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver === "Solution"
                                            ? Colors.LIME3
                                            : interviewDetails.interview
                                                .contact_data
                                                .secondary_driver === "Team"
                                            ? Colors.CERULEAN3
                                            : Colors.BLUE3
                                          : undefined,
                                        color: interviewDetails.interview
                                          .contact_data
                                          ? setTextColour(
                                              interviewDetails.interview
                                                .contact_data
                                                .secondary_driver ===
                                                "Commercials"
                                                ? Colors.GOLD3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .secondary_driver ===
                                                  "Partnerships"
                                                ? Colors.INDIGO3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .secondary_driver ===
                                                  "References"
                                                ? Colors.TURQUOISE3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .secondary_driver ===
                                                  "Relationships"
                                                ? Colors.ROSE3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .secondary_driver ===
                                                  "Solution"
                                                ? Colors.LIME3
                                                : interviewDetails.interview
                                                    .contact_data
                                                    .secondary_driver === "Team"
                                                ? Colors.CERULEAN3
                                                : Colors.BLUE3
                                            )
                                          : undefined,
                                        textAlign: "center",
                                        minWidth: "100px",
                                      }}
                                    >
                                      {interviewDetails.interview.contact_data
                                        ? interviewDetails.interview
                                            .contact_data.secondary_driver
                                        : undefined}
                                    </Tag>
                                  }
                                />

                                {/* Attitude toward us */}
                                <TabSectionField
                                  bottom
                                  helperText={
                                    interviewDetails.interview.contact_data
                                      ? interviewDetails.interview.contact_data
                                          .attitude_toward_us === 5
                                        ? "Very positive"
                                        : interviewDetails.interview
                                            .contact_data.attitude_toward_us ===
                                          4
                                        ? "Positive"
                                        : interviewDetails.interview
                                            .contact_data.attitude_toward_us ===
                                          3
                                        ? "Neutral"
                                        : interviewDetails.interview
                                            .contact_data.attitude_toward_us ===
                                          2
                                        ? "Negative"
                                        : "Very negative"
                                      : undefined
                                  }
                                  label="Attitude toward us"
                                  value={
                                    <TabSectionFieldProgressBar
                                      rating={
                                        interviewDetails.interview.contact_data
                                          ? interviewDetails.interview
                                              .contact_data.attitude_toward_us /
                                            5
                                          : undefined
                                      }
                                    />
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    }
                    help="Review the interviewee's information"
                    icon="id-number"
                    title="Contact summary"
                  />

                  {/* Client overview */}
                  {interviewDetails.interview.contact_data
                    ? interviewDetails.interview.contact_data.client_data && (
                        <TabSection2
                          content={
                            <>
                              {/* Client */}
                              <TabSectionField
                                label="Client"
                                loading={interviewDetails.loading}
                                value={
                                  <NameValue
                                    image={
                                      interviewDetails.interview.contact_data
                                        .client_data.logo
                                    }
                                    logo
                                    text={
                                      interviewDetails.interview.contact_data
                                        .client_data.name
                                    }
                                    url={`/clients/${interviewDetails.interview.contact_data.client}`}
                                  />
                                }
                              />

                              {/* About */}
                              <TabSectionField
                                label="About"
                                loading={interviewDetails.loading}
                                value={
                                  <TabSectionFieldCard
                                    text={
                                      interviewDetails.interview.contact_data
                                        .client_data.about
                                    }
                                  />
                                }
                              />

                              {/* Investment programs */}
                              <TabSectionField
                                label="Investment programs"
                                loading={interviewDetails.loading}
                                subLabel="Key initiatives funded by the client"
                                value={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {interviewDetails.interview.contact_data.client_data.client_programs.map(
                                      (program) => {
                                        return (
                                          <div
                                            key={program.id}
                                            style={{ margin: "5px" }}
                                          >
                                            <TagPopup
                                              backgroundColor={
                                                lightMode
                                                  ? Colors.FOREST2
                                                  : Colors.FOREST5
                                              }
                                              content={
                                                <ClientProgramInfo
                                                  programData={program}
                                                  hideLink
                                                />
                                              }
                                              icon="projects"
                                              text={program.name}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                              />

                              {/* Proposals */}
                              <TabSectionField
                                label="Proposals"
                                loading={interviewDetails.loading}
                                subLabel="Recent proposals to this client"
                                value={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {interviewDetails.interview.contact_data.client_data.proposals.map(
                                      (proposal) => {
                                        return (
                                          <div
                                            key={proposal.id}
                                            style={{ margin: "5px" }}
                                          >
                                            <TagPopup
                                              backgroundColor={
                                                lightMode
                                                  ? Colors.FOREST2
                                                  : Colors.FOREST5
                                              }
                                              content={
                                                <ProposalInfo
                                                  proposalData={proposal}
                                                  hideLink
                                                />
                                              }
                                              icon="manual"
                                              text={proposal.name}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                              />

                              {/* Engagements */}
                              <TabSectionField
                                bottom
                                label="Engagements"
                                loading={interviewDetails.loading}
                                subLabel="Ongoing or completed work at this client"
                                value={
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {interviewDetails.interview.contact_data.client_data.engagements.map(
                                      (engagement) => {
                                        return (
                                          <div
                                            key={engagement.id}
                                            style={{ margin: "5px" }}
                                          >
                                            <TagPopup
                                              backgroundColor={
                                                lightMode
                                                  ? Colors.FOREST2
                                                  : Colors.FOREST5
                                              }
                                              content={
                                                <EngagementInfo
                                                  engagementData={engagement}
                                                  hideLink
                                                />
                                              }
                                              icon="gantt-chart"
                                              text={engagement.name}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                }
                              />
                            </>
                          }
                          help="Understand the interviewee's organisation and our work with them"
                          icon="search-around"
                          title="Client overview"
                        />
                      )
                    : undefined}

                  {/* Market context */}
                  {interviewDetails.interview.contact_data
                    ? interviewDetails.interview.contact_data.client_data
                      ? interviewDetails.interview.contact_data.client_data
                          .market_data && (
                          <TabSection2
                            content={
                              <>
                                {/* Market */}
                                <TabSectionField
                                  label="Market"
                                  loading={interviewDetails.loading}
                                  value={
                                    <TabSectionFieldLink
                                      name={
                                        interviewDetails.interview.contact_data
                                          .client_data.market_data.name
                                      }
                                      url={`/markets/${interviewDetails.interview.contact_data.client_data.market}`}
                                    />
                                  }
                                />

                                {/* Description */}
                                <TabSectionField
                                  label="Description"
                                  loading={interviewDetails.loading}
                                  value={
                                    <TabSectionFieldCard
                                      text={
                                        interviewDetails.interview.contact_data
                                          .client_data.market_data.description
                                      }
                                    />
                                  }
                                />

                                {/* Needs */}
                                <TabSectionField
                                  label="Needs"
                                  loading={interviewDetails.loading}
                                  subLabel="Specific needs identified for this market"
                                  value={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {interviewDetails.interview.contact_data.client_data.market_data.market_needs.map(
                                        (need) => {
                                          return (
                                            <div
                                              key={need.id}
                                              style={{
                                                margin: "5px",
                                              }}
                                            >
                                              <TagPopup
                                                backgroundColor={
                                                  lightMode
                                                    ? Colors.TURQUOISE2
                                                    : Colors.TURQUOISE5
                                                }
                                                content={
                                                  <NeedInfo
                                                    hideLink
                                                    needData={need.need_data}
                                                  />
                                                }
                                                icon="clean"
                                                text={need.need_data.name}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                />

                                {/* Case studies */}
                                <TabSectionField
                                  label="Case studies"
                                  loading={interviewDetails.loading}
                                  subLabel="Case studies that you may wish to share"
                                  value={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {interviewDetails.interview.contact_data.client_data.market_data.market_case_studies.map(
                                        (case_study) => {
                                          return (
                                            <div
                                              key={case_study.id}
                                              style={{
                                                margin: "5px",
                                              }}
                                            >
                                              <TagPopup
                                                backgroundColor={
                                                  lightMode
                                                    ? Colors.VERMILION2
                                                    : Colors.VERMILION5
                                                }
                                                content={
                                                  <CaseStudyInfo
                                                    caseStudyData={
                                                      case_study.case_study_data
                                                    }
                                                    hideLink
                                                  />
                                                }
                                                icon="book"
                                                text={
                                                  case_study.case_study_data
                                                    .name
                                                }
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                />

                                {/* Insights */}
                                <TabSectionField
                                  bottom
                                  label="Insights"
                                  loading={interviewDetails.loading}
                                  subLabel="Thought leadership content that may be relevant"
                                  value={
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {interviewDetails.interview.contact_data.client_data.market_data.market_insights.map(
                                        (insight) => {
                                          return (
                                            <div
                                              key={insight.id}
                                              style={{
                                                margin: "5px",
                                              }}
                                            >
                                              <TagPopup
                                                backgroundColor={
                                                  lightMode
                                                    ? Colors.VERMILION2
                                                    : Colors.VERMILION5
                                                }
                                                content={
                                                  <InsightInfo
                                                    insightData={
                                                      insight.insight_data
                                                    }
                                                    hideLink
                                                  />
                                                }
                                                contentWidth="800px"
                                                icon="lightbulb"
                                                noOverflow
                                                text={insight.insight_data.name}
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  }
                                />
                              </>
                            }
                            help="Keep up-to-date with market developments"
                            icon="heatmap"
                            title="Market context"
                          />
                        )
                      : undefined
                    : undefined}

                  {/* Questions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          interviewDetails.interview.user_access_status ===
                          "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddQuestionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search question"
                            searching={interviewQuestionList.loading}
                            setValueObject={setInterviewQuestionQuery}
                            setValueVariable="searchString"
                            value={interviewQuestionQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={interviewQuestionList.interview_questions.map(
                            (question, i, { length }) => {
                              return (
                                <div key={question.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      question.question_number
                                        ? `${question.question_number}. ${question.name}`
                                        : question.name
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive
                                        onClick={() => {
                                          if (
                                            interviewDetails.interview
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listInterviewQuestionDetailsAction(
                                                question.id
                                              )
                                            );

                                            setUpdateQuestionDrawerIsOpen(true);
                                          }
                                        }}
                                        tag={question.theme}
                                        tagIntent="success"
                                        text={
                                          question.notes ? (
                                            question.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                        title={question.question}
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={interviewQuestionList.count}
                          loading={interviewQuestionList.loading}
                          noResultsMessage="Add questions."
                          noResultsTitle="No questions found"
                          page={interviewQuestionQuery.page}
                          setPageObject={setInterviewQuestionQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Define questions to conduct the interview"
                    icon="form"
                    title="Questions"
                  />
                </div>
              }
            />

            {/* Interview */}
            <Tab
              id="interview-interview-tab"
              title="Interview"
              panel={
                <div>
                  {/* Answers */}
                  <TabSection2
                    buttons={
                      interviewDetails.interview.contact_data ? (
                        <Button
                          icon="download"
                          intent="primary"
                          onClick={() => {
                            dispatch(
                              generatePdfDocumentAction({
                                data: {
                                  client_logo:
                                    interviewDetails.interview.contact_data
                                      .client_data.logo,
                                  client_name:
                                    interviewDetails.interview.contact_data
                                      .client_data.name,
                                  contact_email:
                                    interviewDetails.interview.contact_data
                                      .email,
                                  contact_linkedin_page:
                                    interviewDetails.interview.contact_data
                                      .linkedin_page,
                                  contact_name:
                                    interviewDetails.interview.contact_data
                                      .name,
                                  contact_phone:
                                    interviewDetails.interview.contact_data
                                      .display_phone,
                                  contact_profile_photo:
                                    interviewDetails.interview.contact_data
                                      .profile_photo,
                                  date: interviewDetails.interview.date,
                                  interview_questions:
                                    interviewQuestionList.interview_questions,
                                  manager_name:
                                    interviewDetails.interview.manager_name,
                                  manager_profile_photo:
                                    interviewDetails.interview
                                      .manager_profile_photo,
                                  name: interviewDetails.interview.name,
                                  notes: interviewDetails.interview.notes,
                                  status: interviewDetails.interview.status,
                                },
                                type: "interview_questionnaire",
                              })
                            );

                            setInterviewQuestionnaireIsOpen(true);
                          }}
                          text="Download"
                        />
                      ) : undefined
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search question"
                            searching={interviewQuestionList.loading}
                            setValueObject={setInterviewQuestionQuery}
                            setValueVariable="searchString"
                            value={interviewQuestionQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={interviewQuestionList.interview_questions.map(
                            (question, i, { length }) => {
                              return (
                                <div key={question.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      question.question_number
                                        ? `${question.question_number}. ${question.name}`
                                        : question.name
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive
                                        onClick={() => {
                                          if (
                                            interviewDetails.interview
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listInterviewQuestionDetailsAction(
                                                question.id
                                              )
                                            );

                                            setUpdateAnswerDrawerIsOpen(true);
                                          }
                                        }}
                                        tag={question.theme}
                                        tagIntent="success"
                                        text={
                                          <>
                                            {question.notes && (
                                              <div
                                                style={{ marginBottom: "20px" }}
                                              >
                                                {question.notes}
                                              </div>
                                            )}

                                            <Card>
                                              {question.answer ? (
                                                question.answer
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No answer.
                                                </span>
                                              )}
                                            </Card>
                                          </>
                                        }
                                        title={question.question}
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={interviewQuestionList.count}
                          loading={interviewQuestionList.loading}
                          noResultsMessage="Add questions."
                          noResultsTitle="No questions found"
                          page={interviewQuestionQuery.page}
                          setPageObject={setInterviewQuestionQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Enter the answers to your questions"
                    icon="manually-entered-data"
                    title="Answers"
                  />

                  {/* Analysis */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          interviewDetails.interview.user_access_status ===
                          "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listInterviewDetailsAction(id));

                          setUpdateAnalysisDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Key findings */}
                        <TabSectionField
                          bottom
                          label="Key findings"
                          loading={interviewDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={<>{interviewDetails.interview.findings}</>}
                            />
                          }
                        />
                      </div>
                    }
                    help="Analyse the answers and summarize the key insights"
                    icon="predictive-analysis"
                    title="Analysis"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default InterviewDetailScreen;
