// Constant imports
import { PAGE_SIZE } from "../constants/applicationConstants";

import {
  LEAVE_CATEGORY_LIST_REQUEST,
  LEAVE_CATEGORY_LIST_SUCCESS,
  LEAVE_CATEGORY_LIST_FAIL,
  LEAVE_CATEGORY_LIST_RESET,
  LEAVE_CATEGORY_CREATE_REQUEST,
  LEAVE_CATEGORY_CREATE_SUCCESS,
  LEAVE_CATEGORY_CREATE_FAIL,
  LEAVE_CATEGORY_CREATE_RESET,
  LEAVE_CATEGORY_DETAILS_REQUEST,
  LEAVE_CATEGORY_DETAILS_SUCCESS,
  LEAVE_CATEGORY_DETAILS_FAIL,
  LEAVE_CATEGORY_DETAILS_RESET,
  LEAVE_CATEGORY_UPDATE_REQUEST,
  LEAVE_CATEGORY_UPDATE_SUCCESS,
  LEAVE_CATEGORY_UPDATE_FAIL,
  LEAVE_CATEGORY_UPDATE_RESET,
  LEAVE_CATEGORY_DELETE_REQUEST,
  LEAVE_CATEGORY_DELETE_SUCCESS,
  LEAVE_CATEGORY_DELETE_FAIL,
  LEAVE_CATEGORY_DELETE_RESET,
  LEAVE_CATEGORY_DOWNLOAD_REQUEST,
  LEAVE_CATEGORY_DOWNLOAD_SUCCESS,
  LEAVE_CATEGORY_DOWNLOAD_FAIL,
  LEAVE_CATEGORY_DOWNLOAD_RESET,
  LEAVE_CATEGORY_UPLOAD_BEGIN,
  LEAVE_CATEGORY_UPLOAD_REQUEST,
  LEAVE_CATEGORY_UPLOAD_SUCCESS,
  LEAVE_CATEGORY_UPLOAD_FAIL,
  LEAVE_CATEGORY_UPLOAD_END,
  LEAVE_CATEGORY_UPLOAD_RESET,
} from "../constants/leaveCategoryConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listLeaveCategoriesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_CATEGORY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getLeaveCategories(
        params,
        token
      );

      dispatch({
        type: LEAVE_CATEGORY_LIST_SUCCESS,

        payload: {
          count: data.count,
          leave_categories: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: LEAVE_CATEGORY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListLeaveCategoriesAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_LIST_RESET });
};

// Create
export const createLeaveCategoryAction =
  (leaveCategory) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_CATEGORY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createLeaveCategory(
        token,
        leaveCategory
      );

      dispatch({
        type: LEAVE_CATEGORY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LEAVE_CATEGORY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateLeaveCategoryAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_CREATE_RESET });
};

// List details
export const listLeaveCategoryDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_CATEGORY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getLeaveCategory(id, token);

      dispatch({
        type: LEAVE_CATEGORY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LEAVE_CATEGORY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListLeaveCategoryDetailsAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_DETAILS_RESET });
};

// Update
export const updateLeaveCategoryAction =
  (id, leaveCategory) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_CATEGORY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateLeaveCategory(
        id,
        token,
        leaveCategory
      );

      dispatch({
        type: LEAVE_CATEGORY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: LEAVE_CATEGORY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateLeaveCategoryAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_UPDATE_RESET });
};

// Delete
export const deleteLeaveCategoryAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LEAVE_CATEGORY_DELETE_REQUEST });

    const {
      userLogin: { token },
    } = getState();

    const { data } = await cortixDataService.deleteLeaveCategory(id, token);

    dispatch({
      type: LEAVE_CATEGORY_DELETE_SUCCESS,

      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LEAVE_CATEGORY_DELETE_FAIL,

      payload: error,
    });
  }
};

// Reset delete
export const resetDeleteLeaveCategoryAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_DELETE_RESET });
};

// Download
export const downloadLeaveCategoriesAction =
  (organisation, numRecords) => async (dispatch, getState) => {
    try {
      dispatch({ type: LEAVE_CATEGORY_DOWNLOAD_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      var leaveCategories = [];

      for (var page = 1; page <= Math.ceil(numRecords / PAGE_SIZE); page++) {
        var { data } = await cortixDataService.getLeaveCategories(
          { page: page, organisation: organisation },
          token
        );

        leaveCategories = leaveCategories.concat(data.results);
      }

      dispatch({
        type: LEAVE_CATEGORY_DOWNLOAD_SUCCESS,

        payload: {
          leave_categories: leaveCategories.filter(
            (category) => !category.default
          ),
        },
      });
    } catch (error) {
      dispatch({
        type: LEAVE_CATEGORY_DOWNLOAD_FAIL,

        payload: error,
      });
    }
  };

// Reset download
export const resetDownloadLeaveCategoriesAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_DOWNLOAD_RESET });
};

// Upload
export const uploadLeaveCategoriesAction =
  (id, records) => async (dispatch, getState) => {
    dispatch({
      type: LEAVE_CATEGORY_UPLOAD_BEGIN,
    });

    const {
      userLogin: { token },
    } = getState();

    var progress = 0;
    var recordsFailed = [];

    for (var recordNum = 0; recordNum < records.length; recordNum++) {
      progress = (recordNum + 1) / records.length;

      try {
        dispatch({ type: LEAVE_CATEGORY_UPLOAD_REQUEST });

        if (records[recordNum].delete === "yes" && records[recordNum].id) {
          await cortixDataService.deleteLeaveCategory(
            records[recordNum].id,
            token
          );
        } else if (records[recordNum].id) {
          await cortixDataService.updateLeaveCategory(
            records[recordNum].id,
            token,
            {
              code: records[recordNum].code,
              description: records[recordNum].description,
              name: records[recordNum].name,
              organisation: id,
            }
          );
        } else {
          await cortixDataService.createLeaveCategory(token, {
            code: records[recordNum].code,
            description: records[recordNum].description,
            name: records[recordNum].name,
            organisation: id,
          });
        }

        dispatch({
          type: LEAVE_CATEGORY_UPLOAD_SUCCESS,

          payload: progress,
        });
      } catch (error) {
        recordsFailed.push(records[recordNum]);

        dispatch({
          type: LEAVE_CATEGORY_UPLOAD_FAIL,

          payload: {
            error: error,
            progress: progress,
            records_failed: recordsFailed,
          },
        });
      }
    }

    dispatch({ type: LEAVE_CATEGORY_UPLOAD_END });
  };

// Reset upload
export const resetUploadLeaveCategoriesAction = () => async (dispatch) => {
  dispatch({ type: LEAVE_CATEGORY_UPLOAD_RESET });
};
