// Constant imports
import {
  SERVICE_DELIVERABLE_LIST_REQUEST,
  SERVICE_DELIVERABLE_LIST_SUCCESS,
  SERVICE_DELIVERABLE_LIST_FAIL,
  SERVICE_DELIVERABLE_LIST_RESET,
  SERVICE_DELIVERABLE_CREATE_REQUEST,
  SERVICE_DELIVERABLE_CREATE_SUCCESS,
  SERVICE_DELIVERABLE_CREATE_FAIL,
  SERVICE_DELIVERABLE_CREATE_RESET,
  SERVICE_DELIVERABLE_DETAILS_REQUEST,
  SERVICE_DELIVERABLE_DETAILS_SUCCESS,
  SERVICE_DELIVERABLE_DETAILS_FAIL,
  SERVICE_DELIVERABLE_DETAILS_RESET,
  SERVICE_DELIVERABLE_UPDATE_REQUEST,
  SERVICE_DELIVERABLE_UPDATE_SUCCESS,
  SERVICE_DELIVERABLE_UPDATE_FAIL,
  SERVICE_DELIVERABLE_UPDATE_RESET,
  SERVICE_DELIVERABLE_DELETE_REQUEST,
  SERVICE_DELIVERABLE_DELETE_SUCCESS,
  SERVICE_DELIVERABLE_DELETE_FAIL,
  SERVICE_DELIVERABLE_DELETE_RESET,
  SERVICE_DELIVERABLE_UPLOAD_BEGIN,
  SERVICE_DELIVERABLE_UPLOAD_REQUEST,
  SERVICE_DELIVERABLE_UPLOAD_SUCCESS,
  SERVICE_DELIVERABLE_UPLOAD_FAIL,
  SERVICE_DELIVERABLE_UPLOAD_END,
  SERVICE_DELIVERABLE_UPLOAD_RESET,
} from "../constants/serviceDeliverableConstants";

// List all
export const serviceDeliverableListReducer = (
  state = {
    loading: false,
    count: 0,
    service_deliverables: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_DELIVERABLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_DELIVERABLE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        service_deliverables: action.payload.service_deliverables,
        success: true,
        error: "",
      };

    case SERVICE_DELIVERABLE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        service_deliverables: [],
        success: false,
        error: action.payload,
      };

    case SERVICE_DELIVERABLE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        service_deliverables: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const serviceDeliverableCreateReducer = (
  state = {
    loading: false,
    service_deliverable: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_DELIVERABLE_CREATE_REQUEST:
      return {
        loading: true,
        service_deliverable: state.service_deliverable,
        success: false,
        error: "",
      };

    case SERVICE_DELIVERABLE_CREATE_SUCCESS:
      return {
        loading: false,
        service_deliverable: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_DELIVERABLE_CREATE_FAIL:
      return {
        loading: false,
        service_deliverable: state.service_deliverable,
        success: false,
        error: action.payload,
      };

    case SERVICE_DELIVERABLE_CREATE_RESET:
      return {
        loading: false,
        service_deliverable: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const serviceDeliverableDetailsReducer = (
  state = {
    loading: false,
    service_deliverable: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_DELIVERABLE_DETAILS_REQUEST:
      return {
        loading: true,
        service_deliverable: state.service_deliverable,
        success: false,
        error: "",
      };

    case SERVICE_DELIVERABLE_DETAILS_SUCCESS:
      return {
        loading: false,
        service_deliverable: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_DELIVERABLE_DETAILS_FAIL:
      return {
        loading: false,
        service_deliverable: state.service_deliverable,
        success: false,
        error: action.payload,
      };

    case SERVICE_DELIVERABLE_DETAILS_RESET:
      return {
        loading: false,
        service_deliverable: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const serviceDeliverableUpdateReducer = (
  state = {
    loading: false,
    service_deliverable: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_DELIVERABLE_UPDATE_REQUEST:
      return {
        loading: true,
        service_deliverable: state.service_deliverable,
        success: false,
        error: "",
      };

    case SERVICE_DELIVERABLE_UPDATE_SUCCESS:
      return {
        loading: false,
        service_deliverable: action.payload,
        success: true,
        error: "",
      };

    case SERVICE_DELIVERABLE_UPDATE_FAIL:
      return {
        loading: false,
        service_deliverable: state.service_deliverable,
        success: false,
        error: action.payload,
      };

    case SERVICE_DELIVERABLE_UPDATE_RESET:
      return {
        loading: false,
        service_deliverable: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const serviceDeliverableDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case SERVICE_DELIVERABLE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case SERVICE_DELIVERABLE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case SERVICE_DELIVERABLE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case SERVICE_DELIVERABLE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Upload
export const serviceDeliverableUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case SERVICE_DELIVERABLE_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case SERVICE_DELIVERABLE_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SERVICE_DELIVERABLE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
      };

    case SERVICE_DELIVERABLE_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case SERVICE_DELIVERABLE_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case SERVICE_DELIVERABLE_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
