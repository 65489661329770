// Constant imports
import {
  INVOICE_OTHER_CREDIT_LIST_REQUEST,
  INVOICE_OTHER_CREDIT_LIST_SUCCESS,
  INVOICE_OTHER_CREDIT_LIST_FAIL,
  INVOICE_OTHER_CREDIT_LIST_RESET,
} from "../constants/invoiceOtherCreditConstants";

// List all
export const invoiceOtherCreditListReducer = (
  state = {
    loading: false,
    count: 0,
    invoice_other_credits: [],
    totalNet: 0,
    totalNetQuote: 0,
    totalTax: 0,
    totalValue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INVOICE_OTHER_CREDIT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_OTHER_CREDIT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        invoice_other_credits: action.payload.invoice_other_credits,
        totalNet: action.payload.totalNet,
        totalNetQuote: action.payload.totalNetQuote,
        totalTax: action.payload.totalTax,
        totalValue: action.payload.totalValue,
        success: true,
        error: "",
      };

    case INVOICE_OTHER_CREDIT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        invoice_other_credits: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: action.payload,
      };

    case INVOICE_OTHER_CREDIT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        invoice_other_credits: [],
        totalNet: 0,
        totalNetQuote: 0,
        totalTax: 0,
        totalValue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
