// Component imports
import { AppToaster } from "../components/AppToaster";
import { Button } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Contact
import {
  createCommunicationAction,
  listContactDetailsAction,
  resetCreateCommunicationAction,
} from "../actions/contactActions";

// Contact action
import {
  listContactActionsAction,
  listContactActionDetailsAction,
} from "../actions/contactActionActions";

// Main function
function CreateCommunicationActionButton({
  channel,
  contactData,
  openActionDrawer,
}) {
  // Refresh data
  const [data, setData] = useState({});

  useEffect(() => {
    if (contactData) {
      setData(contactData);
    }
  }, [contactData]);

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Contact
  const contactCreateCommunicationAction = useSelector(
    (state) => state.contactCreateCommunicationAction
  );

  // Create
  const [actionCreated, setActionCreated] = useState(false);

  useEffect(() => {
    switch (channel) {
      case "Email":
        data.email_next_event
          ? setActionCreated(true)
          : setActionCreated(false);

        break;

      case "In-person":
        data.in_person_next_event
          ? setActionCreated(true)
          : setActionCreated(false);

        break;

      default:
        data.phone_next_event
          ? setActionCreated(true)
          : setActionCreated(false);
    }
  }, [channel, data]);

  const createAction = () => {
    // Prepare data
    const action_data = new FormData();

    action_data.append("channel", channel);

    // Dispatch action
    dispatch(createCommunicationAction(data.id, action_data));
  };

  useEffect(() => {
    if (
      contactCreateCommunicationAction.success &&
      contactCreateCommunicationAction.action.channel === channel
    ) {
      AppToaster.show({
        intent: "success",
        message: "Action created.",
      });

      //setActionCreated(true);

      dispatch(listContactDetailsAction(data.id));

      dispatch(resetCreateCommunicationAction());

      dispatch(listContactActionsAction({ contact: data.id }));
    }
    // eslint-disable-next-line
  }, [contactCreateCommunicationAction.success]);

  // View
  const viewAction = () => {
    switch (channel) {
      case "Email":
        dispatch(listContactActionDetailsAction(data.email_next_event));

        openActionDrawer();

        break;

      case "In-person":
        dispatch(listContactActionDetailsAction(data.in_person_next_event));

        openActionDrawer();

        break;

      default:
        dispatch(listContactActionDetailsAction(data.phone_next_event));

        openActionDrawer();
    }
  };

  // JSX UI code
  return (
    <Button
      intent={actionCreated ? "primary" : "success"}
      onClick={actionCreated ? viewAction : createAction}
      small
      style={{ width: "50px" }}
      text={actionCreated ? "View" : "Add"}
    />
  );
}

export default CreateCommunicationActionButton;
