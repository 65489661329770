// Constant imports
import {
  INTERVIEW_PERMISSION_LIST_REQUEST,
  INTERVIEW_PERMISSION_LIST_SUCCESS,
  INTERVIEW_PERMISSION_LIST_FAIL,
  INTERVIEW_PERMISSION_LIST_RESET,
  INTERVIEW_PERMISSION_CREATE_REQUEST,
  INTERVIEW_PERMISSION_CREATE_SUCCESS,
  INTERVIEW_PERMISSION_CREATE_FAIL,
  INTERVIEW_PERMISSION_CREATE_RESET,
  INTERVIEW_PERMISSION_DETAILS_REQUEST,
  INTERVIEW_PERMISSION_DETAILS_SUCCESS,
  INTERVIEW_PERMISSION_DETAILS_FAIL,
  INTERVIEW_PERMISSION_DETAILS_RESET,
  INTERVIEW_PERMISSION_UPDATE_REQUEST,
  INTERVIEW_PERMISSION_UPDATE_SUCCESS,
  INTERVIEW_PERMISSION_UPDATE_FAIL,
  INTERVIEW_PERMISSION_UPDATE_RESET,
  INTERVIEW_PERMISSION_DELETE_REQUEST,
  INTERVIEW_PERMISSION_DELETE_SUCCESS,
  INTERVIEW_PERMISSION_DELETE_FAIL,
  INTERVIEW_PERMISSION_DELETE_RESET,
} from "../constants/interviewPermissionConstants";

// List all
export const interviewPermissionListReducer = (
  state = {
    loading: false,
    count: 0,
    interview_permissions: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INTERVIEW_PERMISSION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INTERVIEW_PERMISSION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        interview_permissions: action.payload.interview_permissions,
        success: true,
        error: "",
      };

    case INTERVIEW_PERMISSION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        interview_permissions: [],
        success: false,
        error: action.payload,
      };

    case INTERVIEW_PERMISSION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        interview_permissions: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const interviewPermissionCreateReducer = (
  state = {
    loading: false,
    interview_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INTERVIEW_PERMISSION_CREATE_REQUEST:
      return {
        loading: true,
        interview_permission: state.interview_permission,
        success: false,
        error: "",
      };

    case INTERVIEW_PERMISSION_CREATE_SUCCESS:
      return {
        loading: false,
        interview_permission: action.payload,
        success: true,
        error: "",
      };

    case INTERVIEW_PERMISSION_CREATE_FAIL:
      return {
        loading: false,
        interview_permission: state.interview_permission,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_PERMISSION_CREATE_RESET:
      return {
        loading: false,
        interview_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const interviewPermissionDetailsReducer = (
  state = {
    loading: false,
    interview_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INTERVIEW_PERMISSION_DETAILS_REQUEST:
      return {
        loading: true,
        interview_permission: state.interview_permission,
        success: false,
        error: "",
      };

    case INTERVIEW_PERMISSION_DETAILS_SUCCESS:
      return {
        loading: false,
        interview_permission: action.payload,
        success: true,
        error: "",
      };

    case INTERVIEW_PERMISSION_DETAILS_FAIL:
      return {
        loading: false,
        interview_permission: state.interview_permission,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_PERMISSION_DETAILS_RESET:
      return {
        loading: false,
        interview_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const interviewPermissionUpdateReducer = (
  state = {
    loading: false,
    interview_permission: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case INTERVIEW_PERMISSION_UPDATE_REQUEST:
      return {
        loading: true,
        interview_permission: state.interview_permission,
        success: false,
        error: "",
      };

    case INTERVIEW_PERMISSION_UPDATE_SUCCESS:
      return {
        loading: false,
        interview_permission: action.payload,
        success: true,
        error: "",
      };

    case INTERVIEW_PERMISSION_UPDATE_FAIL:
      return {
        loading: false,
        interview_permission: state.interview_permission,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_PERMISSION_UPDATE_RESET:
      return {
        loading: false,
        interview_permission: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const interviewPermissionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case INTERVIEW_PERMISSION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case INTERVIEW_PERMISSION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case INTERVIEW_PERMISSION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case INTERVIEW_PERMISSION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
