// Library imports
import { Colors, Divider, H5, UL } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardBookingsChart2 from "../components/DataCardBookingsChart2";

// Main function
function StaffBookingsCard2({ bookingProfile, setStaffQuery }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardBookingsChart2
      comments={
        <>
          <H5>Bookings</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>Hours booked for the selected staff, including:</p>

          <UL>
            <li>Confirmed: Effort assigned with a high level of confidence.</li>

            <li>Tentative: Work allocated with a low level of confidence.</li>

            <li>
              Realised: Actual work done according to the timesheets that have
              been approved.
            </li>
          </UL>
        </>
      }
      data={bookingProfile}
      fill
      noData={!bookingProfile || Object.keys(bookingProfile).length === 0}
      setStaffQuery={setStaffQuery}
      title="Bookings"
      units="Hours"
    />
  );
}

export default StaffBookingsCard2;
