export const CURRENCY_EXCHANGE_RATE_LIST_REQUEST =
  "CURRENCY_EXCHANGE_RATE_LIST_REQUEST";
export const CURRENCY_EXCHANGE_RATE_LIST_SUCCESS =
  "CURRENCY_EXCHANGE_RATE_LIST_SUCCESS";
export const CURRENCY_EXCHANGE_RATE_LIST_FAIL =
  "CURRENCY_EXCHANGE_RATE_LIST_FAIL";
export const CURRENCY_EXCHANGE_RATE_LIST_RESET =
  "CURRENCY_EXCHANGE_RATE_LIST_RESET";

export const CURRENCY_EXCHANGE_RATE_CREATE_REQUEST =
  "CURRENCY_EXCHANGE_RATE_CREATE_REQUEST";
export const CURRENCY_EXCHANGE_RATE_CREATE_SUCCESS =
  "CURRENCY_EXCHANGE_RATE_CREATE_SUCCESS";
export const CURRENCY_EXCHANGE_RATE_CREATE_FAIL =
  "CURRENCY_EXCHANGE_RATE_CREATE_FAIL";
export const CURRENCY_EXCHANGE_RATE_CREATE_RESET =
  "CURRENCY_EXCHANGE_RATE_CREATE_RESET";

export const CURRENCY_EXCHANGE_RATE_DETAILS_REQUEST =
  "CURRENCY_EXCHANGE_RATE_DETAILS_REQUEST";
export const CURRENCY_EXCHANGE_RATE_DETAILS_SUCCESS =
  "CURRENCY_EXCHANGE_RATE_DETAILS_SUCCESS";
export const CURRENCY_EXCHANGE_RATE_DETAILS_FAIL =
  "CURRENCY_EXCHANGE_RATE_DETAILS_FAIL";
export const CURRENCY_EXCHANGE_RATE_DETAILS_RESET =
  "CURRENCY_EXCHANGE_RATE_DETAILS_RESET";

export const CURRENCY_EXCHANGE_RATE_UPDATE_REQUEST =
  "CURRENCY_EXCHANGE_RATE_UPDATE_REQUEST";
export const CURRENCY_EXCHANGE_RATE_UPDATE_SUCCESS =
  "CURRENCY_EXCHANGE_RATE_UPDATE_SUCCESS";
export const CURRENCY_EXCHANGE_RATE_UPDATE_FAIL =
  "CURRENCY_EXCHANGE_RATE_UPDATE_FAIL";
export const CURRENCY_EXCHANGE_RATE_UPDATE_RESET =
  "CURRENCY_EXCHANGE_RATE_UPDATE_RESET";

export const CURRENCY_EXCHANGE_RATE_DELETE_REQUEST =
  "CURRENCY_EXCHANGE_RATE_DELETE_REQUEST";
export const CURRENCY_EXCHANGE_RATE_DELETE_SUCCESS =
  "CURRENCY_EXCHANGE_RATE_DELETE_SUCCESS";
export const CURRENCY_EXCHANGE_RATE_DELETE_FAIL =
  "CURRENCY_EXCHANGE_RATE_DELETE_FAIL";
export const CURRENCY_EXCHANGE_RATE_DELETE_RESET =
  "CURRENCY_EXCHANGE_RATE_DELETE_RESET";
