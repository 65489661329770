// Library imports
import { Card, Classes, Colors, H6, Tag, Text } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Utility imports
import setTextColour from "../utilities/setTextColour";

// Main function
function Matrix({
  bottomColor,
  colors,
  colorDirection,
  data,
  intents,
  interactive,
  itemNameVariable,
  leftColumnWidth,
  middleColor,
  onClickAccessTest,
  onClickListDetailsAction,
  onClickSetDrawerIsOpen,
  tableTop,
  tagColor,
  tagHoverColor,
  topColor,
  xAxisCategories,
  xAxisDisplayCategories,
  xAxisFilterVariable,
  xAxisLabel,
  yAxisCategories,
  yAxisDisplayCategories,
  yAxisFilterVariable,
  yAxisLabel,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Update items
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (data) {
      setItems(data);
    }
  }, [data]);

  // Set tag color
  const [newTagColor, setNewTagColor] = useState([]);

  useEffect(() => {
    if (data && tagColor) {
      data.map((item) =>
        setNewTagColor((prevState) => {
          return {
            ...prevState,
            [item.id]: tagColor,
          };
        })
      );
    }
  }, [data, tagColor]);

  // JSX UI code
  return (
    <div
      style={{
        background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
        margin: tableTop ? "0" : "0 0 20px 0",
        padding: tableTop ? "10px 10px 1px 10px" : "10px",
      }}
    >
      <Card
        className={Classes.TEXT_SMALL}
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
        }}
      >
        {/* First row */}
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              maxWidth: leftColumnWidth,
              minWidth: leftColumnWidth,
              padding: "10px",
            }}
          ></div>

          <div
            style={{
              flex: 1,
              padding: "10px 0 10px 0",
              textAlign: "center",
            }}
          >
            <H6 style={{ margin: "0" }}>
              <Text ellipsize>{xAxisLabel}</Text>
            </H6>
          </div>
        </div>

        {/* Second row */}
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              maxWidth: leftColumnWidth,
              minWidth: leftColumnWidth,
              padding: "10px",
            }}
          >
            <H6 style={{ margin: "0" }}>
              <Text ellipsize>{yAxisLabel}</Text>
            </H6>
          </div>

          {xAxisCategories.map((category, i) => {
            return (
              <div
                key={category}
                style={{
                  flex: 1,
                  padding: "10px 0 10px 0",
                  textAlign: "center",
                }}
              >
                <strong>
                  <Text ellipsize>
                    {xAxisDisplayCategories
                      ? xAxisDisplayCategories[i]
                      : category}
                  </Text>
                </strong>
              </div>
            );
          })}
        </div>

        {/* Data rows */}
        <div
          style={{
            background:
              bottomColor && colorDirection && middleColor && topColor
                ? `linear-gradient(${colorDirection}, ${topColor}80 25%, ${middleColor}80 50%, ${bottomColor}80 75%)`
                : undefined,
            display: "grid",
            gridAutoRows: "1fr",
          }}
        >
          {yAxisCategories.map((yCategory, yCategoryIndex) => {
            return (
              <div
                key={yCategory}
                style={{
                  display: "flex",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    background: lightMode
                      ? Colors.LIGHT_GRAY5
                      : Colors.DARK_GRAY2,
                    display: "flex",
                    maxWidth: leftColumnWidth,
                    minWidth: leftColumnWidth,
                    padding: "10px",
                  }}
                >
                  <strong>
                    <Text ellipsize>
                      {yAxisDisplayCategories
                        ? yAxisDisplayCategories[yCategoryIndex]
                        : yCategory}
                    </Text>
                  </strong>
                </div>

                {xAxisCategories.map((xCategory, xCategoryIndex) => {
                  return (
                    <div
                      key={xCategory}
                      style={{
                        background: colors
                          ? colors[yCategoryIndex][xCategoryIndex]
                          : undefined,
                        borderBottom: `1px solid ${
                          lightMode ? Colors.LIGHT_GRAY1 : Colors.GRAY1
                        }`,
                        borderLeft:
                          xCategoryIndex === 0
                            ? `1px solid ${
                                lightMode ? Colors.LIGHT_GRAY1 : Colors.GRAY1
                              }`
                            : undefined,
                        borderRight: `1px solid ${
                          lightMode ? Colors.LIGHT_GRAY1 : Colors.GRAY1
                        }`,
                        borderTop:
                          yCategoryIndex === 0
                            ? `1px solid ${
                                lightMode ? Colors.LIGHT_GRAY1 : Colors.GRAY1
                              }`
                            : undefined,
                        display: "flex",
                        flex: 1,
                        flexWrap: "wrap",
                        padding: "10px",
                      }}
                    >
                      {items
                        .filter(
                          (item) => item[xAxisFilterVariable] === xCategory
                        )
                        .filter(
                          (item) => item[yAxisFilterVariable] === yCategory
                        )
                        .map((item) => {
                          return (
                            <Tag
                              intent={
                                intents
                                  ? intents[yCategoryIndex][xCategoryIndex]
                                  : undefined
                              }
                              interactive={interactive}
                              key={item.id}
                              onClick={() => {
                                if (onClickAccessTest) {
                                  dispatch(onClickListDetailsAction(item.id));

                                  onClickSetDrawerIsOpen(true);
                                }
                              }}
                              onMouseEnter={() =>
                                tagHoverColor &&
                                setNewTagColor((prevState) => {
                                  return {
                                    ...prevState,
                                    [item.id]: tagHoverColor,
                                  };
                                })
                              }
                              onMouseLeave={() =>
                                tagColor &&
                                setNewTagColor((prevState) => {
                                  return {
                                    ...prevState,
                                    [item.id]: tagColor,
                                  };
                                })
                              }
                              round
                              style={{
                                background: tagColor
                                  ? newTagColor[item.id]
                                  : undefined,
                                color: tagColor
                                  ? setTextColour(newTagColor[item.id])
                                  : undefined,
                                margin: "0 5px 5px 0",
                                maxHeight: "20px",
                                maxWidth: "200px",
                                textAlign: "center",
                              }}
                            >
                              {itemNameVariable
                                ? item[itemNameVariable]
                                : item.name}
                            </Tag>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
}

export default Matrix;
