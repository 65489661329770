// Constant imports
import {
  HUBSPOT_AUTHORISE_REQUEST,
  HUBSPOT_AUTHORISE_SUCCESS,
  HUBSPOT_AUTHORISE_FAIL,
  HUBSPOT_AUTHORISE_RESET,
  HUBSPOT_DEAUTHORISE_SUCCESS,
  HUBSPOT_DEAUTHORISE_REQUEST,
  HUBSPOT_DEAUTHORISE_FAIL,
  HUBSPOT_DEAUTHORISE_RESET,
  HUBSPOT_GET_CONTACT_REQUEST,
  HUBSPOT_GET_CONTACT_SUCCESS,
  HUBSPOT_GET_CONTACT_FAIL,
  HUBSPOT_GET_CONTACT_RESET,
  HUBSPOT_SYNC_CONTACTS_REQUEST,
  HUBSPOT_SYNC_CONTACTS_SUCCESS,
  HUBSPOT_SYNC_CONTACTS_FAIL,
  HUBSPOT_SYNC_CONTACTS_RESET,
} from "../constants/hubspotConstants";

// Authorise
export const hubspotAuthoriseReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case HUBSPOT_AUTHORISE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HUBSPOT_AUTHORISE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case HUBSPOT_AUTHORISE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case HUBSPOT_AUTHORISE_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Deauthorise
export const hubspotDeauthoriseReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case HUBSPOT_DEAUTHORISE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HUBSPOT_DEAUTHORISE_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case HUBSPOT_DEAUTHORISE_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case HUBSPOT_DEAUTHORISE_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Get contact
export const hubspotGetContactReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case HUBSPOT_GET_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HUBSPOT_GET_CONTACT_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case HUBSPOT_GET_CONTACT_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case HUBSPOT_GET_CONTACT_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Sync contacts
export const hubspotContactSyncReducer = (
  state = { loading: false, result: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case HUBSPOT_SYNC_CONTACTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case HUBSPOT_SYNC_CONTACTS_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        success: true,
        error: "",
      };

    case HUBSPOT_SYNC_CONTACTS_FAIL:
      return {
        loading: false,
        result: state.result,
        success: false,
        error: action.payload,
      };

    case HUBSPOT_SYNC_CONTACTS_RESET:
      return { loading: false, result: {}, success: false, error: "" };

    default:
      return state;
  }
};
