// Library imports
import { Button, Classes, Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";
import NumericValue from "../components/NumericValue";

// Redux action imports
// Engagement
import { listEngagementsAction } from "../actions/engagementActions";

// Main function
function EngagementInfo({
  additionalInfo,
  engagementData,
  engagementId,
  hideLink,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Engagement
  const engagementList = useSelector((state) => state.engagementList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh engagement data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [engagement, setEngagement] = useState({});

  useEffect(() => {
    if (engagementData) {
      setEngagement(engagementData);

      setLoading(false);
    }
  }, [engagementData]);

  useEffect(() => {
    if (!engagementData && !engagementList.loading) {
      if (engagementList.success) {
        const thisEngagement = engagementList.engagements.filter(
          (engagement) => engagement.id === engagementId
        );

        if (thisEngagement.length > 0) {
          setEngagement(thisEngagement[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listEngagementsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (engagementList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [engagementList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <H5 style={{ margin: "0 10px 0 0" }}>{engagement.name}</H5>

            {!hideLink && (
              <Button
                icon="open-application"
                intent="primary"
                minimal
                onClick={() =>
                  window.open(
                    `/engagements/${engagement.id}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
                style={{
                  color: lightMode ? Colors.BLUE5 : Colors.BLUE3,
                }}
              />
            )}
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Client</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NameValue
              image={engagement.client_logo}
              invertColor
              logo
              text={engagement.client_name}
              url={`/clients/${engagement.client}`}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Value</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NumericValue
              currency={activeOrganisation.currency}
              fillTag
              number={engagement.value}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Status</strong>
          </p>

          <p>
            {
              <Tag
                intent={
                  engagement.status === "Amber"
                    ? "warning"
                    : engagement.status === "Green"
                    ? "success"
                    : engagement.status === "Red"
                    ? "danger"
                    : "primary"
                }
                large
              >
                {engagement.status}
              </Tag>
            }
          </p>

          {engagement.sponsor && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Contact</strong>
              </p>

              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NameValue
                  image={engagement.sponsor_profile_photo}
                  invertColor
                  text={engagement.sponsor_name}
                  url={`/contacts/${engagement.sponsor}`}
                />
              </div>
            </>
          )}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Comments</strong>
          </p>

          <p>
            {engagement.notes ? (
              engagement.notes
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          {additionalInfo}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Manager</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NameValue
              email={engagement.manager_name}
              image={engagement.manager_profile_photo}
              invertColor
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default EngagementInfo;
