// Library imports
import { Tag } from "@blueprintjs/core";
import React from "react";

// Main function
function LinkTag({ intent, label, onClick }) {
  // JSX UI code
  return (
    <Tag intent={intent} interactive minimal onClick={onClick}>
      {label}
    </Tag>
  );
}

export default LinkTag;
