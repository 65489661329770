// Constant imports
import {
  CLIENT_PROGRAM_LIST_REQUEST,
  CLIENT_PROGRAM_LIST_SUCCESS,
  CLIENT_PROGRAM_LIST_FAIL,
  CLIENT_PROGRAM_LIST_RESET,
  CLIENT_PROGRAM_CREATE_REQUEST,
  CLIENT_PROGRAM_CREATE_SUCCESS,
  CLIENT_PROGRAM_CREATE_FAIL,
  CLIENT_PROGRAM_CREATE_RESET,
  CLIENT_PROGRAM_DETAILS_REQUEST,
  CLIENT_PROGRAM_DETAILS_SUCCESS,
  CLIENT_PROGRAM_DETAILS_FAIL,
  CLIENT_PROGRAM_DETAILS_RESET,
  CLIENT_PROGRAM_UPDATE_REQUEST,
  CLIENT_PROGRAM_UPDATE_SUCCESS,
  CLIENT_PROGRAM_UPDATE_FAIL,
  CLIENT_PROGRAM_UPDATE_RESET,
  CLIENT_PROGRAM_DELETE_REQUEST,
  CLIENT_PROGRAM_DELETE_SUCCESS,
  CLIENT_PROGRAM_DELETE_FAIL,
  CLIENT_PROGRAM_DELETE_RESET,
} from "../constants/clientProgramConstants";

// List all
export const clientProgramListReducer = (
  state = {
    loading: false,
    count: 0,
    client_programs: [],
    expected_revenue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case CLIENT_PROGRAM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CLIENT_PROGRAM_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        client_programs: action.payload.client_programs,
        expected_revenue: action.payload.expected_revenue,
        success: true,
        error: "",
      };

    case CLIENT_PROGRAM_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        client_programs: [],
        expected_revenue: 0,
        success: false,
        error: action.payload,
      };

    case CLIENT_PROGRAM_LIST_RESET:
      return {
        loading: false,
        count: 0,
        client_programs: [],
        expected_revenue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const clientProgramCreateReducer = (
  state = { loading: false, client_program: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_PROGRAM_CREATE_REQUEST:
      return {
        loading: true,
        client_program: state.client_program,
        success: false,
        error: "",
      };

    case CLIENT_PROGRAM_CREATE_SUCCESS:
      return {
        loading: false,
        client_program: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_PROGRAM_CREATE_FAIL:
      return {
        loading: false,
        client_program: state.client_program,
        success: false,
        error: action.payload,
      };

    case CLIENT_PROGRAM_CREATE_RESET:
      return { loading: false, client_program: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const clientProgramDetailsReducer = (
  state = { loading: false, client_program: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_PROGRAM_DETAILS_REQUEST:
      return {
        loading: true,
        client_program: state.client_program,
        success: false,
        error: "",
      };

    case CLIENT_PROGRAM_DETAILS_SUCCESS:
      return {
        loading: false,
        client_program: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_PROGRAM_DETAILS_FAIL:
      return {
        loading: false,
        client_program: state.client_program,
        success: false,
        error: action.payload,
      };

    case CLIENT_PROGRAM_DETAILS_RESET:
      return { loading: false, client_program: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const clientProgramUpdateReducer = (
  state = { loading: false, client_program: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_PROGRAM_UPDATE_REQUEST:
      return {
        loading: true,
        client_program: state.client_program,
        success: false,
        error: "",
      };

    case CLIENT_PROGRAM_UPDATE_SUCCESS:
      return {
        loading: false,
        client_program: action.payload,
        success: true,
        error: "",
      };

    case CLIENT_PROGRAM_UPDATE_FAIL:
      return {
        loading: false,
        client_program: state.client_program,
        success: false,
        error: action.payload,
      };

    case CLIENT_PROGRAM_UPDATE_RESET:
      return { loading: false, client_program: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const clientProgramDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case CLIENT_PROGRAM_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case CLIENT_PROGRAM_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case CLIENT_PROGRAM_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case CLIENT_PROGRAM_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
