// Library imports
import {
  Button,
  Callout,
  Classes,
  Colors,
  Dialog,
  Divider,
  H5,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import { AppToaster } from "../components/AppToaster";
import InfoPopup from "./InfoPopup";

// Main function
function FormDrawerContainer2({
  body,
  cannotDelete,
  deleteSuccess,
  deleteSuccessMessage,
  deleting,
  disabled,
  error,
  errorMessage,
  height,
  helpContent,
  helpTitle,
  icon,
  isOpen,
  loading,
  onDelete,
  onSave,
  refreshList,
  resetAction,
  resetDrawerVariables,
  saveSuccess,
  saveSuccessMessage,
  saving,
  setIsOpen,
  size,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Drawer active variable
  const [thisDrawerActive, setThisDrawerActive] = useState(false);

  // Close form drawer and reset form and query variables on delete or save success
  useEffect(() => {
    if (thisDrawerActive && (deleteSuccess || saveSuccess)) {
      deleteSuccess &&
        AppToaster.show({
          icon: "tick-circle",
          intent: "success",
          message: deleteSuccessMessage,
        });

      saveSuccess &&
        saveSuccessMessage &&
        AppToaster.show({
          icon: "tick-circle",
          intent: "success",
          message: saveSuccessMessage,
        });

      resetAction && resetAction();

      refreshList && refreshList();

      resetDrawerVariables && resetDrawerVariables();

      setThisDrawerActive(false);

      setIsOpen(false);
    }
    // eslint-disable-next-line
  }, [deleteSuccess, saveSuccess]);

  // JSX UI code
  return (
    <div style={{ overflowY: "auto" }}>
      <Dialog
        canOutsideClickClose={false}
        className={!lightMode ? Classes.DARK : undefined}
        icon={icon}
        isCloseButtonShown
        isOpen={isOpen}
        onClose={() => {
          resetAction && resetAction();

          resetDrawerVariables && resetDrawerVariables();

          setThisDrawerActive(false);

          setIsOpen(false);
        }}
        lazy
        style={{
          background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY2,
          height: height ? height : "fit-content",
          maxHeight: height ? height : "90vh",
          minHeight: height ? height : "200px",
          width:
            size === "large"
              ? "1280px"
              : size === "medium"
              ? "990px"
              : size === "small"
              ? "480px"
              : "800px",
        }}
        title={
          <div style={{ alignItems: "center", display: "flex" }}>
            <div style={{ marginRight: "5px" }}>{title}</div>

            {helpContent && helpTitle && (
              <InfoPopup
                content={
                  <div>
                    <H5 style={{ margin: "0 10px 0 0" }}>{helpTitle}</H5>

                    <Divider
                      style={{
                        background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                        margin: "10px 0 10px 0",
                      }}
                    />

                    <div>{helpContent}</div>
                  </div>
                }
                noPreWrap
              />
            )}
          </div>
        }
      >
        {loading ? (
          <div style={{ margin: "auto 0 auto 0" }}>
            <Spinner size={SpinnerSize.LARGE} />
          </div>
        ) : (
          <>
            <div className={Classes.DRAWER_BODY}>
              <div className={Classes.DIALOG_BODY}>
                {error && (
                  <Callout intent="danger" style={{ margin: "0 0 10px 0" }}>
                    {errorMessage
                      ? errorMessage
                      : error.response.data["non_field_errors"]
                      ? error.response.data["non_field_errors"]
                      : "Review any errors below and try saving again."}
                  </Callout>
                )}

                {cannotDelete && !error && (
                  <Callout intent="primary" style={{ margin: "0 0 10px 0" }}>
                    This record cannot be deleted because it is referenced by
                    another item(s).
                  </Callout>
                )}

                {body}
              </div>
            </div>

            <div className={Classes.DRAWER_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                {onDelete && (
                  <Button
                    disabled={cannotDelete || disabled}
                    icon="trash"
                    intent="danger"
                    loading={deleting}
                    onClick={() => {
                      setThisDrawerActive(true);

                      onDelete();
                    }}
                    style={{ margin: "0 auto 0 0" }}
                    text="Delete"
                  />
                )}

                <Button
                  className={Classes.DIALOG_CLOSE_BUTTON}
                  onClick={() => {
                    resetAction && resetAction();

                    resetDrawerVariables && resetDrawerVariables();

                    setThisDrawerActive(false);

                    setIsOpen(false);
                  }}
                  text="Close"
                />

                {onSave && (
                  <Button
                    disabled={disabled}
                    icon="floppy-disk"
                    intent="primary"
                    loading={saving}
                    onClick={() => {
                      setThisDrawerActive(true);

                      onSave();
                    }}
                    text="Save"
                  />
                )}
              </div>
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default FormDrawerContainer2;
