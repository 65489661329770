// Library imports
import {
  Button,
  Callout,
  Card,
  Classes,
  ControlGroup,
  Divider,
  H5,
  InputGroup,
  Tab,
  Tabs,
  Tag,
} from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import GenerateButton from "../components/GenerateButton";
import HelpDialog from "../components/HelpDialog";
import ItemCardList from "../components/ItemCardList";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import PermissionCard from "../components/PermissionCard";
import SearchField from "../components/SearchField";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldMultifieldCardList from "../components/TabSectionFieldMultifieldCardList";

// Redux action imports
// Manager
import { listManagersAction } from "../actions/managerActions";

// Need
import {
  deleteNeedAction,
  listNeedDetailsAction,
  resetDeleteNeedAction,
  resetListNeedsAction,
  resetUpdateNeedAction,
  updateNeedAction,
} from "../actions/needActions";

// Need permission
import {
  createNeedPermissionAction,
  deleteNeedPermissionAction,
  listNeedPermissionsAction,
  listNeedPermissionDetailsAction,
  resetCreateNeedPermissionAction,
  resetDeleteNeedPermissionAction,
  resetUpdateNeedPermissionAction,
  updateNeedPermissionAction,
} from "../actions/needPermissionActions";

// Need question
import {
  createNeedQuestionAction,
  deleteNeedQuestionAction,
  listNeedQuestionsAction,
  listNeedQuestionDetailsAction,
  resetCreateNeedQuestionAction,
  resetDeleteNeedQuestionAction,
  resetUpdateNeedQuestionAction,
  resetUploadNeedQuestionsAction,
  updateNeedQuestionAction,
  uploadNeedQuestionsAction,
} from "../actions/needQuestionActions";

// Main function
function NeedDetailScreen() {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Need id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Need
  const needDelete = useSelector((state) => state.needDelete);

  const needDetails = useSelector((state) => state.needDetails);

  const needUpdate = useSelector((state) => state.needUpdate);

  // Need permission
  const needPermissionCreate = useSelector(
    (state) => state.needPermissionCreate
  );

  const needPermissionDelete = useSelector(
    (state) => state.needPermissionDelete
  );

  const needPermissionDetails = useSelector(
    (state) => state.needPermissionDetails
  );

  const needPermissionList = useSelector((state) => state.needPermissionList);

  const needPermissionUpdate = useSelector(
    (state) => state.needPermissionUpdate
  );

  // Need question
  const needQuestionCreate = useSelector((state) => state.needQuestionCreate);

  const needQuestionDelete = useSelector((state) => state.needQuestionDelete);

  const needQuestionDetails = useSelector((state) => state.needQuestionDetails);

  const needQuestionList = useSelector((state) => state.needQuestionList);

  const needQuestionUpdate = useSelector((state) => state.needQuestionUpdate);

  const needQuestionUpload = useSelector((state) => state.needQuestionUpload);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("need-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Questions
  const [addQuestionDrawerIsOpen, setAddQuestionDrawerIsOpen] = useState(false);
  const [updateQuestionDrawerIsOpen, setUpdateQuestionDrawerIsOpen] =
    useState(false);

  // Search and filter variables
  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Need permission
  const needPermissionQueryDefault = {
    need: id,
    page: 1,
    searchString: "",
  };
  const [needPermissionQuery, setNeedPermissionQuery] = useState({
    ...needPermissionQueryDefault,
  });

  // Need question
  const needQuestionQueryDefault = {
    need: id,
    page: 1,
    searchString: "",
  };
  const [needQuestionQuery, setNeedQuestionQuery] = useState({
    ...needQuestionQueryDefault,
  });

  // Form variables
  // Need
  const needDefault = {
    description: "",
    manager: "",
    managerName: "",
    name: "",
    restricted: true,
    status: "Draft",
  };
  const [need, setNeed] = useState({
    ...needDefault,
  });

  // Need permission
  const needPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [needPermission, setNeedPermission] = useState({
    ...needPermissionDefault,
  });

  // Need question
  const needQuestionDefault = {
    name: "",
    notes: "",
    question: "",
    questionNumber: "",
  };
  const [needQuestion, setNeedQuestion] = useState({
    ...needQuestionDefault,
  });

  // Other
  const [needDeleteKey, setNeedDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Need
    setNeed({
      ...needDefault,
    });

    // Need permission
    setNeedPermission({
      ...needPermissionDefault,
    });

    // Need question
    setNeedQuestion({
      ...needQuestionDefault,
    });

    // Query variables
    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // Initialise drawer variables for need
  useEffect(() => {
    setNeed({
      description: needDetails.need.description
        ? needDetails.need.description
        : needDefault.description,
      manager: needDetails.need.manager
        ? needDetails.need.manager
        : needDefault.manager,
      managerName: needDetails.need.manager_name
        ? needDetails.need.manager_name
        : needDefault.managerName,
      name: needDetails.need.name ? needDetails.need.name : needDefault.name,
      restricted:
        needDetails.need.restricted != null
          ? needDetails.need.restricted
          : needDefault.restricted,
      status: needDetails.need.status
        ? needDetails.need.status
        : needDefault.status,
    });
    // eslint-disable-next-line
  }, [needDetails.need]);

  // Initialise drawer variables for need permission
  useEffect(() => {
    setNeedPermission({
      level: needPermissionDetails.need_permission.level
        ? needPermissionDetails.need_permission.level
        : needPermissionDefault.level,
      manager: needPermissionDetails.need_permission.manager
        ? needPermissionDetails.need_permission.manager
        : needPermissionDefault.manager,
      managerName: needPermissionDetails.need_permission.manager_name
        ? needPermissionDetails.need_permission.manager_name
        : needPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [needPermissionDetails.need_permission]);

  // Initialise drawer variables for need question
  useEffect(() => {
    setNeedQuestion({
      name: needQuestionDetails.need_question.name
        ? needQuestionDetails.need_question.name
        : needQuestionDefault.name,
      notes: needQuestionDetails.need_question.notes
        ? needQuestionDetails.need_question.notes
        : needQuestionDefault.notes,
      question: needQuestionDetails.need_question.question
        ? needQuestionDetails.need_question.question
        : needQuestionDefault.question,
      questionNumber: needQuestionDetails.need_question.question_number
        ? needQuestionDetails.need_question.question_number
        : needQuestionDefault.questionNumber,
    });
    // eslint-disable-next-line
  }, [needQuestionDetails.need_question]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If need deleted redirect to markets
  useEffect(() => {
    if (needDelete.success) {
      dispatch(resetDeleteNeedAction());

      dispatch(resetListNeedsAction());

      navigate("/markets/#needs");
    }
    // eslint-disable-next-line
  }, [needDelete.success]);

  // Load need details
  useEffect(() => {
    dispatch(listNeedDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter need permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () => dispatch(listNeedPermissionsAction({ ...needPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needPermissionQuery]);

  // Filter need questions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNeedQuestionsAction({
            ...needQuestionQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [needQuestionQuery]);

  // Form submission
  // Need
  // Delete need
  const deleteNeed = () => {
    // Dispatch action
    needDeleteKey === needDetails.need.name
      ? dispatch(deleteNeedAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the need's name",
        });
  };

  // Update need summary
  const updateSummary = () => {
    // Prepare data
    const need_data = new FormData();

    need.description
      ? need_data.append("description", need.description)
      : need_data.append("description", needDefault.description);

    need.manager
      ? need_data.append("manager", need.manager)
      : need_data.append("manager", needDefault.manager);

    need.name
      ? need_data.append("name", need.name)
      : need_data.append("name", needDefault.name);

    need.restricted != null
      ? need_data.append("restricted", need.restricted)
      : need_data.append("restricted", needDefault.restricted);

    need.status
      ? need_data.append("status", need.status)
      : need_data.append("status", needDefault.status);

    // Dispatch action
    dispatch(updateNeedAction(needDetails.need.id, need_data));
  };

  // Need permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const need_permission = new FormData();

    needPermission.level
      ? need_permission.append("level", needPermission.level)
      : need_permission.append("level", needPermissionDefault.level);

    needPermission.manager
      ? need_permission.append("manager", needPermission.manager)
      : need_permission.append("manager", needPermissionDefault.manager);

    need_permission.append("need", id);

    // Dispatch action
    dispatch(createNeedPermissionAction(need_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteNeedPermissionAction(needPermissionDetails.need_permission.id)
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const need_permission = new FormData();

    needPermission.level
      ? need_permission.append("level", needPermission.level)
      : need_permission.append("level", needPermissionDefault.level);

    needPermission.manager
      ? need_permission.append("manager", needPermission.manager)
      : need_permission.append("manager", needPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateNeedPermissionAction(
        needPermissionDetails.need_permission.id,
        need_permission
      )
    );
  };

  // Need question
  // Add question
  const addQuestion = () => {
    // Prepare data
    const need_question_data = new FormData();

    needQuestion.name
      ? need_question_data.append("name", needQuestion.name)
      : need_question_data.append("name", needQuestionDefault.name);

    need_question_data.append("need", id);

    needQuestion.notes
      ? need_question_data.append("notes", needQuestion.notes)
      : need_question_data.append("notes", needQuestionDefault.notes);

    needQuestion.question
      ? need_question_data.append("question", needQuestion.question)
      : need_question_data.append("question", needQuestionDefault.question);

    needQuestion.questionNumber
      ? need_question_data.append(
          "question_number",
          needQuestion.questionNumber
        )
      : need_question_data.append(
          "question_number",
          needQuestionDefault.questionNumber
        );

    // Dispatch action
    dispatch(createNeedQuestionAction(need_question_data));
  };

  useEffect(() => {
    if (needQuestionUpload.success) {
      dispatch(listNeedQuestionsAction({ need: id }));

      dispatch(resetUploadNeedQuestionsAction());
    }
    // eslint-disable-next-line
  }, [needQuestionUpload.success]);

  // Delete question
  const deleteQuestion = () => {
    // Dispatch deliverable
    dispatch(deleteNeedQuestionAction(needQuestionDetails.need_question.id));
  };

  // Update question
  const updateQuestion = () => {
    // Prepare data
    const need_question_data = new FormData();

    needQuestion.name
      ? need_question_data.append("name", needQuestion.name)
      : need_question_data.append("name", needQuestionDefault.name);

    needQuestion.notes
      ? need_question_data.append("notes", needQuestion.notes)
      : need_question_data.append("notes", needQuestionDefault.notes);

    needQuestion.question
      ? need_question_data.append("question", needQuestion.question)
      : need_question_data.append("question", needQuestionDefault.question);

    needQuestion.questionNumber
      ? need_question_data.append(
          "question_number",
          needQuestion.questionNumber
        )
      : need_question_data.append(
          "question_number",
          needQuestionDefault.questionNumber
        );

    // Dispatch action
    dispatch(
      updateNeedQuestionAction(
        needQuestionDetails.need_question.id,
        need_question_data
      )
    );
  };

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Describe the need</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Need name */}
                  <FormDrawerTextField2
                    error={needUpdate.error}
                    fieldName="name"
                    icon="label"
                    label="Need name"
                    labelInfo="(required)"
                    placeholder="Enter the need's name"
                    setValueObject={setNeed}
                    setValueVariable="name"
                    value={need.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Description */}
              <FormDrawerTextAreaField3
                error={needUpdate.error}
                fieldName="description"
                label="Description"
                placeholder="Describe the need"
                setValueObject={setNeed}
                setValueVariable="description"
                value={need.description}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Status */}
                  <FormDrawerButtonField
                    disabled={
                      needDetails.need.user_access_status === "edit" ||
                      needDetails.need.user_access_status === "view"
                    }
                    error={needUpdate.error}
                    fieldName="status"
                    helperText="Needs in draft status cannot be linked to clients, markets or services"
                    label="Status"
                    options={[
                      {
                        icon: "tick-circle",
                        id: 1,
                        intent: "success",
                        label: "Approved",
                        value: "Approved",
                      },
                      {
                        icon: "new-drawing",
                        id: 2,
                        intent: "primary",
                        label: "Draft",
                        value: "Draft",
                      },
                    ]}
                    setValueObject={setNeed}
                    setValueVariable="status"
                    value={need.status}
                  />

                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      needDetails.need.user_access_status === "edit" ||
                      needDetails.need.user_access_status === "view"
                    }
                    error={needUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted need can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setNeed}
                    setValueVariable="restricted"
                    value={need.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      needDetails.need.user_access_status === "edit" ||
                      needDetails.need.user_access_status === "view"
                    }
                    error={needUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the need - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setNeed}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={need.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the need manager and the need is
                    restricted, you will not be able to access it again, unless
                    you are organisation administrator/owner or have edit/view
                    permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={needUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Needs"
            view="Detail"
          />
        }
        helpTitle="Updating a need"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={needDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listNeedDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateNeedAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={needUpdate.success}
        saveSuccessMessage="Need updated."
        saving={needUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={needPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setNeedPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={needPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={needPermissionCreate.error}
              fieldName="level"
              intent={
                needPermission.level === "Edit"
                  ? "success"
                  : needPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setNeedPermission}
              setValueVariable="level"
              value={needPermission.level}
            />
          </Card>
        }
        error={needPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Needs"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listNeedPermissionsAction({ need: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateNeedPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={needPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={needPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Update the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={needPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setNeedPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={needPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={needPermissionUpdate.error}
              fieldName="level"
              intent={
                needPermission.level === "Edit"
                  ? "success"
                  : needPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setNeedPermission}
              setValueVariable="level"
              value={needPermission.level}
            />
          </Card>
        }
        deleteSuccess={needPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={needPermissionDelete.loading}
        error={needPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Needs"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={needPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listNeedPermissionsAction({ ...needPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteNeedPermissionAction());

          dispatch(resetUpdateNeedPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={needPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={needPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New question form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the question</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Question name */}
                <FormDrawerTextField2
                  error={needQuestionCreate.error}
                  fieldName="name"
                  icon="form"
                  label="Question name"
                  labelInfo="(required)"
                  placeholder="Enter the question's name"
                  setValueObject={setNeedQuestion}
                  setValueVariable="name"
                  value={needQuestion.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Question number */}
                <FormDrawerTextField2
                  error={needQuestionCreate.error}
                  fieldName="question_number"
                  helperText="advaise.app will order questions based on this number"
                  icon="numbered-list"
                  label="Question number"
                  placeholder="Enter a question number"
                  setValueObject={setNeedQuestion}
                  setValueVariable="questionNumber"
                  value={needQuestion.questionNumber}
                />
              </div>
            </div>

            {/* Question */}
            <FormDrawerTextAreaField3
              error={needQuestionCreate.error}
              fieldName="question"
              height="100px"
              label="Question"
              labelInfo="(required)"
              placeholder="Enter the question"
              setValueObject={setNeedQuestion}
              setValueVariable="question"
              value={needQuestion.question}
            />

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={needQuestionCreate.error}
              fieldName="notes"
              height="100px"
              helperText="Provide guidance on how to interpret answers to establish whether the need exists"
              label="Notes"
              placeholder="Explain the question"
              setValueObject={setNeedQuestion}
              setValueVariable="notes"
              value={needQuestion.notes}
            />
          </Card>
        }
        error={needQuestionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add question"
            module="Needs"
            view="Detail"
          />
        }
        helpTitle="Evaluating needs"
        icon="edit"
        isOpen={addQuestionDrawerIsOpen}
        onSave={addQuestion}
        refreshList={() => {
          dispatch(listNeedQuestionsAction({ need: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateNeedQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={needQuestionCreate.success}
        saveSuccessMessage="Question created."
        saving={needQuestionCreate.loading}
        setIsOpen={setAddQuestionDrawerIsOpen}
        title="Add question"
      />

      {/* Existing question form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Define the question</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Question name */}
                <FormDrawerTextField2
                  error={needQuestionUpdate.error}
                  fieldName="name"
                  icon="form"
                  label="Question name"
                  labelInfo="(required)"
                  placeholder="Enter the question's name"
                  setValueObject={setNeedQuestion}
                  setValueVariable="name"
                  value={needQuestion.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Question number */}
                <FormDrawerTextField2
                  error={needQuestionUpdate.error}
                  fieldName="question_number"
                  helperText="advaise.app will order questions based on this number"
                  icon="numbered-list"
                  label="Question number"
                  placeholder="Enter a question number"
                  setValueObject={setNeedQuestion}
                  setValueVariable="questionNumber"
                  value={needQuestion.questionNumber}
                />
              </div>
            </div>

            {/* Question */}
            <FormDrawerTextAreaField3
              error={needQuestionUpdate.error}
              fieldName="question"
              height="100px"
              label="Question"
              labelInfo="(required)"
              placeholder="Enter the question"
              setValueObject={setNeedQuestion}
              setValueVariable="question"
              value={needQuestion.question}
            />

            {/* Notes */}
            <FormDrawerTextAreaField3
              error={needQuestionUpdate.error}
              fieldName="notes"
              height="100px"
              helperText="Provide guidance on how to interpret answers to establish whether the need exists"
              label="Notes"
              placeholder="Explain the question"
              setValueObject={setNeedQuestion}
              setValueVariable="notes"
              value={needQuestion.notes}
            />
          </Card>
        }
        deleteSuccess={needQuestionDelete.success}
        deleteSuccessMessage="Question deleted."
        deleting={needQuestionDelete.loading}
        error={needQuestionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update question"
            module="Questions"
            view="Detail"
          />
        }
        helpTitle="Evaluating needs"
        icon="edit"
        isOpen={updateQuestionDrawerIsOpen}
        loading={needQuestionDetails.loading}
        onDelete={deleteQuestion}
        onSave={updateQuestion}
        refreshList={() => {
          dispatch(listNeedQuestionsAction({ ...needQuestionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteNeedQuestionAction());

          dispatch(resetUpdateNeedQuestionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={needQuestionUpdate.success}
        saveSuccessMessage="Question updated."
        saving={needQuestionUpdate.loading}
        setIsOpen={setUpdateQuestionDrawerIsOpen}
        title="Update question"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Needs"
              onClick={() => {
                dispatch(resetListNeedsAction());

                navigate("/markets/#needs");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          needDetails.need.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={needDetails.loading}
        title={`${needDetails.need.name}`}
      />

      {/* Error alerts */}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Need delete error */}
      {needDelete.error && (
        <ErrorAlert message="We couldn't delete the need. Try refreshing the page." />
      )}
      {/* Need loading error */}
      {needDetails.error && (
        <ErrorAlert message="We couldn't load the need. Try refreshing the page." />
      )}
      {/* Need permissions loading error */}
      {needPermissionList.error && (
        <ErrorAlert message="We couldn't load the need permissions. Try refreshing the page." />
      )}
      {/* Need questions loading error */}
      {needQuestionList.error && (
        <ErrorAlert message="We couldn't load the need questions. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Need"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="need-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="need-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          needDetails.need.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listNeedDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <div>
                        {/* Id */}
                        <TabSectionField
                          label="Id"
                          loading={needDetails.loading}
                          value={needDetails.need.id}
                        />

                        {/* Need name */}
                        <TabSectionField
                          label="Need name"
                          loading={needDetails.loading}
                          value={needDetails.need.name}
                        />

                        {/* Description */}
                        <TabSectionField
                          label="Description"
                          loading={needDetails.loading}
                          value={
                            <TabSectionFieldCard
                              text={needDetails.need.description}
                            />
                          }
                        />

                        {/* Status */}
                        <TabSectionField
                          label="Status"
                          loading={needDetails.loading}
                          value={
                            <Tag
                              intent={
                                needDetails.need.status === "Approved"
                                  ? "success"
                                  : "primary"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {needDetails.need.status}
                            </Tag>
                          }
                        />

                        {/* Access */}
                        <TabSectionField
                          helperText={
                            needDetails.need.restricted
                              ? "Users must have permission to access this need"
                              : "Everyone in the organisation can edit this need"
                          }
                          label="Access"
                          loading={needDetails.loading}
                          value={
                            <Tag
                              intent={
                                needDetails.need.restricted
                                  ? "danger"
                                  : "success"
                              }
                              large
                              minimal
                              style={{
                                minWidth: "100px",
                                textAlign: "center",
                              }}
                            >
                              {needDetails.need.restricted
                                ? "Restricted"
                                : "Unrestricted"}
                            </Tag>
                          }
                        />

                        {/* Manager */}
                        <TabSectionField
                          bottom
                          helperText={
                            needDetails.need.user_access_status === "view"
                              ? "Contact to request edit access"
                              : undefined
                          }
                          label="Manager"
                          loading={needDetails.loading}
                          value={
                            <NameValue
                              email={needDetails.need.manager_name}
                              image={needDetails.need.manager_profile_photo}
                            />
                          }
                        />
                      </div>
                    }
                    help="Review and update need information"
                    icon="clean"
                    title="Summary"
                  />

                  {/* Permissions */}
                  {(needDetails.need.user_access_status === "owner" ||
                    needDetails.need.user_access_status === "admin" ||
                    needDetails.need.user_access_status === "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={needPermissionList.loading}
                              setValueObject={setNeedPermissionQuery}
                              setValueVariable="searchString"
                              value={needPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={needPermissionList.need_permissions.map(
                              (need_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={need_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listNeedPermissionDetailsAction(
                                          need_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={need_permission}
                                  />
                                );
                              }
                            )}
                            count={needPermissionList.count}
                            loading={needPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={needPermissionQuery.page}
                            setPageObject={setNeedPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, needs are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete need */}
                  {(needDetails.need.user_access_status === "owner" ||
                    needDetails.need.user_access_status === "admin" ||
                    needDetails.need.user_access_status === "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={needDetails.need.cannot_delete}
                              id="confirm-need-name"
                              onChange={(e) => {
                                setNeedDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the need's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={needDeleteKey}
                            />

                            <Button
                              disabled={needDetails.need.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={needDelete.loading}
                              onClick={deleteNeed}
                              text="Delete this need"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{needDetails.need.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the need"
                      icon="delete"
                      message={
                        needDetails.need.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this need, you will lose all information associated with it."
                      }
                      messageIntent={
                        needDetails.need.cannot_delete ? "primary" : "danger"
                      }
                      title="Delete need"
                    />
                  )}
                </div>
              }
            />

            {/* Questions */}
            <Tab
              id="need-questions-tab"
              title="Questions"
              panel={
                <div>
                  {/* Questions */}
                  <TabSection2
                    buttons={
                      <>
                        <Button
                          disabled={
                            needDetails.need.user_access_status === "view"
                          }
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddQuestionDrawerIsOpen(true);
                          }}
                          style={{ margin: "0 10px 0 0" }}
                          text="Add"
                        />

                        <GenerateButton
                          disabled={
                            !needDetails.need.description ||
                            needQuestionList.count > 0 ||
                            needDetails.need.user_access_status === "view"
                          }
                          objectDescription={`${needDetails.need.name}: ${needDetails.need.description}`}
                          objectType="need"
                          onSuccess={(result) => {
                            if (
                              !("questions" in result) ||
                              result.questions.length === 0
                            ) {
                              AppToaster.show({
                                icon: "warning-sign",
                                intent: "warning",
                                message: "Unable to generate suitable content.",
                              });
                            } else {
                              AppToaster.show({
                                icon: "tick-circle",
                                intent: "success",
                                message: "Generation complete.",
                              });
                            }

                            dispatch(
                              uploadNeedQuestionsAction(id, result.questions)
                            );
                          }}
                        />
                      </>
                    }
                    content={
                      <div>
                        <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                          <SearchField
                            placeholder="Search question"
                            searching={needQuestionList.loading}
                            setValueObject={setNeedQuestionQuery}
                            setValueVariable="searchString"
                            value={needQuestionQuery.searchString}
                          />
                        </div>

                        <TabSectionFieldMultifieldCardList
                          content={needQuestionList.need_questions.map(
                            (question, i, { length }) => {
                              return (
                                <div key={question.id}>
                                  <TabSectionField
                                    bottom={length - 1 === i}
                                    label={
                                      question.question_number
                                        ? `${question.question_number}. ${question.name}`
                                        : question.name
                                    }
                                    value={
                                      <TabSectionFieldCard
                                        interactive
                                        onClick={() => {
                                          if (
                                            needDetails.need
                                              .user_access_status !== "view"
                                          ) {
                                            dispatch(
                                              listNeedQuestionDetailsAction(
                                                question.id
                                              )
                                            );

                                            setUpdateQuestionDrawerIsOpen(true);
                                          }
                                        }}
                                        text={
                                          question.notes ? (
                                            question.notes
                                          ) : (
                                            <span
                                              className={Classes.TEXT_MUTED}
                                            >
                                              No comments.
                                            </span>
                                          )
                                        }
                                        title={question.question}
                                      />
                                    }
                                  />
                                </div>
                              );
                            }
                          )}
                          count={needQuestionList.count}
                          loading={needQuestionList.loading}
                          noResultsMessage="Add questions."
                          noResultsTitle="No questions found"
                          page={needQuestionQuery.page}
                          setPageObject={setNeedQuestionQuery}
                          setPageVariable="page"
                        />
                      </div>
                    }
                    help="Define questions to diagnose the need"
                    icon="form"
                    title="Questions"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default NeedDetailScreen;
