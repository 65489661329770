// Constants import
import { PAGE_SIZE } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  ButtonGroup,
  Classes,
  Colors,
  HTMLTable,
  NonIdealState,
  Tag,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TableContainer2({
  body,
  bordered,
  clearFiltersDisabled,
  compact,
  count,
  darkColor,
  footer,
  footnote,
  head,
  interactive,
  lightColor,
  loading,
  noMargin,
  noResultsIcon,
  noResultsMessage,
  noResultsTitle,
  onClearFilters,
  page,
  setPageObject,
  setPageVariable,
  striped,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div
      style={{
        background: lightMode
          ? lightColor
            ? lightColor
            : Colors.LIGHT_GRAY3
          : darkColor
          ? darkColor
          : Colors.DARK_GRAY5,
        margin: noMargin ? "0" : "20px",
        maxWidth: "fit-content",
        padding: "10px",
      }}
    >
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <HTMLTable
          bordered={bordered === false ? false : true}
          compact={compact}
          interactive={interactive}
          striped={striped === false ? false : true}
          style={{
            border:
              bordered === false
                ? undefined
                : `1px solid ${lightMode ? Colors.GRAY5 : Colors.GRAY1}`,
            margin: "5px 0 5px 0",
            tableLayout: "fixed",
            width: "100%",
          }}
        >
          {/* Head */}
          <thead
            style={{
              background: lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY4,
            }}
          >
            {head}
          </thead>

          {/* Body */}
          {count !== 0 && !loading && (
            <tbody
              style={{
                background: lightMode ? Colors.WHITE : Colors.DARK_GRAY3,
              }}
            >
              {body}
            </tbody>
          )}

          {/* Footer */}
          {count !== 0 && !loading && <tfoot>{footer}</tfoot>}
        </HTMLTable>

        {/* Loading */}
        {loading && (
          <div
            style={{
              background: lightMode
                ? lightColor
                  ? lightColor
                  : Colors.LIGHT_GRAY3
                : darkColor
                ? darkColor
                : Colors.DARK_GRAY5,
              padding: "15px 0 15px 0",
            }}
          >
            <div className={Classes.SKELETON} style={{ width: "100%" }}>
              XXXXXXXXXX
            </div>
          </div>
        )}

        {/* No results */}
        {count === 0 && !loading && (
          <div
            style={{
              background: lightMode
                ? lightColor
                  ? lightColor
                  : Colors.LIGHT_GRAY3
                : darkColor
                ? darkColor
                : Colors.DARK_GRAY5,
              margin: "15px 0 15px 0",
              padding: "15px",
            }}
          >
            <NonIdealState
              action={
                onClearFilters && (
                  <Button onClick={onClearFilters} text="Clear filters" />
                )
              }
              description={noResultsMessage ? noResultsMessage : "Add records."}
              icon={noResultsIcon ? noResultsIcon : "search"}
              title={noResultsTitle ? noResultsTitle : "No records found"}
            />
          </div>
        )}
      </div>

      {/* Footnote */}
      {count !== 0 && !loading && (
        <div
          className={[Classes.TEXT_SMALL, Classes.TEXT_MUTED].join(" ")}
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            margin: "0 0 10px 0",
          }}
        >
          <div>{footnote}</div>

          <Tag minimal>
            {count} record{count > 1 && "s"}
          </Tag>
        </div>
      )}

      {/* Table buttons */}
      {count !== 0 && !loading && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* Clear filters */}
          {onClearFilters ? (
            <Button
              minimal
              disabled={clearFiltersDisabled}
              onClick={onClearFilters}
              text={<small>Clear filters</small>}
            />
          ) : (
            <div></div>
          )}

          {/* Previous page */}
          <ButtonGroup minimal>
            <Button
              disabled={page === 1 ? true : false}
              onClick={() => {
                setPageObject((prevState) => {
                  return { ...prevState, [setPageVariable]: page - 1 };
                });
              }}
            >
              {<small>Previous page</small>}
            </Button>

            {/* Next page */}
            <Button
              disabled={page * PAGE_SIZE < Number(count) ? false : true}
              onClick={() => {
                setPageObject((prevState) => {
                  return { ...prevState, [setPageVariable]: page + 1 };
                });
              }}
            >
              {<small>Next page</small>}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
}

export default TableContainer2;
