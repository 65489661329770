// Constant imports
import {
  OPENING_EVALUATION_RATING_LIST_REQUEST,
  OPENING_EVALUATION_RATING_LIST_SUCCESS,
  OPENING_EVALUATION_RATING_LIST_FAIL,
  OPENING_EVALUATION_RATING_LIST_RESET,
  OPENING_EVALUATION_RATING_UPDATE_REQUEST,
  OPENING_EVALUATION_RATING_UPDATE_SUCCESS,
  OPENING_EVALUATION_RATING_UPDATE_FAIL,
  OPENING_EVALUATION_RATING_UPDATE_RESET,
  OPENING_EVALUATION_RATINGS_UPLOAD_BEGIN,
  OPENING_EVALUATION_RATINGS_UPLOAD_REQUEST,
  OPENING_EVALUATION_RATINGS_UPLOAD_SUCCESS,
  OPENING_EVALUATION_RATINGS_UPLOAD_FAIL,
  OPENING_EVALUATION_RATINGS_UPLOAD_END,
  OPENING_EVALUATION_RATINGS_UPLOAD_RESET,
} from "../constants/openingEvaluationRatingConstants";

// List all
export const openingEvaluationRatingListReducer = (
  state = {
    loading: false,
    count: 0,
    opening_evaluation_ratings: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_EVALUATION_RATING_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPENING_EVALUATION_RATING_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        opening_evaluation_ratings: action.payload.opening_evaluation_ratings,
        success: true,
        error: "",
      };

    case OPENING_EVALUATION_RATING_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        opening_evaluation_ratings: [],
        success: false,
        error: action.payload,
      };

    case OPENING_EVALUATION_RATING_LIST_RESET:
      return {
        loading: false,
        count: 0,
        opening_evaluation_ratings: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const openingEvaluationRatingUpdateReducer = (
  state = {
    loading: false,
    opening_evaluation_rating: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_EVALUATION_RATING_UPDATE_REQUEST:
      return {
        loading: true,
        opening_evaluation_rating: state.opening_evaluation_rating,
        success: false,
        error: "",
      };

    case OPENING_EVALUATION_RATING_UPDATE_SUCCESS:
      return {
        loading: false,
        opening_evaluation_rating: action.payload,
        success: true,
        error: "",
      };

    case OPENING_EVALUATION_RATING_UPDATE_FAIL:
      return {
        loading: false,
        opening_evaluation_rating: state.opening_evaluation_rating,
        success: false,
        error: action.payload,
      };

    case OPENING_EVALUATION_RATING_UPDATE_RESET:
      return {
        loading: false,
        opening_evaluation_rating: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const openingEvaluationRatingsUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_EVALUATION_RATINGS_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case OPENING_EVALUATION_RATINGS_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OPENING_EVALUATION_RATINGS_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
      };

    case OPENING_EVALUATION_RATINGS_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case OPENING_EVALUATION_RATINGS_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case OPENING_EVALUATION_RATINGS_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
