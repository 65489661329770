// Library imports
import {
  Button,
  ButtonGroup,
  Callout,
  Classes,
  Dialog,
  Spinner,
  SpinnerSize,
} from "@blueprintjs/core";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import PDF2 from "../components/PDF2";

// Main function
function DocumentDialog3({
  document,
  fileName,
  isOpen,
  loading,
  setIsOpen,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Download variables
  const [message, setMessage] = useState("");
  const [messageIntent, setMessageIntent] = useState("");

  useEffect(() => {
    if (isOpen) {
      setMessage("");

      setMessageIntent("");
    }
  }, [isOpen]);

  // Load document data
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    if (loading) {
      setShowButtons(false);
    }
  }, [loading]);

  // Zoom
  const documentContainerRef = useRef(null);
  const [documentContainerHeight, setDocumentContainerHeight] = useState(null);
  const [documentContainerWidth, setDocumentContainerWidth] = useState(null);
  const [width, setWidth] = useState(600);

  useEffect(() => {
    if (documentContainerRef.current) {
      setDocumentContainerHeight(documentContainerRef.current.clientHeight);

      setDocumentContainerWidth(documentContainerRef.current.clientWidth);
    }
    // eslint-disable-next-line
  }, [documentContainerRef.current]);

  // JSX UI code
  return (
    <Dialog
      className={!lightMode ? Classes.DARK : undefined}
      icon="document"
      isCloseButtonShown
      isOpen={isOpen}
      lazy
      onClose={() => {
        setIsOpen(false);
      }}
      style={{ width: "1150px" }}
      title={title}
    >
      <div className={Classes.DIALOG_BODY}>
        {/* Message */}
        {message ? (
          <Callout intent={messageIntent} style={{ margin: "0 0 20px 0" }}>
            {message}
          </Callout>
        ) : undefined}

        {/* Document */}
        <div
          style={{
            height: "75vh",
            padding: "10px 0 10px 0",
          }}
        >
          {showButtons && (
            <ButtonGroup
              style={{ margin: "10px 0 0 10px", position: "absolute" }}
              vertical
            >
              <Button
                icon="zoom-in"
                onClick={() => {
                  if (width <= 1000) {
                    setWidth(width + 100);
                  }
                }}
              />

              <Button
                icon="zoom-out"
                onClick={() => {
                  if (width >= 600) {
                    setWidth(width - 100);
                  }
                }}
              />
            </ButtonGroup>
          )}

          <div
            ref={documentContainerRef}
            style={{ height: "100%", width: "100%" }}
          >
            {loading ? (
              <Spinner
                size={SpinnerSize.LARGE}
                style={{ margin: "30vh 0 0 0" }}
              />
            ) : (
              <PDF2
                document={document}
                documentContainerHeight={documentContainerHeight}
                documentContainerWidth={documentContainerWidth}
                setShowButtons={setShowButtons}
                width={width}
              />
            )}
          </div>
        </div>
      </div>

      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            disabled={loading}
            icon="download"
            intent="primary"
            onClick={() => {
              saveAs(document, `${fileName}.pdf`);

              setMessage("File downloaded.");

              setMessageIntent("success");
            }}
            text="Download"
          />

          <Button
            className={Classes.DIALOG_CLOSE_BUTTON}
            onClick={() => {
              setIsOpen(false);
            }}
            text="Close"
          />
        </div>
      </div>
    </Dialog>
  );
}

export default DocumentDialog3;
