// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Redux action imports
// Knowledge base document
import { listKnowledgeBaseDocumentsAction } from "../actions/knowledgeBaseDocumentActions";

// Main function
function KnowledgeBaseDocumentInfo({
  additionalInfo,
  documentData,
  documentId,
  query,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Knowledge base document
  const knowledgeBaseDocumentList = useSelector(
    (state) => state.knowledgeBaseDocumentList
  );

  // Refresh knowledge base document data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [document, setDocument] = useState({});

  useEffect(() => {
    if (documentData) {
      setDocument(documentData);

      setLoading(false);
    }
  }, [documentData]);

  useEffect(() => {
    if (!documentData && !knowledgeBaseDocumentList.loading) {
      if (knowledgeBaseDocumentList.success) {
        const thisDocument =
          knowledgeBaseDocumentList.knowledge_base_documents.filter(
            (knowledge_base_document) =>
              knowledge_base_document.id === documentId
          );

        if (thisDocument.length > 0) {
          setDocument(thisDocument[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listKnowledgeBaseDocumentsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (knowledgeBaseDocumentList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [knowledgeBaseDocumentList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{document.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Summary</strong>
          </p>

          <p>
            {document.summary ? (
              document.summary
            ) : (
              <span className={Classes.TEXT_MUTED}>Summary unavailable.</span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default KnowledgeBaseDocumentInfo;
