// Library imports
import { Classes, Colors, Divider, H5 } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Component imports
import NameValue from "../components/NameValue";
import NumericValue from "../components/NumericValue";

// Redux action imports
// Client program
import { listClientProgramsAction } from "../actions/clientProgramActions";

// Main function
function ClientProgramInfo({ additionalInfo, programData, programId, query }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Client program
  const clientProgramList = useSelector((state) => state.clientProgramList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Refresh client program data
  const [loading, setLoading] = useState(true);
  const [noAccess, setNoAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [program, setProgram] = useState({});

  useEffect(() => {
    if (programData) {
      setProgram(programData);

      setLoading(false);
    }
  }, [programData]);

  useEffect(() => {
    if (!programData && !clientProgramList.loading) {
      if (clientProgramList.success) {
        const thisProgram = clientProgramList.client_programs.filter(
          (program) => program.id === programId
        );

        if (thisProgram.length > 0) {
          setProgram(thisProgram[0]);

          setLoading(false);
        } else if (page < 100) {
          setLoading(true);

          dispatch(
            listClientProgramsAction({
              ...query,
              page: page,
            })
          );

          setPage(page + 1);
        } else {
          setLoading(false);

          setNoAccess(true);
        }
      } else if (clientProgramList.error) {
        setLoading(false);

        setNoAccess(true);
      }
    }
    // eslint-disable-next-line
  }, [clientProgramList]);

  // JSX UI code
  return (
    <div className={loading ? Classes.SKELETON : undefined}>
      {loading ? (
        "XXXXXXXXXX"
      ) : noAccess ? (
        "You do not have access to this record."
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <H5 style={{ margin: "0 10px 0 0" }}>{program.name}</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Expected revenue</strong>
          </p>

          <div
            style={{
              marginBottom: "10px",
            }}
          >
            <NumericValue
              currency={activeOrganisation.currency}
              fillTag
              number={program.expected_revenue}
            />
          </div>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Year</strong>
          </p>

          <p>{program.year ? program.year : "-"}</p>

          {program.sponsor && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Sponsor</strong>
              </p>

              <div
                style={{
                  marginBottom: "10px",
                }}
              >
                <NameValue
                  image={program.sponsor_profile_photo}
                  invertColor
                  text={program.sponsor_name}
                  url={`/contacts/${program.sponsor}`}
                />
              </div>
            </>
          )}

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Notes</strong>
          </p>

          <p>
            {program.notes ? (
              program.notes
            ) : (
              <span className={Classes.TEXT_MUTED}>No comments.</span>
            )}
          </p>

          {additionalInfo}
        </div>
      )}
    </div>
  );
}

export default ClientProgramInfo;
