// Library imports
import {
  Alignment,
  Button,
  Callout,
  Card,
  Collapse,
  Colors,
  H5,
} from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Main function
function TabSectionDocumentEditorFormContainer2({
  bottom,
  fields,
  message,
  pageRef,
  setShowPage,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Collapse variables
  const [collapse, setCollapse] = useState(true);

  // JSX UI code
  return (
    <Card
      style={{
        margin: bottom ? "0" : "0 0 20px 0",
        padding: "0",
      }}
    >
      <div
        style={{
          borderBottom: collapse
            ? undefined
            : lightMode
            ? "1px solid " + Colors.GRAY5
            : "1px solid" + Colors.GRAY1,
          width: "100%",
        }}
      >
        <Button
          alignText={Alignment.LEFT}
          children={
            <div style={{ padding: "10px 5px 10px 5px" }}>
              <H5 style={{ margin: "0" }}>{title}</H5>
            </div>
          }
          fill
          large
          minimal
          onClick={() => {
            if (collapse && setShowPage && pageRef) {
              setShowPage(pageRef);
            }

            setCollapse(!collapse);
          }}
          rightIcon={collapse ? "chevron-down" : "chevron-up"}
        />
      </div>

      <Collapse isOpen={!collapse}>
        {message ? (
          <div style={{ padding: "10px 10px 0 10px" }}>
            <Callout intent="primary">{message}</Callout>
          </div>
        ) : undefined}

        <div style={{ padding: "10px" }}>{fields}</div>
      </Collapse>
    </Card>
  );
}

export default TabSectionDocumentEditorFormContainer2;
