// Constant imports
import {
  INTERVIEW_QUESTION_LIST_REQUEST,
  INTERVIEW_QUESTION_LIST_SUCCESS,
  INTERVIEW_QUESTION_LIST_FAIL,
  INTERVIEW_QUESTION_LIST_RESET,
  INTERVIEW_QUESTION_CREATE_REQUEST,
  INTERVIEW_QUESTION_CREATE_SUCCESS,
  INTERVIEW_QUESTION_CREATE_FAIL,
  INTERVIEW_QUESTION_CREATE_RESET,
  INTERVIEW_QUESTION_DETAILS_REQUEST,
  INTERVIEW_QUESTION_DETAILS_SUCCESS,
  INTERVIEW_QUESTION_DETAILS_FAIL,
  INTERVIEW_QUESTION_DETAILS_RESET,
  INTERVIEW_QUESTION_UPDATE_REQUEST,
  INTERVIEW_QUESTION_UPDATE_SUCCESS,
  INTERVIEW_QUESTION_UPDATE_FAIL,
  INTERVIEW_QUESTION_UPDATE_RESET,
  INTERVIEW_QUESTION_DELETE_REQUEST,
  INTERVIEW_QUESTION_DELETE_SUCCESS,
  INTERVIEW_QUESTION_DELETE_FAIL,
  INTERVIEW_QUESTION_DELETE_RESET,
} from "../constants/interviewQuestionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInterviewQuestionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_QUESTION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInterviewQuestions(
        params,
        token
      );

      dispatch({
        type: INTERVIEW_QUESTION_LIST_SUCCESS,

        payload: {
          count: data.count,
          interview_questions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_QUESTION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInterviewQuestionsAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_QUESTION_LIST_RESET });
};

// Create
export const createInterviewQuestionAction =
  (interview_question) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_QUESTION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createInterviewQuestion(
        token,
        interview_question
      );

      dispatch({
        type: INTERVIEW_QUESTION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_QUESTION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateInterviewQuestionAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_QUESTION_CREATE_RESET });
};

// List details
export const listInterviewQuestionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_QUESTION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInterviewQuestion(id, token);

      dispatch({
        type: INTERVIEW_QUESTION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_QUESTION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInterviewQuestionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: INTERVIEW_QUESTION_DETAILS_RESET });
  };

// Update
export const updateInterviewQuestionAction =
  (id, interview_question) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_QUESTION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInterviewQuestion(
        id,
        token,
        interview_question
      );

      dispatch({
        type: INTERVIEW_QUESTION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_QUESTION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInterviewQuestionAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_QUESTION_UPDATE_RESET });
};

// Delete
export const deleteInterviewQuestionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INTERVIEW_QUESTION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteInterviewQuestion(
        id,
        token
      );

      dispatch({
        type: INTERVIEW_QUESTION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INTERVIEW_QUESTION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteInterviewQuestionAction = () => async (dispatch) => {
  dispatch({ type: INTERVIEW_QUESTION_DELETE_RESET });
};
