export const KNOWLEDGE_BASE_DOCUMENT_LIST_REQUEST =
  "KNOWLEDGE_BASE_DOCUMENT_LIST_REQUEST";
export const KNOWLEDGE_BASE_DOCUMENT_LIST_SUCCESS =
  "KNOWLEDGE_BASE_DOCUMENT_LIST_SUCCESS";
export const KNOWLEDGE_BASE_DOCUMENT_LIST_FAIL =
  "KNOWLEDGE_BASE_DOCUMENT_LIST_FAIL";
export const KNOWLEDGE_BASE_DOCUMENT_LIST_RESET =
  "KNOWLEDGE_BASE_DOCUMENT_LIST_RESET";

export const KNOWLEDGE_BASE_DOCUMENT_CREATE_REQUEST =
  "KNOWLEDGE_BASE_DOCUMENT_CREATE_REQUEST";
export const KNOWLEDGE_BASE_DOCUMENT_CREATE_SUCCESS =
  "KNOWLEDGE_BASE_DOCUMENT_CREATE_SUCCESS";
export const KNOWLEDGE_BASE_DOCUMENT_CREATE_FAIL =
  "KNOWLEDGE_BASE_DOCUMENT_CREATE_FAIL";
export const KNOWLEDGE_BASE_DOCUMENT_CREATE_RESET =
  "KNOWLEDGE_BASE_DOCUMENT_CREATE_RESET";

export const KNOWLEDGE_BASE_DOCUMENT_DETAILS_REQUEST =
  "KNOWLEDGE_BASE_DOCUMENT_DETAILS_REQUEST";
export const KNOWLEDGE_BASE_DOCUMENT_DETAILS_SUCCESS =
  "KNOWLEDGE_BASE_DOCUMENT_DETAILS_SUCCESS";
export const KNOWLEDGE_BASE_DOCUMENT_DETAILS_FAIL =
  "KNOWLEDGE_BASE_DOCUMENT_DETAILS_FAIL";
export const KNOWLEDGE_BASE_DOCUMENT_DETAILS_RESET =
  "KNOWLEDGE_BASE_DOCUMENT_DETAILS_RESET";

export const KNOWLEDGE_BASE_DOCUMENT_UPDATE_REQUEST =
  "KNOWLEDGE_BASE_DOCUMENT_UPDATE_REQUEST";
export const KNOWLEDGE_BASE_DOCUMENT_UPDATE_SUCCESS =
  "KNOWLEDGE_BASE_DOCUMENT_UPDATE_SUCCESS";
export const KNOWLEDGE_BASE_DOCUMENT_UPDATE_FAIL =
  "KNOWLEDGE_BASE_DOCUMENT_UPDATE_FAIL";
export const KNOWLEDGE_BASE_DOCUMENT_UPDATE_RESET =
  "KNOWLEDGE_BASE_DOCUMENT_UPDATE_RESET";

export const KNOWLEDGE_BASE_DOCUMENT_DELETE_REQUEST =
  "KNOWLEDGE_BASE_DOCUMENT_DELETE_REQUEST";
export const KNOWLEDGE_BASE_DOCUMENT_DELETE_SUCCESS =
  "KNOWLEDGE_BASE_DOCUMENT_DELETE_SUCCESS";
export const KNOWLEDGE_BASE_DOCUMENT_DELETE_FAIL =
  "KNOWLEDGE_BASE_DOCUMENT_DELETE_FAIL";
export const KNOWLEDGE_BASE_DOCUMENT_DELETE_RESET =
  "KNOWLEDGE_BASE_DOCUMENT_DELETE_RESET";
