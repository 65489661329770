export const STAFF_PERMISSION_LIST_REQUEST = "STAFF_PERMISSION_LIST_REQUEST";
export const STAFF_PERMISSION_LIST_SUCCESS = "STAFF_PERMISSION_LIST_SUCCESS";
export const STAFF_PERMISSION_LIST_FAIL = "STAFF_PERMISSION_LIST_FAIL";
export const STAFF_PERMISSION_LIST_RESET = "STAFF_PERMISSION_LIST_RESET";

export const STAFF_PERMISSION_CREATE_REQUEST =
  "STAFF_PERMISSION_CREATE_REQUEST";
export const STAFF_PERMISSION_CREATE_SUCCESS =
  "STAFF_PERMISSION_CREATE_SUCCESS";
export const STAFF_PERMISSION_CREATE_FAIL = "STAFF_PERMISSION_CREATE_FAIL";
export const STAFF_PERMISSION_CREATE_RESET = "STAFF_PERMISSION_CREATE_RESET";

export const STAFF_PERMISSION_DETAILS_REQUEST =
  "STAFF_PERMISSION_DETAILS_REQUEST";
export const STAFF_PERMISSION_DETAILS_SUCCESS =
  "STAFF_PERMISSION_DETAILS_SUCCESS";
export const STAFF_PERMISSION_DETAILS_FAIL = "STAFF_PERMISSION_DETAILS_FAIL";
export const STAFF_PERMISSION_DETAILS_RESET = "STAFF_PERMISSION_DETAILS_RESET";

export const STAFF_PERMISSION_UPDATE_REQUEST =
  "STAFF_PERMISSION_UPDATE_REQUEST";
export const STAFF_PERMISSION_UPDATE_SUCCESS =
  "STAFF_PERMISSION_UPDATE_SUCCESS";
export const STAFF_PERMISSION_UPDATE_FAIL = "STAFF_PERMISSION_UPDATE_FAIL";
export const STAFF_PERMISSION_UPDATE_RESET = "STAFF_PERMISSION_UPDATE_RESET";

export const STAFF_PERMISSION_DELETE_REQUEST =
  "STAFF_PERMISSION_DELETE_REQUEST";
export const STAFF_PERMISSION_DELETE_SUCCESS =
  "STAFF_PERMISSION_DELETE_SUCCESS";
export const STAFF_PERMISSION_DELETE_FAIL = "STAFF_PERMISSION_DELETE_FAIL";
export const STAFF_PERMISSION_DELETE_RESET = "STAFF_PERMISSION_DELETE_RESET";
