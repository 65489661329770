// Constant imports
import {
  INSIGHT_PERMISSION_LIST_REQUEST,
  INSIGHT_PERMISSION_LIST_SUCCESS,
  INSIGHT_PERMISSION_LIST_FAIL,
  INSIGHT_PERMISSION_LIST_RESET,
  INSIGHT_PERMISSION_CREATE_REQUEST,
  INSIGHT_PERMISSION_CREATE_SUCCESS,
  INSIGHT_PERMISSION_CREATE_FAIL,
  INSIGHT_PERMISSION_CREATE_RESET,
  INSIGHT_PERMISSION_DETAILS_REQUEST,
  INSIGHT_PERMISSION_DETAILS_SUCCESS,
  INSIGHT_PERMISSION_DETAILS_FAIL,
  INSIGHT_PERMISSION_DETAILS_RESET,
  INSIGHT_PERMISSION_UPDATE_REQUEST,
  INSIGHT_PERMISSION_UPDATE_SUCCESS,
  INSIGHT_PERMISSION_UPDATE_FAIL,
  INSIGHT_PERMISSION_UPDATE_RESET,
  INSIGHT_PERMISSION_DELETE_REQUEST,
  INSIGHT_PERMISSION_DELETE_SUCCESS,
  INSIGHT_PERMISSION_DELETE_FAIL,
  INSIGHT_PERMISSION_DELETE_RESET,
} from "../constants/insightPermissionConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listInsightPermissionsAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSIGHT_PERMISSION_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInsightPermissions(
        params,
        token
      );

      dispatch({
        type: INSIGHT_PERMISSION_LIST_SUCCESS,

        payload: {
          count: data.count,
          insight_permissions: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: INSIGHT_PERMISSION_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListInsightPermissionsAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_PERMISSION_LIST_RESET });
};

// Create
export const createInsightPermissionAction =
  (insight_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSIGHT_PERMISSION_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createInsightPermission(
        token,
        insight_permission
      );

      dispatch({
        type: INSIGHT_PERMISSION_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INSIGHT_PERMISSION_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateInsightPermissionAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_PERMISSION_CREATE_RESET });
};

// List details
export const listInsightPermissionDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSIGHT_PERMISSION_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getInsightPermission(id, token);

      dispatch({
        type: INSIGHT_PERMISSION_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INSIGHT_PERMISSION_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListInsightPermissionDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: INSIGHT_PERMISSION_DETAILS_RESET });
  };

// Update
export const updateInsightPermissionAction =
  (id, insight_permission) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSIGHT_PERMISSION_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateInsightPermission(
        id,
        token,
        insight_permission
      );

      dispatch({
        type: INSIGHT_PERMISSION_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INSIGHT_PERMISSION_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateInsightPermissionAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_PERMISSION_UPDATE_RESET });
};

// Delete
export const deleteInsightPermissionAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: INSIGHT_PERMISSION_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteInsightPermission(
        id,
        token
      );

      dispatch({
        type: INSIGHT_PERMISSION_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: INSIGHT_PERMISSION_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteInsightPermissionAction = () => async (dispatch) => {
  dispatch({ type: INSIGHT_PERMISSION_DELETE_RESET });
};
