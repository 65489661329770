// Library imports
import { Card, Classes, Colors, H6 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Main function
function NewsCard({
  date,
  loading,
  interactive,
  noBackground,
  onClick,
  text,
  thumbnail,
  title,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <div style={{ width: "100%" }}>
      <Card
        interactive={interactive}
        onClick={onClick}
        style={{
          background: noBackground
            ? lightMode
              ? Colors.WHITE
              : Colors.DARK_GRAY5
            : lightMode
            ? Colors.LIGHT_GRAY5
            : Colors.DARK_GRAY2,
        }}
      >
        {loading ? (
          <H6 className={Classes.SKELETON}>XXXXXXXXXX</H6>
        ) : (
          <div style={{ display: "flex" }}>
            <div style={{ padding: "0 20px 0 0", width: "100px" }}>
              <img
                alt="thumbnail"
                src={thumbnail ? thumbnail : "/images/no_image.png"}
                style={{
                  border: `1px solid ${
                    lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                  }`,
                  borderRadius: "10%",
                  margin: "0 0 0 0",
                  objectFit: "contain",
                  width: "100%",
                }}
              />
            </div>

            <div>
              {title ? <H6>{title}</H6> : undefined}

              {text ? (
                <div style={{ margin: "15px 0 0 0", whiteSpace: "pre-wrap" }}>
                  {text}
                </div>
              ) : undefined}

              {date ? (
                <div
                  className={`${Classes.TEXT_SMALL} ${Classes.TEXT_MUTED}`}
                  style={{
                    display: "flex",
                    margin: "15px 0 0 0",
                  }}
                >
                  {date}
                </div>
              ) : undefined}
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewsCard;
