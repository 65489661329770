// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  ButtonGroup,
  Callout,
  Card,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  H5,
  HTMLSelect,
  Icon,
  InputGroup,
  NonIdealState,
  Tab,
  Tabs,
  Tag,
  Text,
} from "@blueprintjs/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useWebSocket from "react-use-websocket";

// Component imports
import { AppToaster } from "../components/AppToaster";
import ClientInfo from "../components/ClientInfo";
import CommunicationPlanStatsCard from "../components/CommunicationPlanStatsCard";
import ContactApplicationInfo from "../components/ContactApplicationInfo";
import CreateCommunicationActionButton from "../components/CreateCommunicationActionButton";
import DataCardContainer from "../components/DataCardContainer";
import DataCardSimple from "../components/DataCardSimple";
import DateValue from "../components/DateValue";
import EngagementActualsCard from "../components/EngagementActualsCard";
import EngagementPlanAtCompletionCard from "../components/EngagementPlanAtCompletionCard";
import ErrorAlert from "../components/ErrorAlert";
import FormDrawerButtonField from "../components/FormDrawerButtonField";
import FormDrawerContainer2 from "../components/FormDrawerContainer2";
import FormDrawerDateField2 from "../components/FormDrawerDateField2";
import FormDrawerFileField2 from "../components/FormDrawerFileField2";
import FormDrawerHelpContainer from "../components/FormDrawerHelpContainer";
import FormDrawerNumericField2 from "../components/FormDrawerNumericField2";
import FormDrawerSelectField2 from "../components/FormDrawerSelectField2";
import FormDrawerSelectSimpleField3 from "../components/FormDrawerSelectSimpleField3";
import FormDrawerSliderField from "../components/FormDrawerSliderField";
import FormDrawerTextAreaField3 from "../components/FormDrawerTextAreaField3";
import FormDrawerTextField2 from "../components/FormDrawerTextField2";
import HelpDialog from "../components/HelpDialog";
import ItemCardList from "../components/ItemCardList";
import Kanban from "../components/Kanban";
import NameValue from "../components/NameValue";
import NavbarContainer from "../components/NavbarContainer";
import NumericValue from "../components/NumericValue";
import PermissionCard from "../components/PermissionCard";
import ProposalAverageValueCard from "../components/ProposalAverageValueCard";
import ProposalPipelineCard from "../components/ProposalPipelineCard";
import ProposalTotalValueCard from "../components/ProposalTotalValueCard";
import ProposalWinLossCard from "../components/ProposalWinLossCard";
import SearchField from "../components/SearchField";
import TableColumnHeadDate from "../components/TableColumnHeadDate";
import TableColumnHeadSearch from "../components/TableColumnHeadSearch";
import TableColumnHeadSelect2 from "../components/TableColumnHeadSelect2";
import TableColumnHeadSelectSimple from "../components/TableColumnHeadSelectSimple";
import TableContainer2 from "../components/TableContainer2";
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import TableText from "../components/TableText";
import TabsContainer from "../components/TabsContainer";
import TabSection2 from "../components/TabSection2";
import TabSectionEmailLink from "../components/TabSectionEmailLink";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCard from "../components/TabSectionFieldCard";
import TabSectionFieldLink from "../components/TabSectionFieldLink";
import TabSectionFieldMultifieldCard from "../components/TabSectionFieldMultifieldCard";
import TabSectionFieldProgressBar from "../components/TabSectionFieldProgressBar";
import TagPopup from "../components/TagPopup";

// Utility imports
import generateKanbanDataFromContactActions from "../utilities/generateKanbanDataFromContactActions";
import getDjangoDate from "../utilities/getDjangoDate";
import setTextColour from "../utilities/setTextColour";

// Redux action imports
// Client
import { listClientsAction } from "../actions/clientActions";

// Contact
import {
  deleteContactAction,
  listContactDetailsAction,
  resetDeleteContactAction,
  resetListContactsAction,
  resetUpdateContactAction,
  updateContactAction,
} from "../actions/contactActions";

// Contact action
import {
  createContactActionAction,
  deleteContactActionAction,
  listContactActionsAction,
  listContactActionDetailsAction,
  resetCreateContactActionAction,
  resetDeleteContactActionAction,
  resetUpdateContactActionAction,
  updateContactActionAction,
} from "../actions/contactActionActions";

// Contact permission
import {
  createContactPermissionAction,
  deleteContactPermissionAction,
  listContactPermissionsAction,
  listContactPermissionDetailsAction,
  resetCreateContactPermissionAction,
  resetDeleteContactPermissionAction,
  resetUpdateContactPermissionAction,
  updateContactPermissionAction,
} from "../actions/contactPermissionActions";

// Engagement
import {
  listEngagementsAction,
  resetListEngagementDetailsAction,
} from "../actions/engagementActions";

// HubSpot integration
import {
  hubspotGetContactAction,
  hubspotResetGetContactAction,
} from "../actions/hubspotActions";

// Manager
import { listManagersAction } from "../actions/managerActions";

// Proposal
import {
  listProposalsAction,
  resetListProposalDetailsAction,
} from "../actions/proposalActions";

// Main function
function ContactDetailScreen() {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Contact id
  const id = useParams().id;

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Client
  const clientList = useSelector((state) => state.clientList);

  // Contact
  const contactDelete = useSelector((state) => state.contactDelete);

  const contactDetails = useSelector((state) => state.contactDetails);

  const contactUpdate = useSelector((state) => state.contactUpdate);

  // Contact action
  const contactActionCreate = useSelector((state) => state.contactActionCreate);

  const contactActionDelete = useSelector((state) => state.contactActionDelete);

  const contactActionDetails = useSelector(
    (state) => state.contactActionDetails
  );

  const contactActionList = useSelector((state) => state.contactActionList);

  const contactActionUpdate = useSelector((state) => state.contactActionUpdate);

  // Contact permission
  const contactPermissionCreate = useSelector(
    (state) => state.contactPermissionCreate
  );

  const contactPermissionDelete = useSelector(
    (state) => state.contactPermissionDelete
  );

  const contactPermissionDetails = useSelector(
    (state) => state.contactPermissionDetails
  );

  const contactPermissionList = useSelector(
    (state) => state.contactPermissionList
  );

  const contactPermissionUpdate = useSelector(
    (state) => state.contactPermissionUpdate
  );

  // Engagement
  const engagementList = useSelector((state) => state.engagementList);

  // Manager
  const managerList = useSelector((state) => state.managerList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // Proposal
  const proposalList = useSelector((state) => state.proposalList);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("contact-admin-tab");

  // Dialog variables
  // Help
  const [helpDialogIsOpen, setHelpDialogIsOpen] = useState(false);

  // Summary
  const [updateSummaryDrawerIsOpen, setUpdateSummaryDrawerIsOpen] =
    useState(false);

  // Permissions
  const [addPermissionDrawerIsOpen, setAddPermissionDrawerIsOpen] =
    useState(false);
  const [updatePermissionDrawerIsOpen, setUpdatePermissionDrawerIsOpen] =
    useState(false);

  // Actions
  const [addActionDrawerIsOpen, setAddActionDrawerIsOpen] = useState(false);
  const [updateActionDrawerIsOpen, setUpdateActionDrawerIsOpen] =
    useState(false);

  // Profile
  const [updateProfileDrawerIsOpen, setUpdateProfileDrawerIsOpen] =
    useState(false);

  // Other
  const [initialiseActionKanban, setInitialiseActionKanban] = useState(true);
  const [showActionKanban, setShowActionKanban] = useState(false);

  // Search and filter variables
  // Client
  const clientQueryDefault = {
    manager: "",
    market: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    ticker: "",
  };
  const [clientQuery, setClientQuery] = useState({
    ...clientQueryDefault,
  });

  // Contact action
  const contactActionQueryDefault = {
    category: "",
    contact: id,
    dueEnd: "",
    dueStart: "",
    owner: "",
    page: 1,
    searchString: "",
    status: "",
  };
  const [contactActionQuery, setContactActionQuery] = useState({
    ...contactActionQueryDefault,
  });

  // Contact permission
  const contactPermissionQueryDefault = {
    contact: id,
    page: 1,
    searchString: "",
  };
  const [contactPermissionQuery, setContactPermissionQuery] = useState({
    ...contactPermissionQueryDefault,
  });

  // Engagement
  const engagementQueryDefault = {
    client: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    sponsor: id,
    status: "",
    user: "",
  };
  const [engagementQuery, setEngagementQuery] = useState({
    ...engagementQueryDefault,
  });

  // Manager
  const managerQueryDefault = {
    approvalRole: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
  };
  const [managerQuery, setManagerQuery] = useState({
    ...managerQueryDefault,
  });

  // Proposal
  const proposalQueryDefault = {
    client: "",
    contact: id,
    dueEnd: "",
    dueStart: "",
    exclude: "",
    manager: "",
    organisation: activeOrganisation.id,
    page: 1,
    searchString: "",
    sortField: "",
    status: "",
    unlinked: "",
    user: "",
  };
  const [proposalQuery, setProposalQuery] = useState({
    ...proposalQueryDefault,
  });

  // Form variables
  // Contact
  const contactDefault = {
    attitudeTowardUs: 3,
    budget: 0,
    client: "",
    clientName: "",
    email: "",
    emailFreq: "Monthly",
    function: "",
    inPersonFreq: "Monthly",
    linkedinPage: "",
    manager: "",
    managerName: "",
    name: "",
    notes: "",
    phone: "",
    phoneFreq: "Monthly",
    primaryDriver: "Other",
    profilePhoto: "",
    profilePhotoName: "",
    restricted: true,
    role: "",
    secondaryDriver: "Other",
    seniority: "Middle Management",
  };
  const [contact, setContact] = useState({
    ...contactDefault,
  });

  // Contact action
  const contactActionDefault = {
    action: "",
    category: "Other",
    comments: "",
    due: "",
    name: "",
    owner: "",
    ownerName: "",
    status: "In-progress",
  };
  const [contactAction, setContactAction] = useState({
    ...contactActionDefault,
  });

  // Contact permission
  const contactPermissionDefault = {
    level: "View",
    manager: "",
    managerName: "",
  };
  const [contactPermission, setContactPermission] = useState({
    ...contactPermissionDefault,
  });

  // Other
  const [contactDeleteKey, setContactDeleteKey] = useState("");

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // Contact
    setContact({
      ...contactDefault,
    });

    // Contact action
    setContactAction({
      ...contactActionDefault,
    });

    // Contact permission
    setContactPermission({
      ...contactPermissionDefault,
    });

    // Query variables
    // Client
    setClientQuery({
      ...clientQueryDefault,
    });

    // Manager
    setManagerQuery({
      ...managerQueryDefault,
    });
  };

  // Streaming data websocket connection
  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (contactDetails.contact.email) {
        resolve(
          `${BACKEND_WS_URL}/api/v1/data/contact/${contactDetails.contact.email}/`
        );
      }
    });
  }, [contactDetails.contact.email]);

  const { lastJsonMessage } = useWebSocket(getSocketUrl, {
    shouldReconnect: (closeEvent) => true,
  });

  // Initialise drawer variables for contact
  useEffect(() => {
    setContact({
      attitudeTowardUs: contactDetails.contact.attitude_toward_us
        ? contactDetails.contact.attitude_toward_us
        : contactDefault.attitudeTowardUs,
      budget: contactDetails.contact.budget
        ? contactDetails.contact.budget
        : contactDefault.budget,
      client: contactDetails.contact.client
        ? contactDetails.contact.client
        : contactDefault.client,
      clientName: contactDetails.contact.client_name
        ? contactDetails.contact.client_name
        : contactDefault.clientName,
      email: contactDetails.contact.email
        ? contactDetails.contact.email
        : contactDefault.email,
      emailFreq: contactDetails.contact.email_freq
        ? contactDetails.contact.email_freq
        : contactDefault.emailFreq,
      function: contactDetails.contact.function
        ? contactDetails.contact.function
        : contactDefault.function,
      inPersonFreq: contactDetails.contact.in_person_freq
        ? contactDetails.contact.in_person_freq
        : contactDefault.inPersonFreq,
      linkedinPage: contactDetails.contact.linkedin_page
        ? contactDetails.contact.linkedin_page
        : contactDefault.linkedinPage,
      manager: contactDetails.contact.manager
        ? contactDetails.contact.manager
        : contactDefault.manager,
      managerName: contactDetails.contact.manager_name
        ? contactDetails.contact.manager_name
        : contactDefault.managerName,
      name: contactDetails.contact.name
        ? contactDetails.contact.name
        : contactDefault.name,
      notes: contactDetails.contact.notes
        ? contactDetails.contact.notes
        : contactDefault.notes,
      phone: contactDetails.contact.phone
        ? contactDetails.contact.phone
        : contactDefault.phone,
      phoneFreq: contactDetails.contact.phone_freq
        ? contactDetails.contact.phone_freq
        : contactDefault.phoneFreq,
      primaryDriver: contactDetails.contact.primary_driver
        ? contactDetails.contact.primary_driver
        : contactDefault.primaryDriver,
      profilePhoto: contactDetails.contact.profile_photo
        ? contactDetails.contact.profile_photo
        : contactDefault.profilePhoto,
      profilePhotoName: contactDetails.contact.profile_photo
        ? contactDetails.contact.profile_photo.split("/").pop()
        : contactDefault.profilePhotoName,
      restricted:
        contactDetails.contact.restricted != null
          ? contactDetails.contact.restricted
          : contactDefault.restricted,
      role: contactDetails.contact.role
        ? contactDetails.contact.role
        : contactDefault.role,
      secondaryDriver: contactDetails.contact.secondary_driver
        ? contactDetails.contact.secondary_driver
        : contactDefault.secondaryDriver,
      seniority: contactDetails.contact.seniority
        ? contactDetails.contact.seniority
        : contactDefault.seniority,
    });
    // eslint-disable-next-line
  }, [contactDetails.contact]);

  // Initialise drawer variables for contact action
  useEffect(() => {
    setContactAction({
      action: contactActionDetails.contact_action.action
        ? contactActionDetails.contact_action.action
        : contactActionDefault.action,
      category: contactActionDetails.contact_action.category
        ? contactActionDetails.contact_action.category
        : contactActionDefault.category,
      comments: contactActionDetails.contact_action.comments
        ? contactActionDetails.contact_action.comments
        : contactActionDefault.comments,
      due: contactActionDetails.contact_action.due
        ? new Date(contactActionDetails.contact_action.due)
        : contactActionDefault.due,
      name: contactActionDetails.contact_action.name
        ? contactActionDetails.contact_action.name
        : contactActionDefault.name,
      owner: contactActionDetails.contact_action.owner
        ? contactActionDetails.contact_action.owner
        : contactActionDefault.owner,
      ownerName: contactActionDetails.contact_action.owner_name
        ? contactActionDetails.contact_action.owner_name
        : contactActionDefault.ownerName,
      status: contactActionDetails.contact_action.status
        ? contactActionDetails.contact_action.status
        : contactActionDefault.status,
    });
    // eslint-disable-next-line
  }, [contactActionDetails.contact_action]);

  // Initialise drawer variables for contact permission
  useEffect(() => {
    setContactPermission({
      level: contactPermissionDetails.contact_permission.level
        ? contactPermissionDetails.contact_permission.level
        : contactPermissionDefault.level,
      manager: contactPermissionDetails.contact_permission.manager
        ? contactPermissionDetails.contact_permission.manager
        : contactPermissionDefault.manager,
      managerName: contactPermissionDetails.contact_permission.manager_name
        ? contactPermissionDetails.contact_permission.manager_name
        : contactPermissionDefault.managerName,
    });
    // eslint-disable-next-line
  }, [contactPermissionDetails.contact_permission]);

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // If no token redirect to login page
  useEffect(() => {
    !userLogin.token && navigate("/login");
    // eslint-disable-next-line
  }, [userLogin.token]);

  // If no active organisation redirect to organisations page
  useEffect(() => {
    !activeOrganisation.id && navigate("/organisations");
    // eslint-disable-next-line
  }, [activeOrganisation.id]);

  // If contact deleted redirect to contacts
  useEffect(() => {
    if (contactDelete.success) {
      dispatch(resetDeleteContactAction());

      dispatch(resetListContactsAction());

      navigate("/contacts");
    }
    // eslint-disable-next-line
  }, [contactDelete.success]);

  // Load contact details
  useEffect(() => {
    dispatch(listContactDetailsAction(id));
    // eslint-disable-next-line
  }, []);

  // Filter clients
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listClientsAction({
            ...clientQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [clientQuery]);

  // Filter contact actions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listContactActionsAction({
            ...contactActionQuery,
            dueEnd: contactActionQuery.dueEnd
              ? getDjangoDate(contactActionQuery.dueEnd)
              : "",
            dueStart: contactActionQuery.dueStart
              ? getDjangoDate(contactActionQuery.dueStart)
              : "",
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactActionQuery]);

  // Filter contact permissions
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(listContactPermissionsAction({ ...contactPermissionQuery })),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [contactPermissionQuery]);

  // Filter engagements
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listEngagementsAction({
            ...engagementQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [engagementQuery]);

  // Filter managers
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listManagersAction({
            ...managerQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [managerQuery]);

  // Filter proposals
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listProposalsAction({
            ...proposalQuery,
            dueEnd: proposalQuery.dueEnd
              ? getDjangoDate(proposalQuery.dueEnd)
              : getDjangoDate(proposalQueryDefault.dueEnd),
            dueStart: proposalQuery.dueStart
              ? getDjangoDate(proposalQuery.dueStart)
              : getDjangoDate(proposalQueryDefault.dueStart),
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [proposalQuery]);

  // Form submission
  // Contact
  // Delete contact
  const deleteContact = () => {
    // Dispatch action
    contactDeleteKey === contactDetails.contact.name
      ? dispatch(deleteContactAction(id))
      : AppToaster.show({
          icon: "warning-sign",
          intent: "danger",
          message: "The text entered does not match the contact's name",
        });
  };

  // Update communication plan
  const freqOptions = [
    { label: "Monthly", value: "Monthly" },
    { label: "Quarterly", value: "Quarterly" },
    { label: "Annual", value: "Annual" },
  ];
  const [updateCommPlan, setUpdateCommPlan] = useState(false);

  useEffect(() => {
    if (updateCommPlan) {
      // Prepare data
      const contact_data = new FormData();

      contact.emailFreq
        ? contact_data.append("email_freq", contact.emailFreq)
        : contact_data.append("email_freq", contactDefault.emailFreq);

      contact.inPersonFreq
        ? contact_data.append("in_person_freq", contact.inPersonFreq)
        : contact_data.append("in_person_freq", contactDefault.inPersonFreq);

      contact.phoneFreq
        ? contact_data.append("phone_freq", contact.phoneFreq)
        : contact_data.append("phone_freq", contactDefault.phoneFreq);

      // Dispatch action
      dispatch(
        updateContactAction(contactDetails.contact.id, "", contact_data)
      );
    }
    // eslint-disable-next-line
  }, [contact.emailFreq, contact.inPersonFreq, contact.phoneFreq]);

  useEffect(() => {
    if (contactUpdate.success && updateCommPlan) {
      dispatch(listContactDetailsAction(id));

      setUpdateCommPlan(false);
    }
    // eslint-disable-next-line
  }, [contactUpdate.success]);

  // Update profile
  const updateProfile = () => {
    // Prepare data
    const contact_data = new FormData();

    contact.attitudeTowardUs
      ? contact_data.append("attitude_toward_us", contact.attitudeTowardUs)
      : contact_data.append(
          "attitude_toward_us",
          contactDefault.attitudeTowardUs
        );

    contact.budget
      ? contact_data.append("budget", contact.budget)
      : contact_data.append("budget", contactDefault.budget);

    const client_data = new FormData();
    if (contact.client) {
      contact_data.append("client", contact.client);
    } else {
      contact_data.append("client", contactDefault.client);

      if (contact.clientName) {
        client_data.append("name", contact.clientName);

        client_data.append("organisation", activeOrganisation.id);
      }
    }

    contact.function
      ? contact_data.append("function", contact.function)
      : contact_data.append("function", contactDefault.function);

    contact.primaryDriver
      ? contact_data.append("primary_driver", contact.primaryDriver)
      : contact_data.append("primary_driver", contactDefault.primaryDriver);

    contact.role
      ? contact_data.append("role", contact.role)
      : contact_data.append("role", contactDefault.role);

    contact.secondaryDriver
      ? contact_data.append("secondary_driver", contact.secondaryDriver)
      : contact_data.append("secondary_driver", contactDefault.secondaryDriver);

    contact.seniority
      ? contact_data.append("seniority", contact.seniority)
      : contact_data.append("seniority", contactDefault.seniority);

    // Dispatch action
    dispatch(
      updateContactAction(contactDetails.contact.id, client_data, contact_data)
    );
  };

  // Update summary
  const updateSummary = () => {
    // Prepare data
    const contact_data = new FormData();

    contact.email
      ? contact_data.append("email", contact.email)
      : contact_data.append("email", contactDefault.email);

    contact.linkedinPage
      ? contact_data.append("linkedin_page", contact.linkedinPage)
      : contact_data.append("linkedin_page", contactDefault.linkedinPage);

    contact.manager
      ? contact_data.append("manager", contact.manager)
      : contact_data.append("manager", contactDefault.manager);

    contact.name
      ? contact_data.append("name", contact.name)
      : contact_data.append("name", contactDefault.name);

    contact.notes
      ? contact_data.append("notes", contact.notes)
      : contact_data.append("notes", contactDefault.notes);

    contact.phone
      ? contact_data.append("phone", contact.phone)
      : contact_data.append("phone", contactDefault.phone);

    if (
      contact.profilePhoto &&
      contact.profilePhoto !== contactDetails.contact.profile_photo
    ) {
      contact_data.append("profile_photo", contact.profilePhoto);
    } else if (contact.profilePhoto === "") {
      contact_data.append("profile_photo", contactDefault.profilePhoto);
    }

    contact.restricted != null
      ? contact_data.append("restricted", contact.restricted)
      : contact_data.append("restricted", contactDefault.restricted);

    // Dispatch action
    dispatch(updateContactAction(contactDetails.contact.id, "", contact_data));
  };

  // Contact action
  const [waitingToUpdateKanban, setWaitingToUpdateKanban] = useState(false);

  // Add action
  const addAction = () => {
    // Prepare data
    const contact_action = new FormData();

    contactAction.action
      ? contact_action.append("action", contactAction.action)
      : contact_action.append("action", contactActionDefault.action);

    contactAction.category
      ? contact_action.append("category", contactAction.category)
      : contact_action.append("category", contactActionDefault.category);

    contact_action.append("contact", id);

    contactAction.due
      ? contact_action.append("due", getDjangoDate(contactAction.due))
      : contact_action.append("due", getDjangoDate(contactActionDefault.due));

    contactAction.name
      ? contact_action.append("name", contactAction.name)
      : contact_action.append("name", contactActionDefault.name);

    contactAction.owner
      ? contact_action.append("owner", contactAction.owner)
      : contact_action.append("owner", contactActionDefault.owner);

    // Dispatch action
    dispatch(createContactActionAction(contact_action));
  };

  // Delete action
  const deleteAction = () => {
    // Dispatch action
    dispatch(deleteContactActionAction(contactActionDetails.contact_action.id));
  };

  // Update action
  const updateAction = () => {
    // Prepare data
    const contact_action = new FormData();

    contactAction.action
      ? contact_action.append("action", contactAction.action)
      : contact_action.append("action", contactActionDefault.action);

    contactAction.category
      ? contact_action.append("category", contactAction.category)
      : contact_action.append("category", contactActionDefault.category);

    contactAction.comments
      ? contact_action.append("comments", contactAction.comments)
      : contact_action.append("comments", contactActionDefault.comments);

    contactAction.due
      ? contact_action.append("due", getDjangoDate(contactAction.due))
      : contact_action.append("due", getDjangoDate(contactActionDefault.due));

    contactAction.name
      ? contact_action.append("name", contactAction.name)
      : contact_action.append("name", contactActionDefault.name);

    contactAction.owner
      ? contact_action.append("owner", contactAction.owner)
      : contact_action.append("owner", contactActionDefault.owner);

    contactAction.status
      ? contact_action.append("status", contactAction.status)
      : contact_action.append("status", contactActionDefault.status);

    // Dispatch action
    dispatch(
      updateContactActionAction(
        contactActionDetails.contact_action.id,
        contact_action
      )
    );
  };

  // Update action status
  const updateActionStatus = (actionId, actionStatus) => {
    // Prepare data
    const contact_action = new FormData();

    contact_action.append("status", actionStatus);

    // Dispatch action
    dispatch(updateContactActionAction(actionId, contact_action));
  };

  useEffect(() => {
    if (
      contactActionCreate.success ||
      contactActionDelete.success ||
      contactActionUpdate.success
    ) {
      setWaitingToUpdateKanban(true);

      dispatch(listContactDetailsAction(id));

      setContactActionQuery({
        ...contactActionQueryDefault,
      });
    }
    // eslint-disable-next-line
  }, [
    contactActionCreate.success,
    contactActionDelete.success,
    contactActionUpdate.success,
  ]);

  useEffect(() => {
    if (contactDetails.success && waitingToUpdateKanban) {
      setWaitingToUpdateKanban(false);

      setInitialiseActionKanban(true);
    }
    // eslint-disable-next-line
  }, [contactDetails.success]);

  // Contact permission
  // Add permission
  const addPermission = () => {
    // Prepare data
    const contact_permission = new FormData();

    contactPermission.level
      ? contact_permission.append("level", contactPermission.level)
      : contact_permission.append("level", contactPermissionDefault.level);

    contactPermission.manager
      ? contact_permission.append("manager", contactPermission.manager)
      : contact_permission.append("manager", contactPermissionDefault.manager);

    contact_permission.append("contact", id);

    // Dispatch action
    dispatch(createContactPermissionAction(contact_permission));
  };

  // Delete permission
  const deletePermission = () => {
    // Dispatch action
    dispatch(
      deleteContactPermissionAction(
        contactPermissionDetails.contact_permission.id
      )
    );
  };

  // Update permission
  const updatePermission = () => {
    // Prepare data
    const contact_permission = new FormData();

    contactPermission.level
      ? contact_permission.append("level", contactPermission.level)
      : contact_permission.append("level", contactPermissionDefault.level);

    contactPermission.manager
      ? contact_permission.append("manager", contactPermission.manager)
      : contact_permission.append("manager", contactPermissionDefault.manager);

    // Dispatch action
    dispatch(
      updateContactPermissionAction(
        contactPermissionDetails.contact_permission.id,
        contact_permission
      )
    );
  };

  // Refresh HubSpot data
  const [hubspotData, setHubspotData] = useState({});

  useEffect(() => {
    if (contactDetails.success) {
      setHubspotData(contactDetails.contact.hubspot_data);
    }
    // eslint-disable-next-line
  }, [contactDetails.success]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.message.hubspot_data) {
        setHubspotData(JSON.parse(lastJsonMessage.message.hubspot_data));
      }
    }
    // eslint-disable-next-line
  }, [lastJsonMessage]);

  useEffect(() => {
    if (contactDetails.contact.email) {
      dispatch(
        hubspotGetContactAction(
          contactDetails.contact.organisation,
          contactDetails.contact.email
        )
      );
    } else {
      dispatch(hubspotResetGetContactAction());
    }
    // eslint-disable-next-line
  }, [contactDetails.contact.email]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      {/* Form drawers */}
      {/* Summary form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter contact details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "15px", width: "35%" }}>
                  <img
                    alt="profile"
                    src={
                      contact.profilePhoto
                        ? contact.profilePhoto !==
                          contactDetails.contact.profile_photo
                          ? URL.createObjectURL(
                              new Blob([contact.profilePhoto])
                            )
                          : contactDetails.contact.profile_photo
                        : "/images/no_photo.png"
                    }
                    style={{
                      aspectRatio: "1/1",
                      background: "white",
                      border: `1px solid ${
                        lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
                      }`,
                      borderRadius: "50%",
                      margin: "10px 0 10px 0",
                      objectFit: "contain",
                      width: "100%",
                    }}
                  />
                </div>

                <div style={{ marginLeft: "15px", width: "65%" }}>
                  {/* Contact name */}
                  <FormDrawerTextField2
                    error={contactUpdate.error}
                    fieldName="name"
                    icon="person"
                    label="Contact name"
                    labelInfo="(required)"
                    placeholder="Enter the contact's name"
                    setValueObject={setContact}
                    setValueVariable="name"
                    value={contact.name}
                  />

                  {/* Phone */}
                  <FormDrawerTextField2
                    error={contactUpdate.error}
                    fieldName="phone"
                    helperText="Include the country code"
                    icon="phone"
                    label="Phone"
                    placeholder="Enter the contact's phone number"
                    setValueObject={setContact}
                    setValueVariable="phone"
                    value={contact.phone}
                  />

                  {/* Email */}
                  <FormDrawerTextField2
                    error={contactUpdate.error}
                    fieldName="email"
                    icon="envelope"
                    label="Email"
                    placeholder="Enter the contact's email"
                    setValueObject={setContact}
                    setValueVariable="email"
                    value={contact.email}
                  />

                  {/* LinkedIn page */}
                  <FormDrawerTextField2
                    error={contactUpdate.error}
                    fieldName="linkedin_page"
                    helperText="Enter the full url"
                    icon="social-media"
                    label="LinkedIn page"
                    placeholder="Enter the contact's LinkedIn page"
                    setValueObject={setContact}
                    setValueVariable="linkedinPage"
                    value={contact.linkedinPage}
                  />

                  {/* Profile photo */}
                  <FormDrawerFileField2
                    error={contactUpdate.error}
                    fileName="profile_photo"
                    label="Profile photo"
                    placeholder="Upload the contact's profile photo"
                    setValueObject={setContact}
                    setValueFileVariable="profilePhoto"
                    setValueNameVariable="profilePhotoName"
                    valueFile={contact.profilePhoto}
                    valueName={contact.profilePhotoName}
                  />
                </div>
              </div>

              {/* Notes */}
              <FormDrawerTextAreaField3
                error={contactUpdate.error}
                fieldName="notes"
                label="Notes"
                placeholder="Enter your notes on the contact"
                setValueObject={setContact}
                setValueVariable="notes"
                value={contact.notes}
              />
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Manage access</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Access */}
                  <FormDrawerButtonField
                    disabled={
                      contactDetails.contact.user_access_status === "edit" ||
                      contactDetails.contact.user_access_status === "view"
                    }
                    error={contactUpdate.error}
                    fieldName="restricted"
                    helperText="A restricted contact can only be accessed by users with permissions"
                    label="Access"
                    options={[
                      {
                        icon: "lock",
                        id: 1,
                        intent: "danger",
                        label: "Restricted",
                        value: true,
                      },
                      {
                        icon: "unlock",
                        id: 2,
                        intent: "success",
                        label: "Unrestricted",
                        value: false,
                      },
                    ]}
                    setValueObject={setContact}
                    setValueVariable="restricted"
                    value={contact.restricted}
                  />

                  {/* Manager */}
                  <FormDrawerSelectField2
                    disabled={
                      contactDetails.contact.user_access_status === "edit" ||
                      contactDetails.contact.user_access_status === "view"
                    }
                    error={contactUpdate.error}
                    fieldName="manager"
                    helperText="Person who manages the contact - defaults to the organisation owner"
                    icon="layout-hierarchy"
                    items={managerList.managers}
                    label="Manager"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select a manager"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setContact}
                    setValueIdVariable="manager"
                    setValueNameVariable="managerName"
                    value={contact.managerName}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  <Callout intent="warning">
                    If you remove yourself as the contact manager and the
                    contact is restricted, you will not be able to access it
                    again, unless you are organisation administrator/owner or
                    have edit/view permission.
                  </Callout>
                </div>
              </div>
            </Card>
          </div>
        }
        error={contactUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update summary"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Updating a contact"
        icon="edit"
        isOpen={updateSummaryDrawerIsOpen}
        loading={contactDetails.loading}
        onSave={updateSummary}
        refreshList={() => {
          dispatch(listContactDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactUpdate.success}
        saveSuccessMessage="Contact updated."
        saving={contactUpdate.loading}
        setIsOpen={setUpdateSummaryDrawerIsOpen}
        title="Update summary"
      />

      {/* New permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              error={contactPermissionCreate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setContactPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={contactPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={contactPermissionCreate.error}
              fieldName="level"
              intent={
                contactPermission.level === "Edit"
                  ? "success"
                  : contactPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setContactPermission}
              setValueVariable="level"
              value={contactPermission.level}
            />
          </Card>
        }
        error={contactPermissionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Add permission"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={addPermissionDrawerIsOpen}
        onSave={addPermission}
        refreshList={() => {
          dispatch(listContactPermissionsAction({ contact: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateContactPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactPermissionCreate.success}
        saveSuccessMessage="Permission added."
        saving={contactPermissionCreate.loading}
        setIsOpen={setAddPermissionDrawerIsOpen}
        size="small"
        title="Add permission"
      />

      {/* Existing permission form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Create the permission</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            {/* Manager */}
            <FormDrawerSelectField2
              disabled
              error={contactPermissionUpdate.error}
              fieldName="manager"
              icon="user"
              items={managerList.managers}
              label="Manager"
              labelInfo="(required)"
              loading={managerList.loading}
              placeholder="Select a manager"
              query={managerQuery.searchString}
              setQueryObject={setManagerQuery}
              setQueryVariable="searchString"
              setValueObject={setContactPermission}
              setValueIdVariable="manager"
              setValueNameVariable="managerName"
              value={contactPermission.managerName}
            />

            {/* Level */}
            <FormDrawerSelectSimpleField3
              error={contactPermissionUpdate.error}
              fieldName="level"
              intent={
                contactPermission.level === "Edit"
                  ? "success"
                  : contactPermission.level === "View"
                  ? "primary"
                  : undefined
              }
              label="Level"
              options={[
                {
                  id: 1,
                  icon: "eye-open",
                  value: "View",
                },
                { id: 2, icon: "edit", value: "Edit" },
              ]}
              setValueObject={setContactPermission}
              setValueVariable="level"
              value={contactPermission.level}
            />
          </Card>
        }
        deleteSuccess={contactPermissionDelete.success}
        deleteSuccessMessage="Permission removed."
        deleting={contactPermissionDelete.loading}
        error={contactPermissionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update permission"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Managing permissions"
        icon="edit"
        isOpen={updatePermissionDrawerIsOpen}
        loading={contactPermissionDetails.loading}
        onDelete={deletePermission}
        onSave={updatePermission}
        refreshList={() => {
          dispatch(listContactPermissionsAction({ ...contactPermissionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteContactPermissionAction());

          dispatch(resetUpdateContactPermissionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactPermissionUpdate.success}
        saveSuccessMessage="Permission updated."
        saving={contactPermissionUpdate.loading}
        setIsOpen={setUpdatePermissionDrawerIsOpen}
        size="small"
        title="Update permission"
      />

      {/* New action form drawer */}
      <FormDrawerContainer2
        body={
          <Card style={{ width: "100%" }}>
            <H5>Enter the action</H5>

            <Divider style={{ margin: "0 0 15px 0" }} />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Title */}
                <FormDrawerTextField2
                  error={contactActionCreate.error}
                  fieldName="name"
                  icon="take-action"
                  label="Title"
                  labelInfo="(required)"
                  placeholder="Give the action a title"
                  setValueObject={setContactAction}
                  setValueVariable="name"
                  value={contactAction.name}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Category */}
                <FormDrawerSelectSimpleField3
                  error={contactActionCreate.error}
                  fieldName="category"
                  label="Category"
                  options={[
                    {
                      id: 1,
                      icon: "gantt-chart",
                      value: "Delivery",
                    },
                    { id: 2, icon: "manual", value: "Proposal" },
                    { id: 3, icon: "chat", value: "Reference" },
                    { id: 4, icon: "one-to-one", value: "Relationship" },
                    { id: 5, icon: "more", value: "Other" },
                  ]}
                  setValueObject={setContactAction}
                  setValueVariable="category"
                  value={contactAction.category}
                />
              </div>
            </div>

            {/* Action */}
            <FormDrawerTextAreaField3
              error={contactActionCreate.error}
              fieldName="action"
              height="100px"
              label="Action"
              labelInfo="(required)"
              placeholder="Enter the action"
              setValueObject={setContactAction}
              setValueVariable="action"
              value={contactAction.action}
            />

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px", width: "60%" }}>
                {/* Owner */}
                <FormDrawerSelectField2
                  error={contactActionCreate.error}
                  fieldName="owner"
                  helperText="Person responsible for completing the action"
                  icon="user"
                  items={managerList.managers}
                  label="Owner"
                  loading={managerList.loading}
                  noOptionOnClick
                  placeholder="Select an owner"
                  query={managerQuery.searchString}
                  setQueryObject={setManagerQuery}
                  setQueryVariable="searchString"
                  setValueObject={setContactAction}
                  setValueIdVariable="owner"
                  setValueNameVariable="ownerName"
                  value={contactAction.ownerName}
                />
              </div>

              <div style={{ marginLeft: "10px", width: "40%" }}>
                {/* Due date */}
                <FormDrawerDateField2
                  error={contactActionCreate.error}
                  fieldName="due"
                  icon="calendar"
                  label="Due date"
                  placeholder="Enter the action due date"
                  setValueObject={setContactAction}
                  setValueVariable="due"
                  value={contactAction.due}
                />
              </div>
            </div>
          </Card>
        }
        error={contactActionCreate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Create action"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={addActionDrawerIsOpen}
        onSave={addAction}
        refreshList={() => {
          dispatch(listContactActionsAction({ contact: id }));
        }}
        resetAction={() => {
          dispatch(resetCreateContactActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactActionCreate.success}
        saveSuccessMessage="Action created."
        saving={contactActionCreate.loading}
        setIsOpen={setAddActionDrawerIsOpen}
        title="Create action"
      />

      {/* Existing action form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter the action</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Title */}
                  <FormDrawerTextField2
                    error={contactActionUpdate.error}
                    fieldName="name"
                    icon="take-action"
                    label="Title"
                    labelInfo="(required)"
                    placeholder="Give the action a title"
                    setValueObject={setContactAction}
                    setValueVariable="name"
                    value={contactAction.name}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}>
                  {/* Category */}
                  <FormDrawerSelectSimpleField3
                    error={contactActionUpdate.error}
                    fieldName="category"
                    label="Category"
                    options={[
                      {
                        id: 1,
                        icon: "gantt-chart",
                        value: "Delivery",
                      },
                      { id: 2, icon: "manual", value: "Proposal" },
                      { id: 3, icon: "chat", value: "Reference" },
                      { id: 4, icon: "one-to-one", value: "Relationship" },
                      { id: 5, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setContactAction}
                    setValueVariable="category"
                    value={contactAction.category}
                  />
                </div>
              </div>

              {/* Action */}
              <FormDrawerTextAreaField3
                error={contactActionUpdate.error}
                fieldName="action"
                height="100px"
                label="Action"
                labelInfo="(required)"
                placeholder="Enter the action"
                setValueObject={setContactAction}
                setValueVariable="action"
                value={contactAction.action}
              />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Owner */}
                  <FormDrawerSelectField2
                    error={contactActionUpdate.error}
                    fieldName="owner"
                    helperText="Person responsible"
                    icon="user"
                    items={managerList.managers}
                    label="Owner"
                    loading={managerList.loading}
                    noOptionOnClick
                    placeholder="Select an owner"
                    query={managerQuery.searchString}
                    setQueryObject={setManagerQuery}
                    setQueryVariable="searchString"
                    setValueObject={setContactAction}
                    setValueIdVariable="owner"
                    setValueNameVariable="ownerName"
                    value={contactAction.ownerName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                >
                  {/* Due date */}
                  <FormDrawerDateField2
                    error={contactActionUpdate.error}
                    fieldName="due"
                    icon="calendar"
                    label="Due date"
                    placeholder="Enter the action due date"
                    setValueObject={setContactAction}
                    setValueVariable="due"
                    value={contactAction.due}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Update the status</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ marginBottom: "15px" }}>
                <TabSectionFieldCard
                  tag={
                    contactActionDetails.contact_action.owner_done
                      ? "Ready to close"
                      : "Working on it"
                  }
                  tagIntent={
                    contactActionDetails.contact_action.owner_done
                      ? "success"
                      : "primary"
                  }
                  text={contactActionDetails.contact_action.owner_comments}
                  title="Owner update"
                />
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Status */}
                  <FormDrawerSelectSimpleField3
                    error={contactActionUpdate.error}
                    fieldName="status"
                    intent={
                      contactAction.status === "Completed"
                        ? "success"
                        : contactAction.status === "In-progress"
                        ? "warning"
                        : "primary"
                    }
                    label="Status"
                    options={[
                      {
                        id: 1,
                        icon: "confirm",
                        value: "Completed",
                      },
                      {
                        id: 2,
                        icon: "refresh",
                        value: "In-progress",
                      },
                      {
                        id: 3,
                        icon: "remove",
                        value: "On-hold",
                      },
                    ]}
                    setValueObject={setContactAction}
                    setValueVariable="status"
                    value={contactAction.status}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "40%" }}></div>
              </div>

              {/* Comments */}
              <FormDrawerTextAreaField3
                error={contactActionUpdate.error}
                fieldName="comments"
                label="Comments"
                placeholder="Enter any notes you want to capture"
                setValueObject={setContactAction}
                setValueVariable="comments"
                value={contactAction.comments}
              />
            </Card>
          </div>
        }
        deleteSuccess={contactActionDelete.success}
        deleteSuccessMessage="Action deleted."
        deleting={contactActionDelete.loading}
        error={contactActionUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update action"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Managing actions"
        icon="edit"
        isOpen={updateActionDrawerIsOpen}
        loading={contactActionDetails.loading}
        onDelete={deleteAction}
        onSave={updateAction}
        refreshList={() => {
          dispatch(listContactActionsAction({ ...contactActionQuery }));
        }}
        resetAction={() => {
          dispatch(resetDeleteContactActionAction());

          dispatch(resetUpdateContactActionAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactActionUpdate.success}
        saveSuccessMessage="Action updated."
        saving={contactActionUpdate.loading}
        setIsOpen={setUpdateActionDrawerIsOpen}
        title="Update action"
      />

      {/* Profile form drawer */}
      <FormDrawerContainer2
        body={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Card style={{ margin: "0 0 10px 0", width: "100%" }}>
              <H5>Enter position details</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "60%" }}>
                  {/* Client */}
                  <FormDrawerSelectField2
                    allowCreate
                    error={contactUpdate.error}
                    fieldName="client"
                    helperText="You can create or select an existing client"
                    icon="office"
                    info={
                      <ClientInfo
                        clientId={contact.client}
                        query={clientQueryDefault}
                      />
                    }
                    items={clientList.clients}
                    label="Client"
                    loading={clientList.loading}
                    placeholder="Create or select a client"
                    query={clientQuery.searchString}
                    setQueryObject={setClientQuery}
                    setQueryVariable="searchString"
                    setValueObject={setContact}
                    setValueIdVariable="client"
                    setValueNameVariable="clientName"
                    showInfo={contact.client}
                    value={contact.clientName}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "40%",
                  }}
                >
                  {/* Seniority */}
                  <FormDrawerSelectSimpleField3
                    error={contactUpdate.error}
                    fieldName="seniority"
                    label="Seniority"
                    options={[
                      {
                        id: 1,
                        icon: "arrow-up",
                        value: "Upper Management",
                      },
                      {
                        id: 2,
                        icon: "arrow-right",
                        value: "Middle Management",
                      },
                      { id: 3, icon: "arrow-down", value: "Lower Management" },
                    ]}
                    setValueObject={setContact}
                    setValueVariable="seniority"
                    value={contact.seniority}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "40%" }}>
                  {/* Function */}
                  <FormDrawerSelectSimpleField3
                    error={contactUpdate.error}
                    fieldName="function"
                    label="Function"
                    options={[
                      { id: 1, value: "Finance" },
                      { id: 2, value: "General Management" },
                      { id: 3, value: "Human Resources" },
                      {
                        id: 4,
                        value: "Information Technology",
                      },
                      {
                        id: 5,
                        value: "Marketing & Sales",
                      },
                      {
                        id: 6,
                        value: "Operations",
                      },
                      {
                        id: 7,
                        value: "Other",
                      },
                    ]}
                    setValueObject={setContact}
                    setValueVariable="function"
                    value={contact.function}
                  />
                </div>

                <div
                  style={{
                    marginLeft: "10px",
                    width: "60%",
                  }}
                >
                  {/* Role */}
                  <FormDrawerTextField2
                    error={contactUpdate.error}
                    fieldName="role"
                    icon="layout-hierarchy"
                    label="Role"
                    placeholder="Enter the contact's role"
                    setValueObject={setContact}
                    setValueVariable="role"
                    value={contact.role}
                  />
                </div>
              </div>
            </Card>

            <Card style={{ width: "100%" }}>
              <H5>Enter buyer behaviour variables</H5>

              <Divider style={{ margin: "0 0 15px 0" }} />

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Budget */}
                  <FormDrawerNumericField2
                    error={contactUpdate.error}
                    fieldName="budet"
                    helperText="Estimated annual budget available to spend in our category"
                    label="Budget"
                    leftElement={
                      <Tag
                        minimal
                        style={{ textAlign: "center", width: "50px" }}
                      >
                        {activeOrganisation.currency}
                      </Tag>
                    }
                    majorStepSize={100000}
                    min={0}
                    minorStepSize={1000}
                    placeholder="Enter the contact's estimated budget"
                    setValueObject={setContact}
                    setValueVariable="budget"
                    stepSize={10000}
                    value={contact.budget}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Primary driver */}
                  <FormDrawerSelectSimpleField3
                    error={contactUpdate.error}
                    fieldName="primary_driver"
                    helperText="The first buying driver for this contact"
                    label="Primary driver"
                    options={[
                      {
                        id: 1,
                        icon: "manually-entered-data",
                        value: "Commercials",
                      },
                      { id: 2, icon: "new-link", value: "Partnerships" },
                      { id: 3, icon: "dollar", value: "Price" },
                      { id: 4, icon: "chat", value: "References" },
                      { id: 5, icon: "new-person", value: "Relationships" },
                      { id: 6, icon: "box", value: "Solution" },
                      { id: 7, icon: "people", value: "Team" },
                      { id: 8, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setContact}
                    setValueVariable="primaryDriver"
                    value={contact.primaryDriver}
                  />
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <div style={{ marginRight: "10px", width: "50%" }}>
                  {/* Attitude toward us */}
                  <FormDrawerSliderField
                    error={contactUpdate.error}
                    fieldName="attitude_toward_us"
                    intent={
                      contact.attitudeTowardUs > 4
                        ? "success"
                        : contact.attitudeTowardUs > 2
                        ? "warning"
                        : "danger"
                    }
                    label="Attitude toward us"
                    labelRenderer={(value) => {
                      switch (value) {
                        case 1:
                          return "Negative";
                        case 5:
                          return "Positive";
                        default:
                          return null;
                      }
                    }}
                    labelStepSize={1}
                    margin="35px"
                    max={5}
                    min={1}
                    setValueObject={setContact}
                    setValueVariable="attitudeTowardUs"
                    value={contact.attitudeTowardUs}
                  />
                </div>

                <div style={{ marginLeft: "10px", width: "50%" }}>
                  {/* Secondary driver */}
                  <FormDrawerSelectSimpleField3
                    error={contactUpdate.error}
                    fieldName="secondary_driver"
                    helperText="The second buying driver for this contact"
                    label="Secondary driver"
                    options={[
                      {
                        id: 1,
                        icon: "manually-entered-data",
                        value: "Commercials",
                      },
                      { id: 2, icon: "new-link", value: "Partnerships" },
                      { id: 3, icon: "dollar", value: "Price" },
                      { id: 4, icon: "chat", value: "References" },
                      { id: 5, icon: "new-person", value: "Relationships" },
                      { id: 6, icon: "box", value: "Solution" },
                      { id: 7, icon: "people", value: "Team" },
                      { id: 8, icon: "more", value: "Other" },
                    ]}
                    setValueObject={setContact}
                    setValueVariable="secondaryDriver"
                    value={contact.secondaryDriver}
                  />
                </div>
              </div>
            </Card>
          </div>
        }
        error={contactUpdate.error}
        helpContent={
          <FormDrawerHelpContainer
            form="Update buyer profile"
            module="Contacts"
            view="Detail"
          />
        }
        helpTitle="Updating the buyer profile"
        icon="edit"
        isOpen={updateProfileDrawerIsOpen}
        loading={contactDetails.loading}
        onSave={updateProfile}
        refreshList={() => {
          dispatch(listContactDetailsAction(id));
        }}
        resetAction={() => {
          dispatch(resetUpdateContactAction());
        }}
        resetDrawerVariables={resetDrawerVariables}
        saveSuccess={contactUpdate.success}
        saveSuccessMessage="Contact buyer profile updated."
        saving={contactUpdate.loading}
        setIsOpen={setUpdateProfileDrawerIsOpen}
        title="Update buyer profile"
      />

      {/* Navigation bar */}
      <NavbarContainer
        children={
          <>
            <Button
              icon="circle-arrow-left"
              minimal
              text="Contacts"
              onClick={() => {
                dispatch(resetListContactsAction());

                navigate("/contacts");
              }}
            />

            {false && (
              <Button
                icon="help"
                minimal
                onClick={() => {
                  setHelpDialogIsOpen(true);
                }}
                text="Help"
              />
            )}
          </>
        }
        label={
          contactDetails.contact.user_access_status === "view"
            ? "View only"
            : undefined
        }
        labelIntent="primary"
        loading={contactDetails.loading}
        title={`${contactDetails.contact.name}`}
      />

      {/* Error alerts */}
      {/* Clients loading error */}
      {clientList.error && (
        <ErrorAlert message="We couldn't load the client list. Try refreshing the page." />
      )}
      {/* Contact delete error */}
      {contactDelete.error && (
        <ErrorAlert message="We couldn't delete the contact. Try refreshing the page." />
      )}
      {/* Contact loading error */}
      {contactDetails.error && (
        <ErrorAlert message="We couldn't load the contact. Try refreshing the page." />
      )}
      {/* Contact actions loading error */}
      {contactActionList.error && (
        <ErrorAlert message="We couldn't load the contact actions. Try refreshing the page." />
      )}
      {/* Contact permissions loading error */}
      {contactPermissionList.error && (
        <ErrorAlert message="We couldn't load the contact permissions. Try refreshing the page." />
      )}
      {/* Engagements loading error */}
      {engagementList.error && (
        <ErrorAlert message="We couldn't load the engagement list. Try refreshing the page." />
      )}
      {/* Managers loading error */}
      {managerList.error && (
        <ErrorAlert message="We couldn't load the manager list. Try refreshing the page." />
      )}
      {/* Proposals loading error */}
      {proposalList.error && (
        <ErrorAlert message="We couldn't load the proposal list. Try refreshing the page." />
      )}

      {/* Help */}
      <HelpDialog
        children={
          <div>
            <iframe
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src="https://www.youtube.com/embed/4ipDjRjcpgM?si=-d8G4dFCBEfRjgBO"
              title="Help"
              width="853"
            />
          </div>
        }
        height="fit-content"
        isOpen={helpDialogIsOpen}
        setIsOpen={setHelpDialogIsOpen}
        title="Contact"
        width="fit-content"
      />

      {/* Tabs */}
      <TabsContainer
        children={
          <Tabs
            id="contact-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Administration */}
            <Tab
              id="contact-admin-tab"
              title="Administration"
              panel={
                <div>
                  {/* Summary */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          contactDetails.contact.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listContactDetailsAction(id));

                          setUpdateSummaryDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <TabSectionField
                        bottom
                        label={
                          <div
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <img
                              alt="profile"
                              src={
                                contactDetails.contact.profile_photo
                                  ? contactDetails.contact.profile_photo
                                  : "/images/no_photo.png"
                              }
                              style={{
                                background: "white",
                                border: `1px solid ${
                                  lightMode
                                    ? Colors.LIGHT_GRAY5
                                    : Colors.DARK_GRAY5
                                }`,
                                borderRadius: "50%",
                                height: "150px",
                                margin: "10px 0 10px 0",
                                objectFit: "contain",
                                width: "150px",
                              }}
                            />

                            <Text ellipsize>{contactDetails.contact.name}</Text>
                          </div>
                        }
                        value={
                          <TabSectionFieldMultifieldCard
                            children={
                              <>
                                {/* Id */}
                                <TabSectionField
                                  label="Id"
                                  loading={contactDetails.loading}
                                  value={contactDetails.contact.id}
                                />

                                {/* Phone */}
                                <TabSectionField
                                  label="Phone"
                                  loading={contactDetails.loading}
                                  value={contactDetails.contact.display_phone}
                                />

                                {/* Email */}
                                <TabSectionField
                                  label="Email"
                                  loading={contactDetails.loading}
                                  value={
                                    <TabSectionEmailLink
                                      email={contactDetails.contact.email}
                                    />
                                  }
                                />

                                {/* LinkedIn page */}
                                <TabSectionField
                                  label="LinkedIn page"
                                  loading={contactDetails.loading}
                                  value={
                                    <div onClick={(e) => e.stopPropagation()}>
                                      <TabSectionFieldLink
                                        url={
                                          contactDetails.contact.linkedin_page
                                        }
                                      />
                                    </div>
                                  }
                                />

                                {/* Notes */}
                                <TabSectionField
                                  label="Notes"
                                  loading={contactDetails.loading}
                                  value={contactDetails.contact.notes}
                                />

                                {/* Access */}
                                <TabSectionField
                                  helperText={
                                    contactDetails.contact.restricted
                                      ? "Users must have permission to access this contact"
                                      : "Everyone in the organisation can edit this contact"
                                  }
                                  label="Access"
                                  loading={contactDetails.loading}
                                  value={
                                    <Tag
                                      intent={
                                        contactDetails.contact.restricted
                                          ? "danger"
                                          : "success"
                                      }
                                      large
                                      minimal
                                      style={{
                                        minWidth: "100px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {contactDetails.contact.restricted
                                        ? "Restricted"
                                        : "Unrestricted"}
                                    </Tag>
                                  }
                                />

                                {/* Manager */}
                                <TabSectionField
                                  bottom
                                  helperText={
                                    contactDetails.contact
                                      .user_access_status === "view"
                                      ? "Contact to request edit access"
                                      : undefined
                                  }
                                  label="Manager"
                                  loading={contactDetails.loading}
                                  value={
                                    <NameValue
                                      email={
                                        contactDetails.contact.manager_name
                                      }
                                      image={
                                        contactDetails.contact
                                          .manager_profile_photo
                                      }
                                    />
                                  }
                                />
                              </>
                            }
                          />
                        }
                      />
                    }
                    help="Review and update contact information"
                    icon="id-number"
                    title="Summary"
                  />

                  {/* HubSpot data */}
                  {hubspotData && (
                    <TabSection2
                      content={
                        <>
                          {/* City */}
                          <TabSectionField
                            label="City"
                            loading={contactDetails.loading}
                            value={hubspotData["city"]}
                          />

                          {/* Company */}
                          <TabSectionField
                            label="Company"
                            loading={contactDetails.loading}
                            value={hubspotData["company"]}
                          />

                          {/* Country */}
                          <TabSectionField
                            label="Country"
                            loading={contactDetails.loading}
                            value={hubspotData["country"]}
                          />

                          {/* Create date */}
                          <TabSectionField
                            label="Create date"
                            loading={contactDetails.loading}
                            value={new Date(
                              hubspotData["createdate"]
                            ).toLocaleString(undefined, {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })}
                          />

                          {/* Email */}
                          <TabSectionField
                            label="Email"
                            loading={contactDetails.loading}
                            value={hubspotData["email"]}
                          />

                          {/* First name */}
                          <TabSectionField
                            label="First name"
                            loading={contactDetails.loading}
                            value={hubspotData["firstname"]}
                          />

                          {/* Id */}
                          <TabSectionField
                            label="Id"
                            loading={contactDetails.loading}
                            value={hubspotData["hs_object_id"]}
                          />

                          {/* Industry */}
                          <TabSectionField
                            label="Industry"
                            loading={contactDetails.loading}
                            value={hubspotData["industry"]}
                          />

                          {/* Job title */}
                          <TabSectionField
                            label="Job title"
                            loading={contactDetails.loading}
                            value={hubspotData["jobtitle"]}
                          />

                          {/* Last modified date */}
                          <TabSectionField
                            label="Last modified date"
                            loading={contactDetails.loading}
                            value={new Date(
                              hubspotData["lastmodifieddate"]
                            ).toLocaleString(undefined, {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            })}
                          />

                          {/* Last name */}
                          <TabSectionField
                            label="Last name"
                            loading={contactDetails.loading}
                            value={hubspotData["lastname"]}
                          />

                          {/* Mobile phone */}
                          <TabSectionField
                            label="Mobile phone"
                            loading={contactDetails.loading}
                            value={hubspotData["mobilephone"]}
                          />

                          {/* Phone */}
                          <TabSectionField
                            label="Phone"
                            loading={contactDetails.loading}
                            value={hubspotData["phone"]}
                          />

                          {/* State */}
                          <TabSectionField
                            label="State"
                            loading={contactDetails.loading}
                            value={hubspotData["state"]}
                          />

                          {/* Zip */}
                          <TabSectionField
                            bottom
                            label="Zip"
                            loading={contactDetails.loading}
                            value={hubspotData["zip"]}
                          />
                        </>
                      }
                      help="Access information from HubSpot"
                      icon="person"
                      message="Data sourced from HubSpot."
                      messageIntent="primary"
                      title="HubSpot data"
                    />
                  )}

                  {/* Permissions */}
                  {(contactDetails.contact.user_access_status === "owner" ||
                    contactDetails.contact.user_access_status === "admin" ||
                    contactDetails.contact.user_access_status ===
                      "manager") && (
                    <TabSection2
                      buttons={
                        <Button
                          icon="add"
                          intent="primary"
                          onClick={() => {
                            setAddPermissionDrawerIsOpen(true);
                          }}
                          text="Add"
                        />
                      }
                      content={
                        <div>
                          <div style={{ margin: "0 0 20px 0", width: "300px" }}>
                            <SearchField
                              placeholder="Search manager"
                              searching={contactPermissionList.loading}
                              setValueObject={setContactPermissionQuery}
                              setValueVariable="searchString"
                              value={contactPermissionQuery.searchString}
                            />
                          </div>

                          <ItemCardList
                            content={contactPermissionList.contact_permissions.map(
                              (contact_permission) => {
                                return (
                                  <PermissionCard
                                    interactive
                                    key={contact_permission.id}
                                    onClick={() => {
                                      dispatch(
                                        listContactPermissionDetailsAction(
                                          contact_permission.id
                                        )
                                      );

                                      setUpdatePermissionDrawerIsOpen(true);
                                    }}
                                    permission={contact_permission}
                                  />
                                );
                              }
                            )}
                            count={contactPermissionList.count}
                            loading={contactPermissionList.loading}
                            noMargin
                            noResultsMessage="Add permissions."
                            noResultsTitle="No permissions found"
                            page={contactPermissionQuery.page}
                            setPageObject={setContactPermissionQuery}
                            setPageVariable="page"
                          />
                        </div>
                      }
                      help="Manage access rights"
                      icon="key"
                      message="By default, contacts are open to all members of the organisation. Enable restricted status above to assign edit and view permissions explicitly."
                      messageIntent="warning"
                      title="Permissions"
                    />
                  )}

                  {/* Delete contact */}
                  {(contactDetails.contact.user_access_status === "owner" ||
                    contactDetails.contact.user_access_status === "admin" ||
                    contactDetails.contact.user_access_status ===
                      "manager") && (
                    <TabSection2
                      content={
                        <div>
                          <ControlGroup
                            style={{
                              margin: "20px 0 0 0",
                              textAlign: "start",
                            }}
                          >
                            <InputGroup
                              disabled={contactDetails.contact.cannot_delete}
                              id="confirm-contact-name"
                              onChange={(e) => {
                                setContactDeleteKey(e.target.value);
                              }}
                              placeholder="Enter the contact's name"
                              style={{ margin: "0 5px 0 0", width: "400px" }}
                              value={contactDeleteKey}
                            />

                            <Button
                              disabled={contactDetails.contact.cannot_delete}
                              icon="trash"
                              intent="danger"
                              loading={contactDelete.loading}
                              onClick={deleteContact}
                              text="Delete this contact"
                            />
                          </ControlGroup>

                          <div
                            className={[
                              Classes.TEXT_MUTED,
                              Classes.TEXT_SMALL,
                            ].join(" ")}
                            style={{ margin: "5px 0 0 10px" }}
                          >
                            Enter "{contactDetails.contact.name}" to delete.
                          </div>
                        </div>
                      }
                      help="Delete the contact"
                      icon="delete"
                      message={
                        contactDetails.contact.cannot_delete
                          ? "This record cannot be deleted because it is referenced by other items."
                          : "If you delete this contact, you will lose all information associated with it."
                      }
                      messageIntent={
                        contactDetails.contact.cannot_delete
                          ? "primary"
                          : "danger"
                      }
                      title="Delete contact"
                    />
                  )}
                </div>
              }
            />

            {/* Intelligence */}
            <Tab
              id="contact-intelligence-tab"
              title="Intelligence"
              panel={
                <div>
                  {/* Buyer profile */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          contactDetails.contact.user_access_status === "view"
                        }
                        icon="edit"
                        intent="primary"
                        onClick={() => {
                          dispatch(listContactDetailsAction(id));

                          setUpdateProfileDrawerIsOpen(true);
                        }}
                        text="Edit"
                      />
                    }
                    content={
                      <>
                        {/* Client */}
                        <TabSectionField
                          label="Client"
                          loading={contactDetails.loading}
                          value={
                            <NameValue
                              image={contactDetails.contact.client_logo}
                              logo
                              text={contactDetails.contact.client_name}
                              url={
                                contactDetails.contact.client
                                  ? `/clients/${contactDetails.contact.client}`
                                  : undefined
                              }
                            />
                          }
                        />

                        {/* Seniority */}
                        <TabSectionField
                          label="Seniority"
                          loading={contactDetails.loading}
                          value={contactDetails.contact.seniority}
                        />

                        {/* Function */}
                        <TabSectionField
                          label="Function"
                          loading={contactDetails.loading}
                          value={contactDetails.contact.function}
                        />

                        {/* Role */}
                        <TabSectionField
                          label="Role"
                          loading={contactDetails.loading}
                          value={contactDetails.contact.role}
                        />

                        {/* Budget */}
                        <TabSectionField
                          helperText="Estimated annual budget available to spend in our category"
                          label="Budget"
                          loading={contactDetails.loading}
                          value={
                            <NumericValue
                              currency={activeOrganisation.currency}
                              number={contactDetails.contact.budget}
                            />
                          }
                        />

                        {/* Primary driver */}
                        <TabSectionField
                          label="Primary driver"
                          loading={contactDetails.loading}
                          value={
                            <Tag
                              icon={
                                contactDetails.contact.primary_driver ===
                                "Commercials"
                                  ? "manually-entered-data"
                                  : contactDetails.contact.primary_driver ===
                                    "Partnerships"
                                  ? "new-link"
                                  : contactDetails.contact.primary_driver ===
                                    "References"
                                  ? "chat"
                                  : contactDetails.contact.primary_driver ===
                                    "Relationships"
                                  ? "new-person"
                                  : contactDetails.contact.primary_driver ===
                                    "Solution"
                                  ? "box"
                                  : contactDetails.contact.primary_driver ===
                                    "Team"
                                  ? "people"
                                  : "more"
                              }
                              large
                              round
                              style={{
                                background:
                                  contactDetails.contact.primary_driver ===
                                  "Commercials"
                                    ? Colors.GOLD3
                                    : contactDetails.contact.primary_driver ===
                                      "Partnerships"
                                    ? Colors.INDIGO3
                                    : contactDetails.contact.primary_driver ===
                                      "References"
                                    ? Colors.TURQUOISE3
                                    : contactDetails.contact.primary_driver ===
                                      "Relationships"
                                    ? Colors.ROSE3
                                    : contactDetails.contact.primary_driver ===
                                      "Solution"
                                    ? Colors.LIME3
                                    : contactDetails.contact.primary_driver ===
                                      "Team"
                                    ? Colors.CERULEAN3
                                    : Colors.BLUE3,
                                color: setTextColour(
                                  contactDetails.contact.primary_driver ===
                                    "Commercials"
                                    ? Colors.GOLD3
                                    : contactDetails.contact.primary_driver ===
                                      "Partnerships"
                                    ? Colors.INDIGO3
                                    : contactDetails.contact.primary_driver ===
                                      "References"
                                    ? Colors.TURQUOISE3
                                    : contactDetails.contact.primary_driver ===
                                      "Relationships"
                                    ? Colors.ROSE3
                                    : contactDetails.contact.primary_driver ===
                                      "Solution"
                                    ? Colors.LIME3
                                    : contactDetails.contact.primary_driver ===
                                      "Team"
                                    ? Colors.CERULEAN3
                                    : Colors.BLUE3
                                ),
                                textAlign: "center",
                                minWidth: "100px",
                              }}
                            >
                              {contactDetails.contact.primary_driver}
                            </Tag>
                          }
                        />

                        {/* Secondary driver */}
                        <TabSectionField
                          label="Secondary driver"
                          loading={contactDetails.loading}
                          value={
                            <Tag
                              icon={
                                contactDetails.contact.secondary_driver ===
                                "Commercials"
                                  ? "manually-entered-data"
                                  : contactDetails.contact.secondary_driver ===
                                    "Partnerships"
                                  ? "new-link"
                                  : contactDetails.contact.secondary_driver ===
                                    "References"
                                  ? "chat"
                                  : contactDetails.contact.secondary_driver ===
                                    "Relationships"
                                  ? "new-person"
                                  : contactDetails.contact.secondary_driver ===
                                    "Solution"
                                  ? "box"
                                  : contactDetails.contact.secondary_driver ===
                                    "Team"
                                  ? "people"
                                  : "more"
                              }
                              large
                              round
                              style={{
                                background:
                                  contactDetails.contact.secondary_driver ===
                                  "Commercials"
                                    ? Colors.GOLD3
                                    : contactDetails.contact
                                        .secondary_driver === "Partnerships"
                                    ? Colors.INDIGO3
                                    : contactDetails.contact
                                        .secondary_driver === "References"
                                    ? Colors.TURQUOISE3
                                    : contactDetails.contact
                                        .secondary_driver === "Relationships"
                                    ? Colors.ROSE3
                                    : contactDetails.contact
                                        .secondary_driver === "Solution"
                                    ? Colors.LIME3
                                    : contactDetails.contact
                                        .secondary_driver === "Team"
                                    ? Colors.CERULEAN3
                                    : Colors.BLUE3,
                                color: setTextColour(
                                  contactDetails.contact.secondary_driver ===
                                    "Commercials"
                                    ? Colors.GOLD3
                                    : contactDetails.contact
                                        .secondary_driver === "Partnerships"
                                    ? Colors.INDIGO3
                                    : contactDetails.contact
                                        .secondary_driver === "References"
                                    ? Colors.TURQUOISE3
                                    : contactDetails.contact
                                        .secondary_driver === "Relationships"
                                    ? Colors.ROSE3
                                    : contactDetails.contact
                                        .secondary_driver === "Solution"
                                    ? Colors.LIME3
                                    : contactDetails.contact
                                        .secondary_driver === "Team"
                                    ? Colors.CERULEAN3
                                    : Colors.BLUE3
                                ),
                                textAlign: "center",
                                minWidth: "100px",
                              }}
                            >
                              {contactDetails.contact.secondary_driver}
                            </Tag>
                          }
                        />

                        {/* Attitude toward us */}
                        <TabSectionField
                          bottom
                          helperText={
                            contactDetails.contact.attitude_toward_us === 5
                              ? "Very positive"
                              : contactDetails.contact.attitude_toward_us === 4
                              ? "Positive"
                              : contactDetails.contact.attitude_toward_us === 3
                              ? "Neutral"
                              : contactDetails.contact.attitude_toward_us === 2
                              ? "Negative"
                              : "Very negative"
                          }
                          label="Attitude toward us"
                          value={
                            <TabSectionFieldProgressBar
                              rating={
                                contactDetails.contact.attitude_toward_us / 5
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Analyse variables that shape the contact's buying behaviour"
                    icon="user"
                    title="Buyer profile"
                  />

                  {/* Proposals */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Pipeline */}
                              <ProposalPipelineCard
                                fill
                                proposalList={proposalList}
                                showInsights={false}
                              />

                              {/* Won-lost */}
                              <ProposalWinLossCard
                                proposalList={proposalList}
                                proposalQuery={proposalQuery}
                                showInsights={false}
                              />

                              {/* Average value */}
                              <ProposalAverageValueCard
                                fill
                                proposalList={proposalList}
                                showInsights={false}
                              />

                              {/* Total value */}
                              <ProposalTotalValueCard
                                proposalList={proposalList}
                                proposalQuery={proposalQuery}
                                showInsights={false}
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <TableContainer2
                          body={proposalList.proposals.map((proposal) => {
                            return (
                              <tr
                                key={proposal.id}
                                onClick={() => {
                                  if (proposal.user_has_permission) {
                                    dispatch(resetListProposalDetailsAction());

                                    navigate(`/proposals/${proposal.id}`);
                                  } else {
                                    AppToaster.show({
                                      icon: "warning-sign",
                                      intent: "danger",
                                      message:
                                        "You do not have access to this proposal.",
                                    });
                                  }
                                }}
                              >
                                {/* Proposal */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <TableText text={proposal.name} />

                                    <div>
                                      {!proposal.user_has_permission ? (
                                        <Tag
                                          intent="danger"
                                          minimal
                                          style={{ margin: "0 0 0 5px" }}
                                        >
                                          No access
                                        </Tag>
                                      ) : proposal.user_access_status ===
                                        "view" ? (
                                        <Tag
                                          intent="primary"
                                          minimal
                                          style={{ margin: "0 0 0 5px" }}
                                        >
                                          View only
                                        </Tag>
                                      ) : undefined}
                                    </div>
                                  </div>
                                </td>

                                {/* Client */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    image={proposal.client_logo}
                                    logo
                                    showImage
                                    text={proposal.client_name}
                                    url={`/clients/${proposal.client}`}
                                  />
                                </td>

                                {/* Value */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableNumber number={proposal.value} />
                                </td>

                                {/* Manager */}
                                <td style={{ verticalAlign: "middle" }}>
                                  <TableText
                                    email={proposal.manager_name}
                                    image={proposal.manager_profile_photo}
                                    showImage
                                  />
                                </td>

                                {/* Due */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableDate date={proposal.due} />
                                </td>

                                {/* Status */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <TableTag
                                    intent={
                                      proposal.status === "Won"
                                        ? "success"
                                        : proposal.status === "Lost"
                                        ? "danger"
                                        : proposal.status === "Cancelled"
                                        ? "warning"
                                        : "primary"
                                    }
                                    value={proposal.status}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                          clearFiltersDisabled={
                            !proposalQuery.client &&
                            !proposalQuery.dueEnd &&
                            !proposalQuery.dueStart &&
                            !proposalQuery.manager &&
                            !proposalQuery.searchString &&
                            !proposalQuery.status
                          }
                          count={proposalList.count}
                          head={
                            <tr>
                              {/* Proposal */}
                              <TableColumnHeadSearch
                                queryObject={proposalQuery}
                                queryVariable="searchString"
                                setQueryObject={setProposalQuery}
                                setQueryVariable="searchString"
                                title="Proposal"
                                width="200px"
                              />

                              {/* Client */}
                              <TableColumnHeadSelect2
                                filterObject={proposalQuery}
                                filterVariable="client"
                                items={clientList.clients}
                                setFilterObject={setProposalQuery}
                                setQueryObject={setClientQuery}
                                setQueryVariable="searchString"
                                title="Client"
                                width="200px"
                              />

                              {/* Value */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalQuery}
                                filterVariable="sortField"
                                noActive
                                options={[
                                  {
                                    display: "Sort Ascending",
                                    icon: "sort-asc",
                                    value: "value",
                                  },
                                  {
                                    display: "Sort Descending",
                                    icon: "sort-desc",
                                    value: "-value",
                                  },
                                ]}
                                setFilterObject={setProposalQuery}
                                title="Value"
                                width="125px"
                              />

                              {/* Manager */}
                              <TableColumnHeadSelect2
                                filterObject={proposalQuery}
                                filterVariable="manager"
                                items={managerList.managers}
                                setFilterObject={setProposalQuery}
                                setQueryObject={setManagerQuery}
                                setQueryVariable="searchString"
                                title="Manager"
                                width="200px"
                              />

                              {/* Due */}
                              <TableColumnHeadDate
                                endDateVariable="dueEnd"
                                queryObject={proposalQuery}
                                setQueryObject={setProposalQuery}
                                shortRange
                                startDateVariable="dueStart"
                                title="Due"
                                width="125px"
                              />

                              {/* Status */}
                              <TableColumnHeadSelectSimple
                                filterObject={proposalQuery}
                                filterVariable="status"
                                options={[
                                  {
                                    display: "All",
                                    icon: "filter-remove",
                                    value: "",
                                  },
                                  {
                                    display: "Qualifying",
                                    icon: "search-text",
                                    value: "Qualifying",
                                  },
                                  {
                                    display: "In-progress",
                                    icon: "manually-entered-data",
                                    value: "In-progress",
                                  },
                                  {
                                    display: "Submitted",
                                    icon: "envelope",
                                    value: "Submitted",
                                  },
                                  {
                                    display: "Won",
                                    icon: "tick-circle",
                                    value: "Won",
                                  },
                                  {
                                    display: "Lost",
                                    icon: "error",
                                    value: "Lost",
                                  },
                                  {
                                    display: "Cancelled",
                                    icon: "undo",
                                    value: "Cancelled",
                                  },
                                ]}
                                setFilterObject={setProposalQuery}
                                title="Status"
                                width="125px"
                              />
                            </tr>
                          }
                          interactive
                          loading={proposalList.loading}
                          noMargin
                          onClearFilters={() => {
                            setProposalQuery({
                              ...proposalQueryDefault,
                            });
                          }}
                          page={proposalQuery.page}
                          setPageObject={setProposalQuery}
                          setPageVariable="page"
                        />
                      </>
                    }
                    help="Review proposals where this person is the primary contact"
                    icon="manual"
                    title="Proposals"
                  />

                  {/* Engagements */}
                  <TabSection2
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Plan at completion */}
                              <EngagementPlanAtCompletionCard
                                fill
                                engagementList={engagementList}
                              />

                              {/* Actuals */}
                              <EngagementActualsCard
                                fill
                                engagementList={engagementList}
                              />
                            </>
                          }
                          fill
                          tableTop
                        />

                        {/* Data table */}
                        <div
                          style={{
                            margin: "0 auto 0 auto",
                            maxWidth: "1600px",
                          }}
                        >
                          <TableContainer2
                            body={engagementList.engagements.map(
                              (engagement) => {
                                return (
                                  <tr
                                    key={engagement.id}
                                    onClick={() => {
                                      if (engagement.user_has_permission) {
                                        dispatch(
                                          resetListEngagementDetailsAction()
                                        );

                                        navigate(
                                          `/engagements/${engagement.id}`
                                        );
                                      } else {
                                        AppToaster.show({
                                          icon: "warning-sign",
                                          intent: "danger",
                                          message:
                                            "You do not have access to this engagement.",
                                        });
                                      }
                                    }}
                                  >
                                    {/* Engagement */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        tagIntent={
                                          !engagement.user_has_permission
                                            ? "danger"
                                            : engagement.user_access_status ===
                                              "view"
                                            ? "primary"
                                            : undefined
                                        }
                                        tagText={
                                          !engagement.user_has_permission
                                            ? "No access"
                                            : engagement.user_access_status ===
                                              "view"
                                            ? "View only"
                                            : undefined
                                        }
                                        text={engagement.name}
                                      />
                                    </td>

                                    {/* Client */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        image={engagement.client_logo}
                                        logo
                                        showImage
                                        text={engagement.client_name}
                                        url={`/clients/${engagement.client}`}
                                      />
                                    </td>

                                    {/* Value */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableNumber number={engagement.value} />
                                    </td>

                                    {/* Manager */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={engagement.manager_name}
                                        image={engagement.manager_profile_photo}
                                        showImage
                                      />
                                    </td>

                                    {/* Status */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableTag
                                        intent={
                                          engagement.status === "Amber"
                                            ? "warning"
                                            : engagement.status === "Green"
                                            ? "success"
                                            : engagement.status === "Red"
                                            ? "danger"
                                            : "primary"
                                        }
                                        value={engagement.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !engagementQuery.client &&
                              !engagementQuery.manager &&
                              !engagementQuery.searchString &&
                              !engagementQuery.sponsor
                            }
                            count={engagementList.count}
                            footnote={
                              <div>
                                Figures in {activeOrganisation.currency}
                              </div>
                            }
                            head={
                              <tr>
                                {/* Engagement */}
                                <TableColumnHeadSearch
                                  queryObject={engagementQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setEngagementQuery}
                                  setQueryVariable="searchString"
                                  title="Engagement"
                                  width="200px"
                                />

                                {/* Client */}
                                <TableColumnHeadSelect2
                                  filterObject={engagementQuery}
                                  filterVariable="client"
                                  items={clientList.clients}
                                  setFilterObject={setEngagementQuery}
                                  setQueryObject={setClientQuery}
                                  setQueryVariable="searchString"
                                  title="Client"
                                  width="200px"
                                />

                                {/* Value */}
                                <TableColumnHeadSelectSimple
                                  filterObject={engagementQuery}
                                  filterVariable="sortField"
                                  noActive
                                  options={[
                                    {
                                      display: "Sort Ascending",
                                      icon: "sort-asc",
                                      value: "value",
                                    },
                                    {
                                      display: "Sort Descending",
                                      icon: "sort-desc",
                                      value: "-value",
                                    },
                                  ]}
                                  setFilterObject={setEngagementQuery}
                                  title="Value"
                                  width="125px"
                                />

                                {/* Manager */}
                                <TableColumnHeadSelect2
                                  filterObject={engagementQuery}
                                  filterVariable="manager"
                                  items={managerList.managers}
                                  setFilterObject={setEngagementQuery}
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Manager"
                                  width="200px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={engagementQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Planning",
                                      icon: "playbook",
                                      value: "Planning",
                                    },
                                    {
                                      display: "Green",
                                      icon: "repeat",
                                      value: "Green",
                                    },
                                    {
                                      display: "Amber",
                                      icon: "issue-new",
                                      value: "Amber",
                                    },
                                    {
                                      display: "Red",
                                      icon: "issue",
                                      value: "Red",
                                    },
                                    {
                                      display: "Closed",
                                      icon: "confirm",
                                      value: "Closed",
                                    },
                                  ]}
                                  setFilterObject={setEngagementQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive
                            loading={engagementList.loading}
                            noMargin
                            onClearFilters={() => {
                              setEngagementQuery({
                                ...engagementQueryDefault,
                              });
                            }}
                            page={engagementQuery.page}
                            setPageObject={setEngagementQuery}
                            setPageVariable="page"
                          />
                        </div>
                      </>
                    }
                    help="Review engagements where this contact is the sponsor"
                    icon="gantt-chart"
                    title="Engagements"
                  />

                  {/* Opening applications */}
                  <TabSection2
                    content={
                      <>
                        {contactDetails.loading ? (
                          <div
                            className={Classes.SKELETON}
                            style={{
                              width: "100%",
                            }}
                          >
                            XXXXXXXXXX
                          </div>
                        ) : contactDetails.contact.opening_applications ? (
                          contactDetails.contact.opening_applications.length ===
                          0 ? (
                            <div style={{ margin: "60px 0 60px 0" }}>
                              <NonIdealState
                                icon="search"
                                title="No applications found"
                              />
                            </div>
                          ) : (
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {contactDetails.contact.opening_applications.map(
                                (application) => {
                                  return (
                                    <div
                                      key={application.opening}
                                      style={{ margin: "5px" }}
                                    >
                                      <TagPopup
                                        backgroundColor={
                                          lightMode
                                            ? Colors.INDIGO2
                                            : Colors.INDIGO5
                                        }
                                        content={
                                          <ContactApplicationInfo
                                            application={application}
                                          />
                                        }
                                        icon="inherited-group"
                                        text={application.opening_name}
                                      />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )
                        ) : undefined}
                      </>
                    }
                    help="Review recent job applications from this contact"
                    icon="shared-filter"
                    title="Opening applications"
                  />
                </div>
              }
            />

            {/* Management */}
            <Tab
              id="contact-management-tab"
              title="Management"
              panel={
                <div>
                  {/* Relationship management actions */}
                  <TabSection2
                    buttons={
                      <Button
                        disabled={
                          contactDetails.contact.user_access_status === "view"
                        }
                        icon="add"
                        intent="primary"
                        onClick={() => {
                          setAddActionDrawerIsOpen(true);
                        }}
                        text="Add"
                      />
                    }
                    content={
                      <>
                        {/* Data cards */}
                        <DataCardContainer
                          children={
                            <>
                              {/* Completed */}
                              <DataCardSimple
                                intent="success"
                                interactive={!showActionKanban}
                                label="Completed"
                                loading={contactActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setContactActionQuery({
                                      ...contactActionQuery,
                                      page: 1,
                                      status: "Completed",
                                    });
                                }}
                                value={
                                  contactActionList.numberOfActionsCompleted
                                }
                              />

                              {/* In-progress */}
                              <DataCardSimple
                                intent="warning"
                                interactive={!showActionKanban}
                                label="In-progress"
                                loading={contactActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setContactActionQuery({
                                      ...contactActionQuery,
                                      page: 1,
                                      status: "In-progress",
                                    });
                                }}
                                value={
                                  contactActionList.numberOfActionsInProgress
                                }
                              />

                              {/* On-hold */}
                              <DataCardSimple
                                intent="primary"
                                interactive={!showActionKanban}
                                label="On-hold"
                                loading={contactActionList.loading}
                                onClick={() => {
                                  !showActionKanban &&
                                    setContactActionQuery({
                                      ...contactActionQuery,
                                      page: 1,
                                      status: "On-hold",
                                    });
                                }}
                                value={contactActionList.numberOfActionsOnHold}
                              />
                            </>
                          }
                          tableTop
                        />

                        {/* Buttons */}
                        <ButtonGroup
                          style={{
                            background: lightMode
                              ? Colors.LIGHT_GRAY3
                              : Colors.DARK_GRAY5,
                            maxWidth: "100%",
                            minWidth: "100%",
                            padding: "10px 10px 0 10px",
                          }}
                        >
                          <Button
                            icon="th"
                            onClick={() => setShowActionKanban(false)}
                          />

                          <Button
                            icon="list-columns"
                            onClick={() => {
                              setContactActionQuery({
                                ...contactActionQueryDefault,
                              });

                              setInitialiseActionKanban(true);

                              setShowActionKanban(true);
                            }}
                          />
                        </ButtonGroup>

                        {/* Data table */}
                        {!showActionKanban && (
                          <TableContainer2
                            body={contactActionList.contact_actions.map(
                              (contact_action) => {
                                return (
                                  <tr
                                    key={contact_action.id}
                                    onClick={() => {
                                      if (
                                        contactDetails.contact
                                          .user_access_status !== "view"
                                      ) {
                                        dispatch(
                                          listContactActionDetailsAction(
                                            contact_action.id
                                          )
                                        );

                                        setUpdateActionDrawerIsOpen(true);
                                      }
                                    }}
                                  >
                                    {/* Action */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        info={
                                          <>
                                            <H5>{contact_action.name}</H5>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Action</strong>
                                            </p>

                                            <p>{contact_action.action}</p>

                                            <Divider
                                              style={{
                                                background: lightMode
                                                  ? Colors.GRAY1
                                                  : Colors.GRAY5,
                                                margin: "10px 0 10px 0",
                                              }}
                                            />

                                            <p>
                                              <strong>Comments</strong>
                                            </p>

                                            <p>
                                              {contact_action.comments ? (
                                                contact_action.comments
                                              ) : (
                                                <span
                                                  className={Classes.TEXT_MUTED}
                                                >
                                                  No comments.
                                                </span>
                                              )}
                                            </p>
                                          </>
                                        }
                                        text={contact_action.name}
                                      />
                                    </td>

                                    {/* Category */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableTag
                                        color={
                                          contact_action.category === "Delivery"
                                            ? Colors.INDIGO3
                                            : contact_action.category ===
                                              "Proposal"
                                            ? Colors.TURQUOISE3
                                            : contact_action.category ===
                                              "Reference"
                                            ? Colors.CERULEAN3
                                            : contact_action.category ===
                                              "Relationship"
                                            ? Colors.ROSE3
                                            : Colors.BLUE3
                                        }
                                        value={contact_action.category}
                                      />
                                    </td>

                                    {/* Owner */}
                                    <td style={{ verticalAlign: "middle" }}>
                                      <TableText
                                        email={contact_action.owner_name}
                                        image={
                                          contact_action.owner_profile_photo
                                        }
                                        showImage
                                      />
                                    </td>

                                    {/* Due */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableDate date={contact_action.due} />
                                    </td>

                                    {/* Status */}
                                    <td
                                      style={{
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      <TableTag
                                        intent={
                                          contact_action.status === "Completed"
                                            ? "success"
                                            : contact_action.status ===
                                              "In-progress"
                                            ? "warning"
                                            : "primary"
                                        }
                                        value={contact_action.status}
                                      />
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                            clearFiltersDisabled={
                              !contactActionQuery.category &&
                              !contactActionQuery.dueEnd &&
                              !contactActionQuery.dueStart &&
                              !contactActionQuery.owner &&
                              !contactActionQuery.searchString &&
                              !contactActionQuery.status
                            }
                            count={contactActionList.count}
                            head={
                              <tr>
                                {/* Action */}
                                <TableColumnHeadSearch
                                  queryObject={contactActionQuery}
                                  queryVariable="searchString"
                                  setQueryObject={setContactActionQuery}
                                  setQueryVariable="searchString"
                                  title="Action"
                                  width="200px"
                                />

                                {/* Category */}
                                <TableColumnHeadSelectSimple
                                  filterObject={contactActionQuery}
                                  filterVariable="category"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Delivery",
                                      icon: "gantt-chart",
                                      value: "Delivery",
                                    },
                                    {
                                      display: "Proposal",
                                      icon: "manual",
                                      value: "Proposal",
                                    },
                                    {
                                      display: "Reference",
                                      icon: "chat",
                                      value: "Reference",
                                    },
                                    {
                                      display: "Relationship",
                                      icon: "one-to-one",
                                      value: "Relationship",
                                    },
                                    {
                                      display: "Other",
                                      icon: "more",
                                      value: "Other",
                                    },
                                  ]}
                                  setFilterObject={setContactActionQuery}
                                  title="Category"
                                  width="125px"
                                />

                                {/* Owner */}
                                <TableColumnHeadSelect2
                                  filterObject={contactActionQuery}
                                  filterVariable="owner"
                                  items={managerList.managers}
                                  setFilterObject={setContactActionQuery}
                                  setQueryObject={setManagerQuery}
                                  setQueryVariable="searchString"
                                  title="Owner"
                                  width="200px"
                                />

                                {/* Due */}
                                <TableColumnHeadDate
                                  endDateVariable="dueEnd"
                                  queryObject={contactActionQuery}
                                  setQueryObject={setContactActionQuery}
                                  shortRange
                                  startDateVariable="dueStart"
                                  title="Due"
                                  width="125px"
                                />

                                {/* Status */}
                                <TableColumnHeadSelectSimple
                                  filterObject={contactActionQuery}
                                  filterVariable="status"
                                  options={[
                                    {
                                      display: "All",
                                      icon: "filter-remove",
                                      value: "",
                                    },
                                    {
                                      display: "Completed",
                                      icon: "confirm",
                                      value: "Completed",
                                    },
                                    {
                                      display: "In-progress",
                                      icon: "refresh",
                                      value: "In-progress",
                                    },
                                    {
                                      display: "On-hold",
                                      icon: "remove",
                                      value: "On-hold",
                                    },
                                  ]}
                                  setFilterObject={setContactActionQuery}
                                  title="Status"
                                  width="125px"
                                />
                              </tr>
                            }
                            interactive={
                              contactDetails.contact.user_access_status !==
                              "view"
                            }
                            loading={contactActionList.loading}
                            noMargin
                            onClearFilters={() => {
                              setContactActionQuery({
                                ...contactActionQueryDefault,
                              });
                            }}
                            page={contactActionQuery.page}
                            setPageObject={setContactActionQuery}
                            setPageVariable="page"
                          />
                        )}

                        {/* Kanban */}
                        {showActionKanban && (
                          <Kanban
                            centerColumns
                            data={generateKanbanDataFromContactActions(
                              contactDetails.contact.actions
                            )}
                            initialise={initialiseActionKanban}
                            noMargin
                            onClick={(id) => {
                              if (
                                contactDetails.contact.user_access_status !==
                                "view"
                              ) {
                                dispatch(listContactActionDetailsAction(id));

                                setUpdateActionDrawerIsOpen(true);
                              }
                            }}
                            onDragEnd={(result) => {
                              const actionId = result.draggableId;
                              const actionStatus =
                                result.destination.droppableId;

                              updateActionStatus(actionId, actionStatus);
                            }}
                            searchBarMargin="-45px 0 20px 70px"
                            searchBarPlaceholder="Search actions"
                            setInitialise={setInitialiseActionKanban}
                            statusCategories={[
                              {
                                intent: "primary",
                                value: "On-hold",
                              },
                              {
                                intent: "warning",
                                value: "In-progress",
                              },
                              {
                                intent: "success",
                                value: "Completed",
                              },
                            ]}
                          />
                        )}
                      </>
                    }
                    help="Assign and review actions to manage the contact"
                    icon="take-action"
                    title="Relationship management actions"
                  />

                  {/* Communication plan */}
                  <TabSection2
                    content={
                      <>
                        {/* Data card */}
                        <div style={{ marginBottom: "30px" }}>
                          <DataCardContainer
                            children={
                              <>
                                {/* Planned communications */}
                                <CommunicationPlanStatsCard
                                  contactData={contactDetails.contact}
                                  fill
                                />
                              </>
                            }
                            fill
                            tableTop
                          />
                        </div>

                        {/* In-person */}
                        <TabSectionField
                          label={
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Icon
                                color={
                                  lightMode
                                    ? Colors.LIGHT_GRAY3
                                    : Colors.DARK_GRAY5
                                }
                                icon="people"
                                size={82}
                                style={{ marginBottom: "5px" }}
                              />

                              <H5>In-person</H5>
                            </div>
                          }
                          value={
                            <TabSectionFieldMultifieldCard
                              children={
                                <>
                                  {/* Frequency */}
                                  <TabSectionField
                                    label="Frequency"
                                    loading={contactDetails.loading}
                                    value={
                                      <HTMLSelect
                                        id="inPersonFreqField"
                                        onChange={(event) => {
                                          setContact((prevState) => {
                                            return {
                                              ...prevState,
                                              inPersonFreq:
                                                event.currentTarget.value,
                                            };
                                          });

                                          setUpdateCommPlan(true);
                                        }}
                                        options={freqOptions}
                                        value={contact.inPersonFreq}
                                      />
                                    }
                                  />

                                  {/* Last event */}
                                  <TabSectionField
                                    helperText={
                                      contactDetails.contact
                                        .in_person_last_event && (
                                        <Button
                                          intent="primary"
                                          onClick={() => {
                                            dispatch(
                                              listContactActionDetailsAction(
                                                contactDetails.contact
                                                  .in_person_last_event
                                              )
                                            );

                                            setUpdateActionDrawerIsOpen(true);
                                          }}
                                          small
                                          style={{ width: "50px" }}
                                          text="View"
                                        />
                                      )
                                    }
                                    label="Last event"
                                    loading={contactDetails.loading}
                                    value={
                                      <DateValue
                                        date={
                                          contactDetails.contact
                                            .in_person_last_event_date
                                        }
                                      />
                                    }
                                  />

                                  {/* Next event */}
                                  <TabSectionField
                                    bottom
                                    helperText={
                                      <CreateCommunicationActionButton
                                        channel="In-person"
                                        contactData={contactDetails.contact}
                                        openActionDrawer={() => {
                                          setUpdateActionDrawerIsOpen(true);
                                        }}
                                      />
                                    }
                                    label="Next event"
                                    loading={contactDetails.loading}
                                    subLabel={
                                      contactDetails.contact
                                        .in_person_next_event_date
                                        ? "Planned"
                                        : "Due"
                                    }
                                    value={
                                      <DateValue
                                        date={
                                          contactDetails.contact
                                            .in_person_next_event_date
                                            ? contactDetails.contact
                                                .in_person_next_event_date
                                            : contactDetails.contact
                                                .in_person_next_event_due
                                        }
                                      />
                                    }
                                  />
                                </>
                              }
                            />
                          }
                        />

                        {/* Phone */}
                        <TabSectionField
                          label={
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Icon
                                color={
                                  lightMode
                                    ? Colors.LIGHT_GRAY3
                                    : Colors.DARK_GRAY5
                                }
                                icon="phone"
                                size={82}
                                style={{ marginBottom: "10px" }}
                              />

                              <H5>Phone</H5>
                            </div>
                          }
                          value={
                            <TabSectionFieldMultifieldCard
                              children={
                                <>
                                  {/* Frequency */}
                                  <TabSectionField
                                    label="Frequency"
                                    loading={contactDetails.loading}
                                    value={
                                      <HTMLSelect
                                        id="phoneFreqField"
                                        onChange={(event) => {
                                          setContact((prevState) => {
                                            return {
                                              ...prevState,
                                              phoneFreq:
                                                event.currentTarget.value,
                                            };
                                          });

                                          setUpdateCommPlan(true);
                                        }}
                                        options={freqOptions}
                                        value={contact.phoneFreq}
                                      />
                                    }
                                  />

                                  {/* Last event */}
                                  <TabSectionField
                                    helperText={
                                      contactDetails.contact
                                        .phone_last_event && (
                                        <Button
                                          intent="primary"
                                          onClick={() => {
                                            dispatch(
                                              listContactActionDetailsAction(
                                                contactDetails.contact
                                                  .phone_last_event
                                              )
                                            );

                                            setUpdateActionDrawerIsOpen(true);
                                          }}
                                          small
                                          style={{ width: "50px" }}
                                          text="View"
                                        />
                                      )
                                    }
                                    label="Last event"
                                    loading={contactDetails.loading}
                                    value={
                                      <DateValue
                                        date={
                                          contactDetails.contact
                                            .phone_last_event_date
                                        }
                                      />
                                    }
                                  />

                                  {/* Next event */}
                                  <TabSectionField
                                    bottom
                                    helperText={
                                      <CreateCommunicationActionButton
                                        channel="Phone"
                                        contactData={contactDetails.contact}
                                        openActionDrawer={() => {
                                          setUpdateActionDrawerIsOpen(true);
                                        }}
                                      />
                                    }
                                    label="Next event"
                                    loading={contactDetails.loading}
                                    subLabel={
                                      contactDetails.contact
                                        .phone_next_event_date
                                        ? "Planned"
                                        : "Due"
                                    }
                                    value={
                                      <DateValue
                                        date={
                                          contactDetails.contact
                                            .phone_next_event_date
                                            ? contactDetails.contact
                                                .phone_next_event_date
                                            : contactDetails.contact
                                                .phone_next_event_due
                                        }
                                      />
                                    }
                                  />
                                </>
                              }
                            />
                          }
                        />

                        {/* Email */}
                        <TabSectionField
                          bottom
                          label={
                            <div
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <Icon
                                color={
                                  lightMode
                                    ? Colors.LIGHT_GRAY3
                                    : Colors.DARK_GRAY5
                                }
                                icon="envelope"
                                size={82}
                                style={{ marginBottom: "5px" }}
                              />

                              <H5>Email</H5>
                            </div>
                          }
                          value={
                            <TabSectionFieldMultifieldCard
                              children={
                                <>
                                  {/* Frequency */}
                                  <TabSectionField
                                    label="Frequency"
                                    loading={contactDetails.loading}
                                    value={
                                      <HTMLSelect
                                        id="emailFreqField"
                                        onChange={(event) => {
                                          setContact((prevState) => {
                                            return {
                                              ...prevState,
                                              emailFreq:
                                                event.currentTarget.value,
                                            };
                                          });

                                          setUpdateCommPlan(true);
                                        }}
                                        options={freqOptions}
                                        value={contact.emailFreq}
                                      />
                                    }
                                  />

                                  {/* Last event */}
                                  <TabSectionField
                                    helperText={
                                      contactDetails.contact
                                        .email_last_event && (
                                        <Button
                                          intent="primary"
                                          onClick={() => {
                                            dispatch(
                                              listContactActionDetailsAction(
                                                contactDetails.contact
                                                  .email_last_event
                                              )
                                            );

                                            setUpdateActionDrawerIsOpen(true);
                                          }}
                                          small
                                          style={{ width: "50px" }}
                                          text="View"
                                        />
                                      )
                                    }
                                    label="Last event"
                                    loading={contactDetails.loading}
                                    value={
                                      <DateValue
                                        date={
                                          contactDetails.contact
                                            .email_last_event_date
                                        }
                                      />
                                    }
                                  />

                                  {/* Next event */}
                                  <TabSectionField
                                    bottom
                                    helperText={
                                      <CreateCommunicationActionButton
                                        channel="Email"
                                        contactData={contactDetails.contact}
                                        openActionDrawer={() => {
                                          setUpdateActionDrawerIsOpen(true);
                                        }}
                                      />
                                    }
                                    label="Next event"
                                    loading={contactDetails.loading}
                                    subLabel={
                                      contactDetails.contact
                                        .email_next_event_date
                                        ? "Planned"
                                        : "Due"
                                    }
                                    value={
                                      <DateValue
                                        date={
                                          contactDetails.contact
                                            .email_next_event_date
                                            ? contactDetails.contact
                                                .email_next_event_date
                                            : contactDetails.contact
                                                .email_next_event_due
                                        }
                                      />
                                    }
                                  />
                                </>
                              }
                            />
                          }
                        />
                      </>
                    }
                    help="Schedule regular touchpoints with the contact"
                    icon="chat"
                    title="Communication plan"
                  />
                </div>
              }
            />
          </Tabs>
        }
      />
    </div>
  );
}

export default ContactDetailScreen;
