// Constant imports
import {
  OPENING_APPLICATION_CONFIGURATION_DETAILS_REQUEST,
  OPENING_APPLICATION_CONFIGURATION_DETAILS_SUCCESS,
  OPENING_APPLICATION_CONFIGURATION_DETAILS_FAIL,
  OPENING_APPLICATION_CONFIGURATION_DETAILS_RESET,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_REQUEST,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_SUCCESS,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_FAIL,
  OPENING_APPLICATION_CONFIGURATION_UPDATE_RESET,
  OPENING_APPLICATION_CONFIGURATION_APPLY_REQUEST,
  OPENING_APPLICATION_CONFIGURATION_APPLY_SUCCESS,
  OPENING_APPLICATION_CONFIGURATION_APPLY_FAIL,
  OPENING_APPLICATION_CONFIGURATION_APPLY_RESET,
} from "../constants/openingApplicationConfigurationConstants";

// List details
export const openingApplicationConfigurationDetailsReducer = (
  state = {
    loading: false,
    opening_application_configuration: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_APPLICATION_CONFIGURATION_DETAILS_REQUEST:
      return {
        loading: true,
        opening_application_configuration:
          state.opening_application_configuration,
        success: false,
        error: "",
      };

    case OPENING_APPLICATION_CONFIGURATION_DETAILS_SUCCESS:
      return {
        loading: false,
        opening_application_configuration: action.payload,
        success: true,
        error: "",
      };

    case OPENING_APPLICATION_CONFIGURATION_DETAILS_FAIL:
      return {
        loading: false,
        opening_application_configuration:
          state.opening_application_configuration,
        success: false,
        error: action.payload,
      };

    case OPENING_APPLICATION_CONFIGURATION_DETAILS_RESET:
      return {
        loading: false,
        opening_application_configuration: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const openingApplicationConfigurationUpdateReducer = (
  state = {
    loading: false,
    opening_application_configuration: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_APPLICATION_CONFIGURATION_UPDATE_REQUEST:
      return {
        loading: true,
        opening_application_configuration:
          state.opening_application_configuration,
        success: false,
        error: "",
      };

    case OPENING_APPLICATION_CONFIGURATION_UPDATE_SUCCESS:
      return {
        loading: false,
        opening_application_configuration: action.payload,
        success: true,
        error: "",
      };

    case OPENING_APPLICATION_CONFIGURATION_UPDATE_FAIL:
      return {
        loading: false,
        opening_application_configuration:
          state.opening_application_configuration,
        success: false,
        error: action.payload,
      };

    case OPENING_APPLICATION_CONFIGURATION_UPDATE_RESET:
      return {
        loading: false,
        opening_application_configuration: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Apply
export const openingApplicationConfigurationApplyReducer = (
  state = {
    loading: false,
    opening_application_configuration: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case OPENING_APPLICATION_CONFIGURATION_APPLY_REQUEST:
      return {
        loading: true,
        opening_application_configuration:
          state.opening_application_configuration,
        success: false,
        error: "",
      };

    case OPENING_APPLICATION_CONFIGURATION_APPLY_SUCCESS:
      return {
        loading: false,
        opening_application_configuration: action.payload,
        success: true,
        error: "",
      };

    case OPENING_APPLICATION_CONFIGURATION_APPLY_FAIL:
      return {
        loading: false,
        opening_application_configuration:
          state.opening_application_configuration,
        success: false,
        error: action.payload,
      };

    case OPENING_APPLICATION_CONFIGURATION_APPLY_RESET:
      return {
        loading: false,
        opening_application_configuration: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
