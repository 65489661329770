// Library imports
import {
  Classes,
  Colors,
  Divider,
  H5,
  HTMLTable,
  Icon,
  Spinner,
  SpinnerSize,
  Tag,
  UL,
} from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import TableDate from "../components/TableDate";
import TableNumber from "../components/TableNumber";
import TableTag from "../components/TableTag";
import DataCardBarChart from "../components/DataCardBarChart";
import DataCardComplex from "../components/DataCardComplex";
import DataCardComplexIndicator from "../components/DataCardComplexIndicator";
import DataCardContainer from "../components/DataCardContainer";
import DataCardDonutChart from "../components/DataCardDonutChart";
import DataCardHorizontalBarChart from "../components/DataCardHorizontalBarChart";
import DataCardTwoBarChart from "../components/DataCardTwoBarChart";

// Main function
function EngagementStatusSummary({ data, loading }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  // JSX UI code
  return (
    <>
      {loading ? (
        <div style={{ margin: "auto 0 auto 0" }}>
          <Spinner size={SpinnerSize.LARGE} />
        </div>
      ) : (
        <div>
          <DataCardContainer
            children={
              <>
                {/* Plan at completion */}
                <DataCardTwoBarChart
                  comments={
                    <>
                      <H5>Plan at completion</H5>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Definition</strong>
                      </p>

                      <p>
                        Summary of the engagement's financial estimates,
                        including:
                      </p>

                      <UL>
                        <li>Fee: Total engagement revenue.</li>

                        <li>
                          Expenses: Total cost, including labour and non-labour
                          expenses.
                        </li>

                        <li>Margin: Total engagement profit margin.</li>
                      </UL>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Fee</strong>
                      </p>

                      <div style={{ marginBottom: "10px" }}>
                        {data.total_fees.rating === "green" ? (
                          <>
                            <Tag intent="success">On track</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The expected revenue is as or greater than
                              planned.
                            </div>
                          </>
                        ) : (
                          <>
                            <Tag intent="danger">Needs attention</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The estimated fee is lower than the baseline. You
                              should consider options to bill the full fee
                              without reducing profitability.
                            </div>
                          </>
                        )}
                      </div>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Expenses</strong>
                      </p>

                      <div style={{ marginBottom: "10px" }}>
                        {data.total_expenses.rating === "green" ? (
                          <>
                            <Tag intent="success">On track</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The expected cost at completion is as or better
                              than planned.
                            </div>
                          </>
                        ) : (
                          <>
                            <Tag intent="danger">Needs attention</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The estimated cost at completion exceeds the
                              agreed baseline. You need to reduce expenses or
                              review the budget.
                            </div>
                          </>
                        )}
                      </div>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Margin</strong>
                      </p>

                      <div style={{ marginBottom: "10px" }}>
                        {data.total_margin.rating === "green" ? (
                          <>
                            <Tag intent="success">On track</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The expected profit margin is as or better than
                              planned.
                            </div>
                          </>
                        ) : (
                          <>
                            <Tag intent="danger">Needs attention</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The profit forecast falls short of the plan. You
                              should try to negotiate a higher fee or reduce
                              expenses.
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  }
                  data={[
                    {
                      name: "Fee",
                      fill1: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
                      value1: data.total_fees.baseline,
                      value2: data.total_fees.current,
                    },
                    {
                      name: "Expenses",
                      fill1: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
                      value1: data.total_expenses.baseline,
                      value2: data.total_expenses.current,
                    },
                    {
                      name: "Margin",
                      fill1: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      fill2: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
                      value1: data.total_margin.baseline,
                      value2: data.total_margin.current,
                    },
                  ]}
                  fill
                  legendData={[
                    {
                      color: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      type: "square",
                      value: "Baseline",
                    },
                    {
                      color: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
                      type: "square",
                      value: "Current",
                    },
                  ]}
                  noData={
                    !data.total_fee &&
                    !data.total_expenses &&
                    !data.total_margin
                  }
                  title="Plan at completion"
                  units={activeOrganisation.currency}
                />

                {/* Planned profitability */}
                {data.total_margin.current >= 0 && (
                  <DataCardDonutChart
                    comments={
                      <>
                        <H5>Planned profitability</H5>

                        <Divider
                          style={{
                            background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                            margin: "10px 0 10px 0",
                          }}
                        />

                        <p>
                          <strong>Definition</strong>
                        </p>

                        <p>
                          The estimated margin and expenses as a percentage of
                          the total fee at completion.
                        </p>

                        <Divider
                          style={{
                            background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                            margin: "10px 0 10px 0",
                          }}
                        />

                        <p>
                          <strong>Insights</strong>
                        </p>

                        <div style={{ marginBottom: "10px" }}>
                          {data.average_margin_percentage.rating === "green" ? (
                            <>
                              <Tag intent="success">On track</Tag>

                              <div style={{ marginTop: "10px" }}>
                                The expected profit margin percentage is as or
                                better than planned.
                              </div>
                            </>
                          ) : (
                            <>
                              <Tag intent="danger">Needs attention</Tag>

                              <div style={{ marginTop: "10px" }}>
                                The gross margin forecast is below the expected
                                profit. You may need to raise the team's
                                productivity or negotiate a higher fee with the
                                client.
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    }
                    data={[
                      {
                        name: "Margin",
                        fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
                        value: data.total_margin.current,
                      },
                      {
                        name: "Expenses",
                        fill: lightMode ? Colors.RED3 : Colors.RED5,
                        value: data.total_expenses.current,
                      },
                    ]}
                    noData={!data.total_margin && !data.total_expenses}
                    showPercent
                    title="Planned profitability"
                    units="Percentage"
                  />
                )}
              </>
            }
            fill
            stacked
          />

          <DataCardContainer
            children={
              <>
                {/* Payment milestone status */}
                <DataCardDonutChart
                  comments={
                    <>
                      <H5>Payment milestone status</H5>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Definition</strong>
                      </p>

                      <p>
                        The value of payment milestones by status as a
                        percentage of the total value.
                      </p>
                    </>
                  }
                  data={[
                    {
                      name: "Paid",
                      fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
                      value: data.total_milestone_paid_value,
                    },
                    {
                      name: "Invoiced",
                      fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
                      value: data.total_milestone_invoiced_value,
                    },
                    {
                      name: "Completed",
                      fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      value: data.total_milestone_completed_value,
                    },
                    {
                      name: "Not completed",
                      fill: lightMode ? Colors.RED3 : Colors.RED5,
                      value: data.total_milestone_not_completed_value,
                    },
                  ]}
                  noData={
                    !data.total_milestone_completed_value &&
                    !data.total_milestone_invoiced_value &&
                    !data.total_milestone_not_completed_value &&
                    !data.total_milestone_paid_value
                  }
                  showPercent
                  title="Payment milestone status"
                  units="Percentage"
                />

                {/* Payment milestone value */}
                <DataCardHorizontalBarChart
                  comments={
                    <>
                      <H5>Payment milestone value</H5>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Definition</strong>
                      </p>

                      <p>Total value of the payment milestones identified.</p>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Insights</strong>
                      </p>

                      <div style={{ marginBottom: "10px" }}>
                        {data.total_milestone_value.rating === "green" ? (
                          <>
                            <Tag intent="success">On track</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The expected total milestone payment is as or
                              better than planned.
                            </div>
                          </>
                        ) : (
                          <>
                            <Tag intent="danger">Needs attention</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The milestone payment value is lower than the
                              baseline amount. You should consider options to
                              realise the full milestone payment.
                            </div>
                          </>
                        )}
                      </div>

                      {data.total_fees.current !==
                        data.total_milestone_value.current && (
                        <div
                          style={{
                            background: `${Colors.ORANGE3}33`,
                            color: lightMode ? Colors.ORANGE5 : Colors.ORANGE2,
                            display: "flex",
                            margin: "20px 0 10px 0",
                            padding: "15px",
                          }}
                        >
                          <Icon icon="warning-sign" />

                          <div style={{ marginLeft: "5px" }}>
                            The payment milestone value does not match the total
                            engagement fee. This may be correct if part of the
                            fee is not linked to a payment milestone.
                          </div>
                        </div>
                      )}

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Milestone status</strong>
                      </p>

                      {data.milestones.map((milestone, i) => {
                        return (
                          <div key={i}>
                            <div>{milestone.name}</div>

                            <HTMLTable
                              bordered
                              striped
                              style={{ width: "100%" }}
                            >
                              <tbody className={Classes.TEXT_SMALL}>
                                <tr>
                                  <td
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <strong>Due</strong>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        milestone.due.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {
                                      <TableDate
                                        date={milestone.due.baseline}
                                        fillTag
                                        small
                                      />
                                    }
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {
                                      <TableDate
                                        date={milestone.due.current}
                                        fillTag
                                        small
                                      />
                                    }
                                  </td>

                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <TableTag
                                      fillTag
                                      small
                                      value={`${milestone.due.variance} days`}
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <strong>Value</strong>
                                  </td>

                                  <td
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <Tag
                                      intent={
                                        milestone.value.rating === "green"
                                          ? "success"
                                          : "danger"
                                      }
                                    ></Tag>
                                  </td>

                                  <td
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {
                                      <TableNumber
                                        invertColor
                                        number={milestone.value.baseline}
                                      />
                                    }
                                  </td>

                                  <td
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {
                                      <TableNumber
                                        invertColor
                                        number={milestone.value.current}
                                      />
                                    }
                                  </td>

                                  <td
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {
                                      <TableNumber
                                        invertColor
                                        number={milestone.value.variance}
                                      />
                                    }
                                  </td>
                                </tr>
                              </tbody>

                              <thead className={Classes.TEXT_SMALL}>
                                <tr>
                                  <th></th>

                                  <th></th>

                                  <th
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                    }}
                                  >
                                    Baseline
                                  </th>

                                  <th
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                    }}
                                  >
                                    Current
                                  </th>

                                  <th
                                    style={{
                                      color: lightMode
                                        ? Colors.WHITE
                                        : Colors.BLACK,
                                    }}
                                  >
                                    Variance
                                  </th>
                                </tr>
                              </thead>
                            </HTMLTable>
                          </div>
                        );
                      })}

                      <p
                        className={[
                          Classes.TEXT_SMALL,
                          Classes.TEXT_MUTED,
                        ].join(" ")}
                        style={{ marginTop: "5px" }}
                      >
                        Figures in {activeOrganisation.currency}
                      </p>
                    </>
                  }
                  data={[
                    {
                      name: "Baseline",
                      fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      value: data.total_milestone_value.baseline,
                    },
                    {
                      name: "Current",
                      fill: lightMode ? Colors.INDIGO3 : Colors.INDIGO5,
                      value: data.total_milestone_value.current,
                    },
                  ]}
                  noData={!data.total_fee && !data.total_milestone_value}
                  title="Payment milestone value"
                  units={activeOrganisation.currency}
                />

                {/* Actuals */}
                <DataCardBarChart
                  comments={
                    <>
                      <H5>Actuals</H5>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Definition</strong>
                      </p>

                      <p>Engagement financials to-date, covering:</p>

                      <UL>
                        <li>Invoiced: Total value of invoices issued.</li>

                        <li>
                          Revenue: Revenue recognised to-date, based on actual
                          cost and planned margin.
                        </li>

                        <li>Expenses: Actual cost to-date.</li>

                        <li>Margin: Revenue less expenses to-date.</li>
                      </UL>

                      <Divider
                        style={{
                          background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                          margin: "10px 0 10px 0",
                        }}
                      />

                      <p>
                        <strong>Insights</strong>
                      </p>

                      <div style={{ marginBottom: "10px" }}>
                        {data.total_expenses_actual > data.total_revenue ? (
                          <>
                            <Tag intent="danger">Needs urgent attention</Tag>

                            <div style={{ marginTop: "10px" }}>
                              Actual expenses exceed revenue. You may need to
                              adjust the planned margin on one or more
                              resources.
                            </div>
                          </>
                        ) : 2 * data.total_wip > data.total_revenue ? (
                          <>
                            <Tag intent="danger">Needs attention</Tag>

                            <div style={{ marginTop: "10px" }}>
                              The total WIP is significantly higher than
                              revenue. Consider invoicing all or part of the fee
                              outstanding.
                            </div>
                          </>
                        ) : (
                          <>
                            <Tag intent="success">On track</Tag>

                            <div style={{ marginTop: "10px" }}>
                              No action needed.
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  }
                  data={[
                    {
                      name: "Invoiced",
                      fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      value: data.total_invoiced,
                    },
                    {
                      name: "Revenue",
                      fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      value: data.total_revenue,
                    },
                    {
                      name: "Expenses",
                      fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      value: data.total_expenses_actual,
                    },
                    {
                      name: "Margin",
                      fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
                      value: data.total_margin_actual,
                    },
                  ]}
                  fill
                  noData={!data.total_wip}
                  title="Actuals"
                  units={activeOrganisation.currency}
                />
              </>
            }
            fill
            noMargin
          />

          {false && (
            <DataCardContainer
              children={
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {/* Fee */}
                  <DataCardComplex
                    children={
                      <>
                        {/* Baseline */}
                        <DataCardComplexIndicator
                          label="Baseline"
                          value={data.total_fees.baseline}
                        />

                        <Divider />

                        {/* Current */}
                        <DataCardComplexIndicator
                          intent={
                            data.total_fees.rating === "green"
                              ? "success"
                              : "danger"
                          }
                          label="Current"
                          value={data.total_fees.current}
                        />

                        <Divider />

                        {/* Variance */}
                        <DataCardComplexIndicator
                          label="Variance"
                          value={data.total_fees.variance}
                        />
                      </>
                    }
                    fill
                    title="Fee"
                  />

                  {/* Expenses */}
                  <DataCardComplex
                    children={
                      <>
                        {/* Baseline */}
                        <DataCardComplexIndicator
                          label="Baseline"
                          value={data.total_expenses.baseline}
                        />

                        <Divider />

                        {/* Current */}
                        <DataCardComplexIndicator
                          intent={
                            data.total_expenses.rating === "green"
                              ? "success"
                              : "danger"
                          }
                          label="Current"
                          value={data.total_expenses.current}
                        />

                        <Divider />

                        {/* Variance */}
                        <DataCardComplexIndicator
                          label="Variance"
                          value={data.total_expenses.variance}
                        />
                      </>
                    }
                    fill
                    title="Expenses"
                  />

                  {/* Margin */}
                  <DataCardComplex
                    children={
                      <>
                        {/* Baseline */}
                        <DataCardComplexIndicator
                          label="Baseline"
                          value={data.total_margin.baseline}
                        />

                        <Divider />

                        {/* Current */}
                        <DataCardComplexIndicator
                          intent={
                            data.total_margin.rating === "green"
                              ? "success"
                              : "danger"
                          }
                          label="Current"
                          value={data.total_margin.current}
                        />

                        <Divider />

                        {/* Variance */}
                        <DataCardComplexIndicator
                          label="Variance"
                          value={data.total_margin.variance}
                        />
                      </>
                    }
                    fill
                    title="Margin"
                  />
                </div>
              }
              fill
              noMargin
            />
          )}
        </div>
      )}
    </>
  );
}

export default EngagementStatusSummary;
