// Library imports
import React from "react";

// Main function
function DateValue({ date }) {
  // JSX UI code
  return (
    <div>
      {date
        ? new Date(date).toLocaleString("en-US", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })
        : "-"}
    </div>
  );
}

export default DateValue;
