// Constant imports
import {
  NEED_QUESTION_LIST_REQUEST,
  NEED_QUESTION_LIST_SUCCESS,
  NEED_QUESTION_LIST_FAIL,
  NEED_QUESTION_LIST_RESET,
  NEED_QUESTION_CREATE_REQUEST,
  NEED_QUESTION_CREATE_SUCCESS,
  NEED_QUESTION_CREATE_FAIL,
  NEED_QUESTION_CREATE_RESET,
  NEED_QUESTION_DETAILS_REQUEST,
  NEED_QUESTION_DETAILS_SUCCESS,
  NEED_QUESTION_DETAILS_FAIL,
  NEED_QUESTION_DETAILS_RESET,
  NEED_QUESTION_UPDATE_REQUEST,
  NEED_QUESTION_UPDATE_SUCCESS,
  NEED_QUESTION_UPDATE_FAIL,
  NEED_QUESTION_UPDATE_RESET,
  NEED_QUESTION_DELETE_REQUEST,
  NEED_QUESTION_DELETE_SUCCESS,
  NEED_QUESTION_DELETE_FAIL,
  NEED_QUESTION_DELETE_RESET,
  NEED_QUESTION_UPLOAD_BEGIN,
  NEED_QUESTION_UPLOAD_REQUEST,
  NEED_QUESTION_UPLOAD_SUCCESS,
  NEED_QUESTION_UPLOAD_FAIL,
  NEED_QUESTION_UPLOAD_END,
  NEED_QUESTION_UPLOAD_RESET,
} from "../constants/needQuestionConstants";

// List all
export const needQuestionListReducer = (
  state = {
    loading: false,
    count: 0,
    need_questions: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NEED_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NEED_QUESTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        need_questions: action.payload.need_questions,
        success: true,
        error: "",
      };

    case NEED_QUESTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        need_questions: [],
        success: false,
        error: action.payload,
      };

    case NEED_QUESTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        need_questions: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const needQuestionCreateReducer = (
  state = {
    loading: false,
    need_question: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NEED_QUESTION_CREATE_REQUEST:
      return {
        loading: true,
        need_question: state.need_question,
        success: false,
        error: "",
      };

    case NEED_QUESTION_CREATE_SUCCESS:
      return {
        loading: false,
        need_question: action.payload,
        success: true,
        error: "",
      };

    case NEED_QUESTION_CREATE_FAIL:
      return {
        loading: false,
        need_question: state.need_question,
        success: false,
        error: action.payload,
      };

    case NEED_QUESTION_CREATE_RESET:
      return {
        loading: false,
        need_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const needQuestionDetailsReducer = (
  state = {
    loading: false,
    need_question: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NEED_QUESTION_DETAILS_REQUEST:
      return {
        loading: true,
        need_question: state.need_question,
        success: false,
        error: "",
      };

    case NEED_QUESTION_DETAILS_SUCCESS:
      return {
        loading: false,
        need_question: action.payload,
        success: true,
        error: "",
      };

    case NEED_QUESTION_DETAILS_FAIL:
      return {
        loading: false,
        need_question: state.need_question,
        success: false,
        error: action.payload,
      };

    case NEED_QUESTION_DETAILS_RESET:
      return {
        loading: false,
        need_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const needQuestionUpdateReducer = (
  state = {
    loading: false,
    need_question: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NEED_QUESTION_UPDATE_REQUEST:
      return {
        loading: true,
        need_question: state.need_question,
        success: false,
        error: "",
      };

    case NEED_QUESTION_UPDATE_SUCCESS:
      return {
        loading: false,
        need_question: action.payload,
        success: true,
        error: "",
      };

    case NEED_QUESTION_UPDATE_FAIL:
      return {
        loading: false,
        need_question: state.need_question,
        success: false,
        error: action.payload,
      };

    case NEED_QUESTION_UPDATE_RESET:
      return {
        loading: false,
        need_question: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const needQuestionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case NEED_QUESTION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case NEED_QUESTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case NEED_QUESTION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case NEED_QUESTION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Upload
export const needQuestionUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case NEED_QUESTION_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case NEED_QUESTION_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NEED_QUESTION_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
      };

    case NEED_QUESTION_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case NEED_QUESTION_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case NEED_QUESTION_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
