export const INVOICE_PERMISSION_LIST_REQUEST =
  "INVOICE_PERMISSION_LIST_REQUEST";
export const INVOICE_PERMISSION_LIST_SUCCESS =
  "INVOICE_PERMISSION_LIST_SUCCESS";
export const INVOICE_PERMISSION_LIST_FAIL = "INVOICE_PERMISSION_LIST_FAIL";
export const INVOICE_PERMISSION_LIST_RESET = "INVOICE_PERMISSION_LIST_RESET";

export const INVOICE_PERMISSION_CREATE_REQUEST =
  "INVOICE_PERMISSION_CREATE_REQUEST";
export const INVOICE_PERMISSION_CREATE_SUCCESS =
  "INVOICE_PERMISSION_CREATE_SUCCESS";
export const INVOICE_PERMISSION_CREATE_FAIL = "INVOICE_PERMISSION_CREATE_FAIL";
export const INVOICE_PERMISSION_CREATE_RESET =
  "INVOICE_PERMISSION_CREATE_RESET";

export const INVOICE_PERMISSION_DETAILS_REQUEST =
  "INVOICE_PERMISSION_DETAILS_REQUEST";
export const INVOICE_PERMISSION_DETAILS_SUCCESS =
  "INVOICE_PERMISSION_DETAILS_SUCCESS";
export const INVOICE_PERMISSION_DETAILS_FAIL =
  "INVOICE_PERMISSION_DETAILS_FAIL";
export const INVOICE_PERMISSION_DETAILS_RESET =
  "INVOICE_PERMISSION_DETAILS_RESET";

export const INVOICE_PERMISSION_UPDATE_REQUEST =
  "INVOICE_PERMISSION_UPDATE_REQUEST";
export const INVOICE_PERMISSION_UPDATE_SUCCESS =
  "INVOICE_PERMISSION_UPDATE_SUCCESS";
export const INVOICE_PERMISSION_UPDATE_FAIL = "INVOICE_PERMISSION_UPDATE_FAIL";
export const INVOICE_PERMISSION_UPDATE_RESET =
  "INVOICE_PERMISSION_UPDATE_RESET";

export const INVOICE_PERMISSION_DELETE_REQUEST =
  "INVOICE_PERMISSION_DELETE_REQUEST";
export const INVOICE_PERMISSION_DELETE_SUCCESS =
  "INVOICE_PERMISSION_DELETE_SUCCESS";
export const INVOICE_PERMISSION_DELETE_FAIL = "INVOICE_PERMISSION_DELETE_FAIL";
export const INVOICE_PERMISSION_DELETE_RESET =
  "INVOICE_PERMISSION_DELETE_RESET";
