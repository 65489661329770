// Constant imports
import {
  REIMBURSEMENT_LIST_REQUEST,
  REIMBURSEMENT_LIST_SUCCESS,
  REIMBURSEMENT_LIST_FAIL,
  REIMBURSEMENT_LIST_RESET,
  REIMBURSEMENT_DETAILS_REQUEST,
  REIMBURSEMENT_DETAILS_SUCCESS,
  REIMBURSEMENT_DETAILS_FAIL,
  REIMBURSEMENT_DETAILS_RESET,
  REIMBURSEMENT_UPDATE_REQUEST,
  REIMBURSEMENT_UPDATE_SUCCESS,
  REIMBURSEMENT_UPDATE_FAIL,
  REIMBURSEMENT_UPDATE_RESET,
  REIMBURSEMENT_DOWNLOAD_REQUEST,
  REIMBURSEMENT_DOWNLOAD_SUCCESS,
  REIMBURSEMENT_DOWNLOAD_FAIL,
  REIMBURSEMENT_DOWNLOAD_RESET,
} from "../constants/reimbursementConstants";

// List all
export const reimbursementListReducer = (
  state = {
    loading: false,
    count: 0,
    reimbursements: [],
    totalValue: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case REIMBURSEMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REIMBURSEMENT_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        reimbursements: action.payload.reimbursements,
        totalValue: action.payload.totalValue,
        success: true,
        error: "",
      };

    case REIMBURSEMENT_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        reimbursements: [],
        totalValue: 0,
        success: false,
        error: action.payload,
      };

    case REIMBURSEMENT_LIST_RESET:
      return {
        loading: false,
        count: 0,
        reimbursements: [],
        totalValue: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const reimbursementDetailsReducer = (
  state = { loading: false, reimbursement: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case REIMBURSEMENT_DETAILS_REQUEST:
      return {
        loading: true,
        reimbursement: state.reimbursement,
        success: false,
        error: "",
      };

    case REIMBURSEMENT_DETAILS_SUCCESS:
      return {
        loading: false,
        reimbursement: action.payload,
        success: true,
        error: "",
      };

    case REIMBURSEMENT_DETAILS_FAIL:
      return {
        loading: false,
        reimbursement: state.reimbursement,
        success: false,
        error: action.payload,
      };

    case REIMBURSEMENT_DETAILS_RESET:
      return { loading: false, reimbursement: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const reimbursementUpdateReducer = (
  state = { loading: false, reimbursement: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case REIMBURSEMENT_UPDATE_REQUEST:
      return {
        loading: true,
        reimbursement: state.reimbursement,
        success: false,
        error: "",
      };

    case REIMBURSEMENT_UPDATE_SUCCESS:
      return {
        loading: false,
        reimbursement: action.payload,
        success: true,
        error: "",
      };

    case REIMBURSEMENT_UPDATE_FAIL:
      return {
        loading: false,
        reimbursement: state.reimbursement,
        success: false,
        error: action.payload,
      };

    case REIMBURSEMENT_UPDATE_RESET:
      return { loading: false, reimbursement: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const reimbursementDownloadReducer = (
  state = {
    loading: false,
    reimbursements: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case REIMBURSEMENT_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case REIMBURSEMENT_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        reimbursements: action.payload.reimbursements,
        success: true,
        error: "",
      };

    case REIMBURSEMENT_DOWNLOAD_FAIL:
      return {
        loading: false,
        reimbursements: [],
        success: false,
        error: action.payload,
      };

    case REIMBURSEMENT_DOWNLOAD_RESET:
      return {
        loading: false,
        reimbursements: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
