// Constant imports
import {
  TIMESHEET_ENTRY_LIST_REQUEST,
  TIMESHEET_ENTRY_LIST_SUCCESS,
  TIMESHEET_ENTRY_LIST_FAIL,
  TIMESHEET_ENTRY_LIST_RESET,
  TIMESHEET_ENTRY_CREATE_REQUEST,
  TIMESHEET_ENTRY_CREATE_SUCCESS,
  TIMESHEET_ENTRY_CREATE_FAIL,
  TIMESHEET_ENTRY_CREATE_RESET,
  TIMESHEET_ENTRY_DETAILS_REQUEST,
  TIMESHEET_ENTRY_DETAILS_SUCCESS,
  TIMESHEET_ENTRY_DETAILS_FAIL,
  TIMESHEET_ENTRY_DETAILS_RESET,
  TIMESHEET_ENTRY_UPDATE_REQUEST,
  TIMESHEET_ENTRY_UPDATE_SUCCESS,
  TIMESHEET_ENTRY_UPDATE_FAIL,
  TIMESHEET_ENTRY_UPDATE_RESET,
  TIMESHEET_ENTRY_DELETE_REQUEST,
  TIMESHEET_ENTRY_DELETE_SUCCESS,
  TIMESHEET_ENTRY_DELETE_FAIL,
  TIMESHEET_ENTRY_DELETE_RESET,
} from "../constants/timesheetEntryConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listTimesheetEntriesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_ENTRY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getTimesheetEntries(
        params,
        token
      );

      dispatch({
        type: TIMESHEET_ENTRY_LIST_SUCCESS,

        payload: {
          count: data.count,
          totalMinutes: data.total_minutes.result,
          totalMinutesFri: data.total_minutes_fri.result,
          totalMinutesMon: data.total_minutes_mon.result,
          totalMinutesSat: data.total_minutes_sat.result,
          totalMinutesSun: data.total_minutes_sun.result,
          totalMinutesThu: data.total_minutes_thu.result,
          totalMinutesTue: data.total_minutes_tue.result,
          totalMinutesWed: data.total_minutes_wed.result,
          timesheet_entries: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_ENTRY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListTimesheetEntriesAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_ENTRY_LIST_RESET });
};

// Create
export const createTimesheetEntryAction =
  (timesheet_entry) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_ENTRY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createTimesheetEntry(
        token,
        timesheet_entry
      );

      dispatch({
        type: TIMESHEET_ENTRY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_ENTRY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateTimesheetEntryAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_ENTRY_CREATE_RESET });
};

// List details
export const listTimesheetEntryDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_ENTRY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getTimesheetEntry(id, token);

      dispatch({
        type: TIMESHEET_ENTRY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_ENTRY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListTimesheetEntryDetailsAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_ENTRY_DETAILS_RESET });
};

// Update
export const updateTimesheetEntryAction =
  (id, timesheet_entry) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_ENTRY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateTimesheetEntry(
        id,
        token,
        timesheet_entry
      );

      dispatch({
        type: TIMESHEET_ENTRY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_ENTRY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateTimesheetEntryAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_ENTRY_UPDATE_RESET });
};

// Delete
export const deleteTimesheetEntryAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: TIMESHEET_ENTRY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteTimesheetEntry(id, token);

      dispatch({
        type: TIMESHEET_ENTRY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TIMESHEET_ENTRY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteTimesheetEntryAction = () => async (dispatch) => {
  dispatch({ type: TIMESHEET_ENTRY_DELETE_RESET });
};
