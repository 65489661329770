export const PROPOSAL_CASE_STUDY_LIST_REQUEST =
  "PROPOSAL_CASE_STUDY_LIST_REQUEST";
export const PROPOSAL_CASE_STUDY_LIST_SUCCESS =
  "PROPOSAL_CASE_STUDY_LIST_SUCCESS";
export const PROPOSAL_CASE_STUDY_LIST_FAIL = "PROPOSAL_CASE_STUDY_LIST_FAIL";
export const PROPOSAL_CASE_STUDY_LIST_RESET = "PROPOSAL_CASE_STUDY_LIST_RESET";

export const PROPOSAL_CASE_STUDY_CREATE_REQUEST =
  "PROPOSAL_CASE_STUDY_CREATE_REQUEST";
export const PROPOSAL_CASE_STUDY_CREATE_SUCCESS =
  "PROPOSAL_CASE_STUDY_CREATE_SUCCESS";
export const PROPOSAL_CASE_STUDY_CREATE_FAIL =
  "PROPOSAL_CASE_STUDY_CREATE_FAIL";
export const PROPOSAL_CASE_STUDY_CREATE_RESET =
  "PROPOSAL_CASE_STUDY_CREATE_RESET";

export const PROPOSAL_CASE_STUDY_DETAILS_REQUEST =
  "PROPOSAL_CASE_STUDY_DETAILS_REQUEST";
export const PROPOSAL_CASE_STUDY_DETAILS_SUCCESS =
  "PROPOSAL_CASE_STUDY_DETAILS_SUCCESS";
export const PROPOSAL_CASE_STUDY_DETAILS_FAIL =
  "PROPOSAL_CASE_STUDY_DETAILS_FAIL";
export const PROPOSAL_CASE_STUDY_DETAILS_RESET =
  "PROPOSAL_CASE_STUDY_DETAILS_RESET";

export const PROPOSAL_CASE_STUDY_UPDATE_REQUEST =
  "PROPOSAL_CASE_STUDY_UPDATE_REQUEST";
export const PROPOSAL_CASE_STUDY_UPDATE_SUCCESS =
  "PROPOSAL_CASE_STUDY_UPDATE_SUCCESS";
export const PROPOSAL_CASE_STUDY_UPDATE_FAIL =
  "PROPOSAL_CASE_STUDY_UPDATE_FAIL";
export const PROPOSAL_CASE_STUDY_UPDATE_RESET =
  "PROPOSAL_CASE_STUDY_UPDATE_RESET";

export const PROPOSAL_CASE_STUDY_DELETE_REQUEST =
  "PROPOSAL_CASE_STUDY_DELETE_REQUEST";
export const PROPOSAL_CASE_STUDY_DELETE_SUCCESS =
  "PROPOSAL_CASE_STUDY_DELETE_SUCCESS";
export const PROPOSAL_CASE_STUDY_DELETE_FAIL =
  "PROPOSAL_CASE_STUDY_DELETE_FAIL";
export const PROPOSAL_CASE_STUDY_DELETE_RESET =
  "PROPOSAL_CASE_STUDY_DELETE_RESET";
