// Library imports
import {
  Card,
  Classes,
  Colors,
  Divider,
  H1,
  H5,
  HTMLSelect,
  NonIdealState,
  Tag,
  UL,
} from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

// Component imports
import ErrorAlert from "../components/ErrorAlert";
import InfoPopup from "../components/InfoPopup";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Redux action imports
// Salary benchmark
import { listSalaryBenchmarksAction } from "../actions/salaryBenchmarkActions";

// Main function
function SalaryBenchmarkWidget() {
  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Salary benchmark
  const salaryBenchmarkList = useSelector((state) => state.salaryBenchmarkList);

  // Search and filter variables
  // Salary benchmark
  const salaryBenchmarkQueryDefault = {
    country: "United States of America",
    firm: "",
    measure: "total",
  };
  const [salaryBenchmarkQuery, setSalaryBenchmarkQuery] = useState({
    ...salaryBenchmarkQueryDefault,
  });

  // Filter salary benchmarks
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listSalaryBenchmarksAction({
            ...salaryBenchmarkQuery,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [salaryBenchmarkQuery]);

  // Generate filters
  const [filtersInitialised, setFiltersInitialised] = useState(false);
  const [firms, setFirms] = useState([]);

  useEffect(() => {
    if (filtersInitialised) {
      setSalaryBenchmarkQuery((prevState) => {
        return {
          ...prevState,
          firm: "",
        };
      });

      setFiltersInitialised(false);
    }
    // eslint-disable-next-line
  }, [salaryBenchmarkQuery.country]);

  useEffect(() => {
    if (
      !filtersInitialised &&
      salaryBenchmarkList.salary_benchmarks.length > 0
    ) {
      // Firms
      const firmsArr = Array.from(
        new Set(salaryBenchmarkList.salary_benchmarks.map((item) => item.firm))
      ).map((firm) => ({ label: firm, value: firm.replace(/&/g, "%26") }));

      firmsArr.sort((a, b) => a.label.localeCompare(b.label));

      firmsArr.unshift({ label: "All", value: "" });

      setFirms(firmsArr);

      setFiltersInitialised(true);
    }
    // eslint-disable-next-line
  }, [salaryBenchmarkList.salary_benchmarks]);

  // Generate benchmarks
  const [benchmarks, setBenchmarks] = useState([]);

  useEffect(() => {
    if (salaryBenchmarkList.salary_benchmarks) {
      const managingDirectors = salaryBenchmarkList.salary_benchmarks.filter(
        (item) =>
          item.rank === "Managing Director, Principal, Partner, or C-suite"
      );

      const seniorManagers = salaryBenchmarkList.salary_benchmarks.filter(
        (item) => item.rank === "Senior Manager"
      );

      const managers = salaryBenchmarkList.salary_benchmarks.filter(
        (item) => item.rank === "Manager"
      );

      const seniorConsultants = salaryBenchmarkList.salary_benchmarks.filter(
        (item) => item.rank === "Senior Consultant/Senior Associate"
      );

      const consultants = salaryBenchmarkList.salary_benchmarks.filter(
        (item) => item.rank === "Consultant/Associate"
      );

      const analysts = salaryBenchmarkList.salary_benchmarks.filter(
        (item) => item.rank === "Analyst"
      );

      const interns = salaryBenchmarkList.salary_benchmarks.filter(
        (item) => item.rank === "Intern"
      );

      const benchmarksArr = [];

      managingDirectors.length > 0 &&
        benchmarksArr.push({
          name: "MD/Partner",
          fill:
            managingDirectors.length > 10
              ? Colors.BLUE5
              : managingDirectors.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            managingDirectors.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / managingDirectors.length,
        });

      seniorManagers.length > 0 &&
        benchmarksArr.push({
          name: "Senior Manager",
          fill:
            seniorManagers.length > 10
              ? Colors.BLUE5
              : seniorManagers.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            seniorManagers.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / seniorManagers.length,
        });

      managers.length > 0 &&
        benchmarksArr.push({
          name: "Manager",
          fill:
            managers.length > 10
              ? Colors.BLUE5
              : managers.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            managers.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / managers.length,
        });

      seniorConsultants.length > 0 &&
        benchmarksArr.push({
          name: "Senior Consultant",
          fill:
            seniorConsultants.length > 10
              ? Colors.BLUE5
              : seniorConsultants.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            seniorConsultants.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / seniorConsultants.length,
        });

      consultants.length > 0 &&
        benchmarksArr.push({
          name: "Consultant",
          fill:
            consultants.length > 10
              ? Colors.BLUE5
              : consultants.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            consultants.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / consultants.length,
        });

      analysts.length > 0 &&
        benchmarksArr.push({
          name: "Analyst",
          fill:
            analysts.length > 10
              ? Colors.BLUE5
              : analysts.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            analysts.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / analysts.length,
        });

      interns.length > 0 &&
        benchmarksArr.push({
          name: "Intern",
          fill:
            interns.length > 10
              ? Colors.BLUE5
              : interns.length >= 5
              ? Colors.BLUE4
              : Colors.BLUE3,
          value:
            interns.reduce(
              (sum, item) => sum + item[salaryBenchmarkQuery.measure],
              0
            ) / interns.length,
        });

      setBenchmarks(benchmarksArr);
    }
    // eslint-disable-next-line
  }, [salaryBenchmarkList.salary_benchmarks]);

  // Highlight item
  const [activeIndex, setActiveIndex] = useState(null);

  // JSX UI code
  return (
    <Card
      className={Classes.DARK}
      elevation={4}
      style={{
        border: "1px solid #D2ECF9",
        borderRadius: "5px",
        maxWidth: "800px",
        width: "100%",
      }}
    >
      {/* Heading */}
      <div
        className="row"
        style={{
          textAlign: "center",
        }}
      >
        <H1 style={{ color: "#D2ECF9", marginRight: "10px" }}>
          Consultant Salary Intelligence [FY2024]
        </H1>
      </div>

      <Divider style={{ margin: "10px 0 30px 0px" }} />

      {/* Error message */}
      {salaryBenchmarkList.error && (
        <ErrorAlert message="We couldn't load the salary benchmarks. Try refreshing the page." />
      )}

      {/* Body */}
      {/* Filters */}
      <div className="row" style={{ marginTop: "15px" }}>
        {/* Country */}
        <div className="col-s-1">
          <div style={{ marginTop: "-10px" }}>Country</div>
        </div>

        <div className="col-s-3" style={{ marginTop: "-15px" }}>
          <HTMLSelect
            fill
            id="countryField"
            onChange={(e) => {
              setSalaryBenchmarkQuery((prevState) => {
                return {
                  ...prevState,
                  country: e.target.value,
                };
              });
            }}
            options={[
              { label: "United States", value: "United States of America" },
              { label: "Australia", value: "Australia" },
              { label: "Canada", value: "Canada" },
              { label: "Ireland", value: "Ireland" },
              { label: "New Zealand", value: "New Zealand" },
              { label: "Singapore", value: "Singapore" },
              { label: "United Kingdom", value: "United Kingdom" },
            ]}
            value={salaryBenchmarkQuery.country}
          />
        </div>

        {/* Firm */}
        <div className="col-s-1">
          <div
            style={{
              marginTop: "-10px",
            }}
          >
            Firm
          </div>
        </div>

        <div className="col-s-3" style={{ marginTop: "-15px" }}>
          <HTMLSelect
            fill
            id="firmField"
            onChange={(e) => {
              setSalaryBenchmarkQuery((prevState) => {
                return {
                  ...prevState,
                  firm: e.target.value,
                };
              });
            }}
            options={firms}
            value={salaryBenchmarkQuery.firm}
          />
        </div>

        {/* Measure */}
        <div className="col-s-1">
          <div
            style={{
              marginTop: "-10px",
            }}
          >
            Measure
          </div>
        </div>

        <div className="col-s-3" style={{ marginTop: "-15px" }}>
          <HTMLSelect
            fill
            id="measureField"
            onChange={(e) => {
              setSalaryBenchmarkQuery((prevState) => {
                return {
                  ...prevState,
                  measure: e.target.value,
                };
              });
            }}
            options={[
              { label: "Total", value: "total" },
              { label: "Base", value: "base" },
              { label: "Bonus", value: "bonus" },
            ]}
            value={salaryBenchmarkQuery.measure}
          />
        </div>
      </div>

      {/* Chart */}
      <Card style={{ borderRadius: "5px", marginTop: "15px" }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Tag minimal>USD</Tag>
          </div>

          <InfoPopup
            content={
              <div style={{ maxWidth: "600px" }}>
                <H5>About</H5>

                <Divider
                  style={{
                    background: Colors.GRAY5,
                    margin: "10px 0 10px 0",
                  }}
                />

                <p>
                  This tool provides average salary data, denominated in US
                  Dollars, for a sample of 2,608 consultants employed by various
                  firms in different countries over the 2023-2024 financial
                  year.
                </p>

                <p>
                  The data is based on salary surveys that asked respondents
                  were asked to submit the following information:
                </p>

                <UL>
                  <li>The country they were based out of.</li>
                  <li>The firm they worked for.</li>
                  <li>Their job title or rank.</li>
                  <li>Their annual base compensation.</li>
                  <li>The bonus amount they expected in the same year.</li>
                </UL>
              </div>
            }
            flexWidth
          />
        </div>

        {/* Content */}
        {benchmarks.length > 0 && (
          <ResponsiveContainer height={350} width="100%">
            <BarChart
              data={benchmarks}
              layout="vertical"
              margin={{
                bottom: 10,
                left: 10,
                right: 10,
                top: 30,
              }}
            >
              <defs>
                {benchmarks.map((item, i) => {
                  return (
                    <linearGradient
                      id={`color${item.fill}`}
                      key={i}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={item.fill}
                        stopOpacity={0.9}
                      />
                      <stop
                        offset="95%"
                        stopColor={item.fill}
                        stopOpacity={0.7}
                      />
                    </linearGradient>
                  );
                })}
              </defs>

              <Bar
                dataKey="value"
                label={({ height, index, width, x, y, value }) => {
                  return (
                    <text
                      dy={4}
                      fill="white"
                      fontSize={10}
                      fontWeight={index === activeIndex ? "bold" : "normal"}
                      textAnchor="middle"
                      x={x + width / 2}
                      y={y + height / 2}
                    >
                      {value ? numberFormatter(value) : undefined}
                    </text>
                  );
                }}
                maxBarSize={65}
                onMouseEnter={(_, index) => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(null)}
                radius={[0, 5, 5, 0]}
              >
                {benchmarks.map((entry, index) => (
                  <Cell
                    fill={`url(#color${entry.fill})`}
                    key={`cell-${index}`}
                    stroke={index === activeIndex ? entry.fill : undefined}
                  />
                ))}
              </Bar>

              <ReferenceLine fill={Colors.WHITE} x={0} />

              <XAxis hide type="number" />

              <YAxis
                axisLine={false}
                dataKey="name"
                tick={{ fill: Colors.WHITE }}
                tickLine={false}
                type="category"
                width={75}
              />
            </BarChart>
          </ResponsiveContainer>
        )}

        {/* No results */}
        {benchmarks.length === 0 && (
          <div
            style={{ alignItems: "center", display: "flex", height: "350px" }}
          >
            <NonIdealState icon={"search"} title={"No data"} />
          </div>
        )}

        {/* Legend */}
        <Card
          className="row"
          style={{
            background: Colors.DARK_GRAY4,
            marginTop: "20px",
            padding: 0,
          }}
        >
          <div className="col-s-3">Number of responses:</div>

          <div
            className="col-s-3"
            style={{ alignItems: "center", display: "flex" }}
          >
            <Tag style={{ background: Colors.BLUE5, marginRight: "5px" }} />

            <div>More than 10</div>
          </div>

          <div
            className="col-s-3"
            style={{ alignItems: "center", display: "flex" }}
          >
            <Tag style={{ background: Colors.BLUE4, marginRight: "5px" }} />

            <div>Between 5 and 10</div>
          </div>

          <div
            className="col-s-3"
            style={{ alignItems: "center", display: "flex" }}
          >
            <Tag style={{ background: Colors.BLUE3, marginRight: "5px" }} />

            <div>Less than 5</div>
          </div>
        </Card>
      </Card>

      {/* Footer */}
      <div
        style={{
          margin: "20px 20px 0 20px",
        }}
      >
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "10px",
          }}
        >
          <img
            alt="logo"
            src="/images/logo.svg"
            style={{
              marginRight: "10px",
              maxHeight: "50px",
              objectFit: "contain",
              width: "50px",
            }}
          />

          <div>Powered by advaise.app</div>
        </div>
      </div>
    </Card>
  );
}

export default SalaryBenchmarkWidget;
