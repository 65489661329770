export const ENGAGEMENT_DOCUMENT_DETAILS_REQUEST =
  "ENGAGEMENT_DOCUMENT_DETAILS_REQUEST";
export const ENGAGEMENT_DOCUMENT_DETAILS_SUCCESS =
  "ENGAGEMENT_DOCUMENT_DETAILS_SUCCESS";
export const ENGAGEMENT_DOCUMENT_DETAILS_FAIL =
  "ENGAGEMENT_DOCUMENT_DETAILS_FAIL";
export const ENGAGEMENT_DOCUMENT_DETAILS_RESET =
  "ENGAGEMENT_DOCUMENT_DETAILS_RESET";
export const ENGAGEMENT_DOCUMENT_UPDATE_REQUEST =
  "ENGAGEMENT_DOCUMENT_UPDATE_REQUEST";
export const ENGAGEMENT_DOCUMENT_UPDATE_SUCCESS =
  "ENGAGEMENT_DOCUMENT_UPDATE_SUCCESS";
export const ENGAGEMENT_DOCUMENT_UPDATE_FAIL =
  "ENGAGEMENT_DOCUMENT_UPDATE_FAIL";
export const ENGAGEMENT_DOCUMENT_UPDATE_RESET =
  "ENGAGEMENT_DOCUMENT_UPDATE_RESET";
