// Constant imports
import {
  PROPOSAL_CASE_STUDY_LIST_REQUEST,
  PROPOSAL_CASE_STUDY_LIST_SUCCESS,
  PROPOSAL_CASE_STUDY_LIST_FAIL,
  PROPOSAL_CASE_STUDY_LIST_RESET,
  PROPOSAL_CASE_STUDY_CREATE_REQUEST,
  PROPOSAL_CASE_STUDY_CREATE_SUCCESS,
  PROPOSAL_CASE_STUDY_CREATE_FAIL,
  PROPOSAL_CASE_STUDY_CREATE_RESET,
  PROPOSAL_CASE_STUDY_DETAILS_REQUEST,
  PROPOSAL_CASE_STUDY_DETAILS_SUCCESS,
  PROPOSAL_CASE_STUDY_DETAILS_FAIL,
  PROPOSAL_CASE_STUDY_DETAILS_RESET,
  PROPOSAL_CASE_STUDY_UPDATE_REQUEST,
  PROPOSAL_CASE_STUDY_UPDATE_SUCCESS,
  PROPOSAL_CASE_STUDY_UPDATE_FAIL,
  PROPOSAL_CASE_STUDY_UPDATE_RESET,
  PROPOSAL_CASE_STUDY_DELETE_REQUEST,
  PROPOSAL_CASE_STUDY_DELETE_SUCCESS,
  PROPOSAL_CASE_STUDY_DELETE_FAIL,
  PROPOSAL_CASE_STUDY_DELETE_RESET,
} from "../constants/proposalCaseStudyConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listProposalCaseStudiesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CASE_STUDY_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalCaseStudies(
        params,
        token
      );

      dispatch({
        type: PROPOSAL_CASE_STUDY_LIST_SUCCESS,

        payload: {
          count: data.count,
          proposal_case_studies: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CASE_STUDY_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListProposalCaseStudiesAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CASE_STUDY_LIST_RESET });
};

// Create
export const createProposalCaseStudyAction =
  (proposal_case_study) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CASE_STUDY_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.createProposalCaseStudy(
        token,
        proposal_case_study
      );

      dispatch({
        type: PROPOSAL_CASE_STUDY_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CASE_STUDY_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateProposalCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CASE_STUDY_CREATE_RESET });
};

// List details
export const listProposalCaseStudyDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CASE_STUDY_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getProposalCaseStudy(id, token);

      dispatch({
        type: PROPOSAL_CASE_STUDY_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CASE_STUDY_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListProposalCaseStudyDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: PROPOSAL_CASE_STUDY_DETAILS_RESET });
  };

// Update
export const updateProposalCaseStudyAction =
  (id, proposal_case_study) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CASE_STUDY_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.updateProposalCaseStudy(
        id,
        token,
        proposal_case_study
      );

      dispatch({
        type: PROPOSAL_CASE_STUDY_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CASE_STUDY_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateProposalCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CASE_STUDY_UPDATE_RESET });
};

// Delete
export const deleteProposalCaseStudyAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_CASE_STUDY_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.deleteProposalCaseStudy(
        id,
        token
      );

      dispatch({
        type: PROPOSAL_CASE_STUDY_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PROPOSAL_CASE_STUDY_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteProposalCaseStudyAction = () => async (dispatch) => {
  dispatch({ type: PROPOSAL_CASE_STUDY_DELETE_RESET });
};
