// Library imports
import { Colors, NonIdealState, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import InfoPopup from "./InfoPopup";
import ItemCard from "./ItemCard";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Main function
function DataCardDataPoint({
  background,
  comments,
  darkColor,
  data,
  fill,
  interactive,
  lightColor,
  noData,
  noDataMessage,
  onClick,
  title,
  units,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div
              style={{
                alignItems: "center",
                color: lightMode ? Colors.DARK_GRAY5 : Colors.LIGHT_GRAY1,
                display: "flex",
                height: "200px",
                justifyContent: "center",
                overflowY: "hidden",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  border: `20px solid ${
                    background
                      ? background
                      : lightMode
                      ? Colors.LIGHT_GRAY3
                      : Colors.DARK_GRAY5
                  }`,
                  borderRadius: "50%",
                  color: background ? background : undefined,
                  display: "flex",
                  fontSize: 110 - 15 * numberFormatter(data).length,
                  height: "180px",
                  justifyContent: "center",
                  marginTop: "5px",
                  padding: "5px",
                  width: "180px",
                }}
              >
                {data === 0 ? (
                  <span style={{ marginBottom: "20px" }}>-</span>
                ) : (
                  numberFormatter(data)
                )}
              </div>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState
                icon={"search"}
                title={noDataMessage ? noDataMessage : "No data"}
              />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={title}
      width={width}
    />
  );
}

export default DataCardDataPoint;
