// Library imports
import {
  Alignment,
  Button,
  Classes,
  Navbar,
  Tag,
  Text,
} from "@blueprintjs/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Redux action imports
// UI
import {
  switchActionSidebarModeAction,
  switchSidebarModeAction,
} from "../actions/UIActions";

// Main function
function NavbarContainer({ children, label, labelIntent, loading, title }) {
  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  const checkSubscription = useSelector((state) => state.checkSubscription);

  // UI
  const actionSidebarMode = useSelector((state) => state.actionSidebarMode);
  const sidebarMode = useSelector((state) => state.sidebarMode);

  // Switch action sidebar mode
  const switchActionSidebarMode = () => {
    dispatch(
      switchActionSidebarModeAction(!actionSidebarMode.showActionSidebar)
    );
  };

  // Switch sidebar mode
  const switchSidebarMode = () => {
    dispatch(switchSidebarModeAction(!sidebarMode.showSidebar));
  };

  // JSX UI code
  return (
    <Navbar fixedToTop>
      <Navbar.Group align={Alignment.LEFT}>
        <img
          alt="logo"
          src="/images/logo_modified.svg"
          style={{
            height: "40px",
            margin: "10px 10px 10px 0",
          }}
        />

        <Button
          icon="menu"
          minimal
          onClick={switchSidebarMode}
          style={{ margin: "0 10px 0 0" }}
        />

        <Navbar.Heading className={loading ? Classes.SKELETON : undefined}>
          {loading ? "XXXXXXXXXX" : title}
        </Navbar.Heading>

        {label && (
          <Tag intent={labelIntent} minimal>
            {label}
          </Tag>
        )}

        {children && <Navbar.Divider />}

        {children}
      </Navbar.Group>

      <Navbar.Group align={Alignment.RIGHT}>
        <Button
          icon="user"
          minimal
          onClick={() => {
            navigate("/userProfile");
          }}
          text={
            <Text
              className={Classes.TEXT_SMALL}
              ellipsize
              style={{ maxWidth: "300px" }}
            >
              {userLogin.user}
            </Text>
          }
        />

        {activeOrganisation.id ? (
          <>
            <Navbar.Divider />
            <Button
              icon="office"
              minimal
              onClick={() => navigate("/organisations")}
              text={
                <Text
                  className={Classes.TEXT_SMALL}
                  ellipsize
                  style={{ maxWidth: "300px" }}
                >
                  {activeOrganisation.name}
                </Text>
              }
            />
          </>
        ) : undefined}

        <Button
          disabled={
            !activeOrganisation.id ||
            !checkSubscription.subscription.has_active_seat
          }
          icon="menu-closed"
          minimal
          onClick={switchActionSidebarMode}
          style={{ margin: "0 0 0 10px" }}
        />
      </Navbar.Group>
    </Navbar>
  );
}

export default NavbarContainer;
