// Constants import
import { BACKEND_WS_URL } from "../constants/applicationConstants";

// Library imports
import {
  Button,
  Callout,
  Card,
  Checkbox,
  Classes,
  Colors,
  ControlGroup,
  Divider,
  Drawer,
  HTMLSelect,
  InputGroup,
  Menu,
  MenuItem,
  NonIdealState,
  Position,
  Spinner,
  Tab,
  Tabs,
  Text,
  TextArea,
} from "@blueprintjs/core";
import { marked } from "marked";
import parse from "html-react-parser";
import { Popover2 } from "@blueprintjs/popover2";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";

// Component imports
import CopyButton from "../components/CopyButton";
import DateValue from "../components/DateValue";
import ErrorAlert from "../components/ErrorAlert";
import IconSquare from "../components/IconSquare";
import ItemCard from "../components/ItemCard";
import LinkTag from "../components/LinkTag";
import NameValue from "../components/NameValue";
import TabSectionField from "../components/TabSectionField";
import TabSectionFieldCollapse from "../components/TabSectionFieldCollapse";

// Redux action imports
// Notification
import {
  deleteNotificationAction,
  listNotificationsAction,
  resetDeleteNotificationAction,
} from "../actions/notificationActions";

// Organisation
import { checkSubscriptionAction } from "../actions/organisationActions";

// To-do
import {
  listTodosAction,
  resetUpdateTodoAction,
  updateTodoAction,
} from "../actions/todoActions";

// UI
import { switchActionSidebarModeAction } from "../actions/UIActions";

// Workflow
import {
  createWorkflowAction,
  deleteWorkflowAction,
  listWorkflowsAction,
  resetCreateWorkflowAction,
  resetDeleteWorkflowAction,
  resetUpdateWorkflowAction,
  updateWorkflowAction,
} from "../actions/workflowActions";

// Main function
function ActionSidebar() {
  // Switch action sidebar mode
  const switchActionSidebarMode = () => {
    dispatch(switchActionSidebarModeAction(!showActionSidebar));
  };

  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // User login state variables
  const userLogin = useSelector((state) => state.userLogin);

  // Navigation function
  const navigate = useNavigate();

  // Redux dispatch function
  const dispatch = useDispatch();

  // Redux state variables
  // Action sidebar
  const actionSidebarMode = useSelector((state) => state.actionSidebarMode);
  const { showActionSidebar } = actionSidebarMode;

  // Notification
  const notificationDelete = useSelector((state) => state.notificationDelete);

  const notificationList = useSelector((state) => state.notificationList);

  // Organisation
  const activeOrganisation = useSelector((state) => state.activeOrganisation);

  const checkSubscription = useSelector((state) => state.checkSubscription);

  // To-do
  const todoList = useSelector((state) => state.todoList);

  const todoUpdate = useSelector((state) => state.todoUpdate);

  // Workflow
  const workflowCreate = useSelector((state) => state.workflowCreate);

  const workflowDelete = useSelector((state) => state.workflowDelete);

  const workflowList = useSelector((state) => state.workflowList);

  const workflowUpdate = useSelector((state) => state.workflowUpdate);

  // Page navigation variables
  const topOfPage = useRef(null);

  // Tab navigation variable
  const [selectedTab, setSelectedTab] = useState("chat-tab");

  // Scroll to top
  useEffect(() => {
    topOfPage.current.scrollIntoView(false);
  }, [selectedTab]);

  // Search and filter variables
  // Notification
  const notificationQueryDefault = {
    organisation: activeOrganisation.id,
    page: 1,
  };
  const [notificationQuery] = useState({
    ...notificationQueryDefault,
  });

  // To-do
  const todoQueryDefault = {
    category: "",
    daysAhead: 84,
    done: "",
    organisation: activeOrganisation.id,
  };
  const [todoQuery, setTodoQuery] = useState({
    ...todoQueryDefault,
  });

  // Workflow
  const workflowQueryDefault = {
    organisation: activeOrganisation.id,
    status: "In-progress",
  };
  const [workflowQuery, setWorkflowQuery] = useState({
    ...workflowQueryDefault,
  });

  // Form variables
  // To-do
  const todoDefault = {
    comments: "",
    done: false,
    id: "",
  };
  const [todo, setTodo] = useState({
    ...todoDefault,
  });

  const [todos, setTodos] = useState({});
  const [todosInitialised, setTodosInitialised] = useState(false);

  // Workflow
  const workflowDefault = {
    id: "",
    name: "Organisation",
    notes: "",
    status: "In-progress",
    step_1: false,
    step_2: false,
    step_3: false,
    step_4: false,
    step_5: false,
    step_6: false,
    step_7: false,
    step_8: false,
    step_9: false,
    step_10: false,
  };
  const [newWorkflow, setNewWorkflow] = useState({
    ...workflowDefault,
  });
  const [updatedWorkflow, setUpdatedWorkflow] = useState({
    ...workflowDefault,
  });

  const [workflows, setWorkflows] = useState({});
  const [workflowsInitialised, setWorkflowsInitialised] = useState(false);

  // Reset drawer variables
  const resetDrawerVariables = () => {
    // Form variables
    // To-do
    setTodo({
      ...todoDefault,
    });

    // Workflow
    setNewWorkflow({
      ...workflowDefault,
    });

    setUpdatedWorkflow({
      ...workflowDefault,
    });
  };

  // Filter notifications
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listNotificationsAction({
            ...notificationQuery,
            organisation: activeOrganisation.id,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [activeOrganisation.id, notificationQuery]);

  // Filter to-dos
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listTodosAction({
            ...todoQuery,
            organisation: activeOrganisation.id,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [activeOrganisation.id, todoQuery]);

  // Filter workflows
  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        dispatch(
          listWorkflowsAction({
            ...workflowQuery,
            organisation: activeOrganisation.id,
          })
        ),
      1000
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [activeOrganisation.id, workflowQuery]);

  // Form submission
  // Notification
  // Delete notification
  useEffect(() => {
    if (notificationDelete.success) {
      dispatch(resetDeleteNotificationAction());

      dispatch(
        listNotificationsAction({
          ...notificationQuery,
          organisation: activeOrganisation.id,
        })
      );
    }
    // eslint-disable-next-line
  }, [notificationDelete.success]);

  // Todo
  // Initialise todos
  useEffect(() => {
    if (todoList.count > 0 && !todosInitialised) {
      setTodos(
        Object.assign(
          {},
          ...todoList.todos.map((todo) => ({
            [todo.id]: {
              action: todo.action,
              client_action: todo.client_action,
              client_logo: todo.client_logo,
              client_name: todo.client_name,
              comments: todo.comments,
              contact_action: todo.contact_action,
              contact_name: todo.contact_name,
              contact_profile_photo: todo.contact_profile_photo,
              done: todo.done,
              due: todo.due,
              engagement_action: todo.engagement_action,
              engagement_name: todo.engagement_name,
              id: todo.id,
              manager_name: todo.manager_name,
              manager_profile_photo: todo.manager_profile_photo,
              name: todo.name,
              proposal_action: todo.proposal_action,
              proposal_name: todo.proposal_name,
              status: todo.status,
            },
          }))
        )
      );

      setTodosInitialised(true);
    }
    // eslint-disable-next-line
  }, [todoList.todos]);

  // Update todo
  const updateTodo = () => {
    // Prepare data
    const todo_data = new FormData();

    todo.comments
      ? todo_data.append("comments", todo.comments)
      : todo_data.append("comments", todoDefault.comments);

    todo.done !== null
      ? todo_data.append("done", todo.done)
      : todo_data.append("done", todoDefault.done);

    // Dispatch action
    dispatch(updateTodoAction(todo.id, todo_data));
  };

  useEffect(() => {
    if (todo.id) {
      const timeOutId = setTimeout(() => {
        updateTodo();
      }, 1000);

      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [todo]);

  useEffect(() => {
    if (todoUpdate.success) {
      dispatch(resetUpdateTodoAction());

      dispatch(
        listTodosAction({ ...todoQuery, organisation: activeOrganisation.id })
      );

      resetDrawerVariables();
    }
    // eslint-disable-next-line
  }, [todoUpdate.success]);

  // Workflow
  // Initialise workflows
  useEffect(() => {
    if (workflowList.count > 0 && !workflowsInitialised) {
      setWorkflows(
        Object.assign(
          {},
          ...workflowList.workflows.map((workflow) => ({
            [workflow.id]: {
              id: workflow.id,
              name: workflow.name,
              notes: workflow.notes,
              organisation: workflow.organisation,
              status: workflow.status,
              step_1: workflow.step_1,
              step_2: workflow.step_2,
              step_3: workflow.step_3,
              step_4: workflow.step_4,
              step_5: workflow.step_5,
              step_6: workflow.step_6,
              step_7: workflow.step_7,
              step_8: workflow.step_8,
              step_9: workflow.step_9,
              step_10: workflow.step_10,
            },
          }))
        )
      );

      setWorkflowsInitialised(true);
    }
    // eslint-disable-next-line
  }, [workflowList.workflows]);

  // Create workflow
  const createWorkflow = () => {
    // Prepare data
    const workflow_data = new FormData();

    newWorkflow.name
      ? workflow_data.append("name", newWorkflow.name)
      : workflow_data.append("name", workflowDefault.name);

    workflow_data.append("organisation", activeOrganisation.id);

    // Dispatch action
    dispatch(createWorkflowAction(workflow_data));
  };

  useEffect(() => {
    if (workflowCreate.success) {
      dispatch(resetCreateWorkflowAction());

      dispatch(
        listWorkflowsAction({
          ...workflowQuery,
          organisation: activeOrganisation.id,
        })
      );

      resetDrawerVariables();
    }
    // eslint-disable-next-line
  }, [workflowCreate.success]);

  // Delete workflow
  const deleteWorkflow = (id) => {
    // Dispatch action
    dispatch(deleteWorkflowAction(id));
  };

  useEffect(() => {
    if (workflowDelete.success) {
      dispatch(resetDeleteWorkflowAction());

      dispatch(
        listWorkflowsAction({
          ...workflowQuery,
          organisation: activeOrganisation.id,
        })
      );

      resetDrawerVariables();
    }
    // eslint-disable-next-line
  }, [workflowDelete.success]);

  // Update workflow
  const updateWorkflow = () => {
    // Prepare data
    const workflow_data = new FormData();

    updatedWorkflow.notes
      ? workflow_data.append("notes", updatedWorkflow.notes)
      : workflow_data.append("notes", workflowDefault.notes);

    updatedWorkflow.step_1 !== null
      ? workflow_data.append("step_1", updatedWorkflow.step_1)
      : workflow_data.append("step_1", workflowDefault.step_1);

    updatedWorkflow.step_2 !== null
      ? workflow_data.append("step_2", updatedWorkflow.step_2)
      : workflow_data.append("step_2", workflowDefault.step_2);

    updatedWorkflow.step_3 !== null
      ? workflow_data.append("step_3", updatedWorkflow.step_3)
      : workflow_data.append("step_3", workflowDefault.step_3);

    updatedWorkflow.step_4 !== null
      ? workflow_data.append("step_4", updatedWorkflow.step_4)
      : workflow_data.append("step_4", workflowDefault.step_4);

    updatedWorkflow.step_5 !== null
      ? workflow_data.append("step_5", updatedWorkflow.step_5)
      : workflow_data.append("step_5", workflowDefault.step_5);

    updatedWorkflow.step_6 !== null
      ? workflow_data.append("step_6", updatedWorkflow.step_6)
      : workflow_data.append("step_6", workflowDefault.step_6);

    updatedWorkflow.step_7 !== null
      ? workflow_data.append("step_7", updatedWorkflow.step_7)
      : workflow_data.append("step_7", workflowDefault.step_7);

    updatedWorkflow.step_8 !== null
      ? workflow_data.append("step_8", updatedWorkflow.step_8)
      : workflow_data.append("step_8", workflowDefault.step_8);

    updatedWorkflow.step_9 !== null
      ? workflow_data.append("step_9", updatedWorkflow.step_9)
      : workflow_data.append("step_9", workflowDefault.step_9);

    updatedWorkflow.step_10 !== null
      ? workflow_data.append("step_10", updatedWorkflow.step_10)
      : workflow_data.append("step_10", workflowDefault.step_10);

    updatedWorkflow.status
      ? workflow_data.append("status", updatedWorkflow.status)
      : workflow_data.append("status", workflowDefault.status);

    // Dispatch action
    dispatch(updateWorkflowAction(updatedWorkflow.id, workflow_data));
  };

  useEffect(() => {
    if (updatedWorkflow.id) {
      const timeOutId = setTimeout(() => {
        updateWorkflow();
      }, 1000);

      return () => clearTimeout(timeOutId);
    }

    // eslint-disable-next-line
  }, [updatedWorkflow]);

  useEffect(() => {
    if (workflowUpdate.success) {
      dispatch(resetUpdateWorkflowAction());

      dispatch(
        listWorkflowsAction({
          ...workflowQuery,
          organisation: activeOrganisation.id,
        })
      );

      resetDrawerVariables();
    }
    // eslint-disable-next-line
  }, [workflowUpdate.success]);

  // Get notification icon
  const getNotificationIcon = (type) => {
    switch (type) {
      case "case study":
        return {
          darkColor: Colors.VERMILION5,
          icon: "book",
          lightColor: Colors.VERMILION2,
        };

      case "client":
        return {
          darkColor: Colors.FOREST5,
          icon: "office",
          lightColor: Colors.FOREST2,
        };

      case "contact":
        return {
          darkColor: Colors.FOREST5,
          icon: "id-number",
          lightColor: Colors.FOREST2,
        };

      case "engagement":
        return {
          darkColor: Colors.LIME5,
          icon: "gantt-chart",
          lightColor: Colors.LIME2,
        };

      case "insight":
        return {
          darkColor: Colors.VERMILION5,
          icon: "lightbulb",
          lightColor: Colors.VERMILION2,
        };

      case "invoice":
        return {
          darkColor: Colors.LIME5,
          icon: "dollar",
          lightColor: Colors.LIME2,
        };

      case "market":
        return {
          darkColor: Colors.TURQUOISE5,
          icon: "heatmap",
          lightColor: Colors.TURQUOISE2,
        };

      case "proposal":
        return {
          darkColor: Colors.FOREST5,
          icon: "manual",
          lightColor: Colors.FOREST2,
        };

      case "role":
        return {
          darkColor: Colors.INDIGO5,
          icon: "hat",
          lightColor: Colors.INDIGO2,
        };

      case "service":
        return {
          darkColor: Colors.INDIGO5,
          icon: "heat-grid",
          lightColor: Colors.INDIGO2,
        };

      case "staff":
        return {
          darkColor: Colors.TURQUOISE5,
          icon: "people",
          lightColor: Colors.TURQUOISE2,
        };

      default:
        return {
          darkColor: "#A5D9F3",
          icon: "notifications",
          lightColor: "#1891AC",
        };
    }
  };

  // Chat
  // Mode
  const [mode, setMode] = useState("help");

  // Websocket
  const [message, setMessage] = useState("");
  const [messageHistory, setMessageHistory] = useState([]);

  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      if (userLogin.user) {
        resolve(`${BACKEND_WS_URL}/api/v1/feed/${userLogin.user}/`);
      }
    });
  }, [userLogin.user]);

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    getSocketUrl,
    {
      shouldReconnect: (closeEvent) => true,
    }
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    if (lastJsonMessage !== null) {
      lastJsonMessage.message = parse(marked(lastJsonMessage.message));

      setMessageHistory((prev) => prev.concat(lastJsonMessage));
    }
  }, [lastJsonMessage, setMessageHistory]);

  // Scroll to bottom of feed and remove spinner
  const bottomRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (lastJsonMessage) {
        lastJsonMessage.type === "system_update" && setLoading(false);
      }

      bottomRef.current?.scrollIntoView(false);
    }, 1000);

    return () => clearTimeout(timeOutId);
  }, [lastJsonMessage]);

  // Remove spinner if connection fails (so user stops waiting for response)
  useEffect(() => {
    if (connectionStatus !== "Open") {
      setLoading(false);
    }
  }, [connectionStatus]);

  // JSX UI code
  return (
    <div>
      {/* Top of page */}
      <div ref={topOfPage}></div>

      <Drawer
        className={!lightMode ? Classes.DARK : undefined}
        isOpen={showActionSidebar}
        onClose={switchActionSidebarMode}
        onOpened={() => {
          dispatch(checkSubscriptionAction(activeOrganisation.id));

          dispatch(
            listNotificationsAction({
              ...notificationQuery,
            })
          );

          setTodosInitialised(false);

          dispatch(
            listTodosAction({
              ...todoQuery,
            })
          );

          setWorkflowsInitialised(false);

          dispatch(
            listWorkflowsAction({
              ...workflowQuery,
            })
          );

          bottomRef.current?.scrollIntoView(false);
        }}
        position={Position.RIGHT}
        size={800}
        title={
          <Tabs
            id="action-sidebar-tabs"
            onChange={(newTabId) => {
              setSelectedTab(newTabId);
            }}
            selectedTabId={selectedTab}
          >
            {/* Ask me a question */}
            <Tab
              id="chat-tab"
              title={
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "40px",
                  }}
                >
                  <IconSquare
                    darkBackground={
                      selectedTab === "chat-tab" ? Colors.DARK_GRAY5 : undefined
                    }
                    darkColor={
                      selectedTab === "chat-tab" ? Colors.BLUE5 : undefined
                    }
                    icon="chat"
                    lightBackground={
                      selectedTab === "chat-tab"
                        ? Colors.LIGHT_GRAY5
                        : undefined
                    }
                    lightColor={
                      selectedTab === "chat-tab" ? Colors.BLUE2 : undefined
                    }
                  />

                  <div style={{ marginLeft: "-5px" }}>Ask me a question</div>
                </div>
              }
            />

            {/* Notifications */}
            <Tab
              id="notifications-tab"
              title={
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "40px",
                  }}
                >
                  <IconSquare
                    darkBackground={
                      selectedTab === "notifications-tab"
                        ? Colors.DARK_GRAY5
                        : undefined
                    }
                    darkColor={
                      selectedTab === "notifications-tab"
                        ? Colors.BLUE5
                        : undefined
                    }
                    icon="notifications"
                    lightBackground={
                      selectedTab === "notifications-tab"
                        ? Colors.LIGHT_GRAY5
                        : undefined
                    }
                    lightColor={
                      selectedTab === "notifications-tab"
                        ? Colors.BLUE2
                        : undefined
                    }
                  />

                  <div style={{ marginLeft: "-5px" }}>Notifications</div>
                </div>
              }
            />

            {/* Actions */}
            <Tab
              id="todo-tab"
              title={
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "40px",
                  }}
                >
                  <IconSquare
                    darkBackground={
                      selectedTab === "todo-tab" ? Colors.DARK_GRAY5 : undefined
                    }
                    darkColor={
                      selectedTab === "todo-tab" ? Colors.BLUE5 : undefined
                    }
                    icon="build"
                    lightBackground={
                      selectedTab === "todo-tab"
                        ? Colors.LIGHT_GRAY5
                        : undefined
                    }
                    lightColor={
                      selectedTab === "todo-tab" ? Colors.BLUE2 : undefined
                    }
                  />

                  <div style={{ marginLeft: "-5px" }}>Actions</div>
                </div>
              }
            />

            {/* Workflows */}
            <Tab
              id="workflows-tab"
              title={
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    height: "40px",
                  }}
                >
                  <IconSquare
                    darkBackground={
                      selectedTab === "workflows-tab"
                        ? Colors.DARK_GRAY5
                        : undefined
                    }
                    darkColor={
                      selectedTab === "workflows-tab" ? Colors.BLUE5 : undefined
                    }
                    icon="flows"
                    lightBackground={
                      selectedTab === "workflows-tab"
                        ? Colors.LIGHT_GRAY5
                        : undefined
                    }
                    lightColor={
                      selectedTab === "workflows-tab" ? Colors.BLUE2 : undefined
                    }
                  />

                  <div style={{ marginLeft: "-5px" }}>Workflows</div>
                </div>
              }
            />
          </Tabs>
        }
      >
        <div
          className={Classes.DRAWER_BODY}
          style={{
            background: lightMode ? Colors.LIGHT_GRAY3 : Colors.DARK_GRAY5,
            padding: "0 20px 20px 20px",
          }}
        >
          <Tabs id="action-sidebar-tabs" selectedTabId={selectedTab}>
            {/* Ask me a question */}
            <Tab
              id="chat-tab"
              panel={
                <div
                  style={{
                    height: "calc(100vh - 120px)",
                    marginBottom: "-10px",
                  }}
                >
                  {/* Connection status */}
                  {connectionStatus !== "Open" && (
                    <Callout intent="danger">
                      Attempting to establish a live connection. Please wait a
                      few seconds or refresh the page.
                    </Callout>
                  )}

                  {/* Feed */}
                  <div
                    style={{
                      height:
                        connectionStatus !== "Open"
                          ? "calc(100% - 140px )"
                          : "calc(100% - 55px)",
                      margin:
                        connectionStatus !== "Open"
                          ? "15px -20px 15px 0"
                          : "0 -20px 15px 0",
                      overflowY: "auto",
                      paddingRight: "20px",
                    }}
                  >
                    {checkSubscription.subscription.has_ai_credits ? (
                      messageHistory.length > 0 ? (
                        messageHistory.map((message, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: "flex",
                                marginTop: i === 0 ? "0" : "15px",
                                justifyContent:
                                  message.user === "System" ? "end" : "start",
                              }}
                            >
                              <div>
                                <Callout
                                  icon={null}
                                  intent={
                                    message.user === "System"
                                      ? "success"
                                      : "primary"
                                  }
                                  style={{
                                    padding: "10px",
                                    width: "600px",
                                  }}
                                >
                                  <div
                                    style={{
                                      alignItems: "center",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <Text ellipsize>
                                      <strong>
                                        {message.user === "System"
                                          ? "advaise.app"
                                          : message.user}
                                      </strong>
                                    </Text>

                                    <CopyButton
                                      content={message.message}
                                      intent={
                                        message.user === "System"
                                          ? "success"
                                          : "primary"
                                      }
                                    />
                                  </div>

                                  <div>{message.message}</div>
                                </Callout>

                                <div
                                  className={[
                                    Classes.TEXT_MUTED,
                                    Classes.TEXT_SMALL,
                                  ].join(" ")}
                                  style={{
                                    margin: "3px 0 0 5px",
                                  }}
                                >
                                  {new Date(
                                    message.datetime
                                  ).toLocaleDateString(undefined, {
                                    day: "numeric",
                                    month: "short",
                                    weekday: "short",
                                  })}{" "}
                                  at{" "}
                                  {new Date(
                                    message.datetime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            height: "calc(100vh - 140px)",
                          }}
                        >
                          <NonIdealState
                            icon="chat"
                            description={
                              <LinkTag
                                intent="primary"
                                label="Help docs"
                                onClick={() =>
                                  window.open(
                                    `https://help.advaise.app`,
                                    "_blank",
                                    "noopener,noreferrer"
                                  )
                                }
                              />
                            }
                            title="Ask me a question"
                          />
                        </div>
                      )
                    ) : (
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          height: "calc(100vh - 200px)",
                        }}
                      >
                        <NonIdealState
                          description="You may need to purchase additional seats or AI credits"
                          icon="warning-sign"
                          title="You don't have access to AI services"
                        />
                      </div>
                    )}

                    <div ref={bottomRef}></div>
                  </div>

                  {/* Form */}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <ControlGroup>
                      <Popover2
                        content={
                          <Menu>
                            <MenuItem
                              icon="help"
                              onClick={() => {
                                setMode("help");
                              }}
                              selected={mode === "help"}
                              shouldDismissPopover={false}
                              text="Ask for help"
                            />

                            <MenuItem
                              icon="book"
                              onClick={() => {
                                setMode("answer");
                              }}
                              selected={mode === "answer"}
                              shouldDismissPopover={false}
                              text="Query documents"
                            />
                          </Menu>
                        }
                        placement="bottom"
                      >
                        <Button
                          disabled={
                            connectionStatus !== "Open" ||
                            !checkSubscription.subscription.has_ai_credits
                          }
                          icon={mode === "help" ? "help" : "book"}
                          large
                          style={{ width: "30px" }}
                        />
                      </Popover2>

                      <InputGroup
                        autoFocus
                        disabled={
                          connectionStatus !== "Open" ||
                          !checkSubscription.subscription.has_ai_credits
                        }
                        fill
                        large
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder={
                          mode === "answer"
                            ? "I can answer questions using your organisation's knowledge base. Ask me a question."
                            : "I can help you to use advaise.app productively. Ask me a question."
                        }
                        rightElement={loading && <Spinner size={16} />}
                        value={message}
                      />

                      <Button
                        disabled={
                          connectionStatus !== "Open" ||
                          !checkSubscription.subscription.has_ai_credits ||
                          message === ""
                        }
                        icon="send-message"
                        large
                        onClick={() => {
                          setLoading(true);

                          sendJsonMessage({
                            kb: mode === "answer" ? activeOrganisation.id : "",
                            message: message,
                            organisation: activeOrganisation.id,
                          });

                          setMessage("");
                        }}
                        type="submit"
                      />
                    </ControlGroup>
                  </form>
                </div>
              }
            />

            {/* Notifications */}
            <Tab
              id="notifications-tab"
              panel={
                <div>
                  {/* Notifications loading error */}
                  {notificationList.error && (
                    <ErrorAlert message="We couldn't load the notification list. Try refreshing the page." />
                  )}

                  {/* Content */}
                  {notificationList.count > 0 &&
                    notificationList.notifications.map((notification) => {
                      return (
                        <div
                          key={notification.id}
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <ItemCard
                            content={
                              <div
                                className={[
                                  Classes.TEXT_MUTED,
                                  Classes.TEXT_SMALL,
                                ].join(" ")}
                              >
                                {notification.content}
                              </div>
                            }
                            darkColor={
                              getNotificationIcon(notification.type).darkColor
                            }
                            fill
                            flexHeight
                            lightColor={
                              getNotificationIcon(notification.type).lightColor
                            }
                            title={
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={
                                      getNotificationIcon(notification.type)
                                        .darkColor
                                    }
                                    icon={
                                      getNotificationIcon(notification.type)
                                        .icon
                                    }
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={
                                      getNotificationIcon(notification.type)
                                        .lightColor
                                    }
                                  />

                                  <div style={{ marginLeft: "10px" }}>
                                    {notification.title}
                                  </div>
                                </div>

                                <Button
                                  icon="small-cross"
                                  minimal
                                  onClick={() => {
                                    dispatch(
                                      deleteNotificationAction(notification.id)
                                    );
                                  }}
                                  small
                                />
                              </div>
                            }
                          />
                        </div>
                      );
                    })}

                  {/* No content */}
                  {notificationList.count === 0 &&
                    !notificationList.loading && (
                      <div
                        style={{
                          alignItems: "center",
                          display: "flex",
                          height: "calc(100vh - 90px)",
                        }}
                      >
                        <NonIdealState
                          description="The system will notify you of events related to your work."
                          icon="search"
                          title="You have no notifications"
                        />
                      </div>
                    )}

                  {/* Loading */}
                  {notificationList.count === 0 && notificationList.loading && (
                    <Card
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      <div className={Classes.SKELETON}>
                        XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                      </div>
                    </Card>
                  )}
                </div>
              }
            />

            {/* To-do */}
            <Tab
              id="todo-tab"
              panel={
                <div>
                  {/* Todos loading error */}
                  {todoList.error && (
                    <ErrorAlert message="We couldn't load the action list. Try refreshing the page." />
                  )}

                  {/* Menu */}
                  <div
                    style={{
                      display: "flex",

                      margin: "10px 0 25px 20px",
                    }}
                  >
                    {/* Range */}
                    <div style={{ alignItems: "center", display: "flex" }}>
                      <div style={{ margin: "0 10px 0 0" }}>Range</div>

                      <HTMLSelect
                        id="rangeField"
                        onChange={(e) => {
                          setTodosInitialised(false);

                          setTodoQuery((prevState) => {
                            return {
                              ...prevState,
                              daysAhead: e.target.value,
                            };
                          });
                        }}
                        options={[
                          { label: "One week", value: 7 },
                          { label: "Two weeks", value: 14 },
                          { label: "Four weeks", value: 28 },
                          { label: "Twelve weeks", value: 84 },
                        ]}
                        value={todoQuery.daysAhead}
                      />
                    </div>

                    {/* Category */}
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginLeft: "20px",
                      }}
                    >
                      <div style={{ margin: "0 10px 0 0" }}>Category</div>

                      <HTMLSelect
                        id="rangeField"
                        onChange={(e) => {
                          setTodosInitialised(false);

                          setTodoQuery((prevState) => {
                            return {
                              ...prevState,
                              category: e.target.value,
                            };
                          });
                        }}
                        options={[
                          { label: "All", value: "" },
                          { label: "Account Development", value: "client" },
                          {
                            label: "Relationship Management",
                            value: "contact",
                          },
                          { label: "Proposal Management", value: "proposal" },
                          { label: "Engagement Delivery", value: "engagement" },
                        ]}
                        value={todoQuery.category}
                      />
                    </div>

                    {/* Status */}
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginLeft: "20px",
                      }}
                    >
                      <div style={{ margin: "0 10px 0 0" }}>Status</div>

                      <HTMLSelect
                        id="rangeField"
                        onChange={(e) => {
                          setTodosInitialised(false);

                          setTodoQuery((prevState) => {
                            return {
                              ...prevState,
                              done: e.target.value,
                            };
                          });
                        }}
                        options={[
                          { label: "All", value: "" },
                          { label: "Ready to close", value: "true" },
                          {
                            label: "Working on it",
                            value: "false",
                          },
                        ]}
                        value={todoQuery.done}
                      />
                    </div>
                  </div>

                  {/* Content */}
                  {todoList.count > 0 &&
                    Object.keys(todos).map((todo_key) => {
                      return (
                        <div
                          key={todo_key}
                          style={{ margin: "0 5px 10px 5px" }}
                        >
                          <TabSectionFieldCollapse
                            bottom
                            content={
                              <>
                                <TabSectionField
                                  label={
                                    todos[todo_key].client_action
                                      ? "Client"
                                      : todos[todo_key].contact_action
                                      ? "Contact"
                                      : todos[todo_key].engagement_action
                                      ? "Engagement"
                                      : "Proposal"
                                  }
                                  value={
                                    todos[todo_key].client_action ? (
                                      <NameValue
                                        image={todos[todo_key].client_logo}
                                        logo
                                        text={todos[todo_key].client_name}
                                      />
                                    ) : todos[todo_key].contact_action ? (
                                      <NameValue
                                        image={
                                          todos[todo_key].contact_profile_photo
                                        }
                                        text={todos[todo_key].contact_name}
                                      />
                                    ) : todos[todo_key].engagement_action ? (
                                      todos[todo_key].engagement_name
                                    ) : (
                                      todos[todo_key].proposal_name
                                    )
                                  }
                                  vertical
                                />

                                <TabSectionField
                                  label="Action"
                                  value={todos[todo_key].action}
                                  vertical
                                />

                                <TabSectionField
                                  label="Due"
                                  value={
                                    <DateValue date={todos[todo_key].due} />
                                  }
                                  vertical
                                />

                                <TabSectionField
                                  label="Manager"
                                  value={
                                    <NameValue
                                      image={
                                        todos[todo_key].manager_profile_photo
                                      }
                                      text={todos[todo_key].manager_name}
                                    />
                                  }
                                  vertical
                                />

                                <TabSectionField
                                  bottom
                                  label="Comments"
                                  value={
                                    <TextArea
                                      fill
                                      onChange={(e) => {
                                        const newTodo = {
                                          ...todos[todo_key],
                                          comments: e.target.value,
                                        };

                                        setTodos((prevState) => {
                                          return {
                                            ...prevState,
                                            [todo_key]: newTodo,
                                          };
                                        });

                                        setTodo(newTodo);
                                      }}
                                      placeholder="Enter your comments"
                                      style={{
                                        height: "100px",
                                        resize: "none",
                                      }}
                                      value={todos[todo_key].comments}
                                    />
                                  }
                                  vertical
                                />
                              </>
                            }
                            tag={
                              todos[todo_key].done
                                ? "Ready to close"
                                : "Working on it"
                            }
                            tagIntent={
                              todos[todo_key].done ? "success" : "primary"
                            }
                            title={
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "-10px",
                                }}
                              >
                                <Checkbox
                                  checked={todos[todo_key].done}
                                  disabled={todoUpdate.loading}
                                  onChange={() => {
                                    const newTodo = {
                                      ...todos[todo_key],
                                      done: !todos[todo_key].done,
                                    };

                                    setTodos((prevState) => {
                                      return {
                                        ...prevState,
                                        [todo_key]: newTodo,
                                      };
                                    });

                                    setTodo(newTodo);
                                  }}
                                />

                                <Text
                                  ellipsize
                                  style={{
                                    textDecoration: todos[todo_key].done
                                      ? "line-through"
                                      : undefined,
                                  }}
                                >
                                  {todos[todo_key].name}
                                </Text>
                              </div>
                            }
                          />
                        </div>
                      );
                    })}

                  {/* No content */}
                  {todoList.count === 0 && !todoList.loading && (
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        height: "calc(100vh - 200px)",
                      }}
                    >
                      <NonIdealState
                        description="The system will let you know when you have actions assigned."
                        icon="search"
                        title="You have no actions"
                      />
                    </div>
                  )}

                  {/* Loading */}
                  {todoList.count === 0 && todoList.loading && (
                    <Card
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      <div className={Classes.SKELETON}>
                        XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                      </div>
                    </Card>
                  )}
                </div>
              }
            />

            {/* Workflows */}
            <Tab
              id="workflows-tab"
              panel={
                <div>
                  {/* Workflows loading error */}
                  {workflowList.error && (
                    <ErrorAlert message="We couldn't load the workflow list. Try refreshing the page." />
                  )}

                  {/* Menu */}
                  <div
                    style={{
                      display: "flex",

                      margin: "10px 0 25px 20px",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {/* Start workflow */}
                      <div style={{ margin: "0 10px 0 0" }}>Start workflow</div>

                      <ControlGroup>
                        <HTMLSelect
                          id="rangeField"
                          onChange={(e) => {
                            setNewWorkflow({
                              name: e.target.value,
                            });
                          }}
                          options={[
                            {
                              label: "Set up your organization",
                              value: "Organisation",
                            },
                            {
                              label: "Shape the firm's strategy",
                              value: "Strategy",
                            },
                            {
                              label: "Build the team",
                              value: "Recruitment",
                            },
                            {
                              label: "Establish a high-performing team",
                              value: "Performance",
                            },
                            {
                              label: "Create thought leadership content",
                              value: "Content",
                            },
                            {
                              label: "Develop a client account",
                              value: "Account",
                            },
                            { label: "Generate a lead", value: "Contact" },
                            {
                              label: "Create a winning proposal",
                              value: "Sales",
                            },
                            {
                              label: "Deliver a successful engagement",
                              value: "Delivery",
                            },
                            {
                              label: "Collect fees",
                              value: "Invoicing",
                            },
                          ]}
                          value={newWorkflow.name}
                        />

                        <Button
                          icon="play"
                          intent="primary"
                          onClick={() => {
                            setWorkflowsInitialised(false);

                            createWorkflow();
                          }}
                        />
                      </ControlGroup>
                    </div>

                    {/* Status */}
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginLeft: "20px",
                      }}
                    >
                      <div style={{ margin: "0 10px 0 0" }}>Status</div>

                      <HTMLSelect
                        id="statusField"
                        onChange={(e) => {
                          setWorkflowsInitialised(false);

                          setWorkflowQuery((prevState) => {
                            return {
                              ...prevState,
                              status: e.target.value,
                            };
                          });
                        }}
                        options={[
                          { label: "All", value: "" },
                          { label: "Completed", value: "Completed" },
                          { label: "In-progress", value: "In-progress" },
                        ]}
                        value={workflowQuery.status}
                      />
                    </div>
                  </div>

                  {/* Content */}
                  {workflowList.count > 0 &&
                    Object.keys(workflows).map((workflow_key) => {
                      switch (workflows[workflow_key].name) {
                        case "Account":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Boost the potential for revenue growth by managing accounts strategically. Follow a repeatable approach to develop key accounts, focusing on understanding and meeting client requirements. Identify particular revenue streams and formulate comprehensive strategies to interact with key decision-makers. You may wish to arrange a workshop with other leaders to develop the account plan."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Sales modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will use the Clients module in
                                              this workflow. Confirm that you
                                              have access before starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create the client account
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Clients"
                                                onClick={() => {
                                                  navigate(`/clients`);
                                                }}
                                              />{" "}
                                              module and create a new client
                                              account. If the client is a listed
                                              company and you enter its ticker
                                              symbol, advaise.app will try to
                                              download financial data from
                                              third-party sources. If the client
                                              already exists, skip this step.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Research the client
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              On the Research tab, work through
                                              the system-generated questions in
                                              the Client questions section. You
                                              can add or remove questions as
                                              appropriate. Use the Key
                                              performance indicators section to
                                              analyse the client's performance
                                              and identify emerging needs.
                                              Capture these needs in the Needs
                                              section.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Define actions and stakeholders
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              On the Development tab, expand the
                                              Account development actions
                                              section and add actions to
                                              evaluate the needs identified. In
                                              the Strategic relationships
                                              section, add the client
                                              stakeholders that need to be
                                              consulted for this purpose. You
                                              can add information to these
                                              contacts and schedule interviews
                                              with them in the{" "}
                                              <LinkTag
                                                label="Contacts"
                                                onClick={() => {
                                                  navigate(`/contacts`);
                                                }}
                                              />{" "}
                                              module later on by following the
                                              lead generation workflow.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Plan account revenue
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Use your research to identify
                                              specific revenue sources in the
                                              Investment programs section. You
                                              should review your assumptions
                                              about client needs and investments
                                              as you progress consultations with
                                              key stakeholders.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Establish and monitor targets
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Once you are satisfied with the
                                              identified sources of revenue, use
                                              the Approvals section to request
                                              account target approval. You may
                                              wish to cascade these targets to
                                              individuals, using the workflow to
                                              establish a high-performing team.
                                              You can use the data in the
                                              Proposals and Engagements sections
                                              to monitor the account's
                                              performance.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="briefcase"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Develop a client account"
                              />
                            </div>
                          );

                        case "Contact":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Foster a connection with a particular individual at a current or prospective client. Leverage this connection to understand the client’s requirements and areas of investment, and to gain access to important stakeholders. Identify and nurture opportunities for client engagement."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Sales modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will use the Contacts module
                                              in this workflow. Confirm that you
                                              have access before starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create an interview
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Contacts"
                                                onClick={() => {
                                                  navigate(`/contacts`);
                                                }}
                                              />{" "}
                                              module and select the Interviews
                                              tab. Create an interview with the
                                              contact that you are interested in
                                              engaging with.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Review the background information
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the interview and go to the
                                              Planning tab. Review the
                                              background information generated
                                              by the system under the Contact
                                              summary, Client overview and
                                              Market context sections. The
                                              purpose of this information is to
                                              provide up-to-date information on
                                              the contact, their organisation
                                              and the environment that they
                                              operate within.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Plan the interview
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Questions section, add the
                                              topics you would like to discuss
                                              with the contact. You can select
                                              questions designed to diagnose
                                              specific needs (from the Needs
                                              module) or create new questions.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Conduct the interview
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Arrange to meet the contact and
                                              conduct the interview. Capture
                                              your notes in the Answers section,
                                              under the Interview tab.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Evaluate opportunities
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Review the output of the
                                              interview. You can download and
                                              share your interview notes if
                                              needed. Capture any leads
                                              generated in the Analysis section.
                                              In addition, you may wish to enter
                                              an action in the contact's record
                                              to follow up on your discussion.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="new-link"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Generate a lead"
                              />
                            </div>
                          );

                        case "Content":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Create content that can be disseminated via multiple channels to showcase expertise and cross-pollinate insights throughout the firm. Develop articles, case studies, and social media updates drawing from the organization’s knowledge base. This workflow will assist you in crafting top-tier thought-leadership content with the aid of AI."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Content modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will either use the Case
                                              Studies or Insights module in this
                                              workflow. Confirm that you have
                                              access before starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Update the organization's
                                              knowledge base
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Ensure the firm's knowledge base
                                              contains extensive material
                                              covering the topic you want to
                                              write about. For example,
                                              articles, industry reports or
                                              white papers. The system is also
                                              capable of extracting information
                                              from project deliverables.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create case study or insight
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Case Studies"
                                                onClick={() => {
                                                  navigate(`/caseStudies`);
                                                }}
                                              />{" "}
                                              module or the{" "}
                                              <LinkTag
                                                label="Insights"
                                                onClick={() => {
                                                  navigate(`/insights`);
                                                }}
                                              />{" "}
                                              module to create a piece of
                                              content. A case study is a summary
                                              of a successful engagement that
                                              can be referenced in proposals. An
                                              insight expresses the firm's point
                                              of view about an opportunity or
                                              problem facing clients.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Summarize the key points
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the record you created. In
                                              the Administration tab, expand the
                                              Summary section and summarize the
                                              key points of the case
                                              study/insight in the Description
                                              field. Enter as much detail as
                                              possible, as advaise.app will use
                                              your summary to select relevant
                                              information from the knowledge
                                              base.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Review the content
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Detail tab, click on the
                                              Generate button. The system will
                                              write the case study/insight using
                                              information available. Expand the
                                              description and knowledge base if
                                              needed, as they significantly
                                              impact the result. You can edit
                                              the system-generated content if
                                              needed.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Share the case study or insight
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Once you are satisfied with the
                                              content, change the status of the
                                              case study/insight to Approved. If
                                              you created a case study, team
                                              members can include it in
                                              proposals. If you created an
                                              insight, they can share it with
                                              clients and colleagues to generate
                                              more leads and lift capability.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="lightbulb"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Create thought leadership content"
                              />
                            </div>
                          );

                        case "Delivery":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Plan and deliver a successful client engagement. Smoothly progress from the proposal phase to the implementation stage. Adhere to project management standards to fulfill contract obligations, exceed client expectations, and achieve anticipated profits."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Delivery modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will be using the Engagements
                                              module in this workflow. Confirm
                                              that you have access before
                                              starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create the engagement
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Engagements"
                                                onClick={() => {
                                                  navigate(`/engagements`);
                                                }}
                                              />{" "}
                                              module and create a new
                                              engagement. Create the engagement
                                              from a proposal if appropriate.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Define the engagement strategy
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Assemble the engagement team and
                                              complete the Strategy tab.
                                              Evaluate the client's needs.
                                              Identify key stakeholders and
                                              define plans to involve them in
                                              the engagement. Summarize the
                                              overall approach to deliver on
                                              client expectations.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Plan the work
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Work through the Plan tab, adding
                                              services and deliverables.
                                              Schedule activities per the
                                              expected timelines. Add risks and
                                              dependencies under the RAID tab.
                                              If you created the engagement from
                                              a proposal, review the information
                                              imported.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Estimate expenses
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Resourcing tab, add labour
                                              and non-labour expenses. If you
                                              imported information from a
                                              proposal, these sections may
                                              already contain resource data.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Review the fees
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Financials tab, review the
                                              system-calculated fees. You may
                                              add payment milestones and a rate
                                              card as appropriate. If the
                                              engagement was created from a
                                              proposal, the system may have
                                              prefilled these sections.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_7
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_7:
                                                    !workflows[workflow_key]
                                                      .step_7,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_7
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Finalise the Engagement Charter
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Review the system-generated
                                              document under the Charter tab.
                                              You can download the document in
                                              pdf format based on the selected
                                              template, or as a plain docx file.
                                              When you are ready, you can
                                              request approval to baseline the
                                              engagement in the Approvals
                                              section under the Status tab.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_8
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_8:
                                                    !workflows[workflow_key]
                                                      .step_8,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_8
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Plan status updates
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Schedule regular updates with
                                              client stakeholders and team
                                              members. Once baselined, you can
                                              monitor the engagement and
                                              generate status reports in the
                                              Status tab. Capture risk, action,
                                              issue and dependency updates in
                                              the RAID tab.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_9
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_9:
                                                    !workflows[workflow_key]
                                                      .step_9,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_9
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Capture lessons learned
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Close tab, add lessons
                                              learned to facilitate a PIR at the
                                              end of the engagement.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="gantt-chart"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Deliver a successful engagement"
                              />
                            </div>
                          );

                        case "Invoicing":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Manage collections to optimize engagement cashflow. Automatically add fees to invoices based on completed payment milestones and approved timesheets and expenses. Monitor and follow up on outstanding invoices."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Delivery modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will be using the Invoices
                                              module in this workflow. Confirm
                                              that you have access before
                                              starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Generate the invoice
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Invoices"
                                                onClick={() => {
                                                  navigate(`/invoices`);
                                                }}
                                              />{" "}
                                              module and create a new invoice
                                              against the appropriate booking
                                              code.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Review the details
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the invoice and click on the
                                              Details tab to review the invoice
                                              details. The system automatically
                                              generates fees for completed
                                              payment milestones, as well as
                                              approved timesheets and expenses.
                                              If the code is set as Fixed-price,
                                              it generates fees for payment
                                              milestones only. Add additional
                                              items if required.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Finalize the invoice document
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Review the system-generated
                                              document. Once satisfied, request
                                              approval to submit in the
                                              Approvals section.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Monitor payment status
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Issue the invoice and set its
                                              status to Invoiced. Review its due
                                              date in the{" "}
                                              <LinkTag
                                                label="Invoices"
                                                onClick={() => {
                                                  navigate(`/invoices`);
                                                }}
                                              />{" "}
                                              module. Follow up with the client
                                              as appropriate, until the invoice
                                              has been paid. Once the fee has
                                              been collected, set the status to
                                              Paid.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Raise a credit note (optional)
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              If the client disputes the
                                              invoice, you can raise a credit
                                              note by changing its status to
                                              Disputed. The system will generate
                                              a credit note. Open this credit
                                              note and request approval to
                                              submit in the Approvals section.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="dollar"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Collect fees"
                              />
                            </div>
                          );

                        case "Performance":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Establish a well-defined set of expectations for a team member that you manage. Assess and document skills and abilities to facilitate assignment to appropriate roles in proposals and projects. Set developmental objectives to improve employee experience and support high-performance. You may wish to collaborate on this workflow with other managers and the team member affected."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to People modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will be using the Staff module
                                              in this workflow. Confirm that you
                                              have access before starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Add a role and resume
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Staff"
                                                onClick={() => {
                                                  navigate(`/staff`);
                                                }}
                                              />{" "}
                                              module and open the staff record
                                              you are going to work on. On the
                                              Administration tab, expand the
                                              Summary section to assign a role.
                                              This role defines competency
                                              requirements for the team member.
                                              In the same section, upload a
                                              detailed resume. This document
                                              should cover the team member's
                                              full work history and
                                              qualifications, which as
                                              advaise.app can use to evaluate
                                              competencies.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Enter goals and objectives
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Development tab, open the
                                              Goals section and add specific,
                                              measurable, achievable, relevant
                                              and time-bound (SMART) goals.
                                              Specify qualitative and
                                              quantitative measures, such as
                                              client and engagement manager
                                              feedback, sales, engagement margin
                                              or utilisation.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Evaluate competencies
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Expand the Competencies section
                                              and add staff competencies. If the
                                              team member's resume is
                                              sufficiently detailed, advaise.app
                                              can rate them against each
                                              competency.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Add development goals
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Review the team member's role-fit,
                                              using the Competencies chart. In
                                              the Development plan section,
                                              assign learning goals to address
                                              capability gaps.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Schedule check-ins
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Arrange short and frequent
                                              meetings with the team member to
                                              monitor and support progress
                                              toward goals.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="chart"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Establish a high-performing team"
                              />
                            </div>
                          );

                        case "Recruitment":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Recruit high-performing personnel, whose abilities and principles match the firm's needs. Use an impartial, skills-focused method to pinpoint and assess potential hires, rather than solely depending on their past positions and academic background. This procedure ensures a consistent and repeatable strategy for bringing on board talented staff."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to People modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will be using the Roles and
                                              Openings modules in this workflow.
                                              Confirm that you have access
                                              before starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Update the organization's
                                              knowledge base
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              advaise.app is capable of
                                              inferring role specifications from
                                              documents such as service
                                              definitions, proposals and project
                                              plans. Ensure that the knowledge
                                              based includes documents that the
                                              system can rely upon.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create the role
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Roles"
                                                onClick={() => {
                                                  navigate(`/roles`);
                                                }}
                                              />{" "}
                                              module and add the role that you
                                              are hiring for. You will be able
                                              to use standard role information
                                              to hire staff, price proposals and
                                              manage resourcing. If the role
                                              already exists, skip this step.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Specify the requirements
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the role and work through the
                                              the Detail tab. If there is
                                              relevant material in the knowledge
                                              base, advaise.app can generate a
                                              specification based on the role's
                                              description. You may need to ask
                                              an administrator to update the
                                              organization's competency set.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create the opening
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Openings"
                                                onClick={() => {
                                                  navigate(`/openings`);
                                                }}
                                              />{" "}
                                              module and add the job opening.
                                              Link the opening to the role you
                                              created.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Establish the application process
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the job opening. In the
                                              Detail tab, review the position
                                              specification generated by the
                                              system, which you can download and
                                              share through various channels.
                                              You can also direct applicants to
                                              an application form provided by
                                              advaise.app, which you can
                                              configure on the Application form
                                              section.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_7
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_7:
                                                    !workflows[workflow_key]
                                                      .step_7,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_7
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Shortlist applicants
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              If you are using the application
                                              form provided by advaise.app,
                                              submissions will appear under the
                                              Applicants section. If you
                                              shortlist an applicant, the system
                                              will create an evaluation record
                                              for them in the Evaluations
                                              section. You can also manually
                                              create evaluations for candidates
                                              who apply through other channels.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_8
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_8:
                                                    !workflows[workflow_key]
                                                      .step_8,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_8
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Evaluate candidates
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Create or select candidates to
                                              evaluate. The system can evaluate
                                              candidates and provide role-fit
                                              statistics based on resumes. Once
                                              all evaluations are completed,
                                              select the preferred candidate to
                                              hire.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_9
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_9:
                                                    !workflows[workflow_key]
                                                      .step_9,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_9
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Obtain approval to hire
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Use the Approvals section to
                                              request approval to hire the
                                              selected candidate. There must be
                                              at least one manager in the firm
                                              with appropriate decision rights.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="shared-filter"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Build the team"
                              />
                            </div>
                          );

                        case "Sales":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Make an informed decision to pursue an opportunity. Follow a repeatable and proven process covering solution design, engagement planning and pricing. Leverage AI assistance to create a proposal document to the standard of Tier-1 professional services firms."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Sales modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will be using the Proposals
                                              module in this workflow. Confirm
                                              that you have access before
                                              starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create the proposal
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Proposals"
                                                onClick={() => {
                                                  navigate(`/proposals`);
                                                }}
                                              />{" "}
                                              module and create a new proposal.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Plan the proposal
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Management tab, review the
                                              system-generated actions and
                                              questions under Actions and
                                              Research. If you wish to account
                                              for the proposal's cost, you will
                                              need an administrator to create a
                                              booking code for the proposal.
                                              Team members will then be able to
                                              log time and expenses against the
                                              proposal.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Qualify the opportunity
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Work through the Qualification tab
                                              to estimate the likelihood of
                                              success, involving other managers
                                              as required. The system will
                                              generate an Opportunity Brief
                                              document, summarizing the answers
                                              to qualification questions, which
                                              you can distribute as appropriate
                                              (located under the Files tab). If
                                              you would like to continue the
                                              proposal, you may submit a
                                              proposal qualification request
                                              using the Approvals section under
                                              the Management tab.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Define the proposal strategy
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Assemble the proposal team and
                                              complete the Strategy tab.
                                              Evaluate the client's needs, key
                                              stakeholders and competitors. Use
                                              the output of this analysis to
                                              develop win themes. You can
                                              download a system-generated
                                              Strategy Review document in the
                                              Files tab.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Design the solution
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Work through the Solution tab to
                                              detail your offer. You can export
                                              and import responses to
                                              requirements in Excel format if
                                              you need input from people who do
                                              not have access to advaise.app.
                                              Import and customise content from
                                              the firm's standard services as
                                              appropriate (defined in the
                                              Services module). A summary of the
                                              solution is available in the
                                              Solution Review document, under
                                              Files.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_7
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_7:
                                                    !workflows[workflow_key]
                                                      .step_7,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_7
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Assemble the commercials
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Complete the Commercials tab .
                                              Review the system-calculated fees.
                                              You may add payment milestones, a
                                              rate card and fee assumptions as
                                              appropriate. You can upload the
                                              firm's standard Terms and
                                              Conditions using the template
                                              provided under Legal terms. The
                                              commercials are summarised in the
                                              Commercials Review document, under
                                              Files.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_8
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_8:
                                                    !workflows[workflow_key]
                                                      .step_8,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_8
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Finalise the response
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Review the system-generated
                                              document under the Response tab.
                                              You can download the document in
                                              pdf format based on the selected
                                              template, or as a plain docx file.
                                              When you are ready, you can
                                              request approval to submit the
                                              proposal in the Management tab.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_9
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_9:
                                                    !workflows[workflow_key]
                                                      .step_9,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_9
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Capture lessons learned
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              In the Close tab, expand the
                                              Lessons learned section and add
                                              lessons learned. The system
                                              captures these lessons in the
                                              Win/Loss Review document for
                                              sharing across the firm (under
                                              Files).
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="manual"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Create a winning proposal"
                              />
                            </div>
                          );

                        case "Strategy":
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Gain a clear understanding of the kinds of organization you cater to and how your services assist them in accomplishing their objectives. This process should lead you to define one or more markets with identified needs. Upon completion of this workflow, you should have outlined a range of services tailored to meet these needs. You should be prepared to pinpoint specific organizations for potential outreach. You may wish to arrange a strategic planning workshop with other leaders to complete the steps below."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Confirm access to Strategy modules
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              You will be using the Markets and
                                              Services modules in this workflow.
                                              Confirm that you have access
                                              before starting.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Update the organization's
                                              knowledge base
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              advaise.app is capable of
                                              producing content that assists in
                                              assessing market demands and
                                              outlining your firm's offerings.
                                              Confirm that the knowledge base is
                                              stocked with relevant documents.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Add a market
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Markets"
                                                onClick={() => {
                                                  navigate(`/markets`);
                                                }}
                                              />{" "}
                                              module and add a market. A market
                                              is a group of organizations with
                                              common needs that you address.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Specify a need
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the Needs tab on the
                                              Markets page and add a need of the
                                              market you created. A need is an
                                              opportunity or problem facing one
                                              or more markets. Open the need and
                                              enter a set of questions to help
                                              diagnose the need. If there is
                                              relevant content in the knowledge
                                              base, advaise.app can generate
                                              these questions based on the
                                              need's description.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Link the need to the market
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the market you created. In
                                              the Link tab, expand the Needs
                                              section and add the need you
                                              created. You can also link
                                              relevant case studies and insights
                                              to this market if you have any.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Design a service
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Services"
                                                onClick={() => {
                                                  navigate(`/services`);
                                                }}
                                              />{" "}
                                              module and add a service. A
                                              service is a package of work
                                              intented to address one or more
                                              needs.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_7
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_7:
                                                    !workflows[workflow_key]
                                                      .step_7,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_7
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Enter the service definition
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the service you created. Work
                                              through the Definition tab.
                                              advaise.app can generate context,
                                              activities and deliverables from
                                              the knowledge base based on the
                                              service's description.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_8
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_8:
                                                    !workflows[workflow_key]
                                                      .step_8,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_8
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create additional markets, needs
                                              and services
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Repeat the steps above to create a
                                              complete set of markets, needs and
                                              services for your firm.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(
                                            workflows[workflow_key].id
                                          );
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="playbook"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Shape the firm's strategy"
                              />
                            </div>
                          );

                        default:
                          return (
                            <div
                              key={workflow_key}
                              style={{ margin: "0 5px 10px 5px" }}
                            >
                              <TabSectionFieldCollapse
                                bottom
                                content={
                                  <>
                                    <TabSectionField
                                      label="Goal"
                                      value="Set up your organization, including adding members, entering financial assumptions, defining the firm's governance roles, and creating reference data. This is the first step required to use advaise.app productively. As part of this step, you will also load documents into the knowledge base, which will allow the system's AI engine to learn about your organization."
                                      vertical
                                    />

                                    <TabSectionField
                                      label="Steps"
                                      value={
                                        <div>
                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_1
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_1:
                                                    !workflows[workflow_key]
                                                      .step_1,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_1
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Create the organization
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Navigate to the{" "}
                                              <LinkTag
                                                label="Organisations"
                                                onClick={() => {
                                                  navigate(`/organisations`);
                                                }}
                                              />{" "}
                                              module to add a new organization.
                                              Once you have created it, activate
                                              the organization by clicking on
                                              the tag to the right of its name.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_2
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_2:
                                                    !workflows[workflow_key]
                                                      .step_2,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_2
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Add members
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Go to the organization's page. On
                                              the Administration tab, expand the
                                              Memberships section and add a
                                              member with appropriate
                                              permissions. Add additional
                                              members as needed. These members
                                              will not be able to work on the
                                              organisation until you have
                                              assigned seats to them (next
                                              step).
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_3
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_3:
                                                    !workflows[workflow_key]
                                                      .step_3,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_3
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Assign user seats
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              On the Subscriptions tab, expand
                                              the Seats section and add user
                                              seats as required. Assign these
                                              seats to your team members. After
                                              doing so, they will have access to
                                              advaise.app services on behalf of
                                              the organisation.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_4
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_4:
                                                    !workflows[workflow_key]
                                                      .step_4,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflows[workflow_key]
                                                      .id]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_4
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Review financial settings
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the Financials tab and work
                                              through the Settings, Currencies,
                                              Expense categories, Leave
                                              categories and Rank hierarchy
                                              sections.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_5
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_5:
                                                    !workflows[workflow_key]
                                                      .step_5,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_5
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Establish governance roles
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              On the Governance tab, expand the
                                              Approval roles section and add
                                              roles with appropriate decision
                                              rights. Assign these roles to team
                                              members in the Managers section.
                                            </Text>
                                          </div>

                                          <Divider />

                                          <div
                                            style={{
                                              display: "flex",
                                              padding: "10px 0 5px 0",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                workflows[workflow_key].step_6
                                              }
                                              disabled={workflowUpdate.loading}
                                              onChange={() => {
                                                const newWorkflow = {
                                                  ...workflows[workflow_key],
                                                  step_6:
                                                    !workflows[workflow_key]
                                                      .step_6,
                                                };

                                                setWorkflows((prevState) => {
                                                  return {
                                                    ...prevState,
                                                    [workflow_key]: newWorkflow,
                                                  };
                                                });

                                                setUpdatedWorkflow(newWorkflow);
                                              }}
                                            />

                                            <Text
                                              style={{
                                                textDecoration: workflows[
                                                  workflow_key
                                                ].step_6
                                                  ? "line-through"
                                                  : undefined,
                                                width: "200px",
                                              }}
                                            >
                                              Add reference data
                                            </Text>

                                            <Divider
                                              style={{
                                                margin: "0 10px 0 10px",
                                              }}
                                            />

                                            <Text
                                              className={[
                                                Classes.TEXT_MUTED,
                                                Classes.TEXT_SMALL,
                                              ].join(" ")}
                                              style={{ flex: 1 }}
                                            >
                                              Open the Metadata tab and work
                                              through the Knowledge base,
                                              Competencies and Locations
                                              dropdowns. In the knowledge base
                                              section, upload 20 - 30 documents
                                              covering service definitions,
                                              proposals, project plans, case
                                              studies, publications and other
                                              expertise relevant to your firm.
                                              It is important to upload
                                              documents that are current and of
                                              the highest quality, as
                                              advaise.app can use them to
                                              generate content across modules.
                                            </Text>
                                          </div>
                                        </div>
                                      }
                                      vertical
                                    />

                                    <TabSectionField
                                      bottom
                                      label="Notes"
                                      value={
                                        <TextArea
                                          fill
                                          onChange={(e) => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              notes: e.target.value,
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                          placeholder="Enter your notes"
                                          style={{
                                            height: "100px",
                                            resize: "none",
                                          }}
                                          value={workflows[workflow_key].notes}
                                        />
                                      }
                                      vertical
                                    />

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        marginTop: "20px",
                                      }}
                                    >
                                      <Button
                                        icon="trash"
                                        intent="danger"
                                        onClick={() => {
                                          setWorkflowsInitialised(false);

                                          deleteWorkflow(workflow_key);
                                        }}
                                        text="Delete"
                                      />

                                      {workflows[workflow_key].status ===
                                      "Completed" ? (
                                        <Button
                                          icon="undo"
                                          intent="warning"
                                          text="Re-open"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "In-progress",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      ) : (
                                        <Button
                                          icon="endorsed"
                                          intent="success"
                                          text="Complete"
                                          onClick={() => {
                                            const newWorkflow = {
                                              ...workflows[workflow_key],
                                              status: "Completed",
                                            };

                                            setWorkflows((prevState) => {
                                              return {
                                                ...prevState,
                                                [workflow_key]: newWorkflow,
                                              };
                                            });

                                            setUpdatedWorkflow(newWorkflow);
                                          }}
                                        />
                                      )}
                                    </div>
                                  </>
                                }
                                icon={
                                  <IconSquare
                                    darkBackground={Colors.DARK_GRAY4}
                                    darkColor={Colors.BLUE5}
                                    icon="office"
                                    lightBackground={Colors.LIGHT_GRAY4}
                                    lightColor={Colors.BLUE2}
                                  />
                                }
                                tag={workflows[workflow_key].status}
                                tagIntent={
                                  workflows[workflow_key].status === "Completed"
                                    ? "success"
                                    : "primary"
                                }
                                title="Set up your organization"
                              />
                            </div>
                          );
                      }
                    })}

                  {/* No content */}
                  {workflowList.count === 0 && !workflowList.loading && (
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        height: "calc(100vh - 200px)",
                      }}
                    >
                      <NonIdealState
                        description="Start a workflow."
                        icon="search"
                        title="No workflows found"
                      />
                    </div>
                  )}

                  {/* Loading */}
                  {workflowList.count === 0 && workflowList.loading && (
                    <Card
                      style={{
                        borderRadius: "5px",
                      }}
                    >
                      <div className={Classes.SKELETON}>
                        XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
                      </div>
                    </Card>
                  )}
                </div>
              }
            />
          </Tabs>
        </div>

        {selectedTab === "chat-tab" && (
          <div
            className={[Classes.DRAWER_FOOTER]}
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text
              className={Classes.TEXT_SMALL}
              style={{ textAlign: "center" }}
            >
              AI features are experimental. Check important information.
            </Text>

            <LinkTag
              intent="primary"
              label="Help docs"
              onClick={() =>
                window.open(
                  `https://help.advaise.app`,
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            />
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default ActionSidebar;
