// Constant imports
import {
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_LIST_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_RESET,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_REQUEST,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_SUCCESS,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_FAIL,
  ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_RESET,
} from "../constants/engagementServiceDeliverableConstants";

// Data services import
import cortixDataService from "../services/cortix";

// List all
export const listEngagementServiceDeliverablesAction =
  (params) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_LIST_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementServiceDeliverables(
        params,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_LIST_SUCCESS,

        payload: {
          count: data.count,
          engagement_service_deliverables: data.results,
        },
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_LIST_FAIL,

        payload: error,
      });
    }
  };

// Reset list all
export const resetListEngagementServiceDeliverablesAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_LIST_RESET });
  };

// Create
export const createEngagementServiceDeliverableAction =
  (engagement_service_deliverable) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } =
        await cortixDataService.createEngagementServiceDeliverable(
          token,
          engagement_service_deliverable
        );

      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_FAIL,

        payload: error,
      });
    }
  };

// Reset create
export const resetCreateEngagementServiceDeliverableAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_CREATE_RESET });
  };

// List details
export const listEngagementServiceDeliverableDetailsAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } = await cortixDataService.getEngagementServiceDeliverable(
        id,
        token
      );

      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_FAIL,

        payload: error,
      });
    }
  };

// Reset list details
export const resetListEngagementServiceDeliverableDetailsAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_DETAILS_RESET });
  };

// Update
export const updateEngagementServiceDeliverableAction =
  (id, engagement_service_deliverable) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } =
        await cortixDataService.updateEngagementServiceDeliverable(
          id,
          token,
          engagement_service_deliverable
        );

      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_FAIL,

        payload: error,
      });
    }
  };

// Reset update
export const resetUpdateEngagementServiceDeliverableAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_UPDATE_RESET });
  };

// Delete
export const deleteEngagementServiceDeliverableAction =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_REQUEST });

      const {
        userLogin: { token },
      } = getState();

      const { data } =
        await cortixDataService.deleteEngagementServiceDeliverable(id, token);

      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_SUCCESS,

        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_FAIL,

        payload: error,
      });
    }
  };

// Reset delete
export const resetDeleteEngagementServiceDeliverableAction =
  () => async (dispatch) => {
    dispatch({ type: ENGAGEMENT_SERVICE_DELIVERABLE_DELETE_RESET });
  };
