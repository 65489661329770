// Constant imports
import {
  PROPOSAL_LEGAL_SECTION_LIST_REQUEST,
  PROPOSAL_LEGAL_SECTION_LIST_SUCCESS,
  PROPOSAL_LEGAL_SECTION_LIST_FAIL,
  PROPOSAL_LEGAL_SECTION_LIST_RESET,
  PROPOSAL_LEGAL_SECTION_CREATE_REQUEST,
  PROPOSAL_LEGAL_SECTION_CREATE_SUCCESS,
  PROPOSAL_LEGAL_SECTION_CREATE_FAIL,
  PROPOSAL_LEGAL_SECTION_CREATE_RESET,
  PROPOSAL_LEGAL_SECTION_DETAILS_REQUEST,
  PROPOSAL_LEGAL_SECTION_DETAILS_SUCCESS,
  PROPOSAL_LEGAL_SECTION_DETAILS_FAIL,
  PROPOSAL_LEGAL_SECTION_DETAILS_RESET,
  PROPOSAL_LEGAL_SECTION_UPDATE_REQUEST,
  PROPOSAL_LEGAL_SECTION_UPDATE_SUCCESS,
  PROPOSAL_LEGAL_SECTION_UPDATE_FAIL,
  PROPOSAL_LEGAL_SECTION_UPDATE_RESET,
  PROPOSAL_LEGAL_SECTION_DELETE_REQUEST,
  PROPOSAL_LEGAL_SECTION_DELETE_SUCCESS,
  PROPOSAL_LEGAL_SECTION_DELETE_FAIL,
  PROPOSAL_LEGAL_SECTION_DELETE_RESET,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_REQUEST,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_SUCCESS,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_FAIL,
  PROPOSAL_LEGAL_SECTION_DOWNLOAD_RESET,
  PROPOSAL_LEGAL_SECTION_UPLOAD_BEGIN,
  PROPOSAL_LEGAL_SECTION_UPLOAD_REQUEST,
  PROPOSAL_LEGAL_SECTION_UPLOAD_SUCCESS,
  PROPOSAL_LEGAL_SECTION_UPLOAD_FAIL,
  PROPOSAL_LEGAL_SECTION_UPLOAD_END,
  PROPOSAL_LEGAL_SECTION_UPLOAD_RESET,
} from "../constants/proposalLegalSectionConstants";

// List all
export const proposalLegalSectionListReducer = (
  state = {
    loading: false,
    count: 0,
    proposal_legal_sections: [],
    numberOfSectionsAgreed: 0,
    numberOfSectionsNotAgreed: 0,
    numberOfSectionsUnderReview: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_LEGAL_SECTION_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        proposal_legal_sections: action.payload.proposal_legal_sections,
        numberOfSectionsAgreed: action.payload.numberOfSectionsAgreed,
        numberOfSectionsNotAgreed: action.payload.numberOfSectionsNotAgreed,
        numberOfSectionsUnderReview: action.payload.numberOfSectionsUnderReview,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        proposal_legal_sections: [],
        numberOfSectionsAgreed: 0,
        numberOfSectionsNotAgreed: 0,
        numberOfSectionsUnderReview: 0,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LEGAL_SECTION_LIST_RESET:
      return {
        loading: false,
        count: 0,
        proposal_legal_sections: [],
        numberOfSectionsAgreed: 0,
        numberOfSectionsNotAgreed: 0,
        numberOfSectionsUnderReview: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const proposalLegalSectionCreateReducer = (
  state = {
    loading: false,
    proposal_legal_section: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_CREATE_REQUEST:
      return {
        loading: true,
        proposal_legal_section: state.proposal_legal_section,
        success: false,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_CREATE_SUCCESS:
      return {
        loading: false,
        proposal_legal_section: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_CREATE_FAIL:
      return {
        loading: false,
        proposal_legal_section: state.proposal_legal_section,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LEGAL_SECTION_CREATE_RESET:
      return {
        loading: false,
        proposal_legal_section: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const proposalLegalSectionDetailsReducer = (
  state = {
    loading: false,
    proposal_legal_section: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_DETAILS_REQUEST:
      return {
        loading: true,
        proposal_legal_section: state.proposal_legal_section,
        success: false,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_DETAILS_SUCCESS:
      return {
        loading: false,
        proposal_legal_section: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_DETAILS_FAIL:
      return {
        loading: false,
        proposal_legal_section: state.proposal_legal_section,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LEGAL_SECTION_DETAILS_RESET:
      return {
        loading: false,
        proposal_legal_section: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const proposalLegalSectionUpdateReducer = (
  state = {
    loading: false,
    proposal_legal_section: {},
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_UPDATE_REQUEST:
      return {
        loading: true,
        proposal_legal_section: state.proposal_legal_section,
        success: false,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_UPDATE_SUCCESS:
      return {
        loading: false,
        proposal_legal_section: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_UPDATE_FAIL:
      return {
        loading: false,
        proposal_legal_section: state.proposal_legal_section,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LEGAL_SECTION_UPDATE_RESET:
      return {
        loading: false,
        proposal_legal_section: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const proposalLegalSectionDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LEGAL_SECTION_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Download
export const proposalLegalSectionDownloadReducer = (
  state = {
    loading: false,
    proposal_legal_sections: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_DOWNLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_LEGAL_SECTION_DOWNLOAD_SUCCESS:
      return {
        loading: false,
        proposal_legal_sections: action.payload.proposal_legal_sections,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_DOWNLOAD_FAIL:
      return {
        loading: false,
        proposal_legal_sections: [],
        success: false,
        error: action.payload,
      };

    case PROPOSAL_LEGAL_SECTION_DOWNLOAD_RESET:
      return {
        loading: false,
        proposal_legal_sections: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Upload
export const proposalLegalSectionUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    progress: 0,
    records_failed: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case PROPOSAL_LEGAL_SECTION_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case PROPOSAL_LEGAL_SECTION_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PROPOSAL_LEGAL_SECTION_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        progress: action.payload,
        success: true,
        error: "",
      };

    case PROPOSAL_LEGAL_SECTION_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        records_failed: action.payload.records_failed,
        success: false,
        error: action.payload.error,
      };

    case PROPOSAL_LEGAL_SECTION_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case PROPOSAL_LEGAL_SECTION_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        progress: 0,
        records_failed: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
