export const HUBSPOT_AUTHORISE_REQUEST = "HUBSPOT_AUTHORISE_REQUEST";
export const HUBSPOT_AUTHORISE_SUCCESS = "HUBSPOT_AUTHORISE_SUCCESS";
export const HUBSPOT_AUTHORISE_FAIL = "HUBSPOT_AUTHORISE_FAIL";
export const HUBSPOT_AUTHORISE_RESET = "HUBSPOT_AUTHORISE_RESET";

export const HUBSPOT_DEAUTHORISE_REQUEST = "HUBSPOT_DEAUTHORISE_REQUEST";
export const HUBSPOT_DEAUTHORISE_SUCCESS = "HUBSPOT_DEAUTHORISE_SUCCESS";
export const HUBSPOT_DEAUTHORISE_FAIL = "HUBSPOT_DEAUTHORISE_FAIL";
export const HUBSPOT_DEAUTHORISE_RESET = "HUBSPOT_DEAUTHORISE_RESET";

export const HUBSPOT_GET_CONTACT_REQUEST = "HUBSPOT_GET_CONTACT_REQUEST";
export const HUBSPOT_GET_CONTACT_SUCCESS = "HUBSPOT_GET_CONTACT_SUCCESS";
export const HUBSPOT_GET_CONTACT_FAIL = "HUBSPOT_GET_CONTACT_FAIL";
export const HUBSPOT_GET_CONTACT_RESET = "HUBSPOT_GET_CONTACT_RESET";

export const HUBSPOT_SYNC_CONTACTS_REQUEST = "HUBSPOT_SYNC_CONTACTS_REQUEST";
export const HUBSPOT_SYNC_CONTACTS_SUCCESS = "HUBSPOT_SYNC_CONTACTS_SUCCESS";
export const HUBSPOT_SYNC_CONTACTS_FAIL = "HUBSPOT_SYNC_CONTACTS_FAIL";
export const HUBSPOT_SYNC_CONTACTS_RESET = "HUBSPOT_SYNC_CONTACTS_RESET";
