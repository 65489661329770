// Library imports
import { Colors, Divider, H5 } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function InvoiceTotalValuePercentageCard({ fill, invoiceList, invoiceQuery }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Share of value</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Invoice value by status, as a percentage of the aggregated value of
            all invoices selected.
          </p>
        </>
      }
      data={[
        {
          name: "Paid",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: invoiceList.totalValuePaid,
        },
        {
          name: "Invoiced",
          fill: lightMode ? Colors.ORANGE3 : Colors.ORANGE5,
          value: invoiceList.totalValueInvoiced,
        },
        {
          name: "Draft",
          fill: lightMode ? Colors.BLUE3 : Colors.BLUE5,
          value: invoiceList.totalValueDraft,
        },
      ]}
      fill={fill}
      filter={invoiceQuery ? invoiceQuery.status : undefined}
      noData={
        !invoiceList.totalValueDraft &&
        !invoiceList.totalValueInvoiced &&
        !invoiceList.totalValuePaid
      }
      showPercent
      title="Share of value"
      units="Percentage"
    />
  );
}

export default InvoiceTotalValuePercentageCard;
