// Library imports
import { Classes, Colors, Tag, Text } from "@blueprintjs/core";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// Component imports
import InfoPopup from "../components/InfoPopup";
import TableEmail from "../components/TableEmail";
import TableLink from "../components/TableLink";

// Main function
function TableText({
  email,
  image,
  info,
  logo,
  showImage,
  tagIntent,
  tagOnClick,
  tagText,
  text,
  url,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Scale containers
  const innerContainer = useRef(null);
  const outerContainer = useRef(null);
  const [textWidth, setTextWidth] = useState(20);

  useEffect(() => {
    if (
      innerContainer.current.offsetWidth &&
      outerContainer.current.offsetWidth
    ) {
      setTextWidth(
        outerContainer.current.offsetWidth - innerContainer.current.offsetWidth
      );
    }
  }, [innerContainer, outerContainer]);

  // JSX UI code
  return (
    <div
      onClick={email || url ? (e) => e.stopPropagation() : undefined}
      ref={outerContainer}
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          width: info || tagText ? `${textWidth}px` : "100%",
        }}
      >
        {showImage && (email || text) && (
          <img
            alt={logo ? "logo" : "photo"}
            src={
              image
                ? image
                : logo
                ? "/images/no_logo.png"
                : "/images/no_photo.png"
            }
            style={{
              background: "white",
              border: `1px solid ${
                lightMode ? Colors.LIGHT_GRAY5 : Colors.DARK_GRAY5
              }`,
              borderRadius: logo ? "10%" : "50%",
              height: "40px",
              margin: "0 10px 0 0",
              objectFit: "contain",
              width: "40px",
            }}
          />
        )}

        {email ? (
          <TableEmail email={email} />
        ) : url ? (
          <TableLink name={text} sameTab url={url} />
        ) : (
          <Text ellipsize>{text ? text : "-"}</Text>
        )}
      </div>

      <div
        ref={innerContainer}
        style={{
          alignItems: "center",
          display: "flex",
          width: "fit-content",
        }}
      >
        {tagText && (
          <Tag
            intent={tagIntent}
            minimal
            onClick={(e) => {
              if (tagOnClick) {
                e.stopPropagation();

                tagOnClick();
              }
            }}
            style={{
              marginLeft: "5px",
              marginRight: info ? "5px" : undefined,
              textAlign: "center",
            }}
          >
            <span className={Classes.TEXT_SMALL}>{tagText}</span>
          </Tag>
        )}

        {info && <InfoPopup content={info} />}
      </div>
    </div>
  );
}

export default TableText;
