export const CLIENT_NEED_LIST_REQUEST = "CLIENT_NEED_LIST_REQUEST";
export const CLIENT_NEED_LIST_SUCCESS = "CLIENT_NEED_LIST_SUCCESS";
export const CLIENT_NEED_LIST_FAIL = "CLIENT_NEED_LIST_FAIL";
export const CLIENT_NEED_LIST_RESET = "CLIENT_NEED_LIST_RESET";

export const CLIENT_NEED_CREATE_REQUEST = "CLIENT_NEED_CREATE_REQUEST";
export const CLIENT_NEED_CREATE_SUCCESS = "CLIENT_NEED_CREATE_SUCCESS";
export const CLIENT_NEED_CREATE_FAIL = "CLIENT_NEED_CREATE_FAIL";
export const CLIENT_NEED_CREATE_RESET = "CLIENT_NEED_CREATE_RESET";

export const CLIENT_NEED_DETAILS_REQUEST = "CLIENT_NEED_DETAILS_REQUEST";
export const CLIENT_NEED_DETAILS_SUCCESS = "CLIENT_NEED_DETAILS_SUCCESS";
export const CLIENT_NEED_DETAILS_FAIL = "CLIENT_NEED_DETAILS_FAIL";
export const CLIENT_NEED_DETAILS_RESET = "CLIENT_NEED_DETAILS_RESET";

export const CLIENT_NEED_UPDATE_REQUEST = "CLIENT_NEED_UPDATE_REQUEST";
export const CLIENT_NEED_UPDATE_SUCCESS = "CLIENT_NEED_UPDATE_SUCCESS";
export const CLIENT_NEED_UPDATE_FAIL = "CLIENT_NEED_UPDATE_FAIL";
export const CLIENT_NEED_UPDATE_RESET = "CLIENT_NEED_UPDATE_RESET";

export const CLIENT_NEED_DELETE_REQUEST = "CLIENT_NEED_DELETE_REQUEST";
export const CLIENT_NEED_DELETE_SUCCESS = "CLIENT_NEED_DELETE_SUCCESS";
export const CLIENT_NEED_DELETE_FAIL = "CLIENT_NEED_DELETE_FAIL";
export const CLIENT_NEED_DELETE_RESET = "CLIENT_NEED_DELETE_RESET";
