// Constant imports
import {
  STAFF_COMPETENCY_LIST_REQUEST,
  STAFF_COMPETENCY_LIST_SUCCESS,
  STAFF_COMPETENCY_LIST_FAIL,
  STAFF_COMPETENCY_LIST_RESET,
  STAFF_COMPETENCY_CREATE_REQUEST,
  STAFF_COMPETENCY_CREATE_SUCCESS,
  STAFF_COMPETENCY_CREATE_FAIL,
  STAFF_COMPETENCY_CREATE_RESET,
  STAFF_COMPETENCY_DETAILS_REQUEST,
  STAFF_COMPETENCY_DETAILS_SUCCESS,
  STAFF_COMPETENCY_DETAILS_FAIL,
  STAFF_COMPETENCY_DETAILS_RESET,
  STAFF_COMPETENCY_UPDATE_REQUEST,
  STAFF_COMPETENCY_UPDATE_SUCCESS,
  STAFF_COMPETENCY_UPDATE_FAIL,
  STAFF_COMPETENCY_UPDATE_RESET,
  STAFF_COMPETENCY_DELETE_REQUEST,
  STAFF_COMPETENCY_DELETE_SUCCESS,
  STAFF_COMPETENCY_DELETE_FAIL,
  STAFF_COMPETENCY_DELETE_RESET,
  STAFF_COMPETENCY_UPLOAD_BEGIN,
  STAFF_COMPETENCY_UPLOAD_REQUEST,
  STAFF_COMPETENCY_UPLOAD_SUCCESS,
  STAFF_COMPETENCY_UPLOAD_FAIL,
  STAFF_COMPETENCY_UPLOAD_END,
  STAFF_COMPETENCY_UPLOAD_RESET,
} from "../constants/staffCompetencyConstants";

// List all
export const staffCompetencyListReducer = (
  state = {
    loading: false,
    count: 0,
    staff_competencies: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_COMPETENCY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STAFF_COMPETENCY_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        staff_competencies: action.payload.staff_competencies,
        success: true,
        error: "",
      };

    case STAFF_COMPETENCY_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        staff_competencies: [],
        success: false,
        error: action.payload,
      };

    case STAFF_COMPETENCY_LIST_RESET:
      return {
        loading: false,
        count: 0,
        staff_competencies: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const staffCompetencyCreateReducer = (
  state = { loading: false, staff_competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_COMPETENCY_CREATE_REQUEST:
      return {
        loading: true,
        staff_competency: state.staff_competency,
        success: false,
        error: "",
      };

    case STAFF_COMPETENCY_CREATE_SUCCESS:
      return {
        loading: false,
        staff_competency: action.payload,
        success: true,
        error: "",
      };

    case STAFF_COMPETENCY_CREATE_FAIL:
      return {
        loading: false,
        staff_competency: state.staff_competency,
        success: false,
        error: action.payload,
      };

    case STAFF_COMPETENCY_CREATE_RESET:
      return {
        loading: false,
        staff_competency: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// List details
export const staffCompetencyDetailsReducer = (
  state = { loading: false, staff_competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_COMPETENCY_DETAILS_REQUEST:
      return {
        loading: true,
        staff_competency: state.staff_competency,
        success: false,
        error: "",
      };

    case STAFF_COMPETENCY_DETAILS_SUCCESS:
      return {
        loading: false,
        staff_competency: action.payload,
        success: true,
        error: "",
      };

    case STAFF_COMPETENCY_DETAILS_FAIL:
      return {
        loading: false,
        staff_competency: state.staff_competency,
        success: false,
        error: action.payload,
      };

    case STAFF_COMPETENCY_DETAILS_RESET:
      return {
        loading: false,
        staff_competency: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const staffCompetencyUpdateReducer = (
  state = { loading: false, staff_competency: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_COMPETENCY_UPDATE_REQUEST:
      return {
        loading: true,
        staff_competency: state.staff_competency,
        success: false,
        error: "",
      };

    case STAFF_COMPETENCY_UPDATE_SUCCESS:
      return {
        loading: false,
        staff_competency: action.payload,
        success: true,
        error: "",
      };

    case STAFF_COMPETENCY_UPDATE_FAIL:
      return {
        loading: false,
        staff_competency: state.staff_competency,
        success: false,
        error: action.payload,
      };

    case STAFF_COMPETENCY_UPDATE_RESET:
      return {
        loading: false,
        staff_competency: {},
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Delete
export const staffCompetencyDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case STAFF_COMPETENCY_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case STAFF_COMPETENCY_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case STAFF_COMPETENCY_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case STAFF_COMPETENCY_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};

// Upload
export const staffCompetencyUploadReducer = (
  state = {
    begin: false,
    end: false,
    loading: false,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case STAFF_COMPETENCY_UPLOAD_BEGIN:
      return {
        ...state,
        begin: true,
        end: false,
      };

    case STAFF_COMPETENCY_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case STAFF_COMPETENCY_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: "",
      };

    case STAFF_COMPETENCY_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      };

    case STAFF_COMPETENCY_UPLOAD_END:
      return {
        ...state,
        begin: false,
        end: true,
      };

    case STAFF_COMPETENCY_UPLOAD_RESET:
      return {
        begin: false,
        end: false,
        loading: false,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};
