// Constant imports
import {
  TODO_LIST_REQUEST,
  TODO_LIST_SUCCESS,
  TODO_LIST_FAIL,
  TODO_LIST_RESET,
  TODO_UPDATE_REQUEST,
  TODO_UPDATE_SUCCESS,
  TODO_UPDATE_FAIL,
  TODO_UPDATE_RESET,
} from "../constants/todoConstants";

// List all
export const todoListReducer = (
  state = {
    loading: false,
    count: 0,
    todos: [],
    clientTodosClosed: 0,
    clientTodosOpen: 0,
    contactTodosClosed: 0,
    contactTodosOpen: 0,
    engagementTodosClosed: 0,
    engagementTodosOpen: 0,
    proposalTodosClosed: 0,
    proposalTodosOpen: 0,
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case TODO_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TODO_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        todos: action.payload.todos,
        clientTodosClosed: action.payload.clientTodosClosed,
        clientTodosOpen: action.payload.clientTodosOpen,
        contactTodosClosed: action.payload.contactTodosClosed,
        contactTodosOpen: action.payload.contactTodosOpen,
        engagementTodosClosed: action.payload.engagementTodosClosed,
        engagementTodosOpen: action.payload.engagementTodosOpen,
        proposalTodosClosed: action.payload.proposalTodosClosed,
        proposalTodosOpen: action.payload.proposalTodosOpen,
        success: true,
        error: "",
      };

    case TODO_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        todos: [],
        clientTodosClosed: 0,
        clientTodosOpen: 0,
        contactTodosClosed: 0,
        contactTodosOpen: 0,
        engagementTodosClosed: 0,
        engagementTodosOpen: 0,
        proposalTodosClosed: 0,
        proposalTodosOpen: 0,
        success: false,
        error: action.payload,
      };

    case TODO_LIST_RESET:
      return {
        loading: false,
        count: 0,
        todos: [],
        clientTodosClosed: 0,
        clientTodosOpen: 0,
        contactTodosClosed: 0,
        contactTodosOpen: 0,
        engagementTodosClosed: 0,
        engagementTodosOpen: 0,
        proposalTodosClosed: 0,
        proposalTodosOpen: 0,
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Update
export const todoUpdateReducer = (
  state = { loading: false, todo: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case TODO_UPDATE_REQUEST:
      return {
        loading: true,
        todo: state.todo,
        success: false,
        error: "",
      };

    case TODO_UPDATE_SUCCESS:
      return {
        loading: false,
        todo: action.payload,
        success: true,
        error: "",
      };

    case TODO_UPDATE_FAIL:
      return {
        loading: false,
        todo: state.todo,
        success: false,
        error: action.payload,
      };

    case TODO_UPDATE_RESET:
      return { loading: false, todo: {}, success: false, error: "" };

    default:
      return state;
  }
};
