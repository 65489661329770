// Main function
const generateTimelineDataFromBookings = (bookings) => {
  if (bookings) {
    let arr = [];

    bookings.forEach((booking) => {
      arr.push({
        category: booking.type,
        children: [],
        end: new Date(booking.end),
        id: `${booking.id}`,
        milestones: [],
        name: booking.booking_code_data.name,
        start: new Date(booking.start),
      });
    });

    return arr;
  }

  return [];
};

export default generateTimelineDataFromBookings;
