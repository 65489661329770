// Library imports
import React from "react";
import { useSelector } from "react-redux";

// Main function
function TabSectionFieldLink({ invertColor, name, sameTab, shorten, url }) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <>
      {url ? (
        <a
          href={url}
          rel="noopener noreferrer"
          style={{
            color: invertColor
              ? lightMode
                ? "#D2ECF9"
                : "#1891AC"
              : lightMode
              ? "#1891AC"
              : "#D2ECF9",
          }}
          target={sameTab ? undefined : "_blank"}
        >
          {name ? name : shorten ? url.split("/").pop() : url}
        </a>
      ) : (
        "-"
      )}
    </>
  );
}

export default TabSectionFieldLink;
