// Constant imports
import {
  BOOKING_CODE_LIST_REQUEST,
  BOOKING_CODE_LIST_SUCCESS,
  BOOKING_CODE_LIST_FAIL,
  BOOKING_CODE_LIST_RESET,
  BOOKING_CODE_CREATE_REQUEST,
  BOOKING_CODE_CREATE_SUCCESS,
  BOOKING_CODE_CREATE_FAIL,
  BOOKING_CODE_CREATE_RESET,
  BOOKING_CODE_DETAILS_REQUEST,
  BOOKING_CODE_DETAILS_SUCCESS,
  BOOKING_CODE_DETAILS_FAIL,
  BOOKING_CODE_DETAILS_RESET,
  BOOKING_CODE_UPDATE_REQUEST,
  BOOKING_CODE_UPDATE_SUCCESS,
  BOOKING_CODE_UPDATE_FAIL,
  BOOKING_CODE_UPDATE_RESET,
  BOOKING_CODE_DELETE_REQUEST,
  BOOKING_CODE_DELETE_SUCCESS,
  BOOKING_CODE_DELETE_FAIL,
  BOOKING_CODE_DELETE_RESET,
} from "../constants/bookingCodeConstants";

// List all
export const bookingCodeListReducer = (
  state = {
    loading: false,
    count: 0,
    budgetTotal: 0,
    invoicedTotal: 0,
    labourExpenseTotal: 0,
    marginPercentageAverage: 0,
    marginTotal: 0,
    nonLabourExpenseTotal: 0,
    remainingTotal: 0,
    revenueTotal: 0,
    totalExpenseTotal: 0,
    wipTotal: 0,
    booking_codes: [],
    success: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case BOOKING_CODE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case BOOKING_CODE_LIST_SUCCESS:
      return {
        loading: false,
        count: action.payload.count,
        budgetTotal: action.payload.budget_total,
        invoicedTotal: action.payload.invoiced_total,
        labourExpenseTotal: action.payload.labour_expense_total,
        marginPercentageAverage: action.payload.margin_percentage_average,
        marginTotal: action.payload.margin_total,
        nonLabourExpenseTotal: action.payload.non_labour_expense_total,
        remainingTotal: action.payload.remaining_total,
        revenueTotal: action.payload.revenue_total,
        totalExpenseTotal: action.payload.total_expense_total,
        wipTotal: action.payload.wip_total,
        booking_codes: action.payload.booking_codes,
        success: true,
        error: "",
      };

    case BOOKING_CODE_LIST_FAIL:
      return {
        loading: false,
        count: 0,
        budgetTotal: 0,
        invoicedTotal: 0,
        labourExpenseTotal: 0,
        marginPercentageAverage: 0,
        marginTotal: 0,
        nonLabourExpenseTotal: 0,
        remainingTotal: 0,
        revenueTotal: 0,
        totalExpenseTotal: 0,
        wipTotal: 0,
        booking_codes: [],
        success: false,
        error: action.payload,
      };

    case BOOKING_CODE_LIST_RESET:
      return {
        loading: false,
        count: 0,
        budgetTotal: 0,
        invoicedTotal: 0,
        labourExpenseTotal: 0,
        marginPercentageAverage: 0,
        marginTotal: 0,
        nonLabourExpenseTotal: 0,
        remainingTotal: 0,
        revenueTotal: 0,
        totalExpenseTotal: 0,
        wipTotal: 0,
        booking_codes: [],
        success: false,
        error: "",
      };

    default:
      return state;
  }
};

// Create
export const bookingCodeCreateReducer = (
  state = { loading: false, booking_code: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_CODE_CREATE_REQUEST:
      return {
        loading: true,
        booking_code: state.booking_code,
        success: false,
        error: "",
      };

    case BOOKING_CODE_CREATE_SUCCESS:
      return {
        loading: false,
        booking_code: action.payload,
        success: true,
        error: "",
      };

    case BOOKING_CODE_CREATE_FAIL:
      return {
        loading: false,
        booking_code: state.booking_code,
        success: false,
        error: action.payload,
      };

    case BOOKING_CODE_CREATE_RESET:
      return { loading: false, booking_code: {}, success: false, error: "" };

    default:
      return state;
  }
};

// List details
export const bookingCodeDetailsReducer = (
  state = { loading: false, booking_code: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_CODE_DETAILS_REQUEST:
      return {
        loading: true,
        booking_code: state.booking_code,
        success: false,
        error: "",
      };

    case BOOKING_CODE_DETAILS_SUCCESS:
      return {
        loading: false,
        booking_code: action.payload,
        success: true,
        error: "",
      };

    case BOOKING_CODE_DETAILS_FAIL:
      return {
        loading: false,
        booking_code: state.booking_code,
        success: false,
        error: action.payload,
      };

    case BOOKING_CODE_DETAILS_RESET:
      return { loading: false, booking_code: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Update
export const bookingCodeUpdateReducer = (
  state = { loading: false, booking_code: {}, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_CODE_UPDATE_REQUEST:
      return {
        loading: true,
        booking_code: state.booking_code,
        success: false,
        error: "",
      };

    case BOOKING_CODE_UPDATE_SUCCESS:
      return {
        loading: false,
        booking_code: action.payload,
        success: true,
        error: "",
      };

    case BOOKING_CODE_UPDATE_FAIL:
      return {
        loading: false,
        booking_code: state.booking_code,
        success: false,
        error: action.payload,
      };

    case BOOKING_CODE_UPDATE_RESET:
      return { loading: false, booking_code: {}, success: false, error: "" };

    default:
      return state;
  }
};

// Delete
export const bookingCodeDeleteReducer = (
  state = { loading: false, success: false, error: "" },
  action
) => {
  switch (action.type) {
    case BOOKING_CODE_DELETE_REQUEST:
      return {
        loading: true,
        success: false,
        error: "",
      };

    case BOOKING_CODE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        error: "",
      };

    case BOOKING_CODE_DELETE_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case BOOKING_CODE_DELETE_RESET:
      return { loading: false, success: false, error: "" };

    default:
      return state;
  }
};
