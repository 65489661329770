// Library imports
import { Colors, NonIdealState, Tag } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
} from "recharts";

// Component imports
import InfoPopup from "./InfoPopup";
import ItemCard from "./ItemCard";

// Utility imports
import numberFormatter from "../utilities/numberFormatter";

// Main function
function DataCardBarChart({
  comments,
  darkColor,
  data,
  fill,
  interactive,
  lightColor,
  noData,
  onClick,
  title,
  units,
  width,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // Highlight item
  const [activeIndex, setActiveIndex] = useState(null);

  // JSX UI code
  return (
    <ItemCard
      content={
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Tag minimal>{units}</Tag>
            </div>

            <InfoPopup content={<>{comments}</>} />
          </div>

          {/* Content */}
          {!noData && (
            <div style={{ overflowY: "hidden" }}>
              <ResponsiveContainer height={215} width="100%">
                <BarChart data={data} margin={{ top: 25 }}>
                  <defs>
                    {data.map((item, i) => {
                      return (
                        <linearGradient
                          id={`color${item.fill}`}
                          key={i}
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor={item.fill}
                            stopOpacity={0.9}
                          />
                          <stop
                            offset="95%"
                            stopColor={item.fill}
                            stopOpacity={0.7}
                          />
                        </linearGradient>
                      );
                    })}
                  </defs>

                  <Bar
                    dataKey="value"
                    maxBarSize={65}
                    onMouseEnter={(_, index) => setActiveIndex(index)}
                    onMouseLeave={() => setActiveIndex(null)}
                    radius={[5, 5, 0, 0]}
                  >
                    <LabelList
                      dataKey="value"
                      formatter={(value) =>
                        value ? numberFormatter(value) : ""
                      }
                      position="top"
                      style={{ fontSize: "10px" }}
                    />

                    {data.map((entry, index) => (
                      <Cell
                        fill={`url(#color${entry.fill})`}
                        key={`cell-${index}`}
                        stroke={index === activeIndex ? entry.fill : undefined}
                      />
                    ))}
                  </Bar>

                  <ReferenceLine
                    fill={lightMode ? Colors.BLACK : Colors.WHITE}
                    y={0}
                  />

                  <XAxis
                    axisLine={false}
                    dataKey="name"
                    tick={{ fill: lightMode ? Colors.BLACK : Colors.WHITE }}
                    tickLine={false}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}

          {/* No results */}
          {noData && (
            <div
              style={{ alignItems: "center", display: "flex", height: "200px" }}
            >
              <NonIdealState icon={"search"} title={"No data"} />
            </div>
          )}
        </div>
      }
      darkColor={darkColor}
      fill={fill}
      height={300}
      interactive={interactive}
      lightColor={lightColor}
      noScroll
      onClick={onClick}
      title={title}
      width={width}
    />
  );
}

export default DataCardBarChart;
