// Library imports
import { Colors, Divider, H5, Tag } from "@blueprintjs/core";
import React from "react";
import { useSelector } from "react-redux";

// Component imports
import DataCardDonutChart from "../components/DataCardDonutChart";

// Main function
function ProposalWinLossCard({
  fill,
  proposalList,
  proposalQuery,
  showInsights,
}) {
  // UI configuration state variables
  const UIMode = useSelector((state) => state.UIMode);
  const { lightMode } = UIMode;

  // JSX UI code
  return (
    <DataCardDonutChart
      comments={
        <>
          <H5>Won-Lost</H5>

          <Divider
            style={{
              background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
              margin: "10px 0 10px 0",
            }}
          />

          <p>
            <strong>Definition</strong>
          </p>

          <p>
            Percentage of proposals closed (won or lost). Excludes proposals
            that have been cancelled.
          </p>

          {showInsights && (
            <>
              <Divider
                style={{
                  background: lightMode ? Colors.GRAY1 : Colors.GRAY5,
                  margin: "10px 0 10px 0",
                }}
              />

              <p>
                <strong>Insights</strong>
              </p>

              <div style={{ marginBottom: "10px" }}>
                {proposalList.numberOfProposalsWon /
                  (proposalList.numberOfProposalsWon +
                    proposalList.numberOfProposalsLost) >=
                0.75 ? (
                  <div>
                    <Tag intent="success">Healthy</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm has a good success rate, which can often be
                      attributed to having strong client relationships,
                      efficient qualification processes, and an appealing value
                      proposition.
                    </div>
                  </div>
                ) : proposalList.numberOfProposalsWon /
                    (proposalList.numberOfProposalsWon +
                      proposalList.numberOfProposalsLost) >=
                  0.5 ? (
                  <div>
                    <Tag intent="warning">Needs attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm's success rate is moderate. One potential way to
                      increase your win rate could be to narrow your focus to a
                      smaller number of clients, allowing you to develop
                      stronger relationships and cater to their specific needs.
                    </div>
                  </div>
                ) : (
                  <div>
                    <Tag intent="danger">Needs urgent attention</Tag>

                    <div style={{ marginTop: "10px" }}>
                      Your firm's success rate is poor. It may be necessary to
                      reassess your services and pricing, as well as to
                      carefully evaluate opportunities before pursuing them.
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      }
      data={[
        {
          name: "Won",
          fill: lightMode ? Colors.GREEN3 : Colors.GREEN5,
          value: proposalList.numberOfProposalsWon,
        },
        {
          name: "Lost",
          fill: lightMode ? Colors.RED3 : Colors.RED5,
          value: proposalList.numberOfProposalsLost,
        },
      ]}
      fill={fill}
      filter={proposalQuery ? proposalQuery.status : undefined}
      noData={
        !proposalList.numberOfProposalsLost &&
        !proposalList.numberOfProposalsWon
      }
      showPercent
      title="Won-lost"
      units="Percentage"
    />
  );
}

export default ProposalWinLossCard;
